// src:
// https://raw.githubusercontent.com/jplehmann/textbites/master/textbites/data/NKJV.bible.json

export const bible = {
    "version": "NKJV", 
    "books": [
      {
        "name": "Genesis", 
        "chapters": [
          {
            "verses": [
              {
                "text": "In the beginning God created the heavens and the earth.", 
                "num": 1
              }, 
              {
                "text": "The earth was without form, and void; and darkness was on the face of the deep. And the Spirit of God was hovering over the face of the waters.", 
                "num": 2
              }, 
              {
                "text": "Then God said, \"Let there be light\"; and there was light.", 
                "num": 3
              }, 
              {
                "text": "And God saw the light, that it was good; and God divided the light from the darkness.", 
                "num": 4
              }, 
              {
                "text": "God called the light Day, and the darkness He called Night. So the evening and the morning were the first day.", 
                "num": 5
              }, 
              {
                "text": "Then God said, \"Let there be a firmament in the midst of the waters, and let it divide the waters from the waters.\"", 
                "num": 6
              }, 
              {
                "text": "Thus God made the firmament, and divided the waters which were under the firmament from the waters which were above the firmament; and it was so.", 
                "num": 7
              }, 
              {
                "text": "And God called the firmament Heaven. So the evening and the morning were the second day.", 
                "num": 8
              }, 
              {
                "text": "Then God said, \"Let the waters under the heavens be gathered together into one place, and let the dry land appear\"; and it was so.", 
                "num": 9
              }, 
              {
                "text": "And God called the dry land Earth, and the gathering together of the waters He called Seas. And God saw that it was good.", 
                "num": 10
              }, 
              {
                "text": "Then God said, \"Let the earth bring forth grass, the herb that yields seed, and the fruit tree that yields fruit according to its kind, whose seed is in itself, on the earth\"; and it was so.", 
                "num": 11
              }, 
              {
                "text": "And the earth brought forth grass, the herb that yields seed according to its kind, and the tree that yields fruit, whose seed is in itself according to its kind. And God saw that it was good.", 
                "num": 12
              }, 
              {
                "text": "So the evening and the morning were the third day.", 
                "num": 13
              }, 
              {
                "text": "Then God said, \"Let there be lights in the firmament of the heavens to divide the day from the night; and let them be for signs and seasons, and for days and years;", 
                "num": 14
              }, 
              {
                "text": "and let them be for lights in the firmament of the heavens to give light on the earth\"; and it was so.", 
                "num": 15
              }, 
              {
                "text": "Then God made two great lights: the greater light to rule the day, and the lesser light to rule the night. He made the stars also.", 
                "num": 16
              }, 
              {
                "text": "God set them in the firmament of the heavens to give light on the earth,", 
                "num": 17
              }, 
              {
                "text": "and to rule over the day and over the night, and to divide the light from the darkness. And God saw that it was good.", 
                "num": 18
              }, 
              {
                "text": "So the evening and the morning were the fourth day.", 
                "num": 19
              }, 
              {
                "text": "Then God said, \"Let the waters abound with an abundance of living creatures, and let birds fly above the earth across the face of the firmament of the heavens.\"", 
                "num": 20
              }, 
              {
                "text": "So God created great sea creatures and every living thing that moves, with which the waters abounded, according to their kind, and every winged bird according to its kind. And God saw that it was good.", 
                "num": 21
              }, 
              {
                "text": "And God blessed them, saying, \"Be fruitful and multiply, and fill the waters in the seas, and let birds multiply on the earth.\"", 
                "num": 22
              }, 
              {
                "text": "So the evening and the morning were the fifth day.", 
                "num": 23
              }, 
              {
                "text": "Then God said, \"Let the earth bring forth the living creature according to its kind: cattle and creeping thing and beast of the earth, each according to its kind\"; and it was so.", 
                "num": 24
              }, 
              {
                "text": "And God made the beast of the earth according to its kind, cattle according to its kind, and everything that creeps on the earth according to its kind. And God saw that it was good.", 
                "num": 25
              }, 
              {
                "text": "Then God said, \"Let Us make man in Our image, according to Our likeness; let them have dominion over the fish of the sea, over the birds of the air, and over the cattle, over all the earth and over every creeping thing that creeps on the earth.\"", 
                "num": 26
              }, 
              {
                "text": "So God created man in His own image; in the image of God He created him; male and female He created them.", 
                "num": 27
              }, 
              {
                "text": "Then God blessed them, and God said to them, \"Be fruitful and multiply; fill the earth and subdue it; have dominion over the fish of the sea, over the birds of the air, and over every living thing that moves on the earth.\"", 
                "num": 28
              }, 
              {
                "text": "And God said, \"See, I have given you every herb that yields seed which is on the face of all the earth, and every tree whose fruit yields seed; to you it shall be for food.", 
                "num": 29
              }, 
              {
                "text": "Also, to every beast of the earth, to every bird of the air, and to everything that creeps on the earth, in which there is life, I have given every green herb for food\"; and it was so.", 
                "num": 30
              }, 
              {
                "text": "Then God saw everything that He had made, and indeed it was very good. So the evening and the morning were the sixth day.", 
                "num": 31
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Thus the heavens and the earth, and all the host of them, were finished.", 
                "num": 1
              }, 
              {
                "text": "And on the seventh day God ended His work which He had done, and He rested on the seventh day from all His work which He had done.", 
                "num": 2
              }, 
              {
                "text": "Then God blessed the seventh day and sanctified it, because in it He rested from all His work which God had created and made.", 
                "num": 3
              }, 
              {
                "text": "This is the history of the heavens and the earth when they were created, in the day that the LORD God made the earth and the heavens,", 
                "num": 4
              }, 
              {
                "text": "before any plant of the field was in the earth and before any herb of the field had grown. For the LORD God had not caused it to rain on the earth, and there was no man to till the ground;", 
                "num": 5
              }, 
              {
                "text": "but a mist went up from the earth and watered the whole face of the ground.", 
                "num": 6
              }, 
              {
                "text": "And the LORD God formed man of the dust of the ground, and breathed into his nostrils the breath of life; and man became a living being.", 
                "num": 7
              }, 
              {
                "text": "The LORD God planted a garden eastward in Eden, and there He put the man whom He had formed.", 
                "num": 8
              }, 
              {
                "text": "And out of the ground the LORD God made every tree grow that is pleasant to the sight and good for food. The tree of life was also in the midst of the garden, and the tree of the knowledge of good and evil.", 
                "num": 9
              }, 
              {
                "text": "Now a river went out of Eden to water the garden, and from there it parted and became four riverheads.", 
                "num": 10
              }, 
              {
                "text": "The name of the first is Pishon; it is the one which skirts the whole land of Havilah, where there is gold.", 
                "num": 11
              }, 
              {
                "text": "And the gold of that land is good. Bdellium and the onyx stone are there.", 
                "num": 12
              }, 
              {
                "text": "The name of the second river is Gihon; it is the one which goes around the whole land of Cush.", 
                "num": 13
              }, 
              {
                "text": "The name of the third river is Hiddekel; it is the one which goes toward the east of Assyria. The fourth river is the Euphrates.", 
                "num": 14
              }, 
              {
                "text": "Then the LORD God took the man and put him in the garden of Eden to tend and keep it.", 
                "num": 15
              }, 
              {
                "text": "And the LORD God commanded the man, saying, \"Of every tree of the garden you may freely eat;", 
                "num": 16
              }, 
              {
                "text": "but of the tree of the knowledge of good and evil you shall not eat, for in the day that you eat of it you shall surely die.\"", 
                "num": 17
              }, 
              {
                "text": "And the LORD God said, \"It is not good that man should be alone; I will make him a helper comparable to him.\"", 
                "num": 18
              }, 
              {
                "text": "Out of the ground the LORD God formed every beast of the field and every bird of the air, and brought them to Adam to see what he would call them. And whatever Adam called each living creature, that was its name.", 
                "num": 19
              }, 
              {
                "text": "So Adam gave names to all cattle, to the birds of the air, and to every beast of the field. But for Adam there was not found a helper comparable to him.", 
                "num": 20
              }, 
              {
                "text": "And the LORD God caused a deep sleep to fall on Adam, and he slept; and He took one of his ribs, and closed up the flesh in its place.", 
                "num": 21
              }, 
              {
                "text": "Then the rib which the LORD God had taken from man He made into a woman, and He brought her to the man.", 
                "num": 22
              }, 
              {
                "text": "And Adam said: \"This is now bone of my bones And flesh of my flesh; She shall be called Woman, Because she was taken out of Man.\"", 
                "num": 23
              }, 
              {
                "text": "Therefore a man shall leave his father and mother and be joined to his wife, and they shall become one flesh.", 
                "num": 24
              }, 
              {
                "text": "And they were both naked, the man and his wife, and were not ashamed.", 
                "num": 25
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now the serpent was more cunning than any beast of the field which the LORD God had made. And he said to the woman, \"Has God indeed said, \"You shall not eat of every tree of the garden'?\"", 
                "num": 1
              }, 
              {
                "text": "And the woman said to the serpent, \"We may eat the fruit of the trees of the garden;", 
                "num": 2
              }, 
              {
                "text": "but of the fruit of the tree which is in the midst of the garden, God has said, \"You shall not eat it, nor shall you touch it, lest you die.\"'", 
                "num": 3
              }, 
              {
                "text": "Then the serpent said to the woman, \"You will not surely die.", 
                "num": 4
              }, 
              {
                "text": "For God knows that in the day you eat of it your eyes will be opened, and you will be like God, knowing good and evil.\"", 
                "num": 5
              }, 
              {
                "text": "So when the woman saw that the tree was good for food, that it was pleasant to the eyes, and a tree desirable to make one wise, she took of its fruit and ate. She also gave to her husband with her, and he ate.", 
                "num": 6
              }, 
              {
                "text": "Then the eyes of both of them were opened, and they knew that they were naked; and they sewed fig leaves together and made themselves coverings.", 
                "num": 7
              }, 
              {
                "text": "And they heard the sound of the LORD God walking in the garden in the cool of the day, and Adam and his wife hid themselves from the presence of the LORD God among the trees of the garden.", 
                "num": 8
              }, 
              {
                "text": "Then the LORD God called to Adam and said to him, \"Where are you?\"", 
                "num": 9
              }, 
              {
                "text": "So he said, \"I heard Your voice in the garden, and I was afraid because I was naked; and I hid myself.\"", 
                "num": 10
              }, 
              {
                "text": "And He said, \"Who told you that you were naked? Have you eaten from the tree of which I commanded you that you should not eat?\"", 
                "num": 11
              }, 
              {
                "text": "Then the man said, \"The woman whom You gave to be with me, she gave me of the tree, and I ate.\"", 
                "num": 12
              }, 
              {
                "text": "And the LORD God said to the woman, \"What is this you have done?\" The woman said, \"The serpent deceived me, and I ate.\"", 
                "num": 13
              }, 
              {
                "text": "So the LORD God said to the serpent: \"Because you have done this, You are cursed more than all cattle, And more than every beast of the field; On your belly you shall go, And you shall eat dust All the days of your life.", 
                "num": 14
              }, 
              {
                "text": "And I will put enmity Between you and the woman, And between your seed and her Seed; He shall bruise your head, And you shall bruise His heel.\"", 
                "num": 15
              }, 
              {
                "text": "To the woman He said: \"I will greatly multiply your sorrow and your conception; In pain you shall bring forth children; Your desire shall be for your husband, And he shall rule over you.\"", 
                "num": 16
              }, 
              {
                "text": "Then to Adam He said, \"Because you have heeded the voice of your wife, and have eaten from the tree of which I commanded you, saying, \"You shall not eat of it': \"Cursed is the ground for your sake; In toil you shall eat of it All the days of your life.", 
                "num": 17
              }, 
              {
                "text": "Both thorns and thistles it shall bring forth for you, And you shall eat the herb of the field.", 
                "num": 18
              }, 
              {
                "text": "In the sweat of your face you shall eat bread Till you return to the ground, For out of it you were taken; For dust you are, And to dust you shall return.\"", 
                "num": 19
              }, 
              {
                "text": "And Adam called his wife's name Eve, because she was the mother of all living.", 
                "num": 20
              }, 
              {
                "text": "Also for Adam and his wife the LORD God made tunics of skin, and clothed them.", 
                "num": 21
              }, 
              {
                "text": "Then the LORD God said, \"Behold, the man has become like one of Us, to know good and evil. And now, lest he put out his hand and take also of the tree of life, and eat, and live forever\"--", 
                "num": 22
              }, 
              {
                "text": "therefore the LORD God sent him out of the garden of Eden to till the ground from which he was taken.", 
                "num": 23
              }, 
              {
                "text": "So He drove out the man; and He placed cherubim at the east of the garden of Eden, and a flaming sword which turned every way, to guard the way to the tree of life.", 
                "num": 24
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now Adam knew Eve his wife, and she conceived and bore Cain, and said, \"I have acquired a man from the LORD.\"", 
                "num": 1
              }, 
              {
                "text": "Then she bore again, this time his brother Abel. Now Abel was a keeper of sheep, but Cain was a tiller of the ground.", 
                "num": 2
              }, 
              {
                "text": "And in the process of time it came to pass that Cain brought an offering of the fruit of the ground to the LORD.", 
                "num": 3
              }, 
              {
                "text": "Abel also brought of the firstborn of his flock and of their fat. And the LORD respected Abel and his offering,", 
                "num": 4
              }, 
              {
                "text": "but He did not respect Cain and his offering. And Cain was very angry, and his countenance fell.", 
                "num": 5
              }, 
              {
                "text": "So the LORD said to Cain, \"Why are you angry? And why has your countenance fallen?", 
                "num": 6
              }, 
              {
                "text": "If you do well, will you not be accepted? And if you do not do well, sin lies at the door. And its desire is for you, but you should rule over it.\"", 
                "num": 7
              }, 
              {
                "text": "Now Cain talked with Abel his brother; and it came to pass, when they were in the field, that Cain rose up against Abel his brother and killed him.", 
                "num": 8
              }, 
              {
                "text": "Then the LORD said to Cain, \"Where is Abel your brother?\" He said, \"I do not know. Am I my brother's keeper?\"", 
                "num": 9
              }, 
              {
                "text": "And He said, \"What have you done? The voice of your brother's blood cries out to Me from the ground.", 
                "num": 10
              }, 
              {
                "text": "So now you are cursed from the earth, which has opened its mouth to receive your brother's blood from your hand.", 
                "num": 11
              }, 
              {
                "text": "When you till the ground, it shall no longer yield its strength to you. A fugitive and a vagabond you shall be on the earth.\"", 
                "num": 12
              }, 
              {
                "text": "And Cain said to the LORD, \"My punishment is greater than I can bear!", 
                "num": 13
              }, 
              {
                "text": "Surely You have driven me out this day from the face of the ground; I shall be hidden from Your face; I shall be a fugitive and a vagabond on the earth, and it will happen that anyone who finds me will kill me.\"", 
                "num": 14
              }, 
              {
                "text": "And the LORD said to him, \"Therefore, whoever kills Cain, vengeance shall be taken on him sevenfold.\" And the LORD set a mark on Cain, lest anyone finding him should kill him.", 
                "num": 15
              }, 
              {
                "text": "Then Cain went out from the presence of the LORD and dwelt in the land of Nod on the east of Eden.", 
                "num": 16
              }, 
              {
                "text": "And Cain knew his wife, and she conceived and bore Enoch. And he built a city, and called the name of the city after the name of his son--Enoch.", 
                "num": 17
              }, 
              {
                "text": "To Enoch was born Irad; and Irad begot Mehujael, and Mehujael begot Methushael, and Methushael begot Lamech.", 
                "num": 18
              }, 
              {
                "text": "Then Lamech took for himself two wives: the name of one was Adah, and the name of the second was Zillah.", 
                "num": 19
              }, 
              {
                "text": "And Adah bore Jabal. He was the father of those who dwell in tents and have livestock.", 
                "num": 20
              }, 
              {
                "text": "His brother's name was Jubal. He was the father of all those who play the harp and flute.", 
                "num": 21
              }, 
              {
                "text": "And as for Zillah, she also bore Tubal-Cain, an instructor of every craftsman in bronze and iron. And the sister of Tubal-Cain was Naamah.", 
                "num": 22
              }, 
              {
                "text": "Then Lamech said to his wives: \"Adah and Zillah, hear my voice; Wives of Lamech, listen to my speech! For I have killed a man for wounding me, Even a young man for hurting me.", 
                "num": 23
              }, 
              {
                "text": "If Cain shall be avenged sevenfold, Then Lamech seventy-sevenfold.\"", 
                "num": 24
              }, 
              {
                "text": "And Adam knew his wife again, and she bore a son and named him Seth, \"For God has appointed another seed for me instead of Abel, whom Cain killed.\"", 
                "num": 25
              }, 
              {
                "text": "And as for Seth, to him also a son was born; and he named him Enosh. Then men began to call on the name of the LORD.", 
                "num": 26
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "This is the book of the genealogy of Adam. In the day that God created man, He made him in the likeness of God.", 
                "num": 1
              }, 
              {
                "text": "He created them male and female, and blessed them and called them Mankind in the day they were created.", 
                "num": 2
              }, 
              {
                "text": "And Adam lived one hundred and thirty years, and begot a son in his own likeness, after his image, and named him Seth.", 
                "num": 3
              }, 
              {
                "text": "After he begot Seth, the days of Adam were eight hundred years; and he had sons and daughters.", 
                "num": 4
              }, 
              {
                "text": "So all the days that Adam lived were nine hundred and thirty years; and he died.", 
                "num": 5
              }, 
              {
                "text": "Seth lived one hundred and five years, and begot Enosh.", 
                "num": 6
              }, 
              {
                "text": "After he begot Enosh, Seth lived eight hundred and seven years, and had sons and daughters.", 
                "num": 7
              }, 
              {
                "text": "So all the days of Seth were nine hundred and twelve years; and he died.", 
                "num": 8
              }, 
              {
                "text": "Enosh lived ninety years, and begot Cainan.", 
                "num": 9
              }, 
              {
                "text": "After he begot Cainan, Enosh lived eight hundred and fifteen years, and had sons and daughters.", 
                "num": 10
              }, 
              {
                "text": "So all the days of Enosh were nine hundred and five years; and he died.", 
                "num": 11
              }, 
              {
                "text": "Cainan lived seventy years, and begot Mahalalel.", 
                "num": 12
              }, 
              {
                "text": "After he begot Mahalalel, Cainan lived eight hundred and forty years, and had sons and daughters.", 
                "num": 13
              }, 
              {
                "text": "So all the days of Cainan were nine hundred and ten years; and he died.", 
                "num": 14
              }, 
              {
                "text": "Mahalalel lived sixty-five years, and begot Jared.", 
                "num": 15
              }, 
              {
                "text": "After he begot Jared, Mahalalel lived eight hundred and thirty years, and had sons and daughters.", 
                "num": 16
              }, 
              {
                "text": "So all the days of Mahalalel were eight hundred and ninety-five years; and he died.", 
                "num": 17
              }, 
              {
                "text": "Jared lived one hundred and sixty-two years, and begot Enoch.", 
                "num": 18
              }, 
              {
                "text": "After he begot Enoch, Jared lived eight hundred years, and had sons and daughters.", 
                "num": 19
              }, 
              {
                "text": "So all the days of Jared were nine hundred and sixty-two years; and he died.", 
                "num": 20
              }, 
              {
                "text": "Enoch lived sixty-five years, and begot Methuselah.", 
                "num": 21
              }, 
              {
                "text": "After he begot Methuselah, Enoch walked with God three hundred years, and had sons and daughters.", 
                "num": 22
              }, 
              {
                "text": "So all the days of Enoch were three hundred and sixty-five years.", 
                "num": 23
              }, 
              {
                "text": "And Enoch walked with God; and he was not, for God took him.", 
                "num": 24
              }, 
              {
                "text": "Methuselah lived one hundred and eighty-seven years, and begot Lamech.", 
                "num": 25
              }, 
              {
                "text": "After he begot Lamech, Methuselah lived seven hundred and eighty-two years, and had sons and daughters.", 
                "num": 26
              }, 
              {
                "text": "So all the days of Methuselah were nine hundred and sixty-nine years; and he died.", 
                "num": 27
              }, 
              {
                "text": "Lamech lived one hundred and eighty-two years, and had a son.", 
                "num": 28
              }, 
              {
                "text": "And he called his name Noah, saying, \"This one will comfort us concerning our work and the toil of our hands, because of the ground which the LORD has cursed.\"", 
                "num": 29
              }, 
              {
                "text": "After he begot Noah, Lamech lived five hundred and ninety-five years, and had sons and daughters.", 
                "num": 30
              }, 
              {
                "text": "So all the days of Lamech were seven hundred and seventy-seven years; and he died.", 
                "num": 31
              }, 
              {
                "text": "And Noah was five hundred years old, and Noah begot Shem, Ham, and Japheth.", 
                "num": 32
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when men began to multiply on the face of the earth, and daughters were born to them,", 
                "num": 1
              }, 
              {
                "text": "that the sons of God saw the daughters of men, that they were beautiful; and they took wives for themselves of all whom they chose.", 
                "num": 2
              }, 
              {
                "text": "And the LORD said, \"My Spirit shall not strive with man forever, for he is indeed flesh; yet his days shall be one hundred and twenty years.\"", 
                "num": 3
              }, 
              {
                "text": "There were giants on the earth in those days, and also afterward, when the sons of God came in to the daughters of men and they bore children to them. Those were the mighty men who were of old, men of renown.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD saw that the wickedness of man was great in the earth, and that every intent of the thoughts of his heart was only evil continually.", 
                "num": 5
              }, 
              {
                "text": "And the LORD was sorry that He had made man on the earth, and He was grieved in His heart.", 
                "num": 6
              }, 
              {
                "text": "So the LORD said, \"I will destroy man whom I have created from the face of the earth, both man and beast, creeping thing and birds of the air, for I am sorry that I have made them.\"", 
                "num": 7
              }, 
              {
                "text": "But Noah found grace in the eyes of the LORD.", 
                "num": 8
              }, 
              {
                "text": "This is the genealogy of Noah. Noah was a just man, perfect in his generations. Noah walked with God.", 
                "num": 9
              }, 
              {
                "text": "And Noah begot three sons: Shem, Ham, and Japheth.", 
                "num": 10
              }, 
              {
                "text": "The earth also was corrupt before God, and the earth was filled with violence.", 
                "num": 11
              }, 
              {
                "text": "So God looked upon the earth, and indeed it was corrupt; for all flesh had corrupted their way on the earth.", 
                "num": 12
              }, 
              {
                "text": "And God said to Noah, \"The end of all flesh has come before Me, for the earth is filled with violence through them; and behold, I will destroy them with the earth.", 
                "num": 13
              }, 
              {
                "text": "Make yourself an ark of gopherwood; make rooms in the ark, and cover it inside and outside with pitch.", 
                "num": 14
              }, 
              {
                "text": "And this is how you shall make it: The length of the ark shall be three hundred cubits, its width fifty cubits, and its height thirty cubits.", 
                "num": 15
              }, 
              {
                "text": "You shall make a window for the ark, and you shall finish it to a cubit from above; and set the door of the ark in its side. You shall make it with lower, second, and third decks.", 
                "num": 16
              }, 
              {
                "text": "And behold, I Myself am bringing floodwaters on the earth, to destroy from under heaven all flesh in which is the breath of life; everything that is on the earth shall die.", 
                "num": 17
              }, 
              {
                "text": "But I will establish My covenant with you; and you shall go into the ark--you, your sons, your wife, and your sons' wives with you.", 
                "num": 18
              }, 
              {
                "text": "And of every living thing of all flesh you shall bring two of every sort into the ark, to keep them alive with you; they shall be male and female.", 
                "num": 19
              }, 
              {
                "text": "Of the birds after their kind, of animals after their kind, and of every creeping thing of the earth after its kind, two of every kind will come to you to keep them alive.", 
                "num": 20
              }, 
              {
                "text": "And you shall take for yourself of all food that is eaten, and you shall gather it to yourself; and it shall be food for you and for them.\"", 
                "num": 21
              }, 
              {
                "text": "Thus Noah did; according to all that God commanded him, so he did.", 
                "num": 22
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to Noah, \"Come into the ark, you and all your household, because I have seen that you are righteous before Me in this generation.", 
                "num": 1
              }, 
              {
                "text": "You shall take with you seven each of every clean animal, a male and his female; two each of animals that are unclean, a male and his female;", 
                "num": 2
              }, 
              {
                "text": "also seven each of birds of the air, male and female, to keep the species alive on the face of all the earth.", 
                "num": 3
              }, 
              {
                "text": "For after seven more days I will cause it to rain on the earth forty days and forty nights, and I will destroy from the face of the earth all living things that I have made.\"", 
                "num": 4
              }, 
              {
                "text": "And Noah did according to all that the LORD commanded him.", 
                "num": 5
              }, 
              {
                "text": "Noah was six hundred years old when the floodwaters were on the earth.", 
                "num": 6
              }, 
              {
                "text": "So Noah, with his sons, his wife, and his sons' wives, went into the ark because of the waters of the flood.", 
                "num": 7
              }, 
              {
                "text": "Of clean animals, of animals that are unclean, of birds, and of everything that creeps on the earth,", 
                "num": 8
              }, 
              {
                "text": "two by two they went into the ark to Noah, male and female, as God had commanded Noah.", 
                "num": 9
              }, 
              {
                "text": "And it came to pass after seven days that the waters of the flood were on the earth.", 
                "num": 10
              }, 
              {
                "text": "In the six hundredth year of Noah's life, in the second month, the seventeenth day of the month, on that day all the fountains of the great deep were broken up, and the windows of heaven were opened.", 
                "num": 11
              }, 
              {
                "text": "And the rain was on the earth forty days and forty nights.", 
                "num": 12
              }, 
              {
                "text": "On the very same day Noah and Noah's sons, Shem, Ham, and Japheth, and Noah's wife and the three wives of his sons with them, entered the ark--", 
                "num": 13
              }, 
              {
                "text": "they and every beast after its kind, all cattle after their kind, every creeping thing that creeps on the earth after its kind, and every bird after its kind, every bird of every sort.", 
                "num": 14
              }, 
              {
                "text": "And they went into the ark to Noah, two by two, of all flesh in which is the breath of life.", 
                "num": 15
              }, 
              {
                "text": "So those that entered, male and female of all flesh, went in as God had commanded him; and the LORD shut him in.", 
                "num": 16
              }, 
              {
                "text": "Now the flood was on the earth forty days. The waters increased and lifted up the ark, and it rose high above the earth.", 
                "num": 17
              }, 
              {
                "text": "The waters prevailed and greatly increased on the earth, and the ark moved about on the surface of the waters.", 
                "num": 18
              }, 
              {
                "text": "And the waters prevailed exceedingly on the earth, and all the high hills under the whole heaven were covered.", 
                "num": 19
              }, 
              {
                "text": "The waters prevailed fifteen cubits upward, and the mountains were covered.", 
                "num": 20
              }, 
              {
                "text": "And all flesh died that moved on the earth: birds and cattle and beasts and every creeping thing that creeps on the earth, and every man.", 
                "num": 21
              }, 
              {
                "text": "All in whose nostrils was the breath of the spirit of life, all that was on the dry land, died.", 
                "num": 22
              }, 
              {
                "text": "So He destroyed all living things which were on the face of the ground: both man and cattle, creeping thing and bird of the air. They were destroyed from the earth. Only Noah and those who were with him in the ark remained alive.", 
                "num": 23
              }, 
              {
                "text": "And the waters prevailed on the earth one hundred and fifty days.", 
                "num": 24
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Then God remembered Noah, and every living thing, and all the animals that were with him in the ark. And God made a wind to pass over the earth, and the waters subsided.", 
                "num": 1
              }, 
              {
                "text": "The fountains of the deep and the windows of heaven were also stopped, and the rain from heaven was restrained.", 
                "num": 2
              }, 
              {
                "text": "And the waters receded continually from the earth. At the end of the hundred and fifty days the waters decreased.", 
                "num": 3
              }, 
              {
                "text": "Then the ark rested in the seventh month, the seventeenth day of the month, on the mountains of Ararat.", 
                "num": 4
              }, 
              {
                "text": "And the waters decreased continually until the tenth month. In the tenth month, on the first day of the month, the tops of the mountains were seen.", 
                "num": 5
              }, 
              {
                "text": "So it came to pass, at the end of forty days, that Noah opened the window of the ark which he had made.", 
                "num": 6
              }, 
              {
                "text": "Then he sent out a raven, which kept going to and fro until the waters had dried up from the earth.", 
                "num": 7
              }, 
              {
                "text": "He also sent out from himself a dove, to see if the waters had receded from the face of the ground.", 
                "num": 8
              }, 
              {
                "text": "But the dove found no resting place for the sole of her foot, and she returned into the ark to him, for the waters were on the face of the whole earth. So he put out his hand and took her, and drew her into the ark to himself.", 
                "num": 9
              }, 
              {
                "text": "And he waited yet another seven days, and again he sent the dove out from the ark.", 
                "num": 10
              }, 
              {
                "text": "Then the dove came to him in the evening, and behold, a freshly plucked olive leaf was in her mouth; and Noah knew that the waters had receded from the earth.", 
                "num": 11
              }, 
              {
                "text": "So he waited yet another seven days and sent out the dove, which did not return again to him anymore.", 
                "num": 12
              }, 
              {
                "text": "And it came to pass in the six hundred and first year, in the first month, the first day of the month, that the waters were dried up from the earth; and Noah removed the covering of the ark and looked, and indeed the surface of the ground was dry.", 
                "num": 13
              }, 
              {
                "text": "And in the second month, on the twenty-seventh day of the month, the earth was dried.", 
                "num": 14
              }, 
              {
                "text": "Then God spoke to Noah, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Go out of the ark, you and your wife, and your sons and your sons' wives with you.", 
                "num": 16
              }, 
              {
                "text": "Bring out with you every living thing of all flesh that is with you: birds and cattle and every creeping thing that creeps on the earth, so that they may abound on the earth, and be fruitful and multiply on the earth.\"", 
                "num": 17
              }, 
              {
                "text": "So Noah went out, and his sons and his wife and his sons' wives with him.", 
                "num": 18
              }, 
              {
                "text": "Every animal, every creeping thing, every bird, and whatever creeps on the earth, according to their families, went out of the ark.", 
                "num": 19
              }, 
              {
                "text": "Then Noah built an altar to the LORD, and took of every clean animal and of every clean bird, and offered burnt offerings on the altar.", 
                "num": 20
              }, 
              {
                "text": "And the LORD smelled a soothing aroma. Then the LORD said in His heart, \"I will never again curse the ground for man's sake, although the imagination of man's heart is evil from his youth; nor will I again destroy every living thing as I have done.", 
                "num": 21
              }, 
              {
                "text": "\"While the earth remains, Seedtime and harvest, Cold and heat, Winter and summer, And day and night Shall not cease.\"", 
                "num": 22
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "So God blessed Noah and his sons, and said to them: \"Be fruitful and multiply, and fill the earth.", 
                "num": 1
              }, 
              {
                "text": "And the fear of you and the dread of you shall be on every beast of the earth, on every bird of the air, on all that move on the earth, and on all the fish of the sea. They are given into your hand.", 
                "num": 2
              }, 
              {
                "text": "Every moving thing that lives shall be food for you. I have given you all things, even as the green herbs.", 
                "num": 3
              }, 
              {
                "text": "But you shall not eat flesh with its life, that is, its blood.", 
                "num": 4
              }, 
              {
                "text": "Surely for your lifeblood I will demand a reckoning; from the hand of every beast I will require it, and from the hand of man. From the hand of every man's brother I will require the life of man.", 
                "num": 5
              }, 
              {
                "text": "\"Whoever sheds man's blood, By man his blood shall be shed; For in the image of God He made man.", 
                "num": 6
              }, 
              {
                "text": "And as for you, be fruitful and multiply; Bring forth abundantly in the earth And multiply in it.\"", 
                "num": 7
              }, 
              {
                "text": "Then God spoke to Noah and to his sons with him, saying:", 
                "num": 8
              }, 
              {
                "text": "\"And as for Me, behold, I establish My covenant with you and with your descendants after you,", 
                "num": 9
              }, 
              {
                "text": "and with every living creature that is with you: the birds, the cattle, and every beast of the earth with you, of all that go out of the ark, every beast of the earth.", 
                "num": 10
              }, 
              {
                "text": "Thus I establish My covenant with you: Never again shall all flesh be cut off by the waters of the flood; never again shall there be a flood to destroy the earth.\"", 
                "num": 11
              }, 
              {
                "text": "And God said: \"This is the sign of the covenant which I make between Me and you, and every living creature that is with you, for perpetual generations:", 
                "num": 12
              }, 
              {
                "text": "I set My rainbow in the cloud, and it shall be for the sign of the covenant between Me and the earth.", 
                "num": 13
              }, 
              {
                "text": "It shall be, when I bring a cloud over the earth, that the rainbow shall be seen in the cloud;", 
                "num": 14
              }, 
              {
                "text": "and I will remember My covenant which is between Me and you and every living creature of all flesh; the waters shall never again become a flood to destroy all flesh.", 
                "num": 15
              }, 
              {
                "text": "The rainbow shall be in the cloud, and I will look on it to remember the everlasting covenant between God and every living creature of all flesh that is on the earth.\"", 
                "num": 16
              }, 
              {
                "text": "And God said to Noah, \"This is the sign of the covenant which I have established between Me and all flesh that is on the earth.\"", 
                "num": 17
              }, 
              {
                "text": "Now the sons of Noah who went out of the ark were Shem, Ham, and Japheth. And Ham was the father of Canaan.", 
                "num": 18
              }, 
              {
                "text": "These three were the sons of Noah, and from these the whole earth was populated.", 
                "num": 19
              }, 
              {
                "text": "And Noah began to be a farmer, and he planted a vineyard.", 
                "num": 20
              }, 
              {
                "text": "Then he drank of the wine and was drunk, and became uncovered in his tent.", 
                "num": 21
              }, 
              {
                "text": "And Ham, the father of Canaan, saw the nakedness of his father, and told his two brothers outside.", 
                "num": 22
              }, 
              {
                "text": "But Shem and Japheth took a garment, laid it on both their shoulders, and went backward and covered the nakedness of their father. Their faces were turned away, and they did not see their father's nakedness.", 
                "num": 23
              }, 
              {
                "text": "So Noah awoke from his wine, and knew what his younger son had done to him.", 
                "num": 24
              }, 
              {
                "text": "Then he said: \"Cursed be Canaan; A servant of servants He shall be to his brethren.\"", 
                "num": 25
              }, 
              {
                "text": "And he said: \"Blessed be the LORD, The God of Shem, And may Canaan be his servant.", 
                "num": 26
              }, 
              {
                "text": "May God enlarge Japheth, And may he dwell in the tents of Shem; And may Canaan be his servant.\"", 
                "num": 27
              }, 
              {
                "text": "And Noah lived after the flood three hundred and fifty years.", 
                "num": 28
              }, 
              {
                "text": "So all the days of Noah were nine hundred and fifty years; and he died.", 
                "num": 29
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now this is the genealogy of the sons of Noah: Shem, Ham, and Japheth. And sons were born to them after the flood.", 
                "num": 1
              }, 
              {
                "text": "The sons of Japheth were Gomer, Magog, Madai, Javan, Tubal, Meshech, and Tiras.", 
                "num": 2
              }, 
              {
                "text": "The sons of Gomer were Ashkenaz, Riphath, and Togarmah.", 
                "num": 3
              }, 
              {
                "text": "The sons of Javan were Elishah, Tarshish, Kittim, and Dodanim.", 
                "num": 4
              }, 
              {
                "text": "From these the coastland peoples of the Gentiles were separated into their lands, everyone according to his language, according to their families, into their nations.", 
                "num": 5
              }, 
              {
                "text": "The sons of Ham were Cush, Mizraim, Put, and Canaan.", 
                "num": 6
              }, 
              {
                "text": "The sons of Cush were Seba, Havilah, Sabtah, Raamah, and Sabtechah; and the sons of Raamah were Sheba and Dedan.", 
                "num": 7
              }, 
              {
                "text": "Cush begot Nimrod; he began to be a mighty one on the earth.", 
                "num": 8
              }, 
              {
                "text": "He was a mighty hunter before the LORD; therefore it is said, \"Like Nimrod the mighty hunter before the LORD.\"", 
                "num": 9
              }, 
              {
                "text": "And the beginning of his kingdom was Babel, Erech, Accad, and Calneh, in the land of Shinar.", 
                "num": 10
              }, 
              {
                "text": "From that land he went to Assyria and built Nineveh, Rehoboth Ir, Calah,", 
                "num": 11
              }, 
              {
                "text": "and Resen between Nineveh and Calah (that is the principal city).", 
                "num": 12
              }, 
              {
                "text": "Mizraim begot Ludim, Anamim, Lehabim, Naphtuhim,", 
                "num": 13
              }, 
              {
                "text": "Pathrusim, and Casluhim (from whom came the Philistines and Caphtorim).", 
                "num": 14
              }, 
              {
                "text": "Canaan begot Sidon his firstborn, and Heth;", 
                "num": 15
              }, 
              {
                "text": "the Jebusite, the Amorite, and the Girgashite;", 
                "num": 16
              }, 
              {
                "text": "the Hivite, the Arkite, and the Sinite;", 
                "num": 17
              }, 
              {
                "text": "the Arvadite, the Zemarite, and the Hamathite. Afterward the families of the Canaanites were dispersed.", 
                "num": 18
              }, 
              {
                "text": "And the border of the Canaanites was from Sidon as you go toward Gerar, as far as Gaza; then as you go toward Sodom, Gomorrah, Admah, and Zeboiim, as far as Lasha.", 
                "num": 19
              }, 
              {
                "text": "These were the sons of Ham, according to their families, according to their languages, in their lands and in their nations.", 
                "num": 20
              }, 
              {
                "text": "And children were born also to Shem, the father of all the children of Eber, the brother of Japheth the elder.", 
                "num": 21
              }, 
              {
                "text": "The sons of Shem were Elam, Asshur, Arphaxad, Lud, and Aram.", 
                "num": 22
              }, 
              {
                "text": "The sons of Aram were Uz, Hul, Gether, and Mash.", 
                "num": 23
              }, 
              {
                "text": "Arphaxad begot Salah, and Salah begot Eber.", 
                "num": 24
              }, 
              {
                "text": "To Eber were born two sons: the name of one was Peleg, for in his days the earth was divided; and his brother's name was Joktan.", 
                "num": 25
              }, 
              {
                "text": "Joktan begot Almodad, Sheleph, Hazarmaveth, Jerah,", 
                "num": 26
              }, 
              {
                "text": "Hadoram, Uzal, Diklah,", 
                "num": 27
              }, 
              {
                "text": "Obal, Abimael, Sheba,", 
                "num": 28
              }, 
              {
                "text": "Ophir, Havilah, and Jobab. All these were the sons of Joktan.", 
                "num": 29
              }, 
              {
                "text": "And their dwelling place was from Mesha as you go toward Sephar, the mountain of the east.", 
                "num": 30
              }, 
              {
                "text": "These were the sons of Shem, according to their families, according to their languages, in their lands, according to their nations.", 
                "num": 31
              }, 
              {
                "text": "These were the families of the sons of Noah, according to their generations, in their nations; and from these the nations were divided on the earth after the flood.", 
                "num": 32
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now the whole earth had one language and one speech.", 
                "num": 1
              }, 
              {
                "text": "And it came to pass, as they journeyed from the east, that they found a plain in the land of Shinar, and they dwelt there.", 
                "num": 2
              }, 
              {
                "text": "Then they said to one another, \"Come, let us make bricks and bake them thoroughly.\" They had brick for stone, and they had asphalt for mortar.", 
                "num": 3
              }, 
              {
                "text": "And they said, \"Come, let us build ourselves a city, and a tower whose top is in the heavens; let us make a name for ourselves, lest we be scattered abroad over the face of the whole earth.\"", 
                "num": 4
              }, 
              {
                "text": "But the LORD came down to see the city and the tower which the sons of men had built.", 
                "num": 5
              }, 
              {
                "text": "And the LORD said, \"Indeed the people are one and they all have one language, and this is what they begin to do; now nothing that they propose to do will be withheld from them.", 
                "num": 6
              }, 
              {
                "text": "Come, let Us go down and there confuse their language, that they may not understand one another's speech.\"", 
                "num": 7
              }, 
              {
                "text": "So the LORD scattered them abroad from there over the face of all the earth, and they ceased building the city.", 
                "num": 8
              }, 
              {
                "text": "Therefore its name is called Babel, because there the LORD confused the language of all the earth; and from there the LORD scattered them abroad over the face of all the earth.", 
                "num": 9
              }, 
              {
                "text": "This is the genealogy of Shem: Shem was one hundred years old, and begot Arphaxad two years after the flood.", 
                "num": 10
              }, 
              {
                "text": "After he begot Arphaxad, Shem lived five hundred years, and begot sons and daughters.", 
                "num": 11
              }, 
              {
                "text": "Arphaxad lived thirty-five years, and begot Salah.", 
                "num": 12
              }, 
              {
                "text": "After he begot Salah, Arphaxad lived four hundred and three years, and begot sons and daughters.", 
                "num": 13
              }, 
              {
                "text": "Salah lived thirty years, and begot Eber.", 
                "num": 14
              }, 
              {
                "text": "After he begot Eber, Salah lived four hundred and three years, and begot sons and daughters.", 
                "num": 15
              }, 
              {
                "text": "Eber lived thirty-four years, and begot Peleg.", 
                "num": 16
              }, 
              {
                "text": "After he begot Peleg, Eber lived four hundred and thirty years, and begot sons and daughters.", 
                "num": 17
              }, 
              {
                "text": "Peleg lived thirty years, and begot Reu.", 
                "num": 18
              }, 
              {
                "text": "After he begot Reu, Peleg lived two hundred and nine years, and begot sons and daughters.", 
                "num": 19
              }, 
              {
                "text": "Reu lived thirty-two years, and begot Serug.", 
                "num": 20
              }, 
              {
                "text": "After he begot Serug, Reu lived two hundred and seven years, and begot sons and daughters.", 
                "num": 21
              }, 
              {
                "text": "Serug lived thirty years, and begot Nahor.", 
                "num": 22
              }, 
              {
                "text": "After he begot Nahor, Serug lived two hundred years, and begot sons and daughters.", 
                "num": 23
              }, 
              {
                "text": "Nahor lived twenty-nine years, and begot Terah.", 
                "num": 24
              }, 
              {
                "text": "After he begot Terah, Nahor lived one hundred and nineteen years, and begot sons and daughters.", 
                "num": 25
              }, 
              {
                "text": "Now Terah lived seventy years, and begot Abram, Nahor, and Haran.", 
                "num": 26
              }, 
              {
                "text": "This is the genealogy of Terah: Terah begot Abram, Nahor, and Haran. Haran begot Lot.", 
                "num": 27
              }, 
              {
                "text": "And Haran died before his father Terah in his native land, in Ur of the Chaldeans.", 
                "num": 28
              }, 
              {
                "text": "Then Abram and Nahor took wives: the name of Abram's wife was Sarai, and the name of Nahor's wife, Milcah, the daughter of Haran the father of Milcah and the father of Iscah.", 
                "num": 29
              }, 
              {
                "text": "But Sarai was barren; she had no child.", 
                "num": 30
              }, 
              {
                "text": "And Terah took his son Abram and his grandson Lot, the son of Haran, and his daughter-in-law Sarai, his son Abram's wife, and they went out with them from Ur of the Chaldeans to go to the land of Canaan; and they came to Haran and dwelt there.", 
                "num": 31
              }, 
              {
                "text": "So the days of Terah were two hundred and five years, and Terah died in Haran.", 
                "num": 32
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD had said to Abram: \"Get out of your country, From your family And from your father's house, To a land that I will show you.", 
                "num": 1
              }, 
              {
                "text": "I will make you a great nation; I will bless you And make your name great; And you shall be a blessing.", 
                "num": 2
              }, 
              {
                "text": "I will bless those who bless you, And I will curse him who curses you; And in you all the families of the earth shall be blessed.\"", 
                "num": 3
              }, 
              {
                "text": "So Abram departed as the LORD had spoken to him, and Lot went with him. And Abram was seventy-five years old when he departed from Haran.", 
                "num": 4
              }, 
              {
                "text": "Then Abram took Sarai his wife and Lot his brother's son, and all their possessions that they had gathered, and the people whom they had acquired in Haran, and they departed to go to the land of Canaan. So they came to the land of Canaan.", 
                "num": 5
              }, 
              {
                "text": "Abram passed through the land to the place of Shechem, as far as the terebinth tree of Moreh. And the Canaanites were then in the land.", 
                "num": 6
              }, 
              {
                "text": "Then the LORD appeared to Abram and said, \"To your descendants I will give this land.\" And there he built an altar to the LORD, who had appeared to him.", 
                "num": 7
              }, 
              {
                "text": "And he moved from there to the mountain east of Bethel, and he pitched his tent with Bethel on the west and Ai on the east; there he built an altar to the LORD and called on the name of the LORD.", 
                "num": 8
              }, 
              {
                "text": "So Abram journeyed, going on still toward the South.", 
                "num": 9
              }, 
              {
                "text": "Now there was a famine in the land, and Abram went down to Egypt to dwell there, for the famine was severe in the land.", 
                "num": 10
              }, 
              {
                "text": "And it came to pass, when he was close to entering Egypt, that he said to Sarai his wife, \"Indeed I know that you are a woman of beautiful countenance.", 
                "num": 11
              }, 
              {
                "text": "Therefore it will happen, when the Egyptians see you, that they will say, \"This is his wife'; and they will kill me, but they will let you live.", 
                "num": 12
              }, 
              {
                "text": "Please say you are my sister, that it may be well with me for your sake, and that I may live because of you.\"", 
                "num": 13
              }, 
              {
                "text": "So it was, when Abram came into Egypt, that the Egyptians saw the woman, that she was very beautiful.", 
                "num": 14
              }, 
              {
                "text": "The princes of Pharaoh also saw her and commended her to Pharaoh. And the woman was taken to Pharaoh's house.", 
                "num": 15
              }, 
              {
                "text": "He treated Abram well for her sake. He had sheep, oxen, male donkeys, male and female servants, female donkeys, and camels.", 
                "num": 16
              }, 
              {
                "text": "But the LORD plagued Pharaoh and his house with great plagues because of Sarai, Abram's wife.", 
                "num": 17
              }, 
              {
                "text": "And Pharaoh called Abram and said, \"What is this you have done to me? Why did you not tell me that she was your wife?", 
                "num": 18
              }, 
              {
                "text": "Why did you say, \"She is my sister'? I might have taken her as my wife. Now therefore, here is your wife; take her and go your way.\"", 
                "num": 19
              }, 
              {
                "text": "So Pharaoh commanded his men concerning him; and they sent him away, with his wife and all that he had.", 
                "num": 20
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Then Abram went up from Egypt, he and his wife and all that he had, and Lot with him, to the South.", 
                "num": 1
              }, 
              {
                "text": "Abram was very rich in livestock, in silver, and in gold.", 
                "num": 2
              }, 
              {
                "text": "And he went on his journey from the South as far as Bethel, to the place where his tent had been at the beginning, between Bethel and Ai,", 
                "num": 3
              }, 
              {
                "text": "to the place of the altar which he had made there at first. And there Abram called on the name of the LORD.", 
                "num": 4
              }, 
              {
                "text": "Lot also, who went with Abram, had flocks and herds and tents.", 
                "num": 5
              }, 
              {
                "text": "Now the land was not able to support them, that they might dwell together, for their possessions were so great that they could not dwell together.", 
                "num": 6
              }, 
              {
                "text": "And there was strife between the herdsmen of Abram's livestock and the herdsmen of Lot's livestock. The Canaanites and the Perizzites then dwelt in the land.", 
                "num": 7
              }, 
              {
                "text": "So Abram said to Lot, \"Please let there be no strife between you and me, and between my herdsmen and your herdsmen; for we are brethren.", 
                "num": 8
              }, 
              {
                "text": "Is not the whole land before you? Please separate from me. If you take the left, then I will go to the right; or, if you go to the right, then I will go to the left.\"", 
                "num": 9
              }, 
              {
                "text": "And Lot lifted his eyes and saw all the plain of Jordan, that it was well watered everywhere (before the LORD destroyed Sodom and Gomorrah) like the garden of the LORD, like the land of Egypt as you go toward Zoar.", 
                "num": 10
              }, 
              {
                "text": "Then Lot chose for himself all the plain of Jordan, and Lot journeyed east. And they separated from each other.", 
                "num": 11
              }, 
              {
                "text": "Abram dwelt in the land of Canaan, and Lot dwelt in the cities of the plain and pitched his tent even as far as Sodom.", 
                "num": 12
              }, 
              {
                "text": "But the men of Sodom were exceedingly wicked and sinful against the LORD.", 
                "num": 13
              }, 
              {
                "text": "And the LORD said to Abram, after Lot had separated from him: \"Lift your eyes now and look from the place where you are--northward, southward, eastward, and westward;", 
                "num": 14
              }, 
              {
                "text": "for all the land which you see I give to you and your descendants forever.", 
                "num": 15
              }, 
              {
                "text": "And I will make your descendants as the dust of the earth; so that if a man could number the dust of the earth, then your descendants also could be numbered.", 
                "num": 16
              }, 
              {
                "text": "Arise, walk in the land through its length and its width, for I give it to you.\"", 
                "num": 17
              }, 
              {
                "text": "Then Abram moved his tent, and went and dwelt by the terebinth trees of Mamre, which are in Hebron, and built an altar there to the LORD.", 
                "num": 18
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the days of Amraphel king of Shinar, Arioch king of Ellasar, Chedorlaomer king of Elam, and Tidal king of nations,", 
                "num": 1
              }, 
              {
                "text": "that they made war with Bera king of Sodom, Birsha king of Gomorrah, Shinab king of Admah, Shemeber king of Zeboiim, and the king of Bela (that is, Zoar).", 
                "num": 2
              }, 
              {
                "text": "All these joined together in the Valley of Siddim (that is, the Salt Sea).", 
                "num": 3
              }, 
              {
                "text": "Twelve years they served Chedorlaomer, and in the thirteenth year they rebelled.", 
                "num": 4
              }, 
              {
                "text": "In the fourteenth year Chedorlaomer and the kings that were with him came and attacked the Rephaim in Ashteroth Karnaim, the Zuzim in Ham, the Emim in Shaveh Kiriathaim,", 
                "num": 5
              }, 
              {
                "text": "and the Horites in their mountain of Seir, as far as El Paran, which is by the wilderness.", 
                "num": 6
              }, 
              {
                "text": "Then they turned back and came to En Mishpat (that is, Kadesh), and attacked all the country of the Amalekites, and also the Amorites who dwelt in Hazezon Tamar.", 
                "num": 7
              }, 
              {
                "text": "And the king of Sodom, the king of Gomorrah, the king of Admah, the king of Zeboiim, and the king of Bela (that is, Zoar) went out and joined together in battle in the Valley of Siddim", 
                "num": 8
              }, 
              {
                "text": "against Chedorlaomer king of Elam, Tidal king of nations, Amraphel king of Shinar, and Arioch king of Ellasar--four kings against five.", 
                "num": 9
              }, 
              {
                "text": "Now the Valley of Siddim was full of asphalt pits; and the kings of Sodom and Gomorrah fled; some fell there, and the remainder fled to the mountains.", 
                "num": 10
              }, 
              {
                "text": "Then they took all the goods of Sodom and Gomorrah, and all their provisions, and went their way.", 
                "num": 11
              }, 
              {
                "text": "They also took Lot, Abram's brother's son who dwelt in Sodom, and his goods, and departed.", 
                "num": 12
              }, 
              {
                "text": "Then one who had escaped came and told Abram the Hebrew, for he dwelt by the terebinth trees of Mamre the Amorite, brother of Eshcol and brother of Aner; and they were allies with Abram.", 
                "num": 13
              }, 
              {
                "text": "Now when Abram heard that his brother was taken captive, he armed his three hundred and eighteen trained servants who were born in his own house, and went in pursuit as far as Dan.", 
                "num": 14
              }, 
              {
                "text": "He divided his forces against them by night, and he and his servants attacked them and pursued them as far as Hobah, which is north of Damascus.", 
                "num": 15
              }, 
              {
                "text": "So he brought back all the goods, and also brought back his brother Lot and his goods, as well as the women and the people.", 
                "num": 16
              }, 
              {
                "text": "And the king of Sodom went out to meet him at the Valley of Shaveh (that is, the King's Valley), after his return from the defeat of Chedorlaomer and the kings who were with him.", 
                "num": 17
              }, 
              {
                "text": "Then Melchizedek king of Salem brought out bread and wine; he was the priest of God Most High.", 
                "num": 18
              }, 
              {
                "text": "And he blessed him and said: \"Blessed be Abram of God Most High, Possessor of heaven and earth;", 
                "num": 19
              }, 
              {
                "text": "And blessed be God Most High, Who has delivered your enemies into your hand.\" And he gave him a tithe of all.", 
                "num": 20
              }, 
              {
                "text": "Now the king of Sodom said to Abram, \"Give me the persons, and take the goods for yourself.\"", 
                "num": 21
              }, 
              {
                "text": "But Abram said to the king of Sodom, \"I have raised my hand to the LORD, God Most High, the Possessor of heaven and earth,", 
                "num": 22
              }, 
              {
                "text": "that I will take nothing, from a thread to a sandal strap, and that I will not take anything that is yours, lest you should say, \"I have made Abram rich'--", 
                "num": 23
              }, 
              {
                "text": "except only what the young men have eaten, and the portion of the men who went with me: Aner, Eshcol, and Mamre; let them take their portion.\"", 
                "num": 24
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "After these things the word of the LORD came to Abram in a vision, saying, \"Do not be afraid, Abram. I am your shield, your exceedingly great reward.\"", 
                "num": 1
              }, 
              {
                "text": "But Abram said, \"Lord GOD, what will You give me, seeing I go childless, and the heir of my house is Eliezer of Damascus?\"", 
                "num": 2
              }, 
              {
                "text": "Then Abram said, \"Look, You have given me no offspring; indeed one born in my house is my heir!\"", 
                "num": 3
              }, 
              {
                "text": "And behold, the word of the LORD came to him, saying, \"This one shall not be your heir, but one who will come from your own body shall be your heir.\"", 
                "num": 4
              }, 
              {
                "text": "Then He brought him outside and said, \"Look now toward heaven, and count the stars if you are able to number them.\" And He said to him, \"So shall your descendants be.\"", 
                "num": 5
              }, 
              {
                "text": "And he believed in the LORD, and He accounted it to him for righteousness.", 
                "num": 6
              }, 
              {
                "text": "Then He said to him, \"I am the LORD, who brought you out of Ur of the Chaldeans, to give you this land to inherit it.\"", 
                "num": 7
              }, 
              {
                "text": "And he said, \"Lord GOD, how shall I know that I will inherit it?\"", 
                "num": 8
              }, 
              {
                "text": "So He said to him, \"Bring Me a three-year-old heifer, a three-year-old female goat, a three-year-old ram, a turtledove, and a young pigeon.\"", 
                "num": 9
              }, 
              {
                "text": "Then he brought all these to Him and cut them in two, down the middle, and placed each piece opposite the other; but he did not cut the birds in two.", 
                "num": 10
              }, 
              {
                "text": "And when the vultures came down on the carcasses, Abram drove them away.", 
                "num": 11
              }, 
              {
                "text": "Now when the sun was going down, a deep sleep fell upon Abram; and behold, horror and great darkness fell upon him.", 
                "num": 12
              }, 
              {
                "text": "Then He said to Abram: \"Know certainly that your descendants will be strangers in a land that is not theirs, and will serve them, and they will afflict them four hundred years.", 
                "num": 13
              }, 
              {
                "text": "And also the nation whom they serve I will judge; afterward they shall come out with great possessions.", 
                "num": 14
              }, 
              {
                "text": "Now as for you, you shall go to your fathers in peace; you shall be buried at a good old age.", 
                "num": 15
              }, 
              {
                "text": "But in the fourth generation they shall return here, for the iniquity of the Amorites is not yet complete.\"", 
                "num": 16
              }, 
              {
                "text": "And it came to pass, when the sun went down and it was dark, that behold, there appeared a smoking oven and a burning torch that passed between those pieces.", 
                "num": 17
              }, 
              {
                "text": "On the same day the LORD made a covenant with Abram, saying: \"To your descendants I have given this land, from the river of Egypt to the great river, the River Euphrates--", 
                "num": 18
              }, 
              {
                "text": "the Kenites, the Kenezzites, the Kadmonites,", 
                "num": 19
              }, 
              {
                "text": "the Hittites, the Perizzites, the Rephaim,", 
                "num": 20
              }, 
              {
                "text": "the Amorites, the Canaanites, the Girgashites, and the Jebusites.\"", 
                "num": 21
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now Sarai, Abram's wife, had borne him no children. And she had an Egyptian maidservant whose name was Hagar.", 
                "num": 1
              }, 
              {
                "text": "So Sarai said to Abram, \"See now, the LORD has restrained me from bearing children. Please, go in to my maid; perhaps I shall obtain children by her.\" And Abram heeded the voice of Sarai.", 
                "num": 2
              }, 
              {
                "text": "Then Sarai, Abram's wife, took Hagar her maid, the Egyptian, and gave her to her husband Abram to be his wife, after Abram had dwelt ten years in the land of Canaan.", 
                "num": 3
              }, 
              {
                "text": "So he went in to Hagar, and she conceived. And when she saw that she had conceived, her mistress became despised in her eyes.", 
                "num": 4
              }, 
              {
                "text": "Then Sarai said to Abram, \"My wrong be upon you! I gave my maid into your embrace; and when she saw that she had conceived, I became despised in her eyes. The LORD judge between you and me.\"", 
                "num": 5
              }, 
              {
                "text": "So Abram said to Sarai, \"Indeed your maid is in your hand; do to her as you please.\" And when Sarai dealt harshly with her, she fled from her presence.", 
                "num": 6
              }, 
              {
                "text": "Now the Angel of the LORD found her by a spring of water in the wilderness, by the spring on the way to Shur.", 
                "num": 7
              }, 
              {
                "text": "And He said, \"Hagar, Sarai's maid, where have you come from, and where are you going?\" She said, \"I am fleeing from the presence of my mistress Sarai.\"", 
                "num": 8
              }, 
              {
                "text": "The Angel of the LORD said to her, \"Return to your mistress, and submit yourself under her hand.\"", 
                "num": 9
              }, 
              {
                "text": "Then the Angel of the LORD said to her, \"I will multiply your descendants exceedingly, so that they shall not be counted for multitude.\"", 
                "num": 10
              }, 
              {
                "text": "And the Angel of the LORD said to her: \"Behold, you are with child, And you shall bear a son. You shall call his name Ishmael, Because the LORD has heard your affliction.", 
                "num": 11
              }, 
              {
                "text": "He shall be a wild man; His hand shall be against every man, And every man's hand against him. And he shall dwell in the presence of all his brethren.\"", 
                "num": 12
              }, 
              {
                "text": "Then she called the name of the LORD who spoke to her, You-Are-the-God-Who-Sees; for she said, \"Have I also here seen Him who sees me?\"", 
                "num": 13
              }, 
              {
                "text": "Therefore the well was called Beer Lahai Roi; observe, it is between Kadesh and Bered.", 
                "num": 14
              }, 
              {
                "text": "So Hagar bore Abram a son; and Abram named his son, whom Hagar bore, Ishmael.", 
                "num": 15
              }, 
              {
                "text": "Abram was eighty-six years old when Hagar bore Ishmael to Abram.", 
                "num": 16
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "When Abram was ninety-nine years old, the LORD appeared to Abram and said to him, \"I am Almighty God; walk before Me and be blameless.", 
                "num": 1
              }, 
              {
                "text": "And I will make My covenant between Me and you, and will multiply you exceedingly.\"", 
                "num": 2
              }, 
              {
                "text": "Then Abram fell on his face, and God talked with him, saying:", 
                "num": 3
              }, 
              {
                "text": "\"As for Me, behold, My covenant is with you, and you shall be a father of many nations.", 
                "num": 4
              }, 
              {
                "text": "No longer shall your name be called Abram, but your name shall be Abraham; for I have made you a father of many nations.", 
                "num": 5
              }, 
              {
                "text": "I will make you exceedingly fruitful; and I will make nations of you, and kings shall come from you.", 
                "num": 6
              }, 
              {
                "text": "And I will establish My covenant between Me and you and your descendants after you in their generations, for an everlasting covenant, to be God to you and your descendants after you.", 
                "num": 7
              }, 
              {
                "text": "Also I give to you and your descendants after you the land in which you are a stranger, all the land of Canaan, as an everlasting possession; and I will be their God.\"", 
                "num": 8
              }, 
              {
                "text": "And God said to Abraham: \"As for you, you shall keep My covenant, you and your descendants after you throughout their generations.", 
                "num": 9
              }, 
              {
                "text": "This is My covenant which you shall keep, between Me and you and your descendants after you: Every male child among you shall be circumcised;", 
                "num": 10
              }, 
              {
                "text": "and you shall be circumcised in the flesh of your foreskins, and it shall be a sign of the covenant between Me and you.", 
                "num": 11
              }, 
              {
                "text": "He who is eight days old among you shall be circumcised, every male child in your generations, he who is born in your house or bought with money from any foreigner who is not your descendant.", 
                "num": 12
              }, 
              {
                "text": "He who is born in your house and he who is bought with your money must be circumcised, and My covenant shall be in your flesh for an everlasting covenant.", 
                "num": 13
              }, 
              {
                "text": "And the uncircumcised male child, who is not circumcised in the flesh of his foreskin, that person shall be cut off from his people; he has broken My covenant.\"", 
                "num": 14
              }, 
              {
                "text": "Then God said to Abraham, \"As for Sarai your wife, you shall not call her name Sarai, but Sarah shall be her name.", 
                "num": 15
              }, 
              {
                "text": "And I will bless her and also give you a son by her; then I will bless her, and she shall be a mother of nations; kings of peoples shall be from her.\"", 
                "num": 16
              }, 
              {
                "text": "Then Abraham fell on his face and laughed, and said in his heart, \"Shall a child be born to a man who is one hundred years old? And shall Sarah, who is ninety years old, bear a child?\"", 
                "num": 17
              }, 
              {
                "text": "And Abraham said to God, \"Oh, that Ishmael might live before You!\"", 
                "num": 18
              }, 
              {
                "text": "Then God said: \"No, Sarah your wife shall bear you a son, and you shall call his name Isaac; I will establish My covenant with him for an everlasting covenant, and with his descendants after him.", 
                "num": 19
              }, 
              {
                "text": "And as for Ishmael, I have heard you. Behold, I have blessed him, and will make him fruitful, and will multiply him exceedingly. He shall beget twelve princes, and I will make him a great nation.", 
                "num": 20
              }, 
              {
                "text": "But My covenant I will establish with Isaac, whom Sarah shall bear to you at this set time next year.\"", 
                "num": 21
              }, 
              {
                "text": "Then He finished talking with him, and God went up from Abraham.", 
                "num": 22
              }, 
              {
                "text": "So Abraham took Ishmael his son, all who were born in his house and all who were bought with his money, every male among the men of Abraham's house, and circumcised the flesh of their foreskins that very same day, as God had said to him.", 
                "num": 23
              }, 
              {
                "text": "Abraham was ninety-nine years old when he was circumcised in the flesh of his foreskin.", 
                "num": 24
              }, 
              {
                "text": "And Ishmael his son was thirteen years old when he was circumcised in the flesh of his foreskin.", 
                "num": 25
              }, 
              {
                "text": "That very same day Abraham was circumcised, and his son Ishmael;", 
                "num": 26
              }, 
              {
                "text": "and all the men of his house, born in the house or bought with money from a foreigner, were circumcised with him.", 
                "num": 27
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD appeared to him by the terebinth trees of Mamre, as he was sitting in the tent door in the heat of the day.", 
                "num": 1
              }, 
              {
                "text": "So he lifted his eyes and looked, and behold, three men were standing by him; and when he saw them, he ran from the tent door to meet them, and bowed himself to the ground,", 
                "num": 2
              }, 
              {
                "text": "and said, \"My Lord, if I have now found favor in Your sight, do not pass on by Your servant.", 
                "num": 3
              }, 
              {
                "text": "Please let a little water be brought, and wash your feet, and rest yourselves under the tree.", 
                "num": 4
              }, 
              {
                "text": "And I will bring a morsel of bread, that you may refresh your hearts. After that you may pass by, inasmuch as you have come to your servant.\" They said, \"Do as you have said.\"", 
                "num": 5
              }, 
              {
                "text": "So Abraham hurried into the tent to Sarah and said, \"Quickly, make ready three measures of fine meal; knead it and make cakes.\"", 
                "num": 6
              }, 
              {
                "text": "And Abraham ran to the herd, took a tender and good calf, gave it to a young man, and he hastened to prepare it.", 
                "num": 7
              }, 
              {
                "text": "So he took butter and milk and the calf which he had prepared, and set it before them; and he stood by them under the tree as they ate.", 
                "num": 8
              }, 
              {
                "text": "Then they said to him, \"Where is Sarah your wife?\" So he said, \"Here, in the tent.\"", 
                "num": 9
              }, 
              {
                "text": "And He said, \"I will certainly return to you according to the time of life, and behold, Sarah your wife shall have a son.\" (Sarah was listening in the tent door which was behind him.)", 
                "num": 10
              }, 
              {
                "text": "Now Abraham and Sarah were old, well advanced in age; and Sarah had passed the age of childbearing.", 
                "num": 11
              }, 
              {
                "text": "Therefore Sarah laughed within herself, saying, \"After I have grown old, shall I have pleasure, my lord being old also?\"", 
                "num": 12
              }, 
              {
                "text": "And the LORD said to Abraham, \"Why did Sarah laugh, saying, \"Shall I surely bear a child, since I am old?'", 
                "num": 13
              }, 
              {
                "text": "Is anything too hard for the LORD? At the appointed time I will return to you, according to the time of life, and Sarah shall have a son.\"", 
                "num": 14
              }, 
              {
                "text": "But Sarah denied it, saying, \"I did not laugh,\" for she was afraid. And He said, \"No, but you did laugh!\"", 
                "num": 15
              }, 
              {
                "text": "Then the men rose from there and looked toward Sodom, and Abraham went with them to send them on the way.", 
                "num": 16
              }, 
              {
                "text": "And the LORD said, \"Shall I hide from Abraham what I am doing,", 
                "num": 17
              }, 
              {
                "text": "since Abraham shall surely become a great and mighty nation, and all the nations of the earth shall be blessed in him?", 
                "num": 18
              }, 
              {
                "text": "For I have known him, in order that he may command his children and his household after him, that they keep the way of the LORD, to do righteousness and justice, that the LORD may bring to Abraham what He has spoken to him.\"", 
                "num": 19
              }, 
              {
                "text": "And the LORD said, \"Because the outcry against Sodom and Gomorrah is great, and because their sin is very grave,", 
                "num": 20
              }, 
              {
                "text": "I will go down now and see whether they have done altogether according to the outcry against it that has come to Me; and if not, I will know.\"", 
                "num": 21
              }, 
              {
                "text": "Then the men turned away from there and went toward Sodom, but Abraham still stood before the LORD.", 
                "num": 22
              }, 
              {
                "text": "And Abraham came near and said, \"Would You also destroy the righteous with the wicked?", 
                "num": 23
              }, 
              {
                "text": "Suppose there were fifty righteous within the city; would You also destroy the place and not spare it for the fifty righteous that were in it?", 
                "num": 24
              }, 
              {
                "text": "Far be it from You to do such a thing as this, to slay the righteous with the wicked, so that the righteous should be as the wicked; far be it from You! Shall not the Judge of all the earth do right?\"", 
                "num": 25
              }, 
              {
                "text": "So the LORD said, \"If I find in Sodom fifty righteous within the city, then I will spare all the place for their sakes.\"", 
                "num": 26
              }, 
              {
                "text": "Then Abraham answered and said, \"Indeed now, I who am but dust and ashes have taken it upon myself to speak to the Lord:", 
                "num": 27
              }, 
              {
                "text": "Suppose there were five less than the fifty righteous; would You destroy all of the city for lack of five?\" So He said, \"If I find there forty-five, I will not destroy it.\"", 
                "num": 28
              }, 
              {
                "text": "And he spoke to Him yet again and said, \"Suppose there should be forty found there?\" So He said, \"I will not do it for the sake of forty.\"", 
                "num": 29
              }, 
              {
                "text": "Then he said, \"Let not the Lord be angry, and I will speak: Suppose thirty should be found there?\" So He said, \"I will not do it if I find thirty there.\"", 
                "num": 30
              }, 
              {
                "text": "And he said, \"Indeed now, I have taken it upon myself to speak to the Lord: Suppose twenty should be found there?\" So He said, \"I will not destroy it for the sake of twenty.\"", 
                "num": 31
              }, 
              {
                "text": "Then he said, \"Let not the Lord be angry, and I will speak but once more: Suppose ten should be found there?\" And He said, \"I will not destroy it for the sake of ten.\"", 
                "num": 32
              }, 
              {
                "text": "So the LORD went His way as soon as He had finished speaking with Abraham; and Abraham returned to his place.", 
                "num": 33
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Now the two angels came to Sodom in the evening, and Lot was sitting in the gate of Sodom. When Lot saw them, he rose to meet them, and he bowed himself with his face toward the ground.", 
                "num": 1
              }, 
              {
                "text": "And he said, \"Here now, my lords, please turn in to your servant's house and spend the night, and wash your feet; then you may rise early and go on your way.\" And they said, \"No, but we will spend the night in the open square.\"", 
                "num": 2
              }, 
              {
                "text": "But he insisted strongly; so they turned in to him and entered his house. Then he made them a feast, and baked unleavened bread, and they ate.", 
                "num": 3
              }, 
              {
                "text": "Now before they lay down, the men of the city, the men of Sodom, both old and young, all the people from every quarter, surrounded the house.", 
                "num": 4
              }, 
              {
                "text": "And they called to Lot and said to him, \"Where are the men who came to you tonight? Bring them out to us that we may know them carnally.\"", 
                "num": 5
              }, 
              {
                "text": "So Lot went out to them through the doorway, shut the door behind him,", 
                "num": 6
              }, 
              {
                "text": "and said, \"Please, my brethren, do not do so wickedly!", 
                "num": 7
              }, 
              {
                "text": "See now, I have two daughters who have not known a man; please, let me bring them out to you, and you may do to them as you wish; only do nothing to these men, since this is the reason they have come under the shadow of my roof.\"", 
                "num": 8
              }, 
              {
                "text": "And they said, \"Stand back!\" Then they said, \"This one came in to stay here, and he keeps acting as a judge; now we will deal worse with you than with them.\" So they pressed hard against the man Lot, and came near to break down the door.", 
                "num": 9
              }, 
              {
                "text": "But the men reached out their hands and pulled Lot into the house with them, and shut the door.", 
                "num": 10
              }, 
              {
                "text": "And they struck the men who were at the doorway of the house with blindness, both small and great, so that they became weary trying to find the door.", 
                "num": 11
              }, 
              {
                "text": "Then the men said to Lot, \"Have you anyone else here? Son-in-law, your sons, your daughters, and whomever you have in the city--take them out of this place!", 
                "num": 12
              }, 
              {
                "text": "For we will destroy this place, because the outcry against them has grown great before the face of the LORD, and the LORD has sent us to destroy it.\"", 
                "num": 13
              }, 
              {
                "text": "So Lot went out and spoke to his sons-in-law, who had married his daughters, and said, \"Get up, get out of this place; for the LORD will destroy this city!\" But to his sons-in-law he seemed to be joking.", 
                "num": 14
              }, 
              {
                "text": "When the morning dawned, the angels urged Lot to hurry, saying, \"Arise, take your wife and your two daughters who are here, lest you be consumed in the punishment of the city.\"", 
                "num": 15
              }, 
              {
                "text": "And while he lingered, the men took hold of his hand, his wife's hand, and the hands of his two daughters, the LORD being merciful to him, and they brought him out and set him outside the city.", 
                "num": 16
              }, 
              {
                "text": "So it came to pass, when they had brought them outside, that he said, \"Escape for your life! Do not look behind you nor stay anywhere in the plain. Escape to the mountains, lest you be destroyed.\"", 
                "num": 17
              }, 
              {
                "text": "Then Lot said to them, \"Please, no, my lords!", 
                "num": 18
              }, 
              {
                "text": "Indeed now, your servant has found favor in your sight, and you have increased your mercy which you have shown me by saving my life; but I cannot escape to the mountains, lest some evil overtake me and I die.", 
                "num": 19
              }, 
              {
                "text": "See now, this city is near enough to flee to, and it is a little one; please let me escape there (is it not a little one?) and my soul shall live.\"", 
                "num": 20
              }, 
              {
                "text": "And he said to him, \"See, I have favored you concerning this thing also, in that I will not overthrow this city for which you have spoken.", 
                "num": 21
              }, 
              {
                "text": "Hurry, escape there. For I cannot do anything until you arrive there.\" Therefore the name of the city was called Zoar.", 
                "num": 22
              }, 
              {
                "text": "The sun had risen upon the earth when Lot entered Zoar.", 
                "num": 23
              }, 
              {
                "text": "Then the LORD rained brimstone and fire on Sodom and Gomorrah, from the LORD out of the heavens.", 
                "num": 24
              }, 
              {
                "text": "So He overthrew those cities, all the plain, all the inhabitants of the cities, and what grew on the ground.", 
                "num": 25
              }, 
              {
                "text": "But his wife looked back behind him, and she became a pillar of salt.", 
                "num": 26
              }, 
              {
                "text": "And Abraham went early in the morning to the place where he had stood before the LORD.", 
                "num": 27
              }, 
              {
                "text": "Then he looked toward Sodom and Gomorrah, and toward all the land of the plain; and he saw, and behold, the smoke of the land which went up like the smoke of a furnace.", 
                "num": 28
              }, 
              {
                "text": "And it came to pass, when God destroyed the cities of the plain, that God remembered Abraham, and sent Lot out of the midst of the overthrow, when He overthrew the cities in which Lot had dwelt.", 
                "num": 29
              }, 
              {
                "text": "Then Lot went up out of Zoar and dwelt in the mountains, and his two daughters were with him; for he was afraid to dwell in Zoar. And he and his two daughters dwelt in a cave.", 
                "num": 30
              }, 
              {
                "text": "Now the firstborn said to the younger, \"Our father is old, and there is no man on the earth to come in to us as is the custom of all the earth.", 
                "num": 31
              }, 
              {
                "text": "Come, let us make our father drink wine, and we will lie with him, that we may preserve the lineage of our father.\"", 
                "num": 32
              }, 
              {
                "text": "So they made their father drink wine that night. And the firstborn went in and lay with her father, and he did not know when she lay down or when she arose.", 
                "num": 33
              }, 
              {
                "text": "It happened on the next day that the firstborn said to the younger, \"Indeed I lay with my father last night; let us make him drink wine tonight also, and you go in and lie with him, that we may preserve the lineage of our father.\"", 
                "num": 34
              }, 
              {
                "text": "Then they made their father drink wine that night also. And the younger arose and lay with him, and he did not know when she lay down or when she arose.", 
                "num": 35
              }, 
              {
                "text": "Thus both the daughters of Lot were with child by their father.", 
                "num": 36
              }, 
              {
                "text": "The firstborn bore a son and called his name Moab; he is the father of the Moabites to this day.", 
                "num": 37
              }, 
              {
                "text": "And the younger, she also bore a son and called his name Ben-Ammi; he is the father of the people of Ammon to this day.", 
                "num": 38
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "And Abraham journeyed from there to the South, and dwelt between Kadesh and Shur, and stayed in Gerar.", 
                "num": 1
              }, 
              {
                "text": "Now Abraham said of Sarah his wife, \"She is my sister.\" And Abimelech king of Gerar sent and took Sarah.", 
                "num": 2
              }, 
              {
                "text": "But God came to Abimelech in a dream by night, and said to him, \"Indeed you are a dead man because of the woman whom you have taken, for she is a man's wife.\"", 
                "num": 3
              }, 
              {
                "text": "But Abimelech had not come near her; and he said, \"Lord, will You slay a righteous nation also?", 
                "num": 4
              }, 
              {
                "text": "Did he not say to me, \"She is my sister'? And she, even she herself said, \"He is my brother.' In the integrity of my heart and innocence of my hands I have done this.\"", 
                "num": 5
              }, 
              {
                "text": "And God said to him in a dream, \"Yes, I know that you did this in the integrity of your heart. For I also withheld you from sinning against Me; therefore I did not let you touch her.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, restore the man's wife; for he is a prophet, and he will pray for you and you shall live. But if you do not restore her, know that you shall surely die, you and all who are yours.\"", 
                "num": 7
              }, 
              {
                "text": "So Abimelech rose early in the morning, called all his servants, and told all these things in their hearing; and the men were very much afraid.", 
                "num": 8
              }, 
              {
                "text": "And Abimelech called Abraham and said to him, \"What have you done to us? How have I offended you, that you have brought on me and on my kingdom a great sin? You have done deeds to me that ought not to be done.\"", 
                "num": 9
              }, 
              {
                "text": "Then Abimelech said to Abraham, \"What did you have in view, that you have done this thing?\"", 
                "num": 10
              }, 
              {
                "text": "And Abraham said, \"Because I thought, surely the fear of God is not in this place; and they will kill me on account of my wife.", 
                "num": 11
              }, 
              {
                "text": "But indeed she is truly my sister. She is the daughter of my father, but not the daughter of my mother; and she became my wife.", 
                "num": 12
              }, 
              {
                "text": "And it came to pass, when God caused me to wander from my father's house, that I said to her, \"This is your kindness that you should do for me: in every place, wherever we go, say of me, \"He is my brother.\"\"'", 
                "num": 13
              }, 
              {
                "text": "Then Abimelech took sheep, oxen, and male and female servants, and gave them to Abraham; and he restored Sarah his wife to him.", 
                "num": 14
              }, 
              {
                "text": "And Abimelech said, \"See, my land is before you; dwell where it pleases you.\"", 
                "num": 15
              }, 
              {
                "text": "Then to Sarah he said, \"Behold, I have given your brother a thousand pieces of silver; indeed this vindicates you before all who are with you and before everybody.\" Thus she was rebuked.", 
                "num": 16
              }, 
              {
                "text": "So Abraham prayed to God; and God healed Abimelech, his wife, and his female servants. Then they bore children;", 
                "num": 17
              }, 
              {
                "text": "for the LORD had closed up all the wombs of the house of Abimelech because of Sarah, Abraham's wife.", 
                "num": 18
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And the LORD visited Sarah as He had said, and the LORD did for Sarah as He had spoken.", 
                "num": 1
              }, 
              {
                "text": "For Sarah conceived and bore Abraham a son in his old age, at the set time of which God had spoken to him.", 
                "num": 2
              }, 
              {
                "text": "And Abraham called the name of his son who was born to him--whom Sarah bore to him--Isaac.", 
                "num": 3
              }, 
              {
                "text": "Then Abraham circumcised his son Isaac when he was eight days old, as God had commanded him.", 
                "num": 4
              }, 
              {
                "text": "Now Abraham was one hundred years old when his son Isaac was born to him.", 
                "num": 5
              }, 
              {
                "text": "And Sarah said, \"God has made me laugh, and all who hear will laugh with me.\"", 
                "num": 6
              }, 
              {
                "text": "She also said, \"Who would have said to Abraham that Sarah would nurse children? For I have borne him a son in his old age.\"", 
                "num": 7
              }, 
              {
                "text": "So the child grew and was weaned. And Abraham made a great feast on the same day that Isaac was weaned.", 
                "num": 8
              }, 
              {
                "text": "And Sarah saw the son of Hagar the Egyptian, whom she had borne to Abraham, scoffing.", 
                "num": 9
              }, 
              {
                "text": "Therefore she said to Abraham, \"Cast out this bondwoman and her son; for the son of this bondwoman shall not be heir with my son, namely with Isaac.\"", 
                "num": 10
              }, 
              {
                "text": "And the matter was very displeasing in Abraham's sight because of his son.", 
                "num": 11
              }, 
              {
                "text": "But God said to Abraham, \"Do not let it be displeasing in your sight because of the lad or because of your bondwoman. Whatever Sarah has said to you, listen to her voice; for in Isaac your seed shall be called.", 
                "num": 12
              }, 
              {
                "text": "Yet I will also make a nation of the son of the bondwoman, because he is your seed.\"", 
                "num": 13
              }, 
              {
                "text": "So Abraham rose early in the morning, and took bread and a skin of water; and putting it on her shoulder, he gave it and the boy to Hagar, and sent her away. Then she departed and wandered in the Wilderness of Beersheba.", 
                "num": 14
              }, 
              {
                "text": "And the water in the skin was used up, and she placed the boy under one of the shrubs.", 
                "num": 15
              }, 
              {
                "text": "Then she went and sat down across from him at a distance of about a bowshot; for she said to herself, \"Let me not see the death of the boy.\" So she sat opposite him, and lifted her voice and wept.", 
                "num": 16
              }, 
              {
                "text": "And God heard the voice of the lad. Then the angel of God called to Hagar out of heaven, and said to her, \"What ails you, Hagar? Fear not, for God has heard the voice of the lad where he is.", 
                "num": 17
              }, 
              {
                "text": "Arise, lift up the lad and hold him with your hand, for I will make him a great nation.\"", 
                "num": 18
              }, 
              {
                "text": "Then God opened her eyes, and she saw a well of water. And she went and filled the skin with water, and gave the lad a drink.", 
                "num": 19
              }, 
              {
                "text": "So God was with the lad; and he grew and dwelt in the wilderness, and became an archer.", 
                "num": 20
              }, 
              {
                "text": "He dwelt in the Wilderness of Paran; and his mother took a wife for him from the land of Egypt.", 
                "num": 21
              }, 
              {
                "text": "And it came to pass at that time that Abimelech and Phichol, the commander of his army, spoke to Abraham, saying, \"God is with you in all that you do.", 
                "num": 22
              }, 
              {
                "text": "Now therefore, swear to me by God that you will not deal falsely with me, with my offspring, or with my posterity; but that according to the kindness that I have done to you, you will do to me and to the land in which you have dwelt.\"", 
                "num": 23
              }, 
              {
                "text": "And Abraham said, \"I will swear.\"", 
                "num": 24
              }, 
              {
                "text": "Then Abraham rebuked Abimelech because of a well of water which Abimelech's servants had seized.", 
                "num": 25
              }, 
              {
                "text": "And Abimelech said, \"I do not know who has done this thing; you did not tell me, nor had I heard of it until today.\"", 
                "num": 26
              }, 
              {
                "text": "So Abraham took sheep and oxen and gave them to Abimelech, and the two of them made a covenant.", 
                "num": 27
              }, 
              {
                "text": "And Abraham set seven ewe lambs of the flock by themselves.", 
                "num": 28
              }, 
              {
                "text": "Then Abimelech asked Abraham, \"What is the meaning of these seven ewe lambs which you have set by themselves?\"", 
                "num": 29
              }, 
              {
                "text": "And he said, \"You will take these seven ewe lambs from my hand, that they may be my witness that I have dug this well.\"", 
                "num": 30
              }, 
              {
                "text": "Therefore he called that place Beersheba, because the two of them swore an oath there.", 
                "num": 31
              }, 
              {
                "text": "Thus they made a covenant at Beersheba. So Abimelech rose with Phichol, the commander of his army, and they returned to the land of the Philistines.", 
                "num": 32
              }, 
              {
                "text": "Then Abraham planted a tamarisk tree in Beersheba, and there called on the name of the LORD, the Everlasting God.", 
                "num": 33
              }, 
              {
                "text": "And Abraham stayed in the land of the Philistines many days.", 
                "num": 34
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass after these things that God tested Abraham, and said to him, \"Abraham!\" And he said, \"Here I am.\"", 
                "num": 1
              }, 
              {
                "text": "Then He said, \"Take now your son, your only son Isaac, whom you love, and go to the land of Moriah, and offer him there as a burnt offering on one of the mountains of which I shall tell you.\"", 
                "num": 2
              }, 
              {
                "text": "So Abraham rose early in the morning and saddled his donkey, and took two of his young men with him, and Isaac his son; and he split the wood for the burnt offering, and arose and went to the place of which God had told him.", 
                "num": 3
              }, 
              {
                "text": "Then on the third day Abraham lifted his eyes and saw the place afar off.", 
                "num": 4
              }, 
              {
                "text": "And Abraham said to his young men, \"Stay here with the donkey; the lad and I will go yonder and worship, and we will come back to you.\"", 
                "num": 5
              }, 
              {
                "text": "So Abraham took the wood of the burnt offering and laid it on Isaac his son; and he took the fire in his hand, and a knife, and the two of them went together.", 
                "num": 6
              }, 
              {
                "text": "But Isaac spoke to Abraham his father and said, \"My father!\" And he said, \"Here I am, my son.\" Then he said, \"Look, the fire and the wood, but where is the lamb for a burnt offering?\"", 
                "num": 7
              }, 
              {
                "text": "And Abraham said, \"My son, God will provide for Himself the lamb for a burnt offering.\" So the two of them went together.", 
                "num": 8
              }, 
              {
                "text": "Then they came to the place of which God had told him. And Abraham built an altar there and placed the wood in order; and he bound Isaac his son and laid him on the altar, upon the wood.", 
                "num": 9
              }, 
              {
                "text": "And Abraham stretched out his hand and took the knife to slay his son.", 
                "num": 10
              }, 
              {
                "text": "But the Angel of the LORD called to him from heaven and said, \"Abraham, Abraham!\" So he said, \"Here I am.\"", 
                "num": 11
              }, 
              {
                "text": "And He said, \"Do not lay your hand on the lad, or do anything to him; for now I know that you fear God, since you have not withheld your son, your only son, from Me.\"", 
                "num": 12
              }, 
              {
                "text": "Then Abraham lifted his eyes and looked, and there behind him was a ram caught in a thicket by its horns. So Abraham went and took the ram, and offered it up for a burnt offering instead of his son.", 
                "num": 13
              }, 
              {
                "text": "And Abraham called the name of the place, The-LORD-Will-Provide; as it is said to this day, \"In the Mount of the LORD it shall be provided.\"", 
                "num": 14
              }, 
              {
                "text": "Then the Angel of the LORD called to Abraham a second time out of heaven,", 
                "num": 15
              }, 
              {
                "text": "and said: \"By Myself I have sworn, says the LORD, because you have done this thing, and have not withheld your son, your only son--", 
                "num": 16
              }, 
              {
                "text": "blessing I will bless you, and multiplying I will multiply your descendants as the stars of the heaven and as the sand which is on the seashore; and your descendants shall possess the gate of their enemies.", 
                "num": 17
              }, 
              {
                "text": "In your seed all the nations of the earth shall be blessed, because you have obeyed My voice.\"", 
                "num": 18
              }, 
              {
                "text": "So Abraham returned to his young men, and they rose and went together to Beersheba; and Abraham dwelt at Beersheba.", 
                "num": 19
              }, 
              {
                "text": "Now it came to pass after these things that it was told Abraham, saying, \"Indeed Milcah also has borne children to your brother Nahor:", 
                "num": 20
              }, 
              {
                "text": "Huz his firstborn, Buz his brother, Kemuel the father of Aram,", 
                "num": 21
              }, 
              {
                "text": "Chesed, Hazo, Pildash, Jidlaph, and Bethuel.\"", 
                "num": 22
              }, 
              {
                "text": "And Bethuel begot Rebekah. These eight Milcah bore to Nahor, Abraham's brother.", 
                "num": 23
              }, 
              {
                "text": "His concubine, whose name was Reumah, also bore Tebah, Gaham, Thahash, and Maachah.", 
                "num": 24
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Sarah lived one hundred and twenty-seven years; these were the years of the life of Sarah.", 
                "num": 1
              }, 
              {
                "text": "So Sarah died in Kirjath Arba (that is, Hebron) in the land of Canaan, and Abraham came to mourn for Sarah and to weep for her.", 
                "num": 2
              }, 
              {
                "text": "Then Abraham stood up from before his dead, and spoke to the sons of Heth, saying,", 
                "num": 3
              }, 
              {
                "text": "\"I am a foreigner and a visitor among you. Give me property for a burial place among you, that I may bury my dead out of my sight.\"", 
                "num": 4
              }, 
              {
                "text": "And the sons of Heth answered Abraham, saying to him,", 
                "num": 5
              }, 
              {
                "text": "\"Hear us, my lord: You are a mighty prince among us; bury your dead in the choicest of our burial places. None of us will withhold from you his burial place, that you may bury your dead.\"", 
                "num": 6
              }, 
              {
                "text": "Then Abraham stood up and bowed himself to the people of the land, the sons of Heth.", 
                "num": 7
              }, 
              {
                "text": "And he spoke with them, saying, \"If it is your wish that I bury my dead out of my sight, hear me, and meet with Ephron the son of Zohar for me,", 
                "num": 8
              }, 
              {
                "text": "that he may give me the cave of Machpelah which he has, which is at the end of his field. Let him give it to me at the full price, as property for a burial place among you.\"", 
                "num": 9
              }, 
              {
                "text": "Now Ephron dwelt among the sons of Heth; and Ephron the Hittite answered Abraham in the presence of the sons of Heth, all who entered at the gate of his city, saying,", 
                "num": 10
              }, 
              {
                "text": "\"No, my lord, hear me: I give you the field and the cave that is in it; I give it to you in the presence of the sons of my people. I give it to you. Bury your dead!\"", 
                "num": 11
              }, 
              {
                "text": "Then Abraham bowed himself down before the people of the land;", 
                "num": 12
              }, 
              {
                "text": "and he spoke to Ephron in the hearing of the people of the land, saying, \"If you will give it, please hear me. I will give you money for the field; take it from me and I will bury my dead there.\"", 
                "num": 13
              }, 
              {
                "text": "And Ephron answered Abraham, saying to him,", 
                "num": 14
              }, 
              {
                "text": "\"My lord, listen to me; the land is worth four hundred shekels of silver. What is that between you and me? So bury your dead.\"", 
                "num": 15
              }, 
              {
                "text": "And Abraham listened to Ephron; and Abraham weighed out the silver for Ephron which he had named in the hearing of the sons of Heth, four hundred shekels of silver, currency of the merchants.", 
                "num": 16
              }, 
              {
                "text": "So the field of Ephron which was in Machpelah, which was before Mamre, the field and the cave which was in it, and all the trees that were in the field, which were within all the surrounding borders, were deeded", 
                "num": 17
              }, 
              {
                "text": "to Abraham as a possession in the presence of the sons of Heth, before all who went in at the gate of his city.", 
                "num": 18
              }, 
              {
                "text": "And after this, Abraham buried Sarah his wife in the cave of the field of Machpelah, before Mamre (that is, Hebron) in the land of Canaan.", 
                "num": 19
              }, 
              {
                "text": "So the field and the cave that is in it were deeded to Abraham by the sons of Heth as property for a burial place.", 
                "num": 20
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now Abraham was old, well advanced in age; and the LORD had blessed Abraham in all things.", 
                "num": 1
              }, 
              {
                "text": "So Abraham said to the oldest servant of his house, who ruled over all that he had, \"Please, put your hand under my thigh,", 
                "num": 2
              }, 
              {
                "text": "and I will make you swear by the LORD, the God of heaven and the God of the earth, that you will not take a wife for my son from the daughters of the Canaanites, among whom I dwell;", 
                "num": 3
              }, 
              {
                "text": "but you shall go to my country and to my family, and take a wife for my son Isaac.\"", 
                "num": 4
              }, 
              {
                "text": "And the servant said to him, \"Perhaps the woman will not be willing to follow me to this land. Must I take your son back to the land from which you came?\"", 
                "num": 5
              }, 
              {
                "text": "But Abraham said to him, \"Beware that you do not take my son back there.", 
                "num": 6
              }, 
              {
                "text": "The LORD God of heaven, who took me from my father's house and from the land of my family, and who spoke to me and swore to me, saying, \"To your descendants I give this land,' He will send His angel before you, and you shall take a wife for my son from there.", 
                "num": 7
              }, 
              {
                "text": "And if the woman is not willing to follow you, then you will be released from this oath; only do not take my son back there.\"", 
                "num": 8
              }, 
              {
                "text": "So the servant put his hand under the thigh of Abraham his master, and swore to him concerning this matter.", 
                "num": 9
              }, 
              {
                "text": "Then the servant took ten of his master's camels and departed, for all his master's goods were in his hand. And he arose and went to Mesopotamia, to the city of Nahor.", 
                "num": 10
              }, 
              {
                "text": "And he made his camels kneel down outside the city by a well of water at evening time, the time when women go out to draw water.", 
                "num": 11
              }, 
              {
                "text": "Then he said, \"O LORD God of my master Abraham, please give me success this day, and show kindness to my master Abraham.", 
                "num": 12
              }, 
              {
                "text": "Behold, here I stand by the well of water, and the daughters of the men of the city are coming out to draw water.", 
                "num": 13
              }, 
              {
                "text": "Now let it be that the young woman to whom I say, \"Please let down your pitcher that I may drink,' and she says, \"Drink, and I will also give your camels a drink'--let her be the one You have appointed for Your servant Isaac. And by this I will know that You have shown kindness to my master.\"", 
                "num": 14
              }, 
              {
                "text": "And it happened, before he had finished speaking, that behold, Rebekah, who was born to Bethuel, son of Milcah, the wife of Nahor, Abraham's brother, came out with her pitcher on her shoulder.", 
                "num": 15
              }, 
              {
                "text": "Now the young woman was very beautiful to behold, a virgin; no man had known her. And she went down to the well, filled her pitcher, and came up.", 
                "num": 16
              }, 
              {
                "text": "And the servant ran to meet her and said, \"Please let me drink a little water from your pitcher.\"", 
                "num": 17
              }, 
              {
                "text": "So she said, \"Drink, my lord.\" Then she quickly let her pitcher down to her hand, and gave him a drink.", 
                "num": 18
              }, 
              {
                "text": "And when she had finished giving him a drink, she said, \"I will draw water for your camels also, until they have finished drinking.\"", 
                "num": 19
              }, 
              {
                "text": "Then she quickly emptied her pitcher into the trough, ran back to the well to draw water, and drew for all his camels.", 
                "num": 20
              }, 
              {
                "text": "And the man, wondering at her, remained silent so as to know whether the LORD had made his journey prosperous or not.", 
                "num": 21
              }, 
              {
                "text": "So it was, when the camels had finished drinking, that the man took a golden nose ring weighing half a shekel, and two bracelets for her wrists weighing ten shekels of gold,", 
                "num": 22
              }, 
              {
                "text": "and said, \"Whose daughter are you? Tell me, please, is there room in your father's house for us to lodge?\"", 
                "num": 23
              }, 
              {
                "text": "So she said to him, \"I am the daughter of Bethuel, Milcah's son, whom she bore to Nahor.\"", 
                "num": 24
              }, 
              {
                "text": "Moreover she said to him, \"We have both straw and feed enough, and room to lodge.\"", 
                "num": 25
              }, 
              {
                "text": "Then the man bowed down his head and worshiped the LORD.", 
                "num": 26
              }, 
              {
                "text": "And he said, \"Blessed be the LORD God of my master Abraham, who has not forsaken His mercy and His truth toward my master. As for me, being on the way, the LORD led me to the house of my master's brethren.\"", 
                "num": 27
              }, 
              {
                "text": "So the young woman ran and told her mother's household these things.", 
                "num": 28
              }, 
              {
                "text": "Now Rebekah had a brother whose name was Laban, and Laban ran out to the man by the well.", 
                "num": 29
              }, 
              {
                "text": "So it came to pass, when he saw the nose ring, and the bracelets on his sister's wrists, and when he heard the words of his sister Rebekah, saying, \"Thus the man spoke to me,\" that he went to the man. And there he stood by the camels at the well.", 
                "num": 30
              }, 
              {
                "text": "And he said, \"Come in, O blessed of the LORD! Why do you stand outside? For I have prepared the house, and a place for the camels.\"", 
                "num": 31
              }, 
              {
                "text": "Then the man came to the house. And he unloaded the camels, and provided straw and feed for the camels, and water to wash his feet and the feet of the men who were with him.", 
                "num": 32
              }, 
              {
                "text": "Food was set before him to eat, but he said, \"I will not eat until I have told about my errand.\" And he said, \"Speak on.\"", 
                "num": 33
              }, 
              {
                "text": "So he said, \"I am Abraham's servant.", 
                "num": 34
              }, 
              {
                "text": "The LORD has blessed my master greatly, and he has become great; and He has given him flocks and herds, silver and gold, male and female servants, and camels and donkeys.", 
                "num": 35
              }, 
              {
                "text": "And Sarah my master's wife bore a son to my master when she was old; and to him he has given all that he has.", 
                "num": 36
              }, 
              {
                "text": "Now my master made me swear, saying, \"You shall not take a wife for my son from the daughters of the Canaanites, in whose land I dwell;", 
                "num": 37
              }, 
              {
                "text": "but you shall go to my father's house and to my family, and take a wife for my son.'", 
                "num": 38
              }, 
              {
                "text": "And I said to my master, \"Perhaps the woman will not follow me.'", 
                "num": 39
              }, 
              {
                "text": "But he said to me, \"The LORD, before whom I walk, will send His angel with you and prosper your way; and you shall take a wife for my son from my family and from my father's house.", 
                "num": 40
              }, 
              {
                "text": "You will be clear from this oath when you arrive among my family; for if they will not give her to you, then you will be released from my oath.'", 
                "num": 41
              }, 
              {
                "text": "\"And this day I came to the well and said, \"O LORD God of my master Abraham, if You will now prosper the way in which I go,", 
                "num": 42
              }, 
              {
                "text": "behold, I stand by the well of water; and it shall come to pass that when the virgin comes out to draw water, and I say to her, \"Please give me a little water from your pitcher to drink,\"", 
                "num": 43
              }, 
              {
                "text": "and she says to me, \"Drink, and I will draw for your camels also,\"--let her be the woman whom the LORD has appointed for my master's son.'", 
                "num": 44
              }, 
              {
                "text": "\"But before I had finished speaking in my heart, there was Rebekah, coming out with her pitcher on her shoulder; and she went down to the well and drew water. And I said to her, \"Please let me drink.'", 
                "num": 45
              }, 
              {
                "text": "And she made haste and let her pitcher down from her shoulder, and said, \"Drink, and I will give your camels a drink also.' So I drank, and she gave the camels a drink also.", 
                "num": 46
              }, 
              {
                "text": "Then I asked her, and said, \"Whose daughter are you?' And she said, \"The daughter of Bethuel, Nahor's son, whom Milcah bore to him.' So I put the nose ring on her nose and the bracelets on her wrists.", 
                "num": 47
              }, 
              {
                "text": "And I bowed my head and worshiped the LORD, and blessed the LORD God of my master Abraham, who had led me in the way of truth to take the daughter of my master's brother for his son.", 
                "num": 48
              }, 
              {
                "text": "Now if you will deal kindly and truly with my master, tell me. And if not, tell me, that I may turn to the right hand or to the left.\"", 
                "num": 49
              }, 
              {
                "text": "Then Laban and Bethuel answered and said, \"The thing comes from the LORD; we cannot speak to you either bad or good.", 
                "num": 50
              }, 
              {
                "text": "Here is Rebekah before you; take her and go, and let her be your master's son's wife, as the LORD has spoken.\"", 
                "num": 51
              }, 
              {
                "text": "And it came to pass, when Abraham's servant heard their words, that he worshiped the LORD, bowing himself to the earth.", 
                "num": 52
              }, 
              {
                "text": "Then the servant brought out jewelry of silver, jewelry of gold, and clothing, and gave them to Rebekah. He also gave precious things to her brother and to her mother.", 
                "num": 53
              }, 
              {
                "text": "And he and the men who were with him ate and drank and stayed all night. Then they arose in the morning, and he said, \"Send me away to my master.\"", 
                "num": 54
              }, 
              {
                "text": "But her brother and her mother said, \"Let the young woman stay with us a few days, at least ten; after that she may go.\"", 
                "num": 55
              }, 
              {
                "text": "And he said to them, \"Do not hinder me, since the LORD has prospered my way; send me away so that I may go to my master.\"", 
                "num": 56
              }, 
              {
                "text": "So they said, \"We will call the young woman and ask her personally.\"", 
                "num": 57
              }, 
              {
                "text": "Then they called Rebekah and said to her, \"Will you go with this man?\" And she said, \"I will go.\"", 
                "num": 58
              }, 
              {
                "text": "So they sent away Rebekah their sister and her nurse, and Abraham's servant and his men.", 
                "num": 59
              }, 
              {
                "text": "And they blessed Rebekah and said to her: \"Our sister, may you become The mother of thousands of ten thousands; And may your descendants possess The gates of those who hate them.\"", 
                "num": 60
              }, 
              {
                "text": "Then Rebekah and her maids arose, and they rode on the camels and followed the man. So the servant took Rebekah and departed.", 
                "num": 61
              }, 
              {
                "text": "Now Isaac came from the way of Beer Lahai Roi, for he dwelt in the South.", 
                "num": 62
              }, 
              {
                "text": "And Isaac went out to meditate in the field in the evening; and he lifted his eyes and looked, and there, the camels were coming.", 
                "num": 63
              }, 
              {
                "text": "Then Rebekah lifted her eyes, and when she saw Isaac she dismounted from her camel;", 
                "num": 64
              }, 
              {
                "text": "for she had said to the servant, \"Who is this man walking in the field to meet us?\" The servant said, \"It is my master.\" So she took a veil and covered herself.", 
                "num": 65
              }, 
              {
                "text": "And the servant told Isaac all the things that he had done.", 
                "num": 66
              }, 
              {
                "text": "Then Isaac brought her into his mother Sarah's tent; and he took Rebekah and she became his wife, and he loved her. So Isaac was comforted after his mother's death.", 
                "num": 67
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Abraham again took a wife, and her name was Keturah.", 
                "num": 1
              }, 
              {
                "text": "And she bore him Zimran, Jokshan, Medan, Midian, Ishbak, and Shuah.", 
                "num": 2
              }, 
              {
                "text": "Jokshan begot Sheba and Dedan. And the sons of Dedan were Asshurim, Letushim, and Leummim.", 
                "num": 3
              }, 
              {
                "text": "And the sons of Midian were Ephah, Epher, Hanoch, Abidah, and Eldaah. All these were the children of Keturah.", 
                "num": 4
              }, 
              {
                "text": "And Abraham gave all that he had to Isaac.", 
                "num": 5
              }, 
              {
                "text": "But Abraham gave gifts to the sons of the concubines which Abraham had; and while he was still living he sent them eastward, away from Isaac his son, to the country of the east.", 
                "num": 6
              }, 
              {
                "text": "This is the sum of the years of Abraham's life which he lived: one hundred and seventy-five years.", 
                "num": 7
              }, 
              {
                "text": "Then Abraham breathed his last and died in a good old age, an old man and full of years, and was gathered to his people.", 
                "num": 8
              }, 
              {
                "text": "And his sons Isaac and Ishmael buried him in the cave of Machpelah, which is before Mamre, in the field of Ephron the son of Zohar the Hittite,", 
                "num": 9
              }, 
              {
                "text": "the field which Abraham purchased from the sons of Heth. There Abraham was buried, and Sarah his wife.", 
                "num": 10
              }, 
              {
                "text": "And it came to pass, after the death of Abraham, that God blessed his son Isaac. And Isaac dwelt at Beer Lahai Roi.", 
                "num": 11
              }, 
              {
                "text": "Now this is the genealogy of Ishmael, Abraham's son, whom Hagar the Egyptian, Sarah's maidservant, bore to Abraham.", 
                "num": 12
              }, 
              {
                "text": "And these were the names of the sons of Ishmael, by their names, according to their generations: The firstborn of Ishmael, Nebajoth; then Kedar, Adbeel, Mibsam,", 
                "num": 13
              }, 
              {
                "text": "Mishma, Dumah, Massa,", 
                "num": 14
              }, 
              {
                "text": "Hadar, Tema, Jetur, Naphish, and Kedemah.", 
                "num": 15
              }, 
              {
                "text": "These were the sons of Ishmael and these were their names, by their towns and their settlements, twelve princes according to their nations.", 
                "num": 16
              }, 
              {
                "text": "These were the years of the life of Ishmael: one hundred and thirty-seven years; and he breathed his last and died, and was gathered to his people.", 
                "num": 17
              }, 
              {
                "text": "(They dwelt from Havilah as far as Shur, which is east of Egypt as you go toward Assyria.) He died in the presence of all his brethren.", 
                "num": 18
              }, 
              {
                "text": "This is the genealogy of Isaac, Abraham's son. Abraham begot Isaac.", 
                "num": 19
              }, 
              {
                "text": "Isaac was forty years old when he took Rebekah as wife, the daughter of Bethuel the Syrian of Padan Aram, the sister of Laban the Syrian.", 
                "num": 20
              }, 
              {
                "text": "Now Isaac pleaded with the LORD for his wife, because she was barren; and the LORD granted his plea, and Rebekah his wife conceived.", 
                "num": 21
              }, 
              {
                "text": "But the children struggled together within her; and she said, \"If all is well, why am I like this?|\" So she went to inquire of the LORD.", 
                "num": 22
              }, 
              {
                "text": "And the LORD said to her: \"Two nations are in your womb, Two peoples shall be separated from your body; One people shall be stronger than the other, And the older shall serve the younger.\"", 
                "num": 23
              }, 
              {
                "text": "So when her days were fulfilled for her to give birth, indeed there were twins in her womb.", 
                "num": 24
              }, 
              {
                "text": "And the first came out red. He was like a hairy garment all over; so they called his name Esau.", 
                "num": 25
              }, 
              {
                "text": "Afterward his brother came out, and his hand took hold of Esau's heel; so his name was called Jacob. Isaac was sixty years old when she bore them.", 
                "num": 26
              }, 
              {
                "text": "So the boys grew. And Esau was a skillful hunter, a man of the field; but Jacob was a mild man, dwelling in tents.", 
                "num": 27
              }, 
              {
                "text": "And Isaac loved Esau because he ate of his game, but Rebekah loved Jacob.", 
                "num": 28
              }, 
              {
                "text": "Now Jacob cooked a stew; and Esau came in from the field, and he was weary.", 
                "num": 29
              }, 
              {
                "text": "And Esau said to Jacob, \"Please feed me with that same red stew, for I am weary.\" Therefore his name was called Edom.", 
                "num": 30
              }, 
              {
                "text": "But Jacob said, \"Sell me your birthright as of this day.\"", 
                "num": 31
              }, 
              {
                "text": "And Esau said, \"Look, I am about to die; so what is this birthright to me?\"", 
                "num": 32
              }, 
              {
                "text": "Then Jacob said, \"Swear to me as of this day.\" So he swore to him, and sold his birthright to Jacob.", 
                "num": 33
              }, 
              {
                "text": "And Jacob gave Esau bread and stew of lentils; then he ate and drank, arose, and went his way. Thus Esau despised his birthright.", 
                "num": 34
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "There was a famine in the land, besides the first famine that was in the days of Abraham. And Isaac went to Abimelech king of the Philistines, in Gerar.", 
                "num": 1
              }, 
              {
                "text": "Then the LORD appeared to him and said: \"Do not go down to Egypt; live in the land of which I shall tell you.", 
                "num": 2
              }, 
              {
                "text": "Dwell in this land, and I will be with you and bless you; for to you and your descendants I give all these lands, and I will perform the oath which I swore to Abraham your father.", 
                "num": 3
              }, 
              {
                "text": "And I will make your descendants multiply as the stars of heaven; I will give to your descendants all these lands; and in your seed all the nations of the earth shall be blessed;", 
                "num": 4
              }, 
              {
                "text": "because Abraham obeyed My voice and kept My charge, My commandments, My statutes, and My laws.\"", 
                "num": 5
              }, 
              {
                "text": "So Isaac dwelt in Gerar.", 
                "num": 6
              }, 
              {
                "text": "And the men of the place asked about his wife. And he said, \"She is my sister\"; for he was afraid to say, \"She is my wife,\" because he thought, \"lest the men of the place kill me for Rebekah, because she is beautiful to behold.\"", 
                "num": 7
              }, 
              {
                "text": "Now it came to pass, when he had been there a long time, that Abimelech king of the Philistines looked through a window, and saw, and there was Isaac, showing endearment to Rebekah his wife.", 
                "num": 8
              }, 
              {
                "text": "Then Abimelech called Isaac and said, \"Quite obviously she is your wife; so how could you say, \"She is my sister'?\" Isaac said to him, \"Because I said, \"Lest I die on account of her.'|\"", 
                "num": 9
              }, 
              {
                "text": "And Abimelech said, \"What is this you have done to us? One of the people might soon have lain with your wife, and you would have brought guilt on us.\"", 
                "num": 10
              }, 
              {
                "text": "So Abimelech charged all his people, saying, \"He who touches this man or his wife shall surely be put to death.\"", 
                "num": 11
              }, 
              {
                "text": "Then Isaac sowed in that land, and reaped in the same year a hundredfold; and the LORD blessed him.", 
                "num": 12
              }, 
              {
                "text": "The man began to prosper, and continued prospering until he became very prosperous;", 
                "num": 13
              }, 
              {
                "text": "for he had possessions of flocks and possessions of herds and a great number of servants. So the Philistines envied him.", 
                "num": 14
              }, 
              {
                "text": "Now the Philistines had stopped up all the wells which his father's servants had dug in the days of Abraham his father, and they had filled them with earth.", 
                "num": 15
              }, 
              {
                "text": "And Abimelech said to Isaac, \"Go away from us, for you are much mightier than we.\"", 
                "num": 16
              }, 
              {
                "text": "Then Isaac departed from there and pitched his tent in the Valley of Gerar, and dwelt there.", 
                "num": 17
              }, 
              {
                "text": "And Isaac dug again the wells of water which they had dug in the days of Abraham his father, for the Philistines had stopped them up after the death of Abraham. He called them by the names which his father had called them.", 
                "num": 18
              }, 
              {
                "text": "Also Isaac's servants dug in the valley, and found a well of running water there.", 
                "num": 19
              }, 
              {
                "text": "But the herdsmen of Gerar quarreled with Isaac's herdsmen, saying, \"The water is ours.\" So he called the name of the well Esek, because they quarreled with him.", 
                "num": 20
              }, 
              {
                "text": "Then they dug another well, and they quarreled over that one also. So he called its name Sitnah.", 
                "num": 21
              }, 
              {
                "text": "And he moved from there and dug another well, and they did not quarrel over it. So he called its name Rehoboth, because he said, \"For now the LORD has made room for us, and we shall be fruitful in the land.\"", 
                "num": 22
              }, 
              {
                "text": "Then he went up from there to Beersheba.", 
                "num": 23
              }, 
              {
                "text": "And the LORD appeared to him the same night and said, \"I am the God of your father Abraham; do not fear, for I am with you. I will bless you and multiply your descendants for My servant Abraham's sake.\"", 
                "num": 24
              }, 
              {
                "text": "So he built an altar there and called on the name of the LORD, and he pitched his tent there; and there Isaac's servants dug a well.", 
                "num": 25
              }, 
              {
                "text": "Then Abimelech came to him from Gerar with Ahuzzath, one of his friends, and Phichol the commander of his army.", 
                "num": 26
              }, 
              {
                "text": "And Isaac said to them, \"Why have you come to me, since you hate me and have sent me away from you?\"", 
                "num": 27
              }, 
              {
                "text": "But they said, \"We have certainly seen that the LORD is with you. So we said, \"Let there now be an oath between us, between you and us; and let us make a covenant with you,", 
                "num": 28
              }, 
              {
                "text": "that you will do us no harm, since we have not touched you, and since we have done nothing to you but good and have sent you away in peace. You are now the blessed of the LORD.\"'", 
                "num": 29
              }, 
              {
                "text": "So he made them a feast, and they ate and drank.", 
                "num": 30
              }, 
              {
                "text": "Then they arose early in the morning and swore an oath with one another; and Isaac sent them away, and they departed from him in peace.", 
                "num": 31
              }, 
              {
                "text": "It came to pass the same day that Isaac's servants came and told him about the well which they had dug, and said to him, \"We have found water.\"", 
                "num": 32
              }, 
              {
                "text": "So he called it Shebah. Therefore the name of the city is Beersheba to this day.", 
                "num": 33
              }, 
              {
                "text": "When Esau was forty years old, he took as wives Judith the daughter of Beeri the Hittite, and Basemath the daughter of Elon the Hittite.", 
                "num": 34
              }, 
              {
                "text": "And they were a grief of mind to Isaac and Rebekah.", 
                "num": 35
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Isaac was old and his eyes were so dim that he could not see, that he called Esau his older son and said to him, \"My son.\" And he answered him, \"Here I am.\"", 
                "num": 1
              }, 
              {
                "text": "Then he said, \"Behold now, I am old. I do not know the day of my death.", 
                "num": 2
              }, 
              {
                "text": "Now therefore, please take your weapons, your quiver and your bow, and go out to the field and hunt game for me.", 
                "num": 3
              }, 
              {
                "text": "And make me savory food, such as I love, and bring it to me that I may eat, that my soul may bless you before I die.\"", 
                "num": 4
              }, 
              {
                "text": "Now Rebekah was listening when Isaac spoke to Esau his son. And Esau went to the field to hunt game and to bring it.", 
                "num": 5
              }, 
              {
                "text": "So Rebekah spoke to Jacob her son, saying, \"Indeed I heard your father speak to Esau your brother, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Bring me game and make savory food for me, that I may eat it and bless you in the presence of the LORD before my death.'", 
                "num": 7
              }, 
              {
                "text": "Now therefore, my son, obey my voice according to what I command you.", 
                "num": 8
              }, 
              {
                "text": "Go now to the flock and bring me from there two choice kids of the goats, and I will make savory food from them for your father, such as he loves.", 
                "num": 9
              }, 
              {
                "text": "Then you shall take it to your father, that he may eat it, and that he may bless you before his death.\"", 
                "num": 10
              }, 
              {
                "text": "And Jacob said to Rebekah his mother, \"Look, Esau my brother is a hairy man, and I am a smooth-skinned man.", 
                "num": 11
              }, 
              {
                "text": "Perhaps my father will feel me, and I shall seem to be a deceiver to him; and I shall bring a curse on myself and not a blessing.\"", 
                "num": 12
              }, 
              {
                "text": "But his mother said to him, \"Let your curse be on me, my son; only obey my voice, and go, get them for me.\"", 
                "num": 13
              }, 
              {
                "text": "And he went and got them and brought them to his mother, and his mother made savory food, such as his father loved.", 
                "num": 14
              }, 
              {
                "text": "Then Rebekah took the choice clothes of her elder son Esau, which were with her in the house, and put them on Jacob her younger son.", 
                "num": 15
              }, 
              {
                "text": "And she put the skins of the kids of the goats on his hands and on the smooth part of his neck.", 
                "num": 16
              }, 
              {
                "text": "Then she gave the savory food and the bread, which she had prepared, into the hand of her son Jacob.", 
                "num": 17
              }, 
              {
                "text": "So he went to his father and said, \"My father.\" And he said, \"Here I am. Who are you, my son?\"", 
                "num": 18
              }, 
              {
                "text": "Jacob said to his father, \"I am Esau your firstborn; I have done just as you told me; please arise, sit and eat of my game, that your soul may bless me.\"", 
                "num": 19
              }, 
              {
                "text": "But Isaac said to his son, \"How is it that you have found it so quickly, my son?\" And he said, \"Because the LORD your God brought it to me.\"", 
                "num": 20
              }, 
              {
                "text": "Isaac said to Jacob, \"Please come near, that I may feel you, my son, whether you are really my son Esau or not.\"", 
                "num": 21
              }, 
              {
                "text": "So Jacob went near to Isaac his father, and he felt him and said, \"The voice is Jacob's voice, but the hands are the hands of Esau.\"", 
                "num": 22
              }, 
              {
                "text": "And he did not recognize him, because his hands were hairy like his brother Esau's hands; so he blessed him.", 
                "num": 23
              }, 
              {
                "text": "Then he said, \"Are you really my son Esau?\" He said, \"I am.\"", 
                "num": 24
              }, 
              {
                "text": "He said, \"Bring it near to me, and I will eat of my son's game, so that my soul may bless you.\" So he brought it near to him, and he ate; and he brought him wine, and he drank.", 
                "num": 25
              }, 
              {
                "text": "Then his father Isaac said to him, \"Come near now and kiss me, my son.\"", 
                "num": 26
              }, 
              {
                "text": "And he came near and kissed him; and he smelled the smell of his clothing, and blessed him and said: \"Surely, the smell of my son Is like the smell of a field Which the LORD has blessed.", 
                "num": 27
              }, 
              {
                "text": "Therefore may God give you Of the dew of heaven, Of the fatness of the earth, And plenty of grain and wine.", 
                "num": 28
              }, 
              {
                "text": "Let peoples serve you, And nations bow down to you. Be master over your brethren, And let your mother's sons bow down to you. Cursed be everyone who curses you, And blessed be those who bless you!\"", 
                "num": 29
              }, 
              {
                "text": "Now it happened, as soon as Isaac had finished blessing Jacob, and Jacob had scarcely gone out from the presence of Isaac his father, that Esau his brother came in from his hunting.", 
                "num": 30
              }, 
              {
                "text": "He also had made savory food, and brought it to his father, and said to his father, \"Let my father arise and eat of his son's game, that your soul may bless me.\"", 
                "num": 31
              }, 
              {
                "text": "And his father Isaac said to him, \"Who are you?\" So he said, \"I am your son, your firstborn, Esau.\"", 
                "num": 32
              }, 
              {
                "text": "Then Isaac trembled exceedingly, and said, \"Who? Where is the one who hunted game and brought it to me? I ate all of it before you came, and I have blessed him--and indeed he shall be blessed.\"", 
                "num": 33
              }, 
              {
                "text": "When Esau heard the words of his father, he cried with an exceedingly great and bitter cry, and said to his father, \"Bless me--me also, O my father!\"", 
                "num": 34
              }, 
              {
                "text": "But he said, \"Your brother came with deceit and has taken away your blessing.\"", 
                "num": 35
              }, 
              {
                "text": "And Esau said, \"Is he not rightly named Jacob? For he has supplanted me these two times. He took away my birthright, and now look, he has taken away my blessing!\" And he said, \"Have you not reserved a blessing for me?\"", 
                "num": 36
              }, 
              {
                "text": "Then Isaac answered and said to Esau, \"Indeed I have made him your master, and all his brethren I have given to him as servants; with grain and wine I have sustained him. What shall I do now for you, my son?\"", 
                "num": 37
              }, 
              {
                "text": "And Esau said to his father, \"Have you only one blessing, my father? Bless me--me also, O my father!\" And Esau lifted up his voice and wept.", 
                "num": 38
              }, 
              {
                "text": "Then Isaac his father answered and said to him: \"Behold, your dwelling shall be of the fatness of the earth, And of the dew of heaven from above.", 
                "num": 39
              }, 
              {
                "text": "By your sword you shall live, And you shall serve your brother; And it shall come to pass, when you become restless, That you shall break his yoke from your neck.\"", 
                "num": 40
              }, 
              {
                "text": "So Esau hated Jacob because of the blessing with which his father blessed him, and Esau said in his heart, \"The days of mourning for my father are at hand; then I will kill my brother Jacob.\"", 
                "num": 41
              }, 
              {
                "text": "And the words of Esau her older son were told to Rebekah. So she sent and called Jacob her younger son, and said to him, \"Surely your brother Esau comforts himself concerning you by intending to kill you.", 
                "num": 42
              }, 
              {
                "text": "Now therefore, my son, obey my voice: arise, flee to my brother Laban in Haran.", 
                "num": 43
              }, 
              {
                "text": "And stay with him a few days, until your brother's fury turns away,", 
                "num": 44
              }, 
              {
                "text": "until your brother's anger turns away from you, and he forgets what you have done to him; then I will send and bring you from there. Why should I be bereaved also of you both in one day?\"", 
                "num": 45
              }, 
              {
                "text": "And Rebekah said to Isaac, \"I am weary of my life because of the daughters of Heth; if Jacob takes a wife of the daughters of Heth, like these who are the daughters of the land, what good will my life be to me?\"", 
                "num": 46
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Then Isaac called Jacob and blessed him, and charged him, and said to him: \"You shall not take a wife from the daughters of Canaan.", 
                "num": 1
              }, 
              {
                "text": "Arise, go to Padan Aram, to the house of Bethuel your mother's father; and take yourself a wife from there of the daughters of Laban your mother's brother.", 
                "num": 2
              }, 
              {
                "text": "\"May God Almighty bless you, And make you fruitful and multiply you, That you may be an assembly of peoples;", 
                "num": 3
              }, 
              {
                "text": "And give you the blessing of Abraham, To you and your descendants with you, That you may inherit the land In which you are a stranger, Which God gave to Abraham.\"", 
                "num": 4
              }, 
              {
                "text": "So Isaac sent Jacob away, and he went to Padan Aram, to Laban the son of Bethuel the Syrian, the brother of Rebekah, the mother of Jacob and Esau.", 
                "num": 5
              }, 
              {
                "text": "Esau saw that Isaac had blessed Jacob and sent him away to Padan Aram to take himself a wife from there, and that as he blessed him he gave him a charge, saying, \"You shall not take a wife from the daughters of Canaan,\"", 
                "num": 6
              }, 
              {
                "text": "and that Jacob had obeyed his father and his mother and had gone to Padan Aram.", 
                "num": 7
              }, 
              {
                "text": "Also Esau saw that the daughters of Canaan did not please his father Isaac.", 
                "num": 8
              }, 
              {
                "text": "So Esau went to Ishmael and took Mahalath the daughter of Ishmael, Abraham's son, the sister of Nebajoth, to be his wife in addition to the wives he had.", 
                "num": 9
              }, 
              {
                "text": "Now Jacob went out from Beersheba and went toward Haran.", 
                "num": 10
              }, 
              {
                "text": "So he came to a certain place and stayed there all night, because the sun had set. And he took one of the stones of that place and put it at his head, and he lay down in that place to sleep.", 
                "num": 11
              }, 
              {
                "text": "Then he dreamed, and behold, a ladder was set up on the earth, and its top reached to heaven; and there the angels of God were ascending and descending on it.", 
                "num": 12
              }, 
              {
                "text": "And behold, the LORD stood above it and said: \"I am the LORD God of Abraham your father and the God of Isaac; the land on which you lie I will give to you and your descendants.", 
                "num": 13
              }, 
              {
                "text": "Also your descendants shall be as the dust of the earth; you shall spread abroad to the west and the east, to the north and the south; and in you and in your seed all the families of the earth shall be blessed.", 
                "num": 14
              }, 
              {
                "text": "Behold, I am with you and will keep you wherever you go, and will bring you back to this land; for I will not leave you until I have done what I have spoken to you.\"", 
                "num": 15
              }, 
              {
                "text": "Then Jacob awoke from his sleep and said, \"Surely the LORD is in this place, and I did not know it.\"", 
                "num": 16
              }, 
              {
                "text": "And he was afraid and said, \"How awesome is this place! This is none other than the house of God, and this is the gate of heaven!\"", 
                "num": 17
              }, 
              {
                "text": "Then Jacob rose early in the morning, and took the stone that he had put at his head, set it up as a pillar, and poured oil on top of it.", 
                "num": 18
              }, 
              {
                "text": "And he called the name of that place Bethel; but the name of that city had been Luz previously.", 
                "num": 19
              }, 
              {
                "text": "Then Jacob made a vow, saying, \"If God will be with me, and keep me in this way that I am going, and give me bread to eat and clothing to put on,", 
                "num": 20
              }, 
              {
                "text": "so that I come back to my father's house in peace, then the LORD shall be my God.", 
                "num": 21
              }, 
              {
                "text": "And this stone which I have set as a pillar shall be God's house, and of all that You give me I will surely give a tenth to You.\"", 
                "num": 22
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "So Jacob went on his journey and came to the land of the people of the East.", 
                "num": 1
              }, 
              {
                "text": "And he looked, and saw a well in the field; and behold, there were three flocks of sheep lying by it; for out of that well they watered the flocks. A large stone was on the well's mouth.", 
                "num": 2
              }, 
              {
                "text": "Now all the flocks would be gathered there; and they would roll the stone from the well's mouth, water the sheep, and put the stone back in its place on the well's mouth.", 
                "num": 3
              }, 
              {
                "text": "And Jacob said to them, \"My brethren, where are you from?\" And they said, \"We are from Haran.\"", 
                "num": 4
              }, 
              {
                "text": "Then he said to them, \"Do you know Laban the son of Nahor?\" And they said, \"We know him.\"", 
                "num": 5
              }, 
              {
                "text": "So he said to them, \"Is he well?\" And they said, \"He is well. And look, his daughter Rachel is coming with the sheep.\"", 
                "num": 6
              }, 
              {
                "text": "Then he said, \"Look, it is still high day; it is not time for the cattle to be gathered together. Water the sheep, and go and feed them.\"", 
                "num": 7
              }, 
              {
                "text": "But they said, \"We cannot until all the flocks are gathered together, and they have rolled the stone from the well's mouth; then we water the sheep.\"", 
                "num": 8
              }, 
              {
                "text": "Now while he was still speaking with them, Rachel came with her father's sheep, for she was a shepherdess.", 
                "num": 9
              }, 
              {
                "text": "And it came to pass, when Jacob saw Rachel the daughter of Laban his mother's brother, and the sheep of Laban his mother's brother, that Jacob went near and rolled the stone from the well's mouth, and watered the flock of Laban his mother's brother.", 
                "num": 10
              }, 
              {
                "text": "Then Jacob kissed Rachel, and lifted up his voice and wept.", 
                "num": 11
              }, 
              {
                "text": "And Jacob told Rachel that he was her father's relative and that he was Rebekah's son. So she ran and told her father.", 
                "num": 12
              }, 
              {
                "text": "Then it came to pass, when Laban heard the report about Jacob his sister's son, that he ran to meet him, and embraced him and kissed him, and brought him to his house. So he told Laban all these things.", 
                "num": 13
              }, 
              {
                "text": "And Laban said to him, \"Surely you are my bone and my flesh.\" And he stayed with him for a month.", 
                "num": 14
              }, 
              {
                "text": "Then Laban said to Jacob, \"Because you are my relative, should you therefore serve me for nothing? Tell me, what should your wages be?|\"", 
                "num": 15
              }, 
              {
                "text": "Now Laban had two daughters: the name of the elder was Leah, and the name of the younger was Rachel.", 
                "num": 16
              }, 
              {
                "text": "Leah's eyes were delicate, but Rachel was beautiful of form and appearance.", 
                "num": 17
              }, 
              {
                "text": "Now Jacob loved Rachel; so he said, \"I will serve you seven years for Rachel your younger daughter.\"", 
                "num": 18
              }, 
              {
                "text": "And Laban said, \"It is better that I give her to you than that I should give her to another man. Stay with me.\"", 
                "num": 19
              }, 
              {
                "text": "So Jacob served seven years for Rachel, and they seemed only a few days to him because of the love he had for her.", 
                "num": 20
              }, 
              {
                "text": "Then Jacob said to Laban, \"Give me my wife, for my days are fulfilled, that I may go in to her.\"", 
                "num": 21
              }, 
              {
                "text": "And Laban gathered together all the men of the place and made a feast.", 
                "num": 22
              }, 
              {
                "text": "Now it came to pass in the evening, that he took Leah his daughter and brought her to Jacob; and he went in to her.", 
                "num": 23
              }, 
              {
                "text": "And Laban gave his maid Zilpah to his daughter Leah as a maid.", 
                "num": 24
              }, 
              {
                "text": "So it came to pass in the morning, that behold, it was Leah. And he said to Laban, \"What is this you have done to me? Was it not for Rachel that I served you? Why then have you deceived me?\"", 
                "num": 25
              }, 
              {
                "text": "And Laban said, \"It must not be done so in our country, to give the younger before the firstborn.", 
                "num": 26
              }, 
              {
                "text": "Fulfill her week, and we will give you this one also for the service which you will serve with me still another seven years.\"", 
                "num": 27
              }, 
              {
                "text": "Then Jacob did so and fulfilled her week. So he gave him his daughter Rachel as wife also.", 
                "num": 28
              }, 
              {
                "text": "And Laban gave his maid Bilhah to his daughter Rachel as a maid.", 
                "num": 29
              }, 
              {
                "text": "Then Jacob also went in to Rachel, and he also loved Rachel more than Leah. And he served with Laban still another seven years.", 
                "num": 30
              }, 
              {
                "text": "When the LORD saw that Leah was unloved, He opened her womb; but Rachel was barren.", 
                "num": 31
              }, 
              {
                "text": "So Leah conceived and bore a son, and she called his name Reuben; for she said, \"The LORD has surely looked on my affliction. Now therefore, my husband will love me.\"", 
                "num": 32
              }, 
              {
                "text": "Then she conceived again and bore a son, and said, \"Because the LORD has heard that I am unloved, He has therefore given me this son also.\" And she called his name Simeon.", 
                "num": 33
              }, 
              {
                "text": "She conceived again and bore a son, and said, \"Now this time my husband will become attached to me, because I have borne him three sons.\" Therefore his name was called Levi.", 
                "num": 34
              }, 
              {
                "text": "And she conceived again and bore a son, and said, \"Now I will praise the LORD.\" Therefore she called his name Judah. Then she stopped bearing.", 
                "num": 35
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "Now when Rachel saw that she bore Jacob no children, Rachel envied her sister, and said to Jacob, \"Give me children, or else I die!\"", 
                "num": 1
              }, 
              {
                "text": "And Jacob's anger was aroused against Rachel, and he said, \"Am I in the place of God, who has withheld from you the fruit of the womb?\"", 
                "num": 2
              }, 
              {
                "text": "So she said, \"Here is my maid Bilhah; go in to her, and she will bear a child on my knees, that I also may have children by her.\"", 
                "num": 3
              }, 
              {
                "text": "Then she gave him Bilhah her maid as wife, and Jacob went in to her.", 
                "num": 4
              }, 
              {
                "text": "And Bilhah conceived and bore Jacob a son.", 
                "num": 5
              }, 
              {
                "text": "Then Rachel said, \"God has judged my case; and He has also heard my voice and given me a son.\" Therefore she called his name Dan.", 
                "num": 6
              }, 
              {
                "text": "And Rachel's maid Bilhah conceived again and bore Jacob a second son.", 
                "num": 7
              }, 
              {
                "text": "Then Rachel said, \"With great wrestlings I have wrestled with my sister, and indeed I have prevailed.\" So she called his name Naphtali.", 
                "num": 8
              }, 
              {
                "text": "When Leah saw that she had stopped bearing, she took Zilpah her maid and gave her to Jacob as wife.", 
                "num": 9
              }, 
              {
                "text": "And Leah's maid Zilpah bore Jacob a son.", 
                "num": 10
              }, 
              {
                "text": "Then Leah said, \"A troop comes!\" So she called his name Gad.", 
                "num": 11
              }, 
              {
                "text": "And Leah's maid Zilpah bore Jacob a second son.", 
                "num": 12
              }, 
              {
                "text": "Then Leah said, \"I am happy, for the daughters will call me blessed.\" So she called his name Asher.", 
                "num": 13
              }, 
              {
                "text": "Now Reuben went in the days of wheat harvest and found mandrakes in the field, and brought them to his mother Leah. Then Rachel said to Leah, \"Please give me some of your son's mandrakes.\"", 
                "num": 14
              }, 
              {
                "text": "But she said to her, \"Is it a small matter that you have taken away my husband? Would you take away my son's mandrakes also?\" And Rachel said, \"Therefore he will lie with you tonight for your son's mandrakes.\"", 
                "num": 15
              }, 
              {
                "text": "When Jacob came out of the field in the evening, Leah went out to meet him and said, \"You must come in to me, for I have surely hired you with my son's mandrakes.\" And he lay with her that night.", 
                "num": 16
              }, 
              {
                "text": "And God listened to Leah, and she conceived and bore Jacob a fifth son.", 
                "num": 17
              }, 
              {
                "text": "Leah said, \"God has given me my wages, because I have given my maid to my husband.\" So she called his name Issachar.", 
                "num": 18
              }, 
              {
                "text": "Then Leah conceived again and bore Jacob a sixth son.", 
                "num": 19
              }, 
              {
                "text": "And Leah said, \"God has endowed me with a good endowment; now my husband will dwell with me, because I have borne him six sons.\" So she called his name Zebulun.", 
                "num": 20
              }, 
              {
                "text": "Afterward she bore a daughter, and called her name Dinah.", 
                "num": 21
              }, 
              {
                "text": "Then God remembered Rachel, and God listened to her and opened her womb.", 
                "num": 22
              }, 
              {
                "text": "And she conceived and bore a son, and said, \"God has taken away my reproach.\"", 
                "num": 23
              }, 
              {
                "text": "So she called his name Joseph, and said, \"The LORD shall add to me another son.\"", 
                "num": 24
              }, 
              {
                "text": "And it came to pass, when Rachel had borne Joseph, that Jacob said to Laban, \"Send me away, that I may go to my own place and to my country.", 
                "num": 25
              }, 
              {
                "text": "Give me my wives and my children for whom I have served you, and let me go; for you know my service which I have done for you.\"", 
                "num": 26
              }, 
              {
                "text": "And Laban said to him, \"Please stay, if I have found favor in your eyes, for I have learned by experience that the LORD has blessed me for your sake.\"", 
                "num": 27
              }, 
              {
                "text": "Then he said, \"Name me your wages, and I will give it.\"", 
                "num": 28
              }, 
              {
                "text": "So Jacob said to him, \"You know how I have served you and how your livestock has been with me.", 
                "num": 29
              }, 
              {
                "text": "For what you had before I came was little, and it has increased to a great amount; the LORD has blessed you since my coming. And now, when shall I also provide for my own house?\"", 
                "num": 30
              }, 
              {
                "text": "So he said, \"What shall I give you?\" And Jacob said, \"You shall not give me anything. If you will do this thing for me, I will again feed and keep your flocks:", 
                "num": 31
              }, 
              {
                "text": "Let me pass through all your flock today, removing from there all the speckled and spotted sheep, and all the brown ones among the lambs, and the spotted and speckled among the goats; and these shall be my wages.", 
                "num": 32
              }, 
              {
                "text": "So my righteousness will answer for me in time to come, when the subject of my wages comes before you: every one that is not speckled and spotted among the goats, and brown among the lambs, will be considered stolen, if it is with me.\"", 
                "num": 33
              }, 
              {
                "text": "And Laban said, \"Oh, that it were according to your word!\"", 
                "num": 34
              }, 
              {
                "text": "So he removed that day the male goats that were speckled and spotted, all the female goats that were speckled and spotted, every one that had some white in it, and all the brown ones among the lambs, and gave them into the hand of his sons.", 
                "num": 35
              }, 
              {
                "text": "Then he put three days' journey between himself and Jacob, and Jacob fed the rest of Laban's flocks.", 
                "num": 36
              }, 
              {
                "text": "Now Jacob took for himself rods of green poplar and of the almond and chestnut trees, peeled white strips in them, and exposed the white which was in the rods.", 
                "num": 37
              }, 
              {
                "text": "And the rods which he had peeled, he set before the flocks in the gutters, in the watering troughs where the flocks came to drink, so that they should conceive when they came to drink.", 
                "num": 38
              }, 
              {
                "text": "So the flocks conceived before the rods, and the flocks brought forth streaked, speckled, and spotted.", 
                "num": 39
              }, 
              {
                "text": "Then Jacob separated the lambs, and made the flocks face toward the streaked and all the brown in the flock of Laban; but he put his own flocks by themselves and did not put them with Laban's flock.", 
                "num": 40
              }, 
              {
                "text": "And it came to pass, whenever the stronger livestock conceived, that Jacob placed the rods before the eyes of the livestock in the gutters, that they might conceive among the rods.", 
                "num": 41
              }, 
              {
                "text": "But when the flocks were feeble, he did not put them in; so the feebler were Laban's and the stronger Jacob's.", 
                "num": 42
              }, 
              {
                "text": "Thus the man became exceedingly prosperous, and had large flocks, female and male servants, and camels and donkeys.", 
                "num": 43
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Now Jacob heard the words of Laban's sons, saying, \"Jacob has taken away all that was our father's, and from what was our father's he has acquired all this wealth.\"", 
                "num": 1
              }, 
              {
                "text": "And Jacob saw the countenance of Laban, and indeed it was not favorable toward him as before.", 
                "num": 2
              }, 
              {
                "text": "Then the LORD said to Jacob, \"Return to the land of your fathers and to your family, and I will be with you.\"", 
                "num": 3
              }, 
              {
                "text": "So Jacob sent and called Rachel and Leah to the field, to his flock,", 
                "num": 4
              }, 
              {
                "text": "and said to them, \"I see your father's countenance, that it is not favorable toward me as before; but the God of my father has been with me.", 
                "num": 5
              }, 
              {
                "text": "And you know that with all my might I have served your father.", 
                "num": 6
              }, 
              {
                "text": "Yet your father has deceived me and changed my wages ten times, but God did not allow him to hurt me.", 
                "num": 7
              }, 
              {
                "text": "If he said thus: \"The speckled shall be your wages,' then all the flocks bore speckled. And if he said thus: \"The streaked shall be your wages,' then all the flocks bore streaked.", 
                "num": 8
              }, 
              {
                "text": "So God has taken away the livestock of your father and given them to me.", 
                "num": 9
              }, 
              {
                "text": "\"And it happened, at the time when the flocks conceived, that I lifted my eyes and saw in a dream, and behold, the rams which leaped upon the flocks were streaked, speckled, and gray-spotted.", 
                "num": 10
              }, 
              {
                "text": "Then the Angel of God spoke to me in a dream, saying, \"Jacob.' And I said, \"Here I am.'", 
                "num": 11
              }, 
              {
                "text": "And He said, \"Lift your eyes now and see, all the rams which leap on the flocks are streaked, speckled, and gray-spotted; for I have seen all that Laban is doing to you.", 
                "num": 12
              }, 
              {
                "text": "I am the God of Bethel, where you anointed the pillar and where you made a vow to Me. Now arise, get out of this land, and return to the land of your family.\"'", 
                "num": 13
              }, 
              {
                "text": "Then Rachel and Leah answered and said to him, \"Is there still any portion or inheritance for us in our father's house?", 
                "num": 14
              }, 
              {
                "text": "Are we not considered strangers by him? For he has sold us, and also completely consumed our money.", 
                "num": 15
              }, 
              {
                "text": "For all these riches which God has taken from our father are really ours and our children's; now then, whatever God has said to you, do it.\"", 
                "num": 16
              }, 
              {
                "text": "Then Jacob rose and set his sons and his wives on camels.", 
                "num": 17
              }, 
              {
                "text": "And he carried away all his livestock and all his possessions which he had gained, his acquired livestock which he had gained in Padan Aram, to go to his father Isaac in the land of Canaan.", 
                "num": 18
              }, 
              {
                "text": "Now Laban had gone to shear his sheep, and Rachel had stolen the household idols that were her father's.", 
                "num": 19
              }, 
              {
                "text": "And Jacob stole away, unknown to Laban the Syrian, in that he did not tell him that he intended to flee.", 
                "num": 20
              }, 
              {
                "text": "So he fled with all that he had. He arose and crossed the river, and headed toward the mountains of Gilead.", 
                "num": 21
              }, 
              {
                "text": "And Laban was told on the third day that Jacob had fled.", 
                "num": 22
              }, 
              {
                "text": "Then he took his brethren with him and pursued him for seven days' journey, and he overtook him in the mountains of Gilead.", 
                "num": 23
              }, 
              {
                "text": "But God had come to Laban the Syrian in a dream by night, and said to him, \"Be careful that you speak to Jacob neither good nor bad.\"", 
                "num": 24
              }, 
              {
                "text": "So Laban overtook Jacob. Now Jacob had pitched his tent in the mountains, and Laban with his brethren pitched in the mountains of Gilead.", 
                "num": 25
              }, 
              {
                "text": "And Laban said to Jacob: \"What have you done, that you have stolen away unknown to me, and carried away my daughters like captives taken with the sword?", 
                "num": 26
              }, 
              {
                "text": "Why did you flee away secretly, and steal away from me, and not tell me; for I might have sent you away with joy and songs, with timbrel and harp?", 
                "num": 27
              }, 
              {
                "text": "And you did not allow me to kiss my sons and my daughters. Now you have done foolishly in so doing.", 
                "num": 28
              }, 
              {
                "text": "It is in my power to do you harm, but the God of your father spoke to me last night, saying, \"Be careful that you speak to Jacob neither good nor bad.'", 
                "num": 29
              }, 
              {
                "text": "And now you have surely gone because you greatly long for your father's house, but why did you steal my gods?\"", 
                "num": 30
              }, 
              {
                "text": "Then Jacob answered and said to Laban, \"Because I was afraid, for I said, \"Perhaps you would take your daughters from me by force.'", 
                "num": 31
              }, 
              {
                "text": "With whomever you find your gods, do not let him live. In the presence of our brethren, identify what I have of yours and take it with you.\" For Jacob did not know that Rachel had stolen them.", 
                "num": 32
              }, 
              {
                "text": "And Laban went into Jacob's tent, into Leah's tent, and into the two maids' tents, but he did not find them. Then he went out of Leah's tent and entered Rachel's tent.", 
                "num": 33
              }, 
              {
                "text": "Now Rachel had taken the household idols, put them in the camel's saddle, and sat on them. And Laban searched all about the tent but did not find them.", 
                "num": 34
              }, 
              {
                "text": "And she said to her father, \"Let it not displease my lord that I cannot rise before you, for the manner of women is with me.\" And he searched but did not find the household idols.", 
                "num": 35
              }, 
              {
                "text": "Then Jacob was angry and rebuked Laban, and Jacob answered and said to Laban: \"What is my trespass? What is my sin, that you have so hotly pursued me?", 
                "num": 36
              }, 
              {
                "text": "Although you have searched all my things, what part of your household things have you found? Set it here before my brethren and your brethren, that they may judge between us both!", 
                "num": 37
              }, 
              {
                "text": "These twenty years I have been with you; your ewes and your female goats have not miscarried their young, and I have not eaten the rams of your flock.", 
                "num": 38
              }, 
              {
                "text": "That which was torn by beasts I did not bring to you; I bore the loss of it. You required it from my hand, whether stolen by day or stolen by night.", 
                "num": 39
              }, 
              {
                "text": "There I was! In the day the drought consumed me, and the frost by night, and my sleep departed from my eyes.", 
                "num": 40
              }, 
              {
                "text": "Thus I have been in your house twenty years; I served you fourteen years for your two daughters, and six years for your flock, and you have changed my wages ten times.", 
                "num": 41
              }, 
              {
                "text": "Unless the God of my father, the God of Abraham and the Fear of Isaac, had been with me, surely now you would have sent me away empty-handed. God has seen my affliction and the labor of my hands, and rebuked you last night.\"", 
                "num": 42
              }, 
              {
                "text": "And Laban answered and said to Jacob, \"These daughters are my daughters, and these children are my children, and this flock is my flock; all that you see is mine. But what can I do this day to these my daughters or to their children whom they have borne?", 
                "num": 43
              }, 
              {
                "text": "Now therefore, come, let us make a covenant, you and I, and let it be a witness between you and me.\"", 
                "num": 44
              }, 
              {
                "text": "So Jacob took a stone and set it up as a pillar.", 
                "num": 45
              }, 
              {
                "text": "Then Jacob said to his brethren, \"Gather stones.\" And they took stones and made a heap, and they ate there on the heap.", 
                "num": 46
              }, 
              {
                "text": "Laban called it Jegar Sahadutha, but Jacob called it Galeed.", 
                "num": 47
              }, 
              {
                "text": "And Laban said, \"This heap is a witness between you and me this day.\" Therefore its name was called Galeed,", 
                "num": 48
              }, 
              {
                "text": "also Mizpah, because he said, \"May the LORD watch between you and me when we are absent one from another.", 
                "num": 49
              }, 
              {
                "text": "If you afflict my daughters, or if you take other wives besides my daughters, although no man is with us--see, God is witness between you and me!\"", 
                "num": 50
              }, 
              {
                "text": "Then Laban said to Jacob, \"Here is this heap and here is this pillar, which I have placed between you and me.", 
                "num": 51
              }, 
              {
                "text": "This heap is a witness, and this pillar is a witness, that I will not pass beyond this heap to you, and you will not pass beyond this heap and this pillar to me, for harm.", 
                "num": 52
              }, 
              {
                "text": "The God of Abraham, the God of Nahor, and the God of their father judge between us.\" And Jacob swore by the Fear of his father Isaac.", 
                "num": 53
              }, 
              {
                "text": "Then Jacob offered a sacrifice on the mountain, and called his brethren to eat bread. And they ate bread and stayed all night on the mountain.", 
                "num": 54
              }, 
              {
                "text": "And early in the morning Laban arose, and kissed his sons and daughters and blessed them. Then Laban departed and returned to his place.", 
                "num": 55
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "So Jacob went on his way, and the angels of God met him.", 
                "num": 1
              }, 
              {
                "text": "When Jacob saw them, he said, \"This is God's camp.\" And he called the name of that place Mahanaim.", 
                "num": 2
              }, 
              {
                "text": "Then Jacob sent messengers before him to Esau his brother in the land of Seir, the country of Edom.", 
                "num": 3
              }, 
              {
                "text": "And he commanded them, saying, \"Speak thus to my lord Esau, \"Thus your servant Jacob says: \"I have dwelt with Laban and stayed there until now.", 
                "num": 4
              }, 
              {
                "text": "I have oxen, donkeys, flocks, and male and female servants; and I have sent to tell my lord, that I may find favor in your sight.\"\"'", 
                "num": 5
              }, 
              {
                "text": "Then the messengers returned to Jacob, saying, \"We came to your brother Esau, and he also is coming to meet you, and four hundred men are with him.\"", 
                "num": 6
              }, 
              {
                "text": "So Jacob was greatly afraid and distressed; and he divided the people that were with him, and the flocks and herds and camels, into two companies.", 
                "num": 7
              }, 
              {
                "text": "And he said, \"If Esau comes to the one company and attacks it, then the other company which is left will escape.\"", 
                "num": 8
              }, 
              {
                "text": "Then Jacob said, \"O God of my father Abraham and God of my father Isaac, the LORD who said to me, \"Return to your country and to your family, and I will deal well with you':", 
                "num": 9
              }, 
              {
                "text": "I am not worthy of the least of all the mercies and of all the truth which You have shown Your servant; for I crossed over this Jordan with my staff, and now I have become two companies.", 
                "num": 10
              }, 
              {
                "text": "Deliver me, I pray, from the hand of my brother, from the hand of Esau; for I fear him, lest he come and attack me and the mother with the children.", 
                "num": 11
              }, 
              {
                "text": "For You said, \"I will surely treat you well, and make your descendants as the sand of the sea, which cannot be numbered for multitude.\"'", 
                "num": 12
              }, 
              {
                "text": "So he lodged there that same night, and took what came to his hand as a present for Esau his brother:", 
                "num": 13
              }, 
              {
                "text": "two hundred female goats and twenty male goats, two hundred ewes and twenty rams,", 
                "num": 14
              }, 
              {
                "text": "thirty milk camels with their colts, forty cows and ten bulls, twenty female donkeys and ten foals.", 
                "num": 15
              }, 
              {
                "text": "Then he delivered them to the hand of his servants, every drove by itself, and said to his servants, \"Pass over before me, and put some distance between successive droves.\"", 
                "num": 16
              }, 
              {
                "text": "And he commanded the first one, saying, \"When Esau my brother meets you and asks you, saying, \"To whom do you belong, and where are you going? Whose are these in front of you?'", 
                "num": 17
              }, 
              {
                "text": "then you shall say, \"They are your servant Jacob's. It is a present sent to my lord Esau; and behold, he also is behind us.\"'", 
                "num": 18
              }, 
              {
                "text": "So he commanded the second, the third, and all who followed the droves, saying, \"In this manner you shall speak to Esau when you find him;", 
                "num": 19
              }, 
              {
                "text": "and also say, \"Behold, your servant Jacob is behind us.\"' For he said, \"I will appease him with the present that goes before me, and afterward I will see his face; perhaps he will accept me.\"", 
                "num": 20
              }, 
              {
                "text": "So the present went on over before him, but he himself lodged that night in the camp.", 
                "num": 21
              }, 
              {
                "text": "And he arose that night and took his two wives, his two female servants, and his eleven sons, and crossed over the ford of Jabbok.", 
                "num": 22
              }, 
              {
                "text": "He took them, sent them over the brook, and sent over what he had.", 
                "num": 23
              }, 
              {
                "text": "Then Jacob was left alone; and a Man wrestled with him until the breaking of day.", 
                "num": 24
              }, 
              {
                "text": "Now when He saw that He did not prevail against him, He touched the socket of his hip; and the socket of Jacob's hip was out of joint as He wrestled with him.", 
                "num": 25
              }, 
              {
                "text": "And He said, \"Let Me go, for the day breaks.\" But he said, \"I will not let You go unless You bless me!\"", 
                "num": 26
              }, 
              {
                "text": "So He said to him, \"What is your name?\" He said, \"Jacob.\"", 
                "num": 27
              }, 
              {
                "text": "And He said, \"Your name shall no longer be called Jacob, but Israel; for you have struggled with God and with men, and have prevailed.\"", 
                "num": 28
              }, 
              {
                "text": "Then Jacob asked, saying, \"Tell me Your name, I pray.\" And He said, \"Why is it that you ask about My name?\" And He blessed him there.", 
                "num": 29
              }, 
              {
                "text": "So Jacob called the name of the place Peniel: \"For I have seen God face to face, and my life is preserved.\"", 
                "num": 30
              }, 
              {
                "text": "Just as he crossed over Penuel the sun rose on him, and he limped on his hip.", 
                "num": 31
              }, 
              {
                "text": "Therefore to this day the children of Israel do not eat the muscle that shrank, which is on the hip socket, because He touched the socket of Jacob's hip in the muscle that shrank.", 
                "num": 32
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Now Jacob lifted his eyes and looked, and there, Esau was coming, and with him were four hundred men. So he divided the children among Leah, Rachel, and the two maidservants.", 
                "num": 1
              }, 
              {
                "text": "And he put the maidservants and their children in front, Leah and her children behind, and Rachel and Joseph last.", 
                "num": 2
              }, 
              {
                "text": "Then he crossed over before them and bowed himself to the ground seven times, until he came near to his brother.", 
                "num": 3
              }, 
              {
                "text": "But Esau ran to meet him, and embraced him, and fell on his neck and kissed him, and they wept.", 
                "num": 4
              }, 
              {
                "text": "And he lifted his eyes and saw the women and children, and said, \"Who are these with you?\" So he said, \"The children whom God has graciously given your servant.\"", 
                "num": 5
              }, 
              {
                "text": "Then the maidservants came near, they and their children, and bowed down.", 
                "num": 6
              }, 
              {
                "text": "And Leah also came near with her children, and they bowed down. Afterward Joseph and Rachel came near, and they bowed down.", 
                "num": 7
              }, 
              {
                "text": "Then Esau said, \"What do you mean by all this company which I met?\" And he said, \"These are to find favor in the sight of my lord.\"", 
                "num": 8
              }, 
              {
                "text": "But Esau said, \"I have enough, my brother; keep what you have for yourself.\"", 
                "num": 9
              }, 
              {
                "text": "And Jacob said, \"No, please, if I have now found favor in your sight, then receive my present from my hand, inasmuch as I have seen your face as though I had seen the face of God, and you were pleased with me.", 
                "num": 10
              }, 
              {
                "text": "Please, take my blessing that is brought to you, because God has dealt graciously with me, and because I have enough.\" So he urged him, and he took it.", 
                "num": 11
              }, 
              {
                "text": "Then Esau said, \"Let us take our journey; let us go, and I will go before you.\"", 
                "num": 12
              }, 
              {
                "text": "But Jacob said to him, \"My lord knows that the children are weak, and the flocks and herds which are nursing are with me. And if the men should drive them hard one day, all the flock will die.", 
                "num": 13
              }, 
              {
                "text": "Please let my lord go on ahead before his servant. I will lead on slowly at a pace which the livestock that go before me, and the children, are able to endure, until I come to my lord in Seir.\"", 
                "num": 14
              }, 
              {
                "text": "And Esau said, \"Now let me leave with you some of the people who are with me.\" But he said, \"What need is there? Let me find favor in the sight of my lord.\"", 
                "num": 15
              }, 
              {
                "text": "So Esau returned that day on his way to Seir.", 
                "num": 16
              }, 
              {
                "text": "And Jacob journeyed to Succoth, built himself a house, and made booths for his livestock. Therefore the name of the place is called Succoth.", 
                "num": 17
              }, 
              {
                "text": "Then Jacob came safely to the city of Shechem, which is in the land of Canaan, when he came from Padan Aram; and he pitched his tent before the city.", 
                "num": 18
              }, 
              {
                "text": "And he bought the parcel of land, where he had pitched his tent, from the children of Hamor, Shechem's father, for one hundred pieces of money.", 
                "num": 19
              }, 
              {
                "text": "Then he erected an altar there and called it El Elohe Israel.", 
                "num": 20
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Now Dinah the daughter of Leah, whom she had borne to Jacob, went out to see the daughters of the land.", 
                "num": 1
              }, 
              {
                "text": "And when Shechem the son of Hamor the Hivite, prince of the country, saw her, he took her and lay with her, and violated her.", 
                "num": 2
              }, 
              {
                "text": "His soul was strongly attracted to Dinah the daughter of Jacob, and he loved the young woman and spoke kindly to the young woman.", 
                "num": 3
              }, 
              {
                "text": "So Shechem spoke to his father Hamor, saying, \"Get me this young woman as a wife.\"", 
                "num": 4
              }, 
              {
                "text": "And Jacob heard that he had defiled Dinah his daughter. Now his sons were with his livestock in the field; so Jacob held his peace until they came.", 
                "num": 5
              }, 
              {
                "text": "Then Hamor the father of Shechem went out to Jacob to speak with him.", 
                "num": 6
              }, 
              {
                "text": "And the sons of Jacob came in from the field when they heard it; and the men were grieved and very angry, because he had done a disgraceful thing in Israel by lying with Jacob's daughter, a thing which ought not to be done.", 
                "num": 7
              }, 
              {
                "text": "But Hamor spoke with them, saying, \"The soul of my son Shechem longs for your daughter. Please give her to him as a wife.", 
                "num": 8
              }, 
              {
                "text": "And make marriages with us; give your daughters to us, and take our daughters to yourselves.", 
                "num": 9
              }, 
              {
                "text": "So you shall dwell with us, and the land shall be before you. Dwell and trade in it, and acquire possessions for yourselves in it.\"", 
                "num": 10
              }, 
              {
                "text": "Then Shechem said to her father and her brothers, \"Let me find favor in your eyes, and whatever you say to me I will give.", 
                "num": 11
              }, 
              {
                "text": "Ask me ever so much dowry and gift, and I will give according to what you say to me; but give me the young woman as a wife.\"", 
                "num": 12
              }, 
              {
                "text": "But the sons of Jacob answered Shechem and Hamor his father, and spoke deceitfully, because he had defiled Dinah their sister.", 
                "num": 13
              }, 
              {
                "text": "And they said to them, \"We cannot do this thing, to give our sister to one who is uncircumcised, for that would be a reproach to us.", 
                "num": 14
              }, 
              {
                "text": "But on this condition we will consent to you: If you will become as we are, if every male of you is circumcised,", 
                "num": 15
              }, 
              {
                "text": "then we will give our daughters to you, and we will take your daughters to us; and we will dwell with you, and we will become one people.", 
                "num": 16
              }, 
              {
                "text": "But if you will not heed us and be circumcised, then we will take our daughter and be gone.\"", 
                "num": 17
              }, 
              {
                "text": "And their words pleased Hamor and Shechem, Hamor's son.", 
                "num": 18
              }, 
              {
                "text": "So the young man did not delay to do the thing, because he delighted in Jacob's daughter. He was more honorable than all the household of his father.", 
                "num": 19
              }, 
              {
                "text": "And Hamor and Shechem his son came to the gate of their city, and spoke with the men of their city, saying:", 
                "num": 20
              }, 
              {
                "text": "\"These men are at peace with us. Therefore let them dwell in the land and trade in it. For indeed the land is large enough for them. Let us take their daughters to us as wives, and let us give them our daughters.", 
                "num": 21
              }, 
              {
                "text": "Only on this condition will the men consent to dwell with us, to be one people: if every male among us is circumcised as they are circumcised.", 
                "num": 22
              }, 
              {
                "text": "Will not their livestock, their property, and every animal of theirs be ours? Only let us consent to them, and they will dwell with us.\"", 
                "num": 23
              }, 
              {
                "text": "And all who went out of the gate of his city heeded Hamor and Shechem his son; every male was circumcised, all who went out of the gate of his city.", 
                "num": 24
              }, 
              {
                "text": "Now it came to pass on the third day, when they were in pain, that two of the sons of Jacob, Simeon and Levi, Dinah's brothers, each took his sword and came boldly upon the city and killed all the males.", 
                "num": 25
              }, 
              {
                "text": "And they killed Hamor and Shechem his son with the edge of the sword, and took Dinah from Shechem's house, and went out.", 
                "num": 26
              }, 
              {
                "text": "The sons of Jacob came upon the slain, and plundered the city, because their sister had been defiled.", 
                "num": 27
              }, 
              {
                "text": "They took their sheep, their oxen, and their donkeys, what was in the city and what was in the field,", 
                "num": 28
              }, 
              {
                "text": "and all their wealth. All their little ones and their wives they took captive; and they plundered even all that was in the houses.", 
                "num": 29
              }, 
              {
                "text": "Then Jacob said to Simeon and Levi, \"You have troubled me by making me obnoxious among the inhabitants of the land, among the Canaanites and the Perizzites; and since I am few in number, they will gather themselves together against me and kill me. I shall be destroyed, my household and I.\"", 
                "num": 30
              }, 
              {
                "text": "But they said, \"Should he treat our sister like a harlot?\"", 
                "num": 31
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Then God said to Jacob, \"Arise, go up to Bethel and dwell there; and make an altar there to God, who appeared to you when you fled from the face of Esau your brother.\"", 
                "num": 1
              }, 
              {
                "text": "And Jacob said to his household and to all who were with him, \"Put away the foreign gods that are among you, purify yourselves, and change your garments.", 
                "num": 2
              }, 
              {
                "text": "Then let us arise and go up to Bethel; and I will make an altar there to God, who answered me in the day of my distress and has been with me in the way which I have gone.\"", 
                "num": 3
              }, 
              {
                "text": "So they gave Jacob all the foreign gods which were in their hands, and the earrings which were in their ears; and Jacob hid them under the terebinth tree which was by Shechem.", 
                "num": 4
              }, 
              {
                "text": "And they journeyed, and the terror of God was upon the cities that were all around them, and they did not pursue the sons of Jacob.", 
                "num": 5
              }, 
              {
                "text": "So Jacob came to Luz (that is, Bethel), which is in the land of Canaan, he and all the people who were with him.", 
                "num": 6
              }, 
              {
                "text": "And he built an altar there and called the place El Bethel, because there God appeared to him when he fled from the face of his brother.", 
                "num": 7
              }, 
              {
                "text": "Now Deborah, Rebekah's nurse, died, and she was buried below Bethel under the terebinth tree. So the name of it was called Allon Bachuth.", 
                "num": 8
              }, 
              {
                "text": "Then God appeared to Jacob again, when he came from Padan Aram, and blessed him.", 
                "num": 9
              }, 
              {
                "text": "And God said to him, \"Your name is Jacob; your name shall not be called Jacob anymore, but Israel shall be your name.\" So He called his name Israel.", 
                "num": 10
              }, 
              {
                "text": "Also God said to him: \"I am God Almighty. Be fruitful and multiply; a nation and a company of nations shall proceed from you, and kings shall come from your body.", 
                "num": 11
              }, 
              {
                "text": "The land which I gave Abraham and Isaac I give to you; and to your descendants after you I give this land.\"", 
                "num": 12
              }, 
              {
                "text": "Then God went up from him in the place where He talked with him.", 
                "num": 13
              }, 
              {
                "text": "So Jacob set up a pillar in the place where He talked with him, a pillar of stone; and he poured a drink offering on it, and he poured oil on it.", 
                "num": 14
              }, 
              {
                "text": "And Jacob called the name of the place where God spoke with him, Bethel.", 
                "num": 15
              }, 
              {
                "text": "Then they journeyed from Bethel. And when there was but a little distance to go to Ephrath, Rachel labored in childbirth, and she had hard labor.", 
                "num": 16
              }, 
              {
                "text": "Now it came to pass, when she was in hard labor, that the midwife said to her, \"Do not fear; you will have this son also.\"", 
                "num": 17
              }, 
              {
                "text": "And so it was, as her soul was departing (for she died), that she called his name Ben-Oni; but his father called him Benjamin.", 
                "num": 18
              }, 
              {
                "text": "So Rachel died and was buried on the way to Ephrath (that is, Bethlehem).", 
                "num": 19
              }, 
              {
                "text": "And Jacob set a pillar on her grave, which is the pillar of Rachel's grave to this day.", 
                "num": 20
              }, 
              {
                "text": "Then Israel journeyed and pitched his tent beyond the tower of Eder.", 
                "num": 21
              }, 
              {
                "text": "And it happened, when Israel dwelt in that land, that Reuben went and lay with Bilhah his father's concubine; and Israel heard about it. Now the sons of Jacob were twelve:", 
                "num": 22
              }, 
              {
                "text": "the sons of Leah were Reuben, Jacob's firstborn, and Simeon, Levi, Judah, Issachar, and Zebulun;", 
                "num": 23
              }, 
              {
                "text": "the sons of Rachel were Joseph and Benjamin;", 
                "num": 24
              }, 
              {
                "text": "the sons of Bilhah, Rachel's maidservant, were Dan and Naphtali;", 
                "num": 25
              }, 
              {
                "text": "and the sons of Zilpah, Leah's maidservant, were Gad and Asher. These were the sons of Jacob who were born to him in Padan Aram.", 
                "num": 26
              }, 
              {
                "text": "Then Jacob came to his father Isaac at Mamre, or Kirjath Arba (that is, Hebron), where Abraham and Isaac had dwelt.", 
                "num": 27
              }, 
              {
                "text": "Now the days of Isaac were one hundred and eighty years.", 
                "num": 28
              }, 
              {
                "text": "So Isaac breathed his last and died, and was gathered to his people, being old and full of days. And his sons Esau and Jacob buried him.", 
                "num": 29
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Now this is the genealogy of Esau, who is Edom.", 
                "num": 1
              }, 
              {
                "text": "Esau took his wives from the daughters of Canaan: Adah the daughter of Elon the Hittite; Aholibamah the daughter of Anah, the daughter of Zibeon the Hivite;", 
                "num": 2
              }, 
              {
                "text": "and Basemath, Ishmael's daughter, sister of Nebajoth.", 
                "num": 3
              }, 
              {
                "text": "Now Adah bore Eliphaz to Esau, and Basemath bore Reuel.", 
                "num": 4
              }, 
              {
                "text": "And Aholibamah bore Jeush, Jaalam, and Korah. These were the sons of Esau who were born to him in the land of Canaan.", 
                "num": 5
              }, 
              {
                "text": "Then Esau took his wives, his sons, his daughters, and all the persons of his household, his cattle and all his animals, and all his goods which he had gained in the land of Canaan, and went to a country away from the presence of his brother Jacob.", 
                "num": 6
              }, 
              {
                "text": "For their possessions were too great for them to dwell together, and the land where they were strangers could not support them because of their livestock.", 
                "num": 7
              }, 
              {
                "text": "So Esau dwelt in Mount Seir. Esau is Edom.", 
                "num": 8
              }, 
              {
                "text": "And this is the genealogy of Esau the father of the Edomites in Mount Seir.", 
                "num": 9
              }, 
              {
                "text": "These were the names of Esau's sons: Eliphaz the son of Adah the wife of Esau, and Reuel the son of Basemath the wife of Esau.", 
                "num": 10
              }, 
              {
                "text": "And the sons of Eliphaz were Teman, Omar, Zepho, Gatam, and Kenaz.", 
                "num": 11
              }, 
              {
                "text": "Now Timna was the concubine of Eliphaz, Esau's son, and she bore Amalek to Eliphaz. These were the sons of Adah, Esau's wife.", 
                "num": 12
              }, 
              {
                "text": "These were the sons of Reuel: Nahath, Zerah, Shammah, and Mizzah. These were the sons of Basemath, Esau's wife.", 
                "num": 13
              }, 
              {
                "text": "These were the sons of Aholibamah, Esau's wife, the daughter of Anah, the daughter of Zibeon. And she bore to Esau: Jeush, Jaalam, and Korah.", 
                "num": 14
              }, 
              {
                "text": "These were the chiefs of the sons of Esau. The sons of Eliphaz, the firstborn son of Esau, were Chief Teman, Chief Omar, Chief Zepho, Chief Kenaz,", 
                "num": 15
              }, 
              {
                "text": "Chief Korah, Chief Gatam, and Chief Amalek. These were the chiefs of Eliphaz in the land of Edom. They were the sons of Adah.", 
                "num": 16
              }, 
              {
                "text": "These were the sons of Reuel, Esau's son: Chief Nahath, Chief Zerah, Chief Shammah, and Chief Mizzah. These were the chiefs of Reuel in the land of Edom. These were the sons of Basemath, Esau's wife.", 
                "num": 17
              }, 
              {
                "text": "And these were the sons of Aholibamah, Esau's wife: Chief Jeush, Chief Jaalam, and Chief Korah. These were the chiefs who descended from Aholibamah, Esau's wife, the daughter of Anah.", 
                "num": 18
              }, 
              {
                "text": "These were the sons of Esau, who is Edom, and these were their chiefs.", 
                "num": 19
              }, 
              {
                "text": "These were the sons of Seir the Horite who inhabited the land: Lotan, Shobal, Zibeon, Anah,", 
                "num": 20
              }, 
              {
                "text": "Dishon, Ezer, and Dishan. These were the chiefs of the Horites, the sons of Seir, in the land of Edom.", 
                "num": 21
              }, 
              {
                "text": "And the sons of Lotan were Hori and Hemam. Lotan's sister was Timna.", 
                "num": 22
              }, 
              {
                "text": "These were the sons of Shobal: Alvan, Manahath, Ebal, Shepho, and Onam.", 
                "num": 23
              }, 
              {
                "text": "These were the sons of Zibeon: both Ajah and Anah. This was the Anah who found the water in the wilderness as he pastured the donkeys of his father Zibeon.", 
                "num": 24
              }, 
              {
                "text": "These were the children of Anah: Dishon and Aholibamah the daughter of Anah.", 
                "num": 25
              }, 
              {
                "text": "These were the sons of Dishon: Hemdan, Eshban, Ithran, and Cheran.", 
                "num": 26
              }, 
              {
                "text": "These were the sons of Ezer: Bilhan, Zaavan, and Akan.", 
                "num": 27
              }, 
              {
                "text": "These were the sons of Dishan: Uz and Aran.", 
                "num": 28
              }, 
              {
                "text": "These were the chiefs of the Horites: Chief Lotan, Chief Shobal, Chief Zibeon, Chief Anah,", 
                "num": 29
              }, 
              {
                "text": "Chief Dishon, Chief Ezer, and Chief Dishan. These were the chiefs of the Horites, according to their chiefs in the land of Seir.", 
                "num": 30
              }, 
              {
                "text": "Now these were the kings who reigned in the land of Edom before any king reigned over the children of Israel:", 
                "num": 31
              }, 
              {
                "text": "Bela the son of Beor reigned in Edom, and the name of his city was Dinhabah.", 
                "num": 32
              }, 
              {
                "text": "And when Bela died, Jobab the son of Zerah of Bozrah reigned in his place.", 
                "num": 33
              }, 
              {
                "text": "When Jobab died, Husham of the land of the Temanites reigned in his place.", 
                "num": 34
              }, 
              {
                "text": "And when Husham died, Hadad the son of Bedad, who attacked Midian in the field of Moab, reigned in his place. And the name of his city was Avith.", 
                "num": 35
              }, 
              {
                "text": "When Hadad died, Samlah of Masrekah reigned in his place.", 
                "num": 36
              }, 
              {
                "text": "And when Samlah died, Saul of Rehoboth-by-the-River reigned in his place.", 
                "num": 37
              }, 
              {
                "text": "When Saul died, Baal-Hanan the son of Achbor reigned in his place.", 
                "num": 38
              }, 
              {
                "text": "And when Baal-Hanan the son of Achbor died, Hadar reigned in his place; and the name of his city was Pau. His wife's name was Mehetabel, the daughter of Matred, the daughter of Mezahab.", 
                "num": 39
              }, 
              {
                "text": "And these were the names of the chiefs of Esau, according to their families and their places, by their names: Chief Timnah, Chief Alvah, Chief Jetheth,", 
                "num": 40
              }, 
              {
                "text": "Chief Aholibamah, Chief Elah, Chief Pinon,", 
                "num": 41
              }, 
              {
                "text": "Chief Kenaz, Chief Teman, Chief Mibzar,", 
                "num": 42
              }, 
              {
                "text": "Chief Magdiel, and Chief Iram. These were the chiefs of Edom, according to their dwelling places in the land of their possession. Esau was the father of the Edomites.", 
                "num": 43
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "Now Jacob dwelt in the land where his father was a stranger, in the land of Canaan.", 
                "num": 1
              }, 
              {
                "text": "This is the history of Jacob. Joseph, being seventeen years old, was feeding the flock with his brothers. And the lad was with the sons of Bilhah and the sons of Zilpah, his father's wives; and Joseph brought a bad report of them to his father.", 
                "num": 2
              }, 
              {
                "text": "Now Israel loved Joseph more than all his children, because he was the son of his old age. Also he made him a tunic of many colors.", 
                "num": 3
              }, 
              {
                "text": "But when his brothers saw that their father loved him more than all his brothers, they hated him and could not speak peaceably to him.", 
                "num": 4
              }, 
              {
                "text": "Now Joseph had a dream, and he told it to his brothers; and they hated him even more.", 
                "num": 5
              }, 
              {
                "text": "So he said to them, \"Please hear this dream which I have dreamed:", 
                "num": 6
              }, 
              {
                "text": "There we were, binding sheaves in the field. Then behold, my sheaf arose and also stood upright; and indeed your sheaves stood all around and bowed down to my sheaf.\"", 
                "num": 7
              }, 
              {
                "text": "And his brothers said to him, \"Shall you indeed reign over us? Or shall you indeed have dominion over us?\" So they hated him even more for his dreams and for his words.", 
                "num": 8
              }, 
              {
                "text": "Then he dreamed still another dream and told it to his brothers, and said, \"Look, I have dreamed another dream. And this time, the sun, the moon, and the eleven stars bowed down to me.\"", 
                "num": 9
              }, 
              {
                "text": "So he told it to his father and his brothers; and his father rebuked him and said to him, \"What is this dream that you have dreamed? Shall your mother and I and your brothers indeed come to bow down to the earth before you?\"", 
                "num": 10
              }, 
              {
                "text": "And his brothers envied him, but his father kept the matter in mind.", 
                "num": 11
              }, 
              {
                "text": "Then his brothers went to feed their father's flock in Shechem.", 
                "num": 12
              }, 
              {
                "text": "And Israel said to Joseph, \"Are not your brothers feeding the flock in Shechem? Come, I will send you to them.\" So he said to him, \"Here I am.\"", 
                "num": 13
              }, 
              {
                "text": "Then he said to him, \"Please go and see if it is well with your brothers and well with the flocks, and bring back word to me.\" So he sent him out of the Valley of Hebron, and he went to Shechem.", 
                "num": 14
              }, 
              {
                "text": "Now a certain man found him, and there he was, wandering in the field. And the man asked him, saying, \"What are you seeking?\"", 
                "num": 15
              }, 
              {
                "text": "So he said, \"I am seeking my brothers. Please tell me where they are feeding their flocks.\"", 
                "num": 16
              }, 
              {
                "text": "And the man said, \"They have departed from here, for I heard them say, \"Let us go to Dothan.\"' So Joseph went after his brothers and found them in Dothan.", 
                "num": 17
              }, 
              {
                "text": "Now when they saw him afar off, even before he came near them, they conspired against him to kill him.", 
                "num": 18
              }, 
              {
                "text": "Then they said to one another, \"Look, this dreamer is coming!", 
                "num": 19
              }, 
              {
                "text": "Come therefore, let us now kill him and cast him into some pit; and we shall say, \"Some wild beast has devoured him.' We shall see what will become of his dreams!\"", 
                "num": 20
              }, 
              {
                "text": "But Reuben heard it, and he delivered him out of their hands, and said, \"Let us not kill him.\"", 
                "num": 21
              }, 
              {
                "text": "And Reuben said to them, \"Shed no blood, but cast him into this pit which is in the wilderness, and do not lay a hand on him\"--that he might deliver him out of their hands, and bring him back to his father.", 
                "num": 22
              }, 
              {
                "text": "So it came to pass, when Joseph had come to his brothers, that they stripped Joseph of his tunic, the tunic of many colors that was on him.", 
                "num": 23
              }, 
              {
                "text": "Then they took him and cast him into a pit. And the pit was empty; there was no water in it.", 
                "num": 24
              }, 
              {
                "text": "And they sat down to eat a meal. Then they lifted their eyes and looked, and there was a company of Ishmaelites, coming from Gilead with their camels, bearing spices, balm, and myrrh, on their way to carry them down to Egypt.", 
                "num": 25
              }, 
              {
                "text": "So Judah said to his brothers, \"What profit is there if we kill our brother and conceal his blood?", 
                "num": 26
              }, 
              {
                "text": "Come and let us sell him to the Ishmaelites, and let not our hand be upon him, for he is our brother and our flesh.\" And his brothers listened.", 
                "num": 27
              }, 
              {
                "text": "Then Midianite traders passed by; so the brothers pulled Joseph up and lifted him out of the pit, and sold him to the Ishmaelites for twenty shekels of silver. And they took Joseph to Egypt.", 
                "num": 28
              }, 
              {
                "text": "Then Reuben returned to the pit, and indeed Joseph was not in the pit; and he tore his clothes.", 
                "num": 29
              }, 
              {
                "text": "And he returned to his brothers and said, \"The lad is no more; and I, where shall I go?\"", 
                "num": 30
              }, 
              {
                "text": "So they took Joseph's tunic, killed a kid of the goats, and dipped the tunic in the blood.", 
                "num": 31
              }, 
              {
                "text": "Then they sent the tunic of many colors, and they brought it to their father and said, \"We have found this. Do you know whether it is your son's tunic or not?\"", 
                "num": 32
              }, 
              {
                "text": "And he recognized it and said, \"It is my son's tunic. A wild beast has devoured him. Without doubt Joseph is torn to pieces.\"", 
                "num": 33
              }, 
              {
                "text": "Then Jacob tore his clothes, put sackcloth on his waist, and mourned for his son many days.", 
                "num": 34
              }, 
              {
                "text": "And all his sons and all his daughters arose to comfort him; but he refused to be comforted, and he said, \"For I shall go down into the grave to my son in mourning.\" Thus his father wept for him.", 
                "num": 35
              }, 
              {
                "text": "Now the Midianites had sold him in Egypt to Potiphar, an officer of Pharaoh and captain of the guard.", 
                "num": 36
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "It came to pass at that time that Judah departed from his brothers, and visited a certain Adullamite whose name was Hirah.", 
                "num": 1
              }, 
              {
                "text": "And Judah saw there a daughter of a certain Canaanite whose name was Shua, and he married her and went in to her.", 
                "num": 2
              }, 
              {
                "text": "So she conceived and bore a son, and he called his name Er.", 
                "num": 3
              }, 
              {
                "text": "She conceived again and bore a son, and she called his name Onan.", 
                "num": 4
              }, 
              {
                "text": "And she conceived yet again and bore a son, and called his name Shelah. He was at Chezib when she bore him.", 
                "num": 5
              }, 
              {
                "text": "Then Judah took a wife for Er his firstborn, and her name was Tamar.", 
                "num": 6
              }, 
              {
                "text": "But Er, Judah's firstborn, was wicked in the sight of the LORD, and the LORD killed him.", 
                "num": 7
              }, 
              {
                "text": "And Judah said to Onan, \"Go in to your brother's wife and marry her, and raise up an heir to your brother.\"", 
                "num": 8
              }, 
              {
                "text": "But Onan knew that the heir would not be his; and it came to pass, when he went in to his brother's wife, that he emitted on the ground, lest he should give an heir to his brother.", 
                "num": 9
              }, 
              {
                "text": "And the thing which he did displeased the LORD; therefore He killed him also.", 
                "num": 10
              }, 
              {
                "text": "Then Judah said to Tamar his daughter-in-law, \"Remain a widow in your father's house till my son Shelah is grown.\" For he said, \"Lest he also die like his brothers.\" And Tamar went and dwelt in her father's house.", 
                "num": 11
              }, 
              {
                "text": "Now in the process of time the daughter of Shua, Judah's wife, died; and Judah was comforted, and went up to his sheepshearers at Timnah, he and his friend Hirah the Adullamite.", 
                "num": 12
              }, 
              {
                "text": "And it was told Tamar, saying, \"Look, your father-in-law is going up to Timnah to shear his sheep.\"", 
                "num": 13
              }, 
              {
                "text": "So she took off her widow's garments, covered herself with a veil and wrapped herself, and sat in an open place which was on the way to Timnah; for she saw that Shelah was grown, and she was not given to him as a wife.", 
                "num": 14
              }, 
              {
                "text": "When Judah saw her, he thought she was a harlot, because she had covered her face.", 
                "num": 15
              }, 
              {
                "text": "Then he turned to her by the way, and said, \"Please let me come in to you\"; for he did not know that she was his daughter-in-law. So she said, \"What will you give me, that you may come in to me?\"", 
                "num": 16
              }, 
              {
                "text": "And he said, \"I will send a young goat from the flock.\" So she said, \"Will you give me a pledge till you send it?|\"", 
                "num": 17
              }, 
              {
                "text": "Then he said, \"What pledge shall I give you?\" So she said, \"Your signet and cord, and your staff that is in your hand.\" Then he gave them to her, and went in to her, and she conceived by him.", 
                "num": 18
              }, 
              {
                "text": "So she arose and went away, and laid aside her veil and put on the garments of her widowhood.", 
                "num": 19
              }, 
              {
                "text": "And Judah sent the young goat by the hand of his friend the Adullamite, to receive his pledge from the woman's hand, but he did not find her.", 
                "num": 20
              }, 
              {
                "text": "Then he asked the men of that place, saying, \"Where is the harlot who was openly by the roadside?\" And they said, \"There was no harlot in this place.\"", 
                "num": 21
              }, 
              {
                "text": "So he returned to Judah and said, \"I cannot find her. Also, the men of the place said there was no harlot in this place.\"", 
                "num": 22
              }, 
              {
                "text": "Then Judah said, \"Let her take them for herself, lest we be shamed; for I sent this young goat and you have not found her.\"", 
                "num": 23
              }, 
              {
                "text": "And it came to pass, about three months after, that Judah was told, saying, \"Tamar your daughter-in-law has played the harlot; furthermore she is with child by harlotry.\" So Judah said, \"Bring her out and let her be burned!\"", 
                "num": 24
              }, 
              {
                "text": "When she was brought out, she sent to her father-in-law, saying, \"By the man to whom these belong, I am with child.\" And she said, \"Please determine whose these are--the signet and cord, and staff.\"", 
                "num": 25
              }, 
              {
                "text": "So Judah acknowledged them and said, \"She has been more righteous than I, because I did not give her to Shelah my son.\" And he never knew her again.", 
                "num": 26
              }, 
              {
                "text": "Now it came to pass, at the time for giving birth, that behold, twins were in her womb.", 
                "num": 27
              }, 
              {
                "text": "And so it was, when she was giving birth, that the one put out his hand; and the midwife took a scarlet thread and bound it on his hand, saying, \"This one came out first.\"", 
                "num": 28
              }, 
              {
                "text": "Then it happened, as he drew back his hand, that his brother came out unexpectedly; and she said, \"How did you break through? This breach be upon you!\" Therefore his name was called Perez.", 
                "num": 29
              }, 
              {
                "text": "Afterward his brother came out who had the scarlet thread on his hand. And his name was called Zerah.", 
                "num": 30
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "Now Joseph had been taken down to Egypt. And Potiphar, an officer of Pharaoh, captain of the guard, an Egyptian, bought him from the Ishmaelites who had taken him down there.", 
                "num": 1
              }, 
              {
                "text": "The LORD was with Joseph, and he was a successful man; and he was in the house of his master the Egyptian.", 
                "num": 2
              }, 
              {
                "text": "And his master saw that the LORD was with him and that the LORD made all he did to prosper in his hand.", 
                "num": 3
              }, 
              {
                "text": "So Joseph found favor in his sight, and served him. Then he made him overseer of his house, and all that he had he put under his authority.", 
                "num": 4
              }, 
              {
                "text": "So it was, from the time that he had made him overseer of his house and all that he had, that the LORD blessed the Egyptian's house for Joseph's sake; and the blessing of the LORD was on all that he had in the house and in the field.", 
                "num": 5
              }, 
              {
                "text": "Thus he left all that he had in Joseph's hand, and he did not know what he had except for the bread which he ate. Now Joseph was handsome in form and appearance.", 
                "num": 6
              }, 
              {
                "text": "And it came to pass after these things that his master's wife cast longing eyes on Joseph, and she said, \"Lie with me.\"", 
                "num": 7
              }, 
              {
                "text": "But he refused and said to his master's wife, \"Look, my master does not know what is with me in the house, and he has committed all that he has to my hand.", 
                "num": 8
              }, 
              {
                "text": "There is no one greater in this house than I, nor has he kept back anything from me but you, because you are his wife. How then can I do this great wickedness, and sin against God?\"", 
                "num": 9
              }, 
              {
                "text": "So it was, as she spoke to Joseph day by day, that he did not heed her, to lie with her or to be with her.", 
                "num": 10
              }, 
              {
                "text": "But it happened about this time, when Joseph went into the house to do his work, and none of the men of the house was inside,", 
                "num": 11
              }, 
              {
                "text": "that she caught him by his garment, saying, \"Lie with me.\" But he left his garment in her hand, and fled and ran outside.", 
                "num": 12
              }, 
              {
                "text": "And so it was, when she saw that he had left his garment in her hand and fled outside,", 
                "num": 13
              }, 
              {
                "text": "that she called to the men of her house and spoke to them, saying, \"See, he has brought in to us a Hebrew to mock us. He came in to me to lie with me, and I cried out with a loud voice.", 
                "num": 14
              }, 
              {
                "text": "And it happened, when he heard that I lifted my voice and cried out, that he left his garment with me, and fled and went outside.\"", 
                "num": 15
              }, 
              {
                "text": "So she kept his garment with her until his master came home.", 
                "num": 16
              }, 
              {
                "text": "Then she spoke to him with words like these, saying, \"The Hebrew servant whom you brought to us came in to me to mock me;", 
                "num": 17
              }, 
              {
                "text": "so it happened, as I lifted my voice and cried out, that he left his garment with me and fled outside.\"", 
                "num": 18
              }, 
              {
                "text": "So it was, when his master heard the words which his wife spoke to him, saying, \"Your servant did to me after this manner,\" that his anger was aroused.", 
                "num": 19
              }, 
              {
                "text": "Then Joseph's master took him and put him into the prison, a place where the king's prisoners were confined. And he was there in the prison.", 
                "num": 20
              }, 
              {
                "text": "But the LORD was with Joseph and showed him mercy, and He gave him favor in the sight of the keeper of the prison.", 
                "num": 21
              }, 
              {
                "text": "And the keeper of the prison committed to Joseph's hand all the prisoners who were in the prison; whatever they did there, it was his doing.", 
                "num": 22
              }, 
              {
                "text": "The keeper of the prison did not look into anything that was under Joseph's authority, because the LORD was with him; and whatever he did, the LORD made it prosper.", 
                "num": 23
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "It came to pass after these things that the butler and the baker of the king of Egypt offended their lord, the king of Egypt.", 
                "num": 1
              }, 
              {
                "text": "And Pharaoh was angry with his two officers, the chief butler and the chief baker.", 
                "num": 2
              }, 
              {
                "text": "So he put them in custody in the house of the captain of the guard, in the prison, the place where Joseph was confined.", 
                "num": 3
              }, 
              {
                "text": "And the captain of the guard charged Joseph with them, and he served them; so they were in custody for a while.", 
                "num": 4
              }, 
              {
                "text": "Then the butler and the baker of the king of Egypt, who were confined in the prison, had a dream, both of them, each man's dream in one night and each man's dream with its own interpretation.", 
                "num": 5
              }, 
              {
                "text": "And Joseph came in to them in the morning and looked at them, and saw that they were sad.", 
                "num": 6
              }, 
              {
                "text": "So he asked Pharaoh's officers who were with him in the custody of his lord's house, saying, \"Why do you look so sad today?\"", 
                "num": 7
              }, 
              {
                "text": "And they said to him, \"We each have had a dream, and there is no interpreter of it.\" So Joseph said to them, \"Do not interpretations belong to God? Tell them to me, please.\"", 
                "num": 8
              }, 
              {
                "text": "Then the chief butler told his dream to Joseph, and said to him, \"Behold, in my dream a vine was before me,", 
                "num": 9
              }, 
              {
                "text": "and in the vine were three branches; it was as though it budded, its blossoms shot forth, and its clusters brought forth ripe grapes.", 
                "num": 10
              }, 
              {
                "text": "Then Pharaoh's cup was in my hand; and I took the grapes and pressed them into Pharaoh's cup, and placed the cup in Pharaoh's hand.\"", 
                "num": 11
              }, 
              {
                "text": "And Joseph said to him, \"This is the interpretation of it: The three branches are three days.", 
                "num": 12
              }, 
              {
                "text": "Now within three days Pharaoh will lift up your head and restore you to your place, and you will put Pharaoh's cup in his hand according to the former manner, when you were his butler.", 
                "num": 13
              }, 
              {
                "text": "But remember me when it is well with you, and please show kindness to me; make mention of me to Pharaoh, and get me out of this house.", 
                "num": 14
              }, 
              {
                "text": "For indeed I was stolen away from the land of the Hebrews; and also I have done nothing here that they should put me into the dungeon.\"", 
                "num": 15
              }, 
              {
                "text": "When the chief baker saw that the interpretation was good, he said to Joseph, \"I also was in my dream, and there were three white baskets on my head.", 
                "num": 16
              }, 
              {
                "text": "In the uppermost basket were all kinds of baked goods for Pharaoh, and the birds ate them out of the basket on my head.\"", 
                "num": 17
              }, 
              {
                "text": "So Joseph answered and said, \"This is the interpretation of it: The three baskets are three days.", 
                "num": 18
              }, 
              {
                "text": "Within three days Pharaoh will lift off your head from you and hang you on a tree; and the birds will eat your flesh from you.\"", 
                "num": 19
              }, 
              {
                "text": "Now it came to pass on the third day, which was Pharaoh's birthday, that he made a feast for all his servants; and he lifted up the head of the chief butler and of the chief baker among his servants.", 
                "num": 20
              }, 
              {
                "text": "Then he restored the chief butler to his butlership again, and he placed the cup in Pharaoh's hand.", 
                "num": 21
              }, 
              {
                "text": "But he hanged the chief baker, as Joseph had interpreted to them.", 
                "num": 22
              }, 
              {
                "text": "Yet the chief butler did not remember Joseph, but forgot him.", 
                "num": 23
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "Then it came to pass, at the end of two full years, that Pharaoh had a dream; and behold, he stood by the river.", 
                "num": 1
              }, 
              {
                "text": "Suddenly there came up out of the river seven cows, fine looking and fat; and they fed in the meadow.", 
                "num": 2
              }, 
              {
                "text": "Then behold, seven other cows came up after them out of the river, ugly and gaunt, and stood by the other cows on the bank of the river.", 
                "num": 3
              }, 
              {
                "text": "And the ugly and gaunt cows ate up the seven fine looking and fat cows. So Pharaoh awoke.", 
                "num": 4
              }, 
              {
                "text": "He slept and dreamed a second time; and suddenly seven heads of grain came up on one stalk, plump and good.", 
                "num": 5
              }, 
              {
                "text": "Then behold, seven thin heads, blighted by the east wind, sprang up after them.", 
                "num": 6
              }, 
              {
                "text": "And the seven thin heads devoured the seven plump and full heads. So Pharaoh awoke, and indeed, it was a dream.", 
                "num": 7
              }, 
              {
                "text": "Now it came to pass in the morning that his spirit was troubled, and he sent and called for all the magicians of Egypt and all its wise men. And Pharaoh told them his dreams, but there was no one who could interpret them for Pharaoh.", 
                "num": 8
              }, 
              {
                "text": "Then the chief butler spoke to Pharaoh, saying: \"I remember my faults this day.", 
                "num": 9
              }, 
              {
                "text": "When Pharaoh was angry with his servants, and put me in custody in the house of the captain of the guard, both me and the chief baker,", 
                "num": 10
              }, 
              {
                "text": "we each had a dream in one night, he and I. Each of us dreamed according to the interpretation of his own dream.", 
                "num": 11
              }, 
              {
                "text": "Now there was a young Hebrew man with us there, a servant of the captain of the guard. And we told him, and he interpreted our dreams for us; to each man he interpreted according to his own dream.", 
                "num": 12
              }, 
              {
                "text": "And it came to pass, just as he interpreted for us, so it happened. He restored me to my office, and he hanged him.\"", 
                "num": 13
              }, 
              {
                "text": "Then Pharaoh sent and called Joseph, and they brought him quickly out of the dungeon; and he shaved, changed his clothing, and came to Pharaoh.", 
                "num": 14
              }, 
              {
                "text": "And Pharaoh said to Joseph, \"I have had a dream, and there is no one who can interpret it. But I have heard it said of you that you can understand a dream, to interpret it.\"", 
                "num": 15
              }, 
              {
                "text": "So Joseph answered Pharaoh, saying, \"It is not in me; God will give Pharaoh an answer of peace.\"", 
                "num": 16
              }, 
              {
                "text": "Then Pharaoh said to Joseph: \"Behold, in my dream I stood on the bank of the river.", 
                "num": 17
              }, 
              {
                "text": "Suddenly seven cows came up out of the river, fine looking and fat; and they fed in the meadow.", 
                "num": 18
              }, 
              {
                "text": "Then behold, seven other cows came up after them, poor and very ugly and gaunt, such ugliness as I have never seen in all the land of Egypt.", 
                "num": 19
              }, 
              {
                "text": "And the gaunt and ugly cows ate up the first seven, the fat cows.", 
                "num": 20
              }, 
              {
                "text": "When they had eaten them up, no one would have known that they had eaten them, for they were just as ugly as at the beginning. So I awoke.", 
                "num": 21
              }, 
              {
                "text": "Also I saw in my dream, and suddenly seven heads came up on one stalk, full and good.", 
                "num": 22
              }, 
              {
                "text": "Then behold, seven heads, withered, thin, and blighted by the east wind, sprang up after them.", 
                "num": 23
              }, 
              {
                "text": "And the thin heads devoured the seven good heads. So I told this to the magicians, but there was no one who could explain it to me.\"", 
                "num": 24
              }, 
              {
                "text": "Then Joseph said to Pharaoh, \"The dreams of Pharaoh are one; God has shown Pharaoh what He is about to do:", 
                "num": 25
              }, 
              {
                "text": "The seven good cows are seven years, and the seven good heads are seven years; the dreams are one.", 
                "num": 26
              }, 
              {
                "text": "And the seven thin and ugly cows which came up after them are seven years, and the seven empty heads blighted by the east wind are seven years of famine.", 
                "num": 27
              }, 
              {
                "text": "This is the thing which I have spoken to Pharaoh. God has shown Pharaoh what He is about to do.", 
                "num": 28
              }, 
              {
                "text": "Indeed seven years of great plenty will come throughout all the land of Egypt;", 
                "num": 29
              }, 
              {
                "text": "but after them seven years of famine will arise, and all the plenty will be forgotten in the land of Egypt; and the famine will deplete the land.", 
                "num": 30
              }, 
              {
                "text": "So the plenty will not be known in the land because of the famine following, for it will be very severe.", 
                "num": 31
              }, 
              {
                "text": "And the dream was repeated to Pharaoh twice because the thing is established by God, and God will shortly bring it to pass.", 
                "num": 32
              }, 
              {
                "text": "\"Now therefore, let Pharaoh select a discerning and wise man, and set him over the land of Egypt.", 
                "num": 33
              }, 
              {
                "text": "Let Pharaoh do this, and let him appoint officers over the land, to collect one-fifth of the produce of the land of Egypt in the seven plentiful years.", 
                "num": 34
              }, 
              {
                "text": "And let them gather all the food of those good years that are coming, and store up grain under the authority of Pharaoh, and let them keep food in the cities.", 
                "num": 35
              }, 
              {
                "text": "Then that food shall be as a reserve for the land for the seven years of famine which shall be in the land of Egypt, that the land may not perish during the famine.\"", 
                "num": 36
              }, 
              {
                "text": "So the advice was good in the eyes of Pharaoh and in the eyes of all his servants.", 
                "num": 37
              }, 
              {
                "text": "And Pharaoh said to his servants, \"Can we find such a one as this, a man in whom is the Spirit of God?\"", 
                "num": 38
              }, 
              {
                "text": "Then Pharaoh said to Joseph, \"Inasmuch as God has shown you all this, there is no one as discerning and wise as you.", 
                "num": 39
              }, 
              {
                "text": "You shall be over my house, and all my people shall be ruled according to your word; only in regard to the throne will I be greater than you.\"", 
                "num": 40
              }, 
              {
                "text": "And Pharaoh said to Joseph, \"See, I have set you over all the land of Egypt.\"", 
                "num": 41
              }, 
              {
                "text": "Then Pharaoh took his signet ring off his hand and put it on Joseph's hand; and he clothed him in garments of fine linen and put a gold chain around his neck.", 
                "num": 42
              }, 
              {
                "text": "And he had him ride in the second chariot which he had; and they cried out before him, \"Bow the knee!\" So he set him over all the land of Egypt.", 
                "num": 43
              }, 
              {
                "text": "Pharaoh also said to Joseph, \"I am Pharaoh, and without your consent no man may lift his hand or foot in all the land of Egypt.\"", 
                "num": 44
              }, 
              {
                "text": "And Pharaoh called Joseph's name Zaphnath-Paaneah. And he gave him as a wife Asenath, the daughter of Poti-Pherah priest of On. So Joseph went out over all the land of Egypt.", 
                "num": 45
              }, 
              {
                "text": "Joseph was thirty years old when he stood before Pharaoh king of Egypt. And Joseph went out from the presence of Pharaoh, and went throughout all the land of Egypt.", 
                "num": 46
              }, 
              {
                "text": "Now in the seven plentiful years the ground brought forth abundantly.", 
                "num": 47
              }, 
              {
                "text": "So he gathered up all the food of the seven years which were in the land of Egypt, and laid up the food in the cities; he laid up in every city the food of the fields which surrounded them.", 
                "num": 48
              }, 
              {
                "text": "Joseph gathered very much grain, as the sand of the sea, until he stopped counting, for it was immeasurable.", 
                "num": 49
              }, 
              {
                "text": "And to Joseph were born two sons before the years of famine came, whom Asenath, the daughter of Poti-Pherah priest of On, bore to him.", 
                "num": 50
              }, 
              {
                "text": "Joseph called the name of the firstborn Manasseh: \"For God has made me forget all my toil and all my father's house.\"", 
                "num": 51
              }, 
              {
                "text": "And the name of the second he called Ephraim: \"For God has caused me to be fruitful in the land of my affliction.\"", 
                "num": 52
              }, 
              {
                "text": "Then the seven years of plenty which were in the land of Egypt ended,", 
                "num": 53
              }, 
              {
                "text": "and the seven years of famine began to come, as Joseph had said. The famine was in all lands, but in all the land of Egypt there was bread.", 
                "num": 54
              }, 
              {
                "text": "So when all the land of Egypt was famished, the people cried to Pharaoh for bread. Then Pharaoh said to all the Egyptians, \"Go to Joseph; whatever he says to you, do.\"", 
                "num": 55
              }, 
              {
                "text": "The famine was over all the face of the earth, and Joseph opened all the storehouses and sold to the Egyptians. And the famine became severe in the land of Egypt.", 
                "num": 56
              }, 
              {
                "text": "So all countries came to Joseph in Egypt to buy grain, because the famine was severe in all lands.", 
                "num": 57
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "When Jacob saw that there was grain in Egypt, Jacob said to his sons, \"Why do you look at one another?\"", 
                "num": 1
              }, 
              {
                "text": "And he said, \"Indeed I have heard that there is grain in Egypt; go down to that place and buy for us there, that we may live and not die.\"", 
                "num": 2
              }, 
              {
                "text": "So Joseph's ten brothers went down to buy grain in Egypt.", 
                "num": 3
              }, 
              {
                "text": "But Jacob did not send Joseph's brother Benjamin with his brothers, for he said, \"Lest some calamity befall him.\"", 
                "num": 4
              }, 
              {
                "text": "And the sons of Israel went to buy grain among those who journeyed, for the famine was in the land of Canaan.", 
                "num": 5
              }, 
              {
                "text": "Now Joseph was governor over the land; and it was he who sold to all the people of the land. And Joseph's brothers came and bowed down before him with their faces to the earth.", 
                "num": 6
              }, 
              {
                "text": "Joseph saw his brothers and recognized them, but he acted as a stranger to them and spoke roughly to them. Then he said to them, \"Where do you come from?\" And they said, \"From the land of Canaan to buy food.\"", 
                "num": 7
              }, 
              {
                "text": "So Joseph recognized his brothers, but they did not recognize him.", 
                "num": 8
              }, 
              {
                "text": "Then Joseph remembered the dreams which he had dreamed about them, and said to them, \"You are spies! You have come to see the nakedness of the land!\"", 
                "num": 9
              }, 
              {
                "text": "And they said to him, \"No, my lord, but your servants have come to buy food.", 
                "num": 10
              }, 
              {
                "text": "We are all one man's sons; we are honest men; your servants are not spies.\"", 
                "num": 11
              }, 
              {
                "text": "But he said to them, \"No, but you have come to see the nakedness of the land.\"", 
                "num": 12
              }, 
              {
                "text": "And they said, \"Your servants are twelve brothers, the sons of one man in the land of Canaan; and in fact, the youngest is with our father today, and one is no more.\"", 
                "num": 13
              }, 
              {
                "text": "But Joseph said to them, \"It is as I spoke to you, saying, \"You are spies!'", 
                "num": 14
              }, 
              {
                "text": "In this manner you shall be tested: By the life of Pharaoh, you shall not leave this place unless your youngest brother comes here.", 
                "num": 15
              }, 
              {
                "text": "Send one of you, and let him bring your brother; and you shall be kept in prison, that your words may be tested to see whether there is any truth in you; or else, by the life of Pharaoh, surely you are spies!\"", 
                "num": 16
              }, 
              {
                "text": "So he put them all together in prison three days.", 
                "num": 17
              }, 
              {
                "text": "Then Joseph said to them the third day, \"Do this and live, for I fear God:", 
                "num": 18
              }, 
              {
                "text": "If you are honest men, let one of your brothers be confined to your prison house; but you, go and carry grain for the famine of your houses.", 
                "num": 19
              }, 
              {
                "text": "And bring your youngest brother to me; so your words will be verified, and you shall not die.\" And they did so.", 
                "num": 20
              }, 
              {
                "text": "Then they said to one another, \"We are truly guilty concerning our brother, for we saw the anguish of his soul when he pleaded with us, and we would not hear; therefore this distress has come upon us.\"", 
                "num": 21
              }, 
              {
                "text": "And Reuben answered them, saying, \"Did I not speak to you, saying, \"Do not sin against the boy'; and you would not listen? Therefore behold, his blood is now required of us.\"", 
                "num": 22
              }, 
              {
                "text": "But they did not know that Joseph understood them, for he spoke to them through an interpreter.", 
                "num": 23
              }, 
              {
                "text": "And he turned himself away from them and wept. Then he returned to them again, and talked with them. And he took Simeon from them and bound him before their eyes.", 
                "num": 24
              }, 
              {
                "text": "Then Joseph gave a command to fill their sacks with grain, to restore every man's money to his sack, and to give them provisions for the journey. Thus he did for them.", 
                "num": 25
              }, 
              {
                "text": "So they loaded their donkeys with the grain and departed from there.", 
                "num": 26
              }, 
              {
                "text": "But as one of them opened his sack to give his donkey feed at the encampment, he saw his money; and there it was, in the mouth of his sack.", 
                "num": 27
              }, 
              {
                "text": "So he said to his brothers, \"My money has been restored, and there it is, in my sack!\" Then their hearts failed them and they were afraid, saying to one another, \"What is this that God has done to us?\"", 
                "num": 28
              }, 
              {
                "text": "Then they went to Jacob their father in the land of Canaan and told him all that had happened to them, saying:", 
                "num": 29
              }, 
              {
                "text": "\"The man who is lord of the land spoke roughly to us, and took us for spies of the country.", 
                "num": 30
              }, 
              {
                "text": "But we said to him, \"We are honest men; we are not spies.", 
                "num": 31
              }, 
              {
                "text": "We are twelve brothers, sons of our father; one is no more, and the youngest is with our father this day in the land of Canaan.'", 
                "num": 32
              }, 
              {
                "text": "Then the man, the lord of the country, said to us, \"By this I will know that you are honest men: Leave one of your brothers here with me, take food for the famine of your households, and be gone.", 
                "num": 33
              }, 
              {
                "text": "And bring your youngest brother to me; so I shall know that you are not spies, but that you are honest men. I will grant your brother to you, and you may trade in the land.\"'", 
                "num": 34
              }, 
              {
                "text": "Then it happened as they emptied their sacks, that surprisingly each man's bundle of money was in his sack; and when they and their father saw the bundles of money, they were afraid.", 
                "num": 35
              }, 
              {
                "text": "And Jacob their father said to them, \"You have bereaved me: Joseph is no more, Simeon is no more, and you want to take Benjamin. All these things are against me.\"", 
                "num": 36
              }, 
              {
                "text": "Then Reuben spoke to his father, saying, \"Kill my two sons if I do not bring him back to you; put him in my hands, and I will bring him back to you.\"", 
                "num": 37
              }, 
              {
                "text": "But he said, \"My son shall not go down with you, for his brother is dead, and he is left alone. If any calamity should befall him along the way in which you go, then you would bring down my gray hair with sorrow to the grave.\"", 
                "num": 38
              }
            ], 
            "num": 42
          }, 
          {
            "verses": [
              {
                "text": "Now the famine was severe in the land.", 
                "num": 1
              }, 
              {
                "text": "And it came to pass, when they had eaten up the grain which they had brought from Egypt, that their father said to them, \"Go back, buy us a little food.\"", 
                "num": 2
              }, 
              {
                "text": "But Judah spoke to him, saying, \"The man solemnly warned us, saying, \"You shall not see my face unless your brother is with you.'", 
                "num": 3
              }, 
              {
                "text": "If you send our brother with us, we will go down and buy you food.", 
                "num": 4
              }, 
              {
                "text": "But if you will not send him, we will not go down; for the man said to us, \"You shall not see my face unless your brother is with you.\"'", 
                "num": 5
              }, 
              {
                "text": "And Israel said, \"Why did you deal so wrongfully with me as to tell the man whether you had still another brother?\"", 
                "num": 6
              }, 
              {
                "text": "But they said, \"The man asked us pointedly about ourselves and our family, saying, \"Is your father still alive? Have you another brother?' And we told him according to these words. Could we possibly have known that he would say, \"Bring your brother down'?\"", 
                "num": 7
              }, 
              {
                "text": "Then Judah said to Israel his father, \"Send the lad with me, and we will arise and go, that we may live and not die, both we and you and also our little ones.", 
                "num": 8
              }, 
              {
                "text": "I myself will be surety for him; from my hand you shall require him. If I do not bring him back to you and set him before you, then let me bear the blame forever.", 
                "num": 9
              }, 
              {
                "text": "For if we had not lingered, surely by now we would have returned this second time.\"", 
                "num": 10
              }, 
              {
                "text": "And their father Israel said to them, \"If it must be so, then do this: Take some of the best fruits of the land in your vessels and carry down a present for the man--a little balm and a little honey, spices and myrrh, pistachio nuts and almonds.", 
                "num": 11
              }, 
              {
                "text": "Take double money in your hand, and take back in your hand the money that was returned in the mouth of your sacks; perhaps it was an oversight.", 
                "num": 12
              }, 
              {
                "text": "Take your brother also, and arise, go back to the man.", 
                "num": 13
              }, 
              {
                "text": "And may God Almighty give you mercy before the man, that he may release your other brother and Benjamin. If I am bereaved, I am bereaved!\"", 
                "num": 14
              }, 
              {
                "text": "So the men took that present and Benjamin, and they took double money in their hand, and arose and went down to Egypt; and they stood before Joseph.", 
                "num": 15
              }, 
              {
                "text": "When Joseph saw Benjamin with them, he said to the steward of his house, \"Take these men to my home, and slaughter an animal and make ready; for these men will dine with me at noon.\"", 
                "num": 16
              }, 
              {
                "text": "Then the man did as Joseph ordered, and the man brought the men into Joseph's house.", 
                "num": 17
              }, 
              {
                "text": "Now the men were afraid because they were brought into Joseph's house; and they said, \"It is because of the money, which was returned in our sacks the first time, that we are brought in, so that he may make a case against us and seize us, to take us as slaves with our donkeys.\"", 
                "num": 18
              }, 
              {
                "text": "When they drew near to the steward of Joseph's house, they talked with him at the door of the house,", 
                "num": 19
              }, 
              {
                "text": "and said, \"O sir, we indeed came down the first time to buy food;", 
                "num": 20
              }, 
              {
                "text": "but it happened, when we came to the encampment, that we opened our sacks, and there, each man's money was in the mouth of his sack, our money in full weight; so we have brought it back in our hand.", 
                "num": 21
              }, 
              {
                "text": "And we have brought down other money in our hands to buy food. We do not know who put our money in our sacks.\"", 
                "num": 22
              }, 
              {
                "text": "But he said, \"Peace be with you, do not be afraid. Your God and the God of your father has given you treasure in your sacks; I had your money.\" Then he brought Simeon out to them.", 
                "num": 23
              }, 
              {
                "text": "So the man brought the men into Joseph's house and gave them water, and they washed their feet; and he gave their donkeys feed.", 
                "num": 24
              }, 
              {
                "text": "Then they made the present ready for Joseph's coming at noon, for they heard that they would eat bread there.", 
                "num": 25
              }, 
              {
                "text": "And when Joseph came home, they brought him the present which was in their hand into the house, and bowed down before him to the earth.", 
                "num": 26
              }, 
              {
                "text": "Then he asked them about their well-being, and said, \"Is your father well, the old man of whom you spoke? Is he still alive?\"", 
                "num": 27
              }, 
              {
                "text": "And they answered, \"Your servant our father is in good health; he is still alive.\" And they bowed their heads down and prostrated themselves.", 
                "num": 28
              }, 
              {
                "text": "Then he lifted his eyes and saw his brother Benjamin, his mother's son, and said, \"Is this your younger brother of whom you spoke to me?\" And he said, \"God be gracious to you, my son.\"", 
                "num": 29
              }, 
              {
                "text": "Now his heart yearned for his brother; so Joseph made haste and sought somewhere to weep. And he went into his chamber and wept there.", 
                "num": 30
              }, 
              {
                "text": "Then he washed his face and came out; and he restrained himself, and said, \"Serve the bread.\"", 
                "num": 31
              }, 
              {
                "text": "So they set him a place by himself, and them by themselves, and the Egyptians who ate with him by themselves; because the Egyptians could not eat food with the Hebrews, for that is an abomination to the Egyptians.", 
                "num": 32
              }, 
              {
                "text": "And they sat before him, the firstborn according to his birthright and the youngest according to his youth; and the men looked in astonishment at one another.", 
                "num": 33
              }, 
              {
                "text": "Then he took servings to them from before him, but Benjamin's serving was five times as much as any of theirs. So they drank and were merry with him.", 
                "num": 34
              }
            ], 
            "num": 43
          }, 
          {
            "verses": [
              {
                "text": "And he commanded the steward of his house, saying, \"Fill the men's sacks with food, as much as they can carry, and put each man's money in the mouth of his sack.", 
                "num": 1
              }, 
              {
                "text": "Also put my cup, the silver cup, in the mouth of the sack of the youngest, and his grain money.\" So he did according to the word that Joseph had spoken.", 
                "num": 2
              }, 
              {
                "text": "As soon as the morning dawned, the men were sent away, they and their donkeys.", 
                "num": 3
              }, 
              {
                "text": "When they had gone out of the city, and were not yet far off, Joseph said to his steward, \"Get up, follow the men; and when you overtake them, say to them, \"Why have you repaid evil for good?", 
                "num": 4
              }, 
              {
                "text": "Is not this the one from which my lord drinks, and with which he indeed practices divination? You have done evil in so doing.\"'", 
                "num": 5
              }, 
              {
                "text": "So he overtook them, and he spoke to them these same words.", 
                "num": 6
              }, 
              {
                "text": "And they said to him, \"Why does my lord say these words? Far be it from us that your servants should do such a thing.", 
                "num": 7
              }, 
              {
                "text": "Look, we brought back to you from the land of Canaan the money which we found in the mouth of our sacks. How then could we steal silver or gold from your lord's house?", 
                "num": 8
              }, 
              {
                "text": "With whomever of your servants it is found, let him die, and we also will be my lord's slaves.\"", 
                "num": 9
              }, 
              {
                "text": "And he said, \"Now also let it be according to your words; he with whom it is found shall be my slave, and you shall be blameless.\"", 
                "num": 10
              }, 
              {
                "text": "Then each man speedily let down his sack to the ground, and each opened his sack.", 
                "num": 11
              }, 
              {
                "text": "So he searched. He began with the oldest and left off with the youngest; and the cup was found in Benjamin's sack.", 
                "num": 12
              }, 
              {
                "text": "Then they tore their clothes, and each man loaded his donkey and returned to the city.", 
                "num": 13
              }, 
              {
                "text": "So Judah and his brothers came to Joseph's house, and he was still there; and they fell before him on the ground.", 
                "num": 14
              }, 
              {
                "text": "And Joseph said to them, \"What deed is this you have done? Did you not know that such a man as I can certainly practice divination?\"", 
                "num": 15
              }, 
              {
                "text": "Then Judah said, \"What shall we say to my lord? What shall we speak? Or how shall we clear ourselves? God has found out the iniquity of your servants; here we are, my lord's slaves, both we and he also with whom the cup was found.\"", 
                "num": 16
              }, 
              {
                "text": "But he said, \"Far be it from me that I should do so; the man in whose hand the cup was found, he shall be my slave. And as for you, go up in peace to your father.\"", 
                "num": 17
              }, 
              {
                "text": "Then Judah came near to him and said: \"O my lord, please let your servant speak a word in my lord's hearing, and do not let your anger burn against your servant; for you are even like Pharaoh.", 
                "num": 18
              }, 
              {
                "text": "My lord asked his servants, saying, \"Have you a father or a brother?'", 
                "num": 19
              }, 
              {
                "text": "And we said to my lord, \"We have a father, an old man, and a child of his old age, who is young; his brother is dead, and he alone is left of his mother's children, and his father loves him.'", 
                "num": 20
              }, 
              {
                "text": "Then you said to your servants, \"Bring him down to me, that I may set my eyes on him.'", 
                "num": 21
              }, 
              {
                "text": "And we said to my lord, \"The lad cannot leave his father, for if he should leave his father, his father would die.'", 
                "num": 22
              }, 
              {
                "text": "But you said to your servants, \"Unless your youngest brother comes down with you, you shall see my face no more.'", 
                "num": 23
              }, 
              {
                "text": "\"So it was, when we went up to your servant my father, that we told him the words of my lord.", 
                "num": 24
              }, 
              {
                "text": "And our father said, \"Go back and buy us a little food.'", 
                "num": 25
              }, 
              {
                "text": "But we said, \"We cannot go down; if our youngest brother is with us, then we will go down; for we may not see the man's face unless our youngest brother is with us.'", 
                "num": 26
              }, 
              {
                "text": "Then your servant my father said to us, \"You know that my wife bore me two sons;", 
                "num": 27
              }, 
              {
                "text": "and the one went out from me, and I said, \"Surely he is torn to pieces\"; and I have not seen him since.", 
                "num": 28
              }, 
              {
                "text": "But if you take this one also from me, and calamity befalls him, you shall bring down my gray hair with sorrow to the grave.'", 
                "num": 29
              }, 
              {
                "text": "\"Now therefore, when I come to your servant my father, and the lad is not with us, since his life is bound up in the lad's life,", 
                "num": 30
              }, 
              {
                "text": "it will happen, when he sees that the lad is not with us, that he will die. So your servants will bring down the gray hair of your servant our father with sorrow to the grave.", 
                "num": 31
              }, 
              {
                "text": "For your servant became surety for the lad to my father, saying, \"If I do not bring him back to you, then I shall bear the blame before my father forever.'", 
                "num": 32
              }, 
              {
                "text": "Now therefore, please let your servant remain instead of the lad as a slave to my lord, and let the lad go up with his brothers.", 
                "num": 33
              }, 
              {
                "text": "For how shall I go up to my father if the lad is not with me, lest perhaps I see the evil that would come upon my father?\"", 
                "num": 34
              }
            ], 
            "num": 44
          }, 
          {
            "verses": [
              {
                "text": "Then Joseph could not restrain himself before all those who stood by him, and he cried out, \"Make everyone go out from me!\" So no one stood with him while Joseph made himself known to his brothers.", 
                "num": 1
              }, 
              {
                "text": "And he wept aloud, and the Egyptians and the house of Pharaoh heard it.", 
                "num": 2
              }, 
              {
                "text": "Then Joseph said to his brothers, \"I am Joseph; does my father still live?\" But his brothers could not answer him, for they were dismayed in his presence.", 
                "num": 3
              }, 
              {
                "text": "And Joseph said to his brothers, \"Please come near to me.\" So they came near. Then he said: \"I am Joseph your brother, whom you sold into Egypt.", 
                "num": 4
              }, 
              {
                "text": "But now, do not therefore be grieved or angry with yourselves because you sold me here; for God sent me before you to preserve life.", 
                "num": 5
              }, 
              {
                "text": "For these two years the famine has been in the land, and there are still five years in which there will be neither plowing nor harvesting.", 
                "num": 6
              }, 
              {
                "text": "And God sent me before you to preserve a posterity for you in the earth, and to save your lives by a great deliverance.", 
                "num": 7
              }, 
              {
                "text": "So now it was not you who sent me here, but God; and He has made me a father to Pharaoh, and lord of all his house, and a ruler throughout all the land of Egypt.", 
                "num": 8
              }, 
              {
                "text": "\"Hurry and go up to my father, and say to him, \"Thus says your son Joseph: \"God has made me lord of all Egypt; come down to me, do not tarry.", 
                "num": 9
              }, 
              {
                "text": "You shall dwell in the land of Goshen, and you shall be near to me, you and your children, your children's children, your flocks and your herds, and all that you have.", 
                "num": 10
              }, 
              {
                "text": "There I will provide for you, lest you and your household, and all that you have, come to poverty; for there are still five years of famine.\"'", 
                "num": 11
              }, 
              {
                "text": "\"And behold, your eyes and the eyes of my brother Benjamin see that it is my mouth that speaks to you.", 
                "num": 12
              }, 
              {
                "text": "So you shall tell my father of all my glory in Egypt, and of all that you have seen; and you shall hurry and bring my father down here.\"", 
                "num": 13
              }, 
              {
                "text": "Then he fell on his brother Benjamin's neck and wept, and Benjamin wept on his neck.", 
                "num": 14
              }, 
              {
                "text": "Moreover he kissed all his brothers and wept over them, and after that his brothers talked with him.", 
                "num": 15
              }, 
              {
                "text": "Now the report of it was heard in Pharaoh's house, saying, \"Joseph's brothers have come.\" So it pleased Pharaoh and his servants well.", 
                "num": 16
              }, 
              {
                "text": "And Pharaoh said to Joseph, \"Say to your brothers, \"Do this: Load your animals and depart; go to the land of Canaan.", 
                "num": 17
              }, 
              {
                "text": "Bring your father and your households and come to me; I will give you the best of the land of Egypt, and you will eat the fat of the land.", 
                "num": 18
              }, 
              {
                "text": "Now you are commanded--do this: Take carts out of the land of Egypt for your little ones and your wives; bring your father and come.", 
                "num": 19
              }, 
              {
                "text": "Also do not be concerned about your goods, for the best of all the land of Egypt is yours.\"'", 
                "num": 20
              }, 
              {
                "text": "Then the sons of Israel did so; and Joseph gave them carts, according to the command of Pharaoh, and he gave them provisions for the journey.", 
                "num": 21
              }, 
              {
                "text": "He gave to all of them, to each man, changes of garments; but to Benjamin he gave three hundred pieces of silver and five changes of garments.", 
                "num": 22
              }, 
              {
                "text": "And he sent to his father these things: ten donkeys loaded with the good things of Egypt, and ten female donkeys loaded with grain, bread, and food for his father for the journey.", 
                "num": 23
              }, 
              {
                "text": "So he sent his brothers away, and they departed; and he said to them, \"See that you do not become troubled along the way.\"", 
                "num": 24
              }, 
              {
                "text": "Then they went up out of Egypt, and came to the land of Canaan to Jacob their father.", 
                "num": 25
              }, 
              {
                "text": "And they told him, saying, \"Joseph is still alive, and he is governor over all the land of Egypt.\" And Jacob's heart stood still, because he did not believe them.", 
                "num": 26
              }, 
              {
                "text": "But when they told him all the words which Joseph had said to them, and when he saw the carts which Joseph had sent to carry him, the spirit of Jacob their father revived.", 
                "num": 27
              }, 
              {
                "text": "Then Israel said, \"It is enough. Joseph my son is still alive. I will go and see him before I die.\"", 
                "num": 28
              }
            ], 
            "num": 45
          }, 
          {
            "verses": [
              {
                "text": "So Israel took his journey with all that he had, and came to Beersheba, and offered sacrifices to the God of his father Isaac.", 
                "num": 1
              }, 
              {
                "text": "Then God spoke to Israel in the visions of the night, and said, \"Jacob, Jacob!\" And he said, \"Here I am.\"", 
                "num": 2
              }, 
              {
                "text": "So He said, \"I am God, the God of your father; do not fear to go down to Egypt, for I will make of you a great nation there.", 
                "num": 3
              }, 
              {
                "text": "I will go down with you to Egypt, and I will also surely bring you up again; and Joseph will put his hand on your eyes.\"", 
                "num": 4
              }, 
              {
                "text": "Then Jacob arose from Beersheba; and the sons of Israel carried their father Jacob, their little ones, and their wives, in the carts which Pharaoh had sent to carry him.", 
                "num": 5
              }, 
              {
                "text": "So they took their livestock and their goods, which they had acquired in the land of Canaan, and went to Egypt, Jacob and all his descendants with him.", 
                "num": 6
              }, 
              {
                "text": "His sons and his sons' sons, his daughters and his sons' daughters, and all his descendants he brought with him to Egypt.", 
                "num": 7
              }, 
              {
                "text": "Now these were the names of the children of Israel, Jacob and his sons, who went to Egypt: Reuben was Jacob's firstborn.", 
                "num": 8
              }, 
              {
                "text": "The sons of Reuben were Hanoch, Pallu, Hezron, and Carmi.", 
                "num": 9
              }, 
              {
                "text": "The sons of Simeon were Jemuel, Jamin, Ohad, Jachin, Zohar, and Shaul, the son of a Canaanite woman.", 
                "num": 10
              }, 
              {
                "text": "The sons of Levi were Gershon, Kohath, and Merari.", 
                "num": 11
              }, 
              {
                "text": "The sons of Judah were Er, Onan, Shelah, Perez, and Zerah (but Er and Onan died in the land of Canaan). The sons of Perez were Hezron and Hamul.", 
                "num": 12
              }, 
              {
                "text": "The sons of Issachar were Tola, Puvah, Job, and Shimron.", 
                "num": 13
              }, 
              {
                "text": "The sons of Zebulun were Sered, Elon, and Jahleel.", 
                "num": 14
              }, 
              {
                "text": "These were the sons of Leah, whom she bore to Jacob in Padan Aram, with his daughter Dinah. All the persons, his sons and his daughters, were thirty-three.", 
                "num": 15
              }, 
              {
                "text": "The sons of Gad were Ziphion, Haggi, Shuni, Ezbon, Eri, Arodi, and Areli.", 
                "num": 16
              }, 
              {
                "text": "The sons of Asher were Jimnah, Ishuah, Isui, Beriah, and Serah, their sister. And the sons of Beriah were Heber and Malchiel.", 
                "num": 17
              }, 
              {
                "text": "These were the sons of Zilpah, whom Laban gave to Leah his daughter; and these she bore to Jacob: sixteen persons.", 
                "num": 18
              }, 
              {
                "text": "The sons of Rachel, Jacob's wife, were Joseph and Benjamin.", 
                "num": 19
              }, 
              {
                "text": "And to Joseph in the land of Egypt were born Manasseh and Ephraim, whom Asenath, the daughter of Poti-Pherah priest of On, bore to him.", 
                "num": 20
              }, 
              {
                "text": "The sons of Benjamin were Belah, Becher, Ashbel, Gera, Naaman, Ehi, Rosh, Muppim, Huppim, and Ard.", 
                "num": 21
              }, 
              {
                "text": "These were the sons of Rachel, who were born to Jacob: fourteen persons in all.", 
                "num": 22
              }, 
              {
                "text": "The son of Dan was Hushim.", 
                "num": 23
              }, 
              {
                "text": "The sons of Naphtali were Jahzeel, Guni, Jezer, and Shillem.", 
                "num": 24
              }, 
              {
                "text": "These were the sons of Bilhah, whom Laban gave to Rachel his daughter, and she bore these to Jacob: seven persons in all.", 
                "num": 25
              }, 
              {
                "text": "All the persons who went with Jacob to Egypt, who came from his body, besides Jacob's sons' wives, were sixty-six persons in all.", 
                "num": 26
              }, 
              {
                "text": "And the sons of Joseph who were born to him in Egypt were two persons. All the persons of the house of Jacob who went to Egypt were seventy.", 
                "num": 27
              }, 
              {
                "text": "Then he sent Judah before him to Joseph, to point out before him the way to Goshen. And they came to the land of Goshen.", 
                "num": 28
              }, 
              {
                "text": "So Joseph made ready his chariot and went up to Goshen to meet his father Israel; and he presented himself to him, and fell on his neck and wept on his neck a good while.", 
                "num": 29
              }, 
              {
                "text": "And Israel said to Joseph, \"Now let me die, since I have seen your face, because you are still alive.\"", 
                "num": 30
              }, 
              {
                "text": "Then Joseph said to his brothers and to his father's household, \"I will go up and tell Pharaoh, and say to him, \"My brothers and those of my father's house, who were in the land of Canaan, have come to me.", 
                "num": 31
              }, 
              {
                "text": "And the men are shepherds, for their occupation has been to feed livestock; and they have brought their flocks, their herds, and all that they have.'", 
                "num": 32
              }, 
              {
                "text": "So it shall be, when Pharaoh calls you and says, \"What is your occupation?'", 
                "num": 33
              }, 
              {
                "text": "that you shall say, \"Your servants' occupation has been with livestock from our youth even till now, both we and also our fathers,' that you may dwell in the land of Goshen; for every shepherd is an abomination to the Egyptians.\"", 
                "num": 34
              }
            ], 
            "num": 46
          }, 
          {
            "verses": [
              {
                "text": "Then Joseph went and told Pharaoh, and said, \"My father and my brothers, their flocks and their herds and all that they possess, have come from the land of Canaan; and indeed they are in the land of Goshen.\"", 
                "num": 1
              }, 
              {
                "text": "And he took five men from among his brothers and presented them to Pharaoh.", 
                "num": 2
              }, 
              {
                "text": "Then Pharaoh said to his brothers, \"What is your occupation?\" And they said to Pharaoh, \"Your servants are shepherds, both we and also our fathers.\"", 
                "num": 3
              }, 
              {
                "text": "And they said to Pharaoh, \"We have come to dwell in the land, because your servants have no pasture for their flocks, for the famine is severe in the land of Canaan. Now therefore, please let your servants dwell in the land of Goshen.\"", 
                "num": 4
              }, 
              {
                "text": "Then Pharaoh spoke to Joseph, saying, \"Your father and your brothers have come to you.", 
                "num": 5
              }, 
              {
                "text": "The land of Egypt is before you. Have your father and brothers dwell in the best of the land; let them dwell in the land of Goshen. And if you know any competent men among them, then make them chief herdsmen over my livestock.\"", 
                "num": 6
              }, 
              {
                "text": "Then Joseph brought in his father Jacob and set him before Pharaoh; and Jacob blessed Pharaoh.", 
                "num": 7
              }, 
              {
                "text": "Pharaoh said to Jacob, \"How old are you?\"", 
                "num": 8
              }, 
              {
                "text": "And Jacob said to Pharaoh, \"The days of the years of my pilgrimage are one hundred and thirty years; few and evil have been the days of the years of my life, and they have not attained to the days of the years of the life of my fathers in the days of their pilgrimage.\"", 
                "num": 9
              }, 
              {
                "text": "So Jacob blessed Pharaoh, and went out from before Pharaoh.", 
                "num": 10
              }, 
              {
                "text": "And Joseph situated his father and his brothers, and gave them a possession in the land of Egypt, in the best of the land, in the land of Rameses, as Pharaoh had commanded.", 
                "num": 11
              }, 
              {
                "text": "Then Joseph provided his father, his brothers, and all his father's household with bread, according to the number in their families.", 
                "num": 12
              }, 
              {
                "text": "Now there was no bread in all the land; for the famine was very severe, so that the land of Egypt and the land of Canaan languished because of the famine.", 
                "num": 13
              }, 
              {
                "text": "And Joseph gathered up all the money that was found in the land of Egypt and in the land of Canaan, for the grain which they bought; and Joseph brought the money into Pharaoh's house.", 
                "num": 14
              }, 
              {
                "text": "So when the money failed in the land of Egypt and in the land of Canaan, all the Egyptians came to Joseph and said, \"Give us bread, for why should we die in your presence? For the money has failed.\"", 
                "num": 15
              }, 
              {
                "text": "Then Joseph said, \"Give your livestock, and I will give you bread for your livestock, if the money is gone.\"", 
                "num": 16
              }, 
              {
                "text": "So they brought their livestock to Joseph, and Joseph gave them bread in exchange for the horses, the flocks, the cattle of the herds, and for the donkeys. Thus he fed them with bread in exchange for all their livestock that year.", 
                "num": 17
              }, 
              {
                "text": "When that year had ended, they came to him the next year and said to him, \"We will not hide from my lord that our money is gone; my lord also has our herds of livestock. There is nothing left in the sight of my lord but our bodies and our lands.", 
                "num": 18
              }, 
              {
                "text": "Why should we die before your eyes, both we and our land? Buy us and our land for bread, and we and our land will be servants of Pharaoh; give us seed, that we may live and not die, that the land may not be desolate.\"", 
                "num": 19
              }, 
              {
                "text": "Then Joseph bought all the land of Egypt for Pharaoh; for every man of the Egyptians sold his field, because the famine was severe upon them. So the land became Pharaoh's.", 
                "num": 20
              }, 
              {
                "text": "And as for the people, he moved them into the cities, from one end of the borders of Egypt to the other end.", 
                "num": 21
              }, 
              {
                "text": "Only the land of the priests he did not buy; for the priests had rations allotted to them by Pharaoh, and they ate their rations which Pharaoh gave them; therefore they did not sell their lands.", 
                "num": 22
              }, 
              {
                "text": "Then Joseph said to the people, \"Indeed I have bought you and your land this day for Pharaoh. Look, here is seed for you, and you shall sow the land.", 
                "num": 23
              }, 
              {
                "text": "And it shall come to pass in the harvest that you shall give one-fifth to Pharaoh. Four-fifths shall be your own, as seed for the field and for your food, for those of your households and as food for your little ones.\"", 
                "num": 24
              }, 
              {
                "text": "So they said, \"You have saved our lives; let us find favor in the sight of my lord, and we will be Pharaoh's servants.\"", 
                "num": 25
              }, 
              {
                "text": "And Joseph made it a law over the land of Egypt to this day, that Pharaoh should have one-fifth, except for the land of the priests only, which did not become Pharaoh's.", 
                "num": 26
              }, 
              {
                "text": "So Israel dwelt in the land of Egypt, in the country of Goshen; and they had possessions there and grew and multiplied exceedingly.", 
                "num": 27
              }, 
              {
                "text": "And Jacob lived in the land of Egypt seventeen years. So the length of Jacob's life was one hundred and forty-seven years.", 
                "num": 28
              }, 
              {
                "text": "When the time drew near that Israel must die, he called his son Joseph and said to him, \"Now if I have found favor in your sight, please put your hand under my thigh, and deal kindly and truly with me. Please do not bury me in Egypt,", 
                "num": 29
              }, 
              {
                "text": "but let me lie with my fathers; you shall carry me out of Egypt and bury me in their burial place.\" And he said, \"I will do as you have said.\"", 
                "num": 30
              }, 
              {
                "text": "Then he said, \"Swear to me.\" And he swore to him. So Israel bowed himself on the head of the bed.", 
                "num": 31
              }
            ], 
            "num": 47
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass after these things that Joseph was told, \"Indeed your father is sick\"; and he took with him his two sons, Manasseh and Ephraim.", 
                "num": 1
              }, 
              {
                "text": "And Jacob was told, \"Look, your son Joseph is coming to you\"; and Israel strengthened himself and sat up on the bed.", 
                "num": 2
              }, 
              {
                "text": "Then Jacob said to Joseph: \"God Almighty appeared to me at Luz in the land of Canaan and blessed me,", 
                "num": 3
              }, 
              {
                "text": "and said to me, \"Behold, I will make you fruitful and multiply you, and I will make of you a multitude of people, and give this land to your descendants after you as an everlasting possession.'", 
                "num": 4
              }, 
              {
                "text": "And now your two sons, Ephraim and Manasseh, who were born to you in the land of Egypt before I came to you in Egypt, are mine; as Reuben and Simeon, they shall be mine.", 
                "num": 5
              }, 
              {
                "text": "Your offspring whom you beget after them shall be yours; they will be called by the name of their brothers in their inheritance.", 
                "num": 6
              }, 
              {
                "text": "But as for me, when I came from Padan, Rachel died beside me in the land of Canaan on the way, when there was but a little distance to go to Ephrath; and I buried her there on the way to Ephrath (that is, Bethlehem).\"", 
                "num": 7
              }, 
              {
                "text": "Then Israel saw Joseph's sons, and said, \"Who are these?\"", 
                "num": 8
              }, 
              {
                "text": "Joseph said to his father, \"They are my sons, whom God has given me in this place.\" And he said, \"Please bring them to me, and I will bless them.\"", 
                "num": 9
              }, 
              {
                "text": "Now the eyes of Israel were dim with age, so that he could not see. Then Joseph brought them near him, and he kissed them and embraced them.", 
                "num": 10
              }, 
              {
                "text": "And Israel said to Joseph, \"I had not thought to see your face; but in fact, God has also shown me your offspring!\"", 
                "num": 11
              }, 
              {
                "text": "So Joseph brought them from beside his knees, and he bowed down with his face to the earth.", 
                "num": 12
              }, 
              {
                "text": "And Joseph took them both, Ephraim with his right hand toward Israel's left hand, and Manasseh with his left hand toward Israel's right hand, and brought them near him.", 
                "num": 13
              }, 
              {
                "text": "Then Israel stretched out his right hand and laid it on Ephraim's head, who was the younger, and his left hand on Manasseh's head, guiding his hands knowingly, for Manasseh was the firstborn.", 
                "num": 14
              }, 
              {
                "text": "And he blessed Joseph, and said: \"God, before whom my fathers Abraham and Isaac walked, The God who has fed me all my life long to this day,", 
                "num": 15
              }, 
              {
                "text": "The Angel who has redeemed me from all evil, Bless the lads; Let my name be named upon them, And the name of my fathers Abraham and Isaac; And let them grow into a multitude in the midst of the earth.\"", 
                "num": 16
              }, 
              {
                "text": "Now when Joseph saw that his father laid his right hand on the head of Ephraim, it displeased him; so he took hold of his father's hand to remove it from Ephraim's head to Manasseh's head.", 
                "num": 17
              }, 
              {
                "text": "And Joseph said to his father, \"Not so, my father, for this one is the firstborn; put your right hand on his head.\"", 
                "num": 18
              }, 
              {
                "text": "But his father refused and said, \"I know, my son, I know. He also shall become a people, and he also shall be great; but truly his younger brother shall be greater than he, and his descendants shall become a multitude of nations.\"", 
                "num": 19
              }, 
              {
                "text": "So he blessed them that day, saying, \"By you Israel will bless, saying, \"May God make you as Ephraim and as Manasseh!\"' And thus he set Ephraim before Manasseh.", 
                "num": 20
              }, 
              {
                "text": "Then Israel said to Joseph, \"Behold, I am dying, but God will be with you and bring you back to the land of your fathers.", 
                "num": 21
              }, 
              {
                "text": "Moreover I have given to you one portion above your brothers, which I took from the hand of the Amorite with my sword and my bow.\"", 
                "num": 22
              }
            ], 
            "num": 48
          }, 
          {
            "verses": [
              {
                "text": "And Jacob called his sons and said, \"Gather together, that I may tell you what shall befall you in the last days:", 
                "num": 1
              }, 
              {
                "text": "\"Gather together and hear, you sons of Jacob, And listen to Israel your father.", 
                "num": 2
              }, 
              {
                "text": "\"Reuben, you are my firstborn, My might and the beginning of my strength, The excellency of dignity and the excellency of power.", 
                "num": 3
              }, 
              {
                "text": "Unstable as water, you shall not excel, Because you went up to your father's bed; Then you defiled it-- He went up to my couch.", 
                "num": 4
              }, 
              {
                "text": "\"Simeon and Levi are brothers; Instruments of cruelty are in their dwelling place.", 
                "num": 5
              }, 
              {
                "text": "Let not my soul enter their council; Let not my honor be united to their assembly; For in their anger they slew a man, And in their self-will they hamstrung an ox.", 
                "num": 6
              }, 
              {
                "text": "Cursed be their anger, for it is fierce; And their wrath, for it is cruel! I will divide them in Jacob And scatter them in Israel.", 
                "num": 7
              }, 
              {
                "text": "\"Judah, you are he whom your brothers shall praise; Your hand shall be on the neck of your enemies; Your father's children shall bow down before you.", 
                "num": 8
              }, 
              {
                "text": "Judah is a lion's whelp; From the prey, my son, you have gone up. He bows down, he lies down as a lion; And as a lion, who shall rouse him?", 
                "num": 9
              }, 
              {
                "text": "The scepter shall not depart from Judah, Nor a lawgiver from between his feet, Until Shiloh comes; And to Him shall be the obedience of the people.", 
                "num": 10
              }, 
              {
                "text": "Binding his donkey to the vine, And his donkey's colt to the choice vine, He washed his garments in wine, And his clothes in the blood of grapes.", 
                "num": 11
              }, 
              {
                "text": "His eyes are darker than wine, And his teeth whiter than milk.", 
                "num": 12
              }, 
              {
                "text": "\"Zebulun shall dwell by the haven of the sea; He shall become a haven for ships, And his border shall adjoin Sidon.", 
                "num": 13
              }, 
              {
                "text": "\"Issachar is a strong donkey, Lying down between two burdens;", 
                "num": 14
              }, 
              {
                "text": "He saw that rest was good, And that the land was pleasant; He bowed his shoulder to bear a burden, And became a band of slaves.", 
                "num": 15
              }, 
              {
                "text": "\"Dan shall judge his people As one of the tribes of Israel.", 
                "num": 16
              }, 
              {
                "text": "Dan shall be a serpent by the way, A viper by the path, That bites the horse's heels So that its rider shall fall backward.", 
                "num": 17
              }, 
              {
                "text": "I have waited for your salvation, O LORD!", 
                "num": 18
              }, 
              {
                "text": "\"Gad, a troop shall tramp upon him, But he shall triumph at last.", 
                "num": 19
              }, 
              {
                "text": "\"Bread from Asher shall be rich, And he shall yield royal dainties.", 
                "num": 20
              }, 
              {
                "text": "\"Naphtali is a deer let loose; He uses beautiful words.", 
                "num": 21
              }, 
              {
                "text": "\"Joseph is a fruitful bough, A fruitful bough by a well; His branches run over the wall.", 
                "num": 22
              }, 
              {
                "text": "The archers have bitterly grieved him, Shot at him and hated him.", 
                "num": 23
              }, 
              {
                "text": "But his bow remained in strength, And the arms of his hands were made strong By the hands of the Mighty God of Jacob (From there is the Shepherd, the Stone of Israel),", 
                "num": 24
              }, 
              {
                "text": "By the God of your father who will help you, And by the Almighty who will bless you With blessings of heaven above, Blessings of the deep that lies beneath, Blessings of the breasts and of the womb.", 
                "num": 25
              }, 
              {
                "text": "The blessings of your father Have excelled the blessings of my ancestors, Up to the utmost bound of the everlasting hills. They shall be on the head of Joseph, And on the crown of the head of him who was separate from his brothers.", 
                "num": 26
              }, 
              {
                "text": "\"Benjamin is a ravenous wolf; In the morning he shall devour the prey, And at night he shall divide the spoil.\"", 
                "num": 27
              }, 
              {
                "text": "All these are the twelve tribes of Israel, and this is what their father spoke to them. And he blessed them; he blessed each one according to his own blessing.", 
                "num": 28
              }, 
              {
                "text": "Then he charged them and said to them: \"I am to be gathered to my people; bury me with my fathers in the cave that is in the field of Ephron the Hittite,", 
                "num": 29
              }, 
              {
                "text": "in the cave that is in the field of Machpelah, which is before Mamre in the land of Canaan, which Abraham bought with the field of Ephron the Hittite as a possession for a burial place.", 
                "num": 30
              }, 
              {
                "text": "There they buried Abraham and Sarah his wife, there they buried Isaac and Rebekah his wife, and there I buried Leah.", 
                "num": 31
              }, 
              {
                "text": "The field and the cave that is there were purchased from the sons of Heth.\"", 
                "num": 32
              }, 
              {
                "text": "And when Jacob had finished commanding his sons, he drew his feet up into the bed and breathed his last, and was gathered to his people.", 
                "num": 33
              }
            ], 
            "num": 49
          }, 
          {
            "verses": [
              {
                "text": "Then Joseph fell on his father's face and wept over him, and kissed him.", 
                "num": 1
              }, 
              {
                "text": "And Joseph commanded his servants the physicians to embalm his father. So the physicians embalmed Israel.", 
                "num": 2
              }, 
              {
                "text": "Forty days were required for him, for such are the days required for those who are embalmed; and the Egyptians mourned for him seventy days.", 
                "num": 3
              }, 
              {
                "text": "Now when the days of his mourning were past, Joseph spoke to the household of Pharaoh, saying, \"If now I have found favor in your eyes, please speak in the hearing of Pharaoh, saying,", 
                "num": 4
              }, 
              {
                "text": "\"My father made me swear, saying, \"Behold, I am dying; in my grave which I dug for myself in the land of Canaan, there you shall bury me.\" Now therefore, please let me go up and bury my father, and I will come back.\"'", 
                "num": 5
              }, 
              {
                "text": "And Pharaoh said, \"Go up and bury your father, as he made you swear.\"", 
                "num": 6
              }, 
              {
                "text": "So Joseph went up to bury his father; and with him went up all the servants of Pharaoh, the elders of his house, and all the elders of the land of Egypt,", 
                "num": 7
              }, 
              {
                "text": "as well as all the house of Joseph, his brothers, and his father's house. Only their little ones, their flocks, and their herds they left in the land of Goshen.", 
                "num": 8
              }, 
              {
                "text": "And there went up with him both chariots and horsemen, and it was a very great gathering.", 
                "num": 9
              }, 
              {
                "text": "Then they came to the threshing floor of Atad, which is beyond the Jordan, and they mourned there with a great and very solemn lamentation. He observed seven days of mourning for his father.", 
                "num": 10
              }, 
              {
                "text": "And when the inhabitants of the land, the Canaanites, saw the mourning at the threshing floor of Atad, they said, \"This is a deep mourning of the Egyptians.\" Therefore its name was called Abel Mizraim, which is beyond the Jordan.", 
                "num": 11
              }, 
              {
                "text": "So his sons did for him just as he had commanded them.", 
                "num": 12
              }, 
              {
                "text": "For his sons carried him to the land of Canaan, and buried him in the cave of the field of Machpelah, before Mamre, which Abraham bought with the field from Ephron the Hittite as property for a burial place.", 
                "num": 13
              }, 
              {
                "text": "And after he had buried his father, Joseph returned to Egypt, he and his brothers and all who went up with him to bury his father.", 
                "num": 14
              }, 
              {
                "text": "When Joseph's brothers saw that their father was dead, they said, \"Perhaps Joseph will hate us, and may actually repay us for all the evil which we did to him.\"", 
                "num": 15
              }, 
              {
                "text": "So they sent messengers to Joseph, saying, \"Before your father died he commanded, saying,", 
                "num": 16
              }, 
              {
                "text": "\"Thus you shall say to Joseph: \"I beg you, please forgive the trespass of your brothers and their sin; for they did evil to you.\"' Now, please, forgive the trespass of the servants of the God of your father.\" And Joseph wept when they spoke to him.", 
                "num": 17
              }, 
              {
                "text": "Then his brothers also went and fell down before his face, and they said, \"Behold, we are your servants.\"", 
                "num": 18
              }, 
              {
                "text": "Joseph said to them, \"Do not be afraid, for am I in the place of God?", 
                "num": 19
              }, 
              {
                "text": "But as for you, you meant evil against me; but God meant it for good, in order to bring it about as it is this day, to save many people alive.", 
                "num": 20
              }, 
              {
                "text": "Now therefore, do not be afraid; I will provide for you and your little ones.\" And he comforted them and spoke kindly to them.", 
                "num": 21
              }, 
              {
                "text": "So Joseph dwelt in Egypt, he and his father's household. And Joseph lived one hundred and ten years.", 
                "num": 22
              }, 
              {
                "text": "Joseph saw Ephraim's children to the third generation. The children of Machir, the son of Manasseh, were also brought up on Joseph's knees.", 
                "num": 23
              }, 
              {
                "text": "And Joseph said to his brethren, \"I am dying; but God will surely visit you, and bring you out of this land to the land of which He swore to Abraham, to Isaac, and to Jacob.\"", 
                "num": 24
              }, 
              {
                "text": "Then Joseph took an oath from the children of Israel, saying, \"God will surely visit you, and you shall carry up my bones from here.\"", 
                "num": 25
              }, 
              {
                "text": "So Joseph died, being one hundred and ten years old; and they embalmed him, and he was put in a coffin in Egypt.", 
                "num": 26
              }
            ], 
            "num": 50
          }
        ]
      }, 
      {
        "name": "Exodus", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now these are the names of the children of Israel who came to Egypt; each man and his household came with Jacob:", 
                "num": 1
              }, 
              {
                "text": "Reuben, Simeon, Levi, and Judah;", 
                "num": 2
              }, 
              {
                "text": "Issachar, Zebulun, and Benjamin;", 
                "num": 3
              }, 
              {
                "text": "Dan, Naphtali, Gad, and Asher.", 
                "num": 4
              }, 
              {
                "text": "All those who were descendants of Jacob were seventy persons (for Joseph was in Egypt already).", 
                "num": 5
              }, 
              {
                "text": "And Joseph died, all his brothers, and all that generation.", 
                "num": 6
              }, 
              {
                "text": "But the children of Israel were fruitful and increased abundantly, multiplied and grew exceedingly mighty; and the land was filled with them.", 
                "num": 7
              }, 
              {
                "text": "Now there arose a new king over Egypt, who did not know Joseph.", 
                "num": 8
              }, 
              {
                "text": "And he said to his people, \"Look, the people of the children of Israel are more and mightier than we;", 
                "num": 9
              }, 
              {
                "text": "come, let us deal shrewdly with them, lest they multiply, and it happen, in the event of war, that they also join our enemies and fight against us, and so go up out of the land.\"", 
                "num": 10
              }, 
              {
                "text": "Therefore they set taskmasters over them to afflict them with their burdens. And they built for Pharaoh supply cities, Pithom and Raamses.", 
                "num": 11
              }, 
              {
                "text": "But the more they afflicted them, the more they multiplied and grew. And they were in dread of the children of Israel.", 
                "num": 12
              }, 
              {
                "text": "So the Egyptians made the children of Israel serve with rigor.", 
                "num": 13
              }, 
              {
                "text": "And they made their lives bitter with hard bondage--in mortar, in brick, and in all manner of service in the field. All their service in which they made them serve was with rigor.", 
                "num": 14
              }, 
              {
                "text": "Then the king of Egypt spoke to the Hebrew midwives, of whom the name of one was Shiphrah and the name of the other Puah;", 
                "num": 15
              }, 
              {
                "text": "and he said, \"When you do the duties of a midwife for the Hebrew women, and see them on the birthstools, if it is a son, then you shall kill him; but if it is a daughter, then she shall live.\"", 
                "num": 16
              }, 
              {
                "text": "But the midwives feared God, and did not do as the king of Egypt commanded them, but saved the male children alive.", 
                "num": 17
              }, 
              {
                "text": "So the king of Egypt called for the midwives and said to them, \"Why have you done this thing, and saved the male children alive?\"", 
                "num": 18
              }, 
              {
                "text": "And the midwives said to Pharaoh, \"Because the Hebrew women are not like the Egyptian women; for they are lively and give birth before the midwives come to them.\"", 
                "num": 19
              }, 
              {
                "text": "Therefore God dealt well with the midwives, and the people multiplied and grew very mighty.", 
                "num": 20
              }, 
              {
                "text": "And so it was, because the midwives feared God, that He provided households for them.", 
                "num": 21
              }, 
              {
                "text": "So Pharaoh commanded all his people, saying, \"Every son who is born you shall cast into the river, and every daughter you shall save alive.\"", 
                "num": 22
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And a man of the house of Levi went and took as wife a daughter of Levi.", 
                "num": 1
              }, 
              {
                "text": "So the woman conceived and bore a son. And when she saw that he was a beautiful child, she hid him three months.", 
                "num": 2
              }, 
              {
                "text": "But when she could no longer hide him, she took an ark of bulrushes for him, daubed it with asphalt and pitch, put the child in it, and laid it in the reeds by the river's bank.", 
                "num": 3
              }, 
              {
                "text": "And his sister stood afar off, to know what would be done to him.", 
                "num": 4
              }, 
              {
                "text": "Then the daughter of Pharaoh came down to bathe at the river. And her maidens walked along the riverside; and when she saw the ark among the reeds, she sent her maid to get it.", 
                "num": 5
              }, 
              {
                "text": "And when she opened it, she saw the child, and behold, the baby wept. So she had compassion on him, and said, \"This is one of the Hebrews' children.\"", 
                "num": 6
              }, 
              {
                "text": "Then his sister said to Pharaoh's daughter, \"Shall I go and call a nurse for you from the Hebrew women, that she may nurse the child for you?\"", 
                "num": 7
              }, 
              {
                "text": "And Pharaoh's daughter said to her, \"Go.\" So the maiden went and called the child's mother.", 
                "num": 8
              }, 
              {
                "text": "Then Pharaoh's daughter said to her, \"Take this child away and nurse him for me, and I will give you your wages.\" So the woman took the child and nursed him.", 
                "num": 9
              }, 
              {
                "text": "And the child grew, and she brought him to Pharaoh's daughter, and he became her son. So she called his name Moses, saying, \"Because I drew him out of the water.\"", 
                "num": 10
              }, 
              {
                "text": "Now it came to pass in those days, when Moses was grown, that he went out to his brethren and looked at their burdens. And he saw an Egyptian beating a Hebrew, one of his brethren.", 
                "num": 11
              }, 
              {
                "text": "So he looked this way and that way, and when he saw no one, he killed the Egyptian and hid him in the sand.", 
                "num": 12
              }, 
              {
                "text": "And when he went out the second day, behold, two Hebrew men were fighting, and he said to the one who did the wrong, \"Why are you striking your companion?\"", 
                "num": 13
              }, 
              {
                "text": "Then he said, \"Who made you a prince and a judge over us? Do you intend to kill me as you killed the Egyptian?\" So Moses feared and said, \"Surely this thing is known!\"", 
                "num": 14
              }, 
              {
                "text": "When Pharaoh heard of this matter, he sought to kill Moses. But Moses fled from the face of Pharaoh and dwelt in the land of Midian; and he sat down by a well.", 
                "num": 15
              }, 
              {
                "text": "Now the priest of Midian had seven daughters. And they came and drew water, and they filled the troughs to water their father's flock.", 
                "num": 16
              }, 
              {
                "text": "Then the shepherds came and drove them away; but Moses stood up and helped them, and watered their flock.", 
                "num": 17
              }, 
              {
                "text": "When they came to Reuel their father, he said, \"How is it that you have come so soon today?\"", 
                "num": 18
              }, 
              {
                "text": "And they said, \"An Egyptian delivered us from the hand of the shepherds, and he also drew enough water for us and watered the flock.\"", 
                "num": 19
              }, 
              {
                "text": "So he said to his daughters, \"And where is he? Why is it that you have left the man? Call him, that he may eat bread.\"", 
                "num": 20
              }, 
              {
                "text": "Then Moses was content to live with the man, and he gave Zipporah his daughter to Moses.", 
                "num": 21
              }, 
              {
                "text": "And she bore him a son. He called his name Gershom, for he said, \"I have been a stranger in a foreign land.\"", 
                "num": 22
              }, 
              {
                "text": "Now it happened in the process of time that the king of Egypt died. Then the children of Israel groaned because of the bondage, and they cried out; and their cry came up to God because of the bondage.", 
                "num": 23
              }, 
              {
                "text": "So God heard their groaning, and God remembered His covenant with Abraham, with Isaac, and with Jacob.", 
                "num": 24
              }, 
              {
                "text": "And God looked upon the children of Israel, and God acknowledged them.", 
                "num": 25
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now Moses was tending the flock of Jethro his father-in-law, the priest of Midian. And he led the flock to the back of the desert, and came to Horeb, the mountain of God.", 
                "num": 1
              }, 
              {
                "text": "And the Angel of the LORD appeared to him in a flame of fire from the midst of a bush. So he looked, and behold, the bush was burning with fire, but the bush was not consumed.", 
                "num": 2
              }, 
              {
                "text": "Then Moses said, \"I will now turn aside and see this great sight, why the bush does not burn.\"", 
                "num": 3
              }, 
              {
                "text": "So when the LORD saw that he turned aside to look, God called to him from the midst of the bush and said, \"Moses, Moses!\" And he said, \"Here I am.\"", 
                "num": 4
              }, 
              {
                "text": "Then He said, \"Do not draw near this place. Take your sandals off your feet, for the place where you stand is holy ground.\"", 
                "num": 5
              }, 
              {
                "text": "Moreover He said, \"I am the God of your father--the God of Abraham, the God of Isaac, and the God of Jacob.\" And Moses hid his face, for he was afraid to look upon God.", 
                "num": 6
              }, 
              {
                "text": "And the LORD said: \"I have surely seen the oppression of My people who are in Egypt, and have heard their cry because of their taskmasters, for I know their sorrows.", 
                "num": 7
              }, 
              {
                "text": "So I have come down to deliver them out of the hand of the Egyptians, and to bring them up from that land to a good and large land, to a land flowing with milk and honey, to the place of the Canaanites and the Hittites and the Amorites and the Perizzites and the Hivites and the Jebusites.", 
                "num": 8
              }, 
              {
                "text": "Now therefore, behold, the cry of the children of Israel has come to Me, and I have also seen the oppression with which the Egyptians oppress them.", 
                "num": 9
              }, 
              {
                "text": "Come now, therefore, and I will send you to Pharaoh that you may bring My people, the children of Israel, out of Egypt.\"", 
                "num": 10
              }, 
              {
                "text": "But Moses said to God, \"Who am I that I should go to Pharaoh, and that I should bring the children of Israel out of Egypt?\"", 
                "num": 11
              }, 
              {
                "text": "So He said, \"I will certainly be with you. And this shall be a sign to you that I have sent you: When you have brought the people out of Egypt, you shall serve God on this mountain.\"", 
                "num": 12
              }, 
              {
                "text": "Then Moses said to God, \"Indeed, when I come to the children of Israel and say to them, \"The God of your fathers has sent me to you,' and they say to me, \"What is His name?' what shall I say to them?\"", 
                "num": 13
              }, 
              {
                "text": "And God said to Moses, \"I AM WHO I AM.\" And He said, \"Thus you shall say to the children of Israel, \"I AM has sent me to you.\"'", 
                "num": 14
              }, 
              {
                "text": "Moreover God said to Moses, \"Thus you shall say to the children of Israel: \"The LORD God of your fathers, the God of Abraham, the God of Isaac, and the God of Jacob, has sent me to you. This is My name forever, and this is My memorial to all generations.'", 
                "num": 15
              }, 
              {
                "text": "Go and gather the elders of Israel together, and say to them, \"The LORD God of your fathers, the God of Abraham, of Isaac, and of Jacob, appeared to me, saying, \"I have surely visited you and seen what is done to you in Egypt;", 
                "num": 16
              }, 
              {
                "text": "and I have said I will bring you up out of the affliction of Egypt to the land of the Canaanites and the Hittites and the Amorites and the Perizzites and the Hivites and the Jebusites, to a land flowing with milk and honey.\"'", 
                "num": 17
              }, 
              {
                "text": "Then they will heed your voice; and you shall come, you and the elders of Israel, to the king of Egypt; and you shall say to him, \"The LORD God of the Hebrews has met with us; and now, please, let us go three days' journey into the wilderness, that we may sacrifice to the LORD our God.'", 
                "num": 18
              }, 
              {
                "text": "But I am sure that the king of Egypt will not let you go, no, not even by a mighty hand.", 
                "num": 19
              }, 
              {
                "text": "So I will stretch out My hand and strike Egypt with all My wonders which I will do in its midst; and after that he will let you go.", 
                "num": 20
              }, 
              {
                "text": "And I will give this people favor in the sight of the Egyptians; and it shall be, when you go, that you shall not go empty-handed.", 
                "num": 21
              }, 
              {
                "text": "But every woman shall ask of her neighbor, namely, of her who dwells near her house, articles of silver, articles of gold, and clothing; and you shall put them on your sons and on your daughters. So you shall plunder the Egyptians.\"", 
                "num": 22
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then Moses answered and said, \"But suppose they will not believe me or listen to my voice; suppose they say, \"The LORD has not appeared to you.\"'", 
                "num": 1
              }, 
              {
                "text": "So the LORD said to him, \"What is that in your hand?\" He said, \"A rod.\"", 
                "num": 2
              }, 
              {
                "text": "And He said, \"Cast it on the ground.\" So he cast it on the ground, and it became a serpent; and Moses fled from it.", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said to Moses, \"Reach out your hand and take it by the tail\" (and he reached out his hand and caught it, and it became a rod in his hand),", 
                "num": 4
              }, 
              {
                "text": "\"that they may believe that the LORD God of their fathers, the God of Abraham, the God of Isaac, and the God of Jacob, has appeared to you.\"", 
                "num": 5
              }, 
              {
                "text": "Furthermore the LORD said to him, \"Now put your hand in your bosom.\" And he put his hand in his bosom, and when he took it out, behold, his hand was leprous, like snow.", 
                "num": 6
              }, 
              {
                "text": "And He said, \"Put your hand in your bosom again.\" So he put his hand in his bosom again, and drew it out of his bosom, and behold, it was restored like his other flesh.", 
                "num": 7
              }, 
              {
                "text": "\"Then it will be, if they do not believe you, nor heed the message of the first sign, that they may believe the message of the latter sign.", 
                "num": 8
              }, 
              {
                "text": "And it shall be, if they do not believe even these two signs, or listen to your voice, that you shall take water from the river and pour it on the dry land. The water which you take from the river will become blood on the dry land.\"", 
                "num": 9
              }, 
              {
                "text": "Then Moses said to the LORD, \"O my Lord, I am not eloquent, neither before nor since You have spoken to Your servant; but I am slow of speech and slow of tongue.\"", 
                "num": 10
              }, 
              {
                "text": "So the LORD said to him, \"Who has made man's mouth? Or who makes the mute, the deaf, the seeing, or the blind? Have not I, the LORD?", 
                "num": 11
              }, 
              {
                "text": "Now therefore, go, and I will be with your mouth and teach you what you shall say.\"", 
                "num": 12
              }, 
              {
                "text": "But he said, \"O my Lord, please send by the hand of whomever else You may send.\"", 
                "num": 13
              }, 
              {
                "text": "So the anger of the LORD was kindled against Moses, and He said: \"Is not Aaron the Levite your brother? I know that he can speak well. And look, he is also coming out to meet you. When he sees you, he will be glad in his heart.", 
                "num": 14
              }, 
              {
                "text": "Now you shall speak to him and put the words in his mouth. And I will be with your mouth and with his mouth, and I will teach you what you shall do.", 
                "num": 15
              }, 
              {
                "text": "So he shall be your spokesman to the people. And he himself shall be as a mouth for you, and you shall be to him as God.", 
                "num": 16
              }, 
              {
                "text": "And you shall take this rod in your hand, with which you shall do the signs.\"", 
                "num": 17
              }, 
              {
                "text": "So Moses went and returned to Jethro his father-in-law, and said to him, \"Please let me go and return to my brethren who are in Egypt, and see whether they are still alive.\" And Jethro said to Moses, \"Go in peace.\"", 
                "num": 18
              }, 
              {
                "text": "Now the LORD said to Moses in Midian, \"Go, return to Egypt; for all the men who sought your life are dead.\"", 
                "num": 19
              }, 
              {
                "text": "Then Moses took his wife and his sons and set them on a donkey, and he returned to the land of Egypt. And Moses took the rod of God in his hand.", 
                "num": 20
              }, 
              {
                "text": "And the LORD said to Moses, \"When you go back to Egypt, see that you do all those wonders before Pharaoh which I have put in your hand. But I will harden his heart, so that he will not let the people go.", 
                "num": 21
              }, 
              {
                "text": "Then you shall say to Pharaoh, \"Thus says the LORD: \"Israel is My son, My firstborn.", 
                "num": 22
              }, 
              {
                "text": "So I say to you, let My son go that he may serve Me. But if you refuse to let him go, indeed I will kill your son, your firstborn.\"\"'", 
                "num": 23
              }, 
              {
                "text": "And it came to pass on the way, at the encampment, that the LORD met him and sought to kill him.", 
                "num": 24
              }, 
              {
                "text": "Then Zipporah took a sharp stone and cut off the foreskin of her son and cast it at Moses' feet, and said, \"Surely you are a husband of blood to me!\"", 
                "num": 25
              }, 
              {
                "text": "So He let him go. Then she said, \"You are a husband of blood!\"--because of the circumcision.", 
                "num": 26
              }, 
              {
                "text": "And the LORD said to Aaron, \"Go into the wilderness to meet Moses.\" So he went and met him on the mountain of God, and kissed him.", 
                "num": 27
              }, 
              {
                "text": "So Moses told Aaron all the words of the LORD who had sent him, and all the signs which He had commanded him.", 
                "num": 28
              }, 
              {
                "text": "Then Moses and Aaron went and gathered together all the elders of the children of Israel.", 
                "num": 29
              }, 
              {
                "text": "And Aaron spoke all the words which the LORD had spoken to Moses. Then he did the signs in the sight of the people.", 
                "num": 30
              }, 
              {
                "text": "So the people believed; and when they heard that the LORD had visited the children of Israel and that He had looked on their affliction, then they bowed their heads and worshiped.", 
                "num": 31
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Afterward Moses and Aaron went in and told Pharaoh, \"Thus says the LORD God of Israel: \"Let My people go, that they may hold a feast to Me in the wilderness.\"'", 
                "num": 1
              }, 
              {
                "text": "And Pharaoh said, \"Who is the LORD, that I should obey His voice to let Israel go? I do not know the LORD, nor will I let Israel go.\"", 
                "num": 2
              }, 
              {
                "text": "So they said, \"The God of the Hebrews has met with us. Please, let us go three days' journey into the desert and sacrifice to the LORD our God, lest He fall upon us with pestilence or with the sword.\"", 
                "num": 3
              }, 
              {
                "text": "Then the king of Egypt said to them, \"Moses and Aaron, why do you take the people from their work? Get back to your labor.\"", 
                "num": 4
              }, 
              {
                "text": "And Pharaoh said, \"Look, the people of the land are many now, and you make them rest from their labor!\"", 
                "num": 5
              }, 
              {
                "text": "So the same day Pharaoh commanded the taskmasters of the people and their officers, saying,", 
                "num": 6
              }, 
              {
                "text": "\"You shall no longer give the people straw to make brick as before. Let them go and gather straw for themselves.", 
                "num": 7
              }, 
              {
                "text": "And you shall lay on them the quota of bricks which they made before. You shall not reduce it. For they are idle; therefore they cry out, saying, \"Let us go and sacrifice to our God.'", 
                "num": 8
              }, 
              {
                "text": "Let more work be laid on the men, that they may labor in it, and let them not regard false words.\"", 
                "num": 9
              }, 
              {
                "text": "And the taskmasters of the people and their officers went out and spoke to the people, saying, \"Thus says Pharaoh: \"I will not give you straw.", 
                "num": 10
              }, 
              {
                "text": "Go, get yourselves straw where you can find it; yet none of your work will be reduced.\"'", 
                "num": 11
              }, 
              {
                "text": "So the people were scattered abroad throughout all the land of Egypt to gather stubble instead of straw.", 
                "num": 12
              }, 
              {
                "text": "And the taskmasters forced them to hurry, saying, \"Fulfill your work, your daily quota, as when there was straw.\"", 
                "num": 13
              }, 
              {
                "text": "Also the officers of the children of Israel, whom Pharaoh's taskmasters had set over them, were beaten and were asked, \"Why have you not fulfilled your task in making brick both yesterday and today, as before?\"", 
                "num": 14
              }, 
              {
                "text": "Then the officers of the children of Israel came and cried out to Pharaoh, saying, \"Why are you dealing thus with your servants?", 
                "num": 15
              }, 
              {
                "text": "There is no straw given to your servants, and they say to us, \"Make brick!' And indeed your servants are beaten, but the fault is in your own people.\"", 
                "num": 16
              }, 
              {
                "text": "But he said, \"You are idle! Idle! Therefore you say, \"Let us go and sacrifice to the LORD.'", 
                "num": 17
              }, 
              {
                "text": "Therefore go now and work; for no straw shall be given you, yet you shall deliver the quota of bricks.\"", 
                "num": 18
              }, 
              {
                "text": "And the officers of the children of Israel saw that they were in trouble after it was said, \"You shall not reduce any bricks from your daily quota.\"", 
                "num": 19
              }, 
              {
                "text": "Then, as they came out from Pharaoh, they met Moses and Aaron who stood there to meet them.", 
                "num": 20
              }, 
              {
                "text": "And they said to them, \"Let the LORD look on you and judge, because you have made us abhorrent in the sight of Pharaoh and in the sight of his servants, to put a sword in their hand to kill us.\"", 
                "num": 21
              }, 
              {
                "text": "So Moses returned to the LORD and said, \"Lord, why have You brought trouble on this people? Why is it You have sent me?", 
                "num": 22
              }, 
              {
                "text": "For since I came to Pharaoh to speak in Your name, he has done evil to this people; neither have You delivered Your people at all.\"", 
                "num": 23
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to Moses, \"Now you shall see what I will do to Pharaoh. For with a strong hand he will let them go, and with a strong hand he will drive them out of his land.\"", 
                "num": 1
              }, 
              {
                "text": "And God spoke to Moses and said to him: \"I am the LORD.", 
                "num": 2
              }, 
              {
                "text": "I appeared to Abraham, to Isaac, and to Jacob, as God Almighty, but by My name LORD I was not known to them.", 
                "num": 3
              }, 
              {
                "text": "I have also established My covenant with them, to give them the land of Canaan, the land of their pilgrimage, in which they were strangers.", 
                "num": 4
              }, 
              {
                "text": "And I have also heard the groaning of the children of Israel whom the Egyptians keep in bondage, and I have remembered My covenant.", 
                "num": 5
              }, 
              {
                "text": "Therefore say to the children of Israel: \"I am the LORD; I will bring you out from under the burdens of the Egyptians, I will rescue you from their bondage, and I will redeem you with an outstretched arm and with great judgments.", 
                "num": 6
              }, 
              {
                "text": "I will take you as My people, and I will be your God. Then you shall know that I am the LORD your God who brings you out from under the burdens of the Egyptians.", 
                "num": 7
              }, 
              {
                "text": "And I will bring you into the land which I swore to give to Abraham, Isaac, and Jacob; and I will give it to you as a heritage: I am the LORD.\"'", 
                "num": 8
              }, 
              {
                "text": "So Moses spoke thus to the children of Israel; but they did not heed Moses, because of anguish of spirit and cruel bondage.", 
                "num": 9
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Go in, tell Pharaoh king of Egypt to let the children of Israel go out of his land.\"", 
                "num": 11
              }, 
              {
                "text": "And Moses spoke before the LORD, saying, \"The children of Israel have not heeded me. How then shall Pharaoh heed me, for I am of uncircumcised lips?\"", 
                "num": 12
              }, 
              {
                "text": "Then the LORD spoke to Moses and Aaron, and gave them a command for the children of Israel and for Pharaoh king of Egypt, to bring the children of Israel out of the land of Egypt.", 
                "num": 13
              }, 
              {
                "text": "These are the heads of their fathers' houses: The sons of Reuben, the firstborn of Israel, were Hanoch, Pallu, Hezron, and Carmi. These are the families of Reuben.", 
                "num": 14
              }, 
              {
                "text": "And the sons of Simeon were Jemuel, Jamin, Ohad, Jachin, Zohar, and Shaul the son of a Canaanite woman. These are the families of Simeon.", 
                "num": 15
              }, 
              {
                "text": "These are the names of the sons of Levi according to their generations: Gershon, Kohath, and Merari. And the years of the life of Levi were one hundred and thirty-seven.", 
                "num": 16
              }, 
              {
                "text": "The sons of Gershon were Libni and Shimi according to their families.", 
                "num": 17
              }, 
              {
                "text": "And the sons of Kohath were Amram, Izhar, Hebron, and Uzziel. And the years of the life of Kohath were one hundred and thirty-three.", 
                "num": 18
              }, 
              {
                "text": "The sons of Merari were Mahli and Mushi. These are the families of Levi according to their generations.", 
                "num": 19
              }, 
              {
                "text": "Now Amram took for himself Jochebed, his father's sister, as wife; and she bore him Aaron and Moses. And the years of the life of Amram were one hundred and thirty-seven.", 
                "num": 20
              }, 
              {
                "text": "The sons of Izhar were Korah, Nepheg, and Zichri.", 
                "num": 21
              }, 
              {
                "text": "And the sons of Uzziel were Mishael, Elzaphan, and Zithri.", 
                "num": 22
              }, 
              {
                "text": "Aaron took to himself Elisheba, daughter of Amminadab, sister of Nahshon, as wife; and she bore him Nadab, Abihu, Eleazar, and Ithamar.", 
                "num": 23
              }, 
              {
                "text": "And the sons of Korah were Assir, Elkanah, and Abiasaph. These are the families of the Korahites.", 
                "num": 24
              }, 
              {
                "text": "Eleazar, Aaron's son, took for himself one of the daughters of Putiel as wife; and she bore him Phinehas. These are the heads of the fathers' houses of the Levites according to their families.", 
                "num": 25
              }, 
              {
                "text": "These are the same Aaron and Moses to whom the LORD said, \"Bring out the children of Israel from the land of Egypt according to their armies.\"", 
                "num": 26
              }, 
              {
                "text": "These are the ones who spoke to Pharaoh king of Egypt, to bring out the children of Israel from Egypt. These are the same Moses and Aaron.", 
                "num": 27
              }, 
              {
                "text": "And it came to pass, on the day the LORD spoke to Moses in the land of Egypt,", 
                "num": 28
              }, 
              {
                "text": "that the LORD spoke to Moses, saying, \"I am the LORD. Speak to Pharaoh king of Egypt all that I say to you.\"", 
                "num": 29
              }, 
              {
                "text": "But Moses said before the LORD, \"Behold, I am of uncircumcised lips, and how shall Pharaoh heed me?\"", 
                "num": 30
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "So the LORD said to Moses: \"See, I have made you as God to Pharaoh, and Aaron your brother shall be your prophet.", 
                "num": 1
              }, 
              {
                "text": "You shall speak all that I command you. And Aaron your brother shall tell Pharaoh to send the children of Israel out of his land.", 
                "num": 2
              }, 
              {
                "text": "And I will harden Pharaoh's heart, and multiply My signs and My wonders in the land of Egypt.", 
                "num": 3
              }, 
              {
                "text": "But Pharaoh will not heed you, so that I may lay My hand on Egypt and bring My armies and My people, the children of Israel, out of the land of Egypt by great judgments.", 
                "num": 4
              }, 
              {
                "text": "And the Egyptians shall know that I am the LORD, when I stretch out My hand on Egypt and bring out the children of Israel from among them.\"", 
                "num": 5
              }, 
              {
                "text": "Then Moses and Aaron did so; just as the LORD commanded them, so they did.", 
                "num": 6
              }, 
              {
                "text": "And Moses was eighty years old and Aaron eighty-three years old when they spoke to Pharaoh.", 
                "num": 7
              }, 
              {
                "text": "Then the LORD spoke to Moses and Aaron, saying,", 
                "num": 8
              }, 
              {
                "text": "\"When Pharaoh speaks to you, saying, \"Show a miracle for yourselves,' then you shall say to Aaron, \"Take your rod and cast it before Pharaoh, and let it become a serpent.\"'", 
                "num": 9
              }, 
              {
                "text": "So Moses and Aaron went in to Pharaoh, and they did so, just as the LORD commanded. And Aaron cast down his rod before Pharaoh and before his servants, and it became a serpent.", 
                "num": 10
              }, 
              {
                "text": "But Pharaoh also called the wise men and the sorcerers; so the magicians of Egypt, they also did in like manner with their enchantments.", 
                "num": 11
              }, 
              {
                "text": "For every man threw down his rod, and they became serpents. But Aaron's rod swallowed up their rods.", 
                "num": 12
              }, 
              {
                "text": "And Pharaoh's heart grew hard, and he did not heed them, as the LORD had said.", 
                "num": 13
              }, 
              {
                "text": "So the LORD said to Moses: \"Pharaoh's heart is hard; he refuses to let the people go.", 
                "num": 14
              }, 
              {
                "text": "Go to Pharaoh in the morning, when he goes out to the water, and you shall stand by the river's bank to meet him; and the rod which was turned to a serpent you shall take in your hand.", 
                "num": 15
              }, 
              {
                "text": "And you shall say to him, \"The LORD God of the Hebrews has sent me to you, saying, \"Let My people go, that they may serve Me in the wilderness\"; but indeed, until now you would not hear!", 
                "num": 16
              }, 
              {
                "text": "Thus says the LORD: \"By this you shall know that I am the LORD. Behold, I will strike the waters which are in the river with the rod that is in my hand, and they shall be turned to blood.", 
                "num": 17
              }, 
              {
                "text": "And the fish that are in the river shall die, the river shall stink, and the Egyptians will loathe to drink the water of the river.\"\"'", 
                "num": 18
              }, 
              {
                "text": "Then the LORD spoke to Moses, \"Say to Aaron, \"Take your rod and stretch out your hand over the waters of Egypt, over their streams, over their rivers, over their ponds, and over all their pools of water, that they may become blood. And there shall be blood throughout all the land of Egypt, both in buckets of wood and pitchers of stone.\"'", 
                "num": 19
              }, 
              {
                "text": "And Moses and Aaron did so, just as the LORD commanded. So he lifted up the rod and struck the waters that were in the river, in the sight of Pharaoh and in the sight of his servants. And all the waters that were in the river were turned to blood.", 
                "num": 20
              }, 
              {
                "text": "The fish that were in the river died, the river stank, and the Egyptians could not drink the water of the river. So there was blood throughout all the land of Egypt.", 
                "num": 21
              }, 
              {
                "text": "Then the magicians of Egypt did so with their enchantments; and Pharaoh's heart grew hard, and he did not heed them, as the LORD had said.", 
                "num": 22
              }, 
              {
                "text": "And Pharaoh turned and went into his house. Neither was his heart moved by this.", 
                "num": 23
              }, 
              {
                "text": "So all the Egyptians dug all around the river for water to drink, because they could not drink the water of the river.", 
                "num": 24
              }, 
              {
                "text": "And seven days passed after the LORD had struck the river.", 
                "num": 25
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, \"Go to Pharaoh and say to him, \"Thus says the LORD: \"Let My people go, that they may serve Me.", 
                "num": 1
              }, 
              {
                "text": "But if you refuse to let them go, behold, I will smite all your territory with frogs.", 
                "num": 2
              }, 
              {
                "text": "So the river shall bring forth frogs abundantly, which shall go up and come into your house, into your bedroom, on your bed, into the houses of your servants, on your people, into your ovens, and into your kneading bowls.", 
                "num": 3
              }, 
              {
                "text": "And the frogs shall come up on you, on your people, and on all your servants.\"\"'", 
                "num": 4
              }, 
              {
                "text": "Then the LORD spoke to Moses, \"Say to Aaron, \"Stretch out your hand with your rod over the streams, over the rivers, and over the ponds, and cause frogs to come up on the land of Egypt.\"'", 
                "num": 5
              }, 
              {
                "text": "So Aaron stretched out his hand over the waters of Egypt, and the frogs came up and covered the land of Egypt.", 
                "num": 6
              }, 
              {
                "text": "And the magicians did so with their enchantments, and brought up frogs on the land of Egypt.", 
                "num": 7
              }, 
              {
                "text": "Then Pharaoh called for Moses and Aaron, and said, \"Entreat the LORD that He may take away the frogs from me and from my people; and I will let the people go, that they may sacrifice to the LORD.\"", 
                "num": 8
              }, 
              {
                "text": "And Moses said to Pharaoh, \"Accept the honor of saying when I shall intercede for you, for your servants, and for your people, to destroy the frogs from you and your houses, that they may remain in the river only.\"", 
                "num": 9
              }, 
              {
                "text": "So he said, \"Tomorrow.\" And he said, \"Let it be according to your word, that you may know that there is no one like the LORD our God.", 
                "num": 10
              }, 
              {
                "text": "And the frogs shall depart from you, from your houses, from your servants, and from your people. They shall remain in the river only.\"", 
                "num": 11
              }, 
              {
                "text": "Then Moses and Aaron went out from Pharaoh. And Moses cried out to the LORD concerning the frogs which He had brought against Pharaoh.", 
                "num": 12
              }, 
              {
                "text": "So the LORD did according to the word of Moses. And the frogs died out of the houses, out of the courtyards, and out of the fields.", 
                "num": 13
              }, 
              {
                "text": "They gathered them together in heaps, and the land stank.", 
                "num": 14
              }, 
              {
                "text": "But when Pharaoh saw that there was relief, he hardened his heart and did not heed them, as the LORD had said.", 
                "num": 15
              }, 
              {
                "text": "So the LORD said to Moses, \"Say to Aaron, \"Stretch out your rod, and strike the dust of the land, so that it may become lice throughout all the land of Egypt.\"'", 
                "num": 16
              }, 
              {
                "text": "And they did so. For Aaron stretched out his hand with his rod and struck the dust of the earth, and it became lice on man and beast. All the dust of the land became lice throughout all the land of Egypt.", 
                "num": 17
              }, 
              {
                "text": "Now the magicians so worked with their enchantments to bring forth lice, but they could not. So there were lice on man and beast.", 
                "num": 18
              }, 
              {
                "text": "Then the magicians said to Pharaoh, \"This is the finger of God.\" But Pharaoh's heart grew hard, and he did not heed them, just as the LORD had said.", 
                "num": 19
              }, 
              {
                "text": "And the LORD said to Moses, \"Rise early in the morning and stand before Pharaoh as he comes out to the water. Then say to him, \"Thus says the LORD: \"Let My people go, that they may serve Me.", 
                "num": 20
              }, 
              {
                "text": "Or else, if you will not let My people go, behold, I will send swarms of flies on you and your servants, on your people and into your houses. The houses of the Egyptians shall be full of swarms of flies, and also the ground on which they stand.", 
                "num": 21
              }, 
              {
                "text": "And in that day I will set apart the land of Goshen, in which My people dwell, that no swarms of flies shall be there, in order that you may know that I am the LORD in the midst of the land.", 
                "num": 22
              }, 
              {
                "text": "I will make a difference between My people and your people. Tomorrow this sign shall be.\"\"'", 
                "num": 23
              }, 
              {
                "text": "And the LORD did so. Thick swarms of flies came into the house of Pharaoh, into his servants' houses, and into all the land of Egypt. The land was corrupted because of the swarms of flies.", 
                "num": 24
              }, 
              {
                "text": "Then Pharaoh called for Moses and Aaron, and said, \"Go, sacrifice to your God in the land.\"", 
                "num": 25
              }, 
              {
                "text": "And Moses said, \"It is not right to do so, for we would be sacrificing the abomination of the Egyptians to the LORD our God. If we sacrifice the abomination of the Egyptians before their eyes, then will they not stone us?", 
                "num": 26
              }, 
              {
                "text": "We will go three days' journey into the wilderness and sacrifice to the LORD our God as He will command us.\"", 
                "num": 27
              }, 
              {
                "text": "So Pharaoh said, \"I will let you go, that you may sacrifice to the LORD your God in the wilderness; only you shall not go very far away. Intercede for me.\"", 
                "num": 28
              }, 
              {
                "text": "Then Moses said, \"Indeed I am going out from you, and I will entreat the LORD, that the swarms of flies may depart tomorrow from Pharaoh, from his servants, and from his people. But let Pharaoh not deal deceitfully anymore in not letting the people go to sacrifice to the LORD.\"", 
                "num": 29
              }, 
              {
                "text": "So Moses went out from Pharaoh and entreated the LORD.", 
                "num": 30
              }, 
              {
                "text": "And the LORD did according to the word of Moses; He removed the swarms of flies from Pharaoh, from his servants, and from his people. Not one remained.", 
                "num": 31
              }, 
              {
                "text": "But Pharaoh hardened his heart at this time also; neither would he let the people go.", 
                "num": 32
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to Moses, \"Go in to Pharaoh and tell him, \"Thus says the LORD God of the Hebrews: \"Let My people go, that they may serve Me.", 
                "num": 1
              }, 
              {
                "text": "For if you refuse to let them go, and still hold them,", 
                "num": 2
              }, 
              {
                "text": "behold, the hand of the LORD will be on your cattle in the field, on the horses, on the donkeys, on the camels, on the oxen, and on the sheep--a very severe pestilence.", 
                "num": 3
              }, 
              {
                "text": "And the LORD will make a difference between the livestock of Israel and the livestock of Egypt. So nothing shall die of all that belongs to the children of Israel.\"\"'", 
                "num": 4
              }, 
              {
                "text": "Then the LORD appointed a set time, saying, \"Tomorrow the LORD will do this thing in the land.\"", 
                "num": 5
              }, 
              {
                "text": "So the LORD did this thing on the next day, and all the livestock of Egypt died; but of the livestock of the children of Israel, not one died.", 
                "num": 6
              }, 
              {
                "text": "Then Pharaoh sent, and indeed, not even one of the livestock of the Israelites was dead. But the heart of Pharaoh became hard, and he did not let the people go.", 
                "num": 7
              }, 
              {
                "text": "So the LORD said to Moses and Aaron, \"Take for yourselves handfuls of ashes from a furnace, and let Moses scatter it toward the heavens in the sight of Pharaoh.", 
                "num": 8
              }, 
              {
                "text": "And it will become fine dust in all the land of Egypt, and it will cause boils that break out in sores on man and beast throughout all the land of Egypt.\"", 
                "num": 9
              }, 
              {
                "text": "Then they took ashes from the furnace and stood before Pharaoh, and Moses scattered them toward heaven. And they caused boils that break out in sores on man and beast.", 
                "num": 10
              }, 
              {
                "text": "And the magicians could not stand before Moses because of the boils, for the boils were on the magicians and on all the Egyptians.", 
                "num": 11
              }, 
              {
                "text": "But the LORD hardened the heart of Pharaoh; and he did not heed them, just as the LORD had spoken to Moses.", 
                "num": 12
              }, 
              {
                "text": "Then the LORD said to Moses, \"Rise early in the morning and stand before Pharaoh, and say to him, \"Thus says the LORD God of the Hebrews: \"Let My people go, that they may serve Me,", 
                "num": 13
              }, 
              {
                "text": "for at this time I will send all My plagues to your very heart, and on your servants and on your people, that you may know that there is none like Me in all the earth.", 
                "num": 14
              }, 
              {
                "text": "Now if I had stretched out My hand and struck you and your people with pestilence, then you would have been cut off from the earth.", 
                "num": 15
              }, 
              {
                "text": "But indeed for this purpose I have raised you up, that I may show My power in you, and that My name may be declared in all the earth.", 
                "num": 16
              }, 
              {
                "text": "As yet you exalt yourself against My people in that you will not let them go.", 
                "num": 17
              }, 
              {
                "text": "Behold, tomorrow about this time I will cause very heavy hail to rain down, such as has not been in Egypt since its founding until now.", 
                "num": 18
              }, 
              {
                "text": "Therefore send now and gather your livestock and all that you have in the field, for the hail shall come down on every man and every animal which is found in the field and is not brought home; and they shall die.\"\"'", 
                "num": 19
              }, 
              {
                "text": "He who feared the word of the LORD among the servants of Pharaoh made his servants and his livestock flee to the houses.", 
                "num": 20
              }, 
              {
                "text": "But he who did not regard the word of the LORD left his servants and his livestock in the field.", 
                "num": 21
              }, 
              {
                "text": "Then the LORD said to Moses, \"Stretch out your hand toward heaven, that there may be hail in all the land of Egypt--on man, on beast, and on every herb of the field, throughout the land of Egypt.\"", 
                "num": 22
              }, 
              {
                "text": "And Moses stretched out his rod toward heaven; and the LORD sent thunder and hail, and fire darted to the ground. And the LORD rained hail on the land of Egypt.", 
                "num": 23
              }, 
              {
                "text": "So there was hail, and fire mingled with the hail, so very heavy that there was none like it in all the land of Egypt since it became a nation.", 
                "num": 24
              }, 
              {
                "text": "And the hail struck throughout the whole land of Egypt, all that was in the field, both man and beast; and the hail struck every herb of the field and broke every tree of the field.", 
                "num": 25
              }, 
              {
                "text": "Only in the land of Goshen, where the children of Israel were, there was no hail.", 
                "num": 26
              }, 
              {
                "text": "And Pharaoh sent and called for Moses and Aaron, and said to them, \"I have sinned this time. The LORD is righteous, and my people and I are wicked.", 
                "num": 27
              }, 
              {
                "text": "Entreat the LORD, that there may be no more mighty thundering and hail, for it is enough. I will let you go, and you shall stay no longer.\"", 
                "num": 28
              }, 
              {
                "text": "So Moses said to him, \"As soon as I have gone out of the city, I will spread out my hands to the LORD; the thunder will cease, and there will be no more hail, that you may know that the earth is the LORD's.", 
                "num": 29
              }, 
              {
                "text": "But as for you and your servants, I know that you will not yet fear the LORD God.\"", 
                "num": 30
              }, 
              {
                "text": "Now the flax and the barley were struck, for the barley was in the head and the flax was in bud.", 
                "num": 31
              }, 
              {
                "text": "But the wheat and the spelt were not struck, for they are late crops.", 
                "num": 32
              }, 
              {
                "text": "So Moses went out of the city from Pharaoh and spread out his hands to the LORD; then the thunder and the hail ceased, and the rain was not poured on the earth.", 
                "num": 33
              }, 
              {
                "text": "And when Pharaoh saw that the rain, the hail, and the thunder had ceased, he sinned yet more; and he hardened his heart, he and his servants.", 
                "num": 34
              }, 
              {
                "text": "So the heart of Pharaoh was hard; neither would he let the children of Israel go, as the LORD had spoken by Moses.", 
                "num": 35
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD said to Moses, \"Go in to Pharaoh; for I have hardened his heart and the hearts of his servants, that I may show these signs of Mine before him,", 
                "num": 1
              }, 
              {
                "text": "and that you may tell in the hearing of your son and your son's son the mighty things I have done in Egypt, and My signs which I have done among them, that you may know that I am the LORD.\"", 
                "num": 2
              }, 
              {
                "text": "So Moses and Aaron came in to Pharaoh and said to him, \"Thus says the LORD God of the Hebrews: \"How long will you refuse to humble yourself before Me? Let My people go, that they may serve Me.", 
                "num": 3
              }, 
              {
                "text": "Or else, if you refuse to let My people go, behold, tomorrow I will bring locusts into your territory.", 
                "num": 4
              }, 
              {
                "text": "And they shall cover the face of the earth, so that no one will be able to see the earth; and they shall eat the residue of what is left, which remains to you from the hail, and they shall eat every tree which grows up for you out of the field.", 
                "num": 5
              }, 
              {
                "text": "They shall fill your houses, the houses of all your servants, and the houses of all the Egyptians--which neither your fathers nor your fathers' fathers have seen, since the day that they were on the earth to this day.\"' And he turned and went out from Pharaoh.", 
                "num": 6
              }, 
              {
                "text": "Then Pharaoh's servants said to him, \"How long shall this man be a snare to us? Let the men go, that they may serve the LORD their God. Do you not yet know that Egypt is destroyed?\"", 
                "num": 7
              }, 
              {
                "text": "So Moses and Aaron were brought again to Pharaoh, and he said to them, \"Go, serve the LORD your God. Who are the ones that are going?\"", 
                "num": 8
              }, 
              {
                "text": "And Moses said, \"We will go with our young and our old; with our sons and our daughters, with our flocks and our herds we will go, for we must hold a feast to the LORD.\"", 
                "num": 9
              }, 
              {
                "text": "Then he said to them, \"The LORD had better be with you when I let you and your little ones go! Beware, for evil is ahead of you.", 
                "num": 10
              }, 
              {
                "text": "Not so! Go now, you who are men, and serve the LORD, for that is what you desired.\" And they were driven out from Pharaoh's presence.", 
                "num": 11
              }, 
              {
                "text": "Then the LORD said to Moses, \"Stretch out your hand over the land of Egypt for the locusts, that they may come upon the land of Egypt, and eat every herb of the land--all that the hail has left.\"", 
                "num": 12
              }, 
              {
                "text": "So Moses stretched out his rod over the land of Egypt, and the LORD brought an east wind on the land all that day and all that night. When it was morning, the east wind brought the locusts.", 
                "num": 13
              }, 
              {
                "text": "And the locusts went up over all the land of Egypt and rested on all the territory of Egypt. They were very severe; previously there had been no such locusts as they, nor shall there be such after them.", 
                "num": 14
              }, 
              {
                "text": "For they covered the face of the whole earth, so that the land was darkened; and they ate every herb of the land and all the fruit of the trees which the hail had left. So there remained nothing green on the trees or on the plants of the field throughout all the land of Egypt.", 
                "num": 15
              }, 
              {
                "text": "Then Pharaoh called for Moses and Aaron in haste, and said, \"I have sinned against the LORD your God and against you.", 
                "num": 16
              }, 
              {
                "text": "Now therefore, please forgive my sin only this once, and entreat the LORD your God, that He may take away from me this death only.\"", 
                "num": 17
              }, 
              {
                "text": "So he went out from Pharaoh and entreated the LORD.", 
                "num": 18
              }, 
              {
                "text": "And the LORD turned a very strong west wind, which took the locusts away and blew them into the Red Sea. There remained not one locust in all the territory of Egypt.", 
                "num": 19
              }, 
              {
                "text": "But the LORD hardened Pharaoh's heart, and he did not let the children of Israel go.", 
                "num": 20
              }, 
              {
                "text": "Then the LORD said to Moses, \"Stretch out your hand toward heaven, that there may be darkness over the land of Egypt, darkness which may even be felt.\"", 
                "num": 21
              }, 
              {
                "text": "So Moses stretched out his hand toward heaven, and there was thick darkness in all the land of Egypt three days.", 
                "num": 22
              }, 
              {
                "text": "They did not see one another; nor did anyone rise from his place for three days. But all the children of Israel had light in their dwellings.", 
                "num": 23
              }, 
              {
                "text": "Then Pharaoh called to Moses and said, \"Go, serve the LORD; only let your flocks and your herds be kept back. Let your little ones also go with you.\"", 
                "num": 24
              }, 
              {
                "text": "But Moses said, \"You must also give us sacrifices and burnt offerings, that we may sacrifice to the LORD our God.", 
                "num": 25
              }, 
              {
                "text": "Our livestock also shall go with us; not a hoof shall be left behind. For we must take some of them to serve the LORD our God, and even we do not know with what we must serve the LORD until we arrive there.\"", 
                "num": 26
              }, 
              {
                "text": "But the LORD hardened Pharaoh's heart, and he would not let them go.", 
                "num": 27
              }, 
              {
                "text": "Then Pharaoh said to him, \"Get away from me! Take heed to yourself and see my face no more! For in the day you see my face you shall die!\"", 
                "num": 28
              }, 
              {
                "text": "So Moses said, \"You have spoken well. I will never see your face again.\"", 
                "num": 29
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "And the LORD said to Moses, \"I will bring one more plague on Pharaoh and on Egypt. Afterward he will let you go from here. When he lets you go, he will surely drive you out of here altogether.", 
                "num": 1
              }, 
              {
                "text": "Speak now in the hearing of the people, and let every man ask from his neighbor and every woman from her neighbor, articles of silver and articles of gold.\"", 
                "num": 2
              }, 
              {
                "text": "And the LORD gave the people favor in the sight of the Egyptians. Moreover the man Moses was very great in the land of Egypt, in the sight of Pharaoh's servants and in the sight of the people.", 
                "num": 3
              }, 
              {
                "text": "Then Moses said, \"Thus says the LORD: \"About midnight I will go out into the midst of Egypt;", 
                "num": 4
              }, 
              {
                "text": "and all the firstborn in the land of Egypt shall die, from the firstborn of Pharaoh who sits on his throne, even to the firstborn of the female servant who is behind the handmill, and all the firstborn of the animals.", 
                "num": 5
              }, 
              {
                "text": "Then there shall be a great cry throughout all the land of Egypt, such as was not like it before, nor shall be like it again.", 
                "num": 6
              }, 
              {
                "text": "But against none of the children of Israel shall a dog move its tongue, against man or beast, that you may know that the LORD does make a difference between the Egyptians and Israel.'", 
                "num": 7
              }, 
              {
                "text": "And all these your servants shall come down to me and bow down to me, saying, \"Get out, and all the people who follow you!' After that I will go out.\" Then he went out from Pharaoh in great anger.", 
                "num": 8
              }, 
              {
                "text": "But the LORD said to Moses, \"Pharaoh will not heed you, so that My wonders may be multiplied in the land of Egypt.\"", 
                "num": 9
              }, 
              {
                "text": "So Moses and Aaron did all these wonders before Pharaoh; and the LORD hardened Pharaoh's heart, and he did not let the children of Israel go out of his land.", 
                "num": 10
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses and Aaron in the land of Egypt, saying,", 
                "num": 1
              }, 
              {
                "text": "\"This month shall be your beginning of months; it shall be the first month of the year to you.", 
                "num": 2
              }, 
              {
                "text": "Speak to all the congregation of Israel, saying: \"On the tenth of this month every man shall take for himself a lamb, according to the house of his father, a lamb for a household.", 
                "num": 3
              }, 
              {
                "text": "And if the household is too small for the lamb, let him and his neighbor next to his house take it according to the number of the persons; according to each man's need you shall make your count for the lamb.", 
                "num": 4
              }, 
              {
                "text": "Your lamb shall be without blemish, a male of the first year. You may take it from the sheep or from the goats.", 
                "num": 5
              }, 
              {
                "text": "Now you shall keep it until the fourteenth day of the same month. Then the whole assembly of the congregation of Israel shall kill it at twilight.", 
                "num": 6
              }, 
              {
                "text": "And they shall take some of the blood and put it on the two doorposts and on the lintel of the houses where they eat it.", 
                "num": 7
              }, 
              {
                "text": "Then they shall eat the flesh on that night; roasted in fire, with unleavened bread and with bitter herbs they shall eat it.", 
                "num": 8
              }, 
              {
                "text": "Do not eat it raw, nor boiled at all with water, but roasted in fire--its head with its legs and its entrails.", 
                "num": 9
              }, 
              {
                "text": "You shall let none of it remain until morning, and what remains of it until morning you shall burn with fire.", 
                "num": 10
              }, 
              {
                "text": "And thus you shall eat it: with a belt on your waist, your sandals on your feet, and your staff in your hand. So you shall eat it in haste. It is the LORD's Passover.", 
                "num": 11
              }, 
              {
                "text": "\"For I will pass through the land of Egypt on that night, and will strike all the firstborn in the land of Egypt, both man and beast; and against all the gods of Egypt I will execute judgment: I am the LORD.", 
                "num": 12
              }, 
              {
                "text": "Now the blood shall be a sign for you on the houses where you are. And when I see the blood, I will pass over you; and the plague shall not be on you to destroy you when I strike the land of Egypt.", 
                "num": 13
              }, 
              {
                "text": "\"So this day shall be to you a memorial; and you shall keep it as a feast to the LORD throughout your generations. You shall keep it as a feast by an everlasting ordinance.", 
                "num": 14
              }, 
              {
                "text": "Seven days you shall eat unleavened bread. On the first day you shall remove leaven from your houses. For whoever eats leavened bread from the first day until the seventh day, that person shall be cut off from Israel.", 
                "num": 15
              }, 
              {
                "text": "On the first day there shall be a holy convocation, and on the seventh day there shall be a holy convocation for you. No manner of work shall be done on them; but that which everyone must eat--that only may be prepared by you.", 
                "num": 16
              }, 
              {
                "text": "So you shall observe the Feast of Unleavened Bread, for on this same day I will have brought your armies out of the land of Egypt. Therefore you shall observe this day throughout your generations as an everlasting ordinance.", 
                "num": 17
              }, 
              {
                "text": "In the first month, on the fourteenth day of the month at evening, you shall eat unleavened bread, until the twenty-first day of the month at evening.", 
                "num": 18
              }, 
              {
                "text": "For seven days no leaven shall be found in your houses, since whoever eats what is leavened, that same person shall be cut off from the congregation of Israel, whether he is a stranger or a native of the land.", 
                "num": 19
              }, 
              {
                "text": "You shall eat nothing leavened; in all your dwellings you shall eat unleavened bread.\"'", 
                "num": 20
              }, 
              {
                "text": "Then Moses called for all the elders of Israel and said to them, \"Pick out and take lambs for yourselves according to your families, and kill the Passover lamb.", 
                "num": 21
              }, 
              {
                "text": "And you shall take a bunch of hyssop, dip it in the blood that is in the basin, and strike the lintel and the two doorposts with the blood that is in the basin. And none of you shall go out of the door of his house until morning.", 
                "num": 22
              }, 
              {
                "text": "For the LORD will pass through to strike the Egyptians; and when He sees the blood on the lintel and on the two doorposts, the LORD will pass over the door and not allow the destroyer to come into your houses to strike you.", 
                "num": 23
              }, 
              {
                "text": "And you shall observe this thing as an ordinance for you and your sons forever.", 
                "num": 24
              }, 
              {
                "text": "It will come to pass when you come to the land which the LORD will give you, just as He promised, that you shall keep this service.", 
                "num": 25
              }, 
              {
                "text": "And it shall be, when your children say to you, \"What do you mean by this service?'", 
                "num": 26
              }, 
              {
                "text": "that you shall say, \"It is the Passover sacrifice of the LORD, who passed over the houses of the children of Israel in Egypt when He struck the Egyptians and delivered our households.\"' So the people bowed their heads and worshiped.", 
                "num": 27
              }, 
              {
                "text": "Then the children of Israel went away and did so; just as the LORD had commanded Moses and Aaron, so they did.", 
                "num": 28
              }, 
              {
                "text": "And it came to pass at midnight that the LORD struck all the firstborn in the land of Egypt, from the firstborn of Pharaoh who sat on his throne to the firstborn of the captive who was in the dungeon, and all the firstborn of livestock.", 
                "num": 29
              }, 
              {
                "text": "So Pharaoh rose in the night, he, all his servants, and all the Egyptians; and there was a great cry in Egypt, for there was not a house where there was not one dead.", 
                "num": 30
              }, 
              {
                "text": "Then he called for Moses and Aaron by night, and said, \"Rise, go out from among my people, both you and the children of Israel. And go, serve the LORD as you have said.", 
                "num": 31
              }, 
              {
                "text": "Also take your flocks and your herds, as you have said, and be gone; and bless me also.\"", 
                "num": 32
              }, 
              {
                "text": "And the Egyptians urged the people, that they might send them out of the land in haste. For they said, \"We shall all be dead.\"", 
                "num": 33
              }, 
              {
                "text": "So the people took their dough before it was leavened, having their kneading bowls bound up in their clothes on their shoulders.", 
                "num": 34
              }, 
              {
                "text": "Now the children of Israel had done according to the word of Moses, and they had asked from the Egyptians articles of silver, articles of gold, and clothing.", 
                "num": 35
              }, 
              {
                "text": "And the LORD had given the people favor in the sight of the Egyptians, so that they granted them what they requested. Thus they plundered the Egyptians.", 
                "num": 36
              }, 
              {
                "text": "Then the children of Israel journeyed from Rameses to Succoth, about six hundred thousand men on foot, besides children.", 
                "num": 37
              }, 
              {
                "text": "A mixed multitude went up with them also, and flocks and herds--a great deal of livestock.", 
                "num": 38
              }, 
              {
                "text": "And they baked unleavened cakes of the dough which they had brought out of Egypt; for it was not leavened, because they were driven out of Egypt and could not wait, nor had they prepared provisions for themselves.", 
                "num": 39
              }, 
              {
                "text": "Now the sojourn of the children of Israel who lived in Egypt was four hundred and thirty years.", 
                "num": 40
              }, 
              {
                "text": "And it came to pass at the end of the four hundred and thirty years--on that very same day--it came to pass that all the armies of the LORD went out from the land of Egypt.", 
                "num": 41
              }, 
              {
                "text": "It is a night of solemn observance to the LORD for bringing them out of the land of Egypt. This is that night of the LORD, a solemn observance for all the children of Israel throughout their generations.", 
                "num": 42
              }, 
              {
                "text": "And the LORD said to Moses and Aaron, \"This is the ordinance of the Passover: No foreigner shall eat it.", 
                "num": 43
              }, 
              {
                "text": "But every man's servant who is bought for money, when you have circumcised him, then he may eat it.", 
                "num": 44
              }, 
              {
                "text": "A sojourner and a hired servant shall not eat it.", 
                "num": 45
              }, 
              {
                "text": "In one house it shall be eaten; you shall not carry any of the flesh outside the house, nor shall you break one of its bones.", 
                "num": 46
              }, 
              {
                "text": "All the congregation of Israel shall keep it.", 
                "num": 47
              }, 
              {
                "text": "And when a stranger dwells with you and wants to keep the Passover to the LORD, let all his males be circumcised, and then let him come near and keep it; and he shall be as a native of the land. For no uncircumcised person shall eat it.", 
                "num": 48
              }, 
              {
                "text": "One law shall be for the native-born and for the stranger who dwells among you.\"", 
                "num": 49
              }, 
              {
                "text": "Thus all the children of Israel did; as the LORD commanded Moses and Aaron, so they did.", 
                "num": 50
              }, 
              {
                "text": "And it came to pass, on that very same day, that the LORD brought the children of Israel out of the land of Egypt according to their armies.", 
                "num": 51
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Consecrate to Me all the firstborn, whatever opens the womb among the children of Israel, both of man and beast; it is Mine.\"", 
                "num": 2
              }, 
              {
                "text": "And Moses said to the people: \"Remember this day in which you went out of Egypt, out of the house of bondage; for by strength of hand the LORD brought you out of this place. No leavened bread shall be eaten.", 
                "num": 3
              }, 
              {
                "text": "On this day you are going out, in the month Abib.", 
                "num": 4
              }, 
              {
                "text": "And it shall be, when the LORD brings you into the land of the Canaanites and the Hittites and the Amorites and the Hivites and the Jebusites, which He swore to your fathers to give you, a land flowing with milk and honey, that you shall keep this service in this month.", 
                "num": 5
              }, 
              {
                "text": "Seven days you shall eat unleavened bread, and on the seventh day there shall be a feast to the LORD.", 
                "num": 6
              }, 
              {
                "text": "Unleavened bread shall be eaten seven days. And no leavened bread shall be seen among you, nor shall leaven be seen among you in all your quarters.", 
                "num": 7
              }, 
              {
                "text": "And you shall tell your son in that day, saying, \"This is done because of what the LORD did for me when I came up from Egypt.'", 
                "num": 8
              }, 
              {
                "text": "It shall be as a sign to you on your hand and as a memorial between your eyes, that the LORD's law may be in your mouth; for with a strong hand the LORD has brought you out of Egypt.", 
                "num": 9
              }, 
              {
                "text": "You shall therefore keep this ordinance in its season from year to year.", 
                "num": 10
              }, 
              {
                "text": "\"And it shall be, when the LORD brings you into the land of the Canaanites, as He swore to you and your fathers, and gives it to you,", 
                "num": 11
              }, 
              {
                "text": "that you shall set apart to the LORD all that open the womb, that is, every firstborn that comes from an animal which you have; the males shall be the LORD's.", 
                "num": 12
              }, 
              {
                "text": "But every firstborn of a donkey you shall redeem with a lamb; and if you will not redeem it, then you shall break its neck. And all the firstborn of man among your sons you shall redeem.", 
                "num": 13
              }, 
              {
                "text": "So it shall be, when your son asks you in time to come, saying, \"What is this?' that you shall say to him, \"By strength of hand the LORD brought us out of Egypt, out of the house of bondage.", 
                "num": 14
              }, 
              {
                "text": "And it came to pass, when Pharaoh was stubborn about letting us go, that the LORD killed all the firstborn in the land of Egypt, both the firstborn of man and the firstborn of beast. Therefore I sacrifice to the LORD all males that open the womb, but all the firstborn of my sons I redeem.'", 
                "num": 15
              }, 
              {
                "text": "It shall be as a sign on your hand and as frontlets between your eyes, for by strength of hand the LORD brought us out of Egypt.\"", 
                "num": 16
              }, 
              {
                "text": "Then it came to pass, when Pharaoh had let the people go, that God did not lead them by way of the land of the Philistines, although that was near; for God said, \"Lest perhaps the people change their minds when they see war, and return to Egypt.\"", 
                "num": 17
              }, 
              {
                "text": "So God led the people around by way of the wilderness of the Red Sea. And the children of Israel went up in orderly ranks out of the land of Egypt.", 
                "num": 18
              }, 
              {
                "text": "And Moses took the bones of Joseph with him, for he had placed the children of Israel under solemn oath, saying, \"God will surely visit you, and you shall carry up my bones from here with you.\"", 
                "num": 19
              }, 
              {
                "text": "So they took their journey from Succoth and camped in Etham at the edge of the wilderness.", 
                "num": 20
              }, 
              {
                "text": "And the LORD went before them by day in a pillar of cloud to lead the way, and by night in a pillar of fire to give them light, so as to go by day and night.", 
                "num": 21
              }, 
              {
                "text": "He did not take away the pillar of cloud by day or the pillar of fire by night from before the people.", 
                "num": 22
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, that they turn and camp before Pi Hahiroth, between Migdol and the sea, opposite Baal Zephon; you shall camp before it by the sea.", 
                "num": 2
              }, 
              {
                "text": "For Pharaoh will say of the children of Israel, \"They are bewildered by the land; the wilderness has closed them in.'", 
                "num": 3
              }, 
              {
                "text": "Then I will harden Pharaoh's heart, so that he will pursue them; and I will gain honor over Pharaoh and over all his army, that the Egyptians may know that I am the LORD.\" And they did so.", 
                "num": 4
              }, 
              {
                "text": "Now it was told the king of Egypt that the people had fled, and the heart of Pharaoh and his servants was turned against the people; and they said, \"Why have we done this, that we have let Israel go from serving us?\"", 
                "num": 5
              }, 
              {
                "text": "So he made ready his chariot and took his people with him.", 
                "num": 6
              }, 
              {
                "text": "Also, he took six hundred choice chariots, and all the chariots of Egypt with captains over every one of them.", 
                "num": 7
              }, 
              {
                "text": "And the LORD hardened the heart of Pharaoh king of Egypt, and he pursued the children of Israel; and the children of Israel went out with boldness.", 
                "num": 8
              }, 
              {
                "text": "So the Egyptians pursued them, all the horses and chariots of Pharaoh, his horsemen and his army, and overtook them camping by the sea beside Pi Hahiroth, before Baal Zephon.", 
                "num": 9
              }, 
              {
                "text": "And when Pharaoh drew near, the children of Israel lifted their eyes, and behold, the Egyptians marched after them. So they were very afraid, and the children of Israel cried out to the LORD.", 
                "num": 10
              }, 
              {
                "text": "Then they said to Moses, \"Because there were no graves in Egypt, have you taken us away to die in the wilderness? Why have you so dealt with us, to bring us up out of Egypt?", 
                "num": 11
              }, 
              {
                "text": "Is this not the word that we told you in Egypt, saying, \"Let us alone that we may serve the Egyptians'? For it would have been better for us to serve the Egyptians than that we should die in the wilderness.\"", 
                "num": 12
              }, 
              {
                "text": "And Moses said to the people, \"Do not be afraid. Stand still, and see the salvation of the LORD, which He will accomplish for you today. For the Egyptians whom you see today, you shall see again no more forever.", 
                "num": 13
              }, 
              {
                "text": "The LORD will fight for you, and you shall hold your peace.\"", 
                "num": 14
              }, 
              {
                "text": "And the LORD said to Moses, \"Why do you cry to Me? Tell the children of Israel to go forward.", 
                "num": 15
              }, 
              {
                "text": "But lift up your rod, and stretch out your hand over the sea and divide it. And the children of Israel shall go on dry ground through the midst of the sea.", 
                "num": 16
              }, 
              {
                "text": "And I indeed will harden the hearts of the Egyptians, and they shall follow them. So I will gain honor over Pharaoh and over all his army, his chariots, and his horsemen.", 
                "num": 17
              }, 
              {
                "text": "Then the Egyptians shall know that I am the LORD, when I have gained honor for Myself over Pharaoh, his chariots, and his horsemen.\"", 
                "num": 18
              }, 
              {
                "text": "And the Angel of God, who went before the camp of Israel, moved and went behind them; and the pillar of cloud went from before them and stood behind them.", 
                "num": 19
              }, 
              {
                "text": "So it came between the camp of the Egyptians and the camp of Israel. Thus it was a cloud and darkness to the one, and it gave light by night to the other, so that the one did not come near the other all that night.", 
                "num": 20
              }, 
              {
                "text": "Then Moses stretched out his hand over the sea; and the LORD caused the sea to go back by a strong east wind all that night, and made the sea into dry land, and the waters were divided.", 
                "num": 21
              }, 
              {
                "text": "So the children of Israel went into the midst of the sea on the dry ground, and the waters were a wall to them on their right hand and on their left.", 
                "num": 22
              }, 
              {
                "text": "And the Egyptians pursued and went after them into the midst of the sea, all Pharaoh's horses, his chariots, and his horsemen.", 
                "num": 23
              }, 
              {
                "text": "Now it came to pass, in the morning watch, that the LORD looked down upon the army of the Egyptians through the pillar of fire and cloud, and He troubled the army of the Egyptians.", 
                "num": 24
              }, 
              {
                "text": "And He took off their chariot wheels, so that they drove them with difficulty; and the Egyptians said, \"Let us flee from the face of Israel, for the LORD fights for them against the Egyptians.\"", 
                "num": 25
              }, 
              {
                "text": "Then the LORD said to Moses, \"Stretch out your hand over the sea, that the waters may come back upon the Egyptians, on their chariots, and on their horsemen.\"", 
                "num": 26
              }, 
              {
                "text": "And Moses stretched out his hand over the sea; and when the morning appeared, the sea returned to its full depth, while the Egyptians were fleeing into it. So the LORD overthrew the Egyptians in the midst of the sea.", 
                "num": 27
              }, 
              {
                "text": "Then the waters returned and covered the chariots, the horsemen, and all the army of Pharaoh that came into the sea after them. Not so much as one of them remained.", 
                "num": 28
              }, 
              {
                "text": "But the children of Israel had walked on dry land in the midst of the sea, and the waters were a wall to them on their right hand and on their left.", 
                "num": 29
              }, 
              {
                "text": "So the LORD saved Israel that day out of the hand of the Egyptians, and Israel saw the Egyptians dead on the seashore.", 
                "num": 30
              }, 
              {
                "text": "Thus Israel saw the great work which the LORD had done in Egypt; so the people feared the LORD, and believed the LORD and His servant Moses.", 
                "num": 31
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then Moses and the children of Israel sang this song to the LORD, and spoke, saying: \"I will sing to the LORD, For He has triumphed gloriously! The horse and its rider He has thrown into the sea!", 
                "num": 1
              }, 
              {
                "text": "The LORD is my strength and song, And He has become my salvation; He is my God, and I will praise Him; My father's God, and I will exalt Him.", 
                "num": 2
              }, 
              {
                "text": "The LORD is a man of war; The LORD is His name.", 
                "num": 3
              }, 
              {
                "text": "Pharaoh's chariots and his army He has cast into the sea; His chosen captains also are drowned in the Red Sea.", 
                "num": 4
              }, 
              {
                "text": "The depths have covered them; They sank to the bottom like a stone.", 
                "num": 5
              }, 
              {
                "text": "\"Your right hand, O LORD, has become glorious in power; Your right hand, O LORD, has dashed the enemy in pieces.", 
                "num": 6
              }, 
              {
                "text": "And in the greatness of Your excellence You have overthrown those who rose against You; You sent forth Your wrath; It consumed them like stubble.", 
                "num": 7
              }, 
              {
                "text": "And with the blast of Your nostrils The waters were gathered together; The floods stood upright like a heap; The depths congealed in the heart of the sea.", 
                "num": 8
              }, 
              {
                "text": "The enemy said, \"I will pursue, I will overtake, I will divide the spoil; My desire shall be satisfied on them. I will draw my sword, My hand shall destroy them.'", 
                "num": 9
              }, 
              {
                "text": "You blew with Your wind, The sea covered them; They sank like lead in the mighty waters.", 
                "num": 10
              }, 
              {
                "text": "\"Who is like You, O LORD, among the gods? Who is like You, glorious in holiness, Fearful in praises, doing wonders?", 
                "num": 11
              }, 
              {
                "text": "You stretched out Your right hand; The earth swallowed them.", 
                "num": 12
              }, 
              {
                "text": "You in Your mercy have led forth The people whom You have redeemed; You have guided them in Your strength To Your holy habitation.", 
                "num": 13
              }, 
              {
                "text": "\"The people will hear and be afraid; Sorrow will take hold of the inhabitants of Philistia.", 
                "num": 14
              }, 
              {
                "text": "Then the chiefs of Edom will be dismayed; 1 The mighty men of Moab, Trembling will take hold of them; All the inhabitants of Canaan will melt away.", 
                "num": 15
              }, 
              {
                "text": "Fear and dread will fall on them; By the greatness of Your arm They will be as still as a stone, Till Your people pass over, O LORD, Till the people pass over Whom You have purchased.", 
                "num": 16
              }, 
              {
                "text": "You will bring them in and plant them In the mountain of Your inheritance, In the place, O LORD, which You have made For Your own dwelling, The sanctuary, O LORD, which Your hands have established.", 
                "num": 17
              }, 
              {
                "text": "\"The LORD shall reign forever and ever.\"", 
                "num": 18
              }, 
              {
                "text": "For the horses of Pharaoh went with his chariots and his horsemen into the sea, and the LORD brought back the waters of the sea upon them. But the children of Israel went on dry land in the midst of the sea.", 
                "num": 19
              }, 
              {
                "text": "Then Miriam the prophetess, the sister of Aaron, took the timbrel in her hand; and all the women went out after her with timbrels and with dances.", 
                "num": 20
              }, 
              {
                "text": "And Miriam answered them: \"Sing to the LORD, For He has triumphed gloriously! The horse and its rider He has thrown into the sea!\"", 
                "num": 21
              }, 
              {
                "text": "So Moses brought Israel from the Red Sea; then they went out into the Wilderness of Shur. And they went three days in the wilderness and found no water.", 
                "num": 22
              }, 
              {
                "text": "Now when they came to Marah, they could not drink the waters of Marah, for they were bitter. Therefore the name of it was called Marah.", 
                "num": 23
              }, 
              {
                "text": "And the people complained against Moses, saying, \"What shall we drink?\"", 
                "num": 24
              }, 
              {
                "text": "So he cried out to the LORD, and the LORD showed him a tree. When he cast it into the waters, the waters were made sweet. There He made a statute and an ordinance for them, and there He tested them,", 
                "num": 25
              }, 
              {
                "text": "and said, \"If you diligently heed the voice of the LORD your God and do what is right in His sight, give ear to His commandments and keep all His statutes, I will put none of the diseases on you which I have brought on the Egyptians. For I am the LORD who heals you.\"", 
                "num": 26
              }, 
              {
                "text": "Then they came to Elim, where there were twelve wells of water and seventy palm trees; so they camped there by the waters.", 
                "num": 27
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "And they journeyed from Elim, and all the congregation of the children of Israel came to the Wilderness of Sin, which is between Elim and Sinai, on the fifteenth day of the second month after they departed from the land of Egypt.", 
                "num": 1
              }, 
              {
                "text": "Then the whole congregation of the children of Israel complained against Moses and Aaron in the wilderness.", 
                "num": 2
              }, 
              {
                "text": "And the children of Israel said to them, \"Oh, that we had died by the hand of the LORD in the land of Egypt, when we sat by the pots of meat and when we ate bread to the full! For you have brought us out into this wilderness to kill this whole assembly with hunger.\"", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said to Moses, \"Behold, I will rain bread from heaven for you. And the people shall go out and gather a certain quota every day, that I may test them, whether they will walk in My law or not.", 
                "num": 4
              }, 
              {
                "text": "And it shall be on the sixth day that they shall prepare what they bring in, and it shall be twice as much as they gather daily.\"", 
                "num": 5
              }, 
              {
                "text": "Then Moses and Aaron said to all the children of Israel, \"At evening you shall know that the LORD has brought you out of the land of Egypt.", 
                "num": 6
              }, 
              {
                "text": "And in the morning you shall see the glory of the LORD; for He hears your complaints against the LORD. But what are we, that you complain against us?\"", 
                "num": 7
              }, 
              {
                "text": "Also Moses said, \"This shall be seen when the LORD gives you meat to eat in the evening, and in the morning bread to the full; for the LORD hears your complaints which you make against Him. And what are we? Your complaints are not against us but against the LORD.\"", 
                "num": 8
              }, 
              {
                "text": "Then Moses spoke to Aaron, \"Say to all the congregation of the children of Israel, \"Come near before the LORD, for He has heard your complaints.\"'", 
                "num": 9
              }, 
              {
                "text": "Now it came to pass, as Aaron spoke to the whole congregation of the children of Israel, that they looked toward the wilderness, and behold, the glory of the LORD appeared in the cloud.", 
                "num": 10
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 11
              }, 
              {
                "text": "\"I have heard the complaints of the children of Israel. Speak to them, saying, \"At twilight you shall eat meat, and in the morning you shall be filled with bread. And you shall know that I am the LORD your God.\"'", 
                "num": 12
              }, 
              {
                "text": "So it was that quails came up at evening and covered the camp, and in the morning the dew lay all around the camp.", 
                "num": 13
              }, 
              {
                "text": "And when the layer of dew lifted, there, on the surface of the wilderness, was a small round substance, as fine as frost on the ground.", 
                "num": 14
              }, 
              {
                "text": "So when the children of Israel saw it, they said to one another, \"What is it?\" For they did not know what it was. And Moses said to them, \"This is the bread which the LORD has given you to eat.", 
                "num": 15
              }, 
              {
                "text": "This is the thing which the LORD has commanded: \"Let every man gather it according to each one's need, one omer for each person, according to the number of persons; let every man take for those who are in his tent.\"'", 
                "num": 16
              }, 
              {
                "text": "Then the children of Israel did so and gathered, some more, some less.", 
                "num": 17
              }, 
              {
                "text": "So when they measured it by omers, he who gathered much had nothing left over, and he who gathered little had no lack. Every man had gathered according to each one's need.", 
                "num": 18
              }, 
              {
                "text": "And Moses said, \"Let no one leave any of it till morning.\"", 
                "num": 19
              }, 
              {
                "text": "Notwithstanding they did not heed Moses. But some of them left part of it until morning, and it bred worms and stank. And Moses was angry with them.", 
                "num": 20
              }, 
              {
                "text": "So they gathered it every morning, every man according to his need. And when the sun became hot, it melted.", 
                "num": 21
              }, 
              {
                "text": "And so it was, on the sixth day, that they gathered twice as much bread, two omers for each one. And all the rulers of the congregation came and told Moses.", 
                "num": 22
              }, 
              {
                "text": "Then he said to them, \"This is what the LORD has said: \"Tomorrow is a Sabbath rest, a holy Sabbath to the LORD. Bake what you will bake today, and boil what you will boil; and lay up for yourselves all that remains, to be kept until morning.\"'", 
                "num": 23
              }, 
              {
                "text": "So they laid it up till morning, as Moses commanded; and it did not stink, nor were there any worms in it.", 
                "num": 24
              }, 
              {
                "text": "Then Moses said, \"Eat that today, for today is a Sabbath to the LORD; today you will not find it in the field.", 
                "num": 25
              }, 
              {
                "text": "Six days you shall gather it, but on the seventh day, the Sabbath, there will be none.\"", 
                "num": 26
              }, 
              {
                "text": "Now it happened that some of the people went out on the seventh day to gather, but they found none.", 
                "num": 27
              }, 
              {
                "text": "And the LORD said to Moses, \"How long do you refuse to keep My commandments and My laws?", 
                "num": 28
              }, 
              {
                "text": "See! For the LORD has given you the Sabbath; therefore He gives you on the sixth day bread for two days. Let every man remain in his place; let no man go out of his place on the seventh day.\"", 
                "num": 29
              }, 
              {
                "text": "So the people rested on the seventh day.", 
                "num": 30
              }, 
              {
                "text": "And the house of Israel called its name Manna. And it was like white coriander seed, and the taste of it was like wafers made with honey.", 
                "num": 31
              }, 
              {
                "text": "Then Moses said, \"This is the thing which the LORD has commanded: \"Fill an omer with it, to be kept for your generations, that they may see the bread with which I fed you in the wilderness, when I brought you out of the land of Egypt.\"'", 
                "num": 32
              }, 
              {
                "text": "And Moses said to Aaron, \"Take a pot and put an omer of manna in it, and lay it up before the LORD, to be kept for your generations.\"", 
                "num": 33
              }, 
              {
                "text": "As the LORD commanded Moses, so Aaron laid it up before the Testimony, to be kept.", 
                "num": 34
              }, 
              {
                "text": "And the children of Israel ate manna forty years, until they came to an inhabited land; they ate manna until they came to the border of the land of Canaan.", 
                "num": 35
              }, 
              {
                "text": "Now an omer is one-tenth of an ephah.", 
                "num": 36
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Then all the congregation of the children of Israel set out on their journey from the Wilderness of Sin, according to the commandment of the LORD, and camped in Rephidim; but there was no water for the people to drink.", 
                "num": 1
              }, 
              {
                "text": "Therefore the people contended with Moses, and said, \"Give us water, that we may drink.\" So Moses said to them, \"Why do you contend with me? Why do you tempt the LORD?\"", 
                "num": 2
              }, 
              {
                "text": "And the people thirsted there for water, and the people complained against Moses, and said, \"Why is it you have brought us up out of Egypt, to kill us and our children and our livestock with thirst?\"", 
                "num": 3
              }, 
              {
                "text": "So Moses cried out to the LORD, saying, \"What shall I do with this people? They are almost ready to stone me!\"", 
                "num": 4
              }, 
              {
                "text": "And the LORD said to Moses, \"Go on before the people, and take with you some of the elders of Israel. Also take in your hand your rod with which you struck the river, and go.", 
                "num": 5
              }, 
              {
                "text": "Behold, I will stand before you there on the rock in Horeb; and you shall strike the rock, and water will come out of it, that the people may drink.\" And Moses did so in the sight of the elders of Israel.", 
                "num": 6
              }, 
              {
                "text": "So he called the name of the place Massah and Meribah, because of the contention of the children of Israel, and because they tempted the LORD, saying, \"Is the LORD among us or not?\"", 
                "num": 7
              }, 
              {
                "text": "Now Amalek came and fought with Israel in Rephidim.", 
                "num": 8
              }, 
              {
                "text": "And Moses said to Joshua, \"Choose us some men and go out, fight with Amalek. Tomorrow I will stand on the top of the hill with the rod of God in my hand.\"", 
                "num": 9
              }, 
              {
                "text": "So Joshua did as Moses said to him, and fought with Amalek. And Moses, Aaron, and Hur went up to the top of the hill.", 
                "num": 10
              }, 
              {
                "text": "And so it was, when Moses held up his hand, that Israel prevailed; and when he let down his hand, Amalek prevailed.", 
                "num": 11
              }, 
              {
                "text": "But Moses' hands became heavy; so they took a stone and put it under him, and he sat on it. And Aaron and Hur supported his hands, one on one side, and the other on the other side; and his hands were steady until the going down of the sun.", 
                "num": 12
              }, 
              {
                "text": "So Joshua defeated Amalek and his people with the edge of the sword.", 
                "num": 13
              }, 
              {
                "text": "Then the LORD said to Moses, \"Write this for a memorial in the book and recount it in the hearing of Joshua, that I will utterly blot out the remembrance of Amalek from under heaven.\"", 
                "num": 14
              }, 
              {
                "text": "And Moses built an altar and called its name, The-LORD-Is-My-Banner;", 
                "num": 15
              }, 
              {
                "text": "for he said, \"Because the LORD has sworn: the LORD will have war with Amalek from generation to generation.\"", 
                "num": 16
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "And Jethro, the priest of Midian, Moses' father-in-law, heard of all that God had done for Moses and for Israel His people--that the LORD had brought Israel out of Egypt.", 
                "num": 1
              }, 
              {
                "text": "Then Jethro, Moses' father-in-law, took Zipporah, Moses' wife, after he had sent her back,", 
                "num": 2
              }, 
              {
                "text": "with her two sons, of whom the name of one was Gershom (for he said, \"I have been a stranger in a foreign land\")", 
                "num": 3
              }, 
              {
                "text": "and the name of the other was Eliezer (for he said, \"The God of my father was my help, and delivered me from the sword of Pharaoh\");", 
                "num": 4
              }, 
              {
                "text": "and Jethro, Moses' father-in-law, came with his sons and his wife to Moses in the wilderness, where he was encamped at the mountain of God.", 
                "num": 5
              }, 
              {
                "text": "Now he had said to Moses, \"I, your father-in-law Jethro, am coming to you with your wife and her two sons with her.\"", 
                "num": 6
              }, 
              {
                "text": "So Moses went out to meet his father-in-law, bowed down, and kissed him. And they asked each other about their well-being, and they went into the tent.", 
                "num": 7
              }, 
              {
                "text": "And Moses told his father-in-law all that the LORD had done to Pharaoh and to the Egyptians for Israel's sake, all the hardship that had come upon them on the way, and how the LORD had delivered them.", 
                "num": 8
              }, 
              {
                "text": "Then Jethro rejoiced for all the good which the LORD had done for Israel, whom He had delivered out of the hand of the Egyptians.", 
                "num": 9
              }, 
              {
                "text": "And Jethro said, \"Blessed be the LORD, who has delivered you out of the hand of the Egyptians and out of the hand of Pharaoh, and who has delivered the people from under the hand of the Egyptians.", 
                "num": 10
              }, 
              {
                "text": "Now I know that the LORD is greater than all the gods; for in the very thing in which they behaved proudly, He was above them.\"", 
                "num": 11
              }, 
              {
                "text": "Then Jethro, Moses' father-in-law, took a burnt offering and other sacrifices to offer to God. And Aaron came with all the elders of Israel to eat bread with Moses' father-in-law before God.", 
                "num": 12
              }, 
              {
                "text": "And so it was, on the next day, that Moses sat to judge the people; and the people stood before Moses from morning until evening.", 
                "num": 13
              }, 
              {
                "text": "So when Moses' father-in-law saw all that he did for the people, he said, \"What is this thing that you are doing for the people? Why do you alone sit, and all the people stand before you from morning until evening?\"", 
                "num": 14
              }, 
              {
                "text": "And Moses said to his father-in-law, \"Because the people come to me to inquire of God.", 
                "num": 15
              }, 
              {
                "text": "When they have a difficulty, they come to me, and I judge between one and another; and I make known the statutes of God and His laws.\"", 
                "num": 16
              }, 
              {
                "text": "So Moses' father-in-law said to him, \"The thing that you do is not good.", 
                "num": 17
              }, 
              {
                "text": "Both you and these people who are with you will surely wear yourselves out. For this thing is too much for you; you are not able to perform it by yourself.", 
                "num": 18
              }, 
              {
                "text": "Listen now to my voice; I will give you counsel, and God will be with you: Stand before God for the people, so that you may bring the difficulties to God.", 
                "num": 19
              }, 
              {
                "text": "And you shall teach them the statutes and the laws, and show them the way in which they must walk and the work they must do.", 
                "num": 20
              }, 
              {
                "text": "Moreover you shall select from all the people able men, such as fear God, men of truth, hating covetousness; and place such over them to be rulers of thousands, rulers of hundreds, rulers of fifties, and rulers of tens.", 
                "num": 21
              }, 
              {
                "text": "And let them judge the people at all times. Then it will be that every great matter they shall bring to you, but every small matter they themselves shall judge. So it will be easier for you, for they will bear the burden with you.", 
                "num": 22
              }, 
              {
                "text": "If you do this thing, and God so commands you, then you will be able to endure, and all this people will also go to their place in peace.\"", 
                "num": 23
              }, 
              {
                "text": "So Moses heeded the voice of his father-in-law and did all that he had said.", 
                "num": 24
              }, 
              {
                "text": "And Moses chose able men out of all Israel, and made them heads over the people: rulers of thousands, rulers of hundreds, rulers of fifties, and rulers of tens.", 
                "num": 25
              }, 
              {
                "text": "So they judged the people at all times; the hard cases they brought to Moses, but they judged every small case themselves.", 
                "num": 26
              }, 
              {
                "text": "Then Moses let his father-in-law depart, and he went his way to his own land.", 
                "num": 27
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "In the third month after the children of Israel had gone out of the land of Egypt, on the same day, they came to the Wilderness of Sinai.", 
                "num": 1
              }, 
              {
                "text": "For they had departed from Rephidim, had come to the Wilderness of Sinai, and camped in the wilderness. So Israel camped there before the mountain.", 
                "num": 2
              }, 
              {
                "text": "And Moses went up to God, and the LORD called to him from the mountain, saying, \"Thus you shall say to the house of Jacob, and tell the children of Israel:", 
                "num": 3
              }, 
              {
                "text": "\"You have seen what I did to the Egyptians, and how I bore you on eagles' wings and brought you to Myself.", 
                "num": 4
              }, 
              {
                "text": "Now therefore, if you will indeed obey My voice and keep My covenant, then you shall be a special treasure to Me above all people; for all the earth is Mine.", 
                "num": 5
              }, 
              {
                "text": "And you shall be to Me a kingdom of priests and a holy nation.' These are the words which you shall speak to the children of Israel.\"", 
                "num": 6
              }, 
              {
                "text": "So Moses came and called for the elders of the people, and laid before them all these words which the LORD commanded him.", 
                "num": 7
              }, 
              {
                "text": "Then all the people answered together and said, \"All that the LORD has spoken we will do.\" So Moses brought back the words of the people to the LORD.", 
                "num": 8
              }, 
              {
                "text": "And the LORD said to Moses, \"Behold, I come to you in the thick cloud, that the people may hear when I speak with you, and believe you forever.\" So Moses told the words of the people to the LORD.", 
                "num": 9
              }, 
              {
                "text": "Then the LORD said to Moses, \"Go to the people and consecrate them today and tomorrow, and let them wash their clothes.", 
                "num": 10
              }, 
              {
                "text": "And let them be ready for the third day. For on the third day the LORD will come down upon Mount Sinai in the sight of all the people.", 
                "num": 11
              }, 
              {
                "text": "You shall set bounds for the people all around, saying, \"Take heed to yourselves that you do not go up to the mountain or touch its base. Whoever touches the mountain shall surely be put to death.", 
                "num": 12
              }, 
              {
                "text": "Not a hand shall touch him, but he shall surely be stoned or shot with an arrow; whether man or beast, he shall not live.' When the trumpet sounds long, they shall come near the mountain.\"", 
                "num": 13
              }, 
              {
                "text": "So Moses went down from the mountain to the people and sanctified the people, and they washed their clothes.", 
                "num": 14
              }, 
              {
                "text": "And he said to the people, \"Be ready for the third day; do not come near your wives.\"", 
                "num": 15
              }, 
              {
                "text": "Then it came to pass on the third day, in the morning, that there were thunderings and lightnings, and a thick cloud on the mountain; and the sound of the trumpet was very loud, so that all the people who were in the camp trembled.", 
                "num": 16
              }, 
              {
                "text": "And Moses brought the people out of the camp to meet with God, and they stood at the foot of the mountain.", 
                "num": 17
              }, 
              {
                "text": "Now Mount Sinai was completely in smoke, because the LORD descended upon it in fire. Its smoke ascended like the smoke of a furnace, and the whole mountain quaked greatly.", 
                "num": 18
              }, 
              {
                "text": "And when the blast of the trumpet sounded long and became louder and louder, Moses spoke, and God answered him by voice.", 
                "num": 19
              }, 
              {
                "text": "Then the LORD came down upon Mount Sinai, on the top of the mountain. And the LORD called Moses to the top of the mountain, and Moses went up.", 
                "num": 20
              }, 
              {
                "text": "And the LORD said to Moses, \"Go down and warn the people, lest they break through to gaze at the LORD, and many of them perish.", 
                "num": 21
              }, 
              {
                "text": "Also let the priests who come near the LORD consecrate themselves, lest the LORD break out against them.\"", 
                "num": 22
              }, 
              {
                "text": "But Moses said to the LORD, \"The people cannot come up to Mount Sinai; for You warned us, saying, \"Set bounds around the mountain and consecrate it.\"'", 
                "num": 23
              }, 
              {
                "text": "Then the LORD said to him, \"Away! Get down and then come up, you and Aaron with you. But do not let the priests and the people break through to come up to the LORD, lest He break out against them.\"", 
                "num": 24
              }, 
              {
                "text": "So Moses went down to the people and spoke to them.", 
                "num": 25
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "And God spoke all these words, saying:", 
                "num": 1
              }, 
              {
                "text": "\"I am the LORD your God, who brought you out of the land of Egypt, out of the house of bondage.", 
                "num": 2
              }, 
              {
                "text": "\"You shall have no other gods before Me.", 
                "num": 3
              }, 
              {
                "text": "\"You shall not make for yourself a carved image--any likeness of anything that is in heaven above, or that is in the earth beneath, or that is in the water under the earth;", 
                "num": 4
              }, 
              {
                "text": "you shall not bow down to them nor serve them. For I, the LORD your God, am a jealous God, visiting the iniquity of the fathers upon the children to the third and fourth generations of those who hate Me,", 
                "num": 5
              }, 
              {
                "text": "but showing mercy to thousands, to those who love Me and keep My commandments.", 
                "num": 6
              }, 
              {
                "text": "\"You shall not take the name of the LORD your God in vain, for the LORD will not hold him guiltless who takes His name in vain.", 
                "num": 7
              }, 
              {
                "text": "\"Remember the Sabbath day, to keep it holy.", 
                "num": 8
              }, 
              {
                "text": "Six days you shall labor and do all your work,", 
                "num": 9
              }, 
              {
                "text": "but the seventh day is the Sabbath of the LORD your God. In it you shall do no work: you, nor your son, nor your daughter, nor your male servant, nor your female servant, nor your cattle, nor your stranger who is within your gates.", 
                "num": 10
              }, 
              {
                "text": "For in six days the LORD made the heavens and the earth, the sea, and all that is in them, and rested the seventh day. Therefore the LORD blessed the Sabbath day and hallowed it.", 
                "num": 11
              }, 
              {
                "text": "\"Honor your father and your mother, that your days may be long upon the land which the LORD your God is giving you.", 
                "num": 12
              }, 
              {
                "text": "\"You shall not murder.", 
                "num": 13
              }, 
              {
                "text": "\"You shall not commit adultery.", 
                "num": 14
              }, 
              {
                "text": "\"You shall not steal.", 
                "num": 15
              }, 
              {
                "text": "\"You shall not bear false witness against your neighbor.", 
                "num": 16
              }, 
              {
                "text": "\"You shall not covet your neighbor's house; you shall not covet your neighbor's wife, nor his male servant, nor his female servant, nor his ox, nor his donkey, nor anything that is your neighbor's.\"", 
                "num": 17
              }, 
              {
                "text": "Now all the people witnessed the thunderings, the lightning flashes, the sound of the trumpet, and the mountain smoking; and when the people saw it, they trembled and stood afar off.", 
                "num": 18
              }, 
              {
                "text": "Then they said to Moses, \"You speak with us, and we will hear; but let not God speak with us, lest we die.\"", 
                "num": 19
              }, 
              {
                "text": "And Moses said to the people, \"Do not fear; for God has come to test you, and that His fear may be before you, so that you may not sin.\"", 
                "num": 20
              }, 
              {
                "text": "So the people stood afar off, but Moses drew near the thick darkness where God was.", 
                "num": 21
              }, 
              {
                "text": "Then the LORD said to Moses, \"Thus you shall say to the children of Israel: \"You have seen that I have talked with you from heaven.", 
                "num": 22
              }, 
              {
                "text": "You shall not make anything to be with Me--gods of silver or gods of gold you shall not make for yourselves.", 
                "num": 23
              }, 
              {
                "text": "An altar of earth you shall make for Me, and you shall sacrifice on it your burnt offerings and your peace offerings, your sheep and your oxen. In every place where I record My name I will come to you, and I will bless you.", 
                "num": 24
              }, 
              {
                "text": "And if you make Me an altar of stone, you shall not build it of hewn stone; for if you use your tool on it, you have profaned it.", 
                "num": 25
              }, 
              {
                "text": "Nor shall you go up by steps to My altar, that your nakedness may not be exposed on it.'", 
                "num": 26
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "\"Now these are the judgments which you shall set before them:", 
                "num": 1
              }, 
              {
                "text": "If you buy a Hebrew servant, he shall serve six years; and in the seventh he shall go out free and pay nothing.", 
                "num": 2
              }, 
              {
                "text": "If he comes in by himself, he shall go out by himself; if he comes in married, then his wife shall go out with him.", 
                "num": 3
              }, 
              {
                "text": "If his master has given him a wife, and she has borne him sons or daughters, the wife and her children shall be her master's, and he shall go out by himself.", 
                "num": 4
              }, 
              {
                "text": "But if the servant plainly says, \"I love my master, my wife, and my children; I will not go out free,'", 
                "num": 5
              }, 
              {
                "text": "then his master shall bring him to the judges. He shall also bring him to the door, or to the doorpost, and his master shall pierce his ear with an awl; and he shall serve him forever.", 
                "num": 6
              }, 
              {
                "text": "\"And if a man sells his daughter to be a female slave, she shall not go out as the male slaves do.", 
                "num": 7
              }, 
              {
                "text": "If she does not please her master, who has betrothed her to himself, then he shall let her be redeemed. He shall have no right to sell her to a foreign people, since he has dealt deceitfully with her.", 
                "num": 8
              }, 
              {
                "text": "And if he has betrothed her to his son, he shall deal with her according to the custom of daughters.", 
                "num": 9
              }, 
              {
                "text": "If he takes another wife, he shall not diminish her food, her clothing, and her marriage rights.", 
                "num": 10
              }, 
              {
                "text": "And if he does not do these three for her, then she shall go out free, without paying money.", 
                "num": 11
              }, 
              {
                "text": "\"He who strikes a man so that he dies shall surely be put to death.", 
                "num": 12
              }, 
              {
                "text": "However, if he did not lie in wait, but God delivered him into his hand, then I will appoint for you a place where he may flee.", 
                "num": 13
              }, 
              {
                "text": "\"But if a man acts with premeditation against his neighbor, to kill him by treachery, you shall take him from My altar, that he may die.", 
                "num": 14
              }, 
              {
                "text": "\"And he who strikes his father or his mother shall surely be put to death.", 
                "num": 15
              }, 
              {
                "text": "\"He who kidnaps a man and sells him, or if he is found in his hand, shall surely be put to death.", 
                "num": 16
              }, 
              {
                "text": "\"And he who curses his father or his mother shall surely be put to death.", 
                "num": 17
              }, 
              {
                "text": "\"If men contend with each other, and one strikes the other with a stone or with his fist, and he does not die but is confined to his bed,", 
                "num": 18
              }, 
              {
                "text": "if he rises again and walks about outside with his staff, then he who struck him shall be acquitted. He shall only pay for the loss of his time, and shall provide for him to be thoroughly healed.", 
                "num": 19
              }, 
              {
                "text": "\"And if a man beats his male or female servant with a rod, so that he dies under his hand, he shall surely be punished.", 
                "num": 20
              }, 
              {
                "text": "Notwithstanding, if he remains alive a day or two, he shall not be punished; for he is his property.", 
                "num": 21
              }, 
              {
                "text": "\"If men fight, and hurt a woman with child, so that she gives birth prematurely, yet no harm follows, he shall surely be punished accordingly as the woman's husband imposes on him; and he shall pay as the judges determine.", 
                "num": 22
              }, 
              {
                "text": "But if any harm follows, then you shall give life for life,", 
                "num": 23
              }, 
              {
                "text": "eye for eye, tooth for tooth, hand for hand, foot for foot,", 
                "num": 24
              }, 
              {
                "text": "burn for burn, wound for wound, stripe for stripe.", 
                "num": 25
              }, 
              {
                "text": "\"If a man strikes the eye of his male or female servant, and destroys it, he shall let him go free for the sake of his eye.", 
                "num": 26
              }, 
              {
                "text": "And if he knocks out the tooth of his male or female servant, he shall let him go free for the sake of his tooth.", 
                "num": 27
              }, 
              {
                "text": "\"If an ox gores a man or a woman to death, then the ox shall surely be stoned, and its flesh shall not be eaten; but the owner of the ox shall be acquitted.", 
                "num": 28
              }, 
              {
                "text": "But if the ox tended to thrust with its horn in times past, and it has been made known to his owner, and he has not kept it confined, so that it has killed a man or a woman, the ox shall be stoned and its owner also shall be put to death.", 
                "num": 29
              }, 
              {
                "text": "If there is imposed on him a sum of money, then he shall pay to redeem his life, whatever is imposed on him.", 
                "num": 30
              }, 
              {
                "text": "Whether it has gored a son or gored a daughter, according to this judgment it shall be done to him.", 
                "num": 31
              }, 
              {
                "text": "If the ox gores a male or female servant, he shall give to their master thirty shekels of silver, and the ox shall be stoned.", 
                "num": 32
              }, 
              {
                "text": "\"And if a man opens a pit, or if a man digs a pit and does not cover it, and an ox or a donkey falls in it,", 
                "num": 33
              }, 
              {
                "text": "the owner of the pit shall make it good; he shall give money to their owner, but the dead animal shall be his.", 
                "num": 34
              }, 
              {
                "text": "\"If one man's ox hurts another's, so that it dies, then they shall sell the live ox and divide the money from it; and the dead ox they shall also divide.", 
                "num": 35
              }, 
              {
                "text": "Or if it was known that the ox tended to thrust in time past, and its owner has not kept it confined, he shall surely pay ox for ox, and the dead animal shall be his own.", 
                "num": 36
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "\"If a man steals an ox or a sheep, and slaughters it or sells it, he shall restore five oxen for an ox and four sheep for a sheep.", 
                "num": 1
              }, 
              {
                "text": "If the thief is found breaking in, and he is struck so that he dies, there shall be no guilt for his bloodshed.", 
                "num": 2
              }, 
              {
                "text": "If the sun has risen on him, there shall be guilt for his bloodshed. He should make full restitution; if he has nothing, then he shall be sold for his theft.", 
                "num": 3
              }, 
              {
                "text": "If the theft is certainly found alive in his hand, whether it is an ox or donkey or sheep, he shall restore double.", 
                "num": 4
              }, 
              {
                "text": "\"If a man causes a field or vineyard to be grazed, and lets loose his animal, and it feeds in another man's field, he shall make restitution from the best of his own field and the best of his own vineyard.", 
                "num": 5
              }, 
              {
                "text": "\"If fire breaks out and catches in thorns, so that stacked grain, standing grain, or the field is consumed, he who kindled the fire shall surely make restitution.", 
                "num": 6
              }, 
              {
                "text": "\"If a man delivers to his neighbor money or articles to keep, and it is stolen out of the man's house, if the thief is found, he shall pay double.", 
                "num": 7
              }, 
              {
                "text": "If the thief is not found, then the master of the house shall be brought to the judges to see whether he has put his hand into his neighbor's goods.", 
                "num": 8
              }, 
              {
                "text": "\"For any kind of trespass, whether it concerns an ox, a donkey, a sheep, or clothing, or for any kind of lost thing which another claims to be his, the cause of both parties shall come before the judges; and whomever the judges condemn shall pay double to his neighbor.", 
                "num": 9
              }, 
              {
                "text": "If a man delivers to his neighbor a donkey, an ox, a sheep, or any animal to keep, and it dies, is hurt, or driven away, no one seeing it,", 
                "num": 10
              }, 
              {
                "text": "then an oath of the LORD shall be between them both, that he has not put his hand into his neighbor's goods; and the owner of it shall accept that, and he shall not make it good.", 
                "num": 11
              }, 
              {
                "text": "But if, in fact, it is stolen from him, he shall make restitution to the owner of it.", 
                "num": 12
              }, 
              {
                "text": "If it is torn to pieces by a beast, then he shall bring it as evidence, and he shall not make good what was torn.", 
                "num": 13
              }, 
              {
                "text": "\"And if a man borrows anything from his neighbor, and it becomes injured or dies, the owner of it not being with it, he shall surely make it good.", 
                "num": 14
              }, 
              {
                "text": "If its owner was with it, he shall not make it good; if it was hired, it came for its hire.", 
                "num": 15
              }, 
              {
                "text": "\"If a man entices a virgin who is not betrothed, and lies with her, he shall surely pay the bride-price for her to be his wife.", 
                "num": 16
              }, 
              {
                "text": "If her father utterly refuses to give her to him, he shall pay money according to the bride-price of virgins.", 
                "num": 17
              }, 
              {
                "text": "\"You shall not permit a sorceress to live.", 
                "num": 18
              }, 
              {
                "text": "\"Whoever lies with an animal shall surely be put to death.", 
                "num": 19
              }, 
              {
                "text": "\"He who sacrifices to any god, except to the LORD only, he shall be utterly destroyed.", 
                "num": 20
              }, 
              {
                "text": "\"You shall neither mistreat a stranger nor oppress him, for you were strangers in the land of Egypt.", 
                "num": 21
              }, 
              {
                "text": "\"You shall not afflict any widow or fatherless child.", 
                "num": 22
              }, 
              {
                "text": "If you afflict them in any way, and they cry at all to Me, I will surely hear their cry;", 
                "num": 23
              }, 
              {
                "text": "and My wrath will become hot, and I will kill you with the sword; your wives shall be widows, and your children fatherless.", 
                "num": 24
              }, 
              {
                "text": "\"If you lend money to any of My people who are poor among you, you shall not be like a moneylender to him; you shall not charge him interest.", 
                "num": 25
              }, 
              {
                "text": "If you ever take your neighbor's garment as a pledge, you shall return it to him before the sun goes down.", 
                "num": 26
              }, 
              {
                "text": "For that is his only covering, it is his garment for his skin. What will he sleep in? And it will be that when he cries to Me, I will hear, for I am gracious.", 
                "num": 27
              }, 
              {
                "text": "\"You shall not revile God, nor curse a ruler of your people.", 
                "num": 28
              }, 
              {
                "text": "\"You shall not delay to offer the first of your ripe produce and your juices. The firstborn of your sons you shall give to Me.", 
                "num": 29
              }, 
              {
                "text": "Likewise you shall do with your oxen and your sheep. It shall be with its mother seven days; on the eighth day you shall give it to Me.", 
                "num": 30
              }, 
              {
                "text": "\"And you shall be holy men to Me: you shall not eat meat torn by beasts in the field; you shall throw it to the dogs.", 
                "num": 31
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "\"You shall not circulate a false report. Do not put your hand with the wicked to be an unrighteous witness.", 
                "num": 1
              }, 
              {
                "text": "You shall not follow a crowd to do evil; nor shall you testify in a dispute so as to turn aside after many to pervert justice.", 
                "num": 2
              }, 
              {
                "text": "You shall not show partiality to a poor man in his dispute.", 
                "num": 3
              }, 
              {
                "text": "\"If you meet your enemy's ox or his donkey going astray, you shall surely bring it back to him again.", 
                "num": 4
              }, 
              {
                "text": "If you see the donkey of one who hates you lying under its burden, and you would refrain from helping it, you shall surely help him with it.", 
                "num": 5
              }, 
              {
                "text": "\"You shall not pervert the judgment of your poor in his dispute.", 
                "num": 6
              }, 
              {
                "text": "Keep yourself far from a false matter; do not kill the innocent and righteous. For I will not justify the wicked.", 
                "num": 7
              }, 
              {
                "text": "And you shall take no bribe, for a bribe blinds the discerning and perverts the words of the righteous.", 
                "num": 8
              }, 
              {
                "text": "\"Also you shall not oppress a stranger, for you know the heart of a stranger, because you were strangers in the land of Egypt.", 
                "num": 9
              }, 
              {
                "text": "\"Six years you shall sow your land and gather in its produce,", 
                "num": 10
              }, 
              {
                "text": "but the seventh year you shall let it rest and lie fallow, that the poor of your people may eat; and what they leave, the beasts of the field may eat. In like manner you shall do with your vineyard and your olive grove.", 
                "num": 11
              }, 
              {
                "text": "Six days you shall do your work, and on the seventh day you shall rest, that your ox and your donkey may rest, and the son of your female servant and the stranger may be refreshed.", 
                "num": 12
              }, 
              {
                "text": "\"And in all that I have said to you, be circumspect and make no mention of the name of other gods, nor let it be heard from your mouth.", 
                "num": 13
              }, 
              {
                "text": "\"Three times you shall keep a feast to Me in the year:", 
                "num": 14
              }, 
              {
                "text": "You shall keep the Feast of Unleavened Bread (you shall eat unleavened bread seven days, as I commanded you, at the time appointed in the month of Abib, for in it you came out of Egypt; none shall appear before Me empty);", 
                "num": 15
              }, 
              {
                "text": "and the Feast of Harvest, the firstfruits of your labors which you have sown in the field; and the Feast of Ingathering at the end of the year, when you have gathered in the fruit of your labors from the field.", 
                "num": 16
              }, 
              {
                "text": "\"Three times in the year all your males shall appear before the Lord GOD.", 
                "num": 17
              }, 
              {
                "text": "\"You shall not offer the blood of My sacrifice with leavened bread; nor shall the fat of My sacrifice remain until morning.", 
                "num": 18
              }, 
              {
                "text": "The first of the firstfruits of your land you shall bring into the house of the LORD your God. You shall not boil a young goat in its mother's milk.", 
                "num": 19
              }, 
              {
                "text": "\"Behold, I send an Angel before you to keep you in the way and to bring you into the place which I have prepared.", 
                "num": 20
              }, 
              {
                "text": "Beware of Him and obey His voice; do not provoke Him, for He will not pardon your transgressions; for My name is in Him.", 
                "num": 21
              }, 
              {
                "text": "But if you indeed obey His voice and do all that I speak, then I will be an enemy to your enemies and an adversary to your adversaries.", 
                "num": 22
              }, 
              {
                "text": "For My Angel will go before you and bring you in to the Amorites and the Hittites and the Perizzites and the Canaanites and the Hivites and the Jebusites; and I will cut them off.", 
                "num": 23
              }, 
              {
                "text": "You shall not bow down to their gods, nor serve them, nor do according to their works; but you shall utterly overthrow them and completely break down their sacred pillars.", 
                "num": 24
              }, 
              {
                "text": "\"So you shall serve the LORD your God, and He will bless your bread and your water. And I will take sickness away from the midst of you.", 
                "num": 25
              }, 
              {
                "text": "No one shall suffer miscarriage or be barren in your land; I will fulfill the number of your days.", 
                "num": 26
              }, 
              {
                "text": "\"I will send My fear before you, I will cause confusion among all the people to whom you come, and will make all your enemies turn their backs to you.", 
                "num": 27
              }, 
              {
                "text": "And I will send hornets before you, which shall drive out the Hivite, the Canaanite, and the Hittite from before you.", 
                "num": 28
              }, 
              {
                "text": "I will not drive them out from before you in one year, lest the land become desolate and the beasts of the field become too numerous for you.", 
                "num": 29
              }, 
              {
                "text": "Little by little I will drive them out from before you, until you have increased, and you inherit the land.", 
                "num": 30
              }, 
              {
                "text": "And I will set your bounds from the Red Sea to the sea, Philistia, and from the desert to the River. For I will deliver the inhabitants of the land into your hand, and you shall drive them out before you.", 
                "num": 31
              }, 
              {
                "text": "You shall make no covenant with them, nor with their gods.", 
                "num": 32
              }, 
              {
                "text": "They shall not dwell in your land, lest they make you sin against Me. For if you serve their gods, it will surely be a snare to you.\"", 
                "num": 33
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now He said to Moses, \"Come up to the LORD, you and Aaron, Nadab and Abihu, and seventy of the elders of Israel, and worship from afar.", 
                "num": 1
              }, 
              {
                "text": "And Moses alone shall come near the LORD, but they shall not come near; nor shall the people go up with him.\"", 
                "num": 2
              }, 
              {
                "text": "So Moses came and told the people all the words of the LORD and all the judgments. And all the people answered with one voice and said, \"All the words which the LORD has said we will do.\"", 
                "num": 3
              }, 
              {
                "text": "And Moses wrote all the words of the LORD. And he rose early in the morning, and built an altar at the foot of the mountain, and twelve pillars according to the twelve tribes of Israel.", 
                "num": 4
              }, 
              {
                "text": "Then he sent young men of the children of Israel, who offered burnt offerings and sacrificed peace offerings of oxen to the LORD.", 
                "num": 5
              }, 
              {
                "text": "And Moses took half the blood and put it in basins, and half the blood he sprinkled on the altar.", 
                "num": 6
              }, 
              {
                "text": "Then he took the Book of the Covenant and read in the hearing of the people. And they said, \"All that the LORD has said we will do, and be obedient.\"", 
                "num": 7
              }, 
              {
                "text": "And Moses took the blood, sprinkled it on the people, and said, \"This is the blood of the covenant which the LORD has made with you according to all these words.\"", 
                "num": 8
              }, 
              {
                "text": "Then Moses went up, also Aaron, Nadab, and Abihu, and seventy of the elders of Israel,", 
                "num": 9
              }, 
              {
                "text": "and they saw the God of Israel. And there was under His feet as it were a paved work of sapphire stone, and it was like the very heavens in its clarity.", 
                "num": 10
              }, 
              {
                "text": "But on the nobles of the children of Israel He did not lay His hand. So they saw God, and they ate and drank.", 
                "num": 11
              }, 
              {
                "text": "Then the LORD said to Moses, \"Come up to Me on the mountain and be there; and I will give you tablets of stone, and the law and commandments which I have written, that you may teach them.\"", 
                "num": 12
              }, 
              {
                "text": "So Moses arose with his assistant Joshua, and Moses went up to the mountain of God.", 
                "num": 13
              }, 
              {
                "text": "And he said to the elders, \"Wait here for us until we come back to you. Indeed, Aaron and Hur are with you. If any man has a difficulty, let him go to them.\"", 
                "num": 14
              }, 
              {
                "text": "Then Moses went up into the mountain, and a cloud covered the mountain.", 
                "num": 15
              }, 
              {
                "text": "Now the glory of the LORD rested on Mount Sinai, and the cloud covered it six days. And on the seventh day He called to Moses out of the midst of the cloud.", 
                "num": 16
              }, 
              {
                "text": "The sight of the glory of the LORD was like a consuming fire on the top of the mountain in the eyes of the children of Israel.", 
                "num": 17
              }, 
              {
                "text": "So Moses went into the midst of the cloud and went up into the mountain. And Moses was on the mountain forty days and forty nights.", 
                "num": 18
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, that they bring Me an offering. From everyone who gives it willingly with his heart you shall take My offering.", 
                "num": 2
              }, 
              {
                "text": "And this is the offering which you shall take from them: gold, silver, and bronze;", 
                "num": 3
              }, 
              {
                "text": "blue, purple, and scarlet thread, fine linen, and goats' hair;", 
                "num": 4
              }, 
              {
                "text": "ram skins dyed red, badger skins, and acacia wood;", 
                "num": 5
              }, 
              {
                "text": "oil for the light, and spices for the anointing oil and for the sweet incense;", 
                "num": 6
              }, 
              {
                "text": "onyx stones, and stones to be set in the ephod and in the breastplate.", 
                "num": 7
              }, 
              {
                "text": "And let them make Me a sanctuary, that I may dwell among them.", 
                "num": 8
              }, 
              {
                "text": "According to all that I show you, that is, the pattern of the tabernacle and the pattern of all its furnishings, just so you shall make it.", 
                "num": 9
              }, 
              {
                "text": "\"And they shall make an ark of acacia wood; two and a half cubits shall be its length, a cubit and a half its width, and a cubit and a half its height.", 
                "num": 10
              }, 
              {
                "text": "And you shall overlay it with pure gold, inside and out you shall overlay it, and shall make on it a molding of gold all around.", 
                "num": 11
              }, 
              {
                "text": "You shall cast four rings of gold for it, and put them in its four corners; two rings shall be on one side, and two rings on the other side.", 
                "num": 12
              }, 
              {
                "text": "And you shall make poles of acacia wood, and overlay them with gold.", 
                "num": 13
              }, 
              {
                "text": "You shall put the poles into the rings on the sides of the ark, that the ark may be carried by them.", 
                "num": 14
              }, 
              {
                "text": "The poles shall be in the rings of the ark; they shall not be taken from it.", 
                "num": 15
              }, 
              {
                "text": "And you shall put into the ark the Testimony which I will give you.", 
                "num": 16
              }, 
              {
                "text": "\"You shall make a mercy seat of pure gold; two and a half cubits shall be its length and a cubit and a half its width.", 
                "num": 17
              }, 
              {
                "text": "And you shall make two cherubim of gold; of hammered work you shall make them at the two ends of the mercy seat.", 
                "num": 18
              }, 
              {
                "text": "Make one cherub at one end, and the other cherub at the other end; you shall make the cherubim at the two ends of it of one piece with the mercy seat.", 
                "num": 19
              }, 
              {
                "text": "And the cherubim shall stretch out their wings above, covering the mercy seat with their wings, and they shall face one another; the faces of the cherubim shall be toward the mercy seat.", 
                "num": 20
              }, 
              {
                "text": "You shall put the mercy seat on top of the ark, and in the ark you shall put the Testimony that I will give you.", 
                "num": 21
              }, 
              {
                "text": "And there I will meet with you, and I will speak with you from above the mercy seat, from between the two cherubim which are on the ark of the Testimony, about everything which I will give you in commandment to the children of Israel.", 
                "num": 22
              }, 
              {
                "text": "\"You shall also make a table of acacia wood; two cubits shall be its length, a cubit its width, and a cubit and a half its height.", 
                "num": 23
              }, 
              {
                "text": "And you shall overlay it with pure gold, and make a molding of gold all around.", 
                "num": 24
              }, 
              {
                "text": "You shall make for it a frame of a handbreadth all around, and you shall make a gold molding for the frame all around.", 
                "num": 25
              }, 
              {
                "text": "And you shall make for it four rings of gold, and put the rings on the four corners that are at its four legs.", 
                "num": 26
              }, 
              {
                "text": "The rings shall be close to the frame, as holders for the poles to bear the table.", 
                "num": 27
              }, 
              {
                "text": "And you shall make the poles of acacia wood, and overlay them with gold, that the table may be carried with them.", 
                "num": 28
              }, 
              {
                "text": "You shall make its dishes, its pans, its pitchers, and its bowls for pouring. You shall make them of pure gold.", 
                "num": 29
              }, 
              {
                "text": "And you shall set the showbread on the table before Me always.", 
                "num": 30
              }, 
              {
                "text": "\"You shall also make a lampstand of pure gold; the lampstand shall be of hammered work. Its shaft, its branches, its bowls, its ornamental knobs, and flowers shall be of one piece.", 
                "num": 31
              }, 
              {
                "text": "And six branches shall come out of its sides: three branches of the lampstand out of one side, and three branches of the lampstand out of the other side.", 
                "num": 32
              }, 
              {
                "text": "Three bowls shall be made like almond blossoms on one branch, with an ornamental knob and a flower, and three bowls made like almond blossoms on the other branch, with an ornamental knob and a flower--and so for the six branches that come out of the lampstand.", 
                "num": 33
              }, 
              {
                "text": "On the lampstand itself four bowls shall be made like almond blossoms, each with its ornamental knob and flower.", 
                "num": 34
              }, 
              {
                "text": "And there shall be a knob under the first two branches of the same, a knob under the second two branches of the same, and a knob under the third two branches of the same, according to the six branches that extend from the lampstand.", 
                "num": 35
              }, 
              {
                "text": "Their knobs and their branches shall be of one piece; all of it shall be one hammered piece of pure gold.", 
                "num": 36
              }, 
              {
                "text": "You shall make seven lamps for it, and they shall arrange its lamps so that they give light in front of it.", 
                "num": 37
              }, 
              {
                "text": "And its wick-trimmers and their trays shall be of pure gold.", 
                "num": 38
              }, 
              {
                "text": "It shall be made of a talent of pure gold, with all these utensils.", 
                "num": 39
              }, 
              {
                "text": "And see to it that you make them according to the pattern which was shown you on the mountain.", 
                "num": 40
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "\"Moreover you shall make the tabernacle with ten curtains of fine woven linen and blue, purple, and scarlet thread; with artistic designs of cherubim you shall weave them.", 
                "num": 1
              }, 
              {
                "text": "The length of each curtain shall be twenty-eight cubits, and the width of each curtain four cubits. And every one of the curtains shall have the same measurements.", 
                "num": 2
              }, 
              {
                "text": "Five curtains shall be coupled to one another, and the other five curtains shall be coupled to one another.", 
                "num": 3
              }, 
              {
                "text": "And you shall make loops of blue yarn on the edge of the curtain on the selvedge of one set, and likewise you shall do on the outer edge of the other curtain of the second set.", 
                "num": 4
              }, 
              {
                "text": "Fifty loops you shall make in the one curtain, and fifty loops you shall make on the edge of the curtain that is on the end of the second set, that the loops may be clasped to one another.", 
                "num": 5
              }, 
              {
                "text": "And you shall make fifty clasps of gold, and couple the curtains together with the clasps, so that it may be one tabernacle.", 
                "num": 6
              }, 
              {
                "text": "\"You shall also make curtains of goats' hair, to be a tent over the tabernacle. You shall make eleven curtains.", 
                "num": 7
              }, 
              {
                "text": "The length of each curtain shall be thirty cubits, and the width of each curtain four cubits; and the eleven curtains shall all have the same measurements.", 
                "num": 8
              }, 
              {
                "text": "And you shall couple five curtains by themselves and six curtains by themselves, and you shall double over the sixth curtain at the forefront of the tent.", 
                "num": 9
              }, 
              {
                "text": "You shall make fifty loops on the edge of the curtain that is outermost in one set, and fifty loops on the edge of the curtain of the second set.", 
                "num": 10
              }, 
              {
                "text": "And you shall make fifty bronze clasps, put the clasps into the loops, and couple the tent together, that it may be one.", 
                "num": 11
              }, 
              {
                "text": "The remnant that remains of the curtains of the tent, the half curtain that remains, shall hang over the back of the tabernacle.", 
                "num": 12
              }, 
              {
                "text": "And a cubit on one side and a cubit on the other side, of what remains of the length of the curtains of the tent, shall hang over the sides of the tabernacle, on this side and on that side, to cover it.", 
                "num": 13
              }, 
              {
                "text": "\"You shall also make a covering of ram skins dyed red for the tent, and a covering of badger skins above that.", 
                "num": 14
              }, 
              {
                "text": "\"And for the tabernacle you shall make the boards of acacia wood, standing upright.", 
                "num": 15
              }, 
              {
                "text": "Ten cubits shall be the length of a board, and a cubit and a half shall be the width of each board.", 
                "num": 16
              }, 
              {
                "text": "Two tenons shall be in each board for binding one to another. Thus you shall make for all the boards of the tabernacle.", 
                "num": 17
              }, 
              {
                "text": "And you shall make the boards for the tabernacle, twenty boards for the south side.", 
                "num": 18
              }, 
              {
                "text": "You shall make forty sockets of silver under the twenty boards: two sockets under each of the boards for its two tenons.", 
                "num": 19
              }, 
              {
                "text": "And for the second side of the tabernacle, the north side, there shall be twenty boards", 
                "num": 20
              }, 
              {
                "text": "and their forty sockets of silver: two sockets under each of the boards.", 
                "num": 21
              }, 
              {
                "text": "For the far side of the tabernacle, westward, you shall make six boards.", 
                "num": 22
              }, 
              {
                "text": "And you shall also make two boards for the two back corners of the tabernacle.", 
                "num": 23
              }, 
              {
                "text": "They shall be coupled together at the bottom and they shall be coupled together at the top by one ring. Thus it shall be for both of them. They shall be for the two corners.", 
                "num": 24
              }, 
              {
                "text": "So there shall be eight boards with their sockets of silver--sixteen sockets--two sockets under each of the boards.", 
                "num": 25
              }, 
              {
                "text": "\"And you shall make bars of acacia wood: five for the boards on one side of the tabernacle,", 
                "num": 26
              }, 
              {
                "text": "five bars for the boards on the other side of the tabernacle, and five bars for the boards of the side of the tabernacle, for the far side westward.", 
                "num": 27
              }, 
              {
                "text": "The middle bar shall pass through the midst of the boards from end to end.", 
                "num": 28
              }, 
              {
                "text": "You shall overlay the boards with gold, make their rings of gold as holders for the bars, and overlay the bars with gold.", 
                "num": 29
              }, 
              {
                "text": "And you shall raise up the tabernacle according to its pattern which you were shown on the mountain.", 
                "num": 30
              }, 
              {
                "text": "\"You shall make a veil woven of blue, purple, and scarlet thread, and fine woven linen. It shall be woven with an artistic design of cherubim.", 
                "num": 31
              }, 
              {
                "text": "You shall hang it upon the four pillars of acacia wood overlaid with gold. Their hooks shall be gold, upon four sockets of silver.", 
                "num": 32
              }, 
              {
                "text": "And you shall hang the veil from the clasps. Then you shall bring the ark of the Testimony in there, behind the veil. The veil shall be a divider for you between the holy place and the Most Holy.", 
                "num": 33
              }, 
              {
                "text": "You shall put the mercy seat upon the ark of the Testimony in the Most Holy.", 
                "num": 34
              }, 
              {
                "text": "You shall set the table outside the veil, and the lampstand across from the table on the side of the tabernacle toward the south; and you shall put the table on the north side.", 
                "num": 35
              }, 
              {
                "text": "\"You shall make a screen for the door of the tabernacle, woven of blue, purple, and scarlet thread, and fine woven linen, made by a weaver.", 
                "num": 36
              }, 
              {
                "text": "And you shall make for the screen five pillars of acacia wood, and overlay them with gold; their hooks shall be gold, and you shall cast five sockets of bronze for them.", 
                "num": 37
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "\"You shall make an altar of acacia wood, five cubits long and five cubits wide--the altar shall be square--and its height shall be three cubits.", 
                "num": 1
              }, 
              {
                "text": "You shall make its horns on its four corners; its horns shall be of one piece with it. And you shall overlay it with bronze.", 
                "num": 2
              }, 
              {
                "text": "Also you shall make its pans to receive its ashes, and its shovels and its basins and its forks and its firepans; you shall make all its utensils of bronze.", 
                "num": 3
              }, 
              {
                "text": "You shall make a grate for it, a network of bronze; and on the network you shall make four bronze rings at its four corners.", 
                "num": 4
              }, 
              {
                "text": "You shall put it under the rim of the altar beneath, that the network may be midway up the altar.", 
                "num": 5
              }, 
              {
                "text": "And you shall make poles for the altar, poles of acacia wood, and overlay them with bronze.", 
                "num": 6
              }, 
              {
                "text": "The poles shall be put in the rings, and the poles shall be on the two sides of the altar to bear it.", 
                "num": 7
              }, 
              {
                "text": "You shall make it hollow with boards; as it was shown you on the mountain, so shall they make it.", 
                "num": 8
              }, 
              {
                "text": "\"You shall also make the court of the tabernacle. For the south side there shall be hangings for the court made of fine woven linen, one hundred cubits long for one side.", 
                "num": 9
              }, 
              {
                "text": "And its twenty pillars and their twenty sockets shall be bronze. The hooks of the pillars and their bands shall be silver.", 
                "num": 10
              }, 
              {
                "text": "Likewise along the length of the north side there shall be hangings one hundred cubits long, with its twenty pillars and their twenty sockets of bronze, and the hooks of the pillars and their bands of silver.", 
                "num": 11
              }, 
              {
                "text": "\"And along the width of the court on the west side shall be hangings of fifty cubits, with their ten pillars and their ten sockets.", 
                "num": 12
              }, 
              {
                "text": "The width of the court on the east side shall be fifty cubits.", 
                "num": 13
              }, 
              {
                "text": "The hangings on one side of the gate shall be fifteen cubits, with their three pillars and their three sockets.", 
                "num": 14
              }, 
              {
                "text": "And on the other side shall be hangings of fifteen cubits, with their three pillars and their three sockets.", 
                "num": 15
              }, 
              {
                "text": "\"For the gate of the court there shall be a screen twenty cubits long, woven of blue, purple, and scarlet thread, and fine woven linen, made by a weaver. It shall have four pillars and four sockets.", 
                "num": 16
              }, 
              {
                "text": "All the pillars around the court shall have bands of silver; their hooks shall be of silver and their sockets of bronze.", 
                "num": 17
              }, 
              {
                "text": "The length of the court shall be one hundred cubits, the width fifty throughout, and the height five cubits, made of fine woven linen, and its sockets of bronze.", 
                "num": 18
              }, 
              {
                "text": "All the utensils of the tabernacle for all its service, all its pegs, and all the pegs of the court, shall be of bronze.", 
                "num": 19
              }, 
              {
                "text": "\"And you shall command the children of Israel that they bring you pure oil of pressed olives for the light, to cause the lamp to burn continually.", 
                "num": 20
              }, 
              {
                "text": "In the tabernacle of meeting, outside the veil which is before the Testimony, Aaron and his sons shall tend it from evening until morning before the LORD. It shall be a statute forever to their generations on behalf of the children of Israel.", 
                "num": 21
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "\"Now take Aaron your brother, and his sons with him, from among the children of Israel, that he may minister to Me as priest, Aaron and Aaron's sons: Nadab, Abihu, Eleazar, and Ithamar.", 
                "num": 1
              }, 
              {
                "text": "And you shall make holy garments for Aaron your brother, for glory and for beauty.", 
                "num": 2
              }, 
              {
                "text": "So you shall speak to all who are gifted artisans, whom I have filled with the spirit of wisdom, that they may make Aaron's garments, to consecrate him, that he may minister to Me as priest.", 
                "num": 3
              }, 
              {
                "text": "And these are the garments which they shall make: a breastplate, an ephod, a robe, a skillfully woven tunic, a turban, and a sash. So they shall make holy garments for Aaron your brother and his sons, that he may minister to Me as priest.", 
                "num": 4
              }, 
              {
                "text": "\"They shall take the gold, blue, purple, and scarlet thread, and the fine linen,", 
                "num": 5
              }, 
              {
                "text": "and they shall make the ephod of gold, blue, purple, and scarlet thread, and fine woven linen, artistically worked.", 
                "num": 6
              }, 
              {
                "text": "It shall have two shoulder straps joined at its two edges, and so it shall be joined together.", 
                "num": 7
              }, 
              {
                "text": "And the intricately woven band of the ephod, which is on it, shall be of the same workmanship, made of gold, blue, purple, and scarlet thread, and fine woven linen.", 
                "num": 8
              }, 
              {
                "text": "\"Then you shall take two onyx stones and engrave on them the names of the sons of Israel:", 
                "num": 9
              }, 
              {
                "text": "six of their names on one stone and six names on the other stone, in order of their birth.", 
                "num": 10
              }, 
              {
                "text": "With the work of an engraver in stone, like the engravings of a signet, you shall engrave the two stones with the names of the sons of Israel. You shall set them in settings of gold.", 
                "num": 11
              }, 
              {
                "text": "And you shall put the two stones on the shoulders of the ephod as memorial stones for the sons of Israel. So Aaron shall bear their names before the LORD on his two shoulders as a memorial.", 
                "num": 12
              }, 
              {
                "text": "You shall also make settings of gold,", 
                "num": 13
              }, 
              {
                "text": "and you shall make two chains of pure gold like braided cords, and fasten the braided chains to the settings.", 
                "num": 14
              }, 
              {
                "text": "\"You shall make the breastplate of judgment. Artistically woven according to the workmanship of the ephod you shall make it: of gold, blue, purple, and scarlet thread, and fine woven linen, you shall make it.", 
                "num": 15
              }, 
              {
                "text": "It shall be doubled into a square: a span shall be its length, and a span shall be its width.", 
                "num": 16
              }, 
              {
                "text": "And you shall put settings of stones in it, four rows of stones: The first row shall be a sardius, a topaz, and an emerald; this shall be the first row;", 
                "num": 17
              }, 
              {
                "text": "the second row shall be a turquoise, a sapphire, and a diamond;", 
                "num": 18
              }, 
              {
                "text": "the third row, a jacinth, an agate, and an amethyst;", 
                "num": 19
              }, 
              {
                "text": "and the fourth row, a beryl, an onyx, and a jasper. They shall be set in gold settings.", 
                "num": 20
              }, 
              {
                "text": "And the stones shall have the names of the sons of Israel, twelve according to their names, like the engravings of a signet, each one with its own name; they shall be according to the twelve tribes.", 
                "num": 21
              }, 
              {
                "text": "\"You shall make chains for the breastplate at the end, like braided cords of pure gold.", 
                "num": 22
              }, 
              {
                "text": "And you shall make two rings of gold for the breastplate, and put the two rings on the two ends of the breastplate.", 
                "num": 23
              }, 
              {
                "text": "Then you shall put the two braided chains of gold in the two rings which are on the ends of the breastplate;", 
                "num": 24
              }, 
              {
                "text": "and the other two ends of the two braided chains you shall fasten to the two settings, and put them on the shoulder straps of the ephod in the front.", 
                "num": 25
              }, 
              {
                "text": "\"You shall make two rings of gold, and put them on the two ends of the breastplate, on the edge of it, which is on the inner side of the ephod.", 
                "num": 26
              }, 
              {
                "text": "And two other rings of gold you shall make, and put them on the two shoulder straps, underneath the ephod toward its front, right at the seam above the intricately woven band of the ephod.", 
                "num": 27
              }, 
              {
                "text": "They shall bind the breastplate by means of its rings to the rings of the ephod, using a blue cord, so that it is above the intricately woven band of the ephod, and so that the breastplate does not come loose from the ephod.", 
                "num": 28
              }, 
              {
                "text": "\"So Aaron shall bear the names of the sons of Israel on the breastplate of judgment over his heart, when he goes into the holy place, as a memorial before the LORD continually.", 
                "num": 29
              }, 
              {
                "text": "And you shall put in the breastplate of judgment the Urim and the Thummim, and they shall be over Aaron's heart when he goes in before the LORD. So Aaron shall bear the judgment of the children of Israel over his heart before the LORD continually.", 
                "num": 30
              }, 
              {
                "text": "\"You shall make the robe of the ephod all of blue.", 
                "num": 31
              }, 
              {
                "text": "There shall be an opening for his head in the middle of it; it shall have a woven binding all around its opening, like the opening in a coat of mail, so that it does not tear.", 
                "num": 32
              }, 
              {
                "text": "And upon its hem you shall make pomegranates of blue, purple, and scarlet, all around its hem, and bells of gold between them all around:", 
                "num": 33
              }, 
              {
                "text": "a golden bell and a pomegranate, a golden bell and a pomegranate, upon the hem of the robe all around.", 
                "num": 34
              }, 
              {
                "text": "And it shall be upon Aaron when he ministers, and its sound will be heard when he goes into the holy place before the LORD and when he comes out, that he may not die.", 
                "num": 35
              }, 
              {
                "text": "\"You shall also make a plate of pure gold and engrave on it, like the engraving of a signet: HOLINESS TO THE LORD.", 
                "num": 36
              }, 
              {
                "text": "And you shall put it on a blue cord, that it may be on the turban; it shall be on the front of the turban.", 
                "num": 37
              }, 
              {
                "text": "So it shall be on Aaron's forehead, that Aaron may bear the iniquity of the holy things which the children of Israel hallow in all their holy gifts; and it shall always be on his forehead, that they may be accepted before the LORD.", 
                "num": 38
              }, 
              {
                "text": "\"You shall skillfully weave the tunic of fine linen thread, you shall make the turban of fine linen, and you shall make the sash of woven work.", 
                "num": 39
              }, 
              {
                "text": "\"For Aaron's sons you shall make tunics, and you shall make sashes for them. And you shall make hats for them, for glory and beauty.", 
                "num": 40
              }, 
              {
                "text": "So you shall put them on Aaron your brother and on his sons with him. You shall anoint them, consecrate them, and sanctify them, that they may minister to Me as priests.", 
                "num": 41
              }, 
              {
                "text": "And you shall make for them linen trousers to cover their nakedness; they shall reach from the waist to the thighs.", 
                "num": 42
              }, 
              {
                "text": "They shall be on Aaron and on his sons when they come into the tabernacle of meeting, or when they come near the altar to minister in the holy place, that they do not incur iniquity and die. It shall be a statute forever to him and his descendants after him.", 
                "num": 43
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "\"And this is what you shall do to them to hallow them for ministering to Me as priests: Take one young bull and two rams without blemish,", 
                "num": 1
              }, 
              {
                "text": "and unleavened bread, unleavened cakes mixed with oil, and unleavened wafers anointed with oil (you shall make them of wheat flour).", 
                "num": 2
              }, 
              {
                "text": "You shall put them in one basket and bring them in the basket, with the bull and the two rams.", 
                "num": 3
              }, 
              {
                "text": "\"And Aaron and his sons you shall bring to the door of the tabernacle of meeting, and you shall wash them with water.", 
                "num": 4
              }, 
              {
                "text": "Then you shall take the garments, put the tunic on Aaron, and the robe of the ephod, the ephod, and the breastplate, and gird him with the intricately woven band of the ephod.", 
                "num": 5
              }, 
              {
                "text": "You shall put the turban on his head, and put the holy crown on the turban.", 
                "num": 6
              }, 
              {
                "text": "And you shall take the anointing oil, pour it on his head, and anoint him.", 
                "num": 7
              }, 
              {
                "text": "Then you shall bring his sons and put tunics on them.", 
                "num": 8
              }, 
              {
                "text": "And you shall gird them with sashes, Aaron and his sons, and put the hats on them. The priesthood shall be theirs for a perpetual statute. So you shall consecrate Aaron and his sons.", 
                "num": 9
              }, 
              {
                "text": "\"You shall also have the bull brought before the tabernacle of meeting, and Aaron and his sons shall put their hands on the head of the bull.", 
                "num": 10
              }, 
              {
                "text": "Then you shall kill the bull before the LORD, by the door of the tabernacle of meeting.", 
                "num": 11
              }, 
              {
                "text": "You shall take some of the blood of the bull and put it on the horns of the altar with your finger, and pour all the blood beside the base of the altar.", 
                "num": 12
              }, 
              {
                "text": "And you shall take all the fat that covers the entrails, the fatty lobe attached to the liver, and the two kidneys and the fat that is on them, and burn them on the altar.", 
                "num": 13
              }, 
              {
                "text": "But the flesh of the bull, with its skin and its offal, you shall burn with fire outside the camp. It is a sin offering.", 
                "num": 14
              }, 
              {
                "text": "\"You shall also take one ram, and Aaron and his sons shall put their hands on the head of the ram;", 
                "num": 15
              }, 
              {
                "text": "and you shall kill the ram, and you shall take its blood and sprinkle it all around on the altar.", 
                "num": 16
              }, 
              {
                "text": "Then you shall cut the ram in pieces, wash its entrails and its legs, and put them with its pieces and with its head.", 
                "num": 17
              }, 
              {
                "text": "And you shall burn the whole ram on the altar. It is a burnt offering to the LORD; it is a sweet aroma, an offering made by fire to the LORD.", 
                "num": 18
              }, 
              {
                "text": "\"You shall also take the other ram, and Aaron and his sons shall put their hands on the head of the ram.", 
                "num": 19
              }, 
              {
                "text": "Then you shall kill the ram, and take some of its blood and put it on the tip of the right ear of Aaron and on the tip of the right ear of his sons, on the thumb of their right hand and on the big toe of their right foot, and sprinkle the blood all around on the altar.", 
                "num": 20
              }, 
              {
                "text": "And you shall take some of the blood that is on the altar, and some of the anointing oil, and sprinkle it on Aaron and on his garments, on his sons and on the garments of his sons with him; and he and his garments shall be hallowed, and his sons and his sons' garments with him.", 
                "num": 21
              }, 
              {
                "text": "\"Also you shall take the fat of the ram, the fat tail, the fat that covers the entrails, the fatty lobe attached to the liver, the two kidneys and the fat on them, the right thigh (for it is a ram of consecration),", 
                "num": 22
              }, 
              {
                "text": "one loaf of bread, one cake made with oil, and one wafer from the basket of the unleavened bread that is before the LORD;", 
                "num": 23
              }, 
              {
                "text": "and you shall put all these in the hands of Aaron and in the hands of his sons, and you shall wave them as a wave offering before the LORD.", 
                "num": 24
              }, 
              {
                "text": "You shall receive them back from their hands and burn them on the altar as a burnt offering, as a sweet aroma before the LORD. It is an offering made by fire to the LORD.", 
                "num": 25
              }, 
              {
                "text": "\"Then you shall take the breast of the ram of Aaron's consecration and wave it as a wave offering before the LORD; and it shall be your portion.", 
                "num": 26
              }, 
              {
                "text": "And from the ram of the consecration you shall consecrate the breast of the wave offering which is waved, and the thigh of the heave offering which is raised, of that which is for Aaron and of that which is for his sons.", 
                "num": 27
              }, 
              {
                "text": "It shall be from the children of Israel for Aaron and his sons by a statute forever. For it is a heave offering; it shall be a heave offering from the children of Israel from the sacrifices of their peace offerings, that is, their heave offering to the LORD.", 
                "num": 28
              }, 
              {
                "text": "\"And the holy garments of Aaron shall be his sons' after him, to be anointed in them and to be consecrated in them.", 
                "num": 29
              }, 
              {
                "text": "That son who becomes priest in his place shall put them on for seven days, when he enters the tabernacle of meeting to minister in the holy place.", 
                "num": 30
              }, 
              {
                "text": "\"And you shall take the ram of the consecration and boil its flesh in the holy place.", 
                "num": 31
              }, 
              {
                "text": "Then Aaron and his sons shall eat the flesh of the ram, and the bread that is in the basket, by the door of the tabernacle of meeting.", 
                "num": 32
              }, 
              {
                "text": "They shall eat those things with which the atonement was made, to consecrate and to sanctify them; but an outsider shall not eat them, because they are holy.", 
                "num": 33
              }, 
              {
                "text": "And if any of the flesh of the consecration offerings, or of the bread, remains until the morning, then you shall burn the remainder with fire. It shall not be eaten, because it is holy.", 
                "num": 34
              }, 
              {
                "text": "\"Thus you shall do to Aaron and his sons, according to all that I have commanded you. Seven days you shall consecrate them.", 
                "num": 35
              }, 
              {
                "text": "And you shall offer a bull every day as a sin offering for atonement. You shall cleanse the altar when you make atonement for it, and you shall anoint it to sanctify it.", 
                "num": 36
              }, 
              {
                "text": "Seven days you shall make atonement for the altar and sanctify it. And the altar shall be most holy. Whatever touches the altar must be holy.", 
                "num": 37
              }, 
              {
                "text": "\"Now this is what you shall offer on the altar: two lambs of the first year, day by day continually.", 
                "num": 38
              }, 
              {
                "text": "One lamb you shall offer in the morning, and the other lamb you shall offer at twilight.", 
                "num": 39
              }, 
              {
                "text": "With the one lamb shall be one-tenth of an ephah of flour mixed with one-fourth of a hin of pressed oil, and one-fourth of a hin of wine as a drink offering.", 
                "num": 40
              }, 
              {
                "text": "And the other lamb you shall offer at twilight; and you shall offer with it the grain offering and the drink offering, as in the morning, for a sweet aroma, an offering made by fire to the LORD.", 
                "num": 41
              }, 
              {
                "text": "This shall be a continual burnt offering throughout your generations at the door of the tabernacle of meeting before the LORD, where I will meet you to speak with you.", 
                "num": 42
              }, 
              {
                "text": "And there I will meet with the children of Israel, and the tabernacle shall be sanctified by My glory.", 
                "num": 43
              }, 
              {
                "text": "So I will consecrate the tabernacle of meeting and the altar. I will also consecrate both Aaron and his sons to minister to Me as priests.", 
                "num": 44
              }, 
              {
                "text": "I will dwell among the children of Israel and will be their God.", 
                "num": 45
              }, 
              {
                "text": "And they shall know that I am the LORD their God, who brought them up out of the land of Egypt, that I may dwell among them. I am the LORD their God.", 
                "num": 46
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "\"You shall make an altar to burn incense on; you shall make it of acacia wood.", 
                "num": 1
              }, 
              {
                "text": "A cubit shall be its length and a cubit its width--it shall be square--and two cubits shall be its height. Its horns shall be of one piece with it.", 
                "num": 2
              }, 
              {
                "text": "And you shall overlay its top, its sides all around, and its horns with pure gold; and you shall make for it a molding of gold all around.", 
                "num": 3
              }, 
              {
                "text": "Two gold rings you shall make for it, under the molding on both its sides. You shall place them on its two sides, and they will be holders for the poles with which to bear it.", 
                "num": 4
              }, 
              {
                "text": "You shall make the poles of acacia wood, and overlay them with gold.", 
                "num": 5
              }, 
              {
                "text": "And you shall put it before the veil that is before the ark of the Testimony, before the mercy seat that is over the Testimony, where I will meet with you.", 
                "num": 6
              }, 
              {
                "text": "\"Aaron shall burn on it sweet incense every morning; when he tends the lamps, he shall burn incense on it.", 
                "num": 7
              }, 
              {
                "text": "And when Aaron lights the lamps at twilight, he shall burn incense on it, a perpetual incense before the LORD throughout your generations.", 
                "num": 8
              }, 
              {
                "text": "You shall not offer strange incense on it, or a burnt offering, or a grain offering; nor shall you pour a drink offering on it.", 
                "num": 9
              }, 
              {
                "text": "And Aaron shall make atonement upon its horns once a year with the blood of the sin offering of atonement; once a year he shall make atonement upon it throughout your generations. It is most holy to the LORD.\"", 
                "num": 10
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 11
              }, 
              {
                "text": "\"When you take the census of the children of Israel for their number, then every man shall give a ransom for himself to the LORD, when you number them, that there may be no plague among them when you number them.", 
                "num": 12
              }, 
              {
                "text": "This is what everyone among those who are numbered shall give: half a shekel according to the shekel of the sanctuary (a shekel is twenty gerahs). The half-shekel shall be an offering to the LORD.", 
                "num": 13
              }, 
              {
                "text": "Everyone included among those who are numbered, from twenty years old and above, shall give an offering to the LORD.", 
                "num": 14
              }, 
              {
                "text": "The rich shall not give more and the poor shall not give less than half a shekel, when you give an offering to the LORD, to make atonement for yourselves.", 
                "num": 15
              }, 
              {
                "text": "And you shall take the atonement money of the children of Israel, and shall appoint it for the service of the tabernacle of meeting, that it may be a memorial for the children of Israel before the LORD, to make atonement for yourselves.\"", 
                "num": 16
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 17
              }, 
              {
                "text": "\"You shall also make a laver of bronze, with its base also of bronze, for washing. You shall put it between the tabernacle of meeting and the altar. And you shall put water in it,", 
                "num": 18
              }, 
              {
                "text": "for Aaron and his sons shall wash their hands and their feet in water from it.", 
                "num": 19
              }, 
              {
                "text": "When they go into the tabernacle of meeting, or when they come near the altar to minister, to burn an offering made by fire to the LORD, they shall wash with water, lest they die.", 
                "num": 20
              }, 
              {
                "text": "So they shall wash their hands and their feet, lest they die. And it shall be a statute forever to them--to him and his descendants throughout their generations.\"", 
                "num": 21
              }, 
              {
                "text": "Moreover the LORD spoke to Moses, saying:", 
                "num": 22
              }, 
              {
                "text": "\"Also take for yourself quality spices--five hundred shekels of liquid myrrh, half as much sweet-smelling cinnamon (two hundred and fifty shekels), two hundred and fifty shekels of sweet-smelling cane,", 
                "num": 23
              }, 
              {
                "text": "five hundred shekels of cassia, according to the shekel of the sanctuary, and a hin of olive oil.", 
                "num": 24
              }, 
              {
                "text": "And you shall make from these a holy anointing oil, an ointment compounded according to the art of the perfumer. It shall be a holy anointing oil.", 
                "num": 25
              }, 
              {
                "text": "With it you shall anoint the tabernacle of meeting and the ark of the Testimony;", 
                "num": 26
              }, 
              {
                "text": "the table and all its utensils, the lampstand and its utensils, and the altar of incense;", 
                "num": 27
              }, 
              {
                "text": "the altar of burnt offering with all its utensils, and the laver and its base.", 
                "num": 28
              }, 
              {
                "text": "You shall consecrate them, that they may be most holy; whatever touches them must be holy.", 
                "num": 29
              }, 
              {
                "text": "And you shall anoint Aaron and his sons, and consecrate them, that they may minister to Me as priests.", 
                "num": 30
              }, 
              {
                "text": "\"And you shall speak to the children of Israel, saying: \"This shall be a holy anointing oil to Me throughout your generations.", 
                "num": 31
              }, 
              {
                "text": "It shall not be poured on man's flesh; nor shall you make any other like it, according to its composition. It is holy, and it shall be holy to you.", 
                "num": 32
              }, 
              {
                "text": "Whoever compounds any like it, or whoever puts any of it on an outsider, shall be cut off from his people.\"'", 
                "num": 33
              }, 
              {
                "text": "And the LORD said to Moses: \"Take sweet spices, stacte and onycha and galbanum, and pure frankincense with these sweet spices; there shall be equal amounts of each.", 
                "num": 34
              }, 
              {
                "text": "You shall make of these an incense, a compound according to the art of the perfumer, salted, pure, and holy.", 
                "num": 35
              }, 
              {
                "text": "And you shall beat some of it very fine, and put some of it before the Testimony in the tabernacle of meeting where I will meet with you. It shall be most holy to you.", 
                "num": 36
              }, 
              {
                "text": "But as for the incense which you shall make, you shall not make any for yourselves, according to its composition. It shall be to you holy for the LORD.", 
                "num": 37
              }, 
              {
                "text": "Whoever makes any like it, to smell it, he shall be cut off from his people.\"", 
                "num": 38
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"See, I have called by name Bezalel the son of Uri, the son of Hur, of the tribe of Judah.", 
                "num": 2
              }, 
              {
                "text": "And I have filled him with the Spirit of God, in wisdom, in understanding, in knowledge, and in all manner of workmanship,", 
                "num": 3
              }, 
              {
                "text": "to design artistic works, to work in gold, in silver, in bronze,", 
                "num": 4
              }, 
              {
                "text": "in cutting jewels for setting, in carving wood, and to work in all manner of workmanship.", 
                "num": 5
              }, 
              {
                "text": "\"And I, indeed I, have appointed with him Aholiab the son of Ahisamach, of the tribe of Dan; and I have put wisdom in the hearts of all the gifted artisans, that they may make all that I have commanded you:", 
                "num": 6
              }, 
              {
                "text": "the tabernacle of meeting, the ark of the Testimony and the mercy seat that is on it, and all the furniture of the tabernacle--", 
                "num": 7
              }, 
              {
                "text": "the table and its utensils, the pure gold lampstand with all its utensils, the altar of incense,", 
                "num": 8
              }, 
              {
                "text": "the altar of burnt offering with all its utensils, and the laver and its base--", 
                "num": 9
              }, 
              {
                "text": "the garments of ministry, the holy garments for Aaron the priest and the garments of his sons, to minister as priests,", 
                "num": 10
              }, 
              {
                "text": "and the anointing oil and sweet incense for the holy place. According to all that I have commanded you they shall do.\"", 
                "num": 11
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Speak also to the children of Israel, saying: \"Surely My Sabbaths you shall keep, for it is a sign between Me and you throughout your generations, that you may know that I am the LORD who sanctifies you.", 
                "num": 13
              }, 
              {
                "text": "You shall keep the Sabbath, therefore, for it is holy to you. Everyone who profanes it shall surely be put to death; for whoever does any work on it, that person shall be cut off from among his people.", 
                "num": 14
              }, 
              {
                "text": "Work shall be done for six days, but the seventh is the Sabbath of rest, holy to the LORD. Whoever does any work on the Sabbath day, he shall surely be put to death.", 
                "num": 15
              }, 
              {
                "text": "Therefore the children of Israel shall keep the Sabbath, to observe the Sabbath throughout their generations as a perpetual covenant.", 
                "num": 16
              }, 
              {
                "text": "It is a sign between Me and the children of Israel forever; for in six days the LORD made the heavens and the earth, and on the seventh day He rested and was refreshed.\"'", 
                "num": 17
              }, 
              {
                "text": "And when He had made an end of speaking with him on Mount Sinai, He gave Moses two tablets of the Testimony, tablets of stone, written with the finger of God.", 
                "num": 18
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "Now when the people saw that Moses delayed coming down from the mountain, the people gathered together to Aaron, and said to him, \"Come, make us gods that shall go before us; for as for this Moses, the man who brought us up out of the land of Egypt, we do not know what has become of him.\"", 
                "num": 1
              }, 
              {
                "text": "And Aaron said to them, \"Break off the golden earrings which are in the ears of your wives, your sons, and your daughters, and bring them to me.\"", 
                "num": 2
              }, 
              {
                "text": "So all the people broke off the golden earrings which were in their ears, and brought them to Aaron.", 
                "num": 3
              }, 
              {
                "text": "And he received the gold from their hand, and he fashioned it with an engraving tool, and made a molded calf. Then they said, \"This is your god, O Israel, that brought you out of the land of Egypt!\"", 
                "num": 4
              }, 
              {
                "text": "So when Aaron saw it, he built an altar before it. And Aaron made a proclamation and said, \"Tomorrow is a feast to the LORD.\"", 
                "num": 5
              }, 
              {
                "text": "Then they rose early on the next day, offered burnt offerings, and brought peace offerings; and the people sat down to eat and drink, and rose up to play.", 
                "num": 6
              }, 
              {
                "text": "And the LORD said to Moses, \"Go, get down! For your people whom you brought out of the land of Egypt have corrupted themselves.", 
                "num": 7
              }, 
              {
                "text": "They have turned aside quickly out of the way which I commanded them. They have made themselves a molded calf, and worshiped it and sacrificed to it, and said, \"This is your god, O Israel, that brought you out of the land of Egypt!\"'", 
                "num": 8
              }, 
              {
                "text": "And the LORD said to Moses, \"I have seen this people, and indeed it is a stiff-necked people!", 
                "num": 9
              }, 
              {
                "text": "Now therefore, let Me alone, that My wrath may burn hot against them and I may consume them. And I will make of you a great nation.\"", 
                "num": 10
              }, 
              {
                "text": "Then Moses pleaded with the LORD his God, and said: \"LORD, why does Your wrath burn hot against Your people whom You have brought out of the land of Egypt with great power and with a mighty hand?", 
                "num": 11
              }, 
              {
                "text": "Why should the Egyptians speak, and say, \"He brought them out to harm them, to kill them in the mountains, and to consume them from the face of the earth'? Turn from Your fierce wrath, and relent from this harm to Your people.", 
                "num": 12
              }, 
              {
                "text": "Remember Abraham, Isaac, and Israel, Your servants, to whom You swore by Your own self, and said to them, \"I will multiply your descendants as the stars of heaven; and all this land that I have spoken of I give to your descendants, and they shall inherit it forever.\"'", 
                "num": 13
              }, 
              {
                "text": "So the LORD relented from the harm which He said He would do to His people.", 
                "num": 14
              }, 
              {
                "text": "And Moses turned and went down from the mountain, and the two tablets of the Testimony were in his hand. The tablets were written on both sides; on the one side and on the other they were written.", 
                "num": 15
              }, 
              {
                "text": "Now the tablets were the work of God, and the writing was the writing of God engraved on the tablets.", 
                "num": 16
              }, 
              {
                "text": "And when Joshua heard the noise of the people as they shouted, he said to Moses, \"There is a noise of war in the camp.\"", 
                "num": 17
              }, 
              {
                "text": "But he said: \"It is not the noise of the shout of victory, Nor the noise of the cry of defeat, But the sound of singing I hear.\"", 
                "num": 18
              }, 
              {
                "text": "So it was, as soon as he came near the camp, that he saw the calf and the dancing. So Moses' anger became hot, and he cast the tablets out of his hands and broke them at the foot of the mountain.", 
                "num": 19
              }, 
              {
                "text": "Then he took the calf which they had made, burned it in the fire, and ground it to powder; and he scattered it on the water and made the children of Israel drink it.", 
                "num": 20
              }, 
              {
                "text": "And Moses said to Aaron, \"What did this people do to you that you have brought so great a sin upon them?\"", 
                "num": 21
              }, 
              {
                "text": "So Aaron said, \"Do not let the anger of my lord become hot. You know the people, that they are set on evil.", 
                "num": 22
              }, 
              {
                "text": "For they said to me, \"Make us gods that shall go before us; as for this Moses, the man who brought us out of the land of Egypt, we do not know what has become of him.'", 
                "num": 23
              }, 
              {
                "text": "And I said to them, \"Whoever has any gold, let them break it off.' So they gave it to me, and I cast it into the fire, and this calf came out.\"", 
                "num": 24
              }, 
              {
                "text": "Now when Moses saw that the people were unrestrained (for Aaron had not restrained them, to their shame among their enemies),", 
                "num": 25
              }, 
              {
                "text": "then Moses stood in the entrance of the camp, and said, \"Whoever is on the LORD's side--come to me!\" And all the sons of Levi gathered themselves together to him.", 
                "num": 26
              }, 
              {
                "text": "And he said to them, \"Thus says the LORD God of Israel: \"Let every man put his sword on his side, and go in and out from entrance to entrance throughout the camp, and let every man kill his brother, every man his companion, and every man his neighbor.\"'", 
                "num": 27
              }, 
              {
                "text": "So the sons of Levi did according to the word of Moses. And about three thousand men of the people fell that day.", 
                "num": 28
              }, 
              {
                "text": "Then Moses said, \"Consecrate yourselves today to the LORD, that He may bestow on you a blessing this day, for every man has opposed his son and his brother.\"", 
                "num": 29
              }, 
              {
                "text": "Now it came to pass on the next day that Moses said to the people, \"You have committed a great sin. So now I will go up to the LORD; perhaps I can make atonement for your sin.\"", 
                "num": 30
              }, 
              {
                "text": "Then Moses returned to the LORD and said, \"Oh, these people have committed a great sin, and have made for themselves a god of gold!", 
                "num": 31
              }, 
              {
                "text": "Yet now, if You will forgive their sin--but if not, I pray, blot me out of Your book which You have written.\"", 
                "num": 32
              }, 
              {
                "text": "And the LORD said to Moses, \"Whoever has sinned against Me, I will blot him out of My book.", 
                "num": 33
              }, 
              {
                "text": "Now therefore, go, lead the people to the place of which I have spoken to you. Behold, My Angel shall go before you. Nevertheless, in the day when I visit for punishment, I will visit punishment upon them for their sin.\"", 
                "num": 34
              }, 
              {
                "text": "So the LORD plagued the people because of what they did with the calf which Aaron made.", 
                "num": 35
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to Moses, \"Depart and go up from here, you and the people whom you have brought out of the land of Egypt, to the land of which I swore to Abraham, Isaac, and Jacob, saying, \"To your descendants I will give it.'", 
                "num": 1
              }, 
              {
                "text": "And I will send My Angel before you, and I will drive out the Canaanite and the Amorite and the Hittite and the Perizzite and the Hivite and the Jebusite.", 
                "num": 2
              }, 
              {
                "text": "Go up to a land flowing with milk and honey; for I will not go up in your midst, lest I consume you on the way, for you are a stiff-necked people.\"", 
                "num": 3
              }, 
              {
                "text": "And when the people heard this bad news, they mourned, and no one put on his ornaments.", 
                "num": 4
              }, 
              {
                "text": "For the LORD had said to Moses, \"Say to the children of Israel, \"You are a stiff-necked people. I could come up into your midst in one moment and consume you. Now therefore, take off your ornaments, that I may know what to do to you.\"'", 
                "num": 5
              }, 
              {
                "text": "So the children of Israel stripped themselves of their ornaments by Mount Horeb.", 
                "num": 6
              }, 
              {
                "text": "Moses took his tent and pitched it outside the camp, far from the camp, and called it the tabernacle of meeting. And it came to pass that everyone who sought the LORD went out to the tabernacle of meeting which was outside the camp.", 
                "num": 7
              }, 
              {
                "text": "So it was, whenever Moses went out to the tabernacle, that all the people rose, and each man stood at his tent door and watched Moses until he had gone into the tabernacle.", 
                "num": 8
              }, 
              {
                "text": "And it came to pass, when Moses entered the tabernacle, that the pillar of cloud descended and stood at the door of the tabernacle, and the LORD talked with Moses.", 
                "num": 9
              }, 
              {
                "text": "All the people saw the pillar of cloud standing at the tabernacle door, and all the people rose and worshiped, each man in his tent door.", 
                "num": 10
              }, 
              {
                "text": "So the LORD spoke to Moses face to face, as a man speaks to his friend. And he would return to the camp, but his servant Joshua the son of Nun, a young man, did not depart from the tabernacle.", 
                "num": 11
              }, 
              {
                "text": "Then Moses said to the LORD, \"See, You say to me, \"Bring up this people.' But You have not let me know whom You will send with me. Yet You have said, \"I know you by name, and you have also found grace in My sight.'", 
                "num": 12
              }, 
              {
                "text": "Now therefore, I pray, if I have found grace in Your sight, show me now Your way, that I may know You and that I may find grace in Your sight. And consider that this nation is Your people.\"", 
                "num": 13
              }, 
              {
                "text": "And He said, \"My Presence will go with you, and I will give you rest.\"", 
                "num": 14
              }, 
              {
                "text": "Then he said to Him, \"If Your Presence does not go with us, do not bring us up from here.", 
                "num": 15
              }, 
              {
                "text": "For how then will it be known that Your people and I have found grace in Your sight, except You go with us? So we shall be separate, Your people and I, from all the people who are upon the face of the earth.\"", 
                "num": 16
              }, 
              {
                "text": "So the LORD said to Moses, \"I will also do this thing that you have spoken; for you have found grace in My sight, and I know you by name.\"", 
                "num": 17
              }, 
              {
                "text": "And he said, \"Please, show me Your glory.\"", 
                "num": 18
              }, 
              {
                "text": "Then He said, \"I will make all My goodness pass before you, and I will proclaim the name of the LORD before you. I will be gracious to whom I will be gracious, and I will have compassion on whom I will have compassion.\"", 
                "num": 19
              }, 
              {
                "text": "But He said, \"You cannot see My face; for no man shall see Me, and live.\"", 
                "num": 20
              }, 
              {
                "text": "And the LORD said, \"Here is a place by Me, and you shall stand on the rock.", 
                "num": 21
              }, 
              {
                "text": "So it shall be, while My glory passes by, that I will put you in the cleft of the rock, and will cover you with My hand while I pass by.", 
                "num": 22
              }, 
              {
                "text": "Then I will take away My hand, and you shall see My back; but My face shall not be seen.\"", 
                "num": 23
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "And the LORD said to Moses, \"Cut two tablets of stone like the first ones, and I will write on these tablets the words that were on the first tablets which you broke.", 
                "num": 1
              }, 
              {
                "text": "So be ready in the morning, and come up in the morning to Mount Sinai, and present yourself to Me there on the top of the mountain.", 
                "num": 2
              }, 
              {
                "text": "And no man shall come up with you, and let no man be seen throughout all the mountain; let neither flocks nor herds feed before that mountain.\"", 
                "num": 3
              }, 
              {
                "text": "So he cut two tablets of stone like the first ones. Then Moses rose early in the morning and went up Mount Sinai, as the LORD had commanded him; and he took in his hand the two tablets of stone.", 
                "num": 4
              }, 
              {
                "text": "Now the LORD descended in the cloud and stood with him there, and proclaimed the name of the LORD.", 
                "num": 5
              }, 
              {
                "text": "And the LORD passed before him and proclaimed, \"The LORD, the LORD God, merciful and gracious, longsuffering, and abounding in goodness and truth,", 
                "num": 6
              }, 
              {
                "text": "keeping mercy for thousands, forgiving iniquity and transgression and sin, by no means clearing the guilty, visiting the iniquity of the fathers upon the children and the children's children to the third and the fourth generation.\"", 
                "num": 7
              }, 
              {
                "text": "So Moses made haste and bowed his head toward the earth, and worshiped.", 
                "num": 8
              }, 
              {
                "text": "Then he said, \"If now I have found grace in Your sight, O Lord, let my Lord, I pray, go among us, even though we are a stiff-necked people; and pardon our iniquity and our sin, and take us as Your inheritance.\"", 
                "num": 9
              }, 
              {
                "text": "And He said: \"Behold, I make a covenant. Before all your people I will do marvels such as have not been done in all the earth, nor in any nation; and all the people among whom you are shall see the work of the LORD. For it is an awesome thing that I will do with you.", 
                "num": 10
              }, 
              {
                "text": "Observe what I command you this day. Behold, I am driving out from before you the Amorite and the Canaanite and the Hittite and the Perizzite and the Hivite and the Jebusite.", 
                "num": 11
              }, 
              {
                "text": "Take heed to yourself, lest you make a covenant with the inhabitants of the land where you are going, lest it be a snare in your midst.", 
                "num": 12
              }, 
              {
                "text": "But you shall destroy their altars, break their sacred pillars, and cut down their wooden images", 
                "num": 13
              }, 
              {
                "text": "(for you shall worship no other god, for the LORD, whose name is Jealous, is a jealous God),", 
                "num": 14
              }, 
              {
                "text": "lest you make a covenant with the inhabitants of the land, and they play the harlot with their gods and make sacrifice to their gods, and one of them invites you and you eat of his sacrifice,", 
                "num": 15
              }, 
              {
                "text": "and you take of his daughters for your sons, and his daughters play the harlot with their gods and make your sons play the harlot with their gods.", 
                "num": 16
              }, 
              {
                "text": "\"You shall make no molded gods for yourselves.", 
                "num": 17
              }, 
              {
                "text": "\"The Feast of Unleavened Bread you shall keep. Seven days you shall eat unleavened bread, as I commanded you, in the appointed time of the month of Abib; for in the month of Abib you came out from Egypt.", 
                "num": 18
              }, 
              {
                "text": "\"All that open the womb are Mine, and every male firstborn among your livestock, whether ox or sheep.", 
                "num": 19
              }, 
              {
                "text": "But the firstborn of a donkey you shall redeem with a lamb. And if you will not redeem him, then you shall break his neck. All the firstborn of your sons you shall redeem. \"And none shall appear before Me empty-handed.", 
                "num": 20
              }, 
              {
                "text": "\"Six days you shall work, but on the seventh day you shall rest; in plowing time and in harvest you shall rest.", 
                "num": 21
              }, 
              {
                "text": "\"And you shall observe the Feast of Weeks, of the firstfruits of wheat harvest, and the Feast of Ingathering at the year's end.", 
                "num": 22
              }, 
              {
                "text": "\"Three times in the year all your men shall appear before the Lord, the LORD God of Israel.", 
                "num": 23
              }, 
              {
                "text": "For I will cast out the nations before you and enlarge your borders; neither will any man covet your land when you go up to appear before the LORD your God three times in the year.", 
                "num": 24
              }, 
              {
                "text": "\"You shall not offer the blood of My sacrifice with leaven, nor shall the sacrifice of the Feast of the Passover be left until morning.", 
                "num": 25
              }, 
              {
                "text": "\"The first of the firstfruits of your land you shall bring to the house of the LORD your God. You shall not boil a young goat in its mother's milk.\"", 
                "num": 26
              }, 
              {
                "text": "Then the LORD said to Moses, \"Write these words, for according to the tenor of these words I have made a covenant with you and with Israel.\"", 
                "num": 27
              }, 
              {
                "text": "So he was there with the LORD forty days and forty nights; he neither ate bread nor drank water. And He wrote on the tablets the words of the covenant, the Ten Commandments.", 
                "num": 28
              }, 
              {
                "text": "Now it was so, when Moses came down from Mount Sinai (and the two tablets of the Testimony were in Moses' hand when he came down from the mountain), that Moses did not know that the skin of his face shone while he talked with Him.", 
                "num": 29
              }, 
              {
                "text": "So when Aaron and all the children of Israel saw Moses, behold, the skin of his face shone, and they were afraid to come near him.", 
                "num": 30
              }, 
              {
                "text": "Then Moses called to them, and Aaron and all the rulers of the congregation returned to him; and Moses talked with them.", 
                "num": 31
              }, 
              {
                "text": "Afterward all the children of Israel came near, and he gave them as commandments all that the LORD had spoken with him on Mount Sinai.", 
                "num": 32
              }, 
              {
                "text": "And when Moses had finished speaking with them, he put a veil on his face.", 
                "num": 33
              }, 
              {
                "text": "But whenever Moses went in before the LORD to speak with Him, he would take the veil off until he came out; and he would come out and speak to the children of Israel whatever he had been commanded.", 
                "num": 34
              }, 
              {
                "text": "And whenever the children of Israel saw the face of Moses, that the skin of Moses' face shone, then Moses would put the veil on his face again, until he went in to speak with Him.", 
                "num": 35
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Then Moses gathered all the congregation of the children of Israel together, and said to them, \"These are the words which the LORD has commanded you to do:", 
                "num": 1
              }, 
              {
                "text": "Work shall be done for six days, but the seventh day shall be a holy day for you, a Sabbath of rest to the LORD. Whoever does any work on it shall be put to death.", 
                "num": 2
              }, 
              {
                "text": "You shall kindle no fire throughout your dwellings on the Sabbath day.\"", 
                "num": 3
              }, 
              {
                "text": "And Moses spoke to all the congregation of the children of Israel, saying, \"This is the thing which the LORD commanded, saying:", 
                "num": 4
              }, 
              {
                "text": "\"Take from among you an offering to the LORD. Whoever is of a willing heart, let him bring it as an offering to the LORD: gold, silver, and bronze;", 
                "num": 5
              }, 
              {
                "text": "blue, purple, and scarlet thread, fine linen, and goats' hair;", 
                "num": 6
              }, 
              {
                "text": "ram skins dyed red, badger skins, and acacia wood;", 
                "num": 7
              }, 
              {
                "text": "oil for the light, and spices for the anointing oil and for the sweet incense;", 
                "num": 8
              }, 
              {
                "text": "onyx stones, and stones to be set in the ephod and in the breastplate.", 
                "num": 9
              }, 
              {
                "text": "\"All who are gifted artisans among you shall come and make all that the LORD has commanded:", 
                "num": 10
              }, 
              {
                "text": "the tabernacle, its tent, its covering, its clasps, its boards, its bars, its pillars, and its sockets;", 
                "num": 11
              }, 
              {
                "text": "the ark and its poles, with the mercy seat, and the veil of the covering;", 
                "num": 12
              }, 
              {
                "text": "the table and its poles, all its utensils, and the showbread;", 
                "num": 13
              }, 
              {
                "text": "also the lampstand for the light, its utensils, its lamps, and the oil for the light;", 
                "num": 14
              }, 
              {
                "text": "the incense altar, its poles, the anointing oil, the sweet incense, and the screen for the door at the entrance of the tabernacle;", 
                "num": 15
              }, 
              {
                "text": "the altar of burnt offering with its bronze grating, its poles, all its utensils, and the laver and its base;", 
                "num": 16
              }, 
              {
                "text": "the hangings of the court, its pillars, their sockets, and the screen for the gate of the court;", 
                "num": 17
              }, 
              {
                "text": "the pegs of the tabernacle, the pegs of the court, and their cords;", 
                "num": 18
              }, 
              {
                "text": "the garments of ministry, for ministering in the holy place--the holy garments for Aaron the priest and the garments of his sons, to minister as priests.\"'", 
                "num": 19
              }, 
              {
                "text": "And all the congregation of the children of Israel departed from the presence of Moses.", 
                "num": 20
              }, 
              {
                "text": "Then everyone came whose heart was stirred, and everyone whose spirit was willing, and they brought the LORD's offering for the work of the tabernacle of meeting, for all its service, and for the holy garments.", 
                "num": 21
              }, 
              {
                "text": "They came, both men and women, as many as had a willing heart, and brought earrings and nose rings, rings and necklaces, all jewelry of gold, that is, every man who made an offering of gold to the LORD.", 
                "num": 22
              }, 
              {
                "text": "And every man, with whom was found blue, purple, and scarlet thread, fine linen, goats' hair, red skins of rams, and badger skins, brought them.", 
                "num": 23
              }, 
              {
                "text": "Everyone who offered an offering of silver or bronze brought the LORD's offering. And everyone with whom was found acacia wood for any work of the service, brought it.", 
                "num": 24
              }, 
              {
                "text": "All the women who were gifted artisans spun yarn with their hands, and brought what they had spun, of blue, purple, and scarlet, and fine linen.", 
                "num": 25
              }, 
              {
                "text": "And all the women whose hearts stirred with wisdom spun yarn of goats' hair.", 
                "num": 26
              }, 
              {
                "text": "The rulers brought onyx stones, and the stones to be set in the ephod and in the breastplate,", 
                "num": 27
              }, 
              {
                "text": "and spices and oil for the light, for the anointing oil, and for the sweet incense.", 
                "num": 28
              }, 
              {
                "text": "The children of Israel brought a freewill offering to the LORD, all the men and women whose hearts were willing to bring material for all kinds of work which the LORD, by the hand of Moses, had commanded to be done.", 
                "num": 29
              }, 
              {
                "text": "And Moses said to the children of Israel, \"See, the LORD has called by name Bezalel the son of Uri, the son of Hur, of the tribe of Judah;", 
                "num": 30
              }, 
              {
                "text": "and He has filled him with the Spirit of God, in wisdom and understanding, in knowledge and all manner of workmanship,", 
                "num": 31
              }, 
              {
                "text": "to design artistic works, to work in gold and silver and bronze,", 
                "num": 32
              }, 
              {
                "text": "in cutting jewels for setting, in carving wood, and to work in all manner of artistic workmanship.", 
                "num": 33
              }, 
              {
                "text": "\"And He has put in his heart the ability to teach, in him and Aholiab the son of Ahisamach, of the tribe of Dan.", 
                "num": 34
              }, 
              {
                "text": "He has filled them with skill to do all manner of work of the engraver and the designer and the tapestry maker, in blue, purple, and scarlet thread, and fine linen, and of the weaver--those who do every work and those who design artistic works.", 
                "num": 35
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "\"And Bezalel and Aholiab, and every gifted artisan in whom the LORD has put wisdom and understanding, to know how to do all manner of work for the service of the sanctuary, shall do according to all that the LORD has commanded.\"", 
                "num": 1
              }, 
              {
                "text": "Then Moses called Bezalel and Aholiab, and every gifted artisan in whose heart the LORD had put wisdom, everyone whose heart was stirred, to come and do the work.", 
                "num": 2
              }, 
              {
                "text": "And they received from Moses all the offering which the children of Israel had brought for the work of the service of making the sanctuary. So they continued bringing to him freewill offerings every morning.", 
                "num": 3
              }, 
              {
                "text": "Then all the craftsmen who were doing all the work of the sanctuary came, each from the work he was doing,", 
                "num": 4
              }, 
              {
                "text": "and they spoke to Moses, saying, \"The people bring much more than enough for the service of the work which the LORD commanded us to do.\"", 
                "num": 5
              }, 
              {
                "text": "So Moses gave a commandment, and they caused it to be proclaimed throughout the camp, saying, \"Let neither man nor woman do any more work for the offering of the sanctuary.\" And the people were restrained from bringing,", 
                "num": 6
              }, 
              {
                "text": "for the material they had was sufficient for all the work to be done--indeed too much.", 
                "num": 7
              }, 
              {
                "text": "Then all the gifted artisans among them who worked on the tabernacle made ten curtains woven of fine linen, and of blue, purple, and scarlet thread; with artistic designs of cherubim they made them.", 
                "num": 8
              }, 
              {
                "text": "The length of each curtain was twenty-eight cubits, and the width of each curtain four cubits; the curtains were all the same size.", 
                "num": 9
              }, 
              {
                "text": "And he coupled five curtains to one another, and the other five curtains he coupled to one another.", 
                "num": 10
              }, 
              {
                "text": "He made loops of blue yarn on the edge of the curtain on the selvedge of one set; likewise he did on the outer edge of the other curtain of the second set.", 
                "num": 11
              }, 
              {
                "text": "Fifty loops he made on one curtain, and fifty loops he made on the edge of the curtain on the end of the second set; the loops held one curtain to another.", 
                "num": 12
              }, 
              {
                "text": "And he made fifty clasps of gold, and coupled the curtains to one another with the clasps, that it might be one tabernacle.", 
                "num": 13
              }, 
              {
                "text": "He made curtains of goats' hair for the tent over the tabernacle; he made eleven curtains.", 
                "num": 14
              }, 
              {
                "text": "The length of each curtain was thirty cubits, and the width of each curtain four cubits; the eleven curtains were the same size.", 
                "num": 15
              }, 
              {
                "text": "He coupled five curtains by themselves and six curtains by themselves.", 
                "num": 16
              }, 
              {
                "text": "And he made fifty loops on the edge of the curtain that is outermost in one set, and fifty loops he made on the edge of the curtain of the second set.", 
                "num": 17
              }, 
              {
                "text": "He also made fifty bronze clasps to couple the tent together, that it might be one.", 
                "num": 18
              }, 
              {
                "text": "Then he made a covering for the tent of ram skins dyed red, and a covering of badger skins above that.", 
                "num": 19
              }, 
              {
                "text": "For the tabernacle he made boards of acacia wood, standing upright.", 
                "num": 20
              }, 
              {
                "text": "The length of each board was ten cubits, and the width of each board a cubit and a half.", 
                "num": 21
              }, 
              {
                "text": "Each board had two tenons for binding one to another. Thus he made for all the boards of the tabernacle.", 
                "num": 22
              }, 
              {
                "text": "And he made boards for the tabernacle, twenty boards for the south side.", 
                "num": 23
              }, 
              {
                "text": "Forty sockets of silver he made to go under the twenty boards: two sockets under each of the boards for its two tenons.", 
                "num": 24
              }, 
              {
                "text": "And for the other side of the tabernacle, the north side, he made twenty boards", 
                "num": 25
              }, 
              {
                "text": "and their forty sockets of silver: two sockets under each of the boards.", 
                "num": 26
              }, 
              {
                "text": "For the west side of the tabernacle he made six boards.", 
                "num": 27
              }, 
              {
                "text": "He also made two boards for the two back corners of the tabernacle.", 
                "num": 28
              }, 
              {
                "text": "And they were coupled at the bottom and coupled together at the top by one ring. Thus he made both of them for the two corners.", 
                "num": 29
              }, 
              {
                "text": "So there were eight boards and their sockets--sixteen sockets of silver--two sockets under each of the boards.", 
                "num": 30
              }, 
              {
                "text": "And he made bars of acacia wood: five for the boards on one side of the tabernacle,", 
                "num": 31
              }, 
              {
                "text": "five bars for the boards on the other side of the tabernacle, and five bars for the boards of the tabernacle on the far side westward.", 
                "num": 32
              }, 
              {
                "text": "And he made the middle bar to pass through the boards from one end to the other.", 
                "num": 33
              }, 
              {
                "text": "He overlaid the boards with gold, made their rings of gold to be holders for the bars, and overlaid the bars with gold.", 
                "num": 34
              }, 
              {
                "text": "And he made a veil of blue, purple, and scarlet thread, and fine woven linen; it was worked with an artistic design of cherubim.", 
                "num": 35
              }, 
              {
                "text": "He made for it four pillars of acacia wood, and overlaid them with gold, with their hooks of gold; and he cast four sockets of silver for them.", 
                "num": 36
              }, 
              {
                "text": "He also made a screen for the tabernacle door, of blue, purple, and scarlet thread, and fine woven linen, made by a weaver,", 
                "num": 37
              }, 
              {
                "text": "and its five pillars with their hooks. And he overlaid their capitals and their rings with gold, but their five sockets were bronze.", 
                "num": 38
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "Then Bezalel made the ark of acacia wood; two and a half cubits was its length, a cubit and a half its width, and a cubit and a half its height.", 
                "num": 1
              }, 
              {
                "text": "He overlaid it with pure gold inside and outside, and made a molding of gold all around it.", 
                "num": 2
              }, 
              {
                "text": "And he cast for it four rings of gold to be set in its four corners: two rings on one side, and two rings on the other side of it.", 
                "num": 3
              }, 
              {
                "text": "He made poles of acacia wood, and overlaid them with gold.", 
                "num": 4
              }, 
              {
                "text": "And he put the poles into the rings at the sides of the ark, to bear the ark.", 
                "num": 5
              }, 
              {
                "text": "He also made the mercy seat of pure gold; two and a half cubits was its length and a cubit and a half its width.", 
                "num": 6
              }, 
              {
                "text": "He made two cherubim of beaten gold; he made them of one piece at the two ends of the mercy seat:", 
                "num": 7
              }, 
              {
                "text": "one cherub at one end on this side, and the other cherub at the other end on that side. He made the cherubim at the two ends of one piece with the mercy seat.", 
                "num": 8
              }, 
              {
                "text": "The cherubim spread out their wings above, and covered the mercy seat with their wings. They faced one another; the faces of the cherubim were toward the mercy seat.", 
                "num": 9
              }, 
              {
                "text": "He made the table of acacia wood; two cubits was its length, a cubit its width, and a cubit and a half its height.", 
                "num": 10
              }, 
              {
                "text": "And he overlaid it with pure gold, and made a molding of gold all around it.", 
                "num": 11
              }, 
              {
                "text": "Also he made a frame of a handbreadth all around it, and made a molding of gold for the frame all around it.", 
                "num": 12
              }, 
              {
                "text": "And he cast for it four rings of gold, and put the rings on the four corners that were at its four legs.", 
                "num": 13
              }, 
              {
                "text": "The rings were close to the frame, as holders for the poles to bear the table.", 
                "num": 14
              }, 
              {
                "text": "And he made the poles of acacia wood to bear the table, and overlaid them with gold.", 
                "num": 15
              }, 
              {
                "text": "He made of pure gold the utensils which were on the table: its dishes, its cups, its bowls, and its pitchers for pouring.", 
                "num": 16
              }, 
              {
                "text": "He also made the lampstand of pure gold; of hammered work he made the lampstand. Its shaft, its branches, its bowls, its ornamental knobs, and its flowers were of the same piece.", 
                "num": 17
              }, 
              {
                "text": "And six branches came out of its sides: three branches of the lampstand out of one side, and three branches of the lampstand out of the other side.", 
                "num": 18
              }, 
              {
                "text": "There were three bowls made like almond blossoms on one branch, with an ornamental knob and a flower, and three bowls made like almond blossoms on the other branch, with an ornamental knob and a flower--and so for the six branches coming out of the lampstand.", 
                "num": 19
              }, 
              {
                "text": "And on the lampstand itself were four bowls made like almond blossoms, each with its ornamental knob and flower.", 
                "num": 20
              }, 
              {
                "text": "There was a knob under the first two branches of the same, a knob under the second two branches of the same, and a knob under the third two branches of the same, according to the six branches extending from it.", 
                "num": 21
              }, 
              {
                "text": "Their knobs and their branches were of one piece; all of it was one hammered piece of pure gold.", 
                "num": 22
              }, 
              {
                "text": "And he made its seven lamps, its wick-trimmers, and its trays of pure gold.", 
                "num": 23
              }, 
              {
                "text": "Of a talent of pure gold he made it, with all its utensils.", 
                "num": 24
              }, 
              {
                "text": "He made the incense altar of acacia wood. Its length was a cubit and its width a cubit--it was square--and two cubits was its height. Its horns were of one piece with it.", 
                "num": 25
              }, 
              {
                "text": "And he overlaid it with pure gold: its top, its sides all around, and its horns. He also made for it a molding of gold all around it.", 
                "num": 26
              }, 
              {
                "text": "He made two rings of gold for it under its molding, by its two corners on both sides, as holders for the poles with which to bear it.", 
                "num": 27
              }, 
              {
                "text": "And he made the poles of acacia wood, and overlaid them with gold.", 
                "num": 28
              }, 
              {
                "text": "He also made the holy anointing oil and the pure incense of sweet spices, according to the work of the perfumer.", 
                "num": 29
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "He made the altar of burnt offering of acacia wood; five cubits was its length and five cubits its width--it was square--and its height was three cubits.", 
                "num": 1
              }, 
              {
                "text": "He made its horns on its four corners; the horns were of one piece with it. And he overlaid it with bronze.", 
                "num": 2
              }, 
              {
                "text": "He made all the utensils for the altar: the pans, the shovels, the basins, the forks, and the firepans; all its utensils he made of bronze.", 
                "num": 3
              }, 
              {
                "text": "And he made a grate of bronze network for the altar, under its rim, midway from the bottom.", 
                "num": 4
              }, 
              {
                "text": "He cast four rings for the four corners of the bronze grating, as holders for the poles.", 
                "num": 5
              }, 
              {
                "text": "And he made the poles of acacia wood, and overlaid them with bronze.", 
                "num": 6
              }, 
              {
                "text": "Then he put the poles into the rings on the sides of the altar, with which to bear it. He made the altar hollow with boards.", 
                "num": 7
              }, 
              {
                "text": "He made the laver of bronze and its base of bronze, from the bronze mirrors of the serving women who assembled at the door of the tabernacle of meeting.", 
                "num": 8
              }, 
              {
                "text": "Then he made the court on the south side; the hangings of the court were of fine woven linen, one hundred cubits long.", 
                "num": 9
              }, 
              {
                "text": "There were twenty pillars for them, with twenty bronze sockets. The hooks of the pillars and their bands were silver.", 
                "num": 10
              }, 
              {
                "text": "On the north side the hangings were one hundred cubits long, with twenty pillars and their twenty bronze sockets. The hooks of the pillars and their bands were silver.", 
                "num": 11
              }, 
              {
                "text": "And on the west side there were hangings of fifty cubits, with ten pillars and their ten sockets. The hooks of the pillars and their bands were silver.", 
                "num": 12
              }, 
              {
                "text": "For the east side the hangings were fifty cubits.", 
                "num": 13
              }, 
              {
                "text": "The hangings of one side of the gate were fifteen cubits long, with their three pillars and their three sockets,", 
                "num": 14
              }, 
              {
                "text": "and the same for the other side of the court gate; on this side and that were hangings of fifteen cubits, with their three pillars and their three sockets.", 
                "num": 15
              }, 
              {
                "text": "All the hangings of the court all around were of fine woven linen.", 
                "num": 16
              }, 
              {
                "text": "The sockets for the pillars were bronze, the hooks of the pillars and their bands were silver, and the overlay of their capitals was silver; and all the pillars of the court had bands of silver.", 
                "num": 17
              }, 
              {
                "text": "The screen for the gate of the court was woven of blue, purple, and scarlet thread, and of fine woven linen. The length was twenty cubits, and the height along its width was five cubits, corresponding to the hangings of the court.", 
                "num": 18
              }, 
              {
                "text": "And there were four pillars with their four sockets of bronze; their hooks were silver, and the overlay of their capitals and their bands was silver.", 
                "num": 19
              }, 
              {
                "text": "All the pegs of the tabernacle, and of the court all around, were bronze.", 
                "num": 20
              }, 
              {
                "text": "This is the inventory of the tabernacle, the tabernacle of the Testimony, which was counted according to the commandment of Moses, for the service of the Levites, by the hand of Ithamar, son of Aaron the priest.", 
                "num": 21
              }, 
              {
                "text": "Bezalel the son of Uri, the son of Hur, of the tribe of Judah, made all that the LORD had commanded Moses.", 
                "num": 22
              }, 
              {
                "text": "And with him was Aholiab the son of Ahisamach, of the tribe of Dan, an engraver and designer, a weaver of blue, purple, and scarlet thread, and of fine linen.", 
                "num": 23
              }, 
              {
                "text": "All the gold that was used in all the work of the holy place, that is, the gold of the offering, was twenty-nine talents and seven hundred and thirty shekels, according to the shekel of the sanctuary.", 
                "num": 24
              }, 
              {
                "text": "And the silver from those who were numbered of the congregation was one hundred talents and one thousand seven hundred and seventy-five shekels, according to the shekel of the sanctuary:", 
                "num": 25
              }, 
              {
                "text": "a bekah for each man (that is, half a shekel, according to the shekel of the sanctuary), for everyone included in the numbering from twenty years old and above, for six hundred and three thousand, five hundred and fifty men.", 
                "num": 26
              }, 
              {
                "text": "And from the hundred talents of silver were cast the sockets of the sanctuary and the bases of the veil: one hundred sockets from the hundred talents, one talent for each socket.", 
                "num": 27
              }, 
              {
                "text": "Then from the one thousand seven hundred and seventy-five shekels he made hooks for the pillars, overlaid their capitals, and made bands for them.", 
                "num": 28
              }, 
              {
                "text": "The offering of bronze was seventy talents and two thousand four hundred shekels.", 
                "num": 29
              }, 
              {
                "text": "And with it he made the sockets for the door of the tabernacle of meeting, the bronze altar, the bronze grating for it, and all the utensils for the altar,", 
                "num": 30
              }, 
              {
                "text": "the sockets for the court all around, the bases for the court gate, all the pegs for the tabernacle, and all the pegs for the court all around.", 
                "num": 31
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "Of the blue, purple, and scarlet thread they made garments of ministry, for ministering in the holy place, and made the holy garments for Aaron, as the LORD had commanded Moses.", 
                "num": 1
              }, 
              {
                "text": "He made the ephod of gold, blue, purple, and scarlet thread, and of fine woven linen.", 
                "num": 2
              }, 
              {
                "text": "And they beat the gold into thin sheets and cut it into threads, to work it in with the blue, purple, and scarlet thread, and the fine linen, into artistic designs.", 
                "num": 3
              }, 
              {
                "text": "They made shoulder straps for it to couple it together; it was coupled together at its two edges.", 
                "num": 4
              }, 
              {
                "text": "And the intricately woven band of his ephod that was on it was of the same workmanship, woven of gold, blue, purple, and scarlet thread, and of fine woven linen, as the LORD had commanded Moses.", 
                "num": 5
              }, 
              {
                "text": "And they set onyx stones, enclosed in settings of gold; they were engraved, as signets are engraved, with the names of the sons of Israel.", 
                "num": 6
              }, 
              {
                "text": "He put them on the shoulders of the ephod as memorial stones for the sons of Israel, as the LORD had commanded Moses.", 
                "num": 7
              }, 
              {
                "text": "And he made the breastplate, artistically woven like the workmanship of the ephod, of gold, blue, purple, and scarlet thread, and of fine woven linen.", 
                "num": 8
              }, 
              {
                "text": "They made the breastplate square by doubling it; a span was its length and a span its width when doubled.", 
                "num": 9
              }, 
              {
                "text": "And they set in it four rows of stones: a row with a sardius, a topaz, and an emerald was the first row;", 
                "num": 10
              }, 
              {
                "text": "the second row, a turquoise, a sapphire, and a diamond;", 
                "num": 11
              }, 
              {
                "text": "the third row, a jacinth, an agate, and an amethyst;", 
                "num": 12
              }, 
              {
                "text": "the fourth row, a beryl, an onyx, and a jasper. They were enclosed in settings of gold in their mountings.", 
                "num": 13
              }, 
              {
                "text": "There were twelve stones according to the names of the sons of Israel: according to their names, engraved like a signet, each one with its own name according to the twelve tribes.", 
                "num": 14
              }, 
              {
                "text": "And they made chains for the breastplate at the ends, like braided cords of pure gold.", 
                "num": 15
              }, 
              {
                "text": "They also made two settings of gold and two gold rings, and put the two rings on the two ends of the breastplate.", 
                "num": 16
              }, 
              {
                "text": "And they put the two braided chains of gold in the two rings on the ends of the breastplate.", 
                "num": 17
              }, 
              {
                "text": "The two ends of the two braided chains they fastened in the two settings, and put them on the shoulder straps of the ephod in the front.", 
                "num": 18
              }, 
              {
                "text": "And they made two rings of gold and put them on the two ends of the breastplate, on the edge of it, which was on the inward side of the ephod.", 
                "num": 19
              }, 
              {
                "text": "They made two other gold rings and put them on the two shoulder straps, underneath the ephod toward its front, right at the seam above the intricately woven band of the ephod.", 
                "num": 20
              }, 
              {
                "text": "And they bound the breastplate by means of its rings to the rings of the ephod with a blue cord, so that it would be above the intricately woven band of the ephod, and that the breastplate would not come loose from the ephod, as the LORD had commanded Moses.", 
                "num": 21
              }, 
              {
                "text": "He made the robe of the ephod of woven work, all of blue.", 
                "num": 22
              }, 
              {
                "text": "And there was an opening in the middle of the robe, like the opening in a coat of mail, with a woven binding all around the opening, so that it would not tear.", 
                "num": 23
              }, 
              {
                "text": "They made on the hem of the robe pomegranates of blue, purple, and scarlet, and of fine woven linen.", 
                "num": 24
              }, 
              {
                "text": "And they made bells of pure gold, and put the bells between the pomegranates on the hem of the robe all around between the pomegranates:", 
                "num": 25
              }, 
              {
                "text": "a bell and a pomegranate, a bell and a pomegranate, all around the hem of the robe to minister in, as the LORD had commanded Moses.", 
                "num": 26
              }, 
              {
                "text": "They made tunics, artistically woven of fine linen, for Aaron and his sons,", 
                "num": 27
              }, 
              {
                "text": "a turban of fine linen, exquisite hats of fine linen, short trousers of fine woven linen,", 
                "num": 28
              }, 
              {
                "text": "and a sash of fine woven linen with blue, purple, and scarlet thread, made by a weaver, as the LORD had commanded Moses.", 
                "num": 29
              }, 
              {
                "text": "Then they made the plate of the holy crown of pure gold, and wrote on it an inscription like the engraving of a signet: HOLINESS TO THE LORD.", 
                "num": 30
              }, 
              {
                "text": "And they tied to it a blue cord, to fasten it above on the turban, as the LORD had commanded Moses.", 
                "num": 31
              }, 
              {
                "text": "Thus all the work of the tabernacle of the tent of meeting was finished. And the children of Israel did according to all that the LORD had commanded Moses; so they did.", 
                "num": 32
              }, 
              {
                "text": "And they brought the tabernacle to Moses, the tent and all its furnishings: its clasps, its boards, its bars, its pillars, and its sockets;", 
                "num": 33
              }, 
              {
                "text": "the covering of ram skins dyed red, the covering of badger skins, and the veil of the covering;", 
                "num": 34
              }, 
              {
                "text": "the ark of the Testimony with its poles, and the mercy seat;", 
                "num": 35
              }, 
              {
                "text": "the table, all its utensils, and the showbread;", 
                "num": 36
              }, 
              {
                "text": "the pure gold lampstand with its lamps (the lamps set in order), all its utensils, and the oil for light;", 
                "num": 37
              }, 
              {
                "text": "the gold altar, the anointing oil, and the sweet incense; the screen for the tabernacle door;", 
                "num": 38
              }, 
              {
                "text": "the bronze altar, its grate of bronze, its poles, and all its utensils; the laver with its base;", 
                "num": 39
              }, 
              {
                "text": "the hangings of the court, its pillars and its sockets, the screen for the court gate, its cords, and its pegs; all the utensils for the service of the tabernacle, for the tent of meeting;", 
                "num": 40
              }, 
              {
                "text": "and the garments of ministry, to minister in the holy place: the holy garments for Aaron the priest, and his sons' garments, to minister as priests.", 
                "num": 41
              }, 
              {
                "text": "According to all that the LORD had commanded Moses, so the children of Israel did all the work.", 
                "num": 42
              }, 
              {
                "text": "Then Moses looked over all the work, and indeed they had done it; as the LORD had commanded, just so they had done it. And Moses blessed them.", 
                "num": 43
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"On the first day of the first month you shall set up the tabernacle of the tent of meeting.", 
                "num": 2
              }, 
              {
                "text": "You shall put in it the ark of the Testimony, and partition off the ark with the veil.", 
                "num": 3
              }, 
              {
                "text": "You shall bring in the table and arrange the things that are to be set in order on it; and you shall bring in the lampstand and light its lamps.", 
                "num": 4
              }, 
              {
                "text": "You shall also set the altar of gold for the incense before the ark of the Testimony, and put up the screen for the door of the tabernacle.", 
                "num": 5
              }, 
              {
                "text": "Then you shall set the altar of the burnt offering before the door of the tabernacle of the tent of meeting.", 
                "num": 6
              }, 
              {
                "text": "And you shall set the laver between the tabernacle of meeting and the altar, and put water in it.", 
                "num": 7
              }, 
              {
                "text": "You shall set up the court all around, and hang up the screen at the court gate.", 
                "num": 8
              }, 
              {
                "text": "\"And you shall take the anointing oil, and anoint the tabernacle and all that is in it; and you shall hallow it and all its utensils, and it shall be holy.", 
                "num": 9
              }, 
              {
                "text": "You shall anoint the altar of the burnt offering and all its utensils, and consecrate the altar. The altar shall be most holy.", 
                "num": 10
              }, 
              {
                "text": "And you shall anoint the laver and its base, and consecrate it.", 
                "num": 11
              }, 
              {
                "text": "\"Then you shall bring Aaron and his sons to the door of the tabernacle of meeting and wash them with water.", 
                "num": 12
              }, 
              {
                "text": "You shall put the holy garments on Aaron, and anoint him and consecrate him, that he may minister to Me as priest.", 
                "num": 13
              }, 
              {
                "text": "And you shall bring his sons and clothe them with tunics.", 
                "num": 14
              }, 
              {
                "text": "You shall anoint them, as you anointed their father, that they may minister to Me as priests; for their anointing shall surely be an everlasting priesthood throughout their generations.\"", 
                "num": 15
              }, 
              {
                "text": "Thus Moses did; according to all that the LORD had commanded him, so he did.", 
                "num": 16
              }, 
              {
                "text": "And it came to pass in the first month of the second year, on the first day of the month, that the tabernacle was raised up.", 
                "num": 17
              }, 
              {
                "text": "So Moses raised up the tabernacle, fastened its sockets, set up its boards, put in its bars, and raised up its pillars.", 
                "num": 18
              }, 
              {
                "text": "And he spread out the tent over the tabernacle and put the covering of the tent on top of it, as the LORD had commanded Moses.", 
                "num": 19
              }, 
              {
                "text": "He took the Testimony and put it into the ark, inserted the poles through the rings of the ark, and put the mercy seat on top of the ark.", 
                "num": 20
              }, 
              {
                "text": "And he brought the ark into the tabernacle, hung up the veil of the covering, and partitioned off the ark of the Testimony, as the LORD had commanded Moses.", 
                "num": 21
              }, 
              {
                "text": "He put the table in the tabernacle of meeting, on the north side of the tabernacle, outside the veil;", 
                "num": 22
              }, 
              {
                "text": "and he set the bread in order upon it before the LORD, as the LORD had commanded Moses.", 
                "num": 23
              }, 
              {
                "text": "He put the lampstand in the tabernacle of meeting, across from the table, on the south side of the tabernacle;", 
                "num": 24
              }, 
              {
                "text": "and he lit the lamps before the LORD, as the LORD had commanded Moses.", 
                "num": 25
              }, 
              {
                "text": "He put the gold altar in the tabernacle of meeting in front of the veil;", 
                "num": 26
              }, 
              {
                "text": "and he burned sweet incense on it, as the LORD had commanded Moses.", 
                "num": 27
              }, 
              {
                "text": "He hung up the screen at the door of the tabernacle.", 
                "num": 28
              }, 
              {
                "text": "And he put the altar of burnt offering before the door of the tabernacle of the tent of meeting, and offered upon it the burnt offering and the grain offering, as the LORD had commanded Moses.", 
                "num": 29
              }, 
              {
                "text": "He set the laver between the tabernacle of meeting and the altar, and put water there for washing;", 
                "num": 30
              }, 
              {
                "text": "and Moses, Aaron, and his sons would wash their hands and their feet with water from it.", 
                "num": 31
              }, 
              {
                "text": "Whenever they went into the tabernacle of meeting, and when they came near the altar, they washed, as the LORD had commanded Moses.", 
                "num": 32
              }, 
              {
                "text": "And he raised up the court all around the tabernacle and the altar, and hung up the screen of the court gate. So Moses finished the work.", 
                "num": 33
              }, 
              {
                "text": "Then the cloud covered the tabernacle of meeting, and the glory of the LORD filled the tabernacle.", 
                "num": 34
              }, 
              {
                "text": "And Moses was not able to enter the tabernacle of meeting, because the cloud rested above it, and the glory of the LORD filled the tabernacle.", 
                "num": 35
              }, 
              {
                "text": "Whenever the cloud was taken up from above the tabernacle, the children of Israel would go onward in all their journeys.", 
                "num": 36
              }, 
              {
                "text": "But if the cloud was not taken up, then they did not journey till the day that it was taken up.", 
                "num": 37
              }, 
              {
                "text": "For the cloud of the LORD was above the tabernacle by day, and fire was over it by night, in the sight of all the house of Israel, throughout all their journeys.", 
                "num": 38
              }
            ], 
            "num": 40
          }
        ]
      }, 
      {
        "name": "Leviticus", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now the LORD called to Moses, and spoke to him from the tabernacle of meeting, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When any one of you brings an offering to the LORD, you shall bring your offering of the livestock--of the herd and of the flock.", 
                "num": 2
              }, 
              {
                "text": "\"If his offering is a burnt sacrifice of the herd, let him offer a male without blemish; he shall offer it of his own free will at the door of the tabernacle of meeting before the LORD.", 
                "num": 3
              }, 
              {
                "text": "Then he shall put his hand on the head of the burnt offering, and it will be accepted on his behalf to make atonement for him.", 
                "num": 4
              }, 
              {
                "text": "He shall kill the bull before the LORD; and the priests, Aaron's sons, shall bring the blood and sprinkle the blood all around on the altar that is by the door of the tabernacle of meeting.", 
                "num": 5
              }, 
              {
                "text": "And he shall skin the burnt offering and cut it into its pieces.", 
                "num": 6
              }, 
              {
                "text": "The sons of Aaron the priest shall put fire on the altar, and lay the wood in order on the fire.", 
                "num": 7
              }, 
              {
                "text": "Then the priests, Aaron's sons, shall lay the parts, the head, and the fat in order on the wood that is on the fire upon the altar;", 
                "num": 8
              }, 
              {
                "text": "but he shall wash its entrails and its legs with water. And the priest shall burn all on the altar as a burnt sacrifice, an offering made by fire, a sweet aroma to the LORD.", 
                "num": 9
              }, 
              {
                "text": "\"If his offering is of the flocks--of the sheep or of the goats--as a burnt sacrifice, he shall bring a male without blemish.", 
                "num": 10
              }, 
              {
                "text": "He shall kill it on the north side of the altar before the LORD; and the priests, Aaron's sons, shall sprinkle its blood all around on the altar.", 
                "num": 11
              }, 
              {
                "text": "And he shall cut it into its pieces, with its head and its fat; and the priest shall lay them in order on the wood that is on the fire upon the altar;", 
                "num": 12
              }, 
              {
                "text": "but he shall wash the entrails and the legs with water. Then the priest shall bring it all and burn it on the altar; it is a burnt sacrifice, an offering made by fire, a sweet aroma to the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"And if the burnt sacrifice of his offering to the LORD is of birds, then he shall bring his offering of turtledoves or young pigeons.", 
                "num": 14
              }, 
              {
                "text": "The priest shall bring it to the altar, wring off its head, and burn it on the altar; its blood shall be drained out at the side of the altar.", 
                "num": 15
              }, 
              {
                "text": "And he shall remove its crop with its feathers and cast it beside the altar on the east side, into the place for ashes.", 
                "num": 16
              }, 
              {
                "text": "Then he shall split it at its wings, but shall not divide it completely; and the priest shall burn it on the altar, on the wood that is on the fire. It is a burnt sacrifice, an offering made by fire, a sweet aroma to the LORD.", 
                "num": 17
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "\"When anyone offers a grain offering to the LORD, his offering shall be of fine flour. And he shall pour oil on it, and put frankincense on it.", 
                "num": 1
              }, 
              {
                "text": "He shall bring it to Aaron's sons, the priests, one of whom shall take from it his handful of fine flour and oil with all the frankincense. And the priest shall burn it as a memorial on the altar, an offering made by fire, a sweet aroma to the LORD.", 
                "num": 2
              }, 
              {
                "text": "The rest of the grain offering shall be Aaron's and his sons'. It is most holy of the offerings to the LORD made by fire.", 
                "num": 3
              }, 
              {
                "text": "\"And if you bring as an offering a grain offering baked in the oven, it shall be unleavened cakes of fine flour mixed with oil, or unleavened wafers anointed with oil.", 
                "num": 4
              }, 
              {
                "text": "But if your offering is a grain offering baked in a pan, it shall be of fine flour, unleavened, mixed with oil.", 
                "num": 5
              }, 
              {
                "text": "You shall break it in pieces and pour oil on it; it is a grain offering.", 
                "num": 6
              }, 
              {
                "text": "\"If your offering is a grain offering baked in a covered pan, it shall be made of fine flour with oil.", 
                "num": 7
              }, 
              {
                "text": "You shall bring the grain offering that is made of these things to the LORD. And when it is presented to the priest, he shall bring it to the altar.", 
                "num": 8
              }, 
              {
                "text": "Then the priest shall take from the grain offering a memorial portion, and burn it on the altar. It is an offering made by fire, a sweet aroma to the LORD.", 
                "num": 9
              }, 
              {
                "text": "And what is left of the grain offering shall be Aaron's and his sons'. It is most holy of the offerings to the LORD made by fire.", 
                "num": 10
              }, 
              {
                "text": "\"No grain offering which you bring to the LORD shall be made with leaven, for you shall burn no leaven nor any honey in any offering to the LORD made by fire.", 
                "num": 11
              }, 
              {
                "text": "As for the offering of the firstfruits, you shall offer them to the LORD, but they shall not be burned on the altar for a sweet aroma.", 
                "num": 12
              }, 
              {
                "text": "And every offering of your grain offering you shall season with salt; you shall not allow the salt of the covenant of your God to be lacking from your grain offering. With all your offerings you shall offer salt.", 
                "num": 13
              }, 
              {
                "text": "\"If you offer a grain offering of your firstfruits to the LORD, you shall offer for the grain offering of your firstfruits green heads of grain roasted on the fire, grain beaten from full heads.", 
                "num": 14
              }, 
              {
                "text": "And you shall put oil on it, and lay frankincense on it. It is a grain offering.", 
                "num": 15
              }, 
              {
                "text": "Then the priest shall burn the memorial portion: part of its beaten grain and part of its oil, with all the frankincense, as an offering made by fire to the LORD.", 
                "num": 16
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"When his offering is a sacrifice of a peace offering, if he offers it of the herd, whether male or female, he shall offer it without blemish before the LORD.", 
                "num": 1
              }, 
              {
                "text": "And he shall lay his hand on the head of his offering, and kill it at the door of the tabernacle of meeting; and Aaron's sons, the priests, shall sprinkle the blood all around on the altar.", 
                "num": 2
              }, 
              {
                "text": "Then he shall offer from the sacrifice of the peace offering an offering made by fire to the LORD. The fat that covers the entrails and all the fat that is on the entrails,", 
                "num": 3
              }, 
              {
                "text": "the two kidneys and the fat that is on them by the flanks, and the fatty lobe attached to the liver above the kidneys, he shall remove;", 
                "num": 4
              }, 
              {
                "text": "and Aaron's sons shall burn it on the altar upon the burnt sacrifice, which is on the wood that is on the fire, as an offering made by fire, a sweet aroma to the LORD.", 
                "num": 5
              }, 
              {
                "text": "\"If his offering as a sacrifice of a peace offering to the LORD is of the flock, whether male or female, he shall offer it without blemish.", 
                "num": 6
              }, 
              {
                "text": "If he offers a lamb as his offering, then he shall offer it before the LORD.", 
                "num": 7
              }, 
              {
                "text": "And he shall lay his hand on the head of his offering, and kill it before the tabernacle of meeting; and Aaron's sons shall sprinkle its blood all around on the altar.", 
                "num": 8
              }, 
              {
                "text": "\"Then he shall offer from the sacrifice of the peace offering, as an offering made by fire to the LORD, its fat and the whole fat tail which he shall remove close to the backbone. And the fat that covers the entrails and all the fat that is on the entrails,", 
                "num": 9
              }, 
              {
                "text": "the two kidneys and the fat that is on them by the flanks, and the fatty lobe attached to the liver above the kidneys, he shall remove;", 
                "num": 10
              }, 
              {
                "text": "and the priest shall burn them on the altar as food, an offering made by fire to the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"And if his offering is a goat, then he shall offer it before the LORD.", 
                "num": 12
              }, 
              {
                "text": "He shall lay his hand on its head and kill it before the tabernacle of meeting; and the sons of Aaron shall sprinkle its blood all around on the altar.", 
                "num": 13
              }, 
              {
                "text": "Then he shall offer from it his offering, as an offering made by fire to the LORD. The fat that covers the entrails and all the fat that is on the entrails,", 
                "num": 14
              }, 
              {
                "text": "the two kidneys and the fat that is on them by the flanks, and the fatty lobe attached to the liver above the kidneys, he shall remove;", 
                "num": 15
              }, 
              {
                "text": "and the priest shall burn them on the altar as food, an offering made by fire for a sweet aroma; all the fat is the LORD's.", 
                "num": 16
              }, 
              {
                "text": "\"This shall be a perpetual statute throughout your generations in all your dwellings: you shall eat neither fat nor blood.\"'", 
                "num": 17
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"If a person sins unintentionally against any of the commandments of the LORD in anything which ought not to be done, and does any of them,", 
                "num": 2
              }, 
              {
                "text": "if the anointed priest sins, bringing guilt on the people, then let him offer to the LORD for his sin which he has sinned a young bull without blemish as a sin offering.", 
                "num": 3
              }, 
              {
                "text": "He shall bring the bull to the door of the tabernacle of meeting before the LORD, lay his hand on the bull's head, and kill the bull before the LORD.", 
                "num": 4
              }, 
              {
                "text": "Then the anointed priest shall take some of the bull's blood and bring it to the tabernacle of meeting.", 
                "num": 5
              }, 
              {
                "text": "The priest shall dip his finger in the blood and sprinkle some of the blood seven times before the LORD, in front of the veil of the sanctuary.", 
                "num": 6
              }, 
              {
                "text": "And the priest shall put some of the blood on the horns of the altar of sweet incense before the LORD, which is in the tabernacle of meeting; and he shall pour the remaining blood of the bull at the base of the altar of the burnt offering, which is at the door of the tabernacle of meeting.", 
                "num": 7
              }, 
              {
                "text": "He shall take from it all the fat of the bull as the sin offering. The fat that covers the entrails and all the fat which is on the entrails,", 
                "num": 8
              }, 
              {
                "text": "the two kidneys and the fat that is on them by the flanks, and the fatty lobe attached to the liver above the kidneys, he shall remove,", 
                "num": 9
              }, 
              {
                "text": "as it was taken from the bull of the sacrifice of the peace offering; and the priest shall burn them on the altar of the burnt offering.", 
                "num": 10
              }, 
              {
                "text": "But the bull's hide and all its flesh, with its head and legs, its entrails and offal--", 
                "num": 11
              }, 
              {
                "text": "the whole bull he shall carry outside the camp to a clean place, where the ashes are poured out, and burn it on wood with fire; where the ashes are poured out it shall be burned.", 
                "num": 12
              }, 
              {
                "text": "\"Now if the whole congregation of Israel sins unintentionally, and the thing is hidden from the eyes of the assembly, and they have done something against any of the commandments of the LORD in anything which should not be done, and are guilty;", 
                "num": 13
              }, 
              {
                "text": "when the sin which they have committed becomes known, then the assembly shall offer a young bull for the sin, and bring it before the tabernacle of meeting.", 
                "num": 14
              }, 
              {
                "text": "And the elders of the congregation shall lay their hands on the head of the bull before the LORD. Then the bull shall be killed before the LORD.", 
                "num": 15
              }, 
              {
                "text": "The anointed priest shall bring some of the bull's blood to the tabernacle of meeting.", 
                "num": 16
              }, 
              {
                "text": "Then the priest shall dip his finger in the blood and sprinkle it seven times before the LORD, in front of the veil.", 
                "num": 17
              }, 
              {
                "text": "And he shall put some of the blood on the horns of the altar which is before the LORD, which is in the tabernacle of meeting; and he shall pour the remaining blood at the base of the altar of burnt offering, which is at the door of the tabernacle of meeting.", 
                "num": 18
              }, 
              {
                "text": "He shall take all the fat from it and burn it on the altar.", 
                "num": 19
              }, 
              {
                "text": "And he shall do with the bull as he did with the bull as a sin offering; thus he shall do with it. So the priest shall make atonement for them, and it shall be forgiven them.", 
                "num": 20
              }, 
              {
                "text": "Then he shall carry the bull outside the camp, and burn it as he burned the first bull. It is a sin offering for the assembly.", 
                "num": 21
              }, 
              {
                "text": "\"When a ruler has sinned, and done something unintentionally against any of the commandments of the LORD his God in anything which should not be done, and is guilty,", 
                "num": 22
              }, 
              {
                "text": "or if his sin which he has committed comes to his knowledge, he shall bring as his offering a kid of the goats, a male without blemish.", 
                "num": 23
              }, 
              {
                "text": "And he shall lay his hand on the head of the goat, and kill it at the place where they kill the burnt offering before the LORD. It is a sin offering.", 
                "num": 24
              }, 
              {
                "text": "The priest shall take some of the blood of the sin offering with his finger, put it on the horns of the altar of burnt offering, and pour its blood at the base of the altar of burnt offering.", 
                "num": 25
              }, 
              {
                "text": "And he shall burn all its fat on the altar, like the fat of the sacrifice of the peace offering. So the priest shall make atonement for him concerning his sin, and it shall be forgiven him.", 
                "num": 26
              }, 
              {
                "text": "\"If anyone of the common people sins unintentionally by doing something against any of the commandments of the LORD in anything which ought not to be done, and is guilty,", 
                "num": 27
              }, 
              {
                "text": "or if his sin which he has committed comes to his knowledge, then he shall bring as his offering a kid of the goats, a female without blemish, for his sin which he has committed.", 
                "num": 28
              }, 
              {
                "text": "And he shall lay his hand on the head of the sin offering, and kill the sin offering at the place of the burnt offering.", 
                "num": 29
              }, 
              {
                "text": "Then the priest shall take some of its blood with his finger, put it on the horns of the altar of burnt offering, and pour all the remaining blood at the base of the altar.", 
                "num": 30
              }, 
              {
                "text": "He shall remove all its fat, as fat is removed from the sacrifice of the peace offering; and the priest shall burn it on the altar for a sweet aroma to the LORD. So the priest shall make atonement for him, and it shall be forgiven him.", 
                "num": 31
              }, 
              {
                "text": "\"If he brings a lamb as his sin offering, he shall bring a female without blemish.", 
                "num": 32
              }, 
              {
                "text": "Then he shall lay his hand on the head of the sin offering, and kill it as a sin offering at the place where they kill the burnt offering.", 
                "num": 33
              }, 
              {
                "text": "The priest shall take some of the blood of the sin offering with his finger, put it on the horns of the altar of burnt offering, and pour all the remaining blood at the base of the altar.", 
                "num": 34
              }, 
              {
                "text": "He shall remove all its fat, as the fat of the lamb is removed from the sacrifice of the peace offering. Then the priest shall burn it on the altar, according to the offerings made by fire to the LORD. So the priest shall make atonement for his sin that he has committed, and it shall be forgiven him.", 
                "num": 35
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "\"If a person sins in hearing the utterance of an oath, and is a witness, whether he has seen or known of the matter--if he does not tell it, he bears guilt.", 
                "num": 1
              }, 
              {
                "text": "\"Or if a person touches any unclean thing, whether it is the carcass of an unclean beast, or the carcass of unclean livestock, or the carcass of unclean creeping things, and he is unaware of it, he also shall be unclean and guilty.", 
                "num": 2
              }, 
              {
                "text": "Or if he touches human uncleanness--whatever uncleanness with which a man may be defiled, and he is unaware of it--when he realizes it, then he shall be guilty.", 
                "num": 3
              }, 
              {
                "text": "\"Or if a person swears, speaking thoughtlessly with his lips to do evil or to do good, whatever it is that a man may pronounce by an oath, and he is unaware of it--when he realizes it, then he shall be guilty in any of these matters.", 
                "num": 4
              }, 
              {
                "text": "\"And it shall be, when he is guilty in any of these matters, that he shall confess that he has sinned in that thing;", 
                "num": 5
              }, 
              {
                "text": "and he shall bring his trespass offering to the LORD for his sin which he has committed, a female from the flock, a lamb or a kid of the goats as a sin offering. So the priest shall make atonement for him concerning his sin.", 
                "num": 6
              }, 
              {
                "text": "\"If he is not able to bring a lamb, then he shall bring to the LORD, for his trespass which he has committed, two turtledoves or two young pigeons: one as a sin offering and the other as a burnt offering.", 
                "num": 7
              }, 
              {
                "text": "And he shall bring them to the priest, who shall offer that which is for the sin offering first, and wring off its head from its neck, but shall not divide it completely.", 
                "num": 8
              }, 
              {
                "text": "Then he shall sprinkle some of the blood of the sin offering on the side of the altar, and the rest of the blood shall be drained out at the base of the altar. It is a sin offering.", 
                "num": 9
              }, 
              {
                "text": "And he shall offer the second as a burnt offering according to the prescribed manner. So the priest shall make atonement on his behalf for his sin which he has committed, and it shall be forgiven him.", 
                "num": 10
              }, 
              {
                "text": "\"But if he is not able to bring two turtledoves or two young pigeons, then he who sinned shall bring for his offering one-tenth of an ephah of fine flour as a sin offering. He shall put no oil on it, nor shall he put frankincense on it, for it is a sin offering.", 
                "num": 11
              }, 
              {
                "text": "Then he shall bring it to the priest, and the priest shall take his handful of it as a memorial portion, and burn it on the altar according to the offerings made by fire to the LORD. It is a sin offering.", 
                "num": 12
              }, 
              {
                "text": "The priest shall make atonement for him, for his sin that he has committed in any of these matters; and it shall be forgiven him. The rest shall be the priest's as a grain offering.\"'", 
                "num": 13
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 14
              }, 
              {
                "text": "\"If a person commits a trespass, and sins unintentionally in regard to the holy things of the LORD, then he shall bring to the LORD as his trespass offering a ram without blemish from the flocks, with your valuation in shekels of silver according to the shekel of the sanctuary, as a trespass offering.", 
                "num": 15
              }, 
              {
                "text": "And he shall make restitution for the harm that he has done in regard to the holy thing, and shall add one-fifth to it and give it to the priest. So the priest shall make atonement for him with the ram of the trespass offering, and it shall be forgiven him.", 
                "num": 16
              }, 
              {
                "text": "\"If a person sins, and commits any of these things which are forbidden to be done by the commandments of the LORD, though he does not know it, yet he is guilty and shall bear his iniquity.", 
                "num": 17
              }, 
              {
                "text": "And he shall bring to the priest a ram without blemish from the flock, with your valuation, as a trespass offering. So the priest shall make atonement for him regarding his ignorance in which he erred and did not know it, and it shall be forgiven him.", 
                "num": 18
              }, 
              {
                "text": "It is a trespass offering; he has certainly trespassed against the LORD.\"", 
                "num": 19
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"If a person sins and commits a trespass against the LORD by lying to his neighbor about what was delivered to him for safekeeping, or about a pledge, or about a robbery, or if he has extorted from his neighbor,", 
                "num": 2
              }, 
              {
                "text": "or if he has found what was lost and lies concerning it, and swears falsely--in any one of these things that a man may do in which he sins:", 
                "num": 3
              }, 
              {
                "text": "then it shall be, because he has sinned and is guilty, that he shall restore what he has stolen, or the thing which he has extorted, or what was delivered to him for safekeeping, or the lost thing which he found,", 
                "num": 4
              }, 
              {
                "text": "or all that about which he has sworn falsely. He shall restore its full value, add one-fifth more to it, and give it to whomever it belongs, on the day of his trespass offering.", 
                "num": 5
              }, 
              {
                "text": "And he shall bring his trespass offering to the LORD, a ram without blemish from the flock, with your valuation, as a trespass offering, to the priest.", 
                "num": 6
              }, 
              {
                "text": "So the priest shall make atonement for him before the LORD, and he shall be forgiven for any one of these things that he may have done in which he trespasses.\"", 
                "num": 7
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Command Aaron and his sons, saying, \"This is the law of the burnt offering: The burnt offering shall be on the hearth upon the altar all night until morning, and the fire of the altar shall be kept burning on it.", 
                "num": 9
              }, 
              {
                "text": "And the priest shall put on his linen garment, and his linen trousers he shall put on his body, and take up the ashes of the burnt offering which the fire has consumed on the altar, and he shall put them beside the altar.", 
                "num": 10
              }, 
              {
                "text": "Then he shall take off his garments, put on other garments, and carry the ashes outside the camp to a clean place.", 
                "num": 11
              }, 
              {
                "text": "And the fire on the altar shall be kept burning on it; it shall not be put out. And the priest shall burn wood on it every morning, and lay the burnt offering in order on it; and he shall burn on it the fat of the peace offerings.", 
                "num": 12
              }, 
              {
                "text": "A fire shall always be burning on the altar; it shall never go out.", 
                "num": 13
              }, 
              {
                "text": "\"This is the law of the grain offering: The sons of Aaron shall offer it on the altar before the LORD.", 
                "num": 14
              }, 
              {
                "text": "He shall take from it his handful of the fine flour of the grain offering, with its oil, and all the frankincense which is on the grain offering, and shall burn it on the altar for a sweet aroma, as a memorial to the LORD.", 
                "num": 15
              }, 
              {
                "text": "And the remainder of it Aaron and his sons shall eat; with unleavened bread it shall be eaten in a holy place; in the court of the tabernacle of meeting they shall eat it.", 
                "num": 16
              }, 
              {
                "text": "It shall not be baked with leaven. I have given it as their portion of My offerings made by fire; it is most holy, like the sin offering and the trespass offering.", 
                "num": 17
              }, 
              {
                "text": "All the males among the children of Aaron may eat it. It shall be a statute forever in your generations concerning the offerings made by fire to the LORD. Everyone who touches them must be holy.\"'", 
                "num": 18
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 19
              }, 
              {
                "text": "\"This is the offering of Aaron and his sons, which they shall offer to the LORD, beginning on the day when he is anointed: one-tenth of an ephah of fine flour as a daily grain offering, half of it in the morning and half of it at night.", 
                "num": 20
              }, 
              {
                "text": "It shall be made in a pan with oil. When it is mixed, you shall bring it in. The baked pieces of the grain offering you shall offer for a sweet aroma to the LORD.", 
                "num": 21
              }, 
              {
                "text": "The priest from among his sons, who is anointed in his place, shall offer it. It is a statute forever to the LORD. It shall be wholly burned.", 
                "num": 22
              }, 
              {
                "text": "For every grain offering for the priest shall be wholly burned. It shall not be eaten.\"", 
                "num": 23
              }, 
              {
                "text": "Also the LORD spoke to Moses, saying,", 
                "num": 24
              }, 
              {
                "text": "\"Speak to Aaron and to his sons, saying, \"This is the law of the sin offering: In the place where the burnt offering is killed, the sin offering shall be killed before the LORD. It is most holy.", 
                "num": 25
              }, 
              {
                "text": "The priest who offers it for sin shall eat it. In a holy place it shall be eaten, in the court of the tabernacle of meeting.", 
                "num": 26
              }, 
              {
                "text": "Everyone who touches its flesh must be holy. And when its blood is sprinkled on any garment, you shall wash that on which it was sprinkled, in a holy place.", 
                "num": 27
              }, 
              {
                "text": "But the earthen vessel in which it is boiled shall be broken. And if it is boiled in a bronze pot, it shall be both scoured and rinsed in water.", 
                "num": 28
              }, 
              {
                "text": "All the males among the priests may eat it. It is most holy.", 
                "num": 29
              }, 
              {
                "text": "But no sin offering from which any of the blood is brought into the tabernacle of meeting, to make atonement in the holy place, shall be eaten. It shall be burned in the fire.", 
                "num": 30
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "\"Likewise this is the law of the trespass offering (it is most holy):", 
                "num": 1
              }, 
              {
                "text": "In the place where they kill the burnt offering they shall kill the trespass offering. And its blood he shall sprinkle all around on the altar.", 
                "num": 2
              }, 
              {
                "text": "And he shall offer from it all its fat. The fat tail and the fat that covers the entrails,", 
                "num": 3
              }, 
              {
                "text": "the two kidneys and the fat that is on them by the flanks, and the fatty lobe attached to the liver above the kidneys, he shall remove;", 
                "num": 4
              }, 
              {
                "text": "and the priest shall burn them on the altar as an offering made by fire to the LORD. It is a trespass offering.", 
                "num": 5
              }, 
              {
                "text": "Every male among the priests may eat it. It shall be eaten in a holy place. It is most holy.", 
                "num": 6
              }, 
              {
                "text": "The trespass offering is like the sin offering; there is one law for them both: the priest who makes atonement with it shall have it.", 
                "num": 7
              }, 
              {
                "text": "And the priest who offers anyone's burnt offering, that priest shall have for himself the skin of the burnt offering which he has offered.", 
                "num": 8
              }, 
              {
                "text": "Also every grain offering that is baked in the oven and all that is prepared in the covered pan, or in a pan, shall be the priest's who offers it.", 
                "num": 9
              }, 
              {
                "text": "Every grain offering, whether mixed with oil or dry, shall belong to all the sons of Aaron, to one as much as the other.", 
                "num": 10
              }, 
              {
                "text": "\"This is the law of the sacrifice of peace offerings which he shall offer to the LORD:", 
                "num": 11
              }, 
              {
                "text": "If he offers it for a thanksgiving, then he shall offer, with the sacrifice of thanksgiving, unleavened cakes mixed with oil, unleavened wafers anointed with oil, or cakes of blended flour mixed with oil.", 
                "num": 12
              }, 
              {
                "text": "Besides the cakes, as his offering he shall offer leavened bread with the sacrifice of thanksgiving of his peace offering.", 
                "num": 13
              }, 
              {
                "text": "And from it he shall offer one cake from each offering as a heave offering to the LORD. It shall belong to the priest who sprinkles the blood of the peace offering.", 
                "num": 14
              }, 
              {
                "text": "\"The flesh of the sacrifice of his peace offering for thanksgiving shall be eaten the same day it is offered. He shall not leave any of it until morning.", 
                "num": 15
              }, 
              {
                "text": "But if the sacrifice of his offering is a vow or a voluntary offering, it shall be eaten the same day that he offers his sacrifice; but on the next day the remainder of it also may be eaten;", 
                "num": 16
              }, 
              {
                "text": "the remainder of the flesh of the sacrifice on the third day must be burned with fire.", 
                "num": 17
              }, 
              {
                "text": "And if any of the flesh of the sacrifice of his peace offering is eaten at all on the third day, it shall not be accepted, nor shall it be imputed to him; it shall be an abomination to him who offers it, and the person who eats of it shall bear guilt.", 
                "num": 18
              }, 
              {
                "text": "\"The flesh that touches any unclean thing shall not be eaten. It shall be burned with fire. And as for the clean flesh, all who are clean may eat of it.", 
                "num": 19
              }, 
              {
                "text": "But the person who eats the flesh of the sacrifice of the peace offering that belongs to the LORD, while he is unclean, that person shall be cut off from his people.", 
                "num": 20
              }, 
              {
                "text": "Moreover the person who touches any unclean thing, such as human uncleanness, an unclean animal, or any abominable unclean thing, and who eats the flesh of the sacrifice of the peace offering that belongs to the LORD, that person shall be cut off from his people.\"'", 
                "num": 21
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 22
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"You shall not eat any fat, of ox or sheep or goat.", 
                "num": 23
              }, 
              {
                "text": "And the fat of an animal that dies naturally, and the fat of what is torn by wild beasts, may be used in any other way; but you shall by no means eat it.", 
                "num": 24
              }, 
              {
                "text": "For whoever eats the fat of the animal of which men offer an offering made by fire to the LORD, the person who eats it shall be cut off from his people.", 
                "num": 25
              }, 
              {
                "text": "Moreover you shall not eat any blood in any of your dwellings, whether of bird or beast.", 
                "num": 26
              }, 
              {
                "text": "Whoever eats any blood, that person shall be cut off from his people.\"'", 
                "num": 27
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 28
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"He who offers the sacrifice of his peace offering to the LORD shall bring his offering to the LORD from the sacrifice of his peace offering.", 
                "num": 29
              }, 
              {
                "text": "His own hands shall bring the offerings made by fire to the LORD. The fat with the breast he shall bring, that the breast may be waved as a wave offering before the LORD.", 
                "num": 30
              }, 
              {
                "text": "And the priest shall burn the fat on the altar, but the breast shall be Aaron's and his sons'.", 
                "num": 31
              }, 
              {
                "text": "Also the right thigh you shall give to the priest as a heave offering from the sacrifices of your peace offerings.", 
                "num": 32
              }, 
              {
                "text": "He among the sons of Aaron, who offers the blood of the peace offering and the fat, shall have the right thigh for his part.", 
                "num": 33
              }, 
              {
                "text": "For the breast of the wave offering and the thigh of the heave offering I have taken from the children of Israel, from the sacrifices of their peace offerings, and I have given them to Aaron the priest and to his sons from the children of Israel by a statute forever.\"'", 
                "num": 34
              }, 
              {
                "text": "This is the consecrated portion for Aaron and his sons, from the offerings made by fire to the LORD, on the day when Moses presented them to minister to the LORD as priests.", 
                "num": 35
              }, 
              {
                "text": "The LORD commanded this to be given to them by the children of Israel, on the day that He anointed them, by a statute forever throughout their generations.", 
                "num": 36
              }, 
              {
                "text": "This is the law of the burnt offering, the grain offering, the sin offering, the trespass offering, the consecrations, and the sacrifice of the peace offering,", 
                "num": 37
              }, 
              {
                "text": "which the LORD commanded Moses on Mount Sinai, on the day when He commanded the children of Israel to offer their offerings to the LORD in the Wilderness of Sinai.", 
                "num": 38
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take Aaron and his sons with him, and the garments, the anointing oil, a bull as the sin offering, two rams, and a basket of unleavened bread;", 
                "num": 2
              }, 
              {
                "text": "and gather all the congregation together at the door of the tabernacle of meeting.\"", 
                "num": 3
              }, 
              {
                "text": "So Moses did as the LORD commanded him. And the congregation was gathered together at the door of the tabernacle of meeting.", 
                "num": 4
              }, 
              {
                "text": "And Moses said to the congregation, \"This is what the LORD commanded to be done.\"", 
                "num": 5
              }, 
              {
                "text": "Then Moses brought Aaron and his sons and washed them with water.", 
                "num": 6
              }, 
              {
                "text": "And he put the tunic on him, girded him with the sash, clothed him with the robe, and put the ephod on him; and he girded him with the intricately woven band of the ephod, and with it tied the ephod on him.", 
                "num": 7
              }, 
              {
                "text": "Then he put the breastplate on him, and he put the Urim and the Thummim in the breastplate.", 
                "num": 8
              }, 
              {
                "text": "And he put the turban on his head. Also on the turban, on its front, he put the golden plate, the holy crown, as the LORD had commanded Moses.", 
                "num": 9
              }, 
              {
                "text": "Also Moses took the anointing oil, and anointed the tabernacle and all that was in it, and consecrated them.", 
                "num": 10
              }, 
              {
                "text": "He sprinkled some of it on the altar seven times, anointed the altar and all its utensils, and the laver and its base, to consecrate them.", 
                "num": 11
              }, 
              {
                "text": "And he poured some of the anointing oil on Aaron's head and anointed him, to consecrate him.", 
                "num": 12
              }, 
              {
                "text": "Then Moses brought Aaron's sons and put tunics on them, girded them with sashes, and put hats on them, as the LORD had commanded Moses.", 
                "num": 13
              }, 
              {
                "text": "And he brought the bull for the sin offering. Then Aaron and his sons laid their hands on the head of the bull for the sin offering,", 
                "num": 14
              }, 
              {
                "text": "and Moses killed it. Then he took the blood, and put some on the horns of the altar all around with his finger, and purified the altar. And he poured the blood at the base of the altar, and consecrated it, to make atonement for it.", 
                "num": 15
              }, 
              {
                "text": "Then he took all the fat that was on the entrails, the fatty lobe attached to the liver, and the two kidneys with their fat, and Moses burned them on the altar.", 
                "num": 16
              }, 
              {
                "text": "But the bull, its hide, its flesh, and its offal, he burned with fire outside the camp, as the LORD had commanded Moses.", 
                "num": 17
              }, 
              {
                "text": "Then he brought the ram as the burnt offering. And Aaron and his sons laid their hands on the head of the ram,", 
                "num": 18
              }, 
              {
                "text": "and Moses killed it. Then he sprinkled the blood all around on the altar.", 
                "num": 19
              }, 
              {
                "text": "And he cut the ram into pieces; and Moses burned the head, the pieces, and the fat.", 
                "num": 20
              }, 
              {
                "text": "Then he washed the entrails and the legs in water. And Moses burned the whole ram on the altar. It was a burnt sacrifice for a sweet aroma, an offering made by fire to the LORD, as the LORD had commanded Moses.", 
                "num": 21
              }, 
              {
                "text": "And he brought the second ram, the ram of consecration. Then Aaron and his sons laid their hands on the head of the ram,", 
                "num": 22
              }, 
              {
                "text": "and Moses killed it. Also he took some of its blood and put it on the tip of Aaron's right ear, on the thumb of his right hand, and on the big toe of his right foot.", 
                "num": 23
              }, 
              {
                "text": "Then he brought Aaron's sons. And Moses put some of the blood on the tips of their right ears, on the thumbs of their right hands, and on the big toes of their right feet. And Moses sprinkled the blood all around on the altar.", 
                "num": 24
              }, 
              {
                "text": "Then he took the fat and the fat tail, all the fat that was on the entrails, the fatty lobe attached to the liver, the two kidneys and their fat, and the right thigh;", 
                "num": 25
              }, 
              {
                "text": "and from the basket of unleavened bread that was before the LORD he took one unleavened cake, a cake of bread anointed with oil, and one wafer, and put them on the fat and on the right thigh;", 
                "num": 26
              }, 
              {
                "text": "and he put all these in Aaron's hands and in his sons' hands, and waved them as a wave offering before the LORD.", 
                "num": 27
              }, 
              {
                "text": "Then Moses took them from their hands and burned them on the altar, on the burnt offering. They were consecration offerings for a sweet aroma. That was an offering made by fire to the LORD.", 
                "num": 28
              }, 
              {
                "text": "And Moses took the breast and waved it as a wave offering before the LORD. It was Moses' part of the ram of consecration, as the LORD had commanded Moses.", 
                "num": 29
              }, 
              {
                "text": "Then Moses took some of the anointing oil and some of the blood which was on the altar, and sprinkled it on Aaron, on his garments, on his sons, and on the garments of his sons with him; and he consecrated Aaron, his garments, his sons, and the garments of his sons with him.", 
                "num": 30
              }, 
              {
                "text": "And Moses said to Aaron and his sons, \"Boil the flesh at the door of the tabernacle of meeting, and eat it there with the bread that is in the basket of consecration offerings, as I commanded, saying, \"Aaron and his sons shall eat it.'", 
                "num": 31
              }, 
              {
                "text": "What remains of the flesh and of the bread you shall burn with fire.", 
                "num": 32
              }, 
              {
                "text": "And you shall not go outside the door of the tabernacle of meeting for seven days, until the days of your consecration are ended. For seven days he shall consecrate you.", 
                "num": 33
              }, 
              {
                "text": "As he has done this day, so the LORD has commanded to do, to make atonement for you.", 
                "num": 34
              }, 
              {
                "text": "Therefore you shall stay at the door of the tabernacle of meeting day and night for seven days, and keep the charge of the LORD, so that you may not die; for so I have been commanded.\"", 
                "num": 35
              }, 
              {
                "text": "So Aaron and his sons did all the things that the LORD had commanded by the hand of Moses.", 
                "num": 36
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "It came to pass on the eighth day that Moses called Aaron and his sons and the elders of Israel.", 
                "num": 1
              }, 
              {
                "text": "And he said to Aaron, \"Take for yourself a young bull as a sin offering and a ram as a burnt offering, without blemish, and offer them before the LORD.", 
                "num": 2
              }, 
              {
                "text": "And to the children of Israel you shall speak, saying, \"Take a kid of the goats as a sin offering, and a calf and a lamb, both of the first year, without blemish, as a burnt offering,", 
                "num": 3
              }, 
              {
                "text": "also a bull and a ram as peace offerings, to sacrifice before the LORD, and a grain offering mixed with oil; for today the LORD will appear to you.\"'", 
                "num": 4
              }, 
              {
                "text": "So they brought what Moses commanded before the tabernacle of meeting. And all the congregation drew near and stood before the LORD.", 
                "num": 5
              }, 
              {
                "text": "Then Moses said, \"This is the thing which the LORD commanded you to do, and the glory of the LORD will appear to you.\"", 
                "num": 6
              }, 
              {
                "text": "And Moses said to Aaron, \"Go to the altar, offer your sin offering and your burnt offering, and make atonement for yourself and for the people. Offer the offering of the people, and make atonement for them, as the LORD commanded.\"", 
                "num": 7
              }, 
              {
                "text": "Aaron therefore went to the altar and killed the calf of the sin offering, which was for himself.", 
                "num": 8
              }, 
              {
                "text": "Then the sons of Aaron brought the blood to him. And he dipped his finger in the blood, put it on the horns of the altar, and poured the blood at the base of the altar.", 
                "num": 9
              }, 
              {
                "text": "But the fat, the kidneys, and the fatty lobe from the liver of the sin offering he burned on the altar, as the LORD had commanded Moses.", 
                "num": 10
              }, 
              {
                "text": "The flesh and the hide he burned with fire outside the camp.", 
                "num": 11
              }, 
              {
                "text": "And he killed the burnt offering; and Aaron's sons presented to him the blood, which he sprinkled all around on the altar.", 
                "num": 12
              }, 
              {
                "text": "Then they presented the burnt offering to him, with its pieces and head, and he burned them on the altar.", 
                "num": 13
              }, 
              {
                "text": "And he washed the entrails and the legs, and burned them with the burnt offering on the altar.", 
                "num": 14
              }, 
              {
                "text": "Then he brought the people's offering, and took the goat, which was the sin offering for the people, and killed it and offered it for sin, like the first one.", 
                "num": 15
              }, 
              {
                "text": "And he brought the burnt offering and offered it according to the prescribed manner.", 
                "num": 16
              }, 
              {
                "text": "Then he brought the grain offering, took a handful of it, and burned it on the altar, besides the burnt sacrifice of the morning.", 
                "num": 17
              }, 
              {
                "text": "He also killed the bull and the ram as sacrifices of peace offerings, which were for the people. And Aaron's sons presented to him the blood, which he sprinkled all around on the altar,", 
                "num": 18
              }, 
              {
                "text": "and the fat from the bull and the ram--the fatty tail, what covers the entrails and the kidneys, and the fatty lobe attached to the liver;", 
                "num": 19
              }, 
              {
                "text": "and they put the fat on the breasts. Then he burned the fat on the altar;", 
                "num": 20
              }, 
              {
                "text": "but the breasts and the right thigh Aaron waved as a wave offering before the LORD, as Moses had commanded.", 
                "num": 21
              }, 
              {
                "text": "Then Aaron lifted his hand toward the people, blessed them, and came down from offering the sin offering, the burnt offering, and peace offerings.", 
                "num": 22
              }, 
              {
                "text": "And Moses and Aaron went into the tabernacle of meeting, and came out and blessed the people. Then the glory of the LORD appeared to all the people,", 
                "num": 23
              }, 
              {
                "text": "and fire came out from before the LORD and consumed the burnt offering and the fat on the altar. When all the people saw it, they shouted and fell on their faces.", 
                "num": 24
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Then Nadab and Abihu, the sons of Aaron, each took his censer and put fire in it, put incense on it, and offered profane fire before the LORD, which He had not commanded them.", 
                "num": 1
              }, 
              {
                "text": "So fire went out from the LORD and devoured them, and they died before the LORD.", 
                "num": 2
              }, 
              {
                "text": "And Moses said to Aaron, \"This is what the LORD spoke, saying: \"By those who come near Me I must be regarded as holy; And before all the people I must be glorified.\"' So Aaron held his peace.", 
                "num": 3
              }, 
              {
                "text": "Then Moses called Mishael and Elzaphan, the sons of Uzziel the uncle of Aaron, and said to them, \"Come near, carry your brethren from before the sanctuary out of the camp.\"", 
                "num": 4
              }, 
              {
                "text": "So they went near and carried them by their tunics out of the camp, as Moses had said.", 
                "num": 5
              }, 
              {
                "text": "And Moses said to Aaron, and to Eleazar and Ithamar, his sons, \"Do not uncover your heads nor tear your clothes, lest you die, and wrath come upon all the people. But let your brethren, the whole house of Israel, bewail the burning which the LORD has kindled.", 
                "num": 6
              }, 
              {
                "text": "You shall not go out from the door of the tabernacle of meeting, lest you die, for the anointing oil of the LORD is upon you.\" And they did according to the word of Moses.", 
                "num": 7
              }, 
              {
                "text": "Then the LORD spoke to Aaron, saying:", 
                "num": 8
              }, 
              {
                "text": "\"Do not drink wine or intoxicating drink, you, nor your sons with you, when you go into the tabernacle of meeting, lest you die. It shall be a statute forever throughout your generations,", 
                "num": 9
              }, 
              {
                "text": "that you may distinguish between holy and unholy, and between unclean and clean,", 
                "num": 10
              }, 
              {
                "text": "and that you may teach the children of Israel all the statutes which the LORD has spoken to them by the hand of Moses.\"", 
                "num": 11
              }, 
              {
                "text": "And Moses spoke to Aaron, and to Eleazar and Ithamar, his sons who were left: \"Take the grain offering that remains of the offerings made by fire to the LORD, and eat it without leaven beside the altar; for it is most holy.", 
                "num": 12
              }, 
              {
                "text": "You shall eat it in a holy place, because it is your due and your sons' due, of the sacrifices made by fire to the LORD; for so I have been commanded.", 
                "num": 13
              }, 
              {
                "text": "The breast of the wave offering and the thigh of the heave offering you shall eat in a clean place, you, your sons, and your daughters with you; for they are your due and your sons' due, which are given from the sacrifices of peace offerings of the children of Israel.", 
                "num": 14
              }, 
              {
                "text": "The thigh of the heave offering and the breast of the wave offering they shall bring with the offerings of fat made by fire, to offer as a wave offering before the LORD. And it shall be yours and your sons' with you, by a statute forever, as the LORD has commanded.\"", 
                "num": 15
              }, 
              {
                "text": "Then Moses made careful inquiry about the goat of the sin offering, and there it was--burned up. And he was angry with Eleazar and Ithamar, the sons of Aaron who were left, saying,", 
                "num": 16
              }, 
              {
                "text": "\"Why have you not eaten the sin offering in a holy place, since it is most holy, and God has given it to you to bear the guilt of the congregation, to make atonement for them before the LORD?", 
                "num": 17
              }, 
              {
                "text": "See! Its blood was not brought inside the holy place; indeed you should have eaten it in a holy place, as I commanded.\"", 
                "num": 18
              }, 
              {
                "text": "And Aaron said to Moses, \"Look, this day they have offered their sin offering and their burnt offering before the LORD, and such things have befallen me! If I had eaten the sin offering today, would it have been accepted in the sight of the LORD?\"", 
                "num": 19
              }, 
              {
                "text": "So when Moses heard that, he was content.", 
                "num": 20
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses and Aaron, saying to them,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, saying, \"These are the animals which you may eat among all the animals that are on the earth:", 
                "num": 2
              }, 
              {
                "text": "Among the animals, whatever divides the hoof, having cloven hooves and chewing the cud--that you may eat.", 
                "num": 3
              }, 
              {
                "text": "Nevertheless these you shall not eat among those that chew the cud or those that have cloven hooves: the camel, because it chews the cud but does not have cloven hooves, is unclean to you;", 
                "num": 4
              }, 
              {
                "text": "the rock hyrax, because it chews the cud but does not have cloven hooves, is unclean to you;", 
                "num": 5
              }, 
              {
                "text": "the hare, because it chews the cud but does not have cloven hooves, is unclean to you;", 
                "num": 6
              }, 
              {
                "text": "and the swine, though it divides the hoof, having cloven hooves, yet does not chew the cud, is unclean to you.", 
                "num": 7
              }, 
              {
                "text": "Their flesh you shall not eat, and their carcasses you shall not touch. They are unclean to you.", 
                "num": 8
              }, 
              {
                "text": "\"These you may eat of all that are in the water: whatever in the water has fins and scales, whether in the seas or in the rivers--that you may eat.", 
                "num": 9
              }, 
              {
                "text": "But all in the seas or in the rivers that do not have fins and scales, all that move in the water or any living thing which is in the water, they are an abomination to you.", 
                "num": 10
              }, 
              {
                "text": "They shall be an abomination to you; you shall not eat their flesh, but you shall regard their carcasses as an abomination.", 
                "num": 11
              }, 
              {
                "text": "Whatever in the water does not have fins or scales--that shall be an abomination to you.", 
                "num": 12
              }, 
              {
                "text": "\"And these you shall regard as an abomination among the birds; they shall not be eaten, they are an abomination: the eagle, the vulture, the buzzard,", 
                "num": 13
              }, 
              {
                "text": "the kite, and the falcon after its kind;", 
                "num": 14
              }, 
              {
                "text": "every raven after its kind,", 
                "num": 15
              }, 
              {
                "text": "the ostrich, the short-eared owl, the sea gull, and the hawk after its kind;", 
                "num": 16
              }, 
              {
                "text": "the little owl, the fisher owl, and the screech owl;", 
                "num": 17
              }, 
              {
                "text": "the white owl, the jackdaw, and the carrion vulture;", 
                "num": 18
              }, 
              {
                "text": "the stork, the heron after its kind, the hoopoe, and the bat.", 
                "num": 19
              }, 
              {
                "text": "\"All flying insects that creep on all fours shall be an abomination to you.", 
                "num": 20
              }, 
              {
                "text": "Yet these you may eat of every flying insect that creeps on all fours: those which have jointed legs above their feet with which to leap on the earth.", 
                "num": 21
              }, 
              {
                "text": "These you may eat: the locust after its kind, the destroying locust after its kind, the cricket after its kind, and the grasshopper after its kind.", 
                "num": 22
              }, 
              {
                "text": "But all other flying insects which have four feet shall be an abomination to you.", 
                "num": 23
              }, 
              {
                "text": "\"By these you shall become unclean; whoever touches the carcass of any of them shall be unclean until evening;", 
                "num": 24
              }, 
              {
                "text": "whoever carries part of the carcass of any of them shall wash his clothes and be unclean until evening:", 
                "num": 25
              }, 
              {
                "text": "The carcass of any animal which divides the foot, but is not cloven-hoofed or does not chew the cud, is unclean to you. Everyone who touches it shall be unclean.", 
                "num": 26
              }, 
              {
                "text": "And whatever goes on its paws, among all kinds of animals that go on all fours, those are unclean to you. Whoever touches any such carcass shall be unclean until evening.", 
                "num": 27
              }, 
              {
                "text": "Whoever carries any such carcass shall wash his clothes and be unclean until evening. It is unclean to you.", 
                "num": 28
              }, 
              {
                "text": "\"These also shall be unclean to you among the creeping things that creep on the earth: the mole, the mouse, and the large lizard after its kind;", 
                "num": 29
              }, 
              {
                "text": "the gecko, the monitor lizard, the sand reptile, the sand lizard, and the chameleon.", 
                "num": 30
              }, 
              {
                "text": "These are unclean to you among all that creep. Whoever touches them when they are dead shall be unclean until evening.", 
                "num": 31
              }, 
              {
                "text": "Anything on which any of them falls, when they are dead shall be unclean, whether it is any item of wood or clothing or skin or sack, whatever item it is, in which any work is done, it must be put in water. And it shall be unclean until evening; then it shall be clean.", 
                "num": 32
              }, 
              {
                "text": "Any earthen vessel into which any of them falls you shall break; and whatever is in it shall be unclean:", 
                "num": 33
              }, 
              {
                "text": "in such a vessel, any edible food upon which water falls becomes unclean, and any drink that may be drunk from it becomes unclean.", 
                "num": 34
              }, 
              {
                "text": "And everything on which a part of any such carcass falls shall be unclean; whether it is an oven or cooking stove, it shall be broken down; for they are unclean, and shall be unclean to you.", 
                "num": 35
              }, 
              {
                "text": "Nevertheless a spring or a cistern, in which there is plenty of water, shall be clean, but whatever touches any such carcass becomes unclean.", 
                "num": 36
              }, 
              {
                "text": "And if a part of any such carcass falls on any planting seed which is to be sown, it remains clean.", 
                "num": 37
              }, 
              {
                "text": "But if water is put on the seed, and if a part of any such carcass falls on it, it becomes unclean to you.", 
                "num": 38
              }, 
              {
                "text": "\"And if any animal which you may eat dies, he who touches its carcass shall be unclean until evening.", 
                "num": 39
              }, 
              {
                "text": "He who eats of its carcass shall wash his clothes and be unclean until evening. He also who carries its carcass shall wash his clothes and be unclean until evening.", 
                "num": 40
              }, 
              {
                "text": "\"And every creeping thing that creeps on the earth shall be an abomination. It shall not be eaten.", 
                "num": 41
              }, 
              {
                "text": "Whatever crawls on its belly, whatever goes on all fours, or whatever has many feet among all creeping things that creep on the earth--these you shall not eat, for they are an abomination.", 
                "num": 42
              }, 
              {
                "text": "You shall not make yourselves abominable with any creeping thing that creeps; nor shall you make yourselves unclean with them, lest you be defiled by them.", 
                "num": 43
              }, 
              {
                "text": "For I am the LORD your God. You shall therefore consecrate yourselves, and you shall be holy; for I am holy. Neither shall you defile yourselves with any creeping thing that creeps on the earth.", 
                "num": 44
              }, 
              {
                "text": "For I am the LORD who brings you up out of the land of Egypt, to be your God. You shall therefore be holy, for I am holy.", 
                "num": 45
              }, 
              {
                "text": "\"This is the law of the animals and the birds and every living creature that moves in the waters, and of every creature that creeps on the earth,", 
                "num": 46
              }, 
              {
                "text": "to distinguish between the unclean and the clean, and between the animal that may be eaten and the animal that may not be eaten.\"'", 
                "num": 47
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"If a woman has conceived, and borne a male child, then she shall be unclean seven days; as in the days of her customary impurity she shall be unclean.", 
                "num": 2
              }, 
              {
                "text": "And on the eighth day the flesh of his foreskin shall be circumcised.", 
                "num": 3
              }, 
              {
                "text": "She shall then continue in the blood of her purification thirty-three days. She shall not touch any hallowed thing, nor come into the sanctuary until the days of her purification are fulfilled.", 
                "num": 4
              }, 
              {
                "text": "\"But if she bears a female child, then she shall be unclean two weeks, as in her customary impurity, and she shall continue in the blood of her purification sixty-six days.", 
                "num": 5
              }, 
              {
                "text": "\"When the days of her purification are fulfilled, whether for a son or a daughter, she shall bring to the priest a lamb of the first year as a burnt offering, and a young pigeon or a turtledove as a sin offering, to the door of the tabernacle of meeting.", 
                "num": 6
              }, 
              {
                "text": "Then he shall offer it before the LORD, and make atonement for her. And she shall be clean from the flow of her blood. This is the law for her who has borne a male or a female.", 
                "num": 7
              }, 
              {
                "text": "\"And if she is not able to bring a lamb, then she may bring two turtledoves or two young pigeons--one as a burnt offering and the other as a sin offering. So the priest shall make atonement for her, and she will be clean.\"'", 
                "num": 8
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses and Aaron, saying:", 
                "num": 1
              }, 
              {
                "text": "\"When a man has on the skin of his body a swelling, a scab, or a bright spot, and it becomes on the skin of his body like a leprous sore, then he shall be brought to Aaron the priest or to one of his sons the priests.", 
                "num": 2
              }, 
              {
                "text": "The priest shall examine the sore on the skin of the body; and if the hair on the sore has turned white, and the sore appears to be deeper than the skin of his body, it is a leprous sore. Then the priest shall examine him, and pronounce him unclean.", 
                "num": 3
              }, 
              {
                "text": "But if the bright spot is white on the skin of his body, and does not appear to be deeper than the skin, and its hair has not turned white, then the priest shall isolate the one who has the sore seven days.", 
                "num": 4
              }, 
              {
                "text": "And the priest shall examine him on the seventh day; and indeed if the sore appears to be as it was, and the sore has not spread on the skin, then the priest shall isolate him another seven days.", 
                "num": 5
              }, 
              {
                "text": "Then the priest shall examine him again on the seventh day; and indeed if the sore has faded, and the sore has not spread on the skin, then the priest shall pronounce him clean; it is only a scab, and he shall wash his clothes and be clean.", 
                "num": 6
              }, 
              {
                "text": "But if the scab should at all spread over the skin, after he has been seen by the priest for his cleansing, he shall be seen by the priest again.", 
                "num": 7
              }, 
              {
                "text": "And if the priest sees that the scab has indeed spread on the skin, then the priest shall pronounce him unclean. It is leprosy.", 
                "num": 8
              }, 
              {
                "text": "\"When the leprous sore is on a person, then he shall be brought to the priest.", 
                "num": 9
              }, 
              {
                "text": "And the priest shall examine him; and indeed if the swelling on the skin is white, and it has turned the hair white, and there is a spot of raw flesh in the swelling,", 
                "num": 10
              }, 
              {
                "text": "it is an old leprosy on the skin of his body. The priest shall pronounce him unclean, and shall not isolate him, for he is unclean.", 
                "num": 11
              }, 
              {
                "text": "\"And if leprosy breaks out all over the skin, and the leprosy covers all the skin of the one who has the sore, from his head to his foot, wherever the priest looks,", 
                "num": 12
              }, 
              {
                "text": "then the priest shall consider; and indeed if the leprosy has covered all his body, he shall pronounce him clean who has the sore. It has all turned white. He is clean.", 
                "num": 13
              }, 
              {
                "text": "But when raw flesh appears on him, he shall be unclean.", 
                "num": 14
              }, 
              {
                "text": "And the priest shall examine the raw flesh and pronounce him to be unclean; for the raw flesh is unclean. It is leprosy.", 
                "num": 15
              }, 
              {
                "text": "Or if the raw flesh changes and turns white again, he shall come to the priest.", 
                "num": 16
              }, 
              {
                "text": "And the priest shall examine him; and indeed if the sore has turned white, then the priest shall pronounce him clean who has the sore. He is clean.", 
                "num": 17
              }, 
              {
                "text": "\"If the body develops a boil in the skin, and it is healed,", 
                "num": 18
              }, 
              {
                "text": "and in the place of the boil there comes a white swelling or a bright spot, reddish-white, then it shall be shown to the priest;", 
                "num": 19
              }, 
              {
                "text": "and if, when the priest sees it, it indeed appears deeper than the skin, and its hair has turned white, the priest shall pronounce him unclean. It is a leprous sore which has broken out of the boil.", 
                "num": 20
              }, 
              {
                "text": "But if the priest examines it, and indeed there are no white hairs in it, and it is not deeper than the skin, but has faded, then the priest shall isolate him seven days;", 
                "num": 21
              }, 
              {
                "text": "and if it should at all spread over the skin, then the priest shall pronounce him unclean. It is a leprous sore.", 
                "num": 22
              }, 
              {
                "text": "But if the bright spot stays in one place, and has not spread, it is the scar of the boil; and the priest shall pronounce him clean.", 
                "num": 23
              }, 
              {
                "text": "\"Or if the body receives a burn on its skin by fire, and the raw flesh of the burn becomes a bright spot, reddish-white or white,", 
                "num": 24
              }, 
              {
                "text": "then the priest shall examine it; and indeed if the hair of the bright spot has turned white, and it appears deeper than the skin, it is leprosy broken out in the burn. Therefore the priest shall pronounce him unclean. It is a leprous sore.", 
                "num": 25
              }, 
              {
                "text": "But if the priest examines it, and indeed there are no white hairs in the bright spot, and it is not deeper than the skin, but has faded, then the priest shall isolate him seven days.", 
                "num": 26
              }, 
              {
                "text": "And the priest shall examine him on the seventh day. If it has at all spread over the skin, then the priest shall pronounce him unclean. It is a leprous sore.", 
                "num": 27
              }, 
              {
                "text": "But if the bright spot stays in one place, and has not spread on the skin, but has faded, it is a swelling from the burn. The priest shall pronounce him clean, for it is the scar from the burn.", 
                "num": 28
              }, 
              {
                "text": "\"If a man or woman has a sore on the head or the beard,", 
                "num": 29
              }, 
              {
                "text": "then the priest shall examine the sore; and indeed if it appears deeper than the skin, and there is in it thin yellow hair, then the priest shall pronounce him unclean. It is a scaly leprosy of the head or beard.", 
                "num": 30
              }, 
              {
                "text": "But if the priest examines the scaly sore, and indeed it does not appear deeper than the skin, and there is no black hair in it, then the priest shall isolate the one who has the scale seven days.", 
                "num": 31
              }, 
              {
                "text": "And on the seventh day the priest shall examine the sore; and indeed if the scale has not spread, and there is no yellow hair in it, and the scale does not appear deeper than the skin,", 
                "num": 32
              }, 
              {
                "text": "he shall shave himself, but the scale he shall not shave. And the priest shall isolate the one who has the scale another seven days.", 
                "num": 33
              }, 
              {
                "text": "On the seventh day the priest shall examine the scale; and indeed if the scale has not spread over the skin, and does not appear deeper than the skin, then the priest shall pronounce him clean. He shall wash his clothes and be clean.", 
                "num": 34
              }, 
              {
                "text": "But if the scale should at all spread over the skin after his cleansing,", 
                "num": 35
              }, 
              {
                "text": "then the priest shall examine him; and indeed if the scale has spread over the skin, the priest need not seek for yellow hair. He is unclean.", 
                "num": 36
              }, 
              {
                "text": "But if the scale appears to be at a standstill, and there is black hair grown up in it, the scale has healed. He is clean, and the priest shall pronounce him clean.", 
                "num": 37
              }, 
              {
                "text": "\"If a man or a woman has bright spots on the skin of the body, specifically white bright spots,", 
                "num": 38
              }, 
              {
                "text": "then the priest shall look; and indeed if the bright spots on the skin of the body are dull white, it is a white spot that grows on the skin. He is clean.", 
                "num": 39
              }, 
              {
                "text": "\"As for the man whose hair has fallen from his head, he is bald, but he is clean.", 
                "num": 40
              }, 
              {
                "text": "He whose hair has fallen from his forehead, he is bald on the forehead, but he is clean.", 
                "num": 41
              }, 
              {
                "text": "And if there is on the bald head or bald forehead a reddish-white sore, it is leprosy breaking out on his bald head or his bald forehead.", 
                "num": 42
              }, 
              {
                "text": "Then the priest shall examine it; and indeed if the swelling of the sore is reddish-white on his bald head or on his bald forehead, as the appearance of leprosy on the skin of the body,", 
                "num": 43
              }, 
              {
                "text": "he is a leprous man. He is unclean. The priest shall surely pronounce him unclean; his sore is on his head.", 
                "num": 44
              }, 
              {
                "text": "\"Now the leper on whom the sore is, his clothes shall be torn and his head bare; and he shall cover his mustache, and cry, \"Unclean! Unclean!'", 
                "num": 45
              }, 
              {
                "text": "He shall be unclean. All the days he has the sore he shall be unclean. He is unclean, and he shall dwell alone; his dwelling shall be outside the camp.", 
                "num": 46
              }, 
              {
                "text": "\"Also, if a garment has a leprous plague in it, whether it is a woolen garment or a linen garment,", 
                "num": 47
              }, 
              {
                "text": "whether it is in the warp or woof of linen or wool, whether in leather or in anything made of leather,", 
                "num": 48
              }, 
              {
                "text": "and if the plague is greenish or reddish in the garment or in the leather, whether in the warp or in the woof, or in anything made of leather, it is a leprous plague and shall be shown to the priest.", 
                "num": 49
              }, 
              {
                "text": "The priest shall examine the plague and isolate that which has the plague seven days.", 
                "num": 50
              }, 
              {
                "text": "And he shall examine the plague on the seventh day. If the plague has spread in the garment, either in the warp or in the woof, in the leather or in anything made of leather, the plague is an active leprosy. It is unclean.", 
                "num": 51
              }, 
              {
                "text": "He shall therefore burn that garment in which is the plague, whether warp or woof, in wool or in linen, or anything of leather, for it is an active leprosy; the garment shall be burned in the fire.", 
                "num": 52
              }, 
              {
                "text": "\"But if the priest examines it, and indeed the plague has not spread in the garment, either in the warp or in the woof, or in anything made of leather,", 
                "num": 53
              }, 
              {
                "text": "then the priest shall command that they wash the thing in which is the plague; and he shall isolate it another seven days.", 
                "num": 54
              }, 
              {
                "text": "Then the priest shall examine the plague after it has been washed; and indeed if the plague has not changed its color, though the plague has not spread, it is unclean, and you shall burn it in the fire; it continues eating away, whether the damage is outside or inside.", 
                "num": 55
              }, 
              {
                "text": "If the priest examines it, and indeed the plague has faded after washing it, then he shall tear it out of the garment, whether out of the warp or out of the woof, or out of the leather.", 
                "num": 56
              }, 
              {
                "text": "But if it appears again in the garment, either in the warp or in the woof, or in anything made of leather, it is a spreading plague; you shall burn with fire that in which is the plague.", 
                "num": 57
              }, 
              {
                "text": "And if you wash the garment, either warp or woof, or whatever is made of leather, if the plague has disappeared from it, then it shall be washed a second time, and shall be clean.", 
                "num": 58
              }, 
              {
                "text": "\"This is the law of the leprous plague in a garment of wool or linen, either in the warp or woof, or in anything made of leather, to pronounce it clean or to pronounce it unclean.\"", 
                "num": 59
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"This shall be the law of the leper for the day of his cleansing: He shall be brought to the priest.", 
                "num": 2
              }, 
              {
                "text": "And the priest shall go out of the camp, and the priest shall examine him; and indeed, if the leprosy is healed in the leper,", 
                "num": 3
              }, 
              {
                "text": "then the priest shall command to take for him who is to be cleansed two living and clean birds, cedar wood, scarlet, and hyssop.", 
                "num": 4
              }, 
              {
                "text": "And the priest shall command that one of the birds be killed in an earthen vessel over running water.", 
                "num": 5
              }, 
              {
                "text": "As for the living bird, he shall take it, the cedar wood and the scarlet and the hyssop, and dip them and the living bird in the blood of the bird that was killed over the running water.", 
                "num": 6
              }, 
              {
                "text": "And he shall sprinkle it seven times on him who is to be cleansed from the leprosy, and shall pronounce him clean, and shall let the living bird loose in the open field.", 
                "num": 7
              }, 
              {
                "text": "He who is to be cleansed shall wash his clothes, shave off all his hair, and wash himself in water, that he may be clean. After that he shall come into the camp, and shall stay outside his tent seven days.", 
                "num": 8
              }, 
              {
                "text": "But on the seventh day he shall shave all the hair off his head and his beard and his eyebrows--all his hair he shall shave off. He shall wash his clothes and wash his body in water, and he shall be clean.", 
                "num": 9
              }, 
              {
                "text": "\"And on the eighth day he shall take two male lambs without blemish, one ewe lamb of the first year without blemish, three-tenths of an ephah of fine flour mixed with oil as a grain offering, and one log of oil.", 
                "num": 10
              }, 
              {
                "text": "Then the priest who makes him clean shall present the man who is to be made clean, and those things, before the LORD, at the door of the tabernacle of meeting.", 
                "num": 11
              }, 
              {
                "text": "And the priest shall take one male lamb and offer it as a trespass offering, and the log of oil, and wave them as a wave offering before the LORD.", 
                "num": 12
              }, 
              {
                "text": "Then he shall kill the lamb in the place where he kills the sin offering and the burnt offering, in a holy place; for as the sin offering is the priest's, so is the trespass offering. It is most holy.", 
                "num": 13
              }, 
              {
                "text": "The priest shall take some of the blood of the trespass offering, and the priest shall put it on the tip of the right ear of him who is to be cleansed, on the thumb of his right hand, and on the big toe of his right foot.", 
                "num": 14
              }, 
              {
                "text": "And the priest shall take some of the log of oil, and pour it into the palm of his own left hand.", 
                "num": 15
              }, 
              {
                "text": "Then the priest shall dip his right finger in the oil that is in his left hand, and shall sprinkle some of the oil with his finger seven times before the LORD.", 
                "num": 16
              }, 
              {
                "text": "And of the rest of the oil in his hand, the priest shall put some on the tip of the right ear of him who is to be cleansed, on the thumb of his right hand, and on the big toe of his right foot, on the blood of the trespass offering.", 
                "num": 17
              }, 
              {
                "text": "The rest of the oil that is in the priest's hand he shall put on the head of him who is to be cleansed. So the priest shall make atonement for him before the LORD.", 
                "num": 18
              }, 
              {
                "text": "\"Then the priest shall offer the sin offering, and make atonement for him who is to be cleansed from his uncleanness. Afterward he shall kill the burnt offering.", 
                "num": 19
              }, 
              {
                "text": "And the priest shall offer the burnt offering and the grain offering on the altar. So the priest shall make atonement for him, and he shall be clean.", 
                "num": 20
              }, 
              {
                "text": "\"But if he is poor and cannot afford it, then he shall take one male lamb as a trespass offering to be waved, to make atonement for him, one-tenth of an ephah of fine flour mixed with oil as a grain offering, a log of oil,", 
                "num": 21
              }, 
              {
                "text": "and two turtledoves or two young pigeons, such as he is able to afford: one shall be a sin offering and the other a burnt offering.", 
                "num": 22
              }, 
              {
                "text": "He shall bring them to the priest on the eighth day for his cleansing, to the door of the tabernacle of meeting, before the LORD.", 
                "num": 23
              }, 
              {
                "text": "And the priest shall take the lamb of the trespass offering and the log of oil, and the priest shall wave them as a wave offering before the LORD.", 
                "num": 24
              }, 
              {
                "text": "Then he shall kill the lamb of the trespass offering, and the priest shall take some of the blood of the trespass offering and put it on the tip of the right ear of him who is to be cleansed, on the thumb of his right hand, and on the big toe of his right foot.", 
                "num": 25
              }, 
              {
                "text": "And the priest shall pour some of the oil into the palm of his own left hand.", 
                "num": 26
              }, 
              {
                "text": "Then the priest shall sprinkle with his right finger some of the oil that is in his left hand seven times before the LORD.", 
                "num": 27
              }, 
              {
                "text": "And the priest shall put some of the oil that is in his hand on the tip of the right ear of him who is to be cleansed, on the thumb of the right hand, and on the big toe of his right foot, on the place of the blood of the trespass offering.", 
                "num": 28
              }, 
              {
                "text": "The rest of the oil that is in the priest's hand he shall put on the head of him who is to be cleansed, to make atonement for him before the LORD.", 
                "num": 29
              }, 
              {
                "text": "And he shall offer one of the turtledoves or young pigeons, such as he can afford--", 
                "num": 30
              }, 
              {
                "text": "such as he is able to afford, the one as a sin offering and the other as a burnt offering, with the grain offering. So the priest shall make atonement for him who is to be cleansed before the LORD.", 
                "num": 31
              }, 
              {
                "text": "This is the law for one who had a leprous sore, who cannot afford the usual cleansing.\"", 
                "num": 32
              }, 
              {
                "text": "And the LORD spoke to Moses and Aaron, saying:", 
                "num": 33
              }, 
              {
                "text": "\"When you have come into the land of Canaan, which I give you as a possession, and I put the leprous plague in a house in the land of your possession,", 
                "num": 34
              }, 
              {
                "text": "and he who owns the house comes and tells the priest, saying, \"It seems to me that there is some plague in the house,'", 
                "num": 35
              }, 
              {
                "text": "then the priest shall command that they empty the house, before the priest goes into it to examine the plague, that all that is in the house may not be made unclean; and afterward the priest shall go in to examine the house.", 
                "num": 36
              }, 
              {
                "text": "And he shall examine the plague; and indeed if the plague is on the walls of the house with ingrained streaks, greenish or reddish, which appear to be deep in the wall,", 
                "num": 37
              }, 
              {
                "text": "then the priest shall go out of the house, to the door of the house, and shut up the house seven days.", 
                "num": 38
              }, 
              {
                "text": "And the priest shall come again on the seventh day and look; and indeed if the plague has spread on the walls of the house,", 
                "num": 39
              }, 
              {
                "text": "then the priest shall command that they take away the stones in which is the plague, and they shall cast them into an unclean place outside the city.", 
                "num": 40
              }, 
              {
                "text": "And he shall cause the house to be scraped inside, all around, and the dust that they scrape off they shall pour out in an unclean place outside the city.", 
                "num": 41
              }, 
              {
                "text": "Then they shall take other stones and put them in the place of those stones, and he shall take other mortar and plaster the house.", 
                "num": 42
              }, 
              {
                "text": "\"Now if the plague comes back and breaks out in the house, after he has taken away the stones, after he has scraped the house, and after it is plastered,", 
                "num": 43
              }, 
              {
                "text": "then the priest shall come and look; and indeed if the plague has spread in the house, it is an active leprosy in the house. It is unclean.", 
                "num": 44
              }, 
              {
                "text": "And he shall break down the house, its stones, its timber, and all the plaster of the house, and he shall carry them outside the city to an unclean place.", 
                "num": 45
              }, 
              {
                "text": "Moreover he who goes into the house at all while it is shut up shall be unclean until evening.", 
                "num": 46
              }, 
              {
                "text": "And he who lies down in the house shall wash his clothes, and he who eats in the house shall wash his clothes.", 
                "num": 47
              }, 
              {
                "text": "\"But if the priest comes in and examines it, and indeed the plague has not spread in the house after the house was plastered, then the priest shall pronounce the house clean, because the plague is healed.", 
                "num": 48
              }, 
              {
                "text": "And he shall take, to cleanse the house, two birds, cedar wood, scarlet, and hyssop.", 
                "num": 49
              }, 
              {
                "text": "Then he shall kill one of the birds in an earthen vessel over running water;", 
                "num": 50
              }, 
              {
                "text": "and he shall take the cedar wood, the hyssop, the scarlet, and the living bird, and dip them in the blood of the slain bird and in the running water, and sprinkle the house seven times.", 
                "num": 51
              }, 
              {
                "text": "And he shall cleanse the house with the blood of the bird and the running water and the living bird, with the cedar wood, the hyssop, and the scarlet.", 
                "num": 52
              }, 
              {
                "text": "Then he shall let the living bird loose outside the city in the open field, and make atonement for the house, and it shall be clean.", 
                "num": 53
              }, 
              {
                "text": "\"This is the law for any leprous sore and scale,", 
                "num": 54
              }, 
              {
                "text": "for the leprosy of a garment and of a house,", 
                "num": 55
              }, 
              {
                "text": "for a swelling and a scab and a bright spot,", 
                "num": 56
              }, 
              {
                "text": "to teach when it is unclean and when it is clean. This is the law of leprosy.\"", 
                "num": 57
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses and Aaron, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When any man has a discharge from his body, his discharge is unclean.", 
                "num": 2
              }, 
              {
                "text": "And this shall be his uncleanness in regard to his discharge--whether his body runs with his discharge, or his body is stopped up by his discharge, it is his uncleanness.", 
                "num": 3
              }, 
              {
                "text": "Every bed is unclean on which he who has the discharge lies, and everything on which he sits shall be unclean.", 
                "num": 4
              }, 
              {
                "text": "And whoever touches his bed shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 5
              }, 
              {
                "text": "He who sits on anything on which he who has the discharge sat shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 6
              }, 
              {
                "text": "And he who touches the body of him who has the discharge shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 7
              }, 
              {
                "text": "If he who has the discharge spits on him who is clean, then he shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 8
              }, 
              {
                "text": "Any saddle on which he who has the discharge rides shall be unclean.", 
                "num": 9
              }, 
              {
                "text": "Whoever touches anything that was under him shall be unclean until evening. He who carries any of those things shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 10
              }, 
              {
                "text": "And whomever the one who has the discharge touches, and has not rinsed his hands in water, he shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 11
              }, 
              {
                "text": "The vessel of earth that he who has the discharge touches shall be broken, and every vessel of wood shall be rinsed in water.", 
                "num": 12
              }, 
              {
                "text": "\"And when he who has a discharge is cleansed of his discharge, then he shall count for himself seven days for his cleansing, wash his clothes, and bathe his body in running water; then he shall be clean.", 
                "num": 13
              }, 
              {
                "text": "On the eighth day he shall take for himself two turtledoves or two young pigeons, and come before the LORD, to the door of the tabernacle of meeting, and give them to the priest.", 
                "num": 14
              }, 
              {
                "text": "Then the priest shall offer them, the one as a sin offering and the other as a burnt offering. So the priest shall make atonement for him before the LORD because of his discharge.", 
                "num": 15
              }, 
              {
                "text": "\"If any man has an emission of semen, then he shall wash all his body in water, and be unclean until evening.", 
                "num": 16
              }, 
              {
                "text": "And any garment and any leather on which there is semen, it shall be washed with water, and be unclean until evening.", 
                "num": 17
              }, 
              {
                "text": "Also, when a woman lies with a man, and there is an emission of semen, they shall bathe in water, and be unclean until evening.", 
                "num": 18
              }, 
              {
                "text": "\"If a woman has a discharge, and the discharge from her body is blood, she shall be set apart seven days; and whoever touches her shall be unclean until evening.", 
                "num": 19
              }, 
              {
                "text": "Everything that she lies on during her impurity shall be unclean; also everything that she sits on shall be unclean.", 
                "num": 20
              }, 
              {
                "text": "Whoever touches her bed shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 21
              }, 
              {
                "text": "And whoever touches anything that she sat on shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 22
              }, 
              {
                "text": "If anything is on her bed or on anything on which she sits, when he touches it, he shall be unclean until evening.", 
                "num": 23
              }, 
              {
                "text": "And if any man lies with her at all, so that her impurity is on him, he shall be unclean seven days; and every bed on which he lies shall be unclean.", 
                "num": 24
              }, 
              {
                "text": "\"If a woman has a discharge of blood for many days, other than at the time of her customary impurity, or if it runs beyond her usual time of impurity, all the days of her unclean discharge shall be as the days of her customary impurity. She shall be unclean.", 
                "num": 25
              }, 
              {
                "text": "Every bed on which she lies all the days of her discharge shall be to her as the bed of her impurity; and whatever she sits on shall be unclean, as the uncleanness of her impurity.", 
                "num": 26
              }, 
              {
                "text": "Whoever touches those things shall be unclean; he shall wash his clothes and bathe in water, and be unclean until evening.", 
                "num": 27
              }, 
              {
                "text": "\"But if she is cleansed of her discharge, then she shall count for herself seven days, and after that she shall be clean.", 
                "num": 28
              }, 
              {
                "text": "And on the eighth day she shall take for herself two turtledoves or two young pigeons, and bring them to the priest, to the door of the tabernacle of meeting.", 
                "num": 29
              }, 
              {
                "text": "Then the priest shall offer the one as a sin offering and the other as a burnt offering, and the priest shall make atonement for her before the LORD for the discharge of her uncleanness.", 
                "num": 30
              }, 
              {
                "text": "\"Thus you shall separate the children of Israel from their uncleanness, lest they die in their uncleanness when they defile My tabernacle that is among them.", 
                "num": 31
              }, 
              {
                "text": "This is the law for one who has a discharge, and for him who emits semen and is unclean thereby,", 
                "num": 32
              }, 
              {
                "text": "and for her who is indisposed because of her customary impurity, and for one who has a discharge, either man or woman, and for him who lies with her who is unclean.\"'", 
                "num": 33
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses after the death of the two sons of Aaron, when they offered profane fire before the LORD, and died;", 
                "num": 1
              }, 
              {
                "text": "and the LORD said to Moses: \"Tell Aaron your brother not to come at just any time into the Holy Place inside the veil, before the mercy seat which is on the ark, lest he die; for I will appear in the cloud above the mercy seat.", 
                "num": 2
              }, 
              {
                "text": "\"Thus Aaron shall come into the Holy Place: with the blood of a young bull as a sin offering, and of a ram as a burnt offering.", 
                "num": 3
              }, 
              {
                "text": "He shall put the holy linen tunic and the linen trousers on his body; he shall be girded with a linen sash, and with the linen turban he shall be attired. These are holy garments. Therefore he shall wash his body in water, and put them on.", 
                "num": 4
              }, 
              {
                "text": "And he shall take from the congregation of the children of Israel two kids of the goats as a sin offering, and one ram as a burnt offering.", 
                "num": 5
              }, 
              {
                "text": "\"Aaron shall offer the bull as a sin offering, which is for himself, and make atonement for himself and for his house.", 
                "num": 6
              }, 
              {
                "text": "He shall take the two goats and present them before the LORD at the door of the tabernacle of meeting.", 
                "num": 7
              }, 
              {
                "text": "Then Aaron shall cast lots for the two goats: one lot for the LORD and the other lot for the scapegoat.", 
                "num": 8
              }, 
              {
                "text": "And Aaron shall bring the goat on which the LORD's lot fell, and offer it as a sin offering.", 
                "num": 9
              }, 
              {
                "text": "But the goat on which the lot fell to be the scapegoat shall be presented alive before the LORD, to make atonement upon it, and to let it go as the scapegoat into the wilderness.", 
                "num": 10
              }, 
              {
                "text": "\"And Aaron shall bring the bull of the sin offering, which is for himself, and make atonement for himself and for his house, and shall kill the bull as the sin offering which is for himself.", 
                "num": 11
              }, 
              {
                "text": "Then he shall take a censer full of burning coals of fire from the altar before the LORD, with his hands full of sweet incense beaten fine, and bring it inside the veil.", 
                "num": 12
              }, 
              {
                "text": "And he shall put the incense on the fire before the LORD, that the cloud of incense may cover the mercy seat that is on the Testimony, lest he die.", 
                "num": 13
              }, 
              {
                "text": "He shall take some of the blood of the bull and sprinkle it with his finger on the mercy seat on the east side; and before the mercy seat he shall sprinkle some of the blood with his finger seven times.", 
                "num": 14
              }, 
              {
                "text": "\"Then he shall kill the goat of the sin offering, which is for the people, bring its blood inside the veil, do with that blood as he did with the blood of the bull, and sprinkle it on the mercy seat and before the mercy seat.", 
                "num": 15
              }, 
              {
                "text": "So he shall make atonement for the Holy Place, because of the uncleanness of the children of Israel, and because of their transgressions, for all their sins; and so he shall do for the tabernacle of meeting which remains among them in the midst of their uncleanness.", 
                "num": 16
              }, 
              {
                "text": "There shall be no man in the tabernacle of meeting when he goes in to make atonement in the Holy Place, until he comes out, that he may make atonement for himself, for his household, and for all the assembly of Israel.", 
                "num": 17
              }, 
              {
                "text": "And he shall go out to the altar that is before the LORD, and make atonement for it, and shall take some of the blood of the bull and some of the blood of the goat, and put it on the horns of the altar all around.", 
                "num": 18
              }, 
              {
                "text": "Then he shall sprinkle some of the blood on it with his finger seven times, cleanse it, and consecrate it from the uncleanness of the children of Israel.", 
                "num": 19
              }, 
              {
                "text": "\"And when he has made an end of atoning for the Holy Place, the tabernacle of meeting, and the altar, he shall bring the live goat.", 
                "num": 20
              }, 
              {
                "text": "Aaron shall lay both his hands on the head of the live goat, confess over it all the iniquities of the children of Israel, and all their transgressions, concerning all their sins, putting them on the head of the goat, and shall send it away into the wilderness by the hand of a suitable man.", 
                "num": 21
              }, 
              {
                "text": "The goat shall bear on itself all their iniquities to an uninhabited land; and he shall release the goat in the wilderness.", 
                "num": 22
              }, 
              {
                "text": "\"Then Aaron shall come into the tabernacle of meeting, shall take off the linen garments which he put on when he went into the Holy Place, and shall leave them there.", 
                "num": 23
              }, 
              {
                "text": "And he shall wash his body with water in a holy place, put on his garments, come out and offer his burnt offering and the burnt offering of the people, and make atonement for himself and for the people.", 
                "num": 24
              }, 
              {
                "text": "The fat of the sin offering he shall burn on the altar.", 
                "num": 25
              }, 
              {
                "text": "And he who released the goat as the scapegoat shall wash his clothes and bathe his body in water, and afterward he may come into the camp.", 
                "num": 26
              }, 
              {
                "text": "The bull for the sin offering and the goat for the sin offering, whose blood was brought in to make atonement in the Holy Place, shall be carried outside the camp. And they shall burn in the fire their skins, their flesh, and their offal.", 
                "num": 27
              }, 
              {
                "text": "Then he who burns them shall wash his clothes and bathe his body in water, and afterward he may come into the camp.", 
                "num": 28
              }, 
              {
                "text": "\"This shall be a statute forever for you: In the seventh month, on the tenth day of the month, you shall afflict your souls, and do no work at all, whether a native of your own country or a stranger who dwells among you.", 
                "num": 29
              }, 
              {
                "text": "For on that day the priest shall make atonement for you, to cleanse you, that you may be clean from all your sins before the LORD.", 
                "num": 30
              }, 
              {
                "text": "It is a sabbath of solemn rest for you, and you shall afflict your souls. It is a statute forever.", 
                "num": 31
              }, 
              {
                "text": "And the priest, who is anointed and consecrated to minister as priest in his father's place, shall make atonement, and put on the linen clothes, the holy garments;", 
                "num": 32
              }, 
              {
                "text": "then he shall make atonement for the Holy Sanctuary, and he shall make atonement for the tabernacle of meeting and for the altar, and he shall make atonement for the priests and for all the people of the assembly.", 
                "num": 33
              }, 
              {
                "text": "This shall be an everlasting statute for you, to make atonement for the children of Israel, for all their sins, once a year.\" And he did as the LORD commanded Moses.", 
                "num": 34
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to Aaron, to his sons, and to all the children of Israel, and say to them, \"This is the thing which the LORD has commanded, saying:", 
                "num": 2
              }, 
              {
                "text": "\"Whatever man of the house of Israel who kills an ox or lamb or goat in the camp, or who kills it outside the camp,", 
                "num": 3
              }, 
              {
                "text": "and does not bring it to the door of the tabernacle of meeting to offer an offering to the LORD before the tabernacle of the LORD, the guilt of bloodshed shall be imputed to that man. He has shed blood; and that man shall be cut off from among his people,", 
                "num": 4
              }, 
              {
                "text": "to the end that the children of Israel may bring their sacrifices which they offer in the open field, that they may bring them to the LORD at the door of the tabernacle of meeting, to the priest, and offer them as peace offerings to the LORD.", 
                "num": 5
              }, 
              {
                "text": "And the priest shall sprinkle the blood on the altar of the LORD at the door of the tabernacle of meeting, and burn the fat for a sweet aroma to the LORD.", 
                "num": 6
              }, 
              {
                "text": "They shall no more offer their sacrifices to demons, after whom they have played the harlot. This shall be a statute forever for them throughout their generations.\"'", 
                "num": 7
              }, 
              {
                "text": "\"Also you shall say to them: \"Whatever man of the house of Israel, or of the strangers who dwell among you, who offers a burnt offering or sacrifice,", 
                "num": 8
              }, 
              {
                "text": "and does not bring it to the door of the tabernacle of meeting, to offer it to the LORD, that man shall be cut off from among his people.", 
                "num": 9
              }, 
              {
                "text": "\"And whatever man of the house of Israel, or of the strangers who dwell among you, who eats any blood, I will set My face against that person who eats blood, and will cut him off from among his people.", 
                "num": 10
              }, 
              {
                "text": "For the life of the flesh is in the blood, and I have given it to you upon the altar to make atonement for your souls; for it is the blood that makes atonement for the soul.'", 
                "num": 11
              }, 
              {
                "text": "Therefore I said to the children of Israel, \"No one among you shall eat blood, nor shall any stranger who dwells among you eat blood.'", 
                "num": 12
              }, 
              {
                "text": "\"Whatever man of the children of Israel, or of the strangers who dwell among you, who hunts and catches any animal or bird that may be eaten, he shall pour out its blood and cover it with dust;", 
                "num": 13
              }, 
              {
                "text": "for it is the life of all flesh. Its blood sustains its life. Therefore I said to the children of Israel, \"You shall not eat the blood of any flesh, for the life of all flesh is its blood. Whoever eats it shall be cut off.'", 
                "num": 14
              }, 
              {
                "text": "\"And every person who eats what died naturally or what was torn by beasts, whether he is a native of your own country or a stranger, he shall both wash his clothes and bathe in water, and be unclean until evening. Then he shall be clean.", 
                "num": 15
              }, 
              {
                "text": "But if he does not wash them or bathe his body, then he shall bear his guilt.\"", 
                "num": 16
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"I am the LORD your God.", 
                "num": 2
              }, 
              {
                "text": "According to the doings of the land of Egypt, where you dwelt, you shall not do; and according to the doings of the land of Canaan, where I am bringing you, you shall not do; nor shall you walk in their ordinances.", 
                "num": 3
              }, 
              {
                "text": "You shall observe My judgments and keep My ordinances, to walk in them: I am the LORD your God.", 
                "num": 4
              }, 
              {
                "text": "You shall therefore keep My statutes and My judgments, which if a man does, he shall live by them: I am the LORD.", 
                "num": 5
              }, 
              {
                "text": "\"None of you shall approach anyone who is near of kin to him, to uncover his nakedness: I am the LORD.", 
                "num": 6
              }, 
              {
                "text": "The nakedness of your father or the nakedness of your mother you shall not uncover. She is your mother; you shall not uncover her nakedness.", 
                "num": 7
              }, 
              {
                "text": "The nakedness of your father's wife you shall not uncover; it is your father's nakedness.", 
                "num": 8
              }, 
              {
                "text": "The nakedness of your sister, the daughter of your father, or the daughter of your mother, whether born at home or elsewhere, their nakedness you shall not uncover.", 
                "num": 9
              }, 
              {
                "text": "The nakedness of your son's daughter or your daughter's daughter, their nakedness you shall not uncover; for theirs is your own nakedness.", 
                "num": 10
              }, 
              {
                "text": "The nakedness of your father's wife's daughter, begotten by your father--she is your sister--you shall not uncover her nakedness.", 
                "num": 11
              }, 
              {
                "text": "You shall not uncover the nakedness of your father's sister; she is near of kin to your father.", 
                "num": 12
              }, 
              {
                "text": "You shall not uncover the nakedness of your mother's sister, for she is near of kin to your mother.", 
                "num": 13
              }, 
              {
                "text": "You shall not uncover the nakedness of your father's brother. You shall not approach his wife; she is your aunt.", 
                "num": 14
              }, 
              {
                "text": "You shall not uncover the nakedness of your daughter-in-law--she is your son's wife--you shall not uncover her nakedness.", 
                "num": 15
              }, 
              {
                "text": "You shall not uncover the nakedness of your brother's wife; it is your brother's nakedness.", 
                "num": 16
              }, 
              {
                "text": "You shall not uncover the nakedness of a woman and her daughter, nor shall you take her son's daughter or her daughter's daughter, to uncover her nakedness. They are near of kin to her. It is wickedness.", 
                "num": 17
              }, 
              {
                "text": "Nor shall you take a woman as a rival to her sister, to uncover her nakedness while the other is alive.", 
                "num": 18
              }, 
              {
                "text": "\"Also you shall not approach a woman to uncover her nakedness as long as she is in her customary impurity.", 
                "num": 19
              }, 
              {
                "text": "Moreover you shall not lie carnally with your neighbor's wife, to defile yourself with her.", 
                "num": 20
              }, 
              {
                "text": "And you shall not let any of your descendants pass through the fire to Molech, nor shall you profane the name of your God: I am the LORD.", 
                "num": 21
              }, 
              {
                "text": "You shall not lie with a male as with a woman. It is an abomination.", 
                "num": 22
              }, 
              {
                "text": "Nor shall you mate with any animal, to defile yourself with it. Nor shall any woman stand before an animal to mate with it. It is perversion.", 
                "num": 23
              }, 
              {
                "text": "\"Do not defile yourselves with any of these things; for by all these the nations are defiled, which I am casting out before you.", 
                "num": 24
              }, 
              {
                "text": "For the land is defiled; therefore I visit the punishment of its iniquity upon it, and the land vomits out its inhabitants.", 
                "num": 25
              }, 
              {
                "text": "You shall therefore keep My statutes and My judgments, and shall not commit any of these abominations, either any of your own nation or any stranger who dwells among you", 
                "num": 26
              }, 
              {
                "text": "(for all these abominations the men of the land have done, who were before you, and thus the land is defiled),", 
                "num": 27
              }, 
              {
                "text": "lest the land vomit you out also when you defile it, as it vomited out the nations that were before you.", 
                "num": 28
              }, 
              {
                "text": "For whoever commits any of these abominations, the persons who commit them shall be cut off from among their people.", 
                "num": 29
              }, 
              {
                "text": "\"Therefore you shall keep My ordinance, so that you do not commit any of these abominable customs which were committed before you, and that you do not defile yourselves by them: I am the LORD your God.\"'", 
                "num": 30
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to all the congregation of the children of Israel, and say to them: \"You shall be holy, for I the LORD your God am holy.", 
                "num": 2
              }, 
              {
                "text": "\"Every one of you shall revere his mother and his father, and keep My Sabbaths: I am the LORD your God.", 
                "num": 3
              }, 
              {
                "text": "\"Do not turn to idols, nor make for yourselves molded gods: I am the LORD your God.", 
                "num": 4
              }, 
              {
                "text": "\"And if you offer a sacrifice of a peace offering to the LORD, you shall offer it of your own free will.", 
                "num": 5
              }, 
              {
                "text": "It shall be eaten the same day you offer it, and on the next day. And if any remains until the third day, it shall be burned in the fire.", 
                "num": 6
              }, 
              {
                "text": "And if it is eaten at all on the third day, it is an abomination. It shall not be accepted.", 
                "num": 7
              }, 
              {
                "text": "Therefore everyone who eats it shall bear his iniquity, because he has profaned the hallowed offering of the LORD; and that person shall be cut off from his people.", 
                "num": 8
              }, 
              {
                "text": "\"When you reap the harvest of your land, you shall not wholly reap the corners of your field, nor shall you gather the gleanings of your harvest.", 
                "num": 9
              }, 
              {
                "text": "And you shall not glean your vineyard, nor shall you gather every grape of your vineyard; you shall leave them for the poor and the stranger: I am the LORD your God.", 
                "num": 10
              }, 
              {
                "text": "\"You shall not steal, nor deal falsely, nor lie to one another.", 
                "num": 11
              }, 
              {
                "text": "And you shall not swear by My name falsely, nor shall you profane the name of your God: I am the LORD.", 
                "num": 12
              }, 
              {
                "text": "\"You shall not cheat your neighbor, nor rob him. The wages of him who is hired shall not remain with you all night until morning.", 
                "num": 13
              }, 
              {
                "text": "You shall not curse the deaf, nor put a stumbling block before the blind, but shall fear your God: I am the LORD.", 
                "num": 14
              }, 
              {
                "text": "\"You shall do no injustice in judgment. You shall not be partial to the poor, nor honor the person of the mighty. In righteousness you shall judge your neighbor.", 
                "num": 15
              }, 
              {
                "text": "You shall not go about as a talebearer among your people; nor shall you take a stand against the life of your neighbor: I am the LORD.", 
                "num": 16
              }, 
              {
                "text": "\"You shall not hate your brother in your heart. You shall surely rebuke your neighbor, and not bear sin because of him.", 
                "num": 17
              }, 
              {
                "text": "You shall not take vengeance, nor bear any grudge against the children of your people, but you shall love your neighbor as yourself: I am the LORD.", 
                "num": 18
              }, 
              {
                "text": "\"You shall keep My statutes. You shall not let your livestock breed with another kind. You shall not sow your field with mixed seed. Nor shall a garment of mixed linen and wool come upon you.", 
                "num": 19
              }, 
              {
                "text": "\"Whoever lies carnally with a woman who is betrothed to a man as a concubine, and who has not at all been redeemed nor given her freedom, for this there shall be scourging; but they shall not be put to death, because she was not free.", 
                "num": 20
              }, 
              {
                "text": "And he shall bring his trespass offering to the LORD, to the door of the tabernacle of meeting, a ram as a trespass offering.", 
                "num": 21
              }, 
              {
                "text": "The priest shall make atonement for him with the ram of the trespass offering before the LORD for his sin which he has committed. And the sin which he has committed shall be forgiven him.", 
                "num": 22
              }, 
              {
                "text": "\"When you come into the land, and have planted all kinds of trees for food, then you shall count their fruit as uncircumcised. Three years it shall be as uncircumcised to you. It shall not be eaten.", 
                "num": 23
              }, 
              {
                "text": "But in the fourth year all its fruit shall be holy, a praise to the LORD.", 
                "num": 24
              }, 
              {
                "text": "And in the fifth year you may eat its fruit, that it may yield to you its increase: I am the LORD your God.", 
                "num": 25
              }, 
              {
                "text": "\"You shall not eat anything with the blood, nor shall you practice divination or soothsaying.", 
                "num": 26
              }, 
              {
                "text": "You shall not shave around the sides of your head, nor shall you disfigure the edges of your beard.", 
                "num": 27
              }, 
              {
                "text": "You shall not make any cuttings in your flesh for the dead, nor tattoo any marks on you: I am the LORD.", 
                "num": 28
              }, 
              {
                "text": "\"Do not prostitute your daughter, to cause her to be a harlot, lest the land fall into harlotry, and the land become full of wickedness.", 
                "num": 29
              }, 
              {
                "text": "\"You shall keep My Sabbaths and reverence My sanctuary: I am the LORD.", 
                "num": 30
              }, 
              {
                "text": "\"Give no regard to mediums and familiar spirits; do not seek after them, to be defiled by them: I am the LORD your God.", 
                "num": 31
              }, 
              {
                "text": "\"You shall rise before the gray headed and honor the presence of an old man, and fear your God: I am the LORD.", 
                "num": 32
              }, 
              {
                "text": "\"And if a stranger dwells with you in your land, you shall not mistreat him.", 
                "num": 33
              }, 
              {
                "text": "The stranger who dwells among you shall be to you as one born among you, and you shall love him as yourself; for you were strangers in the land of Egypt: I am the LORD your God.", 
                "num": 34
              }, 
              {
                "text": "\"You shall do no injustice in judgment, in measurement of length, weight, or volume.", 
                "num": 35
              }, 
              {
                "text": "You shall have honest scales, honest weights, an honest ephah, and an honest hin: I am the LORD your God, who brought you out of the land of Egypt.", 
                "num": 36
              }, 
              {
                "text": "\"Therefore you shall observe all My statutes and all My judgments, and perform them: I am the LORD.\"'", 
                "num": 37
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Again, you shall say to the children of Israel: \"Whoever of the children of Israel, or of the strangers who dwell in Israel, who gives any of his descendants to Molech, he shall surely be put to death. The people of the land shall stone him with stones.", 
                "num": 2
              }, 
              {
                "text": "I will set My face against that man, and will cut him off from his people, because he has given some of his descendants to Molech, to defile My sanctuary and profane My holy name.", 
                "num": 3
              }, 
              {
                "text": "And if the people of the land should in any way hide their eyes from the man, when he gives some of his descendants to Molech, and they do not kill him,", 
                "num": 4
              }, 
              {
                "text": "then I will set My face against that man and against his family; and I will cut him off from his people, and all who prostitute themselves with him to commit harlotry with Molech.", 
                "num": 5
              }, 
              {
                "text": "\"And the person who turns to mediums and familiar spirits, to prostitute himself with them, I will set My face against that person and cut him off from his people.", 
                "num": 6
              }, 
              {
                "text": "Consecrate yourselves therefore, and be holy, for I am the LORD your God.", 
                "num": 7
              }, 
              {
                "text": "And you shall keep My statutes, and perform them: I am the LORD who sanctifies you.", 
                "num": 8
              }, 
              {
                "text": "\"For everyone who curses his father or his mother shall surely be put to death. He has cursed his father or his mother. His blood shall be upon him.", 
                "num": 9
              }, 
              {
                "text": "\"The man who commits adultery with another man's wife, he who commits adultery with his neighbor's wife, the adulterer and the adulteress, shall surely be put to death.", 
                "num": 10
              }, 
              {
                "text": "The man who lies with his father's wife has uncovered his father's nakedness; both of them shall surely be put to death. Their blood shall be upon them.", 
                "num": 11
              }, 
              {
                "text": "If a man lies with his daughter-in-law, both of them shall surely be put to death. They have committed perversion. Their blood shall be upon them.", 
                "num": 12
              }, 
              {
                "text": "If a man lies with a male as he lies with a woman, both of them have committed an abomination. They shall surely be put to death. Their blood shall be upon them.", 
                "num": 13
              }, 
              {
                "text": "If a man marries a woman and her mother, it is wickedness. They shall be burned with fire, both he and they, that there may be no wickedness among you.", 
                "num": 14
              }, 
              {
                "text": "If a man mates with an animal, he shall surely be put to death, and you shall kill the animal.", 
                "num": 15
              }, 
              {
                "text": "If a woman approaches any animal and mates with it, you shall kill the woman and the animal. They shall surely be put to death. Their blood is upon them.", 
                "num": 16
              }, 
              {
                "text": "\"If a man takes his sister, his father's daughter or his mother's daughter, and sees her nakedness and she sees his nakedness, it is a wicked thing. And they shall be cut off in the sight of their people. He has uncovered his sister's nakedness. He shall bear his guilt.", 
                "num": 17
              }, 
              {
                "text": "If a man lies with a woman during her sickness and uncovers her nakedness, he has exposed her flow, and she has uncovered the flow of her blood. Both of them shall be cut off from their people.", 
                "num": 18
              }, 
              {
                "text": "\"You shall not uncover the nakedness of your mother's sister nor of your father's sister, for that would uncover his near of kin. They shall bear their guilt.", 
                "num": 19
              }, 
              {
                "text": "If a man lies with his uncle's wife, he has uncovered his uncle's nakedness. They shall bear their sin; they shall die childless.", 
                "num": 20
              }, 
              {
                "text": "If a man takes his brother's wife, it is an unclean thing. He has uncovered his brother's nakedness. They shall be childless.", 
                "num": 21
              }, 
              {
                "text": "\"You shall therefore keep all My statutes and all My judgments, and perform them, that the land where I am bringing you to dwell may not vomit you out.", 
                "num": 22
              }, 
              {
                "text": "And you shall not walk in the statutes of the nation which I am casting out before you; for they commit all these things, and therefore I abhor them.", 
                "num": 23
              }, 
              {
                "text": "But I have said to you, \"You shall inherit their land, and I will give it to you to possess, a land flowing with milk and honey.\" I am the LORD your God, who has separated you from the peoples.", 
                "num": 24
              }, 
              {
                "text": "You shall therefore distinguish between clean animals and unclean, between unclean birds and clean, and you shall not make yourselves abominable by beast or by bird, or by any kind of living thing that creeps on the ground, which I have separated from you as unclean.", 
                "num": 25
              }, 
              {
                "text": "And you shall be holy to Me, for I the LORD am holy, and have separated you from the peoples, that you should be Mine.", 
                "num": 26
              }, 
              {
                "text": "\"A man or a woman who is a medium, or who has familiar spirits, shall surely be put to death; they shall stone them with stones. Their blood shall be upon them.\"'", 
                "num": 27
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And the LORD said to Moses, \"Speak to the priests, the sons of Aaron, and say to them: \"None shall defile himself for the dead among his people,", 
                "num": 1
              }, 
              {
                "text": "except for his relatives who are nearest to him: his mother, his father, his son, his daughter, and his brother;", 
                "num": 2
              }, 
              {
                "text": "also his virgin sister who is near to him, who has had no husband, for her he may defile himself.", 
                "num": 3
              }, 
              {
                "text": "Otherwise he shall not defile himself, being a chief man among his people, to profane himself.", 
                "num": 4
              }, 
              {
                "text": "\"They shall not make any bald place on their heads, nor shall they shave the edges of their beards nor make any cuttings in their flesh.", 
                "num": 5
              }, 
              {
                "text": "They shall be holy to their God and not profane the name of their God, for they offer the offerings of the LORD made by fire, and the bread of their God; therefore they shall be holy.", 
                "num": 6
              }, 
              {
                "text": "They shall not take a wife who is a harlot or a defiled woman, nor shall they take a woman divorced from her husband; for the priest is holy to his God.", 
                "num": 7
              }, 
              {
                "text": "Therefore you shall consecrate him, for he offers the bread of your God. He shall be holy to you, for I the LORD, who sanctify you, am holy.", 
                "num": 8
              }, 
              {
                "text": "The daughter of any priest, if she profanes herself by playing the harlot, she profanes her father. She shall be burned with fire.", 
                "num": 9
              }, 
              {
                "text": "\"He who is the high priest among his brethren, on whose head the anointing oil was poured and who is consecrated to wear the garments, shall not uncover his head nor tear his clothes;", 
                "num": 10
              }, 
              {
                "text": "nor shall he go near any dead body, nor defile himself for his father or his mother;", 
                "num": 11
              }, 
              {
                "text": "nor shall he go out of the sanctuary, nor profane the sanctuary of his God; for the consecration of the anointing oil of his God is upon him: I am the LORD.", 
                "num": 12
              }, 
              {
                "text": "And he shall take a wife in her virginity.", 
                "num": 13
              }, 
              {
                "text": "A widow or a divorced woman or a defiled woman or a harlot--these he shall not marry; but he shall take a virgin of his own people as wife.", 
                "num": 14
              }, 
              {
                "text": "Nor shall he profane his posterity among his people, for I the LORD sanctify him.\"'", 
                "num": 15
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 16
              }, 
              {
                "text": "\"Speak to Aaron, saying: \"No man of your descendants in succeeding generations, who has any defect, may approach to offer the bread of his God.", 
                "num": 17
              }, 
              {
                "text": "For any man who has a defect shall not approach: a man blind or lame, who has a marred face or any limb too long,", 
                "num": 18
              }, 
              {
                "text": "a man who has a broken foot or broken hand,", 
                "num": 19
              }, 
              {
                "text": "or is a hunchback or a dwarf, or a man who has a defect in his eye, or eczema or scab, or is a eunuch.", 
                "num": 20
              }, 
              {
                "text": "No man of the descendants of Aaron the priest, who has a defect, shall come near to offer the offerings made by fire to the LORD. He has a defect; he shall not come near to offer the bread of his God.", 
                "num": 21
              }, 
              {
                "text": "He may eat the bread of his God, both the most holy and the holy;", 
                "num": 22
              }, 
              {
                "text": "only he shall not go near the veil or approach the altar, because he has a defect, lest he profane My sanctuaries; for I the LORD sanctify them.\"'", 
                "num": 23
              }, 
              {
                "text": "And Moses told it to Aaron and his sons, and to all the children of Israel.", 
                "num": 24
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to Aaron and his sons, that they separate themselves from the holy things of the children of Israel, and that they do not profane My holy name by what they dedicate to Me: I am the LORD.", 
                "num": 2
              }, 
              {
                "text": "Say to them: \"Whoever of all your descendants throughout your generations, who goes near the holy things which the children of Israel dedicate to the LORD, while he has uncleanness upon him, that person shall be cut off from My presence: I am the LORD.", 
                "num": 3
              }, 
              {
                "text": "\"Whatever man of the descendants of Aaron, who is a leper or has a discharge, shall not eat the holy offerings until he is clean. And whoever touches anything made unclean by a corpse, or a man who has had an emission of semen,", 
                "num": 4
              }, 
              {
                "text": "or whoever touches any creeping thing by which he would be made unclean, or any person by whom he would become unclean, whatever his uncleanness may be--", 
                "num": 5
              }, 
              {
                "text": "the person who has touched any such thing shall be unclean until evening, and shall not eat the holy offerings unless he washes his body with water.", 
                "num": 6
              }, 
              {
                "text": "And when the sun goes down he shall be clean; and afterward he may eat the holy offerings, because it is his food.", 
                "num": 7
              }, 
              {
                "text": "Whatever dies naturally or is torn by beasts he shall not eat, to defile himself with it: I am the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"They shall therefore keep My ordinance, lest they bear sin for it and die thereby, if they profane it: I the LORD sanctify them.", 
                "num": 9
              }, 
              {
                "text": "\"No outsider shall eat the holy offering; one who dwells with the priest, or a hired servant, shall not eat the holy thing.", 
                "num": 10
              }, 
              {
                "text": "But if the priest buys a person with his money, he may eat it; and one who is born in his house may eat his food.", 
                "num": 11
              }, 
              {
                "text": "If the priest's daughter is married to an outsider, she may not eat of the holy offerings.", 
                "num": 12
              }, 
              {
                "text": "But if the priest's daughter is a widow or divorced, and has no child, and has returned to her father's house as in her youth, she may eat her father's food; but no outsider shall eat it.", 
                "num": 13
              }, 
              {
                "text": "\"And if a man eats the holy offering unintentionally, then he shall restore a holy offering to the priest, and add one-fifth to it.", 
                "num": 14
              }, 
              {
                "text": "They shall not profane the holy offerings of the children of Israel, which they offer to the LORD,", 
                "num": 15
              }, 
              {
                "text": "or allow them to bear the guilt of trespass when they eat their holy offerings; for I the LORD sanctify them.\"'", 
                "num": 16
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Speak to Aaron and his sons, and to all the children of Israel, and say to them: \"Whatever man of the house of Israel, or of the strangers in Israel, who offers his sacrifice for any of his vows or for any of his freewill offerings, which they offer to the LORD as a burnt offering--", 
                "num": 18
              }, 
              {
                "text": "you shall offer of your own free will a male without blemish from the cattle, from the sheep, or from the goats.", 
                "num": 19
              }, 
              {
                "text": "Whatever has a defect, you shall not offer, for it shall not be acceptable on your behalf.", 
                "num": 20
              }, 
              {
                "text": "And whoever offers a sacrifice of a peace offering to the LORD, to fulfill his vow, or a freewill offering from the cattle or the sheep, it must be perfect to be accepted; there shall be no defect in it.", 
                "num": 21
              }, 
              {
                "text": "Those that are blind or broken or maimed, or have an ulcer or eczema or scabs, you shall not offer to the LORD, nor make an offering by fire of them on the altar to the LORD.", 
                "num": 22
              }, 
              {
                "text": "Either a bull or a lamb that has any limb too long or too short you may offer as a freewill offering, but for a vow it shall not be accepted.", 
                "num": 23
              }, 
              {
                "text": "\"You shall not offer to the LORD what is bruised or crushed, or torn or cut; nor shall you make any offering of them in your land.", 
                "num": 24
              }, 
              {
                "text": "Nor from a foreigner's hand shall you offer any of these as the bread of your God, because their corruption is in them, and defects are in them. They shall not be accepted on your behalf.\"'", 
                "num": 25
              }, 
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 26
              }, 
              {
                "text": "\"When a bull or a sheep or a goat is born, it shall be seven days with its mother; and from the eighth day and thereafter it shall be accepted as an offering made by fire to the LORD.", 
                "num": 27
              }, 
              {
                "text": "Whether it is a cow or ewe, do not kill both her and her young on the same day.", 
                "num": 28
              }, 
              {
                "text": "And when you offer a sacrifice of thanksgiving to the LORD, offer it of your own free will.", 
                "num": 29
              }, 
              {
                "text": "On the same day it shall be eaten; you shall leave none of it until morning: I am the LORD.", 
                "num": 30
              }, 
              {
                "text": "\"Therefore you shall keep My commandments, and perform them: I am the LORD.", 
                "num": 31
              }, 
              {
                "text": "You shall not profane My holy name, but I will be hallowed among the children of Israel. I am the LORD who sanctifies you,", 
                "num": 32
              }, 
              {
                "text": "who brought you out of the land of Egypt, to be your God: I am the LORD.\"", 
                "num": 33
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"The feasts of the LORD, which you shall proclaim to be holy convocations, these are My feasts.", 
                "num": 2
              }, 
              {
                "text": "\"Six days shall work be done, but the seventh day is a Sabbath of solemn rest, a holy convocation. You shall do no work on it; it is the Sabbath of the LORD in all your dwellings.", 
                "num": 3
              }, 
              {
                "text": "\"These are the feasts of the LORD, holy convocations which you shall proclaim at their appointed times.", 
                "num": 4
              }, 
              {
                "text": "On the fourteenth day of the first month at twilight is the LORD's Passover.", 
                "num": 5
              }, 
              {
                "text": "And on the fifteenth day of the same month is the Feast of Unleavened Bread to the LORD; seven days you must eat unleavened bread.", 
                "num": 6
              }, 
              {
                "text": "On the first day you shall have a holy convocation; you shall do no customary work on it.", 
                "num": 7
              }, 
              {
                "text": "But you shall offer an offering made by fire to the LORD for seven days. The seventh day shall be a holy convocation; you shall do no customary work on it.\"'", 
                "num": 8
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you come into the land which I give to you, and reap its harvest, then you shall bring a sheaf of the firstfruits of your harvest to the priest.", 
                "num": 10
              }, 
              {
                "text": "He shall wave the sheaf before the LORD, to be accepted on your behalf; on the day after the Sabbath the priest shall wave it.", 
                "num": 11
              }, 
              {
                "text": "And you shall offer on that day, when you wave the sheaf, a male lamb of the first year, without blemish, as a burnt offering to the LORD.", 
                "num": 12
              }, 
              {
                "text": "Its grain offering shall be two-tenths of an ephah of fine flour mixed with oil, an offering made by fire to the LORD, for a sweet aroma; and its drink offering shall be of wine, one-fourth of a hin.", 
                "num": 13
              }, 
              {
                "text": "You shall eat neither bread nor parched grain nor fresh grain until the same day that you have brought an offering to your God; it shall be a statute forever throughout your generations in all your dwellings.", 
                "num": 14
              }, 
              {
                "text": "\"And you shall count for yourselves from the day after the Sabbath, from the day that you brought the sheaf of the wave offering: seven Sabbaths shall be completed.", 
                "num": 15
              }, 
              {
                "text": "Count fifty days to the day after the seventh Sabbath; then you shall offer a new grain offering to the LORD.", 
                "num": 16
              }, 
              {
                "text": "You shall bring from your dwellings two wave loaves of two-tenths of an ephah. They shall be of fine flour; they shall be baked with leaven. They are the firstfruits to the LORD.", 
                "num": 17
              }, 
              {
                "text": "And you shall offer with the bread seven lambs of the first year, without blemish, one young bull, and two rams. They shall be as a burnt offering to the LORD, with their grain offering and their drink offerings, an offering made by fire for a sweet aroma to the LORD.", 
                "num": 18
              }, 
              {
                "text": "Then you shall sacrifice one kid of the goats as a sin offering, and two male lambs of the first year as a sacrifice of a peace offering.", 
                "num": 19
              }, 
              {
                "text": "The priest shall wave them with the bread of the firstfruits as a wave offering before the LORD, with the two lambs. They shall be holy to the LORD for the priest.", 
                "num": 20
              }, 
              {
                "text": "And you shall proclaim on the same day that it is a holy convocation to you. You shall do no customary work on it. It shall be a statute forever in all your dwellings throughout your generations.", 
                "num": 21
              }, 
              {
                "text": "\"When you reap the harvest of your land, you shall not wholly reap the corners of your field when you reap, nor shall you gather any gleaning from your harvest. You shall leave them for the poor and for the stranger: I am the LORD your God.\"'", 
                "num": 22
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 23
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"In the seventh month, on the first day of the month, you shall have a sabbath-rest, a memorial of blowing of trumpets, a holy convocation.", 
                "num": 24
              }, 
              {
                "text": "You shall do no customary work on it; and you shall offer an offering made by fire to the LORD.\"'", 
                "num": 25
              }, 
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 26
              }, 
              {
                "text": "\"Also the tenth day of this seventh month shall be the Day of Atonement. It shall be a holy convocation for you; you shall afflict your souls, and offer an offering made by fire to the LORD.", 
                "num": 27
              }, 
              {
                "text": "And you shall do no work on that same day, for it is the Day of Atonement, to make atonement for you before the LORD your God.", 
                "num": 28
              }, 
              {
                "text": "For any person who is not afflicted in soul on that same day shall be cut off from his people.", 
                "num": 29
              }, 
              {
                "text": "And any person who does any work on that same day, that person I will destroy from among his people.", 
                "num": 30
              }, 
              {
                "text": "You shall do no manner of work; it shall be a statute forever throughout your generations in all your dwellings.", 
                "num": 31
              }, 
              {
                "text": "It shall be to you a sabbath of solemn rest, and you shall afflict your souls; on the ninth day of the month at evening, from evening to evening, you shall celebrate your sabbath.\"", 
                "num": 32
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 33
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"The fifteenth day of this seventh month shall be the Feast of Tabernacles for seven days to the LORD.", 
                "num": 34
              }, 
              {
                "text": "On the first day there shall be a holy convocation. You shall do no customary work on it.", 
                "num": 35
              }, 
              {
                "text": "For seven days you shall offer an offering made by fire to the LORD. On the eighth day you shall have a holy convocation, and you shall offer an offering made by fire to the LORD. It is a sacred assembly, and you shall do no customary work on it.", 
                "num": 36
              }, 
              {
                "text": "\"These are the feasts of the LORD which you shall proclaim to be holy convocations, to offer an offering made by fire to the LORD, a burnt offering and a grain offering, a sacrifice and drink offerings, everything on its day--", 
                "num": 37
              }, 
              {
                "text": "besides the Sabbaths of the LORD, besides your gifts, besides all your vows, and besides all your freewill offerings which you give to the LORD.", 
                "num": 38
              }, 
              {
                "text": "\"Also on the fifteenth day of the seventh month, when you have gathered in the fruit of the land, you shall keep the feast of the LORD for seven days; on the first day there shall be a sabbath-rest, and on the eighth day a sabbath-rest.", 
                "num": 39
              }, 
              {
                "text": "And you shall take for yourselves on the first day the fruit of beautiful trees, branches of palm trees, the boughs of leafy trees, and willows of the brook; and you shall rejoice before the LORD your God for seven days.", 
                "num": 40
              }, 
              {
                "text": "You shall keep it as a feast to the LORD for seven days in the year. It shall be a statute forever in your generations. You shall celebrate it in the seventh month.", 
                "num": 41
              }, 
              {
                "text": "You shall dwell in booths for seven days. All who are native Israelites shall dwell in booths,", 
                "num": 42
              }, 
              {
                "text": "that your generations may know that I made the children of Israel dwell in booths when I brought them out of the land of Egypt: I am the LORD your God.\"'", 
                "num": 43
              }, 
              {
                "text": "So Moses declared to the children of Israel the feasts of the LORD.", 
                "num": 44
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Command the children of Israel that they bring to you pure oil of pressed olives for the light, to make the lamps burn continually.", 
                "num": 2
              }, 
              {
                "text": "Outside the veil of the Testimony, in the tabernacle of meeting, Aaron shall be in charge of it from evening until morning before the LORD continually; it shall be a statute forever in your generations.", 
                "num": 3
              }, 
              {
                "text": "He shall be in charge of the lamps on the pure gold lampstand before the LORD continually.", 
                "num": 4
              }, 
              {
                "text": "\"And you shall take fine flour and bake twelve cakes with it. Two-tenths of an ephah shall be in each cake.", 
                "num": 5
              }, 
              {
                "text": "You shall set them in two rows, six in a row, on the pure gold table before the LORD.", 
                "num": 6
              }, 
              {
                "text": "And you shall put pure frankincense on each row, that it may be on the bread for a memorial, an offering made by fire to the LORD.", 
                "num": 7
              }, 
              {
                "text": "Every Sabbath he shall set it in order before the LORD continually, being taken from the children of Israel by an everlasting covenant.", 
                "num": 8
              }, 
              {
                "text": "And it shall be for Aaron and his sons, and they shall eat it in a holy place; for it is most holy to him from the offerings of the LORD made by fire, by a perpetual statute.\"", 
                "num": 9
              }, 
              {
                "text": "Now the son of an Israelite woman, whose father was an Egyptian, went out among the children of Israel; and this Israelite woman's son and a man of Israel fought each other in the camp.", 
                "num": 10
              }, 
              {
                "text": "And the Israelite woman's son blasphemed the name of the LORD and cursed; and so they brought him to Moses. (His mother's name was Shelomith the daughter of Dibri, of the tribe of Dan.)", 
                "num": 11
              }, 
              {
                "text": "Then they put him in custody, that the mind of the LORD might be shown to them.", 
                "num": 12
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 13
              }, 
              {
                "text": "\"Take outside the camp him who has cursed; then let all who heard him lay their hands on his head, and let all the congregation stone him.", 
                "num": 14
              }, 
              {
                "text": "\"Then you shall speak to the children of Israel, saying: \"Whoever curses his God shall bear his sin.", 
                "num": 15
              }, 
              {
                "text": "And whoever blasphemes the name of the LORD shall surely be put to death. All the congregation shall certainly stone him, the stranger as well as him who is born in the land. When he blasphemes the name of the LORD, he shall be put to death.", 
                "num": 16
              }, 
              {
                "text": "\"Whoever kills any man shall surely be put to death.", 
                "num": 17
              }, 
              {
                "text": "Whoever kills an animal shall make it good, animal for animal.", 
                "num": 18
              }, 
              {
                "text": "\"If a man causes disfigurement of his neighbor, as he has done, so shall it be done to him--", 
                "num": 19
              }, 
              {
                "text": "fracture for fracture, eye for eye, tooth for tooth; as he has caused disfigurement of a man, so shall it be done to him.", 
                "num": 20
              }, 
              {
                "text": "And whoever kills an animal shall restore it; but whoever kills a man shall be put to death.", 
                "num": 21
              }, 
              {
                "text": "You shall have the same law for the stranger and for one from your own country; for I am the LORD your God.\"'", 
                "num": 22
              }, 
              {
                "text": "Then Moses spoke to the children of Israel; and they took outside the camp him who had cursed, and stoned him with stones. So the children of Israel did as the LORD commanded Moses.", 
                "num": 23
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses on Mount Sinai, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you come into the land which I give you, then the land shall keep a sabbath to the LORD.", 
                "num": 2
              }, 
              {
                "text": "Six years you shall sow your field, and six years you shall prune your vineyard, and gather its fruit;", 
                "num": 3
              }, 
              {
                "text": "but in the seventh year there shall be a sabbath of solemn rest for the land, a sabbath to the LORD. You shall neither sow your field nor prune your vineyard.", 
                "num": 4
              }, 
              {
                "text": "What grows of its own accord of your harvest you shall not reap, nor gather the grapes of your untended vine, for it is a year of rest for the land.", 
                "num": 5
              }, 
              {
                "text": "And the sabbath produce of the land shall be food for you: for you, your male and female servants, your hired man, and the stranger who dwells with you,", 
                "num": 6
              }, 
              {
                "text": "for your livestock and the beasts that are in your land--all its produce shall be for food.", 
                "num": 7
              }, 
              {
                "text": "\"And you shall count seven sabbaths of years for yourself, seven times seven years; and the time of the seven sabbaths of years shall be to you forty-nine years.", 
                "num": 8
              }, 
              {
                "text": "Then you shall cause the trumpet of the Jubilee to sound on the tenth day of the seventh month; on the Day of Atonement you shall make the trumpet to sound throughout all your land.", 
                "num": 9
              }, 
              {
                "text": "And you shall consecrate the fiftieth year, and proclaim liberty throughout all the land to all its inhabitants. It shall be a Jubilee for you; and each of you shall return to his possession, and each of you shall return to his family.", 
                "num": 10
              }, 
              {
                "text": "That fiftieth year shall be a Jubilee to you; in it you shall neither sow nor reap what grows of its own accord, nor gather the grapes of your untended vine.", 
                "num": 11
              }, 
              {
                "text": "For it is the Jubilee; it shall be holy to you; you shall eat its produce from the field.", 
                "num": 12
              }, 
              {
                "text": "\"In this Year of Jubilee, each of you shall return to his possession.", 
                "num": 13
              }, 
              {
                "text": "And if you sell anything to your neighbor or buy from your neighbor's hand, you shall not oppress one another.", 
                "num": 14
              }, 
              {
                "text": "According to the number of years after the Jubilee you shall buy from your neighbor, and according to the number of years of crops he shall sell to you.", 
                "num": 15
              }, 
              {
                "text": "According to the multitude of years you shall increase its price, and according to the fewer number of years you shall diminish its price; for he sells to you according to the number of the years of the crops.", 
                "num": 16
              }, 
              {
                "text": "Therefore you shall not oppress one another, but you shall fear your God; for I am the LORD your God.", 
                "num": 17
              }, 
              {
                "text": "\"So you shall observe My statutes and keep My judgments, and perform them; and you will dwell in the land in safety.", 
                "num": 18
              }, 
              {
                "text": "Then the land will yield its fruit, and you will eat your fill, and dwell there in safety.", 
                "num": 19
              }, 
              {
                "text": "\"And if you say, \"What shall we eat in the seventh year, since we shall not sow nor gather in our produce?\"", 
                "num": 20
              }, 
              {
                "text": "Then I will command My blessing on you in the sixth year, and it will bring forth produce enough for three years.", 
                "num": 21
              }, 
              {
                "text": "And you shall sow in the eighth year, and eat old produce until the ninth year; until its produce comes in, you shall eat of the old harvest.", 
                "num": 22
              }, 
              {
                "text": "\"The land shall not be sold permanently, for the land is Mine; for you are strangers and sojourners with Me.", 
                "num": 23
              }, 
              {
                "text": "And in all the land of your possession you shall grant redemption of the land.", 
                "num": 24
              }, 
              {
                "text": "\"If one of your brethren becomes poor, and has sold some of his possession, and if his redeeming relative comes to redeem it, then he may redeem what his brother sold.", 
                "num": 25
              }, 
              {
                "text": "Or if the man has no one to redeem it, but he himself becomes able to redeem it,", 
                "num": 26
              }, 
              {
                "text": "then let him count the years since its sale, and restore the remainder to the man to whom he sold it, that he may return to his possession.", 
                "num": 27
              }, 
              {
                "text": "But if he is not able to have it restored to himself, then what was sold shall remain in the hand of him who bought it until the Year of Jubilee; and in the Jubilee it shall be released, and he shall return to his possession.", 
                "num": 28
              }, 
              {
                "text": "\"If a man sells a house in a walled city, then he may redeem it within a whole year after it is sold; within a full year he may redeem it.", 
                "num": 29
              }, 
              {
                "text": "But if it is not redeemed within the space of a full year, then the house in the walled city shall belong permanently to him who bought it, throughout his generations. It shall not be released in the Jubilee.", 
                "num": 30
              }, 
              {
                "text": "However the houses of villages which have no wall around them shall be counted as the fields of the country. They may be redeemed, and they shall be released in the Jubilee.", 
                "num": 31
              }, 
              {
                "text": "Nevertheless the cities of the Levites, and the houses in the cities of their possession, the Levites may redeem at any time.", 
                "num": 32
              }, 
              {
                "text": "And if a man purchases a house from the Levites, then the house that was sold in the city of his possession shall be released in the Jubilee; for the houses in the cities of the Levites are their possession among the children of Israel.", 
                "num": 33
              }, 
              {
                "text": "But the field of the common-land of their cities may not be sold, for it is their perpetual possession.", 
                "num": 34
              }, 
              {
                "text": "\"If one of your brethren becomes poor, and falls into poverty among you, then you shall help him, like a stranger or a sojourner, that he may live with you.", 
                "num": 35
              }, 
              {
                "text": "Take no usury or interest from him; but fear your God, that your brother may live with you.", 
                "num": 36
              }, 
              {
                "text": "You shall not lend him your money for usury, nor lend him your food at a profit.", 
                "num": 37
              }, 
              {
                "text": "I am the LORD your God, who brought you out of the land of Egypt, to give you the land of Canaan and to be your God.", 
                "num": 38
              }, 
              {
                "text": "\"And if one of your brethren who dwells by you becomes poor, and sells himself to you, you shall not compel him to serve as a slave.", 
                "num": 39
              }, 
              {
                "text": "As a hired servant and a sojourner he shall be with you, and shall serve you until the Year of Jubilee.", 
                "num": 40
              }, 
              {
                "text": "And then he shall depart from you--he and his children with him--and shall return to his own family. He shall return to the possession of his fathers.", 
                "num": 41
              }, 
              {
                "text": "For they are My servants, whom I brought out of the land of Egypt; they shall not be sold as slaves.", 
                "num": 42
              }, 
              {
                "text": "You shall not rule over him with rigor, but you shall fear your God.", 
                "num": 43
              }, 
              {
                "text": "And as for your male and female slaves whom you may have--from the nations that are around you, from them you may buy male and female slaves.", 
                "num": 44
              }, 
              {
                "text": "Moreover you may buy the children of the strangers who dwell among you, and their families who are with you, which they beget in your land; and they shall become your property.", 
                "num": 45
              }, 
              {
                "text": "And you may take them as an inheritance for your children after you, to inherit them as a possession; they shall be your permanent slaves. But regarding your brethren, the children of Israel, you shall not rule over one another with rigor.", 
                "num": 46
              }, 
              {
                "text": "\"Now if a sojourner or stranger close to you becomes rich, and one of your brethren who dwells by him becomes poor, and sells himself to the stranger or sojourner close to you, or to a member of the stranger's family,", 
                "num": 47
              }, 
              {
                "text": "after he is sold he may be redeemed again. One of his brothers may redeem him;", 
                "num": 48
              }, 
              {
                "text": "or his uncle or his uncle's son may redeem him; or anyone who is near of kin to him in his family may redeem him; or if he is able he may redeem himself.", 
                "num": 49
              }, 
              {
                "text": "Thus he shall reckon with him who bought him: The price of his release shall be according to the number of years, from the year that he was sold to him until the Year of Jubilee; it shall be according to the time of a hired servant for him.", 
                "num": 50
              }, 
              {
                "text": "If there are still many years remaining, according to them he shall repay the price of his redemption from the money with which he was bought.", 
                "num": 51
              }, 
              {
                "text": "And if there remain but a few years until the Year of Jubilee, then he shall reckon with him, and according to his years he shall repay him the price of his redemption.", 
                "num": 52
              }, 
              {
                "text": "He shall be with him as a yearly hired servant, and he shall not rule with rigor over him in your sight.", 
                "num": 53
              }, 
              {
                "text": "And if he is not redeemed in these years, then he shall be released in the Year of Jubilee--he and his children with him.", 
                "num": 54
              }, 
              {
                "text": "For the children of Israel are servants to Me; they are My servants whom I brought out of the land of Egypt: I am the LORD your God.", 
                "num": 55
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "\"You shall not make idols for yourselves; neither a carved image nor a sacred pillar shall you rear up for yourselves; nor shall you set up an engraved stone in your land, to bow down to it; for I am the LORD your God.", 
                "num": 1
              }, 
              {
                "text": "You shall keep My Sabbaths and reverence My sanctuary: I am the LORD.", 
                "num": 2
              }, 
              {
                "text": "\"If you walk in My statutes and keep My commandments, and perform them,", 
                "num": 3
              }, 
              {
                "text": "then I will give you rain in its season, the land shall yield its produce, and the trees of the field shall yield their fruit.", 
                "num": 4
              }, 
              {
                "text": "Your threshing shall last till the time of vintage, and the vintage shall last till the time of sowing; you shall eat your bread to the full, and dwell in your land safely.", 
                "num": 5
              }, 
              {
                "text": "I will give peace in the land, and you shall lie down, and none will make you afraid; I will rid the land of evil beasts, and the sword will not go through your land.", 
                "num": 6
              }, 
              {
                "text": "You will chase your enemies, and they shall fall by the sword before you.", 
                "num": 7
              }, 
              {
                "text": "Five of you shall chase a hundred, and a hundred of you shall put ten thousand to flight; your enemies shall fall by the sword before you.", 
                "num": 8
              }, 
              {
                "text": "\"For I will look on you favorably and make you fruitful, multiply you and confirm My covenant with you.", 
                "num": 9
              }, 
              {
                "text": "You shall eat the old harvest, and clear out the old because of the new.", 
                "num": 10
              }, 
              {
                "text": "I will set My tabernacle among you, and My soul shall not abhor you.", 
                "num": 11
              }, 
              {
                "text": "I will walk among you and be your God, and you shall be My people.", 
                "num": 12
              }, 
              {
                "text": "I am the LORD your God, who brought you out of the land of Egypt, that you should not be their slaves; I have broken the bands of your yoke and made you walk upright.", 
                "num": 13
              }, 
              {
                "text": "\"But if you do not obey Me, and do not observe all these commandments,", 
                "num": 14
              }, 
              {
                "text": "and if you despise My statutes, or if your soul abhors My judgments, so that you do not perform all My commandments, but break My covenant,", 
                "num": 15
              }, 
              {
                "text": "I also will do this to you: I will even appoint terror over you, wasting disease and fever which shall consume the eyes and cause sorrow of heart. And you shall sow your seed in vain, for your enemies shall eat it.", 
                "num": 16
              }, 
              {
                "text": "I will set My face against you, and you shall be defeated by your enemies. 1 Those who hate you shall reign over you, and you shall flee when no one pursues you.", 
                "num": 17
              }, 
              {
                "text": "\"And after all this, if you do not obey Me, then I will punish you seven times more for your sins.", 
                "num": 18
              }, 
              {
                "text": "I will break the pride of your power; I will make your heavens like iron and your earth like bronze.", 
                "num": 19
              }, 
              {
                "text": "And your strength shall be spent in vain; for your land shall not yield its produce, nor shall the trees of the land yield their fruit.", 
                "num": 20
              }, 
              {
                "text": "\"Then, if you walk contrary to Me, and are not willing to obey Me, I will bring on you seven times more plagues, according to your sins.", 
                "num": 21
              }, 
              {
                "text": "I will also send wild beasts among you, which shall rob you of your children, destroy your livestock, and make you few in number; and your highways shall be desolate.", 
                "num": 22
              }, 
              {
                "text": "\"And if by these things you are not reformed by Me, but walk contrary to Me,", 
                "num": 23
              }, 
              {
                "text": "then I also will walk contrary to you, and I will punish you yet seven times for your sins.", 
                "num": 24
              }, 
              {
                "text": "And I will bring a sword against you that will execute the vengeance of the covenant; when you are gathered together within your cities I will send pestilence among you; and you shall be delivered into the hand of the enemy.", 
                "num": 25
              }, 
              {
                "text": "When I have cut off your supply of bread, ten women shall bake your bread in one oven, and they shall bring back your bread by weight, and you shall eat and not be satisfied.", 
                "num": 26
              }, 
              {
                "text": "\"And after all this, if you do not obey Me, but walk contrary to Me,", 
                "num": 27
              }, 
              {
                "text": "then I also will walk contrary to you in fury; and I, even I, will chastise you seven times for your sins.", 
                "num": 28
              }, 
              {
                "text": "You shall eat the flesh of your sons, and you shall eat the flesh of your daughters.", 
                "num": 29
              }, 
              {
                "text": "I will destroy your high places, cut down your incense altars, and cast your carcasses on the lifeless forms of your idols; and My soul shall abhor you.", 
                "num": 30
              }, 
              {
                "text": "I will lay your cities waste and bring your sanctuaries to desolation, and I will not smell the fragrance of your sweet aromas.", 
                "num": 31
              }, 
              {
                "text": "I will bring the land to desolation, and your enemies who dwell in it shall be astonished at it.", 
                "num": 32
              }, 
              {
                "text": "I will scatter you among the nations and draw out a sword after you; your land shall be desolate and your cities waste.", 
                "num": 33
              }, 
              {
                "text": "Then the land shall enjoy its sabbaths as long as it lies desolate and you are in your enemies' land; then the land shall rest and enjoy its sabbaths.", 
                "num": 34
              }, 
              {
                "text": "As long as it lies desolate it shall rest-- for the time it did not rest on your sabbaths when you dwelt in it.", 
                "num": 35
              }, 
              {
                "text": "\"And as for those of you who are left, I will send faintness into their hearts in the lands of their enemies; the sound of a shaken leaf shall cause them to flee; they shall flee as though fleeing from a sword, and they shall fall when no one pursues.", 
                "num": 36
              }, 
              {
                "text": "They shall stumble over one another, as it were before a sword, when no one pursues; and you shall have no power to stand before your enemies.", 
                "num": 37
              }, 
              {
                "text": "You shall perish among the nations, and the land of your enemies shall eat you up.", 
                "num": 38
              }, 
              {
                "text": "And those of you who are left shall waste away in their iniquity in your enemies' lands; also in their fathers' iniquities, which are with them, they shall waste away.", 
                "num": 39
              }, 
              {
                "text": "\"But if they confess their iniquity and the iniquity of their fathers, with their unfaithfulness in which they were unfaithful to Me, and that they also have walked contrary to Me,", 
                "num": 40
              }, 
              {
                "text": "and that I also have walked contrary to them and have brought them into the land of their enemies; if their uncircumcised hearts are humbled, and they accept their guilt--", 
                "num": 41
              }, 
              {
                "text": "then I will remember My covenant with Jacob, and My covenant with Isaac and My covenant with Abraham I will remember; I will remember the land.", 
                "num": 42
              }, 
              {
                "text": "The land also shall be left empty by them, and will enjoy its sabbaths while it lies desolate without them; they will accept their guilt, because they despised My judgments and because their soul abhorred My statutes.", 
                "num": 43
              }, 
              {
                "text": "Yet for all that, when they are in the land of their enemies, I will not cast them away, nor shall I abhor them, to utterly destroy them and break My covenant with them; for I am the LORD their God.", 
                "num": 44
              }, 
              {
                "text": "But for their sake I will remember the covenant of their ancestors, whom I brought out of the land of Egypt in the sight of the nations, that I might be their God: I am the LORD.\"'", 
                "num": 45
              }, 
              {
                "text": "These are the statutes and judgments and laws which the LORD made between Himself and the children of Israel on Mount Sinai by the hand of Moses.", 
                "num": 46
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When a man consecrates by a vow certain persons to the LORD, according to your valuation,", 
                "num": 2
              }, 
              {
                "text": "if your valuation is of a male from twenty years old up to sixty years old, then your valuation shall be fifty shekels of silver, according to the shekel of the sanctuary.", 
                "num": 3
              }, 
              {
                "text": "If it is a female, then your valuation shall be thirty shekels;", 
                "num": 4
              }, 
              {
                "text": "and if from five years old up to twenty years old, then your valuation for a male shall be twenty shekels, and for a female ten shekels;", 
                "num": 5
              }, 
              {
                "text": "and if from a month old up to five years old, then your valuation for a male shall be five shekels of silver, and for a female your valuation shall be three shekels of silver;", 
                "num": 6
              }, 
              {
                "text": "and if from sixty years old and above, if it is a male, then your valuation shall be fifteen shekels, and for a female ten shekels.", 
                "num": 7
              }, 
              {
                "text": "\"But if he is too poor to pay your valuation, then he shall present himself before the priest, and the priest shall set a value for him; according to the ability of him who vowed, the priest shall value him.", 
                "num": 8
              }, 
              {
                "text": "\"If it is an animal that men may bring as an offering to the LORD, all that anyone gives to the LORD shall be holy.", 
                "num": 9
              }, 
              {
                "text": "He shall not substitute it or exchange it, good for bad or bad for good; and if he at all exchanges animal for animal, then both it and the one exchanged for it shall be holy.", 
                "num": 10
              }, 
              {
                "text": "If it is an unclean animal which they do not offer as a sacrifice to the LORD, then he shall present the animal before the priest;", 
                "num": 11
              }, 
              {
                "text": "and the priest shall set a value for it, whether it is good or bad; as you, the priest, value it, so it shall be.", 
                "num": 12
              }, 
              {
                "text": "But if he wants at all to redeem it, then he must add one-fifth to your valuation.", 
                "num": 13
              }, 
              {
                "text": "\"And when a man dedicates his house to be holy to the LORD, then the priest shall set a value for it, whether it is good or bad; as the priest values it, so it shall stand.", 
                "num": 14
              }, 
              {
                "text": "If he who dedicated it wants to redeem his house, then he must add one-fifth of the money of your valuation to it, and it shall be his.", 
                "num": 15
              }, 
              {
                "text": "\"If a man dedicates to the LORD part of a field of his possession, then your valuation shall be according to the seed for it. A homer of barley seed shall be valued at fifty shekels of silver.", 
                "num": 16
              }, 
              {
                "text": "If he dedicates his field from the Year of Jubilee, according to your valuation it shall stand.", 
                "num": 17
              }, 
              {
                "text": "But if he dedicates his field after the Jubilee, then the priest shall reckon to him the money due according to the years that remain till the Year of Jubilee, and it shall be deducted from your valuation.", 
                "num": 18
              }, 
              {
                "text": "And if he who dedicates the field ever wishes to redeem it, then he must add one-fifth of the money of your valuation to it, and it shall belong to him.", 
                "num": 19
              }, 
              {
                "text": "But if he does not want to redeem the field, or if he has sold the field to another man, it shall not be redeemed anymore;", 
                "num": 20
              }, 
              {
                "text": "but the field, when it is released in the Jubilee, shall be holy to the LORD, as a devoted field; it shall be the possession of the priest.", 
                "num": 21
              }, 
              {
                "text": "\"And if a man dedicates to the LORD a field which he has bought, which is not the field of his possession,", 
                "num": 22
              }, 
              {
                "text": "then the priest shall reckon to him the worth of your valuation, up to the Year of Jubilee, and he shall give your valuation on that day as a holy offering to the LORD.", 
                "num": 23
              }, 
              {
                "text": "In the Year of Jubilee the field shall return to him from whom it was bought, to the one who owned the land as a possession.", 
                "num": 24
              }, 
              {
                "text": "And all your valuations shall be according to the shekel of the sanctuary: twenty gerahs to the shekel.", 
                "num": 25
              }, 
              {
                "text": "\"But the firstborn of the animals, which should be the LORD's firstborn, no man shall dedicate; whether it is an ox or sheep, it is the LORD's.", 
                "num": 26
              }, 
              {
                "text": "And if it is an unclean animal, then he shall redeem it according to your valuation, and shall add one-fifth to it; or if it is not redeemed, then it shall be sold according to your valuation.", 
                "num": 27
              }, 
              {
                "text": "\"Nevertheless no devoted offering that a man may devote to the LORD of all that he has, both man and beast, or the field of his possession, shall be sold or redeemed; every devoted offering is most holy to the LORD.", 
                "num": 28
              }, 
              {
                "text": "No person under the ban, who may become doomed to destruction among men, shall be redeemed, but shall surely be put to death.", 
                "num": 29
              }, 
              {
                "text": "And all the tithe of the land, whether of the seed of the land or of the fruit of the tree, is the LORD's. It is holy to the LORD.", 
                "num": 30
              }, 
              {
                "text": "If a man wants at all to redeem any of his tithes, he shall add one-fifth to it.", 
                "num": 31
              }, 
              {
                "text": "And concerning the tithe of the herd or the flock, of whatever passes under the rod, the tenth one shall be holy to the LORD.", 
                "num": 32
              }, 
              {
                "text": "He shall not inquire whether it is good or bad, nor shall he exchange it; and if he exchanges it at all, then both it and the one exchanged for it shall be holy; it shall not be redeemed.\"'", 
                "num": 33
              }, 
              {
                "text": "These are the commandments which the LORD commanded Moses for the children of Israel on Mount Sinai.", 
                "num": 34
              }
            ], 
            "num": 27
          }
        ]
      }, 
      {
        "name": "Numbers", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses in the Wilderness of Sinai, in the tabernacle of meeting, on the first day of the second month, in the second year after they had come out of the land of Egypt, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take a census of all the congregation of the children of Israel, by their families, by their fathers' houses, according to the number of names, every male individually,", 
                "num": 2
              }, 
              {
                "text": "from twenty years old and above--all who are able to go to war in Israel. You and Aaron shall number them by their armies.", 
                "num": 3
              }, 
              {
                "text": "And with you there shall be a man from every tribe, each one the head of his father's house.", 
                "num": 4
              }, 
              {
                "text": "\"These are the names of the men who shall stand with you: from Reuben, Elizur the son of Shedeur;", 
                "num": 5
              }, 
              {
                "text": "from Simeon, Shelumiel the son of Zurishaddai;", 
                "num": 6
              }, 
              {
                "text": "from Judah, Nahshon the son of Amminadab;", 
                "num": 7
              }, 
              {
                "text": "from Issachar, Nethanel the son of Zuar;", 
                "num": 8
              }, 
              {
                "text": "from Zebulun, Eliab the son of Helon;", 
                "num": 9
              }, 
              {
                "text": "from the sons of Joseph: from Ephraim, Elishama the son of Ammihud; from Manasseh, Gamaliel the son of Pedahzur;", 
                "num": 10
              }, 
              {
                "text": "from Benjamin, Abidan the son of Gideoni;", 
                "num": 11
              }, 
              {
                "text": "from Dan, Ahiezer the son of Ammishaddai;", 
                "num": 12
              }, 
              {
                "text": "from Asher, Pagiel the son of Ocran;", 
                "num": 13
              }, 
              {
                "text": "from Gad, Eliasaph the son of Deuel;", 
                "num": 14
              }, 
              {
                "text": "from Naphtali, Ahira the son of Enan.\"", 
                "num": 15
              }, 
              {
                "text": "These were chosen from the congregation, leaders of their fathers' tribes, heads of the divisions in Israel.", 
                "num": 16
              }, 
              {
                "text": "Then Moses and Aaron took these men who had been mentioned by name,", 
                "num": 17
              }, 
              {
                "text": "and they assembled all the congregation together on the first day of the second month; and they recited their ancestry by families, by their fathers' houses, according to the number of names, from twenty years old and above, each one individually.", 
                "num": 18
              }, 
              {
                "text": "As the LORD commanded Moses, so he numbered them in the Wilderness of Sinai.", 
                "num": 19
              }, 
              {
                "text": "Now the children of Reuben, Israel's oldest son, their genealogies by their families, by their fathers' house, according to the number of names, every male individually, from twenty years old and above, all who were able to go to war:", 
                "num": 20
              }, 
              {
                "text": "those who were numbered of the tribe of Reuben were forty-six thousand five hundred.", 
                "num": 21
              }, 
              {
                "text": "From the children of Simeon, their genealogies by their families, by their fathers' house, of those who were numbered, according to the number of names, every male individually, from twenty years old and above, all who were able to go to war:", 
                "num": 22
              }, 
              {
                "text": "those who were numbered of the tribe of Simeon were fifty-nine thousand three hundred.", 
                "num": 23
              }, 
              {
                "text": "From the children of Gad, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 24
              }, 
              {
                "text": "those who were numbered of the tribe of Gad were forty-five thousand six hundred and fifty.", 
                "num": 25
              }, 
              {
                "text": "From the children of Judah, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 26
              }, 
              {
                "text": "those who were numbered of the tribe of Judah were seventy-four thousand six hundred.", 
                "num": 27
              }, 
              {
                "text": "From the children of Issachar, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 28
              }, 
              {
                "text": "those who were numbered of the tribe of Issachar were fifty-four thousand four hundred.", 
                "num": 29
              }, 
              {
                "text": "From the children of Zebulun, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 30
              }, 
              {
                "text": "those who were numbered of the tribe of Zebulun were fifty-seven thousand four hundred.", 
                "num": 31
              }, 
              {
                "text": "From the sons of Joseph, the children of Ephraim, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 32
              }, 
              {
                "text": "those who were numbered of the tribe of Ephraim were forty thousand five hundred.", 
                "num": 33
              }, 
              {
                "text": "From the children of Manasseh, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 34
              }, 
              {
                "text": "those who were numbered of the tribe of Manasseh were thirty-two thousand two hundred.", 
                "num": 35
              }, 
              {
                "text": "From the children of Benjamin, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 36
              }, 
              {
                "text": "those who were numbered of the tribe of Benjamin were thirty-five thousand four hundred.", 
                "num": 37
              }, 
              {
                "text": "From the children of Dan, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 38
              }, 
              {
                "text": "those who were numbered of the tribe of Dan were sixty-two thousand seven hundred.", 
                "num": 39
              }, 
              {
                "text": "From the children of Asher, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 40
              }, 
              {
                "text": "those who were numbered of the tribe of Asher were forty-one thousand five hundred.", 
                "num": 41
              }, 
              {
                "text": "From the children of Naphtali, their genealogies by their families, by their fathers' house, according to the number of names, from twenty years old and above, all who were able to go to war:", 
                "num": 42
              }, 
              {
                "text": "those who were numbered of the tribe of Naphtali were fifty-three thousand four hundred.", 
                "num": 43
              }, 
              {
                "text": "These are the ones who were numbered, whom Moses and Aaron numbered, with the leaders of Israel, twelve men, each one representing his father's house.", 
                "num": 44
              }, 
              {
                "text": "So all who were numbered of the children of Israel, by their fathers' houses, from twenty years old and above, all who were able to go to war in Israel--", 
                "num": 45
              }, 
              {
                "text": "all who were numbered were six hundred and three thousand five hundred and fifty.", 
                "num": 46
              }, 
              {
                "text": "But the Levites were not numbered among them by their fathers' tribe;", 
                "num": 47
              }, 
              {
                "text": "for the LORD had spoken to Moses, saying:", 
                "num": 48
              }, 
              {
                "text": "\"Only the tribe of Levi you shall not number, nor take a census of them among the children of Israel;", 
                "num": 49
              }, 
              {
                "text": "but you shall appoint the Levites over the tabernacle of the Testimony, over all its furnishings, and over all things that belong to it; they shall carry the tabernacle and all its furnishings; they shall attend to it and camp around the tabernacle.", 
                "num": 50
              }, 
              {
                "text": "And when the tabernacle is to go forward, the Levites shall take it down; and when the tabernacle is to be set up, the Levites shall set it up. The outsider who comes near shall be put to death.", 
                "num": 51
              }, 
              {
                "text": "The children of Israel shall pitch their tents, everyone by his own camp, everyone by his own standard, according to their armies;", 
                "num": 52
              }, 
              {
                "text": "but the Levites shall camp around the tabernacle of the Testimony, that there may be no wrath on the congregation of the children of Israel; and the Levites shall keep charge of the tabernacle of the Testimony.\"", 
                "num": 53
              }, 
              {
                "text": "Thus the children of Israel did; according to all that the LORD commanded Moses, so they did.", 
                "num": 54
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses and Aaron, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Everyone of the children of Israel shall camp by his own standard, beside the emblems of his father's house; they shall camp some distance from the tabernacle of meeting.", 
                "num": 2
              }, 
              {
                "text": "On the east side, toward the rising of the sun, those of the standard of the forces with Judah shall camp according to their armies; and Nahshon the son of Amminadab shall be the leader of the children of Judah.\"", 
                "num": 3
              }, 
              {
                "text": "And his army was numbered at seventy-four thousand six hundred.", 
                "num": 4
              }, 
              {
                "text": "\"Those who camp next to him shall be the tribe of Issachar, and Nethanel the son of Zuar shall be the leader of the children of Issachar.\"", 
                "num": 5
              }, 
              {
                "text": "And his army was numbered at fifty-four thousand four hundred.", 
                "num": 6
              }, 
              {
                "text": "\"Then comes the tribe of Zebulun, and Eliab the son of Helon shall be the leader of the children of Zebulun.\"", 
                "num": 7
              }, 
              {
                "text": "And his army was numbered at fifty-seven thousand four hundred.", 
                "num": 8
              }, 
              {
                "text": "\"All who were numbered according to their armies of the forces with Judah, one hundred and eighty-six thousand four hundred--these shall break camp first.", 
                "num": 9
              }, 
              {
                "text": "\"On the south side shall be the standard of the forces with Reuben according to their armies, and the leader of the children of Reuben shall be Elizur the son of Shedeur.\"", 
                "num": 10
              }, 
              {
                "text": "And his army was numbered at forty-six thousand five hundred.", 
                "num": 11
              }, 
              {
                "text": "\"Those who camp next to him shall be the tribe of Simeon, and the leader of the children of Simeon shall be Shelumiel the son of Zurishaddai.\"", 
                "num": 12
              }, 
              {
                "text": "And his army was numbered at fifty-nine thousand three hundred.", 
                "num": 13
              }, 
              {
                "text": "\"Then comes the tribe of Gad, and the leader of the children of Gad shall be Eliasaph the son of Reuel.\"", 
                "num": 14
              }, 
              {
                "text": "And his army was numbered at forty-five thousand six hundred and fifty.", 
                "num": 15
              }, 
              {
                "text": "\"All who were numbered according to their armies of the forces with Reuben, one hundred and fifty-one thousand four hundred and fifty--they shall be the second to break camp.", 
                "num": 16
              }, 
              {
                "text": "\"And the tabernacle of meeting shall move out with the camp of the Levites in the middle of the camps; as they camp, so they shall move out, everyone in his place, by their standards.", 
                "num": 17
              }, 
              {
                "text": "\"On the west side shall be the standard of the forces with Ephraim according to their armies, and the leader of the children of Ephraim shall be Elishama the son of Ammihud.\"", 
                "num": 18
              }, 
              {
                "text": "And his army was numbered at forty thousand five hundred.", 
                "num": 19
              }, 
              {
                "text": "\"Next to him comes the tribe of Manasseh, and the leader of the children of Manasseh shall be Gamaliel the son of Pedahzur.\"", 
                "num": 20
              }, 
              {
                "text": "And his army was numbered at thirty-two thousand two hundred.", 
                "num": 21
              }, 
              {
                "text": "\"Then comes the tribe of Benjamin, and the leader of the children of Benjamin shall be Abidan the son of Gideoni.\"", 
                "num": 22
              }, 
              {
                "text": "And his army was numbered at thirty-five thousand four hundred.", 
                "num": 23
              }, 
              {
                "text": "\"All who were numbered according to their armies of the forces with Ephraim, one hundred and eight thousand one hundred--they shall be the third to break camp.", 
                "num": 24
              }, 
              {
                "text": "\"The standard of the forces with Dan shall be on the north side according to their armies, and the leader of the children of Dan shall be Ahiezer the son of Ammishaddai.\"", 
                "num": 25
              }, 
              {
                "text": "And his army was numbered at sixty-two thousand seven hundred.", 
                "num": 26
              }, 
              {
                "text": "\"Those who camp next to him shall be the tribe of Asher, and the leader of the children of Asher shall be Pagiel the son of Ocran.\"", 
                "num": 27
              }, 
              {
                "text": "And his army was numbered at forty-one thousand five hundred.", 
                "num": 28
              }, 
              {
                "text": "\"Then comes the tribe of Naphtali, and the leader of the children of Naphtali shall be Ahira the son of Enan.\"", 
                "num": 29
              }, 
              {
                "text": "And his army was numbered at fifty-three thousand four hundred.", 
                "num": 30
              }, 
              {
                "text": "\"All who were numbered of the forces with Dan, one hundred and fifty-seven thousand six hundred--they shall break camp last, with their standards.\"", 
                "num": 31
              }, 
              {
                "text": "These are the ones who were numbered of the children of Israel by their fathers' houses. All who were numbered according to their armies of the forces were six hundred and three thousand five hundred and fifty.", 
                "num": 32
              }, 
              {
                "text": "But the Levites were not numbered among the children of Israel, just as the LORD commanded Moses.", 
                "num": 33
              }, 
              {
                "text": "Thus the children of Israel did according to all that the LORD commanded Moses; so they camped by their standards and so they broke camp, each one by his family, according to their fathers' houses.", 
                "num": 34
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now these are the records of Aaron and Moses when the LORD spoke with Moses on Mount Sinai.", 
                "num": 1
              }, 
              {
                "text": "And these are the names of the sons of Aaron: Nadab, the firstborn, and Abihu, Eleazar, and Ithamar.", 
                "num": 2
              }, 
              {
                "text": "These are the names of the sons of Aaron, the anointed priests, whom he consecrated to minister as priests.", 
                "num": 3
              }, 
              {
                "text": "Nadab and Abihu had died before the LORD when they offered profane fire before the LORD in the Wilderness of Sinai; and they had no children. So Eleazar and Ithamar ministered as priests in the presence of Aaron their father.", 
                "num": 4
              }, 
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 5
              }, 
              {
                "text": "\"Bring the tribe of Levi near, and present them before Aaron the priest, that they may serve him.", 
                "num": 6
              }, 
              {
                "text": "And they shall attend to his needs and the needs of the whole congregation before the tabernacle of meeting, to do the work of the tabernacle.", 
                "num": 7
              }, 
              {
                "text": "Also they shall attend to all the furnishings of the tabernacle of meeting, and to the needs of the children of Israel, to do the work of the tabernacle.", 
                "num": 8
              }, 
              {
                "text": "And you shall give the Levites to Aaron and his sons; they are given entirely to him from among the children of Israel.", 
                "num": 9
              }, 
              {
                "text": "So you shall appoint Aaron and his sons, and they shall attend to their priesthood; but the outsider who comes near shall be put to death.\"", 
                "num": 10
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 11
              }, 
              {
                "text": "\"Now behold, I Myself have taken the Levites from among the children of Israel instead of every firstborn who opens the womb among the children of Israel. Therefore the Levites shall be Mine,", 
                "num": 12
              }, 
              {
                "text": "because all the firstborn are Mine. On the day that I struck all the firstborn in the land of Egypt, I sanctified to Myself all the firstborn in Israel, both man and beast. They shall be Mine: I am the LORD.\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD spoke to Moses in the Wilderness of Sinai, saying:", 
                "num": 14
              }, 
              {
                "text": "\"Number the children of Levi by their fathers' houses, by their families; you shall number every male from a month old and above.\"", 
                "num": 15
              }, 
              {
                "text": "So Moses numbered them according to the word of the LORD, as he was commanded.", 
                "num": 16
              }, 
              {
                "text": "These were the sons of Levi by their names: Gershon, Kohath, and Merari.", 
                "num": 17
              }, 
              {
                "text": "And these are the names of the sons of Gershon by their families: Libni and Shimei.", 
                "num": 18
              }, 
              {
                "text": "And the sons of Kohath by their families: Amram, Izehar, Hebron, and Uzziel.", 
                "num": 19
              }, 
              {
                "text": "And the sons of Merari by their families: Mahli and Mushi. These are the families of the Levites by their fathers' houses.", 
                "num": 20
              }, 
              {
                "text": "From Gershon came the family of the Libnites and the family of the Shimites; these were the families of the Gershonites.", 
                "num": 21
              }, 
              {
                "text": "Those who were numbered, according to the number of all the males from a month old and above--of those who were numbered there were seven thousand five hundred.", 
                "num": 22
              }, 
              {
                "text": "The families of the Gershonites were to camp behind the tabernacle westward.", 
                "num": 23
              }, 
              {
                "text": "And the leader of the father's house of the Gershonites was Eliasaph the son of Lael.", 
                "num": 24
              }, 
              {
                "text": "The duties of the children of Gershon in the tabernacle of meeting included the tabernacle, the tent with its covering, the screen for the door of the tabernacle of meeting,", 
                "num": 25
              }, 
              {
                "text": "the screen for the door of the court, the hangings of the court which are around the tabernacle and the altar, and their cords, according to all the work relating to them.", 
                "num": 26
              }, 
              {
                "text": "From Kohath came the family of the Amramites, the family of the Izharites, the family of the Hebronites, and the family of the Uzzielites; these were the families of the Kohathites.", 
                "num": 27
              }, 
              {
                "text": "According to the number of all the males, from a month old and above, there were eight thousand six hundred keeping charge of the sanctuary.", 
                "num": 28
              }, 
              {
                "text": "The families of the children of Kohath were to camp on the south side of the tabernacle.", 
                "num": 29
              }, 
              {
                "text": "And the leader of the fathers' house of the families of the Kohathites was Elizaphan the son of Uzziel.", 
                "num": 30
              }, 
              {
                "text": "Their duty included the ark, the table, the lampstand, the altars, the utensils of the sanctuary with which they ministered, the screen, and all the work relating to them.", 
                "num": 31
              }, 
              {
                "text": "And Eleazar the son of Aaron the priest was to be chief over the leaders of the Levites, with oversight of those who kept charge of the sanctuary.", 
                "num": 32
              }, 
              {
                "text": "From Merari came the family of the Mahlites and the family of the Mushites; these were the families of Merari.", 
                "num": 33
              }, 
              {
                "text": "And those who were numbered, according to the number of all the males from a month old and above, were six thousand two hundred.", 
                "num": 34
              }, 
              {
                "text": "The leader of the fathers' house of the families of Merari was Zuriel the son of Abihail. These were to camp on the north side of the tabernacle.", 
                "num": 35
              }, 
              {
                "text": "And the appointed duty of the children of Merari included the boards of the tabernacle, its bars, its pillars, its sockets, its utensils, all the work relating to them,", 
                "num": 36
              }, 
              {
                "text": "and the pillars of the court all around, with their sockets, their pegs, and their cords.", 
                "num": 37
              }, 
              {
                "text": "Moreover those who were to camp before the tabernacle on the east, before the tabernacle of meeting, were Moses, Aaron, and his sons, keeping charge of the sanctuary, to meet the needs of the children of Israel; but the outsider who came near was to be put to death.", 
                "num": 38
              }, 
              {
                "text": "All who were numbered of the Levites, whom Moses and Aaron numbered at the commandment of the LORD, by their families, all the males from a month old and above, were twenty-two thousand.", 
                "num": 39
              }, 
              {
                "text": "Then the LORD said to Moses: \"Number all the firstborn males of the children of Israel from a month old and above, and take the number of their names.", 
                "num": 40
              }, 
              {
                "text": "And you shall take the Levites for Me--I am the LORD--instead of all the firstborn among the children of Israel, and the livestock of the Levites instead of all the firstborn among the livestock of the children of Israel.\"", 
                "num": 41
              }, 
              {
                "text": "So Moses numbered all the firstborn among the children of Israel, as the LORD commanded him.", 
                "num": 42
              }, 
              {
                "text": "And all the firstborn males, according to the number of names from a month old and above, of those who were numbered of them, were twenty-two thousand two hundred and seventy-three.", 
                "num": 43
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 44
              }, 
              {
                "text": "\"Take the Levites instead of all the firstborn among the children of Israel, and the livestock of the Levites instead of their livestock. The Levites shall be Mine: I am the LORD.", 
                "num": 45
              }, 
              {
                "text": "And for the redemption of the two hundred and seventy-three of the firstborn of the children of Israel, who are more than the number of the Levites,", 
                "num": 46
              }, 
              {
                "text": "you shall take five shekels for each one individually; you shall take them in the currency of the shekel of the sanctuary, the shekel of twenty gerahs.", 
                "num": 47
              }, 
              {
                "text": "And you shall give the money, with which the excess number of them is redeemed, to Aaron and his sons.\"", 
                "num": 48
              }, 
              {
                "text": "So Moses took the redemption money from those who were over and above those who were redeemed by the Levites.", 
                "num": 49
              }, 
              {
                "text": "From the firstborn of the children of Israel he took the money, one thousand three hundred and sixty-five shekels, according to the shekel of the sanctuary.", 
                "num": 50
              }, 
              {
                "text": "And Moses gave their redemption money to Aaron and his sons, according to the word of the LORD, as the LORD commanded Moses.", 
                "num": 51
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses and Aaron, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take a census of the sons of Kohath from among the children of Levi, by their families, by their fathers' house,", 
                "num": 2
              }, 
              {
                "text": "from thirty years old and above, even to fifty years old, all who enter the service to do the work in the tabernacle of meeting.", 
                "num": 3
              }, 
              {
                "text": "\"This is the service of the sons of Kohath in the tabernacle of meeting, relating to the most holy things:", 
                "num": 4
              }, 
              {
                "text": "When the camp prepares to journey, Aaron and his sons shall come, and they shall take down the covering veil and cover the ark of the Testimony with it.", 
                "num": 5
              }, 
              {
                "text": "Then they shall put on it a covering of badger skins, and spread over that a cloth entirely of blue; and they shall insert its poles.", 
                "num": 6
              }, 
              {
                "text": "\"On the table of showbread they shall spread a blue cloth, and put on it the dishes, the pans, the bowls, and the pitchers for pouring; and the showbread shall be on it.", 
                "num": 7
              }, 
              {
                "text": "They shall spread over them a scarlet cloth, and cover the same with a covering of badger skins; and they shall insert its poles.", 
                "num": 8
              }, 
              {
                "text": "And they shall take a blue cloth and cover the lampstand of the light, with its lamps, its wick-trimmers, its trays, and all its oil vessels, with which they service it.", 
                "num": 9
              }, 
              {
                "text": "Then they shall put it with all its utensils in a covering of badger skins, and put it on a carrying beam.", 
                "num": 10
              }, 
              {
                "text": "\"Over the golden altar they shall spread a blue cloth, and cover it with a covering of badger skins; and they shall insert its poles.", 
                "num": 11
              }, 
              {
                "text": "Then they shall take all the utensils of service with which they minister in the sanctuary, put them in a blue cloth, cover them with a covering of badger skins, and put them on a carrying beam.", 
                "num": 12
              }, 
              {
                "text": "Also they shall take away the ashes from the altar, and spread a purple cloth over it.", 
                "num": 13
              }, 
              {
                "text": "They shall put on it all its implements with which they minister there--the firepans, the forks, the shovels, the basins, and all the utensils of the altar--and they shall spread on it a covering of badger skins, and insert its poles.", 
                "num": 14
              }, 
              {
                "text": "And when Aaron and his sons have finished covering the sanctuary and all the furnishings of the sanctuary, when the camp is set to go, then the sons of Kohath shall come to carry them; but they shall not touch any holy thing, lest they die. \"These are the things in the tabernacle of meeting which the sons of Kohath are to carry.", 
                "num": 15
              }, 
              {
                "text": "\"The appointed duty of Eleazar the son of Aaron the priest is the oil for the light, the sweet incense, the daily grain offering, the anointing oil, the oversight of all the tabernacle, of all that is in it, with the sanctuary and its furnishings.\"", 
                "num": 16
              }, 
              {
                "text": "Then the LORD spoke to Moses and Aaron, saying:", 
                "num": 17
              }, 
              {
                "text": "\"Do not cut off the tribe of the families of the Kohathites from among the Levites;", 
                "num": 18
              }, 
              {
                "text": "but do this in regard to them, that they may live and not die when they approach the most holy things: Aaron and his sons shall go in and appoint each of them to his service and his task.", 
                "num": 19
              }, 
              {
                "text": "But they shall not go in to watch while the holy things are being covered, lest they die.\"", 
                "num": 20
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 21
              }, 
              {
                "text": "\"Also take a census of the sons of Gershon, by their fathers' house, by their families.", 
                "num": 22
              }, 
              {
                "text": "From thirty years old and above, even to fifty years old, you shall number them, all who enter to perform the service, to do the work in the tabernacle of meeting.", 
                "num": 23
              }, 
              {
                "text": "This is the service of the families of the Gershonites, in serving and carrying:", 
                "num": 24
              }, 
              {
                "text": "They shall carry the curtains of the tabernacle and the tabernacle of meeting with its covering, the covering of badger skins that is on it, the screen for the door of the tabernacle of meeting,", 
                "num": 25
              }, 
              {
                "text": "the screen for the door of the gate of the court, the hangings of the court which are around the tabernacle and altar, and their cords, all the furnishings for their service and all that is made for these things: so shall they serve.", 
                "num": 26
              }, 
              {
                "text": "\"Aaron and his sons shall assign all the service of the sons of the Gershonites, all their tasks and all their service. And you shall appoint to them all their tasks as their duty.", 
                "num": 27
              }, 
              {
                "text": "This is the service of the families of the sons of Gershon in the tabernacle of meeting. And their duties shall be under the authority of Ithamar the son of Aaron the priest.", 
                "num": 28
              }, 
              {
                "text": "\"As for the sons of Merari, you shall number them by their families and by their fathers' house.", 
                "num": 29
              }, 
              {
                "text": "From thirty years old and above, even to fifty years old, you shall number them, everyone who enters the service to do the work of the tabernacle of meeting.", 
                "num": 30
              }, 
              {
                "text": "And this is what they must carry as all their service for the tabernacle of meeting: the boards of the tabernacle, its bars, its pillars, its sockets,", 
                "num": 31
              }, 
              {
                "text": "and the pillars around the court with their sockets, pegs, and cords, with all their furnishings and all their service; and you shall assign to each man by name the items he must carry.", 
                "num": 32
              }, 
              {
                "text": "This is the service of the families of the sons of Merari, as all their service for the tabernacle of meeting, under the authority of Ithamar the son of Aaron the priest.\"", 
                "num": 33
              }, 
              {
                "text": "And Moses, Aaron, and the leaders of the congregation numbered the sons of the Kohathites by their families and by their fathers' house,", 
                "num": 34
              }, 
              {
                "text": "from thirty years old and above, even to fifty years old, everyone who entered the service for work in the tabernacle of meeting;", 
                "num": 35
              }, 
              {
                "text": "and those who were numbered by their families were two thousand seven hundred and fifty.", 
                "num": 36
              }, 
              {
                "text": "These were the ones who were numbered of the families of the Kohathites, all who might serve in the tabernacle of meeting, whom Moses and Aaron numbered according to the commandment of the LORD by the hand of Moses.", 
                "num": 37
              }, 
              {
                "text": "And those who were numbered of the sons of Gershon, by their families and by their fathers' house,", 
                "num": 38
              }, 
              {
                "text": "from thirty years old and above, even to fifty years old, everyone who entered the service for work in the tabernacle of meeting--", 
                "num": 39
              }, 
              {
                "text": "those who were numbered by their families, by their fathers' house, were two thousand six hundred and thirty.", 
                "num": 40
              }, 
              {
                "text": "These are the ones who were numbered of the families of the sons of Gershon, of all who might serve in the tabernacle of meeting, whom Moses and Aaron numbered according to the commandment of the LORD.", 
                "num": 41
              }, 
              {
                "text": "Those of the families of the sons of Merari who were numbered, by their families, by their fathers' house,", 
                "num": 42
              }, 
              {
                "text": "from thirty years old and above, even to fifty years old, everyone who entered the service for work in the tabernacle of meeting--", 
                "num": 43
              }, 
              {
                "text": "those who were numbered by their families were three thousand two hundred.", 
                "num": 44
              }, 
              {
                "text": "These are the ones who were numbered of the families of the sons of Merari, whom Moses and Aaron numbered according to the word of the LORD by the hand of Moses.", 
                "num": 45
              }, 
              {
                "text": "All who were numbered of the Levites, whom Moses, Aaron, and the leaders of Israel numbered, by their families and by their fathers' houses,", 
                "num": 46
              }, 
              {
                "text": "from thirty years old and above, even to fifty years old, everyone who came to do the work of service and the work of bearing burdens in the tabernacle of meeting--", 
                "num": 47
              }, 
              {
                "text": "those who were numbered were eight thousand five hundred and eighty.", 
                "num": 48
              }, 
              {
                "text": "According to the commandment of the LORD they were numbered by the hand of Moses, each according to his service and according to his task; thus were they numbered by him, as the LORD commanded Moses.", 
                "num": 49
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Command the children of Israel that they put out of the camp every leper, everyone who has a discharge, and whoever becomes defiled by a corpse.", 
                "num": 2
              }, 
              {
                "text": "You shall put out both male and female; you shall put them outside the camp, that they may not defile their camps in the midst of which I dwell.\"", 
                "num": 3
              }, 
              {
                "text": "And the children of Israel did so, and put them outside the camp; as the LORD spoke to Moses, so the children of Israel did.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 5
              }, 
              {
                "text": "\"Speak to the children of Israel: \"When a man or woman commits any sin that men commit in unfaithfulness against the LORD, and that person is guilty,", 
                "num": 6
              }, 
              {
                "text": "then he shall confess the sin which he has committed. He shall make restitution for his trespass in full, plus one-fifth of it, and give it to the one he has wronged.", 
                "num": 7
              }, 
              {
                "text": "But if the man has no relative to whom restitution may be made for the wrong, the restitution for the wrong must go to the LORD for the priest, in addition to the ram of the atonement with which atonement is made for him.", 
                "num": 8
              }, 
              {
                "text": "Every offering of all the holy things of the children of Israel, which they bring to the priest, shall be his.", 
                "num": 9
              }, 
              {
                "text": "And every man's holy things shall be his; whatever any man gives the priest shall be his.\"'", 
                "num": 10
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 11
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"If any man's wife goes astray and behaves unfaithfully toward him,", 
                "num": 12
              }, 
              {
                "text": "and a man lies with her carnally, and it is hidden from the eyes of her husband, and it is concealed that she has defiled herself, and there was no witness against her, nor was she caught--", 
                "num": 13
              }, 
              {
                "text": "if the spirit of jealousy comes upon him and he becomes jealous of his wife, who has defiled herself; or if the spirit of jealousy comes upon him and he becomes jealous of his wife, although she has not defiled herself--", 
                "num": 14
              }, 
              {
                "text": "then the man shall bring his wife to the priest. He shall bring the offering required for her, one-tenth of an ephah of barley meal; he shall pour no oil on it and put no frankincense on it, because it is a grain offering of jealousy, an offering for remembering, for bringing iniquity to remembrance.", 
                "num": 15
              }, 
              {
                "text": "\"And the priest shall bring her near, and set her before the LORD.", 
                "num": 16
              }, 
              {
                "text": "The priest shall take holy water in an earthen vessel, and take some of the dust that is on the floor of the tabernacle and put it into the water.", 
                "num": 17
              }, 
              {
                "text": "Then the priest shall stand the woman before the LORD, uncover the woman's head, and put the offering for remembering in her hands, which is the grain offering of jealousy. And the priest shall have in his hand the bitter water that brings a curse.", 
                "num": 18
              }, 
              {
                "text": "And the priest shall put her under oath, and say to the woman, \"If no man has lain with you, and if you have not gone astray to uncleanness while under your husband's authority, be free from this bitter water that brings a curse.", 
                "num": 19
              }, 
              {
                "text": "But if you have gone astray while under your husband's authority, and if you have defiled yourself and some man other than your husband has lain with you\"--", 
                "num": 20
              }, 
              {
                "text": "then the priest shall put the woman under the oath of the curse, and he shall say to the woman--\"the LORD make you a curse and an oath among your people, when the LORD makes your thigh rot and your belly swell;", 
                "num": 21
              }, 
              {
                "text": "and may this water that causes the curse go into your stomach, and make your belly swell and your thigh rot.\" \"Then the woman shall say, \"Amen, so be it.\"", 
                "num": 22
              }, 
              {
                "text": "\"Then the priest shall write these curses in a book, and he shall scrape them off into the bitter water.", 
                "num": 23
              }, 
              {
                "text": "And he shall make the woman drink the bitter water that brings a curse, and the water that brings the curse shall enter her to become bitter.", 
                "num": 24
              }, 
              {
                "text": "Then the priest shall take the grain offering of jealousy from the woman's hand, shall wave the offering before the LORD, and bring it to the altar;", 
                "num": 25
              }, 
              {
                "text": "and the priest shall take a handful of the offering, as its memorial portion, burn it on the altar, and afterward make the woman drink the water.", 
                "num": 26
              }, 
              {
                "text": "When he has made her drink the water, then it shall be, if she has defiled herself and behaved unfaithfully toward her husband, that the water that brings a curse will enter her and become bitter, and her belly will swell, her thigh will rot, and the woman will become a curse among her people.", 
                "num": 27
              }, 
              {
                "text": "But if the woman has not defiled herself, and is clean, then she shall be free and may conceive children.", 
                "num": 28
              }, 
              {
                "text": "\"This is the law of jealousy, when a wife, while under her husband's authority, goes astray and defiles herself,", 
                "num": 29
              }, 
              {
                "text": "or when the spirit of jealousy comes upon a man, and he becomes jealous of his wife; then he shall stand the woman before the LORD, and the priest shall execute all this law upon her.", 
                "num": 30
              }, 
              {
                "text": "Then the man shall be free from iniquity, but that woman shall bear her guilt.\"'", 
                "num": 31
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When either a man or woman consecrates an offering to take the vow of a Nazirite, to separate himself to the LORD,", 
                "num": 2
              }, 
              {
                "text": "he shall separate himself from wine and similar drink; he shall drink neither vinegar made from wine nor vinegar made from similar drink; neither shall he drink any grape juice, nor eat fresh grapes or raisins.", 
                "num": 3
              }, 
              {
                "text": "All the days of his separation he shall eat nothing that is produced by the grapevine, from seed to skin.", 
                "num": 4
              }, 
              {
                "text": "\"All the days of the vow of his separation no razor shall come upon his head; until the days are fulfilled for which he separated himself to the LORD, he shall be holy. Then he shall let the locks of the hair of his head grow.", 
                "num": 5
              }, 
              {
                "text": "All the days that he separates himself to the LORD he shall not go near a dead body.", 
                "num": 6
              }, 
              {
                "text": "He shall not make himself unclean even for his father or his mother, for his brother or his sister, when they die, because his separation to God is on his head.", 
                "num": 7
              }, 
              {
                "text": "All the days of his separation he shall be holy to the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"And if anyone dies very suddenly beside him, and he defiles his consecrated head, then he shall shave his head on the day of his cleansing; on the seventh day he shall shave it.", 
                "num": 9
              }, 
              {
                "text": "Then on the eighth day he shall bring two turtledoves or two young pigeons to the priest, to the door of the tabernacle of meeting;", 
                "num": 10
              }, 
              {
                "text": "and the priest shall offer one as a sin offering and the other as a burnt offering, and make atonement for him, because he sinned in regard to the corpse; and he shall sanctify his head that same day.", 
                "num": 11
              }, 
              {
                "text": "He shall consecrate to the LORD the days of his separation, and bring a male lamb in its first year as a trespass offering; but the former days shall be lost, because his separation was defiled.", 
                "num": 12
              }, 
              {
                "text": "\"Now this is the law of the Nazirite: When the days of his separation are fulfilled, he shall be brought to the door of the tabernacle of meeting.", 
                "num": 13
              }, 
              {
                "text": "And he shall present his offering to the LORD: one male lamb in its first year without blemish as a burnt offering, one ewe lamb in its first year without blemish as a sin offering, one ram without blemish as a peace offering,", 
                "num": 14
              }, 
              {
                "text": "a basket of unleavened bread, cakes of fine flour mixed with oil, unleavened wafers anointed with oil, and their grain offering with their drink offerings.", 
                "num": 15
              }, 
              {
                "text": "\"Then the priest shall bring them before the LORD and offer his sin offering and his burnt offering;", 
                "num": 16
              }, 
              {
                "text": "and he shall offer the ram as a sacrifice of a peace offering to the LORD, with the basket of unleavened bread; the priest shall also offer its grain offering and its drink offering.", 
                "num": 17
              }, 
              {
                "text": "Then the Nazirite shall shave his consecrated head at the door of the tabernacle of meeting, and shall take the hair from his consecrated head and put it on the fire which is under the sacrifice of the peace offering.", 
                "num": 18
              }, 
              {
                "text": "\"And the priest shall take the boiled shoulder of the ram, one unleavened cake from the basket, and one unleavened wafer, and put them upon the hands of the Nazirite after he has shaved his consecrated hair,", 
                "num": 19
              }, 
              {
                "text": "and the priest shall wave them as a wave offering before the LORD; they are holy for the priest, together with the breast of the wave offering and the thigh of the heave offering. After that the Nazirite may drink wine.'", 
                "num": 20
              }, 
              {
                "text": "\"This is the law of the Nazirite who vows to the LORD the offering for his separation, and besides that, whatever else his hand is able to provide; according to the vow which he takes, so he must do according to the law of his separation.\"", 
                "num": 21
              }, 
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 22
              }, 
              {
                "text": "\"Speak to Aaron and his sons, saying, \"This is the way you shall bless the children of Israel. Say to them:", 
                "num": 23
              }, 
              {
                "text": "\"The LORD bless you and keep you;", 
                "num": 24
              }, 
              {
                "text": "The LORD make His face shine upon you, And be gracious to you;", 
                "num": 25
              }, 
              {
                "text": "The LORD lift up His countenance upon you, And give you peace.\"'", 
                "num": 26
              }, 
              {
                "text": "\"So they shall put My name on the children of Israel, and I will bless them.\"", 
                "num": 27
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Moses had finished setting up the tabernacle, that he anointed it and consecrated it and all its furnishings, and the altar and all its utensils; so he anointed them and consecrated them.", 
                "num": 1
              }, 
              {
                "text": "Then the leaders of Israel, the heads of their fathers' houses, who were the leaders of the tribes and over those who were numbered, made an offering.", 
                "num": 2
              }, 
              {
                "text": "And they brought their offering before the LORD, six covered carts and twelve oxen, a cart for every two of the leaders, and for each one an ox; and they presented them before the tabernacle.", 
                "num": 3
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Accept these from them, that they may be used in doing the work of the tabernacle of meeting; and you shall give them to the Levites, to every man according to his service.\"", 
                "num": 5
              }, 
              {
                "text": "So Moses took the carts and the oxen, and gave them to the Levites.", 
                "num": 6
              }, 
              {
                "text": "Two carts and four oxen he gave to the sons of Gershon, according to their service;", 
                "num": 7
              }, 
              {
                "text": "and four carts and eight oxen he gave to the sons of Merari, according to their service, under the authority of Ithamar the son of Aaron the priest.", 
                "num": 8
              }, 
              {
                "text": "But to the sons of Kohath he gave none, because theirs was the service of the holy things, which they carried on their shoulders.", 
                "num": 9
              }, 
              {
                "text": "Now the leaders offered the dedication offering for the altar when it was anointed; so the leaders offered their offering before the altar.", 
                "num": 10
              }, 
              {
                "text": "For the LORD said to Moses, \"They shall offer their offering, one leader each day, for the dedication of the altar.\"", 
                "num": 11
              }, 
              {
                "text": "And the one who offered his offering on the first day was Nahshon the son of Amminadab, from the tribe of Judah.", 
                "num": 12
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 13
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 14
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 15
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 16
              }, 
              {
                "text": "and for the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Nahshon the son of Amminadab.", 
                "num": 17
              }, 
              {
                "text": "On the second day Nethanel the son of Zuar, leader of Issachar, presented an offering.", 
                "num": 18
              }, 
              {
                "text": "For his offering he offered one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 19
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 20
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 21
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 22
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Nethanel the son of Zuar.", 
                "num": 23
              }, 
              {
                "text": "On the third day Eliab the son of Helon, leader of the children of Zebulun, presented an offering.", 
                "num": 24
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 25
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 26
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 27
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 28
              }, 
              {
                "text": "and for the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Eliab the son of Helon.", 
                "num": 29
              }, 
              {
                "text": "On the fourth day Elizur the son of Shedeur, leader of the children of Reuben, presented an offering.", 
                "num": 30
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 31
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 32
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 33
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 34
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Elizur the son of Shedeur.", 
                "num": 35
              }, 
              {
                "text": "On the fifth day Shelumiel the son of Zurishaddai, leader of the children of Simeon, presented an offering.", 
                "num": 36
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 37
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 38
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 39
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 40
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Shelumiel the son of Zurishaddai.", 
                "num": 41
              }, 
              {
                "text": "On the sixth day Eliasaph the son of Deuel, leader of the children of Gad, presented an offering.", 
                "num": 42
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 43
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 44
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 45
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 46
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Eliasaph the son of Deuel.", 
                "num": 47
              }, 
              {
                "text": "On the seventh day Elishama the son of Ammihud, leader of the children of Ephraim, presented an offering.", 
                "num": 48
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 49
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 50
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 51
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 52
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Elishama the son of Ammihud.", 
                "num": 53
              }, 
              {
                "text": "On the eighth day Gamaliel the son of Pedahzur, leader of the children of Manasseh, presented an offering.", 
                "num": 54
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 55
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 56
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 57
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 58
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Gamaliel the son of Pedahzur.", 
                "num": 59
              }, 
              {
                "text": "On the ninth day Abidan the son of Gideoni, leader of the children of Benjamin, presented an offering.", 
                "num": 60
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 61
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 62
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 63
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 64
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Abidan the son of Gideoni.", 
                "num": 65
              }, 
              {
                "text": "On the tenth day Ahiezer the son of Ammishaddai, leader of the children of Dan, presented an offering.", 
                "num": 66
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 67
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 68
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 69
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 70
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Ahiezer the son of Ammishaddai.", 
                "num": 71
              }, 
              {
                "text": "On the eleventh day Pagiel the son of Ocran, leader of the children of Asher, presented an offering.", 
                "num": 72
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 73
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 74
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 75
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 76
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Pagiel the son of Ocran.", 
                "num": 77
              }, 
              {
                "text": "On the twelfth day Ahira the son of Enan, leader of the children of Naphtali, presented an offering.", 
                "num": 78
              }, 
              {
                "text": "His offering was one silver platter, the weight of which was one hundred and thirty shekels, and one silver bowl of seventy shekels, according to the shekel of the sanctuary, both of them full of fine flour mixed with oil as a grain offering;", 
                "num": 79
              }, 
              {
                "text": "one gold pan of ten shekels, full of incense;", 
                "num": 80
              }, 
              {
                "text": "one young bull, one ram, and one male lamb in its first year, as a burnt offering;", 
                "num": 81
              }, 
              {
                "text": "one kid of the goats as a sin offering;", 
                "num": 82
              }, 
              {
                "text": "and as the sacrifice of peace offerings: two oxen, five rams, five male goats, and five male lambs in their first year. This was the offering of Ahira the son of Enan.", 
                "num": 83
              }, 
              {
                "text": "This was the dedication offering for the altar from the leaders of Israel, when it was anointed: twelve silver platters, twelve silver bowls, and twelve gold pans.", 
                "num": 84
              }, 
              {
                "text": "Each silver platter weighed one hundred and thirty shekels and each bowl seventy shekels. All the silver of the vessels weighed two thousand four hundred shekels, according to the shekel of the sanctuary.", 
                "num": 85
              }, 
              {
                "text": "The twelve gold pans full of incense weighed ten shekels apiece, according to the shekel of the sanctuary; all the gold of the pans weighed one hundred and twenty shekels.", 
                "num": 86
              }, 
              {
                "text": "All the oxen for the burnt offering were twelve young bulls, the rams twelve, the male lambs in their first year twelve, with their grain offering, and the kids of the goats as a sin offering twelve.", 
                "num": 87
              }, 
              {
                "text": "And all the oxen for the sacrifice of peace offerings were twenty-four bulls, the rams sixty, the male goats sixty, and the lambs in their first year sixty. This was the dedication offering for the altar after it was anointed.", 
                "num": 88
              }, 
              {
                "text": "Now when Moses went into the tabernacle of meeting to speak with Him, he heard the voice of One speaking to him from above the mercy seat that was on the ark of the Testimony, from between the two cherubim; thus He spoke to him.", 
                "num": 89
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Speak to Aaron, and say to him, \"When you arrange the lamps, the seven lamps shall give light in front of the lampstand.\"'", 
                "num": 2
              }, 
              {
                "text": "And Aaron did so; he arranged the lamps to face toward the front of the lampstand, as the LORD commanded Moses.", 
                "num": 3
              }, 
              {
                "text": "Now this workmanship of the lampstand was hammered gold; from its shaft to its flowers it was hammered work. According to the pattern which the LORD had shown Moses, so he made the lampstand.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 5
              }, 
              {
                "text": "\"Take the Levites from among the children of Israel and cleanse them ceremonially.", 
                "num": 6
              }, 
              {
                "text": "Thus you shall do to them to cleanse them: Sprinkle water of purification on them, and let them shave all their body, and let them wash their clothes, and so make themselves clean.", 
                "num": 7
              }, 
              {
                "text": "Then let them take a young bull with its grain offering of fine flour mixed with oil, and you shall take another young bull as a sin offering.", 
                "num": 8
              }, 
              {
                "text": "And you shall bring the Levites before the tabernacle of meeting, and you shall gather together the whole congregation of the children of Israel.", 
                "num": 9
              }, 
              {
                "text": "So you shall bring the Levites before the LORD, and the children of Israel shall lay their hands on the Levites;", 
                "num": 10
              }, 
              {
                "text": "and Aaron shall offer the Levites before the LORD like a wave offering from the children of Israel, that they may perform the work of the LORD.", 
                "num": 11
              }, 
              {
                "text": "Then the Levites shall lay their hands on the heads of the young bulls, and you shall offer one as a sin offering and the other as a burnt offering to the LORD, to make atonement for the Levites.", 
                "num": 12
              }, 
              {
                "text": "\"And you shall stand the Levites before Aaron and his sons, and then offer them like a wave offering to the LORD.", 
                "num": 13
              }, 
              {
                "text": "Thus you shall separate the Levites from among the children of Israel, and the Levites shall be Mine.", 
                "num": 14
              }, 
              {
                "text": "After that the Levites shall go in to service the tabernacle of meeting. So you shall cleanse them and offer them like a wave offering.", 
                "num": 15
              }, 
              {
                "text": "For they are wholly given to Me from among the children of Israel; I have taken them for Myself instead of all who open the womb, the firstborn of all the children of Israel.", 
                "num": 16
              }, 
              {
                "text": "For all the firstborn among the children of Israel are Mine, both man and beast; on the day that I struck all the firstborn in the land of Egypt I sanctified them to Myself.", 
                "num": 17
              }, 
              {
                "text": "I have taken the Levites instead of all the firstborn of the children of Israel.", 
                "num": 18
              }, 
              {
                "text": "And I have given the Levites as a gift to Aaron and his sons from among the children of Israel, to do the work for the children of Israel in the tabernacle of meeting, and to make atonement for the children of Israel, that there be no plague among the children of Israel when the children of Israel come near the sanctuary.\"", 
                "num": 19
              }, 
              {
                "text": "Thus Moses and Aaron and all the congregation of the children of Israel did to the Levites; according to all that the LORD commanded Moses concerning the Levites, so the children of Israel did to them.", 
                "num": 20
              }, 
              {
                "text": "And the Levites purified themselves and washed their clothes; then Aaron presented them like a wave offering before the LORD, and Aaron made atonement for them to cleanse them.", 
                "num": 21
              }, 
              {
                "text": "After that the Levites went in to do their work in the tabernacle of meeting before Aaron and his sons; as the LORD commanded Moses concerning the Levites, so they did to them.", 
                "num": 22
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 23
              }, 
              {
                "text": "\"This is what pertains to the Levites: From twenty-five years old and above one may enter to perform service in the work of the tabernacle of meeting;", 
                "num": 24
              }, 
              {
                "text": "and at the age of fifty years they must cease performing this work, and shall work no more.", 
                "num": 25
              }, 
              {
                "text": "They may minister with their brethren in the tabernacle of meeting, to attend to needs, but they themselves shall do no work. Thus you shall do to the Levites regarding their duties.\"", 
                "num": 26
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses in the Wilderness of Sinai, in the first month of the second year after they had come out of the land of Egypt, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Let the children of Israel keep the Passover at its appointed time.", 
                "num": 2
              }, 
              {
                "text": "On the fourteenth day of this month, at twilight, you shall keep it at its appointed time. According to all its rites and ceremonies you shall keep it.\"", 
                "num": 3
              }, 
              {
                "text": "So Moses told the children of Israel that they should keep the Passover.", 
                "num": 4
              }, 
              {
                "text": "And they kept the Passover on the fourteenth day of the first month, at twilight, in the Wilderness of Sinai; according to all that the LORD commanded Moses, so the children of Israel did.", 
                "num": 5
              }, 
              {
                "text": "Now there were certain men who were defiled by a human corpse, so that they could not keep the Passover on that day; and they came before Moses and Aaron that day.", 
                "num": 6
              }, 
              {
                "text": "And those men said to him, \"We became defiled by a human corpse. Why are we kept from presenting the offering of the LORD at its appointed time among the children of Israel?\"", 
                "num": 7
              }, 
              {
                "text": "And Moses said to them, \"Stand still, that I may hear what the LORD will command concerning you.\"", 
                "num": 8
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"If anyone of you or your posterity is unclean because of a corpse, or is far away on a journey, he may still keep the LORD's Passover.", 
                "num": 10
              }, 
              {
                "text": "On the fourteenth day of the second month, at twilight, they may keep it. They shall eat it with unleavened bread and bitter herbs.", 
                "num": 11
              }, 
              {
                "text": "They shall leave none of it until morning, nor break one of its bones. According to all the ordinances of the Passover they shall keep it.", 
                "num": 12
              }, 
              {
                "text": "But the man who is clean and is not on a journey, and ceases to keep the Passover, that same person shall be cut off from among his people, because he did not bring the offering of the LORD at its appointed time; that man shall bear his sin.", 
                "num": 13
              }, 
              {
                "text": "\"And if a stranger dwells among you, and would keep the LORD's Passover, he must do so according to the rite of the Passover and according to its ceremony; you shall have one ordinance, both for the stranger and the native of the land.\"'", 
                "num": 14
              }, 
              {
                "text": "Now on the day that the tabernacle was raised up, the cloud covered the tabernacle, the tent of the Testimony; from evening until morning it was above the tabernacle like the appearance of fire.", 
                "num": 15
              }, 
              {
                "text": "So it was always: the cloud covered it by day, and the appearance of fire by night.", 
                "num": 16
              }, 
              {
                "text": "Whenever the cloud was taken up from above the tabernacle, after that the children of Israel would journey; and in the place where the cloud settled, there the children of Israel would pitch their tents.", 
                "num": 17
              }, 
              {
                "text": "At the command of the LORD the children of Israel would journey, and at the command of the LORD they would camp; as long as the cloud stayed above the tabernacle they remained encamped.", 
                "num": 18
              }, 
              {
                "text": "Even when the cloud continued long, many days above the tabernacle, the children of Israel kept the charge of the LORD and did not journey.", 
                "num": 19
              }, 
              {
                "text": "So it was, when the cloud was above the tabernacle a few days: according to the command of the LORD they would remain encamped, and according to the command of the LORD they would journey.", 
                "num": 20
              }, 
              {
                "text": "So it was, when the cloud remained only from evening until morning: when the cloud was taken up in the morning, then they would journey; whether by day or by night, whenever the cloud was taken up, they would journey.", 
                "num": 21
              }, 
              {
                "text": "Whether it was two days, a month, or a year that the cloud remained above the tabernacle, the children of Israel would remain encamped and not journey; but when it was taken up, they would journey.", 
                "num": 22
              }, 
              {
                "text": "At the command of the LORD they remained encamped, and at the command of the LORD they journeyed; they kept the charge of the LORD, at the command of the LORD by the hand of Moses.", 
                "num": 23
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Make two silver trumpets for yourself; you shall make them of hammered work; you shall use them for calling the congregation and for directing the movement of the camps.", 
                "num": 2
              }, 
              {
                "text": "When they blow both of them, all the congregation shall gather before you at the door of the tabernacle of meeting.", 
                "num": 3
              }, 
              {
                "text": "But if they blow only one, then the leaders, the heads of the divisions of Israel, shall gather to you.", 
                "num": 4
              }, 
              {
                "text": "When you sound the advance, the camps that lie on the east side shall then begin their journey.", 
                "num": 5
              }, 
              {
                "text": "When you sound the advance the second time, then the camps that lie on the south side shall begin their journey; they shall sound the call for them to begin their journeys.", 
                "num": 6
              }, 
              {
                "text": "And when the assembly is to be gathered together, you shall blow, but not sound the advance.", 
                "num": 7
              }, 
              {
                "text": "The sons of Aaron, the priests, shall blow the trumpets; and these shall be to you as an ordinance forever throughout your generations.", 
                "num": 8
              }, 
              {
                "text": "\"When you go to war in your land against the enemy who oppresses you, then you shall sound an alarm with the trumpets, and you will be remembered before the LORD your God, and you will be saved from your enemies.", 
                "num": 9
              }, 
              {
                "text": "Also in the day of your gladness, in your appointed feasts, and at the beginning of your months, you shall blow the trumpets over your burnt offerings and over the sacrifices of your peace offerings; and they shall be a memorial for you before your God: I am the LORD your God.\"", 
                "num": 10
              }, 
              {
                "text": "Now it came to pass on the twentieth day of the second month, in the second year, that the cloud was taken up from above the tabernacle of the Testimony.", 
                "num": 11
              }, 
              {
                "text": "And the children of Israel set out from the Wilderness of Sinai on their journeys; then the cloud settled down in the Wilderness of Paran.", 
                "num": 12
              }, 
              {
                "text": "So they started out for the first time according to the command of the LORD by the hand of Moses.", 
                "num": 13
              }, 
              {
                "text": "The standard of the camp of the children of Judah set out first according to their armies; over their army was Nahshon the son of Amminadab.", 
                "num": 14
              }, 
              {
                "text": "Over the army of the tribe of the children of Issachar was Nethanel the son of Zuar.", 
                "num": 15
              }, 
              {
                "text": "And over the army of the tribe of the children of Zebulun was Eliab the son of Helon.", 
                "num": 16
              }, 
              {
                "text": "Then the tabernacle was taken down; and the sons of Gershon and the sons of Merari set out, carrying the tabernacle.", 
                "num": 17
              }, 
              {
                "text": "And the standard of the camp of Reuben set out according to their armies; over their army was Elizur the son of Shedeur.", 
                "num": 18
              }, 
              {
                "text": "Over the army of the tribe of the children of Simeon was Shelumiel the son of Zurishaddai.", 
                "num": 19
              }, 
              {
                "text": "And over the army of the tribe of the children of Gad was Eliasaph the son of Deuel.", 
                "num": 20
              }, 
              {
                "text": "Then the Kohathites set out, carrying the holy things. (The tabernacle would be prepared for their arrival.)", 
                "num": 21
              }, 
              {
                "text": "And the standard of the camp of the children of Ephraim set out according to their armies; over their army was Elishama the son of Ammihud.", 
                "num": 22
              }, 
              {
                "text": "Over the army of the tribe of the children of Manasseh was Gamaliel the son of Pedahzur.", 
                "num": 23
              }, 
              {
                "text": "And over the army of the tribe of the children of Benjamin was Abidan the son of Gideoni.", 
                "num": 24
              }, 
              {
                "text": "Then the standard of the camp of the children of Dan (the rear guard of all the camps) set out according to their armies; over their army was Ahiezer the son of Ammishaddai.", 
                "num": 25
              }, 
              {
                "text": "Over the army of the tribe of the children of Asher was Pagiel the son of Ocran.", 
                "num": 26
              }, 
              {
                "text": "And over the army of the tribe of the children of Naphtali was Ahira the son of Enan.", 
                "num": 27
              }, 
              {
                "text": "Thus was the order of march of the children of Israel, according to their armies, when they began their journey.", 
                "num": 28
              }, 
              {
                "text": "Now Moses said to Hobab the son of Reuel the Midianite, Moses' father-in-law, \"We are setting out for the place of which the LORD said, \"I will give it to you.' Come with us, and we will treat you well; for the LORD has promised good things to Israel.\"", 
                "num": 29
              }, 
              {
                "text": "And he said to him, \"I will not go, but I will depart to my own land and to my relatives.\"", 
                "num": 30
              }, 
              {
                "text": "So Moses said, \"Please do not leave, inasmuch as you know how we are to camp in the wilderness, and you can be our eyes.", 
                "num": 31
              }, 
              {
                "text": "And it shall be, if you go with us--indeed it shall be--that whatever good the LORD will do to us, the same we will do to you.\"", 
                "num": 32
              }, 
              {
                "text": "So they departed from the mountain of the LORD on a journey of three days; and the ark of the covenant of the LORD went before them for the three days' journey, to search out a resting place for them.", 
                "num": 33
              }, 
              {
                "text": "And the cloud of the LORD was above them by day when they went out from the camp.", 
                "num": 34
              }, 
              {
                "text": "So it was, whenever the ark set out, that Moses said: \"Rise up, O LORD! Let Your enemies be scattered, And let those who hate You flee before You.\"", 
                "num": 35
              }, 
              {
                "text": "And when it rested, he said: \"Return, O LORD, To the many thousands of Israel.\"", 
                "num": 36
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now when the people complained, it displeased the LORD; for the LORD heard it, and His anger was aroused. So the fire of the LORD burned among them, and consumed some in the outskirts of the camp.", 
                "num": 1
              }, 
              {
                "text": "Then the people cried out to Moses, and when Moses prayed to the LORD, the fire was quenched.", 
                "num": 2
              }, 
              {
                "text": "So he called the name of the place Taberah, because the fire of the LORD had burned among them.", 
                "num": 3
              }, 
              {
                "text": "Now the mixed multitude who were among them yielded to intense craving; so the children of Israel also wept again and said: \"Who will give us meat to eat?", 
                "num": 4
              }, 
              {
                "text": "We remember the fish which we ate freely in Egypt, the cucumbers, the melons, the leeks, the onions, and the garlic;", 
                "num": 5
              }, 
              {
                "text": "but now our whole being is dried up; there is nothing at all except this manna before our eyes!\"", 
                "num": 6
              }, 
              {
                "text": "Now the manna was like coriander seed, and its color like the color of bdellium.", 
                "num": 7
              }, 
              {
                "text": "The people went about and gathered it, ground it on millstones or beat it in the mortar, cooked it in pans, and made cakes of it; and its taste was like the taste of pastry prepared with oil.", 
                "num": 8
              }, 
              {
                "text": "And when the dew fell on the camp in the night, the manna fell on it.", 
                "num": 9
              }, 
              {
                "text": "Then Moses heard the people weeping throughout their families, everyone at the door of his tent; and the anger of the LORD was greatly aroused; Moses also was displeased.", 
                "num": 10
              }, 
              {
                "text": "So Moses said to the LORD, \"Why have You afflicted Your servant? And why have I not found favor in Your sight, that You have laid the burden of all these people on me?", 
                "num": 11
              }, 
              {
                "text": "Did I conceive all these people? Did I beget them, that You should say to me, \"Carry them in your bosom, as a guardian carries a nursing child,' to the land which You swore to their fathers?", 
                "num": 12
              }, 
              {
                "text": "Where am I to get meat to give to all these people? For they weep all over me, saying, \"Give us meat, that we may eat.'", 
                "num": 13
              }, 
              {
                "text": "I am not able to bear all these people alone, because the burden is too heavy for me.", 
                "num": 14
              }, 
              {
                "text": "If You treat me like this, please kill me here and now--if I have found favor in Your sight--and do not let me see my wretchedness!\"", 
                "num": 15
              }, 
              {
                "text": "So the LORD said to Moses: \"Gather to Me seventy men of the elders of Israel, whom you know to be the elders of the people and officers over them; bring them to the tabernacle of meeting, that they may stand there with you.", 
                "num": 16
              }, 
              {
                "text": "Then I will come down and talk with you there. I will take of the Spirit that is upon you and will put the same upon them; and they shall bear the burden of the people with you, that you may not bear it yourself alone.", 
                "num": 17
              }, 
              {
                "text": "Then you shall say to the people, \"Consecrate yourselves for tomorrow, and you shall eat meat; for you have wept in the hearing of the LORD, saying, \"Who will give us meat to eat? For it was well with us in Egypt.\" Therefore the LORD will give you meat, and you shall eat.", 
                "num": 18
              }, 
              {
                "text": "You shall eat, not one day, nor two days, nor five days, nor ten days, nor twenty days,", 
                "num": 19
              }, 
              {
                "text": "but for a whole month, until it comes out of your nostrils and becomes loathsome to you, because you have despised the LORD who is among you, and have wept before Him, saying, \"Why did we ever come up out of Egypt?\"\"'", 
                "num": 20
              }, 
              {
                "text": "And Moses said, \"The people whom I am among are six hundred thousand men on foot; yet You have said, \"I will give them meat, that they may eat for a whole month.'", 
                "num": 21
              }, 
              {
                "text": "Shall flocks and herds be slaughtered for them, to provide enough for them? Or shall all the fish of the sea be gathered together for them, to provide enough for them?\"", 
                "num": 22
              }, 
              {
                "text": "And the LORD said to Moses, \"Has the LORD's arm been shortened? Now you shall see whether what I say will happen to you or not.\"", 
                "num": 23
              }, 
              {
                "text": "So Moses went out and told the people the words of the LORD, and he gathered the seventy men of the elders of the people and placed them around the tabernacle.", 
                "num": 24
              }, 
              {
                "text": "Then the LORD came down in the cloud, and spoke to him, and took of the Spirit that was upon him, and placed the same upon the seventy elders; and it happened, when the Spirit rested upon them, that they prophesied, although they never did so again.", 
                "num": 25
              }, 
              {
                "text": "But two men had remained in the camp: the name of one was Eldad, and the name of the other Medad. And the Spirit rested upon them. Now they were among those listed, but who had not gone out to the tabernacle; yet they prophesied in the camp.", 
                "num": 26
              }, 
              {
                "text": "And a young man ran and told Moses, and said, \"Eldad and Medad are prophesying in the camp.\"", 
                "num": 27
              }, 
              {
                "text": "So Joshua the son of Nun, Moses' assistant, one of his choice men, answered and said, \"Moses my lord, forbid them!\"", 
                "num": 28
              }, 
              {
                "text": "Then Moses said to him, \"Are you zealous for my sake? Oh, that all the LORD's people were prophets and that the LORD would put His Spirit upon them!\"", 
                "num": 29
              }, 
              {
                "text": "And Moses returned to the camp, he and the elders of Israel.", 
                "num": 30
              }, 
              {
                "text": "Now a wind went out from the LORD, and it brought quail from the sea and left them fluttering near the camp, about a day's journey on this side and about a day's journey on the other side, all around the camp, and about two cubits above the surface of the ground.", 
                "num": 31
              }, 
              {
                "text": "And the people stayed up all that day, all night, and all the next day, and gathered the quail (he who gathered least gathered ten homers); and they spread them out for themselves all around the camp.", 
                "num": 32
              }, 
              {
                "text": "But while the meat was still between their teeth, before it was chewed, the wrath of the LORD was aroused against the people, and the LORD struck the people with a very great plague.", 
                "num": 33
              }, 
              {
                "text": "So he called the name of that place Kibroth Hattaavah, because there they buried the people who had yielded to craving.", 
                "num": 34
              }, 
              {
                "text": "From Kibroth Hattaavah the people moved to Hazeroth, and camped at Hazeroth.", 
                "num": 35
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then Miriam and Aaron spoke against Moses because of the Ethiopian woman whom he had married; for he had married an Ethiopian woman.", 
                "num": 1
              }, 
              {
                "text": "So they said, \"Has the LORD indeed spoken only through Moses? Has He not spoken through us also?\" And the LORD heard it.", 
                "num": 2
              }, 
              {
                "text": "(Now the man Moses was very humble, more than all men who were on the face of the earth.)", 
                "num": 3
              }, 
              {
                "text": "Suddenly the LORD said to Moses, Aaron, and Miriam, \"Come out, you three, to the tabernacle of meeting!\" So the three came out.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD came down in the pillar of cloud and stood in the door of the tabernacle, and called Aaron and Miriam. And they both went forward.", 
                "num": 5
              }, 
              {
                "text": "Then He said, If there is a prophet among you, I, the LORD, make Myself known to him in a vision; I speak to him in a dream.", 
                "num": 6
              }, 
              {
                "text": "Not so with My servant Moses; He is faithful in all My house.", 
                "num": 7
              }, 
              {
                "text": "I speak with him face to face, Even plainly, and not in dark sayings; And he sees the form of the LORD. Why then were you not afraid To speak against My servant Moses?\"", 
                "num": 8
              }, 
              {
                "text": "So the anger of the LORD was aroused against them, and He departed.", 
                "num": 9
              }, 
              {
                "text": "And when the cloud departed from above the tabernacle, suddenly Miriam became leprous, as white as snow. Then Aaron turned toward Miriam, and there she was, a leper.", 
                "num": 10
              }, 
              {
                "text": "So Aaron said to Moses, \"Oh, my lord! Please do not lay this sin on us, in which we have done foolishly and in which we have sinned.", 
                "num": 11
              }, 
              {
                "text": "Please do not let her be as one dead, whose flesh is half consumed when he comes out of his mother's womb!\"", 
                "num": 12
              }, 
              {
                "text": "So Moses cried out to the LORD, saying, \"Please heal her, O God, I pray!\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD said to Moses, \"If her father had but spit in her face, would she not be shamed seven days? Let her be shut out of the camp seven days, and afterward she may be received again.\"", 
                "num": 14
              }, 
              {
                "text": "So Miriam was shut out of the camp seven days, and the people did not journey till Miriam was brought in again.", 
                "num": 15
              }, 
              {
                "text": "And afterward the people moved from Hazeroth and camped in the Wilderness of Paran.", 
                "num": 16
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Send men to spy out the land of Canaan, which I am giving to the children of Israel; from each tribe of their fathers you shall send a man, every one a leader among them.\"", 
                "num": 2
              }, 
              {
                "text": "So Moses sent them from the Wilderness of Paran according to the command of the LORD, all of them men who were heads of the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "Now these were their names: from the tribe of Reuben, Shammua the son of Zaccur;", 
                "num": 4
              }, 
              {
                "text": "from the tribe of Simeon, Shaphat the son of Hori;", 
                "num": 5
              }, 
              {
                "text": "from the tribe of Judah, Caleb the son of Jephunneh;", 
                "num": 6
              }, 
              {
                "text": "from the tribe of Issachar, Igal the son of Joseph;", 
                "num": 7
              }, 
              {
                "text": "from the tribe of Ephraim, Hoshea the son of Nun;", 
                "num": 8
              }, 
              {
                "text": "from the tribe of Benjamin, Palti the son of Raphu;", 
                "num": 9
              }, 
              {
                "text": "from the tribe of Zebulun, Gaddiel the son of Sodi;", 
                "num": 10
              }, 
              {
                "text": "from the tribe of Joseph, that is, from the tribe of Manasseh, Gaddi the son of Susi;", 
                "num": 11
              }, 
              {
                "text": "from the tribe of Dan, Ammiel the son of Gemalli;", 
                "num": 12
              }, 
              {
                "text": "from the tribe of Asher, Sethur the son of Michael;", 
                "num": 13
              }, 
              {
                "text": "from the tribe of Naphtali, Nahbi the son of Vophsi;", 
                "num": 14
              }, 
              {
                "text": "from the tribe of Gad, Geuel the son of Machi.", 
                "num": 15
              }, 
              {
                "text": "These are the names of the men whom Moses sent to spy out the land. And Moses called Hoshea the son of Nun, Joshua.", 
                "num": 16
              }, 
              {
                "text": "Then Moses sent them to spy out the land of Canaan, and said to them, \"Go up this way into the South, and go up to the mountains,", 
                "num": 17
              }, 
              {
                "text": "and see what the land is like: whether the people who dwell in it are strong or weak, few or many;", 
                "num": 18
              }, 
              {
                "text": "whether the land they dwell in is good or bad; whether the cities they inhabit are like camps or strongholds;", 
                "num": 19
              }, 
              {
                "text": "whether the land is rich or poor; and whether there are forests there or not. Be of good courage. And bring some of the fruit of the land.\" Now the time was the season of the first ripe grapes.", 
                "num": 20
              }, 
              {
                "text": "So they went up and spied out the land from the Wilderness of Zin as far as Rehob, near the entrance of Hamath.", 
                "num": 21
              }, 
              {
                "text": "And they went up through the South and came to Hebron; Ahiman, Sheshai, and Talmai, the descendants of Anak, were there. (Now Hebron was built seven years before Zoan in Egypt.)", 
                "num": 22
              }, 
              {
                "text": "Then they came to the Valley of Eshcol, and there cut down a branch with one cluster of grapes; they carried it between two of them on a pole. They also brought some of the pomegranates and figs.", 
                "num": 23
              }, 
              {
                "text": "The place was called the Valley of Eshcol, because of the cluster which the men of Israel cut down there.", 
                "num": 24
              }, 
              {
                "text": "And they returned from spying out the land after forty days.", 
                "num": 25
              }, 
              {
                "text": "Now they departed and came back to Moses and Aaron and all the congregation of the children of Israel in the Wilderness of Paran, at Kadesh; they brought back word to them and to all the congregation, and showed them the fruit of the land.", 
                "num": 26
              }, 
              {
                "text": "Then they told him, and said: \"We went to the land where you sent us. It truly flows with milk and honey, and this is its fruit.", 
                "num": 27
              }, 
              {
                "text": "Nevertheless the people who dwell in the land are strong; the cities are fortified and very large; moreover we saw the descendants of Anak there.", 
                "num": 28
              }, 
              {
                "text": "The Amalekites dwell in the land of the South; the Hittites, the Jebusites, and the Amorites dwell in the mountains; and the Canaanites dwell by the sea and along the banks of the Jordan.\"", 
                "num": 29
              }, 
              {
                "text": "Then Caleb quieted the people before Moses, and said, \"Let us go up at once and take possession, for we are well able to overcome it.\"", 
                "num": 30
              }, 
              {
                "text": "But the men who had gone up with him said, \"We are not able to go up against the people, for they are stronger than we.\"", 
                "num": 31
              }, 
              {
                "text": "And they gave the children of Israel a bad report of the land which they had spied out, saying, \"The land through which we have gone as spies is a land that devours its inhabitants, and all the people whom we saw in it are men of great stature.", 
                "num": 32
              }, 
              {
                "text": "There we saw the giants (the descendants of Anak came from the giants); and we were like grasshoppers in our own sight, and so we were in their sight.\"", 
                "num": 33
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "So all the congregation lifted up their voices and cried, and the people wept that night.", 
                "num": 1
              }, 
              {
                "text": "And all the children of Israel complained against Moses and Aaron, and the whole congregation said to them, \"If only we had died in the land of Egypt! Or if only we had died in this wilderness!", 
                "num": 2
              }, 
              {
                "text": "Why has the LORD brought us to this land to fall by the sword, that our wives and children should become victims? Would it not be better for us to return to Egypt?\"", 
                "num": 3
              }, 
              {
                "text": "So they said to one another, \"Let us select a leader and return to Egypt.\"", 
                "num": 4
              }, 
              {
                "text": "Then Moses and Aaron fell on their faces before all the assembly of the congregation of the children of Israel.", 
                "num": 5
              }, 
              {
                "text": "But Joshua the son of Nun and Caleb the son of Jephunneh, who were among those who had spied out the land, tore their clothes;", 
                "num": 6
              }, 
              {
                "text": "and they spoke to all the congregation of the children of Israel, saying: \"The land we passed through to spy out is an exceedingly good land.", 
                "num": 7
              }, 
              {
                "text": "If the LORD delights in us, then He will bring us into this land and give it to us, \"a land which flows with milk and honey.'", 
                "num": 8
              }, 
              {
                "text": "Only do not rebel against the LORD, nor fear the people of the land, for they are our bread; their protection has departed from them, and the LORD is with us. Do not fear them.\"", 
                "num": 9
              }, 
              {
                "text": "And all the congregation said to stone them with stones. Now the glory of the LORD appeared in the tabernacle of meeting before all the children of Israel.", 
                "num": 10
              }, 
              {
                "text": "Then the LORD said to Moses: \"How long will these people reject Me? And how long will they not believe Me, with all the signs which I have performed among them?", 
                "num": 11
              }, 
              {
                "text": "I will strike them with the pestilence and disinherit them, and I will make of you a nation greater and mightier than they.\"", 
                "num": 12
              }, 
              {
                "text": "And Moses said to the LORD: \"Then the Egyptians will hear it, for by Your might You brought these people up from among them,", 
                "num": 13
              }, 
              {
                "text": "and they will tell it to the inhabitants of this land. They have heard that You, LORD, are among these people; that You, LORD, are seen face to face and Your cloud stands above them, and You go before them in a pillar of cloud by day and in a pillar of fire by night.", 
                "num": 14
              }, 
              {
                "text": "Now if You kill these people as one man, then the nations which have heard of Your fame will speak, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Because the LORD was not able to bring this people to the land which He swore to give them, therefore He killed them in the wilderness.'", 
                "num": 16
              }, 
              {
                "text": "And now, I pray, let the power of my Lord be great, just as You have spoken, saying,", 
                "num": 17
              }, 
              {
                "text": "\"The LORD is longsuffering and abundant in mercy, forgiving iniquity and transgression; but He by no means clears the guilty, visiting the iniquity of the fathers on the children to the third and fourth generation.'", 
                "num": 18
              }, 
              {
                "text": "Pardon the iniquity of this people, I pray, according to the greatness of Your mercy, just as You have forgiven this people, from Egypt even until now.\"", 
                "num": 19
              }, 
              {
                "text": "Then the LORD said: \"I have pardoned, according to your word;", 
                "num": 20
              }, 
              {
                "text": "but truly, as I live, all the earth shall be filled with the glory of the LORD--", 
                "num": 21
              }, 
              {
                "text": "because all these men who have seen My glory and the signs which I did in Egypt and in the wilderness, and have put Me to the test now these ten times, and have not heeded My voice,", 
                "num": 22
              }, 
              {
                "text": "they certainly shall not see the land of which I swore to their fathers, nor shall any of those who rejected Me see it.", 
                "num": 23
              }, 
              {
                "text": "But My servant Caleb, because he has a different spirit in him and has followed Me fully, I will bring into the land where he went, and his descendants shall inherit it.", 
                "num": 24
              }, 
              {
                "text": "Now the Amalekites and the Canaanites dwell in the valley; tomorrow turn and move out into the wilderness by the Way of the Red Sea.\"", 
                "num": 25
              }, 
              {
                "text": "And the LORD spoke to Moses and Aaron, saying,", 
                "num": 26
              }, 
              {
                "text": "\"How long shall I bear with this evil congregation who complain against Me? I have heard the complaints which the children of Israel make against Me.", 
                "num": 27
              }, 
              {
                "text": "Say to them, \"As I live,' says the LORD, \"just as you have spoken in My hearing, so I will do to you:", 
                "num": 28
              }, 
              {
                "text": "The carcasses of you who have complained against Me shall fall in this wilderness, all of you who were numbered, according to your entire number, from twenty years old and above.", 
                "num": 29
              }, 
              {
                "text": "Except for Caleb the son of Jephunneh and Joshua the son of Nun, you shall by no means enter the land which I swore I would make you dwell in.", 
                "num": 30
              }, 
              {
                "text": "But your little ones, whom you said would be victims, I will bring in, and they shall know the land which you have despised.", 
                "num": 31
              }, 
              {
                "text": "But as for you, your carcasses shall fall in this wilderness.", 
                "num": 32
              }, 
              {
                "text": "And your sons shall be shepherds in the wilderness forty years, and bear the brunt of your infidelity, until your carcasses are consumed in the wilderness.", 
                "num": 33
              }, 
              {
                "text": "According to the number of the days in which you spied out the land, forty days, for each day you shall bear your guilt one year, namely forty years, and you shall know My rejection.", 
                "num": 34
              }, 
              {
                "text": "I the LORD have spoken this. I will surely do so to all this evil congregation who are gathered together against Me. In this wilderness they shall be consumed, and there they shall die.\"'", 
                "num": 35
              }, 
              {
                "text": "Now the men whom Moses sent to spy out the land, who returned and made all the congregation complain against him by bringing a bad report of the land,", 
                "num": 36
              }, 
              {
                "text": "those very men who brought the evil report about the land, died by the plague before the LORD.", 
                "num": 37
              }, 
              {
                "text": "But Joshua the son of Nun and Caleb the son of Jephunneh remained alive, of the men who went to spy out the land.", 
                "num": 38
              }, 
              {
                "text": "Then Moses told these words to all the children of Israel, and the people mourned greatly.", 
                "num": 39
              }, 
              {
                "text": "And they rose early in the morning and went up to the top of the mountain, saying, \"Here we are, and we will go up to the place which the LORD has promised, for we have sinned!\"", 
                "num": 40
              }, 
              {
                "text": "And Moses said, \"Now why do you transgress the command of the LORD? For this will not succeed.", 
                "num": 41
              }, 
              {
                "text": "Do not go up, lest you be defeated by your enemies, for the LORD is not among you.", 
                "num": 42
              }, 
              {
                "text": "For the Amalekites and the Canaanites are there before you, and you shall fall by the sword; because you have turned away from the LORD, the LORD will not be with you.\"", 
                "num": 43
              }, 
              {
                "text": "But they presumed to go up to the mountaintop. Nevertheless, neither the ark of the covenant of the LORD nor Moses departed from the camp.", 
                "num": 44
              }, 
              {
                "text": "Then the Amalekites and the Canaanites who dwelt in that mountain came down and attacked them, and drove them back as far as Hormah.", 
                "num": 45
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you have come into the land you are to inhabit, which I am giving to you,", 
                "num": 2
              }, 
              {
                "text": "and you make an offering by fire to the LORD, a burnt offering or a sacrifice, to fulfill a vow or as a freewill offering or in your appointed feasts, to make a sweet aroma to the LORD, from the herd or the flock,", 
                "num": 3
              }, 
              {
                "text": "then he who presents his offering to the LORD shall bring a grain offering of one-tenth of an ephah of fine flour mixed with one-fourth of a hin of oil;", 
                "num": 4
              }, 
              {
                "text": "and one-fourth of a hin of wine as a drink offering you shall prepare with the burnt offering or the sacrifice, for each lamb.", 
                "num": 5
              }, 
              {
                "text": "Or for a ram you shall prepare as a grain offering two-tenths of an ephah of fine flour mixed with one-third of a hin of oil;", 
                "num": 6
              }, 
              {
                "text": "and as a drink offering you shall offer one-third of a hin of wine as a sweet aroma to the LORD.", 
                "num": 7
              }, 
              {
                "text": "And when you prepare a young bull as a burnt offering, or as a sacrifice to fulfill a vow, or as a peace offering to the LORD,", 
                "num": 8
              }, 
              {
                "text": "then shall be offered with the young bull a grain offering of three-tenths of an ephah of fine flour mixed with half a hin of oil;", 
                "num": 9
              }, 
              {
                "text": "and you shall bring as the drink offering half a hin of wine as an offering made by fire, a sweet aroma to the LORD.", 
                "num": 10
              }, 
              {
                "text": "\"Thus it shall be done for each young bull, for each ram, or for each lamb or young goat.", 
                "num": 11
              }, 
              {
                "text": "According to the number that you prepare, so you shall do with everyone according to their number.", 
                "num": 12
              }, 
              {
                "text": "All who are native-born shall do these things in this manner, in presenting an offering made by fire, a sweet aroma to the LORD.", 
                "num": 13
              }, 
              {
                "text": "And if a stranger dwells with you, or whoever is among you throughout your generations, and would present an offering made by fire, a sweet aroma to the LORD, just as you do, so shall he do.", 
                "num": 14
              }, 
              {
                "text": "One ordinance shall be for you of the assembly and for the stranger who dwells with you, an ordinance forever throughout your generations; as you are, so shall the stranger be before the LORD.", 
                "num": 15
              }, 
              {
                "text": "One law and one custom shall be for you and for the stranger who dwells with you.\"'", 
                "num": 16
              }, 
              {
                "text": "Again the LORD spoke to Moses, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you come into the land to which I bring you,", 
                "num": 18
              }, 
              {
                "text": "then it will be, when you eat of the bread of the land, that you shall offer up a heave offering to the LORD.", 
                "num": 19
              }, 
              {
                "text": "You shall offer up a cake of the first of your ground meal as a heave offering; as a heave offering of the threshing floor, so shall you offer it up.", 
                "num": 20
              }, 
              {
                "text": "Of the first of your ground meal you shall give to the LORD a heave offering throughout your generations.", 
                "num": 21
              }, 
              {
                "text": "\"If you sin unintentionally, and do not observe all these commandments which the LORD has spoken to Moses--", 
                "num": 22
              }, 
              {
                "text": "all that the LORD has commanded you by the hand of Moses, from the day the LORD gave commandment and onward throughout your generations--", 
                "num": 23
              }, 
              {
                "text": "then it will be, if it is unintentionally committed, without the knowledge of the congregation, that the whole congregation shall offer one young bull as a burnt offering, as a sweet aroma to the LORD, with its grain offering and its drink offering, according to the ordinance, and one kid of the goats as a sin offering.", 
                "num": 24
              }, 
              {
                "text": "So the priest shall make atonement for the whole congregation of the children of Israel, and it shall be forgiven them, for it was unintentional; they shall bring their offering, an offering made by fire to the LORD, and their sin offering before the LORD, for their unintended sin.", 
                "num": 25
              }, 
              {
                "text": "It shall be forgiven the whole congregation of the children of Israel and the stranger who dwells among them, because all the people did it unintentionally.", 
                "num": 26
              }, 
              {
                "text": "\"And if a person sins unintentionally, then he shall bring a female goat in its first year as a sin offering.", 
                "num": 27
              }, 
              {
                "text": "So the priest shall make atonement for the person who sins unintentionally, when he sins unintentionally before the LORD, to make atonement for him; and it shall be forgiven him.", 
                "num": 28
              }, 
              {
                "text": "You shall have one law for him who sins unintentionally, for him who is native-born among the children of Israel and for the stranger who dwells among them.", 
                "num": 29
              }, 
              {
                "text": "\"But the person who does anything presumptuously, whether he is native-born or a stranger, that one brings reproach on the LORD, and he shall be cut off from among his people.", 
                "num": 30
              }, 
              {
                "text": "Because he has despised the word of the LORD, and has broken His commandment, that person shall be completely cut off; his guilt shall be upon him.\"'", 
                "num": 31
              }, 
              {
                "text": "Now while the children of Israel were in the wilderness, they found a man gathering sticks on the Sabbath day.", 
                "num": 32
              }, 
              {
                "text": "And those who found him gathering sticks brought him to Moses and Aaron, and to all the congregation.", 
                "num": 33
              }, 
              {
                "text": "They put him under guard, because it had not been explained what should be done to him.", 
                "num": 34
              }, 
              {
                "text": "Then the LORD said to Moses, \"The man must surely be put to death; all the congregation shall stone him with stones outside the camp.\"", 
                "num": 35
              }, 
              {
                "text": "So, as the LORD commanded Moses, all the congregation brought him outside the camp and stoned him with stones, and he died.", 
                "num": 36
              }, 
              {
                "text": "Again the LORD spoke to Moses, saying,", 
                "num": 37
              }, 
              {
                "text": "\"Speak to the children of Israel: Tell them to make tassels on the corners of their garments throughout their generations, and to put a blue thread in the tassels of the corners.", 
                "num": 38
              }, 
              {
                "text": "And you shall have the tassel, that you may look upon it and remember all the commandments of the LORD and do them, and that you may not follow the harlotry to which your own heart and your own eyes are inclined,", 
                "num": 39
              }, 
              {
                "text": "and that you may remember and do all My commandments, and be holy for your God.", 
                "num": 40
              }, 
              {
                "text": "I am the LORD your God, who brought you out of the land of Egypt, to be your God: I am the LORD your God.\"", 
                "num": 41
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now Korah the son of Izhar, the son of Kohath, the son of Levi, with Dathan and Abiram the sons of Eliab, and On the son of Peleth, sons of Reuben, took men;", 
                "num": 1
              }, 
              {
                "text": "and they rose up before Moses with some of the children of Israel, two hundred and fifty leaders of the congregation, representatives of the congregation, men of renown.", 
                "num": 2
              }, 
              {
                "text": "They gathered together against Moses and Aaron, and said to them, \"You take too much upon yourselves, for all the congregation is holy, every one of them, and the LORD is among them. Why then do you exalt yourselves above the assembly of the LORD?\"", 
                "num": 3
              }, 
              {
                "text": "So when Moses heard it, he fell on his face;", 
                "num": 4
              }, 
              {
                "text": "and he spoke to Korah and all his company, saying, \"Tomorrow morning the LORD will show who is His and who is holy, and will cause him to come near to Him. That one whom He chooses He will cause to come near to Him.", 
                "num": 5
              }, 
              {
                "text": "Do this: Take censers, Korah and all your company;", 
                "num": 6
              }, 
              {
                "text": "put fire in them and put incense in them before the LORD tomorrow, and it shall be that the man whom the LORD chooses is the holy one. You take too much upon yourselves, you sons of Levi!\"", 
                "num": 7
              }, 
              {
                "text": "Then Moses said to Korah, \"Hear now, you sons of Levi:", 
                "num": 8
              }, 
              {
                "text": "Is it a small thing to you that the God of Israel has separated you from the congregation of Israel, to bring you near to Himself, to do the work of the tabernacle of the LORD, and to stand before the congregation to serve them;", 
                "num": 9
              }, 
              {
                "text": "and that He has brought you near to Himself, you and all your brethren, the sons of Levi, with you? And are you seeking the priesthood also?", 
                "num": 10
              }, 
              {
                "text": "Therefore you and all your company are gathered together against the LORD. And what is Aaron that you complain against him?\"", 
                "num": 11
              }, 
              {
                "text": "And Moses sent to call Dathan and Abiram the sons of Eliab, but they said, \"We will not come up!", 
                "num": 12
              }, 
              {
                "text": "Is it a small thing that you have brought us up out of a land flowing with milk and honey, to kill us in the wilderness, that you should keep acting like a prince over us?", 
                "num": 13
              }, 
              {
                "text": "Moreover you have not brought us into a land flowing with milk and honey, nor given us inheritance of fields and vineyards. Will you put out the eyes of these men? We will not come up!\"", 
                "num": 14
              }, 
              {
                "text": "Then Moses was very angry, and said to the LORD, \"Do not respect their offering. I have not taken one donkey from them, nor have I hurt one of them.\"", 
                "num": 15
              }, 
              {
                "text": "And Moses said to Korah, \"Tomorrow, you and all your company be present before the LORD--you and they, as well as Aaron.", 
                "num": 16
              }, 
              {
                "text": "Let each take his censer and put incense in it, and each of you bring his censer before the LORD, two hundred and fifty censers; both you and Aaron, each with his censer.\"", 
                "num": 17
              }, 
              {
                "text": "So every man took his censer, put fire in it, laid incense on it, and stood at the door of the tabernacle of meeting with Moses and Aaron.", 
                "num": 18
              }, 
              {
                "text": "And Korah gathered all the congregation against them at the door of the tabernacle of meeting. Then the glory of the LORD appeared to all the congregation.", 
                "num": 19
              }, 
              {
                "text": "And the LORD spoke to Moses and Aaron, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Separate yourselves from among this congregation, that I may consume them in a moment.\"", 
                "num": 21
              }, 
              {
                "text": "Then they fell on their faces, and said, \"O God, the God of the spirits of all flesh, shall one man sin, and You be angry with all the congregation?\"", 
                "num": 22
              }, 
              {
                "text": "So the LORD spoke to Moses, saying,", 
                "num": 23
              }, 
              {
                "text": "\"Speak to the congregation, saying, \"Get away from the tents of Korah, Dathan, and Abiram.\"'", 
                "num": 24
              }, 
              {
                "text": "Then Moses rose and went to Dathan and Abiram, and the elders of Israel followed him.", 
                "num": 25
              }, 
              {
                "text": "And he spoke to the congregation, saying, \"Depart now from the tents of these wicked men! Touch nothing of theirs, lest you be consumed in all their sins.\"", 
                "num": 26
              }, 
              {
                "text": "So they got away from around the tents of Korah, Dathan, and Abiram; and Dathan and Abiram came out and stood at the door of their tents, with their wives, their sons, and their little children.", 
                "num": 27
              }, 
              {
                "text": "And Moses said: \"By this you shall know that the LORD has sent me to do all these works, for I have not done them of my own will.", 
                "num": 28
              }, 
              {
                "text": "If these men die naturally like all men, or if they are visited by the common fate of all men, then the LORD has not sent me.", 
                "num": 29
              }, 
              {
                "text": "But if the LORD creates a new thing, and the earth opens its mouth and swallows them up with all that belongs to them, and they go down alive into the pit, then you will understand that these men have rejected the LORD.\"", 
                "num": 30
              }, 
              {
                "text": "Now it came to pass, as he finished speaking all these words, that the ground split apart under them,", 
                "num": 31
              }, 
              {
                "text": "and the earth opened its mouth and swallowed them up, with their households and all the men with Korah, with all their goods.", 
                "num": 32
              }, 
              {
                "text": "So they and all those with them went down alive into the pit; the earth closed over them, and they perished from among the assembly.", 
                "num": 33
              }, 
              {
                "text": "Then all Israel who were around them fled at their cry, for they said, \"Lest the earth swallow us up also!\"", 
                "num": 34
              }, 
              {
                "text": "And a fire came out from the LORD and consumed the two hundred and fifty men who were offering incense.", 
                "num": 35
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 36
              }, 
              {
                "text": "\"Tell Eleazar, the son of Aaron the priest, to pick up the censers out of the blaze, for they are holy, and scatter the fire some distance away.", 
                "num": 37
              }, 
              {
                "text": "The censers of these men who sinned against their own souls, let them be made into hammered plates as a covering for the altar. Because they presented them before the LORD, therefore they are holy; and they shall be a sign to the children of Israel.\"", 
                "num": 38
              }, 
              {
                "text": "So Eleazar the priest took the bronze censers, which those who were burned up had presented, and they were hammered out as a covering on the altar,", 
                "num": 39
              }, 
              {
                "text": "to be a memorial to the children of Israel that no outsider, who is not a descendant of Aaron, should come near to offer incense before the LORD, that he might not become like Korah and his companions, just as the LORD had said to him through Moses.", 
                "num": 40
              }, 
              {
                "text": "On the next day all the congregation of the children of Israel complained against Moses and Aaron, saying, \"You have killed the people of the LORD.\"", 
                "num": 41
              }, 
              {
                "text": "Now it happened, when the congregation had gathered against Moses and Aaron, that they turned toward the tabernacle of meeting; and suddenly the cloud covered it, and the glory of the LORD appeared.", 
                "num": 42
              }, 
              {
                "text": "Then Moses and Aaron came before the tabernacle of meeting.", 
                "num": 43
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 44
              }, 
              {
                "text": "\"Get away from among this congregation, that I may consume them in a moment.\" And they fell on their faces.", 
                "num": 45
              }, 
              {
                "text": "So Moses said to Aaron, \"Take a censer and put fire in it from the altar, put incense on it, and take it quickly to the congregation and make atonement for them; for wrath has gone out from the LORD. The plague has begun.\"", 
                "num": 46
              }, 
              {
                "text": "Then Aaron took it as Moses commanded, and ran into the midst of the assembly; and already the plague had begun among the people. So he put in the incense and made atonement for the people.", 
                "num": 47
              }, 
              {
                "text": "And he stood between the dead and the living; so the plague was stopped.", 
                "num": 48
              }, 
              {
                "text": "Now those who died in the plague were fourteen thousand seven hundred, besides those who died in the Korah incident.", 
                "num": 49
              }, 
              {
                "text": "So Aaron returned to Moses at the door of the tabernacle of meeting, for the plague had stopped.", 
                "num": 50
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, and get from them a rod from each father's house, all their leaders according to their fathers' houses--twelve rods. Write each man's name on his rod.", 
                "num": 2
              }, 
              {
                "text": "And you shall write Aaron's name on the rod of Levi. For there shall be one rod for the head of each father's house.", 
                "num": 3
              }, 
              {
                "text": "Then you shall place them in the tabernacle of meeting before the Testimony, where I meet with you.", 
                "num": 4
              }, 
              {
                "text": "And it shall be that the rod of the man whom I choose will blossom; thus I will rid Myself of the complaints of the children of Israel, which they make against you.\"", 
                "num": 5
              }, 
              {
                "text": "So Moses spoke to the children of Israel, and each of their leaders gave him a rod apiece, for each leader according to their fathers' houses, twelve rods; and the rod of Aaron was among their rods.", 
                "num": 6
              }, 
              {
                "text": "And Moses placed the rods before the LORD in the tabernacle of witness.", 
                "num": 7
              }, 
              {
                "text": "Now it came to pass on the next day that Moses went into the tabernacle of witness, and behold, the rod of Aaron, of the house of Levi, had sprouted and put forth buds, had produced blossoms and yielded ripe almonds.", 
                "num": 8
              }, 
              {
                "text": "Then Moses brought out all the rods from before the LORD to all the children of Israel; and they looked, and each man took his rod.", 
                "num": 9
              }, 
              {
                "text": "And the LORD said to Moses, \"Bring Aaron's rod back before the Testimony, to be kept as a sign against the rebels, that you may put their complaints away from Me, lest they die.\"", 
                "num": 10
              }, 
              {
                "text": "Thus did Moses; just as the LORD had commanded him, so he did.", 
                "num": 11
              }, 
              {
                "text": "So the children of Israel spoke to Moses, saying, \"Surely we die, we perish, we all perish!", 
                "num": 12
              }, 
              {
                "text": "Whoever even comes near the tabernacle of the LORD must die. Shall we all utterly die?\"", 
                "num": 13
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to Aaron: \"You and your sons and your father's house with you shall bear the iniquity related to the sanctuary, and you and your sons with you shall bear the iniquity associated with your priesthood.", 
                "num": 1
              }, 
              {
                "text": "Also bring with you your brethren of the tribe of Levi, the tribe of your father, that they may be joined with you and serve you while you and your sons are with you before the tabernacle of witness.", 
                "num": 2
              }, 
              {
                "text": "They shall attend to your needs and all the needs of the tabernacle; but they shall not come near the articles of the sanctuary and the altar, lest they die--they and you also.", 
                "num": 3
              }, 
              {
                "text": "They shall be joined with you and attend to the needs of the tabernacle of meeting, for all the work of the tabernacle; but an outsider shall not come near you.", 
                "num": 4
              }, 
              {
                "text": "And you shall attend to the duties of the sanctuary and the duties of the altar, that there may be no more wrath on the children of Israel.", 
                "num": 5
              }, 
              {
                "text": "Behold, I Myself have taken your brethren the Levites from among the children of Israel; they are a gift to you, given by the LORD, to do the work of the tabernacle of meeting.", 
                "num": 6
              }, 
              {
                "text": "Therefore you and your sons with you shall attend to your priesthood for everything at the altar and behind the veil; and you shall serve. I give your priesthood to you as a gift for service, but the outsider who comes near shall be put to death.\"", 
                "num": 7
              }, 
              {
                "text": "And the LORD spoke to Aaron: \"Here, I Myself have also given you charge of My heave offerings, all the holy gifts of the children of Israel; I have given them as a portion to you and your sons, as an ordinance forever.", 
                "num": 8
              }, 
              {
                "text": "This shall be yours of the most holy things reserved from the fire: every offering of theirs, every grain offering and every sin offering and every trespass offering which they render to Me, shall be most holy for you and your sons.", 
                "num": 9
              }, 
              {
                "text": "In a most holy place you shall eat it; every male shall eat it. It shall be holy to you.", 
                "num": 10
              }, 
              {
                "text": "\"This also is yours: the heave offering of their gift, with all the wave offerings of the children of Israel; I have given them to you, and your sons and daughters with you, as an ordinance forever. everyone who is clean in your house may eat it.", 
                "num": 11
              }, 
              {
                "text": "\"All the best of the oil, all the best of the new wine and the grain, their firstfruits which they offer to the LORD, I have given them to you.", 
                "num": 12
              }, 
              {
                "text": "Whatever first ripe fruit is in their land, which they bring to the LORD, shall be yours. Everyone who is clean in your house may eat it.", 
                "num": 13
              }, 
              {
                "text": "\"Every devoted thing in Israel shall be yours.", 
                "num": 14
              }, 
              {
                "text": "\"Everything that first opens the womb of all flesh, which they bring to the LORD, whether man or beast, shall be yours; nevertheless the firstborn of man you shall surely redeem, and the firstborn of unclean animals you shall redeem.", 
                "num": 15
              }, 
              {
                "text": "And those redeemed of the devoted things you shall redeem when one month old, according to your valuation, for five shekels of silver, according to the shekel of the sanctuary, which is twenty gerahs.", 
                "num": 16
              }, 
              {
                "text": "But the firstborn of a cow, the firstborn of a sheep, or the firstborn of a goat you shall not redeem; they are holy. You shall sprinkle their blood on the altar, and burn their fat as an offering made by fire for a sweet aroma to the LORD.", 
                "num": 17
              }, 
              {
                "text": "And their flesh shall be yours, just as the wave breast and the right thigh are yours.", 
                "num": 18
              }, 
              {
                "text": "\"All the heave offerings of the holy things, which the children of Israel offer to the LORD, I have given to you and your sons and daughters with you as an ordinance forever; it is a covenant of salt forever before the LORD with you and your descendants with you.\"", 
                "num": 19
              }, 
              {
                "text": "Then the LORD said to Aaron: \"You shall have no inheritance in their land, nor shall you have any portion among them; I am your portion and your inheritance among the children of Israel.", 
                "num": 20
              }, 
              {
                "text": "\"Behold, I have given the children of Levi all the tithes in Israel as an inheritance in return for the work which they perform, the work of the tabernacle of meeting.", 
                "num": 21
              }, 
              {
                "text": "Hereafter the children of Israel shall not come near the tabernacle of meeting, lest they bear sin and die.", 
                "num": 22
              }, 
              {
                "text": "But the Levites shall perform the work of the tabernacle of meeting, and they shall bear their iniquity; it shall be a statute forever, throughout your generations, that among the children of Israel they shall have no inheritance.", 
                "num": 23
              }, 
              {
                "text": "For the tithes of the children of Israel, which they offer up as a heave offering to the LORD, I have given to the Levites as an inheritance; therefore I have said to them, \"Among the children of Israel they shall have no inheritance.\"'", 
                "num": 24
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 25
              }, 
              {
                "text": "\"Speak thus to the Levites, and say to them: \"When you take from the children of Israel the tithes which I have given you from them as your inheritance, then you shall offer up a heave offering of it to the LORD, a tenth of the tithe.", 
                "num": 26
              }, 
              {
                "text": "And your heave offering shall be reckoned to you as though it were the grain of the threshing floor and as the fullness of the winepress.", 
                "num": 27
              }, 
              {
                "text": "Thus you shall also offer a heave offering to the LORD from all your tithes which you receive from the children of Israel, and you shall give the LORD's heave offering from it to Aaron the priest.", 
                "num": 28
              }, 
              {
                "text": "Of all your gifts you shall offer up every heave offering due to the LORD, from all the best of them, the consecrated part of them.'", 
                "num": 29
              }, 
              {
                "text": "Therefore you shall say to them: \"When you have lifted up the best of it, then the rest shall be accounted to the Levites as the produce of the threshing floor and as the produce of the winepress.", 
                "num": 30
              }, 
              {
                "text": "You may eat it in any place, you and your households, for it is your reward for your work in the tabernacle of meeting.", 
                "num": 31
              }, 
              {
                "text": "And you shall bear no sin because of it, when you have lifted up the best of it. But you shall not profane the holy gifts of the children of Israel, lest you die.\"'", 
                "num": 32
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses and Aaron, saying,", 
                "num": 1
              }, 
              {
                "text": "\"This is the ordinance of the law which the LORD has commanded, saying: \"Speak to the children of Israel, that they bring you a red heifer without blemish, in which there is no defect and on which a yoke has never come.", 
                "num": 2
              }, 
              {
                "text": "You shall give it to Eleazar the priest, that he may take it outside the camp, and it shall be slaughtered before him;", 
                "num": 3
              }, 
              {
                "text": "and Eleazar the priest shall take some of its blood with his finger, and sprinkle some of its blood seven times directly in front of the tabernacle of meeting.", 
                "num": 4
              }, 
              {
                "text": "Then the heifer shall be burned in his sight: its hide, its flesh, its blood, and its offal shall be burned.", 
                "num": 5
              }, 
              {
                "text": "And the priest shall take cedar wood and hyssop and scarlet, and cast them into the midst of the fire burning the heifer.", 
                "num": 6
              }, 
              {
                "text": "Then the priest shall wash his clothes, he shall bathe in water, and afterward he shall come into the camp; the priest shall be unclean until evening.", 
                "num": 7
              }, 
              {
                "text": "And the one who burns it shall wash his clothes in water, bathe in water, and shall be unclean until evening.", 
                "num": 8
              }, 
              {
                "text": "Then a man who is clean shall gather up the ashes of the heifer, and store them outside the camp in a clean place; and they shall be kept for the congregation of the children of Israel for the water of purification; it is for purifying from sin.", 
                "num": 9
              }, 
              {
                "text": "And the one who gathers the ashes of the heifer shall wash his clothes, and be unclean until evening. It shall be a statute forever to the children of Israel and to the stranger who dwells among them.", 
                "num": 10
              }, 
              {
                "text": "\"He who touches the dead body of anyone shall be unclean seven days.", 
                "num": 11
              }, 
              {
                "text": "He shall purify himself with the water on the third day and on the seventh day; then he will be clean. But if he does not purify himself on the third day and on the seventh day, he will not be clean.", 
                "num": 12
              }, 
              {
                "text": "Whoever touches the body of anyone who has died, and does not purify himself, defiles the tabernacle of the LORD. That person shall be cut off from Israel. He shall be unclean, because the water of purification was not sprinkled on him; his uncleanness is still on him.", 
                "num": 13
              }, 
              {
                "text": "\"This is the law when a man dies in a tent: All who come into the tent and all who are in the tent shall be unclean seven days;", 
                "num": 14
              }, 
              {
                "text": "and every open vessel, which has no cover fastened on it, is unclean.", 
                "num": 15
              }, 
              {
                "text": "Whoever in the open field touches one who is slain by a sword or who has died, or a bone of a man, or a grave, shall be unclean seven days.", 
                "num": 16
              }, 
              {
                "text": "\"And for an unclean person they shall take some of the ashes of the heifer burnt for purification from sin, and running water shall be put on them in a vessel.", 
                "num": 17
              }, 
              {
                "text": "A clean person shall take hyssop and dip it in the water, sprinkle it on the tent, on all the vessels, on the persons who were there, or on the one who touched a bone, the slain, the dead, or a grave.", 
                "num": 18
              }, 
              {
                "text": "The clean person shall sprinkle the unclean on the third day and on the seventh day; and on the seventh day he shall purify himself, wash his clothes, and bathe in water; and at evening he shall be clean.", 
                "num": 19
              }, 
              {
                "text": "\"But the man who is unclean and does not purify himself, that person shall be cut off from among the assembly, because he has defiled the sanctuary of the LORD. The water of purification has not been sprinkled on him; he is unclean.", 
                "num": 20
              }, 
              {
                "text": "It shall be a perpetual statute for them. He who sprinkles the water of purification shall wash his clothes; and he who touches the water of purification shall be unclean until evening.", 
                "num": 21
              }, 
              {
                "text": "Whatever the unclean person touches shall be unclean; and the person who touches it shall be unclean until evening.\"'", 
                "num": 22
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Then the children of Israel, the whole congregation, came into the Wilderness of Zin in the first month, and the people stayed in Kadesh; and Miriam died there and was buried there.", 
                "num": 1
              }, 
              {
                "text": "Now there was no water for the congregation; so they gathered together against Moses and Aaron.", 
                "num": 2
              }, 
              {
                "text": "And the people contended with Moses and spoke, saying: \"If only we had died when our brethren died before the LORD!", 
                "num": 3
              }, 
              {
                "text": "Why have you brought up the assembly of the LORD into this wilderness, that we and our animals should die here?", 
                "num": 4
              }, 
              {
                "text": "And why have you made us come up out of Egypt, to bring us to this evil place? It is not a place of grain or figs or vines or pomegranates; nor is there any water to drink.\"", 
                "num": 5
              }, 
              {
                "text": "So Moses and Aaron went from the presence of the assembly to the door of the tabernacle of meeting, and they fell on their faces. And the glory of the LORD appeared to them.", 
                "num": 6
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 7
              }, 
              {
                "text": "\"Take the rod; you and your brother Aaron gather the congregation together. Speak to the rock before their eyes, and it will yield its water; thus you shall bring water for them out of the rock, and give drink to the congregation and their animals.\"", 
                "num": 8
              }, 
              {
                "text": "So Moses took the rod from before the LORD as He commanded him.", 
                "num": 9
              }, 
              {
                "text": "And Moses and Aaron gathered the assembly together before the rock; and he said to them, \"Hear now, you rebels! Must we bring water for you out of this rock?\"", 
                "num": 10
              }, 
              {
                "text": "Then Moses lifted his hand and struck the rock twice with his rod; and water came out abundantly, and the congregation and their animals drank.", 
                "num": 11
              }, 
              {
                "text": "Then the LORD spoke to Moses and Aaron, \"Because you did not believe Me, to hallow Me in the eyes of the children of Israel, therefore you shall not bring this assembly into the land which I have given them.\"", 
                "num": 12
              }, 
              {
                "text": "This was the water of Meribah, because the children of Israel contended with the LORD, and He was hallowed among them.", 
                "num": 13
              }, 
              {
                "text": "Now Moses sent messengers from Kadesh to the king of Edom. \"Thus says your brother Israel: \"You know all the hardship that has befallen us,", 
                "num": 14
              }, 
              {
                "text": "how our fathers went down to Egypt, and we dwelt in Egypt a long time, and the Egyptians afflicted us and our fathers.", 
                "num": 15
              }, 
              {
                "text": "When we cried out to the LORD, He heard our voice and sent the Angel and brought us up out of Egypt; now here we are in Kadesh, a city on the edge of your border.", 
                "num": 16
              }, 
              {
                "text": "Please let us pass through your country. We will not pass through fields or vineyards, nor will we drink water from wells; we will go along the King's Highway; we will not turn aside to the right hand or to the left until we have passed through your territory.\"'", 
                "num": 17
              }, 
              {
                "text": "Then Edom said to him, \"You shall not pass through my land, lest I come out against you with the sword.\"", 
                "num": 18
              }, 
              {
                "text": "So the children of Israel said to him, \"We will go by the Highway, and if I or my livestock drink any of your water, then I will pay for it; let me only pass through on foot, nothing more.\"", 
                "num": 19
              }, 
              {
                "text": "Then he said, \"You shall not pass through.\" So Edom came out against them with many men and with a strong hand.", 
                "num": 20
              }, 
              {
                "text": "Thus Edom refused to give Israel passage through his territory; so Israel turned away from him.", 
                "num": 21
              }, 
              {
                "text": "Now the children of Israel, the whole congregation, journeyed from Kadesh and came to Mount Hor.", 
                "num": 22
              }, 
              {
                "text": "And the LORD spoke to Moses and Aaron in Mount Hor by the border of the land of Edom, saying:", 
                "num": 23
              }, 
              {
                "text": "\"Aaron shall be gathered to his people, for he shall not enter the land which I have given to the children of Israel, because you rebelled against My word at the water of Meribah.", 
                "num": 24
              }, 
              {
                "text": "Take Aaron and Eleazar his son, and bring them up to Mount Hor;", 
                "num": 25
              }, 
              {
                "text": "and strip Aaron of his garments and put them on Eleazar his son; for Aaron shall be gathered to his people and die there.\"", 
                "num": 26
              }, 
              {
                "text": "So Moses did just as the LORD commanded, and they went up to Mount Hor in the sight of all the congregation.", 
                "num": 27
              }, 
              {
                "text": "Moses stripped Aaron of his garments and put them on Eleazar his son; and Aaron died there on the top of the mountain. Then Moses and Eleazar came down from the mountain.", 
                "num": 28
              }, 
              {
                "text": "Now when all the congregation saw that Aaron was dead, all the house of Israel mourned for Aaron thirty days.", 
                "num": 29
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "The king of Arad, the Canaanite, who dwelt in the South, heard that Israel was coming on the road to Atharim. Then he fought against Israel and took some of them prisoners.", 
                "num": 1
              }, 
              {
                "text": "So Israel made a vow to the LORD, and said, \"If You will indeed deliver this people into my hand, then I will utterly destroy their cities.\"", 
                "num": 2
              }, 
              {
                "text": "And the LORD listened to the voice of Israel and delivered up the Canaanites, and they utterly destroyed them and their cities. So the name of that place was called Hormah.", 
                "num": 3
              }, 
              {
                "text": "Then they journeyed from Mount Hor by the Way of the Red Sea, to go around the land of Edom; and the soul of the people became very discouraged on the way.", 
                "num": 4
              }, 
              {
                "text": "And the people spoke against God and against Moses: \"Why have you brought us up out of Egypt to die in the wilderness? For there is no food and no water, and our soul loathes this worthless bread.\"", 
                "num": 5
              }, 
              {
                "text": "So the LORD sent fiery serpents among the people, and they bit the people; and many of the people of Israel died.", 
                "num": 6
              }, 
              {
                "text": "Therefore the people came to Moses, and said, \"We have sinned, for we have spoken against the LORD and against you; pray to the LORD that He take away the serpents from us.\" So Moses prayed for the people.", 
                "num": 7
              }, 
              {
                "text": "Then the LORD said to Moses, \"Make a fiery serpent, and set it on a pole; and it shall be that everyone who is bitten, when he looks at it, shall live.\"", 
                "num": 8
              }, 
              {
                "text": "So Moses made a bronze serpent, and put it on a pole; and so it was, if a serpent had bitten anyone, when he looked at the bronze serpent, he lived.", 
                "num": 9
              }, 
              {
                "text": "Now the children of Israel moved on and camped in Oboth.", 
                "num": 10
              }, 
              {
                "text": "And they journeyed from Oboth and camped at Ije Abarim, in the wilderness which is east of Moab, toward the sunrise.", 
                "num": 11
              }, 
              {
                "text": "From there they moved and camped in the Valley of Zered.", 
                "num": 12
              }, 
              {
                "text": "From there they moved and camped on the other side of the Arnon, which is in the wilderness that extends from the border of the Amorites; for the Arnon is the border of Moab, between Moab and the Amorites.", 
                "num": 13
              }, 
              {
                "text": "Therefore it is said in the Book of the Wars of the LORD: \"Waheb in Suphah, The brooks of the Arnon,", 
                "num": 14
              }, 
              {
                "text": "And the slope of the brooks That reaches to the dwelling of Ar, And lies on the border of Moab.\"", 
                "num": 15
              }, 
              {
                "text": "From there they went to Beer, which is the well where the LORD said to Moses, \"Gather the people together, and I will give them water.\"", 
                "num": 16
              }, 
              {
                "text": "Then Israel sang this song: \"Spring up, O well! All of you sing to it--", 
                "num": 17
              }, 
              {
                "text": "The well the leaders sank, Dug by the nation's nobles, By the lawgiver, with their staves.\" And from the wilderness they went to Mattanah,", 
                "num": 18
              }, 
              {
                "text": "from Mattanah to Nahaliel, from Nahaliel to Bamoth,", 
                "num": 19
              }, 
              {
                "text": "and from Bamoth, in the valley that is in the country of Moab, to the top of Pisgah which looks down on the wasteland.", 
                "num": 20
              }, 
              {
                "text": "Then Israel sent messengers to Sihon king of the Amorites, saying,", 
                "num": 21
              }, 
              {
                "text": "\"Let me pass through your land. We will not turn aside into fields or vineyards; we will not drink water from wells. We will go by the King's Highway until we have passed through your territory.\"", 
                "num": 22
              }, 
              {
                "text": "But Sihon would not allow Israel to pass through his territory. So Sihon gathered all his people together and went out against Israel in the wilderness, and he came to Jahaz and fought against Israel.", 
                "num": 23
              }, 
              {
                "text": "Then Israel defeated him with the edge of the sword, and took possession of his land from the Arnon to the Jabbok, as far as the people of Ammon; for the border of the people of Ammon was fortified.", 
                "num": 24
              }, 
              {
                "text": "So Israel took all these cities, and Israel dwelt in all the cities of the Amorites, in Heshbon and in all its villages.", 
                "num": 25
              }, 
              {
                "text": "For Heshbon was the city of Sihon king of the Amorites, who had fought against the former king of Moab, and had taken all his land from his hand as far as the Arnon.", 
                "num": 26
              }, 
              {
                "text": "Therefore those who speak in proverbs say: \"Come to Heshbon, let it be built; Let the city of Sihon be repaired.", 
                "num": 27
              }, 
              {
                "text": "\"For fire went out from Heshbon, A flame from the city of Sihon; It consumed Ar of Moab, The lords of the heights of the Arnon.", 
                "num": 28
              }, 
              {
                "text": "Woe to you, Moab! You have perished, O people of Chemosh! He has given his sons as fugitives, And his daughters into captivity, To Sihon king of the Amorites.", 
                "num": 29
              }, 
              {
                "text": "\"But we have shot at them; Heshbon has perished as far as Dibon. Then we laid waste as far as Nophah, Which reaches to Medeba.\"", 
                "num": 30
              }, 
              {
                "text": "Thus Israel dwelt in the land of the Amorites.", 
                "num": 31
              }, 
              {
                "text": "Then Moses sent to spy out Jazer; and they took its villages and drove out the Amorites who were there.", 
                "num": 32
              }, 
              {
                "text": "And they turned and went up by the way to Bashan. So Og king of Bashan went out against them, he and all his people, to battle at Edrei.", 
                "num": 33
              }, 
              {
                "text": "Then the LORD said to Moses, \"Do not fear him, for I have delivered him into your hand, with all his people and his land; and you shall do to him as you did to Sihon king of the Amorites, who dwelt at Heshbon.\"", 
                "num": 34
              }, 
              {
                "text": "So they defeated him, his sons, and all his people, until there was no survivor left him; and they took possession of his land.", 
                "num": 35
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then the children of Israel moved, and camped in the plains of Moab on the side of the Jordan across from Jericho.", 
                "num": 1
              }, 
              {
                "text": "Now Balak the son of Zippor saw all that Israel had done to the Amorites.", 
                "num": 2
              }, 
              {
                "text": "And Moab was exceedingly afraid of the people because they were many, and Moab was sick with dread because of the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "So Moab said to the elders of Midian, \"Now this company will lick up everything around us, as an ox licks up the grass of the field.\" And Balak the son of Zippor was king of the Moabites at that time.", 
                "num": 4
              }, 
              {
                "text": "Then he sent messengers to Balaam the son of Beor at Pethor, which is near the River in the land of the sons of his people, to call him, saying: \"Look, a people has come from Egypt. See, they cover the face of the earth, and are settling next to me!", 
                "num": 5
              }, 
              {
                "text": "Therefore please come at once, curse this people for me, for they are too mighty for me. Perhaps I shall be able to defeat them and drive them out of the land, for I know that he whom you bless is blessed, and he whom you curse is cursed.\"", 
                "num": 6
              }, 
              {
                "text": "So the elders of Moab and the elders of Midian departed with the diviner's fee in their hand, and they came to Balaam and spoke to him the words of Balak.", 
                "num": 7
              }, 
              {
                "text": "And he said to them, \"Lodge here tonight, and I will bring back word to you, as the LORD speaks to me.\" So the princes of Moab stayed with Balaam.", 
                "num": 8
              }, 
              {
                "text": "Then God came to Balaam and said, \"Who are these men with you?\"", 
                "num": 9
              }, 
              {
                "text": "So Balaam said to God, \"Balak the son of Zippor, king of Moab, has sent to me, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Look, a people has come out of Egypt, and they cover the face of the earth. Come now, curse them for me; perhaps I shall be able to overpower them and drive them out.\"'", 
                "num": 11
              }, 
              {
                "text": "And God said to Balaam, \"You shall not go with them; you shall not curse the people, for they are blessed.\"", 
                "num": 12
              }, 
              {
                "text": "So Balaam rose in the morning and said to the princes of Balak, \"Go back to your land, for the LORD has refused to give me permission to go with you.\"", 
                "num": 13
              }, 
              {
                "text": "And the princes of Moab rose and went to Balak, and said, \"Balaam refuses to come with us.\"", 
                "num": 14
              }, 
              {
                "text": "Then Balak again sent princes, more numerous and more honorable than they.", 
                "num": 15
              }, 
              {
                "text": "And they came to Balaam and said to him, \"Thus says Balak the son of Zippor: \"Please let nothing hinder you from coming to me;", 
                "num": 16
              }, 
              {
                "text": "for I will certainly honor you greatly, and I will do whatever you say to me. Therefore please come, curse this people for me.\"'", 
                "num": 17
              }, 
              {
                "text": "Then Balaam answered and said to the servants of Balak, \"Though Balak were to give me his house full of silver and gold, I could not go beyond the word of the LORD my God, to do less or more.", 
                "num": 18
              }, 
              {
                "text": "Now therefore, please, you also stay here tonight, that I may know what more the LORD will say to me.\"", 
                "num": 19
              }, 
              {
                "text": "And God came to Balaam at night and said to him, \"If the men come to call you, rise and go with them; but only the word which I speak to you--that you shall do.\"", 
                "num": 20
              }, 
              {
                "text": "So Balaam rose in the morning, saddled his donkey, and went with the princes of Moab.", 
                "num": 21
              }, 
              {
                "text": "Then God's anger was aroused because he went, and the Angel of the LORD took His stand in the way as an adversary against him. And he was riding on his donkey, and his two servants were with him.", 
                "num": 22
              }, 
              {
                "text": "Now the donkey saw the Angel of the LORD standing in the way with His drawn sword in His hand, and the donkey turned aside out of the way and went into the field. So Balaam struck the donkey to turn her back onto the road.", 
                "num": 23
              }, 
              {
                "text": "Then the Angel of the LORD stood in a narrow path between the vineyards, with a wall on this side and a wall on that side.", 
                "num": 24
              }, 
              {
                "text": "And when the donkey saw the Angel of the LORD, she pushed herself against the wall and crushed Balaam's foot against the wall; so he struck her again.", 
                "num": 25
              }, 
              {
                "text": "Then the Angel of the LORD went further, and stood in a narrow place where there was no way to turn either to the right hand or to the left.", 
                "num": 26
              }, 
              {
                "text": "And when the donkey saw the Angel of the LORD, she lay down under Balaam; so Balaam's anger was aroused, and he struck the donkey with his staff.", 
                "num": 27
              }, 
              {
                "text": "Then the LORD opened the mouth of the donkey, and she said to Balaam, \"What have I done to you, that you have struck me these three times?\"", 
                "num": 28
              }, 
              {
                "text": "And Balaam said to the donkey, \"Because you have abused me. I wish there were a sword in my hand, for now I would kill you!\"", 
                "num": 29
              }, 
              {
                "text": "So the donkey said to Balaam, \"Am I not your donkey on which you have ridden, ever since I became yours, to this day? Was I ever disposed to do this to you?\" And he said, \"No.\"", 
                "num": 30
              }, 
              {
                "text": "Then the LORD opened Balaam's eyes, and he saw the Angel of the LORD standing in the way with His drawn sword in His hand; and he bowed his head and fell flat on his face.", 
                "num": 31
              }, 
              {
                "text": "And the Angel of the LORD said to him, \"Why have you struck your donkey these three times? Behold, I have come out to stand against you, because your way is perverse before Me.", 
                "num": 32
              }, 
              {
                "text": "The donkey saw Me and turned aside from Me these three times. If she had not turned aside from Me, surely I would also have killed you by now, and let her live.\"", 
                "num": 33
              }, 
              {
                "text": "And Balaam said to the Angel of the LORD, \"I have sinned, for I did not know You stood in the way against me. Now therefore, if it displeases You, I will turn back.\"", 
                "num": 34
              }, 
              {
                "text": "Then the Angel of the LORD said to Balaam, \"Go with the men, but only the word that I speak to you, that you shall speak.\" So Balaam went with the princes of Balak.", 
                "num": 35
              }, 
              {
                "text": "Now when Balak heard that Balaam was coming, he went out to meet him at the city of Moab, which is on the border at the Arnon, the boundary of the territory.", 
                "num": 36
              }, 
              {
                "text": "Then Balak said to Balaam, \"Did I not earnestly send to you, calling for you? Why did you not come to me? Am I not able to honor you?\"", 
                "num": 37
              }, 
              {
                "text": "And Balaam said to Balak, \"Look, I have come to you! Now, have I any power at all to say anything? The word that God puts in my mouth, that I must speak.\"", 
                "num": 38
              }, 
              {
                "text": "So Balaam went with Balak, and they came to Kirjath Huzoth.", 
                "num": 39
              }, 
              {
                "text": "Then Balak offered oxen and sheep, and he sent some to Balaam and to the princes who were with him.", 
                "num": 40
              }, 
              {
                "text": "So it was, the next day, that Balak took Balaam and brought him up to the high places of Baal, that from there he might observe the extent of the people.", 
                "num": 41
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then Balaam said to Balak, \"Build seven altars for me here, and prepare for me here seven bulls and seven rams.\"", 
                "num": 1
              }, 
              {
                "text": "And Balak did just as Balaam had spoken, and Balak and Balaam offered a bull and a ram on each altar.", 
                "num": 2
              }, 
              {
                "text": "Then Balaam said to Balak, \"Stand by your burnt offering, and I will go; perhaps the LORD will come to meet me, and whatever He shows me I will tell you.\" So he went to a desolate height.", 
                "num": 3
              }, 
              {
                "text": "And God met Balaam, and he said to Him, \"I have prepared the seven altars, and I have offered on each altar a bull and a ram.\"", 
                "num": 4
              }, 
              {
                "text": "Then the LORD put a word in Balaam's mouth, and said, \"Return to Balak, and thus you shall speak.\"", 
                "num": 5
              }, 
              {
                "text": "So he returned to him, and there he was, standing by his burnt offering, he and all the princes of Moab.", 
                "num": 6
              }, 
              {
                "text": "And he took up his oracle and said: \"Balak the king of Moab has brought me from Aram, From the mountains of the east. \"Come, curse Jacob for me, And come, denounce Israel!'", 
                "num": 7
              }, 
              {
                "text": "\"How shall I curse whom God has not cursed? And how shall I denounce whom the LORD has not denounced?", 
                "num": 8
              }, 
              {
                "text": "For from the top of the rocks I see him, And from the hills I behold him; There! A people dwelling alone, Not reckoning itself among the nations.", 
                "num": 9
              }, 
              {
                "text": "\"Who can count the dust of Jacob, Or number one-fourth of Israel? Let me die the death of the righteous, And let my end be like his!\"", 
                "num": 10
              }, 
              {
                "text": "Then Balak said to Balaam, \"What have you done to me? I took you to curse my enemies, and look, you have blessed them bountifully!\"", 
                "num": 11
              }, 
              {
                "text": "So he answered and said, \"Must I not take heed to speak what the LORD has put in my mouth?\"", 
                "num": 12
              }, 
              {
                "text": "Then Balak said to him, \"Please come with me to another place from which you may see them; you shall see only the outer part of them, and shall not see them all; curse them for me from there.\"", 
                "num": 13
              }, 
              {
                "text": "So he brought him to the field of Zophim, to the top of Pisgah, and built seven altars, and offered a bull and a ram on each altar.", 
                "num": 14
              }, 
              {
                "text": "And he said to Balak, \"Stand here by your burnt offering while I meet the LORD over there.\"", 
                "num": 15
              }, 
              {
                "text": "Then the LORD met Balaam, and put a word in his mouth, and said, \"Go back to Balak, and thus you shall speak.\"", 
                "num": 16
              }, 
              {
                "text": "So he came to him, and there he was, standing by his burnt offering, and the princes of Moab were with him. And Balak said to him, \"What has the LORD spoken?\"", 
                "num": 17
              }, 
              {
                "text": "Then he took up his oracle and said: \"Rise up, Balak, and hear! Listen to me, son of Zippor!", 
                "num": 18
              }, 
              {
                "text": "\"God is not a man, that He should lie, Nor a son of man, that He should repent. Has He said, and will He not do? Or has He spoken, and will He not make it good?", 
                "num": 19
              }, 
              {
                "text": "Behold, I have received a command to bless; He has blessed, and I cannot reverse it.", 
                "num": 20
              }, 
              {
                "text": "\"He has not observed iniquity in Jacob, Nor has He seen wickedness in Israel. The LORD his God is with him, And the shout of a King is among them.", 
                "num": 21
              }, 
              {
                "text": "God brings them out of Egypt; He has strength like a wild ox.", 
                "num": 22
              }, 
              {
                "text": "\"For there is no sorcery against Jacob, Nor any divination against Israel. It now must be said of Jacob And of Israel, \"Oh, what God has done!'", 
                "num": 23
              }, 
              {
                "text": "Look, a people rises like a lioness, And lifts itself up like a lion; It shall not lie down until it devours the prey, And drinks the blood of the slain.\"", 
                "num": 24
              }, 
              {
                "text": "Then Balak said to Balaam, \"Neither curse them at all, nor bless them at all!\"", 
                "num": 25
              }, 
              {
                "text": "So Balaam answered and said to Balak, \"Did I not tell you, saying, \"All that the LORD speaks, that I must do'?\"", 
                "num": 26
              }, 
              {
                "text": "Then Balak said to Balaam, \"Please come, I will take you to another place; perhaps it will please God that you may curse them for me from there.\"", 
                "num": 27
              }, 
              {
                "text": "So Balak took Balaam to the top of Peor, that overlooks the wasteland.", 
                "num": 28
              }, 
              {
                "text": "Then Balaam said to Balak, \"Build for me here seven altars, and prepare for me here seven bulls and seven rams.\"", 
                "num": 29
              }, 
              {
                "text": "And Balak did as Balaam had said, and offered a bull and a ram on every altar.", 
                "num": 30
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now when Balaam saw that it pleased the LORD to bless Israel, he did not go as at other times, to seek to use sorcery, but he set his face toward the wilderness.", 
                "num": 1
              }, 
              {
                "text": "And Balaam raised his eyes, and saw Israel encamped according to their tribes; and the Spirit of God came upon him.", 
                "num": 2
              }, 
              {
                "text": "Then he took up his oracle and said: \"The utterance of Balaam the son of Beor, The utterance of the man whose eyes are opened,", 
                "num": 3
              }, 
              {
                "text": "The utterance of him who hears the words of God, Who sees the vision of the Almighty, Who falls down, with eyes wide open:", 
                "num": 4
              }, 
              {
                "text": "\"How lovely are your tents, O Jacob! Your dwellings, O Israel!", 
                "num": 5
              }, 
              {
                "text": "Like valleys that stretch out, Like gardens by the riverside, Like aloes planted by the LORD, Like cedars beside the waters.", 
                "num": 6
              }, 
              {
                "text": "He shall pour water from his buckets, And his seed shall be in many waters. \"His king shall be higher than Agag, And his kingdom shall be exalted.", 
                "num": 7
              }, 
              {
                "text": "\"God brings him out of Egypt; He has strength like a wild ox; He shall consume the nations, his enemies; He shall break their bones And pierce them with his arrows.", 
                "num": 8
              }, 
              {
                "text": "\"He bows down, he lies down as a lion; And as a lion, who shall rouse him?' \"Blessed is he who blesses you, And cursed is he who curses you.\"", 
                "num": 9
              }, 
              {
                "text": "Then Balak's anger was aroused against Balaam, and he struck his hands together; and Balak said to Balaam, \"I called you to curse my enemies, and look, you have bountifully blessed them these three times!", 
                "num": 10
              }, 
              {
                "text": "Now therefore, flee to your place. I said I would greatly honor you, but in fact, the LORD has kept you back from honor.\"", 
                "num": 11
              }, 
              {
                "text": "So Balaam said to Balak, \"Did I not also speak to your messengers whom you sent to me, saying,", 
                "num": 12
              }, 
              {
                "text": "\"If Balak were to give me his house full of silver and gold, I could not go beyond the word of the LORD, to do good or bad of my own will. What the LORD says, that I must speak'?", 
                "num": 13
              }, 
              {
                "text": "And now, indeed, I am going to my people. Come, I will advise you what this people will do to your people in the latter days.\"", 
                "num": 14
              }, 
              {
                "text": "So he took up his oracle and said: \"The utterance of Balaam the son of Beor, And the utterance of the man whose eyes are opened;", 
                "num": 15
              }, 
              {
                "text": "The utterance of him who hears the words of God, And has the knowledge of the Most High, Who sees the vision of the Almighty, Who falls down, with eyes wide open:", 
                "num": 16
              }, 
              {
                "text": "\"I see Him, but not now; I behold Him, but not near; A Star shall come out of Jacob; A Scepter shall rise out of Israel, And batter the brow of Moab, And destroy all the sons of tumult.", 
                "num": 17
              }, 
              {
                "text": "\"And Edom shall be a possession; Seir also, his enemies, shall be a possession, While Israel does valiantly.", 
                "num": 18
              }, 
              {
                "text": "Out of Jacob One shall have dominion, And destroy the remains of the city.\"", 
                "num": 19
              }, 
              {
                "text": "Then he looked on Amalek, and he took up his oracle and said: \"Amalek was first among the nations, But shall be last until he perishes.\"", 
                "num": 20
              }, 
              {
                "text": "Then he looked on the Kenites, and he took up his oracle and said: \"Firm is your dwelling place, And your nest is set in the rock;", 
                "num": 21
              }, 
              {
                "text": "Nevertheless Kain shall be burned. How long until Asshur carries you away captive?\"", 
                "num": 22
              }, 
              {
                "text": "Then he took up his oracle and said: \"Alas! Who shall live when God does this?", 
                "num": 23
              }, 
              {
                "text": "But ships shall come from the coasts of Cyprus, And they shall afflict Asshur and afflict Eber, And so shall Amalek, until he perishes.\"", 
                "num": 24
              }, 
              {
                "text": "So Balaam rose and departed and returned to his place; Balak also went his way.", 
                "num": 25
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Now Israel remained in Acacia Grove, and the people began to commit harlotry with the women of Moab.", 
                "num": 1
              }, 
              {
                "text": "They invited the people to the sacrifices of their gods, and the people ate and bowed down to their gods.", 
                "num": 2
              }, 
              {
                "text": "So Israel was joined to Baal of Peor, and the anger of the LORD was aroused against Israel.", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said to Moses, \"Take all the leaders of the people and hang the offenders before the LORD, out in the sun, that the fierce anger of the LORD may turn away from Israel.\"", 
                "num": 4
              }, 
              {
                "text": "So Moses said to the judges of Israel, \"Every one of you kill his men who were joined to Baal of Peor.\"", 
                "num": 5
              }, 
              {
                "text": "And indeed, one of the children of Israel came and presented to his brethren a Midianite woman in the sight of Moses and in the sight of all the congregation of the children of Israel, who were weeping at the door of the tabernacle of meeting.", 
                "num": 6
              }, 
              {
                "text": "Now when Phinehas the son of Eleazar, the son of Aaron the priest, saw it, he rose from among the congregation and took a javelin in his hand;", 
                "num": 7
              }, 
              {
                "text": "and he went after the man of Israel into the tent and thrust both of them through, the man of Israel, and the woman through her body. So the plague was stopped among the children of Israel.", 
                "num": 8
              }, 
              {
                "text": "And those who died in the plague were twenty-four thousand.", 
                "num": 9
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 10
              }, 
              {
                "text": "\"Phinehas the son of Eleazar, the son of Aaron the priest, has turned back My wrath from the children of Israel, because he was zealous with My zeal among them, so that I did not consume the children of Israel in My zeal.", 
                "num": 11
              }, 
              {
                "text": "Therefore say, \"Behold, I give to him My covenant of peace;", 
                "num": 12
              }, 
              {
                "text": "and it shall be to him and his descendants after him a covenant of an everlasting priesthood, because he was zealous for his God, and made atonement for the children of Israel.\"'", 
                "num": 13
              }, 
              {
                "text": "Now the name of the Israelite who was killed, who was killed with the Midianite woman, was Zimri the son of Salu, a leader of a father's house among the Simeonites.", 
                "num": 14
              }, 
              {
                "text": "And the name of the Midianite woman who was killed was Cozbi the daughter of Zur; he was head of the people of a father's house in Midian.", 
                "num": 15
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 16
              }, 
              {
                "text": "\"Harass the Midianites, and attack them;", 
                "num": 17
              }, 
              {
                "text": "for they harassed you with their schemes by which they seduced you in the matter of Peor and in the matter of Cozbi, the daughter of a leader of Midian, their sister, who was killed in the day of the plague because of Peor.\"", 
                "num": 18
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass, after the plague, that the LORD spoke to Moses and Eleazar the son of Aaron the priest, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take a census of all the congregation of the children of Israel from twenty years old and above, by their fathers' houses, all who are able to go to war in Israel.\"", 
                "num": 2
              }, 
              {
                "text": "So Moses and Eleazar the priest spoke with them in the plains of Moab by the Jordan, across from Jericho, saying:", 
                "num": 3
              }, 
              {
                "text": "\"Take a census of the people from twenty years old and above, just as the LORD commanded Moses and the children of Israel who came out of the land of Egypt.\"", 
                "num": 4
              }, 
              {
                "text": "Reuben was the firstborn of Israel. The children of Reuben were: of Hanoch, the family of the Hanochites; of Pallu, the family of the Palluites;", 
                "num": 5
              }, 
              {
                "text": "of Hezron, the family of the Hezronites; of Carmi, the family of the Carmites.", 
                "num": 6
              }, 
              {
                "text": "These are the families of the Reubenites: those who were numbered of them were forty-three thousand seven hundred and thirty.", 
                "num": 7
              }, 
              {
                "text": "And the son of Pallu was Eliab.", 
                "num": 8
              }, 
              {
                "text": "The sons of Eliab were Nemuel, Dathan, and Abiram. These are the Dathan and Abiram, representatives of the congregation, who contended against Moses and Aaron in the company of Korah, when they contended against the LORD;", 
                "num": 9
              }, 
              {
                "text": "and the earth opened its mouth and swallowed them up together with Korah when that company died, when the fire devoured two hundred and fifty men; and they became a sign.", 
                "num": 10
              }, 
              {
                "text": "Nevertheless the children of Korah did not die.", 
                "num": 11
              }, 
              {
                "text": "The sons of Simeon according to their families were: of Nemuel, the family of the Nemuelites; of Jamin, the family of the Jaminites; of Jachin, the family of the Jachinites;", 
                "num": 12
              }, 
              {
                "text": "of Zerah, the family of the Zarhites; of Shaul, the family of the Shaulites.", 
                "num": 13
              }, 
              {
                "text": "These are the families of the Simeonites: twenty-two thousand two hundred.", 
                "num": 14
              }, 
              {
                "text": "The sons of Gad according to their families were: of Zephon, the family of the Zephonites; of Haggi, the family of the Haggites; of Shuni, the family of the Shunites;", 
                "num": 15
              }, 
              {
                "text": "of Ozni, the family of the Oznites; of Eri, the family of the Erites;", 
                "num": 16
              }, 
              {
                "text": "of Arod, the family of the Arodites; of Areli, the family of the Arelites.", 
                "num": 17
              }, 
              {
                "text": "These are the families of the sons of Gad according to those who were numbered of them: forty thousand five hundred.", 
                "num": 18
              }, 
              {
                "text": "The sons of Judah were Er and Onan; and Er and Onan died in the land of Canaan.", 
                "num": 19
              }, 
              {
                "text": "And the sons of Judah according to their families were: of Shelah, the family of the Shelanites; of Perez, the family of the Parzites; of Zerah, the family of the Zarhites.", 
                "num": 20
              }, 
              {
                "text": "And the sons of Perez were: of Hezron, the family of the Hezronites; of Hamul, the family of the Hamulites.", 
                "num": 21
              }, 
              {
                "text": "These are the families of Judah according to those who were numbered of them: seventy-six thousand five hundred.", 
                "num": 22
              }, 
              {
                "text": "The sons of Issachar according to their families were: of Tola, the family of the Tolaites; of Puah, the family of the Punites;", 
                "num": 23
              }, 
              {
                "text": "of Jashub, the family of the Jashubites; of Shimron, the family of the Shimronites.", 
                "num": 24
              }, 
              {
                "text": "These are the families of Issachar according to those who were numbered of them: sixty-four thousand three hundred.", 
                "num": 25
              }, 
              {
                "text": "The sons of Zebulun according to their families were: of Sered, the family of the Sardites; of Elon, the family of the Elonites; of Jahleel, the family of the Jahleelites.", 
                "num": 26
              }, 
              {
                "text": "These are the families of the Zebulunites according to those who were numbered of them: sixty thousand five hundred.", 
                "num": 27
              }, 
              {
                "text": "The sons of Joseph according to their families, by Manasseh and Ephraim, were:", 
                "num": 28
              }, 
              {
                "text": "The sons of Manasseh: of Machir, the family of the Machirites; and Machir begot Gilead; of Gilead, the family of the Gileadites.", 
                "num": 29
              }, 
              {
                "text": "These are the sons of Gilead: of Jeezer, the family of the Jeezerites; of Helek, the family of the Helekites;", 
                "num": 30
              }, 
              {
                "text": "of Asriel, the family of the Asrielites; of Shechem, the family of the Shechemites;", 
                "num": 31
              }, 
              {
                "text": "of Shemida, the family of the Shemidaites; of Hepher, the family of the Hepherites.", 
                "num": 32
              }, 
              {
                "text": "Now Zelophehad the son of Hepher had no sons, but daughters; and the names of the daughters of Zelophehad were Mahlah, Noah, Hoglah, Milcah, and Tirzah.", 
                "num": 33
              }, 
              {
                "text": "These are the families of Manasseh; and those who were numbered of them were fifty-two thousand seven hundred.", 
                "num": 34
              }, 
              {
                "text": "These are the sons of Ephraim according to their families: of Shuthelah, the family of the Shuthalhites; of Becher, the family of the Bachrites; of Tahan, the family of the Tahanites.", 
                "num": 35
              }, 
              {
                "text": "And these are the sons of Shuthelah: of Eran, the family of the Eranites.", 
                "num": 36
              }, 
              {
                "text": "These are the families of the sons of Ephraim according to those who were numbered of them: thirty-two thousand five hundred. These are the sons of Joseph according to their families.", 
                "num": 37
              }, 
              {
                "text": "The sons of Benjamin according to their families were: of Bela, the family of the Belaites; of Ashbel, the family of the Ashbelites; of Ahiram, the family of the Ahiramites;", 
                "num": 38
              }, 
              {
                "text": "of Shupham, the family of the Shuphamites; of Hupham, the family of the Huphamites.", 
                "num": 39
              }, 
              {
                "text": "And the sons of Bela were Ard and Naaman: of Ard, the family of the Ardites; of Naaman, the family of the Naamites.", 
                "num": 40
              }, 
              {
                "text": "These are the sons of Benjamin according to their families; and those who were numbered of them were forty-five thousand six hundred.", 
                "num": 41
              }, 
              {
                "text": "These are the sons of Dan according to their families: of Shuham, the family of the Shuhamites. These are the families of Dan according to their families.", 
                "num": 42
              }, 
              {
                "text": "All the families of the Shuhamites, according to those who were numbered of them, were sixty-four thousand four hundred.", 
                "num": 43
              }, 
              {
                "text": "The sons of Asher according to their families were: of Jimna, the family of the Jimnites; of Jesui, the family of the Jesuites; of Beriah, the family of the Beriites.", 
                "num": 44
              }, 
              {
                "text": "Of the sons of Beriah: of Heber, the family of the Heberites; of Malchiel, the family of the Malchielites.", 
                "num": 45
              }, 
              {
                "text": "And the name of the daughter of Asher was Serah.", 
                "num": 46
              }, 
              {
                "text": "These are the families of the sons of Asher according to those who were numbered of them: fifty-three thousand four hundred.", 
                "num": 47
              }, 
              {
                "text": "The sons of Naphtali according to their families were: of Jahzeel, the family of the Jahzeelites; of Guni, the family of the Gunites;", 
                "num": 48
              }, 
              {
                "text": "of Jezer, the family of the Jezerites; of Shillem, the family of the Shillemites.", 
                "num": 49
              }, 
              {
                "text": "These are the families of Naphtali according to their families; and those who were numbered of them were forty-five thousand four hundred.", 
                "num": 50
              }, 
              {
                "text": "These are those who were numbered of the children of Israel: six hundred and one thousand seven hundred and thirty.", 
                "num": 51
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying:", 
                "num": 52
              }, 
              {
                "text": "\"To these the land shall be divided as an inheritance, according to the number of names.", 
                "num": 53
              }, 
              {
                "text": "To a large tribe you shall give a larger inheritance, and to a small tribe you shall give a smaller inheritance. Each shall be given its inheritance according to those who were numbered of them.", 
                "num": 54
              }, 
              {
                "text": "But the land shall be divided by lot; they shall inherit according to the names of the tribes of their fathers.", 
                "num": 55
              }, 
              {
                "text": "According to the lot their inheritance shall be divided between the larger and the smaller.\"", 
                "num": 56
              }, 
              {
                "text": "And these are those who were numbered of the Levites according to their families: of Gershon, the family of the Gershonites; of Kohath, the family of the Kohathites; of Merari, the family of the Merarites.", 
                "num": 57
              }, 
              {
                "text": "These are the families of the Levites: the family of the Libnites, the family of the Hebronites, the family of the Mahlites, the family of the Mushites, and the family of the Korathites. And Kohath begot Amram.", 
                "num": 58
              }, 
              {
                "text": "The name of Amram's wife was Jochebed the daughter of Levi, who was born to Levi in Egypt; and to Amram she bore Aaron and Moses and their sister Miriam.", 
                "num": 59
              }, 
              {
                "text": "To Aaron were born Nadab and Abihu, Eleazar and Ithamar.", 
                "num": 60
              }, 
              {
                "text": "And Nadab and Abihu died when they offered profane fire before the LORD.", 
                "num": 61
              }, 
              {
                "text": "Now those who were numbered of them were twenty-three thousand, every male from a month old and above; for they were not numbered among the other children of Israel, because there was no inheritance given to them among the children of Israel.", 
                "num": 62
              }, 
              {
                "text": "These are those who were numbered by Moses and Eleazar the priest, who numbered the children of Israel in the plains of Moab by the Jordan, across from Jericho.", 
                "num": 63
              }, 
              {
                "text": "But among these there was not a man of those who were numbered by Moses and Aaron the priest when they numbered the children of Israel in the Wilderness of Sinai.", 
                "num": 64
              }, 
              {
                "text": "For the LORD had said of them, \"They shall surely die in the wilderness.\" So there was not left a man of them, except Caleb the son of Jephunneh and Joshua the son of Nun.", 
                "num": 65
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Then came the daughters of Zelophehad the son of Hepher, the son of Gilead, the son of Machir, the son of Manasseh, from the families of Manasseh the son of Joseph; and these were the names of his daughters: Mahlah, Noah, Hoglah, Milcah, and Tirzah.", 
                "num": 1
              }, 
              {
                "text": "And they stood before Moses, before Eleazar the priest, and before the leaders and all the congregation, by the doorway of the tabernacle of meeting, saying:", 
                "num": 2
              }, 
              {
                "text": "\"Our father died in the wilderness; but he was not in the company of those who gathered together against the LORD, in company with Korah, but he died in his own sin; and he had no sons.", 
                "num": 3
              }, 
              {
                "text": "Why should the name of our father be removed from among his family because he had no son? Give us a possession among our father's brothers.\"", 
                "num": 4
              }, 
              {
                "text": "So Moses brought their case before the LORD.", 
                "num": 5
              }, 
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 6
              }, 
              {
                "text": "\"The daughters of Zelophehad speak what is right; you shall surely give them a possession of inheritance among their father's brothers, and cause the inheritance of their father to pass to them.", 
                "num": 7
              }, 
              {
                "text": "And you shall speak to the children of Israel, saying: \"If a man dies and has no son, then you shall cause his inheritance to pass to his daughter.", 
                "num": 8
              }, 
              {
                "text": "If he has no daughter, then you shall give his inheritance to his brothers.", 
                "num": 9
              }, 
              {
                "text": "If he has no brothers, then you shall give his inheritance to his father's brothers.", 
                "num": 10
              }, 
              {
                "text": "And if his father has no brothers, then you shall give his inheritance to the relative closest to him in his family, and he shall possess it.\"' And it shall be to the children of Israel a statute of judgment, just as the LORD commanded Moses.", 
                "num": 11
              }, 
              {
                "text": "Now the LORD said to Moses: \"Go up into this Mount Abarim, and see the land which I have given to the children of Israel.", 
                "num": 12
              }, 
              {
                "text": "And when you have seen it, you also shall be gathered to your people, as Aaron your brother was gathered.", 
                "num": 13
              }, 
              {
                "text": "For in the Wilderness of Zin, during the strife of the congregation, you rebelled against My command to hallow Me at the waters before their eyes.\" (These are the waters of Meribah, at Kadesh in the Wilderness of Zin.)", 
                "num": 14
              }, 
              {
                "text": "Then Moses spoke to the LORD, saying:", 
                "num": 15
              }, 
              {
                "text": "\"Let the LORD, the God of the spirits of all flesh, set a man over the congregation,", 
                "num": 16
              }, 
              {
                "text": "who may go out before them and go in before them, who may lead them out and bring them in, that the congregation of the LORD may not be like sheep which have no shepherd.\"", 
                "num": 17
              }, 
              {
                "text": "And the LORD said to Moses: \"Take Joshua the son of Nun with you, a man in whom is the Spirit, and lay your hand on him;", 
                "num": 18
              }, 
              {
                "text": "set him before Eleazar the priest and before all the congregation, and inaugurate him in their sight.", 
                "num": 19
              }, 
              {
                "text": "And you shall give some of your authority to him, that all the congregation of the children of Israel may be obedient.", 
                "num": 20
              }, 
              {
                "text": "He shall stand before Eleazar the priest, who shall inquire before the LORD for him by the judgment of the Urim. At his word they shall go out, and at his word they shall come in, he and all the children of Israel with him--all the congregation.\"", 
                "num": 21
              }, 
              {
                "text": "So Moses did as the LORD commanded him. He took Joshua and set him before Eleazar the priest and before all the congregation.", 
                "num": 22
              }, 
              {
                "text": "And he laid his hands on him and inaugurated him, just as the LORD commanded by the hand of Moses.", 
                "num": 23
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Command the children of Israel, and say to them, \"My offering, My food for My offerings made by fire as a sweet aroma to Me, you shall be careful to offer to Me at their appointed time.'", 
                "num": 2
              }, 
              {
                "text": "\"And you shall say to them, \"This is the offering made by fire which you shall offer to the LORD: two male lambs in their first year without blemish, day by day, as a regular burnt offering.", 
                "num": 3
              }, 
              {
                "text": "The one lamb you shall offer in the morning, the other lamb you shall offer in the evening,", 
                "num": 4
              }, 
              {
                "text": "and one-tenth of an ephah of fine flour as a grain offering mixed with one-fourth of a hin of pressed oil.", 
                "num": 5
              }, 
              {
                "text": "It is a regular burnt offering which was ordained at Mount Sinai for a sweet aroma, an offering made by fire to the LORD.", 
                "num": 6
              }, 
              {
                "text": "And its drink offering shall be one-fourth of a hin for each lamb; in a holy place you shall pour out the drink to the LORD as an offering.", 
                "num": 7
              }, 
              {
                "text": "The other lamb you shall offer in the evening; as the morning grain offering and its drink offering, you shall offer it as an offering made by fire, a sweet aroma to the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"And on the Sabbath day two lambs in their first year, without blemish, and two-tenths of an ephah of fine flour as a grain offering, mixed with oil, with its drink offering--", 
                "num": 9
              }, 
              {
                "text": "this is the burnt offering for every Sabbath, besides the regular burnt offering with its drink offering.", 
                "num": 10
              }, 
              {
                "text": "\"At the beginnings of your months you shall present a burnt offering to the LORD: two young bulls, one ram, and seven lambs in their first year, without blemish;", 
                "num": 11
              }, 
              {
                "text": "three-tenths of an ephah of fine flour as a grain offering, mixed with oil, for each bull; two-tenths of an ephah of fine flour as a grain offering, mixed with oil, for the one ram;", 
                "num": 12
              }, 
              {
                "text": "and one-tenth of an ephah of fine flour, mixed with oil, as a grain offering for each lamb, as a burnt offering of sweet aroma, an offering made by fire to the LORD.", 
                "num": 13
              }, 
              {
                "text": "Their drink offering shall be half a hin of wine for a bull, one-third of a hin for a ram, and one-fourth of a hin for a lamb; this is the burnt offering for each month throughout the months of the year.", 
                "num": 14
              }, 
              {
                "text": "Also one kid of the goats as a sin offering to the LORD shall be offered, besides the regular burnt offering and its drink offering.", 
                "num": 15
              }, 
              {
                "text": "\"On the fourteenth day of the first month is the Passover of the LORD.", 
                "num": 16
              }, 
              {
                "text": "And on the fifteenth day of this month is the feast; unleavened bread shall be eaten for seven days.", 
                "num": 17
              }, 
              {
                "text": "On the first day you shall have a holy convocation. You shall do no customary work.", 
                "num": 18
              }, 
              {
                "text": "And you shall present an offering made by fire as a burnt offering to the LORD: two young bulls, one ram, and seven lambs in their first year. Be sure they are without blemish.", 
                "num": 19
              }, 
              {
                "text": "Their grain offering shall be of fine flour mixed with oil: three-tenths of an ephah you shall offer for a bull, and two-tenths for a ram;", 
                "num": 20
              }, 
              {
                "text": "you shall offer one-tenth of an ephah for each of the seven lambs;", 
                "num": 21
              }, 
              {
                "text": "also one goat as a sin offering, to make atonement for you.", 
                "num": 22
              }, 
              {
                "text": "You shall offer these besides the burnt offering of the morning, which is for a regular burnt offering.", 
                "num": 23
              }, 
              {
                "text": "In this manner you shall offer the food of the offering made by fire daily for seven days, as a sweet aroma to the LORD; it shall be offered besides the regular burnt offering and its drink offering.", 
                "num": 24
              }, 
              {
                "text": "And on the seventh day you shall have a holy convocation. You shall do no customary work.", 
                "num": 25
              }, 
              {
                "text": "\"Also on the day of the firstfruits, when you bring a new grain offering to the LORD at your Feast of Weeks, you shall have a holy convocation. You shall do no customary work.", 
                "num": 26
              }, 
              {
                "text": "You shall present a burnt offering as a sweet aroma to the LORD: two young bulls, one ram, and seven lambs in their first year,", 
                "num": 27
              }, 
              {
                "text": "with their grain offering of fine flour mixed with oil: three-tenths of an ephah for each bull, two-tenths for the one ram,", 
                "num": 28
              }, 
              {
                "text": "and one-tenth for each of the seven lambs;", 
                "num": 29
              }, 
              {
                "text": "also one kid of the goats, to make atonement for you.", 
                "num": 30
              }, 
              {
                "text": "Be sure they are without blemish. You shall present them with their drink offerings, besides the regular burnt offering with its grain offering.", 
                "num": 31
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "\"And in the seventh month, on the first day of the month, you shall have a holy convocation. You shall do no customary work. For you it is a day of blowing the trumpets.", 
                "num": 1
              }, 
              {
                "text": "You shall offer a burnt offering as a sweet aroma to the LORD: one young bull, one ram, and seven lambs in their first year, without blemish.", 
                "num": 2
              }, 
              {
                "text": "Their grain offering shall be fine flour mixed with oil: three-tenths of an ephah for the bull, two-tenths for the ram,", 
                "num": 3
              }, 
              {
                "text": "and one-tenth for each of the seven lambs;", 
                "num": 4
              }, 
              {
                "text": "also one kid of the goats as a sin offering, to make atonement for you;", 
                "num": 5
              }, 
              {
                "text": "besides the burnt offering with its grain offering for the New Moon, the regular burnt offering with its grain offering, and their drink offerings, according to their ordinance, as a sweet aroma, an offering made by fire to the LORD.", 
                "num": 6
              }, 
              {
                "text": "\"On the tenth day of this seventh month you shall have a holy convocation. You shall afflict your souls; you shall not do any work.", 
                "num": 7
              }, 
              {
                "text": "You shall present a burnt offering to the LORD as a sweet aroma: one young bull, one ram, and seven lambs in their first year. Be sure they are without blemish.", 
                "num": 8
              }, 
              {
                "text": "Their grain offering shall be of fine flour mixed with oil: three-tenths of an ephah for the bull, two-tenths for the one ram,", 
                "num": 9
              }, 
              {
                "text": "and one-tenth for each of the seven lambs;", 
                "num": 10
              }, 
              {
                "text": "also one kid of the goats as a sin offering, besides the sin offering for atonement, the regular burnt offering with its grain offering, and their drink offerings. ]", 
                "num": 11
              }, 
              {
                "text": "\"On the fifteenth day of the seventh month you shall have a holy convocation. You shall do no customary work, and you shall keep a feast to the LORD seven days.", 
                "num": 12
              }, 
              {
                "text": "You shall present a burnt offering, an offering made by fire as a sweet aroma to the LORD: thirteen young bulls, two rams, and fourteen lambs in their first year. They shall be without blemish.", 
                "num": 13
              }, 
              {
                "text": "Their grain offering shall be of fine flour mixed with oil: three-tenths of an ephah for each of the thirteen bulls, two-tenths for each of the two rams,", 
                "num": 14
              }, 
              {
                "text": "and one-tenth for each of the fourteen lambs;", 
                "num": 15
              }, 
              {
                "text": "also one kid of the goats as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 16
              }, 
              {
                "text": "\"On the second day present twelve young bulls, two rams, fourteen lambs in their first year without blemish,", 
                "num": 17
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 18
              }, 
              {
                "text": "also one kid of the goats as a sin offering, besides the regular burnt offering with its grain offering, and their drink offerings.", 
                "num": 19
              }, 
              {
                "text": "\"On the third day present eleven bulls, two rams, fourteen lambs in their first year without blemish,", 
                "num": 20
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 21
              }, 
              {
                "text": "also one goat as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 22
              }, 
              {
                "text": "\"On the fourth day present ten bulls, two rams, and fourteen lambs in their first year, without blemish,", 
                "num": 23
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 24
              }, 
              {
                "text": "also one kid of the goats as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 25
              }, 
              {
                "text": "\"On the fifth day present nine bulls, two rams, and fourteen lambs in their first year without blemish,", 
                "num": 26
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 27
              }, 
              {
                "text": "also one goat as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 28
              }, 
              {
                "text": "\"On the sixth day present eight bulls, two rams, and fourteen lambs in their first year without blemish,", 
                "num": 29
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 30
              }, 
              {
                "text": "also one goat as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 31
              }, 
              {
                "text": "\"On the seventh day present seven bulls, two rams, and fourteen lambs in their first year without blemish,", 
                "num": 32
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bulls, for the rams, and for the lambs, by their number, according to the ordinance;", 
                "num": 33
              }, 
              {
                "text": "also one goat as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 34
              }, 
              {
                "text": "\"On the eighth day you shall have a sacred assembly. You shall do no customary work.", 
                "num": 35
              }, 
              {
                "text": "You shall present a burnt offering, an offering made by fire as a sweet aroma to the LORD: one bull, one ram, seven lambs in their first year without blemish,", 
                "num": 36
              }, 
              {
                "text": "and their grain offering and their drink offerings for the bull, for the ram, and for the lambs, by their number, according to the ordinance;", 
                "num": 37
              }, 
              {
                "text": "also one goat as a sin offering, besides the regular burnt offering, its grain offering, and its drink offering.", 
                "num": 38
              }, 
              {
                "text": "\"These you shall present to the LORD at your appointed feasts (besides your vowed offerings and your freewill offerings) as your burnt offerings and your grain offerings, as your drink offerings and your peace offerings.\"'", 
                "num": 39
              }, 
              {
                "text": "So Moses told the children of Israel everything, just as the LORD commanded Moses.", 
                "num": 40
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "Then Moses spoke to the heads of the tribes concerning the children of Israel, saying, \"This is the thing which the LORD has commanded:", 
                "num": 1
              }, 
              {
                "text": "If a man makes a vow to the LORD, or swears an oath to bind himself by some agreement, he shall not break his word; he shall do according to all that proceeds out of his mouth.", 
                "num": 2
              }, 
              {
                "text": "\"Or if a woman makes a vow to the LORD, and binds herself by some agreement while in her father's house in her youth,", 
                "num": 3
              }, 
              {
                "text": "and her father hears her vow and the agreement by which she has bound herself, and her father holds his peace, then all her vows shall stand, and every agreement with which she has bound herself shall stand.", 
                "num": 4
              }, 
              {
                "text": "But if her father overrules her on the day that he hears, then none of her vows nor her agreements by which she has bound herself shall stand; and the LORD will release her, because her father overruled her.", 
                "num": 5
              }, 
              {
                "text": "\"If indeed she takes a husband, while bound by her vows or by a rash utterance from her lips by which she bound herself,", 
                "num": 6
              }, 
              {
                "text": "and her husband hears it, and makes no response to her on the day that he hears, then her vows shall stand, and her agreements by which she bound herself shall stand.", 
                "num": 7
              }, 
              {
                "text": "But if her husband overrules her on the day that he hears it, he shall make void her vow which she took and what she uttered with her lips, by which she bound herself, and the LORD will release her.", 
                "num": 8
              }, 
              {
                "text": "\"Also any vow of a widow or a divorced woman, by which she has bound herself, shall stand against her.", 
                "num": 9
              }, 
              {
                "text": "\"If she vowed in her husband's house, or bound herself by an agreement with an oath,", 
                "num": 10
              }, 
              {
                "text": "and her husband heard it, and made no response to her and did not overrule her, then all her vows shall stand, and every agreement by which she bound herself shall stand.", 
                "num": 11
              }, 
              {
                "text": "But if her husband truly made them void on the day he heard them, then whatever proceeded from her lips concerning her vows or concerning the agreement binding her, it shall not stand; her husband has made them void, and the LORD will release her.", 
                "num": 12
              }, 
              {
                "text": "Every vow and every binding oath to afflict her soul, her husband may confirm it, or her husband may make it void.", 
                "num": 13
              }, 
              {
                "text": "Now if her husband makes no response whatever to her from day to day, then he confirms all her vows or all the agreements that bind her; he confirms them, because he made no response to her on the day that he heard them.", 
                "num": 14
              }, 
              {
                "text": "But if he does make them void after he has heard them, then he shall bear her guilt.\"", 
                "num": 15
              }, 
              {
                "text": "These are the statutes which the LORD commanded Moses, between a man and his wife, and between a father and his daughter in her youth in her father's house.", 
                "num": 16
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take vengeance on the Midianites for the children of Israel. Afterward you shall be gathered to your people.\"", 
                "num": 2
              }, 
              {
                "text": "So Moses spoke to the people, saying, \"Arm some of yourselves for war, and let them go against the Midianites to take vengeance for the LORD on Midian.", 
                "num": 3
              }, 
              {
                "text": "A thousand from each tribe of all the tribes of Israel you shall send to the war.\"", 
                "num": 4
              }, 
              {
                "text": "So there were recruited from the divisions of Israel one thousand from each tribe, twelve thousand armed for war.", 
                "num": 5
              }, 
              {
                "text": "Then Moses sent them to the war, one thousand from each tribe; he sent them to the war with Phinehas the son of Eleazar the priest, with the holy articles and the signal trumpets in his hand.", 
                "num": 6
              }, 
              {
                "text": "And they warred against the Midianites, just as the LORD commanded Moses, and they killed all the males.", 
                "num": 7
              }, 
              {
                "text": "They killed the kings of Midian with the rest of those who were killed--Evi, Rekem, Zur, Hur, and Reba, the five kings of Midian. Balaam the son of Beor they also killed with the sword.", 
                "num": 8
              }, 
              {
                "text": "And the children of Israel took the women of Midian captive, with their little ones, and took as spoil all their cattle, all their flocks, and all their goods.", 
                "num": 9
              }, 
              {
                "text": "They also burned with fire all the cities where they dwelt, and all their forts.", 
                "num": 10
              }, 
              {
                "text": "And they took all the spoil and all the booty--of man and beast.", 
                "num": 11
              }, 
              {
                "text": "Then they brought the captives, the booty, and the spoil to Moses, to Eleazar the priest, and to the congregation of the children of Israel, to the camp in the plains of Moab by the Jordan, across from Jericho.", 
                "num": 12
              }, 
              {
                "text": "And Moses, Eleazar the priest, and all the leaders of the congregation, went to meet them outside the camp.", 
                "num": 13
              }, 
              {
                "text": "But Moses was angry with the officers of the army, with the captains over thousands and captains over hundreds, who had come from the battle.", 
                "num": 14
              }, 
              {
                "text": "And Moses said to them: \"Have you kept all the women alive?", 
                "num": 15
              }, 
              {
                "text": "Look, these women caused the children of Israel, through the counsel of Balaam, to trespass against the LORD in the incident of Peor, and there was a plague among the congregation of the LORD.", 
                "num": 16
              }, 
              {
                "text": "Now therefore, kill every male among the little ones, and kill every woman who has known a man intimately.", 
                "num": 17
              }, 
              {
                "text": "But keep alive for yourselves all the young girls who have not known a man intimately.", 
                "num": 18
              }, 
              {
                "text": "And as for you, remain outside the camp seven days; whoever has killed any person, and whoever has touched any slain, purify yourselves and your captives on the third day and on the seventh day.", 
                "num": 19
              }, 
              {
                "text": "Purify every garment, everything made of leather, everything woven of goats' hair, and everything made of wood.\"", 
                "num": 20
              }, 
              {
                "text": "Then Eleazar the priest said to the men of war who had gone to the battle, \"This is the ordinance of the law which the LORD commanded Moses:", 
                "num": 21
              }, 
              {
                "text": "\"Only the gold, the silver, the bronze, the iron, the tin, and the lead,", 
                "num": 22
              }, 
              {
                "text": "everything that can endure fire, you shall put through the fire, and it shall be clean; and it shall be purified with the water of purification. But all that cannot endure fire you shall put through water.", 
                "num": 23
              }, 
              {
                "text": "And you shall wash your clothes on the seventh day and be clean, and afterward you may come into the camp.\"", 
                "num": 24
              }, 
              {
                "text": "Now the LORD spoke to Moses, saying:", 
                "num": 25
              }, 
              {
                "text": "\"Count up the plunder that was taken--of man and beast--you and Eleazar the priest and the chief fathers of the congregation;", 
                "num": 26
              }, 
              {
                "text": "and divide the plunder into two parts, between those who took part in the war, who went out to battle, and all the congregation.", 
                "num": 27
              }, 
              {
                "text": "And levy a tribute for the LORD on the men of war who went out to battle: one of every five hundred of the persons, the cattle, the donkeys, and the sheep;", 
                "num": 28
              }, 
              {
                "text": "take it from their half, and give it to Eleazar the priest as a heave offering to the LORD.", 
                "num": 29
              }, 
              {
                "text": "And from the children of Israel's half you shall take one of every fifty, drawn from the persons, the cattle, the donkeys, and the sheep, from all the livestock, and give them to the Levites who keep charge of the tabernacle of the LORD.\"", 
                "num": 30
              }, 
              {
                "text": "So Moses and Eleazar the priest did as the LORD commanded Moses.", 
                "num": 31
              }, 
              {
                "text": "The booty remaining from the plunder, which the men of war had taken, was six hundred and seventy-five thousand sheep,", 
                "num": 32
              }, 
              {
                "text": "seventy-two thousand cattle,", 
                "num": 33
              }, 
              {
                "text": "sixty-one thousand donkeys,", 
                "num": 34
              }, 
              {
                "text": "and thirty-two thousand persons in all, of women who had not known a man intimately.", 
                "num": 35
              }, 
              {
                "text": "And the half, the portion for those who had gone out to war, was in number three hundred and thirty-seven thousand five hundred sheep;", 
                "num": 36
              }, 
              {
                "text": "and the LORD's tribute of the sheep was six hundred and seventy-five.", 
                "num": 37
              }, 
              {
                "text": "The cattle were thirty-six thousand, of which the LORD's tribute was seventy-two.", 
                "num": 38
              }, 
              {
                "text": "The donkeys were thirty thousand five hundred, of which the LORD's tribute was sixty-one.", 
                "num": 39
              }, 
              {
                "text": "The persons were sixteen thousand, of which the LORD's tribute was thirty-two persons.", 
                "num": 40
              }, 
              {
                "text": "So Moses gave the tribute which was the LORD's heave offering to Eleazar the priest, as the LORD commanded Moses.", 
                "num": 41
              }, 
              {
                "text": "And from the children of Israel's half, which Moses separated from the men who fought--", 
                "num": 42
              }, 
              {
                "text": "now the half belonging to the congregation was three hundred and thirty-seven thousand five hundred sheep,", 
                "num": 43
              }, 
              {
                "text": "thirty-six thousand cattle,", 
                "num": 44
              }, 
              {
                "text": "thirty thousand five hundred donkeys,", 
                "num": 45
              }, 
              {
                "text": "and sixteen thousand persons--", 
                "num": 46
              }, 
              {
                "text": "and from the children of Israel's half Moses took one of every fifty, drawn from man and beast, and gave them to the Levites, who kept charge of the tabernacle of the LORD, as the LORD commanded Moses.", 
                "num": 47
              }, 
              {
                "text": "Then the officers who were over thousands of the army, the captains of thousands and captains of hundreds, came near to Moses;", 
                "num": 48
              }, 
              {
                "text": "and they said to Moses, \"Your servants have taken a count of the men of war who are under our command, and not a man of us is missing.", 
                "num": 49
              }, 
              {
                "text": "Therefore we have brought an offering for the LORD, what every man found of ornaments of gold: armlets and bracelets and signet rings and earrings and necklaces, to make atonement for ourselves before the LORD.\"", 
                "num": 50
              }, 
              {
                "text": "So Moses and Eleazar the priest received the gold from them, all the fashioned ornaments.", 
                "num": 51
              }, 
              {
                "text": "And all the gold of the offering that they offered to the LORD, from the captains of thousands and captains of hundreds, was sixteen thousand seven hundred and fifty shekels.", 
                "num": 52
              }, 
              {
                "text": "(The men of war had taken spoil, every man for himself.)", 
                "num": 53
              }, 
              {
                "text": "And Moses and Eleazar the priest received the gold from the captains of thousands and of hundreds, and brought it into the tabernacle of meeting as a memorial for the children of Israel before the LORD.", 
                "num": 54
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "Now the children of Reuben and the children of Gad had a very great multitude of livestock; and when they saw the land of Jazer and the land of Gilead, that indeed the region was a place for livestock,", 
                "num": 1
              }, 
              {
                "text": "the children of Gad and the children of Reuben came and spoke to Moses, to Eleazar the priest, and to the leaders of the congregation, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Ataroth, Dibon, Jazer, Nimrah, Heshbon, Elealeh, Shebam, Nebo, and Beon,", 
                "num": 3
              }, 
              {
                "text": "the country which the LORD defeated before the congregation of Israel, is a land for livestock, and your servants have livestock.\"", 
                "num": 4
              }, 
              {
                "text": "Therefore they said, \"If we have found favor in your sight, let this land be given to your servants as a possession. Do not take us over the Jordan.\"", 
                "num": 5
              }, 
              {
                "text": "And Moses said to the children of Gad and to the children of Reuben: \"Shall your brethren go to war while you sit here?", 
                "num": 6
              }, 
              {
                "text": "Now why will you discourage the heart of the children of Israel from going over into the land which the LORD has given them?", 
                "num": 7
              }, 
              {
                "text": "Thus your fathers did when I sent them away from Kadesh Barnea to see the land.", 
                "num": 8
              }, 
              {
                "text": "For when they went up to the Valley of Eshcol and saw the land, they discouraged the heart of the children of Israel, so that they did not go into the land which the LORD had given them.", 
                "num": 9
              }, 
              {
                "text": "So the LORD's anger was aroused on that day, and He swore an oath, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Surely none of the men who came up from Egypt, from twenty years old and above, shall see the land of which I swore to Abraham, Isaac, and Jacob, because they have not wholly followed Me,", 
                "num": 11
              }, 
              {
                "text": "except Caleb the son of Jephunneh, the Kenizzite, and Joshua the son of Nun, for they have wholly followed the LORD.'", 
                "num": 12
              }, 
              {
                "text": "So the LORD's anger was aroused against Israel, and He made them wander in the wilderness forty years, until all the generation that had done evil in the sight of the LORD was gone.", 
                "num": 13
              }, 
              {
                "text": "And look! You have risen in your fathers' place, a brood of sinful men, to increase still more the fierce anger of the LORD against Israel.", 
                "num": 14
              }, 
              {
                "text": "For if you turn away from following Him, He will once again leave them in the wilderness, and you will destroy all these people.\"", 
                "num": 15
              }, 
              {
                "text": "Then they came near to him and said: \"We will build sheepfolds here for our livestock, and cities for our little ones,", 
                "num": 16
              }, 
              {
                "text": "but we ourselves will be armed, ready to go before the children of Israel until we have brought them to their place; and our little ones will dwell in the fortified cities because of the inhabitants of the land.", 
                "num": 17
              }, 
              {
                "text": "We will not return to our homes until every one of the children of Israel has received his inheritance.", 
                "num": 18
              }, 
              {
                "text": "For we will not inherit with them on the other side of the Jordan and beyond, because our inheritance has fallen to us on this eastern side of the Jordan.\"", 
                "num": 19
              }, 
              {
                "text": "Then Moses said to them: \"If you do this thing, if you arm yourselves before the LORD for the war,", 
                "num": 20
              }, 
              {
                "text": "and all your armed men cross over the Jordan before the LORD until He has driven out His enemies from before Him,", 
                "num": 21
              }, 
              {
                "text": "and the land is subdued before the LORD, then afterward you may return and be blameless before the LORD and before Israel; and this land shall be your possession before the LORD.", 
                "num": 22
              }, 
              {
                "text": "But if you do not do so, then take note, you have sinned against the LORD; and be sure your sin will find you out.", 
                "num": 23
              }, 
              {
                "text": "Build cities for your little ones and folds for your sheep, and do what has proceeded out of your mouth.\"", 
                "num": 24
              }, 
              {
                "text": "And the children of Gad and the children of Reuben spoke to Moses, saying: \"Your servants will do as my lord commands.", 
                "num": 25
              }, 
              {
                "text": "Our little ones, our wives, our flocks, and all our livestock will be there in the cities of Gilead;", 
                "num": 26
              }, 
              {
                "text": "but your servants will cross over, every man armed for war, before the LORD to battle, just as my lord says.\"", 
                "num": 27
              }, 
              {
                "text": "So Moses gave command concerning them to Eleazar the priest, to Joshua the son of Nun, and to the chief fathers of the tribes of the children of Israel.", 
                "num": 28
              }, 
              {
                "text": "And Moses said to them: \"If the children of Gad and the children of Reuben cross over the Jordan with you, every man armed for battle before the LORD, and the land is subdued before you, then you shall give them the land of Gilead as a possession.", 
                "num": 29
              }, 
              {
                "text": "But if they do not cross over armed with you, they shall have possessions among you in the land of Canaan.\"", 
                "num": 30
              }, 
              {
                "text": "Then the children of Gad and the children of Reuben answered, saying: \"As the LORD has said to your servants, so we will do.", 
                "num": 31
              }, 
              {
                "text": "We will cross over armed before the LORD into the land of Canaan, but the possession of our inheritance shall remain with us on this side of the Jordan.\"", 
                "num": 32
              }, 
              {
                "text": "So Moses gave to the children of Gad, to the children of Reuben, and to half the tribe of Manasseh the son of Joseph, the kingdom of Sihon king of the Amorites and the kingdom of Og king of Bashan, the land with its cities within the borders, the cities of the surrounding country.", 
                "num": 33
              }, 
              {
                "text": "And the children of Gad built Dibon and Ataroth and Aroer,", 
                "num": 34
              }, 
              {
                "text": "Atroth and Shophan and Jazer and Jogbehah,", 
                "num": 35
              }, 
              {
                "text": "Beth Nimrah and Beth Haran, fortified cities, and folds for sheep.", 
                "num": 36
              }, 
              {
                "text": "And the children of Reuben built Heshbon and Elealeh and Kirjathaim,", 
                "num": 37
              }, 
              {
                "text": "Nebo and Baal Meon (their names being changed) and Shibmah; and they gave other names to the cities which they built.", 
                "num": 38
              }, 
              {
                "text": "And the children of Machir the son of Manasseh went to Gilead and took it, and dispossessed the Amorites who were in it.", 
                "num": 39
              }, 
              {
                "text": "So Moses gave Gilead to Machir the son of Manasseh, and he dwelt in it.", 
                "num": 40
              }, 
              {
                "text": "Also Jair the son of Manasseh went and took its small towns, and called them Havoth Jair.", 
                "num": 41
              }, 
              {
                "text": "Then Nobah went and took Kenath and its villages, and he called it Nobah, after his own name.", 
                "num": 42
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "These are the journeys of the children of Israel, who went out of the land of Egypt by their armies under the hand of Moses and Aaron.", 
                "num": 1
              }, 
              {
                "text": "Now Moses wrote down the starting points of their journeys at the command of the LORD. And these are their journeys according to their starting points:", 
                "num": 2
              }, 
              {
                "text": "They departed from Rameses in the first month, on the fifteenth day of the first month; on the day after the Passover the children of Israel went out with boldness in the sight of all the Egyptians.", 
                "num": 3
              }, 
              {
                "text": "For the Egyptians were burying all their firstborn, whom the LORD had killed among them. Also on their gods the LORD had executed judgments.", 
                "num": 4
              }, 
              {
                "text": "Then the children of Israel moved from Rameses and camped at Succoth.", 
                "num": 5
              }, 
              {
                "text": "They departed from Succoth and camped at Etham, which is on the edge of the wilderness.", 
                "num": 6
              }, 
              {
                "text": "They moved from Etham and turned back to Pi Hahiroth, which is east of Baal Zephon; and they camped near Migdol.", 
                "num": 7
              }, 
              {
                "text": "They departed from before Hahiroth and passed through the midst of the sea into the wilderness, went three days' journey in the Wilderness of Etham, and camped at Marah.", 
                "num": 8
              }, 
              {
                "text": "They moved from Marah and came to Elim. At Elim were twelve springs of water and seventy palm trees; so they camped there.", 
                "num": 9
              }, 
              {
                "text": "They moved from Elim and camped by the Red Sea.", 
                "num": 10
              }, 
              {
                "text": "They moved from the Red Sea and camped in the Wilderness of Sin.", 
                "num": 11
              }, 
              {
                "text": "They journeyed from the Wilderness of Sin and camped at Dophkah.", 
                "num": 12
              }, 
              {
                "text": "They departed from Dophkah and camped at Alush.", 
                "num": 13
              }, 
              {
                "text": "They moved from Alush and camped at Rephidim, where there was no water for the people to drink.", 
                "num": 14
              }, 
              {
                "text": "They departed from Rephidim and camped in the Wilderness of Sinai.", 
                "num": 15
              }, 
              {
                "text": "They moved from the Wilderness of Sinai and camped at Kibroth Hattaavah.", 
                "num": 16
              }, 
              {
                "text": "They departed from Kibroth Hattaavah and camped at Hazeroth.", 
                "num": 17
              }, 
              {
                "text": "They departed from Hazeroth and camped at Rithmah.", 
                "num": 18
              }, 
              {
                "text": "They departed from Rithmah and camped at Rimmon Perez.", 
                "num": 19
              }, 
              {
                "text": "They departed from Rimmon Perez and camped at Libnah.", 
                "num": 20
              }, 
              {
                "text": "They moved from Libnah and camped at Rissah.", 
                "num": 21
              }, 
              {
                "text": "They journeyed from Rissah and camped at Kehelathah.", 
                "num": 22
              }, 
              {
                "text": "They went from Kehelathah and camped at Mount Shepher.", 
                "num": 23
              }, 
              {
                "text": "They moved from Mount Shepher and camped at Haradah.", 
                "num": 24
              }, 
              {
                "text": "They moved from Haradah and camped at Makheloth.", 
                "num": 25
              }, 
              {
                "text": "They moved from Makheloth and camped at Tahath.", 
                "num": 26
              }, 
              {
                "text": "They departed from Tahath and camped at Terah.", 
                "num": 27
              }, 
              {
                "text": "They moved from Terah and camped at Mithkah.", 
                "num": 28
              }, 
              {
                "text": "They went from Mithkah and camped at Hashmonah.", 
                "num": 29
              }, 
              {
                "text": "They departed from Hashmonah and camped at Moseroth.", 
                "num": 30
              }, 
              {
                "text": "They departed from Moseroth and camped at Bene Jaakan.", 
                "num": 31
              }, 
              {
                "text": "They moved from Bene Jaakan and camped at Hor Hagidgad.", 
                "num": 32
              }, 
              {
                "text": "They went from Hor Hagidgad and camped at Jotbathah.", 
                "num": 33
              }, 
              {
                "text": "They moved from Jotbathah and camped at Abronah.", 
                "num": 34
              }, 
              {
                "text": "They departed from Abronah and camped at Ezion Geber.", 
                "num": 35
              }, 
              {
                "text": "They moved from Ezion Geber and camped in the Wilderness of Zin, which is Kadesh.", 
                "num": 36
              }, 
              {
                "text": "They moved from Kadesh and camped at Mount Hor, on the boundary of the land of Edom.", 
                "num": 37
              }, 
              {
                "text": "Then Aaron the priest went up to Mount Hor at the command of the LORD, and died there in the fortieth year after the children of Israel had come out of the land of Egypt, on the first day of the fifth month.", 
                "num": 38
              }, 
              {
                "text": "Aaron was one hundred and twenty-three years old when he died on Mount Hor.", 
                "num": 39
              }, 
              {
                "text": "Now the king of Arad, the Canaanite, who dwelt in the South in the land of Canaan, heard of the coming of the children of Israel.", 
                "num": 40
              }, 
              {
                "text": "So they departed from Mount Hor and camped at Zalmonah.", 
                "num": 41
              }, 
              {
                "text": "They departed from Zalmonah and camped at Punon.", 
                "num": 42
              }, 
              {
                "text": "They departed from Punon and camped at Oboth.", 
                "num": 43
              }, 
              {
                "text": "They departed from Oboth and camped at Ije Abarim, at the border of Moab.", 
                "num": 44
              }, 
              {
                "text": "They departed from Ijim and camped at Dibon Gad.", 
                "num": 45
              }, 
              {
                "text": "They moved from Dibon Gad and camped at Almon Diblathaim.", 
                "num": 46
              }, 
              {
                "text": "They moved from Almon Diblathaim and camped in the mountains of Abarim, before Nebo.", 
                "num": 47
              }, 
              {
                "text": "They departed from the mountains of Abarim and camped in the plains of Moab by the Jordan, across from Jericho.", 
                "num": 48
              }, 
              {
                "text": "They camped by the Jordan, from Beth Jesimoth as far as the Abel Acacia Grove in the plains of Moab.", 
                "num": 49
              }, 
              {
                "text": "Now the LORD spoke to Moses in the plains of Moab by the Jordan, across from Jericho, saying,", 
                "num": 50
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you have crossed the Jordan into the land of Canaan,", 
                "num": 51
              }, 
              {
                "text": "then you shall drive out all the inhabitants of the land from before you, destroy all their engraved stones, destroy all their molded images, and demolish all their high places;", 
                "num": 52
              }, 
              {
                "text": "you shall dispossess the inhabitants of the land and dwell in it, for I have given you the land to possess.", 
                "num": 53
              }, 
              {
                "text": "And you shall divide the land by lot as an inheritance among your families; to the larger you shall give a larger inheritance, and to the smaller you shall give a smaller inheritance; there everyone's inheritance shall be whatever falls to him by lot. You shall inherit according to the tribes of your fathers.", 
                "num": 54
              }, 
              {
                "text": "But if you do not drive out the inhabitants of the land from before you, then it shall be that those whom you let remain shall be irritants in your eyes and thorns in your sides, and they shall harass you in the land where you dwell.", 
                "num": 55
              }, 
              {
                "text": "Moreover it shall be that I will do to you as I thought to do to them.\"'", 
                "num": 56
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Command the children of Israel, and say to them: \"When you come into the land of Canaan, this is the land that shall fall to you as an inheritance--the land of Canaan to its boundaries.", 
                "num": 2
              }, 
              {
                "text": "Your southern border shall be from the Wilderness of Zin along the border of Edom; then your southern border shall extend eastward to the end of the Salt Sea;", 
                "num": 3
              }, 
              {
                "text": "your border shall turn from the southern side of the Ascent of Akrabbim, continue to Zin, and be on the south of Kadesh Barnea; then it shall go on to Hazar Addar, and continue to Azmon;", 
                "num": 4
              }, 
              {
                "text": "the border shall turn from Azmon to the Brook of Egypt, and it shall end at the Sea.", 
                "num": 5
              }, 
              {
                "text": "\"As for the western border, you shall have the Great Sea for a border; this shall be your western border.", 
                "num": 6
              }, 
              {
                "text": "\"And this shall be your northern border: From the Great Sea you shall mark out your border line to Mount Hor;", 
                "num": 7
              }, 
              {
                "text": "from Mount Hor you shall mark out your border to the entrance of Hamath; then the direction of the border shall be toward Zedad;", 
                "num": 8
              }, 
              {
                "text": "the border shall proceed to Ziphron, and it shall end at Hazar Enan. This shall be your northern border.", 
                "num": 9
              }, 
              {
                "text": "\"You shall mark out your eastern border from Hazar Enan to Shepham;", 
                "num": 10
              }, 
              {
                "text": "the border shall go down from Shepham to Riblah on the east side of Ain; the border shall go down and reach to the eastern side of the Sea of Chinnereth;", 
                "num": 11
              }, 
              {
                "text": "the border shall go down along the Jordan, and it shall end at the Salt Sea. This shall be your land with its surrounding boundaries.\"'", 
                "num": 12
              }, 
              {
                "text": "Then Moses commanded the children of Israel, saying: \"This is the land which you shall inherit by lot, which the LORD has commanded to give to the nine tribes and to the half-tribe.", 
                "num": 13
              }, 
              {
                "text": "For the tribe of the children of Reuben according to the house of their fathers, and the tribe of the children of Gad according to the house of their fathers, have received their inheritance; and the half-tribe of Manasseh has received its inheritance.", 
                "num": 14
              }, 
              {
                "text": "The two tribes and the half-tribe have received their inheritance on this side of the Jordan, across from Jericho eastward, toward the sunrise.\"", 
                "num": 15
              }, 
              {
                "text": "And the LORD spoke to Moses, saying,", 
                "num": 16
              }, 
              {
                "text": "\"These are the names of the men who shall divide the land among you as an inheritance: Eleazar the priest and Joshua the son of Nun.", 
                "num": 17
              }, 
              {
                "text": "And you shall take one leader of every tribe to divide the land for the inheritance.", 
                "num": 18
              }, 
              {
                "text": "These are the names of the men: from the tribe of Judah, Caleb the son of Jephunneh;", 
                "num": 19
              }, 
              {
                "text": "from the tribe of the children of Simeon, Shemuel the son of Ammihud;", 
                "num": 20
              }, 
              {
                "text": "from the tribe of Benjamin, Elidad the son of Chislon;", 
                "num": 21
              }, 
              {
                "text": "a leader from the tribe of the children of Dan, Bukki the son of Jogli;", 
                "num": 22
              }, 
              {
                "text": "from the sons of Joseph: a leader from the tribe of the children of Manasseh, Hanniel the son of Ephod,", 
                "num": 23
              }, 
              {
                "text": "and a leader from the tribe of the children of Ephraim, Kemuel the son of Shiphtan;", 
                "num": 24
              }, 
              {
                "text": "a leader from the tribe of the children of Zebulun, Elizaphan the son of Parnach;", 
                "num": 25
              }, 
              {
                "text": "a leader from the tribe of the children of Issachar, Paltiel the son of Azzan;", 
                "num": 26
              }, 
              {
                "text": "a leader from the tribe of the children of Asher, Ahihud the son of Shelomi;", 
                "num": 27
              }, 
              {
                "text": "and a leader from the tribe of the children of Naphtali, Pedahel the son of Ammihud.\"", 
                "num": 28
              }, 
              {
                "text": "These are the ones the LORD commanded to divide the inheritance among the children of Israel in the land of Canaan.", 
                "num": 29
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "And the LORD spoke to Moses in the plains of Moab by the Jordan across from Jericho, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Command the children of Israel that they give the Levites cities to dwell in from the inheritance of their possession, and you shall also give the Levites common-land around the cities.", 
                "num": 2
              }, 
              {
                "text": "They shall have the cities to dwell in; and their common-land shall be for their cattle, for their herds, and for all their animals.", 
                "num": 3
              }, 
              {
                "text": "The common-land of the cities which you will give the Levites shall extend from the wall of the city outward a thousand cubits all around.", 
                "num": 4
              }, 
              {
                "text": "And you shall measure outside the city on the east side two thousand cubits, on the south side two thousand cubits, on the west side two thousand cubits, and on the north side two thousand cubits. The city shall be in the middle. This shall belong to them as common-land for the cities.", 
                "num": 5
              }, 
              {
                "text": "\"Now among the cities which you will give to the Levites you shall appoint six cities of refuge, to which a manslayer may flee. And to these you shall add forty-two cities.", 
                "num": 6
              }, 
              {
                "text": "So all the cities you will give to the Levites shall be forty-eight; these you shall give with their common-land.", 
                "num": 7
              }, 
              {
                "text": "And the cities which you will give shall be from the possession of the children of Israel; from the larger tribe you shall give many, from the smaller you shall give few. Each shall give some of its cities to the Levites, in proportion to the inheritance that each receives.\"", 
                "num": 8
              }, 
              {
                "text": "Then the LORD spoke to Moses, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Speak to the children of Israel, and say to them: \"When you cross the Jordan into the land of Canaan,", 
                "num": 10
              }, 
              {
                "text": "then you shall appoint cities to be cities of refuge for you, that the manslayer who kills any person accidentally may flee there.", 
                "num": 11
              }, 
              {
                "text": "They shall be cities of refuge for you from the avenger, that the manslayer may not die until he stands before the congregation in judgment.", 
                "num": 12
              }, 
              {
                "text": "And of the cities which you give, you shall have six cities of refuge.", 
                "num": 13
              }, 
              {
                "text": "You shall appoint three cities on this side of the Jordan, and three cities you shall appoint in the land of Canaan, which will be cities of refuge.", 
                "num": 14
              }, 
              {
                "text": "These six cities shall be for refuge for the children of Israel, for the stranger, and for the sojourner among them, that anyone who kills a person accidentally may flee there.", 
                "num": 15
              }, 
              {
                "text": "\"But if he strikes him with an iron implement, so that he dies, he is a murderer; the murderer shall surely be put to death.", 
                "num": 16
              }, 
              {
                "text": "And if he strikes him with a stone in the hand, by which one could die, and he does die, he is a murderer; the murderer shall surely be put to death.", 
                "num": 17
              }, 
              {
                "text": "Or if he strikes him with a wooden hand weapon, by which one could die, and he does die, he is a murderer; the murderer shall surely be put to death.", 
                "num": 18
              }, 
              {
                "text": "The avenger of blood himself shall put the murderer to death; when he meets him, he shall put him to death.", 
                "num": 19
              }, 
              {
                "text": "If he pushes him out of hatred or, while lying in wait, hurls something at him so that he dies,", 
                "num": 20
              }, 
              {
                "text": "or in enmity he strikes him with his hand so that he dies, the one who struck him shall surely be put to death. He is a murderer. The avenger of blood shall put the murderer to death when he meets him.", 
                "num": 21
              }, 
              {
                "text": "\"However, if he pushes him suddenly without enmity, or throws anything at him without lying in wait,", 
                "num": 22
              }, 
              {
                "text": "or uses a stone, by which a man could die, throwing it at him without seeing him, so that he dies, while he was not his enemy or seeking his harm,", 
                "num": 23
              }, 
              {
                "text": "then the congregation shall judge between the manslayer and the avenger of blood according to these judgments.", 
                "num": 24
              }, 
              {
                "text": "So the congregation shall deliver the manslayer from the hand of the avenger of blood, and the congregation shall return him to the city of refuge where he had fled, and he shall remain there until the death of the high priest who was anointed with the holy oil.", 
                "num": 25
              }, 
              {
                "text": "But if the manslayer at any time goes outside the limits of the city of refuge where he fled,", 
                "num": 26
              }, 
              {
                "text": "and the avenger of blood finds him outside the limits of his city of refuge, and the avenger of blood kills the manslayer, he shall not be guilty of blood,", 
                "num": 27
              }, 
              {
                "text": "because he should have remained in his city of refuge until the death of the high priest. But after the death of the high priest the manslayer may return to the land of his possession.", 
                "num": 28
              }, 
              {
                "text": "\"And these things shall be a statute of judgment to you throughout your generations in all your dwellings.", 
                "num": 29
              }, 
              {
                "text": "Whoever kills a person, the murderer shall be put to death on the testimony of witnesses; but one witness is not sufficient testimony against a person for the death penalty.", 
                "num": 30
              }, 
              {
                "text": "Moreover you shall take no ransom for the life of a murderer who is guilty of death, but he shall surely be put to death.", 
                "num": 31
              }, 
              {
                "text": "And you shall take no ransom for him who has fled to his city of refuge, that he may return to dwell in the land before the death of the priest.", 
                "num": 32
              }, 
              {
                "text": "So you shall not pollute the land where you are; for blood defiles the land, and no atonement can be made for the land, for the blood that is shed on it, except by the blood of him who shed it.", 
                "num": 33
              }, 
              {
                "text": "Therefore do not defile the land which you inhabit, in the midst of which I dwell; for I the LORD dwell among the children of Israel.\"'", 
                "num": 34
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Now the chief fathers of the families of the children of Gilead the son of Machir, the son of Manasseh, of the families of the sons of Joseph, came near and spoke before Moses and before the leaders, the chief fathers of the children of Israel.", 
                "num": 1
              }, 
              {
                "text": "And they said: \"The LORD commanded my lord Moses to give the land as an inheritance by lot to the children of Israel, and my lord was commanded by the LORD to give the inheritance of our brother Zelophehad to his daughters.", 
                "num": 2
              }, 
              {
                "text": "Now if they are married to any of the sons of the other tribes of the children of Israel, then their inheritance will be taken from the inheritance of our fathers, and it will be added to the inheritance of the tribe into which they marry; so it will be taken from the lot of our inheritance.", 
                "num": 3
              }, 
              {
                "text": "And when the Jubilee of the children of Israel comes, then their inheritance will be added to the inheritance of the tribe into which they marry; so their inheritance will be taken away from the inheritance of the tribe of our fathers.\"", 
                "num": 4
              }, 
              {
                "text": "Then Moses commanded the children of Israel according to the word of the LORD, saying: \"What the tribe of the sons of Joseph speaks is right.", 
                "num": 5
              }, 
              {
                "text": "This is what the LORD commands concerning the daughters of Zelophehad, saying, \"Let them marry whom they think best, but they may marry only within the family of their father's tribe.'", 
                "num": 6
              }, 
              {
                "text": "So the inheritance of the children of Israel shall not change hands from tribe to tribe, for every one of the children of Israel shall keep the inheritance of the tribe of his fathers.", 
                "num": 7
              }, 
              {
                "text": "And every daughter who possesses an inheritance in any tribe of the children of Israel shall be the wife of one of the family of her father's tribe, so that the children of Israel each may possess the inheritance of his fathers.", 
                "num": 8
              }, 
              {
                "text": "Thus no inheritance shall change hands from one tribe to another, but every tribe of the children of Israel shall keep its own inheritance.\"", 
                "num": 9
              }, 
              {
                "text": "Just as the LORD commanded Moses, so did the daughters of Zelophehad;", 
                "num": 10
              }, 
              {
                "text": "for Mahlah, Tirzah, Hoglah, Milcah, and Noah, the daughters of Zelophehad, were married to the sons of their father's brothers.", 
                "num": 11
              }, 
              {
                "text": "They were married into the families of the children of Manasseh the son of Joseph, and their inheritance remained in the tribe of their father's family.", 
                "num": 12
              }, 
              {
                "text": "These are the commandments and the judgments which the LORD commanded the children of Israel by the hand of Moses in the plains of Moab by the Jordan, across from Jericho.", 
                "num": 13
              }
            ], 
            "num": 36
          }
        ]
      }, 
      {
        "name": "Deuteronomy", 
        "chapters": [
          {
            "verses": [
              {
                "text": "These are the words which Moses spoke to all Israel on this side of the Jordan in the wilderness, in the plain opposite Suph, between Paran, Tophel, Laban, Hazeroth, and Dizahab.", 
                "num": 1
              }, 
              {
                "text": "It is eleven days' journey from Horeb by way of Mount Seir to Kadesh Barnea.", 
                "num": 2
              }, 
              {
                "text": "Now it came to pass in the fortieth year, in the eleventh month, on the first day of the month, that Moses spoke to the children of Israel according to all that the LORD had given him as commandments to them,", 
                "num": 3
              }, 
              {
                "text": "after he had killed Sihon king of the Amorites, who dwelt in Heshbon, and Og king of Bashan, who dwelt at Ashtaroth in Edrei.", 
                "num": 4
              }, 
              {
                "text": "On this side of the Jordan in the land of Moab, Moses began to explain this law, saying,", 
                "num": 5
              }, 
              {
                "text": "\"The LORD our God spoke to us in Horeb, saying: \"You have dwelt long enough at this mountain.", 
                "num": 6
              }, 
              {
                "text": "Turn and take your journey, and go to the mountains of the Amorites, to all the neighboring places in the plain, in the mountains and in the lowland, in the South and on the seacoast, to the land of the Canaanites and to Lebanon, as far as the great river, the River Euphrates.", 
                "num": 7
              }, 
              {
                "text": "See, I have set the land before you; go in and possess the land which the LORD swore to your fathers--to Abraham, Isaac, and Jacob--to give to them and their descendants after them.'", 
                "num": 8
              }, 
              {
                "text": "\"And I spoke to you at that time, saying: \"I alone am not able to bear you.", 
                "num": 9
              }, 
              {
                "text": "The LORD your God has multiplied you, and here you are today, as the stars of heaven in multitude.", 
                "num": 10
              }, 
              {
                "text": "May the LORD God of your fathers make you a thousand times more numerous than you are, and bless you as He has promised you!", 
                "num": 11
              }, 
              {
                "text": "How can I alone bear your problems and your burdens and your complaints?", 
                "num": 12
              }, 
              {
                "text": "Choose wise, understanding, and knowledgeable men from among your tribes, and I will make them heads over you.'", 
                "num": 13
              }, 
              {
                "text": "And you answered me and said, \"The thing which you have told us to do is good.'", 
                "num": 14
              }, 
              {
                "text": "So I took the heads of your tribes, wise and knowledgeable men, and made them heads over you, leaders of thousands, leaders of hundreds, leaders of fifties, leaders of tens, and officers for your tribes.", 
                "num": 15
              }, 
              {
                "text": "\"Then I commanded your judges at that time, saying, \"Hear the cases between your brethren, and judge righteously between a man and his brother or the stranger who is with him.", 
                "num": 16
              }, 
              {
                "text": "You shall not show partiality in judgment; you shall hear the small as well as the great; you shall not be afraid in any man's presence, for the judgment is God's. The case that is too hard for you, bring to me, and I will hear it.'", 
                "num": 17
              }, 
              {
                "text": "And I commanded you at that time all the things which you should do.", 
                "num": 18
              }, 
              {
                "text": "\"So we departed from Horeb, and went through all that great and terrible wilderness which you saw on the way to the mountains of the Amorites, as the LORD our God had commanded us. Then we came to Kadesh Barnea.", 
                "num": 19
              }, 
              {
                "text": "And I said to you, \"You have come to the mountains of the Amorites, which the LORD our God is giving us.", 
                "num": 20
              }, 
              {
                "text": "Look, the LORD your God has set the land before you; go up and possess it, as the LORD God of your fathers has spoken to you; do not fear or be discouraged.'", 
                "num": 21
              }, 
              {
                "text": "\"And every one of you came near to me and said, \"Let us send men before us, and let them search out the land for us, and bring back word to us of the way by which we should go up, and of the cities into which we shall come.'", 
                "num": 22
              }, 
              {
                "text": "\"The plan pleased me well; so I took twelve of your men, one man from each tribe.", 
                "num": 23
              }, 
              {
                "text": "And they departed and went up into the mountains, and came to the Valley of Eshcol, and spied it out.", 
                "num": 24
              }, 
              {
                "text": "They also took some of the fruit of the land in their hands and brought it down to us; and they brought back word to us, saying, \"It is a good land which the LORD our God is giving us.'", 
                "num": 25
              }, 
              {
                "text": "\"Nevertheless you would not go up, but rebelled against the command of the LORD your God;", 
                "num": 26
              }, 
              {
                "text": "and you complained in your tents, and said, \"Because the LORD hates us, He has brought us out of the land of Egypt to deliver us into the hand of the Amorites, to destroy us.", 
                "num": 27
              }, 
              {
                "text": "Where can we go up? Our brethren have discouraged our hearts, saying, \"The people are greater and taller than we; the cities are great and fortified up to heaven; moreover we have seen the sons of the Anakim there.\"'", 
                "num": 28
              }, 
              {
                "text": "\"Then I said to you, \"Do not be terrified, or afraid of them.", 
                "num": 29
              }, 
              {
                "text": "The LORD your God, who goes before you, He will fight for you, according to all He did for you in Egypt before your eyes,", 
                "num": 30
              }, 
              {
                "text": "and in the wilderness where you saw how the LORD your God carried you, as a man carries his son, in all the way that you went until you came to this place.'", 
                "num": 31
              }, 
              {
                "text": "Yet, for all that, you did not believe the LORD your God,", 
                "num": 32
              }, 
              {
                "text": "who went in the way before you to search out a place for you to pitch your tents, to show you the way you should go, in the fire by night and in the cloud by day.", 
                "num": 33
              }, 
              {
                "text": "\"And the LORD heard the sound of your words, and was angry, and took an oath, saying,", 
                "num": 34
              }, 
              {
                "text": "\"Surely not one of these men of this evil generation shall see that good land of which I swore to give to your fathers,", 
                "num": 35
              }, 
              {
                "text": "except Caleb the son of Jephunneh; he shall see it, and to him and his children I am giving the land on which he walked, because he wholly followed the LORD.'", 
                "num": 36
              }, 
              {
                "text": "The LORD was also angry with me for your sakes, saying, \"Even you shall not go in there.", 
                "num": 37
              }, 
              {
                "text": "Joshua the son of Nun, who stands before you, he shall go in there. Encourage him, for he shall cause Israel to inherit it.", 
                "num": 38
              }, 
              {
                "text": "\"Moreover your little ones and your children, who you say will be victims, who today have no knowledge of good and evil, they shall go in there; to them I will give it, and they shall possess it.", 
                "num": 39
              }, 
              {
                "text": "But as for you, turn and take your journey into the wilderness by the Way of the Red Sea.'", 
                "num": 40
              }, 
              {
                "text": "\"Then you answered and said to me, \"We have sinned against the LORD; we will go up and fight, just as the LORD our God commanded us.' And when everyone of you had girded on his weapons of war, you were ready to go up into the mountain.", 
                "num": 41
              }, 
              {
                "text": "\"And the LORD said to me, \"Tell them, \"Do not go up nor fight, for I am not among you; lest you be defeated before your enemies.\"'", 
                "num": 42
              }, 
              {
                "text": "So I spoke to you; yet you would not listen, but rebelled against the command of the LORD, and presumptuously went up into the mountain.", 
                "num": 43
              }, 
              {
                "text": "And the Amorites who dwelt in that mountain came out against you and chased you as bees do, and drove you back from Seir to Hormah.", 
                "num": 44
              }, 
              {
                "text": "Then you returned and wept before the LORD, but the LORD would not listen to your voice nor give ear to you.", 
                "num": 45
              }, 
              {
                "text": "\"So you remained in Kadesh many days, according to the days that you spent there.", 
                "num": 46
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "\"Then we turned and journeyed into the wilderness of the Way of the Red Sea, as the LORD spoke to me, and we skirted Mount Seir for many days.", 
                "num": 1
              }, 
              {
                "text": "\"And the LORD spoke to me, saying:", 
                "num": 2
              }, 
              {
                "text": "\"You have skirted this mountain long enough; turn northward.", 
                "num": 3
              }, 
              {
                "text": "And command the people, saying, \"You are about to pass through the territory of your brethren, the descendants of Esau, who live in Seir; and they will be afraid of you. Therefore watch yourselves carefully.", 
                "num": 4
              }, 
              {
                "text": "Do not meddle with them, for I will not give you any of their land, no, not so much as one footstep, because I have given Mount Seir to Esau as a possession.", 
                "num": 5
              }, 
              {
                "text": "You shall buy food from them with money, that you may eat; and you shall also buy water from them with money, that you may drink.", 
                "num": 6
              }, 
              {
                "text": "\"For the LORD your God has blessed you in all the work of your hand. He knows your trudging through this great wilderness. These forty years the LORD your God has been with you; you have lacked nothing.\"'", 
                "num": 7
              }, 
              {
                "text": "\"And when we passed beyond our brethren, the descendants of Esau who dwell in Seir, away from the road of the plain, away from Elath and Ezion Geber, we turned and passed by way of the Wilderness of Moab.", 
                "num": 8
              }, 
              {
                "text": "Then the LORD said to me, \"Do not harass Moab, nor contend with them in battle, for I will not give you any of their land as a possession, because I have given Ar to the descendants of Lot as a possession.\"'", 
                "num": 9
              }, 
              {
                "text": "(The Emim had dwelt there in times past, a people as great and numerous and tall as the Anakim.", 
                "num": 10
              }, 
              {
                "text": "They were also regarded as giants, like the Anakim, but the Moabites call them Emim.", 
                "num": 11
              }, 
              {
                "text": "The Horites formerly dwelt in Seir, but the descendants of Esau dispossessed them and destroyed them from before them, and dwelt in their place, just as Israel did to the land of their possession which the LORD gave them.)", 
                "num": 12
              }, 
              {
                "text": "\"\"Now rise and cross over the Valley of the Zered.' So we crossed over the Valley of the Zered.", 
                "num": 13
              }, 
              {
                "text": "And the time we took to come from Kadesh Barnea until we crossed over the Valley of the Zered was thirty-eight years, until all the generation of the men of war was consumed from the midst of the camp, just as the LORD had sworn to them.", 
                "num": 14
              }, 
              {
                "text": "For indeed the hand of the LORD was against them, to destroy them from the midst of the camp until they were consumed.", 
                "num": 15
              }, 
              {
                "text": "\"So it was, when all the men of war had finally perished from among the people,", 
                "num": 16
              }, 
              {
                "text": "that the LORD spoke to me, saying:", 
                "num": 17
              }, 
              {
                "text": "\"This day you are to cross over at Ar, the boundary of Moab.", 
                "num": 18
              }, 
              {
                "text": "And when you come near the people of Ammon, do not harass them or meddle with them, for I will not give you any of the land of the people of Ammon as a possession, because I have given it to the descendants of Lot as a possession.\"'", 
                "num": 19
              }, 
              {
                "text": "(That was also regarded as a land of giants; giants formerly dwelt there. But the Ammonites call them Zamzummim,", 
                "num": 20
              }, 
              {
                "text": "a people as great and numerous and tall as the Anakim. But the LORD destroyed them before them, and they dispossessed them and dwelt in their place,", 
                "num": 21
              }, 
              {
                "text": "just as He had done for the descendants of Esau, who dwelt in Seir, when He destroyed the Horites from before them. They dispossessed them and dwelt in their place, even to this day.", 
                "num": 22
              }, 
              {
                "text": "And the Avim, who dwelt in villages as far as Gaza--the Caphtorim, who came from Caphtor, destroyed them and dwelt in their place.)", 
                "num": 23
              }, 
              {
                "text": "\"\"Rise, take your journey, and cross over the River Arnon. Look, I have given into your hand Sihon the Amorite, king of Heshbon, and his land. Begin to possess it, and engage him in battle.", 
                "num": 24
              }, 
              {
                "text": "This day I will begin to put the dread and fear of you upon the nations under the whole heaven, who shall hear the report of you, and shall tremble and be in anguish because of you.'", 
                "num": 25
              }, 
              {
                "text": "\"And I sent messengers from the Wilderness of Kedemoth to Sihon king of Heshbon, with words of peace, saying,", 
                "num": 26
              }, 
              {
                "text": "\"Let me pass through your land; I will keep strictly to the road, and I will turn neither to the right nor to the left.", 
                "num": 27
              }, 
              {
                "text": "You shall sell me food for money, that I may eat, and give me water for money, that I may drink; only let me pass through on foot,", 
                "num": 28
              }, 
              {
                "text": "just as the descendants of Esau who dwell in Seir and the Moabites who dwell in Ar did for me, until I cross the Jordan to the land which the LORD our God is giving us.'", 
                "num": 29
              }, 
              {
                "text": "\"But Sihon king of Heshbon would not let us pass through, for the LORD your God hardened his spirit and made his heart obstinate, that He might deliver him into your hand, as it is this day.", 
                "num": 30
              }, 
              {
                "text": "\"And the LORD said to me, \"See, I have begun to give Sihon and his land over to you. Begin to possess it, that you may inherit his land.'", 
                "num": 31
              }, 
              {
                "text": "Then Sihon and all his people came out against us to fight at Jahaz.", 
                "num": 32
              }, 
              {
                "text": "And the LORD our God delivered him over to us; so we defeated him, his sons, and all his people.", 
                "num": 33
              }, 
              {
                "text": "We took all his cities at that time, and we utterly destroyed the men, women, and little ones of every city; we left none remaining.", 
                "num": 34
              }, 
              {
                "text": "We took only the livestock as plunder for ourselves, with the spoil of the cities which we took.", 
                "num": 35
              }, 
              {
                "text": "From Aroer, which is on the bank of the River Arnon, and from the city that is in the ravine, as far as Gilead, there was not one city too strong for us; the LORD our God delivered all to us.", 
                "num": 36
              }, 
              {
                "text": "Only you did not go near the land of the people of Ammon--anywhere along the River Jabbok, or to the cities of the mountains, or wherever the LORD our God had forbidden us.", 
                "num": 37
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"Then we turned and went up the road to Bashan; and Og king of Bashan came out against us, he and all his people, to battle at Edrei.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to me, \"Do not fear him, for I have delivered him and all his people and his land into your hand; you shall do to him as you did to Sihon king of the Amorites, who dwelt at Heshbon.'", 
                "num": 2
              }, 
              {
                "text": "\"So the LORD our God also delivered into our hands Og king of Bashan, with all his people, and we attacked him until he had no survivors remaining.", 
                "num": 3
              }, 
              {
                "text": "And we took all his cities at that time; there was not a city which we did not take from them: sixty cities, all the region of Argob, the kingdom of Og in Bashan.", 
                "num": 4
              }, 
              {
                "text": "All these cities were fortified with high walls, gates, and bars, besides a great many rural towns.", 
                "num": 5
              }, 
              {
                "text": "And we utterly destroyed them, as we did to Sihon king of Heshbon, utterly destroying the men, women, and children of every city.", 
                "num": 6
              }, 
              {
                "text": "But all the livestock and the spoil of the cities we took as booty for ourselves.", 
                "num": 7
              }, 
              {
                "text": "\"And at that time we took the land from the hand of the two kings of the Amorites who were on this side of the Jordan, from the River Arnon to Mount Hermon", 
                "num": 8
              }, 
              {
                "text": "(the Sidonians call Hermon Sirion, and the Amorites call it Senir),", 
                "num": 9
              }, 
              {
                "text": "all the cities of the plain, all Gilead, and all Bashan, as far as Salcah and Edrei, cities of the kingdom of Og in Bashan.", 
                "num": 10
              }, 
              {
                "text": "\"For only Og king of Bashan remained of the remnant of the giants. Indeed his bedstead was an iron bedstead. (Is it not in Rabbah of the people of Ammon?) Nine cubits is its length and four cubits its width, according to the standard cubit.", 
                "num": 11
              }, 
              {
                "text": "\"And this land, which we possessed at that time, from Aroer, which is by the River Arnon, and half the mountains of Gilead and its cities, I gave to the Reubenites and the Gadites.", 
                "num": 12
              }, 
              {
                "text": "The rest of Gilead, and all Bashan, the kingdom of Og, I gave to half the tribe of Manasseh. (All the region of Argob, with all Bashan, was called the land of the giants.", 
                "num": 13
              }, 
              {
                "text": "Jair the son of Manasseh took all the region of Argob, as far as the border of the Geshurites and the Maachathites, and called Bashan after his own name, Havoth Jair, to this day.)", 
                "num": 14
              }, 
              {
                "text": "\"Also I gave Gilead to Machir.", 
                "num": 15
              }, 
              {
                "text": "And to the Reubenites and the Gadites I gave from Gilead as far as the River Arnon, the middle of the river as the border, as far as the River Jabbok, the border of the people of Ammon;", 
                "num": 16
              }, 
              {
                "text": "the plain also, with the Jordan as the border, from Chinnereth as far as the east side of the Sea of the Arabah (the Salt Sea), below the slopes of Pisgah.", 
                "num": 17
              }, 
              {
                "text": "\"Then I commanded you at that time, saying: \"The LORD your God has given you this land to possess. All you men of valor shall cross over armed before your brethren, the children of Israel.", 
                "num": 18
              }, 
              {
                "text": "But your wives, your little ones, and your livestock (I know that you have much livestock) shall stay in your cities which I have given you,", 
                "num": 19
              }, 
              {
                "text": "until the LORD has given rest to your brethren as to you, and they also possess the land which the LORD your God is giving them beyond the Jordan. Then each of you may return to his possession which I have given you.'", 
                "num": 20
              }, 
              {
                "text": "\"And I commanded Joshua at that time, saying, \"Your eyes have seen all that the LORD your God has done to these two kings; so will the LORD do to all the kingdoms through which you pass.", 
                "num": 21
              }, 
              {
                "text": "You must not fear them, for the LORD your God Himself fights for you.'", 
                "num": 22
              }, 
              {
                "text": "\"Then I pleaded with the LORD at that time, saying:", 
                "num": 23
              }, 
              {
                "text": "\"O Lord GOD, You have begun to show Your servant Your greatness and Your mighty hand, for what god is there in heaven or on earth who can do anything like Your works and Your mighty deeds?", 
                "num": 24
              }, 
              {
                "text": "I pray, let me cross over and see the good land beyond the Jordan, those pleasant mountains, and Lebanon.'", 
                "num": 25
              }, 
              {
                "text": "\"But the LORD was angry with me on your account, and would not listen to me. So the LORD said to me: \"Enough of that! Speak no more to Me of this matter.", 
                "num": 26
              }, 
              {
                "text": "Go up to the top of Pisgah, and lift your eyes toward the west, the north, the south, and the east; behold it with your eyes, for you shall not cross over this Jordan.", 
                "num": 27
              }, 
              {
                "text": "But command Joshua, and encourage him and strengthen him; for he shall go over before this people, and he shall cause them to inherit the land which you will see.'", 
                "num": 28
              }, 
              {
                "text": "\"So we stayed in the valley opposite Beth Peor.", 
                "num": 29
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "\"Now, O Israel, listen to the statutes and the judgments which I teach you to observe, that you may live, and go in and possess the land which the LORD God of your fathers is giving you.", 
                "num": 1
              }, 
              {
                "text": "You shall not add to the word which I command you, nor take from it, that you may keep the commandments of the LORD your God which I command you.", 
                "num": 2
              }, 
              {
                "text": "Your eyes have seen what the LORD did at Baal Peor; for the LORD your God has destroyed from among you all the men who followed Baal of Peor.", 
                "num": 3
              }, 
              {
                "text": "But you who held fast to the LORD your God are alive today, every one of you.", 
                "num": 4
              }, 
              {
                "text": "\"Surely I have taught you statutes and judgments, just as the LORD my God commanded me, that you should act according to them in the land which you go to possess.", 
                "num": 5
              }, 
              {
                "text": "Therefore be careful to observe them; for this is your wisdom and your understanding in the sight of the peoples who will hear all these statutes, and say, \"Surely this great nation is a wise and understanding people.'", 
                "num": 6
              }, 
              {
                "text": "\"For what great nation is there that has God so near to it, as the LORD our God is to us, for whatever reason we may call upon Him?", 
                "num": 7
              }, 
              {
                "text": "And what great nation is there that has such statutes and righteous judgments as are in all this law which I set before you this day?", 
                "num": 8
              }, 
              {
                "text": "Only take heed to yourself, and diligently keep yourself, lest you forget the things your eyes have seen, and lest they depart from your heart all the days of your life. And teach them to your children and your grandchildren,", 
                "num": 9
              }, 
              {
                "text": "especially concerning the day you stood before the LORD your God in Horeb, when the LORD said to me, \"Gather the people to Me, and I will let them hear My words, that they may learn to fear Me all the days they live on the earth, and that they may teach their children.'", 
                "num": 10
              }, 
              {
                "text": "\"Then you came near and stood at the foot of the mountain, and the mountain burned with fire to the midst of heaven, with darkness, cloud, and thick darkness.", 
                "num": 11
              }, 
              {
                "text": "And the LORD spoke to you out of the midst of the fire. You heard the sound of the words, but saw no form; you only heard a voice.", 
                "num": 12
              }, 
              {
                "text": "So He declared to you His covenant which He commanded you to perform, the Ten Commandments; and He wrote them on two tablets of stone.", 
                "num": 13
              }, 
              {
                "text": "And the LORD commanded me at that time to teach you statutes and judgments, that you might observe them in the land which you cross over to possess.", 
                "num": 14
              }, 
              {
                "text": "\"Take careful heed to yourselves, for you saw no form when the LORD spoke to you at Horeb out of the midst of the fire,", 
                "num": 15
              }, 
              {
                "text": "lest you act corruptly and make for yourselves a carved image in the form of any figure: the likeness of male or female,", 
                "num": 16
              }, 
              {
                "text": "the likeness of any animal that is on the earth or the likeness of any winged bird that flies in the air,", 
                "num": 17
              }, 
              {
                "text": "the likeness of anything that creeps on the ground or the likeness of any fish that is in the water beneath the earth.", 
                "num": 18
              }, 
              {
                "text": "And take heed, lest you lift your eyes to heaven, and when you see the sun, the moon, and the stars, all the host of heaven, you feel driven to worship them and serve them, which the LORD your God has given to all the peoples under the whole heaven as a heritage.", 
                "num": 19
              }, 
              {
                "text": "But the LORD has taken you and brought you out of the iron furnace, out of Egypt, to be His people, an inheritance, as you are this day.", 
                "num": 20
              }, 
              {
                "text": "Furthermore the LORD was angry with me for your sakes, and swore that I would not cross over the Jordan, and that I would not enter the good land which the LORD your God is giving you as an inheritance.", 
                "num": 21
              }, 
              {
                "text": "But I must die in this land, I must not cross over the Jordan; but you shall cross over and possess that good land.", 
                "num": 22
              }, 
              {
                "text": "Take heed to yourselves, lest you forget the covenant of the LORD your God which He made with you, and make for yourselves a carved image in the form of anything which the LORD your God has forbidden you.", 
                "num": 23
              }, 
              {
                "text": "For the LORD your God is a consuming fire, a jealous God.", 
                "num": 24
              }, 
              {
                "text": "\"When you beget children and grandchildren and have grown old in the land, and act corruptly and make a carved image in the form of anything, and do evil in the sight of the LORD your God to provoke Him to anger,", 
                "num": 25
              }, 
              {
                "text": "I call heaven and earth to witness against you this day, that you will soon utterly perish from the land which you cross over the Jordan to possess; you will not prolong your days in it, but will be utterly destroyed.", 
                "num": 26
              }, 
              {
                "text": "And the LORD will scatter you among the peoples, and you will be left few in number among the nations where the LORD will drive you.", 
                "num": 27
              }, 
              {
                "text": "And there you will serve gods, the work of men's hands, wood and stone, which neither see nor hear nor eat nor smell.", 
                "num": 28
              }, 
              {
                "text": "But from there you will seek the LORD your God, and you will find Him if you seek Him with all your heart and with all your soul.", 
                "num": 29
              }, 
              {
                "text": "When you are in distress, and all these things come upon you in the latter days, when you turn to the LORD your God and obey His voice", 
                "num": 30
              }, 
              {
                "text": "(for the LORD your God is a merciful God), He will not forsake you nor destroy you, nor forget the covenant of your fathers which He swore to them.", 
                "num": 31
              }, 
              {
                "text": "\"For ask now concerning the days that are past, which were before you, since the day that God created man on the earth, and ask from one end of heaven to the other, whether any great thing like this has happened, or anything like it has been heard.", 
                "num": 32
              }, 
              {
                "text": "Did any people ever hear the voice of God speaking out of the midst of the fire, as you have heard, and live?", 
                "num": 33
              }, 
              {
                "text": "Or did God ever try to go and take for Himself a nation from the midst of another nation, by trials, by signs, by wonders, by war, by a mighty hand and an outstretched arm, and by great terrors, according to all that the LORD your God did for you in Egypt before your eyes?", 
                "num": 34
              }, 
              {
                "text": "To you it was shown, that you might know that the LORD Himself is God; there is none other besides Him.", 
                "num": 35
              }, 
              {
                "text": "Out of heaven He let you hear His voice, that He might instruct you; on earth He showed you His great fire, and you heard His words out of the midst of the fire.", 
                "num": 36
              }, 
              {
                "text": "And because He loved your fathers, therefore He chose their descendants after them; and He brought you out of Egypt with His Presence, with His mighty power,", 
                "num": 37
              }, 
              {
                "text": "driving out from before you nations greater and mightier than you, to bring you in, to give you their land as an inheritance, as it is this day.", 
                "num": 38
              }, 
              {
                "text": "Therefore know this day, and consider it in your heart, that the LORD Himself is God in heaven above and on the earth beneath; there is no other.", 
                "num": 39
              }, 
              {
                "text": "You shall therefore keep His statutes and His commandments which I command you today, that it may go well with you and with your children after you, and that you may prolong your days in the land which the LORD your God is giving you for all time.\"", 
                "num": 40
              }, 
              {
                "text": "Then Moses set apart three cities on this side of the Jordan, toward the rising of the sun,", 
                "num": 41
              }, 
              {
                "text": "that the manslayer might flee there, who kills his neighbor unintentionally, without having hated him in time past, and that by fleeing to one of these cities he might live:", 
                "num": 42
              }, 
              {
                "text": "Bezer in the wilderness on the plateau for the Reubenites, Ramoth in Gilead for the Gadites, and Golan in Bashan for the Manassites.", 
                "num": 43
              }, 
              {
                "text": "Now this is the law which Moses set before the children of Israel.", 
                "num": 44
              }, 
              {
                "text": "These are the testimonies, the statutes, and the judgments which Moses spoke to the children of Israel after they came out of Egypt,", 
                "num": 45
              }, 
              {
                "text": "on this side of the Jordan, in the valley opposite Beth Peor, in the land of Sihon king of the Amorites, who dwelt at Heshbon, whom Moses and the children of Israel defeated after they came out of Egypt.", 
                "num": 46
              }, 
              {
                "text": "And they took possession of his land and the land of Og king of Bashan, two kings of the Amorites, who were on this side of the Jordan, toward the rising of the sun,", 
                "num": 47
              }, 
              {
                "text": "from Aroer, which is on the bank of the River Arnon, even to Mount Sion (that is, Hermon),", 
                "num": 48
              }, 
              {
                "text": "and all the plain on the east side of the Jordan as far as the Sea of the Arabah, below the slopes of Pisgah.", 
                "num": 49
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "And Moses called all Israel, and said to them: \"Hear, O Israel, the statutes and judgments which I speak in your hearing today, that you may learn them and be careful to observe them.", 
                "num": 1
              }, 
              {
                "text": "The LORD our God made a covenant with us in Horeb.", 
                "num": 2
              }, 
              {
                "text": "The LORD did not make this covenant with our fathers, but with us, those who are here today, all of us who are alive.", 
                "num": 3
              }, 
              {
                "text": "The LORD talked with you face to face on the mountain from the midst of the fire.", 
                "num": 4
              }, 
              {
                "text": "I stood between the LORD and you at that time, to declare to you the word of the LORD; for you were afraid because of the fire, and you did not go up the mountain. He said:", 
                "num": 5
              }, 
              {
                "text": "\"I am the LORD your God who brought you out of the land of Egypt, out of the house of bondage.", 
                "num": 6
              }, 
              {
                "text": "\"You shall have no other gods before Me.", 
                "num": 7
              }, 
              {
                "text": "\"You shall not make for yourself a carved image--any likeness of anything that is in heaven above, or that is in the earth beneath, or that is in the water under the earth;", 
                "num": 8
              }, 
              {
                "text": "you shall not bow down to them nor serve them. For I, the LORD your God, am a jealous God, visiting the iniquity of the fathers upon the children to the third and fourth generations of those who hate Me,", 
                "num": 9
              }, 
              {
                "text": "but showing mercy to thousands, to those who love Me and keep My commandments.", 
                "num": 10
              }, 
              {
                "text": "\"You shall not take the name of the LORD your God in vain, for the LORD will not hold him guiltless who takes His name in vain.", 
                "num": 11
              }, 
              {
                "text": "\"Observe the Sabbath day, to keep it holy, as the LORD your God commanded you.", 
                "num": 12
              }, 
              {
                "text": "Six days you shall labor and do all your work,", 
                "num": 13
              }, 
              {
                "text": "but the seventh day is the Sabbath of the LORD your God. In it you shall do no work: you, nor your son, nor your daughter, nor your male servant, nor your female servant, nor your ox, nor your donkey, nor any of your cattle, nor your stranger who is within your gates, that your male servant and your female servant may rest as well as you.", 
                "num": 14
              }, 
              {
                "text": "And remember that you were a slave in the land of Egypt, and the LORD your God brought you out from there by a mighty hand and by an outstretched arm; therefore the LORD your God commanded you to keep the Sabbath day.", 
                "num": 15
              }, 
              {
                "text": "\"Honor your father and your mother, as the LORD your God has commanded you, that your days may be long, and that it may be well with you in the land which the LORD your God is giving you.", 
                "num": 16
              }, 
              {
                "text": "\"You shall not murder.", 
                "num": 17
              }, 
              {
                "text": "\"You shall not commit adultery.", 
                "num": 18
              }, 
              {
                "text": "\"You shall not steal.", 
                "num": 19
              }, 
              {
                "text": "\"You shall not bear false witness against your neighbor.", 
                "num": 20
              }, 
              {
                "text": "\"You shall not covet your neighbor's wife; and you shall not desire your neighbor's house, his field, his male servant, his female servant, his ox, his donkey, or anything that is your neighbor's.'", 
                "num": 21
              }, 
              {
                "text": "\"These words the LORD spoke to all your assembly, in the mountain from the midst of the fire, the cloud, and the thick darkness, with a loud voice; and He added no more. And He wrote them on two tablets of stone and gave them to me.", 
                "num": 22
              }, 
              {
                "text": "\"So it was, when you heard the voice from the midst of the darkness, while the mountain was burning with fire, that you came near to me, all the heads of your tribes and your elders.", 
                "num": 23
              }, 
              {
                "text": "And you said: \"Surely the LORD our God has shown us His glory and His greatness, and we have heard His voice from the midst of the fire. We have seen this day that God speaks with man; yet he still lives.", 
                "num": 24
              }, 
              {
                "text": "Now therefore, why should we die? For this great fire will consume us; if we hear the voice of the LORD our God anymore, then we shall die.", 
                "num": 25
              }, 
              {
                "text": "For who is there of all flesh who has heard the voice of the living God speaking from the midst of the fire, as we have, and lived?", 
                "num": 26
              }, 
              {
                "text": "You go near and hear all that the LORD our God may say, and tell us all that the LORD our God says to you, and we will hear and do it.'", 
                "num": 27
              }, 
              {
                "text": "\"Then the LORD heard the voice of your words when you spoke to me, and the LORD said to me: \"I have heard the voice of the words of this people which they have spoken to you. They are right in all that they have spoken.", 
                "num": 28
              }, 
              {
                "text": "Oh, that they had such a heart in them that they would fear Me and always keep all My commandments, that it might be well with them and with their children forever!", 
                "num": 29
              }, 
              {
                "text": "Go and say to them, \"Return to your tents.\"", 
                "num": 30
              }, 
              {
                "text": "But as for you, stand here by Me, and I will speak to you all the commandments, the statutes, and the judgments which you shall teach them, that they may observe them in the land which I am giving them to possess.'", 
                "num": 31
              }, 
              {
                "text": "\"Therefore you shall be careful to do as the LORD your God has commanded you; you shall not turn aside to the right hand or to the left.", 
                "num": 32
              }, 
              {
                "text": "You shall walk in all the ways which the LORD your God has commanded you, that you may live and that it may be well with you, and that you may prolong your days in the land which you shall possess.", 
                "num": 33
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "\"Now this is the commandment, and these are the statutes and judgments which the LORD your God has commanded to teach you, that you may observe them in the land which you are crossing over to possess,", 
                "num": 1
              }, 
              {
                "text": "that you may fear the LORD your God, to keep all His statutes and His commandments which I command you, you and your son and your grandson, all the days of your life, and that your days may be prolonged.", 
                "num": 2
              }, 
              {
                "text": "Therefore hear, O Israel, and be careful to observe it, that it may be well with you, and that you may multiply greatly as the LORD God of your fathers has promised you--\"a land flowing with milk and honey.'", 
                "num": 3
              }, 
              {
                "text": "\"Hear, O Israel: The LORD our God, the LORD is one!", 
                "num": 4
              }, 
              {
                "text": "You shall love the LORD your God with all your heart, with all your soul, and with all your strength.", 
                "num": 5
              }, 
              {
                "text": "\"And these words which I command you today shall be in your heart.", 
                "num": 6
              }, 
              {
                "text": "You shall teach them diligently to your children, and shall talk of them when you sit in your house, when you walk by the way, when you lie down, and when you rise up.", 
                "num": 7
              }, 
              {
                "text": "You shall bind them as a sign on your hand, and they shall be as frontlets between your eyes.", 
                "num": 8
              }, 
              {
                "text": "You shall write them on the doorposts of your house and on your gates.", 
                "num": 9
              }, 
              {
                "text": "\"So it shall be, when the LORD your God brings you into the land of which He swore to your fathers, to Abraham, Isaac, and Jacob, to give you large and beautiful cities which you did not build,", 
                "num": 10
              }, 
              {
                "text": "houses full of all good things, which you did not fill, hewn-out wells which you did not dig, vineyards and olive trees which you did not plant--when you have eaten and are full--", 
                "num": 11
              }, 
              {
                "text": "then beware, lest you forget the LORD who brought you out of the land of Egypt, from the house of bondage.", 
                "num": 12
              }, 
              {
                "text": "You shall fear the LORD your God and serve Him, and shall take oaths in His name.", 
                "num": 13
              }, 
              {
                "text": "You shall not go after other gods, the gods of the peoples who are all around you", 
                "num": 14
              }, 
              {
                "text": "(for the LORD your God is a jealous God among you), lest the anger of the LORD your God be aroused against you and destroy you from the face of the earth.", 
                "num": 15
              }, 
              {
                "text": "\"You shall not tempt the LORD your God as you tempted Him in Massah.", 
                "num": 16
              }, 
              {
                "text": "You shall diligently keep the commandments of the LORD your God, His testimonies, and His statutes which He has commanded you.", 
                "num": 17
              }, 
              {
                "text": "And you shall do what is right and good in the sight of the LORD, that it may be well with you, and that you may go in and possess the good land of which the LORD swore to your fathers,", 
                "num": 18
              }, 
              {
                "text": "to cast out all your enemies from before you, as the LORD has spoken.", 
                "num": 19
              }, 
              {
                "text": "\"When your son asks you in time to come, saying, \"What is the meaning of the testimonies, the statutes, and the judgments which the LORD our God has commanded you?'", 
                "num": 20
              }, 
              {
                "text": "then you shall say to your son: \"We were slaves of Pharaoh in Egypt, and the LORD brought us out of Egypt with a mighty hand;", 
                "num": 21
              }, 
              {
                "text": "and the LORD showed signs and wonders before our eyes, great and severe, against Egypt, Pharaoh, and all his household.", 
                "num": 22
              }, 
              {
                "text": "Then He brought us out from there, that He might bring us in, to give us the land of which He swore to our fathers.", 
                "num": 23
              }, 
              {
                "text": "And the LORD commanded us to observe all these statutes, to fear the LORD our God, for our good always, that He might preserve us alive, as it is this day.", 
                "num": 24
              }, 
              {
                "text": "Then it will be righteousness for us, if we are careful to observe all these commandments before the LORD our God, as He has commanded us.'", 
                "num": 25
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "\"When the LORD your God brings you into the land which you go to possess, and has cast out many nations before you, the Hittites and the Girgashites and the Amorites and the Canaanites and the Perizzites and the Hivites and the Jebusites, seven nations greater and mightier than you,", 
                "num": 1
              }, 
              {
                "text": "and when the LORD your God delivers them over to you, you shall conquer them and utterly destroy them. You shall make no covenant with them nor show mercy to them.", 
                "num": 2
              }, 
              {
                "text": "Nor shall you make marriages with them. You shall not give your daughter to their son, nor take their daughter for your son.", 
                "num": 3
              }, 
              {
                "text": "For they will turn your sons away from following Me, to serve other gods; so the anger of the LORD will be aroused against you and destroy you suddenly.", 
                "num": 4
              }, 
              {
                "text": "But thus you shall deal with them: you shall destroy their altars, and break down their sacred pillars, and cut down their wooden images, and burn their carved images with fire.", 
                "num": 5
              }, 
              {
                "text": "\"For you are a holy people to the LORD your God; the LORD your God has chosen you to be a people for Himself, a special treasure above all the peoples on the face of the earth.", 
                "num": 6
              }, 
              {
                "text": "The LORD did not set His love on you nor choose you because you were more in number than any other people, for you were the least of all peoples;", 
                "num": 7
              }, 
              {
                "text": "but because the LORD loves you, and because He would keep the oath which He swore to your fathers, the LORD has brought you out with a mighty hand, and redeemed you from the house of bondage, from the hand of Pharaoh king of Egypt.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore know that the LORD your God, He is God, the faithful God who keeps covenant and mercy for a thousand generations with those who love Him and keep His commandments;", 
                "num": 9
              }, 
              {
                "text": "and He repays those who hate Him to their face, to destroy them. He will not be slack with him who hates Him; He will repay him to his face.", 
                "num": 10
              }, 
              {
                "text": "Therefore you shall keep the commandment, the statutes, and the judgments which I command you today, to observe them.", 
                "num": 11
              }, 
              {
                "text": "\"Then it shall come to pass, because you listen to these judgments, and keep and do them, that the LORD your God will keep with you the covenant and the mercy which He swore to your fathers.", 
                "num": 12
              }, 
              {
                "text": "And He will love you and bless you and multiply you; He will also bless the fruit of your womb and the fruit of your land, your grain and your new wine and your oil, the increase of your cattle and the offspring of your flock, in the land of which He swore to your fathers to give you.", 
                "num": 13
              }, 
              {
                "text": "You shall be blessed above all peoples; there shall not be a male or female barren among you or among your livestock.", 
                "num": 14
              }, 
              {
                "text": "And the LORD will take away from you all sickness, and will afflict you with none of the terrible diseases of Egypt which you have known, but will lay them on all those who hate you.", 
                "num": 15
              }, 
              {
                "text": "Also you shall destroy all the peoples whom the LORD your God delivers over to you; your eye shall have no pity on them; nor shall you serve their gods, for that will be a snare to you.", 
                "num": 16
              }, 
              {
                "text": "\"If you should say in your heart, \"These nations are greater than I; how can I dispossess them?'--", 
                "num": 17
              }, 
              {
                "text": "you shall not be afraid of them, but you shall remember well what the LORD your God did to Pharaoh and to all Egypt:", 
                "num": 18
              }, 
              {
                "text": "the great trials which your eyes saw, the signs and the wonders, the mighty hand and the outstretched arm, by which the LORD your God brought you out. So shall the LORD your God do to all the peoples of whom you are afraid.", 
                "num": 19
              }, 
              {
                "text": "Moreover the LORD your God will send the hornet among them until those who are left, who hide themselves from you, are destroyed.", 
                "num": 20
              }, 
              {
                "text": "You shall not be terrified of them; for the LORD your God, the great and awesome God, is among you.", 
                "num": 21
              }, 
              {
                "text": "And the LORD your God will drive out those nations before you little by little; you will be unable to destroy them at once, lest the beasts of the field become too numerous for you.", 
                "num": 22
              }, 
              {
                "text": "But the LORD your God will deliver them over to you, and will inflict defeat upon them until they are destroyed.", 
                "num": 23
              }, 
              {
                "text": "And He will deliver their kings into your hand, and you will destroy their name from under heaven; no one shall be able to stand against you until you have destroyed them.", 
                "num": 24
              }, 
              {
                "text": "You shall burn the carved images of their gods with fire; you shall not covet the silver or gold that is on them, nor take it for yourselves, lest you be snared by it; for it is an abomination to the LORD your God.", 
                "num": 25
              }, 
              {
                "text": "Nor shall you bring an abomination into your house, lest you be doomed to destruction like it. You shall utterly detest it and utterly abhor it, for it is an accursed thing.", 
                "num": 26
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "\"Every commandment which I command you today you must be careful to observe, that you may live and multiply, and go in and possess the land of which the LORD swore to your fathers.", 
                "num": 1
              }, 
              {
                "text": "And you shall remember that the LORD your God led you all the way these forty years in the wilderness, to humble you and test you, to know what was in your heart, whether you would keep His commandments or not.", 
                "num": 2
              }, 
              {
                "text": "So He humbled you, allowed you to hunger, and fed you with manna which you did not know nor did your fathers know, that He might make you know that man shall not live by bread alone; but man lives by every word that proceeds from the mouth of the LORD.", 
                "num": 3
              }, 
              {
                "text": "Your garments did not wear out on you, nor did your foot swell these forty years.", 
                "num": 4
              }, 
              {
                "text": "You should know in your heart that as a man chastens his son, so the LORD your God chastens you.", 
                "num": 5
              }, 
              {
                "text": "\"Therefore you shall keep the commandments of the LORD your God, to walk in His ways and to fear Him.", 
                "num": 6
              }, 
              {
                "text": "For the LORD your God is bringing you into a good land, a land of brooks of water, of fountains and springs, that flow out of valleys and hills;", 
                "num": 7
              }, 
              {
                "text": "a land of wheat and barley, of vines and fig trees and pomegranates, a land of olive oil and honey;", 
                "num": 8
              }, 
              {
                "text": "a land in which you will eat bread without scarcity, in which you will lack nothing; a land whose stones are iron and out of whose hills you can dig copper.", 
                "num": 9
              }, 
              {
                "text": "When you have eaten and are full, then you shall bless the LORD your God for the good land which He has given you.", 
                "num": 10
              }, 
              {
                "text": "\"Beware that you do not forget the LORD your God by not keeping His commandments, His judgments, and His statutes which I command you today,", 
                "num": 11
              }, 
              {
                "text": "lest--when you have eaten and are full, and have built beautiful houses and dwell in them;", 
                "num": 12
              }, 
              {
                "text": "and when your herds and your flocks multiply, and your silver and your gold are multiplied, and all that you have is multiplied;", 
                "num": 13
              }, 
              {
                "text": "when your heart is lifted up, and you forget the LORD your God who brought you out of the land of Egypt, from the house of bondage;", 
                "num": 14
              }, 
              {
                "text": "who led you through that great and terrible wilderness, in which were fiery serpents and scorpions and thirsty land where there was no water; who brought water for you out of the flinty rock;", 
                "num": 15
              }, 
              {
                "text": "who fed you in the wilderness with manna, which your fathers did not know, that He might humble you and that He might test you, to do you good in the end--", 
                "num": 16
              }, 
              {
                "text": "then you say in your heart, \"My power and the might of my hand have gained me this wealth.'", 
                "num": 17
              }, 
              {
                "text": "\"And you shall remember the LORD your God, for it is He who gives you power to get wealth, that He may establish His covenant which He swore to your fathers, as it is this day.", 
                "num": 18
              }, 
              {
                "text": "Then it shall be, if you by any means forget the LORD your God, and follow other gods, and serve them and worship them, I testify against you this day that you shall surely perish.", 
                "num": 19
              }, 
              {
                "text": "As the nations which the LORD destroys before you, so you shall perish, because you would not be obedient to the voice of the LORD your God.", 
                "num": 20
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "\"Hear, O Israel: You are to cross over the Jordan today, and go in to dispossess nations greater and mightier than yourself, cities great and fortified up to heaven,", 
                "num": 1
              }, 
              {
                "text": "a people great and tall, the descendants of the Anakim, whom you know, and of whom you heard it said, \"Who can stand before the descendants of Anak?'", 
                "num": 2
              }, 
              {
                "text": "Therefore understand today that the LORD your God is He who goes over before you as a consuming fire. He will destroy them and bring them down before you; so you shall drive them out and destroy them quickly, as the LORD has said to you.", 
                "num": 3
              }, 
              {
                "text": "\"Do not think in your heart, after the LORD your God has cast them out before you, saying, \"Because of my righteousness the LORD has brought me in to possess this land'; but it is because of the wickedness of these nations that the LORD is driving them out from before you.", 
                "num": 4
              }, 
              {
                "text": "It is not because of your righteousness or the uprightness of your heart that you go in to possess their land, but because of the wickedness of these nations that the LORD your God drives them out from before you, and that He may fulfill the word which the LORD swore to your fathers, to Abraham, Isaac, and Jacob.", 
                "num": 5
              }, 
              {
                "text": "Therefore understand that the LORD your God is not giving you this good land to possess because of your righteousness, for you are a stiff-necked people.", 
                "num": 6
              }, 
              {
                "text": "\"Remember! Do not forget how you provoked the LORD your God to wrath in the wilderness. From the day that you departed from the land of Egypt until you came to this place, you have been rebellious against the LORD.", 
                "num": 7
              }, 
              {
                "text": "Also in Horeb you provoked the LORD to wrath, so that the LORD was angry enough with you to have destroyed you.", 
                "num": 8
              }, 
              {
                "text": "When I went up into the mountain to receive the tablets of stone, the tablets of the covenant which the LORD made with you, then I stayed on the mountain forty days and forty nights. I neither ate bread nor drank water.", 
                "num": 9
              }, 
              {
                "text": "Then the LORD delivered to me two tablets of stone written with the finger of God, and on them were all the words which the LORD had spoken to you on the mountain from the midst of the fire in the day of the assembly.", 
                "num": 10
              }, 
              {
                "text": "And it came to pass, at the end of forty days and forty nights, that the LORD gave me the two tablets of stone, the tablets of the covenant.", 
                "num": 11
              }, 
              {
                "text": "\"Then the LORD said to me, \"Arise, go down quickly from here, for your people whom you brought out of Egypt have acted corruptly; they have quickly turned aside from the way which I commanded them; they have made themselves a molded image.'", 
                "num": 12
              }, 
              {
                "text": "\"Furthermore the LORD spoke to me, saying, \"I have seen this people, and indeed they are a stiff-necked people.", 
                "num": 13
              }, 
              {
                "text": "Let Me alone, that I may destroy them and blot out their name from under heaven; and I will make of you a nation mightier and greater than they.'", 
                "num": 14
              }, 
              {
                "text": "\"So I turned and came down from the mountain, and the mountain burned with fire; and the two tablets of the covenant were in my two hands.", 
                "num": 15
              }, 
              {
                "text": "And I looked, and behold, you had sinned against the LORD your God--had made for yourselves a molded calf! You had turned aside quickly from the way which the LORD had commanded you.", 
                "num": 16
              }, 
              {
                "text": "Then I took the two tablets and threw them out of my two hands and broke them before your eyes.", 
                "num": 17
              }, 
              {
                "text": "And I fell down before the LORD, as at the first, forty days and forty nights; I neither ate bread nor drank water, because of all your sin which you committed in doing wickedly in the sight of the LORD, to provoke Him to anger.", 
                "num": 18
              }, 
              {
                "text": "For I was afraid of the anger and hot displeasure with which the LORD was angry with you, to destroy you. But the LORD listened to me at that time also.", 
                "num": 19
              }, 
              {
                "text": "And the LORD was very angry with Aaron and would have destroyed him; so I prayed for Aaron also at the same time.", 
                "num": 20
              }, 
              {
                "text": "Then I took your sin, the calf which you had made, and burned it with fire and crushed it and ground it very small, until it was as fine as dust; and I threw its dust into the brook that descended from the mountain.", 
                "num": 21
              }, 
              {
                "text": "\"Also at Taberah and Massah and Kibroth Hattaavah you provoked the LORD to wrath.", 
                "num": 22
              }, 
              {
                "text": "Likewise, when the LORD sent you from Kadesh Barnea, saying, \"Go up and possess the land which I have given you,' then you rebelled against the commandment of the LORD your God, and you did not believe Him nor obey His voice.", 
                "num": 23
              }, 
              {
                "text": "You have been rebellious against the LORD from the day that I knew you.", 
                "num": 24
              }, 
              {
                "text": "\"Thus I prostrated myself before the LORD; forty days and forty nights I kept prostrating myself, because the LORD had said He would destroy you.", 
                "num": 25
              }, 
              {
                "text": "Therefore I prayed to the LORD, and said: \"O Lord GOD, do not destroy Your people and Your inheritance whom You have redeemed through Your greatness, whom You have brought out of Egypt with a mighty hand.", 
                "num": 26
              }, 
              {
                "text": "Remember Your servants, Abraham, Isaac, and Jacob; do not look on the stubbornness of this people, or on their wickedness or their sin,", 
                "num": 27
              }, 
              {
                "text": "lest the land from which You brought us should say, \"Because the LORD was not able to bring them to the land which He promised them, and because He hated them, He has brought them out to kill them in the wilderness.\"", 
                "num": 28
              }, 
              {
                "text": "Yet they are Your people and Your inheritance, whom You brought out by Your mighty power and by Your outstretched arm.'", 
                "num": 29
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "\"At that time the LORD said to me, \"Hew for yourself two tablets of stone like the first, and come up to Me on the mountain and make yourself an ark of wood.", 
                "num": 1
              }, 
              {
                "text": "And I will write on the tablets the words that were on the first tablets, which you broke; and you shall put them in the ark.'", 
                "num": 2
              }, 
              {
                "text": "\"So I made an ark of acacia wood, hewed two tablets of stone like the first, and went up the mountain, having the two tablets in my hand.", 
                "num": 3
              }, 
              {
                "text": "And He wrote on the tablets according to the first writing, the Ten Commandments, which the LORD had spoken to you in the mountain from the midst of the fire in the day of the assembly; and the LORD gave them to me.", 
                "num": 4
              }, 
              {
                "text": "Then I turned and came down from the mountain, and put the tablets in the ark which I had made; and there they are, just as the LORD commanded me.\"", 
                "num": 5
              }, 
              {
                "text": "(Now the children of Israel journeyed from the wells of Bene Jaakan to Moserah, where Aaron died, and where he was buried; and Eleazar his son ministered as priest in his stead.", 
                "num": 6
              }, 
              {
                "text": "From there they journeyed to Gudgodah, and from Gudgodah to Jotbathah, a land of rivers of water.", 
                "num": 7
              }, 
              {
                "text": "At that time the LORD separated the tribe of Levi to bear the ark of the covenant of the LORD, to stand before the LORD to minister to Him and to bless in His name, to this day.", 
                "num": 8
              }, 
              {
                "text": "Therefore Levi has no portion nor inheritance with his brethren; the LORD is his inheritance, just as the LORD your God promised him.)", 
                "num": 9
              }, 
              {
                "text": "\"As at the first time, I stayed in the mountain forty days and forty nights; the LORD also heard me at that time, and the LORD chose not to destroy you.", 
                "num": 10
              }, 
              {
                "text": "Then the LORD said to me, \"Arise, begin your journey before the people, that they may go in and possess the land which I swore to their fathers to give them.'", 
                "num": 11
              }, 
              {
                "text": "\"And now, Israel, what does the LORD your God require of you, but to fear the LORD your God, to walk in all His ways and to love Him, to serve the LORD your God with all your heart and with all your soul,", 
                "num": 12
              }, 
              {
                "text": "and to keep the commandments of the LORD and His statutes which I command you today for your good?", 
                "num": 13
              }, 
              {
                "text": "Indeed heaven and the highest heavens belong to the LORD your God, also the earth with all that is in it.", 
                "num": 14
              }, 
              {
                "text": "The LORD delighted only in your fathers, to love them; and He chose their descendants after them, you above all peoples, as it is this day.", 
                "num": 15
              }, 
              {
                "text": "Therefore circumcise the foreskin of your heart, and be stiff-necked no longer.", 
                "num": 16
              }, 
              {
                "text": "For the LORD your God is God of gods and Lord of lords, the great God, mighty and awesome, who shows no partiality nor takes a bribe.", 
                "num": 17
              }, 
              {
                "text": "He administers justice for the fatherless and the widow, and loves the stranger, giving him food and clothing.", 
                "num": 18
              }, 
              {
                "text": "Therefore love the stranger, for you were strangers in the land of Egypt.", 
                "num": 19
              }, 
              {
                "text": "You shall fear the LORD your God; you shall serve Him, and to Him you shall hold fast, and take oaths in His name.", 
                "num": 20
              }, 
              {
                "text": "He is your praise, and He is your God, who has done for you these great and awesome things which your eyes have seen.", 
                "num": 21
              }, 
              {
                "text": "Your fathers went down to Egypt with seventy persons, and now the LORD your God has made you as the stars of heaven in multitude.", 
                "num": 22
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "\"Therefore you shall love the LORD your God, and keep His charge, His statutes, His judgments, and His commandments always.", 
                "num": 1
              }, 
              {
                "text": "Know today that I do not speak with your children, who have not known and who have not seen the chastening of the LORD your God, His greatness and His mighty hand and His outstretched arm--", 
                "num": 2
              }, 
              {
                "text": "His signs and His acts which He did in the midst of Egypt, to Pharaoh king of Egypt, and to all his land;", 
                "num": 3
              }, 
              {
                "text": "what He did to the army of Egypt, to their horses and their chariots: how He made the waters of the Red Sea overflow them as they pursued you, and how the LORD has destroyed them to this day;", 
                "num": 4
              }, 
              {
                "text": "what He did for you in the wilderness until you came to this place;", 
                "num": 5
              }, 
              {
                "text": "and what He did to Dathan and Abiram the sons of Eliab, the son of Reuben: how the earth opened its mouth and swallowed them up, their households, their tents, and all the substance that was in their possession, in the midst of all Israel--", 
                "num": 6
              }, 
              {
                "text": "but your eyes have seen every great act of the LORD which He did.", 
                "num": 7
              }, 
              {
                "text": "\"Therefore you shall keep every commandment which I command you today, that you may be strong, and go in and possess the land which you cross over to possess,", 
                "num": 8
              }, 
              {
                "text": "and that you may prolong your days in the land which the LORD swore to give your fathers, to them and their descendants, \"a land flowing with milk and honey.'", 
                "num": 9
              }, 
              {
                "text": "For the land which you go to possess is not like the land of Egypt from which you have come, where you sowed your seed and watered it by foot, as a vegetable garden;", 
                "num": 10
              }, 
              {
                "text": "but the land which you cross over to possess is a land of hills and valleys, which drinks water from the rain of heaven,", 
                "num": 11
              }, 
              {
                "text": "a land for which the LORD your God cares; the eyes of the LORD your God are always on it, from the beginning of the year to the very end of the year.", 
                "num": 12
              }, 
              {
                "text": "\"And it shall be that if you earnestly obey My commandments which I command you today, to love the LORD your God and serve Him with all your heart and with all your soul,", 
                "num": 13
              }, 
              {
                "text": "then I will give you the rain for your land in its season, the early rain and the latter rain, that you may gather in your grain, your new wine, and your oil.", 
                "num": 14
              }, 
              {
                "text": "And I will send grass in your fields for your livestock, that you may eat and be filled.'", 
                "num": 15
              }, 
              {
                "text": "Take heed to yourselves, lest your heart be deceived, and you turn aside and serve other gods and worship them,", 
                "num": 16
              }, 
              {
                "text": "lest the LORD's anger be aroused against you, and He shut up the heavens so that there be no rain, and the land yield no produce, and you perish quickly from the good land which the LORD is giving you.", 
                "num": 17
              }, 
              {
                "text": "\"Therefore you shall lay up these words of mine in your heart and in your soul, and bind them as a sign on your hand, and they shall be as frontlets between your eyes.", 
                "num": 18
              }, 
              {
                "text": "You shall teach them to your children, speaking of them when you sit in your house, when you walk by the way, when you lie down, and when you rise up.", 
                "num": 19
              }, 
              {
                "text": "And you shall write them on the doorposts of your house and on your gates,", 
                "num": 20
              }, 
              {
                "text": "that your days and the days of your children may be multiplied in the land of which the LORD swore to your fathers to give them, like the days of the heavens above the earth.", 
                "num": 21
              }, 
              {
                "text": "\"For if you carefully keep all these commandments which I command you to do--to love the LORD your God, to walk in all His ways, and to hold fast to Him--", 
                "num": 22
              }, 
              {
                "text": "then the LORD will drive out all these nations from before you, and you will dispossess greater and mightier nations than yourselves.", 
                "num": 23
              }, 
              {
                "text": "Every place on which the sole of your foot treads shall be yours: from the wilderness and Lebanon, from the river, the River Euphrates, even to the Western Sea, shall be your territory.", 
                "num": 24
              }, 
              {
                "text": "No man shall be able to stand against you; the LORD your God will put the dread of you and the fear of you upon all the land where you tread, just as He has said to you.", 
                "num": 25
              }, 
              {
                "text": "\"Behold, I set before you today a blessing and a curse:", 
                "num": 26
              }, 
              {
                "text": "the blessing, if you obey the commandments of the LORD your God which I command you today;", 
                "num": 27
              }, 
              {
                "text": "and the curse, if you do not obey the commandments of the LORD your God, but turn aside from the way which I command you today, to go after other gods which you have not known.", 
                "num": 28
              }, 
              {
                "text": "Now it shall be, when the LORD your God has brought you into the land which you go to possess, that you shall put the blessing on Mount Gerizim and the curse on Mount Ebal.", 
                "num": 29
              }, 
              {
                "text": "Are they not on the other side of the Jordan, toward the setting sun, in the land of the Canaanites who dwell in the plain opposite Gilgal, beside the terebinth trees of Moreh?", 
                "num": 30
              }, 
              {
                "text": "For you will cross over the Jordan and go in to possess the land which the LORD your God is giving you, and you will possess it and dwell in it.", 
                "num": 31
              }, 
              {
                "text": "And you shall be careful to observe all the statutes and judgments which I set before you today.", 
                "num": 32
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "\"These are the statutes and judgments which you shall be careful to observe in the land which the LORD God of your fathers is giving you to possess, all the days that you live on the earth.", 
                "num": 1
              }, 
              {
                "text": "You shall utterly destroy all the places where the nations which you shall dispossess served their gods, on the high mountains and on the hills and under every green tree.", 
                "num": 2
              }, 
              {
                "text": "And you shall destroy their altars, break their sacred pillars, and burn their wooden images with fire; you shall cut down the carved images of their gods and destroy their names from that place.", 
                "num": 3
              }, 
              {
                "text": "You shall not worship the LORD your God with such things.", 
                "num": 4
              }, 
              {
                "text": "\"But you shall seek the place where the LORD your God chooses, out of all your tribes, to put His name for His dwelling place; and there you shall go.", 
                "num": 5
              }, 
              {
                "text": "There you shall take your burnt offerings, your sacrifices, your tithes, the heave offerings of your hand, your vowed offerings, your freewill offerings, and the firstborn of your herds and flocks.", 
                "num": 6
              }, 
              {
                "text": "And there you shall eat before the LORD your God, and you shall rejoice in all to which you have put your hand, you and your households, in which the LORD your God has blessed you.", 
                "num": 7
              }, 
              {
                "text": "\"You shall not at all do as we are doing here today--every man doing whatever is right in his own eyes--", 
                "num": 8
              }, 
              {
                "text": "for as yet you have not come to the rest and the inheritance which the LORD your God is giving you.", 
                "num": 9
              }, 
              {
                "text": "But when you cross over the Jordan and dwell in the land which the LORD your God is giving you to inherit, and He gives you rest from all your enemies round about, so that you dwell in safety,", 
                "num": 10
              }, 
              {
                "text": "then there will be the place where the LORD your God chooses to make His name abide. There you shall bring all that I command you: your burnt offerings, your sacrifices, your tithes, the heave offerings of your hand, and all your choice offerings which you vow to the LORD.", 
                "num": 11
              }, 
              {
                "text": "And you shall rejoice before the LORD your God, you and your sons and your daughters, your male and female servants, and the Levite who is within your gates, since he has no portion nor inheritance with you.", 
                "num": 12
              }, 
              {
                "text": "Take heed to yourself that you do not offer your burnt offerings in every place that you see;", 
                "num": 13
              }, 
              {
                "text": "but in the place which the LORD chooses, in one of your tribes, there you shall offer your burnt offerings, and there you shall do all that I command you.", 
                "num": 14
              }, 
              {
                "text": "\"However, you may slaughter and eat meat within all your gates, whatever your heart desires, according to the blessing of the LORD your God which He has given you; the unclean and the clean may eat of it, of the gazelle and the deer alike.", 
                "num": 15
              }, 
              {
                "text": "Only you shall not eat the blood; you shall pour it on the earth like water.", 
                "num": 16
              }, 
              {
                "text": "You may not eat within your gates the tithe of your grain or your new wine or your oil, of the firstborn of your herd or your flock, of any of your offerings which you vow, of your freewill offerings, or of the heave offering of your hand.", 
                "num": 17
              }, 
              {
                "text": "But you must eat them before the LORD your God in the place which the LORD your God chooses, you and your son and your daughter, your male servant and your female servant, and the Levite who is within your gates; and you shall rejoice before the LORD your God in all to which you put your hands.", 
                "num": 18
              }, 
              {
                "text": "Take heed to yourself that you do not forsake the Levite as long as you live in your land.", 
                "num": 19
              }, 
              {
                "text": "\"When the LORD your God enlarges your border as He has promised you, and you say, \"Let me eat meat,' because you long to eat meat, you may eat as much meat as your heart desires.", 
                "num": 20
              }, 
              {
                "text": "If the place where the LORD your God chooses to put His name is too far from you, then you may slaughter from your herd and from your flock which the LORD has given you, just as I have commanded you, and you may eat within your gates as much as your heart desires.", 
                "num": 21
              }, 
              {
                "text": "Just as the gazelle and the deer are eaten, so you may eat them; the unclean and the clean alike may eat them.", 
                "num": 22
              }, 
              {
                "text": "Only be sure that you do not eat the blood, for the blood is the life; you may not eat the life with the meat.", 
                "num": 23
              }, 
              {
                "text": "You shall not eat it; you shall pour it on the earth like water.", 
                "num": 24
              }, 
              {
                "text": "You shall not eat it, that it may go well with you and your children after you, when you do what is right in the sight of the LORD.", 
                "num": 25
              }, 
              {
                "text": "Only the holy things which you have, and your vowed offerings, you shall take and go to the place which the LORD chooses.", 
                "num": 26
              }, 
              {
                "text": "And you shall offer your burnt offerings, the meat and the blood, on the altar of the LORD your God; and the blood of your sacrifices shall be poured out on the altar of the LORD your God, and you shall eat the meat.", 
                "num": 27
              }, 
              {
                "text": "Observe and obey all these words which I command you, that it may go well with you and your children after you forever, when you do what is good and right in the sight of the LORD your God.", 
                "num": 28
              }, 
              {
                "text": "\"When the LORD your God cuts off from before you the nations which you go to dispossess, and you displace them and dwell in their land,", 
                "num": 29
              }, 
              {
                "text": "take heed to yourself that you are not ensnared to follow them, after they are destroyed from before you, and that you do not inquire after their gods, saying, \"How did these nations serve their gods? I also will do likewise.'", 
                "num": 30
              }, 
              {
                "text": "You shall not worship the LORD your God in that way; for every abomination to the LORD which He hates they have done to their gods; for they burn even their sons and daughters in the fire to their gods.", 
                "num": 31
              }, 
              {
                "text": "\"Whatever I command you, be careful to observe it; you shall not add to it nor take away from it.", 
                "num": 32
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "\"If there arises among you a prophet or a dreamer of dreams, and he gives you a sign or a wonder,", 
                "num": 1
              }, 
              {
                "text": "and the sign or the wonder comes to pass, of which he spoke to you, saying, \"Let us go after other gods'--which you have not known--\"and let us serve them,'", 
                "num": 2
              }, 
              {
                "text": "you shall not listen to the words of that prophet or that dreamer of dreams, for the LORD your God is testing you to know whether you love the LORD your God with all your heart and with all your soul.", 
                "num": 3
              }, 
              {
                "text": "You shall walk after the LORD your God and fear Him, and keep His commandments and obey His voice; you shall serve Him and hold fast to Him.", 
                "num": 4
              }, 
              {
                "text": "But that prophet or that dreamer of dreams shall be put to death, because he has spoken in order to turn you away from the LORD your God, who brought you out of the land of Egypt and redeemed you from the house of bondage, to entice you from the way in which the LORD your God commanded you to walk. So you shall put away the evil from your midst.", 
                "num": 5
              }, 
              {
                "text": "\"If your brother, the son of your mother, your son or your daughter, the wife of your bosom, or your friend who is as your own soul, secretly entices you, saying, \"Let us go and serve other gods,' which you have not known, neither you nor your fathers,", 
                "num": 6
              }, 
              {
                "text": "of the gods of the people which are all around you, near to you or far off from you, from one end of the earth to the other end of the earth,", 
                "num": 7
              }, 
              {
                "text": "you shall not consent to him or listen to him, nor shall your eye pity him, nor shall you spare him or conceal him;", 
                "num": 8
              }, 
              {
                "text": "but you shall surely kill him; your hand shall be first against him to put him to death, and afterward the hand of all the people.", 
                "num": 9
              }, 
              {
                "text": "And you shall stone him with stones until he dies, because he sought to entice you away from the LORD your God, who brought you out of the land of Egypt, from the house of bondage.", 
                "num": 10
              }, 
              {
                "text": "So all Israel shall hear and fear, and not again do such wickedness as this among you.", 
                "num": 11
              }, 
              {
                "text": "\"If you hear someone in one of your cities, which the LORD your God gives you to dwell in, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Corrupt men have gone out from among you and enticed the inhabitants of their city, saying, \"Let us go and serve other gods\"'--which you have not known--", 
                "num": 13
              }, 
              {
                "text": "then you shall inquire, search out, and ask diligently. And if it is indeed true and certain that such an abomination was committed among you,", 
                "num": 14
              }, 
              {
                "text": "you shall surely strike the inhabitants of that city with the edge of the sword, utterly destroying it, all that is in it and its livestock--with the edge of the sword.", 
                "num": 15
              }, 
              {
                "text": "And you shall gather all its plunder into the middle of the street, and completely burn with fire the city and all its plunder, for the LORD your God. It shall be a heap forever; it shall not be built again.", 
                "num": 16
              }, 
              {
                "text": "So none of the accursed things shall remain in your hand, that the LORD may turn from the fierceness of His anger and show you mercy, have compassion on you and multiply you, just as He swore to your fathers,", 
                "num": 17
              }, 
              {
                "text": "because you have listened to the voice of the LORD your God, to keep all His commandments which I command you today, to do what is right in the eyes of the LORD your God.", 
                "num": 18
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "\"You are the children of the LORD your God; you shall not cut yourselves nor shave the front of your head for the dead.", 
                "num": 1
              }, 
              {
                "text": "For you are a holy people to the LORD your God, and the LORD has chosen you to be a people for Himself, a special treasure above all the peoples who are on the face of the earth.", 
                "num": 2
              }, 
              {
                "text": "\"You shall not eat any detestable thing.", 
                "num": 3
              }, 
              {
                "text": "These are the animals which you may eat: the ox, the sheep, the goat,", 
                "num": 4
              }, 
              {
                "text": "the deer, the gazelle, the roe deer, the wild goat, the mountain goat, the antelope, and the mountain sheep.", 
                "num": 5
              }, 
              {
                "text": "And you may eat every animal with cloven hooves, having the hoof split into two parts, and that chews the cud, among the animals.", 
                "num": 6
              }, 
              {
                "text": "Nevertheless, of those that chew the cud or have cloven hooves, you shall not eat, such as these: the camel, the hare, and the rock hyrax; for they chew the cud but do not have cloven hooves; they are unclean for you.", 
                "num": 7
              }, 
              {
                "text": "Also the swine is unclean for you, because it has cloven hooves, yet does not chew the cud; you shall not eat their flesh or touch their dead carcasses.", 
                "num": 8
              }, 
              {
                "text": "\"These you may eat of all that are in the waters: you may eat all that have fins and scales.", 
                "num": 9
              }, 
              {
                "text": "And whatever does not have fins and scales you shall not eat; it is unclean for you.", 
                "num": 10
              }, 
              {
                "text": "\"All clean birds you may eat.", 
                "num": 11
              }, 
              {
                "text": "But these you shall not eat: the eagle, the vulture, the buzzard,", 
                "num": 12
              }, 
              {
                "text": "the red kite, the falcon, and the kite after their kinds;", 
                "num": 13
              }, 
              {
                "text": "every raven after its kind;", 
                "num": 14
              }, 
              {
                "text": "the ostrich, the short-eared owl, the sea gull, and the hawk after their kinds;", 
                "num": 15
              }, 
              {
                "text": "the little owl, the screech owl, the white owl,", 
                "num": 16
              }, 
              {
                "text": "the jackdaw, the carrion vulture, the fisher owl,", 
                "num": 17
              }, 
              {
                "text": "the stork, the heron after its kind, and the hoopoe and the bat.", 
                "num": 18
              }, 
              {
                "text": "\"Also every creeping thing that flies is unclean for you; they shall not be eaten.", 
                "num": 19
              }, 
              {
                "text": "\"You may eat all clean birds.", 
                "num": 20
              }, 
              {
                "text": "\"You shall not eat anything that dies of itself; you may give it to the alien who is within your gates, that he may eat it, or you may sell it to a foreigner; for you are a holy people to the LORD your God. \"You shall not boil a young goat in its mother's milk.", 
                "num": 21
              }, 
              {
                "text": "\"You shall truly tithe all the increase of your grain that the field produces year by year.", 
                "num": 22
              }, 
              {
                "text": "And you shall eat before the LORD your God, in the place where He chooses to make His name abide, the tithe of your grain and your new wine and your oil, of the firstborn of your herds and your flocks, that you may learn to fear the LORD your God always.", 
                "num": 23
              }, 
              {
                "text": "But if the journey is too long for you, so that you are not able to carry the tithe, or if the place where the LORD your God chooses to put His name is too far from you, when the LORD your God has blessed you,", 
                "num": 24
              }, 
              {
                "text": "then you shall exchange it for money, take the money in your hand, and go to the place which the LORD your God chooses.", 
                "num": 25
              }, 
              {
                "text": "And you shall spend that money for whatever your heart desires: for oxen or sheep, for wine or similar drink, for whatever your heart desires; you shall eat there before the LORD your God, and you shall rejoice, you and your household.", 
                "num": 26
              }, 
              {
                "text": "You shall not forsake the Levite who is within your gates, for he has no part nor inheritance with you.", 
                "num": 27
              }, 
              {
                "text": "\"At the end of every third year you shall bring out the tithe of your produce of that year and store it up within your gates.", 
                "num": 28
              }, 
              {
                "text": "And the Levite, because he has no portion nor inheritance with you, and the stranger and the fatherless and the widow who are within your gates, may come and eat and be satisfied, that the LORD your God may bless you in all the work of your hand which you do.", 
                "num": 29
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "\"At the end of every seven years you shall grant a release of debts.", 
                "num": 1
              }, 
              {
                "text": "And this is the form of the release: Every creditor who has lent anything to his neighbor shall release it; he shall not require it of his neighbor or his brother, because it is called the LORD's release.", 
                "num": 2
              }, 
              {
                "text": "Of a foreigner you may require it; but you shall give up your claim to what is owed by your brother,", 
                "num": 3
              }, 
              {
                "text": "except when there may be no poor among you; for the LORD will greatly bless you in the land which the LORD your God is giving you to possess as an inheritance--", 
                "num": 4
              }, 
              {
                "text": "only if you carefully obey the voice of the LORD your God, to observe with care all these commandments which I command you today.", 
                "num": 5
              }, 
              {
                "text": "For the LORD your God will bless you just as He promised you; you shall lend to many nations, but you shall not borrow; you shall reign over many nations, but they shall not reign over you.", 
                "num": 6
              }, 
              {
                "text": "\"If there is among you a poor man of your brethren, within any of the gates in your land which the LORD your God is giving you, you shall not harden your heart nor shut your hand from your poor brother,", 
                "num": 7
              }, 
              {
                "text": "but you shall open your hand wide to him and willingly lend him sufficient for his need, whatever he needs.", 
                "num": 8
              }, 
              {
                "text": "Beware lest there be a wicked thought in your heart, saying, \"The seventh year, the year of release, is at hand,' and your eye be evil against your poor brother and you give him nothing, and he cry out to the LORD against you, and it become sin among you.", 
                "num": 9
              }, 
              {
                "text": "You shall surely give to him, and your heart should not be grieved when you give to him, because for this thing the LORD your God will bless you in all your works and in all to which you put your hand.", 
                "num": 10
              }, 
              {
                "text": "For the poor will never cease from the land; therefore I command you, saying, \"You shall open your hand wide to your brother, to your poor and your needy, in your land.'", 
                "num": 11
              }, 
              {
                "text": "\"If your brother, a Hebrew man, or a Hebrew woman, is sold to you and serves you six years, then in the seventh year you shall let him go free from you.", 
                "num": 12
              }, 
              {
                "text": "And when you send him away free from you, you shall not let him go away empty-handed;", 
                "num": 13
              }, 
              {
                "text": "you shall supply him liberally from your flock, from your threshing floor, and from your winepress. From what the LORD has blessed you with, you shall give to him.", 
                "num": 14
              }, 
              {
                "text": "You shall remember that you were a slave in the land of Egypt, and the LORD your God redeemed you; therefore I command you this thing today.", 
                "num": 15
              }, 
              {
                "text": "And if it happens that he says to you, \"I will not go away from you,' because he loves you and your house, since he prospers with you,", 
                "num": 16
              }, 
              {
                "text": "then you shall take an awl and thrust it through his ear to the door, and he shall be your servant forever. Also to your female servant you shall do likewise.", 
                "num": 17
              }, 
              {
                "text": "It shall not seem hard to you when you send him away free from you; for he has been worth a double hired servant in serving you six years. Then the LORD your God will bless you in all that you do.", 
                "num": 18
              }, 
              {
                "text": "\"All the firstborn males that come from your herd and your flock you shall sanctify to the LORD your God; you shall do no work with the firstborn of your herd, nor shear the firstborn of your flock.", 
                "num": 19
              }, 
              {
                "text": "You and your household shall eat it before the LORD your God year by year in the place which the LORD chooses.", 
                "num": 20
              }, 
              {
                "text": "But if there is a defect in it, if it is lame or blind or has any serious defect, you shall not sacrifice it to the LORD your God.", 
                "num": 21
              }, 
              {
                "text": "You may eat it within your gates; the unclean and the clean person alike may eat it, as if it were a gazelle or a deer.", 
                "num": 22
              }, 
              {
                "text": "Only you shall not eat its blood; you shall pour it on the ground like water.", 
                "num": 23
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "\"Observe the month of Abib, and keep the Passover to the LORD your God, for in the month of Abib the LORD your God brought you out of Egypt by night.", 
                "num": 1
              }, 
              {
                "text": "Therefore you shall sacrifice the Passover to the LORD your God, from the flock and the herd, in the place where the LORD chooses to put His name.", 
                "num": 2
              }, 
              {
                "text": "You shall eat no leavened bread with it; seven days you shall eat unleavened bread with it, that is, the bread of affliction (for you came out of the land of Egypt in haste), that you may remember the day in which you came out of the land of Egypt all the days of your life.", 
                "num": 3
              }, 
              {
                "text": "And no leaven shall be seen among you in all your territory for seven days, nor shall any of the meat which you sacrifice the first day at twilight remain overnight until morning.", 
                "num": 4
              }, 
              {
                "text": "\"You may not sacrifice the Passover within any of your gates which the LORD your God gives you;", 
                "num": 5
              }, 
              {
                "text": "but at the place where the LORD your God chooses to make His name abide, there you shall sacrifice the Passover at twilight, at the going down of the sun, at the time you came out of Egypt.", 
                "num": 6
              }, 
              {
                "text": "And you shall roast and eat it in the place which the LORD your God chooses, and in the morning you shall turn and go to your tents.", 
                "num": 7
              }, 
              {
                "text": "Six days you shall eat unleavened bread, and on the seventh day there shall be a sacred assembly to the LORD your God. You shall do no work on it.", 
                "num": 8
              }, 
              {
                "text": "\"You shall count seven weeks for yourself; begin to count the seven weeks from the time you begin to put the sickle to the grain.", 
                "num": 9
              }, 
              {
                "text": "Then you shall keep the Feast of Weeks to the LORD your God with the tribute of a freewill offering from your hand, which you shall give as the LORD your God blesses you.", 
                "num": 10
              }, 
              {
                "text": "You shall rejoice before the LORD your God, you and your son and your daughter, your male servant and your female servant, the Levite who is within your gates, the stranger and the fatherless and the widow who are among you, at the place where the LORD your God chooses to make His name abide.", 
                "num": 11
              }, 
              {
                "text": "And you shall remember that you were a slave in Egypt, and you shall be careful to observe these statutes.", 
                "num": 12
              }, 
              {
                "text": "\"You shall observe the Feast of Tabernacles seven days, when you have gathered from your threshing floor and from your winepress.", 
                "num": 13
              }, 
              {
                "text": "And you shall rejoice in your feast, you and your son and your daughter, your male servant and your female servant and the Levite, the stranger and the fatherless and the widow, who are within your gates.", 
                "num": 14
              }, 
              {
                "text": "Seven days you shall keep a sacred feast to the LORD your God in the place which the LORD chooses, because the LORD your God will bless you in all your produce and in all the work of your hands, so that you surely rejoice.", 
                "num": 15
              }, 
              {
                "text": "\"Three times a year all your males shall appear before the LORD your God in the place which He chooses: at the Feast of Unleavened Bread, at the Feast of Weeks, and at the Feast of Tabernacles; and they shall not appear before the LORD empty-handed.", 
                "num": 16
              }, 
              {
                "text": "Every man shall give as he is able, according to the blessing of the LORD your God which He has given you.", 
                "num": 17
              }, 
              {
                "text": "\"You shall appoint judges and officers in all your gates, which the LORD your God gives you, according to your tribes, and they shall judge the people with just judgment.", 
                "num": 18
              }, 
              {
                "text": "You shall not pervert justice; you shall not show partiality, nor take a bribe, for a bribe blinds the eyes of the wise and twists the words of the righteous.", 
                "num": 19
              }, 
              {
                "text": "You shall follow what is altogether just, that you may live and inherit the land which the LORD your God is giving you.", 
                "num": 20
              }, 
              {
                "text": "\"You shall not plant for yourself any tree, as a wooden image, near the altar which you build for yourself to the LORD your God.", 
                "num": 21
              }, 
              {
                "text": "You shall not set up a sacred pillar, which the LORD your God hates.", 
                "num": 22
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "\"You shall not sacrifice to the LORD your God a bull or sheep which has any blemish or defect, for that is an abomination to the LORD your God.", 
                "num": 1
              }, 
              {
                "text": "\"If there is found among you, within any of your gates which the LORD your God gives you, a man or a woman who has been wicked in the sight of the LORD your God, in transgressing His covenant,", 
                "num": 2
              }, 
              {
                "text": "who has gone and served other gods and worshiped them, either the sun or moon or any of the host of heaven, which I have not commanded,", 
                "num": 3
              }, 
              {
                "text": "and it is told you, and you hear of it, then you shall inquire diligently. And if it is indeed true and certain that such an abomination has been committed in Israel,", 
                "num": 4
              }, 
              {
                "text": "then you shall bring out to your gates that man or woman who has committed that wicked thing, and shall stone to death that man or woman with stones.", 
                "num": 5
              }, 
              {
                "text": "Whoever is deserving of death shall be put to death on the testimony of two or three witnesses; he shall not be put to death on the testimony of one witness.", 
                "num": 6
              }, 
              {
                "text": "The hands of the witnesses shall be the first against him to put him to death, and afterward the hands of all the people. So you shall put away the evil from among you.", 
                "num": 7
              }, 
              {
                "text": "\"If a matter arises which is too hard for you to judge, between degrees of guilt for bloodshed, between one judgment or another, or between one punishment or another, matters of controversy within your gates, then you shall arise and go up to the place which the LORD your God chooses.", 
                "num": 8
              }, 
              {
                "text": "And you shall come to the priests, the Levites, and to the judge there in those days, and inquire of them; they shall pronounce upon you the sentence of judgment.", 
                "num": 9
              }, 
              {
                "text": "You shall do according to the sentence which they pronounce upon you in that place which the LORD chooses. And you shall be careful to do according to all that they order you.", 
                "num": 10
              }, 
              {
                "text": "According to the sentence of the law in which they instruct you, according to the judgment which they tell you, you shall do; you shall not turn aside to the right hand or to the left from the sentence which they pronounce upon you.", 
                "num": 11
              }, 
              {
                "text": "Now the man who acts presumptuously and will not heed the priest who stands to minister there before the LORD your God, or the judge, that man shall die. So you shall put away the evil from Israel.", 
                "num": 12
              }, 
              {
                "text": "And all the people shall hear and fear, and no longer act presumptuously.", 
                "num": 13
              }, 
              {
                "text": "\"When you come to the land which the LORD your God is giving you, and possess it and dwell in it, and say, \"I will set a king over me like all the nations that are around me,'", 
                "num": 14
              }, 
              {
                "text": "you shall surely set a king over you whom the LORD your God chooses; one from among your brethren you shall set as king over you; you may not set a foreigner over you, who is not your brother.", 
                "num": 15
              }, 
              {
                "text": "But he shall not multiply horses for himself, nor cause the people to return to Egypt to multiply horses, for the LORD has said to you, \"You shall not return that way again.'", 
                "num": 16
              }, 
              {
                "text": "Neither shall he multiply wives for himself, lest his heart turn away; nor shall he greatly multiply silver and gold for himself.", 
                "num": 17
              }, 
              {
                "text": "\"Also it shall be, when he sits on the throne of his kingdom, that he shall write for himself a copy of this law in a book, from the one before the priests, the Levites.", 
                "num": 18
              }, 
              {
                "text": "And it shall be with him, and he shall read it all the days of his life, that he may learn to fear the LORD his God and be careful to observe all the words of this law and these statutes,", 
                "num": 19
              }, 
              {
                "text": "that his heart may not be lifted above his brethren, that he may not turn aside from the commandment to the right hand or to the left, and that he may prolong his days in his kingdom, he and his children in the midst of Israel.", 
                "num": 20
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "\"The priests, the Levites--all the tribe of Levi--shall have no part nor inheritance with Israel; they shall eat the offerings of the LORD made by fire, and His portion.", 
                "num": 1
              }, 
              {
                "text": "Therefore they shall have no inheritance among their brethren; the LORD is their inheritance, as He said to them.", 
                "num": 2
              }, 
              {
                "text": "\"And this shall be the priest's due from the people, from those who offer a sacrifice, whether it is bull or sheep: they shall give to the priest the shoulder, the cheeks, and the stomach.", 
                "num": 3
              }, 
              {
                "text": "The firstfruits of your grain and your new wine and your oil, and the first of the fleece of your sheep, you shall give him.", 
                "num": 4
              }, 
              {
                "text": "For the LORD your God has chosen him out of all your tribes to stand to minister in the name of the LORD, him and his sons forever.", 
                "num": 5
              }, 
              {
                "text": "\"So if a Levite comes from any of your gates, from where he dwells among all Israel, and comes with all the desire of his mind to the place which the LORD chooses,", 
                "num": 6
              }, 
              {
                "text": "then he may serve in the name of the LORD his God as all his brethren the Levites do, who stand there before the LORD.", 
                "num": 7
              }, 
              {
                "text": "They shall have equal portions to eat, besides what comes from the sale of his inheritance.", 
                "num": 8
              }, 
              {
                "text": "\"When you come into the land which the LORD your God is giving you, you shall not learn to follow the abominations of those nations.", 
                "num": 9
              }, 
              {
                "text": "There shall not be found among you anyone who makes his son or his daughter pass through the fire, or one who practices witchcraft, or a soothsayer, or one who interprets omens, or a sorcerer,", 
                "num": 10
              }, 
              {
                "text": "or one who conjures spells, or a medium, or a spiritist, or one who calls up the dead.", 
                "num": 11
              }, 
              {
                "text": "For all who do these things are an abomination to the LORD, and because of these abominations the LORD your God drives them out from before you.", 
                "num": 12
              }, 
              {
                "text": "You shall be blameless before the LORD your God.", 
                "num": 13
              }, 
              {
                "text": "For these nations which you will dispossess listened to soothsayers and diviners; but as for you, the LORD your God has not appointed such for you.", 
                "num": 14
              }, 
              {
                "text": "\"The LORD your God will raise up for you a Prophet like me from your midst, from your brethren. Him you shall hear,", 
                "num": 15
              }, 
              {
                "text": "according to all you desired of the LORD your God in Horeb in the day of the assembly, saying, \"Let me not hear again the voice of the LORD my God, nor let me see this great fire anymore, lest I die.'", 
                "num": 16
              }, 
              {
                "text": "\"And the LORD said to me: \"What they have spoken is good.", 
                "num": 17
              }, 
              {
                "text": "I will raise up for them a Prophet like you from among their brethren, and will put My words in His mouth, and He shall speak to them all that I command Him.", 
                "num": 18
              }, 
              {
                "text": "And it shall be that whoever will not hear My words, which He speaks in My name, I will require it of him.", 
                "num": 19
              }, 
              {
                "text": "But the prophet who presumes to speak a word in My name, which I have not commanded him to speak, or who speaks in the name of other gods, that prophet shall die.'", 
                "num": 20
              }, 
              {
                "text": "And if you say in your heart, \"How shall we know the word which the LORD has not spoken?'--", 
                "num": 21
              }, 
              {
                "text": "when a prophet speaks in the name of the LORD, if the thing does not happen or come to pass, that is the thing which the LORD has not spoken; the prophet has spoken it presumptuously; you shall not be afraid of him.", 
                "num": 22
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "\"When the LORD your God has cut off the nations whose land the LORD your God is giving you, and you dispossess them and dwell in their cities and in their houses,", 
                "num": 1
              }, 
              {
                "text": "you shall separate three cities for yourself in the midst of your land which the LORD your God is giving you to possess.", 
                "num": 2
              }, 
              {
                "text": "You shall prepare roads for yourself, and divide into three parts the territory of your land which the LORD your God is giving you to inherit, that any manslayer may flee there.", 
                "num": 3
              }, 
              {
                "text": "\"And this is the case of the manslayer who flees there, that he may live: Whoever kills his neighbor unintentionally, not having hated him in time past--", 
                "num": 4
              }, 
              {
                "text": "as when a man goes to the woods with his neighbor to cut timber, and his hand swings a stroke with the ax to cut down the tree, and the head slips from the handle and strikes his neighbor so that he dies--he shall flee to one of these cities and live;", 
                "num": 5
              }, 
              {
                "text": "lest the avenger of blood, while his anger is hot, pursue the manslayer and overtake him, because the way is long, and kill him, though he was not deserving of death, since he had not hated the victim in time past.", 
                "num": 6
              }, 
              {
                "text": "Therefore I command you, saying, \"You shall separate three cities for yourself.'", 
                "num": 7
              }, 
              {
                "text": "\"Now if the LORD your God enlarges your territory, as He swore to your fathers, and gives you the land which He promised to give to your fathers,", 
                "num": 8
              }, 
              {
                "text": "and if you keep all these commandments and do them, which I command you today, to love the LORD your God and to walk always in His ways, then you shall add three more cities for yourself besides these three,", 
                "num": 9
              }, 
              {
                "text": "lest innocent blood be shed in the midst of your land which the LORD your God is giving you as an inheritance, and thus guilt of bloodshed be upon you.", 
                "num": 10
              }, 
              {
                "text": "\"But if anyone hates his neighbor, lies in wait for him, rises against him and strikes him mortally, so that he dies, and he flees to one of these cities,", 
                "num": 11
              }, 
              {
                "text": "then the elders of his city shall send and bring him from there, and deliver him over to the hand of the avenger of blood, that he may die.", 
                "num": 12
              }, 
              {
                "text": "Your eye shall not pity him, but you shall put away the guilt of innocent blood from Israel, that it may go well with you.", 
                "num": 13
              }, 
              {
                "text": "\"You shall not remove your neighbor's landmark, which the men of old have set, in your inheritance which you will inherit in the land that the LORD your God is giving you to possess.", 
                "num": 14
              }, 
              {
                "text": "\"One witness shall not rise against a man concerning any iniquity or any sin that he commits; by the mouth of two or three witnesses the matter shall be established.", 
                "num": 15
              }, 
              {
                "text": "If a false witness rises against any man to testify against him of wrongdoing,", 
                "num": 16
              }, 
              {
                "text": "then both men in the controversy shall stand before the LORD, before the priests and the judges who serve in those days.", 
                "num": 17
              }, 
              {
                "text": "And the judges shall make careful inquiry, and indeed, if the witness is a false witness, who has testified falsely against his brother,", 
                "num": 18
              }, 
              {
                "text": "then you shall do to him as he thought to have done to his brother; so you shall put away the evil from among you.", 
                "num": 19
              }, 
              {
                "text": "And those who remain shall hear and fear, and hereafter they shall not again commit such evil among you.", 
                "num": 20
              }, 
              {
                "text": "Your eye shall not pity: life shall be for life, eye for eye, tooth for tooth, hand for hand, foot for foot.", 
                "num": 21
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "\"When you go out to battle against your enemies, and see horses and chariots and people more numerous than you, do not be afraid of them; for the LORD your God is with you, who brought you up from the land of Egypt.", 
                "num": 1
              }, 
              {
                "text": "So it shall be, when you are on the verge of battle, that the priest shall approach and speak to the people.", 
                "num": 2
              }, 
              {
                "text": "And he shall say to them, \"Hear, O Israel: Today you are on the verge of battle with your enemies. Do not let your heart faint, do not be afraid, and do not tremble or be terrified because of them;", 
                "num": 3
              }, 
              {
                "text": "for the LORD your God is He who goes with you, to fight for you against your enemies, to save you.'", 
                "num": 4
              }, 
              {
                "text": "\"Then the officers shall speak to the people, saying: \"What man is there who has built a new house and has not dedicated it? Let him go and return to his house, lest he die in the battle and another man dedicate it.", 
                "num": 5
              }, 
              {
                "text": "Also what man is there who has planted a vineyard and has not eaten of it? Let him go and return to his house, lest he die in the battle and another man eat of it.", 
                "num": 6
              }, 
              {
                "text": "And what man is there who is betrothed to a woman and has not married her? Let him go and return to his house, lest he die in the battle and another man marry her.'", 
                "num": 7
              }, 
              {
                "text": "\"The officers shall speak further to the people, and say, \"What man is there who is fearful and fainthearted? Let him go and return to his house, lest the heart of his brethren faint like his heart.'", 
                "num": 8
              }, 
              {
                "text": "And so it shall be, when the officers have finished speaking to the people, that they shall make captains of the armies to lead the people.", 
                "num": 9
              }, 
              {
                "text": "\"When you go near a city to fight against it, then proclaim an offer of peace to it.", 
                "num": 10
              }, 
              {
                "text": "And it shall be that if they accept your offer of peace, and open to you, then all the people who are found in it shall be placed under tribute to you, and serve you.", 
                "num": 11
              }, 
              {
                "text": "Now if the city will not make peace with you, but war against you, then you shall besiege it.", 
                "num": 12
              }, 
              {
                "text": "And when the LORD your God delivers it into your hands, you shall strike every male in it with the edge of the sword.", 
                "num": 13
              }, 
              {
                "text": "But the women, the little ones, the livestock, and all that is in the city, all its spoil, you shall plunder for yourself; and you shall eat the enemies' plunder which the LORD your God gives you.", 
                "num": 14
              }, 
              {
                "text": "Thus you shall do to all the cities which are very far from you, which are not of the cities of these nations.", 
                "num": 15
              }, 
              {
                "text": "\"But of the cities of these peoples which the LORD your God gives you as an inheritance, you shall let nothing that breathes remain alive,", 
                "num": 16
              }, 
              {
                "text": "but you shall utterly destroy them: the Hittite and the Amorite and the Canaanite and the Perizzite and the Hivite and the Jebusite, just as the LORD your God has commanded you,", 
                "num": 17
              }, 
              {
                "text": "lest they teach you to do according to all their abominations which they have done for their gods, and you sin against the LORD your God.", 
                "num": 18
              }, 
              {
                "text": "\"When you besiege a city for a long time, while making war against it to take it, you shall not destroy its trees by wielding an ax against them; if you can eat of them, do not cut them down to use in the siege, for the tree of the field is man's food.", 
                "num": 19
              }, 
              {
                "text": "Only the trees which you know are not trees for food you may destroy and cut down, to build siegeworks against the city that makes war with you, until it is subdued.", 
                "num": 20
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "\"If anyone is found slain, lying in the field in the land which the LORD your God is giving you to possess, and it is not known who killed him,", 
                "num": 1
              }, 
              {
                "text": "then your elders and your judges shall go out and measure the distance from the slain man to the surrounding cities.", 
                "num": 2
              }, 
              {
                "text": "And it shall be that the elders of the city nearest to the slain man will take a heifer which has not been worked and which has not pulled with a yoke.", 
                "num": 3
              }, 
              {
                "text": "The elders of that city shall bring the heifer down to a valley with flowing water, which is neither plowed nor sown, and they shall break the heifer's neck there in the valley.", 
                "num": 4
              }, 
              {
                "text": "Then the priests, the sons of Levi, shall come near, for the LORD your God has chosen them to minister to Him and to bless in the name of the LORD; by their word every controversy and every assault shall be settled.", 
                "num": 5
              }, 
              {
                "text": "And all the elders of that city nearest to the slain man shall wash their hands over the heifer whose neck was broken in the valley.", 
                "num": 6
              }, 
              {
                "text": "Then they shall answer and say, \"Our hands have not shed this blood, nor have our eyes seen it.", 
                "num": 7
              }, 
              {
                "text": "Provide atonement, O LORD, for Your people Israel, whom You have redeemed, and do not lay innocent blood to the charge of Your people Israel.' And atonement shall be provided on their behalf for the blood.", 
                "num": 8
              }, 
              {
                "text": "So you shall put away the guilt of innocent blood from among you when you do what is right in the sight of the LORD.", 
                "num": 9
              }, 
              {
                "text": "\"When you go out to war against your enemies, and the LORD your God delivers them into your hand, and you take them captive,", 
                "num": 10
              }, 
              {
                "text": "and you see among the captives a beautiful woman, and desire her and would take her for your wife,", 
                "num": 11
              }, 
              {
                "text": "then you shall bring her home to your house, and she shall shave her head and trim her nails.", 
                "num": 12
              }, 
              {
                "text": "She shall put off the clothes of her captivity, remain in your house, and mourn her father and her mother a full month; after that you may go in to her and be her husband, and she shall be your wife.", 
                "num": 13
              }, 
              {
                "text": "And it shall be, if you have no delight in her, then you shall set her free, but you certainly shall not sell her for money; you shall not treat her brutally, because you have humbled her.", 
                "num": 14
              }, 
              {
                "text": "\"If a man has two wives, one loved and the other unloved, and they have borne him children, both the loved and the unloved, and if the firstborn son is of her who is unloved,", 
                "num": 15
              }, 
              {
                "text": "then it shall be, on the day he bequeaths his possessions to his sons, that he must not bestow firstborn status on the son of the loved wife in preference to the son of the unloved, the true firstborn.", 
                "num": 16
              }, 
              {
                "text": "But he shall acknowledge the son of the unloved wife as the firstborn by giving him a double portion of all that he has, for he is the beginning of his strength; the right of the firstborn is his.", 
                "num": 17
              }, 
              {
                "text": "\"If a man has a stubborn and rebellious son who will not obey the voice of his father or the voice of his mother, and who, when they have chastened him, will not heed them,", 
                "num": 18
              }, 
              {
                "text": "then his father and his mother shall take hold of him and bring him out to the elders of his city, to the gate of his city.", 
                "num": 19
              }, 
              {
                "text": "And they shall say to the elders of his city, \"This son of ours is stubborn and rebellious; he will not obey our voice; he is a glutton and a drunkard.'", 
                "num": 20
              }, 
              {
                "text": "Then all the men of his city shall stone him to death with stones; so you shall put away the evil from among you, and all Israel shall hear and fear.", 
                "num": 21
              }, 
              {
                "text": "\"If a man has committed a sin deserving of death, and he is put to death, and you hang him on a tree,", 
                "num": 22
              }, 
              {
                "text": "his body shall not remain overnight on the tree, but you shall surely bury him that day, so that you do not defile the land which the LORD your God is giving you as an inheritance; for he who is hanged is accursed of God.", 
                "num": 23
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "\"You shall not see your brother's ox or his sheep going astray, and hide yourself from them; you shall certainly bring them back to your brother.", 
                "num": 1
              }, 
              {
                "text": "And if your brother is not near you, or if you do not know him, then you shall bring it to your own house, and it shall remain with you until your brother seeks it; then you shall restore it to him.", 
                "num": 2
              }, 
              {
                "text": "You shall do the same with his donkey, and so shall you do with his garment; with any lost thing of your brother's, which he has lost and you have found, you shall do likewise; you must not hide yourself.", 
                "num": 3
              }, 
              {
                "text": "\"You shall not see your brother's donkey or his ox fall down along the road, and hide yourself from them; you shall surely help him lift them up again.", 
                "num": 4
              }, 
              {
                "text": "\"A woman shall not wear anything that pertains to a man, nor shall a man put on a woman's garment, for all who do so are an abomination to the LORD your God.", 
                "num": 5
              }, 
              {
                "text": "\"If a bird's nest happens to be before you along the way, in any tree or on the ground, with young ones or eggs, with the mother sitting on the young or on the eggs, you shall not take the mother with the young;", 
                "num": 6
              }, 
              {
                "text": "you shall surely let the mother go, and take the young for yourself, that it may be well with you and that you may prolong your days.", 
                "num": 7
              }, 
              {
                "text": "\"When you build a new house, then you shall make a parapet for your roof, that you may not bring guilt of bloodshed on your household if anyone falls from it.", 
                "num": 8
              }, 
              {
                "text": "\"You shall not sow your vineyard with different kinds of seed, lest the yield of the seed which you have sown and the fruit of your vineyard be defiled.", 
                "num": 9
              }, 
              {
                "text": "\"You shall not plow with an ox and a donkey together.", 
                "num": 10
              }, 
              {
                "text": "\"You shall not wear a garment of different sorts, such as wool and linen mixed together.", 
                "num": 11
              }, 
              {
                "text": "\"You shall make tassels on the four corners of the clothing with which you cover yourself.", 
                "num": 12
              }, 
              {
                "text": "\"If any man takes a wife, and goes in to her, and detests her,", 
                "num": 13
              }, 
              {
                "text": "and charges her with shameful conduct, and brings a bad name on her, and says, \"I took this woman, and when I came to her I found she was not a virgin,'", 
                "num": 14
              }, 
              {
                "text": "then the father and mother of the young woman shall take and bring out the evidence of the young woman's virginity to the elders of the city at the gate.", 
                "num": 15
              }, 
              {
                "text": "And the young woman's father shall say to the elders, \"I gave my daughter to this man as wife, and he detests her.", 
                "num": 16
              }, 
              {
                "text": "Now he has charged her with shameful conduct, saying, \"I found your daughter was not a virgin,\" and yet these are the evidences of my daughter's virginity.' And they shall spread the cloth before the elders of the city.", 
                "num": 17
              }, 
              {
                "text": "Then the elders of that city shall take that man and punish him;", 
                "num": 18
              }, 
              {
                "text": "and they shall fine him one hundred shekels of silver and give them to the father of the young woman, because he has brought a bad name on a virgin of Israel. And she shall be his wife; he cannot divorce her all his days.", 
                "num": 19
              }, 
              {
                "text": "\"But if the thing is true, and evidences of virginity are not found for the young woman,", 
                "num": 20
              }, 
              {
                "text": "then they shall bring out the young woman to the door of her father's house, and the men of her city shall stone her to death with stones, because she has done a disgraceful thing in Israel, to play the harlot in her father's house. So you shall put away the evil from among you.", 
                "num": 21
              }, 
              {
                "text": "\"If a man is found lying with a woman married to a husband, then both of them shall die--the man that lay with the woman, and the woman; so you shall put away the evil from Israel.", 
                "num": 22
              }, 
              {
                "text": "\"If a young woman who is a virgin is betrothed to a husband, and a man finds her in the city and lies with her,", 
                "num": 23
              }, 
              {
                "text": "then you shall bring them both out to the gate of that city, and you shall stone them to death with stones, the young woman because she did not cry out in the city, and the man because he humbled his neighbor's wife; so you shall put away the evil from among you.", 
                "num": 24
              }, 
              {
                "text": "\"But if a man finds a betrothed young woman in the countryside, and the man forces her and lies with her, then only the man who lay with her shall die.", 
                "num": 25
              }, 
              {
                "text": "But you shall do nothing to the young woman; there is in the young woman no sin deserving of death, for just as when a man rises against his neighbor and kills him, even so is this matter.", 
                "num": 26
              }, 
              {
                "text": "For he found her in the countryside, and the betrothed young woman cried out, but there was no one to save her.", 
                "num": 27
              }, 
              {
                "text": "\"If a man finds a young woman who is a virgin, who is not betrothed, and he seizes her and lies with her, and they are found out,", 
                "num": 28
              }, 
              {
                "text": "then the man who lay with her shall give to the young woman's father fifty shekels of silver, and she shall be his wife because he has humbled her; he shall not be permitted to divorce her all his days.", 
                "num": 29
              }, 
              {
                "text": "\"A man shall not take his father's wife, nor uncover his father's bed.", 
                "num": 30
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "\"He who is emasculated by crushing or mutilation shall not enter the assembly of the LORD.", 
                "num": 1
              }, 
              {
                "text": "\"One of illegitimate birth shall not enter the assembly of the LORD; even to the tenth generation none of his descendants shall enter the assembly of the LORD.", 
                "num": 2
              }, 
              {
                "text": "\"An Ammonite or Moabite shall not enter the assembly of the LORD; even to the tenth generation none of his descendants shall enter the assembly of the LORD forever,", 
                "num": 3
              }, 
              {
                "text": "because they did not meet you with bread and water on the road when you came out of Egypt, and because they hired against you Balaam the son of Beor from Pethor of Mesopotamia, to curse you.", 
                "num": 4
              }, 
              {
                "text": "Nevertheless the LORD your God would not listen to Balaam, but the LORD your God turned the curse into a blessing for you, because the LORD your God loves you.", 
                "num": 5
              }, 
              {
                "text": "You shall not seek their peace nor their prosperity all your days forever.", 
                "num": 6
              }, 
              {
                "text": "\"You shall not abhor an Edomite, for he is your brother. You shall not abhor an Egyptian, because you were an alien in his land.", 
                "num": 7
              }, 
              {
                "text": "The children of the third generation born to them may enter the assembly of the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"When the army goes out against your enemies, then keep yourself from every wicked thing.", 
                "num": 9
              }, 
              {
                "text": "If there is any man among you who becomes unclean by some occurrence in the night, then he shall go outside the camp; he shall not come inside the camp.", 
                "num": 10
              }, 
              {
                "text": "But it shall be, when evening comes, that he shall wash with water; and when the sun sets, he may come into the camp.", 
                "num": 11
              }, 
              {
                "text": "\"Also you shall have a place outside the camp, where you may go out;", 
                "num": 12
              }, 
              {
                "text": "and you shall have an implement among your equipment, and when you sit down outside, you shall dig with it and turn and cover your refuse.", 
                "num": 13
              }, 
              {
                "text": "For the LORD your God walks in the midst of your camp, to deliver you and give your enemies over to you; therefore your camp shall be holy, that He may see no unclean thing among you, and turn away from you.", 
                "num": 14
              }, 
              {
                "text": "\"You shall not give back to his master the slave who has escaped from his master to you.", 
                "num": 15
              }, 
              {
                "text": "He may dwell with you in your midst, in the place which he chooses within one of your gates, where it seems best to him; you shall not oppress him.", 
                "num": 16
              }, 
              {
                "text": "\"There shall be no ritual harlot of the daughters of Israel, or a perverted one of the sons of Israel.", 
                "num": 17
              }, 
              {
                "text": "You shall not bring the wages of a harlot or the price of a dog to the house of the LORD your God for any vowed offering, for both of these are an abomination to the LORD your God.", 
                "num": 18
              }, 
              {
                "text": "\"You shall not charge interest to your brother--interest on money or food or anything that is lent out at interest.", 
                "num": 19
              }, 
              {
                "text": "To a foreigner you may charge interest, but to your brother you shall not charge interest, that the LORD your God may bless you in all to which you set your hand in the land which you are entering to possess.", 
                "num": 20
              }, 
              {
                "text": "\"When you make a vow to the LORD your God, you shall not delay to pay it; for the LORD your God will surely require it of you, and it would be sin to you.", 
                "num": 21
              }, 
              {
                "text": "But if you abstain from vowing, it shall not be sin to you.", 
                "num": 22
              }, 
              {
                "text": "That which has gone from your lips you shall keep and perform, for you voluntarily vowed to the LORD your God what you have promised with your mouth.", 
                "num": 23
              }, 
              {
                "text": "\"When you come into your neighbor's vineyard, you may eat your fill of grapes at your pleasure, but you shall not put any in your container.", 
                "num": 24
              }, 
              {
                "text": "When you come into your neighbor's standing grain, you may pluck the heads with your hand, but you shall not use a sickle on your neighbor's standing grain.", 
                "num": 25
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "\"When a man takes a wife and marries her, and it happens that she finds no favor in his eyes because he has found some uncleanness in her, and he writes her a certificate of divorce, puts it in her hand, and sends her out of his house,", 
                "num": 1
              }, 
              {
                "text": "when she has departed from his house, and goes and becomes another man's wife,", 
                "num": 2
              }, 
              {
                "text": "if the latter husband detests her and writes her a certificate of divorce, puts it in her hand, and sends her out of his house, or if the latter husband dies who took her as his wife,", 
                "num": 3
              }, 
              {
                "text": "then her former husband who divorced her must not take her back to be his wife after she has been defiled; for that is an abomination before the LORD, and you shall not bring sin on the land which the LORD your God is giving you as an inheritance.", 
                "num": 4
              }, 
              {
                "text": "\"When a man has taken a new wife, he shall not go out to war or be charged with any business; he shall be free at home one year, and bring happiness to his wife whom he has taken.", 
                "num": 5
              }, 
              {
                "text": "\"No man shall take the lower or the upper millstone in pledge, for he takes one's living in pledge.", 
                "num": 6
              }, 
              {
                "text": "\"If a man is found kidnapping any of his brethren of the children of Israel, and mistreats him or sells him, then that kidnapper shall die; and you shall put away the evil from among you.", 
                "num": 7
              }, 
              {
                "text": "\"Take heed in an outbreak of leprosy, that you carefully observe and do according to all that the priests, the Levites, shall teach you; just as I commanded them, so you shall be careful to do.", 
                "num": 8
              }, 
              {
                "text": "Remember what the LORD your God did to Miriam on the way when you came out of Egypt!", 
                "num": 9
              }, 
              {
                "text": "\"When you lend your brother anything, you shall not go into his house to get his pledge.", 
                "num": 10
              }, 
              {
                "text": "You shall stand outside, and the man to whom you lend shall bring the pledge out to you.", 
                "num": 11
              }, 
              {
                "text": "And if the man is poor, you shall not keep his pledge overnight.", 
                "num": 12
              }, 
              {
                "text": "You shall in any case return the pledge to him again when the sun goes down, that he may sleep in his own garment and bless you; and it shall be righteousness to you before the LORD your God.", 
                "num": 13
              }, 
              {
                "text": "\"You shall not oppress a hired servant who is poor and needy, whether one of your brethren or one of the aliens who is in your land within your gates.", 
                "num": 14
              }, 
              {
                "text": "Each day you shall give him his wages, and not let the sun go down on it, for he is poor and has set his heart on it; lest he cry out against you to the LORD, and it be sin to you.", 
                "num": 15
              }, 
              {
                "text": "\"Fathers shall not be put to death for their children, nor shall children be put to death for their fathers; a person shall be put to death for his own sin.", 
                "num": 16
              }, 
              {
                "text": "\"You shall not pervert justice due the stranger or the fatherless, nor take a widow's garment as a pledge.", 
                "num": 17
              }, 
              {
                "text": "But you shall remember that you were a slave in Egypt, and the LORD your God redeemed you from there; therefore I command you to do this thing.", 
                "num": 18
              }, 
              {
                "text": "\"When you reap your harvest in your field, and forget a sheaf in the field, you shall not go back to get it; it shall be for the stranger, the fatherless, and the widow, that the LORD your God may bless you in all the work of your hands.", 
                "num": 19
              }, 
              {
                "text": "When you beat your olive trees, you shall not go over the boughs again; it shall be for the stranger, the fatherless, and the widow.", 
                "num": 20
              }, 
              {
                "text": "When you gather the grapes of your vineyard, you shall not glean it afterward; it shall be for the stranger, the fatherless, and the widow.", 
                "num": 21
              }, 
              {
                "text": "And you shall remember that you were a slave in the land of Egypt; therefore I command you to do this thing.", 
                "num": 22
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "\"If there is a dispute between men, and they come to court, that the judges may judge them, and they justify the righteous and condemn the wicked,", 
                "num": 1
              }, 
              {
                "text": "then it shall be, if the wicked man deserves to be beaten, that the judge will cause him to lie down and be beaten in his presence, according to his guilt, with a certain number of blows.", 
                "num": 2
              }, 
              {
                "text": "Forty blows he may give him and no more, lest he should exceed this and beat him with many blows above these, and your brother be humiliated in your sight.", 
                "num": 3
              }, 
              {
                "text": "\"You shall not muzzle an ox while it treads out the grain.", 
                "num": 4
              }, 
              {
                "text": "\"If brothers dwell together, and one of them dies and has no son, the widow of the dead man shall not be married to a stranger outside the family; her husband's brother shall go in to her, take her as his wife, and perform the duty of a husband's brother to her.", 
                "num": 5
              }, 
              {
                "text": "And it shall be that the firstborn son which she bears will succeed to the name of his dead brother, that his name may not be blotted out of Israel.", 
                "num": 6
              }, 
              {
                "text": "But if the man does not want to take his brother's wife, then let his brother's wife go up to the gate to the elders, and say, \"My husband's brother refuses to raise up a name to his brother in Israel; he will not perform the duty of my husband's brother.'", 
                "num": 7
              }, 
              {
                "text": "Then the elders of his city shall call him and speak to him. But if he stands firm and says, \"I do not want to take her,'", 
                "num": 8
              }, 
              {
                "text": "then his brother's wife shall come to him in the presence of the elders, remove his sandal from his foot, spit in his face, and answer and say, \"So shall it be done to the man who will not build up his brother's house.'", 
                "num": 9
              }, 
              {
                "text": "And his name shall be called in Israel, \"The house of him who had his sandal removed.'", 
                "num": 10
              }, 
              {
                "text": "\"If two men fight together, and the wife of one draws near to rescue her husband from the hand of the one attacking him, and puts out her hand and seizes him by the genitals,", 
                "num": 11
              }, 
              {
                "text": "then you shall cut off her hand; your eye shall not pity her.", 
                "num": 12
              }, 
              {
                "text": "\"You shall not have in your bag differing weights, a heavy and a light.", 
                "num": 13
              }, 
              {
                "text": "You shall not have in your house differing measures, a large and a small.", 
                "num": 14
              }, 
              {
                "text": "You shall have a perfect and just weight, a perfect and just measure, that your days may be lengthened in the land which the LORD your God is giving you.", 
                "num": 15
              }, 
              {
                "text": "For all who do such things, all who behave unrighteously, are an abomination to the LORD your God.", 
                "num": 16
              }, 
              {
                "text": "\"Remember what Amalek did to you on the way as you were coming out of Egypt,", 
                "num": 17
              }, 
              {
                "text": "how he met you on the way and attacked your rear ranks, all the stragglers at your rear, when you were tired and weary; and he did not fear God.", 
                "num": 18
              }, 
              {
                "text": "Therefore it shall be, when the LORD your God has given you rest from your enemies all around, in the land which the LORD your God is giving you to possess as an inheritance, that you will blot out the remembrance of Amalek from under heaven. You shall not forget.", 
                "num": 19
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "\"And it shall be, when you come into the land which the LORD your God is giving you as an inheritance, and you possess it and dwell in it,", 
                "num": 1
              }, 
              {
                "text": "that you shall take some of the first of all the produce of the ground, which you shall bring from your land that the LORD your God is giving you, and put it in a basket and go to the place where the LORD your God chooses to make His name abide.", 
                "num": 2
              }, 
              {
                "text": "And you shall go to the one who is priest in those days, and say to him, \"I declare today to the LORD your God that I have come to the country which the LORD swore to our fathers to give us.'", 
                "num": 3
              }, 
              {
                "text": "\"Then the priest shall take the basket out of your hand and set it down before the altar of the LORD your God.", 
                "num": 4
              }, 
              {
                "text": "And you shall answer and say before the LORD your God: \"My father was a Syrian, about to perish, and he went down to Egypt and dwelt there, few in number; and there he became a nation, great, mighty, and populous.", 
                "num": 5
              }, 
              {
                "text": "But the Egyptians mistreated us, afflicted us, and laid hard bondage on us.", 
                "num": 6
              }, 
              {
                "text": "Then we cried out to the LORD God of our fathers, and the LORD heard our voice and looked on our affliction and our labor and our oppression.", 
                "num": 7
              }, 
              {
                "text": "So the LORD brought us out of Egypt with a mighty hand and with an outstretched arm, with great terror and with signs and wonders.", 
                "num": 8
              }, 
              {
                "text": "He has brought us to this place and has given us this land, \"a land flowing with milk and honey\";", 
                "num": 9
              }, 
              {
                "text": "and now, behold, I have brought the firstfruits of the land which you, O LORD, have given me.' \"Then you shall set it before the LORD your God, and worship before the LORD your God.", 
                "num": 10
              }, 
              {
                "text": "So you shall rejoice in every good thing which the LORD your God has given to you and your house, you and the Levite and the stranger who is among you.", 
                "num": 11
              }, 
              {
                "text": "\"When you have finished laying aside all the tithe of your increase in the third year--the year of tithing--and have given it to the Levite, the stranger, the fatherless, and the widow, so that they may eat within your gates and be filled,", 
                "num": 12
              }, 
              {
                "text": "then you shall say before the LORD your God: \"I have removed the holy tithe from my house, and also have given them to the Levite, the stranger, the fatherless, and the widow, according to all Your commandments which You have commanded me; I have not transgressed Your commandments, nor have I forgotten them.", 
                "num": 13
              }, 
              {
                "text": "I have not eaten any of it when in mourning, nor have I removed any of it for an unclean use, nor given any of it for the dead. I have obeyed the voice of the LORD my God, and have done according to all that You have commanded me.", 
                "num": 14
              }, 
              {
                "text": "Look down from Your holy habitation, from heaven, and bless Your people Israel and the land which You have given us, just as You swore to our fathers, \"a land flowing with milk and honey.\"'", 
                "num": 15
              }, 
              {
                "text": "\"This day the LORD your God commands you to observe these statutes and judgments; therefore you shall be careful to observe them with all your heart and with all your soul.", 
                "num": 16
              }, 
              {
                "text": "Today you have proclaimed the LORD to be your God, and that you will walk in His ways and keep His statutes, His commandments, and His judgments, and that you will obey His voice.", 
                "num": 17
              }, 
              {
                "text": "Also today the LORD has proclaimed you to be His special people, just as He promised you, that you should keep all His commandments,", 
                "num": 18
              }, 
              {
                "text": "and that He will set you high above all nations which He has made, in praise, in name, and in honor, and that you may be a holy people to the LORD your God, just as He has spoken.\"", 
                "num": 19
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Now Moses, with the elders of Israel, commanded the people, saying: \"Keep all the commandments which I command you today.", 
                "num": 1
              }, 
              {
                "text": "And it shall be, on the day when you cross over the Jordan to the land which the LORD your God is giving you, that you shall set up for yourselves large stones, and whitewash them with lime.", 
                "num": 2
              }, 
              {
                "text": "You shall write on them all the words of this law, when you have crossed over, that you may enter the land which the LORD your God is giving you, \"a land flowing with milk and honey,' just as the LORD God of your fathers promised you.", 
                "num": 3
              }, 
              {
                "text": "Therefore it shall be, when you have crossed over the Jordan, that on Mount Ebal you shall set up these stones, which I command you today, and you shall whitewash them with lime.", 
                "num": 4
              }, 
              {
                "text": "And there you shall build an altar to the LORD your God, an altar of stones; you shall not use an iron tool on them.", 
                "num": 5
              }, 
              {
                "text": "You shall build with whole stones the altar of the LORD your God, and offer burnt offerings on it to the LORD your God.", 
                "num": 6
              }, 
              {
                "text": "You shall offer peace offerings, and shall eat there, and rejoice before the LORD your God.", 
                "num": 7
              }, 
              {
                "text": "And you shall write very plainly on the stones all the words of this law.\"", 
                "num": 8
              }, 
              {
                "text": "Then Moses and the priests, the Levites, spoke to all Israel, saying, \"Take heed and listen, O Israel: This day you have become the people of the LORD your God.", 
                "num": 9
              }, 
              {
                "text": "Therefore you shall obey the voice of the LORD your God, and observe His commandments and His statutes which I command you today.\"", 
                "num": 10
              }, 
              {
                "text": "And Moses commanded the people on the same day, saying,", 
                "num": 11
              }, 
              {
                "text": "\"These shall stand on Mount Gerizim to bless the people, when you have crossed over the Jordan: Simeon, Levi, Judah, Issachar, Joseph, and Benjamin;", 
                "num": 12
              }, 
              {
                "text": "and these shall stand on Mount Ebal to curse: Reuben, Gad, Asher, Zebulun, Dan, and Naphtali.", 
                "num": 13
              }, 
              {
                "text": "\"And the Levites shall speak with a loud voice and say to all the men of Israel:", 
                "num": 14
              }, 
              {
                "text": "\"Cursed is the one who makes a carved or molded image, an abomination to the LORD, the work of the hands of the craftsman, and sets it up in secret.' \"And all the people shall answer and say, \"Amen!'", 
                "num": 15
              }, 
              {
                "text": "\"Cursed is the one who treats his father or his mother with contempt.' \"And all the people shall say, \"Amen!'", 
                "num": 16
              }, 
              {
                "text": "\"Cursed is the one who moves his neighbor's landmark.' \"And all the people shall say, \"Amen!'", 
                "num": 17
              }, 
              {
                "text": "\"Cursed is the one who makes the blind to wander off the road.' \"And all the people shall say, \"Amen!'", 
                "num": 18
              }, 
              {
                "text": "\"Cursed is the one who perverts the justice due the stranger, the fatherless, and widow.' \"And all the people shall say, \"Amen!'", 
                "num": 19
              }, 
              {
                "text": "\"Cursed is the one who lies with his father's wife, because he has uncovered his father's bed.' \"And all the people shall say, \"Amen!'", 
                "num": 20
              }, 
              {
                "text": "\"Cursed is the one who lies with any kind of animal.' \"And all the people shall say, \"Amen!'", 
                "num": 21
              }, 
              {
                "text": "\"Cursed is the one who lies with his sister, the daughter of his father or the daughter of his mother.' \"And all the people shall say, \"Amen!'", 
                "num": 22
              }, 
              {
                "text": "\"Cursed is the one who lies with his mother-in-law.' \"And all the people shall say, \"Amen!'", 
                "num": 23
              }, 
              {
                "text": "\"Cursed is the one who attacks his neighbor secretly.' \"And all the people shall say, \"Amen!'", 
                "num": 24
              }, 
              {
                "text": "\"Cursed is the one who takes a bribe to slay an innocent person.' \"And all the people shall say, \"Amen!'", 
                "num": 25
              }, 
              {
                "text": "\"Cursed is the one who does not confirm all the words of this law.' \"And all the people shall say, \"Amen!\"'", 
                "num": 26
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "\"Now it shall come to pass, if you diligently obey the voice of the LORD your God, to observe carefully all His commandments which I command you today, that the LORD your God will set you high above all nations of the earth.", 
                "num": 1
              }, 
              {
                "text": "And all these blessings shall come upon you and overtake you, because you obey the voice of the LORD your God:", 
                "num": 2
              }, 
              {
                "text": "\"Blessed shall you be in the city, and blessed shall you be in the country.", 
                "num": 3
              }, 
              {
                "text": "\"Blessed shall be the fruit of your body, the produce of your ground and the increase of your herds, the increase of your cattle and the offspring of your flocks.", 
                "num": 4
              }, 
              {
                "text": "\"Blessed shall be your basket and your kneading bowl.", 
                "num": 5
              }, 
              {
                "text": "\"Blessed shall you be when you come in, and blessed shall you be when you go out.", 
                "num": 6
              }, 
              {
                "text": "\"The LORD will cause your enemies who rise against you to be defeated before your face; they shall come out against you one way and flee before you seven ways.", 
                "num": 7
              }, 
              {
                "text": "\"The LORD will command the blessing on you in your storehouses and in all to which you set your hand, and He will bless you in the land which the LORD your God is giving you.", 
                "num": 8
              }, 
              {
                "text": "\"The LORD will establish you as a holy people to Himself, just as He has sworn to you, if you keep the commandments of the LORD your God and walk in His ways.", 
                "num": 9
              }, 
              {
                "text": "Then all peoples of the earth shall see that you are called by the name of the LORD, and they shall be afraid of you.", 
                "num": 10
              }, 
              {
                "text": "And the LORD will grant you plenty of goods, in the fruit of your body, in the increase of your livestock, and in the produce of your ground, in the land of which the LORD swore to your fathers to give you.", 
                "num": 11
              }, 
              {
                "text": "The LORD will open to you His good treasure, the heavens, to give the rain to your land in its season, and to bless all the work of your hand. You shall lend to many nations, but you shall not borrow.", 
                "num": 12
              }, 
              {
                "text": "And the LORD will make you the head and not the tail; you shall be above only, and not be beneath, if you heed the commandments of the LORD your God, which I command you today, and are careful to observe them.", 
                "num": 13
              }, 
              {
                "text": "So you shall not turn aside from any of the words which I command you this day, to the right or the left, to go after other gods to serve them.", 
                "num": 14
              }, 
              {
                "text": "\"But it shall come to pass, if you do not obey the voice of the LORD your God, to observe carefully all His commandments and His statutes which I command you today, that all these curses will come upon you and overtake you:", 
                "num": 15
              }, 
              {
                "text": "\"Cursed shall you be in the city, and cursed shall you be in the country.", 
                "num": 16
              }, 
              {
                "text": "\"Cursed shall be your basket and your kneading bowl.", 
                "num": 17
              }, 
              {
                "text": "\"Cursed shall be the fruit of your body and the produce of your land, the increase of your cattle and the offspring of your flocks.", 
                "num": 18
              }, 
              {
                "text": "\"Cursed shall you be when you come in, and cursed shall you be when you go out.", 
                "num": 19
              }, 
              {
                "text": "\"The LORD will send on you cursing, confusion, and rebuke in all that you set your hand to do, until you are destroyed and until you perish quickly, because of the wickedness of your doings in which you have forsaken Me.", 
                "num": 20
              }, 
              {
                "text": "The LORD will make the plague cling to you until He has consumed you from the land which you are going to possess.", 
                "num": 21
              }, 
              {
                "text": "The LORD will strike you with consumption, with fever, with inflammation, with severe burning fever, with the sword, with scorching, and with mildew; they shall pursue you until you perish.", 
                "num": 22
              }, 
              {
                "text": "And your heavens which are over your head shall be bronze, and the earth which is under you shall be iron.", 
                "num": 23
              }, 
              {
                "text": "The LORD will change the rain of your land to powder and dust; from the heaven it shall come down on you until you are destroyed.", 
                "num": 24
              }, 
              {
                "text": "\"The LORD will cause you to be defeated before your enemies; you shall go out one way against them and flee seven ways before them; and you shall become troublesome to all the kingdoms of the earth.", 
                "num": 25
              }, 
              {
                "text": "Your carcasses shall be food for all the birds of the air and the beasts of the earth, and no one shall frighten them away.", 
                "num": 26
              }, 
              {
                "text": "The LORD will strike you with the boils of Egypt, with tumors, with the scab, and with the itch, from which you cannot be healed.", 
                "num": 27
              }, 
              {
                "text": "The LORD will strike you with madness and blindness and confusion of heart.", 
                "num": 28
              }, 
              {
                "text": "And you shall grope at noonday, as a blind man gropes in darkness; you shall not prosper in your ways; you shall be only oppressed and plundered continually, and no one shall save you.", 
                "num": 29
              }, 
              {
                "text": "\"You shall betroth a wife, but another man shall lie with her; you shall build a house, but you shall not dwell in it; you shall plant a vineyard, but shall not gather its grapes.", 
                "num": 30
              }, 
              {
                "text": "Your ox shall be slaughtered before your eyes, but you shall not eat of it; your donkey shall be violently taken away from before you, and shall not be restored to you; your sheep shall be given to your enemies, and you shall have no one to rescue them.", 
                "num": 31
              }, 
              {
                "text": "Your sons and your daughters shall be given to another people, and your eyes shall look and fail with longing for them all day long; and there shall be no strength in your hand.", 
                "num": 32
              }, 
              {
                "text": "A nation whom you have not known shall eat the fruit of your land and the produce of your labor, and you shall be only oppressed and crushed continually.", 
                "num": 33
              }, 
              {
                "text": "So you shall be driven mad because of the sight which your eyes see.", 
                "num": 34
              }, 
              {
                "text": "The LORD will strike you in the knees and on the legs with severe boils which cannot be healed, and from the sole of your foot to the top of your head.", 
                "num": 35
              }, 
              {
                "text": "\"The LORD will bring you and the king whom you set over you to a nation which neither you nor your fathers have known, and there you shall serve other gods--wood and stone.", 
                "num": 36
              }, 
              {
                "text": "And you shall become an astonishment, a proverb, and a byword among all nations where the LORD will drive you.", 
                "num": 37
              }, 
              {
                "text": "\"You shall carry much seed out to the field but gather little in, for the locust shall consume it.", 
                "num": 38
              }, 
              {
                "text": "You shall plant vineyards and tend them, but you shall neither drink of the wine nor gather the grapes; for the worms shall eat them.", 
                "num": 39
              }, 
              {
                "text": "You shall have olive trees throughout all your territory, but you shall not anoint yourself with the oil; for your olives shall drop off.", 
                "num": 40
              }, 
              {
                "text": "You shall beget sons and daughters, but they shall not be yours; for they shall go into captivity.", 
                "num": 41
              }, 
              {
                "text": "Locusts shall consume all your trees and the produce of your land.", 
                "num": 42
              }, 
              {
                "text": "\"The alien who is among you shall rise higher and higher above you, and you shall come down lower and lower.", 
                "num": 43
              }, 
              {
                "text": "He shall lend to you, but you shall not lend to him; he shall be the head, and you shall be the tail.", 
                "num": 44
              }, 
              {
                "text": "\"Moreover all these curses shall come upon you and pursue and overtake you, until you are destroyed, because you did not obey the voice of the LORD your God, to keep His commandments and His statutes which He commanded you.", 
                "num": 45
              }, 
              {
                "text": "And they shall be upon you for a sign and a wonder, and on your descendants forever.", 
                "num": 46
              }, 
              {
                "text": "\"Because you did not serve the LORD your God with joy and gladness of heart, for the abundance of everything,", 
                "num": 47
              }, 
              {
                "text": "therefore you shall serve your enemies, whom the LORD will send against you, in hunger, in thirst, in nakedness, and in need of everything; and He will put a yoke of iron on your neck until He has destroyed you.", 
                "num": 48
              }, 
              {
                "text": "The LORD will bring a nation against you from afar, from the end of the earth, as swift as the eagle flies, a nation whose language you will not understand,", 
                "num": 49
              }, 
              {
                "text": "a nation of fierce countenance, which does not respect the elderly nor show favor to the young.", 
                "num": 50
              }, 
              {
                "text": "And they shall eat the increase of your livestock and the produce of your land, until you are destroyed; they shall not leave you grain or new wine or oil, or the increase of your cattle or the offspring of your flocks, until they have destroyed you.", 
                "num": 51
              }, 
              {
                "text": "\"They shall besiege you at all your gates until your high and fortified walls, in which you trust, come down throughout all your land; and they shall besiege you at all your gates throughout all your land which the LORD your God has given you.", 
                "num": 52
              }, 
              {
                "text": "You shall eat the fruit of your own body, the flesh of your sons and your daughters whom the LORD your God has given you, in the siege and desperate straits in which your enemy shall distress you.", 
                "num": 53
              }, 
              {
                "text": "The sensitive and very refined man among you will be hostile toward his brother, toward the wife of his bosom, and toward the rest of his children whom he leaves behind,", 
                "num": 54
              }, 
              {
                "text": "so that he will not give any of them the flesh of his children whom he will eat, because he has nothing left in the siege and desperate straits in which your enemy shall distress you at all your gates.", 
                "num": 55
              }, 
              {
                "text": "The tender and delicate woman among you, who would not venture to set the sole of her foot on the ground because of her delicateness and sensitivity, will refuse to the husband of her bosom, and to her son and her daughter,", 
                "num": 56
              }, 
              {
                "text": "her placenta which comes out from between her feet and her children whom she bears; for she will eat them secretly for lack of everything in the siege and desperate straits in which your enemy shall distress you at all your gates.", 
                "num": 57
              }, 
              {
                "text": "\"If you do not carefully observe all the words of this law that are written in this book, that you may fear this glorious and awesome name, THE LORD YOUR GOD,", 
                "num": 58
              }, 
              {
                "text": "then the LORD will bring upon you and your descendants extraordinary plagues--great and prolonged plagues--and serious and prolonged sicknesses.", 
                "num": 59
              }, 
              {
                "text": "Moreover He will bring back on you all the diseases of Egypt, of which you were afraid, and they shall cling to you.", 
                "num": 60
              }, 
              {
                "text": "Also every sickness and every plague, which is not written in this Book of the Law, will the LORD bring upon you until you are destroyed.", 
                "num": 61
              }, 
              {
                "text": "You shall be left few in number, whereas you were as the stars of heaven in multitude, because you would not obey the voice of the LORD your God.", 
                "num": 62
              }, 
              {
                "text": "And it shall be, that just as the LORD rejoiced over you to do you good and multiply you, so the LORD will rejoice over you to destroy you and bring you to nothing; and you shall be plucked from off the land which you go to possess.", 
                "num": 63
              }, 
              {
                "text": "\"Then the LORD will scatter you among all peoples, from one end of the earth to the other, and there you shall serve other gods, which neither you nor your fathers have known--wood and stone.", 
                "num": 64
              }, 
              {
                "text": "And among those nations you shall find no rest, nor shall the sole of your foot have a resting place; but there the LORD will give you a trembling heart, failing eyes, and anguish of soul.", 
                "num": 65
              }, 
              {
                "text": "Your life shall hang in doubt before you; you shall fear day and night, and have no assurance of life.", 
                "num": 66
              }, 
              {
                "text": "In the morning you shall say, \"Oh, that it were evening!' And at evening you shall say, \"Oh, that it were morning!' because of the fear which terrifies your heart, and because of the sight which your eyes see.", 
                "num": 67
              }, 
              {
                "text": "\"And the LORD will take you back to Egypt in ships, by the way of which I said to you, \"You shall never see it again.' And there you shall be offered for sale to your enemies as male and female slaves, but no one will buy you.\"", 
                "num": 68
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "These are the words of the covenant which the LORD commanded Moses to make with the children of Israel in the land of Moab, besides the covenant which He made with them in Horeb.", 
                "num": 1
              }, 
              {
                "text": "Now Moses called all Israel and said to them: \"You have seen all that the LORD did before your eyes in the land of Egypt, to Pharaoh and to all his servants and to all his land--", 
                "num": 2
              }, 
              {
                "text": "the great trials which your eyes have seen, the signs, and those great wonders.", 
                "num": 3
              }, 
              {
                "text": "Yet the LORD has not given you a heart to perceive and eyes to see and ears to hear, to this very day.", 
                "num": 4
              }, 
              {
                "text": "And I have led you forty years in the wilderness. Your clothes have not worn out on you, and your sandals have not worn out on your feet.", 
                "num": 5
              }, 
              {
                "text": "You have not eaten bread, nor have you drunk wine or similar drink, that you may know that I am the LORD your God.", 
                "num": 6
              }, 
              {
                "text": "And when you came to this place, Sihon king of Heshbon and Og king of Bashan came out against us to battle, and we conquered them.", 
                "num": 7
              }, 
              {
                "text": "We took their land and gave it as an inheritance to the Reubenites, to the Gadites, and to half the tribe of Manasseh.", 
                "num": 8
              }, 
              {
                "text": "Therefore keep the words of this covenant, and do them, that you may prosper in all that you do.", 
                "num": 9
              }, 
              {
                "text": "\"All of you stand today before the LORD your God: your leaders and your tribes and your elders and your officers, all the men of Israel,", 
                "num": 10
              }, 
              {
                "text": "your little ones and your wives--also the stranger who is in your camp, from the one who cuts your wood to the one who draws your water--", 
                "num": 11
              }, 
              {
                "text": "that you may enter into covenant with the LORD your God, and into His oath, which the LORD your God makes with you today,", 
                "num": 12
              }, 
              {
                "text": "that He may establish you today as a people for Himself, and that He may be God to you, just as He has spoken to you, and just as He has sworn to your fathers, to Abraham, Isaac, and Jacob.", 
                "num": 13
              }, 
              {
                "text": "\"I make this covenant and this oath, not with you alone,", 
                "num": 14
              }, 
              {
                "text": "but with him who stands here with us today before the LORD our God, as well as with him who is not here with us today", 
                "num": 15
              }, 
              {
                "text": "(for you know that we dwelt in the land of Egypt and that we came through the nations which you passed by,", 
                "num": 16
              }, 
              {
                "text": "and you saw their abominations and their idols which were among them--wood and stone and silver and gold);", 
                "num": 17
              }, 
              {
                "text": "so that there may not be among you man or woman or family or tribe, whose heart turns away today from the LORD our God, to go and serve the gods of these nations, and that there may not be among you a root bearing bitterness or wormwood;", 
                "num": 18
              }, 
              {
                "text": "and so it may not happen, when he hears the words of this curse, that he blesses himself in his heart, saying, \"I shall have peace, even though I follow the dictates of my heart'--as though the drunkard could be included with the sober.", 
                "num": 19
              }, 
              {
                "text": "\"The LORD would not spare him; for then the anger of the LORD and His jealousy would burn against that man, and every curse that is written in this book would settle on him, and the LORD would blot out his name from under heaven.", 
                "num": 20
              }, 
              {
                "text": "And the LORD would separate him from all the tribes of Israel for adversity, according to all the curses of the covenant that are written in this Book of the Law,", 
                "num": 21
              }, 
              {
                "text": "so that the coming generation of your children who rise up after you, and the foreigner who comes from a far land, would say, when they see the plagues of that land and the sicknesses which the LORD has laid on it:", 
                "num": 22
              }, 
              {
                "text": "\"The whole land is brimstone, salt, and burning; it is not sown, nor does it bear, nor does any grass grow there, like the overthrow of Sodom and Gomorrah, Admah, and Zeboiim, which the LORD overthrew in His anger and His wrath.'", 
                "num": 23
              }, 
              {
                "text": "All nations would say, \"Why has the LORD done so to this land? What does the heat of this great anger mean?'", 
                "num": 24
              }, 
              {
                "text": "Then people would say: \"Because they have forsaken the covenant of the LORD God of their fathers, which He made with them when He brought them out of the land of Egypt;", 
                "num": 25
              }, 
              {
                "text": "for they went and served other gods and worshiped them, gods that they did not know and that He had not given to them.", 
                "num": 26
              }, 
              {
                "text": "Then the anger of the LORD was aroused against this land, to bring on it every curse that is written in this book.", 
                "num": 27
              }, 
              {
                "text": "And the LORD uprooted them from their land in anger, in wrath, and in great indignation, and cast them into another land, as it is this day.'", 
                "num": 28
              }, 
              {
                "text": "\"The secret things belong to the LORD our God, but those things which are revealed belong to us and to our children forever, that we may do all the words of this law.", 
                "num": 29
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "\"Now it shall come to pass, when all these things come upon you, the blessing and the curse which I have set before you, and you call them to mind among all the nations where the LORD your God drives you,", 
                "num": 1
              }, 
              {
                "text": "and you return to the LORD your God and obey His voice, according to all that I command you today, you and your children, with all your heart and with all your soul,", 
                "num": 2
              }, 
              {
                "text": "that the LORD your God will bring you back from captivity, and have compassion on you, and gather you again from all the nations where the LORD your God has scattered you.", 
                "num": 3
              }, 
              {
                "text": "If any of you are driven out to the farthest parts under heaven, from there the LORD your God will gather you, and from there He will bring you.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD your God will bring you to the land which your fathers possessed, and you shall possess it. He will prosper you and multiply you more than your fathers.", 
                "num": 5
              }, 
              {
                "text": "And the LORD your God will circumcise your heart and the heart of your descendants, to love the LORD your God with all your heart and with all your soul, that you may live.", 
                "num": 6
              }, 
              {
                "text": "\"Also the LORD your God will put all these curses on your enemies and on those who hate you, who persecuted you.", 
                "num": 7
              }, 
              {
                "text": "And you will again obey the voice of the LORD and do all His commandments which I command you today.", 
                "num": 8
              }, 
              {
                "text": "The LORD your God will make you abound in all the work of your hand, in the fruit of your body, in the increase of your livestock, and in the produce of your land for good. For the LORD will again rejoice over you for good as He rejoiced over your fathers,", 
                "num": 9
              }, 
              {
                "text": "if you obey the voice of the LORD your God, to keep His commandments and His statutes which are written in this Book of the Law, and if you turn to the LORD your God with all your heart and with all your soul.", 
                "num": 10
              }, 
              {
                "text": "\"For this commandment which I command you today is not too mysterious for you, nor is it far off.", 
                "num": 11
              }, 
              {
                "text": "It is not in heaven, that you should say, \"Who will ascend into heaven for us and bring it to us, that we may hear it and do it?'", 
                "num": 12
              }, 
              {
                "text": "Nor is it beyond the sea, that you should say, \"Who will go over the sea for us and bring it to us, that we may hear it and do it?'", 
                "num": 13
              }, 
              {
                "text": "But the word is very near you, in your mouth and in your heart, that you may do it.", 
                "num": 14
              }, 
              {
                "text": "\"See, I have set before you today life and good, death and evil,", 
                "num": 15
              }, 
              {
                "text": "in that I command you today to love the LORD your God, to walk in His ways, and to keep His commandments, His statutes, and His judgments, that you may live and multiply; and the LORD your God will bless you in the land which you go to possess.", 
                "num": 16
              }, 
              {
                "text": "But if your heart turns away so that you do not hear, and are drawn away, and worship other gods and serve them,", 
                "num": 17
              }, 
              {
                "text": "I announce to you today that you shall surely perish; you shall not prolong your days in the land which you cross over the Jordan to go in and possess.", 
                "num": 18
              }, 
              {
                "text": "I call heaven and earth as witnesses today against you, that I have set before you life and death, blessing and cursing; therefore choose life, that both you and your descendants may live;", 
                "num": 19
              }, 
              {
                "text": "that you may love the LORD your God, that you may obey His voice, and that you may cling to Him, for He is your life and the length of your days; and that you may dwell in the land which the LORD swore to your fathers, to Abraham, Isaac, and Jacob, to give them.\"", 
                "num": 20
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Then Moses went and spoke these words to all Israel.", 
                "num": 1
              }, 
              {
                "text": "And he said to them: \"I am one hundred and twenty years old today. I can no longer go out and come in. Also the LORD has said to me, \"You shall not cross over this Jordan.'", 
                "num": 2
              }, 
              {
                "text": "The LORD your God Himself crosses over before you; He will destroy these nations from before you, and you shall dispossess them. Joshua himself crosses over before you, just as the LORD has said.", 
                "num": 3
              }, 
              {
                "text": "And the LORD will do to them as He did to Sihon and Og, the kings of the Amorites and their land, when He destroyed them.", 
                "num": 4
              }, 
              {
                "text": "The LORD will give them over to you, that you may do to them according to every commandment which I have commanded you.", 
                "num": 5
              }, 
              {
                "text": "Be strong and of good courage, do not fear nor be afraid of them; for the LORD your God, He is the One who goes with you. He will not leave you nor forsake you.\"", 
                "num": 6
              }, 
              {
                "text": "Then Moses called Joshua and said to him in the sight of all Israel, \"Be strong and of good courage, for you must go with this people to the land which the LORD has sworn to their fathers to give them, and you shall cause them to inherit it.", 
                "num": 7
              }, 
              {
                "text": "And the LORD, He is the One who goes before you. He will be with you, He will not leave you nor forsake you; do not fear nor be dismayed.\"", 
                "num": 8
              }, 
              {
                "text": "So Moses wrote this law and delivered it to the priests, the sons of Levi, who bore the ark of the covenant of the LORD, and to all the elders of Israel.", 
                "num": 9
              }, 
              {
                "text": "And Moses commanded them, saying: \"At the end of every seven years, at the appointed time in the year of release, at the Feast of Tabernacles,", 
                "num": 10
              }, 
              {
                "text": "when all Israel comes to appear before the LORD your God in the place which He chooses, you shall read this law before all Israel in their hearing.", 
                "num": 11
              }, 
              {
                "text": "Gather the people together, men and women and little ones, and the stranger who is within your gates, that they may hear and that they may learn to fear the LORD your God and carefully observe all the words of this law,", 
                "num": 12
              }, 
              {
                "text": "and that their children, who have not known it, may hear and learn to fear the LORD your God as long as you live in the land which you cross the Jordan to possess.\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD said to Moses, \"Behold, the days approach when you must die; call Joshua, and present yourselves in the tabernacle of meeting, that I may inaugurate him.\" So Moses and Joshua went and presented themselves in the tabernacle of meeting.", 
                "num": 14
              }, 
              {
                "text": "Now the LORD appeared at the tabernacle in a pillar of cloud, and the pillar of cloud stood above the door of the tabernacle.", 
                "num": 15
              }, 
              {
                "text": "And the LORD said to Moses: \"Behold, you will rest with your fathers; and this people will rise and play the harlot with the gods of the foreigners of the land, where they go to be among them, and they will forsake Me and break My covenant which I have made with them.", 
                "num": 16
              }, 
              {
                "text": "Then My anger shall be aroused against them in that day, and I will forsake them, and I will hide My face from them, and they shall be devoured. And many evils and troubles shall befall them, so that they will say in that day, \"Have not these evils come upon us because our God is not among us?'", 
                "num": 17
              }, 
              {
                "text": "And I will surely hide My face in that day because of all the evil which they have done, in that they have turned to other gods.", 
                "num": 18
              }, 
              {
                "text": "\"Now therefore, write down this song for yourselves, and teach it to the children of Israel; put it in their mouths, that this song may be a witness for Me against the children of Israel.", 
                "num": 19
              }, 
              {
                "text": "When I have brought them to the land flowing with milk and honey, of which I swore to their fathers, and they have eaten and filled themselves and grown fat, then they will turn to other gods and serve them; and they will provoke Me and break My covenant.", 
                "num": 20
              }, 
              {
                "text": "Then it shall be, when many evils and troubles have come upon them, that this song will testify against them as a witness; for it will not be forgotten in the mouths of their descendants, for I know the inclination of their behavior today, even before I have brought them to the land of which I swore to give them.\"", 
                "num": 21
              }, 
              {
                "text": "Therefore Moses wrote this song the same day, and taught it to the children of Israel.", 
                "num": 22
              }, 
              {
                "text": "Then He inaugurated Joshua the son of Nun, and said, \"Be strong and of good courage; for you shall bring the children of Israel into the land of which I swore to them, and I will be with you.\"", 
                "num": 23
              }, 
              {
                "text": "So it was, when Moses had completed writing the words of this law in a book, when they were finished,", 
                "num": 24
              }, 
              {
                "text": "that Moses commanded the Levites, who bore the ark of the covenant of the LORD, saying:", 
                "num": 25
              }, 
              {
                "text": "\"Take this Book of the Law, and put it beside the ark of the covenant of the LORD your God, that it may be there as a witness against you;", 
                "num": 26
              }, 
              {
                "text": "for I know your rebellion and your stiff neck. If today, while I am yet alive with you, you have been rebellious against the LORD, then how much more after my death?", 
                "num": 27
              }, 
              {
                "text": "Gather to me all the elders of your tribes, and your officers, that I may speak these words in their hearing and call heaven and earth to witness against them.", 
                "num": 28
              }, 
              {
                "text": "For I know that after my death you will become utterly corrupt, and turn aside from the way which I have commanded you. And evil will befall you in the latter days, because you will do evil in the sight of the LORD, to provoke Him to anger through the work of your hands.\"", 
                "num": 29
              }, 
              {
                "text": "Then Moses spoke in the hearing of all the assembly of Israel the words of this song until they were ended:", 
                "num": 30
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "\"Give ear, O heavens, and I will speak; And hear, O earth, the words of my mouth.", 
                "num": 1
              }, 
              {
                "text": "Let my teaching drop as the rain, My speech distill as the dew, As raindrops on the tender herb, And as showers on the grass.", 
                "num": 2
              }, 
              {
                "text": "For I proclaim the name of the LORD: Ascribe greatness to our God.", 
                "num": 3
              }, 
              {
                "text": "He is the Rock, His work is perfect; For all His ways are justice, A God of truth and without injustice; Righteous and upright is He.", 
                "num": 4
              }, 
              {
                "text": "\"They have corrupted themselves; They are not His children, Because of their blemish: A perverse and crooked generation.", 
                "num": 5
              }, 
              {
                "text": "Do you thus deal with the LORD, O foolish and unwise people? Is He not your Father, who bought you? Has He not made you and established you?", 
                "num": 6
              }, 
              {
                "text": "\"Remember the days of old, Consider the years of many generations. Ask your father, and he will show you; Your elders, and they will tell you:", 
                "num": 7
              }, 
              {
                "text": "When the Most High divided their inheritance to the nations, When He separated the sons of Adam, He set the boundaries of the peoples According to the number of the children of Israel.", 
                "num": 8
              }, 
              {
                "text": "For the LORD's portion is His people; Jacob is the place of His inheritance.", 
                "num": 9
              }, 
              {
                "text": "\"He found him in a desert land And in the wasteland, a howling wilderness; He encircled him, He instructed him, He kept him as the apple of His eye.", 
                "num": 10
              }, 
              {
                "text": "As an eagle stirs up its nest, Hovers over its young, Spreading out its wings, taking them up, Carrying them on its wings,", 
                "num": 11
              }, 
              {
                "text": "So the LORD alone led him, And there was no foreign god with him.", 
                "num": 12
              }, 
              {
                "text": "\"He made him ride in the heights of the earth, That he might eat the produce of the fields; He made him draw honey from the rock, And oil from the flinty rock;", 
                "num": 13
              }, 
              {
                "text": "Curds from the cattle, and milk of the flock, With fat of lambs; And rams of the breed of Bashan, and goats, With the choicest wheat; And you drank wine, the blood of the grapes.", 
                "num": 14
              }, 
              {
                "text": "\"But Jeshurun grew fat and kicked; You grew fat, you grew thick, You are obese! Then he forsook God who made him, And scornfully esteemed the Rock of his salvation.", 
                "num": 15
              }, 
              {
                "text": "They provoked Him to jealousy with foreign gods; With abominations they provoked Him to anger.", 
                "num": 16
              }, 
              {
                "text": "They sacrificed to demons, not to God, To gods they did not know, To new gods, new arrivals That your fathers did not fear.", 
                "num": 17
              }, 
              {
                "text": "Of the Rock who begot you, you are unmindful, And have forgotten the God who fathered you.", 
                "num": 18
              }, 
              {
                "text": "\"And when the LORD saw it, He spurned them, Because of the provocation of His sons and His daughters.", 
                "num": 19
              }, 
              {
                "text": "And He said: \"I will hide My face from them, I will see what their end will be, For they are a perverse generation, Children in whom is no faith.", 
                "num": 20
              }, 
              {
                "text": "They have provoked Me to jealousy by what is not God; They have moved Me to anger by their foolish idols. But I will provoke them to jealousy by those who are not a nation; I will move them to anger by a foolish nation.", 
                "num": 21
              }, 
              {
                "text": "For a fire is kindled in My anger, And shall burn to the lowest hell; It shall consume the earth with her increase, And set on fire the foundations of the mountains.", 
                "num": 22
              }, 
              {
                "text": "\"I will heap disasters on them; I will spend My arrows on them.", 
                "num": 23
              }, 
              {
                "text": "They shall be wasted with hunger, Devoured by pestilence and bitter destruction; I will also send against them the teeth of beasts, With the poison of serpents of the dust.", 
                "num": 24
              }, 
              {
                "text": "The sword shall destroy outside; There shall be terror within For the young man and virgin, The nursing child with the man of gray hairs.", 
                "num": 25
              }, 
              {
                "text": "I would have said, \"I will dash them in pieces, I will make the memory of them to cease from among men,\"", 
                "num": 26
              }, 
              {
                "text": "Had I not feared the wrath of the enemy, Lest their adversaries should misunderstand, Lest they should say, \"Our hand is high; And it is not the LORD who has done all this.\"'", 
                "num": 27
              }, 
              {
                "text": "\"For they are a nation void of counsel, Nor is there any understanding in them.", 
                "num": 28
              }, 
              {
                "text": "Oh, that they were wise, that they understood this, That they would consider their latter end!", 
                "num": 29
              }, 
              {
                "text": "How could one chase a thousand, And two put ten thousand to flight, Unless their Rock had sold them, And the LORD had surrendered them?", 
                "num": 30
              }, 
              {
                "text": "For their rock is not like our Rock, Even our enemies themselves being judges.", 
                "num": 31
              }, 
              {
                "text": "For their vine is of the vine of Sodom And of the fields of Gomorrah; Their grapes are grapes of gall, Their clusters are bitter.", 
                "num": 32
              }, 
              {
                "text": "Their wine is the poison of serpents, And the cruel venom of cobras.", 
                "num": 33
              }, 
              {
                "text": "\"Is this not laid up in store with Me, Sealed up among My treasures?", 
                "num": 34
              }, 
              {
                "text": "Vengeance is Mine, and recompense; Their foot shall slip in due time; For the day of their calamity is at hand, And the things to come hasten upon them.'", 
                "num": 35
              }, 
              {
                "text": "\"For the LORD will judge His people And have compassion on His servants, When He sees that their power is gone, And there is no one remaining, bond or free.", 
                "num": 36
              }, 
              {
                "text": "He will say: \"Where are their gods, The rock in which they sought refuge?", 
                "num": 37
              }, 
              {
                "text": "Who ate the fat of their sacrifices, And drank the wine of their drink offering? Let them rise and help you, And be your refuge.", 
                "num": 38
              }, 
              {
                "text": "\"Now see that I, even I, am He, And there is no God besides Me; I kill and I make alive; I wound and I heal; Nor is there any who can deliver from My hand.", 
                "num": 39
              }, 
              {
                "text": "For I raise My hand to heaven, And say, \"As I live forever,", 
                "num": 40
              }, 
              {
                "text": "If I whet My glittering sword, And My hand takes hold on judgment, I will render vengeance to My enemies, And repay those who hate Me.", 
                "num": 41
              }, 
              {
                "text": "I will make My arrows drunk with blood, And My sword shall devour flesh, With the blood of the slain and the captives, From the heads of the leaders of the enemy.\"'", 
                "num": 42
              }, 
              {
                "text": "\"Rejoice, O Gentiles, with His people; For He will avenge the blood of His servants, And render vengeance to His adversaries; He will provide atonement for His land and His people.\"", 
                "num": 43
              }, 
              {
                "text": "So Moses came with Joshua the son of Nun and spoke all the words of this song in the hearing of the people.", 
                "num": 44
              }, 
              {
                "text": "Moses finished speaking all these words to all Israel,", 
                "num": 45
              }, 
              {
                "text": "and he said to them: \"Set your hearts on all the words which I testify among you today, which you shall command your children to be careful to observe--all the words of this law.", 
                "num": 46
              }, 
              {
                "text": "For it is not a futile thing for you, because it is your life, and by this word you shall prolong your days in the land which you cross over the Jordan to possess.\"", 
                "num": 47
              }, 
              {
                "text": "Then the LORD spoke to Moses that very same day, saying:", 
                "num": 48
              }, 
              {
                "text": "\"Go up this mountain of the Abarim, Mount Nebo, which is in the land of Moab, across from Jericho; view the land of Canaan, which I give to the children of Israel as a possession;", 
                "num": 49
              }, 
              {
                "text": "and die on the mountain which you ascend, and be gathered to your people, just as Aaron your brother died on Mount Hor and was gathered to his people;", 
                "num": 50
              }, 
              {
                "text": "because you trespassed against Me among the children of Israel at the waters of Meribah Kadesh, in the Wilderness of Zin, because you did not hallow Me in the midst of the children of Israel.", 
                "num": 51
              }, 
              {
                "text": "Yet you shall see the land before you, though you shall not go there, into the land which I am giving to the children of Israel.\"", 
                "num": 52
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Now this is the blessing with which Moses the man of God blessed the children of Israel before his death.", 
                "num": 1
              }, 
              {
                "text": "And he said: \"The LORD came from Sinai, And dawned on them from Seir; He shone forth from Mount Paran, And He came with ten thousands of saints; From His right hand Came a fiery law for them.", 
                "num": 2
              }, 
              {
                "text": "Yes, He loves the people; All His saints are in Your hand; They sit down at Your feet; everyone receives Your words.", 
                "num": 3
              }, 
              {
                "text": "Moses commanded a law for us, A heritage of the congregation of Jacob.", 
                "num": 4
              }, 
              {
                "text": "And He was King in Jeshurun, When the leaders of the people were gathered, All the tribes of Israel together.", 
                "num": 5
              }, 
              {
                "text": "\"Let Reuben live, and not die, Nor let his men be few.\"", 
                "num": 6
              }, 
              {
                "text": "And this he said of Judah: \"Hear, LORD, the voice of Judah, And bring him to his people; Let his hands be sufficient for him, And may You be a help against his enemies.\"", 
                "num": 7
              }, 
              {
                "text": "And of Levi he said: \"Let Your Thummim and Your Urim be with Your holy one, Whom You tested at Massah, And with whom You contended at the waters of Meribah,", 
                "num": 8
              }, 
              {
                "text": "Who says of his father and mother, \"I have not seen them'; Nor did he acknowledge his brothers, Or know his own children; For they have observed Your word And kept Your covenant.", 
                "num": 9
              }, 
              {
                "text": "They shall teach Jacob Your judgments, And Israel Your law. They shall put incense before You, And a whole burnt sacrifice on Your altar.", 
                "num": 10
              }, 
              {
                "text": "Bless his substance, LORD, And accept the work of his hands; Strike the loins of those who rise against him, And of those who hate him, that they rise not again.\"", 
                "num": 11
              }, 
              {
                "text": "Of Benjamin he said: \"The beloved of the LORD shall dwell in safety by Him, Who shelters him all the day long; And he shall dwell between His shoulders.\"", 
                "num": 12
              }, 
              {
                "text": "And of Joseph he said: \"Blessed of the LORD is his land, With the precious things of heaven, with the dew, And the deep lying beneath,", 
                "num": 13
              }, 
              {
                "text": "With the precious fruits of the sun, With the precious produce of the months,", 
                "num": 14
              }, 
              {
                "text": "With the best things of the ancient mountains, With the precious things of the everlasting hills,", 
                "num": 15
              }, 
              {
                "text": "With the precious things of the earth and its fullness, And the favor of Him who dwelt in the bush. Let the blessing come \"on the head of Joseph, And on the crown of the head of him who was separate from his brothers.'", 
                "num": 16
              }, 
              {
                "text": "His glory is like a firstborn bull, And his horns like the horns of the wild ox; Together with them He shall push the peoples To the ends of the earth; They are the ten thousands of Ephraim, And they are the thousands of Manasseh.\"", 
                "num": 17
              }, 
              {
                "text": "And of Zebulun he said: \"Rejoice, Zebulun, in your going out, And Issachar in your tents!", 
                "num": 18
              }, 
              {
                "text": "They shall call the peoples to the mountain; There they shall offer sacrifices of righteousness; For they shall partake of the abundance of the seas And of treasures hidden in the sand.\"", 
                "num": 19
              }, 
              {
                "text": "And of Gad he said: \"Blessed is he who enlarges Gad; He dwells as a lion, And tears the arm and the crown of his head.", 
                "num": 20
              }, 
              {
                "text": "He provided the first part for himself, Because a lawgiver's portion was reserved there. He came with the heads of the people; He administered the justice of the LORD, And His judgments with Israel.\"", 
                "num": 21
              }, 
              {
                "text": "And of Dan he said: \"Dan is a lion's whelp; He shall leap from Bashan.\"", 
                "num": 22
              }, 
              {
                "text": "And of Naphtali he said: \"O Naphtali, satisfied with favor, And full of the blessing of the LORD, Possess the west and the south.\"", 
                "num": 23
              }, 
              {
                "text": "And of Asher he said: \"Asher is most blessed of sons; Let him be favored by his brothers, And let him dip his foot in oil.", 
                "num": 24
              }, 
              {
                "text": "Your sandals shall be iron and bronze; As your days, so shall your strength be.", 
                "num": 25
              }, 
              {
                "text": "\"There is no one like the God of Jeshurun, Who rides the heavens to help you, And in His excellency on the clouds.", 
                "num": 26
              }, 
              {
                "text": "The eternal God is your refuge, And underneath are the everlasting arms; He will thrust out the enemy from before you, And will say, \"Destroy!'", 
                "num": 27
              }, 
              {
                "text": "Then Israel shall dwell in safety, The fountain of Jacob alone, In a land of grain and new wine; His heavens shall also drop dew.", 
                "num": 28
              }, 
              {
                "text": "Happy are you, O Israel! Who is like you, a people saved by the LORD, The shield of your help And the sword of your majesty! Your enemies shall submit to you, And you shall tread down their high places.\"", 
                "num": 29
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Then Moses went up from the plains of Moab to Mount Nebo, to the top of Pisgah, which is across from Jericho. And the LORD showed him all the land of Gilead as far as Dan,", 
                "num": 1
              }, 
              {
                "text": "all Naphtali and the land of Ephraim and Manasseh, all the land of Judah as far as the Western Sea,", 
                "num": 2
              }, 
              {
                "text": "the South, and the plain of the Valley of Jericho, the city of palm trees, as far as Zoar.", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said to him, \"This is the land of which I swore to give Abraham, Isaac, and Jacob, saying, \"I will give it to your descendants.' I have caused you to see it with your eyes, but you shall not cross over there.\"", 
                "num": 4
              }, 
              {
                "text": "So Moses the servant of the LORD died there in the land of Moab, according to the word of the LORD.", 
                "num": 5
              }, 
              {
                "text": "And He buried him in a valley in the land of Moab, opposite Beth Peor; but no one knows his grave to this day.", 
                "num": 6
              }, 
              {
                "text": "Moses was one hundred and twenty years old when he died. His eyes were not dim nor his natural vigor diminished.", 
                "num": 7
              }, 
              {
                "text": "And the children of Israel wept for Moses in the plains of Moab thirty days. So the days of weeping and mourning for Moses ended.", 
                "num": 8
              }, 
              {
                "text": "Now Joshua the son of Nun was full of the spirit of wisdom, for Moses had laid his hands on him; so the children of Israel heeded him, and did as the LORD had commanded Moses.", 
                "num": 9
              }, 
              {
                "text": "But since then there has not arisen in Israel a prophet like Moses, whom the LORD knew face to face,", 
                "num": 10
              }, 
              {
                "text": "in all the signs and wonders which the LORD sent him to do in the land of Egypt, before Pharaoh, before all his servants, and in all his land,", 
                "num": 11
              }, 
              {
                "text": "and by all that mighty power and all the great terror which Moses performed in the sight of all Israel.", 
                "num": 12
              }
            ], 
            "num": 34
          }
        ]
      }, 
      {
        "name": "Joshua", 
        "chapters": [
          {
            "verses": [
              {
                "text": "After the death of Moses the servant of the LORD, it came to pass that the LORD spoke to Joshua the son of Nun, Moses' assistant, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Moses My servant is dead. Now therefore, arise, go over this Jordan, you and all this people, to the land which I am giving to them--the children of Israel.", 
                "num": 2
              }, 
              {
                "text": "Every place that the sole of your foot will tread upon I have given you, as I said to Moses.", 
                "num": 3
              }, 
              {
                "text": "From the wilderness and this Lebanon as far as the great river, the River Euphrates, all the land of the Hittites, and to the Great Sea toward the going down of the sun, shall be your territory.", 
                "num": 4
              }, 
              {
                "text": "No man shall be able to stand before you all the days of your life; as I was with Moses, so I will be with you. I will not leave you nor forsake you.", 
                "num": 5
              }, 
              {
                "text": "Be strong and of good courage, for to this people you shall divide as an inheritance the land which I swore to their fathers to give them.", 
                "num": 6
              }, 
              {
                "text": "Only be strong and very courageous, that you may observe to do according to all the law which Moses My servant commanded you; do not turn from it to the right hand or to the left, that you may prosper wherever you go.", 
                "num": 7
              }, 
              {
                "text": "This Book of the Law shall not depart from your mouth, but you shall meditate in it day and night, that you may observe to do according to all that is written in it. For then you will make your way prosperous, and then you will have good success.", 
                "num": 8
              }, 
              {
                "text": "Have I not commanded you? Be strong and of good courage; do not be afraid, nor be dismayed, for the LORD your God is with you wherever you go.\"", 
                "num": 9
              }, 
              {
                "text": "Then Joshua commanded the officers of the people, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Pass through the camp and command the people, saying, \"Prepare provisions for yourselves, for within three days you will cross over this Jordan, to go in to possess the land which the LORD your God is giving you to possess.\"'", 
                "num": 11
              }, 
              {
                "text": "And to the Reubenites, the Gadites, and half the tribe of Manasseh Joshua spoke, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Remember the word which Moses the servant of the LORD commanded you, saying, \"The LORD your God is giving you rest and is giving you this land.'", 
                "num": 13
              }, 
              {
                "text": "Your wives, your little ones, and your livestock shall remain in the land which Moses gave you on this side of the Jordan. But you shall pass before your brethren armed, all your mighty men of valor, and help them,", 
                "num": 14
              }, 
              {
                "text": "until the LORD has given your brethren rest, as He gave you, and they also have taken possession of the land which the LORD your God is giving them. Then you shall return to the land of your possession and enjoy it, which Moses the LORD's servant gave you on this side of the Jordan toward the sunrise.\"", 
                "num": 15
              }, 
              {
                "text": "So they answered Joshua, saying, \"All that you command us we will do, and wherever you send us we will go.", 
                "num": 16
              }, 
              {
                "text": "Just as we heeded Moses in all things, so we will heed you. Only the LORD your God be with you, as He was with Moses.", 
                "num": 17
              }, 
              {
                "text": "Whoever rebels against your command and does not heed your words, in all that you command him, shall be put to death. Only be strong and of good courage.\"", 
                "num": 18
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now Joshua the son of Nun sent out two men from Acacia Grove to spy secretly, saying, \"Go, view the land, especially Jericho.\" So they went, and came to the house of a harlot named Rahab, and lodged there.", 
                "num": 1
              }, 
              {
                "text": "And it was told the king of Jericho, saying, \"Behold, men have come here tonight from the children of Israel to search out the country.\"", 
                "num": 2
              }, 
              {
                "text": "So the king of Jericho sent to Rahab, saying, \"Bring out the men who have come to you, who have entered your house, for they have come to search out all the country.\"", 
                "num": 3
              }, 
              {
                "text": "Then the woman took the two men and hid them. So she said, \"Yes, the men came to me, but I did not know where they were from.", 
                "num": 4
              }, 
              {
                "text": "And it happened as the gate was being shut, when it was dark, that the men went out. Where the men went I do not know; pursue them quickly, for you may overtake them.\"", 
                "num": 5
              }, 
              {
                "text": "(But she had brought them up to the roof and hidden them with the stalks of flax, which she had laid in order on the roof.)", 
                "num": 6
              }, 
              {
                "text": "Then the men pursued them by the road to the Jordan, to the fords. And as soon as those who pursued them had gone out, they shut the gate.", 
                "num": 7
              }, 
              {
                "text": "Now before they lay down, she came up to them on the roof,", 
                "num": 8
              }, 
              {
                "text": "and said to the men: \"I know that the LORD has given you the land, that the terror of you has fallen on us, and that all the inhabitants of the land are fainthearted because of you.", 
                "num": 9
              }, 
              {
                "text": "For we have heard how the LORD dried up the water of the Red Sea for you when you came out of Egypt, and what you did to the two kings of the Amorites who were on the other side of the Jordan, Sihon and Og, whom you utterly destroyed.", 
                "num": 10
              }, 
              {
                "text": "And as soon as we heard these things, our hearts melted; neither did there remain any more courage in anyone because of you, for the LORD your God, He is God in heaven above and on earth beneath.", 
                "num": 11
              }, 
              {
                "text": "Now therefore, I beg you, swear to me by the LORD, since I have shown you kindness, that you also will show kindness to my father's house, and give me a true token,", 
                "num": 12
              }, 
              {
                "text": "and spare my father, my mother, my brothers, my sisters, and all that they have, and deliver our lives from death.\"", 
                "num": 13
              }, 
              {
                "text": "So the men answered her, \"Our lives for yours, if none of you tell this business of ours. And it shall be, when the LORD has given us the land, that we will deal kindly and truly with you.\"", 
                "num": 14
              }, 
              {
                "text": "Then she let them down by a rope through the window, for her house was on the city wall; she dwelt on the wall.", 
                "num": 15
              }, 
              {
                "text": "And she said to them, \"Get to the mountain, lest the pursuers meet you. Hide there three days, until the pursuers have returned. Afterward you may go your way.\"", 
                "num": 16
              }, 
              {
                "text": "So the men said to her: \"We will be blameless of this oath of yours which you have made us swear,", 
                "num": 17
              }, 
              {
                "text": "unless, when we come into the land, you bind this line of scarlet cord in the window through which you let us down, and unless you bring your father, your mother, your brothers, and all your father's household to your own home.", 
                "num": 18
              }, 
              {
                "text": "So it shall be that whoever goes outside the doors of your house into the street, his blood shall be on his own head, and we will be guiltless. And whoever is with you in the house, his blood shall be on our head if a hand is laid on him.", 
                "num": 19
              }, 
              {
                "text": "And if you tell this business of ours, then we will be free from your oath which you made us swear.\"", 
                "num": 20
              }, 
              {
                "text": "Then she said, \"According to your words, so be it.\" And she sent them away, and they departed. And she bound the scarlet cord in the window.", 
                "num": 21
              }, 
              {
                "text": "They departed and went to the mountain, and stayed there three days until the pursuers returned. The pursuers sought them all along the way, but did not find them.", 
                "num": 22
              }, 
              {
                "text": "So the two men returned, descended from the mountain, and crossed over; and they came to Joshua the son of Nun, and told him all that had befallen them.", 
                "num": 23
              }, 
              {
                "text": "And they said to Joshua, \"Truly the LORD has delivered all the land into our hands, for indeed all the inhabitants of the country are fainthearted because of us.\"", 
                "num": 24
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Then Joshua rose early in the morning; and they set out from Acacia Grove and came to the Jordan, he and all the children of Israel, and lodged there before they crossed over.", 
                "num": 1
              }, 
              {
                "text": "So it was, after three days, that the officers went through the camp;", 
                "num": 2
              }, 
              {
                "text": "and they commanded the people, saying, \"When you see the ark of the covenant of the LORD your God, and the priests, the Levites, bearing it, then you shall set out from your place and go after it.", 
                "num": 3
              }, 
              {
                "text": "Yet there shall be a space between you and it, about two thousand cubits by measure. Do not come near it, that you may know the way by which you must go, for you have not passed this way before.\"", 
                "num": 4
              }, 
              {
                "text": "And Joshua said to the people, \"Sanctify yourselves, for tomorrow the LORD will do wonders among you.\"", 
                "num": 5
              }, 
              {
                "text": "Then Joshua spoke to the priests, saying, \"Take up the ark of the covenant and cross over before the people.\" So they took up the ark of the covenant and went before the people.", 
                "num": 6
              }, 
              {
                "text": "And the LORD said to Joshua, \"This day I will begin to exalt you in the sight of all Israel, that they may know that, as I was with Moses, so I will be with you.", 
                "num": 7
              }, 
              {
                "text": "You shall command the priests who bear the ark of the covenant, saying, \"When you have come to the edge of the water of the Jordan, you shall stand in the Jordan.\"'", 
                "num": 8
              }, 
              {
                "text": "So Joshua said to the children of Israel, \"Come here, and hear the words of the LORD your God.\"", 
                "num": 9
              }, 
              {
                "text": "And Joshua said, \"By this you shall know that the living God is among you, and that He will without fail drive out from before you the Canaanites and the Hittites and the Hivites and the Perizzites and the Girgashites and the Amorites and the Jebusites:", 
                "num": 10
              }, 
              {
                "text": "Behold, the ark of the covenant of the Lord of all the earth is crossing over before you into the Jordan.", 
                "num": 11
              }, 
              {
                "text": "Now therefore, take for yourselves twelve men from the tribes of Israel, one man from every tribe.", 
                "num": 12
              }, 
              {
                "text": "And it shall come to pass, as soon as the soles of the feet of the priests who bear the ark of the LORD, the Lord of all the earth, shall rest in the waters of the Jordan, that the waters of the Jordan shall be cut off, the waters that come down from upstream, and they shall stand as a heap.\"", 
                "num": 13
              }, 
              {
                "text": "So it was, when the people set out from their camp to cross over the Jordan, with the priests bearing the ark of the covenant before the people,", 
                "num": 14
              }, 
              {
                "text": "and as those who bore the ark came to the Jordan, and the feet of the priests who bore the ark dipped in the edge of the water (for the Jordan overflows all its banks during the whole time of harvest),", 
                "num": 15
              }, 
              {
                "text": "that the waters which came down from upstream stood still, and rose in a heap very far away at Adam, the city that is beside Zaretan. So the waters that went down into the Sea of the Arabah, the Salt Sea, failed, and were cut off; and the people crossed over opposite Jericho.", 
                "num": 16
              }, 
              {
                "text": "Then the priests who bore the ark of the covenant of the LORD stood firm on dry ground in the midst of the Jordan; and all Israel crossed over on dry ground, until all the people had crossed completely over the Jordan.", 
                "num": 17
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass, when all the people had completely crossed over the Jordan, that the LORD spoke to Joshua, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take for yourselves twelve men from the people, one man from every tribe,", 
                "num": 2
              }, 
              {
                "text": "and command them, saying, \"Take for yourselves twelve stones from here, out of the midst of the Jordan, from the place where the priests' feet stood firm. You shall carry them over with you and leave them in the lodging place where you lodge tonight.\"'", 
                "num": 3
              }, 
              {
                "text": "Then Joshua called the twelve men whom he had appointed from the children of Israel, one man from every tribe;", 
                "num": 4
              }, 
              {
                "text": "and Joshua said to them: \"Cross over before the ark of the LORD your God into the midst of the Jordan, and each one of you take up a stone on his shoulder, according to the number of the tribes of the children of Israel,", 
                "num": 5
              }, 
              {
                "text": "that this may be a sign among you when your children ask in time to come, saying, \"What do these stones mean to you?'", 
                "num": 6
              }, 
              {
                "text": "Then you shall answer them that the waters of the Jordan were cut off before the ark of the covenant of the LORD; when it crossed over the Jordan, the waters of the Jordan were cut off. And these stones shall be for a memorial to the children of Israel forever.\"", 
                "num": 7
              }, 
              {
                "text": "And the children of Israel did so, just as Joshua commanded, and took up twelve stones from the midst of the Jordan, as the LORD had spoken to Joshua, according to the number of the tribes of the children of Israel, and carried them over with them to the place where they lodged, and laid them down there.", 
                "num": 8
              }, 
              {
                "text": "Then Joshua set up twelve stones in the midst of the Jordan, in the place where the feet of the priests who bore the ark of the covenant stood; and they are there to this day.", 
                "num": 9
              }, 
              {
                "text": "So the priests who bore the ark stood in the midst of the Jordan until everything was finished that the LORD had commanded Joshua to speak to the people, according to all that Moses had commanded Joshua; and the people hurried and crossed over.", 
                "num": 10
              }, 
              {
                "text": "Then it came to pass, when all the people had completely crossed over, that the ark of the LORD and the priests crossed over in the presence of the people.", 
                "num": 11
              }, 
              {
                "text": "And the men of Reuben, the men of Gad, and half the tribe of Manasseh crossed over armed before the children of Israel, as Moses had spoken to them.", 
                "num": 12
              }, 
              {
                "text": "About forty thousand prepared for war crossed over before the LORD for battle, to the plains of Jericho.", 
                "num": 13
              }, 
              {
                "text": "On that day the LORD exalted Joshua in the sight of all Israel; and they feared him, as they had feared Moses, all the days of his life.", 
                "num": 14
              }, 
              {
                "text": "Then the LORD spoke to Joshua, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Command the priests who bear the ark of the Testimony to come up from the Jordan.\"", 
                "num": 16
              }, 
              {
                "text": "Joshua therefore commanded the priests, saying, \"Come up from the Jordan.\"", 
                "num": 17
              }, 
              {
                "text": "And it came to pass, when the priests who bore the ark of the covenant of the LORD had come from the midst of the Jordan, and the soles of the priests' feet touched the dry land, that the waters of the Jordan returned to their place and overflowed all its banks as before.", 
                "num": 18
              }, 
              {
                "text": "Now the people came up from the Jordan on the tenth day of the first month, and they camped in Gilgal on the east border of Jericho.", 
                "num": 19
              }, 
              {
                "text": "And those twelve stones which they took out of the Jordan, Joshua set up in Gilgal.", 
                "num": 20
              }, 
              {
                "text": "Then he spoke to the children of Israel, saying: \"When your children ask their fathers in time to come, saying, \"What are these stones?'", 
                "num": 21
              }, 
              {
                "text": "then you shall let your children know, saying, \"Israel crossed over this Jordan on dry land';", 
                "num": 22
              }, 
              {
                "text": "for the LORD your God dried up the waters of the Jordan before you until you had crossed over, as the LORD your God did to the Red Sea, which He dried up before us until we had crossed over,", 
                "num": 23
              }, 
              {
                "text": "that all the peoples of the earth may know the hand of the LORD, that it is mighty, that you may fear the LORD your God forever.\"", 
                "num": 24
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "So it was, when all the kings of the Amorites who were on the west side of the Jordan, and all the kings of the Canaanites who were by the sea, heard that the LORD had dried up the waters of the Jordan from before the children of Israel until we had crossed over, that their heart melted; and there was no spirit in them any longer because of the children of Israel.", 
                "num": 1
              }, 
              {
                "text": "At that time the LORD said to Joshua, \"Make flint knives for yourself, and circumcise the sons of Israel again the second time.\"", 
                "num": 2
              }, 
              {
                "text": "So Joshua made flint knives for himself, and circumcised the sons of Israel at the hill of the foreskins.", 
                "num": 3
              }, 
              {
                "text": "And this is the reason why Joshua circumcised them: All the people who came out of Egypt who were males, all the men of war, had died in the wilderness on the way, after they had come out of Egypt.", 
                "num": 4
              }, 
              {
                "text": "For all the people who came out had been circumcised, but all the people born in the wilderness, on the way as they came out of Egypt, had not been circumcised.", 
                "num": 5
              }, 
              {
                "text": "For the children of Israel walked forty years in the wilderness, till all the people who were men of war, who came out of Egypt, were consumed, because they did not obey the voice of the LORD--to whom the LORD swore that He would not show them the land which the LORD had sworn to their fathers that He would give us, \"a land flowing with milk and honey.\"", 
                "num": 6
              }, 
              {
                "text": "Then Joshua circumcised their sons whom He raised up in their place; for they were uncircumcised, because they had not been circumcised on the way.", 
                "num": 7
              }, 
              {
                "text": "So it was, when they had finished circumcising all the people, that they stayed in their places in the camp till they were healed.", 
                "num": 8
              }, 
              {
                "text": "Then the LORD said to Joshua, \"This day I have rolled away the reproach of Egypt from you.\" Therefore the name of the place is called Gilgal to this day.", 
                "num": 9
              }, 
              {
                "text": "Now the children of Israel camped in Gilgal, and kept the Passover on the fourteenth day of the month at twilight on the plains of Jericho.", 
                "num": 10
              }, 
              {
                "text": "And they ate of the produce of the land on the day after the Passover, unleavened bread and parched grain, on the very same day.", 
                "num": 11
              }, 
              {
                "text": "Then the manna ceased on the day after they had eaten the produce of the land; and the children of Israel no longer had manna, but they ate the food of the land of Canaan that year.", 
                "num": 12
              }, 
              {
                "text": "And it came to pass, when Joshua was by Jericho, that he lifted his eyes and looked, and behold, a Man stood opposite him with His sword drawn in His hand. And Joshua went to Him and said to Him, \"Are You for us or for our adversaries?\"", 
                "num": 13
              }, 
              {
                "text": "So He said, \"No, but as Commander of the army of the LORD I have now come.\" And Joshua fell on his face to the earth and worshiped, and said to Him, \"What does my Lord say to His servant?\"", 
                "num": 14
              }, 
              {
                "text": "Then the Commander of the LORD's army said to Joshua, \"Take your sandal off your foot, for the place where you stand is holy.\" And Joshua did so.", 
                "num": 15
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now Jericho was securely shut up because of the children of Israel; none went out, and none came in.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to Joshua: \"See! I have given Jericho into your hand, its king, and the mighty men of valor.", 
                "num": 2
              }, 
              {
                "text": "You shall march around the city, all you men of war; you shall go all around the city once. This you shall do six days.", 
                "num": 3
              }, 
              {
                "text": "And seven priests shall bear seven trumpets of rams' horns before the ark. But the seventh day you shall march around the city seven times, and the priests shall blow the trumpets.", 
                "num": 4
              }, 
              {
                "text": "It shall come to pass, when they make a long blast with the ram's horn, and when you hear the sound of the trumpet, that all the people shall shout with a great shout; then the wall of the city will fall down flat. And the people shall go up every man straight before him.\"", 
                "num": 5
              }, 
              {
                "text": "Then Joshua the son of Nun called the priests and said to them, \"Take up the ark of the covenant, and let seven priests bear seven trumpets of rams' horns before the ark of the LORD.\"", 
                "num": 6
              }, 
              {
                "text": "And he said to the people, \"Proceed, and march around the city, and let him who is armed advance before the ark of the LORD.\"", 
                "num": 7
              }, 
              {
                "text": "So it was, when Joshua had spoken to the people, that the seven priests bearing the seven trumpets of rams' horns before the LORD advanced and blew the trumpets, and the ark of the covenant of the LORD followed them.", 
                "num": 8
              }, 
              {
                "text": "The armed men went before the priests who blew the trumpets, and the rear guard came after the ark, while the priests continued blowing the trumpets.", 
                "num": 9
              }, 
              {
                "text": "Now Joshua had commanded the people, saying, \"You shall not shout or make any noise with your voice, nor shall a word proceed out of your mouth, until the day I say to you, \"Shout!' Then you shall shout.\"", 
                "num": 10
              }, 
              {
                "text": "So he had the ark of the LORD circle the city, going around it once. Then they came into the camp and lodged in the camp.", 
                "num": 11
              }, 
              {
                "text": "And Joshua rose early in the morning, and the priests took up the ark of the LORD.", 
                "num": 12
              }, 
              {
                "text": "Then seven priests bearing seven trumpets of rams' horns before the ark of the LORD went on continually and blew with the trumpets. And the armed men went before them. But the rear guard came after the ark of the LORD, while the priests continued blowing the trumpets.", 
                "num": 13
              }, 
              {
                "text": "And the second day they marched around the city once and returned to the camp. So they did six days.", 
                "num": 14
              }, 
              {
                "text": "But it came to pass on the seventh day that they rose early, about the dawning of the day, and marched around the city seven times in the same manner. On that day only they marched around the city seven times.", 
                "num": 15
              }, 
              {
                "text": "And the seventh time it happened, when the priests blew the trumpets, that Joshua said to the people: \"Shout, for the LORD has given you the city!", 
                "num": 16
              }, 
              {
                "text": "Now the city shall be doomed by the LORD to destruction, it and all who are in it. Only Rahab the harlot shall live, she and all who are with her in the house, because she hid the messengers that we sent.", 
                "num": 17
              }, 
              {
                "text": "And you, by all means abstain from the accursed things, lest you become accursed when you take of the accursed things, and make the camp of Israel a curse, and trouble it.", 
                "num": 18
              }, 
              {
                "text": "But all the silver and gold, and vessels of bronze and iron, are consecrated to the LORD; they shall come into the treasury of the LORD.\"", 
                "num": 19
              }, 
              {
                "text": "So the people shouted when the priests blew the trumpets. And it happened when the people heard the sound of the trumpet, and the people shouted with a great shout, that the wall fell down flat. Then the people went up into the city, every man straight before him, and they took the city.", 
                "num": 20
              }, 
              {
                "text": "And they utterly destroyed all that was in the city, both man and woman, young and old, ox and sheep and donkey, with the edge of the sword.", 
                "num": 21
              }, 
              {
                "text": "But Joshua had said to the two men who had spied out the country, \"Go into the harlot's house, and from there bring out the woman and all that she has, as you swore to her.\"", 
                "num": 22
              }, 
              {
                "text": "And the young men who had been spies went in and brought out Rahab, her father, her mother, her brothers, and all that she had. So they brought out all her relatives and left them outside the camp of Israel.", 
                "num": 23
              }, 
              {
                "text": "But they burned the city and all that was in it with fire. Only the silver and gold, and the vessels of bronze and iron, they put into the treasury of the house of the LORD.", 
                "num": 24
              }, 
              {
                "text": "And Joshua spared Rahab the harlot, her father's household, and all that she had. So she dwells in Israel to this day, because she hid the messengers whom Joshua sent to spy out Jericho.", 
                "num": 25
              }, 
              {
                "text": "Then Joshua charged them at that time, saying, \"Cursed be the man before the LORD who rises up and builds this city Jericho; he shall lay its foundation with his firstborn, and with his youngest he shall set up its gates.\"", 
                "num": 26
              }, 
              {
                "text": "So the LORD was with Joshua, and his fame spread throughout all the country.", 
                "num": 27
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "But the children of Israel committed a trespass regarding the accursed things, for Achan the son of Carmi, the son of Zabdi, the son of Zerah, of the tribe of Judah, took of the accursed things; so the anger of the LORD burned against the children of Israel.", 
                "num": 1
              }, 
              {
                "text": "Now Joshua sent men from Jericho to Ai, which is beside Beth Aven, on the east side of Bethel, and spoke to them, saying, \"Go up and spy out the country.\" So the men went up and spied out Ai.", 
                "num": 2
              }, 
              {
                "text": "And they returned to Joshua and said to him, \"Do not let all the people go up, but let about two or three thousand men go up and attack Ai. Do not weary all the people there, for the people of Ai are few.\"", 
                "num": 3
              }, 
              {
                "text": "So about three thousand men went up there from the people, but they fled before the men of Ai.", 
                "num": 4
              }, 
              {
                "text": "And the men of Ai struck down about thirty-six men, for they chased them from before the gate as far as Shebarim, and struck them down on the descent; therefore the hearts of the people melted and became like water.", 
                "num": 5
              }, 
              {
                "text": "Then Joshua tore his clothes, and fell to the earth on his face before the ark of the LORD until evening, he and the elders of Israel; and they put dust on their heads.", 
                "num": 6
              }, 
              {
                "text": "And Joshua said, \"Alas, Lord GOD, why have You brought this people over the Jordan at all--to deliver us into the hand of the Amorites, to destroy us? Oh, that we had been content, and dwelt on the other side of the Jordan!", 
                "num": 7
              }, 
              {
                "text": "O Lord, what shall I say when Israel turns its back before its enemies?", 
                "num": 8
              }, 
              {
                "text": "For the Canaanites and all the inhabitants of the land will hear it, and surround us, and cut off our name from the earth. Then what will You do for Your great name?\"", 
                "num": 9
              }, 
              {
                "text": "So the LORD said to Joshua: \"Get up! Why do you lie thus on your face?", 
                "num": 10
              }, 
              {
                "text": "Israel has sinned, and they have also transgressed My covenant which I commanded them. For they have even taken some of the accursed things, and have both stolen and deceived; and they have also put it among their own stuff.", 
                "num": 11
              }, 
              {
                "text": "Therefore the children of Israel could not stand before their enemies, but turned their backs before their enemies, because they have become doomed to destruction. Neither will I be with you anymore, unless you destroy the accursed from among you.", 
                "num": 12
              }, 
              {
                "text": "Get up, sanctify the people, and say, \"Sanctify yourselves for tomorrow, because thus says the LORD God of Israel: \"There is an accursed thing in your midst, O Israel; you cannot stand before your enemies until you take away the accursed thing from among you.\"", 
                "num": 13
              }, 
              {
                "text": "In the morning therefore you shall be brought according to your tribes. And it shall be that the tribe which the LORD takes shall come according to families; and the family which the LORD takes shall come by households; and the household which the LORD takes shall come man by man.", 
                "num": 14
              }, 
              {
                "text": "Then it shall be that he who is taken with the accursed thing shall be burned with fire, he and all that he has, because he has transgressed the covenant of the LORD, and because he has done a disgraceful thing in Israel.\"'", 
                "num": 15
              }, 
              {
                "text": "So Joshua rose early in the morning and brought Israel by their tribes, and the tribe of Judah was taken.", 
                "num": 16
              }, 
              {
                "text": "He brought the clan of Judah, and he took the family of the Zarhites; and he brought the family of the Zarhites man by man, and Zabdi was taken.", 
                "num": 17
              }, 
              {
                "text": "Then he brought his household man by man, and Achan the son of Carmi, the son of Zabdi, the son of Zerah, of the tribe of Judah, was taken.", 
                "num": 18
              }, 
              {
                "text": "Now Joshua said to Achan, \"My son, I beg you, give glory to the LORD God of Israel, and make confession to Him, and tell me now what you have done; do not hide it from me.\"", 
                "num": 19
              }, 
              {
                "text": "And Achan answered Joshua and said, \"Indeed I have sinned against the LORD God of Israel, and this is what I have done:", 
                "num": 20
              }, 
              {
                "text": "When I saw among the spoils a beautiful Babylonian garment, two hundred shekels of silver, and a wedge of gold weighing fifty shekels, I coveted them and took them. And there they are, hidden in the earth in the midst of my tent, with the silver under it.\"", 
                "num": 21
              }, 
              {
                "text": "So Joshua sent messengers, and they ran to the tent; and there it was, hidden in his tent, with the silver under it.", 
                "num": 22
              }, 
              {
                "text": "And they took them from the midst of the tent, brought them to Joshua and to all the children of Israel, and laid them out before the LORD.", 
                "num": 23
              }, 
              {
                "text": "Then Joshua, and all Israel with him, took Achan the son of Zerah, the silver, the garment, the wedge of gold, his sons, his daughters, his oxen, his donkeys, his sheep, his tent, and all that he had, and they brought them to the Valley of Achor.", 
                "num": 24
              }, 
              {
                "text": "And Joshua said, \"Why have you troubled us? The LORD will trouble you this day.\" So all Israel stoned him with stones; and they burned them with fire after they had stoned them with stones.", 
                "num": 25
              }, 
              {
                "text": "Then they raised over him a great heap of stones, still there to this day. So the LORD turned from the fierceness of His anger. Therefore the name of that place has been called the Valley of Achor to this day.", 
                "num": 26
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD said to Joshua: \"Do not be afraid, nor be dismayed; take all the people of war with you, and arise, go up to Ai. See, I have given into your hand the king of Ai, his people, his city, and his land.", 
                "num": 1
              }, 
              {
                "text": "And you shall do to Ai and its king as you did to Jericho and its king. Only its spoil and its cattle you shall take as booty for yourselves. Lay an ambush for the city behind it.\"", 
                "num": 2
              }, 
              {
                "text": "So Joshua arose, and all the people of war, to go up against Ai; and Joshua chose thirty thousand mighty men of valor and sent them away by night.", 
                "num": 3
              }, 
              {
                "text": "And he commanded them, saying: \"Behold, you shall lie in ambush against the city, behind the city. Do not go very far from the city, but all of you be ready.", 
                "num": 4
              }, 
              {
                "text": "Then I and all the people who are with me will approach the city; and it will come about, when they come out against us as at the first, that we shall flee before them.", 
                "num": 5
              }, 
              {
                "text": "For they will come out after us till we have drawn them from the city, for they will say, \"They are fleeing before us as at the first.' Therefore we will flee before them.", 
                "num": 6
              }, 
              {
                "text": "Then you shall rise from the ambush and seize the city, for the LORD your God will deliver it into your hand.", 
                "num": 7
              }, 
              {
                "text": "And it will be, when you have taken the city, that you shall set the city on fire. According to the commandment of the LORD you shall do. See, I have commanded you.\"", 
                "num": 8
              }, 
              {
                "text": "Joshua therefore sent them out; and they went to lie in ambush, and stayed between Bethel and Ai, on the west side of Ai; but Joshua lodged that night among the people.", 
                "num": 9
              }, 
              {
                "text": "Then Joshua rose up early in the morning and mustered the people, and went up, he and the elders of Israel, before the people to Ai.", 
                "num": 10
              }, 
              {
                "text": "And all the people of war who were with him went up and drew near; and they came before the city and camped on the north side of Ai. Now a valley lay between them and Ai.", 
                "num": 11
              }, 
              {
                "text": "So he took about five thousand men and set them in ambush between Bethel and Ai, on the west side of the city.", 
                "num": 12
              }, 
              {
                "text": "And when they had set the people, all the army that was on the north of the city, and its rear guard on the west of the city, Joshua went that night into the midst of the valley.", 
                "num": 13
              }, 
              {
                "text": "Now it happened, when the king of Ai saw it, that the men of the city hurried and rose early and went out against Israel to battle, he and all his people, at an appointed place before the plain. But he did not know that there was an ambush against him behind the city.", 
                "num": 14
              }, 
              {
                "text": "And Joshua and all Israel made as if they were beaten before them, and fled by the way of the wilderness.", 
                "num": 15
              }, 
              {
                "text": "So all the people who were in Ai were called together to pursue them. And they pursued Joshua and were drawn away from the city.", 
                "num": 16
              }, 
              {
                "text": "There was not a man left in Ai or Bethel who did not go out after Israel. So they left the city open and pursued Israel.", 
                "num": 17
              }, 
              {
                "text": "Then the LORD said to Joshua, \"Stretch out the spear that is in your hand toward Ai, for I will give it into your hand.\" And Joshua stretched out the spear that was in his hand toward the city.", 
                "num": 18
              }, 
              {
                "text": "So those in ambush arose quickly out of their place; they ran as soon as he had stretched out his hand, and they entered the city and took it, and hurried to set the city on fire.", 
                "num": 19
              }, 
              {
                "text": "And when the men of Ai looked behind them, they saw, and behold, the smoke of the city ascended to heaven. So they had no power to flee this way or that way, and the people who had fled to the wilderness turned back on the pursuers.", 
                "num": 20
              }, 
              {
                "text": "Now when Joshua and all Israel saw that the ambush had taken the city and that the smoke of the city ascended, they turned back and struck down the men of Ai.", 
                "num": 21
              }, 
              {
                "text": "Then the others came out of the city against them; so they were caught in the midst of Israel, some on this side and some on that side. And they struck them down, so that they let none of them remain or escape.", 
                "num": 22
              }, 
              {
                "text": "But the king of Ai they took alive, and brought him to Joshua.", 
                "num": 23
              }, 
              {
                "text": "And it came to pass when Israel had made an end of slaying all the inhabitants of Ai in the field, in the wilderness where they pursued them, and when they all had fallen by the edge of the sword until they were consumed, that all the Israelites returned to Ai and struck it with the edge of the sword.", 
                "num": 24
              }, 
              {
                "text": "So it was that all who fell that day, both men and women, were twelve thousand--all the people of Ai.", 
                "num": 25
              }, 
              {
                "text": "For Joshua did not draw back his hand, with which he stretched out the spear, until he had utterly destroyed all the inhabitants of Ai.", 
                "num": 26
              }, 
              {
                "text": "Only the livestock and the spoil of that city Israel took as booty for themselves, according to the word of the LORD which He had commanded Joshua.", 
                "num": 27
              }, 
              {
                "text": "So Joshua burned Ai and made it a heap forever, a desolation to this day.", 
                "num": 28
              }, 
              {
                "text": "And the king of Ai he hanged on a tree until evening. And as soon as the sun was down, Joshua commanded that they should take his corpse down from the tree, cast it at the entrance of the gate of the city, and raise over it a great heap of stones that remains to this day.", 
                "num": 29
              }, 
              {
                "text": "Now Joshua built an altar to the LORD God of Israel in Mount Ebal,", 
                "num": 30
              }, 
              {
                "text": "as Moses the servant of the LORD had commanded the children of Israel, as it is written in the Book of the Law of Moses: \"an altar of whole stones over which no man has wielded an iron tool.\" And they offered on it burnt offerings to the LORD, and sacrificed peace offerings.", 
                "num": 31
              }, 
              {
                "text": "And there, in the presence of the children of Israel, he wrote on the stones a copy of the law of Moses, which he had written.", 
                "num": 32
              }, 
              {
                "text": "Then all Israel, with their elders and officers and judges, stood on either side of the ark before the priests, the Levites, who bore the ark of the covenant of the LORD, the stranger as well as he who was born among them. Half of them were in front of Mount Gerizim and half of them in front of Mount Ebal, as Moses the servant of the LORD had commanded before, that they should bless the people of Israel.", 
                "num": 33
              }, 
              {
                "text": "And afterward he read all the words of the law, the blessings and the cursings, according to all that is written in the Book of the Law.", 
                "num": 34
              }, 
              {
                "text": "There was not a word of all that Moses had commanded which Joshua did not read before all the assembly of Israel, with the women, the little ones, and the strangers who were living among them.", 
                "num": 35
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass when all the kings who were on this side of the Jordan, in the hills and in the lowland and in all the coasts of the Great Sea toward Lebanon--the Hittite, the Amorite, the Canaanite, the Perizzite, the Hivite, and the Jebusite--heard about it,", 
                "num": 1
              }, 
              {
                "text": "that they gathered together to fight with Joshua and Israel with one accord.", 
                "num": 2
              }, 
              {
                "text": "But when the inhabitants of Gibeon heard what Joshua had done to Jericho and Ai,", 
                "num": 3
              }, 
              {
                "text": "they worked craftily, and went and pretended to be ambassadors. And they took old sacks on their donkeys, old wineskins torn and mended,", 
                "num": 4
              }, 
              {
                "text": "old and patched sandals on their feet, and old garments on themselves; and all the bread of their provision was dry and moldy.", 
                "num": 5
              }, 
              {
                "text": "And they went to Joshua, to the camp at Gilgal, and said to him and to the men of Israel, \"We have come from a far country; now therefore, make a covenant with us.\"", 
                "num": 6
              }, 
              {
                "text": "Then the men of Israel said to the Hivites, \"Perhaps you dwell among us; so how can we make a covenant with you?\"", 
                "num": 7
              }, 
              {
                "text": "But they said to Joshua, \"We are your servants.\" And Joshua said to them, \"Who are you, and where do you come from?\"", 
                "num": 8
              }, 
              {
                "text": "So they said to him: \"From a very far country your servants have come, because of the name of the LORD your God; for we have heard of His fame, and all that He did in Egypt,", 
                "num": 9
              }, 
              {
                "text": "and all that He did to the two kings of the Amorites who were beyond the Jordan--to Sihon king of Heshbon, and Og king of Bashan, who was at Ashtaroth.", 
                "num": 10
              }, 
              {
                "text": "Therefore our elders and all the inhabitants of our country spoke to us, saying, \"Take provisions with you for the journey, and go to meet them, and say to them, \"We are your servants; now therefore, make a covenant with us.\"'", 
                "num": 11
              }, 
              {
                "text": "This bread of ours we took hot for our provision from our houses on the day we departed to come to you. But now look, it is dry and moldy.", 
                "num": 12
              }, 
              {
                "text": "And these wineskins which we filled were new, and see, they are torn; and these our garments and our sandals have become old because of the very long journey.\"", 
                "num": 13
              }, 
              {
                "text": "Then the men of Israel took some of their provisions; but they did not ask counsel of the LORD.", 
                "num": 14
              }, 
              {
                "text": "So Joshua made peace with them, and made a covenant with them to let them live; and the rulers of the congregation swore to them.", 
                "num": 15
              }, 
              {
                "text": "And it happened at the end of three days, after they had made a covenant with them, that they heard that they were their neighbors who dwelt near them.", 
                "num": 16
              }, 
              {
                "text": "Then the children of Israel journeyed and came to their cities on the third day. Now their cities were Gibeon, Chephirah, Beeroth, and Kirjath Jearim.", 
                "num": 17
              }, 
              {
                "text": "But the children of Israel did not attack them, because the rulers of the congregation had sworn to them by the LORD God of Israel. And all the congregation complained against the rulers.", 
                "num": 18
              }, 
              {
                "text": "Then all the rulers said to all the congregation, \"We have sworn to them by the LORD God of Israel; now therefore, we may not touch them.", 
                "num": 19
              }, 
              {
                "text": "This we will do to them: We will let them live, lest wrath be upon us because of the oath which we swore to them.\"", 
                "num": 20
              }, 
              {
                "text": "And the rulers said to them, \"Let them live, but let them be woodcutters and water carriers for all the congregation, as the rulers had promised them.\"", 
                "num": 21
              }, 
              {
                "text": "Then Joshua called for them, and he spoke to them, saying, \"Why have you deceived us, saying, \"We are very far from you,' when you dwell near us?", 
                "num": 22
              }, 
              {
                "text": "Now therefore, you are cursed, and none of you shall be freed from being slaves--woodcutters and water carriers for the house of my God.\"", 
                "num": 23
              }, 
              {
                "text": "So they answered Joshua and said, \"Because your servants were clearly told that the LORD your God commanded His servant Moses to give you all the land, and to destroy all the inhabitants of the land from before you; therefore we were very much afraid for our lives because of you, and have done this thing.", 
                "num": 24
              }, 
              {
                "text": "And now, here we are, in your hands; do with us as it seems good and right to do to us.\"", 
                "num": 25
              }, 
              {
                "text": "So he did to them, and delivered them out of the hand of the children of Israel, so that they did not kill them.", 
                "num": 26
              }, 
              {
                "text": "And that day Joshua made them woodcutters and water carriers for the congregation and for the altar of the LORD, in the place which He would choose, even to this day.", 
                "num": 27
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass when Adoni-Zedek king of Jerusalem heard how Joshua had taken Ai and had utterly destroyed it--as he had done to Jericho and its king, so he had done to Ai and its king--and how the inhabitants of Gibeon had made peace with Israel and were among them,", 
                "num": 1
              }, 
              {
                "text": "that they feared greatly, because Gibeon was a great city, like one of the royal cities, and because it was greater than Ai, and all its men were mighty.", 
                "num": 2
              }, 
              {
                "text": "Therefore Adoni-Zedek king of Jerusalem sent to Hoham king of Hebron, Piram king of Jarmuth, Japhia king of Lachish, and Debir king of Eglon, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Come up to me and help me, that we may attack Gibeon, for it has made peace with Joshua and with the children of Israel.\"", 
                "num": 4
              }, 
              {
                "text": "Therefore the five kings of the Amorites, the king of Jerusalem, the king of Hebron, the king of Jarmuth, the king of Lachish, and the king of Eglon, gathered together and went up, they and all their armies, and camped before Gibeon and made war against it.", 
                "num": 5
              }, 
              {
                "text": "And the men of Gibeon sent to Joshua at the camp at Gilgal, saying, \"Do not forsake your servants; come up to us quickly, save us and help us, for all the kings of the Amorites who dwell in the mountains have gathered together against us.\"", 
                "num": 6
              }, 
              {
                "text": "So Joshua ascended from Gilgal, he and all the people of war with him, and all the mighty men of valor.", 
                "num": 7
              }, 
              {
                "text": "And the LORD said to Joshua, \"Do not fear them, for I have delivered them into your hand; not a man of them shall stand before you.\"", 
                "num": 8
              }, 
              {
                "text": "Joshua therefore came upon them suddenly, having marched all night from Gilgal.", 
                "num": 9
              }, 
              {
                "text": "So the LORD routed them before Israel, killed them with a great slaughter at Gibeon, chased them along the road that goes to Beth Horon, and struck them down as far as Azekah and Makkedah.", 
                "num": 10
              }, 
              {
                "text": "And it happened, as they fled before Israel and were on the descent of Beth Horon, that the LORD cast down large hailstones from heaven on them as far as Azekah, and they died. There were more who died from the hailstones than the children of Israel killed with the sword.", 
                "num": 11
              }, 
              {
                "text": "Then Joshua spoke to the LORD in the day when the LORD delivered up the Amorites before the children of Israel, and he said in the sight of Israel: \"Sun, stand still over Gibeon; And Moon, in the Valley of Aijalon.\"", 
                "num": 12
              }, 
              {
                "text": "So the sun stood still, And the moon stopped, Till the people had revenge Upon their enemies. Is this not written in the Book of Jasher? So the sun stood still in the midst of heaven, and did not hasten to go down for about a whole day.", 
                "num": 13
              }, 
              {
                "text": "And there has been no day like that, before it or after it, that the LORD heeded the voice of a man; for the LORD fought for Israel.", 
                "num": 14
              }, 
              {
                "text": "Then Joshua returned, and all Israel with him, to the camp at Gilgal.", 
                "num": 15
              }, 
              {
                "text": "But these five kings had fled and hidden themselves in a cave at Makkedah.", 
                "num": 16
              }, 
              {
                "text": "And it was told Joshua, saying, \"The five kings have been found hidden in the cave at Makkedah.\"", 
                "num": 17
              }, 
              {
                "text": "So Joshua said, \"Roll large stones against the mouth of the cave, and set men by it to guard them.", 
                "num": 18
              }, 
              {
                "text": "And do not stay there yourselves, but pursue your enemies, and attack their rear guard. Do not allow them to enter their cities, for the LORD your God has delivered them into your hand.\"", 
                "num": 19
              }, 
              {
                "text": "Then it happened, while Joshua and the children of Israel made an end of slaying them with a very great slaughter, till they had finished, that those who escaped entered fortified cities.", 
                "num": 20
              }, 
              {
                "text": "And all the people returned to the camp, to Joshua at Makkedah, in peace. No one moved his tongue against any of the children of Israel.", 
                "num": 21
              }, 
              {
                "text": "Then Joshua said, \"Open the mouth of the cave, and bring out those five kings to me from the cave.\"", 
                "num": 22
              }, 
              {
                "text": "And they did so, and brought out those five kings to him from the cave: the king of Jerusalem, the king of Hebron, the king of Jarmuth, the king of Lachish, and the king of Eglon.", 
                "num": 23
              }, 
              {
                "text": "So it was, when they brought out those kings to Joshua, that Joshua called for all the men of Israel, and said to the captains of the men of war who went with him, \"Come near, put your feet on the necks of these kings.\" And they drew near and put their feet on their necks.", 
                "num": 24
              }, 
              {
                "text": "Then Joshua said to them, \"Do not be afraid, nor be dismayed; be strong and of good courage, for thus the LORD will do to all your enemies against whom you fight.\"", 
                "num": 25
              }, 
              {
                "text": "And afterward Joshua struck them and killed them, and hanged them on five trees; and they were hanging on the trees until evening.", 
                "num": 26
              }, 
              {
                "text": "So it was at the time of the going down of the sun that Joshua commanded, and they took them down from the trees, cast them into the cave where they had been hidden, and laid large stones against the cave's mouth, which remain until this very day.", 
                "num": 27
              }, 
              {
                "text": "On that day Joshua took Makkedah, and struck it and its king with the edge of the sword. He utterly destroyed them--all the people who were in it. He let none remain. He also did to the king of Makkedah as he had done to the king of Jericho.", 
                "num": 28
              }, 
              {
                "text": "Then Joshua passed from Makkedah, and all Israel with him, to Libnah; and they fought against Libnah.", 
                "num": 29
              }, 
              {
                "text": "And the LORD also delivered it and its king into the hand of Israel; he struck it and all the people who were in it with the edge of the sword. He let none remain in it, but did to its king as he had done to the king of Jericho.", 
                "num": 30
              }, 
              {
                "text": "Then Joshua passed from Libnah, and all Israel with him, to Lachish; and they encamped against it and fought against it.", 
                "num": 31
              }, 
              {
                "text": "And the LORD delivered Lachish into the hand of Israel, who took it on the second day, and struck it and all the people who were in it with the edge of the sword, according to all that he had done to Libnah.", 
                "num": 32
              }, 
              {
                "text": "Then Horam king of Gezer came up to help Lachish; and Joshua struck him and his people, until he left him none remaining.", 
                "num": 33
              }, 
              {
                "text": "From Lachish Joshua passed to Eglon, and all Israel with him; and they encamped against it and fought against it.", 
                "num": 34
              }, 
              {
                "text": "They took it on that day and struck it with the edge of the sword; all the people who were in it he utterly destroyed that day, according to all that he had done to Lachish.", 
                "num": 35
              }, 
              {
                "text": "So Joshua went up from Eglon, and all Israel with him, to Hebron; and they fought against it.", 
                "num": 36
              }, 
              {
                "text": "And they took it and struck it with the edge of the sword--its king, all its cities, and all the people who were in it; he left none remaining, according to all that he had done to Eglon, but utterly destroyed it and all the people who were in it.", 
                "num": 37
              }, 
              {
                "text": "Then Joshua returned, and all Israel with him, to Debir; and they fought against it.", 
                "num": 38
              }, 
              {
                "text": "And he took it and its king and all its cities; they struck them with the edge of the sword and utterly destroyed all the people who were in it. He left none remaining; as he had done to Hebron, so he did to Debir and its king, as he had done also to Libnah and its king.", 
                "num": 39
              }, 
              {
                "text": "So Joshua conquered all the land: the mountain country and the South and the lowland and the wilderness slopes, and all their kings; he left none remaining, but utterly destroyed all that breathed, as the LORD God of Israel had commanded.", 
                "num": 40
              }, 
              {
                "text": "And Joshua conquered them from Kadesh Barnea as far as Gaza, and all the country of Goshen, even as far as Gibeon.", 
                "num": 41
              }, 
              {
                "text": "All these kings and their land Joshua took at one time, because the LORD God of Israel fought for Israel.", 
                "num": 42
              }, 
              {
                "text": "Then Joshua returned, and all Israel with him, to the camp at Gilgal.", 
                "num": 43
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass, when Jabin king of Hazor heard these things, that he sent to Jobab king of Madon, to the king of Shimron, to the king of Achshaph,", 
                "num": 1
              }, 
              {
                "text": "and to the kings who were from the north, in the mountains, in the plain south of Chinneroth, in the lowland, and in the heights of Dor on the west,", 
                "num": 2
              }, 
              {
                "text": "to the Canaanites in the east and in the west, the Amorite, the Hittite, the Perizzite, the Jebusite in the mountains, and the Hivite below Hermon in the land of Mizpah.", 
                "num": 3
              }, 
              {
                "text": "So they went out, they and all their armies with them, as many people as the sand that is on the seashore in multitude, with very many horses and chariots.", 
                "num": 4
              }, 
              {
                "text": "And when all these kings had met together, they came and camped together at the waters of Merom to fight against Israel.", 
                "num": 5
              }, 
              {
                "text": "But the LORD said to Joshua, \"Do not be afraid because of them, for tomorrow about this time I will deliver all of them slain before Israel. You shall hamstring their horses and burn their chariots with fire.\"", 
                "num": 6
              }, 
              {
                "text": "So Joshua and all the people of war with him came against them suddenly by the waters of Merom, and they attacked them.", 
                "num": 7
              }, 
              {
                "text": "And the LORD delivered them into the hand of Israel, who defeated them and chased them to Greater Sidon, to the Brook Misrephoth, and to the Valley of Mizpah eastward; they attacked them until they left none of them remaining.", 
                "num": 8
              }, 
              {
                "text": "So Joshua did to them as the LORD had told him: he hamstrung their horses and burned their chariots with fire.", 
                "num": 9
              }, 
              {
                "text": "Joshua turned back at that time and took Hazor, and struck its king with the sword; for Hazor was formerly the head of all those kingdoms.", 
                "num": 10
              }, 
              {
                "text": "And they struck all the people who were in it with the edge of the sword, utterly destroying them. There was none left breathing. Then he burned Hazor with fire.", 
                "num": 11
              }, 
              {
                "text": "So all the cities of those kings, and all their kings, Joshua took and struck with the edge of the sword. He utterly destroyed them, as Moses the servant of the LORD had commanded.", 
                "num": 12
              }, 
              {
                "text": "But as for the cities that stood on their mounds, Israel burned none of them, except Hazor only, which Joshua burned.", 
                "num": 13
              }, 
              {
                "text": "And all the spoil of these cities and the livestock, the children of Israel took as booty for themselves; but they struck every man with the edge of the sword until they had destroyed them, and they left none breathing.", 
                "num": 14
              }, 
              {
                "text": "As the LORD had commanded Moses his servant, so Moses commanded Joshua, and so Joshua did. He left nothing undone of all that the LORD had commanded Moses.", 
                "num": 15
              }, 
              {
                "text": "Thus Joshua took all this land: the mountain country, all the South, all the land of Goshen, the lowland, and the Jordan plain--the mountains of Israel and its lowlands,", 
                "num": 16
              }, 
              {
                "text": "from Mount Halak and the ascent to Seir, even as far as Baal Gad in the Valley of Lebanon below Mount Hermon. He captured all their kings, and struck them down and killed them.", 
                "num": 17
              }, 
              {
                "text": "Joshua made war a long time with all those kings.", 
                "num": 18
              }, 
              {
                "text": "There was not a city that made peace with the children of Israel, except the Hivites, the inhabitants of Gibeon. All the others they took in battle.", 
                "num": 19
              }, 
              {
                "text": "For it was of the LORD to harden their hearts, that they should come against Israel in battle, that He might utterly destroy them, and that they might receive no mercy, but that He might destroy them, as the LORD had commanded Moses.", 
                "num": 20
              }, 
              {
                "text": "And at that time Joshua came and cut off the Anakim from the mountains: from Hebron, from Debir, from Anab, from all the mountains of Judah, and from all the mountains of Israel; Joshua utterly destroyed them with their cities.", 
                "num": 21
              }, 
              {
                "text": "None of the Anakim were left in the land of the children of Israel; they remained only in Gaza, in Gath, and in Ashdod.", 
                "num": 22
              }, 
              {
                "text": "So Joshua took the whole land, according to all that the LORD had said to Moses; and Joshua gave it as an inheritance to Israel according to their divisions by their tribes. Then the land rested from war.", 
                "num": 23
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "These are the kings of the land whom the children of Israel defeated, and whose land they possessed on the other side of the Jordan toward the rising of the sun, from the River Arnon to Mount Hermon, and all the eastern Jordan plain:", 
                "num": 1
              }, 
              {
                "text": "One king was Sihon king of the Amorites, who dwelt in Heshbon and ruled half of Gilead, from Aroer, which is on the bank of the River Arnon, from the middle of that river, even as far as the River Jabbok, which is the border of the Ammonites,", 
                "num": 2
              }, 
              {
                "text": "and the eastern Jordan plain from the Sea of Chinneroth as far as the Sea of the Arabah (the Salt Sea), the road to Beth Jeshimoth, and southward below the slopes of Pisgah.", 
                "num": 3
              }, 
              {
                "text": "The other king was Og king of Bashan and his territory, who was of the remnant of the giants, who dwelt at Ashtaroth and at Edrei,", 
                "num": 4
              }, 
              {
                "text": "and reigned over Mount Hermon, over Salcah, over all Bashan, as far as the border of the Geshurites and the Maachathites, and over half of Gilead to the border of Sihon king of Heshbon.", 
                "num": 5
              }, 
              {
                "text": "These Moses the servant of the LORD and the children of Israel had conquered; and Moses the servant of the LORD had given it as a possession to the Reubenites, the Gadites, and half the tribe of Manasseh.", 
                "num": 6
              }, 
              {
                "text": "And these are the kings of the country which Joshua and the children of Israel conquered on this side of the Jordan, on the west, from Baal Gad in the Valley of Lebanon as far as Mount Halak and the ascent to Seir, which Joshua gave to the tribes of Israel as a possession according to their divisions,", 
                "num": 7
              }, 
              {
                "text": "in the mountain country, in the lowlands, in the Jordan plain, in the slopes, in the wilderness, and in the South--the Hittites, the Amorites, the Canaanites, the Perizzites, the Hivites, and the Jebusites:", 
                "num": 8
              }, 
              {
                "text": "the king of Jericho, one; the king of Ai, which is beside Bethel, one;", 
                "num": 9
              }, 
              {
                "text": "the king of Jerusalem, one; the king of Hebron, one;", 
                "num": 10
              }, 
              {
                "text": "the king of Jarmuth, one; the king of Lachish, one;", 
                "num": 11
              }, 
              {
                "text": "the king of Eglon, one; the king of Gezer, one;", 
                "num": 12
              }, 
              {
                "text": "the king of Debir, one; the king of Geder, one;", 
                "num": 13
              }, 
              {
                "text": "the king of Hormah, one; the king of Arad, one;", 
                "num": 14
              }, 
              {
                "text": "the king of Libnah, one; the king of Adullam, one;", 
                "num": 15
              }, 
              {
                "text": "the king of Makkedah, one; the king of Bethel, one;", 
                "num": 16
              }, 
              {
                "text": "the king of Tappuah, one; the king of Hepher, one;", 
                "num": 17
              }, 
              {
                "text": "the king of Aphek, one; the king of Lasharon, one;", 
                "num": 18
              }, 
              {
                "text": "the king of Madon, one; the king of Hazor, one;", 
                "num": 19
              }, 
              {
                "text": "the king of Shimron Meron, one; the king of Achshaph, one;", 
                "num": 20
              }, 
              {
                "text": "the king of Taanach, one; the king of Megiddo, one;", 
                "num": 21
              }, 
              {
                "text": "the king of Kedesh, one; the king of Jokneam in Carmel, one;", 
                "num": 22
              }, 
              {
                "text": "the king of Dor in the heights of Dor, one; the king of the people of Gilgal, one;", 
                "num": 23
              }, 
              {
                "text": "the king of Tirzah, one--all the kings, thirty-one.", 
                "num": 24
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Now Joshua was old, advanced in years. And the LORD said to him: \"You are old, advanced in years, and there remains very much land yet to be possessed.", 
                "num": 1
              }, 
              {
                "text": "This is the land that yet remains: all the territory of the Philistines and all that of the Geshurites,", 
                "num": 2
              }, 
              {
                "text": "from Sihor, which is east of Egypt, as far as the border of Ekron northward (which is counted as Canaanite); the five lords of the Philistines--the Gazites, the Ashdodites, the Ashkelonites, the Gittites, and the Ekronites; also the Avites;", 
                "num": 3
              }, 
              {
                "text": "from the south, all the land of the Canaanites, and Mearah that belongs to the Sidonians as far as Aphek, to the border of the Amorites;", 
                "num": 4
              }, 
              {
                "text": "the land of the Gebalites, and all Lebanon, toward the sunrise, from Baal Gad below Mount Hermon as far as the entrance to Hamath;", 
                "num": 5
              }, 
              {
                "text": "all the inhabitants of the mountains from Lebanon as far as the Brook Misrephoth, and all the Sidonians--them I will drive out from before the children of Israel; only divide it by lot to Israel as an inheritance, as I have commanded you.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, divide this land as an inheritance to the nine tribes and half the tribe of Manasseh.\"", 
                "num": 7
              }, 
              {
                "text": "With the other half-tribe the Reubenites and the Gadites received their inheritance, which Moses had given them, beyond the Jordan eastward, as Moses the servant of the LORD had given them:", 
                "num": 8
              }, 
              {
                "text": "from Aroer which is on the bank of the River Arnon, and the town that is in the midst of the ravine, and all the plain of Medeba as far as Dibon;", 
                "num": 9
              }, 
              {
                "text": "all the cities of Sihon king of the Amorites, who reigned in Heshbon, as far as the border of the children of Ammon;", 
                "num": 10
              }, 
              {
                "text": "Gilead, and the border of the Geshurites and Maachathites, all Mount Hermon, and all Bashan as far as Salcah;", 
                "num": 11
              }, 
              {
                "text": "all the kingdom of Og in Bashan, who reigned in Ashtaroth and Edrei, who remained of the remnant of the giants; for Moses had defeated and cast out these.", 
                "num": 12
              }, 
              {
                "text": "Nevertheless the children of Israel did not drive out the Geshurites or the Maachathites, but the Geshurites and the Maachathites dwell among the Israelites until this day.", 
                "num": 13
              }, 
              {
                "text": "Only to the tribe of Levi he had given no inheritance; the sacrifices of the LORD God of Israel made by fire are their inheritance, as He said to them.", 
                "num": 14
              }, 
              {
                "text": "And Moses had given to the tribe of the children of Reuben an inheritance according to their families.", 
                "num": 15
              }, 
              {
                "text": "Their territory was from Aroer, which is on the bank of the River Arnon, and the city that is in the midst of the ravine, and all the plain by Medeba;", 
                "num": 16
              }, 
              {
                "text": "Heshbon and all its cities that are in the plain: Dibon, Bamoth Baal, Beth Baal Meon,", 
                "num": 17
              }, 
              {
                "text": "Jahaza, Kedemoth, Mephaath,", 
                "num": 18
              }, 
              {
                "text": "Kirjathaim, Sibmah, Zereth Shahar on the mountain of the valley,", 
                "num": 19
              }, 
              {
                "text": "Beth Peor, the slopes of Pisgah, and Beth Jeshimoth--", 
                "num": 20
              }, 
              {
                "text": "all the cities of the plain and all the kingdom of Sihon king of the Amorites, who reigned in Heshbon, whom Moses had struck with the princes of Midian: Evi, Rekem, Zur, Hur, and Reba, who were princes of Sihon dwelling in the country.", 
                "num": 21
              }, 
              {
                "text": "The children of Israel also killed with the sword Balaam the son of Beor, the soothsayer, among those who were killed by them.", 
                "num": 22
              }, 
              {
                "text": "And the border of the children of Reuben was the bank of the Jordan. This was the inheritance of the children of Reuben according to their families, the cities and their villages.", 
                "num": 23
              }, 
              {
                "text": "Moses also had given an inheritance to the tribe of Gad, to the children of Gad according to their families.", 
                "num": 24
              }, 
              {
                "text": "Their territory was Jazer, and all the cities of Gilead, and half the land of the Ammonites as far as Aroer, which is before Rabbah,", 
                "num": 25
              }, 
              {
                "text": "and from Heshbon to Ramath Mizpah and Betonim, and from Mahanaim to the border of Debir,", 
                "num": 26
              }, 
              {
                "text": "and in the valley Beth Haram, Beth Nimrah, Succoth, and Zaphon, the rest of the kingdom of Sihon king of Heshbon, with the Jordan as its border, as far as the edge of the Sea of Chinnereth, on the other side of the Jordan eastward.", 
                "num": 27
              }, 
              {
                "text": "This is the inheritance of the children of Gad according to their families, the cities and their villages.", 
                "num": 28
              }, 
              {
                "text": "Moses also had given an inheritance to half the tribe of Manasseh; it was for half the tribe of the children of Manasseh according to their families:", 
                "num": 29
              }, 
              {
                "text": "Their territory was from Mahanaim, all Bashan, all the kingdom of Og king of Bashan, and all the towns of Jair which are in Bashan, sixty cities;", 
                "num": 30
              }, 
              {
                "text": "half of Gilead, and Ashtaroth and Edrei, cities of the kingdom of Og in Bashan, were for the children of Machir the son of Manasseh, for half of the children of Machir according to their families.", 
                "num": 31
              }, 
              {
                "text": "These are the areas which Moses had distributed as an inheritance in the plains of Moab on the other side of the Jordan, by Jericho eastward.", 
                "num": 32
              }, 
              {
                "text": "But to the tribe of Levi Moses had given no inheritance; the LORD God of Israel was their inheritance, as He had said to them.", 
                "num": 33
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "These are the areas which the children of Israel inherited in the land of Canaan, which Eleazar the priest, Joshua the son of Nun, and the heads of the fathers of the tribes of the children of Israel distributed as an inheritance to them.", 
                "num": 1
              }, 
              {
                "text": "Their inheritance was by lot, as the LORD had commanded by the hand of Moses, for the nine tribes and the half-tribe.", 
                "num": 2
              }, 
              {
                "text": "For Moses had given the inheritance of the two tribes and the half-tribe on the other side of the Jordan; but to the Levites he had given no inheritance among them.", 
                "num": 3
              }, 
              {
                "text": "For the children of Joseph were two tribes: Manasseh and Ephraim. And they gave no part to the Levites in the land, except cities to dwell in, with their common-lands for their livestock and their property.", 
                "num": 4
              }, 
              {
                "text": "As the LORD had commanded Moses, so the children of Israel did; and they divided the land.", 
                "num": 5
              }, 
              {
                "text": "Then the children of Judah came to Joshua in Gilgal. And Caleb the son of Jephunneh the Kenizzite said to him: \"You know the word which the LORD said to Moses the man of God concerning you and me in Kadesh Barnea.", 
                "num": 6
              }, 
              {
                "text": "I was forty years old when Moses the servant of the LORD sent me from Kadesh Barnea to spy out the land, and I brought back word to him as it was in my heart.", 
                "num": 7
              }, 
              {
                "text": "Nevertheless my brethren who went up with me made the heart of the people melt, but I wholly followed the LORD my God.", 
                "num": 8
              }, 
              {
                "text": "So Moses swore on that day, saying, \"Surely the land where your foot has trodden shall be your inheritance and your children's forever, because you have wholly followed the LORD my God.'", 
                "num": 9
              }, 
              {
                "text": "And now, behold, the LORD has kept me alive, as He said, these forty-five years, ever since the LORD spoke this word to Moses while Israel wandered in the wilderness; and now, here I am this day, eighty-five years old.", 
                "num": 10
              }, 
              {
                "text": "As yet I am as strong this day as on the day that Moses sent me; just as my strength was then, so now is my strength for war, both for going out and for coming in.", 
                "num": 11
              }, 
              {
                "text": "Now therefore, give me this mountain of which the LORD spoke in that day; for you heard in that day how the Anakim were there, and that the cities were great and fortified. It may be that the LORD will be with me, and I shall be able to drive them out as the LORD said.\"", 
                "num": 12
              }, 
              {
                "text": "And Joshua blessed him, and gave Hebron to Caleb the son of Jephunneh as an inheritance.", 
                "num": 13
              }, 
              {
                "text": "Hebron therefore became the inheritance of Caleb the son of Jephunneh the Kenizzite to this day, because he wholly followed the LORD God of Israel.", 
                "num": 14
              }, 
              {
                "text": "And the name of Hebron formerly was Kirjath Arba (Arba was the greatest man among the Anakim). Then the land had rest from war.", 
                "num": 15
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "So this was the lot of the tribe of the children of Judah according to their families: The border of Edom at the Wilderness of Zin southward was the extreme southern boundary.", 
                "num": 1
              }, 
              {
                "text": "And their southern border began at the shore of the Salt Sea, from the bay that faces southward.", 
                "num": 2
              }, 
              {
                "text": "Then it went out to the southern side of the Ascent of Akrabbim, passed along to Zin, ascended on the south side of Kadesh Barnea, passed along to Hezron, went up to Adar, and went around to Karkaa.", 
                "num": 3
              }, 
              {
                "text": "From there it passed toward Azmon and went out to the Brook of Egypt; and the border ended at the sea. This shall be your southern border.", 
                "num": 4
              }, 
              {
                "text": "The east border was the Salt Sea as far as the mouth of the Jordan. And the border on the northern quarter began at the bay of the sea at the mouth of the Jordan.", 
                "num": 5
              }, 
              {
                "text": "The border went up to Beth Hoglah and passed north of Beth Arabah; and the border went up to the stone of Bohan the son of Reuben.", 
                "num": 6
              }, 
              {
                "text": "Then the border went up toward Debir from the Valley of Achor, and it turned northward toward Gilgal, which is before the Ascent of Adummim, which is on the south side of the valley. The border continued toward the waters of En Shemesh and ended at En Rogel.", 
                "num": 7
              }, 
              {
                "text": "And the border went up by the Valley of the Son of Hinnom to the southern slope of the Jebusite city (which is Jerusalem). The border went up to the top of the mountain that lies before the Valley of Hinnom westward, which is at the end of the Valley of Rephaim northward.", 
                "num": 8
              }, 
              {
                "text": "Then the border went around from the top of the hill to the fountain of the water of Nephtoah, and extended to the cities of Mount Ephron. And the border went around to Baalah (which is Kirjath Jearim).", 
                "num": 9
              }, 
              {
                "text": "Then the border turned westward from Baalah to Mount Seir, passed along to the side of Mount Jearim on the north (which is Chesalon), went down to Beth Shemesh, and passed on to Timnah.", 
                "num": 10
              }, 
              {
                "text": "And the border went out to the side of Ekron northward. Then the border went around to Shicron, passed along to Mount Baalah, and extended to Jabneel; and the border ended at the sea.", 
                "num": 11
              }, 
              {
                "text": "The west border was the coastline of the Great Sea. This is the boundary of the children of Judah all around according to their families.", 
                "num": 12
              }, 
              {
                "text": "Now to Caleb the son of Jephunneh he gave a share among the children of Judah, according to the commandment of the LORD to Joshua, namely, Kirjath Arba, which is Hebron (Arba was the father of Anak).", 
                "num": 13
              }, 
              {
                "text": "Caleb drove out the three sons of Anak from there: Sheshai, Ahiman, and Talmai, the children of Anak.", 
                "num": 14
              }, 
              {
                "text": "Then he went up from there to the inhabitants of Debir (formerly the name of Debir was Kirjath Sepher).", 
                "num": 15
              }, 
              {
                "text": "And Caleb said, \"He who attacks Kirjath Sepher and takes it, to him I will give Achsah my daughter as wife.\"", 
                "num": 16
              }, 
              {
                "text": "So Othniel the son of Kenaz, the brother of Caleb, took it; and he gave him Achsah his daughter as wife.", 
                "num": 17
              }, 
              {
                "text": "Now it was so, when she came to him, that she persuaded him to ask her father for a field. So she dismounted from her donkey, and Caleb said to her, \"What do you wish?\"", 
                "num": 18
              }, 
              {
                "text": "She answered, \"Give me a blessing; since you have given me land in the South, give me also springs of water.\" So he gave her the upper springs and the lower springs.", 
                "num": 19
              }, 
              {
                "text": "This was the inheritance of the tribe of the children of Judah according to their families:", 
                "num": 20
              }, 
              {
                "text": "The cities at the limits of the tribe of the children of Judah, toward the border of Edom in the South, were Kabzeel, Eder, Jagur,", 
                "num": 21
              }, 
              {
                "text": "Kinah, Dimonah, Adadah,", 
                "num": 22
              }, 
              {
                "text": "Kedesh, Hazor, Ithnan,", 
                "num": 23
              }, 
              {
                "text": "Ziph, Telem, Bealoth,", 
                "num": 24
              }, 
              {
                "text": "Hazor, Hadattah, Kerioth, Hezron (which is Hazor),", 
                "num": 25
              }, 
              {
                "text": "Amam, Shema, Moladah,", 
                "num": 26
              }, 
              {
                "text": "Hazar Gaddah, Heshmon, Beth Pelet,", 
                "num": 27
              }, 
              {
                "text": "Hazar Shual, Beersheba, Bizjothjah,", 
                "num": 28
              }, 
              {
                "text": "Baalah, Ijim, Ezem,", 
                "num": 29
              }, 
              {
                "text": "Eltolad, Chesil, Hormah,", 
                "num": 30
              }, 
              {
                "text": "Ziklag, Madmannah, Sansannah,", 
                "num": 31
              }, 
              {
                "text": "Lebaoth, Shilhim, Ain, and Rimmon: all the cities are twenty-nine, with their villages.", 
                "num": 32
              }, 
              {
                "text": "In the lowland: Eshtaol, Zorah, Ashnah,", 
                "num": 33
              }, 
              {
                "text": "Zanoah, En Gannim, Tappuah, Enam,", 
                "num": 34
              }, 
              {
                "text": "Jarmuth, Adullam, Socoh, Azekah,", 
                "num": 35
              }, 
              {
                "text": "Sharaim, Adithaim, Gederah, and Gederothaim: fourteen cities with their villages;", 
                "num": 36
              }, 
              {
                "text": "Zenan, Hadashah, Migdal Gad,", 
                "num": 37
              }, 
              {
                "text": "Dilean, Mizpah, Joktheel,", 
                "num": 38
              }, 
              {
                "text": "Lachish, Bozkath, Eglon,", 
                "num": 39
              }, 
              {
                "text": "Cabbon, Lahmas, Kithlish,", 
                "num": 40
              }, 
              {
                "text": "Gederoth, Beth Dagon, Naamah, and Makkedah: sixteen cities with their villages;", 
                "num": 41
              }, 
              {
                "text": "Libnah, Ether, Ashan,", 
                "num": 42
              }, 
              {
                "text": "Jiphtah, Ashnah, Nezib,", 
                "num": 43
              }, 
              {
                "text": "Keilah, Achzib, and Mareshah: nine cities with their villages;", 
                "num": 44
              }, 
              {
                "text": "Ekron, with its towns and villages;", 
                "num": 45
              }, 
              {
                "text": "from Ekron to the sea, all that lay near Ashdod, with their villages;", 
                "num": 46
              }, 
              {
                "text": "Ashdod with its towns and villages, Gaza with its towns and villages--as far as the Brook of Egypt and the Great Sea with its coastline.", 
                "num": 47
              }, 
              {
                "text": "And in the mountain country: Shamir, Jattir, Sochoh,", 
                "num": 48
              }, 
              {
                "text": "Dannah, Kirjath Sannah (which is Debir),", 
                "num": 49
              }, 
              {
                "text": "Anab, Eshtemoh, Anim,", 
                "num": 50
              }, 
              {
                "text": "Goshen, Holon, and Giloh: eleven cities with their villages;", 
                "num": 51
              }, 
              {
                "text": "Arab, Dumah, Eshean,", 
                "num": 52
              }, 
              {
                "text": "Janum, Beth Tappuah, Aphekah,", 
                "num": 53
              }, 
              {
                "text": "Humtah, Kirjath Arba (which is Hebron), and Zior: nine cities with their villages;", 
                "num": 54
              }, 
              {
                "text": "Maon, Carmel, Ziph, Juttah,", 
                "num": 55
              }, 
              {
                "text": "Jezreel, Jokdeam, Zanoah,", 
                "num": 56
              }, 
              {
                "text": "Kain, Gibeah, and Timnah: ten cities with their villages;", 
                "num": 57
              }, 
              {
                "text": "Halhul, Beth Zur, Gedor,", 
                "num": 58
              }, 
              {
                "text": "Maarath, Beth Anoth, and Eltekon: six cities with their villages;", 
                "num": 59
              }, 
              {
                "text": "Kirjath Baal (which is Kirjath Jearim) and Rabbah: two cities with their villages.", 
                "num": 60
              }, 
              {
                "text": "In the wilderness: Beth Arabah, Middin, Secacah,", 
                "num": 61
              }, 
              {
                "text": "Nibshan, the City of Salt, and En Gedi: six cities with their villages.", 
                "num": 62
              }, 
              {
                "text": "As for the Jebusites, the inhabitants of Jerusalem, the children of Judah could not drive them out; but the Jebusites dwell with the children of Judah at Jerusalem to this day.", 
                "num": 63
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "The lot fell to the children of Joseph from the Jordan, by Jericho, to the waters of Jericho on the east, to the wilderness that goes up from Jericho through the mountains to Bethel,", 
                "num": 1
              }, 
              {
                "text": "then went out from Bethel to Luz, passed along to the border of the Archites at Ataroth,", 
                "num": 2
              }, 
              {
                "text": "and went down westward to the boundary of the Japhletites, as far as the boundary of Lower Beth Horon to Gezer; and it ended at the sea.", 
                "num": 3
              }, 
              {
                "text": "So the children of Joseph, Manasseh and Ephraim, took their inheritance.", 
                "num": 4
              }, 
              {
                "text": "The border of the children of Ephraim, according to their families, was thus: The border of their inheritance on the east side was Ataroth Addar as far as Upper Beth Horon.", 
                "num": 5
              }, 
              {
                "text": "And the border went out toward the sea on the north side of Michmethath; then the border went around eastward to Taanath Shiloh, and passed by it on the east of Janohah.", 
                "num": 6
              }, 
              {
                "text": "Then it went down from Janohah to Ataroth and Naarah, reached to Jericho, and came out at the Jordan.", 
                "num": 7
              }, 
              {
                "text": "The border went out from Tappuah westward to the Brook Kanah, and it ended at the sea. This was the inheritance of the tribe of the children of Ephraim according to their families.", 
                "num": 8
              }, 
              {
                "text": "The separate cities for the children of Ephraim were among the inheritance of the children of Manasseh, all the cities with their villages.", 
                "num": 9
              }, 
              {
                "text": "And they did not drive out the Canaanites who dwelt in Gezer; but the Canaanites dwell among the Ephraimites to this day and have become forced laborers.", 
                "num": 10
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "There was also a lot for the tribe of Manasseh, for he was the firstborn of Joseph: namely for Machir the firstborn of Manasseh, the father of Gilead, because he was a man of war; therefore he was given Gilead and Bashan.", 
                "num": 1
              }, 
              {
                "text": "And there was a lot for the rest of the children of Manasseh according to their families: for the children of Abiezer, the children of Helek, the children of Asriel, the children of Shechem, the children of Hepher, and the children of Shemida; these were the male children of Manasseh the son of Joseph according to their families.", 
                "num": 2
              }, 
              {
                "text": "But Zelophehad the son of Hepher, the son of Gilead, the son of Machir, the son of Manasseh, had no sons, but only daughters. And these are the names of his daughters: Mahlah, Noah, Hoglah, Milcah, and Tirzah.", 
                "num": 3
              }, 
              {
                "text": "And they came near before Eleazar the priest, before Joshua the son of Nun, and before the rulers, saying, \"The LORD commanded Moses to give us an inheritance among our brothers.\" Therefore, according to the commandment of the LORD, he gave them an inheritance among their father's brothers.", 
                "num": 4
              }, 
              {
                "text": "Ten shares fell to Manasseh, besides the land of Gilead and Bashan, which were on the other side of the Jordan,", 
                "num": 5
              }, 
              {
                "text": "because the daughters of Manasseh received an inheritance among his sons; and the rest of Manasseh's sons had the land of Gilead.", 
                "num": 6
              }, 
              {
                "text": "And the territory of Manasseh was from Asher to Michmethath, that lies east of Shechem; and the border went along south to the inhabitants of En Tappuah.", 
                "num": 7
              }, 
              {
                "text": "Manasseh had the land of Tappuah, but Tappuah on the border of Manasseh belonged to the children of Ephraim.", 
                "num": 8
              }, 
              {
                "text": "And the border descended to the Brook Kanah, southward to the brook. These cities of Ephraim are among the cities of Manasseh. The border of Manasseh was on the north side of the brook; and it ended at the sea.", 
                "num": 9
              }, 
              {
                "text": "Southward it was Ephraim's, northward it was Manasseh's, and the sea was its border. Manasseh's territory was adjoining Asher on the north and Issachar on the east.", 
                "num": 10
              }, 
              {
                "text": "And in Issachar and in Asher, Manasseh had Beth Shean and its towns, Ibleam and its towns, the inhabitants of Dor and its towns, the inhabitants of En Dor and its towns, the inhabitants of Taanach and its towns, and the inhabitants of Megiddo and its towns--three hilly regions.", 
                "num": 11
              }, 
              {
                "text": "Yet the children of Manasseh could not drive out the inhabitants of those cities, but the Canaanites were determined to dwell in that land.", 
                "num": 12
              }, 
              {
                "text": "And it happened, when the children of Israel grew strong, that they put the Canaanites to forced labor, but did not utterly drive them out.", 
                "num": 13
              }, 
              {
                "text": "Then the children of Joseph spoke to Joshua, saying, \"Why have you given us only one lot and one share to inherit, since we are a great people, inasmuch as the LORD has blessed us until now?\"", 
                "num": 14
              }, 
              {
                "text": "So Joshua answered them, \"If you are a great people, then go up to the forest country and clear a place for yourself there in the land of the Perizzites and the giants, since the mountains of Ephraim are too confined for you.\"", 
                "num": 15
              }, 
              {
                "text": "But the children of Joseph said, \"The mountain country is not enough for us; and all the Canaanites who dwell in the land of the valley have chariots of iron, both those who are of Beth Shean and its towns and those who are of the Valley of Jezreel.\"", 
                "num": 16
              }, 
              {
                "text": "And Joshua spoke to the house of Joseph--to Ephraim and Manasseh--saying, \"You are a great people and have great power; you shall not have only one lot,", 
                "num": 17
              }, 
              {
                "text": "but the mountain country shall be yours. Although it is wooded, you shall cut it down, and its farthest extent shall be yours; for you shall drive out the Canaanites, though they have iron chariots and are strong.\"", 
                "num": 18
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Now the whole congregation of the children of Israel assembled together at Shiloh, and set up the tabernacle of meeting there. And the land was subdued before them.", 
                "num": 1
              }, 
              {
                "text": "But there remained among the children of Israel seven tribes which had not yet received their inheritance.", 
                "num": 2
              }, 
              {
                "text": "Then Joshua said to the children of Israel: \"How long will you neglect to go and possess the land which the LORD God of your fathers has given you?", 
                "num": 3
              }, 
              {
                "text": "Pick out from among you three men for each tribe, and I will send them; they shall rise and go through the land, survey it according to their inheritance, and come back to me.", 
                "num": 4
              }, 
              {
                "text": "And they shall divide it into seven parts. Judah shall remain in their territory on the south, and the house of Joseph shall remain in their territory on the north.", 
                "num": 5
              }, 
              {
                "text": "You shall therefore survey the land in seven parts and bring the survey here to me, that I may cast lots for you here before the LORD our God.", 
                "num": 6
              }, 
              {
                "text": "But the Levites have no part among you, for the priesthood of the LORD is their inheritance. And Gad, Reuben, and half the tribe of Manasseh have received their inheritance beyond the Jordan on the east, which Moses the servant of the LORD gave them.\"", 
                "num": 7
              }, 
              {
                "text": "Then the men arose to go away; and Joshua charged those who went to survey the land, saying, \"Go, walk through the land, survey it, and come back to me, that I may cast lots for you here before the LORD in Shiloh.\"", 
                "num": 8
              }, 
              {
                "text": "So the men went, passed through the land, and wrote the survey in a book in seven parts by cities; and they came to Joshua at the camp in Shiloh.", 
                "num": 9
              }, 
              {
                "text": "Then Joshua cast lots for them in Shiloh before the LORD, and there Joshua divided the land to the children of Israel according to their divisions.", 
                "num": 10
              }, 
              {
                "text": "Now the lot of the tribe of the children of Benjamin came up according to their families, and the territory of their lot came out between the children of Judah and the children of Joseph.", 
                "num": 11
              }, 
              {
                "text": "Their border on the north side began at the Jordan, and the border went up to the side of Jericho on the north, and went up through the mountains westward; it ended at the Wilderness of Beth Aven.", 
                "num": 12
              }, 
              {
                "text": "The border went over from there toward Luz, to the side of Luz (which is Bethel) southward; and the border descended to Ataroth Addar, near the hill that lies on the south side of Lower Beth Horon.", 
                "num": 13
              }, 
              {
                "text": "Then the border extended around the west side to the south, from the hill that lies before Beth Horon southward; and it ended at Kirjath Baal (which is Kirjath Jearim), a city of the children of Judah. This was the west side.", 
                "num": 14
              }, 
              {
                "text": "The south side began at the end of Kirjath Jearim, and the border extended on the west and went out to the spring of the waters of Nephtoah.", 
                "num": 15
              }, 
              {
                "text": "Then the border came down to the end of the mountain that lies before the Valley of the Son of Hinnom, which is in the Valley of the Rephaim on the north, descended to the Valley of Hinnom, to the side of the Jebusite city on the south, and descended to En Rogel.", 
                "num": 16
              }, 
              {
                "text": "And it went around from the north, went out to En Shemesh, and extended toward Geliloth, which is before the Ascent of Adummim, and descended to the stone of Bohan the son of Reuben.", 
                "num": 17
              }, 
              {
                "text": "Then it passed along toward the north side of Arabah, and went down to Arabah.", 
                "num": 18
              }, 
              {
                "text": "And the border passed along to the north side of Beth Hoglah; then the border ended at the north bay at the Salt Sea, at the south end of the Jordan. This was the southern boundary.", 
                "num": 19
              }, 
              {
                "text": "The Jordan was its border on the east side. This was the inheritance of the children of Benjamin, according to its boundaries all around, according to their families.", 
                "num": 20
              }, 
              {
                "text": "Now the cities of the tribe of the children of Benjamin, according to their families, were Jericho, Beth Hoglah, Emek Keziz,", 
                "num": 21
              }, 
              {
                "text": "Beth Arabah, Zemaraim, Bethel,", 
                "num": 22
              }, 
              {
                "text": "Avim, Parah, Ophrah,", 
                "num": 23
              }, 
              {
                "text": "Chephar Haammoni, Ophni, and Gaba: twelve cities with their villages;", 
                "num": 24
              }, 
              {
                "text": "Gibeon, Ramah, Beeroth,", 
                "num": 25
              }, 
              {
                "text": "Mizpah, Chephirah, Mozah,", 
                "num": 26
              }, 
              {
                "text": "Rekem, Irpeel, Taralah,", 
                "num": 27
              }, 
              {
                "text": "Zelah, Eleph, Jebus (which is Jerusalem), Gibeath, and Kirjath: fourteen cities with their villages. This was the inheritance of the children of Benjamin according to their families.", 
                "num": 28
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "The second lot came out for Simeon, for the tribe of the children of Simeon according to their families. And their inheritance was within the inheritance of the children of Judah.", 
                "num": 1
              }, 
              {
                "text": "They had in their inheritance Beersheba (Sheba), Moladah,", 
                "num": 2
              }, 
              {
                "text": "Hazar Shual, Balah, Ezem,", 
                "num": 3
              }, 
              {
                "text": "Eltolad, Bethul, Hormah,", 
                "num": 4
              }, 
              {
                "text": "Ziklag, Beth Marcaboth, Hazar Susah,", 
                "num": 5
              }, 
              {
                "text": "Beth Lebaoth, and Sharuhen: thirteen cities and their villages;", 
                "num": 6
              }, 
              {
                "text": "Ain, Rimmon, Ether, and Ashan: four cities and their villages;", 
                "num": 7
              }, 
              {
                "text": "and all the villages that were all around these cities as far as Baalath Beer, Ramah of the South. This was the inheritance of the tribe of the children of Simeon according to their families.", 
                "num": 8
              }, 
              {
                "text": "The inheritance of the children of Simeon was included in the share of the children of Judah, for the share of the children of Judah was too much for them. Therefore the children of Simeon had their inheritance within the inheritance of that people.", 
                "num": 9
              }, 
              {
                "text": "The third lot came out for the children of Zebulun according to their families, and the border of their inheritance was as far as Sarid.", 
                "num": 10
              }, 
              {
                "text": "Their border went toward the west and to Maralah, went to Dabbasheth, and extended along the brook that is east of Jokneam.", 
                "num": 11
              }, 
              {
                "text": "Then from Sarid it went eastward toward the sunrise along the border of Chisloth Tabor, and went out toward Daberath, bypassing Japhia.", 
                "num": 12
              }, 
              {
                "text": "And from there it passed along on the east of Gath Hepher, toward Eth Kazin, and extended to Rimmon, which borders on Neah.", 
                "num": 13
              }, 
              {
                "text": "Then the border went around it on the north side of Hannathon, and it ended in the Valley of Jiphthah El.", 
                "num": 14
              }, 
              {
                "text": "Included were Kattath, Nahallal, Shimron, Idalah, and Bethlehem: twelve cities with their villages.", 
                "num": 15
              }, 
              {
                "text": "This was the inheritance of the children of Zebulun according to their families, these cities with their villages.", 
                "num": 16
              }, 
              {
                "text": "The fourth lot came out to Issachar, for the children of Issachar according to their families.", 
                "num": 17
              }, 
              {
                "text": "And their territory went to Jezreel, and included Chesulloth, Shunem,", 
                "num": 18
              }, 
              {
                "text": "Haphraim, Shion, Anaharath,", 
                "num": 19
              }, 
              {
                "text": "Rabbith, Kishion, Abez,", 
                "num": 20
              }, 
              {
                "text": "Remeth, En Gannim, En Haddah, and Beth Pazzez.", 
                "num": 21
              }, 
              {
                "text": "And the border reached to Tabor, Shahazimah, and Beth Shemesh; their border ended at the Jordan: sixteen cities with their villages.", 
                "num": 22
              }, 
              {
                "text": "This was the inheritance of the tribe of the children of Issachar according to their families, the cities and their villages.", 
                "num": 23
              }, 
              {
                "text": "The fifth lot came out for the tribe of the children of Asher according to their families.", 
                "num": 24
              }, 
              {
                "text": "And their territory included Helkath, Hali, Beten, Achshaph,", 
                "num": 25
              }, 
              {
                "text": "Alammelech, Amad, and Mishal; it reached to Mount Carmel westward, along the Brook Shihor Libnath.", 
                "num": 26
              }, 
              {
                "text": "It turned toward the sunrise to Beth Dagon; and it reached to Zebulun and to the Valley of Jiphthah El, then northward beyond Beth Emek and Neiel, bypassing Cabul which was on the left,", 
                "num": 27
              }, 
              {
                "text": "including Ebron, Rehob, Hammon, and Kanah, as far as Greater Sidon.", 
                "num": 28
              }, 
              {
                "text": "And the border turned to Ramah and to the fortified city of Tyre; then the border turned to Hosah, and ended at the sea by the region of Achzib.", 
                "num": 29
              }, 
              {
                "text": "Also Ummah, Aphek, and Rehob were included: twenty-two cities with their villages.", 
                "num": 30
              }, 
              {
                "text": "This was the inheritance of the tribe of the children of Asher according to their families, these cities with their villages.", 
                "num": 31
              }, 
              {
                "text": "The sixth lot came out to the children of Naphtali, for the children of Naphtali according to their families.", 
                "num": 32
              }, 
              {
                "text": "And their border began at Heleph, enclosing the territory from the terebinth tree in Zaanannim, Adami Nekeb, and Jabneel, as far as Lakkum; it ended at the Jordan.", 
                "num": 33
              }, 
              {
                "text": "From Heleph the border extended westward to Aznoth Tabor, and went out from there toward Hukkok; it adjoined Zebulun on the south side and Asher on the west side, and ended at Judah by the Jordan toward the sunrise.", 
                "num": 34
              }, 
              {
                "text": "And the fortified cities are Ziddim, Zer, Hammath, Rakkath, Chinnereth,", 
                "num": 35
              }, 
              {
                "text": "Adamah, Ramah, Hazor,", 
                "num": 36
              }, 
              {
                "text": "Kedesh, Edrei, En Hazor,", 
                "num": 37
              }, 
              {
                "text": "Iron, Migdal El, Horem, Beth Anath, and Beth Shemesh: nineteen cities with their villages.", 
                "num": 38
              }, 
              {
                "text": "This was the inheritance of the tribe of the children of Naphtali according to their families, the cities and their villages.", 
                "num": 39
              }, 
              {
                "text": "The seventh lot came out for the tribe of the children of Dan according to their families.", 
                "num": 40
              }, 
              {
                "text": "And the territory of their inheritance was Zorah, Eshtaol, Ir Shemesh,", 
                "num": 41
              }, 
              {
                "text": "Shaalabbin, Aijalon, Jethlah,", 
                "num": 42
              }, 
              {
                "text": "Elon, Timnah, Ekron,", 
                "num": 43
              }, 
              {
                "text": "Eltekeh, Gibbethon, Baalath,", 
                "num": 44
              }, 
              {
                "text": "Jehud, Bene Berak, Gath Rimmon,", 
                "num": 45
              }, 
              {
                "text": "Me Jarkon, and Rakkon, with the region near Joppa.", 
                "num": 46
              }, 
              {
                "text": "And the border of the children of Dan went beyond these, because the children of Dan went up to fight against Leshem and took it; and they struck it with the edge of the sword, took possession of it, and dwelt in it. They called Leshem, Dan, after the name of Dan their father.", 
                "num": 47
              }, 
              {
                "text": "This is the inheritance of the tribe of the children of Dan according to their families, these cities with their villages.", 
                "num": 48
              }, 
              {
                "text": "When they had made an end of dividing the land as an inheritance according to their borders, the children of Israel gave an inheritance among them to Joshua the son of Nun.", 
                "num": 49
              }, 
              {
                "text": "According to the word of the LORD they gave him the city which he asked for, Timnath Serah in the mountains of Ephraim; and he built the city and dwelt in it.", 
                "num": 50
              }, 
              {
                "text": "These were the inheritances which Eleazar the priest, Joshua the son of Nun, and the heads of the fathers of the tribes of the children of Israel divided as an inheritance by lot in Shiloh before the LORD, at the door of the tabernacle of meeting. So they made an end of dividing the country.", 
                "num": 51
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "The LORD also spoke to Joshua, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Speak to the children of Israel, saying: \"Appoint for yourselves cities of refuge, of which I spoke to you through Moses,", 
                "num": 2
              }, 
              {
                "text": "that the slayer who kills a person accidentally or unintentionally may flee there; and they shall be your refuge from the avenger of blood.", 
                "num": 3
              }, 
              {
                "text": "And when he flees to one of those cities, and stands at the entrance of the gate of the city, and declares his case in the hearing of the elders of that city, they shall take him into the city as one of them, and give him a place, that he may dwell among them.", 
                "num": 4
              }, 
              {
                "text": "Then if the avenger of blood pursues him, they shall not deliver the slayer into his hand, because he struck his neighbor unintentionally, but did not hate him beforehand.", 
                "num": 5
              }, 
              {
                "text": "And he shall dwell in that city until he stands before the congregation for judgment, and until the death of the one who is high priest in those days. Then the slayer may return and come to his own city and his own house, to the city from which he fled.\"'", 
                "num": 6
              }, 
              {
                "text": "So they appointed Kedesh in Galilee, in the mountains of Naphtali, Shechem in the mountains of Ephraim, and Kirjath Arba (which is Hebron) in the mountains of Judah.", 
                "num": 7
              }, 
              {
                "text": "And on the other side of the Jordan, by Jericho eastward, they assigned Bezer in the wilderness on the plain, from the tribe of Reuben, Ramoth in Gilead, from the tribe of Gad, and Golan in Bashan, from the tribe of Manasseh.", 
                "num": 8
              }, 
              {
                "text": "These were the cities appointed for all the children of Israel and for the stranger who dwelt among them, that whoever killed a person accidentally might flee there, and not die by the hand of the avenger of blood until he stood before the congregation.", 
                "num": 9
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Then the heads of the fathers' houses of the Levites came near to Eleazar the priest, to Joshua the son of Nun, and to the heads of the fathers' houses of the tribes of the children of Israel.", 
                "num": 1
              }, 
              {
                "text": "And they spoke to them at Shiloh in the land of Canaan, saying, \"The LORD commanded through Moses to give us cities to dwell in, with their common-lands for our livestock.\"", 
                "num": 2
              }, 
              {
                "text": "So the children of Israel gave to the Levites from their inheritance, at the commandment of the LORD, these cities and their common-lands:", 
                "num": 3
              }, 
              {
                "text": "Now the lot came out for the families of the Kohathites. And the children of Aaron the priest, who were of the Levites, had thirteen cities by lot from the tribe of Judah, from the tribe of Simeon, and from the tribe of Benjamin.", 
                "num": 4
              }, 
              {
                "text": "The rest of the children of Kohath had ten cities by lot from the families of the tribe of Ephraim, from the tribe of Dan, and from the half-tribe of Manasseh.", 
                "num": 5
              }, 
              {
                "text": "And the children of Gershon had thirteen cities by lot from the families of the tribe of Issachar, from the tribe of Asher, from the tribe of Naphtali, and from the half-tribe of Manasseh in Bashan.", 
                "num": 6
              }, 
              {
                "text": "The children of Merari according to their families had twelve cities from the tribe of Reuben, from the tribe of Gad, and from the tribe of Zebulun.", 
                "num": 7
              }, 
              {
                "text": "And the children of Israel gave these cities with their common-lands by lot to the Levites, as the LORD had commanded by the hand of Moses.", 
                "num": 8
              }, 
              {
                "text": "So they gave from the tribe of the children of Judah and from the tribe of the children of Simeon these cities which are designated by name,", 
                "num": 9
              }, 
              {
                "text": "which were for the children of Aaron, one of the families of the Kohathites, who were of the children of Levi; for the lot was theirs first.", 
                "num": 10
              }, 
              {
                "text": "And they gave them Kirjath Arba (Arba was the father of Anak), which is Hebron, in the mountains of Judah, with the common-land surrounding it.", 
                "num": 11
              }, 
              {
                "text": "But the fields of the city and its villages they gave to Caleb the son of Jephunneh as his possession.", 
                "num": 12
              }, 
              {
                "text": "Thus to the children of Aaron the priest they gave Hebron with its common-land (a city of refuge for the slayer), Libnah with its common-land,", 
                "num": 13
              }, 
              {
                "text": "Jattir with its common-land, Eshtemoa with its common-land,", 
                "num": 14
              }, 
              {
                "text": "Holon with its common-land, Debir with its common-land,", 
                "num": 15
              }, 
              {
                "text": "Ain with its common-land, Juttah with its common-land, and Beth Shemesh with its common-land: nine cities from those two tribes;", 
                "num": 16
              }, 
              {
                "text": "and from the tribe of Benjamin, Gibeon with its common-land, Geba with its common-land,", 
                "num": 17
              }, 
              {
                "text": "Anathoth with its common-land, and Almon with its common-land: four cities.", 
                "num": 18
              }, 
              {
                "text": "All the cities of the children of Aaron, the priests, were thirteen cities with their common-lands.", 
                "num": 19
              }, 
              {
                "text": "And the families of the children of Kohath, the Levites, the rest of the children of Kohath, even they had the cities of their lot from the tribe of Ephraim.", 
                "num": 20
              }, 
              {
                "text": "For they gave them Shechem with its common-land in the mountains of Ephraim (a city of refuge for the slayer), Gezer with its common-land,", 
                "num": 21
              }, 
              {
                "text": "Kibzaim with its common-land, and Beth Horon with its common-land: four cities;", 
                "num": 22
              }, 
              {
                "text": "and from the tribe of Dan, Eltekeh with its common-land, Gibbethon with its common-land,", 
                "num": 23
              }, 
              {
                "text": "Aijalon with its common-land, and Gath Rimmon with its common-land: four cities;", 
                "num": 24
              }, 
              {
                "text": "and from the half-tribe of Manasseh, Tanach with its common-land and Gath Rimmon with its common-land: two cities.", 
                "num": 25
              }, 
              {
                "text": "All the ten cities with their common-lands were for the rest of the families of the children of Kohath.", 
                "num": 26
              }, 
              {
                "text": "Also to the children of Gershon, of the families of the Levites, from the other half-tribe of Manasseh, they gave Golan in Bashan with its common-land (a city of refuge for the slayer), and Be Eshterah with its common-land: two cities;", 
                "num": 27
              }, 
              {
                "text": "and from the tribe of Issachar, Kishion with its common-land, Daberath with its common-land,", 
                "num": 28
              }, 
              {
                "text": "Jarmuth with its common-land, and En Gannim with its common-land: four cities;", 
                "num": 29
              }, 
              {
                "text": "and from the tribe of Asher, Mishal with its common-land, Abdon with its common-land,", 
                "num": 30
              }, 
              {
                "text": "Helkath with its common-land, and Rehob with its common-land: four cities;", 
                "num": 31
              }, 
              {
                "text": "and from the tribe of Naphtali, Kedesh in Galilee with its common-land (a city of refuge for the slayer), Hammoth Dor with its common-land, and Kartan with its common-land: three cities.", 
                "num": 32
              }, 
              {
                "text": "All the cities of the Gershonites according to their families were thirteen cities with their common-lands.", 
                "num": 33
              }, 
              {
                "text": "And to the families of the children of Merari, the rest of the Levites, from the tribe of Zebulun, Jokneam with its common-land, Kartah with its common-land,", 
                "num": 34
              }, 
              {
                "text": "Dimnah with its common-land, and Nahalal with its common-land: four cities;", 
                "num": 35
              }, 
              {
                "text": "and from the tribe of Reuben, Bezer with its common-land, Jahaz with its common-land,", 
                "num": 36
              }, 
              {
                "text": "Kedemoth with its common-land, and Mephaath with its common-land: four cities;", 
                "num": 37
              }, 
              {
                "text": "and from the tribe of Gad, Ramoth in Gilead with its common-land (a city of refuge for the slayer), Mahanaim with its common-land,", 
                "num": 38
              }, 
              {
                "text": "Heshbon with its common-land, and Jazer with its common-land: four cities in all.", 
                "num": 39
              }, 
              {
                "text": "So all the cities for the children of Merari according to their families, the rest of the families of the Levites, were by their lot twelve cities.", 
                "num": 40
              }, 
              {
                "text": "All the cities of the Levites within the possession of the children of Israel were forty-eight cities with their common-lands.", 
                "num": 41
              }, 
              {
                "text": "Every one of these cities had its common-land surrounding it; thus were all these cities.", 
                "num": 42
              }, 
              {
                "text": "So the LORD gave to Israel all the land of which He had sworn to give to their fathers, and they took possession of it and dwelt in it.", 
                "num": 43
              }, 
              {
                "text": "The LORD gave them rest all around, according to all that He had sworn to their fathers. And not a man of all their enemies stood against them; the LORD delivered all their enemies into their hand.", 
                "num": 44
              }, 
              {
                "text": "Not a word failed of any good thing which the LORD had spoken to the house of Israel. All came to pass.", 
                "num": 45
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then Joshua called the Reubenites, the Gadites, and half the tribe of Manasseh,", 
                "num": 1
              }, 
              {
                "text": "and said to them: \"You have kept all that Moses the servant of the LORD commanded you, and have obeyed my voice in all that I commanded you.", 
                "num": 2
              }, 
              {
                "text": "You have not left your brethren these many days, up to this day, but have kept the charge of the commandment of the LORD your God.", 
                "num": 3
              }, 
              {
                "text": "And now the LORD your God has given rest to your brethren, as He promised them; now therefore, return and go to your tents and to the land of your possession, which Moses the servant of the LORD gave you on the other side of the Jordan.", 
                "num": 4
              }, 
              {
                "text": "But take careful heed to do the commandment and the law which Moses the servant of the LORD commanded you, to love the LORD your God, to walk in all His ways, to keep His commandments, to hold fast to Him, and to serve Him with all your heart and with all your soul.\"", 
                "num": 5
              }, 
              {
                "text": "So Joshua blessed them and sent them away, and they went to their tents.", 
                "num": 6
              }, 
              {
                "text": "Now to half the tribe of Manasseh Moses had given a possession in Bashan, but to the other half of it Joshua gave a possession among their brethren on this side of the Jordan, westward. And indeed, when Joshua sent them away to their tents, he blessed them,", 
                "num": 7
              }, 
              {
                "text": "and spoke to them, saying, \"Return with much riches to your tents, with very much livestock, with silver, with gold, with bronze, with iron, and with very much clothing. Divide the spoil of your enemies with your brethren.\"", 
                "num": 8
              }, 
              {
                "text": "So the children of Reuben, the children of Gad, and half the tribe of Manasseh returned, and departed from the children of Israel at Shiloh, which is in the land of Canaan, to go to the country of Gilead, to the land of their possession, which they had obtained according to the word of the LORD by the hand of Moses.", 
                "num": 9
              }, 
              {
                "text": "And when they came to the region of the Jordan which is in the land of Canaan, the children of Reuben, the children of Gad, and half the tribe of Manasseh built an altar there by the Jordan--a great, impressive altar.", 
                "num": 10
              }, 
              {
                "text": "Now the children of Israel heard someone say, \"Behold, the children of Reuben, the children of Gad, and half the tribe of Manasseh have built an altar on the frontier of the land of Canaan, in the region of the Jordan--on the children of Israel's side.\"", 
                "num": 11
              }, 
              {
                "text": "And when the children of Israel heard of it, the whole congregation of the children of Israel gathered together at Shiloh to go to war against them.", 
                "num": 12
              }, 
              {
                "text": "Then the children of Israel sent Phinehas the son of Eleazar the priest to the children of Reuben, to the children of Gad, and to half the tribe of Manasseh, into the land of Gilead,", 
                "num": 13
              }, 
              {
                "text": "and with him ten rulers, one ruler each from the chief house of every tribe of Israel; and each one was the head of the house of his father among the divisions of Israel.", 
                "num": 14
              }, 
              {
                "text": "Then they came to the children of Reuben, to the children of Gad, and to half the tribe of Manasseh, to the land of Gilead, and they spoke with them, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Thus says the whole congregation of the LORD: \"What treachery is this that you have committed against the God of Israel, to turn away this day from following the LORD, in that you have built for yourselves an altar, that you might rebel this day against the LORD?", 
                "num": 16
              }, 
              {
                "text": "Is the iniquity of Peor not enough for us, from which we are not cleansed till this day, although there was a plague in the congregation of the LORD,", 
                "num": 17
              }, 
              {
                "text": "but that you must turn away this day from following the LORD? And it shall be, if you rebel today against the LORD, that tomorrow He will be angry with the whole congregation of Israel.", 
                "num": 18
              }, 
              {
                "text": "Nevertheless, if the land of your possession is unclean, then cross over to the land of the possession of the LORD, where the LORD's tabernacle stands, and take possession among us; but do not rebel against the LORD, nor rebel against us, by building yourselves an altar besides the altar of the LORD our God.", 
                "num": 19
              }, 
              {
                "text": "Did not Achan the son of Zerah commit a trespass in the accursed thing, and wrath fell on all the congregation of Israel? And that man did not perish alone in his iniquity.\"'", 
                "num": 20
              }, 
              {
                "text": "Then the children of Reuben, the children of Gad, and half the tribe of Manasseh answered and said to the heads of the divisions of Israel:", 
                "num": 21
              }, 
              {
                "text": "\"The LORD God of gods, the LORD God of gods, He knows, and let Israel itself know--if it is in rebellion, or if in treachery against the LORD, do not save us this day.", 
                "num": 22
              }, 
              {
                "text": "If we have built ourselves an altar to turn from following the LORD, or if to offer on it burnt offerings or grain offerings, or if to offer peace offerings on it, let the LORD Himself require an account.", 
                "num": 23
              }, 
              {
                "text": "But in fact we have done it for fear, for a reason, saying, \"In time to come your descendants may speak to our descendants, saying, \"What have you to do with the LORD God of Israel?", 
                "num": 24
              }, 
              {
                "text": "For the LORD has made the Jordan a border between you and us, you children of Reuben and children of Gad. You have no part in the LORD.\" So your descendants would make our descendants cease fearing the LORD.'", 
                "num": 25
              }, 
              {
                "text": "Therefore we said, \"Let us now prepare to build ourselves an altar, not for burnt offering nor for sacrifice,", 
                "num": 26
              }, 
              {
                "text": "but that it may be a witness between you and us and our generations after us, that we may perform the service of the LORD before Him with our burnt offerings, with our sacrifices, and with our peace offerings; that your descendants may not say to our descendants in time to come, \"You have no part in the LORD.\"'", 
                "num": 27
              }, 
              {
                "text": "Therefore we said that it will be, when they say this to us or to our generations in time to come, that we may say, \"Here is the replica of the altar of the LORD which our fathers made, though not for burnt offerings nor for sacrifices; but it is a witness between you and us.'", 
                "num": 28
              }, 
              {
                "text": "Far be it from us that we should rebel against the LORD, and turn from following the LORD this day, to build an altar for burnt offerings, for grain offerings, or for sacrifices, besides the altar of the LORD our God which is before His tabernacle.\"", 
                "num": 29
              }, 
              {
                "text": "Now when Phinehas the priest and the rulers of the congregation, the heads of the divisions of Israel who were with him, heard the words that the children of Reuben, the children of Gad, and the children of Manasseh spoke, it pleased them.", 
                "num": 30
              }, 
              {
                "text": "Then Phinehas the son of Eleazar the priest said to the children of Reuben, the children of Gad, and the children of Manasseh, \"This day we perceive that the LORD is among us, because you have not committed this treachery against the LORD. Now you have delivered the children of Israel out of the hand of the LORD.\"", 
                "num": 31
              }, 
              {
                "text": "And Phinehas the son of Eleazar the priest, and the rulers, returned from the children of Reuben and the children of Gad, from the land of Gilead to the land of Canaan, to the children of Israel, and brought back word to them.", 
                "num": 32
              }, 
              {
                "text": "So the thing pleased the children of Israel, and the children of Israel blessed God; they spoke no more of going against them in battle, to destroy the land where the children of Reuben and Gad dwelt.", 
                "num": 33
              }, 
              {
                "text": "The children of Reuben and the children of Gad called the altar, Witness, \"For it is a witness between us that the LORD is God.\"", 
                "num": 34
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, a long time after the LORD had given rest to Israel from all their enemies round about, that Joshua was old, advanced in age.", 
                "num": 1
              }, 
              {
                "text": "And Joshua called for all Israel, for their elders, for their heads, for their judges, and for their officers, and said to them: \"I am old, advanced in age.", 
                "num": 2
              }, 
              {
                "text": "You have seen all that the LORD your God has done to all these nations because of you, for the LORD your God is He who has fought for you.", 
                "num": 3
              }, 
              {
                "text": "See, I have divided to you by lot these nations that remain, to be an inheritance for your tribes, from the Jordan, with all the nations that I have cut off, as far as the Great Sea westward.", 
                "num": 4
              }, 
              {
                "text": "And the LORD your God will expel them from before you and drive them out of your sight. So you shall possess their land, as the LORD your God promised you.", 
                "num": 5
              }, 
              {
                "text": "Therefore be very courageous to keep and to do all that is written in the Book of the Law of Moses, lest you turn aside from it to the right hand or to the left,", 
                "num": 6
              }, 
              {
                "text": "and lest you go among these nations, these who remain among you. You shall not make mention of the name of their gods, nor cause anyone to swear by them; you shall not serve them nor bow down to them,", 
                "num": 7
              }, 
              {
                "text": "but you shall hold fast to the LORD your God, as you have done to this day.", 
                "num": 8
              }, 
              {
                "text": "For the LORD has driven out from before you great and strong nations; but as for you, no one has been able to stand against you to this day.", 
                "num": 9
              }, 
              {
                "text": "One man of you shall chase a thousand, for the LORD your God is He who fights for you, as He promised you.", 
                "num": 10
              }, 
              {
                "text": "Therefore take careful heed to yourselves, that you love the LORD your God.", 
                "num": 11
              }, 
              {
                "text": "Or else, if indeed you do go back, and cling to the remnant of these nations--these that remain among you--and make marriages with them, and go in to them and they to you,", 
                "num": 12
              }, 
              {
                "text": "know for certain that the LORD your God will no longer drive out these nations from before you. But they shall be snares and traps to you, and scourges on your sides and thorns in your eyes, until you perish from this good land which the LORD your God has given you.", 
                "num": 13
              }, 
              {
                "text": "\"Behold, this day I am going the way of all the earth. And you know in all your hearts and in all your souls that not one thing has failed of all the good things which the LORD your God spoke concerning you. All have come to pass for you; not one word of them has failed.", 
                "num": 14
              }, 
              {
                "text": "Therefore it shall come to pass, that as all the good things have come upon you which the LORD your God promised you, so the LORD will bring upon you all harmful things, until He has destroyed you from this good land which the LORD your God has given you.", 
                "num": 15
              }, 
              {
                "text": "When you have transgressed the covenant of the LORD your God, which He commanded you, and have gone and served other gods, and bowed down to them, then the anger of the LORD will burn against you, and you shall perish quickly from the good land which He has given you.\"", 
                "num": 16
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Then Joshua gathered all the tribes of Israel to Shechem and called for the elders of Israel, for their heads, for their judges, and for their officers; and they presented themselves before God.", 
                "num": 1
              }, 
              {
                "text": "And Joshua said to all the people, \"Thus says the LORD God of Israel: \"Your fathers, including Terah, the father of Abraham and the father of Nahor, dwelt on the other side of the River in old times; and they served other gods.", 
                "num": 2
              }, 
              {
                "text": "Then I took your father Abraham from the other side of the River, led him throughout all the land of Canaan, and multiplied his descendants and gave him Isaac.", 
                "num": 3
              }, 
              {
                "text": "To Isaac I gave Jacob and Esau. To Esau I gave the mountains of Seir to possess, but Jacob and his children went down to Egypt.", 
                "num": 4
              }, 
              {
                "text": "Also I sent Moses and Aaron, and I plagued Egypt, according to what I did among them. Afterward I brought you out.", 
                "num": 5
              }, 
              {
                "text": "\"Then I brought your fathers out of Egypt, and you came to the sea; and the Egyptians pursued your fathers with chariots and horsemen to the Red Sea.", 
                "num": 6
              }, 
              {
                "text": "So they cried out to the LORD; and He put darkness between you and the Egyptians, brought the sea upon them, and covered them. And your eyes saw what I did in Egypt. Then you dwelt in the wilderness a long time.", 
                "num": 7
              }, 
              {
                "text": "And I brought you into the land of the Amorites, who dwelt on the other side of the Jordan, and they fought with you. But I gave them into your hand, that you might possess their land, and I destroyed them from before you.", 
                "num": 8
              }, 
              {
                "text": "Then Balak the son of Zippor, king of Moab, arose to make war against Israel, and sent and called Balaam the son of Beor to curse you.", 
                "num": 9
              }, 
              {
                "text": "But I would not listen to Balaam; therefore he continued to bless you. So I delivered you out of his hand.", 
                "num": 10
              }, 
              {
                "text": "Then you went over the Jordan and came to Jericho. And the men of Jericho fought against you--also the Amorites, the Perizzites, the Canaanites, the Hittites, the Girgashites, the Hivites, and the Jebusites. But I delivered them into your hand.", 
                "num": 11
              }, 
              {
                "text": "I sent the hornet before you which drove them out from before you, also the two kings of the Amorites, but not with your sword or with your bow.", 
                "num": 12
              }, 
              {
                "text": "I have given you a land for which you did not labor, and cities which you did not build, and you dwell in them; you eat of the vineyards and olive groves which you did not plant.'", 
                "num": 13
              }, 
              {
                "text": "\"Now therefore, fear the LORD, serve Him in sincerity and in truth, and put away the gods which your fathers served on the other side of the River and in Egypt. Serve the LORD!", 
                "num": 14
              }, 
              {
                "text": "And if it seems evil to you to serve the LORD, choose for yourselves this day whom you will serve, whether the gods which your fathers served that were on the other side of the River, or the gods of the Amorites, in whose land you dwell. But as for me and my house, we will serve the LORD.\"", 
                "num": 15
              }, 
              {
                "text": "So the people answered and said: \"Far be it from us that we should forsake the LORD to serve other gods;", 
                "num": 16
              }, 
              {
                "text": "for the LORD our God is He who brought us and our fathers up out of the land of Egypt, from the house of bondage, who did those great signs in our sight, and preserved us in all the way that we went and among all the people through whom we passed.", 
                "num": 17
              }, 
              {
                "text": "And the LORD drove out from before us all the people, including the Amorites who dwelt in the land. We also will serve the LORD, for He is our God.\"", 
                "num": 18
              }, 
              {
                "text": "But Joshua said to the people, \"You cannot serve the LORD, for He is a holy God. He is a jealous God; He will not forgive your transgressions nor your sins.", 
                "num": 19
              }, 
              {
                "text": "If you forsake the LORD and serve foreign gods, then He will turn and do you harm and consume you, after He has done you good.\"", 
                "num": 20
              }, 
              {
                "text": "And the people said to Joshua, \"No, but we will serve the LORD!\"", 
                "num": 21
              }, 
              {
                "text": "So Joshua said to the people, \"You are witnesses against yourselves that you have chosen the LORD for yourselves, to serve Him.\" And they said, \"We are witnesses!\"", 
                "num": 22
              }, 
              {
                "text": "\"Now therefore,\" he said, \"put away the foreign gods which are among you, and incline your heart to the LORD God of Israel.\"", 
                "num": 23
              }, 
              {
                "text": "And the people said to Joshua, \"The LORD our God we will serve, and His voice we will obey!\"", 
                "num": 24
              }, 
              {
                "text": "So Joshua made a covenant with the people that day, and made for them a statute and an ordinance in Shechem.", 
                "num": 25
              }, 
              {
                "text": "Then Joshua wrote these words in the Book of the Law of God. And he took a large stone, and set it up there under the oak that was by the sanctuary of the LORD.", 
                "num": 26
              }, 
              {
                "text": "And Joshua said to all the people, \"Behold, this stone shall be a witness to us, for it has heard all the words of the LORD which He spoke to us. It shall therefore be a witness to you, lest you deny your God.\"", 
                "num": 27
              }, 
              {
                "text": "So Joshua let the people depart, each to his own inheritance.", 
                "num": 28
              }, 
              {
                "text": "Now it came to pass after these things that Joshua the son of Nun, the servant of the LORD, died, being one hundred and ten years old.", 
                "num": 29
              }, 
              {
                "text": "And they buried him within the border of his inheritance at Timnath Serah, which is in the mountains of Ephraim, on the north side of Mount Gaash.", 
                "num": 30
              }, 
              {
                "text": "Israel served the LORD all the days of Joshua, and all the days of the elders who outlived Joshua, who had known all the works of the LORD which He had done for Israel.", 
                "num": 31
              }, 
              {
                "text": "The bones of Joseph, which the children of Israel had brought up out of Egypt, they buried at Shechem, in the plot of ground which Jacob had bought from the sons of Hamor the father of Shechem for one hundred pieces of silver, and which had become an inheritance of the children of Joseph.", 
                "num": 32
              }, 
              {
                "text": "And Eleazar the son of Aaron died. They buried him in a hill belonging to Phinehas his son, which was given to him in the mountains of Ephraim.", 
                "num": 33
              }
            ], 
            "num": 24
          }
        ]
      }, 
      {
        "name": "Judges", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now after the death of Joshua it came to pass that the children of Israel asked the LORD, saying, \"Who shall be first to go up for us against the Canaanites to fight against them?\"", 
                "num": 1
              }, 
              {
                "text": "And the LORD said, \"Judah shall go up. Indeed I have delivered the land into his hand.\"", 
                "num": 2
              }, 
              {
                "text": "So Judah said to Simeon his brother, \"Come up with me to my allotted territory, that we may fight against the Canaanites; and I will likewise go with you to your allotted territory.\" And Simeon went with him.", 
                "num": 3
              }, 
              {
                "text": "Then Judah went up, and the LORD delivered the Canaanites and the Perizzites into their hand; and they killed ten thousand men at Bezek.", 
                "num": 4
              }, 
              {
                "text": "And they found Adoni-Bezek in Bezek, and fought against him; and they defeated the Canaanites and the Perizzites.", 
                "num": 5
              }, 
              {
                "text": "Then Adoni-Bezek fled, and they pursued him and caught him and cut off his thumbs and big toes.", 
                "num": 6
              }, 
              {
                "text": "And Adoni-Bezek said, \"Seventy kings with their thumbs and big toes cut off used to gather scraps under my table; as I have done, so God has repaid me.\" Then they brought him to Jerusalem, and there he died.", 
                "num": 7
              }, 
              {
                "text": "Now the children of Judah fought against Jerusalem and took it; they struck it with the edge of the sword and set the city on fire.", 
                "num": 8
              }, 
              {
                "text": "And afterward the children of Judah went down to fight against the Canaanites who dwelt in the mountains, in the South, and in the lowland.", 
                "num": 9
              }, 
              {
                "text": "Then Judah went against the Canaanites who dwelt in Hebron. (Now the name of Hebron was formerly Kirjath Arba.) And they killed Sheshai, Ahiman, and Talmai.", 
                "num": 10
              }, 
              {
                "text": "From there they went against the inhabitants of Debir. (The name of Debir was formerly Kirjath Sepher.)", 
                "num": 11
              }, 
              {
                "text": "Then Caleb said, \"Whoever attacks Kirjath Sepher and takes it, to him I will give my daughter Achsah as wife.\"", 
                "num": 12
              }, 
              {
                "text": "And Othniel the son of Kenaz, Caleb's younger brother, took it; so he gave him his daughter Achsah as wife.", 
                "num": 13
              }, 
              {
                "text": "Now it happened, when she came to him, that she urged him to ask her father for a field. And she dismounted from her donkey, and Caleb said to her, \"What do you wish?\"", 
                "num": 14
              }, 
              {
                "text": "So she said to him, \"Give me a blessing; since you have given me land in the South, give me also springs of water.\" And Caleb gave her the upper springs and the lower springs.", 
                "num": 15
              }, 
              {
                "text": "Now the children of the Kenite, Moses' father-in-law, went up from the City of Palms with the children of Judah into the Wilderness of Judah, which lies in the South near Arad; and they went and dwelt among the people.", 
                "num": 16
              }, 
              {
                "text": "And Judah went with his brother Simeon, and they attacked the Canaanites who inhabited Zephath, and utterly destroyed it. So the name of the city was called Hormah.", 
                "num": 17
              }, 
              {
                "text": "Also Judah took Gaza with its territory, Ashkelon with its territory, and Ekron with its territory.", 
                "num": 18
              }, 
              {
                "text": "So the LORD was with Judah. And they drove out the mountaineers, but they could not drive out the inhabitants of the lowland, because they had chariots of iron.", 
                "num": 19
              }, 
              {
                "text": "And they gave Hebron to Caleb, as Moses had said. Then he expelled from there the three sons of Anak.", 
                "num": 20
              }, 
              {
                "text": "But the children of Benjamin did not drive out the Jebusites who inhabited Jerusalem; so the Jebusites dwell with the children of Benjamin in Jerusalem to this day.", 
                "num": 21
              }, 
              {
                "text": "And the house of Joseph also went up against Bethel, and the LORD was with them.", 
                "num": 22
              }, 
              {
                "text": "So the house of Joseph sent men to spy out Bethel. (The name of the city was formerly Luz.)", 
                "num": 23
              }, 
              {
                "text": "And when the spies saw a man coming out of the city, they said to him, \"Please show us the entrance to the city, and we will show you mercy.\"", 
                "num": 24
              }, 
              {
                "text": "So he showed them the entrance to the city, and they struck the city with the edge of the sword; but they let the man and all his family go.", 
                "num": 25
              }, 
              {
                "text": "And the man went to the land of the Hittites, built a city, and called its name Luz, which is its name to this day.", 
                "num": 26
              }, 
              {
                "text": "However, Manasseh did not drive out the inhabitants of Beth Shean and its villages, or Taanach and its villages, or the inhabitants of Dor and its villages, or the inhabitants of Ibleam and its villages, or the inhabitants of Megiddo and its villages; for the Canaanites were determined to dwell in that land.", 
                "num": 27
              }, 
              {
                "text": "And it came to pass, when Israel was strong, that they put the Canaanites under tribute, but did not completely drive them out.", 
                "num": 28
              }, 
              {
                "text": "Nor did Ephraim drive out the Canaanites who dwelt in Gezer; so the Canaanites dwelt in Gezer among them.", 
                "num": 29
              }, 
              {
                "text": "Nor did Zebulun drive out the inhabitants of Kitron or the inhabitants of Nahalol; so the Canaanites dwelt among them, and were put under tribute.", 
                "num": 30
              }, 
              {
                "text": "Nor did Asher drive out the inhabitants of Acco or the inhabitants of Sidon, or of Ahlab, Achzib, Helbah, Aphik, or Rehob.", 
                "num": 31
              }, 
              {
                "text": "So the Asherites dwelt among the Canaanites, the inhabitants of the land; for they did not drive them out.", 
                "num": 32
              }, 
              {
                "text": "Nor did Naphtali drive out the inhabitants of Beth Shemesh or the inhabitants of Beth Anath; but they dwelt among the Canaanites, the inhabitants of the land. Nevertheless the inhabitants of Beth Shemesh and Beth Anath were put under tribute to them.", 
                "num": 33
              }, 
              {
                "text": "And the Amorites forced the children of Dan into the mountains, for they would not allow them to come down to the valley;", 
                "num": 34
              }, 
              {
                "text": "and the Amorites were determined to dwell in Mount Heres, in Aijalon, and in Shaalbim; yet when the strength of the house of Joseph became greater, they were put under tribute.", 
                "num": 35
              }, 
              {
                "text": "Now the boundary of the Amorites was from the Ascent of Akrabbim, from Sela, and upward.", 
                "num": 36
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Then the Angel of the LORD came up from Gilgal to Bochim, and said: \"I led you up from Egypt and brought you to the land of which I swore to your fathers; and I said, \"I will never break My covenant with you.", 
                "num": 1
              }, 
              {
                "text": "And you shall make no covenant with the inhabitants of this land; you shall tear down their altars.' But you have not obeyed My voice. Why have you done this?", 
                "num": 2
              }, 
              {
                "text": "Therefore I also said, \"I will not drive them out before you; but they shall be thorns in your side, and their gods shall be a snare to you.\"'", 
                "num": 3
              }, 
              {
                "text": "So it was, when the Angel of the LORD spoke these words to all the children of Israel, that the people lifted up their voices and wept.", 
                "num": 4
              }, 
              {
                "text": "Then they called the name of that place Bochim; and they sacrificed there to the LORD.", 
                "num": 5
              }, 
              {
                "text": "And when Joshua had dismissed the people, the children of Israel went each to his own inheritance to possess the land.", 
                "num": 6
              }, 
              {
                "text": "So the people served the LORD all the days of Joshua, and all the days of the elders who outlived Joshua, who had seen all the great works of the LORD which He had done for Israel.", 
                "num": 7
              }, 
              {
                "text": "Now Joshua the son of Nun, the servant of the LORD, died when he was one hundred and ten years old.", 
                "num": 8
              }, 
              {
                "text": "And they buried him within the border of his inheritance at Timnath Heres, in the mountains of Ephraim, on the north side of Mount Gaash.", 
                "num": 9
              }, 
              {
                "text": "When all that generation had been gathered to their fathers, another generation arose after them who did not know the LORD nor the work which He had done for Israel.", 
                "num": 10
              }, 
              {
                "text": "Then the children of Israel did evil in the sight of the LORD, and served the Baals;", 
                "num": 11
              }, 
              {
                "text": "and they forsook the LORD God of their fathers, who had brought them out of the land of Egypt; and they followed other gods from among the gods of the people who were all around them, and they bowed down to them; and they provoked the LORD to anger.", 
                "num": 12
              }, 
              {
                "text": "They forsook the LORD and served Baal and the Ashtoreths.", 
                "num": 13
              }, 
              {
                "text": "And the anger of the LORD was hot against Israel. So He delivered them into the hands of plunderers who despoiled them; and He sold them into the hands of their enemies all around, so that they could no longer stand before their enemies.", 
                "num": 14
              }, 
              {
                "text": "Wherever they went out, the hand of the LORD was against them for calamity, as the LORD had said, and as the LORD had sworn to them. And they were greatly distressed.", 
                "num": 15
              }, 
              {
                "text": "Nevertheless, the LORD raised up judges who delivered them out of the hand of those who plundered them.", 
                "num": 16
              }, 
              {
                "text": "Yet they would not listen to their judges, but they played the harlot with other gods, and bowed down to them. They turned quickly from the way in which their fathers walked, in obeying the commandments of the LORD; they did not do so.", 
                "num": 17
              }, 
              {
                "text": "And when the LORD raised up judges for them, the LORD was with the judge and delivered them out of the hand of their enemies all the days of the judge; for the LORD was moved to pity by their groaning because of those who oppressed them and harassed them.", 
                "num": 18
              }, 
              {
                "text": "And it came to pass, when the judge was dead, that they reverted and behaved more corruptly than their fathers, by following other gods, to serve them and bow down to them. They did not cease from their own doings nor from their stubborn way.", 
                "num": 19
              }, 
              {
                "text": "Then the anger of the LORD was hot against Israel; and He said, \"Because this nation has transgressed My covenant which I commanded their fathers, and has not heeded My voice,", 
                "num": 20
              }, 
              {
                "text": "I also will no longer drive out before them any of the nations which Joshua left when he died,", 
                "num": 21
              }, 
              {
                "text": "so that through them I may test Israel, whether they will keep the ways of the LORD, to walk in them as their fathers kept them, or not.\"", 
                "num": 22
              }, 
              {
                "text": "Therefore the LORD left those nations, without driving them out immediately; nor did He deliver them into the hand of Joshua.", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now these are the nations which the LORD left, that He might test Israel by them, that is, all who had not known any of the wars in Canaan", 
                "num": 1
              }, 
              {
                "text": "(this was only so that the generations of the children of Israel might be taught to know war, at least those who had not formerly known it),", 
                "num": 2
              }, 
              {
                "text": "namely, five lords of the Philistines, all the Canaanites, the Sidonians, and the Hivites who dwelt in Mount Lebanon, from Mount Baal Hermon to the entrance of Hamath.", 
                "num": 3
              }, 
              {
                "text": "And they were left, that He might test Israel by them, to know whether they would obey the commandments of the LORD, which He had commanded their fathers by the hand of Moses.", 
                "num": 4
              }, 
              {
                "text": "Thus the children of Israel dwelt among the Canaanites, the Hittites, the Amorites, the Perizzites, the Hivites, and the Jebusites.", 
                "num": 5
              }, 
              {
                "text": "And they took their daughters to be their wives, and gave their daughters to their sons; and they served their gods.", 
                "num": 6
              }, 
              {
                "text": "So the children of Israel did evil in the sight of the LORD. They forgot the LORD their God, and served the Baals and Asherahs.", 
                "num": 7
              }, 
              {
                "text": "Therefore the anger of the LORD was hot against Israel, and He sold them into the hand of Cushan-Rishathaim king of Mesopotamia; and the children of Israel served Cushan-Rishathaim eight years.", 
                "num": 8
              }, 
              {
                "text": "When the children of Israel cried out to the LORD, the LORD raised up a deliverer for the children of Israel, who delivered them: Othniel the son of Kenaz, Caleb's younger brother.", 
                "num": 9
              }, 
              {
                "text": "The Spirit of the LORD came upon him, and he judged Israel. He went out to war, and the LORD delivered Cushan-Rishathaim king of Mesopotamia into his hand; and his hand prevailed over Cushan-Rishathaim.", 
                "num": 10
              }, 
              {
                "text": "So the land had rest for forty years. Then Othniel the son of Kenaz died.", 
                "num": 11
              }, 
              {
                "text": "And the children of Israel again did evil in the sight of the LORD. So the LORD strengthened Eglon king of Moab against Israel, because they had done evil in the sight of the LORD.", 
                "num": 12
              }, 
              {
                "text": "Then he gathered to himself the people of Ammon and Amalek, went and defeated Israel, and took possession of the City of Palms.", 
                "num": 13
              }, 
              {
                "text": "So the children of Israel served Eglon king of Moab eighteen years.", 
                "num": 14
              }, 
              {
                "text": "But when the children of Israel cried out to the LORD, the LORD raised up a deliverer for them: Ehud the son of Gera, the Benjamite, a left-handed man. By him the children of Israel sent tribute to Eglon king of Moab.", 
                "num": 15
              }, 
              {
                "text": "Now Ehud made himself a dagger (it was double-edged and a cubit in length) and fastened it under his clothes on his right thigh.", 
                "num": 16
              }, 
              {
                "text": "So he brought the tribute to Eglon king of Moab. (Now Eglon was a very fat man.)", 
                "num": 17
              }, 
              {
                "text": "And when he had finished presenting the tribute, he sent away the people who had carried the tribute.", 
                "num": 18
              }, 
              {
                "text": "But he himself turned back from the stone images that were at Gilgal, and said, \"I have a secret message for you, O king.\" He said, \"Keep silence!\" And all who attended him went out from him.", 
                "num": 19
              }, 
              {
                "text": "So Ehud came to him (now he was sitting upstairs in his cool private chamber). Then Ehud said, \"I have a message from God for you.\" So he arose from his seat.", 
                "num": 20
              }, 
              {
                "text": "Then Ehud reached with his left hand, took the dagger from his right thigh, and thrust it into his belly.", 
                "num": 21
              }, 
              {
                "text": "Even the hilt went in after the blade, and the fat closed over the blade, for he did not draw the dagger out of his belly; and his entrails came out.", 
                "num": 22
              }, 
              {
                "text": "Then Ehud went out through the porch and shut the doors of the upper room behind him and locked them.", 
                "num": 23
              }, 
              {
                "text": "When he had gone out, Eglon's servants came to look, and to their surprise, the doors of the upper room were locked. So they said, \"He is probably attending to his needs in the cool chamber.\"", 
                "num": 24
              }, 
              {
                "text": "So they waited till they were embarrassed, and still he had not opened the doors of the upper room. Therefore they took the key and opened them. And there was their master, fallen dead on the floor.", 
                "num": 25
              }, 
              {
                "text": "But Ehud had escaped while they delayed, and passed beyond the stone images and escaped to Seirah.", 
                "num": 26
              }, 
              {
                "text": "And it happened, when he arrived, that he blew the trumpet in the mountains of Ephraim, and the children of Israel went down with him from the mountains; and he led them.", 
                "num": 27
              }, 
              {
                "text": "Then he said to them, \"Follow me, for the LORD has delivered your enemies the Moabites into your hand.\" So they went down after him, seized the fords of the Jordan leading to Moab, and did not allow anyone to cross over.", 
                "num": 28
              }, 
              {
                "text": "And at that time they killed about ten thousand men of Moab, all stout men of valor; not a man escaped.", 
                "num": 29
              }, 
              {
                "text": "So Moab was subdued that day under the hand of Israel. And the land had rest for eighty years.", 
                "num": 30
              }, 
              {
                "text": "After him was Shamgar the son of Anath, who killed six hundred men of the Philistines with an ox goad; and he also delivered Israel.", 
                "num": 31
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "When Ehud was dead, the children of Israel again did evil in the sight of the LORD.", 
                "num": 1
              }, 
              {
                "text": "So the LORD sold them into the hand of Jabin king of Canaan, who reigned in Hazor. The commander of his army was Sisera, who dwelt in Harosheth Hagoyim.", 
                "num": 2
              }, 
              {
                "text": "And the children of Israel cried out to the LORD; for Jabin had nine hundred chariots of iron, and for twenty years he had harshly oppressed the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "Now Deborah, a prophetess, the wife of Lapidoth, was judging Israel at that time.", 
                "num": 4
              }, 
              {
                "text": "And she would sit under the palm tree of Deborah between Ramah and Bethel in the mountains of Ephraim. And the children of Israel came up to her for judgment.", 
                "num": 5
              }, 
              {
                "text": "Then she sent and called for Barak the son of Abinoam from Kedesh in Naphtali, and said to him, \"Has not the LORD God of Israel commanded, \"Go and deploy troops at Mount Tabor; take with you ten thousand men of the sons of Naphtali and of the sons of Zebulun;", 
                "num": 6
              }, 
              {
                "text": "and against you I will deploy Sisera, the commander of Jabin's army, with his chariots and his multitude at the River Kishon; and I will deliver him into your hand'?\"", 
                "num": 7
              }, 
              {
                "text": "And Barak said to her, \"If you will go with me, then I will go; but if you will not go with me, I will not go!\"", 
                "num": 8
              }, 
              {
                "text": "So she said, \"I will surely go with you; nevertheless there will be no glory for you in the journey you are taking, for the LORD will sell Sisera into the hand of a woman.\" Then Deborah arose and went with Barak to Kedesh.", 
                "num": 9
              }, 
              {
                "text": "And Barak called Zebulun and Naphtali to Kedesh; he went up with ten thousand men under his command, and Deborah went up with him.", 
                "num": 10
              }, 
              {
                "text": "Now Heber the Kenite, of the children of Hobab the father-in-law of Moses, had separated himself from the Kenites and pitched his tent near the terebinth tree at Zaanaim, which is beside Kedesh.", 
                "num": 11
              }, 
              {
                "text": "And they reported to Sisera that Barak the son of Abinoam had gone up to Mount Tabor.", 
                "num": 12
              }, 
              {
                "text": "So Sisera gathered together all his chariots, nine hundred chariots of iron, and all the people who were with him, from Harosheth Hagoyim to the River Kishon.", 
                "num": 13
              }, 
              {
                "text": "Then Deborah said to Barak, \"Up! For this is the day in which the LORD has delivered Sisera into your hand. Has not the LORD gone out before you?\" So Barak went down from Mount Tabor with ten thousand men following him.", 
                "num": 14
              }, 
              {
                "text": "And the LORD routed Sisera and all his chariots and all his army with the edge of the sword before Barak; and Sisera alighted from his chariot and fled away on foot.", 
                "num": 15
              }, 
              {
                "text": "But Barak pursued the chariots and the army as far as Harosheth Hagoyim, and all the army of Sisera fell by the edge of the sword; not a man was left.", 
                "num": 16
              }, 
              {
                "text": "However, Sisera had fled away on foot to the tent of Jael, the wife of Heber the Kenite; for there was peace between Jabin king of Hazor and the house of Heber the Kenite.", 
                "num": 17
              }, 
              {
                "text": "And Jael went out to meet Sisera, and said to him, \"Turn aside, my lord, turn aside to me; do not fear.\" And when he had turned aside with her into the tent, she covered him with a blanket.", 
                "num": 18
              }, 
              {
                "text": "Then he said to her, \"Please give me a little water to drink, for I am thirsty.\" So she opened a jug of milk, gave him a drink, and covered him.", 
                "num": 19
              }, 
              {
                "text": "And he said to her, \"Stand at the door of the tent, and if any man comes and inquires of you, and says, \"Is there any man here?' you shall say, \"No.\"'", 
                "num": 20
              }, 
              {
                "text": "Then Jael, Heber's wife, took a tent peg and took a hammer in her hand, and went softly to him and drove the peg into his temple, and it went down into the ground; for he was fast asleep and weary. So he died.", 
                "num": 21
              }, 
              {
                "text": "And then, as Barak pursued Sisera, Jael came out to meet him, and said to him, \"Come, I will show you the man whom you seek.\" And when he went into her tent, there lay Sisera, dead with the peg in his temple.", 
                "num": 22
              }, 
              {
                "text": "So on that day God subdued Jabin king of Canaan in the presence of the children of Israel.", 
                "num": 23
              }, 
              {
                "text": "And the hand of the children of Israel grew stronger and stronger against Jabin king of Canaan, until they had destroyed Jabin king of Canaan.", 
                "num": 24
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then Deborah and Barak the son of Abinoam sang on that day, saying:", 
                "num": 1
              }, 
              {
                "text": "\"When leaders lead in Israel, When the people willingly offer themselves, Bless the LORD!", 
                "num": 2
              }, 
              {
                "text": "\"Hear, O kings! Give ear, O princes! I, even I, will sing to the LORD; I will sing praise to the LORD God of Israel.", 
                "num": 3
              }, 
              {
                "text": "\"LORD, when You went out from Seir, When You marched from the field of Edom, The earth trembled and the heavens poured, The clouds also poured water;", 
                "num": 4
              }, 
              {
                "text": "The mountains gushed before the LORD, This Sinai, before the LORD God of Israel.", 
                "num": 5
              }, 
              {
                "text": "\"In the days of Shamgar, son of Anath, In the days of Jael, The highways were deserted, And the travelers walked along the byways.", 
                "num": 6
              }, 
              {
                "text": "Village life ceased, it ceased in Israel, Until I, Deborah, arose, Arose a mother in Israel.", 
                "num": 7
              }, 
              {
                "text": "They chose new gods; Then there was war in the gates; Not a shield or spear was seen among forty thousand in Israel.", 
                "num": 8
              }, 
              {
                "text": "My heart is with the rulers of Israel Who offered themselves willingly with the people. Bless the LORD!", 
                "num": 9
              }, 
              {
                "text": "\"Speak, you who ride on white donkeys, Who sit in judges' attire, And who walk along the road.", 
                "num": 10
              }, 
              {
                "text": "Far from the noise of the archers, among the watering places, There they shall recount the righteous acts of the LORD, The righteous acts for His villagers in Israel; Then the people of the LORD shall go down to the gates.", 
                "num": 11
              }, 
              {
                "text": "\"Awake, awake, Deborah! Awake, awake, sing a song! Arise, Barak, and lead your captives away, O son of Abinoam!", 
                "num": 12
              }, 
              {
                "text": "\"Then the survivors came down, the people against the nobles; The LORD came down for me against the mighty.", 
                "num": 13
              }, 
              {
                "text": "From Ephraim were those whose roots were in Amalek. After you, Benjamin, with your peoples, From Machir rulers came down, And from Zebulun those who bear the recruiter's staff.", 
                "num": 14
              }, 
              {
                "text": "And the princes of Issachar were with Deborah; As Issachar, so was Barak Sent into the valley under his command; Among the divisions of Reuben There were great resolves of heart.", 
                "num": 15
              }, 
              {
                "text": "Why did you sit among the sheepfolds, To hear the pipings for the flocks? The divisions of Reuben have great searchings of heart.", 
                "num": 16
              }, 
              {
                "text": "Gilead stayed beyond the Jordan, And why did Dan remain on ships? Asher continued at the seashore, And stayed by his inlets.", 
                "num": 17
              }, 
              {
                "text": "Zebulun is a people who jeopardized their lives to the point of death, Naphtali also, on the heights of the battlefield.", 
                "num": 18
              }, 
              {
                "text": "\"The kings came and fought, Then the kings of Canaan fought In Taanach, by the waters of Megiddo; They took no spoils of silver.", 
                "num": 19
              }, 
              {
                "text": "They fought from the heavens; The stars from their courses fought against Sisera.", 
                "num": 20
              }, 
              {
                "text": "The torrent of Kishon swept them away, That ancient torrent, the torrent of Kishon. O my soul, march on in strength!", 
                "num": 21
              }, 
              {
                "text": "Then the horses' hooves pounded, The galloping, galloping of his steeds.", 
                "num": 22
              }, 
              {
                "text": "\"Curse Meroz,' said the angel of the LORD, \"Curse its inhabitants bitterly, Because they did not come to the help of the LORD, To the help of the LORD against the mighty.'", 
                "num": 23
              }, 
              {
                "text": "\"Most blessed among women is Jael, The wife of Heber the Kenite; Blessed is she among women in tents.", 
                "num": 24
              }, 
              {
                "text": "He asked for water, she gave milk; She brought out cream in a lordly bowl.", 
                "num": 25
              }, 
              {
                "text": "She stretched her hand to the tent peg, Her right hand to the workmen's hammer; She pounded Sisera, she pierced his head, She split and struck through his temple.", 
                "num": 26
              }, 
              {
                "text": "At her feet he sank, he fell, he lay still; At her feet he sank, he fell; Where he sank, there he fell dead.", 
                "num": 27
              }, 
              {
                "text": "\"The mother of Sisera looked through the window, And cried out through the lattice, \"Why is his chariot so long in coming? Why tarries the clatter of his chariots?'", 
                "num": 28
              }, 
              {
                "text": "Her wisest ladies answered her, Yes, she answered herself,", 
                "num": 29
              }, 
              {
                "text": "\"Are they not finding and dividing the spoil: To every man a girl or two; For Sisera, plunder of dyed garments, Plunder of garments embroidered and dyed, Two pieces of dyed embroidery for the neck of the looter?'", 
                "num": 30
              }, 
              {
                "text": "\"Thus let all Your enemies perish, O LORD! But let those who love Him be like the sun When it comes out in full strength.\" So the land had rest for forty years.", 
                "num": 31
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then the children of Israel did evil in the sight of the LORD. So the LORD delivered them into the hand of Midian for seven years,", 
                "num": 1
              }, 
              {
                "text": "and the hand of Midian prevailed against Israel. Because of the Midianites, the children of Israel made for themselves the dens, the caves, and the strongholds which are in the mountains.", 
                "num": 2
              }, 
              {
                "text": "So it was, whenever Israel had sown, Midianites would come up; also Amalekites and the people of the East would come up against them.", 
                "num": 3
              }, 
              {
                "text": "Then they would encamp against them and destroy the produce of the earth as far as Gaza, and leave no sustenance for Israel, neither sheep nor ox nor donkey.", 
                "num": 4
              }, 
              {
                "text": "For they would come up with their livestock and their tents, coming in as numerous as locusts; both they and their camels were without number; and they would enter the land to destroy it.", 
                "num": 5
              }, 
              {
                "text": "So Israel was greatly impoverished because of the Midianites, and the children of Israel cried out to the LORD.", 
                "num": 6
              }, 
              {
                "text": "And it came to pass, when the children of Israel cried out to the LORD because of the Midianites,", 
                "num": 7
              }, 
              {
                "text": "that the LORD sent a prophet to the children of Israel, who said to them, \"Thus says the LORD God of Israel: \"I brought you up from Egypt and brought you out of the house of bondage;", 
                "num": 8
              }, 
              {
                "text": "and I delivered you out of the hand of the Egyptians and out of the hand of all who oppressed you, and drove them out before you and gave you their land.", 
                "num": 9
              }, 
              {
                "text": "Also I said to you, \"I am the LORD your God; do not fear the gods of the Amorites, in whose land you dwell.\" But you have not obeyed My voice.\"'", 
                "num": 10
              }, 
              {
                "text": "Now the Angel of the LORD came and sat under the terebinth tree which was in Ophrah, which belonged to Joash the Abiezrite, while his son Gideon threshed wheat in the winepress, in order to hide it from the Midianites.", 
                "num": 11
              }, 
              {
                "text": "And the Angel of the LORD appeared to him, and said to him, \"The LORD is with you, you mighty man of valor!\"", 
                "num": 12
              }, 
              {
                "text": "Gideon said to Him, \"O my lord, if the LORD is with us, why then has all this happened to us? And where are all His miracles which our fathers told us about, saying, \"Did not the LORD bring us up from Egypt?' But now the LORD has forsaken us and delivered us into the hands of the Midianites.\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD turned to him and said, \"Go in this might of yours, and you shall save Israel from the hand of the Midianites. Have I not sent you?\"", 
                "num": 14
              }, 
              {
                "text": "So he said to Him, \"O my Lord, how can I save Israel? Indeed my clan is the weakest in Manasseh, and I am the least in my father's house.\"", 
                "num": 15
              }, 
              {
                "text": "And the LORD said to him, \"Surely I will be with you, and you shall defeat the Midianites as one man.\"", 
                "num": 16
              }, 
              {
                "text": "Then he said to Him, \"If now I have found favor in Your sight, then show me a sign that it is You who talk with me.", 
                "num": 17
              }, 
              {
                "text": "Do not depart from here, I pray, until I come to You and bring out my offering and set it before You.\" And He said, \"I will wait until you come back.\"", 
                "num": 18
              }, 
              {
                "text": "So Gideon went in and prepared a young goat, and unleavened bread from an ephah of flour. The meat he put in a basket, and he put the broth in a pot; and he brought them out to Him under the terebinth tree and presented them.", 
                "num": 19
              }, 
              {
                "text": "The Angel of God said to him, \"Take the meat and the unleavened bread and lay them on this rock, and pour out the broth.\" And he did so.", 
                "num": 20
              }, 
              {
                "text": "Then the Angel of the LORD put out the end of the staff that was in His hand, and touched the meat and the unleavened bread; and fire rose out of the rock and consumed the meat and the unleavened bread. And the Angel of the LORD departed out of his sight.", 
                "num": 21
              }, 
              {
                "text": "Now Gideon perceived that He was the Angel of the LORD. So Gideon said, \"Alas, O Lord GOD! For I have seen the Angel of the LORD face to face.\"", 
                "num": 22
              }, 
              {
                "text": "Then the LORD said to him, \"Peace be with you; do not fear, you shall not die.\"", 
                "num": 23
              }, 
              {
                "text": "So Gideon built an altar there to the LORD, and called it The-LORD-Is-Peace. To this day it is still in Ophrah of the Abiezrites.", 
                "num": 24
              }, 
              {
                "text": "Now it came to pass the same night that the LORD said to him, \"Take your father's young bull, the second bull of seven years old, and tear down the altar of Baal that your father has, and cut down the wooden image that is beside it;", 
                "num": 25
              }, 
              {
                "text": "and build an altar to the LORD your God on top of this rock in the proper arrangement, and take the second bull and offer a burnt sacrifice with the wood of the image which you shall cut down.\"", 
                "num": 26
              }, 
              {
                "text": "So Gideon took ten men from among his servants and did as the LORD had said to him. But because he feared his father's household and the men of the city too much to do it by day, he did it by night.", 
                "num": 27
              }, 
              {
                "text": "And when the men of the city arose early in the morning, there was the altar of Baal, torn down; and the wooden image that was beside it was cut down, and the second bull was being offered on the altar which had been built.", 
                "num": 28
              }, 
              {
                "text": "So they said to one another, \"Who has done this thing?\" And when they had inquired and asked, they said, \"Gideon the son of Joash has done this thing.\"", 
                "num": 29
              }, 
              {
                "text": "Then the men of the city said to Joash, \"Bring out your son, that he may die, because he has torn down the altar of Baal, and because he has cut down the wooden image that was beside it.\"", 
                "num": 30
              }, 
              {
                "text": "But Joash said to all who stood against him, \"Would you plead for Baal? Would you save him? Let the one who would plead for him be put to death by morning! If he is a god, let him plead for himself, because his altar has been torn down!\"", 
                "num": 31
              }, 
              {
                "text": "Therefore on that day he called him Jerubbaal, saying, \"Let Baal plead against him, because he has torn down his altar.\"", 
                "num": 32
              }, 
              {
                "text": "Then all the Midianites and Amalekites, the people of the East, gathered together; and they crossed over and encamped in the Valley of Jezreel.", 
                "num": 33
              }, 
              {
                "text": "But the Spirit of the LORD came upon Gideon; then he blew the trumpet, and the Abiezrites gathered behind him.", 
                "num": 34
              }, 
              {
                "text": "And he sent messengers throughout all Manasseh, who also gathered behind him. He also sent messengers to Asher, Zebulun, and Naphtali; and they came up to meet them.", 
                "num": 35
              }, 
              {
                "text": "So Gideon said to God, \"If You will save Israel by my hand as You have said--", 
                "num": 36
              }, 
              {
                "text": "look, I shall put a fleece of wool on the threshing floor; if there is dew on the fleece only, and it is dry on all the ground, then I shall know that You will save Israel by my hand, as You have said.\"", 
                "num": 37
              }, 
              {
                "text": "And it was so. When he rose early the next morning and squeezed the fleece together, he wrung the dew out of the fleece, a bowlful of water.", 
                "num": 38
              }, 
              {
                "text": "Then Gideon said to God, \"Do not be angry with me, but let me speak just once more: Let me test, I pray, just once more with the fleece; let it now be dry only on the fleece, but on all the ground let there be dew.\"", 
                "num": 39
              }, 
              {
                "text": "And God did so that night. It was dry on the fleece only, but there was dew on all the ground.", 
                "num": 40
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then Jerubbaal (that is, Gideon) and all the people who were with him rose early and encamped beside the well of Harod, so that the camp of the Midianites was on the north side of them by the hill of Moreh in the valley.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to Gideon, \"The people who are with you are too many for Me to give the Midianites into their hands, lest Israel claim glory for itself against Me, saying, \"My own hand has saved me.'", 
                "num": 2
              }, 
              {
                "text": "Now therefore, proclaim in the hearing of the people, saying, \"Whoever is fearful and afraid, let him turn and depart at once from Mount Gilead.\"' And twenty-two thousand of the people returned, and ten thousand remained.", 
                "num": 3
              }, 
              {
                "text": "But the LORD said to Gideon, \"The people are still too many; bring them down to the water, and I will test them for you there. Then it will be, that of whom I say to you, \"This one shall go with you,' the same shall go with you; and of whomever I say to you, \"This one shall not go with you,' the same shall not go.\"", 
                "num": 4
              }, 
              {
                "text": "So he brought the people down to the water. And the LORD said to Gideon, \"Everyone who laps from the water with his tongue, as a dog laps, you shall set apart by himself; likewise everyone who gets down on his knees to drink.\"", 
                "num": 5
              }, 
              {
                "text": "And the number of those who lapped, putting their hand to their mouth, was three hundred men; but all the rest of the people got down on their knees to drink water.", 
                "num": 6
              }, 
              {
                "text": "Then the LORD said to Gideon, \"By the three hundred men who lapped I will save you, and deliver the Midianites into your hand. Let all the other people go, every man to his place.\"", 
                "num": 7
              }, 
              {
                "text": "So the people took provisions and their trumpets in their hands. And he sent away all the rest of Israel, every man to his tent, and retained those three hundred men. Now the camp of Midian was below him in the valley.", 
                "num": 8
              }, 
              {
                "text": "It happened on the same night that the LORD said to him, \"Arise, go down against the camp, for I have delivered it into your hand.", 
                "num": 9
              }, 
              {
                "text": "But if you are afraid to go down, go down to the camp with Purah your servant,", 
                "num": 10
              }, 
              {
                "text": "and you shall hear what they say; and afterward your hands shall be strengthened to go down against the camp.\" Then he went down with Purah his servant to the outpost of the armed men who were in the camp.", 
                "num": 11
              }, 
              {
                "text": "Now the Midianites and Amalekites, all the people of the East, were lying in the valley as numerous as locusts; and their camels were without number, as the sand by the seashore in multitude.", 
                "num": 12
              }, 
              {
                "text": "And when Gideon had come, there was a man telling a dream to his companion. He said, \"I have had a dream: To my surprise, a loaf of barley bread tumbled into the camp of Midian; it came to a tent and struck it so that it fell and overturned, and the tent collapsed.\"", 
                "num": 13
              }, 
              {
                "text": "Then his companion answered and said, \"This is nothing else but the sword of Gideon the son of Joash, a man of Israel! Into his hand God has delivered Midian and the whole camp.\"", 
                "num": 14
              }, 
              {
                "text": "And so it was, when Gideon heard the telling of the dream and its interpretation, that he worshiped. He returned to the camp of Israel, and said, \"Arise, for the LORD has delivered the camp of Midian into your hand.\"", 
                "num": 15
              }, 
              {
                "text": "Then he divided the three hundred men into three companies, and he put a trumpet into every man's hand, with empty pitchers, and torches inside the pitchers.", 
                "num": 16
              }, 
              {
                "text": "And he said to them, \"Look at me and do likewise; watch, and when I come to the edge of the camp you shall do as I do:", 
                "num": 17
              }, 
              {
                "text": "When I blow the trumpet, I and all who are with me, then you also blow the trumpets on every side of the whole camp, and say, \"The sword of the LORD and of Gideon!\"'", 
                "num": 18
              }, 
              {
                "text": "So Gideon and the hundred men who were with him came to the outpost of the camp at the beginning of the middle watch, just as they had posted the watch; and they blew the trumpets and broke the pitchers that were in their hands.", 
                "num": 19
              }, 
              {
                "text": "Then the three companies blew the trumpets and broke the pitchers--they held the torches in their left hands and the trumpets in their right hands for blowing--and they cried, \"The sword of the LORD and of Gideon!\"", 
                "num": 20
              }, 
              {
                "text": "And every man stood in his place all around the camp; and the whole army ran and cried out and fled.", 
                "num": 21
              }, 
              {
                "text": "When the three hundred blew the trumpets, the LORD set every man's sword against his companion throughout the whole camp; and the army fled to Beth Acacia, toward Zererah, as far as the border of Abel Meholah, by Tabbath.", 
                "num": 22
              }, 
              {
                "text": "And the men of Israel gathered together from Naphtali, Asher, and all Manasseh, and pursued the Midianites.", 
                "num": 23
              }, 
              {
                "text": "Then Gideon sent messengers throughout all the mountains of Ephraim, saying, \"Come down against the Midianites, and seize from them the watering places as far as Beth Barah and the Jordan.\" Then all the men of Ephraim gathered together and seized the watering places as far as Beth Barah and the Jordan.", 
                "num": 24
              }, 
              {
                "text": "And they captured two princes of the Midianites, Oreb and Zeeb. They killed Oreb at the rock of Oreb, and Zeeb they killed at the winepress of Zeeb. They pursued Midian and brought the heads of Oreb and Zeeb to Gideon on the other side of the Jordan.", 
                "num": 25
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now the men of Ephraim said to him, \"Why have you done this to us by not calling us when you went to fight with the Midianites?\" And they reprimanded him sharply.", 
                "num": 1
              }, 
              {
                "text": "So he said to them, \"What have I done now in comparison with you? Is not the gleaning of the grapes of Ephraim better than the vintage of Abiezer?", 
                "num": 2
              }, 
              {
                "text": "God has delivered into your hands the princes of Midian, Oreb and Zeeb. And what was I able to do in comparison with you?\" Then their anger toward him subsided when he said that.", 
                "num": 3
              }, 
              {
                "text": "When Gideon came to the Jordan, he and the three hundred men who were with him crossed over, exhausted but still in pursuit.", 
                "num": 4
              }, 
              {
                "text": "Then he said to the men of Succoth, \"Please give loaves of bread to the people who follow me, for they are exhausted, and I am pursuing Zebah and Zalmunna, kings of Midian.\"", 
                "num": 5
              }, 
              {
                "text": "And the leaders of Succoth said, \"Are the hands of Zebah and Zalmunna now in your hand, that we should give bread to your army?\"", 
                "num": 6
              }, 
              {
                "text": "So Gideon said, \"For this cause, when the LORD has delivered Zebah and Zalmunna into my hand, then I will tear your flesh with the thorns of the wilderness and with briers!\"", 
                "num": 7
              }, 
              {
                "text": "Then he went up from there to Penuel and spoke to them in the same way. And the men of Penuel answered him as the men of Succoth had answered.", 
                "num": 8
              }, 
              {
                "text": "So he also spoke to the men of Penuel, saying, \"When I come back in peace, I will tear down this tower!\"", 
                "num": 9
              }, 
              {
                "text": "Now Zebah and Zalmunna were at Karkor, and their armies with them, about fifteen thousand, all who were left of all the army of the people of the East; for one hundred and twenty thousand men who drew the sword had fallen.", 
                "num": 10
              }, 
              {
                "text": "Then Gideon went up by the road of those who dwell in tents on the east of Nobah and Jogbehah; and he attacked the army while the camp felt secure.", 
                "num": 11
              }, 
              {
                "text": "When Zebah and Zalmunna fled, he pursued them; and he took the two kings of Midian, Zebah and Zalmunna, and routed the whole army.", 
                "num": 12
              }, 
              {
                "text": "Then Gideon the son of Joash returned from battle, from the Ascent of Heres.", 
                "num": 13
              }, 
              {
                "text": "And he caught a young man of the men of Succoth and interrogated him; and he wrote down for him the leaders of Succoth and its elders, seventy-seven men.", 
                "num": 14
              }, 
              {
                "text": "Then he came to the men of Succoth and said, \"Here are Zebah and Zalmunna, about whom you ridiculed me, saying, \"Are the hands of Zebah and Zalmunna now in your hand, that we should give bread to your weary men?\"'", 
                "num": 15
              }, 
              {
                "text": "And he took the elders of the city, and thorns of the wilderness and briers, and with them he taught the men of Succoth.", 
                "num": 16
              }, 
              {
                "text": "Then he tore down the tower of Penuel and killed the men of the city.", 
                "num": 17
              }, 
              {
                "text": "And he said to Zebah and Zalmunna, \"What kind of men were they whom you killed at Tabor?\" So they answered, \"As you are, so were they; each one resembled the son of a king.\"", 
                "num": 18
              }, 
              {
                "text": "Then he said, \"They were my brothers, the sons of my mother. As the LORD lives, if you had let them live, I would not kill you.\"", 
                "num": 19
              }, 
              {
                "text": "And he said to Jether his firstborn, \"Rise, kill them!\" But the youth would not draw his sword; for he was afraid, because he was still a youth.", 
                "num": 20
              }, 
              {
                "text": "So Zebah and Zalmunna said, \"Rise yourself, and kill us; for as a man is, so is his strength.\" So Gideon arose and killed Zebah and Zalmunna, and took the crescent ornaments that were on their camels' necks.", 
                "num": 21
              }, 
              {
                "text": "Then the men of Israel said to Gideon, \"Rule over us, both you and your son, and your grandson also; for you have delivered us from the hand of Midian.\"", 
                "num": 22
              }, 
              {
                "text": "But Gideon said to them, \"I will not rule over you, nor shall my son rule over you; the LORD shall rule over you.\"", 
                "num": 23
              }, 
              {
                "text": "Then Gideon said to them, \"I would like to make a request of you, that each of you would give me the earrings from his plunder.\" For they had golden earrings, because they were Ishmaelites.", 
                "num": 24
              }, 
              {
                "text": "So they answered, \"We will gladly give them.\" And they spread out a garment, and each man threw into it the earrings from his plunder.", 
                "num": 25
              }, 
              {
                "text": "Now the weight of the gold earrings that he requested was one thousand seven hundred shekels of gold, besides the crescent ornaments, pendants, and purple robes which were on the kings of Midian, and besides the chains that were around their camels' necks.", 
                "num": 26
              }, 
              {
                "text": "Then Gideon made it into an ephod and set it up in his city, Ophrah. And all Israel played the harlot with it there. It became a snare to Gideon and to his house.", 
                "num": 27
              }, 
              {
                "text": "Thus Midian was subdued before the children of Israel, so that they lifted their heads no more. And the country was quiet for forty years in the days of Gideon.", 
                "num": 28
              }, 
              {
                "text": "Then Jerubbaal the son of Joash went and dwelt in his own house.", 
                "num": 29
              }, 
              {
                "text": "Gideon had seventy sons who were his own offspring, for he had many wives.", 
                "num": 30
              }, 
              {
                "text": "And his concubine who was in Shechem also bore him a son, whose name he called Abimelech.", 
                "num": 31
              }, 
              {
                "text": "Now Gideon the son of Joash died at a good old age, and was buried in the tomb of Joash his father, in Ophrah of the Abiezrites.", 
                "num": 32
              }, 
              {
                "text": "So it was, as soon as Gideon was dead, that the children of Israel again played the harlot with the Baals, and made Baal-Berith their god.", 
                "num": 33
              }, 
              {
                "text": "Thus the children of Israel did not remember the LORD their God, who had delivered them from the hands of all their enemies on every side;", 
                "num": 34
              }, 
              {
                "text": "nor did they show kindness to the house of Jerubbaal (Gideon) in accordance with the good he had done for Israel.", 
                "num": 35
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then Abimelech the son of Jerubbaal went to Shechem, to his mother's brothers, and spoke with them and with all the family of the house of his mother's father, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Please speak in the hearing of all the men of Shechem: \"Which is better for you, that all seventy of the sons of Jerubbaal reign over you, or that one reign over you?' Remember that I am your own flesh and bone.\"", 
                "num": 2
              }, 
              {
                "text": "And his mother's brothers spoke all these words concerning him in the hearing of all the men of Shechem; and their heart was inclined to follow Abimelech, for they said, \"He is our brother.\"", 
                "num": 3
              }, 
              {
                "text": "So they gave him seventy shekels of silver from the temple of Baal-Berith, with which Abimelech hired worthless and reckless men; and they followed him.", 
                "num": 4
              }, 
              {
                "text": "Then he went to his father's house at Ophrah and killed his brothers, the seventy sons of Jerubbaal, on one stone. But Jotham the youngest son of Jerubbaal was left, because he hid himself.", 
                "num": 5
              }, 
              {
                "text": "And all the men of Shechem gathered together, all of Beth Millo, and they went and made Abimelech king beside the terebinth tree at the pillar that was in Shechem.", 
                "num": 6
              }, 
              {
                "text": "Now when they told Jotham, he went and stood on top of Mount Gerizim, and lifted his voice and cried out. And he said to them: \"Listen to me, you men of Shechem, That God may listen to you!", 
                "num": 7
              }, 
              {
                "text": "\"The trees once went forth to anoint a king over them. And they said to the olive tree, \"Reign over us!'", 
                "num": 8
              }, 
              {
                "text": "But the olive tree said to them, \"Should I cease giving my oil, With which they honor God and men, And go to sway over trees?'", 
                "num": 9
              }, 
              {
                "text": "\"Then the trees said to the fig tree, \"You come and reign over us!'", 
                "num": 10
              }, 
              {
                "text": "But the fig tree said to them, \"Should I cease my sweetness and my good fruit, And go to sway over trees?'", 
                "num": 11
              }, 
              {
                "text": "\"Then the trees said to the vine, \"You come and reign over us!'", 
                "num": 12
              }, 
              {
                "text": "But the vine said to them, \"Should I cease my new wine, Which cheers both God and men, And go to sway over trees?'", 
                "num": 13
              }, 
              {
                "text": "\"Then all the trees said to the bramble, \"You come and reign over us!'", 
                "num": 14
              }, 
              {
                "text": "And the bramble said to the trees, \"If in truth you anoint me as king over you, Then come and take shelter in my shade; But if not, let fire come out of the bramble And devour the cedars of Lebanon!'", 
                "num": 15
              }, 
              {
                "text": "\"Now therefore, if you have acted in truth and sincerity in making Abimelech king, and if you have dealt well with Jerubbaal and his house, and have done to him as he deserves--", 
                "num": 16
              }, 
              {
                "text": "for my father fought for you, risked his life, and delivered you out of the hand of Midian;", 
                "num": 17
              }, 
              {
                "text": "but you have risen up against my father's house this day, and killed his seventy sons on one stone, and made Abimelech, the son of his female servant, king over the men of Shechem, because he is your brother--", 
                "num": 18
              }, 
              {
                "text": "if then you have acted in truth and sincerity with Jerubbaal and with his house this day, then rejoice in Abimelech, and let him also rejoice in you.", 
                "num": 19
              }, 
              {
                "text": "But if not, let fire come from Abimelech and devour the men of Shechem and Beth Millo; and let fire come from the men of Shechem and from Beth Millo and devour Abimelech!\"", 
                "num": 20
              }, 
              {
                "text": "And Jotham ran away and fled; and he went to Beer and dwelt there, for fear of Abimelech his brother.", 
                "num": 21
              }, 
              {
                "text": "After Abimelech had reigned over Israel three years,", 
                "num": 22
              }, 
              {
                "text": "God sent a spirit of ill will between Abimelech and the men of Shechem; and the men of Shechem dealt treacherously with Abimelech,", 
                "num": 23
              }, 
              {
                "text": "that the crime done to the seventy sons of Jerubbaal might be settled and their blood be laid on Abimelech their brother, who killed them, and on the men of Shechem, who aided him in the killing of his brothers.", 
                "num": 24
              }, 
              {
                "text": "And the men of Shechem set men in ambush against him on the tops of the mountains, and they robbed all who passed by them along that way; and it was told Abimelech.", 
                "num": 25
              }, 
              {
                "text": "Now Gaal the son of Ebed came with his brothers and went over to Shechem; and the men of Shechem put their confidence in him.", 
                "num": 26
              }, 
              {
                "text": "So they went out into the fields, and gathered grapes from their vineyards and trod them, and made merry. And they went into the house of their god, and ate and drank, and cursed Abimelech.", 
                "num": 27
              }, 
              {
                "text": "Then Gaal the son of Ebed said, \"Who is Abimelech, and who is Shechem, that we should serve him? Is he not the son of Jerubbaal, and is not Zebul his officer? Serve the men of Hamor the father of Shechem; but why should we serve him?", 
                "num": 28
              }, 
              {
                "text": "If only this people were under my authority! Then I would remove Abimelech.\" So he said to Abimelech, \"Increase your army and come out!\"", 
                "num": 29
              }, 
              {
                "text": "When Zebul, the ruler of the city, heard the words of Gaal the son of Ebed, his anger was aroused.", 
                "num": 30
              }, 
              {
                "text": "And he sent messengers to Abimelech secretly, saying, \"Take note! Gaal the son of Ebed and his brothers have come to Shechem; and here they are, fortifying the city against you.", 
                "num": 31
              }, 
              {
                "text": "Now therefore, get up by night, you and the people who are with you, and lie in wait in the field.", 
                "num": 32
              }, 
              {
                "text": "And it shall be, as soon as the sun is up in the morning, that you shall rise early and rush upon the city; and when he and the people who are with him come out against you, you may then do to them as you find opportunity.\"", 
                "num": 33
              }, 
              {
                "text": "So Abimelech and all the people who were with him rose by night, and lay in wait against Shechem in four companies.", 
                "num": 34
              }, 
              {
                "text": "When Gaal the son of Ebed went out and stood in the entrance to the city gate, Abimelech and the people who were with him rose from lying in wait.", 
                "num": 35
              }, 
              {
                "text": "And when Gaal saw the people, he said to Zebul, \"Look, people are coming down from the tops of the mountains!\" But Zebul said to him, \"You see the shadows of the mountains as if they were men.\"", 
                "num": 36
              }, 
              {
                "text": "So Gaal spoke again and said, \"See, people are coming down from the center of the land, and another company is coming from the Diviners' Terebinth Tree.\"", 
                "num": 37
              }, 
              {
                "text": "Then Zebul said to him, \"Where indeed is your mouth now, with which you said, \"Who is Abimelech, that we should serve him?' Are not these the people whom you despised? Go out, if you will, and fight with them now.\"", 
                "num": 38
              }, 
              {
                "text": "So Gaal went out, leading the men of Shechem, and fought with Abimelech.", 
                "num": 39
              }, 
              {
                "text": "And Abimelech chased him, and he fled from him; and many fell wounded, to the very entrance of the gate.", 
                "num": 40
              }, 
              {
                "text": "Then Abimelech dwelt at Arumah, and Zebul drove out Gaal and his brothers, so that they would not dwell in Shechem.", 
                "num": 41
              }, 
              {
                "text": "And it came about on the next day that the people went out into the field, and they told Abimelech.", 
                "num": 42
              }, 
              {
                "text": "So he took his people, divided them into three companies, and lay in wait in the field. And he looked, and there were the people, coming out of the city; and he rose against them and attacked them.", 
                "num": 43
              }, 
              {
                "text": "Then Abimelech and the company that was with him rushed forward and stood at the entrance of the gate of the city; and the other two companies rushed upon all who were in the fields and killed them.", 
                "num": 44
              }, 
              {
                "text": "So Abimelech fought against the city all that day; he took the city and killed the people who were in it; and he demolished the city and sowed it with salt.", 
                "num": 45
              }, 
              {
                "text": "Now when all the men of the tower of Shechem had heard that, they entered the stronghold of the temple of the god Berith.", 
                "num": 46
              }, 
              {
                "text": "And it was told Abimelech that all the men of the tower of Shechem were gathered together.", 
                "num": 47
              }, 
              {
                "text": "Then Abimelech went up to Mount Zalmon, he and all the people who were with him. And Abimelech took an ax in his hand and cut down a bough from the trees, and took it and laid it on his shoulder; then he said to the people who were with him, \"What you have seen me do, make haste and do as I have done.\"", 
                "num": 48
              }, 
              {
                "text": "So each of the people likewise cut down his own bough and followed Abimelech, put them against the stronghold, and set the stronghold on fire above them, so that all the people of the tower of Shechem died, about a thousand men and women.", 
                "num": 49
              }, 
              {
                "text": "Then Abimelech went to Thebez, and he encamped against Thebez and took it.", 
                "num": 50
              }, 
              {
                "text": "But there was a strong tower in the city, and all the men and women--all the people of the city--fled there and shut themselves in; then they went up to the top of the tower.", 
                "num": 51
              }, 
              {
                "text": "So Abimelech came as far as the tower and fought against it; and he drew near the door of the tower to burn it with fire.", 
                "num": 52
              }, 
              {
                "text": "But a certain woman dropped an upper millstone on Abimelech's head and crushed his skull.", 
                "num": 53
              }, 
              {
                "text": "Then he called quickly to the young man, his armorbearer, and said to him, \"Draw your sword and kill me, lest men say of me, \"A woman killed him.\"' So his young man thrust him through, and he died.", 
                "num": 54
              }, 
              {
                "text": "And when the men of Israel saw that Abimelech was dead, they departed, every man to his place.", 
                "num": 55
              }, 
              {
                "text": "Thus God repaid the wickedness of Abimelech, which he had done to his father by killing his seventy brothers.", 
                "num": 56
              }, 
              {
                "text": "And all the evil of the men of Shechem God returned on their own heads, and on them came the curse of Jotham the son of Jerubbaal.", 
                "num": 57
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "After Abimelech there arose to save Israel Tola the son of Puah, the son of Dodo, a man of Issachar; and he dwelt in Shamir in the mountains of Ephraim.", 
                "num": 1
              }, 
              {
                "text": "He judged Israel twenty-three years; and he died and was buried in Shamir.", 
                "num": 2
              }, 
              {
                "text": "After him arose Jair, a Gileadite; and he judged Israel twenty-two years.", 
                "num": 3
              }, 
              {
                "text": "Now he had thirty sons who rode on thirty donkeys; they also had thirty towns, which are called \"Havoth Jair\" to this day, which are in the land of Gilead.", 
                "num": 4
              }, 
              {
                "text": "And Jair died and was buried in Camon.", 
                "num": 5
              }, 
              {
                "text": "Then the children of Israel again did evil in the sight of the LORD, and served the Baals and the Ashtoreths, the gods of Syria, the gods of Sidon, the gods of Moab, the gods of the people of Ammon, and the gods of the Philistines; and they forsook the LORD and did not serve Him.", 
                "num": 6
              }, 
              {
                "text": "So the anger of the LORD was hot against Israel; and He sold them into the hands of the Philistines and into the hands of the people of Ammon.", 
                "num": 7
              }, 
              {
                "text": "From that year they harassed and oppressed the children of Israel for eighteen years--all the children of Israel who were on the other side of the Jordan in the land of the Amorites, in Gilead.", 
                "num": 8
              }, 
              {
                "text": "Moreover the people of Ammon crossed over the Jordan to fight against Judah also, against Benjamin, and against the house of Ephraim, so that Israel was severely distressed.", 
                "num": 9
              }, 
              {
                "text": "And the children of Israel cried out to the LORD, saying, \"We have sinned against You, because we have both forsaken our God and served the Baals!\"", 
                "num": 10
              }, 
              {
                "text": "So the LORD said to the children of Israel, \"Did I not deliver you from the Egyptians and from the Amorites and from the people of Ammon and from the Philistines?", 
                "num": 11
              }, 
              {
                "text": "Also the Sidonians and Amalekites and Maonites oppressed you; and you cried out to Me, and I delivered you from their hand.", 
                "num": 12
              }, 
              {
                "text": "Yet you have forsaken Me and served other gods. Therefore I will deliver you no more.", 
                "num": 13
              }, 
              {
                "text": "Go and cry out to the gods which you have chosen; let them deliver you in your time of distress.\"", 
                "num": 14
              }, 
              {
                "text": "And the children of Israel said to the LORD, \"We have sinned! Do to us whatever seems best to You; only deliver us this day, we pray.\"", 
                "num": 15
              }, 
              {
                "text": "So they put away the foreign gods from among them and served the LORD. And His soul could no longer endure the misery of Israel.", 
                "num": 16
              }, 
              {
                "text": "Then the people of Ammon gathered together and encamped in Gilead. And the children of Israel assembled together and encamped in Mizpah.", 
                "num": 17
              }, 
              {
                "text": "And the people, the leaders of Gilead, said to one another, \"Who is the man who will begin the fight against the people of Ammon? He shall be head over all the inhabitants of Gilead.\"", 
                "num": 18
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now Jephthah the Gileadite was a mighty man of valor, but he was the son of a harlot; and Gilead begot Jephthah.", 
                "num": 1
              }, 
              {
                "text": "Gilead's wife bore sons; and when his wife's sons grew up, they drove Jephthah out, and said to him, \"You shall have no inheritance in our father's house, for you are the son of another woman.\"", 
                "num": 2
              }, 
              {
                "text": "Then Jephthah fled from his brothers and dwelt in the land of Tob; and worthless men banded together with Jephthah and went out raiding with him.", 
                "num": 3
              }, 
              {
                "text": "It came to pass after a time that the people of Ammon made war against Israel.", 
                "num": 4
              }, 
              {
                "text": "And so it was, when the people of Ammon made war against Israel, that the elders of Gilead went to get Jephthah from the land of Tob.", 
                "num": 5
              }, 
              {
                "text": "Then they said to Jephthah, \"Come and be our commander, that we may fight against the people of Ammon.\"", 
                "num": 6
              }, 
              {
                "text": "So Jephthah said to the elders of Gilead, \"Did you not hate me, and expel me from my father's house? Why have you come to me now when you are in distress?\"", 
                "num": 7
              }, 
              {
                "text": "And the elders of Gilead said to Jephthah, \"That is why we have turned again to you now, that you may go with us and fight against the people of Ammon, and be our head over all the inhabitants of Gilead.\"", 
                "num": 8
              }, 
              {
                "text": "So Jephthah said to the elders of Gilead, \"If you take me back home to fight against the people of Ammon, and the LORD delivers them to me, shall I be your head?\"", 
                "num": 9
              }, 
              {
                "text": "And the elders of Gilead said to Jephthah, \"The LORD will be a witness between us, if we do not do according to your words.\"", 
                "num": 10
              }, 
              {
                "text": "Then Jephthah went with the elders of Gilead, and the people made him head and commander over them; and Jephthah spoke all his words before the LORD in Mizpah.", 
                "num": 11
              }, 
              {
                "text": "Now Jephthah sent messengers to the king of the people of Ammon, saying, \"What do you have against me, that you have come to fight against me in my land?\"", 
                "num": 12
              }, 
              {
                "text": "And the king of the people of Ammon answered the messengers of Jephthah, \"Because Israel took away my land when they came up out of Egypt, from the Arnon as far as the Jabbok, and to the Jordan. Now therefore, restore those lands peaceably.\"", 
                "num": 13
              }, 
              {
                "text": "So Jephthah again sent messengers to the king of the people of Ammon,", 
                "num": 14
              }, 
              {
                "text": "and said to him, \"Thus says Jephthah: \"Israel did not take away the land of Moab, nor the land of the people of Ammon;", 
                "num": 15
              }, 
              {
                "text": "for when Israel came up from Egypt, they walked through the wilderness as far as the Red Sea and came to Kadesh.", 
                "num": 16
              }, 
              {
                "text": "Then Israel sent messengers to the king of Edom, saying, \"Please let me pass through your land.\" But the king of Edom would not heed. And in like manner they sent to the king of Moab, but he would not consent. So Israel remained in Kadesh.", 
                "num": 17
              }, 
              {
                "text": "And they went along through the wilderness and bypassed the land of Edom and the land of Moab, came to the east side of the land of Moab, and encamped on the other side of the Arnon. But they did not enter the border of Moab, for the Arnon was the border of Moab.", 
                "num": 18
              }, 
              {
                "text": "Then Israel sent messengers to Sihon king of the Amorites, king of Heshbon; and Israel said to him, \"Please let us pass through your land into our place.\"", 
                "num": 19
              }, 
              {
                "text": "But Sihon did not trust Israel to pass through his territory. So Sihon gathered all his people together, encamped in Jahaz, and fought against Israel.", 
                "num": 20
              }, 
              {
                "text": "And the LORD God of Israel delivered Sihon and all his people into the hand of Israel, and they defeated them. Thus Israel gained possession of all the land of the Amorites, who inhabited that country.", 
                "num": 21
              }, 
              {
                "text": "They took possession of all the territory of the Amorites, from the Arnon to the Jabbok and from the wilderness to the Jordan.", 
                "num": 22
              }, 
              {
                "text": "\"And now the LORD God of Israel has dispossessed the Amorites from before His people Israel; should you then possess it?", 
                "num": 23
              }, 
              {
                "text": "Will you not possess whatever Chemosh your god gives you to possess? So whatever the LORD our God takes possession of before us, we will possess.", 
                "num": 24
              }, 
              {
                "text": "And now, are you any better than Balak the son of Zippor, king of Moab? Did he ever strive against Israel? Did he ever fight against them?", 
                "num": 25
              }, 
              {
                "text": "While Israel dwelt in Heshbon and its villages, in Aroer and its villages, and in all the cities along the banks of the Arnon, for three hundred years, why did you not recover them within that time?", 
                "num": 26
              }, 
              {
                "text": "Therefore I have not sinned against you, but you wronged me by fighting against me. May the LORD, the Judge, render judgment this day between the children of Israel and the people of Ammon.\"'", 
                "num": 27
              }, 
              {
                "text": "However, the king of the people of Ammon did not heed the words which Jephthah sent him.", 
                "num": 28
              }, 
              {
                "text": "Then the Spirit of the LORD came upon Jephthah, and he passed through Gilead and Manasseh, and passed through Mizpah of Gilead; and from Mizpah of Gilead he advanced toward the people of Ammon.", 
                "num": 29
              }, 
              {
                "text": "And Jephthah made a vow to the LORD, and said, \"If You will indeed deliver the people of Ammon into my hands,", 
                "num": 30
              }, 
              {
                "text": "then it will be that whatever comes out of the doors of my house to meet me, when I return in peace from the people of Ammon, shall surely be the LORD's, and I will offer it up as a burnt offering.\"", 
                "num": 31
              }, 
              {
                "text": "So Jephthah advanced toward the people of Ammon to fight against them, and the LORD delivered them into his hands.", 
                "num": 32
              }, 
              {
                "text": "And he defeated them from Aroer as far as Minnith--twenty cities--and to Abel Keramim, with a very great slaughter. Thus the people of Ammon were subdued before the children of Israel.", 
                "num": 33
              }, 
              {
                "text": "When Jephthah came to his house at Mizpah, there was his daughter, coming out to meet him with timbrels and dancing; and she was his only child. Besides her he had neither son nor daughter.", 
                "num": 34
              }, 
              {
                "text": "And it came to pass, when he saw her, that he tore his clothes, and said, \"Alas, my daughter! You have brought me very low! You are among those who trouble me! For I have given my word to the LORD, and I cannot go back on it.\"", 
                "num": 35
              }, 
              {
                "text": "So she said to him, \"My father, if you have given your word to the LORD, do to me according to what has gone out of your mouth, because the LORD has avenged you of your enemies, the people of Ammon.\"", 
                "num": 36
              }, 
              {
                "text": "Then she said to her father, \"Let this thing be done for me: let me alone for two months, that I may go and wander on the mountains and bewail my virginity, my friends and I.\"", 
                "num": 37
              }, 
              {
                "text": "So he said, \"Go.\" And he sent her away for two months; and she went with her friends, and bewailed her virginity on the mountains.", 
                "num": 38
              }, 
              {
                "text": "And it was so at the end of two months that she returned to her father, and he carried out his vow with her which he had vowed. She knew no man. And it became a custom in Israel", 
                "num": 39
              }, 
              {
                "text": "that the daughters of Israel went four days each year to lament the daughter of Jephthah the Gileadite.", 
                "num": 40
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then the men of Ephraim gathered together, crossed over toward Zaphon, and said to Jephthah, \"Why did you cross over to fight against the people of Ammon, and did not call us to go with you? We will burn your house down on you with fire!\"", 
                "num": 1
              }, 
              {
                "text": "And Jephthah said to them, \"My people and I were in a great struggle with the people of Ammon; and when I called you, you did not deliver me out of their hands.", 
                "num": 2
              }, 
              {
                "text": "So when I saw that you would not deliver me, I took my life in my hands and crossed over against the people of Ammon; and the LORD delivered them into my hand. Why then have you come up to me this day to fight against me?\"", 
                "num": 3
              }, 
              {
                "text": "Now Jephthah gathered together all the men of Gilead and fought against Ephraim. And the men of Gilead defeated Ephraim, because they said, \"You Gileadites are fugitives of Ephraim among the Ephraimites and among the Manassites.\"", 
                "num": 4
              }, 
              {
                "text": "The Gileadites seized the fords of the Jordan before the Ephraimites arrived. And when any Ephraimite who escaped said, \"Let me cross over,\" the men of Gilead would say to him, \"Are you an Ephraimite?\" If he said, \"No,\"", 
                "num": 5
              }, 
              {
                "text": "then they would say to him, \"Then say, \"Shibboleth'!\" And he would say, \"Sibboleth,\" for he could not pronounce it right. Then they would take him and kill him at the fords of the Jordan. There fell at that time forty-two thousand Ephraimites.", 
                "num": 6
              }, 
              {
                "text": "And Jephthah judged Israel six years. Then Jephthah the Gileadite died and was buried among the cities of Gilead.", 
                "num": 7
              }, 
              {
                "text": "After him, Ibzan of Bethlehem judged Israel.", 
                "num": 8
              }, 
              {
                "text": "He had thirty sons. And he gave away thirty daughters in marriage, and brought in thirty daughters from elsewhere for his sons. He judged Israel seven years.", 
                "num": 9
              }, 
              {
                "text": "Then Ibzan died and was buried at Bethlehem.", 
                "num": 10
              }, 
              {
                "text": "After him, Elon the Zebulunite judged Israel. He judged Israel ten years.", 
                "num": 11
              }, 
              {
                "text": "And Elon the Zebulunite died and was buried at Aijalon in the country of Zebulun.", 
                "num": 12
              }, 
              {
                "text": "After him, Abdon the son of Hillel the Pirathonite judged Israel.", 
                "num": 13
              }, 
              {
                "text": "He had forty sons and thirty grandsons, who rode on seventy young donkeys. He judged Israel eight years.", 
                "num": 14
              }, 
              {
                "text": "Then Abdon the son of Hillel the Pirathonite died and was buried in Pirathon in the land of Ephraim, in the mountains of the Amalekites.", 
                "num": 15
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Again the children of Israel did evil in the sight of the LORD, and the LORD delivered them into the hand of the Philistines for forty years.", 
                "num": 1
              }, 
              {
                "text": "Now there was a certain man from Zorah, of the family of the Danites, whose name was Manoah; and his wife was barren and had no children.", 
                "num": 2
              }, 
              {
                "text": "And the Angel of the LORD appeared to the woman and said to her, \"Indeed now, you are barren and have borne no children, but you shall conceive and bear a son.", 
                "num": 3
              }, 
              {
                "text": "Now therefore, please be careful not to drink wine or similar drink, and not to eat anything unclean.", 
                "num": 4
              }, 
              {
                "text": "For behold, you shall conceive and bear a son. And no razor shall come upon his head, for the child shall be a Nazirite to God from the womb; and he shall begin to deliver Israel out of the hand of the Philistines.\"", 
                "num": 5
              }, 
              {
                "text": "So the woman came and told her husband, saying, \"A Man of God came to me, and His countenance was like the countenance of the Angel of God, very awesome; but I did not ask Him where He was from, and He did not tell me His name.", 
                "num": 6
              }, 
              {
                "text": "And He said to me, \"Behold, you shall conceive and bear a son. Now drink no wine or similar drink, nor eat anything unclean, for the child shall be a Nazirite to God from the womb to the day of his death.\"'", 
                "num": 7
              }, 
              {
                "text": "Then Manoah prayed to the LORD, and said, \"O my Lord, please let the Man of God whom You sent come to us again and teach us what we shall do for the child who will be born.\"", 
                "num": 8
              }, 
              {
                "text": "And God listened to the voice of Manoah, and the Angel of God came to the woman again as she was sitting in the field; but Manoah her husband was not with her.", 
                "num": 9
              }, 
              {
                "text": "Then the woman ran in haste and told her husband, and said to him, \"Look, the Man who came to me the other day has just now appeared to me!\"", 
                "num": 10
              }, 
              {
                "text": "So Manoah arose and followed his wife. When he came to the Man, he said to Him, \"Are You the Man who spoke to this woman?\" And He said, \"I am.\"", 
                "num": 11
              }, 
              {
                "text": "Manoah said, \"Now let Your words come to pass! What will be the boy's rule of life, and his work?\"", 
                "num": 12
              }, 
              {
                "text": "So the Angel of the LORD said to Manoah, \"Of all that I said to the woman let her be careful.", 
                "num": 13
              }, 
              {
                "text": "She may not eat anything that comes from the vine, nor may she drink wine or similar drink, nor eat anything unclean. All that I commanded her let her observe.\"", 
                "num": 14
              }, 
              {
                "text": "Then Manoah said to the Angel of the LORD, \"Please let us detain You, and we will prepare a young goat for You.\"", 
                "num": 15
              }, 
              {
                "text": "And the Angel of the LORD said to Manoah, \"Though you detain Me, I will not eat your food. But if you offer a burnt offering, you must offer it to the LORD.\" (For Manoah did not know He was the Angel of the LORD.)", 
                "num": 16
              }, 
              {
                "text": "Then Manoah said to the Angel of the LORD, \"What is Your name, that when Your words come to pass we may honor You?\"", 
                "num": 17
              }, 
              {
                "text": "And the Angel of the LORD said to him, \"Why do you ask My name, seeing it is wonderful?\"", 
                "num": 18
              }, 
              {
                "text": "So Manoah took the young goat with the grain offering, and offered it upon the rock to the LORD. And He did a wondrous thing while Manoah and his wife looked on--", 
                "num": 19
              }, 
              {
                "text": "it happened as the flame went up toward heaven from the altar--the Angel of the LORD ascended in the flame of the altar! When Manoah and his wife saw this, they fell on their faces to the ground.", 
                "num": 20
              }, 
              {
                "text": "When the Angel of the LORD appeared no more to Manoah and his wife, then Manoah knew that He was the Angel of the LORD.", 
                "num": 21
              }, 
              {
                "text": "And Manoah said to his wife, \"We shall surely die, because we have seen God!\"", 
                "num": 22
              }, 
              {
                "text": "But his wife said to him, \"If the LORD had desired to kill us, He would not have accepted a burnt offering and a grain offering from our hands, nor would He have shown us all these things, nor would He have told us such things as these at this time.\"", 
                "num": 23
              }, 
              {
                "text": "So the woman bore a son and called his name Samson; and the child grew, and the LORD blessed him.", 
                "num": 24
              }, 
              {
                "text": "And the Spirit of the LORD began to move upon him at Mahaneh Dan between Zorah and Eshtaol.", 
                "num": 25
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now Samson went down to Timnah, and saw a woman in Timnah of the daughters of the Philistines.", 
                "num": 1
              }, 
              {
                "text": "So he went up and told his father and mother, saying, \"I have seen a woman in Timnah of the daughters of the Philistines; now therefore, get her for me as a wife.\"", 
                "num": 2
              }, 
              {
                "text": "Then his father and mother said to him, \"Is there no woman among the daughters of your brethren, or among all my people, that you must go and get a wife from the uncircumcised Philistines?\" And Samson said to his father, \"Get her for me, for she pleases me well.\"", 
                "num": 3
              }, 
              {
                "text": "But his father and mother did not know that it was of the LORD--that He was seeking an occasion to move against the Philistines. For at that time the Philistines had dominion over Israel.", 
                "num": 4
              }, 
              {
                "text": "So Samson went down to Timnah with his father and mother, and came to the vineyards of Timnah. Now to his surprise, a young lion came roaring against him.", 
                "num": 5
              }, 
              {
                "text": "And the Spirit of the LORD came mightily upon him, and he tore the lion apart as one would have torn apart a young goat, though he had nothing in his hand. But he did not tell his father or his mother what he had done.", 
                "num": 6
              }, 
              {
                "text": "Then he went down and talked with the woman; and she pleased Samson well.", 
                "num": 7
              }, 
              {
                "text": "After some time, when he returned to get her, he turned aside to see the carcass of the lion. And behold, a swarm of bees and honey were in the carcass of the lion.", 
                "num": 8
              }, 
              {
                "text": "He took some of it in his hands and went along, eating. When he came to his father and mother, he gave some to them, and they also ate. But he did not tell them that he had taken the honey out of the carcass of the lion.", 
                "num": 9
              }, 
              {
                "text": "So his father went down to the woman. And Samson gave a feast there, for young men used to do so.", 
                "num": 10
              }, 
              {
                "text": "And it happened, when they saw him, that they brought thirty companions to be with him.", 
                "num": 11
              }, 
              {
                "text": "Then Samson said to them, \"Let me pose a riddle to you. If you can correctly solve and explain it to me within the seven days of the feast, then I will give you thirty linen garments and thirty changes of clothing.", 
                "num": 12
              }, 
              {
                "text": "But if you cannot explain it to me, then you shall give me thirty linen garments and thirty changes of clothing.\" And they said to him, \"Pose your riddle, that we may hear it.\"", 
                "num": 13
              }, 
              {
                "text": "So he said to them: \"Out of the eater came something to eat, And out of the strong came something sweet.\" Now for three days they could not explain the riddle.", 
                "num": 14
              }, 
              {
                "text": "But it came to pass on the seventh day that they said to Samson's wife, \"Entice your husband, that he may explain the riddle to us, or else we will burn you and your father's house with fire. Have you invited us in order to take what is ours? Is that not so?\"", 
                "num": 15
              }, 
              {
                "text": "Then Samson's wife wept on him, and said, \"You only hate me! You do not love me! You have posed a riddle to the sons of my people, but you have not explained it to me.\" And he said to her, \"Look, I have not explained it to my father or my mother; so should I explain it to you?\"", 
                "num": 16
              }, 
              {
                "text": "Now she had wept on him the seven days while their feast lasted. And it happened on the seventh day that he told her, because she pressed him so much. Then she explained the riddle to the sons of her people.", 
                "num": 17
              }, 
              {
                "text": "So the men of the city said to him on the seventh day before the sun went down: \"What is sweeter than honey? And what is stronger than a lion?\" And he said to them: \"If you had not plowed with my heifer, You would not have solved my riddle!\"", 
                "num": 18
              }, 
              {
                "text": "Then the Spirit of the LORD came upon him mightily, and he went down to Ashkelon and killed thirty of their men, took their apparel, and gave the changes of clothing to those who had explained the riddle. So his anger was aroused, and he went back up to his father's house.", 
                "num": 19
              }, 
              {
                "text": "And Samson's wife was given to his companion, who had been his best man.", 
                "num": 20
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "After a while, in the time of wheat harvest, it happened that Samson visited his wife with a young goat. And he said, \"Let me go in to my wife, into her room.\" But her father would not permit him to go in.", 
                "num": 1
              }, 
              {
                "text": "Her father said, \"I really thought that you thoroughly hated her; therefore I gave her to your companion. Is not her younger sister better than she? Please, take her instead.\"", 
                "num": 2
              }, 
              {
                "text": "And Samson said to them, \"This time I shall be blameless regarding the Philistines if I harm them!\"", 
                "num": 3
              }, 
              {
                "text": "Then Samson went and caught three hundred foxes; and he took torches, turned the foxes tail to tail, and put a torch between each pair of tails.", 
                "num": 4
              }, 
              {
                "text": "When he had set the torches on fire, he let the foxes go into the standing grain of the Philistines, and burned up both the shocks and the standing grain, as well as the vineyards and olive groves.", 
                "num": 5
              }, 
              {
                "text": "Then the Philistines said, \"Who has done this?\" And they answered, \"Samson, the son-in-law of the Timnite, because he has taken his wife and given her to his companion.\" So the Philistines came up and burned her and her father with fire.", 
                "num": 6
              }, 
              {
                "text": "Samson said to them, \"Since you would do a thing like this, I will surely take revenge on you, and after that I will cease.\"", 
                "num": 7
              }, 
              {
                "text": "So he attacked them hip and thigh with a great slaughter; then he went down and dwelt in the cleft of the rock of Etam.", 
                "num": 8
              }, 
              {
                "text": "Now the Philistines went up, encamped in Judah, and deployed themselves against Lehi.", 
                "num": 9
              }, 
              {
                "text": "And the men of Judah said, \"Why have you come up against us?\" So they answered, \"We have come up to arrest Samson, to do to him as he has done to us.\"", 
                "num": 10
              }, 
              {
                "text": "Then three thousand men of Judah went down to the cleft of the rock of Etam, and said to Samson, \"Do you not know that the Philistines rule over us? What is this you have done to us?\" And he said to them, \"As they did to me, so I have done to them.\"", 
                "num": 11
              }, 
              {
                "text": "But they said to him, \"We have come down to arrest you, that we may deliver you into the hand of the Philistines.\" Then Samson said to them, \"Swear to me that you will not kill me yourselves.\"", 
                "num": 12
              }, 
              {
                "text": "So they spoke to him, saying, \"No, but we will tie you securely and deliver you into their hand; but we will surely not kill you.\" And they bound him with two new ropes and brought him up from the rock.", 
                "num": 13
              }, 
              {
                "text": "When he came to Lehi, the Philistines came shouting against him. Then the Spirit of the LORD came mightily upon him; and the ropes that were on his arms became like flax that is burned with fire, and his bonds broke loose from his hands.", 
                "num": 14
              }, 
              {
                "text": "He found a fresh jawbone of a donkey, reached out his hand and took it, and killed a thousand men with it.", 
                "num": 15
              }, 
              {
                "text": "Then Samson said: \"With the jawbone of a donkey, Heaps upon heaps, With the jawbone of a donkey I have slain a thousand men!\"", 
                "num": 16
              }, 
              {
                "text": "And so it was, when he had finished speaking, that he threw the jawbone from his hand, and called that place Ramath Lehi.", 
                "num": 17
              }, 
              {
                "text": "Then he became very thirsty; so he cried out to the LORD and said, \"You have given this great deliverance by the hand of Your servant; and now shall I die of thirst and fall into the hand of the uncircumcised?\"", 
                "num": 18
              }, 
              {
                "text": "So God split the hollow place that is in Lehi, and water came out, and he drank; and his spirit returned, and he revived. Therefore he called its name En Hakkore, which is in Lehi to this day.", 
                "num": 19
              }, 
              {
                "text": "And he judged Israel twenty years in the days of the Philistines.", 
                "num": 20
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now Samson went to Gaza and saw a harlot there, and went in to her.", 
                "num": 1
              }, 
              {
                "text": "When the Gazites were told, \"Samson has come here!\" they surrounded the place and lay in wait for him all night at the gate of the city. They were quiet all night, saying, \"In the morning, when it is daylight, we will kill him.\"", 
                "num": 2
              }, 
              {
                "text": "And Samson lay low till midnight; then he arose at midnight, took hold of the doors of the gate of the city and the two gateposts, pulled them up, bar and all, put them on his shoulders, and carried them to the top of the hill that faces Hebron.", 
                "num": 3
              }, 
              {
                "text": "Afterward it happened that he loved a woman in the Valley of Sorek, whose name was Delilah.", 
                "num": 4
              }, 
              {
                "text": "And the lords of the Philistines came up to her and said to her, \"Entice him, and find out where his great strength lies, and by what means we may overpower him, that we may bind him to afflict him; and every one of us will give you eleven hundred pieces of silver.\"", 
                "num": 5
              }, 
              {
                "text": "So Delilah said to Samson, \"Please tell me where your great strength lies, and with what you may be bound to afflict you.\"", 
                "num": 6
              }, 
              {
                "text": "And Samson said to her, \"If they bind me with seven fresh bowstrings, not yet dried, then I shall become weak, and be like any other man.\"", 
                "num": 7
              }, 
              {
                "text": "So the lords of the Philistines brought up to her seven fresh bowstrings, not yet dried, and she bound him with them.", 
                "num": 8
              }, 
              {
                "text": "Now men were lying in wait, staying with her in the room. And she said to him, \"The Philistines are upon you, Samson!\" But he broke the bowstrings as a strand of yarn breaks when it touches fire. So the secret of his strength was not known.", 
                "num": 9
              }, 
              {
                "text": "Then Delilah said to Samson, \"Look, you have mocked me and told me lies. Now, please tell me what you may be bound with.\"", 
                "num": 10
              }, 
              {
                "text": "So he said to her, \"If they bind me securely with new ropes that have never been used, then I shall become weak, and be like any other man.\"", 
                "num": 11
              }, 
              {
                "text": "Therefore Delilah took new ropes and bound him with them, and said to him, \"The Philistines are upon you, Samson!\" And men were lying in wait, staying in the room. But he broke them off his arms like a thread.", 
                "num": 12
              }, 
              {
                "text": "Delilah said to Samson, \"Until now you have mocked me and told me lies. Tell me what you may be bound with.\" And he said to her, \"If you weave the seven locks of my head into the web of the loom\"--", 
                "num": 13
              }, 
              {
                "text": "So she wove it tightly with the batten of the loom, and said to him, \"The Philistines are upon you, Samson!\" But he awoke from his sleep, and pulled out the batten and the web from the loom.", 
                "num": 14
              }, 
              {
                "text": "Then she said to him, \"How can you say, \"I love you,' when your heart is not with me? You have mocked me these three times, and have not told me where your great strength lies.\"", 
                "num": 15
              }, 
              {
                "text": "And it came to pass, when she pestered him daily with her words and pressed him, so that his soul was vexed to death,", 
                "num": 16
              }, 
              {
                "text": "that he told her all his heart, and said to her, \"No razor has ever come upon my head, for I have been a Nazirite to God from my mother's womb. If I am shaven, then my strength will leave me, and I shall become weak, and be like any other man.\"", 
                "num": 17
              }, 
              {
                "text": "When Delilah saw that he had told her all his heart, she sent and called for the lords of the Philistines, saying, \"Come up once more, for he has told me all his heart.\" So the lords of the Philistines came up to her and brought the money in their hand.", 
                "num": 18
              }, 
              {
                "text": "Then she lulled him to sleep on her knees, and called for a man and had him shave off the seven locks of his head. Then she began to torment him, and his strength left him.", 
                "num": 19
              }, 
              {
                "text": "And she said, \"The Philistines are upon you, Samson!\" So he awoke from his sleep, and said, \"I will go out as before, at other times, and shake myself free!\" But he did not know that the LORD had departed from him.", 
                "num": 20
              }, 
              {
                "text": "Then the Philistines took him and put out his eyes, and brought him down to Gaza. They bound him with bronze fetters, and he became a grinder in the prison.", 
                "num": 21
              }, 
              {
                "text": "However, the hair of his head began to grow again after it had been shaven.", 
                "num": 22
              }, 
              {
                "text": "Now the lords of the Philistines gathered together to offer a great sacrifice to Dagon their god, and to rejoice. And they said: \"Our god has delivered into our hands Samson our enemy!\"", 
                "num": 23
              }, 
              {
                "text": "When the people saw him, they praised their god; for they said: \"Our god has delivered into our hands our enemy, The destroyer of our land, And the one who multiplied our dead.\"", 
                "num": 24
              }, 
              {
                "text": "So it happened, when their hearts were merry, that they said, \"Call for Samson, that he may perform for us.\" So they called for Samson from the prison, and he performed for them. And they stationed him between the pillars.", 
                "num": 25
              }, 
              {
                "text": "Then Samson said to the lad who held him by the hand, \"Let me feel the pillars which support the temple, so that I can lean on them.\"", 
                "num": 26
              }, 
              {
                "text": "Now the temple was full of men and women. All the lords of the Philistines were there--about three thousand men and women on the roof watching while Samson performed.", 
                "num": 27
              }, 
              {
                "text": "Then Samson called to the LORD, saying, \"O Lord GOD, remember me, I pray! Strengthen me, I pray, just this once, O God, that I may with one blow take vengeance on the Philistines for my two eyes!\"", 
                "num": 28
              }, 
              {
                "text": "And Samson took hold of the two middle pillars which supported the temple, and he braced himself against them, one on his right and the other on his left.", 
                "num": 29
              }, 
              {
                "text": "Then Samson said, \"Let me die with the Philistines!\" And he pushed with all his might, and the temple fell on the lords and all the people who were in it. So the dead that he killed at his death were more than he had killed in his life.", 
                "num": 30
              }, 
              {
                "text": "And his brothers and all his father's household came down and took him, and brought him up and buried him between Zorah and Eshtaol in the tomb of his father Manoah. He had judged Israel twenty years.", 
                "num": 31
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Now there was a man from the mountains of Ephraim, whose name was Micah.", 
                "num": 1
              }, 
              {
                "text": "And he said to his mother, \"The eleven hundred shekels of silver that were taken from you, and on which you put a curse, even saying it in my ears--here is the silver with me; I took it.\" And his mother said, \"May you be blessed by the LORD, my son!\"", 
                "num": 2
              }, 
              {
                "text": "So when he had returned the eleven hundred shekels of silver to his mother, his mother said, \"I had wholly dedicated the silver from my hand to the LORD for my son, to make a carved image and a molded image; now therefore, I will return it to you.\"", 
                "num": 3
              }, 
              {
                "text": "Thus he returned the silver to his mother. Then his mother took two hundred shekels of silver and gave them to the silversmith, and he made it into a carved image and a molded image; and they were in the house of Micah.", 
                "num": 4
              }, 
              {
                "text": "The man Micah had a shrine, and made an ephod and household idols; and he consecrated one of his sons, who became his priest.", 
                "num": 5
              }, 
              {
                "text": "In those days there was no king in Israel; everyone did what was right in his own eyes.", 
                "num": 6
              }, 
              {
                "text": "Now there was a young man from Bethlehem in Judah, of the family of Judah; he was a Levite, and was staying there.", 
                "num": 7
              }, 
              {
                "text": "The man departed from the city of Bethlehem in Judah to stay wherever he could find a place. Then he came to the mountains of Ephraim, to the house of Micah, as he journeyed.", 
                "num": 8
              }, 
              {
                "text": "And Micah said to him, \"Where do you come from?\" So he said to him, \"I am a Levite from Bethlehem in Judah, and I am on my way to find a place to stay.\"", 
                "num": 9
              }, 
              {
                "text": "Micah said to him, \"Dwell with me, and be a father and a priest to me, and I will give you ten shekels of silver per year, a suit of clothes, and your sustenance.\" So the Levite went in.", 
                "num": 10
              }, 
              {
                "text": "Then the Levite was content to dwell with the man; and the young man became like one of his sons to him.", 
                "num": 11
              }, 
              {
                "text": "So Micah consecrated the Levite, and the young man became his priest, and lived in the house of Micah.", 
                "num": 12
              }, 
              {
                "text": "Then Micah said, \"Now I know that the LORD will be good to me, since I have a Levite as priest!\"", 
                "num": 13
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "In those days there was no king in Israel. And in those days the tribe of the Danites was seeking an inheritance for itself to dwell in; for until that day their inheritance among the tribes of Israel had not fallen to them.", 
                "num": 1
              }, 
              {
                "text": "So the children of Dan sent five men of their family from their territory, men of valor from Zorah and Eshtaol, to spy out the land and search it. They said to them, \"Go, search the land.\" So they went to the mountains of Ephraim, to the house of Micah, and lodged there.", 
                "num": 2
              }, 
              {
                "text": "While they were at the house of Micah, they recognized the voice of the young Levite. They turned aside and said to him, \"Who brought you here? What are you doing in this place? What do you have here?\"", 
                "num": 3
              }, 
              {
                "text": "He said to them, \"Thus and so Micah did for me. He has hired me, and I have become his priest.\"", 
                "num": 4
              }, 
              {
                "text": "So they said to him, \"Please inquire of God, that we may know whether the journey on which we go will be prosperous.\"", 
                "num": 5
              }, 
              {
                "text": "And the priest said to them, \"Go in peace. The presence of the LORD be with you on your way.\"", 
                "num": 6
              }, 
              {
                "text": "So the five men departed and went to Laish. They saw the people who were there, how they dwelt safely, in the manner of the Sidonians, quiet and secure. There were no rulers in the land who might put them to shame for anything. They were far from the Sidonians, and they had no ties with anyone.", 
                "num": 7
              }, 
              {
                "text": "Then the spies came back to their brethren at Zorah and Eshtaol, and their brethren said to them, \"What is your report?\"", 
                "num": 8
              }, 
              {
                "text": "So they said, \"Arise, let us go up against them. For we have seen the land, and indeed it is very good. Would you do nothing? Do not hesitate to go, and enter to possess the land.", 
                "num": 9
              }, 
              {
                "text": "When you go, you will come to a secure people and a large land. For God has given it into your hands, a place where there is no lack of anything that is on the earth.\"", 
                "num": 10
              }, 
              {
                "text": "And six hundred men of the family of the Danites went from there, from Zorah and Eshtaol, armed with weapons of war.", 
                "num": 11
              }, 
              {
                "text": "Then they went up and encamped in Kirjath Jearim in Judah. (Therefore they call that place Mahaneh Dan to this day. There it is, west of Kirjath Jearim.)", 
                "num": 12
              }, 
              {
                "text": "And they passed from there to the mountains of Ephraim, and came to the house of Micah.", 
                "num": 13
              }, 
              {
                "text": "Then the five men who had gone to spy out the country of Laish answered and said to their brethren, \"Do you know that there are in these houses an ephod, household idols, a carved image, and a molded image? Now therefore, consider what you should do.\"", 
                "num": 14
              }, 
              {
                "text": "So they turned aside there, and came to the house of the young Levite man--to the house of Micah--and greeted him.", 
                "num": 15
              }, 
              {
                "text": "The six hundred men armed with their weapons of war, who were of the children of Dan, stood by the entrance of the gate.", 
                "num": 16
              }, 
              {
                "text": "Then the five men who had gone to spy out the land went up. Entering there, they took the carved image, the ephod, the household idols, and the molded image. The priest stood at the entrance of the gate with the six hundred men who were armed with weapons of war.", 
                "num": 17
              }, 
              {
                "text": "When these went into Micah's house and took the carved image, the ephod, the household idols, and the molded image, the priest said to them, \"What are you doing?\"", 
                "num": 18
              }, 
              {
                "text": "And they said to him, \"Be quiet, put your hand over your mouth, and come with us; be a father and a priest to us. Is it better for you to be a priest to the household of one man, or that you be a priest to a tribe and a family in Israel?\"", 
                "num": 19
              }, 
              {
                "text": "So the priest's heart was glad; and he took the ephod, the household idols, and the carved image, and took his place among the people.", 
                "num": 20
              }, 
              {
                "text": "Then they turned and departed, and put the little ones, the livestock, and the goods in front of them.", 
                "num": 21
              }, 
              {
                "text": "When they were a good way from the house of Micah, the men who were in the houses near Micah's house gathered together and overtook the children of Dan.", 
                "num": 22
              }, 
              {
                "text": "And they called out to the children of Dan. So they turned around and said to Micah, \"What ails you, that you have gathered such a company?\"", 
                "num": 23
              }, 
              {
                "text": "So he said, \"You have taken away my gods which I made, and the priest, and you have gone away. Now what more do I have? How can you say to me, \"What ails you?\"'", 
                "num": 24
              }, 
              {
                "text": "And the children of Dan said to him, \"Do not let your voice be heard among us, lest angry men fall upon you, and you lose your life, with the lives of your household!\"", 
                "num": 25
              }, 
              {
                "text": "Then the children of Dan went their way. And when Micah saw that they were too strong for him, he turned and went back to his house.", 
                "num": 26
              }, 
              {
                "text": "So they took the things Micah had made, and the priest who had belonged to him, and went to Laish, to a people quiet and secure; and they struck them with the edge of the sword and burned the city with fire.", 
                "num": 27
              }, 
              {
                "text": "There was no deliverer, because it was far from Sidon, and they had no ties with anyone. It was in the valley that belongs to Beth Rehob. So they rebuilt the city and dwelt there.", 
                "num": 28
              }, 
              {
                "text": "And they called the name of the city Dan, after the name of Dan their father, who was born to Israel. However, the name of the city formerly was Laish.", 
                "num": 29
              }, 
              {
                "text": "Then the children of Dan set up for themselves the carved image; and Jonathan the son of Gershom, the son of Manasseh, and his sons were priests to the tribe of Dan until the day of the captivity of the land.", 
                "num": 30
              }, 
              {
                "text": "So they set up for themselves Micah's carved image which he made, all the time that the house of God was in Shiloh.", 
                "num": 31
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in those days, when there was no king in Israel, that there was a certain Levite staying in the remote mountains of Ephraim. He took for himself a concubine from Bethlehem in Judah.", 
                "num": 1
              }, 
              {
                "text": "But his concubine played the harlot against him, and went away from him to her father's house at Bethlehem in Judah, and was there four whole months.", 
                "num": 2
              }, 
              {
                "text": "Then her husband arose and went after her, to speak kindly to her and bring her back, having his servant and a couple of donkeys with him. So she brought him into her father's house; and when the father of the young woman saw him, he was glad to meet him.", 
                "num": 3
              }, 
              {
                "text": "Now his father-in-law, the young woman's father, detained him; and he stayed with him three days. So they ate and drank and lodged there.", 
                "num": 4
              }, 
              {
                "text": "Then it came to pass on the fourth day that they arose early in the morning, and he stood to depart; but the young woman's father said to his son-in-law, \"Refresh your heart with a morsel of bread, and afterward go your way.\"", 
                "num": 5
              }, 
              {
                "text": "So they sat down, and the two of them ate and drank together. Then the young woman's father said to the man, \"Please be content to stay all night, and let your heart be merry.\"", 
                "num": 6
              }, 
              {
                "text": "And when the man stood to depart, his father-in-law urged him; so he lodged there again.", 
                "num": 7
              }, 
              {
                "text": "Then he arose early in the morning on the fifth day to depart, but the young woman's father said, \"Please refresh your heart.\" So they delayed until afternoon; and both of them ate.", 
                "num": 8
              }, 
              {
                "text": "And when the man stood to depart--he and his concubine and his servant--his father-in-law, the young woman's father, said to him, \"Look, the day is now drawing toward evening; please spend the night. See, the day is coming to an end; lodge here, that your heart may be merry. Tomorrow go your way early, so that you may get home.\"", 
                "num": 9
              }, 
              {
                "text": "However, the man was not willing to spend that night; so he rose and departed, and came opposite Jebus (that is, Jerusalem). With him were the two saddled donkeys; his concubine was also with him.", 
                "num": 10
              }, 
              {
                "text": "They were near Jebus, and the day was far spent; and the servant said to his master, \"Come, please, and let us turn aside into this city of the Jebusites and lodge in it.\"", 
                "num": 11
              }, 
              {
                "text": "But his master said to him, \"We will not turn aside here into a city of foreigners, who are not of the children of Israel; we will go on to Gibeah.\"", 
                "num": 12
              }, 
              {
                "text": "So he said to his servant, \"Come, let us draw near to one of these places, and spend the night in Gibeah or in Ramah.\"", 
                "num": 13
              }, 
              {
                "text": "And they passed by and went their way; and the sun went down on them near Gibeah, which belongs to Benjamin.", 
                "num": 14
              }, 
              {
                "text": "They turned aside there to go in to lodge in Gibeah. And when he went in, he sat down in the open square of the city, for no one would take them into his house to spend the night.", 
                "num": 15
              }, 
              {
                "text": "Just then an old man came in from his work in the field at evening, who also was from the mountains of Ephraim; he was staying in Gibeah, whereas the men of the place were Benjamites.", 
                "num": 16
              }, 
              {
                "text": "And when he raised his eyes, he saw the traveler in the open square of the city; and the old man said, \"Where are you going, and where do you come from?\"", 
                "num": 17
              }, 
              {
                "text": "So he said to him, \"We are passing from Bethlehem in Judah toward the remote mountains of Ephraim; I am from there. I went to Bethlehem in Judah; now I am going to the house of the LORD. But there is no one who will take me into his house,", 
                "num": 18
              }, 
              {
                "text": "although we have both straw and fodder for our donkeys, and bread and wine for myself, for your female servant, and for the young man who is with your servant; there is no lack of anything.\"", 
                "num": 19
              }, 
              {
                "text": "And the old man said, \"Peace be with you! However, let all your needs be my responsibility; only do not spend the night in the open square.\"", 
                "num": 20
              }, 
              {
                "text": "So he brought him into his house, and gave fodder to the donkeys. And they washed their feet, and ate and drank.", 
                "num": 21
              }, 
              {
                "text": "As they were enjoying themselves, suddenly certain men of the city, perverted men, surrounded the house and beat on the door. They spoke to the master of the house, the old man, saying, \"Bring out the man who came to your house, that we may know him carnally!\"", 
                "num": 22
              }, 
              {
                "text": "But the man, the master of the house, went out to them and said to them, \"No, my brethren! I beg you, do not act so wickedly! Seeing this man has come into my house, do not commit this outrage.", 
                "num": 23
              }, 
              {
                "text": "Look, here is my virgin daughter and the man's concubine; let me bring them out now. Humble them, and do with them as you please; but to this man do not do such a vile thing!\"", 
                "num": 24
              }, 
              {
                "text": "But the men would not heed him. So the man took his concubine and brought her out to them. And they knew her and abused her all night until morning; and when the day began to break, they let her go.", 
                "num": 25
              }, 
              {
                "text": "Then the woman came as the day was dawning, and fell down at the door of the man's house where her master was, till it was light.", 
                "num": 26
              }, 
              {
                "text": "When her master arose in the morning, and opened the doors of the house and went out to go his way, there was his concubine, fallen at the door of the house with her hands on the threshold.", 
                "num": 27
              }, 
              {
                "text": "And he said to her, \"Get up and let us be going.\" But there was no answer. So the man lifted her onto the donkey; and the man got up and went to his place.", 
                "num": 28
              }, 
              {
                "text": "When he entered his house he took a knife, laid hold of his concubine, and divided her into twelve pieces, limb by limb, and sent her throughout all the territory of Israel.", 
                "num": 29
              }, 
              {
                "text": "And so it was that all who saw it said, \"No such deed has been done or seen from the day that the children of Israel came up from the land of Egypt until this day. Consider it, confer, and speak up!\"", 
                "num": 30
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "So all the children of Israel came out, from Dan to Beersheba, as well as from the land of Gilead, and the congregation gathered together as one man before the LORD at Mizpah.", 
                "num": 1
              }, 
              {
                "text": "And the leaders of all the people, all the tribes of Israel, presented themselves in the assembly of the people of God, four hundred thousand foot soldiers who drew the sword.", 
                "num": 2
              }, 
              {
                "text": "(Now the children of Benjamin heard that the children of Israel had gone up to Mizpah.) Then the children of Israel said, \"Tell us, how did this wicked deed happen?\"", 
                "num": 3
              }, 
              {
                "text": "So the Levite, the husband of the woman who was murdered, answered and said, \"My concubine and I went into Gibeah, which belongs to Benjamin, to spend the night.", 
                "num": 4
              }, 
              {
                "text": "And the men of Gibeah rose against me, and surrounded the house at night because of me. They intended to kill me, but instead they ravished my concubine so that she died.", 
                "num": 5
              }, 
              {
                "text": "So I took hold of my concubine, cut her in pieces, and sent her throughout all the territory of the inheritance of Israel, because they committed lewdness and outrage in Israel.", 
                "num": 6
              }, 
              {
                "text": "Look! All of you are children of Israel; give your advice and counsel here and now!\"", 
                "num": 7
              }, 
              {
                "text": "So all the people arose as one man, saying, \"None of us will go to his tent, nor will any turn back to his house;", 
                "num": 8
              }, 
              {
                "text": "but now this is the thing which we will do to Gibeah: We will go up against it by lot.", 
                "num": 9
              }, 
              {
                "text": "We will take ten men out of every hundred throughout all the tribes of Israel, a hundred out of every thousand, and a thousand out of every ten thousand, to make provisions for the people, that when they come to Gibeah in Benjamin, they may repay all the vileness that they have done in Israel.\"", 
                "num": 10
              }, 
              {
                "text": "So all the men of Israel were gathered against the city, united together as one man.", 
                "num": 11
              }, 
              {
                "text": "Then the tribes of Israel sent men through all the tribe of Benjamin, saying, \"What is this wickedness that has occurred among you?", 
                "num": 12
              }, 
              {
                "text": "Now therefore, deliver up the men, the perverted men who are in Gibeah, that we may put them to death and remove the evil from Israel!\" But the children of Benjamin would not listen to the voice of their brethren, the children of Israel.", 
                "num": 13
              }, 
              {
                "text": "Instead, the children of Benjamin gathered together from their cities to Gibeah, to go to battle against the children of Israel.", 
                "num": 14
              }, 
              {
                "text": "And from their cities at that time the children of Benjamin numbered twenty-six thousand men who drew the sword, besides the inhabitants of Gibeah, who numbered seven hundred select men.", 
                "num": 15
              }, 
              {
                "text": "Among all this people were seven hundred select men who were left-handed; every one could sling a stone at a hair's breadth and not miss.", 
                "num": 16
              }, 
              {
                "text": "Now besides Benjamin, the men of Israel numbered four hundred thousand men who drew the sword; all of these were men of war.", 
                "num": 17
              }, 
              {
                "text": "Then the children of Israel arose and went up to the house of God to inquire of God. They said, \"Which of us shall go up first to battle against the children of Benjamin?\" The LORD said, \"Judah first!\"", 
                "num": 18
              }, 
              {
                "text": "So the children of Israel rose in the morning and encamped against Gibeah.", 
                "num": 19
              }, 
              {
                "text": "And the men of Israel went out to battle against Benjamin, and the men of Israel put themselves in battle array to fight against them at Gibeah.", 
                "num": 20
              }, 
              {
                "text": "Then the children of Benjamin came out of Gibeah, and on that day cut down to the ground twenty-two thousand men of the Israelites.", 
                "num": 21
              }, 
              {
                "text": "And the people, that is, the men of Israel, encouraged themselves and again formed the battle line at the place where they had put themselves in array on the first day.", 
                "num": 22
              }, 
              {
                "text": "Then the children of Israel went up and wept before the LORD until evening, and asked counsel of the LORD, saying, \"Shall I again draw near for battle against the children of my brother Benjamin?\" And the LORD said, \"Go up against him.\"", 
                "num": 23
              }, 
              {
                "text": "So the children of Israel approached the children of Benjamin on the second day.", 
                "num": 24
              }, 
              {
                "text": "And Benjamin went out against them from Gibeah on the second day, and cut down to the ground eighteen thousand more of the children of Israel; all these drew the sword.", 
                "num": 25
              }, 
              {
                "text": "Then all the children of Israel, that is, all the people, went up and came to the house of God and wept. They sat there before the LORD and fasted that day until evening; and they offered burnt offerings and peace offerings before the LORD.", 
                "num": 26
              }, 
              {
                "text": "So the children of Israel inquired of the LORD (the ark of the covenant of God was there in those days,", 
                "num": 27
              }, 
              {
                "text": "and Phinehas the son of Eleazar, the son of Aaron, stood before it in those days), saying, \"Shall I yet again go out to battle against the children of my brother Benjamin, or shall I cease?\" And the LORD said, \"Go up, for tomorrow I will deliver them into your hand.\"", 
                "num": 28
              }, 
              {
                "text": "Then Israel set men in ambush all around Gibeah.", 
                "num": 29
              }, 
              {
                "text": "And the children of Israel went up against the children of Benjamin on the third day, and put themselves in battle array against Gibeah as at the other times.", 
                "num": 30
              }, 
              {
                "text": "So the children of Benjamin went out against the people, and were drawn away from the city. They began to strike down and kill some of the people, as at the other times, in the highways (one of which goes up to Bethel and the other to Gibeah) and in the field, about thirty men of Israel.", 
                "num": 31
              }, 
              {
                "text": "And the children of Benjamin said, \"They are defeated before us, as at first.\" But the children of Israel said, \"Let us flee and draw them away from the city to the highways.\"", 
                "num": 32
              }, 
              {
                "text": "So all the men of Israel rose from their place and put themselves in battle array at Baal Tamar. Then Israel's men in ambush burst forth from their position in the plain of Geba.", 
                "num": 33
              }, 
              {
                "text": "And ten thousand select men from all Israel came against Gibeah, and the battle was fierce. But the Benjamites did not know that disaster was upon them.", 
                "num": 34
              }, 
              {
                "text": "The LORD defeated Benjamin before Israel. And the children of Israel destroyed that day twenty-five thousand one hundred Benjamites; all these drew the sword.", 
                "num": 35
              }, 
              {
                "text": "So the children of Benjamin saw that they were defeated. The men of Israel had given ground to the Benjamites, because they relied on the men in ambush whom they had set against Gibeah.", 
                "num": 36
              }, 
              {
                "text": "And the men in ambush quickly rushed upon Gibeah; the men in ambush spread out and struck the whole city with the edge of the sword.", 
                "num": 37
              }, 
              {
                "text": "Now the appointed signal between the men of Israel and the men in ambush was that they would make a great cloud of smoke rise up from the city,", 
                "num": 38
              }, 
              {
                "text": "whereupon the men of Israel would turn in battle. Now Benjamin had begun to strike and kill about thirty of the men of Israel. For they said, \"Surely they are defeated before us, as in the first battle.\"", 
                "num": 39
              }, 
              {
                "text": "But when the cloud began to rise from the city in a column of smoke, the Benjamites looked behind them, and there was the whole city going up in smoke to heaven.", 
                "num": 40
              }, 
              {
                "text": "And when the men of Israel turned back, the men of Benjamin panicked, for they saw that disaster had come upon them.", 
                "num": 41
              }, 
              {
                "text": "Therefore they turned their backs before the men of Israel in the direction of the wilderness; but the battle overtook them, and whoever came out of the cities they destroyed in their midst.", 
                "num": 42
              }, 
              {
                "text": "They surrounded the Benjamites, chased them, and easily trampled them down as far as the front of Gibeah toward the east.", 
                "num": 43
              }, 
              {
                "text": "And eighteen thousand men of Benjamin fell; all these were men of valor.", 
                "num": 44
              }, 
              {
                "text": "Then they turned and fled toward the wilderness to the rock of Rimmon; and they cut down five thousand of them on the highways. Then they pursued them relentlessly up to Gidom, and killed two thousand of them.", 
                "num": 45
              }, 
              {
                "text": "So all who fell of Benjamin that day were twenty-five thousand men who drew the sword; all these were men of valor.", 
                "num": 46
              }, 
              {
                "text": "But six hundred men turned and fled toward the wilderness to the rock of Rimmon, and they stayed at the rock of Rimmon for four months.", 
                "num": 47
              }, 
              {
                "text": "And the men of Israel turned back against the children of Benjamin, and struck them down with the edge of the sword--from every city, men and beasts, all who were found. They also set fire to all the cities they came to.", 
                "num": 48
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now the men of Israel had sworn an oath at Mizpah, saying, \"None of us shall give his daughter to Benjamin as a wife.\"", 
                "num": 1
              }, 
              {
                "text": "Then the people came to the house of God, and remained there before God till evening. They lifted up their voices and wept bitterly,", 
                "num": 2
              }, 
              {
                "text": "and said, \"O LORD God of Israel, why has this come to pass in Israel, that today there should be one tribe missing in Israel?\"", 
                "num": 3
              }, 
              {
                "text": "So it was, on the next morning, that the people rose early and built an altar there, and offered burnt offerings and peace offerings.", 
                "num": 4
              }, 
              {
                "text": "The children of Israel said, \"Who is there among all the tribes of Israel who did not come up with the assembly to the LORD?\" For they had made a great oath concerning anyone who had not come up to the LORD at Mizpah, saying, \"He shall surely be put to death.\"", 
                "num": 5
              }, 
              {
                "text": "And the children of Israel grieved for Benjamin their brother, and said, \"One tribe is cut off from Israel today.", 
                "num": 6
              }, 
              {
                "text": "What shall we do for wives for those who remain, seeing we have sworn by the LORD that we will not give them our daughters as wives?\"", 
                "num": 7
              }, 
              {
                "text": "And they said, \"What one is there from the tribes of Israel who did not come up to Mizpah to the LORD?\" And, in fact, no one had come to the camp from Jabesh Gilead to the assembly.", 
                "num": 8
              }, 
              {
                "text": "For when the people were counted, indeed, not one of the inhabitants of Jabesh Gilead was there.", 
                "num": 9
              }, 
              {
                "text": "So the congregation sent out there twelve thousand of their most valiant men, and commanded them, saying, \"Go and strike the inhabitants of Jabesh Gilead with the edge of the sword, including the women and children.", 
                "num": 10
              }, 
              {
                "text": "And this is the thing that you shall do: You shall utterly destroy every male, and every woman who has known a man intimately.\"", 
                "num": 11
              }, 
              {
                "text": "So they found among the inhabitants of Jabesh Gilead four hundred young virgins who had not known a man intimately; and they brought them to the camp at Shiloh, which is in the land of Canaan.", 
                "num": 12
              }, 
              {
                "text": "Then the whole congregation sent word to the children of Benjamin who were at the rock of Rimmon, and announced peace to them.", 
                "num": 13
              }, 
              {
                "text": "So Benjamin came back at that time, and they gave them the women whom they had saved alive of the women of Jabesh Gilead; and yet they had not found enough for them.", 
                "num": 14
              }, 
              {
                "text": "And the people grieved for Benjamin, because the LORD had made a void in the tribes of Israel.", 
                "num": 15
              }, 
              {
                "text": "Then the elders of the congregation said, \"What shall we do for wives for those who remain, since the women of Benjamin have been destroyed?\"", 
                "num": 16
              }, 
              {
                "text": "And they said, \"There must be an inheritance for the survivors of Benjamin, that a tribe may not be destroyed from Israel.", 
                "num": 17
              }, 
              {
                "text": "However, we cannot give them wives from our daughters, for the children of Israel have sworn an oath, saying, \"Cursed be the one who gives a wife to Benjamin.\"'", 
                "num": 18
              }, 
              {
                "text": "Then they said, \"In fact, there is a yearly feast of the LORD in Shiloh, which is north of Bethel, on the east side of the highway that goes up from Bethel to Shechem, and south of Lebonah.\"", 
                "num": 19
              }, 
              {
                "text": "Therefore they instructed the children of Benjamin, saying, \"Go, lie in wait in the vineyards,", 
                "num": 20
              }, 
              {
                "text": "and watch; and just when the daughters of Shiloh come out to perform their dances, then come out from the vineyards, and every man catch a wife for himself from the daughters of Shiloh; then go to the land of Benjamin.", 
                "num": 21
              }, 
              {
                "text": "Then it shall be, when their fathers or their brothers come to us to complain, that we will say to them, \"Be kind to them for our sakes, because we did not take a wife for any of them in the war; for it is not as though you have given the women to them at this time, making yourselves guilty of your oath.\"'", 
                "num": 22
              }, 
              {
                "text": "And the children of Benjamin did so; they took enough wives for their number from those who danced, whom they caught. Then they went and returned to their inheritance, and they rebuilt the cities and dwelt in them.", 
                "num": 23
              }, 
              {
                "text": "So the children of Israel departed from there at that time, every man to his tribe and family; they went out from there, every man to his inheritance.", 
                "num": 24
              }, 
              {
                "text": "In those days there was no king in Israel; everyone did what was right in his own eyes.", 
                "num": 25
              }
            ], 
            "num": 21
          }
        ]
      }, 
      {
        "name": "Ruth", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now it came to pass, in the days when the judges ruled, that there was a famine in the land. And a certain man of Bethlehem, Judah, went to dwell in the country of Moab, he and his wife and his two sons.", 
                "num": 1
              }, 
              {
                "text": "The name of the man was Elimelech, the name of his wife was Naomi, and the names of his two sons were Mahlon and Chilion--Ephrathites of Bethlehem, Judah. And they went to the country of Moab and remained there.", 
                "num": 2
              }, 
              {
                "text": "Then Elimelech, Naomi's husband, died; and she was left, and her two sons.", 
                "num": 3
              }, 
              {
                "text": "Now they took wives of the women of Moab: the name of the one was Orpah, and the name of the other Ruth. And they dwelt there about ten years.", 
                "num": 4
              }, 
              {
                "text": "Then both Mahlon and Chilion also died; so the woman survived her two sons and her husband.", 
                "num": 5
              }, 
              {
                "text": "Then she arose with her daughters-in-law that she might return from the country of Moab, for she had heard in the country of Moab that the LORD had visited His people by giving them bread.", 
                "num": 6
              }, 
              {
                "text": "Therefore she went out from the place where she was, and her two daughters-in-law with her; and they went on the way to return to the land of Judah.", 
                "num": 7
              }, 
              {
                "text": "And Naomi said to her two daughters-in-law, \"Go, return each to her mother's house. The LORD deal kindly with you, as you have dealt with the dead and with me.", 
                "num": 8
              }, 
              {
                "text": "The LORD grant that you may find rest, each in the house of her husband.\" So she kissed them, and they lifted up their voices and wept.", 
                "num": 9
              }, 
              {
                "text": "And they said to her, \"Surely we will return with you to your people.\"", 
                "num": 10
              }, 
              {
                "text": "But Naomi said, \"Turn back, my daughters; why will you go with me? Are there still sons in my womb, that they may be your husbands?", 
                "num": 11
              }, 
              {
                "text": "Turn back, my daughters, go--for I am too old to have a husband. If I should say I have hope, if I should have a husband tonight and should also bear sons,", 
                "num": 12
              }, 
              {
                "text": "would you wait for them till they were grown? Would you restrain yourselves from having husbands? No, my daughters; for it grieves me very much for your sakes that the hand of the LORD has gone out against me!\"", 
                "num": 13
              }, 
              {
                "text": "Then they lifted up their voices and wept again; and Orpah kissed her mother-in-law, but Ruth clung to her.", 
                "num": 14
              }, 
              {
                "text": "And she said, \"Look, your sister-in-law has gone back to her people and to her gods; return after your sister-in-law.\"", 
                "num": 15
              }, 
              {
                "text": "But Ruth said: \"Entreat me not to leave you, Or to turn back from following after you; For wherever you go, I will go; And wherever you lodge, I will lodge; Your people shall be my people, And your God, my God.", 
                "num": 16
              }, 
              {
                "text": "Where you die, I will die, And there will I be buried. The LORD do so to me, and more also, If anything but death parts you and me.\"", 
                "num": 17
              }, 
              {
                "text": "When she saw that she was determined to go with her, she stopped speaking to her.", 
                "num": 18
              }, 
              {
                "text": "Now the two of them went until they came to Bethlehem. And it happened, when they had come to Bethlehem, that all the city was excited because of them; and the women said, \"Is this Naomi?\"", 
                "num": 19
              }, 
              {
                "text": "But she said to them, \"Do not call me Naomi; call me Mara, for the Almighty has dealt very bitterly with me.", 
                "num": 20
              }, 
              {
                "text": "I went out full, and the LORD has brought me home again empty. Why do you call me Naomi, since the LORD has testified against me, and the Almighty has afflicted me?\"", 
                "num": 21
              }, 
              {
                "text": "So Naomi returned, and Ruth the Moabitess her daughter-in-law with her, who returned from the country of Moab. Now they came to Bethlehem at the beginning of barley harvest.", 
                "num": 22
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "There was a relative of Naomi's husband, a man of great wealth, of the family of Elimelech. His name was Boaz.", 
                "num": 1
              }, 
              {
                "text": "So Ruth the Moabitess said to Naomi, \"Please let me go to the field, and glean heads of grain after him in whose sight I may find favor.\" And she said to her, \"Go, my daughter.\"", 
                "num": 2
              }, 
              {
                "text": "Then she left, and went and gleaned in the field after the reapers. And she happened to come to the part of the field belonging to Boaz, who was of the family of Elimelech.", 
                "num": 3
              }, 
              {
                "text": "Now behold, Boaz came from Bethlehem, and said to the reapers, \"The LORD be with you!\" And they answered him, \"The LORD bless you!\"", 
                "num": 4
              }, 
              {
                "text": "Then Boaz said to his servant who was in charge of the reapers, \"Whose young woman is this?\"", 
                "num": 5
              }, 
              {
                "text": "So the servant who was in charge of the reapers answered and said, \"It is the young Moabite woman who came back with Naomi from the country of Moab.", 
                "num": 6
              }, 
              {
                "text": "And she said, \"Please let me glean and gather after the reapers among the sheaves.' So she came and has continued from morning until now, though she rested a little in the house.\"", 
                "num": 7
              }, 
              {
                "text": "Then Boaz said to Ruth, \"You will listen, my daughter, will you not? Do not go to glean in another field, nor go from here, but stay close by my young women.", 
                "num": 8
              }, 
              {
                "text": "Let your eyes be on the field which they reap, and go after them. Have I not commanded the young men not to touch you? And when you are thirsty, go to the vessels and drink from what the young men have drawn.\"", 
                "num": 9
              }, 
              {
                "text": "So she fell on her face, bowed down to the ground, and said to him, \"Why have I found favor in your eyes, that you should take notice of me, since I am a foreigner?\"", 
                "num": 10
              }, 
              {
                "text": "And Boaz answered and said to her, \"It has been fully reported to me, all that you have done for your mother-in-law since the death of your husband, and how you have left your father and your mother and the land of your birth, and have come to a people whom you did not know before.", 
                "num": 11
              }, 
              {
                "text": "The LORD repay your work, and a full reward be given you by the LORD God of Israel, under whose wings you have come for refuge.\"", 
                "num": 12
              }, 
              {
                "text": "Then she said, \"Let me find favor in your sight, my lord; for you have comforted me, and have spoken kindly to your maidservant, though I am not like one of your maidservants.\"", 
                "num": 13
              }, 
              {
                "text": "Now Boaz said to her at mealtime, \"Come here, and eat of the bread, and dip your piece of bread in the vinegar.\" So she sat beside the reapers, and he passed parched grain to her; and she ate and was satisfied, and kept some back.", 
                "num": 14
              }, 
              {
                "text": "And when she rose up to glean, Boaz commanded his young men, saying, \"Let her glean even among the sheaves, and do not reproach her.", 
                "num": 15
              }, 
              {
                "text": "Also let grain from the bundles fall purposely for her; leave it that she may glean, and do not rebuke her.\"", 
                "num": 16
              }, 
              {
                "text": "So she gleaned in the field until evening, and beat out what she had gleaned, and it was about an ephah of barley.", 
                "num": 17
              }, 
              {
                "text": "Then she took it up and went into the city, and her mother-in-law saw what she had gleaned. So she brought out and gave to her what she had kept back after she had been satisfied.", 
                "num": 18
              }, 
              {
                "text": "And her mother-in-law said to her, \"Where have you gleaned today? And where did you work? Blessed be the one who took notice of you.\" So she told her mother-in-law with whom she had worked, and said, \"The man's name with whom I worked today is Boaz.\"", 
                "num": 19
              }, 
              {
                "text": "Then Naomi said to her daughter-in-law, \"Blessed be he of the LORD, who has not forsaken His kindness to the living and the dead!\" And Naomi said to her, \"This man is a relation of ours, one of our close relatives.\"", 
                "num": 20
              }, 
              {
                "text": "Ruth the Moabitess said, \"He also said to me, \"You shall stay close by my young men until they have finished all my harvest.\"'", 
                "num": 21
              }, 
              {
                "text": "And Naomi said to Ruth her daughter-in-law, \"It is good, my daughter, that you go out with his young women, and that people do not meet you in any other field.\"", 
                "num": 22
              }, 
              {
                "text": "So she stayed close by the young women of Boaz, to glean until the end of barley harvest and wheat harvest; and she dwelt with her mother-in-law.", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Then Naomi her mother-in-law said to her, \"My daughter, shall I not seek security for you, that it may be well with you?", 
                "num": 1
              }, 
              {
                "text": "Now Boaz, whose young women you were with, is he not our relative? In fact, he is winnowing barley tonight at the threshing floor.", 
                "num": 2
              }, 
              {
                "text": "Therefore wash yourself and anoint yourself, put on your best garment and go down to the threshing floor; but do not make yourself known to the man until he has finished eating and drinking.", 
                "num": 3
              }, 
              {
                "text": "Then it shall be, when he lies down, that you shall notice the place where he lies; and you shall go in, uncover his feet, and lie down; and he will tell you what you should do.\"", 
                "num": 4
              }, 
              {
                "text": "And she said to her, \"All that you say to me I will do.\"", 
                "num": 5
              }, 
              {
                "text": "So she went down to the threshing floor and did according to all that her mother-in-law instructed her.", 
                "num": 6
              }, 
              {
                "text": "And after Boaz had eaten and drunk, and his heart was cheerful, he went to lie down at the end of the heap of grain; and she came softly, uncovered his feet, and lay down.", 
                "num": 7
              }, 
              {
                "text": "Now it happened at midnight that the man was startled, and turned himself; and there, a woman was lying at his feet.", 
                "num": 8
              }, 
              {
                "text": "And he said, \"Who are you?\" So she answered, \"I am Ruth, your maidservant. Take your maidservant under your wing, for you are a close relative.\"", 
                "num": 9
              }, 
              {
                "text": "Then he said, \"Blessed are you of the LORD, my daughter! For you have shown more kindness at the end than at the beginning, in that you did not go after young men, whether poor or rich.", 
                "num": 10
              }, 
              {
                "text": "And now, my daughter, do not fear. I will do for you all that you request, for all the people of my town know that you are a virtuous woman.", 
                "num": 11
              }, 
              {
                "text": "Now it is true that I am a close relative; however, there is a relative closer than I.", 
                "num": 12
              }, 
              {
                "text": "Stay this night, and in the morning it shall be that if he will perform the duty of a close relative for you--good; let him do it. But if he does not want to perform the duty for you, then I will perform the duty for you, as the LORD lives! Lie down until morning.\"", 
                "num": 13
              }, 
              {
                "text": "So she lay at his feet until morning, and she arose before one could recognize another. Then he said, \"Do not let it be known that the woman came to the threshing floor.\"", 
                "num": 14
              }, 
              {
                "text": "Also he said, \"Bring the shawl that is on you and hold it.\" And when she held it, he measured six ephahs of barley, and laid it on her. Then she went into the city.", 
                "num": 15
              }, 
              {
                "text": "When she came to her mother-in-law, she said, \"Is that you, my daughter?\" Then she told her all that the man had done for her.", 
                "num": 16
              }, 
              {
                "text": "And she said, \"These six ephahs of barley he gave me; for he said to me, \"Do not go empty-handed to your mother-in-law.\"'", 
                "num": 17
              }, 
              {
                "text": "Then she said, \"Sit still, my daughter, until you know how the matter will turn out; for the man will not rest until he has concluded the matter this day.\"", 
                "num": 18
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now Boaz went up to the gate and sat down there; and behold, the close relative of whom Boaz had spoken came by. So Boaz said, \"Come aside, friend, sit down here.\" So he came aside and sat down.", 
                "num": 1
              }, 
              {
                "text": "And he took ten men of the elders of the city, and said, \"Sit down here.\" So they sat down.", 
                "num": 2
              }, 
              {
                "text": "Then he said to the close relative, \"Naomi, who has come back from the country of Moab, sold the piece of land which belonged to our brother Elimelech.", 
                "num": 3
              }, 
              {
                "text": "And I thought to inform you, saying, \"Buy it back in the presence of the inhabitants and the elders of my people. If you will redeem it, redeem it; but if you will not redeem it, then tell me, that I may know; for there is no one but you to redeem it, and I am next after you.\"' And he said, \"I will redeem it.\"", 
                "num": 4
              }, 
              {
                "text": "Then Boaz said, \"On the day you buy the field from the hand of Naomi, you must also buy it from Ruth the Moabitess, the wife of the dead, to perpetuate the name of the dead through his inheritance.\"", 
                "num": 5
              }, 
              {
                "text": "And the close relative said, \"I cannot redeem it for myself, lest I ruin my own inheritance. You redeem my right of redemption for yourself, for I cannot redeem it.\"", 
                "num": 6
              }, 
              {
                "text": "Now this was the custom in former times in Israel concerning redeeming and exchanging, to confirm anything: one man took off his sandal and gave it to the other, and this was a confirmation in Israel.", 
                "num": 7
              }, 
              {
                "text": "Therefore the close relative said to Boaz, \"Buy it for yourself.\" So he took off his sandal.", 
                "num": 8
              }, 
              {
                "text": "And Boaz said to the elders and all the people, \"You are witnesses this day that I have bought all that was Elimelech's, and all that was Chilion's and Mahlon's, from the hand of Naomi.", 
                "num": 9
              }, 
              {
                "text": "Moreover, Ruth the Moabitess, the widow of Mahlon, I have acquired as my wife, to perpetuate the name of the dead through his inheritance, that the name of the dead may not be cut off from among his brethren and from his position at the gate. You are witnesses this day.\"", 
                "num": 10
              }, 
              {
                "text": "And all the people who were at the gate, and the elders, said, \"We are witnesses. The LORD make the woman who is coming to your house like Rachel and Leah, the two who built the house of Israel; and may you prosper in Ephrathah and be famous in Bethlehem.", 
                "num": 11
              }, 
              {
                "text": "May your house be like the house of Perez, whom Tamar bore to Judah, because of the offspring which the LORD will give you from this young woman.\"", 
                "num": 12
              }, 
              {
                "text": "So Boaz took Ruth and she became his wife; and when he went in to her, the LORD gave her conception, and she bore a son.", 
                "num": 13
              }, 
              {
                "text": "Then the women said to Naomi, \"Blessed be the LORD, who has not left you this day without a close relative; and may his name be famous in Israel!", 
                "num": 14
              }, 
              {
                "text": "And may he be to you a restorer of life and a nourisher of your old age; for your daughter-in-law, who loves you, who is better to you than seven sons, has borne him.\"", 
                "num": 15
              }, 
              {
                "text": "Then Naomi took the child and laid him on her bosom, and became a nurse to him.", 
                "num": 16
              }, 
              {
                "text": "Also the neighbor women gave him a name, saying, \"There is a son born to Naomi.\" And they called his name Obed. He is the father of Jesse, the father of David.", 
                "num": 17
              }, 
              {
                "text": "Now this is the genealogy of Perez: Perez begot Hezron;", 
                "num": 18
              }, 
              {
                "text": "Hezron begot Ram, and Ram begot Amminadab;", 
                "num": 19
              }, 
              {
                "text": "Amminadab begot Nahshon, and Nahshon begot Salmon;", 
                "num": 20
              }, 
              {
                "text": "Salmon begot Boaz, and Boaz begot Obed;", 
                "num": 21
              }, 
              {
                "text": "Obed begot Jesse, and Jesse begot David.", 
                "num": 22
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "1 Samuel", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now there was a certain man of Ramathaim Zophim, of the mountains of Ephraim, and his name was Elkanah the son of Jeroham, the son of Elihu, the son of Tohu, the son of Zuph, an Ephraimite.", 
                "num": 1
              }, 
              {
                "text": "And he had two wives: the name of one was Hannah, and the name of the other Peninnah. Peninnah had children, but Hannah had no children.", 
                "num": 2
              }, 
              {
                "text": "This man went up from his city yearly to worship and sacrifice to the LORD of hosts in Shiloh. Also the two sons of Eli, Hophni and Phinehas, the priests of the LORD, were there.", 
                "num": 3
              }, 
              {
                "text": "And whenever the time came for Elkanah to make an offering, he would give portions to Peninnah his wife and to all her sons and daughters.", 
                "num": 4
              }, 
              {
                "text": "But to Hannah he would give a double portion, for he loved Hannah, although the LORD had closed her womb.", 
                "num": 5
              }, 
              {
                "text": "And her rival also provoked her severely, to make her miserable, because the LORD had closed her womb.", 
                "num": 6
              }, 
              {
                "text": "So it was, year by year, when she went up to the house of the LORD, that she provoked her; therefore she wept and did not eat.", 
                "num": 7
              }, 
              {
                "text": "Then Elkanah her husband said to her, \"Hannah, why do you weep? Why do you not eat? And why is your heart grieved? Am I not better to you than ten sons?\"", 
                "num": 8
              }, 
              {
                "text": "So Hannah arose after they had finished eating and drinking in Shiloh. Now Eli the priest was sitting on the seat by the doorpost of the tabernacle of the LORD.", 
                "num": 9
              }, 
              {
                "text": "And she was in bitterness of soul, and prayed to the LORD and wept in anguish.", 
                "num": 10
              }, 
              {
                "text": "Then she made a vow and said, \"O LORD of hosts, if You will indeed look on the affliction of Your maidservant and remember me, and not forget Your maidservant, but will give Your maidservant a male child, then I will give him to the LORD all the days of his life, and no razor shall come upon his head.\"", 
                "num": 11
              }, 
              {
                "text": "And it happened, as she continued praying before the LORD, that Eli watched her mouth.", 
                "num": 12
              }, 
              {
                "text": "Now Hannah spoke in her heart; only her lips moved, but her voice was not heard. Therefore Eli thought she was drunk.", 
                "num": 13
              }, 
              {
                "text": "So Eli said to her, \"How long will you be drunk? Put your wine away from you!\"", 
                "num": 14
              }, 
              {
                "text": "But Hannah answered and said, \"No, my lord, I am a woman of sorrowful spirit. I have drunk neither wine nor intoxicating drink, but have poured out my soul before the LORD.", 
                "num": 15
              }, 
              {
                "text": "Do not consider your maidservant a wicked woman, for out of the abundance of my complaint and grief I have spoken until now.\"", 
                "num": 16
              }, 
              {
                "text": "Then Eli answered and said, \"Go in peace, and the God of Israel grant your petition which you have asked of Him.\"", 
                "num": 17
              }, 
              {
                "text": "And she said, \"Let your maidservant find favor in your sight.\" So the woman went her way and ate, and her face was no longer sad.", 
                "num": 18
              }, 
              {
                "text": "Then they rose early in the morning and worshiped before the LORD, and returned and came to their house at Ramah. And Elkanah knew Hannah his wife, and the LORD remembered her.", 
                "num": 19
              }, 
              {
                "text": "So it came to pass in the process of time that Hannah conceived and bore a son, and called his name Samuel, saying, \"Because I have asked for him from the LORD.\"", 
                "num": 20
              }, 
              {
                "text": "Now the man Elkanah and all his house went up to offer to the LORD the yearly sacrifice and his vow.", 
                "num": 21
              }, 
              {
                "text": "But Hannah did not go up, for she said to her husband, \"Not until the child is weaned; then I will take him, that he may appear before the LORD and remain there forever.\"", 
                "num": 22
              }, 
              {
                "text": "So Elkanah her husband said to her, \"Do what seems best to you; wait until you have weaned him. Only let the LORD establish His word.\" Then the woman stayed and nursed her son until she had weaned him.", 
                "num": 23
              }, 
              {
                "text": "Now when she had weaned him, she took him up with her, with three bulls, one ephah of flour, and a skin of wine, and brought him to the house of the LORD in Shiloh. And the child was young.", 
                "num": 24
              }, 
              {
                "text": "Then they slaughtered a bull, and brought the child to Eli.", 
                "num": 25
              }, 
              {
                "text": "And she said, \"O my lord! As your soul lives, my lord, I am the woman who stood by you here, praying to the LORD.", 
                "num": 26
              }, 
              {
                "text": "For this child I prayed, and the LORD has granted me my petition which I asked of Him.", 
                "num": 27
              }, 
              {
                "text": "Therefore I also have lent him to the LORD; as long as he lives he shall be lent to the LORD.\" So they worshiped the LORD there.", 
                "num": 28
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And Hannah prayed and said: \"My heart rejoices in the LORD; My horn is exalted in the LORD. I smile at my enemies, Because I rejoice in Your salvation.", 
                "num": 1
              }, 
              {
                "text": "\"No one is holy like the LORD, For there is none besides You, Nor is there any rock like our God.", 
                "num": 2
              }, 
              {
                "text": "\"Talk no more so very proudly; Let no arrogance come from your mouth, For the LORD is the God of knowledge; And by Him actions are weighed.", 
                "num": 3
              }, 
              {
                "text": "\"The bows of the mighty men are broken, And those who stumbled are girded with strength.", 
                "num": 4
              }, 
              {
                "text": "Those who were full have hired themselves out for bread, And the hungry have ceased to hunger. Even the barren has borne seven, And she who has many children has become feeble.", 
                "num": 5
              }, 
              {
                "text": "\"The LORD kills and makes alive; He brings down to the grave and brings up.", 
                "num": 6
              }, 
              {
                "text": "The LORD makes poor and makes rich; He brings low and lifts up.", 
                "num": 7
              }, 
              {
                "text": "He raises the poor from the dust And lifts the beggar from the ash heap, To set them among princes And make them inherit the throne of glory. \"For the pillars of the earth are the LORD's, And He has set the world upon them.", 
                "num": 8
              }, 
              {
                "text": "He will guard the feet of His saints, But the wicked shall be silent in darkness. \"For by strength no man shall prevail.", 
                "num": 9
              }, 
              {
                "text": "The adversaries of the LORD shall be broken in pieces; From heaven He will thunder against them. The LORD will judge the ends of the earth. \"He will give strength to His king, And exalt the horn of His anointed.\"", 
                "num": 10
              }, 
              {
                "text": "Then Elkanah went to his house at Ramah. But the child ministered to the LORD before Eli the priest.", 
                "num": 11
              }, 
              {
                "text": "Now the sons of Eli were corrupt; they did not know the LORD.", 
                "num": 12
              }, 
              {
                "text": "And the priests' custom with the people was that when any man offered a sacrifice, the priest's servant would come with a three-pronged fleshhook in his hand while the meat was boiling.", 
                "num": 13
              }, 
              {
                "text": "Then he would thrust it into the pan, or kettle, or caldron, or pot; and the priest would take for himself all that the fleshhook brought up. So they did in Shiloh to all the Israelites who came there.", 
                "num": 14
              }, 
              {
                "text": "Also, before they burned the fat, the priest's servant would come and say to the man who sacrificed, \"Give meat for roasting to the priest, for he will not take boiled meat from you, but raw.\"", 
                "num": 15
              }, 
              {
                "text": "And if the man said to him, \"They should really burn the fat first; then you may take as much as your heart desires,\" he would then answer him, \"No, but you must give it now; and if not, I will take it by force.\"", 
                "num": 16
              }, 
              {
                "text": "Therefore the sin of the young men was very great before the LORD, for men abhorred the offering of the LORD.", 
                "num": 17
              }, 
              {
                "text": "But Samuel ministered before the LORD, even as a child, wearing a linen ephod.", 
                "num": 18
              }, 
              {
                "text": "Moreover his mother used to make him a little robe, and bring it to him year by year when she came up with her husband to offer the yearly sacrifice.", 
                "num": 19
              }, 
              {
                "text": "And Eli would bless Elkanah and his wife, and say, \"The LORD give you descendants from this woman for the loan that was given to the LORD.\" Then they would go to their own home.", 
                "num": 20
              }, 
              {
                "text": "And the LORD visited Hannah, so that she conceived and bore three sons and two daughters. Meanwhile the child Samuel grew before the LORD.", 
                "num": 21
              }, 
              {
                "text": "Now Eli was very old; and he heard everything his sons did to all Israel, and how they lay with the women who assembled at the door of the tabernacle of meeting.", 
                "num": 22
              }, 
              {
                "text": "So he said to them, \"Why do you do such things? For I hear of your evil dealings from all the people.", 
                "num": 23
              }, 
              {
                "text": "No, my sons! For it is not a good report that I hear. You make the LORD's people transgress.", 
                "num": 24
              }, 
              {
                "text": "If one man sins against another, God will judge him. But if a man sins against the LORD, who will intercede for him?\" Nevertheless they did not heed the voice of their father, because the LORD desired to kill them.", 
                "num": 25
              }, 
              {
                "text": "And the child Samuel grew in stature, and in favor both with the LORD and men.", 
                "num": 26
              }, 
              {
                "text": "Then a man of God came to Eli and said to him, \"Thus says the LORD: \"Did I not clearly reveal Myself to the house of your father when they were in Egypt in Pharaoh's house?", 
                "num": 27
              }, 
              {
                "text": "Did I not choose him out of all the tribes of Israel to be My priest, to offer upon My altar, to burn incense, and to wear an ephod before Me? And did I not give to the house of your father all the offerings of the children of Israel made by fire?", 
                "num": 28
              }, 
              {
                "text": "Why do you kick at My sacrifice and My offering which I have commanded in My dwelling place, and honor your sons more than Me, to make yourselves fat with the best of all the offerings of Israel My people?'", 
                "num": 29
              }, 
              {
                "text": "Therefore the LORD God of Israel says: \"I said indeed that your house and the house of your father would walk before Me forever.' But now the LORD says: \"Far be it from Me; for those who honor Me I will honor, and those who despise Me shall be lightly esteemed.", 
                "num": 30
              }, 
              {
                "text": "Behold, the days are coming that I will cut off your arm and the arm of your father's house, so that there will not be an old man in your house.", 
                "num": 31
              }, 
              {
                "text": "And you will see an enemy in My dwelling place, despite all the good which God does for Israel. And there shall not be an old man in your house forever.", 
                "num": 32
              }, 
              {
                "text": "But any of your men whom I do not cut off from My altar shall consume your eyes and grieve your heart. And all the descendants of your house shall die in the flower of their age.", 
                "num": 33
              }, 
              {
                "text": "Now this shall be a sign to you that will come upon your two sons, on Hophni and Phinehas: in one day they shall die, both of them.", 
                "num": 34
              }, 
              {
                "text": "Then I will raise up for Myself a faithful priest who shall do according to what is in My heart and in My mind. I will build him a sure house, and he shall walk before My anointed forever.", 
                "num": 35
              }, 
              {
                "text": "And it shall come to pass that everyone who is left in your house will come and bow down to him for a piece of silver and a morsel of bread, and say, \"Please, put me in one of the priestly positions, that I may eat a piece of bread.\"\"'", 
                "num": 36
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now the boy Samuel ministered to the LORD before Eli. And the word of the LORD was rare in those days; there was no widespread revelation.", 
                "num": 1
              }, 
              {
                "text": "And it came to pass at that time, while Eli was lying down in his place, and when his eyes had begun to grow so dim that he could not see,", 
                "num": 2
              }, 
              {
                "text": "and before the lamp of God went out in the tabernacle of the LORD where the ark of God was, and while Samuel was lying down,", 
                "num": 3
              }, 
              {
                "text": "that the LORD called Samuel. And he answered, \"Here I am!\"", 
                "num": 4
              }, 
              {
                "text": "So he ran to Eli and said, \"Here I am, for you called me.\" And he said, \"I did not call; lie down again.\" And he went and lay down.", 
                "num": 5
              }, 
              {
                "text": "Then the LORD called yet again, \"Samuel!\" So Samuel arose and went to Eli, and said, \"Here I am, for you called me.\" He answered, \"I did not call, my son; lie down again.\"", 
                "num": 6
              }, 
              {
                "text": "(Now Samuel did not yet know the LORD, nor was the word of the LORD yet revealed to him.)", 
                "num": 7
              }, 
              {
                "text": "And the LORD called Samuel again the third time. So he arose and went to Eli, and said, \"Here I am, for you did call me.\" Then Eli perceived that the LORD had called the boy.", 
                "num": 8
              }, 
              {
                "text": "Therefore Eli said to Samuel, \"Go, lie down; and it shall be, if He calls you, that you must say, \"Speak, LORD, for Your servant hears.\"' So Samuel went and lay down in his place.", 
                "num": 9
              }, 
              {
                "text": "Now the LORD came and stood and called as at other times, \"Samuel! Samuel!\" And Samuel answered, \"Speak, for Your servant hears.\"", 
                "num": 10
              }, 
              {
                "text": "Then the LORD said to Samuel: \"Behold, I will do something in Israel at which both ears of everyone who hears it will tingle.", 
                "num": 11
              }, 
              {
                "text": "In that day I will perform against Eli all that I have spoken concerning his house, from beginning to end.", 
                "num": 12
              }, 
              {
                "text": "For I have told him that I will judge his house forever for the iniquity which he knows, because his sons made themselves vile, and he did not restrain them.", 
                "num": 13
              }, 
              {
                "text": "And therefore I have sworn to the house of Eli that the iniquity of Eli's house shall not be atoned for by sacrifice or offering forever.\"", 
                "num": 14
              }, 
              {
                "text": "So Samuel lay down until morning, and opened the doors of the house of the LORD. And Samuel was afraid to tell Eli the vision.", 
                "num": 15
              }, 
              {
                "text": "Then Eli called Samuel and said, \"Samuel, my son!\" He answered, \"Here I am.\"", 
                "num": 16
              }, 
              {
                "text": "And he said, \"What is the word that the LORD spoke to you? Please do not hide it from me. God do so to you, and more also, if you hide anything from me of all the things that He said to you.\"", 
                "num": 17
              }, 
              {
                "text": "Then Samuel told him everything, and hid nothing from him. And he said, \"It is the LORD. Let Him do what seems good to Him.\"", 
                "num": 18
              }, 
              {
                "text": "So Samuel grew, and the LORD was with him and let none of his words fall to the ground.", 
                "num": 19
              }, 
              {
                "text": "And all Israel from Dan to Beersheba knew that Samuel had been established as a prophet of the LORD.", 
                "num": 20
              }, 
              {
                "text": "Then the LORD appeared again in Shiloh. For the LORD revealed Himself to Samuel in Shiloh by the word of the LORD.", 
                "num": 21
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "And the word of Samuel came to all Israel. Now Israel went out to battle against the Philistines, and encamped beside Ebenezer; and the Philistines encamped in Aphek.", 
                "num": 1
              }, 
              {
                "text": "Then the Philistines put themselves in battle array against Israel. And when they joined battle, Israel was defeated by the Philistines, who killed about four thousand men of the army in the field.", 
                "num": 2
              }, 
              {
                "text": "And when the people had come into the camp, the elders of Israel said, \"Why has the LORD defeated us today before the Philistines? Let us bring the ark of the covenant of the LORD from Shiloh to us, that when it comes among us it may save us from the hand of our enemies.\"", 
                "num": 3
              }, 
              {
                "text": "So the people sent to Shiloh, that they might bring from there the ark of the covenant of the LORD of hosts, who dwells between the cherubim. And the two sons of Eli, Hophni and Phinehas, were there with the ark of the covenant of God.", 
                "num": 4
              }, 
              {
                "text": "And when the ark of the covenant of the LORD came into the camp, all Israel shouted so loudly that the earth shook.", 
                "num": 5
              }, 
              {
                "text": "Now when the Philistines heard the noise of the shout, they said, \"What does the sound of this great shout in the camp of the Hebrews mean?\" Then they understood that the ark of the LORD had come into the camp.", 
                "num": 6
              }, 
              {
                "text": "So the Philistines were afraid, for they said, \"God has come into the camp!\" And they said, \"Woe to us! For such a thing has never happened before.", 
                "num": 7
              }, 
              {
                "text": "Woe to us! Who will deliver us from the hand of these mighty gods? These are the gods who struck the Egyptians with all the plagues in the wilderness.", 
                "num": 8
              }, 
              {
                "text": "Be strong and conduct yourselves like men, you Philistines, that you do not become servants of the Hebrews, as they have been to you. Conduct yourselves like men, and fight!\"", 
                "num": 9
              }, 
              {
                "text": "So the Philistines fought, and Israel was defeated, and every man fled to his tent. There was a very great slaughter, and there fell of Israel thirty thousand foot soldiers.", 
                "num": 10
              }, 
              {
                "text": "Also the ark of God was captured; and the two sons of Eli, Hophni and Phinehas, died.", 
                "num": 11
              }, 
              {
                "text": "Then a man of Benjamin ran from the battle line the same day, and came to Shiloh with his clothes torn and dirt on his head.", 
                "num": 12
              }, 
              {
                "text": "Now when he came, there was Eli, sitting on a seat by the wayside watching, for his heart trembled for the ark of God. And when the man came into the city and told it, all the city cried out.", 
                "num": 13
              }, 
              {
                "text": "When Eli heard the noise of the outcry, he said, \"What does the sound of this tumult mean?\" And the man came quickly and told Eli.", 
                "num": 14
              }, 
              {
                "text": "Eli was ninety-eight years old, and his eyes were so dim that he could not see.", 
                "num": 15
              }, 
              {
                "text": "Then the man said to Eli, \"I am he who came from the battle. And I fled today from the battle line.\" And he said, \"What happened, my son?\"", 
                "num": 16
              }, 
              {
                "text": "So the messenger answered and said, \"Israel has fled before the Philistines, and there has been a great slaughter among the people. Also your two sons, Hophni and Phinehas, are dead; and the ark of God has been captured.\"", 
                "num": 17
              }, 
              {
                "text": "Then it happened, when he made mention of the ark of God, that Eli fell off the seat backward by the side of the gate; and his neck was broken and he died, for the man was old and heavy. And he had judged Israel forty years.", 
                "num": 18
              }, 
              {
                "text": "Now his daughter-in-law, Phinehas' wife, was with child, due to be delivered; and when she heard the news that the ark of God was captured, and that her father-in-law and her husband were dead, she bowed herself and gave birth, for her labor pains came upon her.", 
                "num": 19
              }, 
              {
                "text": "And about the time of her death the women who stood by her said to her, \"Do not fear, for you have borne a son.\" But she did not answer, nor did she regard it.", 
                "num": 20
              }, 
              {
                "text": "Then she named the child Ichabod, saying, \"The glory has departed from Israel!\" because the ark of God had been captured and because of her father-in-law and her husband.", 
                "num": 21
              }, 
              {
                "text": "And she said, \"The glory has departed from Israel, for the ark of God has been captured.\"", 
                "num": 22
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then the Philistines took the ark of God and brought it from Ebenezer to Ashdod.", 
                "num": 1
              }, 
              {
                "text": "When the Philistines took the ark of God, they brought it into the house of Dagon and set it by Dagon.", 
                "num": 2
              }, 
              {
                "text": "And when the people of Ashdod arose early in the morning, there was Dagon, fallen on its face to the earth before the ark of the LORD. So they took Dagon and set it in its place again.", 
                "num": 3
              }, 
              {
                "text": "And when they arose early the next morning, there was Dagon, fallen on its face to the ground before the ark of the LORD. The head of Dagon and both the palms of its hands were broken off on the threshold; only Dagon's torso was left of it.", 
                "num": 4
              }, 
              {
                "text": "Therefore neither the priests of Dagon nor any who come into Dagon's house tread on the threshold of Dagon in Ashdod to this day.", 
                "num": 5
              }, 
              {
                "text": "But the hand of the LORD was heavy on the people of Ashdod, and He ravaged them and struck them with tumors, both Ashdod and its territory.", 
                "num": 6
              }, 
              {
                "text": "And when the men of Ashdod saw how it was, they said, \"The ark of the God of Israel must not remain with us, for His hand is harsh toward us and Dagon our god.\"", 
                "num": 7
              }, 
              {
                "text": "Therefore they sent and gathered to themselves all the lords of the Philistines, and said, \"What shall we do with the ark of the God of Israel?\" And they answered, \"Let the ark of the God of Israel be carried away to Gath.\" So they carried the ark of the God of Israel away.", 
                "num": 8
              }, 
              {
                "text": "So it was, after they had carried it away, that the hand of the LORD was against the city with a very great destruction; and He struck the men of the city, both small and great, and tumors broke out on them.", 
                "num": 9
              }, 
              {
                "text": "Therefore they sent the ark of God to Ekron. So it was, as the ark of God came to Ekron, that the Ekronites cried out, saying, \"They have brought the ark of the God of Israel to us, to kill us and our people!\"", 
                "num": 10
              }, 
              {
                "text": "So they sent and gathered together all the lords of the Philistines, and said, \"Send away the ark of the God of Israel, and let it go back to its own place, so that it does not kill us and our people.\" For there was a deadly destruction throughout all the city; the hand of God was very heavy there.", 
                "num": 11
              }, 
              {
                "text": "And the men who did not die were stricken with the tumors, and the cry of the city went up to heaven.", 
                "num": 12
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now the ark of the LORD was in the country of the Philistines seven months.", 
                "num": 1
              }, 
              {
                "text": "And the Philistines called for the priests and the diviners, saying, \"What shall we do with the ark of the LORD? Tell us how we should send it to its place.\"", 
                "num": 2
              }, 
              {
                "text": "So they said, \"If you send away the ark of the God of Israel, do not send it empty; but by all means return it to Him with a trespass offering. Then you will be healed, and it will be known to you why His hand is not removed from you.\"", 
                "num": 3
              }, 
              {
                "text": "Then they said, \"What is the trespass offering which we shall return to Him?\" They answered, \"Five golden tumors and five golden rats, according to the number of the lords of the Philistines. For the same plague was on all of you and on your lords.", 
                "num": 4
              }, 
              {
                "text": "Therefore you shall make images of your tumors and images of your rats that ravage the land, and you shall give glory to the God of Israel; perhaps He will lighten His hand from you, from your gods, and from your land.", 
                "num": 5
              }, 
              {
                "text": "Why then do you harden your hearts as the Egyptians and Pharaoh hardened their hearts? When He did mighty things among them, did they not let the people go, that they might depart?", 
                "num": 6
              }, 
              {
                "text": "Now therefore, make a new cart, take two milk cows which have never been yoked, and hitch the cows to the cart; and take their calves home, away from them.", 
                "num": 7
              }, 
              {
                "text": "Then take the ark of the LORD and set it on the cart; and put the articles of gold which you are returning to Him as a trespass offering in a chest by its side. Then send it away, and let it go.", 
                "num": 8
              }, 
              {
                "text": "And watch: if it goes up the road to its own territory, to Beth Shemesh, then He has done us this great evil. But if not, then we shall know that it is not His hand that struck us--it happened to us by chance.\"", 
                "num": 9
              }, 
              {
                "text": "Then the men did so; they took two milk cows and hitched them to the cart, and shut up their calves at home.", 
                "num": 10
              }, 
              {
                "text": "And they set the ark of the LORD on the cart, and the chest with the gold rats and the images of their tumors.", 
                "num": 11
              }, 
              {
                "text": "Then the cows headed straight for the road to Beth Shemesh, and went along the highway, lowing as they went, and did not turn aside to the right hand or the left. And the lords of the Philistines went after them to the border of Beth Shemesh.", 
                "num": 12
              }, 
              {
                "text": "Now the people of Beth Shemesh were reaping their wheat harvest in the valley; and they lifted their eyes and saw the ark, and rejoiced to see it.", 
                "num": 13
              }, 
              {
                "text": "Then the cart came into the field of Joshua of Beth Shemesh, and stood there; a large stone was there. So they split the wood of the cart and offered the cows as a burnt offering to the LORD.", 
                "num": 14
              }, 
              {
                "text": "The Levites took down the ark of the LORD and the chest that was with it, in which were the articles of gold, and put them on the large stone. Then the men of Beth Shemesh offered burnt offerings and made sacrifices the same day to the LORD.", 
                "num": 15
              }, 
              {
                "text": "So when the five lords of the Philistines had seen it, they returned to Ekron the same day.", 
                "num": 16
              }, 
              {
                "text": "These are the golden tumors which the Philistines returned as a trespass offering to the LORD: one for Ashdod, one for Gaza, one for Ashkelon, one for Gath, one for Ekron;", 
                "num": 17
              }, 
              {
                "text": "and the golden rats, according to the number of all the cities of the Philistines belonging to the five lords, both fortified cities and country villages, even as far as the large stone of Abel on which they set the ark of the LORD, which stone remains to this day in the field of Joshua of Beth Shemesh.", 
                "num": 18
              }, 
              {
                "text": "Then He struck the men of Beth Shemesh, because they had looked into the ark of the LORD. He struck fifty thousand and seventy men of the people, and the people lamented because the LORD had struck the people with a great slaughter.", 
                "num": 19
              }, 
              {
                "text": "And the men of Beth Shemesh said, \"Who is able to stand before this holy LORD God? And to whom shall it go up from us?\"", 
                "num": 20
              }, 
              {
                "text": "So they sent messengers to the inhabitants of Kirjath Jearim, saying, \"The Philistines have brought back the ark of the LORD; come down and take it up with you.\"", 
                "num": 21
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then the men of Kirjath Jearim came and took the ark of the LORD, and brought it into the house of Abinadab on the hill, and consecrated Eleazar his son to keep the ark of the LORD.", 
                "num": 1
              }, 
              {
                "text": "So it was that the ark remained in Kirjath Jearim a long time; it was there twenty years. And all the house of Israel lamented after the LORD.", 
                "num": 2
              }, 
              {
                "text": "Then Samuel spoke to all the house of Israel, saying, \"If you return to the LORD with all your hearts, then put away the foreign gods and the Ashtoreths from among you, and prepare your hearts for the LORD, and serve Him only; and He will deliver you from the hand of the Philistines.\"", 
                "num": 3
              }, 
              {
                "text": "So the children of Israel put away the Baals and the Ashtoreths, and served the LORD only.", 
                "num": 4
              }, 
              {
                "text": "And Samuel said, \"Gather all Israel to Mizpah, and I will pray to the LORD for you.\"", 
                "num": 5
              }, 
              {
                "text": "So they gathered together at Mizpah, drew water, and poured it out before the LORD. And they fasted that day, and said there, \"We have sinned against the LORD.\" And Samuel judged the children of Israel at Mizpah.", 
                "num": 6
              }, 
              {
                "text": "Now when the Philistines heard that the children of Israel had gathered together at Mizpah, the lords of the Philistines went up against Israel. And when the children of Israel heard of it, they were afraid of the Philistines.", 
                "num": 7
              }, 
              {
                "text": "So the children of Israel said to Samuel, \"Do not cease to cry out to the LORD our God for us, that He may save us from the hand of the Philistines.\"", 
                "num": 8
              }, 
              {
                "text": "And Samuel took a suckling lamb and offered it as a whole burnt offering to the LORD. Then Samuel cried out to the LORD for Israel, and the LORD answered him.", 
                "num": 9
              }, 
              {
                "text": "Now as Samuel was offering up the burnt offering, the Philistines drew near to battle against Israel. But the LORD thundered with a loud thunder upon the Philistines that day, and so confused them that they were overcome before Israel.", 
                "num": 10
              }, 
              {
                "text": "And the men of Israel went out of Mizpah and pursued the Philistines, and drove them back as far as below Beth Car.", 
                "num": 11
              }, 
              {
                "text": "Then Samuel took a stone and set it up between Mizpah and Shen, and called its name Ebenezer, saying, \"Thus far the LORD has helped us.\"", 
                "num": 12
              }, 
              {
                "text": "So the Philistines were subdued, and they did not come anymore into the territory of Israel. And the hand of the LORD was against the Philistines all the days of Samuel.", 
                "num": 13
              }, 
              {
                "text": "Then the cities which the Philistines had taken from Israel were restored to Israel, from Ekron to Gath; and Israel recovered its territory from the hands of the Philistines. Also there was peace between Israel and the Amorites.", 
                "num": 14
              }, 
              {
                "text": "And Samuel judged Israel all the days of his life.", 
                "num": 15
              }, 
              {
                "text": "He went from year to year on a circuit to Bethel, Gilgal, and Mizpah, and judged Israel in all those places.", 
                "num": 16
              }, 
              {
                "text": "But he always returned to Ramah, for his home was there. There he judged Israel, and there he built an altar to the LORD.", 
                "num": 17
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass when Samuel was old that he made his sons judges over Israel.", 
                "num": 1
              }, 
              {
                "text": "The name of his firstborn was Joel, and the name of his second, Abijah; they were judges in Beersheba.", 
                "num": 2
              }, 
              {
                "text": "But his sons did not walk in his ways; they turned aside after dishonest gain, took bribes, and perverted justice.", 
                "num": 3
              }, 
              {
                "text": "Then all the elders of Israel gathered together and came to Samuel at Ramah,", 
                "num": 4
              }, 
              {
                "text": "and said to him, \"Look, you are old, and your sons do not walk in your ways. Now make us a king to judge us like all the nations.\"", 
                "num": 5
              }, 
              {
                "text": "But the thing displeased Samuel when they said, \"Give us a king to judge us.\" So Samuel prayed to the LORD.", 
                "num": 6
              }, 
              {
                "text": "And the LORD said to Samuel, \"Heed the voice of the people in all that they say to you; for they have not rejected you, but they have rejected Me, that I should not reign over them.", 
                "num": 7
              }, 
              {
                "text": "According to all the works which they have done since the day that I brought them up out of Egypt, even to this day--with which they have forsaken Me and served other gods--so they are doing to you also.", 
                "num": 8
              }, 
              {
                "text": "Now therefore, heed their voice. However, you shall solemnly forewarn them, and show them the behavior of the king who will reign over them.\"", 
                "num": 9
              }, 
              {
                "text": "So Samuel told all the words of the LORD to the people who asked him for a king.", 
                "num": 10
              }, 
              {
                "text": "And he said, \"This will be the behavior of the king who will reign over you: He will take your sons and appoint them for his own chariots and to be his horsemen, and some will run before his chariots.", 
                "num": 11
              }, 
              {
                "text": "He will appoint captains over his thousands and captains over his fifties, will set some to plow his ground and reap his harvest, and some to make his weapons of war and equipment for his chariots.", 
                "num": 12
              }, 
              {
                "text": "He will take your daughters to be perfumers, cooks, and bakers.", 
                "num": 13
              }, 
              {
                "text": "And he will take the best of your fields, your vineyards, and your olive groves, and give them to his servants.", 
                "num": 14
              }, 
              {
                "text": "He will take a tenth of your grain and your vintage, and give it to his officers and servants.", 
                "num": 15
              }, 
              {
                "text": "And he will take your male servants, your female servants, your finest young men, and your donkeys, and put them to his work.", 
                "num": 16
              }, 
              {
                "text": "He will take a tenth of your sheep. And you will be his servants.", 
                "num": 17
              }, 
              {
                "text": "And you will cry out in that day because of your king whom you have chosen for yourselves, and the LORD will not hear you in that day.\"", 
                "num": 18
              }, 
              {
                "text": "Nevertheless the people refused to obey the voice of Samuel; and they said, \"No, but we will have a king over us,", 
                "num": 19
              }, 
              {
                "text": "that we also may be like all the nations, and that our king may judge us and go out before us and fight our battles.\"", 
                "num": 20
              }, 
              {
                "text": "And Samuel heard all the words of the people, and he repeated them in the hearing of the LORD.", 
                "num": 21
              }, 
              {
                "text": "So the LORD said to Samuel, \"Heed their voice, and make them a king.\" And Samuel said to the men of Israel, \"Every man go to his city.\"", 
                "num": 22
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "There was a man of Benjamin whose name was Kish the son of Abiel, the son of Zeror, the son of Bechorath, the son of Aphiah, a Benjamite, a mighty man of power.", 
                "num": 1
              }, 
              {
                "text": "And he had a choice and handsome son whose name was Saul. There was not a more handsome person than he among the children of Israel. From his shoulders upward he was taller than any of the people.", 
                "num": 2
              }, 
              {
                "text": "Now the donkeys of Kish, Saul's father, were lost. And Kish said to his son Saul, \"Please take one of the servants with you, and arise, go and look for the donkeys.\"", 
                "num": 3
              }, 
              {
                "text": "So he passed through the mountains of Ephraim and through the land of Shalisha, but they did not find them. Then they passed through the land of Shaalim, and they were not there. Then he passed through the land of the Benjamites, but they did not find them.", 
                "num": 4
              }, 
              {
                "text": "When they had come to the land of Zuph, Saul said to his servant who was with him, \"Come, let us return, lest my father cease caring about the donkeys and become worried about us.\"", 
                "num": 5
              }, 
              {
                "text": "And he said to him, \"Look now, there is in this city a man of God, and he is an honorable man; all that he says surely comes to pass. So let us go there; perhaps he can show us the way that we should go.\"", 
                "num": 6
              }, 
              {
                "text": "Then Saul said to his servant, \"But look, if we go, what shall we bring the man? For the bread in our vessels is all gone, and there is no present to bring to the man of God. What do we have?\"", 
                "num": 7
              }, 
              {
                "text": "And the servant answered Saul again and said, \"Look, I have here at hand one-fourth of a shekel of silver. I will give that to the man of God, to tell us our way.\"", 
                "num": 8
              }, 
              {
                "text": "(Formerly in Israel, when a man went to inquire of God, he spoke thus: \"Come, let us go to the seer\"; for he who is now called a prophet was formerly called a seer.)", 
                "num": 9
              }, 
              {
                "text": "Then Saul said to his servant, \"Well said; come, let us go.\" So they went to the city where the man of God was.", 
                "num": 10
              }, 
              {
                "text": "As they went up the hill to the city, they met some young women going out to draw water, and said to them, \"Is the seer here?\"", 
                "num": 11
              }, 
              {
                "text": "And they answered them and said, \"Yes, there he is, just ahead of you. Hurry now; for today he came to this city, because there is a sacrifice of the people today on the high place.", 
                "num": 12
              }, 
              {
                "text": "As soon as you come into the city, you will surely find him before he goes up to the high place to eat. For the people will not eat until he comes, because he must bless the sacrifice; afterward those who are invited will eat. Now therefore, go up, for about this time you will find him.\"", 
                "num": 13
              }, 
              {
                "text": "So they went up to the city. As they were coming into the city, there was Samuel, coming out toward them on his way up to the high place.", 
                "num": 14
              }, 
              {
                "text": "Now the LORD had told Samuel in his ear the day before Saul came, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Tomorrow about this time I will send you a man from the land of Benjamin, and you shall anoint him commander over My people Israel, that he may save My people from the hand of the Philistines; for I have looked upon My people, because their cry has come to Me.\"", 
                "num": 16
              }, 
              {
                "text": "So when Samuel saw Saul, the LORD said to him, \"There he is, the man of whom I spoke to you. This one shall reign over My people.\"", 
                "num": 17
              }, 
              {
                "text": "Then Saul drew near to Samuel in the gate, and said, \"Please tell me, where is the seer's house?\"", 
                "num": 18
              }, 
              {
                "text": "Samuel answered Saul and said, \"I am the seer. Go up before me to the high place, for you shall eat with me today; and tomorrow I will let you go and will tell you all that is in your heart.", 
                "num": 19
              }, 
              {
                "text": "But as for your donkeys that were lost three days ago, do not be anxious about them, for they have been found. And on whom is all the desire of Israel? Is it not on you and on all your father's house?\"", 
                "num": 20
              }, 
              {
                "text": "And Saul answered and said, \"Am I not a Benjamite, of the smallest of the tribes of Israel, and my family the least of all the families of the tribe of Benjamin? Why then do you speak like this to me?\"", 
                "num": 21
              }, 
              {
                "text": "Now Samuel took Saul and his servant and brought them into the hall, and had them sit in the place of honor among those who were invited; there were about thirty persons.", 
                "num": 22
              }, 
              {
                "text": "And Samuel said to the cook, \"Bring the portion which I gave you, of which I said to you, \"Set it apart.\"'", 
                "num": 23
              }, 
              {
                "text": "So the cook took up the thigh with its upper part and set it before Saul. And Samuel said, \"Here it is, what was kept back. It was set apart for you. Eat; for until this time it has been kept for you, since I said I invited the people.\" So Saul ate with Samuel that day.", 
                "num": 24
              }, 
              {
                "text": "When they had come down from the high place into the city, Samuel spoke with Saul on the top of the house.", 
                "num": 25
              }, 
              {
                "text": "They arose early; and it was about the dawning of the day that Samuel called to Saul on the top of the house, saying, \"Get up, that I may send you on your way.\" And Saul arose, and both of them went outside, he and Samuel.", 
                "num": 26
              }, 
              {
                "text": "As they were going down to the outskirts of the city, Samuel said to Saul, \"Tell the servant to go on ahead of us.\" And he went on. \"But you stand here awhile, that I may announce to you the word of God.\"", 
                "num": 27
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Then Samuel took a flask of oil and poured it on his head, and kissed him and said: \"Is it not because the LORD has anointed you commander over His inheritance?", 
                "num": 1
              }, 
              {
                "text": "When you have departed from me today, you will find two men by Rachel's tomb in the territory of Benjamin at Zelzah; and they will say to you, \"The donkeys which you went to look for have been found. And now your father has ceased caring about the donkeys and is worrying about you, saying, \"What shall I do about my son?\"'", 
                "num": 2
              }, 
              {
                "text": "Then you shall go on forward from there and come to the terebinth tree of Tabor. There three men going up to God at Bethel will meet you, one carrying three young goats, another carrying three loaves of bread, and another carrying a skin of wine.", 
                "num": 3
              }, 
              {
                "text": "And they will greet you and give you two loaves of bread, which you shall receive from their hands.", 
                "num": 4
              }, 
              {
                "text": "After that you shall come to the hill of God where the Philistine garrison is. And it will happen, when you have come there to the city, that you will meet a group of prophets coming down from the high place with a stringed instrument, a tambourine, a flute, and a harp before them; and they will be prophesying.", 
                "num": 5
              }, 
              {
                "text": "Then the Spirit of the LORD will come upon you, and you will prophesy with them and be turned into another man.", 
                "num": 6
              }, 
              {
                "text": "And let it be, when these signs come to you, that you do as the occasion demands; for God is with you.", 
                "num": 7
              }, 
              {
                "text": "You shall go down before me to Gilgal; and surely I will come down to you to offer burnt offerings and make sacrifices of peace offerings. Seven days you shall wait, till I come to you and show you what you should do.\"", 
                "num": 8
              }, 
              {
                "text": "So it was, when he had turned his back to go from Samuel, that God gave him another heart; and all those signs came to pass that day.", 
                "num": 9
              }, 
              {
                "text": "When they came there to the hill, there was a group of prophets to meet him; then the Spirit of God came upon him, and he prophesied among them.", 
                "num": 10
              }, 
              {
                "text": "And it happened, when all who knew him formerly saw that he indeed prophesied among the prophets, that the people said to one another, \"What is this that has come upon the son of Kish? Is Saul also among the prophets?\"", 
                "num": 11
              }, 
              {
                "text": "Then a man from there answered and said, \"But who is their father?\" Therefore it became a proverb: \"Is Saul also among the prophets?\"", 
                "num": 12
              }, 
              {
                "text": "And when he had finished prophesying, he went to the high place.", 
                "num": 13
              }, 
              {
                "text": "Then Saul's uncle said to him and his servant, \"Where did you go?\" So he said, \"To look for the donkeys. When we saw that they were nowhere to be found, we went to Samuel.\"", 
                "num": 14
              }, 
              {
                "text": "And Saul's uncle said, \"Tell me, please, what Samuel said to you.\"", 
                "num": 15
              }, 
              {
                "text": "So Saul said to his uncle, \"He told us plainly that the donkeys had been found.\" But about the matter of the kingdom, he did not tell him what Samuel had said.", 
                "num": 16
              }, 
              {
                "text": "Then Samuel called the people together to the LORD at Mizpah,", 
                "num": 17
              }, 
              {
                "text": "and said to the children of Israel, \"Thus says the LORD God of Israel: \"I brought up Israel out of Egypt, and delivered you from the hand of the Egyptians and from the hand of all kingdoms and from those who oppressed you.'", 
                "num": 18
              }, 
              {
                "text": "But you have today rejected your God, who Himself saved you from all your adversities and your tribulations; and you have said to Him, \"No, set a king over us!' Now therefore, present yourselves before the LORD by your tribes and by your clans.\"", 
                "num": 19
              }, 
              {
                "text": "And when Samuel had caused all the tribes of Israel to come near, the tribe of Benjamin was chosen.", 
                "num": 20
              }, 
              {
                "text": "When he had caused the tribe of Benjamin to come near by their families, the family of Matri was chosen. And Saul the son of Kish was chosen. But when they sought him, he could not be found.", 
                "num": 21
              }, 
              {
                "text": "Therefore they inquired of the LORD further, \"Has the man come here yet?\" And the LORD answered, \"There he is, hidden among the equipment.\"", 
                "num": 22
              }, 
              {
                "text": "So they ran and brought him from there; and when he stood among the people, he was taller than any of the people from his shoulders upward.", 
                "num": 23
              }, 
              {
                "text": "And Samuel said to all the people, \"Do you see him whom the LORD has chosen, that there is no one like him among all the people?\" So all the people shouted and said, \"Long live the king!\"", 
                "num": 24
              }, 
              {
                "text": "Then Samuel explained to the people the behavior of royalty, and wrote it in a book and laid it up before the LORD. And Samuel sent all the people away, every man to his house.", 
                "num": 25
              }, 
              {
                "text": "And Saul also went home to Gibeah; and valiant men went with him, whose hearts God had touched.", 
                "num": 26
              }, 
              {
                "text": "But some rebels said, \"How can this man save us?\" So they despised him, and brought him no presents. But he held his peace.", 
                "num": 27
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Then Nahash the Ammonite came up and encamped against Jabesh Gilead; and all the men of Jabesh said to Nahash, \"Make a covenant with us, and we will serve you.\"", 
                "num": 1
              }, 
              {
                "text": "And Nahash the Ammonite answered them, \"On this condition I will make a covenant with you, that I may put out all your right eyes, and bring reproach on all Israel.\"", 
                "num": 2
              }, 
              {
                "text": "Then the elders of Jabesh said to him, \"Hold off for seven days, that we may send messengers to all the territory of Israel. And then, if there is no one to save us, we will come out to you.\"", 
                "num": 3
              }, 
              {
                "text": "So the messengers came to Gibeah of Saul and told the news in the hearing of the people. And all the people lifted up their voices and wept.", 
                "num": 4
              }, 
              {
                "text": "Now there was Saul, coming behind the herd from the field; and Saul said, \"What troubles the people, that they weep?\" And they told him the words of the men of Jabesh.", 
                "num": 5
              }, 
              {
                "text": "Then the Spirit of God came upon Saul when he heard this news, and his anger was greatly aroused.", 
                "num": 6
              }, 
              {
                "text": "So he took a yoke of oxen and cut them in pieces, and sent them throughout all the territory of Israel by the hands of messengers, saying, \"Whoever does not go out with Saul and Samuel to battle, so it shall be done to his oxen.\" And the fear of the LORD fell on the people, and they came out with one consent.", 
                "num": 7
              }, 
              {
                "text": "When he numbered them in Bezek, the children of Israel were three hundred thousand, and the men of Judah thirty thousand.", 
                "num": 8
              }, 
              {
                "text": "And they said to the messengers who came, \"Thus you shall say to the men of Jabesh Gilead: \"Tomorrow, by the time the sun is hot, you shall have help.\"' Then the messengers came and reported it to the men of Jabesh, and they were glad.", 
                "num": 9
              }, 
              {
                "text": "Therefore the men of Jabesh said, \"Tomorrow we will come out to you, and you may do with us whatever seems good to you.\"", 
                "num": 10
              }, 
              {
                "text": "So it was, on the next day, that Saul put the people in three companies; and they came into the midst of the camp in the morning watch, and killed Ammonites until the heat of the day. And it happened that those who survived were scattered, so that no two of them were left together.", 
                "num": 11
              }, 
              {
                "text": "Then the people said to Samuel, \"Who is he who said, \"Shall Saul reign over us?' Bring the men, that we may put them to death.\"", 
                "num": 12
              }, 
              {
                "text": "But Saul said, \"Not a man shall be put to death this day, for today the LORD has accomplished salvation in Israel.\"", 
                "num": 13
              }, 
              {
                "text": "Then Samuel said to the people, \"Come, let us go to Gilgal and renew the kingdom there.\"", 
                "num": 14
              }, 
              {
                "text": "So all the people went to Gilgal, and there they made Saul king before the LORD in Gilgal. There they made sacrifices of peace offerings before the LORD, and there Saul and all the men of Israel rejoiced greatly.", 
                "num": 15
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now Samuel said to all Israel: \"Indeed I have heeded your voice in all that you said to me, and have made a king over you.", 
                "num": 1
              }, 
              {
                "text": "And now here is the king, walking before you; and I am old and grayheaded, and look, my sons are with you. I have walked before you from my childhood to this day.", 
                "num": 2
              }, 
              {
                "text": "Here I am. Witness against me before the LORD and before His anointed: Whose ox have I taken, or whose donkey have I taken, or whom have I cheated? Whom have I oppressed, or from whose hand have I received any bribe with which to blind my eyes? I will restore it to you.\"", 
                "num": 3
              }, 
              {
                "text": "And they said, \"You have not cheated us or oppressed us, nor have you taken anything from any man's hand.\"", 
                "num": 4
              }, 
              {
                "text": "Then he said to them, \"The LORD is witness against you, and His anointed is witness this day, that you have not found anything in my hand.\" And they answered, \"He is witness.\"", 
                "num": 5
              }, 
              {
                "text": "Then Samuel said to the people, \"It is the LORD who raised up Moses and Aaron, and who brought your fathers up from the land of Egypt.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, stand still, that I may reason with you before the LORD concerning all the righteous acts of the LORD which He did to you and your fathers:", 
                "num": 7
              }, 
              {
                "text": "When Jacob had gone into Egypt, and your fathers cried out to the LORD, then the LORD sent Moses and Aaron, who brought your fathers out of Egypt and made them dwell in this place.", 
                "num": 8
              }, 
              {
                "text": "And when they forgot the LORD their God, He sold them into the hand of Sisera, commander of the army of Hazor, into the hand of the Philistines, and into the hand of the king of Moab; and they fought against them.", 
                "num": 9
              }, 
              {
                "text": "Then they cried out to the LORD, and said, \"We have sinned, because we have forsaken the LORD and served the Baals and Ashtoreths; but now deliver us from the hand of our enemies, and we will serve You.'", 
                "num": 10
              }, 
              {
                "text": "And the LORD sent Jerubbaal, Bedan, Jephthah, and Samuel, and delivered you out of the hand of your enemies on every side; and you dwelt in safety.", 
                "num": 11
              }, 
              {
                "text": "And when you saw that Nahash king of the Ammonites came against you, you said to me, \"No, but a king shall reign over us,' when the LORD your God was your king.", 
                "num": 12
              }, 
              {
                "text": "\"Now therefore, here is the king whom you have chosen and whom you have desired. And take note, the LORD has set a king over you.", 
                "num": 13
              }, 
              {
                "text": "If you fear the LORD and serve Him and obey His voice, and do not rebel against the commandment of the LORD, then both you and the king who reigns over you will continue following the LORD your God.", 
                "num": 14
              }, 
              {
                "text": "However, if you do not obey the voice of the LORD, but rebel against the commandment of the LORD, then the hand of the LORD will be against you, as it was against your fathers.", 
                "num": 15
              }, 
              {
                "text": "\"Now therefore, stand and see this great thing which the LORD will do before your eyes:", 
                "num": 16
              }, 
              {
                "text": "Is today not the wheat harvest? I will call to the LORD, and He will send thunder and rain, that you may perceive and see that your wickedness is great, which you have done in the sight of the LORD, in asking a king for yourselves.\"", 
                "num": 17
              }, 
              {
                "text": "So Samuel called to the LORD, and the LORD sent thunder and rain that day; and all the people greatly feared the LORD and Samuel.", 
                "num": 18
              }, 
              {
                "text": "And all the people said to Samuel, \"Pray for your servants to the LORD your God, that we may not die; for we have added to all our sins the evil of asking a king for ourselves.\"", 
                "num": 19
              }, 
              {
                "text": "Then Samuel said to the people, \"Do not fear. You have done all this wickedness; yet do not turn aside from following the LORD, but serve the LORD with all your heart.", 
                "num": 20
              }, 
              {
                "text": "And do not turn aside; for then you would go after empty things which cannot profit or deliver, for they are nothing.", 
                "num": 21
              }, 
              {
                "text": "For the LORD will not forsake His people, for His great name's sake, because it has pleased the LORD to make you His people.", 
                "num": 22
              }, 
              {
                "text": "Moreover, as for me, far be it from me that I should sin against the LORD in ceasing to pray for you; but I will teach you the good and the right way.", 
                "num": 23
              }, 
              {
                "text": "Only fear the LORD, and serve Him in truth with all your heart; for consider what great things He has done for you.", 
                "num": 24
              }, 
              {
                "text": "But if you still do wickedly, you shall be swept away, both you and your king.\"", 
                "num": 25
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Saul reigned one year; and when he had reigned two years over Israel,", 
                "num": 1
              }, 
              {
                "text": "Saul chose for himself three thousand men of Israel. Two thousand were with Saul in Michmash and in the mountains of Bethel, and a thousand were with Jonathan in Gibeah of Benjamin. The rest of the people he sent away, every man to his tent.", 
                "num": 2
              }, 
              {
                "text": "And Jonathan attacked the garrison of the Philistines that was in Geba, and the Philistines heard of it. Then Saul blew the trumpet throughout all the land, saying, \"Let the Hebrews hear!\"", 
                "num": 3
              }, 
              {
                "text": "Now all Israel heard it said that Saul had attacked a garrison of the Philistines, and that Israel had also become an abomination to the Philistines. And the people were called together to Saul at Gilgal.", 
                "num": 4
              }, 
              {
                "text": "Then the Philistines gathered together to fight with Israel, thirty thousand chariots and six thousand horsemen, and people as the sand which is on the seashore in multitude. And they came up and encamped in Michmash, to the east of Beth Aven.", 
                "num": 5
              }, 
              {
                "text": "When the men of Israel saw that they were in danger (for the people were distressed), then the people hid in caves, in thickets, in rocks, in holes, and in pits.", 
                "num": 6
              }, 
              {
                "text": "And some of the Hebrews crossed over the Jordan to the land of Gad and Gilead. As for Saul, he was still in Gilgal, and all the people followed him trembling.", 
                "num": 7
              }, 
              {
                "text": "Then he waited seven days, according to the time set by Samuel. But Samuel did not come to Gilgal; and the people were scattered from him.", 
                "num": 8
              }, 
              {
                "text": "So Saul said, \"Bring a burnt offering and peace offerings here to me.\" And he offered the burnt offering.", 
                "num": 9
              }, 
              {
                "text": "Now it happened, as soon as he had finished presenting the burnt offering, that Samuel came; and Saul went out to meet him, that he might greet him.", 
                "num": 10
              }, 
              {
                "text": "And Samuel said, \"What have you done?\" Saul said, \"When I saw that the people were scattered from me, and that you did not come within the days appointed, and that the Philistines gathered together at Michmash,", 
                "num": 11
              }, 
              {
                "text": "then I said, \"The Philistines will now come down on me at Gilgal, and I have not made supplication to the LORD.' Therefore I felt compelled, and offered a burnt offering.\"", 
                "num": 12
              }, 
              {
                "text": "And Samuel said to Saul, \"You have done foolishly. You have not kept the commandment of the LORD your God, which He commanded you. For now the LORD would have established your kingdom over Israel forever.", 
                "num": 13
              }, 
              {
                "text": "But now your kingdom shall not continue. The LORD has sought for Himself a man after His own heart, and the LORD has commanded him to be commander over His people, because you have not kept what the LORD commanded you.\"", 
                "num": 14
              }, 
              {
                "text": "Then Samuel arose and went up from Gilgal to Gibeah of Benjamin. And Saul numbered the people present with him, about six hundred men.", 
                "num": 15
              }, 
              {
                "text": "Saul, Jonathan his son, and the people present with them remained in Gibeah of Benjamin. But the Philistines encamped in Michmash.", 
                "num": 16
              }, 
              {
                "text": "Then raiders came out of the camp of the Philistines in three companies. One company turned onto the road to Ophrah, to the land of Shual,", 
                "num": 17
              }, 
              {
                "text": "another company turned to the road to Beth Horon, and another company turned to the road of the border that overlooks the Valley of Zeboim toward the wilderness.", 
                "num": 18
              }, 
              {
                "text": "Now there was no blacksmith to be found throughout all the land of Israel, for the Philistines said, \"Lest the Hebrews make swords or spears.\"", 
                "num": 19
              }, 
              {
                "text": "But all the Israelites would go down to the Philistines to sharpen each man's plowshare, his mattock, his ax, and his sickle;", 
                "num": 20
              }, 
              {
                "text": "and the charge for a sharpening was a pim for the plowshares, the mattocks, the forks, and the axes, and to set the points of the goads.", 
                "num": 21
              }, 
              {
                "text": "So it came about, on the day of battle, that there was neither sword nor spear found in the hand of any of the people who were with Saul and Jonathan. But they were found with Saul and Jonathan his son.", 
                "num": 22
              }, 
              {
                "text": "And the garrison of the Philistines went out to the pass of Michmash.", 
                "num": 23
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now it happened one day that Jonathan the son of Saul said to the young man who bore his armor, \"Come, let us go over to the Philistines' garrison that is on the other side.\" But he did not tell his father.", 
                "num": 1
              }, 
              {
                "text": "And Saul was sitting in the outskirts of Gibeah under a pomegranate tree which is in Migron. The people who were with him were about six hundred men.", 
                "num": 2
              }, 
              {
                "text": "Ahijah the son of Ahitub, Ichabod's brother, the son of Phinehas, the son of Eli, the LORD's priest in Shiloh, was wearing an ephod. But the people did not know that Jonathan had gone.", 
                "num": 3
              }, 
              {
                "text": "Between the passes, by which Jonathan sought to go over to the Philistines' garrison, there was a sharp rock on one side and a sharp rock on the other side. And the name of one was Bozez, and the name of the other Seneh.", 
                "num": 4
              }, 
              {
                "text": "The front of one faced northward opposite Michmash, and the other southward opposite Gibeah.", 
                "num": 5
              }, 
              {
                "text": "Then Jonathan said to the young man who bore his armor, \"Come, let us go over to the garrison of these uncircumcised; it may be that the LORD will work for us. For nothing restrains the LORD from saving by many or by few.\"", 
                "num": 6
              }, 
              {
                "text": "So his armorbearer said to him, \"Do all that is in your heart. Go then; here I am with you, according to your heart.\"", 
                "num": 7
              }, 
              {
                "text": "Then Jonathan said, \"Very well, let us cross over to these men, and we will show ourselves to them.", 
                "num": 8
              }, 
              {
                "text": "If they say thus to us, \"Wait until we come to you,' then we will stand still in our place and not go up to them.", 
                "num": 9
              }, 
              {
                "text": "But if they say thus, \"Come up to us,' then we will go up. For the LORD has delivered them into our hand, and this will be a sign to us.\"", 
                "num": 10
              }, 
              {
                "text": "So both of them showed themselves to the garrison of the Philistines. And the Philistines said, \"Look, the Hebrews are coming out of the holes where they have hidden.\"", 
                "num": 11
              }, 
              {
                "text": "Then the men of the garrison called to Jonathan and his armorbearer, and said, \"Come up to us, and we will show you something.\" Jonathan said to his armorbearer, \"Come up after me, for the LORD has delivered them into the hand of Israel.\"", 
                "num": 12
              }, 
              {
                "text": "And Jonathan climbed up on his hands and knees with his armorbearer after him; and they fell before Jonathan. And as he came after him, his armorbearer killed them.", 
                "num": 13
              }, 
              {
                "text": "That first slaughter which Jonathan and his armorbearer made was about twenty men within about half an acre of land.", 
                "num": 14
              }, 
              {
                "text": "And there was trembling in the camp, in the field, and among all the people. The garrison and the raiders also trembled; and the earth quaked, so that it was a very great trembling.", 
                "num": 15
              }, 
              {
                "text": "Now the watchmen of Saul in Gibeah of Benjamin looked, and there was the multitude, melting away; and they went here and there.", 
                "num": 16
              }, 
              {
                "text": "Then Saul said to the people who were with him, \"Now call the roll and see who has gone from us.\" And when they had called the roll, surprisingly, Jonathan and his armorbearer were not there.", 
                "num": 17
              }, 
              {
                "text": "And Saul said to Ahijah, \"Bring the ark of God here\" (for at that time the ark of God was with the children of Israel).", 
                "num": 18
              }, 
              {
                "text": "Now it happened, while Saul talked to the priest, that the noise which was in the camp of the Philistines continued to increase; so Saul said to the priest, \"Withdraw your hand.\"", 
                "num": 19
              }, 
              {
                "text": "Then Saul and all the people who were with him assembled, and they went to the battle; and indeed every man's sword was against his neighbor, and there was very great confusion.", 
                "num": 20
              }, 
              {
                "text": "Moreover the Hebrews who were with the Philistines before that time, who went up with them into the camp from the surrounding country, they also joined the Israelites who were with Saul and Jonathan.", 
                "num": 21
              }, 
              {
                "text": "Likewise all the men of Israel who had hidden in the mountains of Ephraim, when they heard that the Philistines fled, they also followed hard after them in the battle.", 
                "num": 22
              }, 
              {
                "text": "So the LORD saved Israel that day, and the battle shifted to Beth Aven.", 
                "num": 23
              }, 
              {
                "text": "And the men of Israel were distressed that day, for Saul had placed the people under oath, saying, \"Cursed is the man who eats any food until evening, before I have taken vengeance on my enemies.\" So none of the people tasted food.", 
                "num": 24
              }, 
              {
                "text": "Now all the people of the land came to a forest; and there was honey on the ground.", 
                "num": 25
              }, 
              {
                "text": "And when the people had come into the woods, there was the honey, dripping; but no one put his hand to his mouth, for the people feared the oath.", 
                "num": 26
              }, 
              {
                "text": "But Jonathan had not heard his father charge the people with the oath; therefore he stretched out the end of the rod that was in his hand and dipped it in a honeycomb, and put his hand to his mouth; and his countenance brightened.", 
                "num": 27
              }, 
              {
                "text": "Then one of the people said, \"Your father strictly charged the people with an oath, saying, \"Cursed is the man who eats food this day.\"' And the people were faint.", 
                "num": 28
              }, 
              {
                "text": "But Jonathan said, \"My father has troubled the land. Look now, how my countenance has brightened because I tasted a little of this honey.", 
                "num": 29
              }, 
              {
                "text": "How much better if the people had eaten freely today of the spoil of their enemies which they found! For now would there not have been a much greater slaughter among the Philistines?\"", 
                "num": 30
              }, 
              {
                "text": "Now they had driven back the Philistines that day from Michmash to Aijalon. So the people were very faint.", 
                "num": 31
              }, 
              {
                "text": "And the people rushed on the spoil, and took sheep, oxen, and calves, and slaughtered them on the ground; and the people ate them with the blood.", 
                "num": 32
              }, 
              {
                "text": "Then they told Saul, saying, \"Look, the people are sinning against the LORD by eating with the blood!\" So he said, \"You have dealt treacherously; roll a large stone to me this day.\"", 
                "num": 33
              }, 
              {
                "text": "Then Saul said, \"Disperse yourselves among the people, and say to them, \"Bring me here every man's ox and every man's sheep, slaughter them here, and eat; and do not sin against the LORD by eating with the blood.\"' So every one of the people brought his ox with him that night, and slaughtered it there.", 
                "num": 34
              }, 
              {
                "text": "Then Saul built an altar to the LORD. This was the first altar that he built to the LORD.", 
                "num": 35
              }, 
              {
                "text": "Now Saul said, \"Let us go down after the Philistines by night, and plunder them until the morning light; and let us not leave a man of them.\" And they said, \"Do whatever seems good to you.\" Then the priest said, \"Let us draw near to God here.\"", 
                "num": 36
              }, 
              {
                "text": "So Saul asked counsel of God, \"Shall I go down after the Philistines? Will You deliver them into the hand of Israel?\" But He did not answer him that day.", 
                "num": 37
              }, 
              {
                "text": "And Saul said, \"Come over here, all you chiefs of the people, and know and see what this sin was today.", 
                "num": 38
              }, 
              {
                "text": "For as the LORD lives, who saves Israel, though it be in Jonathan my son, he shall surely die.\" But not a man among all the people answered him.", 
                "num": 39
              }, 
              {
                "text": "Then he said to all Israel, \"You be on one side, and my son Jonathan and I will be on the other side.\" And the people said to Saul, \"Do what seems good to you.\"", 
                "num": 40
              }, 
              {
                "text": "Therefore Saul said to the LORD God of Israel, \"Give a perfect lot.\" So Saul and Jonathan were taken, but the people escaped.", 
                "num": 41
              }, 
              {
                "text": "And Saul said, \"Cast lots between my son Jonathan and me.\" So Jonathan was taken.", 
                "num": 42
              }, 
              {
                "text": "Then Saul said to Jonathan, \"Tell me what you have done.\" And Jonathan told him, and said, \"I only tasted a little honey with the end of the rod that was in my hand. So now I must die!\"", 
                "num": 43
              }, 
              {
                "text": "Saul answered, \"God do so and more also; for you shall surely die, Jonathan.\"", 
                "num": 44
              }, 
              {
                "text": "But the people said to Saul, \"Shall Jonathan die, who has accomplished this great deliverance in Israel? Certainly not! As the LORD lives, not one hair of his head shall fall to the ground, for he has worked with God this day.\" So the people rescued Jonathan, and he did not die.", 
                "num": 45
              }, 
              {
                "text": "Then Saul returned from pursuing the Philistines, and the Philistines went to their own place.", 
                "num": 46
              }, 
              {
                "text": "So Saul established his sovereignty over Israel, and fought against all his enemies on every side, against Moab, against the people of Ammon, against Edom, against the kings of Zobah, and against the Philistines. Wherever he turned, he harassed them.", 
                "num": 47
              }, 
              {
                "text": "And he gathered an army and attacked the Amalekites, and delivered Israel from the hands of those who plundered them.", 
                "num": 48
              }, 
              {
                "text": "The sons of Saul were Jonathan, Jishui, and Malchishua. And the names of his two daughters were these: the name of the firstborn Merab, and the name of the younger Michal.", 
                "num": 49
              }, 
              {
                "text": "The name of Saul's wife was Ahinoam the daughter of Ahimaaz. And the name of the commander of his army was Abner the son of Ner, Saul's uncle.", 
                "num": 50
              }, 
              {
                "text": "Kish was the father of Saul, and Ner the father of Abner was the son of Abiel.", 
                "num": 51
              }, 
              {
                "text": "Now there was fierce war with the Philistines all the days of Saul. And when Saul saw any strong man or any valiant man, he took him for himself.", 
                "num": 52
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Samuel also said to Saul, \"The LORD sent me to anoint you king over His people, over Israel. Now therefore, heed the voice of the words of the LORD.", 
                "num": 1
              }, 
              {
                "text": "Thus says the LORD of hosts: \"I will punish Amalek for what he did to Israel, how he ambushed him on the way when he came up from Egypt.", 
                "num": 2
              }, 
              {
                "text": "Now go and attack Amalek, and utterly destroy all that they have, and do not spare them. But kill both man and woman, infant and nursing child, ox and sheep, camel and donkey.\"'", 
                "num": 3
              }, 
              {
                "text": "So Saul gathered the people together and numbered them in Telaim, two hundred thousand foot soldiers and ten thousand men of Judah.", 
                "num": 4
              }, 
              {
                "text": "And Saul came to a city of Amalek, and lay in wait in the valley.", 
                "num": 5
              }, 
              {
                "text": "Then Saul said to the Kenites, \"Go, depart, get down from among the Amalekites, lest I destroy you with them. For you showed kindness to all the children of Israel when they came up out of Egypt.\" So the Kenites departed from among the Amalekites.", 
                "num": 6
              }, 
              {
                "text": "And Saul attacked the Amalekites, from Havilah all the way to Shur, which is east of Egypt.", 
                "num": 7
              }, 
              {
                "text": "He also took Agag king of the Amalekites alive, and utterly destroyed all the people with the edge of the sword.", 
                "num": 8
              }, 
              {
                "text": "But Saul and the people spared Agag and the best of the sheep, the oxen, the fatlings, the lambs, and all that was good, and were unwilling to utterly destroy them. But everything despised and worthless, that they utterly destroyed.", 
                "num": 9
              }, 
              {
                "text": "Now the word of the LORD came to Samuel, saying,", 
                "num": 10
              }, 
              {
                "text": "\"I greatly regret that I have set up Saul as king, for he has turned back from following Me, and has not performed My commandments.\" And it grieved Samuel, and he cried out to the LORD all night.", 
                "num": 11
              }, 
              {
                "text": "So when Samuel rose early in the morning to meet Saul, it was told Samuel, saying, \"Saul went to Carmel, and indeed, he set up a monument for himself; and he has gone on around, passed by, and gone down to Gilgal.\"", 
                "num": 12
              }, 
              {
                "text": "Then Samuel went to Saul, and Saul said to him, \"Blessed are you of the LORD! I have performed the commandment of the LORD.\"", 
                "num": 13
              }, 
              {
                "text": "But Samuel said, \"What then is this bleating of the sheep in my ears, and the lowing of the oxen which I hear?\"", 
                "num": 14
              }, 
              {
                "text": "And Saul said, \"They have brought them from the Amalekites; for the people spared the best of the sheep and the oxen, to sacrifice to the LORD your God; and the rest we have utterly destroyed.\"", 
                "num": 15
              }, 
              {
                "text": "Then Samuel said to Saul, \"Be quiet! And I will tell you what the LORD said to me last night.\" And he said to him, \"Speak on.\"", 
                "num": 16
              }, 
              {
                "text": "So Samuel said, \"When you were little in your own eyes, were you not head of the tribes of Israel? And did not the LORD anoint you king over Israel?", 
                "num": 17
              }, 
              {
                "text": "Now the LORD sent you on a mission, and said, \"Go, and utterly destroy the sinners, the Amalekites, and fight against them until they are consumed.'", 
                "num": 18
              }, 
              {
                "text": "Why then did you not obey the voice of the LORD? Why did you swoop down on the spoil, and do evil in the sight of the LORD?\"", 
                "num": 19
              }, 
              {
                "text": "And Saul said to Samuel, \"But I have obeyed the voice of the LORD, and gone on the mission on which the LORD sent me, and brought back Agag king of Amalek; I have utterly destroyed the Amalekites.", 
                "num": 20
              }, 
              {
                "text": "But the people took of the plunder, sheep and oxen, the best of the things which should have been utterly destroyed, to sacrifice to the LORD your God in Gilgal.\"", 
                "num": 21
              }, 
              {
                "text": "So Samuel said: \"Has the LORD as great delight in burnt offerings and sacrifices, As in obeying the voice of the LORD? Behold, to obey is better than sacrifice, And to heed than the fat of rams.", 
                "num": 22
              }, 
              {
                "text": "For rebellion is as the sin of witchcraft, And stubbornness is as iniquity and idolatry. Because you have rejected the word of the LORD, He also has rejected you from being king.\"", 
                "num": 23
              }, 
              {
                "text": "Then Saul said to Samuel, \"I have sinned, for I have transgressed the commandment of the LORD and your words, because I feared the people and obeyed their voice.", 
                "num": 24
              }, 
              {
                "text": "Now therefore, please pardon my sin, and return with me, that I may worship the LORD.\"", 
                "num": 25
              }, 
              {
                "text": "But Samuel said to Saul, \"I will not return with you, for you have rejected the word of the LORD, and the LORD has rejected you from being king over Israel.\"", 
                "num": 26
              }, 
              {
                "text": "And as Samuel turned around to go away, Saul seized the edge of his robe, and it tore.", 
                "num": 27
              }, 
              {
                "text": "So Samuel said to him, \"The LORD has torn the kingdom of Israel from you today, and has given it to a neighbor of yours, who is better than you.", 
                "num": 28
              }, 
              {
                "text": "And also the Strength of Israel will not lie nor relent. For He is not a man, that He should relent.\"", 
                "num": 29
              }, 
              {
                "text": "Then he said, \"I have sinned; yet honor me now, please, before the elders of my people and before Israel, and return with me, that I may worship the LORD your God.\"", 
                "num": 30
              }, 
              {
                "text": "So Samuel turned back after Saul, and Saul worshiped the LORD.", 
                "num": 31
              }, 
              {
                "text": "Then Samuel said, \"Bring Agag king of the Amalekites here to me.\" So Agag came to him cautiously. And Agag said, \"Surely the bitterness of death is past.\"", 
                "num": 32
              }, 
              {
                "text": "But Samuel said, \"As your sword has made women childless, so shall your mother be childless among women.\" And Samuel hacked Agag in pieces before the LORD in Gilgal.", 
                "num": 33
              }, 
              {
                "text": "Then Samuel went to Ramah, and Saul went up to his house at Gibeah of Saul.", 
                "num": 34
              }, 
              {
                "text": "And Samuel went no more to see Saul until the day of his death. Nevertheless Samuel mourned for Saul, and the LORD regretted that He had made Saul king over Israel.", 
                "num": 35
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now the LORD said to Samuel, \"How long will you mourn for Saul, seeing I have rejected him from reigning over Israel? Fill your horn with oil, and go; I am sending you to Jesse the Bethlehemite. For I have provided Myself a king among his sons.\"", 
                "num": 1
              }, 
              {
                "text": "And Samuel said, \"How can I go? If Saul hears it, he will kill me.\" But the LORD said, \"Take a heifer with you, and say, \"I have come to sacrifice to the LORD.'", 
                "num": 2
              }, 
              {
                "text": "Then invite Jesse to the sacrifice, and I will show you what you shall do; you shall anoint for Me the one I name to you.\"", 
                "num": 3
              }, 
              {
                "text": "So Samuel did what the LORD said, and went to Bethlehem. And the elders of the town trembled at his coming, and said, \"Do you come peaceably?\"", 
                "num": 4
              }, 
              {
                "text": "And he said, \"Peaceably; I have come to sacrifice to the LORD. Sanctify yourselves, and come with me to the sacrifice.\" Then he consecrated Jesse and his sons, and invited them to the sacrifice.", 
                "num": 5
              }, 
              {
                "text": "So it was, when they came, that he looked at Eliab and said, \"Surely the LORD's anointed is before Him!\"", 
                "num": 6
              }, 
              {
                "text": "But the LORD said to Samuel, \"Do not look at his appearance or at his physical stature, because I have refused him. For the LORD does not see as man sees; for man looks at the outward appearance, but the LORD looks at the heart.\"", 
                "num": 7
              }, 
              {
                "text": "So Jesse called Abinadab, and made him pass before Samuel. And he said, \"Neither has the LORD chosen this one.\"", 
                "num": 8
              }, 
              {
                "text": "Then Jesse made Shammah pass by. And he said, \"Neither has the LORD chosen this one.\"", 
                "num": 9
              }, 
              {
                "text": "Thus Jesse made seven of his sons pass before Samuel. And Samuel said to Jesse, \"The LORD has not chosen these.\"", 
                "num": 10
              }, 
              {
                "text": "And Samuel said to Jesse, \"Are all the young men here?\" Then he said, \"There remains yet the youngest, and there he is, keeping the sheep.\" And Samuel said to Jesse, \"Send and bring him. For we will not sit down till he comes here.\"", 
                "num": 11
              }, 
              {
                "text": "So he sent and brought him in. Now he was ruddy, with bright eyes, and good-looking. And the LORD said, \"Arise, anoint him; for this is the one!\"", 
                "num": 12
              }, 
              {
                "text": "Then Samuel took the horn of oil and anointed him in the midst of his brothers; and the Spirit of the LORD came upon David from that day forward. So Samuel arose and went to Ramah.", 
                "num": 13
              }, 
              {
                "text": "But the Spirit of the LORD departed from Saul, and a distressing spirit from the LORD troubled him.", 
                "num": 14
              }, 
              {
                "text": "And Saul's servants said to him, \"Surely, a distressing spirit from God is troubling you.", 
                "num": 15
              }, 
              {
                "text": "Let our master now command your servants, who are before you, to seek out a man who is a skillful player on the harp. And it shall be that he will play it with his hand when the distressing spirit from God is upon you, and you shall be well.\"", 
                "num": 16
              }, 
              {
                "text": "So Saul said to his servants, \"Provide me now a man who can play well, and bring him to me.\"", 
                "num": 17
              }, 
              {
                "text": "Then one of the servants answered and said, \"Look, I have seen a son of Jesse the Bethlehemite, who is skillful in playing, a mighty man of valor, a man of war, prudent in speech, and a handsome person; and the LORD is with him.\"", 
                "num": 18
              }, 
              {
                "text": "Therefore Saul sent messengers to Jesse, and said, \"Send me your son David, who is with the sheep.\"", 
                "num": 19
              }, 
              {
                "text": "And Jesse took a donkey loaded with bread, a skin of wine, and a young goat, and sent them by his son David to Saul.", 
                "num": 20
              }, 
              {
                "text": "So David came to Saul and stood before him. And he loved him greatly, and he became his armorbearer.", 
                "num": 21
              }, 
              {
                "text": "Then Saul sent to Jesse, saying, \"Please let David stand before me, for he has found favor in my sight.\"", 
                "num": 22
              }, 
              {
                "text": "And so it was, whenever the spirit from God was upon Saul, that David would take a harp and play it with his hand. Then Saul would become refreshed and well, and the distressing spirit would depart from him.", 
                "num": 23
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Now the Philistines gathered their armies together to battle, and were gathered at Sochoh, which belongs to Judah; they encamped between Sochoh and Azekah, in Ephes Dammim.", 
                "num": 1
              }, 
              {
                "text": "And Saul and the men of Israel were gathered together, and they encamped in the Valley of Elah, and drew up in battle array against the Philistines.", 
                "num": 2
              }, 
              {
                "text": "The Philistines stood on a mountain on one side, and Israel stood on a mountain on the other side, with a valley between them.", 
                "num": 3
              }, 
              {
                "text": "And a champion went out from the camp of the Philistines, named Goliath, from Gath, whose height was six cubits and a span.", 
                "num": 4
              }, 
              {
                "text": "He had a bronze helmet on his head, and he was armed with a coat of mail, and the weight of the coat was five thousand shekels of bronze.", 
                "num": 5
              }, 
              {
                "text": "And he had bronze armor on his legs and a bronze javelin between his shoulders.", 
                "num": 6
              }, 
              {
                "text": "Now the staff of his spear was like a weaver's beam, and his iron spearhead weighed six hundred shekels; and a shield-bearer went before him.", 
                "num": 7
              }, 
              {
                "text": "Then he stood and cried out to the armies of Israel, and said to them, \"Why have you come out to line up for battle? Am I not a Philistine, and you the servants of Saul? Choose a man for yourselves, and let him come down to me.", 
                "num": 8
              }, 
              {
                "text": "If he is able to fight with me and kill me, then we will be your servants. But if I prevail against him and kill him, then you shall be our servants and serve us.\"", 
                "num": 9
              }, 
              {
                "text": "And the Philistine said, \"I defy the armies of Israel this day; give me a man, that we may fight together.\"", 
                "num": 10
              }, 
              {
                "text": "When Saul and all Israel heard these words of the Philistine, they were dismayed and greatly afraid.", 
                "num": 11
              }, 
              {
                "text": "Now David was the son of that Ephrathite of Bethlehem Judah, whose name was Jesse, and who had eight sons. And the man was old, advanced in years, in the days of Saul.", 
                "num": 12
              }, 
              {
                "text": "The three oldest sons of Jesse had gone to follow Saul to the battle. The names of his three sons who went to the battle were Eliab the firstborn, next to him Abinadab, and the third Shammah.", 
                "num": 13
              }, 
              {
                "text": "David was the youngest. And the three oldest followed Saul.", 
                "num": 14
              }, 
              {
                "text": "But David occasionally went and returned from Saul to feed his father's sheep at Bethlehem.", 
                "num": 15
              }, 
              {
                "text": "And the Philistine drew near and presented himself forty days, morning and evening.", 
                "num": 16
              }, 
              {
                "text": "Then Jesse said to his son David, \"Take now for your brothers an ephah of this dried grain and these ten loaves, and run to your brothers at the camp.", 
                "num": 17
              }, 
              {
                "text": "And carry these ten cheeses to the captain of their thousand, and see how your brothers fare, and bring back news of them.\"", 
                "num": 18
              }, 
              {
                "text": "Now Saul and they and all the men of Israel were in the Valley of Elah, fighting with the Philistines.", 
                "num": 19
              }, 
              {
                "text": "So David rose early in the morning, left the sheep with a keeper, and took the things and went as Jesse had commanded him. And he came to the camp as the army was going out to the fight and shouting for the battle.", 
                "num": 20
              }, 
              {
                "text": "For Israel and the Philistines had drawn up in battle array, army against army.", 
                "num": 21
              }, 
              {
                "text": "And David left his supplies in the hand of the supply keeper, ran to the army, and came and greeted his brothers.", 
                "num": 22
              }, 
              {
                "text": "Then as he talked with them, there was the champion, the Philistine of Gath, Goliath by name, coming up from the armies of the Philistines; and he spoke according to the same words. So David heard them.", 
                "num": 23
              }, 
              {
                "text": "And all the men of Israel, when they saw the man, fled from him and were dreadfully afraid.", 
                "num": 24
              }, 
              {
                "text": "So the men of Israel said, \"Have you seen this man who has come up? Surely he has come up to defy Israel; and it shall be that the man who kills him the king will enrich with great riches, will give him his daughter, and give his father's house exemption from taxes in Israel.\"", 
                "num": 25
              }, 
              {
                "text": "Then David spoke to the men who stood by him, saying, \"What shall be done for the man who kills this Philistine and takes away the reproach from Israel? For who is this uncircumcised Philistine, that he should defy the armies of the living God?\"", 
                "num": 26
              }, 
              {
                "text": "And the people answered him in this manner, saying, \"So shall it be done for the man who kills him.\"", 
                "num": 27
              }, 
              {
                "text": "Now Eliab his oldest brother heard when he spoke to the men; and Eliab's anger was aroused against David, and he said, \"Why did you come down here? And with whom have you left those few sheep in the wilderness? I know your pride and the insolence of your heart, for you have come down to see the battle.\"", 
                "num": 28
              }, 
              {
                "text": "And David said, \"What have I done now? Is there not a cause?\"", 
                "num": 29
              }, 
              {
                "text": "Then he turned from him toward another and said the same thing; and these people answered him as the first ones did.", 
                "num": 30
              }, 
              {
                "text": "Now when the words which David spoke were heard, they reported them to Saul; and he sent for him.", 
                "num": 31
              }, 
              {
                "text": "Then David said to Saul, \"Let no man's heart fail because of him; your servant will go and fight with this Philistine.\"", 
                "num": 32
              }, 
              {
                "text": "And Saul said to David, \"You are not able to go against this Philistine to fight with him; for you are a youth, and he a man of war from his youth.\"", 
                "num": 33
              }, 
              {
                "text": "But David said to Saul, \"Your servant used to keep his father's sheep, and when a lion or a bear came and took a lamb out of the flock,", 
                "num": 34
              }, 
              {
                "text": "I went out after it and struck it, and delivered the lamb from its mouth; and when it arose against me, I caught it by its beard, and struck and killed it.", 
                "num": 35
              }, 
              {
                "text": "Your servant has killed both lion and bear; and this uncircumcised Philistine will be like one of them, seeing he has defied the armies of the living God.\"", 
                "num": 36
              }, 
              {
                "text": "Moreover David said, \"The LORD, who delivered me from the paw of the lion and from the paw of the bear, He will deliver me from the hand of this Philistine.\" And Saul said to David, \"Go, and the LORD be with you!\"", 
                "num": 37
              }, 
              {
                "text": "So Saul clothed David with his armor, and he put a bronze helmet on his head; he also clothed him with a coat of mail.", 
                "num": 38
              }, 
              {
                "text": "David fastened his sword to his armor and tried to walk, for he had not tested them. And David said to Saul, \"I cannot walk with these, for I have not tested them.\" So David took them off.", 
                "num": 39
              }, 
              {
                "text": "Then he took his staff in his hand; and he chose for himself five smooth stones from the brook, and put them in a shepherd's bag, in a pouch which he had, and his sling was in his hand. And he drew near to the Philistine.", 
                "num": 40
              }, 
              {
                "text": "So the Philistine came, and began drawing near to David, and the man who bore the shield went before him.", 
                "num": 41
              }, 
              {
                "text": "And when the Philistine looked about and saw David, he disdained him; for he was only a youth, ruddy and good-looking.", 
                "num": 42
              }, 
              {
                "text": "So the Philistine said to David, \"Am I a dog, that you come to me with sticks?\" And the Philistine cursed David by his gods.", 
                "num": 43
              }, 
              {
                "text": "And the Philistine said to David, \"Come to me, and I will give your flesh to the birds of the air and the beasts of the field!\"", 
                "num": 44
              }, 
              {
                "text": "Then David said to the Philistine, \"You come to me with a sword, with a spear, and with a javelin. But I come to you in the name of the LORD of hosts, the God of the armies of Israel, whom you have defied.", 
                "num": 45
              }, 
              {
                "text": "This day the LORD will deliver you into my hand, and I will strike you and take your head from you. And this day I will give the carcasses of the camp of the Philistines to the birds of the air and the wild beasts of the earth, that all the earth may know that there is a God in Israel.", 
                "num": 46
              }, 
              {
                "text": "Then all this assembly shall know that the LORD does not save with sword and spear; for the battle is the LORD's, and He will give you into our hands.\"", 
                "num": 47
              }, 
              {
                "text": "So it was, when the Philistine arose and came and drew near to meet David, that David hurried and ran toward the army to meet the Philistine.", 
                "num": 48
              }, 
              {
                "text": "Then David put his hand in his bag and took out a stone; and he slung it and struck the Philistine in his forehead, so that the stone sank into his forehead, and he fell on his face to the earth.", 
                "num": 49
              }, 
              {
                "text": "So David prevailed over the Philistine with a sling and a stone, and struck the Philistine and killed him. But there was no sword in the hand of David.", 
                "num": 50
              }, 
              {
                "text": "Therefore David ran and stood over the Philistine, took his sword and drew it out of its sheath and killed him, and cut off his head with it. And when the Philistines saw that their champion was dead, they fled.", 
                "num": 51
              }, 
              {
                "text": "Now the men of Israel and Judah arose and shouted, and pursued the Philistines as far as the entrance of the valley and to the gates of Ekron. And the wounded of the Philistines fell along the road to Shaaraim, even as far as Gath and Ekron.", 
                "num": 52
              }, 
              {
                "text": "Then the children of Israel returned from chasing the Philistines, and they plundered their tents.", 
                "num": 53
              }, 
              {
                "text": "And David took the head of the Philistine and brought it to Jerusalem, but he put his armor in his tent.", 
                "num": 54
              }, 
              {
                "text": "When Saul saw David going out against the Philistine, he said to Abner, the commander of the army, \"Abner, whose son is this youth?\" And Abner said, \"As your soul lives, O king, I do not know.\"", 
                "num": 55
              }, 
              {
                "text": "So the king said, \"Inquire whose son this young man is.\"", 
                "num": 56
              }, 
              {
                "text": "Then, as David returned from the slaughter of the Philistine, Abner took him and brought him before Saul with the head of the Philistine in his hand.", 
                "num": 57
              }, 
              {
                "text": "And Saul said to him, \"Whose son are you, young man?\" So David answered, \"I am the son of your servant Jesse the Bethlehemite.\"", 
                "num": 58
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Now when he had finished speaking to Saul, the soul of Jonathan was knit to the soul of David, and Jonathan loved him as his own soul.", 
                "num": 1
              }, 
              {
                "text": "Saul took him that day, and would not let him go home to his father's house anymore.", 
                "num": 2
              }, 
              {
                "text": "Then Jonathan and David made a covenant, because he loved him as his own soul.", 
                "num": 3
              }, 
              {
                "text": "And Jonathan took off the robe that was on him and gave it to David, with his armor, even to his sword and his bow and his belt.", 
                "num": 4
              }, 
              {
                "text": "So David went out wherever Saul sent him, and behaved wisely. And Saul set him over the men of war, and he was accepted in the sight of all the people and also in the sight of Saul's servants.", 
                "num": 5
              }, 
              {
                "text": "Now it had happened as they were coming home, when David was returning from the slaughter of the Philistine, that the women had come out of all the cities of Israel, singing and dancing, to meet King Saul, with tambourines, with joy, and with musical instruments.", 
                "num": 6
              }, 
              {
                "text": "So the women sang as they danced, and said: \"Saul has slain his thousands, And David his ten thousands.\"", 
                "num": 7
              }, 
              {
                "text": "Then Saul was very angry, and the saying displeased him; and he said, \"They have ascribed to David ten thousands, and to me they have ascribed only thousands. Now what more can he have but the kingdom?\"", 
                "num": 8
              }, 
              {
                "text": "So Saul eyed David from that day forward.", 
                "num": 9
              }, 
              {
                "text": "And it happened on the next day that the distressing spirit from God came upon Saul, and he prophesied inside the house. So David played music with his hand, as at other times; but there was a spear in Saul's hand.", 
                "num": 10
              }, 
              {
                "text": "And Saul cast the spear, for he said, \"I will pin David to the wall!\" But David escaped his presence twice.", 
                "num": 11
              }, 
              {
                "text": "Now Saul was afraid of David, because the LORD was with him, but had departed from Saul.", 
                "num": 12
              }, 
              {
                "text": "Therefore Saul removed him from his presence, and made him his captain over a thousand; and he went out and came in before the people.", 
                "num": 13
              }, 
              {
                "text": "And David behaved wisely in all his ways, and the LORD was with him.", 
                "num": 14
              }, 
              {
                "text": "Therefore, when Saul saw that he behaved very wisely, he was afraid of him.", 
                "num": 15
              }, 
              {
                "text": "But all Israel and Judah loved David, because he went out and came in before them.", 
                "num": 16
              }, 
              {
                "text": "Then Saul said to David, \"Here is my older daughter Merab; I will give her to you as a wife. Only be valiant for me, and fight the LORD's battles.\" For Saul thought, \"Let my hand not be against him, but let the hand of the Philistines be against him.\"", 
                "num": 17
              }, 
              {
                "text": "So David said to Saul, \"Who am I, and what is my life or my father's family in Israel, that I should be son-in-law to the king?\"", 
                "num": 18
              }, 
              {
                "text": "But it happened at the time when Merab, Saul's daughter, should have been given to David, that she was given to Adriel the Meholathite as a wife.", 
                "num": 19
              }, 
              {
                "text": "Now Michal, Saul's daughter, loved David. And they told Saul, and the thing pleased him.", 
                "num": 20
              }, 
              {
                "text": "So Saul said, \"I will give her to him, that she may be a snare to him, and that the hand of the Philistines may be against him.\" Therefore Saul said to David a second time, \"You shall be my son-in-law today.\"", 
                "num": 21
              }, 
              {
                "text": "And Saul commanded his servants, \"Communicate with David secretly, and say, \"Look, the king has delight in you, and all his servants love you. Now therefore, become the king's son-in-law.\"'", 
                "num": 22
              }, 
              {
                "text": "So Saul's servants spoke those words in the hearing of David. And David said, \"Does it seem to you a light thing to be a king's son-in-law, seeing I am a poor and lightly esteemed man?\"", 
                "num": 23
              }, 
              {
                "text": "And the servants of Saul told him, saying, \"In this manner David spoke.\"", 
                "num": 24
              }, 
              {
                "text": "Then Saul said, \"Thus you shall say to David: \"The king does not desire any dowry but one hundred foreskins of the Philistines, to take vengeance on the king's enemies.\"' But Saul thought to make David fall by the hand of the Philistines.", 
                "num": 25
              }, 
              {
                "text": "So when his servants told David these words, it pleased David well to become the king's son-in-law. Now the days had not expired;", 
                "num": 26
              }, 
              {
                "text": "therefore David arose and went, he and his men, and killed two hundred men of the Philistines. And David brought their foreskins, and they gave them in full count to the king, that he might become the king's son-in-law. Then Saul gave him Michal his daughter as a wife.", 
                "num": 27
              }, 
              {
                "text": "Thus Saul saw and knew that the LORD was with David, and that Michal, Saul's daughter, loved him;", 
                "num": 28
              }, 
              {
                "text": "and Saul was still more afraid of David. So Saul became David's enemy continually.", 
                "num": 29
              }, 
              {
                "text": "Then the princes of the Philistines went out to war. And so it was, whenever they went out, that David behaved more wisely than all the servants of Saul, so that his name became highly esteemed.", 
                "num": 30
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Now Saul spoke to Jonathan his son and to all his servants, that they should kill David; but Jonathan, Saul's son, delighted greatly in David.", 
                "num": 1
              }, 
              {
                "text": "So Jonathan told David, saying, \"My father Saul seeks to kill you. Therefore please be on your guard until morning, and stay in a secret place and hide.", 
                "num": 2
              }, 
              {
                "text": "And I will go out and stand beside my father in the field where you are, and I will speak with my father about you. Then what I observe, I will tell you.\"", 
                "num": 3
              }, 
              {
                "text": "Thus Jonathan spoke well of David to Saul his father, and said to him, \"Let not the king sin against his servant, against David, because he has not sinned against you, and because his works have been very good toward you.", 
                "num": 4
              }, 
              {
                "text": "For he took his life in his hands and killed the Philistine, and the LORD brought about a great deliverance for all Israel. You saw it and rejoiced. Why then will you sin against innocent blood, to kill David without a cause?\"", 
                "num": 5
              }, 
              {
                "text": "So Saul heeded the voice of Jonathan, and Saul swore, \"As the LORD lives, he shall not be killed.\"", 
                "num": 6
              }, 
              {
                "text": "Then Jonathan called David, and Jonathan told him all these things. So Jonathan brought David to Saul, and he was in his presence as in times past.", 
                "num": 7
              }, 
              {
                "text": "And there was war again; and David went out and fought with the Philistines, and struck them with a mighty blow, and they fled from him.", 
                "num": 8
              }, 
              {
                "text": "Now the distressing spirit from the LORD came upon Saul as he sat in his house with his spear in his hand. And David was playing music with his hand.", 
                "num": 9
              }, 
              {
                "text": "Then Saul sought to pin David to the wall with the spear, but he slipped away from Saul's presence; and he drove the spear into the wall. So David fled and escaped that night.", 
                "num": 10
              }, 
              {
                "text": "Saul also sent messengers to David's house to watch him and to kill him in the morning. And Michal, David's wife, told him, saying, \"If you do not save your life tonight, tomorrow you will be killed.\"", 
                "num": 11
              }, 
              {
                "text": "So Michal let David down through a window. And he went and fled and escaped.", 
                "num": 12
              }, 
              {
                "text": "And Michal took an image and laid it in the bed, put a cover of goats' hair for his head, and covered it with clothes.", 
                "num": 13
              }, 
              {
                "text": "So when Saul sent messengers to take David, she said, \"He is sick.\"", 
                "num": 14
              }, 
              {
                "text": "Then Saul sent the messengers back to see David, saying, \"Bring him up to me in the bed, that I may kill him.\"", 
                "num": 15
              }, 
              {
                "text": "And when the messengers had come in, there was the image in the bed, with a cover of goats' hair for his head.", 
                "num": 16
              }, 
              {
                "text": "Then Saul said to Michal, \"Why have you deceived me like this, and sent my enemy away, so that he has escaped?\" And Michal answered Saul, \"He said to me, \"Let me go! Why should I kill you?\"'", 
                "num": 17
              }, 
              {
                "text": "So David fled and escaped, and went to Samuel at Ramah, and told him all that Saul had done to him. And he and Samuel went and stayed in Naioth.", 
                "num": 18
              }, 
              {
                "text": "Now it was told Saul, saying, \"Take note, David is at Naioth in Ramah!\"", 
                "num": 19
              }, 
              {
                "text": "Then Saul sent messengers to take David. And when they saw the group of prophets prophesying, and Samuel standing as leader over them, the Spirit of God came upon the messengers of Saul, and they also prophesied.", 
                "num": 20
              }, 
              {
                "text": "And when Saul was told, he sent other messengers, and they prophesied likewise. Then Saul sent messengers again the third time, and they prophesied also.", 
                "num": 21
              }, 
              {
                "text": "Then he also went to Ramah, and came to the great well that is at Sechu. So he asked, and said, \"Where are Samuel and David?\" And someone said, \"Indeed they are at Naioth in Ramah.\"", 
                "num": 22
              }, 
              {
                "text": "So he went there to Naioth in Ramah. Then the Spirit of God was upon him also, and he went on and prophesied until he came to Naioth in Ramah.", 
                "num": 23
              }, 
              {
                "text": "And he also stripped off his clothes and prophesied before Samuel in like manner, and lay down naked all that day and all that night. Therefore they say, \"Is Saul also among the prophets?\"", 
                "num": 24
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Then David fled from Naioth in Ramah, and went and said to Jonathan, \"What have I done? What is my iniquity, and what is my sin before your father, that he seeks my life?\"", 
                "num": 1
              }, 
              {
                "text": "So Jonathan said to him, \"By no means! You shall not die! Indeed, my father will do nothing either great or small without first telling me. And why should my father hide this thing from me? It is not so!\"", 
                "num": 2
              }, 
              {
                "text": "Then David took an oath again, and said, \"Your father certainly knows that I have found favor in your eyes, and he has said, \"Do not let Jonathan know this, lest he be grieved.' But truly, as the LORD lives and as your soul lives, there is but a step between me and death.\"", 
                "num": 3
              }, 
              {
                "text": "So Jonathan said to David, \"Whatever you yourself desire, I will do it for you.\"", 
                "num": 4
              }, 
              {
                "text": "And David said to Jonathan, \"Indeed tomorrow is the New Moon, and I should not fail to sit with the king to eat. But let me go, that I may hide in the field until the third day at evening.", 
                "num": 5
              }, 
              {
                "text": "If your father misses me at all, then say, \"David earnestly asked permission of me that he might run over to Bethlehem, his city, for there is a yearly sacrifice there for all the family.'", 
                "num": 6
              }, 
              {
                "text": "If he says thus: \"It is well,' your servant will be safe. But if he is very angry, be sure that evil is determined by him.", 
                "num": 7
              }, 
              {
                "text": "Therefore you shall deal kindly with your servant, for you have brought your servant into a covenant of the LORD with you. Nevertheless, if there is iniquity in me, kill me yourself, for why should you bring me to your father?\"", 
                "num": 8
              }, 
              {
                "text": "But Jonathan said, \"Far be it from you! For if I knew certainly that evil was determined by my father to come upon you, then would I not tell you?\"", 
                "num": 9
              }, 
              {
                "text": "Then David said to Jonathan, \"Who will tell me, or what if your father answers you roughly?\"", 
                "num": 10
              }, 
              {
                "text": "And Jonathan said to David, \"Come, let us go out into the field.\" So both of them went out into the field.", 
                "num": 11
              }, 
              {
                "text": "Then Jonathan said to David: \"The LORD God of Israel is witness! When I have sounded out my father sometime tomorrow, or the third day, and indeed there is good toward David, and I do not send to you and tell you,", 
                "num": 12
              }, 
              {
                "text": "may the LORD do so and much more to Jonathan. But if it pleases my father to do you evil, then I will report it to you and send you away, that you may go in safety. And the LORD be with you as He has been with my father.", 
                "num": 13
              }, 
              {
                "text": "And you shall not only show me the kindness of the LORD while I still live, that I may not die;", 
                "num": 14
              }, 
              {
                "text": "but you shall not cut off your kindness from my house forever, no, not when the LORD has cut off every one of the enemies of David from the face of the earth.\"", 
                "num": 15
              }, 
              {
                "text": "So Jonathan made a covenant with the house of David, saying, \"Let the LORD require it at the hand of David's enemies.\"", 
                "num": 16
              }, 
              {
                "text": "Now Jonathan again caused David to vow, because he loved him; for he loved him as he loved his own soul.", 
                "num": 17
              }, 
              {
                "text": "Then Jonathan said to David, \"Tomorrow is the New Moon; and you will be missed, because your seat will be empty.", 
                "num": 18
              }, 
              {
                "text": "And when you have stayed three days, go down quickly and come to the place where you hid on the day of the deed; and remain by the stone Ezel.", 
                "num": 19
              }, 
              {
                "text": "Then I will shoot three arrows to the side, as though I shot at a target;", 
                "num": 20
              }, 
              {
                "text": "and there I will send a lad, saying, \"Go, find the arrows.' If I expressly say to the lad, \"Look, the arrows are on this side of you; get them and come'--then, as the LORD lives, there is safety for you and no harm.", 
                "num": 21
              }, 
              {
                "text": "But if I say thus to the young man, \"Look, the arrows are beyond you'--go your way, for the LORD has sent you away.", 
                "num": 22
              }, 
              {
                "text": "And as for the matter which you and I have spoken of, indeed the LORD be between you and me forever.\"", 
                "num": 23
              }, 
              {
                "text": "Then David hid in the field. And when the New Moon had come, the king sat down to eat the feast.", 
                "num": 24
              }, 
              {
                "text": "Now the king sat on his seat, as at other times, on a seat by the wall. And Jonathan arose, and Abner sat by Saul's side, but David's place was empty.", 
                "num": 25
              }, 
              {
                "text": "Nevertheless Saul did not say anything that day, for he thought, \"Something has happened to him; he is unclean, surely he is unclean.\"", 
                "num": 26
              }, 
              {
                "text": "And it happened the next day, the second day of the month, that David's place was empty. And Saul said to Jonathan his son, \"Why has the son of Jesse not come to eat, either yesterday or today?\"", 
                "num": 27
              }, 
              {
                "text": "So Jonathan answered Saul, \"David earnestly asked permission of me to go to Bethlehem.", 
                "num": 28
              }, 
              {
                "text": "And he said, \"Please let me go, for our family has a sacrifice in the city, and my brother has commanded me to be there. And now, if I have found favor in your eyes, please let me get away and see my brothers.' Therefore he has not come to the king's table.\"", 
                "num": 29
              }, 
              {
                "text": "Then Saul's anger was aroused against Jonathan, and he said to him, \"You son of a perverse, rebellious woman! Do I not know that you have chosen the son of Jesse to your own shame and to the shame of your mother's nakedness?", 
                "num": 30
              }, 
              {
                "text": "For as long as the son of Jesse lives on the earth, you shall not be established, nor your kingdom. Now therefore, send and bring him to me, for he shall surely die.\"", 
                "num": 31
              }, 
              {
                "text": "And Jonathan answered Saul his father, and said to him, \"Why should he be killed? What has he done?\"", 
                "num": 32
              }, 
              {
                "text": "Then Saul cast a spear at him to kill him, by which Jonathan knew that it was determined by his father to kill David.", 
                "num": 33
              }, 
              {
                "text": "So Jonathan arose from the table in fierce anger, and ate no food the second day of the month, for he was grieved for David, because his father had treated him shamefully.", 
                "num": 34
              }, 
              {
                "text": "And so it was, in the morning, that Jonathan went out into the field at the time appointed with David, and a little lad was with him.", 
                "num": 35
              }, 
              {
                "text": "Then he said to his lad, \"Now run, find the arrows which I shoot.\" As the lad ran, he shot an arrow beyond him.", 
                "num": 36
              }, 
              {
                "text": "When the lad had come to the place where the arrow was which Jonathan had shot, Jonathan cried out after the lad and said, \"Is not the arrow beyond you?\"", 
                "num": 37
              }, 
              {
                "text": "And Jonathan cried out after the lad, \"Make haste, hurry, do not delay!\" So Jonathan's lad gathered up the arrows and came back to his master.", 
                "num": 38
              }, 
              {
                "text": "But the lad did not know anything. Only Jonathan and David knew of the matter.", 
                "num": 39
              }, 
              {
                "text": "Then Jonathan gave his weapons to his lad, and said to him, \"Go, carry them to the city.\"", 
                "num": 40
              }, 
              {
                "text": "As soon as the lad had gone, David arose from a place toward the south, fell on his face to the ground, and bowed down three times. And they kissed one another; and they wept together, but David more so.", 
                "num": 41
              }, 
              {
                "text": "Then Jonathan said to David, \"Go in peace, since we have both sworn in the name of the LORD, saying, \"May the LORD be between you and me, and between your descendants and my descendants, forever.\"' So he arose and departed, and Jonathan went into the city.", 
                "num": 42
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now David came to Nob, to Ahimelech the priest. And Ahimelech was afraid when he met David, and said to him, \"Why are you alone, and no one is with you?\"", 
                "num": 1
              }, 
              {
                "text": "So David said to Ahimelech the priest, \"The king has ordered me on some business, and said to me, \"Do not let anyone know anything about the business on which I send you, or what I have commanded you.' And I have directed my young men to such and such a place.", 
                "num": 2
              }, 
              {
                "text": "Now therefore, what have you on hand? Give me five loaves of bread in my hand, or whatever can be found.\"", 
                "num": 3
              }, 
              {
                "text": "And the priest answered David and said, \"There is no common bread on hand; but there is holy bread, if the young men have at least kept themselves from women.\"", 
                "num": 4
              }, 
              {
                "text": "Then David answered the priest, and said to him, \"Truly, women have been kept from us about three days since I came out. And the vessels of the young men are holy, and the bread is in effect common, even though it was consecrated in the vessel this day.\"", 
                "num": 5
              }, 
              {
                "text": "So the priest gave him holy bread; for there was no bread there but the showbread which had been taken from before the LORD, in order to put hot bread in its place on the day when it was taken away.", 
                "num": 6
              }, 
              {
                "text": "Now a certain man of the servants of Saul was there that day, detained before the LORD. And his name was Doeg, an Edomite, the chief of the herdsmen who belonged to Saul.", 
                "num": 7
              }, 
              {
                "text": "And David said to Ahimelech, \"Is there not here on hand a spear or a sword? For I have brought neither my sword nor my weapons with me, because the king's business required haste.\"", 
                "num": 8
              }, 
              {
                "text": "So the priest said, \"The sword of Goliath the Philistine, whom you killed in the Valley of Elah, there it is, wrapped in a cloth behind the ephod. If you will take that, take it. For there is no other except that one here.\" And David said, \"There is none like it; give it to me.\"", 
                "num": 9
              }, 
              {
                "text": "Then David arose and fled that day from before Saul, and went to Achish the king of Gath.", 
                "num": 10
              }, 
              {
                "text": "And the servants of Achish said to him, \"Is this not David the king of the land? Did they not sing of him to one another in dances, saying: \"Saul has slain his thousands, And David his ten thousands'?\"", 
                "num": 11
              }, 
              {
                "text": "Now David took these words to heart, and was very much afraid of Achish the king of Gath.", 
                "num": 12
              }, 
              {
                "text": "So he changed his behavior before them, pretended madness in their hands, scratched on the doors of the gate, and let his saliva fall down on his beard.", 
                "num": 13
              }, 
              {
                "text": "Then Achish said to his servants, \"Look, you see the man is insane. Why have you brought him to me?", 
                "num": 14
              }, 
              {
                "text": "Have I need of madmen, that you have brought this fellow to play the madman in my presence? Shall this fellow come into my house?\"", 
                "num": 15
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "David therefore departed from there and escaped to the cave of Adullam. So when his brothers and all his father's house heard it, they went down there to him.", 
                "num": 1
              }, 
              {
                "text": "And everyone who was in distress, everyone who was in debt, and everyone who was discontented gathered to him. So he became captain over them. And there were about four hundred men with him.", 
                "num": 2
              }, 
              {
                "text": "Then David went from there to Mizpah of Moab; and he said to the king of Moab, \"Please let my father and mother come here with you, till I know what God will do for me.\"", 
                "num": 3
              }, 
              {
                "text": "So he brought them before the king of Moab, and they dwelt with him all the time that David was in the stronghold.", 
                "num": 4
              }, 
              {
                "text": "Now the prophet Gad said to David, \"Do not stay in the stronghold; depart, and go to the land of Judah.\" So David departed and went into the forest of Hereth.", 
                "num": 5
              }, 
              {
                "text": "When Saul heard that David and the men who were with him had been discovered--now Saul was staying in Gibeah under a tamarisk tree in Ramah, with his spear in his hand, and all his servants standing about him--", 
                "num": 6
              }, 
              {
                "text": "then Saul said to his servants who stood about him, \"Hear now, you Benjamites! Will the son of Jesse give every one of you fields and vineyards, and make you all captains of thousands and captains of hundreds?", 
                "num": 7
              }, 
              {
                "text": "All of you have conspired against me, and there is no one who reveals to me that my son has made a covenant with the son of Jesse; and there is not one of you who is sorry for me or reveals to me that my son has stirred up my servant against me, to lie in wait, as it is this day.\"", 
                "num": 8
              }, 
              {
                "text": "Then answered Doeg the Edomite, who was set over the servants of Saul, and said, \"I saw the son of Jesse going to Nob, to Ahimelech the son of Ahitub.", 
                "num": 9
              }, 
              {
                "text": "And he inquired of the LORD for him, gave him provisions, and gave him the sword of Goliath the Philistine.\"", 
                "num": 10
              }, 
              {
                "text": "So the king sent to call Ahimelech the priest, the son of Ahitub, and all his father's house, the priests who were in Nob. And they all came to the king.", 
                "num": 11
              }, 
              {
                "text": "And Saul said, \"Hear now, son of Ahitub!\" He answered, \"Here I am, my lord.\"", 
                "num": 12
              }, 
              {
                "text": "Then Saul said to him, \"Why have you conspired against me, you and the son of Jesse, in that you have given him bread and a sword, and have inquired of God for him, that he should rise against me, to lie in wait, as it is this day?\"", 
                "num": 13
              }, 
              {
                "text": "So Ahimelech answered the king and said, \"And who among all your servants is as faithful as David, who is the king's son-in-law, who goes at your bidding, and is honorable in your house?", 
                "num": 14
              }, 
              {
                "text": "Did I then begin to inquire of God for him? Far be it from me! Let not the king impute anything to his servant, or to any in the house of my father. For your servant knew nothing of all this, little or much.\"", 
                "num": 15
              }, 
              {
                "text": "And the king said, \"You shall surely die, Ahimelech, you and all your father's house!\"", 
                "num": 16
              }, 
              {
                "text": "Then the king said to the guards who stood about him, \"Turn and kill the priests of the LORD, because their hand also is with David, and because they knew when he fled and did not tell it to me.\" But the servants of the king would not lift their hands to strike the priests of the LORD.", 
                "num": 17
              }, 
              {
                "text": "And the king said to Doeg, \"You turn and kill the priests!\" So Doeg the Edomite turned and struck the priests, and killed on that day eighty-five men who wore a linen ephod.", 
                "num": 18
              }, 
              {
                "text": "Also Nob, the city of the priests, he struck with the edge of the sword, both men and women, children and nursing infants, oxen and donkeys and sheep--with the edge of the sword.", 
                "num": 19
              }, 
              {
                "text": "Now one of the sons of Ahimelech the son of Ahitub, named Abiathar, escaped and fled after David.", 
                "num": 20
              }, 
              {
                "text": "And Abiathar told David that Saul had killed the LORD's priests.", 
                "num": 21
              }, 
              {
                "text": "So David said to Abiathar, \"I knew that day, when Doeg the Edomite was there, that he would surely tell Saul. I have caused the death of all the persons of your father's house.", 
                "num": 22
              }, 
              {
                "text": "Stay with me; do not fear. For he who seeks my life seeks your life, but with me you shall be safe.\"", 
                "num": 23
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then they told David, saying, \"Look, the Philistines are fighting against Keilah, and they are robbing the threshing floors.\"", 
                "num": 1
              }, 
              {
                "text": "Therefore David inquired of the LORD, saying, \"Shall I go and attack these Philistines?\" And the LORD said to David, \"Go and attack the Philistines, and save Keilah.\"", 
                "num": 2
              }, 
              {
                "text": "But David's men said to him, \"Look, we are afraid here in Judah. How much more then if we go to Keilah against the armies of the Philistines?\"", 
                "num": 3
              }, 
              {
                "text": "Then David inquired of the LORD once again. And the LORD answered him and said, \"Arise, go down to Keilah. For I will deliver the Philistines into your hand.\"", 
                "num": 4
              }, 
              {
                "text": "And David and his men went to Keilah and fought with the Philistines, struck them with a mighty blow, and took away their livestock. So David saved the inhabitants of Keilah.", 
                "num": 5
              }, 
              {
                "text": "Now it happened, when Abiathar the son of Ahimelech fled to David at Keilah, that he went down with an ephod in his hand.", 
                "num": 6
              }, 
              {
                "text": "And Saul was told that David had gone to Keilah. So Saul said, \"God has delivered him into my hand, for he has shut himself in by entering a town that has gates and bars.\"", 
                "num": 7
              }, 
              {
                "text": "Then Saul called all the people together for war, to go down to Keilah to besiege David and his men.", 
                "num": 8
              }, 
              {
                "text": "When David knew that Saul plotted evil against him, he said to Abiathar the priest, \"Bring the ephod here.\"", 
                "num": 9
              }, 
              {
                "text": "Then David said, \"O LORD God of Israel, Your servant has certainly heard that Saul seeks to come to Keilah to destroy the city for my sake.", 
                "num": 10
              }, 
              {
                "text": "Will the men of Keilah deliver me into his hand? Will Saul come down, as Your servant has heard? O LORD God of Israel, I pray, tell Your servant.\" And the LORD said, \"He will come down.\"", 
                "num": 11
              }, 
              {
                "text": "Then David said, \"Will the men of Keilah deliver me and my men into the hand of Saul?\" And the LORD said, \"They will deliver you.\"", 
                "num": 12
              }, 
              {
                "text": "So David and his men, about six hundred, arose and departed from Keilah and went wherever they could go. Then it was told Saul that David had escaped from Keilah; so he halted the expedition.", 
                "num": 13
              }, 
              {
                "text": "And David stayed in strongholds in the wilderness, and remained in the mountains in the Wilderness of Ziph. Saul sought him every day, but God did not deliver him into his hand.", 
                "num": 14
              }, 
              {
                "text": "So David saw that Saul had come out to seek his life. And David was in the Wilderness of Ziph in a forest.", 
                "num": 15
              }, 
              {
                "text": "Then Jonathan, Saul's son, arose and went to David in the woods and strengthened his hand in God.", 
                "num": 16
              }, 
              {
                "text": "And he said to him, \"Do not fear, for the hand of Saul my father shall not find you. You shall be king over Israel, and I shall be next to you. Even my father Saul knows that.\"", 
                "num": 17
              }, 
              {
                "text": "So the two of them made a covenant before the LORD. And David stayed in the woods, and Jonathan went to his own house.", 
                "num": 18
              }, 
              {
                "text": "Then the Ziphites came up to Saul at Gibeah, saying, \"Is David not hiding with us in strongholds in the woods, in the hill of Hachilah, which is on the south of Jeshimon?", 
                "num": 19
              }, 
              {
                "text": "Now therefore, O king, come down according to all the desire of your soul to come down; and our part shall be to deliver him into the king's hand.\"", 
                "num": 20
              }, 
              {
                "text": "And Saul said, \"Blessed are you of the LORD, for you have compassion on me.", 
                "num": 21
              }, 
              {
                "text": "Please go and find out for sure, and see the place where his hideout is, and who has seen him there. For I am told he is very crafty.", 
                "num": 22
              }, 
              {
                "text": "See therefore, and take knowledge of all the lurking places where he hides; and come back to me with certainty, and I will go with you. And it shall be, if he is in the land, that I will search for him throughout all the clans of Judah.\"", 
                "num": 23
              }, 
              {
                "text": "So they arose and went to Ziph before Saul. But David and his men were in the Wilderness of Maon, in the plain on the south of Jeshimon.", 
                "num": 24
              }, 
              {
                "text": "When Saul and his men went to seek him, they told David. Therefore he went down to the rock, and stayed in the Wilderness of Maon. And when Saul heard that, he pursued David in the Wilderness of Maon.", 
                "num": 25
              }, 
              {
                "text": "Then Saul went on one side of the mountain, and David and his men on the other side of the mountain. So David made haste to get away from Saul, for Saul and his men were encircling David and his men to take them.", 
                "num": 26
              }, 
              {
                "text": "But a messenger came to Saul, saying, \"Hurry and come, for the Philistines have invaded the land!\"", 
                "num": 27
              }, 
              {
                "text": "Therefore Saul returned from pursuing David, and went against the Philistines; so they called that place the Rock of Escape.", 
                "num": 28
              }, 
              {
                "text": "Then David went up from there and dwelt in strongholds at En Gedi.", 
                "num": 29
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now it happened, when Saul had returned from following the Philistines, that it was told him, saying, \"Take note! David is in the Wilderness of En Gedi.\"", 
                "num": 1
              }, 
              {
                "text": "Then Saul took three thousand chosen men from all Israel, and went to seek David and his men on the Rocks of the Wild Goats.", 
                "num": 2
              }, 
              {
                "text": "So he came to the sheepfolds by the road, where there was a cave; and Saul went in to attend to his needs. (David and his men were staying in the recesses of the cave.)", 
                "num": 3
              }, 
              {
                "text": "Then the men of David said to him, \"This is the day of which the LORD said to you, \"Behold, I will deliver your enemy into your hand, that you may do to him as it seems good to you.\"' And David arose and secretly cut off a corner of Saul's robe.", 
                "num": 4
              }, 
              {
                "text": "Now it happened afterward that David's heart troubled him because he had cut Saul's robe.", 
                "num": 5
              }, 
              {
                "text": "And he said to his men, \"The LORD forbid that I should do this thing to my master, the LORD's anointed, to stretch out my hand against him, seeing he is the anointed of the LORD.\"", 
                "num": 6
              }, 
              {
                "text": "So David restrained his servants with these words, and did not allow them to rise against Saul. And Saul got up from the cave and went on his way.", 
                "num": 7
              }, 
              {
                "text": "David also arose afterward, went out of the cave, and called out to Saul, saying, \"My lord the king!\" And when Saul looked behind him, David stooped with his face to the earth, and bowed down.", 
                "num": 8
              }, 
              {
                "text": "And David said to Saul: \"Why do you listen to the words of men who say, \"Indeed David seeks your harm'?", 
                "num": 9
              }, 
              {
                "text": "Look, this day your eyes have seen that the LORD delivered you today into my hand in the cave, and someone urged me to kill you. But my eye spared you, and I said, \"I will not stretch out my hand against my lord, for he is the LORD's anointed.'", 
                "num": 10
              }, 
              {
                "text": "Moreover, my father, see! Yes, see the corner of your robe in my hand! For in that I cut off the corner of your robe, and did not kill you, know and see that there is neither evil nor rebellion in my hand, and I have not sinned against you. Yet you hunt my life to take it.", 
                "num": 11
              }, 
              {
                "text": "Let the LORD judge between you and me, and let the LORD avenge me on you. But my hand shall not be against you.", 
                "num": 12
              }, 
              {
                "text": "As the proverb of the ancients says, \"Wickedness proceeds from the wicked.' But my hand shall not be against you.", 
                "num": 13
              }, 
              {
                "text": "After whom has the king of Israel come out? Whom do you pursue? A dead dog? A flea?", 
                "num": 14
              }, 
              {
                "text": "Therefore let the LORD be judge, and judge between you and me, and see and plead my case, and deliver me out of your hand.\"", 
                "num": 15
              }, 
              {
                "text": "So it was, when David had finished speaking these words to Saul, that Saul said, \"Is this your voice, my son David?\" And Saul lifted up his voice and wept.", 
                "num": 16
              }, 
              {
                "text": "Then he said to David: \"You are more righteous than I; for you have rewarded me with good, whereas I have rewarded you with evil.", 
                "num": 17
              }, 
              {
                "text": "And you have shown this day how you have dealt well with me; for when the LORD delivered me into your hand, you did not kill me.", 
                "num": 18
              }, 
              {
                "text": "For if a man finds his enemy, will he let him get away safely? Therefore may the LORD reward you with good for what you have done to me this day.", 
                "num": 19
              }, 
              {
                "text": "And now I know indeed that you shall surely be king, and that the kingdom of Israel shall be established in your hand.", 
                "num": 20
              }, 
              {
                "text": "Therefore swear now to me by the LORD that you will not cut off my descendants after me, and that you will not destroy my name from my father's house.\"", 
                "num": 21
              }, 
              {
                "text": "So David swore to Saul. And Saul went home, but David and his men went up to the stronghold.", 
                "num": 22
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Then Samuel died; and the Israelites gathered together and lamented for him, and buried him at his home in Ramah. And David arose and went down to the Wilderness of Paran.", 
                "num": 1
              }, 
              {
                "text": "Now there was a man in Maon whose business was in Carmel, and the man was very rich. He had three thousand sheep and a thousand goats. And he was shearing his sheep in Carmel.", 
                "num": 2
              }, 
              {
                "text": "The name of the man was Nabal, and the name of his wife Abigail. And she was a woman of good understanding and beautiful appearance; but the man was harsh and evil in his doings. He was of the house of Caleb.", 
                "num": 3
              }, 
              {
                "text": "When David heard in the wilderness that Nabal was shearing his sheep,", 
                "num": 4
              }, 
              {
                "text": "David sent ten young men; and David said to the young men, \"Go up to Carmel, go to Nabal, and greet him in my name.", 
                "num": 5
              }, 
              {
                "text": "And thus you shall say to him who lives in prosperity: \"Peace be to you, peace to your house, and peace to all that you have!", 
                "num": 6
              }, 
              {
                "text": "Now I have heard that you have shearers. Your shepherds were with us, and we did not hurt them, nor was there anything missing from them all the while they were in Carmel.", 
                "num": 7
              }, 
              {
                "text": "Ask your young men, and they will tell you. Therefore let my young men find favor in your eyes, for we come on a feast day. Please give whatever comes to your hand to your servants and to your son David.\"'", 
                "num": 8
              }, 
              {
                "text": "So when David's young men came, they spoke to Nabal according to all these words in the name of David, and waited.", 
                "num": 9
              }, 
              {
                "text": "Then Nabal answered David's servants, and said, \"Who is David, and who is the son of Jesse? There are many servants nowadays who break away each one from his master.", 
                "num": 10
              }, 
              {
                "text": "Shall I then take my bread and my water and my meat that I have killed for my shearers, and give it to men when I do not know where they are from?\"", 
                "num": 11
              }, 
              {
                "text": "So David's young men turned on their heels and went back; and they came and told him all these words.", 
                "num": 12
              }, 
              {
                "text": "Then David said to his men, \"Every man gird on his sword.\" So every man girded on his sword, and David also girded on his sword. And about four hundred men went with David, and two hundred stayed with the supplies.", 
                "num": 13
              }, 
              {
                "text": "Now one of the young men told Abigail, Nabal's wife, saying, \"Look, David sent messengers from the wilderness to greet our master; and he reviled them.", 
                "num": 14
              }, 
              {
                "text": "But the men were very good to us, and we were not hurt, nor did we miss anything as long as we accompanied them, when we were in the fields.", 
                "num": 15
              }, 
              {
                "text": "They were a wall to us both by night and day, all the time we were with them keeping the sheep.", 
                "num": 16
              }, 
              {
                "text": "Now therefore, know and consider what you will do, for harm is determined against our master and against all his household. For he is such a scoundrel that one cannot speak to him.\"", 
                "num": 17
              }, 
              {
                "text": "Then Abigail made haste and took two hundred loaves of bread, two skins of wine, five sheep already dressed, five seahs of roasted grain, one hundred clusters of raisins, and two hundred cakes of figs, and loaded them on donkeys.", 
                "num": 18
              }, 
              {
                "text": "And she said to her servants, \"Go on before me; see, I am coming after you.\" But she did not tell her husband Nabal.", 
                "num": 19
              }, 
              {
                "text": "So it was, as she rode on the donkey, that she went down under cover of the hill; and there were David and his men, coming down toward her, and she met them.", 
                "num": 20
              }, 
              {
                "text": "Now David had said, \"Surely in vain I have protected all that this fellow has in the wilderness, so that nothing was missed of all that belongs to him. And he has repaid me evil for good.", 
                "num": 21
              }, 
              {
                "text": "May God do so, and more also, to the enemies of David, if I leave one male of all who belong to him by morning light.\"", 
                "num": 22
              }, 
              {
                "text": "Now when Abigail saw David, she dismounted quickly from the donkey, fell on her face before David, and bowed down to the ground.", 
                "num": 23
              }, 
              {
                "text": "So she fell at his feet and said: \"On me, my lord, on me let this iniquity be! And please let your maidservant speak in your ears, and hear the words of your maidservant.", 
                "num": 24
              }, 
              {
                "text": "Please, let not my lord regard this scoundrel Nabal. For as his name is, so is he: Nabal is his name, and folly is with him! But I, your maidservant, did not see the young men of my lord whom you sent.", 
                "num": 25
              }, 
              {
                "text": "Now therefore, my lord, as the LORD lives and as your soul lives, since the LORD has held you back from coming to bloodshed and from avenging yourself with your own hand, now then, let your enemies and those who seek harm for my lord be as Nabal.", 
                "num": 26
              }, 
              {
                "text": "And now this present which your maidservant has brought to my lord, let it be given to the young men who follow my lord.", 
                "num": 27
              }, 
              {
                "text": "Please forgive the trespass of your maidservant. For the LORD will certainly make for my lord an enduring house, because my lord fights the battles of the LORD, and evil is not found in you throughout your days.", 
                "num": 28
              }, 
              {
                "text": "Yet a man has risen to pursue you and seek your life, but the life of my lord shall be bound in the bundle of the living with the LORD your God; and the lives of your enemies He shall sling out, as from the pocket of a sling.", 
                "num": 29
              }, 
              {
                "text": "And it shall come to pass, when the LORD has done for my lord according to all the good that He has spoken concerning you, and has appointed you ruler over Israel,", 
                "num": 30
              }, 
              {
                "text": "that this will be no grief to you, nor offense of heart to my lord, either that you have shed blood without cause, or that my lord has avenged himself. But when the LORD has dealt well with my lord, then remember your maidservant.\"", 
                "num": 31
              }, 
              {
                "text": "Then David said to Abigail: \"Blessed is the LORD God of Israel, who sent you this day to meet me!", 
                "num": 32
              }, 
              {
                "text": "And blessed is your advice and blessed are you, because you have kept me this day from coming to bloodshed and from avenging myself with my own hand.", 
                "num": 33
              }, 
              {
                "text": "For indeed, as the LORD God of Israel lives, who has kept me back from hurting you, unless you had hurried and come to meet me, surely by morning light no males would have been left to Nabal!\"", 
                "num": 34
              }, 
              {
                "text": "So David received from her hand what she had brought him, and said to her, \"Go up in peace to your house. See, I have heeded your voice and respected your person.\"", 
                "num": 35
              }, 
              {
                "text": "Now Abigail went to Nabal, and there he was, holding a feast in his house, like the feast of a king. And Nabal's heart was merry within him, for he was very drunk; therefore she told him nothing, little or much, until morning light.", 
                "num": 36
              }, 
              {
                "text": "So it was, in the morning, when the wine had gone from Nabal, and his wife had told him these things, that his heart died within him, and he became like a stone.", 
                "num": 37
              }, 
              {
                "text": "Then it happened, after about ten days, that the LORD struck Nabal, and he died.", 
                "num": 38
              }, 
              {
                "text": "So when David heard that Nabal was dead, he said, \"Blessed be the LORD, who has pleaded the cause of my reproach from the hand of Nabal, and has kept His servant from evil! For the LORD has returned the wickedness of Nabal on his own head.\" And David sent and proposed to Abigail, to take her as his wife.", 
                "num": 39
              }, 
              {
                "text": "When the servants of David had come to Abigail at Carmel, they spoke to her saying, \"David sent us to you, to ask you to become his wife.\"", 
                "num": 40
              }, 
              {
                "text": "Then she arose, bowed her face to the earth, and said, \"Here is your maidservant, a servant to wash the feet of the servants of my lord.\"", 
                "num": 41
              }, 
              {
                "text": "So Abigail rose in haste and rode on a donkey, attended by five of her maidens; and she followed the messengers of David, and became his wife.", 
                "num": 42
              }, 
              {
                "text": "David also took Ahinoam of Jezreel, and so both of them were his wives.", 
                "num": 43
              }, 
              {
                "text": "But Saul had given Michal his daughter, David's wife, to Palti the son of Laish, who was from Gallim.", 
                "num": 44
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Now the Ziphites came to Saul at Gibeah, saying, \"Is David not hiding in the hill of Hachilah, opposite Jeshimon?\"", 
                "num": 1
              }, 
              {
                "text": "Then Saul arose and went down to the Wilderness of Ziph, having three thousand chosen men of Israel with him, to seek David in the Wilderness of Ziph.", 
                "num": 2
              }, 
              {
                "text": "And Saul encamped in the hill of Hachilah, which is opposite Jeshimon, by the road. But David stayed in the wilderness, and he saw that Saul came after him into the wilderness.", 
                "num": 3
              }, 
              {
                "text": "David therefore sent out spies, and understood that Saul had indeed come.", 
                "num": 4
              }, 
              {
                "text": "So David arose and came to the place where Saul had encamped. And David saw the place where Saul lay, and Abner the son of Ner, the commander of his army. Now Saul lay within the camp, with the people encamped all around him.", 
                "num": 5
              }, 
              {
                "text": "Then David answered, and said to Ahimelech the Hittite and to Abishai the son of Zeruiah, brother of Joab, saying, \"Who will go down with me to Saul in the camp?\" And Abishai said, \"I will go down with you.\"", 
                "num": 6
              }, 
              {
                "text": "So David and Abishai came to the people by night; and there Saul lay sleeping within the camp, with his spear stuck in the ground by his head. And Abner and the people lay all around him.", 
                "num": 7
              }, 
              {
                "text": "Then Abishai said to David, \"God has delivered your enemy into your hand this day. Now therefore, please, let me strike him at once with the spear, right to the earth; and I will not have to strike him a second time!\"", 
                "num": 8
              }, 
              {
                "text": "But David said to Abishai, \"Do not destroy him; for who can stretch out his hand against the LORD's anointed, and be guiltless?\"", 
                "num": 9
              }, 
              {
                "text": "David said furthermore, \"As the LORD lives, the LORD shall strike him, or his day shall come to die, or he shall go out to battle and perish.", 
                "num": 10
              }, 
              {
                "text": "The LORD forbid that I should stretch out my hand against the LORD's anointed. But please, take now the spear and the jug of water that are by his head, and let us go.\"", 
                "num": 11
              }, 
              {
                "text": "So David took the spear and the jug of water by Saul's head, and they got away; and no man saw or knew it or awoke. For they were all asleep, because a deep sleep from the LORD had fallen on them.", 
                "num": 12
              }, 
              {
                "text": "Now David went over to the other side, and stood on the top of a hill afar off, a great distance being between them.", 
                "num": 13
              }, 
              {
                "text": "And David called out to the people and to Abner the son of Ner, saying, \"Do you not answer, Abner?\" Then Abner answered and said, \"Who are you, calling out to the king?\"", 
                "num": 14
              }, 
              {
                "text": "So David said to Abner, \"Are you not a man? And who is like you in Israel? Why then have you not guarded your lord the king? For one of the people came in to destroy your lord the king.", 
                "num": 15
              }, 
              {
                "text": "This thing that you have done is not good. As the LORD lives, you deserve to die, because you have not guarded your master, the LORD's anointed. And now see where the king's spear is, and the jug of water that was by his head.\"", 
                "num": 16
              }, 
              {
                "text": "Then Saul knew David's voice, and said, \"Is that your voice, my son David?\" David said, \"It is my voice, my lord, O king.\"", 
                "num": 17
              }, 
              {
                "text": "And he said, \"Why does my lord thus pursue his servant? For what have I done, or what evil is in my hand?", 
                "num": 18
              }, 
              {
                "text": "Now therefore, please, let my lord the king hear the words of his servant: If the LORD has stirred you up against me, let Him accept an offering. But if it is the children of men, may they be cursed before the LORD, for they have driven me out this day from sharing in the inheritance of the LORD, saying, \"Go, serve other gods.'", 
                "num": 19
              }, 
              {
                "text": "So now, do not let my blood fall to the earth before the face of the LORD. For the king of Israel has come out to seek a flea, as when one hunts a partridge in the mountains.\"", 
                "num": 20
              }, 
              {
                "text": "Then Saul said, \"I have sinned. Return, my son David. For I will harm you no more, because my life was precious in your eyes this day. Indeed I have played the fool and erred exceedingly.\"", 
                "num": 21
              }, 
              {
                "text": "And David answered and said, \"Here is the king's spear. Let one of the young men come over and get it.", 
                "num": 22
              }, 
              {
                "text": "May the LORD repay every man for his righteousness and his faithfulness; for the LORD delivered you into my hand today, but I would not stretch out my hand against the LORD's anointed.", 
                "num": 23
              }, 
              {
                "text": "And indeed, as your life was valued much this day in my eyes, so let my life be valued much in the eyes of the LORD, and let Him deliver me out of all tribulation.\"", 
                "num": 24
              }, 
              {
                "text": "Then Saul said to David, \"May you be blessed, my son David! You shall both do great things and also still prevail.\" So David went on his way, and Saul returned to his place.", 
                "num": 25
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "And David said in his heart, \"Now I shall perish someday by the hand of Saul. There is nothing better for me than that I should speedily escape to the land of the Philistines; and Saul will despair of me, to seek me anymore in any part of Israel. So I shall escape out of his hand.\"", 
                "num": 1
              }, 
              {
                "text": "Then David arose and went over with the six hundred men who were with him to Achish the son of Maoch, king of Gath.", 
                "num": 2
              }, 
              {
                "text": "So David dwelt with Achish at Gath, he and his men, each man with his household, and David with his two wives, Ahinoam the Jezreelitess, and Abigail the Carmelitess, Nabal's widow.", 
                "num": 3
              }, 
              {
                "text": "And it was told Saul that David had fled to Gath; so he sought him no more.", 
                "num": 4
              }, 
              {
                "text": "Then David said to Achish, \"If I have now found favor in your eyes, let them give me a place in some town in the country, that I may dwell there. For why should your servant dwell in the royal city with you?\"", 
                "num": 5
              }, 
              {
                "text": "So Achish gave him Ziklag that day. Therefore Ziklag has belonged to the kings of Judah to this day.", 
                "num": 6
              }, 
              {
                "text": "Now the time that David dwelt in the country of the Philistines was one full year and four months.", 
                "num": 7
              }, 
              {
                "text": "And David and his men went up and raided the Geshurites, the Girzites, and the Amalekites. For those nations were the inhabitants of the land from of old, as you go to Shur, even as far as the land of Egypt.", 
                "num": 8
              }, 
              {
                "text": "Whenever David attacked the land, he left neither man nor woman alive, but took away the sheep, the oxen, the donkeys, the camels, and the apparel, and returned and came to Achish.", 
                "num": 9
              }, 
              {
                "text": "Then Achish would say, \"Where have you made a raid today?\" And David would say, \"Against the southern area of Judah, or against the southern area of the Jerahmeelites, or against the southern area of the Kenites.\"", 
                "num": 10
              }, 
              {
                "text": "David would save neither man nor woman alive, to bring news to Gath, saying, \"Lest they should inform on us, saying, \"Thus David did.\"' And thus was his behavior all the time he dwelt in the country of the Philistines.", 
                "num": 11
              }, 
              {
                "text": "So Achish believed David, saying, \"He has made his people Israel utterly abhor him; therefore he will be my servant forever.\"", 
                "num": 12
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Now it happened in those days that the Philistines gathered their armies together for war, to fight with Israel. And Achish said to David, \"You assuredly know that you will go out with me to battle, you and your men.\"", 
                "num": 1
              }, 
              {
                "text": "So David said to Achish, \"Surely you know what your servant can do.\" And Achish said to David, \"Therefore I will make you one of my chief guardians forever.\"", 
                "num": 2
              }, 
              {
                "text": "Now Samuel had died, and all Israel had lamented for him and buried him in Ramah, in his own city. And Saul had put the mediums and the spiritists out of the land.", 
                "num": 3
              }, 
              {
                "text": "Then the Philistines gathered together, and came and encamped at Shunem. So Saul gathered all Israel together, and they encamped at Gilboa.", 
                "num": 4
              }, 
              {
                "text": "When Saul saw the army of the Philistines, he was afraid, and his heart trembled greatly.", 
                "num": 5
              }, 
              {
                "text": "And when Saul inquired of the LORD, the LORD did not answer him, either by dreams or by Urim or by the prophets.", 
                "num": 6
              }, 
              {
                "text": "Then Saul said to his servants, \"Find me a woman who is a medium, that I may go to her and inquire of her.\" And his servants said to him, \"In fact, there is a woman who is a medium at En Dor.\"", 
                "num": 7
              }, 
              {
                "text": "So Saul disguised himself and put on other clothes, and he went, and two men with him; and they came to the woman by night. And he said, \"Please conduct a s^eaance for me, and bring up for me the one I shall name to you.\"", 
                "num": 8
              }, 
              {
                "text": "Then the woman said to him, \"Look, you know what Saul has done, how he has cut off the mediums and the spiritists from the land. Why then do you lay a snare for my life, to cause me to die?\"", 
                "num": 9
              }, 
              {
                "text": "And Saul swore to her by the LORD, saying, \"As the LORD lives, no punishment shall come upon you for this thing.\"", 
                "num": 10
              }, 
              {
                "text": "Then the woman said, \"Whom shall I bring up for you?\" And he said, \"Bring up Samuel for me.\"", 
                "num": 11
              }, 
              {
                "text": "When the woman saw Samuel, she cried out with a loud voice. And the woman spoke to Saul, saying, \"Why have you deceived me? For you are Saul!\"", 
                "num": 12
              }, 
              {
                "text": "And the king said to her, \"Do not be afraid. What did you see?\" And the woman said to Saul, \"I saw a spirit ascending out of the earth.\"", 
                "num": 13
              }, 
              {
                "text": "So he said to her, \"What is his form?\" And she said, \"An old man is coming up, and he is covered with a mantle.\" And Saul perceived that it was Samuel, and he stooped with his face to the ground and bowed down.", 
                "num": 14
              }, 
              {
                "text": "Now Samuel said to Saul, \"Why have you disturbed me by bringing me up?\" And Saul answered, \"I am deeply distressed; for the Philistines make war against me, and God has departed from me and does not answer me anymore, neither by prophets nor by dreams. Therefore I have called you, that you may reveal to me what I should do.\"", 
                "num": 15
              }, 
              {
                "text": "Then Samuel said: \"So why do you ask me, seeing the LORD has departed from you and has become your enemy?", 
                "num": 16
              }, 
              {
                "text": "And the LORD has done for Himself as He spoke by me. For the LORD has torn the kingdom out of your hand and given it to your neighbor, David.", 
                "num": 17
              }, 
              {
                "text": "Because you did not obey the voice of the LORD nor execute His fierce wrath upon Amalek, therefore the LORD has done this thing to you this day.", 
                "num": 18
              }, 
              {
                "text": "Moreover the LORD will also deliver Israel with you into the hand of the Philistines. And tomorrow you and your sons will be with me. The LORD will also deliver the army of Israel into the hand of the Philistines.\"", 
                "num": 19
              }, 
              {
                "text": "Immediately Saul fell full length on the ground, and was dreadfully afraid because of the words of Samuel. And there was no strength in him, for he had eaten no food all day or all night.", 
                "num": 20
              }, 
              {
                "text": "And the woman came to Saul and saw that he was severely troubled, and said to him, \"Look, your maidservant has obeyed your voice, and I have put my life in my hands and heeded the words which you spoke to me.", 
                "num": 21
              }, 
              {
                "text": "Now therefore, please, heed also the voice of your maidservant, and let me set a piece of bread before you; and eat, that you may have strength when you go on your way.\"", 
                "num": 22
              }, 
              {
                "text": "But he refused and said, \"I will not eat.\" So his servants, together with the woman, urged him; and he heeded their voice. Then he arose from the ground and sat on the bed.", 
                "num": 23
              }, 
              {
                "text": "Now the woman had a fatted calf in the house, and she hastened to kill it. And she took flour and kneaded it, and baked unleavened bread from it.", 
                "num": 24
              }, 
              {
                "text": "So she brought it before Saul and his servants, and they ate. Then they rose and went away that night.", 
                "num": 25
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Then the Philistines gathered together all their armies at Aphek, and the Israelites encamped by a fountain which is in Jezreel.", 
                "num": 1
              }, 
              {
                "text": "And the lords of the Philistines passed in review by hundreds and by thousands, but David and his men passed in review at the rear with Achish.", 
                "num": 2
              }, 
              {
                "text": "Then the princes of the Philistines said, \"What are these Hebrews doing here?\" And Achish said to the princes of the Philistines, \"Is this not David, the servant of Saul king of Israel, who has been with me these days, or these years? And to this day I have found no fault in him since he defected to me.\"", 
                "num": 3
              }, 
              {
                "text": "But the princes of the Philistines were angry with him; so the princes of the Philistines said to him, \"Make this fellow return, that he may go back to the place which you have appointed for him, and do not let him go down with us to battle, lest in the battle he become our adversary. For with what could he reconcile himself to his master, if not with the heads of these men?", 
                "num": 4
              }, 
              {
                "text": "Is this not David, of whom they sang to one another in dances, saying: \"Saul has slain his thousands, And David his ten thousands'?\"", 
                "num": 5
              }, 
              {
                "text": "Then Achish called David and said to him, \"Surely, as the LORD lives, you have been upright, and your going out and your coming in with me in the army is good in my sight. For to this day I have not found evil in you since the day of your coming to me. Nevertheless the lords do not favor you.", 
                "num": 6
              }, 
              {
                "text": "Therefore return now, and go in peace, that you may not displease the lords of the Philistines.\"", 
                "num": 7
              }, 
              {
                "text": "So David said to Achish, \"But what have I done? And to this day what have you found in your servant as long as I have been with you, that I may not go and fight against the enemies of my lord the king?\"", 
                "num": 8
              }, 
              {
                "text": "Then Achish answered and said to David, \"I know that you are as good in my sight as an angel of God; nevertheless the princes of the Philistines have said, \"He shall not go up with us to the battle.'", 
                "num": 9
              }, 
              {
                "text": "Now therefore, rise early in the morning with your master's servants who have come with you. And as soon as you are up early in the morning and have light, depart.\"", 
                "num": 10
              }, 
              {
                "text": "So David and his men rose early to depart in the morning, to return to the land of the Philistines. And the Philistines went up to Jezreel.", 
                "num": 11
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "Now it happened, when David and his men came to Ziklag, on the third day, that the Amalekites had invaded the South and Ziklag, attacked Ziklag and burned it with fire,", 
                "num": 1
              }, 
              {
                "text": "and had taken captive the women and those who were there, from small to great; they did not kill anyone, but carried them away and went their way.", 
                "num": 2
              }, 
              {
                "text": "So David and his men came to the city, and there it was, burned with fire; and their wives, their sons, and their daughters had been taken captive.", 
                "num": 3
              }, 
              {
                "text": "Then David and the people who were with him lifted up their voices and wept, until they had no more power to weep.", 
                "num": 4
              }, 
              {
                "text": "And David's two wives, Ahinoam the Jezreelitess, and Abigail the widow of Nabal the Carmelite, had been taken captive.", 
                "num": 5
              }, 
              {
                "text": "Now David was greatly distressed, for the people spoke of stoning him, because the soul of all the people was grieved, every man for his sons and his daughters. But David strengthened himself in the LORD his God.", 
                "num": 6
              }, 
              {
                "text": "Then David said to Abiathar the priest, Ahimelech's son, \"Please bring the ephod here to me.\" And Abiathar brought the ephod to David.", 
                "num": 7
              }, 
              {
                "text": "So David inquired of the LORD, saying, \"Shall I pursue this troop? Shall I overtake them?\" And He answered him, \"Pursue, for you shall surely overtake them and without fail recover all.\"", 
                "num": 8
              }, 
              {
                "text": "So David went, he and the six hundred men who were with him, and came to the Brook Besor, where those stayed who were left behind.", 
                "num": 9
              }, 
              {
                "text": "But David pursued, he and four hundred men; for two hundred stayed behind, who were so weary that they could not cross the Brook Besor.", 
                "num": 10
              }, 
              {
                "text": "Then they found an Egyptian in the field, and brought him to David; and they gave him bread and he ate, and they let him drink water.", 
                "num": 11
              }, 
              {
                "text": "And they gave him a piece of a cake of figs and two clusters of raisins. So when he had eaten, his strength came back to him; for he had eaten no bread nor drunk water for three days and three nights.", 
                "num": 12
              }, 
              {
                "text": "Then David said to him, \"To whom do you belong, and where are you from?\" And he said, \"I am a young man from Egypt, servant of an Amalekite; and my master left me behind, because three days ago I fell sick.", 
                "num": 13
              }, 
              {
                "text": "We made an invasion of the southern area of the Cherethites, in the territory which belongs to Judah, and of the southern area of Caleb; and we burned Ziklag with fire.\"", 
                "num": 14
              }, 
              {
                "text": "And David said to him, \"Can you take me down to this troop?\" So he said, \"Swear to me by God that you will neither kill me nor deliver me into the hands of my master, and I will take you down to this troop.\"", 
                "num": 15
              }, 
              {
                "text": "And when he had brought him down, there they were, spread out over all the land, eating and drinking and dancing, because of all the great spoil which they had taken from the land of the Philistines and from the land of Judah.", 
                "num": 16
              }, 
              {
                "text": "Then David attacked them from twilight until the evening of the next day. Not a man of them escaped, except four hundred young men who rode on camels and fled.", 
                "num": 17
              }, 
              {
                "text": "So David recovered all that the Amalekites had carried away, and David rescued his two wives.", 
                "num": 18
              }, 
              {
                "text": "And nothing of theirs was lacking, either small or great, sons or daughters, spoil or anything which they had taken from them; David recovered all.", 
                "num": 19
              }, 
              {
                "text": "Then David took all the flocks and herds they had driven before those other livestock, and said, \"This is David's spoil.\"", 
                "num": 20
              }, 
              {
                "text": "Now David came to the two hundred men who had been so weary that they could not follow David, whom they also had made to stay at the Brook Besor. So they went out to meet David and to meet the people who were with him. And when David came near the people, he greeted them.", 
                "num": 21
              }, 
              {
                "text": "Then all the wicked and worthless men of those who went with David answered and said, \"Because they did not go with us, we will not give them any of the spoil that we have recovered, except for every man's wife and children, that they may lead them away and depart.\"", 
                "num": 22
              }, 
              {
                "text": "But David said, \"My brethren, you shall not do so with what the LORD has given us, who has preserved us and delivered into our hand the troop that came against us.", 
                "num": 23
              }, 
              {
                "text": "For who will heed you in this matter? But as his part is who goes down to the battle, so shall his part be who stays by the supplies; they shall share alike.\"", 
                "num": 24
              }, 
              {
                "text": "So it was, from that day forward; he made it a statute and an ordinance for Israel to this day.", 
                "num": 25
              }, 
              {
                "text": "Now when David came to Ziklag, he sent some of the spoil to the elders of Judah, to his friends, saying, \"Here is a present for you from the spoil of the enemies of the LORD\"--", 
                "num": 26
              }, 
              {
                "text": "to those who were in Bethel, those who were in Ramoth of the South, those who were in Jattir,", 
                "num": 27
              }, 
              {
                "text": "those who were in Aroer, those who were in Siphmoth, those who were in Eshtemoa,", 
                "num": 28
              }, 
              {
                "text": "those who were in Rachal, those who were in the cities of the Jerahmeelites, those who were in the cities of the Kenites,", 
                "num": 29
              }, 
              {
                "text": "those who were in Hormah, those who were in Chorashan, those who were in Athach,", 
                "num": 30
              }, 
              {
                "text": "those who were in Hebron, and to all the places where David himself and his men were accustomed to rove.", 
                "num": 31
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Now the Philistines fought against Israel; and the men of Israel fled from before the Philistines, and fell slain on Mount Gilboa.", 
                "num": 1
              }, 
              {
                "text": "Then the Philistines followed hard after Saul and his sons. And the Philistines killed Jonathan, Abinadab, and Malchishua, Saul's sons.", 
                "num": 2
              }, 
              {
                "text": "The battle became fierce against Saul. The archers hit him, and he was severely wounded by the archers.", 
                "num": 3
              }, 
              {
                "text": "Then Saul said to his armorbearer, \"Draw your sword, and thrust me through with it, lest these uncircumcised men come and thrust me through and abuse me.\" But his armorbearer would not, for he was greatly afraid. Therefore Saul took a sword and fell on it.", 
                "num": 4
              }, 
              {
                "text": "And when his armorbearer saw that Saul was dead, he also fell on his sword, and died with him.", 
                "num": 5
              }, 
              {
                "text": "So Saul, his three sons, his armorbearer, and all his men died together that same day.", 
                "num": 6
              }, 
              {
                "text": "And when the men of Israel who were on the other side of the valley, and those who were on the other side of the Jordan, saw that the men of Israel had fled and that Saul and his sons were dead, they forsook the cities and fled; and the Philistines came and dwelt in them.", 
                "num": 7
              }, 
              {
                "text": "So it happened the next day, when the Philistines came to strip the slain, that they found Saul and his three sons fallen on Mount Gilboa.", 
                "num": 8
              }, 
              {
                "text": "And they cut off his head and stripped off his armor, and sent word throughout the land of the Philistines, to proclaim it in the temple of their idols and among the people.", 
                "num": 9
              }, 
              {
                "text": "Then they put his armor in the temple of the Ashtoreths, and they fastened his body to the wall of Beth Shan.", 
                "num": 10
              }, 
              {
                "text": "Now when the inhabitants of Jabesh Gilead heard what the Philistines had done to Saul,", 
                "num": 11
              }, 
              {
                "text": "all the valiant men arose and traveled all night, and took the body of Saul and the bodies of his sons from the wall of Beth Shan; and they came to Jabesh and burned them there.", 
                "num": 12
              }, 
              {
                "text": "Then they took their bones and buried them under the tamarisk tree at Jabesh, and fasted seven days.", 
                "num": 13
              }
            ], 
            "num": 31
          }
        ]
      }, 
      {
        "name": "2 Samuel", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now it came to pass after the death of Saul, when David had returned from the slaughter of the Amalekites, and David had stayed two days in Ziklag,", 
                "num": 1
              }, 
              {
                "text": "on the third day, behold, it happened that a man came from Saul's camp with his clothes torn and dust on his head. So it was, when he came to David, that he fell to the ground and prostrated himself.", 
                "num": 2
              }, 
              {
                "text": "And David said to him, \"Where have you come from?\" So he said to him, \"I have escaped from the camp of Israel.\"", 
                "num": 3
              }, 
              {
                "text": "Then David said to him, \"How did the matter go? Please tell me.\" And he answered, \"The people have fled from the battle, many of the people are fallen and dead, and Saul and Jonathan his son are dead also.\"", 
                "num": 4
              }, 
              {
                "text": "So David said to the young man who told him, \"How do you know that Saul and Jonathan his son are dead?\"", 
                "num": 5
              }, 
              {
                "text": "Then the young man who told him said, \"As I happened by chance to be on Mount Gilboa, there was Saul, leaning on his spear; and indeed the chariots and horsemen followed hard after him.", 
                "num": 6
              }, 
              {
                "text": "Now when he looked behind him, he saw me and called to me. And I answered, \"Here I am.'", 
                "num": 7
              }, 
              {
                "text": "And he said to me, \"Who are you?' So I answered him, \"I am an Amalekite.'", 
                "num": 8
              }, 
              {
                "text": "He said to me again, \"Please stand over me and kill me, for anguish has come upon me, but my life still remains in me.'", 
                "num": 9
              }, 
              {
                "text": "So I stood over him and killed him, because I was sure that he could not live after he had fallen. And I took the crown that was on his head and the bracelet that was on his arm, and have brought them here to my lord.\"", 
                "num": 10
              }, 
              {
                "text": "Therefore David took hold of his own clothes and tore them, and so did all the men who were with him.", 
                "num": 11
              }, 
              {
                "text": "And they mourned and wept and fasted until evening for Saul and for Jonathan his son, for the people of the LORD and for the house of Israel, because they had fallen by the sword.", 
                "num": 12
              }, 
              {
                "text": "Then David said to the young man who told him, \"Where are you from?\" And he answered, \"I am the son of an alien, an Amalekite.\"", 
                "num": 13
              }, 
              {
                "text": "So David said to him, \"How was it you were not afraid to put forth your hand to destroy the LORD's anointed?\"", 
                "num": 14
              }, 
              {
                "text": "Then David called one of the young men and said, \"Go near, and execute him!\" And he struck him so that he died.", 
                "num": 15
              }, 
              {
                "text": "So David said to him, \"Your blood is on your own head, for your own mouth has testified against you, saying, \"I have killed the LORD's anointed.\"'", 
                "num": 16
              }, 
              {
                "text": "Then David lamented with this lamentation over Saul and over Jonathan his son,", 
                "num": 17
              }, 
              {
                "text": "and he told them to teach the children of Judah the Song of the Bow; indeed it is written in the Book of Jasher:", 
                "num": 18
              }, 
              {
                "text": "\"The beauty of Israel is slain on your high places! How the mighty have fallen!", 
                "num": 19
              }, 
              {
                "text": "Tell it not in Gath, Proclaim it not in the streets of Ashkelon-- Lest the daughters of the Philistines rejoice, Lest the daughters of the uncircumcised triumph.", 
                "num": 20
              }, 
              {
                "text": "\"O mountains of Gilboa, Let there be no dew nor rain upon you, Nor fields of offerings. For the shield of the mighty is cast away there! The shield of Saul, not anointed with oil.", 
                "num": 21
              }, 
              {
                "text": "From the blood of the slain, From the fat of the mighty, The bow of Jonathan did not turn back, And the sword of Saul did not return empty.", 
                "num": 22
              }, 
              {
                "text": "\"Saul and Jonathan were beloved and pleasant in their lives, And in their death they were not divided; They were swifter than eagles, They were stronger than lions.", 
                "num": 23
              }, 
              {
                "text": "\"O daughters of Israel, weep over Saul, Who clothed you in scarlet, with luxury; Who put ornaments of gold on your apparel.", 
                "num": 24
              }, 
              {
                "text": "\"How the mighty have fallen in the midst of the battle! Jonathan was slain in your high places.", 
                "num": 25
              }, 
              {
                "text": "I am distressed for you, my brother Jonathan; You have been very pleasant to me; Your love to me was wonderful, Surpassing the love of women.", 
                "num": 26
              }, 
              {
                "text": "\"How the mighty have fallen, And the weapons of war perished!\"", 
                "num": 27
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "It happened after this that David inquired of the LORD, saying, \"Shall I go up to any of the cities of Judah?\" And the LORD said to him, \"Go up.\" David said, \"Where shall I go up?\" And He said, \"To Hebron.\"", 
                "num": 1
              }, 
              {
                "text": "So David went up there, and his two wives also, Ahinoam the Jezreelitess, and Abigail the widow of Nabal the Carmelite.", 
                "num": 2
              }, 
              {
                "text": "And David brought up the men who were with him, every man with his household. So they dwelt in the cities of Hebron.", 
                "num": 3
              }, 
              {
                "text": "Then the men of Judah came, and there they anointed David king over the house of Judah. And they told David, saying, \"The men of Jabesh Gilead were the ones who buried Saul.\"", 
                "num": 4
              }, 
              {
                "text": "So David sent messengers to the men of Jabesh Gilead, and said to them, \"You are blessed of the LORD, for you have shown this kindness to your lord, to Saul, and have buried him.", 
                "num": 5
              }, 
              {
                "text": "And now may the LORD show kindness and truth to you. I also will repay you this kindness, because you have done this thing.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, let your hands be strengthened, and be valiant; for your master Saul is dead, and also the house of Judah has anointed me king over them.\"", 
                "num": 7
              }, 
              {
                "text": "But Abner the son of Ner, commander of Saul's army, took Ishbosheth the son of Saul and brought him over to Mahanaim;", 
                "num": 8
              }, 
              {
                "text": "and he made him king over Gilead, over the Ashurites, over Jezreel, over Ephraim, over Benjamin, and over all Israel.", 
                "num": 9
              }, 
              {
                "text": "Ishbosheth, Saul's son, was forty years old when he began to reign over Israel, and he reigned two years. Only the house of Judah followed David.", 
                "num": 10
              }, 
              {
                "text": "And the time that David was king in Hebron over the house of Judah was seven years and six months.", 
                "num": 11
              }, 
              {
                "text": "Now Abner the son of Ner, and the servants of Ishbosheth the son of Saul, went out from Mahanaim to Gibeon.", 
                "num": 12
              }, 
              {
                "text": "And Joab the son of Zeruiah, and the servants of David, went out and met them by the pool of Gibeon. So they sat down, one on one side of the pool and the other on the other side of the pool.", 
                "num": 13
              }, 
              {
                "text": "Then Abner said to Joab, \"Let the young men now arise and compete before us.\" And Joab said, \"Let them arise.\"", 
                "num": 14
              }, 
              {
                "text": "So they arose and went over by number, twelve from Benjamin, followers of Ishbosheth the son of Saul, and twelve from the servants of David.", 
                "num": 15
              }, 
              {
                "text": "And each one grasped his opponent by the head and thrust his sword in his opponent's side; so they fell down together. Therefore that place was called the Field of Sharp Swords, which is in Gibeon.", 
                "num": 16
              }, 
              {
                "text": "So there was a very fierce battle that day, and Abner and the men of Israel were beaten before the servants of David.", 
                "num": 17
              }, 
              {
                "text": "Now the three sons of Zeruiah were there: Joab and Abishai and Asahel. And Asahel was as fleet of foot as a wild gazelle.", 
                "num": 18
              }, 
              {
                "text": "So Asahel pursued Abner, and in going he did not turn to the right hand or to the left from following Abner.", 
                "num": 19
              }, 
              {
                "text": "Then Abner looked behind him and said, \"Are you Asahel?\" He answered, \"I am.\"", 
                "num": 20
              }, 
              {
                "text": "And Abner said to him, \"Turn aside to your right hand or to your left, and lay hold on one of the young men and take his armor for yourself.\" But Asahel would not turn aside from following him.", 
                "num": 21
              }, 
              {
                "text": "So Abner said again to Asahel, \"Turn aside from following me. Why should I strike you to the ground? How then could I face your brother Joab?\"", 
                "num": 22
              }, 
              {
                "text": "However, he refused to turn aside. Therefore Abner struck him in the stomach with the blunt end of the spear, so that the spear came out of his back; and he fell down there and died on the spot. So it was that as many as came to the place where Asahel fell down and died, stood still.", 
                "num": 23
              }, 
              {
                "text": "Joab and Abishai also pursued Abner. And the sun was going down when they came to the hill of Ammah, which is before Giah by the road to the Wilderness of Gibeon.", 
                "num": 24
              }, 
              {
                "text": "Now the children of Benjamin gathered together behind Abner and became a unit, and took their stand on top of a hill.", 
                "num": 25
              }, 
              {
                "text": "Then Abner called to Joab and said, \"Shall the sword devour forever? Do you not know that it will be bitter in the latter end? How long will it be then until you tell the people to return from pursuing their brethren?\"", 
                "num": 26
              }, 
              {
                "text": "And Joab said, \"As God lives, unless you had spoken, surely then by morning all the people would have given up pursuing their brethren.\"", 
                "num": 27
              }, 
              {
                "text": "So Joab blew a trumpet; and all the people stood still and did not pursue Israel anymore, nor did they fight anymore.", 
                "num": 28
              }, 
              {
                "text": "Then Abner and his men went on all that night through the plain, crossed over the Jordan, and went through all Bithron; and they came to Mahanaim.", 
                "num": 29
              }, 
              {
                "text": "So Joab returned from pursuing Abner. And when he had gathered all the people together, there were missing of David's servants nineteen men and Asahel.", 
                "num": 30
              }, 
              {
                "text": "But the servants of David had struck down, of Benjamin and Abner's men, three hundred and sixty men who died.", 
                "num": 31
              }, 
              {
                "text": "Then they took up Asahel and buried him in his father's tomb, which was in Bethlehem. And Joab and his men went all night, and they came to Hebron at daybreak.", 
                "num": 32
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now there was a long war between the house of Saul and the house of David. But David grew stronger and stronger, and the house of Saul grew weaker and weaker.", 
                "num": 1
              }, 
              {
                "text": "Sons were born to David in Hebron: His firstborn was Amnon by Ahinoam the Jezreelitess;", 
                "num": 2
              }, 
              {
                "text": "his second, Chileab, by Abigail the widow of Nabal the Carmelite; the third, Absalom the son of Maacah, the daughter of Talmai, king of Geshur;", 
                "num": 3
              }, 
              {
                "text": "the fourth, Adonijah the son of Haggith; the fifth, Shephatiah the son of Abital;", 
                "num": 4
              }, 
              {
                "text": "and the sixth, Ithream, by David's wife Eglah. These were born to David in Hebron.", 
                "num": 5
              }, 
              {
                "text": "Now it was so, while there was war between the house of Saul and the house of David, that Abner was strengthening his hold on the house of Saul.", 
                "num": 6
              }, 
              {
                "text": "And Saul had a concubine, whose name was Rizpah, the daughter of Aiah. So Ishbosheth said to Abner, \"Why have you gone in to my father's concubine?\"", 
                "num": 7
              }, 
              {
                "text": "Then Abner became very angry at the words of Ishbosheth, and said, \"Am I a dog's head that belongs to Judah? Today I show loyalty to the house of Saul your father, to his brothers, and to his friends, and have not delivered you into the hand of David; and you charge me today with a fault concerning this woman?", 
                "num": 8
              }, 
              {
                "text": "May God do so to Abner, and more also, if I do not do for David as the LORD has sworn to him--", 
                "num": 9
              }, 
              {
                "text": "to transfer the kingdom from the house of Saul, and set up the throne of David over Israel and over Judah, from Dan to Beersheba.\"", 
                "num": 10
              }, 
              {
                "text": "And he could not answer Abner another word, because he feared him.", 
                "num": 11
              }, 
              {
                "text": "Then Abner sent messengers on his behalf to David, saying, \"Whose is the land?\" saying also, \"Make your covenant with me, and indeed my hand shall be with you to bring all Israel to you.\"", 
                "num": 12
              }, 
              {
                "text": "And David said, \"Good, I will make a covenant with you. But one thing I require of you: you shall not see my face unless you first bring Michal, Saul's daughter, when you come to see my face.\"", 
                "num": 13
              }, 
              {
                "text": "So David sent messengers to Ishbosheth, Saul's son, saying, \"Give me my wife Michal, whom I betrothed to myself for a hundred foreskins of the Philistines.\"", 
                "num": 14
              }, 
              {
                "text": "And Ishbosheth sent and took her from her husband, from Paltiel the son of Laish.", 
                "num": 15
              }, 
              {
                "text": "Then her husband went along with her to Bahurim, weeping behind her. So Abner said to him, \"Go, return!\" And he returned.", 
                "num": 16
              }, 
              {
                "text": "Now Abner had communicated with the elders of Israel, saying, \"In time past you were seeking for David to be king over you.", 
                "num": 17
              }, 
              {
                "text": "Now then, do it! For the LORD has spoken of David, saying, \"By the hand of My servant David, I will save My people Israel from the hand of the Philistines and the hand of all their enemies.\"'", 
                "num": 18
              }, 
              {
                "text": "And Abner also spoke in the hearing of Benjamin. Then Abner also went to speak in the hearing of David in Hebron all that seemed good to Israel and the whole house of Benjamin.", 
                "num": 19
              }, 
              {
                "text": "So Abner and twenty men with him came to David at Hebron. And David made a feast for Abner and the men who were with him.", 
                "num": 20
              }, 
              {
                "text": "Then Abner said to David, \"I will arise and go, and gather all Israel to my lord the king, that they may make a covenant with you, and that you may reign over all that your heart desires.\" So David sent Abner away, and he went in peace.", 
                "num": 21
              }, 
              {
                "text": "At that moment the servants of David and Joab came from a raid and brought much spoil with them. But Abner was not with David in Hebron, for he had sent him away, and he had gone in peace.", 
                "num": 22
              }, 
              {
                "text": "When Joab and all the troops that were with him had come, they told Joab, saying, \"Abner the son of Ner came to the king, and he sent him away, and he has gone in peace.\"", 
                "num": 23
              }, 
              {
                "text": "Then Joab came to the king and said, \"What have you done? Look, Abner came to you; why is it that you sent him away, and he has already gone?", 
                "num": 24
              }, 
              {
                "text": "Surely you realize that Abner the son of Ner came to deceive you, to know your going out and your coming in, and to know all that you are doing.\"", 
                "num": 25
              }, 
              {
                "text": "And when Joab had gone from David's presence, he sent messengers after Abner, who brought him back from the well of Sirah. But David did not know it.", 
                "num": 26
              }, 
              {
                "text": "Now when Abner had returned to Hebron, Joab took him aside in the gate to speak with him privately, and there stabbed him in the stomach, so that he died for the blood of Asahel his brother.", 
                "num": 27
              }, 
              {
                "text": "Afterward, when David heard it, he said, \"My kingdom and I are guiltless before the LORD forever of the blood of Abner the son of Ner.", 
                "num": 28
              }, 
              {
                "text": "Let it rest on the head of Joab and on all his father's house; and let there never fail to be in the house of Joab one who has a discharge or is a leper, who leans on a staff or falls by the sword, or who lacks bread.\"", 
                "num": 29
              }, 
              {
                "text": "So Joab and Abishai his brother killed Abner, because he had killed their brother Asahel at Gibeon in the battle.", 
                "num": 30
              }, 
              {
                "text": "Then David said to Joab and to all the people who were with him, \"Tear your clothes, gird yourselves with sackcloth, and mourn for Abner.\" And King David followed the coffin.", 
                "num": 31
              }, 
              {
                "text": "So they buried Abner in Hebron; and the king lifted up his voice and wept at the grave of Abner, and all the people wept.", 
                "num": 32
              }, 
              {
                "text": "And the king sang a lament over Abner and said: \"Should Abner die as a fool dies?", 
                "num": 33
              }, 
              {
                "text": "Your hands were not bound Nor your feet put into fetters; As a man falls before wicked men, so you fell.\" Then all the people wept over him again.", 
                "num": 34
              }, 
              {
                "text": "And when all the people came to persuade David to eat food while it was still day, David took an oath, saying, \"God do so to me, and more also, if I taste bread or anything else till the sun goes down!\"", 
                "num": 35
              }, 
              {
                "text": "Now all the people took note of it, and it pleased them, since whatever the king did pleased all the people.", 
                "num": 36
              }, 
              {
                "text": "For all the people and all Israel understood that day that it had not been the king's intent to kill Abner the son of Ner.", 
                "num": 37
              }, 
              {
                "text": "Then the king said to his servants, \"Do you not know that a prince and a great man has fallen this day in Israel?", 
                "num": 38
              }, 
              {
                "text": "And I am weak today, though anointed king; and these men, the sons of Zeruiah, are too harsh for me. The LORD shall repay the evildoer according to his wickedness.\"", 
                "num": 39
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "When Saul's son heard that Abner had died in Hebron, he lost heart, and all Israel was troubled.", 
                "num": 1
              }, 
              {
                "text": "Now Saul's son had two men who were captains of troops. The name of one was Baanah and the name of the other Rechab, the sons of Rimmon the Beerothite, of the children of Benjamin. (For Beeroth also was part of Benjamin,", 
                "num": 2
              }, 
              {
                "text": "because the Beerothites fled to Gittaim and have been sojourners there until this day.)", 
                "num": 3
              }, 
              {
                "text": "Jonathan, Saul's son, had a son who was lame in his feet. He was five years old when the news about Saul and Jonathan came from Jezreel; and his nurse took him up and fled. And it happened, as she made haste to flee, that he fell and became lame. His name was Mephibosheth.", 
                "num": 4
              }, 
              {
                "text": "Then the sons of Rimmon the Beerothite, Rechab and Baanah, set out and came at about the heat of the day to the house of Ishbosheth, who was lying on his bed at noon.", 
                "num": 5
              }, 
              {
                "text": "And they came there, all the way into the house, as though to get wheat, and they stabbed him in the stomach. Then Rechab and Baanah his brother escaped.", 
                "num": 6
              }, 
              {
                "text": "For when they came into the house, he was lying on his bed in his bedroom; then they struck him and killed him, beheaded him and took his head, and were all night escaping through the plain.", 
                "num": 7
              }, 
              {
                "text": "And they brought the head of Ishbosheth to David at Hebron, and said to the king, \"Here is the head of Ishbosheth, the son of Saul your enemy, who sought your life; and the LORD has avenged my lord the king this day of Saul and his descendants.\"", 
                "num": 8
              }, 
              {
                "text": "But David answered Rechab and Baanah his brother, the sons of Rimmon the Beerothite, and said to them, \"As the LORD lives, who has redeemed my life from all adversity,", 
                "num": 9
              }, 
              {
                "text": "when someone told me, saying, \"Look, Saul is dead,' thinking to have brought good news, I arrested him and had him executed in Ziklag--the one who thought I would give him a reward for his news.", 
                "num": 10
              }, 
              {
                "text": "How much more, when wicked men have killed a righteous person in his own house on his bed? Therefore, shall I not now require his blood at your hand and remove you from the earth?\"", 
                "num": 11
              }, 
              {
                "text": "So David commanded his young men, and they executed them, cut off their hands and feet, and hanged them by the pool in Hebron. But they took the head of Ishbosheth and buried it in the tomb of Abner in Hebron.", 
                "num": 12
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then all the tribes of Israel came to David at Hebron and spoke, saying, \"Indeed we are your bone and your flesh.", 
                "num": 1
              }, 
              {
                "text": "Also, in time past, when Saul was king over us, you were the one who led Israel out and brought them in; and the LORD said to you, \"You shall shepherd My people Israel, and be ruler over Israel.\"'", 
                "num": 2
              }, 
              {
                "text": "Therefore all the elders of Israel came to the king at Hebron, and King David made a covenant with them at Hebron before the LORD. And they anointed David king over Israel.", 
                "num": 3
              }, 
              {
                "text": "David was thirty years old when he began to reign, and he reigned forty years.", 
                "num": 4
              }, 
              {
                "text": "In Hebron he reigned over Judah seven years and six months, and in Jerusalem he reigned thirty-three years over all Israel and Judah.", 
                "num": 5
              }, 
              {
                "text": "And the king and his men went to Jerusalem against the Jebusites, the inhabitants of the land, who spoke to David, saying, \"You shall not come in here; but the blind and the lame will repel you,\" thinking, \"David cannot come in here.\"", 
                "num": 6
              }, 
              {
                "text": "Nevertheless David took the stronghold of Zion (that is, the City of David).", 
                "num": 7
              }, 
              {
                "text": "Now David said on that day, \"Whoever climbs up by way of the water shaft and defeats the Jebusites (the lame and the blind, who are hated by David's soul), he shall be chief and captain.\" Therefore they say, \"The blind and the lame shall not come into the house.\"", 
                "num": 8
              }, 
              {
                "text": "Then David dwelt in the stronghold, and called it the City of David. And David built all around from the Millo and inward.", 
                "num": 9
              }, 
              {
                "text": "So David went on and became great, and the LORD God of hosts was with him.", 
                "num": 10
              }, 
              {
                "text": "Then Hiram king of Tyre sent messengers to David, and cedar trees, and carpenters and masons. And they built David a house.", 
                "num": 11
              }, 
              {
                "text": "So David knew that the LORD had established him as king over Israel, and that He had exalted His kingdom for the sake of His people Israel.", 
                "num": 12
              }, 
              {
                "text": "And David took more concubines and wives from Jerusalem, after he had come from Hebron. Also more sons and daughters were born to David.", 
                "num": 13
              }, 
              {
                "text": "Now these are the names of those who were born to him in Jerusalem: Shammua, Shobab, Nathan, Solomon,", 
                "num": 14
              }, 
              {
                "text": "Ibhar, Elishua, Nepheg, Japhia,", 
                "num": 15
              }, 
              {
                "text": "Elishama, Eliada, and Eliphelet.", 
                "num": 16
              }, 
              {
                "text": "Now when the Philistines heard that they had anointed David king over Israel, all the Philistines went up to search for David. And David heard of it and went down to the stronghold.", 
                "num": 17
              }, 
              {
                "text": "The Philistines also went and deployed themselves in the Valley of Rephaim.", 
                "num": 18
              }, 
              {
                "text": "So David inquired of the LORD, saying, \"Shall I go up against the Philistines? Will You deliver them into my hand?\" And the LORD said to David, \"Go up, for I will doubtless deliver the Philistines into your hand.\"", 
                "num": 19
              }, 
              {
                "text": "So David went to Baal Perazim, and David defeated them there; and he said, \"The LORD has broken through my enemies before me, like a breakthrough of water.\" Therefore he called the name of that place Baal Perazim.", 
                "num": 20
              }, 
              {
                "text": "And they left their images there, and David and his men carried them away.", 
                "num": 21
              }, 
              {
                "text": "Then the Philistines went up once again and deployed themselves in the Valley of Rephaim.", 
                "num": 22
              }, 
              {
                "text": "Therefore David inquired of the LORD, and He said, \"You shall not go up; circle around behind them, and come upon them in front of the mulberry trees.", 
                "num": 23
              }, 
              {
                "text": "And it shall be, when you hear the sound of marching in the tops of the mulberry trees, then you shall advance quickly. For then the LORD will go out before you to strike the camp of the Philistines.\"", 
                "num": 24
              }, 
              {
                "text": "And David did so, as the LORD commanded him; and he drove back the Philistines from Geba as far as Gezer.", 
                "num": 25
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Again David gathered all the choice men of Israel, thirty thousand.", 
                "num": 1
              }, 
              {
                "text": "And David arose and went with all the people who were with him from Baale Judah to bring up from there the ark of God, whose name is called by the Name, the LORD of Hosts, who dwells between the cherubim.", 
                "num": 2
              }, 
              {
                "text": "So they set the ark of God on a new cart, and brought it out of the house of Abinadab, which was on the hill; and Uzzah and Ahio, the sons of Abinadab, drove the new cart.", 
                "num": 3
              }, 
              {
                "text": "And they brought it out of the house of Abinadab, which was on the hill, accompanying the ark of God; and Ahio went before the ark.", 
                "num": 4
              }, 
              {
                "text": "Then David and all the house of Israel played music before the LORD on all kinds of instruments of fir wood, on harps, on stringed instruments, on tambourines, on sistrums, and on cymbals.", 
                "num": 5
              }, 
              {
                "text": "And when they came to Nachon's threshing floor, Uzzah put out his hand to the ark of God and took hold of it, for the oxen stumbled.", 
                "num": 6
              }, 
              {
                "text": "Then the anger of the LORD was aroused against Uzzah, and God struck him there for his error; and he died there by the ark of God.", 
                "num": 7
              }, 
              {
                "text": "And David became angry because of the LORD's outbreak against Uzzah; and he called the name of the place Perez Uzzah to this day.", 
                "num": 8
              }, 
              {
                "text": "David was afraid of the LORD that day; and he said, \"How can the ark of the LORD come to me?\"", 
                "num": 9
              }, 
              {
                "text": "So David would not move the ark of the LORD with him into the City of David; but David took it aside into the house of Obed-Edom the Gittite.", 
                "num": 10
              }, 
              {
                "text": "The ark of the LORD remained in the house of Obed-Edom the Gittite three months. And the LORD blessed Obed-Edom and all his household.", 
                "num": 11
              }, 
              {
                "text": "Now it was told King David, saying, \"The LORD has blessed the house of Obed-Edom and all that belongs to him, because of the ark of God.\" So David went and brought up the ark of God from the house of Obed-Edom to the City of David with gladness.", 
                "num": 12
              }, 
              {
                "text": "And so it was, when those bearing the ark of the LORD had gone six paces, that he sacrificed oxen and fatted sheep.", 
                "num": 13
              }, 
              {
                "text": "Then David danced before the LORD with all his might; and David was wearing a linen ephod.", 
                "num": 14
              }, 
              {
                "text": "So David and all the house of Israel brought up the ark of the LORD with shouting and with the sound of the trumpet.", 
                "num": 15
              }, 
              {
                "text": "Now as the ark of the LORD came into the City of David, Michal, Saul's daughter, looked through a window and saw King David leaping and whirling before the LORD; and she despised him in her heart.", 
                "num": 16
              }, 
              {
                "text": "So they brought the ark of the LORD, and set it in its place in the midst of the tabernacle that David had erected for it. Then David offered burnt offerings and peace offerings before the LORD.", 
                "num": 17
              }, 
              {
                "text": "And when David had finished offering burnt offerings and peace offerings, he blessed the people in the name of the LORD of hosts.", 
                "num": 18
              }, 
              {
                "text": "Then he distributed among all the people, among the whole multitude of Israel, both the women and the men, to everyone a loaf of bread, a piece of meat, and a cake of raisins. So all the people departed, everyone to his house.", 
                "num": 19
              }, 
              {
                "text": "Then David returned to bless his household. And Michal the daughter of Saul came out to meet David, and said, \"How glorious was the king of Israel today, uncovering himself today in the eyes of the maids of his servants, as one of the base fellows shamelessly uncovers himself!\"", 
                "num": 20
              }, 
              {
                "text": "So David said to Michal, \"It was before the LORD, who chose me instead of your father and all his house, to appoint me ruler over the people of the LORD, over Israel. Therefore I will play music before the LORD.", 
                "num": 21
              }, 
              {
                "text": "And I will be even more undignified than this, and will be humble in my own sight. But as for the maidservants of whom you have spoken, by them I will be held in honor.\"", 
                "num": 22
              }, 
              {
                "text": "Therefore Michal the daughter of Saul had no children to the day of her death.", 
                "num": 23
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass when the king was dwelling in his house, and the LORD had given him rest from all his enemies all around,", 
                "num": 1
              }, 
              {
                "text": "that the king said to Nathan the prophet, \"See now, I dwell in a house of cedar, but the ark of God dwells inside tent curtains.\"", 
                "num": 2
              }, 
              {
                "text": "Then Nathan said to the king, \"Go, do all that is in your heart, for the LORD is with you.\"", 
                "num": 3
              }, 
              {
                "text": "But it happened that night that the word of the LORD came to Nathan, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Go and tell My servant David, \"Thus says the LORD: \"Would you build a house for Me to dwell in?", 
                "num": 5
              }, 
              {
                "text": "For I have not dwelt in a house since the time that I brought the children of Israel up from Egypt, even to this day, but have moved about in a tent and in a tabernacle.", 
                "num": 6
              }, 
              {
                "text": "Wherever I have moved about with all the children of Israel, have I ever spoken a word to anyone from the tribes of Israel, whom I commanded to shepherd My people Israel, saying, \"Why have you not built Me a house of cedar?\"\"", 
                "num": 7
              }, 
              {
                "text": "Now therefore, thus shall you say to My servant David, \"Thus says the LORD of hosts: \"I took you from the sheepfold, from following the sheep, to be ruler over My people, over Israel.", 
                "num": 8
              }, 
              {
                "text": "And I have been with you wherever you have gone, and have cut off all your enemies from before you, and have made you a great name, like the name of the great men who are on the earth.", 
                "num": 9
              }, 
              {
                "text": "Moreover I will appoint a place for My people Israel, and will plant them, that they may dwell in a place of their own and move no more; nor shall the sons of wickedness oppress them anymore, as previously,", 
                "num": 10
              }, 
              {
                "text": "since the time that I commanded judges to be over My people Israel, and have caused you to rest from all your enemies. Also the LORD tells you that He will make you a house.", 
                "num": 11
              }, 
              {
                "text": "\"When your days are fulfilled and you rest with your fathers, I will set up your seed after you, who will come from your body, and I will establish his kingdom.", 
                "num": 12
              }, 
              {
                "text": "He shall build a house for My name, and I will establish the throne of his kingdom forever.", 
                "num": 13
              }, 
              {
                "text": "I will be his Father, and he shall be My son. If he commits iniquity, I will chasten him with the rod of men and with the blows of the sons of men.", 
                "num": 14
              }, 
              {
                "text": "But My mercy shall not depart from him, as I took it from Saul, whom I removed from before you.", 
                "num": 15
              }, 
              {
                "text": "And your house and your kingdom shall be established forever before you. Your throne shall be established forever.\"\"'", 
                "num": 16
              }, 
              {
                "text": "According to all these words and according to all this vision, so Nathan spoke to David.", 
                "num": 17
              }, 
              {
                "text": "Then King David went in and sat before the LORD; and he said: \"Who am I, O Lord GOD? And what is my house, that You have brought me this far?", 
                "num": 18
              }, 
              {
                "text": "And yet this was a small thing in Your sight, O Lord GOD; and You have also spoken of Your servant's house for a great while to come. Is this the manner of man, O Lord GOD?", 
                "num": 19
              }, 
              {
                "text": "Now what more can David say to You? For You, Lord GOD, know Your servant.", 
                "num": 20
              }, 
              {
                "text": "For Your word's sake, and according to Your own heart, You have done all these great things, to make Your servant know them.", 
                "num": 21
              }, 
              {
                "text": "Therefore You are great, O Lord GOD. For there is none like You, nor is there any God besides You, according to all that we have heard with our ears.", 
                "num": 22
              }, 
              {
                "text": "And who is like Your people, like Israel, the one nation on the earth whom God went to redeem for Himself as a people, to make for Himself a name--and to do for Yourself great and awesome deeds for Your land--before Your people whom You redeemed for Yourself from Egypt, the nations, and their gods?", 
                "num": 23
              }, 
              {
                "text": "For You have made Your people Israel Your very own people forever; and You, LORD, have become their God.", 
                "num": 24
              }, 
              {
                "text": "\"Now, O LORD God, the word which You have spoken concerning Your servant and concerning his house, establish it forever and do as You have said.", 
                "num": 25
              }, 
              {
                "text": "So let Your name be magnified forever, saying, \"The LORD of hosts is the God over Israel.' And let the house of Your servant David be established before You.", 
                "num": 26
              }, 
              {
                "text": "For You, O LORD of hosts, God of Israel, have revealed this to Your servant, saying, \"I will build you a house.' Therefore Your servant has found it in his heart to pray this prayer to You.", 
                "num": 27
              }, 
              {
                "text": "\"And now, O Lord GOD, You are God, and Your words are true, and You have promised this goodness to Your servant.", 
                "num": 28
              }, 
              {
                "text": "Now therefore, let it please You to bless the house of Your servant, that it may continue before You forever; for You, O Lord GOD, have spoken it, and with Your blessing let the house of Your servant be blessed forever.\"", 
                "num": 29
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "After this it came to pass that David attacked the Philistines and subdued them. And David took Metheg Ammah from the hand of the Philistines.", 
                "num": 1
              }, 
              {
                "text": "Then he defeated Moab. Forcing them down to the ground, he measured them off with a line. With two lines he measured off those to be put to death, and with one full line those to be kept alive. So the Moabites became David's servants, and brought tribute.", 
                "num": 2
              }, 
              {
                "text": "David also defeated Hadadezer the son of Rehob, king of Zobah, as he went to recover his territory at the River Euphrates.", 
                "num": 3
              }, 
              {
                "text": "David took from him one thousand chariots, seven hundred horsemen, and twenty thousand foot soldiers. Also David hamstrung all the chariot horses, except that he spared enough of them for one hundred chariots.", 
                "num": 4
              }, 
              {
                "text": "When the Syrians of Damascus came to help Hadadezer king of Zobah, David killed twenty-two thousand of the Syrians.", 
                "num": 5
              }, 
              {
                "text": "Then David put garrisons in Syria of Damascus; and the Syrians became David's servants, and brought tribute. So the LORD preserved David wherever he went.", 
                "num": 6
              }, 
              {
                "text": "And David took the shields of gold that had belonged to the servants of Hadadezer, and brought them to Jerusalem.", 
                "num": 7
              }, 
              {
                "text": "Also from Betah and from Berothai, cities of Hadadezer, King David took a large amount of bronze.", 
                "num": 8
              }, 
              {
                "text": "When Toi king of Hamath heard that David had defeated all the army of Hadadezer,", 
                "num": 9
              }, 
              {
                "text": "then Toi sent Joram his son to King David, to greet him and bless him, because he had fought against Hadadezer and defeated him (for Hadadezer had been at war with Toi); and Joram brought with him articles of silver, articles of gold, and articles of bronze.", 
                "num": 10
              }, 
              {
                "text": "King David also dedicated these to the LORD, along with the silver and gold that he had dedicated from all the nations which he had subdued--", 
                "num": 11
              }, 
              {
                "text": "from Syria, from Moab, from the people of Ammon, from the Philistines, from Amalek, and from the spoil of Hadadezer the son of Rehob, king of Zobah.", 
                "num": 12
              }, 
              {
                "text": "And David made himself a name when he returned from killing eighteen thousand Syrians in the Valley of Salt.", 
                "num": 13
              }, 
              {
                "text": "He also put garrisons in Edom; throughout all Edom he put garrisons, and all the Edomites became David's servants. And the LORD preserved David wherever he went.", 
                "num": 14
              }, 
              {
                "text": "So David reigned over all Israel; and David administered judgment and justice to all his people.", 
                "num": 15
              }, 
              {
                "text": "Joab the son of Zeruiah was over the army; Jehoshaphat the son of Ahilud was recorder;", 
                "num": 16
              }, 
              {
                "text": "Zadok the son of Ahitub and Ahimelech the son of Abiathar were the priests; Seraiah was the scribe;", 
                "num": 17
              }, 
              {
                "text": "Benaiah the son of Jehoiada was over both the Cherethites and the Pelethites; and David's sons were chief ministers.", 
                "num": 18
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now David said, \"Is there still anyone who is left of the house of Saul, that I may show him kindness for Jonathan's sake?\"", 
                "num": 1
              }, 
              {
                "text": "And there was a servant of the house of Saul whose name was Ziba. So when they had called him to David, the king said to him, \"Are you Ziba?\" He said, \"At your service!\"", 
                "num": 2
              }, 
              {
                "text": "Then the king said, \"Is there not still someone of the house of Saul, to whom I may show the kindness of God?\" And Ziba said to the king, \"There is still a son of Jonathan who is lame in his feet.\"", 
                "num": 3
              }, 
              {
                "text": "So the king said to him, \"Where is he?\" And Ziba said to the king, \"Indeed he is in the house of Machir the son of Ammiel, in Lo Debar.\"", 
                "num": 4
              }, 
              {
                "text": "Then King David sent and brought him out of the house of Machir the son of Ammiel, from Lo Debar.", 
                "num": 5
              }, 
              {
                "text": "Now when Mephibosheth the son of Jonathan, the son of Saul, had come to David, he fell on his face and prostrated himself. Then David said, \"Mephibosheth?\" And he answered, \"Here is your servant!\"", 
                "num": 6
              }, 
              {
                "text": "So David said to him, \"Do not fear, for I will surely show you kindness for Jonathan your father's sake, and will restore to you all the land of Saul your grandfather; and you shall eat bread at my table continually.\"", 
                "num": 7
              }, 
              {
                "text": "Then he bowed himself, and said, \"What is your servant, that you should look upon such a dead dog as I?\"", 
                "num": 8
              }, 
              {
                "text": "And the king called to Ziba, Saul's servant, and said to him, \"I have given to your master's son all that belonged to Saul and to all his house.", 
                "num": 9
              }, 
              {
                "text": "You therefore, and your sons and your servants, shall work the land for him, and you shall bring in the harvest, that your master's son may have food to eat. But Mephibosheth your master's son shall eat bread at my table always.\" Now Ziba had fifteen sons and twenty servants.", 
                "num": 10
              }, 
              {
                "text": "Then Ziba said to the king, \"According to all that my lord the king has commanded his servant, so will your servant do.\" \"As for Mephibosheth,\" said the king, \"he shall eat at my table like one of the king's sons.\"", 
                "num": 11
              }, 
              {
                "text": "Mephibosheth had a young son whose name was Micha. And all who dwelt in the house of Ziba were servants of Mephibosheth.", 
                "num": 12
              }, 
              {
                "text": "So Mephibosheth dwelt in Jerusalem, for he ate continually at the king's table. And he was lame in both his feet.", 
                "num": 13
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "It happened after this that the king of the people of Ammon died, and Hanun his son reigned in his place.", 
                "num": 1
              }, 
              {
                "text": "Then David said, \"I will show kindness to Hanun the son of Nahash, as his father showed kindness to me.\" So David sent by the hand of his servants to comfort him concerning his father. And David's servants came into the land of the people of Ammon.", 
                "num": 2
              }, 
              {
                "text": "And the princes of the people of Ammon said to Hanun their lord, \"Do you think that David really honors your father because he has sent comforters to you? Has David not rather sent his servants to you to search the city, to spy it out, and to overthrow it?\"", 
                "num": 3
              }, 
              {
                "text": "Therefore Hanun took David's servants, shaved off half of their beards, cut off their garments in the middle, at their buttocks, and sent them away.", 
                "num": 4
              }, 
              {
                "text": "When they told David, he sent to meet them, because the men were greatly ashamed. And the king said, \"Wait at Jericho until your beards have grown, and then return.\"", 
                "num": 5
              }, 
              {
                "text": "When the people of Ammon saw that they had made themselves repulsive to David, the people of Ammon sent and hired the Syrians of Beth Rehob and the Syrians of Zoba, twenty thousand foot soldiers; and from the king of Maacah one thousand men, and from Ish-Tob twelve thousand men.", 
                "num": 6
              }, 
              {
                "text": "Now when David heard of it, he sent Joab and all the army of the mighty men.", 
                "num": 7
              }, 
              {
                "text": "Then the people of Ammon came out and put themselves in battle array at the entrance of the gate. And the Syrians of Zoba, Beth Rehob, Ish-Tob, and Maacah were by themselves in the field.", 
                "num": 8
              }, 
              {
                "text": "When Joab saw that the battle line was against him before and behind, he chose some of Israel's best and put them in battle array against the Syrians.", 
                "num": 9
              }, 
              {
                "text": "And the rest of the people he put under the command of Abishai his brother, that he might set them in battle array against the people of Ammon.", 
                "num": 10
              }, 
              {
                "text": "Then he said, \"If the Syrians are too strong for me, then you shall help me; but if the people of Ammon are too strong for you, then I will come and help you.", 
                "num": 11
              }, 
              {
                "text": "Be of good courage, and let us be strong for our people and for the cities of our God. And may the LORD do what is good in His sight.\"", 
                "num": 12
              }, 
              {
                "text": "So Joab and the people who were with him drew near for the battle against the Syrians, and they fled before him.", 
                "num": 13
              }, 
              {
                "text": "When the people of Ammon saw that the Syrians were fleeing, they also fled before Abishai, and entered the city. So Joab returned from the people of Ammon and went to Jerusalem.", 
                "num": 14
              }, 
              {
                "text": "When the Syrians saw that they had been defeated by Israel, they gathered together.", 
                "num": 15
              }, 
              {
                "text": "Then Hadadezer sent and brought out the Syrians who were beyond the River, and they came to Helam. And Shobach the commander of Hadadezer's army went before them.", 
                "num": 16
              }, 
              {
                "text": "When it was told David, he gathered all Israel, crossed over the Jordan, and came to Helam. And the Syrians set themselves in battle array against David and fought with him.", 
                "num": 17
              }, 
              {
                "text": "Then the Syrians fled before Israel; and David killed seven hundred charioteers and forty thousand horsemen of the Syrians, and struck Shobach the commander of their army, who died there.", 
                "num": 18
              }, 
              {
                "text": "And when all the kings who were servants to Hadadezer saw that they were defeated by Israel, they made peace with Israel and served them. So the Syrians were afraid to help the people of Ammon anymore.", 
                "num": 19
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "It happened in the spring of the year, at the time when kings go out to battle, that David sent Joab and his servants with him, and all Israel; and they destroyed the people of Ammon and besieged Rabbah. But David remained at Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Then it happened one evening that David arose from his bed and walked on the roof of the king's house. And from the roof he saw a woman bathing, and the woman was very beautiful to behold.", 
                "num": 2
              }, 
              {
                "text": "So David sent and inquired about the woman. And someone said, \"Is this not Bathsheba, the daughter of Eliam, the wife of Uriah the Hittite?\"", 
                "num": 3
              }, 
              {
                "text": "Then David sent messengers, and took her; and she came to him, and he lay with her, for she was cleansed from her impurity; and she returned to her house.", 
                "num": 4
              }, 
              {
                "text": "And the woman conceived; so she sent and told David, and said, \"I am with child.\"", 
                "num": 5
              }, 
              {
                "text": "Then David sent to Joab, saying, \"Send me Uriah the Hittite.\" And Joab sent Uriah to David.", 
                "num": 6
              }, 
              {
                "text": "When Uriah had come to him, David asked how Joab was doing, and how the people were doing, and how the war prospered.", 
                "num": 7
              }, 
              {
                "text": "And David said to Uriah, \"Go down to your house and wash your feet.\" So Uriah departed from the king's house, and a gift of food from the king followed him.", 
                "num": 8
              }, 
              {
                "text": "But Uriah slept at the door of the king's house with all the servants of his lord, and did not go down to his house.", 
                "num": 9
              }, 
              {
                "text": "So when they told David, saying, \"Uriah did not go down to his house,\" David said to Uriah, \"Did you not come from a journey? Why did you not go down to your house?\"", 
                "num": 10
              }, 
              {
                "text": "And Uriah said to David, \"The ark and Israel and Judah are dwelling in tents, and my lord Joab and the servants of my lord are encamped in the open fields. Shall I then go to my house to eat and drink, and to lie with my wife? As you live, and as your soul lives, I will not do this thing.\"", 
                "num": 11
              }, 
              {
                "text": "Then David said to Uriah, \"Wait here today also, and tomorrow I will let you depart.\" So Uriah remained in Jerusalem that day and the next.", 
                "num": 12
              }, 
              {
                "text": "Now when David called him, he ate and drank before him; and he made him drunk. And at evening he went out to lie on his bed with the servants of his lord, but he did not go down to his house.", 
                "num": 13
              }, 
              {
                "text": "In the morning it happened that David wrote a letter to Joab and sent it by the hand of Uriah.", 
                "num": 14
              }, 
              {
                "text": "And he wrote in the letter, saying, \"Set Uriah in the forefront of the hottest battle, and retreat from him, that he may be struck down and die.\"", 
                "num": 15
              }, 
              {
                "text": "So it was, while Joab besieged the city, that he assigned Uriah to a place where he knew there were valiant men.", 
                "num": 16
              }, 
              {
                "text": "Then the men of the city came out and fought with Joab. And some of the people of the servants of David fell; and Uriah the Hittite died also.", 
                "num": 17
              }, 
              {
                "text": "Then Joab sent and told David all the things concerning the war,", 
                "num": 18
              }, 
              {
                "text": "and charged the messenger, saying, \"When you have finished telling the matters of the war to the king,", 
                "num": 19
              }, 
              {
                "text": "if it happens that the king's wrath rises, and he says to you: \"Why did you approach so near to the city when you fought? Did you not know that they would shoot from the wall?", 
                "num": 20
              }, 
              {
                "text": "Who struck Abimelech the son of Jerubbesheth? Was it not a woman who cast a piece of a millstone on him from the wall, so that he died in Thebez? Why did you go near the wall?'--then you shall say, \"Your servant Uriah the Hittite is dead also.\"'", 
                "num": 21
              }, 
              {
                "text": "So the messenger went, and came and told David all that Joab had sent by him.", 
                "num": 22
              }, 
              {
                "text": "And the messenger said to David, \"Surely the men prevailed against us and came out to us in the field; then we drove them back as far as the entrance of the gate.", 
                "num": 23
              }, 
              {
                "text": "The archers shot from the wall at your servants; and some of the king's servants are dead, and your servant Uriah the Hittite is dead also.\"", 
                "num": 24
              }, 
              {
                "text": "Then David said to the messenger, \"Thus you shall say to Joab: \"Do not let this thing displease you, for the sword devours one as well as another. Strengthen your attack against the city, and overthrow it.' So encourage him.\"", 
                "num": 25
              }, 
              {
                "text": "When the wife of Uriah heard that Uriah her husband was dead, she mourned for her husband.", 
                "num": 26
              }, 
              {
                "text": "And when her mourning was over, David sent and brought her to his house, and she became his wife and bore him a son. But the thing that David had done displeased the LORD.", 
                "num": 27
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD sent Nathan to David. And he came to him, and said to him: \"There were two men in one city, one rich and the other poor.", 
                "num": 1
              }, 
              {
                "text": "The rich man had exceedingly many flocks and herds.", 
                "num": 2
              }, 
              {
                "text": "But the poor man had nothing, except one little ewe lamb which he had bought and nourished; and it grew up together with him and with his children. It ate of his own food and drank from his own cup and lay in his bosom; and it was like a daughter to him.", 
                "num": 3
              }, 
              {
                "text": "And a traveler came to the rich man, who refused to take from his own flock and from his own herd to prepare one for the wayfaring man who had come to him; but he took the poor man's lamb and prepared it for the man who had come to him.\"", 
                "num": 4
              }, 
              {
                "text": "So David's anger was greatly aroused against the man, and he said to Nathan, \"As the LORD lives, the man who has done this shall surely die!", 
                "num": 5
              }, 
              {
                "text": "And he shall restore fourfold for the lamb, because he did this thing and because he had no pity.\"", 
                "num": 6
              }, 
              {
                "text": "Then Nathan said to David, \"You are the man! Thus says the LORD God of Israel: \"I anointed you king over Israel, and I delivered you from the hand of Saul.", 
                "num": 7
              }, 
              {
                "text": "I gave you your master's house and your master's wives into your keeping, and gave you the house of Israel and Judah. And if that had been too little, I also would have given you much more!", 
                "num": 8
              }, 
              {
                "text": "Why have you despised the commandment of the LORD, to do evil in His sight? You have killed Uriah the Hittite with the sword; you have taken his wife to be your wife, and have killed him with the sword of the people of Ammon.", 
                "num": 9
              }, 
              {
                "text": "Now therefore, the sword shall never depart from your house, because you have despised Me, and have taken the wife of Uriah the Hittite to be your wife.'", 
                "num": 10
              }, 
              {
                "text": "Thus says the LORD: \"Behold, I will raise up adversity against you from your own house; and I will take your wives before your eyes and give them to your neighbor, and he shall lie with your wives in the sight of this sun.", 
                "num": 11
              }, 
              {
                "text": "For you did it secretly, but I will do this thing before all Israel, before the sun.\"'", 
                "num": 12
              }, 
              {
                "text": "So David said to Nathan, \"I have sinned against the LORD.\" And Nathan said to David, \"The LORD also has put away your sin; you shall not die.", 
                "num": 13
              }, 
              {
                "text": "However, because by this deed you have given great occasion to the enemies of the LORD to blaspheme, the child also who is born to you shall surely die.\"", 
                "num": 14
              }, 
              {
                "text": "Then Nathan departed to his house. And the LORD struck the child that Uriah's wife bore to David, and it became ill.", 
                "num": 15
              }, 
              {
                "text": "David therefore pleaded with God for the child, and David fasted and went in and lay all night on the ground.", 
                "num": 16
              }, 
              {
                "text": "So the elders of his house arose and went to him, to raise him up from the ground. But he would not, nor did he eat food with them.", 
                "num": 17
              }, 
              {
                "text": "Then on the seventh day it came to pass that the child died. And the servants of David were afraid to tell him that the child was dead. For they said, \"Indeed, while the child was alive, we spoke to him, and he would not heed our voice. How can we tell him that the child is dead? He may do some harm!\"", 
                "num": 18
              }, 
              {
                "text": "When David saw that his servants were whispering, David perceived that the child was dead. Therefore David said to his servants, \"Is the child dead?\" And they said, \"He is dead.\"", 
                "num": 19
              }, 
              {
                "text": "So David arose from the ground, washed and anointed himself, and changed his clothes; and he went into the house of the LORD and worshiped. Then he went to his own house; and when he requested, they set food before him, and he ate.", 
                "num": 20
              }, 
              {
                "text": "Then his servants said to him, \"What is this that you have done? You fasted and wept for the child while he was alive, but when the child died, you arose and ate food.\"", 
                "num": 21
              }, 
              {
                "text": "And he said, \"While the child was alive, I fasted and wept; for I said, \"Who can tell whether the LORD will be gracious to me, that the child may live?'", 
                "num": 22
              }, 
              {
                "text": "But now he is dead; why should I fast? Can I bring him back again? I shall go to him, but he shall not return to me.\"", 
                "num": 23
              }, 
              {
                "text": "Then David comforted Bathsheba his wife, and went in to her and lay with her. So she bore a son, and he called his name Solomon. Now the LORD loved him,", 
                "num": 24
              }, 
              {
                "text": "and He sent word by the hand of Nathan the prophet: So he called his name Jedidiah, because of the LORD.", 
                "num": 25
              }, 
              {
                "text": "Now Joab fought against Rabbah of the people of Ammon, and took the royal city.", 
                "num": 26
              }, 
              {
                "text": "And Joab sent messengers to David, and said, \"I have fought against Rabbah, and I have taken the city's water supply.", 
                "num": 27
              }, 
              {
                "text": "Now therefore, gather the rest of the people together and encamp against the city and take it, lest I take the city and it be called after my name.\"", 
                "num": 28
              }, 
              {
                "text": "So David gathered all the people together and went to Rabbah, fought against it, and took it.", 
                "num": 29
              }, 
              {
                "text": "Then he took their king's crown from his head. Its weight was a talent of gold, with precious stones. And it was set on David's head. Also he brought out the spoil of the city in great abundance.", 
                "num": 30
              }, 
              {
                "text": "And he brought out the people who were in it, and put them to work with saws and iron picks and iron axes, and made them cross over to the brick works. So he did to all the cities of the people of Ammon. Then David and all the people returned to Jerusalem.", 
                "num": 31
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "After this Absalom the son of David had a lovely sister, whose name was Tamar; and Amnon the son of David loved her.", 
                "num": 1
              }, 
              {
                "text": "Amnon was so distressed over his sister Tamar that he became sick; for she was a virgin. And it was improper for Amnon to do anything to her.", 
                "num": 2
              }, 
              {
                "text": "But Amnon had a friend whose name was Jonadab the son of Shimeah, David's brother. Now Jonadab was a very crafty man.", 
                "num": 3
              }, 
              {
                "text": "And he said to him, \"Why are you, the king's son, becoming thinner day after day? Will you not tell me?\" Amnon said to him, \"I love Tamar, my brother Absalom's sister.\"", 
                "num": 4
              }, 
              {
                "text": "So Jonadab said to him, \"Lie down on your bed and pretend to be ill. And when your father comes to see you, say to him, \"Please let my sister Tamar come and give me food, and prepare the food in my sight, that I may see it and eat it from her hand.\"'", 
                "num": 5
              }, 
              {
                "text": "Then Amnon lay down and pretended to be ill; and when the king came to see him, Amnon said to the king, \"Please let Tamar my sister come and make a couple of cakes for me in my sight, that I may eat from her hand.\"", 
                "num": 6
              }, 
              {
                "text": "And David sent home to Tamar, saying, \"Now go to your brother Amnon's house, and prepare food for him.\"", 
                "num": 7
              }, 
              {
                "text": "So Tamar went to her brother Amnon's house; and he was lying down. Then she took flour and kneaded it, made cakes in his sight, and baked the cakes.", 
                "num": 8
              }, 
              {
                "text": "And she took the pan and placed them out before him, but he refused to eat. Then Amnon said, \"Have everyone go out from me.\" And they all went out from him.", 
                "num": 9
              }, 
              {
                "text": "Then Amnon said to Tamar, \"Bring the food into the bedroom, that I may eat from your hand.\" And Tamar took the cakes which she had made, and brought them to Amnon her brother in the bedroom.", 
                "num": 10
              }, 
              {
                "text": "Now when she had brought them to him to eat, he took hold of her and said to her, \"Come, lie with me, my sister.\"", 
                "num": 11
              }, 
              {
                "text": "But she answered him, \"No, my brother, do not force me, for no such thing should be done in Israel. Do not do this disgraceful thing!", 
                "num": 12
              }, 
              {
                "text": "And I, where could I take my shame? And as for you, you would be like one of the fools in Israel. Now therefore, please speak to the king; for he will not withhold me from you.\"", 
                "num": 13
              }, 
              {
                "text": "However, he would not heed her voice; and being stronger than she, he forced her and lay with her.", 
                "num": 14
              }, 
              {
                "text": "Then Amnon hated her exceedingly, so that the hatred with which he hated her was greater than the love with which he had loved her. And Amnon said to her, \"Arise, be gone!\"", 
                "num": 15
              }, 
              {
                "text": "So she said to him, \"No, indeed! This evil of sending me away is worse than the other that you did to me.\" But he would not listen to her.", 
                "num": 16
              }, 
              {
                "text": "Then he called his servant who attended him, and said, \"Here! Put this woman out, away from me, and bolt the door behind her.\"", 
                "num": 17
              }, 
              {
                "text": "Now she had on a robe of many colors, for the king's virgin daughters wore such apparel. And his servant put her out and bolted the door behind her.", 
                "num": 18
              }, 
              {
                "text": "Then Tamar put ashes on her head, and tore her robe of many colors that was on her, and laid her hand on her head and went away crying bitterly.", 
                "num": 19
              }, 
              {
                "text": "And Absalom her brother said to her, \"Has Amnon your brother been with you? But now hold your peace, my sister. He is your brother; do not take this thing to heart.\" So Tamar remained desolate in her brother Absalom's house.", 
                "num": 20
              }, 
              {
                "text": "But when King David heard of all these things, he was very angry.", 
                "num": 21
              }, 
              {
                "text": "And Absalom spoke to his brother Amnon neither good nor bad. For Absalom hated Amnon, because he had forced his sister Tamar.", 
                "num": 22
              }, 
              {
                "text": "And it came to pass, after two full years, that Absalom had sheepshearers in Baal Hazor, which is near Ephraim; so Absalom invited all the king's sons.", 
                "num": 23
              }, 
              {
                "text": "Then Absalom came to the king and said, \"Kindly note, your servant has sheepshearers; please, let the king and his servants go with your servant.\"", 
                "num": 24
              }, 
              {
                "text": "But the king said to Absalom, \"No, my son, let us not all go now, lest we be a burden to you.\" Then he urged him, but he would not go; and he blessed him.", 
                "num": 25
              }, 
              {
                "text": "Then Absalom said, \"If not, please let my brother Amnon go with us.\" And the king said to him, \"Why should he go with you?\"", 
                "num": 26
              }, 
              {
                "text": "But Absalom urged him; so he let Amnon and all the king's sons go with him.", 
                "num": 27
              }, 
              {
                "text": "Now Absalom had commanded his servants, saying, \"Watch now, when Amnon's heart is merry with wine, and when I say to you, \"Strike Amnon!' then kill him. Do not be afraid. Have I not commanded you? Be courageous and valiant.\"", 
                "num": 28
              }, 
              {
                "text": "So the servants of Absalom did to Amnon as Absalom had commanded. Then all the king's sons arose, and each one got on his mule and fled.", 
                "num": 29
              }, 
              {
                "text": "And it came to pass, while they were on the way, that news came to David, saying, \"Absalom has killed all the king's sons, and not one of them is left!\"", 
                "num": 30
              }, 
              {
                "text": "So the king arose and tore his garments and lay on the ground, and all his servants stood by with their clothes torn.", 
                "num": 31
              }, 
              {
                "text": "Then Jonadab the son of Shimeah, David's brother, answered and said, \"Let not my lord suppose they have killed all the young men, the king's sons, for only Amnon is dead. For by the command of Absalom this has been determined from the day that he forced his sister Tamar.", 
                "num": 32
              }, 
              {
                "text": "Now therefore, let not my lord the king take the thing to his heart, to think that all the king's sons are dead. For only Amnon is dead.\"", 
                "num": 33
              }, 
              {
                "text": "Then Absalom fled. And the young man who was keeping watch lifted his eyes and looked, and there, many people were coming from the road on the hillside behind him.", 
                "num": 34
              }, 
              {
                "text": "And Jonadab said to the king, \"Look, the king's sons are coming; as your servant said, so it is.\"", 
                "num": 35
              }, 
              {
                "text": "So it was, as soon as he had finished speaking, that the king's sons indeed came, and they lifted up their voice and wept. Also the king and all his servants wept very bitterly.", 
                "num": 36
              }, 
              {
                "text": "But Absalom fled and went to Talmai the son of Ammihud, king of Geshur. And David mourned for his son every day.", 
                "num": 37
              }, 
              {
                "text": "So Absalom fled and went to Geshur, and was there three years.", 
                "num": 38
              }, 
              {
                "text": "And King David longed to go to Absalom. For he had been comforted concerning Amnon, because he was dead.", 
                "num": 39
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "So Joab the son of Zeruiah perceived that the king's heart was concerned about Absalom.", 
                "num": 1
              }, 
              {
                "text": "And Joab sent to Tekoa and brought from there a wise woman, and said to her, \"Please pretend to be a mourner, and put on mourning apparel; do not anoint yourself with oil, but act like a woman who has been mourning a long time for the dead.", 
                "num": 2
              }, 
              {
                "text": "Go to the king and speak to him in this manner.\" So Joab put the words in her mouth.", 
                "num": 3
              }, 
              {
                "text": "And when the woman of Tekoa spoke to the king, she fell on her face to the ground and prostrated herself, and said, \"Help, O king!\"", 
                "num": 4
              }, 
              {
                "text": "Then the king said to her, \"What troubles you?\" And she answered, \"Indeed I am a widow, my husband is dead.", 
                "num": 5
              }, 
              {
                "text": "Now your maidservant had two sons; and the two fought with each other in the field, and there was no one to part them, but the one struck the other and killed him.", 
                "num": 6
              }, 
              {
                "text": "And now the whole family has risen up against your maidservant, and they said, \"Deliver him who struck his brother, that we may execute him for the life of his brother whom he killed; and we will destroy the heir also.' So they would extinguish my ember that is left, and leave to my husband neither name nor remnant on the earth.\"", 
                "num": 7
              }, 
              {
                "text": "Then the king said to the woman, \"Go to your house, and I will give orders concerning you.\"", 
                "num": 8
              }, 
              {
                "text": "And the woman of Tekoa said to the king, \"My lord, O king, let the iniquity be on me and on my father's house, and the king and his throne be guiltless.\"", 
                "num": 9
              }, 
              {
                "text": "So the king said, \"Whoever says anything to you, bring him to me, and he shall not touch you anymore.\"", 
                "num": 10
              }, 
              {
                "text": "Then she said, \"Please let the king remember the LORD your God, and do not permit the avenger of blood to destroy anymore, lest they destroy my son.\" And he said, \"As the LORD lives, not one hair of your son shall fall to the ground.\"", 
                "num": 11
              }, 
              {
                "text": "Therefore the woman said, \"Please, let your maidservant speak another word to my lord the king.\" And he said, \"Say on.\"", 
                "num": 12
              }, 
              {
                "text": "So the woman said: \"Why then have you schemed such a thing against the people of God? For the king speaks this thing as one who is guilty, in that the king does not bring his banished one home again.", 
                "num": 13
              }, 
              {
                "text": "For we will surely die and become like water spilled on the ground, which cannot be gathered up again. Yet God does not take away a life; but He devises means, so that His banished ones are not expelled from Him.", 
                "num": 14
              }, 
              {
                "text": "Now therefore, I have come to speak of this thing to my lord the king because the people have made me afraid. And your maidservant said, \"I will now speak to the king; it may be that the king will perform the request of his maidservant.", 
                "num": 15
              }, 
              {
                "text": "For the king will hear and deliver his maidservant from the hand of the man who would destroy me and my son together from the inheritance of God.'", 
                "num": 16
              }, 
              {
                "text": "Your maidservant said, \"The word of my lord the king will now be comforting; for as the angel of God, so is my lord the king in discerning good and evil. And may the LORD your God be with you.\"'", 
                "num": 17
              }, 
              {
                "text": "Then the king answered and said to the woman, \"Please do not hide from me anything that I ask you.\" And the woman said, \"Please, let my lord the king speak.\"", 
                "num": 18
              }, 
              {
                "text": "So the king said, \"Is the hand of Joab with you in all this?\" And the woman answered and said, \"As you live, my lord the king, no one can turn to the right hand or to the left from anything that my lord the king has spoken. For your servant Joab commanded me, and he put all these words in the mouth of your maidservant.", 
                "num": 19
              }, 
              {
                "text": "To bring about this change of affairs your servant Joab has done this thing; but my lord is wise, according to the wisdom of the angel of God, to know everything that is in the earth.\"", 
                "num": 20
              }, 
              {
                "text": "And the king said to Joab, \"All right, I have granted this thing. Go therefore, bring back the young man Absalom.\"", 
                "num": 21
              }, 
              {
                "text": "Then Joab fell to the ground on his face and bowed himself, and thanked the king. And Joab said, \"Today your servant knows that I have found favor in your sight, my lord, O king, in that the king has fulfilled the request of his servant.\"", 
                "num": 22
              }, 
              {
                "text": "So Joab arose and went to Geshur, and brought Absalom to Jerusalem.", 
                "num": 23
              }, 
              {
                "text": "And the king said, \"Let him return to his own house, but do not let him see my face.\" So Absalom returned to his own house, but did not see the king's face.", 
                "num": 24
              }, 
              {
                "text": "Now in all Israel there was no one who was praised as much as Absalom for his good looks. From the sole of his foot to the crown of his head there was no blemish in him.", 
                "num": 25
              }, 
              {
                "text": "And when he cut the hair of his head--at the end of every year he cut it because it was heavy on him--when he cut it, he weighed the hair of his head at two hundred shekels according to the king's standard.", 
                "num": 26
              }, 
              {
                "text": "To Absalom were born three sons, and one daughter whose name was Tamar. She was a woman of beautiful appearance.", 
                "num": 27
              }, 
              {
                "text": "And Absalom dwelt two full years in Jerusalem, but did not see the king's face.", 
                "num": 28
              }, 
              {
                "text": "Therefore Absalom sent for Joab, to send him to the king, but he would not come to him. And when he sent again the second time, he would not come.", 
                "num": 29
              }, 
              {
                "text": "So he said to his servants, \"See, Joab's field is near mine, and he has barley there; go and set it on fire.\" And Absalom's servants set the field on fire.", 
                "num": 30
              }, 
              {
                "text": "Then Joab arose and came to Absalom's house, and said to him, \"Why have your servants set my field on fire?\"", 
                "num": 31
              }, 
              {
                "text": "And Absalom answered Joab, \"Look, I sent to you, saying, \"Come here, so that I may send you to the king, to say, \"Why have I come from Geshur? It would be better for me to be there still.\"' Now therefore, let me see the king's face; but if there is iniquity in me, let him execute me.\"", 
                "num": 32
              }, 
              {
                "text": "So Joab went to the king and told him. And when he had called for Absalom, he came to the king and bowed himself on his face to the ground before the king. Then the king kissed Absalom.", 
                "num": 33
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "After this it happened that Absalom provided himself with chariots and horses, and fifty men to run before him.", 
                "num": 1
              }, 
              {
                "text": "Now Absalom would rise early and stand beside the way to the gate. So it was, whenever anyone who had a lawsuit came to the king for a decision, that Absalom would call to him and say, \"What city are you from?\" And he would say, \"Your servant is from such and such a tribe of Israel.\"", 
                "num": 2
              }, 
              {
                "text": "Then Absalom would say to him, \"Look, your case is good and right; but there is no deputy of the king to hear you.\"", 
                "num": 3
              }, 
              {
                "text": "Moreover Absalom would say, \"Oh, that I were made judge in the land, and everyone who has any suit or cause would come to me; then I would give him justice.\"", 
                "num": 4
              }, 
              {
                "text": "And so it was, whenever anyone came near to bow down to him, that he would put out his hand and take him and kiss him.", 
                "num": 5
              }, 
              {
                "text": "In this manner Absalom acted toward all Israel who came to the king for judgment. So Absalom stole the hearts of the men of Israel.", 
                "num": 6
              }, 
              {
                "text": "Now it came to pass after forty years that Absalom said to the king, \"Please, let me go to Hebron and pay the vow which I made to the LORD.", 
                "num": 7
              }, 
              {
                "text": "For your servant took a vow while I dwelt at Geshur in Syria, saying, \"If the LORD indeed brings me back to Jerusalem, then I will serve the LORD.\"'", 
                "num": 8
              }, 
              {
                "text": "And the king said to him, \"Go in peace.\" So he arose and went to Hebron.", 
                "num": 9
              }, 
              {
                "text": "Then Absalom sent spies throughout all the tribes of Israel, saying, \"As soon as you hear the sound of the trumpet, then you shall say, \"Absalom reigns in Hebron!\"'", 
                "num": 10
              }, 
              {
                "text": "And with Absalom went two hundred men invited from Jerusalem, and they went along innocently and did not know anything.", 
                "num": 11
              }, 
              {
                "text": "Then Absalom sent for Ahithophel the Gilonite, David's counselor, from his city--from Giloh--while he offered sacrifices. And the conspiracy grew strong, for the people with Absalom continually increased in number.", 
                "num": 12
              }, 
              {
                "text": "Now a messenger came to David, saying, \"The hearts of the men of Israel are with Absalom.\"", 
                "num": 13
              }, 
              {
                "text": "So David said to all his servants who were with him at Jerusalem, \"Arise, and let us flee, or we shall not escape from Absalom. Make haste to depart, lest he overtake us suddenly and bring disaster upon us, and strike the city with the edge of the sword.\"", 
                "num": 14
              }, 
              {
                "text": "And the king's servants said to the king, \"We are your servants, ready to do whatever my lord the king commands.\"", 
                "num": 15
              }, 
              {
                "text": "Then the king went out with all his household after him. But the king left ten women, concubines, to keep the house.", 
                "num": 16
              }, 
              {
                "text": "And the king went out with all the people after him, and stopped at the outskirts.", 
                "num": 17
              }, 
              {
                "text": "Then all his servants passed before him; and all the Cherethites, all the Pelethites, and all the Gittites, six hundred men who had followed him from Gath, passed before the king.", 
                "num": 18
              }, 
              {
                "text": "Then the king said to Ittai the Gittite, \"Why are you also going with us? Return and remain with the king. For you are a foreigner and also an exile from your own place.", 
                "num": 19
              }, 
              {
                "text": "In fact, you came only yesterday. Should I make you wander up and down with us today, since I go I know not where? Return, and take your brethren back. Mercy and truth be with you.\"", 
                "num": 20
              }, 
              {
                "text": "But Ittai answered the king and said, \"As the LORD lives, and as my lord the king lives, surely in whatever place my lord the king shall be, whether in death or life, even there also your servant will be.\"", 
                "num": 21
              }, 
              {
                "text": "So David said to Ittai, \"Go, and cross over.\" Then Ittai the Gittite and all his men and all the little ones who were with him crossed over.", 
                "num": 22
              }, 
              {
                "text": "And all the country wept with a loud voice, and all the people crossed over. The king himself also crossed over the Brook Kidron, and all the people crossed over toward the way of the wilderness.", 
                "num": 23
              }, 
              {
                "text": "There was Zadok also, and all the Levites with him, bearing the ark of the covenant of God. And they set down the ark of God, and Abiathar went up until all the people had finished crossing over from the city.", 
                "num": 24
              }, 
              {
                "text": "Then the king said to Zadok, \"Carry the ark of God back into the city. If I find favor in the eyes of the LORD, He will bring me back and show me both it and His dwelling place.", 
                "num": 25
              }, 
              {
                "text": "But if He says thus: \"I have no delight in you,' here I am, let Him do to me as seems good to Him.\"", 
                "num": 26
              }, 
              {
                "text": "The king also said to Zadok the priest, \"Are you not a seer? Return to the city in peace, and your two sons with you, Ahimaaz your son, and Jonathan the son of Abiathar.", 
                "num": 27
              }, 
              {
                "text": "See, I will wait in the plains of the wilderness until word comes from you to inform me.\"", 
                "num": 28
              }, 
              {
                "text": "Therefore Zadok and Abiathar carried the ark of God back to Jerusalem. And they remained there.", 
                "num": 29
              }, 
              {
                "text": "So David went up by the Ascent of the Mount of Olives, and wept as he went up; and he had his head covered and went barefoot. And all the people who were with him covered their heads and went up, weeping as they went up.", 
                "num": 30
              }, 
              {
                "text": "Then someone told David, saying, \"Ahithophel is among the conspirators with Absalom.\" And David said, \"O LORD, I pray, turn the counsel of Ahithophel into foolishness!\"", 
                "num": 31
              }, 
              {
                "text": "Now it happened when David had come to the top of the mountain, where he worshiped God--there was Hushai the Archite coming to meet him with his robe torn and dust on his head.", 
                "num": 32
              }, 
              {
                "text": "David said to him, \"If you go on with me, then you will become a burden to me.", 
                "num": 33
              }, 
              {
                "text": "But if you return to the city, and say to Absalom, \"I will be your servant, O king; as I was your father's servant previously, so I will now also be your servant,' then you may defeat the counsel of Ahithophel for me.", 
                "num": 34
              }, 
              {
                "text": "And do you not have Zadok and Abiathar the priests with you there? Therefore it will be that whatever you hear from the king's house, you shall tell to Zadok and Abiathar the priests.", 
                "num": 35
              }, 
              {
                "text": "Indeed they have there with them their two sons, Ahimaaz, Zadok's son, and Jonathan, Abiathar's son; and by them you shall send me everything you hear.\"", 
                "num": 36
              }, 
              {
                "text": "So Hushai, David's friend, went into the city. And Absalom came into Jerusalem.", 
                "num": 37
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "When David was a little past the top of the mountain, there was Ziba the servant of Mephibosheth, who met him with a couple of saddled donkeys, and on them two hundred loaves of bread, one hundred clusters of raisins, one hundred summer fruits, and a skin of wine.", 
                "num": 1
              }, 
              {
                "text": "And the king said to Ziba, \"What do you mean to do with these?\" So Ziba said, \"The donkeys are for the king's household to ride on, the bread and summer fruit for the young men to eat, and the wine for those who are faint in the wilderness to drink.\"", 
                "num": 2
              }, 
              {
                "text": "Then the king said, \"And where is your master's son?\" And Ziba said to the king, \"Indeed he is staying in Jerusalem, for he said, \"Today the house of Israel will restore the kingdom of my father to me.\"'", 
                "num": 3
              }, 
              {
                "text": "So the king said to Ziba, \"Here, all that belongs to Mephibosheth is yours.\" And Ziba said, \"I humbly bow before you, that I may find favor in your sight, my lord, O king!\"", 
                "num": 4
              }, 
              {
                "text": "Now when King David came to Bahurim, there was a man from the family of the house of Saul, whose name was Shimei the son of Gera, coming from there. He came out, cursing continuously as he came.", 
                "num": 5
              }, 
              {
                "text": "And he threw stones at David and at all the servants of King David. And all the people and all the mighty men were on his right hand and on his left.", 
                "num": 6
              }, 
              {
                "text": "Also Shimei said thus when he cursed: \"Come out! Come out! You bloodthirsty man, you rogue!", 
                "num": 7
              }, 
              {
                "text": "The LORD has brought upon you all the blood of the house of Saul, in whose place you have reigned; and the LORD has delivered the kingdom into the hand of Absalom your son. So now you are caught in your own evil, because you are a bloodthirsty man!\"", 
                "num": 8
              }, 
              {
                "text": "Then Abishai the son of Zeruiah said to the king, \"Why should this dead dog curse my lord the king? Please, let me go over and take off his head!\"", 
                "num": 9
              }, 
              {
                "text": "But the king said, \"What have I to do with you, you sons of Zeruiah? So let him curse, because the LORD has said to him, \"Curse David.' Who then shall say, \"Why have you done so?\"'", 
                "num": 10
              }, 
              {
                "text": "And David said to Abishai and all his servants, \"See how my son who came from my own body seeks my life. How much more now may this Benjamite? Let him alone, and let him curse; for so the LORD has ordered him.", 
                "num": 11
              }, 
              {
                "text": "It may be that the LORD will look on my affliction, and that the LORD will repay me with good for his cursing this day.\"", 
                "num": 12
              }, 
              {
                "text": "And as David and his men went along the road, Shimei went along the hillside opposite him and cursed as he went, threw stones at him and kicked up dust.", 
                "num": 13
              }, 
              {
                "text": "Now the king and all the people who were with him became weary; so they refreshed themselves there.", 
                "num": 14
              }, 
              {
                "text": "Meanwhile Absalom and all the people, the men of Israel, came to Jerusalem; and Ahithophel was with him.", 
                "num": 15
              }, 
              {
                "text": "And so it was, when Hushai the Archite, David's friend, came to Absalom, that Hushai said to Absalom, \"Long live the king! Long live the king!\"", 
                "num": 16
              }, 
              {
                "text": "So Absalom said to Hushai, \"Is this your loyalty to your friend? Why did you not go with your friend?\"", 
                "num": 17
              }, 
              {
                "text": "And Hushai said to Absalom, \"No, but whom the LORD and this people and all the men of Israel choose, his I will be, and with him I will remain.", 
                "num": 18
              }, 
              {
                "text": "\"Furthermore, whom should I serve? Should I not serve in the presence of his son? As I have served in your father's presence, so will I be in your presence.\"", 
                "num": 19
              }, 
              {
                "text": "Then Absalom said to Ahithophel, \"Give advice as to what we should do.\"", 
                "num": 20
              }, 
              {
                "text": "And Ahithophel said to Absalom, \"Go in to your father's concubines, whom he has left to keep the house; and all Israel will hear that you are abhorred by your father. Then the hands of all who are with you will be strong.\"", 
                "num": 21
              }, 
              {
                "text": "So they pitched a tent for Absalom on the top of the house, and Absalom went in to his father's concubines in the sight of all Israel.", 
                "num": 22
              }, 
              {
                "text": "Now the advice of Ahithophel, which he gave in those days, was as if one had inquired at the oracle of God. So was all the advice of Ahithophel both with David and with Absalom.", 
                "num": 23
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Moreover Ahithophel said to Absalom, \"Now let me choose twelve thousand men, and I will arise and pursue David tonight.", 
                "num": 1
              }, 
              {
                "text": "I will come upon him while he is weary and weak, and make him afraid. And all the people who are with him will flee, and I will strike only the king.", 
                "num": 2
              }, 
              {
                "text": "Then I will bring back all the people to you. When all return except the man whom you seek, all the people will be at peace.\"", 
                "num": 3
              }, 
              {
                "text": "And the saying pleased Absalom and all the elders of Israel.", 
                "num": 4
              }, 
              {
                "text": "Then Absalom said, \"Now call Hushai the Archite also, and let us hear what he says too.\"", 
                "num": 5
              }, 
              {
                "text": "And when Hushai came to Absalom, Absalom spoke to him, saying, \"Ahithophel has spoken in this manner. Shall we do as he says? If not, speak up.\"", 
                "num": 6
              }, 
              {
                "text": "So Hushai said to Absalom: \"The advice that Ahithophel has given is not good at this time.", 
                "num": 7
              }, 
              {
                "text": "For,\" said Hushai, \"you know your father and his men, that they are mighty men, and they are enraged in their minds, like a bear robbed of her cubs in the field; and your father is a man of war, and will not camp with the people.", 
                "num": 8
              }, 
              {
                "text": "Surely by now he is hidden in some pit, or in some other place. And it will be, when some of them are overthrown at the first, that whoever hears it will say, \"There is a slaughter among the people who follow Absalom.'", 
                "num": 9
              }, 
              {
                "text": "And even he who is valiant, whose heart is like the heart of a lion, will melt completely. For all Israel knows that your father is a mighty man, and those who are with him are valiant men.", 
                "num": 10
              }, 
              {
                "text": "Therefore I advise that all Israel be fully gathered to you, from Dan to Beersheba, like the sand that is by the sea for multitude, and that you go to battle in person.", 
                "num": 11
              }, 
              {
                "text": "So we will come upon him in some place where he may be found, and we will fall on him as the dew falls on the ground. And of him and all the men who are with him there shall not be left so much as one.", 
                "num": 12
              }, 
              {
                "text": "Moreover, if he has withdrawn into a city, then all Israel shall bring ropes to that city; and we will pull it into the river, until there is not one small stone found there.\"", 
                "num": 13
              }, 
              {
                "text": "So Absalom and all the men of Israel said, \"The advice of Hushai the Archite is better than the advice of Ahithophel.\" For the LORD had purposed to defeat the good advice of Ahithophel, to the intent that the LORD might bring disaster on Absalom.", 
                "num": 14
              }, 
              {
                "text": "Then Hushai said to Zadok and Abiathar the priests, \"Thus and so Ahithophel advised Absalom and the elders of Israel, and thus and so I have advised.", 
                "num": 15
              }, 
              {
                "text": "Now therefore, send quickly and tell David, saying, \"Do not spend this night in the plains of the wilderness, but speedily cross over, lest the king and all the people who are with him be swallowed up.\"'", 
                "num": 16
              }, 
              {
                "text": "Now Jonathan and Ahimaaz stayed at En Rogel, for they dared not be seen coming into the city; so a female servant would come and tell them, and they would go and tell King David.", 
                "num": 17
              }, 
              {
                "text": "Nevertheless a lad saw them, and told Absalom. But both of them went away quickly and came to a man's house in Bahurim, who had a well in his court; and they went down into it.", 
                "num": 18
              }, 
              {
                "text": "Then the woman took and spread a covering over the well's mouth, and spread ground grain on it; and the thing was not known.", 
                "num": 19
              }, 
              {
                "text": "And when Absalom's servants came to the woman at the house, they said, \"Where are Ahimaaz and Jonathan?\" So the woman said to them, \"They have gone over the water brook.\" And when they had searched and could not find them, they returned to Jerusalem.", 
                "num": 20
              }, 
              {
                "text": "Now it came to pass, after they had departed, that they came up out of the well and went and told King David, and said to David, \"Arise and cross over the water quickly. For thus has Ahithophel advised against you.\"", 
                "num": 21
              }, 
              {
                "text": "So David and all the people who were with him arose and crossed over the Jordan. By morning light not one of them was left who had not gone over the Jordan.", 
                "num": 22
              }, 
              {
                "text": "Now when Ahithophel saw that his advice was not followed, he saddled a donkey, and arose and went home to his house, to his city. Then he put his household in order, and hanged himself, and died; and he was buried in his father's tomb.", 
                "num": 23
              }, 
              {
                "text": "Then David went to Mahanaim. And Absalom crossed over the Jordan, he and all the men of Israel with him.", 
                "num": 24
              }, 
              {
                "text": "And Absalom made Amasa captain of the army instead of Joab. This Amasa was the son of a man whose name was Jithra, an Israelite, who had gone in to Abigail the daughter of Nahash, sister of Zeruiah, Joab's mother.", 
                "num": 25
              }, 
              {
                "text": "So Israel and Absalom encamped in the land of Gilead.", 
                "num": 26
              }, 
              {
                "text": "Now it happened, when David had come to Mahanaim, that Shobi the son of Nahash from Rabbah of the people of Ammon, Machir the son of Ammiel from Lo Debar, and Barzillai the Gileadite from Rogelim,", 
                "num": 27
              }, 
              {
                "text": "brought beds and basins, earthen vessels and wheat, barley and flour, parched grain and beans, lentils and parched seeds,", 
                "num": 28
              }, 
              {
                "text": "honey and curds, sheep and cheese of the herd, for David and the people who were with him to eat. For they said, \"The people are hungry and weary and thirsty in the wilderness.\"", 
                "num": 29
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "And David numbered the people who were with him, and set captains of thousands and captains of hundreds over them.", 
                "num": 1
              }, 
              {
                "text": "Then David sent out one third of the people under the hand of Joab, one third under the hand of Abishai the son of Zeruiah, Joab's brother, and one third under the hand of Ittai the Gittite. And the king said to the people, \"I also will surely go out with you myself.\"", 
                "num": 2
              }, 
              {
                "text": "But the people answered, \"You shall not go out! For if we flee away, they will not care about us; nor if half of us die, will they care about us. But you are worth ten thousand of us now. For you are now more help to us in the city.\"", 
                "num": 3
              }, 
              {
                "text": "Then the king said to them, \"Whatever seems best to you I will do.\" So the king stood beside the gate, and all the people went out by hundreds and by thousands.", 
                "num": 4
              }, 
              {
                "text": "Now the king had commanded Joab, Abishai, and Ittai, saying, \"Deal gently for my sake with the young man Absalom.\" And all the people heard when the king gave all the captains orders concerning Absalom.", 
                "num": 5
              }, 
              {
                "text": "So the people went out into the field of battle against Israel. And the battle was in the woods of Ephraim.", 
                "num": 6
              }, 
              {
                "text": "The people of Israel were overthrown there before the servants of David, and a great slaughter of twenty thousand took place there that day.", 
                "num": 7
              }, 
              {
                "text": "For the battle there was scattered over the face of the whole countryside, and the woods devoured more people that day than the sword devoured.", 
                "num": 8
              }, 
              {
                "text": "Then Absalom met the servants of David. Absalom rode on a mule. The mule went under the thick boughs of a great terebinth tree, and his head caught in the terebinth; so he was left hanging between heaven and earth. And the mule which was under him went on.", 
                "num": 9
              }, 
              {
                "text": "Now a certain man saw it and told Joab, and said, \"I just saw Absalom hanging in a terebinth tree!\"", 
                "num": 10
              }, 
              {
                "text": "So Joab said to the man who told him, \"You just saw him! And why did you not strike him there to the ground? I would have given you ten shekels of silver and a belt.\"", 
                "num": 11
              }, 
              {
                "text": "But the man said to Joab, \"Though I were to receive a thousand shekels of silver in my hand, I would not raise my hand against the king's son. For in our hearing the king commanded you and Abishai and Ittai, saying, \"Beware lest anyone touch the young man Absalom!'", 
                "num": 12
              }, 
              {
                "text": "Otherwise I would have dealt falsely against my own life. For there is nothing hidden from the king, and you yourself would have set yourself against me.\"", 
                "num": 13
              }, 
              {
                "text": "Then Joab said, \"I cannot linger with you.\" And he took three spears in his hand and thrust them through Absalom's heart, while he was still alive in the midst of the terebinth tree.", 
                "num": 14
              }, 
              {
                "text": "And ten young men who bore Joab's armor surrounded Absalom, and struck and killed him.", 
                "num": 15
              }, 
              {
                "text": "So Joab blew the trumpet, and the people returned from pursuing Israel. For Joab held back the people.", 
                "num": 16
              }, 
              {
                "text": "And they took Absalom and cast him into a large pit in the woods, and laid a very large heap of stones over him. Then all Israel fled, everyone to his tent.", 
                "num": 17
              }, 
              {
                "text": "Now Absalom in his lifetime had taken and set up a pillar for himself, which is in the King's Valley. For he said, \"I have no son to keep my name in remembrance.\" He called the pillar after his own name. And to this day it is called Absalom's Monument.", 
                "num": 18
              }, 
              {
                "text": "Then Ahimaaz the son of Zadok said, \"Let me run now and take the news to the king, how the LORD has avenged him of his enemies.\"", 
                "num": 19
              }, 
              {
                "text": "And Joab said to him, \"You shall not take the news this day, for you shall take the news another day. But today you shall take no news, because the king's son is dead.\"", 
                "num": 20
              }, 
              {
                "text": "Then Joab said to the Cushite, \"Go, tell the king what you have seen.\" So the Cushite bowed himself to Joab and ran.", 
                "num": 21
              }, 
              {
                "text": "And Ahimaaz the son of Zadok said again to Joab, \"But whatever happens, please let me also run after the Cushite.\" So Joab said, \"Why will you run, my son, since you have no news ready?\"", 
                "num": 22
              }, 
              {
                "text": "\"But whatever happens,\" he said, \"let me run.\" So he said to him, \"Run.\" Then Ahimaaz ran by way of the plain, and outran the Cushite.", 
                "num": 23
              }, 
              {
                "text": "Now David was sitting between the two gates. And the watchman went up to the roof over the gate, to the wall, lifted his eyes and looked, and there was a man, running alone.", 
                "num": 24
              }, 
              {
                "text": "Then the watchman cried out and told the king. And the king said, \"If he is alone, there is news in his mouth.\" And he came rapidly and drew near.", 
                "num": 25
              }, 
              {
                "text": "Then the watchman saw another man running, and the watchman called to the gatekeeper and said, \"There is another man, running alone!\" And the king said, \"He also brings news.\"", 
                "num": 26
              }, 
              {
                "text": "So the watchman said, \"I think the running of the first is like the running of Ahimaaz the son of Zadok.\" And the king said, \"He is a good man, and comes with good news.\"", 
                "num": 27
              }, 
              {
                "text": "So Ahimaaz called out and said to the king, \"All is well!\" Then he bowed down with his face to the earth before the king, and said, \"Blessed be the LORD your God, who has delivered up the men who raised their hand against my lord the king!\"", 
                "num": 28
              }, 
              {
                "text": "The king said, \"Is the young man Absalom safe?\" Ahimaaz answered, \"When Joab sent the king's servant and me your servant, I saw a great tumult, but I did not know what it was about.\"", 
                "num": 29
              }, 
              {
                "text": "And the king said, \"Turn aside and stand here.\" So he turned aside and stood still.", 
                "num": 30
              }, 
              {
                "text": "Just then the Cushite came, and the Cushite said, \"There is good news, my lord the king! For the LORD has avenged you this day of all those who rose against you.\"", 
                "num": 31
              }, 
              {
                "text": "And the king said to the Cushite, \"Is the young man Absalom safe?\" So the Cushite answered, \"May the enemies of my lord the king, and all who rise against you to do harm, be like that young man!\"", 
                "num": 32
              }, 
              {
                "text": "Then the king was deeply moved, and went up to the chamber over the gate, and wept. And as he went, he said thus: \"O my son Absalom--my son, my son Absalom--if only I had died in your place! O Absalom my son, my son!\"", 
                "num": 33
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And Joab was told, \"Behold, the king is weeping and mourning for Absalom.\"", 
                "num": 1
              }, 
              {
                "text": "So the victory that day was turned into mourning for all the people. For the people heard it said that day, \"The king is grieved for his son.\"", 
                "num": 2
              }, 
              {
                "text": "And the people stole back into the city that day, as people who are ashamed steal away when they flee in battle.", 
                "num": 3
              }, 
              {
                "text": "But the king covered his face, and the king cried out with a loud voice, \"O my son Absalom! O Absalom, my son, my son!\"", 
                "num": 4
              }, 
              {
                "text": "Then Joab came into the house to the king, and said, \"Today you have disgraced all your servants who today have saved your life, the lives of your sons and daughters, the lives of your wives and the lives of your concubines,", 
                "num": 5
              }, 
              {
                "text": "in that you love your enemies and hate your friends. For you have declared today that you regard neither princes nor servants; for today I perceive that if Absalom had lived and all of us had died today, then it would have pleased you well.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, arise, go out and speak comfort to your servants. For I swear by the LORD, if you do not go out, not one will stay with you this night. And that will be worse for you than all the evil that has befallen you from your youth until now.\"", 
                "num": 7
              }, 
              {
                "text": "Then the king arose and sat in the gate. And they told all the people, saying, \"There is the king, sitting in the gate.\" So all the people came before the king. For everyone of Israel had fled to his tent.", 
                "num": 8
              }, 
              {
                "text": "Now all the people were in a dispute throughout all the tribes of Israel, saying, \"The king saved us from the hand of our enemies, he delivered us from the hand of the Philistines, and now he has fled from the land because of Absalom.", 
                "num": 9
              }, 
              {
                "text": "But Absalom, whom we anointed over us, has died in battle. Now therefore, why do you say nothing about bringing back the king?\"", 
                "num": 10
              }, 
              {
                "text": "So King David sent to Zadok and Abiathar the priests, saying, \"Speak to the elders of Judah, saying, \"Why are you the last to bring the king back to his house, since the words of all Israel have come to the king, to his very house?", 
                "num": 11
              }, 
              {
                "text": "You are my brethren, you are my bone and my flesh. Why then are you the last to bring back the king?'", 
                "num": 12
              }, 
              {
                "text": "And say to Amasa, \"Are you not my bone and my flesh? God do so to me, and more also, if you are not commander of the army before me continually in place of Joab.\"'", 
                "num": 13
              }, 
              {
                "text": "So he swayed the hearts of all the men of Judah, just as the heart of one man, so that they sent this word to the king: \"Return, you and all your servants!\"", 
                "num": 14
              }, 
              {
                "text": "Then the king returned and came to the Jordan. And Judah came to Gilgal, to go to meet the king, to escort the king across the Jordan.", 
                "num": 15
              }, 
              {
                "text": "And Shimei the son of Gera, a Benjamite, who was from Bahurim, hurried and came down with the men of Judah to meet King David.", 
                "num": 16
              }, 
              {
                "text": "There were a thousand men of Benjamin with him, and Ziba the servant of the house of Saul, and his fifteen sons and his twenty servants with him; and they went over the Jordan before the king.", 
                "num": 17
              }, 
              {
                "text": "Then a ferryboat went across to carry over the king's household, and to do what he thought good. Now Shimei the son of Gera fell down before the king when he had crossed the Jordan.", 
                "num": 18
              }, 
              {
                "text": "Then he said to the king, \"Do not let my lord impute iniquity to me, or remember what wrong your servant did on the day that my lord the king left Jerusalem, that the king should take it to heart.", 
                "num": 19
              }, 
              {
                "text": "For I, your servant, know that I have sinned. Therefore here I am, the first to come today of all the house of Joseph to go down to meet my lord the king.\"", 
                "num": 20
              }, 
              {
                "text": "But Abishai the son of Zeruiah answered and said, \"Shall not Shimei be put to death for this, because he cursed the LORD's anointed?\"", 
                "num": 21
              }, 
              {
                "text": "And David said, \"What have I to do with you, you sons of Zeruiah, that you should be adversaries to me today? Shall any man be put to death today in Israel? For do I not know that today I am king over Israel?\"", 
                "num": 22
              }, 
              {
                "text": "Therefore the king said to Shimei, \"You shall not die.\" And the king swore to him.", 
                "num": 23
              }, 
              {
                "text": "Now Mephibosheth the son of Saul came down to meet the king. And he had not cared for his feet, nor trimmed his mustache, nor washed his clothes, from the day the king departed until the day he returned in peace.", 
                "num": 24
              }, 
              {
                "text": "So it was, when he had come to Jerusalem to meet the king, that the king said to him, \"Why did you not go with me, Mephibosheth?\"", 
                "num": 25
              }, 
              {
                "text": "And he answered, \"My lord, O king, my servant deceived me. For your servant said, \"I will saddle a donkey for myself, that I may ride on it and go to the king,' because your servant is lame.", 
                "num": 26
              }, 
              {
                "text": "And he has slandered your servant to my lord the king, but my lord the king is like the angel of God. Therefore do what is good in your eyes.", 
                "num": 27
              }, 
              {
                "text": "For all my father's house were but dead men before my lord the king. Yet you set your servant among those who eat at your own table. Therefore what right have I still to cry out anymore to the king?\"", 
                "num": 28
              }, 
              {
                "text": "So the king said to him, \"Why do you speak anymore of your matters? I have said, \"You and Ziba divide the land.\"'", 
                "num": 29
              }, 
              {
                "text": "Then Mephibosheth said to the king, \"Rather, let him take it all, inasmuch as my lord the king has come back in peace to his own house.\"", 
                "num": 30
              }, 
              {
                "text": "And Barzillai the Gileadite came down from Rogelim and went across the Jordan with the king, to escort him across the Jordan.", 
                "num": 31
              }, 
              {
                "text": "Now Barzillai was a very aged man, eighty years old. And he had provided the king with supplies while he stayed at Mahanaim, for he was a very rich man.", 
                "num": 32
              }, 
              {
                "text": "And the king said to Barzillai, \"Come across with me, and I will provide for you while you are with me in Jerusalem.\"", 
                "num": 33
              }, 
              {
                "text": "But Barzillai said to the king, \"How long have I to live, that I should go up with the king to Jerusalem?", 
                "num": 34
              }, 
              {
                "text": "I am today eighty years old. Can I discern between the good and bad? Can your servant taste what I eat or what I drink? Can I hear any longer the voice of singing men and singing women? Why then should your servant be a further burden to my lord the king?", 
                "num": 35
              }, 
              {
                "text": "Your servant will go a little way across the Jordan with the king. And why should the king repay me with such a reward?", 
                "num": 36
              }, 
              {
                "text": "Please let your servant turn back again, that I may die in my own city, near the grave of my father and mother. But here is your servant Chimham; let him cross over with my lord the king, and do for him what seems good to you.\"", 
                "num": 37
              }, 
              {
                "text": "And the king answered, \"Chimham shall cross over with me, and I will do for him what seems good to you. Now whatever you request of me, I will do for you.\"", 
                "num": 38
              }, 
              {
                "text": "Then all the people went over the Jordan. And when the king had crossed over, the king kissed Barzillai and blessed him, and he returned to his own place.", 
                "num": 39
              }, 
              {
                "text": "Now the king went on to Gilgal, and Chimham went on with him. And all the people of Judah escorted the king, and also half the people of Israel.", 
                "num": 40
              }, 
              {
                "text": "Just then all the men of Israel came to the king, and said to the king, \"Why have our brethren, the men of Judah, stolen you away and brought the king, his household, and all David's men with him across the Jordan?\"", 
                "num": 41
              }, 
              {
                "text": "So all the men of Judah answered the men of Israel, \"Because the king is a close relative of ours. Why then are you angry over this matter? Have we ever eaten at the king's expense? Or has he given us any gift?\"", 
                "num": 42
              }, 
              {
                "text": "And the men of Israel answered the men of Judah, and said, \"We have ten shares in the king; therefore we also have more right to David than you. Why then do you despise us--were we not the first to advise bringing back our king?\" Yet the words of the men of Judah were fiercer than the words of the men of Israel.", 
                "num": 43
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "And there happened to be there a rebel, whose name was Sheba the son of Bichri, a Benjamite. And he blew a trumpet, and said: \"We have no share in David, Nor do we have inheritance in the son of Jesse; Every man to his tents, O Israel!\"", 
                "num": 1
              }, 
              {
                "text": "So every man of Israel deserted David, and followed Sheba the son of Bichri. But the men of Judah, from the Jordan as far as Jerusalem, remained loyal to their king.", 
                "num": 2
              }, 
              {
                "text": "Now David came to his house at Jerusalem. And the king took the ten women, his concubines whom he had left to keep the house, and put them in seclusion and supported them, but did not go in to them. So they were shut up to the day of their death, living in widowhood.", 
                "num": 3
              }, 
              {
                "text": "And the king said to Amasa, \"Assemble the men of Judah for me within three days, and be present here yourself.\"", 
                "num": 4
              }, 
              {
                "text": "So Amasa went to assemble the men of Judah. But he delayed longer than the set time which David had appointed him.", 
                "num": 5
              }, 
              {
                "text": "And David said to Abishai, \"Now Sheba the son of Bichri will do us more harm than Absalom. Take your lord's servants and pursue him, lest he find for himself fortified cities, and escape us.\"", 
                "num": 6
              }, 
              {
                "text": "So Joab's men, with the Cherethites, the Pelethites, and all the mighty men, went out after him. And they went out of Jerusalem to pursue Sheba the son of Bichri.", 
                "num": 7
              }, 
              {
                "text": "When they were at the large stone which is in Gibeon, Amasa came before them. Now Joab was dressed in battle armor; on it was a belt with a sword fastened in its sheath at his hips; and as he was going forward, it fell out.", 
                "num": 8
              }, 
              {
                "text": "Then Joab said to Amasa, \"Are you in health, my brother?\" And Joab took Amasa by the beard with his right hand to kiss him.", 
                "num": 9
              }, 
              {
                "text": "But Amasa did not notice the sword that was in Joab's hand. And he struck him with it in the stomach, and his entrails poured out on the ground; and he did not strike him again. Thus he died. Then Joab and Abishai his brother pursued Sheba the son of Bichri.", 
                "num": 10
              }, 
              {
                "text": "Meanwhile one of Joab's men stood near Amasa, and said, \"Whoever favors Joab and whoever is for David--follow Joab!\"", 
                "num": 11
              }, 
              {
                "text": "But Amasa wallowed in his blood in the middle of the highway. And when the man saw that all the people stood still, he moved Amasa from the highway to the field and threw a garment over him, when he saw that everyone who came upon him halted.", 
                "num": 12
              }, 
              {
                "text": "When he was removed from the highway, all the people went on after Joab to pursue Sheba the son of Bichri.", 
                "num": 13
              }, 
              {
                "text": "And he went through all the tribes of Israel to Abel and Beth Maachah and all the Berites. So they were gathered together and also went after Sheba.", 
                "num": 14
              }, 
              {
                "text": "Then they came and besieged him in Abel of Beth Maachah; and they cast up a siege mound against the city, and it stood by the rampart. And all the people who were with Joab battered the wall to throw it down.", 
                "num": 15
              }, 
              {
                "text": "Then a wise woman cried out from the city, \"Hear, hear! Please say to Joab, \"Come nearby, that I may speak with you.\"'", 
                "num": 16
              }, 
              {
                "text": "When he had come near to her, the woman said, \"Are you Joab?\" He answered, \"I am.\" Then she said to him, \"Hear the words of your maidservant.\" And he answered, \"I am listening.\"", 
                "num": 17
              }, 
              {
                "text": "So she spoke, saying, \"They used to talk in former times, saying, \"They shall surely seek guidance at Abel,' and so they would end disputes.", 
                "num": 18
              }, 
              {
                "text": "I am among the peaceable and faithful in Israel. You seek to destroy a city and a mother in Israel. Why would you swallow up the inheritance of the LORD?\"", 
                "num": 19
              }, 
              {
                "text": "And Joab answered and said, \"Far be it, far be it from me, that I should swallow up or destroy!", 
                "num": 20
              }, 
              {
                "text": "That is not so. But a man from the mountains of Ephraim, Sheba the son of Bichri by name, has raised his hand against the king, against David. Deliver him only, and I will depart from the city.\" So the woman said to Joab, \"Watch, his head will be thrown to you over the wall.\"", 
                "num": 21
              }, 
              {
                "text": "Then the woman in her wisdom went to all the people. And they cut off the head of Sheba the son of Bichri, and threw it out to Joab. Then he blew a trumpet, and they withdrew from the city, every man to his tent. So Joab returned to the king at Jerusalem.", 
                "num": 22
              }, 
              {
                "text": "And Joab was over all the army of Israel; Benaiah the son of Jehoiada was over the Cherethites and the Pelethites;", 
                "num": 23
              }, 
              {
                "text": "Adoram was in charge of revenue; Jehoshaphat the son of Ahilud was recorder;", 
                "num": 24
              }, 
              {
                "text": "Sheva was scribe; Zadok and Abiathar were the priests;", 
                "num": 25
              }, 
              {
                "text": "and Ira the Jairite was a chief minister under David.", 
                "num": 26
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now there was a famine in the days of David for three years, year after year; and David inquired of the LORD. And the LORD answered, \"It is because of Saul and his bloodthirsty house, because he killed the Gibeonites.\"", 
                "num": 1
              }, 
              {
                "text": "So the king called the Gibeonites and spoke to them. Now the Gibeonites were not of the children of Israel, but of the remnant of the Amorites; the children of Israel had sworn protection to them, but Saul had sought to kill them in his zeal for the children of Israel and Judah.", 
                "num": 2
              }, 
              {
                "text": "Therefore David said to the Gibeonites, \"What shall I do for you? And with what shall I make atonement, that you may bless the inheritance of the LORD?\"", 
                "num": 3
              }, 
              {
                "text": "And the Gibeonites said to him, \"We will have no silver or gold from Saul or from his house, nor shall you kill any man in Israel for us.\" So he said, \"Whatever you say, I will do for you.\"", 
                "num": 4
              }, 
              {
                "text": "Then they answered the king, \"As for the man who consumed us and plotted against us, that we should be destroyed from remaining in any of the territories of Israel,", 
                "num": 5
              }, 
              {
                "text": "let seven men of his descendants be delivered to us, and we will hang them before the LORD in Gibeah of Saul, whom the LORD chose.\" And the king said, \"I will give them.\"", 
                "num": 6
              }, 
              {
                "text": "But the king spared Mephibosheth the son of Jonathan, the son of Saul, because of the LORD's oath that was between them, between David and Jonathan the son of Saul.", 
                "num": 7
              }, 
              {
                "text": "So the king took Armoni and Mephibosheth, the two sons of Rizpah the daughter of Aiah, whom she bore to Saul, and the five sons of Michal the daughter of Saul, whom she brought up for Adriel the son of Barzillai the Meholathite;", 
                "num": 8
              }, 
              {
                "text": "and he delivered them into the hands of the Gibeonites, and they hanged them on the hill before the LORD. So they fell, all seven together, and were put to death in the days of harvest, in the first days, in the beginning of barley harvest.", 
                "num": 9
              }, 
              {
                "text": "Now Rizpah the daughter of Aiah took sackcloth and spread it for herself on the rock, from the beginning of harvest until the late rains poured on them from heaven. And she did not allow the birds of the air to rest on them by day nor the beasts of the field by night.", 
                "num": 10
              }, 
              {
                "text": "And David was told what Rizpah the daughter of Aiah, the concubine of Saul, had done.", 
                "num": 11
              }, 
              {
                "text": "Then David went and took the bones of Saul, and the bones of Jonathan his son, from the men of Jabesh Gilead who had stolen them from the street of Beth Shan, where the Philistines had hung them up, after the Philistines had struck down Saul in Gilboa.", 
                "num": 12
              }, 
              {
                "text": "So he brought up the bones of Saul and the bones of Jonathan his son from there; and they gathered the bones of those who had been hanged.", 
                "num": 13
              }, 
              {
                "text": "They buried the bones of Saul and Jonathan his son in the country of Benjamin in Zelah, in the tomb of Kish his father. So they performed all that the king commanded. And after that God heeded the prayer for the land.", 
                "num": 14
              }, 
              {
                "text": "When the Philistines were at war again with Israel, David and his servants with him went down and fought against the Philistines; and David grew faint.", 
                "num": 15
              }, 
              {
                "text": "Then Ishbi-Benob, who was one of the sons of the giant, the weight of whose bronze spear was three hundred shekels, who was bearing a new sword, thought he could kill David.", 
                "num": 16
              }, 
              {
                "text": "But Abishai the son of Zeruiah came to his aid, and struck the Philistine and killed him. Then the men of David swore to him, saying, \"You shall go out no more with us to battle, lest you quench the lamp of Israel.\"", 
                "num": 17
              }, 
              {
                "text": "Now it happened afterward that there was again a battle with the Philistines at Gob. Then Sibbechai the Hushathite killed Saph, who was one of the sons of the giant.", 
                "num": 18
              }, 
              {
                "text": "Again there was war at Gob with the Philistines, where Elhanan the son of Jaare-Oregim the Bethlehemite killed the brother of Goliath the Gittite, the shaft of whose spear was like a weaver's beam.", 
                "num": 19
              }, 
              {
                "text": "Yet again there was war at Gath, where there was a man of great stature, who had six fingers on each hand and six toes on each foot, twenty-four in number; and he also was born to the giant.", 
                "num": 20
              }, 
              {
                "text": "So when he defied Israel, Jonathan the son of Shimea, David's brother, killed him.", 
                "num": 21
              }, 
              {
                "text": "These four were born to the giant in Gath, and fell by the hand of David and by the hand of his servants.", 
                "num": 22
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then David spoke to the LORD the words of this song, on the day when the LORD had delivered him from the hand of all his enemies, and from the hand of Saul.", 
                "num": 1
              }, 
              {
                "text": "And he said: \"The LORD is my rock and my fortress and my deliverer;", 
                "num": 2
              }, 
              {
                "text": "The God of my strength, in whom I will trust; My shield and the horn of my salvation, My stronghold and my refuge; My Savior, You save me from violence.", 
                "num": 3
              }, 
              {
                "text": "I will call upon the LORD, who is worthy to be praised; So shall I be saved from my enemies.", 
                "num": 4
              }, 
              {
                "text": "\"When the waves of death surrounded me, The floods of ungodliness made me afraid.", 
                "num": 5
              }, 
              {
                "text": "The sorrows of Sheol surrounded me; The snares of death confronted me.", 
                "num": 6
              }, 
              {
                "text": "In my distress I called upon the LORD, And cried out to my God; He heard my voice from His temple, And my cry entered His ears.", 
                "num": 7
              }, 
              {
                "text": "\"Then the earth shook and trembled; The foundations of heaven quaked and were shaken, Because He was angry.", 
                "num": 8
              }, 
              {
                "text": "Smoke went up from His nostrils, And devouring fire from His mouth; Coals were kindled by it.", 
                "num": 9
              }, 
              {
                "text": "He bowed the heavens also, and came down With darkness under His feet.", 
                "num": 10
              }, 
              {
                "text": "He rode upon a cherub, and flew; And He was seen upon the wings of the wind.", 
                "num": 11
              }, 
              {
                "text": "He made darkness canopies around Him, Dark waters and thick clouds of the skies.", 
                "num": 12
              }, 
              {
                "text": "From the brightness before Him Coals of fire were kindled.", 
                "num": 13
              }, 
              {
                "text": "\"The LORD thundered from heaven, And the Most High uttered His voice.", 
                "num": 14
              }, 
              {
                "text": "He sent out arrows and scattered them; Lightning bolts, and He vanquished them.", 
                "num": 15
              }, 
              {
                "text": "Then the channels of the sea were seen, The foundations of the world were uncovered, At the rebuke of the LORD, At the blast of the breath of His nostrils.", 
                "num": 16
              }, 
              {
                "text": "\"He sent from above, He took me, He drew me out of many waters.", 
                "num": 17
              }, 
              {
                "text": "He delivered me from my strong enemy, From those who hated me; For they were too strong for me.", 
                "num": 18
              }, 
              {
                "text": "They confronted me in the day of my calamity, But the LORD was my support.", 
                "num": 19
              }, 
              {
                "text": "He also brought me out into a broad place; He delivered me because He delighted in me.", 
                "num": 20
              }, 
              {
                "text": "\"The LORD rewarded me according to my righteousness; According to the cleanness of my hands He has recompensed me.", 
                "num": 21
              }, 
              {
                "text": "For I have kept the ways of the LORD, And have not wickedly departed from my God.", 
                "num": 22
              }, 
              {
                "text": "For all His judgments were before me; And as for His statutes, I did not depart from them.", 
                "num": 23
              }, 
              {
                "text": "I was also blameless before Him, And I kept myself from my iniquity.", 
                "num": 24
              }, 
              {
                "text": "Therefore the LORD has recompensed me according to my righteousness, According to my cleanness in His eyes.", 
                "num": 25
              }, 
              {
                "text": "\"With the merciful You will show Yourself merciful; With a blameless man You will show Yourself blameless;", 
                "num": 26
              }, 
              {
                "text": "With the pure You will show Yourself pure; And with the devious You will show Yourself shrewd.", 
                "num": 27
              }, 
              {
                "text": "You will save the humble people; But Your eyes are on the haughty, that You may bring them down.", 
                "num": 28
              }, 
              {
                "text": "\"For You are my lamp, O LORD; The LORD shall enlighten my darkness.", 
                "num": 29
              }, 
              {
                "text": "For by You I can run against a troop; By my God I can leap over a wall.", 
                "num": 30
              }, 
              {
                "text": "As for God, His way is perfect; The word of the LORD is proven; He is a shield to all who trust in Him.", 
                "num": 31
              }, 
              {
                "text": "\"For who is God, except the LORD? And who is a rock, except our God?", 
                "num": 32
              }, 
              {
                "text": "God is my strength and power, And He makes my way perfect.", 
                "num": 33
              }, 
              {
                "text": "He makes my feet like the feet of deer, And sets me on my high places.", 
                "num": 34
              }, 
              {
                "text": "He teaches my hands to make war, So that my arms can bend a bow of bronze.", 
                "num": 35
              }, 
              {
                "text": "\"You have also given me the shield of Your salvation; Your gentleness has made me great.", 
                "num": 36
              }, 
              {
                "text": "You enlarged my path under me; So my feet did not slip.", 
                "num": 37
              }, 
              {
                "text": "\"I have pursued my enemies and destroyed them; Neither did I turn back again till they were destroyed.", 
                "num": 38
              }, 
              {
                "text": "And I have destroyed them and wounded them, So that they could not rise; They have fallen under my feet.", 
                "num": 39
              }, 
              {
                "text": "For You have armed me with strength for the battle; You have subdued under me those who rose against me.", 
                "num": 40
              }, 
              {
                "text": "You have also given me the necks of my enemies, So that I destroyed those who hated me.", 
                "num": 41
              }, 
              {
                "text": "They looked, but there was none to save; Even to the LORD, but He did not answer them.", 
                "num": 42
              }, 
              {
                "text": "Then I beat them as fine as the dust of the earth; I trod them like dirt in the streets, And I spread them out.", 
                "num": 43
              }, 
              {
                "text": "\"You have also delivered me from the strivings of my people; You have kept me as the head of the nations. A people I have not known shall serve me.", 
                "num": 44
              }, 
              {
                "text": "The foreigners submit to me; As soon as they hear, they obey me.", 
                "num": 45
              }, 
              {
                "text": "The foreigners fade away, And come frightened from their hideouts.", 
                "num": 46
              }, 
              {
                "text": "\"The LORD lives! Blessed be my Rock! Let God be exalted, The Rock of my salvation!", 
                "num": 47
              }, 
              {
                "text": "It is God who avenges me, And subdues the peoples under me;", 
                "num": 48
              }, 
              {
                "text": "He delivers me from my enemies. You also lift me up above those who rise against me; You have delivered me from the violent man.", 
                "num": 49
              }, 
              {
                "text": "Therefore I will give thanks to You, O LORD, among the Gentiles, And sing praises to Your name.", 
                "num": 50
              }, 
              {
                "text": "He is the tower of salvation to His king, And shows mercy to His anointed, To David and his descendants forevermore.\"", 
                "num": 51
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Now these are the last words of David. Thus says David the son of Jesse; Thus says the man raised up on high, The anointed of the God of Jacob, And the sweet psalmist of Israel:", 
                "num": 1
              }, 
              {
                "text": "\"The Spirit of the LORD spoke by me, And His word was on my tongue.", 
                "num": 2
              }, 
              {
                "text": "The God of Israel said, The Rock of Israel spoke to me: \"He who rules over men must be just, Ruling in the fear of God.", 
                "num": 3
              }, 
              {
                "text": "And he shall be like the light of the morning when the sun rises, A morning without clouds, Like the tender grass springing out of the earth, By clear shining after rain.'", 
                "num": 4
              }, 
              {
                "text": "\"Although my house is not so with God, Yet He has made with me an everlasting covenant, Ordered in all things and secure. For this is all my salvation and all my desire; Will He not make it increase?", 
                "num": 5
              }, 
              {
                "text": "But the sons of rebellion shall all be as thorns thrust away, Because they cannot be taken with hands.", 
                "num": 6
              }, 
              {
                "text": "But the man who touches them Must be armed with iron and the shaft of a spear, And they shall be utterly burned with fire in their place.\"", 
                "num": 7
              }, 
              {
                "text": "These are the names of the mighty men whom David had: Josheb-Basshebeth the Tachmonite, chief among the captains. He was called Adino the Eznite, because he had killed eight hundred men at one time.", 
                "num": 8
              }, 
              {
                "text": "And after him was Eleazar the son of Dodo, the Ahohite, one of the three mighty men with David when they defied the Philistines who were gathered there for battle, and the men of Israel had retreated.", 
                "num": 9
              }, 
              {
                "text": "He arose and attacked the Philistines until his hand was weary, and his hand stuck to the sword. The LORD brought about a great victory that day; and the people returned after him only to plunder.", 
                "num": 10
              }, 
              {
                "text": "And after him was Shammah the son of Agee the Hararite. The Philistines had gathered together into a troop where there was a piece of ground full of lentils. So the people fled from the Philistines.", 
                "num": 11
              }, 
              {
                "text": "But he stationed himself in the middle of the field, defended it, and killed the Philistines. So the LORD brought about a great victory.", 
                "num": 12
              }, 
              {
                "text": "Then three of the thirty chief men went down at harvest time and came to David at the cave of Adullam. And the troop of Philistines encamped in the Valley of Rephaim.", 
                "num": 13
              }, 
              {
                "text": "David was then in the stronghold, and the garrison of the Philistines was then in Bethlehem.", 
                "num": 14
              }, 
              {
                "text": "And David said with longing, \"Oh, that someone would give me a drink of the water from the well of Bethlehem, which is by the gate!\"", 
                "num": 15
              }, 
              {
                "text": "So the three mighty men broke through the camp of the Philistines, drew water from the well of Bethlehem that was by the gate, and took it and brought it to David. Nevertheless he would not drink it, but poured it out to the LORD.", 
                "num": 16
              }, 
              {
                "text": "And he said, \"Far be it from me, O LORD, that I should do this! Is this not the blood of the men who went in jeopardy of their lives?\" Therefore he would not drink it. These things were done by the three mighty men.", 
                "num": 17
              }, 
              {
                "text": "Now Abishai the brother of Joab, the son of Zeruiah, was chief of another three. He lifted his spear against three hundred men, killed them, and won a name among these three.", 
                "num": 18
              }, 
              {
                "text": "Was he not the most honored of three? Therefore he became their captain. However, he did not attain to the first three.", 
                "num": 19
              }, 
              {
                "text": "Benaiah was the son of Jehoiada, the son of a valiant man from Kabzeel, who had done many deeds. He had killed two lion-like heroes of Moab. He also had gone down and killed a lion in the midst of a pit on a snowy day.", 
                "num": 20
              }, 
              {
                "text": "And he killed an Egyptian, a spectacular man. The Egyptian had a spear in his hand; so he went down to him with a staff, wrested the spear out of the Egyptian's hand, and killed him with his own spear.", 
                "num": 21
              }, 
              {
                "text": "These things Benaiah the son of Jehoiada did, and won a name among three mighty men.", 
                "num": 22
              }, 
              {
                "text": "He was more honored than the thirty, but he did not attain to the first three. And David appointed him over his guard.", 
                "num": 23
              }, 
              {
                "text": "Asahel the brother of Joab was one of the thirty; Elhanan the son of Dodo of Bethlehem,", 
                "num": 24
              }, 
              {
                "text": "Shammah the Harodite, Elika the Harodite,", 
                "num": 25
              }, 
              {
                "text": "Helez the Paltite, Ira the son of Ikkesh the Tekoite,", 
                "num": 26
              }, 
              {
                "text": "Abiezer the Anathothite, Mebunnai the Hushathite,", 
                "num": 27
              }, 
              {
                "text": "Zalmon the Ahohite, Maharai the Netophathite,", 
                "num": 28
              }, 
              {
                "text": "Heleb the son of Baanah (the Netophathite), Ittai the son of Ribai from Gibeah of the children of Benjamin,", 
                "num": 29
              }, 
              {
                "text": "Benaiah a Pirathonite, Hiddai from the brooks of Gaash,", 
                "num": 30
              }, 
              {
                "text": "Abi-Albon the Arbathite, Azmaveth the Barhumite,", 
                "num": 31
              }, 
              {
                "text": "Eliahba the Shaalbonite (of the sons of Jashen), Jonathan,", 
                "num": 32
              }, 
              {
                "text": "Shammah the Hararite, Ahiam the son of Sharar the Hararite,", 
                "num": 33
              }, 
              {
                "text": "Eliphelet the son of Ahasbai, the son of the Maachathite, Eliam the son of Ahithophel the Gilonite,", 
                "num": 34
              }, 
              {
                "text": "Hezrai the Carmelite, Paarai the Arbite,", 
                "num": 35
              }, 
              {
                "text": "Igal the son of Nathan of Zobah, Bani the Gadite,", 
                "num": 36
              }, 
              {
                "text": "Zelek the Ammonite, Naharai the Beerothite (armorbearer of Joab the son of Zeruiah),", 
                "num": 37
              }, 
              {
                "text": "Ira the Ithrite, Gareb the Ithrite,", 
                "num": 38
              }, 
              {
                "text": "and Uriah the Hittite: thirty-seven in all.", 
                "num": 39
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Again the anger of the LORD was aroused against Israel, and He moved David against them to say, \"Go, number Israel and Judah.\"", 
                "num": 1
              }, 
              {
                "text": "So the king said to Joab the commander of the army who was with him, \"Now go throughout all the tribes of Israel, from Dan to Beersheba, and count the people, that I may know the number of the people.\"", 
                "num": 2
              }, 
              {
                "text": "And Joab said to the king, \"Now may the LORD your God add to the people a hundred times more than there are, and may the eyes of my lord the king see it. But why does my lord the king desire this thing?\"", 
                "num": 3
              }, 
              {
                "text": "Nevertheless the king's word prevailed against Joab and against the captains of the army. Therefore Joab and the captains of the army went out from the presence of the king to count the people of Israel.", 
                "num": 4
              }, 
              {
                "text": "And they crossed over the Jordan and camped in Aroer, on the right side of the town which is in the midst of the ravine of Gad, and toward Jazer.", 
                "num": 5
              }, 
              {
                "text": "Then they came to Gilead and to the land of Tahtim Hodshi; they came to Dan Jaan and around to Sidon;", 
                "num": 6
              }, 
              {
                "text": "and they came to the stronghold of Tyre and to all the cities of the Hivites and the Canaanites. Then they went out to South Judah as far as Beersheba.", 
                "num": 7
              }, 
              {
                "text": "So when they had gone through all the land, they came to Jerusalem at the end of nine months and twenty days.", 
                "num": 8
              }, 
              {
                "text": "Then Joab gave the sum of the number of the people to the king. And there were in Israel eight hundred thousand valiant men who drew the sword, and the men of Judah were five hundred thousand men.", 
                "num": 9
              }, 
              {
                "text": "And David's heart condemned him after he had numbered the people. So David said to the LORD, \"I have sinned greatly in what I have done; but now, I pray, O LORD, take away the iniquity of Your servant, for I have done very foolishly.\"", 
                "num": 10
              }, 
              {
                "text": "Now when David arose in the morning, the word of the LORD came to the prophet Gad, David's seer, saying,", 
                "num": 11
              }, 
              {
                "text": "\"Go and tell David, \"Thus says the LORD: \"I offer you three things; choose one of them for yourself, that I may do it to you.\"\"'", 
                "num": 12
              }, 
              {
                "text": "So Gad came to David and told him; and he said to him, \"Shall seven years of famine come to you in your land? Or shall you flee three months before your enemies, while they pursue you? Or shall there be three days' plague in your land? Now consider and see what answer I should take back to Him who sent me.\"", 
                "num": 13
              }, 
              {
                "text": "And David said to Gad, \"I am in great distress. Please let us fall into the hand of the LORD, for His mercies are great; but do not let me fall into the hand of man.\"", 
                "num": 14
              }, 
              {
                "text": "So the LORD sent a plague upon Israel from the morning till the appointed time. From Dan to Beersheba seventy thousand men of the people died.", 
                "num": 15
              }, 
              {
                "text": "And when the angel stretched out His hand over Jerusalem to destroy it, the LORD relented from the destruction, and said to the angel who was destroying the people, \"It is enough; now restrain your hand.\" And the angel of the LORD was by the threshing floor of Araunah the Jebusite.", 
                "num": 16
              }, 
              {
                "text": "Then David spoke to the LORD when he saw the angel who was striking the people, and said, \"Surely I have sinned, and I have done wickedly; but these sheep, what have they done? Let Your hand, I pray, be against me and against my father's house.\"", 
                "num": 17
              }, 
              {
                "text": "And Gad came that day to David and said to him, \"Go up, erect an altar to the LORD on the threshing floor of Araunah the Jebusite.\"", 
                "num": 18
              }, 
              {
                "text": "So David, according to the word of Gad, went up as the LORD commanded.", 
                "num": 19
              }, 
              {
                "text": "Now Araunah looked, and saw the king and his servants coming toward him. So Araunah went out and bowed before the king with his face to the ground.", 
                "num": 20
              }, 
              {
                "text": "Then Araunah said, \"Why has my lord the king come to his servant?\" And David said, \"To buy the threshing floor from you, to build an altar to the LORD, that the plague may be withdrawn from the people.\"", 
                "num": 21
              }, 
              {
                "text": "Now Araunah said to David, \"Let my lord the king take and offer up whatever seems good to him. Look, here are oxen for burnt sacrifice, and threshing implements and the yokes of the oxen for wood.", 
                "num": 22
              }, 
              {
                "text": "All these, O king, Araunah has given to the king.\" And Araunah said to the king, \"May the LORD your God accept you.\"", 
                "num": 23
              }, 
              {
                "text": "Then the king said to Araunah, \"No, but I will surely buy it from you for a price; nor will I offer burnt offerings to the LORD my God with that which costs me nothing.\" So David bought the threshing floor and the oxen for fifty shekels of silver.", 
                "num": 24
              }, 
              {
                "text": "And David built there an altar to the LORD, and offered burnt offerings and peace offerings. So the LORD heeded the prayers for the land, and the plague was withdrawn from Israel.", 
                "num": 25
              }
            ], 
            "num": 24
          }
        ]
      }, 
      {
        "name": "1 Kings", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now King David was old, advanced in years; and they put covers on him, but he could not get warm.", 
                "num": 1
              }, 
              {
                "text": "Therefore his servants said to him, \"Let a young woman, a virgin, be sought for our lord the king, and let her stand before the king, and let her care for him; and let her lie in your bosom, that our lord the king may be warm.\"", 
                "num": 2
              }, 
              {
                "text": "So they sought for a lovely young woman throughout all the territory of Israel, and found Abishag the Shunammite, and brought her to the king.", 
                "num": 3
              }, 
              {
                "text": "The young woman was very lovely; and she cared for the king, and served him; but the king did not know her.", 
                "num": 4
              }, 
              {
                "text": "Then Adonijah the son of Haggith exalted himself, saying, \"I will be king\"; and he prepared for himself chariots and horsemen, and fifty men to run before him.", 
                "num": 5
              }, 
              {
                "text": "(And his father had not rebuked him at any time by saying, \"Why have you done so?\" He was also very good-looking. His mother had borne him after Absalom.)", 
                "num": 6
              }, 
              {
                "text": "Then he conferred with Joab the son of Zeruiah and with Abiathar the priest, and they followed and helped Adonijah.", 
                "num": 7
              }, 
              {
                "text": "But Zadok the priest, Benaiah the son of Jehoiada, Nathan the prophet, Shimei, Rei, and the mighty men who belonged to David were not with Adonijah.", 
                "num": 8
              }, 
              {
                "text": "And Adonijah sacrificed sheep and oxen and fattened cattle by the stone of Zoheleth, which is by En Rogel; he also invited all his brothers, the king's sons, and all the men of Judah, the king's servants.", 
                "num": 9
              }, 
              {
                "text": "But he did not invite Nathan the prophet, Benaiah, the mighty men, or Solomon his brother.", 
                "num": 10
              }, 
              {
                "text": "So Nathan spoke to Bathsheba the mother of Solomon, saying, \"Have you not heard that Adonijah the son of Haggith has become king, and David our lord does not know it?", 
                "num": 11
              }, 
              {
                "text": "Come, please, let me now give you advice, that you may save your own life and the life of your son Solomon.", 
                "num": 12
              }, 
              {
                "text": "Go immediately to King David and say to him, \"Did you not, my lord, O king, swear to your maidservant, saying, \"Assuredly your son Solomon shall reign after me, and he shall sit on my throne\"? Why then has Adonijah become king?'", 
                "num": 13
              }, 
              {
                "text": "Then, while you are still talking there with the king, I also will come in after you and confirm your words.\"", 
                "num": 14
              }, 
              {
                "text": "So Bathsheba went into the chamber to the king. (Now the king was very old, and Abishag the Shunammite was serving the king.)", 
                "num": 15
              }, 
              {
                "text": "And Bathsheba bowed and did homage to the king. Then the king said, \"What is your wish?\"", 
                "num": 16
              }, 
              {
                "text": "Then she said to him, \"My lord, you swore by the LORD your God to your maidservant, saying, \"Assuredly Solomon your son shall reign after me, and he shall sit on my throne.'", 
                "num": 17
              }, 
              {
                "text": "So now, look! Adonijah has become king; and now, my lord the king, you do not know about it.", 
                "num": 18
              }, 
              {
                "text": "He has sacrificed oxen and fattened cattle and sheep in abundance, and has invited all the sons of the king, Abiathar the priest, and Joab the commander of the army; but Solomon your servant he has not invited.", 
                "num": 19
              }, 
              {
                "text": "And as for you, my lord, O king, the eyes of all Israel are on you, that you should tell them who will sit on the throne of my lord the king after him.", 
                "num": 20
              }, 
              {
                "text": "Otherwise it will happen, when my lord the king rests with his fathers, that I and my son Solomon will be counted as offenders.\"", 
                "num": 21
              }, 
              {
                "text": "And just then, while she was still talking with the king, Nathan the prophet also came in.", 
                "num": 22
              }, 
              {
                "text": "So they told the king, saying, \"Here is Nathan the prophet.\" And when he came in before the king, he bowed down before the king with his face to the ground.", 
                "num": 23
              }, 
              {
                "text": "And Nathan said, \"My lord, O king, have you said, \"Adonijah shall reign after me, and he shall sit on my throne'?", 
                "num": 24
              }, 
              {
                "text": "For he has gone down today, and has sacrificed oxen and fattened cattle and sheep in abundance, and has invited all the king's sons, and the commanders of the army, and Abiathar the priest; and look! They are eating and drinking before him; and they say, \"Long live King Adonijah!'", 
                "num": 25
              }, 
              {
                "text": "But he has not invited me--me your servant--nor Zadok the priest, nor Benaiah the son of Jehoiada, nor your servant Solomon.", 
                "num": 26
              }, 
              {
                "text": "Has this thing been done by my lord the king, and you have not told your servant who should sit on the throne of my lord the king after him?\"", 
                "num": 27
              }, 
              {
                "text": "Then King David answered and said, \"Call Bathsheba to me.\" So she came into the king's presence and stood before the king.", 
                "num": 28
              }, 
              {
                "text": "And the king took an oath and said, \"As the LORD lives, who has redeemed my life from every distress,", 
                "num": 29
              }, 
              {
                "text": "just as I swore to you by the LORD God of Israel, saying, \"Assuredly Solomon your son shall be king after me, and he shall sit on my throne in my place,' so I certainly will do this day.\"", 
                "num": 30
              }, 
              {
                "text": "Then Bathsheba bowed with her face to the earth, and paid homage to the king, and said, \"Let my lord King David live forever!\"", 
                "num": 31
              }, 
              {
                "text": "And King David said, \"Call to me Zadok the priest, Nathan the prophet, and Benaiah the son of Jehoiada.\" So they came before the king.", 
                "num": 32
              }, 
              {
                "text": "The king also said to them, \"Take with you the servants of your lord, and have Solomon my son ride on my own mule, and take him down to Gihon.", 
                "num": 33
              }, 
              {
                "text": "There let Zadok the priest and Nathan the prophet anoint him king over Israel; and blow the horn, and say, \"Long live King Solomon!'", 
                "num": 34
              }, 
              {
                "text": "Then you shall come up after him, and he shall come and sit on my throne, and he shall be king in my place. For I have appointed him to be ruler over Israel and Judah.\"", 
                "num": 35
              }, 
              {
                "text": "Benaiah the son of Jehoiada answered the king and said, \"Amen! May the LORD God of my lord the king say so too.", 
                "num": 36
              }, 
              {
                "text": "As the LORD has been with my lord the king, even so may He be with Solomon, and make his throne greater than the throne of my lord King David.\"", 
                "num": 37
              }, 
              {
                "text": "So Zadok the priest, Nathan the prophet, Benaiah the son of Jehoiada, the Cherethites, and the Pelethites went down and had Solomon ride on King David's mule, and took him to Gihon.", 
                "num": 38
              }, 
              {
                "text": "Then Zadok the priest took a horn of oil from the tabernacle and anointed Solomon. And they blew the horn, and all the people said, \"Long live King Solomon!\"", 
                "num": 39
              }, 
              {
                "text": "And all the people went up after him; and the people played the flutes and rejoiced with great joy, so that the earth seemed to split with their sound.", 
                "num": 40
              }, 
              {
                "text": "Now Adonijah and all the guests who were with him heard it as they finished eating. And when Joab heard the sound of the horn, he said, \"Why is the city in such a noisy uproar?\"", 
                "num": 41
              }, 
              {
                "text": "While he was still speaking, there came Jonathan, the son of Abiathar the priest. And Adonijah said to him, \"Come in, for you are a prominent man, and bring good news.\"", 
                "num": 42
              }, 
              {
                "text": "Then Jonathan answered and said to Adonijah, \"No! Our lord King David has made Solomon king.", 
                "num": 43
              }, 
              {
                "text": "The king has sent with him Zadok the priest, Nathan the prophet, Benaiah the son of Jehoiada, the Cherethites, and the Pelethites; and they have made him ride on the king's mule.", 
                "num": 44
              }, 
              {
                "text": "So Zadok the priest and Nathan the prophet have anointed him king at Gihon; and they have gone up from there rejoicing, so that the city is in an uproar. This is the noise that you have heard.", 
                "num": 45
              }, 
              {
                "text": "Also Solomon sits on the throne of the kingdom.", 
                "num": 46
              }, 
              {
                "text": "And moreover the king's servants have gone to bless our lord King David, saying, \"May God make the name of Solomon better than your name, and may He make his throne greater than your throne.' Then the king bowed himself on the bed.", 
                "num": 47
              }, 
              {
                "text": "Also the king said thus, \"Blessed be the LORD God of Israel, who has given one to sit on my throne this day, while my eyes see it!\"'", 
                "num": 48
              }, 
              {
                "text": "So all the guests who were with Adonijah were afraid, and arose, and each one went his way.", 
                "num": 49
              }, 
              {
                "text": "Now Adonijah was afraid of Solomon; so he arose, and went and took hold of the horns of the altar.", 
                "num": 50
              }, 
              {
                "text": "And it was told Solomon, saying, \"Indeed Adonijah is afraid of King Solomon; for look, he has taken hold of the horns of the altar, saying, \"Let King Solomon swear to me today that he will not put his servant to death with the sword.\"'", 
                "num": 51
              }, 
              {
                "text": "Then Solomon said, \"If he proves himself a worthy man, not one hair of him shall fall to the earth; but if wickedness is found in him, he shall die.\"", 
                "num": 52
              }, 
              {
                "text": "So King Solomon sent them to bring him down from the altar. And he came and fell down before King Solomon; and Solomon said to him, \"Go to your house.\"", 
                "num": 53
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now the days of David drew near that he should die, and he charged Solomon his son, saying:", 
                "num": 1
              }, 
              {
                "text": "\"I go the way of all the earth; be strong, therefore, and prove yourself a man.", 
                "num": 2
              }, 
              {
                "text": "And keep the charge of the LORD your God: to walk in His ways, to keep His statutes, His commandments, His judgments, and His testimonies, as it is written in the Law of Moses, that you may prosper in all that you do and wherever you turn;", 
                "num": 3
              }, 
              {
                "text": "that the LORD may fulfill His word which He spoke concerning me, saying, \"If your sons take heed to their way, to walk before Me in truth with all their heart and with all their soul,' He said, \"you shall not lack a man on the throne of Israel.'", 
                "num": 4
              }, 
              {
                "text": "\"Moreover you know also what Joab the son of Zeruiah did to me, and what he did to the two commanders of the armies of Israel, to Abner the son of Ner and Amasa the son of Jether, whom he killed. And he shed the blood of war in peacetime, and put the blood of war on his belt that was around his waist, and on his sandals that were on his feet.", 
                "num": 5
              }, 
              {
                "text": "Therefore do according to your wisdom, and do not let his gray hair go down to the grave in peace.", 
                "num": 6
              }, 
              {
                "text": "\"But show kindness to the sons of Barzillai the Gileadite, and let them be among those who eat at your table, for so they came to me when I fled from Absalom your brother.", 
                "num": 7
              }, 
              {
                "text": "\"And see, you have with you Shimei the son of Gera, a Benjamite from Bahurim, who cursed me with a malicious curse in the day when I went to Mahanaim. But he came down to meet me at the Jordan, and I swore to him by the LORD, saying, \"I will not put you to death with the sword.'", 
                "num": 8
              }, 
              {
                "text": "Now therefore, do not hold him guiltless, for you are a wise man and know what you ought to do to him; but bring his gray hair down to the grave with blood.\"", 
                "num": 9
              }, 
              {
                "text": "So David rested with his fathers, and was buried in the City of David.", 
                "num": 10
              }, 
              {
                "text": "The period that David reigned over Israel was forty years; seven years he reigned in Hebron, and in Jerusalem he reigned thirty-three years.", 
                "num": 11
              }, 
              {
                "text": "Then Solomon sat on the throne of his father David; and his kingdom was firmly established.", 
                "num": 12
              }, 
              {
                "text": "Now Adonijah the son of Haggith came to Bathsheba the mother of Solomon. So she said, \"Do you come peaceably?\" And he said, \"Peaceably.\"", 
                "num": 13
              }, 
              {
                "text": "Moreover he said, \"I have something to say to you.\" And she said, \"Say it.\"", 
                "num": 14
              }, 
              {
                "text": "Then he said, \"You know that the kingdom was mine, and all Israel had set their expectations on me, that I should reign. However, the kingdom has been turned over, and has become my brother's; for it was his from the LORD.", 
                "num": 15
              }, 
              {
                "text": "Now I ask one petition of you; do not deny me.\" And she said to him, \"Say it.\"", 
                "num": 16
              }, 
              {
                "text": "Then he said, \"Please speak to King Solomon, for he will not refuse you, that he may give me Abishag the Shunammite as wife.\"", 
                "num": 17
              }, 
              {
                "text": "So Bathsheba said, \"Very well, I will speak for you to the king.\"", 
                "num": 18
              }, 
              {
                "text": "Bathsheba therefore went to King Solomon, to speak to him for Adonijah. And the king rose up to meet her and bowed down to her, and sat down on his throne and had a throne set for the king's mother; so she sat at his right hand.", 
                "num": 19
              }, 
              {
                "text": "Then she said, \"I desire one small petition of you; do not refuse me.\" And the king said to her, \"Ask it, my mother, for I will not refuse you.\"", 
                "num": 20
              }, 
              {
                "text": "So she said, \"Let Abishag the Shunammite be given to Adonijah your brother as wife.\"", 
                "num": 21
              }, 
              {
                "text": "And King Solomon answered and said to his mother, \"Now why do you ask Abishag the Shunammite for Adonijah? Ask for him the kingdom also--for he is my older brother--for him, and for Abiathar the priest, and for Joab the son of Zeruiah.\"", 
                "num": 22
              }, 
              {
                "text": "Then King Solomon swore by the LORD, saying, \"May God do so to me, and more also, if Adonijah has not spoken this word against his own life!", 
                "num": 23
              }, 
              {
                "text": "Now therefore, as the LORD lives, who has confirmed me and set me on the throne of David my father, and who has established a house for me, as He promised, Adonijah shall be put to death today!\"", 
                "num": 24
              }, 
              {
                "text": "So King Solomon sent by the hand of Benaiah the son of Jehoiada; and he struck him down, and he died.", 
                "num": 25
              }, 
              {
                "text": "And to Abiathar the priest the king said, \"Go to Anathoth, to your own fields, for you are deserving of death; but I will not put you to death at this time, because you carried the ark of the Lord GOD before my father David, and because you were afflicted every time my father was afflicted.\"", 
                "num": 26
              }, 
              {
                "text": "So Solomon removed Abiathar from being priest to the LORD, that he might fulfill the word of the LORD which He spoke concerning the house of Eli at Shiloh.", 
                "num": 27
              }, 
              {
                "text": "Then news came to Joab, for Joab had defected to Adonijah, though he had not defected to Absalom. So Joab fled to the tabernacle of the LORD, and took hold of the horns of the altar.", 
                "num": 28
              }, 
              {
                "text": "And King Solomon was told, \"Joab has fled to the tabernacle of the LORD; there he is, by the altar.\" Then Solomon sent Benaiah the son of Jehoiada, saying, \"Go, strike him down.\"", 
                "num": 29
              }, 
              {
                "text": "So Benaiah went to the tabernacle of the LORD, and said to him, \"Thus says the king, \"Come out!\"' And he said, \"No, but I will die here.\" And Benaiah brought back word to the king, saying, \"Thus said Joab, and thus he answered me.\"", 
                "num": 30
              }, 
              {
                "text": "Then the king said to him, \"Do as he has said, and strike him down and bury him, that you may take away from me and from the house of my father the innocent blood which Joab shed.", 
                "num": 31
              }, 
              {
                "text": "So the LORD will return his blood on his head, because he struck down two men more righteous and better than he, and killed them with the sword--Abner the son of Ner, the commander of the army of Israel, and Amasa the son of Jether, the commander of the army of Judah--though my father David did not know it.", 
                "num": 32
              }, 
              {
                "text": "Their blood shall therefore return upon the head of Joab and upon the head of his descendants forever. But upon David and his descendants, upon his house and his throne, there shall be peace forever from the LORD.\"", 
                "num": 33
              }, 
              {
                "text": "So Benaiah the son of Jehoiada went up and struck and killed him; and he was buried in his own house in the wilderness.", 
                "num": 34
              }, 
              {
                "text": "The king put Benaiah the son of Jehoiada in his place over the army, and the king put Zadok the priest in the place of Abiathar.", 
                "num": 35
              }, 
              {
                "text": "Then the king sent and called for Shimei, and said to him, \"Build yourself a house in Jerusalem and dwell there, and do not go out from there anywhere.", 
                "num": 36
              }, 
              {
                "text": "For it shall be, on the day you go out and cross the Brook Kidron, know for certain you shall surely die; your blood shall be on your own head.\"", 
                "num": 37
              }, 
              {
                "text": "And Shimei said to the king, \"The saying is good. As my lord the king has said, so your servant will do.\" So Shimei dwelt in Jerusalem many days.", 
                "num": 38
              }, 
              {
                "text": "Now it happened at the end of three years, that two slaves of Shimei ran away to Achish the son of Maachah, king of Gath. And they told Shimei, saying, \"Look, your slaves are in Gath!\"", 
                "num": 39
              }, 
              {
                "text": "So Shimei arose, saddled his donkey, and went to Achish at Gath to seek his slaves. And Shimei went and brought his slaves from Gath.", 
                "num": 40
              }, 
              {
                "text": "And Solomon was told that Shimei had gone from Jerusalem to Gath and had come back.", 
                "num": 41
              }, 
              {
                "text": "Then the king sent and called for Shimei, and said to him, \"Did I not make you swear by the LORD, and warn you, saying, \"Know for certain that on the day you go out and travel anywhere, you shall surely die'? And you said to me, \"The word I have heard is good.'", 
                "num": 42
              }, 
              {
                "text": "Why then have you not kept the oath of the LORD and the commandment that I gave you?\"", 
                "num": 43
              }, 
              {
                "text": "The king said moreover to Shimei, \"You know, as your heart acknowledges, all the wickedness that you did to my father David; therefore the LORD will return your wickedness on your own head.", 
                "num": 44
              }, 
              {
                "text": "But King Solomon shall be blessed, and the throne of David shall be established before the LORD forever.\"", 
                "num": 45
              }, 
              {
                "text": "So the king commanded Benaiah the son of Jehoiada; and he went out and struck him down, and he died. Thus the kingdom was established in the hand of Solomon.", 
                "num": 46
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now Solomon made a treaty with Pharaoh king of Egypt, and married Pharaoh's daughter; then he brought her to the City of David until he had finished building his own house, and the house of the LORD, and the wall all around Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Meanwhile the people sacrificed at the high places, because there was no house built for the name of the LORD until those days.", 
                "num": 2
              }, 
              {
                "text": "And Solomon loved the LORD, walking in the statutes of his father David, except that he sacrificed and burned incense at the high places.", 
                "num": 3
              }, 
              {
                "text": "Now the king went to Gibeon to sacrifice there, for that was the great high place: Solomon offered a thousand burnt offerings on that altar.", 
                "num": 4
              }, 
              {
                "text": "At Gibeon the LORD appeared to Solomon in a dream by night; and God said, \"Ask! What shall I give you?\"", 
                "num": 5
              }, 
              {
                "text": "And Solomon said: \"You have shown great mercy to Your servant David my father, because he walked before You in truth, in righteousness, and in uprightness of heart with You; You have continued this great kindness for him, and You have given him a son to sit on his throne, as it is this day.", 
                "num": 6
              }, 
              {
                "text": "Now, O LORD my God, You have made Your servant king instead of my father David, but I am a little child; I do not know how to go out or come in.", 
                "num": 7
              }, 
              {
                "text": "And Your servant is in the midst of Your people whom You have chosen, a great people, too numerous to be numbered or counted.", 
                "num": 8
              }, 
              {
                "text": "Therefore give to Your servant an understanding heart to judge Your people, that I may discern between good and evil. For who is able to judge this great people of Yours?\"", 
                "num": 9
              }, 
              {
                "text": "The speech pleased the LORD, that Solomon had asked this thing.", 
                "num": 10
              }, 
              {
                "text": "Then God said to him: \"Because you have asked this thing, and have not asked long life for yourself, nor have asked riches for yourself, nor have asked the life of your enemies, but have asked for yourself understanding to discern justice,", 
                "num": 11
              }, 
              {
                "text": "behold, I have done according to your words; see, I have given you a wise and understanding heart, so that there has not been anyone like you before you, nor shall any like you arise after you.", 
                "num": 12
              }, 
              {
                "text": "And I have also given you what you have not asked: both riches and honor, so that there shall not be anyone like you among the kings all your days.", 
                "num": 13
              }, 
              {
                "text": "So if you walk in My ways, to keep My statutes and My commandments, as your father David walked, then I will lengthen your days.\"", 
                "num": 14
              }, 
              {
                "text": "Then Solomon awoke; and indeed it had been a dream. And he came to Jerusalem and stood before the ark of the covenant of the LORD, offered up burnt offerings, offered peace offerings, and made a feast for all his servants.", 
                "num": 15
              }, 
              {
                "text": "Now two women who were harlots came to the king, and stood before him.", 
                "num": 16
              }, 
              {
                "text": "And one woman said, \"O my lord, this woman and I dwell in the same house; and I gave birth while she was in the house.", 
                "num": 17
              }, 
              {
                "text": "Then it happened, the third day after I had given birth, that this woman also gave birth. And we were together; no one was with us in the house, except the two of us in the house.", 
                "num": 18
              }, 
              {
                "text": "And this woman's son died in the night, because she lay on him.", 
                "num": 19
              }, 
              {
                "text": "So she arose in the middle of the night and took my son from my side, while your maidservant slept, and laid him in her bosom, and laid her dead child in my bosom.", 
                "num": 20
              }, 
              {
                "text": "And when I rose in the morning to nurse my son, there he was, dead. But when I had examined him in the morning, indeed, he was not my son whom I had borne.\"", 
                "num": 21
              }, 
              {
                "text": "Then the other woman said, \"No! But the living one is my son, and the dead one is your son.\" And the first woman said, \"No! But the dead one is your son, and the living one is my son.\" Thus they spoke before the king.", 
                "num": 22
              }, 
              {
                "text": "And the king said, \"The one says, \"This is my son, who lives, and your son is the dead one'; and the other says, \"No! But your son is the dead one, and my son is the living one.'|\"", 
                "num": 23
              }, 
              {
                "text": "Then the king said, \"Bring me a sword.\" So they brought a sword before the king.", 
                "num": 24
              }, 
              {
                "text": "And the king said, \"Divide the living child in two, and give half to one, and half to the other.\"", 
                "num": 25
              }, 
              {
                "text": "Then the woman whose son was living spoke to the king, for she yearned with compassion for her son; and she said, \"O my lord, give her the living child, and by no means kill him!\" But the other said, \"Let him be neither mine nor yours, but divide him.\"", 
                "num": 26
              }, 
              {
                "text": "So the king answered and said, \"Give the first woman the living child, and by no means kill him; she is his mother.\"", 
                "num": 27
              }, 
              {
                "text": "And all Israel heard of the judgment which the king had rendered; and they feared the king, for they saw that the wisdom of God was in him to administer justice.", 
                "num": 28
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "So King Solomon was king over all Israel.", 
                "num": 1
              }, 
              {
                "text": "And these were his officials: Azariah the son of Zadok, the priest;", 
                "num": 2
              }, 
              {
                "text": "Elihoreph and Ahijah, the sons of Shisha, scribes; Jehoshaphat the son of Ahilud, the recorder;", 
                "num": 3
              }, 
              {
                "text": "Benaiah the son of Jehoiada, over the army; Zadok and Abiathar, the priests;", 
                "num": 4
              }, 
              {
                "text": "Azariah the son of Nathan, over the officers; Zabud the son of Nathan, a priest and the king's friend;", 
                "num": 5
              }, 
              {
                "text": "Ahishar, over the household; and Adoniram the son of Abda, over the labor force.", 
                "num": 6
              }, 
              {
                "text": "And Solomon had twelve governors over all Israel, who provided food for the king and his household; each one made provision for one month of the year.", 
                "num": 7
              }, 
              {
                "text": "These are their names: Ben-Hur, in the mountains of Ephraim;", 
                "num": 8
              }, 
              {
                "text": "Ben-Deker, in Makaz, Shaalbim, Beth Shemesh, and Elon Beth Hanan;", 
                "num": 9
              }, 
              {
                "text": "Ben-Hesed, in Arubboth; to him belonged Sochoh and all the land of Hepher;", 
                "num": 10
              }, 
              {
                "text": "Ben-Abinadab, in all the regions of Dor; he had Taphath the daughter of Solomon as wife;", 
                "num": 11
              }, 
              {
                "text": "Baana the son of Ahilud, in Taanach, Megiddo, and all Beth Shean, which is beside Zaretan below Jezreel, from Beth Shean to Abel Meholah, as far as the other side of Jokneam;", 
                "num": 12
              }, 
              {
                "text": "Ben-Geber, in Ramoth Gilead; to him belonged the towns of Jair the son of Manasseh, in Gilead; to him also belonged the region of Argob in Bashan--sixty large cities with walls and bronze gate-bars;", 
                "num": 13
              }, 
              {
                "text": "Ahinadab the son of Iddo, in Mahanaim;", 
                "num": 14
              }, 
              {
                "text": "Ahimaaz, in Naphtali; he also took Basemath the daughter of Solomon as wife;", 
                "num": 15
              }, 
              {
                "text": "Baanah the son of Hushai, in Asher and Aloth;", 
                "num": 16
              }, 
              {
                "text": "Jehoshaphat the son of Paruah, in Issachar;", 
                "num": 17
              }, 
              {
                "text": "Shimei the son of Elah, in Benjamin;", 
                "num": 18
              }, 
              {
                "text": "Geber the son of Uri, in the land of Gilead, in the country of Sihon king of the Amorites, and of Og king of Bashan. He was the only governor who was in the land.", 
                "num": 19
              }, 
              {
                "text": "Judah and Israel were as numerous as the sand by the sea in multitude, eating and drinking and rejoicing.", 
                "num": 20
              }, 
              {
                "text": "So Solomon reigned over all kingdoms from the River to the land of the Philistines, as far as the border of Egypt. They brought tribute and served Solomon all the days of his life.", 
                "num": 21
              }, 
              {
                "text": "Now Solomon's provision for one day was thirty kors of fine flour, sixty kors of meal,", 
                "num": 22
              }, 
              {
                "text": "ten fatted oxen, twenty oxen from the pastures, and one hundred sheep, besides deer, gazelles, roebucks, and fatted fowl.", 
                "num": 23
              }, 
              {
                "text": "For he had dominion over all the region on this side of the River from Tiphsah even to Gaza, namely over all the kings on this side of the River; and he had peace on every side all around him.", 
                "num": 24
              }, 
              {
                "text": "And Judah and Israel dwelt safely, each man under his vine and his fig tree, from Dan as far as Beersheba, all the days of Solomon.", 
                "num": 25
              }, 
              {
                "text": "Solomon had forty thousand stalls of horses for his chariots, and twelve thousand horsemen.", 
                "num": 26
              }, 
              {
                "text": "And these governors, each man in his month, provided food for King Solomon and for all who came to King Solomon's table. There was no lack in their supply.", 
                "num": 27
              }, 
              {
                "text": "They also brought barley and straw to the proper place, for the horses and steeds, each man according to his charge.", 
                "num": 28
              }, 
              {
                "text": "And God gave Solomon wisdom and exceedingly great understanding, and largeness of heart like the sand on the seashore.", 
                "num": 29
              }, 
              {
                "text": "Thus Solomon's wisdom excelled the wisdom of all the men of the East and all the wisdom of Egypt.", 
                "num": 30
              }, 
              {
                "text": "For he was wiser than all men--than Ethan the Ezrahite, and Heman, Chalcol, and Darda, the sons of Mahol; and his fame was in all the surrounding nations.", 
                "num": 31
              }, 
              {
                "text": "He spoke three thousand proverbs, and his songs were one thousand and five.", 
                "num": 32
              }, 
              {
                "text": "Also he spoke of trees, from the cedar tree of Lebanon even to the hyssop that springs out of the wall; he spoke also of animals, of birds, of creeping things, and of fish.", 
                "num": 33
              }, 
              {
                "text": "And men of all nations, from all the kings of the earth who had heard of his wisdom, came to hear the wisdom of Solomon.", 
                "num": 34
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now Hiram king of Tyre sent his servants to Solomon, because he heard that they had anointed him king in place of his father, for Hiram had always loved David.", 
                "num": 1
              }, 
              {
                "text": "Then Solomon sent to Hiram, saying:", 
                "num": 2
              }, 
              {
                "text": "You know how my father David could not build a house for the name of the LORD his God because of the wars which were fought against him on every side, until the LORD put his foes under the soles of his feet.", 
                "num": 3
              }, 
              {
                "text": "But now the LORD my God has given me rest on every side; there is neither adversary nor evil occurrence.", 
                "num": 4
              }, 
              {
                "text": "And behold, I propose to build a house for the name of the LORD my God, as the LORD spoke to my father David, saying, \"Your son, whom I will set on your throne in your place, he shall build the house for My name.\"", 
                "num": 5
              }, 
              {
                "text": "Now therefore, command that they cut down cedars for me from Lebanon; and my servants will be with your servants, and I will pay you wages for your servants according to whatever you say. For you know there is none among us who has skill to cut timber like the Sidonians.", 
                "num": 6
              }, 
              {
                "text": "So it was, when Hiram heard the words of Solomon, that he rejoiced greatly and said, 4 Blessed be the LORD this day, for He has given David a wise son over this great people!", 
                "num": 7
              }, 
              {
                "text": "Then Hiram sent to Solomon, saying: 4 I have considered the message which you sent me, and I will do all you desire concerning the cedar and cypress logs.", 
                "num": 8
              }, 
              {
                "text": "My servants shall bring them down from Lebanon to the sea; I will float them in rafts by sea to the place you indicate to me, and will have them broken apart there; then you can take them away. And you shall fulfill my desire by giving food for my household.", 
                "num": 9
              }, 
              {
                "text": "Then Hiram gave Solomon cedar and cypress logs according to all his desire.", 
                "num": 10
              }, 
              {
                "text": "And Solomon gave Hiram twenty thousand kors of wheat as food for his household, and twenty kors of pressed oil. Thus Solomon gave to Hiram year by year.", 
                "num": 11
              }, 
              {
                "text": "So the LORD gave Solomon wisdom, as He had promised him; and there was peace between Hiram and Solomon, and the two of them made a treaty together.", 
                "num": 12
              }, 
              {
                "text": "Then King Solomon raised up a labor force out of all Israel; and the labor force was thirty thousand men.", 
                "num": 13
              }, 
              {
                "text": "And he sent them to Lebanon, ten thousand a month in shifts: they were one month in Lebanon and two months at home; Adoniram was in charge of the labor force.", 
                "num": 14
              }, 
              {
                "text": "Solomon had seventy thousand who carried burdens, and eighty thousand who quarried stone in the mountains,", 
                "num": 15
              }, 
              {
                "text": "besides three thousand three hundred from the chiefs of Solomon's deputies, who supervised the people who labored in the work.", 
                "num": 16
              }, 
              {
                "text": "And the king commanded them to quarry large stones, costly stones, and hewn stones, to lay the foundation of the temple.", 
                "num": 17
              }, 
              {
                "text": "So Solomon's builders, Hiram's builders, and the Gebalites quarried them; and they prepared timber and stones to build the temple.", 
                "num": 18
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the four hundred and eightieth year after the children of Israel had come out of the land of Egypt, in the fourth year of Solomon's reign over Israel, in the month of Ziv, which is the second month, that he began to build the house of the LORD.", 
                "num": 1
              }, 
              {
                "text": "Now the house which King Solomon built for the LORD, its length was sixty cubits, its width twenty, and its height thirty cubits.", 
                "num": 2
              }, 
              {
                "text": "The vestibule in front of the sanctuary of the house was twenty cubits long across the width of the house, and the width of the vestibule extended ten cubits from the front of the house.", 
                "num": 3
              }, 
              {
                "text": "And he made for the house windows with beveled frames.", 
                "num": 4
              }, 
              {
                "text": "Against the wall of the temple he built chambers all around, against the walls of the temple, all around the sanctuary and the inner sanctuary. Thus he made side chambers all around it.", 
                "num": 5
              }, 
              {
                "text": "The lowest chamber was five cubits wide, the middle was six cubits wide, and the third was seven cubits wide; for he made narrow ledges around the outside of the temple, so that the support beams would not be fastened into the walls of the temple.", 
                "num": 6
              }, 
              {
                "text": "And the temple, when it was being built, was built with stone finished at the quarry, so that no hammer or chisel or any iron tool was heard in the temple while it was being built.", 
                "num": 7
              }, 
              {
                "text": "The doorway for the middle story was on the right side of the temple. They went up by stairs to the middle story, and from the middle to the third.", 
                "num": 8
              }, 
              {
                "text": "So he built the temple and finished it, and he paneled the temple with beams and boards of cedar.", 
                "num": 9
              }, 
              {
                "text": "And he built side chambers against the entire temple, each five cubits high; they were attached to the temple with cedar beams.", 
                "num": 10
              }, 
              {
                "text": "Then the word of the LORD came to Solomon, saying:", 
                "num": 11
              }, 
              {
                "text": "\"Concerning this temple which you are building, if you walk in My statutes, execute My judgments, keep all My commandments, and walk in them, then I will perform My word with you, which I spoke to your father David.", 
                "num": 12
              }, 
              {
                "text": "And I will dwell among the children of Israel, and will not forsake My people Israel.\"", 
                "num": 13
              }, 
              {
                "text": "So Solomon built the temple and finished it.", 
                "num": 14
              }, 
              {
                "text": "And he built the inside walls of the temple with cedar boards; from the floor of the temple to the ceiling he paneled the inside with wood; and he covered the floor of the temple with planks of cypress.", 
                "num": 15
              }, 
              {
                "text": "Then he built the twenty-cubit room at the rear of the temple, from floor to ceiling, with cedar boards; he built it inside as the inner sanctuary, as the Most Holy Place.", 
                "num": 16
              }, 
              {
                "text": "And in front of it the temple sanctuary was forty cubits long.", 
                "num": 17
              }, 
              {
                "text": "The inside of the temple was cedar, carved with ornamental buds and open flowers. All was cedar; there was no stone to be seen.", 
                "num": 18
              }, 
              {
                "text": "And he prepared the inner sanctuary inside the temple, to set the ark of the covenant of the LORD there.", 
                "num": 19
              }, 
              {
                "text": "The inner sanctuary was twenty cubits long, twenty cubits wide, and twenty cubits high. He overlaid it with pure gold, and overlaid the altar of cedar.", 
                "num": 20
              }, 
              {
                "text": "So Solomon overlaid the inside of the temple with pure gold. He stretched gold chains across the front of the inner sanctuary, and overlaid it with gold.", 
                "num": 21
              }, 
              {
                "text": "The whole temple he overlaid with gold, until he had finished all the temple; also he overlaid with gold the entire altar that was by the inner sanctuary.", 
                "num": 22
              }, 
              {
                "text": "Inside the inner sanctuary he made two cherubim of olive wood, each ten cubits high.", 
                "num": 23
              }, 
              {
                "text": "One wing of the cherub was five cubits, and the other wing of the cherub five cubits: ten cubits from the tip of one wing to the tip of the other.", 
                "num": 24
              }, 
              {
                "text": "And the other cherub was ten cubits; both cherubim were of the same size and shape.", 
                "num": 25
              }, 
              {
                "text": "The height of one cherub was ten cubits, and so was the other cherub.", 
                "num": 26
              }, 
              {
                "text": "Then he set the cherubim inside the inner room; and they stretched out the wings of the cherubim so that the wing of the one touched one wall, and the wing of the other cherub touched the other wall. And their wings touched each other in the middle of the room.", 
                "num": 27
              }, 
              {
                "text": "Also he overlaid the cherubim with gold.", 
                "num": 28
              }, 
              {
                "text": "Then he carved all the walls of the temple all around, both the inner and outer sanctuaries, with carved figures of cherubim, palm trees, and open flowers.", 
                "num": 29
              }, 
              {
                "text": "And the floor of the temple he overlaid with gold, both the inner and outer sanctuaries.", 
                "num": 30
              }, 
              {
                "text": "For the entrance of the inner sanctuary he made doors of olive wood; the lintel and doorposts were one-fifth of the wall.", 
                "num": 31
              }, 
              {
                "text": "The two doors were of olive wood; and he carved on them figures of cherubim, palm trees, and open flowers, and overlaid them with gold; and he spread gold on the cherubim and on the palm trees.", 
                "num": 32
              }, 
              {
                "text": "So for the door of the sanctuary he also made doorposts of olive wood, one-fourth of the wall.", 
                "num": 33
              }, 
              {
                "text": "And the two doors were of cypress wood; two panels comprised one folding door, and two panels comprised the other folding door.", 
                "num": 34
              }, 
              {
                "text": "Then he carved cherubim, palm trees, and open flowers on them, and overlaid them with gold applied evenly on the carved work.", 
                "num": 35
              }, 
              {
                "text": "And he built the inner court with three rows of hewn stone and a row of cedar beams.", 
                "num": 36
              }, 
              {
                "text": "In the fourth year the foundation of the house of the LORD was laid, in the month of Ziv.", 
                "num": 37
              }, 
              {
                "text": "And in the eleventh year, in the month of Bul, which is the eighth month, the house was finished in all its details and according to all its plans. So he was seven years in building it.", 
                "num": 38
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "But Solomon took thirteen years to build his own house; so he finished all his house.", 
                "num": 1
              }, 
              {
                "text": "He also built the House of the Forest of Lebanon; its length was one hundred cubits, its width fifty cubits, and its height thirty cubits, with four rows of cedar pillars, and cedar beams on the pillars.", 
                "num": 2
              }, 
              {
                "text": "And it was paneled with cedar above the beams that were on forty-five pillars, fifteen to a row.", 
                "num": 3
              }, 
              {
                "text": "There were windows with beveled frames in three rows, and window was opposite window in three tiers.", 
                "num": 4
              }, 
              {
                "text": "And all the doorways and doorposts had rectangular frames; and window was opposite window in three tiers.", 
                "num": 5
              }, 
              {
                "text": "He also made the Hall of Pillars: its length was fifty cubits, and its width thirty cubits; and in front of them was a portico with pillars, and a canopy was in front of them.", 
                "num": 6
              }, 
              {
                "text": "Then he made a hall for the throne, the Hall of Judgment, where he might judge; and it was paneled with cedar from floor to ceiling.", 
                "num": 7
              }, 
              {
                "text": "And the house where he dwelt had another court inside the hall, of like workmanship. Solomon also made a house like this hall for Pharaoh's daughter, whom he had taken as wife.", 
                "num": 8
              }, 
              {
                "text": "All these were of costly stones cut to size, trimmed with saws, inside and out, from the foundation to the eaves, and also on the outside to the great court.", 
                "num": 9
              }, 
              {
                "text": "The foundation was of costly stones, large stones, some ten cubits and some eight cubits.", 
                "num": 10
              }, 
              {
                "text": "And above were costly stones, hewn to size, and cedar wood.", 
                "num": 11
              }, 
              {
                "text": "The great court was enclosed with three rows of hewn stones and a row of cedar beams. So were the inner court of the house of the LORD and the vestibule of the temple.", 
                "num": 12
              }, 
              {
                "text": "Now King Solomon sent and brought Huram from Tyre.", 
                "num": 13
              }, 
              {
                "text": "He was the son of a widow from the tribe of Naphtali, and his father was a man of Tyre, a bronze worker; he was filled with wisdom and understanding and skill in working with all kinds of bronze work. So he came to King Solomon and did all his work.", 
                "num": 14
              }, 
              {
                "text": "And he cast two pillars of bronze, each one eighteen cubits high, and a line of twelve cubits measured the circumference of each.", 
                "num": 15
              }, 
              {
                "text": "Then he made two capitals of cast bronze, to set on the tops of the pillars. The height of one capital was five cubits, and the height of the other capital was five cubits.", 
                "num": 16
              }, 
              {
                "text": "He made a lattice network, with wreaths of chainwork, for the capitals which were on top of the pillars: seven chains for one capital and seven for the other capital.", 
                "num": 17
              }, 
              {
                "text": "So he made the pillars, and two rows of pomegranates above the network all around to cover the capitals that were on top; and thus he did for the other capital.", 
                "num": 18
              }, 
              {
                "text": "The capitals which were on top of the pillars in the hall were in the shape of lilies, four cubits.", 
                "num": 19
              }, 
              {
                "text": "The capitals on the two pillars also had pomegranates above, by the convex surface which was next to the network; and there were two hundred such pomegranates in rows on each of the capitals all around.", 
                "num": 20
              }, 
              {
                "text": "Then he set up the pillars by the vestibule of the temple; he set up the pillar on the right and called its name Jachin, and he set up the pillar on the left and called its name Boaz.", 
                "num": 21
              }, 
              {
                "text": "The tops of the pillars were in the shape of lilies. So the work of the pillars was finished.", 
                "num": 22
              }, 
              {
                "text": "And he made the Sea of cast bronze, ten cubits from one brim to the other; it was completely round. Its height was five cubits, and a line of thirty cubits measured its circumference.", 
                "num": 23
              }, 
              {
                "text": "Below its brim were ornamental buds encircling it all around, ten to a cubit, all the way around the Sea. The ornamental buds were cast in two rows when it was cast.", 
                "num": 24
              }, 
              {
                "text": "It stood on twelve oxen: three looking toward the north, three looking toward the west, three looking toward the south, and three looking toward the east; the Sea was set upon them, and all their back parts pointed inward.", 
                "num": 25
              }, 
              {
                "text": "It was a handbreadth thick; and its brim was shaped like the brim of a cup, like a lily blossom. It contained two thousand baths.", 
                "num": 26
              }, 
              {
                "text": "He also made ten carts of bronze; four cubits was the length of each cart, four cubits its width, and three cubits its height.", 
                "num": 27
              }, 
              {
                "text": "And this was the design of the carts: They had panels, and the panels were between frames;", 
                "num": 28
              }, 
              {
                "text": "on the panels that were between the frames were lions, oxen, and cherubim. And on the frames was a pedestal on top. Below the lions and oxen were wreaths of plaited work.", 
                "num": 29
              }, 
              {
                "text": "Every cart had four bronze wheels and axles of bronze, and its four feet had supports. Under the laver were supports of cast bronze beside each wreath.", 
                "num": 30
              }, 
              {
                "text": "Its opening inside the crown at the top was one cubit in diameter; and the opening was round, shaped like a pedestal, one and a half cubits in outside diameter; and also on the opening were engravings, but the panels were square, not round.", 
                "num": 31
              }, 
              {
                "text": "Under the panels were the four wheels, and the axles of the wheels were joined to the cart. The height of a wheel was one and a half cubits.", 
                "num": 32
              }, 
              {
                "text": "The workmanship of the wheels was like the workmanship of a chariot wheel; their axle pins, their rims, their spokes, and their hubs were all of cast bronze.", 
                "num": 33
              }, 
              {
                "text": "And there were four supports at the four corners of each cart; its supports were part of the cart itself.", 
                "num": 34
              }, 
              {
                "text": "On the top of the cart, at the height of half a cubit, it was perfectly round. And on the top of the cart, its flanges and its panels were of the same casting.", 
                "num": 35
              }, 
              {
                "text": "On the plates of its flanges and on its panels he engraved cherubim, lions, and palm trees, wherever there was a clear space on each, with wreaths all around.", 
                "num": 36
              }, 
              {
                "text": "Thus he made the ten carts. All of them were of the same mold, one measure, and one shape.", 
                "num": 37
              }, 
              {
                "text": "Then he made ten lavers of bronze; each laver contained forty baths, and each laver was four cubits. On each of the ten carts was a laver.", 
                "num": 38
              }, 
              {
                "text": "And he put five carts on the right side of the house, and five on the left side of the house. He set the Sea on the right side of the house, toward the southeast.", 
                "num": 39
              }, 
              {
                "text": "Huram made the lavers and the shovels and the bowls. So Huram finished doing all the work that he was to do for King Solomon for the house of the LORD:", 
                "num": 40
              }, 
              {
                "text": "the two pillars, the two bowl-shaped capitals that were on top of the two pillars; the two networks covering the two bowl-shaped capitals which were on top of the pillars;", 
                "num": 41
              }, 
              {
                "text": "four hundred pomegranates for the two networks (two rows of pomegranates for each network, to cover the two bowl-shaped capitals that were on top of the pillars);", 
                "num": 42
              }, 
              {
                "text": "the ten carts, and ten lavers on the carts;", 
                "num": 43
              }, 
              {
                "text": "one Sea, and twelve oxen under the Sea;", 
                "num": 44
              }, 
              {
                "text": "the pots, the shovels, and the bowls. All these articles which Huram made for King Solomon for the house of the LORD were of burnished bronze.", 
                "num": 45
              }, 
              {
                "text": "In the plain of Jordan the king had them cast in clay molds, between Succoth and Zaretan.", 
                "num": 46
              }, 
              {
                "text": "And Solomon did not weigh all the articles, because there were so many; the weight of the bronze was not determined.", 
                "num": 47
              }, 
              {
                "text": "Thus Solomon had all the furnishings made for the house of the LORD: the altar of gold, and the table of gold on which was the showbread;", 
                "num": 48
              }, 
              {
                "text": "the lampstands of pure gold, five on the right side and five on the left in front of the inner sanctuary, with the flowers and the lamps and the wick-trimmers of gold;", 
                "num": 49
              }, 
              {
                "text": "the basins, the trimmers, the bowls, the ladles, and the censers of pure gold; and the hinges of gold, both for the doors of the inner room (the Most Holy Place) and for the doors of the main hall of the temple.", 
                "num": 50
              }, 
              {
                "text": "So all the work that King Solomon had done for the house of the LORD was finished; and Solomon brought in the things which his father David had dedicated: the silver and the gold and the furnishings. He put them in the treasuries of the house of the LORD.", 
                "num": 51
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now Solomon assembled the elders of Israel and all the heads of the tribes, the chief fathers of the children of Israel, to King Solomon in Jerusalem, that they might bring up the ark of the covenant of the LORD from the City of David, which is Zion.", 
                "num": 1
              }, 
              {
                "text": "Therefore all the men of Israel assembled with King Solomon at the feast in the month of Ethanim, which is the seventh month.", 
                "num": 2
              }, 
              {
                "text": "So all the elders of Israel came, and the priests took up the ark.", 
                "num": 3
              }, 
              {
                "text": "Then they brought up the ark of the LORD, the tabernacle of meeting, and all the holy furnishings that were in the tabernacle. The priests and the Levites brought them up.", 
                "num": 4
              }, 
              {
                "text": "Also King Solomon, and all the congregation of Israel who were assembled with him, were with him before the ark, sacrificing sheep and oxen that could not be counted or numbered for multitude.", 
                "num": 5
              }, 
              {
                "text": "Then the priests brought in the ark of the covenant of the LORD to its place, into the inner sanctuary of the temple, to the Most Holy Place, under the wings of the cherubim.", 
                "num": 6
              }, 
              {
                "text": "For the cherubim spread their two wings over the place of the ark, and the cherubim overshadowed the ark and its poles.", 
                "num": 7
              }, 
              {
                "text": "The poles extended so that the ends of the poles could be seen from the holy place, in front of the inner sanctuary; but they could not be seen from outside. And they are there to this day.", 
                "num": 8
              }, 
              {
                "text": "Nothing was in the ark except the two tablets of stone which Moses put there at Horeb, when the LORD made a covenant with the children of Israel, when they came out of the land of Egypt.", 
                "num": 9
              }, 
              {
                "text": "And it came to pass, when the priests came out of the holy place, that the cloud filled the house of the LORD,", 
                "num": 10
              }, 
              {
                "text": "so that the priests could not continue ministering because of the cloud; for the glory of the LORD filled the house of the LORD.", 
                "num": 11
              }, 
              {
                "text": "Then Solomon spoke: \"The LORD said He would dwell in the dark cloud.", 
                "num": 12
              }, 
              {
                "text": "I have surely built You an exalted house, And a place for You to dwell in forever.\"", 
                "num": 13
              }, 
              {
                "text": "Then the king turned around and blessed the whole assembly of Israel, while all the assembly of Israel was standing.", 
                "num": 14
              }, 
              {
                "text": "And he said: \"Blessed be the LORD God of Israel, who spoke with His mouth to my father David, and with His hand has fulfilled it, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Since the day that I brought My people Israel out of Egypt, I have chosen no city from any tribe of Israel in which to build a house, that My name might be there; but I chose David to be over My people Israel.'", 
                "num": 16
              }, 
              {
                "text": "Now it was in the heart of my father David to build a temple for the name of the LORD God of Israel.", 
                "num": 17
              }, 
              {
                "text": "But the LORD said to my father David, \"Whereas it was in your heart to build a temple for My name, you did well that it was in your heart.", 
                "num": 18
              }, 
              {
                "text": "Nevertheless you shall not build the temple, but your son who will come from your body, he shall build the temple for My name.'", 
                "num": 19
              }, 
              {
                "text": "So the LORD has fulfilled His word which He spoke; and I have filled the position of my father David, and sit on the throne of Israel, as the LORD promised; and I have built a temple for the name of the LORD God of Israel.", 
                "num": 20
              }, 
              {
                "text": "And there I have made a place for the ark, in which is the covenant of the LORD which He made with our fathers, when He brought them out of the land of Egypt.\"", 
                "num": 21
              }, 
              {
                "text": "Then Solomon stood before the altar of the LORD in the presence of all the assembly of Israel, and spread out his hands toward heaven;", 
                "num": 22
              }, 
              {
                "text": "and he said: \"LORD God of Israel, there is no God in heaven above or on earth below like You, who keep Your covenant and mercy with Your servants who walk before You with all their hearts.", 
                "num": 23
              }, 
              {
                "text": "You have kept what You promised Your servant David my father; You have both spoken with Your mouth and fulfilled it with Your hand, as it is this day.", 
                "num": 24
              }, 
              {
                "text": "Therefore, LORD God of Israel, now keep what You promised Your servant David my father, saying, \"You shall not fail to have a man sit before Me on the throne of Israel, only if your sons take heed to their way, that they walk before Me as you have walked before Me.'", 
                "num": 25
              }, 
              {
                "text": "And now I pray, O God of Israel, let Your word come true, which You have spoken to Your servant David my father.", 
                "num": 26
              }, 
              {
                "text": "\"But will God indeed dwell on the earth? Behold, heaven and the heaven of heavens cannot contain You. How much less this temple which I have built!", 
                "num": 27
              }, 
              {
                "text": "Yet regard the prayer of Your servant and his supplication, O LORD my God, and listen to the cry and the prayer which Your servant is praying before You today:", 
                "num": 28
              }, 
              {
                "text": "that Your eyes may be open toward this temple night and day, toward the place of which You said, \"My name shall be there,' that You may hear the prayer which Your servant makes toward this place.", 
                "num": 29
              }, 
              {
                "text": "And may You hear the supplication of Your servant and of Your people Israel, when they pray toward this place. Hear in heaven Your dwelling place; and when You hear, forgive.", 
                "num": 30
              }, 
              {
                "text": "\"When anyone sins against his neighbor, and is forced to take an oath, and comes and takes an oath before Your altar in this temple,", 
                "num": 31
              }, 
              {
                "text": "then hear in heaven, and act, and judge Your servants, condemning the wicked, bringing his way on his head, and justifying the righteous by giving him according to his righteousness.", 
                "num": 32
              }, 
              {
                "text": "\"When Your people Israel are defeated before an enemy because they have sinned against You, and when they turn back to You and confess Your name, and pray and make supplication to You in this temple,", 
                "num": 33
              }, 
              {
                "text": "then hear in heaven, and forgive the sin of Your people Israel, and bring them back to the land which You gave to their fathers.", 
                "num": 34
              }, 
              {
                "text": "\"When the heavens are shut up and there is no rain because they have sinned against You, when they pray toward this place and confess Your name, and turn from their sin because You afflict them,", 
                "num": 35
              }, 
              {
                "text": "then hear in heaven, and forgive the sin of Your servants, Your people Israel, that You may teach them the good way in which they should walk; and send rain on Your land which You have given to Your people as an inheritance.", 
                "num": 36
              }, 
              {
                "text": "\"When there is famine in the land, pestilence or blight or mildew, locusts or grasshoppers; when their enemy besieges them in the land of their cities; whatever plague or whatever sickness there is;", 
                "num": 37
              }, 
              {
                "text": "whatever prayer, whatever supplication is made by anyone, or by all Your people Israel, when each one knows the plague of his own heart, and spreads out his hands toward this temple:", 
                "num": 38
              }, 
              {
                "text": "then hear in heaven Your dwelling place, and forgive, and act, and give to everyone according to all his ways, whose heart You know (for You alone know the hearts of all the sons of men),", 
                "num": 39
              }, 
              {
                "text": "that they may fear You all the days that they live in the land which You gave to our fathers.", 
                "num": 40
              }, 
              {
                "text": "\"Moreover, concerning a foreigner, who is not of Your people Israel, but has come from a far country for Your name's sake", 
                "num": 41
              }, 
              {
                "text": "(for they will hear of Your great name and Your strong hand and Your outstretched arm), when he comes and prays toward this temple,", 
                "num": 42
              }, 
              {
                "text": "hear in heaven Your dwelling place, and do according to all for which the foreigner calls to You, that all peoples of the earth may know Your name and fear You, as do Your people Israel, and that they may know that this temple which I have built is called by Your name.", 
                "num": 43
              }, 
              {
                "text": "\"When Your people go out to battle against their enemy, wherever You send them, and when they pray to the LORD toward the city which You have chosen and the temple which I have built for Your name,", 
                "num": 44
              }, 
              {
                "text": "then hear in heaven their prayer and their supplication, and maintain their cause.", 
                "num": 45
              }, 
              {
                "text": "\"When they sin against You (for there is no one who does not sin), and You become angry with them and deliver them to the enemy, and they take them captive to the land of the enemy, far or near;", 
                "num": 46
              }, 
              {
                "text": "yet when they come to themselves in the land where they were carried captive, and repent, and make supplication to You in the land of those who took them captive, saying, \"We have sinned and done wrong, we have committed wickedness';", 
                "num": 47
              }, 
              {
                "text": "and when they return to You with all their heart and with all their soul in the land of their enemies who led them away captive, and pray to You toward their land which You gave to their fathers, the city which You have chosen and the temple which I have built for Your name:", 
                "num": 48
              }, 
              {
                "text": "then hear in heaven Your dwelling place their prayer and their supplication, and maintain their cause,", 
                "num": 49
              }, 
              {
                "text": "and forgive Your people who have sinned against You, and all their transgressions which they have transgressed against You; and grant them compassion before those who took them captive, that they may have compassion on them", 
                "num": 50
              }, 
              {
                "text": "(for they are Your people and Your inheritance, whom You brought out of Egypt, out of the iron furnace),", 
                "num": 51
              }, 
              {
                "text": "that Your eyes may be open to the supplication of Your servant and the supplication of Your people Israel, to listen to them whenever they call to You.", 
                "num": 52
              }, 
              {
                "text": "For You separated them from among all the peoples of the earth to be Your inheritance, as You spoke by Your servant Moses, when You brought our fathers out of Egypt, O Lord GOD.\"", 
                "num": 53
              }, 
              {
                "text": "And so it was, when Solomon had finished praying all this prayer and supplication to the LORD, that he arose from before the altar of the LORD, from kneeling on his knees with his hands spread up to heaven.", 
                "num": 54
              }, 
              {
                "text": "Then he stood and blessed all the assembly of Israel with a loud voice, saying:", 
                "num": 55
              }, 
              {
                "text": "\"Blessed be the LORD, who has given rest to His people Israel, according to all that He promised. There has not failed one word of all His good promise, which He promised through His servant Moses.", 
                "num": 56
              }, 
              {
                "text": "May the LORD our God be with us, as He was with our fathers. May He not leave us nor forsake us,", 
                "num": 57
              }, 
              {
                "text": "that He may incline our hearts to Himself, to walk in all His ways, and to keep His commandments and His statutes and His judgments, which He commanded our fathers.", 
                "num": 58
              }, 
              {
                "text": "And may these words of mine, with which I have made supplication before the LORD, be near the LORD our God day and night, that He may maintain the cause of His servant and the cause of His people Israel, as each day may require,", 
                "num": 59
              }, 
              {
                "text": "that all the peoples of the earth may know that the LORD is God; there is no other.", 
                "num": 60
              }, 
              {
                "text": "Let your heart therefore be loyal to the LORD our God, to walk in His statutes and keep His commandments, as at this day.\"", 
                "num": 61
              }, 
              {
                "text": "Then the king and all Israel with him offered sacrifices before the LORD.", 
                "num": 62
              }, 
              {
                "text": "And Solomon offered a sacrifice of peace offerings, which he offered to the LORD, twenty-two thousand bulls and one hundred and twenty thousand sheep. So the king and all the children of Israel dedicated the house of the LORD.", 
                "num": 63
              }, 
              {
                "text": "On the same day the king consecrated the middle of the court that was in front of the house of the LORD; for there he offered burnt offerings, grain offerings, and the fat of the peace offerings, because the bronze altar that was before the LORD was too small to receive the burnt offerings, the grain offerings, and the fat of the peace offerings.", 
                "num": 64
              }, 
              {
                "text": "At that time Solomon held a feast, and all Israel with him, a great assembly from the entrance of Hamath to the Brook of Egypt, before the LORD our God, seven days and seven more days--fourteen days.", 
                "num": 65
              }, 
              {
                "text": "On the eighth day he sent the people away; and they blessed the king, and went to their tents joyful and glad of heart for all the good that the LORD had done for His servant David, and for Israel His people.", 
                "num": 66
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass, when Solomon had finished building the house of the LORD and the king's house, and all Solomon's desire which he wanted to do,", 
                "num": 1
              }, 
              {
                "text": "that the LORD appeared to Solomon the second time, as He had appeared to him at Gibeon.", 
                "num": 2
              }, 
              {
                "text": "And the LORD said to him: \"I have heard your prayer and your supplication that you have made before Me; I have consecrated this house which you have built to put My name there forever, and My eyes and My heart will be there perpetually.", 
                "num": 3
              }, 
              {
                "text": "Now if you walk before Me as your father David walked, in integrity of heart and in uprightness, to do according to all that I have commanded you, and if you keep My statutes and My judgments,", 
                "num": 4
              }, 
              {
                "text": "then I will establish the throne of your kingdom over Israel forever, as I promised David your father, saying, \"You shall not fail to have a man on the throne of Israel.'", 
                "num": 5
              }, 
              {
                "text": "But if you or your sons at all turn from following Me, and do not keep My commandments and My statutes which I have set before you, but go and serve other gods and worship them,", 
                "num": 6
              }, 
              {
                "text": "then I will cut off Israel from the land which I have given them; and this house which I have consecrated for My name I will cast out of My sight. Israel will be a proverb and a byword among all peoples.", 
                "num": 7
              }, 
              {
                "text": "And as for this house, which is exalted, everyone who passes by it will be astonished and will hiss, and say, \"Why has the LORD done thus to this land and to this house?'", 
                "num": 8
              }, 
              {
                "text": "Then they will answer, \"Because they forsook the LORD their God, who brought their fathers out of the land of Egypt, and have embraced other gods, and worshiped them and served them; therefore the LORD has brought all this calamity on them.\"'", 
                "num": 9
              }, 
              {
                "text": "Now it happened at the end of twenty years, when Solomon had built the two houses, the house of the LORD and the king's house", 
                "num": 10
              }, 
              {
                "text": "(Hiram the king of Tyre had supplied Solomon with cedar and cypress and gold, as much as he desired), that King Solomon then gave Hiram twenty cities in the land of Galilee.", 
                "num": 11
              }, 
              {
                "text": "Then Hiram went from Tyre to see the cities which Solomon had given him, but they did not please him.", 
                "num": 12
              }, 
              {
                "text": "So he said, \"What kind of cities are these which you have given me, my brother?\" And he called them the land of Cabul, as they are to this day.", 
                "num": 13
              }, 
              {
                "text": "Then Hiram sent the king one hundred and twenty talents of gold.", 
                "num": 14
              }, 
              {
                "text": "And this is the reason for the labor force which King Solomon raised: to build the house of the LORD, his own house, the Millo, the wall of Jerusalem, Hazor, Megiddo, and Gezer.", 
                "num": 15
              }, 
              {
                "text": "(Pharaoh king of Egypt had gone up and taken Gezer and burned it with fire, had killed the Canaanites who dwelt in the city, and had given it as a dowry to his daughter, Solomon's wife.)", 
                "num": 16
              }, 
              {
                "text": "And Solomon built Gezer, Lower Beth Horon,", 
                "num": 17
              }, 
              {
                "text": "Baalath, and Tadmor in the wilderness, in the land of Judah,", 
                "num": 18
              }, 
              {
                "text": "all the storage cities that Solomon had, cities for his chariots and cities for his cavalry, and whatever Solomon desired to build in Jerusalem, in Lebanon, and in all the land of his dominion.", 
                "num": 19
              }, 
              {
                "text": "All the people who were left of the Amorites, Hittites, Perizzites, Hivites, and Jebusites, who were not of the children of Israel--", 
                "num": 20
              }, 
              {
                "text": "that is, their descendants who were left in the land after them, whom the children of Israel had not been able to destroy completely--from these Solomon raised forced labor, as it is to this day.", 
                "num": 21
              }, 
              {
                "text": "But of the children of Israel Solomon made no forced laborers, because they were men of war and his servants: his officers, his captains, commanders of his chariots, and his cavalry.", 
                "num": 22
              }, 
              {
                "text": "Others were chiefs of the officials who were over Solomon's work: five hundred and fifty, who ruled over the people who did the work.", 
                "num": 23
              }, 
              {
                "text": "But Pharaoh's daughter came up from the City of David to her house which Solomon had built for her. Then he built the Millo.", 
                "num": 24
              }, 
              {
                "text": "Now three times a year Solomon offered burnt offerings and peace offerings on the altar which he had built for the LORD, and he burned incense with them on the altar that was before the LORD. So he finished the temple.", 
                "num": 25
              }, 
              {
                "text": "King Solomon also built a fleet of ships at Ezion Geber, which is near Elath on the shore of the Red Sea, in the land of Edom.", 
                "num": 26
              }, 
              {
                "text": "Then Hiram sent his servants with the fleet, seamen who knew the sea, to work with the servants of Solomon.", 
                "num": 27
              }, 
              {
                "text": "And they went to Ophir, and acquired four hundred and twenty talents of gold from there, and brought it to King Solomon.", 
                "num": 28
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now when the queen of Sheba heard of the fame of Solomon concerning the name of the LORD, she came to test him with hard questions.", 
                "num": 1
              }, 
              {
                "text": "She came to Jerusalem with a very great retinue, with camels that bore spices, very much gold, and precious stones; and when she came to Solomon, she spoke with him about all that was in her heart.", 
                "num": 2
              }, 
              {
                "text": "So Solomon answered all her questions; there was nothing so difficult for the king that he could not explain it to her.", 
                "num": 3
              }, 
              {
                "text": "And when the queen of Sheba had seen all the wisdom of Solomon, the house that he had built,", 
                "num": 4
              }, 
              {
                "text": "the food on his table, the seating of his servants, the service of his waiters and their apparel, his cupbearers, and his entryway by which he went up to the house of the LORD, there was no more spirit in her.", 
                "num": 5
              }, 
              {
                "text": "Then she said to the king: \"It was a true report which I heard in my own land about your words and your wisdom.", 
                "num": 6
              }, 
              {
                "text": "However I did not believe the words until I came and saw with my own eyes; and indeed the half was not told me. Your wisdom and prosperity exceed the fame of which I heard.", 
                "num": 7
              }, 
              {
                "text": "Happy are your men and happy are these your servants, who stand continually before you and hear your wisdom!", 
                "num": 8
              }, 
              {
                "text": "Blessed be the LORD your God, who delighted in you, setting you on the throne of Israel! Because the LORD has loved Israel forever, therefore He made you king, to do justice and righteousness.\"", 
                "num": 9
              }, 
              {
                "text": "Then she gave the king one hundred and twenty talents of gold, spices in great quantity, and precious stones. There never again came such abundance of spices as the queen of Sheba gave to King Solomon.", 
                "num": 10
              }, 
              {
                "text": "Also, the ships of Hiram, which brought gold from Ophir, brought great quantities of almug wood and precious stones from Ophir.", 
                "num": 11
              }, 
              {
                "text": "And the king made steps of the almug wood for the house of the LORD and for the king's house, also harps and stringed instruments for singers. There never again came such almug wood, nor has the like been seen to this day.", 
                "num": 12
              }, 
              {
                "text": "Now King Solomon gave the queen of Sheba all she desired, whatever she asked, besides what Solomon had given her according to the royal generosity. So she turned and went to her own country, she and her servants.", 
                "num": 13
              }, 
              {
                "text": "The weight of gold that came to Solomon yearly was six hundred and sixty-six talents of gold,", 
                "num": 14
              }, 
              {
                "text": "besides that from the traveling merchants, from the income of traders, from all the kings of Arabia, and from the governors of the country.", 
                "num": 15
              }, 
              {
                "text": "And King Solomon made two hundred large shields of hammered gold; six hundred shekels of gold went into each shield.", 
                "num": 16
              }, 
              {
                "text": "He also made three hundred shields of hammered gold; three minas of gold went into each shield. The king put them in the House of the Forest of Lebanon.", 
                "num": 17
              }, 
              {
                "text": "Moreover the king made a great throne of ivory, and overlaid it with pure gold.", 
                "num": 18
              }, 
              {
                "text": "The throne had six steps, and the top of the throne was round at the back; there were armrests on either side of the place of the seat, and two lions stood beside the armrests.", 
                "num": 19
              }, 
              {
                "text": "Twelve lions stood there, one on each side of the six steps; nothing like this had been made for any other kingdom.", 
                "num": 20
              }, 
              {
                "text": "All King Solomon's drinking vessels were gold, and all the vessels of the House of the Forest of Lebanon were pure gold. Not one was silver, for this was accounted as nothing in the days of Solomon.", 
                "num": 21
              }, 
              {
                "text": "For the king had merchant ships at sea with the fleet of Hiram. Once every three years the merchant ships came bringing gold, silver, ivory, apes, and monkeys.", 
                "num": 22
              }, 
              {
                "text": "So King Solomon surpassed all the kings of the earth in riches and wisdom.", 
                "num": 23
              }, 
              {
                "text": "Now all the earth sought the presence of Solomon to hear his wisdom, which God had put in his heart.", 
                "num": 24
              }, 
              {
                "text": "Each man brought his present: articles of silver and gold, garments, armor, spices, horses, and mules, at a set rate year by year.", 
                "num": 25
              }, 
              {
                "text": "And Solomon gathered chariots and horsemen; he had one thousand four hundred chariots and twelve thousand horsemen, whom he stationed in the chariot cities and with the king at Jerusalem.", 
                "num": 26
              }, 
              {
                "text": "The king made silver as common in Jerusalem as stones, and he made cedar trees as abundant as the sycamores which are in the lowland.", 
                "num": 27
              }, 
              {
                "text": "Also Solomon had horses imported from Egypt and Keveh; the king's merchants bought them in Keveh at the current price.", 
                "num": 28
              }, 
              {
                "text": "Now a chariot that was imported from Egypt cost six hundred shekels of silver, and a horse one hundred and fifty; and thus, through their agents, they exported them to all the kings of the Hittites and the kings of Syria.", 
                "num": 29
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "But King Solomon loved many foreign women, as well as the daughter of Pharaoh: women of the Moabites, Ammonites, Edomites, Sidonians, and Hittites--", 
                "num": 1
              }, 
              {
                "text": "from the nations of whom the LORD had said to the children of Israel, \"You shall not intermarry with them, nor they with you. Surely they will turn away your hearts after their gods.\" Solomon clung to these in love.", 
                "num": 2
              }, 
              {
                "text": "And he had seven hundred wives, princesses, and three hundred concubines; and his wives turned away his heart.", 
                "num": 3
              }, 
              {
                "text": "For it was so, when Solomon was old, that his wives turned his heart after other gods; and his heart was not loyal to the LORD his God, as was the heart of his father David.", 
                "num": 4
              }, 
              {
                "text": "For Solomon went after Ashtoreth the goddess of the Sidonians, and after Milcom the abomination of the Ammonites.", 
                "num": 5
              }, 
              {
                "text": "Solomon did evil in the sight of the LORD, and did not fully follow the LORD, as did his father David.", 
                "num": 6
              }, 
              {
                "text": "Then Solomon built a high place for Chemosh the abomination of Moab, on the hill that is east of Jerusalem, and for Molech the abomination of the people of Ammon.", 
                "num": 7
              }, 
              {
                "text": "And he did likewise for all his foreign wives, who burned incense and sacrificed to their gods.", 
                "num": 8
              }, 
              {
                "text": "So the LORD became angry with Solomon, because his heart had turned from the LORD God of Israel, who had appeared to him twice,", 
                "num": 9
              }, 
              {
                "text": "and had commanded him concerning this thing, that he should not go after other gods; but he did not keep what the LORD had commanded.", 
                "num": 10
              }, 
              {
                "text": "Therefore the LORD said to Solomon, \"Because you have done this, and have not kept My covenant and My statutes, which I have commanded you, I will surely tear the kingdom away from you and give it to your servant.", 
                "num": 11
              }, 
              {
                "text": "Nevertheless I will not do it in your days, for the sake of your father David; I will tear it out of the hand of your son.", 
                "num": 12
              }, 
              {
                "text": "However I will not tear away the whole kingdom; I will give one tribe to your son for the sake of My servant David, and for the sake of Jerusalem which I have chosen.\"", 
                "num": 13
              }, 
              {
                "text": "Now the LORD raised up an adversary against Solomon, Hadad the Edomite; he was a descendant of the king in Edom.", 
                "num": 14
              }, 
              {
                "text": "For it happened, when David was in Edom, and Joab the commander of the army had gone up to bury the slain, after he had killed every male in Edom", 
                "num": 15
              }, 
              {
                "text": "(because for six months Joab remained there with all Israel, until he had cut down every male in Edom),", 
                "num": 16
              }, 
              {
                "text": "that Hadad fled to go to Egypt, he and certain Edomites of his father's servants with him. Hadad was still a little child.", 
                "num": 17
              }, 
              {
                "text": "Then they arose from Midian and came to Paran; and they took men with them from Paran and came to Egypt, to Pharaoh king of Egypt, who gave him a house, apportioned food for him, and gave him land.", 
                "num": 18
              }, 
              {
                "text": "And Hadad found great favor in the sight of Pharaoh, so that he gave him as wife the sister of his own wife, that is, the sister of Queen Tahpenes.", 
                "num": 19
              }, 
              {
                "text": "Then the sister of Tahpenes bore him Genubath his son, whom Tahpenes weaned in Pharaoh's house. And Genubath was in Pharaoh's household among the sons of Pharaoh.", 
                "num": 20
              }, 
              {
                "text": "So when Hadad heard in Egypt that David rested with his fathers, and that Joab the commander of the army was dead, Hadad said to Pharaoh, \"Let me depart, that I may go to my own country.\"", 
                "num": 21
              }, 
              {
                "text": "Then Pharaoh said to him, \"But what have you lacked with me, that suddenly you seek to go to your own country?\" So he answered, \"Nothing, but do let me go anyway.\"", 
                "num": 22
              }, 
              {
                "text": "And God raised up another adversary against him, Rezon the son of Eliadah, who had fled from his lord, Hadadezer king of Zobah.", 
                "num": 23
              }, 
              {
                "text": "So he gathered men to him and became captain over a band of raiders, when David killed those of Zobah. And they went to Damascus and dwelt there, and reigned in Damascus.", 
                "num": 24
              }, 
              {
                "text": "He was an adversary of Israel all the days of Solomon (besides the trouble that Hadad caused); and he abhorred Israel, and reigned over Syria.", 
                "num": 25
              }, 
              {
                "text": "Then Solomon's servant, Jeroboam the son of Nebat, an Ephraimite from Zereda, whose mother's name was Zeruah, a widow, also rebelled against the king.", 
                "num": 26
              }, 
              {
                "text": "And this is what caused him to rebel against the king: Solomon had built the Millo and repaired the damages to the City of David his father.", 
                "num": 27
              }, 
              {
                "text": "The man Jeroboam was a mighty man of valor; and Solomon, seeing that the young man was industrious, made him the officer over all the labor force of the house of Joseph.", 
                "num": 28
              }, 
              {
                "text": "Now it happened at that time, when Jeroboam went out of Jerusalem, that the prophet Ahijah the Shilonite met him on the way; and he had clothed himself with a new garment, and the two were alone in the field.", 
                "num": 29
              }, 
              {
                "text": "Then Ahijah took hold of the new garment that was on him, and tore it into twelve pieces.", 
                "num": 30
              }, 
              {
                "text": "And he said to Jeroboam, \"Take for yourself ten pieces, for thus says the LORD, the God of Israel: \"Behold, I will tear the kingdom out of the hand of Solomon and will give ten tribes to you", 
                "num": 31
              }, 
              {
                "text": "(but he shall have one tribe for the sake of My servant David, and for the sake of Jerusalem, the city which I have chosen out of all the tribes of Israel),", 
                "num": 32
              }, 
              {
                "text": "because they have forsaken Me, and worshiped Ashtoreth the goddess of the Sidonians, Chemosh the god of the Moabites, and Milcom the god of the people of Ammon, and have not walked in My ways to do what is right in My eyes and keep My statutes and My judgments, as did his father David.", 
                "num": 33
              }, 
              {
                "text": "However I will not take the whole kingdom out of his hand, because I have made him ruler all the days of his life for the sake of My servant David, whom I chose because he kept My commandments and My statutes.", 
                "num": 34
              }, 
              {
                "text": "But I will take the kingdom out of his son's hand and give it to you--ten tribes.", 
                "num": 35
              }, 
              {
                "text": "And to his son I will give one tribe, that My servant David may always have a lamp before Me in Jerusalem, the city which I have chosen for Myself, to put My name there.", 
                "num": 36
              }, 
              {
                "text": "So I will take you, and you shall reign over all your heart desires, and you shall be king over Israel.", 
                "num": 37
              }, 
              {
                "text": "Then it shall be, if you heed all that I command you, walk in My ways, and do what is right in My sight, to keep My statutes and My commandments, as My servant David did, then I will be with you and build for you an enduring house, as I built for David, and will give Israel to you.", 
                "num": 38
              }, 
              {
                "text": "And I will afflict the descendants of David because of this, but not forever.\"'", 
                "num": 39
              }, 
              {
                "text": "Solomon therefore sought to kill Jeroboam. But Jeroboam arose and fled to Egypt, to Shishak king of Egypt, and was in Egypt until the death of Solomon.", 
                "num": 40
              }, 
              {
                "text": "Now the rest of the acts of Solomon, all that he did, and his wisdom, are they not written in the book of the acts of Solomon?", 
                "num": 41
              }, 
              {
                "text": "And the period that Solomon reigned in Jerusalem over all Israel was forty years.", 
                "num": 42
              }, 
              {
                "text": "Then Solomon rested with his fathers, and was buried in the City of David his father. And Rehoboam his son reigned in his place.", 
                "num": 43
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "And Rehoboam went to Shechem, for all Israel had gone to Shechem to make him king.", 
                "num": 1
              }, 
              {
                "text": "So it happened, when Jeroboam the son of Nebat heard it (he was still in Egypt, for he had fled from the presence of King Solomon and had been dwelling in Egypt),", 
                "num": 2
              }, 
              {
                "text": "that they sent and called him. Then Jeroboam and the whole assembly of Israel came and spoke to Rehoboam, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Your father made our yoke heavy; now therefore, lighten the burdensome service of your father, and his heavy yoke which he put on us, and we will serve you.\"", 
                "num": 4
              }, 
              {
                "text": "So he said to them, \"Depart for three days, then come back to me.\" And the people departed.", 
                "num": 5
              }, 
              {
                "text": "Then King Rehoboam consulted the elders who stood before his father Solomon while he still lived, and he said, \"How do you advise me to answer these people?\"", 
                "num": 6
              }, 
              {
                "text": "And they spoke to him, saying, \"If you will be a servant to these people today, and serve them, and answer them, and speak good words to them, then they will be your servants forever.\"", 
                "num": 7
              }, 
              {
                "text": "But he rejected the advice which the elders had given him, and consulted the young men who had grown up with him, who stood before him.", 
                "num": 8
              }, 
              {
                "text": "And he said to them, \"What advice do you give? How should we answer this people who have spoken to me, saying, \"Lighten the yoke which your father put on us'?\"", 
                "num": 9
              }, 
              {
                "text": "Then the young men who had grown up with him spoke to him, saying, \"Thus you should speak to this people who have spoken to you, saying, \"Your father made our yoke heavy, but you make it lighter on us'--thus you shall say to them: \"My little finger shall be thicker than my father's waist!", 
                "num": 10
              }, 
              {
                "text": "And now, whereas my father put a heavy yoke on you, I will add to your yoke; my father chastised you with whips, but I will chastise you with scourges!\"'", 
                "num": 11
              }, 
              {
                "text": "So Jeroboam and all the people came to Rehoboam the third day, as the king had directed, saying, \"Come back to me the third day.\"", 
                "num": 12
              }, 
              {
                "text": "Then the king answered the people roughly, and rejected the advice which the elders had given him;", 
                "num": 13
              }, 
              {
                "text": "and he spoke to them according to the advice of the young men, saying, \"My father made your yoke heavy, but I will add to your yoke; my father chastised you with whips, but I will chastise you with scourges!\"", 
                "num": 14
              }, 
              {
                "text": "So the king did not listen to the people; for the turn of events was from the LORD, that He might fulfill His word, which the LORD had spoken by Ahijah the Shilonite to Jeroboam the son of Nebat.", 
                "num": 15
              }, 
              {
                "text": "Now when all Israel saw that the king did not listen to them, the people answered the king, saying: \"What share have we in David? We have no inheritance in the son of Jesse. To your tents, O Israel! Now, see to your own house, O David!\" So Israel departed to their tents.", 
                "num": 16
              }, 
              {
                "text": "But Rehoboam reigned over the children of Israel who dwelt in the cities of Judah.", 
                "num": 17
              }, 
              {
                "text": "Then King Rehoboam sent Adoram, who was in charge of the revenue; but all Israel stoned him with stones, and he died. Therefore King Rehoboam mounted his chariot in haste to flee to Jerusalem.", 
                "num": 18
              }, 
              {
                "text": "So Israel has been in rebellion against the house of David to this day.", 
                "num": 19
              }, 
              {
                "text": "Now it came to pass when all Israel heard that Jeroboam had come back, they sent for him and called him to the congregation, and made him king over all Israel. There was none who followed the house of David, but the tribe of Judah only.", 
                "num": 20
              }, 
              {
                "text": "And when Rehoboam came to Jerusalem, he assembled all the house of Judah with the tribe of Benjamin, one hundred and eighty thousand chosen men who were warriors, to fight against the house of Israel, that he might restore the kingdom to Rehoboam the son of Solomon.", 
                "num": 21
              }, 
              {
                "text": "But the word of God came to Shemaiah the man of God, saying,", 
                "num": 22
              }, 
              {
                "text": "\"Speak to Rehoboam the son of Solomon, king of Judah, to all the house of Judah and Benjamin, and to the rest of the people, saying,", 
                "num": 23
              }, 
              {
                "text": "\"Thus says the LORD: \"You shall not go up nor fight against your brethren the children of Israel. Let every man return to his house, for this thing is from Me.\"\"' Therefore they obeyed the word of the LORD, and turned back, according to the word of the LORD.", 
                "num": 24
              }, 
              {
                "text": "Then Jeroboam built Shechem in the mountains of Ephraim, and dwelt there. Also he went out from there and built Penuel.", 
                "num": 25
              }, 
              {
                "text": "And Jeroboam said in his heart, \"Now the kingdom may return to the house of David:", 
                "num": 26
              }, 
              {
                "text": "If these people go up to offer sacrifices in the house of the LORD at Jerusalem, then the heart of this people will turn back to their lord, Rehoboam king of Judah, and they will kill me and go back to Rehoboam king of Judah.\"", 
                "num": 27
              }, 
              {
                "text": "Therefore the king asked advice, made two calves of gold, and said to the people, \"It is too much for you to go up to Jerusalem. Here are your gods, O Israel, which brought you up from the land of Egypt!\"", 
                "num": 28
              }, 
              {
                "text": "And he set up one in Bethel, and the other he put in Dan.", 
                "num": 29
              }, 
              {
                "text": "Now this thing became a sin, for the people went to worship before the one as far as Dan.", 
                "num": 30
              }, 
              {
                "text": "He made shrines on the high places, and made priests from every class of people, who were not of the sons of Levi.", 
                "num": 31
              }, 
              {
                "text": "Jeroboam ordained a feast on the fifteenth day of the eighth month, like the feast that was in Judah, and offered sacrifices on the altar. So he did at Bethel, sacrificing to the calves that he had made. And at Bethel he installed the priests of the high places which he had made.", 
                "num": 32
              }, 
              {
                "text": "So he made offerings on the altar which he had made at Bethel on the fifteenth day of the eighth month, in the month which he had devised in his own heart. And he ordained a feast for the children of Israel, and offered sacrifices on the altar and burned incense.", 
                "num": 33
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "And behold, a man of God went from Judah to Bethel by the word of the LORD, and Jeroboam stood by the altar to burn incense.", 
                "num": 1
              }, 
              {
                "text": "Then he cried out against the altar by the word of the LORD, and said, \"O altar, altar! Thus says the LORD: \"Behold, a child, Josiah by name, shall be born to the house of David; and on you he shall sacrifice the priests of the high places who burn incense on you, and men's bones shall be burned on you.\"'", 
                "num": 2
              }, 
              {
                "text": "And he gave a sign the same day, saying, \"This is the sign which the LORD has spoken: Surely the altar shall split apart, and the ashes on it shall be poured out.\"", 
                "num": 3
              }, 
              {
                "text": "So it came to pass when King Jeroboam heard the saying of the man of God, who cried out against the altar in Bethel, that he stretched out his hand from the altar, saying, \"Arrest him!\" Then his hand, which he stretched out toward him, withered, so that he could not pull it back to himself.", 
                "num": 4
              }, 
              {
                "text": "The altar also was split apart, and the ashes poured out from the altar, according to the sign which the man of God had given by the word of the LORD.", 
                "num": 5
              }, 
              {
                "text": "Then the king answered and said to the man of God, \"Please entreat the favor of the LORD your God, and pray for me, that my hand may be restored to me.\" So the man of God entreated the LORD, and the king's hand was restored to him, and became as before.", 
                "num": 6
              }, 
              {
                "text": "Then the king said to the man of God, \"Come home with me and refresh yourself, and I will give you a reward.\"", 
                "num": 7
              }, 
              {
                "text": "But the man of God said to the king, \"If you were to give me half your house, I would not go in with you; nor would I eat bread nor drink water in this place.", 
                "num": 8
              }, 
              {
                "text": "For so it was commanded me by the word of the LORD, saying, \"You shall not eat bread, nor drink water, nor return by the same way you came.\"'", 
                "num": 9
              }, 
              {
                "text": "So he went another way and did not return by the way he came to Bethel.", 
                "num": 10
              }, 
              {
                "text": "Now an old prophet dwelt in Bethel, and his sons came and told him all the works that the man of God had done that day in Bethel; they also told their father the words which he had spoken to the king.", 
                "num": 11
              }, 
              {
                "text": "And their father said to them, \"Which way did he go?\" For his sons had seen which way the man of God went who came from Judah.", 
                "num": 12
              }, 
              {
                "text": "Then he said to his sons, \"Saddle the donkey for me.\" So they saddled the donkey for him; and he rode on it,", 
                "num": 13
              }, 
              {
                "text": "and went after the man of God, and found him sitting under an oak. Then he said to him, \"Are you the man of God who came from Judah?\" And he said, \"I am.\"", 
                "num": 14
              }, 
              {
                "text": "Then he said to him, \"Come home with me and eat bread.\"", 
                "num": 15
              }, 
              {
                "text": "And he said, \"I cannot return with you nor go in with you; neither can I eat bread nor drink water with you in this place.", 
                "num": 16
              }, 
              {
                "text": "For I have been told by the word of the LORD, \"You shall not eat bread nor drink water there, nor return by going the way you came.\"'", 
                "num": 17
              }, 
              {
                "text": "He said to him, \"I too am a prophet as you are, and an angel spoke to me by the word of the LORD, saying, \"Bring him back with you to your house, that he may eat bread and drink water.\"' (He was lying to him.)", 
                "num": 18
              }, 
              {
                "text": "So he went back with him, and ate bread in his house, and drank water.", 
                "num": 19
              }, 
              {
                "text": "Now it happened, as they sat at the table, that the word of the LORD came to the prophet who had brought him back;", 
                "num": 20
              }, 
              {
                "text": "and he cried out to the man of God who came from Judah, saying, \"Thus says the LORD: \"Because you have disobeyed the word of the LORD, and have not kept the commandment which the LORD your God commanded you,", 
                "num": 21
              }, 
              {
                "text": "but you came back, ate bread, and drank water in the place of which the LORD said to you, \"Eat no bread and drink no water,\" your corpse shall not come to the tomb of your fathers.\"'", 
                "num": 22
              }, 
              {
                "text": "So it was, after he had eaten bread and after he had drunk, that he saddled the donkey for him, the prophet whom he had brought back.", 
                "num": 23
              }, 
              {
                "text": "When he was gone, a lion met him on the road and killed him. And his corpse was thrown on the road, and the donkey stood by it. The lion also stood by the corpse.", 
                "num": 24
              }, 
              {
                "text": "And there, men passed by and saw the corpse thrown on the road, and the lion standing by the corpse. Then they went and told it in the city where the old prophet dwelt.", 
                "num": 25
              }, 
              {
                "text": "Now when the prophet who had brought him back from the way heard it, he said, \"It is the man of God who was disobedient to the word of the LORD. Therefore the LORD has delivered him to the lion, which has torn him and killed him, according to the word of the LORD which He spoke to him.\"", 
                "num": 26
              }, 
              {
                "text": "And he spoke to his sons, saying, \"Saddle the donkey for me.\" So they saddled it.", 
                "num": 27
              }, 
              {
                "text": "Then he went and found his corpse thrown on the road, and the donkey and the lion standing by the corpse. The lion had not eaten the corpse nor torn the donkey.", 
                "num": 28
              }, 
              {
                "text": "And the prophet took up the corpse of the man of God, laid it on the donkey, and brought it back. So the old prophet came to the city to mourn, and to bury him.", 
                "num": 29
              }, 
              {
                "text": "Then he laid the corpse in his own tomb; and they mourned over him, saying, \"Alas, my brother!\"", 
                "num": 30
              }, 
              {
                "text": "So it was, after he had buried him, that he spoke to his sons, saying, \"When I am dead, then bury me in the tomb where the man of God is buried; lay my bones beside his bones.", 
                "num": 31
              }, 
              {
                "text": "For the saying which he cried out by the word of the LORD against the altar in Bethel, and against all the shrines on the high places which are in the cities of Samaria, will surely come to pass.\"", 
                "num": 32
              }, 
              {
                "text": "After this event Jeroboam did not turn from his evil way, but again he made priests from every class of people for the high places; whoever wished, he consecrated him, and he became one of the priests of the high places.", 
                "num": 33
              }, 
              {
                "text": "And this thing was the sin of the house of Jeroboam, so as to exterminate and destroy it from the face of the earth.", 
                "num": 34
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "At that time Abijah the son of Jeroboam became sick.", 
                "num": 1
              }, 
              {
                "text": "And Jeroboam said to his wife, \"Please arise, and disguise yourself, that they may not recognize you as the wife of Jeroboam, and go to Shiloh. Indeed, Ahijah the prophet is there, who told me that I would be king over this people.", 
                "num": 2
              }, 
              {
                "text": "Also take with you ten loaves, some cakes, and a jar of honey, and go to him; he will tell you what will become of the child.\"", 
                "num": 3
              }, 
              {
                "text": "And Jeroboam's wife did so; she arose and went to Shiloh, and came to the house of Ahijah. But Ahijah could not see, for his eyes were glazed by reason of his age.", 
                "num": 4
              }, 
              {
                "text": "Now the LORD had said to Ahijah, \"Here is the wife of Jeroboam, coming to ask you something about her son, for he is sick. Thus and thus you shall say to her; for it will be, when she comes in, that she will pretend to be another woman.\"", 
                "num": 5
              }, 
              {
                "text": "And so it was, when Ahijah heard the sound of her footsteps as she came through the door, he said, \"Come in, wife of Jeroboam. Why do you pretend to be another person? For I have been sent to you with bad news.", 
                "num": 6
              }, 
              {
                "text": "Go, tell Jeroboam, \"Thus says the LORD God of Israel: \"Because I exalted you from among the people, and made you ruler over My people Israel,", 
                "num": 7
              }, 
              {
                "text": "and tore the kingdom away from the house of David, and gave it to you; and yet you have not been as My servant David, who kept My commandments and who followed Me with all his heart, to do only what was right in My eyes;", 
                "num": 8
              }, 
              {
                "text": "but you have done more evil than all who were before you, for you have gone and made for yourself other gods and molded images to provoke Me to anger, and have cast Me behind your back--", 
                "num": 9
              }, 
              {
                "text": "therefore behold! I will bring disaster on the house of Jeroboam, and will cut off from Jeroboam every male in Israel, bond and free; I will take away the remnant of the house of Jeroboam, as one takes away refuse until it is all gone.", 
                "num": 10
              }, 
              {
                "text": "The dogs shall eat whoever belongs to Jeroboam and dies in the city, and the birds of the air shall eat whoever dies in the field; for the LORD has spoken!\"'", 
                "num": 11
              }, 
              {
                "text": "Arise therefore, go to your own house. When your feet enter the city, the child shall die.", 
                "num": 12
              }, 
              {
                "text": "And all Israel shall mourn for him and bury him, for he is the only one of Jeroboam who shall come to the grave, because in him there is found something good toward the LORD God of Israel in the house of Jeroboam.", 
                "num": 13
              }, 
              {
                "text": "\"Moreover the LORD will raise up for Himself a king over Israel who shall cut off the house of Jeroboam; this is the day. What? Even now!", 
                "num": 14
              }, 
              {
                "text": "For the LORD will strike Israel, as a reed is shaken in the water. He will uproot Israel from this good land which He gave to their fathers, and will scatter them beyond the River, because they have made their wooden images, provoking the LORD to anger.", 
                "num": 15
              }, 
              {
                "text": "And He will give Israel up because of the sins of Jeroboam, who sinned and who made Israel sin.\"", 
                "num": 16
              }, 
              {
                "text": "Then Jeroboam's wife arose and departed, and came to Tirzah. When she came to the threshold of the house, the child died.", 
                "num": 17
              }, 
              {
                "text": "And they buried him; and all Israel mourned for him, according to the word of the LORD which He spoke through His servant Ahijah the prophet.", 
                "num": 18
              }, 
              {
                "text": "Now the rest of the acts of Jeroboam, how he made war and how he reigned, indeed they are written in the book of the chronicles of the kings of Israel.", 
                "num": 19
              }, 
              {
                "text": "The period that Jeroboam reigned was twenty-two years. So he rested with his fathers. Then Nadab his son reigned in his place.", 
                "num": 20
              }, 
              {
                "text": "And Rehoboam the son of Solomon reigned in Judah. Rehoboam was forty-one years old when he became king. He reigned seventeen years in Jerusalem, the city which the LORD had chosen out of all the tribes of Israel, to put His name there. His mother's name was Naamah, an Ammonitess.", 
                "num": 21
              }, 
              {
                "text": "Now Judah did evil in the sight of the LORD, and they provoked Him to jealousy with their sins which they committed, more than all that their fathers had done.", 
                "num": 22
              }, 
              {
                "text": "For they also built for themselves high places, sacred pillars, and wooden images on every high hill and under every green tree.", 
                "num": 23
              }, 
              {
                "text": "And there were also perverted persons in the land. They did according to all the abominations of the nations which the LORD had cast out before the children of Israel.", 
                "num": 24
              }, 
              {
                "text": "It happened in the fifth year of King Rehoboam that Shishak king of Egypt came up against Jerusalem.", 
                "num": 25
              }, 
              {
                "text": "And he took away the treasures of the house of the LORD and the treasures of the king's house; he took away everything. He also took away all the gold shields which Solomon had made.", 
                "num": 26
              }, 
              {
                "text": "Then King Rehoboam made bronze shields in their place, and committed them to the hands of the captains of the guard, who guarded the doorway of the king's house.", 
                "num": 27
              }, 
              {
                "text": "And whenever the king entered the house of the LORD, the guards carried them, then brought them back into the guardroom.", 
                "num": 28
              }, 
              {
                "text": "Now the rest of the acts of Rehoboam, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 29
              }, 
              {
                "text": "And there was war between Rehoboam and Jeroboam all their days.", 
                "num": 30
              }, 
              {
                "text": "So Rehoboam rested with his fathers, and was buried with his fathers in the City of David. His mother's name was Naamah, an Ammonitess. Then Abijam his son reigned in his place.", 
                "num": 31
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "In the eighteenth year of King Jeroboam the son of Nebat, Abijam became king over Judah.", 
                "num": 1
              }, 
              {
                "text": "He reigned three years in Jerusalem. His mother's name was Maachah the granddaughter of Abishalom.", 
                "num": 2
              }, 
              {
                "text": "And he walked in all the sins of his father, which he had done before him; his heart was not loyal to the LORD his God, as was the heart of his father David.", 
                "num": 3
              }, 
              {
                "text": "Nevertheless for David's sake the LORD his God gave him a lamp in Jerusalem, by setting up his son after him and by establishing Jerusalem;", 
                "num": 4
              }, 
              {
                "text": "because David did what was right in the eyes of the LORD, and had not turned aside from anything that He commanded him all the days of his life, except in the matter of Uriah the Hittite.", 
                "num": 5
              }, 
              {
                "text": "And there was war between Rehoboam and Jeroboam all the days of his life.", 
                "num": 6
              }, 
              {
                "text": "Now the rest of the acts of Abijam, and all that he did, are they not written in the book of the chronicles of the kings of Judah? And there was war between Abijam and Jeroboam.", 
                "num": 7
              }, 
              {
                "text": "So Abijam rested with his fathers, and they buried him in the City of David. Then Asa his son reigned in his place.", 
                "num": 8
              }, 
              {
                "text": "In the twentieth year of Jeroboam king of Israel, Asa became king over Judah.", 
                "num": 9
              }, 
              {
                "text": "And he reigned forty-one years in Jerusalem. His grandmother's name was Maachah the granddaughter of Abishalom.", 
                "num": 10
              }, 
              {
                "text": "Asa did what was right in the eyes of the LORD, as did his father David.", 
                "num": 11
              }, 
              {
                "text": "And he banished the perverted persons from the land, and removed all the idols that his fathers had made.", 
                "num": 12
              }, 
              {
                "text": "Also he removed Maachah his grandmother from being queen mother, because she had made an obscene image of Asherah. And Asa cut down her obscene image and burned it by the Brook Kidron.", 
                "num": 13
              }, 
              {
                "text": "But the high places were not removed. Nevertheless Asa's heart was loyal to the LORD all his days.", 
                "num": 14
              }, 
              {
                "text": "He also brought into the house of the LORD the things which his father had dedicated, and the things which he himself had dedicated: silver and gold and utensils.", 
                "num": 15
              }, 
              {
                "text": "Now there was war between Asa and Baasha king of Israel all their days.", 
                "num": 16
              }, 
              {
                "text": "And Baasha king of Israel came up against Judah, and built Ramah, that he might let none go out or come in to Asa king of Judah.", 
                "num": 17
              }, 
              {
                "text": "Then Asa took all the silver and gold that was left in the treasuries of the house of the LORD and the treasuries of the king's house, and delivered them into the hand of his servants. And King Asa sent them to Ben-Hadad the son of Tabrimmon, the son of Hezion, king of Syria, who dwelt in Damascus, saying,", 
                "num": 18
              }, 
              {
                "text": "\"Let there be a treaty between you and me, as there was between my father and your father. See, I have sent you a present of silver and gold. Come and break your treaty with Baasha king of Israel, so that he will withdraw from me.\"", 
                "num": 19
              }, 
              {
                "text": "So Ben-Hadad heeded King Asa, and sent the captains of his armies against the cities of Israel. He attacked Ijon, Dan, Abel Beth Maachah, and all Chinneroth, with all the land of Naphtali.", 
                "num": 20
              }, 
              {
                "text": "Now it happened, when Baasha heard it, that he stopped building Ramah, and remained in Tirzah.", 
                "num": 21
              }, 
              {
                "text": "Then King Asa made a proclamation throughout all Judah; none was exempted. And they took away the stones and timber of Ramah, which Baasha had used for building; and with them King Asa built Geba of Benjamin, and Mizpah.", 
                "num": 22
              }, 
              {
                "text": "The rest of all the acts of Asa, all his might, all that he did, and the cities which he built, are they not written in the book of the chronicles of the kings of Judah? But in the time of his old age he was diseased in his feet.", 
                "num": 23
              }, 
              {
                "text": "So Asa rested with his fathers, and was buried with his fathers in the City of David his father. Then Jehoshaphat his son reigned in his place.", 
                "num": 24
              }, 
              {
                "text": "Now Nadab the son of Jeroboam became king over Israel in the second year of Asa king of Judah, and he reigned over Israel two years.", 
                "num": 25
              }, 
              {
                "text": "And he did evil in the sight of the LORD, and walked in the way of his father, and in his sin by which he had made Israel sin.", 
                "num": 26
              }, 
              {
                "text": "Then Baasha the son of Ahijah, of the house of Issachar, conspired against him. And Baasha killed him at Gibbethon, which belonged to the Philistines, while Nadab and all Israel laid siege to Gibbethon.", 
                "num": 27
              }, 
              {
                "text": "Baasha killed him in the third year of Asa king of Judah, and reigned in his place.", 
                "num": 28
              }, 
              {
                "text": "And it was so, when he became king, that he killed all the house of Jeroboam. He did not leave to Jeroboam anyone that breathed, until he had destroyed him, according to the word of the LORD which He had spoken by His servant Ahijah the Shilonite,", 
                "num": 29
              }, 
              {
                "text": "because of the sins of Jeroboam, which he had sinned and by which he had made Israel sin, because of his provocation with which he had provoked the LORD God of Israel to anger.", 
                "num": 30
              }, 
              {
                "text": "Now the rest of the acts of Nadab, and all that he did, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 31
              }, 
              {
                "text": "And there was war between Asa and Baasha king of Israel all their days.", 
                "num": 32
              }, 
              {
                "text": "In the third year of Asa king of Judah, Baasha the son of Ahijah became king over all Israel in Tirzah, and reigned twenty-four years.", 
                "num": 33
              }, 
              {
                "text": "He did evil in the sight of the LORD, and walked in the way of Jeroboam, and in his sin by which he had made Israel sin.", 
                "num": 34
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Then the word of the LORD came to Jehu the son of Hanani, against Baasha, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Inasmuch as I lifted you out of the dust and made you ruler over My people Israel, and you have walked in the way of Jeroboam, and have made My people Israel sin, to provoke Me to anger with their sins,", 
                "num": 2
              }, 
              {
                "text": "surely I will take away the posterity of Baasha and the posterity of his house, and I will make your house like the house of Jeroboam the son of Nebat.", 
                "num": 3
              }, 
              {
                "text": "The dogs shall eat whoever belongs to Baasha and dies in the city, and the birds of the air shall eat whoever dies in the fields.\"", 
                "num": 4
              }, 
              {
                "text": "Now the rest of the acts of Baasha, what he did, and his might, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 5
              }, 
              {
                "text": "So Baasha rested with his fathers and was buried in Tirzah. Then Elah his son reigned in his place.", 
                "num": 6
              }, 
              {
                "text": "And also the word of the LORD came by the prophet Jehu the son of Hanani against Baasha and his house, because of all the evil that he did in the sight of the LORD in provoking Him to anger with the work of his hands, in being like the house of Jeroboam, and because he killed them.", 
                "num": 7
              }, 
              {
                "text": "In the twenty-sixth year of Asa king of Judah, Elah the son of Baasha became king over Israel, and reigned two years in Tirzah.", 
                "num": 8
              }, 
              {
                "text": "Now his servant Zimri, commander of half his chariots, conspired against him as he was in Tirzah drinking himself drunk in the house of Arza, steward of his house in Tirzah.", 
                "num": 9
              }, 
              {
                "text": "And Zimri went in and struck him and killed him in the twenty-seventh year of Asa king of Judah, and reigned in his place.", 
                "num": 10
              }, 
              {
                "text": "Then it came to pass, when he began to reign, as soon as he was seated on his throne, that he killed all the household of Baasha; he did not leave him one male, neither of his relatives nor of his friends.", 
                "num": 11
              }, 
              {
                "text": "Thus Zimri destroyed all the household of Baasha, according to the word of the LORD, which He spoke against Baasha by Jehu the prophet,", 
                "num": 12
              }, 
              {
                "text": "for all the sins of Baasha and the sins of Elah his son, by which they had sinned and by which they had made Israel sin, in provoking the LORD God of Israel to anger with their idols.", 
                "num": 13
              }, 
              {
                "text": "Now the rest of the acts of Elah, and all that he did, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 14
              }, 
              {
                "text": "In the twenty-seventh year of Asa king of Judah, Zimri had reigned in Tirzah seven days. And the people were encamped against Gibbethon, which belonged to the Philistines.", 
                "num": 15
              }, 
              {
                "text": "Now the people who were encamped heard it said, \"Zimri has conspired and also has killed the king.\" So all Israel made Omri, the commander of the army, king over Israel that day in the camp.", 
                "num": 16
              }, 
              {
                "text": "Then Omri and all Israel with him went up from Gibbethon, and they besieged Tirzah.", 
                "num": 17
              }, 
              {
                "text": "And it happened, when Zimri saw that the city was taken, that he went into the citadel of the king's house and burned the king's house down upon himself with fire, and died,", 
                "num": 18
              }, 
              {
                "text": "because of the sins which he had committed in doing evil in the sight of the LORD, in walking in the way of Jeroboam, and in his sin which he had committed to make Israel sin.", 
                "num": 19
              }, 
              {
                "text": "Now the rest of the acts of Zimri, and the treason he committed, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 20
              }, 
              {
                "text": "Then the people of Israel were divided into two parts: half of the people followed Tibni the son of Ginath, to make him king, and half followed Omri.", 
                "num": 21
              }, 
              {
                "text": "But the people who followed Omri prevailed over the people who followed Tibni the son of Ginath. So Tibni died and Omri reigned.", 
                "num": 22
              }, 
              {
                "text": "In the thirty-first year of Asa king of Judah, Omri became king over Israel, and reigned twelve years. Six years he reigned in Tirzah.", 
                "num": 23
              }, 
              {
                "text": "And he bought the hill of Samaria from Shemer for two talents of silver; then he built on the hill, and called the name of the city which he built, Samaria, after the name of Shemer, owner of the hill.", 
                "num": 24
              }, 
              {
                "text": "Omri did evil in the eyes of the LORD, and did worse than all who were before him.", 
                "num": 25
              }, 
              {
                "text": "For he walked in all the ways of Jeroboam the son of Nebat, and in his sin by which he had made Israel sin, provoking the LORD God of Israel to anger with their idols.", 
                "num": 26
              }, 
              {
                "text": "Now the rest of the acts of Omri which he did, and the might that he showed, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 27
              }, 
              {
                "text": "So Omri rested with his fathers and was buried in Samaria. Then Ahab his son reigned in his place.", 
                "num": 28
              }, 
              {
                "text": "In the thirty-eighth year of Asa king of Judah, Ahab the son of Omri became king over Israel; and Ahab the son of Omri reigned over Israel in Samaria twenty-two years.", 
                "num": 29
              }, 
              {
                "text": "Now Ahab the son of Omri did evil in the sight of the LORD, more than all who were before him.", 
                "num": 30
              }, 
              {
                "text": "And it came to pass, as though it had been a trivial thing for him to walk in the sins of Jeroboam the son of Nebat, that he took as wife Jezebel the daughter of Ethbaal, king of the Sidonians; and he went and served Baal and worshiped him.", 
                "num": 31
              }, 
              {
                "text": "Then he set up an altar for Baal in the temple of Baal, which he had built in Samaria.", 
                "num": 32
              }, 
              {
                "text": "And Ahab made a wooden image. Ahab did more to provoke the LORD God of Israel to anger than all the kings of Israel who were before him.", 
                "num": 33
              }, 
              {
                "text": "In his days Hiel of Bethel built Jericho. He laid its foundation with Abiram his firstborn, and with his youngest son Segub he set up its gates, according to the word of the LORD, which He had spoken through Joshua the son of Nun.", 
                "num": 34
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "And Elijah the Tishbite, of the inhabitants of Gilead, said to Ahab, \"As the LORD God of Israel lives, before whom I stand, there shall not be dew nor rain these years, except at my word.\"", 
                "num": 1
              }, 
              {
                "text": "Then the word of the LORD came to him, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Get away from here and turn eastward, and hide by the Brook Cherith, which flows into the Jordan.", 
                "num": 3
              }, 
              {
                "text": "And it will be that you shall drink from the brook, and I have commanded the ravens to feed you there.\"", 
                "num": 4
              }, 
              {
                "text": "So he went and did according to the word of the LORD, for he went and stayed by the Brook Cherith, which flows into the Jordan.", 
                "num": 5
              }, 
              {
                "text": "The ravens brought him bread and meat in the morning, and bread and meat in the evening; and he drank from the brook.", 
                "num": 6
              }, 
              {
                "text": "And it happened after a while that the brook dried up, because there had been no rain in the land.", 
                "num": 7
              }, 
              {
                "text": "Then the word of the LORD came to him, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Arise, go to Zarephath, which belongs to Sidon, and dwell there. See, I have commanded a widow there to provide for you.\"", 
                "num": 9
              }, 
              {
                "text": "So he arose and went to Zarephath. And when he came to the gate of the city, indeed a widow was there gathering sticks. And he called to her and said, \"Please bring me a little water in a cup, that I may drink.\"", 
                "num": 10
              }, 
              {
                "text": "And as she was going to get it, he called to her and said, \"Please bring me a morsel of bread in your hand.\"", 
                "num": 11
              }, 
              {
                "text": "So she said, \"As the LORD your God lives, I do not have bread, only a handful of flour in a bin, and a little oil in a jar; and see, I am gathering a couple of sticks that I may go in and prepare it for myself and my son, that we may eat it, and die.\"", 
                "num": 12
              }, 
              {
                "text": "And Elijah said to her, \"Do not fear; go and do as you have said, but make me a small cake from it first, and bring it to me; and afterward make some for yourself and your son.", 
                "num": 13
              }, 
              {
                "text": "For thus says the LORD God of Israel: \"The bin of flour shall not be used up, nor shall the jar of oil run dry, until the day the LORD sends rain on the earth.\"'", 
                "num": 14
              }, 
              {
                "text": "So she went away and did according to the word of Elijah; and she and he and her household ate for many days.", 
                "num": 15
              }, 
              {
                "text": "The bin of flour was not used up, nor did the jar of oil run dry, according to the word of the LORD which He spoke by Elijah.", 
                "num": 16
              }, 
              {
                "text": "Now it happened after these things that the son of the woman who owned the house became sick. And his sickness was so serious that there was no breath left in him.", 
                "num": 17
              }, 
              {
                "text": "So she said to Elijah, \"What have I to do with you, O man of God? Have you come to me to bring my sin to remembrance, and to kill my son?\"", 
                "num": 18
              }, 
              {
                "text": "And he said to her, \"Give me your son.\" So he took him out of her arms and carried him to the upper room where he was staying, and laid him on his own bed.", 
                "num": 19
              }, 
              {
                "text": "Then he cried out to the LORD and said, \"O LORD my God, have You also brought tragedy on the widow with whom I lodge, by killing her son?\"", 
                "num": 20
              }, 
              {
                "text": "And he stretched himself out on the child three times, and cried out to the LORD and said, \"O LORD my God, I pray, let this child's soul come back to him.\"", 
                "num": 21
              }, 
              {
                "text": "Then the LORD heard the voice of Elijah; and the soul of the child came back to him, and he revived.", 
                "num": 22
              }, 
              {
                "text": "And Elijah took the child and brought him down from the upper room into the house, and gave him to his mother. And Elijah said, \"See, your son lives!\"", 
                "num": 23
              }, 
              {
                "text": "Then the woman said to Elijah, \"Now by this I know that you are a man of God, and that the word of the LORD in your mouth is the truth.\"", 
                "num": 24
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass after many days that the word of the LORD came to Elijah, in the third year, saying, \"Go, present yourself to Ahab, and I will send rain on the earth.\"", 
                "num": 1
              }, 
              {
                "text": "So Elijah went to present himself to Ahab; and there was a severe famine in Samaria.", 
                "num": 2
              }, 
              {
                "text": "And Ahab had called Obadiah, who was in charge of his house. (Now Obadiah feared the LORD greatly.", 
                "num": 3
              }, 
              {
                "text": "For so it was, while Jezebel massacred the prophets of the LORD, that Obadiah had taken one hundred prophets and hidden them, fifty to a cave, and had fed them with bread and water.)", 
                "num": 4
              }, 
              {
                "text": "And Ahab had said to Obadiah, \"Go into the land to all the springs of water and to all the brooks; perhaps we may find grass to keep the horses and mules alive, so that we will not have to kill any livestock.\"", 
                "num": 5
              }, 
              {
                "text": "So they divided the land between them to explore it; Ahab went one way by himself, and Obadiah went another way by himself.", 
                "num": 6
              }, 
              {
                "text": "Now as Obadiah was on his way, suddenly Elijah met him; and he recognized him, and fell on his face, and said, \"Is that you, my lord Elijah?\"", 
                "num": 7
              }, 
              {
                "text": "And he answered him, \"It is I. Go, tell your master, \"Elijah is here.\"'", 
                "num": 8
              }, 
              {
                "text": "So he said, \"How have I sinned, that you are delivering your servant into the hand of Ahab, to kill me?", 
                "num": 9
              }, 
              {
                "text": "As the LORD your God lives, there is no nation or kingdom where my master has not sent someone to hunt for you; and when they said, \"He is not here,' he took an oath from the kingdom or nation that they could not find you.", 
                "num": 10
              }, 
              {
                "text": "And now you say, \"Go, tell your master, \"Elijah is here\"'!", 
                "num": 11
              }, 
              {
                "text": "And it shall come to pass, as soon as I am gone from you, that the Spirit of the LORD will carry you to a place I do not know; so when I go and tell Ahab, and he cannot find you, he will kill me. But I your servant have feared the LORD from my youth.", 
                "num": 12
              }, 
              {
                "text": "Was it not reported to my lord what I did when Jezebel killed the prophets of the LORD, how I hid one hundred men of the LORD's prophets, fifty to a cave, and fed them with bread and water?", 
                "num": 13
              }, 
              {
                "text": "And now you say, \"Go, tell your master, \"Elijah is here.\"' He will kill me!\"", 
                "num": 14
              }, 
              {
                "text": "Then Elijah said, \"As the LORD of hosts lives, before whom I stand, I will surely present myself to him today.\"", 
                "num": 15
              }, 
              {
                "text": "So Obadiah went to meet Ahab, and told him; and Ahab went to meet Elijah.", 
                "num": 16
              }, 
              {
                "text": "Then it happened, when Ahab saw Elijah, that Ahab said to him, \"Is that you, O troubler of Israel?\"", 
                "num": 17
              }, 
              {
                "text": "And he answered, \"I have not troubled Israel, but you and your father's house have, in that you have forsaken the commandments of the LORD and have followed the Baals.", 
                "num": 18
              }, 
              {
                "text": "Now therefore, send and gather all Israel to me on Mount Carmel, the four hundred and fifty prophets of Baal, and the four hundred prophets of Asherah, who eat at Jezebel's table.\"", 
                "num": 19
              }, 
              {
                "text": "So Ahab sent for all the children of Israel, and gathered the prophets together on Mount Carmel.", 
                "num": 20
              }, 
              {
                "text": "And Elijah came to all the people, and said, \"How long will you falter between two opinions? If the LORD is God, follow Him; but if Baal, follow him.\" But the people answered him not a word.", 
                "num": 21
              }, 
              {
                "text": "Then Elijah said to the people, \"I alone am left a prophet of the LORD; but Baal's prophets are four hundred and fifty men.", 
                "num": 22
              }, 
              {
                "text": "Therefore let them give us two bulls; and let them choose one bull for themselves, cut it in pieces, and lay it on the wood, but put no fire under it; and I will prepare the other bull, and lay it on the wood, but put no fire under it.", 
                "num": 23
              }, 
              {
                "text": "Then you call on the name of your gods, and I will call on the name of the LORD; and the God who answers by fire, He is God.\" So all the people answered and said, \"It is well spoken.\"", 
                "num": 24
              }, 
              {
                "text": "Now Elijah said to the prophets of Baal, \"Choose one bull for yourselves and prepare it first, for you are many; and call on the name of your god, but put no fire under it.\"", 
                "num": 25
              }, 
              {
                "text": "So they took the bull which was given them, and they prepared it, and called on the name of Baal from morning even till noon, saying, \"O Baal, hear us!\" But there was no voice; no one answered. Then they leaped about the altar which they had made.", 
                "num": 26
              }, 
              {
                "text": "And so it was, at noon, that Elijah mocked them and said, \"Cry aloud, for he is a god; either he is meditating, or he is busy, or he is on a journey, or perhaps he is sleeping and must be awakened.\"", 
                "num": 27
              }, 
              {
                "text": "So they cried aloud, and cut themselves, as was their custom, with knives and lances, until the blood gushed out on them.", 
                "num": 28
              }, 
              {
                "text": "And when midday was past, they prophesied until the time of the offering of the evening sacrifice. But there was no voice; no one answered, no one paid attention.", 
                "num": 29
              }, 
              {
                "text": "Then Elijah said to all the people, \"Come near to me.\" So all the people came near to him. And he repaired the altar of the LORD that was broken down.", 
                "num": 30
              }, 
              {
                "text": "And Elijah took twelve stones, according to the number of the tribes of the sons of Jacob, to whom the word of the LORD had come, saying, \"Israel shall be your name.\"", 
                "num": 31
              }, 
              {
                "text": "Then with the stones he built an altar in the name of the LORD; and he made a trench around the altar large enough to hold two seahs of seed.", 
                "num": 32
              }, 
              {
                "text": "And he put the wood in order, cut the bull in pieces, and laid it on the wood, and said, \"Fill four waterpots with water, and pour it on the burnt sacrifice and on the wood.\"", 
                "num": 33
              }, 
              {
                "text": "Then he said, \"Do it a second time,\" and they did it a second time; and he said, \"Do it a third time,\" and they did it a third time.", 
                "num": 34
              }, 
              {
                "text": "So the water ran all around the altar; and he also filled the trench with water.", 
                "num": 35
              }, 
              {
                "text": "And it came to pass, at the time of the offering of the evening sacrifice, that Elijah the prophet came near and said, \"LORD God of Abraham, Isaac, and Israel, let it be known this day that You are God in Israel and I am Your servant, and that I have done all these things at Your word.", 
                "num": 36
              }, 
              {
                "text": "Hear me, O LORD, hear me, that this people may know that You are the LORD God, and that You have turned their hearts back to You again.\"", 
                "num": 37
              }, 
              {
                "text": "Then the fire of the LORD fell and consumed the burnt sacrifice, and the wood and the stones and the dust, and it licked up the water that was in the trench.", 
                "num": 38
              }, 
              {
                "text": "Now when all the people saw it, they fell on their faces; and they said, \"The LORD, He is God! The LORD, He is God!\"", 
                "num": 39
              }, 
              {
                "text": "And Elijah said to them, \"Seize the prophets of Baal! Do not let one of them escape!\" So they seized them; and Elijah brought them down to the Brook Kishon and executed them there.", 
                "num": 40
              }, 
              {
                "text": "Then Elijah said to Ahab, \"Go up, eat and drink; for there is the sound of abundance of rain.\"", 
                "num": 41
              }, 
              {
                "text": "So Ahab went up to eat and drink. And Elijah went up to the top of Carmel; then he bowed down on the ground, and put his face between his knees,", 
                "num": 42
              }, 
              {
                "text": "and said to his servant, \"Go up now, look toward the sea.\" So he went up and looked, and said, \"There is nothing.\" And seven times he said, \"Go again.\"", 
                "num": 43
              }, 
              {
                "text": "Then it came to pass the seventh time, that he said, \"There is a cloud, as small as a man's hand, rising out of the sea!\" So he said, \"Go up, say to Ahab, \"Prepare your chariot, and go down before the rain stops you.\"'", 
                "num": 44
              }, 
              {
                "text": "Now it happened in the meantime that the sky became black with clouds and wind, and there was a heavy rain. So Ahab rode away and went to Jezreel.", 
                "num": 45
              }, 
              {
                "text": "Then the hand of the LORD came upon Elijah; and he girded up his loins and ran ahead of Ahab to the entrance of Jezreel.", 
                "num": 46
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And Ahab told Jezebel all that Elijah had done, also how he had executed all the prophets with the sword.", 
                "num": 1
              }, 
              {
                "text": "Then Jezebel sent a messenger to Elijah, saying, \"So let the gods do to me, and more also, if I do not make your life as the life of one of them by tomorrow about this time.\"", 
                "num": 2
              }, 
              {
                "text": "And when he saw that, he arose and ran for his life, and went to Beersheba, which belongs to Judah, and left his servant there.", 
                "num": 3
              }, 
              {
                "text": "But he himself went a day's journey into the wilderness, and came and sat down under a broom tree. And he prayed that he might die, and said, \"It is enough! Now, LORD, take my life, for I am no better than my fathers!\"", 
                "num": 4
              }, 
              {
                "text": "Then as he lay and slept under a broom tree, suddenly an angel touched him, and said to him, \"Arise and eat.\"", 
                "num": 5
              }, 
              {
                "text": "Then he looked, and there by his head was a cake baked on coals, and a jar of water. So he ate and drank, and lay down again.", 
                "num": 6
              }, 
              {
                "text": "And the angel of the LORD came back the second time, and touched him, and said, \"Arise and eat, because the journey is too great for you.\"", 
                "num": 7
              }, 
              {
                "text": "So he arose, and ate and drank; and he went in the strength of that food forty days and forty nights as far as Horeb, the mountain of God.", 
                "num": 8
              }, 
              {
                "text": "And there he went into a cave, and spent the night in that place; and behold, the word of the LORD came to him, and He said to him, \"What are you doing here, Elijah?\"", 
                "num": 9
              }, 
              {
                "text": "So he said, \"I have been very zealous for the LORD God of hosts; for the children of Israel have forsaken Your covenant, torn down Your altars, and killed Your prophets with the sword. I alone am left; and they seek to take my life.\"", 
                "num": 10
              }, 
              {
                "text": "Then He said, \"Go out, and stand on the mountain before the LORD.\" And behold, the LORD passed by, and a great and strong wind tore into the mountains and broke the rocks in pieces before the LORD, but the LORD was not in the wind; and after the wind an earthquake, but the LORD was not in the earthquake;", 
                "num": 11
              }, 
              {
                "text": "and after the earthquake a fire, but the LORD was not in the fire; and after the fire a still small voice.", 
                "num": 12
              }, 
              {
                "text": "So it was, when Elijah heard it, that he wrapped his face in his mantle and went out and stood in the entrance of the cave. Suddenly a voice came to him, and said, \"What are you doing here, Elijah?\"", 
                "num": 13
              }, 
              {
                "text": "And he said, \"I have been very zealous for the LORD God of hosts; because the children of Israel have forsaken Your covenant, torn down Your altars, and killed Your prophets with the sword. I alone am left; and they seek to take my life.\"", 
                "num": 14
              }, 
              {
                "text": "Then the LORD said to him: \"Go, return on your way to the Wilderness of Damascus; and when you arrive, anoint Hazael as king over Syria.", 
                "num": 15
              }, 
              {
                "text": "Also you shall anoint Jehu the son of Nimshi as king over Israel. And Elisha the son of Shaphat of Abel Meholah you shall anoint as prophet in your place.", 
                "num": 16
              }, 
              {
                "text": "It shall be that whoever escapes the sword of Hazael, Jehu will kill; and whoever escapes the sword of Jehu, Elisha will kill.", 
                "num": 17
              }, 
              {
                "text": "Yet I have reserved seven thousand in Israel, all whose knees have not bowed to Baal, and every mouth that has not kissed him.\"", 
                "num": 18
              }, 
              {
                "text": "So he departed from there, and found Elisha the son of Shaphat, who was plowing with twelve yoke of oxen before him, and he was with the twelfth. Then Elijah passed by him and threw his mantle on him.", 
                "num": 19
              }, 
              {
                "text": "And he left the oxen and ran after Elijah, and said, \"Please let me kiss my father and my mother, and then I will follow you.\" And he said to him, \"Go back again, for what have I done to you?\"", 
                "num": 20
              }, 
              {
                "text": "So Elisha turned back from him, and took a yoke of oxen and slaughtered them and boiled their flesh, using the oxen's equipment, and gave it to the people, and they ate. Then he arose and followed Elijah, and became his servant.", 
                "num": 21
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Now Ben-Hadad the king of Syria gathered all his forces together; thirty-two kings were with him, with horses and chariots. And he went up and besieged Samaria, and made war against it.", 
                "num": 1
              }, 
              {
                "text": "Then he sent messengers into the city to Ahab king of Israel, and said to him, \"Thus says Ben-Hadad:", 
                "num": 2
              }, 
              {
                "text": "\"Your silver and your gold are mine; your loveliest wives and children are mine.\"'", 
                "num": 3
              }, 
              {
                "text": "And the king of Israel answered and said, \"My lord, O king, just as you say, I and all that I have are yours.\"", 
                "num": 4
              }, 
              {
                "text": "Then the messengers came back and said, \"Thus speaks Ben-Hadad, saying, \"Indeed I have sent to you, saying, \"You shall deliver to me your silver and your gold, your wives and your children\";", 
                "num": 5
              }, 
              {
                "text": "but I will send my servants to you tomorrow about this time, and they shall search your house and the houses of your servants. And it shall be, that whatever is pleasant in your eyes, they will put it in their hands and take it.\"'", 
                "num": 6
              }, 
              {
                "text": "So the king of Israel called all the elders of the land, and said, \"Notice, please, and see how this man seeks trouble, for he sent to me for my wives, my children, my silver, and my gold; and I did not deny him.\"", 
                "num": 7
              }, 
              {
                "text": "And all the elders and all the people said to him, \"Do not listen or consent.\"", 
                "num": 8
              }, 
              {
                "text": "Therefore he said to the messengers of Ben-Hadad, \"Tell my lord the king, \"All that you sent for to your servant the first time I will do, but this thing I cannot do.\"' And the messengers departed and brought back word to him.", 
                "num": 9
              }, 
              {
                "text": "Then Ben-Hadad sent to him and said, \"The gods do so to me, and more also, if enough dust is left of Samaria for a handful for each of the people who follow me.\"", 
                "num": 10
              }, 
              {
                "text": "So the king of Israel answered and said, \"Tell him, \"Let not the one who puts on his armor boast like the one who takes it off.\"'", 
                "num": 11
              }, 
              {
                "text": "And it happened when Ben-Hadad heard this message, as he and the kings were drinking at the command post, that he said to his servants, \"Get ready.\" And they got ready to attack the city.", 
                "num": 12
              }, 
              {
                "text": "Suddenly a prophet approached Ahab king of Israel, saying, \"Thus says the LORD: \"Have you seen all this great multitude? Behold, I will deliver it into your hand today, and you shall know that I am the LORD.\"'", 
                "num": 13
              }, 
              {
                "text": "So Ahab said, \"By whom?\" And he said, \"Thus says the LORD: \"By the young leaders of the provinces.\"' Then he said, \"Who will set the battle in order?\" And he answered, \"You.\"", 
                "num": 14
              }, 
              {
                "text": "Then he mustered the young leaders of the provinces, and there were two hundred and thirty-two; and after them he mustered all the people, all the children of Israel--seven thousand.", 
                "num": 15
              }, 
              {
                "text": "So they went out at noon. Meanwhile Ben-Hadad and the thirty-two kings helping him were getting drunk at the command post.", 
                "num": 16
              }, 
              {
                "text": "The young leaders of the provinces went out first. And Ben-Hadad sent out a patrol, and they told him, saying, \"Men are coming out of Samaria!\"", 
                "num": 17
              }, 
              {
                "text": "So he said, \"If they have come out for peace, take them alive; and if they have come out for war, take them alive.\"", 
                "num": 18
              }, 
              {
                "text": "Then these young leaders of the provinces went out of the city with the army which followed them.", 
                "num": 19
              }, 
              {
                "text": "And each one killed his man; so the Syrians fled, and Israel pursued them; and Ben-Hadad the king of Syria escaped on a horse with the cavalry.", 
                "num": 20
              }, 
              {
                "text": "Then the king of Israel went out and attacked the horses and chariots, and killed the Syrians with a great slaughter.", 
                "num": 21
              }, 
              {
                "text": "And the prophet came to the king of Israel and said to him, \"Go, strengthen yourself; take note, and see what you should do, for in the spring of the year the king of Syria will come up against you.\"", 
                "num": 22
              }, 
              {
                "text": "Then the servants of the king of Syria said to him, \"Their gods are gods of the hills. Therefore they were stronger than we; but if we fight against them in the plain, surely we will be stronger than they.", 
                "num": 23
              }, 
              {
                "text": "So do this thing: Dismiss the kings, each from his position, and put captains in their places;", 
                "num": 24
              }, 
              {
                "text": "and you shall muster an army like the army that you have lost, horse for horse and chariot for chariot. Then we will fight against them in the plain; surely we will be stronger than they.\" And he listened to their voice and did so.", 
                "num": 25
              }, 
              {
                "text": "So it was, in the spring of the year, that Ben-Hadad mustered the Syrians and went up to Aphek to fight against Israel.", 
                "num": 26
              }, 
              {
                "text": "And the children of Israel were mustered and given provisions, and they went against them. Now the children of Israel encamped before them like two little flocks of goats, while the Syrians filled the countryside.", 
                "num": 27
              }, 
              {
                "text": "Then a man of God came and spoke to the king of Israel, and said, \"Thus says the LORD: \"Because the Syrians have said, \"The LORD is God of the hills, but He is not God of the valleys,\" therefore I will deliver all this great multitude into your hand, and you shall know that I am the LORD.\"'", 
                "num": 28
              }, 
              {
                "text": "And they encamped opposite each other for seven days. So it was that on the seventh day the battle was joined; and the children of Israel killed one hundred thousand foot soldiers of the Syrians in one day.", 
                "num": 29
              }, 
              {
                "text": "But the rest fled to Aphek, into the city; then a wall fell on twenty-seven thousand of the men who were left. And Ben-Hadad fled and went into the city, into an inner chamber.", 
                "num": 30
              }, 
              {
                "text": "Then his servants said to him, \"Look now, we have heard that the kings of the house of Israel are merciful kings. Please, let us put sackcloth around our waists and ropes around our heads, and go out to the king of Israel; perhaps he will spare your life.\"", 
                "num": 31
              }, 
              {
                "text": "So they wore sackcloth around their waists and put ropes around their heads, and came to the king of Israel and said, \"Your servant Ben-Hadad says, \"Please let me live.\"' And he said, \"Is he still alive? He is my brother.\"", 
                "num": 32
              }, 
              {
                "text": "Now the men were watching closely to see whether any sign of mercy would come from him; and they quickly grasped at this word and said, \"Your brother Ben-Hadad.\" So he said, \"Go, bring him.\" Then Ben-Hadad came out to him; and he had him come up into the chariot.", 
                "num": 33
              }, 
              {
                "text": "So Ben-Hadad said to him, \"The cities which my father took from your father I will restore; and you may set up marketplaces for yourself in Damascus, as my father did in Samaria.\" Then Ahab said, \"I will send you away with this treaty.\" So he made a treaty with him and sent him away.", 
                "num": 34
              }, 
              {
                "text": "Now a certain man of the sons of the prophets said to his neighbor by the word of the LORD, \"Strike me, please.\" And the man refused to strike him.", 
                "num": 35
              }, 
              {
                "text": "Then he said to him, \"Because you have not obeyed the voice of the LORD, surely, as soon as you depart from me, a lion shall kill you.\" And as soon as he left him, a lion found him and killed him.", 
                "num": 36
              }, 
              {
                "text": "And he found another man, and said, \"Strike me, please.\" So the man struck him, inflicting a wound.", 
                "num": 37
              }, 
              {
                "text": "Then the prophet departed and waited for the king by the road, and disguised himself with a bandage over his eyes.", 
                "num": 38
              }, 
              {
                "text": "Now as the king passed by, he cried out to the king and said, \"Your servant went out into the midst of the battle; and there, a man came over and brought a man to me, and said, \"Guard this man; if by any means he is missing, your life shall be for his life, or else you shall pay a talent of silver.'", 
                "num": 39
              }, 
              {
                "text": "While your servant was busy here and there, he was gone.\" Then the king of Israel said to him, \"So shall your judgment be; you yourself have decided it.\"", 
                "num": 40
              }, 
              {
                "text": "And he hastened to take the bandage away from his eyes; and the king of Israel recognized him as one of the prophets.", 
                "num": 41
              }, 
              {
                "text": "Then he said to him, \"Thus says the LORD: \"Because you have let slip out of your hand a man whom I appointed to utter destruction, therefore your life shall go for his life, and your people for his people.\"'", 
                "num": 42
              }, 
              {
                "text": "So the king of Israel went to his house sullen and displeased, and came to Samaria.", 
                "num": 43
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass after these things that Naboth the Jezreelite had a vineyard which was in Jezreel, next to the palace of Ahab king of Samaria.", 
                "num": 1
              }, 
              {
                "text": "So Ahab spoke to Naboth, saying, \"Give me your vineyard, that I may have it for a vegetable garden, because it is near, next to my house; and for it I will give you a vineyard better than it. Or, if it seems good to you, I will give you its worth in money.\"", 
                "num": 2
              }, 
              {
                "text": "But Naboth said to Ahab, \"The LORD forbid that I should give the inheritance of my fathers to you!\"", 
                "num": 3
              }, 
              {
                "text": "So Ahab went into his house sullen and displeased because of the word which Naboth the Jezreelite had spoken to him; for he had said, \"I will not give you the inheritance of my fathers.\" And he lay down on his bed, and turned away his face, and would eat no food.", 
                "num": 4
              }, 
              {
                "text": "But Jezebel his wife came to him, and said to him, \"Why is your spirit so sullen that you eat no food?\"", 
                "num": 5
              }, 
              {
                "text": "He said to her, \"Because I spoke to Naboth the Jezreelite, and said to him, \"Give me your vineyard for money; or else, if it pleases you, I will give you another vineyard for it.' And he answered, \"I will not give you my vineyard.\"'", 
                "num": 6
              }, 
              {
                "text": "Then Jezebel his wife said to him, \"You now exercise authority over Israel! Arise, eat food, and let your heart be cheerful; I will give you the vineyard of Naboth the Jezreelite.\"", 
                "num": 7
              }, 
              {
                "text": "And she wrote letters in Ahab's name, sealed them with his seal, and sent the letters to the elders and the nobles who were dwelling in the city with Naboth.", 
                "num": 8
              }, 
              {
                "text": "She wrote in the letters, saying, 4 Proclaim a fast, and seat Naboth with high honor among the people;", 
                "num": 9
              }, 
              {
                "text": "and seat two men, scoundrels, before him to bear witness against him, saying, You have blasphemed God and the king. Then take him out, and stone him, that he may die.", 
                "num": 10
              }, 
              {
                "text": "So the men of his city, the elders and nobles who were inhabitants of his city, did as Jezebel had sent to them, as it was written in the letters which she had sent to them.", 
                "num": 11
              }, 
              {
                "text": "They proclaimed a fast, and seated Naboth with high honor among the people.", 
                "num": 12
              }, 
              {
                "text": "And two men, scoundrels, came in and sat before him; and the scoundrels witnessed against him, against Naboth, in the presence of the people, saying, \"Naboth has blasphemed God and the king!\" Then they took him outside the city and stoned him with stones, so that he died.", 
                "num": 13
              }, 
              {
                "text": "Then they sent to Jezebel, saying, \"Naboth has been stoned and is dead.\"", 
                "num": 14
              }, 
              {
                "text": "And it came to pass, when Jezebel heard that Naboth had been stoned and was dead, that Jezebel said to Ahab, \"Arise, take possession of the vineyard of Naboth the Jezreelite, which he refused to give you for money; for Naboth is not alive, but dead.\"", 
                "num": 15
              }, 
              {
                "text": "So it was, when Ahab heard that Naboth was dead, that Ahab got up and went down to take possession of the vineyard of Naboth the Jezreelite.", 
                "num": 16
              }, 
              {
                "text": "Then the word of the LORD came to Elijah the Tishbite, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Arise, go down to meet Ahab king of Israel, who lives in Samaria. There he is, in the vineyard of Naboth, where he has gone down to take possession of it.", 
                "num": 18
              }, 
              {
                "text": "You shall speak to him, saying, \"Thus says the LORD: \"Have you murdered and also taken possession?\"' And you shall speak to him, saying, \"Thus says the LORD: \"In the place where dogs licked the blood of Naboth, dogs shall lick your blood, even yours.\"\"'", 
                "num": 19
              }, 
              {
                "text": "So Ahab said to Elijah, \"Have you found me, O my enemy?\" And he answered, \"I have found you, because you have sold yourself to do evil in the sight of the LORD:", 
                "num": 20
              }, 
              {
                "text": "\"Behold, I will bring calamity on you. I will take away your posterity, and will cut off from Ahab every male in Israel, both bond and free.", 
                "num": 21
              }, 
              {
                "text": "I will make your house like the house of Jeroboam the son of Nebat, and like the house of Baasha the son of Ahijah, because of the provocation with which you have provoked Me to anger, and made Israel sin.'", 
                "num": 22
              }, 
              {
                "text": "And concerning Jezebel the LORD also spoke, saying, \"The dogs shall eat Jezebel by the wall of Jezreel.'", 
                "num": 23
              }, 
              {
                "text": "The dogs shall eat whoever belongs to Ahab and dies in the city, and the birds of the air shall eat whoever dies in the field.\"", 
                "num": 24
              }, 
              {
                "text": "But there was no one like Ahab who sold himself to do wickedness in the sight of the LORD, because Jezebel his wife stirred him up.", 
                "num": 25
              }, 
              {
                "text": "And he behaved very abominably in following idols, according to all that the Amorites had done, whom the LORD had cast out before the children of Israel.", 
                "num": 26
              }, 
              {
                "text": "So it was, when Ahab heard those words, that he tore his clothes and put sackcloth on his body, and fasted and lay in sackcloth, and went about mourning.", 
                "num": 27
              }, 
              {
                "text": "And the word of the LORD came to Elijah the Tishbite, saying,", 
                "num": 28
              }, 
              {
                "text": "\"See how Ahab has humbled himself before Me? Because he has humbled himself before Me, I will not bring the calamity in his days. In the days of his son I will bring the calamity on his house.\"", 
                "num": 29
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Now three years passed without war between Syria and Israel.", 
                "num": 1
              }, 
              {
                "text": "Then it came to pass, in the third year, that Jehoshaphat the king of Judah went down to visit the king of Israel.", 
                "num": 2
              }, 
              {
                "text": "And the king of Israel said to his servants, \"Do you know that Ramoth in Gilead is ours, but we hesitate to take it out of the hand of the king of Syria?\"", 
                "num": 3
              }, 
              {
                "text": "So he said to Jehoshaphat, \"Will you go with me to fight at Ramoth Gilead?\" Jehoshaphat said to the king of Israel, \"I am as you are, my people as your people, my horses as your horses.\"", 
                "num": 4
              }, 
              {
                "text": "Also Jehoshaphat said to the king of Israel, \"Please inquire for the word of the LORD today.\"", 
                "num": 5
              }, 
              {
                "text": "Then the king of Israel gathered the prophets together, about four hundred men, and said to them, \"Shall I go against Ramoth Gilead to fight, or shall I refrain?\" So they said, \"Go up, for the Lord will deliver it into the hand of the king.\"", 
                "num": 6
              }, 
              {
                "text": "And Jehoshaphat said, \"Is there not still a prophet of the LORD here, that we may inquire of Him?\"", 
                "num": 7
              }, 
              {
                "text": "So the king of Israel said to Jehoshaphat, \"There is still one man, Micaiah the son of Imlah, by whom we may inquire of the LORD; but I hate him, because he does not prophesy good concerning me, but evil.\" And Jehoshaphat said, \"Let not the king say such things!\"", 
                "num": 8
              }, 
              {
                "text": "Then the king of Israel called an officer and said, \"Bring Micaiah the son of Imlah quickly!\"", 
                "num": 9
              }, 
              {
                "text": "The king of Israel and Jehoshaphat the king of Judah, having put on their robes, sat each on his throne, at a threshing floor at the entrance of the gate of Samaria; and all the prophets prophesied before them.", 
                "num": 10
              }, 
              {
                "text": "Now Zedekiah the son of Chenaanah had made horns of iron for himself; and he said, \"Thus says the LORD: \"With these you shall gore the Syrians until they are destroyed.\"'", 
                "num": 11
              }, 
              {
                "text": "And all the prophets prophesied so, saying, \"Go up to Ramoth Gilead and prosper, for the LORD will deliver it into the king's hand.\"", 
                "num": 12
              }, 
              {
                "text": "Then the messenger who had gone to call Micaiah spoke to him, saying, \"Now listen, the words of the prophets with one accord encourage the king. Please, let your word be like the word of one of them, and speak encouragement.\"", 
                "num": 13
              }, 
              {
                "text": "And Micaiah said, \"As the LORD lives, whatever the LORD says to me, that I will speak.\"", 
                "num": 14
              }, 
              {
                "text": "Then he came to the king; and the king said to him, \"Micaiah, shall we go to war against Ramoth Gilead, or shall we refrain?\" And he answered him, \"Go and prosper, for the LORD will deliver it into the hand of the king!\"", 
                "num": 15
              }, 
              {
                "text": "So the king said to him, \"How many times shall I make you swear that you tell me nothing but the truth in the name of the LORD?\"", 
                "num": 16
              }, 
              {
                "text": "Then he said, \"I saw all Israel scattered on the mountains, as sheep that have no shepherd. And the LORD said, \"These have no master. Let each return to his house in peace.\"'", 
                "num": 17
              }, 
              {
                "text": "And the king of Israel said to Jehoshaphat, \"Did I not tell you he would not prophesy good concerning me, but evil?\"", 
                "num": 18
              }, 
              {
                "text": "Then Micaiah said, \"Therefore hear the word of the LORD: I saw the LORD sitting on His throne, and all the host of heaven standing by, on His right hand and on His left.", 
                "num": 19
              }, 
              {
                "text": "And the LORD said, \"Who will persuade Ahab to go up, that he may fall at Ramoth Gilead?' So one spoke in this manner, and another spoke in that manner.", 
                "num": 20
              }, 
              {
                "text": "Then a spirit came forward and stood before the LORD, and said, \"I will persuade him.'", 
                "num": 21
              }, 
              {
                "text": "The LORD said to him, \"In what way?' So he said, \"I will go out and be a lying spirit in the mouth of all his prophets.' And the LORD said, \"You shall persuade him, and also prevail. Go out and do so.'", 
                "num": 22
              }, 
              {
                "text": "Therefore look! The LORD has put a lying spirit in the mouth of all these prophets of yours, and the LORD has declared disaster against you.\"", 
                "num": 23
              }, 
              {
                "text": "Now Zedekiah the son of Chenaanah went near and struck Micaiah on the cheek, and said, \"Which way did the spirit from the LORD go from me to speak to you?\"", 
                "num": 24
              }, 
              {
                "text": "And Micaiah said, \"Indeed, you shall see on that day when you go into an inner chamber to hide!\"", 
                "num": 25
              }, 
              {
                "text": "So the king of Israel said, \"Take Micaiah, and return him to Amon the governor of the city and to Joash the king's son;", 
                "num": 26
              }, 
              {
                "text": "and say, \"Thus says the king: \"Put this fellow in prison, and feed him with bread of affliction and water of affliction, until I come in peace.\"\"'", 
                "num": 27
              }, 
              {
                "text": "But Micaiah said, \"If you ever return in peace, the LORD has not spoken by me.\" And he said, \"Take heed, all you people!\"", 
                "num": 28
              }, 
              {
                "text": "So the king of Israel and Jehoshaphat the king of Judah went up to Ramoth Gilead.", 
                "num": 29
              }, 
              {
                "text": "And the king of Israel said to Jehoshaphat, \"I will disguise myself and go into battle; but you put on your robes.\" So the king of Israel disguised himself and went into battle.", 
                "num": 30
              }, 
              {
                "text": "Now the king of Syria had commanded the thirty-two captains of his chariots, saying, \"Fight with no one small or great, but only with the king of Israel.\"", 
                "num": 31
              }, 
              {
                "text": "So it was, when the captains of the chariots saw Jehoshaphat, that they said, \"Surely it is the king of Israel!\" Therefore they turned aside to fight against him, and Jehoshaphat cried out.", 
                "num": 32
              }, 
              {
                "text": "And it happened, when the captains of the chariots saw that it was not the king of Israel, that they turned back from pursuing him.", 
                "num": 33
              }, 
              {
                "text": "Now a certain man drew a bow at random, and struck the king of Israel between the joints of his armor. So he said to the driver of his chariot, \"Turn around and take me out of the battle, for I am wounded.\"", 
                "num": 34
              }, 
              {
                "text": "The battle increased that day; and the king was propped up in his chariot, facing the Syrians, and died at evening. The blood ran out from the wound onto the floor of the chariot.", 
                "num": 35
              }, 
              {
                "text": "Then, as the sun was going down, a shout went throughout the army, saying, \"Every man to his city, and every man to his own country!\"", 
                "num": 36
              }, 
              {
                "text": "So the king died, and was brought to Samaria. And they buried the king in Samaria.", 
                "num": 37
              }, 
              {
                "text": "Then someone washed the chariot at a pool in Samaria, and the dogs licked up his blood while the harlots bathed, according to the word of the LORD which He had spoken.", 
                "num": 38
              }, 
              {
                "text": "Now the rest of the acts of Ahab, and all that he did, the ivory house which he built and all the cities that he built, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 39
              }, 
              {
                "text": "So Ahab rested with his fathers. Then Ahaziah his son reigned in his place.", 
                "num": 40
              }, 
              {
                "text": "Jehoshaphat the son of Asa had become king over Judah in the fourth year of Ahab king of Israel.", 
                "num": 41
              }, 
              {
                "text": "Jehoshaphat was thirty-five years old when he became king, and he reigned twenty-five years in Jerusalem. His mother's name was Azubah the daughter of Shilhi.", 
                "num": 42
              }, 
              {
                "text": "And he walked in all the ways of his father Asa. He did not turn aside from them, doing what was right in the eyes of the LORD. Nevertheless the high places were not taken away, for the people offered sacrifices and burned incense on the high places.", 
                "num": 43
              }, 
              {
                "text": "Also Jehoshaphat made peace with the king of Israel.", 
                "num": 44
              }, 
              {
                "text": "Now the rest of the acts of Jehoshaphat, the might that he showed, and how he made war, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 45
              }, 
              {
                "text": "And the rest of the perverted persons, who remained in the days of his father Asa, he banished from the land.", 
                "num": 46
              }, 
              {
                "text": "There was then no king in Edom, only a deputy of the king.", 
                "num": 47
              }, 
              {
                "text": "Jehoshaphat made merchant ships to go to Ophir for gold; but they never sailed, for the ships were wrecked at Ezion Geber.", 
                "num": 48
              }, 
              {
                "text": "Then Ahaziah the son of Ahab said to Jehoshaphat, \"Let my servants go with your servants in the ships.\" But Jehoshaphat would not.", 
                "num": 49
              }, 
              {
                "text": "And Jehoshaphat rested with his fathers, and was buried with his fathers in the City of David his father. Then Jehoram his son reigned in his place.", 
                "num": 50
              }, 
              {
                "text": "Ahaziah the son of Ahab became king over Israel in Samaria in the seventeenth year of Jehoshaphat king of Judah, and reigned two years over Israel.", 
                "num": 51
              }, 
              {
                "text": "He did evil in the sight of the LORD, and walked in the way of his father and in the way of his mother and in the way of Jeroboam the son of Nebat, who had made Israel sin;", 
                "num": 52
              }, 
              {
                "text": "for he served Baal and worshiped him, and provoked the LORD God of Israel to anger, according to all that his father had done.", 
                "num": 53
              }
            ], 
            "num": 22
          }
        ]
      }, 
      {
        "name": "2 Kings", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Moab rebelled against Israel after the death of Ahab.", 
                "num": 1
              }, 
              {
                "text": "Now Ahaziah fell through the lattice of his upper room in Samaria, and was injured; so he sent messengers and said to them, \"Go, inquire of Baal-Zebub, the god of Ekron, whether I shall recover from this injury.\"", 
                "num": 2
              }, 
              {
                "text": "But the angel of the LORD said to Elijah the Tishbite, \"Arise, go up to meet the messengers of the king of Samaria, and say to them, \"Is it because there is no God in Israel that you are going to inquire of Baal-Zebub, the god of Ekron?'", 
                "num": 3
              }, 
              {
                "text": "Now therefore, thus says the LORD: \"You shall not come down from the bed to which you have gone up, but you shall surely die.\"' So Elijah departed.", 
                "num": 4
              }, 
              {
                "text": "And when the messengers returned to him, he said to them, \"Why have you come back?\"", 
                "num": 5
              }, 
              {
                "text": "So they said to him, \"A man came up to meet us, and said to us, \"Go, return to the king who sent you, and say to him, \"Thus says the LORD: \"Is it because there is no God in Israel that you are sending to inquire of Baal-Zebub, the god of Ekron? Therefore you shall not come down from the bed to which you have gone up, but you shall surely die.\"\"\"", 
                "num": 6
              }, 
              {
                "text": "Then he said to them, \"What kind of man was it who came up to meet you and told you these words?\"", 
                "num": 7
              }, 
              {
                "text": "So they answered him, \"A hairy man wearing a leather belt around his waist.\" And he said, \"It is Elijah the Tishbite.\"", 
                "num": 8
              }, 
              {
                "text": "Then the king sent to him a captain of fifty with his fifty men. So he went up to him; and there he was, sitting on the top of a hill. And he spoke to him: \"Man of God, the king has said, \"Come down!\"'", 
                "num": 9
              }, 
              {
                "text": "So Elijah answered and said to the captain of fifty, \"If I am a man of God, then let fire come down from heaven and consume you and your fifty men.\" And fire came down from heaven and consumed him and his fifty.", 
                "num": 10
              }, 
              {
                "text": "Then he sent to him another captain of fifty with his fifty men. And he answered and said to him: \"Man of God, thus has the king said, \"Come down quickly!\"'", 
                "num": 11
              }, 
              {
                "text": "So Elijah answered and said to them, \"If I am a man of God, let fire come down from heaven and consume you and your fifty men.\" And the fire of God came down from heaven and consumed him and his fifty.", 
                "num": 12
              }, 
              {
                "text": "Again, he sent a third captain of fifty with his fifty men. And the third captain of fifty went up, and came and fell on his knees before Elijah, and pleaded with him, and said to him: \"Man of God, please let my life and the life of these fifty servants of yours be precious in your sight.", 
                "num": 13
              }, 
              {
                "text": "Look, fire has come down from heaven and burned up the first two captains of fifties with their fifties. But let my life now be precious in your sight.\"", 
                "num": 14
              }, 
              {
                "text": "And the angel of the LORD said to Elijah, \"Go down with him; do not be afraid of him.\" So he arose and went down with him to the king.", 
                "num": 15
              }, 
              {
                "text": "Then he said to him, \"Thus says the LORD: \"Because you have sent messengers to inquire of Baal-Zebub, the god of Ekron, is it because there is no God in Israel to inquire of His word? Therefore you shall not come down from the bed to which you have gone up, but you shall surely die.\"'", 
                "num": 16
              }, 
              {
                "text": "So Ahaziah died according to the word of the LORD which Elijah had spoken. Because he had no son, Jehoram became king in his place, in the second year of Jehoram the son of Jehoshaphat, king of Judah.", 
                "num": 17
              }, 
              {
                "text": "Now the rest of the acts of Ahaziah which he did, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 18
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass, when the LORD was about to take up Elijah into heaven by a whirlwind, that Elijah went with Elisha from Gilgal.", 
                "num": 1
              }, 
              {
                "text": "Then Elijah said to Elisha, \"Stay here, please, for the LORD has sent me on to Bethel.\" But Elisha said, \"As the LORD lives, and as your soul lives, I will not leave you!\" So they went down to Bethel.", 
                "num": 2
              }, 
              {
                "text": "Now the sons of the prophets who were at Bethel came out to Elisha, and said to him, \"Do you know that the LORD will take away your master from over you today?\" And he said, \"Yes, I know; keep silent!\"", 
                "num": 3
              }, 
              {
                "text": "Then Elijah said to him, \"Elisha, stay here, please, for the LORD has sent me on to Jericho.\" But he said, \"As the LORD lives, and as your soul lives, I will not leave you!\" So they came to Jericho.", 
                "num": 4
              }, 
              {
                "text": "Now the sons of the prophets who were at Jericho came to Elisha and said to him, \"Do you know that the LORD will take away your master from over you today?\" So he answered, \"Yes, I know; keep silent!\"", 
                "num": 5
              }, 
              {
                "text": "Then Elijah said to him, \"Stay here, please, for the LORD has sent me on to the Jordan.\" But he said, \"As the LORD lives, and as your soul lives, I will not leave you!\" So the two of them went on.", 
                "num": 6
              }, 
              {
                "text": "And fifty men of the sons of the prophets went and stood facing them at a distance, while the two of them stood by the Jordan.", 
                "num": 7
              }, 
              {
                "text": "Now Elijah took his mantle, rolled it up, and struck the water; and it was divided this way and that, so that the two of them crossed over on dry ground.", 
                "num": 8
              }, 
              {
                "text": "And so it was, when they had crossed over, that Elijah said to Elisha, \"Ask! What may I do for you, before I am taken away from you?\" Elisha said, \"Please let a double portion of your spirit be upon me.\"", 
                "num": 9
              }, 
              {
                "text": "So he said, \"You have asked a hard thing. Nevertheless, if you see me when I am taken from you, it shall be so for you; but if not, it shall not be so.\"", 
                "num": 10
              }, 
              {
                "text": "Then it happened, as they continued on and talked, that suddenly a chariot of fire appeared with horses of fire, and separated the two of them; and Elijah went up by a whirlwind into heaven.", 
                "num": 11
              }, 
              {
                "text": "And Elisha saw it, and he cried out, \"My father, my father, the chariot of Israel and its horsemen!\" So he saw him no more. And he took hold of his own clothes and tore them into two pieces.", 
                "num": 12
              }, 
              {
                "text": "He also took up the mantle of Elijah that had fallen from him, and went back and stood by the bank of the Jordan.", 
                "num": 13
              }, 
              {
                "text": "Then he took the mantle of Elijah that had fallen from him, and struck the water, and said, \"Where is the LORD God of Elijah?\" And when he also had struck the water, it was divided this way and that; and Elisha crossed over.", 
                "num": 14
              }, 
              {
                "text": "Now when the sons of the prophets who were from Jericho saw him, they said, \"The spirit of Elijah rests on Elisha.\" And they came to meet him, and bowed to the ground before him.", 
                "num": 15
              }, 
              {
                "text": "Then they said to him, \"Look now, there are fifty strong men with your servants. Please let them go and search for your master, lest perhaps the Spirit of the LORD has taken him up and cast him upon some mountain or into some valley.\" And he said, \"You shall not send anyone.\"", 
                "num": 16
              }, 
              {
                "text": "But when they urged him till he was ashamed, he said, \"Send them!\" Therefore they sent fifty men, and they searched for three days but did not find him.", 
                "num": 17
              }, 
              {
                "text": "And when they came back to him, for he had stayed in Jericho, he said to them, \"Did I not say to you, \"Do not go'?\"", 
                "num": 18
              }, 
              {
                "text": "Then the men of the city said to Elisha, \"Please notice, the situation of this city is pleasant, as my lord sees; but the water is bad, and the ground barren.\"", 
                "num": 19
              }, 
              {
                "text": "And he said, \"Bring me a new bowl, and put salt in it.\" So they brought it to him.", 
                "num": 20
              }, 
              {
                "text": "Then he went out to the source of the water, and cast in the salt there, and said, \"Thus says the LORD: \"I have healed this water; from it there shall be no more death or barrenness.\"'", 
                "num": 21
              }, 
              {
                "text": "So the water remains healed to this day, according to the word of Elisha which he spoke.", 
                "num": 22
              }, 
              {
                "text": "Then he went up from there to Bethel; and as he was going up the road, some youths came from the city and mocked him, and said to him, \"Go up, you baldhead! Go up, you baldhead!\"", 
                "num": 23
              }, 
              {
                "text": "So he turned around and looked at them, and pronounced a curse on them in the name of the LORD. And two female bears came out of the woods and mauled forty-two of the youths.", 
                "num": 24
              }, 
              {
                "text": "Then he went from there to Mount Carmel, and from there he returned to Samaria.", 
                "num": 25
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now Jehoram the son of Ahab became king over Israel at Samaria in the eighteenth year of Jehoshaphat king of Judah, and reigned twelve years.", 
                "num": 1
              }, 
              {
                "text": "And he did evil in the sight of the LORD, but not like his father and mother; for he put away the sacred pillar of Baal that his father had made.", 
                "num": 2
              }, 
              {
                "text": "Nevertheless he persisted in the sins of Jeroboam the son of Nebat, who had made Israel sin; he did not depart from them.", 
                "num": 3
              }, 
              {
                "text": "Now Mesha king of Moab was a sheepbreeder, and he regularly paid the king of Israel one hundred thousand lambs and the wool of one hundred thousand rams.", 
                "num": 4
              }, 
              {
                "text": "But it happened, when Ahab died, that the king of Moab rebelled against the king of Israel.", 
                "num": 5
              }, 
              {
                "text": "So King Jehoram went out of Samaria at that time and mustered all Israel.", 
                "num": 6
              }, 
              {
                "text": "Then he went and sent to Jehoshaphat king of Judah, saying, \"The king of Moab has rebelled against me. Will you go with me to fight against Moab?\" And he said, \"I will go up; I am as you are, my people as your people, my horses as your horses.\"", 
                "num": 7
              }, 
              {
                "text": "Then he said, \"Which way shall we go up?\" And he answered, \"By way of the Wilderness of Edom.\"", 
                "num": 8
              }, 
              {
                "text": "So the king of Israel went with the king of Judah and the king of Edom, and they marched on that roundabout route seven days; and there was no water for the army, nor for the animals that followed them.", 
                "num": 9
              }, 
              {
                "text": "And the king of Israel said, \"Alas! For the LORD has called these three kings together to deliver them into the hand of Moab.\"", 
                "num": 10
              }, 
              {
                "text": "But Jehoshaphat said, \"Is there no prophet of the LORD here, that we may inquire of the LORD by him?\" So one of the servants of the king of Israel answered and said, \"Elisha the son of Shaphat is here, who poured water on the hands of Elijah.\"", 
                "num": 11
              }, 
              {
                "text": "And Jehoshaphat said, \"The word of the LORD is with him.\" So the king of Israel and Jehoshaphat and the king of Edom went down to him.", 
                "num": 12
              }, 
              {
                "text": "Then Elisha said to the king of Israel, \"What have I to do with you? Go to the prophets of your father and the prophets of your mother.\" But the king of Israel said to him, \"No, for the LORD has called these three kings together to deliver them into the hand of Moab.\"", 
                "num": 13
              }, 
              {
                "text": "And Elisha said, \"As the LORD of hosts lives, before whom I stand, surely were it not that I regard the presence of Jehoshaphat king of Judah, I would not look at you, nor see you.", 
                "num": 14
              }, 
              {
                "text": "But now bring me a musician.\" Then it happened, when the musician played, that the hand of the LORD came upon him.", 
                "num": 15
              }, 
              {
                "text": "And he said, \"Thus says the LORD: \"Make this valley full of ditches.'", 
                "num": 16
              }, 
              {
                "text": "For thus says the LORD: \"You shall not see wind, nor shall you see rain; yet that valley shall be filled with water, so that you, your cattle, and your animals may drink.'", 
                "num": 17
              }, 
              {
                "text": "And this is a simple matter in the sight of the LORD; He will also deliver the Moabites into your hand.", 
                "num": 18
              }, 
              {
                "text": "Also you shall attack every fortified city and every choice city, and shall cut down every good tree, and stop up every spring of water, and ruin every good piece of land with stones.\"", 
                "num": 19
              }, 
              {
                "text": "Now it happened in the morning, when the grain offering was offered, that suddenly water came by way of Edom, and the land was filled with water.", 
                "num": 20
              }, 
              {
                "text": "And when all the Moabites heard that the kings had come up to fight against them, all who were able to bear arms and older were gathered; and they stood at the border.", 
                "num": 21
              }, 
              {
                "text": "Then they rose up early in the morning, and the sun was shining on the water; and the Moabites saw the water on the other side as red as blood.", 
                "num": 22
              }, 
              {
                "text": "And they said, \"This is blood; the kings have surely struck swords and have killed one another; now therefore, Moab, to the spoil!\"", 
                "num": 23
              }, 
              {
                "text": "So when they came to the camp of Israel, Israel rose up and attacked the Moabites, so that they fled before them; and they entered their land, killing the Moabites.", 
                "num": 24
              }, 
              {
                "text": "Then they destroyed the cities, and each man threw a stone on every good piece of land and filled it; and they stopped up all the springs of water and cut down all the good trees. But they left the stones of Kir Haraseth intact. However the slingers surrounded and attacked it.", 
                "num": 25
              }, 
              {
                "text": "And when the king of Moab saw that the battle was too fierce for him, he took with him seven hundred men who drew swords, to break through to the king of Edom, but they could not.", 
                "num": 26
              }, 
              {
                "text": "Then he took his eldest son who would have reigned in his place, and offered him as a burnt offering upon the wall; and there was great indignation against Israel. So they departed from him and returned to their own land.", 
                "num": 27
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "A certain woman of the wives of the sons of the prophets cried out to Elisha, saying, \"Your servant my husband is dead, and you know that your servant feared the LORD. And the creditor is coming to take my two sons to be his slaves.\"", 
                "num": 1
              }, 
              {
                "text": "So Elisha said to her, \"What shall I do for you? Tell me, what do you have in the house?\" And she said, \"Your maidservant has nothing in the house but a jar of oil.\"", 
                "num": 2
              }, 
              {
                "text": "Then he said, \"Go, borrow vessels from everywhere, from all your neighbors--empty vessels; do not gather just a few.", 
                "num": 3
              }, 
              {
                "text": "And when you have come in, you shall shut the door behind you and your sons; then pour it into all those vessels, and set aside the full ones.\"", 
                "num": 4
              }, 
              {
                "text": "So she went from him and shut the door behind her and her sons, who brought the vessels to her; and she poured it out.", 
                "num": 5
              }, 
              {
                "text": "Now it came to pass, when the vessels were full, that she said to her son, \"Bring me another vessel.\" And he said to her, \"There is not another vessel.\" So the oil ceased.", 
                "num": 6
              }, 
              {
                "text": "Then she came and told the man of God. And he said, \"Go, sell the oil and pay your debt; and you and your sons live on the rest.\"", 
                "num": 7
              }, 
              {
                "text": "Now it happened one day that Elisha went to Shunem, where there was a notable woman, and she persuaded him to eat some food. So it was, as often as he passed by, he would turn in there to eat some food.", 
                "num": 8
              }, 
              {
                "text": "And she said to her husband, \"Look now, I know that this is a holy man of God, who passes by us regularly.", 
                "num": 9
              }, 
              {
                "text": "Please, let us make a small upper room on the wall; and let us put a bed for him there, and a table and a chair and a lampstand; so it will be, whenever he comes to us, he can turn in there.\"", 
                "num": 10
              }, 
              {
                "text": "And it happened one day that he came there, and he turned in to the upper room and lay down there.", 
                "num": 11
              }, 
              {
                "text": "Then he said to Gehazi his servant, \"Call this Shunammite woman.\" When he had called her, she stood before him.", 
                "num": 12
              }, 
              {
                "text": "And he said to him, \"Say now to her, \"Look, you have been concerned for us with all this care. What can I do for you? Do you want me to speak on your behalf to the king or to the commander of the army?\"' She answered, \"I dwell among my own people.\"", 
                "num": 13
              }, 
              {
                "text": "So he said, \"What then is to be done for her?\" And Gehazi answered, \"Actually, she has no son, and her husband is old.\"", 
                "num": 14
              }, 
              {
                "text": "So he said, \"Call her.\" When he had called her, she stood in the doorway.", 
                "num": 15
              }, 
              {
                "text": "Then he said, \"About this time next year you shall embrace a son.\" And she said, \"No, my lord. Man of God, do not lie to your maidservant!\"", 
                "num": 16
              }, 
              {
                "text": "But the woman conceived, and bore a son when the appointed time had come, of which Elisha had told her.", 
                "num": 17
              }, 
              {
                "text": "And the child grew. Now it happened one day that he went out to his father, to the reapers.", 
                "num": 18
              }, 
              {
                "text": "And he said to his father, \"My head, my head!\" So he said to a servant, \"Carry him to his mother.\"", 
                "num": 19
              }, 
              {
                "text": "When he had taken him and brought him to his mother, he sat on her knees till noon, and then died.", 
                "num": 20
              }, 
              {
                "text": "And she went up and laid him on the bed of the man of God, shut the door upon him, and went out.", 
                "num": 21
              }, 
              {
                "text": "Then she called to her husband, and said, \"Please send me one of the young men and one of the donkeys, that I may run to the man of God and come back.\"", 
                "num": 22
              }, 
              {
                "text": "So he said, \"Why are you going to him today? It is neither the New Moon nor the Sabbath.\" And she said, \"It is well.\"", 
                "num": 23
              }, 
              {
                "text": "Then she saddled a donkey, and said to her servant, \"Drive, and go forward; do not slacken the pace for me unless I tell you.\"", 
                "num": 24
              }, 
              {
                "text": "And so she departed, and went to the man of God at Mount Carmel. So it was, when the man of God saw her afar off, that he said to his servant Gehazi, \"Look, the Shunammite woman!", 
                "num": 25
              }, 
              {
                "text": "Please run now to meet her, and say to her, \"Is it well with you? Is it well with your husband? Is it well with the child?\"' And she answered, \"It is well.\"", 
                "num": 26
              }, 
              {
                "text": "Now when she came to the man of God at the hill, she caught him by the feet, but Gehazi came near to push her away. But the man of God said, \"Let her alone; for her soul is in deep distress, and the LORD has hidden it from me, and has not told me.\"", 
                "num": 27
              }, 
              {
                "text": "So she said, \"Did I ask a son of my lord? Did I not say, \"Do not deceive me'?\"", 
                "num": 28
              }, 
              {
                "text": "Then he said to Gehazi, \"Get yourself ready, and take my staff in your hand, and be on your way. If you meet anyone, do not greet him; and if anyone greets you, do not answer him; but lay my staff on the face of the child.\"", 
                "num": 29
              }, 
              {
                "text": "And the mother of the child said, \"As the LORD lives, and as your soul lives, I will not leave you.\" So he arose and followed her.", 
                "num": 30
              }, 
              {
                "text": "Now Gehazi went on ahead of them, and laid the staff on the face of the child; but there was neither voice nor hearing. Therefore he went back to meet him, and told him, saying, \"The child has not awakened.\"", 
                "num": 31
              }, 
              {
                "text": "When Elisha came into the house, there was the child, lying dead on his bed.", 
                "num": 32
              }, 
              {
                "text": "He went in therefore, shut the door behind the two of them, and prayed to the LORD.", 
                "num": 33
              }, 
              {
                "text": "And he went up and lay on the child, and put his mouth on his mouth, his eyes on his eyes, and his hands on his hands; and he stretched himself out on the child, and the flesh of the child became warm.", 
                "num": 34
              }, 
              {
                "text": "He returned and walked back and forth in the house, and again went up and stretched himself out on him; then the child sneezed seven times, and the child opened his eyes.", 
                "num": 35
              }, 
              {
                "text": "And he called Gehazi and said, \"Call this Shunammite woman.\" So he called her. And when she came in to him, he said, \"Pick up your son.\"", 
                "num": 36
              }, 
              {
                "text": "So she went in, fell at his feet, and bowed to the ground; then she picked up her son and went out.", 
                "num": 37
              }, 
              {
                "text": "And Elisha returned to Gilgal, and there was a famine in the land. Now the sons of the prophets were sitting before him; and he said to his servant, \"Put on the large pot, and boil stew for the sons of the prophets.\"", 
                "num": 38
              }, 
              {
                "text": "So one went out into the field to gather herbs, and found a wild vine, and gathered from it a lapful of wild gourds, and came and sliced them into the pot of stew, though they did not know what they were.", 
                "num": 39
              }, 
              {
                "text": "Then they served it to the men to eat. Now it happened, as they were eating the stew, that they cried out and said, \"Man of God, there is death in the pot!\" And they could not eat it.", 
                "num": 40
              }, 
              {
                "text": "So he said, \"Then bring some flour.\" And he put it into the pot, and said, \"Serve it to the people, that they may eat.\" And there was nothing harmful in the pot.", 
                "num": 41
              }, 
              {
                "text": "Then a man came from Baal Shalisha, and brought the man of God bread of the firstfruits, twenty loaves of barley bread, and newly ripened grain in his knapsack. And he said, \"Give it to the people, that they may eat.\"", 
                "num": 42
              }, 
              {
                "text": "But his servant said, \"What? Shall I set this before one hundred men?\" He said again, \"Give it to the people, that they may eat; for thus says the LORD: \"They shall eat and have some left over.\"'", 
                "num": 43
              }, 
              {
                "text": "So he set it before them; and they ate and had some left over, according to the word of the LORD.", 
                "num": 44
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now Naaman, commander of the army of the king of Syria, was a great and honorable man in the eyes of his master, because by him the LORD had given victory to Syria. He was also a mighty man of valor, but a leper.", 
                "num": 1
              }, 
              {
                "text": "And the Syrians had gone out on raids, and had brought back captive a young girl from the land of Israel. She waited on Naaman's wife.", 
                "num": 2
              }, 
              {
                "text": "Then she said to her mistress, \"If only my master were with the prophet who is in Samaria! For he would heal him of his leprosy.\"", 
                "num": 3
              }, 
              {
                "text": "And Naaman went in and told his master, saying, \"Thus and thus said the girl who is from the land of Israel.\"", 
                "num": 4
              }, 
              {
                "text": "Then the king of Syria said, \"Go now, and I will send a letter to the king of Israel.\" So he departed and took with him ten talents of silver, six thousand shekels of gold, and ten changes of clothing.", 
                "num": 5
              }, 
              {
                "text": "Then he brought the letter to the king of Israel, which said, 4 Now be advised, when this letter comes to you, that I have sent Naaman my servant to you, that you may heal him of his leprosy.", 
                "num": 6
              }, 
              {
                "text": "And it happened, when the king of Israel read the letter, that he tore his clothes and said, \"Am I God, to kill and make alive, that this man sends a man to me to heal him of his leprosy? Therefore please consider, and see how he seeks a quarrel with me.\"", 
                "num": 7
              }, 
              {
                "text": "So it was, when Elisha the man of God heard that the king of Israel had torn his clothes, that he sent to the king, saying, \"Why have you torn your clothes? Please let him come to me, and he shall know that there is a prophet in Israel.\"", 
                "num": 8
              }, 
              {
                "text": "Then Naaman went with his horses and chariot, and he stood at the door of Elisha's house.", 
                "num": 9
              }, 
              {
                "text": "And Elisha sent a messenger to him, saying, \"Go and wash in the Jordan seven times, and your flesh shall be restored to you, and you shall be clean.\"", 
                "num": 10
              }, 
              {
                "text": "But Naaman became furious, and went away and said, \"Indeed, I said to myself, \"He will surely come out to me, and stand and call on the name of the LORD his God, and wave his hand over the place, and heal the leprosy.'", 
                "num": 11
              }, 
              {
                "text": "Are not the Abanah and the Pharpar, the rivers of Damascus, better than all the waters of Israel? Could I not wash in them and be clean?\" So he turned and went away in a rage.", 
                "num": 12
              }, 
              {
                "text": "And his servants came near and spoke to him, and said, \"My father, if the prophet had told you to do something great, would you not have done it? How much more then, when he says to you, \"Wash, and be clean'?\"", 
                "num": 13
              }, 
              {
                "text": "So he went down and dipped seven times in the Jordan, according to the saying of the man of God; and his flesh was restored like the flesh of a little child, and he was clean.", 
                "num": 14
              }, 
              {
                "text": "And he returned to the man of God, he and all his aides, and came and stood before him; and he said, \"Indeed, now I know that there is no God in all the earth, except in Israel; now therefore, please take a gift from your servant.\"", 
                "num": 15
              }, 
              {
                "text": "But he said, \"As the LORD lives, before whom I stand, I will receive nothing.\" And he urged him to take it, but he refused.", 
                "num": 16
              }, 
              {
                "text": "So Naaman said, \"Then, if not, please let your servant be given two mule-loads of earth; for your servant will no longer offer either burnt offering or sacrifice to other gods, but to the LORD.", 
                "num": 17
              }, 
              {
                "text": "Yet in this thing may the LORD pardon your servant: when my master goes into the temple of Rimmon to worship there, and he leans on my hand, and I bow down in the temple of Rimmon--when I bow down in the temple of Rimmon, may the LORD please pardon your servant in this thing.\"", 
                "num": 18
              }, 
              {
                "text": "Then he said to him, \"Go in peace.\" So he departed from him a short distance.", 
                "num": 19
              }, 
              {
                "text": "But Gehazi, the servant of Elisha the man of God, said, \"Look, my master has spared Naaman this Syrian, while not receiving from his hands what he brought; but as the LORD lives, I will run after him and take something from him.\"", 
                "num": 20
              }, 
              {
                "text": "So Gehazi pursued Naaman. When Naaman saw him running after him, he got down from the chariot to meet him, and said, \"Is all well?\"", 
                "num": 21
              }, 
              {
                "text": "And he said, \"All is well. My master has sent me, saying, \"Indeed, just now two young men of the sons of the prophets have come to me from the mountains of Ephraim. Please give them a talent of silver and two changes of garments.\"'", 
                "num": 22
              }, 
              {
                "text": "So Naaman said, \"Please, take two talents.\" And he urged him, and bound two talents of silver in two bags, with two changes of garments, and handed them to two of his servants; and they carried them on ahead of him.", 
                "num": 23
              }, 
              {
                "text": "When he came to the citadel, he took them from their hand, and stored them away in the house; then he let the men go, and they departed.", 
                "num": 24
              }, 
              {
                "text": "Now he went in and stood before his master. Elisha said to him, \"Where did you go, Gehazi?\" And he said, \"Your servant did not go anywhere.\"", 
                "num": 25
              }, 
              {
                "text": "Then he said to him, \"Did not my heart go with you when the man turned back from his chariot to meet you? Is it time to receive money and to receive clothing, olive groves and vineyards, sheep and oxen, male and female servants?", 
                "num": 26
              }, 
              {
                "text": "Therefore the leprosy of Naaman shall cling to you and your descendants forever.\" And he went out from his presence leprous, as white as snow.", 
                "num": 27
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "And the sons of the prophets said to Elisha, \"See now, the place where we dwell with you is too small for us.", 
                "num": 1
              }, 
              {
                "text": "Please, let us go to the Jordan, and let every man take a beam from there, and let us make there a place where we may dwell.\" So he answered, \"Go.\"", 
                "num": 2
              }, 
              {
                "text": "Then one said, \"Please consent to go with your servants.\" And he answered, \"I will go.\"", 
                "num": 3
              }, 
              {
                "text": "So he went with them. And when they came to the Jordan, they cut down trees.", 
                "num": 4
              }, 
              {
                "text": "But as one was cutting down a tree, the iron ax head fell into the water; and he cried out and said, \"Alas, master! For it was borrowed.\"", 
                "num": 5
              }, 
              {
                "text": "So the man of God said, \"Where did it fall?\" And he showed him the place. So he cut off a stick, and threw it in there; and he made the iron float.", 
                "num": 6
              }, 
              {
                "text": "Therefore he said, \"Pick it up for yourself.\" So he reached out his hand and took it.", 
                "num": 7
              }, 
              {
                "text": "Now the king of Syria was making war against Israel; and he consulted with his servants, saying, \"My camp will be in such and such a place.\"", 
                "num": 8
              }, 
              {
                "text": "And the man of God sent to the king of Israel, saying, \"Beware that you do not pass this place, for the Syrians are coming down there.\"", 
                "num": 9
              }, 
              {
                "text": "Then the king of Israel sent someone to the place of which the man of God had told him. Thus he warned him, and he was watchful there, not just once or twice.", 
                "num": 10
              }, 
              {
                "text": "Therefore the heart of the king of Syria was greatly troubled by this thing; and he called his servants and said to them, \"Will you not show me which of us is for the king of Israel?\"", 
                "num": 11
              }, 
              {
                "text": "And one of his servants said, \"None, my lord, O king; but Elisha, the prophet who is in Israel, tells the king of Israel the words that you speak in your bedroom.\"", 
                "num": 12
              }, 
              {
                "text": "So he said, \"Go and see where he is, that I may send and get him.\" And it was told him, saying, \"Surely he is in Dothan.\"", 
                "num": 13
              }, 
              {
                "text": "Therefore he sent horses and chariots and a great army there, and they came by night and surrounded the city.", 
                "num": 14
              }, 
              {
                "text": "And when the servant of the man of God arose early and went out, there was an army, surrounding the city with horses and chariots. And his servant said to him, \"Alas, my master! What shall we do?\"", 
                "num": 15
              }, 
              {
                "text": "So he answered, \"Do not fear, for those who are with us are more than those who are with them.\"", 
                "num": 16
              }, 
              {
                "text": "And Elisha prayed, and said, \"LORD, I pray, open his eyes that he may see.\" Then the LORD opened the eyes of the young man, and he saw. And behold, the mountain was full of horses and chariots of fire all around Elisha.", 
                "num": 17
              }, 
              {
                "text": "So when the Syrians came down to him, Elisha prayed to the LORD, and said, \"Strike this people, I pray, with blindness.\" And He struck them with blindness according to the word of Elisha.", 
                "num": 18
              }, 
              {
                "text": "Now Elisha said to them, \"This is not the way, nor is this the city. Follow me, and I will bring you to the man whom you seek.\" But he led them to Samaria.", 
                "num": 19
              }, 
              {
                "text": "So it was, when they had come to Samaria, that Elisha said, \"LORD, open the eyes of these men, that they may see.\" And the LORD opened their eyes, and they saw; and there they were, inside Samaria!", 
                "num": 20
              }, 
              {
                "text": "Now when the king of Israel saw them, he said to Elisha, \"My father, shall I kill them? Shall I kill them?\"", 
                "num": 21
              }, 
              {
                "text": "But he answered, \"You shall not kill them. Would you kill those whom you have taken captive with your sword and your bow? Set food and water before them, that they may eat and drink and go to their master.\"", 
                "num": 22
              }, 
              {
                "text": "Then he prepared a great feast for them; and after they ate and drank, he sent them away and they went to their master. So the bands of Syrian raiders came no more into the land of Israel.", 
                "num": 23
              }, 
              {
                "text": "And it happened after this that Ben-Hadad king of Syria gathered all his army, and went up and besieged Samaria.", 
                "num": 24
              }, 
              {
                "text": "And there was a great famine in Samaria; and indeed they besieged it until a donkey's head was sold for eighty shekels of silver, and one-fourth of a kab of dove droppings for five shekels of silver.", 
                "num": 25
              }, 
              {
                "text": "Then, as the king of Israel was passing by on the wall, a woman cried out to him, saying, \"Help, my lord, O king!\"", 
                "num": 26
              }, 
              {
                "text": "And he said, \"If the LORD does not help you, where can I find help for you? From the threshing floor or from the winepress?\"", 
                "num": 27
              }, 
              {
                "text": "Then the king said to her, \"What is troubling you?\" And she answered, \"This woman said to me, \"Give your son, that we may eat him today, and we will eat my son tomorrow.'", 
                "num": 28
              }, 
              {
                "text": "So we boiled my son, and ate him. And I said to her on the next day, \"Give your son, that we may eat him'; but she has hidden her son.\"", 
                "num": 29
              }, 
              {
                "text": "Now it happened, when the king heard the words of the woman, that he tore his clothes; and as he passed by on the wall, the people looked, and there underneath he had sackcloth on his body.", 
                "num": 30
              }, 
              {
                "text": "Then he said, \"God do so to me and more also, if the head of Elisha the son of Shaphat remains on him today!\"", 
                "num": 31
              }, 
              {
                "text": "But Elisha was sitting in his house, and the elders were sitting with him. And the king sent a man ahead of him, but before the messenger came to him, he said to the elders, \"Do you see how this son of a murderer has sent someone to take away my head? Look, when the messenger comes, shut the door, and hold him fast at the door. Is not the sound of his master's feet behind him?\"", 
                "num": 32
              }, 
              {
                "text": "And while he was still talking with them, there was the messenger, coming down to him; and then the king said, \"Surely this calamity is from the LORD; why should I wait for the LORD any longer?\"", 
                "num": 33
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then Elisha said, \"Hear the word of the LORD. Thus says the LORD: \"Tomorrow about this time a seah of fine flour shall be sold for a shekel, and two seahs of barley for a shekel, at the gate of Samaria.\"'", 
                "num": 1
              }, 
              {
                "text": "So an officer on whose hand the king leaned answered the man of God and said, \"Look, if the LORD would make windows in heaven, could this thing be?\" And he said, \"In fact, you shall see it with your eyes, but you shall not eat of it.\"", 
                "num": 2
              }, 
              {
                "text": "Now there were four leprous men at the entrance of the gate; and they said to one another, \"Why are we sitting here until we die?", 
                "num": 3
              }, 
              {
                "text": "If we say, \"We will enter the city,' the famine is in the city, and we shall die there. And if we sit here, we die also. Now therefore, come, let us surrender to the army of the Syrians. If they keep us alive, we shall live; and if they kill us, we shall only die.\"", 
                "num": 4
              }, 
              {
                "text": "And they rose at twilight to go to the camp of the Syrians; and when they had come to the outskirts of the Syrian camp, to their surprise no one was there.", 
                "num": 5
              }, 
              {
                "text": "For the LORD had caused the army of the Syrians to hear the noise of chariots and the noise of horses--the noise of a great army; so they said to one another, \"Look, the king of Israel has hired against us the kings of the Hittites and the kings of the Egyptians to attack us!\"", 
                "num": 6
              }, 
              {
                "text": "Therefore they arose and fled at twilight, and left the camp intact--their tents, their horses, and their donkeys--and they fled for their lives.", 
                "num": 7
              }, 
              {
                "text": "And when these lepers came to the outskirts of the camp, they went into one tent and ate and drank, and carried from it silver and gold and clothing, and went and hid them; then they came back and entered another tent, and carried some from there also, and went and hid it.", 
                "num": 8
              }, 
              {
                "text": "Then they said to one another, \"We are not doing right. This day is a day of good news, and we remain silent. If we wait until morning light, some punishment will come upon us. Now therefore, come, let us go and tell the king's household.\"", 
                "num": 9
              }, 
              {
                "text": "So they went and called to the gatekeepers of the city, and told them, saying, \"We went to the Syrian camp, and surprisingly no one was there, not a human sound--only horses and donkeys tied, and the tents intact.\"", 
                "num": 10
              }, 
              {
                "text": "And the gatekeepers called out, and they told it to the king's household inside.", 
                "num": 11
              }, 
              {
                "text": "So the king arose in the night and said to his servants, \"Let me now tell you what the Syrians have done to us. They know that we are hungry; therefore they have gone out of the camp to hide themselves in the field, saying, \"When they come out of the city, we shall catch them alive, and get into the city.\"'", 
                "num": 12
              }, 
              {
                "text": "And one of his servants answered and said, \"Please, let several men take five of the remaining horses which are left in the city. Look, they may either become like all the multitude of Israel that are left in it; or indeed, I say, they may become like all the multitude of Israel left from those who are consumed; so let us send them and see.\"", 
                "num": 13
              }, 
              {
                "text": "Therefore they took two chariots with horses; and the king sent them in the direction of the Syrian army, saying, \"Go and see.\"", 
                "num": 14
              }, 
              {
                "text": "And they went after them to the Jordan; and indeed all the road was full of garments and weapons which the Syrians had thrown away in their haste. So the messengers returned and told the king.", 
                "num": 15
              }, 
              {
                "text": "Then the people went out and plundered the tents of the Syrians. So a seah of fine flour was sold for a shekel, and two seahs of barley for a shekel, according to the word of the LORD.", 
                "num": 16
              }, 
              {
                "text": "Now the king had appointed the officer on whose hand he leaned to have charge of the gate. But the people trampled him in the gate, and he died, just as the man of God had said, who spoke when the king came down to him.", 
                "num": 17
              }, 
              {
                "text": "So it happened just as the man of God had spoken to the king, saying, \"Two seahs of barley for a shekel, and a seah of fine flour for a shekel, shall be sold tomorrow about this time in the gate of Samaria.\"", 
                "num": 18
              }, 
              {
                "text": "Then that officer had answered the man of God, and said, \"Now look, if the LORD would make windows in heaven, could such a thing be?\" And he had said, \"In fact, you shall see it with your eyes, but you shall not eat of it.\"", 
                "num": 19
              }, 
              {
                "text": "And so it happened to him, for the people trampled him in the gate, and he died.", 
                "num": 20
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Then Elisha spoke to the woman whose son he had restored to life, saying, \"Arise and go, you and your household, and stay wherever you can; for the LORD has called for a famine, and furthermore, it will come upon the land for seven years.\"", 
                "num": 1
              }, 
              {
                "text": "So the woman arose and did according to the saying of the man of God, and she went with her household and dwelt in the land of the Philistines seven years.", 
                "num": 2
              }, 
              {
                "text": "It came to pass, at the end of seven years, that the woman returned from the land of the Philistines; and she went to make an appeal to the king for her house and for her land.", 
                "num": 3
              }, 
              {
                "text": "Then the king talked with Gehazi, the servant of the man of God, saying, \"Tell me, please, all the great things Elisha has done.\"", 
                "num": 4
              }, 
              {
                "text": "Now it happened, as he was telling the king how he had restored the dead to life, that there was the woman whose son he had restored to life, appealing to the king for her house and for her land. And Gehazi said, \"My lord, O king, this is the woman, and this is her son whom Elisha restored to life.\"", 
                "num": 5
              }, 
              {
                "text": "And when the king asked the woman, she told him. So the king appointed a certain officer for her, saying, \"Restore all that was hers, and all the proceeds of the field from the day that she left the land until now.\"", 
                "num": 6
              }, 
              {
                "text": "Then Elisha went to Damascus, and Ben-Hadad king of Syria was sick; and it was told him, saying, \"The man of God has come here.\"", 
                "num": 7
              }, 
              {
                "text": "And the king said to Hazael, \"Take a present in your hand, and go to meet the man of God, and inquire of the LORD by him, saying, \"Shall I recover from this disease?\"'", 
                "num": 8
              }, 
              {
                "text": "So Hazael went to meet him and took a present with him, of every good thing of Damascus, forty camel-loads; and he came and stood before him, and said, \"Your son Ben-Hadad king of Syria has sent me to you, saying, \"Shall I recover from this disease?\"'", 
                "num": 9
              }, 
              {
                "text": "And Elisha said to him, \"Go, say to him, \"You shall certainly recover.' However the LORD has shown me that he will really die.\"", 
                "num": 10
              }, 
              {
                "text": "Then he set his countenance in a stare until he was ashamed; and the man of God wept.", 
                "num": 11
              }, 
              {
                "text": "And Hazael said, \"Why is my lord weeping?\" He answered, \"Because I know the evil that you will do to the children of Israel: Their strongholds you will set on fire, and their young men you will kill with the sword; and you will dash their children, and rip open their women with child.\"", 
                "num": 12
              }, 
              {
                "text": "So Hazael said, \"But what is your servant--a dog, that he should do this gross thing?\" And Elisha answered, \"The LORD has shown me that you will become king over Syria.\"", 
                "num": 13
              }, 
              {
                "text": "Then he departed from Elisha, and came to his master, who said to him, \"What did Elisha say to you?\" And he answered, \"He told me you would surely recover.\"", 
                "num": 14
              }, 
              {
                "text": "But it happened on the next day that he took a thick cloth and dipped it in water, and spread it over his face so that he died; and Hazael reigned in his place.", 
                "num": 15
              }, 
              {
                "text": "Now in the fifth year of Joram the son of Ahab, king of Israel, Jehoshaphat having been king of Judah, Jehoram the son of Jehoshaphat began to reign as king of Judah.", 
                "num": 16
              }, 
              {
                "text": "He was thirty-two years old when he became king, and he reigned eight years in Jerusalem.", 
                "num": 17
              }, 
              {
                "text": "And he walked in the way of the kings of Israel, just as the house of Ahab had done, for the daughter of Ahab was his wife; and he did evil in the sight of the LORD.", 
                "num": 18
              }, 
              {
                "text": "Yet the LORD would not destroy Judah, for the sake of His servant David, as He promised him to give a lamp to him and his sons forever.", 
                "num": 19
              }, 
              {
                "text": "In his days Edom revolted against Judah's authority, and made a king over themselves.", 
                "num": 20
              }, 
              {
                "text": "So Joram went to Zair, and all his chariots with him. Then he rose by night and attacked the Edomites who had surrounded him and the captains of the chariots; and the troops fled to their tents.", 
                "num": 21
              }, 
              {
                "text": "Thus Edom has been in revolt against Judah's authority to this day. And Libnah revolted at that time.", 
                "num": 22
              }, 
              {
                "text": "Now the rest of the acts of Joram, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 23
              }, 
              {
                "text": "So Joram rested with his fathers, and was buried with his fathers in the City of David. Then Ahaziah his son reigned in his place.", 
                "num": 24
              }, 
              {
                "text": "In the twelfth year of Joram the son of Ahab, king of Israel, Ahaziah the son of Jehoram, king of Judah, began to reign.", 
                "num": 25
              }, 
              {
                "text": "Ahaziah was twenty-two years old when he became king, and he reigned one year in Jerusalem. His mother's name was Athaliah the granddaughter of Omri, king of Israel.", 
                "num": 26
              }, 
              {
                "text": "And he walked in the way of the house of Ahab, and did evil in the sight of the LORD, like the house of Ahab, for he was the son-in-law of the house of Ahab.", 
                "num": 27
              }, 
              {
                "text": "Now he went with Joram the son of Ahab to war against Hazael king of Syria at Ramoth Gilead; and the Syrians wounded Joram.", 
                "num": 28
              }, 
              {
                "text": "Then King Joram went back to Jezreel to recover from the wounds which the Syrians had inflicted on him at Ramah, when he fought against Hazael king of Syria. And Ahaziah the son of Jehoram, king of Judah, went down to see Joram the son of Ahab in Jezreel, because he was sick.", 
                "num": 29
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "And Elisha the prophet called one of the sons of the prophets, and said to him, \"Get yourself ready, take this flask of oil in your hand, and go to Ramoth Gilead.", 
                "num": 1
              }, 
              {
                "text": "Now when you arrive at that place, look there for Jehu the son of Jehoshaphat, the son of Nimshi, and go in and make him rise up from among his associates, and take him to an inner room.", 
                "num": 2
              }, 
              {
                "text": "Then take the flask of oil, and pour it on his head, and say, \"Thus says the LORD: \"I have anointed you king over Israel.\"' Then open the door and flee, and do not delay.\"", 
                "num": 3
              }, 
              {
                "text": "So the young man, the servant of the prophet, went to Ramoth Gilead.", 
                "num": 4
              }, 
              {
                "text": "And when he arrived, there were the captains of the army sitting; and he said, \"I have a message for you, Commander.\" Jehu said, \"For which one of us?\" And he said, \"For you, Commander.\"", 
                "num": 5
              }, 
              {
                "text": "Then he arose and went into the house. And he poured the oil on his head, and said to him, \"Thus says the LORD God of Israel: \"I have anointed you king over the people of the LORD, over Israel.", 
                "num": 6
              }, 
              {
                "text": "You shall strike down the house of Ahab your master, that I may avenge the blood of My servants the prophets, and the blood of all the servants of the LORD, at the hand of Jezebel.", 
                "num": 7
              }, 
              {
                "text": "For the whole house of Ahab shall perish; and I will cut off from Ahab all the males in Israel, both bond and free.", 
                "num": 8
              }, 
              {
                "text": "So I will make the house of Ahab like the house of Jeroboam the son of Nebat, and like the house of Baasha the son of Ahijah.", 
                "num": 9
              }, 
              {
                "text": "The dogs shall eat Jezebel on the plot of ground at Jezreel, and there shall be none to bury her.\"' And he opened the door and fled.", 
                "num": 10
              }, 
              {
                "text": "Then Jehu came out to the servants of his master, and one said to him, \"Is all well? Why did this madman come to you?\" And he said to them, \"You know the man and his babble.\"", 
                "num": 11
              }, 
              {
                "text": "And they said, \"A lie! Tell us now.\" So he said, \"Thus and thus he spoke to me, saying, \"Thus says the LORD: \"I have anointed you king over Israel.\"\"'", 
                "num": 12
              }, 
              {
                "text": "Then each man hastened to take his garment and put it under him on the top of the steps; and they blew trumpets, saying, \"Jehu is king!\"", 
                "num": 13
              }, 
              {
                "text": "So Jehu the son of Jehoshaphat, the son of Nimshi, conspired against Joram. (Now Joram had been defending Ramoth Gilead, he and all Israel, against Hazael king of Syria.", 
                "num": 14
              }, 
              {
                "text": "But King Joram had returned to Jezreel to recover from the wounds which the Syrians had inflicted on him when he fought with Hazael king of Syria.) And Jehu said, \"If you are so minded, let no one leave or escape from the city to go and tell it in Jezreel.\"", 
                "num": 15
              }, 
              {
                "text": "So Jehu rode in a chariot and went to Jezreel, for Joram was laid up there; and Ahaziah king of Judah had come down to see Joram.", 
                "num": 16
              }, 
              {
                "text": "Now a watchman stood on the tower in Jezreel, and he saw the company of Jehu as he came, and said, \"I see a company of men.\" And Joram said, \"Get a horseman and send him to meet them, and let him say, \"Is it peace?\"'", 
                "num": 17
              }, 
              {
                "text": "So the horseman went to meet him, and said, \"Thus says the king: \"Is it peace?\"' And Jehu said, \"What have you to do with peace? Turn around and follow me.\" So the watchman reported, saying, \"The messenger went to them, but is not coming back.\"", 
                "num": 18
              }, 
              {
                "text": "Then he sent out a second horseman who came to them, and said, \"Thus says the king: \"Is it peace?\"' And Jehu answered, \"What have you to do with peace? Turn around and follow me.\"", 
                "num": 19
              }, 
              {
                "text": "So the watchman reported, saying, \"He went up to them and is not coming back; and the driving is like the driving of Jehu the son of Nimshi, for he drives furiously!\"", 
                "num": 20
              }, 
              {
                "text": "Then Joram said, \"Make ready.\" And his chariot was made ready. Then Joram king of Israel and Ahaziah king of Judah went out, each in his chariot; and they went out to meet Jehu, and met him on the property of Naboth the Jezreelite.", 
                "num": 21
              }, 
              {
                "text": "Now it happened, when Joram saw Jehu, that he said, \"Is it peace, Jehu?\" So he answered, \"What peace, as long as the harlotries of your mother Jezebel and her witchcraft are so many?\"", 
                "num": 22
              }, 
              {
                "text": "Then Joram turned around and fled, and said to Ahaziah, \"Treachery, Ahaziah!\"", 
                "num": 23
              }, 
              {
                "text": "Now Jehu drew his bow with full strength and shot Jehoram between his arms; and the arrow came out at his heart, and he sank down in his chariot.", 
                "num": 24
              }, 
              {
                "text": "Then Jehu said to Bidkar his captain, \"Pick him up, and throw him into the tract of the field of Naboth the Jezreelite; for remember, when you and I were riding together behind Ahab his father, that the LORD laid this burden upon him:", 
                "num": 25
              }, 
              {
                "text": "\"Surely I saw yesterday the blood of Naboth and the blood of his sons,' says the LORD, \"and I will repay you in this plot,' says the LORD. Now therefore, take and throw him on the plot of ground, according to the word of the LORD.\"", 
                "num": 26
              }, 
              {
                "text": "But when Ahaziah king of Judah saw this, he fled by the road to Beth Haggan. So Jehu pursued him, and said, \"Shoot him also in the chariot.\" And they shot him at the Ascent of Gur, which is by Ibleam. Then he fled to Megiddo, and died there.", 
                "num": 27
              }, 
              {
                "text": "And his servants carried him in the chariot to Jerusalem, and buried him in his tomb with his fathers in the City of David.", 
                "num": 28
              }, 
              {
                "text": "In the eleventh year of Joram the son of Ahab, Ahaziah had become king over Judah.", 
                "num": 29
              }, 
              {
                "text": "Now when Jehu had come to Jezreel, Jezebel heard of it; and she put paint on her eyes and adorned her head, and looked through a window.", 
                "num": 30
              }, 
              {
                "text": "Then, as Jehu entered at the gate, she said, \"Is it peace, Zimri, murderer of your master?\"", 
                "num": 31
              }, 
              {
                "text": "And he looked up at the window, and said, \"Who is on my side? Who?\" So two or three eunuchs looked out at him.", 
                "num": 32
              }, 
              {
                "text": "Then he said, \"Throw her down.\" So they threw her down, and some of her blood spattered on the wall and on the horses; and he trampled her underfoot.", 
                "num": 33
              }, 
              {
                "text": "And when he had gone in, he ate and drank. Then he said, \"Go now, see to this accursed woman, and bury her, for she was a king's daughter.\"", 
                "num": 34
              }, 
              {
                "text": "So they went to bury her, but they found no more of her than the skull and the feet and the palms of her hands.", 
                "num": 35
              }, 
              {
                "text": "Therefore they came back and told him. And he said, \"This is the word of the LORD, which He spoke by His servant Elijah the Tishbite, saying, \"On the plot of ground at Jezreel dogs shall eat the flesh of Jezebel;", 
                "num": 36
              }, 
              {
                "text": "and the corpse of Jezebel shall be as refuse on the surface of the field, in the plot at Jezreel, so that they shall not say, \"Here lies Jezebel.\"\"'", 
                "num": 37
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now Ahab had seventy sons in Samaria. And Jehu wrote and sent letters to Samaria, to the rulers of Jezreel, to the elders, and to those who reared Ahab's sons, saying:", 
                "num": 1
              }, 
              {
                "text": "Now as soon as this letter comes to you, since your master's sons are with you, and you have chariots and horses, a fortified city also, and weapons,", 
                "num": 2
              }, 
              {
                "text": "choose the best qualified of your master's sons, set him on his father's throne, and fight for your master's house.", 
                "num": 3
              }, 
              {
                "text": "But they were exceedingly afraid, and said, \"Look, two kings could not stand up to him; how then can we stand?\"", 
                "num": 4
              }, 
              {
                "text": "And he who was in charge of the house, and he who was in charge of the city, the elders also, and those who reared the sons, sent to Jehu, saying, \"We are your servants, we will do all you tell us; but we will not make anyone king. Do what is good in your sight.\"", 
                "num": 5
              }, 
              {
                "text": "Then he wrote a second letter to them, saying: 4 If you are for me and will obey my voice, take the heads of the men, your master's sons, and come to me at Jezreel by this time tomorrow. Now the king's sons, seventy persons, were with the great men of the city, who were rearing them.", 
                "num": 6
              }, 
              {
                "text": "So it was, when the letter came to them, that they took the king's sons and slaughtered seventy persons, put their heads in baskets and sent them to him at Jezreel.", 
                "num": 7
              }, 
              {
                "text": "Then a messenger came and told him, saying, \"They have brought the heads of the king's sons.\" And he said, \"Lay them in two heaps at the entrance of the gate until morning.\"", 
                "num": 8
              }, 
              {
                "text": "So it was, in the morning, that he went out and stood, and said to all the people, \"You are righteous. Indeed I conspired against my master and killed him; but who killed all these?", 
                "num": 9
              }, 
              {
                "text": "Know now that nothing shall fall to the earth of the word of the LORD which the LORD spoke concerning the house of Ahab; for the LORD has done what He spoke by His servant Elijah.\"", 
                "num": 10
              }, 
              {
                "text": "So Jehu killed all who remained of the house of Ahab in Jezreel, and all his great men and his close acquaintances and his priests, until he left him none remaining.", 
                "num": 11
              }, 
              {
                "text": "And he arose and departed and went to Samaria. On the way, at Beth Eked of the Shepherds,", 
                "num": 12
              }, 
              {
                "text": "Jehu met with the brothers of Ahaziah king of Judah, and said, \"Who are you?\" So they answered, \"We are the brothers of Ahaziah; we have come down to greet the sons of the king and the sons of the queen mother.\"", 
                "num": 13
              }, 
              {
                "text": "And he said, \"Take them alive!\" So they took them alive, and killed them at the well of Beth Eked, forty-two men; and he left none of them.", 
                "num": 14
              }, 
              {
                "text": "Now when he departed from there, he met Jehonadab the son of Rechab, coming to meet him; and he greeted him and said to him, \"Is your heart right, as my heart is toward your heart?\" And Jehonadab answered, \"It is.\" Jehu said, \"If it is, give me your hand.\" So he gave him his hand, and he took him up to him into the chariot.", 
                "num": 15
              }, 
              {
                "text": "Then he said, \"Come with me, and see my zeal for the LORD.\" So they had him ride in his chariot.", 
                "num": 16
              }, 
              {
                "text": "And when he came to Samaria, he killed all who remained to Ahab in Samaria, till he had destroyed them, according to the word of the LORD which He spoke to Elijah.", 
                "num": 17
              }, 
              {
                "text": "Then Jehu gathered all the people together, and said to them, \"Ahab served Baal a little, Jehu will serve him much.", 
                "num": 18
              }, 
              {
                "text": "Now therefore, call to me all the prophets of Baal, all his servants, and all his priests. Let no one be missing, for I have a great sacrifice for Baal. Whoever is missing shall not live.\" But Jehu acted deceptively, with the intent of destroying the worshipers of Baal.", 
                "num": 19
              }, 
              {
                "text": "And Jehu said, \"Proclaim a solemn assembly for Baal.\" So they proclaimed it.", 
                "num": 20
              }, 
              {
                "text": "Then Jehu sent throughout all Israel; and all the worshipers of Baal came, so that there was not a man left who did not come. So they came into the temple of Baal, and the temple of Baal was full from one end to the other.", 
                "num": 21
              }, 
              {
                "text": "And he said to the one in charge of the wardrobe, \"Bring out vestments for all the worshipers of Baal.\" So he brought out vestments for them.", 
                "num": 22
              }, 
              {
                "text": "Then Jehu and Jehonadab the son of Rechab went into the temple of Baal, and said to the worshipers of Baal, \"Search and see that no servants of the LORD are here with you, but only the worshipers of Baal.\"", 
                "num": 23
              }, 
              {
                "text": "So they went in to offer sacrifices and burnt offerings. Now Jehu had appointed for himself eighty men on the outside, and had said, \"If any of the men whom I have brought into your hands escapes, whoever lets him escape, it shall be his life for the life of the other.\"", 
                "num": 24
              }, 
              {
                "text": "Now it happened, as soon as he had made an end of offering the burnt offering, that Jehu said to the guard and to the captains, \"Go in and kill them; let no one come out!\" And they killed them with the edge of the sword; then the guards and the officers threw them out, and went into the inner room of the temple of Baal.", 
                "num": 25
              }, 
              {
                "text": "And they brought the sacred pillars out of the temple of Baal and burned them.", 
                "num": 26
              }, 
              {
                "text": "Then they broke down the sacred pillar of Baal, and tore down the temple of Baal and made it a refuse dump to this day.", 
                "num": 27
              }, 
              {
                "text": "Thus Jehu destroyed Baal from Israel.", 
                "num": 28
              }, 
              {
                "text": "However Jehu did not turn away from the sins of Jeroboam the son of Nebat, who had made Israel sin, that is, from the golden calves that were at Bethel and Dan.", 
                "num": 29
              }, 
              {
                "text": "And the LORD said to Jehu, \"Because you have done well in doing what is right in My sight, and have done to the house of Ahab all that was in My heart, your sons shall sit on the throne of Israel to the fourth generation.\"", 
                "num": 30
              }, 
              {
                "text": "But Jehu took no heed to walk in the law of the LORD God of Israel with all his heart; for he did not depart from the sins of Jeroboam, who had made Israel sin.", 
                "num": 31
              }, 
              {
                "text": "In those days the LORD began to cut off parts of Israel; and Hazael conquered them in all the territory of Israel", 
                "num": 32
              }, 
              {
                "text": "from the Jordan eastward: all the land of Gilead--Gad, Reuben, and Manasseh--from Aroer, which is by the River Arnon, including Gilead and Bashan.", 
                "num": 33
              }, 
              {
                "text": "Now the rest of the acts of Jehu, all that he did, and all his might, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 34
              }, 
              {
                "text": "So Jehu rested with his fathers, and they buried him in Samaria. Then Jehoahaz his son reigned in his place.", 
                "num": 35
              }, 
              {
                "text": "And the period that Jehu reigned over Israel in Samaria was twenty-eight years.", 
                "num": 36
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "When Athaliah the mother of Ahaziah saw that her son was dead, she arose and destroyed all the royal heirs.", 
                "num": 1
              }, 
              {
                "text": "But Jehosheba, the daughter of King Joram, sister of Ahaziah, took Joash the son of Ahaziah, and stole him away from among the king's sons who were being murdered; and they hid him and his nurse in the bedroom, from Athaliah, so that he was not killed.", 
                "num": 2
              }, 
              {
                "text": "So he was hidden with her in the house of the LORD for six years, while Athaliah reigned over the land.", 
                "num": 3
              }, 
              {
                "text": "In the seventh year Jehoiada sent and brought the captains of hundreds--of the bodyguards and the escorts--and brought them into the house of the LORD to him. And he made a covenant with them and took an oath from them in the house of the LORD, and showed them the king's son.", 
                "num": 4
              }, 
              {
                "text": "Then he commanded them, saying, \"This is what you shall do: One-third of you who come on duty on the Sabbath shall be keeping watch over the king's house,", 
                "num": 5
              }, 
              {
                "text": "one-third shall be at the gate of Sur, and one-third at the gate behind the escorts. You shall keep the watch of the house, lest it be broken down.", 
                "num": 6
              }, 
              {
                "text": "The two contingents of you who go off duty on the Sabbath shall keep the watch of the house of the LORD for the king.", 
                "num": 7
              }, 
              {
                "text": "But you shall surround the king on all sides, every man with his weapons in his hand; and whoever comes within range, let him be put to death. You are to be with the king as he goes out and as he comes in.\"", 
                "num": 8
              }, 
              {
                "text": "So the captains of the hundreds did according to all that Jehoiada the priest commanded. Each of them took his men who were to be on duty on the Sabbath, with those who were going off duty on the Sabbath, and came to Jehoiada the priest.", 
                "num": 9
              }, 
              {
                "text": "And the priest gave the captains of hundreds the spears and shields which had belonged to King David, that were in the temple of the LORD.", 
                "num": 10
              }, 
              {
                "text": "Then the escorts stood, every man with his weapons in his hand, all around the king, from the right side of the temple to the left side of the temple, by the altar and the house.", 
                "num": 11
              }, 
              {
                "text": "And he brought out the king's son, put the crown on him, and gave him the Testimony; they made him king and anointed him, and they clapped their hands and said, \"Long live the king!\"", 
                "num": 12
              }, 
              {
                "text": "Now when Athaliah heard the noise of the escorts and the people, she came to the people in the temple of the LORD.", 
                "num": 13
              }, 
              {
                "text": "When she looked, there was the king standing by a pillar according to custom; and the leaders and the trumpeters were by the king. All the people of the land were rejoicing and blowing trumpets. So Athaliah tore her clothes and cried out, \"Treason! Treason!\"", 
                "num": 14
              }, 
              {
                "text": "And Jehoiada the priest commanded the captains of the hundreds, the officers of the army, and said to them, \"Take her outside under guard, and slay with the sword whoever follows her.\" For the priest had said, \"Do not let her be killed in the house of the LORD.\"", 
                "num": 15
              }, 
              {
                "text": "So they seized her; and she went by way of the horses' entrance into the king's house, and there she was killed.", 
                "num": 16
              }, 
              {
                "text": "Then Jehoiada made a covenant between the LORD, the king, and the people, that they should be the LORD's people, and also between the king and the people.", 
                "num": 17
              }, 
              {
                "text": "And all the people of the land went to the temple of Baal, and tore it down. They thoroughly broke in pieces its altars and images, and killed Mattan the priest of Baal before the altars. And the priest appointed officers over the house of the LORD.", 
                "num": 18
              }, 
              {
                "text": "Then he took the captains of hundreds, the bodyguards, the escorts, and all the people of the land; and they brought the king down from the house of the LORD, and went by way of the gate of the escorts to the king's house. Then he sat on the throne of the kings.", 
                "num": 19
              }, 
              {
                "text": "So all the people of the land rejoiced; and the city was quiet, for they had slain Athaliah with the sword in the king's house.", 
                "num": 20
              }, 
              {
                "text": "Jehoash was seven years old when he became king.", 
                "num": 21
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "In the seventh year of Jehu, Jehoash became king, and he reigned forty years in Jerusalem. His mother's name was Zibiah of Beersheba.", 
                "num": 1
              }, 
              {
                "text": "Jehoash did what was right in the sight of the LORD all the days in which Jehoiada the priest instructed him.", 
                "num": 2
              }, 
              {
                "text": "But the high places were not taken away; the people still sacrificed and burned incense on the high places.", 
                "num": 3
              }, 
              {
                "text": "And Jehoash said to the priests, \"All the money of the dedicated gifts that are brought into the house of the LORD--each man's census money, each man's assessment money--and all the money that a man purposes in his heart to bring into the house of the LORD,", 
                "num": 4
              }, 
              {
                "text": "let the priests take it themselves, each from his constituency; and let them repair the damages of the temple, wherever any dilapidation is found.\"", 
                "num": 5
              }, 
              {
                "text": "Now it was so, by the twenty-third year of King Jehoash, that the priests had not repaired the damages of the temple.", 
                "num": 6
              }, 
              {
                "text": "So King Jehoash called Jehoiada the priest and the other priests, and said to them, \"Why have you not repaired the damages of the temple? Now therefore, do not take more money from your constituency, but deliver it for repairing the damages of the temple.\"", 
                "num": 7
              }, 
              {
                "text": "And the priests agreed that they would neither receive more money from the people, nor repair the damages of the temple.", 
                "num": 8
              }, 
              {
                "text": "Then Jehoiada the priest took a chest, bored a hole in its lid, and set it beside the altar, on the right side as one comes into the house of the LORD; and the priests who kept the door put there all the money brought into the house of the LORD.", 
                "num": 9
              }, 
              {
                "text": "So it was, whenever they saw that there was much money in the chest, that the king's scribe and the high priest came up and put it in bags, and counted the money that was found in the house of the LORD.", 
                "num": 10
              }, 
              {
                "text": "Then they gave the money, which had been apportioned, into the hands of those who did the work, who had the oversight of the house of the LORD; and they paid it out to the carpenters and builders who worked on the house of the LORD,", 
                "num": 11
              }, 
              {
                "text": "and to masons and stonecutters, and for buying timber and hewn stone, to repair the damage of the house of the LORD, and for all that was paid out to repair the temple.", 
                "num": 12
              }, 
              {
                "text": "However there were not made for the house of the LORD basins of silver, trimmers, sprinkling-bowls, trumpets, any articles of gold or articles of silver, from the money brought into the house of the LORD.", 
                "num": 13
              }, 
              {
                "text": "But they gave that to the workmen, and they repaired the house of the LORD with it.", 
                "num": 14
              }, 
              {
                "text": "Moreover they did not require an account from the men into whose hand they delivered the money to be paid to workmen, for they dealt faithfully.", 
                "num": 15
              }, 
              {
                "text": "The money from the trespass offerings and the money from the sin offerings was not brought into the house of the LORD. It belonged to the priests.", 
                "num": 16
              }, 
              {
                "text": "Hazael king of Syria went up and fought against Gath, and took it; then Hazael set his face to go up to Jerusalem.", 
                "num": 17
              }, 
              {
                "text": "And Jehoash king of Judah took all the sacred things that his fathers, Jehoshaphat and Jehoram and Ahaziah, kings of Judah, had dedicated, and his own sacred things, and all the gold found in the treasuries of the house of the LORD and in the king's house, and sent them to Hazael king of Syria. Then he went away from Jerusalem.", 
                "num": 18
              }, 
              {
                "text": "Now the rest of the acts of Joash, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 19
              }, 
              {
                "text": "And his servants arose and formed a conspiracy, and killed Joash in the house of the Millo, which goes down to Silla.", 
                "num": 20
              }, 
              {
                "text": "For Jozachar the son of Shimeath and Jehozabad the son of Shomer, his servants, struck him. So he died, and they buried him with his fathers in the City of David. Then Amaziah his son reigned in his place.", 
                "num": 21
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "In the twenty-third year of Joash the son of Ahaziah, king of Judah, Jehoahaz the son of Jehu became king over Israel in Samaria, and reigned seventeen years.", 
                "num": 1
              }, 
              {
                "text": "And he did evil in the sight of the LORD, and followed the sins of Jeroboam the son of Nebat, who had made Israel sin. He did not depart from them.", 
                "num": 2
              }, 
              {
                "text": "Then the anger of the LORD was aroused against Israel, and He delivered them into the hand of Hazael king of Syria, and into the hand of Ben-Hadad the son of Hazael, all their days.", 
                "num": 3
              }, 
              {
                "text": "So Jehoahaz pleaded with the LORD, and the LORD listened to him; for He saw the oppression of Israel, because the king of Syria oppressed them.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD gave Israel a deliverer, so that they escaped from under the hand of the Syrians; and the children of Israel dwelt in their tents as before.", 
                "num": 5
              }, 
              {
                "text": "Nevertheless they did not depart from the sins of the house of Jeroboam, who had made Israel sin, but walked in them; and the wooden image also remained in Samaria.", 
                "num": 6
              }, 
              {
                "text": "For He left of the army of Jehoahaz only fifty horsemen, ten chariots, and ten thousand foot soldiers; for the king of Syria had destroyed them and made them like the dust at threshing.", 
                "num": 7
              }, 
              {
                "text": "Now the rest of the acts of Jehoahaz, all that he did, and his might, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 8
              }, 
              {
                "text": "So Jehoahaz rested with his fathers, and they buried him in Samaria. Then Joash his son reigned in his place.", 
                "num": 9
              }, 
              {
                "text": "In the thirty-seventh year of Joash king of Judah, Jehoash the son of Jehoahaz became king over Israel in Samaria, and reigned sixteen years.", 
                "num": 10
              }, 
              {
                "text": "And he did evil in the sight of the LORD. He did not depart from all the sins of Jeroboam the son of Nebat, who made Israel sin, but walked in them.", 
                "num": 11
              }, 
              {
                "text": "Now the rest of the acts of Joash, all that he did, and his might with which he fought against Amaziah king of Judah, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 12
              }, 
              {
                "text": "So Joash rested with his fathers. Then Jeroboam sat on his throne. And Joash was buried in Samaria with the kings of Israel.", 
                "num": 13
              }, 
              {
                "text": "Elisha had become sick with the illness of which he would die. Then Joash the king of Israel came down to him, and wept over his face, and said, \"O my father, my father, the chariots of Israel and their horsemen!\"", 
                "num": 14
              }, 
              {
                "text": "And Elisha said to him, \"Take a bow and some arrows.\" So he took himself a bow and some arrows.", 
                "num": 15
              }, 
              {
                "text": "Then he said to the king of Israel, \"Put your hand on the bow.\" So he put his hand on it, and Elisha put his hands on the king's hands.", 
                "num": 16
              }, 
              {
                "text": "And he said, \"Open the east window\"; and he opened it. Then Elisha said, \"Shoot\"; and he shot. And he said, \"The arrow of the LORD's deliverance and the arrow of deliverance from Syria; for you must strike the Syrians at Aphek till you have destroyed them.\"", 
                "num": 17
              }, 
              {
                "text": "Then he said, \"Take the arrows\"; so he took them. And he said to the king of Israel, \"Strike the ground\"; so he struck three times, and stopped.", 
                "num": 18
              }, 
              {
                "text": "And the man of God was angry with him, and said, \"You should have struck five or six times; then you would have struck Syria till you had destroyed it! But now you will strike Syria only three times.\"", 
                "num": 19
              }, 
              {
                "text": "Then Elisha died, and they buried him. And the raiding bands from Moab invaded the land in the spring of the year.", 
                "num": 20
              }, 
              {
                "text": "So it was, as they were burying a man, that suddenly they spied a band of raiders; and they put the man in the tomb of Elisha; and when the man was let down and touched the bones of Elisha, he revived and stood on his feet.", 
                "num": 21
              }, 
              {
                "text": "And Hazael king of Syria oppressed Israel all the days of Jehoahaz.", 
                "num": 22
              }, 
              {
                "text": "But the LORD was gracious to them, had compassion on them, and regarded them, because of His covenant with Abraham, Isaac, and Jacob, and would not yet destroy them or cast them from His presence.", 
                "num": 23
              }, 
              {
                "text": "Now Hazael king of Syria died. Then Ben-Hadad his son reigned in his place.", 
                "num": 24
              }, 
              {
                "text": "And Jehoash the son of Jehoahaz recaptured from the hand of Ben-Hadad, the son of Hazael, the cities which he had taken out of the hand of Jehoahaz his father by war. Three times Joash defeated him and recaptured the cities of Israel.", 
                "num": 25
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "In the second year of Joash the son of Jehoahaz, king of Israel, Amaziah the son of Joash, king of Judah, became king.", 
                "num": 1
              }, 
              {
                "text": "He was twenty-five years old when he became king, and he reigned twenty-nine years in Jerusalem. His mother's name was Jehoaddan of Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, yet not like his father David; he did everything as his father Joash had done.", 
                "num": 3
              }, 
              {
                "text": "However the high places were not taken away, and the people still sacrificed and burned incense on the high places.", 
                "num": 4
              }, 
              {
                "text": "Now it happened, as soon as the kingdom was established in his hand, that he executed his servants who had murdered his father the king.", 
                "num": 5
              }, 
              {
                "text": "But the children of the murderers he did not execute, according to what is written in the Book of the Law of Moses, in which the LORD commanded, saying, \"Fathers shall not be put to death for their children, nor shall children be put to death for their fathers; but a person shall be put to death for his own sin.\"", 
                "num": 6
              }, 
              {
                "text": "He killed ten thousand Edomites in the Valley of Salt, and took Sela by war, and called its name Joktheel to this day.", 
                "num": 7
              }, 
              {
                "text": "Then Amaziah sent messengers to Jehoash the son of Jehoahaz, the son of Jehu, king of Israel, saying, \"Come, let us face one another in battle.\"", 
                "num": 8
              }, 
              {
                "text": "And Jehoash king of Israel sent to Amaziah king of Judah, saying, \"The thistle that was in Lebanon sent to the cedar that was in Lebanon, saying, \"Give your daughter to my son as wife'; and a wild beast that was in Lebanon passed by and trampled the thistle.", 
                "num": 9
              }, 
              {
                "text": "You have indeed defeated Edom, and your heart has lifted you up. Glory in that, and stay at home; for why should you meddle with trouble so that you fall--you and Judah with you?\"", 
                "num": 10
              }, 
              {
                "text": "But Amaziah would not heed. Therefore Jehoash king of Israel went out; so he and Amaziah king of Judah faced one another at Beth Shemesh, which belongs to Judah.", 
                "num": 11
              }, 
              {
                "text": "And Judah was defeated by Israel, and every man fled to his tent.", 
                "num": 12
              }, 
              {
                "text": "Then Jehoash king of Israel captured Amaziah king of Judah, the son of Jehoash, the son of Ahaziah, at Beth Shemesh; and he went to Jerusalem, and broke down the wall of Jerusalem from the Gate of Ephraim to the Corner Gate--four hundred cubits.", 
                "num": 13
              }, 
              {
                "text": "And he took all the gold and silver, all the articles that were found in the house of the LORD and in the treasuries of the king's house, and hostages, and returned to Samaria.", 
                "num": 14
              }, 
              {
                "text": "Now the rest of the acts of Jehoash which he did--his might, and how he fought with Amaziah king of Judah--are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 15
              }, 
              {
                "text": "So Jehoash rested with his fathers, and was buried in Samaria with the kings of Israel. Then Jeroboam his son reigned in his place.", 
                "num": 16
              }, 
              {
                "text": "Amaziah the son of Joash, king of Judah, lived fifteen years after the death of Jehoash the son of Jehoahaz, king of Israel.", 
                "num": 17
              }, 
              {
                "text": "Now the rest of the acts of Amaziah, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 18
              }, 
              {
                "text": "And they formed a conspiracy against him in Jerusalem, and he fled to Lachish; but they sent after him to Lachish and killed him there.", 
                "num": 19
              }, 
              {
                "text": "Then they brought him on horses, and he was buried at Jerusalem with his fathers in the City of David.", 
                "num": 20
              }, 
              {
                "text": "And all the people of Judah took Azariah, who was sixteen years old, and made him king instead of his father Amaziah.", 
                "num": 21
              }, 
              {
                "text": "He built Elath and restored it to Judah, after the king rested with his fathers.", 
                "num": 22
              }, 
              {
                "text": "In the fifteenth year of Amaziah the son of Joash, king of Judah, Jeroboam the son of Joash, king of Israel, became king in Samaria, and reigned forty-one years.", 
                "num": 23
              }, 
              {
                "text": "And he did evil in the sight of the LORD; he did not depart from all the sins of Jeroboam the son of Nebat, who had made Israel sin.", 
                "num": 24
              }, 
              {
                "text": "He restored the territory of Israel from the entrance of Hamath to the Sea of the Arabah, according to the word of the LORD God of Israel, which He had spoken through His servant Jonah the son of Amittai, the prophet who was from Gath Hepher.", 
                "num": 25
              }, 
              {
                "text": "For the LORD saw that the affliction of Israel was very bitter; and whether bond or free, there was no helper for Israel.", 
                "num": 26
              }, 
              {
                "text": "And the LORD did not say that He would blot out the name of Israel from under heaven; but He saved them by the hand of Jeroboam the son of Joash.", 
                "num": 27
              }, 
              {
                "text": "Now the rest of the acts of Jeroboam, and all that he did--his might, how he made war, and how he recaptured for Israel, from Damascus and Hamath, what had belonged to Judah--are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 28
              }, 
              {
                "text": "So Jeroboam rested with his fathers, the kings of Israel. Then Zechariah his son reigned in his place.", 
                "num": 29
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "In the twenty-seventh year of Jeroboam king of Israel, Azariah the son of Amaziah, king of Judah, became king.", 
                "num": 1
              }, 
              {
                "text": "He was sixteen years old when he became king, and he reigned fifty-two years in Jerusalem. His mother's name was Jecholiah of Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, according to all that his father Amaziah had done,", 
                "num": 3
              }, 
              {
                "text": "except that the high places were not removed; the people still sacrificed and burned incense on the high places.", 
                "num": 4
              }, 
              {
                "text": "Then the LORD struck the king, so that he was a leper until the day of his death; so he dwelt in an isolated house. And Jotham the king's son was over the royal house, judging the people of the land.", 
                "num": 5
              }, 
              {
                "text": "Now the rest of the acts of Azariah, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 6
              }, 
              {
                "text": "So Azariah rested with his fathers, and they buried him with his fathers in the City of David. Then Jotham his son reigned in his place.", 
                "num": 7
              }, 
              {
                "text": "In the thirty-eighth year of Azariah king of Judah, Zechariah the son of Jeroboam reigned over Israel in Samaria six months.", 
                "num": 8
              }, 
              {
                "text": "And he did evil in the sight of the LORD, as his fathers had done; he did not depart from the sins of Jeroboam the son of Nebat, who had made Israel sin.", 
                "num": 9
              }, 
              {
                "text": "Then Shallum the son of Jabesh conspired against him, and struck and killed him in front of the people; and he reigned in his place.", 
                "num": 10
              }, 
              {
                "text": "Now the rest of the acts of Zechariah, indeed they are written in the book of the chronicles of the kings of Israel.", 
                "num": 11
              }, 
              {
                "text": "This was the word of the LORD which He spoke to Jehu, saying, \"Your sons shall sit on the throne of Israel to the fourth generation.\" And so it was.", 
                "num": 12
              }, 
              {
                "text": "Shallum the son of Jabesh became king in the thirty-ninth year of Uzziah king of Judah; and he reigned a full month in Samaria.", 
                "num": 13
              }, 
              {
                "text": "For Menahem the son of Gadi went up from Tirzah, came to Samaria, and struck Shallum the son of Jabesh in Samaria and killed him; and he reigned in his place.", 
                "num": 14
              }, 
              {
                "text": "Now the rest of the acts of Shallum, and the conspiracy which he led, indeed they are written in the book of the chronicles of the kings of Israel.", 
                "num": 15
              }, 
              {
                "text": "Then from Tirzah, Menahem attacked Tiphsah, all who were there, and its territory. Because they did not surrender, therefore he attacked it. All the women there who were with child he ripped open.", 
                "num": 16
              }, 
              {
                "text": "In the thirty-ninth year of Azariah king of Judah, Menahem the son of Gadi became king over Israel, and reigned ten years in Samaria.", 
                "num": 17
              }, 
              {
                "text": "And he did evil in the sight of the LORD; he did not depart all his days from the sins of Jeroboam the son of Nebat, who had made Israel sin.", 
                "num": 18
              }, 
              {
                "text": "Pul king of Assyria came against the land; and Menahem gave Pul a thousand talents of silver, that his hand might be with him to strengthen the kingdom under his control.", 
                "num": 19
              }, 
              {
                "text": "And Menahem exacted the money from Israel, from all the very wealthy, from each man fifty shekels of silver, to give to the king of Assyria. So the king of Assyria turned back, and did not stay there in the land.", 
                "num": 20
              }, 
              {
                "text": "Now the rest of the acts of Menahem, and all that he did, are they not written in the book of the chronicles of the kings of Israel?", 
                "num": 21
              }, 
              {
                "text": "So Menahem rested with his fathers. Then Pekahiah his son reigned in his place.", 
                "num": 22
              }, 
              {
                "text": "In the fiftieth year of Azariah king of Judah, Pekahiah the son of Menahem became king over Israel in Samaria, and reigned two years.", 
                "num": 23
              }, 
              {
                "text": "And he did evil in the sight of the LORD; he did not depart from the sins of Jeroboam the son of Nebat, who had made Israel sin.", 
                "num": 24
              }, 
              {
                "text": "Then Pekah the son of Remaliah, an officer of his, conspired against him and killed him in Samaria, in the citadel of the king's house, along with Argob and Arieh; and with him were fifty men of Gilead. He killed him and reigned in his place.", 
                "num": 25
              }, 
              {
                "text": "Now the rest of the acts of Pekahiah, and all that he did, indeed they are written in the book of the chronicles of the kings of Israel.", 
                "num": 26
              }, 
              {
                "text": "In the fifty-second year of Azariah king of Judah, Pekah the son of Remaliah became king over Israel in Samaria, and reigned twenty years.", 
                "num": 27
              }, 
              {
                "text": "And he did evil in the sight of the LORD; he did not depart from the sins of Jeroboam the son of Nebat, who had made Israel sin.", 
                "num": 28
              }, 
              {
                "text": "In the days of Pekah king of Israel, Tiglath-Pileser king of Assyria came and took Ijon, Abel Beth Maachah, Janoah, Kedesh, Hazor, Gilead, and Galilee, all the land of Naphtali; and he carried them captive to Assyria.", 
                "num": 29
              }, 
              {
                "text": "Then Hoshea the son of Elah led a conspiracy against Pekah the son of Remaliah, and struck and killed him; so he reigned in his place in the twentieth year of Jotham the son of Uzziah.", 
                "num": 30
              }, 
              {
                "text": "Now the rest of the acts of Pekah, and all that he did, indeed they are written in the book of the chronicles of the kings of Israel.", 
                "num": 31
              }, 
              {
                "text": "In the second year of Pekah the son of Remaliah, king of Israel, Jotham the son of Uzziah, king of Judah, began to reign.", 
                "num": 32
              }, 
              {
                "text": "He was twenty-five years old when he became king, and he reigned sixteen years in Jerusalem. His mother's name was Jerusha the daughter of Zadok.", 
                "num": 33
              }, 
              {
                "text": "And he did what was right in the sight of the LORD; he did according to all that his father Uzziah had done.", 
                "num": 34
              }, 
              {
                "text": "However the high places were not removed; the people still sacrificed and burned incense on the high places. He built the Upper Gate of the house of the LORD.", 
                "num": 35
              }, 
              {
                "text": "Now the rest of the acts of Jotham, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 36
              }, 
              {
                "text": "In those days the LORD began to send Rezin king of Syria and Pekah the son of Remaliah against Judah.", 
                "num": 37
              }, 
              {
                "text": "So Jotham rested with his fathers, and was buried with his fathers in the City of David his father. Then Ahaz his son reigned in his place.", 
                "num": 38
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "In the seventeenth year of Pekah the son of Remaliah, Ahaz the son of Jotham, king of Judah, began to reign.", 
                "num": 1
              }, 
              {
                "text": "Ahaz was twenty years old when he became king, and he reigned sixteen years in Jerusalem; and he did not do what was right in the sight of the LORD his God, as his father David had done.", 
                "num": 2
              }, 
              {
                "text": "But he walked in the way of the kings of Israel; indeed he made his son pass through the fire, according to the abominations of the nations whom the LORD had cast out from before the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "And he sacrificed and burned incense on the high places, on the hills, and under every green tree.", 
                "num": 4
              }, 
              {
                "text": "Then Rezin king of Syria and Pekah the son of Remaliah, king of Israel, came up to Jerusalem to make war; and they besieged Ahaz but could not overcome him.", 
                "num": 5
              }, 
              {
                "text": "At that time Rezin king of Syria captured Elath for Syria, and drove the men of Judah from Elath. Then the Edomites went to Elath, and dwell there to this day.", 
                "num": 6
              }, 
              {
                "text": "So Ahaz sent messengers to Tiglath-Pileser king of Assyria, saying, \"I am your servant and your son. Come up and save me from the hand of the king of Syria and from the hand of the king of Israel, who rise up against me.\"", 
                "num": 7
              }, 
              {
                "text": "And Ahaz took the silver and gold that was found in the house of the LORD, and in the treasuries of the king's house, and sent it as a present to the king of Assyria.", 
                "num": 8
              }, 
              {
                "text": "So the king of Assyria heeded him; for the king of Assyria went up against Damascus and took it, carried its people captive to Kir, and killed Rezin.", 
                "num": 9
              }, 
              {
                "text": "Now King Ahaz went to Damascus to meet Tiglath-Pileser king of Assyria, and saw an altar that was at Damascus; and King Ahaz sent to Urijah the priest the design of the altar and its pattern, according to all its workmanship.", 
                "num": 10
              }, 
              {
                "text": "Then Urijah the priest built an altar according to all that King Ahaz had sent from Damascus. So Urijah the priest made it before King Ahaz came back from Damascus.", 
                "num": 11
              }, 
              {
                "text": "And when the king came back from Damascus, the king saw the altar; and the king approached the altar and made offerings on it.", 
                "num": 12
              }, 
              {
                "text": "So he burned his burnt offering and his grain offering; and he poured his drink offering and sprinkled the blood of his peace offerings on the altar.", 
                "num": 13
              }, 
              {
                "text": "He also brought the bronze altar which was before the LORD, from the front of the temple--from between the new altar and the house of the LORD--and put it on the north side of the new altar.", 
                "num": 14
              }, 
              {
                "text": "Then King Ahaz commanded Urijah the priest, saying, \"On the great new altar burn the morning burnt offering, the evening grain offering, the king's burnt sacrifice, and his grain offering, with the burnt offering of all the people of the land, their grain offering, and their drink offerings; and sprinkle on it all the blood of the burnt offering and all the blood of the sacrifice. And the bronze altar shall be for me to inquire by.\"", 
                "num": 15
              }, 
              {
                "text": "Thus did Urijah the priest, according to all that King Ahaz commanded.", 
                "num": 16
              }, 
              {
                "text": "And King Ahaz cut off the panels of the carts, and removed the lavers from them; and he took down the Sea from the bronze oxen that were under it, and put it on a pavement of stones.", 
                "num": 17
              }, 
              {
                "text": "Also he removed the Sabbath pavilion which they had built in the temple, and he removed the king's outer entrance from the house of the LORD, on account of the king of Assyria.", 
                "num": 18
              }, 
              {
                "text": "Now the rest of the acts of Ahaz which he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 19
              }, 
              {
                "text": "So Ahaz rested with his fathers, and was buried with his fathers in the City of David. Then Hezekiah his son reigned in his place.", 
                "num": 20
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "In the twelfth year of Ahaz king of Judah, Hoshea the son of Elah became king of Israel in Samaria, and he reigned nine years.", 
                "num": 1
              }, 
              {
                "text": "And he did evil in the sight of the LORD, but not as the kings of Israel who were before him.", 
                "num": 2
              }, 
              {
                "text": "Shalmaneser king of Assyria came up against him; and Hoshea became his vassal, and paid him tribute money.", 
                "num": 3
              }, 
              {
                "text": "And the king of Assyria uncovered a conspiracy by Hoshea; for he had sent messengers to So, king of Egypt, and brought no tribute to the king of Assyria, as he had done year by year. Therefore the king of Assyria shut him up, and bound him in prison.", 
                "num": 4
              }, 
              {
                "text": "Now the king of Assyria went throughout all the land, and went up to Samaria and besieged it for three years.", 
                "num": 5
              }, 
              {
                "text": "In the ninth year of Hoshea, the king of Assyria took Samaria and carried Israel away to Assyria, and placed them in Halah and by the Habor, the River of Gozan, and in the cities of the Medes.", 
                "num": 6
              }, 
              {
                "text": "For so it was that the children of Israel had sinned against the LORD their God, who had brought them up out of the land of Egypt, from under the hand of Pharaoh king of Egypt; and they had feared other gods,", 
                "num": 7
              }, 
              {
                "text": "and had walked in the statutes of the nations whom the LORD had cast out from before the children of Israel, and of the kings of Israel, which they had made.", 
                "num": 8
              }, 
              {
                "text": "Also the children of Israel secretly did against the LORD their God things that were not right, and they built for themselves high places in all their cities, from watchtower to fortified city.", 
                "num": 9
              }, 
              {
                "text": "They set up for themselves sacred pillars and wooden images on every high hill and under every green tree.", 
                "num": 10
              }, 
              {
                "text": "There they burned incense on all the high places, like the nations whom the LORD had carried away before them; and they did wicked things to provoke the LORD to anger,", 
                "num": 11
              }, 
              {
                "text": "for they served idols, of which the LORD had said to them, \"You shall not do this thing.\"", 
                "num": 12
              }, 
              {
                "text": "Yet the LORD testified against Israel and against Judah, by all of His prophets, every seer, saying, \"Turn from your evil ways, and keep My commandments and My statutes, according to all the law which I commanded your fathers, and which I sent to you by My servants the prophets.\"", 
                "num": 13
              }, 
              {
                "text": "Nevertheless they would not hear, but stiffened their necks, like the necks of their fathers, who did not believe in the LORD their God.", 
                "num": 14
              }, 
              {
                "text": "And they rejected His statutes and His covenant that He had made with their fathers, and His testimonies which He had testified against them; they followed idols, became idolaters, and went after the nations who were all around them, concerning whom the LORD had charged them that they should not do like them.", 
                "num": 15
              }, 
              {
                "text": "So they left all the commandments of the LORD their God, made for themselves a molded image and two calves, made a wooden image and worshiped all the host of heaven, and served Baal.", 
                "num": 16
              }, 
              {
                "text": "And they caused their sons and daughters to pass through the fire, practiced witchcraft and soothsaying, and sold themselves to do evil in the sight of the LORD, to provoke Him to anger.", 
                "num": 17
              }, 
              {
                "text": "Therefore the LORD was very angry with Israel, and removed them from His sight; there was none left but the tribe of Judah alone.", 
                "num": 18
              }, 
              {
                "text": "Also Judah did not keep the commandments of the LORD their God, but walked in the statutes of Israel which they made.", 
                "num": 19
              }, 
              {
                "text": "And the LORD rejected all the descendants of Israel, afflicted them, and delivered them into the hand of plunderers, until He had cast them from His sight.", 
                "num": 20
              }, 
              {
                "text": "For He tore Israel from the house of David, and they made Jeroboam the son of Nebat king. Then Jeroboam drove Israel from following the LORD, and made them commit a great sin.", 
                "num": 21
              }, 
              {
                "text": "For the children of Israel walked in all the sins of Jeroboam which he did; they did not depart from them,", 
                "num": 22
              }, 
              {
                "text": "until the LORD removed Israel out of His sight, as He had said by all His servants the prophets. So Israel was carried away from their own land to Assyria, as it is to this day.", 
                "num": 23
              }, 
              {
                "text": "Then the king of Assyria brought people from Babylon, Cuthah, Ava, Hamath, and from Sepharvaim, and placed them in the cities of Samaria instead of the children of Israel; and they took possession of Samaria and dwelt in its cities.", 
                "num": 24
              }, 
              {
                "text": "And it was so, at the beginning of their dwelling there, that they did not fear the LORD; therefore the LORD sent lions among them, which killed some of them.", 
                "num": 25
              }, 
              {
                "text": "So they spoke to the king of Assyria, saying, \"The nations whom you have removed and placed in the cities of Samaria do not know the rituals of the God of the land; therefore He has sent lions among them, and indeed, they are killing them because they do not know the rituals of the God of the land.\"", 
                "num": 26
              }, 
              {
                "text": "Then the king of Assyria commanded, saying, \"Send there one of the priests whom you brought from there; let him go and dwell there, and let him teach them the rituals of the God of the land.\"", 
                "num": 27
              }, 
              {
                "text": "Then one of the priests whom they had carried away from Samaria came and dwelt in Bethel, and taught them how they should fear the LORD.", 
                "num": 28
              }, 
              {
                "text": "However every nation continued to make gods of its own, and put them in the shrines on the high places which the Samaritans had made, every nation in the cities where they dwelt.", 
                "num": 29
              }, 
              {
                "text": "The men of Babylon made Succoth Benoth, the men of Cuth made Nergal, the men of Hamath made Ashima,", 
                "num": 30
              }, 
              {
                "text": "and the Avites made Nibhaz and Tartak; and the Sepharvites burned their children in fire to Adrammelech and Anammelech, the gods of Sepharvaim.", 
                "num": 31
              }, 
              {
                "text": "So they feared the LORD, and from every class they appointed for themselves priests of the high places, who sacrificed for them in the shrines of the high places.", 
                "num": 32
              }, 
              {
                "text": "They feared the LORD, yet served their own gods--according to the rituals of the nations from among whom they were carried away.", 
                "num": 33
              }, 
              {
                "text": "To this day they continue practicing the former rituals; they do not fear the LORD, nor do they follow their statutes or their ordinances, or the law and commandment which the LORD had commanded the children of Jacob, whom He named Israel,", 
                "num": 34
              }, 
              {
                "text": "with whom the LORD had made a covenant and charged them, saying: \"You shall not fear other gods, nor bow down to them nor serve them nor sacrifice to them;", 
                "num": 35
              }, 
              {
                "text": "but the LORD, who brought you up from the land of Egypt with great power and an outstretched arm, Him you shall fear, Him you shall worship, and to Him you shall offer sacrifice.", 
                "num": 36
              }, 
              {
                "text": "And the statutes, the ordinances, the law, and the commandment which He wrote for you, you shall be careful to observe forever; you shall not fear other gods.", 
                "num": 37
              }, 
              {
                "text": "And the covenant that I have made with you, you shall not forget, nor shall you fear other gods.", 
                "num": 38
              }, 
              {
                "text": "But the LORD your God you shall fear; and He will deliver you from the hand of all your enemies.\"", 
                "num": 39
              }, 
              {
                "text": "However they did not obey, but they followed their former rituals.", 
                "num": 40
              }, 
              {
                "text": "So these nations feared the LORD, yet served their carved images; also their children and their children's children have continued doing as their fathers did, even to this day.", 
                "num": 41
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the third year of Hoshea the son of Elah, king of Israel, that Hezekiah the son of Ahaz, king of Judah, began to reign.", 
                "num": 1
              }, 
              {
                "text": "He was twenty-five years old when he became king, and he reigned twenty-nine years in Jerusalem. His mother's name was Abi the daughter of Zechariah.", 
                "num": 2
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, according to all that his father David had done.", 
                "num": 3
              }, 
              {
                "text": "He removed the high places and broke the sacred pillars, cut down the wooden image and broke in pieces the bronze serpent that Moses had made; for until those days the children of Israel burned incense to it, and called it Nehushtan.", 
                "num": 4
              }, 
              {
                "text": "He trusted in the LORD God of Israel, so that after him was none like him among all the kings of Judah, nor who were before him.", 
                "num": 5
              }, 
              {
                "text": "For he held fast to the LORD; he did not depart from following Him, but kept His commandments, which the LORD had commanded Moses.", 
                "num": 6
              }, 
              {
                "text": "The LORD was with him; he prospered wherever he went. And he rebelled against the king of Assyria and did not serve him.", 
                "num": 7
              }, 
              {
                "text": "He subdued the Philistines, as far as Gaza and its territory, from watchtower to fortified city.", 
                "num": 8
              }, 
              {
                "text": "Now it came to pass in the fourth year of King Hezekiah, which was the seventh year of Hoshea the son of Elah, king of Israel, that Shalmaneser king of Assyria came up against Samaria and besieged it.", 
                "num": 9
              }, 
              {
                "text": "And at the end of three years they took it. In the sixth year of Hezekiah, that is, the ninth year of Hoshea king of Israel, Samaria was taken.", 
                "num": 10
              }, 
              {
                "text": "Then the king of Assyria carried Israel away captive to Assyria, and put them in Halah and by the Habor, the River of Gozan, and in the cities of the Medes,", 
                "num": 11
              }, 
              {
                "text": "because they did not obey the voice of the LORD their God, but transgressed His covenant and all that Moses the servant of the LORD had commanded; and they would neither hear nor do them.", 
                "num": 12
              }, 
              {
                "text": "And in the fourteenth year of King Hezekiah, Sennacherib king of Assyria came up against all the fortified cities of Judah and took them.", 
                "num": 13
              }, 
              {
                "text": "Then Hezekiah king of Judah sent to the king of Assyria at Lachish, saying, \"I have done wrong; turn away from me; whatever you impose on me I will pay.\" And the king of Assyria assessed Hezekiah king of Judah three hundred talents of silver and thirty talents of gold.", 
                "num": 14
              }, 
              {
                "text": "So Hezekiah gave him all the silver that was found in the house of the LORD and in the treasuries of the king's house.", 
                "num": 15
              }, 
              {
                "text": "At that time Hezekiah stripped the gold from the doors of the temple of the LORD, and from the pillars which Hezekiah king of Judah had overlaid, and gave it to the king of Assyria.", 
                "num": 16
              }, 
              {
                "text": "Then the king of Assyria sent the Tartan, the Rabsaris, and the Rabshakeh from Lachish, with a great army against Jerusalem, to King Hezekiah. And they went up and came to Jerusalem. When they had come up, they went and stood by the aqueduct from the upper pool, which was on the highway to the Fuller's Field.", 
                "num": 17
              }, 
              {
                "text": "And when they had called to the king, Eliakim the son of Hilkiah, who was over the household, Shebna the scribe, and Joah the son of Asaph, the recorder, came out to them.", 
                "num": 18
              }, 
              {
                "text": "Then the Rabshakeh said to them, \"Say now to Hezekiah, \"Thus says the great king, the king of Assyria: \"What confidence is this in which you trust?", 
                "num": 19
              }, 
              {
                "text": "You speak of having plans and power for war; but they are mere words. And in whom do you trust, that you rebel against me?", 
                "num": 20
              }, 
              {
                "text": "Now look! You are trusting in the staff of this broken reed, Egypt, on which if a man leans, it will go into his hand and pierce it. So is Pharaoh king of Egypt to all who trust in him.", 
                "num": 21
              }, 
              {
                "text": "But if you say to me, \"We trust in the LORD our God,' is it not He whose high places and whose altars Hezekiah has taken away, and said to Judah and Jerusalem, \"You shall worship before this altar in Jerusalem'?\"'", 
                "num": 22
              }, 
              {
                "text": "Now therefore, I urge you, give a pledge to my master the king of Assyria, and I will give you two thousand horses--if you are able on your part to put riders on them!", 
                "num": 23
              }, 
              {
                "text": "How then will you repel one captain of the least of my master's servants, and put your trust in Egypt for chariots and horsemen?", 
                "num": 24
              }, 
              {
                "text": "Have I now come up without the LORD against this place to destroy it? The LORD said to me, \"Go up against this land, and destroy it.\"'", 
                "num": 25
              }, 
              {
                "text": "Then Eliakim the son of Hilkiah, Shebna, and Joah said to the Rabshakeh, \"Please speak to your servants in Aramaic, for we understand it; and do not speak to us in Hebrew in the hearing of the people who are on the wall.\"", 
                "num": 26
              }, 
              {
                "text": "But the Rabshakeh said to them, \"Has my master sent me to your master and to you to speak these words, and not to the men who sit on the wall, who will eat and drink their own waste with you?\"", 
                "num": 27
              }, 
              {
                "text": "Then the Rabshakeh stood and called out with a loud voice in Hebrew, and spoke, saying, \"Hear the word of the great king, the king of Assyria!", 
                "num": 28
              }, 
              {
                "text": "Thus says the king: \"Do not let Hezekiah deceive you, for he shall not be able to deliver you from his hand;", 
                "num": 29
              }, 
              {
                "text": "nor let Hezekiah make you trust in the LORD, saying, \"The LORD will surely deliver us; this city shall not be given into the hand of the king of Assyria.\"'", 
                "num": 30
              }, 
              {
                "text": "Do not listen to Hezekiah; for thus says the king of Assyria: \"Make peace with me by a present and come out to me; and every one of you eat from his own vine and every one from his own fig tree, and every one of you drink the waters of his own cistern;", 
                "num": 31
              }, 
              {
                "text": "until I come and take you away to a land like your own land, a land of grain and new wine, a land of bread and vineyards, a land of olive groves and honey, that you may live and not die. But do not listen to Hezekiah, lest he persuade you, saying, \"The LORD will deliver us.\"", 
                "num": 32
              }, 
              {
                "text": "Has any of the gods of the nations at all delivered its land from the hand of the king of Assyria?", 
                "num": 33
              }, 
              {
                "text": "Where are the gods of Hamath and Arpad? Where are the gods of Sepharvaim and Hena and Ivah? Indeed, have they delivered Samaria from my hand?", 
                "num": 34
              }, 
              {
                "text": "Who among all the gods of the lands have delivered their countries from my hand, that the LORD should deliver Jerusalem from my hand?\"'", 
                "num": 35
              }, 
              {
                "text": "But the people held their peace and answered him not a word; for the king's commandment was, \"Do not answer him.\"", 
                "num": 36
              }, 
              {
                "text": "Then Eliakim the son of Hilkiah, who was over the household, Shebna the scribe, and Joah the son of Asaph, the recorder, came to Hezekiah with their clothes torn, and told him the words of the Rabshakeh.", 
                "num": 37
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And so it was, when King Hezekiah heard it, that he tore his clothes, covered himself with sackcloth, and went into the house of the LORD.", 
                "num": 1
              }, 
              {
                "text": "Then he sent Eliakim, who was over the household, Shebna the scribe, and the elders of the priests, covered with sackcloth, to Isaiah the prophet, the son of Amoz.", 
                "num": 2
              }, 
              {
                "text": "And they said to him, \"Thus says Hezekiah: \"This day is a day of trouble, and rebuke, and blasphemy; for the children have come to birth, but there is no strength to bring them forth.", 
                "num": 3
              }, 
              {
                "text": "It may be that the LORD your God will hear all the words of the Rabshakeh, whom his master the king of Assyria has sent to reproach the living God, and will rebuke the words which the LORD your God has heard. Therefore lift up your prayer for the remnant that is left.\"'", 
                "num": 4
              }, 
              {
                "text": "So the servants of King Hezekiah came to Isaiah.", 
                "num": 5
              }, 
              {
                "text": "And Isaiah said to them, \"Thus you shall say to your master, \"Thus says the LORD: \"Do not be afraid of the words which you have heard, with which the servants of the king of Assyria have blasphemed Me.", 
                "num": 6
              }, 
              {
                "text": "Surely I will send a spirit upon him, and he shall hear a rumor and return to his own land; and I will cause him to fall by the sword in his own land.\"\"'", 
                "num": 7
              }, 
              {
                "text": "Then the Rabshakeh returned and found the king of Assyria warring against Libnah, for he heard that he had departed from Lachish.", 
                "num": 8
              }, 
              {
                "text": "And the king heard concerning Tirhakah king of Ethiopia, \"Look, he has come out to make war with you.\" So he again sent messengers to Hezekiah, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Thus you shall speak to Hezekiah king of Judah, saying: \"Do not let your God in whom you trust deceive you, saying, \"Jerusalem shall not be given into the hand of the king of Assyria.\"", 
                "num": 10
              }, 
              {
                "text": "Look! You have heard what the kings of Assyria have done to all lands by utterly destroying them; and shall you be delivered?", 
                "num": 11
              }, 
              {
                "text": "Have the gods of the nations delivered those whom my fathers have destroyed, Gozan and Haran and Rezeph, and the people of Eden who were in Telassar?", 
                "num": 12
              }, 
              {
                "text": "Where is the king of Hamath, the king of Arpad, and the king of the city of Sepharvaim, Hena, and Ivah?\"'", 
                "num": 13
              }, 
              {
                "text": "And Hezekiah received the letter from the hand of the messengers, and read it; and Hezekiah went up to the house of the LORD, and spread it before the LORD.", 
                "num": 14
              }, 
              {
                "text": "Then Hezekiah prayed before the LORD, and said: \"O LORD God of Israel, the One who dwells between the cherubim, You are God, You alone, of all the kingdoms of the earth. You have made heaven and earth.", 
                "num": 15
              }, 
              {
                "text": "Incline Your ear, O LORD, and hear; open Your eyes, O LORD, and see; and hear the words of Sennacherib, which he has sent to reproach the living God.", 
                "num": 16
              }, 
              {
                "text": "Truly, LORD, the kings of Assyria have laid waste the nations and their lands,", 
                "num": 17
              }, 
              {
                "text": "and have cast their gods into the fire; for they were not gods, but the work of men's hands--wood and stone. Therefore they destroyed them.", 
                "num": 18
              }, 
              {
                "text": "Now therefore, O LORD our God, I pray, save us from his hand, that all the kingdoms of the earth may know that You are the LORD God, You alone.\"", 
                "num": 19
              }, 
              {
                "text": "Then Isaiah the son of Amoz sent to Hezekiah, saying, \"Thus says the LORD God of Israel: \"Because you have prayed to Me against Sennacherib king of Assyria, I have heard.'", 
                "num": 20
              }, 
              {
                "text": "This is the word which the LORD has spoken concerning him: \"The virgin, the daughter of Zion, Has despised you, laughed you to scorn; The daughter of Jerusalem Has shaken her head behind your back!", 
                "num": 21
              }, 
              {
                "text": "\"Whom have you reproached and blasphemed? Against whom have you raised your voice, And lifted up your eyes on high? Against the Holy One of Israel.", 
                "num": 22
              }, 
              {
                "text": "By your messengers you have reproached the Lord, And said: \"By the multitude of my chariots I have come up to the height of the mountains, To the limits of Lebanon; I will cut down its tall cedars And its choice cypress trees; I will enter the extremity of its borders, To its fruitful forest.", 
                "num": 23
              }, 
              {
                "text": "I have dug and drunk strange water, And with the soles of my feet I have dried up All the brooks of defense.\"", 
                "num": 24
              }, 
              {
                "text": "\"Did you not hear long ago How I made it, From ancient times that I formed it? Now I have brought it to pass, That you should be For crushing fortified cities into heaps of ruins.", 
                "num": 25
              }, 
              {
                "text": "Therefore their inhabitants had little power; They were dismayed and confounded; They were as the grass of the field And the green herb, As the grass on the housetops And grain blighted before it is grown.", 
                "num": 26
              }, 
              {
                "text": "\"But I know your dwelling place, Your going out and your coming in, And your rage against Me.", 
                "num": 27
              }, 
              {
                "text": "Because your rage against Me and your tumult Have come up to My ears, Therefore I will put My hook in your nose And My bridle in your lips, And I will turn you back By the way which you came.", 
                "num": 28
              }, 
              {
                "text": "\"This shall be a sign to you: You shall eat this year such as grows of itself, And in the second year what springs from the same; Also in the third year sow and reap, Plant vineyards and eat the fruit of them.", 
                "num": 29
              }, 
              {
                "text": "And the remnant who have escaped of the house of Judah Shall again take root downward, And bear fruit upward.", 
                "num": 30
              }, 
              {
                "text": "For out of Jerusalem shall go a remnant, And those who escape from Mount Zion. The zeal of the LORD of hosts will do this.'", 
                "num": 31
              }, 
              {
                "text": "\"Therefore thus says the LORD concerning the king of Assyria: \"He shall not come into this city, Nor shoot an arrow there, Nor come before it with shield, Nor build a siege mound against it.", 
                "num": 32
              }, 
              {
                "text": "By the way that he came, By the same shall he return; And he shall not come into this city,' Says the LORD.", 
                "num": 33
              }, 
              {
                "text": "\"For I will defend this city, to save it For My own sake and for My servant David's sake.\"'", 
                "num": 34
              }, 
              {
                "text": "And it came to pass on a certain night that the angel of the LORD went out, and killed in the camp of the Assyrians one hundred and eighty-five thousand; and when people arose early in the morning, there were the corpses--all dead.", 
                "num": 35
              }, 
              {
                "text": "So Sennacherib king of Assyria departed and went away, returned home, and remained at Nineveh.", 
                "num": 36
              }, 
              {
                "text": "Now it came to pass, as he was worshiping in the temple of Nisroch his god, that his sons Adrammelech and Sharezer struck him down with the sword; and they escaped into the land of Ararat. Then Esarhaddon his son reigned in his place.", 
                "num": 37
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "In those days Hezekiah was sick and near death. And Isaiah the prophet, the son of Amoz, went to him and said to him, \"Thus says the LORD: \"Set your house in order, for you shall die, and not live.\"'", 
                "num": 1
              }, 
              {
                "text": "Then he turned his face toward the wall, and prayed to the LORD, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Remember now, O LORD, I pray, how I have walked before You in truth and with a loyal heart, and have done what was good in Your sight.\" And Hezekiah wept bitterly.", 
                "num": 3
              }, 
              {
                "text": "And it happened, before Isaiah had gone out into the middle court, that the word of the LORD came to him, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Return and tell Hezekiah the leader of My people, \"Thus says the LORD, the God of David your father: \"I have heard your prayer, I have seen your tears; surely I will heal you. On the third day you shall go up to the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "And I will add to your days fifteen years. I will deliver you and this city from the hand of the king of Assyria; and I will defend this city for My own sake, and for the sake of My servant David.\"\"'", 
                "num": 6
              }, 
              {
                "text": "Then Isaiah said, \"Take a lump of figs.\" So they took and laid it on the boil, and he recovered.", 
                "num": 7
              }, 
              {
                "text": "And Hezekiah said to Isaiah, \"What is the sign that the LORD will heal me, and that I shall go up to the house of the LORD the third day?\"", 
                "num": 8
              }, 
              {
                "text": "Then Isaiah said, \"This is the sign to you from the LORD, that the LORD will do the thing which He has spoken: shall the shadow go forward ten degrees or go backward ten degrees?\"", 
                "num": 9
              }, 
              {
                "text": "And Hezekiah answered, \"It is an easy thing for the shadow to go down ten degrees; no, but let the shadow go backward ten degrees.\"", 
                "num": 10
              }, 
              {
                "text": "So Isaiah the prophet cried out to the LORD, and He brought the shadow ten degrees backward, by which it had gone down on the sundial of Ahaz.", 
                "num": 11
              }, 
              {
                "text": "At that time Berodach-Baladan the son of Baladan, king of Babylon, sent letters and a present to Hezekiah, for he heard that Hezekiah had been sick.", 
                "num": 12
              }, 
              {
                "text": "And Hezekiah was attentive to them, and showed them all the house of his treasures--the silver and gold, the spices and precious ointment, and all his armory--all that was found among his treasures. There was nothing in his house or in all his dominion that Hezekiah did not show them.", 
                "num": 13
              }, 
              {
                "text": "Then Isaiah the prophet went to King Hezekiah, and said to him, \"What did these men say, and from where did they come to you?\" So Hezekiah said, \"They came from a far country, from Babylon.\"", 
                "num": 14
              }, 
              {
                "text": "And he said, \"What have they seen in your house?\" So Hezekiah answered, \"They have seen all that is in my house; there is nothing among my treasures that I have not shown them.\"", 
                "num": 15
              }, 
              {
                "text": "Then Isaiah said to Hezekiah, \"Hear the word of the LORD:", 
                "num": 16
              }, 
              {
                "text": "\"Behold, the days are coming when all that is in your house, and what your fathers have accumulated until this day, shall be carried to Babylon; nothing shall be left,' says the LORD.", 
                "num": 17
              }, 
              {
                "text": "\"And they shall take away some of your sons who will descend from you, whom you will beget; and they shall be eunuchs in the palace of the king of Babylon.\"'", 
                "num": 18
              }, 
              {
                "text": "So Hezekiah said to Isaiah, \"The word of the LORD which you have spoken is good!\" For he said, \"Will there not be peace and truth at least in my days?\"", 
                "num": 19
              }, 
              {
                "text": "Now the rest of the acts of Hezekiah--all his might, and how he made a pool and a tunnel and brought water into the city--are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 20
              }, 
              {
                "text": "So Hezekiah rested with his fathers. Then Manasseh his son reigned in his place.", 
                "num": 21
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Manasseh was twelve years old when he became king, and he reigned fifty-five years in Jerusalem. His mother's name was Hephzibah.", 
                "num": 1
              }, 
              {
                "text": "And he did evil in the sight of the LORD, according to the abominations of the nations whom the LORD had cast out before the children of Israel.", 
                "num": 2
              }, 
              {
                "text": "For he rebuilt the high places which Hezekiah his father had destroyed; he raised up altars for Baal, and made a wooden image, as Ahab king of Israel had done; and he worshiped all the host of heaven and served them.", 
                "num": 3
              }, 
              {
                "text": "He also built altars in the house of the LORD, of which the LORD had said, \"In Jerusalem I will put My name.\"", 
                "num": 4
              }, 
              {
                "text": "And he built altars for all the host of heaven in the two courts of the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "Also he made his son pass through the fire, practiced soothsaying, used witchcraft, and consulted spiritists and mediums. He did much evil in the sight of the LORD, to provoke Him to anger.", 
                "num": 6
              }, 
              {
                "text": "He even set a carved image of Asherah that he had made, in the house of which the LORD had said to David and to Solomon his son, \"In this house and in Jerusalem, which I have chosen out of all the tribes of Israel, I will put My name forever;", 
                "num": 7
              }, 
              {
                "text": "and I will not make the feet of Israel wander anymore from the land which I gave their fathers--only if they are careful to do according to all that I have commanded them, and according to all the law that My servant Moses commanded them.\"", 
                "num": 8
              }, 
              {
                "text": "But they paid no attention, and Manasseh seduced them to do more evil than the nations whom the LORD had destroyed before the children of Israel.", 
                "num": 9
              }, 
              {
                "text": "And the LORD spoke by His servants the prophets, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Because Manasseh king of Judah has done these abominations (he has acted more wickedly than all the Amorites who were before him, and has also made Judah sin with his idols),", 
                "num": 11
              }, 
              {
                "text": "therefore thus says the LORD God of Israel: \"Behold, I am bringing such calamity upon Jerusalem and Judah, that whoever hears of it, both his ears will tingle.", 
                "num": 12
              }, 
              {
                "text": "And I will stretch over Jerusalem the measuring line of Samaria and the plummet of the house of Ahab; I will wipe Jerusalem as one wipes a dish, wiping it and turning it upside down.", 
                "num": 13
              }, 
              {
                "text": "So I will forsake the remnant of My inheritance and deliver them into the hand of their enemies; and they shall become victims of plunder to all their enemies,", 
                "num": 14
              }, 
              {
                "text": "because they have done evil in My sight, and have provoked Me to anger since the day their fathers came out of Egypt, even to this day.\"'", 
                "num": 15
              }, 
              {
                "text": "Moreover Manasseh shed very much innocent blood, till he had filled Jerusalem from one end to another, besides his sin by which he made Judah sin, in doing evil in the sight of the LORD.", 
                "num": 16
              }, 
              {
                "text": "Now the rest of the acts of Manasseh--all that he did, and the sin that he committed--are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 17
              }, 
              {
                "text": "So Manasseh rested with his fathers, and was buried in the garden of his own house, in the garden of Uzza. Then his son Amon reigned in his place.", 
                "num": 18
              }, 
              {
                "text": "Amon was twenty-two years old when he became king, and he reigned two years in Jerusalem. His mother's name was Meshullemeth the daughter of Haruz of Jotbah.", 
                "num": 19
              }, 
              {
                "text": "And he did evil in the sight of the LORD, as his father Manasseh had done.", 
                "num": 20
              }, 
              {
                "text": "So he walked in all the ways that his father had walked; and he served the idols that his father had served, and worshiped them.", 
                "num": 21
              }, 
              {
                "text": "He forsook the LORD God of his fathers, and did not walk in the way of the LORD.", 
                "num": 22
              }, 
              {
                "text": "Then the servants of Amon conspired against him, and killed the king in his own house.", 
                "num": 23
              }, 
              {
                "text": "But the people of the land executed all those who had conspired against King Amon. Then the people of the land made his son Josiah king in his place.", 
                "num": 24
              }, 
              {
                "text": "Now the rest of the acts of Amon which he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 25
              }, 
              {
                "text": "And he was buried in his tomb in the garden of Uzza. Then Josiah his son reigned in his place.", 
                "num": 26
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Josiah was eight years old when he became king, and he reigned thirty-one years in Jerusalem. His mother's name was Jedidah the daughter of Adaiah of Bozkath.", 
                "num": 1
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, and walked in all the ways of his father David; he did not turn aside to the right hand or to the left.", 
                "num": 2
              }, 
              {
                "text": "Now it came to pass, in the eighteenth year of King Josiah, that the king sent Shaphan the scribe, the son of Azaliah, the son of Meshullam, to the house of the LORD, saying:", 
                "num": 3
              }, 
              {
                "text": "\"Go up to Hilkiah the high priest, that he may count the money which has been brought into the house of the LORD, which the doorkeepers have gathered from the people.", 
                "num": 4
              }, 
              {
                "text": "And let them deliver it into the hand of those doing the work, who are the overseers in the house of the LORD; let them give it to those who are in the house of the LORD doing the work, to repair the damages of the house--", 
                "num": 5
              }, 
              {
                "text": "to carpenters and builders and masons--and to buy timber and hewn stone to repair the house.", 
                "num": 6
              }, 
              {
                "text": "However there need be no accounting made with them of the money delivered into their hand, because they deal faithfully.\"", 
                "num": 7
              }, 
              {
                "text": "Then Hilkiah the high priest said to Shaphan the scribe, \"I have found the Book of the Law in the house of the LORD.\" And Hilkiah gave the book to Shaphan, and he read it.", 
                "num": 8
              }, 
              {
                "text": "So Shaphan the scribe went to the king, bringing the king word, saying, \"Your servants have gathered the money that was found in the house, and have delivered it into the hand of those who do the work, who oversee the house of the LORD.\"", 
                "num": 9
              }, 
              {
                "text": "Then Shaphan the scribe showed the king, saying, \"Hilkiah the priest has given me a book.\" And Shaphan read it before the king.", 
                "num": 10
              }, 
              {
                "text": "Now it happened, when the king heard the words of the Book of the Law, that he tore his clothes.", 
                "num": 11
              }, 
              {
                "text": "Then the king commanded Hilkiah the priest, Ahikam the son of Shaphan, Achbor the son of Michaiah, Shaphan the scribe, and Asaiah a servant of the king, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Go, inquire of the LORD for me, for the people and for all Judah, concerning the words of this book that has been found; for great is the wrath of the LORD that is aroused against us, because our fathers have not obeyed the words of this book, to do according to all that is written concerning us.\"", 
                "num": 13
              }, 
              {
                "text": "So Hilkiah the priest, Ahikam, Achbor, Shaphan, and Asaiah went to Huldah the prophetess, the wife of Shallum the son of Tikvah, the son of Harhas, keeper of the wardrobe. (She dwelt in Jerusalem in the Second Quarter.) And they spoke with her.", 
                "num": 14
              }, 
              {
                "text": "Then she said to them, \"Thus says the LORD God of Israel, \"Tell the man who sent you to Me,", 
                "num": 15
              }, 
              {
                "text": "\"Thus says the LORD: \"Behold, I will bring calamity on this place and on its inhabitants--all the words of the book which the king of Judah has read--", 
                "num": 16
              }, 
              {
                "text": "because they have forsaken Me and burned incense to other gods, that they might provoke Me to anger with all the works of their hands. Therefore My wrath shall be aroused against this place and shall not be quenched.\"\"", 
                "num": 17
              }, 
              {
                "text": "But as for the king of Judah, who sent you to inquire of the LORD, in this manner you shall speak to him, \"Thus says the LORD God of Israel: \"Concerning the words which you have heard--", 
                "num": 18
              }, 
              {
                "text": "because your heart was tender, and you humbled yourself before the LORD when you heard what I spoke against this place and against its inhabitants, that they would become a desolation and a curse, and you tore your clothes and wept before Me, I also have heard you,\" says the LORD.", 
                "num": 19
              }, 
              {
                "text": "Surely, therefore, I will gather you to your fathers, and you shall be gathered to your grave in peace; and your eyes shall not see all the calamity which I will bring on this place.\"\"' So they brought back word to the king.", 
                "num": 20
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Now the king sent them to gather all the elders of Judah and Jerusalem to him.", 
                "num": 1
              }, 
              {
                "text": "The king went up to the house of the LORD with all the men of Judah, and with him all the inhabitants of Jerusalem--the priests and the prophets and all the people, both small and great. And he read in their hearing all the words of the Book of the Covenant which had been found in the house of the LORD.", 
                "num": 2
              }, 
              {
                "text": "Then the king stood by a pillar and made a covenant before the LORD, to follow the LORD and to keep His commandments and His testimonies and His statutes, with all his heart and all his soul, to perform the words of this covenant that were written in this book. And all the people took a stand for the covenant.", 
                "num": 3
              }, 
              {
                "text": "And the king commanded Hilkiah the high priest, the priests of the second order, and the doorkeepers, to bring out of the temple of the LORD all the articles that were made for Baal, for Asherah, and for all the host of heaven; and he burned them outside Jerusalem in the fields of Kidron, and carried their ashes to Bethel.", 
                "num": 4
              }, 
              {
                "text": "Then he removed the idolatrous priests whom the kings of Judah had ordained to burn incense on the high places in the cities of Judah and in the places all around Jerusalem, and those who burned incense to Baal, to the sun, to the moon, to the constellations, and to all the host of heaven.", 
                "num": 5
              }, 
              {
                "text": "And he brought out the wooden image from the house of the LORD, to the Brook Kidron outside Jerusalem, burned it at the Brook Kidron and ground it to ashes, and threw its ashes on the graves of the common people.", 
                "num": 6
              }, 
              {
                "text": "Then he tore down the ritual booths of the perverted persons that were in the house of the LORD, where the women wove hangings for the wooden image.", 
                "num": 7
              }, 
              {
                "text": "And he brought all the priests from the cities of Judah, and defiled the high places where the priests had burned incense, from Geba to Beersheba; also he broke down the high places at the gates which were at the entrance of the Gate of Joshua the governor of the city, which were to the left of the city gate.", 
                "num": 8
              }, 
              {
                "text": "Nevertheless the priests of the high places did not come up to the altar of the LORD in Jerusalem, but they ate unleavened bread among their brethren.", 
                "num": 9
              }, 
              {
                "text": "And he defiled Topheth, which is in the Valley of the Son of Hinnom, that no man might make his son or his daughter pass through the fire to Molech.", 
                "num": 10
              }, 
              {
                "text": "Then he removed the horses that the kings of Judah had dedicated to the sun, at the entrance to the house of the LORD, by the chamber of Nathan-Melech, the officer who was in the court; and he burned the chariots of the sun with fire.", 
                "num": 11
              }, 
              {
                "text": "The altars that were on the roof, the upper chamber of Ahaz, which the kings of Judah had made, and the altars which Manasseh had made in the two courts of the house of the LORD, the king broke down and pulverized there, and threw their dust into the Brook Kidron.", 
                "num": 12
              }, 
              {
                "text": "Then the king defiled the high places that were east of Jerusalem, which were on the south of the Mount of Corruption, which Solomon king of Israel had built for Ashtoreth the abomination of the Sidonians, for Chemosh the abomination of the Moabites, and for Milcom the abomination of the people of Ammon.", 
                "num": 13
              }, 
              {
                "text": "And he broke in pieces the sacred pillars and cut down the wooden images, and filled their places with the bones of men.", 
                "num": 14
              }, 
              {
                "text": "Moreover the altar that was at Bethel, and the high place which Jeroboam the son of Nebat, who made Israel sin, had made, both that altar and the high place he broke down; and he burned the high place and crushed it to powder, and burned the wooden image.", 
                "num": 15
              }, 
              {
                "text": "As Josiah turned, he saw the tombs that were there on the mountain. And he sent and took the bones out of the tombs and burned them on the altar, and defiled it according to the word of the LORD which the man of God proclaimed, who proclaimed these words.", 
                "num": 16
              }, 
              {
                "text": "Then he said, \"What gravestone is this that I see?\" So the men of the city told him, \"It is the tomb of the man of God who came from Judah and proclaimed these things which you have done against the altar of Bethel.\"", 
                "num": 17
              }, 
              {
                "text": "And he said, \"Let him alone; let no one move his bones.\" So they let his bones alone, with the bones of the prophet who came from Samaria.", 
                "num": 18
              }, 
              {
                "text": "Now Josiah also took away all the shrines of the high places that were in the cities of Samaria, which the kings of Israel had made to provoke the LORD to anger; and he did to them according to all the deeds he had done in Bethel.", 
                "num": 19
              }, 
              {
                "text": "He executed all the priests of the high places who were there, on the altars, and burned men's bones on them; and he returned to Jerusalem.", 
                "num": 20
              }, 
              {
                "text": "Then the king commanded all the people, saying, \"Keep the Passover to the LORD your God, as it is written in this Book of the Covenant.\"", 
                "num": 21
              }, 
              {
                "text": "Such a Passover surely had never been held since the days of the judges who judged Israel, nor in all the days of the kings of Israel and the kings of Judah.", 
                "num": 22
              }, 
              {
                "text": "But in the eighteenth year of King Josiah this Passover was held before the LORD in Jerusalem.", 
                "num": 23
              }, 
              {
                "text": "Moreover Josiah put away those who consulted mediums and spiritists, the household gods and idols, all the abominations that were seen in the land of Judah and in Jerusalem, that he might perform the words of the law which were written in the book that Hilkiah the priest found in the house of the LORD.", 
                "num": 24
              }, 
              {
                "text": "Now before him there was no king like him, who turned to the LORD with all his heart, with all his soul, and with all his might, according to all the Law of Moses; nor after him did any arise like him.", 
                "num": 25
              }, 
              {
                "text": "Nevertheless the LORD did not turn from the fierceness of His great wrath, with which His anger was aroused against Judah, because of all the provocations with which Manasseh had provoked Him.", 
                "num": 26
              }, 
              {
                "text": "And the LORD said, \"I will also remove Judah from My sight, as I have removed Israel, and will cast off this city Jerusalem which I have chosen, and the house of which I said, \"My name shall be there.\"'", 
                "num": 27
              }, 
              {
                "text": "Now the rest of the acts of Josiah, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 28
              }, 
              {
                "text": "In his days Pharaoh Necho king of Egypt went to the aid of the king of Assyria, to the River Euphrates; and King Josiah went against him. And Pharaoh Necho killed him at Megiddo when he confronted him.", 
                "num": 29
              }, 
              {
                "text": "Then his servants moved his body in a chariot from Megiddo, brought him to Jerusalem, and buried him in his own tomb. And the people of the land took Jehoahaz the son of Josiah, anointed him, and made him king in his father's place.", 
                "num": 30
              }, 
              {
                "text": "Jehoahaz was twenty-three years old when he became king, and he reigned three months in Jerusalem. His mother's name was Hamutal the daughter of Jeremiah of Libnah.", 
                "num": 31
              }, 
              {
                "text": "And he did evil in the sight of the LORD, according to all that his fathers had done.", 
                "num": 32
              }, 
              {
                "text": "Now Pharaoh Necho put him in prison at Riblah in the land of Hamath, that he might not reign in Jerusalem; and he imposed on the land a tribute of one hundred talents of silver and a talent of gold.", 
                "num": 33
              }, 
              {
                "text": "Then Pharaoh Necho made Eliakim the son of Josiah king in place of his father Josiah, and changed his name to Jehoiakim. And Pharaoh took Jehoahaz and went to Egypt, and he died there.", 
                "num": 34
              }, 
              {
                "text": "So Jehoiakim gave the silver and gold to Pharaoh; but he taxed the land to give money according to the command of Pharaoh; he exacted the silver and gold from the people of the land, from every one according to his assessment, to give it to Pharaoh Necho.", 
                "num": 35
              }, 
              {
                "text": "Jehoiakim was twenty-five years old when he became king, and he reigned eleven years in Jerusalem. His mother's name was Zebudah the daughter of Pedaiah of Rumah.", 
                "num": 36
              }, 
              {
                "text": "And he did evil in the sight of the LORD, according to all that his fathers had done.", 
                "num": 37
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "In his days Nebuchadnezzar king of Babylon came up, and Jehoiakim became his vassal for three years. Then he turned and rebelled against him.", 
                "num": 1
              }, 
              {
                "text": "And the LORD sent against him raiding bands of Chaldeans, bands of Syrians, bands of Moabites, and bands of the people of Ammon; He sent them against Judah to destroy it, according to the word of the LORD which He had spoken by His servants the prophets.", 
                "num": 2
              }, 
              {
                "text": "Surely at the commandment of the LORD this came upon Judah, to remove them from His sight because of the sins of Manasseh, according to all that he had done,", 
                "num": 3
              }, 
              {
                "text": "and also because of the innocent blood that he had shed; for he had filled Jerusalem with innocent blood, which the LORD would not pardon.", 
                "num": 4
              }, 
              {
                "text": "Now the rest of the acts of Jehoiakim, and all that he did, are they not written in the book of the chronicles of the kings of Judah?", 
                "num": 5
              }, 
              {
                "text": "So Jehoiakim rested with his fathers. Then Jehoiachin his son reigned in his place.", 
                "num": 6
              }, 
              {
                "text": "And the king of Egypt did not come out of his land anymore, for the king of Babylon had taken all that belonged to the king of Egypt from the Brook of Egypt to the River Euphrates.", 
                "num": 7
              }, 
              {
                "text": "Jehoiachin was eighteen years old when he became king, and he reigned in Jerusalem three months. His mother's name was Nehushta the daughter of Elnathan of Jerusalem.", 
                "num": 8
              }, 
              {
                "text": "And he did evil in the sight of the LORD, according to all that his father had done.", 
                "num": 9
              }, 
              {
                "text": "At that time the servants of Nebuchadnezzar king of Babylon came up against Jerusalem, and the city was besieged.", 
                "num": 10
              }, 
              {
                "text": "And Nebuchadnezzar king of Babylon came against the city, as his servants were besieging it.", 
                "num": 11
              }, 
              {
                "text": "Then Jehoiachin king of Judah, his mother, his servants, his princes, and his officers went out to the king of Babylon; and the king of Babylon, in the eighth year of his reign, took him prisoner.", 
                "num": 12
              }, 
              {
                "text": "And he carried out from there all the treasures of the house of the LORD and the treasures of the king's house, and he cut in pieces all the articles of gold which Solomon king of Israel had made in the temple of the LORD, as the LORD had said.", 
                "num": 13
              }, 
              {
                "text": "Also he carried into captivity all Jerusalem: all the captains and all the mighty men of valor, ten thousand captives, and all the craftsmen and smiths. None remained except the poorest people of the land.", 
                "num": 14
              }, 
              {
                "text": "And he carried Jehoiachin captive to Babylon. The king's mother, the king's wives, his officers, and the mighty of the land he carried into captivity from Jerusalem to Babylon.", 
                "num": 15
              }, 
              {
                "text": "All the valiant men, seven thousand, and craftsmen and smiths, one thousand, all who were strong and fit for war, these the king of Babylon brought captive to Babylon.", 
                "num": 16
              }, 
              {
                "text": "Then the king of Babylon made Mattaniah, Jehoiachin's uncle, king in his place, and changed his name to Zedekiah.", 
                "num": 17
              }, 
              {
                "text": "Zedekiah was twenty-one years old when he became king, and he reigned eleven years in Jerusalem. His mother's name was Hamutal the daughter of Jeremiah of Libnah.", 
                "num": 18
              }, 
              {
                "text": "He also did evil in the sight of the LORD, according to all that Jehoiakim had done.", 
                "num": 19
              }, 
              {
                "text": "For because of the anger of the LORD this happened in Jerusalem and Judah, that He finally cast them out from His presence. Then Zedekiah rebelled against the king of Babylon.", 
                "num": 20
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the ninth year of his reign, in the tenth month, on the tenth day of the month, that Nebuchadnezzar king of Babylon and all his army came against Jerusalem and encamped against it; and they built a siege wall against it all around.", 
                "num": 1
              }, 
              {
                "text": "So the city was besieged until the eleventh year of King Zedekiah.", 
                "num": 2
              }, 
              {
                "text": "By the ninth day of the fourth month the famine had become so severe in the city that there was no food for the people of the land.", 
                "num": 3
              }, 
              {
                "text": "Then the city wall was broken through, and all the men of war fled at night by way of the gate between two walls, which was by the king's garden, even though the Chaldeans were still encamped all around against the city. And the king went by way of the plain.", 
                "num": 4
              }, 
              {
                "text": "But the army of the Chaldeans pursued the king, and they overtook him in the plains of Jericho. All his army was scattered from him.", 
                "num": 5
              }, 
              {
                "text": "So they took the king and brought him up to the king of Babylon at Riblah, and they pronounced judgment on him.", 
                "num": 6
              }, 
              {
                "text": "Then they killed the sons of Zedekiah before his eyes, put out the eyes of Zedekiah, bound him with bronze fetters, and took him to Babylon.", 
                "num": 7
              }, 
              {
                "text": "And in the fifth month, on the seventh day of the month (which was the nineteenth year of King Nebuchadnezzar king of Babylon), Nebuzaradan the captain of the guard, a servant of the king of Babylon, came to Jerusalem.", 
                "num": 8
              }, 
              {
                "text": "He burned the house of the LORD and the king's house; all the houses of Jerusalem, that is, all the houses of the great, he burned with fire.", 
                "num": 9
              }, 
              {
                "text": "And all the army of the Chaldeans who were with the captain of the guard broke down the walls of Jerusalem all around.", 
                "num": 10
              }, 
              {
                "text": "Then Nebuzaradan the captain of the guard carried away captive the rest of the people who remained in the city and the defectors who had deserted to the king of Babylon, with the rest of the multitude.", 
                "num": 11
              }, 
              {
                "text": "But the captain of the guard left some of the poor of the land as vinedressers and farmers.", 
                "num": 12
              }, 
              {
                "text": "The bronze pillars that were in the house of the LORD, and the carts and the bronze Sea that were in the house of the LORD, the Chaldeans broke in pieces, and carried their bronze to Babylon.", 
                "num": 13
              }, 
              {
                "text": "They also took away the pots, the shovels, the trimmers, the spoons, and all the bronze utensils with which the priests ministered.", 
                "num": 14
              }, 
              {
                "text": "The firepans and the basins, the things of solid gold and solid silver, the captain of the guard took away.", 
                "num": 15
              }, 
              {
                "text": "The two pillars, one Sea, and the carts, which Solomon had made for the house of the LORD, the bronze of all these articles was beyond measure.", 
                "num": 16
              }, 
              {
                "text": "The height of one pillar was eighteen cubits, and the capital on it was of bronze. The height of the capital was three cubits, and the network and pomegranates all around the capital were all of bronze. The second pillar was the same, with a network.", 
                "num": 17
              }, 
              {
                "text": "And the captain of the guard took Seraiah the chief priest, Zephaniah the second priest, and the three doorkeepers.", 
                "num": 18
              }, 
              {
                "text": "He also took out of the city an officer who had charge of the men of war, five men of the king's close associates who were found in the city, the chief recruiting officer of the army, who mustered the people of the land, and sixty men of the people of the land who were found in the city.", 
                "num": 19
              }, 
              {
                "text": "So Nebuzaradan, captain of the guard, took these and brought them to the king of Babylon at Riblah.", 
                "num": 20
              }, 
              {
                "text": "Then the king of Babylon struck them and put them to death at Riblah in the land of Hamath. Thus Judah was carried away captive from its own land.", 
                "num": 21
              }, 
              {
                "text": "Then he made Gedaliah the son of Ahikam, the son of Shaphan, governor over the people who remained in the land of Judah, whom Nebuchadnezzar king of Babylon had left.", 
                "num": 22
              }, 
              {
                "text": "Now when all the captains of the armies, they and their men, heard that the king of Babylon had made Gedaliah governor, they came to Gedaliah at Mizpah--Ishmael the son of Nethaniah, Johanan the son of Careah, Seraiah the son of Tanhumeth the Netophathite, and Jaazaniah the son of a Maachathite, they and their men.", 
                "num": 23
              }, 
              {
                "text": "And Gedaliah took an oath before them and their men, and said to them, \"Do not be afraid of the servants of the Chaldeans. Dwell in the land and serve the king of Babylon, and it shall be well with you.\"", 
                "num": 24
              }, 
              {
                "text": "But it happened in the seventh month that Ishmael the son of Nethaniah, the son of Elishama, of the royal family, came with ten men and struck and killed Gedaliah, the Jews, as well as the Chaldeans who were with him at Mizpah.", 
                "num": 25
              }, 
              {
                "text": "And all the people, small and great, and the captains of the armies, arose and went to Egypt; for they were afraid of the Chaldeans.", 
                "num": 26
              }, 
              {
                "text": "Now it came to pass in the thirty-seventh year of the captivity of Jehoiachin king of Judah, in the twelfth month, on the twenty-seventh day of the month, that Evil-Merodach king of Babylon, in the year that he began to reign, released Jehoiachin king of Judah from prison.", 
                "num": 27
              }, 
              {
                "text": "He spoke kindly to him, and gave him a more prominent seat than those of the kings who were with him in Babylon.", 
                "num": 28
              }, 
              {
                "text": "So Jehoiachin changed from his prison garments, and he ate bread regularly before the king all the days of his life.", 
                "num": 29
              }, 
              {
                "text": "And as for his provisions, there was a regular ration given him by the king, a portion for each day, all the days of his life.", 
                "num": 30
              }
            ], 
            "num": 25
          }
        ]
      }, 
      {
        "name": "1 Chronicles", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Adam, Seth, Enosh,", 
                "num": 1
              }, 
              {
                "text": "Cainan, Mahalalel, Jared,", 
                "num": 2
              }, 
              {
                "text": "Enoch, Methuselah, Lamech,", 
                "num": 3
              }, 
              {
                "text": "Noah, Shem, Ham, and Japheth.", 
                "num": 4
              }, 
              {
                "text": "The sons of Japheth were Gomer, Magog, Madai, Javan, Tubal, Meshech, and Tiras.", 
                "num": 5
              }, 
              {
                "text": "The sons of Gomer were Ashkenaz, Diphath, and Togarmah.", 
                "num": 6
              }, 
              {
                "text": "The sons of Javan were Elishah, Tarshishah, Kittim, and Rodanim.", 
                "num": 7
              }, 
              {
                "text": "The sons of Ham were Cush, Mizraim, Put, and Canaan.", 
                "num": 8
              }, 
              {
                "text": "The sons of Cush were Seba, Havilah, Sabta, Raama, and Sabtecha. The sons of Raama were Sheba and Dedan.", 
                "num": 9
              }, 
              {
                "text": "Cush begot Nimrod; he began to be a mighty one on the earth.", 
                "num": 10
              }, 
              {
                "text": "Mizraim begot Ludim, Anamim, Lehabim, Naphtuhim,", 
                "num": 11
              }, 
              {
                "text": "Pathrusim, Casluhim (from whom came the Philistines and the Caphtorim).", 
                "num": 12
              }, 
              {
                "text": "Canaan begot Sidon, his firstborn, and Heth;", 
                "num": 13
              }, 
              {
                "text": "the Jebusite, the Amorite, and the Girgashite;", 
                "num": 14
              }, 
              {
                "text": "the Hivite, the Arkite, and the Sinite;", 
                "num": 15
              }, 
              {
                "text": "the Arvadite, the Zemarite, and the Hamathite.", 
                "num": 16
              }, 
              {
                "text": "The sons of Shem were Elam, Asshur, Arphaxad, Lud, Aram, Uz, Hul, Gether, and Meshech.", 
                "num": 17
              }, 
              {
                "text": "Arphaxad begot Shelah, and Shelah begot Eber.", 
                "num": 18
              }, 
              {
                "text": "To Eber were born two sons: the name of one was Peleg, for in his days the earth was divided; and his brother's name was Joktan.", 
                "num": 19
              }, 
              {
                "text": "Joktan begot Almodad, Sheleph, Hazarmaveth, Jerah,", 
                "num": 20
              }, 
              {
                "text": "Hadoram, Uzal, Diklah,", 
                "num": 21
              }, 
              {
                "text": "Ebal, Abimael, Sheba,", 
                "num": 22
              }, 
              {
                "text": "Ophir, Havilah, and Jobab. All these were the sons of Joktan.", 
                "num": 23
              }, 
              {
                "text": "Shem, Arphaxad, Shelah,", 
                "num": 24
              }, 
              {
                "text": "Eber, Peleg, Reu,", 
                "num": 25
              }, 
              {
                "text": "Serug, Nahor, Terah,", 
                "num": 26
              }, 
              {
                "text": "and Abram, who is Abraham.", 
                "num": 27
              }, 
              {
                "text": "The sons of Abraham were Isaac and Ishmael.", 
                "num": 28
              }, 
              {
                "text": "These are their genealogies: The firstborn of Ishmael was Nebajoth; then Kedar, Adbeel, Mibsam,", 
                "num": 29
              }, 
              {
                "text": "Mishma, Dumah, Massa, Hadad, Tema,", 
                "num": 30
              }, 
              {
                "text": "Jetur, Naphish, and Kedemah. These were the sons of Ishmael.", 
                "num": 31
              }, 
              {
                "text": "Now the sons born to Keturah, Abraham's concubine, were Zimran, Jokshan, Medan, Midian, Ishbak, and Shuah. The sons of Jokshan were Sheba and Dedan.", 
                "num": 32
              }, 
              {
                "text": "The sons of Midian were Ephah, Epher, Hanoch, Abida, and Eldaah. All these were the children of Keturah.", 
                "num": 33
              }, 
              {
                "text": "And Abraham begot Isaac. The sons of Isaac were Esau and Israel.", 
                "num": 34
              }, 
              {
                "text": "The sons of Esau were Eliphaz, Reuel, Jeush, Jaalam, and Korah.", 
                "num": 35
              }, 
              {
                "text": "And the sons of Eliphaz were Teman, Omar, Zephi, Gatam, and Kenaz; and by Timna, Amalek.", 
                "num": 36
              }, 
              {
                "text": "The sons of Reuel were Nahath, Zerah, Shammah, and Mizzah.", 
                "num": 37
              }, 
              {
                "text": "The sons of Seir were Lotan, Shobal, Zibeon, Anah, Dishon, Ezer, and Dishan.", 
                "num": 38
              }, 
              {
                "text": "And the sons of Lotan were Hori and Homam; Lotan's sister was Timna.", 
                "num": 39
              }, 
              {
                "text": "The sons of Shobal were Alian, Manahath, Ebal, Shephi, and Onam. The sons of Zibeon were Ajah and Anah.", 
                "num": 40
              }, 
              {
                "text": "The son of Anah was Dishon. The sons of Dishon were Hamran, Eshban, Ithran, and Cheran.", 
                "num": 41
              }, 
              {
                "text": "The sons of Ezer were Bilhan, Zaavan, and Jaakan. The sons of Dishan were Uz and Aran.", 
                "num": 42
              }, 
              {
                "text": "Now these were the kings who reigned in the land of Edom before a king reigned over the children of Israel: Bela the son of Beor, and the name of his city was Dinhabah.", 
                "num": 43
              }, 
              {
                "text": "And when Bela died, Jobab the son of Zerah of Bozrah reigned in his place.", 
                "num": 44
              }, 
              {
                "text": "When Jobab died, Husham of the land of the Temanites reigned in his place.", 
                "num": 45
              }, 
              {
                "text": "And when Husham died, Hadad the son of Bedad, who attacked Midian in the field of Moab, reigned in his place. The name of his city was Avith.", 
                "num": 46
              }, 
              {
                "text": "When Hadad died, Samlah of Masrekah reigned in his place.", 
                "num": 47
              }, 
              {
                "text": "And when Samlah died, Saul of Rehoboth-by-the-River reigned in his place.", 
                "num": 48
              }, 
              {
                "text": "When Saul died, Baal-Hanan the son of Achbor reigned in his place.", 
                "num": 49
              }, 
              {
                "text": "And when Baal-Hanan died, Hadad reigned in his place; and the name of his city was Pai. His wife's name was Mehetabel the daughter of Matred, the daughter of Mezahab.", 
                "num": 50
              }, 
              {
                "text": "Hadad died also. And the chiefs of Edom were Chief Timnah, Chief Aliah, Chief Jetheth,", 
                "num": 51
              }, 
              {
                "text": "Chief Aholibamah, Chief Elah, Chief Pinon,", 
                "num": 52
              }, 
              {
                "text": "Chief Kenaz, Chief Teman, Chief Mibzar,", 
                "num": 53
              }, 
              {
                "text": "Chief Magdiel, and Chief Iram. These were the chiefs of Edom.", 
                "num": 54
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "These were the sons of Israel: Reuben, Simeon, Levi, Judah, Issachar, Zebulun,", 
                "num": 1
              }, 
              {
                "text": "Dan, Joseph, Benjamin, Naphtali, Gad, and Asher.", 
                "num": 2
              }, 
              {
                "text": "The sons of Judah were Er, Onan, and Shelah. These three were born to him by the daughter of Shua, the Canaanitess. Er, the firstborn of Judah, was wicked in the sight of the LORD; so He killed him.", 
                "num": 3
              }, 
              {
                "text": "And Tamar, his daughter-in-law, bore him Perez and Zerah. All the sons of Judah were five.", 
                "num": 4
              }, 
              {
                "text": "The sons of Perez were Hezron and Hamul.", 
                "num": 5
              }, 
              {
                "text": "The sons of Zerah were Zimri, Ethan, Heman, Calcol, and Dara--five of them in all.", 
                "num": 6
              }, 
              {
                "text": "The son of Carmi was Achar, the troubler of Israel, who transgressed in the accursed thing.", 
                "num": 7
              }, 
              {
                "text": "The son of Ethan was Azariah.", 
                "num": 8
              }, 
              {
                "text": "Also the sons of Hezron who were born to him were Jerahmeel, Ram, and Chelubai.", 
                "num": 9
              }, 
              {
                "text": "Ram begot Amminadab, and Amminadab begot Nahshon, leader of the children of Judah;", 
                "num": 10
              }, 
              {
                "text": "Nahshon begot Salma, and Salma begot Boaz;", 
                "num": 11
              }, 
              {
                "text": "Boaz begot Obed, and Obed begot Jesse;", 
                "num": 12
              }, 
              {
                "text": "Jesse begot Eliab his firstborn, Abinadab the second, Shimea the third,", 
                "num": 13
              }, 
              {
                "text": "Nethanel the fourth, Raddai the fifth,", 
                "num": 14
              }, 
              {
                "text": "Ozem the sixth, and David the seventh.", 
                "num": 15
              }, 
              {
                "text": "Now their sisters were Zeruiah and Abigail. And the sons of Zeruiah were Abishai, Joab, and Asahel--three.", 
                "num": 16
              }, 
              {
                "text": "Abigail bore Amasa; and the father of Amasa was Jether the Ishmaelite.", 
                "num": 17
              }, 
              {
                "text": "Caleb the son of Hezron had children by Azubah, his wife, and by Jerioth. Now these were her sons: Jesher, Shobab, and Ardon.", 
                "num": 18
              }, 
              {
                "text": "When Azubah died, Caleb took Ephrath as his wife, who bore him Hur.", 
                "num": 19
              }, 
              {
                "text": "And Hur begot Uri, and Uri begot Bezalel.", 
                "num": 20
              }, 
              {
                "text": "Now afterward Hezron went in to the daughter of Machir the father of Gilead, whom he married when he was sixty years old; and she bore him Segub.", 
                "num": 21
              }, 
              {
                "text": "Segub begot Jair, who had twenty-three cities in the land of Gilead.", 
                "num": 22
              }, 
              {
                "text": "(Geshur and Syria took from them the towns of Jair, with Kenath and its towns--sixty towns.) All these belonged to the sons of Machir the father of Gilead.", 
                "num": 23
              }, 
              {
                "text": "After Hezron died in Caleb Ephrathah, Hezron's wife Abijah bore him Ashhur the father of Tekoa.", 
                "num": 24
              }, 
              {
                "text": "The sons of Jerahmeel, the firstborn of Hezron, were Ram, the firstborn, and Bunah, Oren, Ozem, and Ahijah.", 
                "num": 25
              }, 
              {
                "text": "Jerahmeel had another wife, whose name was Atarah; she was the mother of Onam.", 
                "num": 26
              }, 
              {
                "text": "The sons of Ram, the firstborn of Jerahmeel, were Maaz, Jamin, and Eker.", 
                "num": 27
              }, 
              {
                "text": "The sons of Onam were Shammai and Jada. The sons of Shammai were Nadab and Abishur.", 
                "num": 28
              }, 
              {
                "text": "And the name of the wife of Abishur was Abihail, and she bore him Ahban and Molid.", 
                "num": 29
              }, 
              {
                "text": "The sons of Nadab were Seled and Appaim; Seled died without children.", 
                "num": 30
              }, 
              {
                "text": "The son of Appaim was Ishi, the son of Ishi was Sheshan, and Sheshan's son was Ahlai.", 
                "num": 31
              }, 
              {
                "text": "The sons of Jada, the brother of Shammai, were Jether and Jonathan; Jether died without children.", 
                "num": 32
              }, 
              {
                "text": "The sons of Jonathan were Peleth and Zaza. These were the sons of Jerahmeel.", 
                "num": 33
              }, 
              {
                "text": "Now Sheshan had no sons, only daughters. And Sheshan had an Egyptian servant whose name was Jarha.", 
                "num": 34
              }, 
              {
                "text": "Sheshan gave his daughter to Jarha his servant as wife, and she bore him Attai.", 
                "num": 35
              }, 
              {
                "text": "Attai begot Nathan, and Nathan begot Zabad;", 
                "num": 36
              }, 
              {
                "text": "Zabad begot Ephlal, and Ephlal begot Obed;", 
                "num": 37
              }, 
              {
                "text": "Obed begot Jehu, and Jehu begot Azariah;", 
                "num": 38
              }, 
              {
                "text": "Azariah begot Helez, and Helez begot Eleasah;", 
                "num": 39
              }, 
              {
                "text": "Eleasah begot Sismai, and Sismai begot Shallum;", 
                "num": 40
              }, 
              {
                "text": "Shallum begot Jekamiah, and Jekamiah begot Elishama.", 
                "num": 41
              }, 
              {
                "text": "The descendants of Caleb the brother of Jerahmeel were Mesha, his firstborn, who was the father of Ziph, and the sons of Mareshah the father of Hebron.", 
                "num": 42
              }, 
              {
                "text": "The sons of Hebron were Korah, Tappuah, Rekem, and Shema.", 
                "num": 43
              }, 
              {
                "text": "Shema begot Raham the father of Jorkoam, and Rekem begot Shammai.", 
                "num": 44
              }, 
              {
                "text": "And the son of Shammai was Maon, and Maon was the father of Beth Zur.", 
                "num": 45
              }, 
              {
                "text": "Ephah, Caleb's concubine, bore Haran, Moza, and Gazez; and Haran begot Gazez.", 
                "num": 46
              }, 
              {
                "text": "And the sons of Jahdai were Regem, Jotham, Geshan, Pelet, Ephah, and Shaaph.", 
                "num": 47
              }, 
              {
                "text": "Maachah, Caleb's concubine, bore Sheber and Tirhanah.", 
                "num": 48
              }, 
              {
                "text": "She also bore Shaaph the father of Madmannah, Sheva the father of Machbenah and the father of Gibea. And the daughter of Caleb was Achsah.", 
                "num": 49
              }, 
              {
                "text": "These were the descendants of Caleb: The sons of Hur, the firstborn of Ephrathah, were Shobal the father of Kirjath Jearim,", 
                "num": 50
              }, 
              {
                "text": "Salma the father of Bethlehem, and Hareph the father of Beth Gader.", 
                "num": 51
              }, 
              {
                "text": "And Shobal the father of Kirjath Jearim had descendants: Haroeh, and half of the families of Manuhoth.", 
                "num": 52
              }, 
              {
                "text": "The families of Kirjath Jearim were the Ithrites, the Puthites, the Shumathites, and the Mishraites. From these came the Zorathites and the Eshtaolites.", 
                "num": 53
              }, 
              {
                "text": "The sons of Salma were Bethlehem, the Netophathites, Atroth Beth Joab, half of the Manahethites, and the Zorites.", 
                "num": 54
              }, 
              {
                "text": "And the families of the scribes who dwelt at Jabez were the Tirathites, the Shimeathites, and the Suchathites. These were the Kenites who came from Hammath, the father of the house of Rechab.", 
                "num": 55
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "The Family of David Now these were the sons of David who were born to him in Hebron: The firstborn was Amnon, by Ahinoam the Jezreelitess; the second, Daniel, by Abigail the Carmelitess;", 
                "num": 1
              }, 
              {
                "text": "the third, Absalom the son of Maacah, the daughter of Talmai, king of Geshur; the fourth, Adonijah the son of Haggith;", 
                "num": 2
              }, 
              {
                "text": "the fifth, Shephatiah, by Abital; the sixth, Ithream, by his wife Eglah.", 
                "num": 3
              }, 
              {
                "text": "These six were born to him in Hebron. There he reigned seven years and six months, and in Jerusalem he reigned thirty-three years.", 
                "num": 4
              }, 
              {
                "text": "And these were born to him in Jerusalem: Shimea, Shobab, Nathan, and Solomon--four by Bathshua the daughter of Ammiel.", 
                "num": 5
              }, 
              {
                "text": "Also there were Ibhar, Elishama, Eliphelet,", 
                "num": 6
              }, 
              {
                "text": "Nogah, Nepheg, Japhia,", 
                "num": 7
              }, 
              {
                "text": "Elishama, Eliada, and Eliphelet--nine in all.", 
                "num": 8
              }, 
              {
                "text": "These were all the sons of David, besides the sons of the concubines, and Tamar their sister.", 
                "num": 9
              }, 
              {
                "text": "Solomon's son was Rehoboam; Abijah was his son, Asa his son, Jehoshaphat his son,", 
                "num": 10
              }, 
              {
                "text": "Joram his son, Ahaziah his son, Joash his son,", 
                "num": 11
              }, 
              {
                "text": "Amaziah his son, Azariah his son, Jotham his son,", 
                "num": 12
              }, 
              {
                "text": "Ahaz his son, Hezekiah his son, Manasseh his son,", 
                "num": 13
              }, 
              {
                "text": "Amon his son, and Josiah his son.", 
                "num": 14
              }, 
              {
                "text": "The sons of Josiah were Johanan the firstborn, the second Jehoiakim, the third Zedekiah, and the fourth Shallum.", 
                "num": 15
              }, 
              {
                "text": "The sons of Jehoiakim were Jeconiah his son and Zedekiah his son.", 
                "num": 16
              }, 
              {
                "text": "And the sons of Jeconiah were Assir, Shealtiel his son,", 
                "num": 17
              }, 
              {
                "text": "and Malchiram, Pedaiah, Shenazzar, Jecamiah, Hoshama, and Nedabiah.", 
                "num": 18
              }, 
              {
                "text": "The sons of Pedaiah were Zerubbabel and Shimei. The sons of Zerubbabel were Meshullam, Hananiah, Shelomith their sister,", 
                "num": 19
              }, 
              {
                "text": "and Hashubah, Ohel, Berechiah, Hasadiah, and Jushab-Hesed--five in all.", 
                "num": 20
              }, 
              {
                "text": "The sons of Hananiah were Pelatiah and Jeshaiah, the sons of Rephaiah, the sons of Arnan, the sons of Obadiah, and the sons of Shechaniah.", 
                "num": 21
              }, 
              {
                "text": "The son of Shechaniah was Shemaiah. The sons of Shemaiah were Hattush, Igal, Bariah, Neariah, and Shaphat--six in all.", 
                "num": 22
              }, 
              {
                "text": "The sons of Neariah were Elioenai, Hezekiah, and Azrikam--three in all.", 
                "num": 23
              }, 
              {
                "text": "The sons of Elioenai were Hodaviah, Eliashib, Pelaiah, Akkub, Johanan, Delaiah, and Anani--seven in all.", 
                "num": 24
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "The sons of Judah were Perez, Hezron, Carmi, Hur, and Shobal.", 
                "num": 1
              }, 
              {
                "text": "And Reaiah the son of Shobal begot Jahath, and Jahath begot Ahumai and Lahad. These were the families of the Zorathites.", 
                "num": 2
              }, 
              {
                "text": "These were the sons of the father of Etam: Jezreel, Ishma, and Idbash; and the name of their sister was Hazelelponi;", 
                "num": 3
              }, 
              {
                "text": "and Penuel was the father of Gedor, and Ezer was the father of Hushah. These were the sons of Hur, the firstborn of Ephrathah the father of Bethlehem.", 
                "num": 4
              }, 
              {
                "text": "And Ashhur the father of Tekoa had two wives, Helah and Naarah.", 
                "num": 5
              }, 
              {
                "text": "Naarah bore him Ahuzzam, Hepher, Temeni, and Haahashtari. These were the sons of Naarah.", 
                "num": 6
              }, 
              {
                "text": "The sons of Helah were Zereth, Zohar, and Ethnan;", 
                "num": 7
              }, 
              {
                "text": "and Koz begot Anub, Zobebah, and the families of Aharhel the son of Harum.", 
                "num": 8
              }, 
              {
                "text": "Now Jabez was more honorable than his brothers, and his mother called his name Jabez, saying, \"Because I bore him in pain.\"", 
                "num": 9
              }, 
              {
                "text": "And Jabez called on the God of Israel saying, \"Oh, that You would bless me indeed, and enlarge my territory, that Your hand would be with me, and that You would keep me from evil, that I may not cause pain!\" So God granted him what he requested.", 
                "num": 10
              }, 
              {
                "text": "Chelub the brother of Shuhah begot Mehir, who was the father of Eshton.", 
                "num": 11
              }, 
              {
                "text": "And Eshton begot Beth-Rapha, Paseah, and Tehinnah the father of Ir-Nahash. These were the men of Rechah.", 
                "num": 12
              }, 
              {
                "text": "The sons of Kenaz were Othniel and Seraiah. The sons of Othniel were Hathath,", 
                "num": 13
              }, 
              {
                "text": "and Meonothai who begot Ophrah. Seraiah begot Joab the father of Ge Harashim, for they were craftsmen.", 
                "num": 14
              }, 
              {
                "text": "The sons of Caleb the son of Jephunneh were Iru, Elah, and Naam. The son of Elah was Kenaz.", 
                "num": 15
              }, 
              {
                "text": "The sons of Jehallelel were Ziph, Ziphah, Tiria, and Asarel.", 
                "num": 16
              }, 
              {
                "text": "The sons of Ezrah were Jether, Mered, Epher, and Jalon. And Mered's wife bore Miriam, Shammai, and Ishbah the father of Eshtemoa.", 
                "num": 17
              }, 
              {
                "text": "(His wife Jehudijah bore Jered the father of Gedor, Heber the father of Sochoh, and Jekuthiel the father of Zanoah.) And these were the sons of Bithiah the daughter of Pharaoh, whom Mered took.", 
                "num": 18
              }, 
              {
                "text": "The sons of Hodiah's wife, the sister of Naham, were the fathers of Keilah the Garmite and of Eshtemoa the Maachathite.", 
                "num": 19
              }, 
              {
                "text": "And the sons of Shimon were Amnon, Rinnah, Ben-Hanan, and Tilon. And the sons of Ishi were Zoheth and Ben-Zoheth.", 
                "num": 20
              }, 
              {
                "text": "The sons of Shelah the son of Judah were Er the father of Lecah, Laadah the father of Mareshah, and the families of the house of the linen workers of the house of Ashbea;", 
                "num": 21
              }, 
              {
                "text": "also Jokim, the men of Chozeba, and Joash; Saraph, who ruled in Moab, and Jashubi-Lehem. Now the records are ancient.", 
                "num": 22
              }, 
              {
                "text": "These were the potters and those who dwell at Netaim and Gederah; there they dwelt with the king for his work.", 
                "num": 23
              }, 
              {
                "text": "The sons of Simeon were Nemuel, Jamin, Jarib, Zerah, and Shaul,", 
                "num": 24
              }, 
              {
                "text": "Shallum his son, Mibsam his son, and Mishma his son.", 
                "num": 25
              }, 
              {
                "text": "And the sons of Mishma were Hamuel his son, Zacchur his son, and Shimei his son.", 
                "num": 26
              }, 
              {
                "text": "Shimei had sixteen sons and six daughters; but his brothers did not have many children, nor did any of their families multiply as much as the children of Judah.", 
                "num": 27
              }, 
              {
                "text": "They dwelt at Beersheba, Moladah, Hazar Shual,", 
                "num": 28
              }, 
              {
                "text": "Bilhah, Ezem, Tolad,", 
                "num": 29
              }, 
              {
                "text": "Bethuel, Hormah, Ziklag,", 
                "num": 30
              }, 
              {
                "text": "Beth Marcaboth, Hazar Susim, Beth Biri, and at Shaaraim. These were their cities until the reign of David.", 
                "num": 31
              }, 
              {
                "text": "And their villages were Etam, Ain, Rimmon, Tochen, and Ashan--five cities--", 
                "num": 32
              }, 
              {
                "text": "and all the villages that were around these cities as far as Baal. These were their dwelling places, and they maintained their genealogy:", 
                "num": 33
              }, 
              {
                "text": "Meshobab, Jamlech, and Joshah the son of Amaziah;", 
                "num": 34
              }, 
              {
                "text": "Joel, and Jehu the son of Joshibiah, the son of Seraiah, the son of Asiel;", 
                "num": 35
              }, 
              {
                "text": "Elioenai, Jaakobah, Jeshohaiah, Asaiah, Adiel, Jesimiel, and Benaiah;", 
                "num": 36
              }, 
              {
                "text": "Ziza the son of Shiphi, the son of Allon, the son of Jedaiah, the son of Shimri, the son of Shemaiah--", 
                "num": 37
              }, 
              {
                "text": "these mentioned by name were leaders in their families, and their father's house increased greatly.", 
                "num": 38
              }, 
              {
                "text": "So they went to the entrance of Gedor, as far as the east side of the valley, to seek pasture for their flocks.", 
                "num": 39
              }, 
              {
                "text": "And they found rich, good pasture, and the land was broad, quiet, and peaceful; for some Hamites formerly lived there.", 
                "num": 40
              }, 
              {
                "text": "These recorded by name came in the days of Hezekiah king of Judah; and they attacked their tents and the Meunites who were found there, and utterly destroyed them, as it is to this day. So they dwelt in their place, because there was pasture for their flocks there.", 
                "num": 41
              }, 
              {
                "text": "Now some of them, five hundred men of the sons of Simeon, went to Mount Seir, having as their captains Pelatiah, Neariah, Rephaiah, and Uzziel, the sons of Ishi.", 
                "num": 42
              }, 
              {
                "text": "And they defeated the rest of the Amalekites who had escaped. They have dwelt there to this day.", 
                "num": 43
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now the sons of Reuben the firstborn of Israel--he was indeed the firstborn, but because he defiled his father's bed, his birthright was given to the sons of Joseph, the son of Israel, so that the genealogy is not listed according to the birthright;", 
                "num": 1
              }, 
              {
                "text": "yet Judah prevailed over his brothers, and from him came a ruler, although the birthright was Joseph's--", 
                "num": 2
              }, 
              {
                "text": "the sons of Reuben the firstborn of Israel were Hanoch, Pallu, Hezron, and Carmi.", 
                "num": 3
              }, 
              {
                "text": "The sons of Joel were Shemaiah his son, Gog his son, Shimei his son,", 
                "num": 4
              }, 
              {
                "text": "Micah his son, Reaiah his son, Baal his son,", 
                "num": 5
              }, 
              {
                "text": "and Beerah his son, whom Tiglath-Pileser king of Assyria carried into captivity. He was leader of the Reubenites.", 
                "num": 6
              }, 
              {
                "text": "And his brethren by their families, when the genealogy of their generations was registered: the chief, Jeiel, and Zechariah,", 
                "num": 7
              }, 
              {
                "text": "and Bela the son of Azaz, the son of Shema, the son of Joel, who dwelt in Aroer, as far as Nebo and Baal Meon.", 
                "num": 8
              }, 
              {
                "text": "Eastward they settled as far as the entrance of the wilderness this side of the River Euphrates, because their cattle had multiplied in the land of Gilead.", 
                "num": 9
              }, 
              {
                "text": "Now in the days of Saul they made war with the Hagrites, who fell by their hand; and they dwelt in their tents throughout the entire area east of Gilead.", 
                "num": 10
              }, 
              {
                "text": "And the children of Gad dwelt next to them in the land of Bashan as far as Salcah:", 
                "num": 11
              }, 
              {
                "text": "Joel was the chief, Shapham the next, then Jaanai and Shaphat in Bashan,", 
                "num": 12
              }, 
              {
                "text": "and their brethren of their father's house: Michael, Meshullam, Sheba, Jorai, Jachan, Zia, and Eber--seven in all.", 
                "num": 13
              }, 
              {
                "text": "These were the children of Abihail the son of Huri, the son of Jaroah, the son of Gilead, the son of Michael, the son of Jeshishai, the son of Jahdo, the son of Buz;", 
                "num": 14
              }, 
              {
                "text": "Ahi the son of Abdiel, the son of Guni, was chief of their father's house.", 
                "num": 15
              }, 
              {
                "text": "And the Gadites dwelt in Gilead, in Bashan and in its villages, and in all the common-lands of Sharon within their borders.", 
                "num": 16
              }, 
              {
                "text": "All these were registered by genealogies in the days of Jotham king of Judah, and in the days of Jeroboam king of Israel.", 
                "num": 17
              }, 
              {
                "text": "The sons of Reuben, the Gadites, and half the tribe of Manasseh had forty-four thousand seven hundred and sixty valiant men, men able to bear shield and sword, to shoot with the bow, and skillful in war, who went to war.", 
                "num": 18
              }, 
              {
                "text": "They made war with the Hagrites, Jetur, Naphish, and Nodab.", 
                "num": 19
              }, 
              {
                "text": "And they were helped against them, and the Hagrites were delivered into their hand, and all who were with them, for they cried out to God in the battle. He heeded their prayer, because they put their trust in Him.", 
                "num": 20
              }, 
              {
                "text": "Then they took away their livestock--fifty thousand of their camels, two hundred and fifty thousand of their sheep, and two thousand of their donkeys--also one hundred thousand of their men;", 
                "num": 21
              }, 
              {
                "text": "for many fell dead, because the war was God's. And they dwelt in their place until the captivity.", 
                "num": 22
              }, 
              {
                "text": "So the children of the half-tribe of Manasseh dwelt in the land. Their numbers increased from Bashan to Baal Hermon, that is, to Senir, or Mount Hermon.", 
                "num": 23
              }, 
              {
                "text": "These were the heads of their fathers' houses: Epher, Ishi, Eliel, Azriel, Jeremiah, Hodaviah, and Jahdiel. They were mighty men of valor, famous men, and heads of their fathers' houses.", 
                "num": 24
              }, 
              {
                "text": "And they were unfaithful to the God of their fathers, and played the harlot after the gods of the peoples of the land, whom God had destroyed before them.", 
                "num": 25
              }, 
              {
                "text": "So the God of Israel stirred up the spirit of Pul king of Assyria, that is, Tiglath-Pileser king of Assyria. He carried the Reubenites, the Gadites, and the half-tribe of Manasseh into captivity. He took them to Halah, Habor, Hara, and the river of Gozan to this day.", 
                "num": 26
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "The sons of Levi were Gershon, Kohath, and Merari.", 
                "num": 1
              }, 
              {
                "text": "The sons of Kohath were Amram, Izhar, Hebron, and Uzziel.", 
                "num": 2
              }, 
              {
                "text": "The children of Amram were Aaron, Moses, and Miriam. And the sons of Aaron were Nadab, Abihu, Eleazar, and Ithamar.", 
                "num": 3
              }, 
              {
                "text": "Eleazar begot Phinehas, and Phinehas begot Abishua;", 
                "num": 4
              }, 
              {
                "text": "Abishua begot Bukki, and Bukki begot Uzzi;", 
                "num": 5
              }, 
              {
                "text": "Uzzi begot Zerahiah, and Zerahiah begot Meraioth;", 
                "num": 6
              }, 
              {
                "text": "Meraioth begot Amariah, and Amariah begot Ahitub;", 
                "num": 7
              }, 
              {
                "text": "Ahitub begot Zadok, and Zadok begot Ahimaaz;", 
                "num": 8
              }, 
              {
                "text": "Ahimaaz begot Azariah, and Azariah begot Johanan;", 
                "num": 9
              }, 
              {
                "text": "Johanan begot Azariah (it was he who ministered as priest in the temple that Solomon built in Jerusalem);", 
                "num": 10
              }, 
              {
                "text": "Azariah begot Amariah, and Amariah begot Ahitub;", 
                "num": 11
              }, 
              {
                "text": "Ahitub begot Zadok, and Zadok begot Shallum;", 
                "num": 12
              }, 
              {
                "text": "Shallum begot Hilkiah, and Hilkiah begot Azariah;", 
                "num": 13
              }, 
              {
                "text": "Azariah begot Seraiah, and Seraiah begot Jehozadak.", 
                "num": 14
              }, 
              {
                "text": "Jehozadak went into captivity when the LORD carried Judah and Jerusalem into captivity by the hand of Nebuchadnezzar.", 
                "num": 15
              }, 
              {
                "text": "The sons of Levi were Gershon, Kohath, and Merari.", 
                "num": 16
              }, 
              {
                "text": "These are the names of the sons of Gershon: Libni and Shimei.", 
                "num": 17
              }, 
              {
                "text": "The sons of Kohath were Amram, Izhar, Hebron, and Uzziel.", 
                "num": 18
              }, 
              {
                "text": "The sons of Merari were Mahli and Mushi. Now these are the families of the Levites according to their fathers:", 
                "num": 19
              }, 
              {
                "text": "Of Gershon were Libni his son, Jahath his son, Zimmah his son,", 
                "num": 20
              }, 
              {
                "text": "Joah his son, Iddo his son, Zerah his son, and Jeatherai his son.", 
                "num": 21
              }, 
              {
                "text": "The sons of Kohath were Amminadab his son, Korah his son, Assir his son,", 
                "num": 22
              }, 
              {
                "text": "Elkanah his son, Ebiasaph his son, Assir his son,", 
                "num": 23
              }, 
              {
                "text": "Tahath his son, Uriel his son, Uzziah his son, and Shaul his son.", 
                "num": 24
              }, 
              {
                "text": "The sons of Elkanah were Amasai and Ahimoth.", 
                "num": 25
              }, 
              {
                "text": "As for Elkanah, the sons of Elkanah were Zophai his son, Nahath his son,", 
                "num": 26
              }, 
              {
                "text": "Eliab his son, Jeroham his son, and Elkanah his son.", 
                "num": 27
              }, 
              {
                "text": "The sons of Samuel were Joel the firstborn, and Abijah the second.", 
                "num": 28
              }, 
              {
                "text": "The sons of Merari were Mahli, Libni his son, Shimei his son, Uzzah his son,", 
                "num": 29
              }, 
              {
                "text": "Shimea his son, Haggiah his son, and Asaiah his son.", 
                "num": 30
              }, 
              {
                "text": "Now these are the men whom David appointed over the service of song in the house of the LORD, after the ark came to rest.", 
                "num": 31
              }, 
              {
                "text": "They were ministering with music before the dwelling place of the tabernacle of meeting, until Solomon had built the house of the LORD in Jerusalem, and they served in their office according to their order.", 
                "num": 32
              }, 
              {
                "text": "And these are the ones who ministered with their sons: Of the sons of the Kohathites were Heman the singer, the son of Joel, the son of Samuel,", 
                "num": 33
              }, 
              {
                "text": "the son of Elkanah, the son of Jeroham, the son of Eliel, the son of Toah,", 
                "num": 34
              }, 
              {
                "text": "the son of Zuph, the son of Elkanah, the son of Mahath, the son of Amasai,", 
                "num": 35
              }, 
              {
                "text": "the son of Elkanah, the son of Joel, the son of Azariah, the son of Zephaniah,", 
                "num": 36
              }, 
              {
                "text": "the son of Tahath, the son of Assir, the son of Ebiasaph, the son of Korah,", 
                "num": 37
              }, 
              {
                "text": "the son of Izhar, the son of Kohath, the son of Levi, the son of Israel.", 
                "num": 38
              }, 
              {
                "text": "And his brother Asaph, who stood at his right hand, was Asaph the son of Berachiah, the son of Shimea,", 
                "num": 39
              }, 
              {
                "text": "the son of Michael, the son of Baaseiah, the son of Malchijah,", 
                "num": 40
              }, 
              {
                "text": "the son of Ethni, the son of Zerah, the son of Adaiah,", 
                "num": 41
              }, 
              {
                "text": "the son of Ethan, the son of Zimmah, the son of Shimei,", 
                "num": 42
              }, 
              {
                "text": "the son of Jahath, the son of Gershon, the son of Levi.", 
                "num": 43
              }, 
              {
                "text": "Their brethren, the sons of Merari, on the left hand, were Ethan the son of Kishi, the son of Abdi, the son of Malluch,", 
                "num": 44
              }, 
              {
                "text": "the son of Hashabiah, the son of Amaziah, the son of Hilkiah,", 
                "num": 45
              }, 
              {
                "text": "the son of Amzi, the son of Bani, the son of Shamer,", 
                "num": 46
              }, 
              {
                "text": "the son of Mahli, the son of Mushi, the son of Merari, the son of Levi.", 
                "num": 47
              }, 
              {
                "text": "And their brethren, the Levites, were appointed to every kind of service of the tabernacle of the house of God.", 
                "num": 48
              }, 
              {
                "text": "But Aaron and his sons offered sacrifices on the altar of burnt offering and on the altar of incense, for all the work of the Most Holy Place, and to make atonement for Israel, according to all that Moses the servant of God had commanded.", 
                "num": 49
              }, 
              {
                "text": "Now these are the sons of Aaron: Eleazar his son, Phinehas his son, Abishua his son,", 
                "num": 50
              }, 
              {
                "text": "Bukki his son, Uzzi his son, Zerahiah his son,", 
                "num": 51
              }, 
              {
                "text": "Meraioth his son, Amariah his son, Ahitub his son,", 
                "num": 52
              }, 
              {
                "text": "Zadok his son, and Ahimaaz his son.", 
                "num": 53
              }, 
              {
                "text": "Now these are their dwelling places throughout their settlements in their territory, for they were given by lot to the sons of Aaron, of the family of the Kohathites:", 
                "num": 54
              }, 
              {
                "text": "They gave them Hebron in the land of Judah, with its surrounding common-lands.", 
                "num": 55
              }, 
              {
                "text": "But the fields of the city and its villages they gave to Caleb the son of Jephunneh.", 
                "num": 56
              }, 
              {
                "text": "And to the sons of Aaron they gave one of the cities of refuge, Hebron; also Libnah with its common-lands, Jattir, Eshtemoa with its common-lands,", 
                "num": 57
              }, 
              {
                "text": "Hilen with its common-lands, Debir with its common-lands,", 
                "num": 58
              }, 
              {
                "text": "Ashan with its common-lands, and Beth Shemesh with its common-lands.", 
                "num": 59
              }, 
              {
                "text": "And from the tribe of Benjamin: Geba with its common-lands, Alemeth with its common-lands, and Anathoth with its common-lands. All their cities among their families were thirteen.", 
                "num": 60
              }, 
              {
                "text": "To the rest of the family of the tribe of the Kohathites they gave by lot ten cities from half the tribe of Manasseh.", 
                "num": 61
              }, 
              {
                "text": "And to the sons of Gershon, throughout their families, they gave thirteen cities from the tribe of Issachar, from the tribe of Asher, from the tribe of Naphtali, and from the tribe of Manasseh in Bashan.", 
                "num": 62
              }, 
              {
                "text": "To the sons of Merari, throughout their families, they gave twelve cities from the tribe of Reuben, from the tribe of Gad, and from the tribe of Zebulun.", 
                "num": 63
              }, 
              {
                "text": "So the children of Israel gave these cities with their common-lands to the Levites.", 
                "num": 64
              }, 
              {
                "text": "And they gave by lot from the tribe of the children of Judah, from the tribe of the children of Simeon, and from the tribe of the children of Benjamin these cities which are called by their names.", 
                "num": 65
              }, 
              {
                "text": "Now some of the families of the sons of Kohath were given cities as their territory from the tribe of Ephraim.", 
                "num": 66
              }, 
              {
                "text": "And they gave them one of the cities of refuge, Shechem with its common-lands, in the mountains of Ephraim, also Gezer with its common-lands,", 
                "num": 67
              }, 
              {
                "text": "Jokmeam with its common-lands, Beth Horon with its common-lands,", 
                "num": 68
              }, 
              {
                "text": "Aijalon with its common-lands, and Gath Rimmon with its common-lands.", 
                "num": 69
              }, 
              {
                "text": "And from the half-tribe of Manasseh: Aner with its common-lands and Bileam with its common-lands, for the rest of the family of the sons of Kohath.", 
                "num": 70
              }, 
              {
                "text": "From the family of the half-tribe of Manasseh the sons of Gershon were given Golan in Bashan with its common-lands and Ashtaroth with its common-lands.", 
                "num": 71
              }, 
              {
                "text": "And from the tribe of Issachar: Kedesh with its common-lands, Daberath with its common-lands,", 
                "num": 72
              }, 
              {
                "text": "Ramoth with its common-lands, and Anem with its common-lands.", 
                "num": 73
              }, 
              {
                "text": "And from the tribe of Asher: Mashal with its common-lands, Abdon with its common-lands,", 
                "num": 74
              }, 
              {
                "text": "Hukok with its common-lands, and Rehob with its common-lands.", 
                "num": 75
              }, 
              {
                "text": "And from the tribe of Naphtali: Kedesh in Galilee with its common-lands, Hammon with its common-lands, and Kirjathaim with its common-lands.", 
                "num": 76
              }, 
              {
                "text": "From the tribe of Zebulun the rest of the children of Merari were given Rimmon with its common-lands and Tabor with its common-lands.", 
                "num": 77
              }, 
              {
                "text": "And on the other side of the Jordan, across from Jericho, on the east side of the Jordan, they were given from the tribe of Reuben: Bezer in the wilderness with its common-lands, Jahzah with its common-lands,", 
                "num": 78
              }, 
              {
                "text": "Kedemoth with its common-lands, and Mephaath with its common-lands.", 
                "num": 79
              }, 
              {
                "text": "And from the tribe of Gad: Ramoth in Gilead with its common-lands, Mahanaim with its common-lands,", 
                "num": 80
              }, 
              {
                "text": "Heshbon with its common-lands, and Jazer with its common-lands.", 
                "num": 81
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "The sons of Issachar were Tola, Puah, Jashub, and Shimron--four in all.", 
                "num": 1
              }, 
              {
                "text": "The sons of Tola were Uzzi, Rephaiah, Jeriel, Jahmai, Jibsam, and Shemuel, heads of their father's house. The sons of Tola were mighty men of valor in their generations; their number in the days of David was twenty-two thousand six hundred.", 
                "num": 2
              }, 
              {
                "text": "The son of Uzzi was Izrahiah, and the sons of Izrahiah were Michael, Obadiah, Joel, and Ishiah. All five of them were chief men.", 
                "num": 3
              }, 
              {
                "text": "And with them, by their generations, according to their fathers' houses, were thirty-six thousand troops ready for war; for they had many wives and sons.", 
                "num": 4
              }, 
              {
                "text": "Now their brethren among all the families of Issachar were mighty men of valor, listed by their genealogies, eighty-seven thousand in all.", 
                "num": 5
              }, 
              {
                "text": "The sons of Benjamin were Bela, Becher, and Jediael--three in all.", 
                "num": 6
              }, 
              {
                "text": "The sons of Bela were Ezbon, Uzzi, Uzziel, Jerimoth, and Iri--five in all. They were heads of their fathers' houses, and they were listed by their genealogies, twenty-two thousand and thirty-four mighty men of valor.", 
                "num": 7
              }, 
              {
                "text": "The sons of Becher were Zemirah, Joash, Eliezer, Elioenai, Omri, Jerimoth, Abijah, Anathoth, and Alemeth. All these are the sons of Becher.", 
                "num": 8
              }, 
              {
                "text": "And they were recorded by genealogy according to their generations, heads of their fathers' houses, twenty thousand two hundred mighty men of valor.", 
                "num": 9
              }, 
              {
                "text": "The son of Jediael was Bilhan, and the sons of Bilhan were Jeush, Benjamin, Ehud, Chenaanah, Zethan, Tharshish, and Ahishahar.", 
                "num": 10
              }, 
              {
                "text": "All these sons of Jediael were heads of their fathers' houses; there were seventeen thousand two hundred mighty men of valor fit to go out for war and battle.", 
                "num": 11
              }, 
              {
                "text": "Shuppim and Huppim were the sons of Ir, and Hushim was the son of Aher.", 
                "num": 12
              }, 
              {
                "text": "The sons of Naphtali were Jahziel, Guni, Jezer, and Shallum, the sons of Bilhah.", 
                "num": 13
              }, 
              {
                "text": "The descendants of Manasseh: his Syrian concubine bore him Machir the father of Gilead, the father of Asriel.", 
                "num": 14
              }, 
              {
                "text": "Machir took as his wife the sister of Huppim and Shuppim, whose name was Maachah. The name of Gilead's grandson was Zelophehad, but Zelophehad begot only daughters.", 
                "num": 15
              }, 
              {
                "text": "(Maachah the wife of Machir bore a son, and she called his name Peresh. The name of his brother was Sheresh, and his sons were Ulam and Rakem.", 
                "num": 16
              }, 
              {
                "text": "The son of Ulam was Bedan.) These were the descendants of Gilead the son of Machir, the son of Manasseh.", 
                "num": 17
              }, 
              {
                "text": "His sister Hammoleketh bore Ishhod, Abiezer, and Mahlah.", 
                "num": 18
              }, 
              {
                "text": "And the sons of Shemida were Ahian, Shechem, Likhi, and Aniam.", 
                "num": 19
              }, 
              {
                "text": "The sons of Ephraim were Shuthelah, Bered his son, Tahath his son, Eladah his son, Tahath his son,", 
                "num": 20
              }, 
              {
                "text": "Zabad his son, Shuthelah his son, and Ezer and Elead. The men of Gath who were born in that land killed them because they came down to take away their cattle.", 
                "num": 21
              }, 
              {
                "text": "Then Ephraim their father mourned many days, and his brethren came to comfort him.", 
                "num": 22
              }, 
              {
                "text": "And when he went in to his wife, she conceived and bore a son; and he called his name Beriah, because tragedy had come upon his house.", 
                "num": 23
              }, 
              {
                "text": "Now his daughter was Sheerah, who built Lower and Upper Beth Horon and Uzzen Sheerah;", 
                "num": 24
              }, 
              {
                "text": "and Rephah was his son, as well as Resheph, and Telah his son, Tahan his son,", 
                "num": 25
              }, 
              {
                "text": "Laadan his son, Ammihud his son, Elishama his son,", 
                "num": 26
              }, 
              {
                "text": "Nun his son, and Joshua his son.", 
                "num": 27
              }, 
              {
                "text": "Now their possessions and dwelling places were Bethel and its towns: to the east Naaran, to the west Gezer and its towns, and Shechem and its towns, as far as Ayyah and its towns;", 
                "num": 28
              }, 
              {
                "text": "and by the borders of the children of Manasseh were Beth Shean and its towns, Taanach and its towns, Megiddo and its towns, Dor and its towns. In these dwelt the children of Joseph, the son of Israel.", 
                "num": 29
              }, 
              {
                "text": "The sons of Asher were Imnah, Ishvah, Ishvi, Beriah, and their sister Serah.", 
                "num": 30
              }, 
              {
                "text": "The sons of Beriah were Heber and Malchiel, who was the father of Birzaith.", 
                "num": 31
              }, 
              {
                "text": "And Heber begot Japhlet, Shomer, Hotham, and their sister Shua.", 
                "num": 32
              }, 
              {
                "text": "The sons of Japhlet were Pasach, Bimhal, and Ashvath. These were the children of Japhlet.", 
                "num": 33
              }, 
              {
                "text": "The sons of Shemer were Ahi, Rohgah, Jehubbah, and Aram.", 
                "num": 34
              }, 
              {
                "text": "And the sons of his brother Helem were Zophah, Imna, Shelesh, and Amal.", 
                "num": 35
              }, 
              {
                "text": "The sons of Zophah were Suah, Harnepher, Shual, Beri, Imrah,", 
                "num": 36
              }, 
              {
                "text": "Bezer, Hod, Shamma, Shilshah, Jithran, and Beera.", 
                "num": 37
              }, 
              {
                "text": "The sons of Jether were Jephunneh, Pispah, and Ara.", 
                "num": 38
              }, 
              {
                "text": "The sons of Ulla were Arah, Haniel, and Rizia.", 
                "num": 39
              }, 
              {
                "text": "All these were the children of Asher, heads of their fathers' houses, choice men, mighty men of valor, chief leaders. And they were recorded by genealogies among the army fit for battle; their number was twenty-six thousand.", 
                "num": 40
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now Benjamin begot Bela his firstborn, Ashbel the second, Aharah the third,", 
                "num": 1
              }, 
              {
                "text": "Nohah the fourth, and Rapha the fifth.", 
                "num": 2
              }, 
              {
                "text": "The sons of Bela were Addar, Gera, Abihud,", 
                "num": 3
              }, 
              {
                "text": "Abishua, Naaman, Ahoah,", 
                "num": 4
              }, 
              {
                "text": "Gera, Shephuphan, and Huram.", 
                "num": 5
              }, 
              {
                "text": "These are the sons of Ehud, who were the heads of the fathers' houses of the inhabitants of Geba, and who forced them to move to Manahath:", 
                "num": 6
              }, 
              {
                "text": "Naaman, Ahijah, and Gera who forced them to move. He begot Uzza and Ahihud.", 
                "num": 7
              }, 
              {
                "text": "Also Shaharaim had children in the country of Moab, after he had sent away Hushim and Baara his wives.", 
                "num": 8
              }, 
              {
                "text": "By Hodesh his wife he begot Jobab, Zibia, Mesha, Malcam,", 
                "num": 9
              }, 
              {
                "text": "Jeuz, Sachiah, and Mirmah. These were his sons, heads of their fathers' houses.", 
                "num": 10
              }, 
              {
                "text": "And by Hushim he begot Abitub and Elpaal.", 
                "num": 11
              }, 
              {
                "text": "The sons of Elpaal were Eber, Misham, and Shemed, who built Ono and Lod with its towns;", 
                "num": 12
              }, 
              {
                "text": "and Beriah and Shema, who were heads of their fathers' houses of the inhabitants of Aijalon, who drove out the inhabitants of Gath.", 
                "num": 13
              }, 
              {
                "text": "Ahio, Shashak, Jeremoth,", 
                "num": 14
              }, 
              {
                "text": "Zebadiah, Arad, Eder,", 
                "num": 15
              }, 
              {
                "text": "Michael, Ispah, and Joha were the sons of Beriah.", 
                "num": 16
              }, 
              {
                "text": "Zebadiah, Meshullam, Hizki, Heber,", 
                "num": 17
              }, 
              {
                "text": "Ishmerai, Jizliah, and Jobab were the sons of Elpaal.", 
                "num": 18
              }, 
              {
                "text": "Jakim, Zichri, Zabdi,", 
                "num": 19
              }, 
              {
                "text": "Elienai, Zillethai, Eliel,", 
                "num": 20
              }, 
              {
                "text": "Adaiah, Beraiah, and Shimrath were the sons of Shimei.", 
                "num": 21
              }, 
              {
                "text": "Ishpan, Eber, Eliel,", 
                "num": 22
              }, 
              {
                "text": "Abdon, Zichri, Hanan,", 
                "num": 23
              }, 
              {
                "text": "Hananiah, Elam, Antothijah,", 
                "num": 24
              }, 
              {
                "text": "Iphdeiah, and Penuel were the sons of Shashak.", 
                "num": 25
              }, 
              {
                "text": "Shamsherai, Shehariah, Athaliah,", 
                "num": 26
              }, 
              {
                "text": "Jaareshiah, Elijah, and Zichri were the sons of Jeroham.", 
                "num": 27
              }, 
              {
                "text": "These were heads of the fathers' houses by their generations, chief men. These dwelt in Jerusalem.", 
                "num": 28
              }, 
              {
                "text": "Now the father of Gibeon, whose wife's name was Maacah, dwelt at Gibeon.", 
                "num": 29
              }, 
              {
                "text": "And his firstborn son was Abdon, then Zur, Kish, Baal, Nadab,", 
                "num": 30
              }, 
              {
                "text": "Gedor, Ahio, Zecher,", 
                "num": 31
              }, 
              {
                "text": "and Mikloth, who begot Shimeah. They also dwelt alongside their relatives in Jerusalem, with their brethren.", 
                "num": 32
              }, 
              {
                "text": "Ner begot Kish, Kish begot Saul, and Saul begot Jonathan, Malchishua, Abinadab, and Esh-Baal.", 
                "num": 33
              }, 
              {
                "text": "The son of Jonathan was Merib-Baal, and Merib-Baal begot Micah.", 
                "num": 34
              }, 
              {
                "text": "The sons of Micah were Pithon, Melech, Tarea, and Ahaz.", 
                "num": 35
              }, 
              {
                "text": "And Ahaz begot Jehoaddah; Jehoaddah begot Alemeth, Azmaveth, and Zimri; and Zimri begot Moza.", 
                "num": 36
              }, 
              {
                "text": "Moza begot Binea, Raphah his son, Eleasah his son, and Azel his son.", 
                "num": 37
              }, 
              {
                "text": "Azel had six sons whose names were these: Azrikam, Bocheru, Ishmael, Sheariah, Obadiah, and Hanan. All these were the sons of Azel.", 
                "num": 38
              }, 
              {
                "text": "And the sons of Eshek his brother were Ulam his firstborn, Jeush the second, and Eliphelet the third.", 
                "num": 39
              }, 
              {
                "text": "The sons of Ulam were mighty men of valor--archers. They had many sons and grandsons, one hundred and fifty in all. These were all sons of Benjamin.", 
                "num": 40
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "So all Israel was recorded by genealogies, and indeed, they were inscribed in the book of the kings of Israel. But Judah was carried away captive to Babylon because of their unfaithfulness.", 
                "num": 1
              }, 
              {
                "text": "And the first inhabitants who dwelt in their possessions in their cities were Israelites, priests, Levites, and the Nethinim.", 
                "num": 2
              }, 
              {
                "text": "Now in Jerusalem the children of Judah dwelt, and some of the children of Benjamin, and of the children of Ephraim and Manasseh:", 
                "num": 3
              }, 
              {
                "text": "Uthai the son of Ammihud, the son of Omri, the son of Imri, the son of Bani, of the descendants of Perez, the son of Judah.", 
                "num": 4
              }, 
              {
                "text": "Of the Shilonites: Asaiah the firstborn and his sons.", 
                "num": 5
              }, 
              {
                "text": "Of the sons of Zerah: Jeuel, and their brethren--six hundred and ninety.", 
                "num": 6
              }, 
              {
                "text": "Of the sons of Benjamin: Sallu the son of Meshullam, the son of Hodaviah, the son of Hassenuah;", 
                "num": 7
              }, 
              {
                "text": "Ibneiah the son of Jeroham; Elah the son of Uzzi, the son of Michri; Meshullam the son of Shephatiah, the son of Reuel, the son of Ibnijah;", 
                "num": 8
              }, 
              {
                "text": "and their brethren, according to their generations--nine hundred and fifty-six. All these men were heads of a father's house in their fathers' houses.", 
                "num": 9
              }, 
              {
                "text": "Of the priests: Jedaiah, Jehoiarib, and Jachin;", 
                "num": 10
              }, 
              {
                "text": "Azariah the son of Hilkiah, the son of Meshullam, the son of Zadok, the son of Meraioth, the son of Ahitub, the officer over the house of God;", 
                "num": 11
              }, 
              {
                "text": "Adaiah the son of Jeroham, the son of Pashur, the son of Malchijah; Maasai the son of Adiel, the son of Jahzerah, the son of Meshullam, the son of Meshillemith, the son of Immer;", 
                "num": 12
              }, 
              {
                "text": "and their brethren, heads of their fathers' houses--one thousand seven hundred and sixty. They were very able men for the work of the service of the house of God.", 
                "num": 13
              }, 
              {
                "text": "Of the Levites: Shemaiah the son of Hasshub, the son of Azrikam, the son of Hashabiah, of the sons of Merari;", 
                "num": 14
              }, 
              {
                "text": "Bakbakkar, Heresh, Galal, and Mattaniah the son of Micah, the son of Zichri, the son of Asaph;", 
                "num": 15
              }, 
              {
                "text": "Obadiah the son of Shemaiah, the son of Galal, the son of Jeduthun; and Berechiah the son of Asa, the son of Elkanah, who lived in the villages of the Netophathites.", 
                "num": 16
              }, 
              {
                "text": "And the gatekeepers were Shallum, Akkub, Talmon, Ahiman, and their brethren. Shallum was the chief.", 
                "num": 17
              }, 
              {
                "text": "Until then they had been gatekeepers for the camps of the children of Levi at the King's Gate on the east.", 
                "num": 18
              }, 
              {
                "text": "Shallum the son of Kore, the son of Ebiasaph, the son of Korah, and his brethren, from his father's house, the Korahites, were in charge of the work of the service, gatekeepers of the tabernacle. Their fathers had been keepers of the entrance to the camp of the LORD.", 
                "num": 19
              }, 
              {
                "text": "And Phinehas the son of Eleazar had been the officer over them in time past; the LORD was with him.", 
                "num": 20
              }, 
              {
                "text": "Zechariah the son of Meshelemiah was keeper of the door of the tabernacle of meeting.", 
                "num": 21
              }, 
              {
                "text": "All those chosen as gatekeepers were two hundred and twelve. They were recorded by their genealogy, in their villages. David and Samuel the seer had appointed them to their trusted office.", 
                "num": 22
              }, 
              {
                "text": "So they and their children were in charge of the gates of the house of the LORD, the house of the tabernacle, by assignment.", 
                "num": 23
              }, 
              {
                "text": "The gatekeepers were assigned to the four directions: the east, west, north, and south.", 
                "num": 24
              }, 
              {
                "text": "And their brethren in their villages had to come with them from time to time for seven days.", 
                "num": 25
              }, 
              {
                "text": "For in this trusted office were four chief gatekeepers; they were Levites. And they had charge over the chambers and treasuries of the house of God.", 
                "num": 26
              }, 
              {
                "text": "And they lodged all around the house of God because they had the responsibility, and they were in charge of opening it every morning.", 
                "num": 27
              }, 
              {
                "text": "Now some of them were in charge of the serving vessels, for they brought them in and took them out by count.", 
                "num": 28
              }, 
              {
                "text": "Some of them were appointed over the furnishings and over all the implements of the sanctuary, and over the fine flour and the wine and the oil and the incense and the spices.", 
                "num": 29
              }, 
              {
                "text": "And some of the sons of the priests made the ointment of the spices.", 
                "num": 30
              }, 
              {
                "text": "Mattithiah of the Levites, the firstborn of Shallum the Korahite, had the trusted office over the things that were baked in the pans.", 
                "num": 31
              }, 
              {
                "text": "And some of their brethren of the sons of the Kohathites were in charge of preparing the showbread for every Sabbath.", 
                "num": 32
              }, 
              {
                "text": "These are the singers, heads of the fathers' houses of the Levites, who lodged in the chambers, and were free from other duties; for they were employed in that work day and night.", 
                "num": 33
              }, 
              {
                "text": "These heads of the fathers' houses of the Levites were heads throughout their generations. They dwelt at Jerusalem.", 
                "num": 34
              }, 
              {
                "text": "Jeiel the father of Gibeon, whose wife's name was Maacah, dwelt at Gibeon.", 
                "num": 35
              }, 
              {
                "text": "His firstborn son was Abdon, then Zur, Kish, Baal, Ner, Nadab,", 
                "num": 36
              }, 
              {
                "text": "Gedor, Ahio, Zechariah, and Mikloth.", 
                "num": 37
              }, 
              {
                "text": "And Mikloth begot Shimeam. They also dwelt alongside their relatives in Jerusalem, with their brethren.", 
                "num": 38
              }, 
              {
                "text": "Ner begot Kish, Kish begot Saul, and Saul begot Jonathan, Malchishua, Abinadab, and Esh-Baal.", 
                "num": 39
              }, 
              {
                "text": "The son of Jonathan was Merib-Baal, and Merib-Baal begot Micah.", 
                "num": 40
              }, 
              {
                "text": "The sons of Micah were Pithon, Melech, Tahrea, and Ahaz.", 
                "num": 41
              }, 
              {
                "text": "And Ahaz begot Jarah; Jarah begot Alemeth, Azmaveth, and Zimri; and Zimri begot Moza;", 
                "num": 42
              }, 
              {
                "text": "Moza begot Binea, Rephaiah his son, Eleasah his son, and Azel his son.", 
                "num": 43
              }, 
              {
                "text": "And Azel had six sons whose names were these: Azrikam, Bocheru, Ishmael, Sheariah, Obadiah, and Hanan; these were the sons of Azel.", 
                "num": 44
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now the Philistines fought against Israel; and the men of Israel fled from before the Philistines, and fell slain on Mount Gilboa.", 
                "num": 1
              }, 
              {
                "text": "Then the Philistines followed hard after Saul and his sons. And the Philistines killed Jonathan, Abinadab, and Malchishua, Saul's sons.", 
                "num": 2
              }, 
              {
                "text": "The battle became fierce against Saul. The archers hit him, and he was wounded by the archers.", 
                "num": 3
              }, 
              {
                "text": "Then Saul said to his armorbearer, \"Draw your sword, and thrust me through with it, lest these uncircumcised men come and abuse me.\" But his armorbearer would not, for he was greatly afraid. Therefore Saul took a sword and fell on it.", 
                "num": 4
              }, 
              {
                "text": "And when his armorbearer saw that Saul was dead, he also fell on his sword and died.", 
                "num": 5
              }, 
              {
                "text": "So Saul and his three sons died, and all his house died together.", 
                "num": 6
              }, 
              {
                "text": "And when all the men of Israel who were in the valley saw that they had fled and that Saul and his sons were dead, they forsook their cities and fled; then the Philistines came and dwelt in them.", 
                "num": 7
              }, 
              {
                "text": "So it happened the next day, when the Philistines came to strip the slain, that they found Saul and his sons fallen on Mount Gilboa.", 
                "num": 8
              }, 
              {
                "text": "And they stripped him and took his head and his armor, and sent word throughout the land of the Philistines to proclaim the news in the temple of their idols and among the people.", 
                "num": 9
              }, 
              {
                "text": "Then they put his armor in the temple of their gods, and fastened his head in the temple of Dagon.", 
                "num": 10
              }, 
              {
                "text": "And when all Jabesh Gilead heard all that the Philistines had done to Saul,", 
                "num": 11
              }, 
              {
                "text": "all the valiant men arose and took the body of Saul and the bodies of his sons; and they brought them to Jabesh, and buried their bones under the tamarisk tree at Jabesh, and fasted seven days.", 
                "num": 12
              }, 
              {
                "text": "So Saul died for his unfaithfulness which he had committed against the LORD, because he did not keep the word of the LORD, and also because he consulted a medium for guidance.", 
                "num": 13
              }, 
              {
                "text": "But he did not inquire of the LORD; therefore He killed him, and turned the kingdom over to David the son of Jesse.", 
                "num": 14
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Then all Israel came together to David at Hebron, saying, \"Indeed we are your bone and your flesh.", 
                "num": 1
              }, 
              {
                "text": "Also, in time past, even when Saul was king, you were the one who led Israel out and brought them in; and the LORD your God said to you, \"You shall shepherd My people Israel, and be ruler over My people Israel.\"'", 
                "num": 2
              }, 
              {
                "text": "Therefore all the elders of Israel came to the king at Hebron, and David made a covenant with them at Hebron before the LORD. And they anointed David king over Israel, according to the word of the LORD by Samuel.", 
                "num": 3
              }, 
              {
                "text": "And David and all Israel went to Jerusalem, which is Jebus, where the Jebusites were, the inhabitants of the land.", 
                "num": 4
              }, 
              {
                "text": "But the inhabitants of Jebus said to David, \"You shall not come in here!\" Nevertheless David took the stronghold of Zion (that is, the City of David).", 
                "num": 5
              }, 
              {
                "text": "Now David said, \"Whoever attacks the Jebusites first shall be chief and captain.\" And Joab the son of Zeruiah went up first, and became chief.", 
                "num": 6
              }, 
              {
                "text": "Then David dwelt in the stronghold; therefore they called it the City of David.", 
                "num": 7
              }, 
              {
                "text": "And he built the city around it, from the Millo to the surrounding area. Joab repaired the rest of the city.", 
                "num": 8
              }, 
              {
                "text": "So David went on and became great, and the LORD of hosts was with him.", 
                "num": 9
              }, 
              {
                "text": "Now these were the heads of the mighty men whom David had, who strengthened themselves with him in his kingdom, with all Israel, to make him king, according to the word of the LORD concerning Israel.", 
                "num": 10
              }, 
              {
                "text": "And this is the number of the mighty men whom David had: Jashobeam the son of a Hachmonite, chief of the captains; he had lifted up his spear against three hundred, killed by him at one time.", 
                "num": 11
              }, 
              {
                "text": "After him was Eleazar the son of Dodo, the Ahohite, who was one of the three mighty men.", 
                "num": 12
              }, 
              {
                "text": "He was with David at Pasdammim. Now there the Philistines were gathered for battle, and there was a piece of ground full of barley. So the people fled from the Philistines.", 
                "num": 13
              }, 
              {
                "text": "But they stationed themselves in the middle of that field, defended it, and killed the Philistines. So the LORD brought about a great victory.", 
                "num": 14
              }, 
              {
                "text": "Now three of the thirty chief men went down to the rock to David, into the cave of Adullam; and the army of the Philistines encamped in the Valley of Rephaim.", 
                "num": 15
              }, 
              {
                "text": "David was then in the stronghold, and the garrison of the Philistines was then in Bethlehem.", 
                "num": 16
              }, 
              {
                "text": "And David said with longing, \"Oh, that someone would give me a drink of water from the well of Bethlehem, which is by the gate!\"", 
                "num": 17
              }, 
              {
                "text": "So the three broke through the camp of the Philistines, drew water from the well of Bethlehem that was by the gate, and took it and brought it to David. Nevertheless David would not drink it, but poured it out to the LORD.", 
                "num": 18
              }, 
              {
                "text": "And he said, \"Far be it from me, O my God, that I should do this! Shall I drink the blood of these men who have put their lives in jeopardy? For at the risk of their lives they brought it.\" Therefore he would not drink it. These things were done by the three mighty men.", 
                "num": 19
              }, 
              {
                "text": "Abishai the brother of Joab was chief of another three. He had lifted up his spear against three hundred men, killed them, and won a name among these three.", 
                "num": 20
              }, 
              {
                "text": "Of the three he was more honored than the other two men. Therefore he became their captain. However he did not attain to the first three.", 
                "num": 21
              }, 
              {
                "text": "Benaiah was the son of Jehoiada, the son of a valiant man from Kabzeel, who had done many deeds. He had killed two lion-like heroes of Moab. He also had gone down and killed a lion in the midst of a pit on a snowy day.", 
                "num": 22
              }, 
              {
                "text": "And he killed an Egyptian, a man of great height, five cubits tall. In the Egyptian's hand there was a spear like a weaver's beam; and he went down to him with a staff, wrested the spear out of the Egyptian's hand, and killed him with his own spear.", 
                "num": 23
              }, 
              {
                "text": "These things Benaiah the son of Jehoiada did, and won a name among three mighty men.", 
                "num": 24
              }, 
              {
                "text": "Indeed he was more honored than the thirty, but he did not attain to the first three. And David appointed him over his guard.", 
                "num": 25
              }, 
              {
                "text": "Also the mighty warriors were Asahel the brother of Joab, Elhanan the son of Dodo of Bethlehem,", 
                "num": 26
              }, 
              {
                "text": "Shammoth the Harorite, Helez the Pelonite,", 
                "num": 27
              }, 
              {
                "text": "Ira the son of Ikkesh the Tekoite, Abiezer the Anathothite,", 
                "num": 28
              }, 
              {
                "text": "Sibbechai the Hushathite, Ilai the Ahohite,", 
                "num": 29
              }, 
              {
                "text": "Maharai the Netophathite, Heled the son of Baanah the Netophathite,", 
                "num": 30
              }, 
              {
                "text": "Ithai the son of Ribai of Gibeah, of the sons of Benjamin, Benaiah the Pirathonite,", 
                "num": 31
              }, 
              {
                "text": "Hurai of the brooks of Gaash, Abiel the Arbathite,", 
                "num": 32
              }, 
              {
                "text": "Azmaveth the Baharumite, Eliahba the Shaalbonite,", 
                "num": 33
              }, 
              {
                "text": "the sons of Hashem the Gizonite, Jonathan the son of Shageh the Hararite,", 
                "num": 34
              }, 
              {
                "text": "Ahiam the son of Sacar the Hararite, Eliphal the son of Ur,", 
                "num": 35
              }, 
              {
                "text": "Hepher the Mecherathite, Ahijah the Pelonite,", 
                "num": 36
              }, 
              {
                "text": "Hezro the Carmelite, Naarai the son of Ezbai,", 
                "num": 37
              }, 
              {
                "text": "Joel the brother of Nathan, Mibhar the son of Hagri,", 
                "num": 38
              }, 
              {
                "text": "Zelek the Ammonite, Naharai the Berothite (the armorbearer of Joab the son of Zeruiah),", 
                "num": 39
              }, 
              {
                "text": "Ira the Ithrite, Gareb the Ithrite,", 
                "num": 40
              }, 
              {
                "text": "Uriah the Hittite, Zabad the son of Ahlai,", 
                "num": 41
              }, 
              {
                "text": "Adina the son of Shiza the Reubenite (a chief of the Reubenites) and thirty with him,", 
                "num": 42
              }, 
              {
                "text": "Hanan the son of Maachah, Joshaphat the Mithnite,", 
                "num": 43
              }, 
              {
                "text": "Uzzia the Ashterathite, Shama and Jeiel the sons of Hotham the Aroerite,", 
                "num": 44
              }, 
              {
                "text": "Jediael the son of Shimri, and Joha his brother, the Tizite,", 
                "num": 45
              }, 
              {
                "text": "Eliel the Mahavite, Jeribai and Joshaviah the sons of Elnaam, Ithmah the Moabite,", 
                "num": 46
              }, 
              {
                "text": "Eliel, Obed, and Jaasiel the Mezobaite.", 
                "num": 47
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now these were the men who came to David at Ziklag while he was still a fugitive from Saul the son of Kish; and they were among the mighty men, helpers in the war,", 
                "num": 1
              }, 
              {
                "text": "armed with bows, using both the right hand and the left in hurling stones and shooting arrows with the bow. They were of Benjamin, Saul's brethren.", 
                "num": 2
              }, 
              {
                "text": "The chief was Ahiezer, then Joash, the sons of Shemaah the Gibeathite; Jeziel and Pelet the sons of Azmaveth; Berachah, and Jehu the Anathothite;", 
                "num": 3
              }, 
              {
                "text": "Ishmaiah the Gibeonite, a mighty man among the thirty, and over the thirty; Jeremiah, Jahaziel, Johanan, and Jozabad the Gederathite;", 
                "num": 4
              }, 
              {
                "text": "Eluzai, Jerimoth, Bealiah, Shemariah, and Shephatiah the Haruphite;", 
                "num": 5
              }, 
              {
                "text": "Elkanah, Jisshiah, Azarel, Joezer, and Jashobeam, the Korahites;", 
                "num": 6
              }, 
              {
                "text": "and Joelah and Zebadiah the sons of Jeroham of Gedor.", 
                "num": 7
              }, 
              {
                "text": "Some Gadites joined David at the stronghold in the wilderness, mighty men of valor, men trained for battle, who could handle shield and spear, whose faces were like the faces of lions, and were as swift as gazelles on the mountains:", 
                "num": 8
              }, 
              {
                "text": "Ezer the first, Obadiah the second, Eliab the third,", 
                "num": 9
              }, 
              {
                "text": "Mishmannah the fourth, Jeremiah the fifth,", 
                "num": 10
              }, 
              {
                "text": "Attai the sixth, Eliel the seventh,", 
                "num": 11
              }, 
              {
                "text": "Johanan the eighth, Elzabad the ninth,", 
                "num": 12
              }, 
              {
                "text": "Jeremiah the tenth, and Machbanai the eleventh.", 
                "num": 13
              }, 
              {
                "text": "These were from the sons of Gad, captains of the army; the least was over a hundred, and the greatest was over a thousand.", 
                "num": 14
              }, 
              {
                "text": "These are the ones who crossed the Jordan in the first month, when it had overflowed all its banks; and they put to flight all those in the valleys, to the east and to the west.", 
                "num": 15
              }, 
              {
                "text": "Then some of the sons of Benjamin and Judah came to David at the stronghold.", 
                "num": 16
              }, 
              {
                "text": "And David went out to meet them, and answered and said to them, \"If you have come peaceably to me to help me, my heart will be united with you; but if to betray me to my enemies, since there is no wrong in my hands, may the God of our fathers look and bring judgment.\"", 
                "num": 17
              }, 
              {
                "text": "Then the Spirit came upon Amasai, chief of the captains, and he said: \"We are yours, O David; We are on your side, O son of Jesse! Peace, peace to you, And peace to your helpers! For your God helps you.\" So David received them, and made them captains of the troop.", 
                "num": 18
              }, 
              {
                "text": "And some from Manasseh defected to David when he was going with the Philistines to battle against Saul; but they did not help them, for the lords of the Philistines sent him away by agreement, saying, \"He may defect to his master Saul and endanger our heads.\"", 
                "num": 19
              }, 
              {
                "text": "When he went to Ziklag, those of Manasseh who defected to him were Adnah, Jozabad, Jediael, Michael, Jozabad, Elihu, and Zillethai, captains of the thousands who were from Manasseh.", 
                "num": 20
              }, 
              {
                "text": "And they helped David against the bands of raiders, for they were all mighty men of valor, and they were captains in the army.", 
                "num": 21
              }, 
              {
                "text": "For at that time they came to David day by day to help him, until it was a great army, like the army of God.", 
                "num": 22
              }, 
              {
                "text": "Now these were the numbers of the divisions that were equipped for war, and came to David at Hebron to turn over the kingdom of Saul to him, according to the word of the LORD:", 
                "num": 23
              }, 
              {
                "text": "of the sons of Judah bearing shield and spear, six thousand eight hundred armed for war;", 
                "num": 24
              }, 
              {
                "text": "of the sons of Simeon, mighty men of valor fit for war, seven thousand one hundred;", 
                "num": 25
              }, 
              {
                "text": "of the sons of Levi four thousand six hundred;", 
                "num": 26
              }, 
              {
                "text": "Jehoiada, the leader of the Aaronites, and with him three thousand seven hundred;", 
                "num": 27
              }, 
              {
                "text": "Zadok, a young man, a valiant warrior, and from his father's house twenty-two captains;", 
                "num": 28
              }, 
              {
                "text": "of the sons of Benjamin, relatives of Saul, three thousand (until then the greatest part of them had remained loyal to the house of Saul);", 
                "num": 29
              }, 
              {
                "text": "of the sons of Ephraim twenty thousand eight hundred, mighty men of valor, famous men throughout their father's house;", 
                "num": 30
              }, 
              {
                "text": "of the half-tribe of Manasseh eighteen thousand, who were designated by name to come and make David king;", 
                "num": 31
              }, 
              {
                "text": "of the sons of Issachar who had understanding of the times, to know what Israel ought to do, their chiefs were two hundred; and all their brethren were at their command;", 
                "num": 32
              }, 
              {
                "text": "of Zebulun there were fifty thousand who went out to battle, expert in war with all weapons of war, stouthearted men who could keep ranks;", 
                "num": 33
              }, 
              {
                "text": "of Naphtali one thousand captains, and with them thirty-seven thousand with shield and spear;", 
                "num": 34
              }, 
              {
                "text": "of the Danites who could keep battle formation, twenty-eight thousand six hundred;", 
                "num": 35
              }, 
              {
                "text": "of Asher, those who could go out to war, able to keep battle formation, forty thousand;", 
                "num": 36
              }, 
              {
                "text": "of the Reubenites and the Gadites and the half-tribe of Manasseh, from the other side of the Jordan, one hundred and twenty thousand armed for battle with every kind of weapon of war.", 
                "num": 37
              }, 
              {
                "text": "All these men of war, who could keep ranks, came to Hebron with a loyal heart, to make David king over all Israel; and all the rest of Israel were of one mind to make David king.", 
                "num": 38
              }, 
              {
                "text": "And they were there with David three days, eating and drinking, for their brethren had prepared for them.", 
                "num": 39
              }, 
              {
                "text": "Moreover those who were near to them, from as far away as Issachar and Zebulun and Naphtali, were bringing food on donkeys and camels, on mules and oxen--provisions of flour and cakes of figs and cakes of raisins, wine and oil and oxen and sheep abundantly, for there was joy in Israel.", 
                "num": 40
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Then David consulted with the captains of thousands and hundreds, and with every leader.", 
                "num": 1
              }, 
              {
                "text": "And David said to all the assembly of Israel, \"If it seems good to you, and if it is of the LORD our God, let us send out to our brethren everywhere who are left in all the land of Israel, and with them to the priests and Levites who are in their cities and their common-lands, that they may gather together to us;", 
                "num": 2
              }, 
              {
                "text": "and let us bring the ark of our God back to us, for we have not inquired at it since the days of Saul.\"", 
                "num": 3
              }, 
              {
                "text": "Then all the assembly said that they would do so, for the thing was right in the eyes of all the people.", 
                "num": 4
              }, 
              {
                "text": "So David gathered all Israel together, from Shihor in Egypt to as far as the entrance of Hamath, to bring the ark of God from Kirjath Jearim.", 
                "num": 5
              }, 
              {
                "text": "And David and all Israel went up to Baalah, to Kirjath Jearim, which belonged to Judah, to bring up from there the ark of God the LORD, who dwells between the cherubim, where His name is proclaimed.", 
                "num": 6
              }, 
              {
                "text": "So they carried the ark of God on a new cart from the house of Abinadab, and Uzza and Ahio drove the cart.", 
                "num": 7
              }, 
              {
                "text": "Then David and all Israel played music before God with all their might, with singing, on harps, on stringed instruments, on tambourines, on cymbals, and with trumpets.", 
                "num": 8
              }, 
              {
                "text": "And when they came to Chidon's threshing floor, Uzza put out his hand to hold the ark, for the oxen stumbled.", 
                "num": 9
              }, 
              {
                "text": "Then the anger of the LORD was aroused against Uzza, and He struck him because he put his hand to the ark; and he died there before God.", 
                "num": 10
              }, 
              {
                "text": "And David became angry because of the LORD's outbreak against Uzza; therefore that place is called Perez Uzza to this day.", 
                "num": 11
              }, 
              {
                "text": "David was afraid of God that day, saying, \"How can I bring the ark of God to me?\"", 
                "num": 12
              }, 
              {
                "text": "So David would not move the ark with him into the City of David, but took it aside into the house of Obed-Edom the Gittite.", 
                "num": 13
              }, 
              {
                "text": "The ark of God remained with the family of Obed-Edom in his house three months. And the LORD blessed the house of Obed-Edom and all that he had.", 
                "num": 14
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now Hiram king of Tyre sent messengers to David, and cedar trees, with masons and carpenters, to build him a house.", 
                "num": 1
              }, 
              {
                "text": "So David knew that the LORD had established him as king over Israel, for his kingdom was highly exalted for the sake of His people Israel.", 
                "num": 2
              }, 
              {
                "text": "Then David took more wives in Jerusalem, and David begot more sons and daughters.", 
                "num": 3
              }, 
              {
                "text": "And these are the names of his children whom he had in Jerusalem: Shammua, Shobab, Nathan, Solomon,", 
                "num": 4
              }, 
              {
                "text": "Ibhar, Elishua, Elpelet,", 
                "num": 5
              }, 
              {
                "text": "Nogah, Nepheg, Japhia,", 
                "num": 6
              }, 
              {
                "text": "Elishama, Beeliada, and Eliphelet.", 
                "num": 7
              }, 
              {
                "text": "Now when the Philistines heard that David had been anointed king over all Israel, all the Philistines went up to search for David. And David heard of it and went out against them.", 
                "num": 8
              }, 
              {
                "text": "Then the Philistines went and made a raid on the Valley of Rephaim.", 
                "num": 9
              }, 
              {
                "text": "And David inquired of God, saying, \"Shall I go up against the Philistines? Will You deliver them into my hand?\" The LORD said to him, \"Go up, for I will deliver them into your hand.\"", 
                "num": 10
              }, 
              {
                "text": "So they went up to Baal Perazim, and David defeated them there. Then David said, \"God has broken through my enemies by my hand like a breakthrough of water.\" Therefore they called the name of that place Baal Perazim.", 
                "num": 11
              }, 
              {
                "text": "And when they left their gods there, David gave a commandment, and they were burned with fire.", 
                "num": 12
              }, 
              {
                "text": "Then the Philistines once again made a raid on the valley.", 
                "num": 13
              }, 
              {
                "text": "Therefore David inquired again of God, and God said to him, \"You shall not go up after them; circle around them, and come upon them in front of the mulberry trees.", 
                "num": 14
              }, 
              {
                "text": "And it shall be, when you hear a sound of marching in the tops of the mulberry trees, then you shall go out to battle, for God has gone out before you to strike the camp of the Philistines.\"", 
                "num": 15
              }, 
              {
                "text": "So David did as God commanded him, and they drove back the army of the Philistines from Gibeon as far as Gezer.", 
                "num": 16
              }, 
              {
                "text": "Then the fame of David went out into all lands, and the LORD brought the fear of him upon all nations.", 
                "num": 17
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "David built houses for himself in the City of David; and he prepared a place for the ark of God, and pitched a tent for it.", 
                "num": 1
              }, 
              {
                "text": "Then David said, \"No one may carry the ark of God but the Levites, for the LORD has chosen them to carry the ark of God and to minister before Him forever.\"", 
                "num": 2
              }, 
              {
                "text": "And David gathered all Israel together at Jerusalem, to bring up the ark of the LORD to its place, which he had prepared for it.", 
                "num": 3
              }, 
              {
                "text": "Then David assembled the children of Aaron and the Levites:", 
                "num": 4
              }, 
              {
                "text": "of the sons of Kohath, Uriel the chief, and one hundred and twenty of his brethren;", 
                "num": 5
              }, 
              {
                "text": "of the sons of Merari, Asaiah the chief, and two hundred and twenty of his brethren;", 
                "num": 6
              }, 
              {
                "text": "of the sons of Gershom, Joel the chief, and one hundred and thirty of his brethren;", 
                "num": 7
              }, 
              {
                "text": "of the sons of Elizaphan, Shemaiah the chief, and two hundred of his brethren;", 
                "num": 8
              }, 
              {
                "text": "of the sons of Hebron, Eliel the chief, and eighty of his brethren;", 
                "num": 9
              }, 
              {
                "text": "of the sons of Uzziel, Amminadab the chief, and one hundred and twelve of his brethren.", 
                "num": 10
              }, 
              {
                "text": "And David called for Zadok and Abiathar the priests, and for the Levites: for Uriel, Asaiah, Joel, Shemaiah, Eliel, and Amminadab.", 
                "num": 11
              }, 
              {
                "text": "He said to them, \"You are the heads of the fathers' houses of the Levites; sanctify yourselves, you and your brethren, that you may bring up the ark of the LORD God of Israel to the place I have prepared for it.", 
                "num": 12
              }, 
              {
                "text": "For because you did not do it the first time, the LORD our God broke out against us, because we did not consult Him about the proper order.\"", 
                "num": 13
              }, 
              {
                "text": "So the priests and the Levites sanctified themselves to bring up the ark of the LORD God of Israel.", 
                "num": 14
              }, 
              {
                "text": "And the children of the Levites bore the ark of God on their shoulders, by its poles, as Moses had commanded according to the word of the LORD.", 
                "num": 15
              }, 
              {
                "text": "Then David spoke to the leaders of the Levites to appoint their brethren to be the singers accompanied by instruments of music, stringed instruments, harps, and cymbals, by raising the voice with resounding joy.", 
                "num": 16
              }, 
              {
                "text": "So the Levites appointed Heman the son of Joel; and of his brethren, Asaph the son of Berechiah; and of their brethren, the sons of Merari, Ethan the son of Kushaiah;", 
                "num": 17
              }, 
              {
                "text": "and with them their brethren of the second rank: Zechariah, Ben, Jaaziel, Shemiramoth, Jehiel, Unni, Eliab, Benaiah, Maaseiah, Mattithiah, Elipheleh, Mikneiah, Obed-Edom, and Jeiel, the gatekeepers;", 
                "num": 18
              }, 
              {
                "text": "the singers, Heman, Asaph, and Ethan, were to sound the cymbals of bronze;", 
                "num": 19
              }, 
              {
                "text": "Zechariah, Aziel, Shemiramoth, Jehiel, Unni, Eliab, Maaseiah, and Benaiah, with strings according to Alamoth;", 
                "num": 20
              }, 
              {
                "text": "Mattithiah, Elipheleh, Mikneiah, Obed-Edom, Jeiel, and Azaziah, to direct with harps on the Sheminith;", 
                "num": 21
              }, 
              {
                "text": "Chenaniah, leader of the Levites, was instructor in charge of the music, because he was skillful;", 
                "num": 22
              }, 
              {
                "text": "Berechiah and Elkanah were doorkeepers for the ark;", 
                "num": 23
              }, 
              {
                "text": "Shebaniah, Joshaphat, Nethanel, Amasai, Zechariah, Benaiah, and Eliezer, the priests, were to blow the trumpets before the ark of God; and Obed-Edom and Jehiah, doorkeepers for the ark.", 
                "num": 24
              }, 
              {
                "text": "So David, the elders of Israel, and the captains over thousands went to bring up the ark of the covenant of the LORD from the house of Obed-Edom with joy.", 
                "num": 25
              }, 
              {
                "text": "And so it was, when God helped the Levites who bore the ark of the covenant of the LORD, that they offered seven bulls and seven rams.", 
                "num": 26
              }, 
              {
                "text": "David was clothed with a robe of fine linen, as were all the Levites who bore the ark, the singers, and Chenaniah the music master with the singers. David also wore a linen ephod.", 
                "num": 27
              }, 
              {
                "text": "Thus all Israel brought up the ark of the covenant of the LORD with shouting and with the sound of the horn, with trumpets and with cymbals, making music with stringed instruments and harps.", 
                "num": 28
              }, 
              {
                "text": "And it happened, as the ark of the covenant of the LORD came to the City of David, that Michal, Saul's daughter, looked through a window and saw King David whirling and playing music; and she despised him in her heart.", 
                "num": 29
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "So they brought the ark of God, and set it in the midst of the tabernacle that David had erected for it. Then they offered burnt offerings and peace offerings before God.", 
                "num": 1
              }, 
              {
                "text": "And when David had finished offering the burnt offerings and the peace offerings, he blessed the people in the name of the LORD.", 
                "num": 2
              }, 
              {
                "text": "Then he distributed to everyone of Israel, both man and woman, to everyone a loaf of bread, a piece of meat, and a cake of raisins.", 
                "num": 3
              }, 
              {
                "text": "And he appointed some of the Levites to minister before the ark of the LORD, to commemorate, to thank, and to praise the LORD God of Israel:", 
                "num": 4
              }, 
              {
                "text": "Asaph the chief, and next to him Zechariah, then Jeiel, Shemiramoth, Jehiel, Mattithiah, Eliab, Benaiah, and Obed-Edom: Jeiel with stringed instruments and harps, but Asaph made music with cymbals;", 
                "num": 5
              }, 
              {
                "text": "Benaiah and Jahaziel the priests regularly blew the trumpets before the ark of the covenant of God.", 
                "num": 6
              }, 
              {
                "text": "On that day David first delivered this psalm into the hand of Asaph and his brethren, to thank the LORD:", 
                "num": 7
              }, 
              {
                "text": "Oh, give thanks to the LORD! Call upon His name; Make known His deeds among the peoples!", 
                "num": 8
              }, 
              {
                "text": "Sing to Him, sing psalms to Him; Talk of all His wondrous works!", 
                "num": 9
              }, 
              {
                "text": "Glory in His holy name; Let the hearts of those rejoice who seek the LORD!", 
                "num": 10
              }, 
              {
                "text": "Seek the LORD and His strength; Seek His face evermore!", 
                "num": 11
              }, 
              {
                "text": "Remember His marvelous works which He has done, His wonders, and the judgments of His mouth,", 
                "num": 12
              }, 
              {
                "text": "O seed of Israel His servant, You children of Jacob, His chosen ones!", 
                "num": 13
              }, 
              {
                "text": "He is the LORD our God; His judgments are in all the earth.", 
                "num": 14
              }, 
              {
                "text": "Remember His covenant forever, The word which He commanded, for a thousand generations,", 
                "num": 15
              }, 
              {
                "text": "The covenant which He made with Abraham, And His oath to Isaac,", 
                "num": 16
              }, 
              {
                "text": "And confirmed it to Jacob for a statute, To Israel for an everlasting covenant,", 
                "num": 17
              }, 
              {
                "text": "Saying, \"To you I will give the land of Canaan As the allotment of your inheritance,\"", 
                "num": 18
              }, 
              {
                "text": "When you were few in number, Indeed very few, and strangers in it.", 
                "num": 19
              }, 
              {
                "text": "When they went from one nation to another, And from one kingdom to another people,", 
                "num": 20
              }, 
              {
                "text": "He permitted no man to do them wrong; Yes, He rebuked kings for their sakes,", 
                "num": 21
              }, 
              {
                "text": "Saying, \"Do not touch My anointed ones, And do My prophets no harm.\"", 
                "num": 22
              }, 
              {
                "text": "Sing to the LORD, all the earth; Proclaim the good news of His salvation from day to day.", 
                "num": 23
              }, 
              {
                "text": "Declare His glory among the nations, His wonders among all peoples.", 
                "num": 24
              }, 
              {
                "text": "For the LORD is great and greatly to be praised; He is also to be feared above all gods.", 
                "num": 25
              }, 
              {
                "text": "For all the gods of the peoples are idols, But the LORD made the heavens.", 
                "num": 26
              }, 
              {
                "text": "Honor and majesty are before Him; Strength and gladness are in His place.", 
                "num": 27
              }, 
              {
                "text": "Give to the LORD, O families of the peoples, Give to the LORD glory and strength.", 
                "num": 28
              }, 
              {
                "text": "Give to the LORD the glory due His name; Bring an offering, and come before Him. Oh, worship the LORD in the beauty of holiness!", 
                "num": 29
              }, 
              {
                "text": "Tremble before Him, all the earth. The world also is firmly established, It shall not be moved.", 
                "num": 30
              }, 
              {
                "text": "Let the heavens rejoice, and let the earth be glad; And let them say among the nations, \"The LORD reigns.\"", 
                "num": 31
              }, 
              {
                "text": "Let the sea roar, and all its fullness; Let the field rejoice, and all that is in it.", 
                "num": 32
              }, 
              {
                "text": "Then the trees of the woods shall rejoice before the LORD, For He is coming to judge the earth.", 
                "num": 33
              }, 
              {
                "text": "Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 34
              }, 
              {
                "text": "And say, \"Save us, O God of our salvation; Gather us together, and deliver us from the Gentiles, To give thanks to Your holy name, To triumph in Your praise.\"", 
                "num": 35
              }, 
              {
                "text": "Blessed be the LORD God of Israel From everlasting to everlasting! And all the people said, \"Amen!\" and praised the LORD.", 
                "num": 36
              }, 
              {
                "text": "So he left Asaph and his brothers there before the ark of the covenant of the LORD to minister before the ark regularly, as every day's work required;", 
                "num": 37
              }, 
              {
                "text": "and Obed-Edom with his sixty-eight brethren, including Obed-Edom the son of Jeduthun, and Hosah, to be gatekeepers;", 
                "num": 38
              }, 
              {
                "text": "and Zadok the priest and his brethren the priests, before the tabernacle of the LORD at the high place that was at Gibeon,", 
                "num": 39
              }, 
              {
                "text": "to offer burnt offerings to the LORD on the altar of burnt offering regularly morning and evening, and to do according to all that is written in the Law of the LORD which He commanded Israel;", 
                "num": 40
              }, 
              {
                "text": "and with them Heman and Jeduthun and the rest who were chosen, who were designated by name, to give thanks to the LORD, because His mercy endures forever;", 
                "num": 41
              }, 
              {
                "text": "and with them Heman and Jeduthun, to sound aloud with trumpets and cymbals and the musical instruments of God. Now the sons of Jeduthun were gatekeepers.", 
                "num": 42
              }, 
              {
                "text": "Then all the people departed, every man to his house; and David returned to bless his house.", 
                "num": 43
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when David was dwelling in his house, that David said to Nathan the prophet, \"See now, I dwell in a house of cedar, but the ark of the covenant of the LORD is under tent curtains.\"", 
                "num": 1
              }, 
              {
                "text": "Then Nathan said to David, \"Do all that is in your heart, for God is with you.\"", 
                "num": 2
              }, 
              {
                "text": "But it happened that night that the word of God came to Nathan, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Go and tell My servant David, \"Thus says the LORD: \"You shall not build Me a house to dwell in.", 
                "num": 4
              }, 
              {
                "text": "For I have not dwelt in a house since the time that I brought up Israel, even to this day, but have gone from tent to tent, and from one tabernacle to another.", 
                "num": 5
              }, 
              {
                "text": "Wherever I have moved about with all Israel, have I ever spoken a word to any of the judges of Israel, whom I commanded to shepherd My people, saying, \"Why have you not built Me a house of cedar?\"\"", 
                "num": 6
              }, 
              {
                "text": "Now therefore, thus shall you say to My servant David, \"Thus says the LORD of hosts: \"I took you from the sheepfold, from following the sheep, to be ruler over My people Israel.", 
                "num": 7
              }, 
              {
                "text": "And I have been with you wherever you have gone, and have cut off all your enemies from before you, and have made you a name like the name of the great men who are on the earth.", 
                "num": 8
              }, 
              {
                "text": "Moreover I will appoint a place for My people Israel, and will plant them, that they may dwell in a place of their own and move no more; nor shall the sons of wickedness oppress them anymore, as previously,", 
                "num": 9
              }, 
              {
                "text": "since the time that I commanded judges to be over My people Israel. Also I will subdue all your enemies. Furthermore I tell you that the LORD will build you a house.", 
                "num": 10
              }, 
              {
                "text": "And it shall be, when your days are fulfilled, when you must go to be with your fathers, that I will set up your seed after you, who will be of your sons; and I will establish his kingdom.", 
                "num": 11
              }, 
              {
                "text": "He shall build Me a house, and I will establish his throne forever.", 
                "num": 12
              }, 
              {
                "text": "I will be his Father, and he shall be My son; and I will not take My mercy away from him, as I took it from him who was before you.", 
                "num": 13
              }, 
              {
                "text": "And I will establish him in My house and in My kingdom forever; and his throne shall be established forever.\"\"'", 
                "num": 14
              }, 
              {
                "text": "According to all these words and according to all this vision, so Nathan spoke to David.", 
                "num": 15
              }, 
              {
                "text": "Then King David went in and sat before the LORD; and he said: \"Who am I, O LORD God? And what is my house, that You have brought me this far?", 
                "num": 16
              }, 
              {
                "text": "And yet this was a small thing in Your sight, O God; and You have also spoken of Your servant's house for a great while to come, and have regarded me according to the rank of a man of high degree, O LORD God.", 
                "num": 17
              }, 
              {
                "text": "What more can David say to You for the honor of Your servant? For You know Your servant.", 
                "num": 18
              }, 
              {
                "text": "O LORD, for Your servant's sake, and according to Your own heart, You have done all this greatness, in making known all these great things.", 
                "num": 19
              }, 
              {
                "text": "O LORD, there is none like You, nor is there any God besides You, according to all that we have heard with our ears.", 
                "num": 20
              }, 
              {
                "text": "And who is like Your people Israel, the one nation on the earth whom God went to redeem for Himself as a people--to make for Yourself a name by great and awesome deeds, by driving out nations from before Your people whom You redeemed from Egypt?", 
                "num": 21
              }, 
              {
                "text": "For You have made Your people Israel Your very own people forever; and You, LORD, have become their God.", 
                "num": 22
              }, 
              {
                "text": "\"And now, O LORD, the word which You have spoken concerning Your servant and concerning his house, let it be established forever, and do as You have said.", 
                "num": 23
              }, 
              {
                "text": "So let it be established, that Your name may be magnified forever, saying, \"The LORD of hosts, the God of Israel, is Israel's God.' And let the house of Your servant David be established before You.", 
                "num": 24
              }, 
              {
                "text": "For You, O my God, have revealed to Your servant that You will build him a house. Therefore Your servant has found it in his heart to pray before You.", 
                "num": 25
              }, 
              {
                "text": "And now, LORD, You are God, and have promised this goodness to Your servant.", 
                "num": 26
              }, 
              {
                "text": "Now You have been pleased to bless the house of Your servant, that it may continue before You forever; for You have blessed it, O LORD, and it shall be blessed forever.\"", 
                "num": 27
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "After this it came to pass that David attacked the Philistines, subdued them, and took Gath and its towns from the hand of the Philistines.", 
                "num": 1
              }, 
              {
                "text": "Then he defeated Moab, and the Moabites became David's servants, and brought tribute.", 
                "num": 2
              }, 
              {
                "text": "And David defeated Hadadezer king of Zobah as far as Hamath, as he went to establish his power by the River Euphrates.", 
                "num": 3
              }, 
              {
                "text": "David took from him one thousand chariots, seven thousand horsemen, and twenty thousand foot soldiers. Also David hamstrung all the chariot horses, except that he spared enough of them for one hundred chariots.", 
                "num": 4
              }, 
              {
                "text": "When the Syrians of Damascus came to help Hadadezer king of Zobah, David killed twenty-two thousand of the Syrians.", 
                "num": 5
              }, 
              {
                "text": "Then David put garrisons in Syria of Damascus; and the Syrians became David's servants, and brought tribute. So the LORD preserved David wherever he went.", 
                "num": 6
              }, 
              {
                "text": "And David took the shields of gold that were on the servants of Hadadezer, and brought them to Jerusalem.", 
                "num": 7
              }, 
              {
                "text": "Also from Tibhath and from Chun, cities of Hadadezer, David brought a large amount of bronze, with which Solomon made the bronze Sea, the pillars, and the articles of bronze.", 
                "num": 8
              }, 
              {
                "text": "Now when Tou king of Hamath heard that David had defeated all the army of Hadadezer king of Zobah,", 
                "num": 9
              }, 
              {
                "text": "he sent Hadoram his son to King David, to greet him and bless him, because he had fought against Hadadezer and defeated him (for Hadadezer had been at war with Tou); and Hadoram brought with him all kinds of articles of gold, silver, and bronze.", 
                "num": 10
              }, 
              {
                "text": "King David also dedicated these to the LORD, along with the silver and gold that he had brought from all these nations--from Edom, from Moab, from the people of Ammon, from the Philistines, and from Amalek.", 
                "num": 11
              }, 
              {
                "text": "Moreover Abishai the son of Zeruiah killed eighteen thousand Edomites in the Valley of Salt.", 
                "num": 12
              }, 
              {
                "text": "He also put garrisons in Edom, and all the Edomites became David's servants. And the LORD preserved David wherever he went.", 
                "num": 13
              }, 
              {
                "text": "So David reigned over all Israel, and administered judgment and justice to all his people.", 
                "num": 14
              }, 
              {
                "text": "Joab the son of Zeruiah was over the army; Jehoshaphat the son of Ahilud was recorder;", 
                "num": 15
              }, 
              {
                "text": "Zadok the son of Ahitub and Abimelech the son of Abiathar were the priests; Shavsha was the scribe;", 
                "num": 16
              }, 
              {
                "text": "Benaiah the son of Jehoiada was over the Cherethites and the Pelethites; and David's sons were chief ministers at the king's side.", 
                "num": 17
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "It happened after this that Nahash the king of the people of Ammon died, and his son reigned in his place.", 
                "num": 1
              }, 
              {
                "text": "Then David said, \"I will show kindness to Hanun the son of Nahash, because his father showed kindness to me.\" So David sent messengers to comfort him concerning his father. And David's servants came to Hanun in the land of the people of Ammon to comfort him.", 
                "num": 2
              }, 
              {
                "text": "And the princes of the people of Ammon said to Hanun, \"Do you think that David really honors your father because he has sent comforters to you? Did his servants not come to you to search and to overthrow and to spy out the land?\"", 
                "num": 3
              }, 
              {
                "text": "Therefore Hanun took David's servants, shaved them, and cut off their garments in the middle, at their buttocks, and sent them away.", 
                "num": 4
              }, 
              {
                "text": "Then some went and told David about the men; and he sent to meet them, because the men were greatly ashamed. And the king said, \"Wait at Jericho until your beards have grown, and then return.\"", 
                "num": 5
              }, 
              {
                "text": "When the people of Ammon saw that they had made themselves repulsive to David, Hanun and the people of Ammon sent a thousand talents of silver to hire for themselves chariots and horsemen from Mesopotamia, from Syrian Maacah, and from Zobah.", 
                "num": 6
              }, 
              {
                "text": "So they hired for themselves thirty-two thousand chariots, with the king of Maacah and his people, who came and encamped before Medeba. Also the people of Ammon gathered together from their cities, and came to battle.", 
                "num": 7
              }, 
              {
                "text": "Now when David heard of it, he sent Joab and all the army of the mighty men.", 
                "num": 8
              }, 
              {
                "text": "Then the people of Ammon came out and put themselves in battle array before the gate of the city, and the kings who had come were by themselves in the field.", 
                "num": 9
              }, 
              {
                "text": "When Joab saw that the battle line was against him before and behind, he chose some of Israel's best, and put them in battle array against the Syrians.", 
                "num": 10
              }, 
              {
                "text": "And the rest of the people he put under the command of Abishai his brother, and they set themselves in battle array against the people of Ammon.", 
                "num": 11
              }, 
              {
                "text": "Then he said, \"If the Syrians are too strong for me, then you shall help me; but if the people of Ammon are too strong for you, then I will help you.", 
                "num": 12
              }, 
              {
                "text": "Be of good courage, and let us be strong for our people and for the cities of our God. And may the LORD do what is good in His sight.\"", 
                "num": 13
              }, 
              {
                "text": "So Joab and the people who were with him drew near for the battle against the Syrians, and they fled before him.", 
                "num": 14
              }, 
              {
                "text": "When the people of Ammon saw that the Syrians were fleeing, they also fled before Abishai his brother, and entered the city. So Joab went to Jerusalem.", 
                "num": 15
              }, 
              {
                "text": "Now when the Syrians saw that they had been defeated by Israel, they sent messengers and brought the Syrians who were beyond the River, and Shophach the commander of Hadadezer's army went before them.", 
                "num": 16
              }, 
              {
                "text": "When it was told David, he gathered all Israel, crossed over the Jordan and came upon them, and set up in battle array against them. So when David had set up in battle array against the Syrians, they fought with him.", 
                "num": 17
              }, 
              {
                "text": "Then the Syrians fled before Israel; and David killed seven thousand charioteers and forty thousand foot soldiers of the Syrians, and killed Shophach the commander of the army.", 
                "num": 18
              }, 
              {
                "text": "And when the servants of Hadadezer saw that they were defeated by Israel, they made peace with David and became his servants. So the Syrians were not willing to help the people of Ammon anymore.", 
                "num": 19
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "It happened in the spring of the year, at the time kings go out to battle, that Joab led out the armed forces and ravaged the country of the people of Ammon, and came and besieged Rabbah. But David stayed at Jerusalem. And Joab defeated Rabbah and overthrew it.", 
                "num": 1
              }, 
              {
                "text": "Then David took their king's crown from his head, and found it to weigh a talent of gold, and there were precious stones in it. And it was set on David's head. Also he brought out the spoil of the city in great abundance.", 
                "num": 2
              }, 
              {
                "text": "And he brought out the people who were in it, and put them to work with saws, with iron picks, and with axes. So David did to all the cities of the people of Ammon. Then David and all the people returned to Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "Now it happened afterward that war broke out at Gezer with the Philistines, at which time Sibbechai the Hushathite killed Sippai, who was one of the sons of the giant. And they were subdued.", 
                "num": 4
              }, 
              {
                "text": "Again there was war with the Philistines, and Elhanan the son of Jair killed Lahmi the brother of Goliath the Gittite, the shaft of whose spear was like a weaver's beam.", 
                "num": 5
              }, 
              {
                "text": "Yet again there was war at Gath, where there was a man of great stature, with twenty-four fingers and toes, six on each hand and six on each foot; and he also was born to the giant.", 
                "num": 6
              }, 
              {
                "text": "So when he defied Israel, Jonathan the son of Shimea, David's brother, killed him.", 
                "num": 7
              }, 
              {
                "text": "These were born to the giant in Gath, and they fell by the hand of David and by the hand of his servants.", 
                "num": 8
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now Satan stood up against Israel, and moved David to number Israel.", 
                "num": 1
              }, 
              {
                "text": "So David said to Joab and to the leaders of the people, \"Go, number Israel from Beersheba to Dan, and bring the number of them to me that I may know it.\"", 
                "num": 2
              }, 
              {
                "text": "And Joab answered, \"May the LORD make His people a hundred times more than they are. But, my lord the king, are they not all my lord's servants? Why then does my lord require this thing? Why should he be a cause of guilt in Israel?\"", 
                "num": 3
              }, 
              {
                "text": "Nevertheless the king's word prevailed against Joab. Therefore Joab departed and went throughout all Israel and came to Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "Then Joab gave the sum of the number of the people to David. All Israel had one million one hundred thousand men who drew the sword, and Judah had four hundred and seventy thousand men who drew the sword.", 
                "num": 5
              }, 
              {
                "text": "But he did not count Levi and Benjamin among them, for the king's word was abominable to Joab.", 
                "num": 6
              }, 
              {
                "text": "And God was displeased with this thing; therefore He struck Israel.", 
                "num": 7
              }, 
              {
                "text": "So David said to God, \"I have sinned greatly, because I have done this thing; but now, I pray, take away the iniquity of Your servant, for I have done very foolishly.\"", 
                "num": 8
              }, 
              {
                "text": "Then the LORD spoke to Gad, David's seer, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Go and tell David, saying, \"Thus says the LORD: \"I offer you three things; choose one of them for yourself, that I may do it to you.\"\"'", 
                "num": 10
              }, 
              {
                "text": "So Gad came to David and said to him, \"Thus says the LORD: \"Choose for yourself,", 
                "num": 11
              }, 
              {
                "text": "either three years of famine, or three months to be defeated by your foes with the sword of your enemies overtaking you, or else for three days the sword of the LORD--the plague in the land, with the angel of the LORD destroying throughout all the territory of Israel.' Now consider what answer I should take back to Him who sent me.\"", 
                "num": 12
              }, 
              {
                "text": "And David said to Gad, \"I am in great distress. Please let me fall into the hand of the LORD, for His mercies are very great; but do not let me fall into the hand of man.\"", 
                "num": 13
              }, 
              {
                "text": "So the LORD sent a plague upon Israel, and seventy thousand men of Israel fell.", 
                "num": 14
              }, 
              {
                "text": "And God sent an angel to Jerusalem to destroy it. As he was destroying, the LORD looked and relented of the disaster, and said to the angel who was destroying, \"It is enough; now restrain your hand.\" And the angel of the LORD stood by the threshing floor of Ornan the Jebusite.", 
                "num": 15
              }, 
              {
                "text": "Then David lifted his eyes and saw the angel of the LORD standing between earth and heaven, having in his hand a drawn sword stretched out over Jerusalem. So David and the elders, clothed in sackcloth, fell on their faces.", 
                "num": 16
              }, 
              {
                "text": "And David said to God, \"Was it not I who commanded the people to be numbered? I am the one who has sinned and done evil indeed; but these sheep, what have they done? Let Your hand, I pray, O LORD my God, be against me and my father's house, but not against Your people that they should be plagued.\"", 
                "num": 17
              }, 
              {
                "text": "Therefore, the angel of the LORD commanded Gad to say to David that David should go and erect an altar to the LORD on the threshing floor of Ornan the Jebusite.", 
                "num": 18
              }, 
              {
                "text": "So David went up at the word of Gad, which he had spoken in the name of the LORD.", 
                "num": 19
              }, 
              {
                "text": "Now Ornan turned and saw the angel; and his four sons who were with him hid themselves, but Ornan continued threshing wheat.", 
                "num": 20
              }, 
              {
                "text": "So David came to Ornan, and Ornan looked and saw David. And he went out from the threshing floor, and bowed before David with his face to the ground.", 
                "num": 21
              }, 
              {
                "text": "Then David said to Ornan, \"Grant me the place of this threshing floor, that I may build an altar on it to the LORD. You shall grant it to me at the full price, that the plague may be withdrawn from the people.\"", 
                "num": 22
              }, 
              {
                "text": "But Ornan said to David, \"Take it to yourself, and let my lord the king do what is good in his eyes. Look, I also give you the oxen for burnt offerings, the threshing implements for wood, and the wheat for the grain offering; I give it all.\"", 
                "num": 23
              }, 
              {
                "text": "Then King David said to Ornan, \"No, but I will surely buy it for the full price, for I will not take what is yours for the LORD, nor offer burnt offerings with that which costs me nothing.\"", 
                "num": 24
              }, 
              {
                "text": "So David gave Ornan six hundred shekels of gold by weight for the place.", 
                "num": 25
              }, 
              {
                "text": "And David built there an altar to the LORD, and offered burnt offerings and peace offerings, and called on the LORD; and He answered him from heaven by fire on the altar of burnt offering.", 
                "num": 26
              }, 
              {
                "text": "So the LORD commanded the angel, and he returned his sword to its sheath.", 
                "num": 27
              }, 
              {
                "text": "At that time, when David saw that the LORD had answered him on the threshing floor of Ornan the Jebusite, he sacrificed there.", 
                "num": 28
              }, 
              {
                "text": "For the tabernacle of the LORD and the altar of the burnt offering, which Moses had made in the wilderness, were at that time at the high place in Gibeon.", 
                "num": 29
              }, 
              {
                "text": "But David could not go before it to inquire of God, for he was afraid of the sword of the angel of the LORD.", 
                "num": 30
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then David said, \"This is the house of the LORD God, and this is the altar of burnt offering for Israel.\"", 
                "num": 1
              }, 
              {
                "text": "So David commanded to gather the aliens who were in the land of Israel; and he appointed masons to cut hewn stones to build the house of God.", 
                "num": 2
              }, 
              {
                "text": "And David prepared iron in abundance for the nails of the doors of the gates and for the joints, and bronze in abundance beyond measure,", 
                "num": 3
              }, 
              {
                "text": "and cedar trees in abundance; for the Sidonians and those from Tyre brought much cedar wood to David.", 
                "num": 4
              }, 
              {
                "text": "Now David said, \"Solomon my son is young and inexperienced, and the house to be built for the LORD must be exceedingly magnificent, famous and glorious throughout all countries. I will now make preparation for it.\" So David made abundant preparations before his death.", 
                "num": 5
              }, 
              {
                "text": "Then he called for his son Solomon, and charged him to build a house for the LORD God of Israel.", 
                "num": 6
              }, 
              {
                "text": "And David said to Solomon: \"My son, as for me, it was in my mind to build a house to the name of the LORD my God;", 
                "num": 7
              }, 
              {
                "text": "but the word of the LORD came to me, saying, \"You have shed much blood and have made great wars; you shall not build a house for My name, because you have shed much blood on the earth in My sight.", 
                "num": 8
              }, 
              {
                "text": "Behold, a son shall be born to you, who shall be a man of rest; and I will give him rest from all his enemies all around. His name shall be Solomon, for I will give peace and quietness to Israel in his days.", 
                "num": 9
              }, 
              {
                "text": "He shall build a house for My name, and he shall be My son, and I will be his Father; and I will establish the throne of his kingdom over Israel forever.'", 
                "num": 10
              }, 
              {
                "text": "Now, my son, may the LORD be with you; and may you prosper, and build the house of the LORD your God, as He has said to you.", 
                "num": 11
              }, 
              {
                "text": "Only may the LORD give you wisdom and understanding, and give you charge concerning Israel, that you may keep the law of the LORD your God.", 
                "num": 12
              }, 
              {
                "text": "Then you will prosper, if you take care to fulfill the statutes and judgments with which the LORD charged Moses concerning Israel. Be strong and of good courage; do not fear nor be dismayed.", 
                "num": 13
              }, 
              {
                "text": "Indeed I have taken much trouble to prepare for the house of the LORD one hundred thousand talents of gold and one million talents of silver, and bronze and iron beyond measure, for it is so abundant. I have prepared timber and stone also, and you may add to them.", 
                "num": 14
              }, 
              {
                "text": "Moreover there are workmen with you in abundance: woodsmen and stonecutters, and all types of skillful men for every kind of work.", 
                "num": 15
              }, 
              {
                "text": "Of gold and silver and bronze and iron there is no limit. Arise and begin working, and the LORD be with you.\"", 
                "num": 16
              }, 
              {
                "text": "David also commanded all the leaders of Israel to help Solomon his son, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Is not the LORD your God with you? And has He not given you rest on every side? For He has given the inhabitants of the land into my hand, and the land is subdued before the LORD and before His people.", 
                "num": 18
              }, 
              {
                "text": "Now set your heart and your soul to seek the LORD your God. Therefore arise and build the sanctuary of the LORD God, to bring the ark of the covenant of the LORD and the holy articles of God into the house that is to be built for the name of the LORD.\"", 
                "num": 19
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "So when David was old and full of days, he made his son Solomon king over Israel.", 
                "num": 1
              }, 
              {
                "text": "And he gathered together all the leaders of Israel, with the priests and the Levites.", 
                "num": 2
              }, 
              {
                "text": "Now the Levites were numbered from the age of thirty years and above; and the number of individual males was thirty-eight thousand.", 
                "num": 3
              }, 
              {
                "text": "Of these, twenty-four thousand were to look after the work of the house of the LORD, six thousand were officers and judges,", 
                "num": 4
              }, 
              {
                "text": "four thousand were gatekeepers, and four thousand praised the LORD with musical instruments, \"which I made,\" said David, \"for giving praise.\"", 
                "num": 5
              }, 
              {
                "text": "Also David separated them into divisions among the sons of Levi: Gershon, Kohath, and Merari.", 
                "num": 6
              }, 
              {
                "text": "Of the Gershonites: Laadan and Shimei.", 
                "num": 7
              }, 
              {
                "text": "The sons of Laadan: the first Jehiel, then Zetham and Joel--three in all.", 
                "num": 8
              }, 
              {
                "text": "The sons of Shimei: Shelomith, Haziel, and Haran--three in all. These were the heads of the fathers' houses of Laadan.", 
                "num": 9
              }, 
              {
                "text": "And the sons of Shimei: Jahath, Zina, Jeush, and Beriah. These were the four sons of Shimei.", 
                "num": 10
              }, 
              {
                "text": "Jahath was the first and Zizah the second. But Jeush and Beriah did not have many sons; therefore they were assigned as one father's house.", 
                "num": 11
              }, 
              {
                "text": "The sons of Kohath: Amram, Izhar, Hebron, and Uzziel--four in all.", 
                "num": 12
              }, 
              {
                "text": "The sons of Amram: Aaron and Moses; and Aaron was set apart, he and his sons forever, that he should sanctify the most holy things, to burn incense before the LORD, to minister to Him, and to give the blessing in His name forever.", 
                "num": 13
              }, 
              {
                "text": "Now the sons of Moses the man of God were reckoned to the tribe of Levi.", 
                "num": 14
              }, 
              {
                "text": "The sons of Moses were Gershon and Eliezer.", 
                "num": 15
              }, 
              {
                "text": "Of the sons of Gershon, Shebuel was the first.", 
                "num": 16
              }, 
              {
                "text": "Of the descendants of Eliezer, Rehabiah was the first. And Eliezer had no other sons, but the sons of Rehabiah were very many.", 
                "num": 17
              }, 
              {
                "text": "Of the sons of Izhar, Shelomith was the first.", 
                "num": 18
              }, 
              {
                "text": "Of the sons of Hebron, Jeriah was the first, Amariah the second, Jahaziel the third, and Jekameam the fourth.", 
                "num": 19
              }, 
              {
                "text": "Of the sons of Uzziel, Michah was the first and Jesshiah the second.", 
                "num": 20
              }, 
              {
                "text": "The sons of Merari were Mahli and Mushi. The sons of Mahli were Eleazar and Kish.", 
                "num": 21
              }, 
              {
                "text": "And Eleazar died, and had no sons, but only daughters; and their brethren, the sons of Kish, took them as wives.", 
                "num": 22
              }, 
              {
                "text": "The sons of Mushi were Mahli, Eder, and Jeremoth--three in all.", 
                "num": 23
              }, 
              {
                "text": "These were the sons of Levi by their fathers' houses--the heads of the fathers' houses as they were counted individually by the number of their names, who did the work for the service of the house of the LORD, from the age of twenty years and above.", 
                "num": 24
              }, 
              {
                "text": "For David said, \"The LORD God of Israel has given rest to His people, that they may dwell in Jerusalem forever\";", 
                "num": 25
              }, 
              {
                "text": "and also to the Levites, \"They shall no longer carry the tabernacle, or any of the articles for its service.\"", 
                "num": 26
              }, 
              {
                "text": "For by the last words of David the Levites were numbered from twenty years old and above;", 
                "num": 27
              }, 
              {
                "text": "because their duty was to help the sons of Aaron in the service of the house of the LORD, in the courts and in the chambers, in the purifying of all holy things and the work of the service of the house of God,", 
                "num": 28
              }, 
              {
                "text": "both with the showbread and the fine flour for the grain offering, with the unleavened cakes and what is baked in the pan, with what is mixed and with all kinds of measures and sizes;", 
                "num": 29
              }, 
              {
                "text": "to stand every morning to thank and praise the LORD, and likewise at evening;", 
                "num": 30
              }, 
              {
                "text": "and at every presentation of a burnt offering to the LORD on the Sabbaths and on the New Moons and on the set feasts, by number according to the ordinance governing them, regularly before the LORD;", 
                "num": 31
              }, 
              {
                "text": "and that they should attend to the needs of the tabernacle of meeting, the needs of the holy place, and the needs of the sons of Aaron their brethren in the work of the house of the LORD.", 
                "num": 32
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now these are the divisions of the sons of Aaron. The sons of Aaron were Nadab, Abihu, Eleazar, and Ithamar.", 
                "num": 1
              }, 
              {
                "text": "And Nadab and Abihu died before their father, and had no children; therefore Eleazar and Ithamar ministered as priests.", 
                "num": 2
              }, 
              {
                "text": "Then David with Zadok of the sons of Eleazar, and Ahimelech of the sons of Ithamar, divided them according to the schedule of their service.", 
                "num": 3
              }, 
              {
                "text": "There were more leaders found of the sons of Eleazar than of the sons of Ithamar, and thus they were divided. Among the sons of Eleazar were sixteen heads of their fathers' houses, and eight heads of their fathers' houses among the sons of Ithamar.", 
                "num": 4
              }, 
              {
                "text": "Thus they were divided by lot, one group as another, for there were officials of the sanctuary and officials of the house of God, from the sons of Eleazar and from the sons of Ithamar.", 
                "num": 5
              }, 
              {
                "text": "And the scribe, Shemaiah the son of Nethanel, one of the Levites, wrote them down before the king, the leaders, Zadok the priest, Ahimelech the son of Abiathar, and the heads of the fathers' houses of the priests and Levites, one father's house taken for Eleazar and one for Ithamar.", 
                "num": 6
              }, 
              {
                "text": "Now the first lot fell to Jehoiarib, the second to Jedaiah,", 
                "num": 7
              }, 
              {
                "text": "the third to Harim, the fourth to Seorim,", 
                "num": 8
              }, 
              {
                "text": "the fifth to Malchijah, the sixth to Mijamin,", 
                "num": 9
              }, 
              {
                "text": "the seventh to Hakkoz, the eighth to Abijah,", 
                "num": 10
              }, 
              {
                "text": "the ninth to Jeshua, the tenth to Shecaniah,", 
                "num": 11
              }, 
              {
                "text": "the eleventh to Eliashib, the twelfth to Jakim,", 
                "num": 12
              }, 
              {
                "text": "the thirteenth to Huppah, the fourteenth to Jeshebeab,", 
                "num": 13
              }, 
              {
                "text": "the fifteenth to Bilgah, the sixteenth to Immer,", 
                "num": 14
              }, 
              {
                "text": "the seventeenth to Hezir, the eighteenth to Happizzez,", 
                "num": 15
              }, 
              {
                "text": "the nineteenth to Pethahiah, the twentieth to Jehezekel,", 
                "num": 16
              }, 
              {
                "text": "the twenty-first to Jachin, the twenty-second to Gamul,", 
                "num": 17
              }, 
              {
                "text": "the twenty-third to Delaiah, the twenty-fourth to Maaziah.", 
                "num": 18
              }, 
              {
                "text": "This was the schedule of their service for coming into the house of the LORD according to their ordinance by the hand of Aaron their father, as the LORD God of Israel had commanded him.", 
                "num": 19
              }, 
              {
                "text": "And the rest of the sons of Levi: of the sons of Amram, Shubael; of the sons of Shubael, Jehdeiah.", 
                "num": 20
              }, 
              {
                "text": "Concerning Rehabiah, of the sons of Rehabiah, the first was Isshiah.", 
                "num": 21
              }, 
              {
                "text": "Of the Izharites, Shelomoth; of the sons of Shelomoth, Jahath.", 
                "num": 22
              }, 
              {
                "text": "Of the sons of Hebron, Jeriah was the first, Amariah the second, Jahaziel the third, and Jekameam the fourth.", 
                "num": 23
              }, 
              {
                "text": "Of the sons of Uzziel, Michah; of the sons of Michah, Shamir.", 
                "num": 24
              }, 
              {
                "text": "The brother of Michah, Isshiah; of the sons of Isshiah, Zechariah.", 
                "num": 25
              }, 
              {
                "text": "The sons of Merari were Mahli and Mushi; the son of Jaaziah, Beno.", 
                "num": 26
              }, 
              {
                "text": "The sons of Merari by Jaaziah were Beno, Shoham, Zaccur, and Ibri.", 
                "num": 27
              }, 
              {
                "text": "Of Mahli: Eleazar, who had no sons.", 
                "num": 28
              }, 
              {
                "text": "Of Kish: the son of Kish, Jerahmeel.", 
                "num": 29
              }, 
              {
                "text": "Also the sons of Mushi were Mahli, Eder, and Jerimoth. These were the sons of the Levites according to their fathers' houses.", 
                "num": 30
              }, 
              {
                "text": "These also cast lots just as their brothers the sons of Aaron did, in the presence of King David, Zadok, Ahimelech, and the heads of the fathers' houses of the priests and Levites. The chief fathers did just as their younger brethren.", 
                "num": 31
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Moreover David and the captains of the army separated for the service some of the sons of Asaph, of Heman, and of Jeduthun, who should prophesy with harps, stringed instruments, and cymbals. And the number of the skilled men performing their service was:", 
                "num": 1
              }, 
              {
                "text": "Of the sons of Asaph: Zaccur, Joseph, Nethaniah, and Asharelah; the sons of Asaph were under the direction of Asaph, who prophesied according to the order of the king.", 
                "num": 2
              }, 
              {
                "text": "Of Jeduthun, the sons of Jeduthun: Gedaliah, Zeri, Jeshaiah, Shimei, Hashabiah, and Mattithiah, six, under the direction of their father Jeduthun, who prophesied with a harp to give thanks and to praise the LORD.", 
                "num": 3
              }, 
              {
                "text": "Of Heman, the sons of Heman: Bukkiah, Mattaniah, Uzziel, Shebuel, Jerimoth, Hananiah, Hanani, Eliathah, Giddalti, Romamti-Ezer, Joshbekashah, Mallothi, Hothir, and Mahazioth.", 
                "num": 4
              }, 
              {
                "text": "All these were the sons of Heman the king's seer in the words of God, to exalt his horn. For God gave Heman fourteen sons and three daughters.", 
                "num": 5
              }, 
              {
                "text": "All these were under the direction of their father for the music in the house of the LORD, with cymbals, stringed instruments, and harps, for the service of the house of God. Asaph, Jeduthun, and Heman were under the authority of the king.", 
                "num": 6
              }, 
              {
                "text": "So the number of them, with their brethren who were instructed in the songs of the LORD, all who were skillful, was two hundred and eighty-eight.", 
                "num": 7
              }, 
              {
                "text": "And they cast lots for their duty, the small as well as the great, the teacher with the student.", 
                "num": 8
              }, 
              {
                "text": "Now the first lot for Asaph came out for Joseph; the second for Gedaliah, him with his brethren and sons, twelve;", 
                "num": 9
              }, 
              {
                "text": "the third for Zaccur, his sons and his brethren, twelve;", 
                "num": 10
              }, 
              {
                "text": "the fourth for Jizri, his sons and his brethren, twelve;", 
                "num": 11
              }, 
              {
                "text": "the fifth for Nethaniah, his sons and his brethren, twelve;", 
                "num": 12
              }, 
              {
                "text": "the sixth for Bukkiah, his sons and his brethren, twelve;", 
                "num": 13
              }, 
              {
                "text": "the seventh for Jesharelah, his sons and his brethren, twelve;", 
                "num": 14
              }, 
              {
                "text": "the eighth for Jeshaiah, his sons and his brethren, twelve;", 
                "num": 15
              }, 
              {
                "text": "the ninth for Mattaniah, his sons and his brethren, twelve;", 
                "num": 16
              }, 
              {
                "text": "the tenth for Shimei, his sons and his brethren, twelve;", 
                "num": 17
              }, 
              {
                "text": "the eleventh for Azarel, his sons and his brethren, twelve;", 
                "num": 18
              }, 
              {
                "text": "the twelfth for Hashabiah, his sons and his brethren, twelve;", 
                "num": 19
              }, 
              {
                "text": "the thirteenth for Shubael, his sons and his brethren, twelve;", 
                "num": 20
              }, 
              {
                "text": "the fourteenth for Mattithiah, his sons and his brethren, twelve;", 
                "num": 21
              }, 
              {
                "text": "the fifteenth for Jeremoth, his sons and his brethren, twelve;", 
                "num": 22
              }, 
              {
                "text": "the sixteenth for Hananiah, his sons and his brethren, twelve;", 
                "num": 23
              }, 
              {
                "text": "the seventeenth for Joshbekashah, his sons and his brethren, twelve;", 
                "num": 24
              }, 
              {
                "text": "the eighteenth for Hanani, his sons and his brethren, twelve;", 
                "num": 25
              }, 
              {
                "text": "the nineteenth for Mallothi, his sons and his brethren, twelve;", 
                "num": 26
              }, 
              {
                "text": "the twentieth for Eliathah, his sons and his brethren, twelve;", 
                "num": 27
              }, 
              {
                "text": "the twenty-first for Hothir, his sons and his brethren, twelve;", 
                "num": 28
              }, 
              {
                "text": "the twenty-second for Giddalti, his sons and his brethren, twelve;", 
                "num": 29
              }, 
              {
                "text": "the twenty-third for Mahazioth, his sons and his brethren, twelve;", 
                "num": 30
              }, 
              {
                "text": "the twenty-fourth for Romamti-Ezer, his sons and his brethren, twelve.", 
                "num": 31
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Concerning the divisions of the gatekeepers: of the Korahites, Meshelemiah the son of Kore, of the sons of Asaph.", 
                "num": 1
              }, 
              {
                "text": "And the sons of Meshelemiah were Zechariah the firstborn, Jediael the second, Zebadiah the third, Jathniel the fourth,", 
                "num": 2
              }, 
              {
                "text": "Elam the fifth, Jehohanan the sixth, Eliehoenai the seventh.", 
                "num": 3
              }, 
              {
                "text": "Moreover the sons of Obed-Edom were Shemaiah the firstborn, Jehozabad the second, Joah the third, Sacar the fourth, Nethanel the fifth,", 
                "num": 4
              }, 
              {
                "text": "Ammiel the sixth, Issachar the seventh, Peulthai the eighth; for God blessed him.", 
                "num": 5
              }, 
              {
                "text": "Also to Shemaiah his son were sons born who governed their fathers' houses, because they were men of great ability.", 
                "num": 6
              }, 
              {
                "text": "The sons of Shemaiah were Othni, Rephael, Obed, and Elzabad, whose brothers Elihu and Semachiah were able men.", 
                "num": 7
              }, 
              {
                "text": "All these were of the sons of Obed-Edom, they and their sons and their brethren, able men with strength for the work: sixty-two of Obed-Edom.", 
                "num": 8
              }, 
              {
                "text": "And Meshelemiah had sons and brethren, eighteen able men.", 
                "num": 9
              }, 
              {
                "text": "Also Hosah, of the children of Merari, had sons: Shimri the first (for though he was not the firstborn, his father made him the first),", 
                "num": 10
              }, 
              {
                "text": "Hilkiah the second, Tebaliah the third, Zechariah the fourth; all the sons and brethren of Hosah were thirteen.", 
                "num": 11
              }, 
              {
                "text": "Among these were the divisions of the gatekeepers, among the chief men, having duties just like their brethren, to serve in the house of the LORD.", 
                "num": 12
              }, 
              {
                "text": "And they cast lots for each gate, the small as well as the great, according to their father's house.", 
                "num": 13
              }, 
              {
                "text": "The lot for the East Gate fell to Shelemiah. Then they cast lots for his son Zechariah, a wise counselor, and his lot came out for the North Gate;", 
                "num": 14
              }, 
              {
                "text": "to Obed-Edom the South Gate, and to his sons the storehouse.", 
                "num": 15
              }, 
              {
                "text": "To Shuppim and Hosah the lot came out for the West Gate, with the Shallecheth Gate on the ascending highway--watchman opposite watchman.", 
                "num": 16
              }, 
              {
                "text": "On the east were six Levites, on the north four each day, on the south four each day, and for the storehouse two by two.", 
                "num": 17
              }, 
              {
                "text": "As for the Parbar on the west, there were four on the highway and two at the Parbar.", 
                "num": 18
              }, 
              {
                "text": "These were the divisions of the gatekeepers among the sons of Korah and among the sons of Merari.", 
                "num": 19
              }, 
              {
                "text": "Of the Levites, Ahijah was over the treasuries of the house of God and over the treasuries of the dedicated things.", 
                "num": 20
              }, 
              {
                "text": "The sons of Laadan, the descendants of the Gershonites of Laadan, heads of their fathers' houses, of Laadan the Gershonite: Jehieli.", 
                "num": 21
              }, 
              {
                "text": "The sons of Jehieli, Zetham and Joel his brother, were over the treasuries of the house of the LORD.", 
                "num": 22
              }, 
              {
                "text": "Of the Amramites, the Izharites, the Hebronites, and the Uzzielites:", 
                "num": 23
              }, 
              {
                "text": "Shebuel the son of Gershom, the son of Moses, was overseer of the treasuries.", 
                "num": 24
              }, 
              {
                "text": "And his brethren by Eliezer were Rehabiah his son, Jeshaiah his son, Joram his son, Zichri his son, and Shelomith his son.", 
                "num": 25
              }, 
              {
                "text": "This Shelomith and his brethren were over all the treasuries of the dedicated things which King David and the heads of fathers' houses, the captains over thousands and hundreds, and the captains of the army, had dedicated.", 
                "num": 26
              }, 
              {
                "text": "Some of the spoils won in battles they dedicated to maintain the house of the LORD.", 
                "num": 27
              }, 
              {
                "text": "And all that Samuel the seer, Saul the son of Kish, Abner the son of Ner, and Joab the son of Zeruiah had dedicated, every dedicated thing, was under the hand of Shelomith and his brethren.", 
                "num": 28
              }, 
              {
                "text": "Of the Izharites, Chenaniah and his sons performed duties as officials and judges over Israel outside Jerusalem.", 
                "num": 29
              }, 
              {
                "text": "Of the Hebronites, Hashabiah and his brethren, one thousand seven hundred able men, had the oversight of Israel on the west side of the Jordan for all the business of the LORD, and in the service of the king.", 
                "num": 30
              }, 
              {
                "text": "Among the Hebronites, Jerijah was head of the Hebronites according to his genealogy of the fathers. In the fortieth year of the reign of David they were sought, and there were found among them capable men at Jazer of Gilead.", 
                "num": 31
              }, 
              {
                "text": "And his brethren were two thousand seven hundred able men, heads of fathers' houses, whom King David made officials over the Reubenites, the Gadites, and the half-tribe of Manasseh, for every matter pertaining to God and the affairs of the king.", 
                "num": 32
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "And the children of Israel, according to their number, the heads of fathers' houses, the captains of thousands and hundreds and their officers, served the king in every matter of the military divisions. These divisions came in and went out month by month throughout all the months of the year, each division having twenty-four thousand.", 
                "num": 1
              }, 
              {
                "text": "Over the first division for the first month was Jashobeam the son of Zabdiel, and in his division were twenty-four thousand;", 
                "num": 2
              }, 
              {
                "text": "he was of the children of Perez, and the chief of all the captains of the army for the first month.", 
                "num": 3
              }, 
              {
                "text": "Over the division of the second month was Dodai an Ahohite, and of his division Mikloth also was the leader; in his division were twenty-four thousand.", 
                "num": 4
              }, 
              {
                "text": "The third captain of the army for the third month was Benaiah, the son of Jehoiada the priest, who was chief; in his division were twenty-four thousand.", 
                "num": 5
              }, 
              {
                "text": "This was the Benaiah who was mighty among the thirty, and was over the thirty; in his division was Ammizabad his son.", 
                "num": 6
              }, 
              {
                "text": "The fourth captain for the fourth month was Asahel the brother of Joab, and Zebadiah his son after him; in his division were twenty-four thousand.", 
                "num": 7
              }, 
              {
                "text": "The fifth captain for the fifth month was Shamhuth the Izrahite; in his division were twenty-four thousand.", 
                "num": 8
              }, 
              {
                "text": "The sixth captain for the sixth month was Ira the son of Ikkesh the Tekoite; in his division were twenty-four thousand.", 
                "num": 9
              }, 
              {
                "text": "The seventh captain for the seventh month was Helez the Pelonite, of the children of Ephraim; in his division were twenty-four thousand.", 
                "num": 10
              }, 
              {
                "text": "The eighth captain for the eighth month was Sibbechai the Hushathite, of the Zarhites; in his division were twenty-four thousand.", 
                "num": 11
              }, 
              {
                "text": "The ninth captain for the ninth month was Abiezer the Anathothite, of the Benjamites; in his division were twenty-four thousand.", 
                "num": 12
              }, 
              {
                "text": "The tenth captain for the tenth month was Maharai the Netophathite, of the Zarhites; in his division were twenty-four thousand.", 
                "num": 13
              }, 
              {
                "text": "The eleventh captain for the eleventh month was Benaiah the Pirathonite, of the children of Ephraim; in his division were twenty-four thousand.", 
                "num": 14
              }, 
              {
                "text": "The twelfth captain for the twelfth month was Heldai the Netophathite, of Othniel; in his division were twenty-four thousand.", 
                "num": 15
              }, 
              {
                "text": "Furthermore, over the tribes of Israel: the officer over the Reubenites was Eliezer the son of Zichri; over the Simeonites, Shephatiah the son of Maachah;", 
                "num": 16
              }, 
              {
                "text": "over the Levites, Hashabiah the son of Kemuel; over the Aaronites, Zadok;", 
                "num": 17
              }, 
              {
                "text": "over Judah, Elihu, one of David's brothers; over Issachar, Omri the son of Michael;", 
                "num": 18
              }, 
              {
                "text": "over Zebulun, Ishmaiah the son of Obadiah; over Naphtali, Jerimoth the son of Azriel;", 
                "num": 19
              }, 
              {
                "text": "over the children of Ephraim, Hoshea the son of Azaziah; over the half-tribe of Manasseh, Joel the son of Pedaiah;", 
                "num": 20
              }, 
              {
                "text": "over the half-tribe of Manasseh in Gilead, Iddo the son of Zechariah; over Benjamin, Jaasiel the son of Abner;", 
                "num": 21
              }, 
              {
                "text": "over Dan, Azarel the son of Jeroham. These were the leaders of the tribes of Israel.", 
                "num": 22
              }, 
              {
                "text": "But David did not take the number of those twenty years old and under, because the LORD had said He would multiply Israel like the stars of the heavens.", 
                "num": 23
              }, 
              {
                "text": "Joab the son of Zeruiah began a census, but he did not finish, for wrath came upon Israel because of this census; nor was the number recorded in the account of the chronicles of King David.", 
                "num": 24
              }, 
              {
                "text": "And Azmaveth the son of Adiel was over the king's treasuries; and Jehonathan the son of Uzziah was over the storehouses in the field, in the cities, in the villages, and in the fortresses.", 
                "num": 25
              }, 
              {
                "text": "Ezri the son of Chelub was over those who did the work of the field for tilling the ground.", 
                "num": 26
              }, 
              {
                "text": "And Shimei the Ramathite was over the vineyards, and Zabdi the Shiphmite was over the produce of the vineyards for the supply of wine.", 
                "num": 27
              }, 
              {
                "text": "Baal-Hanan the Gederite was over the olive trees and the sycamore trees that were in the lowlands, and Joash was over the store of oil.", 
                "num": 28
              }, 
              {
                "text": "And Shitrai the Sharonite was over the herds that fed in Sharon, and Shaphat the son of Adlai was over the herds that were in the valleys.", 
                "num": 29
              }, 
              {
                "text": "Obil the Ishmaelite was over the camels, Jehdeiah the Meronothite was over the donkeys,", 
                "num": 30
              }, 
              {
                "text": "and Jaziz the Hagrite was over the flocks. All these were the officials over King David's property.", 
                "num": 31
              }, 
              {
                "text": "Also Jehonathan, David's uncle, was a counselor, a wise man, and a scribe; and Jehiel the son of Hachmoni was with the king's sons.", 
                "num": 32
              }, 
              {
                "text": "Ahithophel was the king's counselor, and Hushai the Archite was the king's companion.", 
                "num": 33
              }, 
              {
                "text": "After Ahithophel was Jehoiada the son of Benaiah, then Abiathar. And the general of the king's army was Joab.", 
                "num": 34
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Now David assembled at Jerusalem all the leaders of Israel: the officers of the tribes and the captains of the divisions who served the king, the captains over thousands and captains over hundreds, and the stewards over all the substance and possessions of the king and of his sons, with the officials, the valiant men, and all the mighty men of valor.", 
                "num": 1
              }, 
              {
                "text": "Then King David rose to his feet and said, \"Hear me, my brethren and my people: I had it in my heart to build a house of rest for the ark of the covenant of the LORD, and for the footstool of our God, and had made preparations to build it.", 
                "num": 2
              }, 
              {
                "text": "But God said to me, \"You shall not build a house for My name, because you have been a man of war and have shed blood.'", 
                "num": 3
              }, 
              {
                "text": "However the LORD God of Israel chose me above all the house of my father to be king over Israel forever, for He has chosen Judah to be the ruler. And of the house of Judah, the house of my father, and among the sons of my father, He was pleased with me to make me king over all Israel.", 
                "num": 4
              }, 
              {
                "text": "And of all my sons (for the LORD has given me many sons) He has chosen my son Solomon to sit on the throne of the kingdom of the LORD over Israel.", 
                "num": 5
              }, 
              {
                "text": "Now He said to me, \"It is your son Solomon who shall build My house and My courts; for I have chosen him to be My son, and I will be his Father.", 
                "num": 6
              }, 
              {
                "text": "Moreover I will establish his kingdom forever, if he is steadfast to observe My commandments and My judgments, as it is this day.'", 
                "num": 7
              }, 
              {
                "text": "Now therefore, in the sight of all Israel, the assembly of the LORD, and in the hearing of our God, be careful to seek out all the commandments of the LORD your God, that you may possess this good land, and leave it as an inheritance for your children after you forever.", 
                "num": 8
              }, 
              {
                "text": "\"As for you, my son Solomon, know the God of your father, and serve Him with a loyal heart and with a willing mind; for the LORD searches all hearts and understands all the intent of the thoughts. If you seek Him, He will be found by you; but if you forsake Him, He will cast you off forever.", 
                "num": 9
              }, 
              {
                "text": "Consider now, for the LORD has chosen you to build a house for the sanctuary; be strong, and do it.\"", 
                "num": 10
              }, 
              {
                "text": "Then David gave his son Solomon the plans for the vestibule, its houses, its treasuries, its upper chambers, its inner chambers, and the place of the mercy seat;", 
                "num": 11
              }, 
              {
                "text": "and the plans for all that he had by the Spirit, of the courts of the house of the LORD, of all the chambers all around, of the treasuries of the house of God, and of the treasuries for the dedicated things;", 
                "num": 12
              }, 
              {
                "text": "also for the division of the priests and the Levites, for all the work of the service of the house of the LORD, and for all the articles of service in the house of the LORD.", 
                "num": 13
              }, 
              {
                "text": "He gave gold by weight for things of gold, for all articles used in every kind of service; also silver for all articles of silver by weight, for all articles used in every kind of service;", 
                "num": 14
              }, 
              {
                "text": "the weight for the lampstands of gold, and their lamps of gold, by weight for each lampstand and its lamps; for the lampstands of silver by weight, for the lampstand and its lamps, according to the use of each lampstand.", 
                "num": 15
              }, 
              {
                "text": "And by weight he gave gold for the tables of the showbread, for each table, and silver for the tables of silver;", 
                "num": 16
              }, 
              {
                "text": "also pure gold for the forks, the basins, the pitchers of pure gold, and the golden bowls--he gave gold by weight for every bowl; and for the silver bowls, silver by weight for every bowl;", 
                "num": 17
              }, 
              {
                "text": "and refined gold by weight for the altar of incense, and for the construction of the chariot, that is, the gold cherubim that spread their wings and overshadowed the ark of the covenant of the LORD.", 
                "num": 18
              }, 
              {
                "text": "\"All this,\" said David, \"the LORD made me understand in writing, by His hand upon me, all the works of these plans.\"", 
                "num": 19
              }, 
              {
                "text": "And David said to his son Solomon, \"Be strong and of good courage, and do it; do not fear nor be dismayed, for the LORD God--my God--will be with you. He will not leave you nor forsake you, until you have finished all the work for the service of the house of the LORD.", 
                "num": 20
              }, 
              {
                "text": "Here are the divisions of the priests and the Levites for all the service of the house of God; and every willing craftsman will be with you for all manner of workmanship, for every kind of service; also the leaders and all the people will be completely at your command.\"", 
                "num": 21
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Furthermore King David said to all the assembly: \"My son Solomon, whom alone God has chosen, is young and inexperienced; and the work is great, because the temple is not for man but for the LORD God.", 
                "num": 1
              }, 
              {
                "text": "Now for the house of my God I have prepared with all my might: gold for things to be made of gold, silver for things of silver, bronze for things of bronze, iron for things of iron, wood for things of wood, onyx stones, stones to be set, glistening stones of various colors, all kinds of precious stones, and marble slabs in abundance.", 
                "num": 2
              }, 
              {
                "text": "Moreover, because I have set my affection on the house of my God, I have given to the house of my God, over and above all that I have prepared for the holy house, my own special treasure of gold and silver:", 
                "num": 3
              }, 
              {
                "text": "three thousand talents of gold, of the gold of Ophir, and seven thousand talents of refined silver, to overlay the walls of the houses;", 
                "num": 4
              }, 
              {
                "text": "the gold for things of gold and the silver for things of silver, and for all kinds of work to be done by the hands of craftsmen. Who then is willing to consecrate himself this day to the LORD?\"", 
                "num": 5
              }, 
              {
                "text": "Then the leaders of the fathers' houses, leaders of the tribes of Israel, the captains of thousands and of hundreds, with the officers over the king's work, offered willingly.", 
                "num": 6
              }, 
              {
                "text": "They gave for the work of the house of God five thousand talents and ten thousand darics of gold, ten thousand talents of silver, eighteen thousand talents of bronze, and one hundred thousand talents of iron.", 
                "num": 7
              }, 
              {
                "text": "And whoever had precious stones gave them to the treasury of the house of the LORD, into the hand of Jehiel the Gershonite.", 
                "num": 8
              }, 
              {
                "text": "Then the people rejoiced, for they had offered willingly, because with a loyal heart they had offered willingly to the LORD; and King David also rejoiced greatly.", 
                "num": 9
              }, 
              {
                "text": "Therefore David blessed the LORD before all the assembly; and David said: \"Blessed are You, LORD God of Israel, our Father, forever and ever.", 
                "num": 10
              }, 
              {
                "text": "Yours, O LORD, is the greatness, The power and the glory, The victory and the majesty; For all that is in heaven and in earth is Yours; Yours is the kingdom, O LORD, And You are exalted as head over all.", 
                "num": 11
              }, 
              {
                "text": "Both riches and honor come from You, And You reign over all. In Your hand is power and might; In Your hand it is to make great And to give strength to all.", 
                "num": 12
              }, 
              {
                "text": "\"Now therefore, our God, We thank You And praise Your glorious name.", 
                "num": 13
              }, 
              {
                "text": "But who am I, and who are my people, That we should be able to offer so willingly as this? For all things come from You, And of Your own we have given You.", 
                "num": 14
              }, 
              {
                "text": "For we are aliens and pilgrims before You, As were all our fathers; Our days on earth are as a shadow, And without hope.", 
                "num": 15
              }, 
              {
                "text": "\"O LORD our God, all this abundance that we have prepared to build You a house for Your holy name is from Your hand, and is all Your own.", 
                "num": 16
              }, 
              {
                "text": "I know also, my God, that You test the heart and have pleasure in uprightness. As for me, in the uprightness of my heart I have willingly offered all these things; and now with joy I have seen Your people, who are present here to offer willingly to You.", 
                "num": 17
              }, 
              {
                "text": "O LORD God of Abraham, Isaac, and Israel, our fathers, keep this forever in the intent of the thoughts of the heart of Your people, and fix their heart toward You.", 
                "num": 18
              }, 
              {
                "text": "And give my son Solomon a loyal heart to keep Your commandments and Your testimonies and Your statutes, to do all these things, and to build the temple for which I have made provision.\"", 
                "num": 19
              }, 
              {
                "text": "Then David said to all the assembly, \"Now bless the LORD your God.\" So all the assembly blessed the LORD God of their fathers, and bowed their heads and prostrated themselves before the LORD and the king.", 
                "num": 20
              }, 
              {
                "text": "And they made sacrifices to the LORD and offered burnt offerings to the LORD on the next day: a thousand bulls, a thousand rams, a thousand lambs, with their drink offerings, and sacrifices in abundance for all Israel.", 
                "num": 21
              }, 
              {
                "text": "So they ate and drank before the LORD with great gladness on that day. And they made Solomon the son of David king the second time, and anointed him before the LORD to be the leader, and Zadok to be priest.", 
                "num": 22
              }, 
              {
                "text": "Then Solomon sat on the throne of the LORD as king instead of David his father, and prospered; and all Israel obeyed him.", 
                "num": 23
              }, 
              {
                "text": "All the leaders and the mighty men, and also all the sons of King David, submitted themselves to King Solomon.", 
                "num": 24
              }, 
              {
                "text": "So the LORD exalted Solomon exceedingly in the sight of all Israel, and bestowed on him such royal majesty as had not been on any king before him in Israel.", 
                "num": 25
              }, 
              {
                "text": "Thus David the son of Jesse reigned over all Israel.", 
                "num": 26
              }, 
              {
                "text": "And the period that he reigned over Israel was forty years; seven years he reigned in Hebron, and thirty-three years he reigned in Jerusalem.", 
                "num": 27
              }, 
              {
                "text": "So he died in a good old age, full of days and riches and honor; and Solomon his son reigned in his place.", 
                "num": 28
              }, 
              {
                "text": "Now the acts of King David, first and last, indeed they are written in the book of Samuel the seer, in the book of Nathan the prophet, and in the book of Gad the seer,", 
                "num": 29
              }, 
              {
                "text": "with all his reign and his might, and the events that happened to him, to Israel, and to all the kingdoms of the lands.", 
                "num": 30
              }
            ], 
            "num": 29
          }
        ]
      }, 
      {
        "name": "2 Chronicles", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now Solomon the son of David was strengthened in his kingdom, and the LORD his God was with him and exalted him exceedingly.", 
                "num": 1
              }, 
              {
                "text": "And Solomon spoke to all Israel, to the captains of thousands and of hundreds, to the judges, and to every leader in all Israel, the heads of the fathers' houses.", 
                "num": 2
              }, 
              {
                "text": "Then Solomon, and all the assembly with him, went to the high place that was at Gibeon; for the tabernacle of meeting with God was there, which Moses the servant of the LORD had made in the wilderness.", 
                "num": 3
              }, 
              {
                "text": "But David had brought up the ark of God from Kirjath Jearim to the place David had prepared for it, for he had pitched a tent for it at Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "Now the bronze altar that Bezalel the son of Uri, the son of Hur, had made, he put before the tabernacle of the LORD; Solomon and the assembly sought Him there.", 
                "num": 5
              }, 
              {
                "text": "And Solomon went up there to the bronze altar before the LORD, which was at the tabernacle of meeting, and offered a thousand burnt offerings on it.", 
                "num": 6
              }, 
              {
                "text": "On that night God appeared to Solomon, and said to him, \"Ask! What shall I give you?\"", 
                "num": 7
              }, 
              {
                "text": "And Solomon said to God: \"You have shown great mercy to David my father, and have made me king in his place.", 
                "num": 8
              }, 
              {
                "text": "Now, O LORD God, let Your promise to David my father be established, for You have made me king over a people like the dust of the earth in multitude.", 
                "num": 9
              }, 
              {
                "text": "Now give me wisdom and knowledge, that I may go out and come in before this people; for who can judge this great people of Yours?\"", 
                "num": 10
              }, 
              {
                "text": "Then God said to Solomon: \"Because this was in your heart, and you have not asked riches or wealth or honor or the life of your enemies, nor have you asked long life--but have asked wisdom and knowledge for yourself, that you may judge My people over whom I have made you king--", 
                "num": 11
              }, 
              {
                "text": "wisdom and knowledge are granted to you; and I will give you riches and wealth and honor, such as none of the kings have had who were before you, nor shall any after you have the like.\"", 
                "num": 12
              }, 
              {
                "text": "So Solomon came to Jerusalem from the high place that was at Gibeon, from before the tabernacle of meeting, and reigned over Israel.", 
                "num": 13
              }, 
              {
                "text": "And Solomon gathered chariots and horsemen; he had one thousand four hundred chariots and twelve thousand horsemen, whom he stationed in the chariot cities and with the king in Jerusalem.", 
                "num": 14
              }, 
              {
                "text": "Also the king made silver and gold as common in Jerusalem as stones, and he made cedars as abundant as the sycamores which are in the lowland.", 
                "num": 15
              }, 
              {
                "text": "And Solomon had horses imported from Egypt and Keveh; the king's merchants bought them in Keveh at the current price.", 
                "num": 16
              }, 
              {
                "text": "They also acquired and imported from Egypt a chariot for six hundred shekels of silver, and a horse for one hundred and fifty; thus, through their agents, they exported them to all the kings of the Hittites and the kings of Syria.", 
                "num": 17
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Then Solomon determined to build a temple for the name of the LORD, and a royal house for himself.", 
                "num": 1
              }, 
              {
                "text": "Solomon selected seventy thousand men to bear burdens, eighty thousand to quarry stone in the mountains, and three thousand six hundred to oversee them.", 
                "num": 2
              }, 
              {
                "text": "Then Solomon sent to Hiram king of Tyre, saying: 4 As you have dealt with David my father, and sent him cedars to build himself a house to dwell in, so deal with me.", 
                "num": 3
              }, 
              {
                "text": "Behold, I am building a temple for the name of the LORD my God, to dedicate it to Him, to burn before Him sweet incense, for the continual showbread, for the burnt offerings morning and evening, on the Sabbaths, on the New Moons, and on the set feasts of the LORD our God. This is an ordinance forever to Israel.", 
                "num": 4
              }, 
              {
                "text": "And the temple which I build will be great, for our God is greater than all gods.", 
                "num": 5
              }, 
              {
                "text": "But who is able to build Him a temple, since heaven and the heaven of heavens cannot contain Him? Who am I then, that I should build Him a temple, except to burn sacrifice before Him?", 
                "num": 6
              }, 
              {
                "text": "Therefore send me at once a man skillful to work in gold and silver, in bronze and iron, in purple and crimson and blue, who has skill to engrave with the skillful men who are with me in Judah and Jerusalem, whom David my father provided.", 
                "num": 7
              }, 
              {
                "text": "Also send me cedar and cypress and algum logs from Lebanon, for I know that your servants have skill to cut timber in Lebanon; and indeed my servants will be with your servants,", 
                "num": 8
              }, 
              {
                "text": "to prepare timber for me in abundance, for the temple which I am about to build shall be great and wonderful.", 
                "num": 9
              }, 
              {
                "text": "And indeed I will give to your servants, the woodsmen who cut timber, twenty thousand kors of ground wheat, twenty thousand kors of barley, twenty thousand baths of wine, and twenty thousand baths of oil.", 
                "num": 10
              }, 
              {
                "text": "Then Hiram king of Tyre answered in writing, which he sent to Solomon: 4 Because the LORD loves His people, He has made you king over them.", 
                "num": 11
              }, 
              {
                "text": "Hiram also said: 4 Blessed be the LORD God of Israel, who made heaven and earth, for He has given King David a wise son, endowed with prudence and understanding, who will build a temple for the LORD and a royal house for himself!", 
                "num": 12
              }, 
              {
                "text": "And now I have sent a skillful man, endowed with understanding, Huram my master craftsman", 
                "num": 13
              }, 
              {
                "text": "(the son of a woman of the daughters of Dan, and his father was a man of Tyre), skilled to work in gold and silver, bronze and iron, stone and wood, purple and blue, fine linen and crimson, and to make any engraving and to accomplish any plan which may be given to him, with your skillful men and with the skillful men of my lord David your father.", 
                "num": 14
              }, 
              {
                "text": "Now therefore, the wheat, the barley, the oil, and the wine which my lord has spoken of, let him send to his servants.", 
                "num": 15
              }, 
              {
                "text": "And we will cut wood from Lebanon, as much as you need; we will bring it to you in rafts by sea to Joppa, and you will carry it up to Jerusalem.", 
                "num": 16
              }, 
              {
                "text": "Then Solomon numbered all the aliens who were in the land of Israel, after the census in which David his father had numbered them; and there were found to be one hundred and fifty-three thousand six hundred.", 
                "num": 17
              }, 
              {
                "text": "And he made seventy thousand of them bearers of burdens, eighty thousand stonecutters in the mountain, and three thousand six hundred overseers to make the people work.", 
                "num": 18
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now Solomon began to build the house of the LORD at Jerusalem on Mount Moriah, where the LORD had appeared to his father David, at the place that David had prepared on the threshing floor of Ornan the Jebusite.", 
                "num": 1
              }, 
              {
                "text": "And he began to build on the second day of the second month in the fourth year of his reign.", 
                "num": 2
              }, 
              {
                "text": "This is the foundation which Solomon laid for building the house of God: The length was sixty cubits (by cubits according to the former measure) and the width twenty cubits.", 
                "num": 3
              }, 
              {
                "text": "And the vestibule that was in front of the sanctuary was twenty cubits long across the width of the house, and the height was one hundred and twenty. He overlaid the inside with pure gold.", 
                "num": 4
              }, 
              {
                "text": "The larger room he paneled with cypress which he overlaid with fine gold, and he carved palm trees and chainwork on it.", 
                "num": 5
              }, 
              {
                "text": "And he decorated the house with precious stones for beauty, and the gold was gold from Parvaim.", 
                "num": 6
              }, 
              {
                "text": "He also overlaid the house--the beams and doorposts, its walls and doors--with gold; and he carved cherubim on the walls.", 
                "num": 7
              }, 
              {
                "text": "And he made the Most Holy Place. Its length was according to the width of the house, twenty cubits, and its width twenty cubits. He overlaid it with six hundred talents of fine gold.", 
                "num": 8
              }, 
              {
                "text": "The weight of the nails was fifty shekels of gold; and he overlaid the upper area with gold.", 
                "num": 9
              }, 
              {
                "text": "In the Most Holy Place he made two cherubim, fashioned by carving, and overlaid them with gold.", 
                "num": 10
              }, 
              {
                "text": "The wings of the cherubim were twenty cubits in overall length: one wing of the one cherub was five cubits, touching the wall of the room, and the other wing was five cubits, touching the wing of the other cherub;", 
                "num": 11
              }, 
              {
                "text": "one wing of the other cherub was five cubits, touching the wall of the room, and the other wing also was five cubits, touching the wing of the other cherub.", 
                "num": 12
              }, 
              {
                "text": "The wings of these cherubim spanned twenty cubits overall. They stood on their feet, and they faced inward.", 
                "num": 13
              }, 
              {
                "text": "And he made the veil of blue, purple, crimson, and fine linen, and wove cherubim into it.", 
                "num": 14
              }, 
              {
                "text": "Also he made in front of the temple two pillars thirty-five cubits high, and the capital that was on the top of each of them was five cubits.", 
                "num": 15
              }, 
              {
                "text": "He made wreaths of chainwork, as in the inner sanctuary, and put them on top of the pillars; and he made one hundred pomegranates, and put them on the wreaths of chainwork.", 
                "num": 16
              }, 
              {
                "text": "Then he set up the pillars before the temple, one on the right hand and the other on the left; he called the name of the one on the right hand Jachin, and the name of the one on the left Boaz.", 
                "num": 17
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Moreover he made a bronze altar: twenty cubits was its length, twenty cubits its width, and ten cubits its height.", 
                "num": 1
              }, 
              {
                "text": "Then he made the Sea of cast bronze, ten cubits from one brim to the other; it was completely round. Its height was five cubits, and a line of thirty cubits measured its circumference.", 
                "num": 2
              }, 
              {
                "text": "And under it was the likeness of oxen encircling it all around, ten to a cubit, all the way around the Sea. The oxen were cast in two rows, when it was cast.", 
                "num": 3
              }, 
              {
                "text": "It stood on twelve oxen: three looking toward the north, three looking toward the west, three looking toward the south, and three looking toward the east; the Sea was set upon them, and all their back parts pointed inward.", 
                "num": 4
              }, 
              {
                "text": "It was a handbreadth thick; and its brim was shaped like the brim of a cup, like a lily blossom. It contained three thousand baths.", 
                "num": 5
              }, 
              {
                "text": "He also made ten lavers, and put five on the right side and five on the left, to wash in them; such things as they offered for the burnt offering they would wash in them, but the Sea was for the priests to wash in.", 
                "num": 6
              }, 
              {
                "text": "And he made ten lampstands of gold according to their design, and set them in the temple, five on the right side and five on the left.", 
                "num": 7
              }, 
              {
                "text": "He also made ten tables, and placed them in the temple, five on the right side and five on the left. And he made one hundred bowls of gold.", 
                "num": 8
              }, 
              {
                "text": "Furthermore he made the court of the priests, and the great court and doors for the court; and he overlaid these doors with bronze.", 
                "num": 9
              }, 
              {
                "text": "He set the Sea on the right side, toward the southeast.", 
                "num": 10
              }, 
              {
                "text": "Then Huram made the pots and the shovels and the bowls. So Huram finished doing the work that he was to do for King Solomon for the house of God:", 
                "num": 11
              }, 
              {
                "text": "the two pillars and the bowl-shaped capitals that were on top of the two pillars; the two networks covering the two bowl-shaped capitals which were on top of the pillars;", 
                "num": 12
              }, 
              {
                "text": "four hundred pomegranates for the two networks (two rows of pomegranates for each network, to cover the two bowl-shaped capitals that were on the pillars);", 
                "num": 13
              }, 
              {
                "text": "he also made carts and the lavers on the carts;", 
                "num": 14
              }, 
              {
                "text": "one Sea and twelve oxen under it;", 
                "num": 15
              }, 
              {
                "text": "also the pots, the shovels, the forks--and all their articles Huram his master craftsman made of burnished bronze for King Solomon for the house of the LORD.", 
                "num": 16
              }, 
              {
                "text": "In the plain of Jordan the king had them cast in clay molds, between Succoth and Zeredah.", 
                "num": 17
              }, 
              {
                "text": "And Solomon had all these articles made in such great abundance that the weight of the bronze was not determined.", 
                "num": 18
              }, 
              {
                "text": "Thus Solomon had all the furnishings made for the house of God: the altar of gold and the tables on which was the showbread;", 
                "num": 19
              }, 
              {
                "text": "the lampstands with their lamps of pure gold, to burn in the prescribed manner in front of the inner sanctuary,", 
                "num": 20
              }, 
              {
                "text": "with the flowers and the lamps and the wick-trimmers of gold, of purest gold;", 
                "num": 21
              }, 
              {
                "text": "the trimmers, the bowls, the ladles, and the censers of pure gold. As for the entry of the sanctuary, its inner doors to the Most Holy Place, and the doors of the main hall of the temple, were gold.", 
                "num": 22
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "So all the work that Solomon had done for the house of the LORD was finished; and Solomon brought in the things which his father David had dedicated: the silver and the gold and all the furnishings. And he put them in the treasuries of the house of God.", 
                "num": 1
              }, 
              {
                "text": "Now Solomon assembled the elders of Israel and all the heads of the tribes, the chief fathers of the children of Israel, in Jerusalem, that they might bring the ark of the covenant of the LORD up from the City of David, which is Zion.", 
                "num": 2
              }, 
              {
                "text": "Therefore all the men of Israel assembled with the king at the feast, which was in the seventh month.", 
                "num": 3
              }, 
              {
                "text": "So all the elders of Israel came, and the Levites took up the ark.", 
                "num": 4
              }, 
              {
                "text": "Then they brought up the ark, the tabernacle of meeting, and all the holy furnishings that were in the tabernacle. The priests and the Levites brought them up.", 
                "num": 5
              }, 
              {
                "text": "Also King Solomon, and all the congregation of Israel who were assembled with him before the ark, were sacrificing sheep and oxen that could not be counted or numbered for multitude.", 
                "num": 6
              }, 
              {
                "text": "Then the priests brought in the ark of the covenant of the LORD to its place, into the inner sanctuary of the temple, to the Most Holy Place, under the wings of the cherubim.", 
                "num": 7
              }, 
              {
                "text": "For the cherubim spread their wings over the place of the ark, and the cherubim overshadowed the ark and its poles.", 
                "num": 8
              }, 
              {
                "text": "The poles extended so that the ends of the poles of the ark could be seen from the holy place, in front of the inner sanctuary; but they could not be seen from outside. And they are there to this day.", 
                "num": 9
              }, 
              {
                "text": "Nothing was in the ark except the two tablets which Moses put there at Horeb, when the LORD made a covenant with the children of Israel, when they had come out of Egypt.", 
                "num": 10
              }, 
              {
                "text": "And it came to pass when the priests came out of the Most Holy Place (for all the priests who were present had sanctified themselves, without keeping to their divisions),", 
                "num": 11
              }, 
              {
                "text": "and the Levites who were the singers, all those of Asaph and Heman and Jeduthun, with their sons and their brethren, stood at the east end of the altar, clothed in white linen, having cymbals, stringed instruments and harps, and with them one hundred and twenty priests sounding with trumpets--", 
                "num": 12
              }, 
              {
                "text": "indeed it came to pass, when the trumpeters and singers were as one, to make one sound to be heard in praising and thanking the LORD, and when they lifted up their voice with the trumpets and cymbals and instruments of music, and praised the LORD, saying: \"For He is good, For His mercy endures forever,\" that the house, the house of the LORD, was filled with a cloud,", 
                "num": 13
              }, 
              {
                "text": "so that the priests could not continue ministering because of the cloud; for the glory of the LORD filled the house of God.", 
                "num": 14
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then Solomon spoke: \"The LORD said He would dwell in the dark cloud.", 
                "num": 1
              }, 
              {
                "text": "I have surely built You an exalted house, And a place for You to dwell in forever.\"", 
                "num": 2
              }, 
              {
                "text": "Then the king turned around and blessed the whole assembly of Israel, while all the assembly of Israel was standing.", 
                "num": 3
              }, 
              {
                "text": "And he said: \"Blessed be the LORD God of Israel, who has fulfilled with His hands what He spoke with His mouth to my father David, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Since the day that I brought My people out of the land of Egypt, I have chosen no city from any tribe of Israel in which to build a house, that My name might be there, nor did I choose any man to be a ruler over My people Israel.", 
                "num": 5
              }, 
              {
                "text": "Yet I have chosen Jerusalem, that My name may be there, and I have chosen David to be over My people Israel.'", 
                "num": 6
              }, 
              {
                "text": "Now it was in the heart of my father David to build a temple for the name of the LORD God of Israel.", 
                "num": 7
              }, 
              {
                "text": "But the LORD said to my father David, \"Whereas it was in your heart to build a temple for My name, you did well in that it was in your heart.", 
                "num": 8
              }, 
              {
                "text": "Nevertheless you shall not build the temple, but your son who will come from your body, he shall build the temple for My name.'", 
                "num": 9
              }, 
              {
                "text": "So the LORD has fulfilled His word which He spoke, and I have filled the position of my father David, and sit on the throne of Israel, as the LORD promised; and I have built the temple for the name of the LORD God of Israel.", 
                "num": 10
              }, 
              {
                "text": "And there I have put the ark, in which is the covenant of the LORD which He made with the children of Israel.\"", 
                "num": 11
              }, 
              {
                "text": "Then Solomon stood before the altar of the LORD in the presence of all the assembly of Israel, and spread out his hands", 
                "num": 12
              }, 
              {
                "text": "(for Solomon had made a bronze platform five cubits long, five cubits wide, and three cubits high, and had set it in the midst of the court; and he stood on it, knelt down on his knees before all the assembly of Israel, and spread out his hands toward heaven);", 
                "num": 13
              }, 
              {
                "text": "and he said: \"LORD God of Israel, there is no God in heaven or on earth like You, who keep Your covenant and mercy with Your servants who walk before You with all their hearts.", 
                "num": 14
              }, 
              {
                "text": "You have kept what You promised Your servant David my father; You have both spoken with Your mouth and fulfilled it with Your hand, as it is this day.", 
                "num": 15
              }, 
              {
                "text": "Therefore, LORD God of Israel, now keep what You promised Your servant David my father, saying, \"You shall not fail to have a man sit before Me on the throne of Israel, only if your sons take heed to their way, that they walk in My law as you have walked before Me.'", 
                "num": 16
              }, 
              {
                "text": "And now, O LORD God of Israel, let Your word come true, which You have spoken to Your servant David.", 
                "num": 17
              }, 
              {
                "text": "\"But will God indeed dwell with men on the earth? Behold, heaven and the heaven of heavens cannot contain You. How much less this temple which I have built!", 
                "num": 18
              }, 
              {
                "text": "Yet regard the prayer of Your servant and his supplication, O LORD my God, and listen to the cry and the prayer which Your servant is praying before You:", 
                "num": 19
              }, 
              {
                "text": "that Your eyes may be open toward this temple day and night, toward the place where You said You would put Your name, that You may hear the prayer which Your servant makes toward this place.", 
                "num": 20
              }, 
              {
                "text": "And may You hear the supplications of Your servant and of Your people Israel, when they pray toward this place. Hear from heaven Your dwelling place, and when You hear, forgive.", 
                "num": 21
              }, 
              {
                "text": "\"If anyone sins against his neighbor, and is forced to take an oath, and comes and takes an oath before Your altar in this temple,", 
                "num": 22
              }, 
              {
                "text": "then hear from heaven, and act, and judge Your servants, bringing retribution on the wicked by bringing his way on his own head, and justifying the righteous by giving him according to his righteousness.", 
                "num": 23
              }, 
              {
                "text": "\"Or if Your people Israel are defeated before an enemy because they have sinned against You, and return and confess Your name, and pray and make supplication before You in this temple,", 
                "num": 24
              }, 
              {
                "text": "then hear from heaven and forgive the sin of Your people Israel, and bring them back to the land which You gave to them and their fathers.", 
                "num": 25
              }, 
              {
                "text": "\"When the heavens are shut up and there is no rain because they have sinned against You, when they pray toward this place and confess Your name, and turn from their sin because You afflict them,", 
                "num": 26
              }, 
              {
                "text": "then hear in heaven, and forgive the sin of Your servants, Your people Israel, that You may teach them the good way in which they should walk; and send rain on Your land which You have given to Your people as an inheritance.", 
                "num": 27
              }, 
              {
                "text": "\"When there is famine in the land, pestilence or blight or mildew, locusts or grasshoppers; when their enemies besiege them in the land of their cities; whatever plague or whatever sickness there is;", 
                "num": 28
              }, 
              {
                "text": "whatever prayer, whatever supplication is made by anyone, or by all Your people Israel, when each one knows his own burden and his own grief, and spreads out his hands to this temple:", 
                "num": 29
              }, 
              {
                "text": "then hear from heaven Your dwelling place, and forgive, and give to everyone according to all his ways, whose heart You know (for You alone know the hearts of the sons of men),", 
                "num": 30
              }, 
              {
                "text": "that they may fear You, to walk in Your ways as long as they live in the land which You gave to our fathers.", 
                "num": 31
              }, 
              {
                "text": "\"Moreover, concerning a foreigner, who is not of Your people Israel, but has come from a far country for the sake of Your great name and Your mighty hand and Your outstretched arm, when they come and pray in this temple;", 
                "num": 32
              }, 
              {
                "text": "then hear from heaven Your dwelling place, and do according to all for which the foreigner calls to You, that all peoples of the earth may know Your name and fear You, as do Your people Israel, and that they may know that this temple which I have built is called by Your name.", 
                "num": 33
              }, 
              {
                "text": "\"When Your people go out to battle against their enemies, wherever You send them, and when they pray to You toward this city which You have chosen and the temple which I have built for Your name,", 
                "num": 34
              }, 
              {
                "text": "then hear from heaven their prayer and their supplication, and maintain their cause.", 
                "num": 35
              }, 
              {
                "text": "\"When they sin against You (for there is no one who does not sin), and You become angry with them and deliver them to the enemy, and they take them captive to a land far or near;", 
                "num": 36
              }, 
              {
                "text": "yet when they come to themselves in the land where they were carried captive, and repent, and make supplication to You in the land of their captivity, saying, \"We have sinned, we have done wrong, and have committed wickedness';", 
                "num": 37
              }, 
              {
                "text": "and when they return to You with all their heart and with all their soul in the land of their captivity, where they have been carried captive, and pray toward their land which You gave to their fathers, the city which You have chosen, and toward the temple which I have built for Your name:", 
                "num": 38
              }, 
              {
                "text": "then hear from heaven Your dwelling place their prayer and their supplications, and maintain their cause, and forgive Your people who have sinned against You.", 
                "num": 39
              }, 
              {
                "text": "Now, my God, I pray, let Your eyes be open and let Your ears be attentive to the prayer made in this place.", 
                "num": 40
              }, 
              {
                "text": "\"Now therefore, Arise, O LORD God, to Your resting place, You and the ark of Your strength. Let Your priests, O LORD God, be clothed with salvation, And let Your saints rejoice in goodness.", 
                "num": 41
              }, 
              {
                "text": "\"O LORD God, do not turn away the face of Your Anointed; Remember the mercies of Your servant David.\"", 
                "num": 42
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "When Solomon had finished praying, fire came down from heaven and consumed the burnt offering and the sacrifices; and the glory of the LORD filled the temple.", 
                "num": 1
              }, 
              {
                "text": "And the priests could not enter the house of the LORD, because the glory of the LORD had filled the LORD's house.", 
                "num": 2
              }, 
              {
                "text": "When all the children of Israel saw how the fire came down, and the glory of the LORD on the temple, they bowed their faces to the ground on the pavement, and worshiped and praised the LORD, saying: \"For He is good, For His mercy endures forever.\"", 
                "num": 3
              }, 
              {
                "text": "Then the king and all the people offered sacrifices before the LORD.", 
                "num": 4
              }, 
              {
                "text": "King Solomon offered a sacrifice of twenty-two thousand bulls and one hundred and twenty thousand sheep. So the king and all the people dedicated the house of God.", 
                "num": 5
              }, 
              {
                "text": "And the priests attended to their services; the Levites also with instruments of the music of the LORD, which King David had made to praise the LORD, saying, \"For His mercy endures forever,\" whenever David offered praise by their ministry. The priests sounded trumpets opposite them, while all Israel stood.", 
                "num": 6
              }, 
              {
                "text": "Furthermore Solomon consecrated the middle of the court that was in front of the house of the LORD; for there he offered burnt offerings and the fat of the peace offerings, because the bronze altar which Solomon had made was not able to receive the burnt offerings, the grain offerings, and the fat.", 
                "num": 7
              }, 
              {
                "text": "At that time Solomon kept the feast seven days, and all Israel with him, a very great assembly from the entrance of Hamath to the Brook of Egypt.", 
                "num": 8
              }, 
              {
                "text": "And on the eighth day they held a sacred assembly, for they observed the dedication of the altar seven days, and the feast seven days.", 
                "num": 9
              }, 
              {
                "text": "On the twenty-third day of the seventh month he sent the people away to their tents, joyful and glad of heart for the good that the LORD had done for David, for Solomon, and for His people Israel.", 
                "num": 10
              }, 
              {
                "text": "Thus Solomon finished the house of the LORD and the king's house; and Solomon successfully accomplished all that came into his heart to make in the house of the LORD and in his own house.", 
                "num": 11
              }, 
              {
                "text": "Then the LORD appeared to Solomon by night, and said to him: \"I have heard your prayer, and have chosen this place for Myself as a house of sacrifice.", 
                "num": 12
              }, 
              {
                "text": "When I shut up heaven and there is no rain, or command the locusts to devour the land, or send pestilence among My people,", 
                "num": 13
              }, 
              {
                "text": "if My people who are called by My name will humble themselves, and pray and seek My face, and turn from their wicked ways, then I will hear from heaven, and will forgive their sin and heal their land.", 
                "num": 14
              }, 
              {
                "text": "Now My eyes will be open and My ears attentive to prayer made in this place.", 
                "num": 15
              }, 
              {
                "text": "For now I have chosen and sanctified this house, that My name may be there forever; and My eyes and My heart will be there perpetually.", 
                "num": 16
              }, 
              {
                "text": "As for you, if you walk before Me as your father David walked, and do according to all that I have commanded you, and if you keep My statutes and My judgments,", 
                "num": 17
              }, 
              {
                "text": "then I will establish the throne of your kingdom, as I covenanted with David your father, saying, \"You shall not fail to have a man as ruler in Israel.'", 
                "num": 18
              }, 
              {
                "text": "\"But if you turn away and forsake My statutes and My commandments which I have set before you, and go and serve other gods, and worship them,", 
                "num": 19
              }, 
              {
                "text": "then I will uproot them from My land which I have given them; and this house which I have sanctified for My name I will cast out of My sight, and will make it a proverb and a byword among all peoples.", 
                "num": 20
              }, 
              {
                "text": "\"And as for this house, which is exalted, everyone who passes by it will be astonished and say, \"Why has the LORD done thus to this land and this house?'", 
                "num": 21
              }, 
              {
                "text": "Then they will answer, \"Because they forsook the LORD God of their fathers, who brought them out of the land of Egypt, and embraced other gods, and worshiped them and served them; therefore He has brought all this calamity on them.\"'", 
                "num": 22
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "It came to pass at the end of twenty years, when Solomon had built the house of the LORD and his own house,", 
                "num": 1
              }, 
              {
                "text": "that the cities which Hiram had given to Solomon, Solomon built them; and he settled the children of Israel there.", 
                "num": 2
              }, 
              {
                "text": "And Solomon went to Hamath Zobah and seized it.", 
                "num": 3
              }, 
              {
                "text": "He also built Tadmor in the wilderness, and all the storage cities which he built in Hamath.", 
                "num": 4
              }, 
              {
                "text": "He built Upper Beth Horon and Lower Beth Horon, fortified cities with walls, gates, and bars,", 
                "num": 5
              }, 
              {
                "text": "also Baalath and all the storage cities that Solomon had, and all the chariot cities and the cities of the cavalry, and all that Solomon desired to build in Jerusalem, in Lebanon, and in all the land of his dominion.", 
                "num": 6
              }, 
              {
                "text": "All the people who were left of the Hittites, Amorites, Perizzites, Hivites, and Jebusites, who were not of Israel--", 
                "num": 7
              }, 
              {
                "text": "that is, their descendants who were left in the land after them, whom the children of Israel did not destroy--from these Solomon raised forced labor, as it is to this day.", 
                "num": 8
              }, 
              {
                "text": "But Solomon did not make the children of Israel servants for his work. Some were men of war, captains of his officers, captains of his chariots, and his cavalry.", 
                "num": 9
              }, 
              {
                "text": "And others were chiefs of the officials of King Solomon: two hundred and fifty, who ruled over the people.", 
                "num": 10
              }, 
              {
                "text": "Now Solomon brought the daughter of Pharaoh up from the City of David to the house he had built for her, for he said, \"My wife shall not dwell in the house of David king of Israel, because the places to which the ark of the LORD has come are holy.\"", 
                "num": 11
              }, 
              {
                "text": "Then Solomon offered burnt offerings to the LORD on the altar of the LORD which he had built before the vestibule,", 
                "num": 12
              }, 
              {
                "text": "according to the daily rate, offering according to the commandment of Moses, for the Sabbaths, the New Moons, and the three appointed yearly feasts--the Feast of Unleavened Bread, the Feast of Weeks, and the Feast of Tabernacles.", 
                "num": 13
              }, 
              {
                "text": "And, according to the order of David his father, he appointed the divisions of the priests for their service, the Levites for their duties (to praise and serve before the priests) as the duty of each day required, and the gatekeepers by their divisions at each gate; for so David the man of God had commanded.", 
                "num": 14
              }, 
              {
                "text": "They did not depart from the command of the king to the priests and Levites concerning any matter or concerning the treasuries.", 
                "num": 15
              }, 
              {
                "text": "Now all the work of Solomon was well-ordered from the day of the foundation of the house of the LORD until it was finished. So the house of the LORD was completed.", 
                "num": 16
              }, 
              {
                "text": "Then Solomon went to Ezion Geber and Elath on the seacoast, in the land of Edom.", 
                "num": 17
              }, 
              {
                "text": "And Hiram sent him ships by the hand of his servants, and servants who knew the sea. They went with the servants of Solomon to Ophir, and acquired four hundred and fifty talents of gold from there, and brought it to King Solomon.", 
                "num": 18
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now when the queen of Sheba heard of the fame of Solomon, she came to Jerusalem to test Solomon with hard questions, having a very great retinue, camels that bore spices, gold in abundance, and precious stones; and when she came to Solomon, she spoke with him about all that was in her heart.", 
                "num": 1
              }, 
              {
                "text": "So Solomon answered all her questions; there was nothing so difficult for Solomon that he could not explain it to her.", 
                "num": 2
              }, 
              {
                "text": "And when the queen of Sheba had seen the wisdom of Solomon, the house that he had built,", 
                "num": 3
              }, 
              {
                "text": "the food on his table, the seating of his servants, the service of his waiters and their apparel, his cupbearers and their apparel, and his entryway by which he went up to the house of the LORD, there was no more spirit in her.", 
                "num": 4
              }, 
              {
                "text": "Then she said to the king: \"It was a true report which I heard in my own land about your words and your wisdom.", 
                "num": 5
              }, 
              {
                "text": "However I did not believe their words until I came and saw with my own eyes; and indeed the half of the greatness of your wisdom was not told me. You exceed the fame of which I heard.", 
                "num": 6
              }, 
              {
                "text": "Happy are your men and happy are these your servants, who stand continually before you and hear your wisdom!", 
                "num": 7
              }, 
              {
                "text": "Blessed be the LORD your God, who delighted in you, setting you on His throne to be king for the LORD your God! Because your God has loved Israel, to establish them forever, therefore He made you king over them, to do justice and righteousness.\"", 
                "num": 8
              }, 
              {
                "text": "And she gave the king one hundred and twenty talents of gold, spices in great abundance, and precious stones; there never were any spices such as those the queen of Sheba gave to King Solomon.", 
                "num": 9
              }, 
              {
                "text": "Also, the servants of Hiram and the servants of Solomon, who brought gold from Ophir, brought algum wood and precious stones.", 
                "num": 10
              }, 
              {
                "text": "And the king made walkways of the algum wood for the house of the LORD and for the king's house, also harps and stringed instruments for singers; and there were none such as these seen before in the land of Judah.", 
                "num": 11
              }, 
              {
                "text": "Now King Solomon gave to the queen of Sheba all she desired, whatever she asked, much more than she had brought to the king. So she turned and went to her own country, she and her servants.", 
                "num": 12
              }, 
              {
                "text": "The weight of gold that came to Solomon yearly was six hundred and sixty-six talents of gold,", 
                "num": 13
              }, 
              {
                "text": "besides what the traveling merchants and traders brought. And all the kings of Arabia and governors of the country brought gold and silver to Solomon.", 
                "num": 14
              }, 
              {
                "text": "And King Solomon made two hundred large shields of hammered gold; six hundred shekels of hammered gold went into each shield.", 
                "num": 15
              }, 
              {
                "text": "He also made three hundred shields of hammered gold; three hundred shekels of gold went into each shield. The king put them in the House of the Forest of Lebanon.", 
                "num": 16
              }, 
              {
                "text": "Moreover the king made a great throne of ivory, and overlaid it with pure gold.", 
                "num": 17
              }, 
              {
                "text": "The throne had six steps, with a footstool of gold, which were fastened to the throne; there were armrests on either side of the place of the seat, and two lions stood beside the armrests.", 
                "num": 18
              }, 
              {
                "text": "Twelve lions stood there, one on each side of the six steps; nothing like this had been made for any other kingdom.", 
                "num": 19
              }, 
              {
                "text": "All King Solomon's drinking vessels were gold, and all the vessels of the House of the Forest of Lebanon were pure gold. Not one was silver, for this was accounted as nothing in the days of Solomon.", 
                "num": 20
              }, 
              {
                "text": "For the king's ships went to Tarshish with the servants of Hiram. Once every three years the merchant ships came, bringing gold, silver, ivory, apes, and monkeys.", 
                "num": 21
              }, 
              {
                "text": "So King Solomon surpassed all the kings of the earth in riches and wisdom.", 
                "num": 22
              }, 
              {
                "text": "And all the kings of the earth sought the presence of Solomon to hear his wisdom, which God had put in his heart.", 
                "num": 23
              }, 
              {
                "text": "Each man brought his present: articles of silver and gold, garments, armor, spices, horses, and mules, at a set rate year by year.", 
                "num": 24
              }, 
              {
                "text": "Solomon had four thousand stalls for horses and chariots, and twelve thousand horsemen whom he stationed in the chariot cities and with the king at Jerusalem.", 
                "num": 25
              }, 
              {
                "text": "So he reigned over all the kings from the River to the land of the Philistines, as far as the border of Egypt.", 
                "num": 26
              }, 
              {
                "text": "The king made silver as common in Jerusalem as stones, and he made cedar trees as abundant as the sycamores which are in the lowland.", 
                "num": 27
              }, 
              {
                "text": "And they brought horses to Solomon from Egypt and from all lands.", 
                "num": 28
              }, 
              {
                "text": "Now the rest of the acts of Solomon, first and last, are they not written in the book of Nathan the prophet, in the prophecy of Ahijah the Shilonite, and in the visions of Iddo the seer concerning Jeroboam the son of Nebat?", 
                "num": 29
              }, 
              {
                "text": "Solomon reigned in Jerusalem over all Israel forty years.", 
                "num": 30
              }, 
              {
                "text": "Then Solomon rested with his fathers, and was buried in the City of David his father. And Rehoboam his son reigned in his place.", 
                "num": 31
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "And Rehoboam went to Shechem, for all Israel had gone to Shechem to make him king.", 
                "num": 1
              }, 
              {
                "text": "So it happened, when Jeroboam the son of Nebat heard it (he was in Egypt, where he had fled from the presence of King Solomon), that Jeroboam returned from Egypt.", 
                "num": 2
              }, 
              {
                "text": "Then they sent for him and called him. And Jeroboam and all Israel came and spoke to Rehoboam, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Your father made our yoke heavy; now therefore, lighten the burdensome service of your father and his heavy yoke which he put on us, and we will serve you.\"", 
                "num": 4
              }, 
              {
                "text": "So he said to them, \"Come back to me after three days.\" And the people departed.", 
                "num": 5
              }, 
              {
                "text": "Then King Rehoboam consulted the elders who stood before his father Solomon while he still lived, saying, \"How do you advise me to answer these people?\"", 
                "num": 6
              }, 
              {
                "text": "And they spoke to him, saying, \"If you are kind to these people, and please them, and speak good words to them, they will be your servants forever.\"", 
                "num": 7
              }, 
              {
                "text": "But he rejected the advice which the elders had given him, and consulted the young men who had grown up with him, who stood before him.", 
                "num": 8
              }, 
              {
                "text": "And he said to them, \"What advice do you give? How should we answer this people who have spoken to me, saying, \"Lighten the yoke which your father put on us'?\"", 
                "num": 9
              }, 
              {
                "text": "Then the young men who had grown up with him spoke to him, saying, \"Thus you should speak to the people who have spoken to you, saying, \"Your father made our yoke heavy, but you make it lighter on us'--thus you shall say to them: \"My little finger shall be thicker than my father's waist!", 
                "num": 10
              }, 
              {
                "text": "And now, whereas my father put a heavy yoke on you, I will add to your yoke; my father chastised you with whips, but I will chastise you with scourges!\"'", 
                "num": 11
              }, 
              {
                "text": "So Jeroboam and all the people came to Rehoboam on the third day, as the king had directed, saying, \"Come back to me the third day.\"", 
                "num": 12
              }, 
              {
                "text": "Then the king answered them roughly. King Rehoboam rejected the advice of the elders,", 
                "num": 13
              }, 
              {
                "text": "and he spoke to them according to the advice of the young men, saying, \"My father made your yoke heavy, but I will add to it; my father chastised you with whips, but I will chastise you with scourges!\"", 
                "num": 14
              }, 
              {
                "text": "So the king did not listen to the people; for the turn of events was from God, that the LORD might fulfill His word, which He had spoken by the hand of Ahijah the Shilonite to Jeroboam the son of Nebat.", 
                "num": 15
              }, 
              {
                "text": "Now when all Israel saw that the king did not listen to them, the people answered the king, saying: \"What share have we in David? We have no inheritance in the son of Jesse. Every man to your tents, O Israel! Now see to your own house, O David!\" So all Israel departed to their tents.", 
                "num": 16
              }, 
              {
                "text": "But Rehoboam reigned over the children of Israel who dwelt in the cities of Judah.", 
                "num": 17
              }, 
              {
                "text": "Then King Rehoboam sent Hadoram, who was in charge of revenue; but the children of Israel stoned him with stones, and he died. Therefore King Rehoboam mounted his chariot in haste to flee to Jerusalem.", 
                "num": 18
              }, 
              {
                "text": "So Israel has been in rebellion against the house of David to this day.", 
                "num": 19
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now when Rehoboam came to Jerusalem, he assembled from the house of Judah and Benjamin one hundred and eighty thousand chosen men who were warriors, to fight against Israel, that he might restore the kingdom to Rehoboam.", 
                "num": 1
              }, 
              {
                "text": "But the word of the LORD came to Shemaiah the man of God, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Speak to Rehoboam the son of Solomon, king of Judah, and to all Israel in Judah and Benjamin, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Thus says the LORD: \"You shall not go up or fight against your brethren! Let every man return to his house, for this thing is from Me.\"|'|\" Therefore they obeyed the words of the LORD, and turned back from attacking Jeroboam.", 
                "num": 4
              }, 
              {
                "text": "So Rehoboam dwelt in Jerusalem, and built cities for defense in Judah.", 
                "num": 5
              }, 
              {
                "text": "And he built Bethlehem, Etam, Tekoa,", 
                "num": 6
              }, 
              {
                "text": "Beth Zur, Sochoh, Adullam,", 
                "num": 7
              }, 
              {
                "text": "Gath, Mareshah, Ziph,", 
                "num": 8
              }, 
              {
                "text": "Adoraim, Lachish, Azekah,", 
                "num": 9
              }, 
              {
                "text": "Zorah, Aijalon, and Hebron, which are in Judah and Benjamin, fortified cities.", 
                "num": 10
              }, 
              {
                "text": "And he fortified the strongholds, and put captains in them, and stores of food, oil, and wine.", 
                "num": 11
              }, 
              {
                "text": "Also in every city he put shields and spears, and made them very strong, having Judah and Benjamin on his side.", 
                "num": 12
              }, 
              {
                "text": "And from all their territories the priests and the Levites who were in all Israel took their stand with him.", 
                "num": 13
              }, 
              {
                "text": "For the Levites left their common-lands and their possessions and came to Judah and Jerusalem, for Jeroboam and his sons had rejected them from serving as priests to the LORD.", 
                "num": 14
              }, 
              {
                "text": "Then he appointed for himself priests for the high places, for the demons, and the calf idols which he had made.", 
                "num": 15
              }, 
              {
                "text": "And after the Levites left, those from all the tribes of Israel, such as set their heart to seek the LORD God of Israel, came to Jerusalem to sacrifice to the LORD God of their fathers.", 
                "num": 16
              }, 
              {
                "text": "So they strengthened the kingdom of Judah, and made Rehoboam the son of Solomon strong for three years, because they walked in the way of David and Solomon for three years.", 
                "num": 17
              }, 
              {
                "text": "Then Rehoboam took for himself as wife Mahalath the daughter of Jerimoth the son of David, and of Abihail the daughter of Eliah the son of Jesse.", 
                "num": 18
              }, 
              {
                "text": "And she bore him children: Jeush, Shamariah, and Zaham.", 
                "num": 19
              }, 
              {
                "text": "After her he took Maachah the granddaughter of Absalom; and she bore him Abijah, Attai, Ziza, and Shelomith.", 
                "num": 20
              }, 
              {
                "text": "Now Rehoboam loved Maachah the granddaughter of Absalom more than all his wives and his concubines; for he took eighteen wives and sixty concubines, and begot twenty-eight sons and sixty daughters.", 
                "num": 21
              }, 
              {
                "text": "And Rehoboam appointed Abijah the son of Maachah as chief, to be leader among his brothers; for he intended to make him king.", 
                "num": 22
              }, 
              {
                "text": "He dealt wisely, and dispersed some of his sons throughout all the territories of Judah and Benjamin, to every fortified city; and he gave them provisions in abundance. He also sought many wives for them.", 
                "num": 23
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Rehoboam had established the kingdom and had strengthened himself, that he forsook the law of the LORD, and all Israel along with him.", 
                "num": 1
              }, 
              {
                "text": "And it happened in the fifth year of King Rehoboam that Shishak king of Egypt came up against Jerusalem, because they had transgressed against the LORD,", 
                "num": 2
              }, 
              {
                "text": "with twelve hundred chariots, sixty thousand horsemen, and people without number who came with him out of Egypt--the Lubim and the Sukkiim and the Ethiopians.", 
                "num": 3
              }, 
              {
                "text": "And he took the fortified cities of Judah and came to Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "Then Shemaiah the prophet came to Rehoboam and the leaders of Judah, who were gathered together in Jerusalem because of Shishak, and said to them, \"Thus says the LORD: \"You have forsaken Me, and therefore I also have left you in the hand of Shishak.\"'", 
                "num": 5
              }, 
              {
                "text": "So the leaders of Israel and the king humbled themselves; and they said, \"The LORD is righteous.\"", 
                "num": 6
              }, 
              {
                "text": "Now when the LORD saw that they humbled themselves, the word of the LORD came to Shemaiah, saying, \"They have humbled themselves; therefore I will not destroy them, but I will grant them some deliverance. My wrath shall not be poured out on Jerusalem by the hand of Shishak.", 
                "num": 7
              }, 
              {
                "text": "Nevertheless they will be his servants, that they may distinguish My service from the service of the kingdoms of the nations.\"", 
                "num": 8
              }, 
              {
                "text": "So Shishak king of Egypt came up against Jerusalem, and took away the treasures of the house of the LORD and the treasures of the king's house; he took everything. He also carried away the gold shields which Solomon had made.", 
                "num": 9
              }, 
              {
                "text": "Then King Rehoboam made bronze shields in their place, and committed them to the hands of the captains of the guard, who guarded the doorway of the king's house.", 
                "num": 10
              }, 
              {
                "text": "And whenever the king entered the house of the LORD, the guard would go and bring them out; then they would take them back into the guardroom.", 
                "num": 11
              }, 
              {
                "text": "When he humbled himself, the wrath of the LORD turned from him, so as not to destroy him completely; and things also went well in Judah.", 
                "num": 12
              }, 
              {
                "text": "Thus King Rehoboam strengthened himself in Jerusalem and reigned. Now Rehoboam was forty-one years old when he became king; and he reigned seventeen years in Jerusalem, the city which the LORD had chosen out of all the tribes of Israel, to put His name there. His mother's name was Naamah, an Ammonitess.", 
                "num": 13
              }, 
              {
                "text": "And he did evil, because he did not prepare his heart to seek the LORD.", 
                "num": 14
              }, 
              {
                "text": "The acts of Rehoboam, first and last, are they not written in the book of Shemaiah the prophet, and of Iddo the seer concerning genealogies? And there were wars between Rehoboam and Jeroboam all their days.", 
                "num": 15
              }, 
              {
                "text": "So Rehoboam rested with his fathers, and was buried in the City of David. Then Abijah his son reigned in his place.", 
                "num": 16
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "In the eighteenth year of King Jeroboam, Abijah became king over Judah.", 
                "num": 1
              }, 
              {
                "text": "He reigned three years in Jerusalem. His mother's name was Michaiah the daughter of Uriel of Gibeah. And there was war between Abijah and Jeroboam.", 
                "num": 2
              }, 
              {
                "text": "Abijah set the battle in order with an army of valiant warriors, four hundred thousand choice men. Jeroboam also drew up in battle formation against him with eight hundred thousand choice men, mighty men of valor.", 
                "num": 3
              }, 
              {
                "text": "Then Abijah stood on Mount Zemaraim, which is in the mountains of Ephraim, and said, \"Hear me, Jeroboam and all Israel:", 
                "num": 4
              }, 
              {
                "text": "Should you not know that the LORD God of Israel gave the dominion over Israel to David forever, to him and his sons, by a covenant of salt?", 
                "num": 5
              }, 
              {
                "text": "Yet Jeroboam the son of Nebat, the servant of Solomon the son of David, rose up and rebelled against his lord.", 
                "num": 6
              }, 
              {
                "text": "Then worthless rogues gathered to him, and strengthened themselves against Rehoboam the son of Solomon, when Rehoboam was young and inexperienced and could not withstand them.", 
                "num": 7
              }, 
              {
                "text": "And now you think to withstand the kingdom of the LORD, which is in the hand of the sons of David; and you are a great multitude, and with you are the gold calves which Jeroboam made for you as gods.", 
                "num": 8
              }, 
              {
                "text": "Have you not cast out the priests of the LORD, the sons of Aaron, and the Levites, and made for yourselves priests, like the peoples of other lands, so that whoever comes to consecrate himself with a young bull and seven rams may be a priest of things that are not gods?", 
                "num": 9
              }, 
              {
                "text": "But as for us, the LORD is our God, and we have not forsaken Him; and the priests who minister to the LORD are the sons of Aaron, and the Levites attend to their duties.", 
                "num": 10
              }, 
              {
                "text": "And they burn to the LORD every morning and every evening burnt sacrifices and sweet incense; they also set the showbread in order on the pure gold table, and the lampstand of gold with its lamps to burn every evening; for we keep the command of the LORD our God, but you have forsaken Him.", 
                "num": 11
              }, 
              {
                "text": "Now look, God Himself is with us as our head, and His priests with sounding trumpets to sound the alarm against you. O children of Israel, do not fight against the LORD God of your fathers, for you shall not prosper!\"", 
                "num": 12
              }, 
              {
                "text": "But Jeroboam caused an ambush to go around behind them; so they were in front of Judah, and the ambush was behind them.", 
                "num": 13
              }, 
              {
                "text": "And when Judah looked around, to their surprise the battle line was at both front and rear; and they cried out to the LORD, and the priests sounded the trumpets.", 
                "num": 14
              }, 
              {
                "text": "Then the men of Judah gave a shout; and as the men of Judah shouted, it happened that God struck Jeroboam and all Israel before Abijah and Judah.", 
                "num": 15
              }, 
              {
                "text": "And the children of Israel fled before Judah, and God delivered them into their hand.", 
                "num": 16
              }, 
              {
                "text": "Then Abijah and his people struck them with a great slaughter; so five hundred thousand choice men of Israel fell slain.", 
                "num": 17
              }, 
              {
                "text": "Thus the children of Israel were subdued at that time; and the children of Judah prevailed, because they relied on the LORD God of their fathers.", 
                "num": 18
              }, 
              {
                "text": "And Abijah pursued Jeroboam and took cities from him: Bethel with its villages, Jeshanah with its villages, and Ephrain with its villages.", 
                "num": 19
              }, 
              {
                "text": "So Jeroboam did not recover strength again in the days of Abijah; and the LORD struck him, and he died.", 
                "num": 20
              }, 
              {
                "text": "But Abijah grew mighty, married fourteen wives, and begot twenty-two sons and sixteen daughters.", 
                "num": 21
              }, 
              {
                "text": "Now the rest of the acts of Abijah, his ways, and his sayings are written in the annals of the prophet Iddo.", 
                "num": 22
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "So Abijah rested with his fathers, and they buried him in the City of David. Then Asa his son reigned in his place. In his days the land was quiet for ten years.", 
                "num": 1
              }, 
              {
                "text": "Asa did what was good and right in the eyes of the LORD his God,", 
                "num": 2
              }, 
              {
                "text": "for he removed the altars of the foreign gods and the high places, and broke down the sacred pillars and cut down the wooden images.", 
                "num": 3
              }, 
              {
                "text": "He commanded Judah to seek the LORD God of their fathers, and to observe the law and the commandment.", 
                "num": 4
              }, 
              {
                "text": "He also removed the high places and the incense altars from all the cities of Judah, and the kingdom was quiet under him.", 
                "num": 5
              }, 
              {
                "text": "And he built fortified cities in Judah, for the land had rest; he had no war in those years, because the LORD had given him rest.", 
                "num": 6
              }, 
              {
                "text": "Therefore he said to Judah, \"Let us build these cities and make walls around them, and towers, gates, and bars, while the land is yet before us, because we have sought the LORD our God; we have sought Him, and He has given us rest on every side.\" So they built and prospered.", 
                "num": 7
              }, 
              {
                "text": "And Asa had an army of three hundred thousand from Judah who carried shields and spears, and from Benjamin two hundred and eighty thousand men who carried shields and drew bows; all these were mighty men of valor.", 
                "num": 8
              }, 
              {
                "text": "Then Zerah the Ethiopian came out against them with an army of a million men and three hundred chariots, and he came to Mareshah.", 
                "num": 9
              }, 
              {
                "text": "So Asa went out against him, and they set the troops in battle array in the Valley of Zephathah at Mareshah.", 
                "num": 10
              }, 
              {
                "text": "And Asa cried out to the LORD his God, and said, \"LORD, it is nothing for You to help, whether with many or with those who have no power; help us, O LORD our God, for we rest on You, and in Your name we go against this multitude. O LORD, You are our God; do not let man prevail against You!\"", 
                "num": 11
              }, 
              {
                "text": "So the LORD struck the Ethiopians before Asa and Judah, and the Ethiopians fled.", 
                "num": 12
              }, 
              {
                "text": "And Asa and the people who were with him pursued them to Gerar. So the Ethiopians were overthrown, and they could not recover, for they were broken before the LORD and His army. And they carried away very much spoil.", 
                "num": 13
              }, 
              {
                "text": "Then they defeated all the cities around Gerar, for the fear of the LORD came upon them; and they plundered all the cities, for there was exceedingly much spoil in them.", 
                "num": 14
              }, 
              {
                "text": "They also attacked the livestock enclosures, and carried off sheep and camels in abundance, and returned to Jerusalem.", 
                "num": 15
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Now the Spirit of God came upon Azariah the son of Oded.", 
                "num": 1
              }, 
              {
                "text": "And he went out to meet Asa, and said to him: \"Hear me, Asa, and all Judah and Benjamin. The LORD is with you while you are with Him. If you seek Him, He will be found by you; but if you forsake Him, He will forsake you.", 
                "num": 2
              }, 
              {
                "text": "For a long time Israel has been without the true God, without a teaching priest, and without law;", 
                "num": 3
              }, 
              {
                "text": "but when in their trouble they turned to the LORD God of Israel, and sought Him, He was found by them.", 
                "num": 4
              }, 
              {
                "text": "And in those times there was no peace to the one who went out, nor to the one who came in, but great turmoil was on all the inhabitants of the lands.", 
                "num": 5
              }, 
              {
                "text": "So nation was destroyed by nation, and city by city, for God troubled them with every adversity.", 
                "num": 6
              }, 
              {
                "text": "But you, be strong and do not let your hands be weak, for your work shall be rewarded!\"", 
                "num": 7
              }, 
              {
                "text": "And when Asa heard these words and the prophecy of Oded the prophet, he took courage, and removed the abominable idols from all the land of Judah and Benjamin and from the cities which he had taken in the mountains of Ephraim; and he restored the altar of the LORD that was before the vestibule of the LORD.", 
                "num": 8
              }, 
              {
                "text": "Then he gathered all Judah and Benjamin, and those who dwelt with them from Ephraim, Manasseh, and Simeon, for they came over to him in great numbers from Israel when they saw that the LORD his God was with him.", 
                "num": 9
              }, 
              {
                "text": "So they gathered together at Jerusalem in the third month, in the fifteenth year of the reign of Asa.", 
                "num": 10
              }, 
              {
                "text": "And they offered to the LORD at that time seven hundred bulls and seven thousand sheep from the spoil they had brought.", 
                "num": 11
              }, 
              {
                "text": "Then they entered into a covenant to seek the LORD God of their fathers with all their heart and with all their soul;", 
                "num": 12
              }, 
              {
                "text": "and whoever would not seek the LORD God of Israel was to be put to death, whether small or great, whether man or woman.", 
                "num": 13
              }, 
              {
                "text": "Then they took an oath before the LORD with a loud voice, with shouting and trumpets and rams' horns.", 
                "num": 14
              }, 
              {
                "text": "And all Judah rejoiced at the oath, for they had sworn with all their heart and sought Him with all their soul; and He was found by them, and the LORD gave them rest all around.", 
                "num": 15
              }, 
              {
                "text": "Also he removed Maachah, the mother of Asa the king, from being queen mother, because she had made an obscene image of Asherah; and Asa cut down her obscene image, then crushed and burned it by the Brook Kidron.", 
                "num": 16
              }, 
              {
                "text": "But the high places were not removed from Israel. Nevertheless the heart of Asa was loyal all his days.", 
                "num": 17
              }, 
              {
                "text": "He also brought into the house of God the things that his father had dedicated and that he himself had dedicated: silver and gold and utensils.", 
                "num": 18
              }, 
              {
                "text": "And there was no war until the thirty-fifth year of the reign of Asa.", 
                "num": 19
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "In the thirty-sixth year of the reign of Asa, Baasha king of Israel came up against Judah and built Ramah, that he might let none go out or come in to Asa king of Judah.", 
                "num": 1
              }, 
              {
                "text": "Then Asa brought silver and gold from the treasuries of the house of the LORD and of the king's house, and sent to Ben-Hadad king of Syria, who dwelt in Damascus, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Let there be a treaty between you and me, as there was between my father and your father. See, I have sent you silver and gold; come, break your treaty with Baasha king of Israel, so that he will withdraw from me.\"", 
                "num": 3
              }, 
              {
                "text": "So Ben-Hadad heeded King Asa, and sent the captains of his armies against the cities of Israel. They attacked Ijon, Dan, Abel Maim, and all the storage cities of Naphtali.", 
                "num": 4
              }, 
              {
                "text": "Now it happened, when Baasha heard it, that he stopped building Ramah and ceased his work.", 
                "num": 5
              }, 
              {
                "text": "Then King Asa took all Judah, and they carried away the stones and timber of Ramah, which Baasha had used for building; and with them he built Geba and Mizpah.", 
                "num": 6
              }, 
              {
                "text": "And at that time Hanani the seer came to Asa king of Judah, and said to him: \"Because you have relied on the king of Syria, and have not relied on the LORD your God, therefore the army of the king of Syria has escaped from your hand.", 
                "num": 7
              }, 
              {
                "text": "Were the Ethiopians and the Lubim not a huge army with very many chariots and horsemen? Yet, because you relied on the LORD, He delivered them into your hand.", 
                "num": 8
              }, 
              {
                "text": "For the eyes of the LORD run to and fro throughout the whole earth, to show Himself strong on behalf of those whose heart is loyal to Him. In this you have done foolishly; therefore from now on you shall have wars.\"", 
                "num": 9
              }, 
              {
                "text": "Then Asa was angry with the seer, and put him in prison, for he was enraged at him because of this. And Asa oppressed some of the people at that time.", 
                "num": 10
              }, 
              {
                "text": "Note that the acts of Asa, first and last, are indeed written in the book of the kings of Judah and Israel.", 
                "num": 11
              }, 
              {
                "text": "And in the thirty-ninth year of his reign, Asa became diseased in his feet, and his malady was severe; yet in his disease he did not seek the LORD, but the physicians.", 
                "num": 12
              }, 
              {
                "text": "So Asa rested with his fathers; he died in the forty-first year of his reign.", 
                "num": 13
              }, 
              {
                "text": "They buried him in his own tomb, which he had made for himself in the City of David; and they laid him in the bed which was filled with spices and various ingredients prepared in a mixture of ointments. They made a very great burning for him.", 
                "num": 14
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Then Jehoshaphat his son reigned in his place, and strengthened himself against Israel.", 
                "num": 1
              }, 
              {
                "text": "And he placed troops in all the fortified cities of Judah, and set garrisons in the land of Judah and in the cities of Ephraim which Asa his father had taken.", 
                "num": 2
              }, 
              {
                "text": "Now the LORD was with Jehoshaphat, because he walked in the former ways of his father David; he did not seek the Baals,", 
                "num": 3
              }, 
              {
                "text": "but sought the God of his father, and walked in His commandments and not according to the acts of Israel.", 
                "num": 4
              }, 
              {
                "text": "Therefore the LORD established the kingdom in his hand; and all Judah gave presents to Jehoshaphat, and he had riches and honor in abundance.", 
                "num": 5
              }, 
              {
                "text": "And his heart took delight in the ways of the LORD; moreover he removed the high places and wooden images from Judah.", 
                "num": 6
              }, 
              {
                "text": "Also in the third year of his reign he sent his leaders, Ben-Hail, Obadiah, Zechariah, Nethanel, and Michaiah, to teach in the cities of Judah.", 
                "num": 7
              }, 
              {
                "text": "And with them he sent Levites: Shemaiah, Nethaniah, Zebadiah, Asahel, Shemiramoth, Jehonathan, Adonijah, Tobijah, and Tobadonijah--the Levites; and with them Elishama and Jehoram, the priests.", 
                "num": 8
              }, 
              {
                "text": "So they taught in Judah, and had the Book of the Law of the LORD with them; they went throughout all the cities of Judah and taught the people.", 
                "num": 9
              }, 
              {
                "text": "And the fear of the LORD fell on all the kingdoms of the lands that were around Judah, so that they did not make war against Jehoshaphat.", 
                "num": 10
              }, 
              {
                "text": "Also some of the Philistines brought Jehoshaphat presents and silver as tribute; and the Arabians brought him flocks, seven thousand seven hundred rams and seven thousand seven hundred male goats.", 
                "num": 11
              }, 
              {
                "text": "So Jehoshaphat became increasingly powerful, and he built fortresses and storage cities in Judah.", 
                "num": 12
              }, 
              {
                "text": "He had much property in the cities of Judah; and the men of war, mighty men of valor, were in Jerusalem.", 
                "num": 13
              }, 
              {
                "text": "These are their numbers, according to their fathers' houses. Of Judah, the captains of thousands: Adnah the captain, and with him three hundred thousand mighty men of valor;", 
                "num": 14
              }, 
              {
                "text": "and next to him was Jehohanan the captain, and with him two hundred and eighty thousand;", 
                "num": 15
              }, 
              {
                "text": "and next to him was Amasiah the son of Zichri, who willingly offered himself to the LORD, and with him two hundred thousand mighty men of valor.", 
                "num": 16
              }, 
              {
                "text": "Of Benjamin: Eliada a mighty man of valor, and with him two hundred thousand men armed with bow and shield;", 
                "num": 17
              }, 
              {
                "text": "and next to him was Jehozabad, and with him one hundred and eighty thousand prepared for war.", 
                "num": 18
              }, 
              {
                "text": "These served the king, besides those the king put in the fortified cities throughout all Judah.", 
                "num": 19
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Jehoshaphat had riches and honor in abundance; and by marriage he allied himself with Ahab.", 
                "num": 1
              }, 
              {
                "text": "After some years he went down to visit Ahab in Samaria; and Ahab killed sheep and oxen in abundance for him and the people who were with him, and persuaded him to go up with him to Ramoth Gilead.", 
                "num": 2
              }, 
              {
                "text": "So Ahab king of Israel said to Jehoshaphat king of Judah, \"Will you go with me against Ramoth Gilead?\" And he answered him, \"I am as you are, and my people as your people; we will be with you in the war.\"", 
                "num": 3
              }, 
              {
                "text": "Also Jehoshaphat said to the king of Israel, \"Please inquire for the word of the LORD today.\"", 
                "num": 4
              }, 
              {
                "text": "Then the king of Israel gathered the prophets together, four hundred men, and said to them, \"Shall we go to war against Ramoth Gilead, or shall I refrain?\" So they said, \"Go up, for God will deliver it into the king's hand.\"", 
                "num": 5
              }, 
              {
                "text": "But Jehoshaphat said, \"Is there not still a prophet of the LORD here, that we may inquire of Him?\"", 
                "num": 6
              }, 
              {
                "text": "So the king of Israel said to Jehoshaphat, \"There is still one man by whom we may inquire of the LORD; but I hate him, because he never prophesies good concerning me, but always evil. He is Micaiah the son of Imla.\" And Jehoshaphat said, \"Let not the king say such things!\"", 
                "num": 7
              }, 
              {
                "text": "Then the king of Israel called one of his officers and said, \"Bring Micaiah the son of Imla quickly!\"", 
                "num": 8
              }, 
              {
                "text": "The king of Israel and Jehoshaphat king of Judah, clothed in their robes, sat each on his throne; and they sat at a threshing floor at the entrance of the gate of Samaria; and all the prophets prophesied before them.", 
                "num": 9
              }, 
              {
                "text": "Now Zedekiah the son of Chenaanah had made horns of iron for himself; and he said, \"Thus says the LORD: \"With these you shall gore the Syrians until they are destroyed.\"'", 
                "num": 10
              }, 
              {
                "text": "And all the prophets prophesied so, saying, \"Go up to Ramoth Gilead and prosper, for the LORD will deliver it into the king's hand.\"", 
                "num": 11
              }, 
              {
                "text": "Then the messenger who had gone to call Micaiah spoke to him, saying, \"Now listen, the words of the prophets with one accord encourage the king. Therefore please let your word be like the word of one of them, and speak encouragement.\"", 
                "num": 12
              }, 
              {
                "text": "And Micaiah said, \"As the LORD lives, whatever my God says, that I will speak.\"", 
                "num": 13
              }, 
              {
                "text": "Then he came to the king; and the king said to him, \"Micaiah, shall we go to war against Ramoth Gilead, or shall I refrain?\" And he said, \"Go and prosper, and they shall be delivered into your hand!\"", 
                "num": 14
              }, 
              {
                "text": "So the king said to him, \"How many times shall I make you swear that you tell me nothing but the truth in the name of the LORD?\"", 
                "num": 15
              }, 
              {
                "text": "Then he said, \"I saw all Israel scattered on the mountains, as sheep that have no shepherd. And the LORD said, \"These have no master. Let each return to his house in peace.\"'", 
                "num": 16
              }, 
              {
                "text": "And the king of Israel said to Jehoshaphat, \"Did I not tell you he would not prophesy good concerning me, but evil?\"", 
                "num": 17
              }, 
              {
                "text": "Then Micaiah said, \"Therefore hear the word of the LORD: I saw the LORD sitting on His throne, and all the host of heaven standing on His right hand and His left.", 
                "num": 18
              }, 
              {
                "text": "And the LORD said, \"Who will persuade Ahab king of Israel to go up, that he may fall at Ramoth Gilead?' So one spoke in this manner, and another spoke in that manner.", 
                "num": 19
              }, 
              {
                "text": "Then a spirit came forward and stood before the LORD, and said, \"I will persuade him.' The LORD said to him, \"In what way?'", 
                "num": 20
              }, 
              {
                "text": "So he said, \"I will go out and be a lying spirit in the mouth of all his prophets.' And the LORD said, \"You shall persuade him and also prevail; go out and do so.'", 
                "num": 21
              }, 
              {
                "text": "Therefore look! The LORD has put a lying spirit in the mouth of these prophets of yours, and the LORD has declared disaster against you.\"", 
                "num": 22
              }, 
              {
                "text": "Then Zedekiah the son of Chenaanah went near and struck Micaiah on the cheek, and said, \"Which way did the spirit from the LORD go from me to speak to you?\"", 
                "num": 23
              }, 
              {
                "text": "And Micaiah said, \"Indeed you shall see on that day when you go into an inner chamber to hide!\"", 
                "num": 24
              }, 
              {
                "text": "Then the king of Israel said, \"Take Micaiah, and return him to Amon the governor of the city and to Joash the king's son;", 
                "num": 25
              }, 
              {
                "text": "and say, \"Thus says the king: \"Put this fellow in prison, and feed him with bread of affliction and water of affliction, until I return in peace.\"\"'", 
                "num": 26
              }, 
              {
                "text": "But Micaiah said, \"If you ever return in peace, the LORD has not spoken by me.\" And he said, \"Take heed, all you people!\"", 
                "num": 27
              }, 
              {
                "text": "So the king of Israel and Jehoshaphat the king of Judah went up to Ramoth Gilead.", 
                "num": 28
              }, 
              {
                "text": "And the king of Israel said to Jehoshaphat, \"I will disguise myself and go into battle; but you put on your robes.\" So the king of Israel disguised himself, and they went into battle.", 
                "num": 29
              }, 
              {
                "text": "Now the king of Syria had commanded the captains of the chariots who were with him, saying, \"Fight with no one small or great, but only with the king of Israel.\"", 
                "num": 30
              }, 
              {
                "text": "So it was, when the captains of the chariots saw Jehoshaphat, that they said, \"It is the king of Israel!\" Therefore they surrounded him to attack; but Jehoshaphat cried out, and the LORD helped him, and God diverted them from him.", 
                "num": 31
              }, 
              {
                "text": "For so it was, when the captains of the chariots saw that it was not the king of Israel, that they turned back from pursuing him.", 
                "num": 32
              }, 
              {
                "text": "Now a certain man drew a bow at random, and struck the king of Israel between the joints of his armor. So he said to the driver of his chariot, \"Turn around and take me out of the battle, for I am wounded.\"", 
                "num": 33
              }, 
              {
                "text": "The battle increased that day, and the king of Israel propped himself up in his chariot facing the Syrians until evening; and about the time of sunset he died.", 
                "num": 34
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Then Jehoshaphat the king of Judah returned safely to his house in Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "And Jehu the son of Hanani the seer went out to meet him, and said to King Jehoshaphat, \"Should you help the wicked and love those who hate the LORD? Therefore the wrath of the LORD is upon you.", 
                "num": 2
              }, 
              {
                "text": "Nevertheless good things are found in you, in that you have removed the wooden images from the land, and have prepared your heart to seek God.\"", 
                "num": 3
              }, 
              {
                "text": "So Jehoshaphat dwelt at Jerusalem; and he went out again among the people from Beersheba to the mountains of Ephraim, and brought them back to the LORD God of their fathers.", 
                "num": 4
              }, 
              {
                "text": "Then he set judges in the land throughout all the fortified cities of Judah, city by city,", 
                "num": 5
              }, 
              {
                "text": "and said to the judges, \"Take heed to what you are doing, for you do not judge for man but for the LORD, who is with you in the judgment.", 
                "num": 6
              }, 
              {
                "text": "Now therefore, let the fear of the LORD be upon you; take care and do it, for there is no iniquity with the LORD our God, no partiality, nor taking of bribes.\"", 
                "num": 7
              }, 
              {
                "text": "Moreover in Jerusalem, for the judgment of the LORD and for controversies, Jehoshaphat appointed some of the Levites and priests, and some of the chief fathers of Israel, when they returned to Jerusalem.", 
                "num": 8
              }, 
              {
                "text": "And he commanded them, saying, \"Thus you shall act in the fear of the LORD, faithfully and with a loyal heart:", 
                "num": 9
              }, 
              {
                "text": "Whatever case comes to you from your brethren who dwell in their cities, whether of bloodshed or offenses against law or commandment, against statutes or ordinances, you shall warn them, lest they trespass against the LORD and wrath come upon you and your brethren. Do this, and you will not be guilty.", 
                "num": 10
              }, 
              {
                "text": "And take notice: Amariah the chief priest is over you in all matters of the LORD; and Zebadiah the son of Ishmael, the ruler of the house of Judah, for all the king's matters; also the Levites will be officials before you. Behave courageously, and the LORD will be with the good.\"", 
                "num": 11
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "It happened after this that the people of Moab with the people of Ammon, and others with them besides the Ammonites, came to battle against Jehoshaphat.", 
                "num": 1
              }, 
              {
                "text": "Then some came and told Jehoshaphat, saying, \"A great multitude is coming against you from beyond the sea, from Syria; and they are in Hazazon Tamar\" (which is En Gedi).", 
                "num": 2
              }, 
              {
                "text": "And Jehoshaphat feared, and set himself to seek the LORD, and proclaimed a fast throughout all Judah.", 
                "num": 3
              }, 
              {
                "text": "So Judah gathered together to ask help from the LORD; and from all the cities of Judah they came to seek the LORD.", 
                "num": 4
              }, 
              {
                "text": "Then Jehoshaphat stood in the assembly of Judah and Jerusalem, in the house of the LORD, before the new court,", 
                "num": 5
              }, 
              {
                "text": "and said: \"O LORD God of our fathers, are You not God in heaven, and do You not rule over all the kingdoms of the nations, and in Your hand is there not power and might, so that no one is able to withstand You?", 
                "num": 6
              }, 
              {
                "text": "Are You not our God, who drove out the inhabitants of this land before Your people Israel, and gave it to the descendants of Abraham Your friend forever?", 
                "num": 7
              }, 
              {
                "text": "And they dwell in it, and have built You a sanctuary in it for Your name, saying,", 
                "num": 8
              }, 
              {
                "text": "\"If disaster comes upon us--sword, judgment, pestilence, or famine--we will stand before this temple and in Your presence (for Your name is in this temple), and cry out to You in our affliction, and You will hear and save.'", 
                "num": 9
              }, 
              {
                "text": "And now, here are the people of Ammon, Moab, and Mount Seir--whom You would not let Israel invade when they came out of the land of Egypt, but they turned from them and did not destroy them--", 
                "num": 10
              }, 
              {
                "text": "here they are, rewarding us by coming to throw us out of Your possession which You have given us to inherit.", 
                "num": 11
              }, 
              {
                "text": "O our God, will You not judge them? For we have no power against this great multitude that is coming against us; nor do we know what to do, but our eyes are upon You.\"", 
                "num": 12
              }, 
              {
                "text": "Now all Judah, with their little ones, their wives, and their children, stood before the LORD.", 
                "num": 13
              }, 
              {
                "text": "Then the Spirit of the LORD came upon Jahaziel the son of Zechariah, the son of Benaiah, the son of Jeiel, the son of Mattaniah, a Levite of the sons of Asaph, in the midst of the assembly.", 
                "num": 14
              }, 
              {
                "text": "And he said, \"Listen, all you of Judah and you inhabitants of Jerusalem, and you, King Jehoshaphat! Thus says the LORD to you: \"Do not be afraid nor dismayed because of this great multitude, for the battle is not yours, but God's.", 
                "num": 15
              }, 
              {
                "text": "Tomorrow go down against them. They will surely come up by the Ascent of Ziz, and you will find them at the end of the brook before the Wilderness of Jeruel.", 
                "num": 16
              }, 
              {
                "text": "You will not need to fight in this battle. Position yourselves, stand still and see the salvation of the LORD, who is with you, O Judah and Jerusalem!' Do not fear or be dismayed; tomorrow go out against them, for the LORD is with you.\"", 
                "num": 17
              }, 
              {
                "text": "And Jehoshaphat bowed his head with his face to the ground, and all Judah and the inhabitants of Jerusalem bowed before the LORD, worshiping the LORD.", 
                "num": 18
              }, 
              {
                "text": "Then the Levites of the children of the Kohathites and of the children of the Korahites stood up to praise the LORD God of Israel with voices loud and high.", 
                "num": 19
              }, 
              {
                "text": "So they rose early in the morning and went out into the Wilderness of Tekoa; and as they went out, Jehoshaphat stood and said, \"Hear me, O Judah and you inhabitants of Jerusalem: Believe in the LORD your God, and you shall be established; believe His prophets, and you shall prosper.\"", 
                "num": 20
              }, 
              {
                "text": "And when he had consulted with the people, he appointed those who should sing to the LORD, and who should praise the beauty of holiness, as they went out before the army and were saying: \"Praise the LORD, For His mercy endures forever.\"", 
                "num": 21
              }, 
              {
                "text": "Now when they began to sing and to praise, the LORD set ambushes against the people of Ammon, Moab, and Mount Seir, who had come against Judah; and they were defeated.", 
                "num": 22
              }, 
              {
                "text": "For the people of Ammon and Moab stood up against the inhabitants of Mount Seir to utterly kill and destroy them. And when they had made an end of the inhabitants of Seir, they helped to destroy one another.", 
                "num": 23
              }, 
              {
                "text": "So when Judah came to a place overlooking the wilderness, they looked toward the multitude; and there were their dead bodies, fallen on the earth. No one had escaped.", 
                "num": 24
              }, 
              {
                "text": "When Jehoshaphat and his people came to take away their spoil, they found among them an abundance of valuables on the dead bodies, and precious jewelry, which they stripped off for themselves, more than they could carry away; and they were three days gathering the spoil because there was so much.", 
                "num": 25
              }, 
              {
                "text": "And on the fourth day they assembled in the Valley of Berachah, for there they blessed the LORD; therefore the name of that place was called The Valley of Berachah until this day.", 
                "num": 26
              }, 
              {
                "text": "Then they returned, every man of Judah and Jerusalem, with Jehoshaphat in front of them, to go back to Jerusalem with joy, for the LORD had made them rejoice over their enemies.", 
                "num": 27
              }, 
              {
                "text": "So they came to Jerusalem, with stringed instruments and harps and trumpets, to the house of the LORD.", 
                "num": 28
              }, 
              {
                "text": "And the fear of God was on all the kingdoms of those countries when they heard that the LORD had fought against the enemies of Israel.", 
                "num": 29
              }, 
              {
                "text": "Then the realm of Jehoshaphat was quiet, for his God gave him rest all around.", 
                "num": 30
              }, 
              {
                "text": "So Jehoshaphat was king over Judah. He was thirty-five years old when he became king, and he reigned twenty-five years in Jerusalem. His mother's name was Azubah the daughter of Shilhi.", 
                "num": 31
              }, 
              {
                "text": "And he walked in the way of his father Asa, and did not turn aside from it, doing what was right in the sight of the LORD.", 
                "num": 32
              }, 
              {
                "text": "Nevertheless the high places were not taken away, for as yet the people had not directed their hearts to the God of their fathers.", 
                "num": 33
              }, 
              {
                "text": "Now the rest of the acts of Jehoshaphat, first and last, indeed they are written in the book of Jehu the son of Hanani, which is mentioned in the book of the kings of Israel.", 
                "num": 34
              }, 
              {
                "text": "After this Jehoshaphat king of Judah allied himself with Ahaziah king of Israel, who acted very wickedly.", 
                "num": 35
              }, 
              {
                "text": "And he allied himself with him to make ships to go to Tarshish, and they made the ships in Ezion Geber.", 
                "num": 36
              }, 
              {
                "text": "But Eliezer the son of Dodavah of Mareshah prophesied against Jehoshaphat, saying, \"Because you have allied yourself with Ahaziah, the LORD has destroyed your works.\" Then the ships were wrecked, so that they were not able to go to Tarshish.", 
                "num": 37
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And Jehoshaphat rested with his fathers, and was buried with his fathers in the City of David. Then Jehoram his son reigned in his place.", 
                "num": 1
              }, 
              {
                "text": "He had brothers, the sons of Jehoshaphat: Azariah, Jehiel, Zechariah, Azaryahu, Michael, and Shephatiah; all these were the sons of Jehoshaphat king of Israel.", 
                "num": 2
              }, 
              {
                "text": "Their father gave them great gifts of silver and gold and precious things, with fortified cities in Judah; but he gave the kingdom to Jehoram, because he was the firstborn.", 
                "num": 3
              }, 
              {
                "text": "Now when Jehoram was established over the kingdom of his father, he strengthened himself and killed all his brothers with the sword, and also others of the princes of Israel.", 
                "num": 4
              }, 
              {
                "text": "Jehoram was thirty-two years old when he became king, and he reigned eight years in Jerusalem.", 
                "num": 5
              }, 
              {
                "text": "And he walked in the way of the kings of Israel, just as the house of Ahab had done, for he had the daughter of Ahab as a wife; and he did evil in the sight of the LORD.", 
                "num": 6
              }, 
              {
                "text": "Yet the LORD would not destroy the house of David, because of the covenant that He had made with David, and since He had promised to give a lamp to him and to his sons forever.", 
                "num": 7
              }, 
              {
                "text": "In his days Edom revolted against Judah's authority, and made a king over themselves.", 
                "num": 8
              }, 
              {
                "text": "So Jehoram went out with his officers, and all his chariots with him. And he rose by night and attacked the Edomites who had surrounded him and the captains of the chariots.", 
                "num": 9
              }, 
              {
                "text": "Thus Edom has been in revolt against Judah's authority to this day. At that time Libnah revolted against his rule, because he had forsaken the LORD God of his fathers.", 
                "num": 10
              }, 
              {
                "text": "Moreover he made high places in the mountains of Judah, and caused the inhabitants of Jerusalem to commit harlotry, and led Judah astray.", 
                "num": 11
              }, 
              {
                "text": "And a letter came to him from Elijah the prophet, saying, 4 Thus says the LORD God of your father David: 4 Because you have not walked in the ways of Jehoshaphat your father, or in the ways of Asa king of Judah,", 
                "num": 12
              }, 
              {
                "text": "but have walked in the way of the kings of Israel, and have made Judah and the inhabitants of Jerusalem to play the harlot like the harlotry of the house of Ahab, and also have killed your brothers, those of your father's household, who were better than yourself,", 
                "num": 13
              }, 
              {
                "text": "behold, the LORD will strike your people with a serious affliction--your children, your wives, and all your possessions;", 
                "num": 14
              }, 
              {
                "text": "and you will become very sick with a disease of your intestines, until your intestines come out by reason of the sickness, day by day.", 
                "num": 15
              }, 
              {
                "text": "Moreover the LORD stirred up against Jehoram the spirit of the Philistines and the Arabians who were near the Ethiopians.", 
                "num": 16
              }, 
              {
                "text": "And they came up into Judah and invaded it, and carried away all the possessions that were found in the king's house, and also his sons and his wives, so that there was not a son left to him except Jehoahaz, the youngest of his sons.", 
                "num": 17
              }, 
              {
                "text": "After all this the LORD struck him in his intestines with an incurable disease.", 
                "num": 18
              }, 
              {
                "text": "Then it happened in the course of time, after the end of two years, that his intestines came out because of his sickness; so he died in severe pain. And his people made no burning for him, like the burning for his fathers.", 
                "num": 19
              }, 
              {
                "text": "He was thirty-two years old when he became king. He reigned in Jerusalem eight years and, to no one's sorrow, departed. However they buried him in the City of David, but not in the tombs of the kings.", 
                "num": 20
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then the inhabitants of Jerusalem made Ahaziah his youngest son king in his place, for the raiders who came with the Arabians into the camp had killed all the older sons. So Ahaziah the son of Jehoram, king of Judah, reigned.", 
                "num": 1
              }, 
              {
                "text": "Ahaziah was forty-two years old when he became king, and he reigned one year in Jerusalem. His mother's name was Athaliah the granddaughter of Omri.", 
                "num": 2
              }, 
              {
                "text": "He also walked in the ways of the house of Ahab, for his mother advised him to do wickedly.", 
                "num": 3
              }, 
              {
                "text": "Therefore he did evil in the sight of the LORD, like the house of Ahab; for they were his counselors after the death of his father, to his destruction.", 
                "num": 4
              }, 
              {
                "text": "He also followed their advice, and went with Jehoram the son of Ahab king of Israel to war against Hazael king of Syria at Ramoth Gilead; and the Syrians wounded Joram.", 
                "num": 5
              }, 
              {
                "text": "Then he returned to Jezreel to recover from the wounds which he had received at Ramah, when he fought against Hazael king of Syria. And Azariah the son of Jehoram, king of Judah, went down to see Jehoram the son of Ahab in Jezreel, because he was sick.", 
                "num": 6
              }, 
              {
                "text": "His going to Joram was God's occasion for Ahaziah's downfall; for when he arrived, he went out with Jehoram against Jehu the son of Nimshi, whom the LORD had anointed to cut off the house of Ahab.", 
                "num": 7
              }, 
              {
                "text": "And it happened, when Jehu was executing judgment on the house of Ahab, and found the princes of Judah and the sons of Ahaziah's brothers who served Ahaziah, that he killed them.", 
                "num": 8
              }, 
              {
                "text": "Then he searched for Ahaziah; and they caught him (he was hiding in Samaria), and brought him to Jehu. When they had killed him, they buried him, \"because,\" they said, \"he is the son of Jehoshaphat, who sought the LORD with all his heart.\" So the house of Ahaziah had no one to assume power over the kingdom.", 
                "num": 9
              }, 
              {
                "text": "Now when Athaliah the mother of Ahaziah saw that her son was dead, she arose and destroyed all the royal heirs of the house of Judah.", 
                "num": 10
              }, 
              {
                "text": "But Jehoshabeath, the daughter of the king, took Joash the son of Ahaziah, and stole him away from among the king's sons who were being murdered, and put him and his nurse in a bedroom. So Jehoshabeath, the daughter of King Jehoram, the wife of Jehoiada the priest (for she was the sister of Ahaziah), hid him from Athaliah so that she did not kill him.", 
                "num": 11
              }, 
              {
                "text": "And he was hidden with them in the house of God for six years, while Athaliah reigned over the land.", 
                "num": 12
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "In the seventh year Jehoiada strengthened himself, and made a covenant with the captains of hundreds: Azariah the son of Jeroham, Ishmael the son of Jehohanan, Azariah the son of Obed, Maaseiah the son of Adaiah, and Elishaphat the son of Zichri.", 
                "num": 1
              }, 
              {
                "text": "And they went throughout Judah and gathered the Levites from all the cities of Judah, and the chief fathers of Israel, and they came to Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "Then all the assembly made a covenant with the king in the house of God. And he said to them, \"Behold, the king's son shall reign, as the LORD has said of the sons of David.", 
                "num": 3
              }, 
              {
                "text": "This is what you shall do: One-third of you entering on the Sabbath, of the priests and the Levites, shall be keeping watch over the doors;", 
                "num": 4
              }, 
              {
                "text": "one-third shall be at the king's house; and one-third at the Gate of the Foundation. All the people shall be in the courts of the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "But let no one come into the house of the LORD except the priests and those of the Levites who serve. They may go in, for they are holy; but all the people shall keep the watch of the LORD.", 
                "num": 6
              }, 
              {
                "text": "And the Levites shall surround the king on all sides, every man with his weapons in his hand; and whoever comes into the house, let him be put to death. You are to be with the king when he comes in and when he goes out.\"", 
                "num": 7
              }, 
              {
                "text": "So the Levites and all Judah did according to all that Jehoiada the priest commanded. And each man took his men who were to be on duty on the Sabbath, with those who were going off duty on the Sabbath; for Jehoiada the priest had not dismissed the divisions.", 
                "num": 8
              }, 
              {
                "text": "And Jehoiada the priest gave to the captains of hundreds the spears and the large and small shields which had belonged to King David, that were in the temple of God.", 
                "num": 9
              }, 
              {
                "text": "Then he set all the people, every man with his weapon in his hand, from the right side of the temple to the left side of the temple, along by the altar and by the temple, all around the king.", 
                "num": 10
              }, 
              {
                "text": "And they brought out the king's son, put the crown on him, gave him the Testimony, and made him king. Then Jehoiada and his sons anointed him, and said, \"Long live the king!\"", 
                "num": 11
              }, 
              {
                "text": "Now when Athaliah heard the noise of the people running and praising the king, she came to the people in the temple of the LORD.", 
                "num": 12
              }, 
              {
                "text": "When she looked, there was the king standing by his pillar at the entrance; and the leaders and the trumpeters were by the king. All the people of the land were rejoicing and blowing trumpets, also the singers with musical instruments, and those who led in praise. So Athaliah tore her clothes and said, \"Treason! Treason!\"", 
                "num": 13
              }, 
              {
                "text": "And Jehoiada the priest brought out the captains of hundreds who were set over the army, and said to them, \"Take her outside under guard, and slay with the sword whoever follows her.\" For the priest had said, \"Do not kill her in the house of the LORD.\"", 
                "num": 14
              }, 
              {
                "text": "So they seized her; and she went by way of the entrance of the Horse Gate into the king's house, and they killed her there.", 
                "num": 15
              }, 
              {
                "text": "Then Jehoiada made a covenant between himself, the people, and the king, that they should be the LORD's people.", 
                "num": 16
              }, 
              {
                "text": "And all the people went to the temple of Baal, and tore it down. They broke in pieces its altars and images, and killed Mattan the priest of Baal before the altars.", 
                "num": 17
              }, 
              {
                "text": "Also Jehoiada appointed the oversight of the house of the LORD to the hand of the priests, the Levites, whom David had assigned in the house of the LORD, to offer the burnt offerings of the LORD, as it is written in the Law of Moses, with rejoicing and with singing, as it was established by David.", 
                "num": 18
              }, 
              {
                "text": "And he set the gatekeepers at the gates of the house of the LORD, so that no one who was in any way unclean should enter.", 
                "num": 19
              }, 
              {
                "text": "Then he took the captains of hundreds, the nobles, the governors of the people, and all the people of the land, and brought the king down from the house of the LORD; and they went through the Upper Gate to the king's house, and set the king on the throne of the kingdom.", 
                "num": 20
              }, 
              {
                "text": "So all the people of the land rejoiced; and the city was quiet, for they had slain Athaliah with the sword.", 
                "num": 21
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Joash was seven years old when he became king, and he reigned forty years in Jerusalem. His mother's name was Zibiah of Beersheba.", 
                "num": 1
              }, 
              {
                "text": "Joash did what was right in the sight of the LORD all the days of Jehoiada the priest.", 
                "num": 2
              }, 
              {
                "text": "And Jehoiada took two wives for him, and he had sons and daughters.", 
                "num": 3
              }, 
              {
                "text": "Now it happened after this that Joash set his heart on repairing the house of the LORD.", 
                "num": 4
              }, 
              {
                "text": "Then he gathered the priests and the Levites, and said to them, \"Go out to the cities of Judah, and gather from all Israel money to repair the house of your God from year to year, and see that you do it quickly.\" However the Levites did not do it quickly.", 
                "num": 5
              }, 
              {
                "text": "So the king called Jehoiada the chief priest, and said to him, \"Why have you not required the Levites to bring in from Judah and from Jerusalem the collection, according to the commandment of Moses the servant of the LORD and of the assembly of Israel, for the tabernacle of witness?\"", 
                "num": 6
              }, 
              {
                "text": "For the sons of Athaliah, that wicked woman, had broken into the house of God, and had also presented all the dedicated things of the house of the LORD to the Baals.", 
                "num": 7
              }, 
              {
                "text": "Then at the king's command they made a chest, and set it outside at the gate of the house of the LORD.", 
                "num": 8
              }, 
              {
                "text": "And they made a proclamation throughout Judah and Jerusalem to bring to the LORD the collection that Moses the servant of God had imposed on Israel in the wilderness.", 
                "num": 9
              }, 
              {
                "text": "Then all the leaders and all the people rejoiced, brought their contributions, and put them into the chest until all had given.", 
                "num": 10
              }, 
              {
                "text": "So it was, at that time, when the chest was brought to the king's official by the hand of the Levites, and when they saw that there was much money, that the king's scribe and the high priest's officer came and emptied the chest, and took it and returned it to its place. Thus they did day by day, and gathered money in abundance.", 
                "num": 11
              }, 
              {
                "text": "The king and Jehoiada gave it to those who did the work of the service of the house of the LORD; and they hired masons and carpenters to repair the house of the LORD, and also those who worked in iron and bronze to restore the house of the LORD.", 
                "num": 12
              }, 
              {
                "text": "So the workmen labored, and the work was completed by them; they restored the house of God to its original condition and reinforced it.", 
                "num": 13
              }, 
              {
                "text": "When they had finished, they brought the rest of the money before the king and Jehoiada; they made from it articles for the house of the LORD, articles for serving and offering, spoons and vessels of gold and silver. And they offered burnt offerings in the house of the LORD continually all the days of Jehoiada.", 
                "num": 14
              }, 
              {
                "text": "But Jehoiada grew old and was full of days, and he died; he was one hundred and thirty years old when he died.", 
                "num": 15
              }, 
              {
                "text": "And they buried him in the City of David among the kings, because he had done good in Israel, both toward God and His house.", 
                "num": 16
              }, 
              {
                "text": "Now after the death of Jehoiada the leaders of Judah came and bowed down to the king. And the king listened to them.", 
                "num": 17
              }, 
              {
                "text": "Therefore they left the house of the LORD God of their fathers, and served wooden images and idols; and wrath came upon Judah and Jerusalem because of their trespass.", 
                "num": 18
              }, 
              {
                "text": "Yet He sent prophets to them, to bring them back to the LORD; and they testified against them, but they would not listen.", 
                "num": 19
              }, 
              {
                "text": "Then the Spirit of God came upon Zechariah the son of Jehoiada the priest, who stood above the people, and said to them, \"Thus says God: \"Why do you transgress the commandments of the LORD, so that you cannot prosper? Because you have forsaken the LORD, He also has forsaken you.\"'", 
                "num": 20
              }, 
              {
                "text": "So they conspired against him, and at the command of the king they stoned him with stones in the court of the house of the LORD.", 
                "num": 21
              }, 
              {
                "text": "Thus Joash the king did not remember the kindness which Jehoiada his father had done to him, but killed his son; and as he died, he said, \"The LORD look on it, and repay!\"", 
                "num": 22
              }, 
              {
                "text": "So it happened in the spring of the year that the army of Syria came up against him; and they came to Judah and Jerusalem, and destroyed all the leaders of the people from among the people, and sent all their spoil to the king of Damascus.", 
                "num": 23
              }, 
              {
                "text": "For the army of the Syrians came with a small company of men; but the LORD delivered a very great army into their hand, because they had forsaken the LORD God of their fathers. So they executed judgment against Joash.", 
                "num": 24
              }, 
              {
                "text": "And when they had withdrawn from him (for they left him severely wounded), his own servants conspired against him because of the blood of the sons of Jehoiada the priest, and killed him on his bed. So he died. And they buried him in the City of David, but they did not bury him in the tombs of the kings.", 
                "num": 25
              }, 
              {
                "text": "These are the ones who conspired against him: Zabad the son of Shimeath the Ammonitess, and Jehozabad the son of Shimrith the Moabitess.", 
                "num": 26
              }, 
              {
                "text": "Now concerning his sons, and the many oracles about him, and the repairing of the house of God, indeed they are written in the annals of the book of the kings. Then Amaziah his son reigned in his place.", 
                "num": 27
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Amaziah was twenty-five years old when he became king, and he reigned twenty-nine years in Jerusalem. His mother's name was Jehoaddan of Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, but not with a loyal heart.", 
                "num": 2
              }, 
              {
                "text": "Now it happened, as soon as the kingdom was established for him, that he executed his servants who had murdered his father the king.", 
                "num": 3
              }, 
              {
                "text": "However he did not execute their children, but did as it is written in the Law in the Book of Moses, where the LORD commanded, saying, \"The fathers shall not be put to death for their children, nor shall the children be put to death for their fathers; but a person shall die for his own sin.\"", 
                "num": 4
              }, 
              {
                "text": "Moreover Amaziah gathered Judah together and set over them captains of thousands and captains of hundreds, according to their fathers' houses, throughout all Judah and Benjamin; and he numbered them from twenty years old and above, and found them to be three hundred thousand choice men, able to go to war, who could handle spear and shield.", 
                "num": 5
              }, 
              {
                "text": "He also hired one hundred thousand mighty men of valor from Israel for one hundred talents of silver.", 
                "num": 6
              }, 
              {
                "text": "But a man of God came to him, saying, \"O king, do not let the army of Israel go with you, for the LORD is not with Israel--not with any of the children of Ephraim.", 
                "num": 7
              }, 
              {
                "text": "But if you go, be gone! Be strong in battle! Even so, God shall make you fall before the enemy; for God has power to help and to overthrow.\"", 
                "num": 8
              }, 
              {
                "text": "Then Amaziah said to the man of God, \"But what shall we do about the hundred talents which I have given to the troops of Israel?\" And the man of God answered, \"The LORD is able to give you much more than this.\"", 
                "num": 9
              }, 
              {
                "text": "So Amaziah discharged the troops that had come to him from Ephraim, to go back home. Therefore their anger was greatly aroused against Judah, and they returned home in great anger.", 
                "num": 10
              }, 
              {
                "text": "Then Amaziah strengthened himself, and leading his people, he went to the Valley of Salt and killed ten thousand of the people of Seir.", 
                "num": 11
              }, 
              {
                "text": "Also the children of Judah took captive ten thousand alive, brought them to the top of the rock, and cast them down from the top of the rock, so that they all were dashed in pieces.", 
                "num": 12
              }, 
              {
                "text": "But as for the soldiers of the army which Amaziah had discharged, so that they would not go with him to battle, they raided the cities of Judah from Samaria to Beth Horon, killed three thousand in them, and took much spoil.", 
                "num": 13
              }, 
              {
                "text": "Now it was so, after Amaziah came from the slaughter of the Edomites, that he brought the gods of the people of Seir, set them up to be his gods, and bowed down before them and burned incense to them.", 
                "num": 14
              }, 
              {
                "text": "Therefore the anger of the LORD was aroused against Amaziah, and He sent him a prophet who said to him, \"Why have you sought the gods of the people, which could not rescue their own people from your hand?\"", 
                "num": 15
              }, 
              {
                "text": "So it was, as he talked with him, that the king said to him, \"Have we made you the king's counselor? Cease! Why should you be killed?\" Then the prophet ceased, and said, \"I know that God has determined to destroy you, because you have done this and have not heeded my advice.\"", 
                "num": 16
              }, 
              {
                "text": "Now Amaziah king of Judah asked advice and sent to Joash the son of Jehoahaz, the son of Jehu, king of Israel, saying, \"Come, let us face one another in battle.\"", 
                "num": 17
              }, 
              {
                "text": "And Joash king of Israel sent to Amaziah king of Judah, saying, \"The thistle that was in Lebanon sent to the cedar that was in Lebanon, saying, \"Give your daughter to my son as wife'; and a wild beast that was in Lebanon passed by and trampled the thistle.", 
                "num": 18
              }, 
              {
                "text": "Indeed you say that you have defeated the Edomites, and your heart is lifted up to boast. Stay at home now; why should you meddle with trouble, that you should fall--you and Judah with you?\"", 
                "num": 19
              }, 
              {
                "text": "But Amaziah would not heed, for it came from God, that He might give them into the hand of their enemies, because they sought the gods of Edom.", 
                "num": 20
              }, 
              {
                "text": "So Joash king of Israel went out; and he and Amaziah king of Judah faced one another at Beth Shemesh, which belongs to Judah.", 
                "num": 21
              }, 
              {
                "text": "And Judah was defeated by Israel, and every man fled to his tent.", 
                "num": 22
              }, 
              {
                "text": "Then Joash the king of Israel captured Amaziah king of Judah, the son of Joash, the son of Jehoahaz, at Beth Shemesh; and he brought him to Jerusalem, and broke down the wall of Jerusalem from the Gate of Ephraim to the Corner Gate--four hundred cubits.", 
                "num": 23
              }, 
              {
                "text": "And he took all the gold and silver, all the articles that were found in the house of God with Obed-Edom, the treasures of the king's house, and hostages, and returned to Samaria.", 
                "num": 24
              }, 
              {
                "text": "Amaziah the son of Joash, king of Judah, lived fifteen years after the death of Joash the son of Jehoahaz, king of Israel.", 
                "num": 25
              }, 
              {
                "text": "Now the rest of the acts of Amaziah, from first to last, indeed are they not written in the book of the kings of Judah and Israel?", 
                "num": 26
              }, 
              {
                "text": "After the time that Amaziah turned away from following the LORD, they made a conspiracy against him in Jerusalem, and he fled to Lachish; but they sent after him to Lachish and killed him there.", 
                "num": 27
              }, 
              {
                "text": "Then they brought him on horses and buried him with his fathers in the City of Judah.", 
                "num": 28
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Now all the people of Judah took Uzziah, who was sixteen years old, and made him king instead of his father Amaziah.", 
                "num": 1
              }, 
              {
                "text": "He built Elath and restored it to Judah, after the king rested with his fathers.", 
                "num": 2
              }, 
              {
                "text": "Uzziah was sixteen years old when he became king, and he reigned fifty-two years in Jerusalem. His mother's name was Jecholiah of Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, according to all that his father Amaziah had done.", 
                "num": 4
              }, 
              {
                "text": "He sought God in the days of Zechariah, who had understanding in the visions of God; and as long as he sought the LORD, God made him prosper.", 
                "num": 5
              }, 
              {
                "text": "Now he went out and made war against the Philistines, and broke down the wall of Gath, the wall of Jabneh, and the wall of Ashdod; and he built cities around Ashdod and among the Philistines.", 
                "num": 6
              }, 
              {
                "text": "God helped him against the Philistines, against the Arabians who lived in Gur Baal, and against the Meunites.", 
                "num": 7
              }, 
              {
                "text": "Also the Ammonites brought tribute to Uzziah. His fame spread as far as the entrance of Egypt, for he became exceedingly strong.", 
                "num": 8
              }, 
              {
                "text": "And Uzziah built towers in Jerusalem at the Corner Gate, at the Valley Gate, and at the corner buttress of the wall; then he fortified them.", 
                "num": 9
              }, 
              {
                "text": "Also he built towers in the desert. He dug many wells, for he had much livestock, both in the lowlands and in the plains; he also had farmers and vinedressers in the mountains and in Carmel, for he loved the soil.", 
                "num": 10
              }, 
              {
                "text": "Moreover Uzziah had an army of fighting men who went out to war by companies, according to the number on their roll as prepared by Jeiel the scribe and Maaseiah the officer, under the hand of Hananiah, one of the king's captains.", 
                "num": 11
              }, 
              {
                "text": "The total number of chief officers of the mighty men of valor was two thousand six hundred.", 
                "num": 12
              }, 
              {
                "text": "And under their authority was an army of three hundred and seven thousand five hundred, that made war with mighty power, to help the king against the enemy.", 
                "num": 13
              }, 
              {
                "text": "Then Uzziah prepared for them, for the entire army, shields, spears, helmets, body armor, bows, and slings to cast stones.", 
                "num": 14
              }, 
              {
                "text": "And he made devices in Jerusalem, invented by skillful men, to be on the towers and the corners, to shoot arrows and large stones. So his fame spread far and wide, for he was marvelously helped till he became strong.", 
                "num": 15
              }, 
              {
                "text": "But when he was strong his heart was lifted up, to his destruction, for he transgressed against the LORD his God by entering the temple of the LORD to burn incense on the altar of incense.", 
                "num": 16
              }, 
              {
                "text": "So Azariah the priest went in after him, and with him were eighty priests of the LORD--valiant men.", 
                "num": 17
              }, 
              {
                "text": "And they withstood King Uzziah, and said to him, \"It is not for you, Uzziah, to burn incense to the LORD, but for the priests, the sons of Aaron, who are consecrated to burn incense. Get out of the sanctuary, for you have trespassed! You shall have no honor from the LORD God.\"", 
                "num": 18
              }, 
              {
                "text": "Then Uzziah became furious; and he had a censer in his hand to burn incense. And while he was angry with the priests, leprosy broke out on his forehead, before the priests in the house of the LORD, beside the incense altar.", 
                "num": 19
              }, 
              {
                "text": "And Azariah the chief priest and all the priests looked at him, and there, on his forehead, he was leprous; so they thrust him out of that place. Indeed he also hurried to get out, because the LORD had struck him.", 
                "num": 20
              }, 
              {
                "text": "King Uzziah was a leper until the day of his death. He dwelt in an isolated house, because he was a leper; for he was cut off from the house of the LORD. Then Jotham his son was over the king's house, judging the people of the land.", 
                "num": 21
              }, 
              {
                "text": "Now the rest of the acts of Uzziah, from first to last, the prophet Isaiah the son of Amoz wrote.", 
                "num": 22
              }, 
              {
                "text": "So Uzziah rested with his fathers, and they buried him with his fathers in the field of burial which belonged to the kings, for they said, \"He is a leper.\" Then Jotham his son reigned in his place.", 
                "num": 23
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Jotham was twenty-five years old when he became king, and he reigned sixteen years in Jerusalem. His mother's name was Jerushah the daughter of Zadok.", 
                "num": 1
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, according to all that his father Uzziah had done (although he did not enter the temple of the LORD). But still the people acted corruptly.", 
                "num": 2
              }, 
              {
                "text": "He built the Upper Gate of the house of the LORD, and he built extensively on the wall of Ophel.", 
                "num": 3
              }, 
              {
                "text": "Moreover he built cities in the mountains of Judah, and in the forests he built fortresses and towers.", 
                "num": 4
              }, 
              {
                "text": "He also fought with the king of the Ammonites and defeated them. And the people of Ammon gave him in that year one hundred talents of silver, ten thousand kors of wheat, and ten thousand of barley. The people of Ammon paid this to him in the second and third years also.", 
                "num": 5
              }, 
              {
                "text": "So Jotham became mighty, because he prepared his ways before the LORD his God.", 
                "num": 6
              }, 
              {
                "text": "Now the rest of the acts of Jotham, and all his wars and his ways, indeed they are written in the book of the kings of Israel and Judah.", 
                "num": 7
              }, 
              {
                "text": "He was twenty-five years old when he became king, and he reigned sixteen years in Jerusalem.", 
                "num": 8
              }, 
              {
                "text": "So Jotham rested with his fathers, and they buried him in the City of David. Then Ahaz his son reigned in his place.", 
                "num": 9
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Ahaz was twenty years old when he became king, and he reigned sixteen years in Jerusalem; and he did not do what was right in the sight of the LORD, as his father David had done.", 
                "num": 1
              }, 
              {
                "text": "For he walked in the ways of the kings of Israel, and made molded images for the Baals.", 
                "num": 2
              }, 
              {
                "text": "He burned incense in the Valley of the Son of Hinnom, and burned his children in the fire, according to the abominations of the nations whom the LORD had cast out before the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "And he sacrificed and burned incense on the high places, on the hills, and under every green tree.", 
                "num": 4
              }, 
              {
                "text": "Therefore the LORD his God delivered him into the hand of the king of Syria. They defeated him, and carried away a great multitude of them as captives, and brought them to Damascus. Then he was also delivered into the hand of the king of Israel, who defeated him with a great slaughter.", 
                "num": 5
              }, 
              {
                "text": "For Pekah the son of Remaliah killed one hundred and twenty thousand in Judah in one day, all valiant men, because they had forsaken the LORD God of their fathers.", 
                "num": 6
              }, 
              {
                "text": "Zichri, a mighty man of Ephraim, killed Maaseiah the king's son, Azrikam the officer over the house, and Elkanah who was second to the king.", 
                "num": 7
              }, 
              {
                "text": "And the children of Israel carried away captive of their brethren two hundred thousand women, sons, and daughters; and they also took away much spoil from them, and brought the spoil to Samaria.", 
                "num": 8
              }, 
              {
                "text": "But a prophet of the LORD was there, whose name was Oded; and he went out before the army that came to Samaria, and said to them: \"Look, because the LORD God of your fathers was angry with Judah, He has delivered them into your hand; but you have killed them in a rage that reaches up to heaven.", 
                "num": 9
              }, 
              {
                "text": "And now you propose to force the children of Judah and Jerusalem to be your male and female slaves; but are you not also guilty before the LORD your God?", 
                "num": 10
              }, 
              {
                "text": "Now hear me, therefore, and return the captives, whom you have taken captive from your brethren, for the fierce wrath of the LORD is upon you.\"", 
                "num": 11
              }, 
              {
                "text": "Then some of the heads of the children of Ephraim, Azariah the son of Johanan, Berechiah the son of Meshillemoth, Jehizkiah the son of Shallum, and Amasa the son of Hadlai, stood up against those who came from the war,", 
                "num": 12
              }, 
              {
                "text": "and said to them, \"You shall not bring the captives here, for we already have offended the LORD. You intend to add to our sins and to our guilt; for our guilt is great, and there is fierce wrath against Israel.\"", 
                "num": 13
              }, 
              {
                "text": "So the armed men left the captives and the spoil before the leaders and all the assembly.", 
                "num": 14
              }, 
              {
                "text": "Then the men who were designated by name rose up and took the captives, and from the spoil they clothed all who were naked among them, dressed them and gave them sandals, gave them food and drink, and anointed them; and they let all the feeble ones ride on donkeys. So they brought them to their brethren at Jericho, the city of palm trees. Then they returned to Samaria.", 
                "num": 15
              }, 
              {
                "text": "At the same time King Ahaz sent to the kings of Assyria to help him.", 
                "num": 16
              }, 
              {
                "text": "For again the Edomites had come, attacked Judah, and carried away captives.", 
                "num": 17
              }, 
              {
                "text": "The Philistines also had invaded the cities of the lowland and of the South of Judah, and had taken Beth Shemesh, Aijalon, Gederoth, Sochoh with its villages, Timnah with its villages, and Gimzo with its villages; and they dwelt there.", 
                "num": 18
              }, 
              {
                "text": "For the LORD brought Judah low because of Ahaz king of Israel, for he had encouraged moral decline in Judah and had been continually unfaithful to the LORD.", 
                "num": 19
              }, 
              {
                "text": "Also Tiglath-Pileser king of Assyria came to him and distressed him, and did not assist him.", 
                "num": 20
              }, 
              {
                "text": "For Ahaz took part of the treasures from the house of the LORD, from the house of the king, and from the leaders, and he gave it to the king of Assyria; but he did not help him.", 
                "num": 21
              }, 
              {
                "text": "Now in the time of his distress King Ahaz became increasingly unfaithful to the LORD. This is that King Ahaz.", 
                "num": 22
              }, 
              {
                "text": "For he sacrificed to the gods of Damascus which had defeated him, saying, \"Because the gods of the kings of Syria help them, I will sacrifice to them that they may help me.\" But they were the ruin of him and of all Israel.", 
                "num": 23
              }, 
              {
                "text": "So Ahaz gathered the articles of the house of God, cut in pieces the articles of the house of God, shut up the doors of the house of the LORD, and made for himself altars in every corner of Jerusalem.", 
                "num": 24
              }, 
              {
                "text": "And in every single city of Judah he made high places to burn incense to other gods, and provoked to anger the LORD God of his fathers.", 
                "num": 25
              }, 
              {
                "text": "Now the rest of his acts and all his ways, from first to last, indeed they are written in the book of the kings of Judah and Israel.", 
                "num": 26
              }, 
              {
                "text": "So Ahaz rested with his fathers, and they buried him in the city, in Jerusalem; but they did not bring him into the tombs of the kings of Israel. Then Hezekiah his son reigned in his place.", 
                "num": 27
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Hezekiah became king when he was twenty-five years old, and he reigned twenty-nine years in Jerusalem. His mother's name was Abijah the daughter of Zechariah.", 
                "num": 1
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, according to all that his father David had done.", 
                "num": 2
              }, 
              {
                "text": "In the first year of his reign, in the first month, he opened the doors of the house of the LORD and repaired them.", 
                "num": 3
              }, 
              {
                "text": "Then he brought in the priests and the Levites, and gathered them in the East Square,", 
                "num": 4
              }, 
              {
                "text": "and said to them: \"Hear me, Levites! Now sanctify yourselves, sanctify the house of the LORD God of your fathers, and carry out the rubbish from the holy place.", 
                "num": 5
              }, 
              {
                "text": "For our fathers have trespassed and done evil in the eyes of the LORD our God; they have forsaken Him, have turned their faces away from the dwelling place of the LORD, and turned their backs on Him.", 
                "num": 6
              }, 
              {
                "text": "They have also shut up the doors of the vestibule, put out the lamps, and have not burned incense or offered burnt offerings in the holy place to the God of Israel.", 
                "num": 7
              }, 
              {
                "text": "Therefore the wrath of the LORD fell upon Judah and Jerusalem, and He has given them up to trouble, to desolation, and to jeering, as you see with your eyes.", 
                "num": 8
              }, 
              {
                "text": "For indeed, because of this our fathers have fallen by the sword; and our sons, our daughters, and our wives are in captivity.", 
                "num": 9
              }, 
              {
                "text": "\"Now it is in my heart to make a covenant with the LORD God of Israel, that His fierce wrath may turn away from us.", 
                "num": 10
              }, 
              {
                "text": "My sons, do not be negligent now, for the LORD has chosen you to stand before Him, to serve Him, and that you should minister to Him and burn incense.\"", 
                "num": 11
              }, 
              {
                "text": "Then these Levites arose: Mahath the son of Amasai and Joel the son of Azariah, of the sons of the Kohathites; of the sons of Merari, Kish the son of Abdi and Azariah the son of Jehallelel; of the Gershonites, Joah the son of Zimmah and Eden the son of Joah;", 
                "num": 12
              }, 
              {
                "text": "of the sons of Elizaphan, Shimri and Jeiel; of the sons of Asaph, Zechariah and Mattaniah;", 
                "num": 13
              }, 
              {
                "text": "of the sons of Heman, Jehiel and Shimei; and of the sons of Jeduthun, Shemaiah and Uzziel.", 
                "num": 14
              }, 
              {
                "text": "And they gathered their brethren, sanctified themselves, and went according to the commandment of the king, at the words of the LORD, to cleanse the house of the LORD.", 
                "num": 15
              }, 
              {
                "text": "Then the priests went into the inner part of the house of the LORD to cleanse it, and brought out all the debris that they found in the temple of the LORD to the court of the house of the LORD. And the Levites took it out and carried it to the Brook Kidron.", 
                "num": 16
              }, 
              {
                "text": "Now they began to sanctify on the first day of the first month, and on the eighth day of the month they came to the vestibule of the LORD. So they sanctified the house of the LORD in eight days, and on the sixteenth day of the first month they finished.", 
                "num": 17
              }, 
              {
                "text": "Then they went in to King Hezekiah and said, \"We have cleansed all the house of the LORD, the altar of burnt offerings with all its articles, and the table of the showbread with all its articles.", 
                "num": 18
              }, 
              {
                "text": "Moreover all the articles which King Ahaz in his reign had cast aside in his transgression we have prepared and sanctified; and there they are, before the altar of the LORD.\"", 
                "num": 19
              }, 
              {
                "text": "Then King Hezekiah rose early, gathered the rulers of the city, and went up to the house of the LORD.", 
                "num": 20
              }, 
              {
                "text": "And they brought seven bulls, seven rams, seven lambs, and seven male goats for a sin offering for the kingdom, for the sanctuary, and for Judah. Then he commanded the priests, the sons of Aaron, to offer them on the altar of the LORD.", 
                "num": 21
              }, 
              {
                "text": "So they killed the bulls, and the priests received the blood and sprinkled it on the altar. Likewise they killed the rams and sprinkled the blood on the altar. They also killed the lambs and sprinkled the blood on the altar.", 
                "num": 22
              }, 
              {
                "text": "Then they brought out the male goats for the sin offering before the king and the assembly, and they laid their hands on them.", 
                "num": 23
              }, 
              {
                "text": "And the priests killed them; and they presented their blood on the altar as a sin offering to make an atonement for all Israel, for the king commanded that the burnt offering and the sin offering be made for all Israel.", 
                "num": 24
              }, 
              {
                "text": "And he stationed the Levites in the house of the LORD with cymbals, with stringed instruments, and with harps, according to the commandment of David, of Gad the king's seer, and of Nathan the prophet; for thus was the commandment of the LORD by His prophets.", 
                "num": 25
              }, 
              {
                "text": "The Levites stood with the instruments of David, and the priests with the trumpets.", 
                "num": 26
              }, 
              {
                "text": "Then Hezekiah commanded them to offer the burnt offering on the altar. And when the burnt offering began, the song of the LORD also began, with the trumpets and with the instruments of David king of Israel.", 
                "num": 27
              }, 
              {
                "text": "So all the assembly worshiped, the singers sang, and the trumpeters sounded; all this continued until the burnt offering was finished.", 
                "num": 28
              }, 
              {
                "text": "And when they had finished offering, the king and all who were present with him bowed and worshiped.", 
                "num": 29
              }, 
              {
                "text": "Moreover King Hezekiah and the leaders commanded the Levites to sing praise to the LORD with the words of David and of Asaph the seer. So they sang praises with gladness, and they bowed their heads and worshiped.", 
                "num": 30
              }, 
              {
                "text": "Then Hezekiah answered and said, \"Now that you have consecrated yourselves to the LORD, come near, and bring sacrifices and thank offerings into the house of the LORD.\" So the assembly brought in sacrifices and thank offerings, and as many as were of a willing heart brought burnt offerings.", 
                "num": 31
              }, 
              {
                "text": "And the number of the burnt offerings which the assembly brought was seventy bulls, one hundred rams, and two hundred lambs; all these were for a burnt offering to the LORD.", 
                "num": 32
              }, 
              {
                "text": "The consecrated things were six hundred bulls and three thousand sheep.", 
                "num": 33
              }, 
              {
                "text": "But the priests were too few, so that they could not skin all the burnt offerings; therefore their brethren the Levites helped them until the work was ended and until the other priests had sanctified themselves, for the Levites were more diligent in sanctifying themselves than the priests.", 
                "num": 34
              }, 
              {
                "text": "Also the burnt offerings were in abundance, with the fat of the peace offerings and with the drink offerings for every burnt offering. So the service of the house of the LORD was set in order.", 
                "num": 35
              }, 
              {
                "text": "Then Hezekiah and all the people rejoiced that God had prepared the people, since the events took place so suddenly.", 
                "num": 36
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "And Hezekiah sent to all Israel and Judah, and also wrote letters to Ephraim and Manasseh, that they should come to the house of the LORD at Jerusalem, to keep the Passover to the LORD God of Israel.", 
                "num": 1
              }, 
              {
                "text": "For the king and his leaders and all the assembly in Jerusalem had agreed to keep the Passover in the second month.", 
                "num": 2
              }, 
              {
                "text": "For they could not keep it at the regular time, because a sufficient number of priests had not consecrated themselves, nor had the people gathered together at Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "And the matter pleased the king and all the assembly.", 
                "num": 4
              }, 
              {
                "text": "So they resolved to make a proclamation throughout all Israel, from Beersheba to Dan, that they should come to keep the Passover to the LORD God of Israel at Jerusalem, since they had not done it for a long time in the prescribed manner.", 
                "num": 5
              }, 
              {
                "text": "Then the runners went throughout all Israel and Judah with the letters from the king and his leaders, and spoke according to the command of the king: \"Children of Israel, return to the LORD God of Abraham, Isaac, and Israel; then He will return to the remnant of you who have escaped from the hand of the kings of Assyria.", 
                "num": 6
              }, 
              {
                "text": "And do not be like your fathers and your brethren, who trespassed against the LORD God of their fathers, so that He gave them up to desolation, as you see.", 
                "num": 7
              }, 
              {
                "text": "Now do not be stiff-necked, as your fathers were, but yield yourselves to the LORD; and enter His sanctuary, which He has sanctified forever, and serve the LORD your God, that the fierceness of His wrath may turn away from you.", 
                "num": 8
              }, 
              {
                "text": "For if you return to the LORD, your brethren and your children will be treated with compassion by those who lead them captive, so that they may come back to this land; for the LORD your God is gracious and merciful, and will not turn His face from you if you return to Him.\"", 
                "num": 9
              }, 
              {
                "text": "So the runners passed from city to city through the country of Ephraim and Manasseh, as far as Zebulun; but they laughed at them and mocked them.", 
                "num": 10
              }, 
              {
                "text": "Nevertheless some from Asher, Manasseh, and Zebulun humbled themselves and came to Jerusalem.", 
                "num": 11
              }, 
              {
                "text": "Also the hand of God was on Judah to give them singleness of heart to obey the command of the king and the leaders, at the word of the LORD.", 
                "num": 12
              }, 
              {
                "text": "Now many people, a very great assembly, gathered at Jerusalem to keep the Feast of Unleavened Bread in the second month.", 
                "num": 13
              }, 
              {
                "text": "They arose and took away the altars that were in Jerusalem, and they took away all the incense altars and cast them into the Brook Kidron.", 
                "num": 14
              }, 
              {
                "text": "Then they slaughtered the Passover lambs on the fourteenth day of the second month. The priests and the Levites were ashamed, and sanctified themselves, and brought the burnt offerings to the house of the LORD.", 
                "num": 15
              }, 
              {
                "text": "They stood in their place according to their custom, according to the Law of Moses the man of God; the priests sprinkled the blood received from the hand of the Levites.", 
                "num": 16
              }, 
              {
                "text": "For there were many in the assembly who had not sanctified themselves; therefore the Levites had charge of the slaughter of the Passover lambs for everyone who was not clean, to sanctify them to the LORD.", 
                "num": 17
              }, 
              {
                "text": "For a multitude of the people, many from Ephraim, Manasseh, Issachar, and Zebulun, had not cleansed themselves, yet they ate the Passover contrary to what was written. But Hezekiah prayed for them, saying, \"May the good LORD provide atonement for everyone", 
                "num": 18
              }, 
              {
                "text": "who prepares his heart to seek God, the LORD God of his fathers, though he is not cleansed according to the purification of the sanctuary.\"", 
                "num": 19
              }, 
              {
                "text": "And the LORD listened to Hezekiah and healed the people.", 
                "num": 20
              }, 
              {
                "text": "So the children of Israel who were present at Jerusalem kept the Feast of Unleavened Bread seven days with great gladness; and the Levites and the priests praised the LORD day by day, singing to the LORD, accompanied by loud instruments.", 
                "num": 21
              }, 
              {
                "text": "And Hezekiah gave encouragement to all the Levites who taught the good knowledge of the LORD; and they ate throughout the feast seven days, offering peace offerings and making confession to the LORD God of their fathers.", 
                "num": 22
              }, 
              {
                "text": "Then the whole assembly agreed to keep the feast another seven days, and they kept it another seven days with gladness.", 
                "num": 23
              }, 
              {
                "text": "For Hezekiah king of Judah gave to the assembly a thousand bulls and seven thousand sheep, and the leaders gave to the assembly a thousand bulls and ten thousand sheep; and a great number of priests sanctified themselves.", 
                "num": 24
              }, 
              {
                "text": "The whole assembly of Judah rejoiced, also the priests and Levites, all the assembly that came from Israel, the sojourners who came from the land of Israel, and those who dwelt in Judah.", 
                "num": 25
              }, 
              {
                "text": "So there was great joy in Jerusalem, for since the time of Solomon the son of David, king of Israel, there had been nothing like this in Jerusalem.", 
                "num": 26
              }, 
              {
                "text": "Then the priests, the Levites, arose and blessed the people, and their voice was heard; and their prayer came up to His holy dwelling place, to heaven.", 
                "num": 27
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Now when all this was finished, all Israel who were present went out to the cities of Judah and broke the sacred pillars in pieces, cut down the wooden images, and threw down the high places and the altars--from all Judah, Benjamin, Ephraim, and Manasseh--until they had utterly destroyed them all. Then all the children of Israel returned to their own cities, every man to his possession.", 
                "num": 1
              }, 
              {
                "text": "And Hezekiah appointed the divisions of the priests and the Levites according to their divisions, each man according to his service, the priests and Levites for burnt offerings and peace offerings, to serve, to give thanks, and to praise in the gates of the camp of the LORD.", 
                "num": 2
              }, 
              {
                "text": "The king also appointed a portion of his possessions for the burnt offerings: for the morning and evening burnt offerings, the burnt offerings for the Sabbaths and the New Moons and the set feasts, as it is written in the Law of the LORD.", 
                "num": 3
              }, 
              {
                "text": "Moreover he commanded the people who dwelt in Jerusalem to contribute support for the priests and the Levites, that they might devote themselves to the Law of the LORD.", 
                "num": 4
              }, 
              {
                "text": "As soon as the commandment was circulated, the children of Israel brought in abundance the firstfruits of grain and wine, oil and honey, and of all the produce of the field; and they brought in abundantly the tithe of everything.", 
                "num": 5
              }, 
              {
                "text": "And the children of Israel and Judah, who dwelt in the cities of Judah, brought the tithe of oxen and sheep; also the tithe of holy things which were consecrated to the LORD their God they laid in heaps.", 
                "num": 6
              }, 
              {
                "text": "In the third month they began laying them in heaps, and they finished in the seventh month.", 
                "num": 7
              }, 
              {
                "text": "And when Hezekiah and the leaders came and saw the heaps, they blessed the LORD and His people Israel.", 
                "num": 8
              }, 
              {
                "text": "Then Hezekiah questioned the priests and the Levites concerning the heaps.", 
                "num": 9
              }, 
              {
                "text": "And Azariah the chief priest, from the house of Zadok, answered him and said, \"Since the people began to bring the offerings into the house of the LORD, we have had enough to eat and have plenty left, for the LORD has blessed His people; and what is left is this great abundance.\"", 
                "num": 10
              }, 
              {
                "text": "Now Hezekiah commanded them to prepare rooms in the house of the LORD, and they prepared them.", 
                "num": 11
              }, 
              {
                "text": "Then they faithfully brought in the offerings, the tithes, and the dedicated things; Cononiah the Levite had charge of them, and Shimei his brother was the next.", 
                "num": 12
              }, 
              {
                "text": "Jehiel, Azaziah, Nahath, Asahel, Jerimoth, Jozabad, Eliel, Ismachiah, Mahath, and Benaiah were overseers under the hand of Cononiah and Shimei his brother, at the commandment of Hezekiah the king and Azariah the ruler of the house of God.", 
                "num": 13
              }, 
              {
                "text": "Kore the son of Imnah the Levite, the keeper of the East Gate, was over the freewill offerings to God, to distribute the offerings of the LORD and the most holy things.", 
                "num": 14
              }, 
              {
                "text": "And under him were Eden, Miniamin, Jeshua, Shemaiah, Amariah, and Shecaniah, his faithful assistants in the cities of the priests, to distribute allotments to their brethren by divisions, to the great as well as the small.", 
                "num": 15
              }, 
              {
                "text": "Besides those males from three years old and up who were written in the genealogy, they distributed to everyone who entered the house of the LORD his daily portion for the work of his service, by his division,", 
                "num": 16
              }, 
              {
                "text": "and to the priests who were written in the genealogy according to their father's house, and to the Levites from twenty years old and up according to their work, by their divisions,", 
                "num": 17
              }, 
              {
                "text": "and to all who were written in the genealogy--their little ones and their wives, their sons and daughters, the whole company of them--for in their faithfulness they sanctified themselves in holiness.", 
                "num": 18
              }, 
              {
                "text": "Also for the sons of Aaron the priests, who were in the fields of the common-lands of their cities, in every single city, there were men who were designated by name to distribute portions to all the males among the priests and to all who were listed by genealogies among the Levites.", 
                "num": 19
              }, 
              {
                "text": "Thus Hezekiah did throughout all Judah, and he did what was good and right and true before the LORD his God.", 
                "num": 20
              }, 
              {
                "text": "And in every work that he began in the service of the house of God, in the law and in the commandment, to seek his God, he did it with all his heart. So he prospered.", 
                "num": 21
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "After these deeds of faithfulness, Sennacherib king of Assyria came and entered Judah; he encamped against the fortified cities, thinking to win them over to himself.", 
                "num": 1
              }, 
              {
                "text": "And when Hezekiah saw that Sennacherib had come, and that his purpose was to make war against Jerusalem,", 
                "num": 2
              }, 
              {
                "text": "he consulted with his leaders and commanders to stop the water from the springs which were outside the city; and they helped him.", 
                "num": 3
              }, 
              {
                "text": "Thus many people gathered together who stopped all the springs and the brook that ran through the land, saying, \"Why should the kings of Assyria come and find much water?\"", 
                "num": 4
              }, 
              {
                "text": "And he strengthened himself, built up all the wall that was broken, raised it up to the towers, and built another wall outside; also he repaired the Millo in the City of David, and made weapons and shields in abundance.", 
                "num": 5
              }, 
              {
                "text": "Then he set military captains over the people, gathered them together to him in the open square of the city gate, and gave them encouragement, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Be strong and courageous; do not be afraid nor dismayed before the king of Assyria, nor before all the multitude that is with him; for there are more with us than with him.", 
                "num": 7
              }, 
              {
                "text": "With him is an arm of flesh; but with us is the LORD our God, to help us and to fight our battles.\" And the people were strengthened by the words of Hezekiah king of Judah.", 
                "num": 8
              }, 
              {
                "text": "After this Sennacherib king of Assyria sent his servants to Jerusalem (but he and all the forces with him laid siege against Lachish), to Hezekiah king of Judah, and to all Judah who were in Jerusalem, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Thus says Sennacherib king of Assyria: \"In what do you trust, that you remain under siege in Jerusalem?", 
                "num": 10
              }, 
              {
                "text": "Does not Hezekiah persuade you to give yourselves over to die by famine and by thirst, saying, \"The LORD our God will deliver us from the hand of the king of Assyria\"?", 
                "num": 11
              }, 
              {
                "text": "Has not the same Hezekiah taken away His high places and His altars, and commanded Judah and Jerusalem, saying, \"You shall worship before one altar and burn incense on it\"?", 
                "num": 12
              }, 
              {
                "text": "Do you not know what I and my fathers have done to all the peoples of other lands? Were the gods of the nations of those lands in any way able to deliver their lands out of my hand?", 
                "num": 13
              }, 
              {
                "text": "Who was there among all the gods of those nations that my fathers utterly destroyed that could deliver his people from my hand, that your God should be able to deliver you from my hand?", 
                "num": 14
              }, 
              {
                "text": "Now therefore, do not let Hezekiah deceive you or persuade you like this, and do not believe him; for no god of any nation or kingdom was able to deliver his people from my hand or the hand of my fathers. How much less will your God deliver you from my hand?\"'", 
                "num": 15
              }, 
              {
                "text": "Furthermore, his servants spoke against the LORD God and against His servant Hezekiah.", 
                "num": 16
              }, 
              {
                "text": "He also wrote letters to revile the LORD God of Israel, and to speak against Him, saying, \"As the gods of the nations of other lands have not delivered their people from my hand, so the God of Hezekiah will not deliver His people from my hand.\"", 
                "num": 17
              }, 
              {
                "text": "Then they called out with a loud voice in Hebrew to the people of Jerusalem who were on the wall, to frighten them and trouble them, that they might take the city.", 
                "num": 18
              }, 
              {
                "text": "And they spoke against the God of Jerusalem, as against the gods of the people of the earth--the work of men's hands.", 
                "num": 19
              }, 
              {
                "text": "Now because of this King Hezekiah and the prophet Isaiah, the son of Amoz, prayed and cried out to heaven.", 
                "num": 20
              }, 
              {
                "text": "Then the LORD sent an angel who cut down every mighty man of valor, leader, and captain in the camp of the king of Assyria. So he returned shamefaced to his own land. And when he had gone into the temple of his god, some of his own offspring struck him down with the sword there.", 
                "num": 21
              }, 
              {
                "text": "Thus the LORD saved Hezekiah and the inhabitants of Jerusalem from the hand of Sennacherib the king of Assyria, and from the hand of all others, and guided them on every side.", 
                "num": 22
              }, 
              {
                "text": "And many brought gifts to the LORD at Jerusalem, and presents to Hezekiah king of Judah, so that he was exalted in the sight of all nations thereafter.", 
                "num": 23
              }, 
              {
                "text": "In those days Hezekiah was sick and near death, and he prayed to the LORD; and He spoke to him and gave him a sign.", 
                "num": 24
              }, 
              {
                "text": "But Hezekiah did not repay according to the favor shown him, for his heart was lifted up; therefore wrath was looming over him and over Judah and Jerusalem.", 
                "num": 25
              }, 
              {
                "text": "Then Hezekiah humbled himself for the pride of his heart, he and the inhabitants of Jerusalem, so that the wrath of the LORD did not come upon them in the days of Hezekiah.", 
                "num": 26
              }, 
              {
                "text": "Hezekiah had very great riches and honor. And he made himself treasuries for silver, for gold, for precious stones, for spices, for shields, and for all kinds of desirable items;", 
                "num": 27
              }, 
              {
                "text": "storehouses for the harvest of grain, wine, and oil; and stalls for all kinds of livestock, and folds for flocks.", 
                "num": 28
              }, 
              {
                "text": "Moreover he provided cities for himself, and possessions of flocks and herds in abundance; for God had given him very much property.", 
                "num": 29
              }, 
              {
                "text": "This same Hezekiah also stopped the water outlet of Upper Gihon, and brought the water by tunnel to the west side of the City of David. Hezekiah prospered in all his works.", 
                "num": 30
              }, 
              {
                "text": "However, regarding the ambassadors of the princes of Babylon, whom they sent to him to inquire about the wonder that was done in the land, God withdrew from him, in order to test him, that He might know all that was in his heart.", 
                "num": 31
              }, 
              {
                "text": "Now the rest of the acts of Hezekiah, and his goodness, indeed they are written in the vision of Isaiah the prophet, the son of Amoz, and in the book of the kings of Judah and Israel.", 
                "num": 32
              }, 
              {
                "text": "So Hezekiah rested with his fathers, and they buried him in the upper tombs of the sons of David; and all Judah and the inhabitants of Jerusalem honored him at his death. Then Manasseh his son reigned in his place.", 
                "num": 33
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Manasseh was twelve years old when he became king, and he reigned fifty-five years in Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "But he did evil in the sight of the LORD, according to the abominations of the nations whom the LORD had cast out before the children of Israel.", 
                "num": 2
              }, 
              {
                "text": "For he rebuilt the high places which Hezekiah his father had broken down; he raised up altars for the Baals, and made wooden images; and he worshiped all the host of heaven and served them.", 
                "num": 3
              }, 
              {
                "text": "He also built altars in the house of the LORD, of which the LORD had said, \"In Jerusalem shall My name be forever.\"", 
                "num": 4
              }, 
              {
                "text": "And he built altars for all the host of heaven in the two courts of the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "Also he caused his sons to pass through the fire in the Valley of the Son of Hinnom; he practiced soothsaying, used witchcraft and sorcery, and consulted mediums and spiritists. He did much evil in the sight of the LORD, to provoke Him to anger.", 
                "num": 6
              }, 
              {
                "text": "He even set a carved image, the idol which he had made, in the house of God, of which God had said to David and to Solomon his son, \"In this house and in Jerusalem, which I have chosen out of all the tribes of Israel, I will put My name forever;", 
                "num": 7
              }, 
              {
                "text": "and I will not again remove the foot of Israel from the land which I have appointed for your fathers--only if they are careful to do all that I have commanded them, according to the whole law and the statutes and the ordinances by the hand of Moses.\"", 
                "num": 8
              }, 
              {
                "text": "So Manasseh seduced Judah and the inhabitants of Jerusalem to do more evil than the nations whom the LORD had destroyed before the children of Israel.", 
                "num": 9
              }, 
              {
                "text": "And the LORD spoke to Manasseh and his people, but they would not listen.", 
                "num": 10
              }, 
              {
                "text": "Therefore the LORD brought upon them the captains of the army of the king of Assyria, who took Manasseh with hooks, bound him with bronze fetters, and carried him off to Babylon.", 
                "num": 11
              }, 
              {
                "text": "Now when he was in affliction, he implored the LORD his God, and humbled himself greatly before the God of his fathers,", 
                "num": 12
              }, 
              {
                "text": "and prayed to Him; and He received his entreaty, heard his supplication, and brought him back to Jerusalem into his kingdom. Then Manasseh knew that the LORD was God.", 
                "num": 13
              }, 
              {
                "text": "After this he built a wall outside the City of David on the west side of Gihon, in the valley, as far as the entrance of the Fish Gate; and it enclosed Ophel, and he raised it to a very great height. Then he put military captains in all the fortified cities of Judah.", 
                "num": 14
              }, 
              {
                "text": "He took away the foreign gods and the idol from the house of the LORD, and all the altars that he had built in the mount of the house of the LORD and in Jerusalem; and he cast them out of the city.", 
                "num": 15
              }, 
              {
                "text": "He also repaired the altar of the LORD, sacrificed peace offerings and thank offerings on it, and commanded Judah to serve the LORD God of Israel.", 
                "num": 16
              }, 
              {
                "text": "Nevertheless the people still sacrificed on the high places, but only to the LORD their God.", 
                "num": 17
              }, 
              {
                "text": "Now the rest of the acts of Manasseh, his prayer to his God, and the words of the seers who spoke to him in the name of the LORD God of Israel, indeed they are written in the book of the kings of Israel.", 
                "num": 18
              }, 
              {
                "text": "Also his prayer and how God received his entreaty, and all his sin and trespass, and the sites where he built high places and set up wooden images and carved images, before he was humbled, indeed they are written among the sayings of Hozai.", 
                "num": 19
              }, 
              {
                "text": "So Manasseh rested with his fathers, and they buried him in his own house. Then his son Amon reigned in his place.", 
                "num": 20
              }, 
              {
                "text": "Amon was twenty-two years old when he became king, and he reigned two years in Jerusalem.", 
                "num": 21
              }, 
              {
                "text": "But he did evil in the sight of the LORD, as his father Manasseh had done; for Amon sacrificed to all the carved images which his father Manasseh had made, and served them.", 
                "num": 22
              }, 
              {
                "text": "And he did not humble himself before the LORD, as his father Manasseh had humbled himself; but Amon trespassed more and more.", 
                "num": 23
              }, 
              {
                "text": "Then his servants conspired against him, and killed him in his own house.", 
                "num": 24
              }, 
              {
                "text": "But the people of the land executed all those who had conspired against King Amon. Then the people of the land made his son Josiah king in his place.", 
                "num": 25
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Josiah was eight years old when he became king, and he reigned thirty-one years in Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "And he did what was right in the sight of the LORD, and walked in the ways of his father David; he did not turn aside to the right hand or to the left.", 
                "num": 2
              }, 
              {
                "text": "For in the eighth year of his reign, while he was still young, he began to seek the God of his father David; and in the twelfth year he began to purge Judah and Jerusalem of the high places, the wooden images, the carved images, and the molded images.", 
                "num": 3
              }, 
              {
                "text": "They broke down the altars of the Baals in his presence, and the incense altars which were above them he cut down; and the wooden images, the carved images, and the molded images he broke in pieces, and made dust of them and scattered it on the graves of those who had sacrificed to them.", 
                "num": 4
              }, 
              {
                "text": "He also burned the bones of the priests on their altars, and cleansed Judah and Jerusalem.", 
                "num": 5
              }, 
              {
                "text": "And so he did in the cities of Manasseh, Ephraim, and Simeon, as far as Naphtali and all around, with axes.", 
                "num": 6
              }, 
              {
                "text": "When he had broken down the altars and the wooden images, had beaten the carved images into powder, and cut down all the incense altars throughout all the land of Israel, he returned to Jerusalem.", 
                "num": 7
              }, 
              {
                "text": "In the eighteenth year of his reign, when he had purged the land and the temple, he sent Shaphan the son of Azaliah, Maaseiah the governor of the city, and Joah the son of Joahaz the recorder, to repair the house of the LORD his God.", 
                "num": 8
              }, 
              {
                "text": "When they came to Hilkiah the high priest, they delivered the money that was brought into the house of God, which the Levites who kept the doors had gathered from the hand of Manasseh and Ephraim, from all the remnant of Israel, from all Judah and Benjamin, and which they had brought back to Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "Then they put it in the hand of the foremen who had the oversight of the house of the LORD; and they gave it to the workmen who worked in the house of the LORD, to repair and restore the house.", 
                "num": 10
              }, 
              {
                "text": "They gave it to the craftsmen and builders to buy hewn stone and timber for beams, and to floor the houses which the kings of Judah had destroyed.", 
                "num": 11
              }, 
              {
                "text": "And the men did the work faithfully. Their overseers were Jahath and Obadiah the Levites, of the sons of Merari, and Zechariah and Meshullam, of the sons of the Kohathites, to supervise. Others of the Levites, all of whom were skillful with instruments of music,", 
                "num": 12
              }, 
              {
                "text": "were over the burden bearers and were overseers of all who did work in any kind of service. And some of the Levites were scribes, officers, and gatekeepers.", 
                "num": 13
              }, 
              {
                "text": "Now when they brought out the money that was brought into the house of the LORD, Hilkiah the priest found the Book of the Law of the LORD given by Moses.", 
                "num": 14
              }, 
              {
                "text": "Then Hilkiah answered and said to Shaphan the scribe, \"I have found the Book of the Law in the house of the LORD.\" And Hilkiah gave the book to Shaphan.", 
                "num": 15
              }, 
              {
                "text": "So Shaphan carried the book to the king, bringing the king word, saying, \"All that was committed to your servants they are doing.", 
                "num": 16
              }, 
              {
                "text": "And they have gathered the money that was found in the house of the LORD, and have delivered it into the hand of the overseers and the workmen.\"", 
                "num": 17
              }, 
              {
                "text": "Then Shaphan the scribe told the king, saying, \"Hilkiah the priest has given me a book.\" And Shaphan read it before the king.", 
                "num": 18
              }, 
              {
                "text": "Thus it happened, when the king heard the words of the Law, that he tore his clothes.", 
                "num": 19
              }, 
              {
                "text": "Then the king commanded Hilkiah, Ahikam the son of Shaphan, Abdon the son of Micah, Shaphan the scribe, and Asaiah a servant of the king, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Go, inquire of the LORD for me, and for those who are left in Israel and Judah, concerning the words of the book that is found; for great is the wrath of the LORD that is poured out on us, because our fathers have not kept the word of the LORD, to do according to all that is written in this book.\"", 
                "num": 21
              }, 
              {
                "text": "So Hilkiah and those the king had appointed went to Huldah the prophetess, the wife of Shallum the son of Tokhath, the son of Hasrah, keeper of the wardrobe. (She dwelt in Jerusalem in the Second Quarter.) And they spoke to her to that effect.", 
                "num": 22
              }, 
              {
                "text": "Then she answered them, \"Thus says the LORD God of Israel, \"Tell the man who sent you to Me,", 
                "num": 23
              }, 
              {
                "text": "\"Thus says the LORD: \"Behold, I will bring calamity on this place and on its inhabitants, all the curses that are written in the book which they have read before the king of Judah,", 
                "num": 24
              }, 
              {
                "text": "because they have forsaken Me and burned incense to other gods, that they might provoke Me to anger with all the works of their hands. Therefore My wrath will be poured out on this place, and not be quenched.\"\"", 
                "num": 25
              }, 
              {
                "text": "But as for the king of Judah, who sent you to inquire of the LORD, in this manner you shall speak to him, \"Thus says the LORD God of Israel: \"Concerning the words which you have heard--", 
                "num": 26
              }, 
              {
                "text": "because your heart was tender, and you humbled yourself before God when you heard His words against this place and against its inhabitants, and you humbled yourself before Me, and you tore your clothes and wept before Me, I also have heard you,\" says the LORD.", 
                "num": 27
              }, 
              {
                "text": "\"Surely I will gather you to your fathers, and you shall be gathered to your grave in peace; and your eyes shall not see all the calamity which I will bring on this place and its inhabitants.\"\"' So they brought back word to the king.", 
                "num": 28
              }, 
              {
                "text": "Then the king sent and gathered all the elders of Judah and Jerusalem.", 
                "num": 29
              }, 
              {
                "text": "The king went up to the house of the LORD, with all the men of Judah and the inhabitants of Jerusalem--the priests and the Levites, and all the people, great and small. And he read in their hearing all the words of the Book of the Covenant which had been found in the house of the LORD.", 
                "num": 30
              }, 
              {
                "text": "Then the king stood in his place and made a covenant before the LORD, to follow the LORD, and to keep His commandments and His testimonies and His statutes with all his heart and all his soul, to perform the words of the covenant that were written in this book.", 
                "num": 31
              }, 
              {
                "text": "And he made all who were present in Jerusalem and Benjamin take a stand. So the inhabitants of Jerusalem did according to the covenant of God, the God of their fathers.", 
                "num": 32
              }, 
              {
                "text": "Thus Josiah removed all the abominations from all the country that belonged to the children of Israel, and made all who were present in Israel diligently serve the LORD their God. All his days they did not depart from following the LORD God of their fathers.", 
                "num": 33
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Now Josiah kept a Passover to the LORD in Jerusalem, and they slaughtered the Passover lambs on the fourteenth day of the first month.", 
                "num": 1
              }, 
              {
                "text": "And he set the priests in their duties and encouraged them for the service of the house of the LORD.", 
                "num": 2
              }, 
              {
                "text": "Then he said to the Levites who taught all Israel, who were holy to the LORD: \"Put the holy ark in the house which Solomon the son of David, king of Israel, built. It shall no longer be a burden on your shoulders. Now serve the LORD your God and His people Israel.", 
                "num": 3
              }, 
              {
                "text": "Prepare yourselves according to your fathers' houses, according to your divisions, following the written instruction of David king of Israel and the written instruction of Solomon his son.", 
                "num": 4
              }, 
              {
                "text": "And stand in the holy place according to the divisions of the fathers' houses of your brethren the lay people, and according to the division of the father's house of the Levites.", 
                "num": 5
              }, 
              {
                "text": "So slaughter the Passover offerings, consecrate yourselves, and prepare them for your brethren, that they may do according to the word of the LORD by the hand of Moses.\"", 
                "num": 6
              }, 
              {
                "text": "Then Josiah gave the lay people lambs and young goats from the flock, all for Passover offerings for all who were present, to the number of thirty thousand, as well as three thousand cattle; these were from the king's possessions.", 
                "num": 7
              }, 
              {
                "text": "And his leaders gave willingly to the people, to the priests, and to the Levites. Hilkiah, Zechariah, and Jehiel, rulers of the house of God, gave to the priests for the Passover offerings two thousand six hundred from the flock, and three hundred cattle.", 
                "num": 8
              }, 
              {
                "text": "Also Conaniah, his brothers Shemaiah and Nethanel, and Hashabiah and Jeiel and Jozabad, chief of the Levites, gave to the Levites for Passover offerings five thousand from the flock and five hundred cattle.", 
                "num": 9
              }, 
              {
                "text": "So the service was prepared, and the priests stood in their places, and the Levites in their divisions, according to the king's command.", 
                "num": 10
              }, 
              {
                "text": "And they slaughtered the Passover offerings; and the priests sprinkled the blood with their hands, while the Levites skinned the animals.", 
                "num": 11
              }, 
              {
                "text": "Then they removed the burnt offerings that they might give them to the divisions of the fathers' houses of the lay people, to offer to the LORD, as it is written in the Book of Moses. And so they did with the cattle.", 
                "num": 12
              }, 
              {
                "text": "Also they roasted the Passover offerings with fire according to the ordinance; but the other holy offerings they boiled in pots, in caldrons, and in pans, and divided them quickly among all the lay people.", 
                "num": 13
              }, 
              {
                "text": "Then afterward they prepared portions for themselves and for the priests, because the priests, the sons of Aaron, were busy in offering burnt offerings and fat until night; therefore the Levites prepared portions for themselves and for the priests, the sons of Aaron.", 
                "num": 14
              }, 
              {
                "text": "And the singers, the sons of Asaph, were in their places, according to the command of David, Asaph, Heman, and Jeduthun the king's seer. Also the gatekeepers were at each gate; they did not have to leave their position, because their brethren the Levites prepared portions for them.", 
                "num": 15
              }, 
              {
                "text": "So all the service of the LORD was prepared the same day, to keep the Passover and to offer burnt offerings on the altar of the LORD, according to the command of King Josiah.", 
                "num": 16
              }, 
              {
                "text": "And the children of Israel who were present kept the Passover at that time, and the Feast of Unleavened Bread for seven days.", 
                "num": 17
              }, 
              {
                "text": "There had been no Passover kept in Israel like that since the days of Samuel the prophet; and none of the kings of Israel had kept such a Passover as Josiah kept, with the priests and the Levites, all Judah and Israel who were present, and the inhabitants of Jerusalem.", 
                "num": 18
              }, 
              {
                "text": "In the eighteenth year of the reign of Josiah this Passover was kept.", 
                "num": 19
              }, 
              {
                "text": "After all this, when Josiah had prepared the temple, Necho king of Egypt came up to fight against Carchemish by the Euphrates; and Josiah went out against him.", 
                "num": 20
              }, 
              {
                "text": "But he sent messengers to him, saying, \"What have I to do with you, king of Judah? I have not come against you this day, but against the house with which I have war; for God commanded me to make haste. Refrain from meddling with God, who is with me, lest He destroy you.\"", 
                "num": 21
              }, 
              {
                "text": "Nevertheless Josiah would not turn his face from him, but disguised himself so that he might fight with him, and did not heed the words of Necho from the mouth of God. So he came to fight in the Valley of Megiddo.", 
                "num": 22
              }, 
              {
                "text": "And the archers shot King Josiah; and the king said to his servants, \"Take me away, for I am severely wounded.\"", 
                "num": 23
              }, 
              {
                "text": "His servants therefore took him out of that chariot and put him in the second chariot that he had, and they brought him to Jerusalem. So he died, and was buried in one of the tombs of his fathers. And all Judah and Jerusalem mourned for Josiah.", 
                "num": 24
              }, 
              {
                "text": "Jeremiah also lamented for Josiah. And to this day all the singing men and the singing women speak of Josiah in their lamentations. They made it a custom in Israel; and indeed they are written in the Laments.", 
                "num": 25
              }, 
              {
                "text": "Now the rest of the acts of Josiah and his goodness, according to what was written in the Law of the LORD,", 
                "num": 26
              }, 
              {
                "text": "and his deeds from first to last, indeed they are written in the book of the kings of Israel and Judah.", 
                "num": 27
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Then the people of the land took Jehoahaz the son of Josiah, and made him king in his father's place in Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Jehoahaz was twenty-three years old when he became king, and he reigned three months in Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "Now the king of Egypt deposed him at Jerusalem; and he imposed on the land a tribute of one hundred talents of silver and a talent of gold.", 
                "num": 3
              }, 
              {
                "text": "Then the king of Egypt made Jehoahaz's brother Eliakim king over Judah and Jerusalem, and changed his name to Jehoiakim. And Necho took Jehoahaz his brother and carried him off to Egypt.", 
                "num": 4
              }, 
              {
                "text": "Jehoiakim was twenty-five years old when he became king, and he reigned eleven years in Jerusalem. And he did evil in the sight of the LORD his God.", 
                "num": 5
              }, 
              {
                "text": "Nebuchadnezzar king of Babylon came up against him, and bound him in bronze fetters to carry him off to Babylon.", 
                "num": 6
              }, 
              {
                "text": "Nebuchadnezzar also carried off some of the articles from the house of the LORD to Babylon, and put them in his temple at Babylon.", 
                "num": 7
              }, 
              {
                "text": "Now the rest of the acts of Jehoiakim, the abominations which he did, and what was found against him, indeed they are written in the book of the kings of Israel and Judah. Then Jehoiachin his son reigned in his place.", 
                "num": 8
              }, 
              {
                "text": "Jehoiachin was eight years old when he became king, and he reigned in Jerusalem three months and ten days. And he did evil in the sight of the LORD.", 
                "num": 9
              }, 
              {
                "text": "At the turn of the year King Nebuchadnezzar summoned him and took him to Babylon, with the costly articles from the house of the LORD, and made Zedekiah, Jehoiakim's brother, king over Judah and Jerusalem.", 
                "num": 10
              }, 
              {
                "text": "Zedekiah was twenty-one years old when he became king, and he reigned eleven years in Jerusalem.", 
                "num": 11
              }, 
              {
                "text": "He did evil in the sight of the LORD his God, and did not humble himself before Jeremiah the prophet, who spoke from the mouth of the LORD.", 
                "num": 12
              }, 
              {
                "text": "And he also rebelled against King Nebuchadnezzar, who had made him swear an oath by God; but he stiffened his neck and hardened his heart against turning to the LORD God of Israel.", 
                "num": 13
              }, 
              {
                "text": "Moreover all the leaders of the priests and the people transgressed more and more, according to all the abominations of the nations, and defiled the house of the LORD which He had consecrated in Jerusalem.", 
                "num": 14
              }, 
              {
                "text": "And the LORD God of their fathers sent warnings to them by His messengers, rising up early and sending them, because He had compassion on His people and on His dwelling place.", 
                "num": 15
              }, 
              {
                "text": "But they mocked the messengers of God, despised His words, and scoffed at His prophets, until the wrath of the LORD arose against His people, till there was no remedy.", 
                "num": 16
              }, 
              {
                "text": "Therefore He brought against them the king of the Chaldeans, who killed their young men with the sword in the house of their sanctuary, and had no compassion on young man or virgin, on the aged or the weak; He gave them all into his hand.", 
                "num": 17
              }, 
              {
                "text": "And all the articles from the house of God, great and small, the treasures of the house of the LORD, and the treasures of the king and of his leaders, all these he took to Babylon.", 
                "num": 18
              }, 
              {
                "text": "Then they burned the house of God, broke down the wall of Jerusalem, burned all its palaces with fire, and destroyed all its precious possessions.", 
                "num": 19
              }, 
              {
                "text": "And those who escaped from the sword he carried away to Babylon, where they became servants to him and his sons until the rule of the kingdom of Persia,", 
                "num": 20
              }, 
              {
                "text": "to fulfill the word of the LORD by the mouth of Jeremiah, until the land had enjoyed her Sabbaths. As long as she lay desolate she kept Sabbath, to fulfill seventy years.", 
                "num": 21
              }, 
              {
                "text": "Now in the first year of Cyrus king of Persia, that the word of the LORD by the mouth of Jeremiah might be fulfilled, the LORD stirred up the spirit of Cyrus king of Persia, so that he made a proclamation throughout all his kingdom, and also put it in writing, saying,", 
                "num": 22
              }, 
              {
                "text": "Thus says Cyrus king of Persia: 4 All the kingdoms of the earth the LORD God of heaven has given me. And He has commanded me to build Him a house at Jerusalem which is in Judah. Who is among you of all His people? May the LORD his God be with him, and let him go up!", 
                "num": 23
              }
            ], 
            "num": 36
          }
        ]
      }, 
      {
        "name": "Ezra", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now in the first year of Cyrus king of Persia, that the word of the LORD by the mouth of Jeremiah might be fulfilled, the LORD stirred up the spirit of Cyrus king of Persia, so that he made a proclamation throughout all his kingdom, and also put it in writing, saying,", 
                "num": 1
              }, 
              {
                "text": "Thus says Cyrus king of Persia: All the kingdoms of the earth the LORD God of heaven has given me. And He has commanded me to build Him a house at Jerusalem which is in Judah.", 
                "num": 2
              }, 
              {
                "text": "Who is among you of all His people? May his God be with him, and let him go up to Jerusalem which is in Judah, and build the house of the LORD God of Israel (He is God), which is in Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "And whoever is left in any place where he dwells, let the men of his place help him with silver and gold, with goods and livestock, besides the freewill offerings for the house of God which is in Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "Then the heads of the fathers' houses of Judah and Benjamin, and the priests and the Levites, with all whose spirits God had moved, arose to go up and build the house of the LORD which is in Jerusalem.", 
                "num": 5
              }, 
              {
                "text": "And all those who were around them encouraged them with articles of silver and gold, with goods and livestock, and with precious things, besides all that was willingly offered.", 
                "num": 6
              }, 
              {
                "text": "King Cyrus also brought out the articles of the house of the LORD, which Nebuchadnezzar had taken from Jerusalem and put in the temple of his gods;", 
                "num": 7
              }, 
              {
                "text": "and Cyrus king of Persia brought them out by the hand of Mithredath the treasurer, and counted them out to Sheshbazzar the prince of Judah.", 
                "num": 8
              }, 
              {
                "text": "This is the number of them: thirty gold platters, one thousand silver platters, twenty-nine knives,", 
                "num": 9
              }, 
              {
                "text": "thirty gold basins, four hundred and ten silver basins of a similar kind, and one thousand other articles.", 
                "num": 10
              }, 
              {
                "text": "All the articles of gold and silver were five thousand four hundred. All these Sheshbazzar took with the captives who were brought from Babylon to Jerusalem.", 
                "num": 11
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now these are the people of the province who came back from the captivity, of those who had been carried away, whom Nebuchadnezzar the king of Babylon had carried away to Babylon, and who returned to Jerusalem and Judah, everyone to his own city.", 
                "num": 1
              }, 
              {
                "text": "Those who came with Zerubbabel were Jeshua, Nehemiah, Seraiah, Reelaiah, Mordecai, Bilshan, Mispar, Bigvai, Rehum, and Baanah. The number of the men of the people of Israel:", 
                "num": 2
              }, 
              {
                "text": "the people of Parosh, two thousand one hundred and seventy-two;", 
                "num": 3
              }, 
              {
                "text": "the people of Shephatiah, three hundred and seventy-two;", 
                "num": 4
              }, 
              {
                "text": "the people of Arah, seven hundred and seventy-five;", 
                "num": 5
              }, 
              {
                "text": "the people of Pahath-Moab, of the people of Jeshua and Joab, two thousand eight hundred and twelve;", 
                "num": 6
              }, 
              {
                "text": "the people of Elam, one thousand two hundred and fifty-four;", 
                "num": 7
              }, 
              {
                "text": "the people of Zattu, nine hundred and forty-five;", 
                "num": 8
              }, 
              {
                "text": "the people of Zaccai, seven hundred and sixty;", 
                "num": 9
              }, 
              {
                "text": "the people of Bani, six hundred and forty-two;", 
                "num": 10
              }, 
              {
                "text": "the people of Bebai, six hundred and twenty-three;", 
                "num": 11
              }, 
              {
                "text": "the people of Azgad, one thousand two hundred and twenty-two;", 
                "num": 12
              }, 
              {
                "text": "the people of Adonikam, six hundred and sixty-six;", 
                "num": 13
              }, 
              {
                "text": "the people of Bigvai, two thousand and fifty-six;", 
                "num": 14
              }, 
              {
                "text": "the people of Adin, four hundred and fifty-four;", 
                "num": 15
              }, 
              {
                "text": "the people of Ater of Hezekiah, ninety-eight;", 
                "num": 16
              }, 
              {
                "text": "the people of Bezai, three hundred and twenty-three;", 
                "num": 17
              }, 
              {
                "text": "the people of Jorah, one hundred and twelve;", 
                "num": 18
              }, 
              {
                "text": "the people of Hashum, two hundred and twenty-three;", 
                "num": 19
              }, 
              {
                "text": "the people of Gibbar, ninety-five;", 
                "num": 20
              }, 
              {
                "text": "the people of Bethlehem, one hundred and twenty-three;", 
                "num": 21
              }, 
              {
                "text": "the men of Netophah, fifty-six;", 
                "num": 22
              }, 
              {
                "text": "the men of Anathoth, one hundred and twenty-eight;", 
                "num": 23
              }, 
              {
                "text": "the people of Azmaveth, forty-two;", 
                "num": 24
              }, 
              {
                "text": "the people of Kirjath Arim, Chephirah, and Beeroth, seven hundred and forty-three;", 
                "num": 25
              }, 
              {
                "text": "the people of Ramah and Geba, six hundred and twenty-one;", 
                "num": 26
              }, 
              {
                "text": "the men of Michmas, one hundred and twenty-two;", 
                "num": 27
              }, 
              {
                "text": "the men of Bethel and Ai, two hundred and twenty-three;", 
                "num": 28
              }, 
              {
                "text": "the people of Nebo, fifty-two;", 
                "num": 29
              }, 
              {
                "text": "the people of Magbish, one hundred and fifty-six;", 
                "num": 30
              }, 
              {
                "text": "the people of the other Elam, one thousand two hundred and fifty-four;", 
                "num": 31
              }, 
              {
                "text": "the people of Harim, three hundred and twenty;", 
                "num": 32
              }, 
              {
                "text": "the people of Lod, Hadid, and Ono, seven hundred and twenty-five;", 
                "num": 33
              }, 
              {
                "text": "the people of Jericho, three hundred and forty-five;", 
                "num": 34
              }, 
              {
                "text": "the people of Senaah, three thousand six hundred and thirty.", 
                "num": 35
              }, 
              {
                "text": "The priests: the sons of Jedaiah, of the house of Jeshua, nine hundred and seventy-three;", 
                "num": 36
              }, 
              {
                "text": "the sons of Immer, one thousand and fifty-two;", 
                "num": 37
              }, 
              {
                "text": "the sons of Pashhur, one thousand two hundred and forty-seven;", 
                "num": 38
              }, 
              {
                "text": "the sons of Harim, one thousand and seventeen.", 
                "num": 39
              }, 
              {
                "text": "The Levites: the sons of Jeshua and Kadmiel, of the sons of Hodaviah, seventy-four.", 
                "num": 40
              }, 
              {
                "text": "The singers: the sons of Asaph, one hundred and twenty-eight.", 
                "num": 41
              }, 
              {
                "text": "The sons of the gatekeepers: the sons of Shallum, the sons of Ater, the sons of Talmon, the sons of Akkub, the sons of Hatita, and the sons of Shobai, one hundred and thirty-nine in all.", 
                "num": 42
              }, 
              {
                "text": "The Nethinim: the sons of Ziha, the sons of Hasupha, the sons of Tabbaoth,", 
                "num": 43
              }, 
              {
                "text": "the sons of Keros, the sons of Siaha, the sons of Padon,", 
                "num": 44
              }, 
              {
                "text": "the sons of Lebanah, the sons of Hagabah, the sons of Akkub,", 
                "num": 45
              }, 
              {
                "text": "the sons of Hagab, the sons of Shalmai, the sons of Hanan,", 
                "num": 46
              }, 
              {
                "text": "the sons of Giddel, the sons of Gahar, the sons of Reaiah,", 
                "num": 47
              }, 
              {
                "text": "the sons of Rezin, the sons of Nekoda, the sons of Gazzam,", 
                "num": 48
              }, 
              {
                "text": "the sons of Uzza, the sons of Paseah, the sons of Besai,", 
                "num": 49
              }, 
              {
                "text": "the sons of Asnah, the sons of Meunim, the sons of Nephusim,", 
                "num": 50
              }, 
              {
                "text": "the sons of Bakbuk, the sons of Hakupha, the sons of Harhur,", 
                "num": 51
              }, 
              {
                "text": "the sons of Bazluth, the sons of Mehida, the sons of Harsha,", 
                "num": 52
              }, 
              {
                "text": "the sons of Barkos, the sons of Sisera, the sons of Tamah,", 
                "num": 53
              }, 
              {
                "text": "the sons of Neziah, and the sons of Hatipha.", 
                "num": 54
              }, 
              {
                "text": "The sons of Solomon's servants: the sons of Sotai, the sons of Sophereth, the sons of Peruda,", 
                "num": 55
              }, 
              {
                "text": "the sons of Jaala, the sons of Darkon, the sons of Giddel,", 
                "num": 56
              }, 
              {
                "text": "the sons of Shephatiah, the sons of Hattil, the sons of Pochereth of Zebaim, and the sons of Ami.", 
                "num": 57
              }, 
              {
                "text": "All the Nethinim and the children of Solomon's servants were three hundred and ninety-two.", 
                "num": 58
              }, 
              {
                "text": "And these were the ones who came up from Tel Melah, Tel Harsha, Cherub, Addan, and Immer; but they could not identify their father's house or their genealogy, whether they were of Israel:", 
                "num": 59
              }, 
              {
                "text": "the sons of Delaiah, the sons of Tobiah, and the sons of Nekoda, six hundred and fifty-two;", 
                "num": 60
              }, 
              {
                "text": "and of the sons of the priests: the sons of Habaiah, the sons of Koz, and the sons of Barzillai, who took a wife of the daughters of Barzillai the Gileadite, and was called by their name.", 
                "num": 61
              }, 
              {
                "text": "These sought their listing among those who were registered by genealogy, but they were not found; therefore they were excluded from the priesthood as defiled.", 
                "num": 62
              }, 
              {
                "text": "And the governor said to them that they should not eat of the most holy things till a priest could consult with the Urim and Thummim.", 
                "num": 63
              }, 
              {
                "text": "The whole assembly together was forty-two thousand three hundred and sixty,", 
                "num": 64
              }, 
              {
                "text": "besides their male and female servants, of whom there were seven thousand three hundred and thirty-seven; and they had two hundred men and women singers.", 
                "num": 65
              }, 
              {
                "text": "Their horses were seven hundred and thirty-six, their mules two hundred and forty-five,", 
                "num": 66
              }, 
              {
                "text": "their camels four hundred and thirty-five, and their donkeys six thousand seven hundred and twenty.", 
                "num": 67
              }, 
              {
                "text": "Some of the heads of the fathers' houses, when they came to the house of the LORD which is in Jerusalem, offered freely for the house of God, to erect it in its place:", 
                "num": 68
              }, 
              {
                "text": "According to their ability, they gave to the treasury for the work sixty-one thousand gold drachmas, five thousand minas of silver, and one hundred priestly garments.", 
                "num": 69
              }, 
              {
                "text": "So the priests and the Levites, some of the people, the singers, the gatekeepers, and the Nethinim, dwelt in their cities, and all Israel in their cities.", 
                "num": 70
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "And when the seventh month had come, and the children of Israel were in the cities, the people gathered together as one man to Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Then Jeshua the son of Jozadak and his brethren the priests, and Zerubbabel the son of Shealtiel and his brethren, arose and built the altar of the God of Israel, to offer burnt offerings on it, as it is written in the Law of Moses the man of God.", 
                "num": 2
              }, 
              {
                "text": "Though fear had come upon them because of the people of those countries, they set the altar on its bases; and they offered burnt offerings on it to the LORD, both the morning and evening burnt offerings.", 
                "num": 3
              }, 
              {
                "text": "They also kept the Feast of Tabernacles, as it is written, and offered the daily burnt offerings in the number required by ordinance for each day.", 
                "num": 4
              }, 
              {
                "text": "Afterwards they offered the regular burnt offering, and those for New Moons and for all the appointed feasts of the LORD that were consecrated, and those of everyone who willingly offered a freewill offering to the LORD.", 
                "num": 5
              }, 
              {
                "text": "From the first day of the seventh month they began to offer burnt offerings to the LORD, although the foundation of the temple of the LORD had not been laid.", 
                "num": 6
              }, 
              {
                "text": "They also gave money to the masons and the carpenters, and food, drink, and oil to the people of Sidon and Tyre to bring cedar logs from Lebanon to the sea, to Joppa, according to the permission which they had from Cyrus king of Persia.", 
                "num": 7
              }, 
              {
                "text": "Now in the second month of the second year of their coming to the house of God at Jerusalem, Zerubbabel the son of Shealtiel, Jeshua the son of Jozadak, and the rest of their brethren the priests and the Levites, and all those who had come out of the captivity to Jerusalem, began work and appointed the Levites from twenty years old and above to oversee the work of the house of the LORD.", 
                "num": 8
              }, 
              {
                "text": "Then Jeshua with his sons and brothers, Kadmiel with his sons, and the sons of Judah, arose as one to oversee those working on the house of God: the sons of Henadad with their sons and their brethren the Levites.", 
                "num": 9
              }, 
              {
                "text": "When the builders laid the foundation of the temple of the LORD, the priests stood in their apparel with trumpets, and the Levites, the sons of Asaph, with cymbals, to praise the LORD, according to the ordinance of David king of Israel.", 
                "num": 10
              }, 
              {
                "text": "And they sang responsively, praising and giving thanks to the LORD: \"For He is good, For His mercy endures forever toward Israel.\" Then all the people shouted with a great shout, when they praised the LORD, because the foundation of the house of the LORD was laid.", 
                "num": 11
              }, 
              {
                "text": "But many of the priests and Levites and heads of the fathers' houses, old men who had seen the first temple, wept with a loud voice when the foundation of this temple was laid before their eyes. Yet many shouted aloud for joy,", 
                "num": 12
              }, 
              {
                "text": "so that the people could not discern the noise of the shout of joy from the noise of the weeping of the people, for the people shouted with a loud shout, and the sound was heard afar off.", 
                "num": 13
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now when the adversaries of Judah and Benjamin heard that the descendants of the captivity were building the temple of the LORD God of Israel,", 
                "num": 1
              }, 
              {
                "text": "they came to Zerubbabel and the heads of the fathers' houses, and said to them, \"Let us build with you, for we seek your God as you do; and we have sacrificed to Him since the days of Esarhaddon king of Assyria, who brought us here.\"", 
                "num": 2
              }, 
              {
                "text": "But Zerubbabel and Jeshua and the rest of the heads of the fathers' houses of Israel said to them, \"You may do nothing with us to build a house for our God; but we alone will build to the LORD God of Israel, as King Cyrus the king of Persia has commanded us.\"", 
                "num": 3
              }, 
              {
                "text": "Then the people of the land tried to discourage the people of Judah. They troubled them in building,", 
                "num": 4
              }, 
              {
                "text": "and hired counselors against them to frustrate their purpose all the days of Cyrus king of Persia, even until the reign of Darius king of Persia.", 
                "num": 5
              }, 
              {
                "text": "In the reign of Ahasuerus, in the beginning of his reign, they wrote an accusation against the inhabitants of Judah and Jerusalem.", 
                "num": 6
              }, 
              {
                "text": "In the days of Artaxerxes also, Bishlam, Mithredath, Tabel, and the rest of their companions wrote to Artaxerxes king of Persia; and the letter was written in Aramaic script, and translated into the Aramaic language.", 
                "num": 7
              }, 
              {
                "text": "Rehum the commander and Shimshai the scribe wrote a letter against Jerusalem to King Artaxerxes in this fashion:", 
                "num": 8
              }, 
              {
                "text": "From Rehum the commander, Shimshai the scribe, and the rest of their companions--representatives of the Dinaites, the Apharsathchites, the Tarpelites, the people of Persia and Erech and Babylon and Shushan, the Dehavites, the Elamites,", 
                "num": 9
              }, 
              {
                "text": "and the rest of the nations whom the great and noble Osnapper took captive and settled in the cities of Samaria and the remainder beyond the River--and so forth.", 
                "num": 10
              }, 
              {
                "text": "(This is a copy of the letter that they sent him) To King Artaxerxes from your servants, the men of the region beyond the River, and so forth:", 
                "num": 11
              }, 
              {
                "text": "Let it be known to the king that the Jews who came up from you have come to us at Jerusalem, and are building the rebellious and evil city, and are finishing its walls and repairing the foundations.", 
                "num": 12
              }, 
              {
                "text": "Let it now be known to the king that, if this city is built and the walls completed, they will not pay tax, tribute, or custom, and the king's treasury will be diminished.", 
                "num": 13
              }, 
              {
                "text": "Now because we receive support from the palace, it was not proper for us to see the king's dishonor; therefore we have sent and informed the king,", 
                "num": 14
              }, 
              {
                "text": "that search may be made in the book of the records of your fathers. And you will find in the book of the records and know that this city is a rebellious city, harmful to kings and provinces, and that they have incited sedition within the city in former times, for which cause this city was destroyed.", 
                "num": 15
              }, 
              {
                "text": "We inform the king that if this city is rebuilt and its walls are completed, the result will be that you will have no dominion beyond the River.", 
                "num": 16
              }, 
              {
                "text": "The king sent an answer: To Rehum the commander, to Shimshai the scribe, to the rest of their companions who dwell in Samaria, and to the remainder beyond the River: Peace, and so forth.", 
                "num": 17
              }, 
              {
                "text": "The letter which you sent to us has been clearly read before me.", 
                "num": 18
              }, 
              {
                "text": "And I gave the command, and a search has been made, and it was found that this city in former times has revolted against kings, and rebellion and sedition have been fostered in it.", 
                "num": 19
              }, 
              {
                "text": "There have also been mighty kings over Jerusalem, who have ruled over all the region beyond the River; and tax, tribute, and custom were paid to them.", 
                "num": 20
              }, 
              {
                "text": "Now give the command to make these men cease, that this city may not be built until the command is given by me.", 
                "num": 21
              }, 
              {
                "text": "Take heed now that you do not fail to do this. Why should damage increase to the hurt of the kings?", 
                "num": 22
              }, 
              {
                "text": "Now when the copy of King Artaxerxes' letter was read before Rehum, Shimshai the scribe, and their companions, they went up in haste to Jerusalem against the Jews, and by force of arms made them cease.", 
                "num": 23
              }, 
              {
                "text": "Thus the work of the house of God which is at Jerusalem ceased, and it was discontinued until the second year of the reign of Darius king of Persia.", 
                "num": 24
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then the prophet Haggai and Zechariah the son of Iddo, prophets, prophesied to the Jews who were in Judah and Jerusalem, in the name of the God of Israel, who was over them.", 
                "num": 1
              }, 
              {
                "text": "So Zerubbabel the son of Shealtiel and Jeshua the son of Jozadak rose up and began to build the house of God which is in Jerusalem; and the prophets of God were with them, helping them.", 
                "num": 2
              }, 
              {
                "text": "At the same time Tattenai the governor of the region beyond the River and Shethar-Boznai and their companions came to them and spoke thus to them: \"Who has commanded you to build this temple and finish this wall?\"", 
                "num": 3
              }, 
              {
                "text": "Then, accordingly, we told them the names of the men who were constructing this building.", 
                "num": 4
              }, 
              {
                "text": "But the eye of their God was upon the elders of the Jews, so that they could not make them cease till a report could go to Darius. Then a written answer was returned concerning this matter.", 
                "num": 5
              }, 
              {
                "text": "This is a copy of the letter that Tattenai sent: The governor of the region beyond the River, and Shethar-Boznai, and his companions, the Persians who were in the region beyond the River, to Darius the king.", 
                "num": 6
              }, 
              {
                "text": "(They sent a letter to him, in which was written thus) To Darius the king: All peace.", 
                "num": 7
              }, 
              {
                "text": "Let it be known to the king that we went into the province of Judea, to the temple of the great God, which is being built with heavy stones, and timber is being laid in the walls; and this work goes on diligently and prospers in their hands.", 
                "num": 8
              }, 
              {
                "text": "Then we asked those elders, and spoke thus to them: \"Who commanded you to build this temple and to finish these walls?\"", 
                "num": 9
              }, 
              {
                "text": "We also asked them their names to inform you, that we might write the names of the men who were chief among them.", 
                "num": 10
              }, 
              {
                "text": "And thus they returned us an answer, saying: \"We are the servants of the God of heaven and earth, and we are rebuilding the temple that was built many years ago, which a great king of Israel built and completed.", 
                "num": 11
              }, 
              {
                "text": "But because our fathers provoked the God of heaven to wrath, He gave them into the hand of Nebuchadnezzar king of Babylon, the Chaldean, who destroyed this temple and carried the people away to Babylon.", 
                "num": 12
              }, 
              {
                "text": "However, in the first year of Cyrus king of Babylon, King Cyrus issued a decree to build this house of God.", 
                "num": 13
              }, 
              {
                "text": "Also, the gold and silver articles of the house of God, which Nebuchadnezzar had taken from the temple that was in Jerusalem and carried into the temple of Babylon--those King Cyrus took from the temple of Babylon, and they were given to one named Sheshbazzar, whom he had made governor.", 
                "num": 14
              }, 
              {
                "text": "And he said to him, \"Take these articles; go, carry them to the temple site that is in Jerusalem, and let the house of God be rebuilt on its former site.'", 
                "num": 15
              }, 
              {
                "text": "Then the same Sheshbazzar came and laid the foundation of the house of God which is in Jerusalem; but from that time even until now it has been under construction, and it is not finished.\"", 
                "num": 16
              }, 
              {
                "text": "Now therefore, if it seems good to the king, let a search be made in the king's treasure house, which is there in Babylon, whether it is so that a decree was issued by King Cyrus to build this house of God at Jerusalem, and let the king send us his pleasure concerning this matter.", 
                "num": 17
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then King Darius issued a decree, and a search was made in the archives, where the treasures were stored in Babylon.", 
                "num": 1
              }, 
              {
                "text": "And at Achmetha, in the palace that is in the province of Media, a scroll was found, and in it a record was written thus:", 
                "num": 2
              }, 
              {
                "text": "In the first year of King Cyrus, King Cyrus issued a decree concerning the house of God at Jerusalem: \"Let the house be rebuilt, the place where they offered sacrifices; and let the foundations of it be firmly laid, its height sixty cubits and its width sixty cubits,", 
                "num": 3
              }, 
              {
                "text": "with three rows of heavy stones and one row of new timber. Let the expenses be paid from the king's treasury.", 
                "num": 4
              }, 
              {
                "text": "Also let the gold and silver articles of the house of God, which Nebuchadnezzar took from the temple which is in Jerusalem and brought to Babylon, be restored and taken back to the temple which is in Jerusalem, each to its place; and deposit them in the house of God\"--", 
                "num": 5
              }, 
              {
                "text": "Now therefore, Tattenai, governor of the region beyond the River, and Shethar-Boznai, and your companions the Persians who are beyond the River, keep yourselves far from there.", 
                "num": 6
              }, 
              {
                "text": "Let the work of this house of God alone; let the governor of the Jews and the elders of the Jews build this house of God on its site.", 
                "num": 7
              }, 
              {
                "text": "Moreover I issue a decree as to what you shall do for the elders of these Jews, for the building of this house of God: Let the cost be paid at the king's expense from taxes on the region beyond the River; this is to be given immediately to these men, so that they are not hindered.", 
                "num": 8
              }, 
              {
                "text": "And whatever they need--young bulls, rams, and lambs for the burnt offerings of the God of heaven, wheat, salt, wine, and oil, according to the request of the priests who are in Jerusalem--let it be given them day by day without fail,", 
                "num": 9
              }, 
              {
                "text": "that they may offer sacrifices of sweet aroma to the God of heaven, and pray for the life of the king and his sons.", 
                "num": 10
              }, 
              {
                "text": "Also I issue a decree that whoever alters this edict, let a timber be pulled from his house and erected, and let him be hanged on it; and let his house be made a refuse heap because of this.", 
                "num": 11
              }, 
              {
                "text": "And may the God who causes His name to dwell there destroy any king or people who put their hand to alter it, or to destroy this house of God which is in Jerusalem. I Darius issue a decree; let it be done diligently.", 
                "num": 12
              }, 
              {
                "text": "Then Tattenai, governor of the region beyond the River, Shethar-Boznai, and their companions diligently did according to what King Darius had sent.", 
                "num": 13
              }, 
              {
                "text": "So the elders of the Jews built, and they prospered through the prophesying of Haggai the prophet and Zechariah the son of Iddo. And they built and finished it, according to the commandment of the God of Israel, and according to the command of Cyrus, Darius, and Artaxerxes king of Persia.", 
                "num": 14
              }, 
              {
                "text": "Now the temple was finished on the third day of the month of Adar, which was in the sixth year of the reign of King Darius.", 
                "num": 15
              }, 
              {
                "text": "Then the children of Israel, the priests and the Levites and the rest of the descendants of the captivity, celebrated the dedication of this house of God with joy.", 
                "num": 16
              }, 
              {
                "text": "And they offered sacrifices at the dedication of this house of God, one hundred bulls, two hundred rams, four hundred lambs, and as a sin offering for all Israel twelve male goats, according to the number of the tribes of Israel.", 
                "num": 17
              }, 
              {
                "text": "They assigned the priests to their divisions and the Levites to their divisions, over the service of God in Jerusalem, as it is written in the Book of Moses.", 
                "num": 18
              }, 
              {
                "text": "And the descendants of the captivity kept the Passover on the fourteenth day of the first month.", 
                "num": 19
              }, 
              {
                "text": "For the priests and the Levites had purified themselves; all of them were ritually clean. And they slaughtered the Passover lambs for all the descendants of the captivity, for their brethren the priests, and for themselves.", 
                "num": 20
              }, 
              {
                "text": "Then the children of Israel who had returned from the captivity ate together with all who had separated themselves from the filth of the nations of the land in order to seek the LORD God of Israel.", 
                "num": 21
              }, 
              {
                "text": "And they kept the Feast of Unleavened Bread seven days with joy; for the LORD made them joyful, and turned the heart of the king of Assyria toward them, to strengthen their hands in the work of the house of God, the God of Israel.", 
                "num": 22
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now after these things, in the reign of Artaxerxes king of Persia, Ezra the son of Seraiah, the son of Azariah, the son of Hilkiah,", 
                "num": 1
              }, 
              {
                "text": "the son of Shallum, the son of Zadok, the son of Ahitub,", 
                "num": 2
              }, 
              {
                "text": "the son of Amariah, the son of Azariah, the son of Meraioth,", 
                "num": 3
              }, 
              {
                "text": "the son of Zerahiah, the son of Uzzi, the son of Bukki,", 
                "num": 4
              }, 
              {
                "text": "the son of Abishua, the son of Phinehas, the son of Eleazar, the son of Aaron the chief priest--", 
                "num": 5
              }, 
              {
                "text": "this Ezra came up from Babylon; and he was a skilled scribe in the Law of Moses, which the LORD God of Israel had given. The king granted him all his request, according to the hand of the LORD his God upon him.", 
                "num": 6
              }, 
              {
                "text": "Some of the children of Israel, the priests, the Levites, the singers, the gatekeepers, and the Nethinim came up to Jerusalem in the seventh year of King Artaxerxes.", 
                "num": 7
              }, 
              {
                "text": "And Ezra came to Jerusalem in the fifth month, which was in the seventh year of the king.", 
                "num": 8
              }, 
              {
                "text": "On the first day of the first month he began his journey from Babylon, and on the first day of the fifth month he came to Jerusalem, according to the good hand of his God upon him.", 
                "num": 9
              }, 
              {
                "text": "For Ezra had prepared his heart to seek the Law of the LORD, and to do it, and to teach statutes and ordinances in Israel.", 
                "num": 10
              }, 
              {
                "text": "This is a copy of the letter that King Artaxerxes gave Ezra the priest, the scribe, expert in the words of the commandments of the LORD, and of His statutes to Israel:", 
                "num": 11
              }, 
              {
                "text": "Artaxerxes, king of kings, To Ezra the priest, a scribe of the Law of the God of heaven: Perfect peace, and so forth.", 
                "num": 12
              }, 
              {
                "text": "I issue a decree that all those of the people of Israel and the priests and Levites in my realm, who volunteer to go up to Jerusalem, may go with you.", 
                "num": 13
              }, 
              {
                "text": "And whereas you are being sent by the king and his seven counselors to inquire concerning Judah and Jerusalem, with regard to the Law of your God which is in your hand;", 
                "num": 14
              }, 
              {
                "text": "and whereas you are to carry the silver and gold which the king and his counselors have freely offered to the God of Israel, whose dwelling is in Jerusalem;", 
                "num": 15
              }, 
              {
                "text": "and whereas all the silver and gold that you may find in all the province of Babylon, along with the freewill offering of the people and the priests, are to be freely offered for the house of their God in Jerusalem--", 
                "num": 16
              }, 
              {
                "text": "now therefore, be careful to buy with this money bulls, rams, and lambs, with their grain offerings and their drink offerings, and offer them on the altar of the house of your God in Jerusalem.", 
                "num": 17
              }, 
              {
                "text": "And whatever seems good to you and your brethren to do with the rest of the silver and the gold, do it according to the will of your God.", 
                "num": 18
              }, 
              {
                "text": "Also the articles that are given to you for the service of the house of your God, deliver in full before the God of Jerusalem.", 
                "num": 19
              }, 
              {
                "text": "And whatever more may be needed for the house of your God, which you may have occasion to provide, pay for it from the king's treasury.", 
                "num": 20
              }, 
              {
                "text": "And I, even I, Artaxerxes the king, issue a decree to all the treasurers who are in the region beyond the River, that whatever Ezra the priest, the scribe of the Law of the God of heaven, may require of you, let it be done diligently,", 
                "num": 21
              }, 
              {
                "text": "up to one hundred talents of silver, one hundred kors of wheat, one hundred baths of wine, one hundred baths of oil, and salt without prescribed limit.", 
                "num": 22
              }, 
              {
                "text": "Whatever is commanded by the God of heaven, let it diligently be done for the house of the God of heaven. For why should there be wrath against the realm of the king and his sons?", 
                "num": 23
              }, 
              {
                "text": "Also we inform you that it shall not be lawful to impose tax, tribute, or custom on any of the priests, Levites, singers, gatekeepers, Nethinim, or servants of this house of God.", 
                "num": 24
              }, 
              {
                "text": "And you, Ezra, according to your God-given wisdom, set magistrates and judges who may judge all the people who are in the region beyond the River, all such as know the laws of your God; and teach those who do not know them.", 
                "num": 25
              }, 
              {
                "text": "Whoever will not observe the law of your God and the law of the king, let judgment be executed speedily on him, whether it be death, or banishment, or confiscation of goods, or imprisonment.", 
                "num": 26
              }, 
              {
                "text": "Blessed be the LORD God of our fathers, who has put such a thing as this in the king's heart, to beautify the house of the LORD which is in Jerusalem,", 
                "num": 27
              }, 
              {
                "text": "and has extended mercy to me before the king and his counselors, and before all the king's mighty princes. So I was encouraged, as the hand of the LORD my God was upon me; and I gathered leading men of Israel to go up with me.", 
                "num": 28
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "These are the heads of their fathers' houses, and this is the genealogy of those who went up with me from Babylon, in the reign of King Artaxerxes:", 
                "num": 1
              }, 
              {
                "text": "of the sons of Phinehas, Gershom; of the sons of Ithamar, Daniel; of the sons of David, Hattush;", 
                "num": 2
              }, 
              {
                "text": "of the sons of Shecaniah, of the sons of Parosh, Zechariah; and registered with him were one hundred and fifty males;", 
                "num": 3
              }, 
              {
                "text": "of the sons of Pahath-Moab, Eliehoenai the son of Zerahiah, and with him two hundred males;", 
                "num": 4
              }, 
              {
                "text": "of the sons of Shechaniah, Ben-Jahaziel, and with him three hundred males;", 
                "num": 5
              }, 
              {
                "text": "of the sons of Adin, Ebed the son of Jonathan, and with him fifty males;", 
                "num": 6
              }, 
              {
                "text": "of the sons of Elam, Jeshaiah the son of Athaliah, and with him seventy males;", 
                "num": 7
              }, 
              {
                "text": "of the sons of Shephatiah, Zebadiah the son of Michael, and with him eighty males;", 
                "num": 8
              }, 
              {
                "text": "of the sons of Joab, Obadiah the son of Jehiel, and with him two hundred and eighteen males;", 
                "num": 9
              }, 
              {
                "text": "of the sons of Shelomith, Ben-Josiphiah, and with him one hundred and sixty males;", 
                "num": 10
              }, 
              {
                "text": "of the sons of Bebai, Zechariah the son of Bebai, and with him twenty-eight males;", 
                "num": 11
              }, 
              {
                "text": "of the sons of Azgad, Johanan the son of Hakkatan, and with him one hundred and ten males;", 
                "num": 12
              }, 
              {
                "text": "of the last sons of Adonikam, whose names are these--Eliphelet, Jeiel, and Shemaiah--and with them sixty males;", 
                "num": 13
              }, 
              {
                "text": "also of the sons of Bigvai, Uthai and Zabbud, and with them seventy males.", 
                "num": 14
              }, 
              {
                "text": "Now I gathered them by the river that flows to Ahava, and we camped there three days. And I looked among the people and the priests, and found none of the sons of Levi there.", 
                "num": 15
              }, 
              {
                "text": "Then I sent for Eliezer, Ariel, Shemaiah, Elnathan, Jarib, Elnathan, Nathan, Zechariah, and Meshullam, leaders; also for Joiarib and Elnathan, men of understanding.", 
                "num": 16
              }, 
              {
                "text": "And I gave them a command for Iddo the chief man at the place Casiphia, and I told them what they should say to Iddo and his brethren the Nethinim at the place Casiphia--that they should bring us servants for the house of our God.", 
                "num": 17
              }, 
              {
                "text": "Then, by the good hand of our God upon us, they brought us a man of understanding, of the sons of Mahli the son of Levi, the son of Israel, namely Sherebiah, with his sons and brothers, eighteen men;", 
                "num": 18
              }, 
              {
                "text": "and Hashabiah, and with him Jeshaiah of the sons of Merari, his brothers and their sons, twenty men;", 
                "num": 19
              }, 
              {
                "text": "also of the Nethinim, whom David and the leaders had appointed for the service of the Levites, two hundred and twenty Nethinim. All of them were designated by name.", 
                "num": 20
              }, 
              {
                "text": "Then I proclaimed a fast there at the river of Ahava, that we might humble ourselves before our God, to seek from Him the right way for us and our little ones and all our possessions.", 
                "num": 21
              }, 
              {
                "text": "For I was ashamed to request of the king an escort of soldiers and horsemen to help us against the enemy on the road, because we had spoken to the king, saying, \"The hand of our God is upon all those for good who seek Him, but His power and His wrath are against all those who forsake Him.\"", 
                "num": 22
              }, 
              {
                "text": "So we fasted and entreated our God for this, and He answered our prayer.", 
                "num": 23
              }, 
              {
                "text": "And I separated twelve of the leaders of the priests--Sherebiah, Hashabiah, and ten of their brethren with them--", 
                "num": 24
              }, 
              {
                "text": "and weighed out to them the silver, the gold, and the articles, the offering for the house of our God which the king and his counselors and his princes, and all Israel who were present, had offered.", 
                "num": 25
              }, 
              {
                "text": "I weighed into their hand six hundred and fifty talents of silver, silver articles weighing one hundred talents, one hundred talents of gold,", 
                "num": 26
              }, 
              {
                "text": "twenty gold basins worth a thousand drachmas, and two vessels of fine polished bronze, precious as gold.", 
                "num": 27
              }, 
              {
                "text": "And I said to them, \"You are holy to the LORD; the articles are holy also; and the silver and the gold are a freewill offering to the LORD God of your fathers.", 
                "num": 28
              }, 
              {
                "text": "Watch and keep them until you weigh them before the leaders of the priests and the Levites and heads of the fathers' houses of Israel in Jerusalem, in the chambers of the house of the LORD.\"", 
                "num": 29
              }, 
              {
                "text": "So the priests and the Levites received the silver and the gold and the articles by weight, to bring them to Jerusalem to the house of our God.", 
                "num": 30
              }, 
              {
                "text": "Then we departed from the river of Ahava on the twelfth day of the first month, to go to Jerusalem. And the hand of our God was upon us, and He delivered us from the hand of the enemy and from ambush along the road.", 
                "num": 31
              }, 
              {
                "text": "So we came to Jerusalem, and stayed there three days.", 
                "num": 32
              }, 
              {
                "text": "Now on the fourth day the silver and the gold and the articles were weighed in the house of our God by the hand of Meremoth the son of Uriah the priest, and with him was Eleazar the son of Phinehas; with them were the Levites, Jozabad the son of Jeshua and Noadiah the son of Binnui,", 
                "num": 33
              }, 
              {
                "text": "with the number and weight of everything. All the weight was written down at that time.", 
                "num": 34
              }, 
              {
                "text": "The children of those who had been carried away captive, who had come from the captivity, offered burnt offerings to the God of Israel: twelve bulls for all Israel, ninety-six rams, seventy-seven lambs, and twelve male goats as a sin offering. All this was a burnt offering to the LORD.", 
                "num": 35
              }, 
              {
                "text": "And they delivered the king's orders to the king's satraps and the governors in the region beyond the River. So they gave support to the people and the house of God.", 
                "num": 36
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "When these things were done, the leaders came to me, saying, \"The people of Israel and the priests and the Levites have not separated themselves from the peoples of the lands, with respect to the abominations of the Canaanites, the Hittites, the Perizzites, the Jebusites, the Ammonites, the Moabites, the Egyptians, and the Amorites.", 
                "num": 1
              }, 
              {
                "text": "For they have taken some of their daughters as wives for themselves and their sons, so that the holy seed is mixed with the peoples of those lands. Indeed, the hand of the leaders and rulers has been foremost in this trespass.\"", 
                "num": 2
              }, 
              {
                "text": "So when I heard this thing, I tore my garment and my robe, and plucked out some of the hair of my head and beard, and sat down astonished.", 
                "num": 3
              }, 
              {
                "text": "Then everyone who trembled at the words of the God of Israel assembled to me, because of the transgression of those who had been carried away captive, and I sat astonished until the evening sacrifice.", 
                "num": 4
              }, 
              {
                "text": "At the evening sacrifice I arose from my fasting; and having torn my garment and my robe, I fell on my knees and spread out my hands to the LORD my God.", 
                "num": 5
              }, 
              {
                "text": "And I said: \"O my God, I am too ashamed and humiliated to lift up my face to You, my God; for our iniquities have risen higher than our heads, and our guilt has grown up to the heavens.", 
                "num": 6
              }, 
              {
                "text": "Since the days of our fathers to this day we have been very guilty, and for our iniquities we, our kings, and our priests have been delivered into the hand of the kings of the lands, to the sword, to captivity, to plunder, and to humiliation, as it is this day.", 
                "num": 7
              }, 
              {
                "text": "And now for a little while grace has been shown from the LORD our God, to leave us a remnant to escape, and to give us a peg in His holy place, that our God may enlighten our eyes and give us a measure of revival in our bondage.", 
                "num": 8
              }, 
              {
                "text": "For we were slaves. Yet our God did not forsake us in our bondage; but He extended mercy to us in the sight of the kings of Persia, to revive us, to repair the house of our God, to rebuild its ruins, and to give us a wall in Judah and Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "And now, O our God, what shall we say after this? For we have forsaken Your commandments,", 
                "num": 10
              }, 
              {
                "text": "which You commanded by Your servants the prophets, saying, \"The land which you are entering to possess is an unclean land, with the uncleanness of the peoples of the lands, with their abominations which have filled it from one end to another with their impurity.", 
                "num": 11
              }, 
              {
                "text": "Now therefore, do not give your daughters as wives for their sons, nor take their daughters to your sons; and never seek their peace or prosperity, that you may be strong and eat the good of the land, and leave it as an inheritance to your children forever.'", 
                "num": 12
              }, 
              {
                "text": "And after all that has come upon us for our evil deeds and for our great guilt, since You our God have punished us less than our iniquities deserve, and have given us such deliverance as this,", 
                "num": 13
              }, 
              {
                "text": "should we again break Your commandments, and join in marriage with the people committing these abominations? Would You not be angry with us until You had consumed us, so that there would be no remnant or survivor?", 
                "num": 14
              }, 
              {
                "text": "O LORD God of Israel, You are righteous, for we are left as a remnant, as it is this day. Here we are before You, in our guilt, though no one can stand before You because of this!\"", 
                "num": 15
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now while Ezra was praying, and while he was confessing, weeping, and bowing down before the house of God, a very large assembly of men, women, and children gathered to him from Israel; for the people wept very bitterly.", 
                "num": 1
              }, 
              {
                "text": "And Shechaniah the son of Jehiel, one of the sons of Elam, spoke up and said to Ezra, \"We have trespassed against our God, and have taken pagan wives from the peoples of the land; yet now there is hope in Israel in spite of this.", 
                "num": 2
              }, 
              {
                "text": "Now therefore, let us make a covenant with our God to put away all these wives and those who have been born to them, according to the advice of my master and of those who tremble at the commandment of our God; and let it be done according to the law.", 
                "num": 3
              }, 
              {
                "text": "Arise, for this matter is your responsibility. We also are with you. Be of good courage, and do it.\"", 
                "num": 4
              }, 
              {
                "text": "Then Ezra arose, and made the leaders of the priests, the Levites, and all Israel swear an oath that they would do according to this word. So they swore an oath.", 
                "num": 5
              }, 
              {
                "text": "Then Ezra rose up from before the house of God, and went into the chamber of Jehohanan the son of Eliashib; and when he came there, he ate no bread and drank no water, for he mourned because of the guilt of those from the captivity.", 
                "num": 6
              }, 
              {
                "text": "And they issued a proclamation throughout Judah and Jerusalem to all the descendants of the captivity, that they must gather at Jerusalem,", 
                "num": 7
              }, 
              {
                "text": "and that whoever would not come within three days, according to the instructions of the leaders and elders, all his property would be confiscated, and he himself would be separated from the assembly of those from the captivity.", 
                "num": 8
              }, 
              {
                "text": "So all the men of Judah and Benjamin gathered at Jerusalem within three days. It was the ninth month, on the twentieth of the month; and all the people sat in the open square of the house of God, trembling because of this matter and because of heavy rain.", 
                "num": 9
              }, 
              {
                "text": "Then Ezra the priest stood up and said to them, \"You have transgressed and have taken pagan wives, adding to the guilt of Israel.", 
                "num": 10
              }, 
              {
                "text": "Now therefore, make confession to the LORD God of your fathers, and do His will; separate yourselves from the peoples of the land, and from the pagan wives.\"", 
                "num": 11
              }, 
              {
                "text": "Then all the assembly answered and said with a loud voice, \"Yes! As you have said, so we must do.", 
                "num": 12
              }, 
              {
                "text": "But there are many people; it is the season for heavy rain, and we are not able to stand outside. Nor is this the work of one or two days, for there are many of us who have transgressed in this matter.", 
                "num": 13
              }, 
              {
                "text": "Please, let the leaders of our entire assembly stand; and let all those in our cities who have taken pagan wives come at appointed times, together with the elders and judges of their cities, until the fierce wrath of our God is turned away from us in this matter.\"", 
                "num": 14
              }, 
              {
                "text": "Only Jonathan the son of Asahel and Jahaziah the son of Tikvah opposed this, and Meshullam and Shabbethai the Levite gave them support.", 
                "num": 15
              }, 
              {
                "text": "Then the descendants of the captivity did so. And Ezra the priest, with certain heads of the fathers' households, were set apart by the fathers' households, each of them by name; and they sat down on the first day of the tenth month to examine the matter.", 
                "num": 16
              }, 
              {
                "text": "By the first day of the first month they finished questioning all the men who had taken pagan wives.", 
                "num": 17
              }, 
              {
                "text": "And among the sons of the priests who had taken pagan wives the following were found of the sons of Jeshua the son of Jozadak, and his brothers: Maaseiah, Eliezer, Jarib, and Gedaliah.", 
                "num": 18
              }, 
              {
                "text": "And they gave their promise that they would put away their wives; and being guilty, they presented a ram of the flock as their trespass offering.", 
                "num": 19
              }, 
              {
                "text": "Also of the sons of Immer: Hanani and Zebadiah;", 
                "num": 20
              }, 
              {
                "text": "of the sons of Harim: Maaseiah, Elijah, Shemaiah, Jehiel, and Uzziah;", 
                "num": 21
              }, 
              {
                "text": "of the sons of Pashhur: Elioenai, Maaseiah, Ishmael, Nethanel, Jozabad, and Elasah.", 
                "num": 22
              }, 
              {
                "text": "Also of the Levites: Jozabad, Shimei, Kelaiah (the same is Kelita), Pethahiah, Judah, and Eliezer.", 
                "num": 23
              }, 
              {
                "text": "Also of the singers: Eliashib; and of the gatekeepers: Shallum, Telem, and Uri.", 
                "num": 24
              }, 
              {
                "text": "And others of Israel: of the sons of Parosh: Ramiah, Jeziah, Malchiah, Mijamin, Eleazar, Malchijah, and Benaiah;", 
                "num": 25
              }, 
              {
                "text": "of the sons of Elam: Mattaniah, Zechariah, Jehiel, Abdi, Jeremoth, and Eliah;", 
                "num": 26
              }, 
              {
                "text": "of the sons of Zattu: Elioenai, Eliashib, Mattaniah, Jeremoth, Zabad, and Aziza;", 
                "num": 27
              }, 
              {
                "text": "of the sons of Bebai: Jehohanan, Hananiah, Zabbai, and Athlai;", 
                "num": 28
              }, 
              {
                "text": "of the sons of Bani: Meshullam, Malluch, Adaiah, Jashub, Sheal, and Ramoth;", 
                "num": 29
              }, 
              {
                "text": "of the sons of Pahath-Moab: Adna, Chelal, Benaiah, Maaseiah, Mattaniah, Bezalel, Binnui, and Manasseh;", 
                "num": 30
              }, 
              {
                "text": "of the sons of Harim: Eliezer, Ishijah, Malchijah, Shemaiah, Shimeon,", 
                "num": 31
              }, 
              {
                "text": "Benjamin, Malluch, and Shemariah;", 
                "num": 32
              }, 
              {
                "text": "of the sons of Hashum: Mattenai, Mattattah, Zabad, Eliphelet, Jeremai, Manasseh, and Shimei;", 
                "num": 33
              }, 
              {
                "text": "of the sons of Bani: Maadai, Amram, Uel,", 
                "num": 34
              }, 
              {
                "text": "Benaiah, Bedeiah, Cheluh,", 
                "num": 35
              }, 
              {
                "text": "Vaniah, Meremoth, Eliashib,", 
                "num": 36
              }, 
              {
                "text": "Mattaniah, Mattenai, Jaasai,", 
                "num": 37
              }, 
              {
                "text": "Bani, Binnui, Shimei,", 
                "num": 38
              }, 
              {
                "text": "Shelemiah, Nathan, Adaiah,", 
                "num": 39
              }, 
              {
                "text": "Machnadebai, Shashai, Sharai,", 
                "num": 40
              }, 
              {
                "text": "Azarel, Shelemiah, Shemariah,", 
                "num": 41
              }, 
              {
                "text": "Shallum, Amariah, and Joseph;", 
                "num": 42
              }, 
              {
                "text": "of the sons of Nebo: Jeiel, Mattithiah, Zabad, Zebina, Jaddai, Joel, and Benaiah.", 
                "num": 43
              }, 
              {
                "text": "All these had taken pagan wives, and some of them had wives by whom they had children.", 
                "num": 44
              }
            ], 
            "num": 10
          }
        ]
      }, 
      {
        "name": "Nehemiah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The words of Nehemiah the son of Hachaliah. It came to pass in the month of Chislev, in the twentieth year, as I was in Shushan the citadel,", 
                "num": 1
              }, 
              {
                "text": "that Hanani one of my brethren came with men from Judah; and I asked them concerning the Jews who had escaped, who had survived the captivity, and concerning Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "And they said to me, \"The survivors who are left from the captivity in the province are there in great distress and reproach. The wall of Jerusalem is also broken down, and its gates are burned with fire.\"", 
                "num": 3
              }, 
              {
                "text": "So it was, when I heard these words, that I sat down and wept, and mourned for many days; I was fasting and praying before the God of heaven.", 
                "num": 4
              }, 
              {
                "text": "And I said: \"I pray, LORD God of heaven, O great and awesome God, You who keep Your covenant and mercy with those who love You and observe Your commandments,", 
                "num": 5
              }, 
              {
                "text": "please let Your ear be attentive and Your eyes open, that You may hear the prayer of Your servant which I pray before You now, day and night, for the children of Israel Your servants, and confess the sins of the children of Israel which we have sinned against You. Both my father's house and I have sinned.", 
                "num": 6
              }, 
              {
                "text": "We have acted very corruptly against You, and have not kept the commandments, the statutes, nor the ordinances which You commanded Your servant Moses.", 
                "num": 7
              }, 
              {
                "text": "Remember, I pray, the word that You commanded Your servant Moses, saying, \"If you are unfaithful, I will scatter you among the nations;", 
                "num": 8
              }, 
              {
                "text": "but if you return to Me, and keep My commandments and do them, though some of you were cast out to the farthest part of the heavens, yet I will gather them from there, and bring them to the place which I have chosen as a dwelling for My name.'", 
                "num": 9
              }, 
              {
                "text": "Now these are Your servants and Your people, whom You have redeemed by Your great power, and by Your strong hand.", 
                "num": 10
              }, 
              {
                "text": "O Lord, I pray, please let Your ear be attentive to the prayer of Your servant, and to the prayer of Your servants who desire to fear Your name; and let Your servant prosper this day, I pray, and grant him mercy in the sight of this man.\" For I was the king's cupbearer.", 
                "num": 11
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the month of Nisan, in the twentieth year of King Artaxerxes, when wine was before him, that I took the wine and gave it to the king. Now I had never been sad in his presence before.", 
                "num": 1
              }, 
              {
                "text": "Therefore the king said to me, \"Why is your face sad, since you are not sick? This is nothing but sorrow of heart.\" So I became dreadfully afraid,", 
                "num": 2
              }, 
              {
                "text": "and said to the king, \"May the king live forever! Why should my face not be sad, when the city, the place of my fathers' tombs, lies waste, and its gates are burned with fire?\"", 
                "num": 3
              }, 
              {
                "text": "Then the king said to me, \"What do you request?\" So I prayed to the God of heaven.", 
                "num": 4
              }, 
              {
                "text": "And I said to the king, \"If it pleases the king, and if your servant has found favor in your sight, I ask that you send me to Judah, to the city of my fathers' tombs, that I may rebuild it.\"", 
                "num": 5
              }, 
              {
                "text": "Then the king said to me (the queen also sitting beside him), \"How long will your journey be? And when will you return?\" So it pleased the king to send me; and I set him a time.", 
                "num": 6
              }, 
              {
                "text": "Furthermore I said to the king, \"If it pleases the king, let letters be given to me for the governors of the region beyond the River, that they must permit me to pass through till I come to Judah,", 
                "num": 7
              }, 
              {
                "text": "and a letter to Asaph the keeper of the king's forest, that he must give me timber to make beams for the gates of the citadel which pertains to the temple, for the city wall, and for the house that I will occupy.\" And the king granted them to me according to the good hand of my God upon me.", 
                "num": 8
              }, 
              {
                "text": "Then I went to the governors in the region beyond the River, and gave them the king's letters. Now the king had sent captains of the army and horsemen with me.", 
                "num": 9
              }, 
              {
                "text": "When Sanballat the Horonite and Tobiah the Ammonite official heard of it, they were deeply disturbed that a man had come to seek the well-being of the children of Israel.", 
                "num": 10
              }, 
              {
                "text": "So I came to Jerusalem and was there three days.", 
                "num": 11
              }, 
              {
                "text": "Then I arose in the night, I and a few men with me; I told no one what my God had put in my heart to do at Jerusalem; nor was there any animal with me, except the one on which I rode.", 
                "num": 12
              }, 
              {
                "text": "And I went out by night through the Valley Gate to the Serpent Well and the Refuse Gate, and viewed the walls of Jerusalem which were broken down and its gates which were burned with fire.", 
                "num": 13
              }, 
              {
                "text": "Then I went on to the Fountain Gate and to the King's Pool, but there was no room for the animal under me to pass.", 
                "num": 14
              }, 
              {
                "text": "So I went up in the night by the valley, and viewed the wall; then I turned back and entered by the Valley Gate, and so returned.", 
                "num": 15
              }, 
              {
                "text": "And the officials did not know where I had gone or what I had done; I had not yet told the Jews, the priests, the nobles, the officials, or the others who did the work.", 
                "num": 16
              }, 
              {
                "text": "Then I said to them, \"You see the distress that we are in, how Jerusalem lies waste, and its gates are burned with fire. Come and let us build the wall of Jerusalem, that we may no longer be a reproach.\"", 
                "num": 17
              }, 
              {
                "text": "And I told them of the hand of my God which had been good upon me, and also of the king's words that he had spoken to me. So they said, \"Let us rise up and build.\" Then they set their hands to this good work.", 
                "num": 18
              }, 
              {
                "text": "But when Sanballat the Horonite, Tobiah the Ammonite official, and Geshem the Arab heard of it, they laughed at us and despised us, and said, \"What is this thing that you are doing? Will you rebel against the king?\"", 
                "num": 19
              }, 
              {
                "text": "So I answered them, and said to them, \"The God of heaven Himself will prosper us; therefore we His servants will arise and build, but you have no heritage or right or memorial in Jerusalem.\"", 
                "num": 20
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Then Eliashib the high priest rose up with his brethren the priests and built the Sheep Gate; they consecrated it and hung its doors. They built as far as the Tower of the Hundred, and consecrated it, then as far as the Tower of Hananel.", 
                "num": 1
              }, 
              {
                "text": "Next to Eliashib the men of Jericho built. And next to them Zaccur the son of Imri built.", 
                "num": 2
              }, 
              {
                "text": "Also the sons of Hassenaah built the Fish Gate; they laid its beams and hung its doors with its bolts and bars.", 
                "num": 3
              }, 
              {
                "text": "And next to them Meremoth the son of Urijah, the son of Koz, made repairs. Next to them Meshullam the son of Berechiah, the son of Meshezabel, made repairs. Next to them Zadok the son of Baana made repairs.", 
                "num": 4
              }, 
              {
                "text": "Next to them the Tekoites made repairs; but their nobles did not put their shoulders to the work of their Lord.", 
                "num": 5
              }, 
              {
                "text": "Moreover Jehoiada the son of Paseah and Meshullam the son of Besodeiah repaired the Old Gate; they laid its beams and hung its doors, with its bolts and bars.", 
                "num": 6
              }, 
              {
                "text": "And next to them Melatiah the Gibeonite, Jadon the Meronothite, the men of Gibeon and Mizpah, repaired the residence of the governor of the region beyond the River.", 
                "num": 7
              }, 
              {
                "text": "Next to him Uzziel the son of Harhaiah, one of the goldsmiths, made repairs. Also next to him Hananiah, one of the perfumers, made repairs; and they fortified Jerusalem as far as the Broad Wall.", 
                "num": 8
              }, 
              {
                "text": "And next to them Rephaiah the son of Hur, leader of half the district of Jerusalem, made repairs.", 
                "num": 9
              }, 
              {
                "text": "Next to them Jedaiah the son of Harumaph made repairs in front of his house. And next to him Hattush the son of Hashabniah made repairs.", 
                "num": 10
              }, 
              {
                "text": "Malchijah the son of Harim and Hashub the son of Pahath-Moab repaired another section, as well as the Tower of the Ovens.", 
                "num": 11
              }, 
              {
                "text": "And next to him was Shallum the son of Hallohesh, leader of half the district of Jerusalem; he and his daughters made repairs.", 
                "num": 12
              }, 
              {
                "text": "Hanun and the inhabitants of Zanoah repaired the Valley Gate. They built it, hung its doors with its bolts and bars, and repaired a thousand cubits of the wall as far as the Refuse Gate.", 
                "num": 13
              }, 
              {
                "text": "Malchijah the son of Rechab, leader of the district of Beth Haccerem, repaired the Refuse Gate; he built it and hung its doors with its bolts and bars.", 
                "num": 14
              }, 
              {
                "text": "Shallun the son of Col-Hozeh, leader of the district of Mizpah, repaired the Fountain Gate; he built it, covered it, hung its doors with its bolts and bars, and repaired the wall of the Pool of Shelah by the King's Garden, as far as the stairs that go down from the City of David.", 
                "num": 15
              }, 
              {
                "text": "After him Nehemiah the son of Azbuk, leader of half the district of Beth Zur, made repairs as far as the place in front of the tombs of David, to the man-made pool, and as far as the House of the Mighty.", 
                "num": 16
              }, 
              {
                "text": "After him the Levites, under Rehum the son of Bani, made repairs. Next to him Hashabiah, leader of half the district of Keilah, made repairs for his district.", 
                "num": 17
              }, 
              {
                "text": "After him their brethren, under Bavai the son of Henadad, leader of the other half of the district of Keilah, made repairs.", 
                "num": 18
              }, 
              {
                "text": "And next to him Ezer the son of Jeshua, the leader of Mizpah, repaired another section in front of the Ascent to the Armory at the buttress.", 
                "num": 19
              }, 
              {
                "text": "After him Baruch the son of Zabbai carefully repaired the other section, from the buttress to the door of the house of Eliashib the high priest.", 
                "num": 20
              }, 
              {
                "text": "After him Meremoth the son of Urijah, the son of Koz, repaired another section, from the door of the house of Eliashib to the end of the house of Eliashib.", 
                "num": 21
              }, 
              {
                "text": "And after him the priests, the men of the plain, made repairs.", 
                "num": 22
              }, 
              {
                "text": "After him Benjamin and Hasshub made repairs opposite their house. After them Azariah the son of Maaseiah, the son of Ananiah, made repairs by his house.", 
                "num": 23
              }, 
              {
                "text": "After him Binnui the son of Henadad repaired another section, from the house of Azariah to the buttress, even as far as the corner.", 
                "num": 24
              }, 
              {
                "text": "Palal the son of Uzai made repairs opposite the buttress, and on the tower which projects from the king's upper house that was by the court of the prison. After him Pedaiah the son of Parosh made repairs.", 
                "num": 25
              }, 
              {
                "text": "Moreover the Nethinim who dwelt in Ophel made repairs as far as the place in front of the Water Gate toward the east, and on the projecting tower.", 
                "num": 26
              }, 
              {
                "text": "After them the Tekoites repaired another section, next to the great projecting tower, and as far as the wall of Ophel.", 
                "num": 27
              }, 
              {
                "text": "Beyond the Horse Gate the priests made repairs, each in front of his own house.", 
                "num": 28
              }, 
              {
                "text": "After them Zadok the son of Immer made repairs in front of his own house. After him Shemaiah the son of Shechaniah, the keeper of the East Gate, made repairs.", 
                "num": 29
              }, 
              {
                "text": "After him Hananiah the son of Shelemiah, and Hanun, the sixth son of Zalaph, repaired another section. After him Meshullam the son of Berechiah made repairs in front of his dwelling.", 
                "num": 30
              }, 
              {
                "text": "After him Malchijah, one of the goldsmiths, made repairs as far as the house of the Nethinim and of the merchants, in front of the Miphkad Gate, and as far as the upper room at the corner.", 
                "num": 31
              }, 
              {
                "text": "And between the upper room at the corner, as far as the Sheep Gate, the goldsmiths and the merchants made repairs.", 
                "num": 32
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "But it so happened, when Sanballat heard that we were rebuilding the wall, that he was furious and very indignant, and mocked the Jews.", 
                "num": 1
              }, 
              {
                "text": "And he spoke before his brethren and the army of Samaria, and said, \"What are these feeble Jews doing? Will they fortify themselves? Will they offer sacrifices? Will they complete it in a day? Will they revive the stones from the heaps of rubbish--stones that are burned?\"", 
                "num": 2
              }, 
              {
                "text": "Now Tobiah the Ammonite was beside him, and he said, \"Whatever they build, if even a fox goes up on it, he will break down their stone wall.\"", 
                "num": 3
              }, 
              {
                "text": "Hear, O our God, for we are despised; turn their reproach on their own heads, and give them as plunder to a land of captivity!", 
                "num": 4
              }, 
              {
                "text": "Do not cover their iniquity, and do not let their sin be blotted out from before You; for they have provoked You to anger before the builders.", 
                "num": 5
              }, 
              {
                "text": "So we built the wall, and the entire wall was joined together up to half its height, for the people had a mind to work.", 
                "num": 6
              }, 
              {
                "text": "Now it happened, when Sanballat, Tobiah, the Arabs, the Ammonites, and the Ashdodites heard that the walls of Jerusalem were being restored and the gaps were beginning to be closed, that they became very angry,", 
                "num": 7
              }, 
              {
                "text": "and all of them conspired together to come and attack Jerusalem and create confusion.", 
                "num": 8
              }, 
              {
                "text": "Nevertheless we made our prayer to our God, and because of them we set a watch against them day and night.", 
                "num": 9
              }, 
              {
                "text": "Then Judah said, \"The strength of the laborers is failing, and there is so much rubbish that we are not able to build the wall.\"", 
                "num": 10
              }, 
              {
                "text": "And our adversaries said, \"They will neither know nor see anything, till we come into their midst and kill them and cause the work to cease.\"", 
                "num": 11
              }, 
              {
                "text": "So it was, when the Jews who dwelt near them came, that they told us ten times, \"From whatever place you turn, they will be upon us.\"", 
                "num": 12
              }, 
              {
                "text": "Therefore I positioned men behind the lower parts of the wall, at the openings; and I set the people according to their families, with their swords, their spears, and their bows.", 
                "num": 13
              }, 
              {
                "text": "And I looked, and arose and said to the nobles, to the leaders, and to the rest of the people, \"Do not be afraid of them. Remember the Lord, great and awesome, and fight for your brethren, your sons, your daughters, your wives, and your houses.\"", 
                "num": 14
              }, 
              {
                "text": "And it happened, when our enemies heard that it was known to us, and that God had brought their plot to nothing, that all of us returned to the wall, everyone to his work.", 
                "num": 15
              }, 
              {
                "text": "So it was, from that time on, that half of my servants worked at construction, while the other half held the spears, the shields, the bows, and wore armor; and the leaders were behind all the house of Judah.", 
                "num": 16
              }, 
              {
                "text": "Those who built on the wall, and those who carried burdens, loaded themselves so that with one hand they worked at construction, and with the other held a weapon.", 
                "num": 17
              }, 
              {
                "text": "Every one of the builders had his sword girded at his side as he built. And the one who sounded the trumpet was beside me.", 
                "num": 18
              }, 
              {
                "text": "Then I said to the nobles, the rulers, and the rest of the people, \"The work is great and extensive, and we are separated far from one another on the wall.", 
                "num": 19
              }, 
              {
                "text": "Wherever you hear the sound of the trumpet, rally to us there. Our God will fight for us.\"", 
                "num": 20
              }, 
              {
                "text": "So we labored in the work, and half of the men held the spears from daybreak until the stars appeared.", 
                "num": 21
              }, 
              {
                "text": "At the same time I also said to the people, \"Let each man and his servant stay at night in Jerusalem, that they may be our guard by night and a working party by day.\"", 
                "num": 22
              }, 
              {
                "text": "So neither I, my brethren, my servants, nor the men of the guard who followed me took off our clothes, except that everyone took them off for washing.", 
                "num": 23
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "And there was a great outcry of the people and their wives against their Jewish brethren.", 
                "num": 1
              }, 
              {
                "text": "For there were those who said, \"We, our sons, and our daughters are many; therefore let us get grain, that we may eat and live.\"", 
                "num": 2
              }, 
              {
                "text": "There were also some who said, \"We have mortgaged our lands and vineyards and houses, that we might buy grain because of the famine.\"", 
                "num": 3
              }, 
              {
                "text": "There were also those who said, \"We have borrowed money for the king's tax on our lands and vineyards.", 
                "num": 4
              }, 
              {
                "text": "Yet now our flesh is as the flesh of our brethren, our children as their children; and indeed we are forcing our sons and our daughters to be slaves, and some of our daughters have been brought into slavery. It is not in our power to redeem them, for other men have our lands and vineyards.\"", 
                "num": 5
              }, 
              {
                "text": "And I became very angry when I heard their outcry and these words.", 
                "num": 6
              }, 
              {
                "text": "After serious thought, I rebuked the nobles and rulers, and said to them, \"Each of you is exacting usury from his brother.\" So I called a great assembly against them.", 
                "num": 7
              }, 
              {
                "text": "And I said to them, \"According to our ability we have redeemed our Jewish brethren who were sold to the nations. Now indeed, will you even sell your brethren? Or should they be sold to us?\" Then they were silenced and found nothing to say.", 
                "num": 8
              }, 
              {
                "text": "Then I said, \"What you are doing is not good. Should you not walk in the fear of our God because of the reproach of the nations, our enemies?", 
                "num": 9
              }, 
              {
                "text": "I also, with my brethren and my servants, am lending them money and grain. Please, let us stop this usury!", 
                "num": 10
              }, 
              {
                "text": "Restore now to them, even this day, their lands, their vineyards, their olive groves, and their houses, also a hundredth of the money and the grain, the new wine and the oil, that you have charged them.\"", 
                "num": 11
              }, 
              {
                "text": "So they said, \"We will restore it, and will require nothing from them; we will do as you say.\" Then I called the priests, and required an oath from them that they would do according to this promise.", 
                "num": 12
              }, 
              {
                "text": "Then I shook out the fold of my garment and said, \"So may God shake out each man from his house, and from his property, who does not perform this promise. Even thus may he be shaken out and emptied.\" And all the assembly said, \"Amen!\" and praised the LORD. Then the people did according to this promise.", 
                "num": 13
              }, 
              {
                "text": "Moreover, from the time that I was appointed to be their governor in the land of Judah, from the twentieth year until the thirty-second year of King Artaxerxes, twelve years, neither I nor my brothers ate the governor's provisions.", 
                "num": 14
              }, 
              {
                "text": "But the former governors who were before me laid burdens on the people, and took from them bread and wine, besides forty shekels of silver. Yes, even their servants bore rule over the people, but I did not do so, because of the fear of God.", 
                "num": 15
              }, 
              {
                "text": "Indeed, I also continued the work on this wall, and we did not buy any land. All my servants were gathered there for the work.", 
                "num": 16
              }, 
              {
                "text": "And at my table were one hundred and fifty Jews and rulers, besides those who came to us from the nations around us.", 
                "num": 17
              }, 
              {
                "text": "Now that which was prepared daily was one ox and six choice sheep. Also fowl were prepared for me, and once every ten days an abundance of all kinds of wine. Yet in spite of this I did not demand the governor's provisions, because the bondage was heavy on this people.", 
                "num": 18
              }, 
              {
                "text": "Remember me, my God, for good, according to all that I have done for this people.", 
                "num": 19
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now it happened when Sanballat, Tobiah, Geshem the Arab, and the rest of our enemies heard that I had rebuilt the wall, and that there were no breaks left in it (though at that time I had not hung the doors in the gates),", 
                "num": 1
              }, 
              {
                "text": "that Sanballat and Geshem sent to me, saying, \"Come, let us meet together among the villages in the plain of Ono.\" But they thought to do me harm.", 
                "num": 2
              }, 
              {
                "text": "So I sent messengers to them, saying, \"I am doing a great work, so that I cannot come down. Why should the work cease while I leave it and go down to you?\"", 
                "num": 3
              }, 
              {
                "text": "But they sent me this message four times, and I answered them in the same manner.", 
                "num": 4
              }, 
              {
                "text": "Then Sanballat sent his servant to me as before, the fifth time, with an open letter in his hand.", 
                "num": 5
              }, 
              {
                "text": "In it was written: It is reported among the nations, and Geshem says, that you and the Jews plan to rebel; therefore, according to these rumors, you are rebuilding the wall, that you may be their king.", 
                "num": 6
              }, 
              {
                "text": "And you have also appointed prophets to proclaim concerning you at Jerusalem, saying, \"There is a king in Judah!\" Now these matters will be reported to the king. So come, therefore, and let us consult together.", 
                "num": 7
              }, 
              {
                "text": "Then I sent to him, saying, \"No such things as you say are being done, but you invent them in your own heart.\"", 
                "num": 8
              }, 
              {
                "text": "For they all were trying to make us afraid, saying, \"Their hands will be weakened in the work, and it will not be done.\" Now therefore, O God, strengthen my hands.", 
                "num": 9
              }, 
              {
                "text": "Afterward I came to the house of Shemaiah the son of Delaiah, the son of Mehetabel, who was a secret informer; and he said, \"Let us meet together in the house of God, within the temple, and let us close the doors of the temple, for they are coming to kill you; indeed, at night they will come to kill you.\"", 
                "num": 10
              }, 
              {
                "text": "And I said, \"Should such a man as I flee? And who is there such as I who would go into the temple to save his life? I will not go in!\"", 
                "num": 11
              }, 
              {
                "text": "Then I perceived that God had not sent him at all, but that he pronounced this prophecy against me because Tobiah and Sanballat had hired him.", 
                "num": 12
              }, 
              {
                "text": "For this reason he was hired, that I should be afraid and act that way and sin, so that they might have cause for an evil report, that they might reproach me.", 
                "num": 13
              }, 
              {
                "text": "My God, remember Tobiah and Sanballat, according to these their works, and the prophetess Noadiah and the rest of the prophets who would have made me afraid.", 
                "num": 14
              }, 
              {
                "text": "So the wall was finished on the twenty-fifth day of Elul, in fifty-two days.", 
                "num": 15
              }, 
              {
                "text": "And it happened, when all our enemies heard of it, and all the nations around us saw these things, that they were very disheartened in their own eyes; for they perceived that this work was done by our God.", 
                "num": 16
              }, 
              {
                "text": "Also in those days the nobles of Judah sent many letters to Tobiah, and the letters of Tobiah came to them.", 
                "num": 17
              }, 
              {
                "text": "For many in Judah were pledged to him, because he was the son-in-law of Shechaniah the son of Arah, and his son Jehohanan had married the daughter of Meshullam the son of Berechiah.", 
                "num": 18
              }, 
              {
                "text": "Also they reported his good deeds before me, and reported my words to him. Tobiah sent letters to frighten me.", 
                "num": 19
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then it was, when the wall was built and I had hung the doors, when the gatekeepers, the singers, and the Levites had been appointed,", 
                "num": 1
              }, 
              {
                "text": "that I gave the charge of Jerusalem to my brother Hanani, and Hananiah the leader of the citadel, for he was a faithful man and feared God more than many.", 
                "num": 2
              }, 
              {
                "text": "And I said to them, \"Do not let the gates of Jerusalem be opened until the sun is hot; and while they stand guard, let them shut and bar the doors; and appoint guards from among the inhabitants of Jerusalem, one at his watch station and another in front of his own house.\"", 
                "num": 3
              }, 
              {
                "text": "Now the city was large and spacious, but the people in it were few, and the houses were not rebuilt.", 
                "num": 4
              }, 
              {
                "text": "Then my God put it into my heart to gather the nobles, the rulers, and the people, that they might be registered by genealogy. And I found a register of the genealogy of those who had come up in the first return, and found written in it:", 
                "num": 5
              }, 
              {
                "text": "These are the people of the province who came back from the captivity, of those who had been carried away, whom Nebuchadnezzar the king of Babylon had carried away, and who returned to Jerusalem and Judah, everyone to his city.", 
                "num": 6
              }, 
              {
                "text": "Those who came with Zerubbabel were Jeshua, Nehemiah, Azariah, Raamiah, Nahamani, Mordecai, Bilshan, Mispereth, Bigvai, Nehum, and Baanah. The number of the men of the people of Israel:", 
                "num": 7
              }, 
              {
                "text": "the sons of Parosh, two thousand one hundred and seventy-two;", 
                "num": 8
              }, 
              {
                "text": "the sons of Shephatiah, three hundred and seventy-two;", 
                "num": 9
              }, 
              {
                "text": "the sons of Arah, six hundred and fifty-two;", 
                "num": 10
              }, 
              {
                "text": "the sons of Pahath-Moab, of the sons of Jeshua and Joab, two thousand eight hundred and eighteen;", 
                "num": 11
              }, 
              {
                "text": "the sons of Elam, one thousand two hundred and fifty-four;", 
                "num": 12
              }, 
              {
                "text": "the sons of Zattu, eight hundred and forty-five;", 
                "num": 13
              }, 
              {
                "text": "the sons of Zaccai, seven hundred and sixty;", 
                "num": 14
              }, 
              {
                "text": "the sons of Binnui, six hundred and forty-eight;", 
                "num": 15
              }, 
              {
                "text": "the sons of Bebai, six hundred and twenty-eight;", 
                "num": 16
              }, 
              {
                "text": "the sons of Azgad, two thousand three hundred and twenty-two;", 
                "num": 17
              }, 
              {
                "text": "the sons of Adonikam, six hundred and sixty-seven;", 
                "num": 18
              }, 
              {
                "text": "the sons of Bigvai, two thousand and sixty-seven;", 
                "num": 19
              }, 
              {
                "text": "the sons of Adin, six hundred and fifty-five;", 
                "num": 20
              }, 
              {
                "text": "the sons of Ater of Hezekiah, ninety-eight;", 
                "num": 21
              }, 
              {
                "text": "the sons of Hashum, three hundred and twenty-eight;", 
                "num": 22
              }, 
              {
                "text": "the sons of Bezai, three hundred and twenty-four;", 
                "num": 23
              }, 
              {
                "text": "the sons of Hariph, one hundred and twelve;", 
                "num": 24
              }, 
              {
                "text": "the sons of Gibeon, ninety-five;", 
                "num": 25
              }, 
              {
                "text": "the men of Bethlehem and Netophah, one hundred and eighty-eight;", 
                "num": 26
              }, 
              {
                "text": "the men of Anathoth, one hundred and twenty-eight;", 
                "num": 27
              }, 
              {
                "text": "the men of Beth Azmaveth, forty-two;", 
                "num": 28
              }, 
              {
                "text": "the men of Kirjath Jearim, Chephirah, and Beeroth, seven hundred and forty-three;", 
                "num": 29
              }, 
              {
                "text": "the men of Ramah and Geba, six hundred and twenty-one;", 
                "num": 30
              }, 
              {
                "text": "the men of Michmas, one hundred and twenty-two;", 
                "num": 31
              }, 
              {
                "text": "the men of Bethel and Ai, one hundred and twenty-three;", 
                "num": 32
              }, 
              {
                "text": "the men of the other Nebo, fifty-two;", 
                "num": 33
              }, 
              {
                "text": "the sons of the other Elam, one thousand two hundred and fifty-four;", 
                "num": 34
              }, 
              {
                "text": "the sons of Harim, three hundred and twenty;", 
                "num": 35
              }, 
              {
                "text": "the sons of Jericho, three hundred and forty-five;", 
                "num": 36
              }, 
              {
                "text": "the sons of Lod, Hadid, and Ono, seven hundred and twenty-one;", 
                "num": 37
              }, 
              {
                "text": "the sons of Senaah, three thousand nine hundred and thirty.", 
                "num": 38
              }, 
              {
                "text": "The priests: the sons of Jedaiah, of the house of Jeshua, nine hundred and seventy-three;", 
                "num": 39
              }, 
              {
                "text": "the sons of Immer, one thousand and fifty-two;", 
                "num": 40
              }, 
              {
                "text": "the sons of Pashhur, one thousand two hundred and forty-seven;", 
                "num": 41
              }, 
              {
                "text": "the sons of Harim, one thousand and seventeen.", 
                "num": 42
              }, 
              {
                "text": "The Levites: the sons of Jeshua, of Kadmiel, and of the sons of Hodevah, seventy-four.", 
                "num": 43
              }, 
              {
                "text": "The singers: the sons of Asaph, one hundred and forty-eight.", 
                "num": 44
              }, 
              {
                "text": "The gatekeepers: the sons of Shallum, the sons of Ater, the sons of Talmon, the sons of Akkub, the sons of Hatita, the sons of Shobai, one hundred and thirty-eight.", 
                "num": 45
              }, 
              {
                "text": "The Nethinim: the sons of Ziha, the sons of Hasupha, the sons of Tabbaoth,", 
                "num": 46
              }, 
              {
                "text": "the sons of Keros, the sons of Sia, the sons of Padon,", 
                "num": 47
              }, 
              {
                "text": "the sons of Lebana, the sons of Hagaba, the sons of Salmai,", 
                "num": 48
              }, 
              {
                "text": "the sons of Hanan, the sons of Giddel, the sons of Gahar,", 
                "num": 49
              }, 
              {
                "text": "the sons of Reaiah, the sons of Rezin, the sons of Nekoda,", 
                "num": 50
              }, 
              {
                "text": "the sons of Gazzam, the sons of Uzza, the sons of Paseah,", 
                "num": 51
              }, 
              {
                "text": "the sons of Besai, the sons of Meunim, the sons of Nephishesim,", 
                "num": 52
              }, 
              {
                "text": "the sons of Bakbuk, the sons of Hakupha, the sons of Harhur,", 
                "num": 53
              }, 
              {
                "text": "the sons of Bazlith, the sons of Mehida, the sons of Harsha,", 
                "num": 54
              }, 
              {
                "text": "the sons of Barkos, the sons of Sisera, the sons of Tamah,", 
                "num": 55
              }, 
              {
                "text": "the sons of Neziah, and the sons of Hatipha.", 
                "num": 56
              }, 
              {
                "text": "The sons of Solomon's servants: the sons of Sotai, the sons of Sophereth, the sons of Perida,", 
                "num": 57
              }, 
              {
                "text": "the sons of Jaala, the sons of Darkon, the sons of Giddel,", 
                "num": 58
              }, 
              {
                "text": "the sons of Shephatiah, the sons of Hattil, the sons of Pochereth of Zebaim, and the sons of Amon.", 
                "num": 59
              }, 
              {
                "text": "All the Nethinim, and the sons of Solomon's servants, were three hundred and ninety-two.", 
                "num": 60
              }, 
              {
                "text": "And these were the ones who came up from Tel Melah, Tel Harsha, Cherub, Addon, and Immer, but they could not identify their father's house nor their lineage, whether they were of Israel:", 
                "num": 61
              }, 
              {
                "text": "the sons of Delaiah, the sons of Tobiah, the sons of Nekoda, six hundred and forty-two;", 
                "num": 62
              }, 
              {
                "text": "and of the priests: the sons of Habaiah, the sons of Koz, the sons of Barzillai, who took a wife of the daughters of Barzillai the Gileadite, and was called by their name.", 
                "num": 63
              }, 
              {
                "text": "These sought their listing among those who were registered by genealogy, but it was not found; therefore they were excluded from the priesthood as defiled.", 
                "num": 64
              }, 
              {
                "text": "And the governor said to them that they should not eat of the most holy things till a priest could consult with the Urim and Thummim.", 
                "num": 65
              }, 
              {
                "text": "Altogether the whole assembly was forty-two thousand three hundred and sixty,", 
                "num": 66
              }, 
              {
                "text": "besides their male and female servants, of whom there were seven thousand three hundred and thirty-seven; and they had two hundred and forty-five men and women singers.", 
                "num": 67
              }, 
              {
                "text": "Their horses were seven hundred and thirty-six, their mules two hundred and forty-five,", 
                "num": 68
              }, 
              {
                "text": "their camels four hundred and thirty-five, and donkeys six thousand seven hundred and twenty.", 
                "num": 69
              }, 
              {
                "text": "And some of the heads of the fathers' houses gave to the work. The governor gave to the treasury one thousand gold drachmas, fifty basins, and five hundred and thirty priestly garments.", 
                "num": 70
              }, 
              {
                "text": "Some of the heads of the fathers' houses gave to the treasury of the work twenty thousand gold drachmas, and two thousand two hundred silver minas.", 
                "num": 71
              }, 
              {
                "text": "And that which the rest of the people gave was twenty thousand gold drachmas, two thousand silver minas, and sixty-seven priestly garments.", 
                "num": 72
              }, 
              {
                "text": "So the priests, the Levites, the gatekeepers, the singers, some of the people, the Nethinim, and all Israel dwelt in their cities. When the seventh month came, the children of Israel were in their cities.", 
                "num": 73
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now all the people gathered together as one man in the open square that was in front of the Water Gate; and they told Ezra the scribe to bring the Book of the Law of Moses, which the LORD had commanded Israel.", 
                "num": 1
              }, 
              {
                "text": "So Ezra the priest brought the Law before the assembly of men and women and all who could hear with understanding on the first day of the seventh month.", 
                "num": 2
              }, 
              {
                "text": "Then he read from it in the open square that was in front of the Water Gate from morning until midday, before the men and women and those who could understand; and the ears of all the people were attentive to the Book of the Law.", 
                "num": 3
              }, 
              {
                "text": "So Ezra the scribe stood on a platform of wood which they had made for the purpose; and beside him, at his right hand, stood Mattithiah, Shema, Anaiah, Urijah, Hilkiah, and Maaseiah; and at his left hand Pedaiah, Mishael, Malchijah, Hashum, Hashbadana, Zechariah, and Meshullam.", 
                "num": 4
              }, 
              {
                "text": "And Ezra opened the book in the sight of all the people, for he was standing above all the people; and when he opened it, all the people stood up.", 
                "num": 5
              }, 
              {
                "text": "And Ezra blessed the LORD, the great God. Then all the people answered, \"Amen, Amen!\" while lifting up their hands. And they bowed their heads and worshiped the LORD with their faces to the ground.", 
                "num": 6
              }, 
              {
                "text": "Also Jeshua, Bani, Sherebiah, Jamin, Akkub, Shabbethai, Hodijah, Maaseiah, Kelita, Azariah, Jozabad, Hanan, Pelaiah, and the Levites, helped the people to understand the Law; and the people stood in their place.", 
                "num": 7
              }, 
              {
                "text": "So they read distinctly from the book, in the Law of God; and they gave the sense, and helped them to understand the reading.", 
                "num": 8
              }, 
              {
                "text": "And Nehemiah, who was the governor, Ezra the priest and scribe, and the Levites who taught the people said to all the people, \"This day is holy to the LORD your God; do not mourn nor weep.\" For all the people wept, when they heard the words of the Law.", 
                "num": 9
              }, 
              {
                "text": "Then he said to them, \"Go your way, eat the fat, drink the sweet, and send portions to those for whom nothing is prepared; for this day is holy to our Lord. Do not sorrow, for the joy of the LORD is your strength.\"", 
                "num": 10
              }, 
              {
                "text": "So the Levites quieted all the people, saying, \"Be still, for the day is holy; do not be grieved.\"", 
                "num": 11
              }, 
              {
                "text": "And all the people went their way to eat and drink, to send portions and rejoice greatly, because they understood the words that were declared to them.", 
                "num": 12
              }, 
              {
                "text": "Now on the second day the heads of the fathers' houses of all the people, with the priests and Levites, were gathered to Ezra the scribe, in order to understand the words of the Law.", 
                "num": 13
              }, 
              {
                "text": "And they found written in the Law, which the LORD had commanded by Moses, that the children of Israel should dwell in booths during the feast of the seventh month,", 
                "num": 14
              }, 
              {
                "text": "and that they should announce and proclaim in all their cities and in Jerusalem, saying, \"Go out to the mountain, and bring olive branches, branches of oil trees, myrtle branches, palm branches, and branches of leafy trees, to make booths, as it is written.\"", 
                "num": 15
              }, 
              {
                "text": "Then the people went out and brought them and made themselves booths, each one on the roof of his house, or in their courtyards or the courts of the house of God, and in the open square of the Water Gate and in the open square of the Gate of Ephraim.", 
                "num": 16
              }, 
              {
                "text": "So the whole assembly of those who had returned from the captivity made booths and sat under the booths; for since the days of Joshua the son of Nun until that day the children of Israel had not done so. And there was very great gladness.", 
                "num": 17
              }, 
              {
                "text": "Also day by day, from the first day until the last day, he read from the Book of the Law of God. And they kept the feast seven days; and on the eighth day there was a sacred assembly, according to the prescribed manner.", 
                "num": 18
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now on the twenty-fourth day of this month the children of Israel were assembled with fasting, in sackcloth, and with dust on their heads.", 
                "num": 1
              }, 
              {
                "text": "Then those of Israelite lineage separated themselves from all foreigners; and they stood and confessed their sins and the iniquities of their fathers.", 
                "num": 2
              }, 
              {
                "text": "And they stood up in their place and read from the Book of the Law of the LORD their God for one-fourth of the day; and for another fourth they confessed and worshiped the LORD their God.", 
                "num": 3
              }, 
              {
                "text": "Then Jeshua, Bani, Kadmiel, Shebaniah, Bunni, Sherebiah, Bani, and Chenani stood on the stairs of the Levites and cried out with a loud voice to the LORD their God.", 
                "num": 4
              }, 
              {
                "text": "And the Levites, Jeshua, Kadmiel, Bani, Hashabniah, Sherebiah, Hodijah, Shebaniah, and Pethahiah, said: \"Stand up and bless the LORD your God Forever and ever! \"Blessed be Your glorious name, Which is exalted above all blessing and praise!", 
                "num": 5
              }, 
              {
                "text": "You alone are the LORD; You have made heaven, The heaven of heavens, with all their host, The earth and everything on it, The seas and all that is in them, And You preserve them all. The host of heaven worships You.", 
                "num": 6
              }, 
              {
                "text": "\"You are the LORD God, Who chose Abram, And brought him out of Ur of the Chaldeans, And gave him the name Abraham;", 
                "num": 7
              }, 
              {
                "text": "You found his heart faithful before You, And made a covenant with him To give the land of the Canaanites, The Hittites, the Amorites, The Perizzites, the Jebusites, And the Girgashites-- To give it to his descendants. You have performed Your words, For You are righteous.", 
                "num": 8
              }, 
              {
                "text": "\"You saw the affliction of our fathers in Egypt, And heard their cry by the Red Sea.", 
                "num": 9
              }, 
              {
                "text": "You showed signs and wonders against Pharaoh, Against all his servants, And against all the people of his land. For You knew that they acted proudly against them. So You made a name for Yourself, as it is this day.", 
                "num": 10
              }, 
              {
                "text": "And You divided the sea before them, So that they went through the midst of the sea on the dry land; And their persecutors You threw into the deep, As a stone into the mighty waters.", 
                "num": 11
              }, 
              {
                "text": "Moreover You led them by day with a cloudy pillar, And by night with a pillar of fire, To give them light on the road Which they should travel.", 
                "num": 12
              }, 
              {
                "text": "\"You came down also on Mount Sinai, And spoke with them from heaven, And gave them just ordinances and true laws, Good statutes and commandments.", 
                "num": 13
              }, 
              {
                "text": "You made known to them Your holy Sabbath, And commanded them precepts, statutes and laws, By the hand of Moses Your servant.", 
                "num": 14
              }, 
              {
                "text": "You gave them bread from heaven for their hunger, And brought them water out of the rock for their thirst, And told them to go in to possess the land Which You had sworn to give them.", 
                "num": 15
              }, 
              {
                "text": "\"But they and our fathers acted proudly, Hardened their necks, And did not heed Your commandments.", 
                "num": 16
              }, 
              {
                "text": "They refused to obey, And they were not mindful of Your wonders That You did among them. But they hardened their necks, And in their rebellion They appointed a leader To return to their bondage. But You are God, Ready to pardon, Gracious and merciful, Slow to anger, Abundant in kindness, And did not forsake them.", 
                "num": 17
              }, 
              {
                "text": "\"Even when they made a molded calf for themselves, And said, \"This is your god That brought you up out of Egypt,' And worked great provocations,", 
                "num": 18
              }, 
              {
                "text": "Yet in Your manifold mercies You did not forsake them in the wilderness. The pillar of the cloud did not depart from them by day, To lead them on the road; Nor the pillar of fire by night, To show them light, And the way they should go.", 
                "num": 19
              }, 
              {
                "text": "You also gave Your good Spirit to instruct them, And did not withhold Your manna from their mouth, And gave them water for their thirst.", 
                "num": 20
              }, 
              {
                "text": "Forty years You sustained them in the wilderness; They lacked nothing; Their clothes did not wear out And their feet did not swell.", 
                "num": 21
              }, 
              {
                "text": "\"Moreover You gave them kingdoms and nations, And divided them into districts. So they took possession of the land of Sihon, The land of the king of Heshbon, And the land of Og king of Bashan.", 
                "num": 22
              }, 
              {
                "text": "You also multiplied their children as the stars of heaven, And brought them into the land Which You had told their fathers To go in and possess.", 
                "num": 23
              }, 
              {
                "text": "So the people went in And possessed the land; You subdued before them the inhabitants of the land, The Canaanites, And gave them into their hands, With their kings And the people of the land, That they might do with them as they wished.", 
                "num": 24
              }, 
              {
                "text": "And they took strong cities and a rich land, And possessed houses full of all goods, Cisterns already dug, vineyards, olive groves, And fruit trees in abundance. So they ate and were filled and grew fat, And delighted themselves in Your great goodness.", 
                "num": 25
              }, 
              {
                "text": "\"Nevertheless they were disobedient And rebelled against You, Cast Your law behind their backs And killed Your prophets, who testified against them To turn them to Yourself; And they worked great provocations.", 
                "num": 26
              }, 
              {
                "text": "Therefore You delivered them into the hand of their enemies, Who oppressed them; And in the time of their trouble, When they cried to You, You heard from heaven; And according to Your abundant mercies You gave them deliverers who saved them From the hand of their enemies.", 
                "num": 27
              }, 
              {
                "text": "\"But after they had rest, They again did evil before You. Therefore You left them in the hand of their enemies, So that they had dominion over them; Yet when they returned and cried out to You, You heard from heaven; And many times You delivered them according to Your mercies,", 
                "num": 28
              }, 
              {
                "text": "And testified against them, That You might bring them back to Your law. Yet they acted proudly, And did not heed Your commandments, But sinned against Your judgments, \"Which if a man does, he shall live by them.' And they shrugged their shoulders, Stiffened their necks, And would not hear.", 
                "num": 29
              }, 
              {
                "text": "Yet for many years You had patience with them, And testified against them by Your Spirit in Your prophets. Yet they would not listen; Therefore You gave them into the hand of the peoples of the lands.", 
                "num": 30
              }, 
              {
                "text": "Nevertheless in Your great mercy You did not utterly consume them nor forsake them; For You are God, gracious and merciful.", 
                "num": 31
              }, 
              {
                "text": "\"Now therefore, our God, The great, the mighty, and awesome God, Who keeps covenant and mercy: Do not let all the trouble seem small before You That has come upon us, Our kings and our princes, Our priests and our prophets, Our fathers and on all Your people, From the days of the kings of Assyria until this day.", 
                "num": 32
              }, 
              {
                "text": "However You are just in all that has befallen us; For You have dealt faithfully, But we have done wickedly.", 
                "num": 33
              }, 
              {
                "text": "Neither our kings nor our princes, Our priests nor our fathers, Have kept Your law, Nor heeded Your commandments and Your testimonies, With which You testified against them.", 
                "num": 34
              }, 
              {
                "text": "For they have not served You in their kingdom, Or in the many good things that You gave them, Or in the large and rich land which You set before them; Nor did they turn from their wicked works.", 
                "num": 35
              }, 
              {
                "text": "\"Here we are, servants today! And the land that You gave to our fathers, To eat its fruit and its bounty, Here we are, servants in it!", 
                "num": 36
              }, 
              {
                "text": "And it yields much increase to the kings You have set over us, Because of our sins; Also they have dominion over our bodies and our cattle At their pleasure; And we are in great distress.", 
                "num": 37
              }, 
              {
                "text": "\"And because of all this, We make a sure covenant and write it; Our leaders, our Levites, and our priests seal it.\"", 
                "num": 38
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now those who placed their seal on the document were: Nehemiah the governor, the son of Hacaliah, and Zedekiah,", 
                "num": 1
              }, 
              {
                "text": "Seraiah, Azariah, Jeremiah,", 
                "num": 2
              }, 
              {
                "text": "Pashhur, Amariah, Malchijah,", 
                "num": 3
              }, 
              {
                "text": "Hattush, Shebaniah, Malluch,", 
                "num": 4
              }, 
              {
                "text": "Harim, Meremoth, Obadiah,", 
                "num": 5
              }, 
              {
                "text": "Daniel, Ginnethon, Baruch,", 
                "num": 6
              }, 
              {
                "text": "Meshullam, Abijah, Mijamin,", 
                "num": 7
              }, 
              {
                "text": "Maaziah, Bilgai, and Shemaiah. These were the priests.", 
                "num": 8
              }, 
              {
                "text": "The Levites: Jeshua the son of Azaniah, Binnui of the sons of Henadad, and Kadmiel.", 
                "num": 9
              }, 
              {
                "text": "Their brethren: Shebaniah, Hodijah, Kelita, Pelaiah, Hanan,", 
                "num": 10
              }, 
              {
                "text": "Micha, Rehob, Hashabiah,", 
                "num": 11
              }, 
              {
                "text": "Zaccur, Sherebiah, Shebaniah,", 
                "num": 12
              }, 
              {
                "text": "Hodijah, Bani, and Beninu.", 
                "num": 13
              }, 
              {
                "text": "The leaders of the people: Parosh, Pahath-Moab, Elam, Zattu, Bani,", 
                "num": 14
              }, 
              {
                "text": "Bunni, Azgad, Bebai,", 
                "num": 15
              }, 
              {
                "text": "Adonijah, Bigvai, Adin,", 
                "num": 16
              }, 
              {
                "text": "Ater, Hezekiah, Azzur,", 
                "num": 17
              }, 
              {
                "text": "Hodijah, Hashum, Bezai,", 
                "num": 18
              }, 
              {
                "text": "Hariph, Anathoth, Nebai,", 
                "num": 19
              }, 
              {
                "text": "Magpiash, Meshullam, Hezir,", 
                "num": 20
              }, 
              {
                "text": "Meshezabel, Zadok, Jaddua,", 
                "num": 21
              }, 
              {
                "text": "Pelatiah, Hanan, Anaiah,", 
                "num": 22
              }, 
              {
                "text": "Hoshea, Hananiah, Hasshub,", 
                "num": 23
              }, 
              {
                "text": "Hallohesh, Pilha, Shobek,", 
                "num": 24
              }, 
              {
                "text": "Rehum, Hashabnah, Maaseiah,", 
                "num": 25
              }, 
              {
                "text": "Ahijah, Hanan, Anan,", 
                "num": 26
              }, 
              {
                "text": "Malluch, Harim, and Baanah.", 
                "num": 27
              }, 
              {
                "text": "Now the rest of the people--the priests, the Levites, the gatekeepers, the singers, the Nethinim, and all those who had separated themselves from the peoples of the lands to the Law of God, their wives, their sons, and their daughters, everyone who had knowledge and understanding--", 
                "num": 28
              }, 
              {
                "text": "these joined with their brethren, their nobles, and entered into a curse and an oath to walk in God's Law, which was given by Moses the servant of God, and to observe and do all the commandments of the LORD our Lord, and His ordinances and His statutes:", 
                "num": 29
              }, 
              {
                "text": "We would not give our daughters as wives to the peoples of the land, nor take their daughters for our sons;", 
                "num": 30
              }, 
              {
                "text": "if the peoples of the land brought wares or any grain to sell on the Sabbath day, we would not buy it from them on the Sabbath, or on a holy day; and we would forego the seventh year's produce and the exacting of every debt.", 
                "num": 31
              }, 
              {
                "text": "Also we made ordinances for ourselves, to exact from ourselves yearly one-third of a shekel for the service of the house of our God:", 
                "num": 32
              }, 
              {
                "text": "for the showbread, for the regular grain offering, for the regular burnt offering of the Sabbaths, the New Moons, and the set feasts; for the holy things, for the sin offerings to make atonement for Israel, and all the work of the house of our God.", 
                "num": 33
              }, 
              {
                "text": "We cast lots among the priests, the Levites, and the people, for bringing the wood offering into the house of our God, according to our fathers' houses, at the appointed times year by year, to burn on the altar of the LORD our God as it is written in the Law.", 
                "num": 34
              }, 
              {
                "text": "And we made ordinances to bring the firstfruits of our ground and the firstfruits of all fruit of all trees, year by year, to the house of the LORD;", 
                "num": 35
              }, 
              {
                "text": "to bring the firstborn of our sons and our cattle, as it is written in the Law, and the firstborn of our herds and our flocks, to the house of our God, to the priests who minister in the house of our God;", 
                "num": 36
              }, 
              {
                "text": "to bring the firstfruits of our dough, our offerings, the fruit from all kinds of trees, the new wine and oil, to the priests, to the storerooms of the house of our God; and to bring the tithes of our land to the Levites, for the Levites should receive the tithes in all our farming communities.", 
                "num": 37
              }, 
              {
                "text": "And the priest, the descendant of Aaron, shall be with the Levites when the Levites receive tithes; and the Levites shall bring up a tenth of the tithes to the house of our God, to the rooms of the storehouse.", 
                "num": 38
              }, 
              {
                "text": "For the children of Israel and the children of Levi shall bring the offering of the grain, of the new wine and the oil, to the storerooms where the articles of the sanctuary are, where the priests who minister and the gatekeepers and the singers are; and we will not neglect the house of our God.", 
                "num": 39
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now the leaders of the people dwelt at Jerusalem; the rest of the people cast lots to bring one out of ten to dwell in Jerusalem, the holy city, and nine-tenths were to dwell in other cities.", 
                "num": 1
              }, 
              {
                "text": "And the people blessed all the men who willingly offered themselves to dwell at Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "These are the heads of the province who dwelt in Jerusalem. (But in the cities of Judah everyone dwelt in his own possession in their cities--Israelites, priests, Levites, Nethinim, and descendants of Solomon's servants.)", 
                "num": 3
              }, 
              {
                "text": "Also in Jerusalem dwelt some of the children of Judah and of the children of Benjamin. The children of Judah: Athaiah the son of Uzziah, the son of Zechariah, the son of Amariah, the son of Shephatiah, the son of Mahalalel, of the children of Perez;", 
                "num": 4
              }, 
              {
                "text": "and Maaseiah the son of Baruch, the son of Col-Hozeh, the son of Hazaiah, the son of Adaiah, the son of Joiarib, the son of Zechariah, the son of Shiloni.", 
                "num": 5
              }, 
              {
                "text": "All the sons of Perez who dwelt at Jerusalem were four hundred and sixty-eight valiant men.", 
                "num": 6
              }, 
              {
                "text": "And these are the sons of Benjamin: Sallu the son of Meshullam, the son of Joed, the son of Pedaiah, the son of Kolaiah, the son of Maaseiah, the son of Ithiel, the son of Jeshaiah;", 
                "num": 7
              }, 
              {
                "text": "and after him Gabbai and Sallai, nine hundred and twenty-eight.", 
                "num": 8
              }, 
              {
                "text": "Joel the son of Zichri was their overseer, and Judah the son of Senuah was second over the city.", 
                "num": 9
              }, 
              {
                "text": "Of the priests: Jedaiah the son of Joiarib, and Jachin;", 
                "num": 10
              }, 
              {
                "text": "Seraiah the son of Hilkiah, the son of Meshullam, the son of Zadok, the son of Meraioth, the son of Ahitub, was the leader of the house of God.", 
                "num": 11
              }, 
              {
                "text": "Their brethren who did the work of the house were eight hundred and twenty-two; and Adaiah the son of Jeroham, the son of Pelaliah, the son of Amzi, the son of Zechariah, the son of Pashhur, the son of Malchijah,", 
                "num": 12
              }, 
              {
                "text": "and his brethren, heads of the fathers' houses, were two hundred and forty-two; and Amashai the son of Azarel, the son of Ahzai, the son of Meshillemoth, the son of Immer,", 
                "num": 13
              }, 
              {
                "text": "and their brethren, mighty men of valor, were one hundred and twenty-eight. Their overseer was Zabdiel the son of one of the great men.", 
                "num": 14
              }, 
              {
                "text": "Also of the Levites: Shemaiah the son of Hasshub, the son of Azrikam, the son of Hashabiah, the son of Bunni;", 
                "num": 15
              }, 
              {
                "text": "Shabbethai and Jozabad, of the heads of the Levites, had the oversight of the business outside of the house of God;", 
                "num": 16
              }, 
              {
                "text": "Mattaniah the son of Micha, the son of Zabdi, the son of Asaph, the leader who began the thanksgiving with prayer; Bakbukiah, the second among his brethren; and Abda the son of Shammua, the son of Galal, the son of Jeduthun.", 
                "num": 17
              }, 
              {
                "text": "All the Levites in the holy city were two hundred and eighty-four.", 
                "num": 18
              }, 
              {
                "text": "Moreover the gatekeepers, Akkub, Talmon, and their brethren who kept the gates, were one hundred and seventy-two.", 
                "num": 19
              }, 
              {
                "text": "And the rest of Israel, of the priests and Levites, were in all the cities of Judah, everyone in his inheritance.", 
                "num": 20
              }, 
              {
                "text": "But the Nethinim dwelt in Ophel. And Ziha and Gishpa were over the Nethinim.", 
                "num": 21
              }, 
              {
                "text": "Also the overseer of the Levites at Jerusalem was Uzzi the son of Bani, the son of Hashabiah, the son of Mattaniah, the son of Micha, of the sons of Asaph, the singers in charge of the service of the house of God.", 
                "num": 22
              }, 
              {
                "text": "For it was the king's command concerning them that a certain portion should be for the singers, a quota day by day.", 
                "num": 23
              }, 
              {
                "text": "Pethahiah the son of Meshezabel, of the children of Zerah the son of Judah, was the king's deputy in all matters concerning the people.", 
                "num": 24
              }, 
              {
                "text": "And as for the villages with their fields, some of the children of Judah dwelt in Kirjath Arba and its villages, Dibon and its villages, Jekabzeel and its villages;", 
                "num": 25
              }, 
              {
                "text": "in Jeshua, Moladah, Beth Pelet,", 
                "num": 26
              }, 
              {
                "text": "Hazar Shual, and Beersheba and its villages;", 
                "num": 27
              }, 
              {
                "text": "in Ziklag and Meconah and its villages;", 
                "num": 28
              }, 
              {
                "text": "in En Rimmon, Zorah, Jarmuth,", 
                "num": 29
              }, 
              {
                "text": "Zanoah, Adullam, and their villages; in Lachish and its fields; in Azekah and its villages. They dwelt from Beersheba to the Valley of Hinnom.", 
                "num": 30
              }, 
              {
                "text": "Also the children of Benjamin from Geba dwelt in Michmash, Aija, and Bethel, and their villages;", 
                "num": 31
              }, 
              {
                "text": "in Anathoth, Nob, Ananiah;", 
                "num": 32
              }, 
              {
                "text": "in Hazor, Ramah, Gittaim;", 
                "num": 33
              }, 
              {
                "text": "in Hadid, Zeboim, Neballat;", 
                "num": 34
              }, 
              {
                "text": "in Lod, Ono, and the Valley of Craftsmen.", 
                "num": 35
              }, 
              {
                "text": "Some of the Judean divisions of Levites were in Benjamin.", 
                "num": 36
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now these are the priests and the Levites who came up with Zerubbabel the son of Shealtiel, and Jeshua: Seraiah, Jeremiah, Ezra,", 
                "num": 1
              }, 
              {
                "text": "Amariah, Malluch, Hattush,", 
                "num": 2
              }, 
              {
                "text": "Shechaniah, Rehum, Meremoth,", 
                "num": 3
              }, 
              {
                "text": "Iddo, Ginnethoi, Abijah,", 
                "num": 4
              }, 
              {
                "text": "Mijamin, Maadiah, Bilgah,", 
                "num": 5
              }, 
              {
                "text": "Shemaiah, Joiarib, Jedaiah,", 
                "num": 6
              }, 
              {
                "text": "Sallu, Amok, Hilkiah, and Jedaiah. These were the heads of the priests and their brethren in the days of Jeshua.", 
                "num": 7
              }, 
              {
                "text": "Moreover the Levites were Jeshua, Binnui, Kadmiel, Sherebiah, Judah, and Mattaniah who led the thanksgiving psalms, he and his brethren.", 
                "num": 8
              }, 
              {
                "text": "Also Bakbukiah and Unni, their brethren, stood across from them in their duties.", 
                "num": 9
              }, 
              {
                "text": "Jeshua begot Joiakim, Joiakim begot Eliashib, Eliashib begot Joiada,", 
                "num": 10
              }, 
              {
                "text": "Joiada begot Jonathan, and Jonathan begot Jaddua.", 
                "num": 11
              }, 
              {
                "text": "Now in the days of Joiakim, the priests, the heads of the fathers' houses were: of Seraiah, Meraiah; of Jeremiah, Hananiah;", 
                "num": 12
              }, 
              {
                "text": "of Ezra, Meshullam; of Amariah, Jehohanan;", 
                "num": 13
              }, 
              {
                "text": "of Melichu, Jonathan; of Shebaniah, Joseph;", 
                "num": 14
              }, 
              {
                "text": "of Harim, Adna; of Meraioth, Helkai;", 
                "num": 15
              }, 
              {
                "text": "of Iddo, Zechariah; of Ginnethon, Meshullam;", 
                "num": 16
              }, 
              {
                "text": "of Abijah, Zichri; the son of Minjamin; of Moadiah, Piltai;", 
                "num": 17
              }, 
              {
                "text": "of Bilgah, Shammua; of Shemaiah, Jehonathan;", 
                "num": 18
              }, 
              {
                "text": "of Joiarib, Mattenai; of Jedaiah, Uzzi;", 
                "num": 19
              }, 
              {
                "text": "of Sallai, Kallai; of Amok, Eber;", 
                "num": 20
              }, 
              {
                "text": "of Hilkiah, Hashabiah; and of Jedaiah, Nethanel.", 
                "num": 21
              }, 
              {
                "text": "During the reign of Darius the Persian, a record was also kept of the Levites and priests who had been heads of their fathers' houses in the days of Eliashib, Joiada, Johanan, and Jaddua.", 
                "num": 22
              }, 
              {
                "text": "The sons of Levi, the heads of the fathers' houses until the days of Johanan the son of Eliashib, were written in the book of the chronicles.", 
                "num": 23
              }, 
              {
                "text": "And the heads of the Levites were Hashabiah, Sherebiah, and Jeshua the son of Kadmiel, with their brothers across from them, to praise and give thanks, group alternating with group, according to the command of David the man of God.", 
                "num": 24
              }, 
              {
                "text": "Mattaniah, Bakbukiah, Obadiah, Meshullam, Talmon, and Akkub were gatekeepers keeping the watch at the storerooms of the gates.", 
                "num": 25
              }, 
              {
                "text": "These lived in the days of Joiakim the son of Jeshua, the son of Jozadak, and in the days of Nehemiah the governor, and of Ezra the priest, the scribe.", 
                "num": 26
              }, 
              {
                "text": "Now at the dedication of the wall of Jerusalem they sought out the Levites in all their places, to bring them to Jerusalem to celebrate the dedication with gladness, both with thanksgivings and singing, with cymbals and stringed instruments and harps.", 
                "num": 27
              }, 
              {
                "text": "And the sons of the singers gathered together from the countryside around Jerusalem, from the villages of the Netophathites,", 
                "num": 28
              }, 
              {
                "text": "from the house of Gilgal, and from the fields of Geba and Azmaveth; for the singers had built themselves villages all around Jerusalem.", 
                "num": 29
              }, 
              {
                "text": "Then the priests and Levites purified themselves, and purified the people, the gates, and the wall.", 
                "num": 30
              }, 
              {
                "text": "So I brought the leaders of Judah up on the wall, and appointed two large thanksgiving choirs. One went to the right hand on the wall toward the Refuse Gate.", 
                "num": 31
              }, 
              {
                "text": "After them went Hoshaiah and half of the leaders of Judah,", 
                "num": 32
              }, 
              {
                "text": "and Azariah, Ezra, Meshullam,", 
                "num": 33
              }, 
              {
                "text": "Judah, Benjamin, Shemaiah, Jeremiah,", 
                "num": 34
              }, 
              {
                "text": "and some of the priests' sons with trumpets--Zechariah the son of Jonathan, the son of Shemaiah, the son of Mattaniah, the son of Michaiah, the son of Zaccur, the son of Asaph,", 
                "num": 35
              }, 
              {
                "text": "and his brethren, Shemaiah, Azarel, Milalai, Gilalai, Maai, Nethanel, Judah, and Hanani, with the musical instruments of David the man of God. And Ezra the scribe went before them.", 
                "num": 36
              }, 
              {
                "text": "By the Fountain Gate, in front of them, they went up the stairs of the City of David, on the stairway of the wall, beyond the house of David, as far as the Water Gate eastward.", 
                "num": 37
              }, 
              {
                "text": "The other thanksgiving choir went the opposite way, and I was behind them with half of the people on the wall, going past the Tower of the Ovens as far as the Broad Wall,", 
                "num": 38
              }, 
              {
                "text": "and above the Gate of Ephraim, above the Old Gate, above the Fish Gate, the Tower of Hananel, the Tower of the Hundred, as far as the Sheep Gate; and they stopped by the Gate of the Prison.", 
                "num": 39
              }, 
              {
                "text": "So the two thanksgiving choirs stood in the house of God, likewise I and the half of the rulers with me;", 
                "num": 40
              }, 
              {
                "text": "and the priests, Eliakim, Maaseiah, Minjamin, Michaiah, Elioenai, Zechariah, and Hananiah, with trumpets;", 
                "num": 41
              }, 
              {
                "text": "also Maaseiah, Shemaiah, Eleazar, Uzzi, Jehohanan, Malchijah, Elam, and Ezer. The singers sang loudly with Jezrahiah the director.", 
                "num": 42
              }, 
              {
                "text": "Also that day they offered great sacrifices, and rejoiced, for God had made them rejoice with great joy; the women and the children also rejoiced, so that the joy of Jerusalem was heard afar off.", 
                "num": 43
              }, 
              {
                "text": "And at the same time some were appointed over the rooms of the storehouse for the offerings, the firstfruits, and the tithes, to gather into them from the fields of the cities the portions specified by the Law for the priests and Levites; for Judah rejoiced over the priests and Levites who ministered.", 
                "num": 44
              }, 
              {
                "text": "Both the singers and the gatekeepers kept the charge of their God and the charge of the purification, according to the command of David and Solomon his son.", 
                "num": 45
              }, 
              {
                "text": "For in the days of David and Asaph of old there were chiefs of the singers, and songs of praise and thanksgiving to God.", 
                "num": 46
              }, 
              {
                "text": "In the days of Zerubbabel and in the days of Nehemiah all Israel gave the portions for the singers and the gatekeepers, a portion for each day. They also consecrated holy things for the Levites, and the Levites consecrated them for the children of Aaron.", 
                "num": 47
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "On that day they read from the Book of Moses in the hearing of the people, and in it was found written that no Ammonite or Moabite should ever come into the assembly of God,", 
                "num": 1
              }, 
              {
                "text": "because they had not met the children of Israel with bread and water, but hired Balaam against them to curse them. However, our God turned the curse into a blessing.", 
                "num": 2
              }, 
              {
                "text": "So it was, when they had heard the Law, that they separated all the mixed multitude from Israel.", 
                "num": 3
              }, 
              {
                "text": "Now before this, Eliashib the priest, having authority over the storerooms of the house of our God, was allied with Tobiah.", 
                "num": 4
              }, 
              {
                "text": "And he had prepared for him a large room, where previously they had stored the grain offerings, the frankincense, the articles, the tithes of grain, the new wine and oil, which were commanded to be given to the Levites and singers and gatekeepers, and the offerings for the priests.", 
                "num": 5
              }, 
              {
                "text": "But during all this I was not in Jerusalem, for in the thirty-second year of Artaxerxes king of Babylon I had returned to the king. Then after certain days I obtained leave from the king,", 
                "num": 6
              }, 
              {
                "text": "and I came to Jerusalem and discovered the evil that Eliashib had done for Tobiah, in preparing a room for him in the courts of the house of God.", 
                "num": 7
              }, 
              {
                "text": "And it grieved me bitterly; therefore I threw all the household goods of Tobiah out of the room.", 
                "num": 8
              }, 
              {
                "text": "Then I commanded them to cleanse the rooms; and I brought back into them the articles of the house of God, with the grain offering and the frankincense.", 
                "num": 9
              }, 
              {
                "text": "I also realized that the portions for the Levites had not been given them; for each of the Levites and the singers who did the work had gone back to his field.", 
                "num": 10
              }, 
              {
                "text": "So I contended with the rulers, and said, \"Why is the house of God forsaken?\" And I gathered them together and set them in their place.", 
                "num": 11
              }, 
              {
                "text": "Then all Judah brought the tithe of the grain and the new wine and the oil to the storehouse.", 
                "num": 12
              }, 
              {
                "text": "And I appointed as treasurers over the storehouse Shelemiah the priest and Zadok the scribe, and of the Levites, Pedaiah; and next to them was Hanan the son of Zaccur, the son of Mattaniah; for they were considered faithful, and their task was to distribute to their brethren.", 
                "num": 13
              }, 
              {
                "text": "Remember me, O my God, concerning this, and do not wipe out my good deeds that I have done for the house of my God, and for its services!", 
                "num": 14
              }, 
              {
                "text": "In those days I saw people in Judah treading wine presses on the Sabbath, and bringing in sheaves, and loading donkeys with wine, grapes, figs, and all kinds of burdens, which they brought into Jerusalem on the Sabbath day. And I warned them about the day on which they were selling provisions.", 
                "num": 15
              }, 
              {
                "text": "Men of Tyre dwelt there also, who brought in fish and all kinds of goods, and sold them on the Sabbath to the children of Judah, and in Jerusalem.", 
                "num": 16
              }, 
              {
                "text": "Then I contended with the nobles of Judah, and said to them, \"What evil thing is this that you do, by which you profane the Sabbath day?", 
                "num": 17
              }, 
              {
                "text": "Did not your fathers do thus, and did not our God bring all this disaster on us and on this city? Yet you bring added wrath on Israel by profaning the Sabbath.\"", 
                "num": 18
              }, 
              {
                "text": "So it was, at the gates of Jerusalem, as it began to be dark before the Sabbath, that I commanded the gates to be shut, and charged that they must not be opened till after the Sabbath. Then I posted some of my servants at the gates, so that no burdens would be brought in on the Sabbath day.", 
                "num": 19
              }, 
              {
                "text": "Now the merchants and sellers of all kinds of wares lodged outside Jerusalem once or twice.", 
                "num": 20
              }, 
              {
                "text": "Then I warned them, and said to them, \"Why do you spend the night around the wall? If you do so again, I will lay hands on you!\" From that time on they came no more on the Sabbath.", 
                "num": 21
              }, 
              {
                "text": "And I commanded the Levites that they should cleanse themselves, and that they should go and guard the gates, to sanctify the Sabbath day. Remember me, O my God, concerning this also, and spare me according to the greatness of Your mercy!", 
                "num": 22
              }, 
              {
                "text": "In those days I also saw Jews who had married women of Ashdod, Ammon, and Moab.", 
                "num": 23
              }, 
              {
                "text": "And half of their children spoke the language of Ashdod, and could not speak the language of Judah, but spoke according to the language of one or the other people.", 
                "num": 24
              }, 
              {
                "text": "So I contended with them and cursed them, struck some of them and pulled out their hair, and made them swear by God, saying, \"You shall not give your daughters as wives to their sons, nor take their daughters for your sons or yourselves.", 
                "num": 25
              }, 
              {
                "text": "Did not Solomon king of Israel sin by these things? Yet among many nations there was no king like him, who was beloved of his God; and God made him king over all Israel. Nevertheless pagan women caused even him to sin.", 
                "num": 26
              }, 
              {
                "text": "Should we then hear of your doing all this great evil, transgressing against our God by marrying pagan women?\"", 
                "num": 27
              }, 
              {
                "text": "And one of the sons of Joiada, the son of Eliashib the high priest, was a son-in-law of Sanballat the Horonite; therefore I drove him from me.", 
                "num": 28
              }, 
              {
                "text": "Remember them, O my God, because they have defiled the priesthood and the covenant of the priesthood and the Levites.", 
                "num": 29
              }, 
              {
                "text": "Thus I cleansed them of everything pagan. I also assigned duties to the priests and the Levites, each to his service,", 
                "num": 30
              }, 
              {
                "text": "and to bringing the wood offering and the firstfruits at appointed times. Remember me, O my God, for good!", 
                "num": 31
              }
            ], 
            "num": 13
          }
        ]
      }, 
      {
        "name": "Esther", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now it came to pass in the days of Ahasuerus (this was the Ahasuerus who reigned over one hundred and twenty-seven provinces, from India to Ethiopia),", 
                "num": 1
              }, 
              {
                "text": "in those days when King Ahasuerus sat on the throne of his kingdom, which was in Shushan the citadel,", 
                "num": 2
              }, 
              {
                "text": "that in the third year of his reign he made a feast for all his officials and servants--the powers of Persia and Media, the nobles, and the princes of the provinces being before him--", 
                "num": 3
              }, 
              {
                "text": "when he showed the riches of his glorious kingdom and the splendor of his excellent majesty for many days, one hundred and eighty days in all.", 
                "num": 4
              }, 
              {
                "text": "And when these days were completed, the king made a feast lasting seven days for all the people who were present in Shushan the citadel, from great to small, in the court of the garden of the king's palace.", 
                "num": 5
              }, 
              {
                "text": "There were white and blue linen curtains fastened with cords of fine linen and purple on silver rods and marble pillars; and the couches were of gold and silver on a mosaic pavement of alabaster, turquoise, and white and black marble.", 
                "num": 6
              }, 
              {
                "text": "And they served drinks in golden vessels, each vessel being different from the other, with royal wine in abundance, according to the generosity of the king.", 
                "num": 7
              }, 
              {
                "text": "In accordance with the law, the drinking was not compulsory; for so the king had ordered all the officers of his household, that they should do according to each man's pleasure.", 
                "num": 8
              }, 
              {
                "text": "Queen Vashti also made a feast for the women in the royal palace which belonged to King Ahasuerus.", 
                "num": 9
              }, 
              {
                "text": "On the seventh day, when the heart of the king was merry with wine, he commanded Mehuman, Biztha, Harbona, Bigtha, Abagtha, Zethar, and Carcas, seven eunuchs who served in the presence of King Ahasuerus,", 
                "num": 10
              }, 
              {
                "text": "to bring Queen Vashti before the king, wearing her royal crown, in order to show her beauty to the people and the officials, for she was beautiful to behold.", 
                "num": 11
              }, 
              {
                "text": "But Queen Vashti refused to come at the king's command brought by his eunuchs; therefore the king was furious, and his anger burned within him.", 
                "num": 12
              }, 
              {
                "text": "Then the king said to the wise men who understood the times (for this was the king's manner toward all who knew law and justice,", 
                "num": 13
              }, 
              {
                "text": "those closest to him being Carshena, Shethar, Admatha, Tarshish, Meres, Marsena, and Memucan, the seven princes of Persia and Media, who had access to the king's presence, and who ranked highest in the kingdom):", 
                "num": 14
              }, 
              {
                "text": "\"What shall we do to Queen Vashti, according to law, because she did not obey the command of King Ahasuerus brought to her by the eunuchs?\"", 
                "num": 15
              }, 
              {
                "text": "And Memucan answered before the king and the princes: \"Queen Vashti has not only wronged the king, but also all the princes, and all the people who are in all the provinces of King Ahasuerus.", 
                "num": 16
              }, 
              {
                "text": "For the queen's behavior will become known to all women, so that they will despise their husbands in their eyes, when they report, \"King Ahasuerus commanded Queen Vashti to be brought in before him, but she did not come.'", 
                "num": 17
              }, 
              {
                "text": "This very day the noble ladies of Persia and Media will say to all the king's officials that they have heard of the behavior of the queen. Thus there will be excessive contempt and wrath.", 
                "num": 18
              }, 
              {
                "text": "If it pleases the king, let a royal decree go out from him, and let it be recorded in the laws of the Persians and the Medes, so that it will not be altered, that Vashti shall come no more before King Ahasuerus; and let the king give her royal position to another who is better than she.", 
                "num": 19
              }, 
              {
                "text": "When the king's decree which he will make is proclaimed throughout all his empire (for it is great), all wives will honor their husbands, both great and small.\"", 
                "num": 20
              }, 
              {
                "text": "And the reply pleased the king and the princes, and the king did according to the word of Memucan.", 
                "num": 21
              }, 
              {
                "text": "Then he sent letters to all the king's provinces, to each province in its own script, and to every people in their own language, that each man should be master in his own house, and speak in the language of his own people.", 
                "num": 22
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "After these things, when the wrath of King Ahasuerus subsided, he remembered Vashti, what she had done, and what had been decreed against her.", 
                "num": 1
              }, 
              {
                "text": "Then the king's servants who attended him said: \"Let beautiful young virgins be sought for the king;", 
                "num": 2
              }, 
              {
                "text": "and let the king appoint officers in all the provinces of his kingdom, that they may gather all the beautiful young virgins to Shushan the citadel, into the women's quarters, under the custody of Hegai the king's eunuch, custodian of the women. And let beauty preparations be given them.", 
                "num": 3
              }, 
              {
                "text": "Then let the young woman who pleases the king be queen instead of Vashti.\" This thing pleased the king, and he did so.", 
                "num": 4
              }, 
              {
                "text": "In Shushan the citadel there was a certain Jew whose name was Mordecai the son of Jair, the son of Shimei, the son of Kish, a Benjamite.", 
                "num": 5
              }, 
              {
                "text": "Kish had been carried away from Jerusalem with the captives who had been captured with Jeconiah king of Judah, whom Nebuchadnezzar the king of Babylon had carried away.", 
                "num": 6
              }, 
              {
                "text": "And Mordecai had brought up Hadassah, that is, Esther, his uncle's daughter, for she had neither father nor mother. The young woman was lovely and beautiful. When her father and mother died, Mordecai took her as his own daughter.", 
                "num": 7
              }, 
              {
                "text": "So it was, when the king's command and decree were heard, and when many young women were gathered at Shushan the citadel, under the custody of Hegai, that Esther also was taken to the king's palace, into the care of Hegai the custodian of the women.", 
                "num": 8
              }, 
              {
                "text": "Now the young woman pleased him, and she obtained his favor; so he readily gave beauty preparations to her, besides her allowance. Then seven choice maidservants were provided for her from the king's palace, and he moved her and her maidservants to the best place in the house of the women.", 
                "num": 9
              }, 
              {
                "text": "Esther had not revealed her people or family, for Mordecai had charged her not to reveal it.", 
                "num": 10
              }, 
              {
                "text": "And every day Mordecai paced in front of the court of the women's quarters, to learn of Esther's welfare and what was happening to her.", 
                "num": 11
              }, 
              {
                "text": "Each young woman's turn came to go in to King Ahasuerus after she had completed twelve months' preparation, according to the regulations for the women, for thus were the days of their preparation apportioned: six months with oil of myrrh, and six months with perfumes and preparations for beautifying women.", 
                "num": 12
              }, 
              {
                "text": "Thus prepared, each young woman went to the king, and she was given whatever she desired to take with her from the women's quarters to the king's palace.", 
                "num": 13
              }, 
              {
                "text": "In the evening she went, and in the morning she returned to the second house of the women, to the custody of Shaashgaz, the king's eunuch who kept the concubines. She would not go in to the king again unless the king delighted in her and called for her by name.", 
                "num": 14
              }, 
              {
                "text": "Now when the turn came for Esther the daughter of Abihail the uncle of Mordecai, who had taken her as his daughter, to go in to the king, she requested nothing but what Hegai the king's eunuch, the custodian of the women, advised. And Esther obtained favor in the sight of all who saw her.", 
                "num": 15
              }, 
              {
                "text": "So Esther was taken to King Ahasuerus, into his royal palace, in the tenth month, which is the month of Tebeth, in the seventh year of his reign.", 
                "num": 16
              }, 
              {
                "text": "The king loved Esther more than all the other women, and she obtained grace and favor in his sight more than all the virgins; so he set the royal crown upon her head and made her queen instead of Vashti.", 
                "num": 17
              }, 
              {
                "text": "Then the king made a great feast, the Feast of Esther, for all his officials and servants; and he proclaimed a holiday in the provinces and gave gifts according to the generosity of a king.", 
                "num": 18
              }, 
              {
                "text": "When virgins were gathered together a second time, Mordecai sat within the king's gate.", 
                "num": 19
              }, 
              {
                "text": "Now Esther had not revealed her family and her people, just as Mordecai had charged her, for Esther obeyed the command of Mordecai as when she was brought up by him.", 
                "num": 20
              }, 
              {
                "text": "In those days, while Mordecai sat within the king's gate, two of the king's eunuchs, Bigthan and Teresh, doorkeepers, became furious and sought to lay hands on King Ahasuerus.", 
                "num": 21
              }, 
              {
                "text": "So the matter became known to Mordecai, who told Queen Esther, and Esther informed the king in Mordecai's name.", 
                "num": 22
              }, 
              {
                "text": "And when an inquiry was made into the matter, it was confirmed, and both were hanged on a gallows; and it was written in the book of the chronicles in the presence of the king.", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "After these things King Ahasuerus promoted Haman, the son of Hammedatha the Agagite, and advanced him and set his seat above all the princes who were with him.", 
                "num": 1
              }, 
              {
                "text": "And all the king's servants who were within the king's gate bowed and paid homage to Haman, for so the king had commanded concerning him. But Mordecai would not bow or pay homage.", 
                "num": 2
              }, 
              {
                "text": "Then the king's servants who were within the king's gate said to Mordecai, \"Why do you transgress the king's command?\"", 
                "num": 3
              }, 
              {
                "text": "Now it happened, when they spoke to him daily and he would not listen to them, that they told it to Haman, to see whether Mordecai's words would stand; for Mordecai had told them that he was a Jew.", 
                "num": 4
              }, 
              {
                "text": "When Haman saw that Mordecai did not bow or pay him homage, Haman was filled with wrath.", 
                "num": 5
              }, 
              {
                "text": "But he disdained to lay hands on Mordecai alone, for they had told him of the people of Mordecai. Instead, Haman sought to destroy all the Jews who were throughout the whole kingdom of Ahasuerus--the people of Mordecai.", 
                "num": 6
              }, 
              {
                "text": "In the first month, which is the month of Nisan, in the twelfth year of King Ahasuerus, they cast Pur (that is, the lot), before Haman to determine the day and the month, until it fell on the twelfth month, which is the month of Adar.", 
                "num": 7
              }, 
              {
                "text": "Then Haman said to King Ahasuerus, \"There is a certain people scattered and dispersed among the people in all the provinces of your kingdom; their laws are different from all other people's, and they do not keep the king's laws. Therefore it is not fitting for the king to let them remain.", 
                "num": 8
              }, 
              {
                "text": "If it pleases the king, let a decree be written that they be destroyed, and I will pay ten thousand talents of silver into the hands of those who do the work, to bring it into the king's treasuries.\"", 
                "num": 9
              }, 
              {
                "text": "So the king took his signet ring from his hand and gave it to Haman, the son of Hammedatha the Agagite, the enemy of the Jews.", 
                "num": 10
              }, 
              {
                "text": "And the king said to Haman, \"The money and the people are given to you, to do with them as seems good to you.\"", 
                "num": 11
              }, 
              {
                "text": "Then the king's scribes were called on the thirteenth day of the first month, and a decree was written according to all that Haman commanded--to the king's satraps, to the governors who were over each province, to the officials of all people, to every province according to its script, and to every people in their language. In the name of King Ahasuerus it was written, and sealed with the king's signet ring.", 
                "num": 12
              }, 
              {
                "text": "And the letters were sent by couriers into all the king's provinces, to destroy, to kill, and to annihilate all the Jews, both young and old, little children and women, in one day, on the thirteenth day of the twelfth month, which is the month of Adar, and to plunder their possessions.", 
                "num": 13
              }, 
              {
                "text": "A copy of the document was to be issued as law in every province, being published for all people, that they should be ready for that day.", 
                "num": 14
              }, 
              {
                "text": "The couriers went out, hastened by the king's command; and the decree was proclaimed in Shushan the citadel. So the king and Haman sat down to drink, but the city of Shushan was perplexed.", 
                "num": 15
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "When Mordecai learned all that had happened, he tore his clothes and put on sackcloth and ashes, and went out into the midst of the city. He cried out with a loud and bitter cry.", 
                "num": 1
              }, 
              {
                "text": "He went as far as the front of the king's gate, for no one might enter the king's gate clothed with sackcloth.", 
                "num": 2
              }, 
              {
                "text": "And in every province where the king's command and decree arrived, there was great mourning among the Jews, with fasting, weeping, and wailing; and many lay in sackcloth and ashes.", 
                "num": 3
              }, 
              {
                "text": "So Esther's maids and eunuchs came and told her, and the queen was deeply distressed. Then she sent garments to clothe Mordecai and take his sackcloth away from him, but he would not accept them.", 
                "num": 4
              }, 
              {
                "text": "Then Esther called Hathach, one of the king's eunuchs whom he had appointed to attend her, and she gave him a command concerning Mordecai, to learn what and why this was.", 
                "num": 5
              }, 
              {
                "text": "So Hathach went out to Mordecai in the city square that was in front of the king's gate.", 
                "num": 6
              }, 
              {
                "text": "And Mordecai told him all that had happened to him, and the sum of money that Haman had promised to pay into the king's treasuries to destroy the Jews.", 
                "num": 7
              }, 
              {
                "text": "He also gave him a copy of the written decree for their destruction, which was given at Shushan, that he might show it to Esther and explain it to her, and that he might command her to go in to the king to make supplication to him and plead before him for her people.", 
                "num": 8
              }, 
              {
                "text": "So Hathach returned and told Esther the words of Mordecai.", 
                "num": 9
              }, 
              {
                "text": "Then Esther spoke to Hathach, and gave him a command for Mordecai:", 
                "num": 10
              }, 
              {
                "text": "\"All the king's servants and the people of the king's provinces know that any man or woman who goes into the inner court to the king, who has not been called, he has but one law: put all to death, except the one to whom the king holds out the golden scepter, that he may live. Yet I myself have not been called to go in to the king these thirty days.\"", 
                "num": 11
              }, 
              {
                "text": "So they told Mordecai Esther's words.", 
                "num": 12
              }, 
              {
                "text": "And Mordecai told them to answer Esther: \"Do not think in your heart that you will escape in the king's palace any more than all the other Jews.", 
                "num": 13
              }, 
              {
                "text": "For if you remain completely silent at this time, relief and deliverance will arise for the Jews from another place, but you and your father's house will perish. Yet who knows whether you have come to the kingdom for such a time as this?\"", 
                "num": 14
              }, 
              {
                "text": "Then Esther told them to reply to Mordecai:", 
                "num": 15
              }, 
              {
                "text": "\"Go, gather all the Jews who are present in Shushan, and fast for me; neither eat nor drink for three days, night or day. My maids and I will fast likewise. And so I will go to the king, which is against the law; and if I perish, I perish!\"", 
                "num": 16
              }, 
              {
                "text": "So Mordecai went his way and did according to all that Esther commanded him.", 
                "num": 17
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now it happened on the third day that Esther put on her royal robes and stood in the inner court of the king's palace, across from the king's house, while the king sat on his royal throne in the royal house, facing the entrance of the house.", 
                "num": 1
              }, 
              {
                "text": "So it was, when the king saw Queen Esther standing in the court, that she found favor in his sight, and the king held out to Esther the golden scepter that was in his hand. Then Esther went near and touched the top of the scepter.", 
                "num": 2
              }, 
              {
                "text": "And the king said to her, \"What do you wish, Queen Esther? What is your request? It shall be given to you--up to half the kingdom!\"", 
                "num": 3
              }, 
              {
                "text": "So Esther answered, \"If it pleases the king, let the king and Haman come today to the banquet that I have prepared for him.\"", 
                "num": 4
              }, 
              {
                "text": "Then the king said, \"Bring Haman quickly, that he may do as Esther has said.\" So the king and Haman went to the banquet that Esther had prepared.", 
                "num": 5
              }, 
              {
                "text": "At the banquet of wine the king said to Esther, \"What is your petition? It shall be granted you. What is your request, up to half the kingdom? It shall be done!\"", 
                "num": 6
              }, 
              {
                "text": "Then Esther answered and said, \"My petition and request is this:", 
                "num": 7
              }, 
              {
                "text": "If I have found favor in the sight of the king, and if it pleases the king to grant my petition and fulfill my request, then let the king and Haman come to the banquet which I will prepare for them, and tomorrow I will do as the king has said.\"", 
                "num": 8
              }, 
              {
                "text": "So Haman went out that day joyful and with a glad heart; but when Haman saw Mordecai in the king's gate, and that he did not stand or tremble before him, he was filled with indignation against Mordecai.", 
                "num": 9
              }, 
              {
                "text": "Nevertheless Haman restrained himself and went home, and he sent and called for his friends and his wife Zeresh.", 
                "num": 10
              }, 
              {
                "text": "Then Haman told them of his great riches, the multitude of his children, everything in which the king had promoted him, and how he had advanced him above the officials and servants of the king.", 
                "num": 11
              }, 
              {
                "text": "Moreover Haman said, \"Besides, Queen Esther invited no one but me to come in with the king to the banquet that she prepared; and tomorrow I am again invited by her, along with the king.", 
                "num": 12
              }, 
              {
                "text": "Yet all this avails me nothing, so long as I see Mordecai the Jew sitting at the king's gate.\"", 
                "num": 13
              }, 
              {
                "text": "Then his wife Zeresh and all his friends said to him, \"Let a gallows be made, fifty cubits high, and in the morning suggest to the king that Mordecai be hanged on it; then go merrily with the king to the banquet.\" And the thing pleased Haman; so he had the gallows made.", 
                "num": 14
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "That night the king could not sleep. So one was commanded to bring the book of the records of the chronicles; and they were read before the king.", 
                "num": 1
              }, 
              {
                "text": "And it was found written that Mordecai had told of Bigthana and Teresh, two of the king's eunuchs, the doorkeepers who had sought to lay hands on King Ahasuerus.", 
                "num": 2
              }, 
              {
                "text": "Then the king said, \"What honor or dignity has been bestowed on Mordecai for this?\" And the king's servants who attended him said, \"Nothing has been done for him.\"", 
                "num": 3
              }, 
              {
                "text": "So the king said, \"Who is in the court?\" Now Haman had just entered the outer court of the king's palace to suggest that the king hang Mordecai on the gallows that he had prepared for him.", 
                "num": 4
              }, 
              {
                "text": "The king's servants said to him, \"Haman is there, standing in the court.\" And the king said, \"Let him come in.\"", 
                "num": 5
              }, 
              {
                "text": "So Haman came in, and the king asked him, \"What shall be done for the man whom the king delights to honor?\" Now Haman thought in his heart, \"Whom would the king delight to honor more than me?\"", 
                "num": 6
              }, 
              {
                "text": "And Haman answered the king, \"For the man whom the king delights to honor,", 
                "num": 7
              }, 
              {
                "text": "let a royal robe be brought which the king has worn, and a horse on which the king has ridden, which has a royal crest placed on its head.", 
                "num": 8
              }, 
              {
                "text": "Then let this robe and horse be delivered to the hand of one of the king's most noble princes, that he may array the man whom the king delights to honor. Then parade him on horseback through the city square, and proclaim before him: \"Thus shall it be done to the man whom the king delights to honor!'|\"", 
                "num": 9
              }, 
              {
                "text": "Then the king said to Haman, \"Hurry, take the robe and the horse, as you have suggested, and do so for Mordecai the Jew who sits within the king's gate! Leave nothing undone of all that you have spoken.\"", 
                "num": 10
              }, 
              {
                "text": "So Haman took the robe and the horse, arrayed Mordecai and led him on horseback through the city square, and proclaimed before him, \"Thus shall it be done to the man whom the king delights to honor!\"", 
                "num": 11
              }, 
              {
                "text": "Afterward Mordecai went back to the king's gate. But Haman hurried to his house, mourning and with his head covered.", 
                "num": 12
              }, 
              {
                "text": "When Haman told his wife Zeresh and all his friends everything that had happened to him, his wise men and his wife Zeresh said to him, \"If Mordecai, before whom you have begun to fall, is of Jewish descent, you will not prevail against him but will surely fall before him.\"", 
                "num": 13
              }, 
              {
                "text": "While they were still talking with him, the king's eunuchs came, and hastened to bring Haman to the banquet which Esther had prepared.", 
                "num": 14
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "So the king and Haman went to dine with Queen Esther.", 
                "num": 1
              }, 
              {
                "text": "And on the second day, at the banquet of wine, the king again said to Esther, \"What is your petition, Queen Esther? It shall be granted you. And what is your request, up to half the kingdom? It shall be done!\"", 
                "num": 2
              }, 
              {
                "text": "Then Queen Esther answered and said, \"If I have found favor in your sight, O king, and if it pleases the king, let my life be given me at my petition, and my people at my request.", 
                "num": 3
              }, 
              {
                "text": "For we have been sold, my people and I, to be destroyed, to be killed, and to be annihilated. Had we been sold as male and female slaves, I would have held my tongue, although the enemy could never compensate for the king's loss.\"", 
                "num": 4
              }, 
              {
                "text": "So King Ahasuerus answered and said to Queen Esther, \"Who is he, and where is he, who would dare presume in his heart to do such a thing?\"", 
                "num": 5
              }, 
              {
                "text": "And Esther said, \"The adversary and enemy is this wicked Haman!\" So Haman was terrified before the king and queen.", 
                "num": 6
              }, 
              {
                "text": "Then the king arose in his wrath from the banquet of wine and went into the palace garden; but Haman stood before Queen Esther, pleading for his life, for he saw that evil was determined against him by the king.", 
                "num": 7
              }, 
              {
                "text": "When the king returned from the palace garden to the place of the banquet of wine, Haman had fallen across the couch where Esther was. Then the king said, \"Will he also assault the queen while I am in the house?\" As the word left the king's mouth, they covered Haman's face.", 
                "num": 8
              }, 
              {
                "text": "Now Harbonah, one of the eunuchs, said to the king, \"Look! The gallows, fifty cubits high, which Haman made for Mordecai, who spoke good on the king's behalf, is standing at the house of Haman.\" Then the king said, \"Hang him on it!\"", 
                "num": 9
              }, 
              {
                "text": "So they hanged Haman on the gallows that he had prepared for Mordecai. Then the king's wrath subsided.", 
                "num": 10
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "On that day King Ahasuerus gave Queen Esther the house of Haman, the enemy of the Jews. And Mordecai came before the king, for Esther had told how he was related to her.", 
                "num": 1
              }, 
              {
                "text": "So the king took off his signet ring, which he had taken from Haman, and gave it to Mordecai; and Esther appointed Mordecai over the house of Haman.", 
                "num": 2
              }, 
              {
                "text": "Now Esther spoke again to the king, fell down at his feet, and implored him with tears to counteract the evil of Haman the Agagite, and the scheme which he had devised against the Jews.", 
                "num": 3
              }, 
              {
                "text": "And the king held out the golden scepter toward Esther. So Esther arose and stood before the king,", 
                "num": 4
              }, 
              {
                "text": "and said, \"If it pleases the king, and if I have found favor in his sight and the thing seems right to the king and I am pleasing in his eyes, let it be written to revoke the letters devised by Haman, the son of Hammedatha the Agagite, which he wrote to annihilate the Jews who are in all the king's provinces.", 
                "num": 5
              }, 
              {
                "text": "For how can I endure to see the evil that will come to my people? Or how can I endure to see the destruction of my countrymen?\"", 
                "num": 6
              }, 
              {
                "text": "Then King Ahasuerus said to Queen Esther and Mordecai the Jew, \"Indeed, I have given Esther the house of Haman, and they have hanged him on the gallows because he tried to lay his hand on the Jews.", 
                "num": 7
              }, 
              {
                "text": "You yourselves write a decree concerning the Jews, as you please, in the king's name, and seal it with the king's signet ring; for whatever is written in the king's name and sealed with the king's signet ring no one can revoke.\"", 
                "num": 8
              }, 
              {
                "text": "So the king's scribes were called at that time, in the third month, which is the month of Sivan, on the twenty-third day; and it was written, according to all that Mordecai commanded, to the Jews, the satraps, the governors, and the princes of the provinces from India to Ethiopia, one hundred and twenty-seven provinces in all, to every province in its own script, to every people in their own language, and to the Jews in their own script and language.", 
                "num": 9
              }, 
              {
                "text": "And he wrote in the name of King Ahasuerus, sealed it with the king's signet ring, and sent letters by couriers on horseback, riding on royal horses bred from swift steeds.", 
                "num": 10
              }, 
              {
                "text": "By these letters the king permitted the Jews who were in every city to gather together and protect their lives--to destroy, kill, and annihilate all the forces of any people or province that would assault them, both little children and women, and to plunder their possessions,", 
                "num": 11
              }, 
              {
                "text": "on one day in all the provinces of King Ahasuerus, on the thirteenth day of the twelfth month, which is the month of Adar.", 
                "num": 12
              }, 
              {
                "text": "A copy of the document was to be issued as a decree in every province and published for all people, so that the Jews would be ready on that day to avenge themselves on their enemies.", 
                "num": 13
              }, 
              {
                "text": "The couriers who rode on royal horses went out, hastened and pressed on by the king's command. And the decree was issued in Shushan the citadel.", 
                "num": 14
              }, 
              {
                "text": "So Mordecai went out from the presence of the king in royal apparel of blue and white, with a great crown of gold and a garment of fine linen and purple; and the city of Shushan rejoiced and was glad.", 
                "num": 15
              }, 
              {
                "text": "The Jews had light and gladness, joy and honor.", 
                "num": 16
              }, 
              {
                "text": "And in every province and city, wherever the king's command and decree came, the Jews had joy and gladness, a feast and a holiday. Then many of the people of the land became Jews, because fear of the Jews fell upon them.", 
                "num": 17
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now in the twelfth month, that is, the month of Adar, on the thirteenth day, the time came for the king's command and his decree to be executed. On the day that the enemies of the Jews had hoped to overpower them, the opposite occurred, in that the Jews themselves overpowered those who hated them.", 
                "num": 1
              }, 
              {
                "text": "The Jews gathered together in their cities throughout all the provinces of King Ahasuerus to lay hands on those who sought their harm. And no one could withstand them, because fear of them fell upon all people.", 
                "num": 2
              }, 
              {
                "text": "And all the officials of the provinces, the satraps, the governors, and all those doing the king's work, helped the Jews, because the fear of Mordecai fell upon them.", 
                "num": 3
              }, 
              {
                "text": "For Mordecai was great in the king's palace, and his fame spread throughout all the provinces; for this man Mordecai became increasingly prominent.", 
                "num": 4
              }, 
              {
                "text": "Thus the Jews defeated all their enemies with the stroke of the sword, with slaughter and destruction, and did what they pleased with those who hated them.", 
                "num": 5
              }, 
              {
                "text": "And in Shushan the citadel the Jews killed and destroyed five hundred men.", 
                "num": 6
              }, 
              {
                "text": "Also Parshandatha, Dalphon, Aspatha,", 
                "num": 7
              }, 
              {
                "text": "Poratha, Adalia, Aridatha,", 
                "num": 8
              }, 
              {
                "text": "Parmashta, Arisai, Aridai, and Vajezatha--", 
                "num": 9
              }, 
              {
                "text": "the ten sons of Haman the son of Hammedatha, the enemy of the Jews--they killed; but they did not lay a hand on the plunder.", 
                "num": 10
              }, 
              {
                "text": "On that day the number of those who were killed in Shushan the citadel was brought to the king.", 
                "num": 11
              }, 
              {
                "text": "And the king said to Queen Esther, \"The Jews have killed and destroyed five hundred men in Shushan the citadel, and the ten sons of Haman. What have they done in the rest of the king's provinces? Now what is your petition? It shall be granted to you. Or what is your further request? It shall be done.\"", 
                "num": 12
              }, 
              {
                "text": "Then Esther said, \"If it pleases the king, let it be granted to the Jews who are in Shushan to do again tomorrow according to today's decree, and let Haman's ten sons be hanged on the gallows.\"", 
                "num": 13
              }, 
              {
                "text": "So the king commanded this to be done; the decree was issued in Shushan, and they hanged Haman's ten sons.", 
                "num": 14
              }, 
              {
                "text": "And the Jews who were in Shushan gathered together again on the fourteenth day of the month of Adar and killed three hundred men at Shushan; but they did not lay a hand on the plunder.", 
                "num": 15
              }, 
              {
                "text": "The remainder of the Jews in the king's provinces gathered together and protected their lives, had rest from their enemies, and killed seventy-five thousand of their enemies; but they did not lay a hand on the plunder.", 
                "num": 16
              }, 
              {
                "text": "This was on the thirteenth day of the month of Adar. And on the fourteenth of the month they rested and made it a day of feasting and gladness.", 
                "num": 17
              }, 
              {
                "text": "But the Jews who were at Shushan assembled together on the thirteenth day, as well as on the fourteenth; and on the fifteenth of the month they rested, and made it a day of feasting and gladness.", 
                "num": 18
              }, 
              {
                "text": "Therefore the Jews of the villages who dwelt in the unwalled towns celebrated the fourteenth day of the month of Adar with gladness and feasting, as a holiday, and for sending presents to one another.", 
                "num": 19
              }, 
              {
                "text": "And Mordecai wrote these things and sent letters to all the Jews, near and far, who were in all the provinces of King Ahasuerus,", 
                "num": 20
              }, 
              {
                "text": "to establish among them that they should celebrate yearly the fourteenth and fifteenth days of the month of Adar,", 
                "num": 21
              }, 
              {
                "text": "as the days on which the Jews had rest from their enemies, as the month which was turned from sorrow to joy for them, and from mourning to a holiday; that they should make them days of feasting and joy, of sending presents to one another and gifts to the poor.", 
                "num": 22
              }, 
              {
                "text": "So the Jews accepted the custom which they had begun, as Mordecai had written to them,", 
                "num": 23
              }, 
              {
                "text": "because Haman, the son of Hammedatha the Agagite, the enemy of all the Jews, had plotted against the Jews to annihilate them, and had cast Pur (that is, the lot), to consume them and destroy them;", 
                "num": 24
              }, 
              {
                "text": "but when Esther came before the king, he commanded by letter that this wicked plot which Haman had devised against the Jews should return on his own head, and that he and his sons should be hanged on the gallows.", 
                "num": 25
              }, 
              {
                "text": "So they called these days Purim, after the name Pur. Therefore, because of all the words of this letter, what they had seen concerning this matter, and what had happened to them,", 
                "num": 26
              }, 
              {
                "text": "the Jews established and imposed it upon themselves and their descendants and all who would join them, that without fail they should celebrate these two days every year, according to the written instructions and according to the prescribed time,", 
                "num": 27
              }, 
              {
                "text": "that these days should be remembered and kept throughout every generation, every family, every province, and every city, that these days of Purim should not fail to be observed among the Jews, and that the memory of them should not perish among their descendants.", 
                "num": 28
              }, 
              {
                "text": "Then Queen Esther, the daughter of Abihail, with Mordecai the Jew, wrote with full authority to confirm this second letter about Purim.", 
                "num": 29
              }, 
              {
                "text": "And Mordecai sent letters to all the Jews, to the one hundred and twenty-seven provinces of the kingdom of Ahasuerus, with words of peace and truth,", 
                "num": 30
              }, 
              {
                "text": "to confirm these days of Purim at their appointed time, as Mordecai the Jew and Queen Esther had prescribed for them, and as they had decreed for themselves and their descendants concerning matters of their fasting and lamenting.", 
                "num": 31
              }, 
              {
                "text": "So the decree of Esther confirmed these matters of Purim, and it was written in the book.", 
                "num": 32
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "And King Ahasuerus imposed tribute on the land and on the islands of the sea.", 
                "num": 1
              }, 
              {
                "text": "Now all the acts of his power and his might, and the account of the greatness of Mordecai, to which the king advanced him, are they not written in the book of the chronicles of the kings of Media and Persia?", 
                "num": 2
              }, 
              {
                "text": "For Mordecai the Jew was second to King Ahasuerus, and was great among the Jews and well received by the multitude of his brethren, seeking the good of his people and speaking peace to all his countrymen.", 
                "num": 3
              }
            ], 
            "num": 10
          }
        ]
      }, 
      {
        "name": "Job", 
        "chapters": [
          {
            "verses": [
              {
                "text": "There was a man in the land of Uz, whose name was Job; and that man was blameless and upright, and one who feared God and shunned evil.", 
                "num": 1
              }, 
              {
                "text": "And seven sons and three daughters were born to him.", 
                "num": 2
              }, 
              {
                "text": "Also, his possessions were seven thousand sheep, three thousand camels, five hundred yoke of oxen, five hundred female donkeys, and a very large household, so that this man was the greatest of all the people of the East.", 
                "num": 3
              }, 
              {
                "text": "And his sons would go and feast in their houses, each on his appointed day, and would send and invite their three sisters to eat and drink with them.", 
                "num": 4
              }, 
              {
                "text": "So it was, when the days of feasting had run their course, that Job would send and sanctify them, and he would rise early in the morning and offer burnt offerings according to the number of them all. For Job said, \"It may be that my sons have sinned and cursed God in their hearts.\" Thus Job did regularly.", 
                "num": 5
              }, 
              {
                "text": "Now there was a day when the sons of God came to present themselves before the LORD, and Satan also came among them.", 
                "num": 6
              }, 
              {
                "text": "And the LORD said to Satan, \"From where do you come?\" So Satan answered the LORD and said, \"From going to and fro on the earth, and from walking back and forth on it.\"", 
                "num": 7
              }, 
              {
                "text": "Then the LORD said to Satan, \"Have you considered My servant Job, that there is none like him on the earth, a blameless and upright man, one who fears God and shuns evil?\"", 
                "num": 8
              }, 
              {
                "text": "So Satan answered the LORD and said, \"Does Job fear God for nothing?", 
                "num": 9
              }, 
              {
                "text": "Have You not made a hedge around him, around his household, and around all that he has on every side? You have blessed the work of his hands, and his possessions have increased in the land.", 
                "num": 10
              }, 
              {
                "text": "But now, stretch out Your hand and touch all that he has, and he will surely curse You to Your face!\"", 
                "num": 11
              }, 
              {
                "text": "And the LORD said to Satan, \"Behold, all that he has is in your power; only do not lay a hand on his person.\" So Satan went out from the presence of the LORD.", 
                "num": 12
              }, 
              {
                "text": "Now there was a day when his sons and daughters were eating and drinking wine in their oldest brother's house;", 
                "num": 13
              }, 
              {
                "text": "and a messenger came to Job and said, \"The oxen were plowing and the donkeys feeding beside them,", 
                "num": 14
              }, 
              {
                "text": "when the Sabeans raided them and took them away--indeed they have killed the servants with the edge of the sword; and I alone have escaped to tell you!\"", 
                "num": 15
              }, 
              {
                "text": "While he was still speaking, another also came and said, \"The fire of God fell from heaven and burned up the sheep and the servants, and consumed them; and I alone have escaped to tell you!\"", 
                "num": 16
              }, 
              {
                "text": "While he was still speaking, another also came and said, \"The Chaldeans formed three bands, raided the camels and took them away, yes, and killed the servants with the edge of the sword; and I alone have escaped to tell you!\"", 
                "num": 17
              }, 
              {
                "text": "While he was still speaking, another also came and said, \"Your sons and daughters were eating and drinking wine in their oldest brother's house,", 
                "num": 18
              }, 
              {
                "text": "and suddenly a great wind came from across the wilderness and struck the four corners of the house, and it fell on the young people, and they are dead; and I alone have escaped to tell you!\"", 
                "num": 19
              }, 
              {
                "text": "Then Job arose, tore his robe, and shaved his head; and he fell to the ground and worshiped.", 
                "num": 20
              }, 
              {
                "text": "And he said: \"Naked I came from my mother's womb, And naked shall I return there. The LORD gave, and the LORD has taken away; Blessed be the name of the LORD.\"", 
                "num": 21
              }, 
              {
                "text": "In all this Job did not sin nor charge God with wrong.", 
                "num": 22
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Again there was a day when the sons of God came to present themselves before the LORD, and Satan came also among them to present himself before the LORD.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to Satan, \"From where do you come?\" Satan answered the LORD and said, \"From going to and fro on the earth, and from walking back and forth on it.\"", 
                "num": 2
              }, 
              {
                "text": "Then the LORD said to Satan, \"Have you considered My servant Job, that there is none like him on the earth, a blameless and upright man, one who fears God and shuns evil? And still he holds fast to his integrity, although you incited Me against him, to destroy him without cause.\"", 
                "num": 3
              }, 
              {
                "text": "So Satan answered the LORD and said, \"Skin for skin! Yes, all that a man has he will give for his life.", 
                "num": 4
              }, 
              {
                "text": "But stretch out Your hand now, and touch his bone and his flesh, and he will surely curse You to Your face!\"", 
                "num": 5
              }, 
              {
                "text": "And the LORD said to Satan, \"Behold, he is in your hand, but spare his life.\"", 
                "num": 6
              }, 
              {
                "text": "So Satan went out from the presence of the LORD, and struck Job with painful boils from the sole of his foot to the crown of his head.", 
                "num": 7
              }, 
              {
                "text": "And he took for himself a potsherd with which to scrape himself while he sat in the midst of the ashes.", 
                "num": 8
              }, 
              {
                "text": "Then his wife said to him, \"Do you still hold fast to your integrity? Curse God and die!\"", 
                "num": 9
              }, 
              {
                "text": "But he said to her, \"You speak as one of the foolish women speaks. Shall we indeed accept good from God, and shall we not accept adversity?\" In all this Job did not sin with his lips.", 
                "num": 10
              }, 
              {
                "text": "Now when Job's three friends heard of all this adversity that had come upon him, each one came from his own place--Eliphaz the Temanite, Bildad the Shuhite, and Zophar the Naamathite. For they had made an appointment together to come and mourn with him, and to comfort him.", 
                "num": 11
              }, 
              {
                "text": "And when they raised their eyes from afar, and did not recognize him, they lifted their voices and wept; and each one tore his robe and sprinkled dust on his head toward heaven.", 
                "num": 12
              }, 
              {
                "text": "So they sat down with him on the ground seven days and seven nights, and no one spoke a word to him, for they saw that his grief was very great.", 
                "num": 13
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "After this Job opened his mouth and cursed the day of his birth.", 
                "num": 1
              }, 
              {
                "text": "And Job spoke, and said:", 
                "num": 2
              }, 
              {
                "text": "\"May the day perish on which I was born, And the night in which it was said, \"A male child is conceived.'", 
                "num": 3
              }, 
              {
                "text": "May that day be darkness; May God above not seek it, Nor the light shine upon it.", 
                "num": 4
              }, 
              {
                "text": "May darkness and the shadow of death claim it; May a cloud settle on it; May the blackness of the day terrify it.", 
                "num": 5
              }, 
              {
                "text": "As for that night, may darkness seize it; May it not rejoice among the days of the year, May it not come into the number of the months.", 
                "num": 6
              }, 
              {
                "text": "Oh, may that night be barren! May no joyful shout come into it!", 
                "num": 7
              }, 
              {
                "text": "May those curse it who curse the day, Those who are ready to arouse Leviathan.", 
                "num": 8
              }, 
              {
                "text": "May the stars of its morning be dark; May it look for light, but have none, And not see the dawning of the day;", 
                "num": 9
              }, 
              {
                "text": "Because it did not shut up the doors of my mother's womb, Nor hide sorrow from my eyes.", 
                "num": 10
              }, 
              {
                "text": "\"Why did I not die at birth? Why did I not perish when I came from the womb?", 
                "num": 11
              }, 
              {
                "text": "Why did the knees receive me? Or why the breasts, that I should nurse?", 
                "num": 12
              }, 
              {
                "text": "For now I would have lain still and been quiet, I would have been asleep; Then I would have been at rest", 
                "num": 13
              }, 
              {
                "text": "With kings and counselors of the earth, Who built ruins for themselves,", 
                "num": 14
              }, 
              {
                "text": "Or with princes who had gold, Who filled their houses with silver;", 
                "num": 15
              }, 
              {
                "text": "Or why was I not hidden like a stillborn child, Like infants who never saw light?", 
                "num": 16
              }, 
              {
                "text": "There the wicked cease from troubling, And there the weary are at rest.", 
                "num": 17
              }, 
              {
                "text": "There the prisoners rest together; They do not hear the voice of the oppressor.", 
                "num": 18
              }, 
              {
                "text": "The small and great are there, And the servant is free from his master.", 
                "num": 19
              }, 
              {
                "text": "\"Why is light given to him who is in misery, And life to the bitter of soul,", 
                "num": 20
              }, 
              {
                "text": "Who long for death, but it does not come, And search for it more than hidden treasures;", 
                "num": 21
              }, 
              {
                "text": "Who rejoice exceedingly, And are glad when they can find the grave?", 
                "num": 22
              }, 
              {
                "text": "Why is light given to a man whose way is hidden, And whom God has hedged in?", 
                "num": 23
              }, 
              {
                "text": "For my sighing comes before I eat, And my groanings pour out like water.", 
                "num": 24
              }, 
              {
                "text": "For the thing I greatly feared has come upon me, And what I dreaded has happened to me.", 
                "num": 25
              }, 
              {
                "text": "I am not at ease, nor am I quiet; I have no rest, for trouble comes.\"", 
                "num": 26
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then Eliphaz the Temanite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"If one attempts a word with you, will you become weary? But who can withhold himself from speaking?", 
                "num": 2
              }, 
              {
                "text": "Surely you have instructed many, And you have strengthened weak hands.", 
                "num": 3
              }, 
              {
                "text": "Your words have upheld him who was stumbling, And you have strengthened the feeble knees;", 
                "num": 4
              }, 
              {
                "text": "But now it comes upon you, and you are weary; It touches you, and you are troubled.", 
                "num": 5
              }, 
              {
                "text": "Is not your reverence your confidence? And the integrity of your ways your hope?", 
                "num": 6
              }, 
              {
                "text": "\"Remember now, who ever perished being innocent? Or where were the upright ever cut off?", 
                "num": 7
              }, 
              {
                "text": "Even as I have seen, Those who plow iniquity And sow trouble reap the same.", 
                "num": 8
              }, 
              {
                "text": "By the blast of God they perish, And by the breath of His anger they are consumed.", 
                "num": 9
              }, 
              {
                "text": "The roaring of the lion, The voice of the fierce lion, And the teeth of the young lions are broken.", 
                "num": 10
              }, 
              {
                "text": "The old lion perishes for lack of prey, And the cubs of the lioness are scattered.", 
                "num": 11
              }, 
              {
                "text": "\"Now a word was secretly brought to me, And my ear received a whisper of it.", 
                "num": 12
              }, 
              {
                "text": "In disquieting thoughts from the visions of the night, When deep sleep falls on men,", 
                "num": 13
              }, 
              {
                "text": "Fear came upon me, and trembling, Which made all my bones shake.", 
                "num": 14
              }, 
              {
                "text": "Then a spirit passed before my face; The hair on my body stood up.", 
                "num": 15
              }, 
              {
                "text": "It stood still, But I could not discern its appearance. A form was before my eyes; There was silence; Then I heard a voice saying:", 
                "num": 16
              }, 
              {
                "text": "\"Can a mortal be more righteous than God? Can a man be more pure than his Maker?", 
                "num": 17
              }, 
              {
                "text": "If He puts no trust in His servants, If He charges His angels with error,", 
                "num": 18
              }, 
              {
                "text": "How much more those who dwell in houses of clay, Whose foundation is in the dust, Who are crushed before a moth?", 
                "num": 19
              }, 
              {
                "text": "They are broken in pieces from morning till evening; They perish forever, with no one regarding.", 
                "num": 20
              }, 
              {
                "text": "Does not their own excellence go away? They die, even without wisdom.'", 
                "num": 21
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "\"Call out now; Is there anyone who will answer you? And to which of the holy ones will you turn?", 
                "num": 1
              }, 
              {
                "text": "For wrath kills a foolish man, And envy slays a simple one.", 
                "num": 2
              }, 
              {
                "text": "I have seen the foolish taking root, But suddenly I cursed his dwelling place.", 
                "num": 3
              }, 
              {
                "text": "His sons are far from safety, They are crushed in the gate, And there is no deliverer.", 
                "num": 4
              }, 
              {
                "text": "Because the hungry eat up his harvest, Taking it even from the thorns, And a snare snatches their substance.", 
                "num": 5
              }, 
              {
                "text": "For affliction does not come from the dust, Nor does trouble spring from the ground;", 
                "num": 6
              }, 
              {
                "text": "Yet man is born to trouble, As the sparks fly upward.", 
                "num": 7
              }, 
              {
                "text": "\"But as for me, I would seek God, And to God I would commit my cause--", 
                "num": 8
              }, 
              {
                "text": "Who does great things, and unsearchable, Marvelous things without number.", 
                "num": 9
              }, 
              {
                "text": "He gives rain on the earth, And sends waters on the fields.", 
                "num": 10
              }, 
              {
                "text": "He sets on high those who are lowly, And those who mourn are lifted to safety.", 
                "num": 11
              }, 
              {
                "text": "He frustrates the devices of the crafty, So that their hands cannot carry out their plans.", 
                "num": 12
              }, 
              {
                "text": "He catches the wise in their own craftiness, And the counsel of the cunning comes quickly upon them.", 
                "num": 13
              }, 
              {
                "text": "They meet with darkness in the daytime, And grope at noontime as in the night.", 
                "num": 14
              }, 
              {
                "text": "But He saves the needy from the sword, From the mouth of the mighty, And from their hand.", 
                "num": 15
              }, 
              {
                "text": "So the poor have hope, And injustice shuts her mouth.", 
                "num": 16
              }, 
              {
                "text": "\"Behold, happy is the man whom God corrects; Therefore do not despise the chastening of the Almighty.", 
                "num": 17
              }, 
              {
                "text": "For He bruises, but He binds up; He wounds, but His hands make whole.", 
                "num": 18
              }, 
              {
                "text": "He shall deliver you in six troubles, Yes, in seven no evil shall touch you.", 
                "num": 19
              }, 
              {
                "text": "In famine He shall redeem you from death, And in war from the power of the sword.", 
                "num": 20
              }, 
              {
                "text": "You shall be hidden from the scourge of the tongue, And you shall not be afraid of destruction when it comes.", 
                "num": 21
              }, 
              {
                "text": "You shall laugh at destruction and famine, And you shall not be afraid of the beasts of the earth.", 
                "num": 22
              }, 
              {
                "text": "For you shall have a covenant with the stones of the field, And the beasts of the field shall be at peace with you.", 
                "num": 23
              }, 
              {
                "text": "You shall know that your tent is in peace; You shall visit your dwelling and find nothing amiss.", 
                "num": 24
              }, 
              {
                "text": "You shall also know that your descendants shall be many, And your offspring like the grass of the earth.", 
                "num": 25
              }, 
              {
                "text": "You shall come to the grave at a full age, As a sheaf of grain ripens in its season.", 
                "num": 26
              }, 
              {
                "text": "Behold, this we have searched out; It is true. Hear it, and know for yourself.\"", 
                "num": 27
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Oh, that my grief were fully weighed, And my calamity laid with it on the scales!", 
                "num": 2
              }, 
              {
                "text": "For then it would be heavier than the sand of the sea-- Therefore my words have been rash.", 
                "num": 3
              }, 
              {
                "text": "For the arrows of the Almighty are within me; My spirit drinks in their poison; The terrors of God are arrayed against me.", 
                "num": 4
              }, 
              {
                "text": "Does the wild donkey bray when it has grass, Or does the ox low over its fodder?", 
                "num": 5
              }, 
              {
                "text": "Can flavorless food be eaten without salt? Or is there any taste in the white of an egg?", 
                "num": 6
              }, 
              {
                "text": "My soul refuses to touch them; They are as loathsome food to me.", 
                "num": 7
              }, 
              {
                "text": "\"Oh, that I might have my request, That God would grant me the thing that I long for!", 
                "num": 8
              }, 
              {
                "text": "That it would please God to crush me, That He would loose His hand and cut me off!", 
                "num": 9
              }, 
              {
                "text": "Then I would still have comfort; Though in anguish I would exult, He will not spare; For I have not concealed the words of the Holy One.", 
                "num": 10
              }, 
              {
                "text": "\"What strength do I have, that I should hope? And what is my end, that I should prolong my life?", 
                "num": 11
              }, 
              {
                "text": "Is my strength the strength of stones? Or is my flesh bronze?", 
                "num": 12
              }, 
              {
                "text": "Is my help not within me? And is success driven from me?", 
                "num": 13
              }, 
              {
                "text": "\"To him who is afflicted, kindness should be shown by his friend, Even though he forsakes the fear of the Almighty.", 
                "num": 14
              }, 
              {
                "text": "My brothers have dealt deceitfully like a brook, Like the streams of the brooks that pass away,", 
                "num": 15
              }, 
              {
                "text": "Which are dark because of the ice, And into which the snow vanishes.", 
                "num": 16
              }, 
              {
                "text": "When it is warm, they cease to flow; When it is hot, they vanish from their place.", 
                "num": 17
              }, 
              {
                "text": "The paths of their way turn aside, They go nowhere and perish.", 
                "num": 18
              }, 
              {
                "text": "The caravans of Tema look, The travelers of Sheba hope for them.", 
                "num": 19
              }, 
              {
                "text": "They are disappointed because they were confident; They come there and are confused.", 
                "num": 20
              }, 
              {
                "text": "For now you are nothing, You see terror and are afraid.", 
                "num": 21
              }, 
              {
                "text": "Did I ever say, \"Bring something to me'? Or, \"Offer a bribe for me from your wealth'?", 
                "num": 22
              }, 
              {
                "text": "Or, \"Deliver me from the enemy's hand'? Or, \"Redeem me from the hand of oppressors'?", 
                "num": 23
              }, 
              {
                "text": "\"Teach me, and I will hold my tongue; Cause me to understand wherein I have erred.", 
                "num": 24
              }, 
              {
                "text": "How forceful are right words! But what does your arguing prove?", 
                "num": 25
              }, 
              {
                "text": "Do you intend to rebuke my words, And the speeches of a desperate one, which are as wind?", 
                "num": 26
              }, 
              {
                "text": "Yes, you overwhelm the fatherless, And you undermine your friend.", 
                "num": 27
              }, 
              {
                "text": "Now therefore, be pleased to look at me; For I would never lie to your face.", 
                "num": 28
              }, 
              {
                "text": "Yield now, let there be no injustice! Yes, concede, my righteousness still stands!", 
                "num": 29
              }, 
              {
                "text": "Is there injustice on my tongue? Cannot my taste discern the unsavory?", 
                "num": 30
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "\"Is there not a time of hard service for man on earth? Are not his days also like the days of a hired man?", 
                "num": 1
              }, 
              {
                "text": "Like a servant who earnestly desires the shade, And like a hired man who eagerly looks for his wages,", 
                "num": 2
              }, 
              {
                "text": "So I have been allotted months of futility, And wearisome nights have been appointed to me.", 
                "num": 3
              }, 
              {
                "text": "When I lie down, I say, \"When shall I arise, And the night be ended?' For I have had my fill of tossing till dawn.", 
                "num": 4
              }, 
              {
                "text": "My flesh is caked with worms and dust, My skin is cracked and breaks out afresh.", 
                "num": 5
              }, 
              {
                "text": "\"My days are swifter than a weaver's shuttle, And are spent without hope.", 
                "num": 6
              }, 
              {
                "text": "Oh, remember that my life is a breath! My eye will never again see good.", 
                "num": 7
              }, 
              {
                "text": "The eye of him who sees me will see me no more; While your eyes are upon me, I shall no longer be.", 
                "num": 8
              }, 
              {
                "text": "As the cloud disappears and vanishes away, So he who goes down to the grave does not come up.", 
                "num": 9
              }, 
              {
                "text": "He shall never return to his house, Nor shall his place know him anymore.", 
                "num": 10
              }, 
              {
                "text": "\"Therefore I will not restrain my mouth; I will speak in the anguish of my spirit; I will complain in the bitterness of my soul.", 
                "num": 11
              }, 
              {
                "text": "Am I a sea, or a sea serpent, That You set a guard over me?", 
                "num": 12
              }, 
              {
                "text": "When I say, \"My bed will comfort me, My couch will ease my complaint,'", 
                "num": 13
              }, 
              {
                "text": "Then You scare me with dreams And terrify me with visions,", 
                "num": 14
              }, 
              {
                "text": "So that my soul chooses strangling And death rather than my body.", 
                "num": 15
              }, 
              {
                "text": "I loathe my life; I would not live forever. Let me alone, For my days are but a breath.", 
                "num": 16
              }, 
              {
                "text": "\"What is man, that You should exalt him, That You should set Your heart on him,", 
                "num": 17
              }, 
              {
                "text": "That You should visit him every morning, And test him every moment?", 
                "num": 18
              }, 
              {
                "text": "How long? Will You not look away from me, And let me alone till I swallow my saliva?", 
                "num": 19
              }, 
              {
                "text": "Have I sinned? What have I done to You, O watcher of men? Why have You set me as Your target, So that I am a burden to myself?", 
                "num": 20
              }, 
              {
                "text": "Why then do You not pardon my transgression, And take away my iniquity? For now I will lie down in the dust, And You will seek me diligently, But I will no longer be.\"", 
                "num": 21
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Then Bildad the Shuhite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"How long will you speak these things, And the words of your mouth be like a strong wind?", 
                "num": 2
              }, 
              {
                "text": "Does God subvert judgment? Or does the Almighty pervert justice?", 
                "num": 3
              }, 
              {
                "text": "If your sons have sinned against Him, He has cast them away for their transgression.", 
                "num": 4
              }, 
              {
                "text": "If you would earnestly seek God And make your supplication to the Almighty,", 
                "num": 5
              }, 
              {
                "text": "If you were pure and upright, Surely now He would awake for you, And prosper your rightful dwelling place.", 
                "num": 6
              }, 
              {
                "text": "Though your beginning was small, Yet your latter end would increase abundantly.", 
                "num": 7
              }, 
              {
                "text": "\"For inquire, please, of the former age, And consider the things discovered by their fathers;", 
                "num": 8
              }, 
              {
                "text": "For we were born yesterday, and know nothing, Because our days on earth are a shadow.", 
                "num": 9
              }, 
              {
                "text": "Will they not teach you and tell you, And utter words from their heart?", 
                "num": 10
              }, 
              {
                "text": "\"Can the papyrus grow up without a marsh? Can the reeds flourish without water?", 
                "num": 11
              }, 
              {
                "text": "While it is yet green and not cut down, It withers before any other plant.", 
                "num": 12
              }, 
              {
                "text": "So are the paths of all who forget God; And the hope of the hypocrite shall perish,", 
                "num": 13
              }, 
              {
                "text": "Whose confidence shall be cut off, And whose trust is a spider's web.", 
                "num": 14
              }, 
              {
                "text": "He leans on his house, but it does not stand. He holds it fast, but it does not endure.", 
                "num": 15
              }, 
              {
                "text": "He grows green in the sun, And his branches spread out in his garden.", 
                "num": 16
              }, 
              {
                "text": "His roots wrap around the rock heap, And look for a place in the stones.", 
                "num": 17
              }, 
              {
                "text": "If he is destroyed from his place, Then it will deny him, saying, \"I have not seen you.'", 
                "num": 18
              }, 
              {
                "text": "\"Behold, this is the joy of His way, And out of the earth others will grow.", 
                "num": 19
              }, 
              {
                "text": "Behold, God will not cast away the blameless, Nor will He uphold the evildoers.", 
                "num": 20
              }, 
              {
                "text": "He will yet fill your mouth with laughing, And your lips with rejoicing.", 
                "num": 21
              }, 
              {
                "text": "Those who hate you will be clothed with shame, And the dwelling place of the wicked will come to nothing.\"", 
                "num": 22
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Truly I know it is so, But how can a man be righteous before God?", 
                "num": 2
              }, 
              {
                "text": "If one wished to contend with Him, He could not answer Him one time out of a thousand.", 
                "num": 3
              }, 
              {
                "text": "God is wise in heart and mighty in strength. Who has hardened himself against Him and prospered?", 
                "num": 4
              }, 
              {
                "text": "He removes the mountains, and they do not know When He overturns them in His anger;", 
                "num": 5
              }, 
              {
                "text": "He shakes the earth out of its place, And its pillars tremble;", 
                "num": 6
              }, 
              {
                "text": "He commands the sun, and it does not rise; He seals off the stars;", 
                "num": 7
              }, 
              {
                "text": "He alone spreads out the heavens, And treads on the waves of the sea;", 
                "num": 8
              }, 
              {
                "text": "He made the Bear, Orion, and the Pleiades, And the chambers of the south;", 
                "num": 9
              }, 
              {
                "text": "He does great things past finding out, Yes, wonders without number.", 
                "num": 10
              }, 
              {
                "text": "If He goes by me, I do not see Him; If He moves past, I do not perceive Him;", 
                "num": 11
              }, 
              {
                "text": "If He takes away, who can hinder Him? Who can say to Him, \"What are You doing?'", 
                "num": 12
              }, 
              {
                "text": "God will not withdraw His anger, The allies of the proud lie prostrate beneath Him.", 
                "num": 13
              }, 
              {
                "text": "\"How then can I answer Him, And choose my words to reason with Him?", 
                "num": 14
              }, 
              {
                "text": "For though I were righteous, I could not answer Him; I would beg mercy of my Judge.", 
                "num": 15
              }, 
              {
                "text": "If I called and He answered me, I would not believe that He was listening to my voice.", 
                "num": 16
              }, 
              {
                "text": "For He crushes me with a tempest, And multiplies my wounds without cause.", 
                "num": 17
              }, 
              {
                "text": "He will not allow me to catch my breath, But fills me with bitterness.", 
                "num": 18
              }, 
              {
                "text": "If it is a matter of strength, indeed He is strong; And if of justice, who will appoint my day in court?", 
                "num": 19
              }, 
              {
                "text": "Though I were righteous, my own mouth would condemn me; Though I were blameless, it would prove me perverse.", 
                "num": 20
              }, 
              {
                "text": "\"I am blameless, yet I do not know myself; I despise my life.", 
                "num": 21
              }, 
              {
                "text": "It is all one thing; Therefore I say, \"He destroys the blameless and the wicked.'", 
                "num": 22
              }, 
              {
                "text": "If the scourge slays suddenly, He laughs at the plight of the innocent.", 
                "num": 23
              }, 
              {
                "text": "The earth is given into the hand of the wicked. He covers the faces of its judges. If it is not He, who else could it be?", 
                "num": 24
              }, 
              {
                "text": "\"Now my days are swifter than a runner; They flee away, they see no good.", 
                "num": 25
              }, 
              {
                "text": "They pass by like swift ships, Like an eagle swooping on its prey.", 
                "num": 26
              }, 
              {
                "text": "If I say, \"I will forget my complaint, I will put off my sad face and wear a smile,'", 
                "num": 27
              }, 
              {
                "text": "I am afraid of all my sufferings; I know that You will not hold me innocent.", 
                "num": 28
              }, 
              {
                "text": "If I am condemned, Why then do I labor in vain?", 
                "num": 29
              }, 
              {
                "text": "If I wash myself with snow water, And cleanse my hands with soap,", 
                "num": 30
              }, 
              {
                "text": "Yet You will plunge me into the pit, And my own clothes will abhor me.", 
                "num": 31
              }, 
              {
                "text": "\"For He is not a man, as I am, That I may answer Him, And that we should go to court together.", 
                "num": 32
              }, 
              {
                "text": "Nor is there any mediator between us, Who may lay his hand on us both.", 
                "num": 33
              }, 
              {
                "text": "Let Him take His rod away from me, And do not let dread of Him terrify me.", 
                "num": 34
              }, 
              {
                "text": "Then I would speak and not fear Him, But it is not so with me.", 
                "num": 35
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "\"My soul loathes my life; I will give free course to my complaint, I will speak in the bitterness of my soul.", 
                "num": 1
              }, 
              {
                "text": "I will say to God, \"Do not condemn me; Show me why You contend with me.", 
                "num": 2
              }, 
              {
                "text": "Does it seem good to You that You should oppress, That You should despise the work of Your hands, And smile on the counsel of the wicked?", 
                "num": 3
              }, 
              {
                "text": "Do You have eyes of flesh? Or do You see as man sees?", 
                "num": 4
              }, 
              {
                "text": "Are Your days like the days of a mortal man? Are Your years like the days of a mighty man,", 
                "num": 5
              }, 
              {
                "text": "That You should seek for my iniquity And search out my sin,", 
                "num": 6
              }, 
              {
                "text": "Although You know that I am not wicked, And there is no one who can deliver from Your hand?", 
                "num": 7
              }, 
              {
                "text": "\"Your hands have made me and fashioned me, An intricate unity; Yet You would destroy me.", 
                "num": 8
              }, 
              {
                "text": "Remember, I pray, that You have made me like clay. And will You turn me into dust again?", 
                "num": 9
              }, 
              {
                "text": "Did You not pour me out like milk, And curdle me like cheese,", 
                "num": 10
              }, 
              {
                "text": "Clothe me with skin and flesh, And knit me together with bones and sinews?", 
                "num": 11
              }, 
              {
                "text": "You have granted me life and favor, And Your care has preserved my spirit.", 
                "num": 12
              }, 
              {
                "text": "\"And these things You have hidden in Your heart; I know that this was with You:", 
                "num": 13
              }, 
              {
                "text": "If I sin, then You mark me, And will not acquit me of my iniquity.", 
                "num": 14
              }, 
              {
                "text": "If I am wicked, woe to me; Even if I am righteous, I cannot lift up my head. I am full of disgrace; See my misery!", 
                "num": 15
              }, 
              {
                "text": "If my head is exalted, You hunt me like a fierce lion, And again You show Yourself awesome against me.", 
                "num": 16
              }, 
              {
                "text": "You renew Your witnesses against me, And increase Your indignation toward me; Changes and war are ever with me.", 
                "num": 17
              }, 
              {
                "text": "\"Why then have You brought me out of the womb? Oh, that I had perished and no eye had seen me!", 
                "num": 18
              }, 
              {
                "text": "I would have been as though I had not been. I would have been carried from the womb to the grave.", 
                "num": 19
              }, 
              {
                "text": "Are not my days few? Cease! Leave me alone, that I may take a little comfort,", 
                "num": 20
              }, 
              {
                "text": "Before I go to the place from which I shall not return, To the land of darkness and the shadow of death,", 
                "num": 21
              }, 
              {
                "text": "A land as dark as darkness itself, As the shadow of death, without any order, Where even the light is like darkness.\"'", 
                "num": 22
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Then Zophar the Naamathite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Should not the multitude of words be answered? And should a man full of talk be vindicated?", 
                "num": 2
              }, 
              {
                "text": "Should your empty talk make men hold their peace? And when you mock, should no one rebuke you?", 
                "num": 3
              }, 
              {
                "text": "For you have said, \"My doctrine is pure, And I am clean in your eyes.'", 
                "num": 4
              }, 
              {
                "text": "But oh, that God would speak, And open His lips against you,", 
                "num": 5
              }, 
              {
                "text": "That He would show you the secrets of wisdom! For they would double your prudence. Know therefore that God exacts from you Less than your iniquity deserves.", 
                "num": 6
              }, 
              {
                "text": "\"Can you search out the deep things of God? Can you find out the limits of the Almighty?", 
                "num": 7
              }, 
              {
                "text": "They are higher than heaven-- what can you do? Deeper than Sheol-- what can you know?", 
                "num": 8
              }, 
              {
                "text": "Their measure is longer than the earth And broader than the sea.", 
                "num": 9
              }, 
              {
                "text": "\"If He passes by, imprisons, and gathers to judgment, Then who can hinder Him?", 
                "num": 10
              }, 
              {
                "text": "For He knows deceitful men; He sees wickedness also. Will He not then consider it?", 
                "num": 11
              }, 
              {
                "text": "For an empty-headed man will be wise, When a wild donkey's colt is born a man.", 
                "num": 12
              }, 
              {
                "text": "\"If you would prepare your heart, And stretch out your hands toward Him;", 
                "num": 13
              }, 
              {
                "text": "If iniquity were in your hand, and you put it far away, And would not let wickedness dwell in your tents;", 
                "num": 14
              }, 
              {
                "text": "Then surely you could lift up your face without spot; Yes, you could be steadfast, and not fear;", 
                "num": 15
              }, 
              {
                "text": "Because you would forget your misery, And remember it as waters that have passed away,", 
                "num": 16
              }, 
              {
                "text": "And your life would be brighter than noonday. Though you were dark, you would be like the morning.", 
                "num": 17
              }, 
              {
                "text": "And you would be secure, because there is hope; Yes, you would dig around you, and take your rest in safety.", 
                "num": 18
              }, 
              {
                "text": "You would also lie down, and no one would make you afraid; Yes, many would court your favor.", 
                "num": 19
              }, 
              {
                "text": "But the eyes of the wicked will fail, And they shall not escape, And their hope--loss of life!\"", 
                "num": 20
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"No doubt you are the people, And wisdom will die with you!", 
                "num": 2
              }, 
              {
                "text": "But I have understanding as well as you; I am not inferior to you. Indeed, who does not know such things as these?", 
                "num": 3
              }, 
              {
                "text": "\"I am one mocked by his friends, Who called on God, and He answered him, The just and blameless who is ridiculed.", 
                "num": 4
              }, 
              {
                "text": "A lamp is despised in the thought of one who is at ease; It is made ready for those whose feet slip.", 
                "num": 5
              }, 
              {
                "text": "The tents of robbers prosper, And those who provoke God are secure-- In what God provides by His hand.", 
                "num": 6
              }, 
              {
                "text": "\"But now ask the beasts, and they will teach you; And the birds of the air, and they will tell you;", 
                "num": 7
              }, 
              {
                "text": "Or speak to the earth, and it will teach you; And the fish of the sea will explain to you.", 
                "num": 8
              }, 
              {
                "text": "Who among all these does not know That the hand of the LORD has done this,", 
                "num": 9
              }, 
              {
                "text": "In whose hand is the life of every living thing, And the breath of all mankind?", 
                "num": 10
              }, 
              {
                "text": "Does not the ear test words And the mouth taste its food?", 
                "num": 11
              }, 
              {
                "text": "Wisdom is with aged men, And with length of days, understanding.", 
                "num": 12
              }, 
              {
                "text": "\"With Him are wisdom and strength, He has counsel and understanding.", 
                "num": 13
              }, 
              {
                "text": "If He breaks a thing down, it cannot be rebuilt; If He imprisons a man, there can be no release.", 
                "num": 14
              }, 
              {
                "text": "If He withholds the waters, they dry up; If He sends them out, they overwhelm the earth.", 
                "num": 15
              }, 
              {
                "text": "With Him are strength and prudence. The deceived and the deceiver are His.", 
                "num": 16
              }, 
              {
                "text": "He leads counselors away plundered, And makes fools of the judges.", 
                "num": 17
              }, 
              {
                "text": "He loosens the bonds of kings, And binds their waist with a belt.", 
                "num": 18
              }, 
              {
                "text": "He leads princes away plundered, And overthrows the mighty.", 
                "num": 19
              }, 
              {
                "text": "He deprives the trusted ones of speech, And takes away the discernment of the elders.", 
                "num": 20
              }, 
              {
                "text": "He pours contempt on princes, And disarms the mighty.", 
                "num": 21
              }, 
              {
                "text": "He uncovers deep things out of darkness, And brings the shadow of death to light.", 
                "num": 22
              }, 
              {
                "text": "He makes nations great, and destroys them; He enlarges nations, and guides them.", 
                "num": 23
              }, 
              {
                "text": "He takes away the understanding of the chiefs of the people of the earth, And makes them wander in a pathless wilderness.", 
                "num": 24
              }, 
              {
                "text": "They grope in the dark without light, And He makes them stagger like a drunken man.", 
                "num": 25
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "\"Behold, my eye has seen all this, My ear has heard and understood it.", 
                "num": 1
              }, 
              {
                "text": "What you know, I also know; I am not inferior to you.", 
                "num": 2
              }, 
              {
                "text": "But I would speak to the Almighty, And I desire to reason with God.", 
                "num": 3
              }, 
              {
                "text": "But you forgers of lies, You are all worthless physicians.", 
                "num": 4
              }, 
              {
                "text": "Oh, that you would be silent, And it would be your wisdom!", 
                "num": 5
              }, 
              {
                "text": "Now hear my reasoning, And heed the pleadings of my lips.", 
                "num": 6
              }, 
              {
                "text": "Will you speak wickedly for God, And talk deceitfully for Him?", 
                "num": 7
              }, 
              {
                "text": "Will you show partiality for Him? Will you contend for God?", 
                "num": 8
              }, 
              {
                "text": "Will it be well when He searches you out? Or can you mock Him as one mocks a man?", 
                "num": 9
              }, 
              {
                "text": "He will surely rebuke you If you secretly show partiality.", 
                "num": 10
              }, 
              {
                "text": "Will not His excellence make you afraid, And the dread of Him fall upon you?", 
                "num": 11
              }, 
              {
                "text": "Your platitudes are proverbs of ashes, Your defenses are defenses of clay.", 
                "num": 12
              }, 
              {
                "text": "\"Hold your peace with me, and let me speak, Then let come on me what may!", 
                "num": 13
              }, 
              {
                "text": "Why do I take my flesh in my teeth, And put my life in my hands?", 
                "num": 14
              }, 
              {
                "text": "Though He slay me, yet will I trust Him. Even so, I will defend my own ways before Him.", 
                "num": 15
              }, 
              {
                "text": "He also shall be my salvation, For a hypocrite could not come before Him.", 
                "num": 16
              }, 
              {
                "text": "Listen carefully to my speech, And to my declaration with your ears.", 
                "num": 17
              }, 
              {
                "text": "See now, I have prepared my case, I know that I shall be vindicated.", 
                "num": 18
              }, 
              {
                "text": "Who is he who will contend with me? If now I hold my tongue, I perish.", 
                "num": 19
              }, 
              {
                "text": "\"Only two things do not do to me, Then I will not hide myself from You:", 
                "num": 20
              }, 
              {
                "text": "Withdraw Your hand far from me, And let not the dread of You make me afraid.", 
                "num": 21
              }, 
              {
                "text": "Then call, and I will answer; Or let me speak, then You respond to me.", 
                "num": 22
              }, 
              {
                "text": "How many are my iniquities and sins? Make me know my transgression and my sin.", 
                "num": 23
              }, 
              {
                "text": "Why do You hide Your face, And regard me as Your enemy?", 
                "num": 24
              }, 
              {
                "text": "Will You frighten a leaf driven to and fro? And will You pursue dry stubble?", 
                "num": 25
              }, 
              {
                "text": "For You write bitter things against me, And make me inherit the iniquities of my youth.", 
                "num": 26
              }, 
              {
                "text": "You put my feet in the stocks, And watch closely all my paths. You set a limit for the soles of my feet.", 
                "num": 27
              }, 
              {
                "text": "\"Man decays like a rotten thing, Like a garment that is moth-eaten.", 
                "num": 28
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "\"Man who is born of woman Is of few days and full of trouble.", 
                "num": 1
              }, 
              {
                "text": "He comes forth like a flower and fades away; He flees like a shadow and does not continue.", 
                "num": 2
              }, 
              {
                "text": "And do You open Your eyes on such a one, And bring me to judgment with Yourself?", 
                "num": 3
              }, 
              {
                "text": "Who can bring a clean thing out of an unclean? No one!", 
                "num": 4
              }, 
              {
                "text": "Since his days are determined, The number of his months is with You; You have appointed his limits, so that he cannot pass.", 
                "num": 5
              }, 
              {
                "text": "Look away from him that he may rest, Till like a hired man he finishes his day.", 
                "num": 6
              }, 
              {
                "text": "\"For there is hope for a tree, If it is cut down, that it will sprout again, And that its tender shoots will not cease.", 
                "num": 7
              }, 
              {
                "text": "Though its root may grow old in the earth, And its stump may die in the ground,", 
                "num": 8
              }, 
              {
                "text": "Yet at the scent of water it will bud And bring forth branches like a plant.", 
                "num": 9
              }, 
              {
                "text": "But man dies and is laid away; Indeed he breathes his last And where is he?", 
                "num": 10
              }, 
              {
                "text": "As water disappears from the sea, And a river becomes parched and dries up,", 
                "num": 11
              }, 
              {
                "text": "So man lies down and does not rise. Till the heavens are no more, They will not awake Nor be roused from their sleep.", 
                "num": 12
              }, 
              {
                "text": "\"Oh, that You would hide me in the grave, That You would conceal me until Your wrath is past, That You would appoint me a set time, and remember me!", 
                "num": 13
              }, 
              {
                "text": "If a man dies, shall he live again? All the days of my hard service I will wait, Till my change comes.", 
                "num": 14
              }, 
              {
                "text": "You shall call, and I will answer You; You shall desire the work of Your hands.", 
                "num": 15
              }, 
              {
                "text": "For now You number my steps, But do not watch over my sin.", 
                "num": 16
              }, 
              {
                "text": "My transgression is sealed up in a bag, And You cover my iniquity.", 
                "num": 17
              }, 
              {
                "text": "\"But as a mountain falls and crumbles away, And as a rock is moved from its place;", 
                "num": 18
              }, 
              {
                "text": "As water wears away stones, And as torrents wash away the soil of the earth; So You destroy the hope of man.", 
                "num": 19
              }, 
              {
                "text": "You prevail forever against him, and he passes on; You change his countenance and send him away.", 
                "num": 20
              }, 
              {
                "text": "His sons come to honor, and he does not know it; They are brought low, and he does not perceive it.", 
                "num": 21
              }, 
              {
                "text": "But his flesh will be in pain over it, And his soul will mourn over it.\"", 
                "num": 22
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then Eliphaz the Temanite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Should a wise man answer with empty knowledge, And fill himself with the east wind?", 
                "num": 2
              }, 
              {
                "text": "Should he reason with unprofitable talk, Or by speeches with which he can do no good?", 
                "num": 3
              }, 
              {
                "text": "Yes, you cast off fear, And restrain prayer before God.", 
                "num": 4
              }, 
              {
                "text": "For your iniquity teaches your mouth, And you choose the tongue of the crafty.", 
                "num": 5
              }, 
              {
                "text": "Your own mouth condemns you, and not I; Yes, your own lips testify against you.", 
                "num": 6
              }, 
              {
                "text": "\"Are you the first man who was born? Or were you made before the hills?", 
                "num": 7
              }, 
              {
                "text": "Have you heard the counsel of God? Do you limit wisdom to yourself?", 
                "num": 8
              }, 
              {
                "text": "What do you know that we do not know? What do you understand that is not in us?", 
                "num": 9
              }, 
              {
                "text": "Both the gray-haired and the aged are among us, Much older than your father.", 
                "num": 10
              }, 
              {
                "text": "Are the consolations of God too small for you, And the word spoken gently with you?", 
                "num": 11
              }, 
              {
                "text": "Why does your heart carry you away, And what do your eyes wink at,", 
                "num": 12
              }, 
              {
                "text": "That you turn your spirit against God, And let such words go out of your mouth?", 
                "num": 13
              }, 
              {
                "text": "\"What is man, that he could be pure? And he who is born of a woman, that he could be righteous?", 
                "num": 14
              }, 
              {
                "text": "If God puts no trust in His saints, And the heavens are not pure in His sight,", 
                "num": 15
              }, 
              {
                "text": "How much less man, who is abominable and filthy, Who drinks iniquity like water!", 
                "num": 16
              }, 
              {
                "text": "\"I will tell you, hear me; What I have seen I will declare,", 
                "num": 17
              }, 
              {
                "text": "What wise men have told, Not hiding anything received from their fathers,", 
                "num": 18
              }, 
              {
                "text": "To whom alone the land was given, And no alien passed among them:", 
                "num": 19
              }, 
              {
                "text": "The wicked man writhes with pain all his days, And the number of years is hidden from the oppressor.", 
                "num": 20
              }, 
              {
                "text": "Dreadful sounds are in his ears; In prosperity the destroyer comes upon him.", 
                "num": 21
              }, 
              {
                "text": "He does not believe that he will return from darkness, For a sword is waiting for him.", 
                "num": 22
              }, 
              {
                "text": "He wanders about for bread, saying, \"Where is it?' He knows that a day of darkness is ready at his hand.", 
                "num": 23
              }, 
              {
                "text": "Trouble and anguish make him afraid; They overpower him, like a king ready for battle.", 
                "num": 24
              }, 
              {
                "text": "For he stretches out his hand against God, And acts defiantly against the Almighty,", 
                "num": 25
              }, 
              {
                "text": "Running stubbornly against Him With his strong, embossed shield.", 
                "num": 26
              }, 
              {
                "text": "\"Though he has covered his face with his fatness, And made his waist heavy with fat,", 
                "num": 27
              }, 
              {
                "text": "He dwells in desolate cities, In houses which no one inhabits, Which are destined to become ruins.", 
                "num": 28
              }, 
              {
                "text": "He will not be rich, Nor will his wealth continue, Nor will his possessions overspread the earth.", 
                "num": 29
              }, 
              {
                "text": "He will not depart from darkness; The flame will dry out his branches, And by the breath of His mouth he will go away.", 
                "num": 30
              }, 
              {
                "text": "Let him not trust in futile things, deceiving himself, For futility will be his reward.", 
                "num": 31
              }, 
              {
                "text": "It will be accomplished before his time, And his branch will not be green.", 
                "num": 32
              }, 
              {
                "text": "He will shake off his unripe grape like a vine, And cast off his blossom like an olive tree.", 
                "num": 33
              }, 
              {
                "text": "For the company of hypocrites will be barren, And fire will consume the tents of bribery.", 
                "num": 34
              }, 
              {
                "text": "They conceive trouble and bring forth futility; Their womb prepares deceit.\"", 
                "num": 35
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"I have heard many such things; Miserable comforters are you all!", 
                "num": 2
              }, 
              {
                "text": "Shall words of wind have an end? Or what provokes you that you answer?", 
                "num": 3
              }, 
              {
                "text": "I also could speak as you do, If your soul were in my soul's place. I could heap up words against you, And shake my head at you;", 
                "num": 4
              }, 
              {
                "text": "But I would strengthen you with my mouth, And the comfort of my lips would relieve your grief.", 
                "num": 5
              }, 
              {
                "text": "\"Though I speak, my grief is not relieved; And if I remain silent, how am I eased?", 
                "num": 6
              }, 
              {
                "text": "But now He has worn me out; You have made desolate all my company.", 
                "num": 7
              }, 
              {
                "text": "You have shriveled me up, And it is a witness against me; My leanness rises up against me And bears witness to my face.", 
                "num": 8
              }, 
              {
                "text": "He tears me in His wrath, and hates me; He gnashes at me with His teeth; My adversary sharpens His gaze on me.", 
                "num": 9
              }, 
              {
                "text": "They gape at me with their mouth, They strike me reproachfully on the cheek, They gather together against me.", 
                "num": 10
              }, 
              {
                "text": "God has delivered me to the ungodly, And turned me over to the hands of the wicked.", 
                "num": 11
              }, 
              {
                "text": "I was at ease, but He has shattered me; He also has taken me by my neck, and shaken me to pieces; He has set me up for His target,", 
                "num": 12
              }, 
              {
                "text": "His archers surround me. He pierces my heart and does not pity; He pours out my gall on the ground.", 
                "num": 13
              }, 
              {
                "text": "He breaks me with wound upon wound; He runs at me like a warrior.", 
                "num": 14
              }, 
              {
                "text": "\"I have sewn sackcloth over my skin, And laid my head in the dust.", 
                "num": 15
              }, 
              {
                "text": "My face is flushed from weeping, And on my eyelids is the shadow of death;", 
                "num": 16
              }, 
              {
                "text": "Although no violence is in my hands, And my prayer is pure.", 
                "num": 17
              }, 
              {
                "text": "\"O earth, do not cover my blood, And let my cry have no resting place!", 
                "num": 18
              }, 
              {
                "text": "Surely even now my witness is in heaven, And my evidence is on high.", 
                "num": 19
              }, 
              {
                "text": "My friends scorn me; My eyes pour out tears to God.", 
                "num": 20
              }, 
              {
                "text": "Oh, that one might plead for a man with God, As a man pleads for his neighbor!", 
                "num": 21
              }, 
              {
                "text": "For when a few years are finished, I shall go the way of no return.", 
                "num": 22
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "\"My spirit is broken, My days are extinguished, The grave is ready for me.", 
                "num": 1
              }, 
              {
                "text": "Are not mockers with me? And does not my eye dwell on their provocation?", 
                "num": 2
              }, 
              {
                "text": "\"Now put down a pledge for me with Yourself. Who is he who will shake hands with me?", 
                "num": 3
              }, 
              {
                "text": "For You have hidden their heart from understanding; Therefore You will not exalt them.", 
                "num": 4
              }, 
              {
                "text": "He who speaks flattery to his friends, Even the eyes of his children will fail.", 
                "num": 5
              }, 
              {
                "text": "\"But He has made me a byword of the people, And I have become one in whose face men spit.", 
                "num": 6
              }, 
              {
                "text": "My eye has also grown dim because of sorrow, And all my members are like shadows.", 
                "num": 7
              }, 
              {
                "text": "Upright men are astonished at this, And the innocent stirs himself up against the hypocrite.", 
                "num": 8
              }, 
              {
                "text": "Yet the righteous will hold to his way, And he who has clean hands will be stronger and stronger.", 
                "num": 9
              }, 
              {
                "text": "\"But please, come back again, all of you, For I shall not find one wise man among you.", 
                "num": 10
              }, 
              {
                "text": "My days are past, My purposes are broken off, Even the thoughts of my heart.", 
                "num": 11
              }, 
              {
                "text": "They change the night into day; \"The light is near,' they say, in the face of darkness.", 
                "num": 12
              }, 
              {
                "text": "If I wait for the grave as my house, If I make my bed in the darkness,", 
                "num": 13
              }, 
              {
                "text": "If I say to corruption, \"You are my father,' And to the worm, \"You are my mother and my sister,'", 
                "num": 14
              }, 
              {
                "text": "Where then is my hope? As for my hope, who can see it?", 
                "num": 15
              }, 
              {
                "text": "Will they go down to the gates of Sheol? Shall we have rest together in the dust?\"", 
                "num": 16
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Then Bildad the Shuhite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"How long till you put an end to words? Gain understanding, and afterward we will speak.", 
                "num": 2
              }, 
              {
                "text": "Why are we counted as beasts, And regarded as stupid in your sight?", 
                "num": 3
              }, 
              {
                "text": "You who tear yourself in anger, Shall the earth be forsaken for you? Or shall the rock be removed from its place?", 
                "num": 4
              }, 
              {
                "text": "\"The light of the wicked indeed goes out, And the flame of his fire does not shine.", 
                "num": 5
              }, 
              {
                "text": "The light is dark in his tent, And his lamp beside him is put out.", 
                "num": 6
              }, 
              {
                "text": "The steps of his strength are shortened, And his own counsel casts him down.", 
                "num": 7
              }, 
              {
                "text": "For he is cast into a net by his own feet, And he walks into a snare.", 
                "num": 8
              }, 
              {
                "text": "The net takes him by the heel, And a snare lays hold of him.", 
                "num": 9
              }, 
              {
                "text": "A noose is hidden for him on the ground, And a trap for him in the road.", 
                "num": 10
              }, 
              {
                "text": "Terrors frighten him on every side, And drive him to his feet.", 
                "num": 11
              }, 
              {
                "text": "His strength is starved, And destruction is ready at his side.", 
                "num": 12
              }, 
              {
                "text": "It devours patches of his skin; The firstborn of death devours his limbs.", 
                "num": 13
              }, 
              {
                "text": "He is uprooted from the shelter of his tent, And they parade him before the king of terrors.", 
                "num": 14
              }, 
              {
                "text": "They dwell in his tent who are none of his; Brimstone is scattered on his dwelling.", 
                "num": 15
              }, 
              {
                "text": "His roots are dried out below, And his branch withers above.", 
                "num": 16
              }, 
              {
                "text": "The memory of him perishes from the earth, And he has no name among the renowned.", 
                "num": 17
              }, 
              {
                "text": "He is driven from light into darkness, And chased out of the world.", 
                "num": 18
              }, 
              {
                "text": "He has neither son nor posterity among his people, Nor any remaining in his dwellings.", 
                "num": 19
              }, 
              {
                "text": "Those in the west are astonished at his day, As those in the east are frightened.", 
                "num": 20
              }, 
              {
                "text": "Surely such are the dwellings of the wicked, And this is the place of him who does not know God.\"", 
                "num": 21
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"How long will you torment my soul, And break me in pieces with words?", 
                "num": 2
              }, 
              {
                "text": "These ten times you have reproached me; You are not ashamed that you have wronged me.", 
                "num": 3
              }, 
              {
                "text": "And if indeed I have erred, My error remains with me.", 
                "num": 4
              }, 
              {
                "text": "If indeed you exalt yourselves against me, And plead my disgrace against me,", 
                "num": 5
              }, 
              {
                "text": "Know then that God has wronged me, And has surrounded me with His net.", 
                "num": 6
              }, 
              {
                "text": "\"If I cry out concerning wrong, I am not heard. If I cry aloud, there is no justice.", 
                "num": 7
              }, 
              {
                "text": "He has fenced up my way, so that I cannot pass; And He has set darkness in my paths.", 
                "num": 8
              }, 
              {
                "text": "He has stripped me of my glory, And taken the crown from my head.", 
                "num": 9
              }, 
              {
                "text": "He breaks me down on every side, And I am gone; My hope He has uprooted like a tree.", 
                "num": 10
              }, 
              {
                "text": "He has also kindled His wrath against me, And He counts me as one of His enemies.", 
                "num": 11
              }, 
              {
                "text": "His troops come together And build up their road against me; They encamp all around my tent.", 
                "num": 12
              }, 
              {
                "text": "\"He has removed my brothers far from me, And my acquaintances are completely estranged from me.", 
                "num": 13
              }, 
              {
                "text": "My relatives have failed, And my close friends have forgotten me.", 
                "num": 14
              }, 
              {
                "text": "Those who dwell in my house, and my maidservants, Count me as a stranger; I am an alien in their sight.", 
                "num": 15
              }, 
              {
                "text": "I call my servant, but he gives no answer; I beg him with my mouth.", 
                "num": 16
              }, 
              {
                "text": "My breath is offensive to my wife, And I am repulsive to the children of my own body.", 
                "num": 17
              }, 
              {
                "text": "Even young children despise me; I arise, and they speak against me.", 
                "num": 18
              }, 
              {
                "text": "All my close friends abhor me, And those whom I love have turned against me.", 
                "num": 19
              }, 
              {
                "text": "My bone clings to my skin and to my flesh, And I have escaped by the skin of my teeth.", 
                "num": 20
              }, 
              {
                "text": "\"Have pity on me, have pity on me, O you my friends, For the hand of God has struck me!", 
                "num": 21
              }, 
              {
                "text": "Why do you persecute me as God does, And are not satisfied with my flesh?", 
                "num": 22
              }, 
              {
                "text": "\"Oh, that my words were written! Oh, that they were inscribed in a book!", 
                "num": 23
              }, 
              {
                "text": "That they were engraved on a rock With an iron pen and lead, forever!", 
                "num": 24
              }, 
              {
                "text": "For I know that my Redeemer lives, And He shall stand at last on the earth;", 
                "num": 25
              }, 
              {
                "text": "And after my skin is destroyed, this I know, That in my flesh I shall see God,", 
                "num": 26
              }, 
              {
                "text": "Whom I shall see for myself, And my eyes shall behold, and not another. How my heart yearns within me!", 
                "num": 27
              }, 
              {
                "text": "If you should say, \"How shall we persecute him?'-- Since the root of the matter is found in me,", 
                "num": 28
              }, 
              {
                "text": "Be afraid of the sword for yourselves; For wrath brings the punishment of the sword, That you may know there is a judgment.\"", 
                "num": 29
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Then Zophar the Naamathite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Therefore my anxious thoughts make me answer, Because of the turmoil within me.", 
                "num": 2
              }, 
              {
                "text": "I have heard the rebuke that reproaches me, And the spirit of my understanding causes me to answer.", 
                "num": 3
              }, 
              {
                "text": "\"Do you not know this of old, Since man was placed on earth,", 
                "num": 4
              }, 
              {
                "text": "That the triumphing of the wicked is short, And the joy of the hypocrite is but for a moment?", 
                "num": 5
              }, 
              {
                "text": "Though his haughtiness mounts up to the heavens, And his head reaches to the clouds,", 
                "num": 6
              }, 
              {
                "text": "Yet he will perish forever like his own refuse; Those who have seen him will say, \"Where is he?'", 
                "num": 7
              }, 
              {
                "text": "He will fly away like a dream, and not be found; Yes, he will be chased away like a vision of the night.", 
                "num": 8
              }, 
              {
                "text": "The eye that saw him will see him no more, Nor will his place behold him anymore.", 
                "num": 9
              }, 
              {
                "text": "His children will seek the favor of the poor, And his hands will restore his wealth.", 
                "num": 10
              }, 
              {
                "text": "His bones are full of his youthful vigor, But it will lie down with him in the dust.", 
                "num": 11
              }, 
              {
                "text": "\"Though evil is sweet in his mouth, And he hides it under his tongue,", 
                "num": 12
              }, 
              {
                "text": "Though he spares it and does not forsake it, But still keeps it in his mouth,", 
                "num": 13
              }, 
              {
                "text": "Yet his food in his stomach turns sour; It becomes cobra venom within him.", 
                "num": 14
              }, 
              {
                "text": "He swallows down riches And vomits them up again; God casts them out of his belly.", 
                "num": 15
              }, 
              {
                "text": "He will suck the poison of cobras; The viper's tongue will slay him.", 
                "num": 16
              }, 
              {
                "text": "He will not see the streams, The rivers flowing with honey and cream.", 
                "num": 17
              }, 
              {
                "text": "He will restore that for which he labored, And will not swallow it down; From the proceeds of business He will get no enjoyment.", 
                "num": 18
              }, 
              {
                "text": "For he has oppressed and forsaken the poor, He has violently seized a house which he did not build.", 
                "num": 19
              }, 
              {
                "text": "\"Because he knows no quietness in his heart, He will not save anything he desires.", 
                "num": 20
              }, 
              {
                "text": "Nothing is left for him to eat; Therefore his well-being will not last.", 
                "num": 21
              }, 
              {
                "text": "In his self-sufficiency he will be in distress; Every hand of misery will come against him.", 
                "num": 22
              }, 
              {
                "text": "When he is about to fill his stomach, God will cast on him the fury of His wrath, And will rain it on him while he is eating.", 
                "num": 23
              }, 
              {
                "text": "He will flee from the iron weapon; A bronze bow will pierce him through.", 
                "num": 24
              }, 
              {
                "text": "It is drawn, and comes out of the body; Yes, the glittering point comes out of his gall. Terrors come upon him;", 
                "num": 25
              }, 
              {
                "text": "Total darkness is reserved for his treasures. An unfanned fire will consume him; It shall go ill with him who is left in his tent.", 
                "num": 26
              }, 
              {
                "text": "The heavens will reveal his iniquity, And the earth will rise up against him.", 
                "num": 27
              }, 
              {
                "text": "The increase of his house will depart, And his goods will flow away in the day of His wrath.", 
                "num": 28
              }, 
              {
                "text": "This is the portion from God for a wicked man, The heritage appointed to him by God.\"", 
                "num": 29
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Listen carefully to my speech, And let this be your consolation.", 
                "num": 2
              }, 
              {
                "text": "Bear with me that I may speak, And after I have spoken, keep mocking.", 
                "num": 3
              }, 
              {
                "text": "\"As for me, is my complaint against man? And if it were, why should I not be impatient?", 
                "num": 4
              }, 
              {
                "text": "Look at me and be astonished; Put your hand over your mouth.", 
                "num": 5
              }, 
              {
                "text": "Even when I remember I am terrified, And trembling takes hold of my flesh.", 
                "num": 6
              }, 
              {
                "text": "Why do the wicked live and become old, Yes, become mighty in power?", 
                "num": 7
              }, 
              {
                "text": "Their descendants are established with them in their sight, And their offspring before their eyes.", 
                "num": 8
              }, 
              {
                "text": "Their houses are safe from fear, Neither is the rod of God upon them.", 
                "num": 9
              }, 
              {
                "text": "Their bull breeds without failure; Their cow calves without miscarriage.", 
                "num": 10
              }, 
              {
                "text": "They send forth their little ones like a flock, And their children dance.", 
                "num": 11
              }, 
              {
                "text": "They sing to the tambourine and harp, And rejoice to the sound of the flute.", 
                "num": 12
              }, 
              {
                "text": "They spend their days in wealth, And in a moment go down to the grave.", 
                "num": 13
              }, 
              {
                "text": "Yet they say to God, \"Depart from us, For we do not desire the knowledge of Your ways.", 
                "num": 14
              }, 
              {
                "text": "Who is the Almighty, that we should serve Him? And what profit do we have if we pray to Him?'", 
                "num": 15
              }, 
              {
                "text": "Indeed their prosperity is not in their hand; The counsel of the wicked is far from me.", 
                "num": 16
              }, 
              {
                "text": "\"How often is the lamp of the wicked put out? How often does their destruction come upon them, The sorrows God distributes in His anger?", 
                "num": 17
              }, 
              {
                "text": "They are like straw before the wind, And like chaff that a storm carries away.", 
                "num": 18
              }, 
              {
                "text": "They say, \"God lays up one's iniquity for his children'; Let Him recompense him, that he may know it.", 
                "num": 19
              }, 
              {
                "text": "Let his eyes see his destruction, And let him drink of the wrath of the Almighty.", 
                "num": 20
              }, 
              {
                "text": "For what does he care about his household after him, When the number of his months is cut in half?", 
                "num": 21
              }, 
              {
                "text": "\"Can anyone teach God knowledge, Since He judges those on high?", 
                "num": 22
              }, 
              {
                "text": "One dies in his full strength, Being wholly at ease and secure;", 
                "num": 23
              }, 
              {
                "text": "His pails are full of milk, And the marrow of his bones is moist.", 
                "num": 24
              }, 
              {
                "text": "Another man dies in the bitterness of his soul, Never having eaten with pleasure.", 
                "num": 25
              }, 
              {
                "text": "They lie down alike in the dust, And worms cover them.", 
                "num": 26
              }, 
              {
                "text": "\"Look, I know your thoughts, And the schemes with which you would wrong me.", 
                "num": 27
              }, 
              {
                "text": "For you say, \"Where is the house of the prince? And where is the tent, The dwelling place of the wicked?'", 
                "num": 28
              }, 
              {
                "text": "Have you not asked those who travel the road? And do you not know their signs?", 
                "num": 29
              }, 
              {
                "text": "For the wicked are reserved for the day of doom; They shall be brought out on the day of wrath.", 
                "num": 30
              }, 
              {
                "text": "Who condemns his way to his face? And who repays him for what he has done?", 
                "num": 31
              }, 
              {
                "text": "Yet he shall be brought to the grave, And a vigil kept over the tomb.", 
                "num": 32
              }, 
              {
                "text": "The clods of the valley shall be sweet to him; Everyone shall follow him, As countless have gone before him.", 
                "num": 33
              }, 
              {
                "text": "How then can you comfort me with empty words, Since falsehood remains in your answers?\"", 
                "num": 34
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Then Eliphaz the Temanite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Can a man be profitable to God, Though he who is wise may be profitable to himself?", 
                "num": 2
              }, 
              {
                "text": "Is it any pleasure to the Almighty that you are righteous? Or is it gain to Him that you make your ways blameless?", 
                "num": 3
              }, 
              {
                "text": "\"Is it because of your fear of Him that He corrects you, And enters into judgment with you?", 
                "num": 4
              }, 
              {
                "text": "Is not your wickedness great, And your iniquity without end?", 
                "num": 5
              }, 
              {
                "text": "For you have taken pledges from your brother for no reason, And stripped the naked of their clothing.", 
                "num": 6
              }, 
              {
                "text": "You have not given the weary water to drink, And you have withheld bread from the hungry.", 
                "num": 7
              }, 
              {
                "text": "But the mighty man possessed the land, And the honorable man dwelt in it.", 
                "num": 8
              }, 
              {
                "text": "You have sent widows away empty, And the strength of the fatherless was crushed.", 
                "num": 9
              }, 
              {
                "text": "Therefore snares are all around you, And sudden fear troubles you,", 
                "num": 10
              }, 
              {
                "text": "Or darkness so that you cannot see; And an abundance of water covers you.", 
                "num": 11
              }, 
              {
                "text": "\"Is not God in the height of heaven? And see the highest stars, how lofty they are!", 
                "num": 12
              }, 
              {
                "text": "And you say, \"What does God know? Can He judge through the deep darkness?", 
                "num": 13
              }, 
              {
                "text": "Thick clouds cover Him, so that He cannot see, And He walks above the circle of heaven.'", 
                "num": 14
              }, 
              {
                "text": "Will you keep to the old way Which wicked men have trod,", 
                "num": 15
              }, 
              {
                "text": "Who were cut down before their time, Whose foundations were swept away by a flood?", 
                "num": 16
              }, 
              {
                "text": "They said to God, \"Depart from us! What can the Almighty do to them?'", 
                "num": 17
              }, 
              {
                "text": "Yet He filled their houses with good things; But the counsel of the wicked is far from me.", 
                "num": 18
              }, 
              {
                "text": "\"The righteous see it and are glad, And the innocent laugh at them:", 
                "num": 19
              }, 
              {
                "text": "\"Surely our adversaries are cut down, And the fire consumes their remnant.'", 
                "num": 20
              }, 
              {
                "text": "\"Now acquaint yourself with Him, and be at peace; Thereby good will come to you.", 
                "num": 21
              }, 
              {
                "text": "Receive, please, instruction from His mouth, And lay up His words in your heart.", 
                "num": 22
              }, 
              {
                "text": "If you return to the Almighty, you will be built up; You will remove iniquity far from your tents.", 
                "num": 23
              }, 
              {
                "text": "Then you will lay your gold in the dust, And the gold of Ophir among the stones of the brooks.", 
                "num": 24
              }, 
              {
                "text": "Yes, the Almighty will be your gold And your precious silver;", 
                "num": 25
              }, 
              {
                "text": "For then you will have your delight in the Almighty, And lift up your face to God.", 
                "num": 26
              }, 
              {
                "text": "You will make your prayer to Him, He will hear you, And you will pay your vows.", 
                "num": 27
              }, 
              {
                "text": "You will also declare a thing, And it will be established for you; So light will shine on your ways.", 
                "num": 28
              }, 
              {
                "text": "When they cast you down, and you say, \"Exaltation will come!' Then He will save the humble person.", 
                "num": 29
              }, 
              {
                "text": "He will even deliver one who is not innocent; Yes, he will be delivered by the purity of your hands.\"", 
                "num": 30
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Even today my complaint is bitter; My hand is listless because of my groaning.", 
                "num": 2
              }, 
              {
                "text": "Oh, that I knew where I might find Him, That I might come to His seat!", 
                "num": 3
              }, 
              {
                "text": "I would present my case before Him, And fill my mouth with arguments.", 
                "num": 4
              }, 
              {
                "text": "I would know the words which He would answer me, And understand what He would say to me.", 
                "num": 5
              }, 
              {
                "text": "Would He contend with me in His great power? No! But He would take note of me.", 
                "num": 6
              }, 
              {
                "text": "There the upright could reason with Him, And I would be delivered forever from my Judge.", 
                "num": 7
              }, 
              {
                "text": "\"Look, I go forward, but He is not there, And backward, but I cannot perceive Him;", 
                "num": 8
              }, 
              {
                "text": "When He works on the left hand, I cannot behold Him; When He turns to the right hand, I cannot see Him.", 
                "num": 9
              }, 
              {
                "text": "But He knows the way that I take; When He has tested me, I shall come forth as gold.", 
                "num": 10
              }, 
              {
                "text": "My foot has held fast to His steps; I have kept His way and not turned aside.", 
                "num": 11
              }, 
              {
                "text": "I have not departed from the commandment of His lips; I have treasured the words of His mouth More than my necessary food.", 
                "num": 12
              }, 
              {
                "text": "\"But He is unique, and who can make Him change? And whatever His soul desires, that He does.", 
                "num": 13
              }, 
              {
                "text": "For He performs what is appointed for me, And many such things are with Him.", 
                "num": 14
              }, 
              {
                "text": "Therefore I am terrified at His presence; When I consider this, I am afraid of Him.", 
                "num": 15
              }, 
              {
                "text": "For God made my heart weak, And the Almighty terrifies me;", 
                "num": 16
              }, 
              {
                "text": "Because I was not cut off from the presence of darkness, And He did not hide deep darkness from my face.", 
                "num": 17
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "\"Since times are not hidden from the Almighty, Why do those who know Him see not His days?", 
                "num": 1
              }, 
              {
                "text": "\"Some remove landmarks; They seize flocks violently and feed on them;", 
                "num": 2
              }, 
              {
                "text": "They drive away the donkey of the fatherless; They take the widow's ox as a pledge.", 
                "num": 3
              }, 
              {
                "text": "They push the needy off the road; All the poor of the land are forced to hide.", 
                "num": 4
              }, 
              {
                "text": "Indeed, like wild donkeys in the desert, They go out to their work, searching for food. The wilderness yields food for them and for their children.", 
                "num": 5
              }, 
              {
                "text": "They gather their fodder in the field And glean in the vineyard of the wicked.", 
                "num": 6
              }, 
              {
                "text": "They spend the night naked, without clothing, And have no covering in the cold.", 
                "num": 7
              }, 
              {
                "text": "They are wet with the showers of the mountains, And huddle around the rock for want of shelter.", 
                "num": 8
              }, 
              {
                "text": "\"Some snatch the fatherless from the breast, And take a pledge from the poor.", 
                "num": 9
              }, 
              {
                "text": "They cause the poor to go naked, without clothing; And they take away the sheaves from the hungry.", 
                "num": 10
              }, 
              {
                "text": "They press out oil within their walls, And tread winepresses, yet suffer thirst.", 
                "num": 11
              }, 
              {
                "text": "The dying groan in the city, And the souls of the wounded cry out; Yet God does not charge them with wrong.", 
                "num": 12
              }, 
              {
                "text": "\"There are those who rebel against the light; They do not know its ways Nor abide in its paths.", 
                "num": 13
              }, 
              {
                "text": "The murderer rises with the light; He kills the poor and needy; And in the night he is like a thief.", 
                "num": 14
              }, 
              {
                "text": "The eye of the adulterer waits for the twilight, Saying, \"No eye will see me'; And he disguises his face.", 
                "num": 15
              }, 
              {
                "text": "In the dark they break into houses Which they marked for themselves in the daytime; They do not know the light.", 
                "num": 16
              }, 
              {
                "text": "For the morning is the same to them as the shadow of death; If someone recognizes them, They are in the terrors of the shadow of death.", 
                "num": 17
              }, 
              {
                "text": "\"They should be swift on the face of the waters, Their portion should be cursed in the earth, So that no one would turn into the way of their vineyards.", 
                "num": 18
              }, 
              {
                "text": "As drought and heat consume the snow waters, So the grave consumes those who have sinned.", 
                "num": 19
              }, 
              {
                "text": "The womb should forget him, The worm should feed sweetly on him; He should be remembered no more, And wickedness should be broken like a tree.", 
                "num": 20
              }, 
              {
                "text": "For he preys on the barren who do not bear, And does no good for the widow.", 
                "num": 21
              }, 
              {
                "text": "\"But God draws the mighty away with His power; He rises up, but no man is sure of life.", 
                "num": 22
              }, 
              {
                "text": "He gives them security, and they rely on it; Yet His eyes are on their ways.", 
                "num": 23
              }, 
              {
                "text": "They are exalted for a little while, Then they are gone. They are brought low; They are taken out of the way like all others; They dry out like the heads of grain.", 
                "num": 24
              }, 
              {
                "text": "\"Now if it is not so, who will prove me a liar, And make my speech worth nothing?\"", 
                "num": 25
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Then Bildad the Shuhite answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Dominion and fear belong to Him; He makes peace in His high places.", 
                "num": 2
              }, 
              {
                "text": "Is there any number to His armies? Upon whom does His light not rise?", 
                "num": 3
              }, 
              {
                "text": "How then can man be righteous before God? Or how can he be pure who is born of a woman?", 
                "num": 4
              }, 
              {
                "text": "If even the moon does not shine, And the stars are not pure in His sight,", 
                "num": 5
              }, 
              {
                "text": "How much less man, who is a maggot, And a son of man, who is a worm?\"", 
                "num": 6
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "But Job answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"How have you helped him who is without power? How have you saved the arm that has no strength?", 
                "num": 2
              }, 
              {
                "text": "How have you counseled one who has no wisdom? And how have you declared sound advice to many?", 
                "num": 3
              }, 
              {
                "text": "To whom have you uttered words? And whose spirit came from you?", 
                "num": 4
              }, 
              {
                "text": "\"The dead tremble, Those under the waters and those inhabiting them.", 
                "num": 5
              }, 
              {
                "text": "Sheol is naked before Him, And Destruction has no covering.", 
                "num": 6
              }, 
              {
                "text": "He stretches out the north over empty space; He hangs the earth on nothing.", 
                "num": 7
              }, 
              {
                "text": "He binds up the water in His thick clouds, Yet the clouds are not broken under it.", 
                "num": 8
              }, 
              {
                "text": "He covers the face of His throne, And spreads His cloud over it.", 
                "num": 9
              }, 
              {
                "text": "He drew a circular horizon on the face of the waters, At the boundary of light and darkness.", 
                "num": 10
              }, 
              {
                "text": "The pillars of heaven tremble, And are astonished at His rebuke.", 
                "num": 11
              }, 
              {
                "text": "He stirs up the sea with His power, And by His understanding He breaks up the storm.", 
                "num": 12
              }, 
              {
                "text": "By His Spirit He adorned the heavens; His hand pierced the fleeing serpent.", 
                "num": 13
              }, 
              {
                "text": "Indeed these are the mere edges of His ways, And how small a whisper we hear of Him! But the thunder of His power who can understand?\"", 
                "num": 14
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Moreover Job continued his discourse, and said:", 
                "num": 1
              }, 
              {
                "text": "\"As God lives, who has taken away my justice, And the Almighty, who has made my soul bitter,", 
                "num": 2
              }, 
              {
                "text": "As long as my breath is in me, And the breath of God in my nostrils,", 
                "num": 3
              }, 
              {
                "text": "My lips will not speak wickedness, Nor my tongue utter deceit.", 
                "num": 4
              }, 
              {
                "text": "Far be it from me That I should say you are right; Till I die I will not put away my integrity from me.", 
                "num": 5
              }, 
              {
                "text": "My righteousness I hold fast, and will not let it go; My heart shall not reproach me as long as I live.", 
                "num": 6
              }, 
              {
                "text": "\"May my enemy be like the wicked, And he who rises up against me like the unrighteous.", 
                "num": 7
              }, 
              {
                "text": "For what is the hope of the hypocrite, Though he may gain much, If God takes away his life?", 
                "num": 8
              }, 
              {
                "text": "Will God hear his cry When trouble comes upon him?", 
                "num": 9
              }, 
              {
                "text": "Will he delight himself in the Almighty? Will he always call on God?", 
                "num": 10
              }, 
              {
                "text": "\"I will teach you about the hand of God; What is with the Almighty I will not conceal.", 
                "num": 11
              }, 
              {
                "text": "Surely all of you have seen it; Why then do you behave with complete nonsense?", 
                "num": 12
              }, 
              {
                "text": "\"This is the portion of a wicked man with God, And the heritage of oppressors, received from the Almighty:", 
                "num": 13
              }, 
              {
                "text": "If his children are multiplied, it is for the sword; And his offspring shall not be satisfied with bread.", 
                "num": 14
              }, 
              {
                "text": "Those who survive him shall be buried in death, And their widows shall not weep,", 
                "num": 15
              }, 
              {
                "text": "Though he heaps up silver like dust, And piles up clothing like clay--", 
                "num": 16
              }, 
              {
                "text": "He may pile it up, but the just will wear it, And the innocent will divide the silver.", 
                "num": 17
              }, 
              {
                "text": "He builds his house like a moth, Like a booth which a watchman makes.", 
                "num": 18
              }, 
              {
                "text": "The rich man will lie down, But not be gathered up; He opens his eyes, And he is no more.", 
                "num": 19
              }, 
              {
                "text": "Terrors overtake him like a flood; A tempest steals him away in the night.", 
                "num": 20
              }, 
              {
                "text": "The east wind carries him away, and he is gone; It sweeps him out of his place.", 
                "num": 21
              }, 
              {
                "text": "It hurls against him and does not spare; He flees desperately from its power.", 
                "num": 22
              }, 
              {
                "text": "Men shall clap their hands at him, And shall hiss him out of his place.", 
                "num": 23
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "\"Surely there is a mine for silver, And a place where gold is refined.", 
                "num": 1
              }, 
              {
                "text": "Iron is taken from the earth, And copper is smelted from ore.", 
                "num": 2
              }, 
              {
                "text": "Man puts an end to darkness, And searches every recess For ore in the darkness and the shadow of death.", 
                "num": 3
              }, 
              {
                "text": "He breaks open a shaft away from people; In places forgotten by feet They hang far away from men; They swing to and fro.", 
                "num": 4
              }, 
              {
                "text": "As for the earth, from it comes bread, But underneath it is turned up as by fire;", 
                "num": 5
              }, 
              {
                "text": "Its stones are the source of sapphires, And it contains gold dust.", 
                "num": 6
              }, 
              {
                "text": "That path no bird knows, Nor has the falcon's eye seen it.", 
                "num": 7
              }, 
              {
                "text": "The proud lions have not trodden it, Nor has the fierce lion passed over it.", 
                "num": 8
              }, 
              {
                "text": "He puts his hand on the flint; He overturns the mountains at the roots.", 
                "num": 9
              }, 
              {
                "text": "He cuts out channels in the rocks, And his eye sees every precious thing.", 
                "num": 10
              }, 
              {
                "text": "He dams up the streams from trickling; What is hidden he brings forth to light.", 
                "num": 11
              }, 
              {
                "text": "\"But where can wisdom be found? And where is the place of understanding?", 
                "num": 12
              }, 
              {
                "text": "Man does not know its value, Nor is it found in the land of the living.", 
                "num": 13
              }, 
              {
                "text": "The deep says, \"It is not in me'; And the sea says, \"It is not with me.'", 
                "num": 14
              }, 
              {
                "text": "It cannot be purchased for gold, Nor can silver be weighed for its price.", 
                "num": 15
              }, 
              {
                "text": "It cannot be valued in the gold of Ophir, In precious onyx or sapphire.", 
                "num": 16
              }, 
              {
                "text": "Neither gold nor crystal can equal it, Nor can it be exchanged for jewelry of fine gold.", 
                "num": 17
              }, 
              {
                "text": "No mention shall be made of coral or quartz, For the price of wisdom is above rubies.", 
                "num": 18
              }, 
              {
                "text": "The topaz of Ethiopia cannot equal it, Nor can it be valued in pure gold.", 
                "num": 19
              }, 
              {
                "text": "\"From where then does wisdom come? And where is the place of understanding?", 
                "num": 20
              }, 
              {
                "text": "It is hidden from the eyes of all living, And concealed from the birds of the air.", 
                "num": 21
              }, 
              {
                "text": "Destruction and Death say, \"We have heard a report about it with our ears.'", 
                "num": 22
              }, 
              {
                "text": "God understands its way, And He knows its place.", 
                "num": 23
              }, 
              {
                "text": "For He looks to the ends of the earth, And sees under the whole heavens,", 
                "num": 24
              }, 
              {
                "text": "To establish a weight for the wind, And apportion the waters by measure.", 
                "num": 25
              }, 
              {
                "text": "When He made a law for the rain, And a path for the thunderbolt,", 
                "num": 26
              }, 
              {
                "text": "Then He saw wisdom and declared it; He prepared it, indeed, He searched it out.", 
                "num": 27
              }, 
              {
                "text": "And to man He said, \"Behold, the fear of the Lord, that is wisdom, And to depart from evil is understanding.\"'", 
                "num": 28
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Job further continued his discourse, and said:", 
                "num": 1
              }, 
              {
                "text": "\"Oh, that I were as in months past, As in the days when God watched over me;", 
                "num": 2
              }, 
              {
                "text": "When His lamp shone upon my head, And when by His light I walked through darkness;", 
                "num": 3
              }, 
              {
                "text": "Just as I was in the days of my prime, When the friendly counsel of God was over my tent;", 
                "num": 4
              }, 
              {
                "text": "When the Almighty was yet with me, When my children were around me;", 
                "num": 5
              }, 
              {
                "text": "When my steps were bathed with cream, And the rock poured out rivers of oil for me!", 
                "num": 6
              }, 
              {
                "text": "\"When I went out to the gate by the city, When I took my seat in the open square,", 
                "num": 7
              }, 
              {
                "text": "The young men saw me and hid, And the aged arose and stood;", 
                "num": 8
              }, 
              {
                "text": "The princes refrained from talking, And put their hand on their mouth;", 
                "num": 9
              }, 
              {
                "text": "The voice of nobles was hushed, And their tongue stuck to the roof of their mouth.", 
                "num": 10
              }, 
              {
                "text": "When the ear heard, then it blessed me, And when the eye saw, then it approved me;", 
                "num": 11
              }, 
              {
                "text": "Because I delivered the poor who cried out, The fatherless and the one who had no helper.", 
                "num": 12
              }, 
              {
                "text": "The blessing of a perishing man came upon me, And I caused the widow's heart to sing for joy.", 
                "num": 13
              }, 
              {
                "text": "I put on righteousness, and it clothed me; My justice was like a robe and a turban.", 
                "num": 14
              }, 
              {
                "text": "I was eyes to the blind, And I was feet to the lame.", 
                "num": 15
              }, 
              {
                "text": "I was a father to the poor, And I searched out the case that I did not know.", 
                "num": 16
              }, 
              {
                "text": "I broke the fangs of the wicked, And plucked the victim from his teeth.", 
                "num": 17
              }, 
              {
                "text": "\"Then I said, \"I shall die in my nest, And multiply my days as the sand.", 
                "num": 18
              }, 
              {
                "text": "My root is spread out to the waters, And the dew lies all night on my branch.", 
                "num": 19
              }, 
              {
                "text": "My glory is fresh within me, And my bow is renewed in my hand.'", 
                "num": 20
              }, 
              {
                "text": "\"Men listened to me and waited, And kept silence for my counsel.", 
                "num": 21
              }, 
              {
                "text": "After my words they did not speak again, And my speech settled on them as dew.", 
                "num": 22
              }, 
              {
                "text": "They waited for me as for the rain, And they opened their mouth wide as for the spring rain.", 
                "num": 23
              }, 
              {
                "text": "If I mocked at them, they did not believe it, And the light of my countenance they did not cast down.", 
                "num": 24
              }, 
              {
                "text": "I chose the way for them, and sat as chief; So I dwelt as a king in the army, As one who comforts mourners.", 
                "num": 25
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "\"But now they mock at me, men younger than I, Whose fathers I disdained to put with the dogs of my flock.", 
                "num": 1
              }, 
              {
                "text": "Indeed, what profit is the strength of their hands to me? Their vigor has perished.", 
                "num": 2
              }, 
              {
                "text": "They are gaunt from want and famine, Fleeing late to the wilderness, desolate and waste,", 
                "num": 3
              }, 
              {
                "text": "Who pluck mallow by the bushes, And broom tree roots for their food.", 
                "num": 4
              }, 
              {
                "text": "They were driven out from among men, They shouted at them as at a thief.", 
                "num": 5
              }, 
              {
                "text": "They had to live in the clefts of the valleys, In caves of the earth and the rocks.", 
                "num": 6
              }, 
              {
                "text": "Among the bushes they brayed, Under the nettles they nestled.", 
                "num": 7
              }, 
              {
                "text": "They were sons of fools, Yes, sons of vile men; They were scourged from the land.", 
                "num": 8
              }, 
              {
                "text": "\"And now I am their taunting song; Yes, I am their byword.", 
                "num": 9
              }, 
              {
                "text": "They abhor me, they keep far from me; They do not hesitate to spit in my face.", 
                "num": 10
              }, 
              {
                "text": "Because He has loosed my bowstring and afflicted me, They have cast off restraint before me.", 
                "num": 11
              }, 
              {
                "text": "At my right hand the rabble arises; They push away my feet, And they raise against me their ways of destruction.", 
                "num": 12
              }, 
              {
                "text": "They break up my path, They promote my calamity; They have no helper.", 
                "num": 13
              }, 
              {
                "text": "They come as broad breakers; Under the ruinous storm they roll along.", 
                "num": 14
              }, 
              {
                "text": "Terrors are turned upon me; They pursue my honor as the wind, And my prosperity has passed like a cloud.", 
                "num": 15
              }, 
              {
                "text": "\"And now my soul is poured out because of my plight; The days of affliction take hold of me.", 
                "num": 16
              }, 
              {
                "text": "My bones are pierced in me at night, And my gnawing pains take no rest.", 
                "num": 17
              }, 
              {
                "text": "By great force my garment is disfigured; It binds me about as the collar of my coat.", 
                "num": 18
              }, 
              {
                "text": "He has cast me into the mire, And I have become like dust and ashes.", 
                "num": 19
              }, 
              {
                "text": "\"I cry out to You, but You do not answer me; I stand up, and You regard me.", 
                "num": 20
              }, 
              {
                "text": "But You have become cruel to me; With the strength of Your hand You oppose me.", 
                "num": 21
              }, 
              {
                "text": "You lift me up to the wind and cause me to ride on it; You spoil my success.", 
                "num": 22
              }, 
              {
                "text": "For I know that You will bring me to death, And to the house appointed for all living.", 
                "num": 23
              }, 
              {
                "text": "\"Surely He would not stretch out His hand against a heap of ruins, If they cry out when He destroys it.", 
                "num": 24
              }, 
              {
                "text": "Have I not wept for him who was in trouble? Has not my soul grieved for the poor?", 
                "num": 25
              }, 
              {
                "text": "But when I looked for good, evil came to me; And when I waited for light, then came darkness.", 
                "num": 26
              }, 
              {
                "text": "My heart is in turmoil and cannot rest; Days of affliction confront me.", 
                "num": 27
              }, 
              {
                "text": "I go about mourning, but not in the sun; I stand up in the assembly and cry out for help.", 
                "num": 28
              }, 
              {
                "text": "I am a brother of jackals, And a companion of ostriches.", 
                "num": 29
              }, 
              {
                "text": "My skin grows black and falls from me; My bones burn with fever.", 
                "num": 30
              }, 
              {
                "text": "My harp is turned to mourning, And my flute to the voice of those who weep.", 
                "num": 31
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "\"I have made a covenant with my eyes; Why then should I look upon a young woman?", 
                "num": 1
              }, 
              {
                "text": "For what is the allotment of God from above, And the inheritance of the Almighty from on high?", 
                "num": 2
              }, 
              {
                "text": "Is it not destruction for the wicked, And disaster for the workers of iniquity?", 
                "num": 3
              }, 
              {
                "text": "Does He not see my ways, And count all my steps?", 
                "num": 4
              }, 
              {
                "text": "\"If I have walked with falsehood, Or if my foot has hastened to deceit,", 
                "num": 5
              }, 
              {
                "text": "Let me be weighed on honest scales, That God may know my integrity.", 
                "num": 6
              }, 
              {
                "text": "If my step has turned from the way, Or my heart walked after my eyes, Or if any spot adheres to my hands,", 
                "num": 7
              }, 
              {
                "text": "Then let me sow, and another eat; Yes, let my harvest be rooted out.", 
                "num": 8
              }, 
              {
                "text": "\"If my heart has been enticed by a woman, Or if I have lurked at my neighbor's door,", 
                "num": 9
              }, 
              {
                "text": "Then let my wife grind for another, And let others bow down over her.", 
                "num": 10
              }, 
              {
                "text": "For that would be wickedness; Yes, it would be iniquity deserving of judgment.", 
                "num": 11
              }, 
              {
                "text": "For that would be a fire that consumes to destruction, And would root out all my increase.", 
                "num": 12
              }, 
              {
                "text": "\"If I have despised the cause of my male or female servant When they complained against me,", 
                "num": 13
              }, 
              {
                "text": "What then shall I do when God rises up? When He punishes, how shall I answer Him?", 
                "num": 14
              }, 
              {
                "text": "Did not He who made me in the womb make them? Did not the same One fashion us in the womb?", 
                "num": 15
              }, 
              {
                "text": "\"If I have kept the poor from their desire, Or caused the eyes of the widow to fail,", 
                "num": 16
              }, 
              {
                "text": "Or eaten my morsel by myself, So that the fatherless could not eat of it", 
                "num": 17
              }, 
              {
                "text": "(But from my youth I reared him as a father, And from my mother's womb I guided the widow);", 
                "num": 18
              }, 
              {
                "text": "If I have seen anyone perish for lack of clothing, Or any poor man without covering;", 
                "num": 19
              }, 
              {
                "text": "If his heart has not blessed me, And if he was not warmed with the fleece of my sheep;", 
                "num": 20
              }, 
              {
                "text": "If I have raised my hand against the fatherless, When I saw I had help in the gate;", 
                "num": 21
              }, 
              {
                "text": "Then let my arm fall from my shoulder, Let my arm be torn from the socket.", 
                "num": 22
              }, 
              {
                "text": "For destruction from God is a terror to me, And because of His magnificence I cannot endure.", 
                "num": 23
              }, 
              {
                "text": "\"If I have made gold my hope, Or said to fine gold, \"You are my confidence';", 
                "num": 24
              }, 
              {
                "text": "If I have rejoiced because my wealth was great, And because my hand had gained much;", 
                "num": 25
              }, 
              {
                "text": "If I have observed the sun when it shines, Or the moon moving in brightness,", 
                "num": 26
              }, 
              {
                "text": "So that my heart has been secretly enticed, And my mouth has kissed my hand;", 
                "num": 27
              }, 
              {
                "text": "This also would be an iniquity deserving of judgment, For I would have denied God who is above.", 
                "num": 28
              }, 
              {
                "text": "\"If I have rejoiced at the destruction of him who hated me, Or lifted myself up when evil found him", 
                "num": 29
              }, 
              {
                "text": "(Indeed I have not allowed my mouth to sin By asking for a curse on his soul);", 
                "num": 30
              }, 
              {
                "text": "If the men of my tent have not said, \"Who is there that has not been satisfied with his meat?'", 
                "num": 31
              }, 
              {
                "text": "(But no sojourner had to lodge in the street, For I have opened my doors to the traveler);", 
                "num": 32
              }, 
              {
                "text": "If I have covered my transgressions as Adam, By hiding my iniquity in my bosom,", 
                "num": 33
              }, 
              {
                "text": "Because I feared the great multitude, And dreaded the contempt of families, So that I kept silence And did not go out of the door--", 
                "num": 34
              }, 
              {
                "text": "Oh, that I had one to hear me! Here is my mark. Oh, that the Almighty would answer me, That my Prosecutor had written a book!", 
                "num": 35
              }, 
              {
                "text": "Surely I would carry it on my shoulder, And bind it on me like a crown;", 
                "num": 36
              }, 
              {
                "text": "I would declare to Him the number of my steps; Like a prince I would approach Him.", 
                "num": 37
              }, 
              {
                "text": "\"If my land cries out against me, And its furrows weep together;", 
                "num": 38
              }, 
              {
                "text": "If I have eaten its fruit without money, Or caused its owners to lose their lives;", 
                "num": 39
              }, 
              {
                "text": "Then let thistles grow instead of wheat, And weeds instead of barley.\" The words of Job are ended.", 
                "num": 40
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "So these three men ceased answering Job, because he was righteous in his own eyes.", 
                "num": 1
              }, 
              {
                "text": "Then the wrath of Elihu, the son of Barachel the Buzite, of the family of Ram, was aroused against Job; his wrath was aroused because he justified himself rather than God.", 
                "num": 2
              }, 
              {
                "text": "Also against his three friends his wrath was aroused, because they had found no answer, and yet had condemned Job.", 
                "num": 3
              }, 
              {
                "text": "Now because they were years older than he, Elihu had waited to speak to Job.", 
                "num": 4
              }, 
              {
                "text": "When Elihu saw that there was no answer in the mouth of these three men, his wrath was aroused.", 
                "num": 5
              }, 
              {
                "text": "So Elihu, the son of Barachel the Buzite, answered and said: \"I am young in years, and you are very old; Therefore I was afraid, And dared not declare my opinion to you.", 
                "num": 6
              }, 
              {
                "text": "I said, \"Age should speak, And multitude of years should teach wisdom.'", 
                "num": 7
              }, 
              {
                "text": "But there is a spirit in man, And the breath of the Almighty gives him understanding.", 
                "num": 8
              }, 
              {
                "text": "Great men are not always wise, Nor do the aged always understand justice.", 
                "num": 9
              }, 
              {
                "text": "\"Therefore I say, \"Listen to me, I also will declare my opinion.'", 
                "num": 10
              }, 
              {
                "text": "Indeed I waited for your words, I listened to your reasonings, while you searched out what to say.", 
                "num": 11
              }, 
              {
                "text": "I paid close attention to you; And surely not one of you convinced Job, Or answered his words--", 
                "num": 12
              }, 
              {
                "text": "Lest you say, \"We have found wisdom'; God will vanquish him, not man.", 
                "num": 13
              }, 
              {
                "text": "Now he has not directed his words against me; So I will not answer him with your words.", 
                "num": 14
              }, 
              {
                "text": "\"They are dismayed and answer no more; Words escape them.", 
                "num": 15
              }, 
              {
                "text": "And I have waited, because they did not speak, Because they stood still and answered no more.", 
                "num": 16
              }, 
              {
                "text": "I also will answer my part, I too will declare my opinion.", 
                "num": 17
              }, 
              {
                "text": "For I am full of words; The spirit within me compels me.", 
                "num": 18
              }, 
              {
                "text": "Indeed my belly is like wine that has no vent; It is ready to burst like new wineskins.", 
                "num": 19
              }, 
              {
                "text": "I will speak, that I may find relief; I must open my lips and answer.", 
                "num": 20
              }, 
              {
                "text": "Let me not, I pray, show partiality to anyone; Nor let me flatter any man.", 
                "num": 21
              }, 
              {
                "text": "For I do not know how to flatter, Else my Maker would soon take me away.", 
                "num": 22
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "\"But please, Job, hear my speech, And listen to all my words.", 
                "num": 1
              }, 
              {
                "text": "Now, I open my mouth; My tongue speaks in my mouth.", 
                "num": 2
              }, 
              {
                "text": "My words come from my upright heart; My lips utter pure knowledge.", 
                "num": 3
              }, 
              {
                "text": "The Spirit of God has made me, And the breath of the Almighty gives me life.", 
                "num": 4
              }, 
              {
                "text": "If you can answer me, Set your words in order before me; Take your stand.", 
                "num": 5
              }, 
              {
                "text": "Truly I am as your spokesman before God; I also have been formed out of clay.", 
                "num": 6
              }, 
              {
                "text": "Surely no fear of me will terrify you, Nor will my hand be heavy on you.", 
                "num": 7
              }, 
              {
                "text": "\"Surely you have spoken in my hearing, And I have heard the sound of your words, saying,", 
                "num": 8
              }, 
              {
                "text": "\"I am pure, without transgression; I am innocent, and there is no iniquity in me.", 
                "num": 9
              }, 
              {
                "text": "Yet He finds occasions against me, He counts me as His enemy;", 
                "num": 10
              }, 
              {
                "text": "He puts my feet in the stocks, He watches all my paths.'", 
                "num": 11
              }, 
              {
                "text": "\"Look, in this you are not righteous. I will answer you, For God is greater than man.", 
                "num": 12
              }, 
              {
                "text": "Why do you contend with Him? For He does not give an accounting of any of His words.", 
                "num": 13
              }, 
              {
                "text": "For God may speak in one way, or in another, Yet man does not perceive it.", 
                "num": 14
              }, 
              {
                "text": "In a dream, in a vision of the night, When deep sleep falls upon men, While slumbering on their beds,", 
                "num": 15
              }, 
              {
                "text": "Then He opens the ears of men, And seals their instruction.", 
                "num": 16
              }, 
              {
                "text": "In order to turn man from his deed, And conceal pride from man,", 
                "num": 17
              }, 
              {
                "text": "He keeps back his soul from the Pit, And his life from perishing by the sword.", 
                "num": 18
              }, 
              {
                "text": "\"Man is also chastened with pain on his bed, And with strong pain in many of his bones,", 
                "num": 19
              }, 
              {
                "text": "So that his life abhors bread, And his soul succulent food.", 
                "num": 20
              }, 
              {
                "text": "His flesh wastes away from sight, And his bones stick out which once were not seen.", 
                "num": 21
              }, 
              {
                "text": "Yes, his soul draws near the Pit, And his life to the executioners.", 
                "num": 22
              }, 
              {
                "text": "\"If there is a messenger for him, A mediator, one among a thousand, To show man His uprightness,", 
                "num": 23
              }, 
              {
                "text": "Then He is gracious to him, and says, \"Deliver him from going down to the Pit; I have found a ransom';", 
                "num": 24
              }, 
              {
                "text": "His flesh shall be young like a child's, He shall return to the days of his youth.", 
                "num": 25
              }, 
              {
                "text": "He shall pray to God, and He will delight in him, He shall see His face with joy, For He restores to man His righteousness.", 
                "num": 26
              }, 
              {
                "text": "Then he looks at men and says, \"I have sinned, and perverted what was right, And it did not profit me.'", 
                "num": 27
              }, 
              {
                "text": "He will redeem his soul from going down to the Pit, And his life shall see the light.", 
                "num": 28
              }, 
              {
                "text": "\"Behold, God works all these things, Twice, in fact, three times with a man,", 
                "num": 29
              }, 
              {
                "text": "To bring back his soul from the Pit, That he may be enlightened with the light of life.", 
                "num": 30
              }, 
              {
                "text": "\"Give ear, Job, listen to me; Hold your peace, and I will speak.", 
                "num": 31
              }, 
              {
                "text": "If you have anything to say, answer me; Speak, for I desire to justify you.", 
                "num": 32
              }, 
              {
                "text": "If not, listen to me; Hold your peace, and I will teach you wisdom.\"", 
                "num": 33
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Elihu further answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Hear my words, you wise men; Give ear to me, you who have knowledge.", 
                "num": 2
              }, 
              {
                "text": "For the ear tests words As the palate tastes food.", 
                "num": 3
              }, 
              {
                "text": "Let us choose justice for ourselves; Let us know among ourselves what is good.", 
                "num": 4
              }, 
              {
                "text": "\"For Job has said, \"I am righteous, But God has taken away my justice;", 
                "num": 5
              }, 
              {
                "text": "Should I lie concerning my right? My wound is incurable, though I am without transgression.'", 
                "num": 6
              }, 
              {
                "text": "What man is like Job, Who drinks scorn like water,", 
                "num": 7
              }, 
              {
                "text": "Who goes in company with the workers of iniquity, And walks with wicked men?", 
                "num": 8
              }, 
              {
                "text": "For he has said, \"It profits a man nothing That he should delight in God.'", 
                "num": 9
              }, 
              {
                "text": "\"Therefore listen to me, you men of understanding: Far be it from God to do wickedness, And from the Almighty to commit iniquity.", 
                "num": 10
              }, 
              {
                "text": "For He repays man according to his work, And makes man to find a reward according to his way.", 
                "num": 11
              }, 
              {
                "text": "Surely God will never do wickedly, Nor will the Almighty pervert justice.", 
                "num": 12
              }, 
              {
                "text": "Who gave Him charge over the earth? Or who appointed Him over the whole world?", 
                "num": 13
              }, 
              {
                "text": "If He should set His heart on it, If He should gather to Himself His Spirit and His breath,", 
                "num": 14
              }, 
              {
                "text": "All flesh would perish together, And man would return to dust.", 
                "num": 15
              }, 
              {
                "text": "\"If you have understanding, hear this; Listen to the sound of my words:", 
                "num": 16
              }, 
              {
                "text": "Should one who hates justice govern? Will you condemn Him who is most just?", 
                "num": 17
              }, 
              {
                "text": "Is it fitting to say to a king, \"You are worthless,' And to nobles, \"You are wicked'?", 
                "num": 18
              }, 
              {
                "text": "Yet He is not partial to princes, Nor does He regard the rich more than the poor; For they are all the work of His hands.", 
                "num": 19
              }, 
              {
                "text": "In a moment they die, in the middle of the night; The people are shaken and pass away; The mighty are taken away without a hand.", 
                "num": 20
              }, 
              {
                "text": "\"For His eyes are on the ways of man, And He sees all his steps.", 
                "num": 21
              }, 
              {
                "text": "There is no darkness nor shadow of death Where the workers of iniquity may hide themselves.", 
                "num": 22
              }, 
              {
                "text": "For He need not further consider a man, That he should go before God in judgment.", 
                "num": 23
              }, 
              {
                "text": "He breaks in pieces mighty men without inquiry, And sets others in their place.", 
                "num": 24
              }, 
              {
                "text": "Therefore He knows their works; He overthrows them in the night, And they are crushed.", 
                "num": 25
              }, 
              {
                "text": "He strikes them as wicked men In the open sight of others,", 
                "num": 26
              }, 
              {
                "text": "Because they turned back from Him, And would not consider any of His ways,", 
                "num": 27
              }, 
              {
                "text": "So that they caused the cry of the poor to come to Him; For He hears the cry of the afflicted.", 
                "num": 28
              }, 
              {
                "text": "When He gives quietness, who then can make trouble? And when He hides His face, who then can see Him, Whether it is against a nation or a man alone?--", 
                "num": 29
              }, 
              {
                "text": "That the hypocrite should not reign, Lest the people be ensnared.", 
                "num": 30
              }, 
              {
                "text": "\"For has anyone said to God, \"I have borne chastening; I will offend no more;", 
                "num": 31
              }, 
              {
                "text": "Teach me what I do not see; If I have done iniquity, I will do no more'?", 
                "num": 32
              }, 
              {
                "text": "Should He repay it according to your terms, Just because you disavow it? You must choose, and not I; Therefore speak what you know.", 
                "num": 33
              }, 
              {
                "text": "\"Men of understanding say to me, Wise men who listen to me:", 
                "num": 34
              }, 
              {
                "text": "\"Job speaks without knowledge, His words are without wisdom.'", 
                "num": 35
              }, 
              {
                "text": "Oh, that Job were tried to the utmost, Because his answers are like those of wicked men!", 
                "num": 36
              }, 
              {
                "text": "For he adds rebellion to his sin; He claps his hands among us, And multiplies his words against God.\"", 
                "num": 37
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Moreover Elihu answered and said:", 
                "num": 1
              }, 
              {
                "text": "\"Do you think this is right? Do you say, \"My righteousness is more than God's'?", 
                "num": 2
              }, 
              {
                "text": "For you say, \"What advantage will it be to You? What profit shall I have, more than if I had sinned?'", 
                "num": 3
              }, 
              {
                "text": "\"I will answer you, And your companions with you.", 
                "num": 4
              }, 
              {
                "text": "Look to the heavens and see; And behold the clouds-- They are higher than you.", 
                "num": 5
              }, 
              {
                "text": "If you sin, what do you accomplish against Him? Or, if your transgressions are multiplied, what do you do to Him?", 
                "num": 6
              }, 
              {
                "text": "If you are righteous, what do you give Him? Or what does He receive from your hand?", 
                "num": 7
              }, 
              {
                "text": "Your wickedness affects a man such as you, And your righteousness a son of man.", 
                "num": 8
              }, 
              {
                "text": "\"Because of the multitude of oppressions they cry out; They cry out for help because of the arm of the mighty.", 
                "num": 9
              }, 
              {
                "text": "But no one says, \"Where is God my Maker, Who gives songs in the night,", 
                "num": 10
              }, 
              {
                "text": "Who teaches us more than the beasts of the earth, And makes us wiser than the birds of heaven?'", 
                "num": 11
              }, 
              {
                "text": "There they cry out, but He does not answer, Because of the pride of evil men.", 
                "num": 12
              }, 
              {
                "text": "Surely God will not listen to empty talk, Nor will the Almighty regard it.", 
                "num": 13
              }, 
              {
                "text": "Although you say you do not see Him, Yet justice is before Him, and you must wait for Him.", 
                "num": 14
              }, 
              {
                "text": "And now, because He has not punished in His anger, Nor taken much notice of folly,", 
                "num": 15
              }, 
              {
                "text": "Therefore Job opens his mouth in vain; He multiplies words without knowledge.\"", 
                "num": 16
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Elihu also proceeded and said:", 
                "num": 1
              }, 
              {
                "text": "\"Bear with me a little, and I will show you That there are yet words to speak on God's behalf.", 
                "num": 2
              }, 
              {
                "text": "I will fetch my knowledge from afar; I will ascribe righteousness to my Maker.", 
                "num": 3
              }, 
              {
                "text": "For truly my words are not false; One who is perfect in knowledge is with you.", 
                "num": 4
              }, 
              {
                "text": "\"Behold, God is mighty, but despises no one; He is mighty in strength of understanding.", 
                "num": 5
              }, 
              {
                "text": "He does not preserve the life of the wicked, But gives justice to the oppressed.", 
                "num": 6
              }, 
              {
                "text": "He does not withdraw His eyes from the righteous; But they are on the throne with kings, For He has seated them forever, And they are exalted.", 
                "num": 7
              }, 
              {
                "text": "And if they are bound in fetters, Held in the cords of affliction,", 
                "num": 8
              }, 
              {
                "text": "Then He tells them their work and their transgressions-- That they have acted defiantly.", 
                "num": 9
              }, 
              {
                "text": "He also opens their ear to instruction, And commands that they turn from iniquity.", 
                "num": 10
              }, 
              {
                "text": "If they obey and serve Him, They shall spend their days in prosperity, And their years in pleasures.", 
                "num": 11
              }, 
              {
                "text": "But if they do not obey, They shall perish by the sword, And they shall die without knowledge.", 
                "num": 12
              }, 
              {
                "text": "\"But the hypocrites in heart store up wrath; They do not cry for help when He binds them.", 
                "num": 13
              }, 
              {
                "text": "They die in youth, And their life ends among the perverted persons.", 
                "num": 14
              }, 
              {
                "text": "He delivers the poor in their affliction, And opens their ears in oppression.", 
                "num": 15
              }, 
              {
                "text": "\"Indeed He would have brought you out of dire distress, Into a broad place where there is no restraint; And what is set on your table would be full of richness.", 
                "num": 16
              }, 
              {
                "text": "But you are filled with the judgment due the wicked; Judgment and justice take hold of you.", 
                "num": 17
              }, 
              {
                "text": "Because there is wrath, beware lest He take you away with one blow; For a large ransom would not help you avoid it.", 
                "num": 18
              }, 
              {
                "text": "Will your riches, Or all the mighty forces, Keep you from distress?", 
                "num": 19
              }, 
              {
                "text": "Do not desire the night, When people are cut off in their place.", 
                "num": 20
              }, 
              {
                "text": "Take heed, do not turn to iniquity, For you have chosen this rather than affliction.", 
                "num": 21
              }, 
              {
                "text": "\"Behold, God is exalted by His power; Who teaches like Him?", 
                "num": 22
              }, 
              {
                "text": "Who has assigned Him His way, Or who has said, \"You have done wrong'?", 
                "num": 23
              }, 
              {
                "text": "\"Remember to magnify His work, Of which men have sung.", 
                "num": 24
              }, 
              {
                "text": "Everyone has seen it; Man looks on it from afar.", 
                "num": 25
              }, 
              {
                "text": "\"Behold, God is great, and we do not know Him; Nor can the number of His years be discovered.", 
                "num": 26
              }, 
              {
                "text": "For He draws up drops of water, Which distill as rain from the mist,", 
                "num": 27
              }, 
              {
                "text": "Which the clouds drop down And pour abundantly on man.", 
                "num": 28
              }, 
              {
                "text": "Indeed, can anyone understand the spreading of clouds, The thunder from His canopy?", 
                "num": 29
              }, 
              {
                "text": "Look, He scatters His light upon it, And covers the depths of the sea.", 
                "num": 30
              }, 
              {
                "text": "For by these He judges the peoples; He gives food in abundance.", 
                "num": 31
              }, 
              {
                "text": "He covers His hands with lightning, And commands it to strike.", 
                "num": 32
              }, 
              {
                "text": "His thunder declares it, The cattle also, concerning the rising storm.", 
                "num": 33
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "\"At this also my heart trembles, And leaps from its place.", 
                "num": 1
              }, 
              {
                "text": "Hear attentively the thunder of His voice, And the rumbling that comes from His mouth.", 
                "num": 2
              }, 
              {
                "text": "He sends it forth under the whole heaven, His lightning to the ends of the earth.", 
                "num": 3
              }, 
              {
                "text": "After it a voice roars; He thunders with His majestic voice, And He does not restrain them when His voice is heard.", 
                "num": 4
              }, 
              {
                "text": "God thunders marvelously with His voice; He does great things which we cannot comprehend.", 
                "num": 5
              }, 
              {
                "text": "For He says to the snow, \"Fall on the earth'; Likewise to the gentle rain and the heavy rain of His strength.", 
                "num": 6
              }, 
              {
                "text": "He seals the hand of every man, That all men may know His work.", 
                "num": 7
              }, 
              {
                "text": "The beasts go into dens, And remain in their lairs.", 
                "num": 8
              }, 
              {
                "text": "From the chamber of the south comes the whirlwind, And cold from the scattering winds of the north.", 
                "num": 9
              }, 
              {
                "text": "By the breath of God ice is given, And the broad waters are frozen.", 
                "num": 10
              }, 
              {
                "text": "Also with moisture He saturates the thick clouds; He scatters His bright clouds.", 
                "num": 11
              }, 
              {
                "text": "And they swirl about, being turned by His guidance, That they may do whatever He commands them On the face of the whole earth.", 
                "num": 12
              }, 
              {
                "text": "He causes it to come, Whether for correction, Or for His land, Or for mercy.", 
                "num": 13
              }, 
              {
                "text": "\"Listen to this, O Job; Stand still and consider the wondrous works of God.", 
                "num": 14
              }, 
              {
                "text": "Do you know when God dispatches them, And causes the light of His cloud to shine?", 
                "num": 15
              }, 
              {
                "text": "Do you know how the clouds are balanced, Those wondrous works of Him who is perfect in knowledge?", 
                "num": 16
              }, 
              {
                "text": "Why are your garments hot, When He quiets the earth by the south wind?", 
                "num": 17
              }, 
              {
                "text": "With Him, have you spread out the skies, Strong as a cast metal mirror?", 
                "num": 18
              }, 
              {
                "text": "\"Teach us what we should say to Him, For we can prepare nothing because of the darkness.", 
                "num": 19
              }, 
              {
                "text": "Should He be told that I wish to speak? If a man were to speak, surely he would be swallowed up.", 
                "num": 20
              }, 
              {
                "text": "Even now men cannot look at the light when it is bright in the skies, When the wind has passed and cleared them.", 
                "num": 21
              }, 
              {
                "text": "He comes from the north as golden splendor; With God is awesome majesty.", 
                "num": 22
              }, 
              {
                "text": "As for the Almighty, we cannot find Him; He is excellent in power, In judgment and abundant justice; He does not oppress.", 
                "num": 23
              }, 
              {
                "text": "Therefore men fear Him; He shows no partiality to any who are wise of heart.\"", 
                "num": 24
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD answered Job out of the whirlwind, and said:", 
                "num": 1
              }, 
              {
                "text": "\"Who is this who darkens counsel By words without knowledge?", 
                "num": 2
              }, 
              {
                "text": "Now prepare yourself like a man; I will question you, and you shall answer Me.", 
                "num": 3
              }, 
              {
                "text": "\"Where were you when I laid the foundations of the earth? Tell Me, if you have understanding.", 
                "num": 4
              }, 
              {
                "text": "Who determined its measurements? Surely you know! Or who stretched the line upon it?", 
                "num": 5
              }, 
              {
                "text": "To what were its foundations fastened? Or who laid its cornerstone,", 
                "num": 6
              }, 
              {
                "text": "When the morning stars sang together, And all the sons of God shouted for joy?", 
                "num": 7
              }, 
              {
                "text": "\"Or who shut in the sea with doors, When it burst forth and issued from the womb;", 
                "num": 8
              }, 
              {
                "text": "When I made the clouds its garment, And thick darkness its swaddling band;", 
                "num": 9
              }, 
              {
                "text": "When I fixed My limit for it, And set bars and doors;", 
                "num": 10
              }, 
              {
                "text": "When I said, \"This far you may come, but no farther, And here your proud waves must stop!'", 
                "num": 11
              }, 
              {
                "text": "\"Have you commanded the morning since your days began, And caused the dawn to know its place,", 
                "num": 12
              }, 
              {
                "text": "That it might take hold of the ends of the earth, And the wicked be shaken out of it?", 
                "num": 13
              }, 
              {
                "text": "It takes on form like clay under a seal, And stands out like a garment.", 
                "num": 14
              }, 
              {
                "text": "From the wicked their light is withheld, And the upraised arm is broken.", 
                "num": 15
              }, 
              {
                "text": "\"Have you entered the springs of the sea? Or have you walked in search of the depths?", 
                "num": 16
              }, 
              {
                "text": "Have the gates of death been revealed to you? Or have you seen the doors of the shadow of death?", 
                "num": 17
              }, 
              {
                "text": "Have you comprehended the breadth of the earth? Tell Me, if you know all this.", 
                "num": 18
              }, 
              {
                "text": "\"Where is the way to the dwelling of light? And darkness, where is its place,", 
                "num": 19
              }, 
              {
                "text": "That you may take it to its territory, That you may know the paths to its home?", 
                "num": 20
              }, 
              {
                "text": "Do you know it, because you were born then, Or because the number of your days is great?", 
                "num": 21
              }, 
              {
                "text": "\"Have you entered the treasury of snow, Or have you seen the treasury of hail,", 
                "num": 22
              }, 
              {
                "text": "Which I have reserved for the time of trouble, For the day of battle and war?", 
                "num": 23
              }, 
              {
                "text": "By what way is light diffused, Or the east wind scattered over the earth?", 
                "num": 24
              }, 
              {
                "text": "\"Who has divided a channel for the overflowing water, Or a path for the thunderbolt,", 
                "num": 25
              }, 
              {
                "text": "To cause it to rain on a land where there is no one, A wilderness in which there is no man;", 
                "num": 26
              }, 
              {
                "text": "To satisfy the desolate waste, And cause to spring forth the growth of tender grass?", 
                "num": 27
              }, 
              {
                "text": "Has the rain a father? Or who has begotten the drops of dew?", 
                "num": 28
              }, 
              {
                "text": "From whose womb comes the ice? And the frost of heaven, who gives it birth?", 
                "num": 29
              }, 
              {
                "text": "The waters harden like stone, And the surface of the deep is frozen.", 
                "num": 30
              }, 
              {
                "text": "\"Can you bind the cluster of the Pleiades, Or loose the belt of Orion?", 
                "num": 31
              }, 
              {
                "text": "Can you bring out Mazzaroth in its season? Or can you guide the Great Bear with its cubs?", 
                "num": 32
              }, 
              {
                "text": "Do you know the ordinances of the heavens? Can you set their dominion over the earth?", 
                "num": 33
              }, 
              {
                "text": "\"Can you lift up your voice to the clouds, That an abundance of water may cover you?", 
                "num": 34
              }, 
              {
                "text": "Can you send out lightnings, that they may go, And say to you, \"Here we are!'?", 
                "num": 35
              }, 
              {
                "text": "Who has put wisdom in the mind? Or who has given understanding to the heart?", 
                "num": 36
              }, 
              {
                "text": "Who can number the clouds by wisdom? Or who can pour out the bottles of heaven,", 
                "num": 37
              }, 
              {
                "text": "When the dust hardens in clumps, And the clods cling together?", 
                "num": 38
              }, 
              {
                "text": "\"Can you hunt the prey for the lion, Or satisfy the appetite of the young lions,", 
                "num": 39
              }, 
              {
                "text": "When they crouch in their dens, Or lurk in their lairs to lie in wait?", 
                "num": 40
              }, 
              {
                "text": "Who provides food for the raven, When its young ones cry to God, And wander about for lack of food?", 
                "num": 41
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "\"Do you know the time when the wild mountain goats bear young? Or can you mark when the deer gives birth?", 
                "num": 1
              }, 
              {
                "text": "Can you number the months that they fulfill? Or do you know the time when they bear young?", 
                "num": 2
              }, 
              {
                "text": "They bow down, They bring forth their young, They deliver their offspring.", 
                "num": 3
              }, 
              {
                "text": "Their young ones are healthy, They grow strong with grain; They depart and do not return to them.", 
                "num": 4
              }, 
              {
                "text": "\"Who set the wild donkey free? Who loosed the bonds of the onager,", 
                "num": 5
              }, 
              {
                "text": "Whose home I have made the wilderness, And the barren land his dwelling?", 
                "num": 6
              }, 
              {
                "text": "He scorns the tumult of the city; He does not heed the shouts of the driver.", 
                "num": 7
              }, 
              {
                "text": "The range of the mountains is his pasture, And he searches after every green thing.", 
                "num": 8
              }, 
              {
                "text": "\"Will the wild ox be willing to serve you? Will he bed by your manger?", 
                "num": 9
              }, 
              {
                "text": "Can you bind the wild ox in the furrow with ropes? Or will he plow the valleys behind you?", 
                "num": 10
              }, 
              {
                "text": "Will you trust him because his strength is great? Or will you leave your labor to him?", 
                "num": 11
              }, 
              {
                "text": "Will you trust him to bring home your grain, And gather it to your threshing floor?", 
                "num": 12
              }, 
              {
                "text": "\"The wings of the ostrich wave proudly, But are her wings and pinions like the kindly stork's?", 
                "num": 13
              }, 
              {
                "text": "For she leaves her eggs on the ground, And warms them in the dust;", 
                "num": 14
              }, 
              {
                "text": "She forgets that a foot may crush them, Or that a wild beast may break them.", 
                "num": 15
              }, 
              {
                "text": "She treats her young harshly, as though they were not hers; Her labor is in vain, without concern,", 
                "num": 16
              }, 
              {
                "text": "Because God deprived her of wisdom, And did not endow her with understanding.", 
                "num": 17
              }, 
              {
                "text": "When she lifts herself on high, She scorns the horse and its rider.", 
                "num": 18
              }, 
              {
                "text": "\"Have you given the horse strength? Have you clothed his neck with thunder?", 
                "num": 19
              }, 
              {
                "text": "Can you frighten him like a locust? His majestic snorting strikes terror.", 
                "num": 20
              }, 
              {
                "text": "He paws in the valley, and rejoices in his strength; He gallops into the clash of arms.", 
                "num": 21
              }, 
              {
                "text": "He mocks at fear, and is not frightened; Nor does he turn back from the sword.", 
                "num": 22
              }, 
              {
                "text": "The quiver rattles against him, The glittering spear and javelin.", 
                "num": 23
              }, 
              {
                "text": "He devours the distance with fierceness and rage; Nor does he come to a halt because the trumpet has sounded.", 
                "num": 24
              }, 
              {
                "text": "At the blast of the trumpet he says, \"Aha!' He smells the battle from afar, The thunder of captains and shouting.", 
                "num": 25
              }, 
              {
                "text": "\"Does the hawk fly by your wisdom, And spread its wings toward the south?", 
                "num": 26
              }, 
              {
                "text": "Does the eagle mount up at your command, And make its nest on high?", 
                "num": 27
              }, 
              {
                "text": "On the rock it dwells and resides, On the crag of the rock and the stronghold.", 
                "num": 28
              }, 
              {
                "text": "From there it spies out the prey; Its eyes observe from afar.", 
                "num": 29
              }, 
              {
                "text": "Its young ones suck up blood; And where the slain are, there it is.\"", 
                "num": 30
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "Moreover the LORD answered Job, and said:", 
                "num": 1
              }, 
              {
                "text": "\"Shall the one who contends with the Almighty correct Him? He who rebukes God, let him answer it.\"", 
                "num": 2
              }, 
              {
                "text": "Then Job answered the LORD and said:", 
                "num": 3
              }, 
              {
                "text": "\"Behold, I am vile; What shall I answer You? I lay my hand over my mouth.", 
                "num": 4
              }, 
              {
                "text": "Once I have spoken, but I will not answer; Yes, twice, but I will proceed no further.\"", 
                "num": 5
              }, 
              {
                "text": "Then the LORD answered Job out of the whirlwind, and said:", 
                "num": 6
              }, 
              {
                "text": "\"Now prepare yourself like a man; I will question you, and you shall answer Me:", 
                "num": 7
              }, 
              {
                "text": "\"Would you indeed annul My judgment? Would you condemn Me that you may be justified?", 
                "num": 8
              }, 
              {
                "text": "Have you an arm like God? Or can you thunder with a voice like His?", 
                "num": 9
              }, 
              {
                "text": "Then adorn yourself with majesty and splendor, And array yourself with glory and beauty.", 
                "num": 10
              }, 
              {
                "text": "Disperse the rage of your wrath; Look on everyone who is proud, and humble him.", 
                "num": 11
              }, 
              {
                "text": "Look on everyone who is proud, and bring him low; Tread down the wicked in their place.", 
                "num": 12
              }, 
              {
                "text": "Hide them in the dust together, Bind their faces in hidden darkness.", 
                "num": 13
              }, 
              {
                "text": "Then I will also confess to you That your own right hand can save you.", 
                "num": 14
              }, 
              {
                "text": "\"Look now at the behemoth, which I made along with you; He eats grass like an ox.", 
                "num": 15
              }, 
              {
                "text": "See now, his strength is in his hips, And his power is in his stomach muscles.", 
                "num": 16
              }, 
              {
                "text": "He moves his tail like a cedar; The sinews of his thighs are tightly knit.", 
                "num": 17
              }, 
              {
                "text": "His bones are like beams of bronze, His ribs like bars of iron.", 
                "num": 18
              }, 
              {
                "text": "He is the first of the ways of God; Only He who made him can bring near His sword.", 
                "num": 19
              }, 
              {
                "text": "Surely the mountains yield food for him, And all the beasts of the field play there.", 
                "num": 20
              }, 
              {
                "text": "He lies under the lotus trees, In a covert of reeds and marsh.", 
                "num": 21
              }, 
              {
                "text": "The lotus trees cover him with their shade; The willows by the brook surround him.", 
                "num": 22
              }, 
              {
                "text": "Indeed the river may rage, Yet he is not disturbed; He is confident, though the Jordan gushes into his mouth,", 
                "num": 23
              }, 
              {
                "text": "Though he takes it in his eyes, Or one pierces his nose with a snare.", 
                "num": 24
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "\"Can you draw out Leviathan with a hook, Or snare his tongue with a line which you lower?", 
                "num": 1
              }, 
              {
                "text": "Can you put a reed through his nose, Or pierce his jaw with a hook?", 
                "num": 2
              }, 
              {
                "text": "Will he make many supplications to you? Will he speak softly to you?", 
                "num": 3
              }, 
              {
                "text": "Will he make a covenant with you? Will you take him as a servant forever?", 
                "num": 4
              }, 
              {
                "text": "Will you play with him as with a bird, Or will you leash him for your maidens?", 
                "num": 5
              }, 
              {
                "text": "Will your companions make a banquet of him? Will they apportion him among the merchants?", 
                "num": 6
              }, 
              {
                "text": "Can you fill his skin with harpoons, Or his head with fishing spears?", 
                "num": 7
              }, 
              {
                "text": "Lay your hand on him; Remember the battle-- Never do it again!", 
                "num": 8
              }, 
              {
                "text": "Indeed, any hope of overcoming him is false; Shall one not be overwhelmed at the sight of him?", 
                "num": 9
              }, 
              {
                "text": "No one is so fierce that he would dare stir him up. Who then is able to stand against Me?", 
                "num": 10
              }, 
              {
                "text": "Who has preceded Me, that I should pay him? Everything under heaven is Mine.", 
                "num": 11
              }, 
              {
                "text": "\"I will not conceal his limbs, His mighty power, or his graceful proportions.", 
                "num": 12
              }, 
              {
                "text": "Who can remove his outer coat? Who can approach him with a double bridle?", 
                "num": 13
              }, 
              {
                "text": "Who can open the doors of his face, With his terrible teeth all around?", 
                "num": 14
              }, 
              {
                "text": "His rows of scales are his pride, Shut up tightly as with a seal;", 
                "num": 15
              }, 
              {
                "text": "One is so near another That no air can come between them;", 
                "num": 16
              }, 
              {
                "text": "They are joined one to another, They stick together and cannot be parted.", 
                "num": 17
              }, 
              {
                "text": "His sneezings flash forth light, And his eyes are like the eyelids of the morning.", 
                "num": 18
              }, 
              {
                "text": "Out of his mouth go burning lights; Sparks of fire shoot out.", 
                "num": 19
              }, 
              {
                "text": "Smoke goes out of his nostrils, As from a boiling pot and burning rushes.", 
                "num": 20
              }, 
              {
                "text": "His breath kindles coals, And a flame goes out of his mouth.", 
                "num": 21
              }, 
              {
                "text": "Strength dwells in his neck, And sorrow dances before him.", 
                "num": 22
              }, 
              {
                "text": "The folds of his flesh are joined together; They are firm on him and cannot be moved.", 
                "num": 23
              }, 
              {
                "text": "His heart is as hard as stone, Even as hard as the lower millstone.", 
                "num": 24
              }, 
              {
                "text": "When he raises himself up, the mighty are afraid; Because of his crashings they are beside themselves.", 
                "num": 25
              }, 
              {
                "text": "Though the sword reaches him, it cannot avail; Nor does spear, dart, or javelin.", 
                "num": 26
              }, 
              {
                "text": "He regards iron as straw, And bronze as rotten wood.", 
                "num": 27
              }, 
              {
                "text": "The arrow cannot make him flee; Slingstones become like stubble to him.", 
                "num": 28
              }, 
              {
                "text": "Darts are regarded as straw; He laughs at the threat of javelins.", 
                "num": 29
              }, 
              {
                "text": "His undersides are like sharp potsherds; He spreads pointed marks in the mire.", 
                "num": 30
              }, 
              {
                "text": "He makes the deep boil like a pot; He makes the sea like a pot of ointment.", 
                "num": 31
              }, 
              {
                "text": "He leaves a shining wake behind him; One would think the deep had white hair.", 
                "num": 32
              }, 
              {
                "text": "On earth there is nothing like him, Which is made without fear.", 
                "num": 33
              }, 
              {
                "text": "He beholds every high thing; He is king over all the children of pride.\"", 
                "num": 34
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "Then Job answered the LORD and said:", 
                "num": 1
              }, 
              {
                "text": "\"I know that You can do everything, And that no purpose of Yours can be withheld from You.", 
                "num": 2
              }, 
              {
                "text": "You asked, \"Who is this who hides counsel without knowledge?' Therefore I have uttered what I did not understand, Things too wonderful for me, which I did not know.", 
                "num": 3
              }, 
              {
                "text": "Listen, please, and let me speak; You said, \"I will question you, and you shall answer Me.'", 
                "num": 4
              }, 
              {
                "text": "\"I have heard of You by the hearing of the ear, But now my eye sees You.", 
                "num": 5
              }, 
              {
                "text": "Therefore I abhor myself, And repent in dust and ashes.\"", 
                "num": 6
              }, 
              {
                "text": "And so it was, after the LORD had spoken these words to Job, that the LORD said to Eliphaz the Temanite, \"My wrath is aroused against you and your two friends, for you have not spoken of Me what is right, as My servant Job has.", 
                "num": 7
              }, 
              {
                "text": "Now therefore, take for yourselves seven bulls and seven rams, go to My servant Job, and offer up for yourselves a burnt offering; and My servant Job shall pray for you. For I will accept him, lest I deal with you according to your folly; because you have not spoken of Me what is right, as My servant Job has.\"", 
                "num": 8
              }, 
              {
                "text": "So Eliphaz the Temanite and Bildad the Shuhite and Zophar the Naamathite went and did as the LORD commanded them; for the LORD had accepted Job.", 
                "num": 9
              }, 
              {
                "text": "And the LORD restored Job's losses when he prayed for his friends. Indeed the LORD gave Job twice as much as he had before.", 
                "num": 10
              }, 
              {
                "text": "Then all his brothers, all his sisters, and all those who had been his acquaintances before, came to him and ate food with him in his house; and they consoled him and comforted him for all the adversity that the LORD had brought upon him. Each one gave him a piece of silver and each a ring of gold.", 
                "num": 11
              }, 
              {
                "text": "Now the LORD blessed the latter days of Job more than his beginning; for he had fourteen thousand sheep, six thousand camels, one thousand yoke of oxen, and one thousand female donkeys.", 
                "num": 12
              }, 
              {
                "text": "He also had seven sons and three daughters.", 
                "num": 13
              }, 
              {
                "text": "And he called the name of the first Jemimah, the name of the second Keziah, and the name of the third Keren-Happuch.", 
                "num": 14
              }, 
              {
                "text": "In all the land were found no women so beautiful as the daughters of Job; and their father gave them an inheritance among their brothers.", 
                "num": 15
              }, 
              {
                "text": "After this Job lived one hundred and forty years, and saw his children and grandchildren for four generations.", 
                "num": 16
              }, 
              {
                "text": "So Job died, old and full of days.", 
                "num": 17
              }
            ], 
            "num": 42
          }
        ]
      }, 
      {
        "name": "Psalm", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Blessed is the man Who walks not in the counsel of the ungodly, Nor stands in the path of sinners, Nor sits in the seat of the scornful;", 
                "num": 1
              }, 
              {
                "text": "But his delight is in the law of the LORD, And in His law he meditates day and night.", 
                "num": 2
              }, 
              {
                "text": "He shall be like a tree Planted by the rivers of water, That brings forth its fruit in its season, Whose leaf also shall not wither; And whatever he does shall prosper.", 
                "num": 3
              }, 
              {
                "text": "The ungodly are not so, But are like the chaff which the wind drives away.", 
                "num": 4
              }, 
              {
                "text": "Therefore the ungodly shall not stand in the judgment, Nor sinners in the congregation of the righteous.", 
                "num": 5
              }, 
              {
                "text": "For the LORD knows the way of the righteous, But the way of the ungodly shall perish.", 
                "num": 6
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Why do the nations rage, And the people plot a vain thing?", 
                "num": 1
              }, 
              {
                "text": "The kings of the earth set themselves, And the rulers take counsel together, Against the LORD and against His Anointed, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Let us break Their bonds in pieces And cast away Their cords from us.\"", 
                "num": 3
              }, 
              {
                "text": "He who sits in the heavens shall laugh; The LORD shall hold them in derision.", 
                "num": 4
              }, 
              {
                "text": "Then He shall speak to them in His wrath, And distress them in His deep displeasure:", 
                "num": 5
              }, 
              {
                "text": "\"Yet I have set My King On My holy hill of Zion.\"", 
                "num": 6
              }, 
              {
                "text": "\"I will declare the decree: The LORD has said to Me, \"You are My Son, Today I have begotten You.", 
                "num": 7
              }, 
              {
                "text": "Ask of Me, and I will give You The nations for Your inheritance, And the ends of the earth for Your possession.", 
                "num": 8
              }, 
              {
                "text": "You shall break them with a rod of iron; You shall dash them to pieces like a potter's vessel.\"'", 
                "num": 9
              }, 
              {
                "text": "Now therefore, be wise, O kings; Be instructed, you judges of the earth.", 
                "num": 10
              }, 
              {
                "text": "Serve the LORD with fear, And rejoice with trembling.", 
                "num": 11
              }, 
              {
                "text": "Kiss the Son, lest He be angry, And you perish in the way, When His wrath is kindled but a little. Blessed are all those who put their trust in Him.", 
                "num": 12
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "LORD, how they have increased who trouble me! Many are they who rise up against me.", 
                "num": 1
              }, 
              {
                "text": "Many are they who say of me, \"There is no help for him in God.\"Selah", 
                "num": 2
              }, 
              {
                "text": "But You, O LORD, are a shield for me, My glory and the One who lifts up my head.", 
                "num": 3
              }, 
              {
                "text": "I cried to the LORD with my voice, And He heard me from His holy hill.Selah", 
                "num": 4
              }, 
              {
                "text": "I lay down and slept; I awoke, for the LORD sustained me.", 
                "num": 5
              }, 
              {
                "text": "I will not be afraid of ten thousands of people Who have set themselves against me all around.", 
                "num": 6
              }, 
              {
                "text": "Arise, O LORD; Save me, O my God! For You have struck all my enemies on the cheekbone; You have broken the teeth of the ungodly.", 
                "num": 7
              }, 
              {
                "text": "Salvation belongs to the LORD. Your blessing is upon Your people.Selah", 
                "num": 8
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Hear me when I call, O God of my righteousness! You have relieved me in my distress; Have mercy on me, and hear my prayer.", 
                "num": 1
              }, 
              {
                "text": "How long, O you sons of men, Will you turn my glory to shame? How long will you love worthlessness And seek falsehood?Selah", 
                "num": 2
              }, 
              {
                "text": "But know that the LORD has set apart for Himself him who is godly; The LORD will hear when I call to Him.", 
                "num": 3
              }, 
              {
                "text": "Be angry, and do not sin. Meditate within your heart on your bed, and be still.Selah", 
                "num": 4
              }, 
              {
                "text": "Offer the sacrifices of righteousness, And put your trust in the LORD.", 
                "num": 5
              }, 
              {
                "text": "There are many who say, \"Who will show us any good?\" LORD, lift up the light of Your countenance upon us.", 
                "num": 6
              }, 
              {
                "text": "You have put gladness in my heart, More than in the season that their grain and wine increased.", 
                "num": 7
              }, 
              {
                "text": "I will both lie down in peace, and sleep; For You alone, O LORD, make me dwell in safety.", 
                "num": 8
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Give ear to my words, O LORD, Consider my meditation.", 
                "num": 1
              }, 
              {
                "text": "Give heed to the voice of my cry, My King and my God, For to You I will pray.", 
                "num": 2
              }, 
              {
                "text": "My voice You shall hear in the morning, O LORD; In the morning I will direct it to You, And I will look up.", 
                "num": 3
              }, 
              {
                "text": "For You are not a God who takes pleasure in wickedness, Nor shall evil dwell with You.", 
                "num": 4
              }, 
              {
                "text": "The boastful shall not stand in Your sight; You hate all workers of iniquity.", 
                "num": 5
              }, 
              {
                "text": "You shall destroy those who speak falsehood; The LORD abhors the bloodthirsty and deceitful man.", 
                "num": 6
              }, 
              {
                "text": "But as for me, I will come into Your house in the multitude of Your mercy; In fear of You I will worship toward Your holy temple.", 
                "num": 7
              }, 
              {
                "text": "Lead me, O LORD, in Your righteousness because of my enemies; Make Your way straight before my face.", 
                "num": 8
              }, 
              {
                "text": "For there is no faithfulness in their mouth; Their inward part is destruction; Their throat is an open tomb; They flatter with their tongue.", 
                "num": 9
              }, 
              {
                "text": "Pronounce them guilty, O God! Let them fall by their own counsels; Cast them out in the multitude of their transgressions, For they have rebelled against You.", 
                "num": 10
              }, 
              {
                "text": "But let all those rejoice who put their trust in You; Let them ever shout for joy, because You defend them; Let those also who love Your name Be joyful in You.", 
                "num": 11
              }, 
              {
                "text": "For You, O LORD, will bless the righteous; With favor You will surround him as with a shield.", 
                "num": 12
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "O LORD, do not rebuke me in Your anger, Nor chasten me in Your hot displeasure.", 
                "num": 1
              }, 
              {
                "text": "Have mercy on me, O LORD, for I am weak; O LORD, heal me, for my bones are troubled.", 
                "num": 2
              }, 
              {
                "text": "My soul also is greatly troubled; But You, O LORD--how long?", 
                "num": 3
              }, 
              {
                "text": "Return, O LORD, deliver me! Oh, save me for Your mercies' sake!", 
                "num": 4
              }, 
              {
                "text": "For in death there is no remembrance of You; In the grave who will give You thanks?", 
                "num": 5
              }, 
              {
                "text": "I am weary with my groaning; All night I make my bed swim; I drench my couch with my tears.", 
                "num": 6
              }, 
              {
                "text": "My eye wastes away because of grief; It grows old because of all my enemies.", 
                "num": 7
              }, 
              {
                "text": "Depart from me, all you workers of iniquity; For the LORD has heard the voice of my weeping.", 
                "num": 8
              }, 
              {
                "text": "The LORD has heard my supplication; The LORD will receive my prayer.", 
                "num": 9
              }, 
              {
                "text": "Let all my enemies be ashamed and greatly troubled; Let them turn back and be ashamed suddenly.", 
                "num": 10
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "O LORD my God, in You I put my trust; Save me from all those who persecute me; And deliver me,", 
                "num": 1
              }, 
              {
                "text": "Lest they tear me like a lion, Rending me in pieces, while there is none to deliver.", 
                "num": 2
              }, 
              {
                "text": "O LORD my God, if I have done this: If there is iniquity in my hands,", 
                "num": 3
              }, 
              {
                "text": "If I have repaid evil to him who was at peace with me, Or have plundered my enemy without cause,", 
                "num": 4
              }, 
              {
                "text": "Let the enemy pursue me and overtake me; Yes, let him trample my life to the earth, And lay my honor in the dust.Selah", 
                "num": 5
              }, 
              {
                "text": "Arise, O LORD, in Your anger; Lift Yourself up because of the rage of my enemies; Rise up for me to the judgment You have commanded!", 
                "num": 6
              }, 
              {
                "text": "So the congregation of the peoples shall surround You; For their sakes, therefore, return on high.", 
                "num": 7
              }, 
              {
                "text": "The LORD shall judge the peoples; Judge me, O LORD, according to my righteousness, And according to my integrity within me.", 
                "num": 8
              }, 
              {
                "text": "Oh, let the wickedness of the wicked come to an end, But establish the just; For the righteous God tests the hearts and minds.", 
                "num": 9
              }, 
              {
                "text": "My defense is of God, Who saves the upright in heart.", 
                "num": 10
              }, 
              {
                "text": "God is a just judge, And God is angry with the wicked every day.", 
                "num": 11
              }, 
              {
                "text": "If he does not turn back, He will sharpen His sword; He bends His bow and makes it ready.", 
                "num": 12
              }, 
              {
                "text": "He also prepares for Himself instruments of death; He makes His arrows into fiery shafts.", 
                "num": 13
              }, 
              {
                "text": "Behold, the wicked brings forth iniquity; Yes, he conceives trouble and brings forth falsehood.", 
                "num": 14
              }, 
              {
                "text": "He made a pit and dug it out, And has fallen into the ditch which he made.", 
                "num": 15
              }, 
              {
                "text": "His trouble shall return upon his own head, And his violent dealing shall come down on his own crown.", 
                "num": 16
              }, 
              {
                "text": "I will praise the LORD according to His righteousness, And will sing praise to the name of the LORD Most High.", 
                "num": 17
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "O LORD, our Lord, How excellent is Your name in all the earth, Who have set Your glory above the heavens!", 
                "num": 1
              }, 
              {
                "text": "Out of the mouth of babes and nursing infants You have ordained strength, Because of Your enemies, That You may silence the enemy and the avenger.", 
                "num": 2
              }, 
              {
                "text": "When I consider Your heavens, the work of Your fingers, The moon and the stars, which You have ordained,", 
                "num": 3
              }, 
              {
                "text": "What is man that You are mindful of him, And the son of man that You visit him?", 
                "num": 4
              }, 
              {
                "text": "For You have made him a little lower than the angels, And You have crowned him with glory and honor.", 
                "num": 5
              }, 
              {
                "text": "You have made him to have dominion over the works of Your hands; You have put all things under his feet,", 
                "num": 6
              }, 
              {
                "text": "All sheep and oxen-- Even the beasts of the field,", 
                "num": 7
              }, 
              {
                "text": "The birds of the air, And the fish of the sea That pass through the paths of the seas.", 
                "num": 8
              }, 
              {
                "text": "O LORD, our Lord, How excellent is Your name in all the earth!", 
                "num": 9
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "I will praise You, O LORD, with my whole heart; I will tell of all Your marvelous works.", 
                "num": 1
              }, 
              {
                "text": "I will be glad and rejoice in You; I will sing praise to Your name, O Most High.", 
                "num": 2
              }, 
              {
                "text": "When my enemies turn back, They shall fall and perish at Your presence.", 
                "num": 3
              }, 
              {
                "text": "For You have maintained my right and my cause; You sat on the throne judging in righteousness.", 
                "num": 4
              }, 
              {
                "text": "You have rebuked the nations, You have destroyed the wicked; You have blotted out their name forever and ever.", 
                "num": 5
              }, 
              {
                "text": "O enemy, destructions are finished forever! And you have destroyed cities; Even their memory has perished.", 
                "num": 6
              }, 
              {
                "text": "But the LORD shall endure forever; He has prepared His throne for judgment.", 
                "num": 7
              }, 
              {
                "text": "He shall judge the world in righteousness, And He shall administer judgment for the peoples in uprightness.", 
                "num": 8
              }, 
              {
                "text": "The LORD also will be a refuge for the oppressed, A refuge in times of trouble.", 
                "num": 9
              }, 
              {
                "text": "And those who know Your name will put their trust in You; For You, LORD, have not forsaken those who seek You.", 
                "num": 10
              }, 
              {
                "text": "Sing praises to the LORD, who dwells in Zion! Declare His deeds among the people.", 
                "num": 11
              }, 
              {
                "text": "When He avenges blood, He remembers them; He does not forget the cry of the humble.", 
                "num": 12
              }, 
              {
                "text": "Have mercy on me, O LORD! Consider my trouble from those who hate me, You who lift me up from the gates of death,", 
                "num": 13
              }, 
              {
                "text": "That I may tell of all Your praise In the gates of the daughter of Zion. I will rejoice in Your salvation.", 
                "num": 14
              }, 
              {
                "text": "The nations have sunk down in the pit which they made; In the net which they hid, their own foot is caught.", 
                "num": 15
              }, 
              {
                "text": "The LORD is known by the judgment He executes; The wicked is snared in the work of his own hands.", 
                "num": 16
              }, 
              {
                "text": "The wicked shall be turned into hell, And all the nations that forget God.", 
                "num": 17
              }, 
              {
                "text": "For the needy shall not always be forgotten; The expectation of the poor shall not perish forever.", 
                "num": 18
              }, 
              {
                "text": "Arise, O LORD, Do not let man prevail; Let the nations be judged in Your sight.", 
                "num": 19
              }, 
              {
                "text": "Put them in fear, O LORD, That the nations may know themselves to be but men.Selah", 
                "num": 20
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Why do You stand afar off, O LORD? Why do You hide in times of trouble?", 
                "num": 1
              }, 
              {
                "text": "The wicked in his pride persecutes the poor; Let them be caught in the plots which they have devised.", 
                "num": 2
              }, 
              {
                "text": "For the wicked boasts of his heart's desire; He blesses the greedy and renounces the LORD.", 
                "num": 3
              }, 
              {
                "text": "The wicked in his proud countenance does not seek God; God is in none of his thoughts.", 
                "num": 4
              }, 
              {
                "text": "His ways are always prospering; Your judgments are far above, out of his sight; As for all his enemies, he sneers at them.", 
                "num": 5
              }, 
              {
                "text": "He has said in his heart, \"I shall not be moved; I shall never be in adversity.\"", 
                "num": 6
              }, 
              {
                "text": "His mouth is full of cursing and deceit and oppression; Under his tongue is trouble and iniquity.", 
                "num": 7
              }, 
              {
                "text": "He sits in the lurking places of the villages; In the secret places he murders the innocent; His eyes are secretly fixed on the helpless.", 
                "num": 8
              }, 
              {
                "text": "He lies in wait secretly, as a lion in his den; He lies in wait to catch the poor; He catches the poor when he draws him into his net.", 
                "num": 9
              }, 
              {
                "text": "So he crouches, he lies low, That the helpless may fall by his strength.", 
                "num": 10
              }, 
              {
                "text": "He has said in his heart, \"God has forgotten; He hides His face; He will never see.\"", 
                "num": 11
              }, 
              {
                "text": "Arise, O LORD! O God, lift up Your hand! Do not forget the humble.", 
                "num": 12
              }, 
              {
                "text": "Why do the wicked renounce God? He has said in his heart, \"You will not require an account.\"", 
                "num": 13
              }, 
              {
                "text": "But You have seen, for You observe trouble and grief, To repay it by Your hand. The helpless commits himself to You; You are the helper of the fatherless.", 
                "num": 14
              }, 
              {
                "text": "Break the arm of the wicked and the evil man; Seek out his wickedness until You find none.", 
                "num": 15
              }, 
              {
                "text": "The LORD is King forever and ever; The nations have perished out of His land.", 
                "num": 16
              }, 
              {
                "text": "LORD, You have heard the desire of the humble; You will prepare their heart; You will cause Your ear to hear,", 
                "num": 17
              }, 
              {
                "text": "To do justice to the fatherless and the oppressed, That the man of the earth may oppress no more.", 
                "num": 18
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "In the LORD I put my trust; How can you say to my soul, \"Flee as a bird to your mountain\"?", 
                "num": 1
              }, 
              {
                "text": "For look! The wicked bend their bow, They make ready their arrow on the string, That they may shoot secretly at the upright in heart.", 
                "num": 2
              }, 
              {
                "text": "If the foundations are destroyed, What can the righteous do?", 
                "num": 3
              }, 
              {
                "text": "The LORD is in His holy temple, The LORD's throne is in heaven; His eyes behold, His eyelids test the sons of men.", 
                "num": 4
              }, 
              {
                "text": "The LORD tests the righteous, But the wicked and the one who loves violence His soul hates.", 
                "num": 5
              }, 
              {
                "text": "Upon the wicked He will rain coals; Fire and brimstone and a burning wind Shall be the portion of their cup.", 
                "num": 6
              }, 
              {
                "text": "For the LORD is righteous, He loves righteousness; His countenance beholds the upright.", 
                "num": 7
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Help, LORD, for the godly man ceases! For the faithful disappear from among the sons of men.", 
                "num": 1
              }, 
              {
                "text": "They speak idly everyone with his neighbor; With flattering lips and a double heart they speak.", 
                "num": 2
              }, 
              {
                "text": "May the LORD cut off all flattering lips, And the tongue that speaks proud things,", 
                "num": 3
              }, 
              {
                "text": "Who have said, \"With our tongue we will prevail; Our lips are our own; Who is lord over us?\"", 
                "num": 4
              }, 
              {
                "text": "\"For the oppression of the poor, for the sighing of the needy, Now I will arise,\" says the LORD; \"I will set him in the safety for which he yearns.\"", 
                "num": 5
              }, 
              {
                "text": "The words of the LORD are pure words, Like silver tried in a furnace of earth, Purified seven times.", 
                "num": 6
              }, 
              {
                "text": "You shall keep them, O LORD, You shall preserve them from this generation forever.", 
                "num": 7
              }, 
              {
                "text": "The wicked prowl on every side, When vileness is exalted among the sons of men.", 
                "num": 8
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "How long, O LORD? Will You forget me forever? How long will You hide Your face from me?", 
                "num": 1
              }, 
              {
                "text": "How long shall I take counsel in my soul, Having sorrow in my heart daily? How long will my enemy be exalted over me?", 
                "num": 2
              }, 
              {
                "text": "Consider and hear me, O LORD my God; Enlighten my eyes, Lest I sleep the sleep of death;", 
                "num": 3
              }, 
              {
                "text": "Lest my enemy say, \"I have prevailed against him\"; Lest those who trouble me rejoice when I am moved.", 
                "num": 4
              }, 
              {
                "text": "But I have trusted in Your mercy; My heart shall rejoice in Your salvation.", 
                "num": 5
              }, 
              {
                "text": "I will sing to the LORD, Because He has dealt bountifully with me.", 
                "num": 6
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "The fool has said in his heart, \"There is no God.\" They are corrupt, They have done abominable works, There is none who does good.", 
                "num": 1
              }, 
              {
                "text": "The LORD looks down from heaven upon the children of men, To see if there are any who understand, who seek God.", 
                "num": 2
              }, 
              {
                "text": "They have all turned aside, They have together become corrupt; There is none who does good, No, not one.", 
                "num": 3
              }, 
              {
                "text": "Have all the workers of iniquity no knowledge, Who eat up my people as they eat bread, And do not call on the LORD?", 
                "num": 4
              }, 
              {
                "text": "There they are in great fear, For God is with the generation of the righteous.", 
                "num": 5
              }, 
              {
                "text": "You shame the counsel of the poor, But the LORD is his refuge.", 
                "num": 6
              }, 
              {
                "text": "Oh, that the salvation of Israel would come out of Zion! When the LORD brings back the captivity of His people, Let Jacob rejoice and Israel be glad.", 
                "num": 7
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "LORD, who may abide in Your tabernacle? Who may dwell in Your holy hill?", 
                "num": 1
              }, 
              {
                "text": "He who walks uprightly, And works righteousness, And speaks the truth in his heart;", 
                "num": 2
              }, 
              {
                "text": "He who does not backbite with his tongue, Nor does evil to his neighbor, Nor does he take up a reproach against his friend;", 
                "num": 3
              }, 
              {
                "text": "In whose eyes a vile person is despised, But he honors those who fear the LORD; He who swears to his own hurt and does not change;", 
                "num": 4
              }, 
              {
                "text": "He who does not put out his money at usury, Nor does he take a bribe against the innocent. He who does these things shall never be moved.", 
                "num": 5
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Preserve me, O God, for in You I put my trust.", 
                "num": 1
              }, 
              {
                "text": "O my soul, you have said to the LORD, \"You are my Lord, My goodness is nothing apart from You.\"", 
                "num": 2
              }, 
              {
                "text": "As for the saints who are on the earth, \"They are the excellent ones, in whom is all my delight.\"", 
                "num": 3
              }, 
              {
                "text": "Their sorrows shall be multiplied who hasten after another god; Their drink offerings of blood I will not offer, Nor take up their names on my lips.", 
                "num": 4
              }, 
              {
                "text": "O LORD, You are the portion of my inheritance and my cup; You maintain my lot.", 
                "num": 5
              }, 
              {
                "text": "The lines have fallen to me in pleasant places; Yes, I have a good inheritance.", 
                "num": 6
              }, 
              {
                "text": "I will bless the LORD who has given me counsel; My heart also instructs me in the night seasons.", 
                "num": 7
              }, 
              {
                "text": "I have set the LORD always before me; Because He is at my right hand I shall not be moved.", 
                "num": 8
              }, 
              {
                "text": "Therefore my heart is glad, and my glory rejoices; My flesh also will rest in hope.", 
                "num": 9
              }, 
              {
                "text": "For You will not leave my soul in Sheol, Nor will You allow Your Holy One to see corruption.", 
                "num": 10
              }, 
              {
                "text": "You will show me the path of life; In Your presence is fullness of joy; At Your right hand are pleasures forevermore.", 
                "num": 11
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Hear a just cause, O LORD, Attend to my cry; Give ear to my prayer which is not from deceitful lips.", 
                "num": 1
              }, 
              {
                "text": "Let my vindication come from Your presence; Let Your eyes look on the things that are upright.", 
                "num": 2
              }, 
              {
                "text": "You have tested my heart; You have visited me in the night; You have tried me and have found nothing; I have purposed that my mouth shall not transgress.", 
                "num": 3
              }, 
              {
                "text": "Concerning the works of men, By the word of Your lips, I have kept away from the paths of the destroyer.", 
                "num": 4
              }, 
              {
                "text": "Uphold my steps in Your paths, That my footsteps may not slip.", 
                "num": 5
              }, 
              {
                "text": "I have called upon You, for You will hear me, O God; Incline Your ear to me, and hear my speech.", 
                "num": 6
              }, 
              {
                "text": "Show Your marvelous lovingkindness by Your right hand, O You who save those who trust in You From those who rise up against them.", 
                "num": 7
              }, 
              {
                "text": "Keep me as the apple of Your eye; Hide me under the shadow of Your wings,", 
                "num": 8
              }, 
              {
                "text": "From the wicked who oppress me, From my deadly enemies who surround me.", 
                "num": 9
              }, 
              {
                "text": "They have closed up their fat hearts; With their mouths they speak proudly.", 
                "num": 10
              }, 
              {
                "text": "They have now surrounded us in our steps; They have set their eyes, crouching down to the earth,", 
                "num": 11
              }, 
              {
                "text": "As a lion is eager to tear his prey, And like a young lion lurking in secret places.", 
                "num": 12
              }, 
              {
                "text": "Arise, O LORD, Confront him, cast him down; Deliver my life from the wicked with Your sword,", 
                "num": 13
              }, 
              {
                "text": "With Your hand from men, O LORD, From men of the world who have their portion in this life, And whose belly You fill with Your hidden treasure. They are satisfied with children, And leave the rest of their possession for their babes.", 
                "num": 14
              }, 
              {
                "text": "As for me, I will see Your face in righteousness; I shall be satisfied when I awake in Your likeness.", 
                "num": 15
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "I will love You, O LORD, my strength.", 
                "num": 1
              }, 
              {
                "text": "The LORD is my rock and my fortress and my deliverer; My God, my strength, in whom I will trust; My shield and the horn of my salvation, my stronghold.", 
                "num": 2
              }, 
              {
                "text": "I will call upon the LORD, who is worthy to be praised; So shall I be saved from my enemies.", 
                "num": 3
              }, 
              {
                "text": "The pangs of death surrounded me, And the floods of ungodliness made me afraid.", 
                "num": 4
              }, 
              {
                "text": "The sorrows of Sheol surrounded me; The snares of death confronted me.", 
                "num": 5
              }, 
              {
                "text": "In my distress I called upon the LORD, And cried out to my God; He heard my voice from His temple, And my cry came before Him, even to His ears.", 
                "num": 6
              }, 
              {
                "text": "Then the earth shook and trembled; The foundations of the hills also quaked and were shaken, Because He was angry.", 
                "num": 7
              }, 
              {
                "text": "Smoke went up from His nostrils, And devouring fire from His mouth; Coals were kindled by it.", 
                "num": 8
              }, 
              {
                "text": "He bowed the heavens also, and came down With darkness under His feet.", 
                "num": 9
              }, 
              {
                "text": "And He rode upon a cherub, and flew; He flew upon the wings of the wind.", 
                "num": 10
              }, 
              {
                "text": "He made darkness His secret place; His canopy around Him was dark waters And thick clouds of the skies.", 
                "num": 11
              }, 
              {
                "text": "From the brightness before Him, His thick clouds passed with hailstones and coals of fire.", 
                "num": 12
              }, 
              {
                "text": "The LORD thundered from heaven, And the Most High uttered His voice, Hailstones and coals of fire.", 
                "num": 13
              }, 
              {
                "text": "He sent out His arrows and scattered the foe, Lightnings in abundance, and He vanquished them.", 
                "num": 14
              }, 
              {
                "text": "Then the channels of the sea were seen, The foundations of the world were uncovered At Your rebuke, O LORD, At the blast of the breath of Your nostrils.", 
                "num": 15
              }, 
              {
                "text": "He sent from above, He took me; He drew me out of many waters.", 
                "num": 16
              }, 
              {
                "text": "He delivered me from my strong enemy, From those who hated me, For they were too strong for me.", 
                "num": 17
              }, 
              {
                "text": "They confronted me in the day of my calamity, But the LORD was my support.", 
                "num": 18
              }, 
              {
                "text": "He also brought me out into a broad place; He delivered me because He delighted in me.", 
                "num": 19
              }, 
              {
                "text": "The LORD rewarded me according to my righteousness; According to the cleanness of my hands He has recompensed me.", 
                "num": 20
              }, 
              {
                "text": "For I have kept the ways of the LORD, And have not wickedly departed from my God.", 
                "num": 21
              }, 
              {
                "text": "For all His judgments were before me, And I did not put away His statutes from me.", 
                "num": 22
              }, 
              {
                "text": "I was also blameless before Him, And I kept myself from my iniquity.", 
                "num": 23
              }, 
              {
                "text": "Therefore the LORD has recompensed me according to my righteousness, According to the cleanness of my hands in His sight.", 
                "num": 24
              }, 
              {
                "text": "With the merciful You will show Yourself merciful; With a blameless man You will show Yourself blameless;", 
                "num": 25
              }, 
              {
                "text": "With the pure You will show Yourself pure; And with the devious You will show Yourself shrewd.", 
                "num": 26
              }, 
              {
                "text": "For You will save the humble people, But will bring down haughty looks.", 
                "num": 27
              }, 
              {
                "text": "For You will light my lamp; The LORD my God will enlighten my darkness.", 
                "num": 28
              }, 
              {
                "text": "For by You I can run against a troop, By my God I can leap over a wall.", 
                "num": 29
              }, 
              {
                "text": "As for God, His way is perfect; The word of the LORD is proven; He is a shield to all who trust in Him.", 
                "num": 30
              }, 
              {
                "text": "For who is God, except the LORD? And who is a rock, except our God?", 
                "num": 31
              }, 
              {
                "text": "It is God who arms me with strength, And makes my way perfect.", 
                "num": 32
              }, 
              {
                "text": "He makes my feet like the feet of deer, And sets me on my high places.", 
                "num": 33
              }, 
              {
                "text": "He teaches my hands to make war, So that my arms can bend a bow of bronze.", 
                "num": 34
              }, 
              {
                "text": "You have also given me the shield of Your salvation; Your right hand has held me up, Your gentleness has made me great.", 
                "num": 35
              }, 
              {
                "text": "You enlarged my path under me, So my feet did not slip.", 
                "num": 36
              }, 
              {
                "text": "I have pursued my enemies and overtaken them; Neither did I turn back again till they were destroyed.", 
                "num": 37
              }, 
              {
                "text": "I have wounded them, So that they could not rise; They have fallen under my feet.", 
                "num": 38
              }, 
              {
                "text": "For You have armed me with strength for the battle; You have subdued under me those who rose up against me.", 
                "num": 39
              }, 
              {
                "text": "You have also given me the necks of my enemies, So that I destroyed those who hated me.", 
                "num": 40
              }, 
              {
                "text": "They cried out, but there was none to save; Even to the LORD, but He did not answer them.", 
                "num": 41
              }, 
              {
                "text": "Then I beat them as fine as the dust before the wind; I cast them out like dirt in the streets.", 
                "num": 42
              }, 
              {
                "text": "You have delivered me from the strivings of the people; You have made me the head of the nations; A people I have not known shall serve me.", 
                "num": 43
              }, 
              {
                "text": "As soon as they hear of me they obey me; The foreigners submit to me.", 
                "num": 44
              }, 
              {
                "text": "The foreigners fade away, And come frightened from their hideouts.", 
                "num": 45
              }, 
              {
                "text": "The LORD lives! Blessed be my Rock! Let the God of my salvation be exalted.", 
                "num": 46
              }, 
              {
                "text": "It is God who avenges me, And subdues the peoples under me;", 
                "num": 47
              }, 
              {
                "text": "He delivers me from my enemies. You also lift me up above those who rise against me; You have delivered me from the violent man.", 
                "num": 48
              }, 
              {
                "text": "Therefore I will give thanks to You, O LORD, among the Gentiles, And sing praises to Your name.", 
                "num": 49
              }, 
              {
                "text": "Great deliverance He gives to His king, And shows mercy to His anointed, To David and his descendants forevermore.", 
                "num": 50
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "The heavens declare the glory of God; And the firmament shows His handiwork.", 
                "num": 1
              }, 
              {
                "text": "Day unto day utters speech, And night unto night reveals knowledge.", 
                "num": 2
              }, 
              {
                "text": "There is no speech nor language Where their voice is not heard.", 
                "num": 3
              }, 
              {
                "text": "Their line has gone out through all the earth, And their words to the end of the world. In them He has set a tabernacle for the sun,", 
                "num": 4
              }, 
              {
                "text": "Which is like a bridegroom coming out of his chamber, And rejoices like a strong man to run its race.", 
                "num": 5
              }, 
              {
                "text": "Its rising is from one end of heaven, And its circuit to the other end; And there is nothing hidden from its heat.", 
                "num": 6
              }, 
              {
                "text": "The law of the LORD is perfect, converting the soul; The testimony of the LORD is sure, making wise the simple;", 
                "num": 7
              }, 
              {
                "text": "The statutes of the LORD are right, rejoicing the heart; The commandment of the LORD is pure, enlightening the eyes;", 
                "num": 8
              }, 
              {
                "text": "The fear of the LORD is clean, enduring forever; The judgments of the LORD are true and righteous altogether.", 
                "num": 9
              }, 
              {
                "text": "More to be desired are they than gold, Yea, than much fine gold; Sweeter also than honey and the honeycomb.", 
                "num": 10
              }, 
              {
                "text": "Moreover by them Your servant is warned, And in keeping them there is great reward.", 
                "num": 11
              }, 
              {
                "text": "Who can understand his errors? Cleanse me from secret faults.", 
                "num": 12
              }, 
              {
                "text": "Keep back Your servant also from presumptuous sins; Let them not have dominion over me. Then I shall be blameless, And I shall be innocent of great transgression.", 
                "num": 13
              }, 
              {
                "text": "Let the words of my mouth and the meditation of my heart Be acceptable in Your sight, O LORD, my strength and my Redeemer.", 
                "num": 14
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "May the LORD answer you in the day of trouble; May the name of the God of Jacob defend you;", 
                "num": 1
              }, 
              {
                "text": "May He send you help from the sanctuary, And strengthen you out of Zion;", 
                "num": 2
              }, 
              {
                "text": "May He remember all your offerings, And accept your burnt sacrifice.Selah", 
                "num": 3
              }, 
              {
                "text": "May He grant you according to your heart's desire, And fulfill all your purpose.", 
                "num": 4
              }, 
              {
                "text": "We will rejoice in your salvation, And in the name of our God we will set up our banners! May the LORD fulfill all your petitions.", 
                "num": 5
              }, 
              {
                "text": "Now I know that the LORD saves His anointed; He will answer him from His holy heaven With the saving strength of His right hand.", 
                "num": 6
              }, 
              {
                "text": "Some trust in chariots, and some in horses; But we will remember the name of the LORD our God.", 
                "num": 7
              }, 
              {
                "text": "They have bowed down and fallen; But we have risen and stand upright.", 
                "num": 8
              }, 
              {
                "text": "Save, LORD! May the King answer us when we call.", 
                "num": 9
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "The king shall have joy in Your strength, O LORD; And in Your salvation how greatly shall he rejoice!", 
                "num": 1
              }, 
              {
                "text": "You have given him his heart's desire, And have not withheld the request of his lips.Selah", 
                "num": 2
              }, 
              {
                "text": "For You meet him with the blessings of goodness; You set a crown of pure gold upon his head.", 
                "num": 3
              }, 
              {
                "text": "He asked life from You, and You gave it to him-- Length of days forever and ever.", 
                "num": 4
              }, 
              {
                "text": "His glory is great in Your salvation; Honor and majesty You have placed upon him.", 
                "num": 5
              }, 
              {
                "text": "For You have made him most blessed forever; You have made him exceedingly glad with Your presence.", 
                "num": 6
              }, 
              {
                "text": "For the king trusts in the LORD, And through the mercy of the Most High he shall not be moved.", 
                "num": 7
              }, 
              {
                "text": "Your hand will find all Your enemies; Your right hand will find those who hate You.", 
                "num": 8
              }, 
              {
                "text": "You shall make them as a fiery oven in the time of Your anger; The LORD shall swallow them up in His wrath, And the fire shall devour them.", 
                "num": 9
              }, 
              {
                "text": "Their offspring You shall destroy from the earth, And their descendants from among the sons of men.", 
                "num": 10
              }, 
              {
                "text": "For they intended evil against You; They devised a plot which they are not able to perform.", 
                "num": 11
              }, 
              {
                "text": "Therefore You will make them turn their back; You will make ready Your arrows on Your string toward their faces.", 
                "num": 12
              }, 
              {
                "text": "Be exalted, O LORD, in Your own strength! We will sing and praise Your power.", 
                "num": 13
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "My God, My God, why have You forsaken Me? Why are You so far from helping Me, And from the words of My groaning?", 
                "num": 1
              }, 
              {
                "text": "O My God, I cry in the daytime, but You do not hear; And in the night season, and am not silent.", 
                "num": 2
              }, 
              {
                "text": "But You are holy, Enthroned in the praises of Israel.", 
                "num": 3
              }, 
              {
                "text": "Our fathers trusted in You; They trusted, and You delivered them.", 
                "num": 4
              }, 
              {
                "text": "They cried to You, and were delivered; They trusted in You, and were not ashamed.", 
                "num": 5
              }, 
              {
                "text": "But I am a worm, and no man; A reproach of men, and despised by the people.", 
                "num": 6
              }, 
              {
                "text": "All those who see Me ridicule Me; They shoot out the lip, they shake the head, saying,", 
                "num": 7
              }, 
              {
                "text": "\"He trusted in the LORD, let Him rescue Him; Let Him deliver Him, since He delights in Him!\"", 
                "num": 8
              }, 
              {
                "text": "But You are He who took Me out of the womb; You made Me trust while on My mother's breasts.", 
                "num": 9
              }, 
              {
                "text": "I was cast upon You from birth. From My mother's womb You have been My God.", 
                "num": 10
              }, 
              {
                "text": "Be not far from Me, For trouble is near; For there is none to help.", 
                "num": 11
              }, 
              {
                "text": "Many bulls have surrounded Me; Strong bulls of Bashan have encircled Me.", 
                "num": 12
              }, 
              {
                "text": "They gape at Me with their mouths, Like a raging and roaring lion.", 
                "num": 13
              }, 
              {
                "text": "I am poured out like water, And all My bones are out of joint; My heart is like wax; It has melted within Me.", 
                "num": 14
              }, 
              {
                "text": "My strength is dried up like a potsherd, And My tongue clings to My jaws; You have brought Me to the dust of death.", 
                "num": 15
              }, 
              {
                "text": "For dogs have surrounded Me; The congregation of the wicked has enclosed Me. They pierced My hands and My feet;", 
                "num": 16
              }, 
              {
                "text": "I can count all My bones. They look and stare at Me.", 
                "num": 17
              }, 
              {
                "text": "They divide My garments among them, And for My clothing they cast lots.", 
                "num": 18
              }, 
              {
                "text": "But You, O LORD, do not be far from Me; O My Strength, hasten to help Me!", 
                "num": 19
              }, 
              {
                "text": "Deliver Me from the sword, My precious life from the power of the dog.", 
                "num": 20
              }, 
              {
                "text": "Save Me from the lion's mouth And from the horns of the wild oxen! You have answered Me.", 
                "num": 21
              }, 
              {
                "text": "I will declare Your name to My brethren; In the midst of the assembly I will praise You.", 
                "num": 22
              }, 
              {
                "text": "You who fear the LORD, praise Him! All you descendants of Jacob, glorify Him, And fear Him, all you offspring of Israel!", 
                "num": 23
              }, 
              {
                "text": "For He has not despised nor abhorred the affliction of the afflicted; Nor has He hidden His face from Him; But when He cried to Him, He heard.", 
                "num": 24
              }, 
              {
                "text": "My praise shall be of You in the great assembly; I will pay My vows before those who fear Him.", 
                "num": 25
              }, 
              {
                "text": "The poor shall eat and be satisfied; Those who seek Him will praise the LORD. Let your heart live forever!", 
                "num": 26
              }, 
              {
                "text": "All the ends of the world Shall remember and turn to the LORD, And all the families of the nations Shall worship before You.", 
                "num": 27
              }, 
              {
                "text": "For the kingdom is the LORD's, And He rules over the nations.", 
                "num": 28
              }, 
              {
                "text": "All the prosperous of the earth Shall eat and worship; All those who go down to the dust Shall bow before Him, Even he who cannot keep himself alive.", 
                "num": 29
              }, 
              {
                "text": "A posterity shall serve Him. It will be recounted of the Lord to the next generation,", 
                "num": 30
              }, 
              {
                "text": "They will come and declare His righteousness to a people who will be born, That He has done this.", 
                "num": 31
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "The LORD is my shepherd; I shall not want.", 
                "num": 1
              }, 
              {
                "text": "He makes me to lie down in green pastures; He leads me beside the still waters.", 
                "num": 2
              }, 
              {
                "text": "He restores my soul; He leads me in the paths of righteousness For His name's sake.", 
                "num": 3
              }, 
              {
                "text": "Yea, though I walk through the valley of the shadow of death, I will fear no evil; For You are with me; Your rod and Your staff, they comfort me.", 
                "num": 4
              }, 
              {
                "text": "You prepare a table before me in the presence of my enemies; You anoint my head with oil; My cup runs over.", 
                "num": 5
              }, 
              {
                "text": "Surely goodness and mercy shall follow me All the days of my life; And I will dwell in the house of the LORD Forever.", 
                "num": 6
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "The earth is the LORD's, and all its fullness, The world and those who dwell therein.", 
                "num": 1
              }, 
              {
                "text": "For He has founded it upon the seas, And established it upon the waters.", 
                "num": 2
              }, 
              {
                "text": "Who may ascend into the hill of the LORD? Or who may stand in His holy place?", 
                "num": 3
              }, 
              {
                "text": "He who has clean hands and a pure heart, Who has not lifted up his soul to an idol, Nor sworn deceitfully.", 
                "num": 4
              }, 
              {
                "text": "He shall receive blessing from the LORD, And righteousness from the God of his salvation.", 
                "num": 5
              }, 
              {
                "text": "This is Jacob, the generation of those who seek Him, Who seek Your face.Selah", 
                "num": 6
              }, 
              {
                "text": "Lift up your heads, O you gates! And be lifted up, you everlasting doors! And the King of glory shall come in.", 
                "num": 7
              }, 
              {
                "text": "Who is this King of glory? The LORD strong and mighty, The LORD mighty in battle.", 
                "num": 8
              }, 
              {
                "text": "Lift up your heads, O you gates! Lift up, you everlasting doors! And the King of glory shall come in.", 
                "num": 9
              }, 
              {
                "text": "Who is this King of glory? The LORD of hosts, He is the King of glory.Selah", 
                "num": 10
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "To You, O LORD, I lift up my soul.", 
                "num": 1
              }, 
              {
                "text": "O my God, I trust in You; Let me not be ashamed; Let not my enemies triumph over me.", 
                "num": 2
              }, 
              {
                "text": "Indeed, let no one who waits on You be ashamed; Let those be ashamed who deal treacherously without cause.", 
                "num": 3
              }, 
              {
                "text": "Show me Your ways, O LORD; Teach me Your paths.", 
                "num": 4
              }, 
              {
                "text": "Lead me in Your truth and teach me, For You are the God of my salvation; On You I wait all the day.", 
                "num": 5
              }, 
              {
                "text": "Remember, O LORD, Your tender mercies and Your lovingkindnesses, For they are from of old.", 
                "num": 6
              }, 
              {
                "text": "Do not remember the sins of my youth, nor my transgressions; According to Your mercy remember me, For Your goodness' sake, O LORD.", 
                "num": 7
              }, 
              {
                "text": "Good and upright is the LORD; Therefore He teaches sinners in the way.", 
                "num": 8
              }, 
              {
                "text": "The humble He guides in justice, And the humble He teaches His way.", 
                "num": 9
              }, 
              {
                "text": "All the paths of the LORD are mercy and truth, To such as keep His covenant and His testimonies.", 
                "num": 10
              }, 
              {
                "text": "For Your name's sake, O LORD, Pardon my iniquity, for it is great.", 
                "num": 11
              }, 
              {
                "text": "Who is the man that fears the LORD? Him shall He teach in the way He chooses.", 
                "num": 12
              }, 
              {
                "text": "He himself shall dwell in prosperity, And his descendants shall inherit the earth.", 
                "num": 13
              }, 
              {
                "text": "The secret of the LORD is with those who fear Him, And He will show them His covenant.", 
                "num": 14
              }, 
              {
                "text": "My eyes are ever toward the LORD, For He shall pluck my feet out of the net.", 
                "num": 15
              }, 
              {
                "text": "Turn Yourself to me, and have mercy on me, For I am desolate and afflicted.", 
                "num": 16
              }, 
              {
                "text": "The troubles of my heart have enlarged; Bring me out of my distresses!", 
                "num": 17
              }, 
              {
                "text": "Look on my affliction and my pain, And forgive all my sins.", 
                "num": 18
              }, 
              {
                "text": "Consider my enemies, for they are many; And they hate me with cruel hatred.", 
                "num": 19
              }, 
              {
                "text": "Keep my soul, and deliver me; Let me not be ashamed, for I put my trust in You.", 
                "num": 20
              }, 
              {
                "text": "Let integrity and uprightness preserve me, For I wait for You.", 
                "num": 21
              }, 
              {
                "text": "Redeem Israel, O God, Out of all their troubles!", 
                "num": 22
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Vindicate me, O LORD, For I have walked in my integrity. I have also trusted in the LORD; I shall not slip.", 
                "num": 1
              }, 
              {
                "text": "Examine me, O LORD, and prove me; Try my mind and my heart.", 
                "num": 2
              }, 
              {
                "text": "For Your lovingkindness is before my eyes, And I have walked in Your truth.", 
                "num": 3
              }, 
              {
                "text": "I have not sat with idolatrous mortals, Nor will I go in with hypocrites.", 
                "num": 4
              }, 
              {
                "text": "I have hated the assembly of evildoers, And will not sit with the wicked.", 
                "num": 5
              }, 
              {
                "text": "I will wash my hands in innocence; So I will go about Your altar, O LORD,", 
                "num": 6
              }, 
              {
                "text": "That I may proclaim with the voice of thanksgiving, And tell of all Your wondrous works.", 
                "num": 7
              }, 
              {
                "text": "LORD, I have loved the habitation of Your house, And the place where Your glory dwells.", 
                "num": 8
              }, 
              {
                "text": "Do not gather my soul with sinners, Nor my life with bloodthirsty men,", 
                "num": 9
              }, 
              {
                "text": "In whose hands is a sinister scheme, And whose right hand is full of bribes.", 
                "num": 10
              }, 
              {
                "text": "But as for me, I will walk in my integrity; Redeem me and be merciful to me.", 
                "num": 11
              }, 
              {
                "text": "My foot stands in an even place; In the congregations I will bless the LORD.", 
                "num": 12
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "The LORD is my light and my salvation; Whom shall I fear? The LORD is the strength of my life; Of whom shall I be afraid?", 
                "num": 1
              }, 
              {
                "text": "When the wicked came against me To eat up my flesh, My enemies and foes, They stumbled and fell.", 
                "num": 2
              }, 
              {
                "text": "Though an army may encamp against me, My heart shall not fear; Though war may rise against me, In this I will be confident.", 
                "num": 3
              }, 
              {
                "text": "One thing I have desired of the LORD, That will I seek: That I may dwell in the house of the LORD All the days of my life, To behold the beauty of the LORD, And to inquire in His temple.", 
                "num": 4
              }, 
              {
                "text": "For in the time of trouble He shall hide me in His pavilion; In the secret place of His tabernacle He shall hide me; He shall set me high upon a rock.", 
                "num": 5
              }, 
              {
                "text": "And now my head shall be lifted up above my enemies all around me; Therefore I will offer sacrifices of joy in His tabernacle; I will sing, yes, I will sing praises to the LORD.", 
                "num": 6
              }, 
              {
                "text": "Hear, O LORD, when I cry with my voice! Have mercy also upon me, and answer me.", 
                "num": 7
              }, 
              {
                "text": "When You said, \"Seek My face,\" My heart said to You, \"Your face, LORD, I will seek.\"", 
                "num": 8
              }, 
              {
                "text": "Do not hide Your face from me; Do not turn Your servant away in anger; You have been my help; Do not leave me nor forsake me, O God of my salvation.", 
                "num": 9
              }, 
              {
                "text": "When my father and my mother forsake me, Then the LORD will take care of me.", 
                "num": 10
              }, 
              {
                "text": "Teach me Your way, O LORD, And lead me in a smooth path, because of my enemies.", 
                "num": 11
              }, 
              {
                "text": "Do not deliver me to the will of my adversaries; For false witnesses have risen against me, And such as breathe out violence.", 
                "num": 12
              }, 
              {
                "text": "I would have lost heart, unless I had believed That I would see the goodness of the LORD In the land of the living.", 
                "num": 13
              }, 
              {
                "text": "Wait on the LORD; Be of good courage, And He shall strengthen your heart; Wait, I say, on the LORD!", 
                "num": 14
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "To You I will cry, O LORD my Rock: Do not be silent to me, Lest, if You are silent to me, I become like those who go down to the pit.", 
                "num": 1
              }, 
              {
                "text": "Hear the voice of my supplications When I cry to You, When I lift up my hands toward Your holy sanctuary.", 
                "num": 2
              }, 
              {
                "text": "Do not take me away with the wicked And with the workers of iniquity, Who speak peace to their neighbors, But evil is in their hearts.", 
                "num": 3
              }, 
              {
                "text": "Give them according to their deeds, And according to the wickedness of their endeavors; Give them according to the work of their hands; Render to them what they deserve.", 
                "num": 4
              }, 
              {
                "text": "Because they do not regard the works of the LORD, Nor the operation of His hands, He shall destroy them And not build them up.", 
                "num": 5
              }, 
              {
                "text": "Blessed be the LORD, Because He has heard the voice of my supplications!", 
                "num": 6
              }, 
              {
                "text": "The LORD is my strength and my shield; My heart trusted in Him, and I am helped; Therefore my heart greatly rejoices, And with my song I will praise Him.", 
                "num": 7
              }, 
              {
                "text": "The LORD is their strength, And He is the saving refuge of His anointed.", 
                "num": 8
              }, 
              {
                "text": "Save Your people, And bless Your inheritance; Shepherd them also, And bear them up forever.", 
                "num": 9
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Give unto the LORD, O you mighty ones, Give unto the LORD glory and strength.", 
                "num": 1
              }, 
              {
                "text": "Give unto the LORD the glory due to His name; Worship the LORD in the beauty of holiness.", 
                "num": 2
              }, 
              {
                "text": "The voice of the LORD is over the waters; The God of glory thunders; The LORD is over many waters.", 
                "num": 3
              }, 
              {
                "text": "The voice of the LORD is powerful; The voice of the LORD is full of majesty.", 
                "num": 4
              }, 
              {
                "text": "The voice of the LORD breaks the cedars, Yes, the LORD splinters the cedars of Lebanon.", 
                "num": 5
              }, 
              {
                "text": "He makes them also skip like a calf, Lebanon and Sirion like a young wild ox.", 
                "num": 6
              }, 
              {
                "text": "The voice of the LORD divides the flames of fire.", 
                "num": 7
              }, 
              {
                "text": "The voice of the LORD shakes the wilderness; The LORD shakes the Wilderness of Kadesh.", 
                "num": 8
              }, 
              {
                "text": "The voice of the LORD makes the deer give birth, And strips the forests bare; And in His temple everyone says, \"Glory!\"", 
                "num": 9
              }, 
              {
                "text": "The LORD sat enthroned at the Flood, And the LORD sits as King forever.", 
                "num": 10
              }, 
              {
                "text": "The LORD will give strength to His people; The LORD will bless His people with peace.", 
                "num": 11
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "I will extol You, O LORD, for You have lifted me up, And have not let my foes rejoice over me.", 
                "num": 1
              }, 
              {
                "text": "O LORD my God, I cried out to You, And You healed me.", 
                "num": 2
              }, 
              {
                "text": "O LORD, You brought my soul up from the grave; You have kept me alive, that I should not go down to the pit.", 
                "num": 3
              }, 
              {
                "text": "Sing praise to the LORD, you saints of His, And give thanks at the remembrance of His holy name.", 
                "num": 4
              }, 
              {
                "text": "For His anger is but for a moment, His favor is for life; Weeping may endure for a night, But joy comes in the morning.", 
                "num": 5
              }, 
              {
                "text": "Now in my prosperity I said, \"I shall never be moved.\"", 
                "num": 6
              }, 
              {
                "text": "LORD, by Your favor You have made my mountain stand strong; You hid Your face, and I was troubled.", 
                "num": 7
              }, 
              {
                "text": "I cried out to You, O LORD; And to the LORD I made supplication:", 
                "num": 8
              }, 
              {
                "text": "\"What profit is there in my blood, When I go down to the pit? Will the dust praise You? Will it declare Your truth?", 
                "num": 9
              }, 
              {
                "text": "Hear, O LORD, and have mercy on me; LORD, be my helper!\"", 
                "num": 10
              }, 
              {
                "text": "You have turned for me my mourning into dancing; You have put off my sackcloth and clothed me with gladness,", 
                "num": 11
              }, 
              {
                "text": "To the end that my glory may sing praise to You and not be silent. O LORD my God, I will give thanks to You forever.", 
                "num": 12
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "In You, O LORD, I put my trust; Let me never be ashamed; Deliver me in Your righteousness.", 
                "num": 1
              }, 
              {
                "text": "Bow down Your ear to me, Deliver me speedily; Be my rock of refuge, A fortress of defense to save me.", 
                "num": 2
              }, 
              {
                "text": "For You are my rock and my fortress; Therefore, for Your name's sake, Lead me and guide me.", 
                "num": 3
              }, 
              {
                "text": "Pull me out of the net which they have secretly laid for me, For You are my strength.", 
                "num": 4
              }, 
              {
                "text": "Into Your hand I commit my spirit; You have redeemed me, O LORD God of truth.", 
                "num": 5
              }, 
              {
                "text": "I have hated those who regard useless idols; But I trust in the LORD.", 
                "num": 6
              }, 
              {
                "text": "I will be glad and rejoice in Your mercy, For You have considered my trouble; You have known my soul in adversities,", 
                "num": 7
              }, 
              {
                "text": "And have not shut me up into the hand of the enemy; You have set my feet in a wide place.", 
                "num": 8
              }, 
              {
                "text": "Have mercy on me, O LORD, for I am in trouble; My eye wastes away with grief, Yes, my soul and my body!", 
                "num": 9
              }, 
              {
                "text": "For my life is spent with grief, And my years with sighing; My strength fails because of my iniquity, And my bones waste away.", 
                "num": 10
              }, 
              {
                "text": "I am a reproach among all my enemies, But especially among my neighbors, And am repulsive to my acquaintances; Those who see me outside flee from me.", 
                "num": 11
              }, 
              {
                "text": "I am forgotten like a dead man, out of mind; I am like a broken vessel.", 
                "num": 12
              }, 
              {
                "text": "For I hear the slander of many; Fear is on every side; While they take counsel together against me, They scheme to take away my life.", 
                "num": 13
              }, 
              {
                "text": "But as for me, I trust in You, O LORD; I say, \"You are my God.\"", 
                "num": 14
              }, 
              {
                "text": "My times are in Your hand; Deliver me from the hand of my enemies, And from those who persecute me.", 
                "num": 15
              }, 
              {
                "text": "Make Your face shine upon Your servant; Save me for Your mercies' sake.", 
                "num": 16
              }, 
              {
                "text": "Do not let me be ashamed, O LORD, for I have called upon You; Let the wicked be ashamed; Let them be silent in the grave.", 
                "num": 17
              }, 
              {
                "text": "Let the lying lips be put to silence, Which speak insolent things proudly and contemptuously against the righteous.", 
                "num": 18
              }, 
              {
                "text": "Oh, how great is Your goodness, Which You have laid up for those who fear You, Which You have prepared for those who trust in You In the presence of the sons of men!", 
                "num": 19
              }, 
              {
                "text": "You shall hide them in the secret place of Your presence From the plots of man; You shall keep them secretly in a pavilion From the strife of tongues.", 
                "num": 20
              }, 
              {
                "text": "Blessed be the LORD, For He has shown me His marvelous kindness in a strong city!", 
                "num": 21
              }, 
              {
                "text": "For I said in my haste, \"I am cut off from before Your eyes\"; Nevertheless You heard the voice of my supplications When I cried out to You.", 
                "num": 22
              }, 
              {
                "text": "Oh, love the LORD, all you His saints! For the LORD preserves the faithful, And fully repays the proud person.", 
                "num": 23
              }, 
              {
                "text": "Be of good courage, And He shall strengthen your heart, All you who hope in the LORD.", 
                "num": 24
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "Blessed is he whose transgression is forgiven, Whose sin is covered.", 
                "num": 1
              }, 
              {
                "text": "Blessed is the man to whom the LORD does not impute iniquity, And in whose spirit there is no deceit.", 
                "num": 2
              }, 
              {
                "text": "When I kept silent, my bones grew old Through my groaning all the day long.", 
                "num": 3
              }, 
              {
                "text": "For day and night Your hand was heavy upon me; My vitality was turned into the drought of summer.Selah", 
                "num": 4
              }, 
              {
                "text": "I acknowledged my sin to You, And my iniquity I have not hidden. I said, \"I will confess my transgressions to the LORD,\" And You forgave the iniquity of my sin.Selah", 
                "num": 5
              }, 
              {
                "text": "For this cause everyone who is godly shall pray to You In a time when You may be found; Surely in a flood of great waters They shall not come near him.", 
                "num": 6
              }, 
              {
                "text": "You are my hiding place; You shall preserve me from trouble; You shall surround me with songs of deliverance.Selah", 
                "num": 7
              }, 
              {
                "text": "I will instruct you and teach you in the way you should go; I will guide you with My eye.", 
                "num": 8
              }, 
              {
                "text": "Do not be like the horse or like the mule, Which have no understanding, Which must be harnessed with bit and bridle, Else they will not come near you.", 
                "num": 9
              }, 
              {
                "text": "Many sorrows shall be to the wicked; But he who trusts in the LORD, mercy shall surround him.", 
                "num": 10
              }, 
              {
                "text": "Be glad in the LORD and rejoice, you righteous; And shout for joy, all you upright in heart!", 
                "num": 11
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Rejoice in the LORD, O you righteous! For praise from the upright is beautiful.", 
                "num": 1
              }, 
              {
                "text": "Praise the LORD with the harp; Make melody to Him with an instrument of ten strings.", 
                "num": 2
              }, 
              {
                "text": "Sing to Him a new song; Play skillfully with a shout of joy.", 
                "num": 3
              }, 
              {
                "text": "For the word of the LORD is right, And all His work is done in truth.", 
                "num": 4
              }, 
              {
                "text": "He loves righteousness and justice; The earth is full of the goodness of the LORD.", 
                "num": 5
              }, 
              {
                "text": "By the word of the LORD the heavens were made, And all the host of them by the breath of His mouth.", 
                "num": 6
              }, 
              {
                "text": "He gathers the waters of the sea together as a heap; He lays up the deep in storehouses.", 
                "num": 7
              }, 
              {
                "text": "Let all the earth fear the LORD; Let all the inhabitants of the world stand in awe of Him.", 
                "num": 8
              }, 
              {
                "text": "For He spoke, and it was done; He commanded, and it stood fast.", 
                "num": 9
              }, 
              {
                "text": "The LORD brings the counsel of the nations to nothing; He makes the plans of the peoples of no effect.", 
                "num": 10
              }, 
              {
                "text": "The counsel of the LORD stands forever, The plans of His heart to all generations.", 
                "num": 11
              }, 
              {
                "text": "Blessed is the nation whose God is the LORD, The people He has chosen as His own inheritance.", 
                "num": 12
              }, 
              {
                "text": "The LORD looks from heaven; He sees all the sons of men.", 
                "num": 13
              }, 
              {
                "text": "From the place of His dwelling He looks On all the inhabitants of the earth;", 
                "num": 14
              }, 
              {
                "text": "He fashions their hearts individually; He considers all their works.", 
                "num": 15
              }, 
              {
                "text": "No king is saved by the multitude of an army; A mighty man is not delivered by great strength.", 
                "num": 16
              }, 
              {
                "text": "A horse is a vain hope for safety; Neither shall it deliver any by its great strength.", 
                "num": 17
              }, 
              {
                "text": "Behold, the eye of the LORD is on those who fear Him, On those who hope in His mercy,", 
                "num": 18
              }, 
              {
                "text": "To deliver their soul from death, And to keep them alive in famine.", 
                "num": 19
              }, 
              {
                "text": "Our soul waits for the LORD; He is our help and our shield.", 
                "num": 20
              }, 
              {
                "text": "For our heart shall rejoice in Him, Because we have trusted in His holy name.", 
                "num": 21
              }, 
              {
                "text": "Let Your mercy, O LORD, be upon us, Just as we hope in You.", 
                "num": 22
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "I will bless the LORD at all times; His praise shall continually be in my mouth.", 
                "num": 1
              }, 
              {
                "text": "My soul shall make its boast in the LORD; The humble shall hear of it and be glad.", 
                "num": 2
              }, 
              {
                "text": "Oh, magnify the LORD with me, And let us exalt His name together.", 
                "num": 3
              }, 
              {
                "text": "I sought the LORD, and He heard me, And delivered me from all my fears.", 
                "num": 4
              }, 
              {
                "text": "They looked to Him and were radiant, And their faces were not ashamed.", 
                "num": 5
              }, 
              {
                "text": "This poor man cried out, and the LORD heard him, And saved him out of all his troubles.", 
                "num": 6
              }, 
              {
                "text": "The angel of the LORD encamps all around those who fear Him, And delivers them.", 
                "num": 7
              }, 
              {
                "text": "Oh, taste and see that the LORD is good; Blessed is the man who trusts in Him!", 
                "num": 8
              }, 
              {
                "text": "Oh, fear the LORD, you His saints! There is no want to those who fear Him.", 
                "num": 9
              }, 
              {
                "text": "The young lions lack and suffer hunger; But those who seek the LORD shall not lack any good thing.", 
                "num": 10
              }, 
              {
                "text": "Come, you children, listen to me; I will teach you the fear of the LORD.", 
                "num": 11
              }, 
              {
                "text": "Who is the man who desires life, And loves many days, that he may see good?", 
                "num": 12
              }, 
              {
                "text": "Keep your tongue from evil, And your lips from speaking deceit.", 
                "num": 13
              }, 
              {
                "text": "Depart from evil and do good; Seek peace and pursue it.", 
                "num": 14
              }, 
              {
                "text": "The eyes of the LORD are on the righteous, And His ears are open to their cry.", 
                "num": 15
              }, 
              {
                "text": "The face of the LORD is against those who do evil, To cut off the remembrance of them from the earth.", 
                "num": 16
              }, 
              {
                "text": "The righteous cry out, and the LORD hears, And delivers them out of all their troubles.", 
                "num": 17
              }, 
              {
                "text": "The LORD is near to those who have a broken heart, And saves such as have a contrite spirit.", 
                "num": 18
              }, 
              {
                "text": "Many are the afflictions of the righteous, But the LORD delivers him out of them all.", 
                "num": 19
              }, 
              {
                "text": "He guards all his bones; Not one of them is broken.", 
                "num": 20
              }, 
              {
                "text": "Evil shall slay the wicked, And those who hate the righteous shall be condemned.", 
                "num": 21
              }, 
              {
                "text": "The LORD redeems the soul of His servants, And none of those who trust in Him shall be condemned.", 
                "num": 22
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Plead my cause, O LORD, with those who strive with me; Fight against those who fight against me.", 
                "num": 1
              }, 
              {
                "text": "Take hold of shield and buckler, And stand up for my help.", 
                "num": 2
              }, 
              {
                "text": "Also draw out the spear, And stop those who pursue me. Say to my soul, \"I am your salvation.\"", 
                "num": 3
              }, 
              {
                "text": "Let those be put to shame and brought to dishonor Who seek after my life; Let those be turned back and brought to confusion Who plot my hurt.", 
                "num": 4
              }, 
              {
                "text": "Let them be like chaff before the wind, And let the angel of the LORD chase them.", 
                "num": 5
              }, 
              {
                "text": "Let their way be dark and slippery, And let the angel of the LORD pursue them.", 
                "num": 6
              }, 
              {
                "text": "For without cause they have hidden their net for me in a pit, Which they have dug without cause for my life.", 
                "num": 7
              }, 
              {
                "text": "Let destruction come upon him unexpectedly, And let his net that he has hidden catch himself; Into that very destruction let him fall.", 
                "num": 8
              }, 
              {
                "text": "And my soul shall be joyful in the LORD; It shall rejoice in His salvation.", 
                "num": 9
              }, 
              {
                "text": "All my bones shall say, \"LORD, who is like You, Delivering the poor from him who is too strong for him, Yes, the poor and the needy from him who plunders him?\"", 
                "num": 10
              }, 
              {
                "text": "Fierce witnesses rise up; They ask me things that I do not know.", 
                "num": 11
              }, 
              {
                "text": "They reward me evil for good, To the sorrow of my soul.", 
                "num": 12
              }, 
              {
                "text": "But as for me, when they were sick, My clothing was sackcloth; I humbled myself with fasting; And my prayer would return to my own heart.", 
                "num": 13
              }, 
              {
                "text": "I paced about as though he were my friend or brother; I bowed down heavily, as one who mourns for his mother.", 
                "num": 14
              }, 
              {
                "text": "But in my adversity they rejoiced And gathered together; Attackers gathered against me, And I did not know it; They tore at me and did not cease;", 
                "num": 15
              }, 
              {
                "text": "With ungodly mockers at feasts They gnashed at me with their teeth.", 
                "num": 16
              }, 
              {
                "text": "Lord, how long will You look on? Rescue me from their destructions, My precious life from the lions.", 
                "num": 17
              }, 
              {
                "text": "I will give You thanks in the great assembly; I will praise You among many people.", 
                "num": 18
              }, 
              {
                "text": "Let them not rejoice over me who are wrongfully my enemies; Nor let them wink with the eye who hate me without a cause.", 
                "num": 19
              }, 
              {
                "text": "For they do not speak peace, But they devise deceitful matters Against the quiet ones in the land.", 
                "num": 20
              }, 
              {
                "text": "They also opened their mouth wide against me, And said, \"Aha, aha! Our eyes have seen it.\"", 
                "num": 21
              }, 
              {
                "text": "This You have seen, O LORD; Do not keep silence. O Lord, do not be far from me.", 
                "num": 22
              }, 
              {
                "text": "Stir up Yourself, and awake to my vindication, To my cause, my God and my Lord.", 
                "num": 23
              }, 
              {
                "text": "Vindicate me, O LORD my God, according to Your righteousness; And let them not rejoice over me.", 
                "num": 24
              }, 
              {
                "text": "Let them not say in their hearts, \"Ah, so we would have it!\" Let them not say, \"We have swallowed him up.\"", 
                "num": 25
              }, 
              {
                "text": "Let them be ashamed and brought to mutual confusion Who rejoice at my hurt; Let them be clothed with shame and dishonor Who exalt themselves against me.", 
                "num": 26
              }, 
              {
                "text": "Let them shout for joy and be glad, Who favor my righteous cause; And let them say continually, \"Let the LORD be magnified, Who has pleasure in the prosperity of His servant.\"", 
                "num": 27
              }, 
              {
                "text": "And my tongue shall speak of Your righteousness And of Your praise all the day long.", 
                "num": 28
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "An oracle within my heart concerning the transgression of the wicked: There is no fear of God before his eyes.", 
                "num": 1
              }, 
              {
                "text": "For he flatters himself in his own eyes, When he finds out his iniquity and when he hates.", 
                "num": 2
              }, 
              {
                "text": "The words of his mouth are wickedness and deceit; He has ceased to be wise and to do good.", 
                "num": 3
              }, 
              {
                "text": "He devises wickedness on his bed; He sets himself in a way that is not good; He does not abhor evil.", 
                "num": 4
              }, 
              {
                "text": "Your mercy, O LORD, is in the heavens; Your faithfulness reaches to the clouds.", 
                "num": 5
              }, 
              {
                "text": "Your righteousness is like the great mountains; Your judgments are a great deep; O LORD, You preserve man and beast.", 
                "num": 6
              }, 
              {
                "text": "How precious is Your lovingkindness, O God! Therefore the children of men put their trust under the shadow of Your wings.", 
                "num": 7
              }, 
              {
                "text": "They are abundantly satisfied with the fullness of Your house, And You give them drink from the river of Your pleasures.", 
                "num": 8
              }, 
              {
                "text": "For with You is the fountain of life; In Your light we see light.", 
                "num": 9
              }, 
              {
                "text": "Oh, continue Your lovingkindness to those who know You, And Your righteousness to the upright in heart.", 
                "num": 10
              }, 
              {
                "text": "Let not the foot of pride come against me, And let not the hand of the wicked drive me away.", 
                "num": 11
              }, 
              {
                "text": "There the workers of iniquity have fallen; They have been cast down and are not able to rise.", 
                "num": 12
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "Do not fret because of evildoers, Nor be envious of the workers of iniquity.", 
                "num": 1
              }, 
              {
                "text": "For they shall soon be cut down like the grass, And wither as the green herb.", 
                "num": 2
              }, 
              {
                "text": "Trust in the LORD, and do good; Dwell in the land, and feed on His faithfulness.", 
                "num": 3
              }, 
              {
                "text": "Delight yourself also in the LORD, And He shall give you the desires of your heart.", 
                "num": 4
              }, 
              {
                "text": "Commit your way to the LORD, Trust also in Him, And He shall bring it to pass.", 
                "num": 5
              }, 
              {
                "text": "He shall bring forth your righteousness as the light, And your justice as the noonday.", 
                "num": 6
              }, 
              {
                "text": "Rest in the LORD, and wait patiently for Him; Do not fret because of him who prospers in his way, Because of the man who brings wicked schemes to pass.", 
                "num": 7
              }, 
              {
                "text": "Cease from anger, and forsake wrath; Do not fret--it only causes harm.", 
                "num": 8
              }, 
              {
                "text": "For evildoers shall be cut off; But those who wait on the LORD, They shall inherit the earth.", 
                "num": 9
              }, 
              {
                "text": "For yet a little while and the wicked shall be no more; Indeed, you will look carefully for his place, But it shall be no more.", 
                "num": 10
              }, 
              {
                "text": "But the meek shall inherit the earth, And shall delight themselves in the abundance of peace.", 
                "num": 11
              }, 
              {
                "text": "The wicked plots against the just, And gnashes at him with his teeth.", 
                "num": 12
              }, 
              {
                "text": "The Lord laughs at him, For He sees that his day is coming.", 
                "num": 13
              }, 
              {
                "text": "The wicked have drawn the sword And have bent their bow, To cast down the poor and needy, To slay those who are of upright conduct.", 
                "num": 14
              }, 
              {
                "text": "Their sword shall enter their own heart, And their bows shall be broken.", 
                "num": 15
              }, 
              {
                "text": "A little that a righteous man has Is better than the riches of many wicked.", 
                "num": 16
              }, 
              {
                "text": "For the arms of the wicked shall be broken, But the LORD upholds the righteous.", 
                "num": 17
              }, 
              {
                "text": "The LORD knows the days of the upright, And their inheritance shall be forever.", 
                "num": 18
              }, 
              {
                "text": "They shall not be ashamed in the evil time, And in the days of famine they shall be satisfied.", 
                "num": 19
              }, 
              {
                "text": "But the wicked shall perish; And the enemies of the LORD, Like the splendor of the meadows, shall vanish. Into smoke they shall vanish away.", 
                "num": 20
              }, 
              {
                "text": "The wicked borrows and does not repay, But the righteous shows mercy and gives.", 
                "num": 21
              }, 
              {
                "text": "For those blessed by Him shall inherit the earth, But those cursed by Him shall be cut off.", 
                "num": 22
              }, 
              {
                "text": "The steps of a good man are ordered by the LORD, And He delights in his way.", 
                "num": 23
              }, 
              {
                "text": "Though he fall, he shall not be utterly cast down; For the LORD upholds him with His hand.", 
                "num": 24
              }, 
              {
                "text": "I have been young, and now am old; Yet I have not seen the righteous forsaken, Nor his descendants begging bread.", 
                "num": 25
              }, 
              {
                "text": "He is ever merciful, and lends; And his descendants are blessed.", 
                "num": 26
              }, 
              {
                "text": "Depart from evil, and do good; And dwell forevermore.", 
                "num": 27
              }, 
              {
                "text": "For the LORD loves justice, And does not forsake His saints; They are preserved forever, But the descendants of the wicked shall be cut off.", 
                "num": 28
              }, 
              {
                "text": "The righteous shall inherit the land, And dwell in it forever.", 
                "num": 29
              }, 
              {
                "text": "The mouth of the righteous speaks wisdom, And his tongue talks of justice.", 
                "num": 30
              }, 
              {
                "text": "The law of his God is in his heart; None of his steps shall slide.", 
                "num": 31
              }, 
              {
                "text": "The wicked watches the righteous, And seeks to slay him.", 
                "num": 32
              }, 
              {
                "text": "The LORD will not leave him in his hand, Nor condemn him when he is judged.", 
                "num": 33
              }, 
              {
                "text": "Wait on the LORD, And keep His way, And He shall exalt you to inherit the land; When the wicked are cut off, you shall see it.", 
                "num": 34
              }, 
              {
                "text": "I have seen the wicked in great power, And spreading himself like a native green tree.", 
                "num": 35
              }, 
              {
                "text": "Yet he passed away, and behold, he was no more; Indeed I sought him, but he could not be found.", 
                "num": 36
              }, 
              {
                "text": "Mark the blameless man, and observe the upright; For the future of that man is peace.", 
                "num": 37
              }, 
              {
                "text": "But the transgressors shall be destroyed together; The future of the wicked shall be cut off.", 
                "num": 38
              }, 
              {
                "text": "But the salvation of the righteous is from the LORD; He is their strength in the time of trouble.", 
                "num": 39
              }, 
              {
                "text": "And the LORD shall help them and deliver them; He shall deliver them from the wicked, And save them, Because they trust in Him.", 
                "num": 40
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "O LORD, do not rebuke me in Your wrath, Nor chasten me in Your hot displeasure!", 
                "num": 1
              }, 
              {
                "text": "For Your arrows pierce me deeply, And Your hand presses me down.", 
                "num": 2
              }, 
              {
                "text": "There is no soundness in my flesh Because of Your anger, Nor any health in my bones Because of my sin.", 
                "num": 3
              }, 
              {
                "text": "For my iniquities have gone over my head; Like a heavy burden they are too heavy for me.", 
                "num": 4
              }, 
              {
                "text": "My wounds are foul and festering Because of my foolishness.", 
                "num": 5
              }, 
              {
                "text": "I am troubled, I am bowed down greatly; I go mourning all the day long.", 
                "num": 6
              }, 
              {
                "text": "For my loins are full of inflammation, And there is no soundness in my flesh.", 
                "num": 7
              }, 
              {
                "text": "I am feeble and severely broken; I groan because of the turmoil of my heart.", 
                "num": 8
              }, 
              {
                "text": "Lord, all my desire is before You; And my sighing is not hidden from You.", 
                "num": 9
              }, 
              {
                "text": "My heart pants, my strength fails me; As for the light of my eyes, it also has gone from me.", 
                "num": 10
              }, 
              {
                "text": "My loved ones and my friends stand aloof from my plague, And my relatives stand afar off.", 
                "num": 11
              }, 
              {
                "text": "Those also who seek my life lay snares for me; Those who seek my hurt speak of destruction, And plan deception all the day long.", 
                "num": 12
              }, 
              {
                "text": "But I, like a deaf man, do not hear; And I am like a mute who does not open his mouth.", 
                "num": 13
              }, 
              {
                "text": "Thus I am like a man who does not hear, And in whose mouth is no response.", 
                "num": 14
              }, 
              {
                "text": "For in You, O LORD, I hope; You will hear, O Lord my God.", 
                "num": 15
              }, 
              {
                "text": "For I said, \"Hear me, lest they rejoice over me, Lest, when my foot slips, they exalt themselves against me.\"", 
                "num": 16
              }, 
              {
                "text": "For I am ready to fall, And my sorrow is continually before me.", 
                "num": 17
              }, 
              {
                "text": "For I will declare my iniquity; I will be in anguish over my sin.", 
                "num": 18
              }, 
              {
                "text": "But my enemies are vigorous, and they are strong; And those who hate me wrongfully have multiplied.", 
                "num": 19
              }, 
              {
                "text": "Those also who render evil for good, They are my adversaries, because I follow what is good.", 
                "num": 20
              }, 
              {
                "text": "Do not forsake me, O LORD; O my God, be not far from me!", 
                "num": 21
              }, 
              {
                "text": "Make haste to help me, O Lord, my salvation!", 
                "num": 22
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "I said, \"I will guard my ways, Lest I sin with my tongue; I will restrain my mouth with a muzzle, While the wicked are before me.\"", 
                "num": 1
              }, 
              {
                "text": "I was mute with silence, I held my peace even from good; And my sorrow was stirred up.", 
                "num": 2
              }, 
              {
                "text": "My heart was hot within me; While I was musing, the fire burned. Then I spoke with my tongue:", 
                "num": 3
              }, 
              {
                "text": "\"LORD, make me to know my end, And what is the measure of my days, That I may know how frail I am.", 
                "num": 4
              }, 
              {
                "text": "Indeed, You have made my days as handbreadths, And my age is as nothing before You; Certainly every man at his best state is but vapor.Selah", 
                "num": 5
              }, 
              {
                "text": "Surely every man walks about like a shadow; Surely they busy themselves in vain; He heaps up riches, And does not know who will gather them.", 
                "num": 6
              }, 
              {
                "text": "\"And now, Lord, what do I wait for? My hope is in You.", 
                "num": 7
              }, 
              {
                "text": "Deliver me from all my transgressions; Do not make me the reproach of the foolish.", 
                "num": 8
              }, 
              {
                "text": "I was mute, I did not open my mouth, Because it was You who did it.", 
                "num": 9
              }, 
              {
                "text": "Remove Your plague from me; I am consumed by the blow of Your hand.", 
                "num": 10
              }, 
              {
                "text": "When with rebukes You correct man for iniquity, You make his beauty melt away like a moth; Surely every man is vapor.Selah", 
                "num": 11
              }, 
              {
                "text": "\"Hear my prayer, O LORD, And give ear to my cry; Do not be silent at my tears; For I am a stranger with You, A sojourner, as all my fathers were.", 
                "num": 12
              }, 
              {
                "text": "Remove Your gaze from me, that I may regain strength, Before I go away and am no more.\"", 
                "num": 13
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "I waited patiently for the LORD; And He inclined to me, And heard my cry.", 
                "num": 1
              }, 
              {
                "text": "He also brought me up out of a horrible pit, Out of the miry clay, And set my feet upon a rock, And established my steps.", 
                "num": 2
              }, 
              {
                "text": "He has put a new song in my mouth-- Praise to our God; Many will see it and fear, And will trust in the LORD.", 
                "num": 3
              }, 
              {
                "text": "Blessed is that man who makes the LORD his trust, And does not respect the proud, nor such as turn aside to lies.", 
                "num": 4
              }, 
              {
                "text": "Many, O LORD my God, are Your wonderful works Which You have done; And Your thoughts toward us Cannot be recounted to You in order; If I would declare and speak of them, They are more than can be numbered.", 
                "num": 5
              }, 
              {
                "text": "Sacrifice and offering You did not desire; My ears You have opened. Burnt offering and sin offering You did not require.", 
                "num": 6
              }, 
              {
                "text": "Then I said, \"Behold, I come; In the scroll of the book it is written of me.", 
                "num": 7
              }, 
              {
                "text": "I delight to do Your will, O my God, And Your law is within my heart.\"", 
                "num": 8
              }, 
              {
                "text": "I have proclaimed the good news of righteousness In the great assembly; Indeed, I do not restrain my lips, O LORD, You Yourself know.", 
                "num": 9
              }, 
              {
                "text": "I have not hidden Your righteousness within my heart; I have declared Your faithfulness and Your salvation; I have not concealed Your lovingkindness and Your truth From the great assembly.", 
                "num": 10
              }, 
              {
                "text": "Do not withhold Your tender mercies from me, O LORD; Let Your lovingkindness and Your truth continually preserve me.", 
                "num": 11
              }, 
              {
                "text": "For innumerable evils have surrounded me; My iniquities have overtaken me, so that I am not able to look up; They are more than the hairs of my head; Therefore my heart fails me.", 
                "num": 12
              }, 
              {
                "text": "Be pleased, O LORD, to deliver me; O LORD, make haste to help me!", 
                "num": 13
              }, 
              {
                "text": "Let them be ashamed and brought to mutual confusion Who seek to destroy my life; Let them be driven backward and brought to dishonor Who wish me evil.", 
                "num": 14
              }, 
              {
                "text": "Let them be confounded because of their shame, Who say to me, \"Aha, aha!\"", 
                "num": 15
              }, 
              {
                "text": "Let all those who seek You rejoice and be glad in You; Let such as love Your salvation say continually, \"The LORD be magnified!\"", 
                "num": 16
              }, 
              {
                "text": "But I am poor and needy; Yet the LORD thinks upon me. You are my help and my deliverer; Do not delay, O my God.", 
                "num": 17
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "Blessed is he who considers the poor; The LORD will deliver him in time of trouble.", 
                "num": 1
              }, 
              {
                "text": "The LORD will preserve him and keep him alive, And he will be blessed on the earth; You will not deliver him to the will of his enemies.", 
                "num": 2
              }, 
              {
                "text": "The LORD will strengthen him on his bed of illness; You will sustain him on his sickbed.", 
                "num": 3
              }, 
              {
                "text": "I said, \"LORD, be merciful to me; Heal my soul, for I have sinned against You.\"", 
                "num": 4
              }, 
              {
                "text": "My enemies speak evil of me: \"When will he die, and his name perish?\"", 
                "num": 5
              }, 
              {
                "text": "And if he comes to see me, he speaks lies; His heart gathers iniquity to itself; When he goes out, he tells it.", 
                "num": 6
              }, 
              {
                "text": "All who hate me whisper together against me; Against me they devise my hurt.", 
                "num": 7
              }, 
              {
                "text": "\"An evil disease,\" they say, \"clings to him. And now that he lies down, he will rise up no more.\"", 
                "num": 8
              }, 
              {
                "text": "Even my own familiar friend in whom I trusted, Who ate my bread, Has lifted up his heel against me.", 
                "num": 9
              }, 
              {
                "text": "But You, O LORD, be merciful to me, and raise me up, That I may repay them.", 
                "num": 10
              }, 
              {
                "text": "By this I know that You are well pleased with me, Because my enemy does not triumph over me.", 
                "num": 11
              }, 
              {
                "text": "As for me, You uphold me in my integrity, And set me before Your face forever.", 
                "num": 12
              }, 
              {
                "text": "Blessed be the LORD God of Israel From everlasting to everlasting! Amen and Amen.", 
                "num": 13
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "As the deer pants for the water brooks, So pants my soul for You, O God.", 
                "num": 1
              }, 
              {
                "text": "My soul thirsts for God, for the living God. When shall I come and appear before God?", 
                "num": 2
              }, 
              {
                "text": "My tears have been my food day and night, While they continually say to me, \"Where is your God?\"", 
                "num": 3
              }, 
              {
                "text": "When I remember these things, I pour out my soul within me. For I used to go with the multitude; I went with them to the house of God, With the voice of joy and praise, With a multitude that kept a pilgrim feast.", 
                "num": 4
              }, 
              {
                "text": "Why are you cast down, O my soul? And why are you disquieted within me? Hope in God, for I shall yet praise Him For the help of His countenance.", 
                "num": 5
              }, 
              {
                "text": "O my God, my soul is cast down within me; Therefore I will remember You from the land of the Jordan, And from the heights of Hermon, From the Hill Mizar.", 
                "num": 6
              }, 
              {
                "text": "Deep calls unto deep at the noise of Your waterfalls; All Your waves and billows have gone over me.", 
                "num": 7
              }, 
              {
                "text": "The LORD will command His lovingkindness in the daytime, And in the night His song shall be with me-- A prayer to the God of my life.", 
                "num": 8
              }, 
              {
                "text": "I will say to God my Rock, \"Why have You forgotten me? Why do I go mourning because of the oppression of the enemy?\"", 
                "num": 9
              }, 
              {
                "text": "As with a breaking of my bones, My enemies reproach me, While they say to me all day long, \"Where is your God?\"", 
                "num": 10
              }, 
              {
                "text": "Why are you cast down, O my soul? And why are you disquieted within me? Hope in God; For I shall yet praise Him, The help of my countenance and my God.", 
                "num": 11
              }
            ], 
            "num": 42
          }, 
          {
            "verses": [
              {
                "text": "Vindicate me, O God, And plead my cause against an ungodly nation; Oh, deliver me from the deceitful and unjust man!", 
                "num": 1
              }, 
              {
                "text": "For You are the God of my strength; Why do You cast me off? Why do I go mourning because of the oppression of the enemy?", 
                "num": 2
              }, 
              {
                "text": "Oh, send out Your light and Your truth! Let them lead me; Let them bring me to Your holy hill And to Your tabernacle.", 
                "num": 3
              }, 
              {
                "text": "Then I will go to the altar of God, To God my exceeding joy; And on the harp I will praise You, O God, my God.", 
                "num": 4
              }, 
              {
                "text": "Why are you cast down, O my soul? And why are you disquieted within me? Hope in God; For I shall yet praise Him, The help of my countenance and my God.", 
                "num": 5
              }
            ], 
            "num": 43
          }, 
          {
            "verses": [
              {
                "text": "We have heard with our ears, O God, Our fathers have told us, The deeds You did in their days, In days of old:", 
                "num": 1
              }, 
              {
                "text": "You drove out the nations with Your hand, But them You planted; You afflicted the peoples, and cast them out.", 
                "num": 2
              }, 
              {
                "text": "For they did not gain possession of the land by their own sword, Nor did their own arm save them; But it was Your right hand, Your arm, and the light of Your countenance, Because You favored them.", 
                "num": 3
              }, 
              {
                "text": "You are my King, O God; Command victories for Jacob.", 
                "num": 4
              }, 
              {
                "text": "Through You we will push down our enemies; Through Your name we will trample those who rise up against us.", 
                "num": 5
              }, 
              {
                "text": "For I will not trust in my bow, Nor shall my sword save me.", 
                "num": 6
              }, 
              {
                "text": "But You have saved us from our enemies, And have put to shame those who hated us.", 
                "num": 7
              }, 
              {
                "text": "In God we boast all day long, And praise Your name forever.Selah", 
                "num": 8
              }, 
              {
                "text": "But You have cast us off and put us to shame, And You do not go out with our armies.", 
                "num": 9
              }, 
              {
                "text": "You make us turn back from the enemy, And those who hate us have taken spoil for themselves.", 
                "num": 10
              }, 
              {
                "text": "You have given us up like sheep intended for food, And have scattered us among the nations.", 
                "num": 11
              }, 
              {
                "text": "You sell Your people for next to nothing, And are not enriched by selling them.", 
                "num": 12
              }, 
              {
                "text": "You make us a reproach to our neighbors, A scorn and a derision to those all around us.", 
                "num": 13
              }, 
              {
                "text": "You make us a byword among the nations, A shaking of the head among the peoples.", 
                "num": 14
              }, 
              {
                "text": "My dishonor is continually before me, And the shame of my face has covered me,", 
                "num": 15
              }, 
              {
                "text": "Because of the voice of him who reproaches and reviles, Because of the enemy and the avenger.", 
                "num": 16
              }, 
              {
                "text": "All this has come upon us; But we have not forgotten You, Nor have we dealt falsely with Your covenant.", 
                "num": 17
              }, 
              {
                "text": "Our heart has not turned back, Nor have our steps departed from Your way;", 
                "num": 18
              }, 
              {
                "text": "But You have severely broken us in the place of jackals, And covered us with the shadow of death.", 
                "num": 19
              }, 
              {
                "text": "If we had forgotten the name of our God, Or stretched out our hands to a foreign god,", 
                "num": 20
              }, 
              {
                "text": "Would not God search this out? For He knows the secrets of the heart.", 
                "num": 21
              }, 
              {
                "text": "Yet for Your sake we are killed all day long; We are accounted as sheep for the slaughter.", 
                "num": 22
              }, 
              {
                "text": "Awake! Why do You sleep, O Lord? Arise! Do not cast us off forever.", 
                "num": 23
              }, 
              {
                "text": "Why do You hide Your face, And forget our affliction and our oppression?", 
                "num": 24
              }, 
              {
                "text": "For our soul is bowed down to the dust; Our body clings to the ground.", 
                "num": 25
              }, 
              {
                "text": "Arise for our help, And redeem us for Your mercies' sake.", 
                "num": 26
              }
            ], 
            "num": 44
          }, 
          {
            "verses": [
              {
                "text": "My heart is overflowing with a good theme; I recite my composition concerning the King; My tongue is the pen of a ready writer.", 
                "num": 1
              }, 
              {
                "text": "You are fairer than the sons of men; Grace is poured upon Your lips; Therefore God has blessed You forever.", 
                "num": 2
              }, 
              {
                "text": "Gird Your sword upon Your thigh, O Mighty One, With Your glory and Your majesty.", 
                "num": 3
              }, 
              {
                "text": "And in Your majesty ride prosperously because of truth, humility, and righteousness; And Your right hand shall teach You awesome things.", 
                "num": 4
              }, 
              {
                "text": "Your arrows are sharp in the heart of the King's enemies; The peoples fall under You.", 
                "num": 5
              }, 
              {
                "text": "Your throne, O God, is forever and ever; A scepter of righteousness is the scepter of Your kingdom.", 
                "num": 6
              }, 
              {
                "text": "You love righteousness and hate wickedness; Therefore God, Your God, has anointed You With the oil of gladness more than Your companions.", 
                "num": 7
              }, 
              {
                "text": "All Your garments are scented with myrrh and aloes and cassia, Out of the ivory palaces, by which they have made You glad.", 
                "num": 8
              }, 
              {
                "text": "Kings' daughters are among Your honorable women; At Your right hand stands the queen in gold from Ophir.", 
                "num": 9
              }, 
              {
                "text": "Listen, O daughter, Consider and incline your ear; Forget your own people also, and your father's house;", 
                "num": 10
              }, 
              {
                "text": "So the King will greatly desire your beauty; Because He is your Lord, worship Him.", 
                "num": 11
              }, 
              {
                "text": "And the daughter of Tyre will come with a gift; The rich among the people will seek your favor.", 
                "num": 12
              }, 
              {
                "text": "The royal daughter is all glorious within the palace; Her clothing is woven with gold.", 
                "num": 13
              }, 
              {
                "text": "She shall be brought to the King in robes of many colors; The virgins, her companions who follow her, shall be brought to You.", 
                "num": 14
              }, 
              {
                "text": "With gladness and rejoicing they shall be brought; They shall enter the King's palace.", 
                "num": 15
              }, 
              {
                "text": "Instead of Your fathers shall be Your sons, Whom You shall make princes in all the earth.", 
                "num": 16
              }, 
              {
                "text": "I will make Your name to be remembered in all generations; Therefore the people shall praise You forever and ever.", 
                "num": 17
              }
            ], 
            "num": 45
          }, 
          {
            "verses": [
              {
                "text": "God is our refuge and strength, A very present help in trouble.", 
                "num": 1
              }, 
              {
                "text": "Therefore we will not fear, Even though the earth be removed, And though the mountains be carried into the midst of the sea;", 
                "num": 2
              }, 
              {
                "text": "Though its waters roar and be troubled, Though the mountains shake with its swelling.Selah", 
                "num": 3
              }, 
              {
                "text": "There is a river whose streams shall make glad the city of God, The holy place of the tabernacle of the Most High.", 
                "num": 4
              }, 
              {
                "text": "God is in the midst of her, she shall not be moved; God shall help her, just at the break of dawn.", 
                "num": 5
              }, 
              {
                "text": "The nations raged, the kingdoms were moved; He uttered His voice, the earth melted.", 
                "num": 6
              }, 
              {
                "text": "The LORD of hosts is with us; The God of Jacob is our refuge.Selah", 
                "num": 7
              }, 
              {
                "text": "Come, behold the works of the LORD, Who has made desolations in the earth.", 
                "num": 8
              }, 
              {
                "text": "He makes wars cease to the end of the earth; He breaks the bow and cuts the spear in two; He burns the chariot in the fire.", 
                "num": 9
              }, 
              {
                "text": "Be still, and know that I am God; I will be exalted among the nations, I will be exalted in the earth!", 
                "num": 10
              }, 
              {
                "text": "The LORD of hosts is with us; The God of Jacob is our refuge.Selah", 
                "num": 11
              }
            ], 
            "num": 46
          }, 
          {
            "verses": [
              {
                "text": "Oh, clap your hands, all you peoples! Shout to God with the voice of triumph!", 
                "num": 1
              }, 
              {
                "text": "For the LORD Most High is awesome; He is a great King over all the earth.", 
                "num": 2
              }, 
              {
                "text": "He will subdue the peoples under us, And the nations under our feet.", 
                "num": 3
              }, 
              {
                "text": "He will choose our inheritance for us, The excellence of Jacob whom He loves.Selah", 
                "num": 4
              }, 
              {
                "text": "God has gone up with a shout, The LORD with the sound of a trumpet.", 
                "num": 5
              }, 
              {
                "text": "Sing praises to God, sing praises! Sing praises to our King, sing praises!", 
                "num": 6
              }, 
              {
                "text": "For God is the King of all the earth; Sing praises with understanding.", 
                "num": 7
              }, 
              {
                "text": "God reigns over the nations; God sits on His holy throne.", 
                "num": 8
              }, 
              {
                "text": "The princes of the people have gathered together, The people of the God of Abraham. For the shields of the earth belong to God; He is greatly exalted.", 
                "num": 9
              }
            ], 
            "num": 47
          }, 
          {
            "verses": [
              {
                "text": "Great is the LORD, and greatly to be praised In the city of our God, In His holy mountain.", 
                "num": 1
              }, 
              {
                "text": "Beautiful in elevation, The joy of the whole earth, Is Mount Zion on the sides of the north, The city of the great King.", 
                "num": 2
              }, 
              {
                "text": "God is in her palaces; He is known as her refuge.", 
                "num": 3
              }, 
              {
                "text": "For behold, the kings assembled, They passed by together.", 
                "num": 4
              }, 
              {
                "text": "They saw it, and so they marveled; They were troubled, they hastened away.", 
                "num": 5
              }, 
              {
                "text": "Fear took hold of them there, And pain, as of a woman in birth pangs,", 
                "num": 6
              }, 
              {
                "text": "As when You break the ships of Tarshish With an east wind.", 
                "num": 7
              }, 
              {
                "text": "As we have heard, So we have seen In the city of the LORD of hosts, In the city of our God: God will establish it forever.Selah", 
                "num": 8
              }, 
              {
                "text": "We have thought, O God, on Your lovingkindness, In the midst of Your temple.", 
                "num": 9
              }, 
              {
                "text": "According to Your name, O God, So is Your praise to the ends of the earth; Your right hand is full of righteousness.", 
                "num": 10
              }, 
              {
                "text": "Let Mount Zion rejoice, Let the daughters of Judah be glad, Because of Your judgments.", 
                "num": 11
              }, 
              {
                "text": "Walk about Zion, And go all around her. Count her towers;", 
                "num": 12
              }, 
              {
                "text": "Mark well her bulwarks; Consider her palaces; That you may tell it to the generation following.", 
                "num": 13
              }, 
              {
                "text": "For this is God, Our God forever and ever; He will be our guide Even to death.", 
                "num": 14
              }
            ], 
            "num": 48
          }, 
          {
            "verses": [
              {
                "text": "Hear this, all peoples; Give ear, all inhabitants of the world,", 
                "num": 1
              }, 
              {
                "text": "Both low and high, Rich and poor together.", 
                "num": 2
              }, 
              {
                "text": "My mouth shall speak wisdom, And the meditation of my heart shall give understanding.", 
                "num": 3
              }, 
              {
                "text": "I will incline my ear to a proverb; I will disclose my dark saying on the harp.", 
                "num": 4
              }, 
              {
                "text": "Why should I fear in the days of evil, When the iniquity at my heels surrounds me?", 
                "num": 5
              }, 
              {
                "text": "Those who trust in their wealth And boast in the multitude of their riches,", 
                "num": 6
              }, 
              {
                "text": "None of them can by any means redeem his brother, Nor give to God a ransom for him--", 
                "num": 7
              }, 
              {
                "text": "For the redemption of their souls is costly, And it shall cease forever--", 
                "num": 8
              }, 
              {
                "text": "That he should continue to live eternally, And not see the Pit.", 
                "num": 9
              }, 
              {
                "text": "For he sees wise men die; Likewise the fool and the senseless person perish, And leave their wealth to others.", 
                "num": 10
              }, 
              {
                "text": "Their inner thought is that their houses will last forever, Their dwelling places to all generations; They call their lands after their own names.", 
                "num": 11
              }, 
              {
                "text": "Nevertheless man, though in honor, does not remain; He is like the beasts that perish.", 
                "num": 12
              }, 
              {
                "text": "This is the way of those who are foolish, And of their posterity who approve their sayings.Selah", 
                "num": 13
              }, 
              {
                "text": "Like sheep they are laid in the grave; Death shall feed on them; The upright shall have dominion over them in the morning; And their beauty shall be consumed in the grave, far from their dwelling.", 
                "num": 14
              }, 
              {
                "text": "But God will redeem my soul from the power of the grave, For He shall receive me.Selah", 
                "num": 15
              }, 
              {
                "text": "Do not be afraid when one becomes rich, When the glory of his house is increased;", 
                "num": 16
              }, 
              {
                "text": "For when he dies he shall carry nothing away; His glory shall not descend after him.", 
                "num": 17
              }, 
              {
                "text": "Though while he lives he blesses himself (For men will praise you when you do well for yourself),", 
                "num": 18
              }, 
              {
                "text": "He shall go to the generation of his fathers; They shall never see light.", 
                "num": 19
              }, 
              {
                "text": "A man who is in honor, yet does not understand, Is like the beasts that perish.", 
                "num": 20
              }
            ], 
            "num": 49
          }, 
          {
            "verses": [
              {
                "text": "The Mighty One, God the LORD, Has spoken and called the earth From the rising of the sun to its going down.", 
                "num": 1
              }, 
              {
                "text": "Out of Zion, the perfection of beauty, God will shine forth.", 
                "num": 2
              }, 
              {
                "text": "Our God shall come, and shall not keep silent; A fire shall devour before Him, And it shall be very tempestuous all around Him.", 
                "num": 3
              }, 
              {
                "text": "He shall call to the heavens from above, And to the earth, that He may judge His people:", 
                "num": 4
              }, 
              {
                "text": "\"Gather My saints together to Me, Those who have made a covenant with Me by sacrifice.\"", 
                "num": 5
              }, 
              {
                "text": "Let the heavens declare His righteousness, For God Himself is Judge.Selah", 
                "num": 6
              }, 
              {
                "text": "\"Hear, O My people, and I will speak, O Israel, and I will testify against you; I am God, your God!", 
                "num": 7
              }, 
              {
                "text": "I will not rebuke you for your sacrifices Or your burnt offerings, Which are continually before Me.", 
                "num": 8
              }, 
              {
                "text": "I will not take a bull from your house, Nor goats out of your folds.", 
                "num": 9
              }, 
              {
                "text": "For every beast of the forest is Mine, And the cattle on a thousand hills.", 
                "num": 10
              }, 
              {
                "text": "I know all the birds of the mountains, And the wild beasts of the field are Mine.", 
                "num": 11
              }, 
              {
                "text": "\"If I were hungry, I would not tell you; For the world is Mine, and all its fullness.", 
                "num": 12
              }, 
              {
                "text": "Will I eat the flesh of bulls, Or drink the blood of goats?", 
                "num": 13
              }, 
              {
                "text": "Offer to God thanksgiving, And pay your vows to the Most High.", 
                "num": 14
              }, 
              {
                "text": "Call upon Me in the day of trouble; I will deliver you, and you shall glorify Me.\"", 
                "num": 15
              }, 
              {
                "text": "But to the wicked God says: \"What right have you to declare My statutes, Or take My covenant in your mouth,", 
                "num": 16
              }, 
              {
                "text": "Seeing you hate instruction And cast My words behind you?", 
                "num": 17
              }, 
              {
                "text": "When you saw a thief, you consented with him, And have been a partaker with adulterers.", 
                "num": 18
              }, 
              {
                "text": "You give your mouth to evil, And your tongue frames deceit.", 
                "num": 19
              }, 
              {
                "text": "You sit and speak against your brother; You slander your own mother's son.", 
                "num": 20
              }, 
              {
                "text": "These things you have done, and I kept silent; You thought that I was altogether like you; But I will rebuke you, And set them in order before your eyes.", 
                "num": 21
              }, 
              {
                "text": "\"Now consider this, you who forget God, Lest I tear you in pieces, And there be none to deliver:", 
                "num": 22
              }, 
              {
                "text": "Whoever offers praise glorifies Me; And to him who orders his conduct aright I will show the salvation of God.\"", 
                "num": 23
              }
            ], 
            "num": 50
          }, 
          {
            "verses": [
              {
                "text": "Have mercy upon me, O God, According to Your lovingkindness; According to the multitude of Your tender mercies, Blot out my transgressions.", 
                "num": 1
              }, 
              {
                "text": "Wash me thoroughly from my iniquity, And cleanse me from my sin.", 
                "num": 2
              }, 
              {
                "text": "For I acknowledge my transgressions, And my sin is always before me.", 
                "num": 3
              }, 
              {
                "text": "Against You, You only, have I sinned, And done this evil in Your sight-- That You may be found just when You speak, And blameless when You judge.", 
                "num": 4
              }, 
              {
                "text": "Behold, I was brought forth in iniquity, And in sin my mother conceived me.", 
                "num": 5
              }, 
              {
                "text": "Behold, You desire truth in the inward parts, And in the hidden part You will make me to know wisdom.", 
                "num": 6
              }, 
              {
                "text": "Purge me with hyssop, and I shall be clean; Wash me, and I shall be whiter than snow.", 
                "num": 7
              }, 
              {
                "text": "Make me hear joy and gladness, That the bones You have broken may rejoice.", 
                "num": 8
              }, 
              {
                "text": "Hide Your face from my sins, And blot out all my iniquities.", 
                "num": 9
              }, 
              {
                "text": "Create in me a clean heart, O God, And renew a steadfast spirit within me.", 
                "num": 10
              }, 
              {
                "text": "Do not cast me away from Your presence, And do not take Your Holy Spirit from me.", 
                "num": 11
              }, 
              {
                "text": "Restore to me the joy of Your salvation, And uphold me by Your generous Spirit.", 
                "num": 12
              }, 
              {
                "text": "Then I will teach transgressors Your ways, And sinners shall be converted to You.", 
                "num": 13
              }, 
              {
                "text": "Deliver me from the guilt of bloodshed, O God, The God of my salvation, And my tongue shall sing aloud of Your righteousness.", 
                "num": 14
              }, 
              {
                "text": "O Lord, open my lips, And my mouth shall show forth Your praise.", 
                "num": 15
              }, 
              {
                "text": "For You do not desire sacrifice, or else I would give it; You do not delight in burnt offering.", 
                "num": 16
              }, 
              {
                "text": "The sacrifices of God are a broken spirit, A broken and a contrite heart-- These, O God, You will not despise.", 
                "num": 17
              }, 
              {
                "text": "Do good in Your good pleasure to Zion; Build the walls of Jerusalem.", 
                "num": 18
              }, 
              {
                "text": "Then You shall be pleased with the sacrifices of righteousness, With burnt offering and whole burnt offering; Then they shall offer bulls on Your altar.", 
                "num": 19
              }
            ], 
            "num": 51
          }, 
          {
            "verses": [
              {
                "text": "Why do you boast in evil, O mighty man? The goodness of God endures continually.", 
                "num": 1
              }, 
              {
                "text": "Your tongue devises destruction, Like a sharp razor, working deceitfully.", 
                "num": 2
              }, 
              {
                "text": "You love evil more than good, Lying rather than speaking righteousness.Selah", 
                "num": 3
              }, 
              {
                "text": "You love all devouring words, You deceitful tongue.", 
                "num": 4
              }, 
              {
                "text": "God shall likewise destroy you forever; He shall take you away, and pluck you out of your dwelling place, And uproot you from the land of the living.Selah", 
                "num": 5
              }, 
              {
                "text": "The righteous also shall see and fear, And shall laugh at him, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Here is the man who did not make God his strength, But trusted in the abundance of his riches, And strengthened himself in his wickedness.\"", 
                "num": 7
              }, 
              {
                "text": "But I am like a green olive tree in the house of God; I trust in the mercy of God forever and ever.", 
                "num": 8
              }, 
              {
                "text": "I will praise You forever, Because You have done it; And in the presence of Your saints I will wait on Your name, for it is good.", 
                "num": 9
              }
            ], 
            "num": 52
          }, 
          {
            "verses": [
              {
                "text": "The fool has said in his heart, \"There is no God.\" They are corrupt, and have done abominable iniquity; There is none who does good.", 
                "num": 1
              }, 
              {
                "text": "God looks down from heaven upon the children of men, To see if there are any who understand, who seek God.", 
                "num": 2
              }, 
              {
                "text": "Every one of them has turned aside; They have together become corrupt; There is none who does good, No, not one.", 
                "num": 3
              }, 
              {
                "text": "Have the workers of iniquity no knowledge, Who eat up my people as they eat bread, And do not call upon God?", 
                "num": 4
              }, 
              {
                "text": "There they are in great fear Where no fear was, For God has scattered the bones of him who encamps against you; You have put them to shame, Because God has despised them.", 
                "num": 5
              }, 
              {
                "text": "Oh, that the salvation of Israel would come out of Zion! When God brings back the captivity of His people, Let Jacob rejoice and Israel be glad.", 
                "num": 6
              }
            ], 
            "num": 53
          }, 
          {
            "verses": [
              {
                "text": "Save me, O God, by Your name, And vindicate me by Your strength.", 
                "num": 1
              }, 
              {
                "text": "Hear my prayer, O God; Give ear to the words of my mouth.", 
                "num": 2
              }, 
              {
                "text": "For strangers have risen up against me, And oppressors have sought after my life; They have not set God before them.Selah", 
                "num": 3
              }, 
              {
                "text": "Behold, God is my helper; The Lord is with those who uphold my life.", 
                "num": 4
              }, 
              {
                "text": "He will repay my enemies for their evil. Cut them off in Your truth.", 
                "num": 5
              }, 
              {
                "text": "I will freely sacrifice to You; I will praise Your name, O LORD, for it is good.", 
                "num": 6
              }, 
              {
                "text": "For He has delivered me out of all trouble; And my eye has seen its desire upon my enemies.", 
                "num": 7
              }
            ], 
            "num": 54
          }, 
          {
            "verses": [
              {
                "text": "Give ear to my prayer, O God, And do not hide Yourself from my supplication.", 
                "num": 1
              }, 
              {
                "text": "Attend to me, and hear me; I am restless in my complaint, and moan noisily,", 
                "num": 2
              }, 
              {
                "text": "Because of the voice of the enemy, Because of the oppression of the wicked; For they bring down trouble upon me, And in wrath they hate me.", 
                "num": 3
              }, 
              {
                "text": "My heart is severely pained within me, And the terrors of death have fallen upon me.", 
                "num": 4
              }, 
              {
                "text": "Fearfulness and trembling have come upon me, And horror has overwhelmed me.", 
                "num": 5
              }, 
              {
                "text": "So I said, \"Oh, that I had wings like a dove! I would fly away and be at rest.", 
                "num": 6
              }, 
              {
                "text": "Indeed, I would wander far off, And remain in the wilderness.Selah", 
                "num": 7
              }, 
              {
                "text": "I would hasten my escape From the windy storm and tempest.\"", 
                "num": 8
              }, 
              {
                "text": "Destroy, O Lord, and divide their tongues, For I have seen violence and strife in the city.", 
                "num": 9
              }, 
              {
                "text": "Day and night they go around it on its walls; Iniquity and trouble are also in the midst of it.", 
                "num": 10
              }, 
              {
                "text": "Destruction is in its midst; Oppression and deceit do not depart from its streets.", 
                "num": 11
              }, 
              {
                "text": "For it is not an enemy who reproaches me; Then I could bear it. Nor is it one who hates me who has exalted himself against me; Then I could hide from him.", 
                "num": 12
              }, 
              {
                "text": "But it was you, a man my equal, My companion and my acquaintance.", 
                "num": 13
              }, 
              {
                "text": "We took sweet counsel together, And walked to the house of God in the throng.", 
                "num": 14
              }, 
              {
                "text": "Let death seize them; Let them go down alive into hell, For wickedness is in their dwellings and among them.", 
                "num": 15
              }, 
              {
                "text": "As for me, I will call upon God, And the LORD shall save me.", 
                "num": 16
              }, 
              {
                "text": "Evening and morning and at noon I will pray, and cry aloud, And He shall hear my voice.", 
                "num": 17
              }, 
              {
                "text": "He has redeemed my soul in peace from the battle that was against me, For there were many against me.", 
                "num": 18
              }, 
              {
                "text": "God will hear, and afflict them, Even He who abides from of old.Selah Because they do not change, Therefore they do not fear God.", 
                "num": 19
              }, 
              {
                "text": "He has put forth his hands against those who were at peace with him; He has broken his covenant.", 
                "num": 20
              }, 
              {
                "text": "The words of his mouth were smoother than butter, But war was in his heart; His words were softer than oil, Yet they were drawn swords.", 
                "num": 21
              }, 
              {
                "text": "Cast your burden on the LORD, And He shall sustain you; He shall never permit the righteous to be moved.", 
                "num": 22
              }, 
              {
                "text": "But You, O God, shall bring them down to the pit of destruction; Bloodthirsty and deceitful men shall not live out half their days; But I will trust in You.", 
                "num": 23
              }
            ], 
            "num": 55
          }, 
          {
            "verses": [
              {
                "text": "Be merciful to me, O God, for man would swallow me up; Fighting all day he oppresses me.", 
                "num": 1
              }, 
              {
                "text": "My enemies would hound me all day, For there are many who fight against me, O Most High.", 
                "num": 2
              }, 
              {
                "text": "Whenever I am afraid, I will trust in You.", 
                "num": 3
              }, 
              {
                "text": "In God (I will praise His word), In God I have put my trust; I will not fear. What can flesh do to me?", 
                "num": 4
              }, 
              {
                "text": "All day they twist my words; All their thoughts are against me for evil.", 
                "num": 5
              }, 
              {
                "text": "They gather together, They hide, they mark my steps, When they lie in wait for my life.", 
                "num": 6
              }, 
              {
                "text": "Shall they escape by iniquity? In anger cast down the peoples, O God!", 
                "num": 7
              }, 
              {
                "text": "You number my wanderings; Put my tears into Your bottle; Are they not in Your book?", 
                "num": 8
              }, 
              {
                "text": "When I cry out to You, Then my enemies will turn back; This I know, because God is for me.", 
                "num": 9
              }, 
              {
                "text": "In God (I will praise His word), In the LORD (I will praise His word),", 
                "num": 10
              }, 
              {
                "text": "In God I have put my trust; I will not be afraid. What can man do to me?", 
                "num": 11
              }, 
              {
                "text": "Vows made to You are binding upon me, O God; I will render praises to You,", 
                "num": 12
              }, 
              {
                "text": "For You have delivered my soul from death. Have You not kept my feet from falling, That I may walk before God In the light of the living?", 
                "num": 13
              }
            ], 
            "num": 56
          }, 
          {
            "verses": [
              {
                "text": "Be merciful to me, O God, be merciful to me! For my soul trusts in You; And in the shadow of Your wings I will make my refuge, Until these calamities have passed by.", 
                "num": 1
              }, 
              {
                "text": "I will cry out to God Most High, To God who performs all things for me.", 
                "num": 2
              }, 
              {
                "text": "He shall send from heaven and save me; He reproaches the one who would swallow me up.Selah God shall send forth His mercy and His truth.", 
                "num": 3
              }, 
              {
                "text": "My soul is among lions; I lie among the sons of men Who are set on fire, Whose teeth are spears and arrows, And their tongue a sharp sword.", 
                "num": 4
              }, 
              {
                "text": "Be exalted, O God, above the heavens; Let Your glory be above all the earth.", 
                "num": 5
              }, 
              {
                "text": "They have prepared a net for my steps; My soul is bowed down; They have dug a pit before me; Into the midst of it they themselves have fallen.Selah", 
                "num": 6
              }, 
              {
                "text": "My heart is steadfast, O God, my heart is steadfast; I will sing and give praise.", 
                "num": 7
              }, 
              {
                "text": "Awake, my glory! Awake, lute and harp! I will awaken the dawn.", 
                "num": 8
              }, 
              {
                "text": "I will praise You, O Lord, among the peoples; I will sing to You among the nations.", 
                "num": 9
              }, 
              {
                "text": "For Your mercy reaches unto the heavens, And Your truth unto the clouds.", 
                "num": 10
              }, 
              {
                "text": "Be exalted, O God, above the heavens; Let Your glory be above all the earth.", 
                "num": 11
              }
            ], 
            "num": 57
          }, 
          {
            "verses": [
              {
                "text": "Do you indeed speak righteousness, you silent ones? Do you judge uprightly, you sons of men?", 
                "num": 1
              }, 
              {
                "text": "No, in heart you work wickedness; You weigh out the violence of your hands in the earth.", 
                "num": 2
              }, 
              {
                "text": "The wicked are estranged from the womb; They go astray as soon as they are born, speaking lies.", 
                "num": 3
              }, 
              {
                "text": "Their poison is like the poison of a serpent; They are like the deaf cobra that stops its ear,", 
                "num": 4
              }, 
              {
                "text": "Which will not heed the voice of charmers, Charming ever so skillfully.", 
                "num": 5
              }, 
              {
                "text": "Break their teeth in their mouth, O God! Break out the fangs of the young lions, O LORD!", 
                "num": 6
              }, 
              {
                "text": "Let them flow away as waters which run continually; When he bends his bow, Let his arrows be as if cut in pieces.", 
                "num": 7
              }, 
              {
                "text": "Let them be like a snail which melts away as it goes, Like a stillborn child of a woman, that they may not see the sun.", 
                "num": 8
              }, 
              {
                "text": "Before your pots can feel the burning thorns, He shall take them away as with a whirlwind, As in His living and burning wrath.", 
                "num": 9
              }, 
              {
                "text": "The righteous shall rejoice when he sees the vengeance; He shall wash his feet in the blood of the wicked,", 
                "num": 10
              }, 
              {
                "text": "So that men will say, \"Surely there is a reward for the righteous; Surely He is God who judges in the earth.\"", 
                "num": 11
              }
            ], 
            "num": 58
          }, 
          {
            "verses": [
              {
                "text": "Deliver me from my enemies, O my God; Defend me from those who rise up against me.", 
                "num": 1
              }, 
              {
                "text": "Deliver me from the workers of iniquity, And save me from bloodthirsty men.", 
                "num": 2
              }, 
              {
                "text": "For look, they lie in wait for my life; The mighty gather against me, Not for my transgression nor for my sin, O LORD.", 
                "num": 3
              }, 
              {
                "text": "They run and prepare themselves through no fault of mine. Awake to help me, and behold!", 
                "num": 4
              }, 
              {
                "text": "You therefore, O LORD God of hosts, the God of Israel, Awake to punish all the nations; Do not be merciful to any wicked transgressors.Selah", 
                "num": 5
              }, 
              {
                "text": "At evening they return, They growl like a dog, And go all around the city.", 
                "num": 6
              }, 
              {
                "text": "Indeed, they belch with their mouth; Swords are in their lips; For they say, \"Who hears?\"", 
                "num": 7
              }, 
              {
                "text": "But You, O LORD, shall laugh at them; You shall have all the nations in derision.", 
                "num": 8
              }, 
              {
                "text": "I will wait for You, O You his Strength; For God is my defense.", 
                "num": 9
              }, 
              {
                "text": "My God of mercy shall come to meet me; God shall let me see my desire on my enemies.", 
                "num": 10
              }, 
              {
                "text": "Do not slay them, lest my people forget; Scatter them by Your power, And bring them down, O Lord our shield.", 
                "num": 11
              }, 
              {
                "text": "For the sin of their mouth and the words of their lips, Let them even be taken in their pride, And for the cursing and lying which they speak.", 
                "num": 12
              }, 
              {
                "text": "Consume them in wrath, consume them, That they may not be; And let them know that God rules in Jacob To the ends of the earth.Selah", 
                "num": 13
              }, 
              {
                "text": "And at evening they return, They growl like a dog, And go all around the city.", 
                "num": 14
              }, 
              {
                "text": "They wander up and down for food, And howl if they are not satisfied.", 
                "num": 15
              }, 
              {
                "text": "But I will sing of Your power; Yes, I will sing aloud of Your mercy in the morning; For You have been my defense And refuge in the day of my trouble.", 
                "num": 16
              }, 
              {
                "text": "To You, O my Strength, I will sing praises; For God is my defense, My God of mercy.", 
                "num": 17
              }
            ], 
            "num": 59
          }, 
          {
            "verses": [
              {
                "text": "O God, You have cast us off; You have broken us down; You have been displeased; Oh, restore us again!", 
                "num": 1
              }, 
              {
                "text": "You have made the earth tremble; You have broken it; Heal its breaches, for it is shaking.", 
                "num": 2
              }, 
              {
                "text": "You have shown Your people hard things; You have made us drink the wine of confusion.", 
                "num": 3
              }, 
              {
                "text": "You have given a banner to those who fear You, That it may be displayed because of the truth.Selah", 
                "num": 4
              }, 
              {
                "text": "That Your beloved may be delivered, Save with Your right hand, and hear me.", 
                "num": 5
              }, 
              {
                "text": "God has spoken in His holiness: \"I will rejoice; I will divide Shechem And measure out the Valley of Succoth.", 
                "num": 6
              }, 
              {
                "text": "Gilead is Mine, and Manasseh is Mine; Ephraim also is the helmet for My head; Judah is My lawgiver.", 
                "num": 7
              }, 
              {
                "text": "Moab is My washpot; Over Edom I will cast My shoe; Philistia, shout in triumph because of Me.\"", 
                "num": 8
              }, 
              {
                "text": "Who will bring me to the strong city? Who will lead me to Edom?", 
                "num": 9
              }, 
              {
                "text": "Is it not You, O God, who cast us off? And You, O God, who did not go out with our armies?", 
                "num": 10
              }, 
              {
                "text": "Give us help from trouble, For the help of man is useless.", 
                "num": 11
              }, 
              {
                "text": "Through God we will do valiantly, For it is He who shall tread down our enemies.", 
                "num": 12
              }
            ], 
            "num": 60
          }, 
          {
            "verses": [
              {
                "text": "Hear my cry, O God; Attend to my prayer.", 
                "num": 1
              }, 
              {
                "text": "From the end of the earth I will cry to You, When my heart is overwhelmed; Lead me to the rock that is higher than I.", 
                "num": 2
              }, 
              {
                "text": "For You have been a shelter for me, A strong tower from the enemy.", 
                "num": 3
              }, 
              {
                "text": "I will abide in Your tabernacle forever; I will trust in the shelter of Your wings.Selah", 
                "num": 4
              }, 
              {
                "text": "For You, O God, have heard my vows; You have given me the heritage of those who fear Your name.", 
                "num": 5
              }, 
              {
                "text": "You will prolong the king's life, His years as many generations.", 
                "num": 6
              }, 
              {
                "text": "He shall abide before God forever. Oh, prepare mercy and truth, which may preserve him!", 
                "num": 7
              }, 
              {
                "text": "So I will sing praise to Your name forever, That I may daily perform my vows.", 
                "num": 8
              }
            ], 
            "num": 61
          }, 
          {
            "verses": [
              {
                "text": "Truly my soul silently waits for God; From Him comes my salvation.", 
                "num": 1
              }, 
              {
                "text": "He only is my rock and my salvation; He is my defense; I shall not be greatly moved.", 
                "num": 2
              }, 
              {
                "text": "How long will you attack a man? You shall be slain, all of you, Like a leaning wall and a tottering fence.", 
                "num": 3
              }, 
              {
                "text": "They only consult to cast him down from his high position; They delight in lies; They bless with their mouth, But they curse inwardly.Selah", 
                "num": 4
              }, 
              {
                "text": "My soul, wait silently for God alone, For my expectation is from Him.", 
                "num": 5
              }, 
              {
                "text": "He only is my rock and my salvation; He is my defense; I shall not be moved.", 
                "num": 6
              }, 
              {
                "text": "In God is my salvation and my glory; The rock of my strength, And my refuge, is in God.", 
                "num": 7
              }, 
              {
                "text": "Trust in Him at all times, you people; Pour out your heart before Him; God is a refuge for us.Selah", 
                "num": 8
              }, 
              {
                "text": "Surely men of low degree are a vapor, Men of high degree are a lie; If they are weighed on the scales, They are altogether lighter than vapor.", 
                "num": 9
              }, 
              {
                "text": "Do not trust in oppression, Nor vainly hope in robbery; If riches increase, Do not set your heart on them.", 
                "num": 10
              }, 
              {
                "text": "God has spoken once, Twice I have heard this: That power belongs to God.", 
                "num": 11
              }, 
              {
                "text": "Also to You, O Lord, belongs mercy; For You render to each one according to his work.", 
                "num": 12
              }
            ], 
            "num": 62
          }, 
          {
            "verses": [
              {
                "text": "O God, You are my God; Early will I seek You; My soul thirsts for You; My flesh longs for You In a dry and thirsty land Where there is no water.", 
                "num": 1
              }, 
              {
                "text": "So I have looked for You in the sanctuary, To see Your power and Your glory.", 
                "num": 2
              }, 
              {
                "text": "Because Your lovingkindness is better than life, My lips shall praise You.", 
                "num": 3
              }, 
              {
                "text": "Thus I will bless You while I live; I will lift up my hands in Your name.", 
                "num": 4
              }, 
              {
                "text": "My soul shall be satisfied as with marrow and fatness, And my mouth shall praise You with joyful lips.", 
                "num": 5
              }, 
              {
                "text": "When I remember You on my bed, I meditate on You in the night watches.", 
                "num": 6
              }, 
              {
                "text": "Because You have been my help, Therefore in the shadow of Your wings I will rejoice.", 
                "num": 7
              }, 
              {
                "text": "My soul follows close behind You; Your right hand upholds me.", 
                "num": 8
              }, 
              {
                "text": "But those who seek my life, to destroy it, Shall go into the lower parts of the earth.", 
                "num": 9
              }, 
              {
                "text": "They shall fall by the sword; They shall be a portion for jackals.", 
                "num": 10
              }, 
              {
                "text": "But the king shall rejoice in God; Everyone who swears by Him shall glory; But the mouth of those who speak lies shall be stopped.", 
                "num": 11
              }
            ], 
            "num": 63
          }, 
          {
            "verses": [
              {
                "text": "Hear my voice, O God, in my meditation; Preserve my life from fear of the enemy.", 
                "num": 1
              }, 
              {
                "text": "Hide me from the secret plots of the wicked, From the rebellion of the workers of iniquity,", 
                "num": 2
              }, 
              {
                "text": "Who sharpen their tongue like a sword, And bend their bows to shoot their arrows--bitter words,", 
                "num": 3
              }, 
              {
                "text": "That they may shoot in secret at the blameless; Suddenly they shoot at him and do not fear.", 
                "num": 4
              }, 
              {
                "text": "They encourage themselves in an evil matter; They talk of laying snares secretly; They say, \"Who will see them?\"", 
                "num": 5
              }, 
              {
                "text": "They devise iniquities: \"We have perfected a shrewd scheme.\" Both the inward thought and the heart of man are deep.", 
                "num": 6
              }, 
              {
                "text": "But God shall shoot at them with an arrow; Suddenly they shall be wounded.", 
                "num": 7
              }, 
              {
                "text": "So He will make them stumble over their own tongue; All who see them shall flee away.", 
                "num": 8
              }, 
              {
                "text": "All men shall fear, And shall declare the work of God; For they shall wisely consider His doing.", 
                "num": 9
              }, 
              {
                "text": "The righteous shall be glad in the LORD, and trust in Him. And all the upright in heart shall glory.", 
                "num": 10
              }
            ], 
            "num": 64
          }, 
          {
            "verses": [
              {
                "text": "Praise is awaiting You, O God, in Zion; And to You the vow shall be performed.", 
                "num": 1
              }, 
              {
                "text": "O You who hear prayer, To You all flesh will come.", 
                "num": 2
              }, 
              {
                "text": "Iniquities prevail against me; As for our transgressions, You will provide atonement for them.", 
                "num": 3
              }, 
              {
                "text": "Blessed is the man You choose, And cause to approach You, That he may dwell in Your courts. We shall be satisfied with the goodness of Your house, Of Your holy temple.", 
                "num": 4
              }, 
              {
                "text": "By awesome deeds in righteousness You will answer us, O God of our salvation, You who are the confidence of all the ends of the earth, And of the far-off seas;", 
                "num": 5
              }, 
              {
                "text": "Who established the mountains by His strength, Being clothed with power;", 
                "num": 6
              }, 
              {
                "text": "You who still the noise of the seas, The noise of their waves, And the tumult of the peoples.", 
                "num": 7
              }, 
              {
                "text": "They also who dwell in the farthest parts are afraid of Your signs; You make the outgoings of the morning and evening rejoice.", 
                "num": 8
              }, 
              {
                "text": "You visit the earth and water it, You greatly enrich it; The river of God is full of water; You provide their grain, For so You have prepared it.", 
                "num": 9
              }, 
              {
                "text": "You water its ridges abundantly, You settle its furrows; You make it soft with showers, You bless its growth.", 
                "num": 10
              }, 
              {
                "text": "You crown the year with Your goodness, And Your paths drip with abundance.", 
                "num": 11
              }, 
              {
                "text": "They drop on the pastures of the wilderness, And the little hills rejoice on every side.", 
                "num": 12
              }, 
              {
                "text": "The pastures are clothed with flocks; The valleys also are covered with grain; They shout for joy, they also sing.", 
                "num": 13
              }
            ], 
            "num": 65
          }, 
          {
            "verses": [
              {
                "text": "Make a joyful shout to God, all the earth!", 
                "num": 1
              }, 
              {
                "text": "Sing out the honor of His name; Make His praise glorious.", 
                "num": 2
              }, 
              {
                "text": "Say to God, \"How awesome are Your works! Through the greatness of Your power Your enemies shall submit themselves to You.", 
                "num": 3
              }, 
              {
                "text": "All the earth shall worship You And sing praises to You; They shall sing praises to Your name.\"Selah", 
                "num": 4
              }, 
              {
                "text": "Come and see the works of God; He is awesome in His doing toward the sons of men.", 
                "num": 5
              }, 
              {
                "text": "He turned the sea into dry land; They went through the river on foot. There we will rejoice in Him.", 
                "num": 6
              }, 
              {
                "text": "He rules by His power forever; His eyes observe the nations; Do not let the rebellious exalt themselves.Selah", 
                "num": 7
              }, 
              {
                "text": "Oh, bless our God, you peoples! And make the voice of His praise to be heard,", 
                "num": 8
              }, 
              {
                "text": "Who keeps our soul among the living, And does not allow our feet to be moved.", 
                "num": 9
              }, 
              {
                "text": "For You, O God, have tested us; You have refined us as silver is refined.", 
                "num": 10
              }, 
              {
                "text": "You brought us into the net; You laid affliction on our backs.", 
                "num": 11
              }, 
              {
                "text": "You have caused men to ride over our heads; We went through fire and through water; But You brought us out to rich fulfillment.", 
                "num": 12
              }, 
              {
                "text": "I will go into Your house with burnt offerings; I will pay You my vows,", 
                "num": 13
              }, 
              {
                "text": "Which my lips have uttered And my mouth has spoken when I was in trouble.", 
                "num": 14
              }, 
              {
                "text": "I will offer You burnt sacrifices of fat animals, With the sweet aroma of rams; I will offer bulls with goats.Selah", 
                "num": 15
              }, 
              {
                "text": "Come and hear, all you who fear God, And I will declare what He has done for my soul.", 
                "num": 16
              }, 
              {
                "text": "I cried to Him with my mouth, And He was extolled with my tongue.", 
                "num": 17
              }, 
              {
                "text": "If I regard iniquity in my heart, The Lord will not hear.", 
                "num": 18
              }, 
              {
                "text": "But certainly God has heard me; He has attended to the voice of my prayer.", 
                "num": 19
              }, 
              {
                "text": "Blessed be God, Who has not turned away my prayer, Nor His mercy from me!", 
                "num": 20
              }
            ], 
            "num": 66
          }, 
          {
            "verses": [
              {
                "text": "God be merciful to us and bless us, And cause His face to shine upon us,Selah", 
                "num": 1
              }, 
              {
                "text": "That Your way may be known on earth, Your salvation among all nations.", 
                "num": 2
              }, 
              {
                "text": "Let the peoples praise You, O God; Let all the peoples praise You.", 
                "num": 3
              }, 
              {
                "text": "Oh, let the nations be glad and sing for joy! For You shall judge the people righteously, And govern the nations on earth.Selah", 
                "num": 4
              }, 
              {
                "text": "Let the peoples praise You, O God; Let all the peoples praise You.", 
                "num": 5
              }, 
              {
                "text": "Then the earth shall yield her increase; God, our own God, shall bless us.", 
                "num": 6
              }, 
              {
                "text": "God shall bless us, And all the ends of the earth shall fear Him.", 
                "num": 7
              }
            ], 
            "num": 67
          }, 
          {
            "verses": [
              {
                "text": "Let God arise, Let His enemies be scattered; Let those also who hate Him flee before Him.", 
                "num": 1
              }, 
              {
                "text": "As smoke is driven away, So drive them away; As wax melts before the fire, So let the wicked perish at the presence of God.", 
                "num": 2
              }, 
              {
                "text": "But let the righteous be glad; Let them rejoice before God; Yes, let them rejoice exceedingly.", 
                "num": 3
              }, 
              {
                "text": "Sing to God, sing praises to His name; Extol Him who rides on the clouds, By His name YAH, And rejoice before Him.", 
                "num": 4
              }, 
              {
                "text": "A father of the fatherless, a defender of widows, Is God in His holy habitation.", 
                "num": 5
              }, 
              {
                "text": "God sets the solitary in families; He brings out those who are bound into prosperity; But the rebellious dwell in a dry land.", 
                "num": 6
              }, 
              {
                "text": "O God, when You went out before Your people, When You marched through the wilderness,Selah", 
                "num": 7
              }, 
              {
                "text": "The earth shook; The heavens also dropped rain at the presence of God; Sinai itself was moved at the presence of God, the God of Israel.", 
                "num": 8
              }, 
              {
                "text": "You, O God, sent a plentiful rain, Whereby You confirmed Your inheritance, When it was weary.", 
                "num": 9
              }, 
              {
                "text": "Your congregation dwelt in it; You, O God, provided from Your goodness for the poor.", 
                "num": 10
              }, 
              {
                "text": "The Lord gave the word; Great was the company of those who proclaimed it:", 
                "num": 11
              }, 
              {
                "text": "\"Kings of armies flee, they flee, And she who remains at home divides the spoil.", 
                "num": 12
              }, 
              {
                "text": "Though you lie down among the sheepfolds, You will be like the wings of a dove covered with silver, And her feathers with yellow gold.\"", 
                "num": 13
              }, 
              {
                "text": "When the Almighty scattered kings in it, It was white as snow in Zalmon.", 
                "num": 14
              }, 
              {
                "text": "A mountain of God is the mountain of Bashan; A mountain of many peaks is the mountain of Bashan.", 
                "num": 15
              }, 
              {
                "text": "Why do you fume with envy, you mountains of many peaks? This is the mountain which God desires to dwell in; Yes, the LORD will dwell in it forever.", 
                "num": 16
              }, 
              {
                "text": "The chariots of God are twenty thousand, Even thousands of thousands; The Lord is among them as in Sinai, in the Holy Place.", 
                "num": 17
              }, 
              {
                "text": "You have ascended on high, You have led captivity captive; You have received gifts among men, Even from the rebellious, That the LORD God might dwell there.", 
                "num": 18
              }, 
              {
                "text": "Blessed be the Lord, Who daily loads us with benefits, The God of our salvation!Selah", 
                "num": 19
              }, 
              {
                "text": "Our God is the God of salvation; And to GOD the Lord belong escapes from death.", 
                "num": 20
              }, 
              {
                "text": "But God will wound the head of His enemies, The hairy scalp of the one who still goes on in his trespasses.", 
                "num": 21
              }, 
              {
                "text": "The Lord said, \"I will bring back from Bashan, I will bring them back from the depths of the sea,", 
                "num": 22
              }, 
              {
                "text": "That your foot may crush them in blood, And the tongues of your dogs may have their portion from your enemies.\"", 
                "num": 23
              }, 
              {
                "text": "They have seen Your procession, O God, The procession of my God, my King, into the sanctuary.", 
                "num": 24
              }, 
              {
                "text": "The singers went before, the players on instruments followed after; Among them were the maidens playing timbrels.", 
                "num": 25
              }, 
              {
                "text": "Bless God in the congregations, The Lord, from the fountain of Israel.", 
                "num": 26
              }, 
              {
                "text": "There is little Benjamin, their leader, The princes of Judah and their company, The princes of Zebulun and the princes of Naphtali.", 
                "num": 27
              }, 
              {
                "text": "Your God has commanded your strength; Strengthen, O God, what You have done for us.", 
                "num": 28
              }, 
              {
                "text": "Because of Your temple at Jerusalem, Kings will bring presents to You.", 
                "num": 29
              }, 
              {
                "text": "Rebuke the beasts of the reeds, The herd of bulls with the calves of the peoples, Till everyone submits himself with pieces of silver. Scatter the peoples who delight in war.", 
                "num": 30
              }, 
              {
                "text": "Envoys will come out of Egypt; Ethiopia will quickly stretch out her hands to God.", 
                "num": 31
              }, 
              {
                "text": "Sing to God, you kingdoms of the earth; Oh, sing praises to the Lord,Selah", 
                "num": 32
              }, 
              {
                "text": "To Him who rides on the heaven of heavens, which were of old! Indeed, He sends out His voice, a mighty voice.", 
                "num": 33
              }, 
              {
                "text": "Ascribe strength to God; His excellence is over Israel, And His strength is in the clouds.", 
                "num": 34
              }, 
              {
                "text": "O God, You are more awesome than Your holy places. The God of Israel is He who gives strength and power to His people. Blessed be God!", 
                "num": 35
              }
            ], 
            "num": 68
          }, 
          {
            "verses": [
              {
                "text": "Save me, O God! For the waters have come up to my neck.", 
                "num": 1
              }, 
              {
                "text": "I sink in deep mire, Where there is no standing; I have come into deep waters, Where the floods overflow me.", 
                "num": 2
              }, 
              {
                "text": "I am weary with my crying; My throat is dry; My eyes fail while I wait for my God.", 
                "num": 3
              }, 
              {
                "text": "Those who hate me without a cause Are more than the hairs of my head; They are mighty who would destroy me, Being my enemies wrongfully; Though I have stolen nothing, I still must restore it.", 
                "num": 4
              }, 
              {
                "text": "O God, You know my foolishness; And my sins are not hidden from You.", 
                "num": 5
              }, 
              {
                "text": "Let not those who wait for You, O Lord GOD of hosts, be ashamed because of me; Let not those who seek You be confounded because of me, O God of Israel.", 
                "num": 6
              }, 
              {
                "text": "Because for Your sake I have borne reproach; Shame has covered my face.", 
                "num": 7
              }, 
              {
                "text": "I have become a stranger to my brothers, And an alien to my mother's children;", 
                "num": 8
              }, 
              {
                "text": "Because zeal for Your house has eaten me up, And the reproaches of those who reproach You have fallen on me.", 
                "num": 9
              }, 
              {
                "text": "When I wept and chastened my soul with fasting, That became my reproach.", 
                "num": 10
              }, 
              {
                "text": "I also made sackcloth my garment; I became a byword to them.", 
                "num": 11
              }, 
              {
                "text": "Those who sit in the gate speak against me, And I am the song of the drunkards.", 
                "num": 12
              }, 
              {
                "text": "But as for me, my prayer is to You, O LORD, in the acceptable time; O God, in the multitude of Your mercy, Hear me in the truth of Your salvation.", 
                "num": 13
              }, 
              {
                "text": "Deliver me out of the mire, And let me not sink; Let me be delivered from those who hate me, And out of the deep waters.", 
                "num": 14
              }, 
              {
                "text": "Let not the floodwater overflow me, Nor let the deep swallow me up; And let not the pit shut its mouth on me.", 
                "num": 15
              }, 
              {
                "text": "Hear me, O LORD, for Your lovingkindness is good; Turn to me according to the multitude of Your tender mercies.", 
                "num": 16
              }, 
              {
                "text": "And do not hide Your face from Your servant, For I am in trouble; Hear me speedily.", 
                "num": 17
              }, 
              {
                "text": "Draw near to my soul, and redeem it; Deliver me because of my enemies.", 
                "num": 18
              }, 
              {
                "text": "You know my reproach, my shame, and my dishonor; My adversaries are all before You.", 
                "num": 19
              }, 
              {
                "text": "Reproach has broken my heart, And I am full of heaviness; I looked for someone to take pity, but there was none; And for comforters, but I found none.", 
                "num": 20
              }, 
              {
                "text": "They also gave me gall for my food, And for my thirst they gave me vinegar to drink.", 
                "num": 21
              }, 
              {
                "text": "Let their table become a snare before them, And their well-being a trap.", 
                "num": 22
              }, 
              {
                "text": "Let their eyes be darkened, so that they do not see; And make their loins shake continually.", 
                "num": 23
              }, 
              {
                "text": "Pour out Your indignation upon them, And let Your wrathful anger take hold of them.", 
                "num": 24
              }, 
              {
                "text": "Let their dwelling place be desolate; Let no one live in their tents.", 
                "num": 25
              }, 
              {
                "text": "For they persecute the ones You have struck, And talk of the grief of those You have wounded.", 
                "num": 26
              }, 
              {
                "text": "Add iniquity to their iniquity, And let them not come into Your righteousness.", 
                "num": 27
              }, 
              {
                "text": "Let them be blotted out of the book of the living, And not be written with the righteous.", 
                "num": 28
              }, 
              {
                "text": "But I am poor and sorrowful; Let Your salvation, O God, set me up on high.", 
                "num": 29
              }, 
              {
                "text": "I will praise the name of God with a song, And will magnify Him with thanksgiving.", 
                "num": 30
              }, 
              {
                "text": "This also shall please the LORD better than an ox or bull, Which has horns and hooves.", 
                "num": 31
              }, 
              {
                "text": "The humble shall see this and be glad; And you who seek God, your hearts shall live.", 
                "num": 32
              }, 
              {
                "text": "For the LORD hears the poor, And does not despise His prisoners.", 
                "num": 33
              }, 
              {
                "text": "Let heaven and earth praise Him, The seas and everything that moves in them.", 
                "num": 34
              }, 
              {
                "text": "For God will save Zion And build the cities of Judah, That they may dwell there and possess it.", 
                "num": 35
              }, 
              {
                "text": "Also, the descendants of His servants shall inherit it, And those who love His name shall dwell in it.", 
                "num": 36
              }
            ], 
            "num": 69
          }, 
          {
            "verses": [
              {
                "text": "Make haste, O God, to deliver me! Make haste to help me, O LORD!", 
                "num": 1
              }, 
              {
                "text": "Let them be ashamed and confounded Who seek my life; Let them be turned back and confused Who desire my hurt.", 
                "num": 2
              }, 
              {
                "text": "Let them be turned back because of their shame, Who say, \"Aha, aha!\"", 
                "num": 3
              }, 
              {
                "text": "Let all those who seek You rejoice and be glad in You; And let those who love Your salvation say continually, \"Let God be magnified!\"", 
                "num": 4
              }, 
              {
                "text": "But I am poor and needy; Make haste to me, O God! You are my help and my deliverer; O LORD, do not delay.", 
                "num": 5
              }
            ], 
            "num": 70
          }, 
          {
            "verses": [
              {
                "text": "In You, O LORD, I put my trust; Let me never be put to shame.", 
                "num": 1
              }, 
              {
                "text": "Deliver me in Your righteousness, and cause me to escape; Incline Your ear to me, and save me.", 
                "num": 2
              }, 
              {
                "text": "Be my strong refuge, To which I may resort continually; You have given the commandment to save me, For You are my rock and my fortress.", 
                "num": 3
              }, 
              {
                "text": "Deliver me, O my God, out of the hand of the wicked, Out of the hand of the unrighteous and cruel man.", 
                "num": 4
              }, 
              {
                "text": "For You are my hope, O Lord GOD; You are my trust from my youth.", 
                "num": 5
              }, 
              {
                "text": "By You I have been upheld from birth; You are He who took me out of my mother's womb. My praise shall be continually of You.", 
                "num": 6
              }, 
              {
                "text": "I have become as a wonder to many, But You are my strong refuge.", 
                "num": 7
              }, 
              {
                "text": "Let my mouth be filled with Your praise And with Your glory all the day.", 
                "num": 8
              }, 
              {
                "text": "Do not cast me off in the time of old age; Do not forsake me when my strength fails.", 
                "num": 9
              }, 
              {
                "text": "For my enemies speak against me; And those who lie in wait for my life take counsel together,", 
                "num": 10
              }, 
              {
                "text": "Saying, \"God has forsaken him; Pursue and take him, for there is none to deliver him.\"", 
                "num": 11
              }, 
              {
                "text": "O God, do not be far from me; O my God, make haste to help me!", 
                "num": 12
              }, 
              {
                "text": "Let them be confounded and consumed Who are adversaries of my life; Let them be covered with reproach and dishonor Who seek my hurt.", 
                "num": 13
              }, 
              {
                "text": "But I will hope continually, And will praise You yet more and more.", 
                "num": 14
              }, 
              {
                "text": "My mouth shall tell of Your righteousness And Your salvation all the day, For I do not know their limits.", 
                "num": 15
              }, 
              {
                "text": "I will go in the strength of the Lord GOD; I will make mention of Your righteousness, of Yours only.", 
                "num": 16
              }, 
              {
                "text": "O God, You have taught me from my youth; And to this day I declare Your wondrous works.", 
                "num": 17
              }, 
              {
                "text": "Now also when I am old and grayheaded, O God, do not forsake me, Until I declare Your strength to this generation, Your power to everyone who is to come.", 
                "num": 18
              }, 
              {
                "text": "Also Your righteousness, O God, is very high, You who have done great things; O God, who is like You?", 
                "num": 19
              }, 
              {
                "text": "You, who have shown me great and severe troubles, Shall revive me again, And bring me up again from the depths of the earth.", 
                "num": 20
              }, 
              {
                "text": "You shall increase my greatness, And comfort me on every side.", 
                "num": 21
              }, 
              {
                "text": "Also with the lute I will praise You-- And Your faithfulness, O my God! To You I will sing with the harp, O Holy One of Israel.", 
                "num": 22
              }, 
              {
                "text": "My lips shall greatly rejoice when I sing to You, And my soul, which You have redeemed.", 
                "num": 23
              }, 
              {
                "text": "My tongue also shall talk of Your righteousness all the day long; For they are confounded, For they are brought to shame Who seek my hurt.", 
                "num": 24
              }
            ], 
            "num": 71
          }, 
          {
            "verses": [
              {
                "text": "Give the king Your judgments, O God, And Your righteousness to the king's Son.", 
                "num": 1
              }, 
              {
                "text": "He will judge Your people with righteousness, And Your poor with justice.", 
                "num": 2
              }, 
              {
                "text": "The mountains will bring peace to the people, And the little hills, by righteousness.", 
                "num": 3
              }, 
              {
                "text": "He will bring justice to the poor of the people; He will save the children of the needy, And will break in pieces the oppressor.", 
                "num": 4
              }, 
              {
                "text": "They shall fear You As long as the sun and moon endure, Throughout all generations.", 
                "num": 5
              }, 
              {
                "text": "He shall come down like rain upon the grass before mowing, Like showers that water the earth.", 
                "num": 6
              }, 
              {
                "text": "In His days the righteous shall flourish, And abundance of peace, Until the moon is no more.", 
                "num": 7
              }, 
              {
                "text": "He shall have dominion also from sea to sea, And from the River to the ends of the earth.", 
                "num": 8
              }, 
              {
                "text": "Those who dwell in the wilderness will bow before Him, And His enemies will lick the dust.", 
                "num": 9
              }, 
              {
                "text": "The kings of Tarshish and of the isles Will bring presents; The kings of Sheba and Seba Will offer gifts.", 
                "num": 10
              }, 
              {
                "text": "Yes, all kings shall fall down before Him; All nations shall serve Him.", 
                "num": 11
              }, 
              {
                "text": "For He will deliver the needy when he cries, The poor also, and him who has no helper.", 
                "num": 12
              }, 
              {
                "text": "He will spare the poor and needy, And will save the souls of the needy.", 
                "num": 13
              }, 
              {
                "text": "He will redeem their life from oppression and violence; And precious shall be their blood in His sight.", 
                "num": 14
              }, 
              {
                "text": "And He shall live; And the gold of Sheba will be given to Him; Prayer also will be made for Him continually, And daily He shall be praised.", 
                "num": 15
              }, 
              {
                "text": "There will be an abundance of grain in the earth, On the top of the mountains; Its fruit shall wave like Lebanon; And those of the city shall flourish like grass of the earth.", 
                "num": 16
              }, 
              {
                "text": "His name shall endure forever; His name shall continue as long as the sun. And men shall be blessed in Him; All nations shall call Him blessed.", 
                "num": 17
              }, 
              {
                "text": "Blessed be the LORD God, the God of Israel, Who only does wondrous things!", 
                "num": 18
              }, 
              {
                "text": "And blessed be His glorious name forever! And let the whole earth be filled with His glory. Amen and Amen.", 
                "num": 19
              }, 
              {
                "text": "The prayers of David the son of Jesse are ended.", 
                "num": 20
              }
            ], 
            "num": 72
          }, 
          {
            "verses": [
              {
                "text": "Truly God is good to Israel, To such as are pure in heart.", 
                "num": 1
              }, 
              {
                "text": "But as for me, my feet had almost stumbled; My steps had nearly slipped.", 
                "num": 2
              }, 
              {
                "text": "For I was envious of the boastful, When I saw the prosperity of the wicked.", 
                "num": 3
              }, 
              {
                "text": "For there are no pangs in their death, But their strength is firm.", 
                "num": 4
              }, 
              {
                "text": "They are not in trouble as other men, Nor are they plagued like other men.", 
                "num": 5
              }, 
              {
                "text": "Therefore pride serves as their necklace; Violence covers them like a garment.", 
                "num": 6
              }, 
              {
                "text": "Their eyes bulge with abundance; They have more than heart could wish.", 
                "num": 7
              }, 
              {
                "text": "They scoff and speak wickedly concerning oppression; They speak loftily.", 
                "num": 8
              }, 
              {
                "text": "They set their mouth against the heavens, And their tongue walks through the earth.", 
                "num": 9
              }, 
              {
                "text": "Therefore his people return here, And waters of a full cup are drained by them.", 
                "num": 10
              }, 
              {
                "text": "And they say, \"How does God know? And is there knowledge in the Most High?\"", 
                "num": 11
              }, 
              {
                "text": "Behold, these are the ungodly, Who are always at ease; They increase in riches.", 
                "num": 12
              }, 
              {
                "text": "Surely I have cleansed my heart in vain, And washed my hands in innocence.", 
                "num": 13
              }, 
              {
                "text": "For all day long I have been plagued, And chastened every morning.", 
                "num": 14
              }, 
              {
                "text": "If I had said, \"I will speak thus,\" Behold, I would have been untrue to the generation of Your children.", 
                "num": 15
              }, 
              {
                "text": "When I thought how to understand this, It was too painful for me--", 
                "num": 16
              }, 
              {
                "text": "Until I went into the sanctuary of God; Then I understood their end.", 
                "num": 17
              }, 
              {
                "text": "Surely You set them in slippery places; You cast them down to destruction.", 
                "num": 18
              }, 
              {
                "text": "Oh, how they are brought to desolation, as in a moment! They are utterly consumed with terrors.", 
                "num": 19
              }, 
              {
                "text": "As a dream when one awakes, So, Lord, when You awake, You shall despise their image.", 
                "num": 20
              }, 
              {
                "text": "Thus my heart was grieved, And I was vexed in my mind.", 
                "num": 21
              }, 
              {
                "text": "I was so foolish and ignorant; I was like a beast before You.", 
                "num": 22
              }, 
              {
                "text": "Nevertheless I am continually with You; You hold me by my right hand.", 
                "num": 23
              }, 
              {
                "text": "You will guide me with Your counsel, And afterward receive me to glory.", 
                "num": 24
              }, 
              {
                "text": "Whom have I in heaven but You? And there is none upon earth that I desire besides You.", 
                "num": 25
              }, 
              {
                "text": "My flesh and my heart fail; But God is the strength of my heart and my portion forever.", 
                "num": 26
              }, 
              {
                "text": "For indeed, those who are far from You shall perish; You have destroyed all those who desert You for harlotry.", 
                "num": 27
              }, 
              {
                "text": "But it is good for me to draw near to God; I have put my trust in the Lord GOD, That I may declare all Your works.", 
                "num": 28
              }
            ], 
            "num": 73
          }, 
          {
            "verses": [
              {
                "text": "O God, why have You cast us off forever? Why does Your anger smoke against the sheep of Your pasture?", 
                "num": 1
              }, 
              {
                "text": "Remember Your congregation, which You have purchased of old, The tribe of Your inheritance, which You have redeemed-- This Mount Zion where You have dwelt.", 
                "num": 2
              }, 
              {
                "text": "Lift up Your feet to the perpetual desolations. The enemy has damaged everything in the sanctuary.", 
                "num": 3
              }, 
              {
                "text": "Your enemies roar in the midst of Your meeting place; They set up their banners for signs.", 
                "num": 4
              }, 
              {
                "text": "They seem like men who lift up Axes among the thick trees.", 
                "num": 5
              }, 
              {
                "text": "And now they break down its carved work, all at once, With axes and hammers.", 
                "num": 6
              }, 
              {
                "text": "They have set fire to Your sanctuary; They have defiled the dwelling place of Your name to the ground.", 
                "num": 7
              }, 
              {
                "text": "They said in their hearts, \"Let us destroy them altogether.\" They have burned up all the meeting places of God in the land.", 
                "num": 8
              }, 
              {
                "text": "We do not see our signs; There is no longer any prophet; Nor is there any among us who knows how long.", 
                "num": 9
              }, 
              {
                "text": "O God, how long will the adversary reproach? Will the enemy blaspheme Your name forever?", 
                "num": 10
              }, 
              {
                "text": "Why do You withdraw Your hand, even Your right hand? Take it out of Your bosom and destroy them.", 
                "num": 11
              }, 
              {
                "text": "For God is my King from of old, Working salvation in the midst of the earth.", 
                "num": 12
              }, 
              {
                "text": "You divided the sea by Your strength; You broke the heads of the sea serpents in the waters.", 
                "num": 13
              }, 
              {
                "text": "You broke the heads of Leviathan in pieces, And gave him as food to the people inhabiting the wilderness.", 
                "num": 14
              }, 
              {
                "text": "You broke open the fountain and the flood; You dried up mighty rivers.", 
                "num": 15
              }, 
              {
                "text": "The day is Yours, the night also is Yours; You have prepared the light and the sun.", 
                "num": 16
              }, 
              {
                "text": "You have set all the borders of the earth; You have made summer and winter.", 
                "num": 17
              }, 
              {
                "text": "Remember this, that the enemy has reproached, O LORD, And that a foolish people has blasphemed Your name.", 
                "num": 18
              }, 
              {
                "text": "Oh, do not deliver the life of Your turtledove to the wild beast! Do not forget the life of Your poor forever.", 
                "num": 19
              }, 
              {
                "text": "Have respect to the covenant; For the dark places of the earth are full of the haunts of cruelty.", 
                "num": 20
              }, 
              {
                "text": "Oh, do not let the oppressed return ashamed! Let the poor and needy praise Your name.", 
                "num": 21
              }, 
              {
                "text": "Arise, O God, plead Your own cause; Remember how the foolish man reproaches You daily.", 
                "num": 22
              }, 
              {
                "text": "Do not forget the voice of Your enemies; The tumult of those who rise up against You increases continually.", 
                "num": 23
              }
            ], 
            "num": 74
          }, 
          {
            "verses": [
              {
                "text": "We give thanks to You, O God, we give thanks! For Your wondrous works declare that Your name is near.", 
                "num": 1
              }, 
              {
                "text": "\"When I choose the proper time, I will judge uprightly.", 
                "num": 2
              }, 
              {
                "text": "The earth and all its inhabitants are dissolved; I set up its pillars firmly.Selah", 
                "num": 3
              }, 
              {
                "text": "\"I said to the boastful, \"Do not deal boastfully,' And to the wicked, \"Do not lift up the horn.", 
                "num": 4
              }, 
              {
                "text": "Do not lift up your horn on high; Do not speak with a stiff neck.\"'", 
                "num": 5
              }, 
              {
                "text": "For exaltation comes neither from the east Nor from the west nor from the south.", 
                "num": 6
              }, 
              {
                "text": "But God is the Judge: He puts down one, And exalts another.", 
                "num": 7
              }, 
              {
                "text": "For in the hand of the LORD there is a cup, And the wine is red; It is fully mixed, and He pours it out; Surely its dregs shall all the wicked of the earth Drain and drink down.", 
                "num": 8
              }, 
              {
                "text": "But I will declare forever, I will sing praises to the God of Jacob.", 
                "num": 9
              }, 
              {
                "text": "\"All the horns of the wicked I will also cut off, But the horns of the righteous shall be exalted.\"", 
                "num": 10
              }
            ], 
            "num": 75
          }, 
          {
            "verses": [
              {
                "text": "In Judah God is known; His name is great in Israel.", 
                "num": 1
              }, 
              {
                "text": "In Salem also is His tabernacle, And His dwelling place in Zion.", 
                "num": 2
              }, 
              {
                "text": "There He broke the arrows of the bow, The shield and sword of battle.Selah", 
                "num": 3
              }, 
              {
                "text": "You are more glorious and excellent Than the mountains of prey.", 
                "num": 4
              }, 
              {
                "text": "The stouthearted were plundered; They have sunk into their sleep; And none of the mighty men have found the use of their hands.", 
                "num": 5
              }, 
              {
                "text": "At Your rebuke, O God of Jacob, Both the chariot and horse were cast into a dead sleep.", 
                "num": 6
              }, 
              {
                "text": "You, Yourself, are to be feared; And who may stand in Your presence When once You are angry?", 
                "num": 7
              }, 
              {
                "text": "You caused judgment to be heard from heaven; The earth feared and was still,", 
                "num": 8
              }, 
              {
                "text": "When God arose to judgment, To deliver all the oppressed of the earth.Selah", 
                "num": 9
              }, 
              {
                "text": "Surely the wrath of man shall praise You; With the remainder of wrath You shall gird Yourself.", 
                "num": 10
              }, 
              {
                "text": "Make vows to the LORD your God, and pay them; Let all who are around Him bring presents to Him who ought to be feared.", 
                "num": 11
              }, 
              {
                "text": "He shall cut off the spirit of princes; He is awesome to the kings of the earth.", 
                "num": 12
              }
            ], 
            "num": 76
          }, 
          {
            "verses": [
              {
                "text": "I cried out to God with my voice-- To God with my voice; And He gave ear to me.", 
                "num": 1
              }, 
              {
                "text": "In the day of my trouble I sought the Lord; My hand was stretched out in the night without ceasing; My soul refused to be comforted.", 
                "num": 2
              }, 
              {
                "text": "I remembered God, and was troubled; I complained, and my spirit was overwhelmed.Selah", 
                "num": 3
              }, 
              {
                "text": "You hold my eyelids open; I am so troubled that I cannot speak.", 
                "num": 4
              }, 
              {
                "text": "I have considered the days of old, The years of ancient times.", 
                "num": 5
              }, 
              {
                "text": "I call to remembrance my song in the night; I meditate within my heart, And my spirit makes diligent search.", 
                "num": 6
              }, 
              {
                "text": "Will the Lord cast off forever? And will He be favorable no more?", 
                "num": 7
              }, 
              {
                "text": "Has His mercy ceased forever? Has His promise failed forevermore?", 
                "num": 8
              }, 
              {
                "text": "Has God forgotten to be gracious? Has He in anger shut up His tender mercies?Selah", 
                "num": 9
              }, 
              {
                "text": "And I said, \"This is my anguish; But I will remember the years of the right hand of the Most High.\"", 
                "num": 10
              }, 
              {
                "text": "I will remember the works of the LORD; Surely I will remember Your wonders of old.", 
                "num": 11
              }, 
              {
                "text": "I will also meditate on all Your work, And talk of Your deeds.", 
                "num": 12
              }, 
              {
                "text": "Your way, O God, is in the sanctuary; Who is so great a God as our God?", 
                "num": 13
              }, 
              {
                "text": "You are the God who does wonders; You have declared Your strength among the peoples.", 
                "num": 14
              }, 
              {
                "text": "You have with Your arm redeemed Your people, The sons of Jacob and Joseph.Selah", 
                "num": 15
              }, 
              {
                "text": "The waters saw You, O God; The waters saw You, they were afraid; The depths also trembled.", 
                "num": 16
              }, 
              {
                "text": "The clouds poured out water; The skies sent out a sound; Your arrows also flashed about.", 
                "num": 17
              }, 
              {
                "text": "The voice of Your thunder was in the whirlwind; The lightnings lit up the world; The earth trembled and shook.", 
                "num": 18
              }, 
              {
                "text": "Your way was in the sea, Your path in the great waters, And Your footsteps were not known.", 
                "num": 19
              }, 
              {
                "text": "You led Your people like a flock By the hand of Moses and Aaron.", 
                "num": 20
              }
            ], 
            "num": 77
          }, 
          {
            "verses": [
              {
                "text": "Give ear, O my people, to my law; Incline your ears to the words of my mouth.", 
                "num": 1
              }, 
              {
                "text": "I will open my mouth in a parable; I will utter dark sayings of old,", 
                "num": 2
              }, 
              {
                "text": "Which we have heard and known, And our fathers have told us.", 
                "num": 3
              }, 
              {
                "text": "We will not hide them from their children, Telling to the generation to come the praises of the LORD, And His strength and His wonderful works that He has done.", 
                "num": 4
              }, 
              {
                "text": "For He established a testimony in Jacob, And appointed a law in Israel, Which He commanded our fathers, That they should make them known to their children;", 
                "num": 5
              }, 
              {
                "text": "That the generation to come might know them, The children who would be born, That they may arise and declare them to their children,", 
                "num": 6
              }, 
              {
                "text": "That they may set their hope in God, And not forget the works of God, But keep His commandments;", 
                "num": 7
              }, 
              {
                "text": "And may not be like their fathers, A stubborn and rebellious generation, A generation that did not set its heart aright, And whose spirit was not faithful to God.", 
                "num": 8
              }, 
              {
                "text": "The children of Ephraim, being armed and carrying bows, Turned back in the day of battle.", 
                "num": 9
              }, 
              {
                "text": "They did not keep the covenant of God; They refused to walk in His law,", 
                "num": 10
              }, 
              {
                "text": "And forgot His works And His wonders that He had shown them.", 
                "num": 11
              }, 
              {
                "text": "Marvelous things He did in the sight of their fathers, In the land of Egypt, in the field of Zoan.", 
                "num": 12
              }, 
              {
                "text": "He divided the sea and caused them to pass through; And He made the waters stand up like a heap.", 
                "num": 13
              }, 
              {
                "text": "In the daytime also He led them with the cloud, And all the night with a light of fire.", 
                "num": 14
              }, 
              {
                "text": "He split the rocks in the wilderness, And gave them drink in abundance like the depths.", 
                "num": 15
              }, 
              {
                "text": "He also brought streams out of the rock, And caused waters to run down like rivers.", 
                "num": 16
              }, 
              {
                "text": "But they sinned even more against Him By rebelling against the Most High in the wilderness.", 
                "num": 17
              }, 
              {
                "text": "And they tested God in their heart By asking for the food of their fancy.", 
                "num": 18
              }, 
              {
                "text": "Yes, they spoke against God: They said, \"Can God prepare a table in the wilderness?", 
                "num": 19
              }, 
              {
                "text": "Behold, He struck the rock, So that the waters gushed out, And the streams overflowed. Can He give bread also? Can He provide meat for His people?|\"", 
                "num": 20
              }, 
              {
                "text": "Therefore the LORD heard this and was furious; So a fire was kindled against Jacob, And anger also came up against Israel,", 
                "num": 21
              }, 
              {
                "text": "Because they did not believe in God, And did not trust in His salvation.", 
                "num": 22
              }, 
              {
                "text": "Yet He had commanded the clouds above, And opened the doors of heaven,", 
                "num": 23
              }, 
              {
                "text": "Had rained down manna on them to eat, And given them of the bread of heaven.", 
                "num": 24
              }, 
              {
                "text": "Men ate angels' food; He sent them food to the full.", 
                "num": 25
              }, 
              {
                "text": "He caused an east wind to blow in the heavens; And by His power He brought in the south wind.", 
                "num": 26
              }, 
              {
                "text": "He also rained meat on them like the dust, Feathered fowl like the sand of the seas;", 
                "num": 27
              }, 
              {
                "text": "And He let them fall in the midst of their camp, All around their dwellings.", 
                "num": 28
              }, 
              {
                "text": "So they ate and were well filled, For He gave them their own desire.", 
                "num": 29
              }, 
              {
                "text": "They were not deprived of their craving; But while their food was still in their mouths,", 
                "num": 30
              }, 
              {
                "text": "The wrath of God came against them, And slew the stoutest of them, And struck down the choice men of Israel.", 
                "num": 31
              }, 
              {
                "text": "In spite of this they still sinned, And did not believe in His wondrous works.", 
                "num": 32
              }, 
              {
                "text": "Therefore their days He consumed in futility, And their years in fear.", 
                "num": 33
              }, 
              {
                "text": "When He slew them, then they sought Him; And they returned and sought earnestly for God.", 
                "num": 34
              }, 
              {
                "text": "Then they remembered that God was their rock, And the Most High God their Redeemer.", 
                "num": 35
              }, 
              {
                "text": "Nevertheless they flattered Him with their mouth, And they lied to Him with their tongue;", 
                "num": 36
              }, 
              {
                "text": "For their heart was not steadfast with Him, Nor were they faithful in His covenant.", 
                "num": 37
              }, 
              {
                "text": "But He, being full of compassion, forgave their iniquity, And did not destroy them. Yes, many a time He turned His anger away, And did not stir up all His wrath;", 
                "num": 38
              }, 
              {
                "text": "For He remembered that they were but flesh, A breath that passes away and does not come again.", 
                "num": 39
              }, 
              {
                "text": "How often they provoked Him in the wilderness, And grieved Him in the desert!", 
                "num": 40
              }, 
              {
                "text": "Yes, again and again they tempted God, And limited the Holy One of Israel.", 
                "num": 41
              }, 
              {
                "text": "They did not remember His power: The day when He redeemed them from the enemy,", 
                "num": 42
              }, 
              {
                "text": "When He worked His signs in Egypt, And His wonders in the field of Zoan;", 
                "num": 43
              }, 
              {
                "text": "Turned their rivers into blood, And their streams, that they could not drink.", 
                "num": 44
              }, 
              {
                "text": "He sent swarms of flies among them, which devoured them, And frogs, which destroyed them.", 
                "num": 45
              }, 
              {
                "text": "He also gave their crops to the caterpillar, And their labor to the locust.", 
                "num": 46
              }, 
              {
                "text": "He destroyed their vines with hail, And their sycamore trees with frost.", 
                "num": 47
              }, 
              {
                "text": "He also gave up their cattle to the hail, And their flocks to fiery lightning.", 
                "num": 48
              }, 
              {
                "text": "He cast on them the fierceness of His anger, Wrath, indignation, and trouble, By sending angels of destruction among them.", 
                "num": 49
              }, 
              {
                "text": "He made a path for His anger; He did not spare their soul from death, But gave their life over to the plague,", 
                "num": 50
              }, 
              {
                "text": "And destroyed all the firstborn in Egypt, The first of their strength in the tents of Ham.", 
                "num": 51
              }, 
              {
                "text": "But He made His own people go forth like sheep, And guided them in the wilderness like a flock;", 
                "num": 52
              }, 
              {
                "text": "And He led them on safely, so that they did not fear; But the sea overwhelmed their enemies.", 
                "num": 53
              }, 
              {
                "text": "And He brought them to His holy border, This mountain which His right hand had acquired.", 
                "num": 54
              }, 
              {
                "text": "He also drove out the nations before them, Allotted them an inheritance by survey, And made the tribes of Israel dwell in their tents.", 
                "num": 55
              }, 
              {
                "text": "Yet they tested and provoked the Most High God, And did not keep His testimonies,", 
                "num": 56
              }, 
              {
                "text": "But turned back and acted unfaithfully like their fathers; They were turned aside like a deceitful bow.", 
                "num": 57
              }, 
              {
                "text": "For they provoked Him to anger with their high places, And moved Him to jealousy with their carved images.", 
                "num": 58
              }, 
              {
                "text": "When God heard this, He was furious, And greatly abhorred Israel,", 
                "num": 59
              }, 
              {
                "text": "So that He forsook the tabernacle of Shiloh, The tent He had placed among men,", 
                "num": 60
              }, 
              {
                "text": "And delivered His strength into captivity, And His glory into the enemy's hand.", 
                "num": 61
              }, 
              {
                "text": "He also gave His people over to the sword, And was furious with His inheritance.", 
                "num": 62
              }, 
              {
                "text": "The fire consumed their young men, And their maidens were not given in marriage.", 
                "num": 63
              }, 
              {
                "text": "Their priests fell by the sword, And their widows made no lamentation.", 
                "num": 64
              }, 
              {
                "text": "Then the Lord awoke as from sleep, Like a mighty man who shouts because of wine.", 
                "num": 65
              }, 
              {
                "text": "And He beat back His enemies; He put them to a perpetual reproach.", 
                "num": 66
              }, 
              {
                "text": "Moreover He rejected the tent of Joseph, And did not choose the tribe of Ephraim,", 
                "num": 67
              }, 
              {
                "text": "But chose the tribe of Judah, Mount Zion which He loved.", 
                "num": 68
              }, 
              {
                "text": "And He built His sanctuary like the heights, Like the earth which He has established forever.", 
                "num": 69
              }, 
              {
                "text": "He also chose David His servant, And took him from the sheepfolds;", 
                "num": 70
              }, 
              {
                "text": "From following the ewes that had young He brought him, To shepherd Jacob His people, And Israel His inheritance.", 
                "num": 71
              }, 
              {
                "text": "So he shepherded them according to the integrity of his heart, And guided them by the skillfulness of his hands.", 
                "num": 72
              }
            ], 
            "num": 78
          }, 
          {
            "verses": [
              {
                "text": "O God, the nations have come into Your inheritance; Your holy temple they have defiled; They have laid Jerusalem in heaps.", 
                "num": 1
              }, 
              {
                "text": "The dead bodies of Your servants They have given as food for the birds of the heavens, The flesh of Your saints to the beasts of the earth.", 
                "num": 2
              }, 
              {
                "text": "Their blood they have shed like water all around Jerusalem, And there was no one to bury them.", 
                "num": 3
              }, 
              {
                "text": "We have become a reproach to our neighbors, A scorn and derision to those who are around us.", 
                "num": 4
              }, 
              {
                "text": "How long, LORD? Will You be angry forever? Will Your jealousy burn like fire?", 
                "num": 5
              }, 
              {
                "text": "Pour out Your wrath on the nations that do not know You, And on the kingdoms that do not call on Your name.", 
                "num": 6
              }, 
              {
                "text": "For they have devoured Jacob, And laid waste his dwelling place.", 
                "num": 7
              }, 
              {
                "text": "Oh, do not remember former iniquities against us! Let Your tender mercies come speedily to meet us, For we have been brought very low.", 
                "num": 8
              }, 
              {
                "text": "Help us, O God of our salvation, For the glory of Your name; And deliver us, and provide atonement for our sins, For Your name's sake!", 
                "num": 9
              }, 
              {
                "text": "Why should the nations say, \"Where is their God?\" Let there be known among the nations in our sight The avenging of the blood of Your servants which has been shed.", 
                "num": 10
              }, 
              {
                "text": "Let the groaning of the prisoner come before You; According to the greatness of Your power Preserve those who are appointed to die;", 
                "num": 11
              }, 
              {
                "text": "And return to our neighbors sevenfold into their bosom Their reproach with which they have reproached You, O Lord.", 
                "num": 12
              }, 
              {
                "text": "So we, Your people and sheep of Your pasture, Will give You thanks forever; We will show forth Your praise to all generations.", 
                "num": 13
              }
            ], 
            "num": 79
          }, 
          {
            "verses": [
              {
                "text": "Give ear, O Shepherd of Israel, You who lead Joseph like a flock; You who dwell between the cherubim, shine forth!", 
                "num": 1
              }, 
              {
                "text": "Before Ephraim, Benjamin, and Manasseh, Stir up Your strength, And come and save us!", 
                "num": 2
              }, 
              {
                "text": "Restore us, O God; Cause Your face to shine, And we shall be saved!", 
                "num": 3
              }, 
              {
                "text": "O LORD God of hosts, How long will You be angry Against the prayer of Your people?", 
                "num": 4
              }, 
              {
                "text": "You have fed them with the bread of tears, And given them tears to drink in great measure.", 
                "num": 5
              }, 
              {
                "text": "You have made us a strife to our neighbors, And our enemies laugh among themselves.", 
                "num": 6
              }, 
              {
                "text": "Restore us, O God of hosts; Cause Your face to shine, And we shall be saved!", 
                "num": 7
              }, 
              {
                "text": "You have brought a vine out of Egypt; You have cast out the nations, and planted it.", 
                "num": 8
              }, 
              {
                "text": "You prepared room for it, And caused it to take deep root, And it filled the land.", 
                "num": 9
              }, 
              {
                "text": "The hills were covered with its shadow, And the mighty cedars with its boughs.", 
                "num": 10
              }, 
              {
                "text": "She sent out her boughs to the Sea, And her branches to the River.", 
                "num": 11
              }, 
              {
                "text": "Why have You broken down her hedges, So that all who pass by the way pluck her fruit?", 
                "num": 12
              }, 
              {
                "text": "The boar out of the woods uproots it, And the wild beast of the field devours it.", 
                "num": 13
              }, 
              {
                "text": "Return, we beseech You, O God of hosts; Look down from heaven and see, And visit this vine", 
                "num": 14
              }, 
              {
                "text": "And the vineyard which Your right hand has planted, And the branch that You made strong for Yourself.", 
                "num": 15
              }, 
              {
                "text": "It is burned with fire, it is cut down; They perish at the rebuke of Your countenance.", 
                "num": 16
              }, 
              {
                "text": "Let Your hand be upon the man of Your right hand, Upon the son of man whom You made strong for Yourself.", 
                "num": 17
              }, 
              {
                "text": "Then we will not turn back from You; Revive us, and we will call upon Your name.", 
                "num": 18
              }, 
              {
                "text": "Restore us, O LORD God of hosts; Cause Your face to shine, And we shall be saved!", 
                "num": 19
              }
            ], 
            "num": 80
          }, 
          {
            "verses": [
              {
                "text": "Sing aloud to God our strength; Make a joyful shout to the God of Jacob.", 
                "num": 1
              }, 
              {
                "text": "Raise a song and strike the timbrel, The pleasant harp with the lute.", 
                "num": 2
              }, 
              {
                "text": "Blow the trumpet at the time of the New Moon, At the full moon, on our solemn feast day.", 
                "num": 3
              }, 
              {
                "text": "For this is a statute for Israel, A law of the God of Jacob.", 
                "num": 4
              }, 
              {
                "text": "This He established in Joseph as a testimony, When He went throughout the land of Egypt, Where I heard a language I did not understand.", 
                "num": 5
              }, 
              {
                "text": "\"I removed his shoulder from the burden; His hands were freed from the baskets.", 
                "num": 6
              }, 
              {
                "text": "You called in trouble, and I delivered you; I answered you in the secret place of thunder; I tested you at the waters of Meribah.Selah", 
                "num": 7
              }, 
              {
                "text": "\"Hear, O My people, and I will admonish you! O Israel, if you will listen to Me!", 
                "num": 8
              }, 
              {
                "text": "There shall be no foreign god among you; Nor shall you worship any foreign god.", 
                "num": 9
              }, 
              {
                "text": "I am the LORD your God, Who brought you out of the land of Egypt; Open your mouth wide, and I will fill it.", 
                "num": 10
              }, 
              {
                "text": "\"But My people would not heed My voice, And Israel would have none of Me.", 
                "num": 11
              }, 
              {
                "text": "So I gave them over to their own stubborn heart, To walk in their own counsels.", 
                "num": 12
              }, 
              {
                "text": "\"Oh, that My people would listen to Me, That Israel would walk in My ways!", 
                "num": 13
              }, 
              {
                "text": "I would soon subdue their enemies, And turn My hand against their adversaries.", 
                "num": 14
              }, 
              {
                "text": "The haters of the LORD would pretend submission to Him, But their fate would endure forever.", 
                "num": 15
              }, 
              {
                "text": "He would have fed them also with the finest of wheat; And with honey from the rock I would have satisfied you.\"", 
                "num": 16
              }
            ], 
            "num": 81
          }, 
          {
            "verses": [
              {
                "text": "God stands in the congregation of the mighty; He judges among the gods.", 
                "num": 1
              }, 
              {
                "text": "How long will you judge unjustly, And show partiality to the wicked?Selah", 
                "num": 2
              }, 
              {
                "text": "Defend the poor and fatherless; Do justice to the afflicted and needy.", 
                "num": 3
              }, 
              {
                "text": "Deliver the poor and needy; Free them from the hand of the wicked.", 
                "num": 4
              }, 
              {
                "text": "They do not know, nor do they understand; They walk about in darkness; All the foundations of the earth are unstable.", 
                "num": 5
              }, 
              {
                "text": "I said, \"You are gods, And all of you are children of the Most High.", 
                "num": 6
              }, 
              {
                "text": "But you shall die like men, And fall like one of the princes.\"", 
                "num": 7
              }, 
              {
                "text": "Arise, O God, judge the earth; For You shall inherit all nations.", 
                "num": 8
              }
            ], 
            "num": 82
          }, 
          {
            "verses": [
              {
                "text": "Do not keep silent, O God! Do not hold Your peace, And do not be still, O God!", 
                "num": 1
              }, 
              {
                "text": "For behold, Your enemies make a tumult; And those who hate You have lifted up their head.", 
                "num": 2
              }, 
              {
                "text": "They have taken crafty counsel against Your people, And consulted together against Your sheltered ones.", 
                "num": 3
              }, 
              {
                "text": "They have said, \"Come, and let us cut them off from being a nation, That the name of Israel may be remembered no more.\"", 
                "num": 4
              }, 
              {
                "text": "For they have consulted together with one consent; They form a confederacy against You:", 
                "num": 5
              }, 
              {
                "text": "The tents of Edom and the Ishmaelites; Moab and the Hagrites;", 
                "num": 6
              }, 
              {
                "text": "Gebal, Ammon, and Amalek; Philistia with the inhabitants of Tyre;", 
                "num": 7
              }, 
              {
                "text": "Assyria also has joined with them; They have helped the children of Lot.Selah", 
                "num": 8
              }, 
              {
                "text": "Deal with them as with Midian, As with Sisera, As with Jabin at the Brook Kishon,", 
                "num": 9
              }, 
              {
                "text": "Who perished at En Dor, Who became as refuse on the earth.", 
                "num": 10
              }, 
              {
                "text": "Make their nobles like Oreb and like Zeeb, Yes, all their princes like Zebah and Zalmunna,", 
                "num": 11
              }, 
              {
                "text": "Who said, \"Let us take for ourselves The pastures of God for a possession.\"", 
                "num": 12
              }, 
              {
                "text": "O my God, make them like the whirling dust, Like the chaff before the wind!", 
                "num": 13
              }, 
              {
                "text": "As the fire burns the woods, And as the flame sets the mountains on fire,", 
                "num": 14
              }, 
              {
                "text": "So pursue them with Your tempest, And frighten them with Your storm.", 
                "num": 15
              }, 
              {
                "text": "Fill their faces with shame, That they may seek Your name, O LORD.", 
                "num": 16
              }, 
              {
                "text": "Let them be confounded and dismayed forever; Yes, let them be put to shame and perish,", 
                "num": 17
              }, 
              {
                "text": "That they may know that You, whose name alone is the LORD, Are the Most High over all the earth.", 
                "num": 18
              }
            ], 
            "num": 83
          }, 
          {
            "verses": [
              {
                "text": "How lovely is Your tabernacle, O LORD of hosts!", 
                "num": 1
              }, 
              {
                "text": "My soul longs, yes, even faints For the courts of the LORD; My heart and my flesh cry out for the living God.", 
                "num": 2
              }, 
              {
                "text": "Even the sparrow has found a home, And the swallow a nest for herself, Where she may lay her young-- Even Your altars, O LORD of hosts, My King and my God.", 
                "num": 3
              }, 
              {
                "text": "Blessed are those who dwell in Your house; They will still be praising You.Selah", 
                "num": 4
              }, 
              {
                "text": "Blessed is the man whose strength is in You, Whose heart is set on pilgrimage.", 
                "num": 5
              }, 
              {
                "text": "As they pass through the Valley of Baca, They make it a spring; The rain also covers it with pools.", 
                "num": 6
              }, 
              {
                "text": "They go from strength to strength; Each one appears before God in Zion.", 
                "num": 7
              }, 
              {
                "text": "O LORD God of hosts, hear my prayer; Give ear, O God of Jacob!Selah", 
                "num": 8
              }, 
              {
                "text": "O God, behold our shield, And look upon the face of Your anointed.", 
                "num": 9
              }, 
              {
                "text": "For a day in Your courts is better than a thousand. I would rather be a doorkeeper in the house of my God Than dwell in the tents of wickedness.", 
                "num": 10
              }, 
              {
                "text": "For the LORD God is a sun and shield; The LORD will give grace and glory; No good thing will He withhold From those who walk uprightly.", 
                "num": 11
              }, 
              {
                "text": "O LORD of hosts, Blessed is the man who trusts in You!", 
                "num": 12
              }
            ], 
            "num": 84
          }, 
          {
            "verses": [
              {
                "text": "LORD, You have been favorable to Your land; You have brought back the captivity of Jacob.", 
                "num": 1
              }, 
              {
                "text": "You have forgiven the iniquity of Your people; You have covered all their sin.Selah", 
                "num": 2
              }, 
              {
                "text": "You have taken away all Your wrath; You have turned from the fierceness of Your anger.", 
                "num": 3
              }, 
              {
                "text": "Restore us, O God of our salvation, And cause Your anger toward us to cease.", 
                "num": 4
              }, 
              {
                "text": "Will You be angry with us forever? Will You prolong Your anger to all generations?", 
                "num": 5
              }, 
              {
                "text": "Will You not revive us again, That Your people may rejoice in You?", 
                "num": 6
              }, 
              {
                "text": "Show us Your mercy, LORD, And grant us Your salvation.", 
                "num": 7
              }, 
              {
                "text": "I will hear what God the LORD will speak, For He will speak peace To His people and to His saints; But let them not turn back to folly.", 
                "num": 8
              }, 
              {
                "text": "Surely His salvation is near to those who fear Him, That glory may dwell in our land.", 
                "num": 9
              }, 
              {
                "text": "Mercy and truth have met together; Righteousness and peace have kissed.", 
                "num": 10
              }, 
              {
                "text": "Truth shall spring out of the earth, And righteousness shall look down from heaven.", 
                "num": 11
              }, 
              {
                "text": "Yes, the LORD will give what is good; And our land will yield its increase.", 
                "num": 12
              }, 
              {
                "text": "Righteousness will go before Him, And shall make His footsteps our pathway.", 
                "num": 13
              }
            ], 
            "num": 85
          }, 
          {
            "verses": [
              {
                "text": "Bow down Your ear, O LORD, hear me; For I am poor and needy.", 
                "num": 1
              }, 
              {
                "text": "Preserve my life, for I am holy; You are my God; Save Your servant who trusts in You!", 
                "num": 2
              }, 
              {
                "text": "Be merciful to me, O Lord, For I cry to You all day long.", 
                "num": 3
              }, 
              {
                "text": "Rejoice the soul of Your servant, For to You, O Lord, I lift up my soul.", 
                "num": 4
              }, 
              {
                "text": "For You, Lord, are good, and ready to forgive, And abundant in mercy to all those who call upon You.", 
                "num": 5
              }, 
              {
                "text": "Give ear, O LORD, to my prayer; And attend to the voice of my supplications.", 
                "num": 6
              }, 
              {
                "text": "In the day of my trouble I will call upon You, For You will answer me.", 
                "num": 7
              }, 
              {
                "text": "Among the gods there is none like You, O Lord; Nor are there any works like Your works.", 
                "num": 8
              }, 
              {
                "text": "All nations whom You have made Shall come and worship before You, O Lord, And shall glorify Your name.", 
                "num": 9
              }, 
              {
                "text": "For You are great, and do wondrous things; You alone are God.", 
                "num": 10
              }, 
              {
                "text": "Teach me Your way, O LORD; I will walk in Your truth; Unite my heart to fear Your name.", 
                "num": 11
              }, 
              {
                "text": "I will praise You, O Lord my God, with all my heart, And I will glorify Your name forevermore.", 
                "num": 12
              }, 
              {
                "text": "For great is Your mercy toward me, And You have delivered my soul from the depths of Sheol.", 
                "num": 13
              }, 
              {
                "text": "O God, the proud have risen against me, And a mob of violent men have sought my life, And have not set You before them.", 
                "num": 14
              }, 
              {
                "text": "But You, O Lord, are a God full of compassion, and gracious, Longsuffering and abundant in mercy and truth.", 
                "num": 15
              }, 
              {
                "text": "Oh, turn to me, and have mercy on me! Give Your strength to Your servant, And save the son of Your maidservant.", 
                "num": 16
              }, 
              {
                "text": "Show me a sign for good, That those who hate me may see it and be ashamed, Because You, LORD, have helped me and comforted me.", 
                "num": 17
              }
            ], 
            "num": 86
          }, 
          {
            "verses": [
              {
                "text": "His foundation is in the holy mountains.", 
                "num": 1
              }, 
              {
                "text": "The LORD loves the gates of Zion More than all the dwellings of Jacob.", 
                "num": 2
              }, 
              {
                "text": "Glorious things are spoken of you, O city of God!Selah", 
                "num": 3
              }, 
              {
                "text": "\"I will make mention of Rahab and Babylon to those who know Me; Behold, O Philistia and Tyre, with Ethiopia: \"This one was born there.'|\"", 
                "num": 4
              }, 
              {
                "text": "And of Zion it will be said, \"This one and that one were born in her; And the Most High Himself shall establish her.\"", 
                "num": 5
              }, 
              {
                "text": "The LORD will record, When He registers the peoples: \"This one was born there.\"Selah", 
                "num": 6
              }, 
              {
                "text": "Both the singers and the players on instruments say, \"All my springs are in you.\"", 
                "num": 7
              }
            ], 
            "num": 87
          }, 
          {
            "verses": [
              {
                "text": "O LORD, God of my salvation, I have cried out day and night before You.", 
                "num": 1
              }, 
              {
                "text": "Let my prayer come before You; Incline Your ear to my cry.", 
                "num": 2
              }, 
              {
                "text": "For my soul is full of troubles, And my life draws near to the grave.", 
                "num": 3
              }, 
              {
                "text": "I am counted with those who go down to the pit; I am like a man who has no strength,", 
                "num": 4
              }, 
              {
                "text": "Adrift among the dead, Like the slain who lie in the grave, Whom You remember no more, And who are cut off from Your hand.", 
                "num": 5
              }, 
              {
                "text": "You have laid me in the lowest pit, In darkness, in the depths.", 
                "num": 6
              }, 
              {
                "text": "Your wrath lies heavy upon me, And You have afflicted me with all Your waves.Selah", 
                "num": 7
              }, 
              {
                "text": "You have put away my acquaintances far from me; You have made me an abomination to them; I am shut up, and I cannot get out;", 
                "num": 8
              }, 
              {
                "text": "My eye wastes away because of affliction. LORD, I have called daily upon You; I have stretched out my hands to You.", 
                "num": 9
              }, 
              {
                "text": "Will You work wonders for the dead? Shall the dead arise and praise You?Selah", 
                "num": 10
              }, 
              {
                "text": "Shall Your lovingkindness be declared in the grave? Or Your faithfulness in the place of destruction?", 
                "num": 11
              }, 
              {
                "text": "Shall Your wonders be known in the dark? And Your righteousness in the land of forgetfulness?", 
                "num": 12
              }, 
              {
                "text": "But to You I have cried out, O LORD, And in the morning my prayer comes before You.", 
                "num": 13
              }, 
              {
                "text": "LORD, why do You cast off my soul? Why do You hide Your face from me?", 
                "num": 14
              }, 
              {
                "text": "I have been afflicted and ready to die from my youth; I suffer Your terrors; I am distraught.", 
                "num": 15
              }, 
              {
                "text": "Your fierce wrath has gone over me; Your terrors have cut me off.", 
                "num": 16
              }, 
              {
                "text": "They came around me all day long like water; They engulfed me altogether.", 
                "num": 17
              }, 
              {
                "text": "Loved one and friend You have put far from me, And my acquaintances into darkness.", 
                "num": 18
              }
            ], 
            "num": 88
          }, 
          {
            "verses": [
              {
                "text": "I will sing of the mercies of the LORD forever; With my mouth will I make known Your faithfulness to all generations.", 
                "num": 1
              }, 
              {
                "text": "For I have said, \"Mercy shall be built up forever; Your faithfulness You shall establish in the very heavens.\"", 
                "num": 2
              }, 
              {
                "text": "\"I have made a covenant with My chosen, I have sworn to My servant David:", 
                "num": 3
              }, 
              {
                "text": "\"Your seed I will establish forever, And build up your throne to all generations.\"'Selah", 
                "num": 4
              }, 
              {
                "text": "And the heavens will praise Your wonders, O LORD; Your faithfulness also in the assembly of the saints.", 
                "num": 5
              }, 
              {
                "text": "For who in the heavens can be compared to the LORD? Who among the sons of the mighty can be likened to the LORD?", 
                "num": 6
              }, 
              {
                "text": "God is greatly to be feared in the assembly of the saints, And to be held in reverence by all those around Him.", 
                "num": 7
              }, 
              {
                "text": "O LORD God of hosts, Who is mighty like You, O LORD? Your faithfulness also surrounds You.", 
                "num": 8
              }, 
              {
                "text": "You rule the raging of the sea; When its waves rise, You still them.", 
                "num": 9
              }, 
              {
                "text": "You have broken Rahab in pieces, as one who is slain; You have scattered Your enemies with Your mighty arm.", 
                "num": 10
              }, 
              {
                "text": "The heavens are Yours, the earth also is Yours; The world and all its fullness, You have founded them.", 
                "num": 11
              }, 
              {
                "text": "The north and the south, You have created them; Tabor and Hermon rejoice in Your name.", 
                "num": 12
              }, 
              {
                "text": "You have a mighty arm; Strong is Your hand, and high is Your right hand.", 
                "num": 13
              }, 
              {
                "text": "Righteousness and justice are the foundation of Your throne; Mercy and truth go before Your face.", 
                "num": 14
              }, 
              {
                "text": "Blessed are the people who know the joyful sound! They walk, O LORD, in the light of Your countenance.", 
                "num": 15
              }, 
              {
                "text": "In Your name they rejoice all day long, And in Your righteousness they are exalted.", 
                "num": 16
              }, 
              {
                "text": "For You are the glory of their strength, And in Your favor our horn is exalted.", 
                "num": 17
              }, 
              {
                "text": "For our shield belongs to the LORD, And our king to the Holy One of Israel.", 
                "num": 18
              }, 
              {
                "text": "Then You spoke in a vision to Your holy one, And said: \"I have given help to one who is mighty; I have exalted one chosen from the people.", 
                "num": 19
              }, 
              {
                "text": "I have found My servant David; With My holy oil I have anointed him,", 
                "num": 20
              }, 
              {
                "text": "With whom My hand shall be established; Also My arm shall strengthen him.", 
                "num": 21
              }, 
              {
                "text": "The enemy shall not outwit him, Nor the son of wickedness afflict him.", 
                "num": 22
              }, 
              {
                "text": "I will beat down his foes before his face, And plague those who hate him.", 
                "num": 23
              }, 
              {
                "text": "\"But My faithfulness and My mercy shall be with him, And in My name his horn shall be exalted.", 
                "num": 24
              }, 
              {
                "text": "Also I will set his hand over the sea, And his right hand over the rivers.", 
                "num": 25
              }, 
              {
                "text": "He shall cry to Me, \"You are my Father, My God, and the rock of my salvation.'", 
                "num": 26
              }, 
              {
                "text": "Also I will make him My firstborn, The highest of the kings of the earth.", 
                "num": 27
              }, 
              {
                "text": "My mercy I will keep for him forever, And My covenant shall stand firm with him.", 
                "num": 28
              }, 
              {
                "text": "His seed also I will make to endure forever, And his throne as the days of heaven.", 
                "num": 29
              }, 
              {
                "text": "\"If his sons forsake My law And do not walk in My judgments,", 
                "num": 30
              }, 
              {
                "text": "If they break My statutes And do not keep My commandments,", 
                "num": 31
              }, 
              {
                "text": "Then I will punish their transgression with the rod, And their iniquity with stripes.", 
                "num": 32
              }, 
              {
                "text": "Nevertheless My lovingkindness I will not utterly take from him, Nor allow My faithfulness to fail.", 
                "num": 33
              }, 
              {
                "text": "My covenant I will not break, Nor alter the word that has gone out of My lips.", 
                "num": 34
              }, 
              {
                "text": "Once I have sworn by My holiness; I will not lie to David:", 
                "num": 35
              }, 
              {
                "text": "His seed shall endure forever, And his throne as the sun before Me;", 
                "num": 36
              }, 
              {
                "text": "It shall be established forever like the moon, Even like the faithful witness in the sky.\"Selah", 
                "num": 37
              }, 
              {
                "text": "But You have cast off and abhorred, You have been furious with Your anointed.", 
                "num": 38
              }, 
              {
                "text": "You have renounced the covenant of Your servant; You have profaned his crown by casting it to the ground.", 
                "num": 39
              }, 
              {
                "text": "You have broken down all his hedges; You have brought his strongholds to ruin.", 
                "num": 40
              }, 
              {
                "text": "All who pass by the way plunder him; He is a reproach to his neighbors.", 
                "num": 41
              }, 
              {
                "text": "You have exalted the right hand of his adversaries; You have made all his enemies rejoice.", 
                "num": 42
              }, 
              {
                "text": "You have also turned back the edge of his sword, And have not sustained him in the battle.", 
                "num": 43
              }, 
              {
                "text": "You have made his glory cease, And cast his throne down to the ground.", 
                "num": 44
              }, 
              {
                "text": "The days of his youth You have shortened; You have covered him with shame.Selah", 
                "num": 45
              }, 
              {
                "text": "How long, LORD? Will You hide Yourself forever? Will Your wrath burn like fire?", 
                "num": 46
              }, 
              {
                "text": "Remember how short my time is; For what futility have You created all the children of men?", 
                "num": 47
              }, 
              {
                "text": "What man can live and not see death? Can he deliver his life from the power of the grave?Selah", 
                "num": 48
              }, 
              {
                "text": "Lord, where are Your former lovingkindnesses, Which You swore to David in Your truth?", 
                "num": 49
              }, 
              {
                "text": "Remember, Lord, the reproach of Your servants-- How I bear in my bosom the reproach of all the many peoples,", 
                "num": 50
              }, 
              {
                "text": "With which Your enemies have reproached, O LORD, With which they have reproached the footsteps of Your anointed.", 
                "num": 51
              }, 
              {
                "text": "Blessed be the LORD forevermore! Amen and Amen.", 
                "num": 52
              }
            ], 
            "num": 89
          }, 
          {
            "verses": [
              {
                "text": "Lord, You have been our dwelling place in all generations.", 
                "num": 1
              }, 
              {
                "text": "Before the mountains were brought forth, Or ever You had formed the earth and the world, Even from everlasting to everlasting, You are God.", 
                "num": 2
              }, 
              {
                "text": "You turn man to destruction, And say, \"Return, O children of men.\"", 
                "num": 3
              }, 
              {
                "text": "For a thousand years in Your sight Are like yesterday when it is past, And like a watch in the night.", 
                "num": 4
              }, 
              {
                "text": "You carry them away like a flood; They are like a sleep. In the morning they are like grass which grows up:", 
                "num": 5
              }, 
              {
                "text": "In the morning it flourishes and grows up; In the evening it is cut down and withers.", 
                "num": 6
              }, 
              {
                "text": "For we have been consumed by Your anger, And by Your wrath we are terrified.", 
                "num": 7
              }, 
              {
                "text": "You have set our iniquities before You, Our secret sins in the light of Your countenance.", 
                "num": 8
              }, 
              {
                "text": "For all our days have passed away in Your wrath; We finish our years like a sigh.", 
                "num": 9
              }, 
              {
                "text": "The days of our lives are seventy years; And if by reason of strength they are eighty years, Yet their boast is only labor and sorrow; For it is soon cut off, and we fly away.", 
                "num": 10
              }, 
              {
                "text": "Who knows the power of Your anger? For as the fear of You, so is Your wrath.", 
                "num": 11
              }, 
              {
                "text": "So teach us to number our days, That we may gain a heart of wisdom.", 
                "num": 12
              }, 
              {
                "text": "Return, O LORD! How long? And have compassion on Your servants.", 
                "num": 13
              }, 
              {
                "text": "Oh, satisfy us early with Your mercy, That we may rejoice and be glad all our days!", 
                "num": 14
              }, 
              {
                "text": "Make us glad according to the days in which You have afflicted us, The years in which we have seen evil.", 
                "num": 15
              }, 
              {
                "text": "Let Your work appear to Your servants, And Your glory to their children.", 
                "num": 16
              }, 
              {
                "text": "And let the beauty of the LORD our God be upon us, And establish the work of our hands for us; Yes, establish the work of our hands.", 
                "num": 17
              }
            ], 
            "num": 90
          }, 
          {
            "verses": [
              {
                "text": "He who dwells in the secret place of the Most High Shall abide under the shadow of the Almighty.", 
                "num": 1
              }, 
              {
                "text": "I will say of the LORD, \"He is my refuge and my fortress; My God, in Him I will trust.\"", 
                "num": 2
              }, 
              {
                "text": "Surely He shall deliver you from the snare of the fowler And from the perilous pestilence.", 
                "num": 3
              }, 
              {
                "text": "He shall cover you with His feathers, And under His wings you shall take refuge; His truth shall be your shield and buckler.", 
                "num": 4
              }, 
              {
                "text": "You shall not be afraid of the terror by night, Nor of the arrow that flies by day,", 
                "num": 5
              }, 
              {
                "text": "Nor of the pestilence that walks in darkness, Nor of the destruction that lays waste at noonday.", 
                "num": 6
              }, 
              {
                "text": "A thousand may fall at your side, And ten thousand at your right hand; But it shall not come near you.", 
                "num": 7
              }, 
              {
                "text": "Only with your eyes shall you look, And see the reward of the wicked.", 
                "num": 8
              }, 
              {
                "text": "Because you have made the LORD, who is my refuge, Even the Most High, your dwelling place,", 
                "num": 9
              }, 
              {
                "text": "No evil shall befall you, Nor shall any plague come near your dwelling;", 
                "num": 10
              }, 
              {
                "text": "For He shall give His angels charge over you, To keep you in all your ways.", 
                "num": 11
              }, 
              {
                "text": "In their hands they shall bear you up, Lest you dash your foot against a stone.", 
                "num": 12
              }, 
              {
                "text": "You shall tread upon the lion and the cobra, The young lion and the serpent you shall trample underfoot.", 
                "num": 13
              }, 
              {
                "text": "\"Because he has set his love upon Me, therefore I will deliver him; I will set him on high, because he has known My name.", 
                "num": 14
              }, 
              {
                "text": "He shall call upon Me, and I will answer him; I will be with him in trouble; I will deliver him and honor him.", 
                "num": 15
              }, 
              {
                "text": "With long life I will satisfy him, And show him My salvation.\"", 
                "num": 16
              }
            ], 
            "num": 91
          }, 
          {
            "verses": [
              {
                "text": "It is good to give thanks to the LORD, And to sing praises to Your name, O Most High;", 
                "num": 1
              }, 
              {
                "text": "To declare Your lovingkindness in the morning, And Your faithfulness every night,", 
                "num": 2
              }, 
              {
                "text": "On an instrument of ten strings, On the lute, And on the harp, With harmonious sound.", 
                "num": 3
              }, 
              {
                "text": "For You, LORD, have made me glad through Your work; I will triumph in the works of Your hands.", 
                "num": 4
              }, 
              {
                "text": "O LORD, how great are Your works! Your thoughts are very deep.", 
                "num": 5
              }, 
              {
                "text": "A senseless man does not know, Nor does a fool understand this.", 
                "num": 6
              }, 
              {
                "text": "When the wicked spring up like grass, And when all the workers of iniquity flourish, It is that they may be destroyed forever.", 
                "num": 7
              }, 
              {
                "text": "But You, LORD, are on high forevermore.", 
                "num": 8
              }, 
              {
                "text": "For behold, Your enemies, O LORD, For behold, Your enemies shall perish; All the workers of iniquity shall be scattered.", 
                "num": 9
              }, 
              {
                "text": "But my horn You have exalted like a wild ox; I have been anointed with fresh oil.", 
                "num": 10
              }, 
              {
                "text": "My eye also has seen my desire on my enemies; My ears hear my desire on the wicked Who rise up against me.", 
                "num": 11
              }, 
              {
                "text": "The righteous shall flourish like a palm tree, He shall grow like a cedar in Lebanon.", 
                "num": 12
              }, 
              {
                "text": "Those who are planted in the house of the LORD Shall flourish in the courts of our God.", 
                "num": 13
              }, 
              {
                "text": "They shall still bear fruit in old age; They shall be fresh and flourishing,", 
                "num": 14
              }, 
              {
                "text": "To declare that the LORD is upright; He is my rock, and there is no unrighteousness in Him.", 
                "num": 15
              }
            ], 
            "num": 92
          }, 
          {
            "verses": [
              {
                "text": "The LORD reigns, He is clothed with majesty; The LORD is clothed, He has girded Himself with strength. Surely the world is established, so that it cannot be moved.", 
                "num": 1
              }, 
              {
                "text": "Your throne is established from of old; You are from everlasting.", 
                "num": 2
              }, 
              {
                "text": "The floods have lifted up, O LORD, The floods have lifted up their voice; The floods lift up their waves.", 
                "num": 3
              }, 
              {
                "text": "The LORD on high is mightier Than the noise of many waters, Than the mighty waves of the sea.", 
                "num": 4
              }, 
              {
                "text": "Your testimonies are very sure; Holiness adorns Your house, O LORD, forever.", 
                "num": 5
              }
            ], 
            "num": 93
          }, 
          {
            "verses": [
              {
                "text": "O LORD God, to whom vengeance belongs-- O God, to whom vengeance belongs, shine forth!", 
                "num": 1
              }, 
              {
                "text": "Rise up, O Judge of the earth; Render punishment to the proud.", 
                "num": 2
              }, 
              {
                "text": "LORD, how long will the wicked, How long will the wicked triumph?", 
                "num": 3
              }, 
              {
                "text": "They utter speech, and speak insolent things; All the workers of iniquity boast in themselves.", 
                "num": 4
              }, 
              {
                "text": "They break in pieces Your people, O LORD, And afflict Your heritage.", 
                "num": 5
              }, 
              {
                "text": "They slay the widow and the stranger, And murder the fatherless.", 
                "num": 6
              }, 
              {
                "text": "Yet they say, \"The LORD does not see, Nor does the God of Jacob understand.\"", 
                "num": 7
              }, 
              {
                "text": "Understand, you senseless among the people; And you fools, when will you be wise?", 
                "num": 8
              }, 
              {
                "text": "He who planted the ear, shall He not hear? He who formed the eye, shall He not see?", 
                "num": 9
              }, 
              {
                "text": "He who instructs the nations, shall He not correct, He who teaches man knowledge?", 
                "num": 10
              }, 
              {
                "text": "The LORD knows the thoughts of man, That they are futile.", 
                "num": 11
              }, 
              {
                "text": "Blessed is the man whom You instruct, O LORD, And teach out of Your law,", 
                "num": 12
              }, 
              {
                "text": "That You may give him rest from the days of adversity, Until the pit is dug for the wicked.", 
                "num": 13
              }, 
              {
                "text": "For the LORD will not cast off His people, Nor will He forsake His inheritance.", 
                "num": 14
              }, 
              {
                "text": "But judgment will return to righteousness, And all the upright in heart will follow it.", 
                "num": 15
              }, 
              {
                "text": "Who will rise up for me against the evildoers? Who will stand up for me against the workers of iniquity?", 
                "num": 16
              }, 
              {
                "text": "Unless the LORD had been my help, My soul would soon have settled in silence.", 
                "num": 17
              }, 
              {
                "text": "If I say, \"My foot slips,\" Your mercy, O LORD, will hold me up.", 
                "num": 18
              }, 
              {
                "text": "In the multitude of my anxieties within me, Your comforts delight my soul.", 
                "num": 19
              }, 
              {
                "text": "Shall the throne of iniquity, which devises evil by law, Have fellowship with You?", 
                "num": 20
              }, 
              {
                "text": "They gather together against the life of the righteous, And condemn innocent blood.", 
                "num": 21
              }, 
              {
                "text": "But the LORD has been my defense, And my God the rock of my refuge.", 
                "num": 22
              }, 
              {
                "text": "He has brought on them their own iniquity, And shall cut them off in their own wickedness; The LORD our God shall cut them off.", 
                "num": 23
              }
            ], 
            "num": 94
          }, 
          {
            "verses": [
              {
                "text": "Oh come, let us sing to the LORD! Let us shout joyfully to the Rock of our salvation.", 
                "num": 1
              }, 
              {
                "text": "Let us come before His presence with thanksgiving; Let us shout joyfully to Him with psalms.", 
                "num": 2
              }, 
              {
                "text": "For the LORD is the great God, And the great King above all gods.", 
                "num": 3
              }, 
              {
                "text": "In His hand are the deep places of the earth; The heights of the hills are His also.", 
                "num": 4
              }, 
              {
                "text": "The sea is His, for He made it; And His hands formed the dry land.", 
                "num": 5
              }, 
              {
                "text": "Oh come, let us worship and bow down; Let us kneel before the LORD our Maker.", 
                "num": 6
              }, 
              {
                "text": "For He is our God, And we are the people of His pasture, And the sheep of His hand. Today, if you will hear His voice:", 
                "num": 7
              }, 
              {
                "text": "\"Do not harden your hearts, as in the rebellion, As in the day of trial in the wilderness,", 
                "num": 8
              }, 
              {
                "text": "When your fathers tested Me; They tried Me, though they saw My work.", 
                "num": 9
              }, 
              {
                "text": "For forty years I was grieved with that generation, And said, \"It is a people who go astray in their hearts, And they do not know My ways.'", 
                "num": 10
              }, 
              {
                "text": "So I swore in My wrath, \"They shall not enter My rest.'|\"", 
                "num": 11
              }
            ], 
            "num": 95
          }, 
          {
            "verses": [
              {
                "text": "Oh, sing to the LORD a new song! Sing to the LORD, all the earth.", 
                "num": 1
              }, 
              {
                "text": "Sing to the LORD, bless His name; Proclaim the good news of His salvation from day to day.", 
                "num": 2
              }, 
              {
                "text": "Declare His glory among the nations, His wonders among all peoples.", 
                "num": 3
              }, 
              {
                "text": "For the LORD is great and greatly to be praised; He is to be feared above all gods.", 
                "num": 4
              }, 
              {
                "text": "For all the gods of the peoples are idols, But the LORD made the heavens.", 
                "num": 5
              }, 
              {
                "text": "Honor and majesty are before Him; Strength and beauty are in His sanctuary.", 
                "num": 6
              }, 
              {
                "text": "Give to the LORD, O families of the peoples, Give to the LORD glory and strength.", 
                "num": 7
              }, 
              {
                "text": "Give to the LORD the glory due His name; Bring an offering, and come into His courts.", 
                "num": 8
              }, 
              {
                "text": "Oh, worship the LORD in the beauty of holiness! Tremble before Him, all the earth.", 
                "num": 9
              }, 
              {
                "text": "Say among the nations, \"The LORD reigns; The world also is firmly established, It shall not be moved; He shall judge the peoples righteously.\"", 
                "num": 10
              }, 
              {
                "text": "Let the heavens rejoice, and let the earth be glad; Let the sea roar, and all its fullness;", 
                "num": 11
              }, 
              {
                "text": "Let the field be joyful, and all that is in it. Then all the trees of the woods will rejoice before the LORD.", 
                "num": 12
              }, 
              {
                "text": "For He is coming, for He is coming to judge the earth. He shall judge the world with righteousness, And the peoples with His truth.", 
                "num": 13
              }
            ], 
            "num": 96
          }, 
          {
            "verses": [
              {
                "text": "The LORD reigns; Let the earth rejoice; Let the multitude of isles be glad!", 
                "num": 1
              }, 
              {
                "text": "Clouds and darkness surround Him; Righteousness and justice are the foundation of His throne.", 
                "num": 2
              }, 
              {
                "text": "A fire goes before Him, And burns up His enemies round about.", 
                "num": 3
              }, 
              {
                "text": "His lightnings light the world; The earth sees and trembles.", 
                "num": 4
              }, 
              {
                "text": "The mountains melt like wax at the presence of the LORD, At the presence of the Lord of the whole earth.", 
                "num": 5
              }, 
              {
                "text": "The heavens declare His righteousness, And all the peoples see His glory.", 
                "num": 6
              }, 
              {
                "text": "Let all be put to shame who serve carved images, Who boast of idols. Worship Him, all you gods.", 
                "num": 7
              }, 
              {
                "text": "Zion hears and is glad, And the daughters of Judah rejoice Because of Your judgments, O LORD.", 
                "num": 8
              }, 
              {
                "text": "For You, LORD, are most high above all the earth; You are exalted far above all gods.", 
                "num": 9
              }, 
              {
                "text": "You who love the LORD, hate evil! He preserves the souls of His saints; He delivers them out of the hand of the wicked.", 
                "num": 10
              }, 
              {
                "text": "Light is sown for the righteous, And gladness for the upright in heart.", 
                "num": 11
              }, 
              {
                "text": "Rejoice in the LORD, you righteous, And give thanks at the remembrance of His holy name.", 
                "num": 12
              }
            ], 
            "num": 97
          }, 
          {
            "verses": [
              {
                "text": "Oh, sing to the LORD a new song! For He has done marvelous things; His right hand and His holy arm have gained Him the victory.", 
                "num": 1
              }, 
              {
                "text": "The LORD has made known His salvation; His righteousness He has revealed in the sight of the nations.", 
                "num": 2
              }, 
              {
                "text": "He has remembered His mercy and His faithfulness to the house of Israel; All the ends of the earth have seen the salvation of our God.", 
                "num": 3
              }, 
              {
                "text": "Shout joyfully to the LORD, all the earth; Break forth in song, rejoice, and sing praises.", 
                "num": 4
              }, 
              {
                "text": "Sing to the LORD with the harp, With the harp and the sound of a psalm,", 
                "num": 5
              }, 
              {
                "text": "With trumpets and the sound of a horn; Shout joyfully before the LORD, the King.", 
                "num": 6
              }, 
              {
                "text": "Let the sea roar, and all its fullness, The world and those who dwell in it;", 
                "num": 7
              }, 
              {
                "text": "Let the rivers clap their hands; Let the hills be joyful together before the LORD,", 
                "num": 8
              }, 
              {
                "text": "For He is coming to judge the earth. With righteousness He shall judge the world, And the peoples with equity.", 
                "num": 9
              }
            ], 
            "num": 98
          }, 
          {
            "verses": [
              {
                "text": "The LORD reigns; Let the peoples tremble! He dwells between the cherubim; Let the earth be moved!", 
                "num": 1
              }, 
              {
                "text": "The LORD is great in Zion, And He is high above all the peoples.", 
                "num": 2
              }, 
              {
                "text": "Let them praise Your great and awesome name-- He is holy.", 
                "num": 3
              }, 
              {
                "text": "The King's strength also loves justice; You have established equity; You have executed justice and righteousness in Jacob.", 
                "num": 4
              }, 
              {
                "text": "Exalt the LORD our God, And worship at His footstool-- He is holy.", 
                "num": 5
              }, 
              {
                "text": "Moses and Aaron were among His priests, And Samuel was among those who called upon His name; They called upon the LORD, and He answered them.", 
                "num": 6
              }, 
              {
                "text": "He spoke to them in the cloudy pillar; They kept His testimonies and the ordinance He gave them.", 
                "num": 7
              }, 
              {
                "text": "You answered them, O LORD our God; You were to them God-Who-Forgives, Though You took vengeance on their deeds.", 
                "num": 8
              }, 
              {
                "text": "Exalt the LORD our God, And worship at His holy hill; For the LORD our God is holy.", 
                "num": 9
              }
            ], 
            "num": 99
          }, 
          {
            "verses": [
              {
                "text": "Make a joyful shout to the LORD, all you lands!", 
                "num": 1
              }, 
              {
                "text": "Serve the LORD with gladness; Come before His presence with singing.", 
                "num": 2
              }, 
              {
                "text": "Know that the LORD, He is God; It is He who has made us, and not we ourselves; We are His people and the sheep of His pasture.", 
                "num": 3
              }, 
              {
                "text": "Enter into His gates with thanksgiving, And into His courts with praise. Be thankful to Him, and bless His name.", 
                "num": 4
              }, 
              {
                "text": "For the LORD is good; His mercy is everlasting, And His truth endures to all generations.", 
                "num": 5
              }
            ], 
            "num": 100
          }, 
          {
            "verses": [
              {
                "text": "I will sing of mercy and justice; To You, O LORD, I will sing praises.", 
                "num": 1
              }, 
              {
                "text": "I will behave wisely in a perfect way. Oh, when will You come to me? I will walk within my house with a perfect heart.", 
                "num": 2
              }, 
              {
                "text": "I will set nothing wicked before my eyes; I hate the work of those who fall away; It shall not cling to me.", 
                "num": 3
              }, 
              {
                "text": "A perverse heart shall depart from me; I will not know wickedness.", 
                "num": 4
              }, 
              {
                "text": "Whoever secretly slanders his neighbor, Him I will destroy; The one who has a haughty look and a proud heart, Him I will not endure.", 
                "num": 5
              }, 
              {
                "text": "My eyes shall be on the faithful of the land, That they may dwell with me; He who walks in a perfect way, He shall serve me.", 
                "num": 6
              }, 
              {
                "text": "He who works deceit shall not dwell within my house; He who tells lies shall not continue in my presence.", 
                "num": 7
              }, 
              {
                "text": "Early I will destroy all the wicked of the land, That I may cut off all the evildoers from the city of the LORD.", 
                "num": 8
              }
            ], 
            "num": 101
          }, 
          {
            "verses": [
              {
                "text": "Hear my prayer, O LORD, And let my cry come to You.", 
                "num": 1
              }, 
              {
                "text": "Do not hide Your face from me in the day of my trouble; Incline Your ear to me; In the day that I call, answer me speedily.", 
                "num": 2
              }, 
              {
                "text": "For my days are consumed like smoke, And my bones are burned like a hearth.", 
                "num": 3
              }, 
              {
                "text": "My heart is stricken and withered like grass, So that I forget to eat my bread.", 
                "num": 4
              }, 
              {
                "text": "Because of the sound of my groaning My bones cling to my skin.", 
                "num": 5
              }, 
              {
                "text": "I am like a pelican of the wilderness; I am like an owl of the desert.", 
                "num": 6
              }, 
              {
                "text": "I lie awake, And am like a sparrow alone on the housetop.", 
                "num": 7
              }, 
              {
                "text": "My enemies reproach me all day long; Those who deride me swear an oath against me.", 
                "num": 8
              }, 
              {
                "text": "For I have eaten ashes like bread, And mingled my drink with weeping,", 
                "num": 9
              }, 
              {
                "text": "Because of Your indignation and Your wrath; For You have lifted me up and cast me away.", 
                "num": 10
              }, 
              {
                "text": "My days are like a shadow that lengthens, And I wither away like grass.", 
                "num": 11
              }, 
              {
                "text": "But You, O LORD, shall endure forever, And the remembrance of Your name to all generations.", 
                "num": 12
              }, 
              {
                "text": "You will arise and have mercy on Zion; For the time to favor her, Yes, the set time, has come.", 
                "num": 13
              }, 
              {
                "text": "For Your servants take pleasure in her stones, And show favor to her dust.", 
                "num": 14
              }, 
              {
                "text": "So the nations shall fear the name of the LORD, And all the kings of the earth Your glory.", 
                "num": 15
              }, 
              {
                "text": "For the LORD shall build up Zion; He shall appear in His glory.", 
                "num": 16
              }, 
              {
                "text": "He shall regard the prayer of the destitute, And shall not despise their prayer.", 
                "num": 17
              }, 
              {
                "text": "This will be written for the generation to come, That a people yet to be created may praise the LORD.", 
                "num": 18
              }, 
              {
                "text": "For He looked down from the height of His sanctuary; From heaven the LORD viewed the earth,", 
                "num": 19
              }, 
              {
                "text": "To hear the groaning of the prisoner, To release those appointed to death,", 
                "num": 20
              }, 
              {
                "text": "To declare the name of the LORD in Zion, And His praise in Jerusalem,", 
                "num": 21
              }, 
              {
                "text": "When the peoples are gathered together, And the kingdoms, to serve the LORD.", 
                "num": 22
              }, 
              {
                "text": "He weakened my strength in the way; He shortened my days.", 
                "num": 23
              }, 
              {
                "text": "I said, \"O my God, Do not take me away in the midst of my days; Your years are throughout all generations.", 
                "num": 24
              }, 
              {
                "text": "Of old You laid the foundation of the earth, And the heavens are the work of Your hands.", 
                "num": 25
              }, 
              {
                "text": "They will perish, but You will endure; Yes, they will all grow old like a garment; Like a cloak You will change them, And they will be changed.", 
                "num": 26
              }, 
              {
                "text": "But You are the same, And Your years will have no end.", 
                "num": 27
              }, 
              {
                "text": "The children of Your servants will continue, And their descendants will be established before You.\"", 
                "num": 28
              }
            ], 
            "num": 102
          }, 
          {
            "verses": [
              {
                "text": "Bless the LORD, O my soul; And all that is within me, bless His holy name!", 
                "num": 1
              }, 
              {
                "text": "Bless the LORD, O my soul, And forget not all His benefits:", 
                "num": 2
              }, 
              {
                "text": "Who forgives all your iniquities, Who heals all your diseases,", 
                "num": 3
              }, 
              {
                "text": "Who redeems your life from destruction, Who crowns you with lovingkindness and tender mercies,", 
                "num": 4
              }, 
              {
                "text": "Who satisfies your mouth with good things, So that your youth is renewed like the eagle's.", 
                "num": 5
              }, 
              {
                "text": "The LORD executes righteousness And justice for all who are oppressed.", 
                "num": 6
              }, 
              {
                "text": "He made known His ways to Moses, His acts to the children of Israel.", 
                "num": 7
              }, 
              {
                "text": "The LORD is merciful and gracious, Slow to anger, and abounding in mercy.", 
                "num": 8
              }, 
              {
                "text": "He will not always strive with us, Nor will He keep His anger forever.", 
                "num": 9
              }, 
              {
                "text": "He has not dealt with us according to our sins, Nor punished us according to our iniquities.", 
                "num": 10
              }, 
              {
                "text": "For as the heavens are high above the earth, So great is His mercy toward those who fear Him;", 
                "num": 11
              }, 
              {
                "text": "As far as the east is from the west, So far has He removed our transgressions from us.", 
                "num": 12
              }, 
              {
                "text": "As a father pities his children, So the LORD pities those who fear Him.", 
                "num": 13
              }, 
              {
                "text": "For He knows our frame; He remembers that we are dust.", 
                "num": 14
              }, 
              {
                "text": "As for man, his days are like grass; As a flower of the field, so he flourishes.", 
                "num": 15
              }, 
              {
                "text": "For the wind passes over it, and it is gone, And its place remembers it no more.", 
                "num": 16
              }, 
              {
                "text": "But the mercy of the LORD is from everlasting to everlasting On those who fear Him, And His righteousness to children's children,", 
                "num": 17
              }, 
              {
                "text": "To such as keep His covenant, And to those who remember His commandments to do them.", 
                "num": 18
              }, 
              {
                "text": "The LORD has established His throne in heaven, And His kingdom rules over all.", 
                "num": 19
              }, 
              {
                "text": "Bless the LORD, you His angels, Who excel in strength, who do His word, Heeding the voice of His word.", 
                "num": 20
              }, 
              {
                "text": "Bless the LORD, all you His hosts, You ministers of His, who do His pleasure.", 
                "num": 21
              }, 
              {
                "text": "Bless the LORD, all His works, In all places of His dominion. Bless the LORD, O my soul!", 
                "num": 22
              }
            ], 
            "num": 103
          }, 
          {
            "verses": [
              {
                "text": "Bless the LORD, O my soul! O LORD my God, You are very great: You are clothed with honor and majesty,", 
                "num": 1
              }, 
              {
                "text": "Who cover Yourself with light as with a garment, Who stretch out the heavens like a curtain.", 
                "num": 2
              }, 
              {
                "text": "He lays the beams of His upper chambers in the waters, Who makes the clouds His chariot, Who walks on the wings of the wind,", 
                "num": 3
              }, 
              {
                "text": "Who makes His angels spirits, His ministers a flame of fire.", 
                "num": 4
              }, 
              {
                "text": "You who laid the foundations of the earth, So that it should not be moved forever,", 
                "num": 5
              }, 
              {
                "text": "You covered it with the deep as with a garment; The waters stood above the mountains.", 
                "num": 6
              }, 
              {
                "text": "At Your rebuke they fled; At the voice of Your thunder they hastened away.", 
                "num": 7
              }, 
              {
                "text": "They went up over the mountains; They went down into the valleys, To the place which You founded for them.", 
                "num": 8
              }, 
              {
                "text": "You have set a boundary that they may not pass over, That they may not return to cover the earth.", 
                "num": 9
              }, 
              {
                "text": "He sends the springs into the valleys; They flow among the hills.", 
                "num": 10
              }, 
              {
                "text": "They give drink to every beast of the field; The wild donkeys quench their thirst.", 
                "num": 11
              }, 
              {
                "text": "By them the birds of the heavens have their home; They sing among the branches.", 
                "num": 12
              }, 
              {
                "text": "He waters the hills from His upper chambers; The earth is satisfied with the fruit of Your works.", 
                "num": 13
              }, 
              {
                "text": "He causes the grass to grow for the cattle, And vegetation for the service of man, That he may bring forth food from the earth,", 
                "num": 14
              }, 
              {
                "text": "And wine that makes glad the heart of man, Oil to make his face shine, And bread which strengthens man's heart.", 
                "num": 15
              }, 
              {
                "text": "The trees of the LORD are full of sap, The cedars of Lebanon which He planted,", 
                "num": 16
              }, 
              {
                "text": "Where the birds make their nests; The stork has her home in the fir trees.", 
                "num": 17
              }, 
              {
                "text": "The high hills are for the wild goats; The cliffs are a refuge for the rock badgers.", 
                "num": 18
              }, 
              {
                "text": "He appointed the moon for seasons; The sun knows its going down.", 
                "num": 19
              }, 
              {
                "text": "You make darkness, and it is night, In which all the beasts of the forest creep about.", 
                "num": 20
              }, 
              {
                "text": "The young lions roar after their prey, And seek their food from God.", 
                "num": 21
              }, 
              {
                "text": "When the sun rises, they gather together And lie down in their dens.", 
                "num": 22
              }, 
              {
                "text": "Man goes out to his work And to his labor until the evening.", 
                "num": 23
              }, 
              {
                "text": "O LORD, how manifold are Your works! In wisdom You have made them all. The earth is full of Your possessions--", 
                "num": 24
              }, 
              {
                "text": "This great and wide sea, In which are innumerable teeming things, Living things both small and great.", 
                "num": 25
              }, 
              {
                "text": "There the ships sail about; There is that Leviathan Which You have made to play there.", 
                "num": 26
              }, 
              {
                "text": "These all wait for You, That You may give them their food in due season.", 
                "num": 27
              }, 
              {
                "text": "What You give them they gather in; You open Your hand, they are filled with good.", 
                "num": 28
              }, 
              {
                "text": "You hide Your face, they are troubled; You take away their breath, they die and return to their dust.", 
                "num": 29
              }, 
              {
                "text": "You send forth Your Spirit, they are created; And You renew the face of the earth.", 
                "num": 30
              }, 
              {
                "text": "May the glory of the LORD endure forever; May the LORD rejoice in His works.", 
                "num": 31
              }, 
              {
                "text": "He looks on the earth, and it trembles; He touches the hills, and they smoke.", 
                "num": 32
              }, 
              {
                "text": "I will sing to the LORD as long as I live; I will sing praise to my God while I have my being.", 
                "num": 33
              }, 
              {
                "text": "May my meditation be sweet to Him; I will be glad in the LORD.", 
                "num": 34
              }, 
              {
                "text": "May sinners be consumed from the earth, And the wicked be no more. Bless the LORD, O my soul! Praise the LORD!", 
                "num": 35
              }
            ], 
            "num": 104
          }, 
          {
            "verses": [
              {
                "text": "Oh, give thanks to the LORD! Call upon His name; Make known His deeds among the peoples!", 
                "num": 1
              }, 
              {
                "text": "Sing to Him, sing psalms to Him; Talk of all His wondrous works!", 
                "num": 2
              }, 
              {
                "text": "Glory in His holy name; Let the hearts of those rejoice who seek the LORD!", 
                "num": 3
              }, 
              {
                "text": "Seek the LORD and His strength; Seek His face evermore!", 
                "num": 4
              }, 
              {
                "text": "Remember His marvelous works which He has done, His wonders, and the judgments of His mouth,", 
                "num": 5
              }, 
              {
                "text": "O seed of Abraham His servant, You children of Jacob, His chosen ones!", 
                "num": 6
              }, 
              {
                "text": "He is the LORD our God; His judgments are in all the earth.", 
                "num": 7
              }, 
              {
                "text": "He remembers His covenant forever, The word which He commanded, for a thousand generations,", 
                "num": 8
              }, 
              {
                "text": "The covenant which He made with Abraham, And His oath to Isaac,", 
                "num": 9
              }, 
              {
                "text": "And confirmed it to Jacob for a statute, To Israel as an everlasting covenant,", 
                "num": 10
              }, 
              {
                "text": "Saying, \"To you I will give the land of Canaan As the allotment of your inheritance,\"", 
                "num": 11
              }, 
              {
                "text": "When they were few in number, Indeed very few, and strangers in it.", 
                "num": 12
              }, 
              {
                "text": "When they went from one nation to another, From one kingdom to another people,", 
                "num": 13
              }, 
              {
                "text": "He permitted no one to do them wrong; Yes, He rebuked kings for their sakes,", 
                "num": 14
              }, 
              {
                "text": "Saying, \"Do not touch My anointed ones, And do My prophets no harm.\"", 
                "num": 15
              }, 
              {
                "text": "Moreover He called for a famine in the land; He destroyed all the provision of bread.", 
                "num": 16
              }, 
              {
                "text": "He sent a man before them-- Joseph--who was sold as a slave.", 
                "num": 17
              }, 
              {
                "text": "They hurt his feet with fetters, He was laid in irons.", 
                "num": 18
              }, 
              {
                "text": "Until the time that his word came to pass, The word of the LORD tested him.", 
                "num": 19
              }, 
              {
                "text": "The king sent and released him, The ruler of the people let him go free.", 
                "num": 20
              }, 
              {
                "text": "He made him lord of his house, And ruler of all his possessions,", 
                "num": 21
              }, 
              {
                "text": "To bind his princes at his pleasure, And teach his elders wisdom.", 
                "num": 22
              }, 
              {
                "text": "Israel also came into Egypt, And Jacob dwelt in the land of Ham.", 
                "num": 23
              }, 
              {
                "text": "He increased His people greatly, And made them stronger than their enemies.", 
                "num": 24
              }, 
              {
                "text": "He turned their heart to hate His people, To deal craftily with His servants.", 
                "num": 25
              }, 
              {
                "text": "He sent Moses His servant, And Aaron whom He had chosen.", 
                "num": 26
              }, 
              {
                "text": "They performed His signs among them, And wonders in the land of Ham.", 
                "num": 27
              }, 
              {
                "text": "He sent darkness, and made it dark; And they did not rebel against His word.", 
                "num": 28
              }, 
              {
                "text": "He turned their waters into blood, And killed their fish.", 
                "num": 29
              }, 
              {
                "text": "Their land abounded with frogs, Even in the chambers of their kings.", 
                "num": 30
              }, 
              {
                "text": "He spoke, and there came swarms of flies, And lice in all their territory.", 
                "num": 31
              }, 
              {
                "text": "He gave them hail for rain, And flaming fire in their land.", 
                "num": 32
              }, 
              {
                "text": "He struck their vines also, and their fig trees, And splintered the trees of their territory.", 
                "num": 33
              }, 
              {
                "text": "He spoke, and locusts came, Young locusts without number,", 
                "num": 34
              }, 
              {
                "text": "And ate up all the vegetation in their land, And devoured the fruit of their ground.", 
                "num": 35
              }, 
              {
                "text": "He also destroyed all the firstborn in their land, The first of all their strength.", 
                "num": 36
              }, 
              {
                "text": "He also brought them out with silver and gold, And there was none feeble among His tribes.", 
                "num": 37
              }, 
              {
                "text": "Egypt was glad when they departed, For the fear of them had fallen upon them.", 
                "num": 38
              }, 
              {
                "text": "He spread a cloud for a covering, And fire to give light in the night.", 
                "num": 39
              }, 
              {
                "text": "The people asked, and He brought quail, And satisfied them with the bread of heaven.", 
                "num": 40
              }, 
              {
                "text": "He opened the rock, and water gushed out; It ran in the dry places like a river.", 
                "num": 41
              }, 
              {
                "text": "For He remembered His holy promise, And Abraham His servant.", 
                "num": 42
              }, 
              {
                "text": "He brought out His people with joy, His chosen ones with gladness.", 
                "num": 43
              }, 
              {
                "text": "He gave them the lands of the Gentiles, And they inherited the labor of the nations,", 
                "num": 44
              }, 
              {
                "text": "That they might observe His statutes And keep His laws. Praise the LORD!", 
                "num": 45
              }
            ], 
            "num": 105
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 1
              }, 
              {
                "text": "Who can utter the mighty acts of the LORD? Who can declare all His praise?", 
                "num": 2
              }, 
              {
                "text": "Blessed are those who keep justice, And he who does righteousness at all times!", 
                "num": 3
              }, 
              {
                "text": "Remember me, O LORD, with the favor You have toward Your people. Oh, visit me with Your salvation,", 
                "num": 4
              }, 
              {
                "text": "That I may see the benefit of Your chosen ones, That I may rejoice in the gladness of Your nation, That I may glory with Your inheritance.", 
                "num": 5
              }, 
              {
                "text": "We have sinned with our fathers, We have committed iniquity, We have done wickedly.", 
                "num": 6
              }, 
              {
                "text": "Our fathers in Egypt did not understand Your wonders; They did not remember the multitude of Your mercies, But rebelled by the sea--the Red Sea.", 
                "num": 7
              }, 
              {
                "text": "Nevertheless He saved them for His name's sake, That He might make His mighty power known.", 
                "num": 8
              }, 
              {
                "text": "He rebuked the Red Sea also, and it dried up; So He led them through the depths, As through the wilderness.", 
                "num": 9
              }, 
              {
                "text": "He saved them from the hand of him who hated them, And redeemed them from the hand of the enemy.", 
                "num": 10
              }, 
              {
                "text": "The waters covered their enemies; There was not one of them left.", 
                "num": 11
              }, 
              {
                "text": "Then they believed His words; They sang His praise.", 
                "num": 12
              }, 
              {
                "text": "They soon forgot His works; They did not wait for His counsel,", 
                "num": 13
              }, 
              {
                "text": "But lusted exceedingly in the wilderness, And tested God in the desert.", 
                "num": 14
              }, 
              {
                "text": "And He gave them their request, But sent leanness into their soul.", 
                "num": 15
              }, 
              {
                "text": "When they envied Moses in the camp, And Aaron the saint of the LORD,", 
                "num": 16
              }, 
              {
                "text": "The earth opened up and swallowed Dathan, And covered the faction of Abiram.", 
                "num": 17
              }, 
              {
                "text": "A fire was kindled in their company; The flame burned up the wicked.", 
                "num": 18
              }, 
              {
                "text": "They made a calf in Horeb, And worshiped the molded image.", 
                "num": 19
              }, 
              {
                "text": "Thus they changed their glory Into the image of an ox that eats grass.", 
                "num": 20
              }, 
              {
                "text": "They forgot God their Savior, Who had done great things in Egypt,", 
                "num": 21
              }, 
              {
                "text": "Wondrous works in the land of Ham, Awesome things by the Red Sea.", 
                "num": 22
              }, 
              {
                "text": "Therefore He said that He would destroy them, Had not Moses His chosen one stood before Him in the breach, To turn away His wrath, lest He destroy them.", 
                "num": 23
              }, 
              {
                "text": "Then they despised the pleasant land; They did not believe His word,", 
                "num": 24
              }, 
              {
                "text": "But complained in their tents, And did not heed the voice of the LORD.", 
                "num": 25
              }, 
              {
                "text": "Therefore He raised His hand in an oath against them, To overthrow them in the wilderness,", 
                "num": 26
              }, 
              {
                "text": "To overthrow their descendants among the nations, And to scatter them in the lands.", 
                "num": 27
              }, 
              {
                "text": "They joined themselves also to Baal of Peor, And ate sacrifices made to the dead.", 
                "num": 28
              }, 
              {
                "text": "Thus they provoked Him to anger with their deeds, And the plague broke out among them.", 
                "num": 29
              }, 
              {
                "text": "Then Phinehas stood up and intervened, And the plague was stopped.", 
                "num": 30
              }, 
              {
                "text": "And that was accounted to him for righteousness To all generations forevermore.", 
                "num": 31
              }, 
              {
                "text": "They angered Him also at the waters of strife, So that it went ill with Moses on account of them;", 
                "num": 32
              }, 
              {
                "text": "Because they rebelled against His Spirit, So that he spoke rashly with his lips.", 
                "num": 33
              }, 
              {
                "text": "They did not destroy the peoples, Concerning whom the LORD had commanded them,", 
                "num": 34
              }, 
              {
                "text": "But they mingled with the Gentiles And learned their works;", 
                "num": 35
              }, 
              {
                "text": "They served their idols, Which became a snare to them.", 
                "num": 36
              }, 
              {
                "text": "They even sacrificed their sons And their daughters to demons,", 
                "num": 37
              }, 
              {
                "text": "And shed innocent blood, The blood of their sons and daughters, Whom they sacrificed to the idols of Canaan; And the land was polluted with blood.", 
                "num": 38
              }, 
              {
                "text": "Thus they were defiled by their own works, And played the harlot by their own deeds.", 
                "num": 39
              }, 
              {
                "text": "Therefore the wrath of the LORD was kindled against His people, So that He abhorred His own inheritance.", 
                "num": 40
              }, 
              {
                "text": "And He gave them into the hand of the Gentiles, And those who hated them ruled over them.", 
                "num": 41
              }, 
              {
                "text": "Their enemies also oppressed them, And they were brought into subjection under their hand.", 
                "num": 42
              }, 
              {
                "text": "Many times He delivered them; But they rebelled in their counsel, And were brought low for their iniquity.", 
                "num": 43
              }, 
              {
                "text": "Nevertheless He regarded their affliction, When He heard their cry;", 
                "num": 44
              }, 
              {
                "text": "And for their sake He remembered His covenant, And relented according to the multitude of His mercies.", 
                "num": 45
              }, 
              {
                "text": "He also made them to be pitied By all those who carried them away captive.", 
                "num": 46
              }, 
              {
                "text": "Save us, O LORD our God, And gather us from among the Gentiles, To give thanks to Your holy name, To triumph in Your praise.", 
                "num": 47
              }, 
              {
                "text": "Blessed be the LORD God of Israel From everlasting to everlasting! And let all the people say, \"Amen!\" Praise the LORD!", 
                "num": 48
              }
            ], 
            "num": 106
          }, 
          {
            "verses": [
              {
                "text": "Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 1
              }, 
              {
                "text": "Let the redeemed of the LORD say so, Whom He has redeemed from the hand of the enemy,", 
                "num": 2
              }, 
              {
                "text": "And gathered out of the lands, From the east and from the west, From the north and from the south.", 
                "num": 3
              }, 
              {
                "text": "They wandered in the wilderness in a desolate way; They found no city to dwell in.", 
                "num": 4
              }, 
              {
                "text": "Hungry and thirsty, Their soul fainted in them.", 
                "num": 5
              }, 
              {
                "text": "Then they cried out to the LORD in their trouble, And He delivered them out of their distresses.", 
                "num": 6
              }, 
              {
                "text": "And He led them forth by the right way, That they might go to a city for a dwelling place.", 
                "num": 7
              }, 
              {
                "text": "Oh, that men would give thanks to the LORD for His goodness, And for His wonderful works to the children of men!", 
                "num": 8
              }, 
              {
                "text": "For He satisfies the longing soul, And fills the hungry soul with goodness.", 
                "num": 9
              }, 
              {
                "text": "Those who sat in darkness and in the shadow of death, Bound in affliction and irons--", 
                "num": 10
              }, 
              {
                "text": "Because they rebelled against the words of God, And despised the counsel of the Most High,", 
                "num": 11
              }, 
              {
                "text": "Therefore He brought down their heart with labor; They fell down, and there was none to help.", 
                "num": 12
              }, 
              {
                "text": "Then they cried out to the LORD in their trouble, And He saved them out of their distresses.", 
                "num": 13
              }, 
              {
                "text": "He brought them out of darkness and the shadow of death, And broke their chains in pieces.", 
                "num": 14
              }, 
              {
                "text": "Oh, that men would give thanks to the LORD for His goodness, And for His wonderful works to the children of men!", 
                "num": 15
              }, 
              {
                "text": "For He has broken the gates of bronze, And cut the bars of iron in two.", 
                "num": 16
              }, 
              {
                "text": "Fools, because of their transgression, And because of their iniquities, were afflicted.", 
                "num": 17
              }, 
              {
                "text": "Their soul abhorred all manner of food, And they drew near to the gates of death.", 
                "num": 18
              }, 
              {
                "text": "Then they cried out to the LORD in their trouble, And He saved them out of their distresses.", 
                "num": 19
              }, 
              {
                "text": "He sent His word and healed them, And delivered them from their destructions.", 
                "num": 20
              }, 
              {
                "text": "Oh, that men would give thanks to the LORD for His goodness, And for His wonderful works to the children of men!", 
                "num": 21
              }, 
              {
                "text": "Let them sacrifice the sacrifices of thanksgiving, And declare His works with rejoicing.", 
                "num": 22
              }, 
              {
                "text": "Those who go down to the sea in ships, Who do business on great waters,", 
                "num": 23
              }, 
              {
                "text": "They see the works of the LORD, And His wonders in the deep.", 
                "num": 24
              }, 
              {
                "text": "For He commands and raises the stormy wind, Which lifts up the waves of the sea.", 
                "num": 25
              }, 
              {
                "text": "They mount up to the heavens, They go down again to the depths; Their soul melts because of trouble.", 
                "num": 26
              }, 
              {
                "text": "They reel to and fro, and stagger like a drunken man, And are at their wits' end.", 
                "num": 27
              }, 
              {
                "text": "Then they cry out to the LORD in their trouble, And He brings them out of their distresses.", 
                "num": 28
              }, 
              {
                "text": "He calms the storm, So that its waves are still.", 
                "num": 29
              }, 
              {
                "text": "Then they are glad because they are quiet; So He guides them to their desired haven.", 
                "num": 30
              }, 
              {
                "text": "Oh, that men would give thanks to the LORD for His goodness, And for His wonderful works to the children of men!", 
                "num": 31
              }, 
              {
                "text": "Let them exalt Him also in the assembly of the people, And praise Him in the company of the elders.", 
                "num": 32
              }, 
              {
                "text": "He turns rivers into a wilderness, And the watersprings into dry ground;", 
                "num": 33
              }, 
              {
                "text": "A fruitful land into barrenness, For the wickedness of those who dwell in it.", 
                "num": 34
              }, 
              {
                "text": "He turns a wilderness into pools of water, And dry land into watersprings.", 
                "num": 35
              }, 
              {
                "text": "There He makes the hungry dwell, That they may establish a city for a dwelling place,", 
                "num": 36
              }, 
              {
                "text": "And sow fields and plant vineyards, That they may yield a fruitful harvest.", 
                "num": 37
              }, 
              {
                "text": "He also blesses them, and they multiply greatly; And He does not let their cattle decrease.", 
                "num": 38
              }, 
              {
                "text": "When they are diminished and brought low Through oppression, affliction and sorrow,", 
                "num": 39
              }, 
              {
                "text": "He pours contempt on princes, And causes them to wander in the wilderness where there is no way;", 
                "num": 40
              }, 
              {
                "text": "Yet He sets the poor on high, far from affliction, And makes their families like a flock.", 
                "num": 41
              }, 
              {
                "text": "The righteous see it and rejoice, And all iniquity stops its mouth.", 
                "num": 42
              }, 
              {
                "text": "Whoever is wise will observe these things, And they will understand the lovingkindness of the LORD.", 
                "num": 43
              }
            ], 
            "num": 107
          }, 
          {
            "verses": [
              {
                "text": "O God, my heart is steadfast; I will sing and give praise, even with my glory.", 
                "num": 1
              }, 
              {
                "text": "Awake, lute and harp! I will awaken the dawn.", 
                "num": 2
              }, 
              {
                "text": "I will praise You, O LORD, among the peoples, And I will sing praises to You among the nations.", 
                "num": 3
              }, 
              {
                "text": "For Your mercy is great above the heavens, And Your truth reaches to the clouds.", 
                "num": 4
              }, 
              {
                "text": "Be exalted, O God, above the heavens, And Your glory above all the earth;", 
                "num": 5
              }, 
              {
                "text": "That Your beloved may be delivered, Save with Your right hand, and hear me.", 
                "num": 6
              }, 
              {
                "text": "God has spoken in His holiness: \"I will rejoice; I will divide Shechem And measure out the Valley of Succoth.", 
                "num": 7
              }, 
              {
                "text": "Gilead is Mine; Manasseh is Mine; Ephraim also is the helmet for My head; Judah is My lawgiver.", 
                "num": 8
              }, 
              {
                "text": "Moab is My washpot; Over Edom I will cast My shoe; Over Philistia I will triumph.\"", 
                "num": 9
              }, 
              {
                "text": "Who will bring me into the strong city? Who will lead me to Edom?", 
                "num": 10
              }, 
              {
                "text": "Is it not You, O God, who cast us off? And You, O God, who did not go out with our armies?", 
                "num": 11
              }, 
              {
                "text": "Give us help from trouble, For the help of man is useless.", 
                "num": 12
              }, 
              {
                "text": "Through God we will do valiantly, For it is He who shall tread down our enemies.", 
                "num": 13
              }
            ], 
            "num": 108
          }, 
          {
            "verses": [
              {
                "text": "Do not keep silent, O God of my praise!", 
                "num": 1
              }, 
              {
                "text": "For the mouth of the wicked and the mouth of the deceitful Have opened against me; They have spoken against me with a lying tongue.", 
                "num": 2
              }, 
              {
                "text": "They have also surrounded me with words of hatred, And fought against me without a cause.", 
                "num": 3
              }, 
              {
                "text": "In return for my love they are my accusers, But I give myself to prayer.", 
                "num": 4
              }, 
              {
                "text": "Thus they have rewarded me evil for good, And hatred for my love.", 
                "num": 5
              }, 
              {
                "text": "Set a wicked man over him, And let an accuser stand at his right hand.", 
                "num": 6
              }, 
              {
                "text": "When he is judged, let him be found guilty, And let his prayer become sin.", 
                "num": 7
              }, 
              {
                "text": "Let his days be few, And let another take his office.", 
                "num": 8
              }, 
              {
                "text": "Let his children be fatherless, And his wife a widow.", 
                "num": 9
              }, 
              {
                "text": "Let his children continually be vagabonds, and beg; Let them seek their bread also from their desolate places.", 
                "num": 10
              }, 
              {
                "text": "Let the creditor seize all that he has, And let strangers plunder his labor.", 
                "num": 11
              }, 
              {
                "text": "Let there be none to extend mercy to him, Nor let there be any to favor his fatherless children.", 
                "num": 12
              }, 
              {
                "text": "Let his posterity be cut off, And in the generation following let their name be blotted out.", 
                "num": 13
              }, 
              {
                "text": "Let the iniquity of his fathers be remembered before the LORD, And let not the sin of his mother be blotted out.", 
                "num": 14
              }, 
              {
                "text": "Let them be continually before the LORD, That He may cut off the memory of them from the earth;", 
                "num": 15
              }, 
              {
                "text": "Because he did not remember to show mercy, But persecuted the poor and needy man, That he might even slay the broken in heart.", 
                "num": 16
              }, 
              {
                "text": "As he loved cursing, so let it come to him; As he did not delight in blessing, so let it be far from him.", 
                "num": 17
              }, 
              {
                "text": "As he clothed himself with cursing as with his garment, So let it enter his body like water, And like oil into his bones.", 
                "num": 18
              }, 
              {
                "text": "Let it be to him like the garment which covers him, And for a belt with which he girds himself continually.", 
                "num": 19
              }, 
              {
                "text": "Let this be the LORD's reward to my accusers, And to those who speak evil against my person.", 
                "num": 20
              }, 
              {
                "text": "But You, O GOD the Lord, Deal with me for Your name's sake; Because Your mercy is good, deliver me.", 
                "num": 21
              }, 
              {
                "text": "For I am poor and needy, And my heart is wounded within me.", 
                "num": 22
              }, 
              {
                "text": "I am gone like a shadow when it lengthens; I am shaken off like a locust.", 
                "num": 23
              }, 
              {
                "text": "My knees are weak through fasting, And my flesh is feeble from lack of fatness.", 
                "num": 24
              }, 
              {
                "text": "I also have become a reproach to them; When they look at me, they shake their heads.", 
                "num": 25
              }, 
              {
                "text": "Help me, O LORD my God! Oh, save me according to Your mercy,", 
                "num": 26
              }, 
              {
                "text": "That they may know that this is Your hand-- That You, LORD, have done it!", 
                "num": 27
              }, 
              {
                "text": "Let them curse, but You bless; When they arise, let them be ashamed, But let Your servant rejoice.", 
                "num": 28
              }, 
              {
                "text": "Let my accusers be clothed with shame, And let them cover themselves with their own disgrace as with a mantle.", 
                "num": 29
              }, 
              {
                "text": "I will greatly praise the LORD with my mouth; Yes, I will praise Him among the multitude.", 
                "num": 30
              }, 
              {
                "text": "For He shall stand at the right hand of the poor, To save him from those who condemn him.", 
                "num": 31
              }
            ], 
            "num": 109
          }, 
          {
            "verses": [
              {
                "text": "The LORD said to my Lord, \"Sit at My right hand, Till I make Your enemies Your footstool.\"", 
                "num": 1
              }, 
              {
                "text": "The LORD shall send the rod of Your strength out of Zion. Rule in the midst of Your enemies!", 
                "num": 2
              }, 
              {
                "text": "Your people shall be volunteers In the day of Your power; In the beauties of holiness, from the womb of the morning, You have the dew of Your youth.", 
                "num": 3
              }, 
              {
                "text": "The LORD has sworn And will not relent, \"You are a priest forever According to the order of Melchizedek.\"", 
                "num": 4
              }, 
              {
                "text": "The Lord is at Your right hand; He shall execute kings in the day of His wrath.", 
                "num": 5
              }, 
              {
                "text": "He shall judge among the nations, He shall fill the places with dead bodies, He shall execute the heads of many countries.", 
                "num": 6
              }, 
              {
                "text": "He shall drink of the brook by the wayside; Therefore He shall lift up the head.", 
                "num": 7
              }
            ], 
            "num": 110
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! I will praise the LORD with my whole heart, In the assembly of the upright and in the congregation.", 
                "num": 1
              }, 
              {
                "text": "The works of the LORD are great, Studied by all who have pleasure in them.", 
                "num": 2
              }, 
              {
                "text": "His work is honorable and glorious, And His righteousness endures forever.", 
                "num": 3
              }, 
              {
                "text": "He has made His wonderful works to be remembered; The LORD is gracious and full of compassion.", 
                "num": 4
              }, 
              {
                "text": "He has given food to those who fear Him; He will ever be mindful of His covenant.", 
                "num": 5
              }, 
              {
                "text": "He has declared to His people the power of His works, In giving them the heritage of the nations.", 
                "num": 6
              }, 
              {
                "text": "The works of His hands are verity and justice; All His precepts are sure.", 
                "num": 7
              }, 
              {
                "text": "They stand fast forever and ever, And are done in truth and uprightness.", 
                "num": 8
              }, 
              {
                "text": "He has sent redemption to His people; He has commanded His covenant forever: Holy and awesome is His name.", 
                "num": 9
              }, 
              {
                "text": "The fear of the LORD is the beginning of wisdom; A good understanding have all those who do His commandments. His praise endures forever.", 
                "num": 10
              }
            ], 
            "num": 111
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Blessed is the man who fears the LORD, Who delights greatly in His commandments.", 
                "num": 1
              }, 
              {
                "text": "His descendants will be mighty on earth; The generation of the upright will be blessed.", 
                "num": 2
              }, 
              {
                "text": "Wealth and riches will be in his house, And his righteousness endures forever.", 
                "num": 3
              }, 
              {
                "text": "Unto the upright there arises light in the darkness; He is gracious, and full of compassion, and righteous.", 
                "num": 4
              }, 
              {
                "text": "A good man deals graciously and lends; He will guide his affairs with discretion.", 
                "num": 5
              }, 
              {
                "text": "Surely he will never be shaken; The righteous will be in everlasting remembrance.", 
                "num": 6
              }, 
              {
                "text": "He will not be afraid of evil tidings; His heart is steadfast, trusting in the LORD.", 
                "num": 7
              }, 
              {
                "text": "His heart is established; He will not be afraid, Until he sees his desire upon his enemies.", 
                "num": 8
              }, 
              {
                "text": "He has dispersed abroad, He has given to the poor; His righteousness endures forever; His horn will be exalted with honor.", 
                "num": 9
              }, 
              {
                "text": "The wicked will see it and be grieved; He will gnash his teeth and melt away; The desire of the wicked shall perish.", 
                "num": 10
              }
            ], 
            "num": 112
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Praise, O servants of the LORD, Praise the name of the LORD!", 
                "num": 1
              }, 
              {
                "text": "Blessed be the name of the LORD From this time forth and forevermore!", 
                "num": 2
              }, 
              {
                "text": "From the rising of the sun to its going down The LORD's name is to be praised.", 
                "num": 3
              }, 
              {
                "text": "The LORD is high above all nations, His glory above the heavens.", 
                "num": 4
              }, 
              {
                "text": "Who is like the LORD our God, Who dwells on high,", 
                "num": 5
              }, 
              {
                "text": "Who humbles Himself to behold The things that are in the heavens and in the earth?", 
                "num": 6
              }, 
              {
                "text": "He raises the poor out of the dust, And lifts the needy out of the ash heap,", 
                "num": 7
              }, 
              {
                "text": "That He may seat him with princes-- With the princes of His people.", 
                "num": 8
              }, 
              {
                "text": "He grants the barren woman a home, Like a joyful mother of children. Praise the LORD!", 
                "num": 9
              }
            ], 
            "num": 113
          }, 
          {
            "verses": [
              {
                "text": "When Israel went out of Egypt, The house of Jacob from a people of strange language,", 
                "num": 1
              }, 
              {
                "text": "Judah became His sanctuary, And Israel His dominion.", 
                "num": 2
              }, 
              {
                "text": "The sea saw it and fled; Jordan turned back.", 
                "num": 3
              }, 
              {
                "text": "The mountains skipped like rams, The little hills like lambs.", 
                "num": 4
              }, 
              {
                "text": "What ails you, O sea, that you fled? O Jordan, that you turned back?", 
                "num": 5
              }, 
              {
                "text": "O mountains, that you skipped like rams? O little hills, like lambs?", 
                "num": 6
              }, 
              {
                "text": "Tremble, O earth, at the presence of the Lord, At the presence of the God of Jacob,", 
                "num": 7
              }, 
              {
                "text": "Who turned the rock into a pool of water, The flint into a fountain of waters.", 
                "num": 8
              }
            ], 
            "num": 114
          }, 
          {
            "verses": [
              {
                "text": "Not unto us, O LORD, not unto us, But to Your name give glory, Because of Your mercy, Because of Your truth.", 
                "num": 1
              }, 
              {
                "text": "Why should the Gentiles say, \"So where is their God?\"", 
                "num": 2
              }, 
              {
                "text": "But our God is in heaven; He does whatever He pleases.", 
                "num": 3
              }, 
              {
                "text": "Their idols are silver and gold, The work of men's hands.", 
                "num": 4
              }, 
              {
                "text": "They have mouths, but they do not speak; Eyes they have, but they do not see;", 
                "num": 5
              }, 
              {
                "text": "They have ears, but they do not hear; Noses they have, but they do not smell;", 
                "num": 6
              }, 
              {
                "text": "They have hands, but they do not handle; Feet they have, but they do not walk; Nor do they mutter through their throat.", 
                "num": 7
              }, 
              {
                "text": "Those who make them are like them; So is everyone who trusts in them.", 
                "num": 8
              }, 
              {
                "text": "O Israel, trust in the LORD; He is their help and their shield.", 
                "num": 9
              }, 
              {
                "text": "O house of Aaron, trust in the LORD; He is their help and their shield.", 
                "num": 10
              }, 
              {
                "text": "You who fear the LORD, trust in the LORD; He is their help and their shield.", 
                "num": 11
              }, 
              {
                "text": "The LORD has been mindful of us; He will bless us; He will bless the house of Israel; He will bless the house of Aaron.", 
                "num": 12
              }, 
              {
                "text": "He will bless those who fear the LORD, Both small and great.", 
                "num": 13
              }, 
              {
                "text": "May the LORD give you increase more and more, You and your children.", 
                "num": 14
              }, 
              {
                "text": "May you be blessed by the LORD, Who made heaven and earth.", 
                "num": 15
              }, 
              {
                "text": "The heaven, even the heavens, are the LORD's; But the earth He has given to the children of men.", 
                "num": 16
              }, 
              {
                "text": "The dead do not praise the LORD, Nor any who go down into silence.", 
                "num": 17
              }, 
              {
                "text": "But we will bless the LORD From this time forth and forevermore. Praise the LORD!", 
                "num": 18
              }
            ], 
            "num": 115
          }, 
          {
            "verses": [
              {
                "text": "I love the LORD, because He has heard My voice and my supplications.", 
                "num": 1
              }, 
              {
                "text": "Because He has inclined His ear to me, Therefore I will call upon Him as long as I live.", 
                "num": 2
              }, 
              {
                "text": "The pains of death surrounded me, And the pangs of Sheol laid hold of me; I found trouble and sorrow.", 
                "num": 3
              }, 
              {
                "text": "Then I called upon the name of the LORD: \"O LORD, I implore You, deliver my soul!\"", 
                "num": 4
              }, 
              {
                "text": "Gracious is the LORD, and righteous; Yes, our God is merciful.", 
                "num": 5
              }, 
              {
                "text": "The LORD preserves the simple; I was brought low, and He saved me.", 
                "num": 6
              }, 
              {
                "text": "Return to your rest, O my soul, For the LORD has dealt bountifully with you.", 
                "num": 7
              }, 
              {
                "text": "For You have delivered my soul from death, My eyes from tears, And my feet from falling.", 
                "num": 8
              }, 
              {
                "text": "I will walk before the LORD In the land of the living.", 
                "num": 9
              }, 
              {
                "text": "I believed, therefore I spoke, \"I am greatly afflicted.\"", 
                "num": 10
              }, 
              {
                "text": "I said in my haste, \"All men are liars.\"", 
                "num": 11
              }, 
              {
                "text": "What shall I render to the LORD For all His benefits toward me?", 
                "num": 12
              }, 
              {
                "text": "I will take up the cup of salvation, And call upon the name of the LORD.", 
                "num": 13
              }, 
              {
                "text": "I will pay my vows to the LORD Now in the presence of all His people.", 
                "num": 14
              }, 
              {
                "text": "Precious in the sight of the LORD Is the death of His saints.", 
                "num": 15
              }, 
              {
                "text": "O LORD, truly I am Your servant; I am Your servant, the son of Your maidservant; You have loosed my bonds.", 
                "num": 16
              }, 
              {
                "text": "I will offer to You the sacrifice of thanksgiving, And will call upon the name of the LORD.", 
                "num": 17
              }, 
              {
                "text": "I will pay my vows to the LORD Now in the presence of all His people,", 
                "num": 18
              }, 
              {
                "text": "In the courts of the LORD's house, In the midst of you, O Jerusalem. Praise the LORD!", 
                "num": 19
              }
            ], 
            "num": 116
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD, all you Gentiles! Laud Him, all you peoples!", 
                "num": 1
              }, 
              {
                "text": "For His merciful kindness is great toward us, And the truth of the LORD endures forever. Praise the LORD!", 
                "num": 2
              }
            ], 
            "num": 117
          }, 
          {
            "verses": [
              {
                "text": "Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 1
              }, 
              {
                "text": "Let Israel now say, \"His mercy endures forever.\"", 
                "num": 2
              }, 
              {
                "text": "Let the house of Aaron now say, \"His mercy endures forever.\"", 
                "num": 3
              }, 
              {
                "text": "Let those who fear the LORD now say, \"His mercy endures forever.\"", 
                "num": 4
              }, 
              {
                "text": "I called on the LORD in distress; The LORD answered me and set me in a broad place.", 
                "num": 5
              }, 
              {
                "text": "The LORD is on my side; I will not fear. What can man do to me?", 
                "num": 6
              }, 
              {
                "text": "The LORD is for me among those who help me; Therefore I shall see my desire on those who hate me.", 
                "num": 7
              }, 
              {
                "text": "It is better to trust in the LORD Than to put confidence in man.", 
                "num": 8
              }, 
              {
                "text": "It is better to trust in the LORD Than to put confidence in princes.", 
                "num": 9
              }, 
              {
                "text": "All nations surrounded me, But in the name of the LORD I will destroy them.", 
                "num": 10
              }, 
              {
                "text": "They surrounded me, Yes, they surrounded me; But in the name of the LORD I will destroy them.", 
                "num": 11
              }, 
              {
                "text": "They surrounded me like bees; They were quenched like a fire of thorns; For in the name of the LORD I will destroy them.", 
                "num": 12
              }, 
              {
                "text": "You pushed me violently, that I might fall, But the LORD helped me.", 
                "num": 13
              }, 
              {
                "text": "The LORD is my strength and song, And He has become my salvation.", 
                "num": 14
              }, 
              {
                "text": "The voice of rejoicing and salvation Is in the tents of the righteous; The right hand of the LORD does valiantly.", 
                "num": 15
              }, 
              {
                "text": "The right hand of the LORD is exalted; The right hand of the LORD does valiantly.", 
                "num": 16
              }, 
              {
                "text": "I shall not die, but live, And declare the works of the LORD.", 
                "num": 17
              }, 
              {
                "text": "The LORD has chastened me severely, But He has not given me over to death.", 
                "num": 18
              }, 
              {
                "text": "Open to me the gates of righteousness; I will go through them, And I will praise the LORD.", 
                "num": 19
              }, 
              {
                "text": "This is the gate of the LORD, Through which the righteous shall enter.", 
                "num": 20
              }, 
              {
                "text": "I will praise You, For You have answered me, And have become my salvation.", 
                "num": 21
              }, 
              {
                "text": "The stone which the builders rejected Has become the chief cornerstone.", 
                "num": 22
              }, 
              {
                "text": "This was the LORD's doing; It is marvelous in our eyes.", 
                "num": 23
              }, 
              {
                "text": "This is the day the LORD has made; We will rejoice and be glad in it.", 
                "num": 24
              }, 
              {
                "text": "Save now, I pray, O LORD; O LORD, I pray, send now prosperity.", 
                "num": 25
              }, 
              {
                "text": "Blessed is he who comes in the name of the LORD! We have blessed you from the house of the LORD.", 
                "num": 26
              }, 
              {
                "text": "God is the LORD, And He has given us light; Bind the sacrifice with cords to the horns of the altar.", 
                "num": 27
              }, 
              {
                "text": "You are my God, and I will praise You; You are my God, I will exalt You.", 
                "num": 28
              }, 
              {
                "text": "Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 29
              }
            ], 
            "num": 118
          }, 
          {
            "verses": [
              {
                "text": "Blessed are the undefiled in the way, Who walk in the law of the LORD!", 
                "num": 1
              }, 
              {
                "text": "Blessed are those who keep His testimonies, Who seek Him with the whole heart!", 
                "num": 2
              }, 
              {
                "text": "They also do no iniquity; They walk in His ways.", 
                "num": 3
              }, 
              {
                "text": "You have commanded us To keep Your precepts diligently.", 
                "num": 4
              }, 
              {
                "text": "Oh, that my ways were directed To keep Your statutes!", 
                "num": 5
              }, 
              {
                "text": "Then I would not be ashamed, When I look into all Your commandments.", 
                "num": 6
              }, 
              {
                "text": "I will praise You with uprightness of heart, When I learn Your righteous judgments.", 
                "num": 7
              }, 
              {
                "text": "I will keep Your statutes; Oh, do not forsake me utterly!", 
                "num": 8
              }, 
              {
                "text": "How can a young man cleanse his way? By taking heed according to Your word.", 
                "num": 9
              }, 
              {
                "text": "With my whole heart I have sought You; Oh, let me not wander from Your commandments!", 
                "num": 10
              }, 
              {
                "text": "Your word I have hidden in my heart, That I might not sin against You.", 
                "num": 11
              }, 
              {
                "text": "Blessed are You, O LORD! Teach me Your statutes.", 
                "num": 12
              }, 
              {
                "text": "With my lips I have declared All the judgments of Your mouth.", 
                "num": 13
              }, 
              {
                "text": "I have rejoiced in the way of Your testimonies, As much as in all riches.", 
                "num": 14
              }, 
              {
                "text": "I will meditate on Your precepts, And contemplate Your ways.", 
                "num": 15
              }, 
              {
                "text": "I will delight myself in Your statutes; I will not forget Your word.", 
                "num": 16
              }, 
              {
                "text": "Deal bountifully with Your servant, That I may live and keep Your word.", 
                "num": 17
              }, 
              {
                "text": "Open my eyes, that I may see Wondrous things from Your law.", 
                "num": 18
              }, 
              {
                "text": "I am a stranger in the earth; Do not hide Your commandments from me.", 
                "num": 19
              }, 
              {
                "text": "My soul breaks with longing For Your judgments at all times.", 
                "num": 20
              }, 
              {
                "text": "You rebuke the proud--the cursed, Who stray from Your commandments.", 
                "num": 21
              }, 
              {
                "text": "Remove from me reproach and contempt, For I have kept Your testimonies.", 
                "num": 22
              }, 
              {
                "text": "Princes also sit and speak against me, But Your servant meditates on Your statutes.", 
                "num": 23
              }, 
              {
                "text": "Your testimonies also are my delight And my counselors.", 
                "num": 24
              }, 
              {
                "text": "My soul clings to the dust; Revive me according to Your word.", 
                "num": 25
              }, 
              {
                "text": "I have declared my ways, and You answered me; Teach me Your statutes.", 
                "num": 26
              }, 
              {
                "text": "Make me understand the way of Your precepts; So shall I meditate on Your wonderful works.", 
                "num": 27
              }, 
              {
                "text": "My soul melts from heaviness; Strengthen me according to Your word.", 
                "num": 28
              }, 
              {
                "text": "Remove from me the way of lying, And grant me Your law graciously.", 
                "num": 29
              }, 
              {
                "text": "I have chosen the way of truth; Your judgments I have laid before me.", 
                "num": 30
              }, 
              {
                "text": "I cling to Your testimonies; O LORD, do not put me to shame!", 
                "num": 31
              }, 
              {
                "text": "I will run the course of Your commandments, For You shall enlarge my heart.", 
                "num": 32
              }, 
              {
                "text": "Teach me, O LORD, the way of Your statutes, And I shall keep it to the end.", 
                "num": 33
              }, 
              {
                "text": "Give me understanding, and I shall keep Your law; Indeed, I shall observe it with my whole heart.", 
                "num": 34
              }, 
              {
                "text": "Make me walk in the path of Your commandments, For I delight in it.", 
                "num": 35
              }, 
              {
                "text": "Incline my heart to Your testimonies, And not to covetousness.", 
                "num": 36
              }, 
              {
                "text": "Turn away my eyes from looking at worthless things, And revive me in Your way.", 
                "num": 37
              }, 
              {
                "text": "Establish Your word to Your servant, Who is devoted to fearing You.", 
                "num": 38
              }, 
              {
                "text": "Turn away my reproach which I dread, For Your judgments are good.", 
                "num": 39
              }, 
              {
                "text": "Behold, I long for Your precepts; Revive me in Your righteousness.", 
                "num": 40
              }, 
              {
                "text": "Let Your mercies come also to me, O LORD-- Your salvation according to Your word.", 
                "num": 41
              }, 
              {
                "text": "So shall I have an answer for him who reproaches me, For I trust in Your word.", 
                "num": 42
              }, 
              {
                "text": "And take not the word of truth utterly out of my mouth, For I have hoped in Your ordinances.", 
                "num": 43
              }, 
              {
                "text": "So shall I keep Your law continually, Forever and ever.", 
                "num": 44
              }, 
              {
                "text": "And I will walk at liberty, For I seek Your precepts.", 
                "num": 45
              }, 
              {
                "text": "I will speak of Your testimonies also before kings, And will not be ashamed.", 
                "num": 46
              }, 
              {
                "text": "And I will delight myself in Your commandments, Which I love.", 
                "num": 47
              }, 
              {
                "text": "My hands also I will lift up to Your commandments, Which I love, And I will meditate on Your statutes.", 
                "num": 48
              }, 
              {
                "text": "Remember the word to Your servant, Upon which You have caused me to hope.", 
                "num": 49
              }, 
              {
                "text": "This is my comfort in my affliction, For Your word has given me life.", 
                "num": 50
              }, 
              {
                "text": "The proud have me in great derision, Yet I do not turn aside from Your law.", 
                "num": 51
              }, 
              {
                "text": "I remembered Your judgments of old, O LORD, And have comforted myself.", 
                "num": 52
              }, 
              {
                "text": "Indignation has taken hold of me Because of the wicked, who forsake Your law.", 
                "num": 53
              }, 
              {
                "text": "Your statutes have been my songs In the house of my pilgrimage.", 
                "num": 54
              }, 
              {
                "text": "I remember Your name in the night, O LORD, And I keep Your law.", 
                "num": 55
              }, 
              {
                "text": "This has become mine, Because I kept Your precepts.", 
                "num": 56
              }, 
              {
                "text": "You are my portion, O LORD; I have said that I would keep Your words.", 
                "num": 57
              }, 
              {
                "text": "I entreated Your favor with my whole heart; Be merciful to me according to Your word.", 
                "num": 58
              }, 
              {
                "text": "I thought about my ways, And turned my feet to Your testimonies.", 
                "num": 59
              }, 
              {
                "text": "I made haste, and did not delay To keep Your commandments.", 
                "num": 60
              }, 
              {
                "text": "The cords of the wicked have bound me, But I have not forgotten Your law.", 
                "num": 61
              }, 
              {
                "text": "At midnight I will rise to give thanks to You, Because of Your righteous judgments.", 
                "num": 62
              }, 
              {
                "text": "I am a companion of all who fear You, And of those who keep Your precepts.", 
                "num": 63
              }, 
              {
                "text": "The earth, O LORD, is full of Your mercy; Teach me Your statutes.", 
                "num": 64
              }, 
              {
                "text": "You have dealt well with Your servant, O LORD, according to Your word.", 
                "num": 65
              }, 
              {
                "text": "Teach me good judgment and knowledge, For I believe Your commandments.", 
                "num": 66
              }, 
              {
                "text": "Before I was afflicted I went astray, But now I keep Your word.", 
                "num": 67
              }, 
              {
                "text": "You are good, and do good; Teach me Your statutes.", 
                "num": 68
              }, 
              {
                "text": "The proud have forged a lie against me, But I will keep Your precepts with my whole heart.", 
                "num": 69
              }, 
              {
                "text": "Their heart is as fat as grease, But I delight in Your law.", 
                "num": 70
              }, 
              {
                "text": "It is good for me that I have been afflicted, That I may learn Your statutes.", 
                "num": 71
              }, 
              {
                "text": "The law of Your mouth is better to me Than thousands of coins of gold and silver.", 
                "num": 72
              }, 
              {
                "text": "Your hands have made me and fashioned me; Give me understanding, that I may learn Your commandments.", 
                "num": 73
              }, 
              {
                "text": "Those who fear You will be glad when they see me, Because I have hoped in Your word.", 
                "num": 74
              }, 
              {
                "text": "I know, O LORD, that Your judgments are right, And that in faithfulness You have afflicted me.", 
                "num": 75
              }, 
              {
                "text": "Let, I pray, Your merciful kindness be for my comfort, According to Your word to Your servant.", 
                "num": 76
              }, 
              {
                "text": "Let Your tender mercies come to me, that I may live; For Your law is my delight.", 
                "num": 77
              }, 
              {
                "text": "Let the proud be ashamed, For they treated me wrongfully with falsehood; But I will meditate on Your precepts.", 
                "num": 78
              }, 
              {
                "text": "Let those who fear You turn to me, Those who know Your testimonies.", 
                "num": 79
              }, 
              {
                "text": "Let my heart be blameless regarding Your statutes, That I may not be ashamed.", 
                "num": 80
              }, 
              {
                "text": "My soul faints for Your salvation, But I hope in Your word.", 
                "num": 81
              }, 
              {
                "text": "My eyes fail from searching Your word, Saying, \"When will You comfort me?\"", 
                "num": 82
              }, 
              {
                "text": "For I have become like a wineskin in smoke, Yet I do not forget Your statutes.", 
                "num": 83
              }, 
              {
                "text": "How many are the days of Your servant? When will You execute judgment on those who persecute me?", 
                "num": 84
              }, 
              {
                "text": "The proud have dug pits for me, Which is not according to Your law.", 
                "num": 85
              }, 
              {
                "text": "All Your commandments are faithful; They persecute me wrongfully; Help me!", 
                "num": 86
              }, 
              {
                "text": "They almost made an end of me on earth, But I did not forsake Your precepts.", 
                "num": 87
              }, 
              {
                "text": "Revive me according to Your lovingkindness, So that I may keep the testimony of Your mouth.", 
                "num": 88
              }, 
              {
                "text": "Forever, O LORD, Your word is settled in heaven.", 
                "num": 89
              }, 
              {
                "text": "Your faithfulness endures to all generations; You established the earth, and it abides.", 
                "num": 90
              }, 
              {
                "text": "They continue this day according to Your ordinances, For all are Your servants.", 
                "num": 91
              }, 
              {
                "text": "Unless Your law had been my delight, I would then have perished in my affliction.", 
                "num": 92
              }, 
              {
                "text": "I will never forget Your precepts, For by them You have given me life.", 
                "num": 93
              }, 
              {
                "text": "I am Yours, save me; For I have sought Your precepts.", 
                "num": 94
              }, 
              {
                "text": "The wicked wait for me to destroy me, But I will consider Your testimonies.", 
                "num": 95
              }, 
              {
                "text": "I have seen the consummation of all perfection, But Your commandment is exceedingly broad.", 
                "num": 96
              }, 
              {
                "text": "Oh, how I love Your law! It is my meditation all the day.", 
                "num": 97
              }, 
              {
                "text": "You, through Your commandments, make me wiser than my enemies; For they are ever with me.", 
                "num": 98
              }, 
              {
                "text": "I have more understanding than all my teachers, For Your testimonies are my meditation.", 
                "num": 99
              }, 
              {
                "text": "I understand more than the ancients, Because I keep Your precepts.", 
                "num": 100
              }, 
              {
                "text": "I have restrained my feet from every evil way, That I may keep Your word.", 
                "num": 101
              }, 
              {
                "text": "I have not departed from Your judgments, For You Yourself have taught me.", 
                "num": 102
              }, 
              {
                "text": "How sweet are Your words to my taste, Sweeter than honey to my mouth!", 
                "num": 103
              }, 
              {
                "text": "Through Your precepts I get understanding; Therefore I hate every false way.", 
                "num": 104
              }, 
              {
                "text": "Your word is a lamp to my feet And a light to my path.", 
                "num": 105
              }, 
              {
                "text": "I have sworn and confirmed That I will keep Your righteous judgments.", 
                "num": 106
              }, 
              {
                "text": "I am afflicted very much; Revive me, O LORD, according to Your word.", 
                "num": 107
              }, 
              {
                "text": "Accept, I pray, the freewill offerings of my mouth, O LORD, And teach me Your judgments.", 
                "num": 108
              }, 
              {
                "text": "My life is continually in my hand, Yet I do not forget Your law.", 
                "num": 109
              }, 
              {
                "text": "The wicked have laid a snare for me, Yet I have not strayed from Your precepts.", 
                "num": 110
              }, 
              {
                "text": "Your testimonies I have taken as a heritage forever, For they are the rejoicing of my heart.", 
                "num": 111
              }, 
              {
                "text": "I have inclined my heart to perform Your statutes Forever, to the very end.", 
                "num": 112
              }, 
              {
                "text": "I hate the double-minded, But I love Your law.", 
                "num": 113
              }, 
              {
                "text": "You are my hiding place and my shield; I hope in Your word.", 
                "num": 114
              }, 
              {
                "text": "Depart from me, you evildoers, For I will keep the commandments of my God!", 
                "num": 115
              }, 
              {
                "text": "Uphold me according to Your word, that I may live; And do not let me be ashamed of my hope.", 
                "num": 116
              }, 
              {
                "text": "Hold me up, and I shall be safe, And I shall observe Your statutes continually.", 
                "num": 117
              }, 
              {
                "text": "You reject all those who stray from Your statutes, For their deceit is falsehood.", 
                "num": 118
              }, 
              {
                "text": "You put away all the wicked of the earth like dross; Therefore I love Your testimonies.", 
                "num": 119
              }, 
              {
                "text": "My flesh trembles for fear of You, And I am afraid of Your judgments.", 
                "num": 120
              }, 
              {
                "text": "I have done justice and righteousness; Do not leave me to my oppressors.", 
                "num": 121
              }, 
              {
                "text": "Be surety for Your servant for good; Do not let the proud oppress me.", 
                "num": 122
              }, 
              {
                "text": "My eyes fail from seeking Your salvation And Your righteous word.", 
                "num": 123
              }, 
              {
                "text": "Deal with Your servant according to Your mercy, And teach me Your statutes.", 
                "num": 124
              }, 
              {
                "text": "I am Your servant; Give me understanding, That I may know Your testimonies.", 
                "num": 125
              }, 
              {
                "text": "It is time for You to act, O LORD, For they have regarded Your law as void.", 
                "num": 126
              }, 
              {
                "text": "Therefore I love Your commandments More than gold, yes, than fine gold!", 
                "num": 127
              }, 
              {
                "text": "Therefore all Your precepts concerning all things I consider to be right; I hate every false way.", 
                "num": 128
              }, 
              {
                "text": "Your testimonies are wonderful; Therefore my soul keeps them.", 
                "num": 129
              }, 
              {
                "text": "The entrance of Your words gives light; It gives understanding to the simple.", 
                "num": 130
              }, 
              {
                "text": "I opened my mouth and panted, For I longed for Your commandments.", 
                "num": 131
              }, 
              {
                "text": "Look upon me and be merciful to me, As Your custom is toward those who love Your name.", 
                "num": 132
              }, 
              {
                "text": "Direct my steps by Your word, And let no iniquity have dominion over me.", 
                "num": 133
              }, 
              {
                "text": "Redeem me from the oppression of man, That I may keep Your precepts.", 
                "num": 134
              }, 
              {
                "text": "Make Your face shine upon Your servant, And teach me Your statutes.", 
                "num": 135
              }, 
              {
                "text": "Rivers of water run down from my eyes, Because men do not keep Your law.", 
                "num": 136
              }, 
              {
                "text": "Righteous are You, O LORD, And upright are Your judgments.", 
                "num": 137
              }, 
              {
                "text": "Your testimonies, which You have commanded, Are righteous and very faithful.", 
                "num": 138
              }, 
              {
                "text": "My zeal has consumed me, Because my enemies have forgotten Your words.", 
                "num": 139
              }, 
              {
                "text": "Your word is very pure; Therefore Your servant loves it.", 
                "num": 140
              }, 
              {
                "text": "I am small and despised, Yet I do not forget Your precepts.", 
                "num": 141
              }, 
              {
                "text": "Your righteousness is an everlasting righteousness, And Your law is truth.", 
                "num": 142
              }, 
              {
                "text": "Trouble and anguish have overtaken me, Yet Your commandments are my delights.", 
                "num": 143
              }, 
              {
                "text": "The righteousness of Your testimonies is everlasting; Give me understanding, and I shall live.", 
                "num": 144
              }, 
              {
                "text": "I cry out with my whole heart; Hear me, O LORD! I will keep Your statutes.", 
                "num": 145
              }, 
              {
                "text": "I cry out to You; Save me, and I will keep Your testimonies.", 
                "num": 146
              }, 
              {
                "text": "I rise before the dawning of the morning, And cry for help; I hope in Your word.", 
                "num": 147
              }, 
              {
                "text": "My eyes are awake through the night watches, That I may meditate on Your word.", 
                "num": 148
              }, 
              {
                "text": "Hear my voice according to Your lovingkindness; O LORD, revive me according to Your justice.", 
                "num": 149
              }, 
              {
                "text": "They draw near who follow after wickedness; They are far from Your law.", 
                "num": 150
              }, 
              {
                "text": "You are near, O LORD, And all Your commandments are truth.", 
                "num": 151
              }, 
              {
                "text": "Concerning Your testimonies, I have known of old that You have founded them forever.", 
                "num": 152
              }, 
              {
                "text": "Consider my affliction and deliver me, For I do not forget Your law.", 
                "num": 153
              }, 
              {
                "text": "Plead my cause and redeem me; Revive me according to Your word.", 
                "num": 154
              }, 
              {
                "text": "Salvation is far from the wicked, For they do not seek Your statutes.", 
                "num": 155
              }, 
              {
                "text": "Great are Your tender mercies, O LORD; Revive me according to Your judgments.", 
                "num": 156
              }, 
              {
                "text": "Many are my persecutors and my enemies, Yet I do not turn from Your testimonies.", 
                "num": 157
              }, 
              {
                "text": "I see the treacherous, and am disgusted, Because they do not keep Your word.", 
                "num": 158
              }, 
              {
                "text": "Consider how I love Your precepts; Revive me, O LORD, according to Your lovingkindness.", 
                "num": 159
              }, 
              {
                "text": "The entirety of Your word is truth, And every one of Your righteous judgments endures forever.", 
                "num": 160
              }, 
              {
                "text": "Princes persecute me without a cause, But my heart stands in awe of Your word.", 
                "num": 161
              }, 
              {
                "text": "I rejoice at Your word As one who finds great treasure.", 
                "num": 162
              }, 
              {
                "text": "I hate and abhor lying, But I love Your law.", 
                "num": 163
              }, 
              {
                "text": "Seven times a day I praise You, Because of Your righteous judgments.", 
                "num": 164
              }, 
              {
                "text": "Great peace have those who love Your law, And nothing causes them to stumble.", 
                "num": 165
              }, 
              {
                "text": "LORD, I hope for Your salvation, And I do Your commandments.", 
                "num": 166
              }, 
              {
                "text": "My soul keeps Your testimonies, And I love them exceedingly.", 
                "num": 167
              }, 
              {
                "text": "I keep Your precepts and Your testimonies, For all my ways are before You.", 
                "num": 168
              }, 
              {
                "text": "Let my cry come before You, O LORD; Give me understanding according to Your word.", 
                "num": 169
              }, 
              {
                "text": "Let my supplication come before You; Deliver me according to Your word.", 
                "num": 170
              }, 
              {
                "text": "My lips shall utter praise, For You teach me Your statutes.", 
                "num": 171
              }, 
              {
                "text": "My tongue shall speak of Your word, For all Your commandments are righteousness.", 
                "num": 172
              }, 
              {
                "text": "Let Your hand become my help, For I have chosen Your precepts.", 
                "num": 173
              }, 
              {
                "text": "I long for Your salvation, O LORD, And Your law is my delight.", 
                "num": 174
              }, 
              {
                "text": "Let my soul live, and it shall praise You; And let Your judgments help me.", 
                "num": 175
              }, 
              {
                "text": "I have gone astray like a lost sheep; Seek Your servant, For I do not forget Your commandments.", 
                "num": 176
              }
            ], 
            "num": 119
          }, 
          {
            "verses": [
              {
                "text": "In my distress I cried to the LORD, And He heard me.", 
                "num": 1
              }, 
              {
                "text": "Deliver my soul, O LORD, from lying lips And from a deceitful tongue.", 
                "num": 2
              }, 
              {
                "text": "What shall be given to you, Or what shall be done to you, You false tongue?", 
                "num": 3
              }, 
              {
                "text": "Sharp arrows of the warrior, With coals of the broom tree!", 
                "num": 4
              }, 
              {
                "text": "Woe is me, that I dwell in Meshech, That I dwell among the tents of Kedar!", 
                "num": 5
              }, 
              {
                "text": "My soul has dwelt too long With one who hates peace.", 
                "num": 6
              }, 
              {
                "text": "I am for peace; But when I speak, they are for war.", 
                "num": 7
              }
            ], 
            "num": 120
          }, 
          {
            "verses": [
              {
                "text": "I will lift up my eyes to the hills-- From whence comes my help?", 
                "num": 1
              }, 
              {
                "text": "My help comes from the LORD, Who made heaven and earth.", 
                "num": 2
              }, 
              {
                "text": "He will not allow your foot to be moved; He who keeps you will not slumber.", 
                "num": 3
              }, 
              {
                "text": "Behold, He who keeps Israel Shall neither slumber nor sleep.", 
                "num": 4
              }, 
              {
                "text": "The LORD is your keeper; The LORD is your shade at your right hand.", 
                "num": 5
              }, 
              {
                "text": "The sun shall not strike you by day, Nor the moon by night.", 
                "num": 6
              }, 
              {
                "text": "The LORD shall preserve you from all evil; He shall preserve your soul.", 
                "num": 7
              }, 
              {
                "text": "The LORD shall preserve your going out and your coming in From this time forth, and even forevermore.", 
                "num": 8
              }
            ], 
            "num": 121
          }, 
          {
            "verses": [
              {
                "text": "I was glad when they said to me, \"Let us go into the house of the LORD.\"", 
                "num": 1
              }, 
              {
                "text": "Our feet have been standing Within your gates, O Jerusalem!", 
                "num": 2
              }, 
              {
                "text": "Jerusalem is built As a city that is compact together,", 
                "num": 3
              }, 
              {
                "text": "Where the tribes go up, The tribes of the LORD, To the Testimony of Israel, To give thanks to the name of the LORD.", 
                "num": 4
              }, 
              {
                "text": "For thrones are set there for judgment, The thrones of the house of David.", 
                "num": 5
              }, 
              {
                "text": "Pray for the peace of Jerusalem: \"May they prosper who love you.", 
                "num": 6
              }, 
              {
                "text": "Peace be within your walls, Prosperity within your palaces.\"", 
                "num": 7
              }, 
              {
                "text": "For the sake of my brethren and companions, I will now say, \"Peace be within you.\"", 
                "num": 8
              }, 
              {
                "text": "Because of the house of the LORD our God I will seek your good.", 
                "num": 9
              }
            ], 
            "num": 122
          }, 
          {
            "verses": [
              {
                "text": "Unto You I lift up my eyes, O You who dwell in the heavens.", 
                "num": 1
              }, 
              {
                "text": "Behold, as the eyes of servants look to the hand of their masters, As the eyes of a maid to the hand of her mistress, So our eyes look to the LORD our God, Until He has mercy on us.", 
                "num": 2
              }, 
              {
                "text": "Have mercy on us, O LORD, have mercy on us! For we are exceedingly filled with contempt.", 
                "num": 3
              }, 
              {
                "text": "Our soul is exceedingly filled With the scorn of those who are at ease, With the contempt of the proud.", 
                "num": 4
              }
            ], 
            "num": 123
          }, 
          {
            "verses": [
              {
                "text": "\"If it had not been the LORD who was on our side,\" Let Israel now say--", 
                "num": 1
              }, 
              {
                "text": "\"If it had not been the LORD who was on our side, When men rose up against us,", 
                "num": 2
              }, 
              {
                "text": "Then they would have swallowed us alive, When their wrath was kindled against us;", 
                "num": 3
              }, 
              {
                "text": "Then the waters would have overwhelmed us, The stream would have gone over our soul;", 
                "num": 4
              }, 
              {
                "text": "Then the swollen waters Would have gone over our soul.\"", 
                "num": 5
              }, 
              {
                "text": "Blessed be the LORD, Who has not given us as prey to their teeth.", 
                "num": 6
              }, 
              {
                "text": "Our soul has escaped as a bird from the snare of the fowlers; The snare is broken, and we have escaped.", 
                "num": 7
              }, 
              {
                "text": "Our help is in the name of the LORD, Who made heaven and earth.", 
                "num": 8
              }
            ], 
            "num": 124
          }, 
          {
            "verses": [
              {
                "text": "Those who trust in the LORD Are like Mount Zion, Which cannot be moved, but abides forever.", 
                "num": 1
              }, 
              {
                "text": "As the mountains surround Jerusalem, So the LORD surrounds His people From this time forth and forever.", 
                "num": 2
              }, 
              {
                "text": "For the scepter of wickedness shall not rest On the land allotted to the righteous, Lest the righteous reach out their hands to iniquity.", 
                "num": 3
              }, 
              {
                "text": "Do good, O LORD, to those who are good, And to those who are upright in their hearts.", 
                "num": 4
              }, 
              {
                "text": "As for such as turn aside to their crooked ways, The LORD shall lead them away With the workers of iniquity. Peace be upon Israel!", 
                "num": 5
              }
            ], 
            "num": 125
          }, 
          {
            "verses": [
              {
                "text": "When the LORD brought back the captivity of Zion, We were like those who dream.", 
                "num": 1
              }, 
              {
                "text": "Then our mouth was filled with laughter, And our tongue with singing. Then they said among the nations, \"The LORD has done great things for them.\"", 
                "num": 2
              }, 
              {
                "text": "The LORD has done great things for us, And we are glad.", 
                "num": 3
              }, 
              {
                "text": "Bring back our captivity, O LORD, As the streams in the South.", 
                "num": 4
              }, 
              {
                "text": "Those who sow in tears Shall reap in joy.", 
                "num": 5
              }, 
              {
                "text": "He who continually goes forth weeping, Bearing seed for sowing, Shall doubtless come again with rejoicing, Bringing his sheaves with him.", 
                "num": 6
              }
            ], 
            "num": 126
          }, 
          {
            "verses": [
              {
                "text": "Unless the LORD builds the house, They labor in vain who build it; Unless the LORD guards the city, The watchman stays awake in vain.", 
                "num": 1
              }, 
              {
                "text": "It is vain for you to rise up early, To sit up late, To eat the bread of sorrows; For so He gives His beloved sleep.", 
                "num": 2
              }, 
              {
                "text": "Behold, children are a heritage from the LORD, The fruit of the womb is a reward.", 
                "num": 3
              }, 
              {
                "text": "Like arrows in the hand of a warrior, So are the children of one's youth.", 
                "num": 4
              }, 
              {
                "text": "Happy is the man who has his quiver full of them; They shall not be ashamed, But shall speak with their enemies in the gate.", 
                "num": 5
              }
            ], 
            "num": 127
          }, 
          {
            "verses": [
              {
                "text": "Blessed is every one who fears the LORD, Who walks in His ways.", 
                "num": 1
              }, 
              {
                "text": "When you eat the labor of your hands, You shall be happy, and it shall be well with you.", 
                "num": 2
              }, 
              {
                "text": "Your wife shall be like a fruitful vine In the very heart of your house, Your children like olive plants All around your table.", 
                "num": 3
              }, 
              {
                "text": "Behold, thus shall the man be blessed Who fears the LORD.", 
                "num": 4
              }, 
              {
                "text": "The LORD bless you out of Zion, And may you see the good of Jerusalem All the days of your life.", 
                "num": 5
              }, 
              {
                "text": "Yes, may you see your children's children. Peace be upon Israel!", 
                "num": 6
              }
            ], 
            "num": 128
          }, 
          {
            "verses": [
              {
                "text": "\"Many a time they have afflicted me from my youth,\" Let Israel now say--", 
                "num": 1
              }, 
              {
                "text": "\"Many a time they have afflicted me from my youth; Yet they have not prevailed against me.", 
                "num": 2
              }, 
              {
                "text": "The plowers plowed on my back; They made their furrows long.\"", 
                "num": 3
              }, 
              {
                "text": "The LORD is righteous; He has cut in pieces the cords of the wicked.", 
                "num": 4
              }, 
              {
                "text": "Let all those who hate Zion Be put to shame and turned back.", 
                "num": 5
              }, 
              {
                "text": "Let them be as the grass on the housetops, Which withers before it grows up,", 
                "num": 6
              }, 
              {
                "text": "With which the reaper does not fill his hand, Nor he who binds sheaves, his arms.", 
                "num": 7
              }, 
              {
                "text": "Neither let those who pass by them say, \"The blessing of the LORD be upon you; We bless you in the name of the LORD!\"", 
                "num": 8
              }
            ], 
            "num": 129
          }, 
          {
            "verses": [
              {
                "text": "Out of the depths I have cried to You, O LORD;", 
                "num": 1
              }, 
              {
                "text": "Lord, hear my voice! Let Your ears be attentive To the voice of my supplications.", 
                "num": 2
              }, 
              {
                "text": "If You, LORD, should mark iniquities, O Lord, who could stand?", 
                "num": 3
              }, 
              {
                "text": "But there is forgiveness with You, That You may be feared.", 
                "num": 4
              }, 
              {
                "text": "I wait for the LORD, my soul waits, And in His word I do hope.", 
                "num": 5
              }, 
              {
                "text": "My soul waits for the Lord More than those who watch for the morning-- Yes, more than those who watch for the morning.", 
                "num": 6
              }, 
              {
                "text": "O Israel, hope in the LORD; For with the LORD there is mercy, And with Him is abundant redemption.", 
                "num": 7
              }, 
              {
                "text": "And He shall redeem Israel From all his iniquities.", 
                "num": 8
              }
            ], 
            "num": 130
          }, 
          {
            "verses": [
              {
                "text": "LORD, my heart is not haughty, Nor my eyes lofty. Neither do I concern myself with great matters, Nor with things too profound for me.", 
                "num": 1
              }, 
              {
                "text": "Surely I have calmed and quieted my soul, Like a weaned child with his mother; Like a weaned child is my soul within me.", 
                "num": 2
              }, 
              {
                "text": "O Israel, hope in the LORD From this time forth and forever.", 
                "num": 3
              }
            ], 
            "num": 131
          }, 
          {
            "verses": [
              {
                "text": "LORD, remember David And all his afflictions;", 
                "num": 1
              }, 
              {
                "text": "How he swore to the LORD, And vowed to the Mighty One of Jacob:", 
                "num": 2
              }, 
              {
                "text": "\"Surely I will not go into the chamber of my house, Or go up to the comfort of my bed;", 
                "num": 3
              }, 
              {
                "text": "I will not give sleep to my eyes Or slumber to my eyelids,", 
                "num": 4
              }, 
              {
                "text": "Until I find a place for the LORD, A dwelling place for the Mighty One of Jacob.\"", 
                "num": 5
              }, 
              {
                "text": "Behold, we heard of it in Ephrathah; We found it in the fields of the woods.", 
                "num": 6
              }, 
              {
                "text": "Let us go into His tabernacle; Let us worship at His footstool.", 
                "num": 7
              }, 
              {
                "text": "Arise, O LORD, to Your resting place, You and the ark of Your strength.", 
                "num": 8
              }, 
              {
                "text": "Let Your priests be clothed with righteousness, And let Your saints shout for joy.", 
                "num": 9
              }, 
              {
                "text": "For Your servant David's sake, Do not turn away the face of Your Anointed.", 
                "num": 10
              }, 
              {
                "text": "The LORD has sworn in truth to David; He will not turn from it: \"I will set upon your throne the fruit of your body.", 
                "num": 11
              }, 
              {
                "text": "If your sons will keep My covenant And My testimony which I shall teach them, Their sons also shall sit upon your throne forevermore.\"", 
                "num": 12
              }, 
              {
                "text": "For the LORD has chosen Zion; He has desired it for His dwelling place:", 
                "num": 13
              }, 
              {
                "text": "\"This is My resting place forever; Here I will dwell, for I have desired it.", 
                "num": 14
              }, 
              {
                "text": "I will abundantly bless her provision; I will satisfy her poor with bread.", 
                "num": 15
              }, 
              {
                "text": "I will also clothe her priests with salvation, And her saints shall shout aloud for joy.", 
                "num": 16
              }, 
              {
                "text": "There I will make the horn of David grow; I will prepare a lamp for My Anointed.", 
                "num": 17
              }, 
              {
                "text": "His enemies I will clothe with shame, But upon Himself His crown shall flourish.\"", 
                "num": 18
              }
            ], 
            "num": 132
          }, 
          {
            "verses": [
              {
                "text": "Behold, how good and how pleasant it is For brethren to dwell together in unity!", 
                "num": 1
              }, 
              {
                "text": "It is like the precious oil upon the head, Running down on the beard, The beard of Aaron, Running down on the edge of his garments.", 
                "num": 2
              }, 
              {
                "text": "It is like the dew of Hermon, Descending upon the mountains of Zion; For there the LORD commanded the blessing-- Life forevermore.", 
                "num": 3
              }
            ], 
            "num": 133
          }, 
          {
            "verses": [
              {
                "text": "Behold, bless the LORD, All you servants of the LORD, Who by night stand in the house of the LORD!", 
                "num": 1
              }, 
              {
                "text": "Lift up your hands in the sanctuary, And bless the LORD.", 
                "num": 2
              }, 
              {
                "text": "The LORD who made heaven and earth Bless you from Zion!", 
                "num": 3
              }
            ], 
            "num": 134
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Praise the name of the LORD; Praise Him, O you servants of the LORD!", 
                "num": 1
              }, 
              {
                "text": "You who stand in the house of the LORD, In the courts of the house of our God,", 
                "num": 2
              }, 
              {
                "text": "Praise the LORD, for the LORD is good; Sing praises to His name, for it is pleasant.", 
                "num": 3
              }, 
              {
                "text": "For the LORD has chosen Jacob for Himself, Israel for His special treasure.", 
                "num": 4
              }, 
              {
                "text": "For I know that the LORD is great, And our Lord is above all gods.", 
                "num": 5
              }, 
              {
                "text": "Whatever the LORD pleases He does, In heaven and in earth, In the seas and in all deep places.", 
                "num": 6
              }, 
              {
                "text": "He causes the vapors to ascend from the ends of the earth; He makes lightning for the rain; He brings the wind out of His treasuries.", 
                "num": 7
              }, 
              {
                "text": "He destroyed the firstborn of Egypt, Both of man and beast.", 
                "num": 8
              }, 
              {
                "text": "He sent signs and wonders into the midst of you, O Egypt, Upon Pharaoh and all his servants.", 
                "num": 9
              }, 
              {
                "text": "He defeated many nations And slew mighty kings--", 
                "num": 10
              }, 
              {
                "text": "Sihon king of the Amorites, Og king of Bashan, And all the kingdoms of Canaan--", 
                "num": 11
              }, 
              {
                "text": "And gave their land as a heritage, A heritage to Israel His people.", 
                "num": 12
              }, 
              {
                "text": "Your name, O LORD, endures forever, Your fame, O LORD, throughout all generations.", 
                "num": 13
              }, 
              {
                "text": "For the LORD will judge His people, And He will have compassion on His servants.", 
                "num": 14
              }, 
              {
                "text": "The idols of the nations are silver and gold, The work of men's hands.", 
                "num": 15
              }, 
              {
                "text": "They have mouths, but they do not speak; Eyes they have, but they do not see;", 
                "num": 16
              }, 
              {
                "text": "They have ears, but they do not hear; Nor is there any breath in their mouths.", 
                "num": 17
              }, 
              {
                "text": "Those who make them are like them; So is everyone who trusts in them.", 
                "num": 18
              }, 
              {
                "text": "Bless the LORD, O house of Israel! Bless the LORD, O house of Aaron!", 
                "num": 19
              }, 
              {
                "text": "Bless the LORD, O house of Levi! You who fear the LORD, bless the LORD!", 
                "num": 20
              }, 
              {
                "text": "Blessed be the LORD out of Zion, Who dwells in Jerusalem! Praise the LORD!", 
                "num": 21
              }
            ], 
            "num": 135
          }, 
          {
            "verses": [
              {
                "text": "Oh, give thanks to the LORD, for He is good! For His mercy endures forever.", 
                "num": 1
              }, 
              {
                "text": "Oh, give thanks to the God of gods! For His mercy endures forever.", 
                "num": 2
              }, 
              {
                "text": "Oh, give thanks to the Lord of lords! For His mercy endures forever:", 
                "num": 3
              }, 
              {
                "text": "To Him who alone does great wonders, For His mercy endures forever;", 
                "num": 4
              }, 
              {
                "text": "To Him who by wisdom made the heavens, For His mercy endures forever;", 
                "num": 5
              }, 
              {
                "text": "To Him who laid out the earth above the waters, For His mercy endures forever;", 
                "num": 6
              }, 
              {
                "text": "To Him who made great lights, For His mercy endures forever--", 
                "num": 7
              }, 
              {
                "text": "The sun to rule by day, For His mercy endures forever;", 
                "num": 8
              }, 
              {
                "text": "The moon and stars to rule by night, For His mercy endures forever.", 
                "num": 9
              }, 
              {
                "text": "To Him who struck Egypt in their firstborn, For His mercy endures forever;", 
                "num": 10
              }, 
              {
                "text": "And brought out Israel from among them, For His mercy endures forever;", 
                "num": 11
              }, 
              {
                "text": "With a strong hand, and with an outstretched arm, For His mercy endures forever;", 
                "num": 12
              }, 
              {
                "text": "To Him who divided the Red Sea in two, For His mercy endures forever;", 
                "num": 13
              }, 
              {
                "text": "And made Israel pass through the midst of it, For His mercy endures forever;", 
                "num": 14
              }, 
              {
                "text": "But overthrew Pharaoh and his army in the Red Sea, For His mercy endures forever;", 
                "num": 15
              }, 
              {
                "text": "To Him who led His people through the wilderness, For His mercy endures forever;", 
                "num": 16
              }, 
              {
                "text": "To Him who struck down great kings, For His mercy endures forever;", 
                "num": 17
              }, 
              {
                "text": "And slew famous kings, For His mercy endures forever--", 
                "num": 18
              }, 
              {
                "text": "Sihon king of the Amorites, For His mercy endures forever;", 
                "num": 19
              }, 
              {
                "text": "And Og king of Bashan, For His mercy endures forever--", 
                "num": 20
              }, 
              {
                "text": "And gave their land as a heritage, For His mercy endures forever;", 
                "num": 21
              }, 
              {
                "text": "A heritage to Israel His servant, For His mercy endures forever.", 
                "num": 22
              }, 
              {
                "text": "Who remembered us in our lowly state, For His mercy endures forever;", 
                "num": 23
              }, 
              {
                "text": "And rescued us from our enemies, For His mercy endures forever;", 
                "num": 24
              }, 
              {
                "text": "Who gives food to all flesh, For His mercy endures forever.", 
                "num": 25
              }, 
              {
                "text": "Oh, give thanks to the God of heaven! For His mercy endures forever.", 
                "num": 26
              }
            ], 
            "num": 136
          }, 
          {
            "verses": [
              {
                "text": "By the rivers of Babylon, There we sat down, yea, we wept When we remembered Zion.", 
                "num": 1
              }, 
              {
                "text": "We hung our harps Upon the willows in the midst of it.", 
                "num": 2
              }, 
              {
                "text": "For there those who carried us away captive asked of us a song, And those who plundered us requested mirth, Saying, \"Sing us one of the songs of Zion!\"", 
                "num": 3
              }, 
              {
                "text": "How shall we sing the LORD's song In a foreign land?", 
                "num": 4
              }, 
              {
                "text": "If I forget you, O Jerusalem, Let my right hand forget its skill!", 
                "num": 5
              }, 
              {
                "text": "If I do not remember you, Let my tongue cling to the roof of my mouth-- If I do not exalt Jerusalem Above my chief joy.", 
                "num": 6
              }, 
              {
                "text": "Remember, O LORD, against the sons of Edom The day of Jerusalem, Who said, \"Raze it, raze it, To its very foundation!\"", 
                "num": 7
              }, 
              {
                "text": "O daughter of Babylon, who are to be destroyed, Happy the one who repays you as you have served us!", 
                "num": 8
              }, 
              {
                "text": "Happy the one who takes and dashes Your little ones against the rock!", 
                "num": 9
              }
            ], 
            "num": 137
          }, 
          {
            "verses": [
              {
                "text": "I will praise You with my whole heart; Before the gods I will sing praises to You.", 
                "num": 1
              }, 
              {
                "text": "I will worship toward Your holy temple, And praise Your name For Your lovingkindness and Your truth; For You have magnified Your word above all Your name.", 
                "num": 2
              }, 
              {
                "text": "In the day when I cried out, You answered me, And made me bold with strength in my soul.", 
                "num": 3
              }, 
              {
                "text": "All the kings of the earth shall praise You, O LORD, When they hear the words of Your mouth.", 
                "num": 4
              }, 
              {
                "text": "Yes, they shall sing of the ways of the LORD, For great is the glory of the LORD.", 
                "num": 5
              }, 
              {
                "text": "Though the LORD is on high, Yet He regards the lowly; But the proud He knows from afar.", 
                "num": 6
              }, 
              {
                "text": "Though I walk in the midst of trouble, You will revive me; You will stretch out Your hand Against the wrath of my enemies, And Your right hand will save me.", 
                "num": 7
              }, 
              {
                "text": "The LORD will perfect that which concerns me; Your mercy, O LORD, endures forever; Do not forsake the works of Your hands.", 
                "num": 8
              }
            ], 
            "num": 138
          }, 
          {
            "verses": [
              {
                "text": "O LORD, You have searched me and known me.", 
                "num": 1
              }, 
              {
                "text": "You know my sitting down and my rising up; You understand my thought afar off.", 
                "num": 2
              }, 
              {
                "text": "You comprehend my path and my lying down, And are acquainted with all my ways.", 
                "num": 3
              }, 
              {
                "text": "For there is not a word on my tongue, But behold, O LORD, You know it altogether.", 
                "num": 4
              }, 
              {
                "text": "You have hedged me behind and before, And laid Your hand upon me.", 
                "num": 5
              }, 
              {
                "text": "Such knowledge is too wonderful for me; It is high, I cannot attain it.", 
                "num": 6
              }, 
              {
                "text": "Where can I go from Your Spirit? Or where can I flee from Your presence?", 
                "num": 7
              }, 
              {
                "text": "If I ascend into heaven, You are there; If I make my bed in hell, behold, You are there.", 
                "num": 8
              }, 
              {
                "text": "If I take the wings of the morning, And dwell in the uttermost parts of the sea,", 
                "num": 9
              }, 
              {
                "text": "Even there Your hand shall lead me, And Your right hand shall hold me.", 
                "num": 10
              }, 
              {
                "text": "If I say, \"Surely the darkness shall fall on me,\" Even the night shall be light about me;", 
                "num": 11
              }, 
              {
                "text": "Indeed, the darkness shall not hide from You, But the night shines as the day; The darkness and the light are both alike to You.", 
                "num": 12
              }, 
              {
                "text": "For You formed my inward parts; You covered me in my mother's womb.", 
                "num": 13
              }, 
              {
                "text": "I will praise You, for I am fearfully and wonderfully made; Marvelous are Your works, And that my soul knows very well.", 
                "num": 14
              }, 
              {
                "text": "My frame was not hidden from You, When I was made in secret, And skillfully wrought in the lowest parts of the earth.", 
                "num": 15
              }, 
              {
                "text": "Your eyes saw my substance, being yet unformed. And in Your book they all were written, The days fashioned for me, When as yet there were none of them.", 
                "num": 16
              }, 
              {
                "text": "How precious also are Your thoughts to me, O God! How great is the sum of them!", 
                "num": 17
              }, 
              {
                "text": "If I should count them, they would be more in number than the sand; When I awake, I am still with You.", 
                "num": 18
              }, 
              {
                "text": "Oh, that You would slay the wicked, O God! Depart from me, therefore, you bloodthirsty men.", 
                "num": 19
              }, 
              {
                "text": "For they speak against You wickedly; Your enemies take Your name in vain.", 
                "num": 20
              }, 
              {
                "text": "Do I not hate them, O LORD, who hate You? And do I not loathe those who rise up against You?", 
                "num": 21
              }, 
              {
                "text": "I hate them with perfect hatred; I count them my enemies.", 
                "num": 22
              }, 
              {
                "text": "Search me, O God, and know my heart; Try me, and know my anxieties;", 
                "num": 23
              }, 
              {
                "text": "And see if there is any wicked way in me, And lead me in the way everlasting.", 
                "num": 24
              }
            ], 
            "num": 139
          }, 
          {
            "verses": [
              {
                "text": "Deliver me, O LORD, from evil men; Preserve me from violent men,", 
                "num": 1
              }, 
              {
                "text": "Who plan evil things in their hearts; They continually gather together for war.", 
                "num": 2
              }, 
              {
                "text": "They sharpen their tongues like a serpent; The poison of asps is under their lips.Selah", 
                "num": 3
              }, 
              {
                "text": "Keep me, O LORD, from the hands of the wicked; Preserve me from violent men, Who have purposed to make my steps stumble.", 
                "num": 4
              }, 
              {
                "text": "The proud have hidden a snare for me, and cords; They have spread a net by the wayside; They have set traps for me.Selah", 
                "num": 5
              }, 
              {
                "text": "I said to the LORD: \"You are my God; Hear the voice of my supplications, O LORD.", 
                "num": 6
              }, 
              {
                "text": "O GOD the Lord, the strength of my salvation, You have covered my head in the day of battle.", 
                "num": 7
              }, 
              {
                "text": "Do not grant, O LORD, the desires of the wicked; Do not further his wicked scheme, Lest they be exalted.Selah", 
                "num": 8
              }, 
              {
                "text": "\"As for the head of those who surround me, Let the evil of their lips cover them;", 
                "num": 9
              }, 
              {
                "text": "Let burning coals fall upon them; Let them be cast into the fire, Into deep pits, that they rise not up again.", 
                "num": 10
              }, 
              {
                "text": "Let not a slanderer be established in the earth; Let evil hunt the violent man to overthrow him.\"", 
                "num": 11
              }, 
              {
                "text": "I know that the LORD will maintain The cause of the afflicted, And justice for the poor.", 
                "num": 12
              }, 
              {
                "text": "Surely the righteous shall give thanks to Your name; The upright shall dwell in Your presence.", 
                "num": 13
              }
            ], 
            "num": 140
          }, 
          {
            "verses": [
              {
                "text": "LORD, I cry out to You; Make haste to me! Give ear to my voice when I cry out to You.", 
                "num": 1
              }, 
              {
                "text": "Let my prayer be set before You as incense, The lifting up of my hands as the evening sacrifice.", 
                "num": 2
              }, 
              {
                "text": "Set a guard, O LORD, over my mouth; Keep watch over the door of my lips.", 
                "num": 3
              }, 
              {
                "text": "Do not incline my heart to any evil thing, To practice wicked works With men who work iniquity; And do not let me eat of their delicacies.", 
                "num": 4
              }, 
              {
                "text": "Let the righteous strike me; It shall be a kindness. And let him rebuke me; It shall be as excellent oil; Let my head not refuse it. For still my prayer is against the deeds of the wicked.", 
                "num": 5
              }, 
              {
                "text": "Their judges are overthrown by the sides of the cliff, And they hear my words, for they are sweet.", 
                "num": 6
              }, 
              {
                "text": "Our bones are scattered at the mouth of the grave, As when one plows and breaks up the earth.", 
                "num": 7
              }, 
              {
                "text": "But my eyes are upon You, O GOD the Lord; In You I take refuge; Do not leave my soul destitute.", 
                "num": 8
              }, 
              {
                "text": "Keep me from the snares they have laid for me, And from the traps of the workers of iniquity.", 
                "num": 9
              }, 
              {
                "text": "Let the wicked fall into their own nets, While I escape safely.", 
                "num": 10
              }
            ], 
            "num": 141
          }, 
          {
            "verses": [
              {
                "text": "I cry out to the LORD with my voice; With my voice to the LORD I make my supplication.", 
                "num": 1
              }, 
              {
                "text": "I pour out my complaint before Him; I declare before Him my trouble.", 
                "num": 2
              }, 
              {
                "text": "When my spirit was overwhelmed within me, Then You knew my path. In the way in which I walk They have secretly set a snare for me.", 
                "num": 3
              }, 
              {
                "text": "Look on my right hand and see, For there is no one who acknowledges me; Refuge has failed me; No one cares for my soul.", 
                "num": 4
              }, 
              {
                "text": "I cried out to You, O LORD: I said, \"You are my refuge, My portion in the land of the living.", 
                "num": 5
              }, 
              {
                "text": "Attend to my cry, For I am brought very low; Deliver me from my persecutors, For they are stronger than I.", 
                "num": 6
              }, 
              {
                "text": "Bring my soul out of prison, That I may praise Your name; The righteous shall surround me, For You shall deal bountifully with me.\"", 
                "num": 7
              }
            ], 
            "num": 142
          }, 
          {
            "verses": [
              {
                "text": "Hear my prayer, O LORD, Give ear to my supplications! In Your faithfulness answer me, And in Your righteousness.", 
                "num": 1
              }, 
              {
                "text": "Do not enter into judgment with Your servant, For in Your sight no one living is righteous.", 
                "num": 2
              }, 
              {
                "text": "For the enemy has persecuted my soul; He has crushed my life to the ground; He has made me dwell in darkness, Like those who have long been dead.", 
                "num": 3
              }, 
              {
                "text": "Therefore my spirit is overwhelmed within me; My heart within me is distressed.", 
                "num": 4
              }, 
              {
                "text": "I remember the days of old; I meditate on all Your works; I muse on the work of Your hands.", 
                "num": 5
              }, 
              {
                "text": "I spread out my hands to You; My soul longs for You like a thirsty land.Selah", 
                "num": 6
              }, 
              {
                "text": "Answer me speedily, O LORD; My spirit fails! Do not hide Your face from me, Lest I be like those who go down into the pit.", 
                "num": 7
              }, 
              {
                "text": "Cause me to hear Your lovingkindness in the morning, For in You do I trust; Cause me to know the way in which I should walk, For I lift up my soul to You.", 
                "num": 8
              }, 
              {
                "text": "Deliver me, O LORD, from my enemies; In You I take shelter.", 
                "num": 9
              }, 
              {
                "text": "Teach me to do Your will, For You are my God; Your Spirit is good. Lead me in the land of uprightness.", 
                "num": 10
              }, 
              {
                "text": "Revive me, O LORD, for Your name's sake! For Your righteousness' sake bring my soul out of trouble.", 
                "num": 11
              }, 
              {
                "text": "In Your mercy cut off my enemies, And destroy all those who afflict my soul; For I am Your servant.", 
                "num": 12
              }
            ], 
            "num": 143
          }, 
          {
            "verses": [
              {
                "text": "Blessed be the LORD my Rock, Who trains my hands for war, And my fingers for battle--", 
                "num": 1
              }, 
              {
                "text": "My lovingkindness and my fortress, My high tower and my deliverer, My shield and the One in whom I take refuge, Who subdues my people under me.", 
                "num": 2
              }, 
              {
                "text": "LORD, what is man, that You take knowledge of him? Or the son of man, that You are mindful of him?", 
                "num": 3
              }, 
              {
                "text": "Man is like a breath; His days are like a passing shadow.", 
                "num": 4
              }, 
              {
                "text": "Bow down Your heavens, O LORD, and come down; Touch the mountains, and they shall smoke.", 
                "num": 5
              }, 
              {
                "text": "Flash forth lightning and scatter them; Shoot out Your arrows and destroy them.", 
                "num": 6
              }, 
              {
                "text": "Stretch out Your hand from above; Rescue me and deliver me out of great waters, From the hand of foreigners,", 
                "num": 7
              }, 
              {
                "text": "Whose mouth speaks lying words, And whose right hand is a right hand of falsehood.", 
                "num": 8
              }, 
              {
                "text": "I will sing a new song to You, O God; On a harp of ten strings I will sing praises to You,", 
                "num": 9
              }, 
              {
                "text": "The One who gives salvation to kings, Who delivers David His servant From the deadly sword.", 
                "num": 10
              }, 
              {
                "text": "Rescue me and deliver me from the hand of foreigners, Whose mouth speaks lying words, And whose right hand is a right hand of falsehood--", 
                "num": 11
              }, 
              {
                "text": "That our sons may be as plants grown up in their youth; That our daughters may be as pillars, Sculptured in palace style;", 
                "num": 12
              }, 
              {
                "text": "That our barns may be full, Supplying all kinds of produce; That our sheep may bring forth thousands And ten thousands in our fields;", 
                "num": 13
              }, 
              {
                "text": "That our oxen may be well laden; That there be no breaking in or going out; That there be no outcry in our streets.", 
                "num": 14
              }, 
              {
                "text": "Happy are the people who are in such a state; Happy are the people whose God is the LORD!", 
                "num": 15
              }
            ], 
            "num": 144
          }, 
          {
            "verses": [
              {
                "text": "I will extol You, my God, O King; And I will bless Your name forever and ever.", 
                "num": 1
              }, 
              {
                "text": "Every day I will bless You, And I will praise Your name forever and ever.", 
                "num": 2
              }, 
              {
                "text": "Great is the LORD, and greatly to be praised; And His greatness is unsearchable.", 
                "num": 3
              }, 
              {
                "text": "One generation shall praise Your works to another, And shall declare Your mighty acts.", 
                "num": 4
              }, 
              {
                "text": "I will meditate on the glorious splendor of Your majesty, And on Your wondrous works.", 
                "num": 5
              }, 
              {
                "text": "Men shall speak of the might of Your awesome acts, And I will declare Your greatness.", 
                "num": 6
              }, 
              {
                "text": "They shall utter the memory of Your great goodness, And shall sing of Your righteousness.", 
                "num": 7
              }, 
              {
                "text": "The LORD is gracious and full of compassion, Slow to anger and great in mercy.", 
                "num": 8
              }, 
              {
                "text": "The LORD is good to all, And His tender mercies are over all His works.", 
                "num": 9
              }, 
              {
                "text": "All Your works shall praise You, O LORD, And Your saints shall bless You.", 
                "num": 10
              }, 
              {
                "text": "They shall speak of the glory of Your kingdom, And talk of Your power,", 
                "num": 11
              }, 
              {
                "text": "To make known to the sons of men His mighty acts, And the glorious majesty of His kingdom.", 
                "num": 12
              }, 
              {
                "text": "Your kingdom is an everlasting kingdom, And Your dominion endures throughout all generations.", 
                "num": 13
              }, 
              {
                "text": "The LORD upholds all who fall, And raises up all who are bowed down.", 
                "num": 14
              }, 
              {
                "text": "The eyes of all look expectantly to You, And You give them their food in due season.", 
                "num": 15
              }, 
              {
                "text": "You open Your hand And satisfy the desire of every living thing.", 
                "num": 16
              }, 
              {
                "text": "The LORD is righteous in all His ways, Gracious in all His works.", 
                "num": 17
              }, 
              {
                "text": "The LORD is near to all who call upon Him, To all who call upon Him in truth.", 
                "num": 18
              }, 
              {
                "text": "He will fulfill the desire of those who fear Him; He also will hear their cry and save them.", 
                "num": 19
              }, 
              {
                "text": "The LORD preserves all who love Him, But all the wicked He will destroy.", 
                "num": 20
              }, 
              {
                "text": "My mouth shall speak the praise of the LORD, And all flesh shall bless His holy name Forever and ever.", 
                "num": 21
              }
            ], 
            "num": 145
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Praise the LORD, O my soul!", 
                "num": 1
              }, 
              {
                "text": "While I live I will praise the LORD; I will sing praises to my God while I have my being.", 
                "num": 2
              }, 
              {
                "text": "Do not put your trust in princes, Nor in a son of man, in whom there is no help.", 
                "num": 3
              }, 
              {
                "text": "His spirit departs, he returns to his earth; In that very day his plans perish.", 
                "num": 4
              }, 
              {
                "text": "Happy is he who has the God of Jacob for his help, Whose hope is in the LORD his God,", 
                "num": 5
              }, 
              {
                "text": "Who made heaven and earth, The sea, and all that is in them; Who keeps truth forever,", 
                "num": 6
              }, 
              {
                "text": "Who executes justice for the oppressed, Who gives food to the hungry. The LORD gives freedom to the prisoners.", 
                "num": 7
              }, 
              {
                "text": "The LORD opens the eyes of the blind; The LORD raises those who are bowed down; The LORD loves the righteous.", 
                "num": 8
              }, 
              {
                "text": "The LORD watches over the strangers; He relieves the fatherless and widow; But the way of the wicked He turns upside down.", 
                "num": 9
              }, 
              {
                "text": "The LORD shall reign forever-- Your God, O Zion, to all generations. Praise the LORD!", 
                "num": 10
              }
            ], 
            "num": 146
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! For it is good to sing praises to our God; For it is pleasant, and praise is beautiful.", 
                "num": 1
              }, 
              {
                "text": "The LORD builds up Jerusalem; He gathers together the outcasts of Israel.", 
                "num": 2
              }, 
              {
                "text": "He heals the brokenhearted And binds up their wounds.", 
                "num": 3
              }, 
              {
                "text": "He counts the number of the stars; He calls them all by name.", 
                "num": 4
              }, 
              {
                "text": "Great is our Lord, and mighty in power; His understanding is infinite.", 
                "num": 5
              }, 
              {
                "text": "The LORD lifts up the humble; He casts the wicked down to the ground.", 
                "num": 6
              }, 
              {
                "text": "Sing to the LORD with thanksgiving; Sing praises on the harp to our God,", 
                "num": 7
              }, 
              {
                "text": "Who covers the heavens with clouds, Who prepares rain for the earth, Who makes grass to grow on the mountains.", 
                "num": 8
              }, 
              {
                "text": "He gives to the beast its food, And to the young ravens that cry.", 
                "num": 9
              }, 
              {
                "text": "He does not delight in the strength of the horse; He takes no pleasure in the legs of a man.", 
                "num": 10
              }, 
              {
                "text": "The LORD takes pleasure in those who fear Him, In those who hope in His mercy.", 
                "num": 11
              }, 
              {
                "text": "Praise the LORD, O Jerusalem! Praise your God, O Zion!", 
                "num": 12
              }, 
              {
                "text": "For He has strengthened the bars of your gates; He has blessed your children within you.", 
                "num": 13
              }, 
              {
                "text": "He makes peace in your borders, And fills you with the finest wheat.", 
                "num": 14
              }, 
              {
                "text": "He sends out His command to the earth; His word runs very swiftly.", 
                "num": 15
              }, 
              {
                "text": "He gives snow like wool; He scatters the frost like ashes;", 
                "num": 16
              }, 
              {
                "text": "He casts out His hail like morsels; Who can stand before His cold?", 
                "num": 17
              }, 
              {
                "text": "He sends out His word and melts them; He causes His wind to blow, and the waters flow.", 
                "num": 18
              }, 
              {
                "text": "He declares His word to Jacob, His statutes and His judgments to Israel.", 
                "num": 19
              }, 
              {
                "text": "He has not dealt thus with any nation; And as for His judgments, they have not known them. Praise the LORD!", 
                "num": 20
              }
            ], 
            "num": 147
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Praise the LORD from the heavens; Praise Him in the heights!", 
                "num": 1
              }, 
              {
                "text": "Praise Him, all His angels; Praise Him, all His hosts!", 
                "num": 2
              }, 
              {
                "text": "Praise Him, sun and moon; Praise Him, all you stars of light!", 
                "num": 3
              }, 
              {
                "text": "Praise Him, you heavens of heavens, And you waters above the heavens!", 
                "num": 4
              }, 
              {
                "text": "Let them praise the name of the LORD, For He commanded and they were created.", 
                "num": 5
              }, 
              {
                "text": "He also established them forever and ever; He made a decree which shall not pass away.", 
                "num": 6
              }, 
              {
                "text": "Praise the LORD from the earth, You great sea creatures and all the depths;", 
                "num": 7
              }, 
              {
                "text": "Fire and hail, snow and clouds; Stormy wind, fulfilling His word;", 
                "num": 8
              }, 
              {
                "text": "Mountains and all hills; Fruitful trees and all cedars;", 
                "num": 9
              }, 
              {
                "text": "Beasts and all cattle; Creeping things and flying fowl;", 
                "num": 10
              }, 
              {
                "text": "Kings of the earth and all peoples; Princes and all judges of the earth;", 
                "num": 11
              }, 
              {
                "text": "Both young men and maidens; Old men and children.", 
                "num": 12
              }, 
              {
                "text": "Let them praise the name of the LORD, For His name alone is exalted; His glory is above the earth and heaven.", 
                "num": 13
              }, 
              {
                "text": "And He has exalted the horn of His people, The praise of all His saints-- Of the children of Israel, A people near to Him. Praise the LORD!", 
                "num": 14
              }
            ], 
            "num": 148
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Sing to the LORD a new song, And His praise in the assembly of saints.", 
                "num": 1
              }, 
              {
                "text": "Let Israel rejoice in their Maker; Let the children of Zion be joyful in their King.", 
                "num": 2
              }, 
              {
                "text": "Let them praise His name with the dance; Let them sing praises to Him with the timbrel and harp.", 
                "num": 3
              }, 
              {
                "text": "For the LORD takes pleasure in His people; He will beautify the humble with salvation.", 
                "num": 4
              }, 
              {
                "text": "Let the saints be joyful in glory; Let them sing aloud on their beds.", 
                "num": 5
              }, 
              {
                "text": "Let the high praises of God be in their mouth, And a two-edged sword in their hand,", 
                "num": 6
              }, 
              {
                "text": "To execute vengeance on the nations, And punishments on the peoples;", 
                "num": 7
              }, 
              {
                "text": "To bind their kings with chains, And their nobles with fetters of iron;", 
                "num": 8
              }, 
              {
                "text": "To execute on them the written judgment-- This honor have all His saints. Praise the LORD!", 
                "num": 9
              }
            ], 
            "num": 149
          }, 
          {
            "verses": [
              {
                "text": "Praise the LORD! Praise God in His sanctuary; Praise Him in His mighty firmament!", 
                "num": 1
              }, 
              {
                "text": "Praise Him for His mighty acts; Praise Him according to His excellent greatness!", 
                "num": 2
              }, 
              {
                "text": "Praise Him with the sound of the trumpet; Praise Him with the lute and harp!", 
                "num": 3
              }, 
              {
                "text": "Praise Him with the timbrel and dance; Praise Him with stringed instruments and flutes!", 
                "num": 4
              }, 
              {
                "text": "Praise Him with loud cymbals; Praise Him with clashing cymbals!", 
                "num": 5
              }, 
              {
                "text": "Let everything that has breath praise the LORD. Praise the LORD!", 
                "num": 6
              }
            ], 
            "num": 150
          }
        ]
      }, 
      {
        "name": "Proverbs", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The proverbs of Solomon the son of David, king of Israel:", 
                "num": 1
              }, 
              {
                "text": "To know wisdom and instruction, To perceive the words of understanding,", 
                "num": 2
              }, 
              {
                "text": "To receive the instruction of wisdom, Justice, judgment, and equity;", 
                "num": 3
              }, 
              {
                "text": "To give prudence to the simple, To the young man knowledge and discretion--", 
                "num": 4
              }, 
              {
                "text": "A wise man will hear and increase learning, And a man of understanding will attain wise counsel,", 
                "num": 5
              }, 
              {
                "text": "To understand a proverb and an enigma, The words of the wise and their riddles.", 
                "num": 6
              }, 
              {
                "text": "The fear of the LORD is the beginning of knowledge, But fools despise wisdom and instruction.", 
                "num": 7
              }, 
              {
                "text": "My son, hear the instruction of your father, And do not forsake the law of your mother;", 
                "num": 8
              }, 
              {
                "text": "For they will be a graceful ornament on your head, And chains about your neck.", 
                "num": 9
              }, 
              {
                "text": "My son, if sinners entice you, Do not consent.", 
                "num": 10
              }, 
              {
                "text": "If they say, \"Come with us, Let us lie in wait to shed blood; Let us lurk secretly for the innocent without cause;", 
                "num": 11
              }, 
              {
                "text": "Let us swallow them alive like Sheol, And whole, like those who go down to the Pit;", 
                "num": 12
              }, 
              {
                "text": "We shall find all kinds of precious possessions, We shall fill our houses with spoil;", 
                "num": 13
              }, 
              {
                "text": "Cast in your lot among us, Let us all have one purse\"--", 
                "num": 14
              }, 
              {
                "text": "My son, do not walk in the way with them, Keep your foot from their path;", 
                "num": 15
              }, 
              {
                "text": "For their feet run to evil, And they make haste to shed blood.", 
                "num": 16
              }, 
              {
                "text": "Surely, in vain the net is spread In the sight of any bird;", 
                "num": 17
              }, 
              {
                "text": "But they lie in wait for their own blood, They lurk secretly for their own lives.", 
                "num": 18
              }, 
              {
                "text": "So are the ways of everyone who is greedy for gain; It takes away the life of its owners.", 
                "num": 19
              }, 
              {
                "text": "Wisdom calls aloud outside; She raises her voice in the open squares.", 
                "num": 20
              }, 
              {
                "text": "She cries out in the chief concourses, At the openings of the gates in the city She speaks her words:", 
                "num": 21
              }, 
              {
                "text": "\"How long, you simple ones, will you love simplicity? For scorners delight in their scorning, And fools hate knowledge.", 
                "num": 22
              }, 
              {
                "text": "Turn at my rebuke; Surely I will pour out my spirit on you; I will make my words known to you.", 
                "num": 23
              }, 
              {
                "text": "Because I have called and you refused, I have stretched out my hand and no one regarded,", 
                "num": 24
              }, 
              {
                "text": "Because you disdained all my counsel, And would have none of my rebuke,", 
                "num": 25
              }, 
              {
                "text": "I also will laugh at your calamity; I will mock when your terror comes,", 
                "num": 26
              }, 
              {
                "text": "When your terror comes like a storm, And your destruction comes like a whirlwind, When distress and anguish come upon you.", 
                "num": 27
              }, 
              {
                "text": "\"Then they will call on me, but I will not answer; They will seek me diligently, but they will not find me.", 
                "num": 28
              }, 
              {
                "text": "Because they hated knowledge And did not choose the fear of the LORD,", 
                "num": 29
              }, 
              {
                "text": "They would have none of my counsel And despised my every rebuke.", 
                "num": 30
              }, 
              {
                "text": "Therefore they shall eat the fruit of their own way, And be filled to the full with their own fancies.", 
                "num": 31
              }, 
              {
                "text": "For the turning away of the simple will slay them, And the complacency of fools will destroy them;", 
                "num": 32
              }, 
              {
                "text": "But whoever listens to me will dwell safely, And will be secure, without fear of evil.\"", 
                "num": 33
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "My son, if you receive my words, And treasure my commands within you,", 
                "num": 1
              }, 
              {
                "text": "So that you incline your ear to wisdom, And apply your heart to understanding;", 
                "num": 2
              }, 
              {
                "text": "Yes, if you cry out for discernment, And lift up your voice for understanding,", 
                "num": 3
              }, 
              {
                "text": "If you seek her as silver, And search for her as for hidden treasures;", 
                "num": 4
              }, 
              {
                "text": "Then you will understand the fear of the LORD, And find the knowledge of God.", 
                "num": 5
              }, 
              {
                "text": "For the LORD gives wisdom; From His mouth come knowledge and understanding;", 
                "num": 6
              }, 
              {
                "text": "He stores up sound wisdom for the upright; He is a shield to those who walk uprightly;", 
                "num": 7
              }, 
              {
                "text": "He guards the paths of justice, And preserves the way of His saints.", 
                "num": 8
              }, 
              {
                "text": "Then you will understand righteousness and justice, Equity and every good path.", 
                "num": 9
              }, 
              {
                "text": "When wisdom enters your heart, And knowledge is pleasant to your soul,", 
                "num": 10
              }, 
              {
                "text": "Discretion will preserve you; Understanding will keep you,", 
                "num": 11
              }, 
              {
                "text": "To deliver you from the way of evil, From the man who speaks perverse things,", 
                "num": 12
              }, 
              {
                "text": "From those who leave the paths of uprightness To walk in the ways of darkness;", 
                "num": 13
              }, 
              {
                "text": "Who rejoice in doing evil, And delight in the perversity of the wicked;", 
                "num": 14
              }, 
              {
                "text": "Whose ways are crooked, And who are devious in their paths;", 
                "num": 15
              }, 
              {
                "text": "To deliver you from the immoral woman, From the seductress who flatters with her words,", 
                "num": 16
              }, 
              {
                "text": "Who forsakes the companion of her youth, And forgets the covenant of her God.", 
                "num": 17
              }, 
              {
                "text": "For her house leads down to death, And her paths to the dead;", 
                "num": 18
              }, 
              {
                "text": "None who go to her return, Nor do they regain the paths of life--", 
                "num": 19
              }, 
              {
                "text": "So you may walk in the way of goodness, And keep to the paths of righteousness.", 
                "num": 20
              }, 
              {
                "text": "For the upright will dwell in the land, And the blameless will remain in it;", 
                "num": 21
              }, 
              {
                "text": "But the wicked will be cut off from the earth, And the unfaithful will be uprooted from it.", 
                "num": 22
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "My son, do not forget my law, But let your heart keep my commands;", 
                "num": 1
              }, 
              {
                "text": "For length of days and long life And peace they will add to you.", 
                "num": 2
              }, 
              {
                "text": "Let not mercy and truth forsake you; Bind them around your neck, Write them on the tablet of your heart,", 
                "num": 3
              }, 
              {
                "text": "And so find favor and high esteem In the sight of God and man.", 
                "num": 4
              }, 
              {
                "text": "Trust in the LORD with all your heart, And lean not on your own understanding;", 
                "num": 5
              }, 
              {
                "text": "In all your ways acknowledge Him, And He shall direct your paths.", 
                "num": 6
              }, 
              {
                "text": "Do not be wise in your own eyes; Fear the LORD and depart from evil.", 
                "num": 7
              }, 
              {
                "text": "It will be health to your flesh, And strength to your bones.", 
                "num": 8
              }, 
              {
                "text": "Honor the LORD with your possessions, And with the firstfruits of all your increase;", 
                "num": 9
              }, 
              {
                "text": "So your barns will be filled with plenty, And your vats will overflow with new wine.", 
                "num": 10
              }, 
              {
                "text": "My son, do not despise the chastening of the LORD, Nor detest His correction;", 
                "num": 11
              }, 
              {
                "text": "For whom the LORD loves He corrects, Just as a father the son in whom he delights.", 
                "num": 12
              }, 
              {
                "text": "Happy is the man who finds wisdom, And the man who gains understanding;", 
                "num": 13
              }, 
              {
                "text": "For her proceeds are better than the profits of silver, And her gain than fine gold.", 
                "num": 14
              }, 
              {
                "text": "She is more precious than rubies, And all the things you may desire cannot compare with her.", 
                "num": 15
              }, 
              {
                "text": "Length of days is in her right hand, In her left hand riches and honor.", 
                "num": 16
              }, 
              {
                "text": "Her ways are ways of pleasantness, And all her paths are peace.", 
                "num": 17
              }, 
              {
                "text": "She is a tree of life to those who take hold of her, And happy are all who retain her.", 
                "num": 18
              }, 
              {
                "text": "The LORD by wisdom founded the earth; By understanding He established the heavens;", 
                "num": 19
              }, 
              {
                "text": "By His knowledge the depths were broken up, And clouds drop down the dew.", 
                "num": 20
              }, 
              {
                "text": "My son, let them not depart from your eyes-- Keep sound wisdom and discretion;", 
                "num": 21
              }, 
              {
                "text": "So they will be life to your soul And grace to your neck.", 
                "num": 22
              }, 
              {
                "text": "Then you will walk safely in your way, And your foot will not stumble.", 
                "num": 23
              }, 
              {
                "text": "When you lie down, you will not be afraid; Yes, you will lie down and your sleep will be sweet.", 
                "num": 24
              }, 
              {
                "text": "Do not be afraid of sudden terror, Nor of trouble from the wicked when it comes;", 
                "num": 25
              }, 
              {
                "text": "For the LORD will be your confidence, And will keep your foot from being caught.", 
                "num": 26
              }, 
              {
                "text": "Do not withhold good from those to whom it is due, When it is in the power of your hand to do so.", 
                "num": 27
              }, 
              {
                "text": "Do not say to your neighbor, \"Go, and come back, And tomorrow I will give it,\" When you have it with you.", 
                "num": 28
              }, 
              {
                "text": "Do not devise evil against your neighbor, For he dwells by you for safety's sake.", 
                "num": 29
              }, 
              {
                "text": "Do not strive with a man without cause, If he has done you no harm.", 
                "num": 30
              }, 
              {
                "text": "Do not envy the oppressor, And choose none of his ways;", 
                "num": 31
              }, 
              {
                "text": "For the perverse person is an abomination to the LORD, But His secret counsel is with the upright.", 
                "num": 32
              }, 
              {
                "text": "The curse of the LORD is on the house of the wicked, But He blesses the home of the just.", 
                "num": 33
              }, 
              {
                "text": "Surely He scorns the scornful, But gives grace to the humble.", 
                "num": 34
              }, 
              {
                "text": "The wise shall inherit glory, But shame shall be the legacy of fools.", 
                "num": 35
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Hear, my children, the instruction of a father, And give attention to know understanding;", 
                "num": 1
              }, 
              {
                "text": "For I give you good doctrine: Do not forsake my law.", 
                "num": 2
              }, 
              {
                "text": "When I was my father's son, Tender and the only one in the sight of my mother,", 
                "num": 3
              }, 
              {
                "text": "He also taught me, and said to me: \"Let your heart retain my words; Keep my commands, and live.", 
                "num": 4
              }, 
              {
                "text": "Get wisdom! Get understanding! Do not forget, nor turn away from the words of my mouth.", 
                "num": 5
              }, 
              {
                "text": "Do not forsake her, and she will preserve you; Love her, and she will keep you.", 
                "num": 6
              }, 
              {
                "text": "Wisdom is the principal thing; Therefore get wisdom. And in all your getting, get understanding.", 
                "num": 7
              }, 
              {
                "text": "Exalt her, and she will promote you; She will bring you honor, when you embrace her.", 
                "num": 8
              }, 
              {
                "text": "She will place on your head an ornament of grace; A crown of glory she will deliver to you.\"", 
                "num": 9
              }, 
              {
                "text": "Hear, my son, and receive my sayings, And the years of your life will be many.", 
                "num": 10
              }, 
              {
                "text": "I have taught you in the way of wisdom; I have led you in right paths.", 
                "num": 11
              }, 
              {
                "text": "When you walk, your steps will not be hindered, And when you run, you will not stumble.", 
                "num": 12
              }, 
              {
                "text": "Take firm hold of instruction, do not let go; Keep her, for she is your life.", 
                "num": 13
              }, 
              {
                "text": "Do not enter the path of the wicked, And do not walk in the way of evil.", 
                "num": 14
              }, 
              {
                "text": "Avoid it, do not travel on it; Turn away from it and pass on.", 
                "num": 15
              }, 
              {
                "text": "For they do not sleep unless they have done evil; And their sleep is taken away unless they make someone fall.", 
                "num": 16
              }, 
              {
                "text": "For they eat the bread of wickedness, And drink the wine of violence.", 
                "num": 17
              }, 
              {
                "text": "But the path of the just is like the shining sun, That shines ever brighter unto the perfect day.", 
                "num": 18
              }, 
              {
                "text": "The way of the wicked is like darkness; They do not know what makes them stumble.", 
                "num": 19
              }, 
              {
                "text": "My son, give attention to my words; Incline your ear to my sayings.", 
                "num": 20
              }, 
              {
                "text": "Do not let them depart from your eyes; Keep them in the midst of your heart;", 
                "num": 21
              }, 
              {
                "text": "For they are life to those who find them, And health to all their flesh.", 
                "num": 22
              }, 
              {
                "text": "Keep your heart with all diligence, For out of it spring the issues of life.", 
                "num": 23
              }, 
              {
                "text": "Put away from you a deceitful mouth, And put perverse lips far from you.", 
                "num": 24
              }, 
              {
                "text": "Let your eyes look straight ahead, And your eyelids look right before you.", 
                "num": 25
              }, 
              {
                "text": "Ponder the path of your feet, And let all your ways be established.", 
                "num": 26
              }, 
              {
                "text": "Do not turn to the right or the left; Remove your foot from evil.", 
                "num": 27
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "My son, pay attention to my wisdom; Lend your ear to my understanding,", 
                "num": 1
              }, 
              {
                "text": "That you may preserve discretion, And your lips may keep knowledge.", 
                "num": 2
              }, 
              {
                "text": "For the lips of an immoral woman drip honey, And her mouth is smoother than oil;", 
                "num": 3
              }, 
              {
                "text": "But in the end she is bitter as wormwood, Sharp as a two-edged sword.", 
                "num": 4
              }, 
              {
                "text": "Her feet go down to death, Her steps lay hold of hell.", 
                "num": 5
              }, 
              {
                "text": "Lest you ponder her path of life-- Her ways are unstable; You do not know them.", 
                "num": 6
              }, 
              {
                "text": "Therefore hear me now, my children, And do not depart from the words of my mouth.", 
                "num": 7
              }, 
              {
                "text": "Remove your way far from her, And do not go near the door of her house,", 
                "num": 8
              }, 
              {
                "text": "Lest you give your honor to others, And your years to the cruel one;", 
                "num": 9
              }, 
              {
                "text": "Lest aliens be filled with your wealth, And your labors go to the house of a foreigner;", 
                "num": 10
              }, 
              {
                "text": "And you mourn at last, When your flesh and your body are consumed,", 
                "num": 11
              }, 
              {
                "text": "And say: \"How I have hated instruction, And my heart despised correction!", 
                "num": 12
              }, 
              {
                "text": "I have not obeyed the voice of my teachers, Nor inclined my ear to those who instructed me!", 
                "num": 13
              }, 
              {
                "text": "I was on the verge of total ruin, In the midst of the assembly and congregation.\"", 
                "num": 14
              }, 
              {
                "text": "Drink water from your own cistern, And running water from your own well.", 
                "num": 15
              }, 
              {
                "text": "Should your fountains be dispersed abroad, Streams of water in the streets?", 
                "num": 16
              }, 
              {
                "text": "Let them be only your own, And not for strangers with you.", 
                "num": 17
              }, 
              {
                "text": "Let your fountain be blessed, And rejoice with the wife of your youth.", 
                "num": 18
              }, 
              {
                "text": "As a loving deer and a graceful doe, Let her breasts satisfy you at all times; And always be enraptured with her love.", 
                "num": 19
              }, 
              {
                "text": "For why should you, my son, be enraptured by an immoral woman, And be embraced in the arms of a seductress?", 
                "num": 20
              }, 
              {
                "text": "For the ways of man are before the eyes of the LORD, And He ponders all his paths.", 
                "num": 21
              }, 
              {
                "text": "His own iniquities entrap the wicked man, And he is caught in the cords of his sin.", 
                "num": 22
              }, 
              {
                "text": "He shall die for lack of instruction, And in the greatness of his folly he shall go astray.", 
                "num": 23
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "My son, if you become surety for your friend, If you have shaken hands in pledge for a stranger,", 
                "num": 1
              }, 
              {
                "text": "You are snared by the words of your mouth; You are taken by the words of your mouth.", 
                "num": 2
              }, 
              {
                "text": "So do this, my son, and deliver yourself; For you have come into the hand of your friend: Go and humble yourself; Plead with your friend.", 
                "num": 3
              }, 
              {
                "text": "Give no sleep to your eyes, Nor slumber to your eyelids.", 
                "num": 4
              }, 
              {
                "text": "Deliver yourself like a gazelle from the hand of the hunter, And like a bird from the hand of the fowler.", 
                "num": 5
              }, 
              {
                "text": "Go to the ant, you sluggard! Consider her ways and be wise,", 
                "num": 6
              }, 
              {
                "text": "Which, having no captain, Overseer or ruler,", 
                "num": 7
              }, 
              {
                "text": "Provides her supplies in the summer, And gathers her food in the harvest.", 
                "num": 8
              }, 
              {
                "text": "How long will you slumber, O sluggard? When will you rise from your sleep?", 
                "num": 9
              }, 
              {
                "text": "A little sleep, a little slumber, A little folding of the hands to sleep--", 
                "num": 10
              }, 
              {
                "text": "So shall your poverty come on you like a prowler, And your need like an armed man.", 
                "num": 11
              }, 
              {
                "text": "A worthless person, a wicked man, Walks with a perverse mouth;", 
                "num": 12
              }, 
              {
                "text": "He winks with his eyes, He shuffles his feet, He points with his fingers;", 
                "num": 13
              }, 
              {
                "text": "Perversity is in his heart, He devises evil continually, He sows discord.", 
                "num": 14
              }, 
              {
                "text": "Therefore his calamity shall come suddenly; Suddenly he shall be broken without remedy.", 
                "num": 15
              }, 
              {
                "text": "These six things the LORD hates, Yes, seven are an abomination to Him:", 
                "num": 16
              }, 
              {
                "text": "A proud look, A lying tongue, Hands that shed innocent blood,", 
                "num": 17
              }, 
              {
                "text": "A heart that devises wicked plans, Feet that are swift in running to evil,", 
                "num": 18
              }, 
              {
                "text": "A false witness who speaks lies, And one who sows discord among brethren.", 
                "num": 19
              }, 
              {
                "text": "My son, keep your father's command, And do not forsake the law of your mother.", 
                "num": 20
              }, 
              {
                "text": "Bind them continually upon your heart; Tie them around your neck.", 
                "num": 21
              }, 
              {
                "text": "When you roam, they will lead you; When you sleep, they will keep you; And when you awake, they will speak with you.", 
                "num": 22
              }, 
              {
                "text": "For the commandment is a lamp, And the law a light; Reproofs of instruction are the way of life,", 
                "num": 23
              }, 
              {
                "text": "To keep you from the evil woman, From the flattering tongue of a seductress.", 
                "num": 24
              }, 
              {
                "text": "Do not lust after her beauty in your heart, Nor let her allure you with her eyelids.", 
                "num": 25
              }, 
              {
                "text": "For by means of a harlot A man is reduced to a crust of bread; And an adulteress will prey upon his precious life.", 
                "num": 26
              }, 
              {
                "text": "Can a man take fire to his bosom, And his clothes not be burned?", 
                "num": 27
              }, 
              {
                "text": "Can one walk on hot coals, And his feet not be seared?", 
                "num": 28
              }, 
              {
                "text": "So is he who goes in to his neighbor's wife; Whoever touches her shall not be innocent.", 
                "num": 29
              }, 
              {
                "text": "People do not despise a thief If he steals to satisfy himself when he is starving.", 
                "num": 30
              }, 
              {
                "text": "Yet when he is found, he must restore sevenfold; He may have to give up all the substance of his house.", 
                "num": 31
              }, 
              {
                "text": "Whoever commits adultery with a woman lacks understanding; He who does so destroys his own soul.", 
                "num": 32
              }, 
              {
                "text": "Wounds and dishonor he will get, And his reproach will not be wiped away.", 
                "num": 33
              }, 
              {
                "text": "For jealousy is a husband's fury; Therefore he will not spare in the day of vengeance.", 
                "num": 34
              }, 
              {
                "text": "He will accept no recompense, Nor will he be appeased though you give many gifts.", 
                "num": 35
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "My son, keep my words, And treasure my commands within you.", 
                "num": 1
              }, 
              {
                "text": "Keep my commands and live, And my law as the apple of your eye.", 
                "num": 2
              }, 
              {
                "text": "Bind them on your fingers; Write them on the tablet of your heart.", 
                "num": 3
              }, 
              {
                "text": "Say to wisdom, \"You are my sister,\" And call understanding your nearest kin,", 
                "num": 4
              }, 
              {
                "text": "That they may keep you from the immoral woman, From the seductress who flatters with her words.", 
                "num": 5
              }, 
              {
                "text": "For at the window of my house I looked through my lattice,", 
                "num": 6
              }, 
              {
                "text": "And saw among the simple, I perceived among the youths, A young man devoid of understanding,", 
                "num": 7
              }, 
              {
                "text": "Passing along the street near her corner; And he took the path to her house", 
                "num": 8
              }, 
              {
                "text": "In the twilight, in the evening, In the black and dark night.", 
                "num": 9
              }, 
              {
                "text": "And there a woman met him, With the attire of a harlot, and a crafty heart.", 
                "num": 10
              }, 
              {
                "text": "She was loud and rebellious, Her feet would not stay at home.", 
                "num": 11
              }, 
              {
                "text": "At times she was outside, at times in the open square, Lurking at every corner.", 
                "num": 12
              }, 
              {
                "text": "So she caught him and kissed him; With an impudent face she said to him:", 
                "num": 13
              }, 
              {
                "text": "\"I have peace offerings with me; Today I have paid my vows.", 
                "num": 14
              }, 
              {
                "text": "So I came out to meet you, Diligently to seek your face, And I have found you.", 
                "num": 15
              }, 
              {
                "text": "I have spread my bed with tapestry, Colored coverings of Egyptian linen.", 
                "num": 16
              }, 
              {
                "text": "I have perfumed my bed With myrrh, aloes, and cinnamon.", 
                "num": 17
              }, 
              {
                "text": "Come, let us take our fill of love until morning; Let us delight ourselves with love.", 
                "num": 18
              }, 
              {
                "text": "For my husband is not at home; He has gone on a long journey;", 
                "num": 19
              }, 
              {
                "text": "He has taken a bag of money with him, And will come home on the appointed day.\"", 
                "num": 20
              }, 
              {
                "text": "With her enticing speech she caused him to yield, With her flattering lips she seduced him.", 
                "num": 21
              }, 
              {
                "text": "Immediately he went after her, as an ox goes to the slaughter, Or as a fool to the correction of the stocks,", 
                "num": 22
              }, 
              {
                "text": "Till an arrow struck his liver. As a bird hastens to the snare, He did not know it would cost his life.", 
                "num": 23
              }, 
              {
                "text": "Now therefore, listen to me, my children; Pay attention to the words of my mouth:", 
                "num": 24
              }, 
              {
                "text": "Do not let your heart turn aside to her ways, Do not stray into her paths;", 
                "num": 25
              }, 
              {
                "text": "For she has cast down many wounded, And all who were slain by her were strong men.", 
                "num": 26
              }, 
              {
                "text": "Her house is the way to hell, Descending to the chambers of death.", 
                "num": 27
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Does not wisdom cry out, And understanding lift up her voice?", 
                "num": 1
              }, 
              {
                "text": "She takes her stand on the top of the high hill, Beside the way, where the paths meet.", 
                "num": 2
              }, 
              {
                "text": "She cries out by the gates, at the entry of the city, At the entrance of the doors:", 
                "num": 3
              }, 
              {
                "text": "\"To you, O men, I call, And my voice is to the sons of men.", 
                "num": 4
              }, 
              {
                "text": "O you simple ones, understand prudence, And you fools, be of an understanding heart.", 
                "num": 5
              }, 
              {
                "text": "Listen, for I will speak of excellent things, And from the opening of my lips will come right things;", 
                "num": 6
              }, 
              {
                "text": "For my mouth will speak truth; Wickedness is an abomination to my lips.", 
                "num": 7
              }, 
              {
                "text": "All the words of my mouth are with righteousness; Nothing crooked or perverse is in them.", 
                "num": 8
              }, 
              {
                "text": "They are all plain to him who understands, And right to those who find knowledge.", 
                "num": 9
              }, 
              {
                "text": "Receive my instruction, and not silver, And knowledge rather than choice gold;", 
                "num": 10
              }, 
              {
                "text": "For wisdom is better than rubies, And all the things one may desire cannot be compared with her.", 
                "num": 11
              }, 
              {
                "text": "\"I, wisdom, dwell with prudence, And find out knowledge and discretion.", 
                "num": 12
              }, 
              {
                "text": "The fear of the LORD is to hate evil; Pride and arrogance and the evil way And the perverse mouth I hate.", 
                "num": 13
              }, 
              {
                "text": "Counsel is mine, and sound wisdom; I am understanding, I have strength.", 
                "num": 14
              }, 
              {
                "text": "By me kings reign, And rulers decree justice.", 
                "num": 15
              }, 
              {
                "text": "By me princes rule, and nobles, All the judges of the earth.", 
                "num": 16
              }, 
              {
                "text": "I love those who love me, And those who seek me diligently will find me.", 
                "num": 17
              }, 
              {
                "text": "Riches and honor are with me, Enduring riches and righteousness.", 
                "num": 18
              }, 
              {
                "text": "My fruit is better than gold, yes, than fine gold, And my revenue than choice silver.", 
                "num": 19
              }, 
              {
                "text": "I traverse the way of righteousness, In the midst of the paths of justice,", 
                "num": 20
              }, 
              {
                "text": "That I may cause those who love me to inherit wealth, That I may fill their treasuries.", 
                "num": 21
              }, 
              {
                "text": "\"The LORD possessed me at the beginning of His way, Before His works of old.", 
                "num": 22
              }, 
              {
                "text": "I have been established from everlasting, From the beginning, before there was ever an earth.", 
                "num": 23
              }, 
              {
                "text": "When there were no depths I was brought forth, When there were no fountains abounding with water.", 
                "num": 24
              }, 
              {
                "text": "Before the mountains were settled, Before the hills, I was brought forth;", 
                "num": 25
              }, 
              {
                "text": "While as yet He had not made the earth or the fields, Or the primal dust of the world.", 
                "num": 26
              }, 
              {
                "text": "When He prepared the heavens, I was there, When He drew a circle on the face of the deep,", 
                "num": 27
              }, 
              {
                "text": "When He established the clouds above, When He strengthened the fountains of the deep,", 
                "num": 28
              }, 
              {
                "text": "When He assigned to the sea its limit, So that the waters would not transgress His command, When He marked out the foundations of the earth,", 
                "num": 29
              }, 
              {
                "text": "Then I was beside Him as a master craftsman; And I was daily His delight, Rejoicing always before Him,", 
                "num": 30
              }, 
              {
                "text": "Rejoicing in His inhabited world, And my delight was with the sons of men.", 
                "num": 31
              }, 
              {
                "text": "\"Now therefore, listen to me, my children, For blessed are those who keep my ways.", 
                "num": 32
              }, 
              {
                "text": "Hear instruction and be wise, And do not disdain it.", 
                "num": 33
              }, 
              {
                "text": "Blessed is the man who listens to me, Watching daily at my gates, Waiting at the posts of my doors.", 
                "num": 34
              }, 
              {
                "text": "For whoever finds me finds life, And obtains favor from the LORD;", 
                "num": 35
              }, 
              {
                "text": "But he who sins against me wrongs his own soul; All those who hate me love death.\"", 
                "num": 36
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Wisdom has built her house, She has hewn out her seven pillars;", 
                "num": 1
              }, 
              {
                "text": "She has slaughtered her meat, She has mixed her wine, She has also furnished her table.", 
                "num": 2
              }, 
              {
                "text": "She has sent out her maidens, She cries out from the highest places of the city,", 
                "num": 3
              }, 
              {
                "text": "\"Whoever is simple, let him turn in here!\" As for him who lacks understanding, she says to him,", 
                "num": 4
              }, 
              {
                "text": "\"Come, eat of my bread And drink of the wine I have mixed.", 
                "num": 5
              }, 
              {
                "text": "Forsake foolishness and live, And go in the way of understanding.", 
                "num": 6
              }, 
              {
                "text": "\"He who corrects a scoffer gets shame for himself, And he who rebukes a wicked man only harms himself.", 
                "num": 7
              }, 
              {
                "text": "Do not correct a scoffer, lest he hate you; Rebuke a wise man, and he will love you.", 
                "num": 8
              }, 
              {
                "text": "Give instruction to a wise man, and he will be still wiser; Teach a just man, and he will increase in learning.", 
                "num": 9
              }, 
              {
                "text": "\"The fear of the LORD is the beginning of wisdom, And the knowledge of the Holy One is understanding.", 
                "num": 10
              }, 
              {
                "text": "For by me your days will be multiplied, And years of life will be added to you.", 
                "num": 11
              }, 
              {
                "text": "If you are wise, you are wise for yourself, And if you scoff, you will bear it alone.\"", 
                "num": 12
              }, 
              {
                "text": "A foolish woman is clamorous; She is simple, and knows nothing.", 
                "num": 13
              }, 
              {
                "text": "For she sits at the door of her house, On a seat by the highest places of the city,", 
                "num": 14
              }, 
              {
                "text": "To call to those who pass by, Who go straight on their way:", 
                "num": 15
              }, 
              {
                "text": "\"Whoever is simple, let him turn in here\"; And as for him who lacks understanding, she says to him,", 
                "num": 16
              }, 
              {
                "text": "\"Stolen water is sweet, And bread eaten in secret is pleasant.\"", 
                "num": 17
              }, 
              {
                "text": "But he does not know that the dead are there, That her guests are in the depths of hell.", 
                "num": 18
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "The proverbs of Solomon: A wise son makes a glad father, But a foolish son is the grief of his mother.", 
                "num": 1
              }, 
              {
                "text": "Treasures of wickedness profit nothing, But righteousness delivers from death.", 
                "num": 2
              }, 
              {
                "text": "The LORD will not allow the righteous soul to famish, But He casts away the desire of the wicked.", 
                "num": 3
              }, 
              {
                "text": "He who has a slack hand becomes poor, But the hand of the diligent makes rich.", 
                "num": 4
              }, 
              {
                "text": "He who gathers in summer is a wise son; He who sleeps in harvest is a son who causes shame.", 
                "num": 5
              }, 
              {
                "text": "Blessings are on the head of the righteous, But violence covers the mouth of the wicked.", 
                "num": 6
              }, 
              {
                "text": "The memory of the righteous is blessed, But the name of the wicked will rot.", 
                "num": 7
              }, 
              {
                "text": "The wise in heart will receive commands, But a prating fool will fall.", 
                "num": 8
              }, 
              {
                "text": "He who walks with integrity walks securely, But he who perverts his ways will become known.", 
                "num": 9
              }, 
              {
                "text": "He who winks with the eye causes trouble, But a prating fool will fall.", 
                "num": 10
              }, 
              {
                "text": "The mouth of the righteous is a well of life, But violence covers the mouth of the wicked.", 
                "num": 11
              }, 
              {
                "text": "Hatred stirs up strife, But love covers all sins.", 
                "num": 12
              }, 
              {
                "text": "Wisdom is found on the lips of him who has understanding, But a rod is for the back of him who is devoid of understanding.", 
                "num": 13
              }, 
              {
                "text": "Wise people store up knowledge, But the mouth of the foolish is near destruction.", 
                "num": 14
              }, 
              {
                "text": "The rich man's wealth is his strong city; The destruction of the poor is their poverty.", 
                "num": 15
              }, 
              {
                "text": "The labor of the righteous leads to life, The wages of the wicked to sin.", 
                "num": 16
              }, 
              {
                "text": "He who keeps instruction is in the way of life, But he who refuses correction goes astray.", 
                "num": 17
              }, 
              {
                "text": "Whoever hides hatred has lying lips, And whoever spreads slander is a fool.", 
                "num": 18
              }, 
              {
                "text": "In the multitude of words sin is not lacking, But he who restrains his lips is wise.", 
                "num": 19
              }, 
              {
                "text": "The tongue of the righteous is choice silver; The heart of the wicked is worth little.", 
                "num": 20
              }, 
              {
                "text": "The lips of the righteous feed many, But fools die for lack of wisdom.", 
                "num": 21
              }, 
              {
                "text": "The blessing of the LORD makes one rich, And He adds no sorrow with it.", 
                "num": 22
              }, 
              {
                "text": "To do evil is like sport to a fool, But a man of understanding has wisdom.", 
                "num": 23
              }, 
              {
                "text": "The fear of the wicked will come upon him, And the desire of the righteous will be granted.", 
                "num": 24
              }, 
              {
                "text": "When the whirlwind passes by, the wicked is no more, But the righteous has an everlasting foundation.", 
                "num": 25
              }, 
              {
                "text": "As vinegar to the teeth and smoke to the eyes, So is the lazy man to those who send him.", 
                "num": 26
              }, 
              {
                "text": "The fear of the LORD prolongs days, But the years of the wicked will be shortened.", 
                "num": 27
              }, 
              {
                "text": "The hope of the righteous will be gladness, But the expectation of the wicked will perish.", 
                "num": 28
              }, 
              {
                "text": "The way of the LORD is strength for the upright, But destruction will come to the workers of iniquity.", 
                "num": 29
              }, 
              {
                "text": "The righteous will never be removed, But the wicked will not inhabit the earth.", 
                "num": 30
              }, 
              {
                "text": "The mouth of the righteous brings forth wisdom, But the perverse tongue will be cut out.", 
                "num": 31
              }, 
              {
                "text": "The lips of the righteous know what is acceptable, But the mouth of the wicked what is perverse.", 
                "num": 32
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Dishonest scales are an abomination to the LORD, But a just weight is His delight.", 
                "num": 1
              }, 
              {
                "text": "When pride comes, then comes shame; But with the humble is wisdom.", 
                "num": 2
              }, 
              {
                "text": "The integrity of the upright will guide them, But the perversity of the unfaithful will destroy them.", 
                "num": 3
              }, 
              {
                "text": "Riches do not profit in the day of wrath, But righteousness delivers from death.", 
                "num": 4
              }, 
              {
                "text": "The righteousness of the blameless will direct his way aright, But the wicked will fall by his own wickedness.", 
                "num": 5
              }, 
              {
                "text": "The righteousness of the upright will deliver them, But the unfaithful will be caught by their lust.", 
                "num": 6
              }, 
              {
                "text": "When a wicked man dies, his expectation will perish, And the hope of the unjust perishes.", 
                "num": 7
              }, 
              {
                "text": "The righteous is delivered from trouble, And it comes to the wicked instead.", 
                "num": 8
              }, 
              {
                "text": "The hypocrite with his mouth destroys his neighbor, But through knowledge the righteous will be delivered.", 
                "num": 9
              }, 
              {
                "text": "When it goes well with the righteous, the city rejoices; And when the wicked perish, there is jubilation.", 
                "num": 10
              }, 
              {
                "text": "By the blessing of the upright the city is exalted, But it is overthrown by the mouth of the wicked.", 
                "num": 11
              }, 
              {
                "text": "He who is devoid of wisdom despises his neighbor, But a man of understanding holds his peace.", 
                "num": 12
              }, 
              {
                "text": "A talebearer reveals secrets, But he who is of a faithful spirit conceals a matter.", 
                "num": 13
              }, 
              {
                "text": "Where there is no counsel, the people fall; But in the multitude of counselors there is safety.", 
                "num": 14
              }, 
              {
                "text": "He who is surety for a stranger will suffer, But one who hates being surety is secure.", 
                "num": 15
              }, 
              {
                "text": "A gracious woman retains honor, But ruthless men retain riches.", 
                "num": 16
              }, 
              {
                "text": "The merciful man does good for his own soul, But he who is cruel troubles his own flesh.", 
                "num": 17
              }, 
              {
                "text": "The wicked man does deceptive work, But he who sows righteousness will have a sure reward.", 
                "num": 18
              }, 
              {
                "text": "As righteousness leads to life, So he who pursues evil pursues it to his own death.", 
                "num": 19
              }, 
              {
                "text": "Those who are of a perverse heart are an abomination to the LORD, But the blameless in their ways are His delight.", 
                "num": 20
              }, 
              {
                "text": "Though they join forces, the wicked will not go unpunished; But the posterity of the righteous will be delivered.", 
                "num": 21
              }, 
              {
                "text": "As a ring of gold in a swine's snout, So is a lovely woman who lacks discretion.", 
                "num": 22
              }, 
              {
                "text": "The desire of the righteous is only good, But the expectation of the wicked is wrath.", 
                "num": 23
              }, 
              {
                "text": "There is one who scatters, yet increases more; And there is one who withholds more than is right, But it leads to poverty.", 
                "num": 24
              }, 
              {
                "text": "The generous soul will be made rich, And he who waters will also be watered himself.", 
                "num": 25
              }, 
              {
                "text": "The people will curse him who withholds grain, But blessing will be on the head of him who sells it.", 
                "num": 26
              }, 
              {
                "text": "He who earnestly seeks good finds favor, But trouble will come to him who seeks evil.", 
                "num": 27
              }, 
              {
                "text": "He who trusts in his riches will fall, But the righteous will flourish like foliage.", 
                "num": 28
              }, 
              {
                "text": "He who troubles his own house will inherit the wind, And the fool will be servant to the wise of heart.", 
                "num": 29
              }, 
              {
                "text": "The fruit of the righteous is a tree of life, And he who wins souls is wise.", 
                "num": 30
              }, 
              {
                "text": "If the righteous will be recompensed on the earth, How much more the ungodly and the sinner.", 
                "num": 31
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Whoever loves instruction loves knowledge, But he who hates correction is stupid.", 
                "num": 1
              }, 
              {
                "text": "A good man obtains favor from the LORD, But a man of wicked intentions He will condemn.", 
                "num": 2
              }, 
              {
                "text": "A man is not established by wickedness, But the root of the righteous cannot be moved.", 
                "num": 3
              }, 
              {
                "text": "An excellent wife is the crown of her husband, But she who causes shame is like rottenness in his bones.", 
                "num": 4
              }, 
              {
                "text": "The thoughts of the righteous are right, But the counsels of the wicked are deceitful.", 
                "num": 5
              }, 
              {
                "text": "The words of the wicked are, \"Lie in wait for blood,\" But the mouth of the upright will deliver them.", 
                "num": 6
              }, 
              {
                "text": "The wicked are overthrown and are no more, But the house of the righteous will stand.", 
                "num": 7
              }, 
              {
                "text": "A man will be commended according to his wisdom, But he who is of a perverse heart will be despised.", 
                "num": 8
              }, 
              {
                "text": "Better is the one who is slighted but has a servant, Than he who honors himself but lacks bread.", 
                "num": 9
              }, 
              {
                "text": "A righteous man regards the life of his animal, But the tender mercies of the wicked are cruel.", 
                "num": 10
              }, 
              {
                "text": "He who tills his land will be satisfied with bread, But he who follows frivolity is devoid of understanding.", 
                "num": 11
              }, 
              {
                "text": "The wicked covet the catch of evil men, But the root of the righteous yields fruit.", 
                "num": 12
              }, 
              {
                "text": "The wicked is ensnared by the transgression of his lips, But the righteous will come through trouble.", 
                "num": 13
              }, 
              {
                "text": "A man will be satisfied with good by the fruit of his mouth, And the recompense of a man's hands will be rendered to him.", 
                "num": 14
              }, 
              {
                "text": "The way of a fool is right in his own eyes, But he who heeds counsel is wise.", 
                "num": 15
              }, 
              {
                "text": "A fool's wrath is known at once, But a prudent man covers shame.", 
                "num": 16
              }, 
              {
                "text": "He who speaks truth declares righteousness, But a false witness, deceit.", 
                "num": 17
              }, 
              {
                "text": "There is one who speaks like the piercings of a sword, But the tongue of the wise promotes health.", 
                "num": 18
              }, 
              {
                "text": "The truthful lip shall be established forever, But a lying tongue is but for a moment.", 
                "num": 19
              }, 
              {
                "text": "Deceit is in the heart of those who devise evil, But counselors of peace have joy.", 
                "num": 20
              }, 
              {
                "text": "No grave trouble will overtake the righteous, But the wicked shall be filled with evil.", 
                "num": 21
              }, 
              {
                "text": "Lying lips are an abomination to the LORD, But those who deal truthfully are His delight.", 
                "num": 22
              }, 
              {
                "text": "A prudent man conceals knowledge, But the heart of fools proclaims foolishness.", 
                "num": 23
              }, 
              {
                "text": "The hand of the diligent will rule, But the lazy man will be put to forced labor.", 
                "num": 24
              }, 
              {
                "text": "Anxiety in the heart of man causes depression, But a good word makes it glad.", 
                "num": 25
              }, 
              {
                "text": "The righteous should choose his friends carefully, For the way of the wicked leads them astray.", 
                "num": 26
              }, 
              {
                "text": "The lazy man does not roast what he took in hunting, But diligence is man's precious possession.", 
                "num": 27
              }, 
              {
                "text": "In the way of righteousness is life, And in its pathway there is no death.", 
                "num": 28
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "A wise son heeds his father's instruction, But a scoffer does not listen to rebuke.", 
                "num": 1
              }, 
              {
                "text": "A man shall eat well by the fruit of his mouth, But the soul of the unfaithful feeds on violence.", 
                "num": 2
              }, 
              {
                "text": "He who guards his mouth preserves his life, But he who opens wide his lips shall have destruction.", 
                "num": 3
              }, 
              {
                "text": "The soul of a lazy man desires, and has nothing; But the soul of the diligent shall be made rich.", 
                "num": 4
              }, 
              {
                "text": "A righteous man hates lying, But a wicked man is loathsome and comes to shame.", 
                "num": 5
              }, 
              {
                "text": "Righteousness guards him whose way is blameless, But wickedness overthrows the sinner.", 
                "num": 6
              }, 
              {
                "text": "There is one who makes himself rich, yet has nothing; And one who makes himself poor, yet has great riches.", 
                "num": 7
              }, 
              {
                "text": "The ransom of a man's life is his riches, But the poor does not hear rebuke.", 
                "num": 8
              }, 
              {
                "text": "The light of the righteous rejoices, But the lamp of the wicked will be put out.", 
                "num": 9
              }, 
              {
                "text": "By pride comes nothing but strife, But with the well-advised is wisdom.", 
                "num": 10
              }, 
              {
                "text": "Wealth gained by dishonesty will be diminished, But he who gathers by labor will increase.", 
                "num": 11
              }, 
              {
                "text": "Hope deferred makes the heart sick, But when the desire comes, it is a tree of life.", 
                "num": 12
              }, 
              {
                "text": "He who despises the word will be destroyed, But he who fears the commandment will be rewarded.", 
                "num": 13
              }, 
              {
                "text": "The law of the wise is a fountain of life, To turn one away from the snares of death.", 
                "num": 14
              }, 
              {
                "text": "Good understanding gains favor, But the way of the unfaithful is hard.", 
                "num": 15
              }, 
              {
                "text": "Every prudent man acts with knowledge, But a fool lays open his folly.", 
                "num": 16
              }, 
              {
                "text": "A wicked messenger falls into trouble, But a faithful ambassador brings health.", 
                "num": 17
              }, 
              {
                "text": "Poverty and shame will come to him who disdains correction, But he who regards a rebuke will be honored.", 
                "num": 18
              }, 
              {
                "text": "A desire accomplished is sweet to the soul, But it is an abomination to fools to depart from evil.", 
                "num": 19
              }, 
              {
                "text": "He who walks with wise men will be wise, But the companion of fools will be destroyed.", 
                "num": 20
              }, 
              {
                "text": "Evil pursues sinners, But to the righteous, good shall be repaid.", 
                "num": 21
              }, 
              {
                "text": "A good man leaves an inheritance to his children's children, But the wealth of the sinner is stored up for the righteous.", 
                "num": 22
              }, 
              {
                "text": "Much food is in the fallow ground of the poor, And for lack of justice there is waste.", 
                "num": 23
              }, 
              {
                "text": "He who spares his rod hates his son, But he who loves him disciplines him promptly.", 
                "num": 24
              }, 
              {
                "text": "The righteous eats to the satisfying of his soul, But the stomach of the wicked shall be in want.", 
                "num": 25
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "The wise woman builds her house, But the foolish pulls it down with her hands.", 
                "num": 1
              }, 
              {
                "text": "He who walks in his uprightness fears the LORD, But he who is perverse in his ways despises Him.", 
                "num": 2
              }, 
              {
                "text": "In the mouth of a fool is a rod of pride, But the lips of the wise will preserve them.", 
                "num": 3
              }, 
              {
                "text": "Where no oxen are, the trough is clean; But much increase comes by the strength of an ox.", 
                "num": 4
              }, 
              {
                "text": "A faithful witness does not lie, But a false witness will utter lies.", 
                "num": 5
              }, 
              {
                "text": "A scoffer seeks wisdom and does not find it, But knowledge is easy to him who understands.", 
                "num": 6
              }, 
              {
                "text": "Go from the presence of a foolish man, When you do not perceive in him the lips of knowledge.", 
                "num": 7
              }, 
              {
                "text": "The wisdom of the prudent is to understand his way, But the folly of fools is deceit.", 
                "num": 8
              }, 
              {
                "text": "Fools mock at sin, But among the upright there is favor.", 
                "num": 9
              }, 
              {
                "text": "The heart knows its own bitterness, And a stranger does not share its joy.", 
                "num": 10
              }, 
              {
                "text": "The house of the wicked will be overthrown, But the tent of the upright will flourish.", 
                "num": 11
              }, 
              {
                "text": "There is a way that seems right to a man, But its end is the way of death.", 
                "num": 12
              }, 
              {
                "text": "Even in laughter the heart may sorrow, And the end of mirth may be grief.", 
                "num": 13
              }, 
              {
                "text": "The backslider in heart will be filled with his own ways, But a good man will be satisfied from above.", 
                "num": 14
              }, 
              {
                "text": "The simple believes every word, But the prudent considers well his steps.", 
                "num": 15
              }, 
              {
                "text": "A wise man fears and departs from evil, But a fool rages and is self-confident.", 
                "num": 16
              }, 
              {
                "text": "A quick-tempered man acts foolishly, And a man of wicked intentions is hated.", 
                "num": 17
              }, 
              {
                "text": "The simple inherit folly, But the prudent are crowned with knowledge.", 
                "num": 18
              }, 
              {
                "text": "The evil will bow before the good, And the wicked at the gates of the righteous.", 
                "num": 19
              }, 
              {
                "text": "The poor man is hated even by his own neighbor, But the rich has many friends.", 
                "num": 20
              }, 
              {
                "text": "He who despises his neighbor sins; But he who has mercy on the poor, happy is he.", 
                "num": 21
              }, 
              {
                "text": "Do they not go astray who devise evil? But mercy and truth belong to those who devise good.", 
                "num": 22
              }, 
              {
                "text": "In all labor there is profit, But idle chatter leads only to poverty.", 
                "num": 23
              }, 
              {
                "text": "The crown of the wise is their riches, But the foolishness of fools is folly.", 
                "num": 24
              }, 
              {
                "text": "A true witness delivers souls, But a deceitful witness speaks lies.", 
                "num": 25
              }, 
              {
                "text": "In the fear of the LORD there is strong confidence, And His children will have a place of refuge.", 
                "num": 26
              }, 
              {
                "text": "The fear of the LORD is a fountain of life, To turn one away from the snares of death.", 
                "num": 27
              }, 
              {
                "text": "In a multitude of people is a king's honor, But in the lack of people is the downfall of a prince.", 
                "num": 28
              }, 
              {
                "text": "He who is slow to wrath has great understanding, But he who is impulsive exalts folly.", 
                "num": 29
              }, 
              {
                "text": "A sound heart is life to the body, But envy is rottenness to the bones.", 
                "num": 30
              }, 
              {
                "text": "He who oppresses the poor reproaches his Maker, But he who honors Him has mercy on the needy.", 
                "num": 31
              }, 
              {
                "text": "The wicked is banished in his wickedness, But the righteous has a refuge in his death.", 
                "num": 32
              }, 
              {
                "text": "Wisdom rests in the heart of him who has understanding, But what is in the heart of fools is made known.", 
                "num": 33
              }, 
              {
                "text": "Righteousness exalts a nation, But sin is a reproach to any people.", 
                "num": 34
              }, 
              {
                "text": "The king's favor is toward a wise servant, But his wrath is against him who causes shame.", 
                "num": 35
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "A soft answer turns away wrath, But a harsh word stirs up anger.", 
                "num": 1
              }, 
              {
                "text": "The tongue of the wise uses knowledge rightly, But the mouth of fools pours forth foolishness.", 
                "num": 2
              }, 
              {
                "text": "The eyes of the LORD are in every place, Keeping watch on the evil and the good.", 
                "num": 3
              }, 
              {
                "text": "A wholesome tongue is a tree of life, But perverseness in it breaks the spirit.", 
                "num": 4
              }, 
              {
                "text": "A fool despises his father's instruction, But he who receives correction is prudent.", 
                "num": 5
              }, 
              {
                "text": "In the house of the righteous there is much treasure, But in the revenue of the wicked is trouble.", 
                "num": 6
              }, 
              {
                "text": "The lips of the wise disperse knowledge, But the heart of the fool does not do so.", 
                "num": 7
              }, 
              {
                "text": "The sacrifice of the wicked is an abomination to the LORD, But the prayer of the upright is His delight.", 
                "num": 8
              }, 
              {
                "text": "The way of the wicked is an abomination to the LORD, But He loves him who follows righteousness.", 
                "num": 9
              }, 
              {
                "text": "Harsh discipline is for him who forsakes the way, And he who hates correction will die.", 
                "num": 10
              }, 
              {
                "text": "Hell and Destruction are before the LORD; So how much more the hearts of the sons of men.", 
                "num": 11
              }, 
              {
                "text": "A scoffer does not love one who corrects him, Nor will he go to the wise.", 
                "num": 12
              }, 
              {
                "text": "A merry heart makes a cheerful countenance, But by sorrow of the heart the spirit is broken.", 
                "num": 13
              }, 
              {
                "text": "The heart of him who has understanding seeks knowledge, But the mouth of fools feeds on foolishness.", 
                "num": 14
              }, 
              {
                "text": "All the days of the afflicted are evil, But he who is of a merry heart has a continual feast.", 
                "num": 15
              }, 
              {
                "text": "Better is a little with the fear of the LORD, Than great treasure with trouble.", 
                "num": 16
              }, 
              {
                "text": "Better is a dinner of herbs where love is, Than a fatted calf with hatred.", 
                "num": 17
              }, 
              {
                "text": "A wrathful man stirs up strife, But he who is slow to anger allays contention.", 
                "num": 18
              }, 
              {
                "text": "The way of the lazy man is like a hedge of thorns, But the way of the upright is a highway.", 
                "num": 19
              }, 
              {
                "text": "A wise son makes a father glad, But a foolish man despises his mother.", 
                "num": 20
              }, 
              {
                "text": "Folly is joy to him who is destitute of discernment, But a man of understanding walks uprightly.", 
                "num": 21
              }, 
              {
                "text": "Without counsel, plans go awry, But in the multitude of counselors they are established.", 
                "num": 22
              }, 
              {
                "text": "A man has joy by the answer of his mouth, And a word spoken in due season, how good it is!", 
                "num": 23
              }, 
              {
                "text": "The way of life winds upward for the wise, That he may turn away from hell below.", 
                "num": 24
              }, 
              {
                "text": "The LORD will destroy the house of the proud, But He will establish the boundary of the widow.", 
                "num": 25
              }, 
              {
                "text": "The thoughts of the wicked are an abomination to the LORD, But the words of the pure are pleasant.", 
                "num": 26
              }, 
              {
                "text": "He who is greedy for gain troubles his own house, But he who hates bribes will live.", 
                "num": 27
              }, 
              {
                "text": "The heart of the righteous studies how to answer, But the mouth of the wicked pours forth evil.", 
                "num": 28
              }, 
              {
                "text": "The LORD is far from the wicked, But He hears the prayer of the righteous.", 
                "num": 29
              }, 
              {
                "text": "The light of the eyes rejoices the heart, And a good report makes the bones healthy.", 
                "num": 30
              }, 
              {
                "text": "The ear that hears the rebukes of life Will abide among the wise.", 
                "num": 31
              }, 
              {
                "text": "He who disdains instruction despises his own soul, But he who heeds rebuke gets understanding.", 
                "num": 32
              }, 
              {
                "text": "The fear of the LORD is the instruction of wisdom, And before honor is humility.", 
                "num": 33
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "The preparations of the heart belong to man, But the answer of the tongue is from the LORD.", 
                "num": 1
              }, 
              {
                "text": "All the ways of a man are pure in his own eyes, But the LORD weighs the spirits.", 
                "num": 2
              }, 
              {
                "text": "Commit your works to the LORD, And your thoughts will be established.", 
                "num": 3
              }, 
              {
                "text": "The LORD has made all for Himself, Yes, even the wicked for the day of doom.", 
                "num": 4
              }, 
              {
                "text": "Everyone proud in heart is an abomination to the LORD; Though they join forces, none will go unpunished.", 
                "num": 5
              }, 
              {
                "text": "In mercy and truth Atonement is provided for iniquity; And by the fear of the LORD one departs from evil.", 
                "num": 6
              }, 
              {
                "text": "When a man's ways please the LORD, He makes even his enemies to be at peace with him.", 
                "num": 7
              }, 
              {
                "text": "Better is a little with righteousness, Than vast revenues without justice.", 
                "num": 8
              }, 
              {
                "text": "A man's heart plans his way, But the LORD directs his steps.", 
                "num": 9
              }, 
              {
                "text": "Divination is on the lips of the king; His mouth must not transgress in judgment.", 
                "num": 10
              }, 
              {
                "text": "Honest weights and scales are the LORD's; All the weights in the bag are His work.", 
                "num": 11
              }, 
              {
                "text": "It is an abomination for kings to commit wickedness, For a throne is established by righteousness.", 
                "num": 12
              }, 
              {
                "text": "Righteous lips are the delight of kings, And they love him who speaks what is right.", 
                "num": 13
              }, 
              {
                "text": "As messengers of death is the king's wrath, But a wise man will appease it.", 
                "num": 14
              }, 
              {
                "text": "In the light of the king's face is life, And his favor is like a cloud of the latter rain.", 
                "num": 15
              }, 
              {
                "text": "How much better to get wisdom than gold! And to get understanding is to be chosen rather than silver.", 
                "num": 16
              }, 
              {
                "text": "The highway of the upright is to depart from evil; He who keeps his way preserves his soul.", 
                "num": 17
              }, 
              {
                "text": "Pride goes before destruction, And a haughty spirit before a fall.", 
                "num": 18
              }, 
              {
                "text": "Better to be of a humble spirit with the lowly, Than to divide the spoil with the proud.", 
                "num": 19
              }, 
              {
                "text": "He who heeds the word wisely will find good, And whoever trusts in the LORD, happy is he.", 
                "num": 20
              }, 
              {
                "text": "The wise in heart will be called prudent, And sweetness of the lips increases learning.", 
                "num": 21
              }, 
              {
                "text": "Understanding is a wellspring of life to him who has it. But the correction of fools is folly.", 
                "num": 22
              }, 
              {
                "text": "The heart of the wise teaches his mouth, And adds learning to his lips.", 
                "num": 23
              }, 
              {
                "text": "Pleasant words are like a honeycomb, Sweetness to the soul and health to the bones.", 
                "num": 24
              }, 
              {
                "text": "There is a way that seems right to a man, But its end is the way of death.", 
                "num": 25
              }, 
              {
                "text": "The person who labors, labors for himself, For his hungry mouth drives him on.", 
                "num": 26
              }, 
              {
                "text": "An ungodly man digs up evil, And it is on his lips like a burning fire.", 
                "num": 27
              }, 
              {
                "text": "A perverse man sows strife, And a whisperer separates the best of friends.", 
                "num": 28
              }, 
              {
                "text": "A violent man entices his neighbor, And leads him in a way that is not good.", 
                "num": 29
              }, 
              {
                "text": "He winks his eye to devise perverse things; He purses his lips and brings about evil.", 
                "num": 30
              }, 
              {
                "text": "The silver-haired head is a crown of glory, If it is found in the way of righteousness.", 
                "num": 31
              }, 
              {
                "text": "He who is slow to anger is better than the mighty, And he who rules his spirit than he who takes a city.", 
                "num": 32
              }, 
              {
                "text": "The lot is cast into the lap, But its every decision is from the LORD.", 
                "num": 33
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Better is a dry morsel with quietness, Than a house full of feasting with strife.", 
                "num": 1
              }, 
              {
                "text": "A wise servant will rule over a son who causes shame, And will share an inheritance among the brothers.", 
                "num": 2
              }, 
              {
                "text": "The refining pot is for silver and the furnace for gold, But the LORD tests the hearts.", 
                "num": 3
              }, 
              {
                "text": "An evildoer gives heed to false lips; A liar listens eagerly to a spiteful tongue.", 
                "num": 4
              }, 
              {
                "text": "He who mocks the poor reproaches his Maker; He who is glad at calamity will not go unpunished.", 
                "num": 5
              }, 
              {
                "text": "Children's children are the crown of old men, And the glory of children is their father.", 
                "num": 6
              }, 
              {
                "text": "Excellent speech is not becoming to a fool, Much less lying lips to a prince.", 
                "num": 7
              }, 
              {
                "text": "A present is a precious stone in the eyes of its possessor; Wherever he turns, he prospers.", 
                "num": 8
              }, 
              {
                "text": "He who covers a transgression seeks love, But he who repeats a matter separates friends.", 
                "num": 9
              }, 
              {
                "text": "Rebuke is more effective for a wise man Than a hundred blows on a fool.", 
                "num": 10
              }, 
              {
                "text": "An evil man seeks only rebellion; Therefore a cruel messenger will be sent against him.", 
                "num": 11
              }, 
              {
                "text": "Let a man meet a bear robbed of her cubs, Rather than a fool in his folly.", 
                "num": 12
              }, 
              {
                "text": "Whoever rewards evil for good, Evil will not depart from his house.", 
                "num": 13
              }, 
              {
                "text": "The beginning of strife is like releasing water; Therefore stop contention before a quarrel starts.", 
                "num": 14
              }, 
              {
                "text": "He who justifies the wicked, and he who condemns the just, Both of them alike are an abomination to the LORD.", 
                "num": 15
              }, 
              {
                "text": "Why is there in the hand of a fool the purchase price of wisdom, Since he has no heart for it?", 
                "num": 16
              }, 
              {
                "text": "A friend loves at all times, And a brother is born for adversity.", 
                "num": 17
              }, 
              {
                "text": "A man devoid of understanding shakes hands in a pledge, And becomes surety for his friend.", 
                "num": 18
              }, 
              {
                "text": "He who loves transgression loves strife, And he who exalts his gate seeks destruction.", 
                "num": 19
              }, 
              {
                "text": "He who has a deceitful heart finds no good, And he who has a perverse tongue falls into evil.", 
                "num": 20
              }, 
              {
                "text": "He who begets a scoffer does so to his sorrow, And the father of a fool has no joy.", 
                "num": 21
              }, 
              {
                "text": "A merry heart does good, like medicine, But a broken spirit dries the bones.", 
                "num": 22
              }, 
              {
                "text": "A wicked man accepts a bribe behind the back To pervert the ways of justice.", 
                "num": 23
              }, 
              {
                "text": "Wisdom is in the sight of him who has understanding, But the eyes of a fool are on the ends of the earth.", 
                "num": 24
              }, 
              {
                "text": "A foolish son is a grief to his father, And bitterness to her who bore him.", 
                "num": 25
              }, 
              {
                "text": "Also, to punish the righteous is not good, Nor to strike princes for their uprightness.", 
                "num": 26
              }, 
              {
                "text": "He who has knowledge spares his words, And a man of understanding is of a calm spirit.", 
                "num": 27
              }, 
              {
                "text": "Even a fool is counted wise when he holds his peace; When he shuts his lips, he is considered perceptive.", 
                "num": 28
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "A man who isolates himself seeks his own desire; He rages against all wise judgment.", 
                "num": 1
              }, 
              {
                "text": "A fool has no delight in understanding, But in expressing his own heart.", 
                "num": 2
              }, 
              {
                "text": "When the wicked comes, contempt comes also; And with dishonor comes reproach.", 
                "num": 3
              }, 
              {
                "text": "The words of a man's mouth are deep waters; The wellspring of wisdom is a flowing brook.", 
                "num": 4
              }, 
              {
                "text": "It is not good to show partiality to the wicked, Or to overthrow the righteous in judgment.", 
                "num": 5
              }, 
              {
                "text": "A fool's lips enter into contention, And his mouth calls for blows.", 
                "num": 6
              }, 
              {
                "text": "A fool's mouth is his destruction, And his lips are the snare of his soul.", 
                "num": 7
              }, 
              {
                "text": "The words of a talebearer are like tasty trifles, And they go down into the inmost body.", 
                "num": 8
              }, 
              {
                "text": "He who is slothful in his work Is a brother to him who is a great destroyer.", 
                "num": 9
              }, 
              {
                "text": "The name of the LORD is a strong tower; The righteous run to it and are safe.", 
                "num": 10
              }, 
              {
                "text": "The rich man's wealth is his strong city, And like a high wall in his own esteem.", 
                "num": 11
              }, 
              {
                "text": "Before destruction the heart of a man is haughty, And before honor is humility.", 
                "num": 12
              }, 
              {
                "text": "He who answers a matter before he hears it, It is folly and shame to him.", 
                "num": 13
              }, 
              {
                "text": "The spirit of a man will sustain him in sickness, But who can bear a broken spirit?", 
                "num": 14
              }, 
              {
                "text": "The heart of the prudent acquires knowledge, And the ear of the wise seeks knowledge.", 
                "num": 15
              }, 
              {
                "text": "A man's gift makes room for him, And brings him before great men.", 
                "num": 16
              }, 
              {
                "text": "The first one to plead his cause seems right, Until his neighbor comes and examines him.", 
                "num": 17
              }, 
              {
                "text": "Casting lots causes contentions to cease, And keeps the mighty apart.", 
                "num": 18
              }, 
              {
                "text": "A brother offended is harder to win than a strong city, And contentions are like the bars of a castle.", 
                "num": 19
              }, 
              {
                "text": "A man's stomach shall be satisfied from the fruit of his mouth; From the produce of his lips he shall be filled.", 
                "num": 20
              }, 
              {
                "text": "Death and life are in the power of the tongue, And those who love it will eat its fruit.", 
                "num": 21
              }, 
              {
                "text": "He who finds a wife finds a good thing, And obtains favor from the LORD.", 
                "num": 22
              }, 
              {
                "text": "The poor man uses entreaties, But the rich answers roughly.", 
                "num": 23
              }, 
              {
                "text": "A man who has friends must himself be friendly, But there is a friend who sticks closer than a brother.", 
                "num": 24
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Better is the poor who walks in his integrity Than one who is perverse in his lips, and is a fool.", 
                "num": 1
              }, 
              {
                "text": "Also it is not good for a soul to be without knowledge, And he sins who hastens with his feet.", 
                "num": 2
              }, 
              {
                "text": "The foolishness of a man twists his way, And his heart frets against the LORD.", 
                "num": 3
              }, 
              {
                "text": "Wealth makes many friends, But the poor is separated from his friend.", 
                "num": 4
              }, 
              {
                "text": "A false witness will not go unpunished, And he who speaks lies will not escape.", 
                "num": 5
              }, 
              {
                "text": "Many entreat the favor of the nobility, And every man is a friend to one who gives gifts.", 
                "num": 6
              }, 
              {
                "text": "All the brothers of the poor hate him; How much more do his friends go far from him! He may pursue them with words, yet they abandon him.", 
                "num": 7
              }, 
              {
                "text": "He who gets wisdom loves his own soul; He who keeps understanding will find good.", 
                "num": 8
              }, 
              {
                "text": "A false witness will not go unpunished, And he who speaks lies shall perish.", 
                "num": 9
              }, 
              {
                "text": "Luxury is not fitting for a fool, Much less for a servant to rule over princes.", 
                "num": 10
              }, 
              {
                "text": "The discretion of a man makes him slow to anger, And his glory is to overlook a transgression.", 
                "num": 11
              }, 
              {
                "text": "The king's wrath is like the roaring of a lion, But his favor is like dew on the grass.", 
                "num": 12
              }, 
              {
                "text": "A foolish son is the ruin of his father, And the contentions of a wife are a continual dripping.", 
                "num": 13
              }, 
              {
                "text": "Houses and riches are an inheritance from fathers, But a prudent wife is from the LORD.", 
                "num": 14
              }, 
              {
                "text": "Laziness casts one into a deep sleep, And an idle person will suffer hunger.", 
                "num": 15
              }, 
              {
                "text": "He who keeps the commandment keeps his soul, But he who is careless of his ways will die.", 
                "num": 16
              }, 
              {
                "text": "He who has pity on the poor lends to the LORD, And He will pay back what he has given.", 
                "num": 17
              }, 
              {
                "text": "Chasten your son while there is hope, And do not set your heart on his destruction.", 
                "num": 18
              }, 
              {
                "text": "A man of great wrath will suffer punishment; For if you rescue him, you will have to do it again.", 
                "num": 19
              }, 
              {
                "text": "Listen to counsel and receive instruction, That you may be wise in your latter days.", 
                "num": 20
              }, 
              {
                "text": "There are many plans in a man's heart, Nevertheless the LORD's counsel--that will stand.", 
                "num": 21
              }, 
              {
                "text": "What is desired in a man is kindness, And a poor man is better than a liar.", 
                "num": 22
              }, 
              {
                "text": "The fear of the LORD leads to life, And he who has it will abide in satisfaction; He will not be visited with evil.", 
                "num": 23
              }, 
              {
                "text": "A lazy man buries his hand in the bowl, And will not so much as bring it to his mouth again.", 
                "num": 24
              }, 
              {
                "text": "Strike a scoffer, and the simple will become wary; Rebuke one who has understanding, and he will discern knowledge.", 
                "num": 25
              }, 
              {
                "text": "He who mistreats his father and chases away his mother Is a son who causes shame and brings reproach.", 
                "num": 26
              }, 
              {
                "text": "Cease listening to instruction, my son, And you will stray from the words of knowledge.", 
                "num": 27
              }, 
              {
                "text": "A disreputable witness scorns justice, And the mouth of the wicked devours iniquity.", 
                "num": 28
              }, 
              {
                "text": "Judgments are prepared for scoffers, And beatings for the backs of fools.", 
                "num": 29
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Wine is a mocker, Strong drink is a brawler, And whoever is led astray by it is not wise.", 
                "num": 1
              }, 
              {
                "text": "The wrath of a king is like the roaring of a lion; Whoever provokes him to anger sins against his own life.", 
                "num": 2
              }, 
              {
                "text": "It is honorable for a man to stop striving, Since any fool can start a quarrel.", 
                "num": 3
              }, 
              {
                "text": "The lazy man will not plow because of winter; He will beg during harvest and have nothing.", 
                "num": 4
              }, 
              {
                "text": "Counsel in the heart of man is like deep water, But a man of understanding will draw it out.", 
                "num": 5
              }, 
              {
                "text": "Most men will proclaim each his own goodness, But who can find a faithful man?", 
                "num": 6
              }, 
              {
                "text": "The righteous man walks in his integrity; His children are blessed after him.", 
                "num": 7
              }, 
              {
                "text": "A king who sits on the throne of judgment Scatters all evil with his eyes.", 
                "num": 8
              }, 
              {
                "text": "Who can say, \"I have made my heart clean, I am pure from my sin\"?", 
                "num": 9
              }, 
              {
                "text": "Diverse weights and diverse measures, They are both alike, an abomination to the LORD.", 
                "num": 10
              }, 
              {
                "text": "Even a child is known by his deeds, Whether what he does is pure and right.", 
                "num": 11
              }, 
              {
                "text": "The hearing ear and the seeing eye, The LORD has made them both.", 
                "num": 12
              }, 
              {
                "text": "Do not love sleep, lest you come to poverty; Open your eyes, and you will be satisfied with bread.", 
                "num": 13
              }, 
              {
                "text": "\"It is good for nothing,\" cries the buyer; But when he has gone his way, then he boasts.", 
                "num": 14
              }, 
              {
                "text": "There is gold and a multitude of rubies, But the lips of knowledge are a precious jewel.", 
                "num": 15
              }, 
              {
                "text": "Take the garment of one who is surety for a stranger, And hold it as a pledge when it is for a seductress.", 
                "num": 16
              }, 
              {
                "text": "Bread gained by deceit is sweet to a man, But afterward his mouth will be filled with gravel.", 
                "num": 17
              }, 
              {
                "text": "Plans are established by counsel; By wise counsel wage war.", 
                "num": 18
              }, 
              {
                "text": "He who goes about as a talebearer reveals secrets; Therefore do not associate with one who flatters with his lips.", 
                "num": 19
              }, 
              {
                "text": "Whoever curses his father or his mother, His lamp will be put out in deep darkness.", 
                "num": 20
              }, 
              {
                "text": "An inheritance gained hastily at the beginning Will not be blessed at the end.", 
                "num": 21
              }, 
              {
                "text": "Do not say, \"I will recompense evil\"; Wait for the LORD, and He will save you.", 
                "num": 22
              }, 
              {
                "text": "Diverse weights are an abomination to the LORD, And dishonest scales are not good.", 
                "num": 23
              }, 
              {
                "text": "A man's steps are of the LORD; How then can a man understand his own way?", 
                "num": 24
              }, 
              {
                "text": "It is a snare for a man to devote rashly something as holy, And afterward to reconsider his vows.", 
                "num": 25
              }, 
              {
                "text": "A wise king sifts out the wicked, And brings the threshing wheel over them.", 
                "num": 26
              }, 
              {
                "text": "The spirit of a man is the lamp of the LORD, Searching all the inner depths of his heart.", 
                "num": 27
              }, 
              {
                "text": "Mercy and truth preserve the king, And by lovingkindness he upholds his throne.", 
                "num": 28
              }, 
              {
                "text": "The glory of young men is their strength, And the splendor of old men is their gray head.", 
                "num": 29
              }, 
              {
                "text": "Blows that hurt cleanse away evil, As do stripes the inner depths of the heart.", 
                "num": 30
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "The king's heart is in the hand of the LORD, Like the rivers of water; He turns it wherever He wishes.", 
                "num": 1
              }, 
              {
                "text": "Every way of a man is right in his own eyes, But the LORD weighs the hearts.", 
                "num": 2
              }, 
              {
                "text": "To do righteousness and justice Is more acceptable to the LORD than sacrifice.", 
                "num": 3
              }, 
              {
                "text": "A haughty look, a proud heart, And the plowing of the wicked are sin.", 
                "num": 4
              }, 
              {
                "text": "The plans of the diligent lead surely to plenty, But those of everyone who is hasty, surely to poverty.", 
                "num": 5
              }, 
              {
                "text": "Getting treasures by a lying tongue Is the fleeting fantasy of those who seek death.", 
                "num": 6
              }, 
              {
                "text": "The violence of the wicked will destroy them, Because they refuse to do justice.", 
                "num": 7
              }, 
              {
                "text": "The way of a guilty man is perverse; But as for the pure, his work is right.", 
                "num": 8
              }, 
              {
                "text": "Better to dwell in a corner of a housetop, Than in a house shared with a contentious woman.", 
                "num": 9
              }, 
              {
                "text": "The soul of the wicked desires evil; His neighbor finds no favor in his eyes.", 
                "num": 10
              }, 
              {
                "text": "When the scoffer is punished, the simple is made wise; But when the wise is instructed, he receives knowledge.", 
                "num": 11
              }, 
              {
                "text": "The righteous God wisely considers the house of the wicked, Overthrowing the wicked for their wickedness.", 
                "num": 12
              }, 
              {
                "text": "Whoever shuts his ears to the cry of the poor Will also cry himself and not be heard.", 
                "num": 13
              }, 
              {
                "text": "A gift in secret pacifies anger, And a bribe behind the back, strong wrath.", 
                "num": 14
              }, 
              {
                "text": "It is a joy for the just to do justice, But destruction will come to the workers of iniquity.", 
                "num": 15
              }, 
              {
                "text": "A man who wanders from the way of understanding Will rest in the assembly of the dead.", 
                "num": 16
              }, 
              {
                "text": "He who loves pleasure will be a poor man; He who loves wine and oil will not be rich.", 
                "num": 17
              }, 
              {
                "text": "The wicked shall be a ransom for the righteous, And the unfaithful for the upright.", 
                "num": 18
              }, 
              {
                "text": "Better to dwell in the wilderness, Than with a contentious and angry woman.", 
                "num": 19
              }, 
              {
                "text": "There is desirable treasure, And oil in the dwelling of the wise, But a foolish man squanders it.", 
                "num": 20
              }, 
              {
                "text": "He who follows righteousness and mercy Finds life, righteousness and honor.", 
                "num": 21
              }, 
              {
                "text": "A wise man scales the city of the mighty, And brings down the trusted stronghold.", 
                "num": 22
              }, 
              {
                "text": "Whoever guards his mouth and tongue Keeps his soul from troubles.", 
                "num": 23
              }, 
              {
                "text": "A proud and haughty man--\"Scoffer\" is his name; He acts with arrogant pride.", 
                "num": 24
              }, 
              {
                "text": "The desire of the lazy man kills him, For his hands refuse to labor.", 
                "num": 25
              }, 
              {
                "text": "He covets greedily all day long, But the righteous gives and does not spare.", 
                "num": 26
              }, 
              {
                "text": "The sacrifice of the wicked is an abomination; How much more when he brings it with wicked intent!", 
                "num": 27
              }, 
              {
                "text": "A false witness shall perish, But the man who hears him will speak endlessly.", 
                "num": 28
              }, 
              {
                "text": "A wicked man hardens his face, But as for the upright, he establishes his way.", 
                "num": 29
              }, 
              {
                "text": "There is no wisdom or understanding Or counsel against the LORD.", 
                "num": 30
              }, 
              {
                "text": "The horse is prepared for the day of battle, But deliverance is of the LORD.", 
                "num": 31
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "A good name is to be chosen rather than great riches, Loving favor rather than silver and gold.", 
                "num": 1
              }, 
              {
                "text": "The rich and the poor have this in common, The LORD is the maker of them all.", 
                "num": 2
              }, 
              {
                "text": "A prudent man foresees evil and hides himself, But the simple pass on and are punished.", 
                "num": 3
              }, 
              {
                "text": "By humility and the fear of the LORD Are riches and honor and life.", 
                "num": 4
              }, 
              {
                "text": "Thorns and snares are in the way of the perverse; He who guards his soul will be far from them.", 
                "num": 5
              }, 
              {
                "text": "Train up a child in the way he should go, And when he is old he will not depart from it.", 
                "num": 6
              }, 
              {
                "text": "The rich rules over the poor, And the borrower is servant to the lender.", 
                "num": 7
              }, 
              {
                "text": "He who sows iniquity will reap sorrow, And the rod of his anger will fail.", 
                "num": 8
              }, 
              {
                "text": "He who has a generous eye will be blessed, For he gives of his bread to the poor.", 
                "num": 9
              }, 
              {
                "text": "Cast out the scoffer, and contention will leave; Yes, strife and reproach will cease.", 
                "num": 10
              }, 
              {
                "text": "He who loves purity of heart And has grace on his lips, The king will be his friend.", 
                "num": 11
              }, 
              {
                "text": "The eyes of the LORD preserve knowledge, But He overthrows the words of the faithless.", 
                "num": 12
              }, 
              {
                "text": "The lazy man says, \"There is a lion outside! I shall be slain in the streets!\"", 
                "num": 13
              }, 
              {
                "text": "The mouth of an immoral woman is a deep pit; He who is abhorred by the LORD will fall there.", 
                "num": 14
              }, 
              {
                "text": "Foolishness is bound up in the heart of a child; The rod of correction will drive it far from him.", 
                "num": 15
              }, 
              {
                "text": "He who oppresses the poor to increase his riches, And he who gives to the rich, will surely come to poverty.", 
                "num": 16
              }, 
              {
                "text": "Incline your ear and hear the words of the wise, And apply your heart to my knowledge;", 
                "num": 17
              }, 
              {
                "text": "For it is a pleasant thing if you keep them within you; Let them all be fixed upon your lips,", 
                "num": 18
              }, 
              {
                "text": "So that your trust may be in the LORD; I have instructed you today, even you.", 
                "num": 19
              }, 
              {
                "text": "Have I not written to you excellent things Of counsels and knowledge,", 
                "num": 20
              }, 
              {
                "text": "That I may make you know the certainty of the words of truth, That you may answer words of truth To those who send to you?", 
                "num": 21
              }, 
              {
                "text": "Do not rob the poor because he is poor, Nor oppress the afflicted at the gate;", 
                "num": 22
              }, 
              {
                "text": "For the LORD will plead their cause, And plunder the soul of those who plunder them.", 
                "num": 23
              }, 
              {
                "text": "Make no friendship with an angry man, And with a furious man do not go,", 
                "num": 24
              }, 
              {
                "text": "Lest you learn his ways And set a snare for your soul.", 
                "num": 25
              }, 
              {
                "text": "Do not be one of those who shakes hands in a pledge, One of those who is surety for debts;", 
                "num": 26
              }, 
              {
                "text": "If you have nothing with which to pay, Why should he take away your bed from under you?", 
                "num": 27
              }, 
              {
                "text": "Do not remove the ancient landmark Which your fathers have set.", 
                "num": 28
              }, 
              {
                "text": "Do you see a man who excels in his work? He will stand before kings; He will not stand before unknown men.", 
                "num": 29
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "When you sit down to eat with a ruler, Consider carefully what is before you;", 
                "num": 1
              }, 
              {
                "text": "And put a knife to your throat If you are a man given to appetite.", 
                "num": 2
              }, 
              {
                "text": "Do not desire his delicacies, For they are deceptive food.", 
                "num": 3
              }, 
              {
                "text": "Do not overwork to be rich; Because of your own understanding, cease!", 
                "num": 4
              }, 
              {
                "text": "Will you set your eyes on that which is not? For riches certainly make themselves wings; They fly away like an eagle toward heaven.", 
                "num": 5
              }, 
              {
                "text": "Do not eat the bread of a miser, Nor desire his delicacies;", 
                "num": 6
              }, 
              {
                "text": "For as he thinks in his heart, so is he. \"Eat and drink!\" he says to you, But his heart is not with you.", 
                "num": 7
              }, 
              {
                "text": "The morsel you have eaten, you will vomit up, And waste your pleasant words.", 
                "num": 8
              }, 
              {
                "text": "Do not speak in the hearing of a fool, For he will despise the wisdom of your words.", 
                "num": 9
              }, 
              {
                "text": "Do not remove the ancient landmark, Nor enter the fields of the fatherless;", 
                "num": 10
              }, 
              {
                "text": "For their Redeemer is mighty; He will plead their cause against you.", 
                "num": 11
              }, 
              {
                "text": "Apply your heart to instruction, And your ears to words of knowledge.", 
                "num": 12
              }, 
              {
                "text": "Do not withhold correction from a child, For if you beat him with a rod, he will not die.", 
                "num": 13
              }, 
              {
                "text": "You shall beat him with a rod, And deliver his soul from hell.", 
                "num": 14
              }, 
              {
                "text": "My son, if your heart is wise, My heart will rejoice--indeed, I myself;", 
                "num": 15
              }, 
              {
                "text": "Yes, my inmost being will rejoice When your lips speak right things.", 
                "num": 16
              }, 
              {
                "text": "Do not let your heart envy sinners, But be zealous for the fear of the LORD all the day;", 
                "num": 17
              }, 
              {
                "text": "For surely there is a hereafter, And your hope will not be cut off.", 
                "num": 18
              }, 
              {
                "text": "Hear, my son, and be wise; And guide your heart in the way.", 
                "num": 19
              }, 
              {
                "text": "Do not mix with winebibbers, Or with gluttonous eaters of meat;", 
                "num": 20
              }, 
              {
                "text": "For the drunkard and the glutton will come to poverty, And drowsiness will clothe a man with rags.", 
                "num": 21
              }, 
              {
                "text": "Listen to your father who begot you, And do not despise your mother when she is old.", 
                "num": 22
              }, 
              {
                "text": "Buy the truth, and do not sell it, Also wisdom and instruction and understanding.", 
                "num": 23
              }, 
              {
                "text": "The father of the righteous will greatly rejoice, And he who begets a wise child will delight in him.", 
                "num": 24
              }, 
              {
                "text": "Let your father and your mother be glad, And let her who bore you rejoice.", 
                "num": 25
              }, 
              {
                "text": "My son, give me your heart, And let your eyes observe my ways.", 
                "num": 26
              }, 
              {
                "text": "For a harlot is a deep pit, And a seductress is a narrow well.", 
                "num": 27
              }, 
              {
                "text": "She also lies in wait as for a victim, And increases the unfaithful among men.", 
                "num": 28
              }, 
              {
                "text": "Who has woe? Who has sorrow? Who has contentions? Who has complaints? Who has wounds without cause? Who has redness of eyes?", 
                "num": 29
              }, 
              {
                "text": "Those who linger long at the wine, Those who go in search of mixed wine.", 
                "num": 30
              }, 
              {
                "text": "Do not look on the wine when it is red, When it sparkles in the cup, When it swirls around smoothly;", 
                "num": 31
              }, 
              {
                "text": "At the last it bites like a serpent, And stings like a viper.", 
                "num": 32
              }, 
              {
                "text": "Your eyes will see strange things, And your heart will utter perverse things.", 
                "num": 33
              }, 
              {
                "text": "Yes, you will be like one who lies down in the midst of the sea, Or like one who lies at the top of the mast, saying:", 
                "num": 34
              }, 
              {
                "text": "\"They have struck me, but I was not hurt; They have beaten me, but I did not feel it. When shall I awake, that I may seek another drink?|\"", 
                "num": 35
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Do not be envious of evil men, Nor desire to be with them;", 
                "num": 1
              }, 
              {
                "text": "For their heart devises violence, And their lips talk of troublemaking.", 
                "num": 2
              }, 
              {
                "text": "Through wisdom a house is built, And by understanding it is established;", 
                "num": 3
              }, 
              {
                "text": "By knowledge the rooms are filled With all precious and pleasant riches.", 
                "num": 4
              }, 
              {
                "text": "A wise man is strong, Yes, a man of knowledge increases strength;", 
                "num": 5
              }, 
              {
                "text": "For by wise counsel you will wage your own war, And in a multitude of counselors there is safety.", 
                "num": 6
              }, 
              {
                "text": "Wisdom is too lofty for a fool; He does not open his mouth in the gate.", 
                "num": 7
              }, 
              {
                "text": "He who plots to do evil Will be called a schemer.", 
                "num": 8
              }, 
              {
                "text": "The devising of foolishness is sin, And the scoffer is an abomination to men.", 
                "num": 9
              }, 
              {
                "text": "If you faint in the day of adversity, Your strength is small.", 
                "num": 10
              }, 
              {
                "text": "Deliver those who are drawn toward death, And hold back those stumbling to the slaughter.", 
                "num": 11
              }, 
              {
                "text": "If you say, \"Surely we did not know this,\" Does not He who weighs the hearts consider it? He who keeps your soul, does He not know it? And will He not render to each man according to his deeds?", 
                "num": 12
              }, 
              {
                "text": "My son, eat honey because it is good, And the honeycomb which is sweet to your taste;", 
                "num": 13
              }, 
              {
                "text": "So shall the knowledge of wisdom be to your soul; If you have found it, there is a prospect, And your hope will not be cut off.", 
                "num": 14
              }, 
              {
                "text": "Do not lie in wait, O wicked man, against the dwelling of the righteous; Do not plunder his resting place;", 
                "num": 15
              }, 
              {
                "text": "For a righteous man may fall seven times And rise again, But the wicked shall fall by calamity.", 
                "num": 16
              }, 
              {
                "text": "Do not rejoice when your enemy falls, And do not let your heart be glad when he stumbles;", 
                "num": 17
              }, 
              {
                "text": "Lest the LORD see it, and it displease Him, And He turn away His wrath from him.", 
                "num": 18
              }, 
              {
                "text": "Do not fret because of evildoers, Nor be envious of the wicked;", 
                "num": 19
              }, 
              {
                "text": "For there will be no prospect for the evil man; The lamp of the wicked will be put out.", 
                "num": 20
              }, 
              {
                "text": "My son, fear the LORD and the king; Do not associate with those given to change;", 
                "num": 21
              }, 
              {
                "text": "For their calamity will rise suddenly, And who knows the ruin those two can bring?", 
                "num": 22
              }, 
              {
                "text": "These things also belong to the wise: It is not good to show partiality in judgment.", 
                "num": 23
              }, 
              {
                "text": "He who says to the wicked, \"You are righteous,\" Him the people will curse; Nations will abhor him.", 
                "num": 24
              }, 
              {
                "text": "But those who rebuke the wicked will have delight, And a good blessing will come upon them.", 
                "num": 25
              }, 
              {
                "text": "He who gives a right answer kisses the lips.", 
                "num": 26
              }, 
              {
                "text": "Prepare your outside work, Make it fit for yourself in the field; And afterward build your house.", 
                "num": 27
              }, 
              {
                "text": "Do not be a witness against your neighbor without cause, For would you deceive with your lips?", 
                "num": 28
              }, 
              {
                "text": "Do not say, \"I will do to him just as he has done to me; I will render to the man according to his work.\"", 
                "num": 29
              }, 
              {
                "text": "I went by the field of the lazy man, And by the vineyard of the man devoid of understanding;", 
                "num": 30
              }, 
              {
                "text": "And there it was, all overgrown with thorns; Its surface was covered with nettles; Its stone wall was broken down.", 
                "num": 31
              }, 
              {
                "text": "When I saw it, I considered it well; I looked on it and received instruction:", 
                "num": 32
              }, 
              {
                "text": "A little sleep, a little slumber, A little folding of the hands to rest;", 
                "num": 33
              }, 
              {
                "text": "So shall your poverty come like a prowler, And your need like an armed man.", 
                "num": 34
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "These also are proverbs of Solomon which the men of Hezekiah king of Judah copied:", 
                "num": 1
              }, 
              {
                "text": "It is the glory of God to conceal a matter, But the glory of kings is to search out a matter.", 
                "num": 2
              }, 
              {
                "text": "As the heavens for height and the earth for depth, So the heart of kings is unsearchable.", 
                "num": 3
              }, 
              {
                "text": "Take away the dross from silver, And it will go to the silversmith for jewelry.", 
                "num": 4
              }, 
              {
                "text": "Take away the wicked from before the king, And his throne will be established in righteousness.", 
                "num": 5
              }, 
              {
                "text": "Do not exalt yourself in the presence of the king, And do not stand in the place of the great;", 
                "num": 6
              }, 
              {
                "text": "For it is better that he say to you, \"Come up here,\" Than that you should be put lower in the presence of the prince, Whom your eyes have seen.", 
                "num": 7
              }, 
              {
                "text": "Do not go hastily to court; For what will you do in the end, When your neighbor has put you to shame?", 
                "num": 8
              }, 
              {
                "text": "Debate your case with your neighbor, And do not disclose the secret to another;", 
                "num": 9
              }, 
              {
                "text": "Lest he who hears it expose your shame, And your reputation be ruined.", 
                "num": 10
              }, 
              {
                "text": "A word fitly spoken is like apples of gold In settings of silver.", 
                "num": 11
              }, 
              {
                "text": "Like an earring of gold and an ornament of fine gold Is a wise rebuker to an obedient ear.", 
                "num": 12
              }, 
              {
                "text": "Like the cold of snow in time of harvest Is a faithful messenger to those who send him, For he refreshes the soul of his masters.", 
                "num": 13
              }, 
              {
                "text": "Whoever falsely boasts of giving Is like clouds and wind without rain.", 
                "num": 14
              }, 
              {
                "text": "By long forbearance a ruler is persuaded, And a gentle tongue breaks a bone.", 
                "num": 15
              }, 
              {
                "text": "Have you found honey? Eat only as much as you need, Lest you be filled with it and vomit.", 
                "num": 16
              }, 
              {
                "text": "Seldom set foot in your neighbor's house, Lest he become weary of you and hate you.", 
                "num": 17
              }, 
              {
                "text": "A man who bears false witness against his neighbor Is like a club, a sword, and a sharp arrow.", 
                "num": 18
              }, 
              {
                "text": "Confidence in an unfaithful man in time of trouble Is like a bad tooth and a foot out of joint.", 
                "num": 19
              }, 
              {
                "text": "Like one who takes away a garment in cold weather, And like vinegar on soda, Is one who sings songs to a heavy heart.", 
                "num": 20
              }, 
              {
                "text": "If your enemy is hungry, give him bread to eat; And if he is thirsty, give him water to drink;", 
                "num": 21
              }, 
              {
                "text": "For so you will heap coals of fire on his head, And the LORD will reward you.", 
                "num": 22
              }, 
              {
                "text": "The north wind brings forth rain, And a backbiting tongue an angry countenance.", 
                "num": 23
              }, 
              {
                "text": "It is better to dwell in a corner of a housetop, Than in a house shared with a contentious woman.", 
                "num": 24
              }, 
              {
                "text": "As cold water to a weary soul, So is good news from a far country.", 
                "num": 25
              }, 
              {
                "text": "A righteous man who falters before the wicked Is like a murky spring and a polluted well.", 
                "num": 26
              }, 
              {
                "text": "It is not good to eat much honey; So to seek one's own glory is not glory.", 
                "num": 27
              }, 
              {
                "text": "Whoever has no rule over his own spirit Is like a city broken down, without walls.", 
                "num": 28
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "As snow in summer and rain in harvest, So honor is not fitting for a fool.", 
                "num": 1
              }, 
              {
                "text": "Like a flitting sparrow, like a flying swallow, So a curse without cause shall not alight.", 
                "num": 2
              }, 
              {
                "text": "A whip for the horse, A bridle for the donkey, And a rod for the fool's back.", 
                "num": 3
              }, 
              {
                "text": "Do not answer a fool according to his folly, Lest you also be like him.", 
                "num": 4
              }, 
              {
                "text": "Answer a fool according to his folly, Lest he be wise in his own eyes.", 
                "num": 5
              }, 
              {
                "text": "He who sends a message by the hand of a fool Cuts off his own feet and drinks violence.", 
                "num": 6
              }, 
              {
                "text": "Like the legs of the lame that hang limp Is a proverb in the mouth of fools.", 
                "num": 7
              }, 
              {
                "text": "Like one who binds a stone in a sling Is he who gives honor to a fool.", 
                "num": 8
              }, 
              {
                "text": "Like a thorn that goes into the hand of a drunkard Is a proverb in the mouth of fools.", 
                "num": 9
              }, 
              {
                "text": "The great God who formed everything Gives the fool his hire and the transgressor his wages.", 
                "num": 10
              }, 
              {
                "text": "As a dog returns to his own vomit, So a fool repeats his folly.", 
                "num": 11
              }, 
              {
                "text": "Do you see a man wise in his own eyes? There is more hope for a fool than for him.", 
                "num": 12
              }, 
              {
                "text": "The lazy man says, \"There is a lion in the road! A fierce lion is in the streets!\"", 
                "num": 13
              }, 
              {
                "text": "As a door turns on its hinges, So does the lazy man on his bed.", 
                "num": 14
              }, 
              {
                "text": "The lazy man buries his hand in the bowl; It wearies him to bring it back to his mouth.", 
                "num": 15
              }, 
              {
                "text": "The lazy man is wiser in his own eyes Than seven men who can answer sensibly.", 
                "num": 16
              }, 
              {
                "text": "He who passes by and meddles in a quarrel not his own Is like one who takes a dog by the ears.", 
                "num": 17
              }, 
              {
                "text": "Like a madman who throws firebrands, arrows, and death,", 
                "num": 18
              }, 
              {
                "text": "Is the man who deceives his neighbor, And says, \"I was only joking!\"", 
                "num": 19
              }, 
              {
                "text": "Where there is no wood, the fire goes out; And where there is no talebearer, strife ceases.", 
                "num": 20
              }, 
              {
                "text": "As charcoal is to burning coals, and wood to fire, So is a contentious man to kindle strife.", 
                "num": 21
              }, 
              {
                "text": "The words of a talebearer are like tasty trifles, And they go down into the inmost body.", 
                "num": 22
              }, 
              {
                "text": "Fervent lips with a wicked heart Are like earthenware covered with silver dross.", 
                "num": 23
              }, 
              {
                "text": "He who hates, disguises it with his lips, And lays up deceit within himself;", 
                "num": 24
              }, 
              {
                "text": "When he speaks kindly, do not believe him, For there are seven abominations in his heart;", 
                "num": 25
              }, 
              {
                "text": "Though his hatred is covered by deceit, His wickedness will be revealed before the assembly.", 
                "num": 26
              }, 
              {
                "text": "Whoever digs a pit will fall into it, And he who rolls a stone will have it roll back on him.", 
                "num": 27
              }, 
              {
                "text": "A lying tongue hates those who are crushed by it, And a flattering mouth works ruin.", 
                "num": 28
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "Do not boast about tomorrow, For you do not know what a day may bring forth.", 
                "num": 1
              }, 
              {
                "text": "Let another man praise you, and not your own mouth; A stranger, and not your own lips.", 
                "num": 2
              }, 
              {
                "text": "A stone is heavy and sand is weighty, But a fool's wrath is heavier than both of them.", 
                "num": 3
              }, 
              {
                "text": "Wrath is cruel and anger a torrent, But who is able to stand before jealousy?", 
                "num": 4
              }, 
              {
                "text": "Open rebuke is better Than love carefully concealed.", 
                "num": 5
              }, 
              {
                "text": "Faithful are the wounds of a friend, But the kisses of an enemy are deceitful.", 
                "num": 6
              }, 
              {
                "text": "A satisfied soul loathes the honeycomb, But to a hungry soul every bitter thing is sweet.", 
                "num": 7
              }, 
              {
                "text": "Like a bird that wanders from its nest Is a man who wanders from his place.", 
                "num": 8
              }, 
              {
                "text": "Ointment and perfume delight the heart, And the sweetness of a man's friend gives delight by hearty counsel.", 
                "num": 9
              }, 
              {
                "text": "Do not forsake your own friend or your father's friend, Nor go to your brother's house in the day of your calamity; Better is a neighbor nearby than a brother far away.", 
                "num": 10
              }, 
              {
                "text": "My son, be wise, and make my heart glad, That I may answer him who reproaches me.", 
                "num": 11
              }, 
              {
                "text": "A prudent man foresees evil and hides himself; The simple pass on and are punished.", 
                "num": 12
              }, 
              {
                "text": "Take the garment of him who is surety for a stranger, And hold it in pledge when he is surety for a seductress.", 
                "num": 13
              }, 
              {
                "text": "He who blesses his friend with a loud voice, rising early in the morning, It will be counted a curse to him.", 
                "num": 14
              }, 
              {
                "text": "A continual dripping on a very rainy day And a contentious woman are alike;", 
                "num": 15
              }, 
              {
                "text": "Whoever restrains her restrains the wind, And grasps oil with his right hand.", 
                "num": 16
              }, 
              {
                "text": "As iron sharpens iron, So a man sharpens the countenance of his friend.", 
                "num": 17
              }, 
              {
                "text": "Whoever keeps the fig tree will eat its fruit; So he who waits on his master will be honored.", 
                "num": 18
              }, 
              {
                "text": "As in water face reflects face, So a man's heart reveals the man.", 
                "num": 19
              }, 
              {
                "text": "Hell and Destruction are never full; So the eyes of man are never satisfied.", 
                "num": 20
              }, 
              {
                "text": "The refining pot is for silver and the furnace for gold, And a man is valued by what others say of him.", 
                "num": 21
              }, 
              {
                "text": "Though you grind a fool in a mortar with a pestle along with crushed grain, Yet his foolishness will not depart from him.", 
                "num": 22
              }, 
              {
                "text": "Be diligent to know the state of your flocks, And attend to your herds;", 
                "num": 23
              }, 
              {
                "text": "For riches are not forever, Nor does a crown endure to all generations.", 
                "num": 24
              }, 
              {
                "text": "When the hay is removed, and the tender grass shows itself, And the herbs of the mountains are gathered in,", 
                "num": 25
              }, 
              {
                "text": "The lambs will provide your clothing, And the goats the price of a field;", 
                "num": 26
              }, 
              {
                "text": "You shall have enough goats' milk for your food, For the food of your household, And the nourishment of your maidservants.", 
                "num": 27
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "The wicked flee when no one pursues, But the righteous are bold as a lion.", 
                "num": 1
              }, 
              {
                "text": "Because of the transgression of a land, many are its princes; But by a man of understanding and knowledge Right will be prolonged.", 
                "num": 2
              }, 
              {
                "text": "A poor man who oppresses the poor Is like a driving rain which leaves no food.", 
                "num": 3
              }, 
              {
                "text": "Those who forsake the law praise the wicked, But such as keep the law contend with them.", 
                "num": 4
              }, 
              {
                "text": "Evil men do not understand justice, But those who seek the LORD understand all.", 
                "num": 5
              }, 
              {
                "text": "Better is the poor who walks in his integrity Than one perverse in his ways, though he be rich.", 
                "num": 6
              }, 
              {
                "text": "Whoever keeps the law is a discerning son, But a companion of gluttons shames his father.", 
                "num": 7
              }, 
              {
                "text": "One who increases his possessions by usury and extortion Gathers it for him who will pity the poor.", 
                "num": 8
              }, 
              {
                "text": "One who turns away his ear from hearing the law, Even his prayer is an abomination.", 
                "num": 9
              }, 
              {
                "text": "Whoever causes the upright to go astray in an evil way, He himself will fall into his own pit; But the blameless will inherit good.", 
                "num": 10
              }, 
              {
                "text": "The rich man is wise in his own eyes, But the poor who has understanding searches him out.", 
                "num": 11
              }, 
              {
                "text": "When the righteous rejoice, there is great glory; But when the wicked arise, men hide themselves.", 
                "num": 12
              }, 
              {
                "text": "He who covers his sins will not prosper, But whoever confesses and forsakes them will have mercy.", 
                "num": 13
              }, 
              {
                "text": "Happy is the man who is always reverent, But he who hardens his heart will fall into calamity.", 
                "num": 14
              }, 
              {
                "text": "Like a roaring lion and a charging bear Is a wicked ruler over poor people.", 
                "num": 15
              }, 
              {
                "text": "A ruler who lacks understanding is a great oppressor, But he who hates covetousness will prolong his days.", 
                "num": 16
              }, 
              {
                "text": "A man burdened with bloodshed will flee into a pit; Let no one help him.", 
                "num": 17
              }, 
              {
                "text": "Whoever walks blamelessly will be saved, But he who is perverse in his ways will suddenly fall.", 
                "num": 18
              }, 
              {
                "text": "He who tills his land will have plenty of bread, But he who follows frivolity will have poverty enough!", 
                "num": 19
              }, 
              {
                "text": "A faithful man will abound with blessings, But he who hastens to be rich will not go unpunished.", 
                "num": 20
              }, 
              {
                "text": "To show partiality is not good, Because for a piece of bread a man will transgress.", 
                "num": 21
              }, 
              {
                "text": "A man with an evil eye hastens after riches, And does not consider that poverty will come upon him.", 
                "num": 22
              }, 
              {
                "text": "He who rebukes a man will find more favor afterward Than he who flatters with the tongue.", 
                "num": 23
              }, 
              {
                "text": "Whoever robs his father or his mother, And says, \"It is no transgression,\" The same is companion to a destroyer.", 
                "num": 24
              }, 
              {
                "text": "He who is of a proud heart stirs up strife, But he who trusts in the LORD will be prospered.", 
                "num": 25
              }, 
              {
                "text": "He who trusts in his own heart is a fool, But whoever walks wisely will be delivered.", 
                "num": 26
              }, 
              {
                "text": "He who gives to the poor will not lack, But he who hides his eyes will have many curses.", 
                "num": 27
              }, 
              {
                "text": "When the wicked arise, men hide themselves; But when they perish, the righteous increase.", 
                "num": 28
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "He who is often rebuked, and hardens his neck, Will suddenly be destroyed, and that without remedy.", 
                "num": 1
              }, 
              {
                "text": "When the righteous are in authority, the people rejoice; But when a wicked man rules, the people groan.", 
                "num": 2
              }, 
              {
                "text": "Whoever loves wisdom makes his father rejoice, But a companion of harlots wastes his wealth.", 
                "num": 3
              }, 
              {
                "text": "The king establishes the land by justice, But he who receives bribes overthrows it.", 
                "num": 4
              }, 
              {
                "text": "A man who flatters his neighbor Spreads a net for his feet.", 
                "num": 5
              }, 
              {
                "text": "By transgression an evil man is snared, But the righteous sings and rejoices.", 
                "num": 6
              }, 
              {
                "text": "The righteous considers the cause of the poor, But the wicked does not understand such knowledge.", 
                "num": 7
              }, 
              {
                "text": "Scoffers set a city aflame, But wise men turn away wrath.", 
                "num": 8
              }, 
              {
                "text": "If a wise man contends with a foolish man, Whether the fool rages or laughs, there is no peace.", 
                "num": 9
              }, 
              {
                "text": "The bloodthirsty hate the blameless, But the upright seek his well-being.", 
                "num": 10
              }, 
              {
                "text": "A fool vents all his feelings, But a wise man holds them back.", 
                "num": 11
              }, 
              {
                "text": "If a ruler pays attention to lies, All his servants become wicked.", 
                "num": 12
              }, 
              {
                "text": "The poor man and the oppressor have this in common: The LORD gives light to the eyes of both.", 
                "num": 13
              }, 
              {
                "text": "The king who judges the poor with truth, His throne will be established forever.", 
                "num": 14
              }, 
              {
                "text": "The rod and rebuke give wisdom, But a child left to himself brings shame to his mother.", 
                "num": 15
              }, 
              {
                "text": "When the wicked are multiplied, transgression increases; But the righteous will see their fall.", 
                "num": 16
              }, 
              {
                "text": "Correct your son, and he will give you rest; Yes, he will give delight to your soul.", 
                "num": 17
              }, 
              {
                "text": "Where there is no revelation, the people cast off restraint; But happy is he who keeps the law.", 
                "num": 18
              }, 
              {
                "text": "A servant will not be corrected by mere words; For though he understands, he will not respond.", 
                "num": 19
              }, 
              {
                "text": "Do you see a man hasty in his words? There is more hope for a fool than for him.", 
                "num": 20
              }, 
              {
                "text": "He who pampers his servant from childhood Will have him as a son in the end.", 
                "num": 21
              }, 
              {
                "text": "An angry man stirs up strife, And a furious man abounds in transgression.", 
                "num": 22
              }, 
              {
                "text": "A man's pride will bring him low, But the humble in spirit will retain honor.", 
                "num": 23
              }, 
              {
                "text": "Whoever is a partner with a thief hates his own life; He swears to tell the truth, but reveals nothing.", 
                "num": 24
              }, 
              {
                "text": "The fear of man brings a snare, But whoever trusts in the LORD shall be safe.", 
                "num": 25
              }, 
              {
                "text": "Many seek the ruler's favor, But justice for man comes from the LORD.", 
                "num": 26
              }, 
              {
                "text": "An unjust man is an abomination to the righteous, And he who is upright in the way is an abomination to the wicked.", 
                "num": 27
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "The words of Agur the son of Jakeh, his utterance. This man declared to Ithiel--to Ithiel and Ucal:", 
                "num": 1
              }, 
              {
                "text": "Surely I am more stupid than any man, And do not have the understanding of a man.", 
                "num": 2
              }, 
              {
                "text": "I neither learned wisdom Nor have knowledge of the Holy One.", 
                "num": 3
              }, 
              {
                "text": "Who has ascended into heaven, or descended? Who has gathered the wind in His fists? Who has bound the waters in a garment? Who has established all the ends of the earth? What is His name, and what is His Son's name, If you know?", 
                "num": 4
              }, 
              {
                "text": "Every word of God is pure; He is a shield to those who put their trust in Him.", 
                "num": 5
              }, 
              {
                "text": "Do not add to His words, Lest He rebuke you, and you be found a liar.", 
                "num": 6
              }, 
              {
                "text": "Two things I request of You (Deprive me not before I die):", 
                "num": 7
              }, 
              {
                "text": "Remove falsehood and lies far from me; Give me neither poverty nor riches-- Feed me with the food allotted to me;", 
                "num": 8
              }, 
              {
                "text": "Lest I be full and deny You, And say, \"Who is the LORD?\" Or lest I be poor and steal, And profane the name of my God.", 
                "num": 9
              }, 
              {
                "text": "Do not malign a servant to his master, Lest he curse you, and you be found guilty.", 
                "num": 10
              }, 
              {
                "text": "There is a generation that curses its father, And does not bless its mother.", 
                "num": 11
              }, 
              {
                "text": "There is a generation that is pure in its own eyes, Yet is not washed from its filthiness.", 
                "num": 12
              }, 
              {
                "text": "There is a generation-- oh, how lofty are their eyes! And their eyelids are lifted up.", 
                "num": 13
              }, 
              {
                "text": "There is a generation whose teeth are like swords, And whose fangs are like knives, To devour the poor from off the earth, And the needy from among men.", 
                "num": 14
              }, 
              {
                "text": "The leech has two daughters-- Give and Give! There are three things that are never satisfied, Four never say, \"Enough!\":", 
                "num": 15
              }, 
              {
                "text": "The grave, The barren womb, The earth that is not satisfied with water-- And the fire never says, \"Enough!\"", 
                "num": 16
              }, 
              {
                "text": "The eye that mocks his father, And scorns obedience to his mother, The ravens of the valley will pick it out, And the young eagles will eat it.", 
                "num": 17
              }, 
              {
                "text": "There are three things which are too wonderful for me, Yes, four which I do not understand:", 
                "num": 18
              }, 
              {
                "text": "The way of an eagle in the air, The way of a serpent on a rock, The way of a ship in the midst of the sea, And the way of a man with a virgin.", 
                "num": 19
              }, 
              {
                "text": "This is the way of an adulterous woman: She eats and wipes her mouth, And says, \"I have done no wickedness.\"", 
                "num": 20
              }, 
              {
                "text": "For three things the earth is perturbed, Yes, for four it cannot bear up:", 
                "num": 21
              }, 
              {
                "text": "For a servant when he reigns, A fool when he is filled with food,", 
                "num": 22
              }, 
              {
                "text": "A hateful woman when she is married, And a maidservant who succeeds her mistress.", 
                "num": 23
              }, 
              {
                "text": "There are four things which are little on the earth, But they are exceedingly wise:", 
                "num": 24
              }, 
              {
                "text": "The ants are a people not strong, Yet they prepare their food in the summer;", 
                "num": 25
              }, 
              {
                "text": "The rock badgers are a feeble folk, Yet they make their homes in the crags;", 
                "num": 26
              }, 
              {
                "text": "The locusts have no king, Yet they all advance in ranks;", 
                "num": 27
              }, 
              {
                "text": "The spider skillfully grasps with its hands, And it is in kings' palaces.", 
                "num": 28
              }, 
              {
                "text": "There are three things which are majestic in pace, Yes, four which are stately in walk:", 
                "num": 29
              }, 
              {
                "text": "A lion, which is mighty among beasts And does not turn away from any;", 
                "num": 30
              }, 
              {
                "text": "A greyhound, A male goat also, And a king whose troops are with him.", 
                "num": 31
              }, 
              {
                "text": "If you have been foolish in exalting yourself, Or if you have devised evil, put your hand on your mouth.", 
                "num": 32
              }, 
              {
                "text": "For as the churning of milk produces butter, And wringing the nose produces blood, So the forcing of wrath produces strife.", 
                "num": 33
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "The words of King Lemuel, the utterance which his mother taught him:", 
                "num": 1
              }, 
              {
                "text": "What, my son? And what, son of my womb? And what, son of my vows?", 
                "num": 2
              }, 
              {
                "text": "Do not give your strength to women, Nor your ways to that which destroys kings.", 
                "num": 3
              }, 
              {
                "text": "It is not for kings, O Lemuel, It is not for kings to drink wine, Nor for princes intoxicating drink;", 
                "num": 4
              }, 
              {
                "text": "Lest they drink and forget the law, And pervert the justice of all the afflicted.", 
                "num": 5
              }, 
              {
                "text": "Give strong drink to him who is perishing, And wine to those who are bitter of heart.", 
                "num": 6
              }, 
              {
                "text": "Let him drink and forget his poverty, And remember his misery no more.", 
                "num": 7
              }, 
              {
                "text": "Open your mouth for the speechless, In the cause of all who are appointed to die.", 
                "num": 8
              }, 
              {
                "text": "Open your mouth, judge righteously, And plead the cause of the poor and needy.", 
                "num": 9
              }, 
              {
                "text": "Who can find a virtuous wife? For her worth is far above rubies.", 
                "num": 10
              }, 
              {
                "text": "The heart of her husband safely trusts her; So he will have no lack of gain.", 
                "num": 11
              }, 
              {
                "text": "She does him good and not evil All the days of her life.", 
                "num": 12
              }, 
              {
                "text": "She seeks wool and flax, And willingly works with her hands.", 
                "num": 13
              }, 
              {
                "text": "She is like the merchant ships, She brings her food from afar.", 
                "num": 14
              }, 
              {
                "text": "She also rises while it is yet night, And provides food for her household, And a portion for her maidservants.", 
                "num": 15
              }, 
              {
                "text": "She considers a field and buys it; From her profits she plants a vineyard.", 
                "num": 16
              }, 
              {
                "text": "She girds herself with strength, And strengthens her arms.", 
                "num": 17
              }, 
              {
                "text": "She perceives that her merchandise is good, And her lamp does not go out by night.", 
                "num": 18
              }, 
              {
                "text": "She stretches out her hands to the distaff, And her hand holds the spindle.", 
                "num": 19
              }, 
              {
                "text": "She extends her hand to the poor, Yes, she reaches out her hands to the needy.", 
                "num": 20
              }, 
              {
                "text": "She is not afraid of snow for her household, For all her household is clothed with scarlet.", 
                "num": 21
              }, 
              {
                "text": "She makes tapestry for herself; Her clothing is fine linen and purple.", 
                "num": 22
              }, 
              {
                "text": "Her husband is known in the gates, When he sits among the elders of the land.", 
                "num": 23
              }, 
              {
                "text": "She makes linen garments and sells them, And supplies sashes for the merchants.", 
                "num": 24
              }, 
              {
                "text": "Strength and honor are her clothing; She shall rejoice in time to come.", 
                "num": 25
              }, 
              {
                "text": "She opens her mouth with wisdom, And on her tongue is the law of kindness.", 
                "num": 26
              }, 
              {
                "text": "She watches over the ways of her household, And does not eat the bread of idleness.", 
                "num": 27
              }, 
              {
                "text": "Her children rise up and call her blessed; Her husband also, and he praises her:", 
                "num": 28
              }, 
              {
                "text": "\"Many daughters have done well, But you excel them all.\"", 
                "num": 29
              }, 
              {
                "text": "Charm is deceitful and beauty is passing, But a woman who fears the LORD, she shall be praised.", 
                "num": 30
              }, 
              {
                "text": "Give her of the fruit of her hands, And let her own works praise her in the gates.", 
                "num": 31
              }
            ], 
            "num": 31
          }
        ]
      }, 
      {
        "name": "Ecclesiastes", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The words of the Preacher, the son of David, king in Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "\"Vanity of vanities,\" says the Preacher; \"Vanity of vanities, all is vanity.\"", 
                "num": 2
              }, 
              {
                "text": "What profit has a man from all his labor In which he toils under the sun?", 
                "num": 3
              }, 
              {
                "text": "One generation passes away, and another generation comes; But the earth abides forever.", 
                "num": 4
              }, 
              {
                "text": "The sun also rises, and the sun goes down, And hastens to the place where it arose.", 
                "num": 5
              }, 
              {
                "text": "The wind goes toward the south, And turns around to the north; The wind whirls about continually, And comes again on its circuit.", 
                "num": 6
              }, 
              {
                "text": "All the rivers run into the sea, Yet the sea is not full; To the place from which the rivers come, There they return again.", 
                "num": 7
              }, 
              {
                "text": "All things are full of labor; Man cannot express it. The eye is not satisfied with seeing, Nor the ear filled with hearing.", 
                "num": 8
              }, 
              {
                "text": "That which has been is what will be, That which is done is what will be done, And there is nothing new under the sun.", 
                "num": 9
              }, 
              {
                "text": "Is there anything of which it may be said, \"See, this is new\"? It has already been in ancient times before us.", 
                "num": 10
              }, 
              {
                "text": "There is no remembrance of former things, Nor will there be any remembrance of things that are to come By those who will come after.", 
                "num": 11
              }, 
              {
                "text": "I, the Preacher, was king over Israel in Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "And I set my heart to seek and search out by wisdom concerning all that is done under heaven; this burdensome task God has given to the sons of man, by which they may be exercised.", 
                "num": 13
              }, 
              {
                "text": "I have seen all the works that are done under the sun; and indeed, all is vanity and grasping for the wind.", 
                "num": 14
              }, 
              {
                "text": "What is crooked cannot be made straight, And what is lacking cannot be numbered.", 
                "num": 15
              }, 
              {
                "text": "I communed with my heart, saying, \"Look, I have attained greatness, and have gained more wisdom than all who were before me in Jerusalem. My heart has understood great wisdom and knowledge.\"", 
                "num": 16
              }, 
              {
                "text": "And I set my heart to know wisdom and to know madness and folly. I perceived that this also is grasping for the wind.", 
                "num": 17
              }, 
              {
                "text": "For in much wisdom is much grief, And he who increases knowledge increases sorrow.", 
                "num": 18
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "I said in my heart, \"Come now, I will test you with mirth; therefore enjoy pleasure\"; but surely, this also was vanity.", 
                "num": 1
              }, 
              {
                "text": "I said of laughter--\"Madness!\"; and of mirth, \"What does it accomplish?\"", 
                "num": 2
              }, 
              {
                "text": "I searched in my heart how to gratify my flesh with wine, while guiding my heart with wisdom, and how to lay hold on folly, till I might see what was good for the sons of men to do under heaven all the days of their lives.", 
                "num": 3
              }, 
              {
                "text": "I made my works great, I built myself houses, and planted myself vineyards.", 
                "num": 4
              }, 
              {
                "text": "I made myself gardens and orchards, and I planted all kinds of fruit trees in them.", 
                "num": 5
              }, 
              {
                "text": "I made myself water pools from which to water the growing trees of the grove.", 
                "num": 6
              }, 
              {
                "text": "I acquired male and female servants, and had servants born in my house. Yes, I had greater possessions of herds and flocks than all who were in Jerusalem before me.", 
                "num": 7
              }, 
              {
                "text": "I also gathered for myself silver and gold and the special treasures of kings and of the provinces. I acquired male and female singers, the delights of the sons of men, and musical instruments of all kinds.", 
                "num": 8
              }, 
              {
                "text": "So I became great and excelled more than all who were before me in Jerusalem. Also my wisdom remained with me.", 
                "num": 9
              }, 
              {
                "text": "Whatever my eyes desired I did not keep from them. I did not withhold my heart from any pleasure, For my heart rejoiced in all my labor; And this was my reward from all my labor.", 
                "num": 10
              }, 
              {
                "text": "Then I looked on all the works that my hands had done And on the labor in which I had toiled; And indeed all was vanity and grasping for the wind. There was no profit under the sun.", 
                "num": 11
              }, 
              {
                "text": "Then I turned myself to consider wisdom and madness and folly; For what can the man do who succeeds the king?-- Only what he has already done.", 
                "num": 12
              }, 
              {
                "text": "Then I saw that wisdom excels folly As light excels darkness.", 
                "num": 13
              }, 
              {
                "text": "The wise man's eyes are in his head, But the fool walks in darkness. Yet I myself perceived That the same event happens to them all.", 
                "num": 14
              }, 
              {
                "text": "So I said in my heart, \"As it happens to the fool, It also happens to me, And why was I then more wise?\" Then I said in my heart, \"This also is vanity.\"", 
                "num": 15
              }, 
              {
                "text": "For there is no more remembrance of the wise than of the fool forever, Since all that now is will be forgotten in the days to come. And how does a wise man die? As the fool!", 
                "num": 16
              }, 
              {
                "text": "Therefore I hated life because the work that was done under the sun was distressing to me, for all is vanity and grasping for the wind.", 
                "num": 17
              }, 
              {
                "text": "Then I hated all my labor in which I had toiled under the sun, because I must leave it to the man who will come after me.", 
                "num": 18
              }, 
              {
                "text": "And who knows whether he will be wise or a fool? Yet he will rule over all my labor in which I toiled and in which I have shown myself wise under the sun. This also is vanity.", 
                "num": 19
              }, 
              {
                "text": "Therefore I turned my heart and despaired of all the labor in which I had toiled under the sun.", 
                "num": 20
              }, 
              {
                "text": "For there is a man whose labor is with wisdom, knowledge, and skill; yet he must leave his heritage to a man who has not labored for it. This also is vanity and a great evil.", 
                "num": 21
              }, 
              {
                "text": "For what has man for all his labor, and for the striving of his heart with which he has toiled under the sun?", 
                "num": 22
              }, 
              {
                "text": "For all his days are sorrowful, and his work burdensome; even in the night his heart takes no rest. This also is vanity.", 
                "num": 23
              }, 
              {
                "text": "Nothing is better for a man than that he should eat and drink, and that his soul should enjoy good in his labor. This also, I saw, was from the hand of God.", 
                "num": 24
              }, 
              {
                "text": "For who can eat, or who can have enjoyment, more than I?", 
                "num": 25
              }, 
              {
                "text": "For God gives wisdom and knowledge and joy to a man who is good in His sight; but to the sinner He gives the work of gathering and collecting, that he may give to him who is good before God. This also is vanity and grasping for the wind.", 
                "num": 26
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "To everything there is a season, A time for every purpose under heaven:", 
                "num": 1
              }, 
              {
                "text": "A time to be born, And a time to die; A time to plant, And a time to pluck what is planted;", 
                "num": 2
              }, 
              {
                "text": "A time to kill, And a time to heal; A time to break down, And a time to build up;", 
                "num": 3
              }, 
              {
                "text": "A time to weep, And a time to laugh; A time to mourn, And a time to dance;", 
                "num": 4
              }, 
              {
                "text": "A time to cast away stones, And a time to gather stones; A time to embrace, And a time to refrain from embracing;", 
                "num": 5
              }, 
              {
                "text": "A time to gain, And a time to lose; A time to keep, And a time to throw away;", 
                "num": 6
              }, 
              {
                "text": "A time to tear, And a time to sew; A time to keep silence, And a time to speak;", 
                "num": 7
              }, 
              {
                "text": "A time to love, And a time to hate; A time of war, And a time of peace.", 
                "num": 8
              }, 
              {
                "text": "What profit has the worker from that in which he labors?", 
                "num": 9
              }, 
              {
                "text": "I have seen the God-given task with which the sons of men are to be occupied.", 
                "num": 10
              }, 
              {
                "text": "He has made everything beautiful in its time. Also He has put eternity in their hearts, except that no one can find out the work that God does from beginning to end.", 
                "num": 11
              }, 
              {
                "text": "I know that nothing is better for them than to rejoice, and to do good in their lives,", 
                "num": 12
              }, 
              {
                "text": "and also that every man should eat and drink and enjoy the good of all his labor--it is the gift of God.", 
                "num": 13
              }, 
              {
                "text": "I know that whatever God does, It shall be forever. Nothing can be added to it, And nothing taken from it. God does it, that men should fear before Him.", 
                "num": 14
              }, 
              {
                "text": "That which is has already been, And what is to be has already been; And God requires an account of what is past.", 
                "num": 15
              }, 
              {
                "text": "Moreover I saw under the sun: In the place of judgment, Wickedness was there; And in the place of righteousness, Iniquity was there.", 
                "num": 16
              }, 
              {
                "text": "I said in my heart, \"God shall judge the righteous and the wicked, For there is a time there for every purpose and for every work.\"", 
                "num": 17
              }, 
              {
                "text": "I said in my heart, \"Concerning the condition of the sons of men, God tests them, that they may see that they themselves are like animals.\"", 
                "num": 18
              }, 
              {
                "text": "For what happens to the sons of men also happens to animals; one thing befalls them: as one dies, so dies the other. Surely, they all have one breath; man has no advantage over animals, for all is vanity.", 
                "num": 19
              }, 
              {
                "text": "All go to one place: all are from the dust, and all return to dust.", 
                "num": 20
              }, 
              {
                "text": "Who knows the spirit of the sons of men, which goes upward, and the spirit of the animal, which goes down to the earth?", 
                "num": 21
              }, 
              {
                "text": "So I perceived that nothing is better than that a man should rejoice in his own works, for that is his heritage. For who can bring him to see what will happen after him?", 
                "num": 22
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then I returned and considered all the oppression that is done under the sun: And look! The tears of the oppressed, But they have no comforter-- On the side of their oppressors there is power, But they have no comforter.", 
                "num": 1
              }, 
              {
                "text": "Therefore I praised the dead who were already dead, More than the living who are still alive.", 
                "num": 2
              }, 
              {
                "text": "Yet, better than both is he who has never existed, Who has not seen the evil work that is done under the sun.", 
                "num": 3
              }, 
              {
                "text": "Again, I saw that for all toil and every skillful work a man is envied by his neighbor. This also is vanity and grasping for the wind.", 
                "num": 4
              }, 
              {
                "text": "The fool folds his hands And consumes his own flesh.", 
                "num": 5
              }, 
              {
                "text": "Better a handful with quietness Than both hands full, together with toil and grasping for the wind.", 
                "num": 6
              }, 
              {
                "text": "Then I returned, and I saw vanity under the sun:", 
                "num": 7
              }, 
              {
                "text": "There is one alone, without companion: He has neither son nor brother. Yet there is no end to all his labors, Nor is his eye satisfied with riches. But he never asks, \"For whom do I toil and deprive myself of good?\" This also is vanity and a grave misfortune.", 
                "num": 8
              }, 
              {
                "text": "Two are better than one, Because they have a good reward for their labor.", 
                "num": 9
              }, 
              {
                "text": "For if they fall, one will lift up his companion. But woe to him who is alone when he falls, For he has no one to help him up.", 
                "num": 10
              }, 
              {
                "text": "Again, if two lie down together, they will keep warm; But how can one be warm alone?", 
                "num": 11
              }, 
              {
                "text": "Though one may be overpowered by another, two can withstand him. And a threefold cord is not quickly broken.", 
                "num": 12
              }, 
              {
                "text": "Better a poor and wise youth Than an old and foolish king who will be admonished no more.", 
                "num": 13
              }, 
              {
                "text": "For he comes out of prison to be king, Although he was born poor in his kingdom.", 
                "num": 14
              }, 
              {
                "text": "I saw all the living who walk under the sun; They were with the second youth who stands in his place.", 
                "num": 15
              }, 
              {
                "text": "There was no end of all the people over whom he was made king; Yet those who come afterward will not rejoice in him. Surely this also is vanity and grasping for the wind.", 
                "num": 16
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Walk prudently when you go to the house of God; and draw near to hear rather than to give the sacrifice of fools, for they do not know that they do evil.", 
                "num": 1
              }, 
              {
                "text": "Do not be rash with your mouth, And let not your heart utter anything hastily before God. For God is in heaven, and you on earth; Therefore let your words be few.", 
                "num": 2
              }, 
              {
                "text": "For a dream comes through much activity, And a fool's voice is known by his many words.", 
                "num": 3
              }, 
              {
                "text": "When you make a vow to God, do not delay to pay it; For He has no pleasure in fools. Pay what you have vowed--", 
                "num": 4
              }, 
              {
                "text": "Better not to vow than to vow and not pay.", 
                "num": 5
              }, 
              {
                "text": "Do not let your mouth cause your flesh to sin, nor say before the messenger of God that it was an error. Why should God be angry at your excuse and destroy the work of your hands?", 
                "num": 6
              }, 
              {
                "text": "For in the multitude of dreams and many words there is also vanity. But fear God.", 
                "num": 7
              }, 
              {
                "text": "If you see the oppression of the poor, and the violent perversion of justice and righteousness in a province, do not marvel at the matter; for high official watches over high official, and higher officials are over them.", 
                "num": 8
              }, 
              {
                "text": "Moreover the profit of the land is for all; even the king is served from the field.", 
                "num": 9
              }, 
              {
                "text": "He who loves silver will not be satisfied with silver; Nor he who loves abundance, with increase. This also is vanity.", 
                "num": 10
              }, 
              {
                "text": "When goods increase, They increase who eat them; So what profit have the owners Except to see them with their eyes?", 
                "num": 11
              }, 
              {
                "text": "The sleep of a laboring man is sweet, Whether he eats little or much; But the abundance of the rich will not permit him to sleep.", 
                "num": 12
              }, 
              {
                "text": "There is a severe evil which I have seen under the sun: Riches kept for their owner to his hurt.", 
                "num": 13
              }, 
              {
                "text": "But those riches perish through misfortune; When he begets a son, there is nothing in his hand.", 
                "num": 14
              }, 
              {
                "text": "As he came from his mother's womb, naked shall he return, To go as he came; And he shall take nothing from his labor Which he may carry away in his hand.", 
                "num": 15
              }, 
              {
                "text": "And this also is a severe evil-- Just exactly as he came, so shall he go. And what profit has he who has labored for the wind?", 
                "num": 16
              }, 
              {
                "text": "All his days he also eats in darkness, And he has much sorrow and sickness and anger.", 
                "num": 17
              }, 
              {
                "text": "Here is what I have seen: It is good and fitting for one to eat and drink, and to enjoy the good of all his labor in which he toils under the sun all the days of his life which God gives him; for it is his heritage.", 
                "num": 18
              }, 
              {
                "text": "As for every man to whom God has given riches and wealth, and given him power to eat of it, to receive his heritage and rejoice in his labor--this is the gift of God.", 
                "num": 19
              }, 
              {
                "text": "For he will not dwell unduly on the days of his life, because God keeps him busy with the joy of his heart.", 
                "num": 20
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "There is an evil which I have seen under the sun, and it is common among men:", 
                "num": 1
              }, 
              {
                "text": "A man to whom God has given riches and wealth and honor, so that he lacks nothing for himself of all he desires; yet God does not give him power to eat of it, but a foreigner consumes it. This is vanity, and it is an evil affliction.", 
                "num": 2
              }, 
              {
                "text": "If a man begets a hundred children and lives many years, so that the days of his years are many, but his soul is not satisfied with goodness, or indeed he has no burial, I say that a stillborn child is better than he--", 
                "num": 3
              }, 
              {
                "text": "for it comes in vanity and departs in darkness, and its name is covered with darkness.", 
                "num": 4
              }, 
              {
                "text": "Though it has not seen the sun or known anything, this has more rest than that man,", 
                "num": 5
              }, 
              {
                "text": "even if he lives a thousand years twice--but has not seen goodness. Do not all go to one place?", 
                "num": 6
              }, 
              {
                "text": "All the labor of man is for his mouth, And yet the soul is not satisfied.", 
                "num": 7
              }, 
              {
                "text": "For what more has the wise man than the fool? What does the poor man have, Who knows how to walk before the living?", 
                "num": 8
              }, 
              {
                "text": "Better is the sight of the eyes than the wandering of desire. This also is vanity and grasping for the wind.", 
                "num": 9
              }, 
              {
                "text": "Whatever one is, he has been named already, For it is known that he is man; And he cannot contend with Him who is mightier than he.", 
                "num": 10
              }, 
              {
                "text": "Since there are many things that increase vanity, How is man the better?", 
                "num": 11
              }, 
              {
                "text": "For who knows what is good for man in life, all the days of his vain life which he passes like a shadow? Who can tell a man what will happen after him under the sun?", 
                "num": 12
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "A good name is better than precious ointment, And the day of death than the day of one's birth;", 
                "num": 1
              }, 
              {
                "text": "Better to go to the house of mourning Than to go to the house of feasting, For that is the end of all men; And the living will take it to heart.", 
                "num": 2
              }, 
              {
                "text": "Sorrow is better than laughter, For by a sad countenance the heart is made better.", 
                "num": 3
              }, 
              {
                "text": "The heart of the wise is in the house of mourning, But the heart of fools is in the house of mirth.", 
                "num": 4
              }, 
              {
                "text": "It is better to hear the rebuke of the wise Than for a man to hear the song of fools.", 
                "num": 5
              }, 
              {
                "text": "For like the crackling of thorns under a pot, So is the laughter of the fool. This also is vanity.", 
                "num": 6
              }, 
              {
                "text": "Surely oppression destroys a wise man's reason, And a bribe debases the heart.", 
                "num": 7
              }, 
              {
                "text": "The end of a thing is better than its beginning; The patient in spirit is better than the proud in spirit.", 
                "num": 8
              }, 
              {
                "text": "Do not hasten in your spirit to be angry, For anger rests in the bosom of fools.", 
                "num": 9
              }, 
              {
                "text": "Do not say, \"Why were the former days better than these?\" For you do not inquire wisely concerning this.", 
                "num": 10
              }, 
              {
                "text": "Wisdom is good with an inheritance, And profitable to those who see the sun.", 
                "num": 11
              }, 
              {
                "text": "For wisdom is a defense as money is a defense, But the excellence of knowledge is that wisdom gives life to those who have it.", 
                "num": 12
              }, 
              {
                "text": "Consider the work of God; For who can make straight what He has made crooked?", 
                "num": 13
              }, 
              {
                "text": "In the day of prosperity be joyful, But in the day of adversity consider: Surely God has appointed the one as well as the other, So that man can find out nothing that will come after him.", 
                "num": 14
              }, 
              {
                "text": "I have seen everything in my days of vanity: There is a just man who perishes in his righteousness, And there is a wicked man who prolongs life in his wickedness.", 
                "num": 15
              }, 
              {
                "text": "Do not be overly righteous, Nor be overly wise: Why should you destroy yourself?", 
                "num": 16
              }, 
              {
                "text": "Do not be overly wicked, Nor be foolish: Why should you die before your time?", 
                "num": 17
              }, 
              {
                "text": "It is good that you grasp this, And also not remove your hand from the other; For he who fears God will escape them all.", 
                "num": 18
              }, 
              {
                "text": "Wisdom strengthens the wise More than ten rulers of the city.", 
                "num": 19
              }, 
              {
                "text": "For there is not a just man on earth who does good And does not sin.", 
                "num": 20
              }, 
              {
                "text": "Also do not take to heart everything people say, Lest you hear your servant cursing you.", 
                "num": 21
              }, 
              {
                "text": "For many times, also, your own heart has known That even you have cursed others.", 
                "num": 22
              }, 
              {
                "text": "All this I have proved by wisdom. I said, \"I will be wise\"; But it was far from me.", 
                "num": 23
              }, 
              {
                "text": "As for that which is far off and exceedingly deep, Who can find it out?", 
                "num": 24
              }, 
              {
                "text": "I applied my heart to know, To search and seek out wisdom and the reason of things, To know the wickedness of folly, Even of foolishness and madness.", 
                "num": 25
              }, 
              {
                "text": "And I find more bitter than death The woman whose heart is snares and nets, Whose hands are fetters. He who pleases God shall escape from her, But the sinner shall be trapped by her.", 
                "num": 26
              }, 
              {
                "text": "\"Here is what I have found,\" says the Preacher, \"Adding one thing to the other to find out the reason,", 
                "num": 27
              }, 
              {
                "text": "Which my soul still seeks but I cannot find: One man among a thousand I have found, But a woman among all these I have not found.", 
                "num": 28
              }, 
              {
                "text": "Truly, this only I have found: That God made man upright, But they have sought out many schemes.\"", 
                "num": 29
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Who is like a wise man? And who knows the interpretation of a thing? A man's wisdom makes his face shine, And the sternness of his face is changed.", 
                "num": 1
              }, 
              {
                "text": "I say, \"Keep the king's commandment for the sake of your oath to God.", 
                "num": 2
              }, 
              {
                "text": "Do not be hasty to go from his presence. Do not take your stand for an evil thing, for he does whatever pleases him.\"", 
                "num": 3
              }, 
              {
                "text": "Where the word of a king is, there is power; And who may say to him, \"What are you doing?\"", 
                "num": 4
              }, 
              {
                "text": "He who keeps his command will experience nothing harmful; And a wise man's heart discerns both time and judgment,", 
                "num": 5
              }, 
              {
                "text": "Because for every matter there is a time and judgment, Though the misery of man increases greatly.", 
                "num": 6
              }, 
              {
                "text": "For he does not know what will happen; So who can tell him when it will occur?", 
                "num": 7
              }, 
              {
                "text": "No one has power over the spirit to retain the spirit, And no one has power in the day of death. There is no release from that war, And wickedness will not deliver those who are given to it.", 
                "num": 8
              }, 
              {
                "text": "All this I have seen, and applied my heart to every work that is done under the sun: There is a time in which one man rules over another to his own hurt.", 
                "num": 9
              }, 
              {
                "text": "Then I saw the wicked buried, who had come and gone from the place of holiness, and they were forgotten in the city where they had so done. This also is vanity.", 
                "num": 10
              }, 
              {
                "text": "Because the sentence against an evil work is not executed speedily, therefore the heart of the sons of men is fully set in them to do evil.", 
                "num": 11
              }, 
              {
                "text": "Though a sinner does evil a hundred times, and his days are prolonged, yet I surely know that it will be well with those who fear God, who fear before Him.", 
                "num": 12
              }, 
              {
                "text": "But it will not be well with the wicked; nor will he prolong his days, which are as a shadow, because he does not fear before God.", 
                "num": 13
              }, 
              {
                "text": "There is a vanity which occurs on earth, that there are just men to whom it happens according to the work of the wicked; again, there are wicked men to whom it happens according to the work of the righteous. I said that this also is vanity.", 
                "num": 14
              }, 
              {
                "text": "So I commended enjoyment, because a man has nothing better under the sun than to eat, drink, and be merry; for this will remain with him in his labor all the days of his life which God gives him under the sun.", 
                "num": 15
              }, 
              {
                "text": "When I applied my heart to know wisdom and to see the business that is done on earth, even though one sees no sleep day or night,", 
                "num": 16
              }, 
              {
                "text": "then I saw all the work of God, that a man cannot find out the work that is done under the sun. For though a man labors to discover it, yet he will not find it; moreover, though a wise man attempts to know it, he will not be able to find it.", 
                "num": 17
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "For I considered all this in my heart, so that I could declare it all: that the righteous and the wise and their works are in the hand of God. People know neither love nor hatred by anything they see before them.", 
                "num": 1
              }, 
              {
                "text": "All things come alike to all: One event happens to the righteous and the wicked; To the good, the clean, and the unclean; To him who sacrifices and him who does not sacrifice. As is the good, so is the sinner; He who takes an oath as he who fears an oath.", 
                "num": 2
              }, 
              {
                "text": "This is an evil in all that is done under the sun: that one thing happens to all. Truly the hearts of the sons of men are full of evil; madness is in their hearts while they live, and after that they go to the dead.", 
                "num": 3
              }, 
              {
                "text": "But for him who is joined to all the living there is hope, for a living dog is better than a dead lion.", 
                "num": 4
              }, 
              {
                "text": "For the living know that they will die; But the dead know nothing, And they have no more reward, For the memory of them is forgotten.", 
                "num": 5
              }, 
              {
                "text": "Also their love, their hatred, and their envy have now perished; Nevermore will they have a share In anything done under the sun.", 
                "num": 6
              }, 
              {
                "text": "Go, eat your bread with joy, And drink your wine with a merry heart; For God has already accepted your works.", 
                "num": 7
              }, 
              {
                "text": "Let your garments always be white, And let your head lack no oil.", 
                "num": 8
              }, 
              {
                "text": "Live joyfully with the wife whom you love all the days of your vain life which He has given you under the sun, all your days of vanity; for that is your portion in life, and in the labor which you perform under the sun.", 
                "num": 9
              }, 
              {
                "text": "Whatever your hand finds to do, do it with your might; for there is no work or device or knowledge or wisdom in the grave where you are going.", 
                "num": 10
              }, 
              {
                "text": "I returned and saw under the sun that-- The race is not to the swift, Nor the battle to the strong, Nor bread to the wise, Nor riches to men of understanding, Nor favor to men of skill; But time and chance happen to them all.", 
                "num": 11
              }, 
              {
                "text": "For man also does not know his time: Like fish taken in a cruel net, Like birds caught in a snare, So the sons of men are snared in an evil time, When it falls suddenly upon them.", 
                "num": 12
              }, 
              {
                "text": "This wisdom I have also seen under the sun, and it seemed great to me:", 
                "num": 13
              }, 
              {
                "text": "There was a little city with few men in it; and a great king came against it, besieged it, and built great snares around it.", 
                "num": 14
              }, 
              {
                "text": "Now there was found in it a poor wise man, and he by his wisdom delivered the city. Yet no one remembered that same poor man.", 
                "num": 15
              }, 
              {
                "text": "Then I said: \"Wisdom is better than strength. Nevertheless the poor man's wisdom is despised, And his words are not heard.", 
                "num": 16
              }, 
              {
                "text": "Words of the wise, spoken quietly, should be heard Rather than the shout of a ruler of fools.", 
                "num": 17
              }, 
              {
                "text": "Wisdom is better than weapons of war; But one sinner destroys much good.\"", 
                "num": 18
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Dead flies putrefy the perfumer's ointment, And cause it to give off a foul odor; So does a little folly to one respected for wisdom and honor.", 
                "num": 1
              }, 
              {
                "text": "A wise man's heart is at his right hand, But a fool's heart at his left.", 
                "num": 2
              }, 
              {
                "text": "Even when a fool walks along the way, He lacks wisdom, And he shows everyone that he is a fool.", 
                "num": 3
              }, 
              {
                "text": "If the spirit of the ruler rises against you, Do not leave your post; For conciliation pacifies great offenses.", 
                "num": 4
              }, 
              {
                "text": "There is an evil I have seen under the sun, As an error proceeding from the ruler:", 
                "num": 5
              }, 
              {
                "text": "Folly is set in great dignity, While the rich sit in a lowly place.", 
                "num": 6
              }, 
              {
                "text": "I have seen servants on horses, While princes walk on the ground like servants.", 
                "num": 7
              }, 
              {
                "text": "He who digs a pit will fall into it, And whoever breaks through a wall will be bitten by a serpent.", 
                "num": 8
              }, 
              {
                "text": "He who quarries stones may be hurt by them, And he who splits wood may be endangered by it.", 
                "num": 9
              }, 
              {
                "text": "If the ax is dull, And one does not sharpen the edge, Then he must use more strength; But wisdom brings success.", 
                "num": 10
              }, 
              {
                "text": "A serpent may bite when it is not charmed; The babbler is no different.", 
                "num": 11
              }, 
              {
                "text": "The words of a wise man's mouth are gracious, But the lips of a fool shall swallow him up;", 
                "num": 12
              }, 
              {
                "text": "The words of his mouth begin with foolishness, And the end of his talk is raving madness.", 
                "num": 13
              }, 
              {
                "text": "A fool also multiplies words. No man knows what is to be; Who can tell him what will be after him?", 
                "num": 14
              }, 
              {
                "text": "The labor of fools wearies them, For they do not even know how to go to the city!", 
                "num": 15
              }, 
              {
                "text": "Woe to you, O land, when your king is a child, And your princes feast in the morning!", 
                "num": 16
              }, 
              {
                "text": "Blessed are you, O land, when your king is the son of nobles, And your princes feast at the proper time-- For strength and not for drunkenness!", 
                "num": 17
              }, 
              {
                "text": "Because of laziness the building decays, And through idleness of hands the house leaks.", 
                "num": 18
              }, 
              {
                "text": "A feast is made for laughter, And wine makes merry; But money answers everything.", 
                "num": 19
              }, 
              {
                "text": "Do not curse the king, even in your thought; Do not curse the rich, even in your bedroom; For a bird of the air may carry your voice, And a bird in flight may tell the matter.", 
                "num": 20
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Cast your bread upon the waters, For you will find it after many days.", 
                "num": 1
              }, 
              {
                "text": "Give a serving to seven, and also to eight, For you do not know what evil will be on the earth.", 
                "num": 2
              }, 
              {
                "text": "If the clouds are full of rain, They empty themselves upon the earth; And if a tree falls to the south or the north, In the place where the tree falls, there it shall lie.", 
                "num": 3
              }, 
              {
                "text": "He who observes the wind will not sow, And he who regards the clouds will not reap.", 
                "num": 4
              }, 
              {
                "text": "As you do not know what is the way of the wind, Or how the bones grow in the womb of her who is with child, So you do not know the works of God who makes everything.", 
                "num": 5
              }, 
              {
                "text": "In the morning sow your seed, And in the evening do not withhold your hand; For you do not know which will prosper, Either this or that, Or whether both alike will be good.", 
                "num": 6
              }, 
              {
                "text": "Truly the light is sweet, And it is pleasant for the eyes to behold the sun;", 
                "num": 7
              }, 
              {
                "text": "But if a man lives many years And rejoices in them all, Yet let him remember the days of darkness, For they will be many. All that is coming is vanity.", 
                "num": 8
              }, 
              {
                "text": "Rejoice, O young man, in your youth, And let your heart cheer you in the days of your youth; Walk in the ways of your heart, And in the sight of your eyes; But know that for all these God will bring you into judgment.", 
                "num": 9
              }, 
              {
                "text": "Therefore remove sorrow from your heart, And put away evil from your flesh, For childhood and youth are vanity.", 
                "num": 10
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Remember now your Creator in the days of your youth, Before the difficult days come, And the years draw near when you say, \"I have no pleasure in them\":", 
                "num": 1
              }, 
              {
                "text": "While the sun and the light, The moon and the stars, Are not darkened, And the clouds do not return after the rain;", 
                "num": 2
              }, 
              {
                "text": "In the day when the keepers of the house tremble, And the strong men bow down; When the grinders cease because they are few, And those that look through the windows grow dim;", 
                "num": 3
              }, 
              {
                "text": "When the doors are shut in the streets, And the sound of grinding is low; When one rises up at the sound of a bird, And all the daughters of music are brought low.", 
                "num": 4
              }, 
              {
                "text": "Also they are afraid of height, And of terrors in the way; When the almond tree blossoms, The grasshopper is a burden, And desire fails. For man goes to his eternal home, And the mourners go about the streets.", 
                "num": 5
              }, 
              {
                "text": "Remember your Creator before the silver cord is loosed, Or the golden bowl is broken, Or the pitcher shattered at the fountain, Or the wheel broken at the well.", 
                "num": 6
              }, 
              {
                "text": "Then the dust will return to the earth as it was, And the spirit will return to God who gave it.", 
                "num": 7
              }, 
              {
                "text": "\"Vanity of vanities,\" says the Preacher, \"All is vanity.\"", 
                "num": 8
              }, 
              {
                "text": "And moreover, because the Preacher was wise, he still taught the people knowledge; yes, he pondered and sought out and set in order many proverbs.", 
                "num": 9
              }, 
              {
                "text": "The Preacher sought to find acceptable words; and what was written was upright--words of truth.", 
                "num": 10
              }, 
              {
                "text": "The words of the wise are like goads, and the words of scholars are like well-driven nails, given by one Shepherd.", 
                "num": 11
              }, 
              {
                "text": "And further, my son, be admonished by these. Of making many books there is no end, and much study is wearisome to the flesh.", 
                "num": 12
              }, 
              {
                "text": "Let us hear the conclusion of the whole matter: Fear God and keep His commandments, For this is man's all.", 
                "num": 13
              }, 
              {
                "text": "For God will bring every work into judgment, Including every secret thing, Whether good or evil.", 
                "num": 14
              }
            ], 
            "num": 12
          }
        ]
      }, 
      {
        "name": "Song of Solomon", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The song of songs, which is Solomon's.", 
                "num": 1
              }, 
              {
                "text": "Let him kiss me with the kisses of his mouth-- For your love is better than wine.", 
                "num": 2
              }, 
              {
                "text": "Because of the fragrance of your good ointments, Your name is ointment poured forth; Therefore the virgins love you.", 
                "num": 3
              }, 
              {
                "text": "Draw me away! We will run after you. The king has brought me into his chambers. We will be glad and rejoice in you. We will remember 1your love more than wine. Rightly do they love 1you.", 
                "num": 4
              }, 
              {
                "text": "I am dark, but lovely, O daughters of Jerusalem, Like the tents of Kedar, Like the curtains of Solomon.", 
                "num": 5
              }, 
              {
                "text": "Do not look upon me, because I am dark, Because the sun has tanned me. My mother's sons were angry with me; They made me the keeper of the vineyards, But my own vineyard I have not kept.", 
                "num": 6
              }, 
              {
                "text": "Tell me, O you whom I love, Where you feed your flock, Where you make it rest at noon. For why should I be as one who veils herself By the flocks of your companions?", 
                "num": 7
              }, 
              {
                "text": "If you do not know, O fairest among women, Follow in the footsteps of the flock, And feed your little goats Beside the shepherds' tents.", 
                "num": 8
              }, 
              {
                "text": "I have compared you, my love, To my filly among Pharaoh's chariots.", 
                "num": 9
              }, 
              {
                "text": "Your cheeks are lovely with ornaments, Your neck with chains of gold.", 
                "num": 10
              }, 
              {
                "text": "We will make you ornaments of gold With studs of silver.", 
                "num": 11
              }, 
              {
                "text": "While the king is at his table, My spikenard sends forth its fragrance.", 
                "num": 12
              }, 
              {
                "text": "A bundle of myrrh is my beloved to me, That lies all night between my breasts.", 
                "num": 13
              }, 
              {
                "text": "My beloved is to me a cluster of henna blooms In the vineyards of En Gedi.", 
                "num": 14
              }, 
              {
                "text": "Behold, you are fair, my love! Behold, you are fair! You have dove's eyes.", 
                "num": 15
              }, 
              {
                "text": "Behold, you are handsome, my beloved! Yes, pleasant! Also our bed is green.", 
                "num": 16
              }, 
              {
                "text": "The beams of our houses are cedar, And our rafters of fir.", 
                "num": 17
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "I am the rose of Sharon, And the lily of the valleys.", 
                "num": 1
              }, 
              {
                "text": "Like a lily among thorns, So is my love among the daughters.", 
                "num": 2
              }, 
              {
                "text": "Like an apple tree among the trees of the woods, So is my beloved among the sons. I sat down in his shade with great delight, And his fruit was sweet to my taste.", 
                "num": 3
              }, 
              {
                "text": "He brought me to the banqueting house, And his banner over me was love.", 
                "num": 4
              }, 
              {
                "text": "Sustain me with cakes of raisins, Refresh me with apples, For I am lovesick.", 
                "num": 5
              }, 
              {
                "text": "His left hand is under my head, And his right hand embraces me.", 
                "num": 6
              }, 
              {
                "text": "I charge you, O daughters of Jerusalem, By the gazelles or by the does of the field, Do not stir up nor awaken love Until it pleases.", 
                "num": 7
              }, 
              {
                "text": "The voice of my beloved! Behold, he comes Leaping upon the mountains, Skipping upon the hills.", 
                "num": 8
              }, 
              {
                "text": "My beloved is like a gazelle or a young stag. Behold, he stands behind our wall; He is looking through the windows, Gazing through the lattice.", 
                "num": 9
              }, 
              {
                "text": "My beloved spoke, and said to me: \"Rise up, my love, my fair one, And come away.", 
                "num": 10
              }, 
              {
                "text": "For lo, the winter is past, The rain is over and gone.", 
                "num": 11
              }, 
              {
                "text": "The flowers appear on the earth; The time of singing has come, And the voice of the turtledove Is heard in our land.", 
                "num": 12
              }, 
              {
                "text": "The fig tree puts forth her green figs, And the vines with the tender grapes Give a good smell. Rise up, my love, my fair one, And come away!", 
                "num": 13
              }, 
              {
                "text": "\"O my dove, in the clefts of the rock, In the secret places of the cliff, Let me see your face, Let me hear your voice; For your voice is sweet, And your face is lovely.\"", 
                "num": 14
              }, 
              {
                "text": "Catch us the foxes, The little foxes that spoil the vines, For our vines have tender grapes.", 
                "num": 15
              }, 
              {
                "text": "My beloved is mine, and I am his. He feeds his flock among the lilies.", 
                "num": 16
              }, 
              {
                "text": "Until the day breaks And the shadows flee away, Turn, my beloved, And be like a gazelle Or a young stag Upon the mountains of Bether.", 
                "num": 17
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "By night on my bed I sought the one I love; I sought him, but I did not find him.", 
                "num": 1
              }, 
              {
                "text": "\"I will rise now,\" I said, \"And go about the city; In the streets and in the squares I will seek the one I love.\" I sought him, but I did not find him.", 
                "num": 2
              }, 
              {
                "text": "The watchmen who go about the city found me; I said, \"Have you seen the one I love?\"", 
                "num": 3
              }, 
              {
                "text": "Scarcely had I passed by them, When I found the one I love. I held him and would not let him go, Until I had brought him to the house of my mother, And into the chamber of her who conceived me.", 
                "num": 4
              }, 
              {
                "text": "I charge you, O daughters of Jerusalem, By the gazelles or by the does of the field, Do not stir up nor awaken love Until it pleases.", 
                "num": 5
              }, 
              {
                "text": "Who is this coming out of the wilderness Like pillars of smoke, Perfumed with myrrh and frankincense, With all the merchant's fragrant powders?", 
                "num": 6
              }, 
              {
                "text": "Behold, it is Solomon's couch, With sixty valiant men around it, Of the valiant of Israel.", 
                "num": 7
              }, 
              {
                "text": "They all hold swords, Being expert in war. Every man has his sword on his thigh Because of fear in the night.", 
                "num": 8
              }, 
              {
                "text": "Of the wood of Lebanon Solomon the King Made himself a palanquin:", 
                "num": 9
              }, 
              {
                "text": "He made its pillars of silver, Its support of gold, Its seat of purple, Its interior paved with love By the daughters of Jerusalem.", 
                "num": 10
              }, 
              {
                "text": "Go forth, O daughters of Zion, And see King Solomon with the crown With which his mother crowned him On the day of his wedding, The day of the gladness of his heart.", 
                "num": 11
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Behold, you are fair, my love! Behold, you are fair! You have dove's eyes behind your veil. Your hair is like a flock of goats, Going down from Mount Gilead.", 
                "num": 1
              }, 
              {
                "text": "Your teeth are like a flock of shorn sheep Which have come up from the washing, Every one of which bears twins, And none is barren among them.", 
                "num": 2
              }, 
              {
                "text": "Your lips are like a strand of scarlet, And your mouth is lovely. Your temples behind your veil Are like a piece of pomegranate.", 
                "num": 3
              }, 
              {
                "text": "Your neck is like the tower of David, Built for an armory, On which hang a thousand bucklers, All shields of mighty men.", 
                "num": 4
              }, 
              {
                "text": "Your two breasts are like two fawns, Twins of a gazelle, Which feed among the lilies.", 
                "num": 5
              }, 
              {
                "text": "Until the day breaks And the shadows flee away, I will go my way to the mountain of myrrh And to the hill of frankincense.", 
                "num": 6
              }, 
              {
                "text": "You are all fair, my love, And there is no spot in you.", 
                "num": 7
              }, 
              {
                "text": "Come with me from Lebanon, my spouse, With me from Lebanon. Look from the top of Amana, From the top of Senir and Hermon, From the lions' dens, From the mountains of the leopards.", 
                "num": 8
              }, 
              {
                "text": "You have ravished my heart, My sister, my spouse; You have ravished my heart With one look of your eyes, With one link of your necklace.", 
                "num": 9
              }, 
              {
                "text": "How fair is your love, My sister, my spouse! How much better than wine is your love, And the scent of your perfumes Than all spices!", 
                "num": 10
              }, 
              {
                "text": "Your lips, O my spouse, Drip as the honeycomb; Honey and milk are under your tongue; And the fragrance of your garments Is like the fragrance of Lebanon.", 
                "num": 11
              }, 
              {
                "text": "A garden enclosed Is my sister, my spouse, A spring shut up, A fountain sealed.", 
                "num": 12
              }, 
              {
                "text": "Your plants are an orchard of pomegranates With pleasant fruits, Fragrant henna with spikenard,", 
                "num": 13
              }, 
              {
                "text": "Spikenard and saffron, Calamus and cinnamon, With all trees of frankincense, Myrrh and aloes, With all the chief spices--", 
                "num": 14
              }, 
              {
                "text": "A fountain of gardens, A well of living waters, And streams from Lebanon.", 
                "num": 15
              }, 
              {
                "text": "Awake, O north wind, And come, O south! Blow upon my garden, That its spices may flow out. Let my beloved come to his garden And eat its pleasant fruits.", 
                "num": 16
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "I have come to my garden, my sister, my spouse; I have gathered my myrrh with my spice; I have eaten my honeycomb with my honey; I have drunk my wine with my milk. Eat, O friends! Drink, yes, drink deeply, O beloved ones!", 
                "num": 1
              }, 
              {
                "text": "I sleep, but my heart is awake; It is the voice of my beloved! He knocks, saying, \"Open for me, my sister, my love, My dove, my perfect one; For my head is covered with dew, My locks with the drops of the night.\"", 
                "num": 2
              }, 
              {
                "text": "I have taken off my robe; How can I put it on again? I have washed my feet; How can I defile them?", 
                "num": 3
              }, 
              {
                "text": "My beloved put his hand By the latch of the door, And my heart yearned for him.", 
                "num": 4
              }, 
              {
                "text": "I arose to open for my beloved, And my hands dripped with myrrh, My fingers with liquid myrrh, On the handles of the lock.", 
                "num": 5
              }, 
              {
                "text": "I opened for my beloved, But my beloved had turned away and was gone. My heart leaped up when he spoke. I sought him, but I could not find him; I called him, but he gave me no answer.", 
                "num": 6
              }, 
              {
                "text": "The watchmen who went about the city found me. They struck me, they wounded me; The keepers of the walls Took my veil away from me.", 
                "num": 7
              }, 
              {
                "text": "I charge you, O daughters of Jerusalem, If you find my beloved, That you tell him I am lovesick!", 
                "num": 8
              }, 
              {
                "text": "What is your beloved More than another beloved, O fairest among women? What is your beloved More than another beloved, That you so charge us?", 
                "num": 9
              }, 
              {
                "text": "My beloved is white and ruddy, Chief among ten thousand.", 
                "num": 10
              }, 
              {
                "text": "His head is like the finest gold; His locks are wavy, And black as a raven.", 
                "num": 11
              }, 
              {
                "text": "His eyes are like doves By the rivers of waters, Washed with milk, And fitly set.", 
                "num": 12
              }, 
              {
                "text": "His cheeks are like a bed of spices, Banks of scented herbs. His lips are lilies, Dripping liquid myrrh.", 
                "num": 13
              }, 
              {
                "text": "His hands are rods of gold Set with beryl. His body is carved ivory Inlaid with sapphires.", 
                "num": 14
              }, 
              {
                "text": "His legs are pillars of marble Set on bases of fine gold. His countenance is like Lebanon, Excellent as the cedars.", 
                "num": 15
              }, 
              {
                "text": "His mouth is most sweet, Yes, he is altogether lovely. This is my beloved, And this is my friend, O daughters of Jerusalem!", 
                "num": 16
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Where has your beloved gone, O fairest among women? Where has your beloved turned aside, That we may seek him with you?", 
                "num": 1
              }, 
              {
                "text": "My beloved has gone to his garden, To the beds of spices, To feed his flock in the gardens, And to gather lilies.", 
                "num": 2
              }, 
              {
                "text": "I am my beloved's, And my beloved is mine. He feeds his flock among the lilies.", 
                "num": 3
              }, 
              {
                "text": "O my love, you are as beautiful as Tirzah, Lovely as Jerusalem, Awesome as an army with banners!", 
                "num": 4
              }, 
              {
                "text": "Turn your eyes away from me, For they have overcome me. Your hair is like a flock of goats Going down from Gilead.", 
                "num": 5
              }, 
              {
                "text": "Your teeth are like a flock of sheep Which have come up from the washing; Every one bears twins, And none is barren among them.", 
                "num": 6
              }, 
              {
                "text": "Like a piece of pomegranate Are your temples behind your veil.", 
                "num": 7
              }, 
              {
                "text": "There are sixty queens And eighty concubines, And virgins without number.", 
                "num": 8
              }, 
              {
                "text": "My dove, my perfect one, Is the only one, The only one of her mother, The favorite of the one who bore her. The daughters saw her And called her blessed, The queens and the concubines, And they praised her.", 
                "num": 9
              }, 
              {
                "text": "Who is she who looks forth as the morning, Fair as the moon, Clear as the sun, Awesome as an army with banners?", 
                "num": 10
              }, 
              {
                "text": "I went down to the garden of nuts To see the verdure of the valley, To see whether the vine had budded And the pomegranates had bloomed.", 
                "num": 11
              }, 
              {
                "text": "Before I was even aware, My soul had made me As the chariots of my noble people.", 
                "num": 12
              }, 
              {
                "text": "Return, return, O Shulamite; Return, return, that we may look upon you! What would you see in the Shulamite-- As it were, the dance of the two camps?", 
                "num": 13
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "How beautiful are your feet in sandals, O prince's daughter! The curves of your thighs are like jewels, The work of the hands of a skillful workman.", 
                "num": 1
              }, 
              {
                "text": "Your navel is a rounded goblet; It lacks no blended beverage. Your waist is a heap of wheat Set about with lilies.", 
                "num": 2
              }, 
              {
                "text": "Your two breasts are like two fawns, Twins of a gazelle.", 
                "num": 3
              }, 
              {
                "text": "Your neck is like an ivory tower, Your eyes like the pools in Heshbon By the gate of Bath Rabbim. Your nose is like the tower of Lebanon Which looks toward Damascus.", 
                "num": 4
              }, 
              {
                "text": "Your head crowns you like Mount Carmel, And the hair of your head is like purple; A king is held captive by your tresses.", 
                "num": 5
              }, 
              {
                "text": "How fair and how pleasant you are, O love, with your delights!", 
                "num": 6
              }, 
              {
                "text": "This stature of yours is like a palm tree, And your breasts like its clusters.", 
                "num": 7
              }, 
              {
                "text": "I said, \"I will go up to the palm tree, I will take hold of its branches.\" Let now your breasts be like clusters of the vine, The fragrance of your breath like apples,", 
                "num": 8
              }, 
              {
                "text": "And the roof of your mouth like the best wine. The wine goes down smoothly for my beloved, Moving gently the lips of sleepers.", 
                "num": 9
              }, 
              {
                "text": "I am my beloved's, And his desire is toward me.", 
                "num": 10
              }, 
              {
                "text": "Come, my beloved, Let us go forth to the field; Let us lodge in the villages.", 
                "num": 11
              }, 
              {
                "text": "Let us get up early to the vineyards; Let us see if the vine has budded, Whether the grape blossoms are open, And the pomegranates are in bloom. There I will give you my love.", 
                "num": 12
              }, 
              {
                "text": "The mandrakes give off a fragrance, And at our gates are pleasant fruits, All manner, new and old, Which I have laid up for you, my beloved.", 
                "num": 13
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Oh, that you were like my brother, Who nursed at my mother's breasts! If I should find you outside, I would kiss you; I would not be despised.", 
                "num": 1
              }, 
              {
                "text": "I would lead you and bring you Into the house of my mother, She who used to instruct me. I would cause you to drink of spiced wine, Of the juice of my pomegranate.", 
                "num": 2
              }, 
              {
                "text": "His left hand is under my head, And his right hand embraces me.", 
                "num": 3
              }, 
              {
                "text": "I charge you, O daughters of Jerusalem, Do not stir up nor awaken love Until it pleases.", 
                "num": 4
              }, 
              {
                "text": "Who is this coming up from the wilderness, Leaning upon her beloved? I awakened you under the apple tree. There your mother brought you forth; There she who bore you brought you forth.", 
                "num": 5
              }, 
              {
                "text": "Set me as a seal upon your heart, As a seal upon your arm; For love is as strong as death, Jealousy as cruel as the grave; Its flames are flames of fire, A most vehement flame.", 
                "num": 6
              }, 
              {
                "text": "Many waters cannot quench love, Nor can the floods drown it. If a man would give for love All the wealth of his house, It would be utterly despised.", 
                "num": 7
              }, 
              {
                "text": "We have a little sister, And she has no breasts. What shall we do for our sister In the day when she is spoken for?", 
                "num": 8
              }, 
              {
                "text": "If she is a wall, We will build upon her A battlement of silver; And if she is a door, We will enclose her With boards of cedar.", 
                "num": 9
              }, 
              {
                "text": "I am a wall, And my breasts like towers; Then I became in his eyes As one who found peace.", 
                "num": 10
              }, 
              {
                "text": "Solomon had a vineyard at Baal Hamon; He leased the vineyard to keepers; Everyone was to bring for its fruit A thousand silver coins.", 
                "num": 11
              }, 
              {
                "text": "My own vineyard is before me. You, O Solomon, may have a thousand, And those who tend its fruit two hundred.", 
                "num": 12
              }, 
              {
                "text": "You who dwell in the gardens, The companions listen for your voice-- Let me hear it!", 
                "num": 13
              }, 
              {
                "text": "Make haste, my beloved, And be like a gazelle Or a young stag On the mountains of spices.", 
                "num": 14
              }
            ], 
            "num": 8
          }
        ]
      }, 
      {
        "name": "Isaiah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The vision of Isaiah the son of Amoz, which he saw concerning Judah and Jerusalem in the days of Uzziah, Jotham, Ahaz, and Hezekiah, kings of Judah.", 
                "num": 1
              }, 
              {
                "text": "Hear, O heavens, and give ear, O earth! For the LORD has spoken: \"I have nourished and brought up children, And they have rebelled against Me;", 
                "num": 2
              }, 
              {
                "text": "The ox knows its owner And the donkey its master's crib; But Israel does not know, My people do not consider.\"", 
                "num": 3
              }, 
              {
                "text": "Alas, sinful nation, A people laden with iniquity, A brood of evildoers, Children who are corrupters! They have forsaken the LORD, They have provoked to anger The Holy One of Israel, They have turned away backward.", 
                "num": 4
              }, 
              {
                "text": "Why should you be stricken again? You will revolt more and more. The whole head is sick, And the whole heart faints.", 
                "num": 5
              }, 
              {
                "text": "From the sole of the foot even to the head, There is no soundness in it, But wounds and bruises and putrefying sores; They have not been closed or bound up, Or soothed with ointment.", 
                "num": 6
              }, 
              {
                "text": "Your country is desolate, Your cities are burned with fire; Strangers devour your land in your presence; And it is desolate, as overthrown by strangers.", 
                "num": 7
              }, 
              {
                "text": "So the daughter of Zion is left as a booth in a vineyard, As a hut in a garden of cucumbers, As a besieged city.", 
                "num": 8
              }, 
              {
                "text": "Unless the LORD of hosts Had left to us a very small remnant, We would have become like Sodom, We would have been made like Gomorrah.", 
                "num": 9
              }, 
              {
                "text": "Hear the word of the LORD, You rulers of Sodom; Give ear to the law of our God, You people of Gomorrah:", 
                "num": 10
              }, 
              {
                "text": "\"To what purpose is the multitude of your sacrifices to Me?\" Says the LORD. \"I have had enough of burnt offerings of rams And the fat of fed cattle. I do not delight in the blood of bulls, Or of lambs or goats.", 
                "num": 11
              }, 
              {
                "text": "\"When you come to appear before Me, Who has required this from your hand, To trample My courts?", 
                "num": 12
              }, 
              {
                "text": "Bring no more futile sacrifices; Incense is an abomination to Me. The New Moons, the Sabbaths, and the calling of assemblies-- I cannot endure iniquity and the sacred meeting.", 
                "num": 13
              }, 
              {
                "text": "Your New Moons and your appointed feasts My soul hates; They are a trouble to Me, I am weary of bearing them.", 
                "num": 14
              }, 
              {
                "text": "When you spread out your hands, I will hide My eyes from you; Even though you make many prayers, I will not hear. Your hands are full of blood.", 
                "num": 15
              }, 
              {
                "text": "\"Wash yourselves, make yourselves clean; Put away the evil of your doings from before My eyes. Cease to do evil,", 
                "num": 16
              }, 
              {
                "text": "Learn to do good; Seek justice, Rebuke the oppressor; Defend the fatherless, Plead for the widow.", 
                "num": 17
              }, 
              {
                "text": "\"Come now, and let us reason together,\" Says the LORD, \"Though your sins are like scarlet, They shall be as white as snow; Though they are red like crimson, They shall be as wool.", 
                "num": 18
              }, 
              {
                "text": "If you are willing and obedient, You shall eat the good of the land;", 
                "num": 19
              }, 
              {
                "text": "But if you refuse and rebel, You shall be devoured by the sword\"; For the mouth of the LORD has spoken.", 
                "num": 20
              }, 
              {
                "text": "How the faithful city has become a harlot! It was full of justice; Righteousness lodged in it, But now murderers.", 
                "num": 21
              }, 
              {
                "text": "Your silver has become dross, Your wine mixed with water.", 
                "num": 22
              }, 
              {
                "text": "Your princes are rebellious, And companions of thieves; Everyone loves bribes, And follows after rewards. They do not defend the fatherless, Nor does the cause of the widow come before them.", 
                "num": 23
              }, 
              {
                "text": "Therefore the Lord says, The LORD of hosts, the Mighty One of Israel, \"Ah, I will rid Myself of My adversaries, And take vengeance on My enemies.", 
                "num": 24
              }, 
              {
                "text": "I will turn My hand against you, And thoroughly purge away your dross, And take away all your alloy.", 
                "num": 25
              }, 
              {
                "text": "I will restore your judges as at the first, And your counselors as at the beginning. Afterward you shall be called the city of righteousness, the faithful city.\"", 
                "num": 26
              }, 
              {
                "text": "Zion shall be redeemed with justice, And her penitents with righteousness.", 
                "num": 27
              }, 
              {
                "text": "The destruction of transgressors and of sinners shall be together, And those who forsake the LORD shall be consumed.", 
                "num": 28
              }, 
              {
                "text": "For they shall be ashamed of the terebinth trees Which you have desired; And you shall be embarrassed because of the gardens Which you have chosen.", 
                "num": 29
              }, 
              {
                "text": "For you shall be as a terebinth whose leaf fades, And as a garden that has no water.", 
                "num": 30
              }, 
              {
                "text": "The strong shall be as tinder, And the work of it as a spark; Both will burn together, And no one shall quench them.", 
                "num": 31
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "The word that Isaiah the son of Amoz saw concerning Judah and Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Now it shall come to pass in the latter days That the mountain of the LORD's house Shall be established on the top of the mountains, And shall be exalted above the hills; And all nations shall flow to it.", 
                "num": 2
              }, 
              {
                "text": "Many people shall come and say, \"Come, and let us go up to the mountain of the LORD, To the house of the God of Jacob; He will teach us His ways, And we shall walk in His paths.\" For out of Zion shall go forth the law, And the word of the LORD from Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "He shall judge between the nations, And rebuke many people; They shall beat their swords into plowshares, And their spears into pruning hooks; Nation shall not lift up sword against nation, Neither shall they learn war anymore.", 
                "num": 4
              }, 
              {
                "text": "O house of Jacob, come and let us walk In the light of the LORD.", 
                "num": 5
              }, 
              {
                "text": "For You have forsaken Your people, the house of Jacob, Because they are filled with eastern ways; They are soothsayers like the Philistines, And they are pleased with the children of foreigners.", 
                "num": 6
              }, 
              {
                "text": "Their land is also full of silver and gold, And there is no end to their treasures; Their land is also full of horses, And there is no end to their chariots.", 
                "num": 7
              }, 
              {
                "text": "Their land is also full of idols; They worship the work of their own hands, That which their own fingers have made.", 
                "num": 8
              }, 
              {
                "text": "People bow down, And each man humbles himself; Therefore do not forgive them.", 
                "num": 9
              }, 
              {
                "text": "Enter into the rock, and hide in the dust, From the terror of the LORD And the glory of His majesty.", 
                "num": 10
              }, 
              {
                "text": "The lofty looks of man shall be humbled, The haughtiness of men shall be bowed down, And the LORD alone shall be exalted in that day.", 
                "num": 11
              }, 
              {
                "text": "For the day of the LORD of hosts Shall come upon everything proud and lofty, Upon everything lifted up-- And it shall be brought low--", 
                "num": 12
              }, 
              {
                "text": "Upon all the cedars of Lebanon that are high and lifted up, And upon all the oaks of Bashan;", 
                "num": 13
              }, 
              {
                "text": "Upon all the high mountains, And upon all the hills that are lifted up;", 
                "num": 14
              }, 
              {
                "text": "Upon every high tower, And upon every fortified wall;", 
                "num": 15
              }, 
              {
                "text": "Upon all the ships of Tarshish, And upon all the beautiful sloops.", 
                "num": 16
              }, 
              {
                "text": "The loftiness of man shall be bowed down, And the haughtiness of men shall be brought low; The LORD alone will be exalted in that day,", 
                "num": 17
              }, 
              {
                "text": "But the idols He shall utterly abolish.", 
                "num": 18
              }, 
              {
                "text": "They shall go into the holes of the rocks, And into the caves of the earth, From the terror of the LORD And the glory of His majesty, When He arises to shake the earth mightily.", 
                "num": 19
              }, 
              {
                "text": "In that day a man will cast away his idols of silver And his idols of gold, Which they made, each for himself to worship, To the moles and bats,", 
                "num": 20
              }, 
              {
                "text": "To go into the clefts of the rocks, And into the crags of the rugged rocks, From the terror of the LORD And the glory of His majesty, When He arises to shake the earth mightily.", 
                "num": 21
              }, 
              {
                "text": "Sever yourselves from such a man, Whose breath is in his nostrils; For of what account is he?", 
                "num": 22
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "For behold, the Lord, the LORD of hosts, Takes away from Jerusalem and from Judah The stock and the store, The whole supply of bread and the whole supply of water;", 
                "num": 1
              }, 
              {
                "text": "The mighty man and the man of war, The judge and the prophet, And the diviner and the elder;", 
                "num": 2
              }, 
              {
                "text": "The captain of fifty and the honorable man, The counselor and the skillful artisan, And the expert enchanter.", 
                "num": 3
              }, 
              {
                "text": "\"I will give children to be their princes, And babes shall rule over them.", 
                "num": 4
              }, 
              {
                "text": "The people will be oppressed, Every one by another and every one by his neighbor; The child will be insolent toward the elder, And the base toward the honorable.\"", 
                "num": 5
              }, 
              {
                "text": "When a man takes hold of his brother In the house of his father, saying, \"You have clothing; You be our ruler, And let these ruins be under your power,\"", 
                "num": 6
              }, 
              {
                "text": "In that day he will protest, saying, \"I cannot cure your ills, For in my house is neither food nor clothing; Do not make me a ruler of the people.\"", 
                "num": 7
              }, 
              {
                "text": "For Jerusalem stumbled, And Judah is fallen, Because their tongue and their doings Are against the LORD, To provoke the eyes of His glory.", 
                "num": 8
              }, 
              {
                "text": "The look on their countenance witnesses against them, And they declare their sin as Sodom; They do not hide it. Woe to their soul! For they have brought evil upon themselves.", 
                "num": 9
              }, 
              {
                "text": "\"Say to the righteous that it shall be well with them, For they shall eat the fruit of their doings.", 
                "num": 10
              }, 
              {
                "text": "Woe to the wicked! It shall be ill with him, For the reward of his hands shall be given him.", 
                "num": 11
              }, 
              {
                "text": "As for My people, children are their oppressors, And women rule over them. O My people! Those who lead you cause you to err, And destroy the way of your paths.\"", 
                "num": 12
              }, 
              {
                "text": "The LORD stands up to plead, And stands to judge the people.", 
                "num": 13
              }, 
              {
                "text": "The LORD will enter into judgment With the elders of His people And His princes: \"For you have eaten up the vineyard; The plunder of the poor is in your houses.", 
                "num": 14
              }, 
              {
                "text": "What do you mean by crushing My people And grinding the faces of the poor?\" Says the Lord GOD of hosts.", 
                "num": 15
              }, 
              {
                "text": "Moreover the LORD says: \"Because the daughters of Zion are haughty, And walk with outstretched necks And wanton eyes, Walking and mincing as they go, Making a jingling with their feet,", 
                "num": 16
              }, 
              {
                "text": "Therefore the Lord will strike with a scab The crown of the head of the daughters of Zion, And the LORD will uncover their secret parts.\"", 
                "num": 17
              }, 
              {
                "text": "In that day the Lord will take away the finery: The jingling anklets, the scarves, and the crescents;", 
                "num": 18
              }, 
              {
                "text": "The pendants, the bracelets, and the veils;", 
                "num": 19
              }, 
              {
                "text": "The headdresses, the leg ornaments, and the headbands; The perfume boxes, the charms, The nose jewels, The outer garments, the purses, The fine linen, the turbans, and the robes.", 
                "num": 20
              }, 
              {
                "text": "And so it shall be: Instead of a sweet smell there will be a stench; Instead of a sash, a rope; Instead of well-set hair, baldness; Instead of a rich robe, a girding of sackcloth; And branding instead of beauty.", 
                "num": 24
              }, 
              {
                "text": "Your men shall fall by the sword, And your mighty in the war.", 
                "num": 25
              }, 
              {
                "text": "Her gates shall lament and mourn, And she being desolate shall sit on the ground.", 
                "num": 26
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "And in that day seven women shall take hold of one man, saying, \"We will eat our own food and wear our own apparel; Only let us be called by your name, To take away our reproach.\"", 
                "num": 1
              }, 
              {
                "text": "In that day the Branch of the LORD shall be beautiful and glorious; And the fruit of the earth shall be excellent and appealing For those of Israel who have escaped.", 
                "num": 2
              }, 
              {
                "text": "And it shall come to pass that he who is left in Zion and remains in Jerusalem will be called holy--everyone who is recorded among the living in Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "When the Lord has washed away the filth of the daughters of Zion, and purged the blood of Jerusalem from her midst, by the spirit of judgment and by the spirit of burning,", 
                "num": 4
              }, 
              {
                "text": "then the LORD will create above every dwelling place of Mount Zion, and above her assemblies, a cloud and smoke by day and the shining of a flaming fire by night. For over all the glory there will be a covering.", 
                "num": 5
              }, 
              {
                "text": "And there will be a tabernacle for shade in the daytime from the heat, for a place of refuge, and for a shelter from storm and rain.", 
                "num": 6
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now let me sing to my Well-beloved A song of my Beloved regarding His vineyard: My Well-beloved has a vineyard On a very fruitful hill.", 
                "num": 1
              }, 
              {
                "text": "He dug it up and cleared out its stones, And planted it with the choicest vine. He built a tower in its midst, And also made a winepress in it; So He expected it to bring forth good grapes, But it brought forth wild grapes.", 
                "num": 2
              }, 
              {
                "text": "\"And now, O inhabitants of Jerusalem and men of Judah, Judge, please, between Me and My vineyard.", 
                "num": 3
              }, 
              {
                "text": "What more could have been done to My vineyard That I have not done in it? Why then, when I expected it to bring forth good grapes, Did it bring forth wild grapes?", 
                "num": 4
              }, 
              {
                "text": "And now, please let Me tell you what I will do to My vineyard: I will take away its hedge, and it shall be burned; And break down its wall, and it shall be trampled down.", 
                "num": 5
              }, 
              {
                "text": "I will lay it waste; It shall not be pruned or dug, But there shall come up briers and thorns. I will also command the clouds That they rain no rain on it.\"", 
                "num": 6
              }, 
              {
                "text": "For the vineyard of the LORD of hosts is the house of Israel, And the men of Judah are His pleasant plant. He looked for justice, but behold, oppression; For righteousness, but behold, a cry for help.", 
                "num": 7
              }, 
              {
                "text": "Woe to those who join house to house; They add field to field, Till there is no place Where they may dwell alone in the midst of the land!", 
                "num": 8
              }, 
              {
                "text": "In my hearing the LORD of hosts said, \"Truly, many houses shall be desolate, Great and beautiful ones, without inhabitant.", 
                "num": 9
              }, 
              {
                "text": "For ten acres of vineyard shall yield one bath, And a homer of seed shall yield one ephah.\"", 
                "num": 10
              }, 
              {
                "text": "Woe to those who rise early in the morning, That they may follow intoxicating drink; Who continue until night, till wine inflames them!", 
                "num": 11
              }, 
              {
                "text": "The harp and the strings, The tambourine and flute, And wine are in their feasts; But they do not regard the work of the LORD, Nor consider the operation of His hands.", 
                "num": 12
              }, 
              {
                "text": "Therefore my people have gone into captivity, Because they have no knowledge; Their honorable men are famished, And their multitude dried up with thirst.", 
                "num": 13
              }, 
              {
                "text": "Therefore Sheol has enlarged itself And opened its mouth beyond measure; Their glory and their multitude and their pomp, And he who is jubilant, shall descend into it.", 
                "num": 14
              }, 
              {
                "text": "People shall be brought down, Each man shall be humbled, And the eyes of the lofty shall be humbled.", 
                "num": 15
              }, 
              {
                "text": "But the LORD of hosts shall be exalted in judgment, And God who is holy shall be hallowed in righteousness.", 
                "num": 16
              }, 
              {
                "text": "Then the lambs shall feed in their pasture, And in the waste places of the fat ones strangers shall eat.", 
                "num": 17
              }, 
              {
                "text": "Woe to those who draw iniquity with cords of vanity, And sin as if with a cart rope;", 
                "num": 18
              }, 
              {
                "text": "That say, \"Let Him make speed and hasten His work, That we may see it; And let the counsel of the Holy One of Israel draw near and come, That we may know it.\"", 
                "num": 19
              }, 
              {
                "text": "Woe to those who call evil good, and good evil; Who put darkness for light, and light for darkness; Who put bitter for sweet, and sweet for bitter!", 
                "num": 20
              }, 
              {
                "text": "Woe to those who are wise in their own eyes, And prudent in their own sight!", 
                "num": 21
              }, 
              {
                "text": "Woe to men mighty at drinking wine, Woe to men valiant for mixing intoxicating drink,", 
                "num": 22
              }, 
              {
                "text": "Who justify the wicked for a bribe, And take away justice from the righteous man!", 
                "num": 23
              }, 
              {
                "text": "Therefore, as the fire devours the stubble, And the flame consumes the chaff, So their root will be as rottenness, And their blossom will ascend like dust; Because they have rejected the law of the LORD of hosts, And despised the word of the Holy One of Israel.", 
                "num": 24
              }, 
              {
                "text": "Therefore the anger of the LORD is aroused against His people; He has stretched out His hand against them And stricken them, And the hills trembled. Their carcasses were as refuse in the midst of the streets. For all this His anger is not turned away, But His hand is stretched out still.", 
                "num": 25
              }, 
              {
                "text": "He will lift up a banner to the nations from afar, And will whistle to them from the end of the earth; Surely they shall come with speed, swiftly.", 
                "num": 26
              }, 
              {
                "text": "No one will be weary or stumble among them, No one will slumber or sleep; Nor will the belt on their loins be loosed, Nor the strap of their sandals be broken;", 
                "num": 27
              }, 
              {
                "text": "Whose arrows are sharp, And all their bows bent; Their horses' hooves will seem like flint, And their wheels like a whirlwind.", 
                "num": 28
              }, 
              {
                "text": "Their roaring will be like a lion, They will roar like young lions; Yes, they will roar And lay hold of the prey; They will carry it away safely, And no one will deliver.", 
                "num": 29
              }, 
              {
                "text": "In that day they will roar against them Like the roaring of the sea. And if one looks to the land, Behold, darkness and sorrow; And the light is darkened by the clouds.", 
                "num": 30
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "In the year that King Uzziah died, I saw the Lord sitting on a throne, high and lifted up, and the train of His robe filled the temple.", 
                "num": 1
              }, 
              {
                "text": "Above it stood seraphim; each one had six wings: with two he covered his face, with two he covered his feet, and with two he flew.", 
                "num": 2
              }, 
              {
                "text": "And one cried to another and said: \"Holy, holy, holy is the LORD of hosts; The whole earth is full of His glory!\"", 
                "num": 3
              }, 
              {
                "text": "And the posts of the door were shaken by the voice of him who cried out, and the house was filled with smoke.", 
                "num": 4
              }, 
              {
                "text": "So I said: \"Woe is me, for I am undone! Because I am a man of unclean lips, And I dwell in the midst of a people of unclean lips; For my eyes have seen the King, The LORD of hosts.\"", 
                "num": 5
              }, 
              {
                "text": "Then one of the seraphim flew to me, having in his hand a live coal which he had taken with the tongs from the altar.", 
                "num": 6
              }, 
              {
                "text": "And he touched my mouth with it, and said: \"Behold, this has touched your lips; Your iniquity is taken away, And your sin purged.\"", 
                "num": 7
              }, 
              {
                "text": "Also I heard the voice of the Lord, saying: \"Whom shall I send, And who will go for Us?\" Then I said, \"Here am I! Send me.\"", 
                "num": 8
              }, 
              {
                "text": "And He said, \"Go, and tell this people: \"Keep on hearing, but do not understand; Keep on seeing, but do not perceive.'", 
                "num": 9
              }, 
              {
                "text": "\"Make the heart of this people dull, And their ears heavy, And shut their eyes; Lest they see with their eyes, And hear with their ears, And understand with their heart, And return and be healed.\"", 
                "num": 10
              }, 
              {
                "text": "Then I said, \"Lord, how long?\" And He answered: \"Until the cities are laid waste and without inhabitant, The houses are without a man, The land is utterly desolate,", 
                "num": 11
              }, 
              {
                "text": "The LORD has removed men far away, And the forsaken places are many in the midst of the land.", 
                "num": 12
              }, 
              {
                "text": "But yet a tenth will be in it, And will return and be for consuming, As a terebinth tree or as an oak, Whose stump remains when it is cut down. So the holy seed shall be its stump.\"", 
                "num": 13
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the days of Ahaz the son of Jotham, the son of Uzziah, king of Judah, that Rezin king of Syria and Pekah the son of Remaliah, king of Israel, went up to Jerusalem to make war against it, but could not prevail against it.", 
                "num": 1
              }, 
              {
                "text": "And it was told to the house of David, saying, \"Syria's forces are deployed in Ephraim.\" So his heart and the heart of his people were moved as the trees of the woods are moved with the wind.", 
                "num": 2
              }, 
              {
                "text": "Then the LORD said to Isaiah, \"Go out now to meet Ahaz, you and Shear-Jashub your son, at the end of the aqueduct from the upper pool, on the highway to the Fuller's Field,", 
                "num": 3
              }, 
              {
                "text": "and say to him: \"Take heed, and be quiet; do not fear or be fainthearted for these two stubs of smoking firebrands, for the fierce anger of Rezin and Syria, and the son of Remaliah.", 
                "num": 4
              }, 
              {
                "text": "Because Syria, Ephraim, and the son of Remaliah have plotted evil against you, saying,", 
                "num": 5
              }, 
              {
                "text": "\"Let us go up against Judah and trouble it, and let us make a gap in its wall for ourselves, and set a king over them, the son of Tabel\"--", 
                "num": 6
              }, 
              {
                "text": "thus says the Lord GOD: \"It shall not stand, Nor shall it come to pass.", 
                "num": 7
              }, 
              {
                "text": "For the head of Syria is Damascus, And the head of Damascus is Rezin. Within sixty-five years Ephraim will be broken, So that it will not be a people.", 
                "num": 8
              }, 
              {
                "text": "The head of Ephraim is Samaria, And the head of Samaria is Remaliah's son. If you will not believe, Surely you shall not be established.\"\"'", 
                "num": 9
              }, 
              {
                "text": "Moreover the LORD spoke again to Ahaz, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Ask a sign for yourself from the LORD your God; ask it either in the depth or in the height above.\"", 
                "num": 11
              }, 
              {
                "text": "But Ahaz said, \"I will not ask, nor will I test the LORD!\"", 
                "num": 12
              }, 
              {
                "text": "Then he said, \"Hear now, O house of David! Is it a small thing for you to weary men, but will you weary my God also?", 
                "num": 13
              }, 
              {
                "text": "Therefore the Lord Himself will give you a sign: Behold, the virgin shall conceive and bear a Son, and shall call His name Immanuel.", 
                "num": 14
              }, 
              {
                "text": "Curds and honey He shall eat, that He may know to refuse the evil and choose the good.", 
                "num": 15
              }, 
              {
                "text": "For before the Child shall know to refuse the evil and choose the good, the land that you dread will be forsaken by both her kings.", 
                "num": 16
              }, 
              {
                "text": "The LORD will bring the king of Assyria upon you and your people and your father's house--days that have not come since the day that Ephraim departed from Judah.\"", 
                "num": 17
              }, 
              {
                "text": "And it shall come to pass in that day That the LORD will whistle for the fly That is in the farthest part of the rivers of Egypt, And for the bee that is in the land of Assyria.", 
                "num": 18
              }, 
              {
                "text": "They will come, and all of them will rest In the desolate valleys and in the clefts of the rocks, And on all thorns and in all pastures.", 
                "num": 19
              }, 
              {
                "text": "In the same day the Lord will shave with a hired razor, With those from beyond the River, with the king of Assyria, The head and the hair of the legs, And will also remove the beard.", 
                "num": 20
              }, 
              {
                "text": "It shall be in that day That a man will keep alive a young cow and two sheep;", 
                "num": 21
              }, 
              {
                "text": "So it shall be, from the abundance of milk they give, That he will eat curds; For curds and honey everyone will eat who is left in the land.", 
                "num": 22
              }, 
              {
                "text": "It shall happen in that day, That wherever there could be a thousand vines Worth a thousand shekels of silver, It will be for briers and thorns.", 
                "num": 23
              }, 
              {
                "text": "With arrows and bows men will come there, Because all the land will become briers and thorns.", 
                "num": 24
              }, 
              {
                "text": "And to any hill which could be dug with the hoe, You will not go there for fear of briers and thorns; But it will become a range for oxen And a place for sheep to roam.", 
                "num": 25
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Moreover the LORD said to me, \"Take a large scroll, and write on it with a man's pen concerning Maher-Shalal-Hash-Baz.", 
                "num": 1
              }, 
              {
                "text": "And I will take for Myself faithful witnesses to record, Uriah the priest and Zechariah the son of Jeberechiah.\"", 
                "num": 2
              }, 
              {
                "text": "Then I went to the prophetess, and she conceived and bore a son. Then the LORD said to me, \"Call his name Maher-Shalal-Hash-Baz;", 
                "num": 3
              }, 
              {
                "text": "for before the child shall have knowledge to cry \"My father' and \"My mother,' the riches of Damascus and the spoil of Samaria will be taken away before the king of Assyria.\"", 
                "num": 4
              }, 
              {
                "text": "The LORD also spoke to me again, saying:", 
                "num": 5
              }, 
              {
                "text": "\"Inasmuch as these people refused The waters of Shiloah that flow softly, And rejoice in Rezin and in Remaliah's son;", 
                "num": 6
              }, 
              {
                "text": "Now therefore, behold, the Lord brings up over them The waters of the River, strong and mighty-- The king of Assyria and all his glory; He will go up over all his channels And go over all his banks.", 
                "num": 7
              }, 
              {
                "text": "He will pass through Judah, He will overflow and pass over, He will reach up to the neck; And the stretching out of his wings Will fill the breadth of Your land, O Immanuel.", 
                "num": 8
              }, 
              {
                "text": "\"Be shattered, O you peoples, and be broken in pieces! Give ear, all you from far countries. Gird yourselves, but be broken in pieces; Gird yourselves, but be broken in pieces.", 
                "num": 9
              }, 
              {
                "text": "Take counsel together, but it will come to nothing; Speak the word, but it will not stand, For God is with us.\"", 
                "num": 10
              }, 
              {
                "text": "For the LORD spoke thus to me with a strong hand, and instructed me that I should not walk in the way of this people, saying:", 
                "num": 11
              }, 
              {
                "text": "\"Do not say, \"A conspiracy,' Concerning all that this people call a conspiracy, Nor be afraid of their threats, nor be troubled.", 
                "num": 12
              }, 
              {
                "text": "The LORD of hosts, Him you shall hallow; Let Him be your fear, And let Him be your dread.", 
                "num": 13
              }, 
              {
                "text": "He will be as a sanctuary, But a stone of stumbling and a rock of offense To both the houses of Israel, As a trap and a snare to the inhabitants of Jerusalem.", 
                "num": 14
              }, 
              {
                "text": "And many among them shall stumble; They shall fall and be broken, Be snared and taken.\"", 
                "num": 15
              }, 
              {
                "text": "Bind up the testimony, Seal the law among my disciples.", 
                "num": 16
              }, 
              {
                "text": "And I will wait on the LORD, Who hides His face from the house of Jacob; And I will hope in Him.", 
                "num": 17
              }, 
              {
                "text": "Here am I and the children whom the LORD has given me! We are for signs and wonders in Israel From the LORD of hosts, Who dwells in Mount Zion.", 
                "num": 18
              }, 
              {
                "text": "And when they say to you, \"Seek those who are mediums and wizards, who whisper and mutter,\" should not a people seek their God? Should they seek the dead on behalf of the living?", 
                "num": 19
              }, 
              {
                "text": "To the law and to the testimony! If they do not speak according to this word, it is because there is no light in them.", 
                "num": 20
              }, 
              {
                "text": "They will pass through it hard-pressed and hungry; and it shall happen, when they are hungry, that they will be enraged and curse their king and their God, and look upward.", 
                "num": 21
              }, 
              {
                "text": "Then they will look to the earth, and see trouble and darkness, gloom of anguish; and they will be driven into darkness.", 
                "num": 22
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Nevertheless the gloom will not be upon her who is distressed, As when at first He lightly esteemed The land of Zebulun and the land of Naphtali, And afterward more heavily oppressed her, By the way of the sea, beyond the Jordan, In Galilee of the Gentiles.", 
                "num": 1
              }, 
              {
                "text": "The people who walked in darkness Have seen a great light; Those who dwelt in the land of the shadow of death, Upon them a light has shined.", 
                "num": 2
              }, 
              {
                "text": "You have multiplied the nation And increased its joy; They rejoice before You According to the joy of harvest, As men rejoice when they divide the spoil.", 
                "num": 3
              }, 
              {
                "text": "For You have broken the yoke of his burden And the staff of his shoulder, The rod of his oppressor, As in the day of Midian.", 
                "num": 4
              }, 
              {
                "text": "For every warrior's sandal from the noisy battle, And garments rolled in blood, Will be used for burning and fuel of fire.", 
                "num": 5
              }, 
              {
                "text": "For unto us a Child is born, Unto us a Son is given; And the government will be upon His shoulder. And His name will be called Wonderful, Counselor, Mighty God, Everlasting Father, Prince of Peace.", 
                "num": 6
              }, 
              {
                "text": "Of the increase of His government and peace There will be no end, Upon the throne of David and over His kingdom, To order it and establish it with judgment and justice From that time forward, even forever. The zeal of the Lord of hosts will perform this.", 
                "num": 7
              }, 
              {
                "text": "The LORD sent a word against Jacob, And it has fallen on Israel.", 
                "num": 8
              }, 
              {
                "text": "All the people will know-- Ephraim and the inhabitant of Samaria-- Who say in pride and arrogance of heart:", 
                "num": 9
              }, 
              {
                "text": "\"The bricks have fallen down, But we will rebuild with hewn stones; The sycamores are cut down, But we will replace them with cedars.\"", 
                "num": 10
              }, 
              {
                "text": "Therefore the LORD shall set up The adversaries of Rezin against him, And spur his enemies on,", 
                "num": 11
              }, 
              {
                "text": "The Syrians before and the Philistines behind; And they shall devour Israel with an open mouth. For all this His anger is not turned away, But His hand is stretched out still.", 
                "num": 12
              }, 
              {
                "text": "For the people do not turn to Him who strikes them, Nor do they seek the LORD of hosts.", 
                "num": 13
              }, 
              {
                "text": "Therefore the LORD will cut off head and tail from Israel, Palm branch and bulrush in one day.", 
                "num": 14
              }, 
              {
                "text": "The elder and honorable, he is the head; The prophet who teaches lies, he is the tail.", 
                "num": 15
              }, 
              {
                "text": "For the leaders of this people cause them to err, And those who are led by them are destroyed.", 
                "num": 16
              }, 
              {
                "text": "Therefore the Lord will have no joy in their young men, Nor have mercy on their fatherless and widows; For everyone is a hypocrite and an evildoer, And every mouth speaks folly. For all this His anger is not turned away, But His hand is stretched out still.", 
                "num": 17
              }, 
              {
                "text": "For wickedness burns as the fire; It shall devour the briers and thorns, And kindle in the thickets of the forest; They shall mount up like rising smoke.", 
                "num": 18
              }, 
              {
                "text": "Through the wrath of the LORD of hosts The land is burned up, And the people shall be as fuel for the fire; No man shall spare his brother.", 
                "num": 19
              }, 
              {
                "text": "And he shall snatch on the right hand And be hungry; He shall devour on the left hand And not be satisfied; Every man shall eat the flesh of his own arm.", 
                "num": 20
              }, 
              {
                "text": "Manasseh shall devour Ephraim, and Ephraim Manasseh; Together they shall be against Judah. For all this His anger is not turned away, But His hand is stretched out still.", 
                "num": 21
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "\"Woe to those who decree unrighteous decrees, Who write misfortune, Which they have prescribed", 
                "num": 1
              }, 
              {
                "text": "To rob the needy of justice, And to take what is right from the poor of My people, That widows may be their prey, And that they may rob the fatherless.", 
                "num": 2
              }, 
              {
                "text": "What will you do in the day of punishment, And in the desolation which will come from afar? To whom will you flee for help? And where will you leave your glory?", 
                "num": 3
              }, 
              {
                "text": "Without Me they shall bow down among the prisoners, And they shall fall among the slain.\" For all this His anger is not turned away, But His hand is stretched out still.", 
                "num": 4
              }, 
              {
                "text": "\"Woe to Assyria, the rod of My anger And the staff in whose hand is My indignation.", 
                "num": 5
              }, 
              {
                "text": "I will send him against an ungodly nation, And against the people of My wrath I will give him charge, To seize the spoil, to take the prey, And to tread them down like the mire of the streets.", 
                "num": 6
              }, 
              {
                "text": "Yet he does not mean so, Nor does his heart think so; But it is in his heart to destroy, And cut off not a few nations.", 
                "num": 7
              }, 
              {
                "text": "For he says, \"Are not my princes altogether kings?", 
                "num": 8
              }, 
              {
                "text": "Is not Calno like Carchemish? Is not Hamath like Arpad? Is not Samaria like Damascus?", 
                "num": 9
              }, 
              {
                "text": "As my hand has found the kingdoms of the idols, Whose carved images excelled those of Jerusalem and Samaria,", 
                "num": 10
              }, 
              {
                "text": "As I have done to Samaria and her idols, Shall I not do also to Jerusalem and her idols?\"'", 
                "num": 11
              }, 
              {
                "text": "Therefore it shall come to pass, when the Lord has performed all His work on Mount Zion and on Jerusalem, that He will say, \"I will punish the fruit of the arrogant heart of the king of Assyria, and the glory of his haughty looks.\"", 
                "num": 12
              }, 
              {
                "text": "For he says: \"By the strength of my hand I have done it, And by my wisdom, for I am prudent; Also I have removed the boundaries of the people, And have robbed their treasuries; So I have put down the inhabitants like a valiant man.", 
                "num": 13
              }, 
              {
                "text": "My hand has found like a nest the riches of the people, And as one gathers eggs that are left, I have gathered all the earth; And there was no one who moved his wing, Nor opened his mouth with even a peep.\"", 
                "num": 14
              }, 
              {
                "text": "Shall the ax boast itself against him who chops with it? Or shall the saw exalt itself against him who saws with it? As if a rod could wield itself against those who lift it up, Or as if a staff could lift up, as if it were not wood!", 
                "num": 15
              }, 
              {
                "text": "Therefore the Lord, the Lord of hosts, Will send leanness among his fat ones; And under his glory He will kindle a burning Like the burning of a fire.", 
                "num": 16
              }, 
              {
                "text": "So the Light of Israel will be for a fire, And his Holy One for a flame; It will burn and devour His thorns and his briers in one day.", 
                "num": 17
              }, 
              {
                "text": "And it will consume the glory of his forest and of his fruitful field, Both soul and body; And they will be as when a sick man wastes away.", 
                "num": 18
              }, 
              {
                "text": "Then the rest of the trees of his forest Will be so few in number That a child may write them.", 
                "num": 19
              }, 
              {
                "text": "And it shall come to pass in that day That the remnant of Israel, And such as have escaped of the house of Jacob, Will never again depend on him who defeated them, But will depend on the LORD, the Holy One of Israel, in truth.", 
                "num": 20
              }, 
              {
                "text": "The remnant will return, the remnant of Jacob, To the Mighty God.", 
                "num": 21
              }, 
              {
                "text": "For though your people, O Israel, be as the sand of the sea, A remnant of them will return; The destruction decreed shall overflow with righteousness.", 
                "num": 22
              }, 
              {
                "text": "For the Lord GOD of hosts Will make a determined end In the midst of all the land.", 
                "num": 23
              }, 
              {
                "text": "Therefore thus says the Lord GOD of hosts: \"O My people, who dwell in Zion, do not be afraid of the Assyrian. He shall strike you with a rod and lift up his staff against you, in the manner of Egypt.", 
                "num": 24
              }, 
              {
                "text": "For yet a very little while and the indignation will cease, as will My anger in their destruction.\"", 
                "num": 25
              }, 
              {
                "text": "And the LORD of hosts will stir up a scourge for him like the slaughter of Midian at the rock of Oreb; as His rod was on the sea, so will He lift it up in the manner of Egypt.", 
                "num": 26
              }, 
              {
                "text": "It shall come to pass in that day That his burden will be taken away from your shoulder, And his yoke from your neck, And the yoke will be destroyed because of the anointing oil.", 
                "num": 27
              }, 
              {
                "text": "He has come to Aiath, He has passed Migron; At Michmash he has attended to his equipment.", 
                "num": 28
              }, 
              {
                "text": "They have gone along the ridge, They have taken up lodging at Geba. Ramah is afraid, Gibeah of Saul has fled.", 
                "num": 29
              }, 
              {
                "text": "Lift up your voice, O daughter of Gallim! Cause it to be heard as far as Laish-- O poor Anathoth!", 
                "num": 30
              }, 
              {
                "text": "Madmenah has fled, The inhabitants of Gebim seek refuge.", 
                "num": 31
              }, 
              {
                "text": "As yet he will remain at Nob that day; He will shake his fist at the mount of the daughter of Zion, The hill of Jerusalem.", 
                "num": 32
              }, 
              {
                "text": "Behold, the Lord, The LORD of hosts, Will lop off the bough with terror; Those of high stature will be hewn down, And the haughty will be humbled.", 
                "num": 33
              }, 
              {
                "text": "He will cut down the thickets of the forest with iron, And Lebanon will fall by the Mighty One.", 
                "num": 34
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "There shall come forth a Rod from the stem of Jesse, And a Branch shall grow out of his roots.", 
                "num": 1
              }, 
              {
                "text": "The Spirit of the LORD shall rest upon Him, The Spirit of wisdom and understanding, The Spirit of counsel and might, The Spirit of knowledge and of the fear of the LORD.", 
                "num": 2
              }, 
              {
                "text": "His delight is in the fear of the LORD, And He shall not judge by the sight of His eyes, Nor decide by the hearing of His ears;", 
                "num": 3
              }, 
              {
                "text": "But with righteousness He shall judge the poor, And decide with equity for the meek of the earth; He shall strike the earth with the rod of His mouth, And with the breath of His lips He shall slay the wicked.", 
                "num": 4
              }, 
              {
                "text": "Righteousness shall be the belt of His loins, And faithfulness the belt of His waist.", 
                "num": 5
              }, 
              {
                "text": "\"The wolf also shall dwell with the lamb, The leopard shall lie down with the young goat, The calf and the young lion and the fatling together; And a little child shall lead them.", 
                "num": 6
              }, 
              {
                "text": "The cow and the bear shall graze; Their young ones shall lie down together; And the lion shall eat straw like the ox.", 
                "num": 7
              }, 
              {
                "text": "The nursing child shall play by the cobra's hole, And the weaned child shall put his hand in the viper's den.", 
                "num": 8
              }, 
              {
                "text": "They shall not hurt nor destroy in all My holy mountain, For the earth shall be full of the knowledge of the LORD As the waters cover the sea.", 
                "num": 9
              }, 
              {
                "text": "\"And in that day there shall be a Root of Jesse, Who shall stand as a banner to the people; For the Gentiles shall seek Him, And His resting place shall be glorious.\"", 
                "num": 10
              }, 
              {
                "text": "It shall come to pass in that day That the Lord shall set His hand again the second time To recover the remnant of His people who are left, From Assyria and Egypt, From Pathros and Cush, From Elam and Shinar, From Hamath and the islands of the sea.", 
                "num": 11
              }, 
              {
                "text": "He will set up a banner for the nations, And will assemble the outcasts of Israel, And gather together the dispersed of Judah From the four corners of the earth.", 
                "num": 12
              }, 
              {
                "text": "Also the envy of Ephraim shall depart, And the adversaries of Judah shall be cut off; Ephraim shall not envy Judah, And Judah shall not harass Ephraim.", 
                "num": 13
              }, 
              {
                "text": "But they shall fly down upon the shoulder of the Philistines toward the west; Together they shall plunder the people of the East; They shall lay their hand on Edom and Moab; And the people of Ammon shall obey them.", 
                "num": 14
              }, 
              {
                "text": "The LORD will utterly destroy the tongue of the Sea of Egypt; With His mighty wind He will shake His fist over the River, And strike it in the seven streams, And make men cross over dryshod.", 
                "num": 15
              }, 
              {
                "text": "There will be a highway for the remnant of His people Who will be left from Assyria, As it was for Israel In the day that he came up from the land of Egypt.", 
                "num": 16
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "And in that day you will say: \"O LORD, I will praise You; Though You were angry with me, Your anger is turned away, and You comfort me.", 
                "num": 1
              }, 
              {
                "text": "Behold, God is my salvation, I will trust and not be afraid; \"For YAH, the LORD, is my strength and song; He also has become my salvation.\"'", 
                "num": 2
              }, 
              {
                "text": "Therefore with joy you will draw water From the wells of salvation.", 
                "num": 3
              }, 
              {
                "text": "And in that day you will say: \"Praise the LORD, call upon His name; Declare His deeds among the peoples, Make mention that His name is exalted.", 
                "num": 4
              }, 
              {
                "text": "Sing to the LORD, For He has done excellent things; This is known in all the earth.", 
                "num": 5
              }, 
              {
                "text": "Cry out and shout, O inhabitant of Zion, For great is the Holy One of Israel in your midst!\"", 
                "num": 6
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "The burden against Babylon which Isaiah the son of Amoz saw.", 
                "num": 1
              }, 
              {
                "text": "\"Lift up a banner on the high mountain, Raise your voice to them; Wave your hand, that they may enter the gates of the nobles.", 
                "num": 2
              }, 
              {
                "text": "I have commanded My sanctified ones; I have also called My mighty ones for My anger-- Those who rejoice in My exaltation.\"", 
                "num": 3
              }, 
              {
                "text": "The noise of a multitude in the mountains, Like that of many people! A tumultuous noise of the kingdoms of nations gathered together! The LORD of hosts musters The army for battle.", 
                "num": 4
              }, 
              {
                "text": "They come from a far country, From the end of heaven-- The LORD and His weapons of indignation, To destroy the whole land.", 
                "num": 5
              }, 
              {
                "text": "Wail, for the day of the LORD is at hand! It will come as destruction from the Almighty.", 
                "num": 6
              }, 
              {
                "text": "Therefore all hands will be limp, Every man's heart will melt,", 
                "num": 7
              }, 
              {
                "text": "And they will be afraid. Pangs and sorrows will take hold of them; They will be in pain as a woman in childbirth; They will be amazed at one another; Their faces will be like flames.", 
                "num": 8
              }, 
              {
                "text": "Behold, the day of the LORD comes, Cruel, with both wrath and fierce anger, To lay the land desolate; And He will destroy its sinners from it.", 
                "num": 9
              }, 
              {
                "text": "For the stars of heaven and their constellations Will not give their light; The sun will be darkened in its going forth, And the moon will not cause its light to shine.", 
                "num": 10
              }, 
              {
                "text": "\"I will punish the world for its evil, And the wicked for their iniquity; I will halt the arrogance of the proud, And will lay low the haughtiness of the terrible.", 
                "num": 11
              }, 
              {
                "text": "I will make a mortal more rare than fine gold, A man more than the golden wedge of Ophir.", 
                "num": 12
              }, 
              {
                "text": "Therefore I will shake the heavens, And the earth will move out of her place, In the wrath of the LORD of hosts And in the day of His fierce anger.", 
                "num": 13
              }, 
              {
                "text": "It shall be as the hunted gazelle, And as a sheep that no man takes up; Every man will turn to his own people, And everyone will flee to his own land.", 
                "num": 14
              }, 
              {
                "text": "Everyone who is found will be thrust through, And everyone who is captured will fall by the sword.", 
                "num": 15
              }, 
              {
                "text": "Their children also will be dashed to pieces before their eyes; Their houses will be plundered And their wives ravished.", 
                "num": 16
              }, 
              {
                "text": "\"Behold, I will stir up the Medes against them, Who will not regard silver; And as for gold, they will not delight in it.", 
                "num": 17
              }, 
              {
                "text": "Also their bows will dash the young men to pieces, And they will have no pity on the fruit of the womb; Their eye will not spare children.", 
                "num": 18
              }, 
              {
                "text": "And Babylon, the glory of kingdoms, The beauty of the Chaldeans' pride, Will be as when God overthrew Sodom and Gomorrah.", 
                "num": 19
              }, 
              {
                "text": "It will never be inhabited, Nor will it be settled from generation to generation; Nor will the Arabian pitch tents there, Nor will the shepherds make their sheepfolds there.", 
                "num": 20
              }, 
              {
                "text": "But wild beasts of the desert will lie there, And their houses will be full of owls; Ostriches will dwell there, And wild goats will caper there.", 
                "num": 21
              }, 
              {
                "text": "The hyenas will howl in their citadels, And jackals in their pleasant palaces. Her time is near to come, And her days will not be prolonged.\"", 
                "num": 22
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "For the LORD will have mercy on Jacob, and will still choose Israel, and settle them in their own land. The strangers will be joined with them, and they will cling to the house of Jacob.", 
                "num": 1
              }, 
              {
                "text": "Then people will take them and bring them to their place, and the house of Israel will possess them for servants and maids in the land of the LORD; they will take them captive whose captives they were, and rule over their oppressors.", 
                "num": 2
              }, 
              {
                "text": "It shall come to pass in the day the LORD gives you rest from your sorrow, and from your fear and the hard bondage in which you were made to serve,", 
                "num": 3
              }, 
              {
                "text": "that you will take up this proverb against the king of Babylon, and say: \"How the oppressor has ceased, The golden city ceased!", 
                "num": 4
              }, 
              {
                "text": "The LORD has broken the staff of the wicked, The scepter of the rulers;", 
                "num": 5
              }, 
              {
                "text": "He who struck the people in wrath with a continual stroke, He who ruled the nations in anger, Is persecuted and no one hinders.", 
                "num": 6
              }, 
              {
                "text": "The whole earth is at rest and quiet; They break forth into singing.", 
                "num": 7
              }, 
              {
                "text": "Indeed the cypress trees rejoice over you, And the cedars of Lebanon, Saying, \"Since you were cut down, No woodsman has come up against us.'", 
                "num": 8
              }, 
              {
                "text": "\"Hell from beneath is excited about you, To meet you at your coming; It stirs up the dead for you, All the chief ones of the earth; It has raised up from their thrones All the kings of the nations.", 
                "num": 9
              }, 
              {
                "text": "They all shall speak and say to you: \"Have you also become as weak as we? Have you become like us?", 
                "num": 10
              }, 
              {
                "text": "Your pomp is brought down to Sheol, And the sound of your stringed instruments; The maggot is spread under you, And worms cover you.'", 
                "num": 11
              }, 
              {
                "text": "\"How you are fallen from heaven, O Lucifer, son of the morning! How you are cut down to the ground, You who weakened the nations!", 
                "num": 12
              }, 
              {
                "text": "For you have said in your heart: \"I will ascend into heaven, I will exalt my throne above the stars of God; I will also sit on the mount of the congregation On the farthest sides of the north;", 
                "num": 13
              }, 
              {
                "text": "I will ascend above the heights of the clouds, I will be like the Most High.'", 
                "num": 14
              }, 
              {
                "text": "Yet you shall be brought down to Sheol, To the lowest depths of the Pit.", 
                "num": 15
              }, 
              {
                "text": "\"Those who see you will gaze at you, And consider you, saying: \"Is this the man who made the earth tremble, Who shook kingdoms,", 
                "num": 16
              }, 
              {
                "text": "Who made the world as a wilderness And destroyed its cities, Who did not open the house of his prisoners?'", 
                "num": 17
              }, 
              {
                "text": "\"All the kings of the nations, All of them, sleep in glory, Everyone in his own house;", 
                "num": 18
              }, 
              {
                "text": "But you are cast out of your grave Like an abominable branch, Like the garment of those who are slain, Thrust through with a sword, Who go down to the stones of the pit, Like a corpse trodden underfoot.", 
                "num": 19
              }, 
              {
                "text": "You will not be joined with them in burial, Because you have destroyed your land And slain your people. The brood of evildoers shall never be named.", 
                "num": 20
              }, 
              {
                "text": "Prepare slaughter for his children Because of the iniquity of their fathers, Lest they rise up and possess the land, And fill the face of the world with cities.\"", 
                "num": 21
              }, 
              {
                "text": "\"For I will rise up against them,\" says the LORD of hosts, \"And cut off from Babylon the name and remnant, And offspring and posterity,\" says the LORD.", 
                "num": 22
              }, 
              {
                "text": "\"I will also make it a possession for the porcupine, And marshes of muddy water; I will sweep it with the broom of destruction,\" says the LORD of hosts.", 
                "num": 23
              }, 
              {
                "text": "The LORD of hosts has sworn, saying, \"Surely, as I have thought, so it shall come to pass, And as I have purposed, so it shall stand:", 
                "num": 24
              }, 
              {
                "text": "That I will break the Assyrian in My land, And on My mountains tread him underfoot. Then his yoke shall be removed from them, And his burden removed from their shoulders.", 
                "num": 25
              }, 
              {
                "text": "This is the purpose that is purposed against the whole earth, And this is the hand that is stretched out over all the nations.", 
                "num": 26
              }, 
              {
                "text": "For the LORD of hosts has purposed, And who will annul it? His hand is stretched out, And who will turn it back?\"", 
                "num": 27
              }, 
              {
                "text": "This is the burden which came in the year that King Ahaz died.", 
                "num": 28
              }, 
              {
                "text": "\"Do not rejoice, all you of Philistia, Because the rod that struck you is broken; For out of the serpent's roots will come forth a viper, And its offspring will be a fiery flying serpent.", 
                "num": 29
              }, 
              {
                "text": "The firstborn of the poor will feed, And the needy will lie down in safety; I will kill your roots with famine, And it will slay your remnant.", 
                "num": 30
              }, 
              {
                "text": "Wail, O gate! Cry, O city! All you of Philistia are dissolved; For smoke will come from the north, And no one will be alone in his appointed times.\"", 
                "num": 31
              }, 
              {
                "text": "What will they answer the messengers of the nation? That the LORD has founded Zion, And the poor of His people shall take refuge in it.", 
                "num": 32
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "The burden against Moab. Because in the night Ar of Moab is laid waste And destroyed, Because in the night Kir of Moab is laid waste And destroyed,", 
                "num": 1
              }, 
              {
                "text": "He has gone up to the temple and Dibon, To the high places to weep. Moab will wail over Nebo and over Medeba; On all their heads will be baldness, And every beard cut off.", 
                "num": 2
              }, 
              {
                "text": "In their streets they will clothe themselves with sackcloth; On the tops of their houses And in their streets Everyone will wail, weeping bitterly.", 
                "num": 3
              }, 
              {
                "text": "Heshbon and Elealeh will cry out, Their voice shall be heard as far as Jahaz; Therefore the armed soldiers of Moab will cry out; His life will be burdensome to him.", 
                "num": 4
              }, 
              {
                "text": "\"My heart will cry out for Moab; His fugitives shall flee to Zoar, Like a three-year-old heifer. For by the Ascent of Luhith They will go up with weeping; For in the way of Horonaim They will raise up a cry of destruction,", 
                "num": 5
              }, 
              {
                "text": "For the waters of Nimrim will be desolate, For the green grass has withered away; The grass fails, there is nothing green.", 
                "num": 6
              }, 
              {
                "text": "Therefore the abundance they have gained, And what they have laid up, They will carry away to the Brook of the Willows.", 
                "num": 7
              }, 
              {
                "text": "For the cry has gone all around the borders of Moab, Its wailing to Eglaim And its wailing to Beer Elim.", 
                "num": 8
              }, 
              {
                "text": "For the waters of Dimon will be full of blood; Because I will bring more upon Dimon, Lions upon him who escapes from Moab, And on the remnant of the land.\"", 
                "num": 9
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Send the lamb to the ruler of the land, From Sela to the wilderness, To the mount of the daughter of Zion.", 
                "num": 1
              }, 
              {
                "text": "For it shall be as a wandering bird thrown out of the nest; So shall be the daughters of Moab at the fords of the Arnon.", 
                "num": 2
              }, 
              {
                "text": "\"Take counsel, execute judgment; Make your shadow like the night in the middle of the day; Hide the outcasts, Do not betray him who escapes.", 
                "num": 3
              }, 
              {
                "text": "Let My outcasts dwell with you, O Moab; Be a shelter to them from the face of the spoiler. For the extortioner is at an end, Devastation ceases, The oppressors are consumed out of the land.", 
                "num": 4
              }, 
              {
                "text": "In mercy the throne will be established; And One will sit on it in truth, in the tabernacle of David, Judging and seeking justice and hastening righteousness.\"", 
                "num": 5
              }, 
              {
                "text": "We have heard of the pride of Moab-- He is very proud-- Of his haughtiness and his pride and his wrath; But his lies shall not be so.", 
                "num": 6
              }, 
              {
                "text": "Therefore Moab shall wail for Moab; Everyone shall wail. For the foundations of Kir Hareseth you shall mourn; Surely they are stricken.", 
                "num": 7
              }, 
              {
                "text": "For the fields of Heshbon languish, And the vine of Sibmah; The lords of the nations have broken down its choice plants, Which have reached to Jazer And wandered through the wilderness. Her branches are stretched out, They are gone over the sea.", 
                "num": 8
              }, 
              {
                "text": "Therefore I will bewail the vine of Sibmah, With the weeping of Jazer; I will drench you with my tears, O Heshbon and Elealeh; For battle cries have fallen Over your summer fruits and your harvest.", 
                "num": 9
              }, 
              {
                "text": "Gladness is taken away, And joy from the plentiful field; In the vineyards there will be no singing, Nor will there be shouting; No treaders will tread out wine in the presses; I have made their shouting cease.", 
                "num": 10
              }, 
              {
                "text": "Therefore my heart shall resound like a harp for Moab, And my inner being for Kir Heres.", 
                "num": 11
              }, 
              {
                "text": "And it shall come to pass, When it is seen that Moab is weary on the high place, That he will come to his sanctuary to pray; But he will not prevail.", 
                "num": 12
              }, 
              {
                "text": "This is the word which the LORD has spoken concerning Moab since that time.", 
                "num": 13
              }, 
              {
                "text": "But now the LORD has spoken, saying, \"Within three years, as the years of a hired man, the glory of Moab will be despised with all that great multitude, and the remnant will be very small and feeble.\"", 
                "num": 14
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "The burden against Damascus. \"Behold, Damascus will cease from being a city, And it will be a ruinous heap.", 
                "num": 1
              }, 
              {
                "text": "The cities of Aroer are forsaken; They will be for flocks Which lie down, and no one will make them afraid.", 
                "num": 2
              }, 
              {
                "text": "The fortress also will cease from Ephraim, The kingdom from Damascus, And the remnant of Syria; They will be as the glory of the children of Israel,\" Says the LORD of hosts.", 
                "num": 3
              }, 
              {
                "text": "\"In that day it shall come to pass That the glory of Jacob will wane, And the fatness of his flesh grow lean.", 
                "num": 4
              }, 
              {
                "text": "It shall be as when the harvester gathers the grain, And reaps the heads with his arm; It shall be as he who gathers heads of grain In the Valley of Rephaim.", 
                "num": 5
              }, 
              {
                "text": "Yet gleaning grapes will be left in it, Like the shaking of an olive tree, Two or three olives at the top of the uppermost bough, Four or five in its most fruitful branches,\" Says the LORD God of Israel.", 
                "num": 6
              }, 
              {
                "text": "In that day a man will look to his Maker, And his eyes will have respect for the Holy One of Israel.", 
                "num": 7
              }, 
              {
                "text": "He will not look to the altars, The work of his hands; He will not respect what his fingers have made, Nor the wooden images nor the incense altars.", 
                "num": 8
              }, 
              {
                "text": "In that day his strong cities will be as a forsaken bough And an uppermost branch, Which they left because of the children of Israel; And there will be desolation.", 
                "num": 9
              }, 
              {
                "text": "Because you have forgotten the God of your salvation, And have not been mindful of the Rock of your stronghold, Therefore you will plant pleasant plants And set out foreign seedlings;", 
                "num": 10
              }, 
              {
                "text": "In the day you will make your plant to grow, And in the morning you will make your seed to flourish; But the harvest will be a heap of ruins In the day of grief and desperate sorrow.", 
                "num": 11
              }, 
              {
                "text": "Woe to the multitude of many people Who make a noise like the roar of the seas, And to the rushing of nations That make a rushing like the rushing of mighty waters!", 
                "num": 12
              }, 
              {
                "text": "The nations will rush like the rushing of many waters; But God will rebuke them and they will flee far away, And be chased like the chaff of the mountains before the wind, Like a rolling thing before the whirlwind.", 
                "num": 13
              }, 
              {
                "text": "Then behold, at eventide, trouble! And before the morning, he is no more. This is the portion of those who plunder us, And the lot of those who rob us.", 
                "num": 14
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Woe to the land shadowed with buzzing wings, Which is beyond the rivers of Ethiopia,", 
                "num": 1
              }, 
              {
                "text": "Which sends ambassadors by sea, Even in vessels of reed on the waters, saying, \"Go, swift messengers, to a nation tall and smooth of skin, To a people terrible from their beginning onward, A nation powerful and treading down, Whose land the rivers divide.\"", 
                "num": 2
              }, 
              {
                "text": "All inhabitants of the world and dwellers on the earth: When he lifts up a banner on the mountains, you see it; And when he blows a trumpet, you hear it.", 
                "num": 3
              }, 
              {
                "text": "For so the LORD said to me, \"I will take My rest, And I will look from My dwelling place Like clear heat in sunshine, Like a cloud of dew in the heat of harvest.\"", 
                "num": 4
              }, 
              {
                "text": "For before the harvest, when the bud is perfect And the sour grape is ripening in the flower, He will both cut off the sprigs with pruning hooks And take away and cut down the branches.", 
                "num": 5
              }, 
              {
                "text": "They will be left together for the mountain birds of prey And for the beasts of the earth; The birds of prey will summer on them, And all the beasts of the earth will winter on them.", 
                "num": 6
              }, 
              {
                "text": "In that time a present will be brought to the LORD of hosts From a people tall and smooth of skin, And from a people terrible from their beginning onward, A nation powerful and treading down, Whose land the rivers divide-- To the place of the name of the LORD of hosts, To Mount Zion.", 
                "num": 7
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "The burden against Egypt. Behold, the LORD rides on a swift cloud, And will come into Egypt; The idols of Egypt will totter at His presence, And the heart of Egypt will melt in its midst.", 
                "num": 1
              }, 
              {
                "text": "\"I will set Egyptians against Egyptians; Everyone will fight against his brother, And everyone against his neighbor, City against city, kingdom against kingdom.", 
                "num": 2
              }, 
              {
                "text": "The spirit of Egypt will fail in its midst; I will destroy their counsel, And they will consult the idols and the charmers, The mediums and the sorcerers.", 
                "num": 3
              }, 
              {
                "text": "And the Egyptians I will give Into the hand of a cruel master, And a fierce king will rule over them,\" Says the Lord, the LORD of hosts.", 
                "num": 4
              }, 
              {
                "text": "The waters will fail from the sea, And the river will be wasted and dried up.", 
                "num": 5
              }, 
              {
                "text": "The rivers will turn foul; The brooks of defense will be emptied and dried up; The reeds and rushes will wither.", 
                "num": 6
              }, 
              {
                "text": "The papyrus reeds by the River, by the mouth of the River, And everything sown by the River, Will wither, be driven away, and be no more.", 
                "num": 7
              }, 
              {
                "text": "The fishermen also will mourn; All those will lament who cast hooks into the River, And they will languish who spread nets on the waters.", 
                "num": 8
              }, 
              {
                "text": "Moreover those who work in fine flax And those who weave fine fabric will be ashamed;", 
                "num": 9
              }, 
              {
                "text": "And its foundations will be broken. All who make wages will be troubled of soul.", 
                "num": 10
              }, 
              {
                "text": "Surely the princes of Zoan are fools; Pharaoh's wise counselors give foolish counsel. How do you say to Pharaoh, \"I am the son of the wise, The son of ancient kings?\"", 
                "num": 11
              }, 
              {
                "text": "Where are they? Where are your wise men? Let them tell you now, And let them know what the LORD of hosts has purposed against Egypt.", 
                "num": 12
              }, 
              {
                "text": "The princes of Zoan have become fools; The princes of Noph are deceived; They have also deluded Egypt, Those who are the mainstay of its tribes.", 
                "num": 13
              }, 
              {
                "text": "The LORD has mingled a perverse spirit in her midst; And they have caused Egypt to err in all her work, As a drunken man staggers in his vomit.", 
                "num": 14
              }, 
              {
                "text": "Neither will there be any work for Egypt, Which the head or tail, Palm branch or bulrush, may do.", 
                "num": 15
              }, 
              {
                "text": "In that day Egypt will be like women, and will be afraid and fear because of the waving of the hand of the LORD of hosts, which He waves over it.", 
                "num": 16
              }, 
              {
                "text": "And the land of Judah will be a terror to Egypt; everyone who makes mention of it will be afraid in himself, because of the counsel of the LORD of hosts which He has determined against it.", 
                "num": 17
              }, 
              {
                "text": "In that day five cities in the land of Egypt will speak the language of Canaan and swear by the LORD of hosts; one will be called the City of Destruction.", 
                "num": 18
              }, 
              {
                "text": "In that day there will be an altar to the LORD in the midst of the land of Egypt, and a pillar to the LORD at its border.", 
                "num": 19
              }, 
              {
                "text": "And it will be for a sign and for a witness to the LORD of hosts in the land of Egypt; for they will cry to the LORD because of the oppressors, and He will send them a Savior and a Mighty One, and He will deliver them.", 
                "num": 20
              }, 
              {
                "text": "Then the LORD will be known to Egypt, and the Egyptians will know the LORD in that day, and will make sacrifice and offering; yes, they will make a vow to the LORD and perform it.", 
                "num": 21
              }, 
              {
                "text": "And the LORD will strike Egypt, He will strike and heal it; they will return to the LORD, and He will be entreated by them and heal them.", 
                "num": 22
              }, 
              {
                "text": "In that day there will be a highway from Egypt to Assyria, and the Assyrian will come into Egypt and the Egyptian into Assyria, and the Egyptians will serve with the Assyrians.", 
                "num": 23
              }, 
              {
                "text": "In that day Israel will be one of three with Egypt and Assyria--a blessing in the midst of the land,", 
                "num": 24
              }, 
              {
                "text": "whom the LORD of hosts shall bless, saying, \"Blessed is Egypt My people, and Assyria the work of My hands, and Israel My inheritance.\"", 
                "num": 25
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "In the year that Tartan came to Ashdod, when Sargon the king of Assyria sent him, and he fought against Ashdod and took it,", 
                "num": 1
              }, 
              {
                "text": "at the same time the LORD spoke by Isaiah the son of Amoz, saying, \"Go, and remove the sackcloth from your body, and take your sandals off your feet.\" And he did so, walking naked and barefoot.", 
                "num": 2
              }, 
              {
                "text": "Then the LORD said, \"Just as My servant Isaiah has walked naked and barefoot three years for a sign and a wonder against Egypt and Ethiopia,", 
                "num": 3
              }, 
              {
                "text": "so shall the king of Assyria lead away the Egyptians as prisoners and the Ethiopians as captives, young and old, naked and barefoot, with their buttocks uncovered, to the shame of Egypt.", 
                "num": 4
              }, 
              {
                "text": "Then they shall be afraid and ashamed of Ethiopia their expectation and Egypt their glory.", 
                "num": 5
              }, 
              {
                "text": "And the inhabitant of this territory will say in that day, \"Surely such is our expectation, wherever we flee for help to be delivered from the king of Assyria; and how shall we escape?\"'", 
                "num": 6
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "The burden against the Wilderness of the Sea. As whirlwinds in the South pass through, So it comes from the desert, from a terrible land.", 
                "num": 1
              }, 
              {
                "text": "A distressing vision is declared to me; The treacherous dealer deals treacherously, And the plunderer plunders. Go up, O Elam! Besiege, O Media! All its sighing I have made to cease.", 
                "num": 2
              }, 
              {
                "text": "Therefore my loins are filled with pain; Pangs have taken hold of me, like the pangs of a woman in labor. I was distressed when I heard it; I was dismayed when I saw it.", 
                "num": 3
              }, 
              {
                "text": "My heart wavered, fearfulness frightened me; The night for which I longed He turned into fear for me.", 
                "num": 4
              }, 
              {
                "text": "Prepare the table, Set a watchman in the tower, Eat and drink. Arise, you princes, Anoint the shield!", 
                "num": 5
              }, 
              {
                "text": "For thus has the Lord said to me: \"Go, set a watchman, Let him declare what he sees.\"", 
                "num": 6
              }, 
              {
                "text": "And he saw a chariot with a pair of horsemen, A chariot of donkeys, and a chariot of camels, And he listened earnestly with great care.", 
                "num": 7
              }, 
              {
                "text": "Then he cried, \"A lion, my Lord! I stand continually on the watchtower in the daytime; I have sat at my post every night.", 
                "num": 8
              }, 
              {
                "text": "And look, here comes a chariot of men with a pair of horsemen!\" Then he answered and said, \"Babylon is fallen, is fallen! And all the carved images of her gods He has broken to the ground.\"", 
                "num": 9
              }, 
              {
                "text": "Oh, my threshing and the grain of my floor! That which I have heard from the LORD of hosts, The God of Israel, I have declared to you.", 
                "num": 10
              }, 
              {
                "text": "The burden against Dumah. He calls to me out of Seir, \"Watchman, what of the night? Watchman, what of the night?\"", 
                "num": 11
              }, 
              {
                "text": "The watchman said, \"The morning comes, and also the night. If you will inquire, inquire; Return! Come back!\"", 
                "num": 12
              }, 
              {
                "text": "The burden against Arabia. In the forest in Arabia you will lodge, O you traveling companies of Dedanites.", 
                "num": 13
              }, 
              {
                "text": "O inhabitants of the land of Tema, Bring water to him who is thirsty; With their bread they met him who fled.", 
                "num": 14
              }, 
              {
                "text": "For they fled from the swords, from the drawn sword, From the bent bow, and from the distress of war.", 
                "num": 15
              }, 
              {
                "text": "For thus the LORD has said to me: \"Within a year, according to the year of a hired man, all the glory of Kedar will fail;", 
                "num": 16
              }, 
              {
                "text": "and the remainder of the number of archers, the mighty men of the people of Kedar, will be diminished; for the LORD God of Israel has spoken it.\"", 
                "num": 17
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "The burden against the Valley of Vision. What ails you now, that you have all gone up to the housetops,", 
                "num": 1
              }, 
              {
                "text": "You who are full of noise, A tumultuous city, a joyous city? Your slain men are not slain with the sword, Nor dead in battle.", 
                "num": 2
              }, 
              {
                "text": "All your rulers have fled together; They are captured by the archers. All who are found in you are bound together; They have fled from afar.", 
                "num": 3
              }, 
              {
                "text": "Therefore I said, \"Look away from me, I will weep bitterly; Do not labor to comfort me Because of the plundering of the daughter of my people.\"", 
                "num": 4
              }, 
              {
                "text": "For it is a day of trouble and treading down and perplexity By the Lord GOD of hosts In the Valley of Vision-- Breaking down the walls And of crying to the mountain.", 
                "num": 5
              }, 
              {
                "text": "Elam bore the quiver With chariots of men and horsemen, And Kir uncovered the shield.", 
                "num": 6
              }, 
              {
                "text": "It shall come to pass that your choicest valleys Shall be full of chariots, And the horsemen shall set themselves in array at the gate.", 
                "num": 7
              }, 
              {
                "text": "He removed the protection of Judah. You looked in that day to the armor of the House of the Forest;", 
                "num": 8
              }, 
              {
                "text": "You also saw the damage to the city of David, That it was great; And you gathered together the waters of the lower pool.", 
                "num": 9
              }, 
              {
                "text": "You numbered the houses of Jerusalem, And the houses you broke down To fortify the wall.", 
                "num": 10
              }, 
              {
                "text": "You also made a reservoir between the two walls For the water of the old pool. But you did not look to its Maker, Nor did you have respect for Him who fashioned it long ago.", 
                "num": 11
              }, 
              {
                "text": "And in that day the Lord GOD of hosts Called for weeping and for mourning, For baldness and for girding with sackcloth.", 
                "num": 12
              }, 
              {
                "text": "But instead, joy and gladness, Slaying oxen and killing sheep, Eating meat and drinking wine: \"Let us eat and drink, for tomorrow we die!\"", 
                "num": 13
              }, 
              {
                "text": "Then it was revealed in my hearing by the LORD of hosts, \"Surely for this iniquity there will be no atonement for you, Even to your death,\" says the Lord GOD of hosts.", 
                "num": 14
              }, 
              {
                "text": "Thus says the Lord GOD of hosts: \"Go, proceed to this steward, To Shebna, who is over the house, and say:", 
                "num": 15
              }, 
              {
                "text": "\"What have you here, and whom have you here, That you have hewn a sepulcher here, As he who hews himself a sepulcher on high, Who carves a tomb for himself in a rock?", 
                "num": 16
              }, 
              {
                "text": "Indeed, the LORD will throw you away violently, O mighty man, And will surely seize you.", 
                "num": 17
              }, 
              {
                "text": "He will surely turn violently and toss you like a ball Into a large country; There you shall die, and there your glorious chariots Shall be the shame of your master's house.", 
                "num": 18
              }, 
              {
                "text": "So I will drive you out of your office, And from your position he will pull you down.", 
                "num": 19
              }, 
              {
                "text": "\"Then it shall be in that day, That I will call My servant Eliakim the son of Hilkiah;", 
                "num": 20
              }, 
              {
                "text": "I will clothe him with your robe And strengthen him with your belt; I will commit your responsibility into his hand. He shall be a father to the inhabitants of Jerusalem And to the house of Judah.", 
                "num": 21
              }, 
              {
                "text": "The key of the house of David I will lay on his shoulder; So he shall open, and no one shall shut; And he shall shut, and no one shall open.", 
                "num": 22
              }, 
              {
                "text": "I will fasten him as a peg in a secure place, And he will become a glorious throne to his father's house.", 
                "num": 23
              }, 
              {
                "text": "\"They will hang on him all the glory of his father's house, the offspring and the posterity, all vessels of small quantity, from the cups to all the pitchers.", 
                "num": 24
              }, 
              {
                "text": "In that day,' says the LORD of hosts, \"the peg that is fastened in the secure place will be removed and be cut down and fall, and the burden that was on it will be cut off; for the LORD has spoken.\"'", 
                "num": 25
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "The burden against Tyre. Wail, you ships of Tarshish! For it is laid waste, So that there is no house, no harbor; From the land of Cyprus it is revealed to them.", 
                "num": 1
              }, 
              {
                "text": "Be still, you inhabitants of the coastland, You merchants of Sidon, Whom those who cross the sea have filled.", 
                "num": 2
              }, 
              {
                "text": "And on great waters the grain of Shihor, The harvest of the River, is her revenue; And she is a marketplace for the nations.", 
                "num": 3
              }, 
              {
                "text": "Be ashamed, O Sidon; For the sea has spoken, The strength of the sea, saying, \"I do not labor, nor bring forth children; Neither do I rear young men, Nor bring up virgins.\"", 
                "num": 4
              }, 
              {
                "text": "When the report reaches Egypt, They also will be in agony at the report of Tyre.", 
                "num": 5
              }, 
              {
                "text": "Cross over to Tarshish; Wail, you inhabitants of the coastland!", 
                "num": 6
              }, 
              {
                "text": "Is this your joyous city, Whose antiquity is from ancient days, Whose feet carried her far off to dwell?", 
                "num": 7
              }, 
              {
                "text": "Who has taken this counsel against Tyre, the crowning city, Whose merchants are princes, Whose traders are the honorable of the earth?", 
                "num": 8
              }, 
              {
                "text": "The LORD of hosts has purposed it, To bring to dishonor the pride of all glory, To bring into contempt all the honorable of the earth.", 
                "num": 9
              }, 
              {
                "text": "Overflow through your land like the River, O daughter of Tarshish; There is no more strength.", 
                "num": 10
              }, 
              {
                "text": "He stretched out His hand over the sea, He shook the kingdoms; The LORD has given a commandment against Canaan To destroy its strongholds.", 
                "num": 11
              }, 
              {
                "text": "And He said, \"You will rejoice no more, O you oppressed virgin daughter of Sidon. Arise, cross over to Cyprus; There also you will have no rest.\"", 
                "num": 12
              }, 
              {
                "text": "Behold, the land of the Chaldeans, This people which was not; Assyria founded it for wild beasts of the desert. They set up its towers, They raised up its palaces, And brought it to ruin.", 
                "num": 13
              }, 
              {
                "text": "Wail, you ships of Tarshish! For your strength is laid waste.", 
                "num": 14
              }, 
              {
                "text": "Now it shall come to pass in that day that Tyre will be forgotten seventy years, according to the days of one king. At the end of seventy years it will happen to Tyre as in the song of the harlot:", 
                "num": 15
              }, 
              {
                "text": "\"Take a harp, go about the city, You forgotten harlot; Make sweet melody, sing many songs, That you may be remembered.\"", 
                "num": 16
              }, 
              {
                "text": "And it shall be, at the end of seventy years, that the LORD will deal with Tyre. She will return to her hire, and commit fornication with all the kingdoms of the world on the face of the earth.", 
                "num": 17
              }, 
              {
                "text": "Her gain and her pay will be set apart for the LORD; it will not be treasured nor laid up, for her gain will be for those who dwell before the LORD, to eat sufficiently, and for fine clothing.", 
                "num": 18
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Behold, the LORD makes the earth empty and makes it waste, Distorts its surface And scatters abroad its inhabitants.", 
                "num": 1
              }, 
              {
                "text": "And it shall be: As with the people, so with the priest; As with the servant, so with his master; As with the maid, so with her mistress; As with the buyer, so with the seller; As with the lender, so with the borrower; As with the creditor, so with the debtor.", 
                "num": 2
              }, 
              {
                "text": "The land shall be entirely emptied and utterly plundered, For the LORD has spoken this word.", 
                "num": 3
              }, 
              {
                "text": "The earth mourns and fades away, The world languishes and fades away; The haughty people of the earth languish.", 
                "num": 4
              }, 
              {
                "text": "The earth is also defiled under its inhabitants, Because they have transgressed the laws, Changed the ordinance, Broken the everlasting covenant.", 
                "num": 5
              }, 
              {
                "text": "Therefore the curse has devoured the earth, And those who dwell in it are desolate. Therefore the inhabitants of the earth are burned, And few men are left.", 
                "num": 6
              }, 
              {
                "text": "The new wine fails, the vine languishes, All the merry-hearted sigh.", 
                "num": 7
              }, 
              {
                "text": "The mirth of the tambourine ceases, The noise of the jubilant ends, The joy of the harp ceases.", 
                "num": 8
              }, 
              {
                "text": "They shall not drink wine with a song; Strong drink is bitter to those who drink it.", 
                "num": 9
              }, 
              {
                "text": "The city of confusion is broken down; Every house is shut up, so that none may go in.", 
                "num": 10
              }, 
              {
                "text": "There is a cry for wine in the streets, All joy is darkened, The mirth of the land is gone.", 
                "num": 11
              }, 
              {
                "text": "In the city desolation is left, And the gate is stricken with destruction.", 
                "num": 12
              }, 
              {
                "text": "When it shall be thus in the midst of the land among the people, It shall be like the shaking of an olive tree, Like the gleaning of grapes when the vintage is done.", 
                "num": 13
              }, 
              {
                "text": "They shall lift up their voice, they shall sing; For the majesty of the LORD They shall cry aloud from the sea.", 
                "num": 14
              }, 
              {
                "text": "Therefore glorify the LORD in the dawning light, The name of the LORD God of Israel in the coastlands of the sea.", 
                "num": 15
              }, 
              {
                "text": "From the ends of the earth we have heard songs: \"Glory to the righteous!\" But I said, \"I am ruined, ruined! Woe to me! The treacherous dealers have dealt treacherously, Indeed, the treacherous dealers have dealt very treacherously.\"", 
                "num": 16
              }, 
              {
                "text": "Fear and the pit and the snare Are upon you, O inhabitant of the earth.", 
                "num": 17
              }, 
              {
                "text": "And it shall be That he who flees from the noise of the fear Shall fall into the pit, And he who comes up from the midst of the pit Shall be caught in the snare; For the windows from on high are open, And the foundations of the earth are shaken.", 
                "num": 18
              }, 
              {
                "text": "The earth is violently broken, The earth is split open, The earth is shaken exceedingly.", 
                "num": 19
              }, 
              {
                "text": "The earth shall reel to and fro like a drunkard, And shall totter like a hut; Its transgression shall be heavy upon it, And it will fall, and not rise again.", 
                "num": 20
              }, 
              {
                "text": "It shall come to pass in that day That the LORD will punish on high the host of exalted ones, And on the earth the kings of the earth.", 
                "num": 21
              }, 
              {
                "text": "They will be gathered together, As prisoners are gathered in the pit, And will be shut up in the prison; After many days they will be punished.", 
                "num": 22
              }, 
              {
                "text": "Then the moon will be disgraced And the sun ashamed; For the LORD of hosts will reign On Mount Zion and in Jerusalem And before His elders, gloriously.", 
                "num": 23
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "O LORD, You are my God. I will exalt You, I will praise Your name, For You have done wonderful things; Your counsels of old are faithfulness and truth.", 
                "num": 1
              }, 
              {
                "text": "For You have made a city a ruin, A fortified city a ruin, A palace of foreigners to be a city no more; It will never be rebuilt.", 
                "num": 2
              }, 
              {
                "text": "Therefore the strong people will glorify You; The city of the terrible nations will fear You.", 
                "num": 3
              }, 
              {
                "text": "For You have been a strength to the poor, A strength to the needy in his distress, A refuge from the storm, A shade from the heat; For the blast of the terrible ones is as a storm against the wall.", 
                "num": 4
              }, 
              {
                "text": "You will reduce the noise of aliens, As heat in a dry place; As heat in the shadow of a cloud, The song of the terrible ones will be diminished.", 
                "num": 5
              }, 
              {
                "text": "And in this mountain The LORD of hosts will make for all people A feast of choice pieces, A feast of wines on the lees, Of fat things full of marrow, Of well-refined wines on the lees.", 
                "num": 6
              }, 
              {
                "text": "And He will destroy on this mountain The surface of the covering cast over all people, And the veil that is spread over all nations.", 
                "num": 7
              }, 
              {
                "text": "He will swallow up death forever, And the Lord GOD will wipe away tears from all faces; The rebuke of His people He will take away from all the earth; For the LORD has spoken.", 
                "num": 8
              }, 
              {
                "text": "And it will be said in that day: \"Behold, this is our God; We have waited for Him, and He will save us. This is the LORD; We have waited for Him; We will be glad and rejoice in His salvation.\"", 
                "num": 9
              }, 
              {
                "text": "For on this mountain the hand of the LORD will rest, And Moab shall be trampled down under Him, As straw is trampled down for the refuse heap.", 
                "num": 10
              }, 
              {
                "text": "And He will spread out His hands in their midst As a swimmer reaches out to swim, And He will bring down their pride Together with the trickery of their hands.", 
                "num": 11
              }, 
              {
                "text": "The fortress of the high fort of your walls He will bring down, lay low, And bring to the ground, down to the dust.", 
                "num": 12
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "In that day this song will be sung in the land of Judah: \"We have a strong city; God will appoint salvation for walls and bulwarks.", 
                "num": 1
              }, 
              {
                "text": "Open the gates, That the righteous nation which keeps the truth may enter in.", 
                "num": 2
              }, 
              {
                "text": "You will keep him in perfect peace, Whose mind is stayed on You, Because he trusts in You.", 
                "num": 3
              }, 
              {
                "text": "Trust in the LORD forever, For in Yah, the LORD, is everlasting strength.", 
                "num": 4
              }, 
              {
                "text": "For He brings down those who dwell on high, The lofty city; He lays it low, He lays it low to the ground, He brings it down to the dust.", 
                "num": 5
              }, 
              {
                "text": "The foot shall tread it down-- The feet of the poor And the steps of the needy.\"", 
                "num": 6
              }, 
              {
                "text": "The way of the just is uprightness; O Most Upright, You weigh the path of the just.", 
                "num": 7
              }, 
              {
                "text": "Yes, in the way of Your judgments, O LORD, we have waited for You; The desire of our soul is for Your name And for the remembrance of You.", 
                "num": 8
              }, 
              {
                "text": "With my soul I have desired You in the night, Yes, by my spirit within me I will seek You early; For when Your judgments are in the earth, The inhabitants of the world will learn righteousness.", 
                "num": 9
              }, 
              {
                "text": "Let grace be shown to the wicked, Yet he will not learn righteousness; In the land of uprightness he will deal unjustly, And will not behold the majesty of the LORD.", 
                "num": 10
              }, 
              {
                "text": "LORD, when Your hand is lifted up, they will not see. But they will see and be ashamed For their envy of people; Yes, the fire of Your enemies shall devour them.", 
                "num": 11
              }, 
              {
                "text": "LORD, You will establish peace for us, For You have also done all our works in us.", 
                "num": 12
              }, 
              {
                "text": "O LORD our God, masters besides You Have had dominion over us; But by You only we make mention of Your name.", 
                "num": 13
              }, 
              {
                "text": "They are dead, they will not live; They are deceased, they will not rise. Therefore You have punished and destroyed them, And made all their memory to perish.", 
                "num": 14
              }, 
              {
                "text": "You have increased the nation, O LORD, You have increased the nation; You are glorified; You have expanded all the borders of the land.", 
                "num": 15
              }, 
              {
                "text": "LORD, in trouble they have visited You, They poured out a prayer when Your chastening was upon them.", 
                "num": 16
              }, 
              {
                "text": "As a woman with child Is in pain and cries out in her pangs, When she draws near the time of her delivery, So have we been in Your sight, O LORD.", 
                "num": 17
              }, 
              {
                "text": "We have been with child, we have been in pain; We have, as it were, brought forth wind; We have not accomplished any deliverance in the earth, Nor have the inhabitants of the world fallen.", 
                "num": 18
              }, 
              {
                "text": "Your dead shall live; Together with my dead body they shall arise. Awake and sing, you who dwell in dust; For your dew is like the dew of herbs, And the earth shall cast out the dead.", 
                "num": 19
              }, 
              {
                "text": "Come, my people, enter your chambers, And shut your doors behind you; Hide yourself, as it were, for a little moment, Until the indignation is past.", 
                "num": 20
              }, 
              {
                "text": "For behold, the LORD comes out of His place To punish the inhabitants of the earth for their iniquity; The earth will also disclose her blood, And will no more cover her slain.", 
                "num": 21
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "In that day the LORD with His severe sword, great and strong, Will punish Leviathan the fleeing serpent, Leviathan that twisted serpent; And He will slay the reptile that is in the sea.", 
                "num": 1
              }, 
              {
                "text": "In that day sing to her, \"A vineyard of red wine!", 
                "num": 2
              }, 
              {
                "text": "I, the LORD, keep it, I water it every moment; Lest any hurt it, I keep it night and day.", 
                "num": 3
              }, 
              {
                "text": "Fury is not in Me. Who would set briers and thorns Against Me in battle? I would go through them, I would burn them together.", 
                "num": 4
              }, 
              {
                "text": "Or let him take hold of My strength, That he may make peace with Me; And he shall make peace with Me.\"", 
                "num": 5
              }, 
              {
                "text": "Those who come He shall cause to take root in Jacob; Israel shall blossom and bud, And fill the face of the world with fruit.", 
                "num": 6
              }, 
              {
                "text": "Has He struck Israel as He struck those who struck him? Or has He been slain according to the slaughter of those who were slain by Him?", 
                "num": 7
              }, 
              {
                "text": "In measure, by sending it away, You contended with it. He removes it by His rough wind In the day of the east wind.", 
                "num": 8
              }, 
              {
                "text": "Therefore by this the iniquity of Jacob will be covered; And this is all the fruit of taking away his sin: When he makes all the stones of the altar Like chalkstones that are beaten to dust, Wooden images and incense altars shall not stand.", 
                "num": 9
              }, 
              {
                "text": "Yet the fortified city will be desolate, The habitation forsaken and left like a wilderness; There the calf will feed, and there it will lie down And consume its branches.", 
                "num": 10
              }, 
              {
                "text": "When its boughs are withered, they will be broken off; The women come and set them on fire. For it is a people of no understanding; Therefore He who made them will not have mercy on them, And He who formed them will show them no favor.", 
                "num": 11
              }, 
              {
                "text": "And it shall come to pass in that day That the LORD will thresh, From the channel of the River to the Brook of Egypt; And you will be gathered one by one, O you children of Israel.", 
                "num": 12
              }, 
              {
                "text": "So it shall be in that day: The great trumpet will be blown; They will come, who are about to perish in the land of Assyria, And they who are outcasts in the land of Egypt, And shall worship the LORD in the holy mount at Jerusalem.", 
                "num": 13
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Woe to the crown of pride, to the drunkards of Ephraim, Whose glorious beauty is a fading flower Which is at the head of the verdant valleys, To those who are overcome with wine!", 
                "num": 1
              }, 
              {
                "text": "Behold, the Lord has a mighty and strong one, Like a tempest of hail and a destroying storm, Like a flood of mighty waters overflowing, Who will bring them down to the earth with His hand.", 
                "num": 2
              }, 
              {
                "text": "The crown of pride, the drunkards of Ephraim, Will be trampled underfoot;", 
                "num": 3
              }, 
              {
                "text": "And the glorious beauty is a fading flower Which is at the head of the verdant valley, Like the first fruit before the summer, Which an observer sees; He eats it up while it is still in his hand.", 
                "num": 4
              }, 
              {
                "text": "In that day the LORD of hosts will be For a crown of glory and a diadem of beauty To the remnant of His people,", 
                "num": 5
              }, 
              {
                "text": "For a spirit of justice to him who sits in judgment, And for strength to those who turn back the battle at the gate.", 
                "num": 6
              }, 
              {
                "text": "But they also have erred through wine, And through intoxicating drink are out of the way; The priest and the prophet have erred through intoxicating drink, They are swallowed up by wine, They are out of the way through intoxicating drink; They err in vision, they stumble in judgment.", 
                "num": 7
              }, 
              {
                "text": "For all tables are full of vomit and filth; No place is clean.", 
                "num": 8
              }, 
              {
                "text": "\"Whom will he teach knowledge? And whom will he make to understand the message? Those just weaned from milk? Those just drawn from the breasts?", 
                "num": 9
              }, 
              {
                "text": "For precept must be upon precept, precept upon precept, Line upon line, line upon line, Here a little, there a little.\"", 
                "num": 10
              }, 
              {
                "text": "For with stammering lips and another tongue He will speak to this people,", 
                "num": 11
              }, 
              {
                "text": "To whom He said, \"This is the rest with which You may cause the weary to rest,\" And, \"This is the refreshing\"; Yet they would not hear.", 
                "num": 12
              }, 
              {
                "text": "But the word of the LORD was to them, \"Precept upon precept, precept upon precept, Line upon line, line upon line, Here a little, there a little,\" That they might go and fall backward, and be broken And snared and caught.", 
                "num": 13
              }, 
              {
                "text": "Therefore hear the word of the LORD, you scornful men, Who rule this people who are in Jerusalem,", 
                "num": 14
              }, 
              {
                "text": "Because you have said, \"We have made a covenant with death, And with Sheol we are in agreement. When the overflowing scourge passes through, It will not come to us, For we have made lies our refuge, And under falsehood we have hidden ourselves.\"", 
                "num": 15
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Behold, I lay in Zion a stone for a foundation, A tried stone, a precious cornerstone, a sure foundation; Whoever believes will not act hastily.", 
                "num": 16
              }, 
              {
                "text": "Also I will make justice the measuring line, And righteousness the plummet; The hail will sweep away the refuge of lies, And the waters will overflow the hiding place.", 
                "num": 17
              }, 
              {
                "text": "Your covenant with death will be annulled, And your agreement with Sheol will not stand; When the overflowing scourge passes through, Then you will be trampled down by it.", 
                "num": 18
              }, 
              {
                "text": "As often as it goes out it will take you; For morning by morning it will pass over, And by day and by night; It will be a terror just to understand the report.\"", 
                "num": 19
              }, 
              {
                "text": "For the bed is too short to stretch out on, And the covering so narrow that one cannot wrap himself in it.", 
                "num": 20
              }, 
              {
                "text": "For the LORD will rise up as at Mount Perazim, He will be angry as in the Valley of Gibeon-- That He may do His work, His awesome work, And bring to pass His act, His unusual act.", 
                "num": 21
              }, 
              {
                "text": "Now therefore, do not be mockers, Lest your bonds be made strong; For I have heard from the Lord GOD of hosts, A destruction determined even upon the whole earth.", 
                "num": 22
              }, 
              {
                "text": "Give ear and hear my voice, Listen and hear my speech.", 
                "num": 23
              }, 
              {
                "text": "Does the plowman keep plowing all day to sow? Does he keep turning his soil and breaking the clods?", 
                "num": 24
              }, 
              {
                "text": "When he has leveled its surface, Does he not sow the black cummin And scatter the cummin, Plant the wheat in rows, The barley in the appointed place, And the spelt in its place?", 
                "num": 25
              }, 
              {
                "text": "For He instructs him in right judgment, His God teaches him.", 
                "num": 26
              }, 
              {
                "text": "For the black cummin is not threshed with a threshing sledge, Nor is a cartwheel rolled over the cummin; But the black cummin is beaten out with a stick, And the cummin with a rod.", 
                "num": 27
              }, 
              {
                "text": "Bread flour must be ground; Therefore he does not thresh it forever, Break it with his cartwheel, Or crush it with his horsemen.", 
                "num": 28
              }, 
              {
                "text": "This also comes from the LORD of hosts, Who is wonderful in counsel and excellent in guidance.", 
                "num": 29
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "\"Woe to Ariel, to Ariel, the city where David dwelt! Add year to year; Let feasts come around.", 
                "num": 1
              }, 
              {
                "text": "Yet I will distress Ariel; There shall be heaviness and sorrow, And it shall be to Me as Ariel.", 
                "num": 2
              }, 
              {
                "text": "I will encamp against you all around, I will lay siege against you with a mound, And I will raise siegeworks against you.", 
                "num": 3
              }, 
              {
                "text": "You shall be brought down, You shall speak out of the ground; Your speech shall be low, out of the dust; Your voice shall be like a medium's, out of the ground; And your speech shall whisper out of the dust.", 
                "num": 4
              }, 
              {
                "text": "\"Moreover the multitude of your foes Shall be like fine dust, And the multitude of the terrible ones Like chaff that passes away; Yes, it shall be in an instant, suddenly.", 
                "num": 5
              }, 
              {
                "text": "You will be punished by the LORD of hosts With thunder and earthquake and great noise, With storm and tempest And the flame of devouring fire.", 
                "num": 6
              }, 
              {
                "text": "The multitude of all the nations who fight against Ariel, Even all who fight against her and her fortress, And distress her, Shall be as a dream of a night vision.", 
                "num": 7
              }, 
              {
                "text": "It shall even be as when a hungry man dreams, And look--he eats; But he awakes, and his soul is still empty; Or as when a thirsty man dreams, And look--he drinks; But he awakes, and indeed he is faint, And his soul still craves: So the multitude of all the nations shall be, Who fight against Mount Zion.\"", 
                "num": 8
              }, 
              {
                "text": "Pause and wonder! Blind yourselves and be blind! They are drunk, but not with wine; They stagger, but not with intoxicating drink.", 
                "num": 9
              }, 
              {
                "text": "For the LORD has poured out on you The spirit of deep sleep, And has closed your eyes, namely, the prophets; And He has covered your heads, namely, the seers.", 
                "num": 10
              }, 
              {
                "text": "The whole vision has become to you like the words of a book that is sealed, which men deliver to one who is literate, saying, \"Read this, please.\" And he says, \"I cannot, for it is sealed.\"", 
                "num": 11
              }, 
              {
                "text": "Then the book is delivered to one who is illiterate, saying, \"Read this, please.\" And he says, \"I am not literate.\"", 
                "num": 12
              }, 
              {
                "text": "Therefore the Lord said: \"Inasmuch as these people draw near with their mouths And honor Me with their lips, But have removed their hearts far from Me, And their fear toward Me is taught by the commandment of men,", 
                "num": 13
              }, 
              {
                "text": "Therefore, behold, I will again do a marvelous work Among this people, A marvelous work and a wonder; For the wisdom of their wise men shall perish, And the understanding of their prudent men shall be hidden.\"", 
                "num": 14
              }, 
              {
                "text": "Woe to those who seek deep to hide their counsel far from the LORD, And their works are in the dark; They say, \"Who sees us?\" and, \"Who knows us?\"", 
                "num": 15
              }, 
              {
                "text": "Surely you have things turned around! Shall the potter be esteemed as the clay; For shall the thing made say of him who made it, \"He did not make me\"? Or shall the thing formed say of him who formed it, \"He has no understanding\"?", 
                "num": 16
              }, 
              {
                "text": "Is it not yet a very little while Till Lebanon shall be turned into a fruitful field, And the fruitful field be esteemed as a forest?", 
                "num": 17
              }, 
              {
                "text": "In that day the deaf shall hear the words of the book, And the eyes of the blind shall see out of obscurity and out of darkness.", 
                "num": 18
              }, 
              {
                "text": "The humble also shall increase their joy in the LORD, And the poor among men shall rejoice In the Holy One of Israel.", 
                "num": 19
              }, 
              {
                "text": "For the terrible one is brought to nothing, The scornful one is consumed, And all who watch for iniquity are cut off--", 
                "num": 20
              }, 
              {
                "text": "Who make a man an offender by a word, And lay a snare for him who reproves in the gate, And turn aside the just by empty words.", 
                "num": 21
              }, 
              {
                "text": "Therefore thus says the LORD, who redeemed Abraham, concerning the house of Jacob: \"Jacob shall not now be ashamed, Nor shall his face now grow pale;", 
                "num": 22
              }, 
              {
                "text": "But when he sees his children, The work of My hands, in his midst, They will hallow My name, And hallow the Holy One of Jacob, And fear the God of Israel.", 
                "num": 23
              }, 
              {
                "text": "These also who erred in spirit will come to understanding, And those who complained will learn doctrine.\"", 
                "num": 24
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "\"Woe to the rebellious children,\" says the LORD, \"Who take counsel, but not of Me, And who devise plans, but not of My Spirit, That they may add sin to sin;", 
                "num": 1
              }, 
              {
                "text": "Who walk to go down to Egypt, And have not asked My advice, To strengthen themselves in the strength of Pharaoh, And to trust in the shadow of Egypt!", 
                "num": 2
              }, 
              {
                "text": "Therefore the strength of Pharaoh Shall be your shame, And trust in the shadow of Egypt Shall be your humiliation.", 
                "num": 3
              }, 
              {
                "text": "For his princes were at Zoan, And his ambassadors came to Hanes.", 
                "num": 4
              }, 
              {
                "text": "They were all ashamed of a people who could not benefit them, Or be help or benefit, But a shame and also a reproach.\"", 
                "num": 5
              }, 
              {
                "text": "The burden against the beasts of the South. Through a land of trouble and anguish, From which came the lioness and lion, The viper and fiery flying serpent, They will carry their riches on the backs of young donkeys, And their treasures on the humps of camels, To a people who shall not profit;", 
                "num": 6
              }, 
              {
                "text": "For the Egyptians shall help in vain and to no purpose. Therefore I have called her Rahab-Hem-Shebeth.", 
                "num": 7
              }, 
              {
                "text": "Now go, write it before them on a tablet, And note it on a scroll, That it may be for time to come, Forever and ever:", 
                "num": 8
              }, 
              {
                "text": "That this is a rebellious people, Lying children, Children who will not hear the law of the LORD;", 
                "num": 9
              }, 
              {
                "text": "Who say to the seers, \"Do not see,\" And to the prophets, \"Do not prophesy to us right things; Speak to us smooth things, prophesy deceits.", 
                "num": 10
              }, 
              {
                "text": "Get out of the way, Turn aside from the path, Cause the Holy One of Israel To cease from before us.\"", 
                "num": 11
              }, 
              {
                "text": "Therefore thus says the Holy One of Israel: \"Because you despise this word, And trust in oppression and perversity, And rely on them,", 
                "num": 12
              }, 
              {
                "text": "Therefore this iniquity shall be to you Like a breach ready to fall, A bulge in a high wall, Whose breaking comes suddenly, in an instant.", 
                "num": 13
              }, 
              {
                "text": "And He shall break it like the breaking of the potter's vessel, Which is broken in pieces; He shall not spare. So there shall not be found among its fragments A shard to take fire from the hearth, Or to take water from the cistern.\"", 
                "num": 14
              }, 
              {
                "text": "For thus says the Lord GOD, the Holy One of Israel: \"In returning and rest you shall be saved; In quietness and confidence shall be your strength.\" But you would not,", 
                "num": 15
              }, 
              {
                "text": "And you said, \"No, for we will flee on horses\"-- Therefore you shall flee! And, \"We will ride on swift horses\"-- Therefore those who pursue you shall be swift!", 
                "num": 16
              }, 
              {
                "text": "One thousand shall flee at the threat of one, At the threat of five you shall flee, Till you are left as a pole on top of a mountain And as a banner on a hill.", 
                "num": 17
              }, 
              {
                "text": "Therefore the LORD will wait, that He may be gracious to you; And therefore He will be exalted, that He may have mercy on you. For the LORD is a God of justice; Blessed are all those who wait for Him.", 
                "num": 18
              }, 
              {
                "text": "For the people shall dwell in Zion at Jerusalem; You shall weep no more. He will be very gracious to you at the sound of your cry; When He hears it, He will answer you.", 
                "num": 19
              }, 
              {
                "text": "And though the Lord gives you The bread of adversity and the water of affliction, Yet your teachers will not be moved into a corner anymore, But your eyes shall see your teachers.", 
                "num": 20
              }, 
              {
                "text": "Your ears shall hear a word behind you, saying, \"This is the way, walk in it,\" Whenever you turn to the right hand Or whenever you turn to the left.", 
                "num": 21
              }, 
              {
                "text": "You will also defile the covering of your images of silver, And the ornament of your molded images of gold. You will throw them away as an unclean thing; You will say to them, \"Get away!\"", 
                "num": 22
              }, 
              {
                "text": "Then He will give the rain for your seed With which you sow the ground, And bread of the increase of the earth; It will be fat and plentiful. In that day your cattle will feed In large pastures.", 
                "num": 23
              }, 
              {
                "text": "Likewise the oxen and the young donkeys that work the ground Will eat cured fodder, Which has been winnowed with the shovel and fan.", 
                "num": 24
              }, 
              {
                "text": "There will be on every high mountain And on every high hill Rivers and streams of waters, In the day of the great slaughter, When the towers fall.", 
                "num": 25
              }, 
              {
                "text": "Moreover the light of the moon will be as the light of the sun, And the light of the sun will be sevenfold, As the light of seven days, In the day that the LORD binds up the bruise of His people And heals the stroke of their wound.", 
                "num": 26
              }, 
              {
                "text": "Behold, the name of the LORD comes from afar, Burning with His anger, And His burden is heavy; His lips are full of indignation, And His tongue like a devouring fire.", 
                "num": 27
              }, 
              {
                "text": "His breath is like an overflowing stream, Which reaches up to the neck, To sift the nations with the sieve of futility; And there shall be a bridle in the jaws of the people, Causing them to err.", 
                "num": 28
              }, 
              {
                "text": "You shall have a song As in the night when a holy festival is kept, And gladness of heart as when one goes with a flute, To come into the mountain of the LORD, To the Mighty One of Israel.", 
                "num": 29
              }, 
              {
                "text": "The LORD will cause His glorious voice to be heard, And show the descent of His arm, With the indignation of His anger And the flame of a devouring fire, With scattering, tempest, and hailstones.", 
                "num": 30
              }, 
              {
                "text": "For through the voice of the LORD Assyria will be beaten down, As He strikes with the rod.", 
                "num": 31
              }, 
              {
                "text": "And in every place where the staff of punishment passes, Which the LORD lays on him, It will be with tambourines and harps; And in battles of brandishing He will fight with it.", 
                "num": 32
              }, 
              {
                "text": "For Tophet was established of old, Yes, for the king it is prepared. He has made it deep and large; Its pyre is fire with much wood; The breath of the LORD, like a stream of brimstone, Kindles it.", 
                "num": 33
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Woe to those who go down to Egypt for help, And rely on horses, Who trust in chariots because they are many, And in horsemen because they are very strong, But who do not look to the Holy One of Israel, Nor seek the LORD!", 
                "num": 1
              }, 
              {
                "text": "Yet He also is wise and will bring disaster, And will not call back His words, But will arise against the house of evildoers, And against the help of those who work iniquity.", 
                "num": 2
              }, 
              {
                "text": "Now the Egyptians are men, and not God; And their horses are flesh, and not spirit. When the LORD stretches out His hand, Both he who helps will fall, And he who is helped will fall down; They all will perish together.", 
                "num": 3
              }, 
              {
                "text": "For thus the LORD has spoken to me: \"As a lion roars, And a young lion over his prey (When a multitude of shepherds is summoned against him, He will not be afraid of their voice Nor be disturbed by their noise), So the LORD of hosts will come down To fight for Mount Zion and for its hill.", 
                "num": 4
              }, 
              {
                "text": "Like birds flying about, So will the LORD of hosts defend Jerusalem. Defending, He will also deliver it; Passing over, He will preserve it.\"", 
                "num": 5
              }, 
              {
                "text": "Return to Him against whom the children of Israel have deeply revolted.", 
                "num": 6
              }, 
              {
                "text": "For in that day every man shall throw away his idols of silver and his idols of gold--sin, which your own hands have made for yourselves.", 
                "num": 7
              }, 
              {
                "text": "\"Then Assyria shall fall by a sword not of man, And a sword not of mankind shall devour him. But he shall flee from the sword, And his young men shall become forced labor.", 
                "num": 8
              }, 
              {
                "text": "He shall cross over to his stronghold for fear, And his princes shall be afraid of the banner,\" Says the LORD, Whose fire is in Zion And whose furnace is in Jerusalem.", 
                "num": 9
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "Behold, a king will reign in righteousness, And princes will rule with justice.", 
                "num": 1
              }, 
              {
                "text": "A man will be as a hiding place from the wind, And a cover from the tempest, As rivers of water in a dry place, As the shadow of a great rock in a weary land.", 
                "num": 2
              }, 
              {
                "text": "The eyes of those who see will not be dim, And the ears of those who hear will listen.", 
                "num": 3
              }, 
              {
                "text": "Also the heart of the rash will understand knowledge, And the tongue of the stammerers will be ready to speak plainly.", 
                "num": 4
              }, 
              {
                "text": "The foolish person will no longer be called generous, Nor the miser said to be bountiful;", 
                "num": 5
              }, 
              {
                "text": "For the foolish person will speak foolishness, And his heart will work iniquity: To practice ungodliness, To utter error against the LORD, To keep the hungry unsatisfied, And he will cause the drink of the thirsty to fail.", 
                "num": 6
              }, 
              {
                "text": "Also the schemes of the schemer are evil; He devises wicked plans To destroy the poor with lying words, Even when the needy speaks justice.", 
                "num": 7
              }, 
              {
                "text": "But a generous man devises generous things, And by generosity he shall stand.", 
                "num": 8
              }, 
              {
                "text": "Rise up, you women who are at ease, Hear my voice; You complacent daughters, Give ear to my speech.", 
                "num": 9
              }, 
              {
                "text": "In a year and some days You will be troubled, you complacent women; For the vintage will fail, The gathering will not come.", 
                "num": 10
              }, 
              {
                "text": "Tremble, you women who are at ease; Be troubled, you complacent ones; Strip yourselves, make yourselves bare, And gird sackcloth on your waists.", 
                "num": 11
              }, 
              {
                "text": "People shall mourn upon their breasts For the pleasant fields, for the fruitful vine.", 
                "num": 12
              }, 
              {
                "text": "On the land of my people will come up thorns and briers, Yes, on all the happy homes in the joyous city;", 
                "num": 13
              }, 
              {
                "text": "Because the palaces will be forsaken, The bustling city will be deserted. The forts and towers will become lairs forever, A joy of wild donkeys, a pasture of flocks--", 
                "num": 14
              }, 
              {
                "text": "Until the Spirit is poured upon us from on high, And the wilderness becomes a fruitful field, And the fruitful field is counted as a forest.", 
                "num": 15
              }, 
              {
                "text": "Then justice will dwell in the wilderness, And righteousness remain in the fruitful field.", 
                "num": 16
              }, 
              {
                "text": "The work of righteousness will be peace, And the effect of righteousness, quietness and assurance forever.", 
                "num": 17
              }, 
              {
                "text": "My people will dwell in a peaceful habitation, In secure dwellings, and in quiet resting places,", 
                "num": 18
              }, 
              {
                "text": "Though hail comes down on the forest, And the city is brought low in humiliation.", 
                "num": 19
              }, 
              {
                "text": "Blessed are you who sow beside all waters, Who send out freely the feet of the ox and the donkey.", 
                "num": 20
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Woe to you who plunder, though you have not been plundered; And you who deal treacherously, though they have not dealt treacherously with you! When you cease plundering, You will be plundered; When you make an end of dealing treacherously, They will deal treacherously with you.", 
                "num": 1
              }, 
              {
                "text": "O LORD, be gracious to us; We have waited for You. Be their arm every morning, Our salvation also in the time of trouble.", 
                "num": 2
              }, 
              {
                "text": "At the noise of the tumult the people shall flee; When You lift Yourself up, the nations shall be scattered;", 
                "num": 3
              }, 
              {
                "text": "And Your plunder shall be gathered Like the gathering of the caterpillar; As the running to and fro of locusts, He shall run upon them.", 
                "num": 4
              }, 
              {
                "text": "The LORD is exalted, for He dwells on high; He has filled Zion with justice and righteousness.", 
                "num": 5
              }, 
              {
                "text": "Wisdom and knowledge will be the stability of your times, And the strength of salvation; The fear of the LORD is His treasure.", 
                "num": 6
              }, 
              {
                "text": "Surely their valiant ones shall cry outside, The ambassadors of peace shall weep bitterly.", 
                "num": 7
              }, 
              {
                "text": "The highways lie waste, The traveling man ceases. He has broken the covenant, He has despised the cities, He regards no man.", 
                "num": 8
              }, 
              {
                "text": "The earth mourns and languishes, Lebanon is shamed and shriveled; Sharon is like a wilderness, And Bashan and Carmel shake off their fruits.", 
                "num": 9
              }, 
              {
                "text": "\"Now I will rise,\" says the LORD; \"Now I will be exalted, Now I will lift Myself up.", 
                "num": 10
              }, 
              {
                "text": "You shall conceive chaff, You shall bring forth stubble; Your breath, as fire, shall devour you.", 
                "num": 11
              }, 
              {
                "text": "And the people shall be like the burnings of lime; Like thorns cut up they shall be burned in the fire.", 
                "num": 12
              }, 
              {
                "text": "Hear, you who are afar off, what I have done; And you who are near, acknowledge My might.\"", 
                "num": 13
              }, 
              {
                "text": "The sinners in Zion are afraid; Fearfulness has seized the hypocrites: \"Who among us shall dwell with the devouring fire? Who among us shall dwell with everlasting burnings?\"", 
                "num": 14
              }, 
              {
                "text": "He who walks righteously and speaks uprightly, He who despises the gain of oppressions, Who gestures with his hands, refusing bribes, Who stops his ears from hearing of bloodshed, And shuts his eyes from seeing evil:", 
                "num": 15
              }, 
              {
                "text": "He will dwell on high; His place of defense will be the fortress of rocks; Bread will be given him, His water will be sure.", 
                "num": 16
              }, 
              {
                "text": "Your eyes will see the King in His beauty; They will see the land that is very far off.", 
                "num": 17
              }, 
              {
                "text": "Your heart will meditate on terror: \"Where is the scribe? Where is he who weighs? Where is he who counts the towers?\"", 
                "num": 18
              }, 
              {
                "text": "You will not see a fierce people, A people of obscure speech, beyond perception, Of a stammering tongue that you cannot understand.", 
                "num": 19
              }, 
              {
                "text": "Look upon Zion, the city of our appointed feasts; Your eyes will see Jerusalem, a quiet home, A tabernacle that will not be taken down; Not one of its stakes will ever be removed, Nor will any of its cords be broken.", 
                "num": 20
              }, 
              {
                "text": "But there the majestic LORD will be for us A place of broad rivers and streams, In which no galley with oars will sail, Nor majestic ships pass by", 
                "num": 21
              }, 
              {
                "text": "(For the LORD is our Judge, The LORD is our Lawgiver, The LORD is our King; He will save us);", 
                "num": 22
              }, 
              {
                "text": "Your tackle is loosed, They could not strengthen their mast, They could not spread the sail. Then the prey of great plunder is divided; The lame take the prey.", 
                "num": 23
              }, 
              {
                "text": "And the inhabitant will not say, \"I am sick\"; The people who dwell in it will be forgiven their iniquity.", 
                "num": 24
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "Come near, you nations, to hear; And heed, you people! Let the earth hear, and all that is in it, The world and all things that come forth from it.", 
                "num": 1
              }, 
              {
                "text": "For the indignation of the LORD is against all nations, And His fury against all their armies; He has utterly destroyed them, He has given them over to the slaughter.", 
                "num": 2
              }, 
              {
                "text": "Also their slain shall be thrown out; Their stench shall rise from their corpses, And the mountains shall be melted with their blood.", 
                "num": 3
              }, 
              {
                "text": "All the host of heaven shall be dissolved, And the heavens shall be rolled up like a scroll; All their host shall fall down As the leaf falls from the vine, And as fruit falling from a fig tree.", 
                "num": 4
              }, 
              {
                "text": "\"For My sword shall be bathed in heaven; Indeed it shall come down on Edom, And on the people of My curse, for judgment.", 
                "num": 5
              }, 
              {
                "text": "The sword of the LORD is filled with blood, It is made overflowing with fatness, With the blood of lambs and goats, With the fat of the kidneys of rams. For the LORD has a sacrifice in Bozrah, And a great slaughter in the land of Edom.", 
                "num": 6
              }, 
              {
                "text": "The wild oxen shall come down with them, And the young bulls with the mighty bulls; Their land shall be soaked with blood, And their dust saturated with fatness.\"", 
                "num": 7
              }, 
              {
                "text": "For it is the day of the LORD's vengeance, The year of recompense for the cause of Zion.", 
                "num": 8
              }, 
              {
                "text": "Its streams shall be turned into pitch, And its dust into brimstone; Its land shall become burning pitch.", 
                "num": 9
              }, 
              {
                "text": "It shall not be quenched night or day; Its smoke shall ascend forever. From generation to generation it shall lie waste; No one shall pass through it forever and ever.", 
                "num": 10
              }, 
              {
                "text": "But the pelican and the porcupine shall possess it, Also the owl and the raven shall dwell in it. And He shall stretch out over it The line of confusion and the stones of emptiness.", 
                "num": 11
              }, 
              {
                "text": "They shall call its nobles to the kingdom, But none shall be there, and all its princes shall be nothing.", 
                "num": 12
              }, 
              {
                "text": "And thorns shall come up in its palaces, Nettles and brambles in its fortresses; It shall be a habitation of jackals, A courtyard for ostriches.", 
                "num": 13
              }, 
              {
                "text": "The wild beasts of the desert shall also meet with the jackals, And the wild goat shall bleat to its companion; Also the night creature shall rest there, And find for herself a place of rest.", 
                "num": 14
              }, 
              {
                "text": "There the arrow snake shall make her nest and lay eggs And hatch, and gather them under her shadow; There also shall the hawks be gathered, Every one with her mate.", 
                "num": 15
              }, 
              {
                "text": "\"Search from the book of the LORD, and read: Not one of these shall fail; Not one shall lack her mate. For My mouth has commanded it, and His Spirit has gathered them.", 
                "num": 16
              }, 
              {
                "text": "He has cast the lot for them, And His hand has divided it among them with a measuring line. They shall possess it forever; From generation to generation they shall dwell in it.\"", 
                "num": 17
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "The wilderness and the wasteland shall be glad for them, And the desert shall rejoice and blossom as the rose;", 
                "num": 1
              }, 
              {
                "text": "It shall blossom abundantly and rejoice, Even with joy and singing. The glory of Lebanon shall be given to it, The excellence of Carmel and Sharon. They shall see the glory of the LORD, The excellency of our God.", 
                "num": 2
              }, 
              {
                "text": "Strengthen the weak hands, And make firm the feeble knees.", 
                "num": 3
              }, 
              {
                "text": "Say to those who are fearful-hearted, \"Be strong, do not fear! Behold, your God will come with vengeance, With the recompense of God; He will come and save you.\"", 
                "num": 4
              }, 
              {
                "text": "Then the eyes of the blind shall be opened, And the ears of the deaf shall be unstopped.", 
                "num": 5
              }, 
              {
                "text": "Then the lame shall leap like a deer, And the tongue of the dumb sing. For waters shall burst forth in the wilderness, And streams in the desert.", 
                "num": 6
              }, 
              {
                "text": "The parched ground shall become a pool, And the thirsty land springs of water; In the habitation of jackals, where each lay, There shall be grass with reeds and rushes.", 
                "num": 7
              }, 
              {
                "text": "A highway shall be there, and a road, And it shall be called the Highway of Holiness. The unclean shall not pass over it, But it shall be for others. Whoever walks the road, although a fool, Shall not go astray.", 
                "num": 8
              }, 
              {
                "text": "No lion shall be there, Nor shall any ravenous beast go up on it; It shall not be found there. But the redeemed shall walk there,", 
                "num": 9
              }, 
              {
                "text": "And the ransomed of the LORD shall return, And come to Zion with singing, With everlasting joy on their heads. They shall obtain joy and gladness, And sorrow and sighing shall flee away.", 
                "num": 10
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the fourteenth year of King Hezekiah that Sennacherib king of Assyria came up against all the fortified cities of Judah and took them.", 
                "num": 1
              }, 
              {
                "text": "Then the king of Assyria sent the Rabshakeh with a great army from Lachish to King Hezekiah at Jerusalem. And he stood by the aqueduct from the upper pool, on the highway to the Fuller's Field.", 
                "num": 2
              }, 
              {
                "text": "And Eliakim the son of Hilkiah, who was over the household, Shebna the scribe, and Joah the son of Asaph, the recorder, came out to him.", 
                "num": 3
              }, 
              {
                "text": "Then the Rabshakeh said to them, \"Say now to Hezekiah, \"Thus says the great king, the king of Assyria: \"What confidence is this in which you trust?", 
                "num": 4
              }, 
              {
                "text": "I say you speak of having plans and power for war; but they are mere words. Now in whom do you trust, that you rebel against me?", 
                "num": 5
              }, 
              {
                "text": "Look! You are trusting in the staff of this broken reed, Egypt, on which if a man leans, it will go into his hand and pierce it. So is Pharaoh king of Egypt to all who trust in him.", 
                "num": 6
              }, 
              {
                "text": "\"But if you say to me, \"We trust in the LORD our God,' is it not He whose high places and whose altars Hezekiah has taken away, and said to Judah and Jerusalem, \"You shall worship before this altar'?\"'", 
                "num": 7
              }, 
              {
                "text": "Now therefore, I urge you, give a pledge to my master the king of Assyria, and I will give you two thousand horses--if you are able on your part to put riders on them!", 
                "num": 8
              }, 
              {
                "text": "How then will you repel one captain of the least of my master's servants, and put your trust in Egypt for chariots and horsemen?", 
                "num": 9
              }, 
              {
                "text": "Have I now come up without the LORD against this land to destroy it? The LORD said to me, \"Go up against this land, and destroy it.\"'", 
                "num": 10
              }, 
              {
                "text": "Then Eliakim, Shebna, and Joah said to the Rabshakeh, \"Please speak to your servants in Aramaic, for we understand it; and do not speak to us in Hebrew in the hearing of the people who are on the wall.\"", 
                "num": 11
              }, 
              {
                "text": "But the Rabshakeh said, \"Has my master sent me to your master and to you to speak these words, and not to the men who sit on the wall, who will eat and drink their own waste with you?\"", 
                "num": 12
              }, 
              {
                "text": "Then the Rabshakeh stood and called out with a loud voice in Hebrew, and said, \"Hear the words of the great king, the king of Assyria!", 
                "num": 13
              }, 
              {
                "text": "Thus says the king: \"Do not let Hezekiah deceive you, for he will not be able to deliver you;", 
                "num": 14
              }, 
              {
                "text": "nor let Hezekiah make you trust in the LORD, saying, \"The LORD will surely deliver us; this city will not be given into the hand of the king of Assyria.\"'", 
                "num": 15
              }, 
              {
                "text": "Do not listen to Hezekiah; for thus says the king of Assyria: \"Make peace with me by a present and come out to me; and every one of you eat from his own vine and every one from his own fig tree, and every one of you drink the waters of his own cistern;", 
                "num": 16
              }, 
              {
                "text": "until I come and take you away to a land like your own land, a land of grain and new wine, a land of bread and vineyards.", 
                "num": 17
              }, 
              {
                "text": "Beware lest Hezekiah persuade you, saying, \"The LORD will deliver us.\" Has any one of the gods of the nations delivered its land from the hand of the king of Assyria?", 
                "num": 18
              }, 
              {
                "text": "Where are the gods of Hamath and Arpad? Where are the gods of Sepharvaim? Indeed, have they delivered Samaria from my hand?", 
                "num": 19
              }, 
              {
                "text": "Who among all the gods of these lands have delivered their countries from my hand, that the LORD should deliver Jerusalem from my hand?\"'", 
                "num": 20
              }, 
              {
                "text": "But they held their peace and answered him not a word; for the king's commandment was, \"Do not answer him.\"", 
                "num": 21
              }, 
              {
                "text": "Then Eliakim the son of Hilkiah, who was over the household, Shebna the scribe, and Joah the son of Asaph, the recorder, came to Hezekiah with their clothes torn, and told him the words of the Rabshakeh.", 
                "num": 22
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "And so it was, when King Hezekiah heard it, that he tore his clothes, covered himself with sackcloth, and went into the house of the LORD.", 
                "num": 1
              }, 
              {
                "text": "Then he sent Eliakim, who was over the household, Shebna the scribe, and the elders of the priests, covered with sackcloth, to Isaiah the prophet, the son of Amoz.", 
                "num": 2
              }, 
              {
                "text": "And they said to him, \"Thus says Hezekiah: \"This day is a day of trouble and rebuke and blasphemy; for the children have come to birth, but there is no strength to bring them forth.", 
                "num": 3
              }, 
              {
                "text": "It may be that the LORD your God will hear the words of the Rabshakeh, whom his master the king of Assyria has sent to reproach the living God, and will rebuke the words which the LORD your God has heard. Therefore lift up your prayer for the remnant that is left.\"'", 
                "num": 4
              }, 
              {
                "text": "So the servants of King Hezekiah came to Isaiah.", 
                "num": 5
              }, 
              {
                "text": "And Isaiah said to them, \"Thus you shall say to your master, \"Thus says the LORD: \"Do not be afraid of the words which you have heard, with which the servants of the king of Assyria have blasphemed Me.", 
                "num": 6
              }, 
              {
                "text": "Surely I will send a spirit upon him, and he shall hear a rumor and return to his own land; and I will cause him to fall by the sword in his own land.\"\"'", 
                "num": 7
              }, 
              {
                "text": "Then the Rabshakeh returned, and found the king of Assyria warring against Libnah, for he heard that he had departed from Lachish.", 
                "num": 8
              }, 
              {
                "text": "And the king heard concerning Tirhakah king of Ethiopia, \"He has come out to make war with you.\" So when he heard it, he sent messengers to Hezekiah, saying,", 
                "num": 9
              }, 
              {
                "text": "\"Thus you shall speak to Hezekiah king of Judah, saying: \"Do not let your God in whom you trust deceive you, saying, \"Jerusalem shall not be given into the hand of the king of Assyria.\"", 
                "num": 10
              }, 
              {
                "text": "Look! You have heard what the kings of Assyria have done to all lands by utterly destroying them; and shall you be delivered?", 
                "num": 11
              }, 
              {
                "text": "Have the gods of the nations delivered those whom my fathers have destroyed, Gozan and Haran and Rezeph, and the people of Eden who were in Telassar?", 
                "num": 12
              }, 
              {
                "text": "Where is the king of Hamath, the king of Arpad, and the king of the city of Sepharvaim, Hena, and Ivah?\"'", 
                "num": 13
              }, 
              {
                "text": "And Hezekiah received the letter from the hand of the messengers, and read it; and Hezekiah went up to the house of the LORD, and spread it before the LORD.", 
                "num": 14
              }, 
              {
                "text": "Then Hezekiah prayed to the LORD, saying:", 
                "num": 15
              }, 
              {
                "text": "\"O LORD of hosts, God of Israel, the One who dwells between the cherubim, You are God, You alone, of all the kingdoms of the earth. You have made heaven and earth.", 
                "num": 16
              }, 
              {
                "text": "Incline Your ear, O LORD, and hear; open Your eyes, O LORD, and see; and hear all the words of Sennacherib, which he has sent to reproach the living God.", 
                "num": 17
              }, 
              {
                "text": "Truly, LORD, the kings of Assyria have laid waste all the nations and their lands,", 
                "num": 18
              }, 
              {
                "text": "and have cast their gods into the fire; for they were not gods, but the work of men's hands--wood and stone. Therefore they destroyed them.", 
                "num": 19
              }, 
              {
                "text": "Now therefore, O LORD our God, save us from his hand, that all the kingdoms of the earth may know that You are the LORD, You alone.\"", 
                "num": 20
              }, 
              {
                "text": "Then Isaiah the son of Amoz sent to Hezekiah, saying, \"Thus says the LORD God of Israel, \"Because you have prayed to Me against Sennacherib king of Assyria,", 
                "num": 21
              }, 
              {
                "text": "this is the word which the LORD has spoken concerning him: \"The virgin, the daughter of Zion, Has despised you, laughed you to scorn; The daughter of Jerusalem Has shaken her head behind your back!", 
                "num": 22
              }, 
              {
                "text": "\"Whom have you reproached and blasphemed? Against whom have you raised your voice, And lifted up your eyes on high? Against the Holy One of Israel.", 
                "num": 23
              }, 
              {
                "text": "By your servants you have reproached the Lord, And said, \"By the multitude of my chariots I have come up to the height of the mountains, To the limits of Lebanon; I will cut down its tall cedars And its choice cypress trees; I will enter its farthest height, To its fruitful forest.", 
                "num": 24
              }, 
              {
                "text": "I have dug and drunk water, And with the soles of my feet I have dried up All the brooks of defense.'", 
                "num": 25
              }, 
              {
                "text": "\"Did you not hear long ago How I made it, From ancient times that I formed it? Now I have brought it to pass, That you should be For crushing fortified cities into heaps of ruins.", 
                "num": 26
              }, 
              {
                "text": "Therefore their inhabitants had little power; They were dismayed and confounded; They were as the grass of the field And the green herb, As the grass on the housetops And grain blighted before it is grown.", 
                "num": 27
              }, 
              {
                "text": "\"But I know your dwelling place, Your going out and your coming in, And your rage against Me.", 
                "num": 28
              }, 
              {
                "text": "Because your rage against Me and your tumult Have come up to My ears, Therefore I will put My hook in your nose And My bridle in your lips, And I will turn you back By the way which you came.\"'", 
                "num": 29
              }, 
              {
                "text": "\"This shall be a sign to you: You shall eat this year such as grows of itself, And the second year what springs from the same; Also in the third year sow and reap, Plant vineyards and eat the fruit of them.", 
                "num": 30
              }, 
              {
                "text": "And the remnant who have escaped of the house of Judah Shall again take root downward, And bear fruit upward.", 
                "num": 31
              }, 
              {
                "text": "For out of Jerusalem shall go a remnant, And those who escape from Mount Zion. The zeal of the LORD of hosts will do this.", 
                "num": 32
              }, 
              {
                "text": "\"Therefore thus says the LORD concerning the king of Assyria: \"He shall not come into this city, Nor shoot an arrow there, Nor come before it with shield, Nor build a siege mound against it.", 
                "num": 33
              }, 
              {
                "text": "By the way that he came, By the same shall he return; And he shall not come into this city,' Says the LORD.", 
                "num": 34
              }, 
              {
                "text": "\"For I will defend this city, to save it For My own sake and for My servant David's sake.\"'", 
                "num": 35
              }, 
              {
                "text": "Then the angel of the LORD went out, and killed in the camp of the Assyrians one hundred and eighty-five thousand; and when people arose early in the morning, there were the corpses--all dead.", 
                "num": 36
              }, 
              {
                "text": "So Sennacherib king of Assyria departed and went away, returned home, and remained at Nineveh.", 
                "num": 37
              }, 
              {
                "text": "Now it came to pass, as he was worshiping in the house of Nisroch his god, that his sons Adrammelech and Sharezer struck him down with the sword; and they escaped into the land of Ararat. Then Esarhaddon his son reigned in his place.", 
                "num": 38
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "In those days Hezekiah was sick and near death. And Isaiah the prophet, the son of Amoz, went to him and said to him, \"Thus says the LORD: \"Set your house in order, for you shall die and not live.\"'", 
                "num": 1
              }, 
              {
                "text": "Then Hezekiah turned his face toward the wall, and prayed to the LORD,", 
                "num": 2
              }, 
              {
                "text": "and said, \"Remember now, O LORD, I pray, how I have walked before You in truth and with a loyal heart, and have done what is good in Your sight.\" And Hezekiah wept bitterly.", 
                "num": 3
              }, 
              {
                "text": "And the word of the LORD came to Isaiah, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Go and tell Hezekiah, \"Thus says the LORD, the God of David your father: \"I have heard your prayer, I have seen your tears; surely I will add to your days fifteen years.", 
                "num": 5
              }, 
              {
                "text": "I will deliver you and this city from the hand of the king of Assyria, and I will defend this city.\"'", 
                "num": 6
              }, 
              {
                "text": "And this is the sign to you from the LORD, that the LORD will do this thing which He has spoken:", 
                "num": 7
              }, 
              {
                "text": "Behold, I will bring the shadow on the sundial, which has gone down with the sun on the sundial of Ahaz, ten degrees backward.\" So the sun returned ten degrees on the dial by which it had gone down.", 
                "num": 8
              }, 
              {
                "text": "This is the writing of Hezekiah king of Judah, when he had been sick and had recovered from his sickness:", 
                "num": 9
              }, 
              {
                "text": "I said, \"In the prime of my life I shall go to the gates of Sheol; I am deprived of the remainder of my years.\"", 
                "num": 10
              }, 
              {
                "text": "I said, \"I shall not see Yah, The LORD in the land of the living; I shall observe man no more among the inhabitants of the world.", 
                "num": 11
              }, 
              {
                "text": "My life span is gone, Taken from me like a shepherd's tent; I have cut off my life like a weaver. He cuts me off from the loom; From day until night You make an end of me.", 
                "num": 12
              }, 
              {
                "text": "I have considered until morning-- Like a lion, So He breaks all my bones; From day until night You make an end of me.", 
                "num": 13
              }, 
              {
                "text": "Like a crane or a swallow, so I chattered; I mourned like a dove; My eyes fail from looking upward. O LORD, I am oppressed; Undertake for me!", 
                "num": 14
              }, 
              {
                "text": "\"What shall I say? He has both spoken to me, And He Himself has done it. I shall walk carefully all my years In the bitterness of my soul.", 
                "num": 15
              }, 
              {
                "text": "O Lord, by these things men live; And in all these things is the life of my spirit; So You will restore me and make me live.", 
                "num": 16
              }, 
              {
                "text": "Indeed it was for my own peace That I had great bitterness; But You have lovingly delivered my soul from the pit of corruption, For You have cast all my sins behind Your back.", 
                "num": 17
              }, 
              {
                "text": "For Sheol cannot thank You, Death cannot praise You; Those who go down to the pit cannot hope for Your truth.", 
                "num": 18
              }, 
              {
                "text": "The living, the living man, he shall praise You, As I do this day; The father shall make known Your truth to the children.", 
                "num": 19
              }, 
              {
                "text": "\"The LORD was ready to save me; Therefore we will sing my songs with stringed instruments All the days of our life, in the house of the LORD.\"", 
                "num": 20
              }, 
              {
                "text": "Now Isaiah had said, \"Let them take a lump of figs, and apply it as a poultice on the boil, and he shall recover.\"", 
                "num": 21
              }, 
              {
                "text": "And Hezekiah had said, \"What is the sign that I shall go up to the house of the LORD?\"", 
                "num": 22
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "At that time Merodach-Baladan the son of Baladan, king of Babylon, sent letters and a present to Hezekiah, for he heard that he had been sick and had recovered.", 
                "num": 1
              }, 
              {
                "text": "And Hezekiah was pleased with them, and showed them the house of his treasures--the silver and gold, the spices and precious ointment, and all his armory--all that was found among his treasures. There was nothing in his house or in all his dominion that Hezekiah did not show them.", 
                "num": 2
              }, 
              {
                "text": "Then Isaiah the prophet went to King Hezekiah, and said to him, \"What did these men say, and from where did they come to you?\" So Hezekiah said, \"They came to me from a far country, from Babylon.\"", 
                "num": 3
              }, 
              {
                "text": "And he said, \"What have they seen in your house?\" So Hezekiah answered, \"They have seen all that is in my house; there is nothing among my treasures that I have not shown them.\"", 
                "num": 4
              }, 
              {
                "text": "Then Isaiah said to Hezekiah, \"Hear the word of the LORD of hosts:", 
                "num": 5
              }, 
              {
                "text": "\"Behold, the days are coming when all that is in your house, and what your fathers have accumulated until this day, shall be carried to Babylon; nothing shall be left,' says the LORD.", 
                "num": 6
              }, 
              {
                "text": "\"And they shall take away some of your sons who will descend from you, whom you will beget; and they shall be eunuchs in the palace of the king of Babylon.\"'", 
                "num": 7
              }, 
              {
                "text": "So Hezekiah said to Isaiah, \"The word of the LORD which you have spoken is good!\" For he said, \"At least there will be peace and truth in my days.\"", 
                "num": 8
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "\"Comfort, yes, comfort My people!\" Says your God.", 
                "num": 1
              }, 
              {
                "text": "\"Speak comfort to Jerusalem, and cry out to her, That her warfare is ended, That her iniquity is pardoned; For she has received from the LORD's hand Double for all her sins.\"", 
                "num": 2
              }, 
              {
                "text": "The voice of one crying in the wilderness: \"Prepare the way of the LORD; Make straight in the desert A highway for our God.", 
                "num": 3
              }, 
              {
                "text": "Every valley shall be exalted And every mountain and hill brought low; The crooked places shall be made straight And the rough places smooth;", 
                "num": 4
              }, 
              {
                "text": "The glory of the LORD shall be revealed, And all flesh shall see it together; For the mouth of the LORD has spoken.\"", 
                "num": 5
              }, 
              {
                "text": "The voice said, \"Cry out!\" And he said, \"What shall I cry?\" \"All flesh is grass, And all its loveliness is like the flower of the field.", 
                "num": 6
              }, 
              {
                "text": "The grass withers, the flower fades, Because the breath of the LORD blows upon it; Surely the people are grass.", 
                "num": 7
              }, 
              {
                "text": "The grass withers, the flower fades, But the word of our God stands forever.\"", 
                "num": 8
              }, 
              {
                "text": "O Zion, You who bring good tidings, Get up into the high mountain; O Jerusalem, You who bring good tidings, Lift up your voice with strength, Lift it up, be not afraid; Say to the cities of Judah, \"Behold your God!\"", 
                "num": 9
              }, 
              {
                "text": "Behold, the Lord GOD shall come with a strong hand, And His arm shall rule for Him; Behold, His reward is with Him, And His work before Him.", 
                "num": 10
              }, 
              {
                "text": "He will feed His flock like a shepherd; He will gather the lambs with His arm, And carry them in His bosom, And gently lead those who are with young.", 
                "num": 11
              }, 
              {
                "text": "Who has measured the waters in the hollow of His hand, Measured heaven with a span And calculated the dust of the earth in a measure? Weighed the mountains in scales And the hills in a balance?", 
                "num": 12
              }, 
              {
                "text": "Who has directed the Spirit of the LORD, Or as His counselor has taught Him?", 
                "num": 13
              }, 
              {
                "text": "With whom did He take counsel, and who instructed Him, And taught Him in the path of justice? Who taught Him knowledge, And showed Him the way of understanding?", 
                "num": 14
              }, 
              {
                "text": "Behold, the nations are as a drop in a bucket, And are counted as the small dust on the scales; Look, He lifts up the isles as a very little thing.", 
                "num": 15
              }, 
              {
                "text": "And Lebanon is not sufficient to burn, Nor its beasts sufficient for a burnt offering.", 
                "num": 16
              }, 
              {
                "text": "All nations before Him are as nothing, And they are counted by Him less than nothing and worthless.", 
                "num": 17
              }, 
              {
                "text": "To whom then will you liken God? Or what likeness will you compare to Him?", 
                "num": 18
              }, 
              {
                "text": "The workman molds an image, The goldsmith overspreads it with gold, And the silversmith casts silver chains.", 
                "num": 19
              }, 
              {
                "text": "Whoever is too impoverished for such a contribution Chooses a tree that will not rot; He seeks for himself a skillful workman To prepare a carved image that will not totter.", 
                "num": 20
              }, 
              {
                "text": "Have you not known? Have you not heard? Has it not been told you from the beginning? Have you not understood from the foundations of the earth?", 
                "num": 21
              }, 
              {
                "text": "It is He who sits above the circle of the earth, And its inhabitants are like grasshoppers, Who stretches out the heavens like a curtain, And spreads them out like a tent to dwell in.", 
                "num": 22
              }, 
              {
                "text": "He brings the princes to nothing; He makes the judges of the earth useless.", 
                "num": 23
              }, 
              {
                "text": "Scarcely shall they be planted, Scarcely shall they be sown, Scarcely shall their stock take root in the earth, When He will also blow on them, And they will wither, And the whirlwind will take them away like stubble.", 
                "num": 24
              }, 
              {
                "text": "\"To whom then will you liken Me, Or to whom shall I be equal?\" says the Holy One.", 
                "num": 25
              }, 
              {
                "text": "Lift up your eyes on high, And see who has created these things, Who brings out their host by number; He calls them all by name, By the greatness of His might And the strength of His power; Not one is missing.", 
                "num": 26
              }, 
              {
                "text": "Why do you say, O Jacob, And speak, O Israel: \"My way is hidden from the LORD, And my just claim is passed over by my God\"?", 
                "num": 27
              }, 
              {
                "text": "Have you not known? Have you not heard? The everlasting God, the LORD, The Creator of the ends of the earth, Neither faints nor is weary. His understanding is unsearchable.", 
                "num": 28
              }, 
              {
                "text": "He gives power to the weak, And to those who have no might He increases strength.", 
                "num": 29
              }, 
              {
                "text": "Even the youths shall faint and be weary, And the young men shall utterly fall,", 
                "num": 30
              }, 
              {
                "text": "But those who wait on the LORD Shall renew their strength; They shall mount up with wings like eagles, They shall run and not be weary, They shall walk and not faint.", 
                "num": 31
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "\"Keep silence before Me, O coastlands, And let the people renew their strength! Let them come near, then let them speak; Let us come near together for judgment.", 
                "num": 1
              }, 
              {
                "text": "\"Who raised up one from the east? Who in righteousness called him to His feet? Who gave the nations before him, And made him rule over kings? Who gave them as the dust to his sword, As driven stubble to his bow?", 
                "num": 2
              }, 
              {
                "text": "Who pursued them, and passed safely By the way that he had not gone with his feet?", 
                "num": 3
              }, 
              {
                "text": "Who has performed and done it, Calling the generations from the beginning? \"I, the LORD, am the first; And with the last I am He.\"'", 
                "num": 4
              }, 
              {
                "text": "The coastlands saw it and feared, The ends of the earth were afraid; They drew near and came.", 
                "num": 5
              }, 
              {
                "text": "Everyone helped his neighbor, And said to his brother, \"Be of good courage!\"", 
                "num": 6
              }, 
              {
                "text": "So the craftsman encouraged the goldsmith; He who smooths with the hammer inspired him who strikes the anvil, Saying, \"It is ready for the soldering\"; Then he fastened it with pegs, That it might not totter.", 
                "num": 7
              }, 
              {
                "text": "\"But you, Israel, are My servant, Jacob whom I have chosen, The descendants of Abraham My friend.", 
                "num": 8
              }, 
              {
                "text": "You whom I have taken from the ends of the earth, And called from its farthest regions, And said to you, \"You are My servant, I have chosen you and have not cast you away:", 
                "num": 9
              }, 
              {
                "text": "Fear not, for I am with you; Be not dismayed, for I am your God. I will strengthen you, Yes, I will help you, I will uphold you with My righteous right hand.'", 
                "num": 10
              }, 
              {
                "text": "\"Behold, all those who were incensed against you Shall be ashamed and disgraced; They shall be as nothing, And those who strive with you shall perish.", 
                "num": 11
              }, 
              {
                "text": "You shall seek them and not find them-- Those who contended with you. Those who war against you Shall be as nothing, As a nonexistent thing.", 
                "num": 12
              }, 
              {
                "text": "For I, the LORD your God, will hold your right hand, Saying to you, \"Fear not, I will help you.'", 
                "num": 13
              }, 
              {
                "text": "\"Fear not, you worm Jacob, You men of Israel! I will help you,\" says the LORD And your Redeemer, the Holy One of Israel.", 
                "num": 14
              }, 
              {
                "text": "\"Behold, I will make you into a new threshing sledge with sharp teeth; You shall thresh the mountains and beat them small, And make the hills like chaff.", 
                "num": 15
              }, 
              {
                "text": "You shall winnow them, the wind shall carry them away, And the whirlwind shall scatter them; You shall rejoice in the LORD, And glory in the Holy One of Israel.", 
                "num": 16
              }, 
              {
                "text": "\"The poor and needy seek water, but there is none, Their tongues fail for thirst. I, the LORD, will hear them; I, the God of Israel, will not forsake them.", 
                "num": 17
              }, 
              {
                "text": "I will open rivers in desolate heights, And fountains in the midst of the valleys; I will make the wilderness a pool of water, And the dry land springs of water.", 
                "num": 18
              }, 
              {
                "text": "I will plant in the wilderness the cedar and the acacia tree, The myrtle and the oil tree; I will set in the desert the cypress tree and the pine And the box tree together,", 
                "num": 19
              }, 
              {
                "text": "That they may see and know, And consider and understand together, That the hand of the LORD has done this, And the Holy One of Israel has created it.", 
                "num": 20
              }, 
              {
                "text": "\"Present your case,\" says the LORD. \"Bring forth your strong reasons,\" says the King of Jacob.", 
                "num": 21
              }, 
              {
                "text": "\"Let them bring forth and show us what will happen; Let them show the former things, what they were, That we may consider them, And know the latter end of them; Or declare to us things to come.", 
                "num": 22
              }, 
              {
                "text": "Show the things that are to come hereafter, That we may know that you are gods; Yes, do good or do evil, That we may be dismayed and see it together.", 
                "num": 23
              }, 
              {
                "text": "Indeed you are nothing, And your work is nothing; He who chooses you is an abomination.", 
                "num": 24
              }, 
              {
                "text": "\"I have raised up one from the north, And he shall come; From the rising of the sun he shall call on My name; And he shall come against princes as though mortar, As the potter treads clay.", 
                "num": 25
              }, 
              {
                "text": "Who has declared from the beginning, that we may know? And former times, that we may say, \"He is righteous'? Surely there is no one who shows, Surely there is no one who declares, Surely there is no one who hears your words.", 
                "num": 26
              }, 
              {
                "text": "The first time I said to Zion, \"Look, there they are!' And I will give to Jerusalem one who brings good tidings.", 
                "num": 27
              }, 
              {
                "text": "For I looked, and there was no man; I looked among them, but there was no counselor, Who, when I asked of them, could answer a word.", 
                "num": 28
              }, 
              {
                "text": "Indeed they are all worthless; Their works are nothing; Their molded images are wind and confusion.", 
                "num": 29
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "\"Behold! My Servant whom I uphold, My Elect One in whom My soul delights! I have put My Spirit upon Him; He will bring forth justice to the Gentiles.", 
                "num": 1
              }, 
              {
                "text": "He will not cry out, nor raise His voice, Nor cause His voice to be heard in the street.", 
                "num": 2
              }, 
              {
                "text": "A bruised reed He will not break, And smoking flax He will not quench; He will bring forth justice for truth.", 
                "num": 3
              }, 
              {
                "text": "He will not fail nor be discouraged, Till He has established justice in the earth; And the coastlands shall wait for His law.\"", 
                "num": 4
              }, 
              {
                "text": "Thus says God the LORD, Who created the heavens and stretched them out, Who spread forth the earth and that which comes from it, Who gives breath to the people on it, And spirit to those who walk on it:", 
                "num": 5
              }, 
              {
                "text": "\"I, the LORD, have called You in righteousness, And will hold Your hand; I will keep You and give You as a covenant to the people, As a light to the Gentiles,", 
                "num": 6
              }, 
              {
                "text": "To open blind eyes, To bring out prisoners from the prison, Those who sit in darkness from the prison house.", 
                "num": 7
              }, 
              {
                "text": "I am the LORD, that is My name; And My glory I will not give to another, Nor My praise to carved images.", 
                "num": 8
              }, 
              {
                "text": "Behold, the former things have come to pass, And new things I declare; Before they spring forth I tell you of them.\"", 
                "num": 9
              }, 
              {
                "text": "Sing to the LORD a new song, And His praise from the ends of the earth, You who go down to the sea, and all that is in it, You coastlands and you inhabitants of them!", 
                "num": 10
              }, 
              {
                "text": "Let the wilderness and its cities lift up their voice, The villages that Kedar inhabits. Let the inhabitants of Sela sing, Let them shout from the top of the mountains.", 
                "num": 11
              }, 
              {
                "text": "Let them give glory to the LORD, And declare His praise in the coastlands.", 
                "num": 12
              }, 
              {
                "text": "The LORD shall go forth like a mighty man; He shall stir up His zeal like a man of war. He shall cry out, yes, shout aloud; He shall prevail against His enemies.", 
                "num": 13
              }, 
              {
                "text": "\"I have held My peace a long time, I have been still and restrained Myself. Now I will cry like a woman in labor, I will pant and gasp at once.", 
                "num": 14
              }, 
              {
                "text": "I will lay waste the mountains and hills, And dry up all their vegetation; I will make the rivers coastlands, And I will dry up the pools.", 
                "num": 15
              }, 
              {
                "text": "I will bring the blind by a way they did not know; I will lead them in paths they have not known. I will make darkness light before them, And crooked places straight. These things I will do for them, And not forsake them.", 
                "num": 16
              }, 
              {
                "text": "They shall be turned back, They shall be greatly ashamed, Who trust in carved images, Who say to the molded images, \"You are our gods.'", 
                "num": 17
              }, 
              {
                "text": "\"Hear, you deaf; And look, you blind, that you may see.", 
                "num": 18
              }, 
              {
                "text": "Who is blind but My servant, Or deaf as My messenger whom I send? Who is blind as he who is perfect, And blind as the LORD's servant?", 
                "num": 19
              }, 
              {
                "text": "Seeing many things, but you do not observe; Opening the ears, but he does not hear.\"", 
                "num": 20
              }, 
              {
                "text": "The LORD is well pleased for His righteousness' sake; He will exalt the law and make it honorable.", 
                "num": 21
              }, 
              {
                "text": "But this is a people robbed and plundered; All of them are snared in holes, And they are hidden in prison houses; They are for prey, and no one delivers; For plunder, and no one says, \"Restore!\"", 
                "num": 22
              }, 
              {
                "text": "Who among you will give ear to this? Who will listen and hear for the time to come?", 
                "num": 23
              }, 
              {
                "text": "Who gave Jacob for plunder, and Israel to the robbers? Was it not the LORD, He against whom we have sinned? For they would not walk in His ways, Nor were they obedient to His law.", 
                "num": 24
              }, 
              {
                "text": "Therefore He has poured on him the fury of His anger And the strength of battle; It has set him on fire all around, Yet he did not know; And it burned him, Yet he did not take it to heart.", 
                "num": 25
              }
            ], 
            "num": 42
          }, 
          {
            "verses": [
              {
                "text": "But now, thus says the LORD, who created you, O Jacob, And He who formed you, O Israel: \"Fear not, for I have redeemed you; I have called you by your name; You are Mine.", 
                "num": 1
              }, 
              {
                "text": "When you pass through the waters, I will be with you; And through the rivers, they shall not overflow you. When you walk through the fire, you shall not be burned, Nor shall the flame scorch you.", 
                "num": 2
              }, 
              {
                "text": "For I am the LORD your God, The Holy One of Israel, your Savior; I gave Egypt for your ransom, Ethiopia and Seba in your place.", 
                "num": 3
              }, 
              {
                "text": "Since you were precious in My sight, You have been honored, And I have loved you; Therefore I will give men for you, And people for your life.", 
                "num": 4
              }, 
              {
                "text": "Fear not, for I am with you; I will bring your descendants from the east, And gather you from the west;", 
                "num": 5
              }, 
              {
                "text": "I will say to the north, \"Give them up!' And to the south, \"Do not keep them back!' Bring My sons from afar, And My daughters from the ends of the earth--", 
                "num": 6
              }, 
              {
                "text": "Everyone who is called by My name, Whom I have created for My glory; I have formed him, yes, I have made him.\"", 
                "num": 7
              }, 
              {
                "text": "Bring out the blind people who have eyes, And the deaf who have ears.", 
                "num": 8
              }, 
              {
                "text": "Let all the nations be gathered together, And let the people be assembled. Who among them can declare this, And show us former things? Let them bring out their witnesses, that they may be justified; Or let them hear and say, \"It is truth.\"", 
                "num": 9
              }, 
              {
                "text": "\"You are My witnesses,\" says the LORD, \"And My servant whom I have chosen, That you may know and believe Me, And understand that I am He. Before Me there was no God formed, Nor shall there be after Me.", 
                "num": 10
              }, 
              {
                "text": "I, even I, am the LORD, And besides Me there is no savior.", 
                "num": 11
              }, 
              {
                "text": "I have declared and saved, I have proclaimed, And there was no foreign god among you; Therefore you are My witnesses,\" Says the LORD, \"that I am God.", 
                "num": 12
              }, 
              {
                "text": "Indeed before the day was, I am He; And there is no one who can deliver out of My hand; I work, and who will reverse it?\"", 
                "num": 13
              }, 
              {
                "text": "Thus says the LORD, your Redeemer, The Holy One of Israel: \"For your sake I will send to Babylon, And bring them all down as fugitives-- The Chaldeans, who rejoice in their ships.", 
                "num": 14
              }, 
              {
                "text": "I am the LORD, your Holy One, The Creator of Israel, your King.\"", 
                "num": 15
              }, 
              {
                "text": "Thus says the LORD, who makes a way in the sea And a path through the mighty waters,", 
                "num": 16
              }, 
              {
                "text": "Who brings forth the chariot and horse, The army and the power (They shall lie down together, they shall not rise; They are extinguished, they are quenched like a wick):", 
                "num": 17
              }, 
              {
                "text": "\"Do not remember the former things, Nor consider the things of old.", 
                "num": 18
              }, 
              {
                "text": "Behold, I will do a new thing, Now it shall spring forth; Shall you not know it? I will even make a road in the wilderness And rivers in the desert.", 
                "num": 19
              }, 
              {
                "text": "The beast of the field will honor Me, The jackals and the ostriches, Because I give waters in the wilderness And rivers in the desert, To give drink to My people, My chosen.", 
                "num": 20
              }, 
              {
                "text": "This people I have formed for Myself; They shall declare My praise.", 
                "num": 21
              }, 
              {
                "text": "\"But you have not called upon Me, O Jacob; And you have been weary of Me, O Israel.", 
                "num": 22
              }, 
              {
                "text": "You have not brought Me the sheep for your burnt offerings, Nor have you honored Me with your sacrifices. I have not caused you to serve with grain offerings, Nor wearied you with incense.", 
                "num": 23
              }, 
              {
                "text": "You have bought Me no sweet cane with money, Nor have you satisfied Me with the fat of your sacrifices; But you have burdened Me with your sins, You have wearied Me with your iniquities.", 
                "num": 24
              }, 
              {
                "text": "\"I, even I, am He who blots out your transgressions for My own sake; And I will not remember your sins.", 
                "num": 25
              }, 
              {
                "text": "Put Me in remembrance; Let us contend together; State your case, that you may be acquitted.", 
                "num": 26
              }, 
              {
                "text": "Your first father sinned, And your mediators have transgressed against Me.", 
                "num": 27
              }, 
              {
                "text": "Therefore I will profane the princes of the sanctuary; I will give Jacob to the curse, And Israel to reproaches.", 
                "num": 28
              }
            ], 
            "num": 43
          }, 
          {
            "verses": [
              {
                "text": "\"Yet hear me now, O Jacob My servant, And Israel whom I have chosen.", 
                "num": 1
              }, 
              {
                "text": "Thus says the LORD who made you And formed you from the womb, who will help you: \"Fear not, O Jacob My servant; And you, Jeshurun, whom I have chosen.", 
                "num": 2
              }, 
              {
                "text": "For I will pour water on him who is thirsty, And floods on the dry ground; I will pour My Spirit on your descendants, And My blessing on your offspring;", 
                "num": 3
              }, 
              {
                "text": "They will spring up among the grass Like willows by the watercourses.'", 
                "num": 4
              }, 
              {
                "text": "One will say, \"I am the LORD's'; Another will call himself by the name of Jacob; Another will write with his hand, \"The LORD's,' And name himself by the name of Israel.", 
                "num": 5
              }, 
              {
                "text": "\"Thus says the LORD, the King of Israel, And his Redeemer, the LORD of hosts: \"I am the First and I am the Last; Besides Me there is no God.", 
                "num": 6
              }, 
              {
                "text": "And who can proclaim as I do? Then let him declare it and set it in order for Me, Since I appointed the ancient people. And the things that are coming and shall come, Let them show these to them.", 
                "num": 7
              }, 
              {
                "text": "Do not fear, nor be afraid; Have I not told you from that time, and declared it? You are My witnesses. Is there a God besides Me? Indeed there is no other Rock; I know not one.\"'", 
                "num": 8
              }, 
              {
                "text": "Those who make an image, all of them are useless, And their precious things shall not profit; They are their own witnesses; They neither see nor know, that they may be ashamed.", 
                "num": 9
              }, 
              {
                "text": "Who would form a god or mold an image That profits him nothing?", 
                "num": 10
              }, 
              {
                "text": "Surely all his companions would be ashamed; And the workmen, they are mere men. Let them all be gathered together, Let them stand up; Yet they shall fear, They shall be ashamed together.", 
                "num": 11
              }, 
              {
                "text": "The blacksmith with the tongs works one in the coals, Fashions it with hammers, And works it with the strength of his arms. Even so, he is hungry, and his strength fails; He drinks no water and is faint.", 
                "num": 12
              }, 
              {
                "text": "The craftsman stretches out his rule, He marks one out with chalk; He fashions it with a plane, He marks it out with the compass, And makes it like the figure of a man, According to the beauty of a man, that it may remain in the house.", 
                "num": 13
              }, 
              {
                "text": "He cuts down cedars for himself, And takes the cypress and the oak; He secures it for himself among the trees of the forest. He plants a pine, and the rain nourishes it.", 
                "num": 14
              }, 
              {
                "text": "Then it shall be for a man to burn, For he will take some of it and warm himself; Yes, he kindles it and bakes bread; Indeed he makes a god and worships it; He makes it a carved image, and falls down to it.", 
                "num": 15
              }, 
              {
                "text": "He burns half of it in the fire; With this half he eats meat; He roasts a roast, and is satisfied. He even warms himself and says, \"Ah! I am warm, I have seen the fire.\"", 
                "num": 16
              }, 
              {
                "text": "And the rest of it he makes into a god, His carved image. He falls down before it and worships it, Prays to it and says, \"Deliver me, for you are my god!\"", 
                "num": 17
              }, 
              {
                "text": "They do not know nor understand; For He has shut their eyes, so that they cannot see, And their hearts, so that they cannot understand.", 
                "num": 18
              }, 
              {
                "text": "And no one considers in his heart, Nor is there knowledge nor understanding to say, \"I have burned half of it in the fire, Yes, I have also baked bread on its coals; I have roasted meat and eaten it; And shall I make the rest of it an abomination? Shall I fall down before a block of wood?\"", 
                "num": 19
              }, 
              {
                "text": "He feeds on ashes; A deceived heart has turned him aside; And he cannot deliver his soul, Nor say, \"Is there not a lie in my right hand?\"", 
                "num": 20
              }, 
              {
                "text": "\"Remember these, O Jacob, And Israel, for you are My servant; I have formed you, you are My servant; O Israel, you will not be forgotten by Me!", 
                "num": 21
              }, 
              {
                "text": "I have blotted out, like a thick cloud, your transgressions, And like a cloud, your sins. Return to Me, for I have redeemed you.\"", 
                "num": 22
              }, 
              {
                "text": "Sing, O heavens, for the LORD has done it! Shout, you lower parts of the earth; Break forth into singing, you mountains, O forest, and every tree in it! For the LORD has redeemed Jacob, And glorified Himself in Israel.", 
                "num": 23
              }, 
              {
                "text": "Thus says the LORD, your Redeemer, And He who formed you from the womb: \"I am the LORD, who makes all things, Who stretches out the heavens all alone, Who spreads abroad the earth by Myself;", 
                "num": 24
              }, 
              {
                "text": "Who frustrates the signs of the babblers, And drives diviners mad; Who turns wise men backward, And makes their knowledge foolishness;", 
                "num": 25
              }, 
              {
                "text": "Who confirms the word of His servant, And performs the counsel of His messengers; Who says to Jerusalem, \"You shall be inhabited,' To the cities of Judah, \"You shall be built,' And I will raise up her waste places;", 
                "num": 26
              }, 
              {
                "text": "Who says to the deep, \"Be dry! And I will dry up your rivers';", 
                "num": 27
              }, 
              {
                "text": "Who says of Cyrus, \"He is My shepherd, And he shall perform all My pleasure, Saying to Jerusalem, \"You shall be built,\" And to the temple, \"Your foundation shall be laid.\"'", 
                "num": 28
              }
            ], 
            "num": 44
          }, 
          {
            "verses": [
              {
                "text": "\"Thus says the LORD to His anointed, To Cyrus, whose right hand I have held-- To subdue nations before him And loose the armor of kings, To open before him the double doors, So that the gates will not be shut:", 
                "num": 1
              }, 
              {
                "text": "\"I will go before you And make the crooked places straight; I will break in pieces the gates of bronze And cut the bars of iron.", 
                "num": 2
              }, 
              {
                "text": "I will give you the treasures of darkness And hidden riches of secret places, That you may know that I, the LORD, Who call you by your name, Am the God of Israel.", 
                "num": 3
              }, 
              {
                "text": "For Jacob My servant's sake, And Israel My elect, I have even called you by your name; I have named you, though you have not known Me.", 
                "num": 4
              }, 
              {
                "text": "I am the LORD, and there is no other; There is no God besides Me. I will gird you, though you have not known Me,", 
                "num": 5
              }, 
              {
                "text": "That they may know from the rising of the sun to its setting That there is none besides Me. I am the LORD, and there is no other;", 
                "num": 6
              }, 
              {
                "text": "I form the light and create darkness, I make peace and create calamity; I, the LORD, do all these things.'", 
                "num": 7
              }, 
              {
                "text": "\"Rain down, you heavens, from above, And let the skies pour down righteousness; Let the earth open, let them bring forth salvation, And let righteousness spring up together. I, the LORD, have created it.", 
                "num": 8
              }, 
              {
                "text": "\"Woe to him who strives with his Maker! Let the potsherd strive with the potsherds of the earth! Shall the clay say to him who forms it, \"What are you making?' Or shall your handiwork say, \"He has no hands'?", 
                "num": 9
              }, 
              {
                "text": "Woe to him who says to his father, \"What are you begetting?' Or to the woman, \"What have you brought forth?\"'", 
                "num": 10
              }, 
              {
                "text": "Thus says the LORD, The Holy One of Israel, and his Maker: \"Ask Me of things to come concerning My sons; And concerning the work of My hands, you command Me.", 
                "num": 11
              }, 
              {
                "text": "I have made the earth, And created man on it. I--My hands--stretched out the heavens, And all their host I have commanded.", 
                "num": 12
              }, 
              {
                "text": "I have raised him up in righteousness, And I will direct all his ways; He shall build My city And let My exiles go free, Not for price nor reward,\" Says the LORD of hosts.", 
                "num": 13
              }, 
              {
                "text": "Thus says the LORD: \"The labor of Egypt and merchandise of Cush And of the Sabeans, men of stature, Shall come over to you, and they shall be yours; They shall walk behind you, They shall come over in chains; And they shall bow down to you. They will make supplication to you, saying, \"Surely God is in you, And there is no other; There is no other God.\"'", 
                "num": 14
              }, 
              {
                "text": "Truly You are God, who hide Yourself, O God of Israel, the Savior!", 
                "num": 15
              }, 
              {
                "text": "They shall be ashamed And also disgraced, all of them; They shall go in confusion together, Who are makers of idols.", 
                "num": 16
              }, 
              {
                "text": "But Israel shall be saved by the LORD With an everlasting salvation; You shall not be ashamed or disgraced Forever and ever.", 
                "num": 17
              }, 
              {
                "text": "For thus says the LORD, Who created the heavens, Who is God, Who formed the earth and made it, Who has established it, Who did not create it in vain, Who formed it to be inhabited: \"I am the LORD, and there is no other.", 
                "num": 18
              }, 
              {
                "text": "I have not spoken in secret, In a dark place of the earth; I did not say to the seed of Jacob, \"Seek Me in vain'; I, the LORD, speak righteousness, I declare things that are right.", 
                "num": 19
              }, 
              {
                "text": "\"Assemble yourselves and come; Draw near together, You who have escaped from the nations. They have no knowledge, Who carry the wood of their carved image, And pray to a god that cannot save.", 
                "num": 20
              }, 
              {
                "text": "Tell and bring forth your case; Yes, let them take counsel together. Who has declared this from ancient time? Who has told it from that time? Have not I, the LORD? And there is no other God besides Me, A just God and a Savior; There is none besides Me.", 
                "num": 21
              }, 
              {
                "text": "\"Look to Me, and be saved, All you ends of the earth! For I am God, and there is no other.", 
                "num": 22
              }, 
              {
                "text": "I have sworn by Myself; The word has gone out of My mouth in righteousness, And shall not return, That to Me every knee shall bow, Every tongue shall take an oath.", 
                "num": 23
              }, 
              {
                "text": "He shall say, \"Surely in the LORD I have righteousness and strength. To Him men shall come, And all shall be ashamed Who are incensed against Him.", 
                "num": 24
              }, 
              {
                "text": "In the LORD all the descendants of Israel Shall be justified, and shall glory.\"'", 
                "num": 25
              }
            ], 
            "num": 45
          }, 
          {
            "verses": [
              {
                "text": "Bel bows down, Nebo stoops; Their idols were on the beasts and on the cattle. Your carriages were heavily loaded, A burden to the weary beast.", 
                "num": 1
              }, 
              {
                "text": "They stoop, they bow down together; They could not deliver the burden, But have themselves gone into captivity.", 
                "num": 2
              }, 
              {
                "text": "\"Listen to Me, O house of Jacob, And all the remnant of the house of Israel, Who have been upheld by Me from birth, Who have been carried from the womb:", 
                "num": 3
              }, 
              {
                "text": "Even to your old age, I am He, And even to gray hairs I will carry you! I have made, and I will bear; Even I will carry, and will deliver you.", 
                "num": 4
              }, 
              {
                "text": "\"To whom will you liken Me, and make Me equal And compare Me, that we should be alike?", 
                "num": 5
              }, 
              {
                "text": "They lavish gold out of the bag, And weigh silver on the scales; They hire a goldsmith, and he makes it a god; They prostrate themselves, yes, they worship.", 
                "num": 6
              }, 
              {
                "text": "They bear it on the shoulder, they carry it And set it in its place, and it stands; From its place it shall not move. Though one cries out to it, yet it cannot answer Nor save him out of his trouble.", 
                "num": 7
              }, 
              {
                "text": "\"Remember this, and show yourselves men; Recall to mind, O you transgressors.", 
                "num": 8
              }, 
              {
                "text": "Remember the former things of old, For I am God, and there is no other; I am God, and there is none like Me,", 
                "num": 9
              }, 
              {
                "text": "Declaring the end from the beginning, And from ancient times things that are not yet done, Saying, \"My counsel shall stand, And I will do all My pleasure,'", 
                "num": 10
              }, 
              {
                "text": "Calling a bird of prey from the east, The man who executes My counsel, from a far country. Indeed I have spoken it; I will also bring it to pass. I have purposed it; I will also do it.", 
                "num": 11
              }, 
              {
                "text": "\"Listen to Me, you stubborn-hearted, Who are far from righteousness:", 
                "num": 12
              }, 
              {
                "text": "I bring My righteousness near, it shall not be far off; My salvation shall not linger. And I will place salvation in Zion, For Israel My glory.", 
                "num": 13
              }
            ], 
            "num": 46
          }, 
          {
            "verses": [
              {
                "text": "\"Come down and sit in the dust, O virgin daughter of Babylon; Sit on the ground without a throne, O daughter of the Chaldeans! For you shall no more be called Tender and delicate.", 
                "num": 1
              }, 
              {
                "text": "Take the millstones and grind meal. Remove your veil, Take off the skirt, Uncover the thigh, Pass through the rivers.", 
                "num": 2
              }, 
              {
                "text": "Your nakedness shall be uncovered, Yes, your shame will be seen; I will take vengeance, And I will not arbitrate with a man.\"", 
                "num": 3
              }, 
              {
                "text": "As for our Redeemer, the LORD of hosts is His name, The Holy One of Israel.", 
                "num": 4
              }, 
              {
                "text": "\"Sit in silence, and go into darkness, O daughter of the Chaldeans; For you shall no longer be called The Lady of Kingdoms.", 
                "num": 5
              }, 
              {
                "text": "I was angry with My people; I have profaned My inheritance, And given them into your hand. You showed them no mercy; On the elderly you laid your yoke very heavily.", 
                "num": 6
              }, 
              {
                "text": "And you said, \"I shall be a lady forever,' So that you did not take these things to heart, Nor remember the latter end of them.", 
                "num": 7
              }, 
              {
                "text": "\"Therefore hear this now, you who are given to pleasures, Who dwell securely, Who say in your heart, \"I am, and there is no one else besides me; I shall not sit as a widow, Nor shall I know the loss of children';", 
                "num": 8
              }, 
              {
                "text": "But these two things shall come to you In a moment, in one day: The loss of children, and widowhood. They shall come upon you in their fullness Because of the multitude of your sorceries, For the great abundance of your enchantments.", 
                "num": 9
              }, 
              {
                "text": "\"For you have trusted in your wickedness; You have said, \"No one sees me'; Your wisdom and your knowledge have warped you; And you have said in your heart, \"I am, and there is no one else besides me.'", 
                "num": 10
              }, 
              {
                "text": "Therefore evil shall come upon you; You shall not know from where it arises. And trouble shall fall upon you; You will not be able to put it off. And desolation shall come upon you suddenly, Which you shall not know.", 
                "num": 11
              }, 
              {
                "text": "\"Stand now with your enchantments And the multitude of your sorceries, In which you have labored from your youth-- Perhaps you will be able to profit, Perhaps you will prevail.", 
                "num": 12
              }, 
              {
                "text": "You are wearied in the multitude of your counsels; Let now the astrologers, the stargazers, And the monthly prognosticators Stand up and save you From what shall come upon you.", 
                "num": 13
              }, 
              {
                "text": "Behold, they shall be as stubble, The fire shall burn them; They shall not deliver themselves From the power of the flame; It shall not be a coal to be warmed by, Nor a fire to sit before!", 
                "num": 14
              }, 
              {
                "text": "Thus shall they be to you With whom you have labored, Your merchants from your youth; They shall wander each one to his quarter. No one shall save you.", 
                "num": 15
              }
            ], 
            "num": 47
          }, 
          {
            "verses": [
              {
                "text": "\"Hear this, O house of Jacob, Who are called by the name of Israel, And have come forth from the wellsprings of Judah; Who swear by the name of the LORD, And make mention of the God of Israel, But not in truth or in righteousness;", 
                "num": 1
              }, 
              {
                "text": "For they call themselves after the holy city, And lean on the God of Israel; The LORD of hosts is His name:", 
                "num": 2
              }, 
              {
                "text": "\"I have declared the former things from the beginning; They went forth from My mouth, and I caused them to hear it. Suddenly I did them, and they came to pass.", 
                "num": 3
              }, 
              {
                "text": "Because I knew that you were obstinate, And your neck was an iron sinew, And your brow bronze,", 
                "num": 4
              }, 
              {
                "text": "Even from the beginning I have declared it to you; Before it came to pass I proclaimed it to you, Lest you should say, \"My idol has done them, And my carved image and my molded image Have commanded them.'", 
                "num": 5
              }, 
              {
                "text": "\"You have heard; See all this. And will you not declare it? I have made you hear new things from this time, Even hidden things, and you did not know them.", 
                "num": 6
              }, 
              {
                "text": "They are created now and not from the beginning; And before this day you have not heard them, Lest you should say, \"Of course I knew them.'", 
                "num": 7
              }, 
              {
                "text": "Surely you did not hear, Surely you did not know; Surely from long ago your ear was not opened. For I knew that you would deal very treacherously, And were called a transgressor from the womb.", 
                "num": 8
              }, 
              {
                "text": "\"For My name's sake I will defer My anger, And for My praise I will restrain it from you, So that I do not cut you off.", 
                "num": 9
              }, 
              {
                "text": "Behold, I have refined you, but not as silver; I have tested you in the furnace of affliction.", 
                "num": 10
              }, 
              {
                "text": "For My own sake, for My own sake, I will do it; For how should My name be profaned? And I will not give My glory to another.", 
                "num": 11
              }, 
              {
                "text": "\"Listen to Me, O Jacob, And Israel, My called: I am He, I am the First, I am also the Last.", 
                "num": 12
              }, 
              {
                "text": "Indeed My hand has laid the foundation of the earth, And My right hand has stretched out the heavens; When I call to them, They stand up together.", 
                "num": 13
              }, 
              {
                "text": "\"All of you, assemble yourselves, and hear! Who among them has declared these things? The LORD loves him; He shall do His pleasure on Babylon, And His arm shall be against the Chaldeans.", 
                "num": 14
              }, 
              {
                "text": "I, even I, have spoken; Yes, I have called him, I have brought him, and his way will prosper.", 
                "num": 15
              }, 
              {
                "text": "\"Come near to Me, hear this: I have not spoken in secret from the beginning; From the time that it was, I was there. And now the Lord GOD and His Spirit Have sent Me.\"", 
                "num": 16
              }, 
              {
                "text": "Thus says the LORD, your Redeemer, The Holy One of Israel: \"I am the LORD your God, Who teaches you to profit, Who leads you by the way you should go.", 
                "num": 17
              }, 
              {
                "text": "Oh, that you had heeded My commandments! Then your peace would have been like a river, And your righteousness like the waves of the sea.", 
                "num": 18
              }, 
              {
                "text": "Your descendants also would have been like the sand, And the offspring of your body like the grains of sand; His name would not have been cut off Nor destroyed from before Me.\"", 
                "num": 19
              }, 
              {
                "text": "Go forth from Babylon! Flee from the Chaldeans! With a voice of singing, Declare, proclaim this, Utter it to the end of the earth; Say, \"The LORD has redeemed His servant Jacob!\"", 
                "num": 20
              }, 
              {
                "text": "And they did not thirst When He led them through the deserts; He caused the waters to flow from the rock for them; He also split the rock, and the waters gushed out.", 
                "num": 21
              }, 
              {
                "text": "\"There is no peace,\" says the LORD, \"for the wicked.\"", 
                "num": 22
              }
            ], 
            "num": 48
          }, 
          {
            "verses": [
              {
                "text": "\"Listen, O coastlands, to Me, And take heed, you peoples from afar! The LORD has called Me from the womb; From the matrix of My mother He has made mention of My name.", 
                "num": 1
              }, 
              {
                "text": "And He has made My mouth like a sharp sword; In the shadow of His hand He has hidden Me, And made Me a polished shaft; In His quiver He has hidden Me.\"", 
                "num": 2
              }, 
              {
                "text": "\"And He said to me, \"You are My servant, O Israel, In whom I will be glorified.'", 
                "num": 3
              }, 
              {
                "text": "Then I said, \"I have labored in vain, I have spent my strength for nothing and in vain; Yet surely my just reward is with the LORD, And my work with my God.\"'", 
                "num": 4
              }, 
              {
                "text": "\"And now the LORD says, Who formed Me from the womb to be His Servant, To bring Jacob back to Him, So that Israel is gathered to Him (For I shall be glorious in the eyes of the LORD, And My God shall be My strength),", 
                "num": 5
              }, 
              {
                "text": "Indeed He says, \"It is too small a thing that You should be My Servant To raise up the tribes of Jacob, And to restore the preserved ones of Israel; I will also give You as a light to the Gentiles, That You should be My salvation to the ends of the earth.\"'", 
                "num": 6
              }, 
              {
                "text": "Thus says the LORD, The Redeemer of Israel, their Holy One, To Him whom man despises, To Him whom the nation abhors, To the Servant of rulers: \"Kings shall see and arise, Princes also shall worship, Because of the LORD who is faithful, The Holy One of Israel; And He has chosen You.\"", 
                "num": 7
              }, 
              {
                "text": "Thus says the LORD: \"In an acceptable time I have heard You, And in the day of salvation I have helped You; I will preserve You and give You As a covenant to the people, To restore the earth, To cause them to inherit the desolate heritages;", 
                "num": 8
              }, 
              {
                "text": "That You may say to the prisoners, \"Go forth,' To those who are in darkness, \"Show yourselves.' \"They shall feed along the roads, And their pastures shall be on all desolate heights.", 
                "num": 9
              }, 
              {
                "text": "They shall neither hunger nor thirst, Neither heat nor sun shall strike them; For He who has mercy on them will lead them, Even by the springs of water He will guide them.", 
                "num": 10
              }, 
              {
                "text": "I will make each of My mountains a road, And My highways shall be elevated.", 
                "num": 11
              }, 
              {
                "text": "Surely these shall come from afar; Look! Those from the north and the west, And these from the land of Sinim.\"", 
                "num": 12
              }, 
              {
                "text": "Sing, O heavens! Be joyful, O earth! And break out in singing, O mountains! For the LORD has comforted His people, And will have mercy on His afflicted.", 
                "num": 13
              }, 
              {
                "text": "But Zion said, \"The LORD has forsaken me, And my Lord has forgotten me.\"", 
                "num": 14
              }, 
              {
                "text": "\"Can a woman forget her nursing child, And not have compassion on the son of her womb? Surely they may forget, Yet I will not forget you.", 
                "num": 15
              }, 
              {
                "text": "See, I have inscribed you on the palms of My hands; Your walls are continually before Me.", 
                "num": 16
              }, 
              {
                "text": "Your sons shall make haste; Your destroyers and those who laid you waste Shall go away from you.", 
                "num": 17
              }, 
              {
                "text": "Lift up your eyes, look around and see; All these gather together and come to you. As I live,\" says the LORD, \"You shall surely clothe yourselves with them all as an ornament, And bind them on you as a bride does.", 
                "num": 18
              }, 
              {
                "text": "\"For your waste and desolate places, And the land of your destruction, Will even now be too small for the inhabitants; And those who swallowed you up will be far away.", 
                "num": 19
              }, 
              {
                "text": "The children you will have, After you have lost the others, Will say again in your ears, \"The place is too small for me; Give me a place where I may dwell.'", 
                "num": 20
              }, 
              {
                "text": "Then you will say in your heart, \"Who has begotten these for me, Since I have lost my children and am desolate, A captive, and wandering to and fro? And who has brought these up? There I was, left alone; But these, where were they?\"'", 
                "num": 21
              }, 
              {
                "text": "Thus says the Lord GOD: \"Behold, I will lift My hand in an oath to the nations, And set up My standard for the peoples; They shall bring your sons in their arms, And your daughters shall be carried on their shoulders;", 
                "num": 22
              }, 
              {
                "text": "Kings shall be your foster fathers, And their queens your nursing mothers; They shall bow down to you with their faces to the earth, And lick up the dust of your feet. Then you will know that I am the LORD, For they shall not be ashamed who wait for Me.\"", 
                "num": 23
              }, 
              {
                "text": "Shall the prey be taken from the mighty, Or the captives of the righteous be delivered?", 
                "num": 24
              }, 
              {
                "text": "But thus says the LORD: \"Even the captives of the mighty shall be taken away, And the prey of the terrible be delivered; For I will contend with him who contends with you, And I will save your children.", 
                "num": 25
              }, 
              {
                "text": "I will feed those who oppress you with their own flesh, And they shall be drunk with their own blood as with sweet wine. All flesh shall know That I, the LORD, am your Savior, And your Redeemer, the Mighty One of Jacob.\"", 
                "num": 26
              }
            ], 
            "num": 49
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Where is the certificate of your mother's divorce, Whom I have put away? Or which of My creditors is it to whom I have sold you? For your iniquities you have sold yourselves, And for your transgressions your mother has been put away.", 
                "num": 1
              }, 
              {
                "text": "Why, when I came, was there no man? Why, when I called, was there none to answer? Is My hand shortened at all that it cannot redeem? Or have I no power to deliver? Indeed with My rebuke I dry up the sea, I make the rivers a wilderness; Their fish stink because there is no water, And die of thirst.", 
                "num": 2
              }, 
              {
                "text": "I clothe the heavens with blackness, And I make sackcloth their covering.\"", 
                "num": 3
              }, 
              {
                "text": "\"The Lord GOD has given Me The tongue of the learned, That I should know how to speak A word in season to him who is weary. He awakens Me morning by morning, He awakens My ear To hear as the learned.", 
                "num": 4
              }, 
              {
                "text": "The Lord GOD has opened My ear; And I was not rebellious, Nor did I turn away.", 
                "num": 5
              }, 
              {
                "text": "I gave My back to those who struck Me, And My cheeks to those who plucked out the beard; I did not hide My face from shame and spitting.", 
                "num": 6
              }, 
              {
                "text": "\"For the Lord GOD will help Me; Therefore I will not be disgraced; Therefore I have set My face like a flint, And I know that I will not be ashamed.", 
                "num": 7
              }, 
              {
                "text": "He is near who justifies Me; Who will contend with Me? Let us stand together. Who is My adversary? Let him come near Me.", 
                "num": 8
              }, 
              {
                "text": "Surely the Lord GOD will help Me; Who is he who will condemn Me? Indeed they will all grow old like a garment; The moth will eat them up.", 
                "num": 9
              }, 
              {
                "text": "\"Who among you fears the LORD? Who obeys the voice of His Servant? Who walks in darkness And has no light? Let him trust in the name of the LORD And rely upon his God.", 
                "num": 10
              }, 
              {
                "text": "Look, all you who kindle a fire, Who encircle yourselves with sparks: Walk in the light of your fire and in the sparks you have kindled-- This you shall have from My hand: You shall lie down in torment.", 
                "num": 11
              }
            ], 
            "num": 50
          }, 
          {
            "verses": [
              {
                "text": "\"Listen to Me, you who follow after righteousness, You who seek the LORD: Look to the rock from which you were hewn, And to the hole of the pit from which you were dug.", 
                "num": 1
              }, 
              {
                "text": "Look to Abraham your father, And to Sarah who bore you; For I called him alone, And blessed him and increased him.\"", 
                "num": 2
              }, 
              {
                "text": "For the LORD will comfort Zion, He will comfort all her waste places; He will make her wilderness like Eden, And her desert like the garden of the LORD; Joy and gladness will be found in it, Thanksgiving and the voice of melody.", 
                "num": 3
              }, 
              {
                "text": "\"Listen to Me, My people; And give ear to Me, O My nation: For law will proceed from Me, And I will make My justice rest As a light of the peoples.", 
                "num": 4
              }, 
              {
                "text": "My righteousness is near, My salvation has gone forth, And My arms will judge the peoples; The coastlands will wait upon Me, And on My arm they will trust.", 
                "num": 5
              }, 
              {
                "text": "Lift up your eyes to the heavens, And look on the earth beneath. For the heavens will vanish away like smoke, The earth will grow old like a garment, And those who dwell in it will die in like manner; But My salvation will be forever, And My righteousness will not be abolished.", 
                "num": 6
              }, 
              {
                "text": "\"Listen to Me, you who know righteousness, You people in whose heart is My law: Do not fear the reproach of men, Nor be afraid of their insults.", 
                "num": 7
              }, 
              {
                "text": "For the moth will eat them up like a garment, And the worm will eat them like wool; But My righteousness will be forever, And My salvation from generation to generation.\"", 
                "num": 8
              }, 
              {
                "text": "Awake, awake, put on strength, O arm of the LORD! Awake as in the ancient days, In the generations of old. Are You not the arm that cut Rahab apart, And wounded the serpent?", 
                "num": 9
              }, 
              {
                "text": "Are You not the One who dried up the sea, The waters of the great deep; That made the depths of the sea a road For the redeemed to cross over?", 
                "num": 10
              }, 
              {
                "text": "So the ransomed of the LORD shall return, And come to Zion with singing, With everlasting joy on their heads. They shall obtain joy and gladness; Sorrow and sighing shall flee away.", 
                "num": 11
              }, 
              {
                "text": "\"I, even I, am He who comforts you. Who are you that you should be afraid Of a man who will die, And of the son of a man who will be made like grass?", 
                "num": 12
              }, 
              {
                "text": "And you forget the LORD your Maker, Who stretched out the heavens And laid the foundations of the earth; You have feared continually every day Because of the fury of the oppressor, When he has prepared to destroy. And where is the fury of the oppressor?", 
                "num": 13
              }, 
              {
                "text": "The captive exile hastens, that he may be loosed, That he should not die in the pit, And that his bread should not fail.", 
                "num": 14
              }, 
              {
                "text": "But I am the LORD your God, Who divided the sea whose waves roared-- The LORD of hosts is His name.", 
                "num": 15
              }, 
              {
                "text": "And I have put My words in your mouth; I have covered you with the shadow of My hand, That I may plant the heavens, Lay the foundations of the earth, And say to Zion, \"You are My people.\"'", 
                "num": 16
              }, 
              {
                "text": "Awake, awake! Stand up, O Jerusalem, You who have drunk at the hand of the LORD The cup of His fury; You have drunk the dregs of the cup of trembling, And drained it out.", 
                "num": 17
              }, 
              {
                "text": "There is no one to guide her Among all the sons she has brought forth; Nor is there any who takes her by the hand Among all the sons she has brought up.", 
                "num": 18
              }, 
              {
                "text": "These two things have come to you; Who will be sorry for you?-- Desolation and destruction, famine and sword-- By whom will I comfort you?", 
                "num": 19
              }, 
              {
                "text": "Your sons have fainted, They lie at the head of all the streets, Like an antelope in a net; They are full of the fury of the LORD, The rebuke of your God.", 
                "num": 20
              }, 
              {
                "text": "Therefore please hear this, you afflicted, And drunk but not with wine.", 
                "num": 21
              }, 
              {
                "text": "Thus says your Lord, The LORD and your God, Who pleads the cause of His people: \"See, I have taken out of your hand The cup of trembling, The dregs of the cup of My fury; You shall no longer drink it.", 
                "num": 22
              }, 
              {
                "text": "But I will put it into the hand of those who afflict you, Who have said to you, \"Lie down, that we may walk over you.' And you have laid your body like the ground, And as the street, for those who walk over.\"", 
                "num": 23
              }
            ], 
            "num": 51
          }, 
          {
            "verses": [
              {
                "text": "Awake, awake! Put on your strength, O Zion; Put on your beautiful garments, O Jerusalem, the holy city! For the uncircumcised and the unclean Shall no longer come to you.", 
                "num": 1
              }, 
              {
                "text": "Shake yourself from the dust, arise; Sit down, O Jerusalem! Loose yourself from the bonds of your neck, O captive daughter of Zion!", 
                "num": 2
              }, 
              {
                "text": "For thus says the LORD: \"You have sold yourselves for nothing, And you shall be redeemed without money.\"", 
                "num": 3
              }, 
              {
                "text": "For thus says the Lord GOD: \"My people went down at first Into Egypt to dwell there; Then the Assyrian oppressed them without cause.", 
                "num": 4
              }, 
              {
                "text": "Now therefore, what have I here,\" says the LORD, \"That My people are taken away for nothing? Those who rule over them Make them wail,\" says the LORD, \"And My name is blasphemed continually every day.", 
                "num": 5
              }, 
              {
                "text": "Therefore My people shall know My name; Therefore they shall know in that day That I am He who speaks: \"Behold, it is I.\"'", 
                "num": 6
              }, 
              {
                "text": "How beautiful upon the mountains Are the feet of him who brings good news, Who proclaims peace, Who brings glad tidings of good things, Who proclaims salvation, Who says to Zion, \"Your God reigns!\"", 
                "num": 7
              }, 
              {
                "text": "Your watchmen shall lift up their voices, With their voices they shall sing together; For they shall see eye to eye When the LORD brings back Zion.", 
                "num": 8
              }, 
              {
                "text": "Break forth into joy, sing together, You waste places of Jerusalem! For the LORD has comforted His people, He has redeemed Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "The LORD has made bare His holy arm In the eyes of all the nations; And all the ends of the earth shall see The salvation of our God.", 
                "num": 10
              }, 
              {
                "text": "Depart! Depart! Go out from there, Touch no unclean thing; Go out from the midst of her, Be clean, You who bear the vessels of the LORD.", 
                "num": 11
              }, 
              {
                "text": "For you shall not go out with haste, Nor go by flight; For the LORD will go before you, And the God of Israel will be your rear guard.", 
                "num": 12
              }, 
              {
                "text": "Behold, My Servant shall deal prudently; He shall be exalted and extolled and be very high.", 
                "num": 13
              }, 
              {
                "text": "Just as many were astonished at you, So His visage was marred more than any man, And His form more than the sons of men;", 
                "num": 14
              }, 
              {
                "text": "So shall He sprinkle many nations. Kings shall shut their mouths at Him; For what had not been told them they shall see, And what they had not heard they shall consider.", 
                "num": 15
              }
            ], 
            "num": 52
          }, 
          {
            "verses": [
              {
                "text": "Who has believed our report? And to whom has the arm of the LORD been revealed?", 
                "num": 1
              }, 
              {
                "text": "For He shall grow up before Him as a tender plant, And as a root out of dry ground. He has no form or comeliness; And when we see Him, There is no beauty that we should desire Him.", 
                "num": 2
              }, 
              {
                "text": "He is despised and rejected by men, A Man of sorrows and acquainted with grief. And we hid, as it were, our faces from Him; He was despised, and we did not esteem Him.", 
                "num": 3
              }, 
              {
                "text": "Surely He has borne our griefs And carried our sorrows; Yet we esteemed Him stricken, Smitten by God, and afflicted.", 
                "num": 4
              }, 
              {
                "text": "But He was wounded for our transgressions, He was bruised for our iniquities; The chastisement for our peace was upon Him, And by His stripes we are healed.", 
                "num": 5
              }, 
              {
                "text": "All we like sheep have gone astray; We have turned, every one, to his own way; And the LORD has laid on Him the iniquity of us all.", 
                "num": 6
              }, 
              {
                "text": "He was oppressed and He was afflicted, Yet He opened not His mouth; He was led as a lamb to the slaughter, And as a sheep before its shearers is silent, So He opened not His mouth.", 
                "num": 7
              }, 
              {
                "text": "He was taken from prison and from judgment, And who will declare His generation? For He was cut off from the land of the living; For the transgressions of My people He was stricken.", 
                "num": 8
              }, 
              {
                "text": "And they made His grave with the wicked-- But with the rich at His death, Because He had done no violence, Nor was any deceit in His mouth.", 
                "num": 9
              }, 
              {
                "text": "Yet it pleased the LORD to bruise Him; He has put Him to grief. When You make His soul an offering for sin, He shall see His seed, He shall prolong His days, And the pleasure of the LORD shall prosper in His hand.", 
                "num": 10
              }, 
              {
                "text": "He shall see the labor of His soul, and be satisfied. By His knowledge My righteous Servant shall justify many, For He shall bear their iniquities.", 
                "num": 11
              }, 
              {
                "text": "Therefore I will divide Him a portion with the great, And He shall divide the spoil with the strong, Because He poured out His soul unto death, And He was numbered with the transgressors, And He bore the sin of many, And made intercession for the transgressors.", 
                "num": 12
              }
            ], 
            "num": 53
          }, 
          {
            "verses": [
              {
                "text": "\"Sing, O barren, You who have not borne! Break forth into singing, and cry aloud, You who have not labored with child! For more are the children of the desolate Than the children of the married woman,\" says the LORD.", 
                "num": 1
              }, 
              {
                "text": "\"Enlarge the place of your tent, And let them stretch out the curtains of your dwellings; Do not spare; Lengthen your cords, And strengthen your stakes.", 
                "num": 2
              }, 
              {
                "text": "For you shall expand to the right and to the left, And your descendants will inherit the nations, And make the desolate cities inhabited.", 
                "num": 3
              }, 
              {
                "text": "\"Do not fear, for you will not be ashamed; Neither be disgraced, for you will not be put to shame; For you will forget the shame of your youth, And will not remember the reproach of your widowhood anymore.", 
                "num": 4
              }, 
              {
                "text": "For your Maker is your husband, The LORD of hosts is His name; And your Redeemer is the Holy One of Israel; He is called the God of the whole earth.", 
                "num": 5
              }, 
              {
                "text": "For the LORD has called you Like a woman forsaken and grieved in spirit, Like a youthful wife when you were refused,\" Says your God.", 
                "num": 6
              }, 
              {
                "text": "\"For a mere moment I have forsaken you, But with great mercies I will gather you.", 
                "num": 7
              }, 
              {
                "text": "With a little wrath I hid My face from you for a moment; But with everlasting kindness I will have mercy on you,\" Says the LORD, your Redeemer.", 
                "num": 8
              }, 
              {
                "text": "\"For this is like the waters of Noah to Me; For as I have sworn That the waters of Noah would no longer cover the earth, So have I sworn That I would not be angry with you, nor rebuke you.", 
                "num": 9
              }, 
              {
                "text": "For the mountains shall depart And the hills be removed, But My kindness shall not depart from you, Nor shall My covenant of peace be removed,\" Says the LORD, who has mercy on you.", 
                "num": 10
              }, 
              {
                "text": "\"O you afflicted one, Tossed with tempest, and not comforted, Behold, I will lay your stones with colorful gems, And lay your foundations with sapphires.", 
                "num": 11
              }, 
              {
                "text": "I will make your pinnacles of rubies, Your gates of crystal, And all your walls of precious stones.", 
                "num": 12
              }, 
              {
                "text": "All your children shall be taught by the LORD, And great shall be the peace of your children.", 
                "num": 13
              }, 
              {
                "text": "In righteousness you shall be established; You shall be far from oppression, for you shall not fear; And from terror, for it shall not come near you.", 
                "num": 14
              }, 
              {
                "text": "Indeed they shall surely assemble, but not because of Me. Whoever assembles against you shall fall for your sake.", 
                "num": 15
              }, 
              {
                "text": "\"Behold, I have created the blacksmith Who blows the coals in the fire, Who brings forth an instrument for his work; And I have created the spoiler to destroy.", 
                "num": 16
              }, 
              {
                "text": "No weapon formed against you shall prosper, And every tongue which rises against you in judgment You shall condemn. This is the heritage of the servants of the LORD, And their righteousness is from Me,\" Says the LORD.", 
                "num": 17
              }
            ], 
            "num": 54
          }, 
          {
            "verses": [
              {
                "text": "\"Ho! Everyone who thirsts, Come to the waters; And you who have no money, Come, buy and eat. Yes, come, buy wine and milk Without money and without price.", 
                "num": 1
              }, 
              {
                "text": "Why do you spend money for what is not bread, And your wages for what does not satisfy? Listen carefully to Me, and eat what is good, And let your soul delight itself in abundance.", 
                "num": 2
              }, 
              {
                "text": "Incline your ear, and come to Me. Hear, and your soul shall live; And I will make an everlasting covenant with you-- The sure mercies of David.", 
                "num": 3
              }, 
              {
                "text": "Indeed I have given him as a witness to the people, A leader and commander for the people.", 
                "num": 4
              }, 
              {
                "text": "Surely you shall call a nation you do not know, And nations who do not know you shall run to you, Because of the LORD your God, And the Holy One of Israel; For He has glorified you.\"", 
                "num": 5
              }, 
              {
                "text": "Seek the LORD while He may be found, Call upon Him while He is near.", 
                "num": 6
              }, 
              {
                "text": "Let the wicked forsake his way, And the unrighteous man his thoughts; Let him return to the LORD, And He will have mercy on him; And to our God, For He will abundantly pardon.", 
                "num": 7
              }, 
              {
                "text": "\"For My thoughts are not your thoughts, Nor are your ways My ways,\" says the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"For as the heavens are higher than the earth, So are My ways higher than your ways, And My thoughts than your thoughts.", 
                "num": 9
              }, 
              {
                "text": "\"For as the rain comes down, and the snow from heaven, And do not return there, But water the earth, And make it bring forth and bud, That it may give seed to the sower And bread to the eater,", 
                "num": 10
              }, 
              {
                "text": "So shall My word be that goes forth from My mouth; It shall not return to Me void, But it shall accomplish what I please, And it shall prosper in the thing for which I sent it.", 
                "num": 11
              }, 
              {
                "text": "\"For you shall go out with joy, And be led out with peace; The mountains and the hills Shall break forth into singing before you, And all the trees of the field shall clap their hands.", 
                "num": 12
              }, 
              {
                "text": "Instead of the thorn shall come up the cypress tree, And instead of the brier shall come up the myrtle tree; And it shall be to the LORD for a name, For an everlasting sign that shall not be cut off.\"", 
                "num": 13
              }
            ], 
            "num": 55
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Keep justice, and do righteousness, For My salvation is about to come, And My righteousness to be revealed.", 
                "num": 1
              }, 
              {
                "text": "Blessed is the man who does this, And the son of man who lays hold on it; Who keeps from defiling the Sabbath, And keeps his hand from doing any evil.\"", 
                "num": 2
              }, 
              {
                "text": "Do not let the son of the foreigner Who has joined himself to the LORD Speak, saying, \"The LORD has utterly separated me from His people\"; Nor let the eunuch say, \"Here I am, a dry tree.\"", 
                "num": 3
              }, 
              {
                "text": "For thus says the LORD: \"To the eunuchs who keep My Sabbaths, And choose what pleases Me, And hold fast My covenant,", 
                "num": 4
              }, 
              {
                "text": "Even to them I will give in My house And within My walls a place and a name Better than that of sons and daughters; I will give them an everlasting name That shall not be cut off.", 
                "num": 5
              }, 
              {
                "text": "\"Also the sons of the foreigner Who join themselves to the LORD, to serve Him, And to love the name of the LORD, to be His servants-- Everyone who keeps from defiling the Sabbath, And holds fast My covenant--", 
                "num": 6
              }, 
              {
                "text": "Even them I will bring to My holy mountain, And make them joyful in My house of prayer. Their burnt offerings and their sacrifices Will be accepted on My altar; For My house shall be called a house of prayer for all nations.\"", 
                "num": 7
              }, 
              {
                "text": "The Lord GOD, who gathers the outcasts of Israel, says, \"Yet I will gather to him Others besides those who are gathered to him.\"", 
                "num": 8
              }, 
              {
                "text": "All you beasts of the field, come to devour, All you beasts in the forest.", 
                "num": 9
              }, 
              {
                "text": "His watchmen are blind, They are all ignorant; They are all dumb dogs, They cannot bark; Sleeping, lying down, loving to slumber.", 
                "num": 10
              }, 
              {
                "text": "Yes, they are greedy dogs Which never have enough. And they are shepherds Who cannot understand; They all look to their own way, Every one for his own gain, From his own territory.", 
                "num": 11
              }, 
              {
                "text": "\"Come,\" one says, \"I will bring wine, And we will fill ourselves with intoxicating drink; Tomorrow will be as today, And much more abundant.\"", 
                "num": 12
              }
            ], 
            "num": 56
          }, 
          {
            "verses": [
              {
                "text": "The righteous perishes, And no man takes it to heart; Merciful men are taken away, While no one considers That the righteous is taken away from evil.", 
                "num": 1
              }, 
              {
                "text": "He shall enter into peace; They shall rest in their beds, Each one walking in his uprightness.", 
                "num": 2
              }, 
              {
                "text": "\"But come here, You sons of the sorceress, You offspring of the adulterer and the harlot!", 
                "num": 3
              }, 
              {
                "text": "Whom do you ridicule? Against whom do you make a wide mouth And stick out the tongue? Are you not children of transgression, Offspring of falsehood,", 
                "num": 4
              }, 
              {
                "text": "Inflaming yourselves with gods under every green tree, Slaying the children in the valleys, Under the clefts of the rocks?", 
                "num": 5
              }, 
              {
                "text": "Among the smooth stones of the stream Is your portion; They, they, are your lot! Even to them you have poured a drink offering, You have offered a grain offering. Should I receive comfort in these?", 
                "num": 6
              }, 
              {
                "text": "\"On a lofty and high mountain You have set your bed; Even there you went up To offer sacrifice.", 
                "num": 7
              }, 
              {
                "text": "Also behind the doors and their posts You have set up your remembrance; For you have uncovered yourself to those other than Me, And have gone up to them; You have enlarged your bed And made a covenant with them; You have loved their bed, Where you saw their nudity.", 
                "num": 8
              }, 
              {
                "text": "You went to the king with ointment, And increased your perfumes; You sent your messengers far off, And even descended to Sheol.", 
                "num": 9
              }, 
              {
                "text": "You are wearied in the length of your way; Yet you did not say, \"There is no hope.' You have found the life of your hand; Therefore you were not grieved.", 
                "num": 10
              }, 
              {
                "text": "\"And of whom have you been afraid, or feared, That you have lied And not remembered Me, Nor taken it to your heart? Is it not because I have held My peace from of old That you do not fear Me?", 
                "num": 11
              }, 
              {
                "text": "I will declare your righteousness And your works, For they will not profit you.", 
                "num": 12
              }, 
              {
                "text": "When you cry out, Let your collection of idols deliver you. But the wind will carry them all away, A breath will take them. But he who puts his trust in Me shall possess the land, And shall inherit My holy mountain.\"", 
                "num": 13
              }, 
              {
                "text": "And one shall say, \"Heap it up! Heap it up! Prepare the way, Take the stumbling block out of the way of My people.\"", 
                "num": 14
              }, 
              {
                "text": "For thus says the High and Lofty One Who inhabits eternity, whose name is Holy: \"I dwell in the high and holy place, With him who has a contrite and humble spirit, To revive the spirit of the humble, And to revive the heart of the contrite ones.", 
                "num": 15
              }, 
              {
                "text": "For I will not contend forever, Nor will I always be angry; For the spirit would fail before Me, And the souls which I have made.", 
                "num": 16
              }, 
              {
                "text": "For the iniquity of his covetousness I was angry and struck him; I hid and was angry, And he went on backsliding in the way of his heart.", 
                "num": 17
              }, 
              {
                "text": "I have seen his ways, and will heal him; I will also lead him, And restore comforts to him And to his mourners.", 
                "num": 18
              }, 
              {
                "text": "\"I create the fruit of the lips: Peace, peace to him who is far off and to him who is near,\" Says the LORD, \"And I will heal him.\"", 
                "num": 19
              }, 
              {
                "text": "But the wicked are like the troubled sea, When it cannot rest, Whose waters cast up mire and dirt.", 
                "num": 20
              }, 
              {
                "text": "\"There is no peace,\" Says my God, \"for the wicked.\"", 
                "num": 21
              }
            ], 
            "num": 57
          }, 
          {
            "verses": [
              {
                "text": "\"Cry aloud, spare not; Lift up your voice like a trumpet; Tell My people their transgression, And the house of Jacob their sins.", 
                "num": 1
              }, 
              {
                "text": "Yet they seek Me daily, And delight to know My ways, As a nation that did righteousness, And did not forsake the ordinance of their God. They ask of Me the ordinances of justice; They take delight in approaching God.", 
                "num": 2
              }, 
              {
                "text": "\"Why have we fasted,' they say, \"and You have not seen? Why have we afflicted our souls, and You take no notice?' \"In fact, in the day of your fast you find pleasure, And exploit all your laborers.", 
                "num": 3
              }, 
              {
                "text": "Indeed you fast for strife and debate, And to strike with the fist of wickedness. You will not fast as you do this day, To make your voice heard on high.", 
                "num": 4
              }, 
              {
                "text": "Is it a fast that I have chosen, A day for a man to afflict his soul? Is it to bow down his head like a bulrush, And to spread out sackcloth and ashes? Would you call this a fast, And an acceptable day to the LORD?", 
                "num": 5
              }, 
              {
                "text": "\"Is this not the fast that I have chosen: To loose the bonds of wickedness, To undo the heavy burdens, To let the oppressed go free, And that you break every yoke?", 
                "num": 6
              }, 
              {
                "text": "Is it not to share your bread with the hungry, And that you bring to your house the poor who are cast out; When you see the naked, that you cover him, And not hide yourself from your own flesh?", 
                "num": 7
              }, 
              {
                "text": "Then your light shall break forth like the morning, Your healing shall spring forth speedily, And your righteousness shall go before you; The glory of the LORD shall be your rear guard.", 
                "num": 8
              }, 
              {
                "text": "Then you shall call, and the LORD will answer; You shall cry, and He will say, \"Here I am.' \"If you take away the yoke from your midst, The pointing of the finger, and speaking wickedness,", 
                "num": 9
              }, 
              {
                "text": "If you extend your soul to the hungry And satisfy the afflicted soul, Then your light shall dawn in the darkness, And your darkness shall be as the noonday.", 
                "num": 10
              }, 
              {
                "text": "The LORD will guide you continually, And satisfy your soul in drought, And strengthen your bones; You shall be like a watered garden, And like a spring of water, whose waters do not fail.", 
                "num": 11
              }, 
              {
                "text": "Those from among you Shall build the old waste places; You shall raise up the foundations of many generations; And you shall be called the Repairer of the Breach, The Restorer of Streets to Dwell In.", 
                "num": 12
              }, 
              {
                "text": "\"If you turn away your foot from the Sabbath, From doing your pleasure on My holy day, And call the Sabbath a delight, The holy day of the LORD honorable, And shall honor Him, not doing your own ways, Nor finding your own pleasure, Nor speaking your own words,", 
                "num": 13
              }, 
              {
                "text": "Then you shall delight yourself in the LORD; And I will cause you to ride on the high hills of the earth, And feed you with the heritage of Jacob your father. The mouth of the LORD has spoken.\"", 
                "num": 14
              }
            ], 
            "num": 58
          }, 
          {
            "verses": [
              {
                "text": "Behold, the LORD's hand is not shortened, That it cannot save; Nor His ear heavy, That it cannot hear.", 
                "num": 1
              }, 
              {
                "text": "But your iniquities have separated you from your God; And your sins have hidden His face from you, So that He will not hear.", 
                "num": 2
              }, 
              {
                "text": "For your hands are defiled with blood, And your fingers with iniquity; Your lips have spoken lies, Your tongue has muttered perversity.", 
                "num": 3
              }, 
              {
                "text": "No one calls for justice, Nor does any plead for truth. They trust in empty words and speak lies; They conceive evil and bring forth iniquity.", 
                "num": 4
              }, 
              {
                "text": "They hatch vipers' eggs and weave the spider's web; He who eats of their eggs dies, And from that which is crushed a viper breaks out.", 
                "num": 5
              }, 
              {
                "text": "Their webs will not become garments, Nor will they cover themselves with their works; Their works are works of iniquity, And the act of violence is in their hands.", 
                "num": 6
              }, 
              {
                "text": "Their feet run to evil, And they make haste to shed innocent blood; Their thoughts are thoughts of iniquity; Wasting and destruction are in their paths.", 
                "num": 7
              }, 
              {
                "text": "The way of peace they have not known, And there is no justice in their ways; They have made themselves crooked paths; Whoever takes that way shall not know peace.", 
                "num": 8
              }, 
              {
                "text": "Therefore justice is far from us, Nor does righteousness overtake us; We look for light, but there is darkness! For brightness, but we walk in blackness!", 
                "num": 9
              }, 
              {
                "text": "We grope for the wall like the blind, And we grope as if we had no eyes; We stumble at noonday as at twilight; We are as dead men in desolate places.", 
                "num": 10
              }, 
              {
                "text": "We all growl like bears, And moan sadly like doves; We look for justice, but there is none; For salvation, but it is far from us.", 
                "num": 11
              }, 
              {
                "text": "For our transgressions are multiplied before You, And our sins testify against us; For our transgressions are with us, And as for our iniquities, we know them:", 
                "num": 12
              }, 
              {
                "text": "In transgressing and lying against the LORD, And departing from our God, Speaking oppression and revolt, Conceiving and uttering from the heart words of falsehood.", 
                "num": 13
              }, 
              {
                "text": "Justice is turned back, And righteousness stands afar off; For truth is fallen in the street, And equity cannot enter.", 
                "num": 14
              }, 
              {
                "text": "So truth fails, And he who departs from evil makes himself a prey. Then the LORD saw it, and it displeased Him That there was no justice.", 
                "num": 15
              }, 
              {
                "text": "He saw that there was no man, And wondered that there was no intercessor; Therefore His own arm brought salvation for Him; And His own righteousness, it sustained Him.", 
                "num": 16
              }, 
              {
                "text": "For He put on righteousness as a breastplate, And a helmet of salvation on His head; He put on the garments of vengeance for clothing, And was clad with zeal as a cloak.", 
                "num": 17
              }, 
              {
                "text": "According to their deeds, accordingly He will repay, Fury to His adversaries, Recompense to His enemies; The coastlands He will fully repay.", 
                "num": 18
              }, 
              {
                "text": "So shall they fear The name of the LORD from the west, And His glory from the rising of the sun; When the enemy comes in like a flood, The Spirit of the LORD will lift up a standard against him.", 
                "num": 19
              }, 
              {
                "text": "\"The Redeemer will come to Zion, And to those who turn from transgression in Jacob,\" Says the LORD.", 
                "num": 20
              }, 
              {
                "text": "\"As for Me,\" says the LORD, \"this is My covenant with them: My Spirit who is upon you, and My words which I have put in your mouth, shall not depart from your mouth, nor from the mouth of your descendants, nor from the mouth of your descendants' descendants,\" says the LORD, \"from this time and forevermore.\"", 
                "num": 21
              }
            ], 
            "num": 59
          }, 
          {
            "verses": [
              {
                "text": "Arise, shine; For your light has come! And the glory of the LORD is risen upon you.", 
                "num": 1
              }, 
              {
                "text": "For behold, the darkness shall cover the earth, And deep darkness the people; But the LORD will arise over you, And His glory will be seen upon you.", 
                "num": 2
              }, 
              {
                "text": "The Gentiles shall come to your light, And kings to the brightness of your rising.", 
                "num": 3
              }, 
              {
                "text": "\"Lift up your eyes all around, and see: They all gather together, they come to you; Your sons shall come from afar, And your daughters shall be nursed at your side.", 
                "num": 4
              }, 
              {
                "text": "Then you shall see and become radiant, And your heart shall swell with joy; Because the abundance of the sea shall be turned to you, The wealth of the Gentiles shall come to you.", 
                "num": 5
              }, 
              {
                "text": "The multitude of camels shall cover your land, The dromedaries of Midian and Ephah; All those from Sheba shall come; They shall bring gold and incense, And they shall proclaim the praises of the LORD.", 
                "num": 6
              }, 
              {
                "text": "All the flocks of Kedar shall be gathered together to you, The rams of Nebaioth shall minister to you; They shall ascend with acceptance on My altar, And I will glorify the house of My glory.", 
                "num": 7
              }, 
              {
                "text": "\"Who are these who fly like a cloud, And like doves to their roosts?", 
                "num": 8
              }, 
              {
                "text": "Surely the coastlands shall wait for Me; And the ships of Tarshish will come first, To bring your sons from afar, Their silver and their gold with them, To the name of the LORD your God, And to the Holy One of Israel, Because He has glorified you.", 
                "num": 9
              }, 
              {
                "text": "\"The sons of foreigners shall build up your walls, And their kings shall minister to you; For in My wrath I struck you, But in My favor I have had mercy on you.", 
                "num": 10
              }, 
              {
                "text": "Therefore your gates shall be open continually; They shall not be shut day or night, That men may bring to you the wealth of the Gentiles, And their kings in procession.", 
                "num": 11
              }, 
              {
                "text": "For the nation and kingdom which will not serve you shall perish, And those nations shall be utterly ruined.", 
                "num": 12
              }, 
              {
                "text": "\"The glory of Lebanon shall come to you, The cypress, the pine, and the box tree together, To beautify the place of My sanctuary; And I will make the place of My feet glorious.", 
                "num": 13
              }, 
              {
                "text": "Also the sons of those who afflicted you Shall come bowing to you, And all those who despised you shall fall prostrate at the soles of your feet; And they shall call you The City of the LORD, Zion of the Holy One of Israel.", 
                "num": 14
              }, 
              {
                "text": "\"Whereas you have been forsaken and hated, So that no one went through you, I will make you an eternal excellence, A joy of many generations.", 
                "num": 15
              }, 
              {
                "text": "You shall drink the milk of the Gentiles, And milk the breast of kings; You shall know that I, the LORD, am your Savior And your Redeemer, the Mighty One of Jacob.", 
                "num": 16
              }, 
              {
                "text": "\"Instead of bronze I will bring gold, Instead of iron I will bring silver, Instead of wood, bronze, And instead of stones, iron. I will also make your officers peace, And your magistrates righteousness.", 
                "num": 17
              }, 
              {
                "text": "Violence shall no longer be heard in your land, Neither wasting nor destruction within your borders; But you shall call your walls Salvation, And your gates Praise.", 
                "num": 18
              }, 
              {
                "text": "\"The sun shall no longer be your light by day, Nor for brightness shall the moon give light to you; But the LORD will be to you an everlasting light, And your God your glory.", 
                "num": 19
              }, 
              {
                "text": "Your sun shall no longer go down, Nor shall your moon withdraw itself; For the LORD will be your everlasting light, And the days of your mourning shall be ended.", 
                "num": 20
              }, 
              {
                "text": "Also your people shall all be righteous; They shall inherit the land forever, The branch of My planting, The work of My hands, That I may be glorified.", 
                "num": 21
              }, 
              {
                "text": "A little one shall become a thousand, And a small one a strong nation. I, the LORD, will hasten it in its time.\"", 
                "num": 22
              }
            ], 
            "num": 60
          }, 
          {
            "verses": [
              {
                "text": "\"The Spirit of the Lord GOD is upon Me, Because the LORD has anointed Me To preach good tidings to the poor; He has sent Me to heal the brokenhearted, To proclaim liberty to the captives, And the opening of the prison to those who are bound;", 
                "num": 1
              }, 
              {
                "text": "To proclaim the acceptable year of the LORD, And the day of vengeance of our God; To comfort all who mourn,", 
                "num": 2
              }, 
              {
                "text": "To console those who mourn in Zion, To give them beauty for ashes, The oil of joy for mourning, The garment of praise for the spirit of heaviness; That they may be called trees of righteousness, The planting of the LORD, that He may be glorified.\"", 
                "num": 3
              }, 
              {
                "text": "And they shall rebuild the old ruins, They shall raise up the former desolations, And they shall repair the ruined cities, The desolations of many generations.", 
                "num": 4
              }, 
              {
                "text": "Strangers shall stand and feed your flocks, And the sons of the foreigner Shall be your plowmen and your vinedressers.", 
                "num": 5
              }, 
              {
                "text": "But you shall be named the priests of the LORD, They shall call you the servants of our God. You shall eat the riches of the Gentiles, And in their glory you shall boast.", 
                "num": 6
              }, 
              {
                "text": "Instead of your shame you shall have double honor, And instead of confusion they shall rejoice in their portion. Therefore in their land they shall possess double; Everlasting joy shall be theirs.", 
                "num": 7
              }, 
              {
                "text": "\"For I, the LORD, love justice; I hate robbery for burnt offering; I will direct their work in truth, And will make with them an everlasting covenant.", 
                "num": 8
              }, 
              {
                "text": "Their descendants shall be known among the Gentiles, And their offspring among the people. All who see them shall acknowledge them, That they are the posterity whom the LORD has blessed.\"", 
                "num": 9
              }, 
              {
                "text": "I will greatly rejoice in the LORD, My soul shall be joyful in my God; For He has clothed me with the garments of salvation, He has covered me with the robe of righteousness, As a bridegroom decks himself with ornaments, And as a bride adorns herself with her jewels.", 
                "num": 10
              }, 
              {
                "text": "For as the earth brings forth its bud, As the garden causes the things that are sown in it to spring forth, So the Lord GOD will cause righteousness and praise to spring forth before all the nations.", 
                "num": 11
              }
            ], 
            "num": 61
          }, 
          {
            "verses": [
              {
                "text": "For Zion's sake I will not hold My peace, And for Jerusalem's sake I will not rest, Until her righteousness goes forth as brightness, And her salvation as a lamp that burns.", 
                "num": 1
              }, 
              {
                "text": "The Gentiles shall see your righteousness, And all kings your glory. You shall be called by a new name, Which the mouth of the LORD will name.", 
                "num": 2
              }, 
              {
                "text": "You shall also be a crown of glory In the hand of the LORD, And a royal diadem In the hand of your God.", 
                "num": 3
              }, 
              {
                "text": "You shall no longer be termed Forsaken, Nor shall your land any more be termed Desolate; But you shall be called Hephzibah, and your land Beulah; For the LORD delights in you, And your land shall be married.", 
                "num": 4
              }, 
              {
                "text": "For as a young man marries a virgin, So shall your sons marry you; And as the bridegroom rejoices over the bride, So shall your God rejoice over you.", 
                "num": 5
              }, 
              {
                "text": "I have set watchmen on your walls, O Jerusalem; They shall never hold their peace day or night. You who make mention of the LORD, do not keep silent,", 
                "num": 6
              }, 
              {
                "text": "And give Him no rest till He establishes And till He makes Jerusalem a praise in the earth.", 
                "num": 7
              }, 
              {
                "text": "The LORD has sworn by His right hand And by the arm of His strength: \"Surely I will no longer give your grain As food for your enemies; And the sons of the foreigner shall not drink your new wine, For which you have labored.", 
                "num": 8
              }, 
              {
                "text": "But those who have gathered it shall eat it, And praise the LORD; Those who have brought it together shall drink it in My holy courts.\"", 
                "num": 9
              }, 
              {
                "text": "Go through, Go through the gates! Prepare the way for the people; Build up, Build up the highway! Take out the stones, Lift up a banner for the peoples!", 
                "num": 10
              }, 
              {
                "text": "Indeed the LORD has proclaimed To the end of the world: \"Say to the daughter of Zion, \"Surely your salvation is coming; Behold, His reward is with Him, And His work before Him.\"'", 
                "num": 11
              }, 
              {
                "text": "And they shall call them The Holy People, The Redeemed of the LORD; And you shall be called Sought Out, A City Not Forsaken.", 
                "num": 12
              }
            ], 
            "num": 62
          }, 
          {
            "verses": [
              {
                "text": "Who is this who comes from Edom, With dyed garments from Bozrah, This One who is glorious in His apparel, Traveling in the greatness of His strength?-- \"I who speak in righteousness, mighty to save.\"", 
                "num": 1
              }, 
              {
                "text": "Why is Your apparel red, And Your garments like one who treads in the winepress?", 
                "num": 2
              }, 
              {
                "text": "\"I have trodden the winepress alone, And from the peoples no one was with Me. For I have trodden them in My anger, And trampled them in My fury; Their blood is sprinkled upon My garments, And I have stained all My robes.", 
                "num": 3
              }, 
              {
                "text": "For the day of vengeance is in My heart, And the year of My redeemed has come.", 
                "num": 4
              }, 
              {
                "text": "I looked, but there was no one to help, And I wondered That there was no one to uphold; Therefore My own arm brought salvation for Me; And My own fury, it sustained Me.", 
                "num": 5
              }, 
              {
                "text": "I have trodden down the peoples in My anger, Made them drunk in My fury, And brought down their strength to the earth.\"", 
                "num": 6
              }, 
              {
                "text": "I will mention the lovingkindnesses of the LORD And the praises of the LORD, According to all that the LORD has bestowed on us, And the great goodness toward the house of Israel, Which He has bestowed on them according to His mercies, According to the multitude of His lovingkindnesses.", 
                "num": 7
              }, 
              {
                "text": "For He said, \"Surely they are My people, Children who will not lie.\" So He became their Savior.", 
                "num": 8
              }, 
              {
                "text": "In all their affliction He was afflicted, And the Angel of His Presence saved them; In His love and in His pity He redeemed them; And He bore them and carried them All the days of old.", 
                "num": 9
              }, 
              {
                "text": "But they rebelled and grieved His Holy Spirit; So He turned Himself against them as an enemy, And He fought against them.", 
                "num": 10
              }, 
              {
                "text": "Then he remembered the days of old, Moses and his people, saying: \"Where is He who brought them up out of the sea With the shepherd of His flock? Where is He who put His Holy Spirit within them,", 
                "num": 11
              }, 
              {
                "text": "Who led them by the right hand of Moses, With His glorious arm, Dividing the water before them To make for Himself an everlasting name,", 
                "num": 12
              }, 
              {
                "text": "Who led them through the deep, As a horse in the wilderness, That they might not stumble?\"", 
                "num": 13
              }, 
              {
                "text": "As a beast goes down into the valley, And the Spirit of the LORD causes him to rest, So You lead Your people, To make Yourself a glorious name.", 
                "num": 14
              }, 
              {
                "text": "Look down from heaven, And see from Your habitation, holy and glorious. Where are Your zeal and Your strength, The yearning of Your heart and Your mercies toward me? Are they restrained?", 
                "num": 15
              }, 
              {
                "text": "Doubtless You are our Father, Though Abraham was ignorant of us, And Israel does not acknowledge us. You, O LORD, are our Father; Our Redeemer from Everlasting is Your name.", 
                "num": 16
              }, 
              {
                "text": "O LORD, why have You made us stray from Your ways, And hardened our heart from Your fear? Return for Your servants' sake, The tribes of Your inheritance.", 
                "num": 17
              }, 
              {
                "text": "Your holy people have possessed it but a little while; Our adversaries have trodden down Your sanctuary.", 
                "num": 18
              }, 
              {
                "text": "We have become like those of old, over whom You never ruled, Those who were never called by Your name.", 
                "num": 19
              }
            ], 
            "num": 63
          }, 
          {
            "verses": [
              {
                "text": "Oh, that You would rend the heavens! That You would come down! That the mountains might shake at Your presence--", 
                "num": 1
              }, 
              {
                "text": "As fire burns brushwood, As fire causes water to boil-- To make Your name known to Your adversaries, That the nations may tremble at Your presence!", 
                "num": 2
              }, 
              {
                "text": "When You did awesome things for which we did not look, You came down, The mountains shook at Your presence.", 
                "num": 3
              }, 
              {
                "text": "For since the beginning of the world Men have not heard nor perceived by the ear, Nor has the eye seen any God besides You, Who acts for the one who waits for Him.", 
                "num": 4
              }, 
              {
                "text": "You meet him who rejoices and does righteousness, Who remembers You in Your ways. You are indeed angry, for we have sinned-- In these ways we continue; And we need to be saved.", 
                "num": 5
              }, 
              {
                "text": "But we are all like an unclean thing, And all our righteousnesses are like filthy rags; We all fade as a leaf, And our iniquities, like the wind, Have taken us away.", 
                "num": 6
              }, 
              {
                "text": "And there is no one who calls on Your name, Who stirs himself up to take hold of You; For You have hidden Your face from us, And have consumed us because of our iniquities.", 
                "num": 7
              }, 
              {
                "text": "But now, O LORD, You are our Father; We are the clay, and You our potter; And all we are the work of Your hand.", 
                "num": 8
              }, 
              {
                "text": "Do not be furious, O LORD, Nor remember iniquity forever; Indeed, please look--we all are Your people!", 
                "num": 9
              }, 
              {
                "text": "Your holy cities are a wilderness, Zion is a wilderness, Jerusalem a desolation.", 
                "num": 10
              }, 
              {
                "text": "Our holy and beautiful temple, Where our fathers praised You, Is burned up with fire; And all our pleasant things are laid waste.", 
                "num": 11
              }, 
              {
                "text": "Will You restrain Yourself because of these things, O LORD? Will You hold Your peace, and afflict us very severely?", 
                "num": 12
              }
            ], 
            "num": 64
          }, 
          {
            "verses": [
              {
                "text": "\"I was sought by those who did not ask for Me; I was found by those who did not seek Me. I said, \"Here I am, here I am,' To a nation that was not called by My name.", 
                "num": 1
              }, 
              {
                "text": "I have stretched out My hands all day long to a rebellious people, Who walk in a way that is not good, According to their own thoughts;", 
                "num": 2
              }, 
              {
                "text": "A people who provoke Me to anger continually to My face; Who sacrifice in gardens, And burn incense on altars of brick;", 
                "num": 3
              }, 
              {
                "text": "Who sit among the graves, And spend the night in the tombs; Who eat swine's flesh, And the broth of abominable things is in their vessels;", 
                "num": 4
              }, 
              {
                "text": "Who say, \"Keep to yourself, Do not come near me, For I am holier than you!' These are smoke in My nostrils, A fire that burns all the day.", 
                "num": 5
              }, 
              {
                "text": "\"Behold, it is written before Me: I will not keep silence, but will repay-- Even repay into their bosom--", 
                "num": 6
              }, 
              {
                "text": "Your iniquities and the iniquities of your fathers together,\" Says the LORD, \"Who have burned incense on the mountains And blasphemed Me on the hills; Therefore I will measure their former work into their bosom.\"", 
                "num": 7
              }, 
              {
                "text": "Thus says the LORD: \"As the new wine is found in the cluster, And one says, \"Do not destroy it, For a blessing is in it,' So will I do for My servants' sake, That I may not destroy them all.", 
                "num": 8
              }, 
              {
                "text": "I will bring forth descendants from Jacob, And from Judah an heir of My mountains; My elect shall inherit it, And My servants shall dwell there.", 
                "num": 9
              }, 
              {
                "text": "Sharon shall be a fold of flocks, And the Valley of Achor a place for herds to lie down, For My people who have sought Me.", 
                "num": 10
              }, 
              {
                "text": "\"But you are those who forsake the LORD, Who forget My holy mountain, Who prepare a table for Gad, And who furnish a drink offering for Meni.", 
                "num": 11
              }, 
              {
                "text": "Therefore I will number you for the sword, And you shall all bow down to the slaughter; Because, when I called, you did not answer; When I spoke, you did not hear, But did evil before My eyes, And chose that in which I do not delight.\"", 
                "num": 12
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Behold, My servants shall eat, But you shall be hungry; Behold, My servants shall drink, But you shall be thirsty; Behold, My servants shall rejoice, But you shall be ashamed;", 
                "num": 13
              }, 
              {
                "text": "Behold, My servants shall sing for joy of heart, But you shall cry for sorrow of heart, And wail for grief of spirit.", 
                "num": 14
              }, 
              {
                "text": "You shall leave your name as a curse to My chosen; For the Lord GOD will slay you, And call His servants by another name;", 
                "num": 15
              }, 
              {
                "text": "So that he who blesses himself in the earth Shall bless himself in the God of truth; And he who swears in the earth Shall swear by the God of truth; Because the former troubles are forgotten, And because they are hidden from My eyes.", 
                "num": 16
              }, 
              {
                "text": "\"For behold, I create new heavens and a new earth; And the former shall not be remembered or come to mind.", 
                "num": 17
              }, 
              {
                "text": "But be glad and rejoice forever in what I create; For behold, I create Jerusalem as a rejoicing, And her people a joy.", 
                "num": 18
              }, 
              {
                "text": "I will rejoice in Jerusalem, And joy in My people; The voice of weeping shall no longer be heard in her, Nor the voice of crying.", 
                "num": 19
              }, 
              {
                "text": "\"No more shall an infant from there live but a few days, Nor an old man who has not fulfilled his days; For the child shall die one hundred years old, But the sinner being one hundred years old shall be accursed.", 
                "num": 20
              }, 
              {
                "text": "They shall build houses and inhabit them; They shall plant vineyards and eat their fruit.", 
                "num": 21
              }, 
              {
                "text": "They shall not build and another inhabit; They shall not plant and another eat; For as the days of a tree, so shall be the days of My people, And My elect shall long enjoy the work of their hands.", 
                "num": 22
              }, 
              {
                "text": "They shall not labor in vain, Nor bring forth children for trouble; For they shall be the descendants of the blessed of the LORD, And their offspring with them.", 
                "num": 23
              }, 
              {
                "text": "\"It shall come to pass That before they call, I will answer; And while they are still speaking, I will hear.", 
                "num": 24
              }, 
              {
                "text": "The wolf and the lamb shall feed together, The lion shall eat straw like the ox, And dust shall be the serpent's food. They shall not hurt nor destroy in all My holy mountain,\" Says the LORD.", 
                "num": 25
              }
            ], 
            "num": 65
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Heaven is My throne, And earth is My footstool. Where is the house that you will build Me? And where is the place of My rest?", 
                "num": 1
              }, 
              {
                "text": "For all those things My hand has made, And all those things exist,\" Says the LORD. \"But on this one will I look: On him who is poor and of a contrite spirit, And who trembles at My word.", 
                "num": 2
              }, 
              {
                "text": "\"He who kills a bull is as if he slays a man; He who sacrifices a lamb, as if he breaks a dog's neck; He who offers a grain offering, as if he offers swine's blood; He who burns incense, as if he blesses an idol. Just as they have chosen their own ways, And their soul delights in their abominations,", 
                "num": 3
              }, 
              {
                "text": "So will I choose their delusions, And bring their fears on them; Because, when I called, no one answered, When I spoke they did not hear; But they did evil before My eyes, And chose that in which I do not delight.\"", 
                "num": 4
              }, 
              {
                "text": "Hear the word of the LORD, You who tremble at His word: \"Your brethren who hated you, Who cast you out for My name's sake, said, \"Let the LORD be glorified, That we may see your joy.' But they shall be ashamed.\"", 
                "num": 5
              }, 
              {
                "text": "The sound of noise from the city! A voice from the temple! The voice of the LORD, Who fully repays His enemies!", 
                "num": 6
              }, 
              {
                "text": "\"Before she was in labor, she gave birth; Before her pain came, She delivered a male child.", 
                "num": 7
              }, 
              {
                "text": "Who has heard such a thing? Who has seen such things? Shall the earth be made to give birth in one day? Or shall a nation be born at once? For as soon as Zion was in labor, She gave birth to her children.", 
                "num": 8
              }, 
              {
                "text": "Shall I bring to the time of birth, and not cause delivery?\" says the LORD. \"Shall I who cause delivery shut up the womb?\" says your God.", 
                "num": 9
              }, 
              {
                "text": "\"Rejoice with Jerusalem, And be glad with her, all you who love her; Rejoice for joy with her, all you who mourn for her;", 
                "num": 10
              }, 
              {
                "text": "That you may feed and be satisfied With the consolation of her bosom, That you may drink deeply and be delighted With the abundance of her glory.\"", 
                "num": 11
              }, 
              {
                "text": "For thus says the LORD: \"Behold, I will extend peace to her like a river, And the glory of the Gentiles like a flowing stream. Then you shall feed; On her sides shall you be carried, And be dandled on her knees.", 
                "num": 12
              }, 
              {
                "text": "As one whom his mother comforts, So I will comfort you; And you shall be comforted in Jerusalem.\"", 
                "num": 13
              }, 
              {
                "text": "When you see this, your heart shall rejoice, And your bones shall flourish like grass; The hand of the LORD shall be known to His servants, And His indignation to His enemies.", 
                "num": 14
              }, 
              {
                "text": "For behold, the LORD will come with fire And with His chariots, like a whirlwind, To render His anger with fury, And His rebuke with flames of fire.", 
                "num": 15
              }, 
              {
                "text": "For by fire and by His sword The LORD will judge all flesh; And the slain of the LORD shall be many.", 
                "num": 16
              }, 
              {
                "text": "\"Those who sanctify themselves and purify themselves, To go to the gardens After an idol in the midst, Eating swine's flesh and the abomination and the mouse, Shall be consumed together,\" says the LORD.", 
                "num": 17
              }, 
              {
                "text": "\"For I know their works and their thoughts. It shall be that I will gather all nations and tongues; and they shall come and see My glory.", 
                "num": 18
              }, 
              {
                "text": "I will set a sign among them; and those among them who escape I will send to the nations: to Tarshish and Pul and Lud, who draw the bow, and Tubal and Javan, to the coastlands afar off who have not heard My fame nor seen My glory. And they shall declare My glory among the Gentiles.", 
                "num": 19
              }, 
              {
                "text": "Then they shall bring all your brethren for an offering to the LORD out of all nations, on horses and in chariots and in litters, on mules and on camels, to My holy mountain Jerusalem,\" says the LORD, \"as the children of Israel bring an offering in a clean vessel into the house of the LORD.", 
                "num": 20
              }, 
              {
                "text": "And I will also take some of them for priests and Levites,\" says the LORD.", 
                "num": 21
              }, 
              {
                "text": "\"For as the new heavens and the new earth Which I will make shall remain before Me,\" says the LORD, \"So shall your descendants and your name remain.", 
                "num": 22
              }, 
              {
                "text": "And it shall come to pass That from one New Moon to another, And from one Sabbath to another, All flesh shall come to worship before Me,\" says the LORD.", 
                "num": 23
              }, 
              {
                "text": "\"And they shall go forth and look Upon the corpses of the men Who have transgressed against Me. For their worm does not die, And their fire is not quenched. They shall be an abhorrence to all flesh.\"", 
                "num": 24
              }
            ], 
            "num": 66
          }
        ]
      }, 
      {
        "name": "Jeremiah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The words of Jeremiah the son of Hilkiah, of the priests who were in Anathoth in the land of Benjamin,", 
                "num": 1
              }, 
              {
                "text": "to whom the word of the LORD came in the days of Josiah the son of Amon, king of Judah, in the thirteenth year of his reign.", 
                "num": 2
              }, 
              {
                "text": "It came also in the days of Jehoiakim the son of Josiah, king of Judah, until the end of the eleventh year of Zedekiah the son of Josiah, king of Judah, until the carrying away of Jerusalem captive in the fifth month.", 
                "num": 3
              }, 
              {
                "text": "Then the word of the LORD came to me, saying:", 
                "num": 4
              }, 
              {
                "text": "\"Before I formed you in the womb I knew you; Before you were born I sanctified you; I ordained you a prophet to the nations.\"", 
                "num": 5
              }, 
              {
                "text": "Then said I: \"Ah, Lord GOD! Behold, I cannot speak, for I am a youth.\"", 
                "num": 6
              }, 
              {
                "text": "But the LORD said to me: \"Do not say, \"I am a youth,' For you shall go to all to whom I send you, And whatever I command you, you shall speak.", 
                "num": 7
              }, 
              {
                "text": "Do not be afraid of their faces, For I am with you to deliver you,\" says the LORD.", 
                "num": 8
              }, 
              {
                "text": "Then the LORD put forth His hand and touched my mouth, and the LORD said to me: \"Behold, I have put My words in your mouth.", 
                "num": 9
              }, 
              {
                "text": "See, I have this day set you over the nations and over the kingdoms, To root out and to pull down, To destroy and to throw down, To build and to plant.\"", 
                "num": 10
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying, \"Jeremiah, what do you see?\" And I said, \"I see a branch of an almond tree.\"", 
                "num": 11
              }, 
              {
                "text": "Then the LORD said to me, \"You have seen well, for I am ready to perform My word.\"", 
                "num": 12
              }, 
              {
                "text": "And the word of the LORD came to me the second time, saying, \"What do you see?\" And I said, \"I see a boiling pot, and it is facing away from the north.\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD said to me: \"Out of the north calamity shall break forth On all the inhabitants of the land.", 
                "num": 14
              }, 
              {
                "text": "For behold, I am calling All the families of the kingdoms of the north,\" says the LORD; \"They shall come and each one set his throne At the entrance of the gates of Jerusalem, Against all its walls all around, And against all the cities of Judah.", 
                "num": 15
              }, 
              {
                "text": "I will utter My judgments Against them concerning all their wickedness, Because they have forsaken Me, Burned incense to other gods, And worshiped the works of their own hands.", 
                "num": 16
              }, 
              {
                "text": "\"Therefore prepare yourself and arise, And speak to them all that I command you. Do not be dismayed before their faces, Lest I dismay you before them.", 
                "num": 17
              }, 
              {
                "text": "For behold, I have made you this day A fortified city and an iron pillar, And bronze walls against the whole land-- Against the kings of Judah, Against its princes, Against its priests, And against the people of the land.", 
                "num": 18
              }, 
              {
                "text": "They will fight against you, But they shall not prevail against you. For I am with you,\" says the LORD, \"to deliver you.\"", 
                "num": 19
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Go and cry in the hearing of Jerusalem, saying, \"Thus says the LORD: \"I remember you, The kindness of your youth, The love of your betrothal, When you went after Me in the wilderness, In a land not sown.", 
                "num": 2
              }, 
              {
                "text": "Israel was holiness to the LORD, The firstfruits of His increase. All that devour him will offend; Disaster will come upon them,\" says the LORD.\"'", 
                "num": 3
              }, 
              {
                "text": "Hear the word of the LORD, O house of Jacob and all the families of the house of Israel.", 
                "num": 4
              }, 
              {
                "text": "Thus says the LORD: \"What injustice have your fathers found in Me, That they have gone far from Me, Have followed idols, And have become idolaters?", 
                "num": 5
              }, 
              {
                "text": "Neither did they say, \"Where is the LORD, Who brought us up out of the land of Egypt, Who led us through the wilderness, Through a land of deserts and pits, Through a land of drought and the shadow of death, Through a land that no one crossed And where no one dwelt?'", 
                "num": 6
              }, 
              {
                "text": "I brought you into a bountiful country, To eat its fruit and its goodness. But when you entered, you defiled My land And made My heritage an abomination.", 
                "num": 7
              }, 
              {
                "text": "The priests did not say, \"Where is the LORD?' And those who handle the law did not know Me; The rulers also transgressed against Me; The prophets prophesied by Baal, And walked after things that do not profit.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore I will yet bring charges against you,\" says the LORD, \"And against your children's children I will bring charges.", 
                "num": 9
              }, 
              {
                "text": "For pass beyond the coasts of Cyprus and see, Send to Kedar and consider diligently, And see if there has been such a thing.", 
                "num": 10
              }, 
              {
                "text": "Has a nation changed its gods, Which are not gods? But My people have changed their Glory For what does not profit.", 
                "num": 11
              }, 
              {
                "text": "Be astonished, O heavens, at this, And be horribly afraid; Be very desolate,\" says the LORD.", 
                "num": 12
              }, 
              {
                "text": "\"For My people have committed two evils: They have forsaken Me, the fountain of living waters, And hewn themselves cisterns--broken cisterns that can hold no water.", 
                "num": 13
              }, 
              {
                "text": "\"Is Israel a servant? Is he a homeborn slave? Why is he plundered?", 
                "num": 14
              }, 
              {
                "text": "The young lions roared at him, and growled; They made his land waste; His cities are burned, without inhabitant.", 
                "num": 15
              }, 
              {
                "text": "Also the people of Noph and Tahpanhes Have broken the crown of your head.", 
                "num": 16
              }, 
              {
                "text": "Have you not brought this on yourself, In that you have forsaken the LORD your God When He led you in the way?", 
                "num": 17
              }, 
              {
                "text": "And now why take the road to Egypt, To drink the waters of Sihor? Or why take the road to Assyria, To drink the waters of the River?", 
                "num": 18
              }, 
              {
                "text": "Your own wickedness will correct you, And your backslidings will rebuke you. Know therefore and see that it is an evil and bitter thing That you have forsaken the LORD your God, And the fear of Me is not in you,\" Says the Lord GOD of hosts.", 
                "num": 19
              }, 
              {
                "text": "\"For of old I have broken your yoke and burst your bonds; And you said, \"I will not transgress,' When on every high hill and under every green tree You lay down, playing the harlot.", 
                "num": 20
              }, 
              {
                "text": "Yet I had planted you a noble vine, a seed of highest quality. How then have you turned before Me Into the degenerate plant of an alien vine?", 
                "num": 21
              }, 
              {
                "text": "For though you wash yourself with lye, and use much soap, Yet your iniquity is marked before Me,\" says the Lord GOD.", 
                "num": 22
              }, 
              {
                "text": "\"How can you say, \"I am not polluted, I have not gone after the Baals'? See your way in the valley; Know what you have done: You are a swift dromedary breaking loose in her ways,", 
                "num": 23
              }, 
              {
                "text": "A wild donkey used to the wilderness, That sniffs at the wind in her desire; In her time of mating, who can turn her away? All those who seek her will not weary themselves; In her month they will find her.", 
                "num": 24
              }, 
              {
                "text": "Withhold your foot from being unshod, and your throat from thirst. But you said, \"There is no hope. No! For I have loved aliens, and after them I will go.'", 
                "num": 25
              }, 
              {
                "text": "\"As the thief is ashamed when he is found out, So is the house of Israel ashamed; They and their kings and their princes, and their priests and their prophets,", 
                "num": 26
              }, 
              {
                "text": "Saying to a tree, \"You are my father,' And to a stone, \"You gave birth to me.' For they have turned their back to Me, and not their face. But in the time of their trouble They will say, \"Arise and save us.'", 
                "num": 27
              }, 
              {
                "text": "But where are your gods that you have made for yourselves? Let them arise, If they can save you in the time of your trouble; For according to the number of your cities Are your gods, O Judah.", 
                "num": 28
              }, 
              {
                "text": "\"Why will you plead with Me? You all have transgressed against Me,\" says the LORD.", 
                "num": 29
              }, 
              {
                "text": "\"In vain I have chastened your children; They received no correction. Your sword has devoured your prophets Like a destroying lion.", 
                "num": 30
              }, 
              {
                "text": "\"O generation, see the word of the LORD! Have I been a wilderness to Israel, Or a land of darkness? Why do My people say, \"We are lords; We will come no more to You'?", 
                "num": 31
              }, 
              {
                "text": "Can a virgin forget her ornaments, Or a bride her attire? Yet My people have forgotten Me days without number.", 
                "num": 32
              }, 
              {
                "text": "\"Why do you beautify your way to seek love? Therefore you have also taught The wicked women your ways.", 
                "num": 33
              }, 
              {
                "text": "Also on your skirts is found The blood of the lives of the poor innocents. I have not found it by secret search, But plainly on all these things.", 
                "num": 34
              }, 
              {
                "text": "Yet you say, \"Because I am innocent, Surely His anger shall turn from me.' Behold, I will plead My case against you, Because you say, \"I have not sinned.'", 
                "num": 35
              }, 
              {
                "text": "Why do you gad about so much to change your way? Also you shall be ashamed of Egypt as you were ashamed of Assyria.", 
                "num": 36
              }, 
              {
                "text": "Indeed you will go forth from him With your hands on your head; For the LORD has rejected your trusted allies, And you will not prosper by them.", 
                "num": 37
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"They say, \"If a man divorces his wife, And she goes from him And becomes another man's, May he return to her again?' Would not that land be greatly polluted? But you have played the harlot with many lovers; Yet return to Me,\" says the LORD.", 
                "num": 1
              }, 
              {
                "text": "\"Lift up your eyes to the desolate heights and see: Where have you not lain with men? By the road you have sat for them Like an Arabian in the wilderness; And you have polluted the land With your harlotries and your wickedness.", 
                "num": 2
              }, 
              {
                "text": "Therefore the showers have been withheld, And there has been no latter rain. You have had a harlot's forehead; You refuse to be ashamed.", 
                "num": 3
              }, 
              {
                "text": "Will you not from this time cry to Me, \"My Father, You are the guide of my youth?", 
                "num": 4
              }, 
              {
                "text": "Will He remain angry forever? Will He keep it to the end?' Behold, you have spoken and done evil things, As you were able.\"", 
                "num": 5
              }, 
              {
                "text": "The LORD said also to me in the days of Josiah the king: \"Have you seen what backsliding Israel has done? She has gone up on every high mountain and under every green tree, and there played the harlot.", 
                "num": 6
              }, 
              {
                "text": "And I said, after she had done all these things, \"Return to Me.' But she did not return. And her treacherous sister Judah saw it.", 
                "num": 7
              }, 
              {
                "text": "Then I saw that for all the causes for which backsliding Israel had committed adultery, I had put her away and given her a certificate of divorce; yet her treacherous sister Judah did not fear, but went and played the harlot also.", 
                "num": 8
              }, 
              {
                "text": "So it came to pass, through her casual harlotry, that she defiled the land and committed adultery with stones and trees.", 
                "num": 9
              }, 
              {
                "text": "And yet for all this her treacherous sister Judah has not turned to Me with her whole heart, but in pretense,\" says the LORD.", 
                "num": 10
              }, 
              {
                "text": "Then the LORD said to me, \"Backsliding Israel has shown herself more righteous than treacherous Judah.", 
                "num": 11
              }, 
              {
                "text": "Go and proclaim these words toward the north, and say: \"Return, backsliding Israel,' says the LORD; \"I will not cause My anger to fall on you. For I am merciful,' says the LORD; \"I will not remain angry forever.", 
                "num": 12
              }, 
              {
                "text": "Only acknowledge your iniquity, That you have transgressed against the LORD your God, And have scattered your charms To alien deities under every green tree, And you have not obeyed My voice,' says the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"Return, O backsliding children,\" says the LORD; \"for I am married to you. I will take you, one from a city and two from a family, and I will bring you to Zion.", 
                "num": 14
              }, 
              {
                "text": "And I will give you shepherds according to My heart, who will feed you with knowledge and understanding.", 
                "num": 15
              }, 
              {
                "text": "\"Then it shall come to pass, when you are multiplied and increased in the land in those days,\" says the LORD, \"that they will say no more, \"The ark of the covenant of the LORD.' It shall not come to mind, nor shall they remember it, nor shall they visit it, nor shall it be made anymore.", 
                "num": 16
              }, 
              {
                "text": "\"At that time Jerusalem shall be called The Throne of the LORD, and all the nations shall be gathered to it, to the name of the LORD, to Jerusalem. No more shall they follow the dictates of their evil hearts.", 
                "num": 17
              }, 
              {
                "text": "\"In those days the house of Judah shall walk with the house of Israel, and they shall come together out of the land of the north to the land that I have given as an inheritance to your fathers.", 
                "num": 18
              }, 
              {
                "text": "\"But I said: \"How can I put you among the children And give you a pleasant land, A beautiful heritage of the hosts of nations?' \"And I said: \"You shall call Me, \"My Father,\" And not turn away from Me.'", 
                "num": 19
              }, 
              {
                "text": "Surely, as a wife treacherously departs from her husband, So have you dealt treacherously with Me, O house of Israel,\" says the LORD.", 
                "num": 20
              }, 
              {
                "text": "A voice was heard on the desolate heights, Weeping and supplications of the children of Israel. For they have perverted their way; They have forgotten the LORD their God.", 
                "num": 21
              }, 
              {
                "text": "\"Return, you backsliding children, And I will heal your backslidings.\" \"Indeed we do come to You, For You are the LORD our God.", 
                "num": 22
              }, 
              {
                "text": "Truly, in vain is salvation hoped for from the hills, And from the multitude of mountains; Truly, in the LORD our God Is the salvation of Israel.", 
                "num": 23
              }, 
              {
                "text": "For shame has devoured The labor of our fathers from our youth-- Their flocks and their herds, Their sons and their daughters.", 
                "num": 24
              }, 
              {
                "text": "We lie down in our shame, And our reproach covers us. For we have sinned against the LORD our God, We and our fathers, From our youth even to this day, And have not obeyed the voice of the LORD our God.\"", 
                "num": 25
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "\"If you will return, O Israel,\" says the LORD, \"Return to Me; And if you will put away your abominations out of My sight, Then you shall not be moved.", 
                "num": 1
              }, 
              {
                "text": "And you shall swear, \"The LORD lives,' In truth, in judgment, and in righteousness; The nations shall bless themselves in Him, And in Him they shall glory.\"", 
                "num": 2
              }, 
              {
                "text": "For thus says the LORD to the men of Judah and Jerusalem: \"Break up your fallow ground, And do not sow among thorns.", 
                "num": 3
              }, 
              {
                "text": "Circumcise yourselves to the LORD, And take away the foreskins of your hearts, You men of Judah and inhabitants of Jerusalem, Lest My fury come forth like fire, And burn so that no one can quench it, Because of the evil of your doings.\"", 
                "num": 4
              }, 
              {
                "text": "Declare in Judah and proclaim in Jerusalem, and say: \"Blow the trumpet in the land; Cry, \"Gather together,' And say, \"Assemble yourselves, And let us go into the fortified cities.'", 
                "num": 5
              }, 
              {
                "text": "Set up the standard toward Zion. Take refuge! Do not delay! For I will bring disaster from the north, And great destruction.\"", 
                "num": 6
              }, 
              {
                "text": "The lion has come up from his thicket, And the destroyer of nations is on his way. He has gone forth from his place To make your land desolate. Your cities will be laid waste, Without inhabitant.", 
                "num": 7
              }, 
              {
                "text": "For this, clothe yourself with sackcloth, Lament and wail. For the fierce anger of the LORD Has not turned back from us.", 
                "num": 8
              }, 
              {
                "text": "\"And it shall come to pass in that day,\" says the LORD, \"That the heart of the king shall perish, And the heart of the princes; The priests shall be astonished, And the prophets shall wonder.\"", 
                "num": 9
              }, 
              {
                "text": "Then I said, \"Ah, Lord GOD! Surely You have greatly deceived this people and Jerusalem, Saying, \"You shall have peace,' Whereas the sword reaches to the heart.\"", 
                "num": 10
              }, 
              {
                "text": "At that time it will be said To this people and to Jerusalem, \"A dry wind of the desolate heights blows in the wilderness Toward the daughter of My people-- Not to fan or to cleanse--", 
                "num": 11
              }, 
              {
                "text": "A wind too strong for these will come for Me; Now I will also speak judgment against them.\"", 
                "num": 12
              }, 
              {
                "text": "\"Behold, he shall come up like clouds, And his chariots like a whirlwind. His horses are swifter than eagles. Woe to us, for we are plundered!\"", 
                "num": 13
              }, 
              {
                "text": "O Jerusalem, wash your heart from wickedness, That you may be saved. How long shall your evil thoughts lodge within you?", 
                "num": 14
              }, 
              {
                "text": "For a voice declares from Dan And proclaims affliction from Mount Ephraim:", 
                "num": 15
              }, 
              {
                "text": "\"Make mention to the nations, Yes, proclaim against Jerusalem, That watchers come from a far country And raise their voice against the cities of Judah.", 
                "num": 16
              }, 
              {
                "text": "Like keepers of a field they are against her all around, Because she has been rebellious against Me,\" says the LORD.", 
                "num": 17
              }, 
              {
                "text": "\"Your ways and your doings Have procured these things for you. This is your wickedness, Because it is bitter, Because it reaches to your heart.\"", 
                "num": 18
              }, 
              {
                "text": "O my soul, my soul! I am pained in my very heart! My heart makes a noise in me; I cannot hold my peace, Because you have heard, O my soul, The sound of the trumpet, The alarm of war.", 
                "num": 19
              }, 
              {
                "text": "Destruction upon destruction is cried, For the whole land is plundered. Suddenly my tents are plundered, And my curtains in a moment.", 
                "num": 20
              }, 
              {
                "text": "How long will I see the standard, And hear the sound of the trumpet?", 
                "num": 21
              }, 
              {
                "text": "\"For My people are foolish, They have not known Me. They are silly children, And they have no understanding. They are wise to do evil, But to do good they have no knowledge.\"", 
                "num": 22
              }, 
              {
                "text": "I beheld the earth, and indeed it was without form, and void; And the heavens, they had no light.", 
                "num": 23
              }, 
              {
                "text": "I beheld the mountains, and indeed they trembled, And all the hills moved back and forth.", 
                "num": 24
              }, 
              {
                "text": "I beheld, and indeed there was no man, And all the birds of the heavens had fled.", 
                "num": 25
              }, 
              {
                "text": "I beheld, and indeed the fruitful land was a wilderness, And all its cities were broken down At the presence of the LORD, By His fierce anger.", 
                "num": 26
              }, 
              {
                "text": "For thus says the LORD: \"The whole land shall be desolate; Yet I will not make a full end.", 
                "num": 27
              }, 
              {
                "text": "For this shall the earth mourn, And the heavens above be black, Because I have spoken. I have purposed and will not relent, Nor will I turn back from it.", 
                "num": 28
              }, 
              {
                "text": "The whole city shall flee from the noise of the horsemen and bowmen. They shall go into thickets and climb up on the rocks. Every city shall be forsaken, And not a man shall dwell in it.", 
                "num": 29
              }, 
              {
                "text": "\"And when you are plundered, What will you do? Though you clothe yourself with crimson, Though you adorn yourself with ornaments of gold, Though you enlarge your eyes with paint, In vain you will make yourself fair; Your lovers will despise you; They will seek your life.", 
                "num": 30
              }, 
              {
                "text": "\"For I have heard a voice as of a woman in labor, The anguish as of her who brings forth her first child, The voice of the daughter of Zion bewailing herself; She spreads her hands, saying, \"Woe is me now, for my soul is weary Because of murderers!'", 
                "num": 31
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "\"Run to and fro through the streets of Jerusalem; See now and know; And seek in her open places If you can find a man, If there is anyone who executes judgment, Who seeks the truth, And I will pardon her.", 
                "num": 1
              }, 
              {
                "text": "Though they say, \"As the LORD lives,' Surely they swear falsely.\"", 
                "num": 2
              }, 
              {
                "text": "O LORD, are not Your eyes on the truth? You have stricken them, But they have not grieved; You have consumed them, But they have refused to receive correction. They have made their faces harder than rock; They have refused to return.", 
                "num": 3
              }, 
              {
                "text": "Therefore I said, \"Surely these are poor. They are foolish; For they do not know the way of the LORD, The judgment of their God.", 
                "num": 4
              }, 
              {
                "text": "I will go to the great men and speak to them, For they have known the way of the LORD, The judgment of their God.\" But these have altogether broken the yoke And burst the bonds.", 
                "num": 5
              }, 
              {
                "text": "Therefore a lion from the forest shall slay them, A wolf of the deserts shall destroy them; A leopard will watch over their cities. Everyone who goes out from there shall be torn in pieces, Because their transgressions are many; Their backslidings have increased.", 
                "num": 6
              }, 
              {
                "text": "\"How shall I pardon you for this? Your children have forsaken Me And sworn by those that are not gods. When I had fed them to the full, Then they committed adultery And assembled themselves by troops in the harlots' houses.", 
                "num": 7
              }, 
              {
                "text": "They were like well-fed lusty stallions; Every one neighed after his neighbor's wife.", 
                "num": 8
              }, 
              {
                "text": "Shall I not punish them for these things?\" says the LORD. \"And shall I not avenge Myself on such a nation as this?", 
                "num": 9
              }, 
              {
                "text": "\"Go up on her walls and destroy, But do not make a complete end. Take away her branches, For they are not the LORD's.", 
                "num": 10
              }, 
              {
                "text": "For the house of Israel and the house of Judah Have dealt very treacherously with Me,\" says the LORD.", 
                "num": 11
              }, 
              {
                "text": "They have lied about the LORD, And said, \"It is not He. Neither will evil come upon us, Nor shall we see sword or famine.", 
                "num": 12
              }, 
              {
                "text": "And the prophets become wind, For the word is not in them. Thus shall it be done to them.\"", 
                "num": 13
              }, 
              {
                "text": "Therefore thus says the LORD God of hosts: \"Because you speak this word, Behold, I will make My words in your mouth fire, And this people wood, And it shall devour them.", 
                "num": 14
              }, 
              {
                "text": "Behold, I will bring a nation against you from afar, O house of Israel,\" says the LORD. \"It is a mighty nation, It is an ancient nation, A nation whose language you do not know, Nor can you understand what they say.", 
                "num": 15
              }, 
              {
                "text": "Their quiver is like an open tomb; They are all mighty men.", 
                "num": 16
              }, 
              {
                "text": "And they shall eat up your harvest and your bread, Which your sons and daughters should eat. They shall eat up your flocks and your herds; They shall eat up your vines and your fig trees; They shall destroy your fortified cities, In which you trust, with the sword.", 
                "num": 17
              }, 
              {
                "text": "\"Nevertheless in those days,\" says the LORD, \"I will not make a complete end of you.", 
                "num": 18
              }, 
              {
                "text": "And it will be when you say, \"Why does the LORD our God do all these things to us?' then you shall answer them, \"Just as you have forsaken Me and served foreign gods in your land, so you shall serve aliens in a land that is not yours.'", 
                "num": 19
              }, 
              {
                "text": "\"Declare this in the house of Jacob And proclaim it in Judah, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Hear this now, O foolish people, Without understanding, Who have eyes and see not, And who have ears and hear not:", 
                "num": 21
              }, 
              {
                "text": "Do you not fear Me?' says the LORD. \"Will you not tremble at My presence, Who have placed the sand as the bound of the sea, By a perpetual decree, that it cannot pass beyond it? And though its waves toss to and fro, Yet they cannot prevail; Though they roar, yet they cannot pass over it.", 
                "num": 22
              }, 
              {
                "text": "But this people has a defiant and rebellious heart; They have revolted and departed.", 
                "num": 23
              }, 
              {
                "text": "They do not say in their heart, \"Let us now fear the LORD our God, Who gives rain, both the former and the latter, in its season. He reserves for us the appointed weeks of the harvest.\"", 
                "num": 24
              }, 
              {
                "text": "Your iniquities have turned these things away, And your sins have withheld good from you.", 
                "num": 25
              }, 
              {
                "text": "\"For among My people are found wicked men; They lie in wait as one who sets snares; They set a trap; They catch men.", 
                "num": 26
              }, 
              {
                "text": "As a cage is full of birds, So their houses are full of deceit. Therefore they have become great and grown rich.", 
                "num": 27
              }, 
              {
                "text": "They have grown fat, they are sleek; Yes, they surpass the deeds of the wicked; They do not plead the cause, The cause of the fatherless; Yet they prosper, And the right of the needy they do not defend.", 
                "num": 28
              }, 
              {
                "text": "Shall I not punish them for these things?' says the LORD. \"Shall I not avenge Myself on such a nation as this?'", 
                "num": 29
              }, 
              {
                "text": "\"An astonishing and horrible thing Has been committed in the land:", 
                "num": 30
              }, 
              {
                "text": "The prophets prophesy falsely, And the priests rule by their own power; And My people love to have it so. But what will you do in the end?", 
                "num": 31
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "\"O you children of Benjamin, Gather yourselves to flee from the midst of Jerusalem! Blow the trumpet in Tekoa, And set up a signal-fire in Beth Haccerem; For disaster appears out of the north, And great destruction.", 
                "num": 1
              }, 
              {
                "text": "I have likened the daughter of Zion To a lovely and delicate woman.", 
                "num": 2
              }, 
              {
                "text": "The shepherds with their flocks shall come to her. They shall pitch their tents against her all around. Each one shall pasture in his own place.\"", 
                "num": 3
              }, 
              {
                "text": "\"Prepare war against her; Arise, and let us go up at noon. Woe to us, for the day goes away, For the shadows of the evening are lengthening.", 
                "num": 4
              }, 
              {
                "text": "Arise, and let us go by night, And let us destroy her palaces.\"", 
                "num": 5
              }, 
              {
                "text": "For thus has the LORD of hosts said: \"Cut down trees, And build a mound against Jerusalem. This is the city to be punished. She is full of oppression in her midst.", 
                "num": 6
              }, 
              {
                "text": "As a fountain wells up with water, So she wells up with her wickedness. Violence and plundering are heard in her. Before Me continually are grief and wounds.", 
                "num": 7
              }, 
              {
                "text": "Be instructed, O Jerusalem, Lest My soul depart from you; Lest I make you desolate, A land not inhabited.\"", 
                "num": 8
              }, 
              {
                "text": "Thus says the LORD of hosts: \"They shall thoroughly glean as a vine the remnant of Israel; As a grape-gatherer, put your hand back into the branches.\"", 
                "num": 9
              }, 
              {
                "text": "To whom shall I speak and give warning, That they may hear? Indeed their ear is uncircumcised, And they cannot give heed. Behold, the word of the LORD is a reproach to them; They have no delight in it.", 
                "num": 10
              }, 
              {
                "text": "Therefore I am full of the fury of the LORD. I am weary of holding it in. \"I will pour it out on the children outside, And on the assembly of young men together; For even the husband shall be taken with the wife, The aged with him who is full of days.", 
                "num": 11
              }, 
              {
                "text": "And their houses shall be turned over to others, Fields and wives together; For I will stretch out My hand Against the inhabitants of the land,\" says the LORD.", 
                "num": 12
              }, 
              {
                "text": "\"Because from the least of them even to the greatest of them, Everyone is given to covetousness; And from the prophet even to the priest, Everyone deals falsely.", 
                "num": 13
              }, 
              {
                "text": "They have also healed the hurt of My people slightly, Saying, \"Peace, peace!' When there is no peace.", 
                "num": 14
              }, 
              {
                "text": "Were they ashamed when they had committed abomination? No! They were not at all ashamed; Nor did they know how to blush. Therefore they shall fall among those who fall; At the time I punish them, They shall be cast down,\" says the LORD.", 
                "num": 15
              }, 
              {
                "text": "Thus says the LORD: \"Stand in the ways and see, And ask for the old paths, where the good way is, And walk in it; Then you will find rest for your souls. But they said, \"We will not walk in it.'", 
                "num": 16
              }, 
              {
                "text": "Also, I set watchmen over you, saying, \"Listen to the sound of the trumpet!' But they said, \"We will not listen.'", 
                "num": 17
              }, 
              {
                "text": "Therefore hear, you nations, And know, O congregation, what is among them.", 
                "num": 18
              }, 
              {
                "text": "Hear, O earth! Behold, I will certainly bring calamity on this people-- The fruit of their thoughts, Because they have not heeded My words Nor My law, but rejected it.", 
                "num": 19
              }, 
              {
                "text": "For what purpose to Me Comes frankincense from Sheba, And sweet cane from a far country? Your burnt offerings are not acceptable, Nor your sacrifices sweet to Me.\"", 
                "num": 20
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, I will lay stumbling blocks before this people, And the fathers and the sons together shall fall on them. The neighbor and his friend shall perish.\"", 
                "num": 21
              }, 
              {
                "text": "Thus says the LORD: \"Behold, a people comes from the north country, And a great nation will be raised from the farthest parts of the earth.", 
                "num": 22
              }, 
              {
                "text": "They will lay hold on bow and spear; They are cruel and have no mercy; Their voice roars like the sea; And they ride on horses, As men of war set in array against you, O daughter of Zion.\"", 
                "num": 23
              }, 
              {
                "text": "We have heard the report of it; Our hands grow feeble. Anguish has taken hold of us, Pain as of a woman in labor.", 
                "num": 24
              }, 
              {
                "text": "Do not go out into the field, Nor walk by the way. Because of the sword of the enemy, Fear is on every side.", 
                "num": 25
              }, 
              {
                "text": "O daughter of my people, Dress in sackcloth And roll about in ashes! Make mourning as for an only son, most bitter lamentation; For the plunderer will suddenly come upon us.", 
                "num": 26
              }, 
              {
                "text": "\"I have set you as an assayer and a fortress among My people, That you may know and test their way.", 
                "num": 27
              }, 
              {
                "text": "They are all stubborn rebels, walking as slanderers. They are bronze and iron, They are all corrupters;", 
                "num": 28
              }, 
              {
                "text": "The bellows blow fiercely, The lead is consumed by the fire; The smelter refines in vain, For the wicked are not drawn off.", 
                "num": 29
              }, 
              {
                "text": "People will call them rejected silver, Because the LORD has rejected them.\"", 
                "num": 30
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah from the LORD, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Stand in the gate of the LORD's house, and proclaim there this word, and say, \"Hear the word of the LORD, all you of Judah who enter in at these gates to worship the LORD!\"'", 
                "num": 2
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel: \"Amend your ways and your doings, and I will cause you to dwell in this place.", 
                "num": 3
              }, 
              {
                "text": "Do not trust in these lying words, saying, \"The temple of the LORD, the temple of the LORD, the temple of the LORD are these.'", 
                "num": 4
              }, 
              {
                "text": "\"For if you thoroughly amend your ways and your doings, if you thoroughly execute judgment between a man and his neighbor,", 
                "num": 5
              }, 
              {
                "text": "if you do not oppress the stranger, the fatherless, and the widow, and do not shed innocent blood in this place, or walk after other gods to your hurt,", 
                "num": 6
              }, 
              {
                "text": "then I will cause you to dwell in this place, in the land that I gave to your fathers forever and ever.", 
                "num": 7
              }, 
              {
                "text": "\"Behold, you trust in lying words that cannot profit.", 
                "num": 8
              }, 
              {
                "text": "Will you steal, murder, commit adultery, swear falsely, burn incense to Baal, and walk after other gods whom you do not know,", 
                "num": 9
              }, 
              {
                "text": "and then come and stand before Me in this house which is called by My name, and say, \"We are delivered to do all these abominations'?", 
                "num": 10
              }, 
              {
                "text": "Has this house, which is called by My name, become a den of thieves in your eyes? Behold, I, even I, have seen it,\" says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"But go now to My place which was in Shiloh, where I set My name at the first, and see what I did to it because of the wickedness of My people Israel.", 
                "num": 12
              }, 
              {
                "text": "And now, because you have done all these works,\" says the LORD, \"and I spoke to you, rising up early and speaking, but you did not hear, and I called you, but you did not answer,", 
                "num": 13
              }, 
              {
                "text": "therefore I will do to the house which is called by My name, in which you trust, and to this place which I gave to you and your fathers, as I have done to Shiloh.", 
                "num": 14
              }, 
              {
                "text": "And I will cast you out of My sight, as I have cast out all your brethren--the whole posterity of Ephraim.", 
                "num": 15
              }, 
              {
                "text": "\"Therefore do not pray for this people, nor lift up a cry or prayer for them, nor make intercession to Me; for I will not hear you.", 
                "num": 16
              }, 
              {
                "text": "Do you not see what they do in the cities of Judah and in the streets of Jerusalem?", 
                "num": 17
              }, 
              {
                "text": "The children gather wood, the fathers kindle the fire, and the women knead dough, to make cakes for the queen of heaven; and they pour out drink offerings to other gods, that they may provoke Me to anger.", 
                "num": 18
              }, 
              {
                "text": "Do they provoke Me to anger?\" says the LORD. \"Do they not provoke themselves, to the shame of their own faces?\"", 
                "num": 19
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Behold, My anger and My fury will be poured out on this place--on man and on beast, on the trees of the field and on the fruit of the ground. And it will burn and not be quenched.\"", 
                "num": 20
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel: \"Add your burnt offerings to your sacrifices and eat meat.", 
                "num": 21
              }, 
              {
                "text": "For I did not speak to your fathers, or command them in the day that I brought them out of the land of Egypt, concerning burnt offerings or sacrifices.", 
                "num": 22
              }, 
              {
                "text": "But this is what I commanded them, saying, \"Obey My voice, and I will be your God, and you shall be My people. And walk in all the ways that I have commanded you, that it may be well with you.'", 
                "num": 23
              }, 
              {
                "text": "Yet they did not obey or incline their ear, but followed the counsels and the dictates of their evil hearts, and went backward and not forward.", 
                "num": 24
              }, 
              {
                "text": "Since the day that your fathers came out of the land of Egypt until this day, I have even sent to you all My servants the prophets, daily rising up early and sending them.", 
                "num": 25
              }, 
              {
                "text": "Yet they did not obey Me or incline their ear, but stiffened their neck. They did worse than their fathers.", 
                "num": 26
              }, 
              {
                "text": "\"Therefore you shall speak all these words to them, but they will not obey you. You shall also call to them, but they will not answer you.", 
                "num": 27
              }, 
              {
                "text": "\"So you shall say to them, \"This is a nation that does not obey the voice of the LORD their God nor receive correction. Truth has perished and has been cut off from their mouth.", 
                "num": 28
              }, 
              {
                "text": "Cut off your hair and cast it away, and take up a lamentation on the desolate heights; for the LORD has rejected and forsaken the generation of His wrath.'", 
                "num": 29
              }, 
              {
                "text": "For the children of Judah have done evil in My sight,\" says the LORD. \"They have set their abominations in the house which is called by My name, to pollute it.", 
                "num": 30
              }, 
              {
                "text": "And they have built the high places of Tophet, which is in the Valley of the Son of Hinnom, to burn their sons and their daughters in the fire, which I did not command, nor did it come into My heart.", 
                "num": 31
              }, 
              {
                "text": "\"Therefore behold, the days are coming,\" says the LORD, \"when it will no more be called Tophet, or the Valley of the Son of Hinnom, but the Valley of Slaughter; for they will bury in Tophet until there is no room.", 
                "num": 32
              }, 
              {
                "text": "The corpses of this people will be food for the birds of the heaven and for the beasts of the earth. And no one will frighten them away.", 
                "num": 33
              }, 
              {
                "text": "Then I will cause to cease from the cities of Judah and from the streets of Jerusalem the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride. For the land shall be desolate.", 
                "num": 34
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "\"At that time,\" says the LORD, \"they shall bring out the bones of the kings of Judah, and the bones of its princes, and the bones of the priests, and the bones of the prophets, and the bones of the inhabitants of Jerusalem, out of their graves.", 
                "num": 1
              }, 
              {
                "text": "They shall spread them before the sun and the moon and all the host of heaven, which they have loved and which they have served and after which they have walked, which they have sought and which they have worshiped. They shall not be gathered nor buried; they shall be like refuse on the face of the earth.", 
                "num": 2
              }, 
              {
                "text": "Then death shall be chosen rather than life by all the residue of those who remain of this evil family, who remain in all the places where I have driven them,\" says the LORD of hosts.", 
                "num": 3
              }, 
              {
                "text": "\"Moreover you shall say to them, \"Thus says the LORD: \"Will they fall and not rise? Will one turn away and not return?", 
                "num": 4
              }, 
              {
                "text": "Why has this people slidden back, Jerusalem, in a perpetual backsliding? They hold fast to deceit, They refuse to return.", 
                "num": 5
              }, 
              {
                "text": "I listened and heard, But they do not speak aright. No man repented of his wickedness, Saying, \"What have I done?' Everyone turned to his own course, As the horse rushes into the battle.", 
                "num": 6
              }, 
              {
                "text": "\"Even the stork in the heavens Knows her appointed times; And the turtledove, the swift, and the swallow Observe the time of their coming. But My people do not know the judgment of the LORD.", 
                "num": 7
              }, 
              {
                "text": "\"How can you say, \"We are wise, And the law of the LORD is with us'? Look, the false pen of the scribe certainly works falsehood.", 
                "num": 8
              }, 
              {
                "text": "The wise men are ashamed, They are dismayed and taken. Behold, they have rejected the word of the LORD; So what wisdom do they have?", 
                "num": 9
              }, 
              {
                "text": "Therefore I will give their wives to others, And their fields to those who will inherit them; Because from the least even to the greatest Everyone is given to covetousness; From the prophet even to the priest Everyone deals falsely.", 
                "num": 10
              }, 
              {
                "text": "For they have healed the hurt of the daughter of My people slightly, Saying, \"Peace, peace!' When there is no peace.", 
                "num": 11
              }, 
              {
                "text": "Were they ashamed when they had committed abomination? No! They were not at all ashamed, Nor did they know how to blush. Therefore they shall fall among those who fall; In the time of their punishment They shall be cast down,\" says the LORD.", 
                "num": 12
              }, 
              {
                "text": "\"I will surely consume them,\" says the LORD. \"No grapes shall be on the vine, Nor figs on the fig tree, And the leaf shall fade; And the things I have given them shall pass away from them.\"\"'", 
                "num": 13
              }, 
              {
                "text": "\"Why do we sit still? Assemble yourselves, And let us enter the fortified cities, And let us be silent there. For the LORD our God has put us to silence And given us water of gall to drink, Because we have sinned against the LORD.", 
                "num": 14
              }, 
              {
                "text": "\"We looked for peace, but no good came; And for a time of health, and there was trouble!", 
                "num": 15
              }, 
              {
                "text": "The snorting of His horses was heard from Dan. The whole land trembled at the sound of the neighing of His strong ones; For they have come and devoured the land and all that is in it, The city and those who dwell in it.\"", 
                "num": 16
              }, 
              {
                "text": "\"For behold, I will send serpents among you, Vipers which cannot be charmed, And they shall bite you,\" says the LORD.", 
                "num": 17
              }, 
              {
                "text": "I would comfort myself in sorrow; My heart is faint in me.", 
                "num": 18
              }, 
              {
                "text": "Listen! The voice, The cry of the daughter of my people From a far country: \"Is not the LORD in Zion? Is not her King in her?\" \"Why have they provoked Me to anger With their carved images-- With foreign idols?\"", 
                "num": 19
              }, 
              {
                "text": "\"The harvest is past, The summer is ended, And we are not saved!\"", 
                "num": 20
              }, 
              {
                "text": "For the hurt of the daughter of my people I am hurt. I am mourning; Astonishment has taken hold of me.", 
                "num": 21
              }, 
              {
                "text": "Is there no balm in Gilead, Is there no physician there? Why then is there no recovery For the health of the daughter of my people?", 
                "num": 22
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Oh, that my head were waters, And my eyes a fountain of tears, That I might weep day and night For the slain of the daughter of my people!", 
                "num": 1
              }, 
              {
                "text": "Oh, that I had in the wilderness A lodging place for travelers; That I might leave my people, And go from them! For they are all adulterers, An assembly of treacherous men.", 
                "num": 2
              }, 
              {
                "text": "\"And like their bow they have bent their tongues for lies. They are not valiant for the truth on the earth. For they proceed from evil to evil, And they do not know Me,\" says the LORD.", 
                "num": 3
              }, 
              {
                "text": "\"Everyone take heed to his neighbor, And do not trust any brother; For every brother will utterly supplant, And every neighbor will walk with slanderers.", 
                "num": 4
              }, 
              {
                "text": "Everyone will deceive his neighbor, And will not speak the truth; They have taught their tongue to speak lies; They weary themselves to commit iniquity.", 
                "num": 5
              }, 
              {
                "text": "Your dwelling place is in the midst of deceit; Through deceit they refuse to know Me,\" says the LORD.", 
                "num": 6
              }, 
              {
                "text": "Therefore thus says the LORD of hosts: \"Behold, I will refine them and try them; For how shall I deal with the daughter of My people?", 
                "num": 7
              }, 
              {
                "text": "Their tongue is an arrow shot out; It speaks deceit; One speaks peaceably to his neighbor with his mouth, But in his heart he lies in wait.", 
                "num": 8
              }, 
              {
                "text": "Shall I not punish them for these things?\" says the LORD. \"Shall I not avenge Myself on such a nation as this?\"", 
                "num": 9
              }, 
              {
                "text": "I will take up a weeping and wailing for the mountains, And for the dwelling places of the wilderness a lamentation, Because they are burned up, So that no one can pass through; Nor can men hear the voice of the cattle. Both the birds of the heavens and the beasts have fled; They are gone.", 
                "num": 10
              }, 
              {
                "text": "\"I will make Jerusalem a heap of ruins, a den of jackals. I will make the cities of Judah desolate, without an inhabitant.\"", 
                "num": 11
              }, 
              {
                "text": "Who is the wise man who may understand this? And who is he to whom the mouth of the LORD has spoken, that he may declare it? Why does the land perish and burn up like a wilderness, so that no one can pass through?", 
                "num": 12
              }, 
              {
                "text": "And the LORD said, \"Because they have forsaken My law which I set before them, and have not obeyed My voice, nor walked according to it,", 
                "num": 13
              }, 
              {
                "text": "but they have walked according to the dictates of their own hearts and after the Baals, which their fathers taught them,\"", 
                "num": 14
              }, 
              {
                "text": "therefore thus says the LORD of hosts, the God of Israel: \"Behold, I will feed them, this people, with wormwood, and give them water of gall to drink.", 
                "num": 15
              }, 
              {
                "text": "I will scatter them also among the Gentiles, whom neither they nor their fathers have known. And I will send a sword after them until I have consumed them.\"", 
                "num": 16
              }, 
              {
                "text": "Thus says the LORD of hosts: \"Consider and call for the mourning women, That they may come; And send for skillful wailing women, That they may come.", 
                "num": 17
              }, 
              {
                "text": "Let them make haste And take up a wailing for us, That our eyes may run with tears, And our eyelids gush with water.", 
                "num": 18
              }, 
              {
                "text": "For a voice of wailing is heard from Zion: \"How we are plundered! We are greatly ashamed, Because we have forsaken the land, Because we have been cast out of our dwellings.\"'", 
                "num": 19
              }, 
              {
                "text": "Yet hear the word of the LORD, O women, And let your ear receive the word of His mouth; Teach your daughters wailing, And everyone her neighbor a lamentation.", 
                "num": 20
              }, 
              {
                "text": "For death has come through our windows, Has entered our palaces, To kill off the children--no longer to be outside! And the young men--no longer on the streets!", 
                "num": 21
              }, 
              {
                "text": "Speak, \"Thus says the LORD: \"Even the carcasses of men shall fall as refuse on the open field, Like cuttings after the harvester, And no one shall gather them.\"'", 
                "num": 22
              }, 
              {
                "text": "Thus says the LORD: \"Let not the wise man glory in his wisdom, Let not the mighty man glory in his might, Nor let the rich man glory in his riches;", 
                "num": 23
              }, 
              {
                "text": "But let him who glories glory in this, That he understands and knows Me, That I am the LORD, exercising lovingkindness, judgment, and righteousness in the earth. For in these I delight,\" says the LORD.", 
                "num": 24
              }, 
              {
                "text": "\"Behold, the days are coming,\" says the LORD, \"that I will punish all who are circumcised with the uncircumcised--", 
                "num": 25
              }, 
              {
                "text": "Egypt, Judah, Edom, the people of Ammon, Moab, and all who are in the farthest corners, who dwell in the wilderness. For all these nations are uncircumcised, and all the house of Israel are uncircumcised in the heart.\"", 
                "num": 26
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Hear the word which the LORD speaks to you, O house of Israel.", 
                "num": 1
              }, 
              {
                "text": "Thus says the LORD: \"Do not learn the way of the Gentiles; Do not be dismayed at the signs of heaven, For the Gentiles are dismayed at them.", 
                "num": 2
              }, 
              {
                "text": "For the customs of the peoples are futile; For one cuts a tree from the forest, The work of the hands of the workman, with the ax.", 
                "num": 3
              }, 
              {
                "text": "They decorate it with silver and gold; They fasten it with nails and hammers So that it will not topple.", 
                "num": 4
              }, 
              {
                "text": "They are upright, like a palm tree, And they cannot speak; They must be carried, Because they cannot go by themselves. Do not be afraid of them, For they cannot do evil, Nor can they do any good.\"", 
                "num": 5
              }, 
              {
                "text": "Inasmuch as there is none like You, O LORD (You are great, and Your name is great in might),", 
                "num": 6
              }, 
              {
                "text": "Who would not fear You, O King of the nations? For this is Your rightful due. For among all the wise men of the nations, And in all their kingdoms, There is none like You.", 
                "num": 7
              }, 
              {
                "text": "But they are altogether dull-hearted and foolish; A wooden idol is a worthless doctrine.", 
                "num": 8
              }, 
              {
                "text": "Silver is beaten into plates; It is brought from Tarshish, And gold from Uphaz, The work of the craftsman And of the hands of the metalsmith; Blue and purple are their clothing; They are all the work of skillful men.", 
                "num": 9
              }, 
              {
                "text": "But the LORD is the true God; He is the living God and the everlasting King. At His wrath the earth will tremble, And the nations will not be able to endure His indignation.", 
                "num": 10
              }, 
              {
                "text": "Thus you shall say to them: \"The gods that have not made the heavens and the earth shall perish from the earth and from under these heavens.\"", 
                "num": 11
              }, 
              {
                "text": "He has made the earth by His power, He has established the world by His wisdom, And has stretched out the heavens at His discretion.", 
                "num": 12
              }, 
              {
                "text": "When He utters His voice, There is a multitude of waters in the heavens: \"And He causes the vapors to ascend from the ends of the earth. He makes lightning for the rain, He brings the wind out of His treasuries.\"", 
                "num": 13
              }, 
              {
                "text": "Everyone is dull-hearted, without knowledge; Every metalsmith is put to shame by an image; For his molded image is falsehood, And there is no breath in them.", 
                "num": 14
              }, 
              {
                "text": "They are futile, a work of errors; In the time of their punishment they shall perish.", 
                "num": 15
              }, 
              {
                "text": "The Portion of Jacob is not like them, For He is the Maker of all things, And Israel is the tribe of His inheritance; The LORD of hosts is His name.", 
                "num": 16
              }, 
              {
                "text": "Gather up your wares from the land, O inhabitant of the fortress!", 
                "num": 17
              }, 
              {
                "text": "For thus says the LORD: \"Behold, I will throw out at this time The inhabitants of the land, And will distress them, That they may find it so.\"", 
                "num": 18
              }, 
              {
                "text": "Woe is me for my hurt! My wound is severe. But I say, \"Truly this is an infirmity, And I must bear it.\"", 
                "num": 19
              }, 
              {
                "text": "My tent is plundered, And all my cords are broken; My children have gone from me, And they are no more. There is no one to pitch my tent anymore, Or set up my curtains.", 
                "num": 20
              }, 
              {
                "text": "For the shepherds have become dull-hearted, And have not sought the LORD; Therefore they shall not prosper, And all their flocks shall be scattered.", 
                "num": 21
              }, 
              {
                "text": "Behold, the noise of the report has come, And a great commotion out of the north country, To make the cities of Judah desolate, a den of jackals.", 
                "num": 22
              }, 
              {
                "text": "O LORD, I know the way of man is not in himself; It is not in man who walks to direct his own steps.", 
                "num": 23
              }, 
              {
                "text": "O LORD, correct me, but with justice; Not in Your anger, lest You bring me to nothing.", 
                "num": 24
              }, 
              {
                "text": "Pour out Your fury on the Gentiles, who do not know You, And on the families who do not call on Your name; For they have eaten up Jacob, Devoured him and consumed him, And made his dwelling place desolate.", 
                "num": 25
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah from the LORD, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Hear the words of this covenant, and speak to the men of Judah and to the inhabitants of Jerusalem;", 
                "num": 2
              }, 
              {
                "text": "and say to them, \"Thus says the LORD God of Israel: \"Cursed is the man who does not obey the words of this covenant", 
                "num": 3
              }, 
              {
                "text": "which I commanded your fathers in the day I brought them out of the land of Egypt, from the iron furnace, saying, \"Obey My voice, and do according to all that I command you; so shall you be My people, and I will be your God,'", 
                "num": 4
              }, 
              {
                "text": "that I may establish the oath which I have sworn to your fathers, to give them \"a land flowing with milk and honey,' as it is this day.\"\"' And I answered and said, \"So be it, LORD.\"", 
                "num": 5
              }, 
              {
                "text": "Then the LORD said to me, \"Proclaim all these words in the cities of Judah and in the streets of Jerusalem, saying: \"Hear the words of this covenant and do them.", 
                "num": 6
              }, 
              {
                "text": "For I earnestly exhorted your fathers in the day I brought them up out of the land of Egypt, until this day, rising early and exhorting, saying, \"Obey My voice.\"", 
                "num": 7
              }, 
              {
                "text": "Yet they did not obey or incline their ear, but everyone followed the dictates of his evil heart; therefore I will bring upon them all the words of this covenant, which I commanded them to do, but which they have not done.\"'", 
                "num": 8
              }, 
              {
                "text": "And the LORD said to me, \"A conspiracy has been found among the men of Judah and among the inhabitants of Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "They have turned back to the iniquities of their forefathers who refused to hear My words, and they have gone after other gods to serve them; the house of Israel and the house of Judah have broken My covenant which I made with their fathers.\"", 
                "num": 10
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, I will surely bring calamity on them which they will not be able to escape; and though they cry out to Me, I will not listen to them.", 
                "num": 11
              }, 
              {
                "text": "Then the cities of Judah and the inhabitants of Jerusalem will go and cry out to the gods to whom they offer incense, but they will not save them at all in the time of their trouble.", 
                "num": 12
              }, 
              {
                "text": "For according to the number of your cities were your gods, O Judah; and according to the number of the streets of Jerusalem you have set up altars to that shameful thing, altars to burn incense to Baal.", 
                "num": 13
              }, 
              {
                "text": "\"So do not pray for this people, or lift up a cry or prayer for them; for I will not hear them in the time that they cry out to Me because of their trouble.", 
                "num": 14
              }, 
              {
                "text": "\"What has My beloved to do in My house, Having done lewd deeds with many? And the holy flesh has passed from you. When you do evil, then you rejoice.", 
                "num": 15
              }, 
              {
                "text": "The LORD called your name, Green Olive Tree, Lovely and of Good Fruit. With the noise of a great tumult He has kindled fire on it, And its branches are broken.", 
                "num": 16
              }, 
              {
                "text": "\"For the LORD of hosts, who planted you, has pronounced doom against you for the evil of the house of Israel and of the house of Judah, which they have done against themselves to provoke Me to anger in offering incense to Baal.\"", 
                "num": 17
              }, 
              {
                "text": "Now the LORD gave me knowledge of it, and I know it; for You showed me their doings.", 
                "num": 18
              }, 
              {
                "text": "But I was like a docile lamb brought to the slaughter; and I did not know that they had devised schemes against me, saying, \"Let us destroy the tree with its fruit, and let us cut him off from the land of the living, that his name may be remembered no more.\"", 
                "num": 19
              }, 
              {
                "text": "But, O LORD of hosts, You who judge righteously, Testing the mind and the heart, Let me see Your vengeance on them, For to You I have revealed my cause.", 
                "num": 20
              }, 
              {
                "text": "\"Therefore thus says the LORD concerning the men of Anathoth who seek your life, saying, \"Do not prophesy in the name of the LORD, lest you die by our hand'--", 
                "num": 21
              }, 
              {
                "text": "therefore thus says the LORD of hosts: \"Behold, I will punish them. The young men shall die by the sword, their sons and their daughters shall die by famine;", 
                "num": 22
              }, 
              {
                "text": "and there shall be no remnant of them, for I will bring catastrophe on the men of Anathoth, even the year of their punishment.\"'", 
                "num": 23
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Righteous are You, O LORD, when I plead with You; Yet let me talk with You about Your judgments. Why does the way of the wicked prosper? Why are those happy who deal so treacherously?", 
                "num": 1
              }, 
              {
                "text": "You have planted them, yes, they have taken root; They grow, yes, they bear fruit. You are near in their mouth But far from their mind.", 
                "num": 2
              }, 
              {
                "text": "But You, O LORD, know me; You have seen me, And You have tested my heart toward You. Pull them out like sheep for the slaughter, And prepare them for the day of slaughter.", 
                "num": 3
              }, 
              {
                "text": "How long will the land mourn, And the herbs of every field wither? The beasts and birds are consumed, For the wickedness of those who dwell there, Because they said, \"He will not see our final end.\"", 
                "num": 4
              }, 
              {
                "text": "\"If you have run with the footmen, and they have wearied you, Then how can you contend with horses? And if in the land of peace, In which you trusted, they wearied you, Then how will you do in the floodplain of the Jordan?", 
                "num": 5
              }, 
              {
                "text": "For even your brothers, the house of your father, Even they have dealt treacherously with you; Yes, they have called a multitude after you. Do not believe them, Even though they speak smooth words to you.", 
                "num": 6
              }, 
              {
                "text": "\"I have forsaken My house, I have left My heritage; I have given the dearly beloved of My soul into the hand of her enemies.", 
                "num": 7
              }, 
              {
                "text": "My heritage is to Me like a lion in the forest; It cries out against Me; Therefore I have hated it.", 
                "num": 8
              }, 
              {
                "text": "My heritage is to Me like a speckled vulture; The vultures all around are against her. Come, assemble all the beasts of the field, Bring them to devour!", 
                "num": 9
              }, 
              {
                "text": "\"Many rulers have destroyed My vineyard, They have trodden My portion underfoot; They have made My pleasant portion a desolate wilderness.", 
                "num": 10
              }, 
              {
                "text": "They have made it desolate; Desolate, it mourns to Me; The whole land is made desolate, Because no one takes it to heart.", 
                "num": 11
              }, 
              {
                "text": "The plunderers have come On all the desolate heights in the wilderness, For the sword of the LORD shall devour From one end of the land to the other end of the land; No flesh shall have peace.", 
                "num": 12
              }, 
              {
                "text": "They have sown wheat but reaped thorns; They have put themselves to pain but do not profit. But be ashamed of your harvest Because of the fierce anger of the LORD.\"", 
                "num": 13
              }, 
              {
                "text": "Thus says the LORD: \"Against all My evil neighbors who touch the inheritance which I have caused My people Israel to inherit--behold, I will pluck them out of their land and pluck out the house of Judah from among them.", 
                "num": 14
              }, 
              {
                "text": "Then it shall be, after I have plucked them out, that I will return and have compassion on them and bring them back, everyone to his heritage and everyone to his land.", 
                "num": 15
              }, 
              {
                "text": "And it shall be, if they will learn carefully the ways of My people, to swear by My name, \"As the LORD lives,' as they taught My people to swear by Baal, then they shall be established in the midst of My people.", 
                "num": 16
              }, 
              {
                "text": "But if they do not obey, I will utterly pluck up and destroy that nation,\" says the LORD.", 
                "num": 17
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Thus the LORD said to me: \"Go and get yourself a linen sash, and put it around your waist, but do not put it in water.\"", 
                "num": 1
              }, 
              {
                "text": "So I got a sash according to the word of the LORD, and put it around my waist.", 
                "num": 2
              }, 
              {
                "text": "And the word of the LORD came to me the second time, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Take the sash that you acquired, which is around your waist, and arise, go to the Euphrates, and hide it there in a hole in the rock.\"", 
                "num": 4
              }, 
              {
                "text": "So I went and hid it by the Euphrates, as the LORD commanded me.", 
                "num": 5
              }, 
              {
                "text": "Now it came to pass after many days that the LORD said to me, \"Arise, go to the Euphrates, and take from there the sash which I commanded you to hide there.\"", 
                "num": 6
              }, 
              {
                "text": "Then I went to the Euphrates and dug, and I took the sash from the place where I had hidden it; and there was the sash, ruined. It was profitable for nothing.", 
                "num": 7
              }, 
              {
                "text": "Then the word of the LORD came to me, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Thus says the LORD: \"In this manner I will ruin the pride of Judah and the great pride of Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "This evil people, who refuse to hear My words, who follow the dictates of their hearts, and walk after other gods to serve them and worship them, shall be just like this sash which is profitable for nothing.", 
                "num": 10
              }, 
              {
                "text": "For as the sash clings to the waist of a man, so I have caused the whole house of Israel and the whole house of Judah to cling to Me,' says the LORD, \"that they may become My people, for renown, for praise, and for glory; but they would not hear.'", 
                "num": 11
              }, 
              {
                "text": "\"Therefore you shall speak to them this word: \"Thus says the LORD God of Israel: \"Every bottle shall be filled with wine.\"' \"And they will say to you, \"Do we not certainly know that every bottle will be filled with wine?'", 
                "num": 12
              }, 
              {
                "text": "\"Then you shall say to them, \"Thus says the LORD: \"Behold, I will fill all the inhabitants of this land--even the kings who sit on David's throne, the priests, the prophets, and all the inhabitants of Jerusalem--with drunkenness!", 
                "num": 13
              }, 
              {
                "text": "And I will dash them one against another, even the fathers and the sons together,\" says the LORD. \"I will not pity nor spare nor have mercy, but will destroy them.\"\"'", 
                "num": 14
              }, 
              {
                "text": "Hear and give ear: Do not be proud, For the LORD has spoken.", 
                "num": 15
              }, 
              {
                "text": "Give glory to the LORD your God Before He causes darkness, And before your feet stumble On the dark mountains, And while you are looking for light, He turns it into the shadow of death And makes it dense darkness.", 
                "num": 16
              }, 
              {
                "text": "But if you will not hear it, My soul will weep in secret for your pride; My eyes will weep bitterly And run down with tears, Because the LORD's flock has been taken captive.", 
                "num": 17
              }, 
              {
                "text": "Say to the king and to the queen mother, \"Humble yourselves; Sit down, For your rule shall collapse, the crown of your glory.\"", 
                "num": 18
              }, 
              {
                "text": "The cities of the South shall be shut up, And no one shall open them; Judah shall be carried away captive, all of it; It shall be wholly carried away captive.", 
                "num": 19
              }, 
              {
                "text": "Lift up your eyes and see Those who come from the north. Where is the flock that was given to you, Your beautiful sheep?", 
                "num": 20
              }, 
              {
                "text": "What will you say when He punishes you? For you have taught them To be chieftains, to be head over you. Will not pangs seize you, Like a woman in labor?", 
                "num": 21
              }, 
              {
                "text": "And if you say in your heart, \"Why have these things come upon me?\" For the greatness of your iniquity Your skirts have been uncovered, Your heels made bare.", 
                "num": 22
              }, 
              {
                "text": "Can the Ethiopian change his skin or the leopard its spots? Then may you also do good who are accustomed to do evil.", 
                "num": 23
              }, 
              {
                "text": "\"Therefore I will scatter them like stubble That passes away by the wind of the wilderness.", 
                "num": 24
              }, 
              {
                "text": "This is your lot, The portion of your measures from Me,\" says the LORD, \"Because you have forgotten Me And trusted in falsehood.", 
                "num": 25
              }, 
              {
                "text": "Therefore I will uncover your skirts over your face, That your shame may appear.", 
                "num": 26
              }, 
              {
                "text": "I have seen your adulteries And your lustful neighings, The lewdness of your harlotry, Your abominations on the hills in the fields. Woe to you, O Jerusalem! Will you still not be made clean?\"", 
                "num": 27
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD that came to Jeremiah concerning the droughts.", 
                "num": 1
              }, 
              {
                "text": "\"Judah mourns, And her gates languish; They mourn for the land, And the cry of Jerusalem has gone up.", 
                "num": 2
              }, 
              {
                "text": "Their nobles have sent their lads for water; They went to the cisterns and found no water. They returned with their vessels empty; They were ashamed and confounded And covered their heads.", 
                "num": 3
              }, 
              {
                "text": "Because the ground is parched, For there was no rain in the land, The plowmen were ashamed; They covered their heads.", 
                "num": 4
              }, 
              {
                "text": "Yes, the deer also gave birth in the field, But left because there was no grass.", 
                "num": 5
              }, 
              {
                "text": "And the wild donkeys stood in the desolate heights; They sniffed at the wind like jackals; Their eyes failed because there was no grass.\"", 
                "num": 6
              }, 
              {
                "text": "O LORD, though our iniquities testify against us, Do it for Your name's sake; For our backslidings are many, We have sinned against You.", 
                "num": 7
              }, 
              {
                "text": "O the Hope of Israel, his Savior in time of trouble, Why should You be like a stranger in the land, And like a traveler who turns aside to tarry for a night?", 
                "num": 8
              }, 
              {
                "text": "Why should You be like a man astonished, Like a mighty one who cannot save? Yet You, O LORD, are in our midst, And we are called by Your name; Do not leave us!", 
                "num": 9
              }, 
              {
                "text": "Thus says the LORD to this people: \"Thus they have loved to wander; They have not restrained their feet. Therefore the LORD does not accept them; He will remember their iniquity now, And punish their sins.\"", 
                "num": 10
              }, 
              {
                "text": "Then the LORD said to me, \"Do not pray for this people, for their good.", 
                "num": 11
              }, 
              {
                "text": "When they fast, I will not hear their cry; and when they offer burnt offering and grain offering, I will not accept them. But I will consume them by the sword, by the famine, and by the pestilence.\"", 
                "num": 12
              }, 
              {
                "text": "Then I said, \"Ah, Lord GOD! Behold, the prophets say to them, \"You shall not see the sword, nor shall you have famine, but I will give you assured peace in this place.\"'", 
                "num": 13
              }, 
              {
                "text": "And the LORD said to me, \"The prophets prophesy lies in My name. I have not sent them, commanded them, nor spoken to them; they prophesy to you a false vision, divination, a worthless thing, and the deceit of their heart.", 
                "num": 14
              }, 
              {
                "text": "Therefore thus says the LORD concerning the prophets who prophesy in My name, whom I did not send, and who say, \"Sword and famine shall not be in this land'--\"By sword and famine those prophets shall be consumed!", 
                "num": 15
              }, 
              {
                "text": "And the people to whom they prophesy shall be cast out in the streets of Jerusalem because of the famine and the sword; they will have no one to bury them--them nor their wives, their sons nor their daughters--for I will pour their wickedness on them.'", 
                "num": 16
              }, 
              {
                "text": "\"Therefore you shall say this word to them: \"Let my eyes flow with tears night and day, And let them not cease; For the virgin daughter of my people Has been broken with a mighty stroke, with a very severe blow.", 
                "num": 17
              }, 
              {
                "text": "If I go out to the field, Then behold, those slain with the sword! And if I enter the city, Then behold, those sick from famine! Yes, both prophet and priest go about in a land they do not know.\"'", 
                "num": 18
              }, 
              {
                "text": "Have You utterly rejected Judah? Has Your soul loathed Zion? Why have You stricken us so that there is no healing for us? We looked for peace, but there was no good; And for the time of healing, and there was trouble.", 
                "num": 19
              }, 
              {
                "text": "We acknowledge, O LORD, our wickedness And the iniquity of our fathers, For we have sinned against You.", 
                "num": 20
              }, 
              {
                "text": "Do not abhor us, for Your name's sake; Do not disgrace the throne of Your glory. Remember, do not break Your covenant with us.", 
                "num": 21
              }, 
              {
                "text": "Are there any among the idols of the nations that can cause rain? Or can the heavens give showers? Are You not He, O LORD our God? Therefore we will wait for You, Since You have made all these.", 
                "num": 22
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to me, \"Even if Moses and Samuel stood before Me, My mind would not be favorable toward this people. Cast them out of My sight, and let them go forth.", 
                "num": 1
              }, 
              {
                "text": "And it shall be, if they say to you, \"Where should we go?' then you shall tell them, \"Thus says the LORD: \"Such as are for death, to death; And such as are for the sword, to the sword; And such as are for the famine, to the famine; And such as are for the captivity, to the captivity.\"'", 
                "num": 2
              }, 
              {
                "text": "\"And I will appoint over them four forms of destruction,\" says the LORD: \"the sword to slay, the dogs to drag, the birds of the heavens and the beasts of the earth to devour and destroy.", 
                "num": 3
              }, 
              {
                "text": "I will hand them over to trouble, to all kingdoms of the earth, because of Manasseh the son of Hezekiah, king of Judah, for what he did in Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "\"For who will have pity on you, O Jerusalem? Or who will bemoan you? Or who will turn aside to ask how you are doing?", 
                "num": 5
              }, 
              {
                "text": "You have forsaken Me,\" says the LORD, \"You have gone backward. Therefore I will stretch out My hand against you and destroy you; I am weary of relenting!", 
                "num": 6
              }, 
              {
                "text": "And I will winnow them with a winnowing fan in the gates of the land; I will bereave them of children; I will destroy My people, Since they do not return from their ways.", 
                "num": 7
              }, 
              {
                "text": "Their widows will be increased to Me more than the sand of the seas; I will bring against them, Against the mother of the young men, A plunderer at noonday; I will cause anguish and terror to fall on them suddenly.", 
                "num": 8
              }, 
              {
                "text": "\"She languishes who has borne seven; She has breathed her last; Her sun has gone down While it was yet day; She has been ashamed and confounded. And the remnant of them I will deliver to the sword Before their enemies,\" says the LORD.", 
                "num": 9
              }, 
              {
                "text": "Woe is me, my mother, That you have borne me, A man of strife and a man of contention to the whole earth! I have neither lent for interest, Nor have men lent to me for interest. Every one of them curses me.", 
                "num": 10
              }, 
              {
                "text": "The LORD said: \"Surely it will be well with your remnant; Surely I will cause the enemy to intercede with you In the time of adversity and in the time of affliction.", 
                "num": 11
              }, 
              {
                "text": "Can anyone break iron, The northern iron and the bronze?", 
                "num": 12
              }, 
              {
                "text": "Your wealth and your treasures I will give as plunder without price, Because of all your sins, Throughout your territories.", 
                "num": 13
              }, 
              {
                "text": "And I will make you cross over with your enemies Into a land which you do not know; For a fire is kindled in My anger, Which shall burn upon you.\"", 
                "num": 14
              }, 
              {
                "text": "O LORD, You know; Remember me and visit me, And take vengeance for me on my persecutors. In Your enduring patience, do not take me away. Know that for Your sake I have suffered rebuke.", 
                "num": 15
              }, 
              {
                "text": "Your words were found, and I ate them, And Your word was to me the joy and rejoicing of my heart; For I am called by Your name, O LORD God of hosts.", 
                "num": 16
              }, 
              {
                "text": "I did not sit in the assembly of the mockers, Nor did I rejoice; I sat alone because of Your hand, For You have filled me with indignation.", 
                "num": 17
              }, 
              {
                "text": "Why is my pain perpetual And my wound incurable, Which refuses to be healed? Will You surely be to me like an unreliable stream, As waters that fail?", 
                "num": 18
              }, 
              {
                "text": "Therefore thus says the LORD: \"If you return, Then I will bring you back; You shall stand before Me; If you take out the precious from the vile, You shall be as My mouth. Let them return to you, But you must not return to them.", 
                "num": 19
              }, 
              {
                "text": "And I will make you to this people a fortified bronze wall; And they will fight against you, But they shall not prevail against you; For I am with you to save you And deliver you,\" says the LORD.", 
                "num": 20
              }, 
              {
                "text": "\"I will deliver you from the hand of the wicked, And I will redeem you from the grip of the terrible.\"", 
                "num": 21
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD also came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"You shall not take a wife, nor shall you have sons or daughters in this place.\"", 
                "num": 2
              }, 
              {
                "text": "For thus says the LORD concerning the sons and daughters who are born in this place, and concerning their mothers who bore them and their fathers who begot them in this land:", 
                "num": 3
              }, 
              {
                "text": "\"They shall die gruesome deaths; they shall not be lamented nor shall they be buried, but they shall be like refuse on the face of the earth. They shall be consumed by the sword and by famine, and their corpses shall be meat for the birds of heaven and for the beasts of the earth.\"", 
                "num": 4
              }, 
              {
                "text": "For thus says the LORD: \"Do not enter the house of mourning, nor go to lament or bemoan them; for I have taken away My peace from this people,\" says the LORD, \"lovingkindness and mercies.", 
                "num": 5
              }, 
              {
                "text": "Both the great and the small shall die in this land. They shall not be buried; neither shall men lament for them, cut themselves, nor make themselves bald for them.", 
                "num": 6
              }, 
              {
                "text": "Nor shall men break bread in mourning for them, to comfort them for the dead; nor shall men give them the cup of consolation to drink for their father or their mother.", 
                "num": 7
              }, 
              {
                "text": "Also you shall not go into the house of feasting to sit with them, to eat and drink.\"", 
                "num": 8
              }, 
              {
                "text": "For thus says the LORD of hosts, the God of Israel: \"Behold, I will cause to cease from this place, before your eyes and in your days, the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride.", 
                "num": 9
              }, 
              {
                "text": "\"And it shall be, when you show this people all these words, and they say to you, \"Why has the LORD pronounced all this great disaster against us? Or what is our iniquity? Or what is our sin that we have committed against the LORD our God?'", 
                "num": 10
              }, 
              {
                "text": "then you shall say to them, \"Because your fathers have forsaken Me,' says the LORD; \"they have walked after other gods and have served them and worshiped them, and have forsaken Me and not kept My law.", 
                "num": 11
              }, 
              {
                "text": "And you have done worse than your fathers, for behold, each one follows the dictates of his own evil heart, so that no one listens to Me.", 
                "num": 12
              }, 
              {
                "text": "Therefore I will cast you out of this land into a land that you do not know, neither you nor your fathers; and there you shall serve other gods day and night, where I will not show you favor.'", 
                "num": 13
              }, 
              {
                "text": "\"Therefore behold, the days are coming,\" says the LORD, \"that it shall no more be said, \"The LORD lives who brought up the children of Israel from the land of Egypt,'", 
                "num": 14
              }, 
              {
                "text": "but, \"The LORD lives who brought up the children of Israel from the land of the north and from all the lands where He had driven them.' For I will bring them back into their land which I gave to their fathers.", 
                "num": 15
              }, 
              {
                "text": "\"Behold, I will send for many fishermen,\" says the LORD, \"and they shall fish them; and afterward I will send for many hunters, and they shall hunt them from every mountain and every hill, and out of the holes of the rocks.", 
                "num": 16
              }, 
              {
                "text": "For My eyes are on all their ways; they are not hidden from My face, nor is their iniquity hidden from My eyes.", 
                "num": 17
              }, 
              {
                "text": "And first I will repay double for their iniquity and their sin, because they have defiled My land; they have filled My inheritance with the carcasses of their detestable and abominable idols.\"", 
                "num": 18
              }, 
              {
                "text": "O LORD, my strength and my fortress, My refuge in the day of affliction, The Gentiles shall come to You From the ends of the earth and say, \"Surely our fathers have inherited lies, Worthlessness and unprofitable things.\"", 
                "num": 19
              }, 
              {
                "text": "Will a man make gods for himself, Which are not gods?", 
                "num": 20
              }, 
              {
                "text": "\"Therefore behold, I will this once cause them to know, I will cause them to know My hand and My might; And they shall know that My name is the LORD.", 
                "num": 21
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "\"The sin of Judah is written with a pen of iron; With the point of a diamond it is engraved On the tablet of their heart, And on the horns of your altars,", 
                "num": 1
              }, 
              {
                "text": "While their children remember Their altars and their wooden images By the green trees on the high hills.", 
                "num": 2
              }, 
              {
                "text": "O My mountain in the field, I will give as plunder your wealth, all your treasures, And your high places of sin within all your borders.", 
                "num": 3
              }, 
              {
                "text": "And you, even yourself, Shall let go of your heritage which I gave you; And I will cause you to serve your enemies In the land which you do not know; For you have kindled a fire in My anger which shall burn forever.\"", 
                "num": 4
              }, 
              {
                "text": "Thus says the LORD: \"Cursed is the man who trusts in man And makes flesh his strength, Whose heart departs from the LORD.", 
                "num": 5
              }, 
              {
                "text": "For he shall be like a shrub in the desert, And shall not see when good comes, But shall inhabit the parched places in the wilderness, In a salt land which is not inhabited.", 
                "num": 6
              }, 
              {
                "text": "\"Blessed is the man who trusts in the LORD, And whose hope is the LORD.", 
                "num": 7
              }, 
              {
                "text": "For he shall be like a tree planted by the waters, Which spreads out its roots by the river, And will not fear when heat comes; But its leaf will be green, And will not be anxious in the year of drought, Nor will cease from yielding fruit.", 
                "num": 8
              }, 
              {
                "text": "\"The heart is deceitful above all things, And desperately wicked; Who can know it?", 
                "num": 9
              }, 
              {
                "text": "I, the LORD, search the heart, I test the mind, Even to give every man according to his ways, According to the fruit of his doings.", 
                "num": 10
              }, 
              {
                "text": "\"As a partridge that broods but does not hatch, So is he who gets riches, but not by right; It will leave him in the midst of his days, And at his end he will be a fool.\"", 
                "num": 11
              }, 
              {
                "text": "A glorious high throne from the beginning Is the place of our sanctuary.", 
                "num": 12
              }, 
              {
                "text": "O LORD, the hope of Israel, All who forsake You shall be ashamed. \"Those who depart from Me Shall be written in the earth, Because they have forsaken the LORD, The fountain of living waters.\"", 
                "num": 13
              }, 
              {
                "text": "Heal me, O LORD, and I shall be healed; Save me, and I shall be saved, For You are my praise.", 
                "num": 14
              }, 
              {
                "text": "Indeed they say to me, \"Where is the word of the LORD? Let it come now!\"", 
                "num": 15
              }, 
              {
                "text": "As for me, I have not hurried away from being a shepherd who follows You, Nor have I desired the woeful day; You know what came out of my lips; It was right there before You.", 
                "num": 16
              }, 
              {
                "text": "Do not be a terror to me; You are my hope in the day of doom.", 
                "num": 17
              }, 
              {
                "text": "Let them be ashamed who persecute me, But do not let me be put to shame; Let them be dismayed, But do not let me be dismayed. Bring on them the day of doom, And destroy them with double destruction!", 
                "num": 18
              }, 
              {
                "text": "Thus the LORD said to me: \"Go and stand in the gate of the children of the people, by which the kings of Judah come in and by which they go out, and in all the gates of Jerusalem;", 
                "num": 19
              }, 
              {
                "text": "and say to them, \"Hear the word of the LORD, you kings of Judah, and all Judah, and all the inhabitants of Jerusalem, who enter by these gates.", 
                "num": 20
              }, 
              {
                "text": "Thus says the LORD: \"Take heed to yourselves, and bear no burden on the Sabbath day, nor bring it in by the gates of Jerusalem;", 
                "num": 21
              }, 
              {
                "text": "nor carry a burden out of your houses on the Sabbath day, nor do any work, but hallow the Sabbath day, as I commanded your fathers.", 
                "num": 22
              }, 
              {
                "text": "But they did not obey nor incline their ear, but made their neck stiff, that they might not hear nor receive instruction.", 
                "num": 23
              }, 
              {
                "text": "\"And it shall be, if you heed Me carefully,\" says the LORD, \"to bring no burden through the gates of this city on the Sabbath day, but hallow the Sabbath day, to do no work in it,", 
                "num": 24
              }, 
              {
                "text": "then shall enter the gates of this city kings and princes sitting on the throne of David, riding in chariots and on horses, they and their princes, accompanied by the men of Judah and the inhabitants of Jerusalem; and this city shall remain forever.", 
                "num": 25
              }, 
              {
                "text": "And they shall come from the cities of Judah and from the places around Jerusalem, from the land of Benjamin and from the lowland, from the mountains and from the South, bringing burnt offerings and sacrifices, grain offerings and incense, bringing sacrifices of praise to the house of the LORD.", 
                "num": 26
              }, 
              {
                "text": "\"But if you will not heed Me to hallow the Sabbath day, such as not carrying a burden when entering the gates of Jerusalem on the Sabbath day, then I will kindle a fire in its gates, and it shall devour the palaces of Jerusalem, and it shall not be quenched.\"\"'", 
                "num": 27
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "The word which came to Jeremiah from the LORD, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Arise and go down to the potter's house, and there I will cause you to hear My words.\"", 
                "num": 2
              }, 
              {
                "text": "Then I went down to the potter's house, and there he was, making something at the wheel.", 
                "num": 3
              }, 
              {
                "text": "And the vessel that he made of clay was marred in the hand of the potter; so he made it again into another vessel, as it seemed good to the potter to make.", 
                "num": 4
              }, 
              {
                "text": "Then the word of the LORD came to me, saying:", 
                "num": 5
              }, 
              {
                "text": "\"O house of Israel, can I not do with you as this potter?\" says the LORD. \"Look, as the clay is in the potter's hand, so are you in My hand, O house of Israel!", 
                "num": 6
              }, 
              {
                "text": "The instant I speak concerning a nation and concerning a kingdom, to pluck up, to pull down, and to destroy it,", 
                "num": 7
              }, 
              {
                "text": "if that nation against whom I have spoken turns from its evil, I will relent of the disaster that I thought to bring upon it.", 
                "num": 8
              }, 
              {
                "text": "And the instant I speak concerning a nation and concerning a kingdom, to build and to plant it,", 
                "num": 9
              }, 
              {
                "text": "if it does evil in My sight so that it does not obey My voice, then I will relent concerning the good with which I said I would benefit it.", 
                "num": 10
              }, 
              {
                "text": "\"Now therefore, speak to the men of Judah and to the inhabitants of Jerusalem, saying, \"Thus says the LORD: \"Behold, I am fashioning a disaster and devising a plan against you. Return now every one from his evil way, and make your ways and your doings good.\"\"'", 
                "num": 11
              }, 
              {
                "text": "And they said, \"That is hopeless! So we will walk according to our own plans, and we will every one obey the dictates of his evil heart.\"", 
                "num": 12
              }, 
              {
                "text": "Therefore thus says the LORD: \"Ask now among the Gentiles, Who has heard such things? The virgin of Israel has done a very horrible thing.", 
                "num": 13
              }, 
              {
                "text": "Will a man leave the snow water of Lebanon, Which comes from the rock of the field? Will the cold flowing waters be forsaken for strange waters?", 
                "num": 14
              }, 
              {
                "text": "\"Because My people have forgotten Me, They have burned incense to worthless idols. And they have caused themselves to stumble in their ways, From the ancient paths, To walk in pathways and not on a highway,", 
                "num": 15
              }, 
              {
                "text": "To make their land desolate and a perpetual hissing; Everyone who passes by it will be astonished And shake his head.", 
                "num": 16
              }, 
              {
                "text": "I will scatter them as with an east wind before the enemy; I will show them the back and not the face In the day of their calamity.\"", 
                "num": 17
              }, 
              {
                "text": "Then they said, \"Come and let us devise plans against Jeremiah; for the law shall not perish from the priest, nor counsel from the wise, nor the word from the prophet. Come and let us attack him with the tongue, and let us not give heed to any of his words.\"", 
                "num": 18
              }, 
              {
                "text": "Give heed to me, O LORD, And listen to the voice of those who contend with me!", 
                "num": 19
              }, 
              {
                "text": "Shall evil be repaid for good? For they have dug a pit for my life. Remember that I stood before You To speak good for them, To turn away Your wrath from them.", 
                "num": 20
              }, 
              {
                "text": "Therefore deliver up their children to the famine, And pour out their blood By the force of the sword; Let their wives become widows And bereaved of their children. Let their men be put to death, Their young men be slain By the sword in battle.", 
                "num": 21
              }, 
              {
                "text": "Let a cry be heard from their houses, When You bring a troop suddenly upon them; For they have dug a pit to take me, And hidden snares for my feet.", 
                "num": 22
              }, 
              {
                "text": "Yet, LORD, You know all their counsel Which is against me, to slay me. Provide no atonement for their iniquity, Nor blot out their sin from Your sight; But let them be overthrown before You. Deal thus with them In the time of Your anger.", 
                "num": 23
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Go and get a potter's earthen flask, and take some of the elders of the people and some of the elders of the priests.", 
                "num": 1
              }, 
              {
                "text": "And go out to the Valley of the Son of Hinnom, which is by the entry of the Potsherd Gate; and proclaim there the words that I will tell you,", 
                "num": 2
              }, 
              {
                "text": "and say, \"Hear the word of the LORD, O kings of Judah and inhabitants of Jerusalem. Thus says the LORD of hosts, the God of Israel: \"Behold, I will bring such a catastrophe on this place, that whoever hears of it, his ears will tingle.", 
                "num": 3
              }, 
              {
                "text": "\"Because they have forsaken Me and made this an alien place, because they have burned incense in it to other gods whom neither they, their fathers, nor the kings of Judah have known, and have filled this place with the blood of the innocents", 
                "num": 4
              }, 
              {
                "text": "(they have also built the high places of Baal, to burn their sons with fire for burnt offerings to Baal, which I did not command or speak, nor did it come into My mind),", 
                "num": 5
              }, 
              {
                "text": "therefore behold, the days are coming,\" says the LORD, \"that this place shall no more be called Tophet or the Valley of the Son of Hinnom, but the Valley of Slaughter.", 
                "num": 6
              }, 
              {
                "text": "And I will make void the counsel of Judah and Jerusalem in this place, and I will cause them to fall by the sword before their enemies and by the hands of those who seek their lives; their corpses I will give as meat for the birds of the heaven and for the beasts of the earth.", 
                "num": 7
              }, 
              {
                "text": "I will make this city desolate and a hissing; everyone who passes by it will be astonished and hiss because of all its plagues.", 
                "num": 8
              }, 
              {
                "text": "And I will cause them to eat the flesh of their sons and the flesh of their daughters, and everyone shall eat the flesh of his friend in the siege and in the desperation with which their enemies and those who seek their lives shall drive them to despair.\"'", 
                "num": 9
              }, 
              {
                "text": "\"Then you shall break the flask in the sight of the men who go with you,", 
                "num": 10
              }, 
              {
                "text": "and say to them, \"Thus says the LORD of hosts: \"Even so I will break this people and this city, as one breaks a potter's vessel, which cannot be made whole again; and they shall bury them in Tophet till there is no place to bury.", 
                "num": 11
              }, 
              {
                "text": "Thus I will do to this place,\" says the LORD, \"and to its inhabitants, and make this city like Tophet.", 
                "num": 12
              }, 
              {
                "text": "And the houses of Jerusalem and the houses of the kings of Judah shall be defiled like the place of Tophet, because of all the houses on whose roofs they have burned incense to all the host of heaven, and poured out drink offerings to other gods.\"\"'", 
                "num": 13
              }, 
              {
                "text": "Then Jeremiah came from Tophet, where the LORD had sent him to prophesy; and he stood in the court of the Lord's house and said to all the people,", 
                "num": 14
              }, 
              {
                "text": "\"Thus says the LORD of hosts, the God of Israel: \"Behold, I will bring on this city and on all her towns all the doom that I have pronounced against it, because they have stiffened their necks that they might not hear My words.\"'", 
                "num": 15
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Now Pashhur the son of Immer, the priest who was also chief governor in the house of the LORD, heard that Jeremiah prophesied these things.", 
                "num": 1
              }, 
              {
                "text": "Then Pashhur struck Jeremiah the prophet, and put him in the stocks that were in the high gate of Benjamin, which was by the house of the LORD.", 
                "num": 2
              }, 
              {
                "text": "And it happened on the next day that Pashhur brought Jeremiah out of the stocks. Then Jeremiah said to him, \"The LORD has not called your name Pashhur, but Magor-Missabib.", 
                "num": 3
              }, 
              {
                "text": "For thus says the LORD: \"Behold, I will make you a terror to yourself and to all your friends; and they shall fall by the sword of their enemies, and your eyes shall see it. I will give all Judah into the hand of the king of Babylon, and he shall carry them captive to Babylon and slay them with the sword.", 
                "num": 4
              }, 
              {
                "text": "Moreover I will deliver all the wealth of this city, all its produce, and all its precious things; all the treasures of the kings of Judah I will give into the hand of their enemies, who will plunder them, seize them, and carry them to Babylon.", 
                "num": 5
              }, 
              {
                "text": "And you, Pashhur, and all who dwell in your house, shall go into captivity. You shall go to Babylon, and there you shall die, and be buried there, you and all your friends, to whom you have prophesied lies.\"'", 
                "num": 6
              }, 
              {
                "text": "O LORD, You induced me, and I was persuaded; You are stronger than I, and have prevailed. I am in derision daily; Everyone mocks me.", 
                "num": 7
              }, 
              {
                "text": "For when I spoke, I cried out; I shouted, \"Violence and plunder!\" Because the word of the LORD was made to me A reproach and a derision daily.", 
                "num": 8
              }, 
              {
                "text": "Then I said, \"I will not make mention of Him, Nor speak anymore in His name.\" But His word was in my heart like a burning fire Shut up in my bones; I was weary of holding it back, And I could not.", 
                "num": 9
              }, 
              {
                "text": "For I heard many mocking: \"Fear on every side!\" \"Report,\" they say, \"and we will report it!\" All my acquaintances watched for my stumbling, saying, \"Perhaps he can be induced; Then we will prevail against him, And we will take our revenge on him.\"", 
                "num": 10
              }, 
              {
                "text": "But the LORD is with me as a mighty, awesome One. Therefore my persecutors will stumble, and will not prevail. They will be greatly ashamed, for they will not prosper. Their everlasting confusion will never be forgotten.", 
                "num": 11
              }, 
              {
                "text": "But, O LORD of hosts, You who test the righteous, And see the mind and heart, Let me see Your vengeance on them; For I have pleaded my cause before You.", 
                "num": 12
              }, 
              {
                "text": "Sing to the LORD! Praise the LORD! For He has delivered the life of the poor From the hand of evildoers.", 
                "num": 13
              }, 
              {
                "text": "Cursed be the day in which I was born! Let the day not be blessed in which my mother bore me!", 
                "num": 14
              }, 
              {
                "text": "Let the man be cursed Who brought news to my father, saying, \"A male child has been born to you!\" Making him very glad.", 
                "num": 15
              }, 
              {
                "text": "And let that man be like the cities Which the LORD overthrew, and did not relent; Let him hear the cry in the morning And the shouting at noon,", 
                "num": 16
              }, 
              {
                "text": "Because he did not kill me from the womb, That my mother might have been my grave, And her womb always enlarged with me.", 
                "num": 17
              }, 
              {
                "text": "Why did I come forth from the womb to see labor and sorrow, That my days should be consumed with shame?", 
                "num": 18
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "The word which came to Jeremiah from the LORD when King Zedekiah sent to him Pashhur the son of Melchiah, and Zephaniah the son of Maaseiah, the priest, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Please inquire of the LORD for us, for Nebuchadnezzar king of Babylon makes war against us. Perhaps the LORD will deal with us according to all His wonderful works, that the king may go away from us.\"", 
                "num": 2
              }, 
              {
                "text": "Then Jeremiah said to them, \"Thus you shall say to Zedekiah,", 
                "num": 3
              }, 
              {
                "text": "\"Thus says the LORD God of Israel: \"Behold, I will turn back the weapons of war that are in your hands, with which you fight against the king of Babylon and the Chaldeans who besiege you outside the walls; and I will assemble them in the midst of this city.", 
                "num": 4
              }, 
              {
                "text": "I Myself will fight against you with an outstretched hand and with a strong arm, even in anger and fury and great wrath.", 
                "num": 5
              }, 
              {
                "text": "I will strike the inhabitants of this city, both man and beast; they shall die of a great pestilence.", 
                "num": 6
              }, 
              {
                "text": "And afterward,\" says the LORD, \"I will deliver Zedekiah king of Judah, his servants and the people, and such as are left in this city from the pestilence and the sword and the famine, into the hand of Nebuchadnezzar king of Babylon, into the hand of their enemies, and into the hand of those who seek their life; and he shall strike them with the edge of the sword. He shall not spare them, or have pity or mercy.\"'", 
                "num": 7
              }, 
              {
                "text": "\"Now you shall say to this people, \"Thus says the LORD: \"Behold, I set before you the way of life and the way of death.", 
                "num": 8
              }, 
              {
                "text": "He who remains in this city shall die by the sword, by famine, and by pestilence; but he who goes out and defects to the Chaldeans who besiege you, he shall live, and his life shall be as a prize to him.", 
                "num": 9
              }, 
              {
                "text": "For I have set My face against this city for adversity and not for good,\" says the LORD. \"It shall be given into the hand of the king of Babylon, and he shall burn it with fire.\"'", 
                "num": 10
              }, 
              {
                "text": "\"And concerning the house of the king of Judah, say, \"Hear the word of the LORD,", 
                "num": 11
              }, 
              {
                "text": "O house of David! Thus says the LORD: \"Execute judgment in the morning; And deliver him who is plundered Out of the hand of the oppressor, Lest My fury go forth like fire And burn so that no one can quench it, Because of the evil of your doings.", 
                "num": 12
              }, 
              {
                "text": "\"Behold, I am against you, O inhabitant of the valley, And rock of the plain,\" says the LORD, \"Who say, \"Who shall come down against us? Or who shall enter our dwellings?'", 
                "num": 13
              }, 
              {
                "text": "But I will punish you according to the fruit of your doings,\" says the LORD; \"I will kindle a fire in its forest, And it shall devour all things around it.\"\"'", 
                "num": 14
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Go down to the house of the king of Judah, and there speak this word,", 
                "num": 1
              }, 
              {
                "text": "and say, \"Hear the word of the LORD, O king of Judah, you who sit on the throne of David, you and your servants and your people who enter these gates!", 
                "num": 2
              }, 
              {
                "text": "Thus says the LORD: \"Execute judgment and righteousness, and deliver the plundered out of the hand of the oppressor. Do no wrong and do no violence to the stranger, the fatherless, or the widow, nor shed innocent blood in this place.", 
                "num": 3
              }, 
              {
                "text": "For if you indeed do this thing, then shall enter the gates of this house, riding on horses and in chariots, accompanied by servants and people, kings who sit on the throne of David.", 
                "num": 4
              }, 
              {
                "text": "But if you will not hear these words, I swear by Myself,\" says the LORD, \"that this house shall become a desolation.\"\"'", 
                "num": 5
              }, 
              {
                "text": "For thus says the LORD to the house of the king of Judah: \"You are Gilead to Me, The head of Lebanon; Yet I surely will make you a wilderness, Cities which are not inhabited.", 
                "num": 6
              }, 
              {
                "text": "I will prepare destroyers against you, Everyone with his weapons; They shall cut down your choice cedars And cast them into the fire.", 
                "num": 7
              }, 
              {
                "text": "And many nations will pass by this city; and everyone will say to his neighbor, \"Why has the LORD done so to this great city?'", 
                "num": 8
              }, 
              {
                "text": "Then they will answer, \"Because they have forsaken the covenant of the LORD their God, and worshiped other gods and served them.\"'", 
                "num": 9
              }, 
              {
                "text": "Weep not for the dead, nor bemoan him; Weep bitterly for him who goes away, For he shall return no more, Nor see his native country.", 
                "num": 10
              }, 
              {
                "text": "For thus says the LORD concerning Shallum the son of Josiah, king of Judah, who reigned instead of Josiah his father, who went from this place: \"He shall not return here anymore,", 
                "num": 11
              }, 
              {
                "text": "but he shall die in the place where they have led him captive, and shall see this land no more.", 
                "num": 12
              }, 
              {
                "text": "\"Woe to him who builds his house by unrighteousness And his chambers by injustice, Who uses his neighbor's service without wages And gives him nothing for his work,", 
                "num": 13
              }, 
              {
                "text": "Who says, \"I will build myself a wide house with spacious chambers, And cut out windows for it, Paneling it with cedar And painting it with vermilion.'", 
                "num": 14
              }, 
              {
                "text": "\"Shall you reign because you enclose yourself in cedar? Did not your father eat and drink, And do justice and righteousness? Then it was well with him.", 
                "num": 15
              }, 
              {
                "text": "He judged the cause of the poor and needy; Then it was well. Was not this knowing Me?\" says the LORD.", 
                "num": 16
              }, 
              {
                "text": "\"Yet your eyes and your heart are for nothing but your covetousness, For shedding innocent blood, And practicing oppression and violence.\"", 
                "num": 17
              }, 
              {
                "text": "Therefore thus says the LORD concerning Jehoiakim the son of Josiah, king of Judah: \"They shall not lament for him, Saying, \"Alas, my brother!' or \"Alas, my sister!' They shall not lament for him, Saying, \"Alas, master!' or \"Alas, his glory!'", 
                "num": 18
              }, 
              {
                "text": "He shall be buried with the burial of a donkey, Dragged and cast out beyond the gates of Jerusalem.", 
                "num": 19
              }, 
              {
                "text": "\"Go up to Lebanon, and cry out, And lift up your voice in Bashan; Cry from Abarim, For all your lovers are destroyed.", 
                "num": 20
              }, 
              {
                "text": "I spoke to you in your prosperity, But you said, \"I will not hear.' This has been your manner from your youth, That you did not obey My voice.", 
                "num": 21
              }, 
              {
                "text": "The wind shall eat up all your rulers, And your lovers shall go into captivity; Surely then you will be ashamed and humiliated For all your wickedness.", 
                "num": 22
              }, 
              {
                "text": "O inhabitant of Lebanon, Making your nest in the cedars, How gracious will you be when pangs come upon you, Like the pain of a woman in labor?", 
                "num": 23
              }, 
              {
                "text": "\"As I live,\" says the LORD, \"though Coniah the son of Jehoiakim, king of Judah, were the signet on My right hand, yet I would pluck you off;", 
                "num": 24
              }, 
              {
                "text": "and I will give you into the hand of those who seek your life, and into the hand of those whose face you fear--the hand of Nebuchadnezzar king of Babylon and the hand of the Chaldeans.", 
                "num": 25
              }, 
              {
                "text": "So I will cast you out, and your mother who bore you, into another country where you were not born; and there you shall die.", 
                "num": 26
              }, 
              {
                "text": "But to the land to which they desire to return, there they shall not return.", 
                "num": 27
              }, 
              {
                "text": "\"Is this man Coniah a despised, broken idol-- A vessel in which is no pleasure? Why are they cast out, he and his descendants, And cast into a land which they do not know?", 
                "num": 28
              }, 
              {
                "text": "O earth, earth, earth, Hear the word of the LORD!", 
                "num": 29
              }, 
              {
                "text": "Thus says the LORD: \"Write this man down as childless, A man who shall not prosper in his days; For none of his descendants shall prosper, Sitting on the throne of David, And ruling anymore in Judah.\"'", 
                "num": 30
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "\"Woe to the shepherds who destroy and scatter the sheep of My pasture!\" says the LORD.", 
                "num": 1
              }, 
              {
                "text": "Therefore thus says the LORD God of Israel against the shepherds who feed My people: \"You have scattered My flock, driven them away, and not attended to them. Behold, I will attend to you for the evil of your doings,\" says the LORD.", 
                "num": 2
              }, 
              {
                "text": "\"But I will gather the remnant of My flock out of all countries where I have driven them, and bring them back to their folds; and they shall be fruitful and increase.", 
                "num": 3
              }, 
              {
                "text": "I will set up shepherds over them who will feed them; and they shall fear no more, nor be dismayed, nor shall they be lacking,\" says the LORD.", 
                "num": 4
              }, 
              {
                "text": "\"Behold, the days are coming,\" says the LORD, \"That I will raise to David a Branch of righteousness; A King shall reign and prosper, And execute judgment and righteousness in the earth.", 
                "num": 5
              }, 
              {
                "text": "In His days Judah will be saved, And Israel will dwell safely; Now this is His name by which He will be called: THE LORD OUR RIGHTEOUSNESS.", 
                "num": 6
              }, 
              {
                "text": "\"Therefore, behold, the days are coming,\" says the LORD, \"that they shall no longer say, \"As the LORD lives who brought up the children of Israel from the land of Egypt,'", 
                "num": 7
              }, 
              {
                "text": "but, \"As the LORD lives who brought up and led the descendants of the house of Israel from the north country and from all the countries where I had driven them.' And they shall dwell in their own land.\"", 
                "num": 8
              }, 
              {
                "text": "My heart within me is broken Because of the prophets; All my bones shake. I am like a drunken man, And like a man whom wine has overcome, Because of the LORD, And because of His holy words.", 
                "num": 9
              }, 
              {
                "text": "For the land is full of adulterers; For because of a curse the land mourns. The pleasant places of the wilderness are dried up. Their course of life is evil, And their might is not right.", 
                "num": 10
              }, 
              {
                "text": "\"For both prophet and priest are profane; Yes, in My house I have found their wickedness,\" says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"Therefore their way shall be to them Like slippery ways; In the darkness they shall be driven on And fall in them; For I will bring disaster on them, The year of their punishment,\" says the LORD.", 
                "num": 12
              }, 
              {
                "text": "\"And I have seen folly in the prophets of Samaria: They prophesied by Baal And caused My people Israel to err.", 
                "num": 13
              }, 
              {
                "text": "Also I have seen a horrible thing in the prophets of Jerusalem: They commit adultery and walk in lies; They also strengthen the hands of evildoers, So that no one turns back from his wickedness. All of them are like Sodom to Me, And her inhabitants like Gomorrah.", 
                "num": 14
              }, 
              {
                "text": "\"Therefore thus says the LORD of hosts concerning the prophets: \"Behold, I will feed them with wormwood, And make them drink the water of gall; For from the prophets of Jerusalem Profaneness has gone out into all the land.\"'", 
                "num": 15
              }, 
              {
                "text": "Thus says the LORD of hosts: \"Do not listen to the words of the prophets who prophesy to you. They make you worthless; They speak a vision of their own heart, Not from the mouth of the LORD.", 
                "num": 16
              }, 
              {
                "text": "They continually say to those who despise Me, \"The LORD has said, \"You shall have peace\"'; And to everyone who walks according to the dictates of his own heart, they say, \"No evil shall come upon you.\"'", 
                "num": 17
              }, 
              {
                "text": "For who has stood in the counsel of the LORD, And has perceived and heard His word? Who has marked His word and heard it?", 
                "num": 18
              }, 
              {
                "text": "Behold, a whirlwind of the LORD has gone forth in fury-- A violent whirlwind! It will fall violently on the head of the wicked.", 
                "num": 19
              }, 
              {
                "text": "The anger of the LORD will not turn back Until He has executed and performed the thoughts of His heart. In the latter days you will understand it perfectly.", 
                "num": 20
              }, 
              {
                "text": "\"I have not sent these prophets, yet they ran. I have not spoken to them, yet they prophesied.", 
                "num": 21
              }, 
              {
                "text": "But if they had stood in My counsel, And had caused My people to hear My words, Then they would have turned them from their evil way And from the evil of their doings.", 
                "num": 22
              }, 
              {
                "text": "\"Am I a God near at hand,\" says the LORD, \"And not a God afar off?", 
                "num": 23
              }, 
              {
                "text": "Can anyone hide himself in secret places, So I shall not see him?\" says the LORD; \"Do I not fill heaven and earth?\" says the LORD.", 
                "num": 24
              }, 
              {
                "text": "\"I have heard what the prophets have said who prophesy lies in My name, saying, \"I have dreamed, I have dreamed!'", 
                "num": 25
              }, 
              {
                "text": "How long will this be in the heart of the prophets who prophesy lies? Indeed they are prophets of the deceit of their own heart,", 
                "num": 26
              }, 
              {
                "text": "who try to make My people forget My name by their dreams which everyone tells his neighbor, as their fathers forgot My name for Baal.", 
                "num": 27
              }, 
              {
                "text": "\"The prophet who has a dream, let him tell a dream; And he who has My word, let him speak My word faithfully. What is the chaff to the wheat?\" says the LORD.", 
                "num": 28
              }, 
              {
                "text": "\"Is not My word like a fire?\" says the LORD, \"And like a hammer that breaks the rock in pieces?", 
                "num": 29
              }, 
              {
                "text": "\"Therefore behold, I am against the prophets,\" says the LORD, \"who steal My words every one from his neighbor.", 
                "num": 30
              }, 
              {
                "text": "Behold, I am against the prophets,\" says the LORD, \"who use their tongues and say, \"He says.'", 
                "num": 31
              }, 
              {
                "text": "Behold, I am against those who prophesy false dreams,\" says the LORD, \"and tell them, and cause My people to err by their lies and by their recklessness. Yet I did not send them or command them; therefore they shall not profit this people at all,\" says the LORD.", 
                "num": 32
              }, 
              {
                "text": "\"So when these people or the prophet or the priest ask you, saying, \"What is the oracle of the LORD?' you shall then say to them, \"What oracle?' I will even forsake you,\" says the LORD.", 
                "num": 33
              }, 
              {
                "text": "\"And as for the prophet and the priest and the people who say, \"The oracle of the LORD!' I will even punish that man and his house.", 
                "num": 34
              }, 
              {
                "text": "Thus every one of you shall say to his neighbor, and every one to his brother, \"What has the LORD answered?' and, \"What has the LORD spoken?'", 
                "num": 35
              }, 
              {
                "text": "And the oracle of the LORD you shall mention no more. For every man's word will be his oracle, for you have perverted the words of the living God, the LORD of hosts, our God.", 
                "num": 36
              }, 
              {
                "text": "Thus you shall say to the prophet, \"What has the LORD answered you?' and, \"What has the LORD spoken?'", 
                "num": 37
              }, 
              {
                "text": "But since you say, \"The oracle of the LORD!' therefore thus says the LORD: \"Because you say this word, \"The oracle of the LORD!\" and I have sent to you, saying, \"Do not say, \"The oracle of the LORD!\"'", 
                "num": 38
              }, 
              {
                "text": "therefore behold, I, even I, will utterly forget you and forsake you, and the city that I gave you and your fathers, and will cast you out of My presence.", 
                "num": 39
              }, 
              {
                "text": "And I will bring an everlasting reproach upon you, and a perpetual shame, which shall not be forgotten.\"'", 
                "num": 40
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "The LORD showed me, and there were two baskets of figs set before the temple of the LORD, after Nebuchadnezzar king of Babylon had carried away captive Jeconiah the son of Jehoiakim, king of Judah, and the princes of Judah with the craftsmen and smiths, from Jerusalem, and had brought them to Babylon.", 
                "num": 1
              }, 
              {
                "text": "One basket had very good figs, like the figs that are first ripe; and the other basket had very bad figs which could not be eaten, they were so bad.", 
                "num": 2
              }, 
              {
                "text": "Then the LORD said to me, \"What do you see, Jeremiah?\" And I said, \"Figs, the good figs, very good; and the bad, very bad, which cannot be eaten, they are so bad.\"", 
                "num": 3
              }, 
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Thus says the LORD, the God of Israel: \"Like these good figs, so will I acknowledge those who are carried away captive from Judah, whom I have sent out of this place for their own good, into the land of the Chaldeans.", 
                "num": 5
              }, 
              {
                "text": "For I will set My eyes on them for good, and I will bring them back to this land; I will build them and not pull them down, and I will plant them and not pluck them up.", 
                "num": 6
              }, 
              {
                "text": "Then I will give them a heart to know Me, that I am the LORD; and they shall be My people, and I will be their God, for they shall return to Me with their whole heart.", 
                "num": 7
              }, 
              {
                "text": "\"And as the bad figs which cannot be eaten, they are so bad'--surely thus says the LORD--\"so will I give up Zedekiah the king of Judah, his princes, the residue of Jerusalem who remain in this land, and those who dwell in the land of Egypt.", 
                "num": 8
              }, 
              {
                "text": "I will deliver them to trouble into all the kingdoms of the earth, for their harm, to be a reproach and a byword, a taunt and a curse, in all places where I shall drive them.", 
                "num": 9
              }, 
              {
                "text": "And I will send the sword, the famine, and the pestilence among them, till they are consumed from the land that I gave to them and their fathers.\"'", 
                "num": 10
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah concerning all the people of Judah, in the fourth year of Jehoiakim the son of Josiah, king of Judah (which was the first year of Nebuchadnezzar king of Babylon),", 
                "num": 1
              }, 
              {
                "text": "which Jeremiah the prophet spoke to all the people of Judah and to all the inhabitants of Jerusalem, saying:", 
                "num": 2
              }, 
              {
                "text": "\"From the thirteenth year of Josiah the son of Amon, king of Judah, even to this day, this is the twenty-third year in which the word of the LORD has come to me; and I have spoken to you, rising early and speaking, but you have not listened.", 
                "num": 3
              }, 
              {
                "text": "And the LORD has sent to you all His servants the prophets, rising early and sending them, but you have not listened nor inclined your ear to hear.", 
                "num": 4
              }, 
              {
                "text": "They said, \"Repent now everyone of his evil way and his evil doings, and dwell in the land that the LORD has given to you and your fathers forever and ever.", 
                "num": 5
              }, 
              {
                "text": "Do not go after other gods to serve them and worship them, and do not provoke Me to anger with the works of your hands; and I will not harm you.'", 
                "num": 6
              }, 
              {
                "text": "Yet you have not listened to Me,\" says the LORD, \"that you might provoke Me to anger with the works of your hands to your own hurt.", 
                "num": 7
              }, 
              {
                "text": "\"Therefore thus says the LORD of hosts: \"Because you have not heard My words,", 
                "num": 8
              }, 
              {
                "text": "behold, I will send and take all the families of the north,' says the LORD, \"and Nebuchadnezzar the king of Babylon, My servant, and will bring them against this land, against its inhabitants, and against these nations all around, and will utterly destroy them, and make them an astonishment, a hissing, and perpetual desolations.", 
                "num": 9
              }, 
              {
                "text": "Moreover I will take from them the voice of mirth and the voice of gladness, the voice of the bridegroom and the voice of the bride, the sound of the millstones and the light of the lamp.", 
                "num": 10
              }, 
              {
                "text": "And this whole land shall be a desolation and an astonishment, and these nations shall serve the king of Babylon seventy years.", 
                "num": 11
              }, 
              {
                "text": "\"Then it will come to pass, when seventy years are completed, that I will punish the king of Babylon and that nation, the land of the Chaldeans, for their iniquity,' says the LORD; \"and I will make it a perpetual desolation.", 
                "num": 12
              }, 
              {
                "text": "So I will bring on that land all My words which I have pronounced against it, all that is written in this book, which Jeremiah has prophesied concerning all the nations.", 
                "num": 13
              }, 
              {
                "text": "(For many nations and great kings shall be served by them also; and I will repay them according to their deeds and according to the works of their own hands.)\"'", 
                "num": 14
              }, 
              {
                "text": "For thus says the LORD God of Israel to me: \"Take this wine cup of fury from My hand, and cause all the nations, to whom I send you, to drink it.", 
                "num": 15
              }, 
              {
                "text": "And they will drink and stagger and go mad because of the sword that I will send among them.\"", 
                "num": 16
              }, 
              {
                "text": "Then I took the cup from the LORD's hand, and made all the nations drink, to whom the LORD had sent me:", 
                "num": 17
              }, 
              {
                "text": "Jerusalem and the cities of Judah, its kings and its princes, to make them a desolation, an astonishment, a hissing, and a curse, as it is this day;", 
                "num": 18
              }, 
              {
                "text": "Pharaoh king of Egypt, his servants, his princes, and all his people;", 
                "num": 19
              }, 
              {
                "text": "all the mixed multitude, all the kings of the land of Uz, all the kings of the land of the Philistines (namely, Ashkelon, Gaza, Ekron, and the remnant of Ashdod);", 
                "num": 20
              }, 
              {
                "text": "Edom, Moab, and the people of Ammon;", 
                "num": 21
              }, 
              {
                "text": "all the kings of Tyre, all the kings of Sidon, and the kings of the coastlands which are across the sea;", 
                "num": 22
              }, 
              {
                "text": "Dedan, Tema, Buz, and all who are in the farthest corners;", 
                "num": 23
              }, 
              {
                "text": "all the kings of Arabia and all the kings of the mixed multitude who dwell in the desert;", 
                "num": 24
              }, 
              {
                "text": "all the kings of Zimri, all the kings of Elam, and all the kings of the Medes;", 
                "num": 25
              }, 
              {
                "text": "all the kings of the north, far and near, one with another; and all the kingdoms of the world which are on the face of the earth. Also the king of Sheshach shall drink after them.", 
                "num": 26
              }, 
              {
                "text": "\"Therefore you shall say to them, \"Thus says the LORD of hosts, the God of Israel: \"Drink, be drunk, and vomit! Fall and rise no more, because of the sword which I will send among you.\"'", 
                "num": 27
              }, 
              {
                "text": "And it shall be, if they refuse to take the cup from your hand to drink, then you shall say to them, \"Thus says the LORD of hosts: \"You shall certainly drink!", 
                "num": 28
              }, 
              {
                "text": "For behold, I begin to bring calamity on the city which is called by My name, and should you be utterly unpunished? You shall not be unpunished, for I will call for a sword on all the inhabitants of the earth,\" says the LORD of hosts.'", 
                "num": 29
              }, 
              {
                "text": "\"Therefore prophesy against them all these words, and say to them: \"The LORD will roar from on high, And utter His voice from His holy habitation; He will roar mightily against His fold. He will give a shout, as those who tread the grapes, Against all the inhabitants of the earth.", 
                "num": 30
              }, 
              {
                "text": "A noise will come to the ends of the earth-- For the LORD has a controversy with the nations; He will plead His case with all flesh. He will give those who are wicked to the sword,' says the LORD.\"", 
                "num": 31
              }, 
              {
                "text": "Thus says the LORD of hosts: \"Behold, disaster shall go forth From nation to nation, And a great whirlwind shall be raised up From the farthest parts of the earth.", 
                "num": 32
              }, 
              {
                "text": "\"And at that day the slain of the LORD shall be from one end of the earth even to the other end of the earth. They shall not be lamented, or gathered, or buried; they shall become refuse on the ground.", 
                "num": 33
              }, 
              {
                "text": "\"Wail, shepherds, and cry! Roll about in the ashes, You leaders of the flock! For the days of your slaughter and your dispersions are fulfilled; You shall fall like a precious vessel.", 
                "num": 34
              }, 
              {
                "text": "And the shepherds will have no way to flee, Nor the leaders of the flock to escape.", 
                "num": 35
              }, 
              {
                "text": "A voice of the cry of the shepherds, And a wailing of the leaders to the flock will be heard. For the LORD has plundered their pasture,", 
                "num": 36
              }, 
              {
                "text": "And the peaceful dwellings are cut down Because of the fierce anger of the LORD.", 
                "num": 37
              }, 
              {
                "text": "He has left His lair like the lion; For their land is desolate Because of the fierceness of the Oppressor, And because of His fierce anger.\"", 
                "num": 38
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "In the beginning of the reign of Jehoiakim the son of Josiah, king of Judah, this word came from the LORD, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD: \"Stand in the court of the LORD's house, and speak to all the cities of Judah, which come to worship in the LORD's house, all the words that I command you to speak to them. Do not diminish a word.", 
                "num": 2
              }, 
              {
                "text": "Perhaps everyone will listen and turn from his evil way, that I may relent concerning the calamity which I purpose to bring on them because of the evil of their doings.'", 
                "num": 3
              }, 
              {
                "text": "And you shall say to them, \"Thus says the LORD: \"If you will not listen to Me, to walk in My law which I have set before you,", 
                "num": 4
              }, 
              {
                "text": "to heed the words of My servants the prophets whom I sent to you, both rising up early and sending them (but you have not heeded),", 
                "num": 5
              }, 
              {
                "text": "then I will make this house like Shiloh, and will make this city a curse to all the nations of the earth.\"\"'", 
                "num": 6
              }, 
              {
                "text": "So the priests and the prophets and all the people heard Jeremiah speaking these words in the house of the LORD.", 
                "num": 7
              }, 
              {
                "text": "Now it happened, when Jeremiah had made an end of speaking all that the LORD had commanded him to speak to all the people, that the priests and the prophets and all the people seized him, saying, \"You will surely die!", 
                "num": 8
              }, 
              {
                "text": "Why have you prophesied in the name of the LORD, saying, \"This house shall be like Shiloh, and this city shall be desolate, without an inhabitant'?\" And all the people were gathered against Jeremiah in the house of the LORD.", 
                "num": 9
              }, 
              {
                "text": "When the princes of Judah heard these things, they came up from the king's house to the house of the LORD and sat down in the entry of the New Gate of the LORD's house.", 
                "num": 10
              }, 
              {
                "text": "And the priests and the prophets spoke to the princes and all the people, saying, \"This man deserves to die! For he has prophesied against this city, as you have heard with your ears.\"", 
                "num": 11
              }, 
              {
                "text": "Then Jeremiah spoke to all the princes and all the people, saying: \"The LORD sent me to prophesy against this house and against this city with all the words that you have heard.", 
                "num": 12
              }, 
              {
                "text": "Now therefore, amend your ways and your doings, and obey the voice of the LORD your God; then the LORD will relent concerning the doom that He has pronounced against you.", 
                "num": 13
              }, 
              {
                "text": "As for me, here I am, in your hand; do with me as seems good and proper to you.", 
                "num": 14
              }, 
              {
                "text": "But know for certain that if you put me to death, you will surely bring innocent blood on yourselves, on this city, and on its inhabitants; for truly the LORD has sent me to you to speak all these words in your hearing.\"", 
                "num": 15
              }, 
              {
                "text": "So the princes and all the people said to the priests and the prophets, \"This man does not deserve to die. For he has spoken to us in the name of the LORD our God.\"", 
                "num": 16
              }, 
              {
                "text": "Then certain of the elders of the land rose up and spoke to all the assembly of the people, saying:", 
                "num": 17
              }, 
              {
                "text": "\"Micah of Moresheth prophesied in the days of Hezekiah king of Judah, and spoke to all the people of Judah, saying, \"Thus says the LORD of hosts: \"Zion shall be plowed like a field, Jerusalem shall become heaps of ruins, And the mountain of the temple Like the bare hills of the forest.\"'", 
                "num": 18
              }, 
              {
                "text": "Did Hezekiah king of Judah and all Judah ever put him to death? Did he not fear the LORD and seek the LORD's favor? And the LORD relented concerning the doom which He had pronounced against them. But we are doing great evil against ourselves.\"", 
                "num": 19
              }, 
              {
                "text": "Now there was also a man who prophesied in the name of the LORD, Urijah the son of Shemaiah of Kirjath Jearim, who prophesied against this city and against this land according to all the words of Jeremiah.", 
                "num": 20
              }, 
              {
                "text": "And when Jehoiakim the king, with all his mighty men and all the princes, heard his words, the king sought to put him to death; but when Urijah heard it, he was afraid and fled, and went to Egypt.", 
                "num": 21
              }, 
              {
                "text": "Then Jehoiakim the king sent men to Egypt: Elnathan the son of Achbor, and other men who went with him to Egypt.", 
                "num": 22
              }, 
              {
                "text": "And they brought Urijah from Egypt and brought him to Jehoiakim the king, who killed him with the sword and cast his dead body into the graves of the common people.", 
                "num": 23
              }, 
              {
                "text": "Nevertheless the hand of Ahikam the son of Shaphan was with Jeremiah, so that they should not give him into the hand of the people to put him to death.", 
                "num": 24
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "In the beginning of the reign of Jehoiakim the son of Josiah, king of Judah, this word came to Jeremiah from the LORD, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD to me: \"Make for yourselves bonds and yokes, and put them on your neck,", 
                "num": 2
              }, 
              {
                "text": "and send them to the king of Edom, the king of Moab, the king of the Ammonites, the king of Tyre, and the king of Sidon, by the hand of the messengers who come to Jerusalem to Zedekiah king of Judah.", 
                "num": 3
              }, 
              {
                "text": "And command them to say to their masters, \"Thus says the LORD of hosts, the God of Israel--thus you shall say to your masters:", 
                "num": 4
              }, 
              {
                "text": "\"I have made the earth, the man and the beast that are on the ground, by My great power and by My outstretched arm, and have given it to whom it seemed proper to Me.", 
                "num": 5
              }, 
              {
                "text": "And now I have given all these lands into the hand of Nebuchadnezzar the king of Babylon, My servant; and the beasts of the field I have also given him to serve him.", 
                "num": 6
              }, 
              {
                "text": "So all nations shall serve him and his son and his son's son, until the time of his land comes; and then many nations and great kings shall make him serve them.", 
                "num": 7
              }, 
              {
                "text": "And it shall be, that the nation and kingdom which will not serve Nebuchadnezzar the king of Babylon, and which will not put its neck under the yoke of the king of Babylon, that nation I will punish,' says the LORD, \"with the sword, the famine, and the pestilence, until I have consumed them by his hand.", 
                "num": 8
              }, 
              {
                "text": "Therefore do not listen to your prophets, your diviners, your dreamers, your soothsayers, or your sorcerers, who speak to you, saying, \"You shall not serve the king of Babylon.\"", 
                "num": 9
              }, 
              {
                "text": "For they prophesy a lie to you, to remove you far from your land; and I will drive you out, and you will perish.", 
                "num": 10
              }, 
              {
                "text": "But the nations that bring their necks under the yoke of the king of Babylon and serve him, I will let them remain in their own land,' says the LORD, \"and they shall till it and dwell in it.\"\"\"", 
                "num": 11
              }, 
              {
                "text": "I also spoke to Zedekiah king of Judah according to all these words, saying, \"Bring your necks under the yoke of the king of Babylon, and serve him and his people, and live!", 
                "num": 12
              }, 
              {
                "text": "Why will you die, you and your people, by the sword, by the famine, and by the pestilence, as the LORD has spoken against the nation that will not serve the king of Babylon?", 
                "num": 13
              }, 
              {
                "text": "Therefore do not listen to the words of the prophets who speak to you, saying, \"You shall not serve the king of Babylon,' for they prophesy a lie to you;", 
                "num": 14
              }, 
              {
                "text": "for I have not sent them,\" says the LORD, \"yet they prophesy a lie in My name, that I may drive you out, and that you may perish, you and the prophets who prophesy to you.\"", 
                "num": 15
              }, 
              {
                "text": "Also I spoke to the priests and to all this people, saying, \"Thus says the LORD: \"Do not listen to the words of your prophets who prophesy to you, saying, \"Behold, the vessels of the LORD's house will now shortly be brought back from Babylon\"; for they prophesy a lie to you.", 
                "num": 16
              }, 
              {
                "text": "Do not listen to them; serve the king of Babylon, and live! Why should this city be laid waste?", 
                "num": 17
              }, 
              {
                "text": "But if they are prophets, and if the word of the LORD is with them, let them now make intercession to the LORD of hosts, that the vessels which are left in the house of the LORD, in the house of the king of Judah, and at Jerusalem, do not go to Babylon.'", 
                "num": 18
              }, 
              {
                "text": "\"For thus says the LORD of hosts concerning the pillars, concerning the Sea, concerning the carts, and concerning the remainder of the vessels that remain in this city,", 
                "num": 19
              }, 
              {
                "text": "which Nebuchadnezzar king of Babylon did not take, when he carried away captive Jeconiah the son of Jehoiakim, king of Judah, from Jerusalem to Babylon, and all the nobles of Judah and Jerusalem--", 
                "num": 20
              }, 
              {
                "text": "yes, thus says the LORD of hosts, the God of Israel, concerning the vessels that remain in the house of the LORD, and in the house of the king of Judah and of Jerusalem:", 
                "num": 21
              }, 
              {
                "text": "\"They shall be carried to Babylon, and there they shall be until the day that I visit them,' says the LORD. \"Then I will bring them up and restore them to this place.\"'", 
                "num": 22
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "And it happened in the same year, at the beginning of the reign of Zedekiah king of Judah, in the fourth year and in the fifth month, that Hananiah the son of Azur the prophet, who was from Gibeon, spoke to me in the house of the LORD in the presence of the priests and of all the people, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus speaks the LORD of hosts, the God of Israel, saying: \"I have broken the yoke of the king of Babylon.", 
                "num": 2
              }, 
              {
                "text": "Within two full years I will bring back to this place all the vessels of the LORD's house, that Nebuchadnezzar king of Babylon took away from this place and carried to Babylon.", 
                "num": 3
              }, 
              {
                "text": "And I will bring back to this place Jeconiah the son of Jehoiakim, king of Judah, with all the captives of Judah who went to Babylon,' says the LORD, \"for I will break the yoke of the king of Babylon.\"'", 
                "num": 4
              }, 
              {
                "text": "Then the prophet Jeremiah spoke to the prophet Hananiah in the presence of the priests and in the presence of all the people who stood in the house of the LORD,", 
                "num": 5
              }, 
              {
                "text": "and the prophet Jeremiah said, \"Amen! The LORD do so; the LORD perform your words which you have prophesied, to bring back the vessels of the LORD's house and all who were carried away captive, from Babylon to this place.", 
                "num": 6
              }, 
              {
                "text": "Nevertheless hear now this word that I speak in your hearing and in the hearing of all the people:", 
                "num": 7
              }, 
              {
                "text": "The prophets who have been before me and before you of old prophesied against many countries and great kingdoms--of war and disaster and pestilence.", 
                "num": 8
              }, 
              {
                "text": "As for the prophet who prophesies of peace, when the word of the prophet comes to pass, the prophet will be known as one whom the LORD has truly sent.\"", 
                "num": 9
              }, 
              {
                "text": "Then Hananiah the prophet took the yoke off the prophet Jeremiah's neck and broke it.", 
                "num": 10
              }, 
              {
                "text": "And Hananiah spoke in the presence of all the people, saying, \"Thus says the LORD: \"Even so I will break the yoke of Nebuchadnezzar king of Babylon from the neck of all nations within the space of two full years.\"' And the prophet Jeremiah went his way.", 
                "num": 11
              }, 
              {
                "text": "Now the word of the LORD came to Jeremiah, after Hananiah the prophet had broken the yoke from the neck of the prophet Jeremiah, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Go and tell Hananiah, saying, \"Thus says the LORD: \"You have broken the yokes of wood, but you have made in their place yokes of iron.\"", 
                "num": 13
              }, 
              {
                "text": "For thus says the LORD of hosts, the God of Israel: \"I have put a yoke of iron on the neck of all these nations, that they may serve Nebuchadnezzar king of Babylon; and they shall serve him. I have given him the beasts of the field also.\"\"'", 
                "num": 14
              }, 
              {
                "text": "Then the prophet Jeremiah said to Hananiah the prophet, \"Hear now, Hananiah, the LORD has not sent you, but you make this people trust in a lie.", 
                "num": 15
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, I will cast you from the face of the earth. This year you shall die, because you have taught rebellion against the LORD.\"'", 
                "num": 16
              }, 
              {
                "text": "So Hananiah the prophet died the same year in the seventh month.", 
                "num": 17
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "Now these are the words of the letter that Jeremiah the prophet sent from Jerusalem to the remainder of the elders who were carried away captive--to the priests, the prophets, and all the people whom Nebuchadnezzar had carried away captive from Jerusalem to Babylon.", 
                "num": 1
              }, 
              {
                "text": "(This happened after Jeconiah the king, the queen mother, the eunuchs, the princes of Judah and Jerusalem, the craftsmen, and the smiths had departed from Jerusalem.)", 
                "num": 2
              }, 
              {
                "text": "The letter was sent by the hand of Elasah the son of Shaphan, and Gemariah the son of Hilkiah, whom Zedekiah king of Judah sent to Babylon, to Nebuchadnezzar king of Babylon, saying,", 
                "num": 3
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel, to all who were carried away captive, whom I have caused to be carried away from Jerusalem to Babylon:", 
                "num": 4
              }, 
              {
                "text": "Build houses and dwell in them; plant gardens and eat their fruit.", 
                "num": 5
              }, 
              {
                "text": "Take wives and beget sons and daughters; and take wives for your sons and give your daughters to husbands, so that they may bear sons and daughters--that you may be increased there, and not diminished.", 
                "num": 6
              }, 
              {
                "text": "And seek the peace of the city where I have caused you to be carried away captive, and pray to the LORD for it; for in its peace you will have peace.", 
                "num": 7
              }, 
              {
                "text": "For thus says the LORD of hosts, the God of Israel: Do not let your prophets and your diviners who are in your midst deceive you, nor listen to your dreams which you cause to be dreamed.", 
                "num": 8
              }, 
              {
                "text": "For they prophesy falsely to you in My name; I have not sent them, says the LORD.", 
                "num": 9
              }, 
              {
                "text": "For thus says the LORD: After seventy years are completed at Babylon, I will visit you and perform My good word toward you, and cause you to return to this place.", 
                "num": 10
              }, 
              {
                "text": "For I know the thoughts that I think toward you, says the LORD, thoughts of peace and not of evil, to give you a future and a hope.", 
                "num": 11
              }, 
              {
                "text": "Then you will call upon Me and go and pray to Me, and I will listen to you.", 
                "num": 12
              }, 
              {
                "text": "And you will seek Me and find Me, when you search for Me with all your heart.", 
                "num": 13
              }, 
              {
                "text": "I will be found by you, says the LORD, and I will bring you back from your captivity; I will gather you from all the nations and from all the places where I have driven you, says the LORD, and I will bring you to the place from which I cause you to be carried away captive.", 
                "num": 14
              }, 
              {
                "text": "Because you have said, \"The LORD has raised up prophets for us in Babylon\"--", 
                "num": 15
              }, 
              {
                "text": "therefore thus says the LORD concerning the king who sits on the throne of David, concerning all the people who dwell in this city, and concerning your brethren who have not gone out with you into captivity--", 
                "num": 16
              }, 
              {
                "text": "thus says the LORD of hosts: Behold, I will send on them the sword, the famine, and the pestilence, and will make them like rotten figs that cannot be eaten, they are so bad.", 
                "num": 17
              }, 
              {
                "text": "And I will pursue them with the sword, with famine, and with pestilence; and I will deliver them to trouble among all the kingdoms of the earth--to be a curse, an astonishment, a hissing, and a reproach among all the nations where I have driven them,", 
                "num": 18
              }, 
              {
                "text": "because they have not heeded My words, says the LORD, which I sent to them by My servants the prophets, rising up early and sending them; neither would you heed, says the LORD.", 
                "num": 19
              }, 
              {
                "text": "Therefore hear the word of the LORD, all you of the captivity, whom I have sent from Jerusalem to Babylon.", 
                "num": 20
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel, concerning Ahab the son of Kolaiah, and Zedekiah the son of Maaseiah, who prophesy a lie to you in My name: Behold, I will deliver them into the hand of Nebuchadnezzar king of Babylon, and he shall slay them before your eyes.", 
                "num": 21
              }, 
              {
                "text": "And because of them a curse shall be taken up by all the captivity of Judah who are in Babylon, saying, \"The LORD make you like Zedekiah and Ahab, whom the king of Babylon roasted in the fire\";", 
                "num": 22
              }, 
              {
                "text": "because they have done disgraceful things in Israel, have committed adultery with their neighbors' wives, and have spoken lying words in My name, which I have not commanded them. Indeed I know, and am a witness, says the LORD.", 
                "num": 23
              }, 
              {
                "text": "You shall also speak to Shemaiah the Nehelamite, saying,", 
                "num": 24
              }, 
              {
                "text": "Thus speaks the LORD of hosts, the God of Israel, saying: You have sent letters in your name to all the people who are at Jerusalem, to Zephaniah the son of Maaseiah the priest, and to all the priests, saying,", 
                "num": 25
              }, 
              {
                "text": "\"The LORD has made you priest instead of Jehoiada the priest, so that there should be officers in the house of the LORD over every man who is demented and considers himself a prophet, that you should put him in prison and in the stocks.", 
                "num": 26
              }, 
              {
                "text": "Now therefore, why have you not rebuked Jeremiah of Anathoth who makes himself a prophet to you?", 
                "num": 27
              }, 
              {
                "text": "For he has sent to us in Babylon, saying, \"This captivity is long; build houses and dwell in them, and plant gardens and eat their fruit.\"'", 
                "num": 28
              }, 
              {
                "text": "Now Zephaniah the priest read this letter in the hearing of Jeremiah the prophet.", 
                "num": 29
              }, 
              {
                "text": "Then the word of the LORD came to Jeremiah, saying:", 
                "num": 30
              }, 
              {
                "text": "Send to all those in captivity, saying, Thus says the LORD concerning Shemaiah the Nehelamite: Because Shemaiah has prophesied to you, and I have not sent him, and he has caused you to trust in a lie--", 
                "num": 31
              }, 
              {
                "text": "therefore thus says the LORD: Behold, I will punish Shemaiah the Nehelamite and his family: he shall not have anyone to dwell among this people, nor shall he see the good that I will do for My people, says the LORD, because he has taught rebellion against the LORD.", 
                "num": 32
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah from the LORD, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus speaks the LORD God of Israel, saying: \"Write in a book for yourself all the words that I have spoken to you.", 
                "num": 2
              }, 
              {
                "text": "For behold, the days are coming,' says the LORD, \"that I will bring back from captivity My people Israel and Judah,' says the LORD. \"And I will cause them to return to the land that I gave to their fathers, and they shall possess it.\"'", 
                "num": 3
              }, 
              {
                "text": "Now these are the words that the LORD spoke concerning Israel and Judah.", 
                "num": 4
              }, 
              {
                "text": "\"For thus says the LORD: \"We have heard a voice of trembling, Of fear, and not of peace.", 
                "num": 5
              }, 
              {
                "text": "Ask now, and see, Whether a man is ever in labor with child? So why do I see every man with his hands on his loins Like a woman in labor, And all faces turned pale?", 
                "num": 6
              }, 
              {
                "text": "Alas! For that day is great, So that none is like it; And it is the time of Jacob's trouble, But he shall be saved out of it.", 
                "num": 7
              }, 
              {
                "text": "\"For it shall come to pass in that day,' Says the LORD of hosts, \"That I will break his yoke from your neck, And will burst your bonds; Foreigners shall no more enslave them.", 
                "num": 8
              }, 
              {
                "text": "But they shall serve the LORD their God, And David their king, Whom I will raise up for them.", 
                "num": 9
              }, 
              {
                "text": "\"Therefore do not fear, O My servant Jacob,' says the LORD, \"Nor be dismayed, O Israel; For behold, I will save you from afar, And your seed from the land of their captivity. Jacob shall return, have rest and be quiet, And no one shall make him afraid.", 
                "num": 10
              }, 
              {
                "text": "For I am with you,' says the LORD, \"to save you; Though I make a full end of all nations where I have scattered you, Yet I will not make a complete end of you. But I will correct you in justice, And will not let you go altogether unpunished.'", 
                "num": 11
              }, 
              {
                "text": "\"For thus says the LORD: \"Your affliction is incurable, Your wound is severe.", 
                "num": 12
              }, 
              {
                "text": "There is no one to plead your cause, That you may be bound up; You have no healing medicines.", 
                "num": 13
              }, 
              {
                "text": "All your lovers have forgotten you; They do not seek you; For I have wounded you with the wound of an enemy, With the chastisement of a cruel one, For the multitude of your iniquities, Because your sins have increased.", 
                "num": 14
              }, 
              {
                "text": "Why do you cry about your affliction? Your sorrow is incurable. Because of the multitude of your iniquities, Because your sins have increased, I have done these things to you.", 
                "num": 15
              }, 
              {
                "text": "\"Therefore all those who devour you shall be devoured; And all your adversaries, every one of them, shall go into captivity; Those who plunder you shall become plunder, And all who prey upon you I will make a prey.", 
                "num": 16
              }, 
              {
                "text": "For I will restore health to you And heal you of your wounds,' says the LORD, \"Because they called you an outcast saying: \"This is Zion; No one seeks her.\"'", 
                "num": 17
              }, 
              {
                "text": "\"Thus says the LORD: \"Behold, I will bring back the captivity of Jacob's tents, And have mercy on his dwelling places; The city shall be built upon its own mound, And the palace shall remain according to its own plan.", 
                "num": 18
              }, 
              {
                "text": "Then out of them shall proceed thanksgiving And the voice of those who make merry; I will multiply them, and they shall not diminish; I will also glorify them, and they shall not be small.", 
                "num": 19
              }, 
              {
                "text": "Their children also shall be as before, And their congregation shall be established before Me; And I will punish all who oppress them.", 
                "num": 20
              }, 
              {
                "text": "Their nobles shall be from among them, And their governor shall come from their midst; Then I will cause him to draw near, And he shall approach Me; For who is this who pledged his heart to approach Me?' says the LORD.", 
                "num": 21
              }, 
              {
                "text": "\"You shall be My people, And I will be your God.\"'", 
                "num": 22
              }, 
              {
                "text": "Behold, the whirlwind of the LORD Goes forth with fury, A continuing whirlwind; It will fall violently on the head of the wicked.", 
                "num": 23
              }, 
              {
                "text": "The fierce anger of the LORD will not return until He has done it, And until He has performed the intents of His heart. In the latter days you will consider it.", 
                "num": 24
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "\"At the same time,\" says the LORD, \"I will be the God of all the families of Israel, and they shall be My people.\"", 
                "num": 1
              }, 
              {
                "text": "Thus says the LORD: \"The people who survived the sword Found grace in the wilderness-- Israel, when I went to give him rest.\"", 
                "num": 2
              }, 
              {
                "text": "The LORD has appeared of old to me, saying: \"Yes, I have loved you with an everlasting love; Therefore with lovingkindness I have drawn you.", 
                "num": 3
              }, 
              {
                "text": "Again I will build you, and you shall be rebuilt, O virgin of Israel! You shall again be adorned with your tambourines, And shall go forth in the dances of those who rejoice.", 
                "num": 4
              }, 
              {
                "text": "You shall yet plant vines on the mountains of Samaria; The planters shall plant and eat them as ordinary food.", 
                "num": 5
              }, 
              {
                "text": "For there shall be a day When the watchmen will cry on Mount Ephraim, \"Arise, and let us go up to Zion, To the LORD our God.\"'", 
                "num": 6
              }, 
              {
                "text": "For thus says the LORD: \"Sing with gladness for Jacob, And shout among the chief of the nations; Proclaim, give praise, and say, \"O LORD, save Your people, The remnant of Israel!'", 
                "num": 7
              }, 
              {
                "text": "Behold, I will bring them from the north country, And gather them from the ends of the earth, Among them the blind and the lame, The woman with child And the one who labors with child, together; A great throng shall return there.", 
                "num": 8
              }, 
              {
                "text": "They shall come with weeping, And with supplications I will lead them. I will cause them to walk by the rivers of waters, In a straight way in which they shall not stumble; For I am a Father to Israel, And Ephraim is My firstborn.", 
                "num": 9
              }, 
              {
                "text": "\"Hear the word of the LORD, O nations, And declare it in the isles afar off, and say, \"He who scattered Israel will gather him, And keep him as a shepherd does his flock.'", 
                "num": 10
              }, 
              {
                "text": "For the LORD has redeemed Jacob, And ransomed him from the hand of one stronger than he.", 
                "num": 11
              }, 
              {
                "text": "Therefore they shall come and sing in the height of Zion, Streaming to the goodness of the LORD-- For wheat and new wine and oil, For the young of the flock and the herd; Their souls shall be like a well-watered garden, And they shall sorrow no more at all.", 
                "num": 12
              }, 
              {
                "text": "\"Then shall the virgin rejoice in the dance, And the young men and the old, together; For I will turn their mourning to joy, Will comfort them, And make them rejoice rather than sorrow.", 
                "num": 13
              }, 
              {
                "text": "I will satiate the soul of the priests with abundance, And My people shall be satisfied with My goodness, says the LORD.\"", 
                "num": 14
              }, 
              {
                "text": "Thus says the LORD: \"A voice was heard in Ramah, Lamentation and bitter weeping, Rachel weeping for her children, Refusing to be comforted for her children, Because they are no more.\"", 
                "num": 15
              }, 
              {
                "text": "Thus says the LORD: \"Refrain your voice from weeping, And your eyes from tears; For your work shall be rewarded, says the LORD, And they shall come back from the land of the enemy.", 
                "num": 16
              }, 
              {
                "text": "There is hope in your future, says the LORD, That your children shall come back to their own border.", 
                "num": 17
              }, 
              {
                "text": "\"I have surely heard Ephraim bemoaning himself: \"You have chastised me, and I was chastised, Like an untrained bull; Restore me, and I will return, For You are the LORD my God.", 
                "num": 18
              }, 
              {
                "text": "Surely, after my turning, I repented; And after I was instructed, I struck myself on the thigh; I was ashamed, yes, even humiliated, Because I bore the reproach of my youth.'", 
                "num": 19
              }, 
              {
                "text": "Is Ephraim My dear son? Is he a pleasant child? For though I spoke against him, I earnestly remember him still; Therefore My heart yearns for him; I will surely have mercy on him, says the LORD.", 
                "num": 20
              }, 
              {
                "text": "\"Set up signposts, Make landmarks; Set your heart toward the highway, The way in which you went. Turn back, O virgin of Israel, Turn back to these your cities.", 
                "num": 21
              }, 
              {
                "text": "How long will you gad about, O you backsliding daughter? For the LORD has created a new thing in the earth-- A woman shall encompass a man.\"", 
                "num": 22
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel: \"They shall again use this speech in the land of Judah and in its cities, when I bring back their captivity: \"The LORD bless you, O home of justice, and mountain of holiness!'", 
                "num": 23
              }, 
              {
                "text": "And there shall dwell in Judah itself, and in all its cities together, farmers and those going out with flocks.", 
                "num": 24
              }, 
              {
                "text": "For I have satiated the weary soul, and I have replenished every sorrowful soul.\"", 
                "num": 25
              }, 
              {
                "text": "After this I awoke and looked around, and my sleep was sweet to me.", 
                "num": 26
              }, 
              {
                "text": "\"Behold, the days are coming, says the LORD, that I will sow the house of Israel and the house of Judah with the seed of man and the seed of beast.", 
                "num": 27
              }, 
              {
                "text": "And it shall come to pass, that as I have watched over them to pluck up, to break down, to throw down, to destroy, and to afflict, so I will watch over them to build and to plant, says the LORD.", 
                "num": 28
              }, 
              {
                "text": "In those days they shall say no more: \"The fathers have eaten sour grapes, And the children's teeth are set on edge.'", 
                "num": 29
              }, 
              {
                "text": "But every one shall die for his own iniquity; every man who eats the sour grapes, his teeth shall be set on edge.", 
                "num": 30
              }, 
              {
                "text": "\"Behold, the days are coming, says the LORD, when I will make a new covenant with the house of Israel and with the house of Judah--", 
                "num": 31
              }, 
              {
                "text": "not according to the covenant that I made with their fathers in the day that I took them by the hand to lead them out of the land of Egypt, My covenant which they broke, though I was a husband to them, says the LORD.", 
                "num": 32
              }, 
              {
                "text": "But this is the covenant that I will make with the house of Israel after those days, says the LORD: I will put My law in their minds, and write it on their hearts; and I will be their God, and they shall be My people.", 
                "num": 33
              }, 
              {
                "text": "No more shall every man teach his neighbor, and every man his brother, saying, \"Know the LORD,' for they all shall know Me, from the least of them to the greatest of them, says the LORD. For I will forgive their iniquity, and their sin I will remember no more.\"", 
                "num": 34
              }, 
              {
                "text": "Thus says the LORD, Who gives the sun for a light by day, The ordinances of the moon and the stars for a light by night, Who disturbs the sea, And its waves roar (The LORD of hosts is His name):", 
                "num": 35
              }, 
              {
                "text": "\"If those ordinances depart From before Me, says the LORD, Then the seed of Israel shall also cease From being a nation before Me forever.\"", 
                "num": 36
              }, 
              {
                "text": "Thus says the LORD: \"If heaven above can be measured, And the foundations of the earth searched out beneath, I will also cast off all the seed of Israel For all that they have done, says the LORD.", 
                "num": 37
              }, 
              {
                "text": "\"Behold, the days are coming, says the LORD, that the city shall be built for the LORD from the Tower of Hananel to the Corner Gate.", 
                "num": 38
              }, 
              {
                "text": "The surveyor's line shall again extend straight forward over the hill Gareb; then it shall turn toward Goath.", 
                "num": 39
              }, 
              {
                "text": "And the whole valley of the dead bodies and of the ashes, and all the fields as far as the Brook Kidron, to the corner of the Horse Gate toward the east, shall be holy to the LORD. It shall not be plucked up or thrown down anymore forever.\"", 
                "num": 40
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah from the LORD in the tenth year of Zedekiah king of Judah, which was the eighteenth year of Nebuchadnezzar.", 
                "num": 1
              }, 
              {
                "text": "For then the king of Babylon's army besieged Jerusalem, and Jeremiah the prophet was shut up in the court of the prison, which was in the king of Judah's house.", 
                "num": 2
              }, 
              {
                "text": "For Zedekiah king of Judah had shut him up, saying, \"Why do you prophesy and say, \"Thus says the LORD: \"Behold, I will give this city into the hand of the king of Babylon, and he shall take it;", 
                "num": 3
              }, 
              {
                "text": "and Zedekiah king of Judah shall not escape from the hand of the Chaldeans, but shall surely be delivered into the hand of the king of Babylon, and shall speak with him face to face, and see him eye to eye;", 
                "num": 4
              }, 
              {
                "text": "then he shall lead Zedekiah to Babylon, and there he shall be until I visit him,\" says the LORD; \"though you fight with the Chaldeans, you shall not succeed\"'?\"", 
                "num": 5
              }, 
              {
                "text": "And Jeremiah said, \"The word of the LORD came to me, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Behold, Hanamel the son of Shallum your uncle will come to you, saying, \"Buy my field which is in Anathoth, for the right of redemption is yours to buy it.\"'", 
                "num": 7
              }, 
              {
                "text": "Then Hanamel my uncle's son came to me in the court of the prison according to the word of the LORD, and said to me, \"Please buy my field that is in Anathoth, which is in the country of Benjamin; for the right of inheritance is yours, and the redemption yours; buy it for yourself.' Then I knew that this was the word of the LORD.", 
                "num": 8
              }, 
              {
                "text": "So I bought the field from Hanamel, the son of my uncle who was in Anathoth, and weighed out to him the money--seventeen shekels of silver.", 
                "num": 9
              }, 
              {
                "text": "And I signed the deed and sealed it, took witnesses, and weighed the money on the scales.", 
                "num": 10
              }, 
              {
                "text": "So I took the purchase deed, both that which was sealed according to the law and custom, and that which was open;", 
                "num": 11
              }, 
              {
                "text": "and I gave the purchase deed to Baruch the son of Neriah, son of Mahseiah, in the presence of Hanamel my uncle's son, and in the presence of the witnesses who signed the purchase deed, before all the Jews who sat in the court of the prison.", 
                "num": 12
              }, 
              {
                "text": "\"Then I charged Baruch before them, saying,", 
                "num": 13
              }, 
              {
                "text": "\"Thus says the LORD of hosts, the God of Israel: \"Take these deeds, both this purchase deed which is sealed and this deed which is open, and put them in an earthen vessel, that they may last many days.\"", 
                "num": 14
              }, 
              {
                "text": "For thus says the LORD of hosts, the God of Israel: \"Houses and fields and vineyards shall be possessed again in this land.\"'", 
                "num": 15
              }, 
              {
                "text": "\"Now when I had delivered the purchase deed to Baruch the son of Neriah, I prayed to the LORD, saying:", 
                "num": 16
              }, 
              {
                "text": "\"Ah, Lord GOD! Behold, You have made the heavens and the earth by Your great power and outstretched arm. There is nothing too hard for You.", 
                "num": 17
              }, 
              {
                "text": "You show lovingkindness to thousands, and repay the iniquity of the fathers into the bosom of their children after them--the Great, the Mighty God, whose name is the LORD of hosts.", 
                "num": 18
              }, 
              {
                "text": "You are great in counsel and mighty in work, for your eyes are open to all the ways of the sons of men, to give everyone according to his ways and according to the fruit of his doings.", 
                "num": 19
              }, 
              {
                "text": "You have set signs and wonders in the land of Egypt, to this day, and in Israel and among other men; and You have made Yourself a name, as it is this day.", 
                "num": 20
              }, 
              {
                "text": "You have brought Your people Israel out of the land of Egypt with signs and wonders, with a strong hand and an outstretched arm, and with great terror;", 
                "num": 21
              }, 
              {
                "text": "You have given them this land, of which You swore to their fathers to give them--\"a land flowing with milk and honey.\"", 
                "num": 22
              }, 
              {
                "text": "And they came in and took possession of it, but they have not obeyed Your voice or walked in Your law. They have done nothing of all that You commanded them to do; therefore You have caused all this calamity to come upon them.", 
                "num": 23
              }, 
              {
                "text": "\"Look, the siege mounds! They have come to the city to take it; and the city has been given into the hand of the Chaldeans who fight against it, because of the sword and famine and pestilence. What You have spoken has happened; there You see it!", 
                "num": 24
              }, 
              {
                "text": "And You have said to me, O Lord GOD, \"Buy the field for money, and take witnesses\"!--yet the city has been given into the hand of the Chaldeans.\"'", 
                "num": 25
              }, 
              {
                "text": "Then the word of the LORD came to Jeremiah, saying,", 
                "num": 26
              }, 
              {
                "text": "\"Behold, I am the LORD, the God of all flesh. Is there anything too hard for Me?", 
                "num": 27
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, I will give this city into the hand of the Chaldeans, into the hand of Nebuchadnezzar king of Babylon, and he shall take it.", 
                "num": 28
              }, 
              {
                "text": "And the Chaldeans who fight against this city shall come and set fire to this city and burn it, with the houses on whose roofs they have offered incense to Baal and poured out drink offerings to other gods, to provoke Me to anger;", 
                "num": 29
              }, 
              {
                "text": "because the children of Israel and the children of Judah have done only evil before Me from their youth. For the children of Israel have provoked Me only to anger with the work of their hands,' says the LORD.", 
                "num": 30
              }, 
              {
                "text": "\"For this city has been to Me a provocation of My anger and My fury from the day that they built it, even to this day; so I will remove it from before My face", 
                "num": 31
              }, 
              {
                "text": "because of all the evil of the children of Israel and the children of Judah, which they have done to provoke Me to anger--they, their kings, their princes, their priests, their prophets, the men of Judah, and the inhabitants of Jerusalem.", 
                "num": 32
              }, 
              {
                "text": "And they have turned to Me the back, and not the face; though I taught them, rising up early and teaching them, yet they have not listened to receive instruction.", 
                "num": 33
              }, 
              {
                "text": "But they set their abominations in the house which is called by My name, to defile it.", 
                "num": 34
              }, 
              {
                "text": "And they built the high places of Baal which are in the Valley of the Son of Hinnom, to cause their sons and their daughters to pass through the fire to Molech, which I did not command them, nor did it come into My mind that they should do this abomination, to cause Judah to sin.'", 
                "num": 35
              }, 
              {
                "text": "\"Now therefore, thus says the LORD, the God of Israel, concerning this city of which you say, \"It shall be delivered into the hand of the king of Babylon by the sword, by the famine, and by the pestilence:", 
                "num": 36
              }, 
              {
                "text": "Behold, I will gather them out of all countries where I have driven them in My anger, in My fury, and in great wrath; I will bring them back to this place, and I will cause them to dwell safely.", 
                "num": 37
              }, 
              {
                "text": "They shall be My people, and I will be their God;", 
                "num": 38
              }, 
              {
                "text": "then I will give them one heart and one way, that they may fear Me forever, for the good of them and their children after them.", 
                "num": 39
              }, 
              {
                "text": "And I will make an everlasting covenant with them, that I will not turn away from doing them good; but I will put My fear in their hearts so that they will not depart from Me.", 
                "num": 40
              }, 
              {
                "text": "Yes, I will rejoice over them to do them good, and I will assuredly plant them in this land, with all My heart and with all My soul.'", 
                "num": 41
              }, 
              {
                "text": "\"For thus says the LORD: \"Just as I have brought all this great calamity on this people, so I will bring on them all the good that I have promised them.", 
                "num": 42
              }, 
              {
                "text": "And fields will be bought in this land of which you say, \"It is desolate, without man or beast; it has been given into the hand of the Chaldeans.\"", 
                "num": 43
              }, 
              {
                "text": "Men will buy fields for money, sign deeds and seal them, and take witnesses, in the land of Benjamin, in the places around Jerusalem, in the cities of Judah, in the cities of the mountains, in the cities of the lowland, and in the cities of the South; for I will cause their captives to return,' says the LORD.\"", 
                "num": 44
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Moreover the word of the LORD came to Jeremiah a second time, while he was still shut up in the court of the prison, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD who made it, the LORD who formed it to establish it (the LORD is His name):", 
                "num": 2
              }, 
              {
                "text": "\"Call to Me, and I will answer you, and show you great and mighty things, which you do not know.'", 
                "num": 3
              }, 
              {
                "text": "\"For thus says the LORD, the God of Israel, concerning the houses of this city and the houses of the kings of Judah, which have been pulled down to fortify against the siege mounds and the sword:", 
                "num": 4
              }, 
              {
                "text": "\"They come to fight with the Chaldeans, but only to fill their places with the dead bodies of men whom I will slay in My anger and My fury, all for whose wickedness I have hidden My face from this city.", 
                "num": 5
              }, 
              {
                "text": "Behold, I will bring it health and healing; I will heal them and reveal to them the abundance of peace and truth.", 
                "num": 6
              }, 
              {
                "text": "And I will cause the captives of Judah and the captives of Israel to return, and will rebuild those places as at the first.", 
                "num": 7
              }, 
              {
                "text": "I will cleanse them from all their iniquity by which they have sinned against Me, and I will pardon all their iniquities by which they have sinned and by which they have transgressed against Me.", 
                "num": 8
              }, 
              {
                "text": "Then it shall be to Me a name of joy, a praise, and an honor before all nations of the earth, who shall hear all the good that I do to them; they shall fear and tremble for all the goodness and all the prosperity that I provide for it.'", 
                "num": 9
              }, 
              {
                "text": "\"Thus says the LORD: \"Again there shall be heard in this place--of which you say, \"It is desolate, without man and without beast\"--in the cities of Judah, in the streets of Jerusalem that are desolate, without man and without inhabitant and without beast,", 
                "num": 10
              }, 
              {
                "text": "the voice of joy and the voice of gladness, the voice of the bridegroom and the voice of the bride, the voice of those who will say: \"Praise the LORD of hosts, For the LORD is good, For His mercy endures forever\"-- and of those who will bring the sacrifice of praise into the house of the LORD. For I will cause the captives of the land to return as at the first,' says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"In this place which is desolate, without man and without beast, and in all its cities, there shall again be a dwelling place of shepherds causing their flocks to lie down.", 
                "num": 12
              }, 
              {
                "text": "In the cities of the mountains, in the cities of the lowland, in the cities of the South, in the land of Benjamin, in the places around Jerusalem, and in the cities of Judah, the flocks shall again pass under the hands of him who counts them,' says the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"Behold, the days are coming,' says the LORD, \"that I will perform that good thing which I have promised to the house of Israel and to the house of Judah:", 
                "num": 14
              }, 
              {
                "text": "\"In those days and at that time I will cause to grow up to David A Branch of righteousness; He shall execute judgment and righteousness in the earth.", 
                "num": 15
              }, 
              {
                "text": "In those days Judah will be saved, And Jerusalem will dwell safely. And this is the name by which she will be called: THE LORD OUR RIGHTEOUSNESS.'", 
                "num": 16
              }, 
              {
                "text": "\"For thus says the LORD: \"David shall never lack a man to sit on the throne of the house of Israel;", 
                "num": 17
              }, 
              {
                "text": "nor shall the priests, the Levites, lack a man to offer burnt offerings before Me, to kindle grain offerings, and to sacrifice continually.\"'", 
                "num": 18
              }, 
              {
                "text": "And the word of the LORD came to Jeremiah, saying,", 
                "num": 19
              }, 
              {
                "text": "\"Thus says the LORD: \"If you can break My covenant with the day and My covenant with the night, so that there will not be day and night in their season,", 
                "num": 20
              }, 
              {
                "text": "then My covenant may also be broken with David My servant, so that he shall not have a son to reign on his throne, and with the Levites, the priests, My ministers.", 
                "num": 21
              }, 
              {
                "text": "As the host of heaven cannot be numbered, nor the sand of the sea measured, so will I multiply the descendants of David My servant and the Levites who minister to Me.\"'", 
                "num": 22
              }, 
              {
                "text": "Moreover the word of the LORD came to Jeremiah, saying,", 
                "num": 23
              }, 
              {
                "text": "\"Have you not considered what these people have spoken, saying, \"The two families which the LORD has chosen, He has also cast them off|'? Thus they have despised My people, as if they should no more be a nation before them.", 
                "num": 24
              }, 
              {
                "text": "\"Thus says the LORD: \"If My covenant is not with day and night, and if I have not appointed the ordinances of heaven and earth,", 
                "num": 25
              }, 
              {
                "text": "then I will cast away the descendants of Jacob and David My servant, so that I will not take any of his descendants to be rulers over the descendants of Abraham, Isaac, and Jacob. For I will cause their captives to return, and will have mercy on them.\"'", 
                "num": 26
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "The word which came to Jeremiah from the LORD, when Nebuchadnezzar king of Babylon and all his army, all the kingdoms of the earth under his dominion, and all the people, fought against Jerusalem and all its cities, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD, the God of Israel: \"Go and speak to Zedekiah king of Judah and tell him, \"Thus says the LORD: \"Behold, I will give this city into the hand of the king of Babylon, and he shall burn it with fire.", 
                "num": 2
              }, 
              {
                "text": "And you shall not escape from his hand, but shall surely be taken and delivered into his hand; your eyes shall see the eyes of the king of Babylon, he shall speak with you face to face, and you shall go to Babylon.\"\"", 
                "num": 3
              }, 
              {
                "text": "Yet hear the word of the LORD, O Zedekiah king of Judah! Thus says the LORD concerning you: \"You shall not die by the sword.", 
                "num": 4
              }, 
              {
                "text": "You shall die in peace; as in the ceremonies of your fathers, the former kings who were before you, so they shall burn incense for you and lament for you, saying, \"Alas, lord!\" For I have pronounced the word, says the LORD.\"'", 
                "num": 5
              }, 
              {
                "text": "Then Jeremiah the prophet spoke all these words to Zedekiah king of Judah in Jerusalem,", 
                "num": 6
              }, 
              {
                "text": "when the king of Babylon's army fought against Jerusalem and all the cities of Judah that were left, against Lachish and Azekah; for only these fortified cities remained of the cities of Judah.", 
                "num": 7
              }, 
              {
                "text": "This is the word that came to Jeremiah from the LORD, after King Zedekiah had made a covenant with all the people who were at Jerusalem to proclaim liberty to them:", 
                "num": 8
              }, 
              {
                "text": "that every man should set free his male and female slave--a Hebrew man or woman--that no one should keep a Jewish brother in bondage.", 
                "num": 9
              }, 
              {
                "text": "Now when all the princes and all the people, who had entered into the covenant, heard that everyone should set free his male and female slaves, that no one should keep them in bondage anymore, they obeyed and let them go.", 
                "num": 10
              }, 
              {
                "text": "But afterward they changed their minds and made the male and female slaves return, whom they had set free, and brought them into subjection as male and female slaves.", 
                "num": 11
              }, 
              {
                "text": "Therefore the word of the LORD came to Jeremiah from the LORD, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Thus says the LORD, the God of Israel: \"I made a covenant with your fathers in the day that I brought them out of the land of Egypt, out of the house of bondage, saying,", 
                "num": 13
              }, 
              {
                "text": "\"At the end of seven years let every man set free his Hebrew brother, who has been sold to him; and when he has served you six years, you shall let him go free from you.\" But your fathers did not obey Me nor incline their ear.", 
                "num": 14
              }, 
              {
                "text": "Then you recently turned and did what was right in My sight--every man proclaiming liberty to his neighbor; and you made a covenant before Me in the house which is called by My name.", 
                "num": 15
              }, 
              {
                "text": "Then you turned around and profaned My name, and every one of you brought back his male and female slaves, whom he had set at liberty, at their pleasure, and brought them back into subjection, to be your male and female slaves.'", 
                "num": 16
              }, 
              {
                "text": "\"Therefore thus says the LORD: \"You have not obeyed Me in proclaiming liberty, every one to his brother and every one to his neighbor. Behold, I proclaim liberty to you,' says the LORD--\"to the sword, to pestilence, and to famine! And I will deliver you to trouble among all the kingdoms of the earth.", 
                "num": 17
              }, 
              {
                "text": "And I will give the men who have transgressed My covenant, who have not performed the words of the covenant which they made before Me, when they cut the calf in two and passed between the parts of it--", 
                "num": 18
              }, 
              {
                "text": "the princes of Judah, the princes of Jerusalem, the eunuchs, the priests, and all the people of the land who passed between the parts of the calf--", 
                "num": 19
              }, 
              {
                "text": "I will give them into the hand of their enemies and into the hand of those who seek their life. Their dead bodies shall be for meat for the birds of the heaven and the beasts of the earth.", 
                "num": 20
              }, 
              {
                "text": "And I will give Zedekiah king of Judah and his princes into the hand of their enemies, into the hand of those who seek their life, and into the hand of the king of Babylon's army which has gone back from you.", 
                "num": 21
              }, 
              {
                "text": "Behold, I will command,' says the LORD, \"and cause them to return to this city. They will fight against it and take it and burn it with fire; and I will make the cities of Judah a desolation without inhabitant.\"'", 
                "num": 22
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "The word which came to Jeremiah from the LORD in the days of Jehoiakim the son of Josiah, king of Judah, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Go to the house of the Rechabites, speak to them, and bring them into the house of the LORD, into one of the chambers, and give them wine to drink.\"", 
                "num": 2
              }, 
              {
                "text": "Then I took Jaazaniah the son of Jeremiah, the son of Habazziniah, his brothers and all his sons, and the whole house of the Rechabites,", 
                "num": 3
              }, 
              {
                "text": "and I brought them into the house of the LORD, into the chamber of the sons of Hanan the son of Igdaliah, a man of God, which was by the chamber of the princes, above the chamber of Maaseiah the son of Shallum, the keeper of the door.", 
                "num": 4
              }, 
              {
                "text": "Then I set before the sons of the house of the Rechabites bowls full of wine, and cups; and I said to them, \"Drink wine.\"", 
                "num": 5
              }, 
              {
                "text": "But they said, \"We will drink no wine, for Jonadab the son of Rechab, our father, commanded us, saying, \"You shall drink no wine, you nor your sons, forever.", 
                "num": 6
              }, 
              {
                "text": "You shall not build a house, sow seed, plant a vineyard, nor have any of these; but all your days you shall dwell in tents, that you may live many days in the land where you are sojourners.'", 
                "num": 7
              }, 
              {
                "text": "Thus we have obeyed the voice of Jonadab the son of Rechab, our father, in all that he charged us, to drink no wine all our days, we, our wives, our sons, or our daughters,", 
                "num": 8
              }, 
              {
                "text": "nor to build ourselves houses to dwell in; nor do we have vineyard, field, or seed.", 
                "num": 9
              }, 
              {
                "text": "But we have dwelt in tents, and have obeyed and done according to all that Jonadab our father commanded us.", 
                "num": 10
              }, 
              {
                "text": "But it came to pass, when Nebuchadnezzar king of Babylon came up into the land, that we said, \"Come, let us go to Jerusalem for fear of the army of the Chaldeans and for fear of the army of the Syrians.' So we dwell at Jerusalem.\"", 
                "num": 11
              }, 
              {
                "text": "Then came the word of the LORD to Jeremiah, saying,", 
                "num": 12
              }, 
              {
                "text": "\"Thus says the LORD of hosts, the God of Israel: \"Go and tell the men of Judah and the inhabitants of Jerusalem, \"Will you not receive instruction to obey My words?\" says the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"The words of Jonadab the son of Rechab, which he commanded his sons, not to drink wine, are performed; for to this day they drink none, and obey their father's commandment. But although I have spoken to you, rising early and speaking, you did not obey Me.", 
                "num": 14
              }, 
              {
                "text": "I have also sent to you all My servants the prophets, rising up early and sending them, saying, \"Turn now everyone from his evil way, amend your doings, and do not go after other gods to serve them; then you will dwell in the land which I have given you and your fathers.' But you have not inclined your ear, nor obeyed Me.", 
                "num": 15
              }, 
              {
                "text": "Surely the sons of Jonadab the son of Rechab have performed the commandment of their father, which he commanded them, but this people has not obeyed Me.\"'", 
                "num": 16
              }, 
              {
                "text": "\"Therefore thus says the LORD God of hosts, the God of Israel: \"Behold, I will bring on Judah and on all the inhabitants of Jerusalem all the doom that I have pronounced against them; because I have spoken to them but they have not heard, and I have called to them but they have not answered.\"'", 
                "num": 17
              }, 
              {
                "text": "And Jeremiah said to the house of the Rechabites, \"Thus says the LORD of hosts, the God of Israel: \"Because you have obeyed the commandment of Jonadab your father, and kept all his precepts and done according to all that he commanded you,", 
                "num": 18
              }, 
              {
                "text": "therefore thus says the LORD of hosts, the God of Israel: \"Jonadab the son of Rechab shall not lack a man to stand before Me forever.\"\"'", 
                "num": 19
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the fourth year of Jehoiakim the son of Josiah, king of Judah, that this word came to Jeremiah from the LORD, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Take a scroll of a book and write on it all the words that I have spoken to you against Israel, against Judah, and against all the nations, from the day I spoke to you, from the days of Josiah even to this day.", 
                "num": 2
              }, 
              {
                "text": "It may be that the house of Judah will hear all the adversities which I purpose to bring upon them, that everyone may turn from his evil way, that I may forgive their iniquity and their sin.\"", 
                "num": 3
              }, 
              {
                "text": "Then Jeremiah called Baruch the son of Neriah; and Baruch wrote on a scroll of a book, at the instruction of Jeremiah, all the words of the LORD which He had spoken to him.", 
                "num": 4
              }, 
              {
                "text": "And Jeremiah commanded Baruch, saying, \"I am confined, I cannot go into the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "You go, therefore, and read from the scroll which you have written at my instruction, the words of the LORD, in the hearing of the people in the LORD's house on the day of fasting. And you shall also read them in the hearing of all Judah who come from their cities.", 
                "num": 6
              }, 
              {
                "text": "It may be that they will present their supplication before the LORD, and everyone will turn from his evil way. For great is the anger and the fury that the LORD has pronounced against this people.\"", 
                "num": 7
              }, 
              {
                "text": "And Baruch the son of Neriah did according to all that Jeremiah the prophet commanded him, reading from the book the words of the LORD in the LORD's house.", 
                "num": 8
              }, 
              {
                "text": "Now it came to pass in the fifth year of Jehoiakim the son of Josiah, king of Judah, in the ninth month, that they proclaimed a fast before the LORD to all the people in Jerusalem, and to all the people who came from the cities of Judah to Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "Then Baruch read from the book the words of Jeremiah in the house of the LORD, in the chamber of Gemariah the son of Shaphan the scribe, in the upper court at the entry of the New Gate of the LORD's house, in the hearing of all the people.", 
                "num": 10
              }, 
              {
                "text": "When Michaiah the son of Gemariah, the son of Shaphan, heard all the words of the LORD from the book,", 
                "num": 11
              }, 
              {
                "text": "he then went down to the king's house, into the scribe's chamber; and there all the princes were sitting--Elishama the scribe, Delaiah the son of Shemaiah, Elnathan the son of Achbor, Gemariah the son of Shaphan, Zedekiah the son of Hananiah, and all the princes.", 
                "num": 12
              }, 
              {
                "text": "Then Michaiah declared to them all the words that he had heard when Baruch read the book in the hearing of the people.", 
                "num": 13
              }, 
              {
                "text": "Therefore all the princes sent Jehudi the son of Nethaniah, the son of Shelemiah, the son of Cushi, to Baruch, saying, \"Take in your hand the scroll from which you have read in the hearing of the people, and come.\" So Baruch the son of Neriah took the scroll in his hand and came to them.", 
                "num": 14
              }, 
              {
                "text": "And they said to him, \"Sit down now, and read it in our hearing.\" So Baruch read it in their hearing.", 
                "num": 15
              }, 
              {
                "text": "Now it happened, when they had heard all the words, that they looked in fear from one to another, and said to Baruch, \"We will surely tell the king of all these words.\"", 
                "num": 16
              }, 
              {
                "text": "And they asked Baruch, saying, \"Tell us now, how did you write all these words--at his instruction?\"", 
                "num": 17
              }, 
              {
                "text": "So Baruch answered them, \"He proclaimed with his mouth all these words to me, and I wrote them with ink in the book.\"", 
                "num": 18
              }, 
              {
                "text": "Then the princes said to Baruch, \"Go and hide, you and Jeremiah; and let no one know where you are.\"", 
                "num": 19
              }, 
              {
                "text": "And they went to the king, into the court; but they stored the scroll in the chamber of Elishama the scribe, and told all the words in the hearing of the king.", 
                "num": 20
              }, 
              {
                "text": "So the king sent Jehudi to bring the scroll, and he took it from Elishama the scribe's chamber. And Jehudi read it in the hearing of the king and in the hearing of all the princes who stood beside the king.", 
                "num": 21
              }, 
              {
                "text": "Now the king was sitting in the winter house in the ninth month, with a fire burning on the hearth before him.", 
                "num": 22
              }, 
              {
                "text": "And it happened, when Jehudi had read three or four columns, that the king cut it with the scribe's knife and cast it into the fire that was on the hearth, until all the scroll was consumed in the fire that was on the hearth.", 
                "num": 23
              }, 
              {
                "text": "Yet they were not afraid, nor did they tear their garments, the king nor any of his servants who heard all these words.", 
                "num": 24
              }, 
              {
                "text": "Nevertheless Elnathan, Delaiah, and Gemariah implored the king not to burn the scroll; but he would not listen to them.", 
                "num": 25
              }, 
              {
                "text": "And the king commanded Jerahmeel the king's son, Seraiah the son of Azriel, and Shelemiah the son of Abdeel, to seize Baruch the scribe and Jeremiah the prophet, but the LORD hid them.", 
                "num": 26
              }, 
              {
                "text": "Now after the king had burned the scroll with the words which Baruch had written at the instruction of Jeremiah, the word of the LORD came to Jeremiah, saying:", 
                "num": 27
              }, 
              {
                "text": "\"Take yet another scroll, and write on it all the former words that were in the first scroll which Jehoiakim the king of Judah has burned.", 
                "num": 28
              }, 
              {
                "text": "And you shall say to Jehoiakim king of Judah, \"Thus says the LORD: \"You have burned this scroll, saying, \"Why have you written in it that the king of Babylon will certainly come and destroy this land, and cause man and beast to cease from here?\"'", 
                "num": 29
              }, 
              {
                "text": "Therefore thus says the LORD concerning Jehoiakim king of Judah: \"He shall have no one to sit on the throne of David, and his dead body shall be cast out to the heat of the day and the frost of the night.", 
                "num": 30
              }, 
              {
                "text": "I will punish him, his family, and his servants for their iniquity; and I will bring on them, on the inhabitants of Jerusalem, and on the men of Judah all the doom that I have pronounced against them; but they did not heed.\"\"'", 
                "num": 31
              }, 
              {
                "text": "Then Jeremiah took another scroll and gave it to Baruch the scribe, the son of Neriah, who wrote on it at the instruction of Jeremiah all the words of the book which Jehoiakim king of Judah had burned in the fire. And besides, there were added to them many similar words.", 
                "num": 32
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "Now King Zedekiah the son of Josiah reigned instead of Coniah the son of Jehoiakim, whom Nebuchadnezzar king of Babylon made king in the land of Judah.", 
                "num": 1
              }, 
              {
                "text": "But neither he nor his servants nor the people of the land gave heed to the words of the LORD which He spoke by the prophet Jeremiah.", 
                "num": 2
              }, 
              {
                "text": "And Zedekiah the king sent Jehucal the son of Shelemiah, and Zephaniah the son of Maaseiah, the priest, to the prophet Jeremiah, saying, \"Pray now to the LORD our God for us.\"", 
                "num": 3
              }, 
              {
                "text": "Now Jeremiah was coming and going among the people, for they had not yet put him in prison.", 
                "num": 4
              }, 
              {
                "text": "Then Pharaoh's army came up from Egypt; and when the Chaldeans who were besieging Jerusalem heard news of them, they departed from Jerusalem.", 
                "num": 5
              }, 
              {
                "text": "Then the word of the LORD came to the prophet Jeremiah, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Thus says the LORD, the God of Israel, \"Thus you shall say to the king of Judah, who sent you to Me to inquire of Me: \"Behold, Pharaoh's army which has come up to help you will return to Egypt, to their own land.", 
                "num": 7
              }, 
              {
                "text": "And the Chaldeans shall come back and fight against this city, and take it and burn it with fire.\"'", 
                "num": 8
              }, 
              {
                "text": "Thus says the LORD: \"Do not deceive yourselves, saying, \"The Chaldeans will surely depart from us,\" for they will not depart.", 
                "num": 9
              }, 
              {
                "text": "For though you had defeated the whole army of the Chaldeans who fight against you, and there remained only wounded men among them, they would rise up, every man in his tent, and burn the city with fire.\"'", 
                "num": 10
              }, 
              {
                "text": "And it happened, when the army of the Chaldeans left the siege of Jerusalem for fear of Pharaoh's army,", 
                "num": 11
              }, 
              {
                "text": "that Jeremiah went out of Jerusalem to go into the land of Benjamin to claim his property there among the people.", 
                "num": 12
              }, 
              {
                "text": "And when he was in the Gate of Benjamin, a captain of the guard was there whose name was Irijah the son of Shelemiah, the son of Hananiah; and he seized Jeremiah the prophet, saying, \"You are defecting to the Chaldeans!\"", 
                "num": 13
              }, 
              {
                "text": "Then Jeremiah said, \"False! I am not defecting to the Chaldeans.\" But he did not listen to him. So Irijah seized Jeremiah and brought him to the princes.", 
                "num": 14
              }, 
              {
                "text": "Therefore the princes were angry with Jeremiah, and they struck him and put him in prison in the house of Jonathan the scribe. For they had made that the prison.", 
                "num": 15
              }, 
              {
                "text": "When Jeremiah entered the dungeon and the cells, and Jeremiah had remained there many days,", 
                "num": 16
              }, 
              {
                "text": "then Zedekiah the king sent and took him out. The king asked him secretly in his house, and said, \"Is there any word from the LORD?\" And Jeremiah said, \"There is.\" Then he said, \"You shall be delivered into the hand of the king of Babylon!\"", 
                "num": 17
              }, 
              {
                "text": "Moreover Jeremiah said to King Zedekiah, \"What offense have I committed against you, against your servants, or against this people, that you have put me in prison?", 
                "num": 18
              }, 
              {
                "text": "Where now are your prophets who prophesied to you, saying, \"The king of Babylon will not come against you or against this land'?", 
                "num": 19
              }, 
              {
                "text": "Therefore please hear now, O my lord the king. Please, let my petition be accepted before you, and do not make me return to the house of Jonathan the scribe, lest I die there.\"", 
                "num": 20
              }, 
              {
                "text": "Then Zedekiah the king commanded that they should commit Jeremiah to the court of the prison, and that they should give him daily a piece of bread from the bakers' street, until all the bread in the city was gone. Thus Jeremiah remained in the court of the prison.", 
                "num": 21
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "Now Shephatiah the son of Mattan, Gedaliah the son of Pashhur, Jucal the son of Shelemiah, and Pashhur the son of Malchiah heard the words that Jeremiah had spoken to all the people, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD: \"He who remains in this city shall die by the sword, by famine, and by pestilence; but he who goes over to the Chaldeans shall live; his life shall be as a prize to him, and he shall live.'", 
                "num": 2
              }, 
              {
                "text": "Thus says the LORD: \"This city shall surely be given into the hand of the king of Babylon's army, which shall take it.\"'", 
                "num": 3
              }, 
              {
                "text": "Therefore the princes said to the king, \"Please, let this man be put to death, for thus he weakens the hands of the men of war who remain in this city, and the hands of all the people, by speaking such words to them. For this man does not seek the welfare of this people, but their harm.\"", 
                "num": 4
              }, 
              {
                "text": "Then Zedekiah the king said, \"Look, he is in your hand. For the king can do nothing against you.\"", 
                "num": 5
              }, 
              {
                "text": "So they took Jeremiah and cast him into the dungeon of Malchiah the king's son, which was in the court of the prison, and they let Jeremiah down with ropes. And in the dungeon there was no water, but mire. So Jeremiah sank in the mire.", 
                "num": 6
              }, 
              {
                "text": "Now Ebed-Melech the Ethiopian, one of the eunuchs, who was in the king's house, heard that they had put Jeremiah in the dungeon. When the king was sitting at the Gate of Benjamin,", 
                "num": 7
              }, 
              {
                "text": "Ebed-Melech went out of the king's house and spoke to the king, saying:", 
                "num": 8
              }, 
              {
                "text": "\"My lord the king, these men have done evil in all that they have done to Jeremiah the prophet, whom they have cast into the dungeon, and he is likely to die from hunger in the place where he is. For there is no more bread in the city.\"", 
                "num": 9
              }, 
              {
                "text": "Then the king commanded Ebed-Melech the Ethiopian, saying, \"Take from here thirty men with you, and lift Jeremiah the prophet out of the dungeon before he dies.\"", 
                "num": 10
              }, 
              {
                "text": "So Ebed-Melech took the men with him and went into the house of the king under the treasury, and took from there old clothes and old rags, and let them down by ropes into the dungeon to Jeremiah.", 
                "num": 11
              }, 
              {
                "text": "Then Ebed-Melech the Ethiopian said to Jeremiah, \"Please put these old clothes and rags under your armpits, under the ropes.\" And Jeremiah did so.", 
                "num": 12
              }, 
              {
                "text": "So they pulled Jeremiah up with ropes and lifted him out of the dungeon. And Jeremiah remained in the court of the prison.", 
                "num": 13
              }, 
              {
                "text": "Then Zedekiah the king sent and had Jeremiah the prophet brought to him at the third entrance of the house of the LORD. And the king said to Jeremiah, \"I will ask you something. Hide nothing from me.\"", 
                "num": 14
              }, 
              {
                "text": "Jeremiah said to Zedekiah, \"If I declare it to you, will you not surely put me to death? And if I give you advice, you will not listen to me.\"", 
                "num": 15
              }, 
              {
                "text": "So Zedekiah the king swore secretly to Jeremiah, saying, \"As the LORD lives, who made our very souls, I will not put you to death, nor will I give you into the hand of these men who seek your life.\"", 
                "num": 16
              }, 
              {
                "text": "Then Jeremiah said to Zedekiah, \"Thus says the LORD, the God of hosts, the God of Israel: \"If you surely surrender to the king of Babylon's princes, then your soul shall live; this city shall not be burned with fire, and you and your house shall live.", 
                "num": 17
              }, 
              {
                "text": "But if you do not surrender to the king of Babylon's princes, then this city shall be given into the hand of the Chaldeans; they shall burn it with fire, and you shall not escape from their hand.\"'", 
                "num": 18
              }, 
              {
                "text": "And Zedekiah the king said to Jeremiah, \"I am afraid of the Jews who have defected to the Chaldeans, lest they deliver me into their hand, and they abuse me.\"", 
                "num": 19
              }, 
              {
                "text": "But Jeremiah said, \"They shall not deliver you. Please, obey the voice of the LORD which I speak to you. So it shall be well with you, and your soul shall live.", 
                "num": 20
              }, 
              {
                "text": "But if you refuse to surrender, this is the word that the LORD has shown me:", 
                "num": 21
              }, 
              {
                "text": "\"Now behold, all the women who are left in the king of Judah's house shall be surrendered to the king of Babylon's princes, and those women shall say: \"Your close friends have set upon you And prevailed against you; Your feet have sunk in the mire, And they have turned away again.\"", 
                "num": 22
              }, 
              {
                "text": "\"So they shall surrender all your wives and children to the Chaldeans. You shall not escape from their hand, but shall be taken by the hand of the king of Babylon. And you shall cause this city to be burned with fire.\"'", 
                "num": 23
              }, 
              {
                "text": "Then Zedekiah said to Jeremiah, \"Let no one know of these words, and you shall not die.", 
                "num": 24
              }, 
              {
                "text": "But if the princes hear that I have talked with you, and they come to you and say to you, \"Declare to us now what you have said to the king, and also what the king said to you; do not hide it from us, and we will not put you to death,'", 
                "num": 25
              }, 
              {
                "text": "then you shall say to them, \"I presented my request before the king, that he would not make me return to Jonathan's house to die there.\"'", 
                "num": 26
              }, 
              {
                "text": "Then all the princes came to Jeremiah and asked him. And he told them according to all these words that the king had commanded. So they stopped speaking with him, for the conversation had not been heard.", 
                "num": 27
              }, 
              {
                "text": "Now Jeremiah remained in the court of the prison until the day that Jerusalem was taken. And he was there when Jerusalem was taken.", 
                "num": 28
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "In the ninth year of Zedekiah king of Judah, in the tenth month, Nebuchadnezzar king of Babylon and all his army came against Jerusalem, and besieged it.", 
                "num": 1
              }, 
              {
                "text": "In the eleventh year of Zedekiah, in the fourth month, on the ninth day of the month, the city was penetrated.", 
                "num": 2
              }, 
              {
                "text": "Then all the princes of the king of Babylon came in and sat in the Middle Gate: Nergal-Sharezer, Samgar-Nebo, Sarsechim, Rabsaris, Nergal-Sarezer, Rabmag, with the rest of the princes of the king of Babylon.", 
                "num": 3
              }, 
              {
                "text": "So it was, when Zedekiah the king of Judah and all the men of war saw them, that they fled and went out of the city by night, by way of the king's garden, by the gate between the two walls. And he went out by way of the plain.", 
                "num": 4
              }, 
              {
                "text": "But the Chaldean army pursued them and overtook Zedekiah in the plains of Jericho. And when they had captured him, they brought him up to Nebuchadnezzar king of Babylon, to Riblah in the land of Hamath, where he pronounced judgment on him.", 
                "num": 5
              }, 
              {
                "text": "Then the king of Babylon killed the sons of Zedekiah before his eyes in Riblah; the king of Babylon also killed all the nobles of Judah.", 
                "num": 6
              }, 
              {
                "text": "Moreover he put out Zedekiah's eyes, and bound him with bronze fetters to carry him off to Babylon.", 
                "num": 7
              }, 
              {
                "text": "And the Chaldeans burned the king's house and the houses of the people with fire, and broke down the walls of Jerusalem.", 
                "num": 8
              }, 
              {
                "text": "Then Nebuzaradan the captain of the guard carried away captive to Babylon the remnant of the people who remained in the city and those who defected to him, with the rest of the people who remained.", 
                "num": 9
              }, 
              {
                "text": "But Nebuzaradan the captain of the guard left in the land of Judah the poor people, who had nothing, and gave them vineyards and fields at the same time.", 
                "num": 10
              }, 
              {
                "text": "Now Nebuchadnezzar king of Babylon gave charge concerning Jeremiah to Nebuzaradan the captain of the guard, saying,", 
                "num": 11
              }, 
              {
                "text": "\"Take him and look after him, and do him no harm; but do to him just as he says to you.\"", 
                "num": 12
              }, 
              {
                "text": "So Nebuzaradan the captain of the guard sent Nebushasban, Rabsaris, Nergal-Sharezer, Rabmag, and all the king of Babylon's chief officers;", 
                "num": 13
              }, 
              {
                "text": "then they sent someone to take Jeremiah from the court of the prison, and committed him to Gedaliah the son of Ahikam, the son of Shaphan, that he should take him home. So he dwelt among the people.", 
                "num": 14
              }, 
              {
                "text": "Meanwhile the word of the LORD had come to Jeremiah while he was shut up in the court of the prison, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Go and speak to Ebed-Melech the Ethiopian, saying, \"Thus says the LORD of hosts, the God of Israel: \"Behold, I will bring My words upon this city for adversity and not for good, and they shall be performed in that day before you.", 
                "num": 16
              }, 
              {
                "text": "But I will deliver you in that day,\" says the LORD, \"and you shall not be given into the hand of the men of whom you are afraid.", 
                "num": 17
              }, 
              {
                "text": "For I will surely deliver you, and you shall not fall by the sword; but your life shall be as a prize to you, because you have put your trust in Me,\" says the LORD.\"'", 
                "num": 18
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah from the LORD after Nebuzaradan the captain of the guard had let him go from Ramah, when he had taken him bound in chains among all who were carried away captive from Jerusalem and Judah, who were carried away captive to Babylon.", 
                "num": 1
              }, 
              {
                "text": "And the captain of the guard took Jeremiah and said to him: \"The LORD your God has pronounced this doom on this place.", 
                "num": 2
              }, 
              {
                "text": "Now the LORD has brought it, and has done just as He said. Because you people have sinned against the LORD, and not obeyed His voice, therefore this thing has come upon you.", 
                "num": 3
              }, 
              {
                "text": "And now look, I free you this day from the chains that were on your hand. If it seems good to you to come with me to Babylon, come, and I will look after you. But if it seems wrong for you to come with me to Babylon, remain here. See, all the land is before you; wherever it seems good and convenient for you to go, go there.\"", 
                "num": 4
              }, 
              {
                "text": "Now while Jeremiah had not yet gone back, Nebuzaradan said, \"Go back to Gedaliah the son of Ahikam, the son of Shaphan, whom the king of Babylon has made governor over the cities of Judah, and dwell with him among the people. Or go wherever it seems convenient for you to go.\" So the captain of the guard gave him rations and a gift and let him go.", 
                "num": 5
              }, 
              {
                "text": "Then Jeremiah went to Gedaliah the son of Ahikam, to Mizpah, and dwelt with him among the people who were left in the land.", 
                "num": 6
              }, 
              {
                "text": "And when all the captains of the armies who were in the fields, they and their men, heard that the king of Babylon had made Gedaliah the son of Ahikam governor in the land, and had committed to him men, women, children, and the poorest of the land who had not been carried away captive to Babylon,", 
                "num": 7
              }, 
              {
                "text": "then they came to Gedaliah at Mizpah--Ishmael the son of Nethaniah, Johanan and Jonathan the sons of Kareah, Seraiah the son of Tanhumeth, the sons of Ephai the Netophathite, and Jezaniah the son of a Maachathite, they and their men.", 
                "num": 8
              }, 
              {
                "text": "And Gedaliah the son of Ahikam, the son of Shaphan, took an oath before them and their men, saying, \"Do not be afraid to serve the Chaldeans. Dwell in the land and serve the king of Babylon, and it shall be well with you.", 
                "num": 9
              }, 
              {
                "text": "As for me, I will indeed dwell at Mizpah and serve the Chaldeans who come to us. But you, gather wine and summer fruit and oil, put them in your vessels, and dwell in your cities that you have taken.\"", 
                "num": 10
              }, 
              {
                "text": "Likewise, when all the Jews who were in Moab, among the Ammonites, in Edom, and who were in all the countries, heard that the king of Babylon had left a remnant of Judah, and that he had set over them Gedaliah the son of Ahikam, the son of Shaphan,", 
                "num": 11
              }, 
              {
                "text": "then all the Jews returned out of all places where they had been driven, and came to the land of Judah, to Gedaliah at Mizpah, and gathered wine and summer fruit in abundance.", 
                "num": 12
              }, 
              {
                "text": "Moreover Johanan the son of Kareah and all the captains of the forces that were in the fields came to Gedaliah at Mizpah,", 
                "num": 13
              }, 
              {
                "text": "and said to him, \"Do you certainly know that Baalis the king of the Ammonites has sent Ishmael the son of Nethaniah to murder you?\" But Gedaliah the son of Ahikam did not believe them.", 
                "num": 14
              }, 
              {
                "text": "Then Johanan the son of Kareah spoke secretly to Gedaliah in Mizpah, saying, \"Let me go, please, and I will kill Ishmael the son of Nethaniah, and no one will know it. Why should he murder you, so that all the Jews who are gathered to you would be scattered, and the remnant in Judah perish?\"", 
                "num": 15
              }, 
              {
                "text": "But Gedaliah the son of Ahikam said to Johanan the son of Kareah, \"You shall not do this thing, for you speak falsely concerning Ishmael.\"", 
                "num": 16
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the seventh month that Ishmael the son of Nethaniah, the son of Elishama, of the royal family and of the officers of the king, came with ten men to Gedaliah the son of Ahikam, at Mizpah. And there they ate bread together in Mizpah.", 
                "num": 1
              }, 
              {
                "text": "Then Ishmael the son of Nethaniah, and the ten men who were with him, arose and struck Gedaliah the son of Ahikam, the son of Shaphan, with the sword, and killed him whom the king of Babylon had made governor over the land.", 
                "num": 2
              }, 
              {
                "text": "Ishmael also struck down all the Jews who were with him, that is, with Gedaliah at Mizpah, and the Chaldeans who were found there, the men of war.", 
                "num": 3
              }, 
              {
                "text": "And it happened, on the second day after he had killed Gedaliah, when as yet no one knew it,", 
                "num": 4
              }, 
              {
                "text": "that certain men came from Shechem, from Shiloh, and from Samaria, eighty men with their beards shaved and their clothes torn, having cut themselves, with offerings and incense in their hand, to bring them to the house of the LORD.", 
                "num": 5
              }, 
              {
                "text": "Now Ishmael the son of Nethaniah went out from Mizpah to meet them, weeping as he went along; and it happened as he met them that he said to them, \"Come to Gedaliah the son of Ahikam!\"", 
                "num": 6
              }, 
              {
                "text": "So it was, when they came into the midst of the city, that Ishmael the son of Nethaniah killed them and cast them into the midst of a pit, he and the men who were with him.", 
                "num": 7
              }, 
              {
                "text": "But ten men were found among them who said to Ishmael, \"Do not kill us, for we have treasures of wheat, barley, oil, and honey in the field.\" So he desisted and did not kill them among their brethren.", 
                "num": 8
              }, 
              {
                "text": "Now the pit into which Ishmael had cast all the dead bodies of the men whom he had slain, because of Gedaliah, was the same one Asa the king had made for fear of Baasha king of Israel. Ishmael the son of Nethaniah filled it with the slain.", 
                "num": 9
              }, 
              {
                "text": "Then Ishmael carried away captive all the rest of the people who were in Mizpah, the king's daughters and all the people who remained in Mizpah, whom Nebuzaradan the captain of the guard had committed to Gedaliah the son of Ahikam. And Ishmael the son of Nethaniah carried them away captive and departed to go over to the Ammonites.", 
                "num": 10
              }, 
              {
                "text": "But when Johanan the son of Kareah and all the captains of the forces that were with him heard of all the evil that Ishmael the son of Nethaniah had done,", 
                "num": 11
              }, 
              {
                "text": "they took all the men and went to fight with Ishmael the son of Nethaniah; and they found him by the great pool that is in Gibeon.", 
                "num": 12
              }, 
              {
                "text": "So it was, when all the people who were with Ishmael saw Johanan the son of Kareah, and all the captains of the forces who were with him, that they were glad.", 
                "num": 13
              }, 
              {
                "text": "Then all the people whom Ishmael had carried away captive from Mizpah turned around and came back, and went to Johanan the son of Kareah.", 
                "num": 14
              }, 
              {
                "text": "But Ishmael the son of Nethaniah escaped from Johanan with eight men and went to the Ammonites.", 
                "num": 15
              }, 
              {
                "text": "Then Johanan the son of Kareah, and all the captains of the forces that were with him, took from Mizpah all the rest of the people whom he had recovered from Ishmael the son of Nethaniah after he had murdered Gedaliah the son of Ahikam--the mighty men of war and the women and the children and the eunuchs, whom he had brought back from Gibeon.", 
                "num": 16
              }, 
              {
                "text": "And they departed and dwelt in the habitation of Chimham, which is near Bethlehem, as they went on their way to Egypt,", 
                "num": 17
              }, 
              {
                "text": "because of the Chaldeans; for they were afraid of them, because Ishmael the son of Nethaniah had murdered Gedaliah the son of Ahikam, whom the king of Babylon had made governor in the land.", 
                "num": 18
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "Now all the captains of the forces, Johanan the son of Kareah, Jezaniah the son of Hoshaiah, and all the people, from the least to the greatest, came near", 
                "num": 1
              }, 
              {
                "text": "and said to Jeremiah the prophet, \"Please, let our petition be acceptable to you, and pray for us to the LORD your God, for all this remnant (since we are left but a few of many, as you can see),", 
                "num": 2
              }, 
              {
                "text": "that the LORD your God may show us the way in which we should walk and the thing we should do.\"", 
                "num": 3
              }, 
              {
                "text": "Then Jeremiah the prophet said to them, \"I have heard. Indeed, I will pray to the LORD your God according to your words, and it shall be, that whatever the LORD answers you, I will declare it to you. I will keep nothing back from you.\"", 
                "num": 4
              }, 
              {
                "text": "So they said to Jeremiah, \"Let the LORD be a true and faithful witness between us, if we do not do according to everything which the LORD your God sends us by you.", 
                "num": 5
              }, 
              {
                "text": "Whether it is pleasing or displeasing, we will obey the voice of the LORD our God to whom we send you, that it may be well with us when we obey the voice of the LORD our God.\"", 
                "num": 6
              }, 
              {
                "text": "And it happened after ten days that the word of the LORD came to Jeremiah.", 
                "num": 7
              }, 
              {
                "text": "Then he called Johanan the son of Kareah, all the captains of the forces which were with him, and all the people from the least even to the greatest,", 
                "num": 8
              }, 
              {
                "text": "and said to them, \"Thus says the LORD, the God of Israel, to whom you sent me to present your petition before Him:", 
                "num": 9
              }, 
              {
                "text": "\"If you will still remain in this land, then I will build you and not pull you down, and I will plant you and not pluck you up. For I relent concerning the disaster that I have brought upon you.", 
                "num": 10
              }, 
              {
                "text": "Do not be afraid of the king of Babylon, of whom you are afraid; do not be afraid of him,' says the LORD, \"for I am with you, to save you and deliver you from his hand.", 
                "num": 11
              }, 
              {
                "text": "And I will show you mercy, that he may have mercy on you and cause you to return to your own land.'", 
                "num": 12
              }, 
              {
                "text": "\"But if you say, \"We will not dwell in this land,' disobeying the voice of the LORD your God,", 
                "num": 13
              }, 
              {
                "text": "saying, \"No, but we will go to the land of Egypt where we shall see no war, nor hear the sound of the trumpet, nor be hungry for bread, and there we will dwell'--", 
                "num": 14
              }, 
              {
                "text": "Then hear now the word of the LORD, O remnant of Judah! Thus says the LORD of hosts, the God of Israel: \"If you wholly set your faces to enter Egypt, and go to dwell there,", 
                "num": 15
              }, 
              {
                "text": "then it shall be that the sword which you feared shall overtake you there in the land of Egypt; the famine of which you were afraid shall follow close after you there in Egypt; and there you shall die.", 
                "num": 16
              }, 
              {
                "text": "So shall it be with all the men who set their faces to go to Egypt to dwell there. They shall die by the sword, by famine, and by pestilence. And none of them shall remain or escape from the disaster that I will bring upon them.'", 
                "num": 17
              }, 
              {
                "text": "\"For thus says the LORD of hosts, the God of Israel: \"As My anger and My fury have been poured out on the inhabitants of Jerusalem, so will My fury be poured out on you when you enter Egypt. And you shall be an oath, an astonishment, a curse, and a reproach; and you shall see this place no more.'", 
                "num": 18
              }, 
              {
                "text": "\"The LORD has said concerning you, O remnant of Judah, \"Do not go to Egypt!' Know certainly that I have admonished you this day.", 
                "num": 19
              }, 
              {
                "text": "For you were hypocrites in your hearts when you sent me to the LORD your God, saying, \"Pray for us to the LORD our God, and according to all that the LORD your God says, so declare to us and we will do it.'", 
                "num": 20
              }, 
              {
                "text": "And I have this day declared it to you, but you have not obeyed the voice of the LORD your God, or anything which He has sent you by me.", 
                "num": 21
              }, 
              {
                "text": "Now therefore, know certainly that you shall die by the sword, by famine, and by pestilence in the place where you desire to go to dwell.\"", 
                "num": 22
              }
            ], 
            "num": 42
          }, 
          {
            "verses": [
              {
                "text": "Now it happened, when Jeremiah had stopped speaking to all the people all the words of the LORD their God, for which the LORD their God had sent him to them, all these words,", 
                "num": 1
              }, 
              {
                "text": "that Azariah the son of Hoshaiah, Johanan the son of Kareah, and all the proud men spoke, saying to Jeremiah, \"You speak falsely! The LORD our God has not sent you to say, \"Do not go to Egypt to dwell there.'", 
                "num": 2
              }, 
              {
                "text": "But Baruch the son of Neriah has set you against us, to deliver us into the hand of the Chaldeans, that they may put us to death or carry us away captive to Babylon.\"", 
                "num": 3
              }, 
              {
                "text": "So Johanan the son of Kareah, all the captains of the forces, and all the people would not obey the voice of the LORD, to remain in the land of Judah.", 
                "num": 4
              }, 
              {
                "text": "But Johanan the son of Kareah and all the captains of the forces took all the remnant of Judah who had returned to dwell in the land of Judah, from all nations where they had been driven--", 
                "num": 5
              }, 
              {
                "text": "men, women, children, the king's daughters, and every person whom Nebuzaradan the captain of the guard had left with Gedaliah the son of Ahikam, the son of Shaphan, and Jeremiah the prophet and Baruch the son of Neriah.", 
                "num": 6
              }, 
              {
                "text": "So they went to the land of Egypt, for they did not obey the voice of the LORD. And they went as far as Tahpanhes.", 
                "num": 7
              }, 
              {
                "text": "Then the word of the LORD came to Jeremiah in Tahpanhes, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Take large stones in your hand, and hide them in the sight of the men of Judah, in the clay in the brick courtyard which is at the entrance to Pharaoh's house in Tahpanhes;", 
                "num": 9
              }, 
              {
                "text": "and say to them, \"Thus says the LORD of hosts, the God of Israel: \"Behold, I will send and bring Nebuchadnezzar the king of Babylon, My servant, and will set his throne above these stones that I have hidden. And he will spread his royal pavilion over them.", 
                "num": 10
              }, 
              {
                "text": "When he comes, he shall strike the land of Egypt and deliver to death those appointed for death, and to captivity those appointed for captivity, and to the sword those appointed for the sword.", 
                "num": 11
              }, 
              {
                "text": "I will kindle a fire in the houses of the gods of Egypt, and he shall burn them and carry them away captive. And he shall array himself with the land of Egypt, as a shepherd puts on his garment, and he shall go out from there in peace.", 
                "num": 12
              }, 
              {
                "text": "He shall also break the sacred pillars of Beth Shemesh that are in the land of Egypt; and the houses of the gods of the Egyptians he shall burn with fire.\"\"'", 
                "num": 13
              }
            ], 
            "num": 43
          }, 
          {
            "verses": [
              {
                "text": "The word that came to Jeremiah concerning all the Jews who dwell in the land of Egypt, who dwell at Migdol, at Tahpanhes, at Noph, and in the country of Pathros, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD of hosts, the God of Israel: \"You have seen all the calamity that I have brought on Jerusalem and on all the cities of Judah; and behold, this day they are a desolation, and no one dwells in them,", 
                "num": 2
              }, 
              {
                "text": "because of their wickedness which they have committed to provoke Me to anger, in that they went to burn incense and to serve other gods whom they did not know, they nor you nor your fathers.", 
                "num": 3
              }, 
              {
                "text": "However I have sent to you all My servants the prophets, rising early and sending them, saying, \"Oh, do not do this abominable thing that I hate!\"", 
                "num": 4
              }, 
              {
                "text": "But they did not listen or incline their ear to turn from their wickedness, to burn no incense to other gods.", 
                "num": 5
              }, 
              {
                "text": "So My fury and My anger were poured out and kindled in the cities of Judah and in the streets of Jerusalem; and they are wasted and desolate, as it is this day.'", 
                "num": 6
              }, 
              {
                "text": "\"Now therefore, thus says the LORD, the God of hosts, the God of Israel: \"Why do you commit this great evil against yourselves, to cut off from you man and woman, child and infant, out of Judah, leaving none to remain,", 
                "num": 7
              }, 
              {
                "text": "in that you provoke Me to wrath with the works of your hands, burning incense to other gods in the land of Egypt where you have gone to dwell, that you may cut yourselves off and be a curse and a reproach among all the nations of the earth?", 
                "num": 8
              }, 
              {
                "text": "Have you forgotten the wickedness of your fathers, the wickedness of the kings of Judah, the wickedness of their wives, your own wickedness, and the wickedness of your wives, which they committed in the land of Judah and in the streets of Jerusalem?", 
                "num": 9
              }, 
              {
                "text": "They have not been humbled, to this day, nor have they feared; they have not walked in My law or in My statutes that I set before you and your fathers.'", 
                "num": 10
              }, 
              {
                "text": "\"Therefore thus says the LORD of hosts, the God of Israel: \"Behold, I will set My face against you for catastrophe and for cutting off all Judah.", 
                "num": 11
              }, 
              {
                "text": "And I will take the remnant of Judah who have set their faces to go into the land of Egypt to dwell there, and they shall all be consumed and fall in the land of Egypt. They shall be consumed by the sword and by famine. They shall die, from the least to the greatest, by the sword and by famine; and they shall be an oath, an astonishment, a curse and a reproach!", 
                "num": 12
              }, 
              {
                "text": "For I will punish those who dwell in the land of Egypt, as I have punished Jerusalem, by the sword, by famine, and by pestilence,", 
                "num": 13
              }, 
              {
                "text": "so that none of the remnant of Judah who have gone into the land of Egypt to dwell there shall escape or survive, lest they return to the land of Judah, to which they desire to return and dwell. For none shall return except those who escape.\"'", 
                "num": 14
              }, 
              {
                "text": "Then all the men who knew that their wives had burned incense to other gods, with all the women who stood by, a great multitude, and all the people who dwelt in the land of Egypt, in Pathros, answered Jeremiah, saying:", 
                "num": 15
              }, 
              {
                "text": "\"As for the word that you have spoken to us in the name of the LORD, we will not listen to you!", 
                "num": 16
              }, 
              {
                "text": "But we will certainly do whatever has gone out of our own mouth, to burn incense to the queen of heaven and pour out drink offerings to her, as we have done, we and our fathers, our kings and our princes, in the cities of Judah and in the streets of Jerusalem. For then we had plenty of food, were well-off, and saw no trouble.", 
                "num": 17
              }, 
              {
                "text": "But since we stopped burning incense to the queen of heaven and pouring out drink offerings to her, we have lacked everything and have been consumed by the sword and by famine.\"", 
                "num": 18
              }, 
              {
                "text": "The women also said, \"And when we burned incense to the queen of heaven and poured out drink offerings to her, did we make cakes for her, to worship her, and pour out drink offerings to her without our husbands' permission?\"", 
                "num": 19
              }, 
              {
                "text": "Then Jeremiah spoke to all the people--the men, the women, and all the people who had given him that answer--saying:", 
                "num": 20
              }, 
              {
                "text": "\"The incense that you burned in the cities of Judah and in the streets of Jerusalem, you and your fathers, your kings and your princes, and the people of the land, did not the LORD remember them, and did it not come into His mind?", 
                "num": 21
              }, 
              {
                "text": "So the LORD could no longer bear it, because of the evil of your doings and because of the abominations which you committed. Therefore your land is a desolation, an astonishment, a curse, and without an inhabitant, as it is this day.", 
                "num": 22
              }, 
              {
                "text": "Because you have burned incense and because you have sinned against the LORD, and have not obeyed the voice of the LORD or walked in His law, in His statutes or in His testimonies, therefore this calamity has happened to you, as at this day.\"", 
                "num": 23
              }, 
              {
                "text": "Moreover Jeremiah said to all the people and to all the women, \"Hear the word of the LORD, all Judah who are in the land of Egypt!", 
                "num": 24
              }, 
              {
                "text": "Thus says the LORD of hosts, the God of Israel, saying: \"You and your wives have spoken with your mouths and fulfilled with your hands, saying, \"We will surely keep our vows that we have made, to burn incense to the queen of heaven and pour out drink offerings to her.\" You will surely keep your vows and perform your vows!'", 
                "num": 25
              }, 
              {
                "text": "Therefore hear the word of the LORD, all Judah who dwell in the land of Egypt: \"Behold, I have sworn by My great name,' says the LORD, \"that My name shall no more be named in the mouth of any man of Judah in all the land of Egypt, saying, \"The Lord GOD lives.\"", 
                "num": 26
              }, 
              {
                "text": "Behold, I will watch over them for adversity and not for good. And all the men of Judah who are in the land of Egypt shall be consumed by the sword and by famine, until there is an end to them.", 
                "num": 27
              }, 
              {
                "text": "Yet a small number who escape the sword shall return from the land of Egypt to the land of Judah; and all the remnant of Judah, who have gone to the land of Egypt to dwell there, shall know whose words will stand, Mine or theirs.", 
                "num": 28
              }, 
              {
                "text": "And this shall be a sign to you,' says the LORD, \"that I will punish you in this place, that you may know that My words will surely stand against you for adversity.'", 
                "num": 29
              }, 
              {
                "text": "\"Thus says the LORD: \"Behold, I will give Pharaoh Hophra king of Egypt into the hand of his enemies and into the hand of those who seek his life, as I gave Zedekiah king of Judah into the hand of Nebuchadnezzar king of Babylon, his enemy who sought his life.\"'", 
                "num": 30
              }
            ], 
            "num": 44
          }, 
          {
            "verses": [
              {
                "text": "The word that Jeremiah the prophet spoke to Baruch the son of Neriah, when he had written these words in a book at the instruction of Jeremiah, in the fourth year of Jehoiakim the son of Josiah, king of Judah, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD, the God of Israel, to you, O Baruch:", 
                "num": 2
              }, 
              {
                "text": "\"You said, \"Woe is me now! For the LORD has added grief to my sorrow. I fainted in my sighing, and I find no rest.\"'", 
                "num": 3
              }, 
              {
                "text": "\"Thus you shall say to him, \"Thus says the LORD: \"Behold, what I have built I will break down, and what I have planted I will pluck up, that is, this whole land.", 
                "num": 4
              }, 
              {
                "text": "And do you seek great things for yourself? Do not seek them; for behold, I will bring adversity on all flesh,\" says the LORD. \"But I will give your life to you as a prize in all places, wherever you go.\"\"'", 
                "num": 5
              }
            ], 
            "num": 45
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD which came to Jeremiah the prophet against the nations.", 
                "num": 1
              }, 
              {
                "text": "Against Egypt. Concerning the army of Pharaoh Necho, king of Egypt, which was by the River Euphrates in Carchemish, and which Nebuchadnezzar king of Babylon defeated in the fourth year of Jehoiakim the son of Josiah, king of Judah:", 
                "num": 2
              }, 
              {
                "text": "\"Order the buckler and shield, And draw near to battle!", 
                "num": 3
              }, 
              {
                "text": "Harness the horses, And mount up, you horsemen! Stand forth with your helmets, Polish the spears, Put on the armor!", 
                "num": 4
              }, 
              {
                "text": "Why have I seen them dismayed and turned back? Their mighty ones are beaten down; They have speedily fled, And did not look back, For fear was all around,\" says the LORD.", 
                "num": 5
              }, 
              {
                "text": "\"Do not let the swift flee away, Nor the mighty man escape; They will stumble and fall Toward the north, by the River Euphrates.", 
                "num": 6
              }, 
              {
                "text": "\"Who is this coming up like a flood, Whose waters move like the rivers?", 
                "num": 7
              }, 
              {
                "text": "Egypt rises up like a flood, And its waters move like the rivers; And he says, \"I will go up and cover the earth, I will destroy the city and its inhabitants.'", 
                "num": 8
              }, 
              {
                "text": "Come up, O horses, and rage, O chariots! And let the mighty men come forth: The Ethiopians and the Libyans who handle the shield, And the Lydians who handle and bend the bow.", 
                "num": 9
              }, 
              {
                "text": "For this is the day of the Lord GOD of hosts, A day of vengeance, That He may avenge Himself on His adversaries. The sword shall devour; It shall be satiated and made drunk with their blood; For the Lord GOD of hosts has a sacrifice In the north country by the River Euphrates.", 
                "num": 10
              }, 
              {
                "text": "\"Go up to Gilead and take balm, O virgin, the daughter of Egypt; In vain you will use many medicines; You shall not be cured.", 
                "num": 11
              }, 
              {
                "text": "The nations have heard of your shame, And your cry has filled the land; For the mighty man has stumbled against the mighty; They both have fallen together.\"", 
                "num": 12
              }, 
              {
                "text": "The word that the LORD spoke to Jeremiah the prophet, how Nebuchadnezzar king of Babylon would come and strike the land of Egypt.", 
                "num": 13
              }, 
              {
                "text": "\"Declare in Egypt, and proclaim in Migdol; Proclaim in Noph and in Tahpanhes; Say, \"Stand fast and prepare yourselves, For the sword devours all around you.'", 
                "num": 14
              }, 
              {
                "text": "Why are your valiant men swept away? They did not stand Because the LORD drove them away.", 
                "num": 15
              }, 
              {
                "text": "He made many fall; Yes, one fell upon another. And they said, \"Arise! Let us go back to our own people And to the land of our nativity From the oppressing sword.'", 
                "num": 16
              }, 
              {
                "text": "They cried there, \"Pharaoh, king of Egypt, is but a noise. He has passed by the appointed time!'", 
                "num": 17
              }, 
              {
                "text": "\"As I live,\" says the King, Whose name is the LORD of hosts, \"Surely as Tabor is among the mountains And as Carmel by the sea, so he shall come.", 
                "num": 18
              }, 
              {
                "text": "O you daughter dwelling in Egypt, Prepare yourself to go into captivity! For Noph shall be waste and desolate, without inhabitant.", 
                "num": 19
              }, 
              {
                "text": "\"Egypt is a very pretty heifer, But destruction comes, it comes from the north.", 
                "num": 20
              }, 
              {
                "text": "Also her mercenaries are in her midst like fat bulls, For they also are turned back, They have fled away together. They did not stand, For the day of their calamity had come upon them, The time of their punishment.", 
                "num": 21
              }, 
              {
                "text": "Her noise shall go like a serpent, For they shall march with an army And come against her with axes, Like those who chop wood.", 
                "num": 22
              }, 
              {
                "text": "\"They shall cut down her forest,\" says the LORD, \"Though it cannot be searched, Because they are innumerable, And more numerous than grasshoppers.", 
                "num": 23
              }, 
              {
                "text": "The daughter of Egypt shall be ashamed; She shall be delivered into the hand Of the people of the north.\"", 
                "num": 24
              }, 
              {
                "text": "The LORD of hosts, the God of Israel, says: \"Behold, I will bring punishment on Amon of No, and Pharaoh and Egypt, with their gods and their kings--Pharaoh and those who trust in him.", 
                "num": 25
              }, 
              {
                "text": "And I will deliver them into the hand of those who seek their lives, into the hand of Nebuchadnezzar king of Babylon and the hand of his servants. Afterward it shall be inhabited as in the days of old,\" says the LORD.", 
                "num": 26
              }, 
              {
                "text": "\"But do not fear, O My servant Jacob, And do not be dismayed, O Israel! For behold, I will save you from afar, And your offspring from the land of their captivity; Jacob shall return, have rest and be at ease; No one shall make him afraid.", 
                "num": 27
              }, 
              {
                "text": "Do not fear, O Jacob My servant,\" says the LORD, \"For I am with you; For I will make a complete end of all the nations To which I have driven you, But I will not make a complete end of you. I will rightly correct you, For I will not leave you wholly unpunished.\"", 
                "num": 28
              }
            ], 
            "num": 46
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD that came to Jeremiah the prophet against the Philistines, before Pharaoh attacked Gaza.", 
                "num": 1
              }, 
              {
                "text": "Thus says the LORD: \"Behold, waters rise out of the north, And shall be an overflowing flood; They shall overflow the land and all that is in it, The city and those who dwell within; Then the men shall cry, And all the inhabitants of the land shall wail.", 
                "num": 2
              }, 
              {
                "text": "At the noise of the stamping hooves of his strong horses, At the rushing of his chariots, At the rumbling of his wheels, The fathers will not look back for their children, Lacking courage,", 
                "num": 3
              }, 
              {
                "text": "Because of the day that comes to plunder all the Philistines, To cut off from Tyre and Sidon every helper who remains; For the LORD shall plunder the Philistines, The remnant of the country of Caphtor.", 
                "num": 4
              }, 
              {
                "text": "Baldness has come upon Gaza, Ashkelon is cut off With the remnant of their valley. How long will you cut yourself?", 
                "num": 5
              }, 
              {
                "text": "\"O you sword of the LORD, How long until you are quiet? Put yourself up into your scabbard, Rest and be still!", 
                "num": 6
              }, 
              {
                "text": "How can it be quiet, Seeing the LORD has given it a charge Against Ashkelon and against the seashore? There He has appointed it.\"", 
                "num": 7
              }
            ], 
            "num": 47
          }, 
          {
            "verses": [
              {
                "text": "Against Moab. Thus says the LORD of hosts, the God of Israel: \"Woe to Nebo! For it is plundered, Kirjathaim is shamed and taken; The high stronghold is shamed and dismayed--", 
                "num": 1
              }, 
              {
                "text": "No more praise of Moab. In Heshbon they have devised evil against her: \"Come, and let us cut her off as a nation.' You also shall be cut down, O Madmen! The sword shall pursue you;", 
                "num": 2
              }, 
              {
                "text": "A voice of crying shall be from Horonaim: \"Plundering and great destruction!'", 
                "num": 3
              }, 
              {
                "text": "\"Moab is destroyed; Her little ones have caused a cry to be heard;", 
                "num": 4
              }, 
              {
                "text": "For in the Ascent of Luhith they ascend with continual weeping; For in the descent of Horonaim the enemies have heard a cry of destruction.", 
                "num": 5
              }, 
              {
                "text": "\"Flee, save your lives! And be like the juniper in the wilderness.", 
                "num": 6
              }, 
              {
                "text": "For because you have trusted in your works and your treasures, You also shall be taken. And Chemosh shall go forth into captivity, His priests and his princes together.", 
                "num": 7
              }, 
              {
                "text": "And the plunderer shall come against every city; No one shall escape. The valley also shall perish, And the plain shall be destroyed, As the LORD has spoken.", 
                "num": 8
              }, 
              {
                "text": "\"Give wings to Moab, That she may flee and get away; For her cities shall be desolate, Without any to dwell in them.", 
                "num": 9
              }, 
              {
                "text": "Cursed is he who does the work of the LORD deceitfully, And cursed is he who keeps back his sword from blood.", 
                "num": 10
              }, 
              {
                "text": "\"Moab has been at ease from his youth; He has settled on his dregs, And has not been emptied from vessel to vessel, Nor has he gone into captivity. Therefore his taste remained in him, And his scent has not changed.", 
                "num": 11
              }, 
              {
                "text": "\"Therefore behold, the days are coming,\" says the LORD, \"That I shall send him wine-workers Who will tip him over And empty his vessels And break the bottles.", 
                "num": 12
              }, 
              {
                "text": "Moab shall be ashamed of Chemosh, As the house of Israel was ashamed of Bethel, their confidence.", 
                "num": 13
              }, 
              {
                "text": "\"How can you say, \"We are mighty And strong men for the war'?", 
                "num": 14
              }, 
              {
                "text": "Moab is plundered and gone up from her cities; Her chosen young men have gone down to the slaughter,\" says the King, Whose name is the LORD of hosts.", 
                "num": 15
              }, 
              {
                "text": "\"The calamity of Moab is near at hand, And his affliction comes quickly.", 
                "num": 16
              }, 
              {
                "text": "Bemoan him, all you who are around him; And all you who know his name, Say, \"How the strong staff is broken, The beautiful rod!'", 
                "num": 17
              }, 
              {
                "text": "\"O daughter inhabiting Dibon, Come down from your glory, And sit in thirst; For the plunderer of Moab has come against you, He has destroyed your strongholds.", 
                "num": 18
              }, 
              {
                "text": "O inhabitant of Aroer, Stand by the way and watch; Ask him who flees And her who escapes; Say, \"What has happened?'", 
                "num": 19
              }, 
              {
                "text": "Moab is shamed, for he is broken down. Wail and cry! Tell it in Arnon, that Moab is plundered.", 
                "num": 20
              }, 
              {
                "text": "\"And judgment has come on the plain country: On Holon and Jahzah and Mephaath,", 
                "num": 21
              }, 
              {
                "text": "On Dibon and Nebo and Beth Diblathaim,", 
                "num": 22
              }, 
              {
                "text": "On Kirjathaim and Beth Gamul and Beth Meon,", 
                "num": 23
              }, 
              {
                "text": "On Kerioth and Bozrah, On all the cities of the land of Moab, Far or near.", 
                "num": 24
              }, 
              {
                "text": "The horn of Moab is cut off, And his arm is broken,\" says the LORD.", 
                "num": 25
              }, 
              {
                "text": "\"Make him drunk, Because he exalted himself against the LORD. Moab shall wallow in his vomit, And he shall also be in derision.", 
                "num": 26
              }, 
              {
                "text": "For was not Israel a derision to you? Was he found among thieves? For whenever you speak of him, You shake your head in scorn.", 
                "num": 27
              }, 
              {
                "text": "You who dwell in Moab, Leave the cities and dwell in the rock, And be like the dove which makes her nest In the sides of the cave's mouth.", 
                "num": 28
              }, 
              {
                "text": "\"We have heard the pride of Moab (He is exceedingly proud), Of his loftiness and arrogance and pride, And of the haughtiness of his heart.\"", 
                "num": 29
              }, 
              {
                "text": "\"I know his wrath,\" says the LORD, \"But it is not right; His lies have made nothing right.", 
                "num": 30
              }, 
              {
                "text": "Therefore I will wail for Moab, And I will cry out for all Moab; I will mourn for the men of Kir Heres.", 
                "num": 31
              }, 
              {
                "text": "O vine of Sibmah! I will weep for you with the weeping of Jazer. Your plants have gone over the sea, They reach to the sea of Jazer. The plunderer has fallen on your summer fruit and your vintage.", 
                "num": 32
              }, 
              {
                "text": "Joy and gladness are taken From the plentiful field And from the land of Moab; I have caused wine to fail from the winepresses; No one will tread with joyous shouting-- Not joyous shouting!", 
                "num": 33
              }, 
              {
                "text": "\"From the cry of Heshbon to Elealeh and to Jahaz They have uttered their voice, From Zoar to Horonaim, Like a three-year-old heifer; For the waters of Nimrim also shall be desolate.", 
                "num": 34
              }, 
              {
                "text": "\"Moreover,\" says the LORD, \"I will cause to cease in Moab The one who offers sacrifices in the high places And burns incense to his gods.", 
                "num": 35
              }, 
              {
                "text": "Therefore My heart shall wail like flutes for Moab, And like flutes My heart shall wail For the men of Kir Heres. Therefore the riches they have acquired have perished.", 
                "num": 36
              }, 
              {
                "text": "\"For every head shall be bald, and every beard clipped; On all the hands shall be cuts, and on the loins sackcloth--", 
                "num": 37
              }, 
              {
                "text": "A general lamentation On all the housetops of Moab, And in its streets; For I have broken Moab like a vessel in which is no pleasure,\" says the LORD.", 
                "num": 38
              }, 
              {
                "text": "\"They shall wail: \"How she is broken down! How Moab has turned her back with shame!' So Moab shall be a derision And a dismay to all those about her.\"", 
                "num": 39
              }, 
              {
                "text": "For thus says the LORD: \"Behold, one shall fly like an eagle, And spread his wings over Moab.", 
                "num": 40
              }, 
              {
                "text": "Kerioth is taken, And the strongholds are surprised; The mighty men's hearts in Moab on that day shall be Like the heart of a woman in birth pangs.", 
                "num": 41
              }, 
              {
                "text": "And Moab shall be destroyed as a people, Because he exalted himself against the LORD.", 
                "num": 42
              }, 
              {
                "text": "Fear and the pit and the snare shall be upon you, O inhabitant of Moab,\" says the LORD.", 
                "num": 43
              }, 
              {
                "text": "\"He who flees from the fear shall fall into the pit, And he who gets out of the pit shall be caught in the snare. For upon Moab, upon it I will bring The year of their punishment,\" says the LORD.", 
                "num": 44
              }, 
              {
                "text": "\"Those who fled stood under the shadow of Heshbon Because of exhaustion. But a fire shall come out of Heshbon, A flame from the midst of Sihon, And shall devour the brow of Moab, The crown of the head of the sons of tumult.", 
                "num": 45
              }, 
              {
                "text": "Woe to you, O Moab! The people of Chemosh perish; For your sons have been taken captive, And your daughters captive.", 
                "num": 46
              }, 
              {
                "text": "\"Yet I will bring back the captives of Moab In the latter days,\" says the LORD. Thus far is the judgment of Moab.", 
                "num": 47
              }
            ], 
            "num": 48
          }, 
          {
            "verses": [
              {
                "text": "Against the Ammonites. Thus says the LORD: \"Has Israel no sons? Has he no heir? Why then does Milcom inherit Gad, And his people dwell in its cities?", 
                "num": 1
              }, 
              {
                "text": "Therefore behold, the days are coming,\" says the LORD, \"That I will cause to be heard an alarm of war In Rabbah of the Ammonites; It shall be a desolate mound, And her villages shall be burned with fire. Then Israel shall take possession of his inheritance,\" says the LORD.", 
                "num": 2
              }, 
              {
                "text": "\"Wail, O Heshbon, for Ai is plundered! Cry, you daughters of Rabbah, Gird yourselves with sackcloth! Lament and run to and fro by the walls; For Milcom shall go into captivity With his priests and his princes together.", 
                "num": 3
              }, 
              {
                "text": "Why do you boast in the valleys, Your flowing valley, O backsliding daughter? Who trusted in her treasures, saying, \"Who will come against me?'", 
                "num": 4
              }, 
              {
                "text": "Behold, I will bring fear upon you,\" Says the Lord GOD of hosts, \"From all those who are around you; You shall be driven out, everyone headlong, And no one will gather those who wander off.", 
                "num": 5
              }, 
              {
                "text": "But afterward I will bring back The captives of the people of Ammon,\" says the LORD.", 
                "num": 6
              }, 
              {
                "text": "Against Edom. Thus says the LORD of hosts: \"Is wisdom no more in Teman? Has counsel perished from the prudent? Has their wisdom vanished?", 
                "num": 7
              }, 
              {
                "text": "Flee, turn back, dwell in the depths, O inhabitants of Dedan! For I will bring the calamity of Esau upon him, The time that I will punish him.", 
                "num": 8
              }, 
              {
                "text": "If grape-gatherers came to you, Would they not leave some gleaning grapes? If thieves by night, Would they not destroy until they have enough?", 
                "num": 9
              }, 
              {
                "text": "But I have made Esau bare; I have uncovered his secret places, And he shall not be able to hide himself. His descendants are plundered, His brethren and his neighbors, And he is no more.", 
                "num": 10
              }, 
              {
                "text": "Leave your fatherless children, I will preserve them alive; And let your widows trust in Me.\"", 
                "num": 11
              }, 
              {
                "text": "For thus says the LORD: \"Behold, those whose judgment was not to drink of the cup have assuredly drunk. And are you the one who will altogether go unpunished? You shall not go unpunished, but you shall surely drink of it.", 
                "num": 12
              }, 
              {
                "text": "For I have sworn by Myself,\" says the LORD, \"that Bozrah shall become a desolation, a reproach, a waste, and a curse. And all its cities shall be perpetual wastes.\"", 
                "num": 13
              }, 
              {
                "text": "I have heard a message from the LORD, And an ambassador has been sent to the nations: \"Gather together, come against her, And rise up to battle!", 
                "num": 14
              }, 
              {
                "text": "\"For indeed, I will make you small among nations, Despised among men.", 
                "num": 15
              }, 
              {
                "text": "Your fierceness has deceived you, The pride of your heart, O you who dwell in the clefts of the rock, Who hold the height of the hill! Though you make your nest as high as the eagle, I will bring you down from there,\" says the LORD.", 
                "num": 16
              }, 
              {
                "text": "\"Edom also shall be an astonishment; Everyone who goes by it will be astonished And will hiss at all its plagues.", 
                "num": 17
              }, 
              {
                "text": "As in the overthrow of Sodom and Gomorrah And their neighbors,\" says the LORD, \"No one shall remain there, Nor shall a son of man dwell in it.", 
                "num": 18
              }, 
              {
                "text": "\"Behold, he shall come up like a lion from the floodplain of the Jordan Against the dwelling place of the strong; But I will suddenly make him run away from her. And who is a chosen man that I may appoint over her? For who is like Me? Who will arraign Me? And who is that shepherd Who will withstand Me?\"", 
                "num": 19
              }, 
              {
                "text": "Therefore hear the counsel of the LORD that He has taken against Edom, And His purposes that He has proposed against the inhabitants of Teman: Surely the least of the flock shall draw them out; Surely He shall make their dwelling places desolate with them.", 
                "num": 20
              }, 
              {
                "text": "The earth shakes at the noise of their fall; At the cry its noise is heard at the Red Sea.", 
                "num": 21
              }, 
              {
                "text": "Behold, He shall come up and fly like the eagle, And spread His wings over Bozrah; The heart of the mighty men of Edom in that day shall be Like the heart of a woman in birth pangs.", 
                "num": 22
              }, 
              {
                "text": "Against Damascus. \"Hamath and Arpad are shamed, For they have heard bad news. They are fainthearted; There is trouble on the sea; It cannot be quiet.", 
                "num": 23
              }, 
              {
                "text": "Damascus has grown feeble; She turns to flee, And fear has seized her. Anguish and sorrows have taken her like a woman in labor.", 
                "num": 24
              }, 
              {
                "text": "Why is the city of praise not deserted, the city of My joy?", 
                "num": 25
              }, 
              {
                "text": "Therefore her young men shall fall in her streets, And all the men of war shall be cut off in that day,\" says the LORD of hosts.", 
                "num": 26
              }, 
              {
                "text": "\"I will kindle a fire in the wall of Damascus, And it shall consume the palaces of Ben-Hadad.\"", 
                "num": 27
              }, 
              {
                "text": "Against Kedar and against the kingdoms of Hazor, which Nebuchadnezzar king of Babylon shall strike. Thus says the LORD: \"Arise, go up to Kedar, And devastate the men of the East!", 
                "num": 28
              }, 
              {
                "text": "Their tents and their flocks they shall take away. They shall take for themselves their curtains, All their vessels and their camels; And they shall cry out to them, \"Fear is on every side!'", 
                "num": 29
              }, 
              {
                "text": "\"Flee, get far away! Dwell in the depths, O inhabitants of Hazor!\" says the LORD. \"For Nebuchadnezzar king of Babylon has taken counsel against you, And has conceived a plan against you.", 
                "num": 30
              }, 
              {
                "text": "\"Arise, go up to the wealthy nation that dwells securely,\" says the LORD, \"Which has neither gates nor bars, Dwelling alone.", 
                "num": 31
              }, 
              {
                "text": "Their camels shall be for booty, And the multitude of their cattle for plunder. I will scatter to all winds those in the farthest corners, And I will bring their calamity from all its sides,\" says the LORD.", 
                "num": 32
              }, 
              {
                "text": "\"Hazor shall be a dwelling for jackals, a desolation forever; No one shall reside there, Nor son of man dwell in it.\"", 
                "num": 33
              }, 
              {
                "text": "The word of the LORD that came to Jeremiah the prophet against Elam, in the beginning of the reign of Zedekiah king of Judah, saying,", 
                "num": 34
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Behold, I will break the bow of Elam, The foremost of their might.", 
                "num": 35
              }, 
              {
                "text": "Against Elam I will bring the four winds From the four quarters of heaven, And scatter them toward all those winds; There shall be no nations where the outcasts of Elam will not go.", 
                "num": 36
              }, 
              {
                "text": "For I will cause Elam to be dismayed before their enemies And before those who seek their life. I will bring disaster upon them, My fierce anger,' says the LORD; \"And I will send the sword after them Until I have consumed them.", 
                "num": 37
              }, 
              {
                "text": "I will set My throne in Elam, And will destroy from there the king and the princes,' says the LORD.", 
                "num": 38
              }, 
              {
                "text": "\"But it shall come to pass in the latter days: I will bring back the captives of Elam,' says the LORD.\"", 
                "num": 39
              }
            ], 
            "num": 49
          }, 
          {
            "verses": [
              {
                "text": "The word that the LORD spoke against Babylon and against the land of the Chaldeans by Jeremiah the prophet.", 
                "num": 1
              }, 
              {
                "text": "\"Declare among the nations, Proclaim, and set up a standard; Proclaim--do not conceal it-- Say, \"Babylon is taken, Bel is shamed. Merodach is broken in pieces; Her idols are humiliated, Her images are broken in pieces.'", 
                "num": 2
              }, 
              {
                "text": "For out of the north a nation comes up against her, Which shall make her land desolate, And no one shall dwell therein. They shall move, they shall depart, Both man and beast.", 
                "num": 3
              }, 
              {
                "text": "\"In those days and in that time,\" says the LORD, \"The children of Israel shall come, They and the children of Judah together; With continual weeping they shall come, And seek the LORD their God.", 
                "num": 4
              }, 
              {
                "text": "They shall ask the way to Zion, With their faces toward it, saying, \"Come and let us join ourselves to the LORD In a perpetual covenant That will not be forgotten.'", 
                "num": 5
              }, 
              {
                "text": "\"My people have been lost sheep. Their shepherds have led them astray; They have turned them away on the mountains. They have gone from mountain to hill; They have forgotten their resting place.", 
                "num": 6
              }, 
              {
                "text": "All who found them have devoured them; And their adversaries said, \"We have not offended, Because they have sinned against the LORD, the habitation of justice, The LORD, the hope of their fathers.'", 
                "num": 7
              }, 
              {
                "text": "\"Move from the midst of Babylon, Go out of the land of the Chaldeans; And be like the rams before the flocks.", 
                "num": 8
              }, 
              {
                "text": "For behold, I will raise and cause to come up against Babylon An assembly of great nations from the north country, And they shall array themselves against her; From there she shall be captured. Their arrows shall be like those of an expert warrior; None shall return in vain.", 
                "num": 9
              }, 
              {
                "text": "And Chaldea shall become plunder; All who plunder her shall be satisfied,\" says the LORD.", 
                "num": 10
              }, 
              {
                "text": "\"Because you were glad, because you rejoiced, You destroyers of My heritage, Because you have grown fat like a heifer threshing grain, And you bellow like bulls,", 
                "num": 11
              }, 
              {
                "text": "Your mother shall be deeply ashamed; She who bore you shall be ashamed. Behold, the least of the nations shall be a wilderness, A dry land and a desert.", 
                "num": 12
              }, 
              {
                "text": "Because of the wrath of the LORD She shall not be inhabited, But she shall be wholly desolate. Everyone who goes by Babylon shall be horrified And hiss at all her plagues.", 
                "num": 13
              }, 
              {
                "text": "\"Put yourselves in array against Babylon all around, All you who bend the bow; Shoot at her, spare no arrows, For she has sinned against the LORD.", 
                "num": 14
              }, 
              {
                "text": "Shout against her all around; She has given her hand, Her foundations have fallen, Her walls are thrown down; For it is the vengeance of the LORD. Take vengeance on her. As she has done, so do to her.", 
                "num": 15
              }, 
              {
                "text": "Cut off the sower from Babylon, And him who handles the sickle at harvest time. For fear of the oppressing sword Everyone shall turn to his own people, And everyone shall flee to his own land.", 
                "num": 16
              }, 
              {
                "text": "\"Israel is like scattered sheep; The lions have driven him away. First the king of Assyria devoured him; Now at last this Nebuchadnezzar king of Babylon has broken his bones.\"", 
                "num": 17
              }, 
              {
                "text": "Therefore thus says the LORD of hosts, the God of Israel: \"Behold, I will punish the king of Babylon and his land, As I have punished the king of Assyria.", 
                "num": 18
              }, 
              {
                "text": "But I will bring back Israel to his home, And he shall feed on Carmel and Bashan; His soul shall be satisfied on Mount Ephraim and Gilead.", 
                "num": 19
              }, 
              {
                "text": "In those days and in that time,\" says the LORD, \"The iniquity of Israel shall be sought, but there shall be none; And the sins of Judah, but they shall not be found; For I will pardon those whom I preserve.", 
                "num": 20
              }, 
              {
                "text": "\"Go up against the land of Merathaim, against it, And against the inhabitants of Pekod. Waste and utterly destroy them,\" says the LORD, \"And do according to all that I have commanded you.", 
                "num": 21
              }, 
              {
                "text": "A sound of battle is in the land, And of great destruction.", 
                "num": 22
              }, 
              {
                "text": "How the hammer of the whole earth has been cut apart and broken! How Babylon has become a desolation among the nations! I have laid a snare for you;", 
                "num": 23
              }, 
              {
                "text": "You have indeed been trapped, O Babylon, And you were not aware; You have been found and also caught, Because you have contended against the LORD.", 
                "num": 24
              }, 
              {
                "text": "The LORD has opened His armory, And has brought out the weapons of His indignation; For this is the work of the Lord GOD of hosts In the land of the Chaldeans.", 
                "num": 25
              }, 
              {
                "text": "Come against her from the farthest border; Open her storehouses; Cast her up as heaps of ruins, And destroy her utterly; Let nothing of her be left.", 
                "num": 26
              }, 
              {
                "text": "Slay all her bulls, Let them go down to the slaughter. Woe to them! For their day has come, the time of their punishment.", 
                "num": 27
              }, 
              {
                "text": "The voice of those who flee and escape from the land of Babylon Declares in Zion the vengeance of the LORD our God, The vengeance of His temple.", 
                "num": 28
              }, 
              {
                "text": "\"Call together the archers against Babylon. All you who bend the bow, encamp against it all around; Let none of them escape. Repay her according to her work; According to all she has done, do to her; For she has been proud against the LORD, Against the Holy One of Israel.", 
                "num": 29
              }, 
              {
                "text": "Therefore her young men shall fall in the streets, And all her men of war shall be cut off in that day,\" says the LORD.", 
                "num": 30
              }, 
              {
                "text": "\"Behold, I am against you, O most haughty one!\" says the Lord GOD of hosts; \"For your day has come, The time that I will punish you.", 
                "num": 31
              }, 
              {
                "text": "The most proud shall stumble and fall, And no one will raise him up; I will kindle a fire in his cities, And it will devour all around him.\"", 
                "num": 32
              }, 
              {
                "text": "Thus says the LORD of hosts: \"The children of Israel were oppressed, Along with the children of Judah; All who took them captive have held them fast; They have refused to let them go.", 
                "num": 33
              }, 
              {
                "text": "Their Redeemer is strong; The LORD of hosts is His name. He will thoroughly plead their case, That He may give rest to the land, And disquiet the inhabitants of Babylon.", 
                "num": 34
              }, 
              {
                "text": "\"A sword is against the Chaldeans,\" says the LORD, \"Against the inhabitants of Babylon, And against her princes and her wise men.", 
                "num": 35
              }, 
              {
                "text": "A sword is against the soothsayers, and they will be fools. A sword is against her mighty men, and they will be dismayed.", 
                "num": 36
              }, 
              {
                "text": "A sword is against their horses, Against their chariots, And against all the mixed peoples who are in her midst; And they will become like women. A sword is against her treasures, and they will be robbed.", 
                "num": 37
              }, 
              {
                "text": "A drought is against her waters, and they will be dried up. For it is the land of carved images, And they are insane with their idols.", 
                "num": 38
              }, 
              {
                "text": "\"Therefore the wild desert beasts shall dwell there with the jackals, And the ostriches shall dwell in it. It shall be inhabited no more forever, Nor shall it be dwelt in from generation to generation.", 
                "num": 39
              }, 
              {
                "text": "As God overthrew Sodom and Gomorrah And their neighbors,\" says the LORD, \"So no one shall reside there, Nor son of man dwell in it.", 
                "num": 40
              }, 
              {
                "text": "\"Behold, a people shall come from the north, And a great nation and many kings Shall be raised up from the ends of the earth.", 
                "num": 41
              }, 
              {
                "text": "They shall hold the bow and the lance; They are cruel and shall not show mercy. Their voice shall roar like the sea; They shall ride on horses, Set in array, like a man for the battle, Against you, O daughter of Babylon.", 
                "num": 42
              }, 
              {
                "text": "\"The king of Babylon has heard the report about them, And his hands grow feeble; Anguish has taken hold of him, Pangs as of a woman in childbirth.", 
                "num": 43
              }, 
              {
                "text": "\"Behold, he shall come up like a lion from the floodplain of the Jordan Against the dwelling place of the strong; But I will make them suddenly run away from her. And who is a chosen man that I may appoint over her? For who is like Me? Who will arraign Me? And who is that shepherd Who will withstand Me?\"", 
                "num": 44
              }, 
              {
                "text": "Therefore hear the counsel of the LORD that He has taken against Babylon, And His purposes that He has proposed against the land of the Chaldeans: Surely the least of the flock shall draw them out; Surely He will make their dwelling place desolate with them.", 
                "num": 45
              }, 
              {
                "text": "At the noise of the taking of Babylon The earth trembles, And the cry is heard among the nations.", 
                "num": 46
              }
            ], 
            "num": 50
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"Behold, I will raise up against Babylon, Against those who dwell in Leb Kamai, A destroying wind.", 
                "num": 1
              }, 
              {
                "text": "And I will send winnowers to Babylon, Who shall winnow her and empty her land. For in the day of doom They shall be against her all around.", 
                "num": 2
              }, 
              {
                "text": "Against her let the archer bend his bow, And lift himself up against her in his armor. Do not spare her young men; Utterly destroy all her army.", 
                "num": 3
              }, 
              {
                "text": "Thus the slain shall fall in the land of the Chaldeans, And those thrust through in her streets.", 
                "num": 4
              }, 
              {
                "text": "For Israel is not forsaken, nor Judah, By his God, the LORD of hosts, Though their land was filled with sin against the Holy One of Israel.\"", 
                "num": 5
              }, 
              {
                "text": "Flee from the midst of Babylon, And every one save his life! Do not be cut off in her iniquity, For this is the time of the LORD's vengeance; He shall recompense her.", 
                "num": 6
              }, 
              {
                "text": "Babylon was a golden cup in the LORD's hand, That made all the earth drunk. The nations drank her wine; Therefore the nations are deranged.", 
                "num": 7
              }, 
              {
                "text": "Babylon has suddenly fallen and been destroyed. Wail for her! Take balm for her pain; Perhaps she may be healed.", 
                "num": 8
              }, 
              {
                "text": "We would have healed Babylon, But she is not healed. Forsake her, and let us go everyone to his own country; For her judgment reaches to heaven and is lifted up to the skies.", 
                "num": 9
              }, 
              {
                "text": "The LORD has revealed our righteousness. Come and let us declare in Zion the work of the LORD our God.", 
                "num": 10
              }, 
              {
                "text": "Make the arrows bright! Gather the shields! The LORD has raised up the spirit of the kings of the Medes. For His plan is against Babylon to destroy it, Because it is the vengeance of the LORD, The vengeance for His temple.", 
                "num": 11
              }, 
              {
                "text": "Set up the standard on the walls of Babylon; Make the guard strong, Set up the watchmen, Prepare the ambushes. For the LORD has both devised and done What He spoke against the inhabitants of Babylon.", 
                "num": 12
              }, 
              {
                "text": "O you who dwell by many waters, Abundant in treasures, Your end has come, The measure of your covetousness.", 
                "num": 13
              }, 
              {
                "text": "The LORD of hosts has sworn by Himself: \"Surely I will fill you with men, as with locusts, And they shall lift up a shout against you.\"", 
                "num": 14
              }, 
              {
                "text": "He has made the earth by His power; He has established the world by His wisdom, And stretched out the heaven by His understanding.", 
                "num": 15
              }, 
              {
                "text": "When He utters His voice-- There is a multitude of waters in the heavens: \"He causes the vapors to ascend from the ends of the earth; He makes lightnings for the rain; He brings the wind out of His treasuries.\"", 
                "num": 16
              }, 
              {
                "text": "Everyone is dull-hearted, without knowledge; Every metalsmith is put to shame by the carved image; For his molded image is falsehood, And there is no breath in them.", 
                "num": 17
              }, 
              {
                "text": "They are futile, a work of errors; In the time of their punishment they shall perish.", 
                "num": 18
              }, 
              {
                "text": "The Portion of Jacob is not like them, For He is the Maker of all things; And Israel is the tribe of His inheritance. The LORD of hosts is His name.", 
                "num": 19
              }, 
              {
                "text": "\"You are My battle-ax and weapons of war: For with you I will break the nation in pieces; With you I will destroy kingdoms;", 
                "num": 20
              }, 
              {
                "text": "With you I will break in pieces the horse and its rider; With you I will break in pieces the chariot and its rider;", 
                "num": 21
              }, 
              {
                "text": "With you also I will break in pieces man and woman; With you I will break in pieces old and young; With you I will break in pieces the young man and the maiden;", 
                "num": 22
              }, 
              {
                "text": "With you also I will break in pieces the shepherd and his flock; With you I will break in pieces the farmer and his yoke of oxen; And with you I will break in pieces governors and rulers.", 
                "num": 23
              }, 
              {
                "text": "\"And I will repay Babylon And all the inhabitants of Chaldea For all the evil they have done In Zion in your sight,\" says the LORD.", 
                "num": 24
              }, 
              {
                "text": "\"Behold, I am against you, O destroying mountain, Who destroys all the earth,\" says the LORD. \"And I will stretch out My hand against you, Roll you down from the rocks, And make you a burnt mountain.", 
                "num": 25
              }, 
              {
                "text": "They shall not take from you a stone for a corner Nor a stone for a foundation, But you shall be desolate forever,\" says the LORD.", 
                "num": 26
              }, 
              {
                "text": "Set up a banner in the land, Blow the trumpet among the nations! Prepare the nations against her, Call the kingdoms together against her: Ararat, Minni, and Ashkenaz. Appoint a general against her; Cause the horses to come up like the bristling locusts.", 
                "num": 27
              }, 
              {
                "text": "Prepare against her the nations, With the kings of the Medes, Its governors and all its rulers, All the land of his dominion.", 
                "num": 28
              }, 
              {
                "text": "And the land will tremble and sorrow; For every purpose of the LORD shall be performed against Babylon, To make the land of Babylon a desolation without inhabitant.", 
                "num": 29
              }, 
              {
                "text": "The mighty men of Babylon have ceased fighting, They have remained in their strongholds; Their might has failed, They became like women; They have burned her dwelling places, The bars of her gate are broken.", 
                "num": 30
              }, 
              {
                "text": "One runner will run to meet another, And one messenger to meet another, To show the king of Babylon that his city is taken on all sides;", 
                "num": 31
              }, 
              {
                "text": "The passages are blocked, The reeds they have burned with fire, And the men of war are terrified.", 
                "num": 32
              }, 
              {
                "text": "For thus says the LORD of hosts, the God of Israel: \"The daughter of Babylon is like a threshing floor When it is time to thresh her; Yet a little while And the time of her harvest will come.\"", 
                "num": 33
              }, 
              {
                "text": "\"Nebuchadnezzar the king of Babylon Has devoured me, he has crushed me; He has made me an empty vessel, He has swallowed me up like a monster; He has filled his stomach with my delicacies, He has spit me out.", 
                "num": 34
              }, 
              {
                "text": "Let the violence done to me and my flesh be upon Babylon,\" The inhabitant of Zion will say; \"And my blood be upon the inhabitants of Chaldea!\" Jerusalem will say.", 
                "num": 35
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, I will plead your case and take vengeance for you. I will dry up her sea and make her springs dry.", 
                "num": 36
              }, 
              {
                "text": "Babylon shall become a heap, A dwelling place for jackals, An astonishment and a hissing, Without an inhabitant.", 
                "num": 37
              }, 
              {
                "text": "They shall roar together like lions, They shall growl like lions' whelps.", 
                "num": 38
              }, 
              {
                "text": "In their excitement I will prepare their feasts; I will make them drunk, That they may rejoice, And sleep a perpetual sleep And not awake,\" says the LORD.", 
                "num": 39
              }, 
              {
                "text": "\"I will bring them down Like lambs to the slaughter, Like rams with male goats.", 
                "num": 40
              }, 
              {
                "text": "\"Oh, how Sheshach is taken! Oh, how the praise of the whole earth is seized! How Babylon has become desolate among the nations!", 
                "num": 41
              }, 
              {
                "text": "The sea has come up over Babylon; She is covered with the multitude of its waves.", 
                "num": 42
              }, 
              {
                "text": "Her cities are a desolation, A dry land and a wilderness, A land where no one dwells, Through which no son of man passes.", 
                "num": 43
              }, 
              {
                "text": "I will punish Bel in Babylon, And I will bring out of his mouth what he has swallowed; And the nations shall not stream to him anymore. Yes, the wall of Babylon shall fall.", 
                "num": 44
              }, 
              {
                "text": "\"My people, go out of the midst of her! And let everyone deliver himself from the fierce anger of the LORD.", 
                "num": 45
              }, 
              {
                "text": "And lest your heart faint, And you fear for the rumor that will be heard in the land (A rumor will come one year, And after that, in another year A rumor will come, And violence in the land, Ruler against ruler),", 
                "num": 46
              }, 
              {
                "text": "Therefore behold, the days are coming That I will bring judgment on the carved images of Babylon; Her whole land shall be ashamed, And all her slain shall fall in her midst.", 
                "num": 47
              }, 
              {
                "text": "Then the heavens and the earth and all that is in them Shall sing joyously over Babylon; For the plunderers shall come to her from the north,\" says the LORD.", 
                "num": 48
              }, 
              {
                "text": "As Babylon has caused the slain of Israel to fall, So at Babylon the slain of all the earth shall fall.", 
                "num": 49
              }, 
              {
                "text": "You who have escaped the sword, Get away! Do not stand still! Remember the LORD afar off, And let Jerusalem come to your mind.", 
                "num": 50
              }, 
              {
                "text": "We are ashamed because we have heard reproach. Shame has covered our faces, For strangers have come into the sanctuaries of the LORD's house.", 
                "num": 51
              }, 
              {
                "text": "\"Therefore behold, the days are coming,\" says the LORD, \"That I will bring judgment on her carved images, And throughout all her land the wounded shall groan.", 
                "num": 52
              }, 
              {
                "text": "Though Babylon were to mount up to heaven, And though she were to fortify the height of her strength, Yet from Me plunderers would come to her,\" says the LORD.", 
                "num": 53
              }, 
              {
                "text": "The sound of a cry comes from Babylon, And great destruction from the land of the Chaldeans,", 
                "num": 54
              }, 
              {
                "text": "Because the LORD is plundering Babylon And silencing her loud voice, Though her waves roar like great waters, And the noise of their voice is uttered,", 
                "num": 55
              }, 
              {
                "text": "Because the plunderer comes against her, against Babylon, And her mighty men are taken. Every one of their bows is broken; For the LORD is the God of recompense, He will surely repay.", 
                "num": 56
              }, 
              {
                "text": "\"And I will make drunk Her princes and wise men, Her governors, her deputies, and her mighty men. And they shall sleep a perpetual sleep And not awake,\" says the King, Whose name is the LORD of hosts.", 
                "num": 57
              }, 
              {
                "text": "Thus says the LORD of hosts: \"The broad walls of Babylon shall be utterly broken, And her high gates shall be burned with fire; The people will labor in vain, And the nations, because of the fire; And they shall be weary.\"", 
                "num": 58
              }, 
              {
                "text": "The word which Jeremiah the prophet commanded Seraiah the son of Neriah, the son of Mahseiah, when he went with Zedekiah the king of Judah to Babylon in the fourth year of his reign. And Seraiah was the quartermaster.", 
                "num": 59
              }, 
              {
                "text": "So Jeremiah wrote in a book all the evil that would come upon Babylon, all these words that are written against Babylon.", 
                "num": 60
              }, 
              {
                "text": "And Jeremiah said to Seraiah, \"When you arrive in Babylon and see it, and read all these words,", 
                "num": 61
              }, 
              {
                "text": "then you shall say, \"O LORD, You have spoken against this place to cut it off, so that none shall remain in it, neither man nor beast, but it shall be desolate forever.'", 
                "num": 62
              }, 
              {
                "text": "Now it shall be, when you have finished reading this book, that you shall tie a stone to it and throw it out into the Euphrates.", 
                "num": 63
              }, 
              {
                "text": "Then you shall say, \"Thus Babylon shall sink and not rise from the catastrophe that I will bring upon her. And they shall be weary.\"' Thus far are the words of Jeremiah.", 
                "num": 64
              }
            ], 
            "num": 51
          }, 
          {
            "verses": [
              {
                "text": "Zedekiah was twenty-one years old when he became king, and he reigned eleven years in Jerusalem. His mother's name was Hamutal the daughter of Jeremiah of Libnah.", 
                "num": 1
              }, 
              {
                "text": "He also did evil in the sight of the LORD, according to all that Jehoiakim had done.", 
                "num": 2
              }, 
              {
                "text": "For because of the anger of the LORD this happened in Jerusalem and Judah, till He finally cast them out from His presence. Then Zedekiah rebelled against the king of Babylon.", 
                "num": 3
              }, 
              {
                "text": "Now it came to pass in the ninth year of his reign, in the tenth month, on the tenth day of the month, that Nebuchadnezzar king of Babylon and all his army came against Jerusalem and encamped against it; and they built a siege wall against it all around.", 
                "num": 4
              }, 
              {
                "text": "So the city was besieged until the eleventh year of King Zedekiah.", 
                "num": 5
              }, 
              {
                "text": "By the fourth month, on the ninth day of the month, the famine had become so severe in the city that there was no food for the people of the land.", 
                "num": 6
              }, 
              {
                "text": "Then the city wall was broken through, and all the men of war fled and went out of the city at night by way of the gate between the two walls, which was by the king's garden, even though the Chaldeans were near the city all around. And they went by way of the plain.", 
                "num": 7
              }, 
              {
                "text": "But the army of the Chaldeans pursued the king, and they overtook Zedekiah in the plains of Jericho. All his army was scattered from him.", 
                "num": 8
              }, 
              {
                "text": "So they took the king and brought him up to the king of Babylon at Riblah in the land of Hamath, and he pronounced judgment on him.", 
                "num": 9
              }, 
              {
                "text": "Then the king of Babylon killed the sons of Zedekiah before his eyes. And he killed all the princes of Judah in Riblah.", 
                "num": 10
              }, 
              {
                "text": "He also put out the eyes of Zedekiah; and the king of Babylon bound him in bronze fetters, took him to Babylon, and put him in prison till the day of his death.", 
                "num": 11
              }, 
              {
                "text": "Now in the fifth month, on the tenth day of the month (which was the nineteenth year of King Nebuchadnezzar king of Babylon), Nebuzaradan, the captain of the guard, who served the king of Babylon, came to Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "He burned the house of the LORD and the king's house; all the houses of Jerusalem, that is, all the houses of the great, he burned with fire.", 
                "num": 13
              }, 
              {
                "text": "And all the army of the Chaldeans who were with the captain of the guard broke down all the walls of Jerusalem all around.", 
                "num": 14
              }, 
              {
                "text": "Then Nebuzaradan the captain of the guard carried away captive some of the poor people, the rest of the people who remained in the city, the defectors who had deserted to the king of Babylon, and the rest of the craftsmen.", 
                "num": 15
              }, 
              {
                "text": "But Nebuzaradan the captain of the guard left some of the poor of the land as vinedressers and farmers.", 
                "num": 16
              }, 
              {
                "text": "The bronze pillars that were in the house of the LORD, and the carts and the bronze Sea that were in the house of the LORD, the Chaldeans broke in pieces, and carried all their bronze to Babylon.", 
                "num": 17
              }, 
              {
                "text": "They also took away the pots, the shovels, the trimmers, the bowls, the spoons, and all the bronze utensils with which the priests ministered.", 
                "num": 18
              }, 
              {
                "text": "The basins, the firepans, the bowls, the pots, the lampstands, the spoons, and the cups, whatever was solid gold and whatever was solid silver, the captain of the guard took away.", 
                "num": 19
              }, 
              {
                "text": "The two pillars, one Sea, the twelve bronze bulls which were under it, and the carts, which King Solomon had made for the house of the LORD--the bronze of all these articles was beyond measure.", 
                "num": 20
              }, 
              {
                "text": "Now concerning the pillars: the height of one pillar was eighteen cubits, a measuring line of twelve cubits could measure its circumference, and its thickness was four fingers; it was hollow.", 
                "num": 21
              }, 
              {
                "text": "A capital of bronze was on it; and the height of one capital was five cubits, with a network and pomegranates all around the capital, all of bronze. The second pillar, with pomegranates was the same.", 
                "num": 22
              }, 
              {
                "text": "There were ninety-six pomegranates on the sides; all the pomegranates, all around on the network, were one hundred.", 
                "num": 23
              }, 
              {
                "text": "The captain of the guard took Seraiah the chief priest, Zephaniah the second priest, and the three doorkeepers.", 
                "num": 24
              }, 
              {
                "text": "He also took out of the city an officer who had charge of the men of war, seven men of the king's close associates who were found in the city, the principal scribe of the army who mustered the people of the land, and sixty men of the people of the land who were found in the midst of the city.", 
                "num": 25
              }, 
              {
                "text": "And Nebuzaradan the captain of the guard took these and brought them to the king of Babylon at Riblah.", 
                "num": 26
              }, 
              {
                "text": "Then the king of Babylon struck them and put them to death at Riblah in the land of Hamath. Thus Judah was carried away captive from its own land.", 
                "num": 27
              }, 
              {
                "text": "These are the people whom Nebuchadnezzar carried away captive: in the seventh year, three thousand and twenty-three Jews;", 
                "num": 28
              }, 
              {
                "text": "in the eighteenth year of Nebuchadnezzar he carried away captive from Jerusalem eight hundred and thirty-two persons;", 
                "num": 29
              }, 
              {
                "text": "in the twenty-third year of Nebuchadnezzar, Nebuzaradan the captain of the guard carried away captive of the Jews seven hundred and forty-five persons. All the persons were four thousand six hundred.", 
                "num": 30
              }, 
              {
                "text": "Now it came to pass in the thirty-seventh year of the captivity of Jehoiachin king of Judah, in the twelfth month, on the twenty-fifth day of the month, that Evil-Merodach king of Babylon, in the first year of his reign, lifted up the head of Jehoiachin king of Judah and brought him out of prison.", 
                "num": 31
              }, 
              {
                "text": "And he spoke kindly to him and gave him a more prominent seat than those of the kings who were with him in Babylon.", 
                "num": 32
              }, 
              {
                "text": "So Jehoiachin changed from his prison garments, and he ate bread regularly before the king all the days of his life.", 
                "num": 33
              }, 
              {
                "text": "And as for his provisions, there was a regular ration given him by the king of Babylon, a portion for each day until the day of his death, all the days of his life.", 
                "num": 34
              }
            ], 
            "num": 52
          }
        ]
      }, 
      {
        "name": "Lamentations", 
        "chapters": [
          {
            "verses": [
              {
                "text": "How lonely sits the city That was full of people! How like a widow is she, Who was great among the nations! The princess among the provinces Has become a slave!", 
                "num": 1
              }, 
              {
                "text": "She weeps bitterly in the night, Her tears are on her cheeks; Among all her lovers She has none to comfort her. All her friends have dealt treacherously with her; They have become her enemies.", 
                "num": 2
              }, 
              {
                "text": "Judah has gone into captivity, Under affliction and hard servitude; She dwells among the nations, She finds no rest; All her persecutors overtake her in dire straits.", 
                "num": 3
              }, 
              {
                "text": "The roads to Zion mourn Because no one comes to the set feasts. All her gates are desolate; Her priests sigh, Her virgins are afflicted, And she is in bitterness.", 
                "num": 4
              }, 
              {
                "text": "Her adversaries have become the master, Her enemies prosper; For the LORD has afflicted her Because of the multitude of her transgressions. Her children have gone into captivity before the enemy.", 
                "num": 5
              }, 
              {
                "text": "And from the daughter of Zion All her splendor has departed. Her princes have become like deer That find no pasture, That flee without strength Before the pursuer.", 
                "num": 6
              }, 
              {
                "text": "In the days of her affliction and roaming, Jerusalem remembers all her pleasant things That she had in the days of old. When her people fell into the hand of the enemy, With no one to help her, The adversaries saw her And mocked at her downfall.", 
                "num": 7
              }, 
              {
                "text": "Jerusalem has sinned gravely, Therefore she has become vile. All who honored her despise her Because they have seen her nakedness; Yes, she sighs and turns away.", 
                "num": 8
              }, 
              {
                "text": "Her uncleanness is in her skirts; She did not consider her destiny; Therefore her collapse was awesome; She had no comforter. \"O LORD, behold my affliction, For the enemy is exalted!\"", 
                "num": 9
              }, 
              {
                "text": "The adversary has spread his hand Over all her pleasant things; For she has seen the nations enter her sanctuary, Those whom You commanded Not to enter Your assembly.", 
                "num": 10
              }, 
              {
                "text": "All her people sigh, They seek bread; They have given their valuables for food to restore life. \"See, O LORD, and consider, For I am scorned.\"", 
                "num": 11
              }, 
              {
                "text": "\"Is it nothing to you, all you who pass by? Behold and see If there is any sorrow like my sorrow, Which has been brought on me, Which the LORD has inflicted In the day of His fierce anger.", 
                "num": 12
              }, 
              {
                "text": "\"From above He has sent fire into my bones, And it overpowered them; He has spread a net for my feet And turned me back; He has made me desolate And faint all the day.", 
                "num": 13
              }, 
              {
                "text": "\"The yoke of my transgressions was bound; They were woven together by His hands, And thrust upon my neck. He made my strength fail; The Lord delivered me into the hands of those whom I am not able to withstand.", 
                "num": 14
              }, 
              {
                "text": "\"The Lord has trampled underfoot all my mighty men in my midst; He has called an assembly against me To crush my young men; The Lord trampled as in a winepress The virgin daughter of Judah.", 
                "num": 15
              }, 
              {
                "text": "\"For these things I weep; My eye, my eye overflows with water; Because the comforter, who should restore my life, Is far from me. My children are desolate Because the enemy prevailed.\"", 
                "num": 16
              }, 
              {
                "text": "Zion spreads out her hands, But no one comforts her; The LORD has commanded concerning Jacob That those around him become his adversaries; Jerusalem has become an unclean thing among them.", 
                "num": 17
              }, 
              {
                "text": "\"The LORD is righteous, For I rebelled against His commandment. Hear now, all peoples, And behold my sorrow; My virgins and my young men Have gone into captivity.", 
                "num": 18
              }, 
              {
                "text": "\"I called for my lovers, But they deceived me; My priests and my elders Breathed their last in the city, While they sought food To restore their life.", 
                "num": 19
              }, 
              {
                "text": "\"See, O LORD, that I am in distress; My soul is troubled; My heart is overturned within me, For I have been very rebellious. Outside the sword bereaves, At home it is like death.", 
                "num": 20
              }, 
              {
                "text": "\"They have heard that I sigh, But no one comforts me. All my enemies have heard of my trouble; They are glad that You have done it. Bring on the day You have announced, That they may become like me.", 
                "num": 21
              }, 
              {
                "text": "\"Let all their wickedness come before You, And do to them as You have done to me For all my transgressions; For my sighs are many, And my heart is faint.\"", 
                "num": 22
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "How the Lord has covered the daughter of Zion With a cloud in His anger! He cast down from heaven to the earth The beauty of Israel, And did not remember His footstool In the day of His anger.", 
                "num": 1
              }, 
              {
                "text": "The Lord has swallowed up and has not pitied All the dwelling places of Jacob. He has thrown down in His wrath The strongholds of the daughter of Judah; He has brought them down to the ground; He has profaned the kingdom and its princes.", 
                "num": 2
              }, 
              {
                "text": "He has cut off in fierce anger Every horn of Israel; He has drawn back His right hand From before the enemy. He has blazed against Jacob like a flaming fire Devouring all around.", 
                "num": 3
              }, 
              {
                "text": "Standing like an enemy, He has bent His bow; With His right hand, like an adversary, He has slain all who were pleasing to His eye; On the tent of the daughter of Zion, He has poured out His fury like fire.", 
                "num": 4
              }, 
              {
                "text": "The Lord was like an enemy. He has swallowed up Israel, He has swallowed up all her palaces; He has destroyed her strongholds, And has increased mourning and lamentation In the daughter of Judah.", 
                "num": 5
              }, 
              {
                "text": "He has done violence to His tabernacle, As if it were a garden; He has destroyed His place of assembly; The LORD has caused The appointed feasts and Sabbaths to be forgotten in Zion. In His burning indignation He has spurned the king and the priest.", 
                "num": 6
              }, 
              {
                "text": "The Lord has spurned His altar, He has abandoned His sanctuary; He has given up the walls of her palaces Into the hand of the enemy. They have made a noise in the house of the LORD As on the day of a set feast.", 
                "num": 7
              }, 
              {
                "text": "The LORD has purposed to destroy The wall of the daughter of Zion. He has stretched out a line; He has not withdrawn His hand from destroying; Therefore He has caused the rampart and wall to lament; They languished together.", 
                "num": 8
              }, 
              {
                "text": "Her gates have sunk into the ground; He has destroyed and broken her bars. Her king and her princes are among the nations; The Law is no more, And her prophets find no vision from the LORD.", 
                "num": 9
              }, 
              {
                "text": "The elders of the daughter of Zion Sit on the ground and keep silence; They throw dust on their heads And gird themselves with sackcloth. The virgins of Jerusalem Bow their heads to the ground.", 
                "num": 10
              }, 
              {
                "text": "My eyes fail with tears, My heart is troubled; My bile is poured on the ground Because of the destruction of the daughter of my people, Because the children and the infants Faint in the streets of the city.", 
                "num": 11
              }, 
              {
                "text": "They say to their mothers, \"Where is grain and wine?\" As they swoon like the wounded In the streets of the city, As their life is poured out In their mothers' bosom.", 
                "num": 12
              }, 
              {
                "text": "How shall I console you? To what shall I liken you, O daughter of Jerusalem? What shall I compare with you, that I may comfort you, O virgin daughter of Zion? For your ruin is spread wide as the sea; Who can heal you?", 
                "num": 13
              }, 
              {
                "text": "Your prophets have seen for you False and deceptive visions; They have not uncovered your iniquity, To bring back your captives, But have envisioned for you false prophecies and delusions.", 
                "num": 14
              }, 
              {
                "text": "All who pass by clap their hands at you; They hiss and shake their heads At the daughter of Jerusalem: \"Is this the city that is called \"The perfection of beauty, The joy of the whole earth'?\"", 
                "num": 15
              }, 
              {
                "text": "All your enemies have opened their mouth against you; They hiss and gnash their teeth. They say, \"We have swallowed her up! Surely this is the day we have waited for; We have found it, we have seen it!|\"", 
                "num": 16
              }, 
              {
                "text": "The LORD has done what He purposed; He has fulfilled His word Which He commanded in days of old. He has thrown down and has not pitied, And He has caused an enemy to rejoice over you; He has exalted the horn of your adversaries.", 
                "num": 17
              }, 
              {
                "text": "Their heart cried out to the Lord, \"O wall of the daughter of Zion, Let tears run down like a river day and night; Give yourself no relief; Give your eyes no rest.", 
                "num": 18
              }, 
              {
                "text": "\"Arise, cry out in the night, At the beginning of the watches; Pour out your heart like water before the face of the Lord. Lift your hands toward Him For the life of your young children, Who faint from hunger at the head of every street.\"", 
                "num": 19
              }, 
              {
                "text": "\"See, O LORD, and consider! To whom have You done this? Should the women eat their offspring, The children they have cuddled? Should the priest and prophet be slain In the sanctuary of the Lord?", 
                "num": 20
              }, 
              {
                "text": "\"Young and old lie On the ground in the streets; My virgins and my young men Have fallen by the sword; You have slain them in the day of Your anger, You have slaughtered and not pitied.", 
                "num": 21
              }, 
              {
                "text": "\"You have invited as to a feast day The terrors that surround me. In the day of the LORD's anger There was no refugee or survivor. Those whom I have borne and brought up My enemies have destroyed.\"", 
                "num": 22
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "I am the man who has seen affliction by the rod of His wrath.", 
                "num": 1
              }, 
              {
                "text": "He has led me and made me walk In darkness and not in light.", 
                "num": 2
              }, 
              {
                "text": "Surely He has turned His hand against me Time and time again throughout the day.", 
                "num": 3
              }, 
              {
                "text": "He has aged my flesh and my skin, And broken my bones.", 
                "num": 4
              }, 
              {
                "text": "He has besieged me And surrounded me with bitterness and woe.", 
                "num": 5
              }, 
              {
                "text": "He has set me in dark places Like the dead of long ago.", 
                "num": 6
              }, 
              {
                "text": "He has hedged me in so that I cannot get out; He has made my chain heavy.", 
                "num": 7
              }, 
              {
                "text": "Even when I cry and shout, He shuts out my prayer.", 
                "num": 8
              }, 
              {
                "text": "He has blocked my ways with hewn stone; He has made my paths crooked.", 
                "num": 9
              }, 
              {
                "text": "He has been to me a bear lying in wait, Like a lion in ambush.", 
                "num": 10
              }, 
              {
                "text": "He has turned aside my ways and torn me in pieces; He has made me desolate.", 
                "num": 11
              }, 
              {
                "text": "He has bent His bow And set me up as a target for the arrow.", 
                "num": 12
              }, 
              {
                "text": "He has caused the arrows of His quiver To pierce my loins.", 
                "num": 13
              }, 
              {
                "text": "I have become the ridicule of all my people-- Their taunting song all the day.", 
                "num": 14
              }, 
              {
                "text": "He has filled me with bitterness, He has made me drink wormwood.", 
                "num": 15
              }, 
              {
                "text": "He has also broken my teeth with gravel, And covered me with ashes.", 
                "num": 16
              }, 
              {
                "text": "You have moved my soul far from peace; I have forgotten prosperity.", 
                "num": 17
              }, 
              {
                "text": "And I said, \"My strength and my hope Have perished from the LORD.\"", 
                "num": 18
              }, 
              {
                "text": "Remember my affliction and roaming, The wormwood and the gall.", 
                "num": 19
              }, 
              {
                "text": "My soul still remembers And sinks within me.", 
                "num": 20
              }, 
              {
                "text": "This I recall to my mind, Therefore I have hope.", 
                "num": 21
              }, 
              {
                "text": "Through the LORD's mercies we are not consumed, Because His compassions fail not.", 
                "num": 22
              }, 
              {
                "text": "They are new every morning; Great is Your faithfulness.", 
                "num": 23
              }, 
              {
                "text": "\"The LORD is my portion,\" says my soul, \"Therefore I hope in Him!\"", 
                "num": 24
              }, 
              {
                "text": "The LORD is good to those who wait for Him, To the soul who seeks Him.", 
                "num": 25
              }, 
              {
                "text": "It is good that one should hope and wait quietly For the salvation of the LORD.", 
                "num": 26
              }, 
              {
                "text": "It is good for a man to bear The yoke in his youth.", 
                "num": 27
              }, 
              {
                "text": "Let him sit alone and keep silent, Because God has laid it on him;", 
                "num": 28
              }, 
              {
                "text": "Let him put his mouth in the dust-- There may yet be hope.", 
                "num": 29
              }, 
              {
                "text": "Let him give his cheek to the one who strikes him, And be full of reproach.", 
                "num": 30
              }, 
              {
                "text": "For the Lord will not cast off forever.", 
                "num": 31
              }, 
              {
                "text": "Though He causes grief, Yet He will show compassion According to the multitude of His mercies.", 
                "num": 32
              }, 
              {
                "text": "For He does not afflict willingly, Nor grieve the children of men.", 
                "num": 33
              }, 
              {
                "text": "To crush under one's feet All the prisoners of the earth,", 
                "num": 34
              }, 
              {
                "text": "To turn aside the justice due a man Before the face of the Most High,", 
                "num": 35
              }, 
              {
                "text": "Or subvert a man in his cause-- The Lord does not approve.", 
                "num": 36
              }, 
              {
                "text": "Who is he who speaks and it comes to pass, When the Lord has not commanded it?", 
                "num": 37
              }, 
              {
                "text": "Is it not from the mouth of the Most High That woe and well-being proceed?", 
                "num": 38
              }, 
              {
                "text": "Why should a living man complain, A man for the punishment of his sins?", 
                "num": 39
              }, 
              {
                "text": "Let us search out and examine our ways, And turn back to the LORD;", 
                "num": 40
              }, 
              {
                "text": "Let us lift our hearts and hands To God in heaven.", 
                "num": 41
              }, 
              {
                "text": "We have transgressed and rebelled; You have not pardoned.", 
                "num": 42
              }, 
              {
                "text": "You have covered Yourself with anger And pursued us; You have slain and not pitied.", 
                "num": 43
              }, 
              {
                "text": "You have covered Yourself with a cloud, That prayer should not pass through.", 
                "num": 44
              }, 
              {
                "text": "You have made us an offscouring and refuse In the midst of the peoples.", 
                "num": 45
              }, 
              {
                "text": "All our enemies Have opened their mouths against us.", 
                "num": 46
              }, 
              {
                "text": "Fear and a snare have come upon us, Desolation and destruction.", 
                "num": 47
              }, 
              {
                "text": "My eyes overflow with rivers of water For the destruction of the daughter of my people.", 
                "num": 48
              }, 
              {
                "text": "My eyes flow and do not cease, Without interruption,", 
                "num": 49
              }, 
              {
                "text": "Till the LORD from heaven Looks down and sees.", 
                "num": 50
              }, 
              {
                "text": "My eyes bring suffering to my soul Because of all the daughters of my city.", 
                "num": 51
              }, 
              {
                "text": "My enemies without cause Hunted me down like a bird.", 
                "num": 52
              }, 
              {
                "text": "They silenced my life in the pit And threw stones at me.", 
                "num": 53
              }, 
              {
                "text": "The waters flowed over my head; I said, \"I am cut off!\"", 
                "num": 54
              }, 
              {
                "text": "I called on Your name, O LORD, From the lowest pit.", 
                "num": 55
              }, 
              {
                "text": "You have heard my voice: \"Do not hide Your ear From my sighing, from my cry for help.\"", 
                "num": 56
              }, 
              {
                "text": "You drew near on the day I called on You, And said, \"Do not fear!\"", 
                "num": 57
              }, 
              {
                "text": "O Lord, You have pleaded the case for my soul; You have redeemed my life.", 
                "num": 58
              }, 
              {
                "text": "O LORD, You have seen how I am wronged; Judge my case.", 
                "num": 59
              }, 
              {
                "text": "You have seen all their vengeance, All their schemes against me.", 
                "num": 60
              }, 
              {
                "text": "You have heard their reproach, O LORD, All their schemes against me,", 
                "num": 61
              }, 
              {
                "text": "The lips of my enemies And their whispering against me all the day.", 
                "num": 62
              }, 
              {
                "text": "Look at their sitting down and their rising up; I am their taunting song.", 
                "num": 63
              }, 
              {
                "text": "Repay them, O LORD, According to the work of their hands.", 
                "num": 64
              }, 
              {
                "text": "Give them a veiled heart; Your curse be upon them!", 
                "num": 65
              }, 
              {
                "text": "In Your anger, Pursue and destroy them From under the heavens of the LORD.", 
                "num": 66
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "How the gold has become dim! How changed the fine gold! The stones of the sanctuary are scattered At the head of every street.", 
                "num": 1
              }, 
              {
                "text": "The precious sons of Zion, Valuable as fine gold, How they are regarded as clay pots, The work of the hands of the potter!", 
                "num": 2
              }, 
              {
                "text": "Even the jackals present their breasts To nurse their young; But the daughter of my people is cruel, Like ostriches in the wilderness.", 
                "num": 3
              }, 
              {
                "text": "The tongue of the infant clings To the roof of its mouth for thirst; The young children ask for bread, But no one breaks it for them.", 
                "num": 4
              }, 
              {
                "text": "Those who ate delicacies Are desolate in the streets; Those who were brought up in scarlet Embrace ash heaps.", 
                "num": 5
              }, 
              {
                "text": "The punishment of the iniquity of the daughter of my people Is greater than the punishment of the sin of Sodom, Which was overthrown in a moment, With no hand to help her!", 
                "num": 6
              }, 
              {
                "text": "Her Nazirites were brighter than snow And whiter than milk; They were more ruddy in body than rubies, Like sapphire in their appearance.", 
                "num": 7
              }, 
              {
                "text": "Now their appearance is blacker than soot; They go unrecognized in the streets; Their skin clings to their bones, It has become as dry as wood.", 
                "num": 8
              }, 
              {
                "text": "Those slain by the sword are better off Than those who die of hunger; For these pine away, Stricken for lack of the fruits of the field.", 
                "num": 9
              }, 
              {
                "text": "The hands of the compassionate women Have cooked their own children; They became food for them In the destruction of the daughter of my people.", 
                "num": 10
              }, 
              {
                "text": "The LORD has fulfilled His fury, He has poured out His fierce anger. He kindled a fire in Zion, And it has devoured its foundations.", 
                "num": 11
              }, 
              {
                "text": "The kings of the earth, And all inhabitants of the world, Would not have believed That the adversary and the enemy Could enter the gates of Jerusalem--", 
                "num": 12
              }, 
              {
                "text": "Because of the sins of her prophets And the iniquities of her priests, Who shed in her midst The blood of the just.", 
                "num": 13
              }, 
              {
                "text": "They wandered blind in the streets; They have defiled themselves with blood, So that no one would touch their garments.", 
                "num": 14
              }, 
              {
                "text": "They cried out to them, \"Go away, unclean! Go away, go away, Do not touch us!\" When they fled and wandered, Those among the nations said, \"They shall no longer dwell here.\"", 
                "num": 15
              }, 
              {
                "text": "The face of the LORD scattered them; He no longer regards them. The people do not respect the priests Nor show favor to the elders.", 
                "num": 16
              }, 
              {
                "text": "Still our eyes failed us, Watching vainly for our help; In our watching we watched For a nation that could not save us.", 
                "num": 17
              }, 
              {
                "text": "They tracked our steps So that we could not walk in our streets. Our end was near; Our days were over, For our end had come.", 
                "num": 18
              }, 
              {
                "text": "Our pursuers were swifter Than the eagles of the heavens. They pursued us on the mountains And lay in wait for us in the wilderness.", 
                "num": 19
              }, 
              {
                "text": "The breath of our nostrils, the anointed of the LORD, Was caught in their pits, Of whom we said, \"Under his shadow We shall live among the nations.\"", 
                "num": 20
              }, 
              {
                "text": "Rejoice and be glad, O daughter of Edom, You who dwell in the land of Uz! The cup shall also pass over to you And you shall become drunk and make yourself naked.", 
                "num": 21
              }, 
              {
                "text": "The punishment of your iniquity is accomplished, O daughter of Zion; He will no longer send you into captivity. He will punish your iniquity, O daughter of Edom; He will uncover your sins!", 
                "num": 22
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Remember, O LORD, what has come upon us; Look, and behold our reproach!", 
                "num": 1
              }, 
              {
                "text": "Our inheritance has been turned over to aliens, And our houses to foreigners.", 
                "num": 2
              }, 
              {
                "text": "We have become orphans and waifs, Our mothers are like widows.", 
                "num": 3
              }, 
              {
                "text": "We pay for the water we drink, And our wood comes at a price.", 
                "num": 4
              }, 
              {
                "text": "They pursue at our heels; We labor and have no rest.", 
                "num": 5
              }, 
              {
                "text": "We have given our hand to the Egyptians And the Assyrians, to be satisfied with bread.", 
                "num": 6
              }, 
              {
                "text": "Our fathers sinned and are no more, But we bear their iniquities.", 
                "num": 7
              }, 
              {
                "text": "Servants rule over us; There is none to deliver us from their hand.", 
                "num": 8
              }, 
              {
                "text": "We get our bread at the risk of our lives, Because of the sword in the wilderness.", 
                "num": 9
              }, 
              {
                "text": "Our skin is hot as an oven, Because of the fever of famine.", 
                "num": 10
              }, 
              {
                "text": "They ravished the women in Zion, The maidens in the cities of Judah.", 
                "num": 11
              }, 
              {
                "text": "Princes were hung up by their hands, And elders were not respected.", 
                "num": 12
              }, 
              {
                "text": "Young men ground at the millstones; Boys staggered under loads of wood.", 
                "num": 13
              }, 
              {
                "text": "The elders have ceased gathering at the gate, And the young men from their music.", 
                "num": 14
              }, 
              {
                "text": "The joy of our heart has ceased; Our dance has turned into mourning.", 
                "num": 15
              }, 
              {
                "text": "The crown has fallen from our head. Woe to us, for we have sinned!", 
                "num": 16
              }, 
              {
                "text": "Because of this our heart is faint; Because of these things our eyes grow dim;", 
                "num": 17
              }, 
              {
                "text": "Because of Mount Zion which is desolate, With foxes walking about on it.", 
                "num": 18
              }, 
              {
                "text": "You, O LORD, remain forever; Your throne from generation to generation.", 
                "num": 19
              }, 
              {
                "text": "Why do You forget us forever, And forsake us for so long a time?", 
                "num": 20
              }, 
              {
                "text": "Turn us back to You, O LORD, and we will be restored; Renew our days as of old,", 
                "num": 21
              }, 
              {
                "text": "Unless You have utterly rejected us, And are very angry with us!", 
                "num": 22
              }
            ], 
            "num": 5
          }
        ]
      }, 
      {
        "name": "Ezekiel", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now it came to pass in the thirtieth year, in the fourth month, on the fifth day of the month, as I was among the captives by the River Chebar, that the heavens were opened and I saw visions of God.", 
                "num": 1
              }, 
              {
                "text": "On the fifth day of the month, which was in the fifth year of King Jehoiachin's captivity,", 
                "num": 2
              }, 
              {
                "text": "the word of the LORD came expressly to Ezekiel the priest, the son of Buzi, in the land of the Chaldeans by the River Chebar; and the hand of the LORD was upon him there.", 
                "num": 3
              }, 
              {
                "text": "Then I looked, and behold, a whirlwind was coming out of the north, a great cloud with raging fire engulfing itself; and brightness was all around it and radiating out of its midst like the color of amber, out of the midst of the fire.", 
                "num": 4
              }, 
              {
                "text": "Also from within it came the likeness of four living creatures. And this was their appearance: they had the likeness of a man.", 
                "num": 5
              }, 
              {
                "text": "Each one had four faces, and each one had four wings.", 
                "num": 6
              }, 
              {
                "text": "Their legs were straight, and the soles of their feet were like the soles of calves' feet. They sparkled like the color of burnished bronze.", 
                "num": 7
              }, 
              {
                "text": "The hands of a man were under their wings on their four sides; and each of the four had faces and wings.", 
                "num": 8
              }, 
              {
                "text": "Their wings touched one another. The creatures did not turn when they went, but each one went straight forward.", 
                "num": 9
              }, 
              {
                "text": "As for the likeness of their faces, each had the face of a man; each of the four had the face of a lion on the right side, each of the four had the face of an ox on the left side, and each of the four had the face of an eagle.", 
                "num": 10
              }, 
              {
                "text": "Thus were their faces. Their wings stretched upward; two wings of each one touched one another, and two covered their bodies.", 
                "num": 11
              }, 
              {
                "text": "And each one went straight forward; they went wherever the spirit wanted to go, and they did not turn when they went.", 
                "num": 12
              }, 
              {
                "text": "As for the likeness of the living creatures, their appearance was like burning coals of fire, like the appearance of torches going back and forth among the living creatures. The fire was bright, and out of the fire went lightning.", 
                "num": 13
              }, 
              {
                "text": "And the living creatures ran back and forth, in appearance like a flash of lightning.", 
                "num": 14
              }, 
              {
                "text": "Now as I looked at the living creatures, behold, a wheel was on the earth beside each living creature with its four faces.", 
                "num": 15
              }, 
              {
                "text": "The appearance of the wheels and their workings was like the color of beryl, and all four had the same likeness. The appearance of their workings was, as it were, a wheel in the middle of a wheel.", 
                "num": 16
              }, 
              {
                "text": "When they moved, they went toward any one of four directions; they did not turn aside when they went.", 
                "num": 17
              }, 
              {
                "text": "As for their rims, they were so high they were awesome; and their rims were full of eyes, all around the four of them.", 
                "num": 18
              }, 
              {
                "text": "When the living creatures went, the wheels went beside them; and when the living creatures were lifted up from the earth, the wheels were lifted up.", 
                "num": 19
              }, 
              {
                "text": "Wherever the spirit wanted to go, they went, because there the spirit went; and the wheels were lifted together with them, for the spirit of the living creatures was in the wheels.", 
                "num": 20
              }, 
              {
                "text": "When those went, these went; when those stood, these stood; and when those were lifted up from the earth, the wheels were lifted up together with them, for the spirit of the living creatures was in the wheels.", 
                "num": 21
              }, 
              {
                "text": "The likeness of the firmament above the heads of the living creatures was like the color of an awesome crystal, stretched out over their heads.", 
                "num": 22
              }, 
              {
                "text": "And under the firmament their wings spread out straight, one toward another. Each one had two which covered one side, and each one had two which covered the other side of the body.", 
                "num": 23
              }, 
              {
                "text": "When they went, I heard the noise of their wings, like the noise of many waters, like the voice of the Almighty, a tumult like the noise of an army; and when they stood still, they let down their wings.", 
                "num": 24
              }, 
              {
                "text": "A voice came from above the firmament that was over their heads; whenever they stood, they let down their wings.", 
                "num": 25
              }, 
              {
                "text": "And above the firmament over their heads was the likeness of a throne, in appearance like a sapphire stone; on the likeness of the throne was a likeness with the appearance of a man high above it.", 
                "num": 26
              }, 
              {
                "text": "Also from the appearance of His waist and upward I saw, as it were, the color of amber with the appearance of fire all around within it; and from the appearance of His waist and downward I saw, as it were, the appearance of fire with brightness all around.", 
                "num": 27
              }, 
              {
                "text": "Like the appearance of a rainbow in a cloud on a rainy day, so was the appearance of the brightness all around it. This was the appearance of the likeness of the glory of the LORD. So when I saw it, I fell on my face, and I heard a voice of One speaking.", 
                "num": 28
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And He said to me, \"Son of man, stand on your feet, and I will speak to you.\"", 
                "num": 1
              }, 
              {
                "text": "Then the Spirit entered me when He spoke to me, and set me on my feet; and I heard Him who spoke to me.", 
                "num": 2
              }, 
              {
                "text": "And He said to me: \"Son of man, I am sending you to the children of Israel, to a rebellious nation that has rebelled against Me; they and their fathers have transgressed against Me to this very day.", 
                "num": 3
              }, 
              {
                "text": "For they are impudent and stubborn children. I am sending you to them, and you shall say to them, \"Thus says the Lord GOD.'", 
                "num": 4
              }, 
              {
                "text": "As for them, whether they hear or whether they refuse--for they are a rebellious house--yet they will know that a prophet has been among them.", 
                "num": 5
              }, 
              {
                "text": "\"And you, son of man, do not be afraid of them nor be afraid of their words, though briers and thorns are with you and you dwell among scorpions; do not be afraid of their words or dismayed by their looks, though they are a rebellious house.", 
                "num": 6
              }, 
              {
                "text": "You shall speak My words to them, whether they hear or whether they refuse, for they are rebellious.", 
                "num": 7
              }, 
              {
                "text": "But you, son of man, hear what I say to you. Do not be rebellious like that rebellious house; open your mouth and eat what I give you.\"", 
                "num": 8
              }, 
              {
                "text": "Now when I looked, there was a hand stretched out to me; and behold, a scroll of a book was in it.", 
                "num": 9
              }, 
              {
                "text": "Then He spread it before me; and there was writing on the inside and on the outside, and written on it were lamentations and mourning and woe.", 
                "num": 10
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Moreover He said to me, \"Son of man, eat what you find; eat this scroll, and go, speak to the house of Israel.\"", 
                "num": 1
              }, 
              {
                "text": "So I opened my mouth, and He caused me to eat that scroll.", 
                "num": 2
              }, 
              {
                "text": "And He said to me, \"Son of man, feed your belly, and fill your stomach with this scroll that I give you.\" So I ate, and it was in my mouth like honey in sweetness.", 
                "num": 3
              }, 
              {
                "text": "Then He said to me: \"Son of man, go to the house of Israel and speak with My words to them.", 
                "num": 4
              }, 
              {
                "text": "For you are not sent to a people of unfamiliar speech and of hard language, but to the house of Israel,", 
                "num": 5
              }, 
              {
                "text": "not to many people of unfamiliar speech and of hard language, whose words you cannot understand. Surely, had I sent you to them, they would have listened to you.", 
                "num": 6
              }, 
              {
                "text": "But the house of Israel will not listen to you, because they will not listen to Me; for all the house of Israel are impudent and hard-hearted.", 
                "num": 7
              }, 
              {
                "text": "Behold, I have made your face strong against their faces, and your forehead strong against their foreheads.", 
                "num": 8
              }, 
              {
                "text": "Like adamant stone, harder than flint, I have made your forehead; do not be afraid of them, nor be dismayed at their looks, though they are a rebellious house.\"", 
                "num": 9
              }, 
              {
                "text": "Moreover He said to me: \"Son of man, receive into your heart all My words that I speak to you, and hear with your ears.", 
                "num": 10
              }, 
              {
                "text": "And go, get to the captives, to the children of your people, and speak to them and tell them, \"Thus says the Lord GOD,' whether they hear, or whether they refuse.\"", 
                "num": 11
              }, 
              {
                "text": "Then the Spirit lifted me up, and I heard behind me a great thunderous voice: \"Blessed is the glory of the LORD from His place!\"", 
                "num": 12
              }, 
              {
                "text": "I also heard the noise of the wings of the living creatures that touched one another, and the noise of the wheels beside them, and a great thunderous noise.", 
                "num": 13
              }, 
              {
                "text": "So the Spirit lifted me up and took me away, and I went in bitterness, in the heat of my spirit; but the hand of the LORD was strong upon me.", 
                "num": 14
              }, 
              {
                "text": "Then I came to the captives at Tel Abib, who dwelt by the River Chebar; and I sat where they sat, and remained there astonished among them seven days.", 
                "num": 15
              }, 
              {
                "text": "Now it came to pass at the end of seven days that the word of the LORD came to me, saying,", 
                "num": 16
              }, 
              {
                "text": "\"Son of man, I have made you a watchman for the house of Israel; therefore hear a word from My mouth, and give them warning from Me:", 
                "num": 17
              }, 
              {
                "text": "When I say to the wicked, \"You shall surely die,' and you give him no warning, nor speak to warn the wicked from his wicked way, to save his life, that same wicked man shall die in his iniquity; but his blood I will require at your hand.", 
                "num": 18
              }, 
              {
                "text": "Yet, if you warn the wicked, and he does not turn from his wickedness, nor from his wicked way, he shall die in his iniquity; but you have delivered your soul.", 
                "num": 19
              }, 
              {
                "text": "\"Again, when a righteous man turns from his righteousness and commits iniquity, and I lay a stumbling block before him, he shall die; because you did not give him warning, he shall die in his sin, and his righteousness which he has done shall not be remembered; but his blood I will require at your hand.", 
                "num": 20
              }, 
              {
                "text": "Nevertheless if you warn the righteous man that the righteous should not sin, and he does not sin, he shall surely live because he took warning; also you will have delivered your soul.\"", 
                "num": 21
              }, 
              {
                "text": "Then the hand of the LORD was upon me there, and He said to me, \"Arise, go out into the plain, and there I shall talk with you.\"", 
                "num": 22
              }, 
              {
                "text": "So I arose and went out into the plain, and behold, the glory of the LORD stood there, like the glory which I saw by the River Chebar; and I fell on my face.", 
                "num": 23
              }, 
              {
                "text": "Then the Spirit entered me and set me on my feet, and spoke with me and said to me: \"Go, shut yourself inside your house.", 
                "num": 24
              }, 
              {
                "text": "And you, O son of man, surely they will put ropes on you and bind you with them, so that you cannot go out among them.", 
                "num": 25
              }, 
              {
                "text": "I will make your tongue cling to the roof of your mouth, so that you shall be mute and not be one to rebuke them, for they are a rebellious house.", 
                "num": 26
              }, 
              {
                "text": "But when I speak with you, I will open your mouth, and you shall say to them, \"Thus says the Lord GOD.' He who hears, let him hear; and he who refuses, let him refuse; for they are a rebellious house.", 
                "num": 27
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "\"You also, son of man, take a clay tablet and lay it before you, and portray on it a city, Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Lay siege against it, build a siege wall against it, and heap up a mound against it; set camps against it also, and place battering rams against it all around.", 
                "num": 2
              }, 
              {
                "text": "Moreover take for yourself an iron plate, and set it as an iron wall between you and the city. Set your face against it, and it shall be besieged, and you shall lay siege against it. This will be a sign to the house of Israel.", 
                "num": 3
              }, 
              {
                "text": "\"Lie also on your left side, and lay the iniquity of the house of Israel upon it. According to the number of the days that you lie on it, you shall bear their iniquity.", 
                "num": 4
              }, 
              {
                "text": "For I have laid on you the years of their iniquity, according to the number of the days, three hundred and ninety days; so you shall bear the iniquity of the house of Israel.", 
                "num": 5
              }, 
              {
                "text": "And when you have completed them, lie again on your right side; then you shall bear the iniquity of the house of Judah forty days. I have laid on you a day for each year.", 
                "num": 6
              }, 
              {
                "text": "\"Therefore you shall set your face toward the siege of Jerusalem; your arm shall be uncovered, and you shall prophesy against it.", 
                "num": 7
              }, 
              {
                "text": "And surely I will restrain you so that you cannot turn from one side to another till you have ended the days of your siege.", 
                "num": 8
              }, 
              {
                "text": "\"Also take for yourself wheat, barley, beans, lentils, millet, and spelt; put them into one vessel, and make bread of them for yourself. During the number of days that you lie on your side, three hundred and ninety days, you shall eat it.", 
                "num": 9
              }, 
              {
                "text": "And your food which you eat shall be by weight, twenty shekels a day; from time to time you shall eat it.", 
                "num": 10
              }, 
              {
                "text": "You shall also drink water by measure, one-sixth of a hin; from time to time you shall drink.", 
                "num": 11
              }, 
              {
                "text": "And you shall eat it as barley cakes; and bake it using fuel of human waste in their sight.\"", 
                "num": 12
              }, 
              {
                "text": "Then the LORD said, \"So shall the children of Israel eat their defiled bread among the Gentiles, where I will drive them.\"", 
                "num": 13
              }, 
              {
                "text": "So I said, \"Ah, Lord GOD! Indeed I have never defiled myself from my youth till now; I have never eaten what died of itself or was torn by beasts, nor has abominable flesh ever come into my mouth.\"", 
                "num": 14
              }, 
              {
                "text": "Then He said to me, \"See, I am giving you cow dung instead of human waste, and you shall prepare your bread over it.\"", 
                "num": 15
              }, 
              {
                "text": "Moreover He said to me, \"Son of man, surely I will cut off the supply of bread in Jerusalem; they shall eat bread by weight and with anxiety, and shall drink water by measure and with dread,", 
                "num": 16
              }, 
              {
                "text": "that they may lack bread and water, and be dismayed with one another, and waste away because of their iniquity.", 
                "num": 17
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "\"And you, son of man, take a sharp sword, take it as a barber's razor, and pass it over your head and your beard; then take scales to weigh and divide the hair.", 
                "num": 1
              }, 
              {
                "text": "You shall burn with fire one-third in the midst of the city, when the days of the siege are finished; then you shall take one-third and strike around it with the sword, and one-third you shall scatter in the wind: I will draw out a sword after them.", 
                "num": 2
              }, 
              {
                "text": "You shall also take a small number of them and bind them in the edge of your garment.", 
                "num": 3
              }, 
              {
                "text": "Then take some of them again and throw them into the midst of the fire, and burn them in the fire. From there a fire will go out into all the house of Israel.", 
                "num": 4
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"This is Jerusalem; I have set her in the midst of the nations and the countries all around her.", 
                "num": 5
              }, 
              {
                "text": "She has rebelled against My judgments by doing wickedness more than the nations, and against My statutes more than the countries that are all around her; for they have refused My judgments, and they have not walked in My statutes.'", 
                "num": 6
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Because you have multiplied disobedience more than the nations that are all around you, have not walked in My statutes nor kept My judgments, nor even done according to the judgments of the nations that are all around you'--", 
                "num": 7
              }, 
              {
                "text": "therefore thus says the Lord GOD: \"Indeed I, even I, am against you and will execute judgments in your midst in the sight of the nations.", 
                "num": 8
              }, 
              {
                "text": "And I will do among you what I have never done, and the like of which I will never do again, because of all your abominations.", 
                "num": 9
              }, 
              {
                "text": "Therefore fathers shall eat their sons in your midst, and sons shall eat their fathers; and I will execute judgments among you, and all of you who remain I will scatter to all the winds.", 
                "num": 10
              }, 
              {
                "text": "\"Therefore, as I live,' says the Lord GOD, \"surely, because you have defiled My sanctuary with all your detestable things and with all your abominations, therefore I will also diminish you; My eye will not spare, nor will I have any pity.", 
                "num": 11
              }, 
              {
                "text": "One-third of you shall die of the pestilence, and be consumed with famine in your midst; and one-third shall fall by the sword all around you; and I will scatter another third to all the winds, and I will draw out a sword after them.", 
                "num": 12
              }, 
              {
                "text": "\"Thus shall My anger be spent, and I will cause My fury to rest upon them, and I will be avenged; and they shall know that I, the LORD, have spoken it in My zeal, when I have spent My fury upon them.", 
                "num": 13
              }, 
              {
                "text": "Moreover I will make you a waste and a reproach among the nations that are all around you, in the sight of all who pass by.", 
                "num": 14
              }, 
              {
                "text": "\"So it shall be a reproach, a taunt, a lesson, and an astonishment to the nations that are all around you, when I execute judgments among you in anger and in fury and in furious rebukes. I, the LORD, have spoken.", 
                "num": 15
              }, 
              {
                "text": "When I send against them the terrible arrows of famine which shall be for destruction, which I will send to destroy you, I will increase the famine upon you and cut off your supply of bread.", 
                "num": 16
              }, 
              {
                "text": "So I will send against you famine and wild beasts, and they will bereave you. Pestilence and blood shall pass through you, and I will bring the sword against you. I, the LORD, have spoken.\"'", 
                "num": 17
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now the word of the LORD came to me, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face toward the mountains of Israel, and prophesy against them,", 
                "num": 2
              }, 
              {
                "text": "and say, \"O mountains of Israel, hear the word of the Lord GOD! Thus says the Lord GOD to the mountains, to the hills, to the ravines, and to the valleys: \"Indeed I, even I, will bring a sword against you, and I will destroy your high places.", 
                "num": 3
              }, 
              {
                "text": "Then your altars shall be desolate, your incense altars shall be broken, and I will cast down your slain men before your idols.", 
                "num": 4
              }, 
              {
                "text": "And I will lay the corpses of the children of Israel before their idols, and I will scatter your bones all around your altars.", 
                "num": 5
              }, 
              {
                "text": "In all your dwelling places the cities shall be laid waste, and the high places shall be desolate, so that your altars may be laid waste and made desolate, your idols may be broken and made to cease, your incense altars may be cut down, and your works may be abolished.", 
                "num": 6
              }, 
              {
                "text": "The slain shall fall in your midst, and you shall know that I am the LORD.", 
                "num": 7
              }, 
              {
                "text": "\"Yet I will leave a remnant, so that you may have some who escape the sword among the nations, when you are scattered through the countries.", 
                "num": 8
              }, 
              {
                "text": "Then those of you who escape will remember Me among the nations where they are carried captive, because I was crushed by their adulterous heart which has departed from Me, and by their eyes which play the harlot after their idols; they will loathe themselves for the evils which they committed in all their abominations.", 
                "num": 9
              }, 
              {
                "text": "And they shall know that I am the LORD; I have not said in vain that I would bring this calamity upon them.\"", 
                "num": 10
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Pound your fists and stamp your feet, and say, \"Alas, for all the evil abominations of the house of Israel! For they shall fall by the sword, by famine, and by pestilence.", 
                "num": 11
              }, 
              {
                "text": "He who is far off shall die by the pestilence, he who is near shall fall by the sword, and he who remains and is besieged shall die by the famine. Thus will I spend My fury upon them.", 
                "num": 12
              }, 
              {
                "text": "Then you shall know that I am the LORD, when their slain are among their idols all around their altars, on every high hill, on all the mountaintops, under every green tree, and under every thick oak, wherever they offered sweet incense to all their idols.", 
                "num": 13
              }, 
              {
                "text": "So I will stretch out My hand against them and make the land desolate, yes, more desolate than the wilderness toward Diblah, in all their dwelling places. Then they shall know that I am the LORD.\"\"\"", 
                "num": 14
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"And you, son of man, thus says the Lord GOD to the land of Israel: \"An end! The end has come upon the four corners of the land.", 
                "num": 2
              }, 
              {
                "text": "Now the end has come upon you, And I will send My anger against you; I will judge you according to your ways, And I will repay you for all your abominations.", 
                "num": 3
              }, 
              {
                "text": "My eye will not spare you, Nor will I have pity; But I will repay your ways, And your abominations will be in your midst; Then you shall know that I am the LORD!'", 
                "num": 4
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"A disaster, a singular disaster; Behold, it has come!", 
                "num": 5
              }, 
              {
                "text": "An end has come, The end has come; It has dawned for you; Behold, it has come!", 
                "num": 6
              }, 
              {
                "text": "Doom has come to you, you who dwell in the land; The time has come, A day of trouble is near, And not of rejoicing in the mountains.", 
                "num": 7
              }, 
              {
                "text": "Now upon you I will soon pour out My fury, And spend My anger upon you; I will judge you according to your ways, And I will repay you for all your abominations.", 
                "num": 8
              }, 
              {
                "text": "\"My eye will not spare, Nor will I have pity; I will repay you according to your ways, And your abominations will be in your midst. Then you shall know that I am the LORD who strikes.", 
                "num": 9
              }, 
              {
                "text": "\"Behold, the day! Behold, it has come! Doom has gone out; The rod has blossomed, Pride has budded.", 
                "num": 10
              }, 
              {
                "text": "Violence has risen up into a rod of wickedness; None of them shall remain, None of their multitude, None of them; Nor shall there be wailing for them.", 
                "num": 11
              }, 
              {
                "text": "The time has come, The day draws near. \"Let not the buyer rejoice, Nor the seller mourn, For wrath is on their whole multitude.", 
                "num": 12
              }, 
              {
                "text": "For the seller shall not return to what has been sold, Though he may still be alive; For the vision concerns the whole multitude, And it shall not turn back; No one will strengthen himself Who lives in iniquity.", 
                "num": 13
              }, 
              {
                "text": "\"They have blown the trumpet and made everyone ready, But no one goes to battle; For My wrath is on all their multitude.", 
                "num": 14
              }, 
              {
                "text": "The sword is outside, And the pestilence and famine within. Whoever is in the field Will die by the sword; And whoever is in the city, Famine and pestilence will devour him.", 
                "num": 15
              }, 
              {
                "text": "\"Those who survive will escape and be on the mountains Like doves of the valleys, All of them mourning, Each for his iniquity.", 
                "num": 16
              }, 
              {
                "text": "Every hand will be feeble, And every knee will be as weak as water.", 
                "num": 17
              }, 
              {
                "text": "They will also be girded with sackcloth; Horror will cover them; Shame will be on every face, Baldness on all their heads.", 
                "num": 18
              }, 
              {
                "text": "\"They will throw their silver into the streets, And their gold will be like refuse; Their silver and their gold will not be able to deliver them In the day of the wrath of the LORD; They will not satisfy their souls, Nor fill their stomachs, Because it became their stumbling block of iniquity.", 
                "num": 19
              }, 
              {
                "text": "\"As for the beauty of his ornaments, He set it in majesty; But they made from it The images of their abominations-- Their detestable things; Therefore I have made it Like refuse to them.", 
                "num": 20
              }, 
              {
                "text": "I will give it as plunder Into the hands of strangers, And to the wicked of the earth as spoil; And they shall defile it.", 
                "num": 21
              }, 
              {
                "text": "I will turn My face from them, And they will defile My secret place; For robbers shall enter it and defile it.", 
                "num": 22
              }, 
              {
                "text": "\"Make a chain, For the land is filled with crimes of blood, And the city is full of violence.", 
                "num": 23
              }, 
              {
                "text": "Therefore I will bring the worst of the Gentiles, And they will possess their houses; I will cause the pomp of the strong to cease, And their holy places shall be defiled.", 
                "num": 24
              }, 
              {
                "text": "Destruction comes; They will seek peace, but there shall be none.", 
                "num": 25
              }, 
              {
                "text": "Disaster will come upon disaster, And rumor will be upon rumor. Then they will seek a vision from a prophet; But the law will perish from the priest, And counsel from the elders.", 
                "num": 26
              }, 
              {
                "text": "\"The king will mourn, The prince will be clothed with desolation, And the hands of the common people will tremble. I will do to them according to their way, And according to what they deserve I will judge them; Then they shall know that I am the LORD!\"'", 
                "num": 27
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the sixth year, in the sixth month, on the fifth day of the month, as I sat in my house with the elders of Judah sitting before me, that the hand of the Lord GOD fell upon me there.", 
                "num": 1
              }, 
              {
                "text": "Then I looked, and there was a likeness, like the appearance of fire--from the appearance of His waist and downward, fire; and from His waist and upward, like the appearance of brightness, like the color of amber.", 
                "num": 2
              }, 
              {
                "text": "He stretched out the form of a hand, and took me by a lock of my hair; and the Spirit lifted me up between earth and heaven, and brought me in visions of God to Jerusalem, to the door of the north gate of the inner court, where the seat of the image of jealousy was, which provokes to jealousy.", 
                "num": 3
              }, 
              {
                "text": "And behold, the glory of the God of Israel was there, like the vision that I saw in the plain.", 
                "num": 4
              }, 
              {
                "text": "Then He said to me, \"Son of man, lift your eyes now toward the north.\" So I lifted my eyes toward the north, and there, north of the altar gate, was this image of jealousy in the entrance.", 
                "num": 5
              }, 
              {
                "text": "Furthermore He said to me, \"Son of man, do you see what they are doing, the great abominations that the house of Israel commits here, to make Me go far away from My sanctuary? Now turn again, you will see greater abominations.\"", 
                "num": 6
              }, 
              {
                "text": "So He brought me to the door of the court; and when I looked, there was a hole in the wall.", 
                "num": 7
              }, 
              {
                "text": "Then He said to me, \"Son of man, dig into the wall\"; and when I dug into the wall, there was a door.", 
                "num": 8
              }, 
              {
                "text": "And He said to me, \"Go in, and see the wicked abominations which they are doing there.\"", 
                "num": 9
              }, 
              {
                "text": "So I went in and saw, and there--every sort of creeping thing, abominable beasts, and all the idols of the house of Israel, portrayed all around on the walls.", 
                "num": 10
              }, 
              {
                "text": "And there stood before them seventy men of the elders of the house of Israel, and in their midst stood Jaazaniah the son of Shaphan. Each man had a censer in his hand, and a thick cloud of incense went up.", 
                "num": 11
              }, 
              {
                "text": "Then He said to me, \"Son of man, have you seen what the elders of the house of Israel do in the dark, every man in the room of his idols? For they say, \"The LORD does not see us, the LORD has forsaken the land.\"'", 
                "num": 12
              }, 
              {
                "text": "And He said to me, \"Turn again, and you will see greater abominations that they are doing.\"", 
                "num": 13
              }, 
              {
                "text": "So He brought me to the door of the north gate of the LORD's house; and to my dismay, women were sitting there weeping for Tammuz.", 
                "num": 14
              }, 
              {
                "text": "Then He said to me, \"Have you seen this, O son of man? Turn again, you will see greater abominations than these.\"", 
                "num": 15
              }, 
              {
                "text": "So He brought me into the inner court of the LORD's house; and there, at the door of the temple of the LORD, between the porch and the altar, were about twenty-five men with their backs toward the temple of the LORD and their faces toward the east, and they were worshiping the sun toward the east.", 
                "num": 16
              }, 
              {
                "text": "And He said to me, \"Have you seen this, O son of man? Is it a trivial thing to the house of Judah to commit the abominations which they commit here? For they have filled the land with violence; then they have returned to provoke Me to anger. Indeed they put the branch to their nose.", 
                "num": 17
              }, 
              {
                "text": "Therefore I also will act in fury. My eye will not spare nor will I have pity; and though they cry in My ears with a loud voice, I will not hear them.\"", 
                "num": 18
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then He called out in my hearing with a loud voice, saying, \"Let those who have charge over the city draw near, each with a deadly weapon in his hand.\"", 
                "num": 1
              }, 
              {
                "text": "And suddenly six men came from the direction of the upper gate, which faces north, each with his battle-ax in his hand. One man among them was clothed with linen and had a writer's inkhorn at his side. They went in and stood beside the bronze altar.", 
                "num": 2
              }, 
              {
                "text": "Now the glory of the God of Israel had gone up from the cherub, where it had been, to the threshold of the temple. And He called to the man clothed with linen, who had the writer's inkhorn at his side;", 
                "num": 3
              }, 
              {
                "text": "and the LORD said to him, \"Go through the midst of the city, through the midst of Jerusalem, and put a mark on the foreheads of the men who sigh and cry over all the abominations that are done within it.\"", 
                "num": 4
              }, 
              {
                "text": "To the others He said in my hearing, \"Go after him through the city and kill; do not let your eye spare, nor have any pity.", 
                "num": 5
              }, 
              {
                "text": "Utterly slay old and young men, maidens and little children and women; but do not come near anyone on whom is the mark; and begin at My sanctuary.\" So they began with the elders who were before the temple.", 
                "num": 6
              }, 
              {
                "text": "Then He said to them, \"Defile the temple, and fill the courts with the slain. Go out!\" And they went out and killed in the city.", 
                "num": 7
              }, 
              {
                "text": "So it was, that while they were killing them, I was left alone; and I fell on my face and cried out, and said, \"Ah, Lord GOD! Will You destroy all the remnant of Israel in pouring out Your fury on Jerusalem?\"", 
                "num": 8
              }, 
              {
                "text": "Then He said to me, \"The iniquity of the house of Israel and Judah is exceedingly great, and the land is full of bloodshed, and the city full of perversity; for they say, \"The LORD has forsaken the land, and the LORD does not see!'", 
                "num": 9
              }, 
              {
                "text": "And as for Me also, My eye will neither spare, nor will I have pity, but I will recompense their deeds on their own head.\"", 
                "num": 10
              }, 
              {
                "text": "Just then, the man clothed with linen, who had the inkhorn at his side, reported back and said, \"I have done as You commanded me.\"", 
                "num": 11
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "And I looked, and there in the firmament that was above the head of the cherubim, there appeared something like a sapphire stone, having the appearance of the likeness of a throne.", 
                "num": 1
              }, 
              {
                "text": "Then He spoke to the man clothed with linen, and said, \"Go in among the wheels, under the cherub, fill your hands with coals of fire from among the cherubim, and scatter them over the city.\" And he went in as I watched.", 
                "num": 2
              }, 
              {
                "text": "Now the cherubim were standing on the south side of the temple when the man went in, and the cloud filled the inner court.", 
                "num": 3
              }, 
              {
                "text": "Then the glory of the LORD went up from the cherub, and paused over the threshold of the temple; and the house was filled with the cloud, and the court was full of the brightness of the LORD's glory.", 
                "num": 4
              }, 
              {
                "text": "And the sound of the wings of the cherubim was heard even in the outer court, like the voice of Almighty God when He speaks.", 
                "num": 5
              }, 
              {
                "text": "Then it happened, when He commanded the man clothed in linen, saying, \"Take fire from among the wheels, from among the cherubim,\" that he went in and stood beside the wheels.", 
                "num": 6
              }, 
              {
                "text": "And the cherub stretched out his hand from among the cherubim to the fire that was among the cherubim, and took some of it and put it into the hands of the man clothed with linen, who took it and went out.", 
                "num": 7
              }, 
              {
                "text": "The cherubim appeared to have the form of a man's hand under their wings.", 
                "num": 8
              }, 
              {
                "text": "And when I looked, there were four wheels by the cherubim, one wheel by one cherub and another wheel by each other cherub; the wheels appeared to have the color of a beryl stone.", 
                "num": 9
              }, 
              {
                "text": "As for their appearance, all four looked alike--as it were, a wheel in the middle of a wheel.", 
                "num": 10
              }, 
              {
                "text": "When they went, they went toward any of their four directions; they did not turn aside when they went, but followed in the direction the head was facing. They did not turn aside when they went.", 
                "num": 11
              }, 
              {
                "text": "And their whole body, with their back, their hands, their wings, and the wheels that the four had, were full of eyes all around.", 
                "num": 12
              }, 
              {
                "text": "As for the wheels, they were called in my hearing, \"Wheel.\"", 
                "num": 13
              }, 
              {
                "text": "Each one had four faces: the first face was the face of a cherub, the second face the face of a man, the third the face of a lion, and the fourth the face of an eagle.", 
                "num": 14
              }, 
              {
                "text": "And the cherubim were lifted up. This was the living creature I saw by the River Chebar.", 
                "num": 15
              }, 
              {
                "text": "When the cherubim went, the wheels went beside them; and when the cherubim lifted their wings to mount up from the earth, the same wheels also did not turn from beside them.", 
                "num": 16
              }, 
              {
                "text": "When the cherubim stood still, the wheels stood still, and when one was lifted up, the other lifted itself up, for the spirit of the living creature was in them.", 
                "num": 17
              }, 
              {
                "text": "Then the glory of the LORD departed from the threshold of the temple and stood over the cherubim.", 
                "num": 18
              }, 
              {
                "text": "And the cherubim lifted their wings and mounted up from the earth in my sight. When they went out, the wheels were beside them; and they stood at the door of the east gate of the LORD's house, and the glory of the God of Israel was above them.", 
                "num": 19
              }, 
              {
                "text": "This is the living creature I saw under the God of Israel by the River Chebar, and I knew they were cherubim.", 
                "num": 20
              }, 
              {
                "text": "Each one had four faces and each one four wings, and the likeness of the hands of a man was under their wings.", 
                "num": 21
              }, 
              {
                "text": "And the likeness of their faces was the same as the faces which I had seen by the River Chebar, their appearance and their persons. They each went straight forward.", 
                "num": 22
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Then the Spirit lifted me up and brought me to the East Gate of the LORD's house, which faces eastward; and there at the door of the gate were twenty-five men, among whom I saw Jaazaniah the son of Azzur, and Pelatiah the son of Benaiah, princes of the people.", 
                "num": 1
              }, 
              {
                "text": "And He said to me: \"Son of man, these are the men who devise iniquity and give wicked counsel in this city,", 
                "num": 2
              }, 
              {
                "text": "who say, \"The time is not near to build houses; this city is the caldron, and we are the meat.'", 
                "num": 3
              }, 
              {
                "text": "Therefore prophesy against them, prophesy, O son of man!\"", 
                "num": 4
              }, 
              {
                "text": "Then the Spirit of the LORD fell upon me, and said to me, \"Speak! \"Thus says the LORD: \"Thus you have said, O house of Israel; for I know the things that come into your mind.", 
                "num": 5
              }, 
              {
                "text": "You have multiplied your slain in this city, and you have filled its streets with the slain.\"", 
                "num": 6
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Your slain whom you have laid in its midst, they are the meat, and this city is the caldron; but I shall bring you out of the midst of it.", 
                "num": 7
              }, 
              {
                "text": "You have feared the sword; and I will bring a sword upon you,\" says the Lord GOD.", 
                "num": 8
              }, 
              {
                "text": "\"And I will bring you out of its midst, and deliver you into the hands of strangers, and execute judgments on you.", 
                "num": 9
              }, 
              {
                "text": "You shall fall by the sword. I will judge you at the border of Israel. Then you shall know that I am the LORD.", 
                "num": 10
              }, 
              {
                "text": "This city shall not be your caldron, nor shall you be the meat in its midst. I will judge you at the border of Israel.", 
                "num": 11
              }, 
              {
                "text": "And you shall know that I am the LORD; for you have not walked in My statutes nor executed My judgments, but have done according to the customs of the Gentiles which are all around you.\"\"'", 
                "num": 12
              }, 
              {
                "text": "Now it happened, while I was prophesying, that Pelatiah the son of Benaiah died. Then I fell on my face and cried with a loud voice, and said, \"Ah, Lord GOD! Will You make a complete end of the remnant of Israel?\"", 
                "num": 13
              }, 
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 14
              }, 
              {
                "text": "\"Son of man, your brethren, your relatives, your countrymen, and all the house of Israel in its entirety, are those about whom the inhabitants of Jerusalem have said, \"Get far away from the LORD; this land has been given to us as a possession.'", 
                "num": 15
              }, 
              {
                "text": "Therefore say, \"Thus says the Lord GOD: \"Although I have cast them far off among the Gentiles, and although I have scattered them among the countries, yet I shall be a little sanctuary for them in the countries where they have gone.\"'", 
                "num": 16
              }, 
              {
                "text": "Therefore say, \"Thus says the Lord GOD: \"I will gather you from the peoples, assemble you from the countries where you have been scattered, and I will give you the land of Israel.\"'", 
                "num": 17
              }, 
              {
                "text": "And they will go there, and they will take away all its detestable things and all its abominations from there.", 
                "num": 18
              }, 
              {
                "text": "Then I will give them one heart, and I will put a new spirit within them, and take the stony heart out of their flesh, and give them a heart of flesh,", 
                "num": 19
              }, 
              {
                "text": "that they may walk in My statutes and keep My judgments and do them; and they shall be My people, and I will be their God.", 
                "num": 20
              }, 
              {
                "text": "But as for those whose hearts follow the desire for their detestable things and their abominations, I will recompense their deeds on their own heads,\" says the Lord GOD.", 
                "num": 21
              }, 
              {
                "text": "So the cherubim lifted up their wings, with the wheels beside them, and the glory of the God of Israel was high above them.", 
                "num": 22
              }, 
              {
                "text": "And the glory of the LORD went up from the midst of the city and stood on the mountain, which is on the east side of the city.", 
                "num": 23
              }, 
              {
                "text": "Then the Spirit took me up and brought me in a vision by the Spirit of God into Chaldea, to those in captivity. And the vision that I had seen went up from me.", 
                "num": 24
              }, 
              {
                "text": "So I spoke to those in captivity of all the things the LORD had shown me.", 
                "num": 25
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now the word of the LORD came to me, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, you dwell in the midst of a rebellious house, which has eyes to see but does not see, and ears to hear but does not hear; for they are a rebellious house.", 
                "num": 2
              }, 
              {
                "text": "\"Therefore, son of man, prepare your belongings for captivity, and go into captivity by day in their sight. You shall go from your place into captivity to another place in their sight. It may be that they will consider, though they are a rebellious house.", 
                "num": 3
              }, 
              {
                "text": "By day you shall bring out your belongings in their sight, as though going into captivity; and at evening you shall go in their sight, like those who go into captivity.", 
                "num": 4
              }, 
              {
                "text": "Dig through the wall in their sight, and carry your belongings out through it.", 
                "num": 5
              }, 
              {
                "text": "In their sight you shall bear them on your shoulders and carry them out at twilight; you shall cover your face, so that you cannot see the ground, for I have made you a sign to the house of Israel.\"", 
                "num": 6
              }, 
              {
                "text": "So I did as I was commanded. I brought out my belongings by day, as though going into captivity, and at evening I dug through the wall with my hand. I brought them out at twilight, and I bore them on my shoulder in their sight.", 
                "num": 7
              }, 
              {
                "text": "And in the morning the word of the LORD came to me, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Son of man, has not the house of Israel, the rebellious house, said to you, \"What are you doing?'", 
                "num": 9
              }, 
              {
                "text": "Say to them, \"Thus says the Lord GOD: \"This burden concerns the prince in Jerusalem and all the house of Israel who are among them.\"'", 
                "num": 10
              }, 
              {
                "text": "Say, \"I am a sign to you. As I have done, so shall it be done to them; they shall be carried away into captivity.'", 
                "num": 11
              }, 
              {
                "text": "And the prince who is among them shall bear his belongings on his shoulder at twilight and go out. They shall dig through the wall to carry them out through it. He shall cover his face, so that he cannot see the ground with his eyes.", 
                "num": 12
              }, 
              {
                "text": "I will also spread My net over him, and he shall be caught in My snare. I will bring him to Babylon, to the land of the Chaldeans; yet he shall not see it, though he shall die there.", 
                "num": 13
              }, 
              {
                "text": "I will scatter to every wind all who are around him to help him, and all his troops; and I will draw out the sword after them.", 
                "num": 14
              }, 
              {
                "text": "\"Then they shall know that I am the LORD, when I scatter them among the nations and disperse them throughout the countries.", 
                "num": 15
              }, 
              {
                "text": "But I will spare a few of their men from the sword, from famine, and from pestilence, that they may declare all their abominations among the Gentiles wherever they go. Then they shall know that I am the LORD.\"", 
                "num": 16
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Son of man, eat your bread with quaking, and drink your water with trembling and anxiety.", 
                "num": 18
              }, 
              {
                "text": "And say to the people of the land, \"Thus says the Lord GOD to the inhabitants of Jerusalem and to the land of Israel: \"They shall eat their bread with anxiety, and drink their water with dread, so that her land may be emptied of all who are in it, because of the violence of all those who dwell in it.", 
                "num": 19
              }, 
              {
                "text": "Then the cities that are inhabited shall be laid waste, and the land shall become desolate; and you shall know that I am the LORD.\"\"'", 
                "num": 20
              }, 
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 21
              }, 
              {
                "text": "\"Son of man, what is this proverb that you people have about the land of Israel, which says, \"The days are prolonged, and every vision fails'?", 
                "num": 22
              }, 
              {
                "text": "Tell them therefore, \"Thus says the Lord GOD: \"I will lay this proverb to rest, and they shall no more use it as a proverb in Israel.\" But say to them, \"The days are at hand, and the fulfillment of every vision.", 
                "num": 23
              }, 
              {
                "text": "For no more shall there be any false vision or flattering divination within the house of Israel.", 
                "num": 24
              }, 
              {
                "text": "For I am the LORD. I speak, and the word which I speak will come to pass; it will no more be postponed; for in your days, O rebellious house, I will say the word and perform it,\" says the Lord GOD.\"'", 
                "num": 25
              }, 
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 26
              }, 
              {
                "text": "\"Son of man, look, the house of Israel is saying, \"The vision that he sees is for many days from now, and he prophesies of times far off.'", 
                "num": 27
              }, 
              {
                "text": "Therefore say to them, \"Thus says the Lord GOD: \"None of My words will be postponed any more, but the word which I speak will be done,\" says the Lord GOD.\"'", 
                "num": 28
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, prophesy against the prophets of Israel who prophesy, and say to those who prophesy out of their own heart, \"Hear the word of the LORD!\"'", 
                "num": 2
              }, 
              {
                "text": "Thus says the Lord GOD: \"Woe to the foolish prophets, who follow their own spirit and have seen nothing!", 
                "num": 3
              }, 
              {
                "text": "O Israel, your prophets are like foxes in the deserts.", 
                "num": 4
              }, 
              {
                "text": "You have not gone up into the gaps to build a wall for the house of Israel to stand in battle on the day of the LORD.", 
                "num": 5
              }, 
              {
                "text": "They have envisioned futility and false divination, saying, \"Thus says the LORD!' But the LORD has not sent them; yet they hope that the word may be confirmed.", 
                "num": 6
              }, 
              {
                "text": "Have you not seen a futile vision, and have you not spoken false divination? You say, \"The LORD says,' but I have not spoken.\"", 
                "num": 7
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Because you have spoken nonsense and envisioned lies, therefore I am indeed against you,\" says the Lord GOD.", 
                "num": 8
              }, 
              {
                "text": "\"My hand will be against the prophets who envision futility and who divine lies; they shall not be in the assembly of My people, nor be written in the record of the house of Israel, nor shall they enter into the land of Israel. Then you shall know that I am the Lord GOD.", 
                "num": 9
              }, 
              {
                "text": "\"Because, indeed, because they have seduced My people, saying, \"Peace!' when there is no peace--and one builds a wall, and they plaster it with untempered mortar--", 
                "num": 10
              }, 
              {
                "text": "say to those who plaster it with untempered mortar, that it will fall. There will be flooding rain, and you, O great hailstones, shall fall; and a stormy wind shall tear it down.", 
                "num": 11
              }, 
              {
                "text": "Surely, when the wall has fallen, will it not be said to you, \"Where is the mortar with which you plastered it?\"'", 
                "num": 12
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"I will cause a stormy wind to break forth in My fury; and there shall be a flooding rain in My anger, and great hailstones in fury to consume it.", 
                "num": 13
              }, 
              {
                "text": "So I will break down the wall you have plastered with untempered mortar, and bring it down to the ground, so that its foundation will be uncovered; it will fall, and you shall be consumed in the midst of it. Then you shall know that I am the LORD.", 
                "num": 14
              }, 
              {
                "text": "\"Thus will I accomplish My wrath on the wall and on those who have plastered it with untempered mortar; and I will say to you, \"The wall is no more, nor those who plastered it,", 
                "num": 15
              }, 
              {
                "text": "that is, the prophets of Israel who prophesy concerning Jerusalem, and who see visions of peace for her when there is no peace,\"' says the Lord GOD.", 
                "num": 16
              }, 
              {
                "text": "\"Likewise, son of man, set your face against the daughters of your people, who prophesy out of their own heart; prophesy against them,", 
                "num": 17
              }, 
              {
                "text": "and say, \"Thus says the Lord GOD: \"Woe to the women who sew magic charms on their sleeves and make veils for the heads of people of every height to hunt souls! Will you hunt the souls of My people, and keep yourselves alive?", 
                "num": 18
              }, 
              {
                "text": "And will you profane Me among My people for handfuls of barley and for pieces of bread, killing people who should not die, and keeping people alive who should not live, by your lying to My people who listen to lies?\"", 
                "num": 19
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Behold, I am against your magic charms by which you hunt souls there like birds. I will tear them from your arms, and let the souls go, the souls you hunt like birds.", 
                "num": 20
              }, 
              {
                "text": "I will also tear off your veils and deliver My people out of your hand, and they shall no longer be as prey in your hand. Then you shall know that I am the LORD.", 
                "num": 21
              }, 
              {
                "text": "\"Because with lies you have made the heart of the righteous sad, whom I have not made sad; and you have strengthened the hands of the wicked, so that he does not turn from his wicked way to save his life.", 
                "num": 22
              }, 
              {
                "text": "Therefore you shall no longer envision futility nor practice divination; for I will deliver My people out of your hand, and you shall know that I am the LORD.\"\"'", 
                "num": 23
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now some of the elders of Israel came to me and sat before me.", 
                "num": 1
              }, 
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Son of man, these men have set up their idols in their hearts, and put before them that which causes them to stumble into iniquity. Should I let Myself be inquired of at all by them?", 
                "num": 3
              }, 
              {
                "text": "\"Therefore speak to them, and say to them, \"Thus says the Lord GOD: \"Everyone of the house of Israel who sets up his idols in his heart, and puts before him what causes him to stumble into iniquity, and then comes to the prophet, I the LORD will answer him who comes, according to the multitude of his idols,", 
                "num": 4
              }, 
              {
                "text": "that I may seize the house of Israel by their heart, because they are all estranged from Me by their idols.\"'", 
                "num": 5
              }, 
              {
                "text": "\"Therefore say to the house of Israel, \"Thus says the Lord GOD: \"Repent, turn away from your idols, and turn your faces away from all your abominations.", 
                "num": 6
              }, 
              {
                "text": "For anyone of the house of Israel, or of the strangers who dwell in Israel, who separates himself from Me and sets up his idols in his heart and puts before him what causes him to stumble into iniquity, then comes to a prophet to inquire of him concerning Me, I the LORD will answer him by Myself.", 
                "num": 7
              }, 
              {
                "text": "I will set My face against that man and make him a sign and a proverb, and I will cut him off from the midst of My people. Then you shall know that I am the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"And if the prophet is induced to speak anything, I the LORD have induced that prophet, and I will stretch out My hand against him and destroy him from among My people Israel.", 
                "num": 9
              }, 
              {
                "text": "And they shall bear their iniquity; the punishment of the prophet shall be the same as the punishment of the one who inquired,", 
                "num": 10
              }, 
              {
                "text": "that the house of Israel may no longer stray from Me, nor be profaned anymore with all their transgressions, but that they may be My people and I may be their God,\" says the Lord GOD.\"'", 
                "num": 11
              }, 
              {
                "text": "The word of the LORD came again to me, saying:", 
                "num": 12
              }, 
              {
                "text": "\"Son of man, when a land sins against Me by persistent unfaithfulness, I will stretch out My hand against it; I will cut off its supply of bread, send famine on it, and cut off man and beast from it.", 
                "num": 13
              }, 
              {
                "text": "Even if these three men, Noah, Daniel, and Job, were in it, they would deliver only themselves by their righteousness,\" says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "\"If I cause wild beasts to pass through the land, and they empty it, and make it so desolate that no man may pass through because of the beasts,", 
                "num": 15
              }, 
              {
                "text": "even though these three men were in it, as I live,\" says the Lord GOD, \"they would deliver neither sons nor daughters; only they would be delivered, and the land would be desolate.", 
                "num": 16
              }, 
              {
                "text": "\"Or if I bring a sword on that land, and say, \"Sword, go through the land,' and I cut off man and beast from it,", 
                "num": 17
              }, 
              {
                "text": "even though these three men were in it, as I live,\" says the Lord GOD, \"they would deliver neither sons nor daughters, but only they themselves would be delivered.", 
                "num": 18
              }, 
              {
                "text": "\"Or if I send a pestilence into that land and pour out My fury on it in blood, and cut off from it man and beast,", 
                "num": 19
              }, 
              {
                "text": "even though Noah, Daniel, and Job were in it, as I live,\" says the Lord GOD, \"they would deliver neither son nor daughter; they would deliver only themselves by their righteousness.\"", 
                "num": 20
              }, 
              {
                "text": "For thus says the Lord GOD: \"How much more it shall be when I send My four severe judgments on Jerusalem--the sword and famine and wild beasts and pestilence--to cut off man and beast from it?", 
                "num": 21
              }, 
              {
                "text": "Yet behold, there shall be left in it a remnant who will be brought out, both sons and daughters; surely they will come out to you, and you will see their ways and their doings. Then you will be comforted concerning the disaster that I have brought upon Jerusalem, all that I have brought upon it.", 
                "num": 22
              }, 
              {
                "text": "And they will comfort you, when you see their ways and their doings; and you shall know that I have done nothing without cause that I have done in it,\" says the Lord GOD.", 
                "num": 23
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then the word of the LORD came to me, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, how is the wood of the vine better than any other wood, the vine branch which is among the trees of the forest?", 
                "num": 2
              }, 
              {
                "text": "Is wood taken from it to make any object? Or can men make a peg from it to hang any vessel on?", 
                "num": 3
              }, 
              {
                "text": "Instead, it is thrown into the fire for fuel; the fire devours both ends of it, and its middle is burned. Is it useful for any work?", 
                "num": 4
              }, 
              {
                "text": "Indeed, when it was whole, no object could be made from it. How much less will it be useful for any work when the fire has devoured it, and it is burned?", 
                "num": 5
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Like the wood of the vine among the trees of the forest, which I have given to the fire for fuel, so I will give up the inhabitants of Jerusalem;", 
                "num": 6
              }, 
              {
                "text": "and I will set My face against them. They will go out from one fire, but another fire shall devour them. Then you shall know that I am the LORD, when I set My face against them.", 
                "num": 7
              }, 
              {
                "text": "Thus I will make the land desolate, because they have persisted in unfaithfulness,' says the Lord GOD.\"", 
                "num": 8
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, cause Jerusalem to know her abominations,", 
                "num": 2
              }, 
              {
                "text": "and say, \"Thus says the Lord GOD to Jerusalem: \"Your birth and your nativity are from the land of Canaan; your father was an Amorite and your mother a Hittite.", 
                "num": 3
              }, 
              {
                "text": "As for your nativity, on the day you were born your navel cord was not cut, nor were you washed in water to cleanse you; you were not rubbed with salt nor wrapped in swaddling cloths.", 
                "num": 4
              }, 
              {
                "text": "No eye pitied you, to do any of these things for you, to have compassion on you; but you were thrown out into the open field, when you yourself were loathed on the day you were born.", 
                "num": 5
              }, 
              {
                "text": "\"And when I passed by you and saw you struggling in your own blood, I said to you in your blood, \"Live!' Yes, I said to you in your blood, \"Live!'", 
                "num": 6
              }, 
              {
                "text": "I made you thrive like a plant in the field; and you grew, matured, and became very beautiful. Your breasts were formed, your hair grew, but you were naked and bare.", 
                "num": 7
              }, 
              {
                "text": "\"When I passed by you again and looked upon you, indeed your time was the time of love; so I spread My wing over you and covered your nakedness. Yes, I swore an oath to you and entered into a covenant with you, and you became Mine,\" says the Lord GOD.", 
                "num": 8
              }, 
              {
                "text": "\"Then I washed you in water; yes, I thoroughly washed off your blood, and I anointed you with oil.", 
                "num": 9
              }, 
              {
                "text": "I clothed you in embroidered cloth and gave you sandals of badger skin; I clothed you with fine linen and covered you with silk.", 
                "num": 10
              }, 
              {
                "text": "I adorned you with ornaments, put bracelets on your wrists, and a chain on your neck.", 
                "num": 11
              }, 
              {
                "text": "And I put a jewel in your nose, earrings in your ears, and a beautiful crown on your head.", 
                "num": 12
              }, 
              {
                "text": "Thus you were adorned with gold and silver, and your clothing was of fine linen, silk, and embroidered cloth. You ate pastry of fine flour, honey, and oil. You were exceedingly beautiful, and succeeded to royalty.", 
                "num": 13
              }, 
              {
                "text": "Your fame went out among the nations because of your beauty, for it was perfect through My splendor which I had bestowed on you,\" says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "\"But you trusted in your own beauty, played the harlot because of your fame, and poured out your harlotry on everyone passing by who would have it.", 
                "num": 15
              }, 
              {
                "text": "You took some of your garments and adorned multicolored high places for yourself, and played the harlot on them. Such things should not happen, nor be.", 
                "num": 16
              }, 
              {
                "text": "You have also taken your beautiful jewelry from My gold and My silver, which I had given you, and made for yourself male images and played the harlot with them.", 
                "num": 17
              }, 
              {
                "text": "You took your embroidered garments and covered them, and you set My oil and My incense before them.", 
                "num": 18
              }, 
              {
                "text": "Also My food which I gave you--the pastry of fine flour, oil, and honey which I fed you--you set it before them as sweet incense; and so it was,\" says the Lord GOD.", 
                "num": 19
              }, 
              {
                "text": "\"Moreover you took your sons and your daughters, whom you bore to Me, and these you sacrificed to them to be devoured. Were your acts of harlotry a small matter,", 
                "num": 20
              }, 
              {
                "text": "that you have slain My children and offered them up to them by causing them to pass through the fire?", 
                "num": 21
              }, 
              {
                "text": "And in all your abominations and acts of harlotry you did not remember the days of your youth, when you were naked and bare, struggling in your blood.", 
                "num": 22
              }, 
              {
                "text": "\"Then it was so, after all your wickedness--\"Woe, woe to you!' says the Lord GOD--", 
                "num": 23
              }, 
              {
                "text": "that you also built for yourself a shrine, and made a high place for yourself in every street.", 
                "num": 24
              }, 
              {
                "text": "You built your high places at the head of every road, and made your beauty to be abhorred. You offered yourself to everyone who passed by, and multiplied your acts of harlotry.", 
                "num": 25
              }, 
              {
                "text": "You also committed harlotry with the Egyptians, your very fleshly neighbors, and increased your acts of harlotry to provoke Me to anger.", 
                "num": 26
              }, 
              {
                "text": "\"Behold, therefore, I stretched out My hand against you, diminished your allotment, and gave you up to the will of those who hate you, the daughters of the Philistines, who were ashamed of your lewd behavior.", 
                "num": 27
              }, 
              {
                "text": "You also played the harlot with the Assyrians, because you were insatiable; indeed you played the harlot with them and still were not satisfied.", 
                "num": 28
              }, 
              {
                "text": "Moreover you multiplied your acts of harlotry as far as the land of the trader, Chaldea; and even then you were not satisfied.", 
                "num": 29
              }, 
              {
                "text": "\"How degenerate is your heart!\" says the Lord GOD, \"seeing you do all these things, the deeds of a brazen harlot.", 
                "num": 30
              }, 
              {
                "text": "\"You erected your shrine at the head of every road, and built your high place in every street. Yet you were not like a harlot, because you scorned payment.", 
                "num": 31
              }, 
              {
                "text": "You are an adulterous wife, who takes strangers instead of her husband.", 
                "num": 32
              }, 
              {
                "text": "Men make payment to all harlots, but you made your payments to all your lovers, and hired them to come to you from all around for your harlotry.", 
                "num": 33
              }, 
              {
                "text": "You are the opposite of other women in your harlotry, because no one solicited you to be a harlot. In that you gave payment but no payment was given you, therefore you are the opposite.\"", 
                "num": 34
              }, 
              {
                "text": "\"Now then, O harlot, hear the word of the LORD!", 
                "num": 35
              }, 
              {
                "text": "Thus says the Lord GOD: \"Because your filthiness was poured out and your nakedness uncovered in your harlotry with your lovers, and with all your abominable idols, and because of the blood of your children which you gave to them,", 
                "num": 36
              }, 
              {
                "text": "surely, therefore, I will gather all your lovers with whom you took pleasure, all those you loved, and all those you hated; I will gather them from all around against you and will uncover your nakedness to them, that they may see all your nakedness.", 
                "num": 37
              }, 
              {
                "text": "And I will judge you as women who break wedlock or shed blood are judged; I will bring blood upon you in fury and jealousy.", 
                "num": 38
              }, 
              {
                "text": "I will also give you into their hand, and they shall throw down your shrines and break down your high places. They shall also strip you of your clothes, take your beautiful jewelry, and leave you naked and bare.", 
                "num": 39
              }, 
              {
                "text": "\"They shall also bring up an assembly against you, and they shall stone you with stones and thrust you through with their swords.", 
                "num": 40
              }, 
              {
                "text": "They shall burn your houses with fire, and execute judgments on you in the sight of many women; and I will make you cease playing the harlot, and you shall no longer hire lovers.", 
                "num": 41
              }, 
              {
                "text": "So I will lay to rest My fury toward you, and My jealousy shall depart from you. I will be quiet, and be angry no more.", 
                "num": 42
              }, 
              {
                "text": "Because you did not remember the days of your youth, but agitated Me with all these things, surely I will also recompense your deeds on your own head,\" says the Lord GOD. \"And you shall not commit lewdness in addition to all your abominations.", 
                "num": 43
              }, 
              {
                "text": "\"Indeed everyone who quotes proverbs will use this proverb against you: \"Like mother, like daughter!'", 
                "num": 44
              }, 
              {
                "text": "You are your mother's daughter, loathing husband and children; and you are the sister of your sisters, who loathed their husbands and children; your mother was a Hittite and your father an Amorite.", 
                "num": 45
              }, 
              {
                "text": "\"Your elder sister is Samaria, who dwells with her daughters to the north of you; and your younger sister, who dwells to the south of you, is Sodom and her daughters.", 
                "num": 46
              }, 
              {
                "text": "You did not walk in their ways nor act according to their abominations; but, as if that were too little, you became more corrupt than they in all your ways.", 
                "num": 47
              }, 
              {
                "text": "\"As I live,\" says the Lord GOD, \"neither your sister Sodom nor her daughters have done as you and your daughters have done.", 
                "num": 48
              }, 
              {
                "text": "Look, this was the iniquity of your sister Sodom: She and her daughter had pride, fullness of food, and abundance of idleness; neither did she strengthen the hand of the poor and needy.", 
                "num": 49
              }, 
              {
                "text": "And they were haughty and committed abomination before Me; therefore I took them away as I saw fit.", 
                "num": 50
              }, 
              {
                "text": "\"Samaria did not commit half of your sins; but you have multiplied your abominations more than they, and have justified your sisters by all the abominations which you have done.", 
                "num": 51
              }, 
              {
                "text": "You who judged your sisters, bear your own shame also, because the sins which you committed were more abominable than theirs; they are more righteous than you. Yes, be disgraced also, and bear your own shame, because you justified your sisters.", 
                "num": 52
              }, 
              {
                "text": "\"When I bring back their captives, the captives of Sodom and her daughters, and the captives of Samaria and her daughters, then I will also bring back the captives of your captivity among them,", 
                "num": 53
              }, 
              {
                "text": "that you may bear your own shame and be disgraced by all that you did when you comforted them.", 
                "num": 54
              }, 
              {
                "text": "When your sisters, Sodom and her daughters, return to their former state, and Samaria and her daughters return to their former state, then you and your daughters will return to your former state.", 
                "num": 55
              }, 
              {
                "text": "For your sister Sodom was not a byword in your mouth in the days of your pride,", 
                "num": 56
              }, 
              {
                "text": "before your wickedness was uncovered. It was like the time of the reproach of the daughters of Syria and all those around her, and of the daughters of the Philistines, who despise you everywhere.", 
                "num": 57
              }, 
              {
                "text": "You have paid for your lewdness and your abominations,\" says the LORD.", 
                "num": 58
              }, 
              {
                "text": "For thus says the Lord GOD: \"I will deal with you as you have done, who despised the oath by breaking the covenant.", 
                "num": 59
              }, 
              {
                "text": "\"Nevertheless I will remember My covenant with you in the days of your youth, and I will establish an everlasting covenant with you.", 
                "num": 60
              }, 
              {
                "text": "Then you will remember your ways and be ashamed, when you receive your older and your younger sisters; for I will give them to you for daughters, but not because of My covenant with you.", 
                "num": 61
              }, 
              {
                "text": "And I will establish My covenant with you. Then you shall know that I am the LORD,", 
                "num": 62
              }, 
              {
                "text": "that you may remember and be ashamed, and never open your mouth anymore because of your shame, when I provide you an atonement for all you have done,\" says the Lord GOD.\"'", 
                "num": 63
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, pose a riddle, and speak a parable to the house of Israel,", 
                "num": 2
              }, 
              {
                "text": "and say, \"Thus says the Lord GOD: \"A great eagle with large wings and long pinions, Full of feathers of various colors, Came to Lebanon And took from the cedar the highest branch.", 
                "num": 3
              }, 
              {
                "text": "He cropped off its topmost young twig And carried it to a land of trade; He set it in a city of merchants.", 
                "num": 4
              }, 
              {
                "text": "Then he took some of the seed of the land And planted it in a fertile field; He placed it by abundant waters And set it like a willow tree.", 
                "num": 5
              }, 
              {
                "text": "And it grew and became a spreading vine of low stature; Its branches turned toward him, But its roots were under it. So it became a vine, Brought forth branches, And put forth shoots.", 
                "num": 6
              }, 
              {
                "text": "\"But there was another great eagle with large wings and many feathers; And behold, this vine bent its roots toward him, And stretched its branches toward him, From the garden terrace where it had been planted, That he might water it.", 
                "num": 7
              }, 
              {
                "text": "It was planted in good soil by many waters, To bring forth branches, bear fruit, And become a majestic vine.\"'", 
                "num": 8
              }, 
              {
                "text": "\"Say, \"Thus says the Lord GOD: \"Will it thrive? Will he not pull up its roots, Cut off its fruit, And leave it to wither? All of its spring leaves will wither, And no great power or many people Will be needed to pluck it up by its roots.", 
                "num": 9
              }, 
              {
                "text": "Behold, it is planted, Will it thrive? Will it not utterly wither when the east wind touches it? It will wither in the garden terrace where it grew.\"\"'", 
                "num": 10
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 11
              }, 
              {
                "text": "\"Say now to the rebellious house: \"Do you not know what these things mean?' Tell them, \"Indeed the king of Babylon went to Jerusalem and took its king and princes, and led them with him to Babylon.", 
                "num": 12
              }, 
              {
                "text": "And he took the king's offspring, made a covenant with him, and put him under oath. He also took away the mighty of the land,", 
                "num": 13
              }, 
              {
                "text": "that the kingdom might be brought low and not lift itself up, but that by keeping his covenant it might stand.", 
                "num": 14
              }, 
              {
                "text": "But he rebelled against him by sending his ambassadors to Egypt, that they might give him horses and many people. Will he prosper? Will he who does such things escape? Can he break a covenant and still be delivered?", 
                "num": 15
              }, 
              {
                "text": "\"As I live,' says the Lord GOD, \"surely in the place where the king dwells who made him king, whose oath he despised and whose covenant he broke--with him in the midst of Babylon he shall die.", 
                "num": 16
              }, 
              {
                "text": "Nor will Pharaoh with his mighty army and great company do anything in the war, when they heap up a siege mound and build a wall to cut off many persons.", 
                "num": 17
              }, 
              {
                "text": "Since he despised the oath by breaking the covenant, and in fact gave his hand and still did all these things, he shall not escape.\"'", 
                "num": 18
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"As I live, surely My oath which he despised, and My covenant which he broke, I will recompense on his own head.", 
                "num": 19
              }, 
              {
                "text": "I will spread My net over him, and he shall be taken in My snare. I will bring him to Babylon and try him there for the treason which he committed against Me.", 
                "num": 20
              }, 
              {
                "text": "All his fugitives with all his troops shall fall by the sword, and those who remain shall be scattered to every wind; and you shall know that I, the LORD, have spoken.\"", 
                "num": 21
              }, 
              {
                "text": "Thus says the Lord GOD: \"I will take also one of the highest branches of the high cedar and set it out. I will crop off from the topmost of its young twigs a tender one, and will plant it on a high and prominent mountain.", 
                "num": 22
              }, 
              {
                "text": "On the mountain height of Israel I will plant it; and it will bring forth boughs, and bear fruit, and be a majestic cedar. Under it will dwell birds of every sort; in the shadow of its branches they will dwell.", 
                "num": 23
              }, 
              {
                "text": "And all the trees of the field shall know that I, the LORD, have brought down the high tree and exalted the low tree, dried up the green tree and made the dry tree flourish; I, the LORD, have spoken and have done it.\"", 
                "num": 24
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came to me again, saying,", 
                "num": 1
              }, 
              {
                "text": "\"What do you mean when you use this proverb concerning the land of Israel, saying: \"The fathers have eaten sour grapes, And the children's teeth are set on edge'?", 
                "num": 2
              }, 
              {
                "text": "\"As I live,\" says the Lord GOD, \"you shall no longer use this proverb in Israel.", 
                "num": 3
              }, 
              {
                "text": "\"Behold, all souls are Mine; The soul of the father As well as the soul of the son is Mine; The soul who sins shall die.", 
                "num": 4
              }, 
              {
                "text": "But if a man is just And does what is lawful and right;", 
                "num": 5
              }, 
              {
                "text": "If he has not eaten on the mountains, Nor lifted up his eyes to the idols of the house of Israel, Nor defiled his neighbor's wife, Nor approached a woman during her impurity;", 
                "num": 6
              }, 
              {
                "text": "If he has not oppressed anyone, But has restored to the debtor his pledge; Has robbed no one by violence, But has given his bread to the hungry And covered the naked with clothing;", 
                "num": 7
              }, 
              {
                "text": "If he has not exacted usury Nor taken any increase, But has withdrawn his hand from iniquity And executed true judgment between man and man;", 
                "num": 8
              }, 
              {
                "text": "If he has walked in My statutes And kept My judgments faithfully-- He is just; He shall surely live!\" Says the Lord GOD.", 
                "num": 9
              }, 
              {
                "text": "\"If he begets a son who is a robber Or a shedder of blood, Who does any of these things", 
                "num": 10
              }, 
              {
                "text": "And does none of those duties, But has eaten on the mountains Or defiled his neighbor's wife;", 
                "num": 11
              }, 
              {
                "text": "If he has oppressed the poor and needy, Robbed by violence, Not restored the pledge, Lifted his eyes to the idols, Or committed abomination;", 
                "num": 12
              }, 
              {
                "text": "If he has exacted usury Or taken increase-- Shall he then live? He shall not live! If he has done any of these abominations, He shall surely die; His blood shall be upon him.", 
                "num": 13
              }, 
              {
                "text": "\"If, however, he begets a son Who sees all the sins which his father has done, And considers but does not do likewise;", 
                "num": 14
              }, 
              {
                "text": "Who has not eaten on the mountains, Nor lifted his eyes to the idols of the house of Israel, Nor defiled his neighbor's wife;", 
                "num": 15
              }, 
              {
                "text": "Has not oppressed anyone, Nor withheld a pledge, Nor robbed by violence, But has given his bread to the hungry And covered the naked with clothing;", 
                "num": 16
              }, 
              {
                "text": "Who has withdrawn his hand from the poor And not received usury or increase, But has executed My judgments And walked in My statutes-- He shall not die for the iniquity of his father; He shall surely live!", 
                "num": 17
              }, 
              {
                "text": "\"As for his father, Because he cruelly oppressed, Robbed his brother by violence, And did what is not good among his people, Behold, he shall die for his iniquity.", 
                "num": 18
              }, 
              {
                "text": "\"Yet you say, \"Why should the son not bear the guilt of the father?' Because the son has done what is lawful and right, and has kept all My statutes and observed them, he shall surely live.", 
                "num": 19
              }, 
              {
                "text": "The soul who sins shall die. The son shall not bear the guilt of the father, nor the father bear the guilt of the son. The righteousness of the righteous shall be upon himself, and the wickedness of the wicked shall be upon himself.", 
                "num": 20
              }, 
              {
                "text": "\"But if a wicked man turns from all his sins which he has committed, keeps all My statutes, and does what is lawful and right, he shall surely live; he shall not die.", 
                "num": 21
              }, 
              {
                "text": "None of the transgressions which he has committed shall be remembered against him; because of the righteousness which he has done, he shall live.", 
                "num": 22
              }, 
              {
                "text": "Do I have any pleasure at all that the wicked should die?\" says the Lord GOD, \"and not that he should turn from his ways and live?", 
                "num": 23
              }, 
              {
                "text": "\"But when a righteous man turns away from his righteousness and commits iniquity, and does according to all the abominations that the wicked man does, shall he live? All the righteousness which he has done shall not be remembered; because of the unfaithfulness of which he is guilty and the sin which he has committed, because of them he shall die.", 
                "num": 24
              }, 
              {
                "text": "\"Yet you say, \"The way of the Lord is not fair.' Hear now, O house of Israel, is it not My way which is fair, and your ways which are not fair?", 
                "num": 25
              }, 
              {
                "text": "When a righteous man turns away from his righteousness, commits iniquity, and dies in it, it is because of the iniquity which he has done that he dies.", 
                "num": 26
              }, 
              {
                "text": "Again, when a wicked man turns away from the wickedness which he committed, and does what is lawful and right, he preserves himself alive.", 
                "num": 27
              }, 
              {
                "text": "Because he considers and turns away from all the transgressions which he committed, he shall surely live; he shall not die.", 
                "num": 28
              }, 
              {
                "text": "Yet the house of Israel says, \"The way of the Lord is not fair.' O house of Israel, is it not My ways which are fair, and your ways which are not fair?", 
                "num": 29
              }, 
              {
                "text": "\"Therefore I will judge you, O house of Israel, every one according to his ways,\" says the Lord GOD. \"Repent, and turn from all your transgressions, so that iniquity will not be your ruin.", 
                "num": 30
              }, 
              {
                "text": "Cast away from you all the transgressions which you have committed, and get yourselves a new heart and a new spirit. For why should you die, O house of Israel?", 
                "num": 31
              }, 
              {
                "text": "For I have no pleasure in the death of one who dies,\" says the Lord GOD. \"Therefore turn and live!\"", 
                "num": 32
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "\"Moreover take up a lamentation for the princes of Israel,", 
                "num": 1
              }, 
              {
                "text": "and say: \"What is your mother? A lioness: She lay down among the lions; Among the young lions she nourished her cubs.", 
                "num": 2
              }, 
              {
                "text": "She brought up one of her cubs, And he became a young lion; He learned to catch prey, And he devoured men.", 
                "num": 3
              }, 
              {
                "text": "The nations also heard of him; He was trapped in their pit, And they brought him with chains to the land of Egypt.", 
                "num": 4
              }, 
              {
                "text": "\"When she saw that she waited, that her hope was lost, She took another of her cubs and made him a young lion.", 
                "num": 5
              }, 
              {
                "text": "He roved among the lions, And became a young lion; He learned to catch prey; He devoured men.", 
                "num": 6
              }, 
              {
                "text": "He knew their desolate places, And laid waste their cities; The land with its fullness was desolated By the noise of his roaring.", 
                "num": 7
              }, 
              {
                "text": "Then the nations set against him from the provinces on every side, And spread their net over him; He was trapped in their pit.", 
                "num": 8
              }, 
              {
                "text": "They put him in a cage with chains, And brought him to the king of Babylon; They brought him in nets, That his voice should no longer be heard on the mountains of Israel.", 
                "num": 9
              }, 
              {
                "text": "\"Your mother was like a vine in your bloodline, Planted by the waters, Fruitful and full of branches Because of many waters.", 
                "num": 10
              }, 
              {
                "text": "She had strong branches for scepters of rulers. She towered in stature above the thick branches, And was seen in her height amid the dense foliage.", 
                "num": 11
              }, 
              {
                "text": "But she was plucked up in fury, She was cast down to the ground, And the east wind dried her fruit. Her strong branches were broken and withered; The fire consumed them.", 
                "num": 12
              }, 
              {
                "text": "And now she is planted in the wilderness, In a dry and thirsty land.", 
                "num": 13
              }, 
              {
                "text": "Fire has come out from a rod of her branches And devoured her fruit, So that she has no strong branch-- a scepter for ruling.\"' This is a lamentation, and has become a lamentation.", 
                "num": 14
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "It came to pass in the seventh year, in the fifth month, on the tenth day of the month, that certain of the elders of Israel came to inquire of the LORD, and sat before me.", 
                "num": 1
              }, 
              {
                "text": "Then the word of the LORD came to me, saying,", 
                "num": 2
              }, 
              {
                "text": "\"Son of man, speak to the elders of Israel, and say to them, \"Thus says the Lord GOD: \"Have you come to inquire of Me? As I live,\" says the Lord GOD, \"I will not be inquired of by you.\"'", 
                "num": 3
              }, 
              {
                "text": "Will you judge them, son of man, will you judge them? Then make known to them the abominations of their fathers.", 
                "num": 4
              }, 
              {
                "text": "\"Say to them, \"Thus says the Lord GOD: \"On the day when I chose Israel and raised My hand in an oath to the descendants of the house of Jacob, and made Myself known to them in the land of Egypt, I raised My hand in an oath to them, saying, \"I am the LORD your God.'", 
                "num": 5
              }, 
              {
                "text": "On that day I raised My hand in an oath to them, to bring them out of the land of Egypt into a land that I had searched out for them, \"flowing with milk and honey,' the glory of all lands.", 
                "num": 6
              }, 
              {
                "text": "Then I said to them, \"Each of you, throw away the abominations which are before his eyes, and do not defile yourselves with the idols of Egypt. I am the LORD your God.'", 
                "num": 7
              }, 
              {
                "text": "But they rebelled against Me and would not obey Me. They did not all cast away the abominations which were before their eyes, nor did they forsake the idols of Egypt. Then I said, \"I will pour out My fury on them and fulfill My anger against them in the midst of the land of Egypt.'", 
                "num": 8
              }, 
              {
                "text": "But I acted for My name's sake, that it should not be profaned before the Gentiles among whom they were, in whose sight I had made Myself known to them, to bring them out of the land of Egypt.", 
                "num": 9
              }, 
              {
                "text": "\"Therefore I made them go out of the land of Egypt and brought them into the wilderness.", 
                "num": 10
              }, 
              {
                "text": "And I gave them My statutes and showed them My judgments, \"which, if a man does, he shall live by them.'", 
                "num": 11
              }, 
              {
                "text": "Moreover I also gave them My Sabbaths, to be a sign between them and Me, that they might know that I am the LORD who sanctifies them.", 
                "num": 12
              }, 
              {
                "text": "Yet the house of Israel rebelled against Me in the wilderness; they did not walk in My statutes; they despised My judgments, \"which, if a man does, he shall live by them'; and they greatly defiled My Sabbaths. Then I said I would pour out My fury on them in the wilderness, to consume them.", 
                "num": 13
              }, 
              {
                "text": "But I acted for My name's sake, that it should not be profaned before the Gentiles, in whose sight I had brought them out.", 
                "num": 14
              }, 
              {
                "text": "So I also raised My hand in an oath to them in the wilderness, that I would not bring them into the land which I had given them, \"flowing with milk and honey,' the glory of all lands,", 
                "num": 15
              }, 
              {
                "text": "because they despised My judgments and did not walk in My statutes, but profaned My Sabbaths; for their heart went after their idols.", 
                "num": 16
              }, 
              {
                "text": "Nevertheless My eye spared them from destruction. I did not make an end of them in the wilderness.", 
                "num": 17
              }, 
              {
                "text": "\"But I said to their children in the wilderness, \"Do not walk in the statutes of your fathers, nor observe their judgments, nor defile yourselves with their idols.", 
                "num": 18
              }, 
              {
                "text": "I am the LORD your God: Walk in My statutes, keep My judgments, and do them;", 
                "num": 19
              }, 
              {
                "text": "hallow My Sabbaths, and they will be a sign between Me and you, that you may know that I am the LORD your God.'", 
                "num": 20
              }, 
              {
                "text": "\"Notwithstanding, the children rebelled against Me; they did not walk in My statutes, and were not careful to observe My judgments, \"which, if a man does, he shall live by them'; but they profaned My Sabbaths. Then I said I would pour out My fury on them and fulfill My anger against them in the wilderness.", 
                "num": 21
              }, 
              {
                "text": "Nevertheless I withdrew My hand and acted for My name's sake, that it should not be profaned in the sight of the Gentiles, in whose sight I had brought them out.", 
                "num": 22
              }, 
              {
                "text": "Also I raised My hand in an oath to those in the wilderness, that I would scatter them among the Gentiles and disperse them throughout the countries,", 
                "num": 23
              }, 
              {
                "text": "because they had not executed My judgments, but had despised My statutes, profaned My Sabbaths, and their eyes were fixed on their fathers' idols.", 
                "num": 24
              }, 
              {
                "text": "\"Therefore I also gave them up to statutes that were not good, and judgments by which they could not live;", 
                "num": 25
              }, 
              {
                "text": "and I pronounced them unclean because of their ritual gifts, in that they caused all their firstborn to pass through the fire, that I might make them desolate and that they might know that I am the LORD.\"'", 
                "num": 26
              }, 
              {
                "text": "\"Therefore, son of man, speak to the house of Israel, and say to them, \"Thus says the Lord GOD: \"In this too your fathers have blasphemed Me, by being unfaithful to Me.", 
                "num": 27
              }, 
              {
                "text": "When I brought them into the land concerning which I had raised My hand in an oath to give them, and they saw all the high hills and all the thick trees, there they offered their sacrifices and provoked Me with their offerings. There they also sent up their sweet aroma and poured out their drink offerings.", 
                "num": 28
              }, 
              {
                "text": "Then I said to them, \"What is this high place to which you go?' So its name is called Bamah to this day.\"'", 
                "num": 29
              }, 
              {
                "text": "Therefore say to the house of Israel, \"Thus says the Lord GOD: \"Are you defiling yourselves in the manner of your fathers, and committing harlotry according to their abominations?", 
                "num": 30
              }, 
              {
                "text": "For when you offer your gifts and make your sons pass through the fire, you defile yourselves with all your idols, even to this day. So shall I be inquired of by you, O house of Israel? As I live,\" says the Lord GOD, \"I will not be inquired of by you.", 
                "num": 31
              }, 
              {
                "text": "What you have in your mind shall never be, when you say, \"We will be like the Gentiles, like the families in other countries, serving wood and stone.'", 
                "num": 32
              }, 
              {
                "text": "\"As I live,\" says the Lord GOD, \"surely with a mighty hand, with an outstretched arm, and with fury poured out, I will rule over you.", 
                "num": 33
              }, 
              {
                "text": "I will bring you out from the peoples and gather you out of the countries where you are scattered, with a mighty hand, with an outstretched arm, and with fury poured out.", 
                "num": 34
              }, 
              {
                "text": "And I will bring you into the wilderness of the peoples, and there I will plead My case with you face to face.", 
                "num": 35
              }, 
              {
                "text": "Just as I pleaded My case with your fathers in the wilderness of the land of Egypt, so I will plead My case with you,\" says the Lord GOD.", 
                "num": 36
              }, 
              {
                "text": "\"I will make you pass under the rod, and I will bring you into the bond of the covenant;", 
                "num": 37
              }, 
              {
                "text": "I will purge the rebels from among you, and those who transgress against Me; I will bring them out of the country where they dwell, but they shall not enter the land of Israel. Then you will know that I am the LORD.", 
                "num": 38
              }, 
              {
                "text": "\"As for you, O house of Israel,\" thus says the Lord GOD: \"Go, serve every one of you his idols--and hereafter--if you will not obey Me; but profane My holy name no more with your gifts and your idols.", 
                "num": 39
              }, 
              {
                "text": "For on My holy mountain, on the mountain height of Israel,\" says the Lord GOD, \"there all the house of Israel, all of them in the land, shall serve Me; there I will accept them, and there I will require your offerings and the firstfruits of your sacrifices, together with all your holy things.", 
                "num": 40
              }, 
              {
                "text": "I will accept you as a sweet aroma when I bring you out from the peoples and gather you out of the countries where you have been scattered; and I will be hallowed in you before the Gentiles.", 
                "num": 41
              }, 
              {
                "text": "Then you shall know that I am the LORD, when I bring you into the land of Israel, into the country for which I raised My hand in an oath to give to your fathers.", 
                "num": 42
              }, 
              {
                "text": "And there you shall remember your ways and all your doings with which you were defiled; and you shall loathe yourselves in your own sight because of all the evils that you have committed.", 
                "num": 43
              }, 
              {
                "text": "Then you shall know that I am the LORD, when I have dealt with you for My name's sake, not according to your wicked ways nor according to your corrupt doings, O house of Israel,\" says the Lord GOD.\"'", 
                "num": 44
              }, 
              {
                "text": "Furthermore the word of the LORD came to me, saying,", 
                "num": 45
              }, 
              {
                "text": "\"Son of man, set your face toward the south; preach against the south and prophesy against the forest land, the South,", 
                "num": 46
              }, 
              {
                "text": "and say to the forest of the South, \"Hear the word of the LORD! Thus says the Lord GOD: \"Behold, I will kindle a fire in you, and it shall devour every green tree and every dry tree in you; the blazing flame shall not be quenched, and all faces from the south to the north shall be scorched by it.", 
                "num": 47
              }, 
              {
                "text": "All flesh shall see that I, the LORD, have kindled it; it shall not be quenched.\"\"'", 
                "num": 48
              }, 
              {
                "text": "Then I said, \"Ah, Lord GOD! They say of me, \"Does he not speak parables?\"'", 
                "num": 49
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face toward Jerusalem, preach against the holy places, and prophesy against the land of Israel;", 
                "num": 2
              }, 
              {
                "text": "and say to the land of Israel, \"Thus says the LORD: \"Behold, I am against you, and I will draw My sword out of its sheath and cut off both righteous and wicked from you.", 
                "num": 3
              }, 
              {
                "text": "Because I will cut off both righteous and wicked from you, therefore My sword shall go out of its sheath against all flesh from south to north,", 
                "num": 4
              }, 
              {
                "text": "that all flesh may know that I, the LORD, have drawn My sword out of its sheath; it shall not return anymore.\"'", 
                "num": 5
              }, 
              {
                "text": "Sigh therefore, son of man, with a breaking heart, and sigh with bitterness before their eyes.", 
                "num": 6
              }, 
              {
                "text": "And it shall be when they say to you, \"Why are you sighing?' that you shall answer, \"Because of the news; when it comes, every heart will melt, all hands will be feeble, every spirit will faint, and all knees will be weak as water. Behold, it is coming and shall be brought to pass,' says the Lord GOD.\"", 
                "num": 7
              }, 
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Son of man, prophesy and say, \"Thus says the LORD!' Say: \"A sword, a sword is sharpened And also polished!", 
                "num": 9
              }, 
              {
                "text": "Sharpened to make a dreadful slaughter, Polished to flash like lightning! Should we then make mirth? It despises the scepter of My son, As it does all wood.", 
                "num": 10
              }, 
              {
                "text": "And He has given it to be polished, That it may be handled; This sword is sharpened, and it is polished To be given into the hand of the slayer.'", 
                "num": 11
              }, 
              {
                "text": "\"Cry and wail, son of man; For it will be against My people, Against all the princes of Israel. Terrors including the sword will be against My people; Therefore strike your thigh.", 
                "num": 12
              }, 
              {
                "text": "\"Because it is a testing, And what if the sword despises even the scepter? The scepter shall be no more,\" says the Lord GOD.", 
                "num": 13
              }, 
              {
                "text": "\"You therefore, son of man, prophesy, And strike your hands together. The third time let the sword do double damage. It is the sword that slays, The sword that slays the great men, That enters their private chambers.", 
                "num": 14
              }, 
              {
                "text": "I have set the point of the sword against all their gates, That the heart may melt and many may stumble. Ah! It is made bright; It is grasped for slaughter:", 
                "num": 15
              }, 
              {
                "text": "\"Swords at the ready! Thrust right! Set your blade! Thrust left-- Wherever your edge is ordered!", 
                "num": 16
              }, 
              {
                "text": "\"I also will beat My fists together, And I will cause My fury to rest; I, the LORD, have spoken.\"", 
                "num": 17
              }, 
              {
                "text": "The word of the LORD came to me again, saying:", 
                "num": 18
              }, 
              {
                "text": "\"And son of man, appoint for yourself two ways for the sword of the king of Babylon to go; both of them shall go from the same land. Make a sign; put it at the head of the road to the city.", 
                "num": 19
              }, 
              {
                "text": "Appoint a road for the sword to go to Rabbah of the Ammonites, and to Judah, into fortified Jerusalem.", 
                "num": 20
              }, 
              {
                "text": "For the king of Babylon stands at the parting of the road, at the fork of the two roads, to use divination: he shakes the arrows, he consults the images, he looks at the liver.", 
                "num": 21
              }, 
              {
                "text": "In his right hand is the divination for Jerusalem: to set up battering rams, to call for a slaughter, to lift the voice with shouting, to set battering rams against the gates, to heap up a siege mound, and to build a wall.", 
                "num": 22
              }, 
              {
                "text": "And it will be to them like a false divination in the eyes of those who have sworn oaths with them; but he will bring their iniquity to remembrance, that they may be taken.", 
                "num": 23
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Because you have made your iniquity to be remembered, in that your transgressions are uncovered, so that in all your doings your sins appear--because you have come to remembrance, you shall be taken in hand.", 
                "num": 24
              }, 
              {
                "text": "\"Now to you, O profane, wicked prince of Israel, whose day has come, whose iniquity shall end,", 
                "num": 25
              }, 
              {
                "text": "thus says the Lord GOD: \"Remove the turban, and take off the crown; Nothing shall remain the same. Exalt the humble, and humble the exalted.", 
                "num": 26
              }, 
              {
                "text": "Overthrown, overthrown, I will make it overthrown! It shall be no longer, Until He comes whose right it is, And I will give it to Him.\"'", 
                "num": 27
              }, 
              {
                "text": "\"And you, son of man, prophesy and say, \"Thus says the Lord GOD concerning the Ammonites and concerning their reproach,' and say: \"A sword, a sword is drawn, Polished for slaughter, For consuming, for flashing--", 
                "num": 28
              }, 
              {
                "text": "While they see false visions for you, While they divine a lie to you, To bring you on the necks of the wicked, the slain Whose day has come, Whose iniquity shall end.", 
                "num": 29
              }, 
              {
                "text": "\"Return it to its sheath. I will judge you In the place where you were created, In the land of your nativity.", 
                "num": 30
              }, 
              {
                "text": "I will pour out My indignation on you; I will blow against you with the fire of My wrath, And deliver you into the hands of brutal men who are skillful to destroy.", 
                "num": 31
              }, 
              {
                "text": "You shall be fuel for the fire; Your blood shall be in the midst of the land. You shall not be remembered, For I the LORD have spoken.\"'", 
                "num": 32
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Now, son of man, will you judge, will you judge the bloody city? Yes, show her all her abominations!", 
                "num": 2
              }, 
              {
                "text": "Then say, \"Thus says the Lord GOD: \"The city sheds blood in her own midst, that her time may come; and she makes idols within herself to defile herself.", 
                "num": 3
              }, 
              {
                "text": "You have become guilty by the blood which you have shed, and have defiled yourself with the idols which you have made. You have caused your days to draw near, and have come to the end of your years; therefore I have made you a reproach to the nations, and a mockery to all countries.", 
                "num": 4
              }, 
              {
                "text": "Those near and those far from you will mock you as infamous and full of tumult.", 
                "num": 5
              }, 
              {
                "text": "\"Look, the princes of Israel: each one has used his power to shed blood in you.", 
                "num": 6
              }, 
              {
                "text": "In you they have made light of father and mother; in your midst they have oppressed the stranger; in you they have mistreated the fatherless and the widow.", 
                "num": 7
              }, 
              {
                "text": "You have despised My holy things and profaned My Sabbaths.", 
                "num": 8
              }, 
              {
                "text": "In you are men who slander to cause bloodshed; in you are those who eat on the mountains; in your midst they commit lewdness.", 
                "num": 9
              }, 
              {
                "text": "In you men uncover their fathers' nakedness; in you they violate women who are set apart during their impurity.", 
                "num": 10
              }, 
              {
                "text": "One commits abomination with his neighbor's wife; another lewdly defiles his daughter-in-law; and another in you violates his sister, his father's daughter.", 
                "num": 11
              }, 
              {
                "text": "In you they take bribes to shed blood; you take usury and increase; you have made profit from your neighbors by extortion, and have forgotten Me,\" says the Lord GOD.", 
                "num": 12
              }, 
              {
                "text": "\"Behold, therefore, I beat My fists at the dishonest profit which you have made, and at the bloodshed which has been in your midst.", 
                "num": 13
              }, 
              {
                "text": "Can your heart endure, or can your hands remain strong, in the days when I shall deal with you? I, the LORD, have spoken, and will do it.", 
                "num": 14
              }, 
              {
                "text": "I will scatter you among the nations, disperse you throughout the countries, and remove your filthiness completely from you.", 
                "num": 15
              }, 
              {
                "text": "You shall defile yourself in the sight of the nations; then you shall know that I am the LORD.\"\"'", 
                "num": 16
              }, 
              {
                "text": "The word of the LORD came to me, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Son of man, the house of Israel has become dross to Me; they are all bronze, tin, iron, and lead, in the midst of a furnace; they have become dross from silver.", 
                "num": 18
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Because you have all become dross, therefore behold, I will gather you into the midst of Jerusalem.", 
                "num": 19
              }, 
              {
                "text": "As men gather silver, bronze, iron, lead, and tin into the midst of a furnace, to blow fire on it, to melt it; so I will gather you in My anger and in My fury, and I will leave you there and melt you.", 
                "num": 20
              }, 
              {
                "text": "Yes, I will gather you and blow on you with the fire of My wrath, and you shall be melted in its midst.", 
                "num": 21
              }, 
              {
                "text": "As silver is melted in the midst of a furnace, so shall you be melted in its midst; then you shall know that I, the LORD, have poured out My fury on you.\"'", 
                "num": 22
              }, 
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 23
              }, 
              {
                "text": "\"Son of man, say to her: \"You are a land that is not cleansed or rained on in the day of indignation.'", 
                "num": 24
              }, 
              {
                "text": "The conspiracy of her prophets in her midst is like a roaring lion tearing the prey; they have devoured people; they have taken treasure and precious things; they have made many widows in her midst.", 
                "num": 25
              }, 
              {
                "text": "Her priests have violated My law and profaned My holy things; they have not distinguished between the holy and unholy, nor have they made known the difference between the unclean and the clean; and they have hidden their eyes from My Sabbaths, so that I am profaned among them.", 
                "num": 26
              }, 
              {
                "text": "Her princes in her midst are like wolves tearing the prey, to shed blood, to destroy people, and to get dishonest gain.", 
                "num": 27
              }, 
              {
                "text": "Her prophets plastered them with untempered mortar, seeing false visions, and divining lies for them, saying, \"Thus says the Lord GOD,' when the LORD had not spoken.", 
                "num": 28
              }, 
              {
                "text": "The people of the land have used oppressions, committed robbery, and mistreated the poor and needy; and they wrongfully oppress the stranger.", 
                "num": 29
              }, 
              {
                "text": "So I sought for a man among them who would make a wall, and stand in the gap before Me on behalf of the land, that I should not destroy it; but I found no one.", 
                "num": 30
              }, 
              {
                "text": "Therefore I have poured out My indignation on them; I have consumed them with the fire of My wrath; and I have recompensed their deeds on their own heads,\" says the Lord GOD.", 
                "num": 31
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came again to me, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, there were two women, The daughters of one mother.", 
                "num": 2
              }, 
              {
                "text": "They committed harlotry in Egypt, They committed harlotry in their youth; Their breasts were there embraced, Their virgin bosom was there pressed.", 
                "num": 3
              }, 
              {
                "text": "Their names: Oholah the elder and Oholibah her sister; They were Mine, And they bore sons and daughters. As for their names, Samaria is Oholah, and Jerusalem is Oholibah.", 
                "num": 4
              }, 
              {
                "text": "\"Oholah played the harlot even though she was Mine; And she lusted for her lovers, the neighboring Assyrians,", 
                "num": 5
              }, 
              {
                "text": "Who were clothed in purple, Captains and rulers, All of them desirable young men, Horsemen riding on horses.", 
                "num": 6
              }, 
              {
                "text": "Thus she committed her harlotry with them, All of them choice men of Assyria; And with all for whom she lusted, With all their idols, she defiled herself.", 
                "num": 7
              }, 
              {
                "text": "She has never given up her harlotry brought from Egypt, For in her youth they had lain with her, Pressed her virgin bosom, And poured out their immorality upon her.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore I have delivered her Into the hand of her lovers, Into the hand of the Assyrians, For whom she lusted.", 
                "num": 9
              }, 
              {
                "text": "They uncovered her nakedness, Took away her sons and daughters, And slew her with the sword; She became a byword among women, For they had executed judgment on her.", 
                "num": 10
              }, 
              {
                "text": "\"Now although her sister Oholibah saw this, she became more corrupt in her lust than she, and in her harlotry more corrupt than her sister's harlotry.", 
                "num": 11
              }, 
              {
                "text": "\"She lusted for the neighboring Assyrians, Captains and rulers, Clothed most gorgeously, Horsemen riding on horses, All of them desirable young men.", 
                "num": 12
              }, 
              {
                "text": "Then I saw that she was defiled; Both took the same way.", 
                "num": 13
              }, 
              {
                "text": "But she increased her harlotry; She looked at men portrayed on the wall, Images of Chaldeans portrayed in vermilion,", 
                "num": 14
              }, 
              {
                "text": "Girded with belts around their waists, Flowing turbans on their heads, All of them looking like captains, In the manner of the Babylonians of Chaldea, The land of their nativity.", 
                "num": 15
              }, 
              {
                "text": "As soon as her eyes saw them, She lusted for them And sent messengers to them in Chaldea.", 
                "num": 16
              }, 
              {
                "text": "\"Then the Babylonians came to her, into the bed of love, And they defiled her with their immorality; So she was defiled by them, and alienated herself from them.", 
                "num": 17
              }, 
              {
                "text": "She revealed her harlotry and uncovered her nakedness. Then I alienated Myself from her, As I had alienated Myself from her sister.", 
                "num": 18
              }, 
              {
                "text": "\"Yet she multiplied her harlotry In calling to remembrance the days of her youth, When she had played the harlot in the land of Egypt.", 
                "num": 19
              }, 
              {
                "text": "For she lusted for her paramours, Whose flesh is like the flesh of donkeys, And whose issue is like the issue of horses.", 
                "num": 20
              }, 
              {
                "text": "Thus you called to remembrance the lewdness of your youth, When the Egyptians pressed your bosom Because of your youthful breasts.", 
                "num": 21
              }, 
              {
                "text": "\"Therefore, Oholibah, thus says the Lord GOD: \"Behold, I will stir up your lovers against you, From whom you have alienated yourself, And I will bring them against you from every side:", 
                "num": 22
              }, 
              {
                "text": "The Babylonians, All the Chaldeans, Pekod, Shoa, Koa, All the Assyrians with them, All of them desirable young men, Governors and rulers, Captains and men of renown, All of them riding on horses.", 
                "num": 23
              }, 
              {
                "text": "And they shall come against you With chariots, wagons, and war-horses, With a horde of people. They shall array against you Buckler, shield, and helmet all around. \"I will delegate judgment to them, And they shall judge you according to their judgments.", 
                "num": 24
              }, 
              {
                "text": "I will set My jealousy against you, And they shall deal furiously with you; They shall remove your nose and your ears, And your remnant shall fall by the sword; They shall take your sons and your daughters, And your remnant shall be devoured by fire.", 
                "num": 25
              }, 
              {
                "text": "They shall also strip you of your clothes And take away your beautiful jewelry.", 
                "num": 26
              }, 
              {
                "text": "\"Thus I will make you cease your lewdness and your harlotry Brought from the land of Egypt, So that you will not lift your eyes to them, Nor remember Egypt anymore.'", 
                "num": 27
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"Surely I will deliver you into the hand of those you hate, into the hand of those from whom you alienated yourself.", 
                "num": 28
              }, 
              {
                "text": "They will deal hatefully with you, take away all you have worked for, and leave you naked and bare. The nakedness of your harlotry shall be uncovered, both your lewdness and your harlotry.", 
                "num": 29
              }, 
              {
                "text": "I will do these things to you because you have gone as a harlot after the Gentiles, because you have become defiled by their idols.", 
                "num": 30
              }, 
              {
                "text": "You have walked in the way of your sister; therefore I will put her cup in your hand.'", 
                "num": 31
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"You shall drink of your sister's cup, The deep and wide one; You shall be laughed to scorn And held in derision; It contains much.", 
                "num": 32
              }, 
              {
                "text": "You will be filled with drunkenness and sorrow, The cup of horror and desolation, The cup of your sister Samaria.", 
                "num": 33
              }, 
              {
                "text": "You shall drink and drain it, You shall break its shards, And tear at your own breasts; For I have spoken,' Says the Lord GOD.", 
                "num": 34
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Because you have forgotten Me and cast Me behind your back, Therefore you shall bear the penalty Of your lewdness and your harlotry.\"'", 
                "num": 35
              }, 
              {
                "text": "The LORD also said to me: \"Son of man, will you judge Oholah and Oholibah? Then declare to them their abominations.", 
                "num": 36
              }, 
              {
                "text": "For they have committed adultery, and blood is on their hands. They have committed adultery with their idols, and even sacrificed their sons whom they bore to Me, passing them through the fire, to devour them.", 
                "num": 37
              }, 
              {
                "text": "Moreover they have done this to Me: They have defiled My sanctuary on the same day and profaned My Sabbaths.", 
                "num": 38
              }, 
              {
                "text": "For after they had slain their children for their idols, on the same day they came into My sanctuary to profane it; and indeed thus they have done in the midst of My house.", 
                "num": 39
              }, 
              {
                "text": "\"Furthermore you sent for men to come from afar, to whom a messenger was sent; and there they came. And you washed yourself for them, painted your eyes, and adorned yourself with ornaments.", 
                "num": 40
              }, 
              {
                "text": "You sat on a stately couch, with a table prepared before it, on which you had set My incense and My oil.", 
                "num": 41
              }, 
              {
                "text": "The sound of a carefree multitude was with her, and Sabeans were brought from the wilderness with men of the common sort, who put bracelets on their wrists and beautiful crowns on their heads.", 
                "num": 42
              }, 
              {
                "text": "Then I said concerning her who had grown old in adulteries, \"Will they commit harlotry with her now, and she with them?'", 
                "num": 43
              }, 
              {
                "text": "Yet they went in to her, as men go in to a woman who plays the harlot; thus they went in to Oholah and Oholibah, the lewd women.", 
                "num": 44
              }, 
              {
                "text": "But righteous men will judge them after the manner of adulteresses, and after the manner of women who shed blood, because they are adulteresses, and blood is on their hands.", 
                "num": 45
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"Bring up an assembly against them, give them up to trouble and plunder.", 
                "num": 46
              }, 
              {
                "text": "The assembly shall stone them with stones and execute them with their swords; they shall slay their sons and their daughters, and burn their houses with fire.", 
                "num": 47
              }, 
              {
                "text": "Thus I will cause lewdness to cease from the land, that all women may be taught not to practice your lewdness.", 
                "num": 48
              }, 
              {
                "text": "They shall repay you for your lewdness, and you shall pay for your idolatrous sins. Then you shall know that I am the Lord GOD.\"'", 
                "num": 49
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Again, in the ninth year, in the tenth month, on the tenth day of the month, the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, write down the name of the day, this very day--the king of Babylon started his siege against Jerusalem this very day.", 
                "num": 2
              }, 
              {
                "text": "And utter a parable to the rebellious house, and say to them, \"Thus says the Lord GOD: \"Put on a pot, set it on, And also pour water into it.", 
                "num": 3
              }, 
              {
                "text": "Gather pieces of meat in it, Every good piece, The thigh and the shoulder. Fill it with choice cuts;", 
                "num": 4
              }, 
              {
                "text": "Take the choice of the flock. Also pile fuel bones under it, Make it boil well, And let the cuts simmer in it.\"", 
                "num": 5
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Woe to the bloody city, To the pot whose scum is in it, And whose scum is not gone from it! Bring it out piece by piece, On which no lot has fallen.", 
                "num": 6
              }, 
              {
                "text": "For her blood is in her midst; She set it on top of a rock; She did not pour it on the ground, To cover it with dust.", 
                "num": 7
              }, 
              {
                "text": "That it may raise up fury and take vengeance, I have set her blood on top of a rock, That it may not be covered.\"", 
                "num": 8
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Woe to the bloody city! I too will make the pyre great.", 
                "num": 9
              }, 
              {
                "text": "Heap on the wood, Kindle the fire; Cook the meat well, Mix in the spices, And let the cuts be burned up.", 
                "num": 10
              }, 
              {
                "text": "\"Then set the pot empty on the coals, That it may become hot and its bronze may burn, That its filthiness may be melted in it, That its scum may be consumed.", 
                "num": 11
              }, 
              {
                "text": "She has grown weary with lies, And her great scum has not gone from her. Let her scum be in the fire!", 
                "num": 12
              }, 
              {
                "text": "In your filthiness is lewdness. Because I have cleansed you, and you were not cleansed, You will not be cleansed of your filthiness anymore, Till I have caused My fury to rest upon you.", 
                "num": 13
              }, 
              {
                "text": "I, the LORD, have spoken it; It shall come to pass, and I will do it; I will not hold back, Nor will I spare, Nor will I relent; According to your ways And according to your deeds They will judge you,\" Says the Lord GOD.\"'", 
                "num": 14
              }, 
              {
                "text": "Also the word of the LORD came to me, saying,", 
                "num": 15
              }, 
              {
                "text": "\"Son of man, behold, I take away from you the desire of your eyes with one stroke; yet you shall neither mourn nor weep, nor shall your tears run down.", 
                "num": 16
              }, 
              {
                "text": "Sigh in silence, make no mourning for the dead; bind your turban on your head, and put your sandals on your feet; do not cover your lips, and do not eat man's bread of sorrow.\"", 
                "num": 17
              }, 
              {
                "text": "So I spoke to the people in the morning, and at evening my wife died; and the next morning I did as I was commanded.", 
                "num": 18
              }, 
              {
                "text": "And the people said to me, \"Will you not tell us what these things signify to us, that you behave so?\"", 
                "num": 19
              }, 
              {
                "text": "Then I answered them, \"The word of the LORD came to me, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Speak to the house of Israel, \"Thus says the Lord GOD: \"Behold, I will profane My sanctuary, your arrogant boast, the desire of your eyes, the delight of your soul; and your sons and daughters whom you left behind shall fall by the sword.", 
                "num": 21
              }, 
              {
                "text": "And you shall do as I have done; you shall not cover your lips nor eat man's bread of sorrow.", 
                "num": 22
              }, 
              {
                "text": "Your turbans shall be on your heads and your sandals on your feet; you shall neither mourn nor weep, but you shall pine away in your iniquities and mourn with one another.", 
                "num": 23
              }, 
              {
                "text": "Thus Ezekiel is a sign to you; according to all that he has done you shall do; and when this comes, you shall know that I am the Lord GOD.\"'", 
                "num": 24
              }, 
              {
                "text": "\"And you, son of man--will it not be in the day when I take from them their stronghold, their joy and their glory, the desire of their eyes, and that on which they set their minds, their sons and their daughters:", 
                "num": 25
              }, 
              {
                "text": "on that day one who escapes will come to you to let you hear it with your ears;", 
                "num": 26
              }, 
              {
                "text": "on that day your mouth will be opened to him who has escaped; you shall speak and no longer be mute. Thus you will be a sign to them, and they shall know that I am the LORD.\"'", 
                "num": 27
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face against the Ammonites, and prophesy against them.", 
                "num": 2
              }, 
              {
                "text": "Say to the Ammonites, \"Hear the word of the Lord GOD! Thus says the Lord GOD: \"Because you said, \"Aha!' against My sanctuary when it was profaned, and against the land of Israel when it was desolate, and against the house of Judah when they went into captivity,", 
                "num": 3
              }, 
              {
                "text": "indeed, therefore, I will deliver you as a possession to the men of the East, and they shall set their encampments among you and make their dwellings among you; they shall eat your fruit, and they shall drink your milk.", 
                "num": 4
              }, 
              {
                "text": "And I will make Rabbah a stable for camels and Ammon a resting place for flocks. Then you shall know that I am the LORD.\"", 
                "num": 5
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"Because you clapped your hands, stamped your feet, and rejoiced in heart with all your disdain for the land of Israel,", 
                "num": 6
              }, 
              {
                "text": "indeed, therefore, I will stretch out My hand against you, and give you as plunder to the nations; I will cut you off from the peoples, and I will cause you to perish from the countries; I will destroy you, and you shall know that I am the LORD.\"", 
                "num": 7
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Because Moab and Seir say, \"Look! The house of Judah is like all the nations,'", 
                "num": 8
              }, 
              {
                "text": "therefore, behold, I will clear the territory of Moab of cities, of the cities on its frontier, the glory of the country, Beth Jeshimoth, Baal Meon, and Kirjathaim.", 
                "num": 9
              }, 
              {
                "text": "To the men of the East I will give it as a possession, together with the Ammonites, that the Ammonites may not be remembered among the nations.", 
                "num": 10
              }, 
              {
                "text": "And I will execute judgments upon Moab, and they shall know that I am the LORD.\"", 
                "num": 11
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Because of what Edom did against the house of Judah by taking vengeance, and has greatly offended by avenging itself on them,\"", 
                "num": 12
              }, 
              {
                "text": "therefore thus says the Lord GOD: \"I will also stretch out My hand against Edom, cut off man and beast from it, and make it desolate from Teman; Dedan shall fall by the sword.", 
                "num": 13
              }, 
              {
                "text": "I will lay My vengeance on Edom by the hand of My people Israel, that they may do in Edom according to My anger and according to My fury; and they shall know My vengeance,\" says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Because the Philistines dealt vengefully and took vengeance with a spiteful heart, to destroy because of the old hatred,\"", 
                "num": 15
              }, 
              {
                "text": "therefore thus says the Lord GOD: \"I will stretch out My hand against the Philistines, and I will cut off the Cherethites and destroy the remnant of the seacoast.", 
                "num": 16
              }, 
              {
                "text": "I will execute great vengeance on them with furious rebukes; and they shall know that I am the LORD, when I lay My vengeance upon them.\"\"'", 
                "num": 17
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the eleventh year, on the first day of the month, that the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, because Tyre has said against Jerusalem, \"Aha! She is broken who was the gateway of the peoples; now she is turned over to me; I shall be filled; she is laid waste.'", 
                "num": 2
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Behold, I am against you, O Tyre, and will cause many nations to come up against you, as the sea causes its waves to come up.", 
                "num": 3
              }, 
              {
                "text": "And they shall destroy the walls of Tyre and break down her towers; I will also scrape her dust from her, and make her like the top of a rock.", 
                "num": 4
              }, 
              {
                "text": "It shall be a place for spreading nets in the midst of the sea, for I have spoken,' says the Lord GOD; \"it shall become plunder for the nations.", 
                "num": 5
              }, 
              {
                "text": "Also her daughter villages which are in the fields shall be slain by the sword. Then they shall know that I am the LORD.'", 
                "num": 6
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"Behold, I will bring against Tyre from the north Nebuchadnezzar king of Babylon, king of kings, with horses, with chariots, and with horsemen, and an army with many people.", 
                "num": 7
              }, 
              {
                "text": "He will slay with the sword your daughter villages in the fields; he will heap up a siege mound against you, build a wall against you, and raise a defense against you.", 
                "num": 8
              }, 
              {
                "text": "He will direct his battering rams against your walls, and with his axes he will break down your towers.", 
                "num": 9
              }, 
              {
                "text": "Because of the abundance of his horses, their dust will cover you; your walls will shake at the noise of the horsemen, the wagons, and the chariots, when he enters your gates, as men enter a city that has been breached.", 
                "num": 10
              }, 
              {
                "text": "With the hooves of his horses he will trample all your streets; he will slay your people by the sword, and your strong pillars will fall to the ground.", 
                "num": 11
              }, 
              {
                "text": "They will plunder your riches and pillage your merchandise; they will break down your walls and destroy your pleasant houses; they will lay your stones, your timber, and your soil in the midst of the water.", 
                "num": 12
              }, 
              {
                "text": "I will put an end to the sound of your songs, and the sound of your harps shall be heard no more.", 
                "num": 13
              }, 
              {
                "text": "I will make you like the top of a rock; you shall be a place for spreading nets, and you shall never be rebuilt, for I the LORD have spoken,' says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "\"Thus says the Lord GOD to Tyre: \"Will the coastlands not shake at the sound of your fall, when the wounded cry, when slaughter is made in the midst of you?", 
                "num": 15
              }, 
              {
                "text": "Then all the princes of the sea will come down from their thrones, lay aside their robes, and take off their embroidered garments; they will clothe themselves with trembling; they will sit on the ground, tremble every moment, and be astonished at you.", 
                "num": 16
              }, 
              {
                "text": "And they will take up a lamentation for you, and say to you: \"How you have perished, O one inhabited by seafaring men, O renowned city, Who was strong at sea, She and her inhabitants, Who caused their terror to be on all her inhabitants!", 
                "num": 17
              }, 
              {
                "text": "Now the coastlands tremble on the day of your fall; Yes, the coastlands by the sea are troubled at your departure.\"'", 
                "num": 18
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"When I make you a desolate city, like cities that are not inhabited, when I bring the deep upon you, and great waters cover you,", 
                "num": 19
              }, 
              {
                "text": "then I will bring you down with those who descend into the Pit, to the people of old, and I will make you dwell in the lowest part of the earth, in places desolate from antiquity, with those who go down to the Pit, so that you may never be inhabited; and I shall establish glory in the land of the living.", 
                "num": 20
              }, 
              {
                "text": "I will make you a terror, and you shall be no more; though you are sought for, you will never be found again,' says the Lord GOD.\"", 
                "num": 21
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came again to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Now, son of man, take up a lamentation for Tyre,", 
                "num": 2
              }, 
              {
                "text": "and say to Tyre, \"You who are situated at the entrance of the sea, merchant of the peoples on many coastlands, thus says the Lord GOD: \"O Tyre, you have said, \"I am perfect in beauty.'", 
                "num": 3
              }, 
              {
                "text": "Your borders are in the midst of the seas. Your builders have perfected your beauty.", 
                "num": 4
              }, 
              {
                "text": "They made all your planks of fir trees from Senir; They took a cedar from Lebanon to make you a mast.", 
                "num": 5
              }, 
              {
                "text": "Of oaks from Bashan they made your oars; The company of Ashurites have inlaid your planks With ivory from the coasts of Cyprus.", 
                "num": 6
              }, 
              {
                "text": "Fine embroidered linen from Egypt was what you spread for your sail; Blue and purple from the coasts of Elishah was what covered you.", 
                "num": 7
              }, 
              {
                "text": "\"Inhabitants of Sidon and Arvad were your oarsmen; Your wise men, O Tyre, were in you; They became your pilots.", 
                "num": 8
              }, 
              {
                "text": "Elders of Gebal and its wise men Were in you to caulk your seams; All the ships of the sea And their oarsmen were in you To market your merchandise.", 
                "num": 9
              }, 
              {
                "text": "\"Those from Persia, Lydia, and Libya Were in your army as men of war; They hung shield and helmet in you; They gave splendor to you.", 
                "num": 10
              }, 
              {
                "text": "Men of Arvad with your army were on your walls all around, And the men of Gammad were in your towers; They hung their shields on your walls all around; They made your beauty perfect.", 
                "num": 11
              }, 
              {
                "text": "\"Tarshish was your merchant because of your many luxury goods. They gave you silver, iron, tin, and lead for your goods.", 
                "num": 12
              }, 
              {
                "text": "Javan, Tubal, and Meshech were your traders. They bartered human lives and vessels of bronze for your merchandise.", 
                "num": 13
              }, 
              {
                "text": "Those from the house of Togarmah traded for your wares with horses, steeds, and mules.", 
                "num": 14
              }, 
              {
                "text": "The men of Dedan were your traders; many isles were the market of your hand. They brought you ivory tusks and ebony as payment.", 
                "num": 15
              }, 
              {
                "text": "Syria was your merchant because of the abundance of goods you made. They gave you for your wares emeralds, purple, embroidery, fine linen, corals, and rubies.", 
                "num": 16
              }, 
              {
                "text": "Judah and the land of Israel were your traders. They traded for your merchandise wheat of Minnith, millet, honey, oil, and balm.", 
                "num": 17
              }, 
              {
                "text": "Damascus was your merchant because of the abundance of goods you made, because of your many luxury items, with the wine of Helbon and with white wool.", 
                "num": 18
              }, 
              {
                "text": "Dan and Javan paid for your wares, traversing back and forth. Wrought iron, cassia, and cane were among your merchandise.", 
                "num": 19
              }, 
              {
                "text": "Dedan was your merchant in saddlecloths for riding.", 
                "num": 20
              }, 
              {
                "text": "Arabia and all the princes of Kedar were your regular merchants. They traded with you in lambs, rams, and goats.", 
                "num": 21
              }, 
              {
                "text": "The merchants of Sheba and Raamah were your merchants. They traded for your wares the choicest spices, all kinds of precious stones, and gold.", 
                "num": 22
              }, 
              {
                "text": "Haran, Canneh, Eden, the merchants of Sheba, Assyria, and Chilmad were your merchants.", 
                "num": 23
              }, 
              {
                "text": "These were your merchants in choice items--in purple clothes, in embroidered garments, in chests of multicolored apparel, in sturdy woven cords, which were in your marketplace.", 
                "num": 24
              }, 
              {
                "text": "\"The ships of Tarshish were carriers of your merchandise. You were filled and very glorious in the midst of the seas.", 
                "num": 25
              }, 
              {
                "text": "Your oarsmen brought you into many waters, But the east wind broke you in the midst of the seas.", 
                "num": 26
              }, 
              {
                "text": "\"Your riches, wares, and merchandise, Your mariners and pilots, Your caulkers and merchandisers, All your men of war who are in you, And the entire company which is in your midst, Will fall into the midst of the seas on the day of your ruin.", 
                "num": 27
              }, 
              {
                "text": "The common-land will shake at the sound of the cry of your pilots.", 
                "num": 28
              }, 
              {
                "text": "\"All who handle the oar, The mariners, All the pilots of the sea Will come down from their ships and stand on the shore.", 
                "num": 29
              }, 
              {
                "text": "They will make their voice heard because of you; They will cry bitterly and cast dust on their heads; They will roll about in ashes;", 
                "num": 30
              }, 
              {
                "text": "They will shave themselves completely bald because of you, Gird themselves with sackcloth, And weep for you With bitterness of heart and bitter wailing.", 
                "num": 31
              }, 
              {
                "text": "In their wailing for you They will take up a lamentation, And lament for you: \"What city is like Tyre, Destroyed in the midst of the sea?", 
                "num": 32
              }, 
              {
                "text": "\"When your wares went out by sea, You satisfied many people; You enriched the kings of the earth With your many luxury goods and your merchandise.", 
                "num": 33
              }, 
              {
                "text": "But you are broken by the seas in the depths of the waters; Your merchandise and the entire company will fall in your midst.", 
                "num": 34
              }, 
              {
                "text": "All the inhabitants of the isles will be astonished at you; Their kings will be greatly afraid, And their countenance will be troubled.", 
                "num": 35
              }, 
              {
                "text": "The merchants among the peoples will hiss at you; You will become a horror, and be no more forever.\"\"\"", 
                "num": 36
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came to me again, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, say to the prince of Tyre, \"Thus says the Lord GOD: \"Because your heart is lifted up, And you say, \"I am a god, I sit in the seat of gods, In the midst of the seas,' Yet you are a man, and not a god, Though you set your heart as the heart of a god", 
                "num": 2
              }, 
              {
                "text": "(Behold, you are wiser than Daniel! There is no secret that can be hidden from you!", 
                "num": 3
              }, 
              {
                "text": "With your wisdom and your understanding You have gained riches for yourself, And gathered gold and silver into your treasuries;", 
                "num": 4
              }, 
              {
                "text": "By your great wisdom in trade you have increased your riches, And your heart is lifted up because of your riches),\"", 
                "num": 5
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Because you have set your heart as the heart of a god,", 
                "num": 6
              }, 
              {
                "text": "Behold, therefore, I will bring strangers against you, The most terrible of the nations; And they shall draw their swords against the beauty of your wisdom, And defile your splendor.", 
                "num": 7
              }, 
              {
                "text": "They shall throw you down into the Pit, And you shall die the death of the slain In the midst of the seas.", 
                "num": 8
              }, 
              {
                "text": "\"Will you still say before him who slays you, \"I am a god'? But you shall be a man, and not a god, In the hand of him who slays you.", 
                "num": 9
              }, 
              {
                "text": "You shall die the death of the uncircumcised By the hand of aliens; For I have spoken,\" says the Lord GOD.\"'", 
                "num": 10
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 11
              }, 
              {
                "text": "\"Son of man, take up a lamentation for the king of Tyre, and say to him, \"Thus says the Lord GOD: \"You were the seal of perfection, Full of wisdom and perfect in beauty.", 
                "num": 12
              }, 
              {
                "text": "You were in Eden, the garden of God; Every precious stone was your covering: The sardius, topaz, and diamond, Beryl, onyx, and jasper, Sapphire, turquoise, and emerald with gold. The workmanship of your timbrels and pipes Was prepared for you on the day you were created.", 
                "num": 13
              }, 
              {
                "text": "\"You were the anointed cherub who covers; I established you; You were on the holy mountain of God; You walked back and forth in the midst of fiery stones.", 
                "num": 14
              }, 
              {
                "text": "You were perfect in your ways from the day you were created, Till iniquity was found in you.", 
                "num": 15
              }, 
              {
                "text": "\"By the abundance of your trading You became filled with violence within, And you sinned; Therefore I cast you as a profane thing Out of the mountain of God; And I destroyed you, O covering cherub, From the midst of the fiery stones.", 
                "num": 16
              }, 
              {
                "text": "\"Your heart was lifted up because of your beauty; You corrupted your wisdom for the sake of your splendor; I cast you to the ground, I laid you before kings, That they might gaze at you.", 
                "num": 17
              }, 
              {
                "text": "\"You defiled your sanctuaries By the multitude of your iniquities, By the iniquity of your trading; Therefore I brought fire from your midst; It devoured you, And I turned you to ashes upon the earth In the sight of all who saw you.", 
                "num": 18
              }, 
              {
                "text": "All who knew you among the peoples are astonished at you; You have become a horror, And shall be no more forever.\"\"'", 
                "num": 19
              }, 
              {
                "text": "Then the word of the LORD came to me, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Son of man, set your face toward Sidon, and prophesy against her,", 
                "num": 21
              }, 
              {
                "text": "and say, \"Thus says the Lord GOD: \"Behold, I am against you, O Sidon; I will be glorified in your midst; And they shall know that I am the LORD, When I execute judgments in her and am hallowed in her.", 
                "num": 22
              }, 
              {
                "text": "For I will send pestilence upon her, And blood in her streets; The wounded shall be judged in her midst By the sword against her on every side; Then they shall know that I am the LORD.", 
                "num": 23
              }, 
              {
                "text": "\"And there shall no longer be a pricking brier or a painful thorn for the house of Israel from among all who are around them, who despise them. Then they shall know that I am the Lord GOD.\"", 
                "num": 24
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"When I have gathered the house of Israel from the peoples among whom they are scattered, and am hallowed in them in the sight of the Gentiles, then they will dwell in their own land which I gave to My servant Jacob.", 
                "num": 25
              }, 
              {
                "text": "And they will dwell safely there, build houses, and plant vineyards; yes, they will dwell securely, when I execute judgments on all those around them who despise them. Then they shall know that I am the LORD their God.\"\"'", 
                "num": 26
              }
            ], 
            "num": 28
          }, 
          {
            "verses": [
              {
                "text": "In the tenth year, in the tenth month, on the twelfth day of the month, the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face against Pharaoh king of Egypt, and prophesy against him, and against all Egypt.", 
                "num": 2
              }, 
              {
                "text": "Speak, and say, \"Thus says the Lord GOD: \"Behold, I am against you, O Pharaoh king of Egypt, O great monster who lies in the midst of his rivers, Who has said, \"My River is my own; I have made it for myself.'", 
                "num": 3
              }, 
              {
                "text": "But I will put hooks in your jaws, And cause the fish of your rivers to stick to your scales; I will bring you up out of the midst of your rivers, And all the fish in your rivers will stick to your scales.", 
                "num": 4
              }, 
              {
                "text": "I will leave you in the wilderness, You and all the fish of your rivers; You shall fall on the open field; You shall not be picked up or gathered. I have given you as food To the beasts of the field And to the birds of the heavens.", 
                "num": 5
              }, 
              {
                "text": "\"Then all the inhabitants of Egypt Shall know that I am the LORD, Because they have been a staff of reed to the house of Israel.", 
                "num": 6
              }, 
              {
                "text": "When they took hold of you with the hand, You broke and tore all their shoulders; When they leaned on you, You broke and made all their backs quiver.\"", 
                "num": 7
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Surely I will bring a sword upon you and cut off from you man and beast.", 
                "num": 8
              }, 
              {
                "text": "And the land of Egypt shall become desolate and waste; then they will know that I am the LORD, because he said, \"The River is mine, and I have made it.'", 
                "num": 9
              }, 
              {
                "text": "Indeed, therefore, I am against you and against your rivers, and I will make the land of Egypt utterly waste and desolate, from Migdol to Syene, as far as the border of Ethiopia.", 
                "num": 10
              }, 
              {
                "text": "Neither foot of man shall pass through it nor foot of beast pass through it, and it shall be uninhabited forty years.", 
                "num": 11
              }, 
              {
                "text": "I will make the land of Egypt desolate in the midst of the countries that are desolate; and among the cities that are laid waste, her cities shall be desolate forty years; and I will scatter the Egyptians among the nations and disperse them throughout the countries.\"", 
                "num": 12
              }, 
              {
                "text": "\"Yet, thus says the Lord GOD: \"At the end of forty years I will gather the Egyptians from the peoples among whom they were scattered.", 
                "num": 13
              }, 
              {
                "text": "I will bring back the captives of Egypt and cause them to return to the land of Pathros, to the land of their origin, and there they shall be a lowly kingdom.", 
                "num": 14
              }, 
              {
                "text": "It shall be the lowliest of kingdoms; it shall never again exalt itself above the nations, for I will diminish them so that they will not rule over the nations anymore.", 
                "num": 15
              }, 
              {
                "text": "No longer shall it be the confidence of the house of Israel, but will remind them of their iniquity when they turned to follow them. Then they shall know that I am the Lord GOD.\"\"'", 
                "num": 16
              }, 
              {
                "text": "And it came to pass in the twenty-seventh year, in the first month, on the first day of the month, that the word of the LORD came to me, saying,", 
                "num": 17
              }, 
              {
                "text": "\"Son of man, Nebuchadnezzar king of Babylon caused his army to labor strenuously against Tyre; every head was made bald, and every shoulder rubbed raw; yet neither he nor his army received wages from Tyre, for the labor which they expended on it.", 
                "num": 18
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Surely I will give the land of Egypt to Nebuchadnezzar king of Babylon; he shall take away her wealth, carry off her spoil, and remove her pillage; and that will be the wages for his army.", 
                "num": 19
              }, 
              {
                "text": "I have given him the land of Egypt for his labor, because they worked for Me,' says the Lord GOD.", 
                "num": 20
              }, 
              {
                "text": "\"In that day I will cause the horn of the house of Israel to spring forth, and I will open your mouth to speak in their midst. Then they shall know that I am the LORD.\"'", 
                "num": 21
              }
            ], 
            "num": 29
          }, 
          {
            "verses": [
              {
                "text": "The word of the LORD came to me again, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, prophesy and say, \"Thus says the Lord GOD: \"Wail, \"Woe to the day!'", 
                "num": 2
              }, 
              {
                "text": "For the day is near, Even the day of the LORD is near; It will be a day of clouds, the time of the Gentiles.", 
                "num": 3
              }, 
              {
                "text": "The sword shall come upon Egypt, And great anguish shall be in Ethiopia, When the slain fall in Egypt, And they take away her wealth, And her foundations are broken down.", 
                "num": 4
              }, 
              {
                "text": "\"Ethiopia, Libya, Lydia, all the mingled people, Chub, and the men of the lands who are allied, shall fall with them by the sword.\"", 
                "num": 5
              }, 
              {
                "text": "\"Thus says the LORD: \"Those who uphold Egypt shall fall, And the pride of her power shall come down. From Migdol to Syene Those within her shall fall by the sword,\" Says the Lord GOD.", 
                "num": 6
              }, 
              {
                "text": "\"They shall be desolate in the midst of the desolate countries, And her cities shall be in the midst of the cities that are laid waste.", 
                "num": 7
              }, 
              {
                "text": "Then they will know that I am the LORD, When I have set a fire in Egypt And all her helpers are destroyed.", 
                "num": 8
              }, 
              {
                "text": "On that day messengers shall go forth from Me in ships To make the careless Ethiopians afraid, And great anguish shall come upon them, As on the day of Egypt; For indeed it is coming!\"", 
                "num": 9
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"I will also make a multitude of Egypt to cease By the hand of Nebuchadnezzar king of Babylon.", 
                "num": 10
              }, 
              {
                "text": "He and his people with him, the most terrible of the nations, Shall be brought to destroy the land; They shall draw their swords against Egypt, And fill the land with the slain.", 
                "num": 11
              }, 
              {
                "text": "I will make the rivers dry, And sell the land into the hand of the wicked; I will make the land waste, and all that is in it, By the hand of aliens. I, the LORD, have spoken.\"", 
                "num": 12
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"I will also destroy the idols, And cause the images to cease from Noph; There shall no longer be princes from the land of Egypt; I will put fear in the land of Egypt.", 
                "num": 13
              }, 
              {
                "text": "I will make Pathros desolate, Set fire to Zoan, And execute judgments in No.", 
                "num": 14
              }, 
              {
                "text": "I will pour My fury on Sin, the strength of Egypt; I will cut off the multitude of No,", 
                "num": 15
              }, 
              {
                "text": "And set a fire in Egypt; Sin shall have great pain, No shall be split open, And Noph shall be in distress daily.", 
                "num": 16
              }, 
              {
                "text": "The young men of Aven and Pi Beseth shall fall by the sword, And these cities shall go into captivity.", 
                "num": 17
              }, 
              {
                "text": "At Tehaphnehes the day shall also be darkened, When I break the yokes of Egypt there. And her arrogant strength shall cease in her; As for her, a cloud shall cover her, And her daughters shall go into captivity.", 
                "num": 18
              }, 
              {
                "text": "Thus I will execute judgments on Egypt, Then they shall know that I am the LORD.\"\"'", 
                "num": 19
              }, 
              {
                "text": "And it came to pass in the eleventh year, in the first month, on the seventh day of the month, that the word of the LORD came to me, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Son of man, I have broken the arm of Pharaoh king of Egypt; and see, it has not been bandaged for healing, nor a splint put on to bind it, to make it strong enough to hold a sword.", 
                "num": 21
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"Surely I am against Pharaoh king of Egypt, and will break his arms, both the strong one and the one that was broken; and I will make the sword fall out of his hand.", 
                "num": 22
              }, 
              {
                "text": "I will scatter the Egyptians among the nations, and disperse them throughout the countries.", 
                "num": 23
              }, 
              {
                "text": "I will strengthen the arms of the king of Babylon and put My sword in his hand; but I will break Pharaoh's arms, and he will groan before him with the groanings of a mortally wounded man.", 
                "num": 24
              }, 
              {
                "text": "Thus I will strengthen the arms of the king of Babylon, but the arms of Pharaoh shall fall down; they shall know that I am the LORD, when I put My sword into the hand of the king of Babylon and he stretches it out against the land of Egypt.", 
                "num": 25
              }, 
              {
                "text": "I will scatter the Egyptians among the nations and disperse them throughout the countries. Then they shall know that I am the LORD.\"'", 
                "num": 26
              }
            ], 
            "num": 30
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass in the eleventh year, in the third month, on the first day of the month, that the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, say to Pharaoh king of Egypt and to his multitude: \"Whom are you like in your greatness?", 
                "num": 2
              }, 
              {
                "text": "Indeed Assyria was a cedar in Lebanon, With fine branches that shaded the forest, And of high stature; And its top was among the thick boughs.", 
                "num": 3
              }, 
              {
                "text": "The waters made it grow; Underground waters gave it height, With their rivers running around the place where it was planted, And sent out rivulets to all the trees of the field.", 
                "num": 4
              }, 
              {
                "text": "\"Therefore its height was exalted above all the trees of the field; Its boughs were multiplied, And its branches became long because of the abundance of water, As it sent them out.", 
                "num": 5
              }, 
              {
                "text": "All the birds of the heavens made their nests in its boughs; Under its branches all the beasts of the field brought forth their young; And in its shadow all great nations made their home.", 
                "num": 6
              }, 
              {
                "text": "\"Thus it was beautiful in greatness and in the length of its branches, Because its roots reached to abundant waters.", 
                "num": 7
              }, 
              {
                "text": "The cedars in the garden of God could not hide it; The fir trees were not like its boughs, And the chestnut trees were not like its branches; No tree in the garden of God was like it in beauty.", 
                "num": 8
              }, 
              {
                "text": "I made it beautiful with a multitude of branches, So that all the trees of Eden envied it, That were in the garden of God.'", 
                "num": 9
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Because you have increased in height, and it set its top among the thick boughs, and its heart was lifted up in its height,", 
                "num": 10
              }, 
              {
                "text": "therefore I will deliver it into the hand of the mighty one of the nations, and he shall surely deal with it; I have driven it out for its wickedness.", 
                "num": 11
              }, 
              {
                "text": "And aliens, the most terrible of the nations, have cut it down and left it; its branches have fallen on the mountains and in all the valleys; its boughs lie broken by all the rivers of the land; and all the peoples of the earth have gone from under its shadow and left it.", 
                "num": 12
              }, 
              {
                "text": "\"On its ruin will remain all the birds of the heavens, And all the beasts of the field will come to its branches--", 
                "num": 13
              }, 
              {
                "text": "\"So that no trees by the waters may ever again exalt themselves for their height, nor set their tops among the thick boughs, that no tree which drinks water may ever be high enough to reach up to them. \"For they have all been delivered to death, To the depths of the earth, Among the children of men who go down to the Pit.'", 
                "num": 14
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"In the day when it went down to hell, I caused mourning. I covered the deep because of it. I restrained its rivers, and the great waters were held back. I caused Lebanon to mourn for it, and all the trees of the field wilted because of it.", 
                "num": 15
              }, 
              {
                "text": "I made the nations shake at the sound of its fall, when I cast it down to hell together with those who descend into the Pit; and all the trees of Eden, the choice and best of Lebanon, all that drink water, were comforted in the depths of the earth.", 
                "num": 16
              }, 
              {
                "text": "They also went down to hell with it, with those slain by the sword; and those who were its strong arm dwelt in its shadows among the nations.", 
                "num": 17
              }, 
              {
                "text": "\"To which of the trees in Eden will you then be likened in glory and greatness? Yet you shall be brought down with the trees of Eden to the depths of the earth; you shall lie in the midst of the uncircumcised, with those slain by the sword. This is Pharaoh and all his multitude,' says the Lord GOD.\"", 
                "num": 18
              }
            ], 
            "num": 31
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in the twelfth year, in the twelfth month, on the first day of the month, that the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, take up a lamentation for Pharaoh king of Egypt, and say to him: \"You are like a young lion among the nations, And you are like a monster in the seas, Bursting forth in your rivers, Troubling the waters with your feet, And fouling their rivers.'", 
                "num": 2
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"I will therefore spread My net over you with a company of many people, And they will draw you up in My net.", 
                "num": 3
              }, 
              {
                "text": "Then I will leave you on the land; I will cast you out on the open fields, And cause to settle on you all the birds of the heavens. And with you I will fill the beasts of the whole earth.", 
                "num": 4
              }, 
              {
                "text": "I will lay your flesh on the mountains, And fill the valleys with your carcass.", 
                "num": 5
              }, 
              {
                "text": "\"I will also water the land with the flow of your blood, Even to the mountains; And the riverbeds will be full of you.", 
                "num": 6
              }, 
              {
                "text": "When I put out your light, I will cover the heavens, and make its stars dark; I will cover the sun with a cloud, And the moon shall not give her light.", 
                "num": 7
              }, 
              {
                "text": "All the bright lights of the heavens I will make dark over you, And bring darkness upon your land,' Says the Lord GOD.", 
                "num": 8
              }, 
              {
                "text": "\"I will also trouble the hearts of many peoples, when I bring your destruction among the nations, into the countries which you have not known.", 
                "num": 9
              }, 
              {
                "text": "Yes, I will make many peoples astonished at you, and their kings shall be horribly afraid of you when I brandish My sword before them; and they shall tremble every moment, every man for his own life, in the day of your fall.'", 
                "num": 10
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"The sword of the king of Babylon shall come upon you.", 
                "num": 11
              }, 
              {
                "text": "By the swords of the mighty warriors, all of them the most terrible of the nations, I will cause your multitude to fall. \"They shall plunder the pomp of Egypt, And all its multitude shall be destroyed.", 
                "num": 12
              }, 
              {
                "text": "Also I will destroy all its animals From beside its great waters; The foot of man shall muddy them no more, Nor shall the hooves of animals muddy them.", 
                "num": 13
              }, 
              {
                "text": "Then I will make their waters clear, And make their rivers run like oil,' Says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "\"When I make the land of Egypt desolate, And the country is destitute of all that once filled it, When I strike all who dwell in it, Then they shall know that I am the LORD.", 
                "num": 15
              }, 
              {
                "text": "\"This is the lamentation With which they shall lament her; The daughters of the nations shall lament her; They shall lament for her, for Egypt, And for all her multitude,' Says the Lord GOD.\"", 
                "num": 16
              }, 
              {
                "text": "It came to pass also in the twelfth year, on the fifteenth day of the month, that the word of the LORD came to me, saying:", 
                "num": 17
              }, 
              {
                "text": "\"Son of man, wail over the multitude of Egypt, And cast them down to the depths of the earth, Her and the daughters of the famous nations, With those who go down to the Pit:", 
                "num": 18
              }, 
              {
                "text": "\"Whom do you surpass in beauty? Go down, be placed with the uncircumcised.'", 
                "num": 19
              }, 
              {
                "text": "\"They shall fall in the midst of those slain by the sword; She is delivered to the sword, Drawing her and all her multitudes.", 
                "num": 20
              }, 
              {
                "text": "The strong among the mighty Shall speak to him out of the midst of hell With those who help him: \"They have gone down, They lie with the uncircumcised, slain by the sword.'", 
                "num": 21
              }, 
              {
                "text": "\"Assyria is there, and all her company, With their graves all around her, All of them slain, fallen by the sword.", 
                "num": 22
              }, 
              {
                "text": "Her graves are set in the recesses of the Pit, And her company is all around her grave, All of them slain, fallen by the sword, Who caused terror in the land of the living.", 
                "num": 23
              }, 
              {
                "text": "\"There is Elam and all her multitude, All around her grave, All of them slain, fallen by the sword, Who have gone down uncircumcised to the lower parts of the earth, Who caused their terror in the land of the living; Now they bear their shame with those who go down to the Pit.", 
                "num": 24
              }, 
              {
                "text": "They have set her bed in the midst of the slain, With all her multitude, With her graves all around it, All of them uncircumcised, slain by the sword; Though their terror was caused In the land of the living, Yet they bear their shame With those who go down to the Pit; It was put in the midst of the slain.", 
                "num": 25
              }, 
              {
                "text": "\"There are Meshech and Tubal and all their multitudes, With all their graves around it, All of them uncircumcised, slain by the sword, Though they caused their terror in the land of the living.", 
                "num": 26
              }, 
              {
                "text": "They do not lie with the mighty Who are fallen of the uncircumcised, Who have gone down to hell with their weapons of war; They have laid their swords under their heads, But their iniquities will be on their bones, Because of the terror of the mighty in the land of the living.", 
                "num": 27
              }, 
              {
                "text": "Yes, you shall be broken in the midst of the uncircumcised, And lie with those slain by the sword.", 
                "num": 28
              }, 
              {
                "text": "\"There is Edom, Her kings and all her princes, Who despite their might Are laid beside those slain by the sword; They shall lie with the uncircumcised, And with those who go down to the Pit.", 
                "num": 29
              }, 
              {
                "text": "There are the princes of the north, All of them, and all the Sidonians, Who have gone down with the slain In shame at the terror which they caused by their might; They lie uncircumcised with those slain by the sword, And bear their shame with those who go down to the Pit.", 
                "num": 30
              }, 
              {
                "text": "\"Pharaoh will see them And be comforted over all his multitude, Pharaoh and all his army, Slain by the sword,\" Says the Lord GOD.", 
                "num": 31
              }, 
              {
                "text": "\"For I have caused My terror in the land of the living; And he shall be placed in the midst of the uncircumcised With those slain by the sword, Pharaoh and all his multitude,\" Says the Lord GOD.", 
                "num": 32
              }
            ], 
            "num": 32
          }, 
          {
            "verses": [
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, speak to the children of your people, and say to them: \"When I bring the sword upon a land, and the people of the land take a man from their territory and make him their watchman,", 
                "num": 2
              }, 
              {
                "text": "when he sees the sword coming upon the land, if he blows the trumpet and warns the people,", 
                "num": 3
              }, 
              {
                "text": "then whoever hears the sound of the trumpet and does not take warning, if the sword comes and takes him away, his blood shall be on his own head.", 
                "num": 4
              }, 
              {
                "text": "He heard the sound of the trumpet, but did not take warning; his blood shall be upon himself. But he who takes warning will save his life.", 
                "num": 5
              }, 
              {
                "text": "But if the watchman sees the sword coming and does not blow the trumpet, and the people are not warned, and the sword comes and takes any person from among them, he is taken away in his iniquity; but his blood I will require at the watchman's hand.'", 
                "num": 6
              }, 
              {
                "text": "\"So you, son of man: I have made you a watchman for the house of Israel; therefore you shall hear a word from My mouth and warn them for Me.", 
                "num": 7
              }, 
              {
                "text": "When I say to the wicked, \"O wicked man, you shall surely die!' and you do not speak to warn the wicked from his way, that wicked man shall die in his iniquity; but his blood I will require at your hand.", 
                "num": 8
              }, 
              {
                "text": "Nevertheless if you warn the wicked to turn from his way, and he does not turn from his way, he shall die in his iniquity; but you have delivered your soul.", 
                "num": 9
              }, 
              {
                "text": "\"Therefore you, O son of man, say to the house of Israel: \"Thus you say, \"If our transgressions and our sins lie upon us, and we pine away in them, how can we then live?\"'", 
                "num": 10
              }, 
              {
                "text": "Say to them: \"As I live,' says the Lord GOD, \"I have no pleasure in the death of the wicked, but that the wicked turn from his way and live. Turn, turn from your evil ways! For why should you die, O house of Israel?'", 
                "num": 11
              }, 
              {
                "text": "\"Therefore you, O son of man, say to the children of your people: \"The righteousness of the righteous man shall not deliver him in the day of his transgression; as for the wickedness of the wicked, he shall not fall because of it in the day that he turns from his wickedness; nor shall the righteous be able to live because of his righteousness in the day that he sins.'", 
                "num": 12
              }, 
              {
                "text": "When I say to the righteous that he shall surely live, but he trusts in his own righteousness and commits iniquity, none of his righteous works shall be remembered; but because of the iniquity that he has committed, he shall die.", 
                "num": 13
              }, 
              {
                "text": "Again, when I say to the wicked, \"You shall surely die,' if he turns from his sin and does what is lawful and right,", 
                "num": 14
              }, 
              {
                "text": "if the wicked restores the pledge, gives back what he has stolen, and walks in the statutes of life without committing iniquity, he shall surely live; he shall not die.", 
                "num": 15
              }, 
              {
                "text": "None of his sins which he has committed shall be remembered against him; he has done what is lawful and right; he shall surely live.", 
                "num": 16
              }, 
              {
                "text": "\"Yet the children of your people say, \"The way of the LORD is not fair.' But it is their way which is not fair!", 
                "num": 17
              }, 
              {
                "text": "When the righteous turns from his righteousness and commits iniquity, he shall die because of it.", 
                "num": 18
              }, 
              {
                "text": "But when the wicked turns from his wickedness and does what is lawful and right, he shall live because of it.", 
                "num": 19
              }, 
              {
                "text": "Yet you say, \"The way of the LORD is not fair.' O house of Israel, I will judge every one of you according to his own ways.\"", 
                "num": 20
              }, 
              {
                "text": "And it came to pass in the twelfth year of our captivity, in the tenth month, on the fifth day of the month, that one who had escaped from Jerusalem came to me and said, \"The city has been captured!\"", 
                "num": 21
              }, 
              {
                "text": "Now the hand of the LORD had been upon me the evening before the man came who had escaped. And He had opened my mouth; so when he came to me in the morning, my mouth was opened, and I was no longer mute.", 
                "num": 22
              }, 
              {
                "text": "Then the word of the LORD came to me, saying:", 
                "num": 23
              }, 
              {
                "text": "\"Son of man, they who inhabit those ruins in the land of Israel are saying, \"Abraham was only one, and he inherited the land. But we are many; the land has been given to us as a possession.'", 
                "num": 24
              }, 
              {
                "text": "\"Therefore say to them, \"Thus says the Lord GOD: \"You eat meat with blood, you lift up your eyes toward your idols, and shed blood. Should you then possess the land?", 
                "num": 25
              }, 
              {
                "text": "You rely on your sword, you commit abominations, and you defile one another's wives. Should you then possess the land?\"'", 
                "num": 26
              }, 
              {
                "text": "\"Say thus to them, \"Thus says the Lord GOD: \"As I live, surely those who are in the ruins shall fall by the sword, and the one who is in the open field I will give to the beasts to be devoured, and those who are in the strongholds and caves shall die of the pestilence.", 
                "num": 27
              }, 
              {
                "text": "For I will make the land most desolate, her arrogant strength shall cease, and the mountains of Israel shall be so desolate that no one will pass through.", 
                "num": 28
              }, 
              {
                "text": "Then they shall know that I am the LORD, when I have made the land most desolate because of all their abominations which they have committed.\"'", 
                "num": 29
              }, 
              {
                "text": "\"As for you, son of man, the children of your people are talking about you beside the walls and in the doors of the houses; and they speak to one another, everyone saying to his brother, \"Please come and hear what the word is that comes from the LORD.'", 
                "num": 30
              }, 
              {
                "text": "So they come to you as people do, they sit before you as My people, and they hear your words, but they do not do them; for with their mouth they show much love, but their hearts pursue their own gain.", 
                "num": 31
              }, 
              {
                "text": "Indeed you are to them as a very lovely song of one who has a pleasant voice and can play well on an instrument; for they hear your words, but they do not do them.", 
                "num": 32
              }, 
              {
                "text": "And when this comes to pass--surely it will come--then they will know that a prophet has been among them.\"", 
                "num": 33
              }
            ], 
            "num": 33
          }, 
          {
            "verses": [
              {
                "text": "And the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, prophesy against the shepherds of Israel, prophesy and say to them, \"Thus says the Lord GOD to the shepherds: \"Woe to the shepherds of Israel who feed themselves! Should not the shepherds feed the flocks?", 
                "num": 2
              }, 
              {
                "text": "You eat the fat and clothe yourselves with the wool; you slaughter the fatlings, but you do not feed the flock.", 
                "num": 3
              }, 
              {
                "text": "The weak you have not strengthened, nor have you healed those who were sick, nor bound up the broken, nor brought back what was driven away, nor sought what was lost; but with force and cruelty you have ruled them.", 
                "num": 4
              }, 
              {
                "text": "So they were scattered because there was no shepherd; and they became food for all the beasts of the field when they were scattered.", 
                "num": 5
              }, 
              {
                "text": "My sheep wandered through all the mountains, and on every high hill; yes, My flock was scattered over the whole face of the earth, and no one was seeking or searching for them.\"", 
                "num": 6
              }, 
              {
                "text": "\"Therefore, you shepherds, hear the word of the LORD:", 
                "num": 7
              }, 
              {
                "text": "\"As I live,\" says the Lord GOD, \"surely because My flock became a prey, and My flock became food for every beast of the field, because there was no shepherd, nor did My shepherds search for My flock, but the shepherds fed themselves and did not feed My flock\"--", 
                "num": 8
              }, 
              {
                "text": "therefore, O shepherds, hear the word of the LORD!", 
                "num": 9
              }, 
              {
                "text": "Thus says the Lord GOD: \"Behold, I am against the shepherds, and I will require My flock at their hand; I will cause them to cease feeding the sheep, and the shepherds shall feed themselves no more; for I will deliver My flock from their mouths, that they may no longer be food for them.\"", 
                "num": 10
              }, 
              {
                "text": "\"For thus says the Lord GOD: \"Indeed I Myself will search for My sheep and seek them out.", 
                "num": 11
              }, 
              {
                "text": "As a shepherd seeks out his flock on the day he is among his scattered sheep, so will I seek out My sheep and deliver them from all the places where they were scattered on a cloudy and dark day.", 
                "num": 12
              }, 
              {
                "text": "And I will bring them out from the peoples and gather them from the countries, and will bring them to their own land; I will feed them on the mountains of Israel, in the valleys and in all the inhabited places of the country.", 
                "num": 13
              }, 
              {
                "text": "I will feed them in good pasture, and their fold shall be on the high mountains of Israel. There they shall lie down in a good fold and feed in rich pasture on the mountains of Israel.", 
                "num": 14
              }, 
              {
                "text": "I will feed My flock, and I will make them lie down,\" says the Lord GOD.", 
                "num": 15
              }, 
              {
                "text": "\"I will seek what was lost and bring back what was driven away, bind up the broken and strengthen what was sick; but I will destroy the fat and the strong, and feed them in judgment.\"", 
                "num": 16
              }, 
              {
                "text": "\"And as for you, O My flock, thus says the Lord GOD: \"Behold, I shall judge between sheep and sheep, between rams and goats.", 
                "num": 17
              }, 
              {
                "text": "Is it too little for you to have eaten up the good pasture, that you must tread down with your feet the residue of your pasture--and to have drunk of the clear waters, that you must foul the residue with your feet?", 
                "num": 18
              }, 
              {
                "text": "And as for My flock, they eat what you have trampled with your feet, and they drink what you have fouled with your feet.\"", 
                "num": 19
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD to them: \"Behold, I Myself will judge between the fat and the lean sheep.", 
                "num": 20
              }, 
              {
                "text": "Because you have pushed with side and shoulder, butted all the weak ones with your horns, and scattered them abroad,", 
                "num": 21
              }, 
              {
                "text": "therefore I will save My flock, and they shall no longer be a prey; and I will judge between sheep and sheep.", 
                "num": 22
              }, 
              {
                "text": "I will establish one shepherd over them, and he shall feed them--My servant David. He shall feed them and be their shepherd.", 
                "num": 23
              }, 
              {
                "text": "And I, the LORD, will be their God, and My servant David a prince among them; I, the LORD, have spoken.", 
                "num": 24
              }, 
              {
                "text": "\"I will make a covenant of peace with them, and cause wild beasts to cease from the land; and they will dwell safely in the wilderness and sleep in the woods.", 
                "num": 25
              }, 
              {
                "text": "I will make them and the places all around My hill a blessing; and I will cause showers to come down in their season; there shall be showers of blessing.", 
                "num": 26
              }, 
              {
                "text": "Then the trees of the field shall yield their fruit, and the earth shall yield her increase. They shall be safe in their land; and they shall know that I am the LORD, when I have broken the bands of their yoke and delivered them from the hand of those who enslaved them.", 
                "num": 27
              }, 
              {
                "text": "And they shall no longer be a prey for the nations, nor shall beasts of the land devour them; but they shall dwell safely, and no one shall make them afraid.", 
                "num": 28
              }, 
              {
                "text": "I will raise up for them a garden of renown, and they shall no longer be consumed with hunger in the land, nor bear the shame of the Gentiles anymore.", 
                "num": 29
              }, 
              {
                "text": "Thus they shall know that I, the LORD their God, am with them, and they, the house of Israel, are My people,\" says the Lord GOD.\"'", 
                "num": 30
              }, 
              {
                "text": "\"You are My flock, the flock of My pasture; you are men, and I am your God,\" says the Lord GOD.", 
                "num": 31
              }
            ], 
            "num": 34
          }, 
          {
            "verses": [
              {
                "text": "Moreover the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face against Mount Seir and prophesy against it,", 
                "num": 2
              }, 
              {
                "text": "and say to it, \"Thus says the Lord GOD: \"Behold, O Mount Seir, I am against you; I will stretch out My hand against you, And make you most desolate;", 
                "num": 3
              }, 
              {
                "text": "I shall lay your cities waste, And you shall be desolate. Then you shall know that I am the LORD.", 
                "num": 4
              }, 
              {
                "text": "\"Because you have had an ancient hatred, and have shed the blood of the children of Israel by the power of the sword at the time of their calamity, when their iniquity came to an end,", 
                "num": 5
              }, 
              {
                "text": "therefore, as I live,\" says the Lord GOD, \"I will prepare you for blood, and blood shall pursue you; since you have not hated blood, therefore blood shall pursue you.", 
                "num": 6
              }, 
              {
                "text": "Thus I will make Mount Seir most desolate, and cut off from it the one who leaves and the one who returns.", 
                "num": 7
              }, 
              {
                "text": "And I will fill its mountains with the slain; on your hills and in your valleys and in all your ravines those who are slain by the sword shall fall.", 
                "num": 8
              }, 
              {
                "text": "I will make you perpetually desolate, and your cities shall be uninhabited; then you shall know that I am the LORD.", 
                "num": 9
              }, 
              {
                "text": "\"Because you have said, \"These two nations and these two countries shall be mine, and we will possess them,' although the LORD was there,", 
                "num": 10
              }, 
              {
                "text": "therefore, as I live,\" says the Lord GOD, \"I will do according to your anger and according to the envy which you showed in your hatred against them; and I will make Myself known among them when I judge you.", 
                "num": 11
              }, 
              {
                "text": "Then you shall know that I am the LORD. I have heard all your blasphemies which you have spoken against the mountains of Israel, saying, \"They are desolate; they are given to us to consume.'", 
                "num": 12
              }, 
              {
                "text": "Thus with your mouth you have boasted against Me and multiplied your words against Me; I have heard them.\"", 
                "num": 13
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"The whole earth will rejoice when I make you desolate.", 
                "num": 14
              }, 
              {
                "text": "As you rejoiced because the inheritance of the house of Israel was desolate, so I will do to you; you shall be desolate, O Mount Seir, as well as all of Edom--all of it! Then they shall know that I am the LORD.\"'", 
                "num": 15
              }
            ], 
            "num": 35
          }, 
          {
            "verses": [
              {
                "text": "\"And you, son of man, prophesy to the mountains of Israel, and say, \"O mountains of Israel, hear the word of the LORD!", 
                "num": 1
              }, 
              {
                "text": "Thus says the Lord GOD: \"Because the enemy has said of you, \"Aha! The ancient heights have become our possession,\"\"", 
                "num": 2
              }, 
              {
                "text": "therefore prophesy, and say, \"Thus says the Lord GOD: \"Because they made you desolate and swallowed you up on every side, so that you became the possession of the rest of the nations, and you are taken up by the lips of talkers and slandered by the people\"--", 
                "num": 3
              }, 
              {
                "text": "therefore, O mountains of Israel, hear the word of the Lord GOD! Thus says the Lord GOD to the mountains, the hills, the rivers, the valleys, the desolate wastes, and the cities that have been forsaken, which became plunder and mockery to the rest of the nations all around--", 
                "num": 4
              }, 
              {
                "text": "therefore thus says the Lord GOD: \"Surely I have spoken in My burning jealousy against the rest of the nations and against all Edom, who gave My land to themselves as a possession, with wholehearted joy and spiteful minds, in order to plunder its open country.\"'", 
                "num": 5
              }, 
              {
                "text": "\"Therefore prophesy concerning the land of Israel, and say to the mountains, the hills, the rivers, and the valleys, \"Thus says the Lord GOD: \"Behold, I have spoken in My jealousy and My fury, because you have borne the shame of the nations.\"", 
                "num": 6
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"I have raised My hand in an oath that surely the nations that are around you shall bear their own shame.", 
                "num": 7
              }, 
              {
                "text": "But you, O mountains of Israel, you shall shoot forth your branches and yield your fruit to My people Israel, for they are about to come.", 
                "num": 8
              }, 
              {
                "text": "For indeed I am for you, and I will turn to you, and you shall be tilled and sown.", 
                "num": 9
              }, 
              {
                "text": "I will multiply men upon you, all the house of Israel, all of it; and the cities shall be inhabited and the ruins rebuilt.", 
                "num": 10
              }, 
              {
                "text": "I will multiply upon you man and beast; and they shall increase and bear young; I will make you inhabited as in former times, and do better for you than at your beginnings. Then you shall know that I am the LORD.", 
                "num": 11
              }, 
              {
                "text": "Yes, I will cause men to walk on you, My people Israel; they shall take possession of you, and you shall be their inheritance; no more shall you bereave them of children.\"", 
                "num": 12
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Because they say to you, \"You devour men and bereave your nation of children,'", 
                "num": 13
              }, 
              {
                "text": "therefore you shall devour men no more, nor bereave your nation anymore,\" says the Lord GOD.", 
                "num": 14
              }, 
              {
                "text": "Nor will I let you hear the taunts of the nations anymore, nor bear the reproach of the peoples anymore, nor shall you cause your nation to stumble anymore,\" says the Lord GOD.\"'", 
                "num": 15
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying:", 
                "num": 16
              }, 
              {
                "text": "\"Son of man, when the house of Israel dwelt in their own land, they defiled it by their own ways and deeds; to Me their way was like the uncleanness of a woman in her customary impurity.", 
                "num": 17
              }, 
              {
                "text": "Therefore I poured out My fury on them for the blood they had shed on the land, and for their idols with which they had defiled it.", 
                "num": 18
              }, 
              {
                "text": "So I scattered them among the nations, and they were dispersed throughout the countries; I judged them according to their ways and their deeds.", 
                "num": 19
              }, 
              {
                "text": "When they came to the nations, wherever they went, they profaned My holy name--when they said of them, \"These are the people of the LORD, and yet they have gone out of His land.'", 
                "num": 20
              }, 
              {
                "text": "But I had concern for My holy name, which the house of Israel had profaned among the nations wherever they went.", 
                "num": 21
              }, 
              {
                "text": "\"Therefore say to the house of Israel, \"Thus says the Lord GOD: \"I do not do this for your sake, O house of Israel, but for My holy name's sake, which you have profaned among the nations wherever you went.", 
                "num": 22
              }, 
              {
                "text": "And I will sanctify My great name, which has been profaned among the nations, which you have profaned in their midst; and the nations shall know that I am the LORD,\" says the Lord GOD, \"when I am hallowed in you before their eyes.", 
                "num": 23
              }, 
              {
                "text": "For I will take you from among the nations, gather you out of all countries, and bring you into your own land.", 
                "num": 24
              }, 
              {
                "text": "Then I will sprinkle clean water on you, and you shall be clean; I will cleanse you from all your filthiness and from all your idols.", 
                "num": 25
              }, 
              {
                "text": "I will give you a new heart and put a new spirit within you; I will take the heart of stone out of your flesh and give you a heart of flesh.", 
                "num": 26
              }, 
              {
                "text": "I will put My Spirit within you and cause you to walk in My statutes, and you will keep My judgments and do them.", 
                "num": 27
              }, 
              {
                "text": "Then you shall dwell in the land that I gave to your fathers; you shall be My people, and I will be your God.", 
                "num": 28
              }, 
              {
                "text": "I will deliver you from all your uncleannesses. I will call for the grain and multiply it, and bring no famine upon you.", 
                "num": 29
              }, 
              {
                "text": "And I will multiply the fruit of your trees and the increase of your fields, so that you need never again bear the reproach of famine among the nations.", 
                "num": 30
              }, 
              {
                "text": "Then you will remember your evil ways and your deeds that were not good; and you will loathe yourselves in your own sight, for your iniquities and your abominations.", 
                "num": 31
              }, 
              {
                "text": "Not for your sake do I do this,\" says the Lord GOD, \"let it be known to you. Be ashamed and confounded for your own ways, O house of Israel!\"", 
                "num": 32
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"On the day that I cleanse you from all your iniquities, I will also enable you to dwell in the cities, and the ruins shall be rebuilt.", 
                "num": 33
              }, 
              {
                "text": "The desolate land shall be tilled instead of lying desolate in the sight of all who pass by.", 
                "num": 34
              }, 
              {
                "text": "So they will say, \"This land that was desolate has become like the garden of Eden; and the wasted, desolate, and ruined cities are now fortified and inhabited.'", 
                "num": 35
              }, 
              {
                "text": "Then the nations which are left all around you shall know that I, the LORD, have rebuilt the ruined places and planted what was desolate. I, the LORD, have spoken it, and I will do it.\"", 
                "num": 36
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"I will also let the house of Israel inquire of Me to do this for them: I will increase their men like a flock.", 
                "num": 37
              }, 
              {
                "text": "Like a flock offered as holy sacrifices, like the flock at Jerusalem on its feast days, so shall the ruined cities be filled with flocks of men. Then they shall know that I am the LORD.\"\"'", 
                "num": 38
              }
            ], 
            "num": 36
          }, 
          {
            "verses": [
              {
                "text": "The hand of the LORD came upon me and brought me out in the Spirit of the LORD, and set me down in the midst of the valley; and it was full of bones.", 
                "num": 1
              }, 
              {
                "text": "Then He caused me to pass by them all around, and behold, there were very many in the open valley; and indeed they were very dry.", 
                "num": 2
              }, 
              {
                "text": "And He said to me, \"Son of man, can these bones live?\" So I answered, \"O Lord GOD, You know.\"", 
                "num": 3
              }, 
              {
                "text": "Again He said to me, \"Prophesy to these bones, and say to them, \"O dry bones, hear the word of the LORD!", 
                "num": 4
              }, 
              {
                "text": "Thus says the Lord GOD to these bones: \"Surely I will cause breath to enter into you, and you shall live.", 
                "num": 5
              }, 
              {
                "text": "I will put sinews on you and bring flesh upon you, cover you with skin and put breath in you; and you shall live. Then you shall know that I am the LORD.\"\"'", 
                "num": 6
              }, 
              {
                "text": "So I prophesied as I was commanded; and as I prophesied, there was a noise, and suddenly a rattling; and the bones came together, bone to bone.", 
                "num": 7
              }, 
              {
                "text": "Indeed, as I looked, the sinews and the flesh came upon them, and the skin covered them over; but there was no breath in them.", 
                "num": 8
              }, 
              {
                "text": "Also He said to me, \"Prophesy to the breath, prophesy, son of man, and say to the breath, \"Thus says the Lord GOD: \"Come from the four winds, O breath, and breathe on these slain, that they may live.\"\"'", 
                "num": 9
              }, 
              {
                "text": "So I prophesied as He commanded me, and breath came into them, and they lived, and stood upon their feet, an exceedingly great army.", 
                "num": 10
              }, 
              {
                "text": "Then He said to me, \"Son of man, these bones are the whole house of Israel. They indeed say, \"Our bones are dry, our hope is lost, and we ourselves are cut off!'", 
                "num": 11
              }, 
              {
                "text": "Therefore prophesy and say to them, \"Thus says the Lord GOD: \"Behold, O My people, I will open your graves and cause you to come up from your graves, and bring you into the land of Israel.", 
                "num": 12
              }, 
              {
                "text": "Then you shall know that I am the LORD, when I have opened your graves, O My people, and brought you up from your graves.", 
                "num": 13
              }, 
              {
                "text": "I will put My Spirit in you, and you shall live, and I will place you in your own land. Then you shall know that I, the LORD, have spoken it and performed it,\" says the LORD.\"'", 
                "num": 14
              }, 
              {
                "text": "Again the word of the LORD came to me, saying,", 
                "num": 15
              }, 
              {
                "text": "\"As for you, son of man, take a stick for yourself and write on it: \"For Judah and for the children of Israel, his companions.' Then take another stick and write on it, \"For Joseph, the stick of Ephraim, and for all the house of Israel, his companions.'", 
                "num": 16
              }, 
              {
                "text": "Then join them one to another for yourself into one stick, and they will become one in your hand.", 
                "num": 17
              }, 
              {
                "text": "\"And when the children of your people speak to you, saying, \"Will you not show us what you mean by these?'--", 
                "num": 18
              }, 
              {
                "text": "say to them, \"Thus says the Lord GOD: \"Surely I will take the stick of Joseph, which is in the hand of Ephraim, and the tribes of Israel, his companions; and I will join them with it, with the stick of Judah, and make them one stick, and they will be one in My hand.\"'", 
                "num": 19
              }, 
              {
                "text": "And the sticks on which you write will be in your hand before their eyes.", 
                "num": 20
              }, 
              {
                "text": "\"Then say to them, \"Thus says the Lord GOD: \"Surely I will take the children of Israel from among the nations, wherever they have gone, and will gather them from every side and bring them into their own land;", 
                "num": 21
              }, 
              {
                "text": "and I will make them one nation in the land, on the mountains of Israel; and one king shall be king over them all; they shall no longer be two nations, nor shall they ever be divided into two kingdoms again.", 
                "num": 22
              }, 
              {
                "text": "They shall not defile themselves anymore with their idols, nor with their detestable things, nor with any of their transgressions; but I will deliver them from all their dwelling places in which they have sinned, and will cleanse them. Then they shall be My people, and I will be their God.", 
                "num": 23
              }, 
              {
                "text": "\"David My servant shall be king over them, and they shall all have one shepherd; they shall also walk in My judgments and observe My statutes, and do them.", 
                "num": 24
              }, 
              {
                "text": "Then they shall dwell in the land that I have given to Jacob My servant, where your fathers dwelt; and they shall dwell there, they, their children, and their children's children, forever; and My servant David shall be their prince forever.", 
                "num": 25
              }, 
              {
                "text": "Moreover I will make a covenant of peace with them, and it shall be an everlasting covenant with them; I will establish them and multiply them, and I will set My sanctuary in their midst forevermore.", 
                "num": 26
              }, 
              {
                "text": "My tabernacle also shall be with them; indeed I will be their God, and they shall be My people.", 
                "num": 27
              }, 
              {
                "text": "The nations also will know that I, the LORD, sanctify Israel, when My sanctuary is in their midst forevermore.\"\"'", 
                "num": 28
              }
            ], 
            "num": 37
          }, 
          {
            "verses": [
              {
                "text": "Now the word of the LORD came to me, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Son of man, set your face against Gog, of the land of Magog, the prince of Rosh, Meshech, and Tubal, and prophesy against him,", 
                "num": 2
              }, 
              {
                "text": "and say, \"Thus says the Lord GOD: \"Behold, I am against you, O Gog, the prince of Rosh, Meshech, and Tubal.", 
                "num": 3
              }, 
              {
                "text": "I will turn you around, put hooks into your jaws, and lead you out, with all your army, horses, and horsemen, all splendidly clothed, a great company with bucklers and shields, all of them handling swords.", 
                "num": 4
              }, 
              {
                "text": "Persia, Ethiopia, and Libya are with them, all of them with shield and helmet;", 
                "num": 5
              }, 
              {
                "text": "Gomer and all its troops; the house of Togarmah from the far north and all its troops--many people are with you.", 
                "num": 6
              }, 
              {
                "text": "\"Prepare yourself and be ready, you and all your companies that are gathered about you; and be a guard for them.", 
                "num": 7
              }, 
              {
                "text": "After many days you will be visited. In the latter years you will come into the land of those brought back from the sword and gathered from many people on the mountains of Israel, which had long been desolate; they were brought out of the nations, and now all of them dwell safely.", 
                "num": 8
              }, 
              {
                "text": "You will ascend, coming like a storm, covering the land like a cloud, you and all your troops and many peoples with you.\"", 
                "num": 9
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"On that day it shall come to pass that thoughts will arise in your mind, and you will make an evil plan:", 
                "num": 10
              }, 
              {
                "text": "You will say, \"I will go up against a land of unwalled villages; I will go to a peaceful people, who dwell safely, all of them dwelling without walls, and having neither bars nor gates'--", 
                "num": 11
              }, 
              {
                "text": "to take plunder and to take booty, to stretch out your hand against the waste places that are again inhabited, and against a people gathered from the nations, who have acquired livestock and goods, who dwell in the midst of the land.", 
                "num": 12
              }, 
              {
                "text": "Sheba, Dedan, the merchants of Tarshish, and all their young lions will say to you, \"Have you come to take plunder? Have you gathered your army to take booty, to carry away silver and gold, to take away livestock and goods, to take great plunder?\"\"", 
                "num": 13
              }, 
              {
                "text": "\"Therefore, son of man, prophesy and say to Gog, \"Thus says the Lord GOD: \"On that day when My people Israel dwell safely, will you not know it?", 
                "num": 14
              }, 
              {
                "text": "Then you will come from your place out of the far north, you and many peoples with you, all of them riding on horses, a great company and a mighty army.", 
                "num": 15
              }, 
              {
                "text": "You will come up against My people Israel like a cloud, to cover the land. It will be in the latter days that I will bring you against My land, so that the nations may know Me, when I am hallowed in you, O Gog, before their eyes.\"", 
                "num": 16
              }, 
              {
                "text": "Thus says the Lord GOD: \"Are you he of whom I have spoken in former days by My servants the prophets of Israel, who prophesied for years in those days that I would bring you against them?", 
                "num": 17
              }, 
              {
                "text": "\"And it will come to pass at the same time, when Gog comes against the land of Israel,\" says the Lord GOD, \"that My fury will show in My face.", 
                "num": 18
              }, 
              {
                "text": "For in My jealousy and in the fire of My wrath I have spoken: \"Surely in that day there shall be a great earthquake in the land of Israel,", 
                "num": 19
              }, 
              {
                "text": "so that the fish of the sea, the birds of the heavens, the beasts of the field, all creeping things that creep on the earth, and all men who are on the face of the earth shall shake at My presence. The mountains shall be thrown down, the steep places shall fall, and every wall shall fall to the ground.'", 
                "num": 20
              }, 
              {
                "text": "I will call for a sword against Gog throughout all My mountains,\" says the Lord GOD. \"Every man's sword will be against his brother.", 
                "num": 21
              }, 
              {
                "text": "And I will bring him to judgment with pestilence and bloodshed; I will rain down on him, on his troops, and on the many peoples who are with him, flooding rain, great hailstones, fire, and brimstone.", 
                "num": 22
              }, 
              {
                "text": "Thus I will magnify Myself and sanctify Myself, and I will be known in the eyes of many nations. Then they shall know that I am the LORD.\"'", 
                "num": 23
              }
            ], 
            "num": 38
          }, 
          {
            "verses": [
              {
                "text": "\"And you, son of man, prophesy against Gog, and say, \"Thus says the Lord GOD: \"Behold, I am against you, O Gog, the prince of Rosh, Meshech, and Tubal;", 
                "num": 1
              }, 
              {
                "text": "and I will turn you around and lead you on, bringing you up from the far north, and bring you against the mountains of Israel.", 
                "num": 2
              }, 
              {
                "text": "Then I will knock the bow out of your left hand, and cause the arrows to fall out of your right hand.", 
                "num": 3
              }, 
              {
                "text": "You shall fall upon the mountains of Israel, you and all your troops and the peoples who are with you; I will give you to birds of prey of every sort and to the beasts of the field to be devoured.", 
                "num": 4
              }, 
              {
                "text": "You shall fall on the open field; for I have spoken,\" says the Lord GOD.", 
                "num": 5
              }, 
              {
                "text": "\"And I will send fire on Magog and on those who live in security in the coastlands. Then they shall know that I am the LORD.", 
                "num": 6
              }, 
              {
                "text": "So I will make My holy name known in the midst of My people Israel, and I will not let them profane My holy name anymore. Then the nations shall know that I am the LORD, the Holy One in Israel.", 
                "num": 7
              }, 
              {
                "text": "Surely it is coming, and it shall be done,\" says the Lord GOD. \"This is the day of which I have spoken.", 
                "num": 8
              }, 
              {
                "text": "\"Then those who dwell in the cities of Israel will go out and set on fire and burn the weapons, both the shields and bucklers, the bows and arrows, the javelins and spears; and they will make fires with them for seven years.", 
                "num": 9
              }, 
              {
                "text": "They will not take wood from the field nor cut down any from the forests, because they will make fires with the weapons; and they will plunder those who plundered them, and pillage those who pillaged them,\" says the Lord GOD.", 
                "num": 10
              }, 
              {
                "text": "\"It will come to pass in that day that I will give Gog a burial place there in Israel, the valley of those who pass by east of the sea; and it will obstruct travelers, because there they will bury Gog and all his multitude. Therefore they will call it the Valley of Hamon Gog.", 
                "num": 11
              }, 
              {
                "text": "For seven months the house of Israel will be burying them, in order to cleanse the land.", 
                "num": 12
              }, 
              {
                "text": "Indeed all the people of the land will be burying, and they will gain renown for it on the day that I am glorified,\" says the Lord GOD.", 
                "num": 13
              }, 
              {
                "text": "\"They will set apart men regularly employed, with the help of a search party, to pass through the land and bury those bodies remaining on the ground, in order to cleanse it. At the end of seven months they will make a search.", 
                "num": 14
              }, 
              {
                "text": "The search party will pass through the land; and when anyone sees a man's bone, he shall set up a marker by it, till the buriers have buried it in the Valley of Hamon Gog.", 
                "num": 15
              }, 
              {
                "text": "The name of the city will also be Hamonah. Thus they shall cleanse the land.\"'", 
                "num": 16
              }, 
              {
                "text": "\"And as for you, son of man, thus says the Lord GOD, \"Speak to every sort of bird and to every beast of the field: \"Assemble yourselves and come; Gather together from all sides to My sacrificial meal Which I am sacrificing for you, A great sacrificial meal on the mountains of Israel, That you may eat flesh and drink blood.", 
                "num": 17
              }, 
              {
                "text": "You shall eat the flesh of the mighty, Drink the blood of the princes of the earth, Of rams and lambs, Of goats and bulls, All of them fatlings of Bashan.", 
                "num": 18
              }, 
              {
                "text": "You shall eat fat till you are full, And drink blood till you are drunk, At My sacrificial meal Which I am sacrificing for you.", 
                "num": 19
              }, 
              {
                "text": "You shall be filled at My table With horses and riders, With mighty men And with all the men of war,\" says the Lord GOD.", 
                "num": 20
              }, 
              {
                "text": "\"I will set My glory among the nations; all the nations shall see My judgment which I have executed, and My hand which I have laid on them.", 
                "num": 21
              }, 
              {
                "text": "So the house of Israel shall know that I am the LORD their God from that day forward.", 
                "num": 22
              }, 
              {
                "text": "The Gentiles shall know that the house of Israel went into captivity for their iniquity; because they were unfaithful to Me, therefore I hid My face from them. I gave them into the hand of their enemies, and they all fell by the sword.", 
                "num": 23
              }, 
              {
                "text": "According to their uncleanness and according to their transgressions I have dealt with them, and hidden My face from them.\"'", 
                "num": 24
              }, 
              {
                "text": "\"Therefore thus says the Lord GOD: \"Now I will bring back the captives of Jacob, and have mercy on the whole house of Israel; and I will be jealous for My holy name--", 
                "num": 25
              }, 
              {
                "text": "after they have borne their shame, and all their unfaithfulness in which they were unfaithful to Me, when they dwelt safely in their own land and no one made them afraid.", 
                "num": 26
              }, 
              {
                "text": "When I have brought them back from the peoples and gathered them out of their enemies' lands, and I am hallowed in them in the sight of many nations,", 
                "num": 27
              }, 
              {
                "text": "then they shall know that I am the LORD their God, who sent them into captivity among the nations, but also brought them back to their land, and left none of them captive any longer.", 
                "num": 28
              }, 
              {
                "text": "And I will not hide My face from them anymore; for I shall have poured out My Spirit on the house of Israel,' says the Lord GOD.\"", 
                "num": 29
              }
            ], 
            "num": 39
          }, 
          {
            "verses": [
              {
                "text": "In the twenty-fifth year of our captivity, at the beginning of the year, on the tenth day of the month, in the fourteenth year after the city was captured, on the very same day the hand of the LORD was upon me; and He took me there.", 
                "num": 1
              }, 
              {
                "text": "In the visions of God He took me into the land of Israel and set me on a very high mountain; on it toward the south was something like the structure of a city.", 
                "num": 2
              }, 
              {
                "text": "He took me there, and behold, there was a man whose appearance was like the appearance of bronze. He had a line of flax and a measuring rod in his hand, and he stood in the gateway.", 
                "num": 3
              }, 
              {
                "text": "And the man said to me, \"Son of man, look with your eyes and hear with your ears, and fix your mind on everything I show you; for you were brought here so that I might show them to you. Declare to the house of Israel everything you see.\"", 
                "num": 4
              }, 
              {
                "text": "Now there was a wall all around the outside of the temple. In the man's hand was a measuring rod six cubits long, each being a cubit and a handbreadth; and he measured the width of the wall structure, one rod; and the height, one rod.", 
                "num": 5
              }, 
              {
                "text": "Then he went to the gateway which faced east; and he went up its stairs and measured the threshold of the gateway, which was one rod wide, and the other threshold was one rod wide.", 
                "num": 6
              }, 
              {
                "text": "Each gate chamber was one rod long and one rod wide; between the gate chambers was a space of five cubits; and the threshold of the gateway by the vestibule of the inside gate was one rod.", 
                "num": 7
              }, 
              {
                "text": "He also measured the vestibule of the inside gate, one rod.", 
                "num": 8
              }, 
              {
                "text": "Then he measured the vestibule of the gateway, eight cubits; and the gateposts, two cubits. The vestibule of the gate was on the inside.", 
                "num": 9
              }, 
              {
                "text": "In the eastern gateway were three gate chambers on one side and three on the other; the three were all the same size; also the gateposts were of the same size on this side and that side.", 
                "num": 10
              }, 
              {
                "text": "He measured the width of the entrance to the gateway, ten cubits; and the length of the gate, thirteen cubits.", 
                "num": 11
              }, 
              {
                "text": "There was a space in front of the gate chambers, one cubit on this side and one cubit on that side; the gate chambers were six cubits on this side and six cubits on that side.", 
                "num": 12
              }, 
              {
                "text": "Then he measured the gateway from the roof of one gate chamber to the roof of the other; the width was twenty-five cubits, as door faces door.", 
                "num": 13
              }, 
              {
                "text": "He measured the gateposts, sixty cubits high, and the court all around the gateway extended to the gatepost.", 
                "num": 14
              }, 
              {
                "text": "From the front of the entrance gate to the front of the vestibule of the inner gate was fifty cubits.", 
                "num": 15
              }, 
              {
                "text": "There were beveled window frames in the gate chambers and in their intervening archways on the inside of the gateway all around, and likewise in the vestibules. There were windows all around on the inside. And on each gatepost were palm trees.", 
                "num": 16
              }, 
              {
                "text": "Then he brought me into the outer court; and there were chambers and a pavement made all around the court; thirty chambers faced the pavement.", 
                "num": 17
              }, 
              {
                "text": "The pavement was by the side of the gateways, corresponding to the length of the gateways; this was the lower pavement.", 
                "num": 18
              }, 
              {
                "text": "Then he measured the width from the front of the lower gateway to the front of the inner court exterior, one hundred cubits toward the east and the north.", 
                "num": 19
              }, 
              {
                "text": "On the outer court was also a gateway facing north, and he measured its length and its width.", 
                "num": 20
              }, 
              {
                "text": "Its gate chambers, three on this side and three on that side, its gateposts and its archways, had the same measurements as the first gate; its length was fifty cubits and its width twenty-five cubits.", 
                "num": 21
              }, 
              {
                "text": "Its windows and those of its archways, and also its palm trees, had the same measurements as the gateway facing east; it was ascended by seven steps, and its archway was in front of it.", 
                "num": 22
              }, 
              {
                "text": "A gate of the inner court was opposite the northern gateway, just as the eastern gateway; and he measured from gateway to gateway, one hundred cubits.", 
                "num": 23
              }, 
              {
                "text": "After that he brought me toward the south, and there a gateway was facing south; and he measured its gateposts and archways according to these same measurements.", 
                "num": 24
              }, 
              {
                "text": "There were windows in it and in its archways all around like those windows; its length was fifty cubits and its width twenty-five cubits.", 
                "num": 25
              }, 
              {
                "text": "Seven steps led up to it, and its archway was in front of them; and it had palm trees on its gateposts, one on this side and one on that side.", 
                "num": 26
              }, 
              {
                "text": "There was also a gateway on the inner court, facing south; and he measured from gateway to gateway toward the south, one hundred cubits.", 
                "num": 27
              }, 
              {
                "text": "Then he brought me to the inner court through the southern gateway; he measured the southern gateway according to these same measurements.", 
                "num": 28
              }, 
              {
                "text": "Also its gate chambers, its gateposts, and its archways were according to these same measurements; there were windows in it and in its archways all around; it was fifty cubits long and twenty-five cubits wide.", 
                "num": 29
              }, 
              {
                "text": "There were archways all around, twenty-five cubits long and five cubits wide.", 
                "num": 30
              }, 
              {
                "text": "Its archways faced the outer court, palm trees were on its gateposts, and going up to it were eight steps.", 
                "num": 31
              }, 
              {
                "text": "And he brought me into the inner court facing east; he measured the gateway according to these same measurements.", 
                "num": 32
              }, 
              {
                "text": "Also its gate chambers, its gateposts, and its archways were according to these same measurements; and there were windows in it and in its archways all around; it was fifty cubits long and twenty-five cubits wide.", 
                "num": 33
              }, 
              {
                "text": "Its archways faced the outer court, and palm trees were on its gateposts on this side and on that side; and going up to it were eight steps.", 
                "num": 34
              }, 
              {
                "text": "Then he brought me to the north gateway and measured it according to these same measurements--", 
                "num": 35
              }, 
              {
                "text": "also its gate chambers, its gateposts, and its archways. It had windows all around; its length was fifty cubits and its width twenty-five cubits.", 
                "num": 36
              }, 
              {
                "text": "Its gateposts faced the outer court, palm trees were on its gateposts on this side and on that side, and going up to it were eight steps.", 
                "num": 37
              }, 
              {
                "text": "There was a chamber and its entrance by the gateposts of the gateway, where they washed the burnt offering.", 
                "num": 38
              }, 
              {
                "text": "In the vestibule of the gateway were two tables on this side and two tables on that side, on which to slay the burnt offering, the sin offering, and the trespass offering.", 
                "num": 39
              }, 
              {
                "text": "At the outer side of the vestibule, as one goes up to the entrance of the northern gateway, were two tables; and on the other side of the vestibule of the gateway were two tables.", 
                "num": 40
              }, 
              {
                "text": "Four tables were on this side and four tables on that side, by the side of the gateway, eight tables on which they slaughtered the sacrifices.", 
                "num": 41
              }, 
              {
                "text": "There were also four tables of hewn stone for the burnt offering, one cubit and a half long, one cubit and a half wide, and one cubit high; on these they laid the instruments with which they slaughtered the burnt offering and the sacrifice.", 
                "num": 42
              }, 
              {
                "text": "Inside were hooks, a handbreadth wide, fastened all around; and the flesh of the sacrifices was on the tables.", 
                "num": 43
              }, 
              {
                "text": "Outside the inner gate were the chambers for the singers in the inner court, one facing south at the side of the northern gateway, and the other facing north at the side of the southern gateway.", 
                "num": 44
              }, 
              {
                "text": "Then he said to me, \"This chamber which faces south is for the priests who have charge of the temple.", 
                "num": 45
              }, 
              {
                "text": "The chamber which faces north is for the priests who have charge of the altar; these are the sons of Zadok, from the sons of Levi, who come near the LORD to minister to Him.\"", 
                "num": 46
              }, 
              {
                "text": "And he measured the court, one hundred cubits long and one hundred cubits wide, foursquare. The altar was in front of the temple.", 
                "num": 47
              }, 
              {
                "text": "Then he brought me to the vestibule of the temple and measured the doorposts of the vestibule, five cubits on this side and five cubits on that side; and the width of the gateway was three cubits on this side and three cubits on that side.", 
                "num": 48
              }, 
              {
                "text": "The length of the vestibule was twenty cubits, and the width eleven cubits; and by the steps which led up to it there were pillars by the doorposts, one on this side and another on that side.", 
                "num": 49
              }
            ], 
            "num": 40
          }, 
          {
            "verses": [
              {
                "text": "Then he brought me into the sanctuary and measured the doorposts, six cubits wide on one side and six cubits wide on the other side--the width of the tabernacle.", 
                "num": 1
              }, 
              {
                "text": "The width of the entryway was ten cubits, and the side walls of the entrance were five cubits on this side and five cubits on the other side; and he measured its length, forty cubits, and its width, twenty cubits.", 
                "num": 2
              }, 
              {
                "text": "Also he went inside and measured the doorposts, two cubits; and the entrance, six cubits high; and the width of the entrance, seven cubits.", 
                "num": 3
              }, 
              {
                "text": "He measured the length, twenty cubits; and the width, twenty cubits, beyond the sanctuary; and he said to me, \"This is the Most Holy Place.\"", 
                "num": 4
              }, 
              {
                "text": "Next, he measured the wall of the temple, six cubits. The width of each side chamber all around the temple was four cubits on every side.", 
                "num": 5
              }, 
              {
                "text": "The side chambers were in three stories, one above the other, thirty chambers in each story; they rested on ledges which were for the side chambers all around, that they might be supported, but not fastened to the wall of the temple.", 
                "num": 6
              }, 
              {
                "text": "As one went up from story to story, the side chambers became wider all around, because their supporting ledges in the wall of the temple ascended like steps; therefore the width of the structure increased as one went up from the lowest story to the highest by way of the middle one.", 
                "num": 7
              }, 
              {
                "text": "I also saw an elevation all around the temple; it was the foundation of the side chambers, a full rod, that is, six cubits high.", 
                "num": 8
              }, 
              {
                "text": "The thickness of the outer wall of the side chambers was five cubits, and so also the remaining terrace by the place of the side chambers of the temple.", 
                "num": 9
              }, 
              {
                "text": "And between it and the wall chambers was a width of twenty cubits all around the temple on every side.", 
                "num": 10
              }, 
              {
                "text": "The doors of the side chambers opened on the terrace, one door toward the north and another toward the south; and the width of the terrace was five cubits all around.", 
                "num": 11
              }, 
              {
                "text": "The building that faced the separating courtyard at its western end was seventy cubits wide; the wall of the building was five cubits thick all around, and its length ninety cubits.", 
                "num": 12
              }, 
              {
                "text": "So he measured the temple, one hundred cubits long; and the separating courtyard with the building and its walls was one hundred cubits long;", 
                "num": 13
              }, 
              {
                "text": "also the width of the eastern face of the temple, including the separating courtyard, was one hundred cubits.", 
                "num": 14
              }, 
              {
                "text": "He measured the length of the building behind it, facing the separating courtyard, with its galleries on the one side and on the other side, one hundred cubits, as well as the inner temple and the porches of the court,", 
                "num": 15
              }, 
              {
                "text": "their doorposts and the beveled window frames. And the galleries all around their three stories opposite the threshold were paneled with wood from the ground to the windows--the windows were covered--", 
                "num": 16
              }, 
              {
                "text": "from the space above the door, even to the inner room, as well as outside, and on every wall all around, inside and outside, by measure.", 
                "num": 17
              }, 
              {
                "text": "And it was made with cherubim and palm trees, a palm tree between cherub and cherub. Each cherub had two faces,", 
                "num": 18
              }, 
              {
                "text": "so that the face of a man was toward a palm tree on one side, and the face of a young lion toward a palm tree on the other side; thus it was made throughout the temple all around.", 
                "num": 19
              }, 
              {
                "text": "From the floor to the space above the door, and on the wall of the sanctuary, cherubim and palm trees were carved.", 
                "num": 20
              }, 
              {
                "text": "The doorposts of the temple were square, as was the front of the sanctuary; their appearance was similar.", 
                "num": 21
              }, 
              {
                "text": "The altar was of wood, three cubits high, and its length two cubits. Its corners, its length, and its sides were of wood; and he said to me, \"This is the table that is before the LORD.\"", 
                "num": 22
              }, 
              {
                "text": "The temple and the sanctuary had two doors.", 
                "num": 23
              }, 
              {
                "text": "The doors had two panels apiece, two folding panels: two panels for one door and two panels for the other door.", 
                "num": 24
              }, 
              {
                "text": "Cherubim and palm trees were carved on the doors of the temple just as they were carved on the walls. A wooden canopy was on the front of the vestibule outside.", 
                "num": 25
              }, 
              {
                "text": "There were beveled window frames and palm trees on one side and on the other, on the sides of the vestibule--also on the side chambers of the temple and on the canopies.", 
                "num": 26
              }
            ], 
            "num": 41
          }, 
          {
            "verses": [
              {
                "text": "Then he brought me out into the outer court, by the way toward the north; and he brought me into the chamber which was opposite the separating courtyard, and which was opposite the building toward the north.", 
                "num": 1
              }, 
              {
                "text": "Facing the length, which was one hundred cubits (the width was fifty cubits), was the north door.", 
                "num": 2
              }, 
              {
                "text": "Opposite the inner court of twenty cubits, and opposite the pavement of the outer court, was gallery against gallery in three stories.", 
                "num": 3
              }, 
              {
                "text": "In front of the chambers, toward the inside, was a walk ten cubits wide, at a distance of one cubit; and their doors faced north.", 
                "num": 4
              }, 
              {
                "text": "Now the upper chambers were shorter, because the galleries took away space from them more than from the lower and middle stories of the building.", 
                "num": 5
              }, 
              {
                "text": "For they were in three stories and did not have pillars like the pillars of the courts; therefore the upper level was shortened more than the lower and middle levels from the ground up.", 
                "num": 6
              }, 
              {
                "text": "And a wall which was outside ran parallel to the chambers, at the front of the chambers, toward the outer court; its length was fifty cubits.", 
                "num": 7
              }, 
              {
                "text": "The length of the chambers toward the outer court was fifty cubits, whereas that facing the temple was one hundred cubits.", 
                "num": 8
              }, 
              {
                "text": "At the lower chambers was the entrance on the east side, as one goes into them from the outer court.", 
                "num": 9
              }, 
              {
                "text": "Also there were chambers in the thickness of the wall of the court toward the east, opposite the separating courtyard and opposite the building.", 
                "num": 10
              }, 
              {
                "text": "There was a walk in front of them also, and their appearance was like the chambers which were toward the north; they were as long and as wide as the others, and all their exits and entrances were according to plan.", 
                "num": 11
              }, 
              {
                "text": "And corresponding to the doors of the chambers that were facing south, as one enters them, there was a door in front of the walk, the way directly in front of the wall toward the east.", 
                "num": 12
              }, 
              {
                "text": "Then he said to me, \"The north chambers and the south chambers, which are opposite the separating courtyard, are the holy chambers where the priests who approach the LORD shall eat the most holy offerings. There they shall lay the most holy offerings--the grain offering, the sin offering, and the trespass offering--for the place is holy.", 
                "num": 13
              }, 
              {
                "text": "When the priests enter them, they shall not go out of the holy chamber into the outer court; but there they shall leave their garments in which they minister, for they are holy. They shall put on other garments; then they may approach that which is for the people.\"", 
                "num": 14
              }, 
              {
                "text": "Now when he had finished measuring the inner temple, he brought me out through the gateway that faces toward the east, and measured it all around.", 
                "num": 15
              }, 
              {
                "text": "He measured the east side with the measuring rod, five hundred rods by the measuring rod all around.", 
                "num": 16
              }, 
              {
                "text": "He measured the north side, five hundred rods by the measuring rod all around.", 
                "num": 17
              }, 
              {
                "text": "He measured the south side, five hundred rods by the measuring rod.", 
                "num": 18
              }, 
              {
                "text": "He came around to the west side and measured five hundred rods by the measuring rod.", 
                "num": 19
              }, 
              {
                "text": "He measured it on the four sides; it had a wall all around, five hundred cubits long and five hundred wide, to separate the holy areas from the common.", 
                "num": 20
              }
            ], 
            "num": 42
          }, 
          {
            "verses": [
              {
                "text": "Afterward he brought me to the gate, the gate that faces toward the east.", 
                "num": 1
              }, 
              {
                "text": "And behold, the glory of the God of Israel came from the way of the east. His voice was like the sound of many waters; and the earth shone with His glory.", 
                "num": 2
              }, 
              {
                "text": "It was like the appearance of the vision which I saw--like the vision which I saw when I came to destroy the city. The visions were like the vision which I saw by the River Chebar; and I fell on my face.", 
                "num": 3
              }, 
              {
                "text": "And the glory of the LORD came into the temple by way of the gate which faces toward the east.", 
                "num": 4
              }, 
              {
                "text": "The Spirit lifted me up and brought me into the inner court; and behold, the glory of the LORD filled the temple.", 
                "num": 5
              }, 
              {
                "text": "Then I heard Him speaking to me from the temple, while a man stood beside me.", 
                "num": 6
              }, 
              {
                "text": "And He said to me, \"Son of man, this is the place of My throne and the place of the soles of My feet, where I will dwell in the midst of the children of Israel forever. No more shall the house of Israel defile My holy name, they nor their kings, by their harlotry or with the carcasses of their kings on their high places.", 
                "num": 7
              }, 
              {
                "text": "When they set their threshold by My threshold, and their doorpost by My doorpost, with a wall between them and Me, they defiled My holy name by the abominations which they committed; therefore I have consumed them in My anger.", 
                "num": 8
              }, 
              {
                "text": "Now let them put their harlotry and the carcasses of their kings far away from Me, and I will dwell in their midst forever.", 
                "num": 9
              }, 
              {
                "text": "\"Son of man, describe the temple to the house of Israel, that they may be ashamed of their iniquities; and let them measure the pattern.", 
                "num": 10
              }, 
              {
                "text": "And if they are ashamed of all that they have done, make known to them the design of the temple and its arrangement, its exits and its entrances, its entire design and all its ordinances, all its forms and all its laws. Write it down in their sight, so that they may keep its whole design and all its ordinances, and perform them.", 
                "num": 11
              }, 
              {
                "text": "This is the law of the temple: The whole area surrounding the mountaintop is most holy. Behold, this is the law of the temple.", 
                "num": 12
              }, 
              {
                "text": "\"These are the measurements of the altar in cubits (the cubit is one cubit and a handbreadth): the base one cubit high and one cubit wide, with a rim all around its edge of one span. This is the height of the altar:", 
                "num": 13
              }, 
              {
                "text": "from the base on the ground to the lower ledge, two cubits; the width of the ledge, one cubit; from the smaller ledge to the larger ledge, four cubits; and the width of the ledge, one cubit.", 
                "num": 14
              }, 
              {
                "text": "The altar hearth is four cubits high, with four horns extending upward from the hearth.", 
                "num": 15
              }, 
              {
                "text": "The altar hearth is twelve cubits long, twelve wide, square at its four corners;", 
                "num": 16
              }, 
              {
                "text": "the ledge, fourteen cubits long and fourteen wide on its four sides, with a rim of half a cubit around it; its base, one cubit all around; and its steps face toward the east.\"", 
                "num": 17
              }, 
              {
                "text": "And He said to me, \"Son of man, thus says the Lord GOD: \"These are the ordinances for the altar on the day when it is made, for sacrificing burnt offerings on it, and for sprinkling blood on it.", 
                "num": 18
              }, 
              {
                "text": "You shall give a young bull for a sin offering to the priests, the Levites, who are of the seed of Zadok, who approach Me to minister to Me,' says the Lord GOD.", 
                "num": 19
              }, 
              {
                "text": "You shall take some of its blood and put it on the four horns of the altar, on the four corners of the ledge, and on the rim around it; thus you shall cleanse it and make atonement for it.", 
                "num": 20
              }, 
              {
                "text": "Then you shall also take the bull of the sin offering, and burn it in the appointed place of the temple, outside the sanctuary.", 
                "num": 21
              }, 
              {
                "text": "On the second day you shall offer a kid of the goats without blemish for a sin offering; and they shall cleanse the altar, as they cleansed it with the bull.", 
                "num": 22
              }, 
              {
                "text": "When you have finished cleansing it, you shall offer a young bull without blemish, and a ram from the flock without blemish.", 
                "num": 23
              }, 
              {
                "text": "When you offer them before the LORD, the priests shall throw salt on them, and they will offer them up as a burnt offering to the LORD.", 
                "num": 24
              }, 
              {
                "text": "Every day for seven days you shall prepare a goat for a sin offering; they shall also prepare a young bull and a ram from the flock, both without blemish.", 
                "num": 25
              }, 
              {
                "text": "Seven days they shall make atonement for the altar and purify it, and so consecrate it.", 
                "num": 26
              }, 
              {
                "text": "When these days are over it shall be, on the eighth day and thereafter, that the priests shall offer your burnt offerings and your peace offerings on the altar; and I will accept you,' says the Lord GOD.\"", 
                "num": 27
              }
            ], 
            "num": 43
          }, 
          {
            "verses": [
              {
                "text": "Then He brought me back to the outer gate of the sanctuary which faces toward the east, but it was shut.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to me, \"This gate shall be shut; it shall not be opened, and no man shall enter by it, because the LORD God of Israel has entered by it; therefore it shall be shut.", 
                "num": 2
              }, 
              {
                "text": "As for the prince, because he is the prince, he may sit in it to eat bread before the LORD; he shall enter by way of the vestibule of the gateway, and go out the same way.\"", 
                "num": 3
              }, 
              {
                "text": "Also He brought me by way of the north gate to the front of the temple; so I looked, and behold, the glory of the LORD filled the house of the LORD; and I fell on my face.", 
                "num": 4
              }, 
              {
                "text": "And the LORD said to me, \"Son of man, mark well, see with your eyes and hear with your ears, all that I say to you concerning all the ordinances of the house of the LORD and all its laws. Mark well who may enter the house and all who go out from the sanctuary.", 
                "num": 5
              }, 
              {
                "text": "\"Now say to the rebellious, to the house of Israel, \"Thus says the Lord GOD: \"O house of Israel, let Us have no more of all your abominations.", 
                "num": 6
              }, 
              {
                "text": "When you brought in foreigners, uncircumcised in heart and uncircumcised in flesh, to be in My sanctuary to defile it--My house--and when you offered My food, the fat and the blood, then they broke My covenant because of all your abominations.", 
                "num": 7
              }, 
              {
                "text": "And you have not kept charge of My holy things, but you have set others to keep charge of My sanctuary for you.\"", 
                "num": 8
              }, 
              {
                "text": "Thus says the Lord GOD: \"No foreigner, uncircumcised in heart or uncircumcised in flesh, shall enter My sanctuary, including any foreigner who is among the children of Israel.", 
                "num": 9
              }, 
              {
                "text": "\"And the Levites who went far from Me, when Israel went astray, who strayed away from Me after their idols, they shall bear their iniquity.", 
                "num": 10
              }, 
              {
                "text": "Yet they shall be ministers in My sanctuary, as gatekeepers of the house and ministers of the house; they shall slay the burnt offering and the sacrifice for the people, and they shall stand before them to minister to them.", 
                "num": 11
              }, 
              {
                "text": "Because they ministered to them before their idols and caused the house of Israel to fall into iniquity, therefore I have raised My hand in an oath against them,\" says the Lord GOD, \"that they shall bear their iniquity.", 
                "num": 12
              }, 
              {
                "text": "And they shall not come near Me to minister to Me as priest, nor come near any of My holy things, nor into the Most Holy Place; but they shall bear their shame and their abominations which they have committed.", 
                "num": 13
              }, 
              {
                "text": "Nevertheless I will make them keep charge of the temple, for all its work, and for all that has to be done in it.", 
                "num": 14
              }, 
              {
                "text": "\"But the priests, the Levites, the sons of Zadok, who kept charge of My sanctuary when the children of Israel went astray from Me, they shall come near Me to minister to Me; and they shall stand before Me to offer to Me the fat and the blood,\" says the Lord GOD.", 
                "num": 15
              }, 
              {
                "text": "\"They shall enter My sanctuary, and they shall come near My table to minister to Me, and they shall keep My charge.", 
                "num": 16
              }, 
              {
                "text": "And it shall be, whenever they enter the gates of the inner court, that they shall put on linen garments; no wool shall come upon them while they minister within the gates of the inner court or within the house.", 
                "num": 17
              }, 
              {
                "text": "They shall have linen turbans on their heads and linen trousers on their bodies; they shall not clothe themselves with anything that causes sweat.", 
                "num": 18
              }, 
              {
                "text": "When they go out to the outer court, to the outer court to the people, they shall take off their garments in which they have ministered, leave them in the holy chambers, and put on other garments; and in their holy garments they shall not sanctify the people.", 
                "num": 19
              }, 
              {
                "text": "\"They shall neither shave their heads, nor let their hair grow long, but they shall keep their hair well trimmed.", 
                "num": 20
              }, 
              {
                "text": "No priest shall drink wine when he enters the inner court.", 
                "num": 21
              }, 
              {
                "text": "They shall not take as wife a widow or a divorced woman, but take virgins of the descendants of the house of Israel, or widows of priests.", 
                "num": 22
              }, 
              {
                "text": "\"And they shall teach My people the difference between the holy and the unholy, and cause them to discern between the unclean and the clean.", 
                "num": 23
              }, 
              {
                "text": "In controversy they shall stand as judges, and judge it according to My judgments. They shall keep My laws and My statutes in all My appointed meetings, and they shall hallow My Sabbaths.", 
                "num": 24
              }, 
              {
                "text": "\"They shall not defile themselves by coming near a dead person. Only for father or mother, for son or daughter, for brother or unmarried sister may they defile themselves.", 
                "num": 25
              }, 
              {
                "text": "After he is cleansed, they shall count seven days for him.", 
                "num": 26
              }, 
              {
                "text": "And on the day that he goes to the sanctuary to minister in the sanctuary, he must offer his sin offering in the inner court,\" says the Lord GOD.", 
                "num": 27
              }, 
              {
                "text": "\"It shall be, in regard to their inheritance, that I am their inheritance. You shall give them no possession in Israel, for I am their possession.", 
                "num": 28
              }, 
              {
                "text": "They shall eat the grain offering, the sin offering, and the trespass offering; every dedicated thing in Israel shall be theirs.", 
                "num": 29
              }, 
              {
                "text": "The best of all firstfruits of any kind, and every sacrifice of any kind from all your sacrifices, shall be the priest's; also you shall give to the priest the first of your ground meal, to cause a blessing to rest on your house.", 
                "num": 30
              }, 
              {
                "text": "The priests shall not eat anything, bird or beast, that died naturally or was torn by wild beasts.", 
                "num": 31
              }
            ], 
            "num": 44
          }, 
          {
            "verses": [
              {
                "text": "\"Moreover, when you divide the land by lot into inheritance, you shall set apart a district for the LORD, a holy section of the land; its length shall be twenty-five thousand cubits, and the width ten thousand. It shall be holy throughout its territory all around.", 
                "num": 1
              }, 
              {
                "text": "Of this there shall be a square plot for the sanctuary, five hundred by five hundred rods, with fifty cubits around it for an open space.", 
                "num": 2
              }, 
              {
                "text": "So this is the district you shall measure: twenty-five thousand cubits long and ten thousand wide; in it shall be the sanctuary, the Most Holy Place.", 
                "num": 3
              }, 
              {
                "text": "It shall be a holy section of the land, belonging to the priests, the ministers of the sanctuary, who come near to minister to the LORD; it shall be a place for their houses and a holy place for the sanctuary.", 
                "num": 4
              }, 
              {
                "text": "An area twenty-five thousand cubits long and ten thousand wide shall belong to the Levites, the ministers of the temple; they shall have twenty chambers as a possession.", 
                "num": 5
              }, 
              {
                "text": "\"You shall appoint as the property of the city an area five thousand cubits wide and twenty-five thousand long, adjacent to the district of the holy section; it shall belong to the whole house of Israel.", 
                "num": 6
              }, 
              {
                "text": "\"The prince shall have a section on one side and the other of the holy district and the city's property; and bordering on the holy district and the city's property, extending westward on the west side and eastward on the east side, the length shall be side by side with one of the tribal portions, from the west border to the east border.", 
                "num": 7
              }, 
              {
                "text": "The land shall be his possession in Israel; and My princes shall no more oppress My people, but they shall give the rest of the land to the house of Israel, according to their tribes.\"", 
                "num": 8
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"Enough, O princes of Israel! Remove violence and plundering, execute justice and righteousness, and stop dispossessing My people,\" says the Lord GOD.", 
                "num": 9
              }, 
              {
                "text": "\"You shall have honest scales, an honest ephah, and an honest bath.", 
                "num": 10
              }, 
              {
                "text": "The ephah and the bath shall be of the same measure, so that the bath contains one-tenth of a homer, and the ephah one-tenth of a homer; their measure shall be according to the homer.", 
                "num": 11
              }, 
              {
                "text": "The shekel shall be twenty gerahs; twenty shekels, twenty-five shekels, and fifteen shekels shall be your mina.", 
                "num": 12
              }, 
              {
                "text": "\"This is the offering which you shall offer: you shall give one-sixth of an ephah from a homer of wheat, and one-sixth of an ephah from a homer of barley.", 
                "num": 13
              }, 
              {
                "text": "The ordinance concerning oil, the bath of oil, is one-tenth of a bath from a kor. A kor is a homer or ten baths, for ten baths are a homer.", 
                "num": 14
              }, 
              {
                "text": "And one lamb shall be given from a flock of two hundred, from the rich pastures of Israel. These shall be for grain offerings, burnt offerings, and peace offerings, to make atonement for them,\" says the Lord GOD.", 
                "num": 15
              }, 
              {
                "text": "\"All the people of the land shall give this offering for the prince in Israel.", 
                "num": 16
              }, 
              {
                "text": "Then it shall be the prince's part to give burnt offerings, grain offerings, and drink offerings, at the feasts, the New Moons, the Sabbaths, and at all the appointed seasons of the house of Israel. He shall prepare the sin offering, the grain offering, the burnt offering, and the peace offerings to make atonement for the house of Israel.\"", 
                "num": 17
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"In the first month, on the first day of the month, you shall take a young bull without blemish and cleanse the sanctuary.", 
                "num": 18
              }, 
              {
                "text": "The priest shall take some of the blood of the sin offering and put it on the doorposts of the temple, on the four corners of the ledge of the altar, and on the gateposts of the gate of the inner court.", 
                "num": 19
              }, 
              {
                "text": "And so you shall do on the seventh day of the month for everyone who has sinned unintentionally or in ignorance. Thus you shall make atonement for the temple.", 
                "num": 20
              }, 
              {
                "text": "\"In the first month, on the fourteenth day of the month, you shall observe the Passover, a feast of seven days; unleavened bread shall be eaten.", 
                "num": 21
              }, 
              {
                "text": "And on that day the prince shall prepare for himself and for all the people of the land a bull for a sin offering.", 
                "num": 22
              }, 
              {
                "text": "On the seven days of the feast he shall prepare a burnt offering to the LORD, seven bulls and seven rams without blemish, daily for seven days, and a kid of the goats daily for a sin offering.", 
                "num": 23
              }, 
              {
                "text": "And he shall prepare a grain offering of one ephah for each bull and one ephah for each ram, together with a hin of oil for each ephah.", 
                "num": 24
              }, 
              {
                "text": "\"In the seventh month, on the fifteenth day of the month, at the feast, he shall do likewise for seven days, according to the sin offering, the burnt offering, the grain offering, and the oil.\"", 
                "num": 25
              }
            ], 
            "num": 45
          }, 
          {
            "verses": [
              {
                "text": "\"Thus says the Lord GOD: \"The gateway of the inner court that faces toward the east shall be shut the six working days; but on the Sabbath it shall be opened, and on the day of the New Moon it shall be opened.", 
                "num": 1
              }, 
              {
                "text": "The prince shall enter by way of the vestibule of the gateway from the outside, and stand by the gatepost. The priests shall prepare his burnt offering and his peace offerings. He shall worship at the threshold of the gate. Then he shall go out, but the gate shall not be shut until evening.", 
                "num": 2
              }, 
              {
                "text": "Likewise the people of the land shall worship at the entrance to this gateway before the LORD on the Sabbaths and the New Moons.", 
                "num": 3
              }, 
              {
                "text": "The burnt offering that the prince offers to the LORD on the Sabbath day shall be six lambs without blemish, and a ram without blemish;", 
                "num": 4
              }, 
              {
                "text": "and the grain offering shall be one ephah for a ram, and the grain offering for the lambs, as much as he wants to give, as well as a hin of oil with every ephah.", 
                "num": 5
              }, 
              {
                "text": "On the day of the New Moon it shall be a young bull without blemish, six lambs, and a ram; they shall be without blemish.", 
                "num": 6
              }, 
              {
                "text": "He shall prepare a grain offering of an ephah for a bull, an ephah for a ram, as much as he wants to give for the lambs, and a hin of oil with every ephah.", 
                "num": 7
              }, 
              {
                "text": "When the prince enters, he shall go in by way of the vestibule of the gateway, and go out the same way.", 
                "num": 8
              }, 
              {
                "text": "\"But when the people of the land come before the LORD on the appointed feast days, whoever enters by way of the north gate to worship shall go out by way of the south gate; and whoever enters by way of the south gate shall go out by way of the north gate. He shall not return by way of the gate through which he came, but shall go out through the opposite gate.", 
                "num": 9
              }, 
              {
                "text": "The prince shall then be in their midst. When they go in, he shall go in; and when they go out, he shall go out.", 
                "num": 10
              }, 
              {
                "text": "At the festivals and the appointed feast days the grain offering shall be an ephah for a bull, an ephah for a ram, as much as he wants to give for the lambs, and a hin of oil with every ephah.", 
                "num": 11
              }, 
              {
                "text": "\"Now when the prince makes a voluntary burnt offering or voluntary peace offering to the LORD, the gate that faces toward the east shall then be opened for him; and he shall prepare his burnt offering and his peace offerings as he did on the Sabbath day. Then he shall go out, and after he goes out the gate shall be shut.", 
                "num": 12
              }, 
              {
                "text": "\"You shall daily make a burnt offering to the LORD of a lamb of the first year without blemish; you shall prepare it every morning.", 
                "num": 13
              }, 
              {
                "text": "And you shall prepare a grain offering with it every morning, a sixth of an ephah, and a third of a hin of oil to moisten the fine flour. This grain offering is a perpetual ordinance, to be made regularly to the LORD.", 
                "num": 14
              }, 
              {
                "text": "Thus they shall prepare the lamb, the grain offering, and the oil, as a regular burnt offering every morning.\"", 
                "num": 15
              }, 
              {
                "text": "\"Thus says the Lord GOD: \"If the prince gives a gift of some of his inheritance to any of his sons, it shall belong to his sons; it is their possession by inheritance.", 
                "num": 16
              }, 
              {
                "text": "But if he gives a gift of some of his inheritance to one of his servants, it shall be his until the year of liberty, after which it shall return to the prince. But his inheritance shall belong to his sons; it shall become theirs.", 
                "num": 17
              }, 
              {
                "text": "Moreover the prince shall not take any of the people's inheritance by evicting them from their property; he shall provide an inheritance for his sons from his own property, so that none of My people may be scattered from his property.\"\"'", 
                "num": 18
              }, 
              {
                "text": "Now he brought me through the entrance, which was at the side of the gate, into the holy chambers of the priests which face toward the north; and there a place was situated at their extreme western end.", 
                "num": 19
              }, 
              {
                "text": "And he said to me, \"This is the place where the priests shall boil the trespass offering and the sin offering, and where they shall bake the grain offering, so that they do not bring them out into the outer court to sanctify the people.\"", 
                "num": 20
              }, 
              {
                "text": "Then he brought me out into the outer court and caused me to pass by the four corners of the court; and in fact, in every corner of the court there was another court.", 
                "num": 21
              }, 
              {
                "text": "In the four corners of the court were enclosed courts, forty cubits long and thirty wide; all four corners were the same size.", 
                "num": 22
              }, 
              {
                "text": "There was a row of building stones all around in them, all around the four of them; and cooking hearths were made under the rows of stones all around.", 
                "num": 23
              }, 
              {
                "text": "And he said to me, \"These are the kitchens where the ministers of the temple shall boil the sacrifices of the people.\"", 
                "num": 24
              }
            ], 
            "num": 46
          }, 
          {
            "verses": [
              {
                "text": "Then he brought me back to the door of the temple; and there was water, flowing from under the threshold of the temple toward the east, for the front of the temple faced east; the water was flowing from under the right side of the temple, south of the altar.", 
                "num": 1
              }, 
              {
                "text": "He brought me out by way of the north gate, and led me around on the outside to the outer gateway that faces east; and there was water, running out on the right side.", 
                "num": 2
              }, 
              {
                "text": "And when the man went out to the east with the line in his hand, he measured one thousand cubits, and he brought me through the waters; the water came up to my ankles.", 
                "num": 3
              }, 
              {
                "text": "Again he measured one thousand and brought me through the waters; the water came up to my knees. Again he measured one thousand and brought me through; the water came up to my waist.", 
                "num": 4
              }, 
              {
                "text": "Again he measured one thousand, and it was a river that I could not cross; for the water was too deep, water in which one must swim, a river that could not be crossed.", 
                "num": 5
              }, 
              {
                "text": "He said to me, \"Son of man, have you seen this?\" Then he brought me and returned me to the bank of the river.", 
                "num": 6
              }, 
              {
                "text": "When I returned, there, along the bank of the river, were very many trees on one side and the other.", 
                "num": 7
              }, 
              {
                "text": "Then he said to me: \"This water flows toward the eastern region, goes down into the valley, and enters the sea. When it reaches the sea, its waters are healed.", 
                "num": 8
              }, 
              {
                "text": "And it shall be that every living thing that moves, wherever the rivers go, will live. There will be a very great multitude of fish, because these waters go there; for they will be healed, and everything will live wherever the river goes.", 
                "num": 9
              }, 
              {
                "text": "It shall be that fishermen will stand by it from En Gedi to En Eglaim; they will be places for spreading their nets. Their fish will be of the same kinds as the fish of the Great Sea, exceedingly many.", 
                "num": 10
              }, 
              {
                "text": "But its swamps and marshes will not be healed; they will be given over to salt.", 
                "num": 11
              }, 
              {
                "text": "Along the bank of the river, on this side and that, will grow all kinds of trees used for food; their leaves will not wither, and their fruit will not fail. They will bear fruit every month, because their water flows from the sanctuary. Their fruit will be for food, and their leaves for medicine.\"", 
                "num": 12
              }, 
              {
                "text": "Thus says the Lord GOD: \"These are the borders by which you shall divide the land as an inheritance among the twelve tribes of Israel. Joseph shall have two portions.", 
                "num": 13
              }, 
              {
                "text": "You shall inherit it equally with one another; for I raised My hand in an oath to give it to your fathers, and this land shall fall to you as your inheritance.", 
                "num": 14
              }, 
              {
                "text": "\"This shall be the border of the land on the north: from the Great Sea, by the road to Hethlon, as one goes to Zedad,", 
                "num": 15
              }, 
              {
                "text": "Hamath, Berothah, Sibraim (which is between the border of Damascus and the border of Hamath), to Hazar Hatticon (which is on the border of Hauran).", 
                "num": 16
              }, 
              {
                "text": "Thus the boundary shall be from the Sea to Hazar Enan, the border of Damascus; and as for the north, northward, it is the border of Hamath. This is the north side.", 
                "num": 17
              }, 
              {
                "text": "\"On the east side you shall mark out the border from between Hauran and Damascus, and between Gilead and the land of Israel, along the Jordan, and along the eastern side of the sea. This is the east side.", 
                "num": 18
              }, 
              {
                "text": "\"The south side, toward the South, shall be from Tamar to the waters of Meribah by Kadesh, along the brook to the Great Sea. This is the south side, toward the South.", 
                "num": 19
              }, 
              {
                "text": "\"The west side shall be the Great Sea, from the southern boundary until one comes to a point opposite Hamath. This is the west side.", 
                "num": 20
              }, 
              {
                "text": "\"Thus you shall divide this land among yourselves according to the tribes of Israel.", 
                "num": 21
              }, 
              {
                "text": "It shall be that you will divide it by lot as an inheritance for yourselves, and for the strangers who dwell among you and who bear children among you. They shall be to you as native-born among the children of Israel; they shall have an inheritance with you among the tribes of Israel.", 
                "num": 22
              }, 
              {
                "text": "And it shall be that in whatever tribe the stranger dwells, there you shall give him his inheritance,\" says the Lord GOD.", 
                "num": 23
              }
            ], 
            "num": 47
          }, 
          {
            "verses": [
              {
                "text": "\"Now these are the names of the tribes: From the northern border along the road to Hethlon at the entrance of Hamath, to Hazar Enan, the border of Damascus northward, in the direction of Hamath, there shall be one section for Dan from its east to its west side;", 
                "num": 1
              }, 
              {
                "text": "by the border of Dan, from the east side to the west, one section for Asher;", 
                "num": 2
              }, 
              {
                "text": "by the border of Asher, from the east side to the west, one section for Naphtali;", 
                "num": 3
              }, 
              {
                "text": "by the border of Naphtali, from the east side to the west, one section for Manasseh;", 
                "num": 4
              }, 
              {
                "text": "by the border of Manasseh, from the east side to the west, one section for Ephraim;", 
                "num": 5
              }, 
              {
                "text": "by the border of Ephraim, from the east side to the west, one section for Reuben;", 
                "num": 6
              }, 
              {
                "text": "by the border of Reuben, from the east side to the west, one section for Judah;", 
                "num": 7
              }, 
              {
                "text": "by the border of Judah, from the east side to the west, shall be the district which you shall set apart, twenty-five thousand cubits in width, and in length the same as one of the other portions, from the east side to the west, with the sanctuary in the center.", 
                "num": 8
              }, 
              {
                "text": "\"The district that you shall set apart for the LORD shall be twenty-five thousand cubits in length and ten thousand in width.", 
                "num": 9
              }, 
              {
                "text": "To these--to the priests--the holy district shall belong: on the north twenty-five thousand cubits in length, on the west ten thousand in width, on the east ten thousand in width, and on the south twenty-five thousand in length. The sanctuary of the LORD shall be in the center.", 
                "num": 10
              }, 
              {
                "text": "It shall be for the priests of the sons of Zadok, who are sanctified, who have kept My charge, who did not go astray when the children of Israel went astray, as the Levites went astray.", 
                "num": 11
              }, 
              {
                "text": "And this district of land that is set apart shall be to them a thing most holy by the border of the Levites.", 
                "num": 12
              }, 
              {
                "text": "\"Opposite the border of the priests, the Levites shall have an area twenty-five thousand cubits in length and ten thousand in width; its entire length shall be twenty-five thousand and its width ten thousand.", 
                "num": 13
              }, 
              {
                "text": "And they shall not sell or exchange any of it; they may not alienate this best part of the land, for it is holy to the LORD.", 
                "num": 14
              }, 
              {
                "text": "\"The five thousand cubits in width that remain, along the edge of the twenty-five thousand, shall be for general use by the city, for dwellings and common-land; and the city shall be in the center.", 
                "num": 15
              }, 
              {
                "text": "These shall be its measurements: the north side four thousand five hundred cubits, the south side four thousand five hundred, the east side four thousand five hundred, and the west side four thousand five hundred.", 
                "num": 16
              }, 
              {
                "text": "The common-land of the city shall be: to the north two hundred and fifty cubits, to the south two hundred and fifty, to the east two hundred and fifty, and to the west two hundred and fifty.", 
                "num": 17
              }, 
              {
                "text": "The rest of the length, alongside the district of the holy section, shall be ten thousand cubits to the east and ten thousand to the west. It shall be adjacent to the district of the holy section, and its produce shall be food for the workers of the city.", 
                "num": 18
              }, 
              {
                "text": "The workers of the city, from all the tribes of Israel, shall cultivate it.", 
                "num": 19
              }, 
              {
                "text": "The entire district shall be twenty-five thousand cubits by twenty-five thousand cubits, foursquare. You shall set apart the holy district with the property of the city.", 
                "num": 20
              }, 
              {
                "text": "\"The rest shall belong to the prince, on one side and on the other of the holy district and of the city's property, next to the twenty-five thousand cubits of the holy district as far as the eastern border, and westward next to the twenty-five thousand as far as the western border, adjacent to the tribal portions; it shall belong to the prince. It shall be the holy district, and the sanctuary of the temple shall be in the center.", 
                "num": 21
              }, 
              {
                "text": "Moreover, apart from the possession of the Levites and the possession of the city which are in the midst of what belongs to the prince, the area between the border of Judah and the border of Benjamin shall belong to the prince.", 
                "num": 22
              }, 
              {
                "text": "\"As for the rest of the tribes, from the east side to the west, Benjamin shall have one section;", 
                "num": 23
              }, 
              {
                "text": "by the border of Benjamin, from the east side to the west, Simeon shall have one section;", 
                "num": 24
              }, 
              {
                "text": "by the border of Simeon, from the east side to the west, Issachar shall have one section;", 
                "num": 25
              }, 
              {
                "text": "by the border of Issachar, from the east side to the west, Zebulun shall have one section;", 
                "num": 26
              }, 
              {
                "text": "by the border of Zebulun, from the east side to the west, Gad shall have one section;", 
                "num": 27
              }, 
              {
                "text": "by the border of Gad, on the south side, toward the South, the border shall be from Tamar to the waters of Meribah by Kadesh, along the brook to the Great Sea.", 
                "num": 28
              }, 
              {
                "text": "This is the land which you shall divide by lot as an inheritance among the tribes of Israel, and these are their portions,\" says the Lord GOD.", 
                "num": 29
              }, 
              {
                "text": "\"These are the exits of the city. On the north side, measuring four thousand five hundred cubits", 
                "num": 30
              }, 
              {
                "text": "(the gates of the city shall be named after the tribes of Israel), the three gates northward: one gate for Reuben, one gate for Judah, and one gate for Levi;", 
                "num": 31
              }, 
              {
                "text": "on the east side, four thousand five hundred cubits, three gates: one gate for Joseph, one gate for Benjamin, and one gate for Dan;", 
                "num": 32
              }, 
              {
                "text": "on the south side, measuring four thousand five hundred cubits, three gates: one gate for Simeon, one gate for Issachar, and one gate for Zebulun;", 
                "num": 33
              }, 
              {
                "text": "on the west side, four thousand five hundred cubits with their three gates: one gate for Gad, one gate for Asher, and one gate for Naphtali.", 
                "num": 34
              }, 
              {
                "text": "All the way around shall be eighteen thousand cubits; and the name of the city from that day shall be: THE LORD IS THERE.\"", 
                "num": 35
              }
            ], 
            "num": 48
          }
        ]
      }, 
      {
        "name": "Daniel", 
        "chapters": [
          {
            "verses": [
              {
                "text": "In the third year of the reign of Jehoiakim king of Judah, Nebuchadnezzar king of Babylon came to Jerusalem and besieged it.", 
                "num": 1
              }, 
              {
                "text": "And the Lord gave Jehoiakim king of Judah into his hand, with some of the articles of the house of God, which he carried into the land of Shinar to the house of his god; and he brought the articles into the treasure house of his god.", 
                "num": 2
              }, 
              {
                "text": "Then the king instructed Ashpenaz, the master of his eunuchs, to bring some of the children of Israel and some of the king's descendants and some of the nobles,", 
                "num": 3
              }, 
              {
                "text": "young men in whom there was no blemish, but good-looking, gifted in all wisdom, possessing knowledge and quick to understand, who had ability to serve in the king's palace, and whom they might teach the language and literature of the Chaldeans.", 
                "num": 4
              }, 
              {
                "text": "And the king appointed for them a daily provision of the king's delicacies and of the wine which he drank, and three years of training for them, so that at the end of that time they might serve before the king.", 
                "num": 5
              }, 
              {
                "text": "Now from among those of the sons of Judah were Daniel, Hananiah, Mishael, and Azariah.", 
                "num": 6
              }, 
              {
                "text": "To them the chief of the eunuchs gave names: he gave Daniel the name Belteshazzar; to Hananiah, Shadrach; to Mishael, Meshach; and to Azariah, Abed-Nego.", 
                "num": 7
              }, 
              {
                "text": "But Daniel purposed in his heart that he would not defile himself with the portion of the king's delicacies, nor with the wine which he drank; therefore he requested of the chief of the eunuchs that he might not defile himself.", 
                "num": 8
              }, 
              {
                "text": "Now God had brought Daniel into the favor and goodwill of the chief of the eunuchs.", 
                "num": 9
              }, 
              {
                "text": "And the chief of the eunuchs said to Daniel, \"I fear my lord the king, who has appointed your food and drink. For why should he see your faces looking worse than the young men who are your age? Then you would endanger my head before the king.\"", 
                "num": 10
              }, 
              {
                "text": "So Daniel said to the steward whom the chief of the eunuchs had set over Daniel, Hananiah, Mishael, and Azariah,", 
                "num": 11
              }, 
              {
                "text": "\"Please test your servants for ten days, and let them give us vegetables to eat and water to drink.", 
                "num": 12
              }, 
              {
                "text": "Then let our appearance be examined before you, and the appearance of the young men who eat the portion of the king's delicacies; and as you see fit, so deal with your servants.\"", 
                "num": 13
              }, 
              {
                "text": "So he consented with them in this matter, and tested them ten days.", 
                "num": 14
              }, 
              {
                "text": "And at the end of ten days their features appeared better and fatter in flesh than all the young men who ate the portion of the king's delicacies.", 
                "num": 15
              }, 
              {
                "text": "Thus the steward took away their portion of delicacies and the wine that they were to drink, and gave them vegetables.", 
                "num": 16
              }, 
              {
                "text": "As for these four young men, God gave them knowledge and skill in all literature and wisdom; and Daniel had understanding in all visions and dreams.", 
                "num": 17
              }, 
              {
                "text": "Now at the end of the days, when the king had said that they should be brought in, the chief of the eunuchs brought them in before Nebuchadnezzar.", 
                "num": 18
              }, 
              {
                "text": "Then the king interviewed them, and among them all none was found like Daniel, Hananiah, Mishael, and Azariah; therefore they served before the king.", 
                "num": 19
              }, 
              {
                "text": "And in all matters of wisdom and understanding about which the king examined them, he found them ten times better than all the magicians and astrologers who were in all his realm.", 
                "num": 20
              }, 
              {
                "text": "Thus Daniel continued until the first year of King Cyrus.", 
                "num": 21
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now in the second year of Nebuchadnezzar's reign, Nebuchadnezzar had dreams; and his spirit was so troubled that his sleep left him.", 
                "num": 1
              }, 
              {
                "text": "Then the king gave the command to call the magicians, the astrologers, the sorcerers, and the Chaldeans to tell the king his dreams. So they came and stood before the king.", 
                "num": 2
              }, 
              {
                "text": "And the king said to them, \"I have had a dream, and my spirit is anxious to know the dream.\"", 
                "num": 3
              }, 
              {
                "text": "Then the Chaldeans spoke to the king in Aramaic, \"O king, live forever! Tell your servants the dream, and we will give the interpretation.\"", 
                "num": 4
              }, 
              {
                "text": "The king answered and said to the Chaldeans, \"My decision is firm: if you do not make known the dream to me, and its interpretation, you shall be cut in pieces, and your houses shall be made an ash heap.", 
                "num": 5
              }, 
              {
                "text": "However, if you tell the dream and its interpretation, you shall receive from me gifts, rewards, and great honor. Therefore tell me the dream and its interpretation.\"", 
                "num": 6
              }, 
              {
                "text": "They answered again and said, \"Let the king tell his servants the dream, and we will give its interpretation.\"", 
                "num": 7
              }, 
              {
                "text": "The king answered and said, \"I know for certain that you would gain time, because you see that my decision is firm:", 
                "num": 8
              }, 
              {
                "text": "if you do not make known the dream to me, there is only one decree for you! For you have agreed to speak lying and corrupt words before me till the time has changed. Therefore tell me the dream, and I shall know that you can give me its interpretation.\"", 
                "num": 9
              }, 
              {
                "text": "The Chaldeans answered the king, and said, \"There is not a man on earth who can tell the king's matter; therefore no king, lord, or ruler has ever asked such things of any magician, astrologer, or Chaldean.", 
                "num": 10
              }, 
              {
                "text": "It is a difficult thing that the king requests, and there is no other who can tell it to the king except the gods, whose dwelling is not with flesh.\"", 
                "num": 11
              }, 
              {
                "text": "For this reason the king was angry and very furious, and gave the command to destroy all the wise men of Babylon.", 
                "num": 12
              }, 
              {
                "text": "So the decree went out, and they began killing the wise men; and they sought Daniel and his companions, to kill them.", 
                "num": 13
              }, 
              {
                "text": "Then with counsel and wisdom Daniel answered Arioch, the captain of the king's guard, who had gone out to kill the wise men of Babylon;", 
                "num": 14
              }, 
              {
                "text": "he answered and said to Arioch the king's captain, \"Why is the decree from the king so urgent?\" Then Arioch made the decision known to Daniel.", 
                "num": 15
              }, 
              {
                "text": "So Daniel went in and asked the king to give him time, that he might tell the king the interpretation.", 
                "num": 16
              }, 
              {
                "text": "Then Daniel went to his house, and made the decision known to Hananiah, Mishael, and Azariah, his companions,", 
                "num": 17
              }, 
              {
                "text": "that they might seek mercies from the God of heaven concerning this secret, so that Daniel and his companions might not perish with the rest of the wise men of Babylon.", 
                "num": 18
              }, 
              {
                "text": "Then the secret was revealed to Daniel in a night vision. So Daniel blessed the God of heaven.", 
                "num": 19
              }, 
              {
                "text": "Daniel answered and said: \"Blessed be the name of God forever and ever, For wisdom and might are His.", 
                "num": 20
              }, 
              {
                "text": "And He changes the times and the seasons; He removes kings and raises up kings; He gives wisdom to the wise And knowledge to those who have understanding.", 
                "num": 21
              }, 
              {
                "text": "He reveals deep and secret things; He knows what is in the darkness, And light dwells with Him.", 
                "num": 22
              }, 
              {
                "text": "\"I thank You and praise You, O God of my fathers; You have given me wisdom and might, And have now made known to me what we asked of You, For You have made known to us the king's demand.\"", 
                "num": 23
              }, 
              {
                "text": "Therefore Daniel went to Arioch, whom the king had appointed to destroy the wise men of Babylon. He went and said thus to him: \"Do not destroy the wise men of Babylon; take me before the king, and I will tell the king the interpretation.\"", 
                "num": 24
              }, 
              {
                "text": "Then Arioch quickly brought Daniel before the king, and said thus to him, \"I have found a man of the captives of Judah, who will make known to the king the interpretation.\"", 
                "num": 25
              }, 
              {
                "text": "The king answered and said to Daniel, whose name was Belteshazzar, \"Are you able to make known to me the dream which I have seen, and its interpretation?\"", 
                "num": 26
              }, 
              {
                "text": "Daniel answered in the presence of the king, and said, \"The secret which the king has demanded, the wise men, the astrologers, the magicians, and the soothsayers cannot declare to the king.", 
                "num": 27
              }, 
              {
                "text": "But there is a God in heaven who reveals secrets, and He has made known to King Nebuchadnezzar what will be in the latter days. Your dream, and the visions of your head upon your bed, were these:", 
                "num": 28
              }, 
              {
                "text": "As for you, O king, thoughts came to your mind while on your bed, about what would come to pass after this; and He who reveals secrets has made known to you what will be.", 
                "num": 29
              }, 
              {
                "text": "But as for me, this secret has not been revealed to me because I have more wisdom than anyone living, but for our sakes who make known the interpretation to the king, and that you may know the thoughts of your heart.", 
                "num": 30
              }, 
              {
                "text": "\"You, O king, were watching; and behold, a great image! This great image, whose splendor was excellent, stood before you; and its form was awesome.", 
                "num": 31
              }, 
              {
                "text": "This image's head was of fine gold, its chest and arms of silver, its belly and thighs of bronze,", 
                "num": 32
              }, 
              {
                "text": "its legs of iron, its feet partly of iron and partly of clay.", 
                "num": 33
              }, 
              {
                "text": "You watched while a stone was cut out without hands, which struck the image on its feet of iron and clay, and broke them in pieces.", 
                "num": 34
              }, 
              {
                "text": "Then the iron, the clay, the bronze, the silver, and the gold were crushed together, and became like chaff from the summer threshing floors; the wind carried them away so that no trace of them was found. And the stone that struck the image became a great mountain and filled the whole earth.", 
                "num": 35
              }, 
              {
                "text": "\"This is the dream. Now we will tell the interpretation of it before the king.", 
                "num": 36
              }, 
              {
                "text": "You, O king, are a king of kings. For the God of heaven has given you a kingdom, power, strength, and glory;", 
                "num": 37
              }, 
              {
                "text": "and wherever the children of men dwell, or the beasts of the field and the birds of the heaven, He has given them into your hand, and has made you ruler over them all--you are this head of gold.", 
                "num": 38
              }, 
              {
                "text": "But after you shall arise another kingdom inferior to yours; then another, a third kingdom of bronze, which shall rule over all the earth.", 
                "num": 39
              }, 
              {
                "text": "And the fourth kingdom shall be as strong as iron, inasmuch as iron breaks in pieces and shatters everything; and like iron that crushes, that kingdom will break in pieces and crush all the others.", 
                "num": 40
              }, 
              {
                "text": "Whereas you saw the feet and toes, partly of potter's clay and partly of iron, the kingdom shall be divided; yet the strength of the iron shall be in it, just as you saw the iron mixed with ceramic clay.", 
                "num": 41
              }, 
              {
                "text": "And as the toes of the feet were partly of iron and partly of clay, so the kingdom shall be partly strong and partly fragile.", 
                "num": 42
              }, 
              {
                "text": "As you saw iron mixed with ceramic clay, they will mingle with the seed of men; but they will not adhere to one another, just as iron does not mix with clay.", 
                "num": 43
              }, 
              {
                "text": "And in the days of these kings the God of heaven will set up a kingdom which shall never be destroyed; and the kingdom shall not be left to other people; it shall break in pieces and consume all these kingdoms, and it shall stand forever.", 
                "num": 44
              }, 
              {
                "text": "Inasmuch as you saw that the stone was cut out of the mountain without hands, and that it broke in pieces the iron, the bronze, the clay, the silver, and the gold--the great God has made known to the king what will come to pass after this. The dream is certain, and its interpretation is sure.\"", 
                "num": 45
              }, 
              {
                "text": "Then King Nebuchadnezzar fell on his face, prostrate before Daniel, and commanded that they should present an offering and incense to him.", 
                "num": 46
              }, 
              {
                "text": "The king answered Daniel, and said, \"Truly your God is the God of gods, the Lord of kings, and a revealer of secrets, since you could reveal this secret.\"", 
                "num": 47
              }, 
              {
                "text": "Then the king promoted Daniel and gave him many great gifts; and he made him ruler over the whole province of Babylon, and chief administrator over all the wise men of Babylon.", 
                "num": 48
              }, 
              {
                "text": "Also Daniel petitioned the king, and he set Shadrach, Meshach, and Abed-Nego over the affairs of the province of Babylon; but Daniel sat in the gate of the king.", 
                "num": 49
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Nebuchadnezzar the king made an image of gold, whose height was sixty cubits and its width six cubits. He set it up in the plain of Dura, in the province of Babylon.", 
                "num": 1
              }, 
              {
                "text": "And King Nebuchadnezzar sent word to gather together the satraps, the administrators, the governors, the counselors, the treasurers, the judges, the magistrates, and all the officials of the provinces, to come to the dedication of the image which King Nebuchadnezzar had set up.", 
                "num": 2
              }, 
              {
                "text": "So the satraps, the administrators, the governors, the counselors, the treasurers, the judges, the magistrates, and all the officials of the provinces gathered together for the dedication of the image that King Nebuchadnezzar had set up; and they stood before the image that Nebuchadnezzar had set up.", 
                "num": 3
              }, 
              {
                "text": "Then a herald cried aloud: \"To you it is commanded, O peoples, nations, and languages,", 
                "num": 4
              }, 
              {
                "text": "that at the time you hear the sound of the horn, flute, harp, lyre, and psaltery, in symphony with all kinds of music, you shall fall down and worship the gold image that King Nebuchadnezzar has set up;", 
                "num": 5
              }, 
              {
                "text": "and whoever does not fall down and worship shall be cast immediately into the midst of a burning fiery furnace.\"", 
                "num": 6
              }, 
              {
                "text": "So at that time, when all the people heard the sound of the horn, flute, harp, and lyre, in symphony with all kinds of music, all the people, nations, and languages fell down and worshiped the gold image which King Nebuchadnezzar had set up.", 
                "num": 7
              }, 
              {
                "text": "Therefore at that time certain Chaldeans came forward and accused the Jews.", 
                "num": 8
              }, 
              {
                "text": "They spoke and said to King Nebuchadnezzar, \"O king, live forever!", 
                "num": 9
              }, 
              {
                "text": "You, O king, have made a decree that everyone who hears the sound of the horn, flute, harp, lyre, and psaltery, in symphony with all kinds of music, shall fall down and worship the gold image;", 
                "num": 10
              }, 
              {
                "text": "and whoever does not fall down and worship shall be cast into the midst of a burning fiery furnace.", 
                "num": 11
              }, 
              {
                "text": "There are certain Jews whom you have set over the affairs of the province of Babylon: Shadrach, Meshach, and Abed-Nego; these men, O king, have not paid due regard to you. They do not serve your gods or worship the gold image which you have set up.\"", 
                "num": 12
              }, 
              {
                "text": "Then Nebuchadnezzar, in rage and fury, gave the command to bring Shadrach, Meshach, and Abed-Nego. So they brought these men before the king.", 
                "num": 13
              }, 
              {
                "text": "Nebuchadnezzar spoke, saying to them, \"Is it true, Shadrach, Meshach, and Abed-Nego, that you do not serve my gods or worship the gold image which I have set up?", 
                "num": 14
              }, 
              {
                "text": "Now if you are ready at the time you hear the sound of the horn, flute, harp, lyre, and psaltery, in symphony with all kinds of music, and you fall down and worship the image which I have made, good! But if you do not worship, you shall be cast immediately into the midst of a burning fiery furnace. And who is the god who will deliver you from my hands?\"", 
                "num": 15
              }, 
              {
                "text": "Shadrach, Meshach, and Abed-Nego answered and said to the king, \"O Nebuchadnezzar, we have no need to answer you in this matter.", 
                "num": 16
              }, 
              {
                "text": "If that is the case, our God whom we serve is able to deliver us from the burning fiery furnace, and He will deliver us from your hand, O king.", 
                "num": 17
              }, 
              {
                "text": "But if not, let it be known to you, O king, that we do not serve your gods, nor will we worship the gold image which you have set up.\"", 
                "num": 18
              }, 
              {
                "text": "Then Nebuchadnezzar was full of fury, and the expression on his face changed toward Shadrach, Meshach, and Abed-Nego. He spoke and commanded that they heat the furnace seven times more than it was usually heated.", 
                "num": 19
              }, 
              {
                "text": "And he commanded certain mighty men of valor who were in his army to bind Shadrach, Meshach, and Abed-Nego, and cast them into the burning fiery furnace.", 
                "num": 20
              }, 
              {
                "text": "Then these men were bound in their coats, their trousers, their turbans, and their other garments, and were cast into the midst of the burning fiery furnace.", 
                "num": 21
              }, 
              {
                "text": "Therefore, because the king's command was urgent, and the furnace exceedingly hot, the flame of the fire killed those men who took up Shadrach, Meshach, and Abed-Nego.", 
                "num": 22
              }, 
              {
                "text": "And these three men, Shadrach, Meshach, and Abed-Nego, fell down bound into the midst of the burning fiery furnace.", 
                "num": 23
              }, 
              {
                "text": "Then King Nebuchadnezzar was astonished; and he rose in haste and spoke, saying to his counselors, \"Did we not cast three men bound into the midst of the fire?\" They answered and said to the king, \"True, O king.\"", 
                "num": 24
              }, 
              {
                "text": "\"Look!\" he answered, \"I see four men loose, walking in the midst of the fire; and they are not hurt, and the form of the fourth is like the Son of God.\"", 
                "num": 25
              }, 
              {
                "text": "Then Nebuchadnezzar went near the mouth of the burning fiery furnace and spoke, saying, \"Shadrach, Meshach, and Abed-Nego, servants of the Most High God, come out, and come here.\" Then Shadrach, Meshach, and Abed-Nego came from the midst of the fire.", 
                "num": 26
              }, 
              {
                "text": "And the satraps, administrators, governors, and the king's counselors gathered together, and they saw these men on whose bodies the fire had no power; the hair of their head was not singed nor were their garments affected, and the smell of fire was not on them.", 
                "num": 27
              }, 
              {
                "text": "Nebuchadnezzar spoke, saying, \"Blessed be the God of Shadrach, Meshach, and Abed-Nego, who sent His Angel and delivered His servants who trusted in Him, and they have frustrated the king's word, and yielded their bodies, that they should not serve nor worship any god except their own God!", 
                "num": 28
              }, 
              {
                "text": "Therefore I make a decree that any people, nation, or language which speaks anything amiss against the God of Shadrach, Meshach, and Abed-Nego shall be cut in pieces, and their houses shall be made an ash heap; because there is no other God who can deliver like this.\"", 
                "num": 29
              }, 
              {
                "text": "Then the king promoted Shadrach, Meshach, and Abed-Nego in the province of Babylon.", 
                "num": 30
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Nebuchadnezzar the king, To all peoples, nations, and languages that dwell in all the earth: Peace be multiplied to you.", 
                "num": 1
              }, 
              {
                "text": "I thought it good to declare the signs and wonders that the Most High God has worked for me.", 
                "num": 2
              }, 
              {
                "text": "How great are His signs, And how mighty His wonders! His kingdom is an everlasting kingdom, And His dominion is from generation to generation.", 
                "num": 3
              }, 
              {
                "text": "I, Nebuchadnezzar, was at rest in my house, and flourishing in my palace.", 
                "num": 4
              }, 
              {
                "text": "I saw a dream which made me afraid, and the thoughts on my bed and the visions of my head troubled me.", 
                "num": 5
              }, 
              {
                "text": "Therefore I issued a decree to bring in all the wise men of Babylon before me, that they might make known to me the interpretation of the dream.", 
                "num": 6
              }, 
              {
                "text": "Then the magicians, the astrologers, the Chaldeans, and the soothsayers came in, and I told them the dream; but they did not make known to me its interpretation.", 
                "num": 7
              }, 
              {
                "text": "But at last Daniel came before me (his name is Belteshazzar, according to the name of my god; in him is the Spirit of the Holy God), and I told the dream before him, saying:", 
                "num": 8
              }, 
              {
                "text": "\"Belteshazzar, chief of the magicians, because I know that the Spirit of the Holy God is in you, and no secret troubles you, explain to me the visions of my dream that I have seen, and its interpretation.", 
                "num": 9
              }, 
              {
                "text": "\"These were the visions of my head while on my bed: I was looking, and behold, A tree in the midst of the earth, And its height was great.", 
                "num": 10
              }, 
              {
                "text": "The tree grew and became strong; Its height reached to the heavens, And it could be seen to the ends of all the earth.", 
                "num": 11
              }, 
              {
                "text": "Its leaves were lovely, Its fruit abundant, And in it was food for all. The beasts of the field found shade under it, The birds of the heavens dwelt in its branches, And all flesh was fed from it.", 
                "num": 12
              }, 
              {
                "text": "\"I saw in the visions of my head while on my bed, and there was a watcher, a holy one, coming down from heaven.", 
                "num": 13
              }, 
              {
                "text": "He cried aloud and said thus: \"Chop down the tree and cut off its branches, Strip off its leaves and scatter its fruit. Let the beasts get out from under it, And the birds from its branches.", 
                "num": 14
              }, 
              {
                "text": "Nevertheless leave the stump and roots in the earth, Bound with a band of iron and bronze, In the tender grass of the field. Let it be wet with the dew of heaven, And let him graze with the beasts On the grass of the earth.", 
                "num": 15
              }, 
              {
                "text": "Let his heart be changed from that of a man, Let him be given the heart of a beast, And let seven times pass over him.", 
                "num": 16
              }, 
              {
                "text": "\"This decision is by the decree of the watchers, And the sentence by the word of the holy ones, In order that the living may know That the Most High rules in the kingdom of men, Gives it to whomever He will, And sets over it the lowest of men.'", 
                "num": 17
              }, 
              {
                "text": "\"This dream I, King Nebuchadnezzar, have seen. Now you, Belteshazzar, declare its interpretation, since all the wise men of my kingdom are not able to make known to me the interpretation; but you are able, for the Spirit of the Holy God is in you.\"", 
                "num": 18
              }, 
              {
                "text": "Then Daniel, whose name was Belteshazzar, was astonished for a time, and his thoughts troubled him. So the king spoke, and said, \"Belteshazzar, do not let the dream or its interpretation trouble you.\" Belteshazzar answered and said, \"My lord, may the dream concern those who hate you, and its interpretation concern your enemies!", 
                "num": 19
              }, 
              {
                "text": "\"The tree that you saw, which grew and became strong, whose height reached to the heavens and which could be seen by all the earth,", 
                "num": 20
              }, 
              {
                "text": "whose leaves were lovely and its fruit abundant, in which was food for all, under which the beasts of the field dwelt, and in whose branches the birds of the heaven had their home--", 
                "num": 21
              }, 
              {
                "text": "it is you, O king, who have grown and become strong; for your greatness has grown and reaches to the heavens, and your dominion to the end of the earth.", 
                "num": 22
              }, 
              {
                "text": "\"And inasmuch as the king saw a watcher, a holy one, coming down from heaven and saying, \"Chop down the tree and destroy it, but leave its stump and roots in the earth, bound with a band of iron and bronze in the tender grass of the field; let it be wet with the dew of heaven, and let him graze with the beasts of the field, till seven times pass over him';", 
                "num": 23
              }, 
              {
                "text": "this is the interpretation, O king, and this is the decree of the Most High, which has come upon my lord the king:", 
                "num": 24
              }, 
              {
                "text": "They shall drive you from men, your dwelling shall be with the beasts of the field, and they shall make you eat grass like oxen. They shall wet you with the dew of heaven, and seven times shall pass over you, till you know that the Most High rules in the kingdom of men, and gives it to whomever He chooses.", 
                "num": 25
              }, 
              {
                "text": "\"And inasmuch as they gave the command to leave the stump and roots of the tree, your kingdom shall be assured to you, after you come to know that Heaven rules.", 
                "num": 26
              }, 
              {
                "text": "Therefore, O king, let my advice be acceptable to you; break off your sins by being righteous, and your iniquities by showing mercy to the poor. Perhaps there may be a lengthening of your prosperity.\"", 
                "num": 27
              }, 
              {
                "text": "All this came upon King Nebuchadnezzar.", 
                "num": 28
              }, 
              {
                "text": "At the end of the twelve months he was walking about the royal palace of Babylon.", 
                "num": 29
              }, 
              {
                "text": "The king spoke, saying, \"Is not this great Babylon, that I have built for a royal dwelling by my mighty power and for the honor of my majesty?\"", 
                "num": 30
              }, 
              {
                "text": "While the word was still in the king's mouth, a voice fell from heaven: \"King Nebuchadnezzar, to you it is spoken: the kingdom has departed from you!", 
                "num": 31
              }, 
              {
                "text": "And they shall drive you from men, and your dwelling shall be with the beasts of the field. They shall make you eat grass like oxen; and seven times shall pass over you, until you know that the Most High rules in the kingdom of men, and gives it to whomever He chooses.\"", 
                "num": 32
              }, 
              {
                "text": "That very hour the word was fulfilled concerning Nebuchadnezzar; he was driven from men and ate grass like oxen; his body was wet with the dew of heaven till his hair had grown like eagles' feathers and his nails like birds' claws.", 
                "num": 33
              }, 
              {
                "text": "And at the end of the time I, Nebuchadnezzar, lifted my eyes to heaven, and my understanding returned to me; and I blessed the Most High and praised and honored Him who lives forever: For His dominion is an everlasting dominion, And His kingdom is from generation to generation.", 
                "num": 34
              }, 
              {
                "text": "All the inhabitants of the earth are reputed as nothing; He does according to His will in the army of heaven And among the inhabitants of the earth. No one can restrain His hand Or say to Him, \"What have You done?\"", 
                "num": 35
              }, 
              {
                "text": "At the same time my reason returned to me, and for the glory of my kingdom, my honor and splendor returned to me. My counselors and nobles resorted to me, I was restored to my kingdom, and excellent majesty was added to me.", 
                "num": 36
              }, 
              {
                "text": "Now I, Nebuchadnezzar, praise and extol and honor the King of heaven, all of whose works are truth, and His ways justice. And those who walk in pride He is able to put down.", 
                "num": 37
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Belshazzar the king made a great feast for a thousand of his lords, and drank wine in the presence of the thousand.", 
                "num": 1
              }, 
              {
                "text": "While he tasted the wine, Belshazzar gave the command to bring the gold and silver vessels which his father Nebuchadnezzar had taken from the temple which had been in Jerusalem, that the king and his lords, his wives, and his concubines might drink from them.", 
                "num": 2
              }, 
              {
                "text": "Then they brought the gold vessels that had been taken from the temple of the house of God which had been in Jerusalem; and the king and his lords, his wives, and his concubines drank from them.", 
                "num": 3
              }, 
              {
                "text": "They drank wine, and praised the gods of gold and silver, bronze and iron, wood and stone.", 
                "num": 4
              }, 
              {
                "text": "In the same hour the fingers of a man's hand appeared and wrote opposite the lampstand on the plaster of the wall of the king's palace; and the king saw the part of the hand that wrote.", 
                "num": 5
              }, 
              {
                "text": "Then the king's countenance changed, and his thoughts troubled him, so that the joints of his hips were loosened and his knees knocked against each other.", 
                "num": 6
              }, 
              {
                "text": "The king cried aloud to bring in the astrologers, the Chaldeans, and the soothsayers. The king spoke, saying to the wise men of Babylon, \"Whoever reads this writing, and tells me its interpretation, shall be clothed with purple and have a chain of gold around his neck; and he shall be the third ruler in the kingdom.\"", 
                "num": 7
              }, 
              {
                "text": "Now all the king's wise men came, but they could not read the writing, or make known to the king its interpretation.", 
                "num": 8
              }, 
              {
                "text": "Then King Belshazzar was greatly troubled, his countenance was changed, and his lords were astonished.", 
                "num": 9
              }, 
              {
                "text": "The queen, because of the words of the king and his lords, came to the banquet hall. The queen spoke, saying, \"O king, live forever! Do not let your thoughts trouble you, nor let your countenance change.", 
                "num": 10
              }, 
              {
                "text": "There is a man in your kingdom in whom is the Spirit of the Holy God. And in the days of your father, light and understanding and wisdom, like the wisdom of the gods, were found in him; and King Nebuchadnezzar your father--your father the king--made him chief of the magicians, astrologers, Chaldeans, and soothsayers.", 
                "num": 11
              }, 
              {
                "text": "Inasmuch as an excellent spirit, knowledge, understanding, interpreting dreams, solving riddles, and explaining enigmas were found in this Daniel, whom the king named Belteshazzar, now let Daniel be called, and he will give the interpretation.\"", 
                "num": 12
              }, 
              {
                "text": "Then Daniel was brought in before the king. The king spoke, and said to Daniel, \"Are you that Daniel who is one of the captives from Judah, whom my father the king brought from Judah?", 
                "num": 13
              }, 
              {
                "text": "I have heard of you, that the Spirit of God is in you, and that light and understanding and excellent wisdom are found in you.", 
                "num": 14
              }, 
              {
                "text": "Now the wise men, the astrologers, have been brought in before me, that they should read this writing and make known to me its interpretation, but they could not give the interpretation of the thing.", 
                "num": 15
              }, 
              {
                "text": "And I have heard of you, that you can give interpretations and explain enigmas. Now if you can read the writing and make known to me its interpretation, you shall be clothed with purple and have a chain of gold around your neck, and shall be the third ruler in the kingdom.\"", 
                "num": 16
              }, 
              {
                "text": "Then Daniel answered, and said before the king, \"Let your gifts be for yourself, and give your rewards to another; yet I will read the writing to the king, and make known to him the interpretation.", 
                "num": 17
              }, 
              {
                "text": "O king, the Most High God gave Nebuchadnezzar your father a kingdom and majesty, glory and honor.", 
                "num": 18
              }, 
              {
                "text": "And because of the majesty that He gave him, all peoples, nations, and languages trembled and feared before him. Whomever he wished, he executed; whomever he wished, he kept alive; whomever he wished, he set up; and whomever he wished, he put down.", 
                "num": 19
              }, 
              {
                "text": "But when his heart was lifted up, and his spirit was hardened in pride, he was deposed from his kingly throne, and they took his glory from him.", 
                "num": 20
              }, 
              {
                "text": "Then he was driven from the sons of men, his heart was made like the beasts, and his dwelling was with the wild donkeys. They fed him with grass like oxen, and his body was wet with the dew of heaven, till he knew that the Most High God rules in the kingdom of men, and appoints over it whomever He chooses.", 
                "num": 21
              }, 
              {
                "text": "\"But you his son, Belshazzar, have not humbled your heart, although you knew all this.", 
                "num": 22
              }, 
              {
                "text": "And you have lifted yourself up against the Lord of heaven. They have brought the vessels of His house before you, and you and your lords, your wives and your concubines, have drunk wine from them. And you have praised the gods of silver and gold, bronze and iron, wood and stone, which do not see or hear or know; and the God who holds your breath in His hand and owns all your ways, you have not glorified.", 
                "num": 23
              }, 
              {
                "text": "Then the fingers of the hand were sent from Him, and this writing was written.", 
                "num": 24
              }, 
              {
                "text": "\"And this is the inscription that was written: MENE, MENE, TEKEL, UPHARSIN.", 
                "num": 25
              }, 
              {
                "text": "This is the interpretation of each word. MENE: God has numbered your kingdom, and finished it;", 
                "num": 26
              }, 
              {
                "text": "TEKEL: You have been weighed in the balances, and found wanting;", 
                "num": 27
              }, 
              {
                "text": "PERES: Your kingdom has been divided, and given to the Medes and Persians.\"", 
                "num": 28
              }, 
              {
                "text": "Then Belshazzar gave the command, and they clothed Daniel with purple and put a chain of gold around his neck, and made a proclamation concerning him that he should be the third ruler in the kingdom.", 
                "num": 29
              }, 
              {
                "text": "That very night Belshazzar, king of the Chaldeans, was slain.", 
                "num": 30
              }, 
              {
                "text": "And Darius the Mede received the kingdom, being about sixty-two years old.", 
                "num": 31
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "It pleased Darius to set over the kingdom one hundred and twenty satraps, to be over the whole kingdom;", 
                "num": 1
              }, 
              {
                "text": "and over these, three governors, of whom Daniel was one, that the satraps might give account to them, so that the king would suffer no loss.", 
                "num": 2
              }, 
              {
                "text": "Then this Daniel distinguished himself above the governors and satraps, because an excellent spirit was in him; and the king gave thought to setting him over the whole realm.", 
                "num": 3
              }, 
              {
                "text": "So the governors and satraps sought to find some charge against Daniel concerning the kingdom; but they could find no charge or fault, because he was faithful; nor was there any error or fault found in him.", 
                "num": 4
              }, 
              {
                "text": "Then these men said, \"We shall not find any charge against this Daniel unless we find it against him concerning the law of his God.\"", 
                "num": 5
              }, 
              {
                "text": "So these governors and satraps thronged before the king, and said thus to him: \"King Darius, live forever!", 
                "num": 6
              }, 
              {
                "text": "All the governors of the kingdom, the administrators and satraps, the counselors and advisors, have consulted together to establish a royal statute and to make a firm decree, that whoever petitions any god or man for thirty days, except you, O king, shall be cast into the den of lions.", 
                "num": 7
              }, 
              {
                "text": "Now, O king, establish the decree and sign the writing, so that it cannot be changed, according to the law of the Medes and Persians, which does not alter.\"", 
                "num": 8
              }, 
              {
                "text": "Therefore King Darius signed the written decree.", 
                "num": 9
              }, 
              {
                "text": "Now when Daniel knew that the writing was signed, he went home. And in his upper room, with his windows open toward Jerusalem, he knelt down on his knees three times that day, and prayed and gave thanks before his God, as was his custom since early days.", 
                "num": 10
              }, 
              {
                "text": "Then these men assembled and found Daniel praying and making supplication before his God.", 
                "num": 11
              }, 
              {
                "text": "And they went before the king, and spoke concerning the king's decree: \"Have you not signed a decree that every man who petitions any god or man within thirty days, except you, O king, shall be cast into the den of lions?\" The king answered and said, \"The thing is true, according to the law of the Medes and Persians, which does not alter.\"", 
                "num": 12
              }, 
              {
                "text": "So they answered and said before the king, \"That Daniel, who is one of the captives from Judah, does not show due regard for you, O king, or for the decree that you have signed, but makes his petition three times a day.\"", 
                "num": 13
              }, 
              {
                "text": "And the king, when he heard these words, was greatly displeased with himself, and set his heart on Daniel to deliver him; and he labored till the going down of the sun to deliver him.", 
                "num": 14
              }, 
              {
                "text": "Then these men approached the king, and said to the king, \"Know, O king, that it is the law of the Medes and Persians that no decree or statute which the king establishes may be changed.\"", 
                "num": 15
              }, 
              {
                "text": "So the king gave the command, and they brought Daniel and cast him into the den of lions. But the king spoke, saying to Daniel, \"Your God, whom you serve continually, He will deliver you.\"", 
                "num": 16
              }, 
              {
                "text": "Then a stone was brought and laid on the mouth of the den, and the king sealed it with his own signet ring and with the signets of his lords, that the purpose concerning Daniel might not be changed.", 
                "num": 17
              }, 
              {
                "text": "Now the king went to his palace and spent the night fasting; and no musicians were brought before him. Also his sleep went from him.", 
                "num": 18
              }, 
              {
                "text": "Then the king arose very early in the morning and went in haste to the den of lions.", 
                "num": 19
              }, 
              {
                "text": "And when he came to the den, he cried out with a lamenting voice to Daniel. The king spoke, saying to Daniel, \"Daniel, servant of the living God, has your God, whom you serve continually, been able to deliver you from the lions?\"", 
                "num": 20
              }, 
              {
                "text": "Then Daniel said to the king, \"O king, live forever!", 
                "num": 21
              }, 
              {
                "text": "My God sent His angel and shut the lions' mouths, so that they have not hurt me, because I was found innocent before Him; and also, O king, I have done no wrong before you.\"", 
                "num": 22
              }, 
              {
                "text": "Now the king was exceedingly glad for him, and commanded that they should take Daniel up out of the den. So Daniel was taken up out of the den, and no injury whatever was found on him, because he believed in his God.", 
                "num": 23
              }, 
              {
                "text": "And the king gave the command, and they brought those men who had accused Daniel, and they cast them into the den of lions--them, their children, and their wives; and the lions overpowered them, and broke all their bones in pieces before they ever came to the bottom of the den.", 
                "num": 24
              }, 
              {
                "text": "Then King Darius wrote: To all peoples, nations, and languages that dwell in all the earth: Peace be multiplied to you.", 
                "num": 25
              }, 
              {
                "text": "I make a decree that in every dominion of my kingdom men must tremble and fear before the God of Daniel. For He is the living God, And steadfast forever; His kingdom is the one which shall not be destroyed, And His dominion shall endure to the end.", 
                "num": 26
              }, 
              {
                "text": "He delivers and rescues, And He works signs and wonders In heaven and on earth, Who has delivered Daniel from the power of the lions.", 
                "num": 27
              }, 
              {
                "text": "So this Daniel prospered in the reign of Darius and in the reign of Cyrus the Persian.", 
                "num": 28
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "In the first year of Belshazzar king of Babylon, Daniel had a dream and visions of his head while on his bed. Then he wrote down the dream, telling the main facts.", 
                "num": 1
              }, 
              {
                "text": "Daniel spoke, saying, \"I saw in my vision by night, and behold, the four winds of heaven were stirring up the Great Sea.", 
                "num": 2
              }, 
              {
                "text": "And four great beasts came up from the sea, each different from the other.", 
                "num": 3
              }, 
              {
                "text": "The first was like a lion, and had eagle's wings. I watched till its wings were plucked off; and it was lifted up from the earth and made to stand on two feet like a man, and a man's heart was given to it.", 
                "num": 4
              }, 
              {
                "text": "\"And suddenly another beast, a second, like a bear. It was raised up on one side, and had three ribs in its mouth between its teeth. And they said thus to it: \"Arise, devour much flesh!'", 
                "num": 5
              }, 
              {
                "text": "\"After this I looked, and there was another, like a leopard, which had on its back four wings of a bird. The beast also had four heads, and dominion was given to it.", 
                "num": 6
              }, 
              {
                "text": "\"After this I saw in the night visions, and behold, a fourth beast, dreadful and terrible, exceedingly strong. It had huge iron teeth; it was devouring, breaking in pieces, and trampling the residue with its feet. It was different from all the beasts that were before it, and it had ten horns.", 
                "num": 7
              }, 
              {
                "text": "I was considering the horns, and there was another horn, a little one, coming up among them, before whom three of the first horns were plucked out by the roots. And there, in this horn, were eyes like the eyes of a man, and a mouth speaking pompous words.", 
                "num": 8
              }, 
              {
                "text": "\"I watched till thrones were put in place, And the Ancient of Days was seated; His garment was white as snow, And the hair of His head was like pure wool. His throne was a fiery flame, Its wheels a burning fire;", 
                "num": 9
              }, 
              {
                "text": "A fiery stream issued And came forth from before Him. A thousand thousands ministered to Him; Ten thousand times ten thousand stood before Him. The court was seated, And the books were opened.", 
                "num": 10
              }, 
              {
                "text": "\"I watched then because of the sound of the pompous words which the horn was speaking; I watched till the beast was slain, and its body destroyed and given to the burning flame.", 
                "num": 11
              }, 
              {
                "text": "As for the rest of the beasts, they had their dominion taken away, yet their lives were prolonged for a season and a time.", 
                "num": 12
              }, 
              {
                "text": "\"I was watching in the night visions, And behold, One like the Son of Man, Coming with the clouds of heaven! He came to the Ancient of Days, And they brought Him near before Him.", 
                "num": 13
              }, 
              {
                "text": "Then to Him was given dominion and glory and a kingdom, That all peoples, nations, and languages should serve Him. His dominion is an everlasting dominion, Which shall not pass away, And His kingdom the one Which shall not be destroyed.", 
                "num": 14
              }, 
              {
                "text": "\"I, Daniel, was grieved in my spirit within my body, and the visions of my head troubled me.", 
                "num": 15
              }, 
              {
                "text": "I came near to one of those who stood by, and asked him the truth of all this. So he told me and made known to me the interpretation of these things:", 
                "num": 16
              }, 
              {
                "text": "\"Those great beasts, which are four, are four kings which arise out of the earth.", 
                "num": 17
              }, 
              {
                "text": "But the saints of the Most High shall receive the kingdom, and possess the kingdom forever, even forever and ever.'", 
                "num": 18
              }, 
              {
                "text": "\"Then I wished to know the truth about the fourth beast, which was different from all the others, exceedingly dreadful, with its teeth of iron and its nails of bronze, which devoured, broke in pieces, and trampled the residue with its feet;", 
                "num": 19
              }, 
              {
                "text": "and the ten horns that were on its head, and the other horn which came up, before which three fell, namely, that horn which had eyes and a mouth which spoke pompous words, whose appearance was greater than his fellows.", 
                "num": 20
              }, 
              {
                "text": "\"I was watching; and the same horn was making war against the saints, and prevailing against them,", 
                "num": 21
              }, 
              {
                "text": "until the Ancient of Days came, and a judgment was made in favor of the saints of the Most High, and the time came for the saints to possess the kingdom.", 
                "num": 22
              }, 
              {
                "text": "\"Thus he said: \"The fourth beast shall be A fourth kingdom on earth, Which shall be different from all other kingdoms, And shall devour the whole earth, Trample it and break it in pieces.", 
                "num": 23
              }, 
              {
                "text": "The ten horns are ten kings Who shall arise from this kingdom. And another shall rise after them; He shall be different from the first ones, And shall subdue three kings.", 
                "num": 24
              }, 
              {
                "text": "He shall speak pompous words against the Most High, Shall persecute the saints of the Most High, And shall intend to change times and law. Then the saints shall be given into his hand For a time and times and half a time.", 
                "num": 25
              }, 
              {
                "text": "\"But the court shall be seated, And they shall take away his dominion, To consume and destroy it forever.", 
                "num": 26
              }, 
              {
                "text": "Then the kingdom and dominion, And the greatness of the kingdoms under the whole heaven, Shall be given to the people, the saints of the Most High. His kingdom is an everlasting kingdom, And all dominions shall serve and obey Him.'", 
                "num": 27
              }, 
              {
                "text": "\"This is the end of the account. As for me, Daniel, my thoughts greatly troubled me, and my countenance changed; but I kept the matter in my heart.\"", 
                "num": 28
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "In the third year of the reign of King Belshazzar a vision appeared to me--to me, Daniel--after the one that appeared to me the first time.", 
                "num": 1
              }, 
              {
                "text": "I saw in the vision, and it so happened while I was looking, that I was in Shushan, the citadel, which is in the province of Elam; and I saw in the vision that I was by the River Ulai.", 
                "num": 2
              }, 
              {
                "text": "Then I lifted my eyes and saw, and there, standing beside the river, was a ram which had two horns, and the two horns were high; but one was higher than the other, and the higher one came up last.", 
                "num": 3
              }, 
              {
                "text": "I saw the ram pushing westward, northward, and southward, so that no animal could withstand him; nor was there any that could deliver from his hand, but he did according to his will and became great.", 
                "num": 4
              }, 
              {
                "text": "And as I was considering, suddenly a male goat came from the west, across the surface of the whole earth, without touching the ground; and the goat had a notable horn between his eyes.", 
                "num": 5
              }, 
              {
                "text": "Then he came to the ram that had two horns, which I had seen standing beside the river, and ran at him with furious power.", 
                "num": 6
              }, 
              {
                "text": "And I saw him confronting the ram; he was moved with rage against him, attacked the ram, and broke his two horns. There was no power in the ram to withstand him, but he cast him down to the ground and trampled him; and there was no one that could deliver the ram from his hand.", 
                "num": 7
              }, 
              {
                "text": "Therefore the male goat grew very great; but when he became strong, the large horn was broken, and in place of it four notable ones came up toward the four winds of heaven.", 
                "num": 8
              }, 
              {
                "text": "And out of one of them came a little horn which grew exceedingly great toward the south, toward the east, and toward the Glorious Land.", 
                "num": 9
              }, 
              {
                "text": "And it grew up to the host of heaven; and it cast down some of the host and some of the stars to the ground, and trampled them.", 
                "num": 10
              }, 
              {
                "text": "He even exalted himself as high as the Prince of the host; and by him the daily sacrifices were taken away, and the place of His sanctuary was cast down.", 
                "num": 11
              }, 
              {
                "text": "Because of transgression, an army was given over to the horn to oppose the daily sacrifices; and he cast truth down to the ground. He did all this and prospered.", 
                "num": 12
              }, 
              {
                "text": "Then I heard a holy one speaking; and another holy one said to that certain one who was speaking, \"How long will the vision be, concerning the daily sacrifices and the transgression of desolation, the giving of both the sanctuary and the host to be trampled underfoot?\"", 
                "num": 13
              }, 
              {
                "text": "And he said to me, \"For two thousand three hundred days; then the sanctuary shall be cleansed.\"", 
                "num": 14
              }, 
              {
                "text": "Then it happened, when I, Daniel, had seen the vision and was seeking the meaning, that suddenly there stood before me one having the appearance of a man.", 
                "num": 15
              }, 
              {
                "text": "And I heard a man's voice between the banks of the Ulai, who called, and said, \"Gabriel, make this man understand the vision.\"", 
                "num": 16
              }, 
              {
                "text": "So he came near where I stood, and when he came I was afraid and fell on my face; but he said to me, \"Understand, son of man, that the vision refers to the time of the end.\"", 
                "num": 17
              }, 
              {
                "text": "Now, as he was speaking with me, I was in a deep sleep with my face to the ground; but he touched me, and stood me upright.", 
                "num": 18
              }, 
              {
                "text": "And he said, \"Look, I am making known to you what shall happen in the latter time of the indignation; for at the appointed time the end shall be.", 
                "num": 19
              }, 
              {
                "text": "The ram which you saw, having the two horns--they are the kings of Media and Persia.", 
                "num": 20
              }, 
              {
                "text": "And the male goat is the kingdom of Greece. The large horn that is between its eyes is the first king.", 
                "num": 21
              }, 
              {
                "text": "As for the broken horn and the four that stood up in its place, four kingdoms shall arise out of that nation, but not with its power.", 
                "num": 22
              }, 
              {
                "text": "\"And in the latter time of their kingdom, When the transgressors have reached their fullness, A king shall arise, Having fierce features, Who understands sinister schemes.", 
                "num": 23
              }, 
              {
                "text": "His power shall be mighty, but not by his own power; He shall destroy fearfully, And shall prosper and thrive; He shall destroy the mighty, and also the holy people.", 
                "num": 24
              }, 
              {
                "text": "\"Through his cunning He shall cause deceit to prosper under his rule; And he shall exalt himself in his heart. He shall destroy many in their prosperity. He shall even rise against the Prince of princes; But he shall be broken without human means.", 
                "num": 25
              }, 
              {
                "text": "\"And the vision of the evenings and mornings Which was told is true; Therefore seal up the vision, For it refers to many days in the future.\"", 
                "num": 26
              }, 
              {
                "text": "And I, Daniel, fainted and was sick for days; afterward I arose and went about the king's business. I was astonished by the vision, but no one understood it.", 
                "num": 27
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "In the first year of Darius the son of Ahasuerus, of the lineage of the Medes, who was made king over the realm of the Chaldeans--", 
                "num": 1
              }, 
              {
                "text": "in the first year of his reign I, Daniel, understood by the books the number of the years specified by the word of the LORD through Jeremiah the prophet, that He would accomplish seventy years in the desolations of Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "Then I set my face toward the Lord God to make request by prayer and supplications, with fasting, sackcloth, and ashes.", 
                "num": 3
              }, 
              {
                "text": "And I prayed to the LORD my God, and made confession, and said, \"O Lord, great and awesome God, who keeps His covenant and mercy with those who love Him, and with those who keep His commandments,", 
                "num": 4
              }, 
              {
                "text": "we have sinned and committed iniquity, we have done wickedly and rebelled, even by departing from Your precepts and Your judgments.", 
                "num": 5
              }, 
              {
                "text": "Neither have we heeded Your servants the prophets, who spoke in Your name to our kings and our princes, to our fathers and all the people of the land.", 
                "num": 6
              }, 
              {
                "text": "O Lord, righteousness belongs to You, but to us shame of face, as it is this day--to the men of Judah, to the inhabitants of Jerusalem and all Israel, those near and those far off in all the countries to which You have driven them, because of the unfaithfulness which they have committed against You.", 
                "num": 7
              }, 
              {
                "text": "\"O Lord, to us belongs shame of face, to our kings, our princes, and our fathers, because we have sinned against You.", 
                "num": 8
              }, 
              {
                "text": "To the Lord our God belong mercy and forgiveness, though we have rebelled against Him.", 
                "num": 9
              }, 
              {
                "text": "We have not obeyed the voice of the LORD our God, to walk in His laws, which He set before us by His servants the prophets.", 
                "num": 10
              }, 
              {
                "text": "Yes, all Israel has transgressed Your law, and has departed so as not to obey Your voice; therefore the curse and the oath written in the Law of Moses the servant of God have been poured out on us, because we have sinned against Him.", 
                "num": 11
              }, 
              {
                "text": "And He has confirmed His words, which He spoke against us and against our judges who judged us, by bringing upon us a great disaster; for under the whole heaven such has never been done as what has been done to Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "\"As it is written in the Law of Moses, all this disaster has come upon us; yet we have not made our prayer before the LORD our God, that we might turn from our iniquities and understand Your truth.", 
                "num": 13
              }, 
              {
                "text": "Therefore the LORD has kept the disaster in mind, and brought it upon us; for the LORD our God is righteous in all the works which He does, though we have not obeyed His voice.", 
                "num": 14
              }, 
              {
                "text": "And now, O Lord our God, who brought Your people out of the land of Egypt with a mighty hand, and made Yourself a name, as it is this day--we have sinned, we have done wickedly!", 
                "num": 15
              }, 
              {
                "text": "\"O Lord, according to all Your righteousness, I pray, let Your anger and Your fury be turned away from Your city Jerusalem, Your holy mountain; because for our sins, and for the iniquities of our fathers, Jerusalem and Your people are a reproach to all those around us.", 
                "num": 16
              }, 
              {
                "text": "Now therefore, our God, hear the prayer of Your servant, and his supplications, and for the Lord's sake cause Your face to shine on Your sanctuary, which is desolate.", 
                "num": 17
              }, 
              {
                "text": "O my God, incline Your ear and hear; open Your eyes and see our desolations, and the city which is called by Your name; for we do not present our supplications before You because of our righteous deeds, but because of Your great mercies.", 
                "num": 18
              }, 
              {
                "text": "O Lord, hear! O Lord, forgive! O Lord, listen and act! Do not delay for Your own sake, my God, for Your city and Your people are called by Your name.\"", 
                "num": 19
              }, 
              {
                "text": "Now while I was speaking, praying, and confessing my sin and the sin of my people Israel, and presenting my supplication before the LORD my God for the holy mountain of my God,", 
                "num": 20
              }, 
              {
                "text": "yes, while I was speaking in prayer, the man Gabriel, whom I had seen in the vision at the beginning, being caused to fly swiftly, reached me about the time of the evening offering.", 
                "num": 21
              }, 
              {
                "text": "And he informed me, and talked with me, and said, \"O Daniel, I have now come forth to give you skill to understand.", 
                "num": 22
              }, 
              {
                "text": "At the beginning of your supplications the command went out, and I have come to tell you, for you are greatly beloved; therefore consider the matter, and understand the vision:", 
                "num": 23
              }, 
              {
                "text": "\"Seventy weeks are determined For your people and for your holy city, To finish the transgression, To make an end of sins, To make reconciliation for iniquity, To bring in everlasting righteousness, To seal up vision and prophecy, And to anoint the Most Holy.", 
                "num": 24
              }, 
              {
                "text": "\"Know therefore and understand, That from the going forth of the command To restore and build Jerusalem Until Messiah the Prince, There shall be seven weeks and sixty-two weeks; The street shall be built again, and the wall, Even in troublesome times.", 
                "num": 25
              }, 
              {
                "text": "\"And after the sixty-two weeks Messiah shall be cut off, but not for Himself; And the people of the prince who is to come Shall destroy the city and the sanctuary. The end of it shall be with a flood, And till the end of the war desolations are determined.", 
                "num": 26
              }, 
              {
                "text": "Then he shall confirm a covenant with many for one week; But in the middle of the week He shall bring an end to sacrifice and offering. And on the wing of abominations shall be one who makes desolate, Even until the consummation, which is determined, Is poured out on the desolate.\"", 
                "num": 27
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "In the third year of Cyrus king of Persia a message was revealed to Daniel, whose name was called Belteshazzar. The message was true, but the appointed time was long; and he understood the message, and had understanding of the vision.", 
                "num": 1
              }, 
              {
                "text": "In those days I, Daniel, was mourning three full weeks.", 
                "num": 2
              }, 
              {
                "text": "I ate no pleasant food, no meat or wine came into my mouth, nor did I anoint myself at all, till three whole weeks were fulfilled.", 
                "num": 3
              }, 
              {
                "text": "Now on the twenty-fourth day of the first month, as I was by the side of the great river, that is, the Tigris,", 
                "num": 4
              }, 
              {
                "text": "I lifted my eyes and looked, and behold, a certain man clothed in linen, whose waist was girded with gold of Uphaz!", 
                "num": 5
              }, 
              {
                "text": "His body was like beryl, his face like the appearance of lightning, his eyes like torches of fire, his arms and feet like burnished bronze in color, and the sound of his words like the voice of a multitude.", 
                "num": 6
              }, 
              {
                "text": "And I, Daniel, alone saw the vision, for the men who were with me did not see the vision; but a great terror fell upon them, so that they fled to hide themselves.", 
                "num": 7
              }, 
              {
                "text": "Therefore I was left alone when I saw this great vision, and no strength remained in me; for my vigor was turned to frailty in me, and I retained no strength.", 
                "num": 8
              }, 
              {
                "text": "Yet I heard the sound of his words; and while I heard the sound of his words I was in a deep sleep on my face, with my face to the ground.", 
                "num": 9
              }, 
              {
                "text": "Suddenly, a hand touched me, which made me tremble on my knees and on the palms of my hands.", 
                "num": 10
              }, 
              {
                "text": "And he said to me, \"O Daniel, man greatly beloved, understand the words that I speak to you, and stand upright, for I have now been sent to you.\" While he was speaking this word to me, I stood trembling.", 
                "num": 11
              }, 
              {
                "text": "Then he said to me, \"Do not fear, Daniel, for from the first day that you set your heart to understand, and to humble yourself before your God, your words were heard; and I have come because of your words.", 
                "num": 12
              }, 
              {
                "text": "But the prince of the kingdom of Persia withstood me twenty-one days; and behold, Michael, one of the chief princes, came to help me, for I had been left alone there with the kings of Persia.", 
                "num": 13
              }, 
              {
                "text": "Now I have come to make you understand what will happen to your people in the latter days, for the vision refers to many days yet to come.\"", 
                "num": 14
              }, 
              {
                "text": "When he had spoken such words to me, I turned my face toward the ground and became speechless.", 
                "num": 15
              }, 
              {
                "text": "And suddenly, one having the likeness of the sons of men touched my lips; then I opened my mouth and spoke, saying to him who stood before me, \"My lord, because of the vision my sorrows have overwhelmed me, and I have retained no strength.", 
                "num": 16
              }, 
              {
                "text": "For how can this servant of my lord talk with you, my lord? As for me, no strength remains in me now, nor is any breath left in me.\"", 
                "num": 17
              }, 
              {
                "text": "Then again, the one having the likeness of a man touched me and strengthened me.", 
                "num": 18
              }, 
              {
                "text": "And he said, \"O man greatly beloved, fear not! Peace be to you; be strong, yes, be strong!\" So when he spoke to me I was strengthened, and said, \"Let my lord speak, for you have strengthened me.\"", 
                "num": 19
              }, 
              {
                "text": "Then he said, \"Do you know why I have come to you? And now I must return to fight with the prince of Persia; and when I have gone forth, indeed the prince of Greece will come.", 
                "num": 20
              }, 
              {
                "text": "But I will tell you what is noted in the Scripture of Truth. (No one upholds me against these, except Michael your prince.", 
                "num": 21
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "\"Also in the first year of Darius the Mede, I, even I, stood up to confirm and strengthen him.)", 
                "num": 1
              }, 
              {
                "text": "And now I will tell you the truth: Behold, three more kings will arise in Persia, and the fourth shall be far richer than them all; by his strength, through his riches, he shall stir up all against the realm of Greece.", 
                "num": 2
              }, 
              {
                "text": "Then a mighty king shall arise, who shall rule with great dominion, and do according to his will.", 
                "num": 3
              }, 
              {
                "text": "And when he has arisen, his kingdom shall be broken up and divided toward the four winds of heaven, but not among his posterity nor according to his dominion with which he ruled; for his kingdom shall be uprooted, even for others besides these.", 
                "num": 4
              }, 
              {
                "text": "\"Also the king of the South shall become strong, as well as one of his princes; and he shall gain power over him and have dominion. His dominion shall be a great dominion.", 
                "num": 5
              }, 
              {
                "text": "And at the end of some years they shall join forces, for the daughter of the king of the South shall go to the king of the North to make an agreement; but she shall not retain the power of her authority, and neither he nor his authority shall stand; but she shall be given up, with those who brought her, and with him who begot her, and with him who strengthened her in those times.", 
                "num": 6
              }, 
              {
                "text": "But from a branch of her roots one shall arise in his place, who shall come with an army, enter the fortress of the king of the North, and deal with them and prevail.", 
                "num": 7
              }, 
              {
                "text": "And he shall also carry their gods captive to Egypt, with their princes and their precious articles of silver and gold; and he shall continue more years than the king of the North.", 
                "num": 8
              }, 
              {
                "text": "\"Also the king of the North shall come to the kingdom of the king of the South, but shall return to his own land.", 
                "num": 9
              }, 
              {
                "text": "However his sons shall stir up strife, and assemble a multitude of great forces; and one shall certainly come and overwhelm and pass through; then he shall return to his fortress and stir up strife.", 
                "num": 10
              }, 
              {
                "text": "\"And the king of the South shall be moved with rage, and go out and fight with him, with the king of the North, who shall muster a great multitude; but the multitude shall be given into the hand of his enemy.", 
                "num": 11
              }, 
              {
                "text": "When he has taken away the multitude, his heart will be lifted up; and he will cast down tens of thousands, but he will not prevail.", 
                "num": 12
              }, 
              {
                "text": "For the king of the North will return and muster a multitude greater than the former, and shall certainly come at the end of some years with a great army and much equipment.", 
                "num": 13
              }, 
              {
                "text": "\"Now in those times many shall rise up against the king of the South. Also, violent men of your people shall exalt themselves in fulfillment of the vision, but they shall fall.", 
                "num": 14
              }, 
              {
                "text": "So the king of the North shall come and build a siege mound, and take a fortified city; and the forces of the South shall not withstand him. Even his choice troops shall have no strength to resist.", 
                "num": 15
              }, 
              {
                "text": "But he who comes against him shall do according to his own will, and no one shall stand against him. He shall stand in the Glorious Land with destruction in his power.", 
                "num": 16
              }, 
              {
                "text": "\"He shall also set his face to enter with the strength of his whole kingdom, and upright ones with him; thus shall he do. And he shall give him the daughter of women to destroy it; but she shall not stand with him, or be for him.", 
                "num": 17
              }, 
              {
                "text": "After this he shall turn his face to the coastlands, and shall take many. But a ruler shall bring the reproach against them to an end; and with the reproach removed, he shall turn back on him.", 
                "num": 18
              }, 
              {
                "text": "Then he shall turn his face toward the fortress of his own land; but he shall stumble and fall, and not be found.", 
                "num": 19
              }, 
              {
                "text": "\"There shall arise in his place one who imposes taxes on the glorious kingdom; but within a few days he shall be destroyed, but not in anger or in battle.", 
                "num": 20
              }, 
              {
                "text": "And in his place shall arise a vile person, to whom they will not give the honor of royalty; but he shall come in peaceably, and seize the kingdom by intrigue.", 
                "num": 21
              }, 
              {
                "text": "With the force of a flood they shall be swept away from before him and be broken, and also the prince of the covenant.", 
                "num": 22
              }, 
              {
                "text": "And after the league is made with him he shall act deceitfully, for he shall come up and become strong with a small number of people.", 
                "num": 23
              }, 
              {
                "text": "He shall enter peaceably, even into the richest places of the province; and he shall do what his fathers have not done, nor his forefathers: he shall disperse among them the plunder, spoil, and riches; and he shall devise his plans against the strongholds, but only for a time.", 
                "num": 24
              }, 
              {
                "text": "\"He shall stir up his power and his courage against the king of the South with a great army. And the king of the South shall be stirred up to battle with a very great and mighty army; but he shall not stand, for they shall devise plans against him.", 
                "num": 25
              }, 
              {
                "text": "Yes, those who eat of the portion of his delicacies shall destroy him; his army shall be swept away, and many shall fall down slain.", 
                "num": 26
              }, 
              {
                "text": "Both these kings' hearts shall be bent on evil, and they shall speak lies at the same table; but it shall not prosper, for the end will still be at the appointed time.", 
                "num": 27
              }, 
              {
                "text": "While returning to his land with great riches, his heart shall be moved against the holy covenant; so he shall do damage and return to his own land.", 
                "num": 28
              }, 
              {
                "text": "\"At the appointed time he shall return and go toward the south; but it shall not be like the former or the latter.", 
                "num": 29
              }, 
              {
                "text": "For ships from Cyprus shall come against him; therefore he shall be grieved, and return in rage against the holy covenant, and do damage. \"So he shall return and show regard for those who forsake the holy covenant.", 
                "num": 30
              }, 
              {
                "text": "And forces shall be mustered by him, and they shall defile the sanctuary fortress; then they shall take away the daily sacrifices, and place there the abomination of desolation.", 
                "num": 31
              }, 
              {
                "text": "Those who do wickedly against the covenant he shall corrupt with flattery; but the people who know their God shall be strong, and carry out great exploits.", 
                "num": 32
              }, 
              {
                "text": "And those of the people who understand shall instruct many; yet for many days they shall fall by sword and flame, by captivity and plundering.", 
                "num": 33
              }, 
              {
                "text": "Now when they fall, they shall be aided with a little help; but many shall join with them by intrigue.", 
                "num": 34
              }, 
              {
                "text": "And some of those of understanding shall fall, to refine them, purify them, and make them white, until the time of the end; because it is still for the appointed time.", 
                "num": 35
              }, 
              {
                "text": "\"Then the king shall do according to his own will: he shall exalt and magnify himself above every god, shall speak blasphemies against the God of gods, and shall prosper till the wrath has been accomplished; for what has been determined shall be done.", 
                "num": 36
              }, 
              {
                "text": "He shall regard neither the God of his fathers nor the desire of women, nor regard any god; for he shall exalt himself above them all.", 
                "num": 37
              }, 
              {
                "text": "But in their place he shall honor a god of fortresses; and a god which his fathers did not know he shall honor with gold and silver, with precious stones and pleasant things.", 
                "num": 38
              }, 
              {
                "text": "Thus he shall act against the strongest fortresses with a foreign god, which he shall acknowledge, and advance its glory; and he shall cause them to rule over many, and divide the land for gain.", 
                "num": 39
              }, 
              {
                "text": "\"At the time of the end the king of the South shall attack him; and the king of the North shall come against him like a whirlwind, with chariots, horsemen, and with many ships; and he shall enter the countries, overwhelm them, and pass through.", 
                "num": 40
              }, 
              {
                "text": "He shall also enter the Glorious Land, and many countries shall be overthrown; but these shall escape from his hand: Edom, Moab, and the prominent people of Ammon.", 
                "num": 41
              }, 
              {
                "text": "He shall stretch out his hand against the countries, and the land of Egypt shall not escape.", 
                "num": 42
              }, 
              {
                "text": "He shall have power over the treasures of gold and silver, and over all the precious things of Egypt; also the Libyans and Ethiopians shall follow at his heels.", 
                "num": 43
              }, 
              {
                "text": "But news from the east and the north shall trouble him; therefore he shall go out with great fury to destroy and annihilate many.", 
                "num": 44
              }, 
              {
                "text": "And he shall plant the tents of his palace between the seas and the glorious holy mountain; yet he shall come to his end, and no one will help him.", 
                "num": 45
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "\"At that time Michael shall stand up, The great prince who stands watch over the sons of your people; And there shall be a time of trouble, Such as never was since there was a nation, Even to that time. And at that time your people shall be delivered, Every one who is found written in the book.", 
                "num": 1
              }, 
              {
                "text": "And many of those who sleep in the dust of the earth shall awake, Some to everlasting life, Some to shame and everlasting contempt.", 
                "num": 2
              }, 
              {
                "text": "Those who are wise shall shine Like the brightness of the firmament, And those who turn many to righteousness Like the stars forever and ever.", 
                "num": 3
              }, 
              {
                "text": "\"But you, Daniel, shut up the words, and seal the book until the time of the end; many shall run to and fro, and knowledge shall increase.\"", 
                "num": 4
              }, 
              {
                "text": "Then I, Daniel, looked; and there stood two others, one on this riverbank and the other on that riverbank.", 
                "num": 5
              }, 
              {
                "text": "And one said to the man clothed in linen, who was above the waters of the river, \"How long shall the fulfillment of these wonders be?\"", 
                "num": 6
              }, 
              {
                "text": "Then I heard the man clothed in linen, who was above the waters of the river, when he held up his right hand and his left hand to heaven, and swore by Him who lives forever, that it shall be for a time, times, and half a time; and when the power of the holy people has been completely shattered, all these things shall be finished.", 
                "num": 7
              }, 
              {
                "text": "Although I heard, I did not understand. Then I said, \"My lord, what shall be the end of these things?\"", 
                "num": 8
              }, 
              {
                "text": "And he said, \"Go your way, Daniel, for the words are closed up and sealed till the time of the end.", 
                "num": 9
              }, 
              {
                "text": "Many shall be purified, made white, and refined, but the wicked shall do wickedly; and none of the wicked shall understand, but the wise shall understand.", 
                "num": 10
              }, 
              {
                "text": "\"And from the time that the daily sacrifice is taken away, and the abomination of desolation is set up, there shall be one thousand two hundred and ninety days.", 
                "num": 11
              }, 
              {
                "text": "Blessed is he who waits, and comes to the one thousand three hundred and thirty-five days.", 
                "num": 12
              }, 
              {
                "text": "\"But you, go your way till the end; for you shall rest, and will arise to your inheritance at the end of the days.\"", 
                "num": 13
              }
            ], 
            "num": 12
          }
        ]
      }, 
      {
        "name": "Hosea", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The word of the LORD that came to Hosea the son of Beeri, in the days of Uzziah, Jotham, Ahaz, and Hezekiah, kings of Judah, and in the days of Jeroboam the son of Joash, king of Israel.", 
                "num": 1
              }, 
              {
                "text": "When the LORD began to speak by Hosea, the LORD said to Hosea: \"Go, take yourself a wife of harlotry And children of harlotry, For the land has committed great harlotry By departing from the LORD.\"", 
                "num": 2
              }, 
              {
                "text": "So he went and took Gomer the daughter of Diblaim, and she conceived and bore him a son.", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said to him: \"Call his name Jezreel, For in a little while I will avenge the bloodshed of Jezreel on the house of Jehu, And bring an end to the kingdom of the house of Israel.", 
                "num": 4
              }, 
              {
                "text": "It shall come to pass in that day That I will break the bow of Israel in the Valley of Jezreel.\"", 
                "num": 5
              }, 
              {
                "text": "And she conceived again and bore a daughter. Then God said to him: \"Call her name Lo-Ruhamah, For I will no longer have mercy on the house of Israel, But I will utterly take them away.", 
                "num": 6
              }, 
              {
                "text": "Yet I will have mercy on the house of Judah, Will save them by the LORD their God, And will not save them by bow, Nor by sword or battle, By horses or horsemen.\"", 
                "num": 7
              }, 
              {
                "text": "Now when she had weaned Lo-Ruhamah, she conceived and bore a son.", 
                "num": 8
              }, 
              {
                "text": "Then God said: \"Call his name Lo-Ammi, For you are not My people, And I will not be your God.", 
                "num": 9
              }, 
              {
                "text": "\"Yet the number of the children of Israel Shall be as the sand of the sea, Which cannot be measured or numbered. And it shall come to pass In the place where it was said to them, \"You are not My people,' There it shall be said to them, \"You are sons of the living God.'", 
                "num": 10
              }, 
              {
                "text": "Then the children of Judah and the children of Israel Shall be gathered together, And appoint for themselves one head; And they shall come up out of the land, For great will be the day of Jezreel!", 
                "num": 11
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Say to your brethren, \"My people,' And to your sisters, \"Mercy is shown.'", 
                "num": 1
              }, 
              {
                "text": "\"Bring charges against your mother, bring charges; For she is not My wife, nor am I her Husband! Let her put away her harlotries from her sight, And her adulteries from between her breasts;", 
                "num": 2
              }, 
              {
                "text": "Lest I strip her naked And expose her, as in the day she was born, And make her like a wilderness, And set her like a dry land, And slay her with thirst.", 
                "num": 3
              }, 
              {
                "text": "\"I will not have mercy on her children, For they are the children of harlotry.", 
                "num": 4
              }, 
              {
                "text": "For their mother has played the harlot; She who conceived them has behaved shamefully. For she said, \"I will go after my lovers, Who give me my bread and my water, My wool and my linen, My oil and my drink.'", 
                "num": 5
              }, 
              {
                "text": "\"Therefore, behold, I will hedge up your way with thorns, And wall her in, So that she cannot find her paths.", 
                "num": 6
              }, 
              {
                "text": "She will chase her lovers, But not overtake them; Yes, she will seek them, but not find them. Then she will say, \"I will go and return to my first husband, For then it was better for me than now.'", 
                "num": 7
              }, 
              {
                "text": "For she did not know That I gave her grain, new wine, and oil, And multiplied her silver and gold-- Which they prepared for Baal.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore I will return and take away My grain in its time And My new wine in its season, And will take back My wool and My linen, Given to cover her nakedness.", 
                "num": 9
              }, 
              {
                "text": "Now I will uncover her lewdness in the sight of her lovers, And no one shall deliver her from My hand.", 
                "num": 10
              }, 
              {
                "text": "I will also cause all her mirth to cease, Her feast days, Her New Moons, Her Sabbaths-- All her appointed feasts.", 
                "num": 11
              }, 
              {
                "text": "\"And I will destroy her vines and her fig trees, Of which she has said, \"These are my wages that my lovers have given me.' So I will make them a forest, And the beasts of the field shall eat them.", 
                "num": 12
              }, 
              {
                "text": "I will punish her For the days of the Baals to which she burned incense. She decked herself with her earrings and jewelry, And went after her lovers; But Me she forgot,\" says the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"Therefore, behold, I will allure her, Will bring her into the wilderness, And speak comfort to her.", 
                "num": 14
              }, 
              {
                "text": "I will give her her vineyards from there, And the Valley of Achor as a door of hope; She shall sing there, As in the days of her youth, As in the day when she came up from the land of Egypt.", 
                "num": 15
              }, 
              {
                "text": "\"And it shall be, in that day,\" Says the LORD, \"That you will call Me \"My Husband,' And no longer call Me \"My Master,'", 
                "num": 16
              }, 
              {
                "text": "For I will take from her mouth the names of the Baals, And they shall be remembered by their name no more.", 
                "num": 17
              }, 
              {
                "text": "In that day I will make a covenant for them With the beasts of the field, With the birds of the air, And with the creeping things of the ground. Bow and sword of battle I will shatter from the earth, To make them lie down safely.", 
                "num": 18
              }, 
              {
                "text": "\"I will betroth you to Me forever; Yes, I will betroth you to Me In righteousness and justice, In lovingkindness and mercy;", 
                "num": 19
              }, 
              {
                "text": "I will betroth you to Me in faithfulness, And you shall know the LORD.", 
                "num": 20
              }, 
              {
                "text": "\"It shall come to pass in that day That I will answer,\" says the LORD; \"I will answer the heavens, And they shall answer the earth.", 
                "num": 21
              }, 
              {
                "text": "The earth shall answer With grain, With new wine, And with oil; They shall answer Jezreel.", 
                "num": 22
              }, 
              {
                "text": "Then I will sow her for Myself in the earth, And I will have mercy on her who had not obtained mercy; Then I will say to those who were not My people, \"You are My people!' And they shall say, \"You are my God!\"'", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Then the LORD said to me, \"Go again, love a woman who is loved by a lover and is committing adultery, just like the love of the LORD for the children of Israel, who look to other gods and love the raisin cakes of the pagans.\"", 
                "num": 1
              }, 
              {
                "text": "So I bought her for myself for fifteen shekels of silver, and one and one-half homers of barley.", 
                "num": 2
              }, 
              {
                "text": "And I said to her, \"You shall stay with me many days; you shall not play the harlot, nor shall you have a man--so, too, will I be toward you.\"", 
                "num": 3
              }, 
              {
                "text": "For the children of Israel shall abide many days without king or prince, without sacrifice or sacred pillar, without ephod or teraphim.", 
                "num": 4
              }, 
              {
                "text": "Afterward the children of Israel shall return and seek the LORD their God and David their king. They shall fear the LORD and His goodness in the latter days.", 
                "num": 5
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Hear the word of the LORD, You children of Israel, For the LORD brings a charge against the inhabitants of the land: \"There is no truth or mercy Or knowledge of God in the land.", 
                "num": 1
              }, 
              {
                "text": "By swearing and lying, Killing and stealing and committing adultery, They break all restraint, With bloodshed upon bloodshed.", 
                "num": 2
              }, 
              {
                "text": "Therefore the land will mourn; And everyone who dwells there will waste away With the beasts of the field And the birds of the air; Even the fish of the sea will be taken away.", 
                "num": 3
              }, 
              {
                "text": "\"Now let no man contend, or rebuke another; For your people are like those who contend with the priest.", 
                "num": 4
              }, 
              {
                "text": "Therefore you shall stumble in the day; The prophet also shall stumble with you in the night; And I will destroy your mother.", 
                "num": 5
              }, 
              {
                "text": "My people are destroyed for lack of knowledge. Because you have rejected knowledge, I also will reject you from being priest for Me; Because you have forgotten the law of your God, I also will forget your children.", 
                "num": 6
              }, 
              {
                "text": "\"The more they increased, The more they sinned against Me; I will change their glory into shame.", 
                "num": 7
              }, 
              {
                "text": "They eat up the sin of My people; They set their heart on their iniquity.", 
                "num": 8
              }, 
              {
                "text": "And it shall be: like people, like priest. So I will punish them for their ways, And reward them for their deeds.", 
                "num": 9
              }, 
              {
                "text": "For they shall eat, but not have enough; They shall commit harlotry, but not increase; Because they have ceased obeying the LORD.", 
                "num": 10
              }, 
              {
                "text": "\"Harlotry, wine, and new wine enslave the heart.", 
                "num": 11
              }, 
              {
                "text": "My people ask counsel from their wooden idols, And their staff informs them. For the spirit of harlotry has caused them to stray, And they have played the harlot against their God.", 
                "num": 12
              }, 
              {
                "text": "They offer sacrifices on the mountaintops, And burn incense on the hills, Under oaks, poplars, and terebinths, Because their shade is good. Therefore your daughters commit harlotry, And your brides commit adultery.", 
                "num": 13
              }, 
              {
                "text": "\"I will not punish your daughters when they commit harlotry, Nor your brides when they commit adultery; For the men themselves go apart with harlots, And offer sacrifices with a ritual harlot. Therefore people who do not understand will be trampled.", 
                "num": 14
              }, 
              {
                "text": "\"Though you, Israel, play the harlot, Let not Judah offend. Do not come up to Gilgal, Nor go up to Beth Aven, Nor swear an oath, saying, \"As the LORD lives'--", 
                "num": 15
              }, 
              {
                "text": "\"For Israel is stubborn Like a stubborn calf; Now the LORD will let them forage Like a lamb in open country.", 
                "num": 16
              }, 
              {
                "text": "\"Ephraim is joined to idols, Let him alone.", 
                "num": 17
              }, 
              {
                "text": "Their drink is rebellion, They commit harlotry continually. Her rulers dearly love dishonor.", 
                "num": 18
              }, 
              {
                "text": "The wind has wrapped her up in its wings, And they shall be ashamed because of their sacrifices.", 
                "num": 19
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "\"Hear this, O priests! Take heed, O house of Israel! Give ear, O house of the king! For yours is the judgment, Because you have been a snare to Mizpah And a net spread on Tabor.", 
                "num": 1
              }, 
              {
                "text": "The revolters are deeply involved in slaughter, Though I rebuke them all.", 
                "num": 2
              }, 
              {
                "text": "I know Ephraim, And Israel is not hidden from Me; For now, O Ephraim, you commit harlotry; Israel is defiled.", 
                "num": 3
              }, 
              {
                "text": "\"They do not direct their deeds Toward turning to their God, For the spirit of harlotry is in their midst, And they do not know the LORD.", 
                "num": 4
              }, 
              {
                "text": "The pride of Israel testifies to his face; Therefore Israel and Ephraim stumble in their iniquity; Judah also stumbles with them.", 
                "num": 5
              }, 
              {
                "text": "\"With their flocks and herds They shall go to seek the LORD, But they will not find Him; He has withdrawn Himself from them.", 
                "num": 6
              }, 
              {
                "text": "They have dealt treacherously with the LORD, For they have begotten pagan children. Now a New Moon shall devour them and their heritage.", 
                "num": 7
              }, 
              {
                "text": "\"Blow the ram's horn in Gibeah, The trumpet in Ramah! Cry aloud at Beth Aven, \"Look behind you, O Benjamin!'", 
                "num": 8
              }, 
              {
                "text": "Ephraim shall be desolate in the day of rebuke; Among the tribes of Israel I make known what is sure.", 
                "num": 9
              }, 
              {
                "text": "\"The princes of Judah are like those who remove a landmark; I will pour out My wrath on them like water.", 
                "num": 10
              }, 
              {
                "text": "Ephraim is oppressed and broken in judgment, Because he willingly walked by human precept.", 
                "num": 11
              }, 
              {
                "text": "Therefore I will be to Ephraim like a moth, And to the house of Judah like rottenness.", 
                "num": 12
              }, 
              {
                "text": "\"When Ephraim saw his sickness, And Judah saw his wound, Then Ephraim went to Assyria And sent to King Jareb; Yet he cannot cure you, Nor heal you of your wound.", 
                "num": 13
              }, 
              {
                "text": "For I will be like a lion to Ephraim, And like a young lion to the house of Judah. I, even I, will tear them and go away; I will take them away, and no one shall rescue.", 
                "num": 14
              }, 
              {
                "text": "I will return again to My place Till they acknowledge their offense. Then they will seek My face; In their affliction they will earnestly seek Me.\"", 
                "num": 15
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Come, and let us return to the LORD; For He has torn, but He will heal us; He has stricken, but He will bind us up.", 
                "num": 1
              }, 
              {
                "text": "After two days He will revive us; On the third day He will raise us up, That we may live in His sight.", 
                "num": 2
              }, 
              {
                "text": "Let us know, Let us pursue the knowledge of the LORD. His going forth is established as the morning; He will come to us like the rain, Like the latter and former rain to the earth.", 
                "num": 3
              }, 
              {
                "text": "\"O Ephraim, what shall I do to you? O Judah, what shall I do to you? For your faithfulness is like a morning cloud, And like the early dew it goes away.", 
                "num": 4
              }, 
              {
                "text": "Therefore I have hewn them by the prophets, I have slain them by the words of My mouth; And your judgments are like light that goes forth.", 
                "num": 5
              }, 
              {
                "text": "For I desire mercy and not sacrifice, And the knowledge of God more than burnt offerings.", 
                "num": 6
              }, 
              {
                "text": "\"But like men they transgressed the covenant; There they dealt treacherously with Me.", 
                "num": 7
              }, 
              {
                "text": "Gilead is a city of evildoers And defiled with blood.", 
                "num": 8
              }, 
              {
                "text": "As bands of robbers lie in wait for a man, So the company of priests murder on the way to Shechem; Surely they commit lewdness.", 
                "num": 9
              }, 
              {
                "text": "I have seen a horrible thing in the house of Israel: There is the harlotry of Ephraim; Israel is defiled.", 
                "num": 10
              }, 
              {
                "text": "Also, O Judah, a harvest is appointed for you, When I return the captives of My people.", 
                "num": 11
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "\"When I would have healed Israel, Then the iniquity of Ephraim was uncovered, And the wickedness of Samaria. For they have committed fraud; A thief comes in; A band of robbers takes spoil outside.", 
                "num": 1
              }, 
              {
                "text": "They do not consider in their hearts That I remember all their wickedness; Now their own deeds have surrounded them; They are before My face.", 
                "num": 2
              }, 
              {
                "text": "They make a king glad with their wickedness, And princes with their lies.", 
                "num": 3
              }, 
              {
                "text": "\"They are all adulterers. Like an oven heated by a baker-- He ceases stirring the fire after kneading the dough, Until it is leavened.", 
                "num": 4
              }, 
              {
                "text": "In the day of our king Princes have made him sick, inflamed with wine; He stretched out his hand with scoffers.", 
                "num": 5
              }, 
              {
                "text": "They prepare their heart like an oven, While they lie in wait; Their baker sleeps all night; In the morning it burns like a flaming fire.", 
                "num": 6
              }, 
              {
                "text": "They are all hot, like an oven, And have devoured their judges; All their kings have fallen. None among them calls upon Me.", 
                "num": 7
              }, 
              {
                "text": "\"Ephraim has mixed himself among the peoples; Ephraim is a cake unturned.", 
                "num": 8
              }, 
              {
                "text": "Aliens have devoured his strength, But he does not know it; Yes, gray hairs are here and there on him, Yet he does not know it.", 
                "num": 9
              }, 
              {
                "text": "And the pride of Israel testifies to his face, But they do not return to the LORD their God, Nor seek Him for all this.", 
                "num": 10
              }, 
              {
                "text": "\"Ephraim also is like a silly dove, without sense-- They call to Egypt, They go to Assyria.", 
                "num": 11
              }, 
              {
                "text": "Wherever they go, I will spread My net on them; I will bring them down like birds of the air; I will chastise them According to what their congregation has heard.", 
                "num": 12
              }, 
              {
                "text": "\"Woe to them, for they have fled from Me! Destruction to them, Because they have transgressed against Me! Though I redeemed them, Yet they have spoken lies against Me.", 
                "num": 13
              }, 
              {
                "text": "They did not cry out to Me with their heart When they wailed upon their beds. \"They assemble together for grain and new wine, They rebel against Me;", 
                "num": 14
              }, 
              {
                "text": "Though I disciplined and strengthened their arms, Yet they devise evil against Me;", 
                "num": 15
              }, 
              {
                "text": "They return, but not to the Most High; They are like a treacherous bow. Their princes shall fall by the sword For the cursings of their tongue. This shall be their derision in the land of Egypt.", 
                "num": 16
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "\"Set the trumpet to your mouth! He shall come like an eagle against the house of the LORD, Because they have transgressed My covenant And rebelled against My law.", 
                "num": 1
              }, 
              {
                "text": "Israel will cry to Me, \"My God, we know You!'", 
                "num": 2
              }, 
              {
                "text": "Israel has rejected the good; The enemy will pursue him.", 
                "num": 3
              }, 
              {
                "text": "\"They set up kings, but not by Me; They made princes, but I did not acknowledge them. From their silver and gold They made idols for themselves-- That they might be cut off.", 
                "num": 4
              }, 
              {
                "text": "Your calf is rejected, O Samaria! My anger is aroused against them-- How long until they attain to innocence?", 
                "num": 5
              }, 
              {
                "text": "For from Israel is even this: A workman made it, and it is not God; But the calf of Samaria shall be broken to pieces.", 
                "num": 6
              }, 
              {
                "text": "\"They sow the wind, And reap the whirlwind. The stalk has no bud; It shall never produce meal. If it should produce, Aliens would swallow it up.", 
                "num": 7
              }, 
              {
                "text": "Israel is swallowed up; Now they are among the Gentiles Like a vessel in which is no pleasure.", 
                "num": 8
              }, 
              {
                "text": "For they have gone up to Assyria, Like a wild donkey alone by itself; Ephraim has hired lovers.", 
                "num": 9
              }, 
              {
                "text": "Yes, though they have hired among the nations, Now I will gather them; And they shall sorrow a little, Because of the burden of the king of princes.", 
                "num": 10
              }, 
              {
                "text": "\"Because Ephraim has made many altars for sin, They have become for him altars for sinning.", 
                "num": 11
              }, 
              {
                "text": "I have written for him the great things of My law, But they were considered a strange thing.", 
                "num": 12
              }, 
              {
                "text": "For the sacrifices of My offerings they sacrifice flesh and eat it, But the LORD does not accept them. Now He will remember their iniquity and punish their sins. They shall return to Egypt.", 
                "num": 13
              }, 
              {
                "text": "\"For Israel has forgotten his Maker, And has built temples; Judah also has multiplied fortified cities; But I will send fire upon his cities, And it shall devour his palaces.\"", 
                "num": 14
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Do not rejoice, O Israel, with joy like other peoples, For you have played the harlot against your God. You have made love for hire on every threshing floor.", 
                "num": 1
              }, 
              {
                "text": "The threshing floor and the winepress Shall not feed them, And the new wine shall fail in her.", 
                "num": 2
              }, 
              {
                "text": "They shall not dwell in the LORD's land, But Ephraim shall return to Egypt, And shall eat unclean things in Assyria.", 
                "num": 3
              }, 
              {
                "text": "They shall not offer wine offerings to the LORD, Nor shall their sacrifices be pleasing to Him. It shall be like bread of mourners to them; All who eat it shall be defiled. For their bread shall be for their own life; It shall not come into the house of the LORD.", 
                "num": 4
              }, 
              {
                "text": "What will you do in the appointed day, And in the day of the feast of the LORD?", 
                "num": 5
              }, 
              {
                "text": "For indeed they are gone because of destruction. Egypt shall gather them up; Memphis shall bury them. Nettles shall possess their valuables of silver; Thorns shall be in their tents.", 
                "num": 6
              }, 
              {
                "text": "The days of punishment have come; The days of recompense have come. Israel knows! The prophet is a fool, The spiritual man is insane, Because of the greatness of your iniquity and great enmity.", 
                "num": 7
              }, 
              {
                "text": "The watchman of Ephraim is with my God; But the prophet is a fowler's snare in all his ways-- Enmity in the house of his God.", 
                "num": 8
              }, 
              {
                "text": "They are deeply corrupted, As in the days of Gibeah. He will remember their iniquity; He will punish their sins.", 
                "num": 9
              }, 
              {
                "text": "\"I found Israel Like grapes in the wilderness; I saw your fathers As the firstfruits on the fig tree in its first season. But they went to Baal Peor, And separated themselves to that shame; They became an abomination like the thing they loved.", 
                "num": 10
              }, 
              {
                "text": "As for Ephraim, their glory shall fly away like a bird-- No birth, no pregnancy, and no conception!", 
                "num": 11
              }, 
              {
                "text": "Though they bring up their children, Yet I will bereave them to the last man. Yes, woe to them when I depart from them!", 
                "num": 12
              }, 
              {
                "text": "Just as I saw Ephraim like Tyre, planted in a pleasant place, So Ephraim will bring out his children to the murderer.\"", 
                "num": 13
              }, 
              {
                "text": "Give them, O LORD-- What will You give? Give them a miscarrying womb And dry breasts!", 
                "num": 14
              }, 
              {
                "text": "\"All their wickedness is in Gilgal, For there I hated them. Because of the evil of their deeds I will drive them from My house; I will love them no more. All their princes are rebellious.", 
                "num": 15
              }, 
              {
                "text": "Ephraim is stricken, Their root is dried up; They shall bear no fruit. Yes, were they to bear children, I would kill the darlings of their womb.\"", 
                "num": 16
              }, 
              {
                "text": "My God will cast them away, Because they did not obey Him; And they shall be wanderers among the nations.", 
                "num": 17
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Israel empties his vine; He brings forth fruit for himself. According to the multitude of his fruit He has increased the altars; According to the bounty of his land They have embellished his sacred pillars.", 
                "num": 1
              }, 
              {
                "text": "Their heart is divided; Now they are held guilty. He will break down their altars; He will ruin their sacred pillars.", 
                "num": 2
              }, 
              {
                "text": "For now they say, \"We have no king, Because we did not fear the LORD. And as for a king, what would he do for us?\"", 
                "num": 3
              }, 
              {
                "text": "They have spoken words, Swearing falsely in making a covenant. Thus judgment springs up like hemlock in the furrows of the field.", 
                "num": 4
              }, 
              {
                "text": "The inhabitants of Samaria fear Because of the calf of Beth Aven. For its people mourn for it, And its priests shriek for it-- Because its glory has departed from it.", 
                "num": 5
              }, 
              {
                "text": "The idol also shall be carried to Assyria As a present for King Jareb. Ephraim shall receive shame, And Israel shall be ashamed of his own counsel.", 
                "num": 6
              }, 
              {
                "text": "As for Samaria, her king is cut off Like a twig on the water.", 
                "num": 7
              }, 
              {
                "text": "Also the high places of Aven, the sin of Israel, Shall be destroyed. The thorn and thistle shall grow on their altars; They shall say to the mountains, \"Cover us!\" And to the hills, \"Fall on us!\"", 
                "num": 8
              }, 
              {
                "text": "\"O Israel, you have sinned from the days of Gibeah; There they stood. The battle in Gibeah against the children of iniquity Did not overtake them.", 
                "num": 9
              }, 
              {
                "text": "When it is My desire, I will chasten them. Peoples shall be gathered against them When I bind them for their two transgressions.", 
                "num": 10
              }, 
              {
                "text": "Ephraim is a trained heifer That loves to thresh grain; But I harnessed her fair neck, I will make Ephraim pull a plow. Judah shall plow; Jacob shall break his clods.\"", 
                "num": 11
              }, 
              {
                "text": "Sow for yourselves righteousness; Reap in mercy; Break up your fallow ground, For it is time to seek the LORD, Till He comes and rains righteousness on you.", 
                "num": 12
              }, 
              {
                "text": "You have plowed wickedness; You have reaped iniquity. You have eaten the fruit of lies, Because you trusted in your own way, In the multitude of your mighty men.", 
                "num": 13
              }, 
              {
                "text": "Therefore tumult shall arise among your people, And all your fortresses shall be plundered As Shalman plundered Beth Arbel in the day of battle-- A mother dashed in pieces upon her children.", 
                "num": 14
              }, 
              {
                "text": "Thus it shall be done to you, O Bethel, Because of your great wickedness. At dawn the king of Israel Shall be cut off utterly.", 
                "num": 15
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "\"When Israel was a child, I loved him, And out of Egypt I called My son.", 
                "num": 1
              }, 
              {
                "text": "As they called them, So they went from them; They sacrificed to the Baals, And burned incense to carved images.", 
                "num": 2
              }, 
              {
                "text": "\"I taught Ephraim to walk, Taking them by their arms; But they did not know that I healed them.", 
                "num": 3
              }, 
              {
                "text": "I drew them with gentle cords, With bands of love, And I was to them as those who take the yoke from their neck. I stooped and fed them.", 
                "num": 4
              }, 
              {
                "text": "\"He shall not return to the land of Egypt; But the Assyrian shall be his king, Because they refused to repent.", 
                "num": 5
              }, 
              {
                "text": "And the sword shall slash in his cities, Devour his districts, And consume them, Because of their own counsels.", 
                "num": 6
              }, 
              {
                "text": "My people are bent on backsliding from Me. Though they call to the Most High, None at all exalt Him.", 
                "num": 7
              }, 
              {
                "text": "\"How can I give you up, Ephraim? How can I hand you over, Israel? How can I make you like Admah? How can I set you like Zeboiim? My heart churns within Me; My sympathy is stirred.", 
                "num": 8
              }, 
              {
                "text": "I will not execute the fierceness of My anger; I will not again destroy Ephraim. For I am God, and not man, The Holy One in your midst; And I will not come with terror.", 
                "num": 9
              }, 
              {
                "text": "\"They shall walk after the LORD. He will roar like a lion. When He roars, Then His sons shall come trembling from the west;", 
                "num": 10
              }, 
              {
                "text": "They shall come trembling like a bird from Egypt, Like a dove from the land of Assyria. And I will let them dwell in their houses,\" Says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"Ephraim has encircled Me with lies, And the house of Israel with deceit; But Judah still walks with God, Even with the Holy One who is faithful.", 
                "num": 12
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "\"Ephraim feeds on the wind, And pursues the east wind; He daily increases lies and desolation. Also they make a covenant with the Assyrians, And oil is carried to Egypt.", 
                "num": 1
              }, 
              {
                "text": "\"The LORD also brings a charge against Judah, And will punish Jacob according to his ways; According to his deeds He will recompense him.", 
                "num": 2
              }, 
              {
                "text": "He took his brother by the heel in the womb, And in his strength he struggled with God.", 
                "num": 3
              }, 
              {
                "text": "Yes, he struggled with the Angel and prevailed; He wept, and sought favor from Him. He found Him in Bethel, And there He spoke to us--", 
                "num": 4
              }, 
              {
                "text": "That is, the LORD God of hosts. The LORD is His memorable name.", 
                "num": 5
              }, 
              {
                "text": "So you, by the help of your God, return; Observe mercy and justice, And wait on your God continually.", 
                "num": 6
              }, 
              {
                "text": "\"A cunning Canaanite! Deceitful scales are in his hand; He loves to oppress.", 
                "num": 7
              }, 
              {
                "text": "And Ephraim said, \"Surely I have become rich, I have found wealth for myself; In all my labors They shall find in me no iniquity that is sin.'", 
                "num": 8
              }, 
              {
                "text": "\"But I am the LORD your God, Ever since the land of Egypt; I will again make you dwell in tents, As in the days of the appointed feast.", 
                "num": 9
              }, 
              {
                "text": "I have also spoken by the prophets, And have multiplied visions; I have given symbols through the witness of the prophets.\"", 
                "num": 10
              }, 
              {
                "text": "Though Gilead has idols-- Surely they are vanity-- Though they sacrifice bulls in Gilgal, Indeed their altars shall be heaps in the furrows of the field.", 
                "num": 11
              }, 
              {
                "text": "Jacob fled to the country of Syria; Israel served for a spouse, And for a wife he tended sheep.", 
                "num": 12
              }, 
              {
                "text": "By a prophet the LORD brought Israel out of Egypt, And by a prophet he was preserved.", 
                "num": 13
              }, 
              {
                "text": "Ephraim provoked Him to anger most bitterly; Therefore his Lord will leave the guilt of his bloodshed upon him, And return his reproach upon him.", 
                "num": 14
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "When Ephraim spoke, trembling, He exalted himself in Israel; But when he offended through Baal worship, he died.", 
                "num": 1
              }, 
              {
                "text": "Now they sin more and more, And have made for themselves molded images, Idols of their silver, according to their skill; All of it is the work of craftsmen. They say of them, \"Let the men who sacrifice kiss the calves!\"", 
                "num": 2
              }, 
              {
                "text": "Therefore they shall be like the morning cloud And like the early dew that passes away, Like chaff blown off from a threshing floor And like smoke from a chimney.", 
                "num": 3
              }, 
              {
                "text": "\"Yet I am the LORD your God Ever since the land of Egypt, And you shall know no God but Me; For there is no savior besides Me.", 
                "num": 4
              }, 
              {
                "text": "I knew you in the wilderness, In the land of great drought.", 
                "num": 5
              }, 
              {
                "text": "When they had pasture, they were filled; They were filled and their heart was exalted; Therefore they forgot Me.", 
                "num": 6
              }, 
              {
                "text": "\"So I will be to them like a lion; Like a leopard by the road I will lurk;", 
                "num": 7
              }, 
              {
                "text": "I will meet them like a bear deprived of her cubs; I will tear open their rib cage, And there I will devour them like a lion. The wild beast shall tear them.", 
                "num": 8
              }, 
              {
                "text": "\"O Israel, you are destroyed, But your help is from Me.", 
                "num": 9
              }, 
              {
                "text": "I will be your King; Where is any other, That he may save you in all your cities? And your judges to whom you said, \"Give me a king and princes'?", 
                "num": 10
              }, 
              {
                "text": "I gave you a king in My anger, And took him away in My wrath.", 
                "num": 11
              }, 
              {
                "text": "\"The iniquity of Ephraim is bound up; His sin is stored up.", 
                "num": 12
              }, 
              {
                "text": "The sorrows of a woman in childbirth shall come upon him. He is an unwise son, For he should not stay long where children are born.", 
                "num": 13
              }, 
              {
                "text": "\"I will ransom them from the power of the grave; I will redeem them from death. O Death, I will be your plagues! O Grave, I will be your destruction! Pity is hidden from My eyes.\"", 
                "num": 14
              }, 
              {
                "text": "Though he is fruitful among his brethren, An east wind shall come; The wind of the LORD shall come up from the wilderness. Then his spring shall become dry, And his fountain shall be dried up. He shall plunder the treasury of every desirable prize.", 
                "num": 15
              }, 
              {
                "text": "Samaria is held guilty, For she has rebelled against her God. They shall fall by the sword, Their infants shall be dashed in pieces, And their women with child ripped open.", 
                "num": 16
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "O Israel, return to the LORD your God, For you have stumbled because of your iniquity;", 
                "num": 1
              }, 
              {
                "text": "Take words with you, And return to the LORD. Say to Him, \"Take away all iniquity; Receive us graciously, For we will offer the sacrifices of our lips.", 
                "num": 2
              }, 
              {
                "text": "Assyria shall not save us, We will not ride on horses, Nor will we say anymore to the work of our hands, \"You are our gods.' For in You the fatherless finds mercy.\"", 
                "num": 3
              }, 
              {
                "text": "\"I will heal their backsliding, I will love them freely, For My anger has turned away from him.", 
                "num": 4
              }, 
              {
                "text": "I will be like the dew to Israel; He shall grow like the lily, And lengthen his roots like Lebanon.", 
                "num": 5
              }, 
              {
                "text": "His branches shall spread; His beauty shall be like an olive tree, And his fragrance like Lebanon.", 
                "num": 6
              }, 
              {
                "text": "Those who dwell under his shadow shall return; They shall be revived like grain, And grow like a vine. Their scent shall be like the wine of Lebanon.", 
                "num": 7
              }, 
              {
                "text": "\"Ephraim shall say, \"What have I to do anymore with idols?' I have heard and observed him. I am like a green cypress tree; Your fruit is found in Me.\"", 
                "num": 8
              }, 
              {
                "text": "Who is wise? Let him understand these things. Who is prudent? Let him know them. For the ways of the LORD are right; The righteous walk in them, But transgressors stumble in them.", 
                "num": 9
              }
            ], 
            "num": 14
          }
        ]
      }, 
      {
        "name": "Joel", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The word of the LORD that came to Joel the son of Pethuel.", 
                "num": 1
              }, 
              {
                "text": "Hear this, you elders, And give ear, all you inhabitants of the land! Has anything like this happened in your days, Or even in the days of your fathers?", 
                "num": 2
              }, 
              {
                "text": "Tell your children about it, Let your children tell their children, And their children another generation.", 
                "num": 3
              }, 
              {
                "text": "What the chewing locust left, the swarming locust has eaten; What the swarming locust left, the crawling locust has eaten; And what the crawling locust left, the consuming locust has eaten.", 
                "num": 4
              }, 
              {
                "text": "Awake, you drunkards, and weep; And wail, all you drinkers of wine, Because of the new wine, For it has been cut off from your mouth.", 
                "num": 5
              }, 
              {
                "text": "For a nation has come up against My land, Strong, and without number; His teeth are the teeth of a lion, And he has the fangs of a fierce lion.", 
                "num": 6
              }, 
              {
                "text": "He has laid waste My vine, And ruined My fig tree; He has stripped it bare and thrown it away; Its branches are made white.", 
                "num": 7
              }, 
              {
                "text": "Lament like a virgin girded with sackcloth For the husband of her youth.", 
                "num": 8
              }, 
              {
                "text": "The grain offering and the drink offering Have been cut off from the house of the LORD; The priests mourn, who minister to the LORD.", 
                "num": 9
              }, 
              {
                "text": "The field is wasted, The land mourns; For the grain is ruined, The new wine is dried up, The oil fails.", 
                "num": 10
              }, 
              {
                "text": "Be ashamed, you farmers, Wail, you vinedressers, For the wheat and the barley; Because the harvest of the field has perished.", 
                "num": 11
              }, 
              {
                "text": "The vine has dried up, And the fig tree has withered; The pomegranate tree, The palm tree also, And the apple tree-- All the trees of the field are withered; Surely joy has withered away from the sons of men.", 
                "num": 12
              }, 
              {
                "text": "Gird yourselves and lament, you priests; Wail, you who minister before the altar; Come, lie all night in sackcloth, You who minister to my God; For the grain offering and the drink offering Are withheld from the house of your God.", 
                "num": 13
              }, 
              {
                "text": "Consecrate a fast, Call a sacred assembly; Gather the elders And all the inhabitants of the land Into the house of the LORD your God, And cry out to the LORD.", 
                "num": 14
              }, 
              {
                "text": "Alas for the day! For the day of the LORD is at hand; It shall come as destruction from the Almighty.", 
                "num": 15
              }, 
              {
                "text": "Is not the food cut off before our eyes, Joy and gladness from the house of our God?", 
                "num": 16
              }, 
              {
                "text": "The seed shrivels under the clods, Storehouses are in shambles; Barns are broken down, For the grain has withered.", 
                "num": 17
              }, 
              {
                "text": "How the animals groan! The herds of cattle are restless, Because they have no pasture; Even the flocks of sheep suffer punishment.", 
                "num": 18
              }, 
              {
                "text": "O LORD, to You I cry out; For fire has devoured the open pastures, And a flame has burned all the trees of the field.", 
                "num": 19
              }, 
              {
                "text": "The beasts of the field also cry out to You, For the water brooks are dried up, And fire has devoured the open pastures.", 
                "num": 20
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Blow the trumpet in Zion, And sound an alarm in My holy mountain! Let all the inhabitants of the land tremble; For the day of the LORD is coming, For it is at hand:", 
                "num": 1
              }, 
              {
                "text": "A day of darkness and gloominess, A day of clouds and thick darkness, Like the morning clouds spread over the mountains. A people come, great and strong, The like of whom has never been; Nor will there ever be any such after them, Even for many successive generations.", 
                "num": 2
              }, 
              {
                "text": "A fire devours before them, And behind them a flame burns; The land is like the Garden of Eden before them, And behind them a desolate wilderness; Surely nothing shall escape them.", 
                "num": 3
              }, 
              {
                "text": "Their appearance is like the appearance of horses; And like swift steeds, so they run.", 
                "num": 4
              }, 
              {
                "text": "With a noise like chariots Over mountaintops they leap, Like the noise of a flaming fire that devours the stubble, Like a strong people set in battle array.", 
                "num": 5
              }, 
              {
                "text": "Before them the people writhe in pain; All faces are drained of color.", 
                "num": 6
              }, 
              {
                "text": "They run like mighty men, They climb the wall like men of war; Every one marches in formation, And they do not break ranks.", 
                "num": 7
              }, 
              {
                "text": "They do not push one another; Every one marches in his own column. Though they lunge between the weapons, They are not cut down.", 
                "num": 8
              }, 
              {
                "text": "They run to and fro in the city, They run on the wall; They climb into the houses, They enter at the windows like a thief.", 
                "num": 9
              }, 
              {
                "text": "The earth quakes before them, The heavens tremble; The sun and moon grow dark, And the stars diminish their brightness.", 
                "num": 10
              }, 
              {
                "text": "The LORD gives voice before His army, For His camp is very great; For strong is the One who executes His word. For the day of the LORD is great and very terrible; Who can endure it?", 
                "num": 11
              }, 
              {
                "text": "\"Now, therefore,\" says the LORD, \"Turn to Me with all your heart, With fasting, with weeping, and with mourning.\"", 
                "num": 12
              }, 
              {
                "text": "So rend your heart, and not your garments; Return to the LORD your God, For He is gracious and merciful, Slow to anger, and of great kindness; And He relents from doing harm.", 
                "num": 13
              }, 
              {
                "text": "Who knows if He will turn and relent, And leave a blessing behind Him-- A grain offering and a drink offering For the LORD your God?", 
                "num": 14
              }, 
              {
                "text": "Blow the trumpet in Zion, Consecrate a fast, Call a sacred assembly;", 
                "num": 15
              }, 
              {
                "text": "Gather the people, Sanctify the congregation, Assemble the elders, Gather the children and nursing babes; Let the bridegroom go out from his chamber, And the bride from her dressing room.", 
                "num": 16
              }, 
              {
                "text": "Let the priests, who minister to the LORD, Weep between the porch and the altar; Let them say, \"Spare Your people, O LORD, And do not give Your heritage to reproach, That the nations should rule over them. Why should they say among the peoples, \"Where is their God?\"'", 
                "num": 17
              }, 
              {
                "text": "Then the LORD will be zealous for His land, And pity His people.", 
                "num": 18
              }, 
              {
                "text": "The LORD will answer and say to His people, \"Behold, I will send you grain and new wine and oil, And you will be satisfied by them; I will no longer make you a reproach among the nations.", 
                "num": 19
              }, 
              {
                "text": "\"But I will remove far from you the northern army, And will drive him away into a barren and desolate land, With his face toward the eastern sea And his back toward the western sea; His stench will come up, And his foul odor will rise, Because he has done monstrous things.\"", 
                "num": 20
              }, 
              {
                "text": "Fear not, O land; Be glad and rejoice, For the LORD has done marvelous things!", 
                "num": 21
              }, 
              {
                "text": "Do not be afraid, you beasts of the field; For the open pastures are springing up, And the tree bears its fruit; The fig tree and the vine yield their strength.", 
                "num": 22
              }, 
              {
                "text": "Be glad then, you children of Zion, And rejoice in the LORD your God; For He has given you the former rain faithfully, And He will cause the rain to come down for you-- The former rain, And the latter rain in the first month.", 
                "num": 23
              }, 
              {
                "text": "The threshing floors shall be full of wheat, And the vats shall overflow with new wine and oil.", 
                "num": 24
              }, 
              {
                "text": "\"So I will restore to you the years that the swarming locust has eaten, The crawling locust, The consuming locust, And the chewing locust, My great army which I sent among you.", 
                "num": 25
              }, 
              {
                "text": "You shall eat in plenty and be satisfied, And praise the name of the LORD your God, Who has dealt wondrously with you; And My people shall never be put to shame.", 
                "num": 26
              }, 
              {
                "text": "Then you shall know that I am in the midst of Israel: I am the LORD your God And there is no other. My people shall never be put to shame.", 
                "num": 27
              }, 
              {
                "text": "\"And it shall come to pass afterward That I will pour out My Spirit on all flesh; Your sons and your daughters shall prophesy, Your old men shall dream dreams, Your young men shall see visions.", 
                "num": 28
              }, 
              {
                "text": "And also on My menservants and on My maidservants I will pour out My Spirit in those days.", 
                "num": 29
              }, 
              {
                "text": "\"And I will show wonders in the heavens and in the earth: Blood and fire and pillars of smoke.", 
                "num": 30
              }, 
              {
                "text": "The sun shall be turned into darkness, And the moon into blood, Before the coming of the great and awesome day of the LORD.", 
                "num": 31
              }, 
              {
                "text": "And it shall come to pass That whoever calls on the name of the LORD Shall be saved. For in Mount Zion and in Jerusalem there shall be deliverance, As the LORD has said, Among the remnant whom the LORD calls.", 
                "num": 32
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"For behold, in those days and at that time, When I bring back the captives of Judah and Jerusalem,", 
                "num": 1
              }, 
              {
                "text": "I will also gather all nations, And bring them down to the Valley of Jehoshaphat; And I will enter into judgment with them there On account of My people, My heritage Israel, Whom they have scattered among the nations; They have also divided up My land.", 
                "num": 2
              }, 
              {
                "text": "They have cast lots for My people, Have given a boy as payment for a harlot, And sold a girl for wine, that they may drink.", 
                "num": 3
              }, 
              {
                "text": "\"Indeed, what have you to do with Me, O Tyre and Sidon, and all the coasts of Philistia? Will you retaliate against Me? But if you retaliate against Me, Swiftly and speedily I will return your retaliation upon your own head;", 
                "num": 4
              }, 
              {
                "text": "Because you have taken My silver and My gold, And have carried into your temples My prized possessions.", 
                "num": 5
              }, 
              {
                "text": "Also the people of Judah and the people of Jerusalem You have sold to the Greeks, That you may remove them far from their borders.", 
                "num": 6
              }, 
              {
                "text": "\"Behold, I will raise them Out of the place to which you have sold them, And will return your retaliation upon your own head.", 
                "num": 7
              }, 
              {
                "text": "I will sell your sons and your daughters Into the hand of the people of Judah, And they will sell them to the Sabeans, To a people far off; For the LORD has spoken.\"", 
                "num": 8
              }, 
              {
                "text": "Proclaim this among the nations: \"Prepare for war! Wake up the mighty men, Let all the men of war draw near, Let them come up.", 
                "num": 9
              }, 
              {
                "text": "Beat your plowshares into swords And your pruning hooks into spears; Let the weak say, \"I am strong.\"'", 
                "num": 10
              }, 
              {
                "text": "Assemble and come, all you nations, And gather together all around. Cause Your mighty ones to go down there, O LORD.", 
                "num": 11
              }, 
              {
                "text": "\"Let the nations be wakened, and come up to the Valley of Jehoshaphat; For there I will sit to judge all the surrounding nations.", 
                "num": 12
              }, 
              {
                "text": "Put in the sickle, for the harvest is ripe. Come, go down; For the winepress is full, The vats overflow-- For their wickedness is great.\"", 
                "num": 13
              }, 
              {
                "text": "Multitudes, multitudes in the valley of decision! For the day of the LORD is near in the valley of decision.", 
                "num": 14
              }, 
              {
                "text": "The sun and moon will grow dark, And the stars will diminish their brightness.", 
                "num": 15
              }, 
              {
                "text": "The LORD also will roar from Zion, And utter His voice from Jerusalem; The heavens and earth will shake; But the LORD will be a shelter for His people, And the strength of the children of Israel.", 
                "num": 16
              }, 
              {
                "text": "\"So you shall know that I am the LORD your God, Dwelling in Zion My holy mountain. Then Jerusalem shall be holy, And no aliens shall ever pass through her again.\"", 
                "num": 17
              }, 
              {
                "text": "And it will come to pass in that day That the mountains shall drip with new wine, The hills shall flow with milk, And all the brooks of Judah shall be flooded with water; A fountain shall flow from the house of the LORD And water the Valley of Acacias.", 
                "num": 18
              }, 
              {
                "text": "\"Egypt shall be a desolation, And Edom a desolate wilderness, Because of violence against the people of Judah, For they have shed innocent blood in their land.", 
                "num": 19
              }, 
              {
                "text": "But Judah shall abide forever, And Jerusalem from generation to generation.", 
                "num": 20
              }, 
              {
                "text": "For I will acquit them of the guilt of bloodshed, whom I had not acquitted; For the LORD dwells in Zion.\"", 
                "num": 21
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "Amos", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The words of Amos, who was among the sheepbreeders of Tekoa, which he saw concerning Israel in the days of Uzziah king of Judah, and in the days of Jeroboam the son of Joash, king of Israel, two years before the earthquake.", 
                "num": 1
              }, 
              {
                "text": "And he said: \"The LORD roars from Zion, And utters His voice from Jerusalem; The pastures of the shepherds mourn, And the top of Carmel withers.\"", 
                "num": 2
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Damascus, and for four, I will not turn away its punishment, Because they have threshed Gilead with implements of iron.", 
                "num": 3
              }, 
              {
                "text": "But I will send a fire into the house of Hazael, Which shall devour the palaces of Ben-Hadad.", 
                "num": 4
              }, 
              {
                "text": "I will also break the gate bar of Damascus, And cut off the inhabitant from the Valley of Aven, And the one who holds the scepter from Beth Eden. The people of Syria shall go captive to Kir,\" Says the LORD.", 
                "num": 5
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Gaza, and for four, I will not turn away its punishment, Because they took captive the whole captivity To deliver them up to Edom.", 
                "num": 6
              }, 
              {
                "text": "But I will send a fire upon the wall of Gaza, Which shall devour its palaces.", 
                "num": 7
              }, 
              {
                "text": "I will cut off the inhabitant from Ashdod, And the one who holds the scepter from Ashkelon; I will turn My hand against Ekron, And the remnant of the Philistines shall perish,\" Says the Lord GOD.", 
                "num": 8
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Tyre, and for four, I will not turn away its punishment, Because they delivered up the whole captivity to Edom, And did not remember the covenant of brotherhood.", 
                "num": 9
              }, 
              {
                "text": "But I will send a fire upon the wall of Tyre, Which shall devour its palaces.\"", 
                "num": 10
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Edom, and for four, I will not turn away its punishment, Because he pursued his brother with the sword, And cast off all pity; His anger tore perpetually, And he kept his wrath forever.", 
                "num": 11
              }, 
              {
                "text": "But I will send a fire upon Teman, Which shall devour the palaces of Bozrah.\"", 
                "num": 12
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of the people of Ammon, and for four, I will not turn away its punishment, Because they ripped open the women with child in Gilead, That they might enlarge their territory.", 
                "num": 13
              }, 
              {
                "text": "But I will kindle a fire in the wall of Rabbah, And it shall devour its palaces, Amid shouting in the day of battle, And a tempest in the day of the whirlwind.", 
                "num": 14
              }, 
              {
                "text": "Their king shall go into captivity, He and his princes together,\" Says the LORD.", 
                "num": 15
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Thus says the LORD: \"For three transgressions of Moab, and for four, I will not turn away its punishment, Because he burned the bones of the king of Edom to lime.", 
                "num": 1
              }, 
              {
                "text": "But I will send a fire upon Moab, And it shall devour the palaces of Kerioth; Moab shall die with tumult, With shouting and trumpet sound.", 
                "num": 2
              }, 
              {
                "text": "And I will cut off the judge from its midst, And slay all its princes with him,\" Says the LORD.", 
                "num": 3
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Judah, and for four, I will not turn away its punishment, Because they have despised the law of the LORD, And have not kept His commandments. Their lies lead them astray, Lies which their fathers followed.", 
                "num": 4
              }, 
              {
                "text": "But I will send a fire upon Judah, And it shall devour the palaces of Jerusalem.\"", 
                "num": 5
              }, 
              {
                "text": "Thus says the LORD: \"For three transgressions of Israel, and for four, I will not turn away its punishment, Because they sell the righteous for silver, And the poor for a pair of sandals.", 
                "num": 6
              }, 
              {
                "text": "They pant after the dust of the earth which is on the head of the poor, And pervert the way of the humble. A man and his father go in to the same girl, To defile My holy name.", 
                "num": 7
              }, 
              {
                "text": "They lie down by every altar on clothes taken in pledge, And drink the wine of the condemned in the house of their god.", 
                "num": 8
              }, 
              {
                "text": "\"Yet it was I who destroyed the Amorite before them, Whose height was like the height of the cedars, And he was as strong as the oaks; Yet I destroyed his fruit above And his roots beneath.", 
                "num": 9
              }, 
              {
                "text": "Also it was I who brought you up from the land of Egypt, And led you forty years through the wilderness, To possess the land of the Amorite.", 
                "num": 10
              }, 
              {
                "text": "I raised up some of your sons as prophets, And some of your young men as Nazirites. Is it not so, O you children of Israel?\" Says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"But you gave the Nazirites wine to drink, And commanded the prophets saying, \"Do not prophesy!'", 
                "num": 12
              }, 
              {
                "text": "\"Behold, I am weighed down by you, As a cart full of sheaves is weighed down.", 
                "num": 13
              }, 
              {
                "text": "Therefore flight shall perish from the swift, The strong shall not strengthen his power, Nor shall the mighty deliver himself;", 
                "num": 14
              }, 
              {
                "text": "He shall not stand who handles the bow, The swift of foot shall not escape, Nor shall he who rides a horse deliver himself.", 
                "num": 15
              }, 
              {
                "text": "The most courageous men of might Shall flee naked in that day,\" Says the LORD.", 
                "num": 16
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Hear this word that the LORD has spoken against you, O children of Israel, against the whole family which I brought up from the land of Egypt, saying:", 
                "num": 1
              }, 
              {
                "text": "\"You only have I known of all the families of the earth; Therefore I will punish you for all your iniquities.\"", 
                "num": 2
              }, 
              {
                "text": "Can two walk together, unless they are agreed?", 
                "num": 3
              }, 
              {
                "text": "Will a lion roar in the forest, when he has no prey? Will a young lion cry out of his den, if he has caught nothing?", 
                "num": 4
              }, 
              {
                "text": "Will a bird fall into a snare on the earth, where there is no trap for it? Will a snare spring up from the earth, if it has caught nothing at all?", 
                "num": 5
              }, 
              {
                "text": "If a trumpet is blown in a city, will not the people be afraid? If there is calamity in a city, will not the LORD have done it?", 
                "num": 6
              }, 
              {
                "text": "Surely the Lord GOD does nothing, Unless He reveals His secret to His servants the prophets.", 
                "num": 7
              }, 
              {
                "text": "A lion has roared! Who will not fear? The Lord GOD has spoken! Who can but prophesy?", 
                "num": 8
              }, 
              {
                "text": "\"Proclaim in the palaces at Ashdod, And in the palaces in the land of Egypt, and say: \"Assemble on the mountains of Samaria; See great tumults in her midst, And the oppressed within her.", 
                "num": 9
              }, 
              {
                "text": "For they do not know to do right,' Says the LORD, \"Who store up violence and robbery in their palaces.\"'", 
                "num": 10
              }, 
              {
                "text": "Therefore thus says the Lord GOD: \"An adversary shall be all around the land; He shall sap your strength from you, And your palaces shall be plundered.\"", 
                "num": 11
              }, 
              {
                "text": "Thus says the LORD: \"As a shepherd takes from the mouth of a lion Two legs or a piece of an ear, So shall the children of Israel be taken out Who dwell in Samaria-- In the corner of a bed and on the edge of a couch!", 
                "num": 12
              }, 
              {
                "text": "Hear and testify against the house of Jacob,\" Says the Lord GOD, the God of hosts,", 
                "num": 13
              }, 
              {
                "text": "\"That in the day I punish Israel for their transgressions, I will also visit destruction on the altars of Bethel; And the horns of the altar shall be cut off And fall to the ground.", 
                "num": 14
              }, 
              {
                "text": "I will destroy the winter house along with the summer house; The houses of ivory shall perish, And the great houses shall have an end,\" Says the LORD.", 
                "num": 15
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Hear this word, you cows of Bashan, who are on the mountain of Samaria, Who oppress the poor, Who crush the needy, Who say to your husbands, \"Bring wine, let us drink!\"", 
                "num": 1
              }, 
              {
                "text": "The Lord GOD has sworn by His holiness: \"Behold, the days shall come upon you When He will take you away with fishhooks, And your posterity with fishhooks.", 
                "num": 2
              }, 
              {
                "text": "You will go out through broken walls, Each one straight ahead of her, And you will be cast into Harmon,\" Says the LORD.", 
                "num": 3
              }, 
              {
                "text": "\"Come to Bethel and transgress, At Gilgal multiply transgression; Bring your sacrifices every morning, Your tithes every three days.", 
                "num": 4
              }, 
              {
                "text": "Offer a sacrifice of thanksgiving with leaven, Proclaim and announce the freewill offerings; For this you love, You children of Israel!\" Says the Lord GOD.", 
                "num": 5
              }, 
              {
                "text": "\"Also I gave you cleanness of teeth in all your cities. And lack of bread in all your places; Yet you have not returned to Me,\" Says the LORD.", 
                "num": 6
              }, 
              {
                "text": "\"I also withheld rain from you, When there were still three months to the harvest. I made it rain on one city, I withheld rain from another city. One part was rained upon, And where it did not rain the part withered.", 
                "num": 7
              }, 
              {
                "text": "So two or three cities wandered to another city to drink water, But they were not satisfied; Yet you have not returned to Me,\" Says the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"I blasted you with blight and mildew. When your gardens increased, Your vineyards, Your fig trees, And your olive trees, The locust devoured them; Yet you have not returned to Me,\" Says the LORD.", 
                "num": 9
              }, 
              {
                "text": "\"I sent among you a plague after the manner of Egypt; Your young men I killed with a sword, Along with your captive horses; I made the stench of your camps come up into your nostrils; Yet you have not returned to Me,\" Says the LORD.", 
                "num": 10
              }, 
              {
                "text": "\"I overthrew some of you, As God overthrew Sodom and Gomorrah, And you were like a firebrand plucked from the burning; Yet you have not returned to Me,\" Says the LORD.", 
                "num": 11
              }, 
              {
                "text": "\"Therefore thus will I do to you, O Israel; Because I will do this to you, Prepare to meet your God, O Israel!\"", 
                "num": 12
              }, 
              {
                "text": "For behold, He who forms mountains, And creates the wind, Who declares to man what his thought is, And makes the morning darkness, Who treads the high places of the earth-- The LORD God of hosts is His name.", 
                "num": 13
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Hear this word which I take up against you, a lamentation, O house of Israel:", 
                "num": 1
              }, 
              {
                "text": "The virgin of Israel has fallen; She will rise no more. She lies forsaken on her land; There is no one to raise her up.", 
                "num": 2
              }, 
              {
                "text": "For thus says the Lord GOD: \"The city that goes out by a thousand Shall have a hundred left, And that which goes out by a hundred Shall have ten left to the house of Israel.\"", 
                "num": 3
              }, 
              {
                "text": "For thus says the LORD to the house of Israel: \"Seek Me and live;", 
                "num": 4
              }, 
              {
                "text": "But do not seek Bethel, Nor enter Gilgal, Nor pass over to Beersheba; For Gilgal shall surely go into captivity, And Bethel shall come to nothing.", 
                "num": 5
              }, 
              {
                "text": "Seek the LORD and live, Lest He break out like fire in the house of Joseph, And devour it, With no one to quench it in Bethel--", 
                "num": 6
              }, 
              {
                "text": "You who turn justice to wormwood, And lay righteousness to rest in the earth!\"", 
                "num": 7
              }, 
              {
                "text": "He made the Pleiades and Orion; He turns the shadow of death into morning And makes the day dark as night; He calls for the waters of the sea And pours them out on the face of the earth; The LORD is His name.", 
                "num": 8
              }, 
              {
                "text": "He rains ruin upon the strong, So that fury comes upon the fortress.", 
                "num": 9
              }, 
              {
                "text": "They hate the one who rebukes in the gate, And they abhor the one who speaks uprightly.", 
                "num": 10
              }, 
              {
                "text": "Therefore, because you tread down the poor And take grain taxes from him, Though you have built houses of hewn stone, Yet you shall not dwell in them; You have planted pleasant vineyards, But you shall not drink wine from them.", 
                "num": 11
              }, 
              {
                "text": "For I know your manifold transgressions And your mighty sins: Afflicting the just and taking bribes; Diverting the poor from justice at the gate.", 
                "num": 12
              }, 
              {
                "text": "Therefore the prudent keep silent at that time, For it is an evil time.", 
                "num": 13
              }, 
              {
                "text": "Seek good and not evil, That you may live; So the LORD God of hosts will be with you, As you have spoken.", 
                "num": 14
              }, 
              {
                "text": "Hate evil, love good; Establish justice in the gate. It may be that the LORD God of hosts Will be gracious to the remnant of Joseph.", 
                "num": 15
              }, 
              {
                "text": "Therefore the LORD God of hosts, the Lord, says this: \"There shall be wailing in all streets, And they shall say in all the highways, \"Alas! Alas!' They shall call the farmer to mourning, And skillful lamenters to wailing.", 
                "num": 16
              }, 
              {
                "text": "In all vineyards there shall be wailing, For I will pass through you,\" Says the LORD.", 
                "num": 17
              }, 
              {
                "text": "Woe to you who desire the day of the LORD! For what good is the day of the LORD to you? It will be darkness, and not light.", 
                "num": 18
              }, 
              {
                "text": "It will be as though a man fled from a lion, And a bear met him! Or as though he went into the house, Leaned his hand on the wall, And a serpent bit him!", 
                "num": 19
              }, 
              {
                "text": "Is not the day of the LORD darkness, and not light? Is it not very dark, with no brightness in it?", 
                "num": 20
              }, 
              {
                "text": "\"I hate, I despise your feast days, And I do not savor your sacred assemblies.", 
                "num": 21
              }, 
              {
                "text": "Though you offer Me burnt offerings and your grain offerings, I will not accept them, Nor will I regard your fattened peace offerings.", 
                "num": 22
              }, 
              {
                "text": "Take away from Me the noise of your songs, For I will not hear the melody of your stringed instruments.", 
                "num": 23
              }, 
              {
                "text": "But let justice run down like water, And righteousness like a mighty stream.", 
                "num": 24
              }, 
              {
                "text": "\"Did you offer Me sacrifices and offerings In the wilderness forty years, O house of Israel?", 
                "num": 25
              }, 
              {
                "text": "You also carried Sikkuth your king And Chiun, your idols, The star of your gods, Which you made for yourselves.", 
                "num": 26
              }, 
              {
                "text": "Therefore I will send you into captivity beyond Damascus,\" Says the LORD, whose name is the God of hosts.", 
                "num": 27
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Woe to you who are at ease in Zion, And trust in Mount Samaria, Notable persons in the chief nation, To whom the house of Israel comes!", 
                "num": 1
              }, 
              {
                "text": "Go over to Calneh and see; And from there go to Hamath the great; Then go down to Gath of the Philistines. Are you better than these kingdoms? Or is their territory greater than your territory?", 
                "num": 2
              }, 
              {
                "text": "Woe to you who put far off the day of doom, Who cause the seat of violence to come near;", 
                "num": 3
              }, 
              {
                "text": "Who lie on beds of ivory, Stretch out on your couches, Eat lambs from the flock And calves from the midst of the stall;", 
                "num": 4
              }, 
              {
                "text": "Who sing idly to the sound of stringed instruments, And invent for yourselves musical instruments like David;", 
                "num": 5
              }, 
              {
                "text": "Who drink wine from bowls, And anoint yourselves with the best ointments, But are not grieved for the affliction of Joseph.", 
                "num": 6
              }, 
              {
                "text": "Therefore they shall now go captive as the first of the captives, And those who recline at banquets shall be removed.", 
                "num": 7
              }, 
              {
                "text": "The Lord GOD has sworn by Himself, The LORD God of hosts says: \"I abhor the pride of Jacob, And hate his palaces; Therefore I will deliver up the city And all that is in it.\"", 
                "num": 8
              }, 
              {
                "text": "Then it shall come to pass, that if ten men remain in one house, they shall die.", 
                "num": 9
              }, 
              {
                "text": "And when a relative of the dead, with one who will burn the bodies, picks up the bodies to take them out of the house, he will say to one inside the house, \"Are there any more with you?\" Then someone will say, \"None.\" And he will say, \"Hold your tongue! For we dare not mention the name of the LORD.\"", 
                "num": 10
              }, 
              {
                "text": "For behold, the LORD gives a command: He will break the great house into bits, And the little house into pieces.", 
                "num": 11
              }, 
              {
                "text": "Do horses run on rocks? Does one plow there with oxen? Yet you have turned justice into gall, And the fruit of righteousness into wormwood,", 
                "num": 12
              }, 
              {
                "text": "You who rejoice over Lo Debar, Who say, \"Have we not taken Karnaim for ourselves By our own strength?\"", 
                "num": 13
              }, 
              {
                "text": "\"But, behold, I will raise up a nation against you, O house of Israel,\" Says the LORD God of hosts; \"And they will afflict you from the entrance of Hamath To the Valley of the Arabah.\"", 
                "num": 14
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Thus the Lord GOD showed me: Behold, He formed locust swarms at the beginning of the late crop; indeed it was the late crop after the king's mowings.", 
                "num": 1
              }, 
              {
                "text": "And so it was, when they had finished eating the grass of the land, that I said: \"O Lord GOD, forgive, I pray! Oh, that Jacob may stand, For he is small!\"", 
                "num": 2
              }, 
              {
                "text": "So the LORD relented concerning this. \"It shall not be,\" said the LORD.", 
                "num": 3
              }, 
              {
                "text": "Thus the Lord GOD showed me: Behold, the Lord GOD called for conflict by fire, and it consumed the great deep and devoured the territory.", 
                "num": 4
              }, 
              {
                "text": "Then I said: \"O Lord GOD, cease, I pray! Oh, that Jacob may stand, For he is small!\"", 
                "num": 5
              }, 
              {
                "text": "So the LORD relented concerning this. \"This also shall not be,\" said the Lord GOD.", 
                "num": 6
              }, 
              {
                "text": "Thus He showed me: Behold, the Lord stood on a wall made with a plumb line, with a plumb line in His hand.", 
                "num": 7
              }, 
              {
                "text": "And the LORD said to me, \"Amos, what do you see?\" And I said, \"A plumb line.\" Then the Lord said: \"Behold, I am setting a plumb line In the midst of My people Israel; I will not pass by them anymore.", 
                "num": 8
              }, 
              {
                "text": "The high places of Isaac shall be desolate, And the sanctuaries of Israel shall be laid waste. I will rise with the sword against the house of Jeroboam.\"", 
                "num": 9
              }, 
              {
                "text": "Then Amaziah the priest of Bethel sent to Jeroboam king of Israel, saying, \"Amos has conspired against you in the midst of the house of Israel. The land is not able to bear all his words.", 
                "num": 10
              }, 
              {
                "text": "For thus Amos has said: \"Jeroboam shall die by the sword, And Israel shall surely be led away captive From their own land.\"'", 
                "num": 11
              }, 
              {
                "text": "Then Amaziah said to Amos: \"Go, you seer! Flee to the land of Judah. There eat bread, And there prophesy.", 
                "num": 12
              }, 
              {
                "text": "But never again prophesy at Bethel, For it is the king's sanctuary, And it is the royal residence.\"", 
                "num": 13
              }, 
              {
                "text": "Then Amos answered, and said to Amaziah: \"I was no prophet, Nor was I a son of a prophet, But I was a sheepbreeder And a tender of sycamore fruit.", 
                "num": 14
              }, 
              {
                "text": "Then the LORD took me as I followed the flock, And the LORD said to me, \"Go, prophesy to My people Israel.'", 
                "num": 15
              }, 
              {
                "text": "Now therefore, hear the word of the LORD: You say, \"Do not prophesy against Israel, And do not spout against the house of Isaac.'", 
                "num": 16
              }, 
              {
                "text": "\"Therefore thus says the LORD: \"Your wife shall be a harlot in the city; Your sons and daughters shall fall by the sword; Your land shall be divided by survey line; You shall die in a defiled land; And Israel shall surely be led away captive From his own land.\"'", 
                "num": 17
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Thus the Lord GOD showed me: Behold, a basket of summer fruit.", 
                "num": 1
              }, 
              {
                "text": "And He said, \"Amos, what do you see?\" So I said, \"A basket of summer fruit.\" Then the LORD said to me: \"The end has come upon My people Israel; I will not pass by them anymore.", 
                "num": 2
              }, 
              {
                "text": "And the songs of the temple Shall be wailing in that day,\" Says the Lord GOD-- \"Many dead bodies everywhere, They shall be thrown out in silence.\"", 
                "num": 3
              }, 
              {
                "text": "Hear this, you who swallow up the needy, And make the poor of the land fail,", 
                "num": 4
              }, 
              {
                "text": "Saying: \"When will the New Moon be past, That we may sell grain? And the Sabbath, That we may trade wheat? Making the ephah small and the shekel large, Falsifying the scales by deceit,", 
                "num": 5
              }, 
              {
                "text": "That we may buy the poor for silver, And the needy for a pair of sandals-- Even sell the bad wheat?\"", 
                "num": 6
              }, 
              {
                "text": "The LORD has sworn by the pride of Jacob: \"Surely I will never forget any of their works.", 
                "num": 7
              }, 
              {
                "text": "Shall the land not tremble for this, And everyone mourn who dwells in it? All of it shall swell like the River, Heave and subside Like the River of Egypt.", 
                "num": 8
              }, 
              {
                "text": "\"And it shall come to pass in that day,\" says the Lord GOD, \"That I will make the sun go down at noon, And I will darken the earth in broad daylight;", 
                "num": 9
              }, 
              {
                "text": "I will turn your feasts into mourning, And all your songs into lamentation; I will bring sackcloth on every waist, And baldness on every head; I will make it like mourning for an only son, And its end like a bitter day.", 
                "num": 10
              }, 
              {
                "text": "\"Behold, the days are coming,\" says the Lord GOD, \"That I will send a famine on the land, Not a famine of bread, Nor a thirst for water, But of hearing the words of the LORD.", 
                "num": 11
              }, 
              {
                "text": "They shall wander from sea to sea, And from north to east; They shall run to and fro, seeking the word of the LORD, But shall not find it.", 
                "num": 12
              }, 
              {
                "text": "\"In that day the fair virgins And strong young men Shall faint from thirst.", 
                "num": 13
              }, 
              {
                "text": "Those who swear by the sin of Samaria, Who say, \"As your god lives, O Dan!' And, \"As the way of Beersheba lives!' They shall fall and never rise again.\"", 
                "num": 14
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "I saw the Lord standing by the altar, and He said: \"Strike the doorposts, that the thresholds may shake, And break them on the heads of them all. I will slay the last of them with the sword. He who flees from them shall not get away, And he who escapes from them shall not be delivered.", 
                "num": 1
              }, 
              {
                "text": "\"Though they dig into hell, From there My hand shall take them; Though they climb up to heaven, From there I will bring them down;", 
                "num": 2
              }, 
              {
                "text": "And though they hide themselves on top of Carmel, From there I will search and take them; Though they hide from My sight at the bottom of the sea, From there I will command the serpent, and it shall bite them;", 
                "num": 3
              }, 
              {
                "text": "Though they go into captivity before their enemies, From there I will command the sword, And it shall slay them. I will set My eyes on them for harm and not for good.\"", 
                "num": 4
              }, 
              {
                "text": "The Lord GOD of hosts, He who touches the earth and it melts, And all who dwell there mourn; All of it shall swell like the River, And subside like the River of Egypt.", 
                "num": 5
              }, 
              {
                "text": "He who builds His layers in the sky, And has founded His strata in the earth; Who calls for the waters of the sea, And pours them out on the face of the earth-- The LORD is His name.", 
                "num": 6
              }, 
              {
                "text": "\"Are you not like the people of Ethiopia to Me, O children of Israel?\" says the LORD. \"Did I not bring up Israel from the land of Egypt, The Philistines from Caphtor, And the Syrians from Kir?", 
                "num": 7
              }, 
              {
                "text": "\"Behold, the eyes of the Lord GOD are on the sinful kingdom, And I will destroy it from the face of the earth; Yet I will not utterly destroy the house of Jacob,\" Says the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"For surely I will command, And will sift the house of Israel among all nations, As grain is sifted in a sieve; Yet not the smallest grain shall fall to the ground.", 
                "num": 9
              }, 
              {
                "text": "All the sinners of My people shall die by the sword, Who say, \"The calamity shall not overtake nor confront us.'", 
                "num": 10
              }, 
              {
                "text": "\"On that day I will raise up The tabernacle of David, which has fallen down, And repair its damages; I will raise up its ruins, And rebuild it as in the days of old;", 
                "num": 11
              }, 
              {
                "text": "That they may possess the remnant of Edom, And all the Gentiles who are called by My name,\" Says the LORD who does this thing.", 
                "num": 12
              }, 
              {
                "text": "\"Behold, the days are coming,\" says the LORD, \"When the plowman shall overtake the reaper, And the treader of grapes him who sows seed; The mountains shall drip with sweet wine, And all the hills shall flow with it.", 
                "num": 13
              }, 
              {
                "text": "I will bring back the captives of My people Israel; They shall build the waste cities and inhabit them; They shall plant vineyards and drink wine from them; They shall also make gardens and eat fruit from them.", 
                "num": 14
              }, 
              {
                "text": "I will plant them in their land, And no longer shall they be pulled up From the land I have given them,\" Says the LORD your God.", 
                "num": 15
              }
            ], 
            "num": 9
          }
        ]
      }, 
      {
        "name": "Obadiah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The vision of Obadiah. Thus says the Lord GOD concerning Edom (We have heard a report from the LORD, And a messenger has been sent among the nations, saying, \"Arise, and let us rise up against her for battle\"):", 
                "num": 1
              }, 
              {
                "text": "\"Behold, I will make you small among the nations; You shall be greatly despised.", 
                "num": 2
              }, 
              {
                "text": "The pride of your heart has deceived you, You who dwell in the clefts of the rock, Whose habitation is high; You who say in your heart, \"Who will bring me down to the ground?'", 
                "num": 3
              }, 
              {
                "text": "Though you ascend as high as the eagle, And though you set your nest among the stars, From there I will bring you down,\" says the LORD.", 
                "num": 4
              }, 
              {
                "text": "\"If thieves had come to you, If robbers by night-- Oh, how you will be cut off!-- Would they not have stolen till they had enough? If grape-gatherers had come to you, Would they not have left some gleanings?", 
                "num": 5
              }, 
              {
                "text": "\"Oh, how Esau shall be searched out! How his hidden treasures shall be sought after!", 
                "num": 6
              }, 
              {
                "text": "All the men in your confederacy Shall force you to the border; The men at peace with you Shall deceive you and prevail against you. Those who eat your bread shall lay a trap for you. No one is aware of it.", 
                "num": 7
              }, 
              {
                "text": "\"Will I not in that day,\" says the LORD, \"Even destroy the wise men from Edom, And understanding from the mountains of Esau?", 
                "num": 8
              }, 
              {
                "text": "Then your mighty men, O Teman, shall be dismayed, To the end that everyone from the mountains of Esau May be cut off by slaughter.", 
                "num": 9
              }, 
              {
                "text": "\"For violence against your brother Jacob, Shame shall cover you, And you shall be cut off forever.", 
                "num": 10
              }, 
              {
                "text": "In the day that you stood on the other side-- In the day that strangers carried captive his forces, When foreigners entered his gates And cast lots for Jerusalem-- Even you were as one of them.", 
                "num": 11
              }, 
              {
                "text": "\"But you should not have gazed on the day of your brother In the day of his captivity; Nor should you have rejoiced over the children of Judah In the day of their destruction; Nor should you have spoken proudly In the day of distress.", 
                "num": 12
              }, 
              {
                "text": "You should not have entered the gate of My people In the day of their calamity. Indeed, you should not have gazed on their affliction In the day of their calamity, Nor laid hands on their substance In the day of their calamity.", 
                "num": 13
              }, 
              {
                "text": "You should not have stood at the crossroads To cut off those among them who escaped; Nor should you have delivered up those among them who remained In the day of distress.", 
                "num": 14
              }, 
              {
                "text": "\"For the day of the LORD upon all the nations is near; As you have done, it shall be done to you; Your reprisal shall return upon your own head.", 
                "num": 15
              }, 
              {
                "text": "For as you drank on My holy mountain, So shall all the nations drink continually; Yes, they shall drink, and swallow, And they shall be as though they had never been.", 
                "num": 16
              }, 
              {
                "text": "\"But on Mount Zion there shall be deliverance, And there shall be holiness; The house of Jacob shall possess their possessions.", 
                "num": 17
              }, 
              {
                "text": "The house of Jacob shall be a fire, And the house of Joseph a flame; But the house of Esau shall be stubble; They shall kindle them and devour them, And no survivor shall remain of the house of Esau,\" For the LORD has spoken.", 
                "num": 18
              }, 
              {
                "text": "The South shall possess the mountains of Esau, And the Lowland shall possess Philistia. They shall possess the fields of Ephraim And the fields of Samaria. Benjamin shall possess Gilead.", 
                "num": 19
              }, 
              {
                "text": "And the captives of this host of the children of Israel Shall possess the land of the Canaanites As far as Zarephath. The captives of Jerusalem who are in Sepharad Shall possess the cities of the South.", 
                "num": 20
              }, 
              {
                "text": "Then saviors shall come to Mount Zion To judge the mountains of Esau, And the kingdom shall be the LORD's.", 
                "num": 21
              }
            ], 
            "num": 1
          }
        ]
      }, 
      {
        "name": "Jonah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Now the word of the LORD came to Jonah the son of Amittai, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Arise, go to Nineveh, that great city, and cry out against it; for their wickedness has come up before Me.\"", 
                "num": 2
              }, 
              {
                "text": "But Jonah arose to flee to Tarshish from the presence of the LORD. He went down to Joppa, and found a ship going to Tarshish; so he paid the fare, and went down into it, to go with them to Tarshish from the presence of the LORD.", 
                "num": 3
              }, 
              {
                "text": "But the LORD sent out a great wind on the sea, and there was a mighty tempest on the sea, so that the ship was about to be broken up.", 
                "num": 4
              }, 
              {
                "text": "Then the mariners were afraid; and every man cried out to his god, and threw the cargo that was in the ship into the sea, to lighten the load. But Jonah had gone down into the lowest parts of the ship, had lain down, and was fast asleep.", 
                "num": 5
              }, 
              {
                "text": "So the captain came to him, and said to him, \"What do you mean, sleeper? Arise, call on your God; perhaps your God will consider us, so that we may not perish.\"", 
                "num": 6
              }, 
              {
                "text": "And they said to one another, \"Come, let us cast lots, that we may know for whose cause this trouble has come upon us.\" So they cast lots, and the lot fell on Jonah.", 
                "num": 7
              }, 
              {
                "text": "Then they said to him, \"Please tell us! For whose cause is this trouble upon us? What is your occupation? And where do you come from? What is your country? And of what people are you?\"", 
                "num": 8
              }, 
              {
                "text": "So he said to them, \"I am a Hebrew; and I fear the LORD, the God of heaven, who made the sea and the dry land.\"", 
                "num": 9
              }, 
              {
                "text": "Then the men were exceedingly afraid, and said to him, \"Why have you done this?\" For the men knew that he fled from the presence of the LORD, because he had told them.", 
                "num": 10
              }, 
              {
                "text": "Then they said to him, \"What shall we do to you that the sea may be calm for us?\"--for the sea was growing more tempestuous.", 
                "num": 11
              }, 
              {
                "text": "And he said to them, \"Pick me up and throw me into the sea; then the sea will become calm for you. For I know that this great tempest is because of me.\"", 
                "num": 12
              }, 
              {
                "text": "Nevertheless the men rowed hard to return to land, but they could not, for the sea continued to grow more tempestuous against them.", 
                "num": 13
              }, 
              {
                "text": "Therefore they cried out to the LORD and said, \"We pray, O LORD, please do not let us perish for this man's life, and do not charge us with innocent blood; for You, O LORD, have done as it pleased You.\"", 
                "num": 14
              }, 
              {
                "text": "So they picked up Jonah and threw him into the sea, and the sea ceased from its raging.", 
                "num": 15
              }, 
              {
                "text": "Then the men feared the LORD exceedingly, and offered a sacrifice to the LORD and took vows.", 
                "num": 16
              }, 
              {
                "text": "Now the LORD had prepared a great fish to swallow Jonah. And Jonah was in the belly of the fish three days and three nights.", 
                "num": 17
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Then Jonah prayed to the LORD his God from the fish's belly.", 
                "num": 1
              }, 
              {
                "text": "And he said: \"I cried out to the LORD because of my affliction, And He answered me. \"Out of the belly of Sheol I cried, And You heard my voice.", 
                "num": 2
              }, 
              {
                "text": "For You cast me into the deep, Into the heart of the seas, And the floods surrounded me; All Your billows and Your waves passed over me.", 
                "num": 3
              }, 
              {
                "text": "Then I said, \"I have been cast out of Your sight; Yet I will look again toward Your holy temple.'", 
                "num": 4
              }, 
              {
                "text": "The waters surrounded me, even to my soul; The deep closed around me; Weeds were wrapped around my head.", 
                "num": 5
              }, 
              {
                "text": "I went down to the moorings of the mountains; The earth with its bars closed behind me forever; Yet You have brought up my life from the pit, O LORD, my God.", 
                "num": 6
              }, 
              {
                "text": "\"When my soul fainted within me, I remembered the LORD; And my prayer went up to You, Into Your holy temple.", 
                "num": 7
              }, 
              {
                "text": "\"Those who regard worthless idols Forsake their own Mercy.", 
                "num": 8
              }, 
              {
                "text": "But I will sacrifice to You With the voice of thanksgiving; I will pay what I have vowed. Salvation is of the LORD.\"", 
                "num": 9
              }, 
              {
                "text": "So the LORD spoke to the fish, and it vomited Jonah onto dry land.", 
                "num": 10
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now the word of the LORD came to Jonah the second time, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Arise, go to Nineveh, that great city, and preach to it the message that I tell you.\"", 
                "num": 2
              }, 
              {
                "text": "So Jonah arose and went to Nineveh, according to the word of the LORD. Now Nineveh was an exceedingly great city, a three-day journey in extent.", 
                "num": 3
              }, 
              {
                "text": "And Jonah began to enter the city on the first day's walk. Then he cried out and said, \"Yet forty days, and Nineveh shall be overthrown!\"", 
                "num": 4
              }, 
              {
                "text": "So the people of Nineveh believed God, proclaimed a fast, and put on sackcloth, from the greatest to the least of them.", 
                "num": 5
              }, 
              {
                "text": "Then word came to the king of Nineveh; and he arose from his throne and laid aside his robe, covered himself with sackcloth and sat in ashes.", 
                "num": 6
              }, 
              {
                "text": "And he caused it to be proclaimed and published throughout Nineveh by the decree of the king and his nobles, saying, Let neither man nor beast, herd nor flock, taste anything; do not let them eat, or drink water.", 
                "num": 7
              }, 
              {
                "text": "But let man and beast be covered with sackcloth, and cry mightily to God; yes, let every one turn from his evil way and from the violence that is in his hands.", 
                "num": 8
              }, 
              {
                "text": "Who can tell if God will turn and relent, and turn away from His fierce anger, so that we may not perish?", 
                "num": 9
              }, 
              {
                "text": "Then God saw their works, that they turned from their evil way; and God relented from the disaster that He had said He would bring upon them, and He did not do it.", 
                "num": 10
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "But it displeased Jonah exceedingly, and he became angry.", 
                "num": 1
              }, 
              {
                "text": "So he prayed to the LORD, and said, \"Ah, LORD, was not this what I said when I was still in my country? Therefore I fled previously to Tarshish; for I know that You are a gracious and merciful God, slow to anger and abundant in lovingkindness, One who relents from doing harm.", 
                "num": 2
              }, 
              {
                "text": "Therefore now, O LORD, please take my life from me, for it is better for me to die than to live!\"", 
                "num": 3
              }, 
              {
                "text": "Then the LORD said, \"Is it right for you to be angry?\"", 
                "num": 4
              }, 
              {
                "text": "So Jonah went out of the city and sat on the east side of the city. There he made himself a shelter and sat under it in the shade, till he might see what would become of the city.", 
                "num": 5
              }, 
              {
                "text": "And the LORD God prepared a plant and made it come up over Jonah, that it might be shade for his head to deliver him from his misery. So Jonah was very grateful for the plant.", 
                "num": 6
              }, 
              {
                "text": "But as morning dawned the next day God prepared a worm, and it so damaged the plant that it withered.", 
                "num": 7
              }, 
              {
                "text": "And it happened, when the sun arose, that God prepared a vehement east wind; and the sun beat on Jonah's head, so that he grew faint. Then he wished death for himself, and said, \"It is better for me to die than to live.\"", 
                "num": 8
              }, 
              {
                "text": "Then God said to Jonah, \"Is it right for you to be angry about the plant?\" And he said, \"It is right for me to be angry, even to death!\"", 
                "num": 9
              }, 
              {
                "text": "But the LORD said, \"You have had pity on the plant for which you have not labored, nor made it grow, which came up in a night and perished in a night.", 
                "num": 10
              }, 
              {
                "text": "And should I not pity Nineveh, that great city, in which are more than one hundred and twenty thousand persons who cannot discern between their right hand and their left--and much livestock?\"", 
                "num": 11
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "Micah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The word of the LORD that came to Micah of Moresheth in the days of Jotham, Ahaz, and Hezekiah, kings of Judah, which he saw concerning Samaria and Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Hear, all you peoples! Listen, O earth, and all that is in it! Let the Lord GOD be a witness against you, The Lord from His holy temple.", 
                "num": 2
              }, 
              {
                "text": "For behold, the LORD is coming out of His place; He will come down And tread on the high places of the earth.", 
                "num": 3
              }, 
              {
                "text": "The mountains will melt under Him, And the valleys will split Like wax before the fire, Like waters poured down a steep place.", 
                "num": 4
              }, 
              {
                "text": "All this is for the transgression of Jacob And for the sins of the house of Israel. What is the transgression of Jacob? Is it not Samaria? And what are the high places of Judah? Are they not Jerusalem?", 
                "num": 5
              }, 
              {
                "text": "\"Therefore I will make Samaria a heap of ruins in the field, Places for planting a vineyard; I will pour down her stones into the valley, And I will uncover her foundations.", 
                "num": 6
              }, 
              {
                "text": "All her carved images shall be beaten to pieces, And all her pay as a harlot shall be burned with the fire; All her idols I will lay desolate, For she gathered it from the pay of a harlot, And they shall return to the pay of a harlot.\"", 
                "num": 7
              }, 
              {
                "text": "Therefore I will wail and howl, I will go stripped and naked; I will make a wailing like the jackals And a mourning like the ostriches,", 
                "num": 8
              }, 
              {
                "text": "For her wounds are incurable. For it has come to Judah; It has come to the gate of My people-- To Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "Tell it not in Gath, Weep not at all; In Beth Aphrah Roll yourself in the dust.", 
                "num": 10
              }, 
              {
                "text": "Pass by in naked shame, you inhabitant of Shaphir; The inhabitant of Zaanan does not go out. Beth Ezel mourns; Its place to stand is taken away from you.", 
                "num": 11
              }, 
              {
                "text": "For the inhabitant of Maroth pined for good, But disaster came down from the LORD To the gate of Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "O inhabitant of Lachish, Harness the chariot to the swift steeds (She was the beginning of sin to the daughter of Zion), For the transgressions of Israel were found in you.", 
                "num": 13
              }, 
              {
                "text": "Therefore you shall give presents to Moresheth Gath; The houses of Achzib shall be a lie to the kings of Israel.", 
                "num": 14
              }, 
              {
                "text": "I will yet bring an heir to you, O inhabitant of Mareshah; The glory of Israel shall come to Adullam.", 
                "num": 15
              }, 
              {
                "text": "Make yourself bald and cut off your hair, Because of your precious children; Enlarge your baldness like an eagle, For they shall go from you into captivity.", 
                "num": 16
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Woe to those who devise iniquity, And work out evil on their beds! At morning light they practice it, Because it is in the power of their hand.", 
                "num": 1
              }, 
              {
                "text": "They covet fields and take them by violence, Also houses, and seize them. So they oppress a man and his house, A man and his inheritance.", 
                "num": 2
              }, 
              {
                "text": "Therefore thus says the LORD: \"Behold, against this family I am devising disaster, From which you cannot remove your necks; Nor shall you walk haughtily, For this is an evil time.", 
                "num": 3
              }, 
              {
                "text": "In that day one shall take up a proverb against you, And lament with a bitter lamentation, saying: \"We are utterly destroyed! He has changed the heritage of my people; How He has removed it from me! To a turncoat He has divided our fields.\"'", 
                "num": 4
              }, 
              {
                "text": "Therefore you will have no one to determine boundaries by lot In the assembly of the LORD.", 
                "num": 5
              }, 
              {
                "text": "\"Do not prattle,\" you say to those who prophesy. So they shall not prophesy to you; They shall not return insult for insult.", 
                "num": 6
              }, 
              {
                "text": "You who are named the house of Jacob: \"Is the Spirit of the LORD restricted? Are these His doings? Do not My words do good To him who walks uprightly?", 
                "num": 7
              }, 
              {
                "text": "\"Lately My people have risen up as an enemy-- You pull off the robe with the garment From those who trust you, as they pass by, Like men returned from war.", 
                "num": 8
              }, 
              {
                "text": "The women of My people you cast out From their pleasant houses; From their children You have taken away My glory forever.", 
                "num": 9
              }, 
              {
                "text": "\"Arise and depart, For this is not your rest; Because it is defiled, it shall destroy, Yes, with utter destruction.", 
                "num": 10
              }, 
              {
                "text": "If a man should walk in a false spirit And speak a lie, saying, \"I will prophesy to you of wine and drink,' Even he would be the prattler of this people.", 
                "num": 11
              }, 
              {
                "text": "\"I will surely assemble all of you, O Jacob, I will surely gather the remnant of Israel; I will put them together like sheep of the fold, Like a flock in the midst of their pasture; They shall make a loud noise because of so many people.", 
                "num": 12
              }, 
              {
                "text": "The one who breaks open will come up before them; They will break out, Pass through the gate, And go out by it; Their king will pass before them, With the LORD at their head.\"", 
                "num": 13
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "And I said: \"Hear now, O heads of Jacob, And you rulers of the house of Israel: Is it not for you to know justice?", 
                "num": 1
              }, 
              {
                "text": "You who hate good and love evil; Who strip the skin from My people, And the flesh from their bones;", 
                "num": 2
              }, 
              {
                "text": "Who also eat the flesh of My people, Flay their skin from them, Break their bones, And chop them in pieces Like meat for the pot, Like flesh in the caldron.\"", 
                "num": 3
              }, 
              {
                "text": "Then they will cry to the LORD, But He will not hear them; He will even hide His face from them at that time, Because they have been evil in their deeds.", 
                "num": 4
              }, 
              {
                "text": "Thus says the LORD concerning the prophets Who make my people stray; Who chant \"Peace\" While they chew with their teeth, But who prepare war against him Who puts nothing into their mouths:", 
                "num": 5
              }, 
              {
                "text": "\"Therefore you shall have night without vision, And you shall have darkness without divination; The sun shall go down on the prophets, And the day shall be dark for them.", 
                "num": 6
              }, 
              {
                "text": "So the seers shall be ashamed, And the diviners abashed; Indeed they shall all cover their lips; For there is no answer from God.\"", 
                "num": 7
              }, 
              {
                "text": "But truly I am full of power by the Spirit of the LORD, And of justice and might, To declare to Jacob his transgression And to Israel his sin.", 
                "num": 8
              }, 
              {
                "text": "Now hear this, You heads of the house of Jacob And rulers of the house of Israel, Who abhor justice And pervert all equity,", 
                "num": 9
              }, 
              {
                "text": "Who build up Zion with bloodshed And Jerusalem with iniquity:", 
                "num": 10
              }, 
              {
                "text": "Her heads judge for a bribe, Her priests teach for pay, And her prophets divine for money. Yet they lean on the LORD, and say, \"Is not the LORD among us? No harm can come upon us.\"", 
                "num": 11
              }, 
              {
                "text": "Therefore because of you Zion shall be plowed like a field, Jerusalem shall become heaps of ruins, And the mountain of the temple Like the bare hills of the forest.", 
                "num": 12
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now it shall come to pass in the latter days That the mountain of the LORD's house Shall be established on the top of the mountains, And shall be exalted above the hills; And peoples shall flow to it.", 
                "num": 1
              }, 
              {
                "text": "Many nations shall come and say, \"Come, and let us go up to the mountain of the LORD, To the house of the God of Jacob; He will teach us His ways, And we shall walk in His paths.\" For out of Zion the law shall go forth, And the word of the LORD from Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "He shall judge between many peoples, And rebuke strong nations afar off; They shall beat their swords into plowshares, And their spears into pruning hooks; Nation shall not lift up sword against nation, Neither shall they learn war anymore.", 
                "num": 3
              }, 
              {
                "text": "But everyone shall sit under his vine and under his fig tree, And no one shall make them afraid; For the mouth of the LORD of hosts has spoken.", 
                "num": 4
              }, 
              {
                "text": "For all people walk each in the name of his god, But we will walk in the name of the LORD our God Forever and ever.", 
                "num": 5
              }, 
              {
                "text": "\"In that day,\" says the LORD, \"I will assemble the lame, I will gather the outcast And those whom I have afflicted;", 
                "num": 6
              }, 
              {
                "text": "I will make the lame a remnant, And the outcast a strong nation; So the LORD will reign over them in Mount Zion From now on, even forever.", 
                "num": 7
              }, 
              {
                "text": "And you, O tower of the flock, The stronghold of the daughter of Zion, To you shall it come, Even the former dominion shall come, The kingdom of the daughter of Jerusalem.\"", 
                "num": 8
              }, 
              {
                "text": "Now why do you cry aloud? Is there no king in your midst? Has your counselor perished? For pangs have seized you like a woman in labor.", 
                "num": 9
              }, 
              {
                "text": "Be in pain, and labor to bring forth, O daughter of Zion, Like a woman in birth pangs. For now you shall go forth from the city, You shall dwell in the field, And to Babylon you shall go. There you shall be delivered; There the LORD will redeem you From the hand of your enemies.", 
                "num": 10
              }, 
              {
                "text": "Now also many nations have gathered against you, Who say, \"Let her be defiled, And let our eye look upon Zion.\"", 
                "num": 11
              }, 
              {
                "text": "But they do not know the thoughts of the LORD, Nor do they understand His counsel; For He will gather them like sheaves to the threshing floor.", 
                "num": 12
              }, 
              {
                "text": "\"Arise and thresh, O daughter of Zion; For I will make your horn iron, And I will make your hooves bronze; You shall beat in pieces many peoples; I will consecrate their gain to the LORD, And their substance to the Lord of the whole earth.\"", 
                "num": 13
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Now gather yourself in troops, O daughter of troops; He has laid siege against us; They will strike the judge of Israel with a rod on the cheek.", 
                "num": 1
              }, 
              {
                "text": "\"But you, Bethlehem Ephrathah, Though you are little among the thousands of Judah, Yet out of you shall come forth to Me The One to be Ruler in Israel, Whose goings forth are from of old, From everlasting.\"", 
                "num": 2
              }, 
              {
                "text": "Therefore He shall give them up, Until the time that she who is in labor has given birth; Then the remnant of His brethren Shall return to the children of Israel.", 
                "num": 3
              }, 
              {
                "text": "And He shall stand and feed His flock In the strength of the LORD, In the majesty of the name of the LORD His God; And they shall abide, For now He shall be great To the ends of the earth;", 
                "num": 4
              }, 
              {
                "text": "And this One shall be peace. When the Assyrian comes into our land, And when he treads in our palaces, Then we will raise against him Seven shepherds and eight princely men.", 
                "num": 5
              }, 
              {
                "text": "They shall waste with the sword the land of Assyria, And the land of Nimrod at its entrances; Thus He shall deliver us from the Assyrian, When he comes into our land And when he treads within our borders.", 
                "num": 6
              }, 
              {
                "text": "Then the remnant of Jacob Shall be in the midst of many peoples, Like dew from the LORD, Like showers on the grass, That tarry for no man Nor wait for the sons of men.", 
                "num": 7
              }, 
              {
                "text": "And the remnant of Jacob Shall be among the Gentiles, In the midst of many peoples, Like a lion among the beasts of the forest, Like a young lion among flocks of sheep, Who, if he passes through, Both treads down and tears in pieces, And none can deliver.", 
                "num": 8
              }, 
              {
                "text": "Your hand shall be lifted against your adversaries, And all your enemies shall be cut off.", 
                "num": 9
              }, 
              {
                "text": "\"And it shall be in that day,\" says the LORD, \"That I will cut off your horses from your midst And destroy your chariots.", 
                "num": 10
              }, 
              {
                "text": "I will cut off the cities of your land And throw down all your strongholds.", 
                "num": 11
              }, 
              {
                "text": "I will cut off sorceries from your hand, And you shall have no soothsayers.", 
                "num": 12
              }, 
              {
                "text": "Your carved images I will also cut off, And your sacred pillars from your midst; You shall no more worship the work of your hands;", 
                "num": 13
              }, 
              {
                "text": "I will pluck your wooden images from your midst; Thus I will destroy your cities.", 
                "num": 14
              }, 
              {
                "text": "And I will execute vengeance in anger and fury On the nations that have not heard.\"", 
                "num": 15
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Hear now what the LORD says: \"Arise, plead your case before the mountains, And let the hills hear your voice.", 
                "num": 1
              }, 
              {
                "text": "Hear, O you mountains, the LORD's complaint, And you strong foundations of the earth; For the LORD has a complaint against His people, And He will contend with Israel.", 
                "num": 2
              }, 
              {
                "text": "\"O My people, what have I done to you? And how have I wearied you? Testify against Me.", 
                "num": 3
              }, 
              {
                "text": "For I brought you up from the land of Egypt, I redeemed you from the house of bondage; And I sent before you Moses, Aaron, and Miriam.", 
                "num": 4
              }, 
              {
                "text": "O My people, remember now What Balak king of Moab counseled, And what Balaam the son of Beor answered him, From Acacia Grove to Gilgal, That you may know the righteousness of the LORD.\"", 
                "num": 5
              }, 
              {
                "text": "With what shall I come before the LORD, And bow myself before the High God? Shall I come before Him with burnt offerings, With calves a year old?", 
                "num": 6
              }, 
              {
                "text": "Will the LORD be pleased with thousands of rams, Ten thousand rivers of oil? Shall I give my firstborn for my transgression, The fruit of my body for the sin of my soul?", 
                "num": 7
              }, 
              {
                "text": "He has shown you, O man, what is good; And what does the LORD require of you But to do justly, To love mercy, And to walk humbly with your God?", 
                "num": 8
              }, 
              {
                "text": "The LORD's voice cries to the city-- Wisdom shall see Your name: \"Hear the rod! Who has appointed it?", 
                "num": 9
              }, 
              {
                "text": "Are there yet the treasures of wickedness In the house of the wicked, And the short measure that is an abomination?", 
                "num": 10
              }, 
              {
                "text": "Shall I count pure those with the wicked scales, And with the bag of deceitful weights?", 
                "num": 11
              }, 
              {
                "text": "For her rich men are full of violence, Her inhabitants have spoken lies, And their tongue is deceitful in their mouth.", 
                "num": 12
              }, 
              {
                "text": "\"Therefore I will also make you sick by striking you, By making you desolate because of your sins.", 
                "num": 13
              }, 
              {
                "text": "You shall eat, but not be satisfied; Hunger shall be in your midst. You may carry some away, but shall not save them; And what you do rescue I will give over to the sword.", 
                "num": 14
              }, 
              {
                "text": "\"You shall sow, but not reap; You shall tread the olives, but not anoint yourselves with oil; And make sweet wine, but not drink wine.", 
                "num": 15
              }, 
              {
                "text": "For the statutes of Omri are kept; All the works of Ahab's house are done; And you walk in their counsels, That I may make you a desolation, And your inhabitants a hissing. Therefore you shall bear the reproach of My people.\"", 
                "num": 16
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Woe is me! For I am like those who gather summer fruits, Like those who glean vintage grapes; There is no cluster to eat Of the first-ripe fruit which my soul desires.", 
                "num": 1
              }, 
              {
                "text": "The faithful man has perished from the earth, And there is no one upright among men. They all lie in wait for blood; Every man hunts his brother with a net.", 
                "num": 2
              }, 
              {
                "text": "That they may successfully do evil with both hands-- The prince asks for gifts, The judge seeks a bribe, And the great man utters his evil desire; So they scheme together.", 
                "num": 3
              }, 
              {
                "text": "The best of them is like a brier; The most upright is sharper than a thorn hedge; The day of your watchman and your punishment comes; Now shall be their perplexity.", 
                "num": 4
              }, 
              {
                "text": "Do not trust in a friend; Do not put your confidence in a companion; Guard the doors of your mouth From her who lies in your bosom.", 
                "num": 5
              }, 
              {
                "text": "For son dishonors father, Daughter rises against her mother, Daughter-in-law against her mother-in-law; A man's enemies are the men of his own household.", 
                "num": 6
              }, 
              {
                "text": "Therefore I will look to the LORD; I will wait for the God of my salvation; My God will hear me.", 
                "num": 7
              }, 
              {
                "text": "Do not rejoice over me, my enemy; When I fall, I will arise; When I sit in darkness, The LORD will be a light to me.", 
                "num": 8
              }, 
              {
                "text": "I will bear the indignation of the LORD, Because I have sinned against Him, Until He pleads my case And executes justice for me. He will bring me forth to the light; I will see His righteousness.", 
                "num": 9
              }, 
              {
                "text": "Then she who is my enemy will see, And shame will cover her who said to me, \"Where is the LORD your God?\" My eyes will see her; Now she will be trampled down Like mud in the streets.", 
                "num": 10
              }, 
              {
                "text": "In the day when your walls are to be built, In that day the decree shall go far and wide.", 
                "num": 11
              }, 
              {
                "text": "In that day they shall come to you From Assyria and the fortified cities, From the fortress to the River, From sea to sea, And mountain to mountain.", 
                "num": 12
              }, 
              {
                "text": "Yet the land shall be desolate Because of those who dwell in it, And for the fruit of their deeds.", 
                "num": 13
              }, 
              {
                "text": "Shepherd Your people with Your staff, The flock of Your heritage, Who dwell solitarily in a woodland, In the midst of Carmel; Let them feed in Bashan and Gilead, As in days of old.", 
                "num": 14
              }, 
              {
                "text": "\"As in the days when you came out of the land of Egypt, I will show them wonders.\"", 
                "num": 15
              }, 
              {
                "text": "The nations shall see and be ashamed of all their might; They shall put their hand over their mouth; Their ears shall be deaf.", 
                "num": 16
              }, 
              {
                "text": "They shall lick the dust like a serpent; They shall crawl from their holes like snakes of the earth. They shall be afraid of the LORD our God, And shall fear because of You.", 
                "num": 17
              }, 
              {
                "text": "Who is a God like You, Pardoning iniquity And passing over the transgression of the remnant of His heritage? He does not retain His anger forever, Because He delights in mercy.", 
                "num": 18
              }, 
              {
                "text": "He will again have compassion on us, And will subdue our iniquities. You will cast all our sins Into the depths of the sea.", 
                "num": 19
              }, 
              {
                "text": "You will give truth to Jacob And mercy to Abraham, Which You have sworn to our fathers From days of old.", 
                "num": 20
              }
            ], 
            "num": 7
          }
        ]
      }, 
      {
        "name": "Nahum", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The burden against Nineveh. The book of the vision of Nahum the Elkoshite.", 
                "num": 1
              }, 
              {
                "text": "God is jealous, and the LORD avenges; The LORD avenges and is furious. The LORD will take vengeance on His adversaries, And He reserves wrath for His enemies;", 
                "num": 2
              }, 
              {
                "text": "The LORD is slow to anger and great in power, And will not at all acquit the wicked. The LORD has His way In the whirlwind and in the storm, And the clouds are the dust of His feet.", 
                "num": 3
              }, 
              {
                "text": "He rebukes the sea and makes it dry, And dries up all the rivers. Bashan and Carmel wither, And the flower of Lebanon wilts.", 
                "num": 4
              }, 
              {
                "text": "The mountains quake before Him, The hills melt, And the earth heaves at His presence, Yes, the world and all who dwell in it.", 
                "num": 5
              }, 
              {
                "text": "Who can stand before His indignation? And who can endure the fierceness of His anger? His fury is poured out like fire, And the rocks are thrown down by Him.", 
                "num": 6
              }, 
              {
                "text": "The LORD is good, A stronghold in the day of trouble; And He knows those who trust in Him.", 
                "num": 7
              }, 
              {
                "text": "But with an overflowing flood He will make an utter end of its place, And darkness will pursue His enemies.", 
                "num": 8
              }, 
              {
                "text": "What do you conspire against the LORD? He will make an utter end of it. Affliction will not rise up a second time.", 
                "num": 9
              }, 
              {
                "text": "For while tangled like thorns, And while drunken like drunkards, They shall be devoured like stubble fully dried.", 
                "num": 10
              }, 
              {
                "text": "From you comes forth one Who plots evil against the LORD, A wicked counselor.", 
                "num": 11
              }, 
              {
                "text": "Thus says the LORD: \"Though they are safe, and likewise many, Yet in this manner they will be cut down When he passes through. Though I have afflicted you, I will afflict you no more;", 
                "num": 12
              }, 
              {
                "text": "For now I will break off his yoke from you, And burst your bonds apart.\"", 
                "num": 13
              }, 
              {
                "text": "The LORD has given a command concerning you: \"Your name shall be perpetuated no longer. Out of the house of your gods I will cut off the carved image and the molded image. I will dig your grave, For you are vile.\"", 
                "num": 14
              }, 
              {
                "text": "Behold, on the mountains The feet of him who brings good tidings, Who proclaims peace! O Judah, keep your appointed feasts, Perform your vows. For the wicked one shall no more pass through you; He is utterly cut off.", 
                "num": 15
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "He who scatters has come up before your face. Man the fort! Watch the road! Strengthen your flanks! Fortify your power mightily.", 
                "num": 1
              }, 
              {
                "text": "For the LORD will restore the excellence of Jacob Like the excellence of Israel, For the emptiers have emptied them out And ruined their vine branches.", 
                "num": 2
              }, 
              {
                "text": "The shields of his mighty men are made red, The valiant men are in scarlet. The chariots come with flaming torches In the day of his preparation, And the spears are brandished.", 
                "num": 3
              }, 
              {
                "text": "The chariots rage in the streets, They jostle one another in the broad roads; They seem like torches, They run like lightning.", 
                "num": 4
              }, 
              {
                "text": "He remembers his nobles; They stumble in their walk; They make haste to her walls, And the defense is prepared.", 
                "num": 5
              }, 
              {
                "text": "The gates of the rivers are opened, And the palace is dissolved.", 
                "num": 6
              }, 
              {
                "text": "It is decreed: She shall be led away captive, She shall be brought up; And her maidservants shall lead her as with the voice of doves, Beating their breasts.", 
                "num": 7
              }, 
              {
                "text": "Though Nineveh of old was like a pool of water, Now they flee away. \"Halt! Halt!\" they cry; But no one turns back.", 
                "num": 8
              }, 
              {
                "text": "Take spoil of silver! Take spoil of gold! There is no end of treasure, Or wealth of every desirable prize.", 
                "num": 9
              }, 
              {
                "text": "She is empty, desolate, and waste! The heart melts, and the knees shake; Much pain is in every side, And all their faces are drained of color.1", 
                "num": 10
              }, 
              {
                "text": "Where is the dwelling of the lions, And the feeding place of the young lions, Where the lion walked, the lioness and lion's cub, And no one made them afraid?", 
                "num": 11
              }, 
              {
                "text": "The lion tore in pieces enough for his cubs, Killed for his lionesses, Filled his caves with prey, And his dens with flesh.", 
                "num": 12
              }, 
              {
                "text": "\"Behold, I am against you,\" says the LORD of hosts, \"I will burn your chariots in smoke, and the sword shall devour your young lions; I will cut off your prey from the earth, and the voice of your messengers shall be heard no more.\"", 
                "num": 13
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Woe to the bloody city! It is all full of lies and robbery. Its victim never departs.", 
                "num": 1
              }, 
              {
                "text": "The noise of a whip And the noise of rattling wheels, Of galloping horses, Of clattering chariots!", 
                "num": 2
              }, 
              {
                "text": "Horsemen charge with bright sword and glittering spear. There is a multitude of slain, A great number of bodies, Countless corpses-- They stumble over the corpses--", 
                "num": 3
              }, 
              {
                "text": "Because of the multitude of harlotries of the seductive harlot, The mistress of sorceries, Who sells nations through her harlotries, And families through her sorceries.", 
                "num": 4
              }, 
              {
                "text": "\"Behold, I am against you,\" says the LORD of hosts; \"I will lift your skirts over your face, I will show the nations your nakedness, And the kingdoms your shame.", 
                "num": 5
              }, 
              {
                "text": "I will cast abominable filth upon you, Make you vile, And make you a spectacle.", 
                "num": 6
              }, 
              {
                "text": "It shall come to pass that all who look upon you Will flee from you, and say, \"Nineveh is laid waste! Who will bemoan her?' Where shall I seek comforters for you?\"", 
                "num": 7
              }, 
              {
                "text": "Are you better than No Amon That was situated by the River, That had the waters around her, Whose rampart was the sea, Whose wall was the sea?", 
                "num": 8
              }, 
              {
                "text": "Ethiopia and Egypt were her strength, And it was boundless; Put and Lubim were your helpers.", 
                "num": 9
              }, 
              {
                "text": "Yet she was carried away, She went into captivity; Her young children also were dashed to pieces At the head of every street; They cast lots for her honorable men, And all her great men were bound in chains.", 
                "num": 10
              }, 
              {
                "text": "You also will be drunk; You will be hidden; You also will seek refuge from the enemy.", 
                "num": 11
              }, 
              {
                "text": "All your strongholds are fig trees with ripened figs: If they are shaken, They fall into the mouth of the eater.", 
                "num": 12
              }, 
              {
                "text": "Surely, your people in your midst are women! The gates of your land are wide open for your enemies; Fire shall devour the bars of your gates.", 
                "num": 13
              }, 
              {
                "text": "Draw your water for the siege! Fortify your strongholds! Go into the clay and tread the mortar! Make strong the brick kiln!", 
                "num": 14
              }, 
              {
                "text": "There the fire will devour you, The sword will cut you off; It will eat you up like a locust. Make yourself many--like the locust! Make yourself many-- like the swarming locusts!", 
                "num": 15
              }, 
              {
                "text": "You have multiplied your merchants more than the stars of heaven. The locust plunders and flies away.", 
                "num": 16
              }, 
              {
                "text": "Your commanders are like swarming locusts, And your generals like great grasshoppers, Which camp in the hedges on a cold day; When the sun rises they flee away, And the place where they are is not known.", 
                "num": 17
              }, 
              {
                "text": "Your shepherds slumber, O king of Assyria; Your nobles rest in the dust. Your people are scattered on the mountains, And no one gathers them.", 
                "num": 18
              }, 
              {
                "text": "Your injury has no healing, Your wound is severe. All who hear news of you Will clap their hands over you, For upon whom has not your wickedness passed continually?", 
                "num": 19
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "Habakkuk", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The burden which the prophet Habakkuk saw.", 
                "num": 1
              }, 
              {
                "text": "O LORD, how long shall I cry, And You will not hear? Even cry out to You, \"Violence!\" And You will not save.", 
                "num": 2
              }, 
              {
                "text": "Why do You show me iniquity, And cause me to see trouble? For plundering and violence are before me; There is strife, and contention arises.", 
                "num": 3
              }, 
              {
                "text": "Therefore the law is powerless, And justice never goes forth. For the wicked surround the righteous; Therefore perverse judgment proceeds.", 
                "num": 4
              }, 
              {
                "text": "\"Look among the nations and watch-- Be utterly astounded! For I will work a work in your days Which you would not believe, though it were told you.", 
                "num": 5
              }, 
              {
                "text": "For indeed I am raising up the Chaldeans, A bitter and hasty nation Which marches through the breadth of the earth, To possess dwelling places that are not theirs.", 
                "num": 6
              }, 
              {
                "text": "They are terrible and dreadful; Their judgment and their dignity proceed from themselves.", 
                "num": 7
              }, 
              {
                "text": "Their horses also are swifter than leopards, And more fierce than evening wolves. Their chargers charge ahead; Their cavalry comes from afar; They fly as the eagle that hastens to eat.", 
                "num": 8
              }, 
              {
                "text": "\"They all come for violence; Their faces are set like the east wind. They gather captives like sand.", 
                "num": 9
              }, 
              {
                "text": "They scoff at kings, And princes are scorned by them. They deride every stronghold, For they heap up earthen mounds and seize it.", 
                "num": 10
              }, 
              {
                "text": "Then his mind changes, and he transgresses; He commits offense, Ascribing this power to his god.\"", 
                "num": 11
              }, 
              {
                "text": "Are You not from everlasting, O LORD my God, my Holy One? We shall not die. O LORD, You have appointed them for judgment; O Rock, You have marked them for correction.", 
                "num": 12
              }, 
              {
                "text": "You are of purer eyes than to behold evil, And cannot look on wickedness. Why do You look on those who deal treacherously, And hold Your tongue when the wicked devours A person more righteous than he?", 
                "num": 13
              }, 
              {
                "text": "Why do You make men like fish of the sea, Like creeping things that have no ruler over them?", 
                "num": 14
              }, 
              {
                "text": "They take up all of them with a hook, They catch them in their net, And gather them in their dragnet. Therefore they rejoice and are glad.", 
                "num": 15
              }, 
              {
                "text": "Therefore they sacrifice to their net, And burn incense to their dragnet; Because by them their share is sumptuous And their food plentiful.", 
                "num": 16
              }, 
              {
                "text": "Shall they therefore empty their net, And continue to slay nations without pity?", 
                "num": 17
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "I will stand my watch And set myself on the rampart, And watch to see what He will say to me, And what I will answer when I am corrected.", 
                "num": 1
              }, 
              {
                "text": "Then the LORD answered me and said: \"Write the vision And make it plain on tablets, That he may run who reads it.", 
                "num": 2
              }, 
              {
                "text": "For the vision is yet for an appointed time; But at the end it will speak, and it will not lie. Though it tarries, wait for it; Because it will surely come, It will not tarry.", 
                "num": 3
              }, 
              {
                "text": "\"Behold the proud, His soul is not upright in him; But the just shall live by his faith.", 
                "num": 4
              }, 
              {
                "text": "\"Indeed, because he transgresses by wine, He is a proud man, And he does not stay at home. Because he enlarges his desire as hell, And he is like death, and cannot be satisfied, He gathers to himself all nations And heaps up for himself all peoples.", 
                "num": 5
              }, 
              {
                "text": "\"Will not all these take up a proverb against him, And a taunting riddle against him, and say, \"Woe to him who increases What is not his--how long? And to him who loads himself with many pledges'?", 
                "num": 6
              }, 
              {
                "text": "Will not your creditors rise up suddenly? Will they not awaken who oppress you? And you will become their booty.", 
                "num": 7
              }, 
              {
                "text": "Because you have plundered many nations, All the remnant of the people shall plunder you, Because of men's blood And the violence of the land and the city, And of all who dwell in it.", 
                "num": 8
              }, 
              {
                "text": "\"Woe to him who covets evil gain for his house, That he may set his nest on high, That he may be delivered from the power of disaster!", 
                "num": 9
              }, 
              {
                "text": "You give shameful counsel to your house, Cutting off many peoples, And sin against your soul.", 
                "num": 10
              }, 
              {
                "text": "For the stone will cry out from the wall, And the beam from the timbers will answer it.", 
                "num": 11
              }, 
              {
                "text": "\"Woe to him who builds a town with bloodshed, Who establishes a city by iniquity!", 
                "num": 12
              }, 
              {
                "text": "Behold, is it not of the LORD of hosts That the peoples labor to feed the fire, And nations weary themselves in vain?", 
                "num": 13
              }, 
              {
                "text": "For the earth will be filled With the knowledge of the glory of the LORD, As the waters cover the sea.", 
                "num": 14
              }, 
              {
                "text": "\"Woe to him who gives drink to his neighbor, Pressing him to your bottle, Even to make him drunk, That you may look on his nakedness!", 
                "num": 15
              }, 
              {
                "text": "You are filled with shame instead of glory. You also--drink! And be exposed as uncircumcised! The cup of the LORD's right hand will be turned against you, And utter shame will be on your glory.", 
                "num": 16
              }, 
              {
                "text": "For the violence done to Lebanon will cover you, And the plunder of beasts which made them afraid, Because of men's blood And the violence of the land and the city, And of all who dwell in it.", 
                "num": 17
              }, 
              {
                "text": "\"What profit is the image, that its maker should carve it, The molded image, a teacher of lies, That the maker of its mold should trust in it, To make mute idols?", 
                "num": 18
              }, 
              {
                "text": "Woe to him who says to wood, \"Awake!' To silent stone, \"Arise! It shall teach!' Behold, it is overlaid with gold and silver, Yet in it there is no breath at all.", 
                "num": 19
              }, 
              {
                "text": "\"But the LORD is in His holy temple. Let all the earth keep silence before Him.\"", 
                "num": 20
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "A prayer of Habakkuk the prophet, on Shigionoth.", 
                "num": 1
              }, 
              {
                "text": "O LORD, I have heard Your speech and was afraid; O LORD, revive Your work in the midst of the years! In the midst of the years make it known; In wrath remember mercy.", 
                "num": 2
              }, 
              {
                "text": "God came from Teman, The Holy One from Mount Paran. Selah His glory covered the heavens, And the earth was full of His praise.", 
                "num": 3
              }, 
              {
                "text": "His brightness was like the light; He had rays flashing from His hand, And there His power was hidden.", 
                "num": 4
              }, 
              {
                "text": "Before Him went pestilence, And fever followed at His feet.", 
                "num": 5
              }, 
              {
                "text": "He stood and measured the earth; He looked and startled the nations. And the everlasting mountains were scattered, The perpetual hills bowed. His ways are everlasting.", 
                "num": 6
              }, 
              {
                "text": "I saw the tents of Cushan in affliction; The curtains of the land of Midian trembled.", 
                "num": 7
              }, 
              {
                "text": "O LORD, were You displeased with the rivers, Was Your anger against the rivers, Was Your wrath against the sea, That You rode on Your horses, Your chariots of salvation?", 
                "num": 8
              }, 
              {
                "text": "Your bow was made quite ready; Oaths were sworn over Your arrows. Selah You divided the earth with rivers.", 
                "num": 9
              }, 
              {
                "text": "The mountains saw You and trembled; The overflowing of the water passed by. The deep uttered its voice, And lifted its hands on high.", 
                "num": 10
              }, 
              {
                "text": "The sun and moon stood still in their habitation; At the light of Your arrows they went, At the shining of Your glittering spear.", 
                "num": 11
              }, 
              {
                "text": "You marched through the land in indignation; You trampled the nations in anger.", 
                "num": 12
              }, 
              {
                "text": "You went forth for the salvation of Your people, For salvation with Your Anointed. You struck the head from the house of the wicked, By laying bare from foundation to neck. Selah", 
                "num": 13
              }, 
              {
                "text": "You thrust through with his own arrows The head of his villages. They came out like a whirlwind to scatter me; Their rejoicing was like feasting on the poor in secret.", 
                "num": 14
              }, 
              {
                "text": "You walked through the sea with Your horses, Through the heap of great waters.", 
                "num": 15
              }, 
              {
                "text": "When I heard, my body trembled; My lips quivered at the voice; Rottenness entered my bones; And I trembled in myself, That I might rest in the day of trouble. When he comes up to the people, He will invade them with his troops.", 
                "num": 16
              }, 
              {
                "text": "Though the fig tree may not blossom, Nor fruit be on the vines; Though the labor of the olive may fail, And the fields yield no food; Though the flock may be cut off from the fold, And there be no herd in the stalls--", 
                "num": 17
              }, 
              {
                "text": "Yet I will rejoice in the LORD, I will joy in the God of my salvation.", 
                "num": 18
              }, 
              {
                "text": "The LORD God is my strength; He will make my feet like deer's feet, And He will make me walk on my high hills. To the Chief Musician. With my stringed instruments.", 
                "num": 19
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "Zephaniah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The word of the LORD which came to Zephaniah the son of Cushi, the son of Gedaliah, the son of Amariah, the son of Hezekiah, in the days of Josiah the son of Amon, king of Judah.", 
                "num": 1
              }, 
              {
                "text": "\"I will utterly consume everything From the face of the land,\" Says the LORD;", 
                "num": 2
              }, 
              {
                "text": "\"I will consume man and beast; I will consume the birds of the heavens, The fish of the sea, And the stumbling blocks along with the wicked. I will cut off man from the face of the land,\" Says the LORD.", 
                "num": 3
              }, 
              {
                "text": "\"I will stretch out My hand against Judah, And against all the inhabitants of Jerusalem. I will cut off every trace of Baal from this place, The names of the idolatrous priests with the pagan priests--", 
                "num": 4
              }, 
              {
                "text": "Those who worship the host of heaven on the housetops; Those who worship and swear oaths by the LORD, But who also swear by Milcom;", 
                "num": 5
              }, 
              {
                "text": "Those who have turned back from following the LORD, And have not sought the LORD, nor inquired of Him.\"", 
                "num": 6
              }, 
              {
                "text": "Be silent in the presence of the Lord GOD; For the day of the LORD is at hand, For the LORD has prepared a sacrifice; He has invited His guests.", 
                "num": 7
              }, 
              {
                "text": "\"And it shall be, In the day of the LORD's sacrifice, That I will punish the princes and the king's children, And all such as are clothed with foreign apparel.", 
                "num": 8
              }, 
              {
                "text": "In the same day I will punish All those who leap over the threshold, Who fill their masters' houses with violence and deceit.", 
                "num": 9
              }, 
              {
                "text": "\"And there shall be on that day,\" says the LORD, \"The sound of a mournful cry from the Fish Gate, A wailing from the Second Quarter, And a loud crashing from the hills.", 
                "num": 10
              }, 
              {
                "text": "Wail, you inhabitants of Maktesh! For all the merchant people are cut down; All those who handle money are cut off.", 
                "num": 11
              }, 
              {
                "text": "\"And it shall come to pass at that time That I will search Jerusalem with lamps, And punish the men Who are settled in complacency, Who say in their heart, \"The LORD will not do good, Nor will He do evil.'", 
                "num": 12
              }, 
              {
                "text": "Therefore their goods shall become booty, And their houses a desolation; They shall build houses, but not inhabit them; They shall plant vineyards, but not drink their wine.\"", 
                "num": 13
              }, 
              {
                "text": "The great day of the LORD is near; It is near and hastens quickly. The noise of the day of the LORD is bitter; There the mighty men shall cry out.", 
                "num": 14
              }, 
              {
                "text": "That day is a day of wrath, A day of trouble and distress, A day of devastation and desolation, A day of darkness and gloominess, A day of clouds and thick darkness,", 
                "num": 15
              }, 
              {
                "text": "A day of trumpet and alarm Against the fortified cities And against the high towers.", 
                "num": 16
              }, 
              {
                "text": "\"I will bring distress upon men, And they shall walk like blind men, Because they have sinned against the LORD; Their blood shall be poured out like dust, And their flesh like refuse.\"", 
                "num": 17
              }, 
              {
                "text": "Neither their silver nor their gold Shall be able to deliver them In the day of the LORD's wrath; But the whole land shall be devoured By the fire of His jealousy, For He will make speedy riddance Of all those who dwell in the land.", 
                "num": 18
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Gather yourselves together, yes, gather together, O undesirable nation,", 
                "num": 1
              }, 
              {
                "text": "Before the decree is issued, Or the day passes like chaff, Before the LORD's fierce anger comes upon you, Before the day of the LORD's anger comes upon you!", 
                "num": 2
              }, 
              {
                "text": "Seek the LORD, all you meek of the earth, Who have upheld His justice. Seek righteousness, seek humility. It may be that you will be hidden In the day of the LORD's anger.", 
                "num": 3
              }, 
              {
                "text": "For Gaza shall be forsaken, And Ashkelon desolate; They shall drive out Ashdod at noonday, And Ekron shall be uprooted.", 
                "num": 4
              }, 
              {
                "text": "Woe to the inhabitants of the seacoast, The nation of the Cherethites! The word of the LORD is against you, O Canaan, land of the Philistines: \"I will destroy you; So there shall be no inhabitant.\"", 
                "num": 5
              }, 
              {
                "text": "The seacoast shall be pastures, With shelters for shepherds and folds for flocks.", 
                "num": 6
              }, 
              {
                "text": "The coast shall be for the remnant of the house of Judah; They shall feed their flocks there; In the houses of Ashkelon they shall lie down at evening. For the LORD their God will intervene for them, And return their captives.", 
                "num": 7
              }, 
              {
                "text": "\"I have heard the reproach of Moab, And the insults of the people of Ammon, With which they have reproached My people, And made arrogant threats against their borders.", 
                "num": 8
              }, 
              {
                "text": "Therefore, as I live,\" Says the LORD of hosts, the God of Israel, \"Surely Moab shall be like Sodom, And the people of Ammon like Gomorrah-- Overrun with weeds and saltpits, And a perpetual desolation. The residue of My people shall plunder them, And the remnant of My people shall possess them.\"", 
                "num": 9
              }, 
              {
                "text": "This they shall have for their pride, Because they have reproached and made arrogant threats Against the people of the LORD of hosts.", 
                "num": 10
              }, 
              {
                "text": "The LORD will be awesome to them, For He will reduce to nothing all the gods of the earth; People shall worship Him, Each one from his place, Indeed all the shores of the nations.", 
                "num": 11
              }, 
              {
                "text": "\"You Ethiopians also, You shall be slain by My sword.\"", 
                "num": 12
              }, 
              {
                "text": "And He will stretch out His hand against the north, Destroy Assyria, And make Nineveh a desolation, As dry as the wilderness.", 
                "num": 13
              }, 
              {
                "text": "The herds shall lie down in her midst, Every beast of the nation. Both the pelican and the bittern Shall lodge on the capitals of her pillars; Their voice shall sing in the windows; Desolation shall be at the threshold; For He will lay bare the cedar work.", 
                "num": 14
              }, 
              {
                "text": "This is the rejoicing city That dwelt securely, That said in her heart, \"I am it, and there is none besides me.\" How has she become a desolation, A place for beasts to lie down! Everyone who passes by her Shall hiss and shake his fist.", 
                "num": 15
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Woe to her who is rebellious and polluted, To the oppressing city!", 
                "num": 1
              }, 
              {
                "text": "She has not obeyed His voice, She has not received correction; She has not trusted in the LORD, She has not drawn near to her God.", 
                "num": 2
              }, 
              {
                "text": "Her princes in her midst are roaring lions; Her judges are evening wolves That leave not a bone till morning.", 
                "num": 3
              }, 
              {
                "text": "Her prophets are insolent, treacherous people; Her priests have polluted the sanctuary, They have done violence to the law.", 
                "num": 4
              }, 
              {
                "text": "The LORD is righteous in her midst, He will do no unrighteousness. Every morning He brings His justice to light; He never fails, But the unjust knows no shame.", 
                "num": 5
              }, 
              {
                "text": "\"I have cut off nations, Their fortresses are devastated; I have made their streets desolate, With none passing by. Their cities are destroyed; There is no one, no inhabitant.", 
                "num": 6
              }, 
              {
                "text": "I said, \"Surely you will fear Me, You will receive instruction'-- So that her dwelling would not be cut off, Despite everything for which I punished her. But they rose early and corrupted all their deeds.", 
                "num": 7
              }, 
              {
                "text": "\"Therefore wait for Me,\" says the LORD, \"Until the day I rise up for plunder; My determination is to gather the nations To My assembly of kingdoms, To pour on them My indignation, All My fierce anger; All the earth shall be devoured With the fire of My jealousy.", 
                "num": 8
              }, 
              {
                "text": "\"For then I will restore to the peoples a pure language, That they all may call on the name of the LORD, To serve Him with one accord.", 
                "num": 9
              }, 
              {
                "text": "From beyond the rivers of Ethiopia My worshipers, The daughter of My dispersed ones, Shall bring My offering.", 
                "num": 10
              }, 
              {
                "text": "In that day you shall not be shamed for any of your deeds In which you transgress against Me; For then I will take away from your midst Those who rejoice in your pride, And you shall no longer be haughty In My holy mountain.", 
                "num": 11
              }, 
              {
                "text": "I will leave in your midst A meek and humble people, And they shall trust in the name of the LORD.", 
                "num": 12
              }, 
              {
                "text": "The remnant of Israel shall do no unrighteousness And speak no lies, Nor shall a deceitful tongue be found in their mouth; For they shall feed their flocks and lie down, And no one shall make them afraid.\"", 
                "num": 13
              }, 
              {
                "text": "Sing, O daughter of Zion! Shout, O Israel! Be glad and rejoice with all your heart, O daughter of Jerusalem!", 
                "num": 14
              }, 
              {
                "text": "The LORD has taken away your judgments, He has cast out your enemy. The King of Israel, the LORD, is in your midst; You shall see disaster no more.", 
                "num": 15
              }, 
              {
                "text": "In that day it shall be said to Jerusalem: \"Do not fear; Zion, let not your hands be weak.", 
                "num": 16
              }, 
              {
                "text": "The LORD your God in your midst, The Mighty One, will save; He will rejoice over you with gladness, He will quiet you with His love, He will rejoice over you with singing.\"", 
                "num": 17
              }, 
              {
                "text": "\"I will gather those who sorrow over the appointed assembly, Who are among you, To whom its reproach is a burden.", 
                "num": 18
              }, 
              {
                "text": "Behold, at that time I will deal with all who afflict you; I will save the lame, And gather those who were driven out; I will appoint them for praise and fame In every land where they were put to shame.", 
                "num": 19
              }, 
              {
                "text": "At that time I will bring you back, Even at the time I gather you; For I will give you fame and praise Among all the peoples of the earth, When I return your captives before your eyes,\" Says the LORD.", 
                "num": 20
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "Haggai", 
        "chapters": [
          {
            "verses": [
              {
                "text": "In the second year of King Darius, in the sixth month, on the first day of the month, the word of the LORD came by Haggai the prophet to Zerubbabel the son of Shealtiel, governor of Judah, and to Joshua the son of Jehozadak, the high priest, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus speaks the LORD of hosts, saying: \"This people says, \"The time has not come, the time that the LORD's house should be built.\"\"'", 
                "num": 2
              }, 
              {
                "text": "Then the word of the LORD came by Haggai the prophet, saying,", 
                "num": 3
              }, 
              {
                "text": "\"Is it time for you yourselves to dwell in your paneled houses, and this temple to lie in ruins?\"", 
                "num": 4
              }, 
              {
                "text": "Now therefore, thus says the LORD of hosts: \"Consider your ways!", 
                "num": 5
              }, 
              {
                "text": "\"You have sown much, and bring in little; You eat, but do not have enough; You drink, but you are not filled with drink; You clothe yourselves, but no one is warm; And he who earns wages, Earns wages to put into a bag with holes.\"", 
                "num": 6
              }, 
              {
                "text": "Thus says the LORD of hosts: \"Consider your ways!", 
                "num": 7
              }, 
              {
                "text": "Go up to the mountains and bring wood and build the temple, that I may take pleasure in it and be glorified,\" says the LORD.", 
                "num": 8
              }, 
              {
                "text": "\"You looked for much, but indeed it came to little; and when you brought it home, I blew it away. Why?\" says the LORD of hosts. \"Because of My house that is in ruins, while every one of you runs to his own house.", 
                "num": 9
              }, 
              {
                "text": "Therefore the heavens above you withhold the dew, and the earth withholds its fruit.", 
                "num": 10
              }, 
              {
                "text": "For I called for a drought on the land and the mountains, on the grain and the new wine and the oil, on whatever the ground brings forth, on men and livestock, and on all the labor of your hands.\"", 
                "num": 11
              }, 
              {
                "text": "Then Zerubbabel the son of Shealtiel, and Joshua the son of Jehozadak, the high priest, with all the remnant of the people, obeyed the voice of the LORD their God, and the words of Haggai the prophet, as the LORD their God had sent him; and the people feared the presence of the LORD.", 
                "num": 12
              }, 
              {
                "text": "Then Haggai, the LORD's messenger, spoke the LORD's message to the people, saying, \"I am with you, says the LORD.\"", 
                "num": 13
              }, 
              {
                "text": "So the LORD stirred up the spirit of Zerubbabel the son of Shealtiel, governor of Judah, and the spirit of Joshua the son of Jehozadak, the high priest, and the spirit of all the remnant of the people; and they came and worked on the house of the LORD of hosts, their God,", 
                "num": 14
              }, 
              {
                "text": "on the twenty-fourth day of the sixth month, in the second year of King Darius.", 
                "num": 15
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "In the seventh month, on the twenty-first of the month, the word of the LORD came by Haggai the prophet, saying:", 
                "num": 1
              }, 
              {
                "text": "\"Speak now to Zerubbabel the son of Shealtiel, governor of Judah, and to Joshua the son of Jehozadak, the high priest, and to the remnant of the people, saying:", 
                "num": 2
              }, 
              {
                "text": "\"Who is left among you who saw this temple in its former glory? And how do you see it now? In comparison with it, is this not in your eyes as nothing?", 
                "num": 3
              }, 
              {
                "text": "Yet now be strong, Zerubbabel,' says the LORD; \"and be strong, Joshua, son of Jehozadak, the high priest; and be strong, all you people of the land,' says the LORD, \"and work; for I am with you,' says the LORD of hosts.", 
                "num": 4
              }, 
              {
                "text": "\"According to the word that I covenanted with you when you came out of Egypt, so My Spirit remains among you; do not fear!'", 
                "num": 5
              }, 
              {
                "text": "\"For thus says the LORD of hosts: \"Once more (it is a little while) I will shake heaven and earth, the sea and dry land;", 
                "num": 6
              }, 
              {
                "text": "and I will shake all nations, and they shall come to the Desire of All Nations, and I will fill this temple with glory,' says the LORD of hosts.", 
                "num": 7
              }, 
              {
                "text": "\"The silver is Mine, and the gold is Mine,' says the LORD of hosts.", 
                "num": 8
              }, 
              {
                "text": "\"The glory of this latter temple shall be greater than the former,' says the LORD of hosts. \"And in this place I will give peace,' says the LORD of hosts.\"", 
                "num": 9
              }, 
              {
                "text": "On the twenty-fourth day of the ninth month, in the second year of Darius, the word of the LORD came by Haggai the prophet, saying,", 
                "num": 10
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Now, ask the priests concerning the law, saying,", 
                "num": 11
              }, 
              {
                "text": "\"If one carries holy meat in the fold of his garment, and with the edge he touches bread or stew, wine or oil, or any food, will it become holy?\"\"' Then the priests answered and said, \"No.\"", 
                "num": 12
              }, 
              {
                "text": "And Haggai said, \"If one who is unclean because of a dead body touches any of these, will it be unclean?\" So the priests answered and said, \"It shall be unclean.\"", 
                "num": 13
              }, 
              {
                "text": "Then Haggai answered and said, \"\"So is this people, and so is this nation before Me,' says the LORD, \"and so is every work of their hands; and what they offer there is unclean.", 
                "num": 14
              }, 
              {
                "text": "\"And now, carefully consider from this day forward: from before stone was laid upon stone in the temple of the LORD--", 
                "num": 15
              }, 
              {
                "text": "since those days, when one came to a heap of twenty ephahs, there were but ten; when one came to the wine vat to draw out fifty baths from the press, there were but twenty.", 
                "num": 16
              }, 
              {
                "text": "I struck you with blight and mildew and hail in all the labors of your hands; yet you did not turn to Me,' says the LORD.", 
                "num": 17
              }, 
              {
                "text": "\"Consider now from this day forward, from the twenty-fourth day of the ninth month, from the day that the foundation of the LORD's temple was laid--consider it:", 
                "num": 18
              }, 
              {
                "text": "Is the seed still in the barn? As yet the vine, the fig tree, the pomegranate, and the olive tree have not yielded fruit. But from this day I will bless you.\"'", 
                "num": 19
              }, 
              {
                "text": "And again the word of the LORD came to Haggai on the twenty-fourth day of the month, saying,", 
                "num": 20
              }, 
              {
                "text": "\"Speak to Zerubbabel, governor of Judah, saying: \"I will shake heaven and earth.", 
                "num": 21
              }, 
              {
                "text": "I will overthrow the throne of kingdoms; I will destroy the strength of the Gentile kingdoms. I will overthrow the chariots And those who ride in them; The horses and their riders shall come down, Every one by the sword of his brother.", 
                "num": 22
              }, 
              {
                "text": "\"In that day,' says the LORD of hosts, \"I will take you, Zerubbabel My servant, the son of Shealtiel,' says the LORD, \"and will make you like a signet ring; for I have chosen you,' says the LORD of hosts.\"", 
                "num": 23
              }
            ], 
            "num": 2
          }
        ]
      }, 
      {
        "name": "Zechariah", 
        "chapters": [
          {
            "verses": [
              {
                "text": "In the eighth month of the second year of Darius, the word of the LORD came to Zechariah the son of Berechiah, the son of Iddo the prophet, saying,", 
                "num": 1
              }, 
              {
                "text": "\"The LORD has been very angry with your fathers.", 
                "num": 2
              }, 
              {
                "text": "Therefore say to them, \"Thus says the LORD of hosts: \"Return to Me,\" says the LORD of hosts, \"and I will return to you,\" says the LORD of hosts.", 
                "num": 3
              }, 
              {
                "text": "\"Do not be like your fathers, to whom the former prophets preached, saying, \"Thus says the LORD of hosts: \"Turn now from your evil ways and your evil deeds.\"' But they did not hear nor heed Me,\" says the LORD.", 
                "num": 4
              }, 
              {
                "text": "\"Your fathers, where are they? And the prophets, do they live forever?", 
                "num": 5
              }, 
              {
                "text": "Yet surely My words and My statutes, Which I commanded My servants the prophets, Did they not overtake your fathers? \"So they returned and said: \"Just as the LORD of hosts determined to do to us, According to our ways and according to our deeds, So He has dealt with us.\"\"\"", 
                "num": 6
              }, 
              {
                "text": "On the twenty-fourth day of the eleventh month, which is the month Shebat, in the second year of Darius, the word of the LORD came to Zechariah the son of Berechiah, the son of Iddo the prophet:", 
                "num": 7
              }, 
              {
                "text": "I saw by night, and behold, a man riding on a red horse, and it stood among the myrtle trees in the hollow; and behind him were horses: red, sorrel, and white.", 
                "num": 8
              }, 
              {
                "text": "Then I said, \"My lord, what are these?\" So the angel who talked with me said to me, \"I will show you what they are.\"", 
                "num": 9
              }, 
              {
                "text": "And the man who stood among the myrtle trees answered and said, \"These are the ones whom the LORD has sent to walk to and fro throughout the earth.\"", 
                "num": 10
              }, 
              {
                "text": "So they answered the Angel of the LORD, who stood among the myrtle trees, and said, \"We have walked to and fro throughout the earth, and behold, all the earth is resting quietly.\"", 
                "num": 11
              }, 
              {
                "text": "Then the Angel of the LORD answered and said, \"O LORD of hosts, how long will You not have mercy on Jerusalem and on the cities of Judah, against which You were angry these seventy years?\"", 
                "num": 12
              }, 
              {
                "text": "And the LORD answered the angel who talked to me, with good and comforting words.", 
                "num": 13
              }, 
              {
                "text": "So the angel who spoke with me said to me, \"Proclaim, saying, \"Thus says the LORD of hosts: \"I am zealous for Jerusalem And for Zion with great zeal.", 
                "num": 14
              }, 
              {
                "text": "I am exceedingly angry with the nations at ease; For I was a little angry, And they helped--but with evil intent.\"", 
                "num": 15
              }, 
              {
                "text": "\"Therefore thus says the LORD: \"I am returning to Jerusalem with mercy; My house shall be built in it,\" says the LORD of hosts, \"And a surveyor's line shall be stretched out over Jerusalem.\"'", 
                "num": 16
              }, 
              {
                "text": "\"Again proclaim, saying, \"Thus says the LORD of hosts: \"My cities shall again spread out through prosperity; The LORD will again comfort Zion, And will again choose Jerusalem.\"\"'", 
                "num": 17
              }, 
              {
                "text": "Then I raised my eyes and looked, and there were four horns.", 
                "num": 18
              }, 
              {
                "text": "And I said to the angel who talked with me, \"What are these?\" So he answered me, \"These are the horns that have scattered Judah, Israel, and Jerusalem.\"", 
                "num": 19
              }, 
              {
                "text": "Then the LORD showed me four craftsmen.", 
                "num": 20
              }, 
              {
                "text": "And I said, \"What are these coming to do?\" So he said, \"These are the horns that scattered Judah, so that no one could lift up his head; but the craftsmen are coming to terrify them, to cast out the horns of the nations that lifted up their horn against the land of Judah to scatter it.\"", 
                "num": 21
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Then I raised my eyes and looked, and behold, a man with a measuring line in his hand.", 
                "num": 1
              }, 
              {
                "text": "So I said, \"Where are you going?\" And he said to me, \"To measure Jerusalem, to see what is its width and what is its length.\"", 
                "num": 2
              }, 
              {
                "text": "And there was the angel who talked with me, going out; and another angel was coming out to meet him,", 
                "num": 3
              }, 
              {
                "text": "who said to him, \"Run, speak to this young man, saying: \"Jerusalem shall be inhabited as towns without walls, because of the multitude of men and livestock in it.", 
                "num": 4
              }, 
              {
                "text": "For I,' says the LORD, \"will be a wall of fire all around her, and I will be the glory in her midst.\"'", 
                "num": 5
              }, 
              {
                "text": "\"Up, up! Flee from the land of the north,\" says the LORD; \"for I have spread you abroad like the four winds of heaven,\" says the LORD.", 
                "num": 6
              }, 
              {
                "text": "\"Up, Zion! Escape, you who dwell with the daughter of Babylon.\"", 
                "num": 7
              }, 
              {
                "text": "For thus says the LORD of hosts: \"He sent Me after glory, to the nations which plunder you; for he who touches you touches the apple of His eye.", 
                "num": 8
              }, 
              {
                "text": "For surely I will shake My hand against them, and they shall become spoil for their servants. Then you will know that the LORD of hosts has sent Me.", 
                "num": 9
              }, 
              {
                "text": "\"Sing and rejoice, O daughter of Zion! For behold, I am coming and I will dwell in your midst,\" says the LORD.", 
                "num": 10
              }, 
              {
                "text": "\"Many nations shall be joined to the LORD in that day, and they shall become My people. And I will dwell in your midst. Then you will know that the LORD of hosts has sent Me to you.", 
                "num": 11
              }, 
              {
                "text": "And the LORD will take possession of Judah as His inheritance in the Holy Land, and will again choose Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "Be silent, all flesh, before the LORD, for He is aroused from His holy habitation!\"", 
                "num": 13
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Then he showed me Joshua the high priest standing before the Angel of the LORD, and Satan standing at his right hand to oppose him.", 
                "num": 1
              }, 
              {
                "text": "And the LORD said to Satan, \"The LORD rebuke you, Satan! The LORD who has chosen Jerusalem rebuke you! Is this not a brand plucked from the fire?\"", 
                "num": 2
              }, 
              {
                "text": "Now Joshua was clothed with filthy garments, and was standing before the Angel.", 
                "num": 3
              }, 
              {
                "text": "Then He answered and spoke to those who stood before Him, saying, \"Take away the filthy garments from him.\" And to him He said, \"See, I have removed your iniquity from you, and I will clothe you with rich robes.\"", 
                "num": 4
              }, 
              {
                "text": "And I said, \"Let them put a clean turban on his head.\" So they put a clean turban on his head, and they put the clothes on him. And the Angel of the LORD stood by.", 
                "num": 5
              }, 
              {
                "text": "Then the Angel of the LORD admonished Joshua, saying,", 
                "num": 6
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"If you will walk in My ways, And if you will keep My command, Then you shall also judge My house, And likewise have charge of My courts; I will give you places to walk Among these who stand here.", 
                "num": 7
              }, 
              {
                "text": "\"Hear, O Joshua, the high priest, You and your companions who sit before you, For they are a wondrous sign; For behold, I am bringing forth My Servant the BRANCH.", 
                "num": 8
              }, 
              {
                "text": "For behold, the stone That I have laid before Joshua: Upon the stone are seven eyes. Behold, I will engrave its inscription,' Says the LORD of hosts, \"And I will remove the iniquity of that land in one day.", 
                "num": 9
              }, 
              {
                "text": "In that day,' says the LORD of hosts, \"Everyone will invite his neighbor Under his vine and under his fig tree.\"'", 
                "num": 10
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now the angel who talked with me came back and wakened me, as a man who is wakened out of his sleep.", 
                "num": 1
              }, 
              {
                "text": "And he said to me, \"What do you see?\" So I said, \"I am looking, and there is a lampstand of solid gold with a bowl on top of it, and on the stand seven lamps with seven pipes to the seven lamps.", 
                "num": 2
              }, 
              {
                "text": "Two olive trees are by it, one at the right of the bowl and the other at its left.\"", 
                "num": 3
              }, 
              {
                "text": "So I answered and spoke to the angel who talked with me, saying, \"What are these, my lord?\"", 
                "num": 4
              }, 
              {
                "text": "Then the angel who talked with me answered and said to me, \"Do you not know what these are?\" And I said, \"No, my lord.\"", 
                "num": 5
              }, 
              {
                "text": "So he answered and said to me: \"This is the word of the LORD to Zerubbabel: \"Not by might nor by power, but by My Spirit,' Says the LORD of hosts.", 
                "num": 6
              }, 
              {
                "text": "\"Who are you, O great mountain? Before Zerubbabel you shall become a plain! And he shall bring forth the capstone With shouts of \"Grace, grace to it!\"\"'", 
                "num": 7
              }, 
              {
                "text": "Moreover the word of the LORD came to me, saying:", 
                "num": 8
              }, 
              {
                "text": "\"The hands of Zerubbabel Have laid the foundation of this temple; His hands shall also finish it. Then you will know That the LORD of hosts has sent Me to you.", 
                "num": 9
              }, 
              {
                "text": "For who has despised the day of small things? For these seven rejoice to see The plumb line in the hand of Zerubbabel. They are the eyes of the LORD, Which scan to and fro throughout the whole earth.\"", 
                "num": 10
              }, 
              {
                "text": "Then I answered and said to him, \"What are these two olive trees--at the right of the lampstand and at its left?\"", 
                "num": 11
              }, 
              {
                "text": "And I further answered and said to him, \"What are these two olive branches that drip into the receptacles of the two gold pipes from which the golden oil drains?\"", 
                "num": 12
              }, 
              {
                "text": "Then he answered me and said, \"Do you not know what these are?\" And I said, \"No, my lord.\"", 
                "num": 13
              }, 
              {
                "text": "So he said, \"These are the two anointed ones, who stand beside the Lord of the whole earth.\"", 
                "num": 14
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then I turned and raised my eyes, and saw there a flying scroll.", 
                "num": 1
              }, 
              {
                "text": "And he said to me, \"What do you see?\" So I answered, \"I see a flying scroll. Its length is twenty cubits and its width ten cubits.\"", 
                "num": 2
              }, 
              {
                "text": "Then he said to me, \"This is the curse that goes out over the face of the whole earth: \"Every thief shall be expelled,' according to this side of the scroll; and, \"Every perjurer shall be expelled,' according to that side of it.\"", 
                "num": 3
              }, 
              {
                "text": "\"I will send out the curse,\" says the LORD of hosts; \"It shall enter the house of the thief And the house of the one who swears falsely by My name. It shall remain in the midst of his house And consume it, with its timber and stones.\"", 
                "num": 4
              }, 
              {
                "text": "Then the angel who talked with me came out and said to me, \"Lift your eyes now, and see what this is that goes forth.\"", 
                "num": 5
              }, 
              {
                "text": "So I asked, \"What is it?\" And he said, \"It is a basket that is going forth.\" He also said, \"This is their resemblance throughout the earth:", 
                "num": 6
              }, 
              {
                "text": "Here is a lead disc lifted up, and this is a woman sitting inside the basket\";", 
                "num": 7
              }, 
              {
                "text": "then he said, \"This is Wickedness!\" And he thrust her down into the basket, and threw the lead cover over its mouth.", 
                "num": 8
              }, 
              {
                "text": "Then I raised my eyes and looked, and there were two women, coming with the wind in their wings; for they had wings like the wings of a stork, and they lifted up the basket between earth and heaven.", 
                "num": 9
              }, 
              {
                "text": "So I said to the angel who talked with me, \"Where are they carrying the basket?\"", 
                "num": 10
              }, 
              {
                "text": "And he said to me, \"To build a house for it in the land of Shinar; when it is ready, the basket will be set there on its base.\"", 
                "num": 11
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then I turned and raised my eyes and looked, and behold, four chariots were coming from between two mountains, and the mountains were mountains of bronze.", 
                "num": 1
              }, 
              {
                "text": "With the first chariot were red horses, with the second chariot black horses,", 
                "num": 2
              }, 
              {
                "text": "with the third chariot white horses, and with the fourth chariot dappled horses--strong steeds.", 
                "num": 3
              }, 
              {
                "text": "Then I answered and said to the angel who talked with me, \"What are these, my lord?\"", 
                "num": 4
              }, 
              {
                "text": "And the angel answered and said to me, \"These are four spirits of heaven, who go out from their station before the Lord of all the earth.", 
                "num": 5
              }, 
              {
                "text": "The one with the black horses is going to the north country, the white are going after them, and the dappled are going toward the south country.\"", 
                "num": 6
              }, 
              {
                "text": "Then the strong steeds went out, eager to go, that they might walk to and fro throughout the earth. And He said, \"Go, walk to and fro throughout the earth.\" So they walked to and fro throughout the earth.", 
                "num": 7
              }, 
              {
                "text": "And He called to me, and spoke to me, saying, \"See, those who go toward the north country have given rest to My Spirit in the north country.\"", 
                "num": 8
              }, 
              {
                "text": "Then the word of the LORD came to me, saying:", 
                "num": 9
              }, 
              {
                "text": "\"Receive the gift from the captives--from Heldai, Tobijah, and Jedaiah, who have come from Babylon--and go the same day and enter the house of Josiah the son of Zephaniah.", 
                "num": 10
              }, 
              {
                "text": "Take the silver and gold, make an elaborate crown, and set it on the head of Joshua the son of Jehozadak, the high priest.", 
                "num": 11
              }, 
              {
                "text": "Then speak to him, saying, \"Thus says the LORD of hosts, saying: \"Behold, the Man whose name is the BRANCH! From His place He shall branch out, And He shall build the temple of the LORD;", 
                "num": 12
              }, 
              {
                "text": "Yes, He shall build the temple of the LORD. He shall bear the glory, And shall sit and rule on His throne; So He shall be a priest on His throne, And the counsel of peace shall be between them both.\"'", 
                "num": 13
              }, 
              {
                "text": "\"Now the elaborate crown shall be for a memorial in the temple of the LORD for Helem, Tobijah, Jedaiah, and Hen the son of Zephaniah.", 
                "num": 14
              }, 
              {
                "text": "Even those from afar shall come and build the temple of the LORD. Then you shall know that the LORD of hosts has sent Me to you. And this shall come to pass if you diligently obey the voice of the LORD your God.\"", 
                "num": 15
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now in the fourth year of King Darius it came to pass that the word of the LORD came to Zechariah, on the fourth day of the ninth month, Chislev,", 
                "num": 1
              }, 
              {
                "text": "when the people sent Sherezer, with Regem-Melech and his men, to the house of God, to pray before the LORD,", 
                "num": 2
              }, 
              {
                "text": "and to ask the priests who were in the house of the LORD of hosts, and the prophets, saying, \"Should I weep in the fifth month and fast as I have done for so many years?\"", 
                "num": 3
              }, 
              {
                "text": "Then the word of the LORD of hosts came to me, saying,", 
                "num": 4
              }, 
              {
                "text": "\"Say to all the people of the land, and to the priests: \"When you fasted and mourned in the fifth and seventh months during those seventy years, did you really fast for Me--for Me?", 
                "num": 5
              }, 
              {
                "text": "When you eat and when you drink, do you not eat and drink for yourselves?", 
                "num": 6
              }, 
              {
                "text": "Should you not have obeyed the words which the LORD proclaimed through the former prophets when Jerusalem and the cities around it were inhabited and prosperous, and the South and the Lowland were inhabited?\"'", 
                "num": 7
              }, 
              {
                "text": "Then the word of the LORD came to Zechariah, saying,", 
                "num": 8
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Execute true justice, Show mercy and compassion Everyone to his brother.", 
                "num": 9
              }, 
              {
                "text": "Do not oppress the widow or the fatherless, The alien or the poor. Let none of you plan evil in his heart Against his brother.'", 
                "num": 10
              }, 
              {
                "text": "But they refused to heed, shrugged their shoulders, and stopped their ears so that they could not hear.", 
                "num": 11
              }, 
              {
                "text": "Yes, they made their hearts like flint, refusing to hear the law and the words which the LORD of hosts had sent by His Spirit through the former prophets. Thus great wrath came from the LORD of hosts.", 
                "num": 12
              }, 
              {
                "text": "Therefore it happened, that just as He proclaimed and they would not hear, so they called out and I would not listen,\" says the LORD of hosts.", 
                "num": 13
              }, 
              {
                "text": "\"But I scattered them with a whirlwind among all the nations which they had not known. Thus the land became desolate after them, so that no one passed through or returned; for they made the pleasant land desolate.\"", 
                "num": 14
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Again the word of the LORD of hosts came, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"I am zealous for Zion with great zeal; With great fervor I am zealous for her.'", 
                "num": 2
              }, 
              {
                "text": "\"Thus says the LORD: \"I will return to Zion, And dwell in the midst of Jerusalem. Jerusalem shall be called the City of Truth, The Mountain of the LORD of hosts, The Holy Mountain.'", 
                "num": 3
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Old men and old women shall again sit In the streets of Jerusalem, Each one with his staff in his hand Because of great age.", 
                "num": 4
              }, 
              {
                "text": "The streets of the city Shall be full of boys and girls Playing in its streets.'", 
                "num": 5
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"If it is marvelous in the eyes of the remnant of this people in these days, Will it also be marvelous in My eyes?' Says the LORD of hosts.", 
                "num": 6
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Behold, I will save My people from the land of the east And from the land of the west;", 
                "num": 7
              }, 
              {
                "text": "I will bring them back, And they shall dwell in the midst of Jerusalem. They shall be My people And I will be their God, In truth and righteousness.'", 
                "num": 8
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Let your hands be strong, You who have been hearing in these days These words by the mouth of the prophets, Who spoke in the day the foundation was laid For the house of the LORD of hosts, That the temple might be built.", 
                "num": 9
              }, 
              {
                "text": "For before these days There were no wages for man nor any hire for beast; There was no peace from the enemy for whoever went out or came in; For I set all men, everyone, against his neighbor.", 
                "num": 10
              }, 
              {
                "text": "But now I will not treat the remnant of this people as in the former days,' says the LORD of hosts.", 
                "num": 11
              }, 
              {
                "text": "\"For the seed shall be prosperous, The vine shall give its fruit, The ground shall give her increase, And the heavens shall give their dew-- I will cause the remnant of this people To possess all these.", 
                "num": 12
              }, 
              {
                "text": "And it shall come to pass That just as you were a curse among the nations, O house of Judah and house of Israel, So I will save you, and you shall be a blessing. Do not fear, Let your hands be strong.'", 
                "num": 13
              }, 
              {
                "text": "\"For thus says the LORD of hosts: \"Just as I determined to punish you When your fathers provoked Me to wrath,' Says the LORD of hosts, \"And I would not relent,", 
                "num": 14
              }, 
              {
                "text": "So again in these days I am determined to do good To Jerusalem and to the house of Judah. Do not fear.", 
                "num": 15
              }, 
              {
                "text": "These are the things you shall do: Speak each man the truth to his neighbor; Give judgment in your gates for truth, justice, and peace;", 
                "num": 16
              }, 
              {
                "text": "Let none of you think evil in your heart against your neighbor; And do not love a false oath. For all these are things that I hate,' Says the LORD.\"", 
                "num": 17
              }, 
              {
                "text": "Then the word of the LORD of hosts came to me, saying,", 
                "num": 18
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"The fast of the fourth month, The fast of the fifth, The fast of the seventh, And the fast of the tenth, Shall be joy and gladness and cheerful feasts For the house of Judah. Therefore love truth and peace.'", 
                "num": 19
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"Peoples shall yet come, Inhabitants of many cities;", 
                "num": 20
              }, 
              {
                "text": "The inhabitants of one city shall go to another, saying, \"Let us continue to go and pray before the LORD, And seek the LORD of hosts. I myself will go also.\"", 
                "num": 21
              }, 
              {
                "text": "Yes, many peoples and strong nations Shall come to seek the LORD of hosts in Jerusalem, And to pray before the LORD.'", 
                "num": 22
              }, 
              {
                "text": "\"Thus says the LORD of hosts: \"In those days ten men from every language of the nations shall grasp the sleeve of a Jewish man, saying, \"Let us go with you, for we have heard that God is with you.\"\"'", 
                "num": 23
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "The burden of the word of the LORD Against the land of Hadrach, And Damascus its resting place (For the eyes of men And all the tribes of Israel Are on the LORD);", 
                "num": 1
              }, 
              {
                "text": "Also against Hamath, which borders on it, And against Tyre and Sidon, though they are very wise.", 
                "num": 2
              }, 
              {
                "text": "For Tyre built herself a tower, Heaped up silver like the dust, And gold like the mire of the streets.", 
                "num": 3
              }, 
              {
                "text": "Behold, the LORD will cast her out; He will destroy her power in the sea, And she will be devoured by fire.", 
                "num": 4
              }, 
              {
                "text": "Ashkelon shall see it and fear; Gaza also shall be very sorrowful; And Ekron, for He dried up her expectation. The king shall perish from Gaza, And Ashkelon shall not be inhabited.", 
                "num": 5
              }, 
              {
                "text": "\"A mixed race shall settle in Ashdod, And I will cut off the pride of the Philistines.", 
                "num": 6
              }, 
              {
                "text": "I will take away the blood from his mouth, And the abominations from between his teeth. But he who remains, even he shall be for our God, And shall be like a leader in Judah, And Ekron like a Jebusite.", 
                "num": 7
              }, 
              {
                "text": "I will camp around My house Because of the army, Because of him who passes by and him who returns. No more shall an oppressor pass through them, For now I have seen with My eyes.", 
                "num": 8
              }, 
              {
                "text": "\"Rejoice greatly, O daughter of Zion! Shout, O daughter of Jerusalem! Behold, your King is coming to you; He is just and having salvation, Lowly and riding on a donkey, A colt, the foal of a donkey.", 
                "num": 9
              }, 
              {
                "text": "I will cut off the chariot from Ephraim And the horse from Jerusalem; The battle bow shall be cut off. He shall speak peace to the nations; His dominion shall be \"from sea to sea, And from the River to the ends of the earth.'", 
                "num": 10
              }, 
              {
                "text": "\"As for you also, Because of the blood of your covenant, I will set your prisoners free from the waterless pit.", 
                "num": 11
              }, 
              {
                "text": "Return to the stronghold, You prisoners of hope. Even today I declare That I will restore double to you.", 
                "num": 12
              }, 
              {
                "text": "For I have bent Judah, My bow, Fitted the bow with Ephraim, And raised up your sons, O Zion, Against your sons, O Greece, And made you like the sword of a mighty man.\"", 
                "num": 13
              }, 
              {
                "text": "Then the LORD will be seen over them, And His arrow will go forth like lightning. The Lord GOD will blow the trumpet, And go with whirlwinds from the south.", 
                "num": 14
              }, 
              {
                "text": "The LORD of hosts will defend them; They shall devour and subdue with slingstones. They shall drink and roar as if with wine; They shall be filled with blood like basins, Like the corners of the altar.", 
                "num": 15
              }, 
              {
                "text": "The LORD their God will save them in that day, As the flock of His people. For they shall be like the jewels of a crown, Lifted like a banner over His land--", 
                "num": 16
              }, 
              {
                "text": "For how great is its goodness And how great its beauty! Grain shall make the young men thrive, And new wine the young women.", 
                "num": 17
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Ask the LORD for rain In the time of the latter rain. The LORD will make flashing clouds; He will give them showers of rain, Grass in the field for everyone.", 
                "num": 1
              }, 
              {
                "text": "For the idols speak delusion; The diviners envision lies, And tell false dreams; They comfort in vain. Therefore the people wend their way like sheep; They are in trouble because there is no shepherd.", 
                "num": 2
              }, 
              {
                "text": "\"My anger is kindled against the shepherds, And I will punish the goatherds. For the LORD of hosts will visit His flock, The house of Judah, And will make them as His royal horse in the battle.", 
                "num": 3
              }, 
              {
                "text": "From him comes the cornerstone, From him the tent peg, From him the battle bow, From him every ruler together.", 
                "num": 4
              }, 
              {
                "text": "They shall be like mighty men, Who tread down their enemies In the mire of the streets in the battle. They shall fight because the LORD is with them, And the riders on horses shall be put to shame.", 
                "num": 5
              }, 
              {
                "text": "\"I will strengthen the house of Judah, And I will save the house of Joseph. I will bring them back, Because I have mercy on them. They shall be as though I had not cast them aside; For I am the LORD their God, And I will hear them.", 
                "num": 6
              }, 
              {
                "text": "Those of Ephraim shall be like a mighty man, And their heart shall rejoice as if with wine. Yes, their children shall see it and be glad; Their heart shall rejoice in the LORD.", 
                "num": 7
              }, 
              {
                "text": "I will whistle for them and gather them, For I will redeem them; And they shall increase as they once increased.", 
                "num": 8
              }, 
              {
                "text": "\"I will sow them among the peoples, And they shall remember Me in far countries; They shall live, together with their children, And they shall return.", 
                "num": 9
              }, 
              {
                "text": "I will also bring them back from the land of Egypt, And gather them from Assyria. I will bring them into the land of Gilead and Lebanon, Until no more room is found for them.", 
                "num": 10
              }, 
              {
                "text": "He shall pass through the sea with affliction, And strike the waves of the sea: All the depths of the River shall dry up. Then the pride of Assyria shall be brought down, And the scepter of Egypt shall depart.", 
                "num": 11
              }, 
              {
                "text": "\"So I will strengthen them in the LORD, And they shall walk up and down in His name,\" Says the LORD.", 
                "num": 12
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Open your doors, O Lebanon, That fire may devour your cedars.", 
                "num": 1
              }, 
              {
                "text": "Wail, O cypress, for the cedar has fallen, Because the mighty trees are ruined. Wail, O oaks of Bashan, For the thick forest has come down.", 
                "num": 2
              }, 
              {
                "text": "There is the sound of wailing shepherds! For their glory is in ruins. There is the sound of roaring lions! For the pride of the Jordan is in ruins.", 
                "num": 3
              }, 
              {
                "text": "Thus says the LORD my God, \"Feed the flock for slaughter,", 
                "num": 4
              }, 
              {
                "text": "whose owners slaughter them and feel no guilt; those who sell them say, \"Blessed be the LORD, for I am rich'; and their shepherds do not pity them.", 
                "num": 5
              }, 
              {
                "text": "For I will no longer pity the inhabitants of the land,\" says the LORD. \"But indeed I will give everyone into his neighbor's hand and into the hand of his king. They shall attack the land, and I will not deliver them from their hand.\"", 
                "num": 6
              }, 
              {
                "text": "So I fed the flock for slaughter, in particular the poor of the flock. I took for myself two staffs: the one I called Beauty, and the other I called Bonds; and I fed the flock.", 
                "num": 7
              }, 
              {
                "text": "I dismissed the three shepherds in one month. My soul loathed them, and their soul also abhorred me.", 
                "num": 8
              }, 
              {
                "text": "Then I said, \"I will not feed you. Let what is dying die, and what is perishing perish. Let those that are left eat each other's flesh.\"", 
                "num": 9
              }, 
              {
                "text": "And I took my staff, Beauty, and cut it in two, that I might break the covenant which I had made with all the peoples.", 
                "num": 10
              }, 
              {
                "text": "So it was broken on that day. Thus the poor of the flock, who were watching me, knew that it was the word of the LORD.", 
                "num": 11
              }, 
              {
                "text": "Then I said to them, \"If it is agreeable to you, give me my wages; and if not, refrain.\" So they weighed out for my wages thirty pieces of silver.", 
                "num": 12
              }, 
              {
                "text": "And the LORD said to me, \"Throw it to the potter\"--that princely price they set on me. So I took the thirty pieces of silver and threw them into the house of the LORD for the potter.", 
                "num": 13
              }, 
              {
                "text": "Then I cut in two my other staff, Bonds, that I might break the brotherhood between Judah and Israel.", 
                "num": 14
              }, 
              {
                "text": "And the LORD said to me, \"Next, take for yourself the implements of a foolish shepherd.", 
                "num": 15
              }, 
              {
                "text": "For indeed I will raise up a shepherd in the land who will not care for those who are cut off, nor seek the young, nor heal those that are broken, nor feed those that still stand. But he will eat the flesh of the fat and tear their hooves in pieces.", 
                "num": 16
              }, 
              {
                "text": "\"Woe to the worthless shepherd, Who leaves the flock! A sword shall be against his arm And against his right eye; His arm shall completely wither, And his right eye shall be totally blinded.\"", 
                "num": 17
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "The burden of the word of the LORD against Israel. Thus says the LORD, who stretches out the heavens, lays the foundation of the earth, and forms the spirit of man within him:", 
                "num": 1
              }, 
              {
                "text": "\"Behold, I will make Jerusalem a cup of drunkenness to all the surrounding peoples, when they lay siege against Judah and Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "And it shall happen in that day that I will make Jerusalem a very heavy stone for all peoples; all who would heave it away will surely be cut in pieces, though all nations of the earth are gathered against it.", 
                "num": 3
              }, 
              {
                "text": "In that day,\" says the LORD, \"I will strike every horse with confusion, and its rider with madness; I will open My eyes on the house of Judah, and will strike every horse of the peoples with blindness.", 
                "num": 4
              }, 
              {
                "text": "And the governors of Judah shall say in their heart, \"The inhabitants of Jerusalem are my strength in the LORD of hosts, their God.'", 
                "num": 5
              }, 
              {
                "text": "In that day I will make the governors of Judah like a firepan in the woodpile, and like a fiery torch in the sheaves; they shall devour all the surrounding peoples on the right hand and on the left, but Jerusalem shall be inhabited again in her own place--Jerusalem.", 
                "num": 6
              }, 
              {
                "text": "\"The LORD will save the tents of Judah first, so that the glory of the house of David and the glory of the inhabitants of Jerusalem shall not become greater than that of Judah.", 
                "num": 7
              }, 
              {
                "text": "In that day the LORD will defend the inhabitants of Jerusalem; the one who is feeble among them in that day shall be like David, and the house of David shall be like God, like the Angel of the LORD before them.", 
                "num": 8
              }, 
              {
                "text": "It shall be in that day that I will seek to destroy all the nations that come against Jerusalem.", 
                "num": 9
              }, 
              {
                "text": "\"And I will pour on the house of David and on the inhabitants of Jerusalem the Spirit of grace and supplication; then they will look on Me whom they pierced. Yes, they will mourn for Him as one mourns for his only son, and grieve for Him as one grieves for a firstborn.", 
                "num": 10
              }, 
              {
                "text": "In that day there shall be a great mourning in Jerusalem, like the mourning at Hadad Rimmon in the plain of Megiddo.", 
                "num": 11
              }, 
              {
                "text": "And the land shall mourn, every family by itself: the family of the house of David by itself, and their wives by themselves; the family of the house of Nathan by itself, and their wives by themselves;", 
                "num": 12
              }, 
              {
                "text": "the family of the house of Levi by itself, and their wives by themselves; the family of Shimei by itself, and their wives by themselves;", 
                "num": 13
              }, 
              {
                "text": "all the families that remain, every family by itself, and their wives by themselves.", 
                "num": 14
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "\"In that day a fountain shall be opened for the house of David and for the inhabitants of Jerusalem, for sin and for uncleanness.", 
                "num": 1
              }, 
              {
                "text": "\"It shall be in that day,\" says the LORD of hosts, \"that I will cut off the names of the idols from the land, and they shall no longer be remembered. I will also cause the prophets and the unclean spirit to depart from the land.", 
                "num": 2
              }, 
              {
                "text": "It shall come to pass that if anyone still prophesies, then his father and mother who begot him will say to him, \"You shall not live, because you have spoken lies in the name of the LORD.' And his father and mother who begot him shall thrust him through when he prophesies.", 
                "num": 3
              }, 
              {
                "text": "\"And it shall be in that day that every prophet will be ashamed of his vision when he prophesies; they will not wear a robe of coarse hair to deceive.", 
                "num": 4
              }, 
              {
                "text": "But he will say, \"I am no prophet, I am a farmer; for a man taught me to keep cattle from my youth.'", 
                "num": 5
              }, 
              {
                "text": "And one will say to him, \"What are these wounds between your arms?' Then he will answer, \"Those with which I was wounded in the house of my friends.'", 
                "num": 6
              }, 
              {
                "text": "\"Awake, O sword, against My Shepherd, Against the Man who is My Companion,\" Says the LORD of hosts. \"Strike the Shepherd, And the sheep will be scattered; Then I will turn My hand against the little ones.", 
                "num": 7
              }, 
              {
                "text": "And it shall come to pass in all the land,\" Says the LORD, \"That two-thirds in it shall be cut off and die, But one-third shall be left in it:", 
                "num": 8
              }, 
              {
                "text": "I will bring the one-third through the fire, Will refine them as silver is refined, And test them as gold is tested. They will call on My name, And I will answer them. I will say, \"This is My people'; And each one will say, \"The LORD is my God.\"'", 
                "num": 9
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Behold, the day of the LORD is coming, And your spoil will be divided in your midst.", 
                "num": 1
              }, 
              {
                "text": "For I will gather all the nations to battle against Jerusalem; The city shall be taken, The houses rifled, And the women ravished. Half of the city shall go into captivity, But the remnant of the people shall not be cut off from the city.", 
                "num": 2
              }, 
              {
                "text": "Then the LORD will go forth And fight against those nations, As He fights in the day of battle.", 
                "num": 3
              }, 
              {
                "text": "And in that day His feet will stand on the Mount of Olives, Which faces Jerusalem on the east. And the Mount of Olives shall be split in two, From east to west, Making a very large valley; Half of the mountain shall move toward the north And half of it toward the south.", 
                "num": 4
              }, 
              {
                "text": "Then you shall flee through My mountain valley, For the mountain valley shall reach to Azal. Yes, you shall flee As you fled from the earthquake In the days of Uzziah king of Judah. Thus the LORD my God will come, And all the saints with You.", 
                "num": 5
              }, 
              {
                "text": "It shall come to pass in that day That there will be no light; The lights will diminish.", 
                "num": 6
              }, 
              {
                "text": "It shall be one day Which is known to the LORD-- Neither day nor night. But at evening time it shall happen That it will be light.", 
                "num": 7
              }, 
              {
                "text": "And in that day it shall be That living waters shall flow from Jerusalem, Half of them toward the eastern sea And half of them toward the western sea; In both summer and winter it shall occur.", 
                "num": 8
              }, 
              {
                "text": "And the LORD shall be King over all the earth. In that day it shall be-- \"The LORD is one,\" And His name one.", 
                "num": 9
              }, 
              {
                "text": "All the land shall be turned into a plain from Geba to Rimmon south of Jerusalem. Jerusalem shall be raised up and inhabited in her place from Benjamin's Gate to the place of the First Gate and the Corner Gate, and from the Tower of Hananel to the king's winepresses.", 
                "num": 10
              }, 
              {
                "text": "The people shall dwell in it; And no longer shall there be utter destruction, But Jerusalem shall be safely inhabited.", 
                "num": 11
              }, 
              {
                "text": "And this shall be the plague with which the LORD will strike all the people who fought against Jerusalem: Their flesh shall dissolve while they stand on their feet, Their eyes shall dissolve in their sockets, And their tongues shall dissolve in their mouths.", 
                "num": 12
              }, 
              {
                "text": "It shall come to pass in that day That a great panic from the LORD will be among them. Everyone will seize the hand of his neighbor, And raise his hand against his neighbor's hand;", 
                "num": 13
              }, 
              {
                "text": "Judah also will fight at Jerusalem. And the wealth of all the surrounding nations Shall be gathered together: Gold, silver, and apparel in great abundance.", 
                "num": 14
              }, 
              {
                "text": "Such also shall be the plague On the horse and the mule, On the camel and the donkey, And on all the cattle that will be in those camps. So shall this plague be.", 
                "num": 15
              }, 
              {
                "text": "And it shall come to pass that everyone who is left of all the nations which came against Jerusalem shall go up from year to year to worship the King, the LORD of hosts, and to keep the Feast of Tabernacles.", 
                "num": 16
              }, 
              {
                "text": "And it shall be that whichever of the families of the earth do not come up to Jerusalem to worship the King, the LORD of hosts, on them there will be no rain.", 
                "num": 17
              }, 
              {
                "text": "If the family of Egypt will not come up and enter in, they shall have no rain; they shall receive the plague with which the LORD strikes the nations who do not come up to keep the Feast of Tabernacles.", 
                "num": 18
              }, 
              {
                "text": "This shall be the punishment of Egypt and the punishment of all the nations that do not come up to keep the Feast of Tabernacles.", 
                "num": 19
              }, 
              {
                "text": "In that day \"HOLINESS TO THE LORD\" shall be engraved on the bells of the horses. The pots in the LORD's house shall be like the bowls before the altar.", 
                "num": 20
              }, 
              {
                "text": "Yes, every pot in Jerusalem and Judah shall be holiness to the LORD of hosts. Everyone who sacrifices shall come and take them and cook in them. In that day there shall no longer be a Canaanite in the house of the LORD of hosts.", 
                "num": 21
              }
            ], 
            "num": 14
          }
        ]
      }, 
      {
        "name": "Malachi", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The burden of the word of the LORD to Israel by Malachi.", 
                "num": 1
              }, 
              {
                "text": "\"I have loved you,\" says the LORD. \"Yet you say, \"In what way have You loved us?' Was not Esau Jacob's brother?\" Says the LORD. \"Yet Jacob I have loved;", 
                "num": 2
              }, 
              {
                "text": "But Esau I have hated, And laid waste his mountains and his heritage For the jackals of the wilderness.\"", 
                "num": 3
              }, 
              {
                "text": "Even though Edom has said, \"We have been impoverished, But we will return and build the desolate places,\" Thus says the LORD of hosts: \"They may build, but I will throw down; They shall be called the Territory of Wickedness, And the people against whom the LORD will have indignation forever.", 
                "num": 4
              }, 
              {
                "text": "Your eyes shall see, And you shall say, \"The LORD is magnified beyond the border of Israel.'", 
                "num": 5
              }, 
              {
                "text": "\"A son honors his father, And a servant his master. If then I am the Father, Where is My honor? And if I am a Master, Where is My reverence? Says the LORD of hosts To you priests who despise My name. Yet you say, \"In what way have we despised Your name?'", 
                "num": 6
              }, 
              {
                "text": "\"You offer defiled food on My altar, But say, \"In what way have we defiled You?' By saying, \"The table of the LORD is contemptible.'", 
                "num": 7
              }, 
              {
                "text": "And when you offer the blind as a sacrifice, Is it not evil? And when you offer the lame and sick, Is it not evil? Offer it then to your governor! Would he be pleased with you? Would he accept you favorably?\" Says the LORD of hosts.", 
                "num": 8
              }, 
              {
                "text": "\"But now entreat God's favor, That He may be gracious to us. While this is being done by your hands, Will He accept you favorably?\" Says the LORD of hosts.", 
                "num": 9
              }, 
              {
                "text": "\"Who is there even among you who would shut the doors, So that you would not kindle fire on My altar in vain? I have no pleasure in you,\" Says the LORD of hosts, \"Nor will I accept an offering from your hands.", 
                "num": 10
              }, 
              {
                "text": "For from the rising of the sun, even to its going down, My name shall be great among the Gentiles; In every place incense shall be offered to My name, And a pure offering; For My name shall be great among the nations,\" Says the LORD of hosts.", 
                "num": 11
              }, 
              {
                "text": "\"But you profane it, In that you say, \"The table of the LORD is defiled; And its fruit, its food, is contemptible.'", 
                "num": 12
              }, 
              {
                "text": "You also say, \"Oh, what a weariness!' And you sneer at it,\" Says the LORD of hosts. \"And you bring the stolen, the lame, and the sick; Thus you bring an offering! Should I accept this from your hand?\" Says the LORD.", 
                "num": 13
              }, 
              {
                "text": "\"But cursed be the deceiver Who has in his flock a male, And takes a vow, But sacrifices to the Lord what is blemished-- For I am a great King,\" Says the LORD of hosts, \"And My name is to be feared among the nations.", 
                "num": 14
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "\"And now, O priests, this commandment is for you.", 
                "num": 1
              }, 
              {
                "text": "If you will not hear, And if you will not take it to heart, To give glory to My name,\" Says the LORD of hosts, \"I will send a curse upon you, And I will curse your blessings. Yes, I have cursed them already, Because you do not take it to heart.", 
                "num": 2
              }, 
              {
                "text": "\"Behold, I will rebuke your descendants And spread refuse on your faces, The refuse of your solemn feasts; And one will take you away with it.", 
                "num": 3
              }, 
              {
                "text": "Then you shall know that I have sent this commandment to you, That My covenant with Levi may continue,\" Says the LORD of hosts.", 
                "num": 4
              }, 
              {
                "text": "\"My covenant was with him, one of life and peace, And I gave them to him that he might fear Me; So he feared Me And was reverent before My name.", 
                "num": 5
              }, 
              {
                "text": "The law of truth was in his mouth, And injustice was not found on his lips. He walked with Me in peace and equity, And turned many away from iniquity.", 
                "num": 6
              }, 
              {
                "text": "\"For the lips of a priest should keep knowledge, And people should seek the law from his mouth; For he is the messenger of the LORD of hosts.", 
                "num": 7
              }, 
              {
                "text": "But you have departed from the way; You have caused many to stumble at the law. You have corrupted the covenant of Levi,\" Says the LORD of hosts.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore I also have made you contemptible and base Before all the people, Because you have not kept My ways But have shown partiality in the law.\"", 
                "num": 9
              }, 
              {
                "text": "Have we not all one Father? Has not one God created us? Why do we deal treacherously with one another By profaning the covenant of the fathers?", 
                "num": 10
              }, 
              {
                "text": "Judah has dealt treacherously, And an abomination has been committed in Israel and in Jerusalem, For Judah has profaned The LORD's holy institution which He loves: He has married the daughter of a foreign god.", 
                "num": 11
              }, 
              {
                "text": "May the LORD cut off from the tents of Jacob The man who does this, being awake and aware, Yet who brings an offering to the LORD of hosts!", 
                "num": 12
              }, 
              {
                "text": "And this is the second thing you do: You cover the altar of the LORD with tears, With weeping and crying; So He does not regard the offering anymore, Nor receive it with goodwill from your hands.", 
                "num": 13
              }, 
              {
                "text": "Yet you say, \"For what reason?\" Because the LORD has been witness Between you and the wife of your youth, With whom you have dealt treacherously; Yet she is your companion And your wife by covenant.", 
                "num": 14
              }, 
              {
                "text": "But did He not make them one, Having a remnant of the Spirit? And why one? He seeks godly offspring. Therefore take heed to your spirit, And let none deal treacherously with the wife of his youth.", 
                "num": 15
              }, 
              {
                "text": "\"For the LORD God of Israel says That He hates divorce, For it covers one's garment with violence,\" Says the LORD of hosts. \"Therefore take heed to your spirit, That you do not deal treacherously.\"", 
                "num": 16
              }, 
              {
                "text": "You have wearied the LORD with your words; Yet you say, \"In what way have we wearied Him?\" In that you say, \"Everyone who does evil Is good in the sight of the LORD, And He delights in them,\" Or, \"Where is the God of justice?\"", 
                "num": 17
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"Behold, I send My messenger, And he will prepare the way before Me. And the Lord, whom you seek, Will suddenly come to His temple, Even the Messenger of the covenant, In whom you delight. Behold, He is coming,\" Says the LORD of hosts.", 
                "num": 1
              }, 
              {
                "text": "\"But who can endure the day of His coming? And who can stand when He appears? For He is like a refiner's fire And like launderers' soap.", 
                "num": 2
              }, 
              {
                "text": "He will sit as a refiner and a purifier of silver; He will purify the sons of Levi, And purge them as gold and silver, That they may offer to the LORD An offering in righteousness.", 
                "num": 3
              }, 
              {
                "text": "\"Then the offering of Judah and Jerusalem Will be pleasant to the LORD, As in the days of old, As in former years.", 
                "num": 4
              }, 
              {
                "text": "And I will come near you for judgment; I will be a swift witness Against sorcerers, Against adulterers, Against perjurers, Against those who exploit wage earners and widows and orphans, And against those who turn away an alien-- Because they do not fear Me,\" Says the LORD of hosts.", 
                "num": 5
              }, 
              {
                "text": "\"For I am the LORD, I do not change; Therefore you are not consumed, O sons of Jacob.", 
                "num": 6
              }, 
              {
                "text": "Yet from the days of your fathers You have gone away from My ordinances And have not kept them. Return to Me, and I will return to you,\" Says the LORD of hosts. \"But you said, \"In what way shall we return?'", 
                "num": 7
              }, 
              {
                "text": "\"Will a man rob God? Yet you have robbed Me! But you say, \"In what way have we robbed You?' In tithes and offerings.", 
                "num": 8
              }, 
              {
                "text": "You are cursed with a curse, For you have robbed Me, Even this whole nation.", 
                "num": 9
              }, 
              {
                "text": "Bring all the tithes into the storehouse, That there may be food in My house, And try Me now in this,\" Says the LORD of hosts, \"If I will not open for you the windows of heaven And pour out for you such blessing That there will not be room enough to receive it.", 
                "num": 10
              }, 
              {
                "text": "\"And I will rebuke the devourer for your sakes, So that he will not destroy the fruit of your ground, Nor shall the vine fail to bear fruit for you in the field,\" Says the LORD of hosts;", 
                "num": 11
              }, 
              {
                "text": "And all nations will call you blessed, For you will be a delightful land,\" Says the LORD of hosts.", 
                "num": 12
              }, 
              {
                "text": "\"Your words have been harsh against Me,\" Says the LORD, \"Yet you say, \"What have we spoken against You?'", 
                "num": 13
              }, 
              {
                "text": "You have said, \"It is useless to serve God; What profit is it that we have kept His ordinance, And that we have walked as mourners Before the LORD of hosts?", 
                "num": 14
              }, 
              {
                "text": "So now we call the proud blessed, For those who do wickedness are raised up; They even tempt God and go free.\"'", 
                "num": 15
              }, 
              {
                "text": "Then those who feared the LORD spoke to one another, And the LORD listened and heard them; So a book of remembrance was written before Him For those who fear the LORD And who meditate on His name.", 
                "num": 16
              }, 
              {
                "text": "\"They shall be Mine,\" says the LORD of hosts, \"On the day that I make them My jewels. And I will spare them As a man spares his own son who serves him.\"", 
                "num": 17
              }, 
              {
                "text": "Then you shall again discern Between the righteous and the wicked, Between one who serves God And one who does not serve Him.", 
                "num": 18
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "\"For behold, the day is coming, Burning like an oven, And all the proud, yes, all who do wickedly will be stubble. And the day which is coming shall burn them up,\" Says the LORD of hosts, \"That will leave them neither root nor branch.", 
                "num": 1
              }, 
              {
                "text": "But to you who fear My name The Sun of Righteousness shall arise With healing in His wings; And you shall go out And grow fat like stall-fed calves.", 
                "num": 2
              }, 
              {
                "text": "You shall trample the wicked, For they shall be ashes under the soles of your feet On the day that I do this,\" Says the LORD of hosts.", 
                "num": 3
              }, 
              {
                "text": "\"Remember the Law of Moses, My servant, Which I commanded him in Horeb for all Israel, With the statutes and judgments.", 
                "num": 4
              }, 
              {
                "text": "Behold, I will send you Elijah the prophet Before the coming of the great and dreadful day of the LORD.", 
                "num": 5
              }, 
              {
                "text": "And he will turn The hearts of the fathers to the children, And the hearts of the children to their fathers, Lest I come and strike the earth with a curse.\"", 
                "num": 6
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "Matthew", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The book of the genealogy of Jesus Christ, the Son of David, the Son of Abraham:", 
                "num": 1
              }, 
              {
                "text": "Abraham begot Isaac, Isaac begot Jacob, and Jacob begot Judah and his brothers.", 
                "num": 2
              }, 
              {
                "text": "Judah begot Perez and Zerah by Tamar, Perez begot Hezron, and Hezron begot Ram.", 
                "num": 3
              }, 
              {
                "text": "Ram begot Amminadab, Amminadab begot Nahshon, and Nahshon begot Salmon.", 
                "num": 4
              }, 
              {
                "text": "Salmon begot Boaz by Rahab, Boaz begot Obed by Ruth, Obed begot Jesse,", 
                "num": 5
              }, 
              {
                "text": "and Jesse begot David the king. David the king begot Solomon by her who had been the wife of Uriah.", 
                "num": 6
              }, 
              {
                "text": "Solomon begot Rehoboam, Rehoboam begot Abijah, and Abijah begot Asa.", 
                "num": 7
              }, 
              {
                "text": "Asa begot Jehoshaphat, Jehoshaphat begot Joram, and Joram begot Uzziah.", 
                "num": 8
              }, 
              {
                "text": "Uzziah begot Jotham, Jotham begot Ahaz, and Ahaz begot Hezekiah.", 
                "num": 9
              }, 
              {
                "text": "Hezekiah begot Manasseh, Manasseh begot Amon, and Amon begot Josiah.", 
                "num": 10
              }, 
              {
                "text": "Josiah begot Jeconiah and his brothers about the time they were carried away to Babylon.", 
                "num": 11
              }, 
              {
                "text": "And after they were brought to Babylon, Jeconiah begot Shealtiel, and Shealtiel begot Zerubbabel.", 
                "num": 12
              }, 
              {
                "text": "Zerubbabel begot Abiud, Abiud begot Eliakim, and Eliakim begot Azor.", 
                "num": 13
              }, 
              {
                "text": "Azor begot Zadok, Zadok begot Achim, and Achim begot Eliud.", 
                "num": 14
              }, 
              {
                "text": "Eliud begot Eleazar, Eleazar begot Matthan, and Matthan begot Jacob.", 
                "num": 15
              }, 
              {
                "text": "And Jacob begot Joseph the husband of Mary, of whom was born Jesus who is called Christ.", 
                "num": 16
              }, 
              {
                "text": "So all the generations from Abraham to David are fourteen generations, from David until the captivity in Babylon are fourteen generations, and from the captivity in Babylon until the Christ are fourteen generations.", 
                "num": 17
              }, 
              {
                "text": "Now the birth of Jesus Christ was as follows: After His mother Mary was betrothed to Joseph, before they came together, she was found with child of the Holy Spirit.", 
                "num": 18
              }, 
              {
                "text": "Then Joseph her husband, being a just man, and not wanting to make her a public example, was minded to put her away secretly.", 
                "num": 19
              }, 
              {
                "text": "But while he thought about these things, behold, an angel of the Lord appeared to him in a dream, saying, \"Joseph, son of David, do not be afraid to take to you Mary your wife, for that which is conceived in her is of the Holy Spirit.", 
                "num": 20
              }, 
              {
                "text": "And she will bring forth a Son, and you shall call His name JESUS, for He will save His people from their sins.\"", 
                "num": 21
              }, 
              {
                "text": "So all this was done that it might be fulfilled which was spoken by the Lord through the prophet, saying:", 
                "num": 22
              }, 
              {
                "text": "\"Behold, the virgin shall be with child, and bear a Son, and they shall call His name Immanuel,\" which is translated, \"God with us.\"", 
                "num": 23
              }, 
              {
                "text": "Then Joseph, being aroused from sleep, did as the angel of the Lord commanded him and took to him his wife,", 
                "num": 24
              }, 
              {
                "text": "and did not know her till she had brought forth her firstborn Son. And he called His name JESUS.", 
                "num": 25
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now after Jesus was born in Bethlehem of Judea in the days of Herod the king, behold, wise men from the East came to Jerusalem,", 
                "num": 1
              }, 
              {
                "text": "saying, \"Where is He who has been born King of the Jews? For we have seen His star in the East and have come to worship Him.\"", 
                "num": 2
              }, 
              {
                "text": "When Herod the king heard this, he was troubled, and all Jerusalem with him.", 
                "num": 3
              }, 
              {
                "text": "And when he had gathered all the chief priests and scribes of the people together, he inquired of them where the Christ was to be born.", 
                "num": 4
              }, 
              {
                "text": "So they said to him, \"In Bethlehem of Judea, for thus it is written by the prophet:", 
                "num": 5
              }, 
              {
                "text": "\"But you, Bethlehem, in the land of Judah, Are not the least among the rulers of Judah; For out of you shall come a Ruler Who will shepherd My people Israel.\"'", 
                "num": 6
              }, 
              {
                "text": "Then Herod, when he had secretly called the wise men, determined from them what time the star appeared.", 
                "num": 7
              }, 
              {
                "text": "And he sent them to Bethlehem and said, \"Go and search carefully for the young Child, and when you have found Him, bring back word to me, that I may come and worship Him also.\"", 
                "num": 8
              }, 
              {
                "text": "When they heard the king, they departed; and behold, the star which they had seen in the East went before them, till it came and stood over where the young Child was.", 
                "num": 9
              }, 
              {
                "text": "When they saw the star, they rejoiced with exceedingly great joy.", 
                "num": 10
              }, 
              {
                "text": "And when they had come into the house, they saw the young Child with Mary His mother, and fell down and worshiped Him. And when they had opened their treasures, they presented gifts to Him: gold, frankincense, and myrrh.", 
                "num": 11
              }, 
              {
                "text": "Then, being divinely warned in a dream that they should not return to Herod, they departed for their own country another way.", 
                "num": 12
              }, 
              {
                "text": "Now when they had departed, behold, an angel of the Lord appeared to Joseph in a dream, saying, \"Arise, take the young Child and His mother, flee to Egypt, and stay there until I bring you word; for Herod will seek the young Child to destroy Him.\"", 
                "num": 13
              }, 
              {
                "text": "When he arose, he took the young Child and His mother by night and departed for Egypt,", 
                "num": 14
              }, 
              {
                "text": "and was there until the death of Herod, that it might be fulfilled which was spoken by the Lord through the prophet, saying, \"Out of Egypt I called My Son.\"", 
                "num": 15
              }, 
              {
                "text": "Then Herod, when he saw that he was deceived by the wise men, was exceedingly angry; and he sent forth and put to death all the male children who were in Bethlehem and in all its districts, from two years old and under, according to the time which he had determined from the wise men.", 
                "num": 16
              }, 
              {
                "text": "Then was fulfilled what was spoken by Jeremiah the prophet, saying:", 
                "num": 17
              }, 
              {
                "text": "\"A voice was heard in Ramah, Lamentation, weeping, and great mourning, Rachel weeping for her children, Refusing to be comforted, Because they are no more.\"", 
                "num": 18
              }, 
              {
                "text": "Now when Herod was dead, behold, an angel of the Lord appeared in a dream to Joseph in Egypt,", 
                "num": 19
              }, 
              {
                "text": "saying, \"Arise, take the young Child and His mother, and go to the land of Israel, for those who sought the young Child's life are dead.\"", 
                "num": 20
              }, 
              {
                "text": "Then he arose, took the young Child and His mother, and came into the land of Israel.", 
                "num": 21
              }, 
              {
                "text": "But when he heard that Archelaus was reigning over Judea instead of his father Herod, he was afraid to go there. And being warned by God in a dream, he turned aside into the region of Galilee.", 
                "num": 22
              }, 
              {
                "text": "And he came and dwelt in a city called Nazareth, that it might be fulfilled which was spoken by the prophets, \"He shall be called a Nazarene.\"", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "In those days John the Baptist came preaching in the wilderness of Judea,", 
                "num": 1
              }, 
              {
                "text": "and saying, \"Repent, for the kingdom of heaven is at hand!\"", 
                "num": 2
              }, 
              {
                "text": "For this is he who was spoken of by the prophet Isaiah, saying: \"The voice of one crying in the wilderness: \"Prepare the way of the LORD; Make His paths straight.\"'", 
                "num": 3
              }, 
              {
                "text": "Now John himself was clothed in camel's hair, with a leather belt around his waist; and his food was locusts and wild honey.", 
                "num": 4
              }, 
              {
                "text": "Then Jerusalem, all Judea, and all the region around the Jordan went out to him", 
                "num": 5
              }, 
              {
                "text": "and were baptized by him in the Jordan, confessing their sins.", 
                "num": 6
              }, 
              {
                "text": "But when he saw many of the Pharisees and Sadducees coming to his baptism, he said to them, \"Brood of vipers! Who warned you to flee from the wrath to come?", 
                "num": 7
              }, 
              {
                "text": "Therefore bear fruits worthy of repentance,", 
                "num": 8
              }, 
              {
                "text": "and do not think to say to yourselves, \"We have Abraham as our father.' For I say to you that God is able to raise up children to Abraham from these stones.", 
                "num": 9
              }, 
              {
                "text": "And even now the ax is laid to the root of the trees. Therefore every tree which does not bear good fruit is cut down and thrown into the fire.", 
                "num": 10
              }, 
              {
                "text": "I indeed baptize you with water unto repentance, but He who is coming after me is mightier than I, whose sandals I am not worthy to carry. He will baptize you with the Holy Spirit and fire.", 
                "num": 11
              }, 
              {
                "text": "His winnowing fan is in His hand, and He will thoroughly clean out His threshing floor, and gather His wheat into the barn; but He will burn up the chaff with unquenchable fire.\"", 
                "num": 12
              }, 
              {
                "text": "Then Jesus came from Galilee to John at the Jordan to be baptized by him.", 
                "num": 13
              }, 
              {
                "text": "And John tried to prevent Him, saying, \"I need to be baptized by You, and are You coming to me?\"", 
                "num": 14
              }, 
              {
                "text": "But Jesus answered and said to him, \"Permit it to be so now, for thus it is fitting for us to fulfill all righteousness.\" Then he allowed Him.", 
                "num": 15
              }, 
              {
                "text": "When He had been baptized, Jesus came up immediately from the water; and behold, the heavens were opened to Him, and He saw the Spirit of God descending like a dove and alighting upon Him.", 
                "num": 16
              }, 
              {
                "text": "And suddenly a voice came from heaven, saying, \"This is My beloved Son, in whom I am well pleased.\"", 
                "num": 17
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then Jesus was led up by the Spirit into the wilderness to be tempted by the devil.", 
                "num": 1
              }, 
              {
                "text": "And when He had fasted forty days and forty nights, afterward He was hungry.", 
                "num": 2
              }, 
              {
                "text": "Now when the tempter came to Him, he said, \"If You are the Son of God, command that these stones become bread.\"", 
                "num": 3
              }, 
              {
                "text": "But He answered and said, \"It is written, \"Man shall not live by bread alone, but by every word that proceeds from the mouth of God.\"'", 
                "num": 4
              }, 
              {
                "text": "Then the devil took Him up into the holy city, set Him on the pinnacle of the temple,", 
                "num": 5
              }, 
              {
                "text": "and said to Him, \"If You are the Son of God, throw Yourself down. For it is written: \"He shall give His angels charge over you,' and, \"In their hands they shall bear you up, Lest you dash your foot against a stone.\"'", 
                "num": 6
              }, 
              {
                "text": "Jesus said to him, \"It is written again, \"You shall not tempt the LORD your God.\"'", 
                "num": 7
              }, 
              {
                "text": "Again, the devil took Him up on an exceedingly high mountain, and showed Him all the kingdoms of the world and their glory.", 
                "num": 8
              }, 
              {
                "text": "And he said to Him, \"All these things I will give You if You will fall down and worship me.\"", 
                "num": 9
              }, 
              {
                "text": "Then Jesus said to him, \"Away with you, Satan! For it is written, \"You shall worship the LORD your God, and Him only you shall serve.\"'", 
                "num": 10
              }, 
              {
                "text": "Then the devil left Him, and behold, angels came and ministered to Him.", 
                "num": 11
              }, 
              {
                "text": "Now when Jesus heard that John had been put in prison, He departed to Galilee.", 
                "num": 12
              }, 
              {
                "text": "And leaving Nazareth, He came and dwelt in Capernaum, which is by the sea, in the regions of Zebulun and Naphtali,", 
                "num": 13
              }, 
              {
                "text": "that it might be fulfilled which was spoken by Isaiah the prophet, saying:", 
                "num": 14
              }, 
              {
                "text": "\"The land of Zebulun and the land of Naphtali, By the way of the sea, beyond the Jordan, Galilee of the Gentiles:", 
                "num": 15
              }, 
              {
                "text": "The people who sat in darkness have seen a great light, And upon those who sat in the region and shadow of death Light has dawned.\"", 
                "num": 16
              }, 
              {
                "text": "From that time Jesus began to preach and to say, \"Repent, for the kingdom of heaven is at hand.\"", 
                "num": 17
              }, 
              {
                "text": "And Jesus, walking by the Sea of Galilee, saw two brothers, Simon called Peter, and Andrew his brother, casting a net into the sea; for they were fishermen.", 
                "num": 18
              }, 
              {
                "text": "Then He said to them, \"Follow Me, and I will make you fishers of men.\" rm820They immediately left their nets and followed Him.", 
                "num": 19
              }, 
              {
                "text": "Going on from there, He saw two other brothers, James the son of Zebedee, and John his brother, in the boat with Zebedee their father, mending their nets. He called them,", 
                "num": 21
              }, 
              {
                "text": "and immediately they left the boat and their father, and followed Him.", 
                "num": 22
              }, 
              {
                "text": "And Jesus went about all Galilee, teaching in their synagogues, preaching the gospel of the kingdom, and healing all kinds of sickness and all kinds of disease among the people.", 
                "num": 23
              }, 
              {
                "text": "Then His fame went throughout all Syria; and they brought to Him all sick people who were afflicted with various diseases and torments, and those who were demon-possessed, epileptics, and paralytics; and He healed them.", 
                "num": 24
              }, 
              {
                "text": "Great multitudes followed Him--from Galilee, and from Decapolis, Jerusalem, Judea, and beyond the Jordan.", 
                "num": 25
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "And seeing the multitudes, He went up on a mountain, and when He was seated His disciples came to Him.", 
                "num": 1
              }, 
              {
                "text": "Then He opened His mouth and taught them, saying:", 
                "num": 2
              }, 
              {
                "text": "\"Blessed are the poor in spirit, For theirs is the kingdom of heaven.", 
                "num": 3
              }, 
              {
                "text": "Blessed are those who mourn, For they shall be comforted.", 
                "num": 4
              }, 
              {
                "text": "Blessed are the meek, For they shall inherit the earth.", 
                "num": 5
              }, 
              {
                "text": "Blessed are those who hunger and thirst for righteousness, For they shall be filled.", 
                "num": 6
              }, 
              {
                "text": "Blessed are the merciful, For they shall obtain mercy.", 
                "num": 7
              }, 
              {
                "text": "Blessed are the pure in heart, For they shall see God.", 
                "num": 8
              }, 
              {
                "text": "Blessed are the peacemakers, For they shall be called sons of God.", 
                "num": 9
              }, 
              {
                "text": "Blessed are those who are persecuted for righteousness' sake, For theirs is the kingdom of heaven.", 
                "num": 10
              }, 
              {
                "text": "\"Blessed are you when they revile and persecute you, and say all kinds of evil against you falsely for My sake.", 
                "num": 11
              }, 
              {
                "text": "Rejoice and be exceedingly glad, for great is your reward in heaven, for so they persecuted the prophets who were before you.", 
                "num": 12
              }, 
              {
                "text": "\"You are the salt of the earth; but if the salt loses its flavor, how shall it be seasoned? It is then good for nothing but to be thrown out and trampled underfoot by men.", 
                "num": 13
              }, 
              {
                "text": "\"You are the light of the world. A city that is set on a hill cannot be hidden.", 
                "num": 14
              }, 
              {
                "text": "Nor do they light a lamp and put it under a basket, but on a lampstand, and it gives light to all who are in the house.", 
                "num": 15
              }, 
              {
                "text": "Let your light so shine before men, that they may see your good works and glorify your Father in heaven.", 
                "num": 16
              }, 
              {
                "text": "\"Do not think that I came to destroy the Law or the Prophets. I did not come to destroy but to fulfill.", 
                "num": 17
              }, 
              {
                "text": "For assuredly, I say to you, till heaven and earth pass away, one jot or one tittle will by no means pass from the law till all is fulfilled.", 
                "num": 18
              }, 
              {
                "text": "Whoever therefore breaks one of the least of these commandments, and teaches men so, shall be called least in the kingdom of heaven; but whoever does and teaches them, he shall be called great in the kingdom of heaven.", 
                "num": 19
              }, 
              {
                "text": "For I say to you, that unless your righteousness exceeds the righteousness of the scribes and Pharisees, you will by no means enter the kingdom of heaven.", 
                "num": 20
              }, 
              {
                "text": "\"You have heard that it was said to those of old, \"You shall not murder, and whoever murders will be in danger of the judgment.'", 
                "num": 21
              }, 
              {
                "text": "But I say to you that whoever is angry with his brother without a cause shall be in danger of the judgment. And whoever says to his brother, \"Raca!' shall be in danger of the council. But whoever says, \"You fool!' shall be in danger of hell fire.", 
                "num": 22
              }, 
              {
                "text": "Therefore if you bring your gift to the altar, and there remember that your brother has something against you,", 
                "num": 23
              }, 
              {
                "text": "leave your gift there before the altar, and go your way. First be reconciled to your brother, and then come and offer your gift.", 
                "num": 24
              }, 
              {
                "text": "Agree with your adversary quickly, while you are on the way with him, lest your adversary deliver you to the judge, the judge hand you over to the officer, and you be thrown into prison.", 
                "num": 25
              }, 
              {
                "text": "Assuredly, I say to you, you will by no means get out of there till you have paid the last penny.", 
                "num": 26
              }, 
              {
                "text": "\"You have heard that it was said to those of old, \"You shall not commit adultery.'", 
                "num": 27
              }, 
              {
                "text": "But I say to you that whoever looks at a woman to lust for her has already committed adultery with her in his heart.", 
                "num": 28
              }, 
              {
                "text": "If your right eye causes you to sin, pluck it out and cast it from you; for it is more profitable for you that one of your members perish, than for your whole body to be cast into hell.", 
                "num": 29
              }, 
              {
                "text": "And if your right hand causes you to sin, cut it off and cast it from you; for it is more profitable for you that one of your members perish, than for your whole body to be cast into hell.", 
                "num": 30
              }, 
              {
                "text": "\"Furthermore it has been said, \"Whoever divorces his wife, let him give her a certificate of divorce.'", 
                "num": 31
              }, 
              {
                "text": "But I say to you that whoever divorces his wife for any reason except sexual immorality causes her to commit adultery; and whoever marries a woman who is divorced commits adultery.", 
                "num": 32
              }, 
              {
                "text": "\"Again you have heard that it was said to those of old, \"You shall not swear falsely, but shall perform your oaths to the Lord.'", 
                "num": 33
              }, 
              {
                "text": "But I say to you, do not swear at all: neither by heaven, for it is God's throne;", 
                "num": 34
              }, 
              {
                "text": "nor by the earth, for it is His footstool; nor by Jerusalem, for it is the city of the great King.", 
                "num": 35
              }, 
              {
                "text": "Nor shall you swear by your head, because you cannot make one hair white or black.", 
                "num": 36
              }, 
              {
                "text": "But let your \"Yes' be \"Yes,' and your \"No,' \"No.' For whatever is more than these is from the evil one.", 
                "num": 37
              }, 
              {
                "text": "\"You have heard that it was said, \"An eye for an eye and a tooth for a tooth.'", 
                "num": 38
              }, 
              {
                "text": "But I tell you not to resist an evil person. But whoever slaps you on your right cheek, turn the other to him also.", 
                "num": 39
              }, 
              {
                "text": "If anyone wants to sue you and take away your tunic, let him have your cloak also.", 
                "num": 40
              }, 
              {
                "text": "And whoever compels you to go one mile, go with him two.", 
                "num": 41
              }, 
              {
                "text": "Give to him who asks you, and from him who wants to borrow from you do not turn away.", 
                "num": 42
              }, 
              {
                "text": "\"You have heard that it was said, \"You shall love your neighbor and hate your enemy.'", 
                "num": 43
              }, 
              {
                "text": "But I say to you, love your enemies, bless those who curse you, do good to those who hate you, and pray for those who spitefully use you and persecute you,", 
                "num": 44
              }, 
              {
                "text": "that you may be sons of your Father in heaven; for He makes His sun rise on the evil and on the good, and sends rain on the just and on the unjust.", 
                "num": 45
              }, 
              {
                "text": "For if you love those who love you, what reward have you? Do not even the tax collectors do the same?", 
                "num": 46
              }, 
              {
                "text": "And if you greet your brethren only, what do you do more than others? Do not even the tax collectors do so?", 
                "num": 47
              }, 
              {
                "text": "Therefore you shall be perfect, just as your Father in heaven is perfect.", 
                "num": 48
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "\"Take heed that you do not do your charitable deeds before men, to be seen by them. Otherwise you have no reward from your Father in heaven.", 
                "num": 1
              }, 
              {
                "text": "Therefore, when you do a charitable deed, do not sound a trumpet before you as the hypocrites do in the synagogues and in the streets, that they may have glory from men. Assuredly, I say to you, they have their reward.", 
                "num": 2
              }, 
              {
                "text": "But when you do a charitable deed, do not let your left hand know what your right hand is doing,", 
                "num": 3
              }, 
              {
                "text": "that your charitable deed may be in secret; and your Father who sees in secret will Himself reward you openly.", 
                "num": 4
              }, 
              {
                "text": "\"And when you pray, you shall not be like the hypocrites. For they love to pray standing in the synagogues and on the corners of the streets, that they may be seen by men. Assuredly, I say to you, they have their reward.", 
                "num": 5
              }, 
              {
                "text": "But you, when you pray, go into your room, and when you have shut your door, pray to your Father who is in the secret place; and your Father who sees in secret will reward you openly.", 
                "num": 6
              }, 
              {
                "text": "And when you pray, do not use vain repetitions as the heathen do. For they think that they will be heard for their many words.", 
                "num": 7
              }, 
              {
                "text": "\"Therefore do not be like them. For your Father knows the things you have need of before you ask Him.", 
                "num": 8
              }, 
              {
                "text": "In this manner, therefore, pray: Our Father in heaven, Hallowed be Your name.", 
                "num": 9
              }, 
              {
                "text": "Your kingdom come. Your will be done On earth as it is in heaven.", 
                "num": 10
              }, 
              {
                "text": "Give us this day our daily bread.", 
                "num": 11
              }, 
              {
                "text": "And forgive us our debts, As we forgive our debtors.", 
                "num": 12
              }, 
              {
                "text": "And do not lead us into temptation, But deliver us from the evil one. For Yours is the kingdom and the power and the glory forever. Amen.", 
                "num": 13
              }, 
              {
                "text": "\"For if you forgive men their trespasses, your heavenly Father will also forgive you.", 
                "num": 14
              }, 
              {
                "text": "But if you do not forgive men their trespasses, neither will your Father forgive your trespasses.", 
                "num": 15
              }, 
              {
                "text": "\"Moreover, when you fast, do not be like the hypocrites, with a sad countenance. For they disfigure their faces that they may appear to men to be fasting. Assuredly, I say to you, they have their reward.", 
                "num": 16
              }, 
              {
                "text": "But you, when you fast, anoint your head and wash your face,", 
                "num": 17
              }, 
              {
                "text": "so that you do not appear to men to be fasting, but to your Father who is in the secret place; and your Father who sees in secret will reward you openly.", 
                "num": 18
              }, 
              {
                "text": "\"Do not lay up for yourselves treasures on earth, where moth and rust destroy and where thieves break in and steal;", 
                "num": 19
              }, 
              {
                "text": "but lay up for yourselves treasures in heaven, where neither moth nor rust destroys and where thieves do not break in and steal.", 
                "num": 20
              }, 
              {
                "text": "For where your treasure is, there your heart will be also.", 
                "num": 21
              }, 
              {
                "text": "\"The lamp of the body is the eye. If therefore your eye is good, your whole body will be full of light.", 
                "num": 22
              }, 
              {
                "text": "But if your eye is bad, your whole body will be full of darkness. If therefore the light that is in you is darkness, how great is that darkness!", 
                "num": 23
              }, 
              {
                "text": "\"No one can serve two masters; for either he will hate the one and love the other, or else he will be loyal to the one and despise the other. You cannot serve God and mammon.", 
                "num": 24
              }, 
              {
                "text": "\"Therefore I say to you, do not worry about your life, what you will eat or what you will drink; nor about your body, what you will put on. Is not life more than food and the body more than clothing?", 
                "num": 25
              }, 
              {
                "text": "Look at the birds of the air, for they neither sow nor reap nor gather into barns; yet your heavenly Father feeds them. Are you not of more value than they?", 
                "num": 26
              }, 
              {
                "text": "Which of you by worrying can add one cubit to his stature?", 
                "num": 27
              }, 
              {
                "text": "\"So why do you worry about clothing? Consider the lilies of the field, how they grow: they neither toil nor spin;", 
                "num": 28
              }, 
              {
                "text": "and yet I say to you that even Solomon in all his glory was not arrayed like one of these.", 
                "num": 29
              }, 
              {
                "text": "Now if God so clothes the grass of the field, which today is, and tomorrow is thrown into the oven, will He not much more clothe you, O you of little faith?", 
                "num": 30
              }, 
              {
                "text": "\"Therefore do not worry, saying, \"What shall we eat?' or \"What shall we drink?' or \"What shall we wear?'", 
                "num": 31
              }, 
              {
                "text": "For after all these things the Gentiles seek. For your heavenly Father knows that you need all these things.", 
                "num": 32
              }, 
              {
                "text": "But seek first the kingdom of God and His righteousness, and all these things shall be added to you.", 
                "num": 33
              }, 
              {
                "text": "Therefore do not worry about tomorrow, for tomorrow will worry about its own things. Sufficient for the day is its own trouble.", 
                "num": 34
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "\"Judge not, that you be not judged.", 
                "num": 1
              }, 
              {
                "text": "For with what judgment you judge, you will be judged; and with the measure you use, it will be measured back to you.", 
                "num": 2
              }, 
              {
                "text": "And why do you look at the speck in your brother's eye, but do not consider the plank in your own eye?", 
                "num": 3
              }, 
              {
                "text": "Or how can you say to your brother, \"Let me remove the speck from your eye'; and look, a plank is in your own eye?", 
                "num": 4
              }, 
              {
                "text": "Hypocrite! First remove the plank from your own eye, and then you will see clearly to remove the speck from your brother's eye.", 
                "num": 5
              }, 
              {
                "text": "\"Do not give what is holy to the dogs; nor cast your pearls before swine, lest they trample them under their feet, and turn and tear you in pieces.", 
                "num": 6
              }, 
              {
                "text": "\"Ask, and it will be given to you; seek, and you will find; knock, and it will be opened to you.", 
                "num": 7
              }, 
              {
                "text": "For everyone who asks receives, and he who seeks finds, and to him who knocks it will be opened.", 
                "num": 8
              }, 
              {
                "text": "Or what man is there among you who, if his son asks for bread, will give him a stone?", 
                "num": 9
              }, 
              {
                "text": "Or if he asks for a fish, will he give him a serpent?", 
                "num": 10
              }, 
              {
                "text": "If you then, being evil, know how to give good gifts to your children, how much more will your Father who is in heaven give good things to those who ask Him!", 
                "num": 11
              }, 
              {
                "text": "Therefore, whatever you want men to do to you, do also to them, for this is the Law and the Prophets.", 
                "num": 12
              }, 
              {
                "text": "\"Enter by the narrow gate; for wide is the gate and broad is the way that leads to destruction, and there are many who go in by it.", 
                "num": 13
              }, 
              {
                "text": "Because narrow is the gate and difficult is the way which leads to life, and there are few who find it.", 
                "num": 14
              }, 
              {
                "text": "\"Beware of false prophets, who come to you in sheep's clothing, but inwardly they are ravenous wolves.", 
                "num": 15
              }, 
              {
                "text": "You will know them by their fruits. Do men gather grapes from thornbushes or figs from thistles?", 
                "num": 16
              }, 
              {
                "text": "Even so, every good tree bears good fruit, but a bad tree bears bad fruit.", 
                "num": 17
              }, 
              {
                "text": "A good tree cannot bear bad fruit, nor can a bad tree bear good fruit.", 
                "num": 18
              }, 
              {
                "text": "Every tree that does not bear good fruit is cut down and thrown into the fire.", 
                "num": 19
              }, 
              {
                "text": "Therefore by their fruits you will know them.", 
                "num": 20
              }, 
              {
                "text": "\"Not everyone who says to Me, \"Lord, Lord,' shall enter the kingdom of heaven, but he who does the will of My Father in heaven.", 
                "num": 21
              }, 
              {
                "text": "Many will say to Me in that day, \"Lord, Lord, have we not prophesied in Your name, cast out demons in Your name, and done many wonders in Your name?'", 
                "num": 22
              }, 
              {
                "text": "And then I will declare to them, \"I never knew you; depart from Me, you who practice lawlessness!'", 
                "num": 23
              }, 
              {
                "text": "\"Therefore whoever hears these sayings of Mine, and does them, I will liken him to a wise man who built his house on the rock:", 
                "num": 24
              }, 
              {
                "text": "and the rain descended, the floods came, and the winds blew and beat on that house; and it did not fall, for it was founded on the rock.", 
                "num": 25
              }, 
              {
                "text": "\"But everyone who hears these sayings of Mine, and does not do them, will be like a foolish man who built his house on the sand:", 
                "num": 26
              }, 
              {
                "text": "and the rain descended, the floods came, and the winds blew and beat on that house; and it fell. And great was its fall.\"", 
                "num": 27
              }, 
              {
                "text": "And so it was, when Jesus had ended these sayings, that the people were astonished at His teaching,", 
                "num": 28
              }, 
              {
                "text": "for He taught them as one having authority, and not as the scribes.", 
                "num": 29
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "When He had come down from the mountain, great multitudes followed Him.", 
                "num": 1
              }, 
              {
                "text": "And behold, a leper came and worshiped Him, saying, \"Lord, if You are willing, You can make me clean.\"", 
                "num": 2
              }, 
              {
                "text": "Then Jesus put out His hand and touched him, saying, \"I am willing; be cleansed.\" Immediately his leprosy was cleansed.", 
                "num": 3
              }, 
              {
                "text": "And Jesus said to him, \"See that you tell no one; but go your way, show yourself to the priest, and offer the gift that Moses commanded, as a testimony to them.\"", 
                "num": 4
              }, 
              {
                "text": "Now when Jesus had entered Capernaum, a centurion came to Him, pleading with Him,", 
                "num": 5
              }, 
              {
                "text": "saying, \"Lord, my servant is lying at home paralyzed, dreadfully tormented.\"", 
                "num": 6
              }, 
              {
                "text": "And Jesus said to him, \"I will come and heal him.\"", 
                "num": 7
              }, 
              {
                "text": "The centurion answered and said, \"Lord, I am not worthy that You should come under my roof. But only speak a word, and my servant will be healed.", 
                "num": 8
              }, 
              {
                "text": "For I also am a man under authority, having soldiers under me. And I say to this one, \"Go,' and he goes; and to another, \"Come,' and he comes; and to my servant, \"Do this,' and he does it.\"", 
                "num": 9
              }, 
              {
                "text": "When Jesus heard it, He marveled, and said to those who followed, \"Assuredly, I say to you, I have not found such great faith, not even in Israel!", 
                "num": 10
              }, 
              {
                "text": "And I say to you that many will come from east and west, and sit down with Abraham, Isaac, and Jacob in the kingdom of heaven.", 
                "num": 11
              }, 
              {
                "text": "But the sons of the kingdom will be cast out into outer darkness. There will be weeping and gnashing of teeth.\"", 
                "num": 12
              }, 
              {
                "text": "Then Jesus said to the centurion, \"Go your way; and as you have believed, so let it be done for you.\" And his servant was healed that same hour.", 
                "num": 13
              }, 
              {
                "text": "Now when Jesus had come into Peter's house, He saw his wife's mother lying sick with a fever.", 
                "num": 14
              }, 
              {
                "text": "So He touched her hand, and the fever left her. And she arose and served them.", 
                "num": 15
              }, 
              {
                "text": "When evening had come, they brought to Him many who were demon-possessed. And He cast out the spirits with a word, and healed all who were sick,", 
                "num": 16
              }, 
              {
                "text": "that it might be fulfilled which was spoken by Isaiah the prophet, saying: \"He Himself took our infirmities And bore our sicknesses.\"", 
                "num": 17
              }, 
              {
                "text": "And when Jesus saw great multitudes about Him, He gave a command to depart to the other side.", 
                "num": 18
              }, 
              {
                "text": "Then a certain scribe came and said to Him, \"Teacher, I will follow You wherever You go.\"", 
                "num": 19
              }, 
              {
                "text": "And Jesus said to him, \"Foxes have holes and birds of the air have nests, but the Son of Man has nowhere to lay His head.\"", 
                "num": 20
              }, 
              {
                "text": "Then another of His disciples said to Him, \"Lord, let me first go and bury my father.\"", 
                "num": 21
              }, 
              {
                "text": "But Jesus said to him, \"Follow Me, and let the dead bury their own dead.\"", 
                "num": 22
              }, 
              {
                "text": "Now when He got into a boat, His disciples followed Him.", 
                "num": 23
              }, 
              {
                "text": "And suddenly a great tempest arose on the sea, so that the boat was covered with the waves. But He was asleep.", 
                "num": 24
              }, 
              {
                "text": "Then His disciples came to Him and awoke Him, saying, \"Lord, save us! We are perishing!\"", 
                "num": 25
              }, 
              {
                "text": "But He said to them, \"Why are you fearful, O you of little faith?\" Then He arose and rebuked the winds and the sea, and there was a great calm.", 
                "num": 26
              }, 
              {
                "text": "So the men marveled, saying, \"Who can this be, that even the winds and the sea obey Him?\"", 
                "num": 27
              }, 
              {
                "text": "When He had come to the other side, to the country of the Gergesenes, there met Him two demon-possessed men, coming out of the tombs, exceedingly fierce, so that no one could pass that way.", 
                "num": 28
              }, 
              {
                "text": "And suddenly they cried out, saying, \"What have we to do with You, Jesus, You Son of God? Have You come here to torment us before the time?\"", 
                "num": 29
              }, 
              {
                "text": "Now a good way off from them there was a herd of many swine feeding.", 
                "num": 30
              }, 
              {
                "text": "So the demons begged Him, saying, \"If You cast us out, permit us to go away into the herd of swine.\"", 
                "num": 31
              }, 
              {
                "text": "And He said to them, \"Go.\" So when they had come out, they went into the herd of swine. And suddenly the whole herd of swine ran violently down the steep place into the sea, and perished in the water.", 
                "num": 32
              }, 
              {
                "text": "Then those who kept them fled; and they went away into the city and told everything, including what had happened to the demon-possessed men.", 
                "num": 33
              }, 
              {
                "text": "And behold, the whole city came out to meet Jesus. And when they saw Him, they begged Him to depart from their region.", 
                "num": 34
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "So He got into a boat, crossed over, and came to His own city.", 
                "num": 1
              }, 
              {
                "text": "Then behold, they brought to Him a paralytic lying on a bed. When Jesus saw their faith, He said to the paralytic, \"Son, be of good cheer; your sins are forgiven you.\"", 
                "num": 2
              }, 
              {
                "text": "And at once some of the scribes said within themselves, \"This Man blasphemes!\"", 
                "num": 3
              }, 
              {
                "text": "But Jesus, knowing their thoughts, said, \"Why do you think evil in your hearts?", 
                "num": 4
              }, 
              {
                "text": "For which is easier, to say, \"Your sins are forgiven you,' or to say, \"Arise and walk'?", 
                "num": 5
              }, 
              {
                "text": "But that you may know that the Son of Man has power on earth to forgive sins\"--then He said to the paralytic, \"Arise, take up your bed, and go to your house.\"", 
                "num": 6
              }, 
              {
                "text": "And he arose and departed to his house.", 
                "num": 7
              }, 
              {
                "text": "Now when the multitudes saw it, they marveled and glorified God, who had given such power to men.", 
                "num": 8
              }, 
              {
                "text": "As Jesus passed on from there, He saw a man named Matthew sitting at the tax office. And He said to him, \"Follow Me.\" So he arose and followed Him.", 
                "num": 9
              }, 
              {
                "text": "Now it happened, as Jesus sat at the table in the house, that behold, many tax collectors and sinners came and sat down with Him and His disciples.", 
                "num": 10
              }, 
              {
                "text": "And when the Pharisees saw it, they said to His disciples, \"Why does your Teacher eat with tax collectors and sinners?\"", 
                "num": 11
              }, 
              {
                "text": "When Jesus heard that, He said to them, \"Those who are well have no need of a physician, but those who are sick.", 
                "num": 12
              }, 
              {
                "text": "But go and learn what this means: \"I desire mercy and not sacrifice.' For I did not come to call the righteous, but sinners, to repentance.\"", 
                "num": 13
              }, 
              {
                "text": "Then the disciples of John came to Him, saying, \"Why do we and the Pharisees fast often, but Your disciples do not fast?\"", 
                "num": 14
              }, 
              {
                "text": "And Jesus said to them, \"Can the friends of the bridegroom mourn as long as the bridegroom is with them? But the days will come when the bridegroom will be taken away from them, and then they will fast.", 
                "num": 15
              }, 
              {
                "text": "No one puts a piece of unshrunk cloth on an old garment; for the patch pulls away from the garment, and the tear is made worse.", 
                "num": 16
              }, 
              {
                "text": "Nor do they put new wine into old wineskins, or else the wineskins break, the wine is spilled, and the wineskins are ruined. But they put new wine into new wineskins, and both are preserved.\"", 
                "num": 17
              }, 
              {
                "text": "While He spoke these things to them, behold, a ruler came and worshiped Him, saying, \"My daughter has just died, but come and lay Your hand on her and she will live.\"", 
                "num": 18
              }, 
              {
                "text": "So Jesus arose and followed him, and so did His disciples.", 
                "num": 19
              }, 
              {
                "text": "And suddenly, a woman who had a flow of blood for twelve years came from behind and touched the hem of His garment.", 
                "num": 20
              }, 
              {
                "text": "For she said to herself, \"If only I may touch His garment, I shall be made well.\"", 
                "num": 21
              }, 
              {
                "text": "But Jesus turned around, and when He saw her He said, \"Be of good cheer, daughter; your faith has made you well.\" And the woman was made well from that hour.", 
                "num": 22
              }, 
              {
                "text": "When Jesus came into the ruler's house, and saw the flute players and the noisy crowd wailing,", 
                "num": 23
              }, 
              {
                "text": "He said to them, \"Make room, for the girl is not dead, but sleeping.\" And they ridiculed Him.", 
                "num": 24
              }, 
              {
                "text": "But when the crowd was put outside, He went in and took her by the hand, and the girl arose.", 
                "num": 25
              }, 
              {
                "text": "And the report of this went out into all that land.", 
                "num": 26
              }, 
              {
                "text": "When Jesus departed from there, two blind men followed Him, crying out and saying, \"Son of David, have mercy on us!\"", 
                "num": 27
              }, 
              {
                "text": "And when He had come into the house, the blind men came to Him. And Jesus said to them, \"Do you believe that I am able to do this?\" They said to Him, \"Yes, Lord.\"", 
                "num": 28
              }, 
              {
                "text": "Then He touched their eyes, saying, \"According to your faith let it be to you.\"", 
                "num": 29
              }, 
              {
                "text": "And their eyes were opened. And Jesus sternly warned them, saying, \"See that no one knows it.\"", 
                "num": 30
              }, 
              {
                "text": "But when they had departed, they spread the news about Him in all that country.", 
                "num": 31
              }, 
              {
                "text": "As they went out, behold, they brought to Him a man, mute and demon-possessed.", 
                "num": 32
              }, 
              {
                "text": "And when the demon was cast out, the mute spoke. And the multitudes marveled, saying, \"It was never seen like this in Israel!\"", 
                "num": 33
              }, 
              {
                "text": "But the Pharisees said, \"He casts out demons by the ruler of the demons.\"", 
                "num": 34
              }, 
              {
                "text": "Then Jesus went about all the cities and villages, teaching in their synagogues, preaching the gospel of the kingdom, and healing every sickness and every disease among the people.", 
                "num": 35
              }, 
              {
                "text": "But when He saw the multitudes, He was moved with compassion for them, because they were weary and scattered, like sheep having no shepherd.", 
                "num": 36
              }, 
              {
                "text": "Then He said to His disciples, \"The harvest truly is plentiful, but the laborers are few.", 
                "num": 37
              }, 
              {
                "text": "Therefore pray the Lord of the harvest to send out laborers into His harvest.\"", 
                "num": 38
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "And when He had called His twelve disciples to Him, He gave them power over unclean spirits, to cast them out, and to heal all kinds of sickness and all kinds of disease.", 
                "num": 1
              }, 
              {
                "text": "Now the names of the twelve apostles are these: first, Simon, who is called Peter, and Andrew his brother; James the son of Zebedee, and John his brother;", 
                "num": 2
              }, 
              {
                "text": "Philip and Bartholomew; Thomas and Matthew the tax collector; James the son of Alphaeus, and Lebbaeus, whose surname was Thaddaeus;", 
                "num": 3
              }, 
              {
                "text": "Simon the Cananite, and Judas Iscariot, who also betrayed Him.", 
                "num": 4
              }, 
              {
                "text": "These twelve Jesus sent out and commanded them, saying: \"Do not go into the way of the Gentiles, and do not enter a city of the Samaritans.", 
                "num": 5
              }, 
              {
                "text": "But go rather to the lost sheep of the house of Israel.", 
                "num": 6
              }, 
              {
                "text": "And as you go, preach, saying, \"The kingdom of heaven is at hand.'", 
                "num": 7
              }, 
              {
                "text": "Heal the sick, cleanse the lepers, raise the dead, cast out demons. Freely you have received, freely give.", 
                "num": 8
              }, 
              {
                "text": "Provide neither gold nor silver nor copper in your money belts,", 
                "num": 9
              }, 
              {
                "text": "nor bag for your journey, nor two tunics, nor sandals, nor staffs; for a worker is worthy of his food.", 
                "num": 10
              }, 
              {
                "text": "\"Now whatever city or town you enter, inquire who in it is worthy, and stay there till you go out.", 
                "num": 11
              }, 
              {
                "text": "And when you go into a household, greet it.", 
                "num": 12
              }, 
              {
                "text": "If the household is worthy, let your peace come upon it. But if it is not worthy, let your peace return to you.", 
                "num": 13
              }, 
              {
                "text": "And whoever will not receive you nor hear your words, when you depart from that house or city, shake off the dust from your feet.", 
                "num": 14
              }, 
              {
                "text": "Assuredly, I say to you, it will be more tolerable for the land of Sodom and Gomorrah in the day of judgment than for that city!", 
                "num": 15
              }, 
              {
                "text": "\"Behold, I send you out as sheep in the midst of wolves. Therefore be wise as serpents and harmless as doves.", 
                "num": 16
              }, 
              {
                "text": "But beware of men, for they will deliver you up to councils and scourge you in their synagogues.", 
                "num": 17
              }, 
              {
                "text": "You will be brought before governors and kings for My sake, as a testimony to them and to the Gentiles.", 
                "num": 18
              }, 
              {
                "text": "But when they deliver you up, do not worry about how or what you should speak. For it will be given to you in that hour what you should speak;", 
                "num": 19
              }, 
              {
                "text": "for it is not you who speak, but the Spirit of your Father who speaks in you.", 
                "num": 20
              }, 
              {
                "text": "\"Now brother will deliver up brother to death, and a father his child; and children will rise up against parents and cause them to be put to death.", 
                "num": 21
              }, 
              {
                "text": "And you will be hated by all for My name's sake. But he who endures to the end will be saved.", 
                "num": 22
              }, 
              {
                "text": "When they persecute you in this city, flee to another. For assuredly, I say to you, you will not have gone through the cities of Israel before the Son of Man comes.", 
                "num": 23
              }, 
              {
                "text": "\"A disciple is not above his teacher, nor a servant above his master.", 
                "num": 24
              }, 
              {
                "text": "It is enough for a disciple that he be like his teacher, and a servant like his master. If they have called the master of the house Beelzebub, how much more will they call those of his household!", 
                "num": 25
              }, 
              {
                "text": "Therefore do not fear them. For there is nothing covered that will not be revealed, and hidden that will not be known.", 
                "num": 26
              }, 
              {
                "text": "\"Whatever I tell you in the dark, speak in the light; and what you hear in the ear, preach on the housetops.", 
                "num": 27
              }, 
              {
                "text": "And do not fear those who kill the body but cannot kill the soul. But rather fear Him who is able to destroy both soul and body in hell.", 
                "num": 28
              }, 
              {
                "text": "Are not two sparrows sold for a copper coin? And not one of them falls to the ground apart from your Father's will.", 
                "num": 29
              }, 
              {
                "text": "But the very hairs of your head are all numbered.", 
                "num": 30
              }, 
              {
                "text": "Do not fear therefore; you are of more value than many sparrows.", 
                "num": 31
              }, 
              {
                "text": "\"Therefore whoever confesses Me before men, him I will also confess before My Father who is in heaven.", 
                "num": 32
              }, 
              {
                "text": "But whoever denies Me before men, him I will also deny before My Father who is in heaven.", 
                "num": 33
              }, 
              {
                "text": "\"Do not think that I came to bring peace on earth. I did not come to bring peace but a sword.", 
                "num": 34
              }, 
              {
                "text": "For I have come to \"set a man against his father, a daughter against her mother, and a daughter-in-law against her mother-in-law';", 
                "num": 35
              }, 
              {
                "text": "and \"a man's enemies will be those of his own household.'", 
                "num": 36
              }, 
              {
                "text": "He who loves father or mother more than Me is not worthy of Me. And he who loves son or daughter more than Me is not worthy of Me.", 
                "num": 37
              }, 
              {
                "text": "And he who does not take his cross and follow after Me is not worthy of Me.", 
                "num": 38
              }, 
              {
                "text": "He who finds his life will lose it, and he who loses his life for My sake will find it.", 
                "num": 39
              }, 
              {
                "text": "\"He who receives you receives Me, and he who receives Me receives Him who sent Me.", 
                "num": 40
              }, 
              {
                "text": "He who receives a prophet in the name of a prophet shall receive a prophet's reward. And he who receives a righteous man in the name of a righteous man shall receive a righteous man's reward.", 
                "num": 41
              }, 
              {
                "text": "And whoever gives one of these little ones only a cup of cold water in the name of a disciple, assuredly, I say to you, he shall by no means lose his reward.\"", 
                "num": 42
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Jesus finished commanding His twelve disciples, that He departed from there to teach and to preach in their cities.", 
                "num": 1
              }, 
              {
                "text": "And when John had heard in prison about the works of Christ, he sent two of his disciples", 
                "num": 2
              }, 
              {
                "text": "and said to Him, \"Are You the Coming One, or do we look for another?\"", 
                "num": 3
              }, 
              {
                "text": "Jesus answered and said to them, \"Go and tell John the things which you hear and see:", 
                "num": 4
              }, 
              {
                "text": "The blind see and the lame walk; the lepers are cleansed and the deaf hear; the dead are raised up and the poor have the gospel preached to them.", 
                "num": 5
              }, 
              {
                "text": "And blessed is he who is not offended because of Me.\"", 
                "num": 6
              }, 
              {
                "text": "As they departed, Jesus began to say to the multitudes concerning John: \"What did you go out into the wilderness to see? A reed shaken by the wind?", 
                "num": 7
              }, 
              {
                "text": "But what did you go out to see? A man clothed in soft garments? Indeed, those who wear soft clothing are in kings' houses.", 
                "num": 8
              }, 
              {
                "text": "But what did you go out to see? A prophet? Yes, I say to you, and more than a prophet.", 
                "num": 9
              }, 
              {
                "text": "For this is he of whom it is written: \"Behold, I send My messenger before Your face, Who will prepare Your way before You.'", 
                "num": 10
              }, 
              {
                "text": "\"Assuredly, I say to you, among those born of women there has not risen one greater than John the Baptist; but he who is least in the kingdom of heaven is greater than he.", 
                "num": 11
              }, 
              {
                "text": "And from the days of John the Baptist until now the kingdom of heaven suffers violence, and the violent take it by force.", 
                "num": 12
              }, 
              {
                "text": "For all the prophets and the law prophesied until John.", 
                "num": 13
              }, 
              {
                "text": "And if you are willing to receive it, he is Elijah who is to come.", 
                "num": 14
              }, 
              {
                "text": "He who has ears to hear, let him hear!", 
                "num": 15
              }, 
              {
                "text": "\"But to what shall I liken this generation? It is like children sitting in the marketplaces and calling to their companions,", 
                "num": 16
              }, 
              {
                "text": "and saying: \"We played the flute for you, And you did not dance; We mourned to you, And you did not lament.'", 
                "num": 17
              }, 
              {
                "text": "For John came neither eating nor drinking, and they say, \"He has a demon.'", 
                "num": 18
              }, 
              {
                "text": "The Son of Man came eating and drinking, and they say, \"Look, a glutton and a winebibber, a friend of tax collectors and sinners!' But wisdom is justified by her children.\"", 
                "num": 19
              }, 
              {
                "text": "Then He began to rebuke the cities in which most of His mighty works had been done, because they did not repent:", 
                "num": 20
              }, 
              {
                "text": "\"Woe to you, Chorazin! Woe to you, Bethsaida! For if the mighty works which were done in you had been done in Tyre and Sidon, they would have repented long ago in sackcloth and ashes.", 
                "num": 21
              }, 
              {
                "text": "But I say to you, it will be more tolerable for Tyre and Sidon in the day of judgment than for you.", 
                "num": 22
              }, 
              {
                "text": "And you, Capernaum, who are exalted to heaven, will be brought down to Hades; for if the mighty works which were done in you had been done in Sodom, it would have remained until this day.", 
                "num": 23
              }, 
              {
                "text": "But I say to you that it shall be more tolerable for the land of Sodom in the day of judgment than for you.\"", 
                "num": 24
              }, 
              {
                "text": "At that time Jesus answered and said, \"I thank You, Father, Lord of heaven and earth, that You have hidden these things from the wise and prudent and have revealed them to babes.", 
                "num": 25
              }, 
              {
                "text": "Even so, Father, for so it seemed good in Your sight.", 
                "num": 26
              }, 
              {
                "text": "All things have been delivered to Me by My Father, and no one knows the Son except the Father. Nor does anyone know the Father except the Son, and the one to whom the Son wills to reveal Him.", 
                "num": 27
              }, 
              {
                "text": "Come to Me, all you who labor and are heavy laden, and I will give you rest.", 
                "num": 28
              }, 
              {
                "text": "Take My yoke upon you and learn from Me, for I am gentle and lowly in heart, and you will find rest for your souls.", 
                "num": 29
              }, 
              {
                "text": "For My yoke is easy and My burden is light.\"", 
                "num": 30
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "At that time Jesus went through the grainfields on the Sabbath. And His disciples were hungry, and began to pluck heads of grain and to eat.", 
                "num": 1
              }, 
              {
                "text": "And when the Pharisees saw it, they said to Him, \"Look, Your disciples are doing what is not lawful to do on the Sabbath!\"", 
                "num": 2
              }, 
              {
                "text": "But He said to them, \"Have you not read what David did when he was hungry, he and those who were with him:", 
                "num": 3
              }, 
              {
                "text": "how he entered the house of God and ate the showbread which was not lawful for him to eat, nor for those who were with him, but only for the priests?", 
                "num": 4
              }, 
              {
                "text": "Or have you not read in the law that on the Sabbath the priests in the temple profane the Sabbath, and are blameless?", 
                "num": 5
              }, 
              {
                "text": "Yet I say to you that in this place there is One greater than the temple.", 
                "num": 6
              }, 
              {
                "text": "But if you had known what this means, \"I desire mercy and not sacrifice,' you would not have condemned the guiltless.", 
                "num": 7
              }, 
              {
                "text": "For the Son of Man is Lord even of the Sabbath.\"", 
                "num": 8
              }, 
              {
                "text": "Now when He had departed from there, He went into their synagogue.", 
                "num": 9
              }, 
              {
                "text": "And behold, there was a man who had a withered hand. And they asked Him, saying, \"Is it lawful to heal on the Sabbath?\"--that they might accuse Him.", 
                "num": 10
              }, 
              {
                "text": "Then He said to them, \"What man is there among you who has one sheep, and if it falls into a pit on the Sabbath, will not lay hold of it and lift it out?", 
                "num": 11
              }, 
              {
                "text": "Of how much more value then is a man than a sheep? Therefore it is lawful to do good on the Sabbath.\"", 
                "num": 12
              }, 
              {
                "text": "Then He said to the man, \"Stretch out your hand.\" And he stretched it out, and it was restored as whole as the other.", 
                "num": 13
              }, 
              {
                "text": "Then the Pharisees went out and plotted against Him, how they might destroy Him.", 
                "num": 14
              }, 
              {
                "text": "But when Jesus knew it, He withdrew from there. And great multitudes followed Him, and He healed them all.", 
                "num": 15
              }, 
              {
                "text": "Yet He warned them not to make Him known,", 
                "num": 16
              }, 
              {
                "text": "that it might be fulfilled which was spoken by Isaiah the prophet, saying:", 
                "num": 17
              }, 
              {
                "text": "\"Behold! My Servant whom I have chosen, My Beloved in whom My soul is well pleased! I will put My Spirit upon Him, And He will declare justice to the Gentiles.", 
                "num": 18
              }, 
              {
                "text": "He will not quarrel nor cry out, Nor will anyone hear His voice in the streets.", 
                "num": 19
              }, 
              {
                "text": "A bruised reed He will not break, And smoking flax He will not quench, Till He sends forth justice to victory;", 
                "num": 20
              }, 
              {
                "text": "And in His name Gentiles will trust.\"", 
                "num": 21
              }, 
              {
                "text": "Then one was brought to Him who was demon-possessed, blind and mute; and He healed him, so that the blind and mute man both spoke and saw.", 
                "num": 22
              }, 
              {
                "text": "And all the multitudes were amazed and said, \"Could this be the Son of David?\"", 
                "num": 23
              }, 
              {
                "text": "Now when the Pharisees heard it they said, \"This fellow does not cast out demons except by Beelzebub, the ruler of the demons.\"", 
                "num": 24
              }, 
              {
                "text": "But Jesus knew their thoughts, and said to them: \"Every kingdom divided against itself is brought to desolation, and every city or house divided against itself will not stand.", 
                "num": 25
              }, 
              {
                "text": "If Satan casts out Satan, he is divided against himself. How then will his kingdom stand?", 
                "num": 26
              }, 
              {
                "text": "And if I cast out demons by Beelzebub, by whom do your sons cast them out? Therefore they shall be your judges.", 
                "num": 27
              }, 
              {
                "text": "But if I cast out demons by the Spirit of God, surely the kingdom of God has come upon you.", 
                "num": 28
              }, 
              {
                "text": "Or how can one enter a strong man's house and plunder his goods, unless he first binds the strong man? And then he will plunder his house.", 
                "num": 29
              }, 
              {
                "text": "He who is not with Me is against Me, and he who does not gather with Me scatters abroad.", 
                "num": 30
              }, 
              {
                "text": "\"Therefore I say to you, every sin and blasphemy will be forgiven men, but the blasphemy against the Spirit will not be forgiven men.", 
                "num": 31
              }, 
              {
                "text": "Anyone who speaks a word against the Son of Man, it will be forgiven him; but whoever speaks against the Holy Spirit, it will not be forgiven him, either in this age or in the age to come.", 
                "num": 32
              }, 
              {
                "text": "\"Either make the tree good and its fruit good, or else make the tree bad and its fruit bad; for a tree is known by its fruit.", 
                "num": 33
              }, 
              {
                "text": "Brood of vipers! How can you, being evil, speak good things? For out of the abundance of the heart the mouth speaks.", 
                "num": 34
              }, 
              {
                "text": "A good man out of the good treasure of his heart brings forth good things, and an evil man out of the evil treasure brings forth evil things.", 
                "num": 35
              }, 
              {
                "text": "But I say to you that for every idle word men may speak, they will give account of it in the day of judgment.", 
                "num": 36
              }, 
              {
                "text": "For by your words you will be justified, and by your words you will be condemned.\"", 
                "num": 37
              }, 
              {
                "text": "Then some of the scribes and Pharisees answered, saying, \"Teacher, we want to see a sign from You.\"", 
                "num": 38
              }, 
              {
                "text": "But He answered and said to them, \"An evil and adulterous generation seeks after a sign, and no sign will be given to it except the sign of the prophet Jonah.", 
                "num": 39
              }, 
              {
                "text": "For as Jonah was three days and three nights in the belly of the great fish, so will the Son of Man be three days and three nights in the heart of the earth.", 
                "num": 40
              }, 
              {
                "text": "The men of Nineveh will rise up in the judgment with this generation and condemn it, because they repented at the preaching of Jonah; and indeed a greater than Jonah is here.", 
                "num": 41
              }, 
              {
                "text": "The queen of the South will rise up in the judgment with this generation and condemn it, for she came from the ends of the earth to hear the wisdom of Solomon; and indeed a greater than Solomon is here.", 
                "num": 42
              }, 
              {
                "text": "\"When an unclean spirit goes out of a man, he goes through dry places, seeking rest, and finds none.", 
                "num": 43
              }, 
              {
                "text": "Then he says, \"I will return to my house from which I came.' And when he comes, he finds it empty, swept, and put in order.", 
                "num": 44
              }, 
              {
                "text": "Then he goes and takes with him seven other spirits more wicked than himself, and they enter and dwell there; and the last state of that man is worse than the first. So shall it also be with this wicked generation.\"", 
                "num": 45
              }, 
              {
                "text": "While He was still talking to the multitudes, behold, His mother and brothers stood outside, seeking to speak with Him.", 
                "num": 46
              }, 
              {
                "text": "Then one said to Him, \"Look, Your mother and Your brothers are standing outside, seeking to speak with You.\"", 
                "num": 47
              }, 
              {
                "text": "But He answered and said to the one who told Him, \"Who is My mother and who are My brothers?\"", 
                "num": 48
              }, 
              {
                "text": "And He stretched out His hand toward His disciples and said, \"Here are My mother and My brothers!", 
                "num": 49
              }, 
              {
                "text": "For whoever does the will of My Father in heaven is My brother and sister and mother.\"", 
                "num": 50
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "On the same day Jesus went out of the house and sat by the sea.", 
                "num": 1
              }, 
              {
                "text": "And great multitudes were gathered together to Him, so that He got into a boat and sat; and the whole multitude stood on the shore.", 
                "num": 2
              }, 
              {
                "text": "Then He spoke many things to them in parables, saying: \"Behold, a sower went out to sow.", 
                "num": 3
              }, 
              {
                "text": "And as he sowed, some seed fell by the wayside; and the birds came and devoured them.", 
                "num": 4
              }, 
              {
                "text": "Some fell on stony places, where they did not have much earth; and they immediately sprang up because they had no depth of earth.", 
                "num": 5
              }, 
              {
                "text": "But when the sun was up they were scorched, and because they had no root they withered away.", 
                "num": 6
              }, 
              {
                "text": "And some fell among thorns, and the thorns sprang up and choked them.", 
                "num": 7
              }, 
              {
                "text": "But others fell on good ground and yielded a crop: some a hundredfold, some sixty, some thirty.", 
                "num": 8
              }, 
              {
                "text": "He who has ears to hear, let him hear!\"", 
                "num": 9
              }, 
              {
                "text": "And the disciples came and said to Him, \"Why do You speak to them in parables?\"", 
                "num": 10
              }, 
              {
                "text": "He answered and said to them, \"Because it has been given to you to know the mysteries of the kingdom of heaven, but to them it has not been given.", 
                "num": 11
              }, 
              {
                "text": "For whoever has, to him more will be given, and he will have abundance; but whoever does not have, even what he has will be taken away from him.", 
                "num": 12
              }, 
              {
                "text": "Therefore I speak to them in parables, because seeing they do not see, and hearing they do not hear, nor do they understand.", 
                "num": 13
              }, 
              {
                "text": "And in them the prophecy of Isaiah is fulfilled, which says: \"Hearing you will hear and shall not understand, And seeing you will see and not perceive;", 
                "num": 14
              }, 
              {
                "text": "For the hearts of this people have grown dull. Their ears are hard of hearing, And their eyes they have closed, Lest they should see with their eyes and hear with their ears, Lest they should understand with their hearts and turn, So that I should heal them.'", 
                "num": 15
              }, 
              {
                "text": "But blessed are your eyes for they see, and your ears for they hear;", 
                "num": 16
              }, 
              {
                "text": "for assuredly, I say to you that many prophets and righteous men desired to see what you see, and did not see it, and to hear what you hear, and did not hear it.", 
                "num": 17
              }, 
              {
                "text": "\"Therefore hear the parable of the sower:", 
                "num": 18
              }, 
              {
                "text": "When anyone hears the word of the kingdom, and does not understand it, then the wicked one comes and snatches away what was sown in his heart. This is he who received seed by the wayside.", 
                "num": 19
              }, 
              {
                "text": "But he who received the seed on stony places, this is he who hears the word and immediately receives it with joy;", 
                "num": 20
              }, 
              {
                "text": "yet he has no root in himself, but endures only for a while. For when tribulation or persecution arises because of the word, immediately he stumbles.", 
                "num": 21
              }, 
              {
                "text": "Now he who received seed among the thorns is he who hears the word, and the cares of this world and the deceitfulness of riches choke the word, and he becomes unfruitful.", 
                "num": 22
              }, 
              {
                "text": "But he who received seed on the good ground is he who hears the word and understands it, who indeed bears fruit and produces: some a hundredfold, some sixty, some thirty.\"", 
                "num": 23
              }, 
              {
                "text": "Another parable He put forth to them, saying: \"The kingdom of heaven is like a man who sowed good seed in his field;", 
                "num": 24
              }, 
              {
                "text": "but while men slept, his enemy came and sowed tares among the wheat and went his way.", 
                "num": 25
              }, 
              {
                "text": "But when the grain had sprouted and produced a crop, then the tares also appeared.", 
                "num": 26
              }, 
              {
                "text": "So the servants of the owner came and said to him, \"Sir, did you not sow good seed in your field? How then does it have tares?'", 
                "num": 27
              }, 
              {
                "text": "He said to them, \"An enemy has done this.' The servants said to him, \"Do you want us then to go and gather them up?'", 
                "num": 28
              }, 
              {
                "text": "But he said, \"No, lest while you gather up the tares you also uproot the wheat with them.", 
                "num": 29
              }, 
              {
                "text": "Let both grow together until the harvest, and at the time of harvest I will say to the reapers, \"First gather together the tares and bind them in bundles to burn them, but gather the wheat into my barn.\"\"'", 
                "num": 30
              }, 
              {
                "text": "Another parable He put forth to them, saying: \"The kingdom of heaven is like a mustard seed, which a man took and sowed in his field,", 
                "num": 31
              }, 
              {
                "text": "which indeed is the least of all the seeds; but when it is grown it is greater than the herbs and becomes a tree, so that the birds of the air come and nest in its branches.\"", 
                "num": 32
              }, 
              {
                "text": "Another parable He spoke to them: \"The kingdom of heaven is like leaven, which a woman took and hid in three measures of meal till it was all leavened.\"", 
                "num": 33
              }, 
              {
                "text": "All these things Jesus spoke to the multitude in parables; and without a parable He did not speak to them,", 
                "num": 34
              }, 
              {
                "text": "that it might be fulfilled which was spoken by the prophet, saying: \"I will open My mouth in parables; I will utter things kept secret from the foundation of the world.\"", 
                "num": 35
              }, 
              {
                "text": "Then Jesus sent the multitude away and went into the house. And His disciples came to Him, saying, \"Explain to us the parable of the tares of the field.\"", 
                "num": 36
              }, 
              {
                "text": "He answered and said to them: \"He who sows the good seed is the Son of Man.", 
                "num": 37
              }, 
              {
                "text": "The field is the world, the good seeds are the sons of the kingdom, but the tares are the sons of the wicked one.", 
                "num": 38
              }, 
              {
                "text": "The enemy who sowed them is the devil, the harvest is the end of the age, and the reapers are the angels.", 
                "num": 39
              }, 
              {
                "text": "Therefore as the tares are gathered and burned in the fire, so it will be at the end of this age.", 
                "num": 40
              }, 
              {
                "text": "The Son of Man will send out His angels, and they will gather out of His kingdom all things that offend, and those who practice lawlessness,", 
                "num": 41
              }, 
              {
                "text": "and will cast them into the furnace of fire. There will be wailing and gnashing of teeth.", 
                "num": 42
              }, 
              {
                "text": "Then the righteous will shine forth as the sun in the kingdom of their Father. He who has ears to hear, let him hear!", 
                "num": 43
              }, 
              {
                "text": "\"Again, the kingdom of heaven is like treasure hidden in a field, which a man found and hid; and for joy over it he goes and sells all that he has and buys that field.", 
                "num": 44
              }, 
              {
                "text": "\"Again, the kingdom of heaven is like a merchant seeking beautiful pearls,", 
                "num": 45
              }, 
              {
                "text": "who, when he had found one pearl of great price, went and sold all that he had and bought it.", 
                "num": 46
              }, 
              {
                "text": "\"Again, the kingdom of heaven is like a dragnet that was cast into the sea and gathered some of every kind,", 
                "num": 47
              }, 
              {
                "text": "which, when it was full, they drew to shore; and they sat down and gathered the good into vessels, but threw the bad away.", 
                "num": 48
              }, 
              {
                "text": "So it will be at the end of the age. The angels will come forth, separate the wicked from among the just,", 
                "num": 49
              }, 
              {
                "text": "and cast them into the furnace of fire. There will be wailing and gnashing of teeth.\"", 
                "num": 50
              }, 
              {
                "text": "Jesus said to them, \"Have you understood all these things?\" They said to Him, \"Yes, Lord.\"", 
                "num": 51
              }, 
              {
                "text": "Then He said to them, \"Therefore every scribe instructed concerning the kingdom of heaven is like a householder who brings out of his treasure things new and old.\"", 
                "num": 52
              }, 
              {
                "text": "Now it came to pass, when Jesus had finished these parables, that He departed from there.", 
                "num": 53
              }, 
              {
                "text": "When He had come to His own country, He taught them in their synagogue, so that they were astonished and said, \"Where did this Man get this wisdom and these mighty works?", 
                "num": 54
              }, 
              {
                "text": "Is this not the carpenter's son? Is not His mother called Mary? And His brothers James, Joses, Simon, and Judas?", 
                "num": 55
              }, 
              {
                "text": "And His sisters, are they not all with us? Where then did this Man get all these things?\"", 
                "num": 56
              }, 
              {
                "text": "So they were offended at Him. But Jesus said to them, \"A prophet is not without honor except in his own country and in his own house.\"", 
                "num": 57
              }, 
              {
                "text": "Now He did not do many mighty works there because of their unbelief.", 
                "num": 58
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "At that time Herod the tetrarch heard the report about Jesus", 
                "num": 1
              }, 
              {
                "text": "and said to his servants, \"This is John the Baptist; he is risen from the dead, and therefore these powers are at work in him.\"", 
                "num": 2
              }, 
              {
                "text": "For Herod had laid hold of John and bound him, and put him in prison for the sake of Herodias, his brother Philip's wife.", 
                "num": 3
              }, 
              {
                "text": "Because John had said to him, \"It is not lawful for you to have her.\"", 
                "num": 4
              }, 
              {
                "text": "And although he wanted to put him to death, he feared the multitude, because they counted him as a prophet.", 
                "num": 5
              }, 
              {
                "text": "But when Herod's birthday was celebrated, the daughter of Herodias danced before them and pleased Herod.", 
                "num": 6
              }, 
              {
                "text": "Therefore he promised with an oath to give her whatever she might ask.", 
                "num": 7
              }, 
              {
                "text": "So she, having been prompted by her mother, said, \"Give me John the Baptist's head here on a platter.\"", 
                "num": 8
              }, 
              {
                "text": "And the king was sorry; nevertheless, because of the oaths and because of those who sat with him, he commanded it to be given to her.", 
                "num": 9
              }, 
              {
                "text": "So he sent and had John beheaded in prison.", 
                "num": 10
              }, 
              {
                "text": "And his head was brought on a platter and given to the girl, and she brought it to her mother.", 
                "num": 11
              }, 
              {
                "text": "Then his disciples came and took away the body and buried it, and went and told Jesus.", 
                "num": 12
              }, 
              {
                "text": "When Jesus heard it, He departed from there by boat to a deserted place by Himself. But when the multitudes heard it, they followed Him on foot from the cities.", 
                "num": 13
              }, 
              {
                "text": "And when Jesus went out He saw a great multitude; and He was moved with compassion for them, and healed their sick.", 
                "num": 14
              }, 
              {
                "text": "When it was evening, His disciples came to Him, saying, \"This is a deserted place, and the hour is already late. Send the multitudes away, that they may go into the villages and buy themselves food.\"", 
                "num": 15
              }, 
              {
                "text": "But Jesus said to them, \"They do not need to go away. You give them something to eat.\"", 
                "num": 16
              }, 
              {
                "text": "And they said to Him, \"We have here only five loaves and two fish.\"", 
                "num": 17
              }, 
              {
                "text": "He said, \"Bring them here to Me.\"", 
                "num": 18
              }, 
              {
                "text": "Then He commanded the multitudes to sit down on the grass. And He took the five loaves and the two fish, and looking up to heaven, He blessed and broke and gave the loaves to the disciples; and the disciples gave to the multitudes.", 
                "num": 19
              }, 
              {
                "text": "So they all ate and were filled, and they took up twelve baskets full of the fragments that remained.", 
                "num": 20
              }, 
              {
                "text": "Now those who had eaten were about five thousand men, besides women and children.", 
                "num": 21
              }, 
              {
                "text": "Immediately Jesus made His disciples get into the boat and go before Him to the other side, while He sent the multitudes away.", 
                "num": 22
              }, 
              {
                "text": "And when He had sent the multitudes away, He went up on the mountain by Himself to pray. Now when evening came, He was alone there.", 
                "num": 23
              }, 
              {
                "text": "But the boat was now in the middle of the sea, tossed by the waves, for the wind was contrary.", 
                "num": 24
              }, 
              {
                "text": "Now in the fourth watch of the night Jesus went to them, walking on the sea.", 
                "num": 25
              }, 
              {
                "text": "And when the disciples saw Him walking on the sea, they were troubled, saying, \"It is a ghost!\" And they cried out for fear.", 
                "num": 26
              }, 
              {
                "text": "But immediately Jesus spoke to them, saying, \"Be of good cheer! It is I; do not be afraid.\"", 
                "num": 27
              }, 
              {
                "text": "And Peter answered Him and said, \"Lord, if it is You, command me to come to You on the water.\"", 
                "num": 28
              }, 
              {
                "text": "So He said, \"Come.\" And when Peter had come down out of the boat, he walked on the water to go to Jesus.", 
                "num": 29
              }, 
              {
                "text": "But when he saw that the wind was boisterous, he was afraid; and beginning to sink he cried out, saying, \"Lord, save me!\"", 
                "num": 30
              }, 
              {
                "text": "And immediately Jesus stretched out His hand and caught him, and said to him, \"O you of little faith, why did you doubt?\"", 
                "num": 31
              }, 
              {
                "text": "And when they got into the boat, the wind ceased.", 
                "num": 32
              }, 
              {
                "text": "Then those who were in the boat came and worshiped Him, saying, \"Truly You are the Son of God.\"", 
                "num": 33
              }, 
              {
                "text": "When they had crossed over, they came to the land of Gennesaret.", 
                "num": 34
              }, 
              {
                "text": "And when the men of that place recognized Him, they sent out into all that surrounding region, brought to Him all who were sick,", 
                "num": 35
              }, 
              {
                "text": "and begged Him that they might only touch the hem of His garment. And as many as touched it were made perfectly well.", 
                "num": 36
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then the scribes and Pharisees who were from Jerusalem came to Jesus, saying,", 
                "num": 1
              }, 
              {
                "text": "\"Why do Your disciples transgress the tradition of the elders? For they do not wash their hands when they eat bread.\"", 
                "num": 2
              }, 
              {
                "text": "He answered and said to them, \"Why do you also transgress the commandment of God because of your tradition?", 
                "num": 3
              }, 
              {
                "text": "For God commanded, saying, \"Honor your father and your mother'; and, \"He who curses father or mother, let him be put to death.'", 
                "num": 4
              }, 
              {
                "text": "But you say, \"Whoever says to his father or mother, \"Whatever profit you might have received from me is a gift to God\"--", 
                "num": 5
              }, 
              {
                "text": "then he need not honor his father or mother.' Thus you have made the commandment of God of no effect by your tradition.", 
                "num": 6
              }, 
              {
                "text": "Hypocrites! Well did Isaiah prophesy about you, saying:", 
                "num": 7
              }, 
              {
                "text": "\"These people draw near to Me with their mouth, And honor Me with their lips, But their heart is far from Me.", 
                "num": 8
              }, 
              {
                "text": "And in vain they worship Me, Teaching as doctrines the commandments of men.\"'", 
                "num": 9
              }, 
              {
                "text": "When He had called the multitude to Himself, He said to them, \"Hear and understand:", 
                "num": 10
              }, 
              {
                "text": "Not what goes into the mouth defiles a man; but what comes out of the mouth, this defiles a man.\"", 
                "num": 11
              }, 
              {
                "text": "Then His disciples came and said to Him, \"Do You know that the Pharisees were offended when they heard this saying?\"", 
                "num": 12
              }, 
              {
                "text": "But He answered and said, \"Every plant which My heavenly Father has not planted will be uprooted.", 
                "num": 13
              }, 
              {
                "text": "Let them alone. They are blind leaders of the blind. And if the blind leads the blind, both will fall into a ditch.\"", 
                "num": 14
              }, 
              {
                "text": "Then Peter answered and said to Him, \"Explain this parable to us.\"", 
                "num": 15
              }, 
              {
                "text": "So Jesus said, \"Are you also still without understanding?", 
                "num": 16
              }, 
              {
                "text": "Do you not yet understand that whatever enters the mouth goes into the stomach and is eliminated?", 
                "num": 17
              }, 
              {
                "text": "But those things which proceed out of the mouth come from the heart, and they defile a man.", 
                "num": 18
              }, 
              {
                "text": "For out of the heart proceed evil thoughts, murders, adulteries, fornications, thefts, false witness, blasphemies.", 
                "num": 19
              }, 
              {
                "text": "These are the things which defile a man, but to eat with unwashed hands does not defile a man.\"", 
                "num": 20
              }, 
              {
                "text": "Then Jesus went out from there and departed to the region of Tyre and Sidon.", 
                "num": 21
              }, 
              {
                "text": "And behold, a woman of Canaan came from that region and cried out to Him, saying, \"Have mercy on me, O Lord, Son of David! My daughter is severely demon-possessed.\"", 
                "num": 22
              }, 
              {
                "text": "But He answered her not a word. And His disciples came and urged Him, saying, \"Send her away, for she cries out after us.\"", 
                "num": 23
              }, 
              {
                "text": "But He answered and said, \"I was not sent except to the lost sheep of the house of Israel.\"", 
                "num": 24
              }, 
              {
                "text": "Then she came and worshiped Him, saying, \"Lord, help me!\"", 
                "num": 25
              }, 
              {
                "text": "But He answered and said, \"It is not good to take the children's bread and throw it to the little dogs.\"", 
                "num": 26
              }, 
              {
                "text": "And she said, \"Yes, Lord, yet even the little dogs eat the crumbs which fall from their masters' table.\"", 
                "num": 27
              }, 
              {
                "text": "Then Jesus answered and said to her, \"O woman, great is your faith! Let it be to you as you desire.\" And her daughter was healed from that very hour.", 
                "num": 28
              }, 
              {
                "text": "Jesus departed from there, skirted the Sea of Galilee, and went up on the mountain and sat down there.", 
                "num": 29
              }, 
              {
                "text": "Then great multitudes came to Him, having with them the lame, blind, mute, maimed, and many others; and they laid them down at Jesus' feet, and He healed them.", 
                "num": 30
              }, 
              {
                "text": "So the multitude marveled when they saw the mute speaking, the maimed made whole, the lame walking, and the blind seeing; and they glorified the God of Israel.", 
                "num": 31
              }, 
              {
                "text": "Now Jesus called His disciples to Himself and said, \"I have compassion on the multitude, because they have now continued with Me three days and have nothing to eat. And I do not want to send them away hungry, lest they faint on the way.\"", 
                "num": 32
              }, 
              {
                "text": "Then His disciples said to Him, \"Where could we get enough bread in the wilderness to fill such a great multitude?\"", 
                "num": 33
              }, 
              {
                "text": "Jesus said to them, \"How many loaves do you have?\" And they said, \"Seven, and a few little fish.\"", 
                "num": 34
              }, 
              {
                "text": "So He commanded the multitude to sit down on the ground.", 
                "num": 35
              }, 
              {
                "text": "And He took the seven loaves and the fish and gave thanks, broke them and gave them to His disciples; and the disciples gave to the multitude.", 
                "num": 36
              }, 
              {
                "text": "So they all ate and were filled, and they took up seven large baskets full of the fragments that were left.", 
                "num": 37
              }, 
              {
                "text": "Now those who ate were four thousand men, besides women and children.", 
                "num": 38
              }, 
              {
                "text": "And He sent away the multitude, got into the boat, and came to the region of Magdala.", 
                "num": 39
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Then the Pharisees and Sadducees came, and testing Him asked that He would show them a sign from heaven.", 
                "num": 1
              }, 
              {
                "text": "He answered and said to them, \"When it is evening you say, \"It will be fair weather, for the sky is red';", 
                "num": 2
              }, 
              {
                "text": "and in the morning, \"It will be foul weather today, for the sky is red and threatening.' Hypocrites! You know how to discern the face of the sky, but you cannot discern the signs of the times.", 
                "num": 3
              }, 
              {
                "text": "A wicked and adulterous generation seeks after a sign, and no sign shall be given to it except the sign of the prophet Jonah.\" And He left them and departed.", 
                "num": 4
              }, 
              {
                "text": "Now when His disciples had come to the other side, they had forgotten to take bread.", 
                "num": 5
              }, 
              {
                "text": "Then Jesus said to them, \"Take heed and beware of the leaven of the Pharisees and the Sadducees.\"", 
                "num": 6
              }, 
              {
                "text": "And they reasoned among themselves, saying, \"It is because we have taken no bread.\"", 
                "num": 7
              }, 
              {
                "text": "But Jesus, being aware of it, said to them, \"O you of little faith, why do you reason among yourselves because you have brought no bread?", 
                "num": 8
              }, 
              {
                "text": "Do you not yet understand, or remember the five loaves of the five thousand and how many baskets you took up?", 
                "num": 9
              }, 
              {
                "text": "Nor the seven loaves of the four thousand and how many large baskets you took up?", 
                "num": 10
              }, 
              {
                "text": "How is it you do not understand that I did not speak to you concerning bread?--but to beware of the leaven of the Pharisees and Sadducees.\"", 
                "num": 11
              }, 
              {
                "text": "Then they understood that He did not tell them to beware of the leaven of bread, but of the doctrine of the Pharisees and Sadducees.", 
                "num": 12
              }, 
              {
                "text": "When Jesus came into the region of Caesarea Philippi, He asked His disciples, saying, \"Who do men say that I, the Son of Man, am?\"", 
                "num": 13
              }, 
              {
                "text": "So they said, \"Some say John the Baptist, some Elijah, and others Jeremiah or one of the prophets.\"", 
                "num": 14
              }, 
              {
                "text": "He said to them, \"But who do you say that I am?\"", 
                "num": 15
              }, 
              {
                "text": "Simon Peter answered and said, \"You are the Christ, the Son of the living God.\"", 
                "num": 16
              }, 
              {
                "text": "Jesus answered and said to him, \"Blessed are you, Simon Bar-Jonah, for flesh and blood has not revealed this to you, but My Father who is in heaven.", 
                "num": 17
              }, 
              {
                "text": "And I also say to you that you are Peter, and on this rock I will build My church, and the gates of Hades shall not prevail against it.", 
                "num": 18
              }, 
              {
                "text": "And I will give you the keys of the kingdom of heaven, and whatever you bind on earth will be bound in heaven, and whatever you loose on earth will be loosed in heaven.\"", 
                "num": 19
              }, 
              {
                "text": "Then He commanded His disciples that they should tell no one that He was Jesus the Christ.", 
                "num": 20
              }, 
              {
                "text": "From that time Jesus began to show to His disciples that He must go to Jerusalem, and suffer many things from the elders and chief priests and scribes, and be killed, and be raised the third day.", 
                "num": 21
              }, 
              {
                "text": "Then Peter took Him aside and began to rebuke Him, saying, \"Far be it from You, Lord; this shall not happen to You!\"", 
                "num": 22
              }, 
              {
                "text": "But He turned and said to Peter, \"Get behind Me, Satan! You are an offense to Me, for you are not mindful of the things of God, but the things of men.\"", 
                "num": 23
              }, 
              {
                "text": "Then Jesus said to His disciples, \"If anyone desires to come after Me, let him deny himself, and take up his cross, and follow Me.", 
                "num": 24
              }, 
              {
                "text": "For whoever desires to save his life will lose it, but whoever loses his life for My sake will find it.", 
                "num": 25
              }, 
              {
                "text": "For what profit is it to a man if he gains the whole world, and loses his own soul? Or what will a man give in exchange for his soul?", 
                "num": 26
              }, 
              {
                "text": "For the Son of Man will come in the glory of His Father with His angels, and then He will reward each according to his works.", 
                "num": 27
              }, 
              {
                "text": "Assuredly, I say to you, there are some standing here who shall not taste death till they see the Son of Man coming in His kingdom.\"", 
                "num": 28
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Now after six days Jesus took Peter, James, and John his brother, led them up on a high mountain by themselves;", 
                "num": 1
              }, 
              {
                "text": "and He was transfigured before them. His face shone like the sun, and His clothes became as white as the light.", 
                "num": 2
              }, 
              {
                "text": "And behold, Moses and Elijah appeared to them, talking with Him.", 
                "num": 3
              }, 
              {
                "text": "Then Peter answered and said to Jesus, \"Lord, it is good for us to be here; if You wish, let us make here three tabernacles: one for You, one for Moses, and one for Elijah.\"", 
                "num": 4
              }, 
              {
                "text": "While he was still speaking, behold, a bright cloud overshadowed them; and suddenly a voice came out of the cloud, saying, \"This is My beloved Son, in whom I am well pleased. Hear Him!\"", 
                "num": 5
              }, 
              {
                "text": "And when the disciples heard it, they fell on their faces and were greatly afraid.", 
                "num": 6
              }, 
              {
                "text": "But Jesus came and touched them and said, \"Arise, and do not be afraid.\"", 
                "num": 7
              }, 
              {
                "text": "When they had lifted up their eyes, they saw no one but Jesus only.", 
                "num": 8
              }, 
              {
                "text": "Now as they came down from the mountain, Jesus commanded them, saying, \"Tell the vision to no one until the Son of Man is risen from the dead.\"", 
                "num": 9
              }, 
              {
                "text": "And His disciples asked Him, saying, \"Why then do the scribes say that Elijah must come first?\"", 
                "num": 10
              }, 
              {
                "text": "Jesus answered and said to them, \"Indeed, Elijah is coming first and will restore all things.", 
                "num": 11
              }, 
              {
                "text": "But I say to you that Elijah has come already, and they did not know him but did to him whatever they wished. Likewise the Son of Man is also about to suffer at their hands.\"", 
                "num": 12
              }, 
              {
                "text": "Then the disciples understood that He spoke to them of John the Baptist.", 
                "num": 13
              }, 
              {
                "text": "And when they had come to the multitude, a man came to Him, kneeling down to Him and saying,", 
                "num": 14
              }, 
              {
                "text": "\"Lord, have mercy on my son, for he is an epileptic and suffers severely; for he often falls into the fire and often into the water.", 
                "num": 15
              }, 
              {
                "text": "So I brought him to Your disciples, but they could not cure him.\"", 
                "num": 16
              }, 
              {
                "text": "Then Jesus answered and said, \"O faithless and perverse generation, how long shall I be with you? How long shall I bear with you? Bring him here to Me.\"", 
                "num": 17
              }, 
              {
                "text": "And Jesus rebuked the demon, and it came out of him; and the child was cured from that very hour.", 
                "num": 18
              }, 
              {
                "text": "Then the disciples came to Jesus privately and said, \"Why could we not cast it out?\"", 
                "num": 19
              }, 
              {
                "text": "So Jesus said to them, \"Because of your unbelief; for assuredly, I say to you, if you have faith as a mustard seed, you will say to this mountain, \"Move from here to there,' and it will move; and nothing will be impossible for you.", 
                "num": 20
              }, 
              {
                "text": "However, this kind does not go out except by prayer and fasting.\"", 
                "num": 21
              }, 
              {
                "text": "Now while they were staying in Galilee, Jesus said to them, \"The Son of Man is about to be betrayed into the hands of men,", 
                "num": 22
              }, 
              {
                "text": "and they will kill Him, and the third day He will be raised up.\" And they were exceedingly sorrowful.", 
                "num": 23
              }, 
              {
                "text": "When they had come to Capernaum, those who received the temple tax came to Peter and said, \"Does your Teacher not pay the temple tax?\"", 
                "num": 24
              }, 
              {
                "text": "He said, \"Yes.\" And when he had come into the house, Jesus anticipated him, saying, \"What do you think, Simon? From whom do the kings of the earth take customs or taxes, from their sons or from strangers?\"", 
                "num": 25
              }, 
              {
                "text": "Peter said to Him, \"From strangers.\" Jesus said to him, \"Then the sons are free.", 
                "num": 26
              }, 
              {
                "text": "Nevertheless, lest we offend them, go to the sea, cast in a hook, and take the fish that comes up first. And when you have opened its mouth, you will find a piece of money; take that and give it to them for Me and you.\"", 
                "num": 27
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "At that time the disciples came to Jesus, saying, \"Who then is greatest in the kingdom of heaven?\"", 
                "num": 1
              }, 
              {
                "text": "Then Jesus called a little child to Him, set him in the midst of them,", 
                "num": 2
              }, 
              {
                "text": "and said, \"Assuredly, I say to you, unless you are converted and become as little children, you will by no means enter the kingdom of heaven.", 
                "num": 3
              }, 
              {
                "text": "Therefore whoever humbles himself as this little child is the greatest in the kingdom of heaven.", 
                "num": 4
              }, 
              {
                "text": "Whoever receives one little child like this in My name receives Me.", 
                "num": 5
              }, 
              {
                "text": "\"Whoever causes one of these little ones who believe in Me to sin, it would be better for him if a millstone were hung around his neck, and he were drowned in the depth of the sea.", 
                "num": 6
              }, 
              {
                "text": "Woe to the world because of offenses! For offenses must come, but woe to that man by whom the offense comes!", 
                "num": 7
              }, 
              {
                "text": "\"If your hand or foot causes you to sin, cut it off and cast it from you. It is better for you to enter into life lame or maimed, rather than having two hands or two feet, to be cast into the everlasting fire.", 
                "num": 8
              }, 
              {
                "text": "And if your eye causes you to sin, pluck it out and cast it from you. It is better for you to enter into life with one eye, rather than having two eyes, to be cast into hell fire.", 
                "num": 9
              }, 
              {
                "text": "\"Take heed that you do not despise one of these little ones, for I say to you that in heaven their angels always see the face of My Father who is in heaven.", 
                "num": 10
              }, 
              {
                "text": "For the Son of Man has come to save that which was lost.", 
                "num": 11
              }, 
              {
                "text": "\"What do you think? If a man has a hundred sheep, and one of them goes astray, does he not leave the ninety-nine and go to the mountains to seek the one that is straying?", 
                "num": 12
              }, 
              {
                "text": "And if he should find it, assuredly, I say to you, he rejoices more over that sheep than over the ninety-nine that did not go astray.", 
                "num": 13
              }, 
              {
                "text": "Even so it is not the will of your Father who is in heaven that one of these little ones should perish.", 
                "num": 14
              }, 
              {
                "text": "\"Moreover if your brother sins against you, go and tell him his fault between you and him alone. If he hears you, you have gained your brother.", 
                "num": 15
              }, 
              {
                "text": "But if he will not hear, take with you one or two more, that \"by the mouth of two or three witnesses every word may be established.'", 
                "num": 16
              }, 
              {
                "text": "And if he refuses to hear them, tell it to the church. But if he refuses even to hear the church, let him be to you like a heathen and a tax collector.", 
                "num": 17
              }, 
              {
                "text": "\"Assuredly, I say to you, whatever you bind on earth will be bound in heaven, and whatever you loose on earth will be loosed in heaven.", 
                "num": 18
              }, 
              {
                "text": "\"Again I say to you that if two of you agree on earth concerning anything that they ask, it will be done for them by My Father in heaven.", 
                "num": 19
              }, 
              {
                "text": "For where two or three are gathered together in My name, I am there in the midst of them.\"", 
                "num": 20
              }, 
              {
                "text": "Then Peter came to Him and said, \"Lord, how often shall my brother sin against me, and I forgive him? Up to seven times?\"", 
                "num": 21
              }, 
              {
                "text": "Jesus said to him, \"I do not say to you, up to seven times, but up to seventy times seven.", 
                "num": 22
              }, 
              {
                "text": "Therefore the kingdom of heaven is like a certain king who wanted to settle accounts with his servants.", 
                "num": 23
              }, 
              {
                "text": "And when he had begun to settle accounts, one was brought to him who owed him ten thousand talents.", 
                "num": 24
              }, 
              {
                "text": "But as he was not able to pay, his master commanded that he be sold, with his wife and children and all that he had, and that payment be made.", 
                "num": 25
              }, 
              {
                "text": "The servant therefore fell down before him, saying, \"Master, have patience with me, and I will pay you all.'", 
                "num": 26
              }, 
              {
                "text": "Then the master of that servant was moved with compassion, released him, and forgave him the debt.", 
                "num": 27
              }, 
              {
                "text": "\"But that servant went out and found one of his fellow servants who owed him a hundred denarii; and he laid hands on him and took him by the throat, saying, \"Pay me what you owe!'", 
                "num": 28
              }, 
              {
                "text": "So his fellow servant fell down at his feet and begged him, saying, \"Have patience with me, and I will pay you all.'", 
                "num": 29
              }, 
              {
                "text": "And he would not, but went and threw him into prison till he should pay the debt.", 
                "num": 30
              }, 
              {
                "text": "So when his fellow servants saw what had been done, they were very grieved, and came and told their master all that had been done.", 
                "num": 31
              }, 
              {
                "text": "Then his master, after he had called him, said to him, \"You wicked servant! I forgave you all that debt because you begged me.", 
                "num": 32
              }, 
              {
                "text": "Should you not also have had compassion on your fellow servant, just as I had pity on you?'", 
                "num": 33
              }, 
              {
                "text": "And his master was angry, and delivered him to the torturers until he should pay all that was due to him.", 
                "num": 34
              }, 
              {
                "text": "\"So My heavenly Father also will do to you if each of you, from his heart, does not forgive his brother his trespasses.\"", 
                "num": 35
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Jesus had finished these sayings, that He departed from Galilee and came to the region of Judea beyond the Jordan.", 
                "num": 1
              }, 
              {
                "text": "And great multitudes followed Him, and He healed them there.", 
                "num": 2
              }, 
              {
                "text": "The Pharisees also came to Him, testing Him, and saying to Him, \"Is it lawful for a man to divorce his wife for just any reason?\"", 
                "num": 3
              }, 
              {
                "text": "And He answered and said to them, \"Have you not read that He who made them at the beginning \"made them male and female,'", 
                "num": 4
              }, 
              {
                "text": "and said, \"For this reason a man shall leave his father and mother and be joined to his wife, and the two shall become one flesh'?", 
                "num": 5
              }, 
              {
                "text": "So then, they are no longer two but one flesh. Therefore what God has joined together, let not man separate.\"", 
                "num": 6
              }, 
              {
                "text": "They said to Him, \"Why then did Moses command to give a certificate of divorce, and to put her away?\"", 
                "num": 7
              }, 
              {
                "text": "He said to them, \"Moses, because of the hardness of your hearts, permitted you to divorce your wives, but from the beginning it was not so.", 
                "num": 8
              }, 
              {
                "text": "And I say to you, whoever divorces his wife, except for sexual immorality, and marries another, commits adultery; and whoever marries her who is divorced commits adultery.\"", 
                "num": 9
              }, 
              {
                "text": "His disciples said to Him, \"If such is the case of the man with his wife, it is better not to marry.\"", 
                "num": 10
              }, 
              {
                "text": "But He said to them, \"All cannot accept this saying, but only those to whom it has been given:", 
                "num": 11
              }, 
              {
                "text": "For there are eunuchs who were born thus from their mother's womb, and there are eunuchs who were made eunuchs by men, and there are eunuchs who have made themselves eunuchs for the kingdom of heaven's sake. He who is able to accept it, let him accept it.\"", 
                "num": 12
              }, 
              {
                "text": "Then little children were brought to Him that He might put His hands on them and pray, but the disciples rebuked them.", 
                "num": 13
              }, 
              {
                "text": "But Jesus said, \"Let the little children come to Me, and do not forbid them; for of such is the kingdom of heaven.\"", 
                "num": 14
              }, 
              {
                "text": "And He laid His hands on them and departed from there.", 
                "num": 15
              }, 
              {
                "text": "Now behold, one came and said to Him, \"Good Teacher, what good thing shall I do that I may have eternal life?\"", 
                "num": 16
              }, 
              {
                "text": "So He said to him, \"Why do you call Me good? No one is good but One, that is, God. But if you want to enter into life, keep the commandments.\"", 
                "num": 17
              }, 
              {
                "text": "He said to Him, \"Which ones?\" Jesus said, \"\"You shall not murder,' \"You shall not commit adultery,' \"You shall not steal,' \"You shall not bear false witness,'", 
                "num": 18
              }, 
              {
                "text": "\"Honor your father and your mother,' and, \"You shall love your neighbor as yourself.\"'", 
                "num": 19
              }, 
              {
                "text": "The young man said to Him, \"All these things I have kept from my youth. What do I still lack?\"", 
                "num": 20
              }, 
              {
                "text": "Jesus said to him, \"If you want to be perfect, go, sell what you have and give to the poor, and you will have treasure in heaven; and come, follow Me.\"", 
                "num": 21
              }, 
              {
                "text": "But when the young man heard that saying, he went away sorrowful, for he had great possessions.", 
                "num": 22
              }, 
              {
                "text": "Then Jesus said to His disciples, \"Assuredly, I say to you that it is hard for a rich man to enter the kingdom of heaven.", 
                "num": 23
              }, 
              {
                "text": "And again I say to you, it is easier for a camel to go through the eye of a needle than for a rich man to enter the kingdom of God.\"", 
                "num": 24
              }, 
              {
                "text": "When His disciples heard it, they were greatly astonished, saying, \"Who then can be saved?\"", 
                "num": 25
              }, 
              {
                "text": "But Jesus looked at them and said to them, \"With men this is impossible, but with God all things are possible.\"", 
                "num": 26
              }, 
              {
                "text": "Then Peter answered and said to Him, \"See, we have left all and followed You. Therefore what shall we have?\"", 
                "num": 27
              }, 
              {
                "text": "So Jesus said to them, \"Assuredly I say to you, that in the regeneration, when the Son of Man sits on the throne of His glory, you who have followed Me will also sit on twelve thrones, judging the twelve tribes of Israel.", 
                "num": 28
              }, 
              {
                "text": "And everyone who has left houses or brothers or sisters or father or mother or wife or children or lands, for My name's sake, shall receive a hundredfold, and inherit eternal life.", 
                "num": 29
              }, 
              {
                "text": "But many who are first will be last, and the last first.", 
                "num": 30
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "\"For the kingdom of heaven is like a landowner who went out early in the morning to hire laborers for his vineyard.", 
                "num": 1
              }, 
              {
                "text": "Now when he had agreed with the laborers for a denarius a day, he sent them into his vineyard.", 
                "num": 2
              }, 
              {
                "text": "And he went out about the third hour and saw others standing idle in the marketplace,", 
                "num": 3
              }, 
              {
                "text": "and said to them, \"You also go into the vineyard, and whatever is right I will give you.' So they went.", 
                "num": 4
              }, 
              {
                "text": "Again he went out about the sixth and the ninth hour, and did likewise.", 
                "num": 5
              }, 
              {
                "text": "And about the eleventh hour he went out and found others standing idle, and said to them, \"Why have you been standing here idle all day?'", 
                "num": 6
              }, 
              {
                "text": "They said to him, \"Because no one hired us.' He said to them, \"You also go into the vineyard, and whatever is right you will receive.'", 
                "num": 7
              }, 
              {
                "text": "\"So when evening had come, the owner of the vineyard said to his steward, \"Call the laborers and give them their wages, beginning with the last to the first.'", 
                "num": 8
              }, 
              {
                "text": "And when those came who were hired about the eleventh hour, they each received a denarius.", 
                "num": 9
              }, 
              {
                "text": "But when the first came, they supposed that they would receive more; and they likewise received each a denarius.", 
                "num": 10
              }, 
              {
                "text": "And when they had received it, they complained against the landowner,", 
                "num": 11
              }, 
              {
                "text": "saying, \"These last men have worked only one hour, and you made them equal to us who have borne the burden and the heat of the day.'", 
                "num": 12
              }, 
              {
                "text": "But he answered one of them and said, \"Friend, I am doing you no wrong. Did you not agree with me for a denarius?", 
                "num": 13
              }, 
              {
                "text": "Take what is yours and go your way. I wish to give to this last man the same as to you.", 
                "num": 14
              }, 
              {
                "text": "Is it not lawful for me to do what I wish with my own things? Or is your eye evil because I am good?'", 
                "num": 15
              }, 
              {
                "text": "So the last will be first, and the first last. For many are called, but few chosen.\"", 
                "num": 16
              }, 
              {
                "text": "Now Jesus, going up to Jerusalem, took the twelve disciples aside on the road and said to them,", 
                "num": 17
              }, 
              {
                "text": "\"Behold, we are going up to Jerusalem, and the Son of Man will be betrayed to the chief priests and to the scribes; and they will condemn Him to death,", 
                "num": 18
              }, 
              {
                "text": "and deliver Him to the Gentiles to mock and to scourge and to crucify. And the third day He will rise again.\"", 
                "num": 19
              }, 
              {
                "text": "Then the mother of Zebedee's sons came to Him with her sons, kneeling down and asking something from Him.", 
                "num": 20
              }, 
              {
                "text": "And He said to her, \"What do you wish?\" She said to Him, \"Grant that these two sons of mine may sit, one on Your right hand and the other on the left, in Your kingdom.\"", 
                "num": 21
              }, 
              {
                "text": "But Jesus answered and said, \"You do not know what you ask. Are you able to drink the cup that I am about to drink, and be baptized with the baptism that I am baptized with?\" They said to Him, \"We are able.\"", 
                "num": 22
              }, 
              {
                "text": "So He said to them, \"You will indeed drink My cup, and be baptized with the baptism that I am baptized with; but to sit on My right hand and on My left is not Mine to give, but it is for those for whom it is prepared by My Father.\"", 
                "num": 23
              }, 
              {
                "text": "And when the ten heard it, they were greatly displeased with the two brothers.", 
                "num": 24
              }, 
              {
                "text": "But Jesus called them to Himself and said, \"You know that the rulers of the Gentiles lord it over them, and those who are great exercise authority over them.", 
                "num": 25
              }, 
              {
                "text": "Yet it shall not be so among you; but whoever desires to become great among you, let him be your servant.", 
                "num": 26
              }, 
              {
                "text": "And whoever desires to be first among you, let him be your slave--", 
                "num": 27
              }, 
              {
                "text": "just as the Son of Man did not come to be served, but to serve, and to give His life a ransom for many.\"", 
                "num": 28
              }, 
              {
                "text": "Now as they went out of Jericho, a great multitude followed Him.", 
                "num": 29
              }, 
              {
                "text": "And behold, two blind men sitting by the road, when they heard that Jesus was passing by, cried out, saying, \"Have mercy on us, O Lord, Son of David!\"", 
                "num": 30
              }, 
              {
                "text": "Then the multitude warned them that they should be quiet; but they cried out all the more, saying, \"Have mercy on us, O Lord, Son of David!\"", 
                "num": 31
              }, 
              {
                "text": "So Jesus stood still and called them, and said, \"What do you want Me to do for you?\"", 
                "num": 32
              }, 
              {
                "text": "They said to Him, \"Lord, that our eyes may be opened.\"", 
                "num": 33
              }, 
              {
                "text": "So Jesus had compassion and touched their eyes. And immediately their eyes received sight, and they followed Him.", 
                "num": 34
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now when they drew near Jerusalem, and came to Bethphage, at the Mount of Olives, then Jesus sent two disciples,", 
                "num": 1
              }, 
              {
                "text": "saying to them, \"Go into the village opposite you, and immediately you will find a donkey tied, and a colt with her. Loose them and bring them to Me.", 
                "num": 2
              }, 
              {
                "text": "And if anyone says anything to you, you shall say, \"The Lord has need of them,' and immediately he will send them.\"", 
                "num": 3
              }, 
              {
                "text": "All this was done that it might be fulfilled which was spoken by the prophet, saying:", 
                "num": 4
              }, 
              {
                "text": "\"Tell the daughter of Zion, \"Behold, your King is coming to you, Lowly, and sitting on a donkey, A colt, the foal of a donkey.\"'", 
                "num": 5
              }, 
              {
                "text": "So the disciples went and did as Jesus commanded them.", 
                "num": 6
              }, 
              {
                "text": "They brought the donkey and the colt, laid their clothes on them, and set Him on them.", 
                "num": 7
              }, 
              {
                "text": "And a very great multitude spread their clothes on the road; others cut down branches from the trees and spread them on the road.", 
                "num": 8
              }, 
              {
                "text": "Then the multitudes who went before and those who followed cried out, saying: \"Hosanna to the Son of David! \"Blessed is He who comes in the name of the LORD!' Hosanna in the highest!\"", 
                "num": 9
              }, 
              {
                "text": "And when He had come into Jerusalem, all the city was moved, saying, \"Who is this?\"", 
                "num": 10
              }, 
              {
                "text": "So the multitudes said, \"This is Jesus, the prophet from Nazareth of Galilee.\"", 
                "num": 11
              }, 
              {
                "text": "Then Jesus went into the temple of God and drove out all those who bought and sold in the temple, and overturned the tables of the money changers and the seats of those who sold doves.", 
                "num": 12
              }, 
              {
                "text": "And He said to them, \"It is written, \"My house shall be called a house of prayer,' but you have made it a \"den of thieves.\"'", 
                "num": 13
              }, 
              {
                "text": "Then the blind and the lame came to Him in the temple, and He healed them.", 
                "num": 14
              }, 
              {
                "text": "But when the chief priests and scribes saw the wonderful things that He did, and the children crying out in the temple and saying, \"Hosanna to the Son of David!\" they were indignant", 
                "num": 15
              }, 
              {
                "text": "and said to Him, \"Do You hear what these are saying?\" And Jesus said to them, \"Yes. Have you never read, \"Out of the mouth of babes and nursing infants You have perfected praise'?\"", 
                "num": 16
              }, 
              {
                "text": "Then He left them and went out of the city to Bethany, and He lodged there.", 
                "num": 17
              }, 
              {
                "text": "Now in the morning, as He returned to the city, He was hungry.", 
                "num": 18
              }, 
              {
                "text": "And seeing a fig tree by the road, He came to it and found nothing on it but leaves, and said to it, \"Let no fruit grow on you ever again.\" Immediately the fig tree withered away.", 
                "num": 19
              }, 
              {
                "text": "And when the disciples saw it, they marveled, saying, \"How did the fig tree wither away so soon?\"", 
                "num": 20
              }, 
              {
                "text": "So Jesus answered and said to them, \"Assuredly, I say to you, if you have faith and do not doubt, you will not only do what was done to the fig tree, but also if you say to this mountain, \"Be removed and be cast into the sea,' it will be done.", 
                "num": 21
              }, 
              {
                "text": "And whatever things you ask in prayer, believing, you will receive.\"", 
                "num": 22
              }, 
              {
                "text": "Now when He came into the temple, the chief priests and the elders of the people confronted Him as He was teaching, and said, \"By what authority are You doing these things? And who gave You this authority?\"", 
                "num": 23
              }, 
              {
                "text": "But Jesus answered and said to them, \"I also will ask you one thing, which if you tell Me, I likewise will tell you by what authority I do these things:", 
                "num": 24
              }, 
              {
                "text": "The baptism of John--where was it from? From heaven or from men?\" And they reasoned among themselves, saying, \"If we say, \"From heaven,' He will say to us, \"Why then did you not believe him?'", 
                "num": 25
              }, 
              {
                "text": "But if we say, \"From men,' we fear the multitude, for all count John as a prophet.\"", 
                "num": 26
              }, 
              {
                "text": "So they answered Jesus and said, \"We do not know.\" And He said to them, \"Neither will I tell you by what authority I do these things.", 
                "num": 27
              }, 
              {
                "text": "\"But what do you think? A man had two sons, and he came to the first and said, \"Son, go, work today in my vineyard.'", 
                "num": 28
              }, 
              {
                "text": "He answered and said, \"I will not,' but afterward he regretted it and went.", 
                "num": 29
              }, 
              {
                "text": "Then he came to the second and said likewise. And he answered and said, \"I go, sir,' but he did not go.", 
                "num": 30
              }, 
              {
                "text": "Which of the two did the will of his father?\" They said to Him, \"The first.\" Jesus said to them, \"Assuredly, I say to you that tax collectors and harlots enter the kingdom of God before you.", 
                "num": 31
              }, 
              {
                "text": "For John came to you in the way of righteousness, and you did not believe him; but tax collectors and harlots believed him; and when you saw it, you did not afterward relent and believe him.", 
                "num": 32
              }, 
              {
                "text": "\"Hear another parable: There was a certain landowner who planted a vineyard and set a hedge around it, dug a winepress in it and built a tower. And he leased it to vinedressers and went into a far country.", 
                "num": 33
              }, 
              {
                "text": "Now when vintage-time drew near, he sent his servants to the vinedressers, that they might receive its fruit.", 
                "num": 34
              }, 
              {
                "text": "And the vinedressers took his servants, beat one, killed one, and stoned another.", 
                "num": 35
              }, 
              {
                "text": "Again he sent other servants, more than the first, and they did likewise to them.", 
                "num": 36
              }, 
              {
                "text": "Then last of all he sent his son to them, saying, \"They will respect my son.'", 
                "num": 37
              }, 
              {
                "text": "But when the vinedressers saw the son, they said among themselves, \"This is the heir. Come, let us kill him and seize his inheritance.'", 
                "num": 38
              }, 
              {
                "text": "So they took him and cast him out of the vineyard and killed him.", 
                "num": 39
              }, 
              {
                "text": "\"Therefore, when the owner of the vineyard comes, what will he do to those vinedressers?\"", 
                "num": 40
              }, 
              {
                "text": "They said to Him, \"He will destroy those wicked men miserably, and lease his vineyard to other vinedressers who will render to him the fruits in their seasons.\"", 
                "num": 41
              }, 
              {
                "text": "Jesus said to them, \"Have you never read in the Scriptures: \"The stone which the builders rejected Has become the chief cornerstone. This was the LORD's doing, And it is marvelous in our eyes'?", 
                "num": 42
              }, 
              {
                "text": "\"Therefore I say to you, the kingdom of God will be taken from you and given to a nation bearing the fruits of it.", 
                "num": 43
              }, 
              {
                "text": "And whoever falls on this stone will be broken; but on whomever it falls, it will grind him to powder.\"", 
                "num": 44
              }, 
              {
                "text": "Now when the chief priests and Pharisees heard His parables, they perceived that He was speaking of them.", 
                "num": 45
              }, 
              {
                "text": "But when they sought to lay hands on Him, they feared the multitudes, because they took Him for a prophet.", 
                "num": 46
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "And Jesus answered and spoke to them again by parables and said:", 
                "num": 1
              }, 
              {
                "text": "\"The kingdom of heaven is like a certain king who arranged a marriage for his son,", 
                "num": 2
              }, 
              {
                "text": "and sent out his servants to call those who were invited to the wedding; and they were not willing to come.", 
                "num": 3
              }, 
              {
                "text": "Again, he sent out other servants, saying, \"Tell those who are invited, \"See, I have prepared my dinner; my oxen and fatted cattle are killed, and all things are ready. Come to the wedding.\"'", 
                "num": 4
              }, 
              {
                "text": "But they made light of it and went their ways, one to his own farm, another to his business.", 
                "num": 5
              }, 
              {
                "text": "And the rest seized his servants, treated them spitefully, and killed them.", 
                "num": 6
              }, 
              {
                "text": "But when the king heard about it, he was furious. And he sent out his armies, destroyed those murderers, and burned up their city.", 
                "num": 7
              }, 
              {
                "text": "Then he said to his servants, \"The wedding is ready, but those who were invited were not worthy.", 
                "num": 8
              }, 
              {
                "text": "Therefore go into the highways, and as many as you find, invite to the wedding.'", 
                "num": 9
              }, 
              {
                "text": "So those servants went out into the highways and gathered together all whom they found, both bad and good. And the wedding hall was filled with guests.", 
                "num": 10
              }, 
              {
                "text": "\"But when the king came in to see the guests, he saw a man there who did not have on a wedding garment.", 
                "num": 11
              }, 
              {
                "text": "So he said to him, \"Friend, how did you come in here without a wedding garment?' And he was speechless.", 
                "num": 12
              }, 
              {
                "text": "Then the king said to the servants, \"Bind him hand and foot, take him away, and cast him into outer darkness; there will be weeping and gnashing of teeth.'", 
                "num": 13
              }, 
              {
                "text": "\"For many are called, but few are chosen.\"", 
                "num": 14
              }, 
              {
                "text": "Then the Pharisees went and plotted how they might entangle Him in His talk.", 
                "num": 15
              }, 
              {
                "text": "And they sent to Him their disciples with the Herodians, saying, \"Teacher, we know that You are true, and teach the way of God in truth; nor do You care about anyone, for You do not regard the person of men.", 
                "num": 16
              }, 
              {
                "text": "Tell us, therefore, what do You think? Is it lawful to pay taxes to Caesar, or not?\"", 
                "num": 17
              }, 
              {
                "text": "But Jesus perceived their wickedness, and said, \"Why do you test Me, you hypocrites?", 
                "num": 18
              }, 
              {
                "text": "Show Me the tax money.\" So they brought Him a denarius.", 
                "num": 19
              }, 
              {
                "text": "And He said to them, \"Whose image and inscription is this?\"", 
                "num": 20
              }, 
              {
                "text": "They said to Him, \"Caesar's.\" And He said to them, \"Render therefore to Caesar the things that are Caesar's, and to God the things that are God's.\"", 
                "num": 21
              }, 
              {
                "text": "When they had heard these words, they marveled, and left Him and went their way.", 
                "num": 22
              }, 
              {
                "text": "The same day the Sadducees, who say there is no resurrection, came to Him and asked Him,", 
                "num": 23
              }, 
              {
                "text": "saying: \"Teacher, Moses said that if a man dies, having no children, his brother shall marry his wife and raise up offspring for his brother.", 
                "num": 24
              }, 
              {
                "text": "Now there were with us seven brothers. The first died after he had married, and having no offspring, left his wife to his brother.", 
                "num": 25
              }, 
              {
                "text": "Likewise the second also, and the third, even to the seventh.", 
                "num": 26
              }, 
              {
                "text": "Last of all the woman died also.", 
                "num": 27
              }, 
              {
                "text": "Therefore, in the resurrection, whose wife of the seven will she be? For they all had her.\"", 
                "num": 28
              }, 
              {
                "text": "Jesus answered and said to them, \"You are mistaken, not knowing the Scriptures nor the power of God.", 
                "num": 29
              }, 
              {
                "text": "For in the resurrection they neither marry nor are given in marriage, but are like angels of God in heaven.", 
                "num": 30
              }, 
              {
                "text": "But concerning the resurrection of the dead, have you not read what was spoken to you by God, saying,", 
                "num": 31
              }, 
              {
                "text": "\"I am the God of Abraham, the God of Isaac, and the God of Jacob'? God is not the God of the dead, but of the living.\"", 
                "num": 32
              }, 
              {
                "text": "And when the multitudes heard this, they were astonished at His teaching.", 
                "num": 33
              }, 
              {
                "text": "But when the Pharisees heard that He had silenced the Sadducees, they gathered together.", 
                "num": 34
              }, 
              {
                "text": "Then one of them, a lawyer, asked Him a question, testing Him, and saying,", 
                "num": 35
              }, 
              {
                "text": "\"Teacher, which is the great commandment in the law?\"", 
                "num": 36
              }, 
              {
                "text": "Jesus said to him, \"\"You shall love the LORD your God with all your heart, with all your soul, and with all your mind.'", 
                "num": 37
              }, 
              {
                "text": "This is the first and great commandment.", 
                "num": 38
              }, 
              {
                "text": "And the second is like it: \"You shall love your neighbor as yourself.'", 
                "num": 39
              }, 
              {
                "text": "On these two commandments hang all the Law and the Prophets.\"", 
                "num": 40
              }, 
              {
                "text": "While the Pharisees were gathered together, Jesus asked them,", 
                "num": 41
              }, 
              {
                "text": "saying, \"What do you think about the Christ? Whose Son is He?\" They said to Him, \"The Son of David.\"", 
                "num": 42
              }, 
              {
                "text": "He said to them, \"How then does David in the Spirit call Him \"Lord,' saying:", 
                "num": 43
              }, 
              {
                "text": "\"The LORD said to my Lord, \"Sit at My right hand, Till I make Your enemies Your footstool\"'?", 
                "num": 44
              }, 
              {
                "text": "If David then calls Him \"Lord,' how is He his Son?\"", 
                "num": 45
              }, 
              {
                "text": "And no one was able to answer Him a word, nor from that day on did anyone dare question Him anymore.", 
                "num": 46
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then Jesus spoke to the multitudes and to His disciples,", 
                "num": 1
              }, 
              {
                "text": "saying: \"The scribes and the Pharisees sit in Moses' seat.", 
                "num": 2
              }, 
              {
                "text": "Therefore whatever they tell you to observe, that observe and do, but do not do according to their works; for they say, and do not do.", 
                "num": 3
              }, 
              {
                "text": "For they bind heavy burdens, hard to bear, and lay them on men's shoulders; but they themselves will not move them with one of their fingers.", 
                "num": 4
              }, 
              {
                "text": "But all their works they do to be seen by men. They make their phylacteries broad and enlarge the borders of their garments.", 
                "num": 5
              }, 
              {
                "text": "They love the best places at feasts, the best seats in the synagogues,", 
                "num": 6
              }, 
              {
                "text": "greetings in the marketplaces, and to be called by men, \"Rabbi, Rabbi.'", 
                "num": 7
              }, 
              {
                "text": "But you, do not be called \"Rabbi'; for One is your Teacher, the Christ, and you are all brethren.", 
                "num": 8
              }, 
              {
                "text": "Do not call anyone on earth your father; for One is your Father, He who is in heaven.", 
                "num": 9
              }, 
              {
                "text": "And do not be called teachers; for One is your Teacher, the Christ.", 
                "num": 10
              }, 
              {
                "text": "But he who is greatest among you shall be your servant.", 
                "num": 11
              }, 
              {
                "text": "And whoever exalts himself will be humbled, and he who humbles himself will be exalted.", 
                "num": 12
              }, 
              {
                "text": "\"But woe to you, scribes and Pharisees, hypocrites! For you shut up the kingdom of heaven against men; for you neither go in yourselves, nor do you allow those who are entering to go in.", 
                "num": 13
              }, 
              {
                "text": "Woe to you, scribes and Pharisees, hypocrites! For you devour widows' houses, and for a pretense make long prayers. Therefore you will receive greater condemnation.", 
                "num": 14
              }, 
              {
                "text": "\"Woe to you, scribes and Pharisees, hypocrites! For you travel land and sea to win one proselyte, and when he is won, you make him twice as much a son of hell as yourselves.", 
                "num": 15
              }, 
              {
                "text": "\"Woe to you, blind guides, who say, \"Whoever swears by the temple, it is nothing; but whoever swears by the gold of the temple, he is obliged to perform it.'", 
                "num": 16
              }, 
              {
                "text": "Fools and blind! For which is greater, the gold or the temple that sanctifies the gold?", 
                "num": 17
              }, 
              {
                "text": "And, \"Whoever swears by the altar, it is nothing; but whoever swears by the gift that is on it, he is obliged to perform it.'", 
                "num": 18
              }, 
              {
                "text": "Fools and blind! For which is greater, the gift or the altar that sanctifies the gift?", 
                "num": 19
              }, 
              {
                "text": "Therefore he who swears by the altar, swears by it and by all things on it.", 
                "num": 20
              }, 
              {
                "text": "He who swears by the temple, swears by it and by Him who dwells in it.", 
                "num": 21
              }, 
              {
                "text": "And he who swears by heaven, swears by the throne of God and by Him who sits on it.", 
                "num": 22
              }, 
              {
                "text": "\"Woe to you, scribes and Pharisees, hypocrites! For you pay tithe of mint and anise and cummin, and have neglected the weightier matters of the law: justice and mercy and faith. These you ought to have done, without leaving the others undone.", 
                "num": 23
              }, 
              {
                "text": "Blind guides, who strain out a gnat and swallow a camel!", 
                "num": 24
              }, 
              {
                "text": "\"Woe to you, scribes and Pharisees, hypocrites! For you cleanse the outside of the cup and dish, but inside they are full of extortion and self-indulgence.", 
                "num": 25
              }, 
              {
                "text": "Blind Pharisee, first cleanse the inside of the cup and dish, that the outside of them may be clean also.", 
                "num": 26
              }, 
              {
                "text": "\"Woe to you, scribes and Pharisees, hypocrites! For you are like whitewashed tombs which indeed appear beautiful outwardly, but inside are full of dead men's bones and all uncleanness.", 
                "num": 27
              }, 
              {
                "text": "Even so you also outwardly appear righteous to men, but inside you are full of hypocrisy and lawlessness.", 
                "num": 28
              }, 
              {
                "text": "\"Woe to you, scribes and Pharisees, hypocrites! Because you build the tombs of the prophets and adorn the monuments of the righteous,", 
                "num": 29
              }, 
              {
                "text": "and say, \"If we had lived in the days of our fathers, we would not have been partakers with them in the blood of the prophets.'", 
                "num": 30
              }, 
              {
                "text": "\"Therefore you are witnesses against yourselves that you are sons of those who murdered the prophets.", 
                "num": 31
              }, 
              {
                "text": "Fill up, then, the measure of your fathers' guilt.", 
                "num": 32
              }, 
              {
                "text": "Serpents, brood of vipers! How can you escape the condemnation of hell?", 
                "num": 33
              }, 
              {
                "text": "Therefore, indeed, I send you prophets, wise men, and scribes: some of them you will kill and crucify, and some of them you will scourge in your synagogues and persecute from city to city,", 
                "num": 34
              }, 
              {
                "text": "that on you may come all the righteous blood shed on the earth, from the blood of righteous Abel to the blood of Zechariah, son of Berechiah, whom you murdered between the temple and the altar.", 
                "num": 35
              }, 
              {
                "text": "Assuredly, I say to you, all these things will come upon this generation.", 
                "num": 36
              }, 
              {
                "text": "\"O Jerusalem, Jerusalem, the one who kills the prophets and stones those who are sent to her! How often I wanted to gather your children together, as a hen gathers her chicks under her wings, but you were not willing!", 
                "num": 37
              }, 
              {
                "text": "See! Your house is left to you desolate;", 
                "num": 38
              }, 
              {
                "text": "for I say to you, you shall see Me no more till you say, \"Blessed is He who comes in the name of the LORD!\"'", 
                "num": 39
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Then Jesus went out and departed from the temple, and His disciples came up to show Him the buildings of the temple.", 
                "num": 1
              }, 
              {
                "text": "And Jesus said to them, \"Do you not see all these things? Assuredly, I say to you, not one stone shall be left here upon another, that shall not be thrown down.\"", 
                "num": 2
              }, 
              {
                "text": "Now as He sat on the Mount of Olives, the disciples came to Him privately, saying, \"Tell us, when will these things be? And what will be the sign of Your coming, and of the end of the age?\"", 
                "num": 3
              }, 
              {
                "text": "And Jesus answered and said to them: \"Take heed that no one deceives you.", 
                "num": 4
              }, 
              {
                "text": "For many will come in My name, saying, \"I am the Christ,' and will deceive many.", 
                "num": 5
              }, 
              {
                "text": "And you will hear of wars and rumors of wars. See that you are not troubled; for all these things must come to pass, but the end is not yet.", 
                "num": 6
              }, 
              {
                "text": "For nation will rise against nation, and kingdom against kingdom. And there will be famines, pestilences, and earthquakes in various places.", 
                "num": 7
              }, 
              {
                "text": "All these are the beginning of sorrows.", 
                "num": 8
              }, 
              {
                "text": "\"Then they will deliver you up to tribulation and kill you, and you will be hated by all nations for My name's sake.", 
                "num": 9
              }, 
              {
                "text": "And then many will be offended, will betray one another, and will hate one another.", 
                "num": 10
              }, 
              {
                "text": "Then many false prophets will rise up and deceive many.", 
                "num": 11
              }, 
              {
                "text": "And because lawlessness will abound, the love of many will grow cold.", 
                "num": 12
              }, 
              {
                "text": "But he who endures to the end shall be saved.", 
                "num": 13
              }, 
              {
                "text": "And this gospel of the kingdom will be preached in all the world as a witness to all the nations, and then the end will come.", 
                "num": 14
              }, 
              {
                "text": "\"Therefore when you see the \"abomination of desolation,' spoken of by Daniel the prophet, standing in the holy place\" (whoever reads, let him understand),", 
                "num": 15
              }, 
              {
                "text": "\"then let those who are in Judea flee to the mountains.", 
                "num": 16
              }, 
              {
                "text": "Let him who is on the housetop not go down to take anything out of his house.", 
                "num": 17
              }, 
              {
                "text": "And let him who is in the field not go back to get his clothes.", 
                "num": 18
              }, 
              {
                "text": "But woe to those who are pregnant and to those who are nursing babies in those days!", 
                "num": 19
              }, 
              {
                "text": "And pray that your flight may not be in winter or on the Sabbath.", 
                "num": 20
              }, 
              {
                "text": "For then there will be great tribulation, such as has not been since the beginning of the world until this time, no, nor ever shall be.", 
                "num": 21
              }, 
              {
                "text": "And unless those days were shortened, no flesh would be saved; but for the elect's sake those days will be shortened.", 
                "num": 22
              }, 
              {
                "text": "\"Then if anyone says to you, \"Look, here is the Christ!' or \"There!' do not believe it.", 
                "num": 23
              }, 
              {
                "text": "For false christs and false prophets will rise and show great signs and wonders to deceive, if possible, even the elect.", 
                "num": 24
              }, 
              {
                "text": "See, I have told you beforehand.", 
                "num": 25
              }, 
              {
                "text": "\"Therefore if they say to you, \"Look, He is in the desert!' do not go out; or \"Look, He is in the inner rooms!' do not believe it.", 
                "num": 26
              }, 
              {
                "text": "For as the lightning comes from the east and flashes to the west, so also will the coming of the Son of Man be.", 
                "num": 27
              }, 
              {
                "text": "For wherever the carcass is, there the eagles will be gathered together.", 
                "num": 28
              }, 
              {
                "text": "\"Immediately after the tribulation of those days the sun will be darkened, and the moon will not give its light; the stars will fall from heaven, and the powers of the heavens will be shaken.", 
                "num": 29
              }, 
              {
                "text": "Then the sign of the Son of Man will appear in heaven, and then all the tribes of the earth will mourn, and they will see the Son of Man coming on the clouds of heaven with power and great glory.", 
                "num": 30
              }, 
              {
                "text": "And He will send His angels with a great sound of a trumpet, and they will gather together His elect from the four winds, from one end of heaven to the other.", 
                "num": 31
              }, 
              {
                "text": "\"Now learn this parable from the fig tree: When its branch has already become tender and puts forth leaves, you know that summer is near.", 
                "num": 32
              }, 
              {
                "text": "So you also, when you see all these things, know that it is near--at the doors!", 
                "num": 33
              }, 
              {
                "text": "Assuredly, I say to you, this generation will by no means pass away till all these things take place.", 
                "num": 34
              }, 
              {
                "text": "Heaven and earth will pass away, but My words will by no means pass away.", 
                "num": 35
              }, 
              {
                "text": "\"But of that day and hour no one knows, not even the angels of heaven, but My Father only.", 
                "num": 36
              }, 
              {
                "text": "But as the days of Noah were, so also will the coming of the Son of Man be.", 
                "num": 37
              }, 
              {
                "text": "For as in the days before the flood, they were eating and drinking, marrying and giving in marriage, until the day that Noah entered the ark,", 
                "num": 38
              }, 
              {
                "text": "and did not know until the flood came and took them all away, so also will the coming of the Son of Man be.", 
                "num": 39
              }, 
              {
                "text": "Then two men will be in the field: one will be taken and the other left.", 
                "num": 40
              }, 
              {
                "text": "Two women will be grinding at the mill: one will be taken and the other left.", 
                "num": 41
              }, 
              {
                "text": "Watch therefore, for you do not know what hour your Lord is coming.", 
                "num": 42
              }, 
              {
                "text": "But know this, that if the master of the house had known what hour the thief would come, he would have watched and not allowed his house to be broken into.", 
                "num": 43
              }, 
              {
                "text": "Therefore you also be ready, for the Son of Man is coming at an hour you do not expect.", 
                "num": 44
              }, 
              {
                "text": "\"Who then is a faithful and wise servant, whom his master made ruler over his household, to give them food in due season?", 
                "num": 45
              }, 
              {
                "text": "Blessed is that servant whom his master, when he comes, will find so doing.", 
                "num": 46
              }, 
              {
                "text": "Assuredly, I say to you that he will make him ruler over all his goods.", 
                "num": 47
              }, 
              {
                "text": "But if that evil servant says in his heart, \"My master is delaying his coming,'", 
                "num": 48
              }, 
              {
                "text": "and begins to beat his fellow servants, and to eat and drink with the drunkards,", 
                "num": 49
              }, 
              {
                "text": "the master of that servant will come on a day when he is not looking for him and at an hour that he is not aware of,", 
                "num": 50
              }, 
              {
                "text": "and will cut him in two and appoint him his portion with the hypocrites. There shall be weeping and gnashing of teeth.", 
                "num": 51
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "\"Then the kingdom of heaven shall be likened to ten virgins who took their lamps and went out to meet the bridegroom.", 
                "num": 1
              }, 
              {
                "text": "Now five of them were wise, and five were foolish.", 
                "num": 2
              }, 
              {
                "text": "Those who were foolish took their lamps and took no oil with them,", 
                "num": 3
              }, 
              {
                "text": "but the wise took oil in their vessels with their lamps.", 
                "num": 4
              }, 
              {
                "text": "But while the bridegroom was delayed, they all slumbered and slept.", 
                "num": 5
              }, 
              {
                "text": "\"And at midnight a cry was heard: \"Behold, the bridegroom is coming; go out to meet him!'", 
                "num": 6
              }, 
              {
                "text": "Then all those virgins arose and trimmed their lamps.", 
                "num": 7
              }, 
              {
                "text": "And the foolish said to the wise, \"Give us some of your oil, for our lamps are going out.'", 
                "num": 8
              }, 
              {
                "text": "But the wise answered, saying, \"No, lest there should not be enough for us and you; but go rather to those who sell, and buy for yourselves.'", 
                "num": 9
              }, 
              {
                "text": "And while they went to buy, the bridegroom came, and those who were ready went in with him to the wedding; and the door was shut.", 
                "num": 10
              }, 
              {
                "text": "\"Afterward the other virgins came also, saying, \"Lord, Lord, open to us!'", 
                "num": 11
              }, 
              {
                "text": "But he answered and said, \"Assuredly, I say to you, I do not know you.'", 
                "num": 12
              }, 
              {
                "text": "\"Watch therefore, for you know neither the day nor the hour in which the Son of Man is coming.", 
                "num": 13
              }, 
              {
                "text": "\"For the kingdom of heaven is like a man traveling to a far country, who called his own servants and delivered his goods to them.", 
                "num": 14
              }, 
              {
                "text": "And to one he gave five talents, to another two, and to another one, to each according to his own ability; and immediately he went on a journey.", 
                "num": 15
              }, 
              {
                "text": "Then he who had received the five talents went and traded with them, and made another five talents.", 
                "num": 16
              }, 
              {
                "text": "And likewise he who had received two gained two more also.", 
                "num": 17
              }, 
              {
                "text": "But he who had received one went and dug in the ground, and hid his lord's money.", 
                "num": 18
              }, 
              {
                "text": "After a long time the lord of those servants came and settled accounts with them.", 
                "num": 19
              }, 
              {
                "text": "\"So he who had received five talents came and brought five other talents, saying, \"Lord, you delivered to me five talents; look, I have gained five more talents besides them.'", 
                "num": 20
              }, 
              {
                "text": "His lord said to him, \"Well done, good and faithful servant; you were faithful over a few things, I will make you ruler over many things. Enter into the joy of your lord.'", 
                "num": 21
              }, 
              {
                "text": "He also who had received two talents came and said, \"Lord, you delivered to me two talents; look, I have gained two more talents besides them.'", 
                "num": 22
              }, 
              {
                "text": "His lord said to him, \"Well done, good and faithful servant; you have been faithful over a few things, I will make you ruler over many things. Enter into the joy of your lord.'", 
                "num": 23
              }, 
              {
                "text": "\"Then he who had received the one talent came and said, \"Lord, I knew you to be a hard man, reaping where you have not sown, and gathering where you have not scattered seed.", 
                "num": 24
              }, 
              {
                "text": "And I was afraid, and went and hid your talent in the ground. Look, there you have what is yours.'", 
                "num": 25
              }, 
              {
                "text": "\"But his lord answered and said to him, \"You wicked and lazy servant, you knew that I reap where I have not sown, and gather where I have not scattered seed.", 
                "num": 26
              }, 
              {
                "text": "So you ought to have deposited my money with the bankers, and at my coming I would have received back my own with interest.", 
                "num": 27
              }, 
              {
                "text": "So take the talent from him, and give it to him who has ten talents.", 
                "num": 28
              }, 
              {
                "text": "\"For to everyone who has, more will be given, and he will have abundance; but from him who does not have, even what he has will be taken away.", 
                "num": 29
              }, 
              {
                "text": "And cast the unprofitable servant into the outer darkness. There will be weeping and gnashing of teeth.'", 
                "num": 30
              }, 
              {
                "text": "\"When the Son of Man comes in His glory, and all the holy angels with Him, then He will sit on the throne of His glory.", 
                "num": 31
              }, 
              {
                "text": "All the nations will be gathered before Him, and He will separate them one from another, as a shepherd divides his sheep from the goats.", 
                "num": 32
              }, 
              {
                "text": "And He will set the sheep on His right hand, but the goats on the left.", 
                "num": 33
              }, 
              {
                "text": "Then the King will say to those on His right hand, \"Come, you blessed of My Father, inherit the kingdom prepared for you from the foundation of the world:", 
                "num": 34
              }, 
              {
                "text": "for I was hungry and you gave Me food; I was thirsty and you gave Me drink; I was a stranger and you took Me in;", 
                "num": 35
              }, 
              {
                "text": "I was naked and you clothed Me; I was sick and you visited Me; I was in prison and you came to Me.'", 
                "num": 36
              }, 
              {
                "text": "\"Then the righteous will answer Him, saying, \"Lord, when did we see You hungry and feed You, or thirsty and give You drink?", 
                "num": 37
              }, 
              {
                "text": "When did we see You a stranger and take You in, or naked and clothe You?", 
                "num": 38
              }, 
              {
                "text": "Or when did we see You sick, or in prison, and come to You?'", 
                "num": 39
              }, 
              {
                "text": "And the King will answer and say to them, \"Assuredly, I say to you, inasmuch as you did it to one of the least of these My brethren, you did it to Me.'", 
                "num": 40
              }, 
              {
                "text": "\"Then He will also say to those on the left hand, \"Depart from Me, you cursed, into the everlasting fire prepared for the devil and his angels:", 
                "num": 41
              }, 
              {
                "text": "for I was hungry and you gave Me no food; I was thirsty and you gave Me no drink;", 
                "num": 42
              }, 
              {
                "text": "I was a stranger and you did not take Me in, naked and you did not clothe Me, sick and in prison and you did not visit Me.'", 
                "num": 43
              }, 
              {
                "text": "\"Then they also will answer Him, saying, \"Lord, when did we see You hungry or thirsty or a stranger or naked or sick or in prison, and did not minister to You?'", 
                "num": 44
              }, 
              {
                "text": "Then He will answer them, saying, \"Assuredly, I say to you, inasmuch as you did not do it to one of the least of these, you did not do it to Me.'", 
                "num": 45
              }, 
              {
                "text": "And these will go away into everlasting punishment, but the righteous into eternal life.\"", 
                "num": 46
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, when Jesus had finished all these sayings, that He said to His disciples,", 
                "num": 1
              }, 
              {
                "text": "\"You know that after two days is the Passover, and the Son of Man will be delivered up to be crucified.\"", 
                "num": 2
              }, 
              {
                "text": "Then the chief priests, the scribes, and the elders of the people assembled at the palace of the high priest, who was called Caiaphas,", 
                "num": 3
              }, 
              {
                "text": "and plotted to take Jesus by trickery and kill Him.", 
                "num": 4
              }, 
              {
                "text": "But they said, \"Not during the feast, lest there be an uproar among the people.\"", 
                "num": 5
              }, 
              {
                "text": "And when Jesus was in Bethany at the house of Simon the leper,", 
                "num": 6
              }, 
              {
                "text": "a woman came to Him having an alabaster flask of very costly fragrant oil, and she poured it on His head as He sat at the table.", 
                "num": 7
              }, 
              {
                "text": "But when His disciples saw it, they were indignant, saying, \"Why this waste?", 
                "num": 8
              }, 
              {
                "text": "For this fragrant oil might have been sold for much and given to the poor.\"", 
                "num": 9
              }, 
              {
                "text": "But when Jesus was aware of it, He said to them, \"Why do you trouble the woman? For she has done a good work for Me.", 
                "num": 10
              }, 
              {
                "text": "For you have the poor with you always, but Me you do not have always.", 
                "num": 11
              }, 
              {
                "text": "For in pouring this fragrant oil on My body, she did it for My burial.", 
                "num": 12
              }, 
              {
                "text": "Assuredly, I say to you, wherever this gospel is preached in the whole world, what this woman has done will also be told as a memorial to her.\"", 
                "num": 13
              }, 
              {
                "text": "Then one of the twelve, called Judas Iscariot, went to the chief priests", 
                "num": 14
              }, 
              {
                "text": "and said, \"What are you willing to give me if I deliver Him to you?\" And they counted out to him thirty pieces of silver.", 
                "num": 15
              }, 
              {
                "text": "So from that time he sought opportunity to betray Him.", 
                "num": 16
              }, 
              {
                "text": "Now on the first day of the Feast of the Unleavened Bread the disciples came to Jesus, saying to Him, \"Where do You want us to prepare for You to eat the Passover?\"", 
                "num": 17
              }, 
              {
                "text": "And He said, \"Go into the city to a certain man, and say to him, \"The Teacher says, \"My time is at hand; I will keep the Passover at your house with My disciples.\"\"'", 
                "num": 18
              }, 
              {
                "text": "So the disciples did as Jesus had directed them; and they prepared the Passover.", 
                "num": 19
              }, 
              {
                "text": "When evening had come, He sat down with the twelve.", 
                "num": 20
              }, 
              {
                "text": "Now as they were eating, He said, \"Assuredly, I say to you, one of you will betray Me.\"", 
                "num": 21
              }, 
              {
                "text": "And they were exceedingly sorrowful, and each of them began to say to Him, \"Lord, is it I?\"", 
                "num": 22
              }, 
              {
                "text": "He answered and said, \"He who dipped his hand with Me in the dish will betray Me.", 
                "num": 23
              }, 
              {
                "text": "The Son of Man indeed goes just as it is written of Him, but woe to that man by whom the Son of Man is betrayed! It would have been good for that man if he had not been born.\"", 
                "num": 24
              }, 
              {
                "text": "Then Judas, who was betraying Him, answered and said, \"Rabbi, is it I?\" He said to him, \"You have said it.\"", 
                "num": 25
              }, 
              {
                "text": "And as they were eating, Jesus took bread, blessed and broke it, and gave it to the disciples and said, \"Take, eat; this is My body.\"", 
                "num": 26
              }, 
              {
                "text": "Then He took the cup, and gave thanks, and gave it to them, saying, \"Drink from it, all of you.", 
                "num": 27
              }, 
              {
                "text": "For this is My blood of the new covenant, which is shed for many for the remission of sins.", 
                "num": 28
              }, 
              {
                "text": "But I say to you, I will not drink of this fruit of the vine from now on until that day when I drink it new with you in My Father's kingdom.\"", 
                "num": 29
              }, 
              {
                "text": "And when they had sung a hymn, they went out to the Mount of Olives.", 
                "num": 30
              }, 
              {
                "text": "Then Jesus said to them, \"All of you will be made to stumble because of Me this night, for it is written: \"I will strike the Shepherd, And the sheep of the flock will be scattered.'", 
                "num": 31
              }, 
              {
                "text": "But after I have been raised, I will go before you to Galilee.\"", 
                "num": 32
              }, 
              {
                "text": "Peter answered and said to Him, \"Even if all are made to stumble because of You, I will never be made to stumble.\"", 
                "num": 33
              }, 
              {
                "text": "Jesus said to him, \"Assuredly, I say to you that this night, before the rooster crows, you will deny Me three times.\"", 
                "num": 34
              }, 
              {
                "text": "Peter said to Him, \"Even if I have to die with You, I will not deny You!\" And so said all the disciples.", 
                "num": 35
              }, 
              {
                "text": "Then Jesus came with them to a place called Gethsemane, and said to the disciples, \"Sit here while I go and pray over there.\"", 
                "num": 36
              }, 
              {
                "text": "And He took with Him Peter and the two sons of Zebedee, and He began to be sorrowful and deeply distressed.", 
                "num": 37
              }, 
              {
                "text": "Then He said to them, \"My soul is exceedingly sorrowful, even to death. Stay here and watch with Me.\"", 
                "num": 38
              }, 
              {
                "text": "He went a little farther and fell on His face, and prayed, saying, \"O My Father, if it is possible, let this cup pass from Me; nevertheless, not as I will, but as You will.\"", 
                "num": 39
              }, 
              {
                "text": "Then He came to the disciples and found them sleeping, and said to Peter, \"What! Could you not watch with Me one hour?", 
                "num": 40
              }, 
              {
                "text": "Watch and pray, lest you enter into temptation. The spirit indeed is willing, but the flesh is weak.\"", 
                "num": 41
              }, 
              {
                "text": "Again, a second time, He went away and prayed, saying, \"O My Father, if this cup cannot pass away from Me unless I drink it, Your will be done.\"", 
                "num": 42
              }, 
              {
                "text": "And He came and found them asleep again, for their eyes were heavy.", 
                "num": 43
              }, 
              {
                "text": "So He left them, went away again, and prayed the third time, saying the same words.", 
                "num": 44
              }, 
              {
                "text": "Then He came to His disciples and said to them, \"Are you still sleeping and resting? Behold, the hour is at hand, and the Son of Man is being betrayed into the hands of sinners.", 
                "num": 45
              }, 
              {
                "text": "Rise, let us be going. See, My betrayer is at hand.\"", 
                "num": 46
              }, 
              {
                "text": "And while He was still speaking, behold, Judas, one of the twelve, with a great multitude with swords and clubs, came from the chief priests and elders of the people.", 
                "num": 47
              }, 
              {
                "text": "Now His betrayer had given them a sign, saying, \"Whomever I kiss, He is the One; seize Him.\"", 
                "num": 48
              }, 
              {
                "text": "Immediately he went up to Jesus and said, \"Greetings, Rabbi!\" and kissed Him.", 
                "num": 49
              }, 
              {
                "text": "But Jesus said to him, \"Friend, why have you come?\" Then they came and laid hands on Jesus and took Him.", 
                "num": 50
              }, 
              {
                "text": "And suddenly, one of those who were with Jesus stretched out his hand and drew his sword, struck the servant of the high priest, and cut off his ear.", 
                "num": 51
              }, 
              {
                "text": "But Jesus said to him, \"Put your sword in its place, for all who take the sword will perish by the sword.", 
                "num": 52
              }, 
              {
                "text": "Or do you think that I cannot now pray to My Father, and He will provide Me with more than twelve legions of angels?", 
                "num": 53
              }, 
              {
                "text": "How then could the Scriptures be fulfilled, that it must happen thus?\"", 
                "num": 54
              }, 
              {
                "text": "In that hour Jesus said to the multitudes, \"Have you come out, as against a robber, with swords and clubs to take Me? I sat daily with you, teaching in the temple, and you did not seize Me.", 
                "num": 55
              }, 
              {
                "text": "But all this was done that the Scriptures of the prophets might be fulfilled.\" Then all the disciples forsook Him and fled.", 
                "num": 56
              }, 
              {
                "text": "And those who had laid hold of Jesus led Him away to Caiaphas the high priest, where the scribes and the elders were assembled.", 
                "num": 57
              }, 
              {
                "text": "But Peter followed Him at a distance to the high priest's courtyard. And he went in and sat with the servants to see the end.", 
                "num": 58
              }, 
              {
                "text": "Now the chief priests, the elders, and all the council sought false testimony against Jesus to put Him to death,", 
                "num": 59
              }, 
              {
                "text": "but found none. Even though many false witnesses came forward, they found none. But at last two false witnesses came forward", 
                "num": 60
              }, 
              {
                "text": "and said, \"This fellow said, \"I am able to destroy the temple of God and to build it in three days.\"'", 
                "num": 61
              }, 
              {
                "text": "And the high priest arose and said to Him, \"Do You answer nothing? What is it these men testify against You?\"", 
                "num": 62
              }, 
              {
                "text": "But Jesus kept silent. And the high priest answered and said to Him, \"I put You under oath by the living God: Tell us if You are the Christ, the Son of God!\"", 
                "num": 63
              }, 
              {
                "text": "Jesus said to him, \"It is as you said. Nevertheless, I say to you, hereafter you will see the Son of Man sitting at the right hand of the Power, and coming on the clouds of heaven.\"", 
                "num": 64
              }, 
              {
                "text": "Then the high priest tore his clothes, saying, \"He has spoken blasphemy! What further need do we have of witnesses? Look, now you have heard His blasphemy!", 
                "num": 65
              }, 
              {
                "text": "What do you think?\" They answered and said, \"He is deserving of death.\"", 
                "num": 66
              }, 
              {
                "text": "Then they spat in His face and beat Him; and others struck Him with the palms of their hands,", 
                "num": 67
              }, 
              {
                "text": "saying, \"Prophesy to us, Christ! Who is the one who struck You?\"", 
                "num": 68
              }, 
              {
                "text": "Now Peter sat outside in the courtyard. And a servant girl came to him, saying, \"You also were with Jesus of Galilee.\"", 
                "num": 69
              }, 
              {
                "text": "But he denied it before them all, saying, \"I do not know what you are saying.\"", 
                "num": 70
              }, 
              {
                "text": "And when he had gone out to the gateway, another girl saw him and said to those who were there, \"This fellow also was with Jesus of Nazareth.\"", 
                "num": 71
              }, 
              {
                "text": "But again he denied with an oath, \"I do not know the Man!\"", 
                "num": 72
              }, 
              {
                "text": "And a little later those who stood by came up and said to Peter, \"Surely you also are one of them, for your speech betrays you.\"", 
                "num": 73
              }, 
              {
                "text": "Then he began to curse and swear, saying, \"I do not know the Man!\" Immediately a rooster crowed.", 
                "num": 74
              }, 
              {
                "text": "And Peter remembered the word of Jesus who had said to him, \"Before the rooster crows, you will deny Me three times.\" So he went out and wept bitterly.", 
                "num": 75
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "When morning came, all the chief priests and elders of the people plotted against Jesus to put Him to death.", 
                "num": 1
              }, 
              {
                "text": "And when they had bound Him, they led Him away and delivered Him to Pontius Pilate the governor.", 
                "num": 2
              }, 
              {
                "text": "Then Judas, His betrayer, seeing that He had been condemned, was remorseful and brought back the thirty pieces of silver to the chief priests and elders,", 
                "num": 3
              }, 
              {
                "text": "saying, \"I have sinned by betraying innocent blood.\" And they said, \"What is that to us? You see to it!\"", 
                "num": 4
              }, 
              {
                "text": "Then he threw down the pieces of silver in the temple and departed, and went and hanged himself.", 
                "num": 5
              }, 
              {
                "text": "But the chief priests took the silver pieces and said, \"It is not lawful to put them into the treasury, because they are the price of blood.\"", 
                "num": 6
              }, 
              {
                "text": "And they consulted together and bought with them the potter's field, to bury strangers in.", 
                "num": 7
              }, 
              {
                "text": "Therefore that field has been called the Field of Blood to this day.", 
                "num": 8
              }, 
              {
                "text": "Then was fulfilled what was spoken by Jeremiah the prophet, saying, \"And they took the thirty pieces of silver, the value of Him who was priced, whom they of the children of Israel priced,", 
                "num": 9
              }, 
              {
                "text": "and gave them for the potter's field, as the LORD directed me.\"", 
                "num": 10
              }, 
              {
                "text": "Now Jesus stood before the governor. And the governor asked Him, saying, \"Are You the King of the Jews?\" Jesus said to him, \"It is as you say.\"", 
                "num": 11
              }, 
              {
                "text": "And while He was being accused by the chief priests and elders, He answered nothing.", 
                "num": 12
              }, 
              {
                "text": "Then Pilate said to Him, \"Do You not hear how many things they testify against You?\"", 
                "num": 13
              }, 
              {
                "text": "But He answered him not one word, so that the governor marveled greatly.", 
                "num": 14
              }, 
              {
                "text": "Now at the feast the governor was accustomed to releasing to the multitude one prisoner whom they wished.", 
                "num": 15
              }, 
              {
                "text": "And at that time they had a notorious prisoner called Barabbas.", 
                "num": 16
              }, 
              {
                "text": "Therefore, when they had gathered together, Pilate said to them, \"Whom do you want me to release to you? Barabbas, or Jesus who is called Christ?\"", 
                "num": 17
              }, 
              {
                "text": "For he knew that they had handed Him over because of envy.", 
                "num": 18
              }, 
              {
                "text": "While he was sitting on the judgment seat, his wife sent to him, saying, \"Have nothing to do with that just Man, for I have suffered many things today in a dream because of Him.\"", 
                "num": 19
              }, 
              {
                "text": "But the chief priests and elders persuaded the multitudes that they should ask for Barabbas and destroy Jesus.", 
                "num": 20
              }, 
              {
                "text": "The governor answered and said to them, \"Which of the two do you want me to release to you?\" They said, \"Barabbas!\"", 
                "num": 21
              }, 
              {
                "text": "Pilate said to them, \"What then shall I do with Jesus who is called Christ?\" They all said to him, \"Let Him be crucified!\"", 
                "num": 22
              }, 
              {
                "text": "Then the governor said, \"Why, what evil has He done?\" But they cried out all the more, saying, \"Let Him be crucified!\"", 
                "num": 23
              }, 
              {
                "text": "When Pilate saw that he could not prevail at all, but rather that a tumult was rising, he took water and washed his hands before the multitude, saying, \"I am innocent of the blood of this just Person. You see to it.\"", 
                "num": 24
              }, 
              {
                "text": "And all the people answered and said, \"His blood be on us and on our children.\"", 
                "num": 25
              }, 
              {
                "text": "Then he released Barabbas to them; and when he had scourged Jesus, he delivered Him to be crucified.", 
                "num": 26
              }, 
              {
                "text": "Then the soldiers of the governor took Jesus into the Praetorium and gathered the whole garrison around Him.", 
                "num": 27
              }, 
              {
                "text": "And they stripped Him and put a scarlet robe on Him.", 
                "num": 28
              }, 
              {
                "text": "When they had twisted a crown of thorns, they put it on His head, and a reed in His right hand. And they bowed the knee before Him and mocked Him, saying, \"Hail, King of the Jews!\"", 
                "num": 29
              }, 
              {
                "text": "Then they spat on Him, and took the reed and struck Him on the head.", 
                "num": 30
              }, 
              {
                "text": "And when they had mocked Him, they took the robe off Him, put His own clothes on Him, and led Him away to be crucified.", 
                "num": 31
              }, 
              {
                "text": "Now as they came out, they found a man of Cyrene, Simon by name. Him they compelled to bear His cross.", 
                "num": 32
              }, 
              {
                "text": "And when they had come to a place called Golgotha, that is to say, Place of a Skull,", 
                "num": 33
              }, 
              {
                "text": "they gave Him sour wine mingled with gall to drink. But when He had tasted it, He would not drink.", 
                "num": 34
              }, 
              {
                "text": "Then they crucified Him, and divided His garments, casting lots, that it might be fulfilled which was spoken by the prophet: \"They divided My garments among them, And for My clothing they cast lots.\"", 
                "num": 35
              }, 
              {
                "text": "Sitting down, they kept watch over Him there.", 
                "num": 36
              }, 
              {
                "text": "And they put up over His head the accusation written against Him: THIS IS JESUS THE KING OF THE JEWS.", 
                "num": 37
              }, 
              {
                "text": "Then two robbers were crucified with Him, one on the right and another on the left.", 
                "num": 38
              }, 
              {
                "text": "And those who passed by blasphemed Him, wagging their heads", 
                "num": 39
              }, 
              {
                "text": "and saying, \"You who destroy the temple and build it in three days, save Yourself! If You are the Son of God, come down from the cross.\"", 
                "num": 40
              }, 
              {
                "text": "Likewise the chief priests also, mocking with the scribes and elders, said,", 
                "num": 41
              }, 
              {
                "text": "\"He saved others; Himself He cannot save. If He is the King of Israel, let Him now come down from the cross, and we will believe Him.", 
                "num": 42
              }, 
              {
                "text": "He trusted in God; let Him deliver Him now if He will have Him; for He said, \"I am the Son of God.\"'", 
                "num": 43
              }, 
              {
                "text": "Even the robbers who were crucified with Him reviled Him with the same thing.", 
                "num": 44
              }, 
              {
                "text": "Now from the sixth hour until the ninth hour there was darkness over all the land.", 
                "num": 45
              }, 
              {
                "text": "And about the ninth hour Jesus cried out with a loud voice, saying, \"Eli, Eli, lama sabachthani?\" that is, \"My God, My God, why have You forsaken Me?\"", 
                "num": 46
              }, 
              {
                "text": "Some of those who stood there, when they heard that, said, \"This Man is calling for Elijah!\"", 
                "num": 47
              }, 
              {
                "text": "Immediately one of them ran and took a sponge, filled it with sour wine and put it on a reed, and offered it to Him to drink.", 
                "num": 48
              }, 
              {
                "text": "The rest said, \"Let Him alone; let us see if Elijah will come to save Him.\"", 
                "num": 49
              }, 
              {
                "text": "And Jesus cried out again with a loud voice, and yielded up His spirit.", 
                "num": 50
              }, 
              {
                "text": "Then, behold, the veil of the temple was torn in two from top to bottom; and the earth quaked, and the rocks were split,", 
                "num": 51
              }, 
              {
                "text": "and the graves were opened; and many bodies of the saints who had fallen asleep were raised;", 
                "num": 52
              }, 
              {
                "text": "and coming out of the graves after His resurrection, they went into the holy city and appeared to many.", 
                "num": 53
              }, 
              {
                "text": "So when the centurion and those with him, who were guarding Jesus, saw the earthquake and the things that had happened, they feared greatly, saying, \"Truly this was the Son of God!\"", 
                "num": 54
              }, 
              {
                "text": "And many women who followed Jesus from Galilee, ministering to Him, were there looking on from afar,", 
                "num": 55
              }, 
              {
                "text": "among whom were Mary Magdalene, Mary the mother of James and Joses, and the mother of Zebedee's sons.", 
                "num": 56
              }, 
              {
                "text": "Now when evening had come, there came a rich man from Arimathea, named Joseph, who himself had also become a disciple of Jesus.", 
                "num": 57
              }, 
              {
                "text": "This man went to Pilate and asked for the body of Jesus. Then Pilate commanded the body to be given to him.", 
                "num": 58
              }, 
              {
                "text": "When Joseph had taken the body, he wrapped it in a clean linen cloth,", 
                "num": 59
              }, 
              {
                "text": "and laid it in his new tomb which he had hewn out of the rock; and he rolled a large stone against the door of the tomb, and departed.", 
                "num": 60
              }, 
              {
                "text": "And Mary Magdalene was there, and the other Mary, sitting opposite the tomb.", 
                "num": 61
              }, 
              {
                "text": "On the next day, which followed the Day of Preparation, the chief priests and Pharisees gathered together to Pilate,", 
                "num": 62
              }, 
              {
                "text": "saying, \"Sir, we remember, while He was still alive, how that deceiver said, \"After three days I will rise.'", 
                "num": 63
              }, 
              {
                "text": "Therefore command that the tomb be made secure until the third day, lest His disciples come by night and steal Him away, and say to the people, \"He has risen from the dead.' So the last deception will be worse than the first.\"", 
                "num": 64
              }, 
              {
                "text": "Pilate said to them, \"You have a guard; go your way, make it as secure as you know how.\"", 
                "num": 65
              }, 
              {
                "text": "So they went and made the tomb secure, sealing the stone and setting the guard.", 
                "num": 66
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Now after the Sabbath, as the first day of the week began to dawn, Mary Magdalene and the other Mary came to see the tomb.", 
                "num": 1
              }, 
              {
                "text": "And behold, there was a great earthquake; for an angel of the Lord descended from heaven, and came and rolled back the stone from the door, and sat on it.", 
                "num": 2
              }, 
              {
                "text": "His countenance was like lightning, and his clothing as white as snow.", 
                "num": 3
              }, 
              {
                "text": "And the guards shook for fear of him, and became like dead men.", 
                "num": 4
              }, 
              {
                "text": "But the angel answered and said to the women, \"Do not be afraid, for I know that you seek Jesus who was crucified.", 
                "num": 5
              }, 
              {
                "text": "He is not here; for He is risen, as He said. Come, see the place where the Lord lay.", 
                "num": 6
              }, 
              {
                "text": "And go quickly and tell His disciples that He is risen from the dead, and indeed He is going before you into Galilee; there you will see Him. Behold, I have told you.\"", 
                "num": 7
              }, 
              {
                "text": "So they went out quickly from the tomb with fear and great joy, and ran to bring His disciples word.", 
                "num": 8
              }, 
              {
                "text": "And as they went to tell His disciples, behold, Jesus met them, saying, \"Rejoice!\" So they came and held Him by the feet and worshiped Him.", 
                "num": 9
              }, 
              {
                "text": "Then Jesus said to them, \"Do not be afraid. Go and tell My brethren to go to Galilee, and there they will see Me.\"", 
                "num": 10
              }, 
              {
                "text": "Now while they were going, behold, some of the guard came into the city and reported to the chief priests all the things that had happened.", 
                "num": 11
              }, 
              {
                "text": "When they had assembled with the elders and consulted together, they gave a large sum of money to the soldiers,", 
                "num": 12
              }, 
              {
                "text": "saying, \"Tell them, \"His disciples came at night and stole Him away while we slept.'", 
                "num": 13
              }, 
              {
                "text": "And if this comes to the governor's ears, we will appease him and make you secure.\"", 
                "num": 14
              }, 
              {
                "text": "So they took the money and did as they were instructed; and this saying is commonly reported among the Jews until this day.", 
                "num": 15
              }, 
              {
                "text": "Then the eleven disciples went away into Galilee, to the mountain which Jesus had appointed for them.", 
                "num": 16
              }, 
              {
                "text": "When they saw Him, they worshiped Him; but some doubted.", 
                "num": 17
              }, 
              {
                "text": "And Jesus came and spoke to them, saying, \"All authority has been given to Me in heaven and on earth.", 
                "num": 18
              }, 
              {
                "text": "Go therefore and make disciples of all the nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit,", 
                "num": 19
              }, 
              {
                "text": "teaching them to observe all things that I have commanded you; and lo, I am with you always, even to the end of the age.\" Amen.", 
                "num": 20
              }
            ], 
            "num": 28
          }
        ]
      }, 
      {
        "name": "Mark", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The beginning of the gospel of Jesus Christ, the Son of God.", 
                "num": 1
              }, 
              {
                "text": "As it is written in the Prophets: \"Behold, I send My messenger before Your face, Who will prepare Your way before You.\"", 
                "num": 2
              }, 
              {
                "text": "\"The voice of one crying in the wilderness: \"Prepare the way of the LORD; Make His paths straight.\"'", 
                "num": 3
              }, 
              {
                "text": "John came baptizing in the wilderness and preaching a baptism of repentance for the remission of sins.", 
                "num": 4
              }, 
              {
                "text": "Then all the land of Judea, and those from Jerusalem, went out to him and were all baptized by him in the Jordan River, confessing their sins.", 
                "num": 5
              }, 
              {
                "text": "Now John was clothed with camel's hair and with a leather belt around his waist, and he ate locusts and wild honey.", 
                "num": 6
              }, 
              {
                "text": "And he preached, saying, \"There comes One after me who is mightier than I, whose sandal strap I am not worthy to stoop down and loose.", 
                "num": 7
              }, 
              {
                "text": "I indeed baptized you with water, but He will baptize you with the Holy Spirit.\"", 
                "num": 8
              }, 
              {
                "text": "It came to pass in those days that Jesus came from Nazareth of Galilee, and was baptized by John in the Jordan.", 
                "num": 9
              }, 
              {
                "text": "And immediately, coming up from the water, He saw the heavens parting and the Spirit descending upon Him like a dove.", 
                "num": 10
              }, 
              {
                "text": "Then a voice came from heaven, \"You are My beloved Son, in whom I am well pleased.\"", 
                "num": 11
              }, 
              {
                "text": "Immediately the Spirit drove Him into the wilderness.", 
                "num": 12
              }, 
              {
                "text": "And He was there in the wilderness forty days, tempted by Satan, and was with the wild beasts; and the angels ministered to Him.", 
                "num": 13
              }, 
              {
                "text": "Now after John was put in prison, Jesus came to Galilee, preaching the gospel of the kingdom of God,", 
                "num": 14
              }, 
              {
                "text": "and saying, \"The time is fulfilled, and the kingdom of God is at hand. Repent, and believe in the gospel.\"", 
                "num": 15
              }, 
              {
                "text": "And as He walked by the Sea of Galilee, He saw Simon and Andrew his brother casting a net into the sea; for they were fishermen.", 
                "num": 16
              }, 
              {
                "text": "Then Jesus said to them, \"Follow Me, and I will make you become fishers of men.\"", 
                "num": 17
              }, 
              {
                "text": "They immediately left their nets and followed Him.", 
                "num": 18
              }, 
              {
                "text": "When He had gone a little farther from there, He saw James the son of Zebedee, and John his brother, who also were in the boat mending their nets.", 
                "num": 19
              }, 
              {
                "text": "And immediately He called them, and they left their father Zebedee in the boat with the hired servants, and went after Him.", 
                "num": 20
              }, 
              {
                "text": "Then they went into Capernaum, and immediately on the Sabbath He entered the synagogue and taught.", 
                "num": 21
              }, 
              {
                "text": "And they were astonished at His teaching, for He taught them as one having authority, and not as the scribes.", 
                "num": 22
              }, 
              {
                "text": "Now there was a man in their synagogue with an unclean spirit. And he cried out,", 
                "num": 23
              }, 
              {
                "text": "saying, \"Let us alone! What have we to do with You, Jesus of Nazareth? Did You come to destroy us? I know who You are--the Holy One of God!\"", 
                "num": 24
              }, 
              {
                "text": "But Jesus rebuked him, saying, \"Be quiet, and come out of him!\"", 
                "num": 25
              }, 
              {
                "text": "And when the unclean spirit had convulsed him and cried out with a loud voice, he came out of him.", 
                "num": 26
              }, 
              {
                "text": "Then they were all amazed, so that they questioned among themselves, saying, \"What is this? What new doctrine is this? For with authority He commands even the unclean spirits, and they obey Him.\"", 
                "num": 27
              }, 
              {
                "text": "And immediately His fame spread throughout all the region around Galilee.", 
                "num": 28
              }, 
              {
                "text": "Now as soon as they had come out of the synagogue, they entered the house of Simon and Andrew, with James and John.", 
                "num": 29
              }, 
              {
                "text": "But Simon's wife's mother lay sick with a fever, and they told Him about her at once.", 
                "num": 30
              }, 
              {
                "text": "So He came and took her by the hand and lifted her up, and immediately the fever left her. And she served them.", 
                "num": 31
              }, 
              {
                "text": "At evening, when the sun had set, they brought to Him all who were sick and those who were demon-possessed.", 
                "num": 32
              }, 
              {
                "text": "And the whole city was gathered together at the door.", 
                "num": 33
              }, 
              {
                "text": "Then He healed many who were sick with various diseases, and cast out many demons; and He did not allow the demons to speak, because they knew Him.", 
                "num": 34
              }, 
              {
                "text": "Now in the morning, having risen a long while before daylight, He went out and departed to a solitary place; and there He prayed.", 
                "num": 35
              }, 
              {
                "text": "And Simon and those who were with Him searched for Him.", 
                "num": 36
              }, 
              {
                "text": "When they found Him, they said to Him, \"Everyone is looking for You.\"", 
                "num": 37
              }, 
              {
                "text": "But He said to them, \"Let us go into the next towns, that I may preach there also, because for this purpose I have come forth.\"", 
                "num": 38
              }, 
              {
                "text": "And He was preaching in their synagogues throughout all Galilee, and casting out demons.", 
                "num": 39
              }, 
              {
                "text": "Now a leper came to Him, imploring Him, kneeling down to Him and saying to Him, \"If You are willing, You can make me clean.\"", 
                "num": 40
              }, 
              {
                "text": "Then Jesus, moved with compassion, stretched out His hand and touched him, and said to him, \"I am willing; be cleansed.\"", 
                "num": 41
              }, 
              {
                "text": "As soon as He had spoken, immediately the leprosy left him, and he was cleansed.", 
                "num": 42
              }, 
              {
                "text": "And He strictly warned him and sent him away at once,", 
                "num": 43
              }, 
              {
                "text": "and said to him, \"See that you say nothing to anyone; but go your way, show yourself to the priest, and offer for your cleansing those things which Moses commanded, as a testimony to them.\"", 
                "num": 44
              }, 
              {
                "text": "However, he went out and began to proclaim it freely, and to spread the matter, so that Jesus could no longer openly enter the city, but was outside in deserted places; and they came to Him from every direction.", 
                "num": 45
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And again He entered Capernaum after some days, and it was heard that He was in the house.", 
                "num": 1
              }, 
              {
                "text": "Immediately many gathered together, so that there was no longer room to receive them, not even near the door. And He preached the word to them.", 
                "num": 2
              }, 
              {
                "text": "Then they came to Him, bringing a paralytic who was carried by four men.", 
                "num": 3
              }, 
              {
                "text": "And when they could not come near Him because of the crowd, they uncovered the roof where He was. So when they had broken through, they let down the bed on which the paralytic was lying.", 
                "num": 4
              }, 
              {
                "text": "When Jesus saw their faith, He said to the paralytic, \"Son, your sins are forgiven you.\"", 
                "num": 5
              }, 
              {
                "text": "And some of the scribes were sitting there and reasoning in their hearts,", 
                "num": 6
              }, 
              {
                "text": "\"Why does this Man speak blasphemies like this? Who can forgive sins but God alone?\"", 
                "num": 7
              }, 
              {
                "text": "But immediately, when Jesus perceived in His spirit that they reasoned thus within themselves, He said to them, \"Why do you reason about these things in your hearts?", 
                "num": 8
              }, 
              {
                "text": "Which is easier, to say to the paralytic, \"Your sins are forgiven you,' or to say, \"Arise, take up your bed and walk'?", 
                "num": 9
              }, 
              {
                "text": "But that you may know that the Son of Man has power on earth to forgive sins\"--He said to the paralytic,", 
                "num": 10
              }, 
              {
                "text": "\"I say to you, arise, take up your bed, and go to your house.\"", 
                "num": 11
              }, 
              {
                "text": "Immediately he arose, took up the bed, and went out in the presence of them all, so that all were amazed and glorified God, saying, \"We never saw anything like this!\"", 
                "num": 12
              }, 
              {
                "text": "Then He went out again by the sea; and all the multitude came to Him, and He taught them.", 
                "num": 13
              }, 
              {
                "text": "As He passed by, He saw Levi the son of Alphaeus sitting at the tax office. And He said to him, \"Follow Me.\" So he arose and followed Him.", 
                "num": 14
              }, 
              {
                "text": "Now it happened, as He was dining in Levi's house, that many tax collectors and sinners also sat together with Jesus and His disciples; for there were many, and they followed Him.", 
                "num": 15
              }, 
              {
                "text": "And when the scribes and Pharisees saw Him eating with the tax collectors and sinners, they said to His disciples, \"How is it that He eats and drinks with tax collectors and sinners?\"", 
                "num": 16
              }, 
              {
                "text": "When Jesus heard it, He said to them, \"Those who are well have no need of a physician, but those who are sick. I did not come to call the righteous, but sinners, to repentance.\"", 
                "num": 17
              }, 
              {
                "text": "The disciples of John and of the Pharisees were fasting. Then they came and said to Him, \"Why do the disciples of John and of the Pharisees fast, but Your disciples do not fast?\"", 
                "num": 18
              }, 
              {
                "text": "And Jesus said to them, \"Can the friends of the bridegroom fast while the bridegroom is with them? As long as they have the bridegroom with them they cannot fast.", 
                "num": 19
              }, 
              {
                "text": "But the days will come when the bridegroom will be taken away from them, and then they will fast in those days.", 
                "num": 20
              }, 
              {
                "text": "No one sews a piece of unshrunk cloth on an old garment; or else the new piece pulls away from the old, and the tear is made worse.", 
                "num": 21
              }, 
              {
                "text": "And no one puts new wine into old wineskins; or else the new wine bursts the wineskins, the wine is spilled, and the wineskins are ruined. But new wine must be put into new wineskins.\"", 
                "num": 22
              }, 
              {
                "text": "Now it happened that He went through the grainfields on the Sabbath; and as they went His disciples began to pluck the heads of grain.", 
                "num": 23
              }, 
              {
                "text": "And the Pharisees said to Him, \"Look, why do they do what is not lawful on the Sabbath?\"", 
                "num": 24
              }, 
              {
                "text": "But He said to them, \"Have you never read what David did when he was in need and hungry, he and those with him:", 
                "num": 25
              }, 
              {
                "text": "how he went into the house of God in the days of Abiathar the high priest, and ate the showbread, which is not lawful to eat except for the priests, and also gave some to those who were with him?\"", 
                "num": 26
              }, 
              {
                "text": "And He said to them, \"The Sabbath was made for man, and not man for the Sabbath.", 
                "num": 27
              }, 
              {
                "text": "Therefore the Son of Man is also Lord of the Sabbath.\"", 
                "num": 28
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "And He entered the synagogue again, and a man was there who had a withered hand.", 
                "num": 1
              }, 
              {
                "text": "So they watched Him closely, whether He would heal him on the Sabbath, so that they might accuse Him.", 
                "num": 2
              }, 
              {
                "text": "And He said to the man who had the withered hand, \"Step forward.\"", 
                "num": 3
              }, 
              {
                "text": "Then He said to them, \"Is it lawful on the Sabbath to do good or to do evil, to save life or to kill?\" But they kept silent.", 
                "num": 4
              }, 
              {
                "text": "And when He had looked around at them with anger, being grieved by the hardness of their hearts, He said to the man, \"Stretch out your hand.\" And he stretched it out, and his hand was restored as whole as the other.", 
                "num": 5
              }, 
              {
                "text": "Then the Pharisees went out and immediately plotted with the Herodians against Him, how they might destroy Him.", 
                "num": 6
              }, 
              {
                "text": "But Jesus withdrew with His disciples to the sea. And a great multitude from Galilee followed Him, and from Judea", 
                "num": 7
              }, 
              {
                "text": "and Jerusalem and Idumea and beyond the Jordan; and those from Tyre and Sidon, a great multitude, when they heard how many things He was doing, came to Him.", 
                "num": 8
              }, 
              {
                "text": "So He told His disciples that a small boat should be kept ready for Him because of the multitude, lest they should crush Him.", 
                "num": 9
              }, 
              {
                "text": "For He healed many, so that as many as had afflictions pressed about Him to touch Him.", 
                "num": 10
              }, 
              {
                "text": "And the unclean spirits, whenever they saw Him, fell down before Him and cried out, saying, \"You are the Son of God.\"", 
                "num": 11
              }, 
              {
                "text": "But He sternly warned them that they should not make Him known.", 
                "num": 12
              }, 
              {
                "text": "And He went up on the mountain and called to Him those He Himself wanted. And they came to Him.", 
                "num": 13
              }, 
              {
                "text": "Then He appointed twelve, that they might be with Him and that He might send them out to preach,", 
                "num": 14
              }, 
              {
                "text": "and to have power to heal sicknesses and to cast out demons:", 
                "num": 15
              }, 
              {
                "text": "Simon, to whom He gave the name Peter;", 
                "num": 16
              }, 
              {
                "text": "James the son of Zebedee and John the brother of James, to whom He gave the name Boanerges, that is, \"Sons of Thunder\";", 
                "num": 17
              }, 
              {
                "text": "Andrew, Philip, Bartholomew, Matthew, Thomas, James the son of Alphaeus, Thaddaeus, Simon the Cananite;", 
                "num": 18
              }, 
              {
                "text": "and Judas Iscariot, who also betrayed Him. And they went into a house.", 
                "num": 19
              }, 
              {
                "text": "Then the multitude came together again, so that they could not so much as eat bread.", 
                "num": 20
              }, 
              {
                "text": "But when His own people heard about this, they went out to lay hold of Him, for they said, \"He is out of His mind.\"", 
                "num": 21
              }, 
              {
                "text": "And the scribes who came down from Jerusalem said, \"He has Beelzebub,\" and, \"By the ruler of the demons He casts out demons.\"", 
                "num": 22
              }, 
              {
                "text": "So He called them to Himself and said to them in parables: \"How can Satan cast out Satan?", 
                "num": 23
              }, 
              {
                "text": "If a kingdom is divided against itself, that kingdom cannot stand.", 
                "num": 24
              }, 
              {
                "text": "And if a house is divided against itself, that house cannot stand.", 
                "num": 25
              }, 
              {
                "text": "And if Satan has risen up against himself, and is divided, he cannot stand, but has an end.", 
                "num": 26
              }, 
              {
                "text": "No one can enter a strong man's house and plunder his goods, unless he first binds the strong man. And then he will plunder his house.", 
                "num": 27
              }, 
              {
                "text": "\"Assuredly, I say to you, all sins will be forgiven the sons of men, and whatever blasphemies they may utter;", 
                "num": 28
              }, 
              {
                "text": "but he who blasphemes against the Holy Spirit never has forgiveness, but is subject to eternal condemnation\"--", 
                "num": 29
              }, 
              {
                "text": "because they said, \"He has an unclean spirit.\"", 
                "num": 30
              }, 
              {
                "text": "Then His brothers and His mother came, and standing outside they sent to Him, calling Him.", 
                "num": 31
              }, 
              {
                "text": "And a multitude was sitting around Him; and they said to Him, \"Look, Your mother and Your brothers are outside seeking You.\"", 
                "num": 32
              }, 
              {
                "text": "But He answered them, saying, \"Who is My mother, or My brothers?\"", 
                "num": 33
              }, 
              {
                "text": "And He looked around in a circle at those who sat about Him, and said, \"Here are My mother and My brothers!", 
                "num": 34
              }, 
              {
                "text": "For whoever does the will of God is My brother and My sister and mother.\"", 
                "num": 35
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "And again He began to teach by the sea. And a great multitude was gathered to Him, so that He got into a boat and sat in it on the sea; and the whole multitude was on the land facing the sea.", 
                "num": 1
              }, 
              {
                "text": "Then He taught them many things by parables, and said to them in His teaching:", 
                "num": 2
              }, 
              {
                "text": "\"Listen! Behold, a sower went out to sow.", 
                "num": 3
              }, 
              {
                "text": "And it happened, as he sowed, that some seed fell by the wayside; and the birds of the air came and devoured it.", 
                "num": 4
              }, 
              {
                "text": "Some fell on stony ground, where it did not have much earth; and immediately it sprang up because it had no depth of earth.", 
                "num": 5
              }, 
              {
                "text": "But when the sun was up it was scorched, and because it had no root it withered away.", 
                "num": 6
              }, 
              {
                "text": "And some seed fell among thorns; and the thorns grew up and choked it, and it yielded no crop.", 
                "num": 7
              }, 
              {
                "text": "But other seed fell on good ground and yielded a crop that sprang up, increased and produced: some thirtyfold, some sixty, and some a hundred.\"", 
                "num": 8
              }, 
              {
                "text": "And He said to them, \"He who has ears to hear, let him hear!\"", 
                "num": 9
              }, 
              {
                "text": "But when He was alone, those around Him with the twelve asked Him about the parable.", 
                "num": 10
              }, 
              {
                "text": "And He said to them, \"To you it has been given to know the mystery of the kingdom of God; but to those who are outside, all things come in parables,", 
                "num": 11
              }, 
              {
                "text": "so that \"Seeing they may see and not perceive, And hearing they may hear and not understand; Lest they should turn, And their sins be forgiven them.\"'", 
                "num": 12
              }, 
              {
                "text": "And He said to them, \"Do you not understand this parable? How then will you understand all the parables?", 
                "num": 13
              }, 
              {
                "text": "The sower sows the word.", 
                "num": 14
              }, 
              {
                "text": "And these are the ones by the wayside where the word is sown. When they hear, Satan comes immediately and takes away the word that was sown in their hearts.", 
                "num": 15
              }, 
              {
                "text": "These likewise are the ones sown on stony ground who, when they hear the word, immediately receive it with gladness;", 
                "num": 16
              }, 
              {
                "text": "and they have no root in themselves, and so endure only for a time. Afterward, when tribulation or persecution arises for the word's sake, immediately they stumble.", 
                "num": 17
              }, 
              {
                "text": "Now these are the ones sown among thorns; they are the ones who hear the word,", 
                "num": 18
              }, 
              {
                "text": "and the cares of this world, the deceitfulness of riches, and the desires for other things entering in choke the word, and it becomes unfruitful.", 
                "num": 19
              }, 
              {
                "text": "But these are the ones sown on good ground, those who hear the word, accept it, and bear fruit: some thirtyfold, some sixty, and some a hundred.\"", 
                "num": 20
              }, 
              {
                "text": "Also He said to them, \"Is a lamp brought to be put under a basket or under a bed? Is it not to be set on a lampstand?", 
                "num": 21
              }, 
              {
                "text": "For there is nothing hidden which will not be revealed, nor has anything been kept secret but that it should come to light.", 
                "num": 22
              }, 
              {
                "text": "If anyone has ears to hear, let him hear.\"", 
                "num": 23
              }, 
              {
                "text": "Then He said to them, \"Take heed what you hear. With the same measure you use, it will be measured to you; and to you who hear, more will be given.", 
                "num": 24
              }, 
              {
                "text": "For whoever has, to him more will be given; but whoever does not have, even what he has will be taken away from him.\"", 
                "num": 25
              }, 
              {
                "text": "And He said, \"The kingdom of God is as if a man should scatter seed on the ground,", 
                "num": 26
              }, 
              {
                "text": "and should sleep by night and rise by day, and the seed should sprout and grow, he himself does not know how.", 
                "num": 27
              }, 
              {
                "text": "For the earth yields crops by itself: first the blade, then the head, after that the full grain in the head.", 
                "num": 28
              }, 
              {
                "text": "But when the grain ripens, immediately he puts in the sickle, because the harvest has come.\"", 
                "num": 29
              }, 
              {
                "text": "Then He said, \"To what shall we liken the kingdom of God? Or with what parable shall we picture it?", 
                "num": 30
              }, 
              {
                "text": "It is like a mustard seed which, when it is sown on the ground, is smaller than all the seeds on earth;", 
                "num": 31
              }, 
              {
                "text": "but when it is sown, it grows up and becomes greater than all herbs, and shoots out large branches, so that the birds of the air may nest under its shade.\"", 
                "num": 32
              }, 
              {
                "text": "And with many such parables He spoke the word to them as they were able to hear it.", 
                "num": 33
              }, 
              {
                "text": "But without a parable He did not speak to them. And when they were alone, He explained all things to His disciples.", 
                "num": 34
              }, 
              {
                "text": "On the same day, when evening had come, He said to them, \"Let us cross over to the other side.\"", 
                "num": 35
              }, 
              {
                "text": "Now when they had left the multitude, they took Him along in the boat as He was. And other little boats were also with Him.", 
                "num": 36
              }, 
              {
                "text": "And a great windstorm arose, and the waves beat into the boat, so that it was already filling.", 
                "num": 37
              }, 
              {
                "text": "But He was in the stern, asleep on a pillow. And they awoke Him and said to Him, \"Teacher, do You not care that we are perishing?\"", 
                "num": 38
              }, 
              {
                "text": "Then He arose and rebuked the wind, and said to the sea, \"Peace, be still!\" And the wind ceased and there was a great calm.", 
                "num": 39
              }, 
              {
                "text": "But He said to them, \"Why are you so fearful? How is it that you have no faith?\"", 
                "num": 40
              }, 
              {
                "text": "And they feared exceedingly, and said to one another, \"Who can this be, that even the wind and the sea obey Him!\"", 
                "num": 41
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Then they came to the other side of the sea, to the country of the Gadarenes.", 
                "num": 1
              }, 
              {
                "text": "And when He had come out of the boat, immediately there met Him out of the tombs a man with an unclean spirit,", 
                "num": 2
              }, 
              {
                "text": "who had his dwelling among the tombs; and no one could bind him, not even with chains,", 
                "num": 3
              }, 
              {
                "text": "because he had often been bound with shackles and chains. And the chains had been pulled apart by him, and the shackles broken in pieces; neither could anyone tame him.", 
                "num": 4
              }, 
              {
                "text": "And always, night and day, he was in the mountains and in the tombs, crying out and cutting himself with stones.", 
                "num": 5
              }, 
              {
                "text": "When he saw Jesus from afar, he ran and worshiped Him.", 
                "num": 6
              }, 
              {
                "text": "And he cried out with a loud voice and said, \"What have I to do with You, Jesus, Son of the Most High God? I implore You by God that You do not torment me.\"", 
                "num": 7
              }, 
              {
                "text": "For He said to him, \"Come out of the man, unclean spirit!\"", 
                "num": 8
              }, 
              {
                "text": "Then He asked him, \"What is your name?\" And he answered, saying, \"My name is Legion; for we are many.\"", 
                "num": 9
              }, 
              {
                "text": "Also he begged Him earnestly that He would not send them out of the country.", 
                "num": 10
              }, 
              {
                "text": "Now a large herd of swine was feeding there near the mountains.", 
                "num": 11
              }, 
              {
                "text": "So all the demons begged Him, saying, \"Send us to the swine, that we may enter them.\"", 
                "num": 12
              }, 
              {
                "text": "And at once Jesus gave them permission. Then the unclean spirits went out and entered the swine (there were about two thousand); and the herd ran violently down the steep place into the sea, and drowned in the sea.", 
                "num": 13
              }, 
              {
                "text": "So those who fed the swine fled, and they told it in the city and in the country. And they went out to see what it was that had happened.", 
                "num": 14
              }, 
              {
                "text": "Then they came to Jesus, and saw the one who had been demon-possessed and had the legion, sitting and clothed and in his right mind. And they were afraid.", 
                "num": 15
              }, 
              {
                "text": "And those who saw it told them how it happened to him who had been demon-possessed, and about the swine.", 
                "num": 16
              }, 
              {
                "text": "Then they began to plead with Him to depart from their region.", 
                "num": 17
              }, 
              {
                "text": "And when He got into the boat, he who had been demon-possessed begged Him that he might be with Him.", 
                "num": 18
              }, 
              {
                "text": "However, Jesus did not permit him, but said to him, \"Go home to your friends, and tell them what great things the Lord has done for you, and how He has had compassion on you.\"", 
                "num": 19
              }, 
              {
                "text": "And he departed and began to proclaim in Decapolis all that Jesus had done for him; and all marveled.", 
                "num": 20
              }, 
              {
                "text": "Now when Jesus had crossed over again by boat to the other side, a great multitude gathered to Him; and He was by the sea.", 
                "num": 21
              }, 
              {
                "text": "And behold, one of the rulers of the synagogue came, Jairus by name. And when he saw Him, he fell at His feet", 
                "num": 22
              }, 
              {
                "text": "and begged Him earnestly, saying, \"My little daughter lies at the point of death. Come and lay Your hands on her, that she may be healed, and she will live.\"", 
                "num": 23
              }, 
              {
                "text": "So Jesus went with him, and a great multitude followed Him and thronged Him.", 
                "num": 24
              }, 
              {
                "text": "Now a certain woman had a flow of blood for twelve years,", 
                "num": 25
              }, 
              {
                "text": "and had suffered many things from many physicians. She had spent all that she had and was no better, but rather grew worse.", 
                "num": 26
              }, 
              {
                "text": "When she heard about Jesus, she came behind Him in the crowd and touched His garment.", 
                "num": 27
              }, 
              {
                "text": "For she said, \"If only I may touch His clothes, I shall be made well.\"", 
                "num": 28
              }, 
              {
                "text": "Immediately the fountain of her blood was dried up, and she felt in her body that she was healed of the affliction.", 
                "num": 29
              }, 
              {
                "text": "And Jesus, immediately knowing in Himself that power had gone out of Him, turned around in the crowd and said, \"Who touched My clothes?\"", 
                "num": 30
              }, 
              {
                "text": "But His disciples said to Him, \"You see the multitude thronging You, and You say, \"Who touched Me?\"'", 
                "num": 31
              }, 
              {
                "text": "And He looked around to see her who had done this thing.", 
                "num": 32
              }, 
              {
                "text": "But the woman, fearing and trembling, knowing what had happened to her, came and fell down before Him and told Him the whole truth.", 
                "num": 33
              }, 
              {
                "text": "And He said to her, \"Daughter, your faith has made you well. Go in peace, and be healed of your affliction.\"", 
                "num": 34
              }, 
              {
                "text": "While He was still speaking, some came from the ruler of the synagogue's house who said, \"Your daughter is dead. Why trouble the Teacher any further?\"", 
                "num": 35
              }, 
              {
                "text": "As soon as Jesus heard the word that was spoken, He said to the ruler of the synagogue, \"Do not be afraid; only believe.\"", 
                "num": 36
              }, 
              {
                "text": "And He permitted no one to follow Him except Peter, James, and John the brother of James.", 
                "num": 37
              }, 
              {
                "text": "Then He came to the house of the ruler of the synagogue, and saw a tumult and those who wept and wailed loudly.", 
                "num": 38
              }, 
              {
                "text": "When He came in, He said to them, \"Why make this commotion and weep? The child is not dead, but sleeping.\"", 
                "num": 39
              }, 
              {
                "text": "And they ridiculed Him. But when He had put them all outside, He took the father and the mother of the child, and those who were with Him, and entered where the child was lying.", 
                "num": 40
              }, 
              {
                "text": "Then He took the child by the hand, and said to her, \"Talitha, cumi,\" which is translated, \"Little girl, I say to you, arise.\"", 
                "num": 41
              }, 
              {
                "text": "Immediately the girl arose and walked, for she was twelve years of age. And they were overcome with great amazement.", 
                "num": 42
              }, 
              {
                "text": "But He commanded them strictly that no one should know it, and said that something should be given her to eat.", 
                "num": 43
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Then He went out from there and came to His own country, and His disciples followed Him.", 
                "num": 1
              }, 
              {
                "text": "And when the Sabbath had come, He began to teach in the synagogue. And many hearing Him were astonished, saying, \"Where did this Man get these things? And what wisdom is this which is given to Him, that such mighty works are performed by His hands!", 
                "num": 2
              }, 
              {
                "text": "Is this not the carpenter, the Son of Mary, and brother of James, Joses, Judas, and Simon? And are not His sisters here with us?\" So they were offended at Him.", 
                "num": 3
              }, 
              {
                "text": "But Jesus said to them, \"A prophet is not without honor except in his own country, among his own relatives, and in his own house.\"", 
                "num": 4
              }, 
              {
                "text": "Now He could do no mighty work there, except that He laid His hands on a few sick people and healed them.", 
                "num": 5
              }, 
              {
                "text": "And He marveled because of their unbelief. Then He went about the villages in a circuit, teaching.", 
                "num": 6
              }, 
              {
                "text": "And He called the twelve to Himself, and began to send them out two by two, and gave them power over unclean spirits.", 
                "num": 7
              }, 
              {
                "text": "He commanded them to take nothing for the journey except a staff--no bag, no bread, no copper in their money belts--", 
                "num": 8
              }, 
              {
                "text": "but to wear sandals, and not to put on two tunics.", 
                "num": 9
              }, 
              {
                "text": "Also He said to them, \"In whatever place you enter a house, stay there till you depart from that place.", 
                "num": 10
              }, 
              {
                "text": "And whoever will not receive you nor hear you, when you depart from there, shake off the dust under your feet as a testimony against them. Assuredly, I say to you, it will be more tolerable for Sodom and Gomorrah in the day of judgment than for that city!\"", 
                "num": 11
              }, 
              {
                "text": "So they went out and preached that people should repent.", 
                "num": 12
              }, 
              {
                "text": "And they cast out many demons, and anointed with oil many who were sick, and healed them.", 
                "num": 13
              }, 
              {
                "text": "Now King Herod heard of Him, for His name had become well known. And he said, \"John the Baptist is risen from the dead, and therefore these powers are at work in him.\"", 
                "num": 14
              }, 
              {
                "text": "Others said, \"It is Elijah.\" And others said, \"It is the Prophet, or like one of the prophets.\"", 
                "num": 15
              }, 
              {
                "text": "But when Herod heard, he said, \"This is John, whom I beheaded; he has been raised from the dead!\"", 
                "num": 16
              }, 
              {
                "text": "For Herod himself had sent and laid hold of John, and bound him in prison for the sake of Herodias, his brother Philip's wife; for he had married her.", 
                "num": 17
              }, 
              {
                "text": "Because John had said to Herod, \"It is not lawful for you to have your brother's wife.\"", 
                "num": 18
              }, 
              {
                "text": "Therefore Herodias held it against him and wanted to kill him, but she could not;", 
                "num": 19
              }, 
              {
                "text": "for Herod feared John, knowing that he was a just and holy man, and he protected him. And when he heard him, he did many things, and heard him gladly.", 
                "num": 20
              }, 
              {
                "text": "Then an opportune day came when Herod on his birthday gave a feast for his nobles, the high officers, and the chief men of Galilee.", 
                "num": 21
              }, 
              {
                "text": "And when Herodias' daughter herself came in and danced, and pleased Herod and those who sat with him, the king said to the girl, \"Ask me whatever you want, and I will give it to you.\"", 
                "num": 22
              }, 
              {
                "text": "He also swore to her, \"Whatever you ask me, I will give you, up to half my kingdom.\"", 
                "num": 23
              }, 
              {
                "text": "So she went out and said to her mother, \"What shall I ask?\" And she said, \"The head of John the Baptist!\"", 
                "num": 24
              }, 
              {
                "text": "Immediately she came in with haste to the king and asked, saying, \"I want you to give me at once the head of John the Baptist on a platter.\"", 
                "num": 25
              }, 
              {
                "text": "And the king was exceedingly sorry; yet, because of the oaths and because of those who sat with him, he did not want to refuse her.", 
                "num": 26
              }, 
              {
                "text": "Immediately the king sent an executioner and commanded his head to be brought. And he went and beheaded him in prison,", 
                "num": 27
              }, 
              {
                "text": "brought his head on a platter, and gave it to the girl; and the girl gave it to her mother.", 
                "num": 28
              }, 
              {
                "text": "When his disciples heard of it, they came and took away his corpse and laid it in a tomb.", 
                "num": 29
              }, 
              {
                "text": "Then the apostles gathered to Jesus and told Him all things, both what they had done and what they had taught.", 
                "num": 30
              }, 
              {
                "text": "And He said to them, \"Come aside by yourselves to a deserted place and rest a while.\" For there were many coming and going, and they did not even have time to eat.", 
                "num": 31
              }, 
              {
                "text": "So they departed to a deserted place in the boat by themselves.", 
                "num": 32
              }, 
              {
                "text": "But the multitudes saw them departing, and many knew Him and ran there on foot from all the cities. They arrived before them and came together to Him.", 
                "num": 33
              }, 
              {
                "text": "And Jesus, when He came out, saw a great multitude and was moved with compassion for them, because they were like sheep not having a shepherd. So He began to teach them many things.", 
                "num": 34
              }, 
              {
                "text": "When the day was now far spent, His disciples came to Him and said, \"This is a deserted place, and already the hour is late.", 
                "num": 35
              }, 
              {
                "text": "Send them away, that they may go into the surrounding country and villages and buy themselves bread; for they have nothing to eat.\"", 
                "num": 36
              }, 
              {
                "text": "But He answered and said to them, \"You give them something to eat.\" And they said to Him, \"Shall we go and buy two hundred denarii worth of bread and give them something to eat?\"", 
                "num": 37
              }, 
              {
                "text": "But He said to them, \"How many loaves do you have? Go and see.\" And when they found out they said, \"Five, and two fish.\"", 
                "num": 38
              }, 
              {
                "text": "Then He commanded them to make them all sit down in groups on the green grass.", 
                "num": 39
              }, 
              {
                "text": "So they sat down in ranks, in hundreds and in fifties.", 
                "num": 40
              }, 
              {
                "text": "And when He had taken the five loaves and the two fish, He looked up to heaven, blessed and broke the loaves, and gave them to His disciples to set before them; and the two fish He divided among them all.", 
                "num": 41
              }, 
              {
                "text": "So they all ate and were filled.", 
                "num": 42
              }, 
              {
                "text": "And they took up twelve baskets full of fragments and of the fish.", 
                "num": 43
              }, 
              {
                "text": "Now those who had eaten the loaves were about five thousand men.", 
                "num": 44
              }, 
              {
                "text": "Immediately He made His disciples get into the boat and go before Him to the other side, to Bethsaida, while He sent the multitude away.", 
                "num": 45
              }, 
              {
                "text": "And when He had sent them away, He departed to the mountain to pray.", 
                "num": 46
              }, 
              {
                "text": "Now when evening came, the boat was in the middle of the sea; and He was alone on the land.", 
                "num": 47
              }, 
              {
                "text": "Then He saw them straining at rowing, for the wind was against them. Now about the fourth watch of the night He came to them, walking on the sea, and would have passed them by.", 
                "num": 48
              }, 
              {
                "text": "And when they saw Him walking on the sea, they supposed it was a ghost, and cried out;", 
                "num": 49
              }, 
              {
                "text": "for they all saw Him and were troubled. But immediately He talked with them and said to them, \"Be of good cheer! It is I; do not be afraid.\"", 
                "num": 50
              }, 
              {
                "text": "Then He went up into the boat to them, and the wind ceased. And they were greatly amazed in themselves beyond measure, and marveled.", 
                "num": 51
              }, 
              {
                "text": "For they had not understood about the loaves, because their heart was hardened.", 
                "num": 52
              }, 
              {
                "text": "When they had crossed over, they came to the land of Gennesaret and anchored there.", 
                "num": 53
              }, 
              {
                "text": "And when they came out of the boat, immediately the people recognized Him,", 
                "num": 54
              }, 
              {
                "text": "ran through that whole surrounding region, and began to carry about on beds those who were sick to wherever they heard He was.", 
                "num": 55
              }, 
              {
                "text": "Wherever He entered, into villages, cities, or the country, they laid the sick in the marketplaces, and begged Him that they might just touch the hem of His garment. And as many as touched Him were made well.", 
                "num": 56
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then the Pharisees and some of the scribes came together to Him, having come from Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Now when they saw some of His disciples eat bread with defiled, that is, with unwashed hands, they found fault.", 
                "num": 2
              }, 
              {
                "text": "For the Pharisees and all the Jews do not eat unless they wash their hands in a special way, holding the tradition of the elders.", 
                "num": 3
              }, 
              {
                "text": "When they come from the marketplace, they do not eat unless they wash. And there are many other things which they have received and hold, like the washing of cups, pitchers, copper vessels, and couches.", 
                "num": 4
              }, 
              {
                "text": "Then the Pharisees and scribes asked Him, \"Why do Your disciples not walk according to the tradition of the elders, but eat bread with unwashed hands?\"", 
                "num": 5
              }, 
              {
                "text": "He answered and said to them, \"Well did Isaiah prophesy of you hypocrites, as it is written: \"This people honors Me with their lips, But their heart is far from Me.", 
                "num": 6
              }, 
              {
                "text": "And in vain they worship Me, Teaching as doctrines the commandments of men.'", 
                "num": 7
              }, 
              {
                "text": "For laying aside the commandment of God, you hold the tradition of men--the washing of pitchers and cups, and many other such things you do.\"", 
                "num": 8
              }, 
              {
                "text": "He said to them, \"All too well you reject the commandment of God, that you may keep your tradition.", 
                "num": 9
              }, 
              {
                "text": "For Moses said, \"Honor your father and your mother'; and, \"He who curses father or mother, let him be put to death.'", 
                "num": 10
              }, 
              {
                "text": "But you say, \"If a man says to his father or mother, \"Whatever profit you might have received from me is Corban\"--' (that is, a gift to God),", 
                "num": 11
              }, 
              {
                "text": "then you no longer let him do anything for his father or his mother,", 
                "num": 12
              }, 
              {
                "text": "making the word of God of no effect through your tradition which you have handed down. And many such things you do.\"", 
                "num": 13
              }, 
              {
                "text": "When He had called all the multitude to Himself, He said to them, \"Hear Me, everyone, and understand:", 
                "num": 14
              }, 
              {
                "text": "There is nothing that enters a man from outside which can defile him; but the things which come out of him, those are the things that defile a man.", 
                "num": 15
              }, 
              {
                "text": "If anyone has ears to hear, let him hear!\"", 
                "num": 16
              }, 
              {
                "text": "When He had entered a house away from the crowd, His disciples asked Him concerning the parable.", 
                "num": 17
              }, 
              {
                "text": "So He said to them, \"Are you thus without understanding also? Do you not perceive that whatever enters a man from outside cannot defile him,", 
                "num": 18
              }, 
              {
                "text": "because it does not enter his heart but his stomach, and is eliminated, thus purifying all foods?\"", 
                "num": 19
              }, 
              {
                "text": "And He said, \"What comes out of a man, that defiles a man.", 
                "num": 20
              }, 
              {
                "text": "For from within, out of the heart of men, proceed evil thoughts, adulteries, fornications, murders,", 
                "num": 21
              }, 
              {
                "text": "thefts, covetousness, wickedness, deceit, lewdness, an evil eye, blasphemy, pride, foolishness.", 
                "num": 22
              }, 
              {
                "text": "All these evil things come from within and defile a man.\"", 
                "num": 23
              }, 
              {
                "text": "From there He arose and went to the region of Tyre and Sidon. And He entered a house and wanted no one to know it, but He could not be hidden.", 
                "num": 24
              }, 
              {
                "text": "For a woman whose young daughter had an unclean spirit heard about Him, and she came and fell at His feet.", 
                "num": 25
              }, 
              {
                "text": "The woman was a Greek, a Syro-Phoenician by birth, and she kept asking Him to cast the demon out of her daughter.", 
                "num": 26
              }, 
              {
                "text": "But Jesus said to her, \"Let the children be filled first, for it is not good to take the children's bread and throw it to the little dogs.\"", 
                "num": 27
              }, 
              {
                "text": "And she answered and said to Him, \"Yes, Lord, yet even the little dogs under the table eat from the children's crumbs.\"", 
                "num": 28
              }, 
              {
                "text": "Then He said to her, \"For this saying go your way; the demon has gone out of your daughter.\"", 
                "num": 29
              }, 
              {
                "text": "And when she had come to her house, she found the demon gone out, and her daughter lying on the bed.", 
                "num": 30
              }, 
              {
                "text": "Again, departing from the region of Tyre and Sidon, He came through the midst of the region of Decapolis to the Sea of Galilee.", 
                "num": 31
              }, 
              {
                "text": "Then they brought to Him one who was deaf and had an impediment in his speech, and they begged Him to put His hand on him.", 
                "num": 32
              }, 
              {
                "text": "And He took him aside from the multitude, and put His fingers in his ears, and He spat and touched his tongue.", 
                "num": 33
              }, 
              {
                "text": "Then, looking up to heaven, He sighed, and said to him, \"Ephphatha,\" that is, \"Be opened.\"", 
                "num": 34
              }, 
              {
                "text": "Immediately his ears were opened, and the impediment of his tongue was loosed, and he spoke plainly.", 
                "num": 35
              }, 
              {
                "text": "Then He commanded them that they should tell no one; but the more He commanded them, the more widely they proclaimed it.", 
                "num": 36
              }, 
              {
                "text": "And they were astonished beyond measure, saying, \"He has done all things well. He makes both the deaf to hear and the mute to speak.\"", 
                "num": 37
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "In those days, the multitude being very great and having nothing to eat, Jesus called His disciples to Him and said to them,", 
                "num": 1
              }, 
              {
                "text": "\"I have compassion on the multitude, because they have now continued with Me three days and have nothing to eat.", 
                "num": 2
              }, 
              {
                "text": "And if I send them away hungry to their own houses, they will faint on the way; for some of them have come from afar.\"", 
                "num": 3
              }, 
              {
                "text": "Then His disciples answered Him, \"How can one satisfy these people with bread here in the wilderness?\"", 
                "num": 4
              }, 
              {
                "text": "He asked them, \"How many loaves do you have?\" And they said, \"Seven.\"", 
                "num": 5
              }, 
              {
                "text": "So He commanded the multitude to sit down on the ground. And He took the seven loaves and gave thanks, broke them and gave them to His disciples to set before them; and they set them before the multitude.", 
                "num": 6
              }, 
              {
                "text": "They also had a few small fish; and having blessed them, He said to set them also before them.", 
                "num": 7
              }, 
              {
                "text": "So they ate and were filled, and they took up seven large baskets of leftover fragments.", 
                "num": 8
              }, 
              {
                "text": "Now those who had eaten were about four thousand. And He sent them away,", 
                "num": 9
              }, 
              {
                "text": "immediately got into the boat with His disciples, and came to the region of Dalmanutha.", 
                "num": 10
              }, 
              {
                "text": "Then the Pharisees came out and began to dispute with Him, seeking from Him a sign from heaven, testing Him.", 
                "num": 11
              }, 
              {
                "text": "But He sighed deeply in His spirit, and said, \"Why does this generation seek a sign? Assuredly, I say to you, no sign shall be given to this generation.\"", 
                "num": 12
              }, 
              {
                "text": "And He left them, and getting into the boat again, departed to the other side.", 
                "num": 13
              }, 
              {
                "text": "Now the disciples had forgotten to take bread, and they did not have more than one loaf with them in the boat.", 
                "num": 14
              }, 
              {
                "text": "Then He charged them, saying, \"Take heed, beware of the leaven of the Pharisees and the leaven of Herod.\"", 
                "num": 15
              }, 
              {
                "text": "And they reasoned among themselves, saying, \"It is because we have no bread.\"", 
                "num": 16
              }, 
              {
                "text": "But Jesus, being aware of it, said to them, \"Why do you reason because you have no bread? Do you not yet perceive nor understand? Is your heart still hardened?", 
                "num": 17
              }, 
              {
                "text": "Having eyes, do you not see? And having ears, do you not hear? And do you not remember?", 
                "num": 18
              }, 
              {
                "text": "When I broke the five loaves for the five thousand, how many baskets full of fragments did you take up?\" They said to Him, \"Twelve.\"", 
                "num": 19
              }, 
              {
                "text": "\"Also, when I broke the seven for the four thousand, how many large baskets full of fragments did you take up?\" And they said, \"Seven.\"", 
                "num": 20
              }, 
              {
                "text": "So He said to them, \"How is it you do not understand?\"", 
                "num": 21
              }, 
              {
                "text": "Then He came to Bethsaida; and they brought a blind man to Him, and begged Him to touch him.", 
                "num": 22
              }, 
              {
                "text": "So He took the blind man by the hand and led him out of the town. And when He had spit on his eyes and put His hands on him, He asked him if he saw anything.", 
                "num": 23
              }, 
              {
                "text": "And he looked up and said, \"I see men like trees, walking.\"", 
                "num": 24
              }, 
              {
                "text": "Then He put His hands on his eyes again and made him look up. And he was restored and saw everyone clearly.", 
                "num": 25
              }, 
              {
                "text": "Then He sent him away to his house, saying, \"Neither go into the town, nor tell anyone in the town.\"", 
                "num": 26
              }, 
              {
                "text": "Now Jesus and His disciples went out to the towns of Caesarea Philippi; and on the road He asked His disciples, saying to them, \"Who do men say that I am?\"", 
                "num": 27
              }, 
              {
                "text": "So they answered, \"John the Baptist; but some say, Elijah; and others, one of the prophets.\"", 
                "num": 28
              }, 
              {
                "text": "He said to them, \"But who do you say that I am?\" Peter answered and said to Him, \"You are the Christ.\"", 
                "num": 29
              }, 
              {
                "text": "Then He strictly warned them that they should tell no one about Him.", 
                "num": 30
              }, 
              {
                "text": "And He began to teach them that the Son of Man must suffer many things, and be rejected by the elders and chief priests and scribes, and be killed, and after three days rise again.", 
                "num": 31
              }, 
              {
                "text": "He spoke this word openly. Then Peter took Him aside and began to rebuke Him.", 
                "num": 32
              }, 
              {
                "text": "But when He had turned around and looked at His disciples, He rebuked Peter, saying, \"Get behind Me, Satan! For you are not mindful of the things of God, but the things of men.\"", 
                "num": 33
              }, 
              {
                "text": "When He had called the people to Himself, with His disciples also, He said to them, \"Whoever desires to come after Me, let him deny himself, and take up his cross, and follow Me.", 
                "num": 34
              }, 
              {
                "text": "For whoever desires to save his life will lose it, but whoever loses his life for My sake and the gospel's will save it.", 
                "num": 35
              }, 
              {
                "text": "For what will it profit a man if he gains the whole world, and loses his own soul?", 
                "num": 36
              }, 
              {
                "text": "Or what will a man give in exchange for his soul?", 
                "num": 37
              }, 
              {
                "text": "For whoever is ashamed of Me and My words in this adulterous and sinful generation, of him the Son of Man also will be ashamed when He comes in the glory of His Father with the holy angels.\"", 
                "num": 38
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "And He said to them, \"Assuredly, I say to you that there are some standing here who will not taste death till they see the kingdom of God present with power.\"", 
                "num": 1
              }, 
              {
                "text": "Now after six days Jesus took Peter, James, and John, and led them up on a high mountain apart by themselves; and He was transfigured before them.", 
                "num": 2
              }, 
              {
                "text": "His clothes became shining, exceedingly white, like snow, such as no launderer on earth can whiten them.", 
                "num": 3
              }, 
              {
                "text": "And Elijah appeared to them with Moses, and they were talking with Jesus.", 
                "num": 4
              }, 
              {
                "text": "Then Peter answered and said to Jesus, \"Rabbi, it is good for us to be here; and let us make three tabernacles: one for You, one for Moses, and one for Elijah\"--", 
                "num": 5
              }, 
              {
                "text": "because he did not know what to say, for they were greatly afraid.", 
                "num": 6
              }, 
              {
                "text": "And a cloud came and overshadowed them; and a voice came out of the cloud, saying, \"This is My beloved Son. Hear Him!\"", 
                "num": 7
              }, 
              {
                "text": "Suddenly, when they had looked around, they saw no one anymore, but only Jesus with themselves.", 
                "num": 8
              }, 
              {
                "text": "Now as they came down from the mountain, He commanded them that they should tell no one the things they had seen, till the Son of Man had risen from the dead.", 
                "num": 9
              }, 
              {
                "text": "So they kept this word to themselves, questioning what the rising from the dead meant.", 
                "num": 10
              }, 
              {
                "text": "And they asked Him, saying, \"Why do the scribes say that Elijah must come first?\"", 
                "num": 11
              }, 
              {
                "text": "Then He answered and told them, \"Indeed, Elijah is coming first and restores all things. And how is it written concerning the Son of Man, that He must suffer many things and be treated with contempt?", 
                "num": 12
              }, 
              {
                "text": "But I say to you that Elijah has also come, and they did to him whatever they wished, as it is written of him.\"", 
                "num": 13
              }, 
              {
                "text": "And when He came to the disciples, He saw a great multitude around them, and scribes disputing with them.", 
                "num": 14
              }, 
              {
                "text": "Immediately, when they saw Him, all the people were greatly amazed, and running to Him, greeted Him.", 
                "num": 15
              }, 
              {
                "text": "And He asked the scribes, \"What are you discussing with them?\"", 
                "num": 16
              }, 
              {
                "text": "Then one of the crowd answered and said, \"Teacher, I brought You my son, who has a mute spirit.", 
                "num": 17
              }, 
              {
                "text": "And wherever it seizes him, it throws him down; he foams at the mouth, gnashes his teeth, and becomes rigid. So I spoke to Your disciples, that they should cast it out, but they could not.\"", 
                "num": 18
              }, 
              {
                "text": "He answered him and said, \"O faithless generation, how long shall I be with you? How long shall I bear with you? Bring him to Me.\"", 
                "num": 19
              }, 
              {
                "text": "Then they brought him to Him. And when he saw Him, immediately the spirit convulsed him, and he fell on the ground and wallowed, foaming at the mouth.", 
                "num": 20
              }, 
              {
                "text": "So He asked his father, \"How long has this been happening to him?\" And he said, \"From childhood.", 
                "num": 21
              }, 
              {
                "text": "And often he has thrown him both into the fire and into the water to destroy him. But if You can do anything, have compassion on us and help us.\"", 
                "num": 22
              }, 
              {
                "text": "Jesus said to him, \"If you can believe, all things are possible to him who believes.\"", 
                "num": 23
              }, 
              {
                "text": "Immediately the father of the child cried out and said with tears, \"Lord, I believe; help my unbelief!\"", 
                "num": 24
              }, 
              {
                "text": "When Jesus saw that the people came running together, He rebuked the unclean spirit, saying to it, \"Deaf and dumb spirit, I command you, come out of him and enter him no more!\"", 
                "num": 25
              }, 
              {
                "text": "Then the spirit cried out, convulsed him greatly, and came out of him. And he became as one dead, so that many said, \"He is dead.\"", 
                "num": 26
              }, 
              {
                "text": "But Jesus took him by the hand and lifted him up, and he arose.", 
                "num": 27
              }, 
              {
                "text": "And when He had come into the house, His disciples asked Him privately, \"Why could we not cast it out?\"", 
                "num": 28
              }, 
              {
                "text": "So He said to them, \"This kind can come out by nothing but prayer and fasting.\"", 
                "num": 29
              }, 
              {
                "text": "Then they departed from there and passed through Galilee, and He did not want anyone to know it.", 
                "num": 30
              }, 
              {
                "text": "For He taught His disciples and said to them, \"The Son of Man is being betrayed into the hands of men, and they will kill Him. And after He is killed, He will rise the third day.\"", 
                "num": 31
              }, 
              {
                "text": "But they did not understand this saying, and were afraid to ask Him.", 
                "num": 32
              }, 
              {
                "text": "Then He came to Capernaum. And when He was in the house He asked them, \"What was it you disputed among yourselves on the road?\"", 
                "num": 33
              }, 
              {
                "text": "But they kept silent, for on the road they had disputed among themselves who would be the greatest.", 
                "num": 34
              }, 
              {
                "text": "And He sat down, called the twelve, and said to them, \"If anyone desires to be first, he shall be last of all and servant of all.\"", 
                "num": 35
              }, 
              {
                "text": "Then He took a little child and set him in the midst of them. And when He had taken him in His arms, He said to them,", 
                "num": 36
              }, 
              {
                "text": "\"Whoever receives one of these little children in My name receives Me; and whoever receives Me, receives not Me but Him who sent Me.\"", 
                "num": 37
              }, 
              {
                "text": "Now John answered Him, saying, \"Teacher, we saw someone who does not follow us casting out demons in Your name, and we forbade him because he does not follow us.\"", 
                "num": 38
              }, 
              {
                "text": "But Jesus said, \"Do not forbid him, for no one who works a miracle in My name can soon afterward speak evil of Me.", 
                "num": 39
              }, 
              {
                "text": "For he who is not against us is on our side.", 
                "num": 40
              }, 
              {
                "text": "For whoever gives you a cup of water to drink in My name, because you belong to Christ, assuredly, I say to you, he will by no means lose his reward.", 
                "num": 41
              }, 
              {
                "text": "\"But whoever causes one of these little ones who believe in Me to stumble, it would be better for him if a millstone were hung around his neck, and he were thrown into the sea.", 
                "num": 42
              }, 
              {
                "text": "If your hand causes you to sin, cut it off. It is better for you to enter into life maimed, rather than having two hands, to go to hell, into the fire that shall never be quenched--", 
                "num": 43
              }, 
              {
                "text": "where \"Their worm does not die And the fire is not quenched.'", 
                "num": 44
              }, 
              {
                "text": "And if your foot causes you to sin, cut it off. It is better for you to enter life lame, rather than having two feet, to be cast into hell, into the fire that shall never be quenched--", 
                "num": 45
              }, 
              {
                "text": "where \"Their worm does not die And the fire is not quenched.'", 
                "num": 46
              }, 
              {
                "text": "And if your eye causes you to sin, pluck it out. It is better for you to enter the kingdom of God with one eye, rather than having two eyes, to be cast into hell fire--", 
                "num": 47
              }, 
              {
                "text": "where \"Their worm does not die And the fire is not quenched.'", 
                "num": 48
              }, 
              {
                "text": "\"For everyone will be seasoned with fire, and every sacrifice will be seasoned with salt.", 
                "num": 49
              }, 
              {
                "text": "Salt is good, but if the salt loses its flavor, how will you season it? Have salt in yourselves, and have peace with one another.\"", 
                "num": 50
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Then He arose from there and came to the region of Judea by the other side of the Jordan. And multitudes gathered to Him again, and as He was accustomed, He taught them again.", 
                "num": 1
              }, 
              {
                "text": "The Pharisees came and asked Him, \"Is it lawful for a man to divorce his wife?\" testing Him.", 
                "num": 2
              }, 
              {
                "text": "And He answered and said to them, \"What did Moses command you?\"", 
                "num": 3
              }, 
              {
                "text": "They said, \"Moses permitted a man to write a certificate of divorce, and to dismiss her.\"", 
                "num": 4
              }, 
              {
                "text": "And Jesus answered and said to them, \"Because of the hardness of your heart he wrote you this precept.", 
                "num": 5
              }, 
              {
                "text": "But from the beginning of the creation, God \"made them male and female.'", 
                "num": 6
              }, 
              {
                "text": "\"For this reason a man shall leave his father and mother and be joined to his wife,", 
                "num": 7
              }, 
              {
                "text": "and the two shall become one flesh'; so then they are no longer two, but one flesh.", 
                "num": 8
              }, 
              {
                "text": "Therefore what God has joined together, let not man separate.\"", 
                "num": 9
              }, 
              {
                "text": "In the house His disciples also asked Him again about the same matter.", 
                "num": 10
              }, 
              {
                "text": "So He said to them, \"Whoever divorces his wife and marries another commits adultery against her.", 
                "num": 11
              }, 
              {
                "text": "And if a woman divorces her husband and marries another, she commits adultery.\"", 
                "num": 12
              }, 
              {
                "text": "Then they brought little children to Him, that He might touch them; but the disciples rebuked those who brought them.", 
                "num": 13
              }, 
              {
                "text": "But when Jesus saw it, He was greatly displeased and said to them, \"Let the little children come to Me, and do not forbid them; for of such is the kingdom of God.", 
                "num": 14
              }, 
              {
                "text": "Assuredly, I say to you, whoever does not receive the kingdom of God as a little child will by no means enter it.\"", 
                "num": 15
              }, 
              {
                "text": "And He took them up in His arms, laid His hands on them, and blessed them.", 
                "num": 16
              }, 
              {
                "text": "Now as He was going out on the road, one came running, knelt before Him, and asked Him, \"Good Teacher, what shall I do that I may inherit eternal life?\"", 
                "num": 17
              }, 
              {
                "text": "So Jesus said to him, \"Why do you call Me good? No one is good but One, that is, God.", 
                "num": 18
              }, 
              {
                "text": "You know the commandments: \"Do not commit adultery,' \"Do not murder,' \"Do not steal,' \"Do not bear false witness,' \"Do not defraud,' \"Honor your father and your mother.\"'", 
                "num": 19
              }, 
              {
                "text": "And he answered and said to Him, \"Teacher, all these things I have kept from my youth.\"", 
                "num": 20
              }, 
              {
                "text": "Then Jesus, looking at him, loved him, and said to him, \"One thing you lack: Go your way, sell whatever you have and give to the poor, and you will have treasure in heaven; and come, take up the cross, and follow Me.\"", 
                "num": 21
              }, 
              {
                "text": "But he was sad at this word, and went away sorrowful, for he had great possessions.", 
                "num": 22
              }, 
              {
                "text": "Then Jesus looked around and said to His disciples, \"How hard it is for those who have riches to enter the kingdom of God!\"", 
                "num": 23
              }, 
              {
                "text": "And the disciples were astonished at His words. But Jesus answered again and said to them, \"Children, how hard it is for those who trust in riches to enter the kingdom of God!", 
                "num": 24
              }, 
              {
                "text": "It is easier for a camel to go through the eye of a needle than for a rich man to enter the kingdom of God.\"", 
                "num": 25
              }, 
              {
                "text": "And they were greatly astonished, saying among themselves, \"Who then can be saved?\"", 
                "num": 26
              }, 
              {
                "text": "But Jesus looked at them and said, \"With men it is impossible, but not with God; for with God all things are possible.\"", 
                "num": 27
              }, 
              {
                "text": "Then Peter began to say to Him, \"See, we have left all and followed You.\"", 
                "num": 28
              }, 
              {
                "text": "So Jesus answered and said, \"Assuredly, I say to you, there is no one who has left house or brothers or sisters or father or mother or wife or children or lands, for My sake and the gospel's,", 
                "num": 29
              }, 
              {
                "text": "who shall not receive a hundredfold now in this time--houses and brothers and sisters and mothers and children and lands, with persecutions--and in the age to come, eternal life.", 
                "num": 30
              }, 
              {
                "text": "But many who are first will be last, and the last first.\"", 
                "num": 31
              }, 
              {
                "text": "Now they were on the road, going up to Jerusalem, and Jesus was going before them; and they were amazed. And as they followed they were afraid. Then He took the twelve aside again and began to tell them the things that would happen to Him:", 
                "num": 32
              }, 
              {
                "text": "\"Behold, we are going up to Jerusalem, and the Son of Man will be betrayed to the chief priests and to the scribes; and they will condemn Him to death and deliver Him to the Gentiles;", 
                "num": 33
              }, 
              {
                "text": "and they will mock Him, and scourge Him, and spit on Him, and kill Him. And the third day He will rise again.\"", 
                "num": 34
              }, 
              {
                "text": "Then James and John, the sons of Zebedee, came to Him, saying, \"Teacher, we want You to do for us whatever we ask.\"", 
                "num": 35
              }, 
              {
                "text": "And He said to them, \"What do you want Me to do for you?\"", 
                "num": 36
              }, 
              {
                "text": "They said to Him, \"Grant us that we may sit, one on Your right hand and the other on Your left, in Your glory.\"", 
                "num": 37
              }, 
              {
                "text": "But Jesus said to them, \"You do not know what you ask. Are you able to drink the cup that I drink, and be baptized with the baptism that I am baptized with?\"", 
                "num": 38
              }, 
              {
                "text": "They said to Him, \"We are able.\" So Jesus said to them, \"You will indeed drink the cup that I drink, and with the baptism I am baptized with you will be baptized;", 
                "num": 39
              }, 
              {
                "text": "but to sit on My right hand and on My left is not Mine to give, but it is for those for whom it is prepared.\"", 
                "num": 40
              }, 
              {
                "text": "And when the ten heard it, they began to be greatly displeased with James and John.", 
                "num": 41
              }, 
              {
                "text": "But Jesus called them to Himself and said to them, \"You know that those who are considered rulers over the Gentiles lord it over them, and their great ones exercise authority over them.", 
                "num": 42
              }, 
              {
                "text": "Yet it shall not be so among you; but whoever desires to become great among you shall be your servant.", 
                "num": 43
              }, 
              {
                "text": "And whoever of you desires to be first shall be slave of all.", 
                "num": 44
              }, 
              {
                "text": "For even the Son of Man did not come to be served, but to serve, and to give His life a ransom for many.\"", 
                "num": 45
              }, 
              {
                "text": "Now they came to Jericho. As He went out of Jericho with His disciples and a great multitude, blind Bartimaeus, the son of Timaeus, sat by the road begging.", 
                "num": 46
              }, 
              {
                "text": "And when he heard that it was Jesus of Nazareth, he began to cry out and say, \"Jesus, Son of David, have mercy on me!\"", 
                "num": 47
              }, 
              {
                "text": "Then many warned him to be quiet; but he cried out all the more, \"Son of David, have mercy on me!\"", 
                "num": 48
              }, 
              {
                "text": "So Jesus stood still and commanded him to be called. Then they called the blind man, saying to him, \"Be of good cheer. Rise, He is calling you.\"", 
                "num": 49
              }, 
              {
                "text": "And throwing aside his garment, he rose and came to Jesus.", 
                "num": 50
              }, 
              {
                "text": "So Jesus answered and said to him, \"What do you want Me to do for you?\" The blind man said to Him, \"Rabboni, that I may receive my sight.\"", 
                "num": 51
              }, 
              {
                "text": "Then Jesus said to him, \"Go your way; your faith has made you well.\" And immediately he received his sight and followed Jesus on the road.", 
                "num": 52
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now when they drew near Jerusalem, to Bethphage and Bethany, at the Mount of Olives, He sent two of His disciples;", 
                "num": 1
              }, 
              {
                "text": "and He said to them, \"Go into the village opposite you; and as soon as you have entered it you will find a colt tied, on which no one has sat. Loose it and bring it.", 
                "num": 2
              }, 
              {
                "text": "And if anyone says to you, \"Why are you doing this?' say, \"The Lord has need of it,' and immediately he will send it here.\"", 
                "num": 3
              }, 
              {
                "text": "So they went their way, and found the colt tied by the door outside on the street, and they loosed it.", 
                "num": 4
              }, 
              {
                "text": "But some of those who stood there said to them, \"What are you doing, loosing the colt?\"", 
                "num": 5
              }, 
              {
                "text": "And they spoke to them just as Jesus had commanded. So they let them go.", 
                "num": 6
              }, 
              {
                "text": "Then they brought the colt to Jesus and threw their clothes on it, and He sat on it.", 
                "num": 7
              }, 
              {
                "text": "And many spread their clothes on the road, and others cut down leafy branches from the trees and spread them on the road.", 
                "num": 8
              }, 
              {
                "text": "Then those who went before and those who followed cried out, saying: \"Hosanna! \"Blessed is He who comes in the name of the LORD!'", 
                "num": 9
              }, 
              {
                "text": "Blessed is the kingdom of our father David That comes in the name of the Lord! Hosanna in the highest!\"", 
                "num": 10
              }, 
              {
                "text": "And Jesus went into Jerusalem and into the temple. So when He had looked around at all things, as the hour was already late, He went out to Bethany with the twelve.", 
                "num": 11
              }, 
              {
                "text": "Now the next day, when they had come out from Bethany, He was hungry.", 
                "num": 12
              }, 
              {
                "text": "And seeing from afar a fig tree having leaves, He went to see if perhaps He would find something on it. When He came to it, He found nothing but leaves, for it was not the season for figs.", 
                "num": 13
              }, 
              {
                "text": "In response Jesus said to it, \"Let no one eat fruit from you ever again.\" And His disciples heard it.", 
                "num": 14
              }, 
              {
                "text": "So they came to Jerusalem. Then Jesus went into the temple and began to drive out those who bought and sold in the temple, and overturned the tables of the money changers and the seats of those who sold doves.", 
                "num": 15
              }, 
              {
                "text": "And He would not allow anyone to carry wares through the temple.", 
                "num": 16
              }, 
              {
                "text": "Then He taught, saying to them, \"Is it not written, \"My house shall be called a house of prayer for all nations'? But you have made it a \"den of thieves.\"'", 
                "num": 17
              }, 
              {
                "text": "And the scribes and chief priests heard it and sought how they might destroy Him; for they feared Him, because all the people were astonished at His teaching.", 
                "num": 18
              }, 
              {
                "text": "When evening had come, He went out of the city.", 
                "num": 19
              }, 
              {
                "text": "Now in the morning, as they passed by, they saw the fig tree dried up from the roots.", 
                "num": 20
              }, 
              {
                "text": "And Peter, remembering, said to Him, \"Rabbi, look! The fig tree which You cursed has withered away.\"", 
                "num": 21
              }, 
              {
                "text": "So Jesus answered and said to them, \"Have faith in God.", 
                "num": 22
              }, 
              {
                "text": "For assuredly, I say to you, whoever says to this mountain, \"Be removed and be cast into the sea,' and does not doubt in his heart, but believes that those things he says will be done, he will have whatever he says.", 
                "num": 23
              }, 
              {
                "text": "Therefore I say to you, whatever things you ask when you pray, believe that you receive them, and you will have them.", 
                "num": 24
              }, 
              {
                "text": "\"And whenever you stand praying, if you have anything against anyone, forgive him, that your Father in heaven may also forgive you your trespasses.", 
                "num": 25
              }, 
              {
                "text": "But if you do not forgive, neither will your Father in heaven forgive your trespasses.\"", 
                "num": 26
              }, 
              {
                "text": "Then they came again to Jerusalem. And as He was walking in the temple, the chief priests, the scribes, and the elders came to Him.", 
                "num": 27
              }, 
              {
                "text": "And they said to Him, \"By what authority are You doing these things? And who gave You this authority to do these things?\"", 
                "num": 28
              }, 
              {
                "text": "But Jesus answered and said to them, \"I also will ask you one question; then answer Me, and I will tell you by what authority I do these things:", 
                "num": 29
              }, 
              {
                "text": "The baptism of John--was it from heaven or from men? Answer Me.\"", 
                "num": 30
              }, 
              {
                "text": "And they reasoned among themselves, saying, \"If we say, \"From heaven,' He will say, \"Why then did you not believe him?'", 
                "num": 31
              }, 
              {
                "text": "But if we say, \"From men\"'--they feared the people, for all counted John to have been a prophet indeed.", 
                "num": 32
              }, 
              {
                "text": "So they answered and said to Jesus, \"We do not know.\" And Jesus answered and said to them, \"Neither will I tell you by what authority I do these things.\"", 
                "num": 33
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then He began to speak to them in parables: \"A man planted a vineyard and set a hedge around it, dug a place for the wine vat and built a tower. And he leased it to vinedressers and went into a far country.", 
                "num": 1
              }, 
              {
                "text": "Now at vintage-time he sent a servant to the vinedressers, that he might receive some of the fruit of the vineyard from the vinedressers.", 
                "num": 2
              }, 
              {
                "text": "And they took him and beat him and sent him away empty-handed.", 
                "num": 3
              }, 
              {
                "text": "Again he sent them another servant, and at him they threw stones, wounded him in the head, and sent him away shamefully treated.", 
                "num": 4
              }, 
              {
                "text": "And again he sent another, and him they killed; and many others, beating some and killing some.", 
                "num": 5
              }, 
              {
                "text": "Therefore still having one son, his beloved, he also sent him to them last, saying, \"They will respect my son.'", 
                "num": 6
              }, 
              {
                "text": "But those vinedressers said among themselves, \"This is the heir. Come, let us kill him, and the inheritance will be ours.'", 
                "num": 7
              }, 
              {
                "text": "So they took him and killed him and cast him out of the vineyard.", 
                "num": 8
              }, 
              {
                "text": "\"Therefore what will the owner of the vineyard do? He will come and destroy the vinedressers, and give the vineyard to others.", 
                "num": 9
              }, 
              {
                "text": "Have you not even read this Scripture: \"The stone which the builders rejected Has become the chief cornerstone.", 
                "num": 10
              }, 
              {
                "text": "This was the LORD's doing, And it is marvelous in our eyes'?\"", 
                "num": 11
              }, 
              {
                "text": "And they sought to lay hands on Him, but feared the multitude, for they knew He had spoken the parable against them. So they left Him and went away.", 
                "num": 12
              }, 
              {
                "text": "Then they sent to Him some of the Pharisees and the Herodians, to catch Him in His words.", 
                "num": 13
              }, 
              {
                "text": "When they had come, they said to Him, \"Teacher, we know that You are true, and care about no one; for You do not regard the person of men, but teach the way of God in truth. Is it lawful to pay taxes to Caesar, or not?", 
                "num": 14
              }, 
              {
                "text": "Shall we pay, or shall we not pay?\" But He, knowing their hypocrisy, said to them, \"Why do you test Me? Bring Me a denarius that I may see it.\"", 
                "num": 15
              }, 
              {
                "text": "So they brought it. And He said to them, \"Whose image and inscription is this?\" They said to Him, \"Caesar's.\"", 
                "num": 16
              }, 
              {
                "text": "And Jesus answered and said to them, \"Render to Caesar the things that are Caesar's, and to God the things that are God's.\" And they marveled at Him.", 
                "num": 17
              }, 
              {
                "text": "Then some Sadducees, who say there is no resurrection, came to Him; and they asked Him, saying:", 
                "num": 18
              }, 
              {
                "text": "\"Teacher, Moses wrote to us that if a man's brother dies, and leaves his wife behind, and leaves no children, his brother should take his wife and raise up offspring for his brother.", 
                "num": 19
              }, 
              {
                "text": "Now there were seven brothers. The first took a wife; and dying, he left no offspring.", 
                "num": 20
              }, 
              {
                "text": "And the second took her, and he died; nor did he leave any offspring. And the third likewise.", 
                "num": 21
              }, 
              {
                "text": "So the seven had her and left no offspring. Last of all the woman died also.", 
                "num": 22
              }, 
              {
                "text": "Therefore, in the resurrection, when they rise, whose wife will she be? For all seven had her as wife.\"", 
                "num": 23
              }, 
              {
                "text": "Jesus answered and said to them, \"Are you not therefore mistaken, because you do not know the Scriptures nor the power of God?", 
                "num": 24
              }, 
              {
                "text": "For when they rise from the dead, they neither marry nor are given in marriage, but are like angels in heaven.", 
                "num": 25
              }, 
              {
                "text": "But concerning the dead, that they rise, have you not read in the book of Moses, in the burning bush passage, how God spoke to him, saying, \"I am the God of Abraham, the God of Isaac, and the God of Jacob'?", 
                "num": 26
              }, 
              {
                "text": "He is not the God of the dead, but the God of the living. You are therefore greatly mistaken.\"", 
                "num": 27
              }, 
              {
                "text": "Then one of the scribes came, and having heard them reasoning together, perceiving that He had answered them well, asked Him, \"Which is the first commandment of all?\"", 
                "num": 28
              }, 
              {
                "text": "Jesus answered him, \"The first of all the commandments is: \"Hear, O Israel, the LORD our God, the LORD is one.", 
                "num": 29
              }, 
              {
                "text": "And you shall love the LORD your God with all your heart, with all your soul, with all your mind, and with all your strength.' This is the first commandment.", 
                "num": 30
              }, 
              {
                "text": "And the second, like it, is this: \"You shall love your neighbor as yourself.' There is no other commandment greater than these.\"", 
                "num": 31
              }, 
              {
                "text": "So the scribe said to Him, \"Well said, Teacher. You have spoken the truth, for there is one God, and there is no other but He.", 
                "num": 32
              }, 
              {
                "text": "And to love Him with all the heart, with all the understanding, with all the soul, and with all the strength, and to love one's neighbor as oneself, is more than all the whole burnt offerings and sacrifices.\"", 
                "num": 33
              }, 
              {
                "text": "Now when Jesus saw that he answered wisely, He said to him, \"You are not far from the kingdom of God.\" But after that no one dared question Him.", 
                "num": 34
              }, 
              {
                "text": "Then Jesus answered and said, while He taught in the temple, \"How is it that the scribes say that the Christ is the Son of David?", 
                "num": 35
              }, 
              {
                "text": "For David himself said by the Holy Spirit: \"The LORD said to my Lord, \"Sit at My right hand, Till I make Your enemies Your footstool.\"'", 
                "num": 36
              }, 
              {
                "text": "Therefore David himself calls Him \"Lord'; how is He then his Son?\" And the common people heard Him gladly.", 
                "num": 37
              }, 
              {
                "text": "Then He said to them in His teaching, \"Beware of the scribes, who desire to go around in long robes, love greetings in the marketplaces,", 
                "num": 38
              }, 
              {
                "text": "the best seats in the synagogues, and the best places at feasts,", 
                "num": 39
              }, 
              {
                "text": "who devour widows' houses, and for a pretense make long prayers. These will receive greater condemnation.\"", 
                "num": 40
              }, 
              {
                "text": "Now Jesus sat opposite the treasury and saw how the people put money into the treasury. And many who were rich put in much.", 
                "num": 41
              }, 
              {
                "text": "Then one poor widow came and threw in two mites, which make a quadrans.", 
                "num": 42
              }, 
              {
                "text": "So He called His disciples to Himself and said to them, \"Assuredly, I say to you that this poor widow has put in more than all those who have given to the treasury;", 
                "num": 43
              }, 
              {
                "text": "for they all put in out of their abundance, but she out of her poverty put in all that she had, her whole livelihood.\"", 
                "num": 44
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Then as He went out of the temple, one of His disciples said to Him, \"Teacher, see what manner of stones and what buildings are here!\"", 
                "num": 1
              }, 
              {
                "text": "And Jesus answered and said to him, \"Do you see these great buildings? Not one stone shall be left upon another, that shall not be thrown down.\"", 
                "num": 2
              }, 
              {
                "text": "Now as He sat on the Mount of Olives opposite the temple, Peter, James, John, and Andrew asked Him privately,", 
                "num": 3
              }, 
              {
                "text": "\"Tell us, when will these things be? And what will be the sign when all these things will be fulfilled?\"", 
                "num": 4
              }, 
              {
                "text": "And Jesus, answering them, began to say: \"Take heed that no one deceives you.", 
                "num": 5
              }, 
              {
                "text": "For many will come in My name, saying, \"I am He,' and will deceive many.", 
                "num": 6
              }, 
              {
                "text": "But when you hear of wars and rumors of wars, do not be troubled; for such things must happen, but the end is not yet.", 
                "num": 7
              }, 
              {
                "text": "For nation will rise against nation, and kingdom against kingdom. And there will be earthquakes in various places, and there will be famines and troubles. These are the beginnings of sorrows.", 
                "num": 8
              }, 
              {
                "text": "\"But watch out for yourselves, for they will deliver you up to councils, and you will be beaten in the synagogues. You will be brought before rulers and kings for My sake, for a testimony to them.", 
                "num": 9
              }, 
              {
                "text": "And the gospel must first be preached to all the nations.", 
                "num": 10
              }, 
              {
                "text": "But when they arrest you and deliver you up, do not worry beforehand, or premeditate what you will speak. But whatever is given you in that hour, speak that; for it is not you who speak, but the Holy Spirit.", 
                "num": 11
              }, 
              {
                "text": "Now brother will betray brother to death, and a father his child; and children will rise up against parents and cause them to be put to death.", 
                "num": 12
              }, 
              {
                "text": "And you will be hated by all for My name's sake. But he who endures to the end shall be saved.", 
                "num": 13
              }, 
              {
                "text": "\"So when you see the \"abomination of desolation,' spoken of by Daniel the prophet, standing where it ought not\" (let the reader understand), \"then let those who are in Judea flee to the mountains.", 
                "num": 14
              }, 
              {
                "text": "Let him who is on the housetop not go down into the house, nor enter to take anything out of his house.", 
                "num": 15
              }, 
              {
                "text": "And let him who is in the field not go back to get his clothes.", 
                "num": 16
              }, 
              {
                "text": "But woe to those who are pregnant and to those who are nursing babies in those days!", 
                "num": 17
              }, 
              {
                "text": "And pray that your flight may not be in winter.", 
                "num": 18
              }, 
              {
                "text": "For in those days there will be tribulation, such as has not been since the beginning of the creation which God created until this time, nor ever shall be.", 
                "num": 19
              }, 
              {
                "text": "And unless the Lord had shortened those days, no flesh would be saved; but for the elect's sake, whom He chose, He shortened the days.", 
                "num": 20
              }, 
              {
                "text": "\"Then if anyone says to you, \"Look, here is the Christ!' or, \"Look, He is there!' do not believe it.", 
                "num": 21
              }, 
              {
                "text": "For false christs and false prophets will rise and show signs and wonders to deceive, if possible, even the elect.", 
                "num": 22
              }, 
              {
                "text": "But take heed; see, I have told you all things beforehand.", 
                "num": 23
              }, 
              {
                "text": "\"But in those days, after that tribulation, the sun will be darkened, and the moon will not give its light;", 
                "num": 24
              }, 
              {
                "text": "the stars of heaven will fall, and the powers in the heavens will be shaken.", 
                "num": 25
              }, 
              {
                "text": "Then they will see the Son of Man coming in the clouds with great power and glory.", 
                "num": 26
              }, 
              {
                "text": "And then He will send His angels, and gather together His elect from the four winds, from the farthest part of earth to the farthest part of heaven.", 
                "num": 27
              }, 
              {
                "text": "\"Now learn this parable from the fig tree: When its branch has already become tender, and puts forth leaves, you know that summer is near.", 
                "num": 28
              }, 
              {
                "text": "So you also, when you see these things happening, know that it is near--at the doors!", 
                "num": 29
              }, 
              {
                "text": "Assuredly, I say to you, this generation will by no means pass away till all these things take place.", 
                "num": 30
              }, 
              {
                "text": "Heaven and earth will pass away, but My words will by no means pass away.", 
                "num": 31
              }, 
              {
                "text": "\"But of that day and hour no one knows, not even the angels in heaven, nor the Son, but only the Father.", 
                "num": 32
              }, 
              {
                "text": "Take heed, watch and pray; for you do not know when the time is.", 
                "num": 33
              }, 
              {
                "text": "It is like a man going to a far country, who left his house and gave authority to his servants, and to each his work, and commanded the doorkeeper to watch.", 
                "num": 34
              }, 
              {
                "text": "Watch therefore, for you do not know when the master of the house is coming--in the evening, at midnight, at the crowing of the rooster, or in the morning--", 
                "num": 35
              }, 
              {
                "text": "lest, coming suddenly, he find you sleeping.", 
                "num": 36
              }, 
              {
                "text": "And what I say to you, I say to all: Watch!\"", 
                "num": 37
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "After two days it was the Passover and the Feast of Unleavened Bread. And the chief priests and the scribes sought how they might take Him by trickery and put Him to death.", 
                "num": 1
              }, 
              {
                "text": "But they said, \"Not during the feast, lest there be an uproar of the people.\"", 
                "num": 2
              }, 
              {
                "text": "And being in Bethany at the house of Simon the leper, as He sat at the table, a woman came having an alabaster flask of very costly oil of spikenard. Then she broke the flask and poured it on His head.", 
                "num": 3
              }, 
              {
                "text": "But there were some who were indignant among themselves, and said, \"Why was this fragrant oil wasted?", 
                "num": 4
              }, 
              {
                "text": "For it might have been sold for more than three hundred denarii and given to the poor.\" And they criticized her sharply.", 
                "num": 5
              }, 
              {
                "text": "But Jesus said, \"Let her alone. Why do you trouble her? She has done a good work for Me.", 
                "num": 6
              }, 
              {
                "text": "For you have the poor with you always, and whenever you wish you may do them good; but Me you do not have always.", 
                "num": 7
              }, 
              {
                "text": "She has done what she could. She has come beforehand to anoint My body for burial.", 
                "num": 8
              }, 
              {
                "text": "Assuredly, I say to you, wherever this gospel is preached in the whole world, what this woman has done will also be told as a memorial to her.\"", 
                "num": 9
              }, 
              {
                "text": "Then Judas Iscariot, one of the twelve, went to the chief priests to betray Him to them.", 
                "num": 10
              }, 
              {
                "text": "And when they heard it, they were glad, and promised to give him money. So he sought how he might conveniently betray Him.", 
                "num": 11
              }, 
              {
                "text": "Now on the first day of Unleavened Bread, when they killed the Passover lamb, His disciples said to Him, \"Where do You want us to go and prepare, that You may eat the Passover?\"", 
                "num": 12
              }, 
              {
                "text": "And He sent out two of His disciples and said to them, \"Go into the city, and a man will meet you carrying a pitcher of water; follow him.", 
                "num": 13
              }, 
              {
                "text": "Wherever he goes in, say to the master of the house, \"The Teacher says, \"Where is the guest room in which I may eat the Passover with My disciples?\"'", 
                "num": 14
              }, 
              {
                "text": "Then he will show you a large upper room, furnished and prepared; there make ready for us.\"", 
                "num": 15
              }, 
              {
                "text": "So His disciples went out, and came into the city, and found it just as He had said to them; and they prepared the Passover.", 
                "num": 16
              }, 
              {
                "text": "In the evening He came with the twelve.", 
                "num": 17
              }, 
              {
                "text": "Now as they sat and ate, Jesus said, \"Assuredly, I say to you, one of you who eats with Me will betray Me.\"", 
                "num": 18
              }, 
              {
                "text": "And they began to be sorrowful, and to say to Him one by one, \"Is it I?\" And another said, \"Is it I?\"", 
                "num": 19
              }, 
              {
                "text": "He answered and said to them, \"It is one of the twelve, who dips with Me in the dish.", 
                "num": 20
              }, 
              {
                "text": "The Son of Man indeed goes just as it is written of Him, but woe to that man by whom the Son of Man is betrayed! It would have been good for that man if he had never been born.\"", 
                "num": 21
              }, 
              {
                "text": "And as they were eating, Jesus took bread, blessed and broke it, and gave it to them and said, \"Take, eat; this is My body.\"", 
                "num": 22
              }, 
              {
                "text": "Then He took the cup, and when He had given thanks He gave it to them, and they all drank from it.", 
                "num": 23
              }, 
              {
                "text": "And He said to them, \"This is My blood of the new covenant, which is shed for many.", 
                "num": 24
              }, 
              {
                "text": "Assuredly, I say to you, I will no longer drink of the fruit of the vine until that day when I drink it new in the kingdom of God.\"", 
                "num": 25
              }, 
              {
                "text": "And when they had sung a hymn, they went out to the Mount of Olives.", 
                "num": 26
              }, 
              {
                "text": "Then Jesus said to them, \"All of you will be made to stumble because of Me this night, for it is written: \"I will strike the Shepherd, And the sheep will be scattered.'", 
                "num": 27
              }, 
              {
                "text": "\"But after I have been raised, I will go before you to Galilee.\"", 
                "num": 28
              }, 
              {
                "text": "Peter said to Him, \"Even if all are made to stumble, yet I will not be.\"", 
                "num": 29
              }, 
              {
                "text": "Jesus said to him, \"Assuredly, I say to you that today, even this night, before the rooster crows twice, you will deny Me three times.\"", 
                "num": 30
              }, 
              {
                "text": "But he spoke more vehemently, \"If I have to die with You, I will not deny You!\" And they all said likewise.", 
                "num": 31
              }, 
              {
                "text": "Then they came to a place which was named Gethsemane; and He said to His disciples, \"Sit here while I pray.\"", 
                "num": 32
              }, 
              {
                "text": "And He took Peter, James, and John with Him, and He began to be troubled and deeply distressed.", 
                "num": 33
              }, 
              {
                "text": "Then He said to them, \"My soul is exceedingly sorrowful, even to death. Stay here and watch.\"", 
                "num": 34
              }, 
              {
                "text": "He went a little farther, and fell on the ground, and prayed that if it were possible, the hour might pass from Him.", 
                "num": 35
              }, 
              {
                "text": "And He said, \"Abba, Father, all things are possible for You. Take this cup away from Me; nevertheless, not what I will, but what You will.\"", 
                "num": 36
              }, 
              {
                "text": "Then He came and found them sleeping, and said to Peter, \"Simon, are you sleeping? Could you not watch one hour?", 
                "num": 37
              }, 
              {
                "text": "Watch and pray, lest you enter into temptation. The spirit indeed is willing, but the flesh is weak.\"", 
                "num": 38
              }, 
              {
                "text": "Again He went away and prayed, and spoke the same words.", 
                "num": 39
              }, 
              {
                "text": "And when He returned, He found them asleep again, for their eyes were heavy; and they did not know what to answer Him.", 
                "num": 40
              }, 
              {
                "text": "Then He came the third time and said to them, \"Are you still sleeping and resting? It is enough! The hour has come; behold, the Son of Man is being betrayed into the hands of sinners.", 
                "num": 41
              }, 
              {
                "text": "Rise, let us be going. See, My betrayer is at hand.\"", 
                "num": 42
              }, 
              {
                "text": "And immediately, while He was still speaking, Judas, one of the twelve, with a great multitude with swords and clubs, came from the chief priests and the scribes and the elders.", 
                "num": 43
              }, 
              {
                "text": "Now His betrayer had given them a signal, saying, \"Whomever I kiss, He is the One; seize Him and lead Him away safely.\"", 
                "num": 44
              }, 
              {
                "text": "As soon as he had come, immediately he went up to Him and said to Him, \"Rabbi, Rabbi!\" and kissed Him.", 
                "num": 45
              }, 
              {
                "text": "Then they laid their hands on Him and took Him.", 
                "num": 46
              }, 
              {
                "text": "And one of those who stood by drew his sword and struck the servant of the high priest, and cut off his ear.", 
                "num": 47
              }, 
              {
                "text": "Then Jesus answered and said to them, \"Have you come out, as against a robber, with swords and clubs to take Me?", 
                "num": 48
              }, 
              {
                "text": "I was daily with you in the temple teaching, and you did not seize Me. But the Scriptures must be fulfilled.\"", 
                "num": 49
              }, 
              {
                "text": "Then they all forsook Him and fled.", 
                "num": 50
              }, 
              {
                "text": "Now a certain young man followed Him, having a linen cloth thrown around his naked body. And the young men laid hold of him,", 
                "num": 51
              }, 
              {
                "text": "and he left the linen cloth and fled from them naked.", 
                "num": 52
              }, 
              {
                "text": "And they led Jesus away to the high priest; and with him were assembled all the chief priests, the elders, and the scribes.", 
                "num": 53
              }, 
              {
                "text": "But Peter followed Him at a distance, right into the courtyard of the high priest. And he sat with the servants and warmed himself at the fire.", 
                "num": 54
              }, 
              {
                "text": "Now the chief priests and all the council sought testimony against Jesus to put Him to death, but found none.", 
                "num": 55
              }, 
              {
                "text": "For many bore false witness against Him, but their testimonies did not agree.", 
                "num": 56
              }, 
              {
                "text": "Then some rose up and bore false witness against Him, saying,", 
                "num": 57
              }, 
              {
                "text": "\"We heard Him say, \"I will destroy this temple made with hands, and within three days I will build another made without hands.\"'", 
                "num": 58
              }, 
              {
                "text": "But not even then did their testimony agree.", 
                "num": 59
              }, 
              {
                "text": "And the high priest stood up in the midst and asked Jesus, saying, \"Do You answer nothing? What is it these men testify against You?\"", 
                "num": 60
              }, 
              {
                "text": "But He kept silent and answered nothing. Again the high priest asked Him, saying to Him, \"Are You the Christ, the Son of the Blessed?\"", 
                "num": 61
              }, 
              {
                "text": "Jesus said, \"I am. And you will see the Son of Man sitting at the right hand of the Power, and coming with the clouds of heaven.\"", 
                "num": 62
              }, 
              {
                "text": "Then the high priest tore his clothes and said, \"What further need do we have of witnesses?", 
                "num": 63
              }, 
              {
                "text": "You have heard the blasphemy! What do you think?\" And they all condemned Him to be deserving of death.", 
                "num": 64
              }, 
              {
                "text": "Then some began to spit on Him, and to blindfold Him, and to beat Him, and to say to Him, \"Prophesy!\" And the officers struck Him with the palms of their hands.", 
                "num": 65
              }, 
              {
                "text": "Now as Peter was below in the courtyard, one of the servant girls of the high priest came.", 
                "num": 66
              }, 
              {
                "text": "And when she saw Peter warming himself, she looked at him and said, \"You also were with Jesus of Nazareth.\"", 
                "num": 67
              }, 
              {
                "text": "But he denied it, saying, \"I neither know nor understand what you are saying.\" And he went out on the porch, and a rooster crowed.", 
                "num": 68
              }, 
              {
                "text": "And the servant girl saw him again, and began to say to those who stood by, \"This is one of them.\"", 
                "num": 69
              }, 
              {
                "text": "But he denied it again. And a little later those who stood by said to Peter again, \"Surely you are one of them; for you are a Galilean, and your speech shows it.\"", 
                "num": 70
              }, 
              {
                "text": "Then he began to curse and swear, \"I do not know this Man of whom you speak!\"", 
                "num": 71
              }, 
              {
                "text": "A second time the rooster crowed. Then Peter called to mind the word that Jesus had said to him, \"Before the rooster crows twice, you will deny Me three times.\" And when he thought about it, he wept.", 
                "num": 72
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Immediately, in the morning, the chief priests held a consultation with the elders and scribes and the whole council; and they bound Jesus, led Him away, and delivered Him to Pilate.", 
                "num": 1
              }, 
              {
                "text": "Then Pilate asked Him, \"Are You the King of the Jews?\" He answered and said to him, \"It is as you say.\"", 
                "num": 2
              }, 
              {
                "text": "And the chief priests accused Him of many things, but He answered nothing.", 
                "num": 3
              }, 
              {
                "text": "Then Pilate asked Him again, saying, \"Do You answer nothing? See how many things they testify against You!\"", 
                "num": 4
              }, 
              {
                "text": "But Jesus still answered nothing, so that Pilate marveled.", 
                "num": 5
              }, 
              {
                "text": "Now at the feast he was accustomed to releasing one prisoner to them, whomever they requested.", 
                "num": 6
              }, 
              {
                "text": "And there was one named Barabbas, who was chained with his fellow rebels; they had committed murder in the rebellion.", 
                "num": 7
              }, 
              {
                "text": "Then the multitude, crying aloud, began to ask him to do just as he had always done for them.", 
                "num": 8
              }, 
              {
                "text": "But Pilate answered them, saying, \"Do you want me to release to you the King of the Jews?\"", 
                "num": 9
              }, 
              {
                "text": "For he knew that the chief priests had handed Him over because of envy.", 
                "num": 10
              }, 
              {
                "text": "But the chief priests stirred up the crowd, so that he should rather release Barabbas to them.", 
                "num": 11
              }, 
              {
                "text": "Pilate answered and said to them again, \"What then do you want me to do with Him whom you call the King of the Jews?\"", 
                "num": 12
              }, 
              {
                "text": "So they cried out again, \"Crucify Him!\"", 
                "num": 13
              }, 
              {
                "text": "Then Pilate said to them, \"Why, what evil has He done?\" But they cried out all the more, \"Crucify Him!\"", 
                "num": 14
              }, 
              {
                "text": "So Pilate, wanting to gratify the crowd, released Barabbas to them; and he delivered Jesus, after he had scourged Him, to be crucified.", 
                "num": 15
              }, 
              {
                "text": "Then the soldiers led Him away into the hall called Praetorium, and they called together the whole garrison.", 
                "num": 16
              }, 
              {
                "text": "And they clothed Him with purple; and they twisted a crown of thorns, put it on His head,", 
                "num": 17
              }, 
              {
                "text": "and began to salute Him, \"Hail, King of the Jews!\"", 
                "num": 18
              }, 
              {
                "text": "Then they struck Him on the head with a reed and spat on Him; and bowing the knee, they worshiped Him.", 
                "num": 19
              }, 
              {
                "text": "And when they had mocked Him, they took the purple off Him, put His own clothes on Him, and led Him out to crucify Him.", 
                "num": 20
              }, 
              {
                "text": "Then they compelled a certain man, Simon a Cyrenian, the father of Alexander and Rufus, as he was coming out of the country and passing by, to bear His cross.", 
                "num": 21
              }, 
              {
                "text": "And they brought Him to the place Golgotha, which is translated, Place of a Skull.", 
                "num": 22
              }, 
              {
                "text": "Then they gave Him wine mingled with myrrh to drink, but He did not take it.", 
                "num": 23
              }, 
              {
                "text": "And when they crucified Him, they divided His garments, casting lots for them to determine what every man should take.", 
                "num": 24
              }, 
              {
                "text": "Now it was the third hour, and they crucified Him.", 
                "num": 25
              }, 
              {
                "text": "And the inscription of His accusation was written above: THE KING OF THE JEWS.", 
                "num": 26
              }, 
              {
                "text": "With Him they also crucified two robbers, one on His right and the other on His left.", 
                "num": 27
              }, 
              {
                "text": "So the Scripture was fulfilled which says, \"And He was numbered with the transgressors.\"", 
                "num": 28
              }, 
              {
                "text": "And those who passed by blasphemed Him, wagging their heads and saying, \"Aha! You who destroy the temple and build it in three days,", 
                "num": 29
              }, 
              {
                "text": "save Yourself, and come down from the cross!\"", 
                "num": 30
              }, 
              {
                "text": "Likewise the chief priests also, mocking among themselves with the scribes, said, \"He saved others; Himself He cannot save.", 
                "num": 31
              }, 
              {
                "text": "Let the Christ, the King of Israel, descend now from the cross, that we may see and believe.\" Even those who were crucified with Him reviled Him.", 
                "num": 32
              }, 
              {
                "text": "Now when the sixth hour had come, there was darkness over the whole land until the ninth hour.", 
                "num": 33
              }, 
              {
                "text": "And at the ninth hour Jesus cried out with a loud voice, saying, \"Eloi, Eloi, lama sabachthani?\" which is translated, \"My God, My God, why have You forsaken Me?\"", 
                "num": 34
              }, 
              {
                "text": "Some of those who stood by, when they heard that, said, \"Look, He is calling for Elijah!\"", 
                "num": 35
              }, 
              {
                "text": "Then someone ran and filled a sponge full of sour wine, put it on a reed, and offered it to Him to drink, saying, \"Let Him alone; let us see if Elijah will come to take Him down.\"", 
                "num": 36
              }, 
              {
                "text": "And Jesus cried out with a loud voice, and breathed His last.", 
                "num": 37
              }, 
              {
                "text": "Then the veil of the temple was torn in two from top to bottom.", 
                "num": 38
              }, 
              {
                "text": "So when the centurion, who stood opposite Him, saw that He cried out like this and breathed His last, he said, \"Truly this Man was the Son of God!\"", 
                "num": 39
              }, 
              {
                "text": "There were also women looking on from afar, among whom were Mary Magdalene, Mary the mother of James the Less and of Joses, and Salome,", 
                "num": 40
              }, 
              {
                "text": "who also followed Him and ministered to Him when He was in Galilee, and many other women who came up with Him to Jerusalem.", 
                "num": 41
              }, 
              {
                "text": "Now when evening had come, because it was the Preparation Day, that is, the day before the Sabbath,", 
                "num": 42
              }, 
              {
                "text": "Joseph of Arimathea, a prominent council member, who was himself waiting for the kingdom of God, coming and taking courage, went in to Pilate and asked for the body of Jesus.", 
                "num": 43
              }, 
              {
                "text": "Pilate marveled that He was already dead; and summoning the centurion, he asked him if He had been dead for some time.", 
                "num": 44
              }, 
              {
                "text": "So when he found out from the centurion, he granted the body to Joseph.", 
                "num": 45
              }, 
              {
                "text": "Then he bought fine linen, took Him down, and wrapped Him in the linen. And he laid Him in a tomb which had been hewn out of the rock, and rolled a stone against the door of the tomb.", 
                "num": 46
              }, 
              {
                "text": "And Mary Magdalene and Mary the mother of Joses observed where He was laid.", 
                "num": 47
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now when the Sabbath was past, Mary Magdalene, Mary the mother of James, and Salome bought spices, that they might come and anoint Him.", 
                "num": 1
              }, 
              {
                "text": "Very early in the morning, on the first day of the week, they came to the tomb when the sun had risen.", 
                "num": 2
              }, 
              {
                "text": "And they said among themselves, \"Who will roll away the stone from the door of the tomb for us?\"", 
                "num": 3
              }, 
              {
                "text": "But when they looked up, they saw that the stone had been rolled away--for it was very large.", 
                "num": 4
              }, 
              {
                "text": "And entering the tomb, they saw a young man clothed in a long white robe sitting on the right side; and they were alarmed.", 
                "num": 5
              }, 
              {
                "text": "But he said to them, \"Do not be alarmed. You seek Jesus of Nazareth, who was crucified. He is risen! He is not here. See the place where they laid Him.", 
                "num": 6
              }, 
              {
                "text": "But go, tell His disciples--and Peter--that He is going before you into Galilee; there you will see Him, as He said to you.\"", 
                "num": 7
              }, 
              {
                "text": "So they went out quickly and fled from the tomb, for they trembled and were amazed. And they said nothing to anyone, for they were afraid.", 
                "num": 8
              }, 
              {
                "text": "Now when He rose early on the first day of the week, He appeared first to Mary Magdalene, out of whom He had cast seven demons.", 
                "num": 9
              }, 
              {
                "text": "She went and told those who had been with Him, as they mourned and wept.", 
                "num": 10
              }, 
              {
                "text": "And when they heard that He was alive and had been seen by her, they did not believe.", 
                "num": 11
              }, 
              {
                "text": "After that, He appeared in another form to two of them as they walked and went into the country.", 
                "num": 12
              }, 
              {
                "text": "And they went and told it to the rest, but they did not believe them either.", 
                "num": 13
              }, 
              {
                "text": "Later He appeared to the eleven as they sat at the table; and He rebuked their unbelief and hardness of heart, because they did not believe those who had seen Him after He had risen.", 
                "num": 14
              }, 
              {
                "text": "And He said to them, \"Go into all the world and preach the gospel to every creature.", 
                "num": 15
              }, 
              {
                "text": "He who believes and is baptized will be saved; but he who does not believe will be condemned.", 
                "num": 16
              }, 
              {
                "text": "And these signs will follow those who believe: In My name they will cast out demons; they will speak with new tongues;", 
                "num": 17
              }, 
              {
                "text": "they will take up serpents; and if they drink anything deadly, it will by no means hurt them; they will lay hands on the sick, and they will recover.\"", 
                "num": 18
              }, 
              {
                "text": "So then, after the Lord had spoken to them, He was received up into heaven, and sat down at the right hand of God.", 
                "num": 19
              }, 
              {
                "text": "And they went out and preached everywhere, the Lord working with them and confirming the word through the accompanying signs. Amen.", 
                "num": 20
              }
            ], 
            "num": 16
          }
        ]
      }, 
      {
        "name": "Luke", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Inasmuch as many have taken in hand to set in order a narrative of those things which have been fulfilled among us,", 
                "num": 1
              }, 
              {
                "text": "just as those who from the beginning were eyewitnesses and ministers of the word delivered them to us,", 
                "num": 2
              }, 
              {
                "text": "it seemed good to me also, having had perfect understanding of all things from the very first, to write to you an orderly account, most excellent Theophilus,", 
                "num": 3
              }, 
              {
                "text": "that you may know the certainty of those things in which you were instructed.", 
                "num": 4
              }, 
              {
                "text": "There was in the days of Herod, the king of Judea, a certain priest named Zacharias, of the division of Abijah. His wife was of the daughters of Aaron, and her name was Elizabeth.", 
                "num": 5
              }, 
              {
                "text": "And they were both righteous before God, walking in all the commandments and ordinances of the Lord blameless.", 
                "num": 6
              }, 
              {
                "text": "But they had no child, because Elizabeth was barren, and they were both well advanced in years.", 
                "num": 7
              }, 
              {
                "text": "So it was, that while he was serving as priest before God in the order of his division,", 
                "num": 8
              }, 
              {
                "text": "according to the custom of the priesthood, his lot fell to burn incense when he went into the temple of the Lord.", 
                "num": 9
              }, 
              {
                "text": "And the whole multitude of the people was praying outside at the hour of incense.", 
                "num": 10
              }, 
              {
                "text": "Then an angel of the Lord appeared to him, standing on the right side of the altar of incense.", 
                "num": 11
              }, 
              {
                "text": "And when Zacharias saw him, he was troubled, and fear fell upon him.", 
                "num": 12
              }, 
              {
                "text": "But the angel said to him, \"Do not be afraid, Zacharias, for your prayer is heard; and your wife Elizabeth will bear you a son, and you shall call his name John.", 
                "num": 13
              }, 
              {
                "text": "And you will have joy and gladness, and many will rejoice at his birth.", 
                "num": 14
              }, 
              {
                "text": "For he will be great in the sight of the Lord, and shall drink neither wine nor strong drink. He will also be filled with the Holy Spirit, even from his mother's womb.", 
                "num": 15
              }, 
              {
                "text": "And he will turn many of the children of Israel to the Lord their God.", 
                "num": 16
              }, 
              {
                "text": "He will also go before Him in the spirit and power of Elijah, \"to turn the hearts of the fathers to the children,' and the disobedient to the wisdom of the just, to make ready a people prepared for the Lord.\"", 
                "num": 17
              }, 
              {
                "text": "And Zacharias said to the angel, \"How shall I know this? For I am an old man, and my wife is well advanced in years.\"", 
                "num": 18
              }, 
              {
                "text": "And the angel answered and said to him, \"I am Gabriel, who stands in the presence of God, and was sent to speak to you and bring you these glad tidings.", 
                "num": 19
              }, 
              {
                "text": "But behold, you will be mute and not able to speak until the day these things take place, because you did not believe my words which will be fulfilled in their own time.\"", 
                "num": 20
              }, 
              {
                "text": "And the people waited for Zacharias, and marveled that he lingered so long in the temple.", 
                "num": 21
              }, 
              {
                "text": "But when he came out, he could not speak to them; and they perceived that he had seen a vision in the temple, for he beckoned to them and remained speechless.", 
                "num": 22
              }, 
              {
                "text": "So it was, as soon as the days of his service were completed, that he departed to his own house.", 
                "num": 23
              }, 
              {
                "text": "Now after those days his wife Elizabeth conceived; and she hid herself five months, saying,", 
                "num": 24
              }, 
              {
                "text": "\"Thus the Lord has dealt with me, in the days when He looked on me, to take away my reproach among people.\"", 
                "num": 25
              }, 
              {
                "text": "Now in the sixth month the angel Gabriel was sent by God to a city of Galilee named Nazareth,", 
                "num": 26
              }, 
              {
                "text": "to a virgin betrothed to a man whose name was Joseph, of the house of David. The virgin's name was Mary.", 
                "num": 27
              }, 
              {
                "text": "And having come in, the angel said to her, \"Rejoice, highly favored one, the Lord is with you; blessed are you among women!\"", 
                "num": 28
              }, 
              {
                "text": "But when she saw him, she was troubled at his saying, and considered what manner of greeting this was.", 
                "num": 29
              }, 
              {
                "text": "Then the angel said to her, \"Do not be afraid, Mary, for you have found favor with God.", 
                "num": 30
              }, 
              {
                "text": "And behold, you will conceive in your womb and bring forth a Son, and shall call His name JESUS.", 
                "num": 31
              }, 
              {
                "text": "He will be great, and will be called the Son of the Highest; and the Lord God will give Him the throne of His father David.", 
                "num": 32
              }, 
              {
                "text": "And He will reign over the house of Jacob forever, and of His kingdom there will be no end.\"", 
                "num": 33
              }, 
              {
                "text": "Then Mary said to the angel, \"How can this be, since I do not know a man?\"", 
                "num": 34
              }, 
              {
                "text": "And the angel answered and said to her, \"The Holy Spirit will come upon you, and the power of the Highest will overshadow you; therefore, also, that Holy One who is to be born will be called the Son of God.", 
                "num": 35
              }, 
              {
                "text": "Now indeed, Elizabeth your relative has also conceived a son in her old age; and this is now the sixth month for her who was called barren.", 
                "num": 36
              }, 
              {
                "text": "For with God nothing will be impossible.\"", 
                "num": 37
              }, 
              {
                "text": "Then Mary said, \"Behold the maidservant of the Lord! Let it be to me according to your word.\" And the angel departed from her.", 
                "num": 38
              }, 
              {
                "text": "Now Mary arose in those days and went into the hill country with haste, to a city of Judah,", 
                "num": 39
              }, 
              {
                "text": "and entered the house of Zacharias and greeted Elizabeth.", 
                "num": 40
              }, 
              {
                "text": "And it happened, when Elizabeth heard the greeting of Mary, that the babe leaped in her womb; and Elizabeth was filled with the Holy Spirit.", 
                "num": 41
              }, 
              {
                "text": "Then she spoke out with a loud voice and said, \"Blessed are you among women, and blessed is the fruit of your womb!", 
                "num": 42
              }, 
              {
                "text": "But why is this granted to me, that the mother of my Lord should come to me?", 
                "num": 43
              }, 
              {
                "text": "For indeed, as soon as the voice of your greeting sounded in my ears, the babe leaped in my womb for joy.", 
                "num": 44
              }, 
              {
                "text": "Blessed is she who believed, for there will be a fulfillment of those things which were told her from the Lord.\"", 
                "num": 45
              }, 
              {
                "text": "And Mary said: \"My soul magnifies the Lord,", 
                "num": 46
              }, 
              {
                "text": "And my spirit has rejoiced in God my Savior.", 
                "num": 47
              }, 
              {
                "text": "For He has regarded the lowly state of His maidservant; For behold, henceforth all generations will call me blessed.", 
                "num": 48
              }, 
              {
                "text": "For He who is mighty has done great things for me, And holy is His name.", 
                "num": 49
              }, 
              {
                "text": "And His mercy is on those who fear Him From generation to generation.", 
                "num": 50
              }, 
              {
                "text": "He has shown strength with His arm; He has scattered the proud in the imagination of their hearts.", 
                "num": 51
              }, 
              {
                "text": "He has put down the mighty from their thrones, And exalted the lowly.", 
                "num": 52
              }, 
              {
                "text": "He has filled the hungry with good things, And the rich He has sent away empty.", 
                "num": 53
              }, 
              {
                "text": "He has helped His servant Israel, In remembrance of His mercy,", 
                "num": 54
              }, 
              {
                "text": "As He spoke to our fathers, To Abraham and to his seed forever.\"", 
                "num": 55
              }, 
              {
                "text": "And Mary remained with her about three months, and returned to her house.", 
                "num": 56
              }, 
              {
                "text": "Now Elizabeth's full time came for her to be delivered, and she brought forth a son.", 
                "num": 57
              }, 
              {
                "text": "When her neighbors and relatives heard how the Lord had shown great mercy to her, they rejoiced with her.", 
                "num": 58
              }, 
              {
                "text": "So it was, on the eighth day, that they came to circumcise the child; and they would have called him by the name of his father, Zacharias.", 
                "num": 59
              }, 
              {
                "text": "His mother answered and said, \"No; he shall be called John.\"", 
                "num": 60
              }, 
              {
                "text": "But they said to her, \"There is no one among your relatives who is called by this name.\"", 
                "num": 61
              }, 
              {
                "text": "So they made signs to his father--what he would have him called.", 
                "num": 62
              }, 
              {
                "text": "And he asked for a writing tablet, and wrote, saying, \"His name is John.\" So they all marveled.", 
                "num": 63
              }, 
              {
                "text": "Immediately his mouth was opened and his tongue loosed, and he spoke, praising God.", 
                "num": 64
              }, 
              {
                "text": "Then fear came on all who dwelt around them; and all these sayings were discussed throughout all the hill country of Judea.", 
                "num": 65
              }, 
              {
                "text": "And all those who heard them kept them in their hearts, saying, \"What kind of child will this be?\" And the hand of the Lord was with him.", 
                "num": 66
              }, 
              {
                "text": "Now his father Zacharias was filled with the Holy Spirit, and prophesied, saying:", 
                "num": 67
              }, 
              {
                "text": "\"Blessed is the Lord God of Israel, For He has visited and redeemed His people,", 
                "num": 68
              }, 
              {
                "text": "And has raised up a horn of salvation for us In the house of His servant David,", 
                "num": 69
              }, 
              {
                "text": "As He spoke by the mouth of His holy prophets, Who have been since the world began,", 
                "num": 70
              }, 
              {
                "text": "That we should be saved from our enemies And from the hand of all who hate us,", 
                "num": 71
              }, 
              {
                "text": "To perform the mercy promised to our fathers And to remember His holy covenant,", 
                "num": 72
              }, 
              {
                "text": "The oath which He swore to our father Abraham:", 
                "num": 73
              }, 
              {
                "text": "To grant us that we, Being delivered from the hand of our enemies, Might serve Him without fear,", 
                "num": 74
              }, 
              {
                "text": "In holiness and righteousness before Him all the days of our life.", 
                "num": 75
              }, 
              {
                "text": "\"And you, child, will be called the prophet of the Highest; For you will go before the face of the Lord to prepare His ways,", 
                "num": 76
              }, 
              {
                "text": "To give knowledge of salvation to His people By the remission of their sins,", 
                "num": 77
              }, 
              {
                "text": "Through the tender mercy of our God, With which the Dayspring from on high has visited us;", 
                "num": 78
              }, 
              {
                "text": "To give light to those who sit in darkness and the shadow of death, To guide our feet into the way of peace.\"", 
                "num": 79
              }, 
              {
                "text": "So the child grew and became strong in spirit, and was in the deserts till the day of his manifestation to Israel.", 
                "num": 80
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And it came to pass in those days that a decree went out from Caesar Augustus that all the world should be registered.", 
                "num": 1
              }, 
              {
                "text": "This census first took place while Quirinius was governing Syria.", 
                "num": 2
              }, 
              {
                "text": "So all went to be registered, everyone to his own city.", 
                "num": 3
              }, 
              {
                "text": "Joseph also went up from Galilee, out of the city of Nazareth, into Judea, to the city of David, which is called Bethlehem, because he was of the house and lineage of David,", 
                "num": 4
              }, 
              {
                "text": "to be registered with Mary, his betrothed wife, who was with child.", 
                "num": 5
              }, 
              {
                "text": "So it was, that while they were there, the days were completed for her to be delivered.", 
                "num": 6
              }, 
              {
                "text": "And she brought forth her firstborn Son, and wrapped Him in swaddling cloths, and laid Him in a manger, because there was no room for them in the inn.", 
                "num": 7
              }, 
              {
                "text": "Now there were in the same country shepherds living out in the fields, keeping watch over their flock by night.", 
                "num": 8
              }, 
              {
                "text": "And behold, an angel of the Lord stood before them, and the glory of the Lord shone around them, and they were greatly afraid.", 
                "num": 9
              }, 
              {
                "text": "Then the angel said to them, \"Do not be afraid, for behold, I bring you good tidings of great joy which will be to all people.", 
                "num": 10
              }, 
              {
                "text": "For there is born to you this day in the city of David a Savior, who is Christ the Lord.", 
                "num": 11
              }, 
              {
                "text": "And this will be the sign to you: You will find a Babe wrapped in swaddling cloths, lying in a manger.\"", 
                "num": 12
              }, 
              {
                "text": "And suddenly there was with the angel a multitude of the heavenly host praising God and saying:", 
                "num": 13
              }, 
              {
                "text": "\"Glory to God in the highest, And on earth peace, goodwill toward men!\"", 
                "num": 14
              }, 
              {
                "text": "So it was, when the angels had gone away from them into heaven, that the shepherds said to one another, \"Let us now go to Bethlehem and see this thing that has come to pass, which the Lord has made known to us.\"", 
                "num": 15
              }, 
              {
                "text": "And they came with haste and found Mary and Joseph, and the Babe lying in a manger.", 
                "num": 16
              }, 
              {
                "text": "Now when they had seen Him, they made widely known the saying which was told them concerning this Child.", 
                "num": 17
              }, 
              {
                "text": "And all those who heard it marveled at those things which were told them by the shepherds.", 
                "num": 18
              }, 
              {
                "text": "But Mary kept all these things and pondered them in her heart.", 
                "num": 19
              }, 
              {
                "text": "Then the shepherds returned, glorifying and praising God for all the things that they had heard and seen, as it was told them.", 
                "num": 20
              }, 
              {
                "text": "And when eight days were completed for the circumcision of the Child, His name was called JESUS, the name given by the angel before He was conceived in the womb.", 
                "num": 21
              }, 
              {
                "text": "Now when the days of her purification according to the law of Moses were completed, they brought Him to Jerusalem to present Him to the Lord", 
                "num": 22
              }, 
              {
                "text": "(as it is written in the law of the Lord, \"Every male who opens the womb shall be called holy to the LORD\"),", 
                "num": 23
              }, 
              {
                "text": "and to offer a sacrifice according to what is said in the law of the Lord, \"A pair of turtledoves or two young pigeons.\"", 
                "num": 24
              }, 
              {
                "text": "And behold, there was a man in Jerusalem whose name was Simeon, and this man was just and devout, waiting for the Consolation of Israel, and the Holy Spirit was upon him.", 
                "num": 25
              }, 
              {
                "text": "And it had been revealed to him by the Holy Spirit that he would not see death before he had seen the Lord's Christ.", 
                "num": 26
              }, 
              {
                "text": "So he came by the Spirit into the temple. And when the parents brought in the Child Jesus, to do for Him according to the custom of the law,", 
                "num": 27
              }, 
              {
                "text": "he took Him up in his arms and blessed God and said:", 
                "num": 28
              }, 
              {
                "text": "\"Lord, now You are letting Your servant depart in peace, According to Your word;", 
                "num": 29
              }, 
              {
                "text": "For my eyes have seen Your salvation", 
                "num": 30
              }, 
              {
                "text": "Which You have prepared before the face of all peoples,", 
                "num": 31
              }, 
              {
                "text": "A light to bring revelation to the Gentiles, And the glory of Your people Israel.\"", 
                "num": 32
              }, 
              {
                "text": "And Joseph and His mother marveled at those things which were spoken of Him.", 
                "num": 33
              }, 
              {
                "text": "Then Simeon blessed them, and said to Mary His mother, \"Behold, this Child is destined for the fall and rising of many in Israel, and for a sign which will be spoken against", 
                "num": 34
              }, 
              {
                "text": "(yes, a sword will pierce through your own soul also), that the thoughts of many hearts may be revealed.\"", 
                "num": 35
              }, 
              {
                "text": "Now there was one, Anna, a prophetess, the daughter of Phanuel, of the tribe of Asher. She was of a great age, and had lived with a husband seven years from her virginity;", 
                "num": 36
              }, 
              {
                "text": "and this woman was a widow of about eighty-four years, who did not depart from the temple, but served God with fastings and prayers night and day.", 
                "num": 37
              }, 
              {
                "text": "And coming in that instant she gave thanks to the Lord, and spoke of Him to all those who looked for redemption in Jerusalem.", 
                "num": 38
              }, 
              {
                "text": "So when they had performed all things according to the law of the Lord, they returned to Galilee, to their own city, Nazareth.", 
                "num": 39
              }, 
              {
                "text": "And the Child grew and became strong in spirit, filled with wisdom; and the grace of God was upon Him.", 
                "num": 40
              }, 
              {
                "text": "His parents went to Jerusalem every year at the Feast of the Passover.", 
                "num": 41
              }, 
              {
                "text": "And when He was twelve years old, they went up to Jerusalem according to the custom of the feast.", 
                "num": 42
              }, 
              {
                "text": "When they had finished the days, as they returned, the Boy Jesus lingered behind in Jerusalem. And Joseph and His mother did not know it;", 
                "num": 43
              }, 
              {
                "text": "but supposing Him to have been in the company, they went a day's journey, and sought Him among their relatives and acquaintances.", 
                "num": 44
              }, 
              {
                "text": "So when they did not find Him, they returned to Jerusalem, seeking Him.", 
                "num": 45
              }, 
              {
                "text": "Now so it was that after three days they found Him in the temple, sitting in the midst of the teachers, both listening to them and asking them questions.", 
                "num": 46
              }, 
              {
                "text": "And all who heard Him were astonished at His understanding and answers.", 
                "num": 47
              }, 
              {
                "text": "So when they saw Him, they were amazed; and His mother said to Him, \"Son, why have You done this to us? Look, Your father and I have sought You anxiously.\"", 
                "num": 48
              }, 
              {
                "text": "And He said to them, \"Why did you seek Me? Did you not know that I must be about My Father's business?\"", 
                "num": 49
              }, 
              {
                "text": "But they did not understand the statement which He spoke to them.", 
                "num": 50
              }, 
              {
                "text": "Then He went down with them and came to Nazareth, and was subject to them, but His mother kept all these things in her heart.", 
                "num": 51
              }, 
              {
                "text": "And Jesus increased in wisdom and stature, and in favor with God and men.", 
                "num": 52
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now in the fifteenth year of the reign of Tiberius Caesar, Pontius Pilate being governor of Judea, Herod being tetrarch of Galilee, his brother Philip tetrarch of Iturea and the region of Trachonitis, and Lysanias tetrarch of Abilene,", 
                "num": 1
              }, 
              {
                "text": "while Annas and Caiaphas were high priests, the word of God came to John the son of Zacharias in the wilderness.", 
                "num": 2
              }, 
              {
                "text": "And he went into all the region around the Jordan, preaching a baptism of repentance for the remission of sins,", 
                "num": 3
              }, 
              {
                "text": "as it is written in the book of the words of Isaiah the prophet, saying: \"The voice of one crying in the wilderness: \"Prepare the way of the LORD; Make His paths straight.", 
                "num": 4
              }, 
              {
                "text": "Every valley shall be filled And every mountain and hill brought low; The crooked places shall be made straight And the rough ways smooth;", 
                "num": 5
              }, 
              {
                "text": "And all flesh shall see the salvation of God.\"'", 
                "num": 6
              }, 
              {
                "text": "Then he said to the multitudes that came out to be baptized by him, \"Brood of vipers! Who warned you to flee from the wrath to come?", 
                "num": 7
              }, 
              {
                "text": "Therefore bear fruits worthy of repentance, and do not begin to say to yourselves, \"We have Abraham as our father.' For I say to you that God is able to raise up children to Abraham from these stones.", 
                "num": 8
              }, 
              {
                "text": "And even now the ax is laid to the root of the trees. Therefore every tree which does not bear good fruit is cut down and thrown into the fire.\"", 
                "num": 9
              }, 
              {
                "text": "So the people asked him, saying, \"What shall we do then?\"", 
                "num": 10
              }, 
              {
                "text": "He answered and said to them, \"He who has two tunics, let him give to him who has none; and he who has food, let him do likewise.\"", 
                "num": 11
              }, 
              {
                "text": "Then tax collectors also came to be baptized, and said to him, \"Teacher, what shall we do?\"", 
                "num": 12
              }, 
              {
                "text": "And he said to them, \"Collect no more than what is appointed for you.\"", 
                "num": 13
              }, 
              {
                "text": "Likewise the soldiers asked him, saying, \"And what shall we do?\" So he said to them, \"Do not intimidate anyone or accuse falsely, and be content with your wages.\"", 
                "num": 14
              }, 
              {
                "text": "Now as the people were in expectation, and all reasoned in their hearts about John, whether he was the Christ or not,", 
                "num": 15
              }, 
              {
                "text": "John answered, saying to all, \"I indeed baptize you with water; but One mightier than I is coming, whose sandal strap I am not worthy to loose. He will baptize you with the Holy Spirit and fire.", 
                "num": 16
              }, 
              {
                "text": "His winnowing fan is in His hand, and He will thoroughly clean out His threshing floor, and gather the wheat into His barn; but the chaff He will burn with unquenchable fire.\"", 
                "num": 17
              }, 
              {
                "text": "And with many other exhortations he preached to the people.", 
                "num": 18
              }, 
              {
                "text": "But Herod the tetrarch, being rebuked by him concerning Herodias, his brother Philip's wife, and for all the evils which Herod had done,", 
                "num": 19
              }, 
              {
                "text": "also added this, above all, that he shut John up in prison.", 
                "num": 20
              }, 
              {
                "text": "When all the people were baptized, it came to pass that Jesus also was baptized; and while He prayed, the heaven was opened.", 
                "num": 21
              }, 
              {
                "text": "And the Holy Spirit descended in bodily form like a dove upon Him, and a voice came from heaven which said, \"You are My beloved Son; in You I am well pleased.\"", 
                "num": 22
              }, 
              {
                "text": "Now Jesus Himself began His ministry at about thirty years of age, being (as was supposed) the son of Joseph, the son of Heli,", 
                "num": 23
              }, 
              {
                "text": "the son of Matthat, the son of Levi, the son of Melchi, the son of Janna, the son of Joseph,", 
                "num": 24
              }, 
              {
                "text": "the son of Mattathiah, the son of Amos, the son of Nahum, the son of Esli, the son of Naggai,", 
                "num": 25
              }, 
              {
                "text": "the son of Maath, the son of Mattathiah, the son of Semei, the son of Joseph, the son of Judah,", 
                "num": 26
              }, 
              {
                "text": "the son of Joannas, the son of Rhesa, the son of Zerubbabel, the son of Shealtiel, the son of Neri,", 
                "num": 27
              }, 
              {
                "text": "the son of Melchi, the son of Addi, the son of Cosam, the son of Elmodam, the son of Er,", 
                "num": 28
              }, 
              {
                "text": "the son of Jose, the son of Eliezer, the son of Jorim, the son of Matthat, the son of Levi,", 
                "num": 29
              }, 
              {
                "text": "the son of Simeon, the son of Judah, the son of Joseph, the son of Jonan, the son of Eliakim,", 
                "num": 30
              }, 
              {
                "text": "the son of Melea, the son of Menan, the son of Mattathah, the son of Nathan, the son of David,", 
                "num": 31
              }, 
              {
                "text": "the son of Jesse, the son of Obed, the son of Boaz, the son of Salmon, the son of Nahshon,", 
                "num": 32
              }, 
              {
                "text": "the son of Amminadab, the son of Ram, the son of Hezron, the son of Perez, the son of Judah,", 
                "num": 33
              }, 
              {
                "text": "the son of Jacob, the son of Isaac, the son of Abraham, the son of Terah, the son of Nahor,", 
                "num": 34
              }, 
              {
                "text": "the son of Serug, the son of Reu, the son of Peleg, the son of Eber, the son of Shelah,", 
                "num": 35
              }, 
              {
                "text": "the son of Cainan, the son of Arphaxad, the son of Shem, the son of Noah, the son of Lamech,", 
                "num": 36
              }, 
              {
                "text": "the son of Methuselah, the son of Enoch, the son of Jared, the son of Mahalalel, the son of Cainan,", 
                "num": 37
              }, 
              {
                "text": "the son of Enosh, the son of Seth, the son of Adam, the son of God.", 
                "num": 38
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Then Jesus, being filled with the Holy Spirit, returned from the Jordan and was led by the Spirit into the wilderness,", 
                "num": 1
              }, 
              {
                "text": "being tempted for forty days by the devil. And in those days He ate nothing, and afterward, when they had ended, He was hungry.", 
                "num": 2
              }, 
              {
                "text": "And the devil said to Him, \"If You are the Son of God, command this stone to become bread.\"", 
                "num": 3
              }, 
              {
                "text": "But Jesus answered him, saying, \"It is written, \"Man shall not live by bread alone, but by every word of God.\"'", 
                "num": 4
              }, 
              {
                "text": "Then the devil, taking Him up on a high mountain, showed Him all the kingdoms of the world in a moment of time.", 
                "num": 5
              }, 
              {
                "text": "And the devil said to Him, \"All this authority I will give You, and their glory; for this has been delivered to me, and I give it to whomever I wish.", 
                "num": 6
              }, 
              {
                "text": "Therefore, if You will worship before me, all will be Yours.\"", 
                "num": 7
              }, 
              {
                "text": "And Jesus answered and said to him, \"Get behind Me, Satan! For it is written, \"You shall worship the LORD your God, and Him only you shall serve.\"'", 
                "num": 8
              }, 
              {
                "text": "Then he brought Him to Jerusalem, set Him on the pinnacle of the temple, and said to Him, \"If You are the Son of God, throw Yourself down from here.", 
                "num": 9
              }, 
              {
                "text": "For it is written: \"He shall give His angels charge over you, To keep you,'", 
                "num": 10
              }, 
              {
                "text": "and, \"In their hands they shall bear you up, Lest you dash your foot against a stone.\"'", 
                "num": 11
              }, 
              {
                "text": "And Jesus answered and said to him, \"It has been said, \"You shall not tempt the LORD your God.\"'", 
                "num": 12
              }, 
              {
                "text": "Now when the devil had ended every temptation, he departed from Him until an opportune time.", 
                "num": 13
              }, 
              {
                "text": "Then Jesus returned in the power of the Spirit to Galilee, and news of Him went out through all the surrounding region.", 
                "num": 14
              }, 
              {
                "text": "And He taught in their synagogues, being glorified by all.", 
                "num": 15
              }, 
              {
                "text": "So He came to Nazareth, where He had been brought up. And as His custom was, He went into the synagogue on the Sabbath day, and stood up to read.", 
                "num": 16
              }, 
              {
                "text": "And He was handed the book of the prophet Isaiah. And when He had opened the book, He found the place where it was written:", 
                "num": 17
              }, 
              {
                "text": "\"The Spirit of the LORD is upon Me, Because He has anointed Me To preach the gospel to the poor; He has sent Me to heal the brokenhearted, To proclaim liberty to the captives And recovery of sight to the blind, To set at liberty those who are oppressed;", 
                "num": 18
              }, 
              {
                "text": "To proclaim the acceptable year of the LORD.\"", 
                "num": 19
              }, 
              {
                "text": "Then He closed the book, and gave it back to the attendant and sat down. And the eyes of all who were in the synagogue were fixed on Him.", 
                "num": 20
              }, 
              {
                "text": "And He began to say to them, \"Today this Scripture is fulfilled in your hearing.\"", 
                "num": 21
              }, 
              {
                "text": "So all bore witness to Him, and marveled at the gracious words which proceeded out of His mouth. And they said, \"Is this not Joseph's son?\"", 
                "num": 22
              }, 
              {
                "text": "He said to them, \"You will surely say this proverb to Me, \"Physician, heal yourself! Whatever we have heard done in Capernaum, do also here in Your country.\"'", 
                "num": 23
              }, 
              {
                "text": "Then He said, \"Assuredly, I say to you, no prophet is accepted in his own country.", 
                "num": 24
              }, 
              {
                "text": "But I tell you truly, many widows were in Israel in the days of Elijah, when the heaven was shut up three years and six months, and there was a great famine throughout all the land;", 
                "num": 25
              }, 
              {
                "text": "but to none of them was Elijah sent except to Zarephath, in the region of Sidon, to a woman who was a widow.", 
                "num": 26
              }, 
              {
                "text": "And many lepers were in Israel in the time of Elisha the prophet, and none of them was cleansed except Naaman the Syrian.\"", 
                "num": 27
              }, 
              {
                "text": "So all those in the synagogue, when they heard these things, were filled with wrath,", 
                "num": 28
              }, 
              {
                "text": "and rose up and thrust Him out of the city; and they led Him to the brow of the hill on which their city was built, that they might throw Him down over the cliff.", 
                "num": 29
              }, 
              {
                "text": "Then passing through the midst of them, He went His way.", 
                "num": 30
              }, 
              {
                "text": "Then He went down to Capernaum, a city of Galilee, and was teaching them on the Sabbaths.", 
                "num": 31
              }, 
              {
                "text": "And they were astonished at His teaching, for His word was with authority.", 
                "num": 32
              }, 
              {
                "text": "Now in the synagogue there was a man who had a spirit of an unclean demon. And he cried out with a loud voice,", 
                "num": 33
              }, 
              {
                "text": "saying, \"Let us alone! What have we to do with You, Jesus of Nazareth? Did You come to destroy us? I know who You are--the Holy One of God!\"", 
                "num": 34
              }, 
              {
                "text": "But Jesus rebuked him, saying, \"Be quiet, and come out of him!\" And when the demon had thrown him in their midst, it came out of him and did not hurt him.", 
                "num": 35
              }, 
              {
                "text": "Then they were all amazed and spoke among themselves, saying, \"What a word this is! For with authority and power He commands the unclean spirits, and they come out.\"", 
                "num": 36
              }, 
              {
                "text": "And the report about Him went out into every place in the surrounding region.", 
                "num": 37
              }, 
              {
                "text": "Now He arose from the synagogue and entered Simon's house. But Simon's wife's mother was sick with a high fever, and they made request of Him concerning her.", 
                "num": 38
              }, 
              {
                "text": "So He stood over her and rebuked the fever, and it left her. And immediately she arose and served them.", 
                "num": 39
              }, 
              {
                "text": "When the sun was setting, all those who had any that were sick with various diseases brought them to Him; and He laid His hands on every one of them and healed them.", 
                "num": 40
              }, 
              {
                "text": "And demons also came out of many, crying out and saying, \"You are the Christ, the Son of God!\" And He, rebuking them, did not allow them to speak, for they knew that He was the Christ.", 
                "num": 41
              }, 
              {
                "text": "Now when it was day, He departed and went into a deserted place. And the crowd sought Him and came to Him, and tried to keep Him from leaving them;", 
                "num": 42
              }, 
              {
                "text": "but He said to them, \"I must preach the kingdom of God to the other cities also, because for this purpose I have been sent.\"", 
                "num": 43
              }, 
              {
                "text": "And He was preaching in the synagogues of Galilee.", 
                "num": 44
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "So it was, as the multitude pressed about Him to hear the word of God, that He stood by the Lake of Gennesaret,", 
                "num": 1
              }, 
              {
                "text": "and saw two boats standing by the lake; but the fishermen had gone from them and were washing their nets.", 
                "num": 2
              }, 
              {
                "text": "Then He got into one of the boats, which was Simon's, and asked him to put out a little from the land. And He sat down and taught the multitudes from the boat.", 
                "num": 3
              }, 
              {
                "text": "When He had stopped speaking, He said to Simon, \"Launch out into the deep and let down your nets for a catch.\"", 
                "num": 4
              }, 
              {
                "text": "But Simon answered and said to Him, \"Master, we have toiled all night and caught nothing; nevertheless at Your word I will let down the net.\"", 
                "num": 5
              }, 
              {
                "text": "And when they had done this, they caught a great number of fish, and their net was breaking.", 
                "num": 6
              }, 
              {
                "text": "So they signaled to their partners in the other boat to come and help them. And they came and filled both the boats, so that they began to sink.", 
                "num": 7
              }, 
              {
                "text": "When Simon Peter saw it, he fell down at Jesus' knees, saying, \"Depart from me, for I am a sinful man, O Lord!\"", 
                "num": 8
              }, 
              {
                "text": "For he and all who were with him were astonished at the catch of fish which they had taken;", 
                "num": 9
              }, 
              {
                "text": "and so also were James and John, the sons of Zebedee, who were partners with Simon. And Jesus said to Simon, \"Do not be afraid. From now on you will catch men.\"", 
                "num": 10
              }, 
              {
                "text": "So when they had brought their boats to land, they forsook all and followed Him.", 
                "num": 11
              }, 
              {
                "text": "And it happened when He was in a certain city, that behold, a man who was full of leprosy saw Jesus; and he fell on his face and implored Him, saying, \"Lord, if You are willing, You can make me clean.\"", 
                "num": 12
              }, 
              {
                "text": "Then He put out His hand and touched him, saying, \"I am willing; be cleansed.\" Immediately the leprosy left him.", 
                "num": 13
              }, 
              {
                "text": "And He charged him to tell no one, \"But go and show yourself to the priest, and make an offering for your cleansing, as a testimony to them, just as Moses commanded.\"", 
                "num": 14
              }, 
              {
                "text": "However, the report went around concerning Him all the more; and great multitudes came together to hear, and to be healed by Him of their infirmities.", 
                "num": 15
              }, 
              {
                "text": "So He Himself often withdrew into the wilderness and prayed.", 
                "num": 16
              }, 
              {
                "text": "Now it happened on a certain day, as He was teaching, that there were Pharisees and teachers of the law sitting by, who had come out of every town of Galilee, Judea, and Jerusalem. And the power of the Lord was present to heal them.", 
                "num": 17
              }, 
              {
                "text": "Then behold, men brought on a bed a man who was paralyzed, whom they sought to bring in and lay before Him.", 
                "num": 18
              }, 
              {
                "text": "And when they could not find how they might bring him in, because of the crowd, they went up on the housetop and let him down with his bed through the tiling into the midst before Jesus.", 
                "num": 19
              }, 
              {
                "text": "When He saw their faith, He said to him, \"Man, your sins are forgiven you.\"", 
                "num": 20
              }, 
              {
                "text": "And the scribes and the Pharisees began to reason, saying, \"Who is this who speaks blasphemies? Who can forgive sins but God alone?\"", 
                "num": 21
              }, 
              {
                "text": "But when Jesus perceived their thoughts, He answered and said to them, \"Why are you reasoning in your hearts?", 
                "num": 22
              }, 
              {
                "text": "Which is easier, to say, \"Your sins are forgiven you,' or to say, \"Rise up and walk'?", 
                "num": 23
              }, 
              {
                "text": "But that you may know that the Son of Man has power on earth to forgive sins\"--He said to the man who was paralyzed, \"I say to you, arise, take up your bed, and go to your house.\"", 
                "num": 24
              }, 
              {
                "text": "Immediately he rose up before them, took up what he had been lying on, and departed to his own house, glorifying God.", 
                "num": 25
              }, 
              {
                "text": "And they were all amazed, and they glorified God and were filled with fear, saying, \"We have seen strange things today!\"", 
                "num": 26
              }, 
              {
                "text": "After these things He went out and saw a tax collector named Levi, sitting at the tax office. And He said to him, \"Follow Me.\"", 
                "num": 27
              }, 
              {
                "text": "So he left all, rose up, and followed Him.", 
                "num": 28
              }, 
              {
                "text": "Then Levi gave Him a great feast in his own house. And there were a great number of tax collectors and others who sat down with them.", 
                "num": 29
              }, 
              {
                "text": "And their scribes and the Pharisees complained against His disciples, saying, \"Why do You eat and drink with tax collectors and sinners?\"", 
                "num": 30
              }, 
              {
                "text": "Jesus answered and said to them, \"Those who are well have no need of a physician, but those who are sick.", 
                "num": 31
              }, 
              {
                "text": "I have not come to call the righteous, but sinners, to repentance.\"", 
                "num": 32
              }, 
              {
                "text": "Then they said to Him, \"Why do the disciples of John fast often and make prayers, and likewise those of the Pharisees, but Yours eat and drink?\"", 
                "num": 33
              }, 
              {
                "text": "And He said to them, \"Can you make the friends of the bridegroom fast while the bridegroom is with them?", 
                "num": 34
              }, 
              {
                "text": "But the days will come when the bridegroom will be taken away from them; then they will fast in those days.\"", 
                "num": 35
              }, 
              {
                "text": "Then He spoke a parable to them: \"No one puts a piece from a new garment on an old one; otherwise the new makes a tear, and also the piece that was taken out of the new does not match the old.", 
                "num": 36
              }, 
              {
                "text": "And no one puts new wine into old wineskins; or else the new wine will burst the wineskins and be spilled, and the wineskins will be ruined.", 
                "num": 37
              }, 
              {
                "text": "But new wine must be put into new wineskins, and both are preserved.", 
                "num": 38
              }, 
              {
                "text": "And no one, having drunk old wine, immediately desires new; for he says, \"The old is better.\"'", 
                "num": 39
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now it happened on the second Sabbath after the first that He went through the grainfields. And His disciples plucked the heads of grain and ate them, rubbing them in their hands.", 
                "num": 1
              }, 
              {
                "text": "And some of the Pharisees said to them, \"Why are you doing what is not lawful to do on the Sabbath?\"", 
                "num": 2
              }, 
              {
                "text": "But Jesus answering them said, \"Have you not even read this, what David did when he was hungry, he and those who were with him:", 
                "num": 3
              }, 
              {
                "text": "how he went into the house of God, took and ate the showbread, and also gave some to those with him, which is not lawful for any but the priests to eat?\"", 
                "num": 4
              }, 
              {
                "text": "And He said to them, \"The Son of Man is also Lord of the Sabbath.\"", 
                "num": 5
              }, 
              {
                "text": "Now it happened on another Sabbath, also, that He entered the synagogue and taught. And a man was there whose right hand was withered.", 
                "num": 6
              }, 
              {
                "text": "So the scribes and Pharisees watched Him closely, whether He would heal on the Sabbath, that they might find an accusation against Him.", 
                "num": 7
              }, 
              {
                "text": "But He knew their thoughts, and said to the man who had the withered hand, \"Arise and stand here.\" And he arose and stood.", 
                "num": 8
              }, 
              {
                "text": "Then Jesus said to them, \"I will ask you one thing: Is it lawful on the Sabbath to do good or to do evil, to save life or to destroy?\"", 
                "num": 9
              }, 
              {
                "text": "And when He had looked around at them all, He said to the man, \"Stretch out your hand.\" And he did so, and his hand was restored as whole as the other.", 
                "num": 10
              }, 
              {
                "text": "But they were filled with rage, and discussed with one another what they might do to Jesus.", 
                "num": 11
              }, 
              {
                "text": "Now it came to pass in those days that He went out to the mountain to pray, and continued all night in prayer to God.", 
                "num": 12
              }, 
              {
                "text": "And when it was day, He called His disciples to Himself; and from them He chose twelve whom He also named apostles:", 
                "num": 13
              }, 
              {
                "text": "Simon, whom He also named Peter, and Andrew his brother; James and John; Philip and Bartholomew;", 
                "num": 14
              }, 
              {
                "text": "Matthew and Thomas; James the son of Alphaeus, and Simon called the Zealot;", 
                "num": 15
              }, 
              {
                "text": "Judas the son of James, and Judas Iscariot who also became a traitor.", 
                "num": 16
              }, 
              {
                "text": "And He came down with them and stood on a level place with a crowd of His disciples and a great multitude of people from all Judea and Jerusalem, and from the seacoast of Tyre and Sidon, who came to hear Him and be healed of their diseases,", 
                "num": 17
              }, 
              {
                "text": "as well as those who were tormented with unclean spirits. And they were healed.", 
                "num": 18
              }, 
              {
                "text": "And the whole multitude sought to touch Him, for power went out from Him and healed them all.", 
                "num": 19
              }, 
              {
                "text": "Then He lifted up His eyes toward His disciples, and said: \"Blessed are you poor, For yours is the kingdom of God.", 
                "num": 20
              }, 
              {
                "text": "Blessed are you who hunger now, For you shall be filled. Blessed are you who weep now, For you shall laugh.", 
                "num": 21
              }, 
              {
                "text": "Blessed are you when men hate you, And when they exclude you, And revile you, and cast out your name as evil, For the Son of Man's sake.", 
                "num": 22
              }, 
              {
                "text": "Rejoice in that day and leap for joy! For indeed your reward is great in heaven, For in like manner their fathers did to the prophets.", 
                "num": 23
              }, 
              {
                "text": "\"But woe to you who are rich, For you have received your consolation.", 
                "num": 24
              }, 
              {
                "text": "Woe to you who are full, For you shall hunger. Woe to you who laugh now, For you shall mourn and weep.", 
                "num": 25
              }, 
              {
                "text": "Woe to you when all men speak well of you, For so did their fathers to the false prophets.", 
                "num": 26
              }, 
              {
                "text": "\"But I say to you who hear: Love your enemies, do good to those who hate you,", 
                "num": 27
              }, 
              {
                "text": "bless those who curse you, and pray for those who spitefully use you.", 
                "num": 28
              }, 
              {
                "text": "To him who strikes you on the one cheek, offer the other also. And from him who takes away your cloak, do not withhold your tunic either.", 
                "num": 29
              }, 
              {
                "text": "Give to everyone who asks of you. And from him who takes away your goods do not ask them back.", 
                "num": 30
              }, 
              {
                "text": "And just as you want men to do to you, you also do to them likewise.", 
                "num": 31
              }, 
              {
                "text": "\"But if you love those who love you, what credit is that to you? For even sinners love those who love them.", 
                "num": 32
              }, 
              {
                "text": "And if you do good to those who do good to you, what credit is that to you? For even sinners do the same.", 
                "num": 33
              }, 
              {
                "text": "And if you lend to those from whom you hope to receive back, what credit is that to you? For even sinners lend to sinners to receive as much back.", 
                "num": 34
              }, 
              {
                "text": "But love your enemies, do good, and lend, hoping for nothing in return; and your reward will be great, and you will be sons of the Most High. For He is kind to the unthankful and evil.", 
                "num": 35
              }, 
              {
                "text": "Therefore be merciful, just as your Father also is merciful.", 
                "num": 36
              }, 
              {
                "text": "\"Judge not, and you shall not be judged. Condemn not, and you shall not be condemned. Forgive, and you will be forgiven.", 
                "num": 37
              }, 
              {
                "text": "Give, and it will be given to you: good measure, pressed down, shaken together, and running over will be put into your bosom. For with the same measure that you use, it will be measured back to you.\"", 
                "num": 38
              }, 
              {
                "text": "And He spoke a parable to them: \"Can the blind lead the blind? Will they not both fall into the ditch?", 
                "num": 39
              }, 
              {
                "text": "A disciple is not above his teacher, but everyone who is perfectly trained will be like his teacher.", 
                "num": 40
              }, 
              {
                "text": "And why do you look at the speck in your brother's eye, but do not perceive the plank in your own eye?", 
                "num": 41
              }, 
              {
                "text": "Or how can you say to your brother, \"Brother, let me remove the speck that is in your eye,' when you yourself do not see the plank that is in your own eye? Hypocrite! First remove the plank from your own eye, and then you will see clearly to remove the speck that is in your brother's eye.", 
                "num": 42
              }, 
              {
                "text": "\"For a good tree does not bear bad fruit, nor does a bad tree bear good fruit.", 
                "num": 43
              }, 
              {
                "text": "For every tree is known by its own fruit. For men do not gather figs from thorns, nor do they gather grapes from a bramble bush.", 
                "num": 44
              }, 
              {
                "text": "A good man out of the good treasure of his heart brings forth good; and an evil man out of the evil treasure of his heart brings forth evil. For out of the abundance of the heart his mouth speaks.", 
                "num": 45
              }, 
              {
                "text": "\"But why do you call Me \"Lord, Lord,' and not do the things which I say?", 
                "num": 46
              }, 
              {
                "text": "Whoever comes to Me, and hears My sayings and does them, I will show you whom he is like:", 
                "num": 47
              }, 
              {
                "text": "He is like a man building a house, who dug deep and laid the foundation on the rock. And when the flood arose, the stream beat vehemently against that house, and could not shake it, for it was founded on the rock.", 
                "num": 48
              }, 
              {
                "text": "But he who heard and did nothing is like a man who built a house on the earth without a foundation, against which the stream beat vehemently; and immediately it fell. And the ruin of that house was great.\"", 
                "num": 49
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now when He concluded all His sayings in the hearing of the people, He entered Capernaum.", 
                "num": 1
              }, 
              {
                "text": "And a certain centurion's servant, who was dear to him, was sick and ready to die.", 
                "num": 2
              }, 
              {
                "text": "So when he heard about Jesus, he sent elders of the Jews to Him, pleading with Him to come and heal his servant.", 
                "num": 3
              }, 
              {
                "text": "And when they came to Jesus, they begged Him earnestly, saying that the one for whom He should do this was deserving,", 
                "num": 4
              }, 
              {
                "text": "\"for he loves our nation, and has built us a synagogue.\"", 
                "num": 5
              }, 
              {
                "text": "Then Jesus went with them. And when He was already not far from the house, the centurion sent friends to Him, saying to Him, \"Lord, do not trouble Yourself, for I am not worthy that You should enter under my roof.", 
                "num": 6
              }, 
              {
                "text": "Therefore I did not even think myself worthy to come to You. But say the word, and my servant will be healed.", 
                "num": 7
              }, 
              {
                "text": "For I also am a man placed under authority, having soldiers under me. And I say to one, \"Go,' and he goes; and to another, \"Come,' and he comes; and to my servant, \"Do this,' and he does it.\"", 
                "num": 8
              }, 
              {
                "text": "When Jesus heard these things, He marveled at him, and turned around and said to the crowd that followed Him, \"I say to you, I have not found such great faith, not even in Israel!\"", 
                "num": 9
              }, 
              {
                "text": "And those who were sent, returning to the house, found the servant well who had been sick.", 
                "num": 10
              }, 
              {
                "text": "Now it happened, the day after, that He went into a city called Nain; and many of His disciples went with Him, and a large crowd.", 
                "num": 11
              }, 
              {
                "text": "And when He came near the gate of the city, behold, a dead man was being carried out, the only son of his mother; and she was a widow. And a large crowd from the city was with her.", 
                "num": 12
              }, 
              {
                "text": "When the Lord saw her, He had compassion on her and said to her, \"Do not weep.\"", 
                "num": 13
              }, 
              {
                "text": "Then He came and touched the open coffin, and those who carried him stood still. And He said, \"Young man, I say to you, arise.\"", 
                "num": 14
              }, 
              {
                "text": "So he who was dead sat up and began to speak. And He presented him to his mother.", 
                "num": 15
              }, 
              {
                "text": "Then fear came upon all, and they glorified God, saying, \"A great prophet has risen up among us\"; and, \"God has visited His people.\"", 
                "num": 16
              }, 
              {
                "text": "And this report about Him went throughout all Judea and all the surrounding region.", 
                "num": 17
              }, 
              {
                "text": "Then the disciples of John reported to him concerning all these things.", 
                "num": 18
              }, 
              {
                "text": "And John, calling two of his disciples to him, sent them to Jesus, saying, \"Are You the Coming One, or do we look for another?\"", 
                "num": 19
              }, 
              {
                "text": "When the men had come to Him, they said, \"John the Baptist has sent us to You, saying, \"Are You the Coming One, or do we look for another?\"'", 
                "num": 20
              }, 
              {
                "text": "And that very hour He cured many of infirmities, afflictions, and evil spirits; and to many blind He gave sight.", 
                "num": 21
              }, 
              {
                "text": "Jesus answered and said to them, \"Go and tell John the things you have seen and heard: that the blind see, the lame walk, the lepers are cleansed, the deaf hear, the dead are raised, the poor have the gospel preached to them.", 
                "num": 22
              }, 
              {
                "text": "And blessed is he who is not offended because of Me.\"", 
                "num": 23
              }, 
              {
                "text": "When the messengers of John had departed, He began to speak to the multitudes concerning John: \"What did you go out into the wilderness to see? A reed shaken by the wind?", 
                "num": 24
              }, 
              {
                "text": "But what did you go out to see? A man clothed in soft garments? Indeed those who are gorgeously appareled and live in luxury are in kings' courts.", 
                "num": 25
              }, 
              {
                "text": "But what did you go out to see? A prophet? Yes, I say to you, and more than a prophet.", 
                "num": 26
              }, 
              {
                "text": "This is he of whom it is written: \"Behold, I send My messenger before Your face, Who will prepare Your way before You.'", 
                "num": 27
              }, 
              {
                "text": "For I say to you, among those born of women there is not a greater prophet than John the Baptist; but he who is least in the kingdom of God is greater than he.\"", 
                "num": 28
              }, 
              {
                "text": "And when all the people heard Him, even the tax collectors justified God, having been baptized with the baptism of John.", 
                "num": 29
              }, 
              {
                "text": "But the Pharisees and lawyers rejected the will of God for themselves, not having been baptized by him.", 
                "num": 30
              }, 
              {
                "text": "And the Lord said, \"To what then shall I liken the men of this generation, and what are they like?", 
                "num": 31
              }, 
              {
                "text": "They are like children sitting in the marketplace and calling to one another, saying: \"We played the flute for you, And you did not dance; We mourned to you, And you did not weep.'", 
                "num": 32
              }, 
              {
                "text": "For John the Baptist came neither eating bread nor drinking wine, and you say, \"He has a demon.'", 
                "num": 33
              }, 
              {
                "text": "The Son of Man has come eating and drinking, and you say, \"Look, a glutton and a winebibber, a friend of tax collectors and sinners!'", 
                "num": 34
              }, 
              {
                "text": "But wisdom is justified by all her children.\"", 
                "num": 35
              }, 
              {
                "text": "Then one of the Pharisees asked Him to eat with him. And He went to the Pharisee's house, and sat down to eat.", 
                "num": 36
              }, 
              {
                "text": "And behold, a woman in the city who was a sinner, when she knew that Jesus sat at the table in the Pharisee's house, brought an alabaster flask of fragrant oil,", 
                "num": 37
              }, 
              {
                "text": "and stood at His feet behind Him weeping; and she began to wash His feet with her tears, and wiped them with the hair of her head; and she kissed His feet and anointed them with the fragrant oil.", 
                "num": 38
              }, 
              {
                "text": "Now when the Pharisee who had invited Him saw this, he spoke to himself, saying, \"This Man, if He were a prophet, would know who and what manner of woman this is who is touching Him, for she is a sinner.\"", 
                "num": 39
              }, 
              {
                "text": "And Jesus answered and said to him, \"Simon, I have something to say to you.\" So he said, \"Teacher, say it.\"", 
                "num": 40
              }, 
              {
                "text": "\"There was a certain creditor who had two debtors. One owed five hundred denarii, and the other fifty.", 
                "num": 41
              }, 
              {
                "text": "And when they had nothing with which to repay, he freely forgave them both. Tell Me, therefore, which of them will love him more?\"", 
                "num": 42
              }, 
              {
                "text": "Simon answered and said, \"I suppose the one whom he forgave more.\" And He said to him, \"You have rightly judged.\"", 
                "num": 43
              }, 
              {
                "text": "Then He turned to the woman and said to Simon, \"Do you see this woman? I entered your house; you gave Me no water for My feet, but she has washed My feet with her tears and wiped them with the hair of her head.", 
                "num": 44
              }, 
              {
                "text": "You gave Me no kiss, but this woman has not ceased to kiss My feet since the time I came in.", 
                "num": 45
              }, 
              {
                "text": "You did not anoint My head with oil, but this woman has anointed My feet with fragrant oil.", 
                "num": 46
              }, 
              {
                "text": "Therefore I say to you, her sins, which are many, are forgiven, for she loved much. But to whom little is forgiven, the same loves little.\"", 
                "num": 47
              }, 
              {
                "text": "Then He said to her, \"Your sins are forgiven.\"", 
                "num": 48
              }, 
              {
                "text": "And those who sat at the table with Him began to say to themselves, \"Who is this who even forgives sins?\"", 
                "num": 49
              }, 
              {
                "text": "Then He said to the woman, \"Your faith has saved you. Go in peace.\"", 
                "num": 50
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, afterward, that He went through every city and village, preaching and bringing the glad tidings of the kingdom of God. And the twelve were with Him,", 
                "num": 1
              }, 
              {
                "text": "and certain women who had been healed of evil spirits and infirmities--Mary called Magdalene, out of whom had come seven demons,", 
                "num": 2
              }, 
              {
                "text": "and Joanna the wife of Chuza, Herod's steward, and Susanna, and many others who provided for Him from their substance.", 
                "num": 3
              }, 
              {
                "text": "And when a great multitude had gathered, and they had come to Him from every city, He spoke by a parable:", 
                "num": 4
              }, 
              {
                "text": "\"A sower went out to sow his seed. And as he sowed, some fell by the wayside; and it was trampled down, and the birds of the air devoured it.", 
                "num": 5
              }, 
              {
                "text": "Some fell on rock; and as soon as it sprang up, it withered away because it lacked moisture.", 
                "num": 6
              }, 
              {
                "text": "And some fell among thorns, and the thorns sprang up with it and choked it.", 
                "num": 7
              }, 
              {
                "text": "But others fell on good ground, sprang up, and yielded a crop a hundredfold.\" When He had said these things He cried, \"He who has ears to hear, let him hear!\"", 
                "num": 8
              }, 
              {
                "text": "Then His disciples asked Him, saying, \"What does this parable mean?\"", 
                "num": 9
              }, 
              {
                "text": "And He said, \"To you it has been given to know the mysteries of the kingdom of God, but to the rest it is given in parables, that \"Seeing they may not see, And hearing they may not understand.'", 
                "num": 10
              }, 
              {
                "text": "\"Now the parable is this: The seed is the word of God.", 
                "num": 11
              }, 
              {
                "text": "Those by the wayside are the ones who hear; then the devil comes and takes away the word out of their hearts, lest they should believe and be saved.", 
                "num": 12
              }, 
              {
                "text": "But the ones on the rock are those who, when they hear, receive the word with joy; and these have no root, who believe for a while and in time of temptation fall away.", 
                "num": 13
              }, 
              {
                "text": "Now the ones that fell among thorns are those who, when they have heard, go out and are choked with cares, riches, and pleasures of life, and bring no fruit to maturity.", 
                "num": 14
              }, 
              {
                "text": "But the ones that fell on the good ground are those who, having heard the word with a noble and good heart, keep it and bear fruit with patience.", 
                "num": 15
              }, 
              {
                "text": "\"No one, when he has lit a lamp, covers it with a vessel or puts it under a bed, but sets it on a lampstand, that those who enter may see the light.", 
                "num": 16
              }, 
              {
                "text": "For nothing is secret that will not be revealed, nor anything hidden that will not be known and come to light.", 
                "num": 17
              }, 
              {
                "text": "Therefore take heed how you hear. For whoever has, to him more will be given; and whoever does not have, even what he seems to have will be taken from him.\"", 
                "num": 18
              }, 
              {
                "text": "Then His mother and brothers came to Him, and could not approach Him because of the crowd.", 
                "num": 19
              }, 
              {
                "text": "And it was told Him by some, who said, \"Your mother and Your brothers are standing outside, desiring to see You.\"", 
                "num": 20
              }, 
              {
                "text": "But He answered and said to them, \"My mother and My brothers are these who hear the word of God and do it.\"", 
                "num": 21
              }, 
              {
                "text": "Now it happened, on a certain day, that He got into a boat with His disciples. And He said to them, \"Let us cross over to the other side of the lake.\" And they launched out.", 
                "num": 22
              }, 
              {
                "text": "But as they sailed He fell asleep. And a windstorm came down on the lake, and they were filling with water, and were in jeopardy.", 
                "num": 23
              }, 
              {
                "text": "And they came to Him and awoke Him, saying, \"Master, Master, we are perishing!\" Then He arose and rebuked the wind and the raging of the water. And they ceased, and there was a calm.", 
                "num": 24
              }, 
              {
                "text": "But He said to them, \"Where is your faith?\" And they were afraid, and marveled, saying to one another, \"Who can this be? For He commands even the winds and water, and they obey Him!\"", 
                "num": 25
              }, 
              {
                "text": "Then they sailed to the country of the Gadarenes, which is opposite Galilee.", 
                "num": 26
              }, 
              {
                "text": "And when He stepped out on the land, there met Him a certain man from the city who had demons for a long time. And he wore no clothes, nor did he live in a house but in the tombs.", 
                "num": 27
              }, 
              {
                "text": "When he saw Jesus, he cried out, fell down before Him, and with a loud voice said, \"What have I to do with You, Jesus, Son of the Most High God? I beg You, do not torment me!\"", 
                "num": 28
              }, 
              {
                "text": "For He had commanded the unclean spirit to come out of the man. For it had often seized him, and he was kept under guard, bound with chains and shackles; and he broke the bonds and was driven by the demon into the wilderness.", 
                "num": 29
              }, 
              {
                "text": "Jesus asked him, saying, \"What is your name?\" And he said, \"Legion,\" because many demons had entered him.", 
                "num": 30
              }, 
              {
                "text": "And they begged Him that He would not command them to go out into the abyss.", 
                "num": 31
              }, 
              {
                "text": "Now a herd of many swine was feeding there on the mountain. So they begged Him that He would permit them to enter them. And He permitted them.", 
                "num": 32
              }, 
              {
                "text": "Then the demons went out of the man and entered the swine, and the herd ran violently down the steep place into the lake and drowned.", 
                "num": 33
              }, 
              {
                "text": "When those who fed them saw what had happened, they fled and told it in the city and in the country.", 
                "num": 34
              }, 
              {
                "text": "Then they went out to see what had happened, and came to Jesus, and found the man from whom the demons had departed, sitting at the feet of Jesus, clothed and in his right mind. And they were afraid.", 
                "num": 35
              }, 
              {
                "text": "They also who had seen it told them by what means he who had been demon-possessed was healed.", 
                "num": 36
              }, 
              {
                "text": "Then the whole multitude of the surrounding region of the Gadarenes asked Him to depart from them, for they were seized with great fear. And He got into the boat and returned.", 
                "num": 37
              }, 
              {
                "text": "Now the man from whom the demons had departed begged Him that he might be with Him. But Jesus sent him away, saying,", 
                "num": 38
              }, 
              {
                "text": "\"Return to your own house, and tell what great things God has done for you.\" And he went his way and proclaimed throughout the whole city what great things Jesus had done for him.", 
                "num": 39
              }, 
              {
                "text": "So it was, when Jesus returned, that the multitude welcomed Him, for they were all waiting for Him.", 
                "num": 40
              }, 
              {
                "text": "And behold, there came a man named Jairus, and he was a ruler of the synagogue. And he fell down at Jesus' feet and begged Him to come to his house,", 
                "num": 41
              }, 
              {
                "text": "for he had an only daughter about twelve years of age, and she was dying. But as He went, the multitudes thronged Him.", 
                "num": 42
              }, 
              {
                "text": "Now a woman, having a flow of blood for twelve years, who had spent all her livelihood on physicians and could not be healed by any,", 
                "num": 43
              }, 
              {
                "text": "came from behind and touched the border of His garment. And immediately her flow of blood stopped.", 
                "num": 44
              }, 
              {
                "text": "And Jesus said, \"Who touched Me?\" When all denied it, Peter and those with him said, \"Master, the multitudes throng and press You, and You say, \"Who touched Me?\"'", 
                "num": 45
              }, 
              {
                "text": "But Jesus said, \"Somebody touched Me, for I perceived power going out from Me.\"", 
                "num": 46
              }, 
              {
                "text": "Now when the woman saw that she was not hidden, she came trembling; and falling down before Him, she declared to Him in the presence of all the people the reason she had touched Him and how she was healed immediately.", 
                "num": 47
              }, 
              {
                "text": "And He said to her, \"Daughter, be of good cheer; your faith has made you well. Go in peace.\"", 
                "num": 48
              }, 
              {
                "text": "While He was still speaking, someone came from the ruler of the synagogue's house, saying to him, \"Your daughter is dead. Do not trouble the Teacher.\"", 
                "num": 49
              }, 
              {
                "text": "But when Jesus heard it, He answered him, saying, \"Do not be afraid; only believe, and she will be made well.\"", 
                "num": 50
              }, 
              {
                "text": "When He came into the house, He permitted no one to go in except Peter, James, and John, and the father and mother of the girl.", 
                "num": 51
              }, 
              {
                "text": "Now all wept and mourned for her; but He said, \"Do not weep; she is not dead, but sleeping.\"", 
                "num": 52
              }, 
              {
                "text": "And they ridiculed Him, knowing that she was dead.", 
                "num": 53
              }, 
              {
                "text": "But He put them all outside, took her by the hand and called, saying, \"Little girl, arise.\"", 
                "num": 54
              }, 
              {
                "text": "Then her spirit returned, and she arose immediately. And He commanded that she be given something to eat.", 
                "num": 55
              }, 
              {
                "text": "And her parents were astonished, but He charged them to tell no one what had happened.", 
                "num": 56
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then He called His twelve disciples together and gave them power and authority over all demons, and to cure diseases.", 
                "num": 1
              }, 
              {
                "text": "He sent them to preach the kingdom of God and to heal the sick.", 
                "num": 2
              }, 
              {
                "text": "And He said to them, \"Take nothing for the journey, neither staffs nor bag nor bread nor money; and do not have two tunics apiece.", 
                "num": 3
              }, 
              {
                "text": "\"Whatever house you enter, stay there, and from there depart.", 
                "num": 4
              }, 
              {
                "text": "And whoever will not receive you, when you go out of that city, shake off the very dust from your feet as a testimony against them.\"", 
                "num": 5
              }, 
              {
                "text": "So they departed and went through the towns, preaching the gospel and healing everywhere.", 
                "num": 6
              }, 
              {
                "text": "Now Herod the tetrarch heard of all that was done by Him; and he was perplexed, because it was said by some that John had risen from the dead,", 
                "num": 7
              }, 
              {
                "text": "and by some that Elijah had appeared, and by others that one of the old prophets had risen again.", 
                "num": 8
              }, 
              {
                "text": "Herod said, \"John I have beheaded, but who is this of whom I hear such things?\" So he sought to see Him.", 
                "num": 9
              }, 
              {
                "text": "And the apostles, when they had returned, told Him all that they had done. Then He took them and went aside privately into a deserted place belonging to the city called Bethsaida.", 
                "num": 10
              }, 
              {
                "text": "But when the multitudes knew it, they followed Him; and He received them and spoke to them about the kingdom of God, and healed those who had need of healing.", 
                "num": 11
              }, 
              {
                "text": "When the day began to wear away, the twelve came and said to Him, \"Send the multitude away, that they may go into the surrounding towns and country, and lodge and get provisions; for we are in a deserted place here.\"", 
                "num": 12
              }, 
              {
                "text": "But He said to them, \"You give them something to eat.\" And they said, \"We have no more than five loaves and two fish, unless we go and buy food for all these people.\"", 
                "num": 13
              }, 
              {
                "text": "For there were about five thousand men. Then He said to His disciples, \"Make them sit down in groups of fifty.\"", 
                "num": 14
              }, 
              {
                "text": "And they did so, and made them all sit down.", 
                "num": 15
              }, 
              {
                "text": "Then He took the five loaves and the two fish, and looking up to heaven, He blessed and broke them, and gave them to the disciples to set before the multitude.", 
                "num": 16
              }, 
              {
                "text": "So they all ate and were filled, and twelve baskets of the leftover fragments were taken up by them.", 
                "num": 17
              }, 
              {
                "text": "And it happened, as He was alone praying, that His disciples joined Him, and He asked them, saying, \"Who do the crowds say that I am?\"", 
                "num": 18
              }, 
              {
                "text": "So they answered and said, \"John the Baptist, but some say Elijah; and others say that one of the old prophets has risen again.\"", 
                "num": 19
              }, 
              {
                "text": "He said to them, \"But who do you say that I am?\" Peter answered and said, \"The Christ of God.\"", 
                "num": 20
              }, 
              {
                "text": "And He strictly warned and commanded them to tell this to no one,", 
                "num": 21
              }, 
              {
                "text": "saying, \"The Son of Man must suffer many things, and be rejected by the elders and chief priests and scribes, and be killed, and be raised the third day.\"", 
                "num": 22
              }, 
              {
                "text": "Then He said to them all, \"If anyone desires to come after Me, let him deny himself, and take up his cross daily, and follow Me.", 
                "num": 23
              }, 
              {
                "text": "For whoever desires to save his life will lose it, but whoever loses his life for My sake will save it.", 
                "num": 24
              }, 
              {
                "text": "For what profit is it to a man if he gains the whole world, and is himself destroyed or lost?", 
                "num": 25
              }, 
              {
                "text": "For whoever is ashamed of Me and My words, of him the Son of Man will be ashamed when He comes in His own glory, and in His Father's, and of the holy angels.", 
                "num": 26
              }, 
              {
                "text": "But I tell you truly, there are some standing here who shall not taste death till they see the kingdom of God.\"", 
                "num": 27
              }, 
              {
                "text": "Now it came to pass, about eight days after these sayings, that He took Peter, John, and James and went up on the mountain to pray.", 
                "num": 28
              }, 
              {
                "text": "As He prayed, the appearance of His face was altered, and His robe became white and glistening.", 
                "num": 29
              }, 
              {
                "text": "And behold, two men talked with Him, who were Moses and Elijah,", 
                "num": 30
              }, 
              {
                "text": "who appeared in glory and spoke of His decease which He was about to accomplish at Jerusalem.", 
                "num": 31
              }, 
              {
                "text": "But Peter and those with him were heavy with sleep; and when they were fully awake, they saw His glory and the two men who stood with Him.", 
                "num": 32
              }, 
              {
                "text": "Then it happened, as they were parting from Him, that Peter said to Jesus, \"Master, it is good for us to be here; and let us make three tabernacles: one for You, one for Moses, and one for Elijah\"--not knowing what he said.", 
                "num": 33
              }, 
              {
                "text": "While he was saying this, a cloud came and overshadowed them; and they were fearful as they entered the cloud.", 
                "num": 34
              }, 
              {
                "text": "And a voice came out of the cloud, saying, \"This is My beloved Son. Hear Him!\"", 
                "num": 35
              }, 
              {
                "text": "When the voice had ceased, Jesus was found alone. But they kept quiet, and told no one in those days any of the things they had seen.", 
                "num": 36
              }, 
              {
                "text": "Now it happened on the next day, when they had come down from the mountain, that a great multitude met Him.", 
                "num": 37
              }, 
              {
                "text": "Suddenly a man from the multitude cried out, saying, \"Teacher, I implore You, look on my son, for he is my only child.", 
                "num": 38
              }, 
              {
                "text": "And behold, a spirit seizes him, and he suddenly cries out; it convulses him so that he foams at the mouth; and it departs from him with great difficulty, bruising him.", 
                "num": 39
              }, 
              {
                "text": "So I implored Your disciples to cast it out, but they could not.\"", 
                "num": 40
              }, 
              {
                "text": "Then Jesus answered and said, \"O faithless and perverse generation, how long shall I be with you and bear with you? Bring your son here.\"", 
                "num": 41
              }, 
              {
                "text": "And as he was still coming, the demon threw him down and convulsed him. Then Jesus rebuked the unclean spirit, healed the child, and gave him back to his father.", 
                "num": 42
              }, 
              {
                "text": "And they were all amazed at the majesty of God. But while everyone marveled at all the things which Jesus did, He said to His disciples,", 
                "num": 43
              }, 
              {
                "text": "\"Let these words sink down into your ears, for the Son of Man is about to be betrayed into the hands of men.\"", 
                "num": 44
              }, 
              {
                "text": "But they did not understand this saying, and it was hidden from them so that they did not perceive it; and they were afraid to ask Him about this saying.", 
                "num": 45
              }, 
              {
                "text": "Then a dispute arose among them as to which of them would be greatest.", 
                "num": 46
              }, 
              {
                "text": "And Jesus, perceiving the thought of their heart, took a little child and set him by Him,", 
                "num": 47
              }, 
              {
                "text": "and said to them, \"Whoever receives this little child in My name receives Me; and whoever receives Me receives Him who sent Me. For he who is least among you all will be great.\"", 
                "num": 48
              }, 
              {
                "text": "Now John answered and said, \"Master, we saw someone casting out demons in Your name, and we forbade him because he does not follow with us.\"", 
                "num": 49
              }, 
              {
                "text": "But Jesus said to him, \"Do not forbid him, for he who is not against us is on our side.\"", 
                "num": 50
              }, 
              {
                "text": "Now it came to pass, when the time had come for Him to be received up, that He steadfastly set His face to go to Jerusalem,", 
                "num": 51
              }, 
              {
                "text": "and sent messengers before His face. And as they went, they entered a village of the Samaritans, to prepare for Him.", 
                "num": 52
              }, 
              {
                "text": "But they did not receive Him, because His face was set for the journey to Jerusalem.", 
                "num": 53
              }, 
              {
                "text": "And when His disciples James and John saw this, they said, \"Lord, do You want us to command fire to come down from heaven and consume them, just as Elijah did?\"", 
                "num": 54
              }, 
              {
                "text": "But He turned and rebuked them, and said, \"You do not know what manner of spirit you are of.", 
                "num": 55
              }, 
              {
                "text": "For the Son of Man did not come to destroy men's lives but to save them.\" And they went to another village.", 
                "num": 56
              }, 
              {
                "text": "Now it happened as they journeyed on the road, that someone said to Him, \"Lord, I will follow You wherever You go.\"", 
                "num": 57
              }, 
              {
                "text": "And Jesus said to him, \"Foxes have holes and birds of the air have nests, but the Son of Man has nowhere to lay His head.\"", 
                "num": 58
              }, 
              {
                "text": "Then He said to another, \"Follow Me.\" But he said, \"Lord, let me first go and bury my father.\"", 
                "num": 59
              }, 
              {
                "text": "Jesus said to him, \"Let the dead bury their own dead, but you go and preach the kingdom of God.\"", 
                "num": 60
              }, 
              {
                "text": "And another also said, \"Lord, I will follow You, but let me first go and bid them farewell who are at my house.\"", 
                "num": 61
              }, 
              {
                "text": "But Jesus said to him, \"No one, having put his hand to the plow, and looking back, is fit for the kingdom of God.\"", 
                "num": 62
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "After these things the Lord appointed seventy others also, and sent them two by two before His face into every city and place where He Himself was about to go.", 
                "num": 1
              }, 
              {
                "text": "Then He said to them, \"The harvest truly is great, but the laborers are few; therefore pray the Lord of the harvest to send out laborers into His harvest.", 
                "num": 2
              }, 
              {
                "text": "Go your way; behold, I send you out as lambs among wolves.", 
                "num": 3
              }, 
              {
                "text": "Carry neither money bag, knapsack, nor sandals; and greet no one along the road.", 
                "num": 4
              }, 
              {
                "text": "But whatever house you enter, first say, \"Peace to this house.'", 
                "num": 5
              }, 
              {
                "text": "And if a son of peace is there, your peace will rest on it; if not, it will return to you.", 
                "num": 6
              }, 
              {
                "text": "And remain in the same house, eating and drinking such things as they give, for the laborer is worthy of his wages. Do not go from house to house.", 
                "num": 7
              }, 
              {
                "text": "Whatever city you enter, and they receive you, eat such things as are set before you.", 
                "num": 8
              }, 
              {
                "text": "And heal the sick there, and say to them, \"The kingdom of God has come near to you.'", 
                "num": 9
              }, 
              {
                "text": "But whatever city you enter, and they do not receive you, go out into its streets and say,", 
                "num": 10
              }, 
              {
                "text": "\"The very dust of your city which clings to us we wipe off against you. Nevertheless know this, that the kingdom of God has come near you.'", 
                "num": 11
              }, 
              {
                "text": "But I say to you that it will be more tolerable in that Day for Sodom than for that city.", 
                "num": 12
              }, 
              {
                "text": "\"Woe to you, Chorazin! Woe to you, Bethsaida! For if the mighty works which were done in you had been done in Tyre and Sidon, they would have repented long ago, sitting in sackcloth and ashes.", 
                "num": 13
              }, 
              {
                "text": "But it will be more tolerable for Tyre and Sidon at the judgment than for you.", 
                "num": 14
              }, 
              {
                "text": "And you, Capernaum, who are exalted to heaven, will be brought down to Hades.", 
                "num": 15
              }, 
              {
                "text": "He who hears you hears Me, he who rejects you rejects Me, and he who rejects Me rejects Him who sent Me.\"", 
                "num": 16
              }, 
              {
                "text": "Then the seventy returned with joy, saying, \"Lord, even the demons are subject to us in Your name.\"", 
                "num": 17
              }, 
              {
                "text": "And He said to them, \"I saw Satan fall like lightning from heaven.", 
                "num": 18
              }, 
              {
                "text": "Behold, I give you the authority to trample on serpents and scorpions, and over all the power of the enemy, and nothing shall by any means hurt you.", 
                "num": 19
              }, 
              {
                "text": "Nevertheless do not rejoice in this, that the spirits are subject to you, but rather rejoice because your names are written in heaven.\"", 
                "num": 20
              }, 
              {
                "text": "In that hour Jesus rejoiced in the Spirit and said, \"I thank You, Father, Lord of heaven and earth, that You have hidden these things from the wise and prudent and revealed them to babes. Even so, Father, for so it seemed good in Your sight.", 
                "num": 21
              }, 
              {
                "text": "All things have been delivered to Me by My Father, and no one knows who the Son is except the Father, and who the Father is except the Son, and the one to whom the Son wills to reveal Him.\"", 
                "num": 22
              }, 
              {
                "text": "Then He turned to His disciples and said privately, \"Blessed are the eyes which see the things you see;", 
                "num": 23
              }, 
              {
                "text": "for I tell you that many prophets and kings have desired to see what you see, and have not seen it, and to hear what you hear, and have not heard it.\"", 
                "num": 24
              }, 
              {
                "text": "And behold, a certain lawyer stood up and tested Him, saying, \"Teacher, what shall I do to inherit eternal life?\"", 
                "num": 25
              }, 
              {
                "text": "He said to him, \"What is written in the law? What is your reading of it?\"", 
                "num": 26
              }, 
              {
                "text": "So he answered and said, \"\"You shall love the LORD your God with all your heart, with all your soul, with all your strength, and with all your mind,' and \"your neighbor as yourself.\"'", 
                "num": 27
              }, 
              {
                "text": "And He said to him, \"You have answered rightly; do this and you will live.\"", 
                "num": 28
              }, 
              {
                "text": "But he, wanting to justify himself, said to Jesus, \"And who is my neighbor?\"", 
                "num": 29
              }, 
              {
                "text": "Then Jesus answered and said: \"A certain man went down from Jerusalem to Jericho, and fell among thieves, who stripped him of his clothing, wounded him, and departed, leaving him half dead.", 
                "num": 30
              }, 
              {
                "text": "Now by chance a certain priest came down that road. And when he saw him, he passed by on the other side.", 
                "num": 31
              }, 
              {
                "text": "Likewise a Levite, when he arrived at the place, came and looked, and passed by on the other side.", 
                "num": 32
              }, 
              {
                "text": "But a certain Samaritan, as he journeyed, came where he was. And when he saw him, he had compassion.", 
                "num": 33
              }, 
              {
                "text": "So he went to him and bandaged his wounds, pouring on oil and wine; and he set him on his own animal, brought him to an inn, and took care of him.", 
                "num": 34
              }, 
              {
                "text": "On the next day, when he departed, he took out two denarii, gave them to the innkeeper, and said to him, \"Take care of him; and whatever more you spend, when I come again, I will repay you.'", 
                "num": 35
              }, 
              {
                "text": "So which of these three do you think was neighbor to him who fell among the thieves?\"", 
                "num": 36
              }, 
              {
                "text": "And he said, \"He who showed mercy on him.\" Then Jesus said to him, \"Go and do likewise.\"", 
                "num": 37
              }, 
              {
                "text": "Now it happened as they went that He entered a certain village; and a certain woman named Martha welcomed Him into her house.", 
                "num": 38
              }, 
              {
                "text": "And she had a sister called Mary, who also sat at Jesus' feet and heard His word.", 
                "num": 39
              }, 
              {
                "text": "But Martha was distracted with much serving, and she approached Him and said, \"Lord, do You not care that my sister has left me to serve alone? Therefore tell her to help me.\"", 
                "num": 40
              }, 
              {
                "text": "And Jesus answered and said to her, \"Martha, Martha, you are worried and troubled about many things.", 
                "num": 41
              }, 
              {
                "text": "But one thing is needed, and Mary has chosen that good part, which will not be taken away from her.\"", 
                "num": 42
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, as He was praying in a certain place, when He ceased, that one of His disciples said to Him, \"Lord, teach us to pray, as John also taught his disciples.\"", 
                "num": 1
              }, 
              {
                "text": "So He said to them, \"When you pray, say: Our Father in heaven, Hallowed be Your name. Your kingdom come. Your will be done On earth as it is in heaven.", 
                "num": 2
              }, 
              {
                "text": "Give us day by day our daily bread.", 
                "num": 3
              }, 
              {
                "text": "And forgive us our sins, For we also forgive everyone who is indebted to us. And do not lead us into temptation, But deliver us from the evil one.\"", 
                "num": 4
              }, 
              {
                "text": "And He said to them, \"Which of you shall have a friend, and go to him at midnight and say to him, \"Friend, lend me three loaves;", 
                "num": 5
              }, 
              {
                "text": "for a friend of mine has come to me on his journey, and I have nothing to set before him';", 
                "num": 6
              }, 
              {
                "text": "and he will answer from within and say, \"Do not trouble me; the door is now shut, and my children are with me in bed; I cannot rise and give to you'?", 
                "num": 7
              }, 
              {
                "text": "I say to you, though he will not rise and give to him because he is his friend, yet because of his persistence he will rise and give him as many as he needs.", 
                "num": 8
              }, 
              {
                "text": "\"So I say to you, ask, and it will be given to you; seek, and you will find; knock, and it will be opened to you.", 
                "num": 9
              }, 
              {
                "text": "For everyone who asks receives, and he who seeks finds, and to him who knocks it will be opened.", 
                "num": 10
              }, 
              {
                "text": "If a son asks for bread from any father among you, will he give him a stone? Or if he asks for a fish, will he give him a serpent instead of a fish?", 
                "num": 11
              }, 
              {
                "text": "Or if he asks for an egg, will he offer him a scorpion?", 
                "num": 12
              }, 
              {
                "text": "If you then, being evil, know how to give good gifts to your children, how much more will your heavenly Father give the Holy Spirit to those who ask Him!\"", 
                "num": 13
              }, 
              {
                "text": "And He was casting out a demon, and it was mute. So it was, when the demon had gone out, that the mute spoke; and the multitudes marveled.", 
                "num": 14
              }, 
              {
                "text": "But some of them said, \"He casts out demons by Beelzebub, the ruler of the demons.\"", 
                "num": 15
              }, 
              {
                "text": "Others, testing Him, sought from Him a sign from heaven.", 
                "num": 16
              }, 
              {
                "text": "But He, knowing their thoughts, said to them: \"Every kingdom divided against itself is brought to desolation, and a house divided against a house falls.", 
                "num": 17
              }, 
              {
                "text": "If Satan also is divided against himself, how will his kingdom stand? Because you say I cast out demons by Beelzebub.", 
                "num": 18
              }, 
              {
                "text": "And if I cast out demons by Beelzebub, by whom do your sons cast them out? Therefore they will be your judges.", 
                "num": 19
              }, 
              {
                "text": "But if I cast out demons with the finger of God, surely the kingdom of God has come upon you.", 
                "num": 20
              }, 
              {
                "text": "When a strong man, fully armed, guards his own palace, his goods are in peace.", 
                "num": 21
              }, 
              {
                "text": "But when a stronger than he comes upon him and overcomes him, he takes from him all his armor in which he trusted, and divides his spoils.", 
                "num": 22
              }, 
              {
                "text": "He who is not with Me is against Me, and he who does not gather with Me scatters.", 
                "num": 23
              }, 
              {
                "text": "\"When an unclean spirit goes out of a man, he goes through dry places, seeking rest; and finding none, he says, \"I will return to my house from which I came.'", 
                "num": 24
              }, 
              {
                "text": "And when he comes, he finds it swept and put in order.", 
                "num": 25
              }, 
              {
                "text": "Then he goes and takes with him seven other spirits more wicked than himself, and they enter and dwell there; and the last state of that man is worse than the first.\"", 
                "num": 26
              }, 
              {
                "text": "And it happened, as He spoke these things, that a certain woman from the crowd raised her voice and said to Him, \"Blessed is the womb that bore You, and the breasts which nursed You!\"", 
                "num": 27
              }, 
              {
                "text": "But He said, \"More than that, blessed are those who hear the word of God and keep it!\"", 
                "num": 28
              }, 
              {
                "text": "And while the crowds were thickly gathered together, He began to say, \"This is an evil generation. It seeks a sign, and no sign will be given to it except the sign of Jonah the prophet.", 
                "num": 29
              }, 
              {
                "text": "For as Jonah became a sign to the Ninevites, so also the Son of Man will be to this generation.", 
                "num": 30
              }, 
              {
                "text": "The queen of the South will rise up in the judgment with the men of this generation and condemn them, for she came from the ends of the earth to hear the wisdom of Solomon; and indeed a greater than Solomon is here.", 
                "num": 31
              }, 
              {
                "text": "The men of Nineveh will rise up in the judgment with this generation and condemn it, for they repented at the preaching of Jonah; and indeed a greater than Jonah is here.", 
                "num": 32
              }, 
              {
                "text": "\"No one, when he has lit a lamp, puts it in a secret place or under a basket, but on a lampstand, that those who come in may see the light.", 
                "num": 33
              }, 
              {
                "text": "The lamp of the body is the eye. Therefore, when your eye is good, your whole body also is full of light. But when your eye is bad, your body also is full of darkness.", 
                "num": 34
              }, 
              {
                "text": "Therefore take heed that the light which is in you is not darkness.", 
                "num": 35
              }, 
              {
                "text": "If then your whole body is full of light, having no part dark, the whole body will be full of light, as when the bright shining of a lamp gives you light.\"", 
                "num": 36
              }, 
              {
                "text": "And as He spoke, a certain Pharisee asked Him to dine with him. So He went in and sat down to eat.", 
                "num": 37
              }, 
              {
                "text": "When the Pharisee saw it, he marveled that He had not first washed before dinner.", 
                "num": 38
              }, 
              {
                "text": "Then the Lord said to him, \"Now you Pharisees make the outside of the cup and dish clean, but your inward part is full of greed and wickedness.", 
                "num": 39
              }, 
              {
                "text": "Foolish ones! Did not He who made the outside make the inside also?", 
                "num": 40
              }, 
              {
                "text": "But rather give alms of such things as you have; then indeed all things are clean to you.", 
                "num": 41
              }, 
              {
                "text": "\"But woe to you Pharisees! For you tithe mint and rue and all manner of herbs, and pass by justice and the love of God. These you ought to have done, without leaving the others undone.", 
                "num": 42
              }, 
              {
                "text": "Woe to you Pharisees! For you love the best seats in the synagogues and greetings in the marketplaces.", 
                "num": 43
              }, 
              {
                "text": "Woe to you, scribes and Pharisees, hypocrites! For you are like graves which are not seen, and the men who walk over them are not aware of them.\"", 
                "num": 44
              }, 
              {
                "text": "Then one of the lawyers answered and said to Him, \"Teacher, by saying these things You reproach us also.\"", 
                "num": 45
              }, 
              {
                "text": "And He said, \"Woe to you also, lawyers! For you load men with burdens hard to bear, and you yourselves do not touch the burdens with one of your fingers.", 
                "num": 46
              }, 
              {
                "text": "Woe to you! For you build the tombs of the prophets, and your fathers killed them.", 
                "num": 47
              }, 
              {
                "text": "In fact, you bear witness that you approve the deeds of your fathers; for they indeed killed them, and you build their tombs.", 
                "num": 48
              }, 
              {
                "text": "Therefore the wisdom of God also said, \"I will send them prophets and apostles, and some of them they will kill and persecute,'", 
                "num": 49
              }, 
              {
                "text": "that the blood of all the prophets which was shed from the foundation of the world may be required of this generation,", 
                "num": 50
              }, 
              {
                "text": "from the blood of Abel to the blood of Zechariah who perished between the altar and the temple. Yes, I say to you, it shall be required of this generation.", 
                "num": 51
              }, 
              {
                "text": "\"Woe to you lawyers! For you have taken away the key of knowledge. You did not enter in yourselves, and those who were entering in you hindered.\"", 
                "num": 52
              }, 
              {
                "text": "And as He said these things to them, the scribes and the Pharisees began to assail Him vehemently, and to cross-examine Him about many things,", 
                "num": 53
              }, 
              {
                "text": "lying in wait for Him, and seeking to catch Him in something He might say, that they might accuse Him.", 
                "num": 54
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "In the meantime, when an innumerable multitude of people had gathered together, so that they trampled one another, He began to say to His disciples first of all, \"Beware of the leaven of the Pharisees, which is hypocrisy.", 
                "num": 1
              }, 
              {
                "text": "For there is nothing covered that will not be revealed, nor hidden that will not be known.", 
                "num": 2
              }, 
              {
                "text": "Therefore whatever you have spoken in the dark will be heard in the light, and what you have spoken in the ear in inner rooms will be proclaimed on the housetops.", 
                "num": 3
              }, 
              {
                "text": "\"And I say to you, My friends, do not be afraid of those who kill the body, and after that have no more that they can do.", 
                "num": 4
              }, 
              {
                "text": "But I will show you whom you should fear: Fear Him who, after He has killed, has power to cast into hell; yes, I say to you, fear Him!", 
                "num": 5
              }, 
              {
                "text": "\"Are not five sparrows sold for two copper coins? And not one of them is forgotten before God.", 
                "num": 6
              }, 
              {
                "text": "But the very hairs of your head are all numbered. Do not fear therefore; you are of more value than many sparrows.", 
                "num": 7
              }, 
              {
                "text": "\"Also I say to you, whoever confesses Me before men, him the Son of Man also will confess before the angels of God.", 
                "num": 8
              }, 
              {
                "text": "But he who denies Me before men will be denied before the angels of God.", 
                "num": 9
              }, 
              {
                "text": "\"And anyone who speaks a word against the Son of Man, it will be forgiven him; but to him who blasphemes against the Holy Spirit, it will not be forgiven.", 
                "num": 10
              }, 
              {
                "text": "\"Now when they bring you to the synagogues and magistrates and authorities, do not worry about how or what you should answer, or what you should say.", 
                "num": 11
              }, 
              {
                "text": "For the Holy Spirit will teach you in that very hour what you ought to say.\"", 
                "num": 12
              }, 
              {
                "text": "Then one from the crowd said to Him, \"Teacher, tell my brother to divide the inheritance with me.\"", 
                "num": 13
              }, 
              {
                "text": "But He said to him, \"Man, who made Me a judge or an arbitrator over you?\"", 
                "num": 14
              }, 
              {
                "text": "And He said to them, \"Take heed and beware of covetousness, for one's life does not consist in the abundance of the things he possesses.\"", 
                "num": 15
              }, 
              {
                "text": "Then He spoke a parable to them, saying: \"The ground of a certain rich man yielded plentifully.", 
                "num": 16
              }, 
              {
                "text": "And he thought within himself, saying, \"What shall I do, since I have no room to store my crops?'", 
                "num": 17
              }, 
              {
                "text": "So he said, \"I will do this: I will pull down my barns and build greater, and there I will store all my crops and my goods.", 
                "num": 18
              }, 
              {
                "text": "And I will say to my soul, \"Soul, you have many goods laid up for many years; take your ease; eat, drink, and be merry.\"'", 
                "num": 19
              }, 
              {
                "text": "But God said to him, \"Fool! This night your soul will be required of you; then whose will those things be which you have provided?'", 
                "num": 20
              }, 
              {
                "text": "\"So is he who lays up treasure for himself, and is not rich toward God.\"", 
                "num": 21
              }, 
              {
                "text": "Then He said to His disciples, \"Therefore I say to you, do not worry about your life, what you will eat; nor about the body, what you will put on.", 
                "num": 22
              }, 
              {
                "text": "Life is more than food, and the body is more than clothing.", 
                "num": 23
              }, 
              {
                "text": "Consider the ravens, for they neither sow nor reap, which have neither storehouse nor barn; and God feeds them. Of how much more value are you than the birds?", 
                "num": 24
              }, 
              {
                "text": "And which of you by worrying can add one cubit to his stature?", 
                "num": 25
              }, 
              {
                "text": "If you then are not able to do the least, why are you anxious for the rest?", 
                "num": 26
              }, 
              {
                "text": "Consider the lilies, how they grow: they neither toil nor spin; and yet I say to you, even Solomon in all his glory was not arrayed like one of these.", 
                "num": 27
              }, 
              {
                "text": "If then God so clothes the grass, which today is in the field and tomorrow is thrown into the oven, how much more will He clothe you, O you of little faith?", 
                "num": 28
              }, 
              {
                "text": "\"And do not seek what you should eat or what you should drink, nor have an anxious mind.", 
                "num": 29
              }, 
              {
                "text": "For all these things the nations of the world seek after, and your Father knows that you need these things.", 
                "num": 30
              }, 
              {
                "text": "But seek the kingdom of God, and all these things shall be added to you.", 
                "num": 31
              }, 
              {
                "text": "\"Do not fear, little flock, for it is your Father's good pleasure to give you the kingdom.", 
                "num": 32
              }, 
              {
                "text": "Sell what you have and give alms; provide yourselves money bags which do not grow old, a treasure in the heavens that does not fail, where no thief approaches nor moth destroys.", 
                "num": 33
              }, 
              {
                "text": "For where your treasure is, there your heart will be also.", 
                "num": 34
              }, 
              {
                "text": "\"Let your waist be girded and your lamps burning;", 
                "num": 35
              }, 
              {
                "text": "and you yourselves be like men who wait for their master, when he will return from the wedding, that when he comes and knocks they may open to him immediately.", 
                "num": 36
              }, 
              {
                "text": "Blessed are those servants whom the master, when he comes, will find watching. Assuredly, I say to you that he will gird himself and have them sit down to eat, and will come and serve them.", 
                "num": 37
              }, 
              {
                "text": "And if he should come in the second watch, or come in the third watch, and find them so, blessed are those servants.", 
                "num": 38
              }, 
              {
                "text": "But know this, that if the master of the house had known what hour the thief would come, he would have watched and not allowed his house to be broken into.", 
                "num": 39
              }, 
              {
                "text": "Therefore you also be ready, for the Son of Man is coming at an hour you do not expect.\"", 
                "num": 40
              }, 
              {
                "text": "Then Peter said to Him, \"Lord, do You speak this parable only to us, or to all people?\"", 
                "num": 41
              }, 
              {
                "text": "And the Lord said, \"Who then is that faithful and wise steward, whom his master will make ruler over his household, to give them their portion of food in due season?", 
                "num": 42
              }, 
              {
                "text": "Blessed is that servant whom his master will find so doing when he comes.", 
                "num": 43
              }, 
              {
                "text": "Truly, I say to you that he will make him ruler over all that he has.", 
                "num": 44
              }, 
              {
                "text": "But if that servant says in his heart, \"My master is delaying his coming,' and begins to beat the male and female servants, and to eat and drink and be drunk,", 
                "num": 45
              }, 
              {
                "text": "the master of that servant will come on a day when he is not looking for him, and at an hour when he is not aware, and will cut him in two and appoint him his portion with the unbelievers.", 
                "num": 46
              }, 
              {
                "text": "And that servant who knew his master's will, and did not prepare himself or do according to his will, shall be beaten with many stripes.", 
                "num": 47
              }, 
              {
                "text": "But he who did not know, yet committed things deserving of stripes, shall be beaten with few. For everyone to whom much is given, from him much will be required; and to whom much has been committed, of him they will ask the more.", 
                "num": 48
              }, 
              {
                "text": "\"I came to send fire on the earth, and how I wish it were already kindled!", 
                "num": 49
              }, 
              {
                "text": "But I have a baptism to be baptized with, and how distressed I am till it is accomplished!", 
                "num": 50
              }, 
              {
                "text": "Do you suppose that I came to give peace on earth? I tell you, not at all, but rather division.", 
                "num": 51
              }, 
              {
                "text": "For from now on five in one house will be divided: three against two, and two against three.", 
                "num": 52
              }, 
              {
                "text": "Father will be divided against son and son against father, mother against daughter and daughter against mother, mother-in-law against her daughter-in-law and daughter-in-law against her mother-in-law.\"", 
                "num": 53
              }, 
              {
                "text": "Then He also said to the multitudes, \"Whenever you see a cloud rising out of the west, immediately you say, \"A shower is coming'; and so it is.", 
                "num": 54
              }, 
              {
                "text": "And when you see the south wind blow, you say, \"There will be hot weather'; and there is.", 
                "num": 55
              }, 
              {
                "text": "Hypocrites! You can discern the face of the sky and of the earth, but how is it you do not discern this time?", 
                "num": 56
              }, 
              {
                "text": "\"Yes, and why, even of yourselves, do you not judge what is right?", 
                "num": 57
              }, 
              {
                "text": "When you go with your adversary to the magistrate, make every effort along the way to settle with him, lest he drag you to the judge, the judge deliver you to the officer, and the officer throw you into prison.", 
                "num": 58
              }, 
              {
                "text": "I tell you, you shall not depart from there till you have paid the very last mite.\"", 
                "num": 59
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "There were present at that season some who told Him about the Galileans whose blood Pilate had mingled with their sacrifices.", 
                "num": 1
              }, 
              {
                "text": "And Jesus answered and said to them, \"Do you suppose that these Galileans were worse sinners than all other Galileans, because they suffered such things?", 
                "num": 2
              }, 
              {
                "text": "I tell you, no; but unless you repent you will all likewise perish.", 
                "num": 3
              }, 
              {
                "text": "Or those eighteen on whom the tower in Siloam fell and killed them, do you think that they were worse sinners than all other men who dwelt in Jerusalem?", 
                "num": 4
              }, 
              {
                "text": "I tell you, no; but unless you repent you will all likewise perish.\"", 
                "num": 5
              }, 
              {
                "text": "He also spoke this parable: \"A certain man had a fig tree planted in his vineyard, and he came seeking fruit on it and found none.", 
                "num": 6
              }, 
              {
                "text": "Then he said to the keeper of his vineyard, \"Look, for three years I have come seeking fruit on this fig tree and find none. Cut it down; why does it use up the ground?'", 
                "num": 7
              }, 
              {
                "text": "But he answered and said to him, \"Sir, let it alone this year also, until I dig around it and fertilize it.", 
                "num": 8
              }, 
              {
                "text": "And if it bears fruit, well. But if not, after that you can cut it down.\"'", 
                "num": 9
              }, 
              {
                "text": "Now He was teaching in one of the synagogues on the Sabbath.", 
                "num": 10
              }, 
              {
                "text": "And behold, there was a woman who had a spirit of infirmity eighteen years, and was bent over and could in no way raise herself up.", 
                "num": 11
              }, 
              {
                "text": "But when Jesus saw her, He called her to Him and said to her, \"Woman, you are loosed from your infirmity.\"", 
                "num": 12
              }, 
              {
                "text": "And He laid His hands on her, and immediately she was made straight, and glorified God.", 
                "num": 13
              }, 
              {
                "text": "But the ruler of the synagogue answered with indignation, because Jesus had healed on the Sabbath; and he said to the crowd, \"There are six days on which men ought to work; therefore come and be healed on them, and not on the Sabbath day.\"", 
                "num": 14
              }, 
              {
                "text": "The Lord then answered him and said, \"Hypocrite! Does not each one of you on the Sabbath loose his ox or donkey from the stall, and lead it away to water it?", 
                "num": 15
              }, 
              {
                "text": "So ought not this woman, being a daughter of Abraham, whom Satan has bound--think of it--for eighteen years, be loosed from this bond on the Sabbath?\"", 
                "num": 16
              }, 
              {
                "text": "And when He said these things, all His adversaries were put to shame; and all the multitude rejoiced for all the glorious things that were done by Him.", 
                "num": 17
              }, 
              {
                "text": "Then He said, \"What is the kingdom of God like? And to what shall I compare it?", 
                "num": 18
              }, 
              {
                "text": "It is like a mustard seed, which a man took and put in his garden; and it grew and became a large tree, and the birds of the air nested in its branches.\"", 
                "num": 19
              }, 
              {
                "text": "And again He said, \"To what shall I liken the kingdom of God?", 
                "num": 20
              }, 
              {
                "text": "It is like leaven, which a woman took and hid in three measures of meal till it was all leavened.\"", 
                "num": 21
              }, 
              {
                "text": "And He went through the cities and villages, teaching, and journeying toward Jerusalem.", 
                "num": 22
              }, 
              {
                "text": "Then one said to Him, \"Lord, are there few who are saved?\" And He said to them,", 
                "num": 23
              }, 
              {
                "text": "\"Strive to enter through the narrow gate, for many, I say to you, will seek to enter and will not be able.", 
                "num": 24
              }, 
              {
                "text": "When once the Master of the house has risen up and shut the door, and you begin to stand outside and knock at the door, saying, \"Lord, Lord, open for us,' and He will answer and say to you, \"I do not know you, where you are from,'", 
                "num": 25
              }, 
              {
                "text": "then you will begin to say, \"We ate and drank in Your presence, and You taught in our streets.'", 
                "num": 26
              }, 
              {
                "text": "But He will say, \"I tell you I do not know you, where you are from. Depart from Me, all you workers of iniquity.'", 
                "num": 27
              }, 
              {
                "text": "There will be weeping and gnashing of teeth, when you see Abraham and Isaac and Jacob and all the prophets in the kingdom of God, and yourselves thrust out.", 
                "num": 28
              }, 
              {
                "text": "They will come from the east and the west, from the north and the south, and sit down in the kingdom of God.", 
                "num": 29
              }, 
              {
                "text": "And indeed there are last who will be first, and there are first who will be last.\"", 
                "num": 30
              }, 
              {
                "text": "On that very day some Pharisees came, saying to Him, \"Get out and depart from here, for Herod wants to kill You.\"", 
                "num": 31
              }, 
              {
                "text": "And He said to them, \"Go, tell that fox, \"Behold, I cast out demons and perform cures today and tomorrow, and the third day I shall be perfected.'", 
                "num": 32
              }, 
              {
                "text": "Nevertheless I must journey today, tomorrow, and the day following; for it cannot be that a prophet should perish outside of Jerusalem.", 
                "num": 33
              }, 
              {
                "text": "\"O Jerusalem, Jerusalem, the one who kills the prophets and stones those who are sent to her! How often I wanted to gather your children together, as a hen gathers her brood under her wings, but you were not willing!", 
                "num": 34
              }, 
              {
                "text": "See! Your house is left to you desolate; and assuredly, I say to you, you shall not see Me until the time comes when you say, \"Blessed is He who comes in the name of the LORD!\"'", 
                "num": 35
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now it happened, as He went into the house of one of the rulers of the Pharisees to eat bread on the Sabbath, that they watched Him closely.", 
                "num": 1
              }, 
              {
                "text": "And behold, there was a certain man before Him who had dropsy.", 
                "num": 2
              }, 
              {
                "text": "And Jesus, answering, spoke to the lawyers and Pharisees, saying, \"Is it lawful to heal on the Sabbath?\"", 
                "num": 3
              }, 
              {
                "text": "But they kept silent. And He took him and healed him, and let him go.", 
                "num": 4
              }, 
              {
                "text": "Then He answered them, saying, \"Which of you, having a donkey or an ox that has fallen into a pit, will not immediately pull him out on the Sabbath day?\"", 
                "num": 5
              }, 
              {
                "text": "And they could not answer Him regarding these things.", 
                "num": 6
              }, 
              {
                "text": "So He told a parable to those who were invited, when He noted how they chose the best places, saying to them:", 
                "num": 7
              }, 
              {
                "text": "\"When you are invited by anyone to a wedding feast, do not sit down in the best place, lest one more honorable than you be invited by him;", 
                "num": 8
              }, 
              {
                "text": "and he who invited you and him come and say to you, \"Give place to this man,' and then you begin with shame to take the lowest place.", 
                "num": 9
              }, 
              {
                "text": "But when you are invited, go and sit down in the lowest place, so that when he who invited you comes he may say to you, \"Friend, go up higher.' Then you will have glory in the presence of those who sit at the table with you.", 
                "num": 10
              }, 
              {
                "text": "For whoever exalts himself will be humbled, and he who humbles himself will be exalted.\"", 
                "num": 11
              }, 
              {
                "text": "Then He also said to him who invited Him, \"When you give a dinner or a supper, do not ask your friends, your brothers, your relatives, nor rich neighbors, lest they also invite you back, and you be repaid.", 
                "num": 12
              }, 
              {
                "text": "But when you give a feast, invite the poor, the maimed, the lame, the blind.", 
                "num": 13
              }, 
              {
                "text": "And you will be blessed, because they cannot repay you; for you shall be repaid at the resurrection of the just.\"", 
                "num": 14
              }, 
              {
                "text": "Now when one of those who sat at the table with Him heard these things, he said to Him, \"Blessed is he who shall eat bread in the kingdom of God!\"", 
                "num": 15
              }, 
              {
                "text": "Then He said to him, \"A certain man gave a great supper and invited many,", 
                "num": 16
              }, 
              {
                "text": "and sent his servant at supper time to say to those who were invited, \"Come, for all things are now ready.'", 
                "num": 17
              }, 
              {
                "text": "But they all with one accord began to make excuses. The first said to him, \"I have bought a piece of ground, and I must go and see it. I ask you to have me excused.'", 
                "num": 18
              }, 
              {
                "text": "And another said, \"I have bought five yoke of oxen, and I am going to test them. I ask you to have me excused.'", 
                "num": 19
              }, 
              {
                "text": "Still another said, \"I have married a wife, and therefore I cannot come.'", 
                "num": 20
              }, 
              {
                "text": "So that servant came and reported these things to his master. Then the master of the house, being angry, said to his servant, \"Go out quickly into the streets and lanes of the city, and bring in here the poor and the maimed and the lame and the blind.'", 
                "num": 21
              }, 
              {
                "text": "And the servant said, \"Master, it is done as you commanded, and still there is room.'", 
                "num": 22
              }, 
              {
                "text": "Then the master said to the servant, \"Go out into the highways and hedges, and compel them to come in, that my house may be filled.", 
                "num": 23
              }, 
              {
                "text": "For I say to you that none of those men who were invited shall taste my supper.\"'", 
                "num": 24
              }, 
              {
                "text": "Now great multitudes went with Him. And He turned and said to them,", 
                "num": 25
              }, 
              {
                "text": "\"If anyone comes to Me and does not hate his father and mother, wife and children, brothers and sisters, yes, and his own life also, he cannot be My disciple.", 
                "num": 26
              }, 
              {
                "text": "And whoever does not bear his cross and come after Me cannot be My disciple.", 
                "num": 27
              }, 
              {
                "text": "For which of you, intending to build a tower, does not sit down first and count the cost, whether he has enough to finish it--", 
                "num": 28
              }, 
              {
                "text": "lest, after he has laid the foundation, and is not able to finish, all who see it begin to mock him,", 
                "num": 29
              }, 
              {
                "text": "saying, \"This man began to build and was not able to finish.'", 
                "num": 30
              }, 
              {
                "text": "Or what king, going to make war against another king, does not sit down first and consider whether he is able with ten thousand to meet him who comes against him with twenty thousand?", 
                "num": 31
              }, 
              {
                "text": "Or else, while the other is still a great way off, he sends a delegation and asks conditions of peace.", 
                "num": 32
              }, 
              {
                "text": "So likewise, whoever of you does not forsake all that he has cannot be My disciple.", 
                "num": 33
              }, 
              {
                "text": "\"Salt is good; but if the salt has lost its flavor, how shall it be seasoned?", 
                "num": 34
              }, 
              {
                "text": "It is neither fit for the land nor for the dunghill, but men throw it out. He who has ears to hear, let him hear!\"", 
                "num": 35
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then all the tax collectors and the sinners drew near to Him to hear Him.", 
                "num": 1
              }, 
              {
                "text": "And the Pharisees and scribes complained, saying, \"This Man receives sinners and eats with them.\"", 
                "num": 2
              }, 
              {
                "text": "So He spoke this parable to them, saying:", 
                "num": 3
              }, 
              {
                "text": "\"What man of you, having a hundred sheep, if he loses one of them, does not leave the ninety-nine in the wilderness, and go after the one which is lost until he finds it?", 
                "num": 4
              }, 
              {
                "text": "And when he has found it, he lays it on his shoulders, rejoicing.", 
                "num": 5
              }, 
              {
                "text": "And when he comes home, he calls together his friends and neighbors, saying to them, \"Rejoice with me, for I have found my sheep which was lost!'", 
                "num": 6
              }, 
              {
                "text": "I say to you that likewise there will be more joy in heaven over one sinner who repents than over ninety-nine just persons who need no repentance.", 
                "num": 7
              }, 
              {
                "text": "\"Or what woman, having ten silver coins, if she loses one coin, does not light a lamp, sweep the house, and search carefully until she finds it?", 
                "num": 8
              }, 
              {
                "text": "And when she has found it, she calls her friends and neighbors together, saying, \"Rejoice with me, for I have found the piece which I lost!'", 
                "num": 9
              }, 
              {
                "text": "Likewise, I say to you, there is joy in the presence of the angels of God over one sinner who repents.\"", 
                "num": 10
              }, 
              {
                "text": "Then He said: \"A certain man had two sons.", 
                "num": 11
              }, 
              {
                "text": "And the younger of them said to his father, \"Father, give me the portion of goods that falls to me.' So he divided to them his livelihood.", 
                "num": 12
              }, 
              {
                "text": "And not many days after, the younger son gathered all together, journeyed to a far country, and there wasted his possessions with prodigal living.", 
                "num": 13
              }, 
              {
                "text": "But when he had spent all, there arose a severe famine in that land, and he began to be in want.", 
                "num": 14
              }, 
              {
                "text": "Then he went and joined himself to a citizen of that country, and he sent him into his fields to feed swine.", 
                "num": 15
              }, 
              {
                "text": "And he would gladly have filled his stomach with the pods that the swine ate, and no one gave him anything.", 
                "num": 16
              }, 
              {
                "text": "\"But when he came to himself, he said, \"How many of my father's hired servants have bread enough and to spare, and I perish with hunger!", 
                "num": 17
              }, 
              {
                "text": "I will arise and go to my father, and will say to him, \"Father, I have sinned against heaven and before you,", 
                "num": 18
              }, 
              {
                "text": "and I am no longer worthy to be called your son. Make me like one of your hired servants.\"'", 
                "num": 19
              }, 
              {
                "text": "\"And he arose and came to his father. But when he was still a great way off, his father saw him and had compassion, and ran and fell on his neck and kissed him.", 
                "num": 20
              }, 
              {
                "text": "And the son said to him, \"Father, I have sinned against heaven and in your sight, and am no longer worthy to be called your son.'", 
                "num": 21
              }, 
              {
                "text": "\"But the father said to his servants, \"Bring out the best robe and put it on him, and put a ring on his hand and sandals on his feet.", 
                "num": 22
              }, 
              {
                "text": "And bring the fatted calf here and kill it, and let us eat and be merry;", 
                "num": 23
              }, 
              {
                "text": "for this my son was dead and is alive again; he was lost and is found.' And they began to be merry.", 
                "num": 24
              }, 
              {
                "text": "\"Now his older son was in the field. And as he came and drew near to the house, he heard music and dancing.", 
                "num": 25
              }, 
              {
                "text": "So he called one of the servants and asked what these things meant.", 
                "num": 26
              }, 
              {
                "text": "And he said to him, \"Your brother has come, and because he has received him safe and sound, your father has killed the fatted calf.'", 
                "num": 27
              }, 
              {
                "text": "\"But he was angry and would not go in. Therefore his father came out and pleaded with him.", 
                "num": 28
              }, 
              {
                "text": "So he answered and said to his father, \"Lo, these many years I have been serving you; I never transgressed your commandment at any time; and yet you never gave me a young goat, that I might make merry with my friends.", 
                "num": 29
              }, 
              {
                "text": "But as soon as this son of yours came, who has devoured your livelihood with harlots, you killed the fatted calf for him.'", 
                "num": 30
              }, 
              {
                "text": "\"And he said to him, \"Son, you are always with me, and all that I have is yours.", 
                "num": 31
              }, 
              {
                "text": "It was right that we should make merry and be glad, for your brother was dead and is alive again, and was lost and is found.\"'", 
                "num": 32
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "He also said to His disciples: \"There was a certain rich man who had a steward, and an accusation was brought to him that this man was wasting his goods.", 
                "num": 1
              }, 
              {
                "text": "So he called him and said to him, \"What is this I hear about you? Give an account of your stewardship, for you can no longer be steward.'", 
                "num": 2
              }, 
              {
                "text": "\"Then the steward said within himself, \"What shall I do? For my master is taking the stewardship away from me. I cannot dig; I am ashamed to beg.", 
                "num": 3
              }, 
              {
                "text": "I have resolved what to do, that when I am put out of the stewardship, they may receive me into their houses.'", 
                "num": 4
              }, 
              {
                "text": "\"So he called every one of his master's debtors to him, and said to the first, \"How much do you owe my master?'", 
                "num": 5
              }, 
              {
                "text": "And he said, \"A hundred measures of oil.' So he said to him, \"Take your bill, and sit down quickly and write fifty.'", 
                "num": 6
              }, 
              {
                "text": "Then he said to another, \"And how much do you owe?' So he said, \"A hundred measures of wheat.' And he said to him, \"Take your bill, and write eighty.'", 
                "num": 7
              }, 
              {
                "text": "So the master commended the unjust steward because he had dealt shrewdly. For the sons of this world are more shrewd in their generation than the sons of light.", 
                "num": 8
              }, 
              {
                "text": "\"And I say to you, make friends for yourselves by unrighteous mammon, that when you fail, they may receive you into an everlasting home.", 
                "num": 9
              }, 
              {
                "text": "He who is faithful in what is least is faithful also in much; and he who is unjust in what is least is unjust also in much.", 
                "num": 10
              }, 
              {
                "text": "Therefore if you have not been faithful in the unrighteous mammon, who will commit to your trust the true riches?", 
                "num": 11
              }, 
              {
                "text": "And if you have not been faithful in what is another man's, who will give you what is your own?", 
                "num": 12
              }, 
              {
                "text": "\"No servant can serve two masters; for either he will hate the one and love the other, or else he will be loyal to the one and despise the other. You cannot serve God and mammon.\"", 
                "num": 13
              }, 
              {
                "text": "Now the Pharisees, who were lovers of money, also heard all these things, and they derided Him.", 
                "num": 14
              }, 
              {
                "text": "And He said to them, \"You are those who justify yourselves before men, but God knows your hearts. For what is highly esteemed among men is an abomination in the sight of God.", 
                "num": 15
              }, 
              {
                "text": "\"The law and the prophets were until John. Since that time the kingdom of God has been preached, and everyone is pressing into it.", 
                "num": 16
              }, 
              {
                "text": "And it is easier for heaven and earth to pass away than for one tittle of the law to fail.", 
                "num": 17
              }, 
              {
                "text": "\"Whoever divorces his wife and marries another commits adultery; and whoever marries her who is divorced from her husband commits adultery.", 
                "num": 18
              }, 
              {
                "text": "\"There was a certain rich man who was clothed in purple and fine linen and fared sumptuously every day.", 
                "num": 19
              }, 
              {
                "text": "But there was a certain beggar named Lazarus, full of sores, who was laid at his gate,", 
                "num": 20
              }, 
              {
                "text": "desiring to be fed with the crumbs which fell from the rich man's table. Moreover the dogs came and licked his sores.", 
                "num": 21
              }, 
              {
                "text": "So it was that the beggar died, and was carried by the angels to Abraham's bosom. The rich man also died and was buried.", 
                "num": 22
              }, 
              {
                "text": "And being in torments in Hades, he lifted up his eyes and saw Abraham afar off, and Lazarus in his bosom.", 
                "num": 23
              }, 
              {
                "text": "\"Then he cried and said, \"Father Abraham, have mercy on me, and send Lazarus that he may dip the tip of his finger in water and cool my tongue; for I am tormented in this flame.'", 
                "num": 24
              }, 
              {
                "text": "But Abraham said, \"Son, remember that in your lifetime you received your good things, and likewise Lazarus evil things; but now he is comforted and you are tormented.", 
                "num": 25
              }, 
              {
                "text": "And besides all this, between us and you there is a great gulf fixed, so that those who want to pass from here to you cannot, nor can those from there pass to us.'", 
                "num": 26
              }, 
              {
                "text": "\"Then he said, \"I beg you therefore, father, that you would send him to my father's house,", 
                "num": 27
              }, 
              {
                "text": "for I have five brothers, that he may testify to them, lest they also come to this place of torment.'", 
                "num": 28
              }, 
              {
                "text": "Abraham said to him, \"They have Moses and the prophets; let them hear them.'", 
                "num": 29
              }, 
              {
                "text": "And he said, \"No, father Abraham; but if one goes to them from the dead, they will repent.'", 
                "num": 30
              }, 
              {
                "text": "But he said to him, \"If they do not hear Moses and the prophets, neither will they be persuaded though one rise from the dead.\"'", 
                "num": 31
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Then He said to the disciples, \"It is impossible that no offenses should come, but woe to him through whom they do come!", 
                "num": 1
              }, 
              {
                "text": "It would be better for him if a millstone were hung around his neck, and he were thrown into the sea, than that he should offend one of these little ones.", 
                "num": 2
              }, 
              {
                "text": "Take heed to yourselves. If your brother sins against you, rebuke him; and if he repents, forgive him.", 
                "num": 3
              }, 
              {
                "text": "And if he sins against you seven times in a day, and seven times in a day returns to you, saying, \"I repent,' you shall forgive him.\"", 
                "num": 4
              }, 
              {
                "text": "And the apostles said to the Lord, \"Increase our faith.\"", 
                "num": 5
              }, 
              {
                "text": "So the Lord said, \"If you have faith as a mustard seed, you can say to this mulberry tree, \"Be pulled up by the roots and be planted in the sea,' and it would obey you.", 
                "num": 6
              }, 
              {
                "text": "And which of you, having a servant plowing or tending sheep, will say to him when he has come in from the field, \"Come at once and sit down to eat'?", 
                "num": 7
              }, 
              {
                "text": "But will he not rather say to him, \"Prepare something for my supper, and gird yourself and serve me till I have eaten and drunk, and afterward you will eat and drink'?", 
                "num": 8
              }, 
              {
                "text": "Does he thank that servant because he did the things that were commanded him? I think not.", 
                "num": 9
              }, 
              {
                "text": "So likewise you, when you have done all those things which you are commanded, say, \"We are unprofitable servants. We have done what was our duty to do.\"'", 
                "num": 10
              }, 
              {
                "text": "Now it happened as He went to Jerusalem that He passed through the midst of Samaria and Galilee.", 
                "num": 11
              }, 
              {
                "text": "Then as He entered a certain village, there met Him ten men who were lepers, who stood afar off.", 
                "num": 12
              }, 
              {
                "text": "And they lifted up their voices and said, \"Jesus, Master, have mercy on us!\"", 
                "num": 13
              }, 
              {
                "text": "So when He saw them, He said to them, \"Go, show yourselves to the priests.\" And so it was that as they went, they were cleansed.", 
                "num": 14
              }, 
              {
                "text": "And one of them, when he saw that he was healed, returned, and with a loud voice glorified God,", 
                "num": 15
              }, 
              {
                "text": "and fell down on his face at His feet, giving Him thanks. And he was a Samaritan.", 
                "num": 16
              }, 
              {
                "text": "So Jesus answered and said, \"Were there not ten cleansed? But where are the nine?", 
                "num": 17
              }, 
              {
                "text": "Were there not any found who returned to give glory to God except this foreigner?\"", 
                "num": 18
              }, 
              {
                "text": "And He said to him, \"Arise, go your way. Your faith has made you well.\"", 
                "num": 19
              }, 
              {
                "text": "Now when He was asked by the Pharisees when the kingdom of God would come, He answered them and said, \"The kingdom of God does not come with observation;", 
                "num": 20
              }, 
              {
                "text": "nor will they say, \"See here!' or \"See there!' For indeed, the kingdom of God is within you.\"", 
                "num": 21
              }, 
              {
                "text": "Then He said to the disciples, \"The days will come when you will desire to see one of the days of the Son of Man, and you will not see it.", 
                "num": 22
              }, 
              {
                "text": "And they will say to you, \"Look here!' or \"Look there!' Do not go after them or follow them.", 
                "num": 23
              }, 
              {
                "text": "For as the lightning that flashes out of one part under heaven shines to the other part under heaven, so also the Son of Man will be in His day.", 
                "num": 24
              }, 
              {
                "text": "But first He must suffer many things and be rejected by this generation.", 
                "num": 25
              }, 
              {
                "text": "And as it was in the days of Noah, so it will be also in the days of the Son of Man:", 
                "num": 26
              }, 
              {
                "text": "They ate, they drank, they married wives, they were given in marriage, until the day that Noah entered the ark, and the flood came and destroyed them all.", 
                "num": 27
              }, 
              {
                "text": "Likewise as it was also in the days of Lot: They ate, they drank, they bought, they sold, they planted, they built;", 
                "num": 28
              }, 
              {
                "text": "but on the day that Lot went out of Sodom it rained fire and brimstone from heaven and destroyed them all.", 
                "num": 29
              }, 
              {
                "text": "Even so will it be in the day when the Son of Man is revealed.", 
                "num": 30
              }, 
              {
                "text": "\"In that day, he who is on the housetop, and his goods are in the house, let him not come down to take them away. And likewise the one who is in the field, let him not turn back.", 
                "num": 31
              }, 
              {
                "text": "Remember Lot's wife.", 
                "num": 32
              }, 
              {
                "text": "Whoever seeks to save his life will lose it, and whoever loses his life will preserve it.", 
                "num": 33
              }, 
              {
                "text": "I tell you, in that night there will be two men in one bed: the one will be taken and the other will be left.", 
                "num": 34
              }, 
              {
                "text": "Two women will be grinding together: the one will be taken and the other left.", 
                "num": 35
              }, 
              {
                "text": "Two men will be in the field: the one will be taken and the other left.\"", 
                "num": 36
              }, 
              {
                "text": "And they answered and said to Him, \"Where, Lord?\" So He said to them, \"Wherever the body is, there the eagles will be gathered together.\"", 
                "num": 37
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "Then He spoke a parable to them, that men always ought to pray and not lose heart,", 
                "num": 1
              }, 
              {
                "text": "saying: \"There was in a certain city a judge who did not fear God nor regard man.", 
                "num": 2
              }, 
              {
                "text": "Now there was a widow in that city; and she came to him, saying, \"Get justice for me from my adversary.'", 
                "num": 3
              }, 
              {
                "text": "And he would not for a while; but afterward he said within himself, \"Though I do not fear God nor regard man,", 
                "num": 4
              }, 
              {
                "text": "yet because this widow troubles me I will avenge her, lest by her continual coming she weary me.\"'", 
                "num": 5
              }, 
              {
                "text": "Then the Lord said, \"Hear what the unjust judge said.", 
                "num": 6
              }, 
              {
                "text": "And shall God not avenge His own elect who cry out day and night to Him, though He bears long with them?", 
                "num": 7
              }, 
              {
                "text": "I tell you that He will avenge them speedily. Nevertheless, when the Son of Man comes, will He really find faith on the earth?\"", 
                "num": 8
              }, 
              {
                "text": "Also He spoke this parable to some who trusted in themselves that they were righteous, and despised others:", 
                "num": 9
              }, 
              {
                "text": "\"Two men went up to the temple to pray, one a Pharisee and the other a tax collector.", 
                "num": 10
              }, 
              {
                "text": "The Pharisee stood and prayed thus with himself, \"God, I thank You that I am not like other men--extortioners, unjust, adulterers, or even as this tax collector.", 
                "num": 11
              }, 
              {
                "text": "I fast twice a week; I give tithes of all that I possess.'", 
                "num": 12
              }, 
              {
                "text": "And the tax collector, standing afar off, would not so much as raise his eyes to heaven, but beat his breast, saying, \"God, be merciful to me a sinner!'", 
                "num": 13
              }, 
              {
                "text": "I tell you, this man went down to his house justified rather than the other; for everyone who exalts himself will be humbled, and he who humbles himself will be exalted.\"", 
                "num": 14
              }, 
              {
                "text": "Then they also brought infants to Him that He might touch them; but when the disciples saw it, they rebuked them.", 
                "num": 15
              }, 
              {
                "text": "But Jesus called them to Him and said, \"Let the little children come to Me, and do not forbid them; for of such is the kingdom of God.", 
                "num": 16
              }, 
              {
                "text": "Assuredly, I say to you, whoever does not receive the kingdom of God as a little child will by no means enter it.\"", 
                "num": 17
              }, 
              {
                "text": "Now a certain ruler asked Him, saying, \"Good Teacher, what shall I do to inherit eternal life?\"", 
                "num": 18
              }, 
              {
                "text": "So Jesus said to him, \"Why do you call Me good? No one is good but One, that is, God.", 
                "num": 19
              }, 
              {
                "text": "You know the commandments: \"Do not commit adultery,' \"Do not murder,' \"Do not steal,' \"Do not bear false witness,' \"Honor your father and your mother.\"'", 
                "num": 20
              }, 
              {
                "text": "And he said, \"All these things I have kept from my youth.\"", 
                "num": 21
              }, 
              {
                "text": "So when Jesus heard these things, He said to him, \"You still lack one thing. Sell all that you have and distribute to the poor, and you will have treasure in heaven; and come, follow Me.\"", 
                "num": 22
              }, 
              {
                "text": "But when he heard this, he became very sorrowful, for he was very rich.", 
                "num": 23
              }, 
              {
                "text": "And when Jesus saw that he became very sorrowful, He said, \"How hard it is for those who have riches to enter the kingdom of God!", 
                "num": 24
              }, 
              {
                "text": "For it is easier for a camel to go through the eye of a needle than for a rich man to enter the kingdom of God.\"", 
                "num": 25
              }, 
              {
                "text": "And those who heard it said, \"Who then can be saved?\"", 
                "num": 26
              }, 
              {
                "text": "But He said, \"The things which are impossible with men are possible with God.\"", 
                "num": 27
              }, 
              {
                "text": "Then Peter said, \"See, we have left all and followed You.\"", 
                "num": 28
              }, 
              {
                "text": "So He said to them, \"Assuredly, I say to you, there is no one who has left house or parents or brothers or wife or children, for the sake of the kingdom of God,", 
                "num": 29
              }, 
              {
                "text": "who shall not receive many times more in this present time, and in the age to come eternal life.\"", 
                "num": 30
              }, 
              {
                "text": "Then He took the twelve aside and said to them, \"Behold, we are going up to Jerusalem, and all things that are written by the prophets concerning the Son of Man will be accomplished.", 
                "num": 31
              }, 
              {
                "text": "For He will be delivered to the Gentiles and will be mocked and insulted and spit upon.", 
                "num": 32
              }, 
              {
                "text": "They will scourge Him and kill Him. And the third day He will rise again.\"", 
                "num": 33
              }, 
              {
                "text": "But they understood none of these things; this saying was hidden from them, and they did not know the things which were spoken.", 
                "num": 34
              }, 
              {
                "text": "Then it happened, as He was coming near Jericho, that a certain blind man sat by the road begging.", 
                "num": 35
              }, 
              {
                "text": "And hearing a multitude passing by, he asked what it meant.", 
                "num": 36
              }, 
              {
                "text": "So they told him that Jesus of Nazareth was passing by.", 
                "num": 37
              }, 
              {
                "text": "And he cried out, saying, \"Jesus, Son of David, have mercy on me!\"", 
                "num": 38
              }, 
              {
                "text": "Then those who went before warned him that he should be quiet; but he cried out all the more, \"Son of David, have mercy on me!\"", 
                "num": 39
              }, 
              {
                "text": "So Jesus stood still and commanded him to be brought to Him. And when he had come near, He asked him,", 
                "num": 40
              }, 
              {
                "text": "saying, \"What do you want Me to do for you?\" He said, \"Lord, that I may receive my sight.\"", 
                "num": 41
              }, 
              {
                "text": "Then Jesus said to him, \"Receive your sight; your faith has made you well.\"", 
                "num": 42
              }, 
              {
                "text": "And immediately he received his sight, and followed Him, glorifying God. And all the people, when they saw it, gave praise to God.", 
                "num": 43
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "Then Jesus entered and passed through Jericho.", 
                "num": 1
              }, 
              {
                "text": "Now behold, there was a man named Zacchaeus who was a chief tax collector, and he was rich.", 
                "num": 2
              }, 
              {
                "text": "And he sought to see who Jesus was, but could not because of the crowd, for he was of short stature.", 
                "num": 3
              }, 
              {
                "text": "So he ran ahead and climbed up into a sycamore tree to see Him, for He was going to pass that way.", 
                "num": 4
              }, 
              {
                "text": "And when Jesus came to the place, He looked up and saw him, and said to him, \"Zacchaeus, make haste and come down, for today I must stay at your house.\"", 
                "num": 5
              }, 
              {
                "text": "So he made haste and came down, and received Him joyfully.", 
                "num": 6
              }, 
              {
                "text": "But when they saw it, they all complained, saying, \"He has gone to be a guest with a man who is a sinner.\"", 
                "num": 7
              }, 
              {
                "text": "Then Zacchaeus stood and said to the Lord, \"Look, Lord, I give half of my goods to the poor; and if I have taken anything from anyone by false accusation, I restore fourfold.\"", 
                "num": 8
              }, 
              {
                "text": "And Jesus said to him, \"Today salvation has come to this house, because he also is a son of Abraham;", 
                "num": 9
              }, 
              {
                "text": "for the Son of Man has come to seek and to save that which was lost.\"", 
                "num": 10
              }, 
              {
                "text": "Now as they heard these things, He spoke another parable, because He was near Jerusalem and because they thought the kingdom of God would appear immediately.", 
                "num": 11
              }, 
              {
                "text": "Therefore He said: \"A certain nobleman went into a far country to receive for himself a kingdom and to return.", 
                "num": 12
              }, 
              {
                "text": "So he called ten of his servants, delivered to them ten minas, and said to them, \"Do business till I come.'", 
                "num": 13
              }, 
              {
                "text": "But his citizens hated him, and sent a delegation after him, saying, \"We will not have this man to reign over us.'", 
                "num": 14
              }, 
              {
                "text": "\"And so it was that when he returned, having received the kingdom, he then commanded these servants, to whom he had given the money, to be called to him, that he might know how much every man had gained by trading.", 
                "num": 15
              }, 
              {
                "text": "Then came the first, saying, \"Master, your mina has earned ten minas.'", 
                "num": 16
              }, 
              {
                "text": "And he said to him, \"Well done, good servant; because you were faithful in a very little, have authority over ten cities.'", 
                "num": 17
              }, 
              {
                "text": "And the second came, saying, \"Master, your mina has earned five minas.'", 
                "num": 18
              }, 
              {
                "text": "Likewise he said to him, \"You also be over five cities.'", 
                "num": 19
              }, 
              {
                "text": "\"Then another came, saying, \"Master, here is your mina, which I have kept put away in a handkerchief.", 
                "num": 20
              }, 
              {
                "text": "For I feared you, because you are an austere man. You collect what you did not deposit, and reap what you did not sow.'", 
                "num": 21
              }, 
              {
                "text": "And he said to him, \"Out of your own mouth I will judge you, you wicked servant. You knew that I was an austere man, collecting what I did not deposit and reaping what I did not sow.", 
                "num": 22
              }, 
              {
                "text": "Why then did you not put my money in the bank, that at my coming I might have collected it with interest?'", 
                "num": 23
              }, 
              {
                "text": "\"And he said to those who stood by, \"Take the mina from him, and give it to him who has ten minas.'", 
                "num": 24
              }, 
              {
                "text": "(But they said to him, \"Master, he has ten minas.')", 
                "num": 25
              }, 
              {
                "text": "\"For I say to you, that to everyone who has will be given; and from him who does not have, even what he has will be taken away from him.", 
                "num": 26
              }, 
              {
                "text": "But bring here those enemies of mine, who did not want me to reign over them, and slay them before me.\"'", 
                "num": 27
              }, 
              {
                "text": "When He had said this, He went on ahead, going up to Jerusalem.", 
                "num": 28
              }, 
              {
                "text": "And it came to pass, when He drew near to Bethphage and Bethany, at the mountain called Olivet, that He sent two of His disciples,", 
                "num": 29
              }, 
              {
                "text": "saying, \"Go into the village opposite you, where as you enter you will find a colt tied, on which no one has ever sat. Loose it and bring it here.", 
                "num": 30
              }, 
              {
                "text": "And if anyone asks you, \"Why are you loosing it?' thus you shall say to him, \"Because the Lord has need of it.\"'", 
                "num": 31
              }, 
              {
                "text": "So those who were sent went their way and found it just as He had said to them.", 
                "num": 32
              }, 
              {
                "text": "But as they were loosing the colt, the owners of it said to them, \"Why are you loosing the colt?\"", 
                "num": 33
              }, 
              {
                "text": "And they said, \"The Lord has need of him.\"", 
                "num": 34
              }, 
              {
                "text": "Then they brought him to Jesus. And they threw their own clothes on the colt, and they set Jesus on him.", 
                "num": 35
              }, 
              {
                "text": "And as He went, many spread their clothes on the road.", 
                "num": 36
              }, 
              {
                "text": "Then, as He was now drawing near the descent of the Mount of Olives, the whole multitude of the disciples began to rejoice and praise God with a loud voice for all the mighty works they had seen,", 
                "num": 37
              }, 
              {
                "text": "saying: \"\"Blessed is the King who comes in the name of the LORD!' Peace in heaven and glory in the highest!\"", 
                "num": 38
              }, 
              {
                "text": "And some of the Pharisees called to Him from the crowd, \"Teacher, rebuke Your disciples.\"", 
                "num": 39
              }, 
              {
                "text": "But He answered and said to them, \"I tell you that if these should keep silent, the stones would immediately cry out.\"", 
                "num": 40
              }, 
              {
                "text": "Now as He drew near, He saw the city and wept over it,", 
                "num": 41
              }, 
              {
                "text": "saying, \"If you had known, even you, especially in this your day, the things that make for your peace! But now they are hidden from your eyes.", 
                "num": 42
              }, 
              {
                "text": "For days will come upon you when your enemies will build an embankment around you, surround you and close you in on every side,", 
                "num": 43
              }, 
              {
                "text": "and level you, and your children within you, to the ground; and they will not leave in you one stone upon another, because you did not know the time of your visitation.\"", 
                "num": 44
              }, 
              {
                "text": "Then He went into the temple and began to drive out those who bought and sold in it,", 
                "num": 45
              }, 
              {
                "text": "saying to them, \"It is written, \"My house is a house of prayer,' but you have made it a \"den of thieves.\"'", 
                "num": 46
              }, 
              {
                "text": "And He was teaching daily in the temple. But the chief priests, the scribes, and the leaders of the people sought to destroy Him,", 
                "num": 47
              }, 
              {
                "text": "and were unable to do anything; for all the people were very attentive to hear Him.", 
                "num": 48
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Now it happened on one of those days, as He taught the people in the temple and preached the gospel, that the chief priests and the scribes, together with the elders, confronted Him", 
                "num": 1
              }, 
              {
                "text": "and spoke to Him, saying, \"Tell us, by what authority are You doing these things? Or who is he who gave You this authority?\"", 
                "num": 2
              }, 
              {
                "text": "But He answered and said to them, \"I also will ask you one thing, and answer Me:", 
                "num": 3
              }, 
              {
                "text": "The baptism of John--was it from heaven or from men?\"", 
                "num": 4
              }, 
              {
                "text": "And they reasoned among themselves, saying, \"If we say, \"From heaven,' He will say, \"Why then did you not believe him?'", 
                "num": 5
              }, 
              {
                "text": "But if we say, \"From men,' all the people will stone us, for they are persuaded that John was a prophet.\"", 
                "num": 6
              }, 
              {
                "text": "So they answered that they did not know where it was from.", 
                "num": 7
              }, 
              {
                "text": "And Jesus said to them, \"Neither will I tell you by what authority I do these things.\"", 
                "num": 8
              }, 
              {
                "text": "Then He began to tell the people this parable: \"A certain man planted a vineyard, leased it to vinedressers, and went into a far country for a long time.", 
                "num": 9
              }, 
              {
                "text": "Now at vintage-time he sent a servant to the vinedressers, that they might give him some of the fruit of the vineyard. But the vinedressers beat him and sent him away empty-handed.", 
                "num": 10
              }, 
              {
                "text": "Again he sent another servant; and they beat him also, treated him shamefully, and sent him away empty-handed.", 
                "num": 11
              }, 
              {
                "text": "And again he sent a third; and they wounded him also and cast him out.", 
                "num": 12
              }, 
              {
                "text": "\"Then the owner of the vineyard said, \"What shall I do? I will send my beloved son. Probably they will respect him when they see him.'", 
                "num": 13
              }, 
              {
                "text": "But when the vinedressers saw him, they reasoned among themselves, saying, \"This is the heir. Come, let us kill him, that the inheritance may be ours.'", 
                "num": 14
              }, 
              {
                "text": "So they cast him out of the vineyard and killed him. Therefore what will the owner of the vineyard do to them?", 
                "num": 15
              }, 
              {
                "text": "He will come and destroy those vinedressers and give the vineyard to others.\" And when they heard it they said, \"Certainly not!\"", 
                "num": 16
              }, 
              {
                "text": "Then He looked at them and said, \"What then is this that is written: \"The stone which the builders rejected Has become the chief cornerstone'?", 
                "num": 17
              }, 
              {
                "text": "Whoever falls on that stone will be broken; but on whomever it falls, it will grind him to powder.\"", 
                "num": 18
              }, 
              {
                "text": "And the chief priests and the scribes that very hour sought to lay hands on Him, but they feared the people--for they knew He had spoken this parable against them.", 
                "num": 19
              }, 
              {
                "text": "So they watched Him, and sent spies who pretended to be righteous, that they might seize on His words, in order to deliver Him to the power and the authority of the governor.", 
                "num": 20
              }, 
              {
                "text": "Then they asked Him, saying, \"Teacher, we know that You say and teach rightly, and You do not show personal favoritism, but teach the way of God in truth:", 
                "num": 21
              }, 
              {
                "text": "Is it lawful for us to pay taxes to Caesar or not?\"", 
                "num": 22
              }, 
              {
                "text": "But He perceived their craftiness, and said to them, \"Why do you test Me?", 
                "num": 23
              }, 
              {
                "text": "Show Me a denarius. Whose image and inscription does it have?\" They answered and said, \"Caesar's.\"", 
                "num": 24
              }, 
              {
                "text": "And He said to them, \"Render therefore to Caesar the things that are Caesar's, and to God the things that are God's.\"", 
                "num": 25
              }, 
              {
                "text": "But they could not catch Him in His words in the presence of the people. And they marveled at His answer and kept silent.", 
                "num": 26
              }, 
              {
                "text": "Then some of the Sadducees, who deny that there is a resurrection, came to Him and asked Him,", 
                "num": 27
              }, 
              {
                "text": "saying: \"Teacher, Moses wrote to us that if a man's brother dies, having a wife, and he dies without children, his brother should take his wife and raise up offspring for his brother.", 
                "num": 28
              }, 
              {
                "text": "Now there were seven brothers. And the first took a wife, and died without children.", 
                "num": 29
              }, 
              {
                "text": "And the second took her as wife, and he died childless.", 
                "num": 30
              }, 
              {
                "text": "Then the third took her, and in like manner the seven also; and they left no children, and died.", 
                "num": 31
              }, 
              {
                "text": "Last of all the woman died also.", 
                "num": 32
              }, 
              {
                "text": "Therefore, in the resurrection, whose wife does she become? For all seven had her as wife.\"", 
                "num": 33
              }, 
              {
                "text": "Jesus answered and said to them, \"The sons of this age marry and are given in marriage.", 
                "num": 34
              }, 
              {
                "text": "But those who are counted worthy to attain that age, and the resurrection from the dead, neither marry nor are given in marriage;", 
                "num": 35
              }, 
              {
                "text": "nor can they die anymore, for they are equal to the angels and are sons of God, being sons of the resurrection.", 
                "num": 36
              }, 
              {
                "text": "But even Moses showed in the burning bush passage that the dead are raised, when he called the Lord \"the God of Abraham, the God of Isaac, and the God of Jacob.'", 
                "num": 37
              }, 
              {
                "text": "For He is not the God of the dead but of the living, for all live to Him.\"", 
                "num": 38
              }, 
              {
                "text": "Then some of the scribes answered and said, \"Teacher, You have spoken well.\"", 
                "num": 39
              }, 
              {
                "text": "But after that they dared not question Him anymore.", 
                "num": 40
              }, 
              {
                "text": "And He said to them, \"How can they say that the Christ is the Son of David?", 
                "num": 41
              }, 
              {
                "text": "Now David himself said in the Book of Psalms: \"The LORD said to my Lord, \"Sit at My right hand,", 
                "num": 42
              }, 
              {
                "text": "Till I make Your enemies Your footstool.\"'", 
                "num": 43
              }, 
              {
                "text": "Therefore David calls Him \"Lord'; how is He then his Son?\"", 
                "num": 44
              }, 
              {
                "text": "Then, in the hearing of all the people, He said to His disciples,", 
                "num": 45
              }, 
              {
                "text": "\"Beware of the scribes, who desire to go around in long robes, love greetings in the marketplaces, the best seats in the synagogues, and the best places at feasts,", 
                "num": 46
              }, 
              {
                "text": "who devour widows' houses, and for a pretense make long prayers. These will receive greater condemnation.\"", 
                "num": 47
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "And He looked up and saw the rich putting their gifts into the treasury,", 
                "num": 1
              }, 
              {
                "text": "and He saw also a certain poor widow putting in two mites.", 
                "num": 2
              }, 
              {
                "text": "So He said, \"Truly I say to you that this poor widow has put in more than all;", 
                "num": 3
              }, 
              {
                "text": "for all these out of their abundance have put in offerings for God, but she out of her poverty put in all the livelihood that she had.\"", 
                "num": 4
              }, 
              {
                "text": "Then, as some spoke of the temple, how it was adorned with beautiful stones and donations, He said,", 
                "num": 5
              }, 
              {
                "text": "\"These things which you see--the days will come in which not one stone shall be left upon another that shall not be thrown down.\"", 
                "num": 6
              }, 
              {
                "text": "So they asked Him, saying, \"Teacher, but when will these things be? And what sign will there be when these things are about to take place?\"", 
                "num": 7
              }, 
              {
                "text": "And He said: \"Take heed that you not be deceived. For many will come in My name, saying, \"I am He,' and, \"The time has drawn near.' Therefore do not go after them.", 
                "num": 8
              }, 
              {
                "text": "But when you hear of wars and commotions, do not be terrified; for these things must come to pass first, but the end will not come immediately.\"", 
                "num": 9
              }, 
              {
                "text": "Then He said to them, \"Nation will rise against nation, and kingdom against kingdom.", 
                "num": 10
              }, 
              {
                "text": "And there will be great earthquakes in various places, and famines and pestilences; and there will be fearful sights and great signs from heaven.", 
                "num": 11
              }, 
              {
                "text": "But before all these things, they will lay their hands on you and persecute you, delivering you up to the synagogues and prisons. You will be brought before kings and rulers for My name's sake.", 
                "num": 12
              }, 
              {
                "text": "But it will turn out for you as an occasion for testimony.", 
                "num": 13
              }, 
              {
                "text": "Therefore settle it in your hearts not to meditate beforehand on what you will answer;", 
                "num": 14
              }, 
              {
                "text": "for I will give you a mouth and wisdom which all your adversaries will not be able to contradict or resist.", 
                "num": 15
              }, 
              {
                "text": "You will be betrayed even by parents and brothers, relatives and friends; and they will put some of you to death.", 
                "num": 16
              }, 
              {
                "text": "And you will be hated by all for My name's sake.", 
                "num": 17
              }, 
              {
                "text": "But not a hair of your head shall be lost.", 
                "num": 18
              }, 
              {
                "text": "By your patience possess your souls.", 
                "num": 19
              }, 
              {
                "text": "\"But when you see Jerusalem surrounded by armies, then know that its desolation is near.", 
                "num": 20
              }, 
              {
                "text": "Then let those who are in Judea flee to the mountains, let those who are in the midst of her depart, and let not those who are in the country enter her.", 
                "num": 21
              }, 
              {
                "text": "For these are the days of vengeance, that all things which are written may be fulfilled.", 
                "num": 22
              }, 
              {
                "text": "But woe to those who are pregnant and to those who are nursing babies in those days! For there will be great distress in the land and wrath upon this people.", 
                "num": 23
              }, 
              {
                "text": "And they will fall by the edge of the sword, and be led away captive into all nations. And Jerusalem will be trampled by Gentiles until the times of the Gentiles are fulfilled.", 
                "num": 24
              }, 
              {
                "text": "\"And there will be signs in the sun, in the moon, and in the stars; and on the earth distress of nations, with perplexity, the sea and the waves roaring;", 
                "num": 25
              }, 
              {
                "text": "men's hearts failing them from fear and the expectation of those things which are coming on the earth, for the powers of the heavens will be shaken.", 
                "num": 26
              }, 
              {
                "text": "Then they will see the Son of Man coming in a cloud with power and great glory.", 
                "num": 27
              }, 
              {
                "text": "Now when these things begin to happen, look up and lift up your heads, because your redemption draws near.\"", 
                "num": 28
              }, 
              {
                "text": "Then He spoke to them a parable: \"Look at the fig tree, and all the trees.", 
                "num": 29
              }, 
              {
                "text": "When they are already budding, you see and know for yourselves that summer is now near.", 
                "num": 30
              }, 
              {
                "text": "So you also, when you see these things happening, know that the kingdom of God is near.", 
                "num": 31
              }, 
              {
                "text": "Assuredly, I say to you, this generation will by no means pass away till all things take place.", 
                "num": 32
              }, 
              {
                "text": "Heaven and earth will pass away, but My words will by no means pass away.", 
                "num": 33
              }, 
              {
                "text": "\"But take heed to yourselves, lest your hearts be weighed down with carousing, drunkenness, and cares of this life, and that Day come on you unexpectedly.", 
                "num": 34
              }, 
              {
                "text": "For it will come as a snare on all those who dwell on the face of the whole earth.", 
                "num": 35
              }, 
              {
                "text": "Watch therefore, and pray always that you may be counted worthy to escape all these things that will come to pass, and to stand before the Son of Man.\"", 
                "num": 36
              }, 
              {
                "text": "And in the daytime He was teaching in the temple, but at night He went out and stayed on the mountain called Olivet.", 
                "num": 37
              }, 
              {
                "text": "Then early in the morning all the people came to Him in the temple to hear Him.", 
                "num": 38
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "Now the Feast of Unleavened Bread drew near, which is called Passover.", 
                "num": 1
              }, 
              {
                "text": "And the chief priests and the scribes sought how they might kill Him, for they feared the people.", 
                "num": 2
              }, 
              {
                "text": "Then Satan entered Judas, surnamed Iscariot, who was numbered among the twelve.", 
                "num": 3
              }, 
              {
                "text": "So he went his way and conferred with the chief priests and captains, how he might betray Him to them.", 
                "num": 4
              }, 
              {
                "text": "And they were glad, and agreed to give him money.", 
                "num": 5
              }, 
              {
                "text": "So he promised and sought opportunity to betray Him to them in the absence of the multitude.", 
                "num": 6
              }, 
              {
                "text": "Then came the Day of Unleavened Bread, when the Passover must be killed.", 
                "num": 7
              }, 
              {
                "text": "And He sent Peter and John, saying, \"Go and prepare the Passover for us, that we may eat.\"", 
                "num": 8
              }, 
              {
                "text": "So they said to Him, \"Where do You want us to prepare?\"", 
                "num": 9
              }, 
              {
                "text": "And He said to them, \"Behold, when you have entered the city, a man will meet you carrying a pitcher of water; follow him into the house which he enters.", 
                "num": 10
              }, 
              {
                "text": "Then you shall say to the master of the house, \"The Teacher says to you, \"Where is the guest room where I may eat the Passover with My disciples?\"'", 
                "num": 11
              }, 
              {
                "text": "Then he will show you a large, furnished upper room; there make ready.\"", 
                "num": 12
              }, 
              {
                "text": "So they went and found it just as He had said to them, and they prepared the Passover.", 
                "num": 13
              }, 
              {
                "text": "When the hour had come, He sat down, and the twelve apostles with Him.", 
                "num": 14
              }, 
              {
                "text": "Then He said to them, \"With fervent desire I have desired to eat this Passover with you before I suffer;", 
                "num": 15
              }, 
              {
                "text": "for I say to you, I will no longer eat of it until it is fulfilled in the kingdom of God.\"", 
                "num": 16
              }, 
              {
                "text": "Then He took the cup, and gave thanks, and said, \"Take this and divide it among yourselves;", 
                "num": 17
              }, 
              {
                "text": "for I say to you, I will not drink of the fruit of the vine until the kingdom of God comes.\"", 
                "num": 18
              }, 
              {
                "text": "And He took bread, gave thanks and broke it, and gave it to them, saying, \"This is My body which is given for you; do this in remembrance of Me.\"", 
                "num": 19
              }, 
              {
                "text": "Likewise He also took the cup after supper, saying, \"This cup is the new covenant in My blood, which is shed for you.", 
                "num": 20
              }, 
              {
                "text": "But behold, the hand of My betrayer is with Me on the table.", 
                "num": 21
              }, 
              {
                "text": "And truly the Son of Man goes as it has been determined, but woe to that man by whom He is betrayed!\"", 
                "num": 22
              }, 
              {
                "text": "Then they began to question among themselves, which of them it was who would do this thing.", 
                "num": 23
              }, 
              {
                "text": "Now there was also a dispute among them, as to which of them should be considered the greatest.", 
                "num": 24
              }, 
              {
                "text": "And He said to them, \"The kings of the Gentiles exercise lordship over them, and those who exercise authority over them are called \"benefactors.'", 
                "num": 25
              }, 
              {
                "text": "But not so among you; on the contrary, he who is greatest among you, let him be as the younger, and he who governs as he who serves.", 
                "num": 26
              }, 
              {
                "text": "For who is greater, he who sits at the table, or he who serves? Is it not he who sits at the table? Yet I am among you as the One who serves.", 
                "num": 27
              }, 
              {
                "text": "\"But you are those who have continued with Me in My trials.", 
                "num": 28
              }, 
              {
                "text": "And I bestow upon you a kingdom, just as My Father bestowed one upon Me,", 
                "num": 29
              }, 
              {
                "text": "that you may eat and drink at My table in My kingdom, and sit on thrones judging the twelve tribes of Israel.\"", 
                "num": 30
              }, 
              {
                "text": "And the Lord said, \"Simon, Simon! Indeed, Satan has asked for you, that he may sift you as wheat.", 
                "num": 31
              }, 
              {
                "text": "But I have prayed for you, that your faith should not fail; and when you have returned to Me, strengthen your brethren.\"", 
                "num": 32
              }, 
              {
                "text": "But he said to Him, \"Lord, I am ready to go with You, both to prison and to death.\"", 
                "num": 33
              }, 
              {
                "text": "Then He said, \"I tell you, Peter, the rooster shall not crow this day before you will deny three times that you know Me.\"", 
                "num": 34
              }, 
              {
                "text": "And He said to them, \"When I sent you without money bag, knapsack, and sandals, did you lack anything?\" So they said, \"Nothing.\"", 
                "num": 35
              }, 
              {
                "text": "Then He said to them, \"But now, he who has a money bag, let him take it, and likewise a knapsack; and he who has no sword, let him sell his garment and buy one.", 
                "num": 36
              }, 
              {
                "text": "For I say to you that this which is written must still be accomplished in Me: \"And He was numbered with the transgressors.' For the things concerning Me have an end.\"", 
                "num": 37
              }, 
              {
                "text": "So they said, \"Lord, look, here are two swords.\" And He said to them, \"It is enough.\"", 
                "num": 38
              }, 
              {
                "text": "Coming out, He went to the Mount of Olives, as He was accustomed, and His disciples also followed Him.", 
                "num": 39
              }, 
              {
                "text": "When He came to the place, He said to them, \"Pray that you may not enter into temptation.\"", 
                "num": 40
              }, 
              {
                "text": "And He was withdrawn from them about a stone's throw, and He knelt down and prayed,", 
                "num": 41
              }, 
              {
                "text": "saying, \"Father, if it is Your will, take this cup away from Me; nevertheless not My will, but Yours, be done.\"", 
                "num": 42
              }, 
              {
                "text": "Then an angel appeared to Him from heaven, strengthening Him.", 
                "num": 43
              }, 
              {
                "text": "And being in agony, He prayed more earnestly. Then His sweat became like great drops of blood falling down to the ground.", 
                "num": 44
              }, 
              {
                "text": "When He rose up from prayer, and had come to His disciples, He found them sleeping from sorrow.", 
                "num": 45
              }, 
              {
                "text": "Then He said to them, \"Why do you sleep? Rise and pray, lest you enter into temptation.\"", 
                "num": 46
              }, 
              {
                "text": "And while He was still speaking, behold, a multitude; and he who was called Judas, one of the twelve, went before them and drew near to Jesus to kiss Him.", 
                "num": 47
              }, 
              {
                "text": "But Jesus said to him, \"Judas, are you betraying the Son of Man with a kiss?\"", 
                "num": 48
              }, 
              {
                "text": "When those around Him saw what was going to happen, they said to Him, \"Lord, shall we strike with the sword?\"", 
                "num": 49
              }, 
              {
                "text": "And one of them struck the servant of the high priest and cut off his right ear.", 
                "num": 50
              }, 
              {
                "text": "But Jesus answered and said, \"Permit even this.\" And He touched his ear and healed him.", 
                "num": 51
              }, 
              {
                "text": "Then Jesus said to the chief priests, captains of the temple, and the elders who had come to Him, \"Have you come out, as against a robber, with swords and clubs?", 
                "num": 52
              }, 
              {
                "text": "When I was with you daily in the temple, you did not try to seize Me. But this is your hour, and the power of darkness.\"", 
                "num": 53
              }, 
              {
                "text": "Having arrested Him, they led Him and brought Him into the high priest's house. But Peter followed at a distance.", 
                "num": 54
              }, 
              {
                "text": "Now when they had kindled a fire in the midst of the courtyard and sat down together, Peter sat among them.", 
                "num": 55
              }, 
              {
                "text": "And a certain servant girl, seeing him as he sat by the fire, looked intently at him and said, \"This man was also with Him.\"", 
                "num": 56
              }, 
              {
                "text": "But he denied Him, saying, \"Woman, I do not know Him.\"", 
                "num": 57
              }, 
              {
                "text": "And after a little while another saw him and said, \"You also are of them.\" But Peter said, \"Man, I am not!\"", 
                "num": 58
              }, 
              {
                "text": "Then after about an hour had passed, another confidently affirmed, saying, \"Surely this fellow also was with Him, for he is a Galilean.\"", 
                "num": 59
              }, 
              {
                "text": "But Peter said, \"Man, I do not know what you are saying!\" Immediately, while he was still speaking, the rooster crowed.", 
                "num": 60
              }, 
              {
                "text": "And the Lord turned and looked at Peter. Then Peter remembered the word of the Lord, how He had said to him, \"Before the rooster crows, you will deny Me three times.\"", 
                "num": 61
              }, 
              {
                "text": "So Peter went out and wept bitterly.", 
                "num": 62
              }, 
              {
                "text": "Now the men who held Jesus mocked Him and beat Him.", 
                "num": 63
              }, 
              {
                "text": "And having blindfolded Him, they struck Him on the face and asked Him, saying, \"Prophesy! Who is the one who struck You?\"", 
                "num": 64
              }, 
              {
                "text": "And many other things they blasphemously spoke against Him.", 
                "num": 65
              }, 
              {
                "text": "As soon as it was day, the elders of the people, both chief priests and scribes, came together and led Him into their council, saying,", 
                "num": 66
              }, 
              {
                "text": "\"If You are the Christ, tell us.\" But He said to them, \"If I tell you, you will by no means believe.", 
                "num": 67
              }, 
              {
                "text": "And if I also ask you, you will by no means answer Me or let Me go.", 
                "num": 68
              }, 
              {
                "text": "Hereafter the Son of Man will sit on the right hand of the power of God.\"", 
                "num": 69
              }, 
              {
                "text": "Then they all said, \"Are You then the Son of God?\" So He said to them, \"You rightly say that I am.\"", 
                "num": 70
              }, 
              {
                "text": "And they said, \"What further testimony do we need? For we have heard it ourselves from His own mouth.\"", 
                "num": 71
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then the whole multitude of them arose and led Him to Pilate.", 
                "num": 1
              }, 
              {
                "text": "And they began to accuse Him, saying, \"We found this fellow perverting the nation, and forbidding to pay taxes to Caesar, saying that He Himself is Christ, a King.\"", 
                "num": 2
              }, 
              {
                "text": "Then Pilate asked Him, saying, \"Are You the King of the Jews?\" He answered him and said, \"It is as you say.\"", 
                "num": 3
              }, 
              {
                "text": "So Pilate said to the chief priests and the crowd, \"I find no fault in this Man.\"", 
                "num": 4
              }, 
              {
                "text": "But they were the more fierce, saying, \"He stirs up the people, teaching throughout all Judea, beginning from Galilee to this place.\"", 
                "num": 5
              }, 
              {
                "text": "When Pilate heard of Galilee, he asked if the Man were a Galilean.", 
                "num": 6
              }, 
              {
                "text": "And as soon as he knew that He belonged to Herod's jurisdiction, he sent Him to Herod, who was also in Jerusalem at that time.", 
                "num": 7
              }, 
              {
                "text": "Now when Herod saw Jesus, he was exceedingly glad; for he had desired for a long time to see Him, because he had heard many things about Him, and he hoped to see some miracle done by Him.", 
                "num": 8
              }, 
              {
                "text": "Then he questioned Him with many words, but He answered him nothing.", 
                "num": 9
              }, 
              {
                "text": "And the chief priests and scribes stood and vehemently accused Him.", 
                "num": 10
              }, 
              {
                "text": "Then Herod, with his men of war, treated Him with contempt and mocked Him, arrayed Him in a gorgeous robe, and sent Him back to Pilate.", 
                "num": 11
              }, 
              {
                "text": "That very day Pilate and Herod became friends with each other, for previously they had been at enmity with each other.", 
                "num": 12
              }, 
              {
                "text": "Then Pilate, when he had called together the chief priests, the rulers, and the people,", 
                "num": 13
              }, 
              {
                "text": "said to them, \"You have brought this Man to me, as one who misleads the people. And indeed, having examined Him in your presence, I have found no fault in this Man concerning those things of which you accuse Him;", 
                "num": 14
              }, 
              {
                "text": "no, neither did Herod, for I sent you back to him; and indeed nothing deserving of death has been done by Him.", 
                "num": 15
              }, 
              {
                "text": "I will therefore chastise Him and release Him\"", 
                "num": 16
              }, 
              {
                "text": "(for it was necessary for him to release one to them at the feast).", 
                "num": 17
              }, 
              {
                "text": "And they all cried out at once, saying, \"Away with this Man, and release to us Barabbas\"--", 
                "num": 18
              }, 
              {
                "text": "who had been thrown into prison for a certain rebellion made in the city, and for murder.", 
                "num": 19
              }, 
              {
                "text": "Pilate, therefore, wishing to release Jesus, again called out to them.", 
                "num": 20
              }, 
              {
                "text": "But they shouted, saying, \"Crucify Him, crucify Him!\"", 
                "num": 21
              }, 
              {
                "text": "Then he said to them the third time, \"Why, what evil has He done? I have found no reason for death in Him. I will therefore chastise Him and let Him go.\"", 
                "num": 22
              }, 
              {
                "text": "But they were insistent, demanding with loud voices that He be crucified. And the voices of these men and of the chief priests prevailed.", 
                "num": 23
              }, 
              {
                "text": "So Pilate gave sentence that it should be as they requested.", 
                "num": 24
              }, 
              {
                "text": "And he released to them the one they requested, who for rebellion and murder had been thrown into prison; but he delivered Jesus to their will.", 
                "num": 25
              }, 
              {
                "text": "Now as they led Him away, they laid hold of a certain man, Simon a Cyrenian, who was coming from the country, and on him they laid the cross that he might bear it after Jesus.", 
                "num": 26
              }, 
              {
                "text": "And a great multitude of the people followed Him, and women who also mourned and lamented Him.", 
                "num": 27
              }, 
              {
                "text": "But Jesus, turning to them, said, \"Daughters of Jerusalem, do not weep for Me, but weep for yourselves and for your children.", 
                "num": 28
              }, 
              {
                "text": "For indeed the days are coming in which they will say, \"Blessed are the barren, wombs that never bore, and breasts which never nursed!'", 
                "num": 29
              }, 
              {
                "text": "Then they will begin \"to say to the mountains, \"Fall on us!\" and to the hills, \"Cover us!\"'", 
                "num": 30
              }, 
              {
                "text": "For if they do these things in the green wood, what will be done in the dry?\"", 
                "num": 31
              }, 
              {
                "text": "There were also two others, criminals, led with Him to be put to death.", 
                "num": 32
              }, 
              {
                "text": "And when they had come to the place called Calvary, there they crucified Him, and the criminals, one on the right hand and the other on the left.", 
                "num": 33
              }, 
              {
                "text": "Then Jesus said, \"Father, forgive them, for they do not know what they do.\" And they divided His garments and cast lots.", 
                "num": 34
              }, 
              {
                "text": "And the people stood looking on. But even the rulers with them sneered, saying, \"He saved others; let Him save Himself if He is the Christ, the chosen of God.\"", 
                "num": 35
              }, 
              {
                "text": "The soldiers also mocked Him, coming and offering Him sour wine,", 
                "num": 36
              }, 
              {
                "text": "and saying, \"If You are the King of the Jews, save Yourself.\"", 
                "num": 37
              }, 
              {
                "text": "And an inscription also was written over Him in letters of Greek, Latin, and Hebrew: THIS IS THE KING OF THE JEWS.", 
                "num": 38
              }, 
              {
                "text": "Then one of the criminals who were hanged blasphemed Him, saying, \"If You are the Christ, save Yourself and us.\"", 
                "num": 39
              }, 
              {
                "text": "But the other, answering, rebuked him, saying, \"Do you not even fear God, seeing you are under the same condemnation?", 
                "num": 40
              }, 
              {
                "text": "And we indeed justly, for we receive the due reward of our deeds; but this Man has done nothing wrong.\"", 
                "num": 41
              }, 
              {
                "text": "Then he said to Jesus, \"Lord, remember me when You come into Your kingdom.\"", 
                "num": 42
              }, 
              {
                "text": "And Jesus said to him, \"Assuredly, I say to you, today you will be with Me in Paradise.\"", 
                "num": 43
              }, 
              {
                "text": "Now it was about the sixth hour, and there was darkness over all the earth until the ninth hour.", 
                "num": 44
              }, 
              {
                "text": "Then the sun was darkened, and the veil of the temple was torn in two.", 
                "num": 45
              }, 
              {
                "text": "And when Jesus had cried out with a loud voice, He said, \"Father, \"into Your hands I commit My spirit.\"' Having said this, He breathed His last.", 
                "num": 46
              }, 
              {
                "text": "So when the centurion saw what had happened, he glorified God, saying, \"Certainly this was a righteous Man!\"", 
                "num": 47
              }, 
              {
                "text": "And the whole crowd who came together to that sight, seeing what had been done, beat their breasts and returned.", 
                "num": 48
              }, 
              {
                "text": "But all His acquaintances, and the women who followed Him from Galilee, stood at a distance, watching these things.", 
                "num": 49
              }, 
              {
                "text": "Now behold, there was a man named Joseph, a council member, a good and just man.", 
                "num": 50
              }, 
              {
                "text": "He had not consented to their decision and deed. He was from Arimathea, a city of the Jews, who himself was also waiting for the kingdom of God.", 
                "num": 51
              }, 
              {
                "text": "This man went to Pilate and asked for the body of Jesus.", 
                "num": 52
              }, 
              {
                "text": "Then he took it down, wrapped it in linen, and laid it in a tomb that was hewn out of the rock, where no one had ever lain before.", 
                "num": 53
              }, 
              {
                "text": "That day was the Preparation, and the Sabbath drew near.", 
                "num": 54
              }, 
              {
                "text": "And the women who had come with Him from Galilee followed after, and they observed the tomb and how His body was laid.", 
                "num": 55
              }, 
              {
                "text": "Then they returned and prepared spices and fragrant oils. And they rested on the Sabbath according to the commandment.", 
                "num": 56
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now on the first day of the week, very early in the morning, they, and certain other women with them, came to the tomb bringing the spices which they had prepared.", 
                "num": 1
              }, 
              {
                "text": "But they found the stone rolled away from the tomb.", 
                "num": 2
              }, 
              {
                "text": "Then they went in and did not find the body of the Lord Jesus.", 
                "num": 3
              }, 
              {
                "text": "And it happened, as they were greatly perplexed about this, that behold, two men stood by them in shining garments.", 
                "num": 4
              }, 
              {
                "text": "Then, as they were afraid and bowed their faces to the earth, they said to them, \"Why do you seek the living among the dead?", 
                "num": 5
              }, 
              {
                "text": "He is not here, but is risen! Remember how He spoke to you when He was still in Galilee,", 
                "num": 6
              }, 
              {
                "text": "saying, \"The Son of Man must be delivered into the hands of sinful men, and be crucified, and the third day rise again.\"'", 
                "num": 7
              }, 
              {
                "text": "And they remembered His words.", 
                "num": 8
              }, 
              {
                "text": "Then they returned from the tomb and told all these things to the eleven and to all the rest.", 
                "num": 9
              }, 
              {
                "text": "It was Mary Magdalene, Joanna, Mary the mother of James, and the other women with them, who told these things to the apostles.", 
                "num": 10
              }, 
              {
                "text": "And their words seemed to them like idle tales, and they did not believe them.", 
                "num": 11
              }, 
              {
                "text": "But Peter arose and ran to the tomb; and stooping down, he saw the linen cloths lying by themselves; and he departed, marveling to himself at what had happened.", 
                "num": 12
              }, 
              {
                "text": "Now behold, two of them were traveling that same day to a village called Emmaus, which was seven miles from Jerusalem.", 
                "num": 13
              }, 
              {
                "text": "And they talked together of all these things which had happened.", 
                "num": 14
              }, 
              {
                "text": "So it was, while they conversed and reasoned, that Jesus Himself drew near and went with them.", 
                "num": 15
              }, 
              {
                "text": "But their eyes were restrained, so that they did not know Him.", 
                "num": 16
              }, 
              {
                "text": "And He said to them, \"What kind of conversation is this that you have with one another as you walk and are sad?\"", 
                "num": 17
              }, 
              {
                "text": "Then the one whose name was Cleopas answered and said to Him, \"Are You the only stranger in Jerusalem, and have You not known the things which happened there in these days?\"", 
                "num": 18
              }, 
              {
                "text": "And He said to them, \"What things?\" So they said to Him, \"The things concerning Jesus of Nazareth, who was a Prophet mighty in deed and word before God and all the people,", 
                "num": 19
              }, 
              {
                "text": "and how the chief priests and our rulers delivered Him to be condemned to death, and crucified Him.", 
                "num": 20
              }, 
              {
                "text": "But we were hoping that it was He who was going to redeem Israel. Indeed, besides all this, today is the third day since these things happened.", 
                "num": 21
              }, 
              {
                "text": "Yes, and certain women of our company, who arrived at the tomb early, astonished us.", 
                "num": 22
              }, 
              {
                "text": "When they did not find His body, they came saying that they had also seen a vision of angels who said He was alive.", 
                "num": 23
              }, 
              {
                "text": "And certain of those who were with us went to the tomb and found it just as the women had said; but Him they did not see.\"", 
                "num": 24
              }, 
              {
                "text": "Then He said to them, \"O foolish ones, and slow of heart to believe in all that the prophets have spoken!", 
                "num": 25
              }, 
              {
                "text": "Ought not the Christ to have suffered these things and to enter into His glory?\"", 
                "num": 26
              }, 
              {
                "text": "And beginning at Moses and all the Prophets, He expounded to them in all the Scriptures the things concerning Himself.", 
                "num": 27
              }, 
              {
                "text": "Then they drew near to the village where they were going, and He indicated that He would have gone farther.", 
                "num": 28
              }, 
              {
                "text": "But they constrained Him, saying, \"Abide with us, for it is toward evening, and the day is far spent.\" And He went in to stay with them.", 
                "num": 29
              }, 
              {
                "text": "Now it came to pass, as He sat at the table with them, that He took bread, blessed and broke it, and gave it to them.", 
                "num": 30
              }, 
              {
                "text": "Then their eyes were opened and they knew Him; and He vanished from their sight.", 
                "num": 31
              }, 
              {
                "text": "And they said to one another, \"Did not our heart burn within us while He talked with us on the road, and while He opened the Scriptures to us?\"", 
                "num": 32
              }, 
              {
                "text": "So they rose up that very hour and returned to Jerusalem, and found the eleven and those who were with them gathered together,", 
                "num": 33
              }, 
              {
                "text": "saying, \"The Lord is risen indeed, and has appeared to Simon!\"", 
                "num": 34
              }, 
              {
                "text": "And they told about the things that had happened on the road, and how He was known to them in the breaking of bread.", 
                "num": 35
              }, 
              {
                "text": "Now as they said these things, Jesus Himself stood in the midst of them, and said to them, \"Peace to you.\"", 
                "num": 36
              }, 
              {
                "text": "But they were terrified and frightened, and supposed they had seen a spirit.", 
                "num": 37
              }, 
              {
                "text": "And He said to them, \"Why are you troubled? And why do doubts arise in your hearts?", 
                "num": 38
              }, 
              {
                "text": "Behold My hands and My feet, that it is I Myself. Handle Me and see, for a spirit does not have flesh and bones as you see I have.\"", 
                "num": 39
              }, 
              {
                "text": "When He had said this, He showed them His hands and His feet.", 
                "num": 40
              }, 
              {
                "text": "But while they still did not believe for joy, and marveled, He said to them, \"Have you any food here?\"", 
                "num": 41
              }, 
              {
                "text": "So they gave Him a piece of a broiled fish and some honeycomb.", 
                "num": 42
              }, 
              {
                "text": "And He took it and ate in their presence.", 
                "num": 43
              }, 
              {
                "text": "Then He said to them, \"These are the words which I spoke to you while I was still with you, that all things must be fulfilled which were written in the Law of Moses and the Prophets and the Psalms concerning Me.\"", 
                "num": 44
              }, 
              {
                "text": "And He opened their understanding, that they might comprehend the Scriptures.", 
                "num": 45
              }, 
              {
                "text": "Then He said to them, \"Thus it is written, and thus it was necessary for the Christ to suffer and to rise from the dead the third day,", 
                "num": 46
              }, 
              {
                "text": "and that repentance and remission of sins should be preached in His name to all nations, beginning at Jerusalem.", 
                "num": 47
              }, 
              {
                "text": "And you are witnesses of these things.", 
                "num": 48
              }, 
              {
                "text": "Behold, I send the Promise of My Father upon you; but tarry in the city of Jerusalem until you are endued with power from on high.\"", 
                "num": 49
              }, 
              {
                "text": "And He led them out as far as Bethany, and He lifted up His hands and blessed them.", 
                "num": 50
              }, 
              {
                "text": "Now it came to pass, while He blessed them, that He was parted from them and carried up into heaven.", 
                "num": 51
              }, 
              {
                "text": "And they worshiped Him, and returned to Jerusalem with great joy,", 
                "num": 52
              }, 
              {
                "text": "and were continually in the temple praising and blessing God. Amen.", 
                "num": 53
              }
            ], 
            "num": 24
          }
        ]
      }, 
      {
        "name": "John", 
        "chapters": [
          {
            "verses": [
              {
                "text": "In the beginning was the Word, and the Word was with God, and the Word was God.", 
                "num": 1
              }, 
              {
                "text": "He was in the beginning with God.", 
                "num": 2
              }, 
              {
                "text": "All things were made through Him, and without Him nothing was made that was made.", 
                "num": 3
              }, 
              {
                "text": "In Him was life, and the life was the light of men.", 
                "num": 4
              }, 
              {
                "text": "And the light shines in the darkness, and the darkness did not comprehend it.", 
                "num": 5
              }, 
              {
                "text": "There was a man sent from God, whose name was John.", 
                "num": 6
              }, 
              {
                "text": "This man came for a witness, to bear witness of the Light, that all through him might believe.", 
                "num": 7
              }, 
              {
                "text": "He was not that Light, but was sent to bear witness of that Light.", 
                "num": 8
              }, 
              {
                "text": "That was the true Light which gives light to every man coming into the world.", 
                "num": 9
              }, 
              {
                "text": "He was in the world, and the world was made through Him, and the world did not know Him.", 
                "num": 10
              }, 
              {
                "text": "He came to His own, and His own did not receive Him.", 
                "num": 11
              }, 
              {
                "text": "But as many as received Him, to them He gave the right to become children of God, to those who believe in His name:", 
                "num": 12
              }, 
              {
                "text": "who were born, not of blood, nor of the will of the flesh, nor of the will of man, but of God.", 
                "num": 13
              }, 
              {
                "text": "And the Word became flesh and dwelt among us, and we beheld His glory, the glory as of the only begotten of the Father, full of grace and truth.", 
                "num": 14
              }, 
              {
                "text": "John bore witness of Him and cried out, saying, \"This was He of whom I said, \"He who comes after me is preferred before me, for He was before me.\"'", 
                "num": 15
              }, 
              {
                "text": "And of His fullness we have all received, and grace for grace.", 
                "num": 16
              }, 
              {
                "text": "For the law was given through Moses, but grace and truth came through Jesus Christ.", 
                "num": 17
              }, 
              {
                "text": "No one has seen God at any time. The only begotten Son, who is in the bosom of the Father, He has declared Him.", 
                "num": 18
              }, 
              {
                "text": "Now this is the testimony of John, when the Jews sent priests and Levites from Jerusalem to ask him, \"Who are you?\"", 
                "num": 19
              }, 
              {
                "text": "He confessed, and did not deny, but confessed, \"I am not the Christ.\"", 
                "num": 20
              }, 
              {
                "text": "And they asked him, \"What then? Are you Elijah?\" He said, \"I am not.\" \"Are you the Prophet?\" And he answered, \"No.\"", 
                "num": 21
              }, 
              {
                "text": "Then they said to him, \"Who are you, that we may give an answer to those who sent us? What do you say about yourself?\"", 
                "num": 22
              }, 
              {
                "text": "He said: \"I am \"The voice of one crying in the wilderness: \"Make straight the way of the LORD,\"' as the prophet Isaiah said.\"", 
                "num": 23
              }, 
              {
                "text": "Now those who were sent were from the Pharisees.", 
                "num": 24
              }, 
              {
                "text": "And they asked him, saying, \"Why then do you baptize if you are not the Christ, nor Elijah, nor the Prophet?\"", 
                "num": 25
              }, 
              {
                "text": "John answered them, saying, \"I baptize with water, but there stands One among you whom you do not know.", 
                "num": 26
              }, 
              {
                "text": "It is He who, coming after me, is preferred before me, whose sandal strap I am not worthy to loose.\"", 
                "num": 27
              }, 
              {
                "text": "These things were done in Bethabara beyond the Jordan, where John was baptizing.", 
                "num": 28
              }, 
              {
                "text": "The next day John saw Jesus coming toward him, and said, \"Behold! The Lamb of God who takes away the sin of the world!", 
                "num": 29
              }, 
              {
                "text": "This is He of whom I said, \"After me comes a Man who is preferred before me, for He was before me.'", 
                "num": 30
              }, 
              {
                "text": "I did not know Him; but that He should be revealed to Israel, therefore I came baptizing with water.\"", 
                "num": 31
              }, 
              {
                "text": "And John bore witness, saying, \"I saw the Spirit descending from heaven like a dove, and He remained upon Him.", 
                "num": 32
              }, 
              {
                "text": "I did not know Him, but He who sent me to baptize with water said to me, \"Upon whom you see the Spirit descending, and remaining on Him, this is He who baptizes with the Holy Spirit.'", 
                "num": 33
              }, 
              {
                "text": "And I have seen and testified that this is the Son of God.\"", 
                "num": 34
              }, 
              {
                "text": "Again, the next day, John stood with two of his disciples.", 
                "num": 35
              }, 
              {
                "text": "And looking at Jesus as He walked, he said, \"Behold the Lamb of God!\"", 
                "num": 36
              }, 
              {
                "text": "The two disciples heard him speak, and they followed Jesus.", 
                "num": 37
              }, 
              {
                "text": "Then Jesus turned, and seeing them following, said to them, \"What do you seek?\" They said to Him, \"Rabbi\" (which is to say, when translated, Teacher), \"where are You staying?\"", 
                "num": 38
              }, 
              {
                "text": "He said to them, \"Come and see.\" They came and saw where He was staying, and remained with Him that day (now it was about the tenth hour).", 
                "num": 39
              }, 
              {
                "text": "One of the two who heard John speak, and followed Him, was Andrew, Simon Peter's brother.", 
                "num": 40
              }, 
              {
                "text": "He first found his own brother Simon, and said to him, \"We have found the Messiah\" (which is translated, the Christ).", 
                "num": 41
              }, 
              {
                "text": "And he brought him to Jesus. Now when Jesus looked at him, He said, \"You are Simon the son of Jonah. You shall be called Cephas\" (which is translated, A Stone).", 
                "num": 42
              }, 
              {
                "text": "The following day Jesus wanted to go to Galilee, and He found Philip and said to him, \"Follow Me.\"", 
                "num": 43
              }, 
              {
                "text": "Now Philip was from Bethsaida, the city of Andrew and Peter.", 
                "num": 44
              }, 
              {
                "text": "Philip found Nathanael and said to him, \"We have found Him of whom Moses in the law, and also the prophets, wrote--Jesus of Nazareth, the son of Joseph.\"", 
                "num": 45
              }, 
              {
                "text": "And Nathanael said to him, \"Can anything good come out of Nazareth?\" Philip said to him, \"Come and see.\"", 
                "num": 46
              }, 
              {
                "text": "Jesus saw Nathanael coming toward Him, and said of him, \"Behold, an Israelite indeed, in whom is no deceit!\"", 
                "num": 47
              }, 
              {
                "text": "Nathanael said to Him, \"How do You know me?\" Jesus answered and said to him, \"Before Philip called you, when you were under the fig tree, I saw you.\"", 
                "num": 48
              }, 
              {
                "text": "Nathanael answered and said to Him, \"Rabbi, You are the Son of God! You are the King of Israel!\"", 
                "num": 49
              }, 
              {
                "text": "Jesus answered and said to him, \"Because I said to you, \"I saw you under the fig tree,' do you believe? You will see greater things than these.\"", 
                "num": 50
              }, 
              {
                "text": "And He said to him, \"Most assuredly, I say to you, hereafter you shall see heaven open, and the angels of God ascending and descending upon the Son of Man.\"", 
                "num": 51
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "On the third day there was a wedding in Cana of Galilee, and the mother of Jesus was there.", 
                "num": 1
              }, 
              {
                "text": "Now both Jesus and His disciples were invited to the wedding.", 
                "num": 2
              }, 
              {
                "text": "And when they ran out of wine, the mother of Jesus said to Him, \"They have no wine.\"", 
                "num": 3
              }, 
              {
                "text": "Jesus said to her, \"Woman, what does your concern have to do with Me? My hour has not yet come.\"", 
                "num": 4
              }, 
              {
                "text": "His mother said to the servants, \"Whatever He says to you, do it.\"", 
                "num": 5
              }, 
              {
                "text": "Now there were set there six waterpots of stone, according to the manner of purification of the Jews, containing twenty or thirty gallons apiece.", 
                "num": 6
              }, 
              {
                "text": "Jesus said to them, \"Fill the waterpots with water.\" And they filled them up to the brim.", 
                "num": 7
              }, 
              {
                "text": "And He said to them, \"Draw some out now, and take it to the master of the feast.\" And they took it.", 
                "num": 8
              }, 
              {
                "text": "When the master of the feast had tasted the water that was made wine, and did not know where it came from (but the servants who had drawn the water knew), the master of the feast called the bridegroom.", 
                "num": 9
              }, 
              {
                "text": "And he said to him, \"Every man at the beginning sets out the good wine, and when the guests have well drunk, then the inferior. You have kept the good wine until now!\"", 
                "num": 10
              }, 
              {
                "text": "This beginning of signs Jesus did in Cana of Galilee, and manifested His glory; and His disciples believed in Him.", 
                "num": 11
              }, 
              {
                "text": "After this He went down to Capernaum, He, His mother, His brothers, and His disciples; and they did not stay there many days.", 
                "num": 12
              }, 
              {
                "text": "Now the Passover of the Jews was at hand, and Jesus went up to Jerusalem.", 
                "num": 13
              }, 
              {
                "text": "And He found in the temple those who sold oxen and sheep and doves, and the money changers doing business.", 
                "num": 14
              }, 
              {
                "text": "When He had made a whip of cords, He drove them all out of the temple, with the sheep and the oxen, and poured out the changers' money and overturned the tables.", 
                "num": 15
              }, 
              {
                "text": "And He said to those who sold doves, \"Take these things away! Do not make My Father's house a house of merchandise!\"", 
                "num": 16
              }, 
              {
                "text": "Then His disciples remembered that it was written, \"Zeal for Your house has eaten Me up.\"", 
                "num": 17
              }, 
              {
                "text": "So the Jews answered and said to Him, \"What sign do You show to us, since You do these things?\"", 
                "num": 18
              }, 
              {
                "text": "Jesus answered and said to them, \"Destroy this temple, and in three days I will raise it up.\"", 
                "num": 19
              }, 
              {
                "text": "Then the Jews said, \"It has taken forty-six years to build this temple, and will You raise it up in three days?\"", 
                "num": 20
              }, 
              {
                "text": "But He was speaking of the temple of His body.", 
                "num": 21
              }, 
              {
                "text": "Therefore, when He had risen from the dead, His disciples remembered that He had said this to them; and they believed the Scripture and the word which Jesus had said.", 
                "num": 22
              }, 
              {
                "text": "Now when He was in Jerusalem at the Passover, during the feast, many believed in His name when they saw the signs which He did.", 
                "num": 23
              }, 
              {
                "text": "But Jesus did not commit Himself to them, because He knew all men,", 
                "num": 24
              }, 
              {
                "text": "and had no need that anyone should testify of man, for He knew what was in man.", 
                "num": 25
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "There was a man of the Pharisees named Nicodemus, a ruler of the Jews.", 
                "num": 1
              }, 
              {
                "text": "This man came to Jesus by night and said to Him, \"Rabbi, we know that You are a teacher come from God; for no one can do these signs that You do unless God is with him.\"", 
                "num": 2
              }, 
              {
                "text": "Jesus answered and said to him, \"Most assuredly, I say to you, unless one is born again, he cannot see the kingdom of God.\"", 
                "num": 3
              }, 
              {
                "text": "Nicodemus said to Him, \"How can a man be born when he is old? Can he enter a second time into his mother's womb and be born?\"", 
                "num": 4
              }, 
              {
                "text": "Jesus answered, \"Most assuredly, I say to you, unless one is born of water and the Spirit, he cannot enter the kingdom of God.", 
                "num": 5
              }, 
              {
                "text": "That which is born of the flesh is flesh, and that which is born of the Spirit is spirit.", 
                "num": 6
              }, 
              {
                "text": "Do not marvel that I said to you, \"You must be born again.'", 
                "num": 7
              }, 
              {
                "text": "The wind blows where it wishes, and you hear the sound of it, but cannot tell where it comes from and where it goes. So is everyone who is born of the Spirit.\"", 
                "num": 8
              }, 
              {
                "text": "Nicodemus answered and said to Him, \"How can these things be?\"", 
                "num": 9
              }, 
              {
                "text": "Jesus answered and said to him, \"Are you the teacher of Israel, and do not know these things?", 
                "num": 10
              }, 
              {
                "text": "Most assuredly, I say to you, We speak what We know and testify what We have seen, and you do not receive Our witness.", 
                "num": 11
              }, 
              {
                "text": "If I have told you earthly things and you do not believe, how will you believe if I tell you heavenly things?", 
                "num": 12
              }, 
              {
                "text": "No one has ascended to heaven but He who came down from heaven, that is, the Son of Man who is in heaven.", 
                "num": 13
              }, 
              {
                "text": "And as Moses lifted up the serpent in the wilderness, even so must the Son of Man be lifted up,", 
                "num": 14
              }, 
              {
                "text": "that whoever believes in Him should not perish but have eternal life.", 
                "num": 15
              }, 
              {
                "text": "For God so loved the world that He gave His only begotten Son, that whoever believes in Him should not perish but have everlasting life.", 
                "num": 16
              }, 
              {
                "text": "For God did not send His Son into the world to condemn the world, but that the world through Him might be saved.", 
                "num": 17
              }, 
              {
                "text": "\"He who believes in Him is not condemned; but he who does not believe is condemned already, because he has not believed in the name of the only begotten Son of God.", 
                "num": 18
              }, 
              {
                "text": "And this is the condemnation, that the light has come into the world, and men loved darkness rather than light, because their deeds were evil.", 
                "num": 19
              }, 
              {
                "text": "For everyone practicing evil hates the light and does not come to the light, lest his deeds should be exposed.", 
                "num": 20
              }, 
              {
                "text": "But he who does the truth comes to the light, that his deeds may be clearly seen, that they have been done in God.\"", 
                "num": 21
              }, 
              {
                "text": "After these things Jesus and His disciples came into the land of Judea, and there He remained with them and baptized.", 
                "num": 22
              }, 
              {
                "text": "Now John also was baptizing in Aenon near Salim, because there was much water there. And they came and were baptized.", 
                "num": 23
              }, 
              {
                "text": "For John had not yet been thrown into prison.", 
                "num": 24
              }, 
              {
                "text": "Then there arose a dispute between some of John's disciples and the Jews about purification.", 
                "num": 25
              }, 
              {
                "text": "And they came to John and said to him, \"Rabbi, He who was with you beyond the Jordan, to whom you have testified--behold, He is baptizing, and all are coming to Him!\"", 
                "num": 26
              }, 
              {
                "text": "John answered and said, \"A man can receive nothing unless it has been given to him from heaven.", 
                "num": 27
              }, 
              {
                "text": "You yourselves bear me witness, that I said, \"I am not the Christ,' but, \"I have been sent before Him.'", 
                "num": 28
              }, 
              {
                "text": "He who has the bride is the bridegroom; but the friend of the bridegroom, who stands and hears him, rejoices greatly because of the bridegroom's voice. Therefore this joy of mine is fulfilled.", 
                "num": 29
              }, 
              {
                "text": "He must increase, but I must decrease.", 
                "num": 30
              }, 
              {
                "text": "He who comes from above is above all; he who is of the earth is earthly and speaks of the earth. He who comes from heaven is above all.", 
                "num": 31
              }, 
              {
                "text": "And what He has seen and heard, that He testifies; and no one receives His testimony.", 
                "num": 32
              }, 
              {
                "text": "He who has received His testimony has certified that God is true.", 
                "num": 33
              }, 
              {
                "text": "For He whom God has sent speaks the words of God, for God does not give the Spirit by measure.", 
                "num": 34
              }, 
              {
                "text": "The Father loves the Son, and has given all things into His hand.", 
                "num": 35
              }, 
              {
                "text": "He who believes in the Son has everlasting life; and he who does not believe the Son shall not see life, but the wrath of God abides on him.\"", 
                "num": 36
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Therefore, when the Lord knew that the Pharisees had heard that Jesus made and baptized more disciples than John", 
                "num": 1
              }, 
              {
                "text": "(though Jesus Himself did not baptize, but His disciples),", 
                "num": 2
              }, 
              {
                "text": "He left Judea and departed again to Galilee.", 
                "num": 3
              }, 
              {
                "text": "But He needed to go through Samaria.", 
                "num": 4
              }, 
              {
                "text": "So He came to a city of Samaria which is called Sychar, near the plot of ground that Jacob gave to his son Joseph.", 
                "num": 5
              }, 
              {
                "text": "Now Jacob's well was there. Jesus therefore, being wearied from His journey, sat thus by the well. It was about the sixth hour.", 
                "num": 6
              }, 
              {
                "text": "A woman of Samaria came to draw water. Jesus said to her, \"Give Me a drink.\"", 
                "num": 7
              }, 
              {
                "text": "For His disciples had gone away into the city to buy food.", 
                "num": 8
              }, 
              {
                "text": "Then the woman of Samaria said to Him, \"How is it that You, being a Jew, ask a drink from me, a Samaritan woman?\" For Jews have no dealings with Samaritans.", 
                "num": 9
              }, 
              {
                "text": "Jesus answered and said to her, \"If you knew the gift of God, and who it is who says to you, \"Give Me a drink,' you would have asked Him, and He would have given you living water.\"", 
                "num": 10
              }, 
              {
                "text": "The woman said to Him, \"Sir, You have nothing to draw with, and the well is deep. Where then do You get that living water?", 
                "num": 11
              }, 
              {
                "text": "Are You greater than our father Jacob, who gave us the well, and drank from it himself, as well as his sons and his livestock?\"", 
                "num": 12
              }, 
              {
                "text": "Jesus answered and said to her, \"Whoever drinks of this water will thirst again,", 
                "num": 13
              }, 
              {
                "text": "but whoever drinks of the water that I shall give him will never thirst. But the water that I shall give him will become in him a fountain of water springing up into everlasting life.\"", 
                "num": 14
              }, 
              {
                "text": "The woman said to Him, \"Sir, give me this water, that I may not thirst, nor come here to draw.\"", 
                "num": 15
              }, 
              {
                "text": "Jesus said to her, \"Go, call your husband, and come here.\"", 
                "num": 16
              }, 
              {
                "text": "The woman answered and said, \"I have no husband.\" Jesus said to her, \"You have well said, \"I have no husband,'", 
                "num": 17
              }, 
              {
                "text": "for you have had five husbands, and the one whom you now have is not your husband; in that you spoke truly.\"", 
                "num": 18
              }, 
              {
                "text": "The woman said to Him, \"Sir, I perceive that You are a prophet.", 
                "num": 19
              }, 
              {
                "text": "Our fathers worshiped on this mountain, and you Jews say that in Jerusalem is the place where one ought to worship.\"", 
                "num": 20
              }, 
              {
                "text": "Jesus said to her, \"Woman, believe Me, the hour is coming when you will neither on this mountain, nor in Jerusalem, worship the Father.", 
                "num": 21
              }, 
              {
                "text": "You worship what you do not know; we know what we worship, for salvation is of the Jews.", 
                "num": 22
              }, 
              {
                "text": "But the hour is coming, and now is, when the true worshipers will worship the Father in spirit and truth; for the Father is seeking such to worship Him.", 
                "num": 23
              }, 
              {
                "text": "God is Spirit, and those who worship Him must worship in spirit and truth.\"", 
                "num": 24
              }, 
              {
                "text": "The woman said to Him, \"I know that Messiah is coming\" (who is called Christ). \"When He comes, He will tell us all things.\"", 
                "num": 25
              }, 
              {
                "text": "Jesus said to her, \"I who speak to you am He.\"", 
                "num": 26
              }, 
              {
                "text": "And at this point His disciples came, and they marveled that He talked with a woman; yet no one said, \"What do You seek?\" or, \"Why are You talking with her?\"", 
                "num": 27
              }, 
              {
                "text": "The woman then left her waterpot, went her way into the city, and said to the men,", 
                "num": 28
              }, 
              {
                "text": "\"Come, see a Man who told me all things that I ever did. Could this be the Christ?\"", 
                "num": 29
              }, 
              {
                "text": "Then they went out of the city and came to Him.", 
                "num": 30
              }, 
              {
                "text": "In the meantime His disciples urged Him, saying, \"Rabbi, eat.\"", 
                "num": 31
              }, 
              {
                "text": "But He said to them, \"I have food to eat of which you do not know.\"", 
                "num": 32
              }, 
              {
                "text": "Therefore the disciples said to one another, \"Has anyone brought Him anything to eat?\"", 
                "num": 33
              }, 
              {
                "text": "Jesus said to them, \"My food is to do the will of Him who sent Me, and to finish His work.", 
                "num": 34
              }, 
              {
                "text": "Do you not say, \"There are still four months and then comes the harvest'? Behold, I say to you, lift up your eyes and look at the fields, for they are already white for harvest!", 
                "num": 35
              }, 
              {
                "text": "And he who reaps receives wages, and gathers fruit for eternal life, that both he who sows and he who reaps may rejoice together.", 
                "num": 36
              }, 
              {
                "text": "For in this the saying is true: \"One sows and another reaps.'", 
                "num": 37
              }, 
              {
                "text": "I sent you to reap that for which you have not labored; others have labored, and you have entered into their labors.\"", 
                "num": 38
              }, 
              {
                "text": "And many of the Samaritans of that city believed in Him because of the word of the woman who testified, \"He told me all that I ever did.\"", 
                "num": 39
              }, 
              {
                "text": "So when the Samaritans had come to Him, they urged Him to stay with them; and He stayed there two days.", 
                "num": 40
              }, 
              {
                "text": "And many more believed because of His own word.", 
                "num": 41
              }, 
              {
                "text": "Then they said to the woman, \"Now we believe, not because of what you said, for we ourselves have heard Him and we know that this is indeed the Christ, the Savior of the world.\"", 
                "num": 42
              }, 
              {
                "text": "Now after the two days He departed from there and went to Galilee.", 
                "num": 43
              }, 
              {
                "text": "For Jesus Himself testified that a prophet has no honor in his own country.", 
                "num": 44
              }, 
              {
                "text": "So when He came to Galilee, the Galileans received Him, having seen all the things He did in Jerusalem at the feast; for they also had gone to the feast.", 
                "num": 45
              }, 
              {
                "text": "So Jesus came again to Cana of Galilee where He had made the water wine. And there was a certain nobleman whose son was sick at Capernaum.", 
                "num": 46
              }, 
              {
                "text": "When he heard that Jesus had come out of Judea into Galilee, he went to Him and implored Him to come down and heal his son, for he was at the point of death.", 
                "num": 47
              }, 
              {
                "text": "Then Jesus said to him, \"Unless you people see signs and wonders, you will by no means believe.\"", 
                "num": 48
              }, 
              {
                "text": "The nobleman said to Him, \"Sir, come down before my child dies!\"", 
                "num": 49
              }, 
              {
                "text": "Jesus said to him, \"Go your way; your son lives.\" So the man believed the word that Jesus spoke to him, and he went his way.", 
                "num": 50
              }, 
              {
                "text": "And as he was now going down, his servants met him and told him, saying, \"Your son lives!\"", 
                "num": 51
              }, 
              {
                "text": "Then he inquired of them the hour when he got better. And they said to him, \"Yesterday at the seventh hour the fever left him.\"", 
                "num": 52
              }, 
              {
                "text": "So the father knew that it was at the same hour in which Jesus said to him, \"Your son lives.\" And he himself believed, and his whole household.", 
                "num": 53
              }, 
              {
                "text": "This again is the second sign Jesus did when He had come out of Judea into Galilee.", 
                "num": 54
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "After this there was a feast of the Jews, and Jesus went up to Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Now there is in Jerusalem by the Sheep Gate a pool, which is called in Hebrew, Bethesda, having five porches.", 
                "num": 2
              }, 
              {
                "text": "In these lay a great multitude of sick people, blind, lame, paralyzed, waiting for the moving of the water.", 
                "num": 3
              }, 
              {
                "text": "For an angel went down at a certain time into the pool and stirred up the water; then whoever stepped in first, after the stirring of the water, was made well of whatever disease he had.", 
                "num": 4
              }, 
              {
                "text": "Now a certain man was there who had an infirmity thirty-eight years.", 
                "num": 5
              }, 
              {
                "text": "When Jesus saw him lying there, and knew that he already had been in that condition a long time, He said to him, \"Do you want to be made well?\"", 
                "num": 6
              }, 
              {
                "text": "The sick man answered Him, \"Sir, I have no man to put me into the pool when the water is stirred up; but while I am coming, another steps down before me.\"", 
                "num": 7
              }, 
              {
                "text": "Jesus said to him, \"Rise, take up your bed and walk.\"", 
                "num": 8
              }, 
              {
                "text": "And immediately the man was made well, took up his bed, and walked. And that day was the Sabbath.", 
                "num": 9
              }, 
              {
                "text": "The Jews therefore said to him who was cured, \"It is the Sabbath; it is not lawful for you to carry your bed.\"", 
                "num": 10
              }, 
              {
                "text": "He answered them, \"He who made me well said to me, \"Take up your bed and walk.\"'", 
                "num": 11
              }, 
              {
                "text": "Then they asked him, \"Who is the Man who said to you, \"Take up your bed and walk'?\"", 
                "num": 12
              }, 
              {
                "text": "But the one who was healed did not know who it was, for Jesus had withdrawn, a multitude being in that place.", 
                "num": 13
              }, 
              {
                "text": "Afterward Jesus found him in the temple, and said to him, \"See, you have been made well. Sin no more, lest a worse thing come upon you.\"", 
                "num": 14
              }, 
              {
                "text": "The man departed and told the Jews that it was Jesus who had made him well.", 
                "num": 15
              }, 
              {
                "text": "For this reason the Jews persecuted Jesus, and sought to kill Him, because He had done these things on the Sabbath.", 
                "num": 16
              }, 
              {
                "text": "But Jesus answered them, \"My Father has been working until now, and I have been working.\"", 
                "num": 17
              }, 
              {
                "text": "Therefore the Jews sought all the more to kill Him, because He not only broke the Sabbath, but also said that God was His Father, making Himself equal with God.", 
                "num": 18
              }, 
              {
                "text": "Then Jesus answered and said to them, \"Most assuredly, I say to you, the Son can do nothing of Himself, but what He sees the Father do; for whatever He does, the Son also does in like manner.", 
                "num": 19
              }, 
              {
                "text": "For the Father loves the Son, and shows Him all things that He Himself does; and He will show Him greater works than these, that you may marvel.", 
                "num": 20
              }, 
              {
                "text": "For as the Father raises the dead and gives life to them, even so the Son gives life to whom He will.", 
                "num": 21
              }, 
              {
                "text": "For the Father judges no one, but has committed all judgment to the Son,", 
                "num": 22
              }, 
              {
                "text": "that all should honor the Son just as they honor the Father. He who does not honor the Son does not honor the Father who sent Him.", 
                "num": 23
              }, 
              {
                "text": "\"Most assuredly, I say to you, he who hears My word and believes in Him who sent Me has everlasting life, and shall not come into judgment, but has passed from death into life.", 
                "num": 24
              }, 
              {
                "text": "Most assuredly, I say to you, the hour is coming, and now is, when the dead will hear the voice of the Son of God; and those who hear will live.", 
                "num": 25
              }, 
              {
                "text": "For as the Father has life in Himself, so He has granted the Son to have life in Himself,", 
                "num": 26
              }, 
              {
                "text": "and has given Him authority to execute judgment also, because He is the Son of Man.", 
                "num": 27
              }, 
              {
                "text": "Do not marvel at this; for the hour is coming in which all who are in the graves will hear His voice", 
                "num": 28
              }, 
              {
                "text": "and come forth--those who have done good, to the resurrection of life, and those who have done evil, to the resurrection of condemnation.", 
                "num": 29
              }, 
              {
                "text": "I can of Myself do nothing. As I hear, I judge; and My judgment is righteous, because I do not seek My own will but the will of the Father who sent Me.", 
                "num": 30
              }, 
              {
                "text": "\"If I bear witness of Myself, My witness is not true.", 
                "num": 31
              }, 
              {
                "text": "There is another who bears witness of Me, and I know that the witness which He witnesses of Me is true.", 
                "num": 32
              }, 
              {
                "text": "You have sent to John, and he has borne witness to the truth.", 
                "num": 33
              }, 
              {
                "text": "Yet I do not receive testimony from man, but I say these things that you may be saved.", 
                "num": 34
              }, 
              {
                "text": "He was the burning and shining lamp, and you were willing for a time to rejoice in his light.", 
                "num": 35
              }, 
              {
                "text": "But I have a greater witness than John's; for the works which the Father has given Me to finish--the very works that I do--bear witness of Me, that the Father has sent Me.", 
                "num": 36
              }, 
              {
                "text": "And the Father Himself, who sent Me, has testified of Me. You have neither heard His voice at any time, nor seen His form.", 
                "num": 37
              }, 
              {
                "text": "But you do not have His word abiding in you, because whom He sent, Him you do not believe.", 
                "num": 38
              }, 
              {
                "text": "You search the Scriptures, for in them you think you have eternal life; and these are they which testify of Me.", 
                "num": 39
              }, 
              {
                "text": "But you are not willing to come to Me that you may have life.", 
                "num": 40
              }, 
              {
                "text": "\"I do not receive honor from men.", 
                "num": 41
              }, 
              {
                "text": "But I know you, that you do not have the love of God in you.", 
                "num": 42
              }, 
              {
                "text": "I have come in My Father's name, and you do not receive Me; if another comes in his own name, him you will receive.", 
                "num": 43
              }, 
              {
                "text": "How can you believe, who receive honor from one another, and do not seek the honor that comes from the only God?", 
                "num": 44
              }, 
              {
                "text": "Do not think that I shall accuse you to the Father; there is one who accuses you--Moses, in whom you trust.", 
                "num": 45
              }, 
              {
                "text": "For if you believed Moses, you would believe Me; for he wrote about Me.", 
                "num": 46
              }, 
              {
                "text": "But if you do not believe his writings, how will you believe My words?\"", 
                "num": 47
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "After these things Jesus went over the Sea of Galilee, which is the Sea of Tiberias.", 
                "num": 1
              }, 
              {
                "text": "Then a great multitude followed Him, because they saw His signs which He performed on those who were diseased.", 
                "num": 2
              }, 
              {
                "text": "And Jesus went up on the mountain, and there He sat with His disciples.", 
                "num": 3
              }, 
              {
                "text": "Now the Passover, a feast of the Jews, was near.", 
                "num": 4
              }, 
              {
                "text": "Then Jesus lifted up His eyes, and seeing a great multitude coming toward Him, He said to Philip, \"Where shall we buy bread, that these may eat?\"", 
                "num": 5
              }, 
              {
                "text": "But this He said to test him, for He Himself knew what He would do.", 
                "num": 6
              }, 
              {
                "text": "Philip answered Him, \"Two hundred denarii worth of bread is not sufficient for them, that every one of them may have a little.\"", 
                "num": 7
              }, 
              {
                "text": "One of His disciples, Andrew, Simon Peter's brother, said to Him,", 
                "num": 8
              }, 
              {
                "text": "\"There is a lad here who has five barley loaves and two small fish, but what are they among so many?\"", 
                "num": 9
              }, 
              {
                "text": "Then Jesus said, \"Make the people sit down.\" Now there was much grass in the place. So the men sat down, in number about five thousand.", 
                "num": 10
              }, 
              {
                "text": "And Jesus took the loaves, and when He had given thanks He distributed them to the disciples, and the disciples to those sitting down; and likewise of the fish, as much as they wanted.", 
                "num": 11
              }, 
              {
                "text": "So when they were filled, He said to His disciples, \"Gather up the fragments that remain, so that nothing is lost.\"", 
                "num": 12
              }, 
              {
                "text": "Therefore they gathered them up, and filled twelve baskets with the fragments of the five barley loaves which were left over by those who had eaten.", 
                "num": 13
              }, 
              {
                "text": "Then those men, when they had seen the sign that Jesus did, said, \"This is truly the Prophet who is to come into the world.\"", 
                "num": 14
              }, 
              {
                "text": "Therefore when Jesus perceived that they were about to come and take Him by force to make Him king, He departed again to the mountain by Himself alone.", 
                "num": 15
              }, 
              {
                "text": "Now when evening came, His disciples went down to the sea,", 
                "num": 16
              }, 
              {
                "text": "got into the boat, and went over the sea toward Capernaum. And it was already dark, and Jesus had not come to them.", 
                "num": 17
              }, 
              {
                "text": "Then the sea arose because a great wind was blowing.", 
                "num": 18
              }, 
              {
                "text": "So when they had rowed about three or four miles, they saw Jesus walking on the sea and drawing near the boat; and they were afraid.", 
                "num": 19
              }, 
              {
                "text": "But He said to them, \"It is I; do not be afraid.\"", 
                "num": 20
              }, 
              {
                "text": "Then they willingly received Him into the boat, and immediately the boat was at the land where they were going.", 
                "num": 21
              }, 
              {
                "text": "On the following day, when the people who were standing on the other side of the sea saw that there was no other boat there, except that one which His disciples had entered, and that Jesus had not entered the boat with His disciples, but His disciples had gone away alone--", 
                "num": 22
              }, 
              {
                "text": "however, other boats came from Tiberias, near the place where they ate bread after the Lord had given thanks--", 
                "num": 23
              }, 
              {
                "text": "when the people therefore saw that Jesus was not there, nor His disciples, they also got into boats and came to Capernaum, seeking Jesus.", 
                "num": 24
              }, 
              {
                "text": "And when they found Him on the other side of the sea, they said to Him, \"Rabbi, when did You come here?\"", 
                "num": 25
              }, 
              {
                "text": "Jesus answered them and said, \"Most assuredly, I say to you, you seek Me, not because you saw the signs, but because you ate of the loaves and were filled.", 
                "num": 26
              }, 
              {
                "text": "Do not labor for the food which perishes, but for the food which endures to everlasting life, which the Son of Man will give you, because God the Father has set His seal on Him.\"", 
                "num": 27
              }, 
              {
                "text": "Then they said to Him, \"What shall we do, that we may work the works of God?\"", 
                "num": 28
              }, 
              {
                "text": "Jesus answered and said to them, \"This is the work of God, that you believe in Him whom He sent.\"", 
                "num": 29
              }, 
              {
                "text": "Therefore they said to Him, \"What sign will You perform then, that we may see it and believe You? What work will You do?", 
                "num": 30
              }, 
              {
                "text": "Our fathers ate the manna in the desert; as it is written, \"He gave them bread from heaven to eat.\"'", 
                "num": 31
              }, 
              {
                "text": "Then Jesus said to them, \"Most assuredly, I say to you, Moses did not give you the bread from heaven, but My Father gives you the true bread from heaven.", 
                "num": 32
              }, 
              {
                "text": "For the bread of God is He who comes down from heaven and gives life to the world.\"", 
                "num": 33
              }, 
              {
                "text": "Then they said to Him, \"Lord, give us this bread always.\"", 
                "num": 34
              }, 
              {
                "text": "And Jesus said to them, \"I am the bread of life. He who comes to Me shall never hunger, and he who believes in Me shall never thirst.", 
                "num": 35
              }, 
              {
                "text": "But I said to you that you have seen Me and yet do not believe.", 
                "num": 36
              }, 
              {
                "text": "All that the Father gives Me will come to Me, and the one who comes to Me I will by no means cast out.", 
                "num": 37
              }, 
              {
                "text": "For I have come down from heaven, not to do My own will, but the will of Him who sent Me.", 
                "num": 38
              }, 
              {
                "text": "This is the will of the Father who sent Me, that of all He has given Me I should lose nothing, but should raise it up at the last day.", 
                "num": 39
              }, 
              {
                "text": "And this is the will of Him who sent Me, that everyone who sees the Son and believes in Him may have everlasting life; and I will raise him up at the last day.\"", 
                "num": 40
              }, 
              {
                "text": "The Jews then complained about Him, because He said, \"I am the bread which came down from heaven.\"", 
                "num": 41
              }, 
              {
                "text": "And they said, \"Is not this Jesus, the son of Joseph, whose father and mother we know? How is it then that He says, \"I have come down from heaven'?\"", 
                "num": 42
              }, 
              {
                "text": "Jesus therefore answered and said to them, \"Do not murmur among yourselves.", 
                "num": 43
              }, 
              {
                "text": "No one can come to Me unless the Father who sent Me draws him; and I will raise him up at the last day.", 
                "num": 44
              }, 
              {
                "text": "It is written in the prophets, \"And they shall all be taught by God.' Therefore everyone who has heard and learned from the Father comes to Me.", 
                "num": 45
              }, 
              {
                "text": "Not that anyone has seen the Father, except He who is from God; He has seen the Father.", 
                "num": 46
              }, 
              {
                "text": "Most assuredly, I say to you, he who believes in Me has everlasting life.", 
                "num": 47
              }, 
              {
                "text": "I am the bread of life.", 
                "num": 48
              }, 
              {
                "text": "Your fathers ate the manna in the wilderness, and are dead.", 
                "num": 49
              }, 
              {
                "text": "This is the bread which comes down from heaven, that one may eat of it and not die.", 
                "num": 50
              }, 
              {
                "text": "I am the living bread which came down from heaven. If anyone eats of this bread, he will live forever; and the bread that I shall give is My flesh, which I shall give for the life of the world.\"", 
                "num": 51
              }, 
              {
                "text": "The Jews therefore quarreled among themselves, saying, \"How can this Man give us His flesh to eat?\"", 
                "num": 52
              }, 
              {
                "text": "Then Jesus said to them, \"Most assuredly, I say to you, unless you eat the flesh of the Son of Man and drink His blood, you have no life in you.", 
                "num": 53
              }, 
              {
                "text": "Whoever eats My flesh and drinks My blood has eternal life, and I will raise him up at the last day.", 
                "num": 54
              }, 
              {
                "text": "For My flesh is food indeed, and My blood is drink indeed.", 
                "num": 55
              }, 
              {
                "text": "He who eats My flesh and drinks My blood abides in Me, and I in him.", 
                "num": 56
              }, 
              {
                "text": "As the living Father sent Me, and I live because of the Father, so he who feeds on Me will live because of Me.", 
                "num": 57
              }, 
              {
                "text": "This is the bread which came down from heaven--not as your fathers ate the manna, and are dead. He who eats this bread will live forever.\"", 
                "num": 58
              }, 
              {
                "text": "These things He said in the synagogue as He taught in Capernaum.", 
                "num": 59
              }, 
              {
                "text": "Therefore many of His disciples, when they heard this, said, \"This is a hard saying; who can understand it?\"", 
                "num": 60
              }, 
              {
                "text": "When Jesus knew in Himself that His disciples complained about this, He said to them, \"Does this offend you?", 
                "num": 61
              }, 
              {
                "text": "What then if you should see the Son of Man ascend where He was before?", 
                "num": 62
              }, 
              {
                "text": "It is the Spirit who gives life; the flesh profits nothing. The words that I speak to you are spirit, and they are life.", 
                "num": 63
              }, 
              {
                "text": "But there are some of you who do not believe.\" For Jesus knew from the beginning who they were who did not believe, and who would betray Him.", 
                "num": 64
              }, 
              {
                "text": "And He said, \"Therefore I have said to you that no one can come to Me unless it has been granted to him by My Father.\"", 
                "num": 65
              }, 
              {
                "text": "From that time many of His disciples went back and walked with Him no more.", 
                "num": 66
              }, 
              {
                "text": "Then Jesus said to the twelve, \"Do you also want to go away?\"", 
                "num": 67
              }, 
              {
                "text": "But Simon Peter answered Him, \"Lord, to whom shall we go? You have the words of eternal life.", 
                "num": 68
              }, 
              {
                "text": "Also we have come to believe and know that You are the Christ, the Son of the living God.\"", 
                "num": 69
              }, 
              {
                "text": "Jesus answered them, \"Did I not choose you, the twelve, and one of you is a devil?\"", 
                "num": 70
              }, 
              {
                "text": "He spoke of Judas Iscariot, the son of Simon, for it was he who would betray Him, being one of the twelve.", 
                "num": 71
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "After these things Jesus walked in Galilee; for He did not want to walk in Judea, because the Jews sought to kill Him.", 
                "num": 1
              }, 
              {
                "text": "Now the Jews' Feast of Tabernacles was at hand.", 
                "num": 2
              }, 
              {
                "text": "His brothers therefore said to Him, \"Depart from here and go into Judea, that Your disciples also may see the works that You are doing.", 
                "num": 3
              }, 
              {
                "text": "For no one does anything in secret while he himself seeks to be known openly. If You do these things, show Yourself to the world.\"", 
                "num": 4
              }, 
              {
                "text": "For even His brothers did not believe in Him.", 
                "num": 5
              }, 
              {
                "text": "Then Jesus said to them, \"My time has not yet come, but your time is always ready.", 
                "num": 6
              }, 
              {
                "text": "The world cannot hate you, but it hates Me because I testify of it that its works are evil.", 
                "num": 7
              }, 
              {
                "text": "You go up to this feast. I am not yet going up to this feast, for My time has not yet fully come.\"", 
                "num": 8
              }, 
              {
                "text": "When He had said these things to them, He remained in Galilee.", 
                "num": 9
              }, 
              {
                "text": "But when His brothers had gone up, then He also went up to the feast, not openly, but as it were in secret.", 
                "num": 10
              }, 
              {
                "text": "Then the Jews sought Him at the feast, and said, \"Where is He?\"", 
                "num": 11
              }, 
              {
                "text": "And there was much complaining among the people concerning Him. Some said, \"He is good\"; others said, \"No, on the contrary, He deceives the people.\"", 
                "num": 12
              }, 
              {
                "text": "However, no one spoke openly of Him for fear of the Jews.", 
                "num": 13
              }, 
              {
                "text": "Now about the middle of the feast Jesus went up into the temple and taught.", 
                "num": 14
              }, 
              {
                "text": "And the Jews marveled, saying, \"How does this Man know letters, having never studied?\"", 
                "num": 15
              }, 
              {
                "text": "Jesus answered them and said, \"My doctrine is not Mine, but His who sent Me.", 
                "num": 16
              }, 
              {
                "text": "If anyone wills to do His will, he shall know concerning the doctrine, whether it is from God or whether I speak on My own authority.", 
                "num": 17
              }, 
              {
                "text": "He who speaks from himself seeks his own glory; but He who seeks the glory of the One who sent Him is true, and no unrighteousness is in Him.", 
                "num": 18
              }, 
              {
                "text": "Did not Moses give you the law, yet none of you keeps the law? Why do you seek to kill Me?\"", 
                "num": 19
              }, 
              {
                "text": "The people answered and said, \"You have a demon. Who is seeking to kill You?\"", 
                "num": 20
              }, 
              {
                "text": "Jesus answered and said to them, \"I did one work, and you all marvel.", 
                "num": 21
              }, 
              {
                "text": "Moses therefore gave you circumcision (not that it is from Moses, but from the fathers), and you circumcise a man on the Sabbath.", 
                "num": 22
              }, 
              {
                "text": "If a man receives circumcision on the Sabbath, so that the law of Moses should not be broken, are you angry with Me because I made a man completely well on the Sabbath?", 
                "num": 23
              }, 
              {
                "text": "Do not judge according to appearance, but judge with righteous judgment.\"", 
                "num": 24
              }, 
              {
                "text": "Now some of them from Jerusalem said, \"Is this not He whom they seek to kill?", 
                "num": 25
              }, 
              {
                "text": "But look! He speaks boldly, and they say nothing to Him. Do the rulers know indeed that this is truly the Christ?", 
                "num": 26
              }, 
              {
                "text": "However, we know where this Man is from; but when the Christ comes, no one knows where He is from.\"", 
                "num": 27
              }, 
              {
                "text": "Then Jesus cried out, as He taught in the temple, saying, \"You both know Me, and you know where I am from; and I have not come of Myself, but He who sent Me is true, whom you do not know.", 
                "num": 28
              }, 
              {
                "text": "But I know Him, for I am from Him, and He sent Me.\"", 
                "num": 29
              }, 
              {
                "text": "Therefore they sought to take Him; but no one laid a hand on Him, because His hour had not yet come.", 
                "num": 30
              }, 
              {
                "text": "And many of the people believed in Him, and said, \"When the Christ comes, will He do more signs than these which this Man has done?\"", 
                "num": 31
              }, 
              {
                "text": "The Pharisees heard the crowd murmuring these things concerning Him, and the Pharisees and the chief priests sent officers to take Him.", 
                "num": 32
              }, 
              {
                "text": "Then Jesus said to them, \"I shall be with you a little while longer, and then I go to Him who sent Me.", 
                "num": 33
              }, 
              {
                "text": "You will seek Me and not find Me, and where I am you cannot come.\"", 
                "num": 34
              }, 
              {
                "text": "Then the Jews said among themselves, \"Where does He intend to go that we shall not find Him? Does He intend to go to the Dispersion among the Greeks and teach the Greeks?", 
                "num": 35
              }, 
              {
                "text": "What is this thing that He said, \"You will seek Me and not find Me, and where I am you cannot come'?\"", 
                "num": 36
              }, 
              {
                "text": "On the last day, that great day of the feast, Jesus stood and cried out, saying, \"If anyone thirsts, let him come to Me and drink.", 
                "num": 37
              }, 
              {
                "text": "He who believes in Me, as the Scripture has said, out of his heart will flow rivers of living water.\"", 
                "num": 38
              }, 
              {
                "text": "But this He spoke concerning the Spirit, whom those believing in Him would receive; for the Holy Spirit was not yet given, because Jesus was not yet glorified.", 
                "num": 39
              }, 
              {
                "text": "Therefore many from the crowd, when they heard this saying, said, \"Truly this is the Prophet.\"", 
                "num": 40
              }, 
              {
                "text": "Others said, \"This is the Christ.\" But some said, \"Will the Christ come out of Galilee?", 
                "num": 41
              }, 
              {
                "text": "Has not the Scripture said that the Christ comes from the seed of David and from the town of Bethlehem, where David was?\"", 
                "num": 42
              }, 
              {
                "text": "So there was a division among the people because of Him.", 
                "num": 43
              }, 
              {
                "text": "Now some of them wanted to take Him, but no one laid hands on Him.", 
                "num": 44
              }, 
              {
                "text": "Then the officers came to the chief priests and Pharisees, who said to them, \"Why have you not brought Him?\"", 
                "num": 45
              }, 
              {
                "text": "The officers answered, \"No man ever spoke like this Man!\"", 
                "num": 46
              }, 
              {
                "text": "Then the Pharisees answered them, \"Are you also deceived?", 
                "num": 47
              }, 
              {
                "text": "Have any of the rulers or the Pharisees believed in Him?", 
                "num": 48
              }, 
              {
                "text": "But this crowd that does not know the law is accursed.\"", 
                "num": 49
              }, 
              {
                "text": "Nicodemus (he who came to Jesus by night, being one of them) said to them,", 
                "num": 50
              }, 
              {
                "text": "\"Does our law judge a man before it hears him and knows what he is doing?\"", 
                "num": 51
              }, 
              {
                "text": "They answered and said to him, \"Are you also from Galilee? Search and look, for no prophet has arisen out of Galilee.\"", 
                "num": 52
              }, 
              {
                "text": "And everyone went to his own house.", 
                "num": 53
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "But Jesus went to the Mount of Olives.", 
                "num": 1
              }, 
              {
                "text": "Now early in the morning He came again into the temple, and all the people came to Him; and He sat down and taught them.", 
                "num": 2
              }, 
              {
                "text": "Then the scribes and Pharisees brought to Him a woman caught in adultery. And when they had set her in the midst,", 
                "num": 3
              }, 
              {
                "text": "they said to Him, \"Teacher, this woman was caught in adultery, in the very act.", 
                "num": 4
              }, 
              {
                "text": "Now Moses, in the law, commanded us that such should be stoned. But what do You say?\"", 
                "num": 5
              }, 
              {
                "text": "This they said, testing Him, that they might have something of which to accuse Him. But Jesus stooped down and wrote on the ground with His finger, as though He did not hear.", 
                "num": 6
              }, 
              {
                "text": "So when they continued asking Him, He raised Himself up and said to them, \"He who is without sin among you, let him throw a stone at her first.\"", 
                "num": 7
              }, 
              {
                "text": "And again He stooped down and wrote on the ground.", 
                "num": 8
              }, 
              {
                "text": "Then those who heard it, being convicted by their conscience, went out one by one, beginning with the oldest even to the last. And Jesus was left alone, and the woman standing in the midst.", 
                "num": 9
              }, 
              {
                "text": "When Jesus had raised Himself up and saw no one but the woman, He said to her, \"Woman, where are those accusers of yours? Has no one condemned you?\"", 
                "num": 10
              }, 
              {
                "text": "She said, \"No one, Lord.\" And Jesus said to her, \"Neither do I condemn you; go and sin no more.\"", 
                "num": 11
              }, 
              {
                "text": "Then Jesus spoke to them again, saying, \"I am the light of the world. He who follows Me shall not walk in darkness, but have the light of life.\"", 
                "num": 12
              }, 
              {
                "text": "The Pharisees therefore said to Him, \"You bear witness of Yourself; Your witness is not true.\"", 
                "num": 13
              }, 
              {
                "text": "Jesus answered and said to them, \"Even if I bear witness of Myself, My witness is true, for I know where I came from and where I am going; but you do not know where I come from and where I am going.", 
                "num": 14
              }, 
              {
                "text": "You judge according to the flesh; I judge no one.", 
                "num": 15
              }, 
              {
                "text": "And yet if I do judge, My judgment is true; for I am not alone, but I am with the Father who sent Me.", 
                "num": 16
              }, 
              {
                "text": "It is also written in your law that the testimony of two men is true.", 
                "num": 17
              }, 
              {
                "text": "I am One who bears witness of Myself, and the Father who sent Me bears witness of Me.\"", 
                "num": 18
              }, 
              {
                "text": "Then they said to Him, \"Where is Your Father?\" Jesus answered, \"You know neither Me nor My Father. If you had known Me, you would have known My Father also.\"", 
                "num": 19
              }, 
              {
                "text": "These words Jesus spoke in the treasury, as He taught in the temple; and no one laid hands on Him, for His hour had not yet come.", 
                "num": 20
              }, 
              {
                "text": "Then Jesus said to them again, \"I am going away, and you will seek Me, and will die in your sin. Where I go you cannot come.\"", 
                "num": 21
              }, 
              {
                "text": "So the Jews said, \"Will He kill Himself, because He says, \"Where I go you cannot come'?\"", 
                "num": 22
              }, 
              {
                "text": "And He said to them, \"You are from beneath; I am from above. You are of this world; I am not of this world.", 
                "num": 23
              }, 
              {
                "text": "Therefore I said to you that you will die in your sins; for if you do not believe that I am He, you will die in your sins.\"", 
                "num": 24
              }, 
              {
                "text": "Then they said to Him, \"Who are You?\" And Jesus said to them, \"Just what I have been saying to you from the beginning.", 
                "num": 25
              }, 
              {
                "text": "I have many things to say and to judge concerning you, but He who sent Me is true; and I speak to the world those things which I heard from Him.\"", 
                "num": 26
              }, 
              {
                "text": "They did not understand that He spoke to them of the Father.", 
                "num": 27
              }, 
              {
                "text": "Then Jesus said to them, \"When you lift up the Son of Man, then you will know that I am He, and that I do nothing of Myself; but as My Father taught Me, I speak these things.", 
                "num": 28
              }, 
              {
                "text": "And He who sent Me is with Me. The Father has not left Me alone, for I always do those things that please Him.\"", 
                "num": 29
              }, 
              {
                "text": "As He spoke these words, many believed in Him.", 
                "num": 30
              }, 
              {
                "text": "Then Jesus said to those Jews who believed Him, \"If you abide in My word, you are My disciples indeed.", 
                "num": 31
              }, 
              {
                "text": "And you shall know the truth, and the truth shall make you free.\"", 
                "num": 32
              }, 
              {
                "text": "They answered Him, \"We are Abraham's descendants, and have never been in bondage to anyone. How can You say, \"You will be made free'?\"", 
                "num": 33
              }, 
              {
                "text": "Jesus answered them, \"Most assuredly, I say to you, whoever commits sin is a slave of sin.", 
                "num": 34
              }, 
              {
                "text": "And a slave does not abide in the house forever, but a son abides forever.", 
                "num": 35
              }, 
              {
                "text": "Therefore if the Son makes you free, you shall be free indeed.", 
                "num": 36
              }, 
              {
                "text": "\"I know that you are Abraham's descendants, but you seek to kill Me, because My word has no place in you.", 
                "num": 37
              }, 
              {
                "text": "I speak what I have seen with My Father, and you do what you have seen with your father.\"", 
                "num": 38
              }, 
              {
                "text": "They answered and said to Him, \"Abraham is our father.\" Jesus said to them, \"If you were Abraham's children, you would do the works of Abraham.", 
                "num": 39
              }, 
              {
                "text": "But now you seek to kill Me, a Man who has told you the truth which I heard from God. Abraham did not do this.", 
                "num": 40
              }, 
              {
                "text": "You do the deeds of your father.\" Then they said to Him, \"We were not born of fornication; we have one Father--God.\"", 
                "num": 41
              }, 
              {
                "text": "Jesus said to them, \"If God were your Father, you would love Me, for I proceeded forth and came from God; nor have I come of Myself, but He sent Me.", 
                "num": 42
              }, 
              {
                "text": "Why do you not understand My speech? Because you are not able to listen to My word.", 
                "num": 43
              }, 
              {
                "text": "You are of your father the devil, and the desires of your father you want to do. He was a murderer from the beginning, and does not stand in the truth, because there is no truth in him. When he speaks a lie, he speaks from his own resources, for he is a liar and the father of it.", 
                "num": 44
              }, 
              {
                "text": "But because I tell the truth, you do not believe Me.", 
                "num": 45
              }, 
              {
                "text": "Which of you convicts Me of sin? And if I tell the truth, why do you not believe Me?", 
                "num": 46
              }, 
              {
                "text": "He who is of God hears God's words; therefore you do not hear, because you are not of God.\"", 
                "num": 47
              }, 
              {
                "text": "Then the Jews answered and said to Him, \"Do we not say rightly that You are a Samaritan and have a demon?\"", 
                "num": 48
              }, 
              {
                "text": "Jesus answered, \"I do not have a demon; but I honor My Father, and you dishonor Me.", 
                "num": 49
              }, 
              {
                "text": "And I do not seek My own glory; there is One who seeks and judges.", 
                "num": 50
              }, 
              {
                "text": "Most assuredly, I say to you, if anyone keeps My word he shall never see death.\"", 
                "num": 51
              }, 
              {
                "text": "Then the Jews said to Him, \"Now we know that You have a demon! Abraham is dead, and the prophets; and You say, \"If anyone keeps My word he shall never taste death.'", 
                "num": 52
              }, 
              {
                "text": "Are You greater than our father Abraham, who is dead? And the prophets are dead. Who do You make Yourself out to be?\"", 
                "num": 53
              }, 
              {
                "text": "Jesus answered, \"If I honor Myself, My honor is nothing. It is My Father who honors Me, of whom you say that He is your God.", 
                "num": 54
              }, 
              {
                "text": "Yet you have not known Him, but I know Him. And if I say, \"I do not know Him,' I shall be a liar like you; but I do know Him and keep His word.", 
                "num": 55
              }, 
              {
                "text": "Your father Abraham rejoiced to see My day, and he saw it and was glad.\"", 
                "num": 56
              }, 
              {
                "text": "Then the Jews said to Him, \"You are not yet fifty years old, and have You seen Abraham?\"", 
                "num": 57
              }, 
              {
                "text": "Jesus said to them, \"Most assuredly, I say to you, before Abraham was, I AM.\"", 
                "num": 58
              }, 
              {
                "text": "Then they took up stones to throw at Him; but Jesus hid Himself and went out of the temple, going through the midst of them, and so passed by.", 
                "num": 59
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now as Jesus passed by, He saw a man who was blind from birth.", 
                "num": 1
              }, 
              {
                "text": "And His disciples asked Him, saying, \"Rabbi, who sinned, this man or his parents, that he was born blind?\"", 
                "num": 2
              }, 
              {
                "text": "Jesus answered, \"Neither this man nor his parents sinned, but that the works of God should be revealed in him.", 
                "num": 3
              }, 
              {
                "text": "I must work the works of Him who sent Me while it is day; the night is coming when no one can work.", 
                "num": 4
              }, 
              {
                "text": "As long as I am in the world, I am the light of the world.\"", 
                "num": 5
              }, 
              {
                "text": "When He had said these things, He spat on the ground and made clay with the saliva; and He anointed the eyes of the blind man with the clay.", 
                "num": 6
              }, 
              {
                "text": "And He said to him, \"Go, wash in the pool of Siloam\" (which is translated, Sent). So he went and washed, and came back seeing.", 
                "num": 7
              }, 
              {
                "text": "Therefore the neighbors and those who previously had seen that he was blind said, \"Is not this he who sat and begged?\"", 
                "num": 8
              }, 
              {
                "text": "Some said, \"This is he.\" Others said, \"He is like him.\" He said, \"I am he.\"", 
                "num": 9
              }, 
              {
                "text": "Therefore they said to him, \"How were your eyes opened?\"", 
                "num": 10
              }, 
              {
                "text": "He answered and said, \"A Man called Jesus made clay and anointed my eyes and said to me, \"Go to the pool of Siloam and wash.' So I went and washed, and I received sight.\"", 
                "num": 11
              }, 
              {
                "text": "Then they said to him, \"Where is He?\" He said, \"I do not know.\"", 
                "num": 12
              }, 
              {
                "text": "They brought him who formerly was blind to the Pharisees.", 
                "num": 13
              }, 
              {
                "text": "Now it was a Sabbath when Jesus made the clay and opened his eyes.", 
                "num": 14
              }, 
              {
                "text": "Then the Pharisees also asked him again how he had received his sight. He said to them, \"He put clay on my eyes, and I washed, and I see.\"", 
                "num": 15
              }, 
              {
                "text": "Therefore some of the Pharisees said, \"This Man is not from God, because He does not keep the Sabbath.\" Others said, \"How can a man who is a sinner do such signs?\" And there was a division among them.", 
                "num": 16
              }, 
              {
                "text": "They said to the blind man again, \"What do you say about Him because He opened your eyes?\" He said, \"He is a prophet.\"", 
                "num": 17
              }, 
              {
                "text": "But the Jews did not believe concerning him, that he had been blind and received his sight, until they called the parents of him who had received his sight.", 
                "num": 18
              }, 
              {
                "text": "And they asked them, saying, \"Is this your son, who you say was born blind? How then does he now see?\"", 
                "num": 19
              }, 
              {
                "text": "His parents answered them and said, \"We know that this is our son, and that he was born blind;", 
                "num": 20
              }, 
              {
                "text": "but by what means he now sees we do not know, or who opened his eyes we do not know. He is of age; ask him. He will speak for himself.\"", 
                "num": 21
              }, 
              {
                "text": "His parents said these things because they feared the Jews, for the Jews had agreed already that if anyone confessed that He was Christ, he would be put out of the synagogue.", 
                "num": 22
              }, 
              {
                "text": "Therefore his parents said, \"He is of age; ask him.\"", 
                "num": 23
              }, 
              {
                "text": "So they again called the man who was blind, and said to him, \"Give God the glory! We know that this Man is a sinner.\"", 
                "num": 24
              }, 
              {
                "text": "He answered and said, \"Whether He is a sinner or not I do not know. One thing I know: that though I was blind, now I see.\"", 
                "num": 25
              }, 
              {
                "text": "Then they said to him again, \"What did He do to you? How did He open your eyes?\"", 
                "num": 26
              }, 
              {
                "text": "He answered them, \"I told you already, and you did not listen. Why do you want to hear it again? Do you also want to become His disciples?\"", 
                "num": 27
              }, 
              {
                "text": "Then they reviled him and said, \"You are His disciple, but we are Moses' disciples.", 
                "num": 28
              }, 
              {
                "text": "We know that God spoke to Moses; as for this fellow, we do not know where He is from.\"", 
                "num": 29
              }, 
              {
                "text": "The man answered and said to them, \"Why, this is a marvelous thing, that you do not know where He is from; yet He has opened my eyes!", 
                "num": 30
              }, 
              {
                "text": "Now we know that God does not hear sinners; but if anyone is a worshiper of God and does His will, He hears him.", 
                "num": 31
              }, 
              {
                "text": "Since the world began it has been unheard of that anyone opened the eyes of one who was born blind.", 
                "num": 32
              }, 
              {
                "text": "If this Man were not from God, He could do nothing.\"", 
                "num": 33
              }, 
              {
                "text": "They answered and said to him, \"You were completely born in sins, and are you teaching us?\" And they cast him out.", 
                "num": 34
              }, 
              {
                "text": "Jesus heard that they had cast him out; and when He had found him, He said to him, \"Do you believe in the Son of God?\"", 
                "num": 35
              }, 
              {
                "text": "He answered and said, \"Who is He, Lord, that I may believe in Him?\"", 
                "num": 36
              }, 
              {
                "text": "And Jesus said to him, \"You have both seen Him and it is He who is talking with you.\"", 
                "num": 37
              }, 
              {
                "text": "Then he said, \"Lord, I believe!\" And he worshiped Him.", 
                "num": 38
              }, 
              {
                "text": "And Jesus said, \"For judgment I have come into this world, that those who do not see may see, and that those who see may be made blind.\"", 
                "num": 39
              }, 
              {
                "text": "Then some of the Pharisees who were with Him heard these words, and said to Him, \"Are we blind also?\"", 
                "num": 40
              }, 
              {
                "text": "Jesus said to them, \"If you were blind, you would have no sin; but now you say, \"We see.' Therefore your sin remains.", 
                "num": 41
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "\"Most assuredly, I say to you, he who does not enter the sheepfold by the door, but climbs up some other way, the same is a thief and a robber.", 
                "num": 1
              }, 
              {
                "text": "But he who enters by the door is the shepherd of the sheep.", 
                "num": 2
              }, 
              {
                "text": "To him the doorkeeper opens, and the sheep hear his voice; and he calls his own sheep by name and leads them out.", 
                "num": 3
              }, 
              {
                "text": "And when he brings out his own sheep, he goes before them; and the sheep follow him, for they know his voice.", 
                "num": 4
              }, 
              {
                "text": "Yet they will by no means follow a stranger, but will flee from him, for they do not know the voice of strangers.\"", 
                "num": 5
              }, 
              {
                "text": "Jesus used this illustration, but they did not understand the things which He spoke to them.", 
                "num": 6
              }, 
              {
                "text": "Then Jesus said to them again, \"Most assuredly, I say to you, I am the door of the sheep.", 
                "num": 7
              }, 
              {
                "text": "All who ever came before Me are thieves and robbers, but the sheep did not hear them.", 
                "num": 8
              }, 
              {
                "text": "I am the door. If anyone enters by Me, he will be saved, and will go in and out and find pasture.", 
                "num": 9
              }, 
              {
                "text": "The thief does not come except to steal, and to kill, and to destroy. I have come that they may have life, and that they may have it more abundantly.", 
                "num": 10
              }, 
              {
                "text": "\"I am the good shepherd. The good shepherd gives His life for the sheep.", 
                "num": 11
              }, 
              {
                "text": "But a hireling, he who is not the shepherd, one who does not own the sheep, sees the wolf coming and leaves the sheep and flees; and the wolf catches the sheep and scatters them.", 
                "num": 12
              }, 
              {
                "text": "The hireling flees because he is a hireling and does not care about the sheep.", 
                "num": 13
              }, 
              {
                "text": "I am the good shepherd; and I know My sheep, and am known by My own.", 
                "num": 14
              }, 
              {
                "text": "As the Father knows Me, even so I know the Father; and I lay down My life for the sheep.", 
                "num": 15
              }, 
              {
                "text": "And other sheep I have which are not of this fold; them also I must bring, and they will hear My voice; and there will be one flock and one shepherd.", 
                "num": 16
              }, 
              {
                "text": "\"Therefore My Father loves Me, because I lay down My life that I may take it again.", 
                "num": 17
              }, 
              {
                "text": "No one takes it from Me, but I lay it down of Myself. I have power to lay it down, and I have power to take it again. This command I have received from My Father.\"", 
                "num": 18
              }, 
              {
                "text": "Therefore there was a division again among the Jews because of these sayings.", 
                "num": 19
              }, 
              {
                "text": "And many of them said, \"He has a demon and is mad. Why do you listen to Him?\"", 
                "num": 20
              }, 
              {
                "text": "Others said, \"These are not the words of one who has a demon. Can a demon open the eyes of the blind?\"", 
                "num": 21
              }, 
              {
                "text": "Now it was the Feast of Dedication in Jerusalem, and it was winter.", 
                "num": 22
              }, 
              {
                "text": "And Jesus walked in the temple, in Solomon's porch.", 
                "num": 23
              }, 
              {
                "text": "Then the Jews surrounded Him and said to Him, \"How long do You keep us in doubt? If You are the Christ, tell us plainly.\"", 
                "num": 24
              }, 
              {
                "text": "Jesus answered them, \"I told you, and you do not believe. The works that I do in My Father's name, they bear witness of Me.", 
                "num": 25
              }, 
              {
                "text": "But you do not believe, because you are not of My sheep, as I said to you.", 
                "num": 26
              }, 
              {
                "text": "My sheep hear My voice, and I know them, and they follow Me.", 
                "num": 27
              }, 
              {
                "text": "And I give them eternal life, and they shall never perish; neither shall anyone snatch them out of My hand.", 
                "num": 28
              }, 
              {
                "text": "My Father, who has given them to Me, is greater than all; and no one is able to snatch them out of My Father's hand.", 
                "num": 29
              }, 
              {
                "text": "I and My Father are one.\"", 
                "num": 30
              }, 
              {
                "text": "Then the Jews took up stones again to stone Him.", 
                "num": 31
              }, 
              {
                "text": "Jesus answered them, \"Many good works I have shown you from My Father. For which of those works do you stone Me?\"", 
                "num": 32
              }, 
              {
                "text": "The Jews answered Him, saying, \"For a good work we do not stone You, but for blasphemy, and because You, being a Man, make Yourself God.\"", 
                "num": 33
              }, 
              {
                "text": "Jesus answered them, \"Is it not written in your law, \"I said, \"You are gods\"'?", 
                "num": 34
              }, 
              {
                "text": "If He called them gods, to whom the word of God came (and the Scripture cannot be broken),", 
                "num": 35
              }, 
              {
                "text": "do you say of Him whom the Father sanctified and sent into the world, \"You are blaspheming,' because I said, \"I am the Son of God'?", 
                "num": 36
              }, 
              {
                "text": "If I do not do the works of My Father, do not believe Me;", 
                "num": 37
              }, 
              {
                "text": "but if I do, though you do not believe Me, believe the works, that you may know and believe that the Father is in Me, and I in Him.\"", 
                "num": 38
              }, 
              {
                "text": "Therefore they sought again to seize Him, but He escaped out of their hand.", 
                "num": 39
              }, 
              {
                "text": "And He went away again beyond the Jordan to the place where John was baptizing at first, and there He stayed.", 
                "num": 40
              }, 
              {
                "text": "Then many came to Him and said, \"John performed no sign, but all the things that John spoke about this Man were true.\"", 
                "num": 41
              }, 
              {
                "text": "And many believed in Him there.", 
                "num": 42
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now a certain man was sick, Lazarus of Bethany, the town of Mary and her sister Martha.", 
                "num": 1
              }, 
              {
                "text": "It was that Mary who anointed the Lord with fragrant oil and wiped His feet with her hair, whose brother Lazarus was sick.", 
                "num": 2
              }, 
              {
                "text": "Therefore the sisters sent to Him, saying, \"Lord, behold, he whom You love is sick.\"", 
                "num": 3
              }, 
              {
                "text": "When Jesus heard that, He said, \"This sickness is not unto death, but for the glory of God, that the Son of God may be glorified through it.\"", 
                "num": 4
              }, 
              {
                "text": "Now Jesus loved Martha and her sister and Lazarus.", 
                "num": 5
              }, 
              {
                "text": "So, when He heard that he was sick, He stayed two more days in the place where He was.", 
                "num": 6
              }, 
              {
                "text": "Then after this He said to the disciples, \"Let us go to Judea again.\"", 
                "num": 7
              }, 
              {
                "text": "The disciples said to Him, \"Rabbi, lately the Jews sought to stone You, and are You going there again?\"", 
                "num": 8
              }, 
              {
                "text": "Jesus answered, \"Are there not twelve hours in the day? If anyone walks in the day, he does not stumble, because he sees the light of this world.", 
                "num": 9
              }, 
              {
                "text": "But if one walks in the night, he stumbles, because the light is not in him.\"", 
                "num": 10
              }, 
              {
                "text": "These things He said, and after that He said to them, \"Our friend Lazarus sleeps, but I go that I may wake him up.\"", 
                "num": 11
              }, 
              {
                "text": "Then His disciples said, \"Lord, if he sleeps he will get well.\"", 
                "num": 12
              }, 
              {
                "text": "However, Jesus spoke of his death, but they thought that He was speaking about taking rest in sleep.", 
                "num": 13
              }, 
              {
                "text": "Then Jesus said to them plainly, \"Lazarus is dead.", 
                "num": 14
              }, 
              {
                "text": "And I am glad for your sakes that I was not there, that you may believe. Nevertheless let us go to him.\"", 
                "num": 15
              }, 
              {
                "text": "Then Thomas, who is called the Twin, said to his fellow disciples, \"Let us also go, that we may die with Him.\"", 
                "num": 16
              }, 
              {
                "text": "So when Jesus came, He found that he had already been in the tomb four days.", 
                "num": 17
              }, 
              {
                "text": "Now Bethany was near Jerusalem, about two miles away.", 
                "num": 18
              }, 
              {
                "text": "And many of the Jews had joined the women around Martha and Mary, to comfort them concerning their brother.", 
                "num": 19
              }, 
              {
                "text": "Now Martha, as soon as she heard that Jesus was coming, went and met Him, but Mary was sitting in the house.", 
                "num": 20
              }, 
              {
                "text": "Now Martha said to Jesus, \"Lord, if You had been here, my brother would not have died.", 
                "num": 21
              }, 
              {
                "text": "But even now I know that whatever You ask of God, God will give You.\"", 
                "num": 22
              }, 
              {
                "text": "Jesus said to her, \"Your brother will rise again.\"", 
                "num": 23
              }, 
              {
                "text": "Martha said to Him, \"I know that he will rise again in the resurrection at the last day.\"", 
                "num": 24
              }, 
              {
                "text": "Jesus said to her, \"I am the resurrection and the life. He who believes in Me, though he may die, he shall live.", 
                "num": 25
              }, 
              {
                "text": "And whoever lives and believes in Me shall never die. Do you believe this?\"", 
                "num": 26
              }, 
              {
                "text": "She said to Him, \"Yes, Lord, I believe that You are the Christ, the Son of God, who is to come into the world.\"", 
                "num": 27
              }, 
              {
                "text": "And when she had said these things, she went her way and secretly called Mary her sister, saying, \"The Teacher has come and is calling for you.\"", 
                "num": 28
              }, 
              {
                "text": "As soon as she heard that, she arose quickly and came to Him.", 
                "num": 29
              }, 
              {
                "text": "Now Jesus had not yet come into the town, but was in the place where Martha met Him.", 
                "num": 30
              }, 
              {
                "text": "Then the Jews who were with her in the house, and comforting her, when they saw that Mary rose up quickly and went out, followed her, saying, \"She is going to the tomb to weep there.\"", 
                "num": 31
              }, 
              {
                "text": "Then, when Mary came where Jesus was, and saw Him, she fell down at His feet, saying to Him, \"Lord, if You had been here, my brother would not have died.\"", 
                "num": 32
              }, 
              {
                "text": "Therefore, when Jesus saw her weeping, and the Jews who came with her weeping, He groaned in the spirit and was troubled.", 
                "num": 33
              }, 
              {
                "text": "And He said, \"Where have you laid him?\" They said to Him, \"Lord, come and see.\"", 
                "num": 34
              }, 
              {
                "text": "Jesus wept.", 
                "num": 35
              }, 
              {
                "text": "Then the Jews said, \"See how He loved him!\"", 
                "num": 36
              }, 
              {
                "text": "And some of them said, \"Could not this Man, who opened the eyes of the blind, also have kept this man from dying?\"", 
                "num": 37
              }, 
              {
                "text": "Then Jesus, again groaning in Himself, came to the tomb. It was a cave, and a stone lay against it.", 
                "num": 38
              }, 
              {
                "text": "Jesus said, \"Take away the stone.\" Martha, the sister of him who was dead, said to Him, \"Lord, by this time there is a stench, for he has been dead four days.\"", 
                "num": 39
              }, 
              {
                "text": "Jesus said to her, \"Did I not say to you that if you would believe you would see the glory of God?\"", 
                "num": 40
              }, 
              {
                "text": "Then they took away the stone from the place where the dead man was lying. And Jesus lifted up His eyes and said, \"Father, I thank You that You have heard Me.", 
                "num": 41
              }, 
              {
                "text": "And I know that You always hear Me, but because of the people who are standing by I said this, that they may believe that You sent Me.\"", 
                "num": 42
              }, 
              {
                "text": "Now when He had said these things, He cried with a loud voice, \"Lazarus, come forth!\"", 
                "num": 43
              }, 
              {
                "text": "And he who had died came out bound hand and foot with graveclothes, and his face was wrapped with a cloth. Jesus said to them, \"Loose him, and let him go.\"", 
                "num": 44
              }, 
              {
                "text": "Then many of the Jews who had come to Mary, and had seen the things Jesus did, believed in Him.", 
                "num": 45
              }, 
              {
                "text": "But some of them went away to the Pharisees and told them the things Jesus did.", 
                "num": 46
              }, 
              {
                "text": "Then the chief priests and the Pharisees gathered a council and said, \"What shall we do? For this Man works many signs.", 
                "num": 47
              }, 
              {
                "text": "If we let Him alone like this, everyone will believe in Him, and the Romans will come and take away both our place and nation.\"", 
                "num": 48
              }, 
              {
                "text": "And one of them, Caiaphas, being high priest that year, said to them, \"You know nothing at all,", 
                "num": 49
              }, 
              {
                "text": "nor do you consider that it is expedient for us that one man should die for the people, and not that the whole nation should perish.\"", 
                "num": 50
              }, 
              {
                "text": "Now this he did not say on his own authority; but being high priest that year he prophesied that Jesus would die for the nation,", 
                "num": 51
              }, 
              {
                "text": "and not for that nation only, but also that He would gather together in one the children of God who were scattered abroad.", 
                "num": 52
              }, 
              {
                "text": "Then, from that day on, they plotted to put Him to death.", 
                "num": 53
              }, 
              {
                "text": "Therefore Jesus no longer walked openly among the Jews, but went from there into the country near the wilderness, to a city called Ephraim, and there remained with His disciples.", 
                "num": 54
              }, 
              {
                "text": "And the Passover of the Jews was near, and many went from the country up to Jerusalem before the Passover, to purify themselves.", 
                "num": 55
              }, 
              {
                "text": "Then they sought Jesus, and spoke among themselves as they stood in the temple, \"What do you think--that He will not come to the feast?\"", 
                "num": 56
              }, 
              {
                "text": "Now both the chief priests and the Pharisees had given a command, that if anyone knew where He was, he should report it, that they might seize Him.", 
                "num": 57
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Then, six days before the Passover, Jesus came to Bethany, where Lazarus was who had been dead, whom He had raised from the dead.", 
                "num": 1
              }, 
              {
                "text": "There they made Him a supper; and Martha served, but Lazarus was one of those who sat at the table with Him.", 
                "num": 2
              }, 
              {
                "text": "Then Mary took a pound of very costly oil of spikenard, anointed the feet of Jesus, and wiped His feet with her hair. And the house was filled with the fragrance of the oil.", 
                "num": 3
              }, 
              {
                "text": "But one of His disciples, Judas Iscariot, Simon's son, who would betray Him, said,", 
                "num": 4
              }, 
              {
                "text": "\"Why was this fragrant oil not sold for three hundred denarii and given to the poor?\"", 
                "num": 5
              }, 
              {
                "text": "This he said, not that he cared for the poor, but because he was a thief, and had the money box; and he used to take what was put in it.", 
                "num": 6
              }, 
              {
                "text": "But Jesus said, \"Let her alone; she has kept this for the day of My burial.", 
                "num": 7
              }, 
              {
                "text": "For the poor you have with you always, but Me you do not have always.\"", 
                "num": 8
              }, 
              {
                "text": "Now a great many of the Jews knew that He was there; and they came, not for Jesus' sake only, but that they might also see Lazarus, whom He had raised from the dead.", 
                "num": 9
              }, 
              {
                "text": "But the chief priests plotted to put Lazarus to death also,", 
                "num": 10
              }, 
              {
                "text": "because on account of him many of the Jews went away and believed in Jesus.", 
                "num": 11
              }, 
              {
                "text": "The next day a great multitude that had come to the feast, when they heard that Jesus was coming to Jerusalem,", 
                "num": 12
              }, 
              {
                "text": "took branches of palm trees and went out to meet Him, and cried out: \"Hosanna! \"Blessed is He who comes in the name of the LORD!' The King of Israel!\"", 
                "num": 13
              }, 
              {
                "text": "Then Jesus, when He had found a young donkey, sat on it; as it is written:", 
                "num": 14
              }, 
              {
                "text": "\"Fear not, daughter of Zion; Behold, your King is coming, Sitting on a donkey's colt.\"", 
                "num": 15
              }, 
              {
                "text": "His disciples did not understand these things at first; but when Jesus was glorified, then they remembered that these things were written about Him and that they had done these things to Him.", 
                "num": 16
              }, 
              {
                "text": "Therefore the people, who were with Him when He called Lazarus out of his tomb and raised him from the dead, bore witness.", 
                "num": 17
              }, 
              {
                "text": "For this reason the people also met Him, because they heard that He had done this sign.", 
                "num": 18
              }, 
              {
                "text": "The Pharisees therefore said among themselves, \"You see that you are accomplishing nothing. Look, the world has gone after Him!\"", 
                "num": 19
              }, 
              {
                "text": "Now there were certain Greeks among those who came up to worship at the feast.", 
                "num": 20
              }, 
              {
                "text": "Then they came to Philip, who was from Bethsaida of Galilee, and asked him, saying, \"Sir, we wish to see Jesus.\"", 
                "num": 21
              }, 
              {
                "text": "Philip came and told Andrew, and in turn Andrew and Philip told Jesus.", 
                "num": 22
              }, 
              {
                "text": "But Jesus answered them, saying, \"The hour has come that the Son of Man should be glorified.", 
                "num": 23
              }, 
              {
                "text": "Most assuredly, I say to you, unless a grain of wheat falls into the ground and dies, it remains alone; but if it dies, it produces much grain.", 
                "num": 24
              }, 
              {
                "text": "He who loves his life will lose it, and he who hates his life in this world will keep it for eternal life.", 
                "num": 25
              }, 
              {
                "text": "If anyone serves Me, let him follow Me; and where I am, there My servant will be also. If anyone serves Me, him My Father will honor.", 
                "num": 26
              }, 
              {
                "text": "\"Now My soul is troubled, and what shall I say? \"Father, save Me from this hour'? But for this purpose I came to this hour.", 
                "num": 27
              }, 
              {
                "text": "Father, glorify Your name.\" Then a voice came from heaven, saying, \"I have both glorified it and will glorify it again.\"", 
                "num": 28
              }, 
              {
                "text": "Therefore the people who stood by and heard it said that it had thundered. Others said, \"An angel has spoken to Him.\"", 
                "num": 29
              }, 
              {
                "text": "Jesus answered and said, \"This voice did not come because of Me, but for your sake.", 
                "num": 30
              }, 
              {
                "text": "Now is the judgment of this world; now the ruler of this world will be cast out.", 
                "num": 31
              }, 
              {
                "text": "And I, if I am lifted up from the earth, will draw all peoples to Myself.\"", 
                "num": 32
              }, 
              {
                "text": "This He said, signifying by what death He would die.", 
                "num": 33
              }, 
              {
                "text": "The people answered Him, \"We have heard from the law that the Christ remains forever; and how can You say, \"The Son of Man must be lifted up'? Who is this Son of Man?\"", 
                "num": 34
              }, 
              {
                "text": "Then Jesus said to them, \"A little while longer the light is with you. Walk while you have the light, lest darkness overtake you; he who walks in darkness does not know where he is going.", 
                "num": 35
              }, 
              {
                "text": "While you have the light, believe in the light, that you may become sons of light.\" These things Jesus spoke, and departed, and was hidden from them.", 
                "num": 36
              }, 
              {
                "text": "But although He had done so many signs before them, they did not believe in Him,", 
                "num": 37
              }, 
              {
                "text": "that the word of Isaiah the prophet might be fulfilled, which he spoke: \"Lord, who has believed our report? And to whom has the arm of the LORD been revealed?\"", 
                "num": 38
              }, 
              {
                "text": "Therefore they could not believe, because Isaiah said again:", 
                "num": 39
              }, 
              {
                "text": "\"He has blinded their eyes and hardened their hearts, Lest they should see with their eyes, Lest they should understand with their hearts and turn, So that I should heal them.\"", 
                "num": 40
              }, 
              {
                "text": "These things Isaiah said when he saw His glory and spoke of Him.", 
                "num": 41
              }, 
              {
                "text": "Nevertheless even among the rulers many believed in Him, but because of the Pharisees they did not confess Him, lest they should be put out of the synagogue;", 
                "num": 42
              }, 
              {
                "text": "for they loved the praise of men more than the praise of God.", 
                "num": 43
              }, 
              {
                "text": "Then Jesus cried out and said, \"He who believes in Me, believes not in Me but in Him who sent Me.", 
                "num": 44
              }, 
              {
                "text": "And he who sees Me sees Him who sent Me.", 
                "num": 45
              }, 
              {
                "text": "I have come as a light into the world, that whoever believes in Me should not abide in darkness.", 
                "num": 46
              }, 
              {
                "text": "And if anyone hears My words and does not believe, I do not judge him; for I did not come to judge the world but to save the world.", 
                "num": 47
              }, 
              {
                "text": "He who rejects Me, and does not receive My words, has that which judges him--the word that I have spoken will judge him in the last day.", 
                "num": 48
              }, 
              {
                "text": "For I have not spoken on My own authority; but the Father who sent Me gave Me a command, what I should say and what I should speak.", 
                "num": 49
              }, 
              {
                "text": "And I know that His command is everlasting life. Therefore, whatever I speak, just as the Father has told Me, so I speak.\"", 
                "num": 50
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Now before the Feast of the Passover, when Jesus knew that His hour had come that He should depart from this world to the Father, having loved His own who were in the world, He loved them to the end.", 
                "num": 1
              }, 
              {
                "text": "And supper being ended, the devil having already put it into the heart of Judas Iscariot, Simon's son, to betray Him,", 
                "num": 2
              }, 
              {
                "text": "Jesus, knowing that the Father had given all things into His hands, and that He had come from God and was going to God,", 
                "num": 3
              }, 
              {
                "text": "rose from supper and laid aside His garments, took a towel and girded Himself.", 
                "num": 4
              }, 
              {
                "text": "After that, He poured water into a basin and began to wash the disciples' feet, and to wipe them with the towel with which He was girded.", 
                "num": 5
              }, 
              {
                "text": "Then He came to Simon Peter. And Peter said to Him, \"Lord, are You washing my feet?\"", 
                "num": 6
              }, 
              {
                "text": "Jesus answered and said to him, \"What I am doing you do not understand now, but you will know after this.\"", 
                "num": 7
              }, 
              {
                "text": "Peter said to Him, \"You shall never wash my feet!\" Jesus answered him, \"If I do not wash you, you have no part with Me.\"", 
                "num": 8
              }, 
              {
                "text": "Simon Peter said to Him, \"Lord, not my feet only, but also my hands and my head!\"", 
                "num": 9
              }, 
              {
                "text": "Jesus said to him, \"He who is bathed needs only to wash his feet, but is completely clean; and you are clean, but not all of you.\"", 
                "num": 10
              }, 
              {
                "text": "For He knew who would betray Him; therefore He said, \"You are not all clean.\"", 
                "num": 11
              }, 
              {
                "text": "So when He had washed their feet, taken His garments, and sat down again, He said to them, \"Do you know what I have done to you?", 
                "num": 12
              }, 
              {
                "text": "You call Me Teacher and Lord, and you say well, for so I am.", 
                "num": 13
              }, 
              {
                "text": "If I then, your Lord and Teacher, have washed your feet, you also ought to wash one another's feet.", 
                "num": 14
              }, 
              {
                "text": "For I have given you an example, that you should do as I have done to you.", 
                "num": 15
              }, 
              {
                "text": "Most assuredly, I say to you, a servant is not greater than his master; nor is he who is sent greater than he who sent him.", 
                "num": 16
              }, 
              {
                "text": "If you know these things, blessed are you if you do them.", 
                "num": 17
              }, 
              {
                "text": "\"I do not speak concerning all of you. I know whom I have chosen; but that the Scripture may be fulfilled, \"He who eats bread with Me has lifted up his heel against Me.'", 
                "num": 18
              }, 
              {
                "text": "Now I tell you before it comes, that when it does come to pass, you may believe that I am He.", 
                "num": 19
              }, 
              {
                "text": "Most assuredly, I say to you, he who receives whomever I send receives Me; and he who receives Me receives Him who sent Me.\"", 
                "num": 20
              }, 
              {
                "text": "When Jesus had said these things, He was troubled in spirit, and testified and said, \"Most assuredly, I say to you, one of you will betray Me.\"", 
                "num": 21
              }, 
              {
                "text": "Then the disciples looked at one another, perplexed about whom He spoke.", 
                "num": 22
              }, 
              {
                "text": "Now there was leaning on Jesus' bosom one of His disciples, whom Jesus loved.", 
                "num": 23
              }, 
              {
                "text": "Simon Peter therefore motioned to him to ask who it was of whom He spoke.", 
                "num": 24
              }, 
              {
                "text": "Then, leaning back on Jesus' breast, he said to Him, \"Lord, who is it?\"", 
                "num": 25
              }, 
              {
                "text": "Jesus answered, \"It is he to whom I shall give a piece of bread when I have dipped it.\" And having dipped the bread, He gave it to Judas Iscariot, the son of Simon.", 
                "num": 26
              }, 
              {
                "text": "Now after the piece of bread, Satan entered him. Then Jesus said to him, \"What you do, do quickly.\"", 
                "num": 27
              }, 
              {
                "text": "But no one at the table knew for what reason He said this to him.", 
                "num": 28
              }, 
              {
                "text": "For some thought, because Judas had the money box, that Jesus had said to him, \"Buy those things we need for the feast,\" or that he should give something to the poor.", 
                "num": 29
              }, 
              {
                "text": "Having received the piece of bread, he then went out immediately. And it was night.", 
                "num": 30
              }, 
              {
                "text": "So, when he had gone out, Jesus said, \"Now the Son of Man is glorified, and God is glorified in Him.", 
                "num": 31
              }, 
              {
                "text": "If God is glorified in Him, God will also glorify Him in Himself, and glorify Him immediately.", 
                "num": 32
              }, 
              {
                "text": "Little children, I shall be with you a little while longer. You will seek Me; and as I said to the Jews, \"Where I am going, you cannot come,' so now I say to you.", 
                "num": 33
              }, 
              {
                "text": "A new commandment I give to you, that you love one another; as I have loved you, that you also love one another.", 
                "num": 34
              }, 
              {
                "text": "By this all will know that you are My disciples, if you have love for one another.\"", 
                "num": 35
              }, 
              {
                "text": "Simon Peter said to Him, \"Lord, where are You going?\" Jesus answered him, \"Where I am going you cannot follow Me now, but you shall follow Me afterward.\"", 
                "num": 36
              }, 
              {
                "text": "Peter said to Him, \"Lord, why can I not follow You now? I will lay down my life for Your sake.\"", 
                "num": 37
              }, 
              {
                "text": "Jesus answered him, \"Will you lay down your life for My sake? Most assuredly, I say to you, the rooster shall not crow till you have denied Me three times.", 
                "num": 38
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "\"Let not your heart be troubled; you believe in God, believe also in Me.", 
                "num": 1
              }, 
              {
                "text": "In My Father's house are many mansions; if it were not so, I would have told you. I go to prepare a place for you.", 
                "num": 2
              }, 
              {
                "text": "And if I go and prepare a place for you, I will come again and receive you to Myself; that where I am, there you may be also.", 
                "num": 3
              }, 
              {
                "text": "And where I go you know, and the way you know.\"", 
                "num": 4
              }, 
              {
                "text": "Thomas said to Him, \"Lord, we do not know where You are going, and how can we know the way?\"", 
                "num": 5
              }, 
              {
                "text": "Jesus said to him, \"I am the way, the truth, and the life. No one comes to the Father except through Me.", 
                "num": 6
              }, 
              {
                "text": "\"If you had known Me, you would have known My Father also; and from now on you know Him and have seen Him.\"", 
                "num": 7
              }, 
              {
                "text": "Philip said to Him, \"Lord, show us the Father, and it is sufficient for us.\"", 
                "num": 8
              }, 
              {
                "text": "Jesus said to him, \"Have I been with you so long, and yet you have not known Me, Philip? He who has seen Me has seen the Father; so how can you say, \"Show us the Father'?", 
                "num": 9
              }, 
              {
                "text": "Do you not believe that I am in the Father, and the Father in Me? The words that I speak to you I do not speak on My own authority; but the Father who dwells in Me does the works.", 
                "num": 10
              }, 
              {
                "text": "Believe Me that I am in the Father and the Father in Me, or else believe Me for the sake of the works themselves.", 
                "num": 11
              }, 
              {
                "text": "\"Most assuredly, I say to you, he who believes in Me, the works that I do he will do also; and greater works than these he will do, because I go to My Father.", 
                "num": 12
              }, 
              {
                "text": "And whatever you ask in My name, that I will do, that the Father may be glorified in the Son.", 
                "num": 13
              }, 
              {
                "text": "If you ask anything in My name, I will do it.", 
                "num": 14
              }, 
              {
                "text": "\"If you love Me, keep My commandments.", 
                "num": 15
              }, 
              {
                "text": "And I will pray the Father, and He will give you another Helper, that He may abide with you forever--", 
                "num": 16
              }, 
              {
                "text": "the Spirit of truth, whom the world cannot receive, because it neither sees Him nor knows Him; but you know Him, for He dwells with you and will be in you.", 
                "num": 17
              }, 
              {
                "text": "I will not leave you orphans; I will come to you.", 
                "num": 18
              }, 
              {
                "text": "\"A little while longer and the world will see Me no more, but you will see Me. Because I live, you will live also.", 
                "num": 19
              }, 
              {
                "text": "At that day you will know that I am in My Father, and you in Me, and I in you.", 
                "num": 20
              }, 
              {
                "text": "He who has My commandments and keeps them, it is he who loves Me. And he who loves Me will be loved by My Father, and I will love him and manifest Myself to him.\"", 
                "num": 21
              }, 
              {
                "text": "Judas (not Iscariot) said to Him, \"Lord, how is it that You will manifest Yourself to us, and not to the world?\"", 
                "num": 22
              }, 
              {
                "text": "Jesus answered and said to him, \"If anyone loves Me, he will keep My word; and My Father will love him, and We will come to him and make Our home with him.", 
                "num": 23
              }, 
              {
                "text": "He who does not love Me does not keep My words; and the word which you hear is not Mine but the Father's who sent Me.", 
                "num": 24
              }, 
              {
                "text": "\"These things I have spoken to you while being present with you.", 
                "num": 25
              }, 
              {
                "text": "But the Helper, the Holy Spirit, whom the Father will send in My name, He will teach you all things, and bring to your remembrance all things that I said to you.", 
                "num": 26
              }, 
              {
                "text": "Peace I leave with you, My peace I give to you; not as the world gives do I give to you. Let not your heart be troubled, neither let it be afraid.", 
                "num": 27
              }, 
              {
                "text": "You have heard Me say to you, \"I am going away and coming back to you.' If you loved Me, you would rejoice because I said, \"I am going to the Father,' for My Father is greater than I.", 
                "num": 28
              }, 
              {
                "text": "\"And now I have told you before it comes, that when it does come to pass, you may believe.", 
                "num": 29
              }, 
              {
                "text": "I will no longer talk much with you, for the ruler of this world is coming, and he has nothing in Me.", 
                "num": 30
              }, 
              {
                "text": "But that the world may know that I love the Father, and as the Father gave Me commandment, so I do. Arise, let us go from here.", 
                "num": 31
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "\"I am the true vine, and My Father is the vinedresser.", 
                "num": 1
              }, 
              {
                "text": "Every branch in Me that does not bear fruit He takes away; and every branch that bears fruit He prunes, that it may bear more fruit.", 
                "num": 2
              }, 
              {
                "text": "You are already clean because of the word which I have spoken to you.", 
                "num": 3
              }, 
              {
                "text": "Abide in Me, and I in you. As the branch cannot bear fruit of itself, unless it abides in the vine, neither can you, unless you abide in Me.", 
                "num": 4
              }, 
              {
                "text": "\"I am the vine, you are the branches. He who abides in Me, and I in him, bears much fruit; for without Me you can do nothing.", 
                "num": 5
              }, 
              {
                "text": "If anyone does not abide in Me, he is cast out as a branch and is withered; and they gather them and throw them into the fire, and they are burned.", 
                "num": 6
              }, 
              {
                "text": "If you abide in Me, and My words abide in you, you will ask what you desire, and it shall be done for you.", 
                "num": 7
              }, 
              {
                "text": "By this My Father is glorified, that you bear much fruit; so you will be My disciples.", 
                "num": 8
              }, 
              {
                "text": "\"As the Father loved Me, I also have loved you; abide in My love.", 
                "num": 9
              }, 
              {
                "text": "If you keep My commandments, you will abide in My love, just as I have kept My Father's commandments and abide in His love.", 
                "num": 10
              }, 
              {
                "text": "\"These things I have spoken to you, that My joy may remain in you, and that your joy may be full.", 
                "num": 11
              }, 
              {
                "text": "This is My commandment, that you love one another as I have loved you.", 
                "num": 12
              }, 
              {
                "text": "Greater love has no one than this, than to lay down one's life for his friends.", 
                "num": 13
              }, 
              {
                "text": "You are My friends if you do whatever I command you.", 
                "num": 14
              }, 
              {
                "text": "No longer do I call you servants, for a servant does not know what his master is doing; but I have called you friends, for all things that I heard from My Father I have made known to you.", 
                "num": 15
              }, 
              {
                "text": "You did not choose Me, but I chose you and appointed you that you should go and bear fruit, and that your fruit should remain, that whatever you ask the Father in My name He may give you.", 
                "num": 16
              }, 
              {
                "text": "These things I command you, that you love one another.", 
                "num": 17
              }, 
              {
                "text": "\"If the world hates you, you know that it hated Me before it hated you.", 
                "num": 18
              }, 
              {
                "text": "If you were of the world, the world would love its own. Yet because you are not of the world, but I chose you out of the world, therefore the world hates you.", 
                "num": 19
              }, 
              {
                "text": "Remember the word that I said to you, \"A servant is not greater than his master.' If they persecuted Me, they will also persecute you. If they kept My word, they will keep yours also.", 
                "num": 20
              }, 
              {
                "text": "But all these things they will do to you for My name's sake, because they do not know Him who sent Me.", 
                "num": 21
              }, 
              {
                "text": "If I had not come and spoken to them, they would have no sin, but now they have no excuse for their sin.", 
                "num": 22
              }, 
              {
                "text": "He who hates Me hates My Father also.", 
                "num": 23
              }, 
              {
                "text": "If I had not done among them the works which no one else did, they would have no sin; but now they have seen and also hated both Me and My Father.", 
                "num": 24
              }, 
              {
                "text": "But this happened that the word might be fulfilled which is written in their law, \"They hated Me without a cause.'", 
                "num": 25
              }, 
              {
                "text": "\"But when the Helper comes, whom I shall send to you from the Father, the Spirit of truth who proceeds from the Father, He will testify of Me.", 
                "num": 26
              }, 
              {
                "text": "And you also will bear witness, because you have been with Me from the beginning.", 
                "num": 27
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "\"These things I have spoken to you, that you should not be made to stumble.", 
                "num": 1
              }, 
              {
                "text": "They will put you out of the synagogues; yes, the time is coming that whoever kills you will think that he offers God service.", 
                "num": 2
              }, 
              {
                "text": "And these things they will do to you because they have not known the Father nor Me.", 
                "num": 3
              }, 
              {
                "text": "But these things I have told you, that when the time comes, you may remember that I told you of them. \"And these things I did not say to you at the beginning, because I was with you.", 
                "num": 4
              }, 
              {
                "text": "\"But now I go away to Him who sent Me, and none of you asks Me, \"Where are You going?'", 
                "num": 5
              }, 
              {
                "text": "But because I have said these things to you, sorrow has filled your heart.", 
                "num": 6
              }, 
              {
                "text": "Nevertheless I tell you the truth. It is to your advantage that I go away; for if I do not go away, the Helper will not come to you; but if I depart, I will send Him to you.", 
                "num": 7
              }, 
              {
                "text": "And when He has come, He will convict the world of sin, and of righteousness, and of judgment:", 
                "num": 8
              }, 
              {
                "text": "of sin, because they do not believe in Me;", 
                "num": 9
              }, 
              {
                "text": "of righteousness, because I go to My Father and you see Me no more;", 
                "num": 10
              }, 
              {
                "text": "of judgment, because the ruler of this world is judged.", 
                "num": 11
              }, 
              {
                "text": "\"I still have many things to say to you, but you cannot bear them now.", 
                "num": 12
              }, 
              {
                "text": "However, when He, the Spirit of truth, has come, He will guide you into all truth; for He will not speak on His own authority, but whatever He hears He will speak; and He will tell you things to come.", 
                "num": 13
              }, 
              {
                "text": "He will glorify Me, for He will take of what is Mine and declare it to you.", 
                "num": 14
              }, 
              {
                "text": "All things that the Father has are Mine. Therefore I said that He will take of Mine and declare it to you.", 
                "num": 15
              }, 
              {
                "text": "\"A little while, and you will not see Me; and again a little while, and you will see Me, because I go to the Father.\"", 
                "num": 16
              }, 
              {
                "text": "Then some of His disciples said among themselves, \"What is this that He says to us, \"A little while, and you will not see Me; and again a little while, and you will see Me'; and, \"because I go to the Father'?\"", 
                "num": 17
              }, 
              {
                "text": "They said therefore, \"What is this that He says, \"A little while'? We do not know what He is saying.\"", 
                "num": 18
              }, 
              {
                "text": "Now Jesus knew that they desired to ask Him, and He said to them, \"Are you inquiring among yourselves about what I said, \"A little while, and you will not see Me; and again a little while, and you will see Me'?", 
                "num": 19
              }, 
              {
                "text": "Most assuredly, I say to you that you will weep and lament, but the world will rejoice; and you will be sorrowful, but your sorrow will be turned into joy.", 
                "num": 20
              }, 
              {
                "text": "A woman, when she is in labor, has sorrow because her hour has come; but as soon as she has given birth to the child, she no longer remembers the anguish, for joy that a human being has been born into the world.", 
                "num": 21
              }, 
              {
                "text": "Therefore you now have sorrow; but I will see you again and your heart will rejoice, and your joy no one will take from you.", 
                "num": 22
              }, 
              {
                "text": "\"And in that day you will ask Me nothing. Most assuredly, I say to you, whatever you ask the Father in My name He will give you.", 
                "num": 23
              }, 
              {
                "text": "Until now you have asked nothing in My name. Ask, and you will receive, that your joy may be full.", 
                "num": 24
              }, 
              {
                "text": "\"These things I have spoken to you in figurative language; but the time is coming when I will no longer speak to you in figurative language, but I will tell you plainly about the Father.", 
                "num": 25
              }, 
              {
                "text": "In that day you will ask in My name, and I do not say to you that I shall pray the Father for you;", 
                "num": 26
              }, 
              {
                "text": "for the Father Himself loves you, because you have loved Me, and have believed that I came forth from God.", 
                "num": 27
              }, 
              {
                "text": "I came forth from the Father and have come into the world. Again, I leave the world and go to the Father.\"", 
                "num": 28
              }, 
              {
                "text": "His disciples said to Him, \"See, now You are speaking plainly, and using no figure of speech!", 
                "num": 29
              }, 
              {
                "text": "Now we are sure that You know all things, and have no need that anyone should question You. By this we believe that You came forth from God.\"", 
                "num": 30
              }, 
              {
                "text": "Jesus answered them, \"Do you now believe?", 
                "num": 31
              }, 
              {
                "text": "Indeed the hour is coming, yes, has now come, that you will be scattered, each to his own, and will leave Me alone. And yet I am not alone, because the Father is with Me.", 
                "num": 32
              }, 
              {
                "text": "These things I have spoken to you, that in Me you may have peace. In the world you will have tribulation; but be of good cheer, I have overcome the world.\"", 
                "num": 33
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Jesus spoke these words, lifted up His eyes to heaven, and said: \"Father, the hour has come. Glorify Your Son, that Your Son also may glorify You,", 
                "num": 1
              }, 
              {
                "text": "as You have given Him authority over all flesh, that He should give eternal life to as many as You have given Him.", 
                "num": 2
              }, 
              {
                "text": "And this is eternal life, that they may know You, the only true God, and Jesus Christ whom You have sent.", 
                "num": 3
              }, 
              {
                "text": "I have glorified You on the earth. I have finished the work which You have given Me to do.", 
                "num": 4
              }, 
              {
                "text": "And now, O Father, glorify Me together with Yourself, with the glory which I had with You before the world was.", 
                "num": 5
              }, 
              {
                "text": "\"I have manifested Your name to the men whom You have given Me out of the world. They were Yours, You gave them to Me, and they have kept Your word.", 
                "num": 6
              }, 
              {
                "text": "Now they have known that all things which You have given Me are from You.", 
                "num": 7
              }, 
              {
                "text": "For I have given to them the words which You have given Me; and they have received them, and have known surely that I came forth from You; and they have believed that You sent Me.", 
                "num": 8
              }, 
              {
                "text": "\"I pray for them. I do not pray for the world but for those whom You have given Me, for they are Yours.", 
                "num": 9
              }, 
              {
                "text": "And all Mine are Yours, and Yours are Mine, and I am glorified in them.", 
                "num": 10
              }, 
              {
                "text": "Now I am no longer in the world, but these are in the world, and I come to You. Holy Father, keep through Your name those whom You have given Me, that they may be one as We are.", 
                "num": 11
              }, 
              {
                "text": "While I was with them in the world, I kept them in Your name. Those whom You gave Me I have kept; and none of them is lost except the son of perdition, that the Scripture might be fulfilled.", 
                "num": 12
              }, 
              {
                "text": "But now I come to You, and these things I speak in the world, that they may have My joy fulfilled in themselves.", 
                "num": 13
              }, 
              {
                "text": "I have given them Your word; and the world has hated them because they are not of the world, just as I am not of the world.", 
                "num": 14
              }, 
              {
                "text": "I do not pray that You should take them out of the world, but that You should keep them from the evil one.", 
                "num": 15
              }, 
              {
                "text": "They are not of the world, just as I am not of the world.", 
                "num": 16
              }, 
              {
                "text": "Sanctify them by Your truth. Your word is truth.", 
                "num": 17
              }, 
              {
                "text": "As You sent Me into the world, I also have sent them into the world.", 
                "num": 18
              }, 
              {
                "text": "And for their sakes I sanctify Myself, that they also may be sanctified by the truth.", 
                "num": 19
              }, 
              {
                "text": "\"I do not pray for these alone, but also for those who will believe in Me through their word;", 
                "num": 20
              }, 
              {
                "text": "that they all may be one, as You, Father, are in Me, and I in You; that they also may be one in Us, that the world may believe that You sent Me.", 
                "num": 21
              }, 
              {
                "text": "And the glory which You gave Me I have given them, that they may be one just as We are one:", 
                "num": 22
              }, 
              {
                "text": "I in them, and You in Me; that they may be made perfect in one, and that the world may know that You have sent Me, and have loved them as You have loved Me.", 
                "num": 23
              }, 
              {
                "text": "\"Father, I desire that they also whom You gave Me may be with Me where I am, that they may behold My glory which You have given Me; for You loved Me before the foundation of the world.", 
                "num": 24
              }, 
              {
                "text": "O righteous Father! The world has not known You, but I have known You; and these have known that You sent Me.", 
                "num": 25
              }, 
              {
                "text": "And I have declared to them Your name, and will declare it, that the love with which You loved Me may be in them, and I in them.\"", 
                "num": 26
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "When Jesus had spoken these words, He went out with His disciples over the Brook Kidron, where there was a garden, which He and His disciples entered.", 
                "num": 1
              }, 
              {
                "text": "And Judas, who betrayed Him, also knew the place; for Jesus often met there with His disciples.", 
                "num": 2
              }, 
              {
                "text": "Then Judas, having received a detachment of troops, and officers from the chief priests and Pharisees, came there with lanterns, torches, and weapons.", 
                "num": 3
              }, 
              {
                "text": "Jesus therefore, knowing all things that would come upon Him, went forward and said to them, \"Whom are you seeking?\"", 
                "num": 4
              }, 
              {
                "text": "They answered Him, \"Jesus of Nazareth.\" Jesus said to them, \"I am He.\" And Judas, who betrayed Him, also stood with them.", 
                "num": 5
              }, 
              {
                "text": "Now when He said to them, \"I am He,\" they drew back and fell to the ground.", 
                "num": 6
              }, 
              {
                "text": "Then He asked them again, \"Whom are you seeking?\" And they said, \"Jesus of Nazareth.\"", 
                "num": 7
              }, 
              {
                "text": "Jesus answered, \"I have told you that I am He. Therefore, if you seek Me, let these go their way,\"", 
                "num": 8
              }, 
              {
                "text": "that the saying might be fulfilled which He spoke, \"Of those whom You gave Me I have lost none.\"", 
                "num": 9
              }, 
              {
                "text": "Then Simon Peter, having a sword, drew it and struck the high priest's servant, and cut off his right ear. The servant's name was Malchus.", 
                "num": 10
              }, 
              {
                "text": "So Jesus said to Peter, \"Put your sword into the sheath. Shall I not drink the cup which My Father has given Me?\"", 
                "num": 11
              }, 
              {
                "text": "Then the detachment of troops and the captain and the officers of the Jews arrested Jesus and bound Him.", 
                "num": 12
              }, 
              {
                "text": "And they led Him away to Annas first, for he was the father-in-law of Caiaphas who was high priest that year.", 
                "num": 13
              }, 
              {
                "text": "Now it was Caiaphas who advised the Jews that it was expedient that one man should die for the people.", 
                "num": 14
              }, 
              {
                "text": "And Simon Peter followed Jesus, and so did another disciple. Now that disciple was known to the high priest, and went with Jesus into the courtyard of the high priest.", 
                "num": 15
              }, 
              {
                "text": "But Peter stood at the door outside. Then the other disciple, who was known to the high priest, went out and spoke to her who kept the door, and brought Peter in.", 
                "num": 16
              }, 
              {
                "text": "Then the servant girl who kept the door said to Peter, \"You are not also one of this Man's disciples, are you?\" He said, \"I am not.\"", 
                "num": 17
              }, 
              {
                "text": "Now the servants and officers who had made a fire of coals stood there, for it was cold, and they warmed themselves. And Peter stood with them and warmed himself.", 
                "num": 18
              }, 
              {
                "text": "The high priest then asked Jesus about His disciples and His doctrine.", 
                "num": 19
              }, 
              {
                "text": "Jesus answered him, \"I spoke openly to the world. I always taught in synagogues and in the temple, where the Jews always meet, and in secret I have said nothing.", 
                "num": 20
              }, 
              {
                "text": "Why do you ask Me? Ask those who have heard Me what I said to them. Indeed they know what I said.\"", 
                "num": 21
              }, 
              {
                "text": "And when He had said these things, one of the officers who stood by struck Jesus with the palm of his hand, saying, \"Do You answer the high priest like that?\"", 
                "num": 22
              }, 
              {
                "text": "Jesus answered him, \"If I have spoken evil, bear witness of the evil; but if well, why do you strike Me?\"", 
                "num": 23
              }, 
              {
                "text": "Then Annas sent Him bound to Caiaphas the high priest.", 
                "num": 24
              }, 
              {
                "text": "Now Simon Peter stood and warmed himself. Therefore they said to him, \"You are not also one of His disciples, are you?\" He denied it and said, \"I am not!\"", 
                "num": 25
              }, 
              {
                "text": "One of the servants of the high priest, a relative of him whose ear Peter cut off, said, \"Did I not see you in the garden with Him?\"", 
                "num": 26
              }, 
              {
                "text": "Peter then denied again; and immediately a rooster crowed.", 
                "num": 27
              }, 
              {
                "text": "Then they led Jesus from Caiaphas to the Praetorium, and it was early morning. But they themselves did not go into the Praetorium, lest they should be defiled, but that they might eat the Passover.", 
                "num": 28
              }, 
              {
                "text": "Pilate then went out to them and said, \"What accusation do you bring against this Man?\"", 
                "num": 29
              }, 
              {
                "text": "They answered and said to him, \"If He were not an evildoer, we would not have delivered Him up to you.\"", 
                "num": 30
              }, 
              {
                "text": "Then Pilate said to them, \"You take Him and judge Him according to your law.\" Therefore the Jews said to him, \"It is not lawful for us to put anyone to death,\"", 
                "num": 31
              }, 
              {
                "text": "that the saying of Jesus might be fulfilled which He spoke, signifying by what death He would die.", 
                "num": 32
              }, 
              {
                "text": "Then Pilate entered the Praetorium again, called Jesus, and said to Him, \"Are You the King of the Jews?\"", 
                "num": 33
              }, 
              {
                "text": "Jesus answered him, \"Are you speaking for yourself about this, or did others tell you this concerning Me?\"", 
                "num": 34
              }, 
              {
                "text": "Pilate answered, \"Am I a Jew? Your own nation and the chief priests have delivered You to me. What have You done?\"", 
                "num": 35
              }, 
              {
                "text": "Jesus answered, \"My kingdom is not of this world. If My kingdom were of this world, My servants would fight, so that I should not be delivered to the Jews; but now My kingdom is not from here.\"", 
                "num": 36
              }, 
              {
                "text": "Pilate therefore said to Him, \"Are You a king then?\" Jesus answered, \"You say rightly that I am a king. For this cause I was born, and for this cause I have come into the world, that I should bear witness to the truth. Everyone who is of the truth hears My voice.\"", 
                "num": 37
              }, 
              {
                "text": "Pilate said to Him, \"What is truth?\" And when he had said this, he went out again to the Jews, and said to them, \"I find no fault in Him at all.", 
                "num": 38
              }, 
              {
                "text": "\"But you have a custom that I should release someone to you at the Passover. Do you therefore want me to release to you the King of the Jews?\"", 
                "num": 39
              }, 
              {
                "text": "Then they all cried again, saying, \"Not this Man, but Barabbas!\" Now Barabbas was a robber.", 
                "num": 40
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "So then Pilate took Jesus and scourged Him.", 
                "num": 1
              }, 
              {
                "text": "And the soldiers twisted a crown of thorns and put it on His head, and they put on Him a purple robe.", 
                "num": 2
              }, 
              {
                "text": "Then they said, \"Hail, King of the Jews!\" And they struck Him with their hands.", 
                "num": 3
              }, 
              {
                "text": "Pilate then went out again, and said to them, \"Behold, I am bringing Him out to you, that you may know that I find no fault in Him.\"", 
                "num": 4
              }, 
              {
                "text": "Then Jesus came out, wearing the crown of thorns and the purple robe. And Pilate said to them, \"Behold the Man!\"", 
                "num": 5
              }, 
              {
                "text": "Therefore, when the chief priests and officers saw Him, they cried out, saying, \"Crucify Him, crucify Him!\" Pilate said to them, \"You take Him and crucify Him, for I find no fault in Him.\"", 
                "num": 6
              }, 
              {
                "text": "The Jews answered him, \"We have a law, and according to our law He ought to die, because He made Himself the Son of God.\"", 
                "num": 7
              }, 
              {
                "text": "Therefore, when Pilate heard that saying, he was the more afraid,", 
                "num": 8
              }, 
              {
                "text": "and went again into the Praetorium, and said to Jesus, \"Where are You from?\" But Jesus gave him no answer.", 
                "num": 9
              }, 
              {
                "text": "Then Pilate said to Him, \"Are You not speaking to me? Do You not know that I have power to crucify You, and power to release You?\"", 
                "num": 10
              }, 
              {
                "text": "Jesus answered, \"You could have no power at all against Me unless it had been given you from above. Therefore the one who delivered Me to you has the greater sin.\"", 
                "num": 11
              }, 
              {
                "text": "From then on Pilate sought to release Him, but the Jews cried out, saying, \"If you let this Man go, you are not Caesar's friend. Whoever makes himself a king speaks against Caesar.\"", 
                "num": 12
              }, 
              {
                "text": "When Pilate therefore heard that saying, he brought Jesus out and sat down in the judgment seat in a place that is called The Pavement, but in Hebrew, Gabbatha.", 
                "num": 13
              }, 
              {
                "text": "Now it was the Preparation Day of the Passover, and about the sixth hour. And he said to the Jews, \"Behold your King!\"", 
                "num": 14
              }, 
              {
                "text": "But they cried out, \"Away with Him, away with Him! Crucify Him!\" Pilate said to them, \"Shall I crucify your King?\" The chief priests answered, \"We have no king but Caesar!\"", 
                "num": 15
              }, 
              {
                "text": "Then he delivered Him to them to be crucified. Then they took Jesus and led Him away.", 
                "num": 16
              }, 
              {
                "text": "And He, bearing His cross, went out to a place called the Place of a Skull, which is called in Hebrew, Golgotha,", 
                "num": 17
              }, 
              {
                "text": "where they crucified Him, and two others with Him, one on either side, and Jesus in the center.", 
                "num": 18
              }, 
              {
                "text": "Now Pilate wrote a title and put it on the cross. And the writing was: JESUS OF NAZARETH, THE KING OF THE JEWS.", 
                "num": 19
              }, 
              {
                "text": "Then many of the Jews read this title, for the place where Jesus was crucified was near the city; and it was written in Hebrew, Greek, and Latin.", 
                "num": 20
              }, 
              {
                "text": "Therefore the chief priests of the Jews said to Pilate, \"Do not write, \"The King of the Jews,' but, \"He said, \"I am the King of the Jews.\"\"'", 
                "num": 21
              }, 
              {
                "text": "Pilate answered, \"What I have written, I have written.\"", 
                "num": 22
              }, 
              {
                "text": "Then the soldiers, when they had crucified Jesus, took His garments and made four parts, to each soldier a part, and also the tunic. Now the tunic was without seam, woven from the top in one piece.", 
                "num": 23
              }, 
              {
                "text": "They said therefore among themselves, \"Let us not tear it, but cast lots for it, whose it shall be,\" that the Scripture might be fulfilled which says: \"They divided My garments among them, And for My clothing they cast lots.\" Therefore the soldiers did these things.", 
                "num": 24
              }, 
              {
                "text": "Now there stood by the cross of Jesus His mother, and His mother's sister, Mary the wife of Clopas, and Mary Magdalene.", 
                "num": 25
              }, 
              {
                "text": "When Jesus therefore saw His mother, and the disciple whom He loved standing by, He said to His mother, \"Woman, behold your son!\"", 
                "num": 26
              }, 
              {
                "text": "Then He said to the disciple, \"Behold your mother!\" And from that hour that disciple took her to his own home.", 
                "num": 27
              }, 
              {
                "text": "After this, Jesus, knowing that all things were now accomplished, that the Scripture might be fulfilled, said, \"I thirst!\"", 
                "num": 28
              }, 
              {
                "text": "Now a vessel full of sour wine was sitting there; and they filled a sponge with sour wine, put it on hyssop, and put it to His mouth.", 
                "num": 29
              }, 
              {
                "text": "So when Jesus had received the sour wine, He said, \"It is finished!\" And bowing His head, He gave up His spirit.", 
                "num": 30
              }, 
              {
                "text": "Therefore, because it was the Preparation Day, that the bodies should not remain on the cross on the Sabbath (for that Sabbath was a high day), the Jews asked Pilate that their legs might be broken, and that they might be taken away.", 
                "num": 31
              }, 
              {
                "text": "Then the soldiers came and broke the legs of the first and of the other who was crucified with Him.", 
                "num": 32
              }, 
              {
                "text": "But when they came to Jesus and saw that He was already dead, they did not break His legs.", 
                "num": 33
              }, 
              {
                "text": "But one of the soldiers pierced His side with a spear, and immediately blood and water came out.", 
                "num": 34
              }, 
              {
                "text": "And he who has seen has testified, and his testimony is true; and he knows that he is telling the truth, so that you may believe.", 
                "num": 35
              }, 
              {
                "text": "For these things were done that the Scripture should be fulfilled, \"Not one of His bones shall be broken.\"", 
                "num": 36
              }, 
              {
                "text": "And again another Scripture says, \"They shall look on Him whom they pierced.\"", 
                "num": 37
              }, 
              {
                "text": "After this, Joseph of Arimathea, being a disciple of Jesus, but secretly, for fear of the Jews, asked Pilate that he might take away the body of Jesus; and Pilate gave him permission. So he came and took the body of Jesus.", 
                "num": 38
              }, 
              {
                "text": "And Nicodemus, who at first came to Jesus by night, also came, bringing a mixture of myrrh and aloes, about a hundred pounds.", 
                "num": 39
              }, 
              {
                "text": "Then they took the body of Jesus, and bound it in strips of linen with the spices, as the custom of the Jews is to bury.", 
                "num": 40
              }, 
              {
                "text": "Now in the place where He was crucified there was a garden, and in the garden a new tomb in which no one had yet been laid.", 
                "num": 41
              }, 
              {
                "text": "So there they laid Jesus, because of the Jews' Preparation Day, for the tomb was nearby.", 
                "num": 42
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Now the first day of the week Mary Magdalene went to the tomb early, while it was still dark, and saw that the stone had been taken away from the tomb.", 
                "num": 1
              }, 
              {
                "text": "Then she ran and came to Simon Peter, and to the other disciple, whom Jesus loved, and said to them, \"They have taken away the Lord out of the tomb, and we do not know where they have laid Him.\"", 
                "num": 2
              }, 
              {
                "text": "Peter therefore went out, and the other disciple, and were going to the tomb.", 
                "num": 3
              }, 
              {
                "text": "So they both ran together, and the other disciple outran Peter and came to the tomb first.", 
                "num": 4
              }, 
              {
                "text": "And he, stooping down and looking in, saw the linen cloths lying there; yet he did not go in.", 
                "num": 5
              }, 
              {
                "text": "Then Simon Peter came, following him, and went into the tomb; and he saw the linen cloths lying there,", 
                "num": 6
              }, 
              {
                "text": "and the handkerchief that had been around His head, not lying with the linen cloths, but folded together in a place by itself.", 
                "num": 7
              }, 
              {
                "text": "Then the other disciple, who came to the tomb first, went in also; and he saw and believed.", 
                "num": 8
              }, 
              {
                "text": "For as yet they did not know the Scripture, that He must rise again from the dead.", 
                "num": 9
              }, 
              {
                "text": "Then the disciples went away again to their own homes.", 
                "num": 10
              }, 
              {
                "text": "But Mary stood outside by the tomb weeping, and as she wept she stooped down and looked into the tomb.", 
                "num": 11
              }, 
              {
                "text": "And she saw two angels in white sitting, one at the head and the other at the feet, where the body of Jesus had lain.", 
                "num": 12
              }, 
              {
                "text": "Then they said to her, \"Woman, why are you weeping?\" She said to them, \"Because they have taken away my Lord, and I do not know where they have laid Him.\"", 
                "num": 13
              }, 
              {
                "text": "Now when she had said this, she turned around and saw Jesus standing there, and did not know that it was Jesus.", 
                "num": 14
              }, 
              {
                "text": "Jesus said to her, \"Woman, why are you weeping? Whom are you seeking?\" She, supposing Him to be the gardener, said to Him, \"Sir, if You have carried Him away, tell me where You have laid Him, and I will take Him away.\"", 
                "num": 15
              }, 
              {
                "text": "Jesus said to her, \"Mary!\" She turned and said to Him, \"Rabboni!\" (which is to say, Teacher).", 
                "num": 16
              }, 
              {
                "text": "Jesus said to her, \"Do not cling to Me, for I have not yet ascended to My Father; but go to My brethren and say to them, \"I am ascending to My Father and your Father, and to My God and your God.\"'", 
                "num": 17
              }, 
              {
                "text": "Mary Magdalene came and told the disciples that she had seen the Lord, and that He had spoken these things to her.", 
                "num": 18
              }, 
              {
                "text": "Then, the same day at evening, being the first day of the week, when the doors were shut where the disciples were assembled, for fear of the Jews, Jesus came and stood in the midst, and said to them, \"Peace be with you.\"", 
                "num": 19
              }, 
              {
                "text": "When He had said this, He showed them His hands and His side. Then the disciples were glad when they saw the Lord.", 
                "num": 20
              }, 
              {
                "text": "So Jesus said to them again, \"Peace to you! As the Father has sent Me, I also send you.\"", 
                "num": 21
              }, 
              {
                "text": "And when He had said this, He breathed on them, and said to them, \"Receive the Holy Spirit.", 
                "num": 22
              }, 
              {
                "text": "If you forgive the sins of any, they are forgiven them; if you retain the sins of any, they are retained.\"", 
                "num": 23
              }, 
              {
                "text": "Now Thomas, called the Twin, one of the twelve, was not with them when Jesus came.", 
                "num": 24
              }, 
              {
                "text": "The other disciples therefore said to him, \"We have seen the Lord.\" So he said to them, \"Unless I see in His hands the print of the nails, and put my finger into the print of the nails, and put my hand into His side, I will not believe.\"", 
                "num": 25
              }, 
              {
                "text": "And after eight days His disciples were again inside, and Thomas with them. Jesus came, the doors being shut, and stood in the midst, and said, \"Peace to you!\"", 
                "num": 26
              }, 
              {
                "text": "Then He said to Thomas, \"Reach your finger here, and look at My hands; and reach your hand here, and put it into My side. Do not be unbelieving, but believing.\"", 
                "num": 27
              }, 
              {
                "text": "And Thomas answered and said to Him, \"My Lord and my God!\"", 
                "num": 28
              }, 
              {
                "text": "Jesus said to him, \"Thomas, because you have seen Me, you have believed. Blessed are those who have not seen and yet have believed.\"", 
                "num": 29
              }, 
              {
                "text": "And truly Jesus did many other signs in the presence of His disciples, which are not written in this book;", 
                "num": 30
              }, 
              {
                "text": "but these are written that you may believe that Jesus is the Christ, the Son of God, and that believing you may have life in His name.", 
                "num": 31
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "After these things Jesus showed Himself again to the disciples at the Sea of Tiberias, and in this way He showed Himself:", 
                "num": 1
              }, 
              {
                "text": "Simon Peter, Thomas called the Twin, Nathanael of Cana in Galilee, the sons of Zebedee, and two others of His disciples were together.", 
                "num": 2
              }, 
              {
                "text": "Simon Peter said to them, \"I am going fishing.\" They said to him, \"We are going with you also.\" They went out and immediately got into the boat, and that night they caught nothing.", 
                "num": 3
              }, 
              {
                "text": "But when the morning had now come, Jesus stood on the shore; yet the disciples did not know that it was Jesus.", 
                "num": 4
              }, 
              {
                "text": "Then Jesus said to them, \"Children, have you any food?\" They answered Him, \"No.\"", 
                "num": 5
              }, 
              {
                "text": "And He said to them, \"Cast the net on the right side of the boat, and you will find some.\" So they cast, and now they were not able to draw it in because of the multitude of fish.", 
                "num": 6
              }, 
              {
                "text": "Therefore that disciple whom Jesus loved said to Peter, \"It is the Lord!\" Now when Simon Peter heard that it was the Lord, he put on his outer garment (for he had removed it), and plunged into the sea.", 
                "num": 7
              }, 
              {
                "text": "But the other disciples came in the little boat (for they were not far from land, but about two hundred cubits), dragging the net with fish.", 
                "num": 8
              }, 
              {
                "text": "Then, as soon as they had come to land, they saw a fire of coals there, and fish laid on it, and bread.", 
                "num": 9
              }, 
              {
                "text": "Jesus said to them, \"Bring some of the fish which you have just caught.\"", 
                "num": 10
              }, 
              {
                "text": "Simon Peter went up and dragged the net to land, full of large fish, one hundred and fifty-three; and although there were so many, the net was not broken.", 
                "num": 11
              }, 
              {
                "text": "Jesus said to them, \"Come and eat breakfast.\" Yet none of the disciples dared ask Him, \"Who are You?\"--knowing that it was the Lord.", 
                "num": 12
              }, 
              {
                "text": "Jesus then came and took the bread and gave it to them, and likewise the fish.", 
                "num": 13
              }, 
              {
                "text": "This is now the third time Jesus showed Himself to His disciples after He was raised from the dead.", 
                "num": 14
              }, 
              {
                "text": "So when they had eaten breakfast, Jesus said to Simon Peter, \"Simon, son of Jonah, do you love Me more than these?\" He said to Him, \"Yes, Lord; You know that I love You.\" He said to him, \"Feed My lambs.\"", 
                "num": 15
              }, 
              {
                "text": "He said to him again a second time, \"Simon, son of Jonah, do you love Me?\" He said to Him, \"Yes, Lord; You know that I love You.\" He said to him, \"Tend My sheep.\"", 
                "num": 16
              }, 
              {
                "text": "He said to him the third time, \"Simon, son of Jonah, do you love Me?\" Peter was grieved because He said to him the third time, \"Do you love Me?\" And he said to Him, \"Lord, You know all things; You know that I love You.\" Jesus said to him, \"Feed My sheep.", 
                "num": 17
              }, 
              {
                "text": "Most assuredly, I say to you, when you were younger, you girded yourself and walked where you wished; but when you are old, you will stretch out your hands, and another will gird you and carry you where you do not wish.\"", 
                "num": 18
              }, 
              {
                "text": "This He spoke, signifying by what death he would glorify God. And when He had spoken this, He said to him, \"Follow Me.\"", 
                "num": 19
              }, 
              {
                "text": "Then Peter, turning around, saw the disciple whom Jesus loved following, who also had leaned on His breast at the supper, and said, \"Lord, who is the one who betrays You?\"", 
                "num": 20
              }, 
              {
                "text": "Peter, seeing him, said to Jesus, \"But Lord, what about this man?\"", 
                "num": 21
              }, 
              {
                "text": "Jesus said to him, \"If I will that he remain till I come, what is that to you? You follow Me.\"", 
                "num": 22
              }, 
              {
                "text": "Then this saying went out among the brethren that this disciple would not die. Yet Jesus did not say to him that he would not die, but, \"If I will that he remain till I come, what is that to you?\"", 
                "num": 23
              }, 
              {
                "text": "This is the disciple who testifies of these things, and wrote these things; and we know that his testimony is true.", 
                "num": 24
              }, 
              {
                "text": "And there are also many other things that Jesus did, which if they were written one by one, I suppose that even the world itself could not contain the books that would be written. Amen.", 
                "num": 25
              }
            ], 
            "num": 21
          }
        ]
      }, 
      {
        "name": "Acts", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The former account I made, O Theophilus, of all that Jesus began both to do and teach,", 
                "num": 1
              }, 
              {
                "text": "until the day in which He was taken up, after He through the Holy Spirit had given commandments to the apostles whom He had chosen,", 
                "num": 2
              }, 
              {
                "text": "to whom He also presented Himself alive after His suffering by many infallible proofs, being seen by them during forty days and speaking of the things pertaining to the kingdom of God.", 
                "num": 3
              }, 
              {
                "text": "And being assembled together with them, He commanded them not to depart from Jerusalem, but to wait for the Promise of the Father, \"which,\" He said, \"you have heard from Me;", 
                "num": 4
              }, 
              {
                "text": "for John truly baptized with water, but you shall be baptized with the Holy Spirit not many days from now.\"", 
                "num": 5
              }, 
              {
                "text": "Therefore, when they had come together, they asked Him, saying, \"Lord, will You at this time restore the kingdom to Israel?\"", 
                "num": 6
              }, 
              {
                "text": "And He said to them, \"It is not for you to know times or seasons which the Father has put in His own authority.", 
                "num": 7
              }, 
              {
                "text": "But you shall receive power when the Holy Spirit has come upon you; and you shall be witnesses to Me in Jerusalem, and in all Judea and Samaria, and to the end of the earth.\"", 
                "num": 8
              }, 
              {
                "text": "Now when He had spoken these things, while they watched, He was taken up, and a cloud received Him out of their sight.", 
                "num": 9
              }, 
              {
                "text": "And while they looked steadfastly toward heaven as He went up, behold, two men stood by them in white apparel,", 
                "num": 10
              }, 
              {
                "text": "who also said, \"Men of Galilee, why do you stand gazing up into heaven? This same Jesus, who was taken up from you into heaven, will so come in like manner as you saw Him go into heaven.\"", 
                "num": 11
              }, 
              {
                "text": "Then they returned to Jerusalem from the mount called Olivet, which is near Jerusalem, a Sabbath day's journey.", 
                "num": 12
              }, 
              {
                "text": "And when they had entered, they went up into the upper room where they were staying: Peter, James, John, and Andrew; Philip and Thomas; Bartholomew and Matthew; James the son of Alphaeus and Simon the Zealot; and Judas the son of James.", 
                "num": 13
              }, 
              {
                "text": "These all continued with one accord in prayer and supplication, with the women and Mary the mother of Jesus, and with His brothers.", 
                "num": 14
              }, 
              {
                "text": "And in those days Peter stood up in the midst of the disciples (altogether the number of names was about a hundred and twenty), and said,", 
                "num": 15
              }, 
              {
                "text": "\"Men and brethren, this Scripture had to be fulfilled, which the Holy Spirit spoke before by the mouth of David concerning Judas, who became a guide to those who arrested Jesus;", 
                "num": 16
              }, 
              {
                "text": "for he was numbered with us and obtained a part in this ministry.\"", 
                "num": 17
              }, 
              {
                "text": "(Now this man purchased a field with the wages of iniquity; and falling headlong, he burst open in the middle and all his entrails gushed out.", 
                "num": 18
              }, 
              {
                "text": "And it became known to all those dwelling in Jerusalem; so that field is called in their own language, Akel Dama, that is, Field of Blood.)", 
                "num": 19
              }, 
              {
                "text": "\"For it is written in the Book of Psalms: \"Let his dwelling place be desolate, And let no one live in it'; and, \"Let another take his office.'", 
                "num": 20
              }, 
              {
                "text": "\"Therefore, of these men who have accompanied us all the time that the Lord Jesus went in and out among us,", 
                "num": 21
              }, 
              {
                "text": "beginning from the baptism of John to that day when He was taken up from us, one of these must become a witness with us of His resurrection.\"", 
                "num": 22
              }, 
              {
                "text": "And they proposed two: Joseph called Barsabas, who was surnamed Justus, and Matthias.", 
                "num": 23
              }, 
              {
                "text": "And they prayed and said, \"You, O Lord, who know the hearts of all, show which of these two You have chosen", 
                "num": 24
              }, 
              {
                "text": "to take part in this ministry and apostleship from which Judas by transgression fell, that he might go to his own place.\"", 
                "num": 25
              }, 
              {
                "text": "And they cast their lots, and the lot fell on Matthias. And he was numbered with the eleven apostles.", 
                "num": 26
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "When the Day of Pentecost had fully come, they were all with one accord in one place.", 
                "num": 1
              }, 
              {
                "text": "And suddenly there came a sound from heaven, as of a rushing mighty wind, and it filled the whole house where they were sitting.", 
                "num": 2
              }, 
              {
                "text": "Then there appeared to them divided tongues, as of fire, and one sat upon each of them.", 
                "num": 3
              }, 
              {
                "text": "And they were all filled with the Holy Spirit and began to speak with other tongues, as the Spirit gave them utterance.", 
                "num": 4
              }, 
              {
                "text": "And there were dwelling in Jerusalem Jews, devout men, from every nation under heaven.", 
                "num": 5
              }, 
              {
                "text": "And when this sound occurred, the multitude came together, and were confused, because everyone heard them speak in his own language.", 
                "num": 6
              }, 
              {
                "text": "Then they were all amazed and marveled, saying to one another, \"Look, are not all these who speak Galileans?", 
                "num": 7
              }, 
              {
                "text": "And how is it that we hear, each in our own language in which we were born?", 
                "num": 8
              }, 
              {
                "text": "Parthians and Medes and Elamites, those dwelling in Mesopotamia, Judea and Cappadocia, Pontus and Asia,", 
                "num": 9
              }, 
              {
                "text": "Phrygia and Pamphylia, Egypt and the parts of Libya adjoining Cyrene, visitors from Rome, both Jews and proselytes,", 
                "num": 10
              }, 
              {
                "text": "Cretans and Arabs--we hear them speaking in our own tongues the wonderful works of God.\"", 
                "num": 11
              }, 
              {
                "text": "So they were all amazed and perplexed, saying to one another, \"Whatever could this mean?\"", 
                "num": 12
              }, 
              {
                "text": "Others mocking said, \"They are full of new wine.\"", 
                "num": 13
              }, 
              {
                "text": "But Peter, standing up with the eleven, raised his voice and said to them, \"Men of Judea and all who dwell in Jerusalem, let this be known to you, and heed my words.", 
                "num": 14
              }, 
              {
                "text": "For these are not drunk, as you suppose, since it is only the third hour of the day.", 
                "num": 15
              }, 
              {
                "text": "But this is what was spoken by the prophet Joel:", 
                "num": 16
              }, 
              {
                "text": "\"And it shall come to pass in the last days, says God, That I will pour out of My Spirit on all flesh; Your sons and your daughters shall prophesy, Your young men shall see visions, Your old men shall dream dreams.", 
                "num": 17
              }, 
              {
                "text": "And on My menservants and on My maidservants I will pour out My Spirit in those days; And they shall prophesy.", 
                "num": 18
              }, 
              {
                "text": "I will show wonders in heaven above And signs in the earth beneath: Blood and fire and vapor of smoke.", 
                "num": 19
              }, 
              {
                "text": "The sun shall be turned into darkness, And the moon into blood, Before the coming of the great and awesome day of the LORD.", 
                "num": 20
              }, 
              {
                "text": "And it shall come to pass That whoever calls on the name of the LORD Shall be saved.'", 
                "num": 21
              }, 
              {
                "text": "\"Men of Israel, hear these words: Jesus of Nazareth, a Man attested by God to you by miracles, wonders, and signs which God did through Him in your midst, as you yourselves also know--", 
                "num": 22
              }, 
              {
                "text": "Him, being delivered by the determined purpose and foreknowledge of God, you have taken by lawless hands, have crucified, and put to death;", 
                "num": 23
              }, 
              {
                "text": "whom God raised up, having loosed the pains of death, because it was not possible that He should be held by it.", 
                "num": 24
              }, 
              {
                "text": "For David says concerning Him: \"I foresaw the LORD always before my face, For He is at my right hand, that I may not be shaken.", 
                "num": 25
              }, 
              {
                "text": "Therefore my heart rejoiced, and my tongue was glad; Moreover my flesh also will rest in hope.", 
                "num": 26
              }, 
              {
                "text": "For You will not leave my soul in Hades, Nor will You allow Your Holy One to see corruption.", 
                "num": 27
              }, 
              {
                "text": "You have made known to me the ways of life; You will make me full of joy in Your presence.'", 
                "num": 28
              }, 
              {
                "text": "\"Men and brethren, let me speak freely to you of the patriarch David, that he is both dead and buried, and his tomb is with us to this day.", 
                "num": 29
              }, 
              {
                "text": "Therefore, being a prophet, and knowing that God had sworn with an oath to him that of the fruit of his body, according to the flesh, He would raise up the Christ to sit on his throne,", 
                "num": 30
              }, 
              {
                "text": "he, foreseeing this, spoke concerning the resurrection of the Christ, that His soul was not left in Hades, nor did His flesh see corruption.", 
                "num": 31
              }, 
              {
                "text": "This Jesus God has raised up, of which we are all witnesses.", 
                "num": 32
              }, 
              {
                "text": "Therefore being exalted to the right hand of God, and having received from the Father the promise of the Holy Spirit, He poured out this which you now see and hear.", 
                "num": 33
              }, 
              {
                "text": "\"For David did not ascend into the heavens, but he says himself: \"The LORD said to my Lord, \"Sit at My right hand,", 
                "num": 34
              }, 
              {
                "text": "Till I make Your enemies Your footstool.\"'", 
                "num": 35
              }, 
              {
                "text": "\"Therefore let all the house of Israel know assuredly that God has made this Jesus, whom you crucified, both Lord and Christ.\"", 
                "num": 36
              }, 
              {
                "text": "Now when they heard this, they were cut to the heart, and said to Peter and the rest of the apostles, \"Men and brethren, what shall we do?\"", 
                "num": 37
              }, 
              {
                "text": "Then Peter said to them, \"Repent, and let every one of you be baptized in the name of Jesus Christ for the remission of sins; and you shall receive the gift of the Holy Spirit.", 
                "num": 38
              }, 
              {
                "text": "For the promise is to you and to your children, and to all who are afar off, as many as the Lord our God will call.\"", 
                "num": 39
              }, 
              {
                "text": "And with many other words he testified and exhorted them, saying, \"Be saved from this perverse generation.\"", 
                "num": 40
              }, 
              {
                "text": "Then those who gladly received his word were baptized; and that day about three thousand souls were added to them.", 
                "num": 41
              }, 
              {
                "text": "And they continued steadfastly in the apostles' doctrine and fellowship, in the breaking of bread, and in prayers.", 
                "num": 42
              }, 
              {
                "text": "Then fear came upon every soul, and many wonders and signs were done through the apostles.", 
                "num": 43
              }, 
              {
                "text": "Now all who believed were together, and had all things in common,", 
                "num": 44
              }, 
              {
                "text": "and sold their possessions and goods, and divided them among all, as anyone had need.", 
                "num": 45
              }, 
              {
                "text": "So continuing daily with one accord in the temple, and breaking bread from house to house, they ate their food with gladness and simplicity of heart,", 
                "num": 46
              }, 
              {
                "text": "praising God and having favor with all the people. And the Lord added to the church daily those who were being saved.", 
                "num": 47
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Now Peter and John went up together to the temple at the hour of prayer, the ninth hour.", 
                "num": 1
              }, 
              {
                "text": "And a certain man lame from his mother's womb was carried, whom they laid daily at the gate of the temple which is called Beautiful, to ask alms from those who entered the temple;", 
                "num": 2
              }, 
              {
                "text": "who, seeing Peter and John about to go into the temple, asked for alms.", 
                "num": 3
              }, 
              {
                "text": "And fixing his eyes on him, with John, Peter said, \"Look at us.\"", 
                "num": 4
              }, 
              {
                "text": "So he gave them his attention, expecting to receive something from them.", 
                "num": 5
              }, 
              {
                "text": "Then Peter said, \"Silver and gold I do not have, but what I do have I give you: In the name of Jesus Christ of Nazareth, rise up and walk.\"", 
                "num": 6
              }, 
              {
                "text": "And he took him by the right hand and lifted him up, and immediately his feet and ankle bones received strength.", 
                "num": 7
              }, 
              {
                "text": "So he, leaping up, stood and walked and entered the temple with them--walking, leaping, and praising God.", 
                "num": 8
              }, 
              {
                "text": "And all the people saw him walking and praising God.", 
                "num": 9
              }, 
              {
                "text": "Then they knew that it was he who sat begging alms at the Beautiful Gate of the temple; and they were filled with wonder and amazement at what had happened to him.", 
                "num": 10
              }, 
              {
                "text": "Now as the lame man who was healed held on to Peter and John, all the people ran together to them in the porch which is called Solomon's, greatly amazed.", 
                "num": 11
              }, 
              {
                "text": "So when Peter saw it, he responded to the people: \"Men of Israel, why do you marvel at this? Or why look so intently at us, as though by our own power or godliness we had made this man walk?", 
                "num": 12
              }, 
              {
                "text": "The God of Abraham, Isaac, and Jacob, the God of our fathers, glorified His Servant Jesus, whom you delivered up and denied in the presence of Pilate, when he was determined to let Him go.", 
                "num": 13
              }, 
              {
                "text": "But you denied the Holy One and the Just, and asked for a murderer to be granted to you,", 
                "num": 14
              }, 
              {
                "text": "and killed the Prince of life, whom God raised from the dead, of which we are witnesses.", 
                "num": 15
              }, 
              {
                "text": "And His name, through faith in His name, has made this man strong, whom you see and know. Yes, the faith which comes through Him has given him this perfect soundness in the presence of you all.", 
                "num": 16
              }, 
              {
                "text": "\"Yet now, brethren, I know that you did it in ignorance, as did also your rulers.", 
                "num": 17
              }, 
              {
                "text": "But those things which God foretold by the mouth of all His prophets, that the Christ would suffer, He has thus fulfilled.", 
                "num": 18
              }, 
              {
                "text": "Repent therefore and be converted, that your sins may be blotted out, so that times of refreshing may come from the presence of the Lord,", 
                "num": 19
              }, 
              {
                "text": "and that He may send Jesus Christ, who was preached to you before,", 
                "num": 20
              }, 
              {
                "text": "whom heaven must receive until the times of restoration of all things, which God has spoken by the mouth of all His holy prophets since the world began.", 
                "num": 21
              }, 
              {
                "text": "For Moses truly said to the fathers, \"The LORD your God will raise up for you a Prophet like me from your brethren. Him you shall hear in all things, whatever He says to you.", 
                "num": 22
              }, 
              {
                "text": "And it shall be that every soul who will not hear that Prophet shall be utterly destroyed from among the people.'", 
                "num": 23
              }, 
              {
                "text": "Yes, and all the prophets, from Samuel and those who follow, as many as have spoken, have also foretold these days.", 
                "num": 24
              }, 
              {
                "text": "You are sons of the prophets, and of the covenant which God made with our fathers, saying to Abraham, \"And in your seed all the families of the earth shall be blessed.'", 
                "num": 25
              }, 
              {
                "text": "To you first, God, having raised up His Servant Jesus, sent Him to bless you, in turning away every one of you from your iniquities.\"", 
                "num": 26
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now as they spoke to the people, the priests, the captain of the temple, and the Sadducees came upon them,", 
                "num": 1
              }, 
              {
                "text": "being greatly disturbed that they taught the people and preached in Jesus the resurrection from the dead.", 
                "num": 2
              }, 
              {
                "text": "And they laid hands on them, and put them in custody until the next day, for it was already evening.", 
                "num": 3
              }, 
              {
                "text": "However, many of those who heard the word believed; and the number of the men came to be about five thousand.", 
                "num": 4
              }, 
              {
                "text": "And it came to pass, on the next day, that their rulers, elders, and scribes,", 
                "num": 5
              }, 
              {
                "text": "as well as Annas the high priest, Caiaphas, John, and Alexander, and as many as were of the family of the high priest, were gathered together at Jerusalem.", 
                "num": 6
              }, 
              {
                "text": "And when they had set them in the midst, they asked, \"By what power or by what name have you done this?\"", 
                "num": 7
              }, 
              {
                "text": "Then Peter, filled with the Holy Spirit, said to them, \"Rulers of the people and elders of Israel:", 
                "num": 8
              }, 
              {
                "text": "If we this day are judged for a good deed done to a helpless man, by what means he has been made well,", 
                "num": 9
              }, 
              {
                "text": "let it be known to you all, and to all the people of Israel, that by the name of Jesus Christ of Nazareth, whom you crucified, whom God raised from the dead, by Him this man stands here before you whole.", 
                "num": 10
              }, 
              {
                "text": "This is the \"stone which was rejected by you builders, which has become the chief cornerstone.'", 
                "num": 11
              }, 
              {
                "text": "Nor is there salvation in any other, for there is no other name under heaven given among men by which we must be saved.\"", 
                "num": 12
              }, 
              {
                "text": "Now when they saw the boldness of Peter and John, and perceived that they were uneducated and untrained men, they marveled. And they realized that they had been with Jesus.", 
                "num": 13
              }, 
              {
                "text": "And seeing the man who had been healed standing with them, they could say nothing against it.", 
                "num": 14
              }, 
              {
                "text": "But when they had commanded them to go aside out of the council, they conferred among themselves,", 
                "num": 15
              }, 
              {
                "text": "saying, \"What shall we do to these men? For, indeed, that a notable miracle has been done through them is evident to all who dwell in Jerusalem, and we cannot deny it.", 
                "num": 16
              }, 
              {
                "text": "But so that it spreads no further among the people, let us severely threaten them, that from now on they speak to no man in this name.\"", 
                "num": 17
              }, 
              {
                "text": "So they called them and commanded them not to speak at all nor teach in the name of Jesus.", 
                "num": 18
              }, 
              {
                "text": "But Peter and John answered and said to them, \"Whether it is right in the sight of God to listen to you more than to God, you judge.", 
                "num": 19
              }, 
              {
                "text": "For we cannot but speak the things which we have seen and heard.\"", 
                "num": 20
              }, 
              {
                "text": "So when they had further threatened them, they let them go, finding no way of punishing them, because of the people, since they all glorified God for what had been done.", 
                "num": 21
              }, 
              {
                "text": "For the man was over forty years old on whom this miracle of healing had been performed.", 
                "num": 22
              }, 
              {
                "text": "And being let go, they went to their own companions and reported all that the chief priests and elders had said to them.", 
                "num": 23
              }, 
              {
                "text": "So when they heard that, they raised their voice to God with one accord and said: \"Lord, You are God, who made heaven and earth and the sea, and all that is in them,", 
                "num": 24
              }, 
              {
                "text": "who by the mouth of Your servant David have said: \"Why did the nations rage, And the people plot vain things?", 
                "num": 25
              }, 
              {
                "text": "The kings of the earth took their stand, And the rulers were gathered together Against the LORD and against His Christ.'", 
                "num": 26
              }, 
              {
                "text": "\"For truly against Your holy Servant Jesus, whom You anointed, both Herod and Pontius Pilate, with the Gentiles and the people of Israel, were gathered together", 
                "num": 27
              }, 
              {
                "text": "to do whatever Your hand and Your purpose determined before to be done.", 
                "num": 28
              }, 
              {
                "text": "Now, Lord, look on their threats, and grant to Your servants that with all boldness they may speak Your word,", 
                "num": 29
              }, 
              {
                "text": "by stretching out Your hand to heal, and that signs and wonders may be done through the name of Your holy Servant Jesus.\"", 
                "num": 30
              }, 
              {
                "text": "And when they had prayed, the place where they were assembled together was shaken; and they were all filled with the Holy Spirit, and they spoke the word of God with boldness.", 
                "num": 31
              }, 
              {
                "text": "Now the multitude of those who believed were of one heart and one soul; neither did anyone say that any of the things he possessed was his own, but they had all things in common.", 
                "num": 32
              }, 
              {
                "text": "And with great power the apostles gave witness to the resurrection of the Lord Jesus. And great grace was upon them all.", 
                "num": 33
              }, 
              {
                "text": "Nor was there anyone among them who lacked; for all who were possessors of lands or houses sold them, and brought the proceeds of the things that were sold,", 
                "num": 34
              }, 
              {
                "text": "and laid them at the apostles' feet; and they distributed to each as anyone had need.", 
                "num": 35
              }, 
              {
                "text": "And Joses, who was also named Barnabas by the apostles (which is translated Son of Encouragement), a Levite of the country of Cyprus,", 
                "num": 36
              }, 
              {
                "text": "having land, sold it, and brought the money and laid it at the apostles' feet.", 
                "num": 37
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "But a certain man named Ananias, with Sapphira his wife, sold a possession.", 
                "num": 1
              }, 
              {
                "text": "And he kept back part of the proceeds, his wife also being aware of it, and brought a certain part and laid it at the apostles' feet.", 
                "num": 2
              }, 
              {
                "text": "But Peter said, \"Ananias, why has Satan filled your heart to lie to the Holy Spirit and keep back part of the price of the land for yourself?", 
                "num": 3
              }, 
              {
                "text": "While it remained, was it not your own? And after it was sold, was it not in your own control? Why have you conceived this thing in your heart? You have not lied to men but to God.\"", 
                "num": 4
              }, 
              {
                "text": "Then Ananias, hearing these words, fell down and breathed his last. So great fear came upon all those who heard these things.", 
                "num": 5
              }, 
              {
                "text": "And the young men arose and wrapped him up, carried him out, and buried him.", 
                "num": 6
              }, 
              {
                "text": "Now it was about three hours later when his wife came in, not knowing what had happened.", 
                "num": 7
              }, 
              {
                "text": "And Peter answered her, \"Tell me whether you sold the land for so much?\" She said, \"Yes, for so much.\"", 
                "num": 8
              }, 
              {
                "text": "Then Peter said to her, \"How is it that you have agreed together to test the Spirit of the Lord? Look, the feet of those who have buried your husband are at the door, and they will carry you out.\"", 
                "num": 9
              }, 
              {
                "text": "Then immediately she fell down at his feet and breathed her last. And the young men came in and found her dead, and carrying her out, buried her by her husband.", 
                "num": 10
              }, 
              {
                "text": "So great fear came upon all the church and upon all who heard these things.", 
                "num": 11
              }, 
              {
                "text": "And through the hands of the apostles many signs and wonders were done among the people. And they were all with one accord in Solomon's Porch.", 
                "num": 12
              }, 
              {
                "text": "Yet none of the rest dared join them, but the people esteemed them highly.", 
                "num": 13
              }, 
              {
                "text": "And believers were increasingly added to the Lord, multitudes of both men and women,", 
                "num": 14
              }, 
              {
                "text": "so that they brought the sick out into the streets and laid them on beds and couches, that at least the shadow of Peter passing by might fall on some of them.", 
                "num": 15
              }, 
              {
                "text": "Also a multitude gathered from the surrounding cities to Jerusalem, bringing sick people and those who were tormented by unclean spirits, and they were all healed.", 
                "num": 16
              }, 
              {
                "text": "Then the high priest rose up, and all those who were with him (which is the sect of the Sadducees), and they were filled with indignation,", 
                "num": 17
              }, 
              {
                "text": "and laid their hands on the apostles and put them in the common prison.", 
                "num": 18
              }, 
              {
                "text": "But at night an angel of the Lord opened the prison doors and brought them out, and said,", 
                "num": 19
              }, 
              {
                "text": "\"Go, stand in the temple and speak to the people all the words of this life.\"", 
                "num": 20
              }, 
              {
                "text": "And when they heard that, they entered the temple early in the morning and taught. But the high priest and those with him came and called the council together, with all the elders of the children of Israel, and sent to the prison to have them brought.", 
                "num": 21
              }, 
              {
                "text": "But when the officers came and did not find them in the prison, they returned and reported,", 
                "num": 22
              }, 
              {
                "text": "saying, \"Indeed we found the prison shut securely, and the guards standing outside before the doors; but when we opened them, we found no one inside!\"", 
                "num": 23
              }, 
              {
                "text": "Now when the high priest, the captain of the temple, and the chief priests heard these things, they wondered what the outcome would be.", 
                "num": 24
              }, 
              {
                "text": "So one came and told them, saying, \"Look, the men whom you put in prison are standing in the temple and teaching the people!\"", 
                "num": 25
              }, 
              {
                "text": "Then the captain went with the officers and brought them without violence, for they feared the people, lest they should be stoned.", 
                "num": 26
              }, 
              {
                "text": "And when they had brought them, they set them before the council. And the high priest asked them,", 
                "num": 27
              }, 
              {
                "text": "saying, \"Did we not strictly command you not to teach in this name? And look, you have filled Jerusalem with your doctrine, and intend to bring this Man's blood on us!\"", 
                "num": 28
              }, 
              {
                "text": "But Peter and the other apostles answered and said: \"We ought to obey God rather than men.", 
                "num": 29
              }, 
              {
                "text": "The God of our fathers raised up Jesus whom you murdered by hanging on a tree.", 
                "num": 30
              }, 
              {
                "text": "Him God has exalted to His right hand to be Prince and Savior, to give repentance to Israel and forgiveness of sins.", 
                "num": 31
              }, 
              {
                "text": "And we are His witnesses to these things, and so also is the Holy Spirit whom God has given to those who obey Him.\"", 
                "num": 32
              }, 
              {
                "text": "When they heard this, they were furious and plotted to kill them.", 
                "num": 33
              }, 
              {
                "text": "Then one in the council stood up, a Pharisee named Gamaliel, a teacher of the law held in respect by all the people, and commanded them to put the apostles outside for a little while.", 
                "num": 34
              }, 
              {
                "text": "And he said to them: \"Men of Israel, take heed to yourselves what you intend to do regarding these men.", 
                "num": 35
              }, 
              {
                "text": "For some time ago Theudas rose up, claiming to be somebody. A number of men, about four hundred, joined him. He was slain, and all who obeyed him were scattered and came to nothing.", 
                "num": 36
              }, 
              {
                "text": "After this man, Judas of Galilee rose up in the days of the census, and drew away many people after him. He also perished, and all who obeyed him were dispersed.", 
                "num": 37
              }, 
              {
                "text": "And now I say to you, keep away from these men and let them alone; for if this plan or this work is of men, it will come to nothing;", 
                "num": 38
              }, 
              {
                "text": "but if it is of God, you cannot overthrow it--lest you even be found to fight against God.\"", 
                "num": 39
              }, 
              {
                "text": "And they agreed with him, and when they had called for the apostles and beaten them, they commanded that they should not speak in the name of Jesus, and let them go.", 
                "num": 40
              }, 
              {
                "text": "So they departed from the presence of the council, rejoicing that they were counted worthy to suffer shame for His name.", 
                "num": 41
              }, 
              {
                "text": "And daily in the temple, and in every house, they did not cease teaching and preaching Jesus as the Christ.", 
                "num": 42
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now in those days, when the number of the disciples was multiplying, there arose a complaint against the Hebrews by the Hellenists, because their widows were neglected in the daily distribution.", 
                "num": 1
              }, 
              {
                "text": "Then the twelve summoned the multitude of the disciples and said, \"It is not desirable that we should leave the word of God and serve tables.", 
                "num": 2
              }, 
              {
                "text": "Therefore, brethren, seek out from among you seven men of good reputation, full of the Holy Spirit and wisdom, whom we may appoint over this business;", 
                "num": 3
              }, 
              {
                "text": "but we will give ourselves continually to prayer and to the ministry of the word.\"", 
                "num": 4
              }, 
              {
                "text": "And the saying pleased the whole multitude. And they chose Stephen, a man full of faith and the Holy Spirit, and Philip, Prochorus, Nicanor, Timon, Parmenas, and Nicolas, a proselyte from Antioch,", 
                "num": 5
              }, 
              {
                "text": "whom they set before the apostles; and when they had prayed, they laid hands on them.", 
                "num": 6
              }, 
              {
                "text": "Then the word of God spread, and the number of the disciples multiplied greatly in Jerusalem, and a great many of the priests were obedient to the faith.", 
                "num": 7
              }, 
              {
                "text": "And Stephen, full of faith and power, did great wonders and signs among the people.", 
                "num": 8
              }, 
              {
                "text": "Then there arose some from what is called the Synagogue of the Freedmen (Cyrenians, Alexandrians, and those from Cilicia and Asia), disputing with Stephen.", 
                "num": 9
              }, 
              {
                "text": "And they were not able to resist the wisdom and the Spirit by which he spoke.", 
                "num": 10
              }, 
              {
                "text": "Then they secretly induced men to say, \"We have heard him speak blasphemous words against Moses and God.\"", 
                "num": 11
              }, 
              {
                "text": "And they stirred up the people, the elders, and the scribes; and they came upon him, seized him, and brought him to the council.", 
                "num": 12
              }, 
              {
                "text": "They also set up false witnesses who said, \"This man does not cease to speak blasphemous words against this holy place and the law;", 
                "num": 13
              }, 
              {
                "text": "for we have heard him say that this Jesus of Nazareth will destroy this place and change the customs which Moses delivered to us.\"", 
                "num": 14
              }, 
              {
                "text": "And all who sat in the council, looking steadfastly at him, saw his face as the face of an angel.", 
                "num": 15
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Then the high priest said, \"Are these things so?\"", 
                "num": 1
              }, 
              {
                "text": "And he said, \"Brethren and fathers, listen: The God of glory appeared to our father Abraham when he was in Mesopotamia, before he dwelt in Haran,", 
                "num": 2
              }, 
              {
                "text": "and said to him, \"Get out of your country and from your relatives, and come to a land that I will show you.'", 
                "num": 3
              }, 
              {
                "text": "Then he came out of the land of the Chaldeans and dwelt in Haran. And from there, when his father was dead, He moved him to this land in which you now dwell.", 
                "num": 4
              }, 
              {
                "text": "And God gave him no inheritance in it, not even enough to set his foot on. But even when Abraham had no child, He promised to give it to him for a possession, and to his descendants after him.", 
                "num": 5
              }, 
              {
                "text": "But God spoke in this way: that his descendants would dwell in a foreign land, and that they would bring them into bondage and oppress them four hundred years.", 
                "num": 6
              }, 
              {
                "text": "\"And the nation to whom they will be in bondage I will judge,' said God, \"and after that they shall come out and serve Me in this place.'", 
                "num": 7
              }, 
              {
                "text": "Then He gave him the covenant of circumcision; and so Abraham begot Isaac and circumcised him on the eighth day; and Isaac begot Jacob, and Jacob begot the twelve patriarchs.", 
                "num": 8
              }, 
              {
                "text": "\"And the patriarchs, becoming envious, sold Joseph into Egypt. But God was with him", 
                "num": 9
              }, 
              {
                "text": "and delivered him out of all his troubles, and gave him favor and wisdom in the presence of Pharaoh, king of Egypt; and he made him governor over Egypt and all his house.", 
                "num": 10
              }, 
              {
                "text": "Now a famine and great trouble came over all the land of Egypt and Canaan, and our fathers found no sustenance.", 
                "num": 11
              }, 
              {
                "text": "But when Jacob heard that there was grain in Egypt, he sent out our fathers first.", 
                "num": 12
              }, 
              {
                "text": "And the second time Joseph was made known to his brothers, and Joseph's family became known to the Pharaoh.", 
                "num": 13
              }, 
              {
                "text": "Then Joseph sent and called his father Jacob and all his relatives to him, seventy-five people.", 
                "num": 14
              }, 
              {
                "text": "So Jacob went down to Egypt; and he died, he and our fathers.", 
                "num": 15
              }, 
              {
                "text": "And they were carried back to Shechem and laid in the tomb that Abraham bought for a sum of money from the sons of Hamor, the father of Shechem.", 
                "num": 16
              }, 
              {
                "text": "\"But when the time of the promise drew near which God had sworn to Abraham, the people grew and multiplied in Egypt", 
                "num": 17
              }, 
              {
                "text": "till another king arose who did not know Joseph.", 
                "num": 18
              }, 
              {
                "text": "This man dealt treacherously with our people, and oppressed our forefathers, making them expose their babies, so that they might not live.", 
                "num": 19
              }, 
              {
                "text": "At this time Moses was born, and was well pleasing to God; and he was brought up in his father's house for three months.", 
                "num": 20
              }, 
              {
                "text": "But when he was set out, Pharaoh's daughter took him away and brought him up as her own son.", 
                "num": 21
              }, 
              {
                "text": "And Moses was learned in all the wisdom of the Egyptians, and was mighty in words and deeds.", 
                "num": 22
              }, 
              {
                "text": "\"Now when he was forty years old, it came into his heart to visit his brethren, the children of Israel.", 
                "num": 23
              }, 
              {
                "text": "And seeing one of them suffer wrong, he defended and avenged him who was oppressed, and struck down the Egyptian.", 
                "num": 24
              }, 
              {
                "text": "For he supposed that his brethren would have understood that God would deliver them by his hand, but they did not understand.", 
                "num": 25
              }, 
              {
                "text": "And the next day he appeared to two of them as they were fighting, and tried to reconcile them, saying, \"Men, you are brethren; why do you wrong one another?'", 
                "num": 26
              }, 
              {
                "text": "But he who did his neighbor wrong pushed him away, saying, \"Who made you a ruler and a judge over us?", 
                "num": 27
              }, 
              {
                "text": "Do you want to kill me as you did the Egyptian yesterday?'", 
                "num": 28
              }, 
              {
                "text": "Then, at this saying, Moses fled and became a dweller in the land of Midian, where he had two sons.", 
                "num": 29
              }, 
              {
                "text": "\"And when forty years had passed, an Angel of the Lord appeared to him in a flame of fire in a bush, in the wilderness of Mount Sinai.", 
                "num": 30
              }, 
              {
                "text": "When Moses saw it, he marveled at the sight; and as he drew near to observe, the voice of the Lord came to him,", 
                "num": 31
              }, 
              {
                "text": "saying, \"I am the God of your fathers--the God of Abraham, the God of Isaac, and the God of Jacob.' And Moses trembled and dared not look.", 
                "num": 32
              }, 
              {
                "text": "\"Then the LORD said to him, \"Take your sandals off your feet, for the place where you stand is holy ground.", 
                "num": 33
              }, 
              {
                "text": "I have surely seen the oppression of My people who are in Egypt; I have heard their groaning and have come down to deliver them. And now come, I will send you to Egypt.\"'", 
                "num": 34
              }, 
              {
                "text": "\"This Moses whom they rejected, saying, \"Who made you a ruler and a judge?' is the one God sent to be a ruler and a deliverer by the hand of the Angel who appeared to him in the bush.", 
                "num": 35
              }, 
              {
                "text": "He brought them out, after he had shown wonders and signs in the land of Egypt, and in the Red Sea, and in the wilderness forty years.", 
                "num": 36
              }, 
              {
                "text": "\"This is that Moses who said to the children of Israel, \"The LORD your God will raise up for you a Prophet like me from your brethren. Him you shall hear.'", 
                "num": 37
              }, 
              {
                "text": "\"This is he who was in the congregation in the wilderness with the Angel who spoke to him on Mount Sinai, and with our fathers, the one who received the living oracles to give to us,", 
                "num": 38
              }, 
              {
                "text": "whom our fathers would not obey, but rejected. And in their hearts they turned back to Egypt,", 
                "num": 39
              }, 
              {
                "text": "saying to Aaron, \"Make us gods to go before us; as for this Moses who brought us out of the land of Egypt, we do not know what has become of him.'", 
                "num": 40
              }, 
              {
                "text": "And they made a calf in those days, offered sacrifices to the idol, and rejoiced in the works of their own hands.", 
                "num": 41
              }, 
              {
                "text": "Then God turned and gave them up to worship the host of heaven, as it is written in the book of the Prophets: \"Did you offer Me slaughtered animals and sacrifices during forty years in the wilderness, O house of Israel?", 
                "num": 42
              }, 
              {
                "text": "You also took up the tabernacle of Moloch, And the star of your god Remphan, Images which you made to worship; And I will carry you away beyond Babylon.'", 
                "num": 43
              }, 
              {
                "text": "\"Our fathers had the tabernacle of witness in the wilderness, as He appointed, instructing Moses to make it according to the pattern that he had seen,", 
                "num": 44
              }, 
              {
                "text": "which our fathers, having received it in turn, also brought with Joshua into the land possessed by the Gentiles, whom God drove out before the face of our fathers until the days of David,", 
                "num": 45
              }, 
              {
                "text": "who found favor before God and asked to find a dwelling for the God of Jacob.", 
                "num": 46
              }, 
              {
                "text": "But Solomon built Him a house.", 
                "num": 47
              }, 
              {
                "text": "\"However, the Most High does not dwell in temples made with hands, as the prophet says:", 
                "num": 48
              }, 
              {
                "text": "\"Heaven is My throne, And earth is My footstool. What house will you build for Me? says the LORD, Or what is the place of My rest?", 
                "num": 49
              }, 
              {
                "text": "Has My hand not made all these things?'", 
                "num": 50
              }, 
              {
                "text": "\"You stiff-necked and uncircumcised in heart and ears! You always resist the Holy Spirit; as your fathers did, so do you.", 
                "num": 51
              }, 
              {
                "text": "Which of the prophets did your fathers not persecute? And they killed those who foretold the coming of the Just One, of whom you now have become the betrayers and murderers,", 
                "num": 52
              }, 
              {
                "text": "who have received the law by the direction of angels and have not kept it.\"", 
                "num": 53
              }, 
              {
                "text": "When they heard these things they were cut to the heart, and they gnashed at him with their teeth.", 
                "num": 54
              }, 
              {
                "text": "But he, being full of the Holy Spirit, gazed into heaven and saw the glory of God, and Jesus standing at the right hand of God,", 
                "num": 55
              }, 
              {
                "text": "and said, \"Look! I see the heavens opened and the Son of Man standing at the right hand of God!\"", 
                "num": 56
              }, 
              {
                "text": "Then they cried out with a loud voice, stopped their ears, and ran at him with one accord;", 
                "num": 57
              }, 
              {
                "text": "and they cast him out of the city and stoned him. And the witnesses laid down their clothes at the feet of a young man named Saul.", 
                "num": 58
              }, 
              {
                "text": "And they stoned Stephen as he was calling on God and saying, \"Lord Jesus, receive my spirit.\"", 
                "num": 59
              }, 
              {
                "text": "Then he knelt down and cried out with a loud voice, \"Lord, do not charge them with this sin.\" And when he had said this, he fell asleep.", 
                "num": 60
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now Saul was consenting to his death. At that time a great persecution arose against the church which was at Jerusalem; and they were all scattered throughout the regions of Judea and Samaria, except the apostles.", 
                "num": 1
              }, 
              {
                "text": "And devout men carried Stephen to his burial, and made great lamentation over him.", 
                "num": 2
              }, 
              {
                "text": "As for Saul, he made havoc of the church, entering every house, and dragging off men and women, committing them to prison.", 
                "num": 3
              }, 
              {
                "text": "Therefore those who were scattered went everywhere preaching the word.", 
                "num": 4
              }, 
              {
                "text": "Then Philip went down to the city of Samaria and preached Christ to them.", 
                "num": 5
              }, 
              {
                "text": "And the multitudes with one accord heeded the things spoken by Philip, hearing and seeing the miracles which he did.", 
                "num": 6
              }, 
              {
                "text": "For unclean spirits, crying with a loud voice, came out of many who were possessed; and many who were paralyzed and lame were healed.", 
                "num": 7
              }, 
              {
                "text": "And there was great joy in that city.", 
                "num": 8
              }, 
              {
                "text": "But there was a certain man called Simon, who previously practiced sorcery in the city and astonished the people of Samaria, claiming that he was someone great,", 
                "num": 9
              }, 
              {
                "text": "to whom they all gave heed, from the least to the greatest, saying, \"This man is the great power of God.\"", 
                "num": 10
              }, 
              {
                "text": "And they heeded him because he had astonished them with his sorceries for a long time.", 
                "num": 11
              }, 
              {
                "text": "But when they believed Philip as he preached the things concerning the kingdom of God and the name of Jesus Christ, both men and women were baptized.", 
                "num": 12
              }, 
              {
                "text": "Then Simon himself also believed; and when he was baptized he continued with Philip, and was amazed, seeing the miracles and signs which were done.", 
                "num": 13
              }, 
              {
                "text": "Now when the apostles who were at Jerusalem heard that Samaria had received the word of God, they sent Peter and John to them,", 
                "num": 14
              }, 
              {
                "text": "who, when they had come down, prayed for them that they might receive the Holy Spirit.", 
                "num": 15
              }, 
              {
                "text": "For as yet He had fallen upon none of them. They had only been baptized in the name of the Lord Jesus.", 
                "num": 16
              }, 
              {
                "text": "Then they laid hands on them, and they received the Holy Spirit.", 
                "num": 17
              }, 
              {
                "text": "And when Simon saw that through the laying on of the apostles' hands the Holy Spirit was given, he offered them money,", 
                "num": 18
              }, 
              {
                "text": "saying, \"Give me this power also, that anyone on whom I lay hands may receive the Holy Spirit.\"", 
                "num": 19
              }, 
              {
                "text": "But Peter said to him, \"Your money perish with you, because you thought that the gift of God could be purchased with money!", 
                "num": 20
              }, 
              {
                "text": "You have neither part nor portion in this matter, for your heart is not right in the sight of God.", 
                "num": 21
              }, 
              {
                "text": "Repent therefore of this your wickedness, and pray God if perhaps the thought of your heart may be forgiven you.", 
                "num": 22
              }, 
              {
                "text": "For I see that you are poisoned by bitterness and bound by iniquity.\"", 
                "num": 23
              }, 
              {
                "text": "Then Simon answered and said, \"Pray to the Lord for me, that none of the things which you have spoken may come upon me.\"", 
                "num": 24
              }, 
              {
                "text": "So when they had testified and preached the word of the Lord, they returned to Jerusalem, preaching the gospel in many villages of the Samaritans.", 
                "num": 25
              }, 
              {
                "text": "Now an angel of the Lord spoke to Philip, saying, \"Arise and go toward the south along the road which goes down from Jerusalem to Gaza.\" This is desert.", 
                "num": 26
              }, 
              {
                "text": "So he arose and went. And behold, a man of Ethiopia, a eunuch of great authority under Candace the queen of the Ethiopians, who had charge of all her treasury, and had come to Jerusalem to worship,", 
                "num": 27
              }, 
              {
                "text": "was returning. And sitting in his chariot, he was reading Isaiah the prophet.", 
                "num": 28
              }, 
              {
                "text": "Then the Spirit said to Philip, \"Go near and overtake this chariot.\"", 
                "num": 29
              }, 
              {
                "text": "So Philip ran to him, and heard him reading the prophet Isaiah, and said, \"Do you understand what you are reading?\"", 
                "num": 30
              }, 
              {
                "text": "And he said, \"How can I, unless someone guides me?\" And he asked Philip to come up and sit with him.", 
                "num": 31
              }, 
              {
                "text": "The place in the Scripture which he read was this: \"He was led as a sheep to the slaughter; And as a lamb before its shearer is silent, So He opened not His mouth.", 
                "num": 32
              }, 
              {
                "text": "In His humiliation His justice was taken away, And who will declare His generation? For His life is taken from the earth.\"", 
                "num": 33
              }, 
              {
                "text": "So the eunuch answered Philip and said, \"I ask you, of whom does the prophet say this, of himself or of some other man?\"", 
                "num": 34
              }, 
              {
                "text": "Then Philip opened his mouth, and beginning at this Scripture, preached Jesus to him.", 
                "num": 35
              }, 
              {
                "text": "Now as they went down the road, they came to some water. And the eunuch said, \"See, here is water. What hinders me from being baptized?\"", 
                "num": 36
              }, 
              {
                "text": "Then Philip said, \"If you believe with all your heart, you may.\" And he answered and said, \"I believe that Jesus Christ is the Son of God.\"", 
                "num": 37
              }, 
              {
                "text": "So he commanded the chariot to stand still. And both Philip and the eunuch went down into the water, and he baptized him.", 
                "num": 38
              }, 
              {
                "text": "Now when they came up out of the water, the Spirit of the Lord caught Philip away, so that the eunuch saw him no more; and he went on his way rejoicing.", 
                "num": 39
              }, 
              {
                "text": "But Philip was found at Azotus. And passing through, he preached in all the cities till he came to Caesarea.", 
                "num": 40
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then Saul, still breathing threats and murder against the disciples of the Lord, went to the high priest", 
                "num": 1
              }, 
              {
                "text": "and asked letters from him to the synagogues of Damascus, so that if he found any who were of the Way, whether men or women, he might bring them bound to Jerusalem.", 
                "num": 2
              }, 
              {
                "text": "As he journeyed he came near Damascus, and suddenly a light shone around him from heaven.", 
                "num": 3
              }, 
              {
                "text": "Then he fell to the ground, and heard a voice saying to him, \"Saul, Saul, why are you persecuting Me?\"", 
                "num": 4
              }, 
              {
                "text": "And he said, \"Who are You, Lord?\" Then the Lord said, \"I am Jesus, whom you are persecuting. It is hard for you to kick against the goads.\"", 
                "num": 5
              }, 
              {
                "text": "So he, trembling and astonished, said, \"Lord, what do You want me to do?\" Then the Lord said to him, \"Arise and go into the city, and you will be told what you must do.\"", 
                "num": 6
              }, 
              {
                "text": "And the men who journeyed with him stood speechless, hearing a voice but seeing no one.", 
                "num": 7
              }, 
              {
                "text": "Then Saul arose from the ground, and when his eyes were opened he saw no one. But they led him by the hand and brought him into Damascus.", 
                "num": 8
              }, 
              {
                "text": "And he was three days without sight, and neither ate nor drank.", 
                "num": 9
              }, 
              {
                "text": "Now there was a certain disciple at Damascus named Ananias; and to him the Lord said in a vision, \"Ananias.\" And he said, \"Here I am, Lord.\"", 
                "num": 10
              }, 
              {
                "text": "So the Lord said to him, \"Arise and go to the street called Straight, and inquire at the house of Judas for one called Saul of Tarsus, for behold, he is praying.", 
                "num": 11
              }, 
              {
                "text": "And in a vision he has seen a man named Ananias coming in and putting his hand on him, so that he might receive his sight.\"", 
                "num": 12
              }, 
              {
                "text": "Then Ananias answered, \"Lord, I have heard from many about this man, how much harm he has done to Your saints in Jerusalem.", 
                "num": 13
              }, 
              {
                "text": "And here he has authority from the chief priests to bind all who call on Your name.\"", 
                "num": 14
              }, 
              {
                "text": "But the Lord said to him, \"Go, for he is a chosen vessel of Mine to bear My name before Gentiles, kings, and the children of Israel.", 
                "num": 15
              }, 
              {
                "text": "For I will show him how many things he must suffer for My name's sake.\"", 
                "num": 16
              }, 
              {
                "text": "And Ananias went his way and entered the house; and laying his hands on him he said, \"Brother Saul, the Lord Jesus, who appeared to you on the road as you came, has sent me that you may receive your sight and be filled with the Holy Spirit.\"", 
                "num": 17
              }, 
              {
                "text": "Immediately there fell from his eyes something like scales, and he received his sight at once; and he arose and was baptized.", 
                "num": 18
              }, 
              {
                "text": "So when he had received food, he was strengthened. Then Saul spent some days with the disciples at Damascus.", 
                "num": 19
              }, 
              {
                "text": "Immediately he preached the Christ in the synagogues, that He is the Son of God.", 
                "num": 20
              }, 
              {
                "text": "Then all who heard were amazed, and said, \"Is this not he who destroyed those who called on this name in Jerusalem, and has come here for that purpose, so that he might bring them bound to the chief priests?\"", 
                "num": 21
              }, 
              {
                "text": "But Saul increased all the more in strength, and confounded the Jews who dwelt in Damascus, proving that this Jesus is the Christ.", 
                "num": 22
              }, 
              {
                "text": "Now after many days were past, the Jews plotted to kill him.", 
                "num": 23
              }, 
              {
                "text": "But their plot became known to Saul. And they watched the gates day and night, to kill him.", 
                "num": 24
              }, 
              {
                "text": "Then the disciples took him by night and let him down through the wall in a large basket.", 
                "num": 25
              }, 
              {
                "text": "And when Saul had come to Jerusalem, he tried to join the disciples; but they were all afraid of him, and did not believe that he was a disciple.", 
                "num": 26
              }, 
              {
                "text": "But Barnabas took him and brought him to the apostles. And he declared to them how he had seen the Lord on the road, and that He had spoken to him, and how he had preached boldly at Damascus in the name of Jesus.", 
                "num": 27
              }, 
              {
                "text": "So he was with them at Jerusalem, coming in and going out.", 
                "num": 28
              }, 
              {
                "text": "And he spoke boldly in the name of the Lord Jesus and disputed against the Hellenists, but they attempted to kill him.", 
                "num": 29
              }, 
              {
                "text": "When the brethren found out, they brought him down to Caesarea and sent him out to Tarsus.", 
                "num": 30
              }, 
              {
                "text": "Then the churches throughout all Judea, Galilee, and Samaria had peace and were edified. And walking in the fear of the Lord and in the comfort of the Holy Spirit, they were multiplied.", 
                "num": 31
              }, 
              {
                "text": "Now it came to pass, as Peter went through all parts of the country, that he also came down to the saints who dwelt in Lydda.", 
                "num": 32
              }, 
              {
                "text": "There he found a certain man named Aeneas, who had been bedridden eight years and was paralyzed.", 
                "num": 33
              }, 
              {
                "text": "And Peter said to him, \"Aeneas, Jesus the Christ heals you. Arise and make your bed.\" Then he arose immediately.", 
                "num": 34
              }, 
              {
                "text": "So all who dwelt at Lydda and Sharon saw him and turned to the Lord.", 
                "num": 35
              }, 
              {
                "text": "At Joppa there was a certain disciple named Tabitha, which is translated Dorcas. This woman was full of good works and charitable deeds which she did.", 
                "num": 36
              }, 
              {
                "text": "But it happened in those days that she became sick and died. When they had washed her, they laid her in an upper room.", 
                "num": 37
              }, 
              {
                "text": "And since Lydda was near Joppa, and the disciples had heard that Peter was there, they sent two men to him, imploring him not to delay in coming to them.", 
                "num": 38
              }, 
              {
                "text": "Then Peter arose and went with them. When he had come, they brought him to the upper room. And all the widows stood by him weeping, showing the tunics and garments which Dorcas had made while she was with them.", 
                "num": 39
              }, 
              {
                "text": "But Peter put them all out, and knelt down and prayed. And turning to the body he said, \"Tabitha, arise.\" And she opened her eyes, and when she saw Peter she sat up.", 
                "num": 40
              }, 
              {
                "text": "Then he gave her his hand and lifted her up; and when he had called the saints and widows, he presented her alive.", 
                "num": 41
              }, 
              {
                "text": "And it became known throughout all Joppa, and many believed on the Lord.", 
                "num": 42
              }, 
              {
                "text": "So it was that he stayed many days in Joppa with Simon, a tanner.", 
                "num": 43
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "There was a certain man in Caesarea called Cornelius, a centurion of what was called the Italian Regiment,", 
                "num": 1
              }, 
              {
                "text": "a devout man and one who feared God with all his household, who gave alms generously to the people, and prayed to God always.", 
                "num": 2
              }, 
              {
                "text": "About the ninth hour of the day he saw clearly in a vision an angel of God coming in and saying to him, \"Cornelius!\"", 
                "num": 3
              }, 
              {
                "text": "And when he observed him, he was afraid, and said, \"What is it, lord?\" So he said to him, \"Your prayers and your alms have come up for a memorial before God.", 
                "num": 4
              }, 
              {
                "text": "Now send men to Joppa, and send for Simon whose surname is Peter.", 
                "num": 5
              }, 
              {
                "text": "He is lodging with Simon, a tanner, whose house is by the sea. He will tell you what you must do.\"", 
                "num": 6
              }, 
              {
                "text": "And when the angel who spoke to him had departed, Cornelius called two of his household servants and a devout soldier from among those who waited on him continually.", 
                "num": 7
              }, 
              {
                "text": "So when he had explained all these things to them, he sent them to Joppa.", 
                "num": 8
              }, 
              {
                "text": "The next day, as they went on their journey and drew near the city, Peter went up on the housetop to pray, about the sixth hour.", 
                "num": 9
              }, 
              {
                "text": "Then he became very hungry and wanted to eat; but while they made ready, he fell into a trance", 
                "num": 10
              }, 
              {
                "text": "and saw heaven opened and an object like a great sheet bound at the four corners, descending to him and let down to the earth.", 
                "num": 11
              }, 
              {
                "text": "In it were all kinds of four-footed animals of the earth, wild beasts, creeping things, and birds of the air.", 
                "num": 12
              }, 
              {
                "text": "And a voice came to him, \"Rise, Peter; kill and eat.\"", 
                "num": 13
              }, 
              {
                "text": "But Peter said, \"Not so, Lord! For I have never eaten anything common or unclean.\"", 
                "num": 14
              }, 
              {
                "text": "And a voice spoke to him again the second time, \"What God has cleansed you must not call common.\"", 
                "num": 15
              }, 
              {
                "text": "This was done three times. And the object was taken up into heaven again.", 
                "num": 16
              }, 
              {
                "text": "Now while Peter wondered within himself what this vision which he had seen meant, behold, the men who had been sent from Cornelius had made inquiry for Simon's house, and stood before the gate.", 
                "num": 17
              }, 
              {
                "text": "And they called and asked whether Simon, whose surname was Peter, was lodging there.", 
                "num": 18
              }, 
              {
                "text": "While Peter thought about the vision, the Spirit said to him, \"Behold, three men are seeking you.", 
                "num": 19
              }, 
              {
                "text": "Arise therefore, go down and go with them, doubting nothing; for I have sent them.\"", 
                "num": 20
              }, 
              {
                "text": "Then Peter went down to the men who had been sent to him from Cornelius, and said, \"Yes, I am he whom you seek. For what reason have you come?\"", 
                "num": 21
              }, 
              {
                "text": "And they said, \"Cornelius the centurion, a just man, one who fears God and has a good reputation among all the nation of the Jews, was divinely instructed by a holy angel to summon you to his house, and to hear words from you.\"", 
                "num": 22
              }, 
              {
                "text": "Then he invited them in and lodged them. On the next day Peter went away with them, and some brethren from Joppa accompanied him.", 
                "num": 23
              }, 
              {
                "text": "And the following day they entered Caesarea. Now Cornelius was waiting for them, and had called together his relatives and close friends.", 
                "num": 24
              }, 
              {
                "text": "As Peter was coming in, Cornelius met him and fell down at his feet and worshiped him.", 
                "num": 25
              }, 
              {
                "text": "But Peter lifted him up, saying, \"Stand up; I myself am also a man.\"", 
                "num": 26
              }, 
              {
                "text": "And as he talked with him, he went in and found many who had come together.", 
                "num": 27
              }, 
              {
                "text": "Then he said to them, \"You know how unlawful it is for a Jewish man to keep company with or go to one of another nation. But God has shown me that I should not call any man common or unclean.", 
                "num": 28
              }, 
              {
                "text": "Therefore I came without objection as soon as I was sent for. I ask, then, for what reason have you sent for me?\"", 
                "num": 29
              }, 
              {
                "text": "So Cornelius said, \"Four days ago I was fasting until this hour; and at the ninth hour I prayed in my house, and behold, a man stood before me in bright clothing,", 
                "num": 30
              }, 
              {
                "text": "and said, \"Cornelius, your prayer has been heard, and your alms are remembered in the sight of God.", 
                "num": 31
              }, 
              {
                "text": "Send therefore to Joppa and call Simon here, whose surname is Peter. He is lodging in the house of Simon, a tanner, by the sea. When he comes, he will speak to you.'", 
                "num": 32
              }, 
              {
                "text": "So I sent to you immediately, and you have done well to come. Now therefore, we are all present before God, to hear all the things commanded you by God.\"", 
                "num": 33
              }, 
              {
                "text": "Then Peter opened his mouth and said: \"In truth I perceive that God shows no partiality.", 
                "num": 34
              }, 
              {
                "text": "But in every nation whoever fears Him and works righteousness is accepted by Him.", 
                "num": 35
              }, 
              {
                "text": "The word which God sent to the children of Israel, preaching peace through Jesus Christ--He is Lord of all--", 
                "num": 36
              }, 
              {
                "text": "that word you know, which was proclaimed throughout all Judea, and began from Galilee after the baptism which John preached:", 
                "num": 37
              }, 
              {
                "text": "how God anointed Jesus of Nazareth with the Holy Spirit and with power, who went about doing good and healing all who were oppressed by the devil, for God was with Him.", 
                "num": 38
              }, 
              {
                "text": "And we are witnesses of all things which He did both in the land of the Jews and in Jerusalem, whom they killed by hanging on a tree.", 
                "num": 39
              }, 
              {
                "text": "Him God raised up on the third day, and showed Him openly,", 
                "num": 40
              }, 
              {
                "text": "not to all the people, but to witnesses chosen before by God, even to us who ate and drank with Him after He arose from the dead.", 
                "num": 41
              }, 
              {
                "text": "And He commanded us to preach to the people, and to testify that it is He who was ordained by God to be Judge of the living and the dead.", 
                "num": 42
              }, 
              {
                "text": "To Him all the prophets witness that, through His name, whoever believes in Him will receive remission of sins.\"", 
                "num": 43
              }, 
              {
                "text": "While Peter was still speaking these words, the Holy Spirit fell upon all those who heard the word.", 
                "num": 44
              }, 
              {
                "text": "And those of the circumcision who believed were astonished, as many as came with Peter, because the gift of the Holy Spirit had been poured out on the Gentiles also.", 
                "num": 45
              }, 
              {
                "text": "For they heard them speak with tongues and magnify God. Then Peter answered,", 
                "num": 46
              }, 
              {
                "text": "\"Can anyone forbid water, that these should not be baptized who have received the Holy Spirit just as we have?\"", 
                "num": 47
              }, 
              {
                "text": "And he commanded them to be baptized in the name of the Lord. Then they asked him to stay a few days.", 
                "num": 48
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now the apostles and brethren who were in Judea heard that the Gentiles had also received the word of God.", 
                "num": 1
              }, 
              {
                "text": "And when Peter came up to Jerusalem, those of the circumcision contended with him,", 
                "num": 2
              }, 
              {
                "text": "saying, \"You went in to uncircumcised men and ate with them!\"", 
                "num": 3
              }, 
              {
                "text": "But Peter explained it to them in order from the beginning, saying:", 
                "num": 4
              }, 
              {
                "text": "\"I was in the city of Joppa praying; and in a trance I saw a vision, an object descending like a great sheet, let down from heaven by four corners; and it came to me.", 
                "num": 5
              }, 
              {
                "text": "When I observed it intently and considered, I saw four-footed animals of the earth, wild beasts, creeping things, and birds of the air.", 
                "num": 6
              }, 
              {
                "text": "And I heard a voice saying to me, \"Rise, Peter; kill and eat.'", 
                "num": 7
              }, 
              {
                "text": "But I said, \"Not so, Lord! For nothing common or unclean has at any time entered my mouth.'", 
                "num": 8
              }, 
              {
                "text": "But the voice answered me again from heaven, \"What God has cleansed you must not call common.'", 
                "num": 9
              }, 
              {
                "text": "Now this was done three times, and all were drawn up again into heaven.", 
                "num": 10
              }, 
              {
                "text": "At that very moment, three men stood before the house where I was, having been sent to me from Caesarea.", 
                "num": 11
              }, 
              {
                "text": "Then the Spirit told me to go with them, doubting nothing. Moreover these six brethren accompanied me, and we entered the man's house.", 
                "num": 12
              }, 
              {
                "text": "And he told us how he had seen an angel standing in his house, who said to him, \"Send men to Joppa, and call for Simon whose surname is Peter,", 
                "num": 13
              }, 
              {
                "text": "who will tell you words by which you and all your household will be saved.'", 
                "num": 14
              }, 
              {
                "text": "And as I began to speak, the Holy Spirit fell upon them, as upon us at the beginning.", 
                "num": 15
              }, 
              {
                "text": "Then I remembered the word of the Lord, how He said, \"John indeed baptized with water, but you shall be baptized with the Holy Spirit.'", 
                "num": 16
              }, 
              {
                "text": "If therefore God gave them the same gift as He gave us when we believed on the Lord Jesus Christ, who was I that I could withstand God?\"", 
                "num": 17
              }, 
              {
                "text": "When they heard these things they became silent; and they glorified God, saying, \"Then God has also granted to the Gentiles repentance to life.\"", 
                "num": 18
              }, 
              {
                "text": "Now those who were scattered after the persecution that arose over Stephen traveled as far as Phoenicia, Cyprus, and Antioch, preaching the word to no one but the Jews only.", 
                "num": 19
              }, 
              {
                "text": "But some of them were men from Cyprus and Cyrene, who, when they had come to Antioch, spoke to the Hellenists, preaching the Lord Jesus.", 
                "num": 20
              }, 
              {
                "text": "And the hand of the Lord was with them, and a great number believed and turned to the Lord.", 
                "num": 21
              }, 
              {
                "text": "Then news of these things came to the ears of the church in Jerusalem, and they sent out Barnabas to go as far as Antioch.", 
                "num": 22
              }, 
              {
                "text": "When he came and had seen the grace of God, he was glad, and encouraged them all that with purpose of heart they should continue with the Lord.", 
                "num": 23
              }, 
              {
                "text": "For he was a good man, full of the Holy Spirit and of faith. And a great many people were added to the Lord.", 
                "num": 24
              }, 
              {
                "text": "Then Barnabas departed for Tarsus to seek Saul.", 
                "num": 25
              }, 
              {
                "text": "And when he had found him, he brought him to Antioch. So it was that for a whole year they assembled with the church and taught a great many people. And the disciples were first called Christians in Antioch.", 
                "num": 26
              }, 
              {
                "text": "And in these days prophets came from Jerusalem to Antioch.", 
                "num": 27
              }, 
              {
                "text": "Then one of them, named Agabus, stood up and showed by the Spirit that there was going to be a great famine throughout all the world, which also happened in the days of Claudius Caesar.", 
                "num": 28
              }, 
              {
                "text": "Then the disciples, each according to his ability, determined to send relief to the brethren dwelling in Judea.", 
                "num": 29
              }, 
              {
                "text": "This they also did, and sent it to the elders by the hands of Barnabas and Saul.", 
                "num": 30
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now about that time Herod the king stretched out his hand to harass some from the church.", 
                "num": 1
              }, 
              {
                "text": "Then he killed James the brother of John with the sword.", 
                "num": 2
              }, 
              {
                "text": "And because he saw that it pleased the Jews, he proceeded further to seize Peter also. Now it was during the Days of Unleavened Bread.", 
                "num": 3
              }, 
              {
                "text": "So when he had arrested him, he put him in prison, and delivered him to four squads of soldiers to keep him, intending to bring him before the people after Passover.", 
                "num": 4
              }, 
              {
                "text": "Peter was therefore kept in prison, but constant prayer was offered to God for him by the church.", 
                "num": 5
              }, 
              {
                "text": "And when Herod was about to bring him out, that night Peter was sleeping, bound with two chains between two soldiers; and the guards before the door were keeping the prison.", 
                "num": 6
              }, 
              {
                "text": "Now behold, an angel of the Lord stood by him, and a light shone in the prison; and he struck Peter on the side and raised him up, saying, \"Arise quickly!\" And his chains fell off his hands.", 
                "num": 7
              }, 
              {
                "text": "Then the angel said to him, \"Gird yourself and tie on your sandals\"; and so he did. And he said to him, \"Put on your garment and follow me.\"", 
                "num": 8
              }, 
              {
                "text": "So he went out and followed him, and did not know that what was done by the angel was real, but thought he was seeing a vision.", 
                "num": 9
              }, 
              {
                "text": "When they were past the first and the second guard posts, they came to the iron gate that leads to the city, which opened to them of its own accord; and they went out and went down one street, and immediately the angel departed from him.", 
                "num": 10
              }, 
              {
                "text": "And when Peter had come to himself, he said, \"Now I know for certain that the Lord has sent His angel, and has delivered me from the hand of Herod and from all the expectation of the Jewish people.\"", 
                "num": 11
              }, 
              {
                "text": "So, when he had considered this, he came to the house of Mary, the mother of John whose surname was Mark, where many were gathered together praying.", 
                "num": 12
              }, 
              {
                "text": "And as Peter knocked at the door of the gate, a girl named Rhoda came to answer.", 
                "num": 13
              }, 
              {
                "text": "When she recognized Peter's voice, because of her gladness she did not open the gate, but ran in and announced that Peter stood before the gate.", 
                "num": 14
              }, 
              {
                "text": "But they said to her, \"You are beside yourself!\" Yet she kept insisting that it was so. So they said, \"It is his angel.\"", 
                "num": 15
              }, 
              {
                "text": "Now Peter continued knocking; and when they opened the door and saw him, they were astonished.", 
                "num": 16
              }, 
              {
                "text": "But motioning to them with his hand to keep silent, he declared to them how the Lord had brought him out of the prison. And he said, \"Go, tell these things to James and to the brethren.\" And he departed and went to another place.", 
                "num": 17
              }, 
              {
                "text": "Then, as soon as it was day, there was no small stir among the soldiers about what had become of Peter.", 
                "num": 18
              }, 
              {
                "text": "But when Herod had searched for him and not found him, he examined the guards and commanded that they should be put to death. And he went down from Judea to Caesarea, and stayed there.", 
                "num": 19
              }, 
              {
                "text": "Now Herod had been very angry with the people of Tyre and Sidon; but they came to him with one accord, and having made Blastus the king's personal aide their friend, they asked for peace, because their country was supplied with food by the king's country.", 
                "num": 20
              }, 
              {
                "text": "So on a set day Herod, arrayed in royal apparel, sat on his throne and gave an oration to them.", 
                "num": 21
              }, 
              {
                "text": "And the people kept shouting, \"The voice of a god and not of a man!\"", 
                "num": 22
              }, 
              {
                "text": "Then immediately an angel of the Lord struck him, because he did not give glory to God. And he was eaten by worms and died.", 
                "num": 23
              }, 
              {
                "text": "But the word of God grew and multiplied.", 
                "num": 24
              }, 
              {
                "text": "And Barnabas and Saul returned from Jerusalem when they had fulfilled their ministry, and they also took with them John whose surname was Mark.", 
                "num": 25
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Now in the church that was at Antioch there were certain prophets and teachers: Barnabas, Simeon who was called Niger, Lucius of Cyrene, Manaen who had been brought up with Herod the tetrarch, and Saul.", 
                "num": 1
              }, 
              {
                "text": "As they ministered to the Lord and fasted, the Holy Spirit said, \"Now separate to Me Barnabas and Saul for the work to which I have called them.\"", 
                "num": 2
              }, 
              {
                "text": "Then, having fasted and prayed, and laid hands on them, they sent them away.", 
                "num": 3
              }, 
              {
                "text": "So, being sent out by the Holy Spirit, they went down to Seleucia, and from there they sailed to Cyprus.", 
                "num": 4
              }, 
              {
                "text": "And when they arrived in Salamis, they preached the word of God in the synagogues of the Jews. They also had John as their assistant.", 
                "num": 5
              }, 
              {
                "text": "Now when they had gone through the island to Paphos, they found a certain sorcerer, a false prophet, a Jew whose name was Bar-Jesus,", 
                "num": 6
              }, 
              {
                "text": "who was with the proconsul, Sergius Paulus, an intelligent man. This man called for Barnabas and Saul and sought to hear the word of God.", 
                "num": 7
              }, 
              {
                "text": "But Elymas the sorcerer (for so his name is translated) withstood them, seeking to turn the proconsul away from the faith.", 
                "num": 8
              }, 
              {
                "text": "Then Saul, who also is called Paul, filled with the Holy Spirit, looked intently at him", 
                "num": 9
              }, 
              {
                "text": "and said, \"O full of all deceit and all fraud, you son of the devil, you enemy of all righteousness, will you not cease perverting the straight ways of the Lord?", 
                "num": 10
              }, 
              {
                "text": "And now, indeed, the hand of the Lord is upon you, and you shall be blind, not seeing the sun for a time.\" And immediately a dark mist fell on him, and he went around seeking someone to lead him by the hand.", 
                "num": 11
              }, 
              {
                "text": "Then the proconsul believed, when he saw what had been done, being astonished at the teaching of the Lord.", 
                "num": 12
              }, 
              {
                "text": "Now when Paul and his party set sail from Paphos, they came to Perga in Pamphylia; and John, departing from them, returned to Jerusalem.", 
                "num": 13
              }, 
              {
                "text": "But when they departed from Perga, they came to Antioch in Pisidia, and went into the synagogue on the Sabbath day and sat down.", 
                "num": 14
              }, 
              {
                "text": "And after the reading of the Law and the Prophets, the rulers of the synagogue sent to them, saying, \"Men and brethren, if you have any word of exhortation for the people, say on.\"", 
                "num": 15
              }, 
              {
                "text": "Then Paul stood up, and motioning with his hand said, \"Men of Israel, and you who fear God, listen:", 
                "num": 16
              }, 
              {
                "text": "The God of this people Israel chose our fathers, and exalted the people when they dwelt as strangers in the land of Egypt, and with an uplifted arm He brought them out of it.", 
                "num": 17
              }, 
              {
                "text": "Now for a time of about forty years He put up with their ways in the wilderness.", 
                "num": 18
              }, 
              {
                "text": "And when He had destroyed seven nations in the land of Canaan, He distributed their land to them by allotment.", 
                "num": 19
              }, 
              {
                "text": "\"After that He gave them judges for about four hundred and fifty years, until Samuel the prophet.", 
                "num": 20
              }, 
              {
                "text": "And afterward they asked for a king; so God gave them Saul the son of Kish, a man of the tribe of Benjamin, for forty years.", 
                "num": 21
              }, 
              {
                "text": "And when He had removed him, He raised up for them David as king, to whom also He gave testimony and said, \"I have found David the son of Jesse, a man after My own heart, who will do all My will.'", 
                "num": 22
              }, 
              {
                "text": "From this man's seed, according to the promise, God raised up for Israel a Savior--Jesus--", 
                "num": 23
              }, 
              {
                "text": "after John had first preached, before His coming, the baptism of repentance to all the people of Israel.", 
                "num": 24
              }, 
              {
                "text": "And as John was finishing his course, he said, \"Who do you think I am? I am not He. But behold, there comes One after me, the sandals of whose feet I am not worthy to loose.'", 
                "num": 25
              }, 
              {
                "text": "\"Men and brethren, sons of the family of Abraham, and those among you who fear God, to you the word of this salvation has been sent.", 
                "num": 26
              }, 
              {
                "text": "For those who dwell in Jerusalem, and their rulers, because they did not know Him, nor even the voices of the Prophets which are read every Sabbath, have fulfilled them in condemning Him.", 
                "num": 27
              }, 
              {
                "text": "And though they found no cause for death in Him, they asked Pilate that He should be put to death.", 
                "num": 28
              }, 
              {
                "text": "Now when they had fulfilled all that was written concerning Him, they took Him down from the tree and laid Him in a tomb.", 
                "num": 29
              }, 
              {
                "text": "But God raised Him from the dead.", 
                "num": 30
              }, 
              {
                "text": "He was seen for many days by those who came up with Him from Galilee to Jerusalem, who are His witnesses to the people.", 
                "num": 31
              }, 
              {
                "text": "And we declare to you glad tidings--that promise which was made to the fathers.", 
                "num": 32
              }, 
              {
                "text": "God has fulfilled this for us their children, in that He has raised up Jesus. As it is also written in the second Psalm: \"You are My Son, Today I have begotten You.'", 
                "num": 33
              }, 
              {
                "text": "And that He raised Him from the dead, no more to return to corruption, He has spoken thus: \"I will give you the sure mercies of David.'", 
                "num": 34
              }, 
              {
                "text": "Therefore He also says in another Psalm: \"You will not allow Your Holy One to see corruption.'", 
                "num": 35
              }, 
              {
                "text": "\"For David, after he had served his own generation by the will of God, fell asleep, was buried with his fathers, and saw corruption;", 
                "num": 36
              }, 
              {
                "text": "but He whom God raised up saw no corruption.", 
                "num": 37
              }, 
              {
                "text": "Therefore let it be known to you, brethren, that through this Man is preached to you the forgiveness of sins;", 
                "num": 38
              }, 
              {
                "text": "and by Him everyone who believes is justified from all things from which you could not be justified by the law of Moses.", 
                "num": 39
              }, 
              {
                "text": "Beware therefore, lest what has been spoken in the prophets come upon you:", 
                "num": 40
              }, 
              {
                "text": "\"Behold, you despisers, Marvel and perish! For I work a work in your days, A work which you will by no means believe, Though one were to declare it to you.\"'", 
                "num": 41
              }, 
              {
                "text": "So when the Jews went out of the synagogue, the Gentiles begged that these words might be preached to them the next Sabbath.", 
                "num": 42
              }, 
              {
                "text": "Now when the congregation had broken up, many of the Jews and devout proselytes followed Paul and Barnabas, who, speaking to them, persuaded them to continue in the grace of God.", 
                "num": 43
              }, 
              {
                "text": "On the next Sabbath almost the whole city came together to hear the word of God.", 
                "num": 44
              }, 
              {
                "text": "But when the Jews saw the multitudes, they were filled with envy; and contradicting and blaspheming, they opposed the things spoken by Paul.", 
                "num": 45
              }, 
              {
                "text": "Then Paul and Barnabas grew bold and said, \"It was necessary that the word of God should be spoken to you first; but since you reject it, and judge yourselves unworthy of everlasting life, behold, we turn to the Gentiles.", 
                "num": 46
              }, 
              {
                "text": "For so the Lord has commanded us: \"I have set you as a light to the Gentiles, That you should be for salvation to the ends of the earth.\"'", 
                "num": 47
              }, 
              {
                "text": "Now when the Gentiles heard this, they were glad and glorified the word of the Lord. And as many as had been appointed to eternal life believed.", 
                "num": 48
              }, 
              {
                "text": "And the word of the Lord was being spread throughout all the region.", 
                "num": 49
              }, 
              {
                "text": "But the Jews stirred up the devout and prominent women and the chief men of the city, raised up persecution against Paul and Barnabas, and expelled them from their region.", 
                "num": 50
              }, 
              {
                "text": "But they shook off the dust from their feet against them, and came to Iconium.", 
                "num": 51
              }, 
              {
                "text": "And the disciples were filled with joy and with the Holy Spirit.", 
                "num": 52
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Now it happened in Iconium that they went together to the synagogue of the Jews, and so spoke that a great multitude both of the Jews and of the Greeks believed.", 
                "num": 1
              }, 
              {
                "text": "But the unbelieving Jews stirred up the Gentiles and poisoned their minds against the brethren.", 
                "num": 2
              }, 
              {
                "text": "Therefore they stayed there a long time, speaking boldly in the Lord, who was bearing witness to the word of His grace, granting signs and wonders to be done by their hands.", 
                "num": 3
              }, 
              {
                "text": "But the multitude of the city was divided: part sided with the Jews, and part with the apostles.", 
                "num": 4
              }, 
              {
                "text": "And when a violent attempt was made by both the Gentiles and Jews, with their rulers, to abuse and stone them,", 
                "num": 5
              }, 
              {
                "text": "they became aware of it and fled to Lystra and Derbe, cities of Lycaonia, and to the surrounding region.", 
                "num": 6
              }, 
              {
                "text": "And they were preaching the gospel there.", 
                "num": 7
              }, 
              {
                "text": "And in Lystra a certain man without strength in his feet was sitting, a cripple from his mother's womb, who had never walked.", 
                "num": 8
              }, 
              {
                "text": "This man heard Paul speaking. Paul, observing him intently and seeing that he had faith to be healed,", 
                "num": 9
              }, 
              {
                "text": "said with a loud voice, \"Stand up straight on your feet!\" And he leaped and walked.", 
                "num": 10
              }, 
              {
                "text": "Now when the people saw what Paul had done, they raised their voices, saying in the Lycaonian language, \"The gods have come down to us in the likeness of men!\"", 
                "num": 11
              }, 
              {
                "text": "And Barnabas they called Zeus, and Paul, Hermes, because he was the chief speaker.", 
                "num": 12
              }, 
              {
                "text": "Then the priest of Zeus, whose temple was in front of their city, brought oxen and garlands to the gates, intending to sacrifice with the multitudes.", 
                "num": 13
              }, 
              {
                "text": "But when the apostles Barnabas and Paul heard this, they tore their clothes and ran in among the multitude, crying out", 
                "num": 14
              }, 
              {
                "text": "and saying, \"Men, why are you doing these things? We also are men with the same nature as you, and preach to you that you should turn from these useless things to the living God, who made the heaven, the earth, the sea, and all things that are in them,", 
                "num": 15
              }, 
              {
                "text": "who in bygone generations allowed all nations to walk in their own ways.", 
                "num": 16
              }, 
              {
                "text": "Nevertheless He did not leave Himself without witness, in that He did good, gave us rain from heaven and fruitful seasons, filling our hearts with food and gladness.\"", 
                "num": 17
              }, 
              {
                "text": "And with these sayings they could scarcely restrain the multitudes from sacrificing to them.", 
                "num": 18
              }, 
              {
                "text": "Then Jews from Antioch and Iconium came there; and having persuaded the multitudes, they stoned Paul and dragged him out of the city, supposing him to be dead.", 
                "num": 19
              }, 
              {
                "text": "However, when the disciples gathered around him, he rose up and went into the city. And the next day he departed with Barnabas to Derbe.", 
                "num": 20
              }, 
              {
                "text": "And when they had preached the gospel to that city and made many disciples, they returned to Lystra, Iconium, and Antioch,", 
                "num": 21
              }, 
              {
                "text": "strengthening the souls of the disciples, exhorting them to continue in the faith, and saying, \"We must through many tribulations enter the kingdom of God.\"", 
                "num": 22
              }, 
              {
                "text": "So when they had appointed elders in every church, and prayed with fasting, they commended them to the Lord in whom they had believed.", 
                "num": 23
              }, 
              {
                "text": "And after they had passed through Pisidia, they came to Pamphylia.", 
                "num": 24
              }, 
              {
                "text": "Now when they had preached the word in Perga, they went down to Attalia.", 
                "num": 25
              }, 
              {
                "text": "From there they sailed to Antioch, where they had been commended to the grace of God for the work which they had completed.", 
                "num": 26
              }, 
              {
                "text": "Now when they had come and gathered the church together, they reported all that God had done with them, and that He had opened the door of faith to the Gentiles.", 
                "num": 27
              }, 
              {
                "text": "So they stayed there a long time with the disciples.", 
                "num": 28
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "And certain men came down from Judea and taught the brethren, \"Unless you are circumcised according to the custom of Moses, you cannot be saved.\"", 
                "num": 1
              }, 
              {
                "text": "Therefore, when Paul and Barnabas had no small dissension and dispute with them, they determined that Paul and Barnabas and certain others of them should go up to Jerusalem, to the apostles and elders, about this question.", 
                "num": 2
              }, 
              {
                "text": "So, being sent on their way by the church, they passed through Phoenicia and Samaria, describing the conversion of the Gentiles; and they caused great joy to all the brethren.", 
                "num": 3
              }, 
              {
                "text": "And when they had come to Jerusalem, they were received by the church and the apostles and the elders; and they reported all things that God had done with them.", 
                "num": 4
              }, 
              {
                "text": "But some of the sect of the Pharisees who believed rose up, saying, \"It is necessary to circumcise them, and to command them to keep the law of Moses.\"", 
                "num": 5
              }, 
              {
                "text": "Now the apostles and elders came together to consider this matter.", 
                "num": 6
              }, 
              {
                "text": "And when there had been much dispute, Peter rose up and said to them: \"Men and brethren, you know that a good while ago God chose among us, that by my mouth the Gentiles should hear the word of the gospel and believe.", 
                "num": 7
              }, 
              {
                "text": "So God, who knows the heart, acknowledged them by giving them the Holy Spirit, just as He did to us,", 
                "num": 8
              }, 
              {
                "text": "and made no distinction between us and them, purifying their hearts by faith.", 
                "num": 9
              }, 
              {
                "text": "Now therefore, why do you test God by putting a yoke on the neck of the disciples which neither our fathers nor we were able to bear?", 
                "num": 10
              }, 
              {
                "text": "But we believe that through the grace of the Lord Jesus Christ we shall be saved in the same manner as they.\"", 
                "num": 11
              }, 
              {
                "text": "Then all the multitude kept silent and listened to Barnabas and Paul declaring how many miracles and wonders God had worked through them among the Gentiles.", 
                "num": 12
              }, 
              {
                "text": "And after they had become silent, James answered, saying, \"Men and brethren, listen to me:", 
                "num": 13
              }, 
              {
                "text": "Simon has declared how God at the first visited the Gentiles to take out of them a people for His name.", 
                "num": 14
              }, 
              {
                "text": "And with this the words of the prophets agree, just as it is written:", 
                "num": 15
              }, 
              {
                "text": "'After this I will return And will rebuild the tabernacle of David, which has fallen down; I will rebuild its ruins, And I will set it up;", 
                "num": 16
              }, 
              {
                "text": "So that the rest of mankind may seek the LORD. Even all the Gentiles who are called by My name, Says the LORD who does all these things.'", 
                "num": 17
              }, 
              {
                "text": "\"Known to God from eternity are all His works.", 
                "num": 18
              }, 
              {
                "text": "Therefore I judge that we should not trouble those from among the Gentiles who are turning to God,", 
                "num": 19
              }, 
              {
                "text": "but that we write to them to abstain from things polluted by idols, from sexual immorality, from things strangled, and from blood.", 
                "num": 20
              }, 
              {
                "text": "For Moses has had throughout many generations those who preach him in every city, being read in the synagogues every Sabbath.\"", 
                "num": 21
              }, 
              {
                "text": "Then it pleased the apostles and elders, with the whole church, to send chosen men of their own company to Antioch with Paul and Barnabas, namely, Judas who was also named Barsabas, and Silas, leading men among the brethren.", 
                "num": 22
              }, 
              {
                "text": "They wrote this, letter by them: The apostles, the elders, and the brethren, To the brethren who are of the Gentiles in Antioch, Syria, and Cilicia: Greetings.", 
                "num": 23
              }, 
              {
                "text": "Since we have heard that some who went out from us have troubled you with words, unsettling your souls, saying, \"You must be circumcised and keep the law\"--to whom we gave no such commandment--", 
                "num": 24
              }, 
              {
                "text": "it seemed good to us, being assembled with one accord, to send chosen men to you with our beloved Barnabas and Paul,", 
                "num": 25
              }, 
              {
                "text": "men who have risked their lives for the name of our Lord Jesus Christ.", 
                "num": 26
              }, 
              {
                "text": "We have therefore sent Judas and Silas, who will also report the same things by word of mouth.", 
                "num": 27
              }, 
              {
                "text": "For it seemed good to the Holy Spirit, and to us, to lay upon you no greater burden than these necessary things:", 
                "num": 28
              }, 
              {
                "text": "that you abstain from things offered to idols, from blood, from things strangled, and from sexual immorality. If you keep yourselves from these, you will do well. Farewell.", 
                "num": 29
              }, 
              {
                "text": "So when they were sent off, they came to Antioch; and when they had gathered the multitude together, they delivered the letter.", 
                "num": 30
              }, 
              {
                "text": "When they had read it, they rejoiced over its encouragement.", 
                "num": 31
              }, 
              {
                "text": "Now Judas and Silas, themselves being prophets also, exhorted and strengthened the brethren with many words.", 
                "num": 32
              }, 
              {
                "text": "And after they had stayed there for a time, they were sent back with greetings from the brethren to the apostles.", 
                "num": 33
              }, 
              {
                "text": "However, it seemed good to Silas to remain there.", 
                "num": 34
              }, 
              {
                "text": "Paul and Barnabas also remained in Antioch, teaching and preaching the word of the Lord, with many others also.", 
                "num": 35
              }, 
              {
                "text": "Then after some days Paul said to Barnabas, \"Let us now go back and visit our brethren in every city where we have preached the word of the Lord, and see how they are doing.\"", 
                "num": 36
              }, 
              {
                "text": "Now Barnabas was determined to take with them John called Mark.", 
                "num": 37
              }, 
              {
                "text": "But Paul insisted that they should not take with them the one who had departed from them in Pamphylia, and had not gone with them to the work.", 
                "num": 38
              }, 
              {
                "text": "Then the contention became so sharp that they parted from one another. And so Barnabas took Mark and sailed to Cyprus;", 
                "num": 39
              }, 
              {
                "text": "but Paul chose Silas and departed, being commended by the brethren to the grace of God.", 
                "num": 40
              }, 
              {
                "text": "And he went through Syria and Cilicia, strengthening the churches.", 
                "num": 41
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Then he came to Derbe and Lystra. And behold, a certain disciple was there, named Timothy, the son of a certain Jewish woman who believed, but his father was Greek.", 
                "num": 1
              }, 
              {
                "text": "He was well spoken of by the brethren who were at Lystra and Iconium.", 
                "num": 2
              }, 
              {
                "text": "Paul wanted to have him go on with him. And he took him and circumcised him because of the Jews who were in that region, for they all knew that his father was Greek.", 
                "num": 3
              }, 
              {
                "text": "And as they went through the cities, they delivered to them the decrees to keep, which were determined by the apostles and elders at Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "So the churches were strengthened in the faith, and increased in number daily.", 
                "num": 5
              }, 
              {
                "text": "Now when they had gone through Phrygia and the region of Galatia, they were forbidden by the Holy Spirit to preach the word in Asia.", 
                "num": 6
              }, 
              {
                "text": "After they had come to Mysia, they tried to go into Bithynia, but the Spirit did not permit them.", 
                "num": 7
              }, 
              {
                "text": "So passing by Mysia, they came down to Troas.", 
                "num": 8
              }, 
              {
                "text": "And a vision appeared to Paul in the night. A man of Macedonia stood and pleaded with him, saying, \"Come over to Macedonia and help us.\"", 
                "num": 9
              }, 
              {
                "text": "Now after he had seen the vision, immediately we sought to go to Macedonia, concluding that the Lord had called us to preach the gospel to them.", 
                "num": 10
              }, 
              {
                "text": "Therefore, sailing from Troas, we ran a straight course to Samothrace, and the next day came to Neapolis,", 
                "num": 11
              }, 
              {
                "text": "and from there to Philippi, which is the foremost city of that part of Macedonia, a colony. And we were staying in that city for some days.", 
                "num": 12
              }, 
              {
                "text": "And on the Sabbath day we went out of the city to the riverside, where prayer was customarily made; and we sat down and spoke to the women who met there.", 
                "num": 13
              }, 
              {
                "text": "Now a certain woman named Lydia heard us. She was a seller of purple from the city of Thyatira, who worshiped God. The Lord opened her heart to heed the things spoken by Paul.", 
                "num": 14
              }, 
              {
                "text": "And when she and her household were baptized, she begged us, saying, \"If you have judged me to be faithful to the Lord, come to my house and stay.\" So she persuaded us.", 
                "num": 15
              }, 
              {
                "text": "Now it happened, as we went to prayer, that a certain slave girl possessed with a spirit of divination met us, who brought her masters much profit by fortune-telling.", 
                "num": 16
              }, 
              {
                "text": "This girl followed Paul and us, and cried out, saying, \"These men are the servants of the Most High God, who proclaim to us the way of salvation.\"", 
                "num": 17
              }, 
              {
                "text": "And this she did for many days. But Paul, greatly annoyed, turned and said to the spirit, \"I command you in the name of Jesus Christ to come out of her.\" And he came out that very hour.", 
                "num": 18
              }, 
              {
                "text": "But when her masters saw that their hope of profit was gone, they seized Paul and Silas and dragged them into the marketplace to the authorities.", 
                "num": 19
              }, 
              {
                "text": "And they brought them to the magistrates, and said, \"These men, being Jews, exceedingly trouble our city;", 
                "num": 20
              }, 
              {
                "text": "and they teach customs which are not lawful for us, being Romans, to receive or observe.\"", 
                "num": 21
              }, 
              {
                "text": "Then the multitude rose up together against them; and the magistrates tore off their clothes and commanded them to be beaten with rods.", 
                "num": 22
              }, 
              {
                "text": "And when they had laid many stripes on them, they threw them into prison, commanding the jailer to keep them securely.", 
                "num": 23
              }, 
              {
                "text": "Having received such a charge, he put them into the inner prison and fastened their feet in the stocks.", 
                "num": 24
              }, 
              {
                "text": "But at midnight Paul and Silas were praying and singing hymns to God, and the prisoners were listening to them.", 
                "num": 25
              }, 
              {
                "text": "Suddenly there was a great earthquake, so that the foundations of the prison were shaken; and immediately all the doors were opened and everyone's chains were loosed.", 
                "num": 26
              }, 
              {
                "text": "And the keeper of the prison, awaking from sleep and seeing the prison doors open, supposing the prisoners had fled, drew his sword and was about to kill himself.", 
                "num": 27
              }, 
              {
                "text": "But Paul called with a loud voice, saying, \"Do yourself no harm, for we are all here.\"", 
                "num": 28
              }, 
              {
                "text": "Then he called for a light, ran in, and fell down trembling before Paul and Silas.", 
                "num": 29
              }, 
              {
                "text": "And he brought them out and said, \"Sirs, what must I do to be saved?\"", 
                "num": 30
              }, 
              {
                "text": "So they said, \"Believe on the Lord Jesus Christ, and you will be saved, you and your household.\"", 
                "num": 31
              }, 
              {
                "text": "Then they spoke the word of the Lord to him and to all who were in his house.", 
                "num": 32
              }, 
              {
                "text": "And he took them the same hour of the night and washed their stripes. And immediately he and all his family were baptized.", 
                "num": 33
              }, 
              {
                "text": "Now when he had brought them into his house, he set food before them; and he rejoiced, having believed in God with all his household.", 
                "num": 34
              }, 
              {
                "text": "And when it was day, the magistrates sent the officers, saying, \"Let those men go.\"", 
                "num": 35
              }, 
              {
                "text": "So the keeper of the prison reported these words to Paul, saying, \"The magistrates have sent to let you go. Now therefore depart, and go in peace.\"", 
                "num": 36
              }, 
              {
                "text": "But Paul said to them, \"They have beaten us openly, uncondemned Romans, and have thrown us into prison. And now do they put us out secretly? No indeed! Let them come themselves and get us out.\"", 
                "num": 37
              }, 
              {
                "text": "And the officers told these words to the magistrates, and they were afraid when they heard that they were Romans.", 
                "num": 38
              }, 
              {
                "text": "Then they came and pleaded with them and brought them out, and asked them to depart from the city.", 
                "num": 39
              }, 
              {
                "text": "So they went out of the prison and entered the house of Lydia; and when they had seen the brethren, they encouraged them and departed.", 
                "num": 40
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Now when they had passed through Amphipolis and Apollonia, they came to Thessalonica, where there was a synagogue of the Jews.", 
                "num": 1
              }, 
              {
                "text": "Then Paul, as his custom was, went in to them, and for three Sabbaths reasoned with them from the Scriptures,", 
                "num": 2
              }, 
              {
                "text": "explaining and demonstrating that the Christ had to suffer and rise again from the dead, and saying, \"This Jesus whom I preach to you is the Christ.\"", 
                "num": 3
              }, 
              {
                "text": "And some of them were persuaded; and a great multitude of the devout Greeks, and not a few of the leading women, joined Paul and Silas.", 
                "num": 4
              }, 
              {
                "text": "But the Jews who were not persuaded, becoming envious, took some of the evil men from the marketplace, and gathering a mob, set all the city in an uproar and attacked the house of Jason, and sought to bring them out to the people.", 
                "num": 5
              }, 
              {
                "text": "But when they did not find them, they dragged Jason and some brethren to the rulers of the city, crying out, \"These who have turned the world upside down have come here too.", 
                "num": 6
              }, 
              {
                "text": "Jason has harbored them, and these are all acting contrary to the decrees of Caesar, saying there is another king--Jesus.\"", 
                "num": 7
              }, 
              {
                "text": "And they troubled the crowd and the rulers of the city when they heard these things.", 
                "num": 8
              }, 
              {
                "text": "So when they had taken security from Jason and the rest, they let them go.", 
                "num": 9
              }, 
              {
                "text": "Then the brethren immediately sent Paul and Silas away by night to Berea. When they arrived, they went into the synagogue of the Jews.", 
                "num": 10
              }, 
              {
                "text": "These were more fair-minded than those in Thessalonica, in that they received the word with all readiness, and searched the Scriptures daily to find out whether these things were so.", 
                "num": 11
              }, 
              {
                "text": "Therefore many of them believed, and also not a few of the Greeks, prominent women as well as men.", 
                "num": 12
              }, 
              {
                "text": "But when the Jews from Thessalonica learned that the word of God was preached by Paul at Berea, they came there also and stirred up the crowds.", 
                "num": 13
              }, 
              {
                "text": "Then immediately the brethren sent Paul away, to go to the sea; but both Silas and Timothy remained there.", 
                "num": 14
              }, 
              {
                "text": "So those who conducted Paul brought him to Athens; and receiving a command for Silas and Timothy to come to him with all speed, they departed.", 
                "num": 15
              }, 
              {
                "text": "Now while Paul waited for them at Athens, his spirit was provoked within him when he saw that the city was given over to idols.", 
                "num": 16
              }, 
              {
                "text": "Therefore he reasoned in the synagogue with the Jews and with the Gentile worshipers, and in the marketplace daily with those who happened to be there.", 
                "num": 17
              }, 
              {
                "text": "Then certain Epicurean and Stoic philosophers encountered him. And some said, \"What does this babbler want to say?\" Others said, \"He seems to be a proclaimer of foreign gods,\" because he preached to them Jesus and the resurrection.", 
                "num": 18
              }, 
              {
                "text": "And they took him and brought him to the Areopagus, saying, \"May we know what this new doctrine is of which you speak?", 
                "num": 19
              }, 
              {
                "text": "For you are bringing some strange things to our ears. Therefore we want to know what these things mean.\"", 
                "num": 20
              }, 
              {
                "text": "For all the Athenians and the foreigners who were there spent their time in nothing else but either to tell or to hear some new thing.", 
                "num": 21
              }, 
              {
                "text": "Then Paul stood in the midst of the Areopagus and said, \"Men of Athens, I perceive that in all things you are very religious;", 
                "num": 22
              }, 
              {
                "text": "for as I was passing through and considering the objects of your worship, I even found an altar with this inscription: TO THE UNKNOWN GOD. Therefore, the One whom you worship without knowing, Him I proclaim to you:", 
                "num": 23
              }, 
              {
                "text": "God, who made the world and everything in it, since He is Lord of heaven and earth, does not dwell in temples made with hands.", 
                "num": 24
              }, 
              {
                "text": "Nor is He worshiped with men's hands, as though He needed anything, since He gives to all life, breath, and all things.", 
                "num": 25
              }, 
              {
                "text": "And He has made from one blood every nation of men to dwell on all the face of the earth, and has determined their preappointed times and the boundaries of their dwellings,", 
                "num": 26
              }, 
              {
                "text": "so that they should seek the Lord, in the hope that they might grope for Him and find Him, though He is not far from each one of us;", 
                "num": 27
              }, 
              {
                "text": "for in Him we live and move and have our being, as also some of your own poets have said, \"For we are also His offspring.'", 
                "num": 28
              }, 
              {
                "text": "Therefore, since we are the offspring of God, we ought not to think that the Divine Nature is like gold or silver or stone, something shaped by art and man's devising.", 
                "num": 29
              }, 
              {
                "text": "Truly, these times of ignorance God overlooked, but now commands all men everywhere to repent,", 
                "num": 30
              }, 
              {
                "text": "because He has appointed a day on which He will judge the world in righteousness by the Man whom He has ordained. He has given assurance of this to all by raising Him from the dead.\"", 
                "num": 31
              }, 
              {
                "text": "And when they heard of the resurrection of the dead, some mocked, while others said, \"We will hear you again on this matter.\"", 
                "num": 32
              }, 
              {
                "text": "So Paul departed from among them.", 
                "num": 33
              }, 
              {
                "text": "However, some men joined him and believed, among them Dionysius the Areopagite, a woman named Damaris, and others with them.", 
                "num": 34
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "After these things Paul departed from Athens and went to Corinth.", 
                "num": 1
              }, 
              {
                "text": "And he found a certain Jew named Aquila, born in Pontus, who had recently come from Italy with his wife Priscilla (because Claudius had commanded all the Jews to depart from Rome); and he came to them.", 
                "num": 2
              }, 
              {
                "text": "So, because he was of the same trade, he stayed with them and worked; for by occupation they were tentmakers.", 
                "num": 3
              }, 
              {
                "text": "And he reasoned in the synagogue every Sabbath, and persuaded both Jews and Greeks.", 
                "num": 4
              }, 
              {
                "text": "When Silas and Timothy had come from Macedonia, Paul was compelled by the Spirit, and testified to the Jews that Jesus is the Christ.", 
                "num": 5
              }, 
              {
                "text": "But when they opposed him and blasphemed, he shook his garments and said to them, \"Your blood be upon your own heads; I am clean. From now on I will go to the Gentiles.\"", 
                "num": 6
              }, 
              {
                "text": "And he departed from there and entered the house of a certain man named Justus, one who worshiped God, whose house was next door to the synagogue.", 
                "num": 7
              }, 
              {
                "text": "Then Crispus, the ruler of the synagogue, believed on the Lord with all his household. And many of the Corinthians, hearing, believed and were baptized.", 
                "num": 8
              }, 
              {
                "text": "Now the Lord spoke to Paul in the night by a vision, \"Do not be afraid, but speak, and do not keep silent;", 
                "num": 9
              }, 
              {
                "text": "for I am with you, and no one will attack you to hurt you; for I have many people in this city.\"", 
                "num": 10
              }, 
              {
                "text": "And he continued there a year and six months, teaching the word of God among them.", 
                "num": 11
              }, 
              {
                "text": "When Gallio was proconsul of Achaia, the Jews with one accord rose up against Paul and brought him to the judgment seat,", 
                "num": 12
              }, 
              {
                "text": "saying, \"This fellow persuades men to worship God contrary to the law.\"", 
                "num": 13
              }, 
              {
                "text": "And when Paul was about to open his mouth, Gallio said to the Jews, \"If it were a matter of wrongdoing or wicked crimes, O Jews, there would be reason why I should bear with you.", 
                "num": 14
              }, 
              {
                "text": "But if it is a question of words and names and your own law, look to it yourselves; for I do not want to be a judge of such matters.\"", 
                "num": 15
              }, 
              {
                "text": "And he drove them from the judgment seat.", 
                "num": 16
              }, 
              {
                "text": "Then all the Greeks took Sosthenes, the ruler of the synagogue, and beat him before the judgment seat. But Gallio took no notice of these things.", 
                "num": 17
              }, 
              {
                "text": "So Paul still remained a good while. Then he took leave of the brethren and sailed for Syria, and Priscilla and Aquila were with him. He had his hair cut off at Cenchrea, for he had taken a vow.", 
                "num": 18
              }, 
              {
                "text": "And he came to Ephesus, and left them there; but he himself entered the synagogue and reasoned with the Jews.", 
                "num": 19
              }, 
              {
                "text": "When they asked him to stay a longer time with them, he did not consent,", 
                "num": 20
              }, 
              {
                "text": "but took leave of them, saying, \"I must by all means keep this coming feast in Jerusalem; but I will return again to you, God willing.\" And he sailed from Ephesus.", 
                "num": 21
              }, 
              {
                "text": "And when he had landed at Caesarea, and gone up and greeted the church, he went down to Antioch.", 
                "num": 22
              }, 
              {
                "text": "After he had spent some time there, he departed and went over the region of Galatia and Phrygia in order, strengthening all the disciples.", 
                "num": 23
              }, 
              {
                "text": "Now a certain Jew named Apollos, born at Alexandria, an eloquent man and mighty in the Scriptures, came to Ephesus.", 
                "num": 24
              }, 
              {
                "text": "This man had been instructed in the way of the Lord; and being fervent in spirit, he spoke and taught accurately the things of the Lord, though he knew only the baptism of John.", 
                "num": 25
              }, 
              {
                "text": "So he began to speak boldly in the synagogue. When Aquila and Priscilla heard him, they took him aside and explained to him the way of God more accurately.", 
                "num": 26
              }, 
              {
                "text": "And when he desired to cross to Achaia, the brethren wrote, exhorting the disciples to receive him; and when he arrived, he greatly helped those who had believed through grace;", 
                "num": 27
              }, 
              {
                "text": "for he vigorously refuted the Jews publicly, showing from the Scriptures that Jesus is the Christ.", 
                "num": 28
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "And it happened, while Apollos was at Corinth, that Paul, having passed through the upper regions, came to Ephesus. And finding some disciples", 
                "num": 1
              }, 
              {
                "text": "he said to them, \"Did you receive the Holy Spirit when you believed?\" So they said to him, \"We have not so much as heard whether there is a Holy Spirit.\"", 
                "num": 2
              }, 
              {
                "text": "And he said to them, \"Into what then were you baptized?\" So they said, \"Into John's baptism.\"", 
                "num": 3
              }, 
              {
                "text": "Then Paul said, \"John indeed baptized with a baptism of repentance, saying to the people that they should believe on Him who would come after him, that is, on Christ Jesus.\"", 
                "num": 4
              }, 
              {
                "text": "When they heard this, they were baptized in the name of the Lord Jesus.", 
                "num": 5
              }, 
              {
                "text": "And when Paul had laid hands on them, the Holy Spirit came upon them, and they spoke with tongues and prophesied.", 
                "num": 6
              }, 
              {
                "text": "Now the men were about twelve in all.", 
                "num": 7
              }, 
              {
                "text": "And he went into the synagogue and spoke boldly for three months, reasoning and persuading concerning the things of the kingdom of God.", 
                "num": 8
              }, 
              {
                "text": "But when some were hardened and did not believe, but spoke evil of the Way before the multitude, he departed from them and withdrew the disciples, reasoning daily in the school of Tyrannus.", 
                "num": 9
              }, 
              {
                "text": "And this continued for two years, so that all who dwelt in Asia heard the word of the Lord Jesus, both Jews and Greeks.", 
                "num": 10
              }, 
              {
                "text": "Now God worked unusual miracles by the hands of Paul,", 
                "num": 11
              }, 
              {
                "text": "so that even handkerchiefs or aprons were brought from his body to the sick, and the diseases left them and the evil spirits went out of them.", 
                "num": 12
              }, 
              {
                "text": "Then some of the itinerant Jewish exorcists took it upon themselves to call the name of the Lord Jesus over those who had evil spirits, saying, \"We exorcise you by the Jesus whom Paul preaches.\"", 
                "num": 13
              }, 
              {
                "text": "Also there were seven sons of Sceva, a Jewish chief priest, who did so.", 
                "num": 14
              }, 
              {
                "text": "And the evil spirit answered and said, \"Jesus I know, and Paul I know; but who are you?\"", 
                "num": 15
              }, 
              {
                "text": "Then the man in whom the evil spirit was leaped on them, overpowered them, and prevailed against them, so that they fled out of that house naked and wounded.", 
                "num": 16
              }, 
              {
                "text": "This became known both to all Jews and Greeks dwelling in Ephesus; and fear fell on them all, and the name of the Lord Jesus was magnified.", 
                "num": 17
              }, 
              {
                "text": "And many who had believed came confessing and telling their deeds.", 
                "num": 18
              }, 
              {
                "text": "Also, many of those who had practiced magic brought their books together and burned them in the sight of all. And they counted up the value of them, and it totaled fifty thousand pieces of silver.", 
                "num": 19
              }, 
              {
                "text": "So the word of the Lord grew mightily and prevailed.", 
                "num": 20
              }, 
              {
                "text": "When these things were accomplished, Paul purposed in the Spirit, when he had passed through Macedonia and Achaia, to go to Jerusalem, saying, \"After I have been there, I must also see Rome.\"", 
                "num": 21
              }, 
              {
                "text": "So he sent into Macedonia two of those who ministered to him, Timothy and Erastus, but he himself stayed in Asia for a time.", 
                "num": 22
              }, 
              {
                "text": "And about that time there arose a great commotion about the Way.", 
                "num": 23
              }, 
              {
                "text": "For a certain man named Demetrius, a silversmith, who made silver shrines of Diana, brought no small profit to the craftsmen.", 
                "num": 24
              }, 
              {
                "text": "He called them together with the workers of similar occupation, and said: \"Men, you know that we have our prosperity by this trade.", 
                "num": 25
              }, 
              {
                "text": "Moreover you see and hear that not only at Ephesus, but throughout almost all Asia, this Paul has persuaded and turned away many people, saying that they are not gods which are made with hands.", 
                "num": 26
              }, 
              {
                "text": "So not only is this trade of ours in danger of falling into disrepute, but also the temple of the great goddess Diana may be despised and her magnificence destroyed, whom all Asia and the world worship.\"", 
                "num": 27
              }, 
              {
                "text": "Now when they heard this, they were full of wrath and cried out, saying, \"Great is Diana of the Ephesians!\"", 
                "num": 28
              }, 
              {
                "text": "So the whole city was filled with confusion, and rushed into the theater with one accord, having seized Gaius and Aristarchus, Macedonians, Paul's travel companions.", 
                "num": 29
              }, 
              {
                "text": "And when Paul wanted to go in to the people, the disciples would not allow him.", 
                "num": 30
              }, 
              {
                "text": "Then some of the officials of Asia, who were his friends, sent to him pleading that he would not venture into the theater.", 
                "num": 31
              }, 
              {
                "text": "Some therefore cried one thing and some another, for the assembly was confused, and most of them did not know why they had come together.", 
                "num": 32
              }, 
              {
                "text": "And they drew Alexander out of the multitude, the Jews putting him forward. And Alexander motioned with his hand, and wanted to make his defense to the people.", 
                "num": 33
              }, 
              {
                "text": "But when they found out that he was a Jew, all with one voice cried out for about two hours, \"Great is Diana of the Ephesians!\"", 
                "num": 34
              }, 
              {
                "text": "And when the city clerk had quieted the crowd, he said: \"Men of Ephesus, what man is there who does not know that the city of the Ephesians is temple guardian of the great goddess Diana, and of the image which fell down from Zeus?", 
                "num": 35
              }, 
              {
                "text": "Therefore, since these things cannot be denied, you ought to be quiet and do nothing rashly.", 
                "num": 36
              }, 
              {
                "text": "For you have brought these men here who are neither robbers of temples nor blasphemers of your goddess.", 
                "num": 37
              }, 
              {
                "text": "Therefore, if Demetrius and his fellow craftsmen have a case against anyone, the courts are open and there are proconsuls. Let them bring charges against one another.", 
                "num": 38
              }, 
              {
                "text": "But if you have any other inquiry to make, it shall be determined in the lawful assembly.", 
                "num": 39
              }, 
              {
                "text": "For we are in danger of being called in question for today's uproar, there being no reason which we may give to account for this disorderly gathering.\"", 
                "num": 40
              }, 
              {
                "text": "And when he had said these things, he dismissed the assembly.", 
                "num": 41
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "After the uproar had ceased, Paul called the disciples to himself, embraced them, and departed to go to Macedonia.", 
                "num": 1
              }, 
              {
                "text": "Now when he had gone over that region and encouraged them with many words, he came to Greece", 
                "num": 2
              }, 
              {
                "text": "and stayed three months. And when the Jews plotted against him as he was about to sail to Syria, he decided to return through Macedonia.", 
                "num": 3
              }, 
              {
                "text": "And Sopater of Berea accompanied him to Asia--also Aristarchus and Secundus of the Thessalonians, and Gaius of Derbe, and Timothy, and Tychicus and Trophimus of Asia.", 
                "num": 4
              }, 
              {
                "text": "These men, going ahead, waited for us at Troas.", 
                "num": 5
              }, 
              {
                "text": "But we sailed away from Philippi after the Days of Unleavened Bread, and in five days joined them at Troas, where we stayed seven days.", 
                "num": 6
              }, 
              {
                "text": "Now on the first day of the week, when the disciples came together to break bread, Paul, ready to depart the next day, spoke to them and continued his message until midnight.", 
                "num": 7
              }, 
              {
                "text": "There were many lamps in the upper room where they were gathered together.", 
                "num": 8
              }, 
              {
                "text": "And in a window sat a certain young man named Eutychus, who was sinking into a deep sleep. He was overcome by sleep; and as Paul continued speaking, he fell down from the third story and was taken up dead.", 
                "num": 9
              }, 
              {
                "text": "But Paul went down, fell on him, and embracing him said, \"Do not trouble yourselves, for his life is in him.\"", 
                "num": 10
              }, 
              {
                "text": "Now when he had come up, had broken bread and eaten, and talked a long while, even till daybreak, he departed.", 
                "num": 11
              }, 
              {
                "text": "And they brought the young man in alive, and they were not a little comforted.", 
                "num": 12
              }, 
              {
                "text": "Then we went ahead to the ship and sailed to Assos, there intending to take Paul on board; for so he had given orders, intending himself to go on foot.", 
                "num": 13
              }, 
              {
                "text": "And when he met us at Assos, we took him on board and came to Mitylene.", 
                "num": 14
              }, 
              {
                "text": "We sailed from there, and the next day came opposite Chios. The following day we arrived at Samos and stayed at Trogyllium. The next day we came to Miletus.", 
                "num": 15
              }, 
              {
                "text": "For Paul had decided to sail past Ephesus, so that he would not have to spend time in Asia; for he was hurrying to be at Jerusalem, if possible, on the Day of Pentecost.", 
                "num": 16
              }, 
              {
                "text": "From Miletus he sent to Ephesus and called for the elders of the church.", 
                "num": 17
              }, 
              {
                "text": "And when they had come to him, he said to them: \"You know, from the first day that I came to Asia, in what manner I always lived among you,", 
                "num": 18
              }, 
              {
                "text": "serving the Lord with all humility, with many tears and trials which happened to me by the plotting of the Jews;", 
                "num": 19
              }, 
              {
                "text": "how I kept back nothing that was helpful, but proclaimed it to you, and taught you publicly and from house to house,", 
                "num": 20
              }, 
              {
                "text": "testifying to Jews, and also to Greeks, repentance toward God and faith toward our Lord Jesus Christ.", 
                "num": 21
              }, 
              {
                "text": "And see, now I go bound in the spirit to Jerusalem, not knowing the things that will happen to me there,", 
                "num": 22
              }, 
              {
                "text": "except that the Holy Spirit testifies in every city, saying that chains and tribulations await me.", 
                "num": 23
              }, 
              {
                "text": "But none of these things move me; nor do I count my life dear to myself, so that I may finish my race with joy, and the ministry which I received from the Lord Jesus, to testify to the gospel of the grace of God.", 
                "num": 24
              }, 
              {
                "text": "\"And indeed, now I know that you all, among whom I have gone preaching the kingdom of God, will see my face no more.", 
                "num": 25
              }, 
              {
                "text": "Therefore I testify to you this day that I am innocent of the blood of all men.", 
                "num": 26
              }, 
              {
                "text": "For I have not shunned to declare to you the whole counsel of God.", 
                "num": 27
              }, 
              {
                "text": "Therefore take heed to yourselves and to all the flock, among which the Holy Spirit has made you overseers, to shepherd the church of God which He purchased with His own blood.", 
                "num": 28
              }, 
              {
                "text": "For I know this, that after my departure savage wolves will come in among you, not sparing the flock.", 
                "num": 29
              }, 
              {
                "text": "Also from among yourselves men will rise up, speaking perverse things, to draw away the disciples after themselves.", 
                "num": 30
              }, 
              {
                "text": "Therefore watch, and remember that for three years I did not cease to warn everyone night and day with tears.", 
                "num": 31
              }, 
              {
                "text": "\"So now, brethren, I commend you to God and to the word of His grace, which is able to build you up and give you an inheritance among all those who are sanctified.", 
                "num": 32
              }, 
              {
                "text": "I have coveted no one's silver or gold or apparel.", 
                "num": 33
              }, 
              {
                "text": "Yes, you yourselves know that these hands have provided for my necessities, and for those who were with me.", 
                "num": 34
              }, 
              {
                "text": "I have shown you in every way, by laboring like this, that you must support the weak. And remember the words of the Lord Jesus, that He said, \"It is more blessed to give than to receive.\"'", 
                "num": 35
              }, 
              {
                "text": "And when he had said these things, he knelt down and prayed with them all.", 
                "num": 36
              }, 
              {
                "text": "Then they all wept freely, and fell on Paul's neck and kissed him,", 
                "num": 37
              }, 
              {
                "text": "sorrowing most of all for the words which he spoke, that they would see his face no more. And they accompanied him to the ship.", 
                "num": 38
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now it came to pass, that when we had departed from them and set sail, running a straight course we came to Cos, the following day to Rhodes, and from there to Patara.", 
                "num": 1
              }, 
              {
                "text": "And finding a ship sailing over to Phoenicia, we went aboard and set sail.", 
                "num": 2
              }, 
              {
                "text": "When we had sighted Cyprus, we passed it on the left, sailed to Syria, and landed at Tyre; for there the ship was to unload her cargo.", 
                "num": 3
              }, 
              {
                "text": "And finding disciples, we stayed there seven days. They told Paul through the Spirit not to go up to Jerusalem.", 
                "num": 4
              }, 
              {
                "text": "When we had come to the end of those days, we departed and went on our way; and they all accompanied us, with wives and children, till we were out of the city. And we knelt down on the shore and prayed.", 
                "num": 5
              }, 
              {
                "text": "When we had taken our leave of one another, we boarded the ship, and they returned home.", 
                "num": 6
              }, 
              {
                "text": "And when we had finished our voyage from Tyre, we came to Ptolemais, greeted the brethren, and stayed with them one day.", 
                "num": 7
              }, 
              {
                "text": "On the next day we who were Paul's companions departed and came to Caesarea, and entered the house of Philip the evangelist, who was one of the seven, and stayed with him.", 
                "num": 8
              }, 
              {
                "text": "Now this man had four virgin daughters who prophesied.", 
                "num": 9
              }, 
              {
                "text": "And as we stayed many days, a certain prophet named Agabus came down from Judea.", 
                "num": 10
              }, 
              {
                "text": "When he had come to us, he took Paul's belt, bound his own hands and feet, and said, \"Thus says the Holy Spirit, \"So shall the Jews at Jerusalem bind the man who owns this belt, and deliver him into the hands of the Gentiles.\"'", 
                "num": 11
              }, 
              {
                "text": "Now when we heard these things, both we and those from that place pleaded with him not to go up to Jerusalem.", 
                "num": 12
              }, 
              {
                "text": "Then Paul answered, \"What do you mean by weeping and breaking my heart? For I am ready not only to be bound, but also to die at Jerusalem for the name of the Lord Jesus.\"", 
                "num": 13
              }, 
              {
                "text": "So when he would not be persuaded, we ceased, saying, \"The will of the Lord be done.\"", 
                "num": 14
              }, 
              {
                "text": "And after those days we packed and went up to Jerusalem.", 
                "num": 15
              }, 
              {
                "text": "Also some of the disciples from Caesarea went with us and brought with them a certain Mnason of Cyprus, an early disciple, with whom we were to lodge.", 
                "num": 16
              }, 
              {
                "text": "And when we had come to Jerusalem, the brethren received us gladly.", 
                "num": 17
              }, 
              {
                "text": "On the following day Paul went in with us to James, and all the elders were present.", 
                "num": 18
              }, 
              {
                "text": "When he had greeted them, he told in detail those things which God had done among the Gentiles through his ministry.", 
                "num": 19
              }, 
              {
                "text": "And when they heard it, they glorified the Lord. And they said to him, \"You see, brother, how many myriads of Jews there are who have believed, and they are all zealous for the law;", 
                "num": 20
              }, 
              {
                "text": "but they have been informed about you that you teach all the Jews who are among the Gentiles to forsake Moses, saying that they ought not to circumcise their children nor to walk according to the customs.", 
                "num": 21
              }, 
              {
                "text": "What then? The assembly must certainly meet, for they will hear that you have come.", 
                "num": 22
              }, 
              {
                "text": "Therefore do what we tell you: We have four men who have taken a vow.", 
                "num": 23
              }, 
              {
                "text": "Take them and be purified with them, and pay their expenses so that they may shave their heads, and that all may know that those things of which they were informed concerning you are nothing, but that you yourself also walk orderly and keep the law.", 
                "num": 24
              }, 
              {
                "text": "But concerning the Gentiles who believe, we have written and decided that they should observe no such thing, except that they should keep themselves from things offered to idols, from blood, from things strangled, and from sexual immorality.\"", 
                "num": 25
              }, 
              {
                "text": "Then Paul took the men, and the next day, having been purified with them, entered the temple to announce the expiration of the days of purification, at which time an offering should be made for each one of them.", 
                "num": 26
              }, 
              {
                "text": "Now when the seven days were almost ended, the Jews from Asia, seeing him in the temple, stirred up the whole crowd and laid hands on him,", 
                "num": 27
              }, 
              {
                "text": "crying out, \"Men of Israel, help! This is the man who teaches all men everywhere against the people, the law, and this place; and furthermore he also brought Greeks into the temple and has defiled this holy place.\"", 
                "num": 28
              }, 
              {
                "text": "(For they had previously seen Trophimus the Ephesian with him in the city, whom they supposed that Paul had brought into the temple.)", 
                "num": 29
              }, 
              {
                "text": "And all the city was disturbed; and the people ran together, seized Paul, and dragged him out of the temple; and immediately the doors were shut.", 
                "num": 30
              }, 
              {
                "text": "Now as they were seeking to kill him, news came to the commander of the garrison that all Jerusalem was in an uproar.", 
                "num": 31
              }, 
              {
                "text": "He immediately took soldiers and centurions, and ran down to them. And when they saw the commander and the soldiers, they stopped beating Paul.", 
                "num": 32
              }, 
              {
                "text": "Then the commander came near and took him, and commanded him to be bound with two chains; and he asked who he was and what he had done.", 
                "num": 33
              }, 
              {
                "text": "And some among the multitude cried one thing and some another. So when he could not ascertain the truth because of the tumult, he commanded him to be taken into the barracks.", 
                "num": 34
              }, 
              {
                "text": "When he reached the stairs, he had to be carried by the soldiers because of the violence of the mob.", 
                "num": 35
              }, 
              {
                "text": "For the multitude of the people followed after, crying out, \"Away with him!\"", 
                "num": 36
              }, 
              {
                "text": "Then as Paul was about to be led into the barracks, he said to the commander, \"May I speak to you?\" He replied, \"Can you speak Greek?", 
                "num": 37
              }, 
              {
                "text": "Are you not the Egyptian who some time ago stirred up a rebellion and led the four thousand assassins out into the wilderness?\"", 
                "num": 38
              }, 
              {
                "text": "But Paul said, \"I am a Jew from Tarsus, in Cilicia, a citizen of no mean city; and I implore you, permit me to speak to the people.\"", 
                "num": 39
              }, 
              {
                "text": "So when he had given him permission, Paul stood on the stairs and motioned with his hand to the people. And when there was a great silence, he spoke to them in the Hebrew language, saying,", 
                "num": 40
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "\"Brethren and fathers, hear my defense before you now.\"", 
                "num": 1
              }, 
              {
                "text": "And when they heard that he spoke to them in the Hebrew language, they kept all the more silent. Then he said:", 
                "num": 2
              }, 
              {
                "text": "\"I am indeed a Jew, born in Tarsus of Cilicia, but brought up in this city at the feet of Gamaliel, taught according to the strictness of our fathers' law, and was zealous toward God as you all are today.", 
                "num": 3
              }, 
              {
                "text": "I persecuted this Way to the death, binding and delivering into prisons both men and women,", 
                "num": 4
              }, 
              {
                "text": "as also the high priest bears me witness, and all the council of the elders, from whom I also received letters to the brethren, and went to Damascus to bring in chains even those who were there to Jerusalem to be punished.", 
                "num": 5
              }, 
              {
                "text": "\"Now it happened, as I journeyed and came near Damascus at about noon, suddenly a great light from heaven shone around me.", 
                "num": 6
              }, 
              {
                "text": "And I fell to the ground and heard a voice saying to me, \"Saul, Saul, why are you persecuting Me?'", 
                "num": 7
              }, 
              {
                "text": "So I answered, \"Who are You, Lord?' And He said to me, \"I am Jesus of Nazareth, whom you are persecuting.'", 
                "num": 8
              }, 
              {
                "text": "\"And those who were with me indeed saw the light and were afraid, but they did not hear the voice of Him who spoke to me.", 
                "num": 9
              }, 
              {
                "text": "So I said, \"What shall I do, Lord?' And the Lord said to me, \"Arise and go into Damascus, and there you will be told all things which are appointed for you to do.'", 
                "num": 10
              }, 
              {
                "text": "And since I could not see for the glory of that light, being led by the hand of those who were with me, I came into Damascus.", 
                "num": 11
              }, 
              {
                "text": "\"Then a certain Ananias, a devout man according to the law, having a good testimony with all the Jews who dwelt there,", 
                "num": 12
              }, 
              {
                "text": "came to me; and he stood and said to me, \"Brother Saul, receive your sight.' And at that same hour I looked up at him.", 
                "num": 13
              }, 
              {
                "text": "Then he said, \"The God of our fathers has chosen you that you should know His will, and see the Just One, and hear the voice of His mouth.", 
                "num": 14
              }, 
              {
                "text": "For you will be His witness to all men of what you have seen and heard.", 
                "num": 15
              }, 
              {
                "text": "And now why are you waiting? Arise and be baptized, and wash away your sins, calling on the name of the Lord.'", 
                "num": 16
              }, 
              {
                "text": "\"Now it happened, when I returned to Jerusalem and was praying in the temple, that I was in a trance", 
                "num": 17
              }, 
              {
                "text": "and saw Him saying to me, \"Make haste and get out of Jerusalem quickly, for they will not receive your testimony concerning Me.'", 
                "num": 18
              }, 
              {
                "text": "So I said, \"Lord, they know that in every synagogue I imprisoned and beat those who believe on You.", 
                "num": 19
              }, 
              {
                "text": "And when the blood of Your martyr Stephen was shed, I also was standing by consenting to his death, and guarding the clothes of those who were killing him.'", 
                "num": 20
              }, 
              {
                "text": "Then He said to me, \"Depart, for I will send you far from here to the Gentiles.\"'", 
                "num": 21
              }, 
              {
                "text": "And they listened to him until this word, and then they raised their voices and said, \"Away with such a fellow from the earth, for he is not fit to live!\"", 
                "num": 22
              }, 
              {
                "text": "Then, as they cried out and tore off their clothes and threw dust into the air,", 
                "num": 23
              }, 
              {
                "text": "the commander ordered him to be brought into the barracks, and said that he should be examined under scourging, so that he might know why they shouted so against him.", 
                "num": 24
              }, 
              {
                "text": "And as they bound him with thongs, Paul said to the centurion who stood by, \"Is it lawful for you to scourge a man who is a Roman, and uncondemned?\"", 
                "num": 25
              }, 
              {
                "text": "When the centurion heard that, he went and told the commander, saying, \"Take care what you do, for this man is a Roman.\"", 
                "num": 26
              }, 
              {
                "text": "Then the commander came and said to him, \"Tell me, are you a Roman?\" He said, \"Yes.\"", 
                "num": 27
              }, 
              {
                "text": "The commander answered, \"With a large sum I obtained this citizenship.\" And Paul said, \"But I was born a citizen.\"", 
                "num": 28
              }, 
              {
                "text": "Then immediately those who were about to examine him withdrew from him; and the commander was also afraid after he found out that he was a Roman, and because he had bound him.", 
                "num": 29
              }, 
              {
                "text": "The next day, because he wanted to know for certain why he was accused by the Jews, he released him from his bonds, and commanded the chief priests and all their council to appear, and brought Paul down and set him before them.", 
                "num": 30
              }
            ], 
            "num": 22
          }, 
          {
            "verses": [
              {
                "text": "Then Paul, looking earnestly at the council, said, \"Men and brethren, I have lived in all good conscience before God until this day.\"", 
                "num": 1
              }, 
              {
                "text": "And the high priest Ananias commanded those who stood by him to strike him on the mouth.", 
                "num": 2
              }, 
              {
                "text": "Then Paul said to him, \"God will strike you, you whitewashed wall! For you sit to judge me according to the law, and do you command me to be struck contrary to the law?\"", 
                "num": 3
              }, 
              {
                "text": "And those who stood by said, \"Do you revile God's high priest?\"", 
                "num": 4
              }, 
              {
                "text": "Then Paul said, \"I did not know, brethren, that he was the high priest; for it is written, \"You shall not speak evil of a ruler of your people.\"'", 
                "num": 5
              }, 
              {
                "text": "But when Paul perceived that one part were Sadducees and the other Pharisees, he cried out in the council, \"Men and brethren, I am a Pharisee, the son of a Pharisee; concerning the hope and resurrection of the dead I am being judged!\"", 
                "num": 6
              }, 
              {
                "text": "And when he had said this, a dissension arose between the Pharisees and the Sadducees; and the assembly was divided.", 
                "num": 7
              }, 
              {
                "text": "For Sadducees say that there is no resurrection--and no angel or spirit; but the Pharisees confess both.", 
                "num": 8
              }, 
              {
                "text": "Then there arose a loud outcry. And the scribes of the Pharisees' party arose and protested, saying, \"We find no evil in this man; but if a spirit or an angel has spoken to him, let us not fight against God.\"", 
                "num": 9
              }, 
              {
                "text": "Now when there arose a great dissension, the commander, fearing lest Paul might be pulled to pieces by them, commanded the soldiers to go down and take him by force from among them, and bring him into the barracks.", 
                "num": 10
              }, 
              {
                "text": "But the following night the Lord stood by him and said, \"Be of good cheer, Paul; for as you have testified for Me in Jerusalem, so you must also bear witness at Rome.\"", 
                "num": 11
              }, 
              {
                "text": "And when it was day, some of the Jews banded together and bound themselves under an oath, saying that they would neither eat nor drink till they had killed Paul.", 
                "num": 12
              }, 
              {
                "text": "Now there were more than forty who had formed this conspiracy.", 
                "num": 13
              }, 
              {
                "text": "They came to the chief priests and elders, and said, \"We have bound ourselves under a great oath that we will eat nothing until we have killed Paul.", 
                "num": 14
              }, 
              {
                "text": "Now you, therefore, together with the council, suggest to the commander that he be brought down to you tomorrow, as though you were going to make further inquiries concerning him; but we are ready to kill him before he comes near.\"", 
                "num": 15
              }, 
              {
                "text": "So when Paul's sister's son heard of their ambush, he went and entered the barracks and told Paul.", 
                "num": 16
              }, 
              {
                "text": "Then Paul called one of the centurions to him and said, \"Take this young man to the commander, for he has something to tell him.\"", 
                "num": 17
              }, 
              {
                "text": "So he took him and brought him to the commander and said, \"Paul the prisoner called me to him and asked me to bring this young man to you. He has something to say to you.\"", 
                "num": 18
              }, 
              {
                "text": "Then the commander took him by the hand, went aside, and asked privately, \"What is it that you have to tell me?\"", 
                "num": 19
              }, 
              {
                "text": "And he said, \"The Jews have agreed to ask that you bring Paul down to the council tomorrow, as though they were going to inquire more fully about him.", 
                "num": 20
              }, 
              {
                "text": "But do not yield to them, for more than forty of them lie in wait for him, men who have bound themselves by an oath that they will neither eat nor drink till they have killed him; and now they are ready, waiting for the promise from you.\"", 
                "num": 21
              }, 
              {
                "text": "So the commander let the young man depart, and commanded him, \"Tell no one that you have revealed these things to me.\"", 
                "num": 22
              }, 
              {
                "text": "And he called for two centurions, saying, \"Prepare two hundred soldiers, seventy horsemen, and two hundred spearmen to go to Caesarea at the third hour of the night;", 
                "num": 23
              }, 
              {
                "text": "and provide mounts to set Paul on, and bring him safely to Felix the governor.\"", 
                "num": 24
              }, 
              {
                "text": "He wrote a letter in the following manner:", 
                "num": 25
              }, 
              {
                "text": "Claudius Lysias, To the most excellent governor Felix: Greetings.", 
                "num": 26
              }, 
              {
                "text": "This man was seized by the Jews and was about to be killed by them. Coming with the troops I rescued him, having learned that he was a Roman.", 
                "num": 27
              }, 
              {
                "text": "And when I wanted to know the reason they accused him, I brought him before their council.", 
                "num": 28
              }, 
              {
                "text": "I found out that he was accused concerning questions of their law, but had nothing charged against him deserving of death or chains.", 
                "num": 29
              }, 
              {
                "text": "And when it was told me that the Jews lay in wait for the man, I sent him immediately to you, and also commanded his accusers to state before you the charges against him. Farewell.", 
                "num": 30
              }, 
              {
                "text": "Then the soldiers, as they were commanded, took Paul and brought him by night to Antipatris.", 
                "num": 31
              }, 
              {
                "text": "The next day they left the horsemen to go on with him, and returned to the barracks.", 
                "num": 32
              }, 
              {
                "text": "When they came to Caesarea and had delivered the letter to the governor, they also presented Paul to him.", 
                "num": 33
              }, 
              {
                "text": "And when the governor had read it, he asked what province he was from. And when he understood that he was from Cilicia,", 
                "num": 34
              }, 
              {
                "text": "he said, \"I will hear you when your accusers also have come.\" And he commanded him to be kept in Herod's Praetorium.", 
                "num": 35
              }
            ], 
            "num": 23
          }, 
          {
            "verses": [
              {
                "text": "Now after five days Ananias the high priest came down with the elders and a certain orator named Tertullus. These gave evidence to the governor against Paul.", 
                "num": 1
              }, 
              {
                "text": "And when he was called upon, Tertullus began his accusation, saying: \"Seeing that through you we enjoy great peace, and prosperity is being brought to this nation by your foresight,", 
                "num": 2
              }, 
              {
                "text": "we accept it always and in all places, most noble Felix, with all thankfulness.", 
                "num": 3
              }, 
              {
                "text": "Nevertheless, not to be tedious to you any further, I beg you to hear, by your courtesy, a few words from us.", 
                "num": 4
              }, 
              {
                "text": "For we have found this man a plague, a creator of dissension among all the Jews throughout the world, and a ringleader of the sect of the Nazarenes.", 
                "num": 5
              }, 
              {
                "text": "He even tried to profane the temple, and we seized him, and wanted to judge him according to our law.", 
                "num": 6
              }, 
              {
                "text": "But the commander Lysias came by and with great violence took him out of our hands,", 
                "num": 7
              }, 
              {
                "text": "commanding his accusers to come to you. By examining him yourself you may ascertain all these things of which we accuse him.\"", 
                "num": 8
              }, 
              {
                "text": "And the Jews also assented, maintaining that these things were so.", 
                "num": 9
              }, 
              {
                "text": "Then Paul, after the governor had nodded to him to speak, answered: \"Inasmuch as I know that you have been for many years a judge of this nation, I do the more cheerfully answer for myself,", 
                "num": 10
              }, 
              {
                "text": "because you may ascertain that it is no more than twelve days since I went up to Jerusalem to worship.", 
                "num": 11
              }, 
              {
                "text": "And they neither found me in the temple disputing with anyone nor inciting the crowd, either in the synagogues or in the city.", 
                "num": 12
              }, 
              {
                "text": "Nor can they prove the things of which they now accuse me.", 
                "num": 13
              }, 
              {
                "text": "But this I confess to you, that according to the Way which they call a sect, so I worship the God of my fathers, believing all things which are written in the Law and in the Prophets.", 
                "num": 14
              }, 
              {
                "text": "I have hope in God, which they themselves also accept, that there will be a resurrection of the dead, both of the just and the unjust.", 
                "num": 15
              }, 
              {
                "text": "This being so, I myself always strive to have a conscience without offense toward God and men.", 
                "num": 16
              }, 
              {
                "text": "\"Now after many years I came to bring alms and offerings to my nation,", 
                "num": 17
              }, 
              {
                "text": "in the midst of which some Jews from Asia found me purified in the temple, neither with a mob nor with tumult.", 
                "num": 18
              }, 
              {
                "text": "They ought to have been here before you to object if they had anything against me.", 
                "num": 19
              }, 
              {
                "text": "Or else let those who are here themselves say if they found any wrongdoing in me while I stood before the council,", 
                "num": 20
              }, 
              {
                "text": "unless it is for this one statement which I cried out, standing among them, \"Concerning the resurrection of the dead I am being judged by you this day.\"'", 
                "num": 21
              }, 
              {
                "text": "But when Felix heard these things, having more accurate knowledge of the Way, he adjourned the proceedings and said, \"When Lysias the commander comes down, I will make a decision on your case.\"", 
                "num": 22
              }, 
              {
                "text": "So he commanded the centurion to keep Paul and to let him have liberty, and told him not to forbid any of his friends to provide for or visit him.", 
                "num": 23
              }, 
              {
                "text": "And after some days, when Felix came with his wife Drusilla, who was Jewish, he sent for Paul and heard him concerning the faith in Christ.", 
                "num": 24
              }, 
              {
                "text": "Now as he reasoned about righteousness, self-control, and the judgment to come, Felix was afraid and answered, \"Go away for now; when I have a convenient time I will call for you.\"", 
                "num": 25
              }, 
              {
                "text": "Meanwhile he also hoped that money would be given him by Paul, that he might release him. Therefore he sent for him more often and conversed with him.", 
                "num": 26
              }, 
              {
                "text": "But after two years Porcius Festus succeeded Felix; and Felix, wanting to do the Jews a favor, left Paul bound.", 
                "num": 27
              }
            ], 
            "num": 24
          }, 
          {
            "verses": [
              {
                "text": "Now when Festus had come to the province, after three days he went up from Caesarea to Jerusalem.", 
                "num": 1
              }, 
              {
                "text": "Then the high priest and the chief men of the Jews informed him against Paul; and they petitioned him,", 
                "num": 2
              }, 
              {
                "text": "asking a favor against him, that he would summon him to Jerusalem--while they lay in ambush along the road to kill him.", 
                "num": 3
              }, 
              {
                "text": "But Festus answered that Paul should be kept at Caesarea, and that he himself was going there shortly.", 
                "num": 4
              }, 
              {
                "text": "\"Therefore,\" he said, \"let those who have authority among you go down with me and accuse this man, to see if there is any fault in him.\"", 
                "num": 5
              }, 
              {
                "text": "And when he had remained among them more than ten days, he went down to Caesarea. And the next day, sitting on the judgment seat, he commanded Paul to be brought.", 
                "num": 6
              }, 
              {
                "text": "When he had come, the Jews who had come down from Jerusalem stood about and laid many serious complaints against Paul, which they could not prove,", 
                "num": 7
              }, 
              {
                "text": "while he answered for himself, \"Neither against the law of the Jews, nor against the temple, nor against Caesar have I offended in anything at all.\"", 
                "num": 8
              }, 
              {
                "text": "But Festus, wanting to do the Jews a favor, answered Paul and said, \"Are you willing to go up to Jerusalem and there be judged before me concerning these things?\"", 
                "num": 9
              }, 
              {
                "text": "So Paul said, \"I stand at Caesar's judgment seat, where I ought to be judged. To the Jews I have done no wrong, as you very well know.", 
                "num": 10
              }, 
              {
                "text": "For if I am an offender, or have committed anything deserving of death, I do not object to dying; but if there is nothing in these things of which these men accuse me, no one can deliver me to them. I appeal to Caesar.\"", 
                "num": 11
              }, 
              {
                "text": "Then Festus, when he had conferred with the council, answered, \"You have appealed to Caesar? To Caesar you shall go!\"", 
                "num": 12
              }, 
              {
                "text": "And after some days King Agrippa and Bernice came to Caesarea to greet Festus.", 
                "num": 13
              }, 
              {
                "text": "When they had been there many days, Festus laid Paul's case before the king, saying: \"There is a certain man left a prisoner by Felix,", 
                "num": 14
              }, 
              {
                "text": "about whom the chief priests and the elders of the Jews informed me, when I was in Jerusalem, asking for a judgment against him.", 
                "num": 15
              }, 
              {
                "text": "To them I answered, \"It is not the custom of the Romans to deliver any man to destruction before the accused meets the accusers face to face, and has opportunity to answer for himself concerning the charge against him.'", 
                "num": 16
              }, 
              {
                "text": "Therefore when they had come together, without any delay, the next day I sat on the judgment seat and commanded the man to be brought in.", 
                "num": 17
              }, 
              {
                "text": "When the accusers stood up, they brought no accusation against him of such things as I supposed,", 
                "num": 18
              }, 
              {
                "text": "but had some questions against him about their own religion and about a certain Jesus, who had died, whom Paul affirmed to be alive.", 
                "num": 19
              }, 
              {
                "text": "And because I was uncertain of such questions, I asked whether he was willing to go to Jerusalem and there be judged concerning these matters.", 
                "num": 20
              }, 
              {
                "text": "But when Paul appealed to be reserved for the decision of Augustus, I commanded him to be kept till I could send him to Caesar.\"", 
                "num": 21
              }, 
              {
                "text": "Then Agrippa said to Festus, \"I also would like to hear the man myself.\" \"Tomorrow,\" he said, \"you shall hear him.\"", 
                "num": 22
              }, 
              {
                "text": "So the next day, when Agrippa and Bernice had come with great pomp, and had entered the auditorium with the commanders and the prominent men of the city, at Festus' command Paul was brought in.", 
                "num": 23
              }, 
              {
                "text": "And Festus said: \"King Agrippa and all the men who are here present with us, you see this man about whom the whole assembly of the Jews petitioned me, both at Jerusalem and here, crying out that he was not fit to live any longer.", 
                "num": 24
              }, 
              {
                "text": "But when I found that he had committed nothing deserving of death, and that he himself had appealed to Augustus, I decided to send him.", 
                "num": 25
              }, 
              {
                "text": "I have nothing certain to write to my lord concerning him. Therefore I have brought him out before you, and especially before you, King Agrippa, so that after the examination has taken place I may have something to write.", 
                "num": 26
              }, 
              {
                "text": "For it seems to me unreasonable to send a prisoner and not to specify the charges against him.\"", 
                "num": 27
              }
            ], 
            "num": 25
          }, 
          {
            "verses": [
              {
                "text": "Then Agrippa said to Paul, \"You are permitted to speak for yourself.\" So Paul stretched out his hand and answered for himself:", 
                "num": 1
              }, 
              {
                "text": "\"I think myself happy, King Agrippa, because today I shall answer for myself before you concerning all the things of which I am accused by the Jews,", 
                "num": 2
              }, 
              {
                "text": "especially because you are expert in all customs and questions which have to do with the Jews. Therefore I beg you to hear me patiently.", 
                "num": 3
              }, 
              {
                "text": "\"My manner of life from my youth, which was spent from the beginning among my own nation at Jerusalem, all the Jews know.", 
                "num": 4
              }, 
              {
                "text": "They knew me from the first, if they were willing to testify, that according to the strictest sect of our religion I lived a Pharisee.", 
                "num": 5
              }, 
              {
                "text": "And now I stand and am judged for the hope of the promise made by God to our fathers.", 
                "num": 6
              }, 
              {
                "text": "To this promise our twelve tribes, earnestly serving God night and day, hope to attain. For this hope's sake, King Agrippa, I am accused by the Jews.", 
                "num": 7
              }, 
              {
                "text": "Why should it be thought incredible by you that God raises the dead?", 
                "num": 8
              }, 
              {
                "text": "\"Indeed, I myself thought I must do many things contrary to the name of Jesus of Nazareth.", 
                "num": 9
              }, 
              {
                "text": "This I also did in Jerusalem, and many of the saints I shut up in prison, having received authority from the chief priests; and when they were put to death, I cast my vote against them.", 
                "num": 10
              }, 
              {
                "text": "And I punished them often in every synagogue and compelled them to blaspheme; and being exceedingly enraged against them, I persecuted them even to foreign cities.", 
                "num": 11
              }, 
              {
                "text": "\"While thus occupied, as I journeyed to Damascus with authority and commission from the chief priests,", 
                "num": 12
              }, 
              {
                "text": "at midday, O king, along the road I saw a light from heaven, brighter than the sun, shining around me and those who journeyed with me.", 
                "num": 13
              }, 
              {
                "text": "And when we all had fallen to the ground, I heard a voice speaking to me and saying in the Hebrew language, \"Saul, Saul, why are you persecuting Me? It is hard for you to kick against the goads.'", 
                "num": 14
              }, 
              {
                "text": "So I said, \"Who are You, Lord?' And He said, \"I am Jesus, whom you are persecuting.", 
                "num": 15
              }, 
              {
                "text": "But rise and stand on your feet; for I have appeared to you for this purpose, to make you a minister and a witness both of the things which you have seen and of the things which I will yet reveal to you.", 
                "num": 16
              }, 
              {
                "text": "I will deliver you from the Jewish people, as well as from the Gentiles, to whom I now send you,", 
                "num": 17
              }, 
              {
                "text": "to open their eyes, in order to turn them from darkness to light, and from the power of Satan to God, that they may receive forgiveness of sins and an inheritance among those who are sanctified by faith in Me.'", 
                "num": 18
              }, 
              {
                "text": "\"Therefore, King Agrippa, I was not disobedient to the heavenly vision,", 
                "num": 19
              }, 
              {
                "text": "but declared first to those in Damascus and in Jerusalem, and throughout all the region of Judea, and then to the Gentiles, that they should repent, turn to God, and do works befitting repentance.", 
                "num": 20
              }, 
              {
                "text": "For these reasons the Jews seized me in the temple and tried to kill me.", 
                "num": 21
              }, 
              {
                "text": "Therefore, having obtained help from God, to this day I stand, witnessing both to small and great, saying no other things than those which the prophets and Moses said would come--", 
                "num": 22
              }, 
              {
                "text": "that the Christ would suffer, that He would be the first to rise from the dead, and would proclaim light to the Jewish people and to the Gentiles.\"", 
                "num": 23
              }, 
              {
                "text": "Now as he thus made his defense, Festus said with a loud voice, \"Paul, you are beside yourself! Much learning is driving you mad!\"", 
                "num": 24
              }, 
              {
                "text": "But he said, \"I am not mad, most noble Festus, but speak the words of truth and reason.", 
                "num": 25
              }, 
              {
                "text": "For the king, before whom I also speak freely, knows these things; for I am convinced that none of these things escapes his attention, since this thing was not done in a corner.", 
                "num": 26
              }, 
              {
                "text": "King Agrippa, do you believe the prophets? I know that you do believe.\"", 
                "num": 27
              }, 
              {
                "text": "Then Agrippa said to Paul, \"You almost persuade me to become a Christian.\"", 
                "num": 28
              }, 
              {
                "text": "And Paul said, \"I would to God that not only you, but also all who hear me today, might become both almost and altogether such as I am, except for these chains.\"", 
                "num": 29
              }, 
              {
                "text": "When he had said these things, the king stood up, as well as the governor and Bernice and those who sat with them;", 
                "num": 30
              }, 
              {
                "text": "and when they had gone aside, they talked among themselves, saying, \"This man is doing nothing deserving of death or chains.\"", 
                "num": 31
              }, 
              {
                "text": "Then Agrippa said to Festus, \"This man might have been set free if he had not appealed to Caesar.\"", 
                "num": 32
              }
            ], 
            "num": 26
          }, 
          {
            "verses": [
              {
                "text": "And when it was decided that we should sail to Italy, they delivered Paul and some other prisoners to one named Julius, a centurion of the Augustan Regiment.", 
                "num": 1
              }, 
              {
                "text": "So, entering a ship of Adramyttium, we put to sea, meaning to sail along the coasts of Asia. Aristarchus, a Macedonian of Thessalonica, was with us.", 
                "num": 2
              }, 
              {
                "text": "And the next day we landed at Sidon. And Julius treated Paul kindly and gave him liberty to go to his friends and receive care.", 
                "num": 3
              }, 
              {
                "text": "When we had put to sea from there, we sailed under the shelter of Cyprus, because the winds were contrary.", 
                "num": 4
              }, 
              {
                "text": "And when we had sailed over the sea which is off Cilicia and Pamphylia, we came to Myra, a city of Lycia.", 
                "num": 5
              }, 
              {
                "text": "There the centurion found an Alexandrian ship sailing to Italy, and he put us on board.", 
                "num": 6
              }, 
              {
                "text": "When we had sailed slowly many days, and arrived with difficulty off Cnidus, the wind not permitting us to proceed, we sailed under the shelter of Crete off Salmone.", 
                "num": 7
              }, 
              {
                "text": "Passing it with difficulty, we came to a place called Fair Havens, near the city of Lasea.", 
                "num": 8
              }, 
              {
                "text": "Now when much time had been spent, and sailing was now dangerous because the Fast was already over, Paul advised them,", 
                "num": 9
              }, 
              {
                "text": "saying, \"Men, I perceive that this voyage will end with disaster and much loss, not only of the cargo and ship, but also our lives.\"", 
                "num": 10
              }, 
              {
                "text": "Nevertheless the centurion was more persuaded by the helmsman and the owner of the ship than by the things spoken by Paul.", 
                "num": 11
              }, 
              {
                "text": "And because the harbor was not suitable to winter in, the majority advised to set sail from there also, if by any means they could reach Phoenix, a harbor of Crete opening toward the southwest and northwest, and winter there.", 
                "num": 12
              }, 
              {
                "text": "When the south wind blew softly, supposing that they had obtained their desire, putting out to sea, they sailed close by Crete.", 
                "num": 13
              }, 
              {
                "text": "But not long after, a tempestuous head wind arose, called Euroclydon.", 
                "num": 14
              }, 
              {
                "text": "So when the ship was caught, and could not head into the wind, we let her drive.", 
                "num": 15
              }, 
              {
                "text": "And running under the shelter of an island called Clauda, we secured the skiff with difficulty.", 
                "num": 16
              }, 
              {
                "text": "When they had taken it on board, they used cables to undergird the ship; and fearing lest they should run aground on the Syrtis Sands, they struck sail and so were driven.", 
                "num": 17
              }, 
              {
                "text": "And because we were exceedingly tempest-tossed, the next day they lightened the ship.", 
                "num": 18
              }, 
              {
                "text": "On the third day we threw the ship's tackle overboard with our own hands.", 
                "num": 19
              }, 
              {
                "text": "Now when neither sun nor stars appeared for many days, and no small tempest beat on us, all hope that we would be saved was finally given up.", 
                "num": 20
              }, 
              {
                "text": "But after long abstinence from food, then Paul stood in the midst of them and said, \"Men, you should have listened to me, and not have sailed from Crete and incurred this disaster and loss.", 
                "num": 21
              }, 
              {
                "text": "And now I urge you to take heart, for there will be no loss of life among you, but only of the ship.", 
                "num": 22
              }, 
              {
                "text": "For there stood by me this night an angel of the God to whom I belong and whom I serve,", 
                "num": 23
              }, 
              {
                "text": "saying, \"Do not be afraid, Paul; you must be brought before Caesar; and indeed God has granted you all those who sail with you.'", 
                "num": 24
              }, 
              {
                "text": "Therefore take heart, men, for I believe God that it will be just as it was told me.", 
                "num": 25
              }, 
              {
                "text": "However, we must run aground on a certain island.\"", 
                "num": 26
              }, 
              {
                "text": "Now when the fourteenth night had come, as we were driven up and down in the Adriatic Sea, about midnight the sailors sensed that they were drawing near some land.", 
                "num": 27
              }, 
              {
                "text": "And they took soundings and found it to be twenty fathoms; and when they had gone a little farther, they took soundings again and found it to be fifteen fathoms.", 
                "num": 28
              }, 
              {
                "text": "Then, fearing lest we should run aground on the rocks, they dropped four anchors from the stern, and prayed for day to come.", 
                "num": 29
              }, 
              {
                "text": "And as the sailors were seeking to escape from the ship, when they had let down the skiff into the sea, under pretense of putting out anchors from the prow,", 
                "num": 30
              }, 
              {
                "text": "Paul said to the centurion and the soldiers, \"Unless these men stay in the ship, you cannot be saved.\"", 
                "num": 31
              }, 
              {
                "text": "Then the soldiers cut away the ropes of the skiff and let it fall off.", 
                "num": 32
              }, 
              {
                "text": "And as day was about to dawn, Paul implored them all to take food, saying, \"Today is the fourteenth day you have waited and continued without food, and eaten nothing.", 
                "num": 33
              }, 
              {
                "text": "Therefore I urge you to take nourishment, for this is for your survival, since not a hair will fall from the head of any of you.\"", 
                "num": 34
              }, 
              {
                "text": "And when he had said these things, he took bread and gave thanks to God in the presence of them all; and when he had broken it he began to eat.", 
                "num": 35
              }, 
              {
                "text": "Then they were all encouraged, and also took food themselves.", 
                "num": 36
              }, 
              {
                "text": "And in all we were two hundred and seventy-six persons on the ship.", 
                "num": 37
              }, 
              {
                "text": "So when they had eaten enough, they lightened the ship and threw out the wheat into the sea.", 
                "num": 38
              }, 
              {
                "text": "When it was day, they did not recognize the land; but they observed a bay with a beach, onto which they planned to run the ship if possible.", 
                "num": 39
              }, 
              {
                "text": "And they let go the anchors and left them in the sea, meanwhile loosing the rudder ropes; and they hoisted the mainsail to the wind and made for shore.", 
                "num": 40
              }, 
              {
                "text": "But striking a place where two seas met, they ran the ship aground; and the prow stuck fast and remained immovable, but the stern was being broken up by the violence of the waves.", 
                "num": 41
              }, 
              {
                "text": "And the soldiers' plan was to kill the prisoners, lest any of them should swim away and escape.", 
                "num": 42
              }, 
              {
                "text": "But the centurion, wanting to save Paul, kept them from their purpose, and commanded that those who could swim should jump overboard first and get to land,", 
                "num": 43
              }, 
              {
                "text": "and the rest, some on boards and some on parts of the ship. And so it was that they all escaped safely to land.", 
                "num": 44
              }
            ], 
            "num": 27
          }, 
          {
            "verses": [
              {
                "text": "Now when they had escaped, they then found out that the island was called Malta.", 
                "num": 1
              }, 
              {
                "text": "And the natives showed us unusual kindness; for they kindled a fire and made us all welcome, because of the rain that was falling and because of the cold.", 
                "num": 2
              }, 
              {
                "text": "But when Paul had gathered a bundle of sticks and laid them on the fire, a viper came out because of the heat, and fastened on his hand.", 
                "num": 3
              }, 
              {
                "text": "So when the natives saw the creature hanging from his hand, they said to one another, \"No doubt this man is a murderer, whom, though he has escaped the sea, yet justice does not allow to live.\"", 
                "num": 4
              }, 
              {
                "text": "But he shook off the creature into the fire and suffered no harm.", 
                "num": 5
              }, 
              {
                "text": "However, they were expecting that he would swell up or suddenly fall down dead. But after they had looked for a long time and saw no harm come to him, they changed their minds and said that he was a god.", 
                "num": 6
              }, 
              {
                "text": "In that region there was an estate of the leading citizen of the island, whose name was Publius, who received us and entertained us courteously for three days.", 
                "num": 7
              }, 
              {
                "text": "And it happened that the father of Publius lay sick of a fever and dysentery. Paul went in to him and prayed, and he laid his hands on him and healed him.", 
                "num": 8
              }, 
              {
                "text": "So when this was done, the rest of those on the island who had diseases also came and were healed.", 
                "num": 9
              }, 
              {
                "text": "They also honored us in many ways; and when we departed, they provided such things as were necessary.", 
                "num": 10
              }, 
              {
                "text": "After three months we sailed in an Alexandrian ship whose figurehead was the Twin Brothers, which had wintered at the island.", 
                "num": 11
              }, 
              {
                "text": "And landing at Syracuse, we stayed three days.", 
                "num": 12
              }, 
              {
                "text": "From there we circled round and reached Rhegium. And after one day the south wind blew; and the next day we came to Puteoli,", 
                "num": 13
              }, 
              {
                "text": "where we found brethren, and were invited to stay with them seven days. And so we went toward Rome.", 
                "num": 14
              }, 
              {
                "text": "And from there, when the brethren heard about us, they came to meet us as far as Appii Forum and Three Inns. When Paul saw them, he thanked God and took courage.", 
                "num": 15
              }, 
              {
                "text": "Now when we came to Rome, the centurion delivered the prisoners to the captain of the guard; but Paul was permitted to dwell by himself with the soldier who guarded him.", 
                "num": 16
              }, 
              {
                "text": "And it came to pass after three days that Paul called the leaders of the Jews together. So when they had come together, he said to them: \"Men and brethren, though I have done nothing against our people or the customs of our fathers, yet I was delivered as a prisoner from Jerusalem into the hands of the Romans,", 
                "num": 17
              }, 
              {
                "text": "who, when they had examined me, wanted to let me go, because there was no cause for putting me to death.", 
                "num": 18
              }, 
              {
                "text": "But when the Jews spoke against it, I was compelled to appeal to Caesar, not that I had anything of which to accuse my nation.", 
                "num": 19
              }, 
              {
                "text": "For this reason therefore I have called for you, to see you and speak with you, because for the hope of Israel I am bound with this chain.\"", 
                "num": 20
              }, 
              {
                "text": "Then they said to him, \"We neither received letters from Judea concerning you, nor have any of the brethren who came reported or spoken any evil of you.", 
                "num": 21
              }, 
              {
                "text": "But we desire to hear from you what you think; for concerning this sect, we know that it is spoken against everywhere.\"", 
                "num": 22
              }, 
              {
                "text": "So when they had appointed him a day, many came to him at his lodging, to whom he explained and solemnly testified of the kingdom of God, persuading them concerning Jesus from both the Law of Moses and the Prophets, from morning till evening.", 
                "num": 23
              }, 
              {
                "text": "And some were persuaded by the things which were spoken, and some disbelieved.", 
                "num": 24
              }, 
              {
                "text": "So when they did not agree among themselves, they departed after Paul had said one word: \"The Holy Spirit spoke rightly through Isaiah the prophet to our fathers,", 
                "num": 25
              }, 
              {
                "text": "saying, \"Go to this people and say: \"Hearing you will hear, and shall not understand; And seeing you will see, and not perceive;", 
                "num": 26
              }, 
              {
                "text": "For the hearts of this people have grown dull. Their ears are hard of hearing, And their eyes they have closed, Lest they should see with their eyes and hear with their ears, Lest they should understand with their hearts and turn, So that I should heal them.\"'", 
                "num": 27
              }, 
              {
                "text": "\"Therefore let it be known to you that the salvation of God has been sent to the Gentiles, and they will hear it!\"", 
                "num": 28
              }, 
              {
                "text": "And when he had said these words, the Jews departed and had a great dispute among themselves.", 
                "num": 29
              }, 
              {
                "text": "Then Paul dwelt two whole years in his own rented house, and received all who came to him,", 
                "num": 30
              }, 
              {
                "text": "preaching the kingdom of God and teaching the things which concern the Lord Jesus Christ with all confidence, no one forbidding him.", 
                "num": 31
              }
            ], 
            "num": 28
          }
        ]
      }, 
      {
        "name": "Romans", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, a bondservant of Jesus Christ, called to be an apostle, separated to the gospel of God", 
                "num": 1
              }, 
              {
                "text": "which He promised before through His prophets in the Holy Scriptures,", 
                "num": 2
              }, 
              {
                "text": "concerning His Son Jesus Christ our Lord, who was born of the seed of David according to the flesh,", 
                "num": 3
              }, 
              {
                "text": "and declared to be the Son of God with power according to the Spirit of holiness, by the resurrection from the dead.", 
                "num": 4
              }, 
              {
                "text": "Through Him we have received grace and apostleship for obedience to the faith among all nations for His name,", 
                "num": 5
              }, 
              {
                "text": "among whom you also are the called of Jesus Christ;", 
                "num": 6
              }, 
              {
                "text": "To all who are in Rome, beloved of God, called to be saints: Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 7
              }, 
              {
                "text": "First, I thank my God through Jesus Christ for you all, that your faith is spoken of throughout the whole world.", 
                "num": 8
              }, 
              {
                "text": "For God is my witness, whom I serve with my spirit in the gospel of His Son, that without ceasing I make mention of you always in my prayers,", 
                "num": 9
              }, 
              {
                "text": "making request if, by some means, now at last I may find a way in the will of God to come to you.", 
                "num": 10
              }, 
              {
                "text": "For I long to see you, that I may impart to you some spiritual gift, so that you may be established--", 
                "num": 11
              }, 
              {
                "text": "that is, that I may be encouraged together with you by the mutual faith both of you and me.", 
                "num": 12
              }, 
              {
                "text": "Now I do not want you to be unaware, brethren, that I often planned to come to you (but was hindered until now), that I might have some fruit among you also, just as among the other Gentiles.", 
                "num": 13
              }, 
              {
                "text": "I am a debtor both to Greeks and to barbarians, both to wise and to unwise.", 
                "num": 14
              }, 
              {
                "text": "So, as much as is in me, I am ready to preach the gospel to you who are in Rome also.", 
                "num": 15
              }, 
              {
                "text": "For I am not ashamed of the gospel of Christ, for it is the power of God to salvation for everyone who believes, for the Jew first and also for the Greek.", 
                "num": 16
              }, 
              {
                "text": "For in it the righteousness of God is revealed from faith to faith; as it is written, \"The just shall live by faith.\"", 
                "num": 17
              }, 
              {
                "text": "For the wrath of God is revealed from heaven against all ungodliness and unrighteousness of men, who suppress the truth in unrighteousness,", 
                "num": 18
              }, 
              {
                "text": "because what may be known of God is manifest in them, for God has shown it to them.", 
                "num": 19
              }, 
              {
                "text": "For since the creation of the world His invisible attributes are clearly seen, being understood by the things that are made, even His eternal power and Godhead, so that they are without excuse,", 
                "num": 20
              }, 
              {
                "text": "because, although they knew God, they did not glorify Him as God, nor were thankful, but became futile in their thoughts, and their foolish hearts were darkened.", 
                "num": 21
              }, 
              {
                "text": "Professing to be wise, they became fools,", 
                "num": 22
              }, 
              {
                "text": "and changed the glory of the incorruptible God into an image made like corruptible man--and birds and four-footed animals and creeping things.", 
                "num": 23
              }, 
              {
                "text": "Therefore God also gave them up to uncleanness, in the lusts of their hearts, to dishonor their bodies among themselves,", 
                "num": 24
              }, 
              {
                "text": "who exchanged the truth of God for the lie, and worshiped and served the creature rather than the Creator, who is blessed forever. Amen.", 
                "num": 25
              }, 
              {
                "text": "For this reason God gave them up to vile passions. For even their women exchanged the natural use for what is against nature.", 
                "num": 26
              }, 
              {
                "text": "Likewise also the men, leaving the natural use of the woman, burned in their lust for one another, men with men committing what is shameful, and receiving in themselves the penalty of their error which was due.", 
                "num": 27
              }, 
              {
                "text": "And even as they did not like to retain God in their knowledge, God gave them over to a debased mind, to do those things which are not fitting;", 
                "num": 28
              }, 
              {
                "text": "being filled with all unrighteousness, sexual immorality, wickedness, covetousness, maliciousness; full of envy, murder, strife, deceit, evil-mindedness; they are whisperers,", 
                "num": 29
              }, 
              {
                "text": "backbiters, haters of God, violent, proud, boasters, inventors of evil things, disobedient to parents,", 
                "num": 30
              }, 
              {
                "text": "undiscerning, untrustworthy, unloving, unforgiving, unmerciful;", 
                "num": 31
              }, 
              {
                "text": "who, knowing the righteous judgment of God, that those who practice such things are deserving of death, not only do the same but also approve of those who practice them.", 
                "num": 32
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Therefore you are inexcusable, O man, whoever you are who judge, for in whatever you judge another you condemn yourself; for you who judge practice the same things.", 
                "num": 1
              }, 
              {
                "text": "But we know that the judgment of God is according to truth against those who practice such things.", 
                "num": 2
              }, 
              {
                "text": "And do you think this, O man, you who judge those practicing such things, and doing the same, that you will escape the judgment of God?", 
                "num": 3
              }, 
              {
                "text": "Or do you despise the riches of His goodness, forbearance, and longsuffering, not knowing that the goodness of God leads you to repentance?", 
                "num": 4
              }, 
              {
                "text": "But in accordance with your hardness and your impenitent heart you are treasuring up for yourself wrath in the day of wrath and revelation of the righteous judgment of God,", 
                "num": 5
              }, 
              {
                "text": "who \"will render to each one according to his deeds\":", 
                "num": 6
              }, 
              {
                "text": "eternal life to those who by patient continuance in doing good seek for glory, honor, and immortality;", 
                "num": 7
              }, 
              {
                "text": "but to those who are self-seeking and do not obey the truth, but obey unrighteousness--indignation and wrath,", 
                "num": 8
              }, 
              {
                "text": "tribulation and anguish, on every soul of man who does evil, of the Jew first and also of the Greek;", 
                "num": 9
              }, 
              {
                "text": "but glory, honor, and peace to everyone who works what is good, to the Jew first and also to the Greek.", 
                "num": 10
              }, 
              {
                "text": "For there is no partiality with God.", 
                "num": 11
              }, 
              {
                "text": "For as many as have sinned without law will also perish without law, and as many as have sinned in the law will be judged by the law", 
                "num": 12
              }, 
              {
                "text": "(for not the hearers of the law are just in the sight of God, but the doers of the law will be justified;", 
                "num": 13
              }, 
              {
                "text": "for when Gentiles, who do not have the law, by nature do the things in the law, these, although not having the law, are a law to themselves,", 
                "num": 14
              }, 
              {
                "text": "who show the work of the law written in their hearts, their conscience also bearing witness, and between themselves their thoughts accusing or else excusing them)", 
                "num": 15
              }, 
              {
                "text": "in the day when God will judge the secrets of men by Jesus Christ, according to my gospel.", 
                "num": 16
              }, 
              {
                "text": "Indeed you are called a Jew, and rest on the law, and make your boast in God,", 
                "num": 17
              }, 
              {
                "text": "and know His will, and approve the things that are excellent, being instructed out of the law,", 
                "num": 18
              }, 
              {
                "text": "and are confident that you yourself are a guide to the blind, a light to those who are in darkness,", 
                "num": 19
              }, 
              {
                "text": "an instructor of the foolish, a teacher of babes, having the form of knowledge and truth in the law.", 
                "num": 20
              }, 
              {
                "text": "You, therefore, who teach another, do you not teach yourself? You who preach that a man should not steal, do you steal?", 
                "num": 21
              }, 
              {
                "text": "You who say, \"Do not commit adultery,\" do you commit adultery? You who abhor idols, do you rob temples?", 
                "num": 22
              }, 
              {
                "text": "You who make your boast in the law, do you dishonor God through breaking the law?", 
                "num": 23
              }, 
              {
                "text": "For \"the name of God is blasphemed among the Gentiles because of you,\" as it is written.", 
                "num": 24
              }, 
              {
                "text": "For circumcision is indeed profitable if you keep the law; but if you are a breaker of the law, your circumcision has become uncircumcision.", 
                "num": 25
              }, 
              {
                "text": "Therefore, if an uncircumcised man keeps the righteous requirements of the law, will not his uncircumcision be counted as circumcision?", 
                "num": 26
              }, 
              {
                "text": "And will not the physically uncircumcised, if he fulfills the law, judge you who, even with your written code and circumcision, are a transgressor of the law?", 
                "num": 27
              }, 
              {
                "text": "For he is not a Jew who is one outwardly, nor is circumcision that which is outward in the flesh;", 
                "num": 28
              }, 
              {
                "text": "but he is a Jew who is one inwardly; and circumcision is that of the heart, in the Spirit, not in the letter; whose praise is not from men but from God.", 
                "num": 29
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "What advantage then has the Jew, or what is the profit of circumcision?", 
                "num": 1
              }, 
              {
                "text": "Much in every way! Chiefly because to them were committed the oracles of God.", 
                "num": 2
              }, 
              {
                "text": "For what if some did not believe? Will their unbelief make the faithfulness of God without effect?", 
                "num": 3
              }, 
              {
                "text": "Certainly not! Indeed, let God be true but every man a liar. As it is written: \"That You may be justified in Your words, And may overcome when You are judged.\"", 
                "num": 4
              }, 
              {
                "text": "But if our unrighteousness demonstrates the righteousness of God, what shall we say? Is God unjust who inflicts wrath? (I speak as a man.)", 
                "num": 5
              }, 
              {
                "text": "Certainly not! For then how will God judge the world?", 
                "num": 6
              }, 
              {
                "text": "For if the truth of God has increased through my lie to His glory, why am I also still judged as a sinner?", 
                "num": 7
              }, 
              {
                "text": "And why not say, \"Let us do evil that good may come\"?--as we are slanderously reported and as some affirm that we say. Their condemnation is just.", 
                "num": 8
              }, 
              {
                "text": "What then? Are we better than they? Not at all. For we have previously charged both Jews and Greeks that they are all under sin.", 
                "num": 9
              }, 
              {
                "text": "As it is written: \"There is none righteous, no, not one;", 
                "num": 10
              }, 
              {
                "text": "There is none who understands; There is none who seeks after God.", 
                "num": 11
              }, 
              {
                "text": "They have all turned aside; They have together become unprofitable; There is none who does good, no, not one.\"", 
                "num": 12
              }, 
              {
                "text": "\"Their throat is an open tomb; With their tongues they have practiced deceit\"; \"The poison of asps is under their lips\";", 
                "num": 13
              }, 
              {
                "text": "\"Whose mouth is full of cursing and bitterness.\"", 
                "num": 14
              }, 
              {
                "text": "\"Their feet are swift to shed blood;", 
                "num": 15
              }, 
              {
                "text": "Destruction and misery are in their ways;", 
                "num": 16
              }, 
              {
                "text": "And the way of peace they have not known.\"", 
                "num": 17
              }, 
              {
                "text": "\"There is no fear of God before their eyes.\"", 
                "num": 18
              }, 
              {
                "text": "Now we know that whatever the law says, it says to those who are under the law, that every mouth may be stopped, and all the world may become guilty before God.", 
                "num": 19
              }, 
              {
                "text": "Therefore by the deeds of the law no flesh will be justified in His sight, for by the law is the knowledge of sin.", 
                "num": 20
              }, 
              {
                "text": "But now the righteousness of God apart from the law is revealed, being witnessed by the Law and the Prophets,", 
                "num": 21
              }, 
              {
                "text": "even the righteousness of God, through faith in Jesus Christ, to all and on all who believe. For there is no difference;", 
                "num": 22
              }, 
              {
                "text": "for all have sinned and fall short of the glory of God,", 
                "num": 23
              }, 
              {
                "text": "being justified freely by His grace through the redemption that is in Christ Jesus,", 
                "num": 24
              }, 
              {
                "text": "whom God set forth as a propitiation by His blood, through faith, to demonstrate His righteousness, because in His forbearance God had passed over the sins that were previously committed,", 
                "num": 25
              }, 
              {
                "text": "to demonstrate at the present time His righteousness, that He might be just and the justifier of the one who has faith in Jesus.", 
                "num": 26
              }, 
              {
                "text": "Where is boasting then? It is excluded. By what law? Of works? No, but by the law of faith.", 
                "num": 27
              }, 
              {
                "text": "Therefore we conclude that a man is justified by faith apart from the deeds of the law.", 
                "num": 28
              }, 
              {
                "text": "Or is He the God of the Jews only? Is He not also the God of the Gentiles? Yes, of the Gentiles also,", 
                "num": 29
              }, 
              {
                "text": "since there is one God who will justify the circumcised by faith and the uncircumcised through faith.", 
                "num": 30
              }, 
              {
                "text": "Do we then make void the law through faith? Certainly not! On the contrary, we establish the law.", 
                "num": 31
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "What then shall we say that Abraham our father has found according to the flesh?", 
                "num": 1
              }, 
              {
                "text": "For if Abraham was justified by works, he has something to boast about, but not before God.", 
                "num": 2
              }, 
              {
                "text": "For what does the Scripture say? \"Abraham believed God, and it was accounted to him for righteousness.\"", 
                "num": 3
              }, 
              {
                "text": "Now to him who works, the wages are not counted as grace but as debt.", 
                "num": 4
              }, 
              {
                "text": "But to him who does not work but believes on Him who justifies the ungodly, his faith is accounted for righteousness,", 
                "num": 5
              }, 
              {
                "text": "just as David also describes the blessedness of the man to whom God imputes righteousness apart from works:", 
                "num": 6
              }, 
              {
                "text": "\"Blessed are those whose lawless deeds are forgiven, And whose sins are covered;", 
                "num": 7
              }, 
              {
                "text": "Blessed is the man to whom the LORD shall not impute sin.\"", 
                "num": 8
              }, 
              {
                "text": "Does this blessedness then come upon the circumcised only, or upon the uncircumcised also? For we say that faith was accounted to Abraham for righteousness.", 
                "num": 9
              }, 
              {
                "text": "How then was it accounted? While he was circumcised, or uncircumcised? Not while circumcised, but while uncircumcised.", 
                "num": 10
              }, 
              {
                "text": "And he received the sign of circumcision, a seal of the righteousness of the faith which he had while still uncircumcised, that he might be the father of all those who believe, though they are uncircumcised, that righteousness might be imputed to them also,", 
                "num": 11
              }, 
              {
                "text": "and the father of circumcision to those who not only are of the circumcision, but who also walk in the steps of the faith which our father Abraham had while still uncircumcised.", 
                "num": 12
              }, 
              {
                "text": "For the promise that he would be the heir of the world was not to Abraham or to his seed through the law, but through the righteousness of faith.", 
                "num": 13
              }, 
              {
                "text": "For if those who are of the law are heirs, faith is made void and the promise made of no effect,", 
                "num": 14
              }, 
              {
                "text": "because the law brings about wrath; for where there is no law there is no transgression.", 
                "num": 15
              }, 
              {
                "text": "Therefore it is of faith that it might be according to grace, so that the promise might be sure to all the seed, not only to those who are of the law, but also to those who are of the faith of Abraham, who is the father of us all", 
                "num": 16
              }, 
              {
                "text": "(as it is written, \"I have made you a father of many nations\") in the presence of Him whom he believed--God, who gives life to the dead and calls those things which do not exist as though they did;", 
                "num": 17
              }, 
              {
                "text": "who, contrary to hope, in hope believed, so that he became the father of many nations, according to what was spoken, \"So shall your descendants be.\"", 
                "num": 18
              }, 
              {
                "text": "And not being weak in faith, he did not consider his own body, already dead (since he was about a hundred years old), and the deadness of Sarah's womb.", 
                "num": 19
              }, 
              {
                "text": "He did not waver at the promise of God through unbelief, but was strengthened in faith, giving glory to God,", 
                "num": 20
              }, 
              {
                "text": "and being fully convinced that what He had promised He was also able to perform.", 
                "num": 21
              }, 
              {
                "text": "And therefore \"it was accounted to him for righteousness.\"", 
                "num": 22
              }, 
              {
                "text": "Now it was not written for his sake alone that it was imputed to him,", 
                "num": 23
              }, 
              {
                "text": "but also for us. It shall be imputed to us who believe in Him who raised up Jesus our Lord from the dead,", 
                "num": 24
              }, 
              {
                "text": "who was delivered up because of our offenses, and was raised because of our justification.", 
                "num": 25
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Therefore, having been justified by faith, we have peace with God through our Lord Jesus Christ,", 
                "num": 1
              }, 
              {
                "text": "through whom also we have access by faith into this grace in which we stand, and rejoice in hope of the glory of God.", 
                "num": 2
              }, 
              {
                "text": "And not only that, but we also glory in tribulations, knowing that tribulation produces perseverance;", 
                "num": 3
              }, 
              {
                "text": "and perseverance, character; and character, hope.", 
                "num": 4
              }, 
              {
                "text": "Now hope does not disappoint, because the love of God has been poured out in our hearts by the Holy Spirit who was given to us.", 
                "num": 5
              }, 
              {
                "text": "For when we were still without strength, in due time Christ died for the ungodly.", 
                "num": 6
              }, 
              {
                "text": "For scarcely for a righteous man will one die; yet perhaps for a good man someone would even dare to die.", 
                "num": 7
              }, 
              {
                "text": "But God demonstrates His own love toward us, in that while we were still sinners, Christ died for us.", 
                "num": 8
              }, 
              {
                "text": "Much more then, having now been justified by His blood, we shall be saved from wrath through Him.", 
                "num": 9
              }, 
              {
                "text": "For if when we were enemies we were reconciled to God through the death of His Son, much more, having been reconciled, we shall be saved by His life.", 
                "num": 10
              }, 
              {
                "text": "And not only that, but we also rejoice in God through our Lord Jesus Christ, through whom we have now received the reconciliation.", 
                "num": 11
              }, 
              {
                "text": "Therefore, just as through one man sin entered the world, and death through sin, and thus death spread to all men, because all sinned--", 
                "num": 12
              }, 
              {
                "text": "(For until the law sin was in the world, but sin is not imputed when there is no law.", 
                "num": 13
              }, 
              {
                "text": "Nevertheless death reigned from Adam to Moses, even over those who had not sinned according to the likeness of the transgression of Adam, who is a type of Him who was to come.", 
                "num": 14
              }, 
              {
                "text": "But the free gift is not like the offense. For if by the one man's offense many died, much more the grace of God and the gift by the grace of the one Man, Jesus Christ, abounded to many.", 
                "num": 15
              }, 
              {
                "text": "And the gift is not like that which came through the one who sinned. For the judgment which came from one offense resulted in condemnation, but the free gift which came from many offenses resulted in justification.", 
                "num": 16
              }, 
              {
                "text": "For if by the one man's offense death reigned through the one, much more those who receive abundance of grace and of the gift of righteousness will reign in life through the One, Jesus Christ.)", 
                "num": 17
              }, 
              {
                "text": "Therefore, as through one man's offense judgment came to all men, resulting in condemnation, even so through one Man's righteous act the free gift came to all men, resulting in justification of life.", 
                "num": 18
              }, 
              {
                "text": "For as by one man's disobedience many were made sinners, so also by one Man's obedience many will be made righteous.", 
                "num": 19
              }, 
              {
                "text": "Moreover the law entered that the offense might abound. But where sin abounded, grace abounded much more,", 
                "num": 20
              }, 
              {
                "text": "so that as sin reigned in death, even so grace might reign through righteousness to eternal life through Jesus Christ our Lord.", 
                "num": 21
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "What shall we say then? Shall we continue in sin that grace may abound?", 
                "num": 1
              }, 
              {
                "text": "Certainly not! How shall we who died to sin live any longer in it?", 
                "num": 2
              }, 
              {
                "text": "Or do you not know that as many of us as were baptized into Christ Jesus were baptized into His death?", 
                "num": 3
              }, 
              {
                "text": "Therefore we were buried with Him through baptism into death, that just as Christ was raised from the dead by the glory of the Father, even so we also should walk in newness of life.", 
                "num": 4
              }, 
              {
                "text": "For if we have been united together in the likeness of His death, certainly we also shall be in the likeness of His resurrection,", 
                "num": 5
              }, 
              {
                "text": "knowing this, that our old man was crucified with Him, that the body of sin might be done away with, that we should no longer be slaves of sin.", 
                "num": 6
              }, 
              {
                "text": "For he who has died has been freed from sin.", 
                "num": 7
              }, 
              {
                "text": "Now if we died with Christ, we believe that we shall also live with Him,", 
                "num": 8
              }, 
              {
                "text": "knowing that Christ, having been raised from the dead, dies no more. Death no longer has dominion over Him.", 
                "num": 9
              }, 
              {
                "text": "For the death that He died, He died to sin once for all; but the life that He lives, He lives to God.", 
                "num": 10
              }, 
              {
                "text": "Likewise you also, reckon yourselves to be dead indeed to sin, but alive to God in Christ Jesus our Lord.", 
                "num": 11
              }, 
              {
                "text": "Therefore do not let sin reign in your mortal body, that you should obey it in its lusts.", 
                "num": 12
              }, 
              {
                "text": "And do not present your members as instruments of unrighteousness to sin, but present yourselves to God as being alive from the dead, and your members as instruments of righteousness to God.", 
                "num": 13
              }, 
              {
                "text": "For sin shall not have dominion over you, for you are not under law but under grace.", 
                "num": 14
              }, 
              {
                "text": "What then? Shall we sin because we are not under law but under grace? Certainly not!", 
                "num": 15
              }, 
              {
                "text": "Do you not know that to whom you present yourselves slaves to obey, you are that one's slaves whom you obey, whether of sin leading to death, or of obedience leading to righteousness?", 
                "num": 16
              }, 
              {
                "text": "But God be thanked that though you were slaves of sin, yet you obeyed from the heart that form of doctrine to which you were delivered.", 
                "num": 17
              }, 
              {
                "text": "And having been set free from sin, you became slaves of righteousness.", 
                "num": 18
              }, 
              {
                "text": "I speak in human terms because of the weakness of your flesh. For just as you presented your members as slaves of uncleanness, and of lawlessness leading to more lawlessness, so now present your members as slaves of righteousness for holiness.", 
                "num": 19
              }, 
              {
                "text": "For when you were slaves of sin, you were free in regard to righteousness.", 
                "num": 20
              }, 
              {
                "text": "What fruit did you have then in the things of which you are now ashamed? For the end of those things is death.", 
                "num": 21
              }, 
              {
                "text": "But now having been set free from sin, and having become slaves of God, you have your fruit to holiness, and the end, everlasting life.", 
                "num": 22
              }, 
              {
                "text": "For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.", 
                "num": 23
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Or do you not know, brethren (for I speak to those who know the law), that the law has dominion over a man as long as he lives?", 
                "num": 1
              }, 
              {
                "text": "For the woman who has a husband is bound by the law to her husband as long as he lives. But if the husband dies, she is released from the law of her husband.", 
                "num": 2
              }, 
              {
                "text": "So then if, while her husband lives, she marries another man, she will be called an adulteress; but if her husband dies, she is free from that law, so that she is no adulteress, though she has married another man.", 
                "num": 3
              }, 
              {
                "text": "Therefore, my brethren, you also have become dead to the law through the body of Christ, that you may be married to another--to Him who was raised from the dead, that we should bear fruit to God.", 
                "num": 4
              }, 
              {
                "text": "For when we were in the flesh, the sinful passions which were aroused by the law were at work in our members to bear fruit to death.", 
                "num": 5
              }, 
              {
                "text": "But now we have been delivered from the law, having died to what we were held by, so that we should serve in the newness of the Spirit and not in the oldness of the letter.", 
                "num": 6
              }, 
              {
                "text": "What shall we say then? Is the law sin? Certainly not! On the contrary, I would not have known sin except through the law. For I would not have known covetousness unless the law had said, \"You shall not covet.\"", 
                "num": 7
              }, 
              {
                "text": "But sin, taking opportunity by the commandment, produced in me all manner of evil desire. For apart from the law sin was dead.", 
                "num": 8
              }, 
              {
                "text": "I was alive once without the law, but when the commandment came, sin revived and I died.", 
                "num": 9
              }, 
              {
                "text": "And the commandment, which was to bring life, I found to bring death.", 
                "num": 10
              }, 
              {
                "text": "For sin, taking occasion by the commandment, deceived me, and by it killed me.", 
                "num": 11
              }, 
              {
                "text": "Therefore the law is holy, and the commandment holy and just and good.", 
                "num": 12
              }, 
              {
                "text": "Has then what is good become death to me? Certainly not! But sin, that it might appear sin, was producing death in me through what is good, so that sin through the commandment might become exceedingly sinful.", 
                "num": 13
              }, 
              {
                "text": "For we know that the law is spiritual, but I am carnal, sold under sin.", 
                "num": 14
              }, 
              {
                "text": "For what I am doing, I do not understand. For what I will to do, that I do not practice; but what I hate, that I do.", 
                "num": 15
              }, 
              {
                "text": "If, then, I do what I will not to do, I agree with the law that it is good.", 
                "num": 16
              }, 
              {
                "text": "But now, it is no longer I who do it, but sin that dwells in me.", 
                "num": 17
              }, 
              {
                "text": "For I know that in me (that is, in my flesh) nothing good dwells; for to will is present with me, but how to perform what is good I do not find.", 
                "num": 18
              }, 
              {
                "text": "For the good that I will to do, I do not do; but the evil I will not to do, that I practice.", 
                "num": 19
              }, 
              {
                "text": "Now if I do what I will not to do, it is no longer I who do it, but sin that dwells in me.", 
                "num": 20
              }, 
              {
                "text": "I find then a law, that evil is present with me, the one who wills to do good.", 
                "num": 21
              }, 
              {
                "text": "For I delight in the law of God according to the inward man.", 
                "num": 22
              }, 
              {
                "text": "But I see another law in my members, warring against the law of my mind, and bringing me into captivity to the law of sin which is in my members.", 
                "num": 23
              }, 
              {
                "text": "O wretched man that I am! Who will deliver me from this body of death?", 
                "num": 24
              }, 
              {
                "text": "I thank God--through Jesus Christ our Lord! So then, with the mind I myself serve the law of God, but with the flesh the law of sin.", 
                "num": 25
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "There is therefore now no condemnation to those who are in Christ Jesus, who do not walk according to the flesh, but according to the Spirit.", 
                "num": 1
              }, 
              {
                "text": "For the law of the Spirit of life in Christ Jesus has made me free from the law of sin and death.", 
                "num": 2
              }, 
              {
                "text": "For what the law could not do in that it was weak through the flesh, God did by sending His own Son in the likeness of sinful flesh, on account of sin: He condemned sin in the flesh,", 
                "num": 3
              }, 
              {
                "text": "that the righteous requirement of the law might be fulfilled in us who do not walk according to the flesh but according to the Spirit.", 
                "num": 4
              }, 
              {
                "text": "For those who live according to the flesh set their minds on the things of the flesh, but those who live according to the Spirit, the things of the Spirit.", 
                "num": 5
              }, 
              {
                "text": "For to be carnally minded is death, but to be spiritually minded is life and peace.", 
                "num": 6
              }, 
              {
                "text": "Because the carnal mind is enmity against God; for it is not subject to the law of God, nor indeed can be.", 
                "num": 7
              }, 
              {
                "text": "So then, those who are in the flesh cannot please God.", 
                "num": 8
              }, 
              {
                "text": "But you are not in the flesh but in the Spirit, if indeed the Spirit of God dwells in you. Now if anyone does not have the Spirit of Christ, he is not His.", 
                "num": 9
              }, 
              {
                "text": "And if Christ is in you, the body is dead because of sin, but the Spirit is life because of righteousness.", 
                "num": 10
              }, 
              {
                "text": "But if the Spirit of Him who raised Jesus from the dead dwells in you, He who raised Christ from the dead will also give life to your mortal bodies through His Spirit who dwells in you.", 
                "num": 11
              }, 
              {
                "text": "Therefore, brethren, we are debtors--not to the flesh, to live according to the flesh.", 
                "num": 12
              }, 
              {
                "text": "For if you live according to the flesh you will die; but if by the Spirit you put to death the deeds of the body, you will live.", 
                "num": 13
              }, 
              {
                "text": "For as many as are led by the Spirit of God, these are sons of God.", 
                "num": 14
              }, 
              {
                "text": "For you did not receive the spirit of bondage again to fear, but you received the Spirit of adoption by whom we cry out, \"Abba, Father.\"", 
                "num": 15
              }, 
              {
                "text": "The Spirit Himself bears witness with our spirit that we are children of God,", 
                "num": 16
              }, 
              {
                "text": "and if children, then heirs--heirs of God and joint heirs with Christ, if indeed we suffer with Him, that we may also be glorified together.", 
                "num": 17
              }, 
              {
                "text": "For I consider that the sufferings of this present time are not worthy to be compared with the glory which shall be revealed in us.", 
                "num": 18
              }, 
              {
                "text": "For the earnest expectation of the creation eagerly waits for the revealing of the sons of God.", 
                "num": 19
              }, 
              {
                "text": "For the creation was subjected to futility, not willingly, but because of Him who subjected it in hope;", 
                "num": 20
              }, 
              {
                "text": "because the creation itself also will be delivered from the bondage of corruption into the glorious liberty of the children of God.", 
                "num": 21
              }, 
              {
                "text": "For we know that the whole creation groans and labors with birth pangs together until now.", 
                "num": 22
              }, 
              {
                "text": "Not only that, but we also who have the firstfruits of the Spirit, even we ourselves groan within ourselves, eagerly waiting for the adoption, the redemption of our body.", 
                "num": 23
              }, 
              {
                "text": "For we were saved in this hope, but hope that is seen is not hope; for why does one still hope for what he sees?", 
                "num": 24
              }, 
              {
                "text": "But if we hope for what we do not see, we eagerly wait for it with perseverance.", 
                "num": 25
              }, 
              {
                "text": "Likewise the Spirit also helps in our weaknesses. For we do not know what we should pray for as we ought, but the Spirit Himself makes intercession for us with groanings which cannot be uttered.", 
                "num": 26
              }, 
              {
                "text": "Now He who searches the hearts knows what the mind of the Spirit is, because He makes intercession for the saints according to the will of God.", 
                "num": 27
              }, 
              {
                "text": "And we know that all things work together for good to those who love God, to those who are the called according to His purpose.", 
                "num": 28
              }, 
              {
                "text": "For whom He foreknew, He also predestined to be conformed to the image of His Son, that He might be the firstborn among many brethren.", 
                "num": 29
              }, 
              {
                "text": "Moreover whom He predestined, these He also called; whom He called, these He also justified; and whom He justified, these He also glorified.", 
                "num": 30
              }, 
              {
                "text": "What then shall we say to these things? If God is for us, who can be against us?", 
                "num": 31
              }, 
              {
                "text": "He who did not spare His own Son, but delivered Him up for us all, how shall He not with Him also freely give us all things?", 
                "num": 32
              }, 
              {
                "text": "Who shall bring a charge against God's elect? It is God who justifies.", 
                "num": 33
              }, 
              {
                "text": "Who is he who condemns? It is Christ who died, and furthermore is also risen, who is even at the right hand of God, who also makes intercession for us.", 
                "num": 34
              }, 
              {
                "text": "Who shall separate us from the love of Christ? Shall tribulation, or distress, or persecution, or famine, or nakedness, or peril, or sword?", 
                "num": 35
              }, 
              {
                "text": "As it is written: \"For Your sake we are killed all day long; We are accounted as sheep for the slaughter.\"", 
                "num": 36
              }, 
              {
                "text": "Yet in all these things we are more than conquerors through Him who loved us.", 
                "num": 37
              }, 
              {
                "text": "For I am persuaded that neither death nor life, nor angels nor principalities nor powers, nor things present nor things to come,", 
                "num": 38
              }, 
              {
                "text": "nor height nor depth, nor any other created thing, shall be able to separate us from the love of God which is in Christ Jesus our Lord.", 
                "num": 39
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "I tell the truth in Christ, I am not lying, my conscience also bearing me witness in the Holy Spirit,", 
                "num": 1
              }, 
              {
                "text": "that I have great sorrow and continual grief in my heart.", 
                "num": 2
              }, 
              {
                "text": "For I could wish that I myself were accursed from Christ for my brethren, my countrymen according to the flesh,", 
                "num": 3
              }, 
              {
                "text": "who are Israelites, to whom pertain the adoption, the glory, the covenants, the giving of the law, the service of God, and the promises;", 
                "num": 4
              }, 
              {
                "text": "of whom are the fathers and from whom, according to the flesh, Christ came, who is over all, the eternally blessed God. Amen.", 
                "num": 5
              }, 
              {
                "text": "But it is not that the word of God has taken no effect. For they are not all Israel who are of Israel,", 
                "num": 6
              }, 
              {
                "text": "nor are they all children because they are the seed of Abraham; but, \"In Isaac your seed shall be called.\"", 
                "num": 7
              }, 
              {
                "text": "That is, those who are the children of the flesh, these are not the children of God; but the children of the promise are counted as the seed.", 
                "num": 8
              }, 
              {
                "text": "For this is the word of promise: \"At this time I will come and Sarah shall have a son.\"", 
                "num": 9
              }, 
              {
                "text": "And not only this, but when Rebecca also had conceived by one man, even by our father Isaac", 
                "num": 10
              }, 
              {
                "text": "(for the children not yet being born, nor having done any good or evil, that the purpose of God according to election might stand, not of works but of Him who calls),", 
                "num": 11
              }, 
              {
                "text": "it was said to her, \"The older shall serve the younger.\"", 
                "num": 12
              }, 
              {
                "text": "As it is written, \"Jacob I have loved, but Esau I have hated.\"", 
                "num": 13
              }, 
              {
                "text": "What shall we say then? Is there unrighteousness with God? Certainly not!", 
                "num": 14
              }, 
              {
                "text": "For He says to Moses, \"I will have mercy on whomever I will have mercy, and I will have compassion on whomever I will have compassion.\"", 
                "num": 15
              }, 
              {
                "text": "So then it is not of him who wills, nor of him who runs, but of God who shows mercy.", 
                "num": 16
              }, 
              {
                "text": "For the Scripture says to the Pharaoh, \"For this very purpose I have raised you up, that I may show My power in you, and that My name may be declared in all the earth.\"", 
                "num": 17
              }, 
              {
                "text": "Therefore He has mercy on whom He wills, and whom He wills He hardens.", 
                "num": 18
              }, 
              {
                "text": "You will say to me then, \"Why does He still find fault? For who has resisted His will?\"", 
                "num": 19
              }, 
              {
                "text": "But indeed, O man, who are you to reply against God? Will the thing formed say to him who formed it, \"Why have you made me like this?\"", 
                "num": 20
              }, 
              {
                "text": "Does not the potter have power over the clay, from the same lump to make one vessel for honor and another for dishonor?", 
                "num": 21
              }, 
              {
                "text": "What if God, wanting to show His wrath and to make His power known, endured with much longsuffering the vessels of wrath prepared for destruction,", 
                "num": 22
              }, 
              {
                "text": "and that He might make known the riches of His glory on the vessels of mercy, which He had prepared beforehand for glory,", 
                "num": 23
              }, 
              {
                "text": "even us whom He called, not of the Jews only, but also of the Gentiles?", 
                "num": 24
              }, 
              {
                "text": "As He says also in Hosea: \"I will call them My people, who were not My people, And her beloved, who was not beloved.\"", 
                "num": 25
              }, 
              {
                "text": "\"And it shall come to pass in the place where it was said to them, \"You are not My people,' There they shall be called sons of the living God.\"", 
                "num": 26
              }, 
              {
                "text": "Isaiah also cries out concerning Israel: \"Though the number of the children of Israel be as the sand of the sea, The remnant will be saved.", 
                "num": 27
              }, 
              {
                "text": "For He will finish the work and cut it short in righteousness, Because the LORD will make a short work upon the earth.\"", 
                "num": 28
              }, 
              {
                "text": "And as Isaiah said before: \"Unless the LORD of Sabaoth had left us a seed, We would have become like Sodom, And we would have been made like Gomorrah.\"", 
                "num": 29
              }, 
              {
                "text": "What shall we say then? That Gentiles, who did not pursue righteousness, have attained to righteousness, even the righteousness of faith;", 
                "num": 30
              }, 
              {
                "text": "but Israel, pursuing the law of righteousness, has not attained to the law of righteousness.", 
                "num": 31
              }, 
              {
                "text": "Why? Because they did not seek it by faith, but as it were, by the works of the law. For they stumbled at that stumbling stone.", 
                "num": 32
              }, 
              {
                "text": "As it is written: \"Behold, I lay in Zion a stumbling stone and rock of offense, And whoever believes on Him will not be put to shame.\"", 
                "num": 33
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Brethren, my heart's desire and prayer to God for Israel is that they may be saved.", 
                "num": 1
              }, 
              {
                "text": "For I bear them witness that they have a zeal for God, but not according to knowledge.", 
                "num": 2
              }, 
              {
                "text": "For they being ignorant of God's righteousness, and seeking to establish their own righteousness, have not submitted to the righteousness of God.", 
                "num": 3
              }, 
              {
                "text": "For Christ is the end of the law for righteousness to everyone who believes.", 
                "num": 4
              }, 
              {
                "text": "For Moses writes about the righteousness which is of the law, \"The man who does those things shall live by them.\"", 
                "num": 5
              }, 
              {
                "text": "But the righteousness of faith speaks in this way, \"Do not say in your heart, \"Who will ascend into heaven?\"' (that is, to bring Christ down from above)", 
                "num": 6
              }, 
              {
                "text": "or, \"\"Who will descend into the abyss?\"' (that is, to bring Christ up from the dead).", 
                "num": 7
              }, 
              {
                "text": "But what does it say? \"The word is near you, in your mouth and in your heart\" (that is, the word of faith which we preach):", 
                "num": 8
              }, 
              {
                "text": "that if you confess with your mouth the Lord Jesus and believe in your heart that God has raised Him from the dead, you will be saved.", 
                "num": 9
              }, 
              {
                "text": "For with the heart one believes unto righteousness, and with the mouth confession is made unto salvation.", 
                "num": 10
              }, 
              {
                "text": "For the Scripture says, \"Whoever believes on Him will not be put to shame.\"", 
                "num": 11
              }, 
              {
                "text": "For there is no distinction between Jew and Greek, for the same Lord over all is rich to all who call upon Him.", 
                "num": 12
              }, 
              {
                "text": "For \"whoever calls on the name of the LORD shall be saved.\"", 
                "num": 13
              }, 
              {
                "text": "How then shall they call on Him in whom they have not believed? And how shall they believe in Him of whom they have not heard? And how shall they hear without a preacher?", 
                "num": 14
              }, 
              {
                "text": "And how shall they preach unless they are sent? As it is written: \"How beautiful are the feet of those who preach the gospel of peace, Who bring glad tidings of good things!\"", 
                "num": 15
              }, 
              {
                "text": "But they have not all obeyed the gospel. For Isaiah says, \"LORD, who has believed our report?\"", 
                "num": 16
              }, 
              {
                "text": "So then faith comes by hearing, and hearing by the word of God.", 
                "num": 17
              }, 
              {
                "text": "But I say, have they not heard? Yes indeed: \"Their sound has gone out to all the earth, And their words to the ends of the world.\"", 
                "num": 18
              }, 
              {
                "text": "But I say, did Israel not know? First Moses says: \"I will provoke you to jealousy by those who are not a nation, I will move you to anger by a foolish nation.\"", 
                "num": 19
              }, 
              {
                "text": "But Isaiah is very bold and says: \"I was found by those who did not seek Me; I was made manifest to those who did not ask for Me.\"", 
                "num": 20
              }, 
              {
                "text": "But to Israel he says: \"All day long I have stretched out My hands To a disobedient and contrary people.\"", 
                "num": 21
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "I say then, has God cast away His people? Certainly not! For I also am an Israelite, of the seed of Abraham, of the tribe of Benjamin.", 
                "num": 1
              }, 
              {
                "text": "God has not cast away His people whom He foreknew. Or do you not know what the Scripture says of Elijah, how he pleads with God against Israel, saying,", 
                "num": 2
              }, 
              {
                "text": "\"LORD, they have killed Your prophets and torn down Your altars, and I alone am left, and they seek my life\"?", 
                "num": 3
              }, 
              {
                "text": "But what does the divine response say to him? \"I have reserved for Myself seven thousand men who have not bowed the knee to Baal.\"", 
                "num": 4
              }, 
              {
                "text": "Even so then, at this present time there is a remnant according to the election of grace.", 
                "num": 5
              }, 
              {
                "text": "And if by grace, then it is no longer of works; otherwise grace is no longer grace. But if it is of works, it is no longer grace; otherwise work is no longer work.", 
                "num": 6
              }, 
              {
                "text": "What then? Israel has not obtained what it seeks; but the elect have obtained it, and the rest were blinded.", 
                "num": 7
              }, 
              {
                "text": "Just as it is written: \"God has given them a spirit of stupor, Eyes that they should not see And ears that they should not hear, To this very day.\"", 
                "num": 8
              }, 
              {
                "text": "And David says: \"Let their table become a snare and a trap, A stumbling block and a recompense to them.", 
                "num": 9
              }, 
              {
                "text": "Let their eyes be darkened, so that they do not see, And bow down their back always.\"", 
                "num": 10
              }, 
              {
                "text": "I say then, have they stumbled that they should fall? Certainly not! But through their fall, to provoke them to jealousy, salvation has come to the Gentiles.", 
                "num": 11
              }, 
              {
                "text": "Now if their fall is riches for the world, and their failure riches for the Gentiles, how much more their fullness!", 
                "num": 12
              }, 
              {
                "text": "For I speak to you Gentiles; inasmuch as I am an apostle to the Gentiles, I magnify my ministry,", 
                "num": 13
              }, 
              {
                "text": "if by any means I may provoke to jealousy those who are my flesh and save some of them.", 
                "num": 14
              }, 
              {
                "text": "For if their being cast away is the reconciling of the world, what will their acceptance be but life from the dead?", 
                "num": 15
              }, 
              {
                "text": "For if the firstfruit is holy, the lump is also holy; and if the root is holy, so are the branches.", 
                "num": 16
              }, 
              {
                "text": "And if some of the branches were broken off, and you, being a wild olive tree, were grafted in among them, and with them became a partaker of the root and fatness of the olive tree,", 
                "num": 17
              }, 
              {
                "text": "do not boast against the branches. But if you do boast, remember that you do not support the root, but the root supports you.", 
                "num": 18
              }, 
              {
                "text": "You will say then, \"Branches were broken off that I might be grafted in.\"", 
                "num": 19
              }, 
              {
                "text": "Well said. Because of unbelief they were broken off, and you stand by faith. Do not be haughty, but fear.", 
                "num": 20
              }, 
              {
                "text": "For if God did not spare the natural branches, He may not spare you either.", 
                "num": 21
              }, 
              {
                "text": "Therefore consider the goodness and severity of God: on those who fell, severity; but toward you, goodness, if you continue in His goodness. Otherwise you also will be cut off.", 
                "num": 22
              }, 
              {
                "text": "And they also, if they do not continue in unbelief, will be grafted in, for God is able to graft them in again.", 
                "num": 23
              }, 
              {
                "text": "For if you were cut out of the olive tree which is wild by nature, and were grafted contrary to nature into a cultivated olive tree, how much more will these, who are natural branches, be grafted into their own olive tree?", 
                "num": 24
              }, 
              {
                "text": "For I do not desire, brethren, that you should be ignorant of this mystery, lest you should be wise in your own opinion, that blindness in part has happened to Israel until the fullness of the Gentiles has come in.", 
                "num": 25
              }, 
              {
                "text": "And so all Israel will be saved, as it is written: \"The Deliverer will come out of Zion, And He will turn away ungodliness from Jacob;", 
                "num": 26
              }, 
              {
                "text": "For this is My covenant with them, When I take away their sins.\"", 
                "num": 27
              }, 
              {
                "text": "Concerning the gospel they are enemies for your sake, but concerning the election they are beloved for the sake of the fathers.", 
                "num": 28
              }, 
              {
                "text": "For the gifts and the calling of God are irrevocable.", 
                "num": 29
              }, 
              {
                "text": "For as you were once disobedient to God, yet have now obtained mercy through their disobedience,", 
                "num": 30
              }, 
              {
                "text": "even so these also have now been disobedient, that through the mercy shown you they also may obtain mercy.", 
                "num": 31
              }, 
              {
                "text": "For God has committed them all to disobedience, that He might have mercy on all.", 
                "num": 32
              }, 
              {
                "text": "Oh, the depth of the riches both of the wisdom and knowledge of God! How unsearchable are His judgments and His ways past finding out!", 
                "num": 33
              }, 
              {
                "text": "\"For who has known the mind of the LORD? Or who has become His counselor?\"", 
                "num": 34
              }, 
              {
                "text": "\"Or who has first given to Him And it shall be repaid to him?\"", 
                "num": 35
              }, 
              {
                "text": "For of Him and through Him and to Him are all things, to whom be glory forever. Amen.", 
                "num": 36
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "I beseech you therefore, brethren, by the mercies of God, that you present your bodies a living sacrifice, holy, acceptable to God, which is your reasonable service.", 
                "num": 1
              }, 
              {
                "text": "And do not be conformed to this world, but be transformed by the renewing of your mind, that you may prove what is that good and acceptable and perfect will of God.", 
                "num": 2
              }, 
              {
                "text": "For I say, through the grace given to me, to everyone who is among you, not to think of himself more highly than he ought to think, but to think soberly, as God has dealt to each one a measure of faith.", 
                "num": 3
              }, 
              {
                "text": "For as we have many members in one body, but all the members do not have the same function,", 
                "num": 4
              }, 
              {
                "text": "so we, being many, are one body in Christ, and individually members of one another.", 
                "num": 5
              }, 
              {
                "text": "Having then gifts differing according to the grace that is given to us, let us use them: if prophecy, let us prophesy in proportion to our faith;", 
                "num": 6
              }, 
              {
                "text": "or ministry, let us use it in our ministering; he who teaches, in teaching;", 
                "num": 7
              }, 
              {
                "text": "he who exhorts, in exhortation; he who gives, with liberality; he who leads, with diligence; he who shows mercy, with cheerfulness.", 
                "num": 8
              }, 
              {
                "text": "Let love be without hypocrisy. Abhor what is evil. Cling to what is good.", 
                "num": 9
              }, 
              {
                "text": "Be kindly affectionate to one another with brotherly love, in honor giving preference to one another;", 
                "num": 10
              }, 
              {
                "text": "not lagging in diligence, fervent in spirit, serving the Lord;", 
                "num": 11
              }, 
              {
                "text": "rejoicing in hope, patient in tribulation, continuing steadfastly in prayer;", 
                "num": 12
              }, 
              {
                "text": "distributing to the needs of the saints, given to hospitality.", 
                "num": 13
              }, 
              {
                "text": "Bless those who persecute you; bless and do not curse.", 
                "num": 14
              }, 
              {
                "text": "Rejoice with those who rejoice, and weep with those who weep.", 
                "num": 15
              }, 
              {
                "text": "Be of the same mind toward one another. Do not set your mind on high things, but associate with the humble. Do not be wise in your own opinion.", 
                "num": 16
              }, 
              {
                "text": "Repay no one evil for evil. Have regard for good things in the sight of all men.", 
                "num": 17
              }, 
              {
                "text": "If it is possible, as much as depends on you, live peaceably with all men.", 
                "num": 18
              }, 
              {
                "text": "Beloved, do not avenge yourselves, but rather give place to wrath; for it is written, \"Vengeance is Mine, I will repay,\" says the Lord.", 
                "num": 19
              }, 
              {
                "text": "Therefore \"If your enemy is hungry, feed him; If he is thirsty, give him a drink; For in so doing you will heap coals of fire on his head.\"", 
                "num": 20
              }, 
              {
                "text": "Do not be overcome by evil, but overcome evil with good.", 
                "num": 21
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Let every soul be subject to the governing authorities. For there is no authority except from God, and the authorities that exist are appointed by God.", 
                "num": 1
              }, 
              {
                "text": "Therefore whoever resists the authority resists the ordinance of God, and those who resist will bring judgment on themselves.", 
                "num": 2
              }, 
              {
                "text": "For rulers are not a terror to good works, but to evil. Do you want to be unafraid of the authority? Do what is good, and you will have praise from the same.", 
                "num": 3
              }, 
              {
                "text": "For he is God's minister to you for good. But if you do evil, be afraid; for he does not bear the sword in vain; for he is God's minister, an avenger to execute wrath on him who practices evil.", 
                "num": 4
              }, 
              {
                "text": "Therefore you must be subject, not only because of wrath but also for conscience' sake.", 
                "num": 5
              }, 
              {
                "text": "For because of this you also pay taxes, for they are God's ministers attending continually to this very thing.", 
                "num": 6
              }, 
              {
                "text": "Render therefore to all their due: taxes to whom taxes are due, customs to whom customs, fear to whom fear, honor to whom honor.", 
                "num": 7
              }, 
              {
                "text": "Owe no one anything except to love one another, for he who loves another has fulfilled the law.", 
                "num": 8
              }, 
              {
                "text": "For the commandments, \"You shall not commit adultery,\" \"You shall not murder,\" \"You shall not steal,\" \"You shall not bear false witness,\" \"You shall not covet,\" and if there is any other commandment, are all summed up in this saying, namely, \"You shall love your neighbor as yourself.\"", 
                "num": 9
              }, 
              {
                "text": "Love does no harm to a neighbor; therefore love is the fulfillment of the law.", 
                "num": 10
              }, 
              {
                "text": "And do this, knowing the time, that now it is high time to awake out of sleep; for now our salvation is nearer than when we first believed.", 
                "num": 11
              }, 
              {
                "text": "The night is far spent, the day is at hand. Therefore let us cast off the works of darkness, and let us put on the armor of light.", 
                "num": 12
              }, 
              {
                "text": "Let us walk properly, as in the day, not in revelry and drunkenness, not in lewdness and lust, not in strife and envy.", 
                "num": 13
              }, 
              {
                "text": "But put on the Lord Jesus Christ, and make no provision for the flesh, to fulfill its lusts.", 
                "num": 14
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Receive one who is weak in the faith, but not to disputes over doubtful things.", 
                "num": 1
              }, 
              {
                "text": "For one believes he may eat all things, but he who is weak eats only vegetables.", 
                "num": 2
              }, 
              {
                "text": "Let not him who eats despise him who does not eat, and let not him who does not eat judge him who eats; for God has received him.", 
                "num": 3
              }, 
              {
                "text": "Who are you to judge another's servant? To his own master he stands or falls. Indeed, he will be made to stand, for God is able to make him stand.", 
                "num": 4
              }, 
              {
                "text": "One person esteems one day above another; another esteems every day alike. Let each be fully convinced in his own mind.", 
                "num": 5
              }, 
              {
                "text": "He who observes the day, observes it to the Lord; and he who does not observe the day, to the Lord he does not observe it. He who eats, eats to the Lord, for he gives God thanks; and he who does not eat, to the Lord he does not eat, and gives God thanks.", 
                "num": 6
              }, 
              {
                "text": "For none of us lives to himself, and no one dies to himself.", 
                "num": 7
              }, 
              {
                "text": "For if we live, we live to the Lord; and if we die, we die to the Lord. Therefore, whether we live or die, we are the Lord's.", 
                "num": 8
              }, 
              {
                "text": "For to this end Christ died and rose and lived again, that He might be Lord of both the dead and the living.", 
                "num": 9
              }, 
              {
                "text": "But why do you judge your brother? Or why do you show contempt for your brother? For we shall all stand before the judgment seat of Christ.", 
                "num": 10
              }, 
              {
                "text": "For it is written: \"As I live, says the LORD, Every knee shall bow to Me, And every tongue shall confess to God.\"", 
                "num": 11
              }, 
              {
                "text": "So then each of us shall give account of himself to God.", 
                "num": 12
              }, 
              {
                "text": "Therefore let us not judge one another anymore, but rather resolve this, not to put a stumbling block or a cause to fall in our brother's way.", 
                "num": 13
              }, 
              {
                "text": "I know and am convinced by the Lord Jesus that there is nothing unclean of itself; but to him who considers anything to be unclean, to him it is unclean.", 
                "num": 14
              }, 
              {
                "text": "Yet if your brother is grieved because of your food, you are no longer walking in love. Do not destroy with your food the one for whom Christ died.", 
                "num": 15
              }, 
              {
                "text": "Therefore do not let your good be spoken of as evil;", 
                "num": 16
              }, 
              {
                "text": "for the kingdom of God is not eating and drinking, but righteousness and peace and joy in the Holy Spirit.", 
                "num": 17
              }, 
              {
                "text": "For he who serves Christ in these things is acceptable to God and approved by men.", 
                "num": 18
              }, 
              {
                "text": "Therefore let us pursue the things which make for peace and the things by which one may edify another.", 
                "num": 19
              }, 
              {
                "text": "Do not destroy the work of God for the sake of food. All things indeed are pure, but it is evil for the man who eats with offense.", 
                "num": 20
              }, 
              {
                "text": "It is good neither to eat meat nor drink wine nor do anything by which your brother stumbles or is offended or is made weak.", 
                "num": 21
              }, 
              {
                "text": "Do you have faith? Have it to yourself before God. Happy is he who does not condemn himself in what he approves.", 
                "num": 22
              }, 
              {
                "text": "But he who doubts is condemned if he eats, because he does not eat from faith; for whatever is not from faith is sin.", 
                "num": 23
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "We then who are strong ought to bear with the scruples of the weak, and not to please ourselves.", 
                "num": 1
              }, 
              {
                "text": "Let each of us please his neighbor for his good, leading to edification.", 
                "num": 2
              }, 
              {
                "text": "For even Christ did not please Himself; but as it is written, \"The reproaches of those who reproached You fell on Me.\"", 
                "num": 3
              }, 
              {
                "text": "For whatever things were written before were written for our learning, that we through the patience and comfort of the Scriptures might have hope.", 
                "num": 4
              }, 
              {
                "text": "Now may the God of patience and comfort grant you to be like-minded toward one another, according to Christ Jesus,", 
                "num": 5
              }, 
              {
                "text": "that you may with one mind and one mouth glorify the God and Father of our Lord Jesus Christ.", 
                "num": 6
              }, 
              {
                "text": "Therefore receive one another, just as Christ also received us, to the glory of God.", 
                "num": 7
              }, 
              {
                "text": "Now I say that Jesus Christ has become a servant to the circumcision for the truth of God, to confirm the promises made to the fathers,", 
                "num": 8
              }, 
              {
                "text": "and that the Gentiles might glorify God for His mercy, as it is written: \"For this reason I will confess to You among the Gentiles, And sing to Your name.\"", 
                "num": 9
              }, 
              {
                "text": "And again he says: \"Rejoice, O Gentiles, with His people!\"", 
                "num": 10
              }, 
              {
                "text": "And again: \"Praise the LORD, all you Gentiles! Laud Him, all you peoples!\"", 
                "num": 11
              }, 
              {
                "text": "And again, Isaiah says: \"There shall be a root of Jesse; And He who shall rise to reign over the Gentiles, In Him the Gentiles shall hope.\"", 
                "num": 12
              }, 
              {
                "text": "Now may the God of hope fill you with all joy and peace in believing, that you may abound in hope by the power of the Holy Spirit.", 
                "num": 13
              }, 
              {
                "text": "Now I myself am confident concerning you, my brethren, that you also are full of goodness, filled with all knowledge, able also to admonish one another.", 
                "num": 14
              }, 
              {
                "text": "Nevertheless, brethren, I have written more boldly to you on some points, as reminding you, because of the grace given to me by God,", 
                "num": 15
              }, 
              {
                "text": "that I might be a minister of Jesus Christ to the Gentiles, ministering the gospel of God, that the offering of the Gentiles might be acceptable, sanctified by the Holy Spirit.", 
                "num": 16
              }, 
              {
                "text": "Therefore I have reason to glory in Christ Jesus in the things which pertain to God.", 
                "num": 17
              }, 
              {
                "text": "For I will not dare to speak of any of those things which Christ has not accomplished through me, in word and deed, to make the Gentiles obedient--", 
                "num": 18
              }, 
              {
                "text": "in mighty signs and wonders, by the power of the Spirit of God, so that from Jerusalem and round about to Illyricum I have fully preached the gospel of Christ.", 
                "num": 19
              }, 
              {
                "text": "And so I have made it my aim to preach the gospel, not where Christ was named, lest I should build on another man's foundation,", 
                "num": 20
              }, 
              {
                "text": "but as it is written: \"To whom He was not announced, they shall see; And those who have not heard shall understand.\"", 
                "num": 21
              }, 
              {
                "text": "For this reason I also have been much hindered from coming to you.", 
                "num": 22
              }, 
              {
                "text": "But now no longer having a place in these parts, and having a great desire these many years to come to you,", 
                "num": 23
              }, 
              {
                "text": "whenever I journey to Spain, I shall come to you. For I hope to see you on my journey, and to be helped on my way there by you, if first I may enjoy your company for a while.", 
                "num": 24
              }, 
              {
                "text": "But now I am going to Jerusalem to minister to the saints.", 
                "num": 25
              }, 
              {
                "text": "For it pleased those from Macedonia and Achaia to make a certain contribution for the poor among the saints who are in Jerusalem.", 
                "num": 26
              }, 
              {
                "text": "It pleased them indeed, and they are their debtors. For if the Gentiles have been partakers of their spiritual things, their duty is also to minister to them in material things.", 
                "num": 27
              }, 
              {
                "text": "Therefore, when I have performed this and have sealed to them this fruit, I shall go by way of you to Spain.", 
                "num": 28
              }, 
              {
                "text": "But I know that when I come to you, I shall come in the fullness of the blessing of the gospel of Christ.", 
                "num": 29
              }, 
              {
                "text": "Now I beg you, brethren, through the Lord Jesus Christ, and through the love of the Spirit, that you strive together with me in prayers to God for me,", 
                "num": 30
              }, 
              {
                "text": "that I may be delivered from those in Judea who do not believe, and that my service for Jerusalem may be acceptable to the saints,", 
                "num": 31
              }, 
              {
                "text": "that I may come to you with joy by the will of God, and may be refreshed together with you.", 
                "num": 32
              }, 
              {
                "text": "Now the God of peace be with you all. Amen.", 
                "num": 33
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "I commend to you Phoebe our sister, who is a servant of the church in Cenchrea,", 
                "num": 1
              }, 
              {
                "text": "that you may receive her in the Lord in a manner worthy of the saints, and assist her in whatever business she has need of you; for indeed she has been a helper of many and of myself also.", 
                "num": 2
              }, 
              {
                "text": "Greet Priscilla and Aquila, my fellow workers in Christ Jesus,", 
                "num": 3
              }, 
              {
                "text": "who risked their own necks for my life, to whom not only I give thanks, but also all the churches of the Gentiles.", 
                "num": 4
              }, 
              {
                "text": "Likewise greet the church that is in their house. Greet my beloved Epaenetus, who is the firstfruits of Achaia to Christ.", 
                "num": 5
              }, 
              {
                "text": "Greet Mary, who labored much for us.", 
                "num": 6
              }, 
              {
                "text": "Greet Andronicus and Junia, my countrymen and my fellow prisoners, who are of note among the apostles, who also were in Christ before me.", 
                "num": 7
              }, 
              {
                "text": "Greet Amplias, my beloved in the Lord.", 
                "num": 8
              }, 
              {
                "text": "Greet Urbanus, our fellow worker in Christ, and Stachys, my beloved.", 
                "num": 9
              }, 
              {
                "text": "Greet Apelles, approved in Christ. Greet those who are of the household of Aristobulus.", 
                "num": 10
              }, 
              {
                "text": "Greet Herodion, my countryman. Greet those who are of the household of Narcissus who are in the Lord.", 
                "num": 11
              }, 
              {
                "text": "Greet Tryphena and Tryphosa, who have labored in the Lord. Greet the beloved Persis, who labored much in the Lord.", 
                "num": 12
              }, 
              {
                "text": "Greet Rufus, chosen in the Lord, and his mother and mine.", 
                "num": 13
              }, 
              {
                "text": "Greet Asyncritus, Phlegon, Hermas, Patrobas, Hermes, and the brethren who are with them.", 
                "num": 14
              }, 
              {
                "text": "Greet Philologus and Julia, Nereus and his sister, and Olympas, and all the saints who are with them.", 
                "num": 15
              }, 
              {
                "text": "Greet one another with a holy kiss. The churches of Christ greet you.", 
                "num": 16
              }, 
              {
                "text": "Now I urge you, brethren, note those who cause divisions and offenses, contrary to the doctrine which you learned, and avoid them.", 
                "num": 17
              }, 
              {
                "text": "For those who are such do not serve our Lord Jesus Christ, but their own belly, and by smooth words and flattering speech deceive the hearts of the simple.", 
                "num": 18
              }, 
              {
                "text": "For your obedience has become known to all. Therefore I am glad on your behalf; but I want you to be wise in what is good, and simple concerning evil.", 
                "num": 19
              }, 
              {
                "text": "And the God of peace will crush Satan under your feet shortly. The grace of our Lord Jesus Christ be with you. Amen.", 
                "num": 20
              }, 
              {
                "text": "Timothy, my fellow worker, and Lucius, Jason, and Sosipater, my countrymen, greet you.", 
                "num": 21
              }, 
              {
                "text": "I, Tertius, who wrote this epistle, greet you in the Lord.", 
                "num": 22
              }, 
              {
                "text": "Gaius, my host and the host of the whole church, greets you. Erastus, the treasurer of the city, greets you, and Quartus, a brother.", 
                "num": 23
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you all. Amen.", 
                "num": 24
              }, 
              {
                "text": "Now to Him who is able to establish you according to my gospel and the preaching of Jesus Christ, according to the revelation of the mystery kept secret since the world began", 
                "num": 25
              }, 
              {
                "text": "but now made manifest, and by the prophetic Scriptures made known to all nations, according to the commandment of the everlasting God, for obedience to the faith--", 
                "num": 26
              }, 
              {
                "text": "to God, alone wise, be glory through Jesus Christ forever. Amen.", 
                "num": 27
              }
            ], 
            "num": 16
          }
        ]
      }, 
      {
        "name": "1 Corinthians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, called to be an apostle of Jesus Christ through the will of God, and Sosthenes our brother,", 
                "num": 1
              }, 
              {
                "text": "To the church of God which is at Corinth, to those who are sanctified in Christ Jesus, called to be saints, with all who in every place call on the name of Jesus Christ our Lord, both theirs and ours:", 
                "num": 2
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 3
              }, 
              {
                "text": "I thank my God always concerning you for the grace of God which was given to you by Christ Jesus,", 
                "num": 4
              }, 
              {
                "text": "that you were enriched in everything by Him in all utterance and all knowledge,", 
                "num": 5
              }, 
              {
                "text": "even as the testimony of Christ was confirmed in you,", 
                "num": 6
              }, 
              {
                "text": "so that you come short in no gift, eagerly waiting for the revelation of our Lord Jesus Christ,", 
                "num": 7
              }, 
              {
                "text": "who will also confirm you to the end, that you may be blameless in the day of our Lord Jesus Christ.", 
                "num": 8
              }, 
              {
                "text": "God is faithful, by whom you were called into the fellowship of His Son, Jesus Christ our Lord.", 
                "num": 9
              }, 
              {
                "text": "Now I plead with you, brethren, by the name of our Lord Jesus Christ, that you all speak the same thing, and that there be no divisions among you, but that you be perfectly joined together in the same mind and in the same judgment.", 
                "num": 10
              }, 
              {
                "text": "For it has been declared to me concerning you, my brethren, by those of Chloe's household, that there are contentions among you.", 
                "num": 11
              }, 
              {
                "text": "Now I say this, that each of you says, \"I am of Paul,\" or \"I am of Apollos,\" or \"I am of Cephas,\" or \"I am of Christ.\"", 
                "num": 12
              }, 
              {
                "text": "Is Christ divided? Was Paul crucified for you? Or were you baptized in the name of Paul?", 
                "num": 13
              }, 
              {
                "text": "I thank God that I baptized none of you except Crispus and Gaius,", 
                "num": 14
              }, 
              {
                "text": "lest anyone should say that I had baptized in my own name.", 
                "num": 15
              }, 
              {
                "text": "Yes, I also baptized the household of Stephanas. Besides, I do not know whether I baptized any other.", 
                "num": 16
              }, 
              {
                "text": "For Christ did not send me to baptize, but to preach the gospel, not with wisdom of words, lest the cross of Christ should be made of no effect.", 
                "num": 17
              }, 
              {
                "text": "For the message of the cross is foolishness to those who are perishing, but to us who are being saved it is the power of God.", 
                "num": 18
              }, 
              {
                "text": "For it is written: \"I will destroy the wisdom of the wise, And bring to nothing the understanding of the prudent.\"", 
                "num": 19
              }, 
              {
                "text": "Where is the wise? Where is the scribe? Where is the disputer of this age? Has not God made foolish the wisdom of this world?", 
                "num": 20
              }, 
              {
                "text": "For since, in the wisdom of God, the world through wisdom did not know God, it pleased God through the foolishness of the message preached to save those who believe.", 
                "num": 21
              }, 
              {
                "text": "For Jews request a sign, and Greeks seek after wisdom;", 
                "num": 22
              }, 
              {
                "text": "but we preach Christ crucified, to the Jews a stumbling block and to the Greeks foolishness,", 
                "num": 23
              }, 
              {
                "text": "but to those who are called, both Jews and Greeks, Christ the power of God and the wisdom of God.", 
                "num": 24
              }, 
              {
                "text": "Because the foolishness of God is wiser than men, and the weakness of God is stronger than men.", 
                "num": 25
              }, 
              {
                "text": "For you see your calling, brethren, that not many wise according to the flesh, not many mighty, not many noble, are called.", 
                "num": 26
              }, 
              {
                "text": "But God has chosen the foolish things of the world to put to shame the wise, and God has chosen the weak things of the world to put to shame the things which are mighty;", 
                "num": 27
              }, 
              {
                "text": "and the base things of the world and the things which are despised God has chosen, and the things which are not, to bring to nothing the things that are,", 
                "num": 28
              }, 
              {
                "text": "that no flesh should glory in His presence.", 
                "num": 29
              }, 
              {
                "text": "But of Him you are in Christ Jesus, who became for us wisdom from God--and righteousness and sanctification and redemption--", 
                "num": 30
              }, 
              {
                "text": "that, as it is written, \"He who glories, let him glory in the LORD.\"", 
                "num": 31
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And I, brethren, when I came to you, did not come with excellence of speech or of wisdom declaring to you the testimony of God.", 
                "num": 1
              }, 
              {
                "text": "For I determined not to know anything among you except Jesus Christ and Him crucified.", 
                "num": 2
              }, 
              {
                "text": "I was with you in weakness, in fear, and in much trembling.", 
                "num": 3
              }, 
              {
                "text": "And my speech and my preaching were not with persuasive words of human wisdom, but in demonstration of the Spirit and of power,", 
                "num": 4
              }, 
              {
                "text": "that your faith should not be in the wisdom of men but in the power of God.", 
                "num": 5
              }, 
              {
                "text": "However, we speak wisdom among those who are mature, yet not the wisdom of this age, nor of the rulers of this age, who are coming to nothing.", 
                "num": 6
              }, 
              {
                "text": "But we speak the wisdom of God in a mystery, the hidden wisdom which God ordained before the ages for our glory,", 
                "num": 7
              }, 
              {
                "text": "which none of the rulers of this age knew; for had they known, they would not have crucified the Lord of glory.", 
                "num": 8
              }, 
              {
                "text": "But as it is written: \"Eye has not seen, nor ear heard, Nor have entered into the heart of man The things which God has prepared for those who love Him.\"", 
                "num": 9
              }, 
              {
                "text": "But God has revealed them to us through His Spirit. For the Spirit searches all things, yes, the deep things of God.", 
                "num": 10
              }, 
              {
                "text": "For what man knows the things of a man except the spirit of the man which is in him? Even so no one knows the things of God except the Spirit of God.", 
                "num": 11
              }, 
              {
                "text": "Now we have received, not the spirit of the world, but the Spirit who is from God, that we might know the things that have been freely given to us by God.", 
                "num": 12
              }, 
              {
                "text": "These things we also speak, not in words which man's wisdom teaches but which the Holy Spirit teaches, comparing spiritual things with spiritual.", 
                "num": 13
              }, 
              {
                "text": "But the natural man does not receive the things of the Spirit of God, for they are foolishness to him; nor can he know them, because they are spiritually discerned.", 
                "num": 14
              }, 
              {
                "text": "But he who is spiritual judges all things, yet he himself is rightly judged by no one.", 
                "num": 15
              }, 
              {
                "text": "For \"who has known the mind of the LORD that he may instruct Him?\" But we have the mind of Christ.", 
                "num": 16
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "And I, brethren, could not speak to you as to spiritual people but as to carnal, as to babes in Christ.", 
                "num": 1
              }, 
              {
                "text": "I fed you with milk and not with solid food; for until now you were not able to receive it, and even now you are still not able;", 
                "num": 2
              }, 
              {
                "text": "for you are still carnal. For where there are envy, strife, and divisions among you, are you not carnal and behaving like mere men?", 
                "num": 3
              }, 
              {
                "text": "For when one says, \"I am of Paul,\" and another, \"I am of Apollos,\" are you not carnal?", 
                "num": 4
              }, 
              {
                "text": "Who then is Paul, and who is Apollos, but ministers through whom you believed, as the Lord gave to each one?", 
                "num": 5
              }, 
              {
                "text": "I planted, Apollos watered, but God gave the increase.", 
                "num": 6
              }, 
              {
                "text": "So then neither he who plants is anything, nor he who waters, but God who gives the increase.", 
                "num": 7
              }, 
              {
                "text": "Now he who plants and he who waters are one, and each one will receive his own reward according to his own labor.", 
                "num": 8
              }, 
              {
                "text": "For we are God's fellow workers; you are God's field, you are God's building.", 
                "num": 9
              }, 
              {
                "text": "According to the grace of God which was given to me, as a wise master builder I have laid the foundation, and another builds on it. But let each one take heed how he builds on it.", 
                "num": 10
              }, 
              {
                "text": "For no other foundation can anyone lay than that which is laid, which is Jesus Christ.", 
                "num": 11
              }, 
              {
                "text": "Now if anyone builds on this foundation with gold, silver, precious stones, wood, hay, straw,", 
                "num": 12
              }, 
              {
                "text": "each one's work will become clear; for the Day will declare it, because it will be revealed by fire; and the fire will test each one's work, of what sort it is.", 
                "num": 13
              }, 
              {
                "text": "If anyone's work which he has built on it endures, he will receive a reward.", 
                "num": 14
              }, 
              {
                "text": "If anyone's work is burned, he will suffer loss; but he himself will be saved, yet so as through fire.", 
                "num": 15
              }, 
              {
                "text": "Do you not know that you are the temple of God and that the Spirit of God dwells in you?", 
                "num": 16
              }, 
              {
                "text": "If anyone defiles the temple of God, God will destroy him. For the temple of God is holy, which temple you are.", 
                "num": 17
              }, 
              {
                "text": "Let no one deceive himself. If anyone among you seems to be wise in this age, let him become a fool that he may become wise.", 
                "num": 18
              }, 
              {
                "text": "For the wisdom of this world is foolishness with God. For it is written, \"He catches the wise in their own craftiness\";", 
                "num": 19
              }, 
              {
                "text": "and again, \"The LORD knows the thoughts of the wise, that they are futile.\"", 
                "num": 20
              }, 
              {
                "text": "Therefore let no one boast in men. For all things are yours:", 
                "num": 21
              }, 
              {
                "text": "whether Paul or Apollos or Cephas, or the world or life or death, or things present or things to come--all are yours.", 
                "num": 22
              }, 
              {
                "text": "And you are Christ's, and Christ is God's.", 
                "num": 23
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Let a man so consider us, as servants of Christ and stewards of the mysteries of God.", 
                "num": 1
              }, 
              {
                "text": "Moreover it is required in stewards that one be found faithful.", 
                "num": 2
              }, 
              {
                "text": "But with me it is a very small thing that I should be judged by you or by a human court. In fact, I do not even judge myself.", 
                "num": 3
              }, 
              {
                "text": "For I know of nothing against myself, yet I am not justified by this; but He who judges me is the Lord.", 
                "num": 4
              }, 
              {
                "text": "Therefore judge nothing before the time, until the Lord comes, who will both bring to light the hidden things of darkness and reveal the counsels of the hearts. Then each one's praise will come from God.", 
                "num": 5
              }, 
              {
                "text": "Now these things, brethren, I have figuratively transferred to myself and Apollos for your sakes, that you may learn in us not to think beyond what is written, that none of you may be puffed up on behalf of one against the other.", 
                "num": 6
              }, 
              {
                "text": "For who makes you differ from another? And what do you have that you did not receive? Now if you did indeed receive it, why do you boast as if you had not received it?", 
                "num": 7
              }, 
              {
                "text": "You are already full! You are already rich! You have reigned as kings without us--and indeed I could wish you did reign, that we also might reign with you!", 
                "num": 8
              }, 
              {
                "text": "For I think that God has displayed us, the apostles, last, as men condemned to death; for we have been made a spectacle to the world, both to angels and to men.", 
                "num": 9
              }, 
              {
                "text": "We are fools for Christ's sake, but you are wise in Christ! We are weak, but you are strong! You are distinguished, but we are dishonored!", 
                "num": 10
              }, 
              {
                "text": "To the present hour we both hunger and thirst, and we are poorly clothed, and beaten, and homeless.", 
                "num": 11
              }, 
              {
                "text": "And we labor, working with our own hands. Being reviled, we bless; being persecuted, we endure;", 
                "num": 12
              }, 
              {
                "text": "being defamed, we entreat. We have been made as the filth of the world, the offscouring of all things until now.", 
                "num": 13
              }, 
              {
                "text": "I do not write these things to shame you, but as my beloved children I warn you.", 
                "num": 14
              }, 
              {
                "text": "For though you might have ten thousand instructors in Christ, yet you do not have many fathers; for in Christ Jesus I have begotten you through the gospel.", 
                "num": 15
              }, 
              {
                "text": "Therefore I urge you, imitate me.", 
                "num": 16
              }, 
              {
                "text": "For this reason I have sent Timothy to you, who is my beloved and faithful son in the Lord, who will remind you of my ways in Christ, as I teach everywhere in every church.", 
                "num": 17
              }, 
              {
                "text": "Now some are puffed up, as though I were not coming to you.", 
                "num": 18
              }, 
              {
                "text": "But I will come to you shortly, if the Lord wills, and I will know, not the word of those who are puffed up, but the power.", 
                "num": 19
              }, 
              {
                "text": "For the kingdom of God is not in word but in power.", 
                "num": 20
              }, 
              {
                "text": "What do you want? Shall I come to you with a rod, or in love and a spirit of gentleness?", 
                "num": 21
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "It is actually reported that there is sexual immorality among you, and such sexual immorality as is not even named among the Gentiles--that a man has his father's wife!", 
                "num": 1
              }, 
              {
                "text": "And you are puffed up, and have not rather mourned, that he who has done this deed might be taken away from among you.", 
                "num": 2
              }, 
              {
                "text": "For I indeed, as absent in body but present in spirit, have already judged (as though I were present) him who has so done this deed.", 
                "num": 3
              }, 
              {
                "text": "In the name of our Lord Jesus Christ, when you are gathered together, along with my spirit, with the power of our Lord Jesus Christ,", 
                "num": 4
              }, 
              {
                "text": "deliver such a one to Satan for the destruction of the flesh, that his spirit may be saved in the day of the Lord Jesus.", 
                "num": 5
              }, 
              {
                "text": "Your glorying is not good. Do you not know that a little leaven leavens the whole lump?", 
                "num": 6
              }, 
              {
                "text": "Therefore purge out the old leaven, that you may be a new lump, since you truly are unleavened. For indeed Christ, our Passover, was sacrificed for us.", 
                "num": 7
              }, 
              {
                "text": "Therefore let us keep the feast, not with old leaven, nor with the leaven of malice and wickedness, but with the unleavened bread of sincerity and truth.", 
                "num": 8
              }, 
              {
                "text": "I wrote to you in my epistle not to keep company with sexually immoral people.", 
                "num": 9
              }, 
              {
                "text": "Yet I certainly did not mean with the sexually immoral people of this world, or with the covetous, or extortioners, or idolaters, since then you would need to go out of the world.", 
                "num": 10
              }, 
              {
                "text": "But now I have written to you not to keep company with anyone named a brother, who is sexually immoral, or covetous, or an idolater, or a reviler, or a drunkard, or an extortioner--not even to eat with such a person.", 
                "num": 11
              }, 
              {
                "text": "For what have I to do with judging those also who are outside? Do you not judge those who are inside?", 
                "num": 12
              }, 
              {
                "text": "But those who are outside God judges. Therefore \"put away from yourselves the evil person.\"", 
                "num": 13
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Dare any of you, having a matter against another, go to law before the unrighteous, and not before the saints?", 
                "num": 1
              }, 
              {
                "text": "Do you not know that the saints will judge the world? And if the world will be judged by you, are you unworthy to judge the smallest matters?", 
                "num": 2
              }, 
              {
                "text": "Do you not know that we shall judge angels? How much more, things that pertain to this life?", 
                "num": 3
              }, 
              {
                "text": "If then you have judgments concerning things pertaining to this life, do you appoint those who are least esteemed by the church to judge?", 
                "num": 4
              }, 
              {
                "text": "I say this to your shame. Is it so, that there is not a wise man among you, not even one, who will be able to judge between his brethren?", 
                "num": 5
              }, 
              {
                "text": "But brother goes to law against brother, and that before unbelievers!", 
                "num": 6
              }, 
              {
                "text": "Now therefore, it is already an utter failure for you that you go to law against one another. Why do you not rather accept wrong? Why do you not rather let yourselves be cheated?", 
                "num": 7
              }, 
              {
                "text": "No, you yourselves do wrong and cheat, and you do these things to your brethren!", 
                "num": 8
              }, 
              {
                "text": "Do you not know that the unrighteous will not inherit the kingdom of God? Do not be deceived. Neither fornicators, nor idolaters, nor adulterers, nor homosexuals, nor sodomites,", 
                "num": 9
              }, 
              {
                "text": "nor thieves, nor covetous, nor drunkards, nor revilers, nor extortioners will inherit the kingdom of God.", 
                "num": 10
              }, 
              {
                "text": "And such were some of you. But you were washed, but you were sanctified, but you were justified in the name of the Lord Jesus and by the Spirit of our God.", 
                "num": 11
              }, 
              {
                "text": "All things are lawful for me, but all things are not helpful. All things are lawful for me, but I will not be brought under the power of any.", 
                "num": 12
              }, 
              {
                "text": "Foods for the stomach and the stomach for foods, but God will destroy both it and them. Now the body is not for sexual immorality but for the Lord, and the Lord for the body.", 
                "num": 13
              }, 
              {
                "text": "And God both raised up the Lord and will also raise us up by His power.", 
                "num": 14
              }, 
              {
                "text": "Do you not know that your bodies are members of Christ? Shall I then take the members of Christ and make them members of a harlot? Certainly not!", 
                "num": 15
              }, 
              {
                "text": "Or do you not know that he who is joined to a harlot is one body with her? For \"the two,\" He says, \"shall become one flesh.\"", 
                "num": 16
              }, 
              {
                "text": "But he who is joined to the Lord is one spirit with Him.", 
                "num": 17
              }, 
              {
                "text": "Flee sexual immorality. Every sin that a man does is outside the body, but he who commits sexual immorality sins against his own body.", 
                "num": 18
              }, 
              {
                "text": "Or do you not know that your body is the temple of the Holy Spirit who is in you, whom you have from God, and you are not your own?", 
                "num": 19
              }, 
              {
                "text": "For you were bought at a price; therefore glorify God in your body and in your spirit, which are God's.", 
                "num": 20
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Now concerning the things of which you wrote to me: It is good for a man not to touch a woman.", 
                "num": 1
              }, 
              {
                "text": "Nevertheless, because of sexual immorality, let each man have his own wife, and let each woman have her own husband.", 
                "num": 2
              }, 
              {
                "text": "Let the husband render to his wife the affection due her, and likewise also the wife to her husband.", 
                "num": 3
              }, 
              {
                "text": "The wife does not have authority over her own body, but the husband does. And likewise the husband does not have authority over his own body, but the wife does.", 
                "num": 4
              }, 
              {
                "text": "Do not deprive one another except with consent for a time, that you may give yourselves to fasting and prayer; and come together again so that Satan does not tempt you because of your lack of self-control.", 
                "num": 5
              }, 
              {
                "text": "But I say this as a concession, not as a commandment.", 
                "num": 6
              }, 
              {
                "text": "For I wish that all men were even as I myself. But each one has his own gift from God, one in this manner and another in that.", 
                "num": 7
              }, 
              {
                "text": "But I say to the unmarried and to the widows: It is good for them if they remain even as I am;", 
                "num": 8
              }, 
              {
                "text": "but if they cannot exercise self-control, let them marry. For it is better to marry than to burn with passion.", 
                "num": 9
              }, 
              {
                "text": "Now to the married I command, yet not I but the Lord: A wife is not to depart from her husband.", 
                "num": 10
              }, 
              {
                "text": "But even if she does depart, let her remain unmarried or be reconciled to her husband. And a husband is not to divorce his wife.", 
                "num": 11
              }, 
              {
                "text": "But to the rest I, not the Lord, say: If any brother has a wife who does not believe, and she is willing to live with him, let him not divorce her.", 
                "num": 12
              }, 
              {
                "text": "And a woman who has a husband who does not believe, if he is willing to live with her, let her not divorce him.", 
                "num": 13
              }, 
              {
                "text": "For the unbelieving husband is sanctified by the wife, and the unbelieving wife is sanctified by the husband; otherwise your children would be unclean, but now they are holy.", 
                "num": 14
              }, 
              {
                "text": "But if the unbeliever departs, let him depart; a brother or a sister is not under bondage in such cases. But God has called us to peace.", 
                "num": 15
              }, 
              {
                "text": "For how do you know, O wife, whether you will save your husband? Or how do you know, O husband, whether you will save your wife?", 
                "num": 16
              }, 
              {
                "text": "But as God has distributed to each one, as the Lord has called each one, so let him walk. And so I ordain in all the churches.", 
                "num": 17
              }, 
              {
                "text": "Was anyone called while circumcised? Let him not become uncircumcised. Was anyone called while uncircumcised? Let him not be circumcised.", 
                "num": 18
              }, 
              {
                "text": "Circumcision is nothing and uncircumcision is nothing, but keeping the commandments of God is what matters.", 
                "num": 19
              }, 
              {
                "text": "Let each one remain in the same calling in which he was called.", 
                "num": 20
              }, 
              {
                "text": "Were you called while a slave? Do not be concerned about it; but if you can be made free, rather use it.", 
                "num": 21
              }, 
              {
                "text": "For he who is called in the Lord while a slave is the Lord's freedman. Likewise he who is called while free is Christ's slave.", 
                "num": 22
              }, 
              {
                "text": "You were bought at a price; do not become slaves of men.", 
                "num": 23
              }, 
              {
                "text": "Brethren, let each one remain with God in that state in which he was called.", 
                "num": 24
              }, 
              {
                "text": "Now concerning virgins: I have no commandment from the Lord; yet I give judgment as one whom the Lord in His mercy has made trustworthy.", 
                "num": 25
              }, 
              {
                "text": "I suppose therefore that this is good because of the present distress--that it is good for a man to remain as he is:", 
                "num": 26
              }, 
              {
                "text": "Are you bound to a wife? Do not seek to be loosed. Are you loosed from a wife? Do not seek a wife.", 
                "num": 27
              }, 
              {
                "text": "But even if you do marry, you have not sinned; and if a virgin marries, she has not sinned. Nevertheless such will have trouble in the flesh, but I would spare you.", 
                "num": 28
              }, 
              {
                "text": "But this I say, brethren, the time is short, so that from now on even those who have wives should be as though they had none,", 
                "num": 29
              }, 
              {
                "text": "those who weep as though they did not weep, those who rejoice as though they did not rejoice, those who buy as though they did not possess,", 
                "num": 30
              }, 
              {
                "text": "and those who use this world as not misusing it. For the form of this world is passing away.", 
                "num": 31
              }, 
              {
                "text": "But I want you to be without care. He who is unmarried cares for the things of the Lord--how he may please the Lord.", 
                "num": 32
              }, 
              {
                "text": "But he who is married cares about the things of the world--how he may please his wife.", 
                "num": 33
              }, 
              {
                "text": "There is a difference between a wife and a virgin. The unmarried woman cares about the things of the Lord, that she may be holy both in body and in spirit. But she who is married cares about the things of the world--how she may please her husband.", 
                "num": 34
              }, 
              {
                "text": "And this I say for your own profit, not that I may put a leash on you, but for what is proper, and that you may serve the Lord without distraction.", 
                "num": 35
              }, 
              {
                "text": "But if any man thinks he is behaving improperly toward his virgin, if she is past the flower of youth, and thus it must be, let him do what he wishes. He does not sin; let them marry.", 
                "num": 36
              }, 
              {
                "text": "Nevertheless he who stands steadfast in his heart, having no necessity, but has power over his own will, and has so determined in his heart that he will keep his virgin, does well.", 
                "num": 37
              }, 
              {
                "text": "So then he who gives her in marriage does well, but he who does not give her in marriage does better.", 
                "num": 38
              }, 
              {
                "text": "A wife is bound by law as long as her husband lives; but if her husband dies, she is at liberty to be married to whom she wishes, only in the Lord.", 
                "num": 39
              }, 
              {
                "text": "But she is happier if she remains as she is, according to my judgment--and I think I also have the Spirit of God.", 
                "num": 40
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now concerning things offered to idols: We know that we all have knowledge. Knowledge puffs up, but love edifies.", 
                "num": 1
              }, 
              {
                "text": "And if anyone thinks that he knows anything, he knows nothing yet as he ought to know.", 
                "num": 2
              }, 
              {
                "text": "But if anyone loves God, this one is known by Him.", 
                "num": 3
              }, 
              {
                "text": "Therefore concerning the eating of things offered to idols, we know that an idol is nothing in the world, and that there is no other God but one.", 
                "num": 4
              }, 
              {
                "text": "For even if there are so-called gods, whether in heaven or on earth (as there are many gods and many lords),", 
                "num": 5
              }, 
              {
                "text": "yet for us there is one God, the Father, of whom are all things, and we for Him; and one Lord Jesus Christ, through whom are all things, and through whom we live.", 
                "num": 6
              }, 
              {
                "text": "However, there is not in everyone that knowledge; for some, with consciousness of the idol, until now eat it as a thing offered to an idol; and their conscience, being weak, is defiled.", 
                "num": 7
              }, 
              {
                "text": "But food does not commend us to God; for neither if we eat are we the better, nor if we do not eat are we the worse.", 
                "num": 8
              }, 
              {
                "text": "But beware lest somehow this liberty of yours become a stumbling block to those who are weak.", 
                "num": 9
              }, 
              {
                "text": "For if anyone sees you who have knowledge eating in an idol's temple, will not the conscience of him who is weak be emboldened to eat those things offered to idols?", 
                "num": 10
              }, 
              {
                "text": "And because of your knowledge shall the weak brother perish, for whom Christ died?", 
                "num": 11
              }, 
              {
                "text": "But when you thus sin against the brethren, and wound their weak conscience, you sin against Christ.", 
                "num": 12
              }, 
              {
                "text": "Therefore, if food makes my brother stumble, I will never again eat meat, lest I make my brother stumble.", 
                "num": 13
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Am I not an apostle? Am I not free? Have I not seen Jesus Christ our Lord? Are you not my work in the Lord?", 
                "num": 1
              }, 
              {
                "text": "If I am not an apostle to others, yet doubtless I am to you. For you are the seal of my apostleship in the Lord.", 
                "num": 2
              }, 
              {
                "text": "My defense to those who examine me is this:", 
                "num": 3
              }, 
              {
                "text": "Do we have no right to eat and drink?", 
                "num": 4
              }, 
              {
                "text": "Do we have no right to take along a believing wife, as do also the other apostles, the brothers of the Lord, and Cephas?", 
                "num": 5
              }, 
              {
                "text": "Or is it only Barnabas and I who have no right to refrain from working?", 
                "num": 6
              }, 
              {
                "text": "Who ever goes to war at his own expense? Who plants a vineyard and does not eat of its fruit? Or who tends a flock and does not drink of the milk of the flock?", 
                "num": 7
              }, 
              {
                "text": "Do I say these things as a mere man? Or does not the law say the same also?", 
                "num": 8
              }, 
              {
                "text": "For it is written in the law of Moses, \"You shall not muzzle an ox while it treads out the grain.\" Is it oxen God is concerned about?", 
                "num": 9
              }, 
              {
                "text": "Or does He say it altogether for our sakes? For our sakes, no doubt, this is written, that he who plows should plow in hope, and he who threshes in hope should be partaker of his hope.", 
                "num": 10
              }, 
              {
                "text": "If we have sown spiritual things for you, is it a great thing if we reap your material things?", 
                "num": 11
              }, 
              {
                "text": "If others are partakers of this right over you, are we not even more? Nevertheless we have not used this right, but endure all things lest we hinder the gospel of Christ.", 
                "num": 12
              }, 
              {
                "text": "Do you not know that those who minister the holy things eat of the things of the temple, and those who serve at the altar partake of the offerings of the altar?", 
                "num": 13
              }, 
              {
                "text": "Even so the Lord has commanded that those who preach the gospel should live from the gospel.", 
                "num": 14
              }, 
              {
                "text": "But I have used none of these things, nor have I written these things that it should be done so to me; for it would be better for me to die than that anyone should make my boasting void.", 
                "num": 15
              }, 
              {
                "text": "For if I preach the gospel, I have nothing to boast of, for necessity is laid upon me; yes, woe is me if I do not preach the gospel!", 
                "num": 16
              }, 
              {
                "text": "For if I do this willingly, I have a reward; but if against my will, I have been entrusted with a stewardship.", 
                "num": 17
              }, 
              {
                "text": "What is my reward then? That when I preach the gospel, I may present the gospel of Christ without charge, that I may not abuse my authority in the gospel.", 
                "num": 18
              }, 
              {
                "text": "For though I am free from all men, I have made myself a servant to all, that I might win the more;", 
                "num": 19
              }, 
              {
                "text": "and to the Jews I became as a Jew, that I might win Jews; to those who are under the law, as under the law, that I might win those who are under the law;", 
                "num": 20
              }, 
              {
                "text": "to those who are without law, as without law (not being without law toward God, but under law toward Christ), that I might win those who are without law;", 
                "num": 21
              }, 
              {
                "text": "to the weak I became as weak, that I might win the weak. I have become all things to all men, that I might by all means save some.", 
                "num": 22
              }, 
              {
                "text": "Now this I do for the gospel's sake, that I may be partaker of it with you.", 
                "num": 23
              }, 
              {
                "text": "Do you not know that those who run in a race all run, but one receives the prize? Run in such a way that you may obtain it.", 
                "num": 24
              }, 
              {
                "text": "And everyone who competes for the prize is temperate in all things. Now they do it to obtain a perishable crown, but we for an imperishable crown.", 
                "num": 25
              }, 
              {
                "text": "Therefore I run thus: not with uncertainty. Thus I fight: not as one who beats the air.", 
                "num": 26
              }, 
              {
                "text": "But I discipline my body and bring it into subjection, lest, when I have preached to others, I myself should become disqualified.", 
                "num": 27
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Moreover, brethren, I do not want you to be unaware that all our fathers were under the cloud, all passed through the sea,", 
                "num": 1
              }, 
              {
                "text": "all were baptized into Moses in the cloud and in the sea,", 
                "num": 2
              }, 
              {
                "text": "all ate the same spiritual food,", 
                "num": 3
              }, 
              {
                "text": "and all drank the same spiritual drink. For they drank of that spiritual Rock that followed them, and that Rock was Christ.", 
                "num": 4
              }, 
              {
                "text": "But with most of them God was not well pleased, for their bodies were scattered in the wilderness.", 
                "num": 5
              }, 
              {
                "text": "Now these things became our examples, to the intent that we should not lust after evil things as they also lusted.", 
                "num": 6
              }, 
              {
                "text": "And do not become idolaters as were some of them. As it is written, \"The people sat down to eat and drink, and rose up to play.\"", 
                "num": 7
              }, 
              {
                "text": "Nor let us commit sexual immorality, as some of them did, and in one day twenty-three thousand fell;", 
                "num": 8
              }, 
              {
                "text": "nor let us tempt Christ, as some of them also tempted, and were destroyed by serpents;", 
                "num": 9
              }, 
              {
                "text": "nor complain, as some of them also complained, and were destroyed by the destroyer.", 
                "num": 10
              }, 
              {
                "text": "Now all these things happened to them as examples, and they were written for our admonition, upon whom the ends of the ages have come.", 
                "num": 11
              }, 
              {
                "text": "Therefore let him who thinks he stands take heed lest he fall.", 
                "num": 12
              }, 
              {
                "text": "No temptation has overtaken you except such as is common to man; but God is faithful, who will not allow you to be tempted beyond what you are able, but with the temptation will also make the way of escape, that you may be able to bear it.", 
                "num": 13
              }, 
              {
                "text": "Therefore, my beloved, flee from idolatry.", 
                "num": 14
              }, 
              {
                "text": "I speak as to wise men; judge for yourselves what I say.", 
                "num": 15
              }, 
              {
                "text": "The cup of blessing which we bless, is it not the communion of the blood of Christ? The bread which we break, is it not the communion of the body of Christ?", 
                "num": 16
              }, 
              {
                "text": "For we, though many, are one bread and one body; for we all partake of that one bread.", 
                "num": 17
              }, 
              {
                "text": "Observe Israel after the flesh: Are not those who eat of the sacrifices partakers of the altar?", 
                "num": 18
              }, 
              {
                "text": "What am I saying then? That an idol is anything, or what is offered to idols is anything?", 
                "num": 19
              }, 
              {
                "text": "Rather, that the things which the Gentiles sacrifice they sacrifice to demons and not to God, and I do not want you to have fellowship with demons.", 
                "num": 20
              }, 
              {
                "text": "You cannot drink the cup of the Lord and the cup of demons; you cannot partake of the Lord's table and of the table of demons.", 
                "num": 21
              }, 
              {
                "text": "Or do we provoke the Lord to jealousy? Are we stronger than He?", 
                "num": 22
              }, 
              {
                "text": "All things are lawful for me, but not all things are helpful; all things are lawful for me, but not all things edify.", 
                "num": 23
              }, 
              {
                "text": "Let no one seek his own, but each one the other's well-being.", 
                "num": 24
              }, 
              {
                "text": "Eat whatever is sold in the meat market, asking no questions for conscience' sake;", 
                "num": 25
              }, 
              {
                "text": "for \"the earth is the LORD's, and all its fullness.\"", 
                "num": 26
              }, 
              {
                "text": "If any of those who do not believe invites you to dinner, and you desire to go, eat whatever is set before you, asking no question for conscience' sake.", 
                "num": 27
              }, 
              {
                "text": "But if anyone says to you, \"This was offered to idols,\" do not eat it for the sake of the one who told you, and for conscience' sake; for \"the earth is the LORD's, and all its fullness.\"", 
                "num": 28
              }, 
              {
                "text": "\"Conscience,\" I say, not your own, but that of the other. For why is my liberty judged by another man's conscience?", 
                "num": 29
              }, 
              {
                "text": "But if I partake with thanks, why am I evil spoken of for the food over which I give thanks?", 
                "num": 30
              }, 
              {
                "text": "Therefore, whether you eat or drink, or whatever you do, do all to the glory of God.", 
                "num": 31
              }, 
              {
                "text": "Give no offense, either to the Jews or to the Greeks or to the church of God,", 
                "num": 32
              }, 
              {
                "text": "just as I also please all men in all things, not seeking my own profit, but the profit of many, that they may be saved.", 
                "num": 33
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Imitate me, just as I also imitate Christ.", 
                "num": 1
              }, 
              {
                "text": "Now I praise you, brethren, that you remember me in all things and keep the traditions just as I delivered them to you.", 
                "num": 2
              }, 
              {
                "text": "But I want you to know that the head of every man is Christ, the head of woman is man, and the head of Christ is God.", 
                "num": 3
              }, 
              {
                "text": "Every man praying or prophesying, having his head covered, dishonors his head.", 
                "num": 4
              }, 
              {
                "text": "But every woman who prays or prophesies with her head uncovered dishonors her head, for that is one and the same as if her head were shaved.", 
                "num": 5
              }, 
              {
                "text": "For if a woman is not covered, let her also be shorn. But if it is shameful for a woman to be shorn or shaved, let her be covered.", 
                "num": 6
              }, 
              {
                "text": "For a man indeed ought not to cover his head, since he is the image and glory of God; but woman is the glory of man.", 
                "num": 7
              }, 
              {
                "text": "For man is not from woman, but woman from man.", 
                "num": 8
              }, 
              {
                "text": "Nor was man created for the woman, but woman for the man.", 
                "num": 9
              }, 
              {
                "text": "For this reason the woman ought to have a symbol of authority on her head, because of the angels.", 
                "num": 10
              }, 
              {
                "text": "Nevertheless, neither is man independent of woman, nor woman independent of man, in the Lord.", 
                "num": 11
              }, 
              {
                "text": "For as woman came from man, even so man also comes through woman; but all things are from God.", 
                "num": 12
              }, 
              {
                "text": "Judge among yourselves. Is it proper for a woman to pray to God with her head uncovered?", 
                "num": 13
              }, 
              {
                "text": "Does not even nature itself teach you that if a man has long hair, it is a dishonor to him?", 
                "num": 14
              }, 
              {
                "text": "But if a woman has long hair, it is a glory to her; for her hair is given to her for a covering.", 
                "num": 15
              }, 
              {
                "text": "But if anyone seems to be contentious, we have no such custom, nor do the churches of God.", 
                "num": 16
              }, 
              {
                "text": "Now in giving these instructions I do not praise you, since you come together not for the better but for the worse.", 
                "num": 17
              }, 
              {
                "text": "For first of all, when you come together as a church, I hear that there are divisions among you, and in part I believe it.", 
                "num": 18
              }, 
              {
                "text": "For there must also be factions among you, that those who are approved may be recognized among you.", 
                "num": 19
              }, 
              {
                "text": "Therefore when you come together in one place, it is not to eat the Lord's Supper.", 
                "num": 20
              }, 
              {
                "text": "For in eating, each one takes his own supper ahead of others; and one is hungry and another is drunk.", 
                "num": 21
              }, 
              {
                "text": "What! Do you not have houses to eat and drink in? Or do you despise the church of God and shame those who have nothing? What shall I say to you? Shall I praise you in this? I do not praise you.", 
                "num": 22
              }, 
              {
                "text": "For I received from the Lord that which I also delivered to you: that the Lord Jesus on the same night in which He was betrayed took bread;", 
                "num": 23
              }, 
              {
                "text": "and when He had given thanks, He broke it and said, \"Take, eat; this is My body which is broken for you; do this in remembrance of Me.\"", 
                "num": 24
              }, 
              {
                "text": "In the same manner He also took the cup after supper, saying, \"This cup is the new covenant in My blood. This do, as often as you drink it, in remembrance of Me.\"", 
                "num": 25
              }, 
              {
                "text": "For as often as you eat this bread and drink this cup, you proclaim the Lord's death till He comes.", 
                "num": 26
              }, 
              {
                "text": "Therefore whoever eats this bread or drinks this cup of the Lord in an unworthy manner will be guilty of the body and blood of the Lord.", 
                "num": 27
              }, 
              {
                "text": "But let a man examine himself, and so let him eat of the bread and drink of the cup.", 
                "num": 28
              }, 
              {
                "text": "For he who eats and drinks in an unworthy manner eats and drinks judgment to himself, not discerning the Lord's body.", 
                "num": 29
              }, 
              {
                "text": "For this reason many are weak and sick among you, and many sleep.", 
                "num": 30
              }, 
              {
                "text": "For if we would judge ourselves, we would not be judged.", 
                "num": 31
              }, 
              {
                "text": "But when we are judged, we are chastened by the Lord, that we may not be condemned with the world.", 
                "num": 32
              }, 
              {
                "text": "Therefore, my brethren, when you come together to eat, wait for one another.", 
                "num": 33
              }, 
              {
                "text": "But if anyone is hungry, let him eat at home, lest you come together for judgment. And the rest I will set in order when I come.", 
                "num": 34
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now concerning spiritual gifts, brethren, I do not want you to be ignorant:", 
                "num": 1
              }, 
              {
                "text": "You know that you were Gentiles, carried away to these dumb idols, however you were led.", 
                "num": 2
              }, 
              {
                "text": "Therefore I make known to you that no one speaking by the Spirit of God calls Jesus accursed, and no one can say that Jesus is Lord except by the Holy Spirit.", 
                "num": 3
              }, 
              {
                "text": "There are diversities of gifts, but the same Spirit.", 
                "num": 4
              }, 
              {
                "text": "There are differences of ministries, but the same Lord.", 
                "num": 5
              }, 
              {
                "text": "And there are diversities of activities, but it is the same God who works all in all.", 
                "num": 6
              }, 
              {
                "text": "But the manifestation of the Spirit is given to each one for the profit of all:", 
                "num": 7
              }, 
              {
                "text": "for to one is given the word of wisdom through the Spirit, to another the word of knowledge through the same Spirit,", 
                "num": 8
              }, 
              {
                "text": "to another faith by the same Spirit, to another gifts of healings by the same Spirit,", 
                "num": 9
              }, 
              {
                "text": "to another the working of miracles, to another prophecy, to another discerning of spirits, to another different kinds of tongues, to another the interpretation of tongues.", 
                "num": 10
              }, 
              {
                "text": "But one and the same Spirit works all these things, distributing to each one individually as He wills.", 
                "num": 11
              }, 
              {
                "text": "For as the body is one and has many members, but all the members of that one body, being many, are one body, so also is Christ.", 
                "num": 12
              }, 
              {
                "text": "For by one Spirit we were all baptized into one body--whether Jews or Greeks, whether slaves or free--and have all been made to drink into one Spirit.", 
                "num": 13
              }, 
              {
                "text": "For in fact the body is not one member but many.", 
                "num": 14
              }, 
              {
                "text": "If the foot should say, \"Because I am not a hand, I am not of the body,\" is it therefore not of the body?", 
                "num": 15
              }, 
              {
                "text": "And if the ear should say, \"Because I am not an eye, I am not of the body,\" is it therefore not of the body?", 
                "num": 16
              }, 
              {
                "text": "If the whole body were an eye, where would be the hearing? If the whole were hearing, where would be the smelling?", 
                "num": 17
              }, 
              {
                "text": "But now God has set the members, each one of them, in the body just as He pleased.", 
                "num": 18
              }, 
              {
                "text": "And if they were all one member, where would the body be?", 
                "num": 19
              }, 
              {
                "text": "But now indeed there are many members, yet one body.", 
                "num": 20
              }, 
              {
                "text": "And the eye cannot say to the hand, \"I have no need of you\"; nor again the head to the feet, \"I have no need of you.\"", 
                "num": 21
              }, 
              {
                "text": "No, much rather, those members of the body which seem to be weaker are necessary.", 
                "num": 22
              }, 
              {
                "text": "And those members of the body which we think to be less honorable, on these we bestow greater honor; and our unpresentable parts have greater modesty,", 
                "num": 23
              }, 
              {
                "text": "but our presentable parts have no need. But God composed the body, having given greater honor to that part which lacks it,", 
                "num": 24
              }, 
              {
                "text": "that there should be no schism in the body, but that the members should have the same care for one another.", 
                "num": 25
              }, 
              {
                "text": "And if one member suffers, all the members suffer with it; or if one member is honored, all the members rejoice with it.", 
                "num": 26
              }, 
              {
                "text": "Now you are the body of Christ, and members individually.", 
                "num": 27
              }, 
              {
                "text": "And God has appointed these in the church: first apostles, second prophets, third teachers, after that miracles, then gifts of healings, helps, administrations, varieties of tongues.", 
                "num": 28
              }, 
              {
                "text": "Are all apostles? Are all prophets? Are all teachers? Are all workers of miracles?", 
                "num": 29
              }, 
              {
                "text": "Do all have gifts of healings? Do all speak with tongues? Do all interpret?", 
                "num": 30
              }, 
              {
                "text": "But earnestly desire the best gifts. And yet I show you a more excellent way.", 
                "num": 31
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Though I speak with the tongues of men and of angels, but have not love, I have become sounding brass or a clanging cymbal.", 
                "num": 1
              }, 
              {
                "text": "And though I have the gift of prophecy, and understand all mysteries and all knowledge, and though I have all faith, so that I could remove mountains, but have not love, I am nothing.", 
                "num": 2
              }, 
              {
                "text": "And though I bestow all my goods to feed the poor, and though I give my body to be burned, but have not love, it profits me nothing.", 
                "num": 3
              }, 
              {
                "text": "Love suffers long and is kind; love does not envy; love does not parade itself, is not puffed up;", 
                "num": 4
              }, 
              {
                "text": "does not behave rudely, does not seek its own, is not provoked, thinks no evil;", 
                "num": 5
              }, 
              {
                "text": "does not rejoice in iniquity, but rejoices in the truth;", 
                "num": 6
              }, 
              {
                "text": "bears all things, believes all things, hopes all things, endures all things.", 
                "num": 7
              }, 
              {
                "text": "Love never fails. But whether there are prophecies, they will fail; whether there are tongues, they will cease; whether there is knowledge, it will vanish away.", 
                "num": 8
              }, 
              {
                "text": "For we know in part and we prophesy in part.", 
                "num": 9
              }, 
              {
                "text": "But when that which is perfect has come, then that which is in part will be done away.", 
                "num": 10
              }, 
              {
                "text": "When I was a child, I spoke as a child, I understood as a child, I thought as a child; but when I became a man, I put away childish things.", 
                "num": 11
              }, 
              {
                "text": "For now we see in a mirror, dimly, but then face to face. Now I know in part, but then I shall know just as I also am known.", 
                "num": 12
              }, 
              {
                "text": "And now abide faith, hope, love, these three; but the greatest of these is love.", 
                "num": 13
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Pursue love, and desire spiritual gifts, but especially that you may prophesy.", 
                "num": 1
              }, 
              {
                "text": "For he who speaks in a tongue does not speak to men but to God, for no one understands him; however, in the spirit he speaks mysteries.", 
                "num": 2
              }, 
              {
                "text": "But he who prophesies speaks edification and exhortation and comfort to men.", 
                "num": 3
              }, 
              {
                "text": "He who speaks in a tongue edifies himself, but he who prophesies edifies the church.", 
                "num": 4
              }, 
              {
                "text": "I wish you all spoke with tongues, but even more that you prophesied; for he who prophesies is greater than he who speaks with tongues, unless indeed he interprets, that the church may receive edification.", 
                "num": 5
              }, 
              {
                "text": "But now, brethren, if I come to you speaking with tongues, what shall I profit you unless I speak to you either by revelation, by knowledge, by prophesying, or by teaching?", 
                "num": 6
              }, 
              {
                "text": "Even things without life, whether flute or harp, when they make a sound, unless they make a distinction in the sounds, how will it be known what is piped or played?", 
                "num": 7
              }, 
              {
                "text": "For if the trumpet makes an uncertain sound, who will prepare for battle?", 
                "num": 8
              }, 
              {
                "text": "So likewise you, unless you utter by the tongue words easy to understand, how will it be known what is spoken? For you will be speaking into the air.", 
                "num": 9
              }, 
              {
                "text": "There are, it may be, so many kinds of languages in the world, and none of them is without significance.", 
                "num": 10
              }, 
              {
                "text": "Therefore, if I do not know the meaning of the language, I shall be a foreigner to him who speaks, and he who speaks will be a foreigner to me.", 
                "num": 11
              }, 
              {
                "text": "Even so you, since you are zealous for spiritual gifts, let it be for the edification of the church that you seek to excel.", 
                "num": 12
              }, 
              {
                "text": "Therefore let him who speaks in a tongue pray that he may interpret.", 
                "num": 13
              }, 
              {
                "text": "For if I pray in a tongue, my spirit prays, but my understanding is unfruitful.", 
                "num": 14
              }, 
              {
                "text": "What is the conclusion then? I will pray with the spirit, and I will also pray with the understanding. I will sing with the spirit, and I will also sing with the understanding.", 
                "num": 15
              }, 
              {
                "text": "Otherwise, if you bless with the spirit, how will he who occupies the place of the uninformed say \"Amen\" at your giving of thanks, since he does not understand what you say?", 
                "num": 16
              }, 
              {
                "text": "For you indeed give thanks well, but the other is not edified.", 
                "num": 17
              }, 
              {
                "text": "I thank my God I speak with tongues more than you all;", 
                "num": 18
              }, 
              {
                "text": "yet in the church I would rather speak five words with my understanding, that I may teach others also, than ten thousand words in a tongue.", 
                "num": 19
              }, 
              {
                "text": "Brethren, do not be children in understanding; however, in malice be babes, but in understanding be mature.", 
                "num": 20
              }, 
              {
                "text": "In the law it is written: \"With men of other tongues and other lips I will speak to this people; And yet, for all that, they will not hear Me,\" says the Lord.", 
                "num": 21
              }, 
              {
                "text": "Therefore tongues are for a sign, not to those who believe but to unbelievers; but prophesying is not for unbelievers but for those who believe.", 
                "num": 22
              }, 
              {
                "text": "Therefore if the whole church comes together in one place, and all speak with tongues, and there come in those who are uninformed or unbelievers, will they not say that you are out of your mind?", 
                "num": 23
              }, 
              {
                "text": "But if all prophesy, and an unbeliever or an uninformed person comes in, he is convinced by all, he is convicted by all.", 
                "num": 24
              }, 
              {
                "text": "And thus the secrets of his heart are revealed; and so, falling down on his face, he will worship God and report that God is truly among you.", 
                "num": 25
              }, 
              {
                "text": "How is it then, brethren? Whenever you come together, each of you has a psalm, has a teaching, has a tongue, has a revelation, has an interpretation. Let all things be done for edification.", 
                "num": 26
              }, 
              {
                "text": "If anyone speaks in a tongue, let there be two or at the most three, each in turn, and let one interpret.", 
                "num": 27
              }, 
              {
                "text": "But if there is no interpreter, let him keep silent in church, and let him speak to himself and to God.", 
                "num": 28
              }, 
              {
                "text": "Let two or three prophets speak, and let the others judge.", 
                "num": 29
              }, 
              {
                "text": "But if anything is revealed to another who sits by, let the first keep silent.", 
                "num": 30
              }, 
              {
                "text": "For you can all prophesy one by one, that all may learn and all may be encouraged.", 
                "num": 31
              }, 
              {
                "text": "And the spirits of the prophets are subject to the prophets.", 
                "num": 32
              }, 
              {
                "text": "For God is not the author of confusion but of peace, as in all the churches of the saints.", 
                "num": 33
              }, 
              {
                "text": "Let your women keep silent in the churches, for they are not permitted to speak; but they are to be submissive, as the law also says.", 
                "num": 34
              }, 
              {
                "text": "And if they want to learn something, let them ask their own husbands at home; for it is shameful for women to speak in church.", 
                "num": 35
              }, 
              {
                "text": "Or did the word of God come originally from you? Or was it you only that it reached?", 
                "num": 36
              }, 
              {
                "text": "If anyone thinks himself to be a prophet or spiritual, let him acknowledge that the things which I write to you are the commandments of the Lord.", 
                "num": 37
              }, 
              {
                "text": "But if anyone is ignorant, let him be ignorant.", 
                "num": 38
              }, 
              {
                "text": "Therefore, brethren, desire earnestly to prophesy, and do not forbid to speak with tongues.", 
                "num": 39
              }, 
              {
                "text": "Let all things be done decently and in order.", 
                "num": 40
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Moreover, brethren, I declare to you the gospel which I preached to you, which also you received and in which you stand,", 
                "num": 1
              }, 
              {
                "text": "by which also you are saved, if you hold fast that word which I preached to you--unless you believed in vain.", 
                "num": 2
              }, 
              {
                "text": "For I delivered to you first of all that which I also received: that Christ died for our sins according to the Scriptures,", 
                "num": 3
              }, 
              {
                "text": "and that He was buried, and that He rose again the third day according to the Scriptures,", 
                "num": 4
              }, 
              {
                "text": "and that He was seen by Cephas, then by the twelve.", 
                "num": 5
              }, 
              {
                "text": "After that He was seen by over five hundred brethren at once, of whom the greater part remain to the present, but some have fallen asleep.", 
                "num": 6
              }, 
              {
                "text": "After that He was seen by James, then by all the apostles.", 
                "num": 7
              }, 
              {
                "text": "Then last of all He was seen by me also, as by one born out of due time.", 
                "num": 8
              }, 
              {
                "text": "For I am the least of the apostles, who am not worthy to be called an apostle, because I persecuted the church of God.", 
                "num": 9
              }, 
              {
                "text": "But by the grace of God I am what I am, and His grace toward me was not in vain; but I labored more abundantly than they all, yet not I, but the grace of God which was with me.", 
                "num": 10
              }, 
              {
                "text": "Therefore, whether it was I or they, so we preach and so you believed.", 
                "num": 11
              }, 
              {
                "text": "Now if Christ is preached that He has been raised from the dead, how do some among you say that there is no resurrection of the dead?", 
                "num": 12
              }, 
              {
                "text": "But if there is no resurrection of the dead, then Christ is not risen.", 
                "num": 13
              }, 
              {
                "text": "And if Christ is not risen, then our preaching is empty and your faith is also empty.", 
                "num": 14
              }, 
              {
                "text": "Yes, and we are found false witnesses of God, because we have testified of God that He raised up Christ, whom He did not raise up--if in fact the dead do not rise.", 
                "num": 15
              }, 
              {
                "text": "For if the dead do not rise, then Christ is not risen.", 
                "num": 16
              }, 
              {
                "text": "And if Christ is not risen, your faith is futile; you are still in your sins!", 
                "num": 17
              }, 
              {
                "text": "Then also those who have fallen asleep in Christ have perished.", 
                "num": 18
              }, 
              {
                "text": "If in this life only we have hope in Christ, we are of all men the most pitiable.", 
                "num": 19
              }, 
              {
                "text": "But now Christ is risen from the dead, and has become the firstfruits of those who have fallen asleep.", 
                "num": 20
              }, 
              {
                "text": "For since by man came death, by Man also came the resurrection of the dead.", 
                "num": 21
              }, 
              {
                "text": "For as in Adam all die, even so in Christ all shall be made alive.", 
                "num": 22
              }, 
              {
                "text": "But each one in his own order: Christ the firstfruits, afterward those who are Christ's at His coming.", 
                "num": 23
              }, 
              {
                "text": "Then comes the end, when He delivers the kingdom to God the Father, when He puts an end to all rule and all authority and power.", 
                "num": 24
              }, 
              {
                "text": "For He must reign till He has put all enemies under His feet.", 
                "num": 25
              }, 
              {
                "text": "The last enemy that will be destroyed is death.", 
                "num": 26
              }, 
              {
                "text": "For \"He has put all things under His feet.\" But when He says \"all things are put under Him,\" it is evident that He who put all things under Him is excepted.", 
                "num": 27
              }, 
              {
                "text": "Now when all things are made subject to Him, then the Son Himself will also be subject to Him who put all things under Him, that God may be all in all.", 
                "num": 28
              }, 
              {
                "text": "Otherwise, what will they do who are baptized for the dead, if the dead do not rise at all? Why then are they baptized for the dead?", 
                "num": 29
              }, 
              {
                "text": "And why do we stand in jeopardy every hour?", 
                "num": 30
              }, 
              {
                "text": "I affirm, by the boasting in you which I have in Christ Jesus our Lord, I die daily.", 
                "num": 31
              }, 
              {
                "text": "If, in the manner of men, I have fought with beasts at Ephesus, what advantage is it to me? If the dead do not rise, \"Let us eat and drink, for tomorrow we die!\"", 
                "num": 32
              }, 
              {
                "text": "Do not be deceived: \"Evil company corrupts good habits.\"", 
                "num": 33
              }, 
              {
                "text": "Awake to righteousness, and do not sin; for some do not have the knowledge of God. I speak this to your shame.", 
                "num": 34
              }, 
              {
                "text": "But someone will say, \"How are the dead raised up? And with what body do they come?\"", 
                "num": 35
              }, 
              {
                "text": "Foolish one, what you sow is not made alive unless it dies.", 
                "num": 36
              }, 
              {
                "text": "And what you sow, you do not sow that body that shall be, but mere grain--perhaps wheat or some other grain.", 
                "num": 37
              }, 
              {
                "text": "But God gives it a body as He pleases, and to each seed its own body.", 
                "num": 38
              }, 
              {
                "text": "All flesh is not the same flesh, but there is one kind of flesh of men, another flesh of animals, another of fish, and another of birds.", 
                "num": 39
              }, 
              {
                "text": "There are also celestial bodies and terrestrial bodies; but the glory of the celestial is one, and the glory of the terrestrial is another.", 
                "num": 40
              }, 
              {
                "text": "There is one glory of the sun, another glory of the moon, and another glory of the stars; for one star differs from another star in glory.", 
                "num": 41
              }, 
              {
                "text": "So also is the resurrection of the dead. The body is sown in corruption, it is raised in incorruption.", 
                "num": 42
              }, 
              {
                "text": "It is sown in dishonor, it is raised in glory. It is sown in weakness, it is raised in power.", 
                "num": 43
              }, 
              {
                "text": "It is sown a natural body, it is raised a spiritual body. There is a natural body, and there is a spiritual body.", 
                "num": 44
              }, 
              {
                "text": "And so it is written, \"The first man Adam became a living being.\" The last Adam became a life-giving spirit.", 
                "num": 45
              }, 
              {
                "text": "However, the spiritual is not first, but the natural, and afterward the spiritual.", 
                "num": 46
              }, 
              {
                "text": "The first man was of the earth, made of dust; the second Man is the Lord from heaven.", 
                "num": 47
              }, 
              {
                "text": "As was the man of dust, so also are those who are made of dust; and as is the heavenly Man, so also are those who are heavenly.", 
                "num": 48
              }, 
              {
                "text": "And as we have borne the image of the man of dust, we shall also bear the image of the heavenly Man.", 
                "num": 49
              }, 
              {
                "text": "Now this I say, brethren, that flesh and blood cannot inherit the kingdom of God; nor does corruption inherit incorruption.", 
                "num": 50
              }, 
              {
                "text": "Behold, I tell you a mystery: We shall not all sleep, but we shall all be changed--", 
                "num": 51
              }, 
              {
                "text": "in a moment, in the twinkling of an eye, at the last trumpet. For the trumpet will sound, and the dead will be raised incorruptible, and we shall be changed.", 
                "num": 52
              }, 
              {
                "text": "For this corruptible must put on incorruption, and this mortal must put on immortality.", 
                "num": 53
              }, 
              {
                "text": "So when this corruptible has put on incorruption, and this mortal has put on immortality, then shall be brought to pass the saying that is written: \"Death is swallowed up in victory.\"", 
                "num": 54
              }, 
              {
                "text": "\"O Death, where is your sting? O Hades, where is your victory?\"", 
                "num": 55
              }, 
              {
                "text": "The sting of death is sin, and the strength of sin is the law.", 
                "num": 56
              }, 
              {
                "text": "But thanks be to God, who gives us the victory through our Lord Jesus Christ.", 
                "num": 57
              }, 
              {
                "text": "Therefore, my beloved brethren, be steadfast, immovable, always abounding in the work of the Lord, knowing that your labor is not in vain in the Lord.", 
                "num": 58
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Now concerning the collection for the saints, as I have given orders to the churches of Galatia, so you must do also:", 
                "num": 1
              }, 
              {
                "text": "On the first day of the week let each one of you lay something aside, storing up as he may prosper, that there be no collections when I come.", 
                "num": 2
              }, 
              {
                "text": "And when I come, whomever you approve by your letters I will send to bear your gift to Jerusalem.", 
                "num": 3
              }, 
              {
                "text": "But if it is fitting that I go also, they will go with me.", 
                "num": 4
              }, 
              {
                "text": "Now I will come to you when I pass through Macedonia (for I am passing through Macedonia).", 
                "num": 5
              }, 
              {
                "text": "And it may be that I will remain, or even spend the winter with you, that you may send me on my journey, wherever I go.", 
                "num": 6
              }, 
              {
                "text": "For I do not wish to see you now on the way; but I hope to stay a while with you, if the Lord permits.", 
                "num": 7
              }, 
              {
                "text": "But I will tarry in Ephesus until Pentecost.", 
                "num": 8
              }, 
              {
                "text": "For a great and effective door has opened to me, and there are many adversaries.", 
                "num": 9
              }, 
              {
                "text": "And if Timothy comes, see that he may be with you without fear; for he does the work of the Lord, as I also do.", 
                "num": 10
              }, 
              {
                "text": "Therefore let no one despise him. But send him on his journey in peace, that he may come to me; for I am waiting for him with the brethren.", 
                "num": 11
              }, 
              {
                "text": "Now concerning our brother Apollos, I strongly urged him to come to you with the brethren, but he was quite unwilling to come at this time; however, he will come when he has a convenient time.", 
                "num": 12
              }, 
              {
                "text": "Watch, stand fast in the faith, be brave, be strong.", 
                "num": 13
              }, 
              {
                "text": "Let all that you do be done with love.", 
                "num": 14
              }, 
              {
                "text": "I urge you, brethren--you know the household of Stephanas, that it is the firstfruits of Achaia, and that they have devoted themselves to the ministry of the saints--", 
                "num": 15
              }, 
              {
                "text": "that you also submit to such, and to everyone who works and labors with us.", 
                "num": 16
              }, 
              {
                "text": "I am glad about the coming of Stephanas, Fortunatus, and Achaicus, for what was lacking on your part they supplied.", 
                "num": 17
              }, 
              {
                "text": "For they refreshed my spirit and yours. Therefore acknowledge such men.", 
                "num": 18
              }, 
              {
                "text": "The churches of Asia greet you. Aquila and Priscilla greet you heartily in the Lord, with the church that is in their house.", 
                "num": 19
              }, 
              {
                "text": "All the brethren greet you. Greet one another with a holy kiss.", 
                "num": 20
              }, 
              {
                "text": "The salutation with my own hand--Paul's.", 
                "num": 21
              }, 
              {
                "text": "If anyone does not love the Lord Jesus Christ, let him be accursed. O Lord, come!", 
                "num": 22
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you.", 
                "num": 23
              }, 
              {
                "text": "My love be with you all in Christ Jesus. Amen.", 
                "num": 24
              }
            ], 
            "num": 16
          }
        ]
      }, 
      {
        "name": "2 Corinthians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle of Jesus Christ by the will of God, and Timothy our brother, To the church of God which is at Corinth, with all the saints who are in all Achaia:", 
                "num": 1
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 2
              }, 
              {
                "text": "Blessed be the God and Father of our Lord Jesus Christ, the Father of mercies and God of all comfort,", 
                "num": 3
              }, 
              {
                "text": "who comforts us in all our tribulation, that we may be able to comfort those who are in any trouble, with the comfort with which we ourselves are comforted by God.", 
                "num": 4
              }, 
              {
                "text": "For as the sufferings of Christ abound in us, so our consolation also abounds through Christ.", 
                "num": 5
              }, 
              {
                "text": "Now if we are afflicted, it is for your consolation and salvation, which is effective for enduring the same sufferings which we also suffer. Or if we are comforted, it is for your consolation and salvation.", 
                "num": 6
              }, 
              {
                "text": "And our hope for you is steadfast, because we know that as you are partakers of the sufferings, so also you will partake of the consolation.", 
                "num": 7
              }, 
              {
                "text": "For we do not want you to be ignorant, brethren, of our trouble which came to us in Asia: that we were burdened beyond measure, above strength, so that we despaired even of life.", 
                "num": 8
              }, 
              {
                "text": "Yes, we had the sentence of death in ourselves, that we should not trust in ourselves but in God who raises the dead,", 
                "num": 9
              }, 
              {
                "text": "who delivered us from so great a death, and does deliver us; in whom we trust that He will still deliver us,", 
                "num": 10
              }, 
              {
                "text": "you also helping together in prayer for us, that thanks may be given by many persons on our behalf for the gift granted to us through many.", 
                "num": 11
              }, 
              {
                "text": "For our boasting is this: the testimony of our conscience that we conducted ourselves in the world in simplicity and godly sincerity, not with fleshly wisdom but by the grace of God, and more abundantly toward you.", 
                "num": 12
              }, 
              {
                "text": "For we are not writing any other things to you than what you read or understand. Now I trust you will understand, even to the end", 
                "num": 13
              }, 
              {
                "text": "(as also you have understood us in part), that we are your boast as you also are ours, in the day of the Lord Jesus.", 
                "num": 14
              }, 
              {
                "text": "And in this confidence I intended to come to you before, that you might have a second benefit--", 
                "num": 15
              }, 
              {
                "text": "to pass by way of you to Macedonia, to come again from Macedonia to you, and be helped by you on my way to Judea.", 
                "num": 16
              }, 
              {
                "text": "Therefore, when I was planning this, did I do it lightly? Or the things I plan, do I plan according to the flesh, that with me there should be Yes, Yes, and No, No?", 
                "num": 17
              }, 
              {
                "text": "But as God is faithful, our word to you was not Yes and No.", 
                "num": 18
              }, 
              {
                "text": "For the Son of God, Jesus Christ, who was preached among you by us--by me, Silvanus, and Timothy--was not Yes and No, but in Him was Yes.", 
                "num": 19
              }, 
              {
                "text": "For all the promises of God in Him are Yes, and in Him Amen, to the glory of God through us.", 
                "num": 20
              }, 
              {
                "text": "Now He who establishes us with you in Christ and has anointed us is God,", 
                "num": 21
              }, 
              {
                "text": "who also has sealed us and given us the Spirit in our hearts as a guarantee.", 
                "num": 22
              }, 
              {
                "text": "Moreover I call God as witness against my soul, that to spare you I came no more to Corinth.", 
                "num": 23
              }, 
              {
                "text": "Not that we have dominion over your faith, but are fellow workers for your joy; for by faith you stand.", 
                "num": 24
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "But I determined this within myself, that I would not come again to you in sorrow.", 
                "num": 1
              }, 
              {
                "text": "For if I make you sorrowful, then who is he who makes me glad but the one who is made sorrowful by me?", 
                "num": 2
              }, 
              {
                "text": "And I wrote this very thing to you, lest, when I came, I should have sorrow over those from whom I ought to have joy, having confidence in you all that my joy is the joy of you all.", 
                "num": 3
              }, 
              {
                "text": "For out of much affliction and anguish of heart I wrote to you, with many tears, not that you should be grieved, but that you might know the love which I have so abundantly for you.", 
                "num": 4
              }, 
              {
                "text": "But if anyone has caused grief, he has not grieved me, but all of you to some extent--not to be too severe.", 
                "num": 5
              }, 
              {
                "text": "This punishment which was inflicted by the majority is sufficient for such a man,", 
                "num": 6
              }, 
              {
                "text": "so that, on the contrary, you ought rather to forgive and comfort him, lest perhaps such a one be swallowed up with too much sorrow.", 
                "num": 7
              }, 
              {
                "text": "Therefore I urge you to reaffirm your love to him.", 
                "num": 8
              }, 
              {
                "text": "For to this end I also wrote, that I might put you to the test, whether you are obedient in all things.", 
                "num": 9
              }, 
              {
                "text": "Now whom you forgive anything, I also forgive. For if indeed I have forgiven anything, I have forgiven that one for your sakes in the presence of Christ,", 
                "num": 10
              }, 
              {
                "text": "lest Satan should take advantage of us; for we are not ignorant of his devices.", 
                "num": 11
              }, 
              {
                "text": "Furthermore, when I came to Troas to preach Christ's gospel, and a door was opened to me by the Lord,", 
                "num": 12
              }, 
              {
                "text": "I had no rest in my spirit, because I did not find Titus my brother; but taking my leave of them, I departed for Macedonia.", 
                "num": 13
              }, 
              {
                "text": "Now thanks be to God who always leads us in triumph in Christ, and through us diffuses the fragrance of His knowledge in every place.", 
                "num": 14
              }, 
              {
                "text": "For we are to God the fragrance of Christ among those who are being saved and among those who are perishing.", 
                "num": 15
              }, 
              {
                "text": "To the one we are the aroma of death leading to death, and to the other the aroma of life leading to life. And who is sufficient for these things?", 
                "num": 16
              }, 
              {
                "text": "For we are not, as so many, peddling the word of God; but as of sincerity, but as from God, we speak in the sight of God in Christ.", 
                "num": 17
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Do we begin again to commend ourselves? Or do we need, as some others, epistles of commendation to you or letters of commendation from you?", 
                "num": 1
              }, 
              {
                "text": "You are our epistle written in our hearts, known and read by all men;", 
                "num": 2
              }, 
              {
                "text": "clearly you are an epistle of Christ, ministered by us, written not with ink but by the Spirit of the living God, not on tablets of stone but on tablets of flesh, that is, of the heart.", 
                "num": 3
              }, 
              {
                "text": "And we have such trust through Christ toward God.", 
                "num": 4
              }, 
              {
                "text": "Not that we are sufficient of ourselves to think of anything as being from ourselves, but our sufficiency is from God,", 
                "num": 5
              }, 
              {
                "text": "who also made us sufficient as ministers of the new covenant, not of the letter but of the Spirit; for the letter kills, but the Spirit gives life.", 
                "num": 6
              }, 
              {
                "text": "But if the ministry of death, written and engraved on stones, was glorious, so that the children of Israel could not look steadily at the face of Moses because of the glory of his countenance, which glory was passing away,", 
                "num": 7
              }, 
              {
                "text": "how will the ministry of the Spirit not be more glorious?", 
                "num": 8
              }, 
              {
                "text": "For if the ministry of condemnation had glory, the ministry of righteousness exceeds much more in glory.", 
                "num": 9
              }, 
              {
                "text": "For even what was made glorious had no glory in this respect, because of the glory that excels.", 
                "num": 10
              }, 
              {
                "text": "For if what is passing away was glorious, what remains is much more glorious.", 
                "num": 11
              }, 
              {
                "text": "Therefore, since we have such hope, we use great boldness of speech--", 
                "num": 12
              }, 
              {
                "text": "unlike Moses, who put a veil over his face so that the children of Israel could not look steadily at the end of what was passing away.", 
                "num": 13
              }, 
              {
                "text": "But their minds were blinded. For until this day the same veil remains unlifted in the reading of the Old Testament, because the veil is taken away in Christ.", 
                "num": 14
              }, 
              {
                "text": "But even to this day, when Moses is read, a veil lies on their heart.", 
                "num": 15
              }, 
              {
                "text": "Nevertheless when one turns to the Lord, the veil is taken away.", 
                "num": 16
              }, 
              {
                "text": "Now the Lord is the Spirit; and where the Spirit of the Lord is, there is liberty.", 
                "num": 17
              }, 
              {
                "text": "But we all, with unveiled face, beholding as in a mirror the glory of the Lord, are being transformed into the same image from glory to glory, just as by the Spirit of the Lord.", 
                "num": 18
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Therefore, since we have this ministry, as we have received mercy, we do not lose heart.", 
                "num": 1
              }, 
              {
                "text": "But we have renounced the hidden things of shame, not walking in craftiness nor handling the word of God deceitfully, but by manifestation of the truth commending ourselves to every man's conscience in the sight of God.", 
                "num": 2
              }, 
              {
                "text": "But even if our gospel is veiled, it is veiled to those who are perishing,", 
                "num": 3
              }, 
              {
                "text": "whose minds the god of this age has blinded, who do not believe, lest the light of the gospel of the glory of Christ, who is the image of God, should shine on them.", 
                "num": 4
              }, 
              {
                "text": "For we do not preach ourselves, but Christ Jesus the Lord, and ourselves your bondservants for Jesus' sake.", 
                "num": 5
              }, 
              {
                "text": "For it is the God who commanded light to shine out of darkness, who has shone in our hearts to give the light of the knowledge of the glory of God in the face of Jesus Christ.", 
                "num": 6
              }, 
              {
                "text": "But we have this treasure in earthen vessels, that the excellence of the power may be of God and not of us.", 
                "num": 7
              }, 
              {
                "text": "We are hard-pressed on every side, yet not crushed; we are perplexed, but not in despair;", 
                "num": 8
              }, 
              {
                "text": "persecuted, but not forsaken; struck down, but not destroyed--", 
                "num": 9
              }, 
              {
                "text": "always carrying about in the body the dying of the Lord Jesus, that the life of Jesus also may be manifested in our body.", 
                "num": 10
              }, 
              {
                "text": "For we who live are always delivered to death for Jesus' sake, that the life of Jesus also may be manifested in our mortal flesh.", 
                "num": 11
              }, 
              {
                "text": "So then death is working in us, but life in you.", 
                "num": 12
              }, 
              {
                "text": "And since we have the same spirit of faith, according to what is written, \"I believed and therefore I spoke,\" we also believe and therefore speak,", 
                "num": 13
              }, 
              {
                "text": "knowing that He who raised up the Lord Jesus will also raise us up with Jesus, and will present us with you.", 
                "num": 14
              }, 
              {
                "text": "For all things are for your sakes, that grace, having spread through the many, may cause thanksgiving to abound to the glory of God.", 
                "num": 15
              }, 
              {
                "text": "Therefore we do not lose heart. Even though our outward man is perishing, yet the inward man is being renewed day by day.", 
                "num": 16
              }, 
              {
                "text": "For our light affliction, which is but for a moment, is working for us a far more exceeding and eternal weight of glory,", 
                "num": 17
              }, 
              {
                "text": "while we do not look at the things which are seen, but at the things which are not seen. For the things which are seen are temporary, but the things which are not seen are eternal.", 
                "num": 18
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "For we know that if our earthly house, this tent, is destroyed, we have a building from God, a house not made with hands, eternal in the heavens.", 
                "num": 1
              }, 
              {
                "text": "For in this we groan, earnestly desiring to be clothed with our habitation which is from heaven,", 
                "num": 2
              }, 
              {
                "text": "if indeed, having been clothed, we shall not be found naked.", 
                "num": 3
              }, 
              {
                "text": "For we who are in this tent groan, being burdened, not because we want to be unclothed, but further clothed, that mortality may be swallowed up by life.", 
                "num": 4
              }, 
              {
                "text": "Now He who has prepared us for this very thing is God, who also has given us the Spirit as a guarantee.", 
                "num": 5
              }, 
              {
                "text": "So we are always confident, knowing that while we are at home in the body we are absent from the Lord.", 
                "num": 6
              }, 
              {
                "text": "For we walk by faith, not by sight.", 
                "num": 7
              }, 
              {
                "text": "We are confident, yes, well pleased rather to be absent from the body and to be present with the Lord.", 
                "num": 8
              }, 
              {
                "text": "Therefore we make it our aim, whether present or absent, to be well pleasing to Him.", 
                "num": 9
              }, 
              {
                "text": "For we must all appear before the judgment seat of Christ, that each one may receive the things done in the body, according to what he has done, whether good or bad.", 
                "num": 10
              }, 
              {
                "text": "Knowing, therefore, the terror of the Lord, we persuade men; but we are well known to God, and I also trust are well known in your consciences.", 
                "num": 11
              }, 
              {
                "text": "For we do not commend ourselves again to you, but give you opportunity to boast on our behalf, that you may have an answer for those who boast in appearance and not in heart.", 
                "num": 12
              }, 
              {
                "text": "For if we are beside ourselves, it is for God; or if we are of sound mind, it is for you.", 
                "num": 13
              }, 
              {
                "text": "For the love of Christ compels us, because we judge thus: that if One died for all, then all died;", 
                "num": 14
              }, 
              {
                "text": "and He died for all, that those who live should live no longer for themselves, but for Him who died for them and rose again.", 
                "num": 15
              }, 
              {
                "text": "Therefore, from now on, we regard no one according to the flesh. Even though we have known Christ according to the flesh, yet now we know Him thus no longer.", 
                "num": 16
              }, 
              {
                "text": "Therefore, if anyone is in Christ, he is a new creation; old things have passed away; behold, all things have become new.", 
                "num": 17
              }, 
              {
                "text": "Now all things are of God, who has reconciled us to Himself through Jesus Christ, and has given us the ministry of reconciliation,", 
                "num": 18
              }, 
              {
                "text": "that is, that God was in Christ reconciling the world to Himself, not imputing their trespasses to them, and has committed to us the word of reconciliation.", 
                "num": 19
              }, 
              {
                "text": "Now then, we are ambassadors for Christ, as though God were pleading through us: we implore you on Christ's behalf, be reconciled to God.", 
                "num": 20
              }, 
              {
                "text": "For He made Him who knew no sin to be sin for us, that we might become the righteousness of God in Him.", 
                "num": 21
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "We then, as workers together with Him also plead with you not to receive the grace of God in vain.", 
                "num": 1
              }, 
              {
                "text": "For He says: \"In an acceptable time I have heard you, And in the day of salvation I have helped you.\" Behold, now is the accepted time; behold, now is the day of salvation.", 
                "num": 2
              }, 
              {
                "text": "We give no offense in anything, that our ministry may not be blamed.", 
                "num": 3
              }, 
              {
                "text": "But in all things we commend ourselves as ministers of God: in much patience, in tribulations, in needs, in distresses,", 
                "num": 4
              }, 
              {
                "text": "in stripes, in imprisonments, in tumults, in labors, in sleeplessness, in fastings;", 
                "num": 5
              }, 
              {
                "text": "by purity, by knowledge, by longsuffering, by kindness, by the Holy Spirit, by sincere love,", 
                "num": 6
              }, 
              {
                "text": "by the word of truth, by the power of God, by the armor of righteousness on the right hand and on the left,", 
                "num": 7
              }, 
              {
                "text": "by honor and dishonor, by evil report and good report; as deceivers, and yet true;", 
                "num": 8
              }, 
              {
                "text": "as unknown, and yet well known; as dying, and behold we live; as chastened, and yet not killed;", 
                "num": 9
              }, 
              {
                "text": "as sorrowful, yet always rejoicing; as poor, yet making many rich; as having nothing, and yet possessing all things.", 
                "num": 10
              }, 
              {
                "text": "O Corinthians! We have spoken openly to you, our heart is wide open.", 
                "num": 11
              }, 
              {
                "text": "You are not restricted by us, but you are restricted by your own affections.", 
                "num": 12
              }, 
              {
                "text": "Now in return for the same (I speak as to children), you also be open.", 
                "num": 13
              }, 
              {
                "text": "Do not be unequally yoked together with unbelievers. For what fellowship has righteousness with lawlessness? And what communion has light with darkness?", 
                "num": 14
              }, 
              {
                "text": "And what accord has Christ with Belial? Or what part has a believer with an unbeliever?", 
                "num": 15
              }, 
              {
                "text": "And what agreement has the temple of God with idols? For you are the temple of the living God. As God has said: \"I will dwell in them And walk among them. I will be their God, And they shall be My people.\"", 
                "num": 16
              }, 
              {
                "text": "Therefore \"Come out from among them And be separate, says the Lord. Do not touch what is unclean, And I will receive you.\"", 
                "num": 17
              }, 
              {
                "text": "\"I will be a Father to you, And you shall be My sons and daughters, Says the LORD Almighty.\"", 
                "num": 18
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "Therefore, having these promises, beloved, let us cleanse ourselves from all filthiness of the flesh and spirit, perfecting holiness in the fear of God.", 
                "num": 1
              }, 
              {
                "text": "Open your hearts to us. We have wronged no one, we have corrupted no one, we have cheated no one.", 
                "num": 2
              }, 
              {
                "text": "I do not say this to condemn; for I have said before that you are in our hearts, to die together and to live together.", 
                "num": 3
              }, 
              {
                "text": "Great is my boldness of speech toward you, great is my boasting on your behalf. I am filled with comfort. I am exceedingly joyful in all our tribulation.", 
                "num": 4
              }, 
              {
                "text": "For indeed, when we came to Macedonia, our bodies had no rest, but we were troubled on every side. Outside were conflicts, inside were fears.", 
                "num": 5
              }, 
              {
                "text": "Nevertheless God, who comforts the downcast, comforted us by the coming of Titus,", 
                "num": 6
              }, 
              {
                "text": "and not only by his coming, but also by the consolation with which he was comforted in you, when he told us of your earnest desire, your mourning, your zeal for me, so that I rejoiced even more.", 
                "num": 7
              }, 
              {
                "text": "For even if I made you sorry with my letter, I do not regret it; though I did regret it. For I perceive that the same epistle made you sorry, though only for a while.", 
                "num": 8
              }, 
              {
                "text": "Now I rejoice, not that you were made sorry, but that your sorrow led to repentance. For you were made sorry in a godly manner, that you might suffer loss from us in nothing.", 
                "num": 9
              }, 
              {
                "text": "For godly sorrow produces repentance leading to salvation, not to be regretted; but the sorrow of the world produces death.", 
                "num": 10
              }, 
              {
                "text": "For observe this very thing, that you sorrowed in a godly manner: What diligence it produced in you, what clearing of yourselves, what indignation, what fear, what vehement desire, what zeal, what vindication! In all things you proved yourselves to be clear in this matter.", 
                "num": 11
              }, 
              {
                "text": "Therefore, although I wrote to you, I did not do it for the sake of him who had done the wrong, nor for the sake of him who suffered wrong, but that our care for you in the sight of God might appear to you.", 
                "num": 12
              }, 
              {
                "text": "Therefore we have been comforted in your comfort. And we rejoiced exceedingly more for the joy of Titus, because his spirit has been refreshed by you all.", 
                "num": 13
              }, 
              {
                "text": "For if in anything I have boasted to him about you, I am not ashamed. But as we spoke all things to you in truth, even so our boasting to Titus was found true.", 
                "num": 14
              }, 
              {
                "text": "And his affections are greater for you as he remembers the obedience of you all, how with fear and trembling you received him.", 
                "num": 15
              }, 
              {
                "text": "Therefore I rejoice that I have confidence in you in everything.", 
                "num": 16
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Moreover, brethren, we make known to you the grace of God bestowed on the churches of Macedonia:", 
                "num": 1
              }, 
              {
                "text": "that in a great trial of affliction the abundance of their joy and their deep poverty abounded in the riches of their liberality.", 
                "num": 2
              }, 
              {
                "text": "For I bear witness that according to their ability, yes, and beyond their ability, they were freely willing,", 
                "num": 3
              }, 
              {
                "text": "imploring us with much urgency that we would receive the gift and the fellowship of the ministering to the saints.", 
                "num": 4
              }, 
              {
                "text": "And not only as we had hoped, but they first gave themselves to the Lord, and then to us by the will of God.", 
                "num": 5
              }, 
              {
                "text": "So we urged Titus, that as he had begun, so he would also complete this grace in you as well.", 
                "num": 6
              }, 
              {
                "text": "But as you abound in everything--in faith, in speech, in knowledge, in all diligence, and in your love for us--see that you abound in this grace also.", 
                "num": 7
              }, 
              {
                "text": "I speak not by commandment, but I am testing the sincerity of your love by the diligence of others.", 
                "num": 8
              }, 
              {
                "text": "For you know the grace of our Lord Jesus Christ, that though He was rich, yet for your sakes He became poor, that you through His poverty might become rich.", 
                "num": 9
              }, 
              {
                "text": "And in this I give advice: It is to your advantage not only to be doing what you began and were desiring to do a year ago;", 
                "num": 10
              }, 
              {
                "text": "but now you also must complete the doing of it; that as there was a readiness to desire it, so there also may be a completion out of what you have.", 
                "num": 11
              }, 
              {
                "text": "For if there is first a willing mind, it is accepted according to what one has, and not according to what he does not have.", 
                "num": 12
              }, 
              {
                "text": "For I do not mean that others should be eased and you burdened;", 
                "num": 13
              }, 
              {
                "text": "but by an equality, that now at this time your abundance may supply their lack, that their abundance also may supply your lack--that there may be equality.", 
                "num": 14
              }, 
              {
                "text": "As it is written, \"He who gathered much had nothing left over, and he who gathered little had no lack.\"", 
                "num": 15
              }, 
              {
                "text": "But thanks be to God who puts the same earnest care for you into the heart of Titus.", 
                "num": 16
              }, 
              {
                "text": "For he not only accepted the exhortation, but being more diligent, he went to you of his own accord.", 
                "num": 17
              }, 
              {
                "text": "And we have sent with him the brother whose praise is in the gospel throughout all the churches,", 
                "num": 18
              }, 
              {
                "text": "and not only that, but who was also chosen by the churches to travel with us with this gift, which is administered by us to the glory of the Lord Himself and to show your ready mind,", 
                "num": 19
              }, 
              {
                "text": "avoiding this: that anyone should blame us in this lavish gift which is administered by us--", 
                "num": 20
              }, 
              {
                "text": "providing honorable things, not only in the sight of the Lord, but also in the sight of men.", 
                "num": 21
              }, 
              {
                "text": "And we have sent with them our brother whom we have often proved diligent in many things, but now much more diligent, because of the great confidence which we have in you.", 
                "num": 22
              }, 
              {
                "text": "If anyone inquires about Titus, he is my partner and fellow worker concerning you. Or if our brethren are inquired about, they are messengers of the churches, the glory of Christ.", 
                "num": 23
              }, 
              {
                "text": "Therefore show to them, and before the churches, the proof of your love and of our boasting on your behalf.", 
                "num": 24
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Now concerning the ministering to the saints, it is superfluous for me to write to you;", 
                "num": 1
              }, 
              {
                "text": "for I know your willingness, about which I boast of you to the Macedonians, that Achaia was ready a year ago; and your zeal has stirred up the majority.", 
                "num": 2
              }, 
              {
                "text": "Yet I have sent the brethren, lest our boasting of you should be in vain in this respect, that, as I said, you may be ready;", 
                "num": 3
              }, 
              {
                "text": "lest if some Macedonians come with me and find you unprepared, we (not to mention you!) should be ashamed of this confident boasting.", 
                "num": 4
              }, 
              {
                "text": "Therefore I thought it necessary to exhort the brethren to go to you ahead of time, and prepare your generous gift beforehand, which you had previously promised, that it may be ready as a matter of generosity and not as a grudging obligation.", 
                "num": 5
              }, 
              {
                "text": "But this I say: He who sows sparingly will also reap sparingly, and he who sows bountifully will also reap bountifully.", 
                "num": 6
              }, 
              {
                "text": "So let each one give as he purposes in his heart, not grudgingly or of necessity; for God loves a cheerful giver.", 
                "num": 7
              }, 
              {
                "text": "And God is able to make all grace abound toward you, that you, always having all sufficiency in all things, may have an abundance for every good work.", 
                "num": 8
              }, 
              {
                "text": "As it is written: \"He has dispersed abroad, He has given to the poor; His righteousness endures forever.\"", 
                "num": 9
              }, 
              {
                "text": "Now may He who supplies seed to the sower, and bread for food, supply and multiply the seed you have sown and increase the fruits of your righteousness,", 
                "num": 10
              }, 
              {
                "text": "while you are enriched in everything for all liberality, which causes thanksgiving through us to God.", 
                "num": 11
              }, 
              {
                "text": "For the administration of this service not only supplies the needs of the saints, but also is abounding through many thanksgivings to God,", 
                "num": 12
              }, 
              {
                "text": "while, through the proof of this ministry, they glorify God for the obedience of your confession to the gospel of Christ, and for your liberal sharing with them and all men,", 
                "num": 13
              }, 
              {
                "text": "and by their prayer for you, who long for you because of the exceeding grace of God in you.", 
                "num": 14
              }, 
              {
                "text": "Thanks be to God for His indescribable gift!", 
                "num": 15
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "Now I, Paul, myself am pleading with you by the meekness and gentleness of Christ--who in presence am lowly among you, but being absent am bold toward you.", 
                "num": 1
              }, 
              {
                "text": "But I beg you that when I am present I may not be bold with that confidence by which I intend to be bold against some, who think of us as if we walked according to the flesh.", 
                "num": 2
              }, 
              {
                "text": "For though we walk in the flesh, we do not war according to the flesh.", 
                "num": 3
              }, 
              {
                "text": "For the weapons of our warfare are not carnal but mighty in God for pulling down strongholds,", 
                "num": 4
              }, 
              {
                "text": "casting down arguments and every high thing that exalts itself against the knowledge of God, bringing every thought into captivity to the obedience of Christ,", 
                "num": 5
              }, 
              {
                "text": "and being ready to punish all disobedience when your obedience is fulfilled.", 
                "num": 6
              }, 
              {
                "text": "Do you look at things according to the outward appearance? If anyone is convinced in himself that he is Christ's, let him again consider this in himself, that just as he is Christ's, even so we are Christ's.", 
                "num": 7
              }, 
              {
                "text": "For even if I should boast somewhat more about our authority, which the Lord gave us for edification and not for your destruction, I shall not be ashamed--", 
                "num": 8
              }, 
              {
                "text": "lest I seem to terrify you by letters.", 
                "num": 9
              }, 
              {
                "text": "\"For his letters,\" they say, \"are weighty and powerful, but his bodily presence is weak, and his speech contemptible.\"", 
                "num": 10
              }, 
              {
                "text": "Let such a person consider this, that what we are in word by letters when we are absent, such we will also be in deed when we are present.", 
                "num": 11
              }, 
              {
                "text": "For we dare not class ourselves or compare ourselves with those who commend themselves. But they, measuring themselves by themselves, and comparing themselves among themselves, are not wise.", 
                "num": 12
              }, 
              {
                "text": "We, however, will not boast beyond measure, but within the limits of the sphere which God appointed us--a sphere which especially includes you.", 
                "num": 13
              }, 
              {
                "text": "For we are not overextending ourselves (as though our authority did not extend to you), for it was to you that we came with the gospel of Christ;", 
                "num": 14
              }, 
              {
                "text": "not boasting of things beyond measure, that is, in other men's labors, but having hope, that as your faith is increased, we shall be greatly enlarged by you in our sphere,", 
                "num": 15
              }, 
              {
                "text": "to preach the gospel in the regions beyond you, and not to boast in another man's sphere of accomplishment.", 
                "num": 16
              }, 
              {
                "text": "But \"he who glories, let him glory in the LORD.\"", 
                "num": 17
              }, 
              {
                "text": "For not he who commends himself is approved, but whom the Lord commends.", 
                "num": 18
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Oh, that you would bear with me in a little folly--and indeed you do bear with me.", 
                "num": 1
              }, 
              {
                "text": "For I am jealous for you with godly jealousy. For I have betrothed you to one husband, that I may present you as a chaste virgin to Christ.", 
                "num": 2
              }, 
              {
                "text": "But I fear, lest somehow, as the serpent deceived Eve by his craftiness, so your minds may be corrupted from the simplicity that is in Christ.", 
                "num": 3
              }, 
              {
                "text": "For if he who comes preaches another Jesus whom we have not preached, or if you receive a different spirit which you have not received, or a different gospel which you have not accepted--you may well put up with it!", 
                "num": 4
              }, 
              {
                "text": "For I consider that I am not at all inferior to the most eminent apostles.", 
                "num": 5
              }, 
              {
                "text": "Even though I am untrained in speech, yet I am not in knowledge. But we have been thoroughly manifested among you in all things.", 
                "num": 6
              }, 
              {
                "text": "Did I commit sin in humbling myself that you might be exalted, because I preached the gospel of God to you free of charge?", 
                "num": 7
              }, 
              {
                "text": "I robbed other churches, taking wages from them to minister to you.", 
                "num": 8
              }, 
              {
                "text": "And when I was present with you, and in need, I was a burden to no one, for what I lacked the brethren who came from Macedonia supplied. And in everything I kept myself from being burdensome to you, and so I will keep myself.", 
                "num": 9
              }, 
              {
                "text": "As the truth of Christ is in me, no one shall stop me from this boasting in the regions of Achaia.", 
                "num": 10
              }, 
              {
                "text": "Why? Because I do not love you? God knows!", 
                "num": 11
              }, 
              {
                "text": "But what I do, I will also continue to do, that I may cut off the opportunity from those who desire an opportunity to be regarded just as we are in the things of which they boast.", 
                "num": 12
              }, 
              {
                "text": "For such are false apostles, deceitful workers, transforming themselves into apostles of Christ.", 
                "num": 13
              }, 
              {
                "text": "And no wonder! For Satan himself transforms himself into an angel of light.", 
                "num": 14
              }, 
              {
                "text": "Therefore it is no great thing if his ministers also transform themselves into ministers of righteousness, whose end will be according to their works.", 
                "num": 15
              }, 
              {
                "text": "I say again, let no one think me a fool. If otherwise, at least receive me as a fool, that I also may boast a little.", 
                "num": 16
              }, 
              {
                "text": "What I speak, I speak not according to the Lord, but as it were, foolishly, in this confidence of boasting.", 
                "num": 17
              }, 
              {
                "text": "Seeing that many boast according to the flesh, I also will boast.", 
                "num": 18
              }, 
              {
                "text": "For you put up with fools gladly, since you yourselves are wise!", 
                "num": 19
              }, 
              {
                "text": "For you put up with it if one brings you into bondage, if one devours you, if one takes from you, if one exalts himself, if one strikes you on the face.", 
                "num": 20
              }, 
              {
                "text": "To our shame I say that we were too weak for that! But in whatever anyone is bold--I speak foolishly--I am bold also.", 
                "num": 21
              }, 
              {
                "text": "Are they Hebrews? So am I. Are they Israelites? So am I. Are they the seed of Abraham? So am I.", 
                "num": 22
              }, 
              {
                "text": "Are they ministers of Christ?--I speak as a fool--I am more: in labors more abundant, in stripes above measure, in prisons more frequently, in deaths often.", 
                "num": 23
              }, 
              {
                "text": "From the Jews five times I received forty stripes minus one.", 
                "num": 24
              }, 
              {
                "text": "Three times I was beaten with rods; once I was stoned; three times I was shipwrecked; a night and a day I have been in the deep;", 
                "num": 25
              }, 
              {
                "text": "in journeys often, in perils of waters, in perils of robbers, in perils of my own countrymen, in perils of the Gentiles, in perils in the city, in perils in the wilderness, in perils in the sea, in perils among false brethren;", 
                "num": 26
              }, 
              {
                "text": "in weariness and toil, in sleeplessness often, in hunger and thirst, in fastings often, in cold and nakedness--", 
                "num": 27
              }, 
              {
                "text": "besides the other things, what comes upon me daily: my deep concern for all the churches.", 
                "num": 28
              }, 
              {
                "text": "Who is weak, and I am not weak? Who is made to stumble, and I do not burn with indignation?", 
                "num": 29
              }, 
              {
                "text": "If I must boast, I will boast in the things which concern my infirmity.", 
                "num": 30
              }, 
              {
                "text": "The God and Father of our Lord Jesus Christ, who is blessed forever, knows that I am not lying.", 
                "num": 31
              }, 
              {
                "text": "In Damascus the governor, under Aretas the king, was guarding the city of the Damascenes with a garrison, desiring to arrest me;", 
                "num": 32
              }, 
              {
                "text": "but I was let down in a basket through a window in the wall, and escaped from his hands.", 
                "num": 33
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "It is doubtless not profitable for me to boast. I will come to visions and revelations of the Lord:", 
                "num": 1
              }, 
              {
                "text": "I know a man in Christ who fourteen years ago--whether in the body I do not know, or whether out of the body I do not know, God knows--such a one was caught up to the third heaven.", 
                "num": 2
              }, 
              {
                "text": "And I know such a man--whether in the body or out of the body I do not know, God knows--", 
                "num": 3
              }, 
              {
                "text": "how he was caught up into Paradise and heard inexpressible words, which it is not lawful for a man to utter.", 
                "num": 4
              }, 
              {
                "text": "Of such a one I will boast; yet of myself I will not boast, except in my infirmities.", 
                "num": 5
              }, 
              {
                "text": "For though I might desire to boast, I will not be a fool; for I will speak the truth. But I refrain, lest anyone should think of me above what he sees me to be or hears from me.", 
                "num": 6
              }, 
              {
                "text": "And lest I should be exalted above measure by the abundance of the revelations, a thorn in the flesh was given to me, a messenger of Satan to buffet me, lest I be exalted above measure.", 
                "num": 7
              }, 
              {
                "text": "Concerning this thing I pleaded with the Lord three times that it might depart from me.", 
                "num": 8
              }, 
              {
                "text": "And He said to me, \"My grace is sufficient for you, for My strength is made perfect in weakness.\" Therefore most gladly I will rather boast in my infirmities, that the power of Christ may rest upon me.", 
                "num": 9
              }, 
              {
                "text": "Therefore I take pleasure in infirmities, in reproaches, in needs, in persecutions, in distresses, for Christ's sake. For when I am weak, then I am strong.", 
                "num": 10
              }, 
              {
                "text": "I have become a fool in boasting; you have compelled me. For I ought to have been commended by you; for in nothing was I behind the most eminent apostles, though I am nothing.", 
                "num": 11
              }, 
              {
                "text": "Truly the signs of an apostle were accomplished among you with all perseverance, in signs and wonders and mighty deeds.", 
                "num": 12
              }, 
              {
                "text": "For what is it in which you were inferior to other churches, except that I myself was not burdensome to you? Forgive me this wrong!", 
                "num": 13
              }, 
              {
                "text": "Now for the third time I am ready to come to you. And I will not be burdensome to you; for I do not seek yours, but you. For the children ought not to lay up for the parents, but the parents for the children.", 
                "num": 14
              }, 
              {
                "text": "And I will very gladly spend and be spent for your souls; though the more abundantly I love you, the less I am loved.", 
                "num": 15
              }, 
              {
                "text": "But be that as it may, I did not burden you. Nevertheless, being crafty, I caught you by cunning!", 
                "num": 16
              }, 
              {
                "text": "Did I take advantage of you by any of those whom I sent to you?", 
                "num": 17
              }, 
              {
                "text": "I urged Titus, and sent our brother with him. Did Titus take advantage of you? Did we not walk in the same spirit? Did we not walk in the same steps?", 
                "num": 18
              }, 
              {
                "text": "Again, do you think that we excuse ourselves to you? We speak before God in Christ. But we do all things, beloved, for your edification.", 
                "num": 19
              }, 
              {
                "text": "For I fear lest, when I come, I shall not find you such as I wish, and that I shall be found by you such as you do not wish; lest there be contentions, jealousies, outbursts of wrath, selfish ambitions, backbitings, whisperings, conceits, tumults;", 
                "num": 20
              }, 
              {
                "text": "lest, when I come again, my God will humble me among you, and I shall mourn for many who have sinned before and have not repented of the uncleanness, fornication, and lewdness which they have practiced.", 
                "num": 21
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "This will be the third time I am coming to you. \"By the mouth of two or three witnesses every word shall be established.\"", 
                "num": 1
              }, 
              {
                "text": "I have told you before, and foretell as if I were present the second time, and now being absent I write to those who have sinned before, and to all the rest, that if I come again I will not spare--", 
                "num": 2
              }, 
              {
                "text": "since you seek a proof of Christ speaking in me, who is not weak toward you, but mighty in you.", 
                "num": 3
              }, 
              {
                "text": "For though He was crucified in weakness, yet He lives by the power of God. For we also are weak in Him, but we shall live with Him by the power of God toward you.", 
                "num": 4
              }, 
              {
                "text": "Examine yourselves as to whether you are in the faith. Test yourselves. Do you not know yourselves, that Jesus Christ is in you?--unless indeed you are disqualified.", 
                "num": 5
              }, 
              {
                "text": "But I trust that you will know that we are not disqualified.", 
                "num": 6
              }, 
              {
                "text": "Now I pray to God that you do no evil, not that we should appear approved, but that you should do what is honorable, though we may seem disqualified.", 
                "num": 7
              }, 
              {
                "text": "For we can do nothing against the truth, but for the truth.", 
                "num": 8
              }, 
              {
                "text": "For we are glad when we are weak and you are strong. And this also we pray, that you may be made complete.", 
                "num": 9
              }, 
              {
                "text": "Therefore I write these things being absent, lest being present I should use sharpness, according to the authority which the Lord has given me for edification and not for destruction.", 
                "num": 10
              }, 
              {
                "text": "Finally, brethren, farewell. Become complete. Be of good comfort, be of one mind, live in peace; and the God of love and peace will be with you.", 
                "num": 11
              }, 
              {
                "text": "Greet one another with a holy kiss.", 
                "num": 12
              }, 
              {
                "text": "All the saints greet you.", 
                "num": 13
              }, 
              {
                "text": "The grace of the Lord Jesus Christ, and the love of God, and the communion of the Holy Spirit be with you all. Amen.", 
                "num": 14
              }
            ], 
            "num": 13
          }
        ]
      }, 
      {
        "name": "Galatians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle (not from men nor through man, but through Jesus Christ and God the Father who raised Him from the dead),", 
                "num": 1
              }, 
              {
                "text": "and all the brethren who are with me, To the churches of Galatia:", 
                "num": 2
              }, 
              {
                "text": "Grace to you and peace from God the Father and our Lord Jesus Christ,", 
                "num": 3
              }, 
              {
                "text": "who gave Himself for our sins, that He might deliver us from this present evil age, according to the will of our God and Father,", 
                "num": 4
              }, 
              {
                "text": "to whom be glory forever and ever. Amen.", 
                "num": 5
              }, 
              {
                "text": "I marvel that you are turning away so soon from Him who called you in the grace of Christ, to a different gospel,", 
                "num": 6
              }, 
              {
                "text": "which is not another; but there are some who trouble you and want to pervert the gospel of Christ.", 
                "num": 7
              }, 
              {
                "text": "But even if we, or an angel from heaven, preach any other gospel to you than what we have preached to you, let him be accursed.", 
                "num": 8
              }, 
              {
                "text": "As we have said before, so now I say again, if anyone preaches any other gospel to you than what you have received, let him be accursed.", 
                "num": 9
              }, 
              {
                "text": "For do I now persuade men, or God? Or do I seek to please men? For if I still pleased men, I would not be a bondservant of Christ.", 
                "num": 10
              }, 
              {
                "text": "But I make known to you, brethren, that the gospel which was preached by me is not according to man.", 
                "num": 11
              }, 
              {
                "text": "For I neither received it from man, nor was I taught it, but it came through the revelation of Jesus Christ.", 
                "num": 12
              }, 
              {
                "text": "For you have heard of my former conduct in Judaism, how I persecuted the church of God beyond measure and tried to destroy it.", 
                "num": 13
              }, 
              {
                "text": "And I advanced in Judaism beyond many of my contemporaries in my own nation, being more exceedingly zealous for the traditions of my fathers.", 
                "num": 14
              }, 
              {
                "text": "But when it pleased God, who separated me from my mother's womb and called me through His grace,", 
                "num": 15
              }, 
              {
                "text": "to reveal His Son in me, that I might preach Him among the Gentiles, I did not immediately confer with flesh and blood,", 
                "num": 16
              }, 
              {
                "text": "nor did I go up to Jerusalem to those who were apostles before me; but I went to Arabia, and returned again to Damascus.", 
                "num": 17
              }, 
              {
                "text": "Then after three years I went up to Jerusalem to see Peter, and remained with him fifteen days.", 
                "num": 18
              }, 
              {
                "text": "But I saw none of the other apostles except James, the Lord's brother.", 
                "num": 19
              }, 
              {
                "text": "(Now concerning the things which I write to you, indeed, before God, I do not lie.)", 
                "num": 20
              }, 
              {
                "text": "Afterward I went into the regions of Syria and Cilicia.", 
                "num": 21
              }, 
              {
                "text": "And I was unknown by face to the churches of Judea which were in Christ.", 
                "num": 22
              }, 
              {
                "text": "But they were hearing only, \"He who formerly persecuted us now preaches the faith which he once tried to destroy.\"", 
                "num": 23
              }, 
              {
                "text": "And they glorified God in me.", 
                "num": 24
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Then after fourteen years I went up again to Jerusalem with Barnabas, and also took Titus with me.", 
                "num": 1
              }, 
              {
                "text": "And I went up by revelation, and communicated to them that gospel which I preach among the Gentiles, but privately to those who were of reputation, lest by any means I might run, or had run, in vain.", 
                "num": 2
              }, 
              {
                "text": "Yet not even Titus who was with me, being a Greek, was compelled to be circumcised.", 
                "num": 3
              }, 
              {
                "text": "And this occurred because of false brethren secretly brought in (who came in by stealth to spy out our liberty which we have in Christ Jesus, that they might bring us into bondage),", 
                "num": 4
              }, 
              {
                "text": "to whom we did not yield submission even for an hour, that the truth of the gospel might continue with you.", 
                "num": 5
              }, 
              {
                "text": "But from those who seemed to be something--whatever they were, it makes no difference to me; God shows personal favoritism to no man--for those who seemed to be something added nothing to me.", 
                "num": 6
              }, 
              {
                "text": "But on the contrary, when they saw that the gospel for the uncircumcised had been committed to me, as the gospel for the circumcised was to Peter", 
                "num": 7
              }, 
              {
                "text": "(for He who worked effectively in Peter for the apostleship to the circumcised also worked effectively in me toward the Gentiles),", 
                "num": 8
              }, 
              {
                "text": "and when James, Cephas, and John, who seemed to be pillars, perceived the grace that had been given to me, they gave me and Barnabas the right hand of fellowship, that we should go to the Gentiles and they to the circumcised.", 
                "num": 9
              }, 
              {
                "text": "They desired only that we should remember the poor, the very thing which I also was eager to do.", 
                "num": 10
              }, 
              {
                "text": "Now when Peter had come to Antioch, I withstood him to his face, because he was to be blamed;", 
                "num": 11
              }, 
              {
                "text": "for before certain men came from James, he would eat with the Gentiles; but when they came, he withdrew and separated himself, fearing those who were of the circumcision.", 
                "num": 12
              }, 
              {
                "text": "And the rest of the Jews also played the hypocrite with him, so that even Barnabas was carried away with their hypocrisy.", 
                "num": 13
              }, 
              {
                "text": "But when I saw that they were not straightforward about the truth of the gospel, I said to Peter before them all, \"If you, being a Jew, live in the manner of Gentiles and not as the Jews, why do you compel Gentiles to live as Jews?", 
                "num": 14
              }, 
              {
                "text": "We who are Jews by nature, and not sinners of the Gentiles,", 
                "num": 15
              }, 
              {
                "text": "knowing that a man is not justified by the works of the law but by faith in Jesus Christ, even we have believed in Christ Jesus, that we might be justified by faith in Christ and not by the works of the law; for by the works of the law no flesh shall be justified.", 
                "num": 16
              }, 
              {
                "text": "\"But if, while we seek to be justified by Christ, we ourselves also are found sinners, is Christ therefore a minister of sin? Certainly not!", 
                "num": 17
              }, 
              {
                "text": "For if I build again those things which I destroyed, I make myself a transgressor.", 
                "num": 18
              }, 
              {
                "text": "For I through the law died to the law that I might live to God.", 
                "num": 19
              }, 
              {
                "text": "I have been crucified with Christ; it is no longer I who live, but Christ lives in me; and the life which I now live in the flesh I live by faith in the Son of God, who loved me and gave Himself for me.", 
                "num": 20
              }, 
              {
                "text": "I do not set aside the grace of God; for if righteousness comes through the law, then Christ died in vain.\"", 
                "num": 21
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "O foolish Galatians! Who has bewitched you that you should not obey the truth, before whose eyes Jesus Christ was clearly portrayed among you as crucified?", 
                "num": 1
              }, 
              {
                "text": "This only I want to learn from you: Did you receive the Spirit by the works of the law, or by the hearing of faith?", 
                "num": 2
              }, 
              {
                "text": "Are you so foolish? Having begun in the Spirit, are you now being made perfect by the flesh?", 
                "num": 3
              }, 
              {
                "text": "Have you suffered so many things in vain--if indeed it was in vain?", 
                "num": 4
              }, 
              {
                "text": "Therefore He who supplies the Spirit to you and works miracles among you, does He do it by the works of the law, or by the hearing of faith?--", 
                "num": 5
              }, 
              {
                "text": "just as Abraham \"believed God, and it was accounted to him for righteousness.\"", 
                "num": 6
              }, 
              {
                "text": "Therefore know that only those who are of faith are sons of Abraham.", 
                "num": 7
              }, 
              {
                "text": "And the Scripture, foreseeing that God would justify the Gentiles by faith, preached the gospel to Abraham beforehand, saying, \"In you all the nations shall be blessed.\"", 
                "num": 8
              }, 
              {
                "text": "So then those who are of faith are blessed with believing Abraham.", 
                "num": 9
              }, 
              {
                "text": "For as many as are of the works of the law are under the curse; for it is written, \"Cursed is everyone who does not continue in all things which are written in the book of the law, to do them.\"", 
                "num": 10
              }, 
              {
                "text": "But that no one is justified by the law in the sight of God is evident, for \"the just shall live by faith.\"", 
                "num": 11
              }, 
              {
                "text": "Yet the law is not of faith, but \"the man who does them shall live by them.\"", 
                "num": 12
              }, 
              {
                "text": "Christ has redeemed us from the curse of the law, having become a curse for us (for it is written, \"Cursed is everyone who hangs on a tree\"),", 
                "num": 13
              }, 
              {
                "text": "that the blessing of Abraham might come upon the Gentiles in Christ Jesus, that we might receive the promise of the Spirit through faith.", 
                "num": 14
              }, 
              {
                "text": "Brethren, I speak in the manner of men: Though it is only a man's covenant, yet if it is confirmed, no one annuls or adds to it.", 
                "num": 15
              }, 
              {
                "text": "Now to Abraham and his Seed were the promises made. He does not say, \"And to seeds,\" as of many, but as of one, \"And to your Seed,\" who is Christ.", 
                "num": 16
              }, 
              {
                "text": "And this I say, that the law, which was four hundred and thirty years later, cannot annul the covenant that was confirmed before by God in Christ, that it should make the promise of no effect.", 
                "num": 17
              }, 
              {
                "text": "For if the inheritance is of the law, it is no longer of promise; but God gave it to Abraham by promise.", 
                "num": 18
              }, 
              {
                "text": "What purpose then does the law serve? It was added because of transgressions, till the Seed should come to whom the promise was made; and it was appointed through angels by the hand of a mediator.", 
                "num": 19
              }, 
              {
                "text": "Now a mediator does not mediate for one only, but God is one.", 
                "num": 20
              }, 
              {
                "text": "Is the law then against the promises of God? Certainly not! For if there had been a law given which could have given life, truly righteousness would have been by the law.", 
                "num": 21
              }, 
              {
                "text": "But the Scripture has confined all under sin, that the promise by faith in Jesus Christ might be given to those who believe.", 
                "num": 22
              }, 
              {
                "text": "But before faith came, we were kept under guard by the law, kept for the faith which would afterward be revealed.", 
                "num": 23
              }, 
              {
                "text": "Therefore the law was our tutor to bring us to Christ, that we might be justified by faith.", 
                "num": 24
              }, 
              {
                "text": "But after faith has come, we are no longer under a tutor.", 
                "num": 25
              }, 
              {
                "text": "For you are all sons of God through faith in Christ Jesus.", 
                "num": 26
              }, 
              {
                "text": "For as many of you as were baptized into Christ have put on Christ.", 
                "num": 27
              }, 
              {
                "text": "There is neither Jew nor Greek, there is neither slave nor free, there is neither male nor female; for you are all one in Christ Jesus.", 
                "num": 28
              }, 
              {
                "text": "And if you are Christ's, then you are Abraham's seed, and heirs according to the promise.", 
                "num": 29
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now I say that the heir, as long as he is a child, does not differ at all from a slave, though he is master of all,", 
                "num": 1
              }, 
              {
                "text": "but is under guardians and stewards until the time appointed by the father.", 
                "num": 2
              }, 
              {
                "text": "Even so we, when we were children, were in bondage under the elements of the world.", 
                "num": 3
              }, 
              {
                "text": "But when the fullness of the time had come, God sent forth His Son, born of a woman, born under the law,", 
                "num": 4
              }, 
              {
                "text": "to redeem those who were under the law, that we might receive the adoption as sons.", 
                "num": 5
              }, 
              {
                "text": "And because you are sons, God has sent forth the Spirit of His Son into your hearts, crying out, \"Abba, Father!\"", 
                "num": 6
              }, 
              {
                "text": "Therefore you are no longer a slave but a son, and if a son, then an heir of God through Christ.", 
                "num": 7
              }, 
              {
                "text": "But then, indeed, when you did not know God, you served those which by nature are not gods.", 
                "num": 8
              }, 
              {
                "text": "But now after you have known God, or rather are known by God, how is it that you turn again to the weak and beggarly elements, to which you desire again to be in bondage?", 
                "num": 9
              }, 
              {
                "text": "You observe days and months and seasons and years.", 
                "num": 10
              }, 
              {
                "text": "I am afraid for you, lest I have labored for you in vain.", 
                "num": 11
              }, 
              {
                "text": "Brethren, I urge you to become like me, for I became like you. You have not injured me at all.", 
                "num": 12
              }, 
              {
                "text": "You know that because of physical infirmity I preached the gospel to you at the first.", 
                "num": 13
              }, 
              {
                "text": "And my trial which was in my flesh you did not despise or reject, but you received me as an angel of God, even as Christ Jesus.", 
                "num": 14
              }, 
              {
                "text": "What then was the blessing you enjoyed? For I bear you witness that, if possible, you would have plucked out your own eyes and given them to me.", 
                "num": 15
              }, 
              {
                "text": "Have I therefore become your enemy because I tell you the truth?", 
                "num": 16
              }, 
              {
                "text": "They zealously court you, but for no good; yes, they want to exclude you, that you may be zealous for them.", 
                "num": 17
              }, 
              {
                "text": "But it is good to be zealous in a good thing always, and not only when I am present with you.", 
                "num": 18
              }, 
              {
                "text": "My little children, for whom I labor in birth again until Christ is formed in you,", 
                "num": 19
              }, 
              {
                "text": "I would like to be present with you now and to change my tone; for I have doubts about you.", 
                "num": 20
              }, 
              {
                "text": "Tell me, you who desire to be under the law, do you not hear the law?", 
                "num": 21
              }, 
              {
                "text": "For it is written that Abraham had two sons: the one by a bondwoman, the other by a freewoman.", 
                "num": 22
              }, 
              {
                "text": "But he who was of the bondwoman was born according to the flesh, and he of the freewoman through promise,", 
                "num": 23
              }, 
              {
                "text": "which things are symbolic. For these are the two covenants: the one from Mount Sinai which gives birth to bondage, which is Hagar--", 
                "num": 24
              }, 
              {
                "text": "for this Hagar is Mount Sinai in Arabia, and corresponds to Jerusalem which now is, and is in bondage with her children--", 
                "num": 25
              }, 
              {
                "text": "but the Jerusalem above is free, which is the mother of us all.", 
                "num": 26
              }, 
              {
                "text": "For it is written: \"Rejoice, O barren, You who do not bear! Break forth and shout, You who are not in labor! For the desolate has many more children Than she who has a husband.\"", 
                "num": 27
              }, 
              {
                "text": "Now we, brethren, as Isaac was, are children of promise.", 
                "num": 28
              }, 
              {
                "text": "But, as he who was born according to the flesh then persecuted him who was born according to the Spirit, even so it is now.", 
                "num": 29
              }, 
              {
                "text": "Nevertheless what does the Scripture say? \"Cast out the bondwoman and her son, for the son of the bondwoman shall not be heir with the son of the freewoman.\"", 
                "num": 30
              }, 
              {
                "text": "So then, brethren, we are not children of the bondwoman but of the free.", 
                "num": 31
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Stand fast therefore in the liberty by which Christ has made us free, and do not be entangled again with a yoke of bondage.", 
                "num": 1
              }, 
              {
                "text": "Indeed I, Paul, say to you that if you become circumcised, Christ will profit you nothing.", 
                "num": 2
              }, 
              {
                "text": "And I testify again to every man who becomes circumcised that he is a debtor to keep the whole law.", 
                "num": 3
              }, 
              {
                "text": "You have become estranged from Christ, you who attempt to be justified by law; you have fallen from grace.", 
                "num": 4
              }, 
              {
                "text": "For we through the Spirit eagerly wait for the hope of righteousness by faith.", 
                "num": 5
              }, 
              {
                "text": "For in Christ Jesus neither circumcision nor uncircumcision avails anything, but faith working through love.", 
                "num": 6
              }, 
              {
                "text": "You ran well. Who hindered you from obeying the truth?", 
                "num": 7
              }, 
              {
                "text": "This persuasion does not come from Him who calls you.", 
                "num": 8
              }, 
              {
                "text": "A little leaven leavens the whole lump.", 
                "num": 9
              }, 
              {
                "text": "I have confidence in you, in the Lord, that you will have no other mind; but he who troubles you shall bear his judgment, whoever he is.", 
                "num": 10
              }, 
              {
                "text": "And I, brethren, if I still preach circumcision, why do I still suffer persecution? Then the offense of the cross has ceased.", 
                "num": 11
              }, 
              {
                "text": "I could wish that those who trouble you would even cut themselves off!", 
                "num": 12
              }, 
              {
                "text": "For you, brethren, have been called to liberty; only do not use liberty as an opportunity for the flesh, but through love serve one another.", 
                "num": 13
              }, 
              {
                "text": "For all the law is fulfilled in one word, even in this: \"You shall love your neighbor as yourself.\"", 
                "num": 14
              }, 
              {
                "text": "But if you bite and devour one another, beware lest you be consumed by one another!", 
                "num": 15
              }, 
              {
                "text": "I say then: Walk in the Spirit, and you shall not fulfill the lust of the flesh.", 
                "num": 16
              }, 
              {
                "text": "For the flesh lusts against the Spirit, and the Spirit against the flesh; and these are contrary to one another, so that you do not do the things that you wish.", 
                "num": 17
              }, 
              {
                "text": "But if you are led by the Spirit, you are not under the law.", 
                "num": 18
              }, 
              {
                "text": "Now the works of the flesh are evident, which are: adultery, fornication, uncleanness, lewdness,", 
                "num": 19
              }, 
              {
                "text": "idolatry, sorcery, hatred, contentions, jealousies, outbursts of wrath, selfish ambitions, dissensions, heresies,", 
                "num": 20
              }, 
              {
                "text": "envy, murders, drunkenness, revelries, and the like; of which I tell you beforehand, just as I also told you in time past, that those who practice such things will not inherit the kingdom of God.", 
                "num": 21
              }, 
              {
                "text": "But the fruit of the Spirit is love, joy, peace, longsuffering, kindness, goodness, faithfulness,", 
                "num": 22
              }, 
              {
                "text": "gentleness, self-control. Against such there is no law.", 
                "num": 23
              }, 
              {
                "text": "And those who are Christ's have crucified the flesh with its passions and desires.", 
                "num": 24
              }, 
              {
                "text": "If we live in the Spirit, let us also walk in the Spirit.", 
                "num": 25
              }, 
              {
                "text": "Let us not become conceited, provoking one another, envying one another.", 
                "num": 26
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Brethren, if a man is overtaken in any trespass, you who are spiritual restore such a one in a spirit of gentleness, considering yourself lest you also be tempted.", 
                "num": 1
              }, 
              {
                "text": "Bear one another's burdens, and so fulfill the law of Christ.", 
                "num": 2
              }, 
              {
                "text": "For if anyone thinks himself to be something, when he is nothing, he deceives himself.", 
                "num": 3
              }, 
              {
                "text": "But let each one examine his own work, and then he will have rejoicing in himself alone, and not in another.", 
                "num": 4
              }, 
              {
                "text": "For each one shall bear his own load.", 
                "num": 5
              }, 
              {
                "text": "Let him who is taught the word share in all good things with him who teaches.", 
                "num": 6
              }, 
              {
                "text": "Do not be deceived, God is not mocked; for whatever a man sows, that he will also reap.", 
                "num": 7
              }, 
              {
                "text": "For he who sows to his flesh will of the flesh reap corruption, but he who sows to the Spirit will of the Spirit reap everlasting life.", 
                "num": 8
              }, 
              {
                "text": "And let us not grow weary while doing good, for in due season we shall reap if we do not lose heart.", 
                "num": 9
              }, 
              {
                "text": "Therefore, as we have opportunity, let us do good to all, especially to those who are of the household of faith.", 
                "num": 10
              }, 
              {
                "text": "See with what large letters I have written to you with my own hand!", 
                "num": 11
              }, 
              {
                "text": "As many as desire to make a good showing in the flesh, these would compel you to be circumcised, only that they may not suffer persecution for the cross of Christ.", 
                "num": 12
              }, 
              {
                "text": "For not even those who are circumcised keep the law, but they desire to have you circumcised that they may boast in your flesh.", 
                "num": 13
              }, 
              {
                "text": "But God forbid that I should boast except in the cross of our Lord Jesus Christ, by whom the world has been crucified to me, and I to the world.", 
                "num": 14
              }, 
              {
                "text": "For in Christ Jesus neither circumcision nor uncircumcision avails anything, but a new creation.", 
                "num": 15
              }, 
              {
                "text": "And as many as walk according to this rule, peace and mercy be upon them, and upon the Israel of God.", 
                "num": 16
              }, 
              {
                "text": "From now on let no one trouble me, for I bear in my body the marks of the Lord Jesus.", 
                "num": 17
              }, 
              {
                "text": "Brethren, the grace of our Lord Jesus Christ be with your spirit. Amen.", 
                "num": 18
              }
            ], 
            "num": 6
          }
        ]
      }, 
      {
        "name": "Ephesians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle of Jesus Christ by the will of God, To the saints who are in Ephesus, and faithful in Christ Jesus:", 
                "num": 1
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 2
              }, 
              {
                "text": "Blessed be the God and Father of our Lord Jesus Christ, who has blessed us with every spiritual blessing in the heavenly places in Christ,", 
                "num": 3
              }, 
              {
                "text": "just as He chose us in Him before the foundation of the world, that we should be holy and without blame before Him in love,", 
                "num": 4
              }, 
              {
                "text": "having predestined us to adoption as sons by Jesus Christ to Himself, according to the good pleasure of His will,", 
                "num": 5
              }, 
              {
                "text": "to the praise of the glory of His grace, by which He made us accepted in the Beloved.", 
                "num": 6
              }, 
              {
                "text": "In Him we have redemption through His blood, the forgiveness of sins, according to the riches of His grace", 
                "num": 7
              }, 
              {
                "text": "which He made to abound toward us in all wisdom and prudence,", 
                "num": 8
              }, 
              {
                "text": "having made known to us the mystery of His will, according to His good pleasure which He purposed in Himself,", 
                "num": 9
              }, 
              {
                "text": "that in the dispensation of the fullness of the times He might gather together in one all things in Christ, both which are in heaven and which are on earth--in Him.", 
                "num": 10
              }, 
              {
                "text": "In Him also we have obtained an inheritance, being predestined according to the purpose of Him who works all things according to the counsel of His will,", 
                "num": 11
              }, 
              {
                "text": "that we who first trusted in Christ should be to the praise of His glory.", 
                "num": 12
              }, 
              {
                "text": "In Him you also trusted, after you heard the word of truth, the gospel of your salvation; in whom also, having believed, you were sealed with the Holy Spirit of promise,", 
                "num": 13
              }, 
              {
                "text": "who is the guarantee of our inheritance until the redemption of the purchased possession, to the praise of His glory.", 
                "num": 14
              }, 
              {
                "text": "Therefore I also, after I heard of your faith in the Lord Jesus and your love for all the saints,", 
                "num": 15
              }, 
              {
                "text": "do not cease to give thanks for you, making mention of you in my prayers:", 
                "num": 16
              }, 
              {
                "text": "that the God of our Lord Jesus Christ, the Father of glory, may give to you the spirit of wisdom and revelation in the knowledge of Him,", 
                "num": 17
              }, 
              {
                "text": "the eyes of your understanding being enlightened; that you may know what is the hope of His calling, what are the riches of the glory of His inheritance in the saints,", 
                "num": 18
              }, 
              {
                "text": "and what is the exceeding greatness of His power toward us who believe, according to the working of His mighty power", 
                "num": 19
              }, 
              {
                "text": "which He worked in Christ when He raised Him from the dead and seated Him at His right hand in the heavenly places,", 
                "num": 20
              }, 
              {
                "text": "far above all principality and power and might and dominion, and every name that is named, not only in this age but also in that which is to come.", 
                "num": 21
              }, 
              {
                "text": "And He put all things under His feet, and gave Him to be head over all things to the church,", 
                "num": 22
              }, 
              {
                "text": "which is His body, the fullness of Him who fills all in all.", 
                "num": 23
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "And you He made alive, who were dead in trespasses and sins,", 
                "num": 1
              }, 
              {
                "text": "in which you once walked according to the course of this world, according to the prince of the power of the air, the spirit who now works in the sons of disobedience,", 
                "num": 2
              }, 
              {
                "text": "among whom also we all once conducted ourselves in the lusts of our flesh, fulfilling the desires of the flesh and of the mind, and were by nature children of wrath, just as the others.", 
                "num": 3
              }, 
              {
                "text": "But God, who is rich in mercy, because of His great love with which He loved us,", 
                "num": 4
              }, 
              {
                "text": "even when we were dead in trespasses, made us alive together with Christ (by grace you have been saved),", 
                "num": 5
              }, 
              {
                "text": "and raised us up together, and made us sit together in the heavenly places in Christ Jesus,", 
                "num": 6
              }, 
              {
                "text": "that in the ages to come He might show the exceeding riches of His grace in His kindness toward us in Christ Jesus.", 
                "num": 7
              }, 
              {
                "text": "For by grace you have been saved through faith, and that not of yourselves; it is the gift of God,", 
                "num": 8
              }, 
              {
                "text": "not of works, lest anyone should boast.", 
                "num": 9
              }, 
              {
                "text": "For we are His workmanship, created in Christ Jesus for good works, which God prepared beforehand that we should walk in them.", 
                "num": 10
              }, 
              {
                "text": "Therefore remember that you, once Gentiles in the flesh--who are called Uncircumcision by what is called the Circumcision made in the flesh by hands--", 
                "num": 11
              }, 
              {
                "text": "that at that time you were without Christ, being aliens from the commonwealth of Israel and strangers from the covenants of promise, having no hope and without God in the world.", 
                "num": 12
              }, 
              {
                "text": "But now in Christ Jesus you who once were far off have been brought near by the blood of Christ.", 
                "num": 13
              }, 
              {
                "text": "For He Himself is our peace, who has made both one, and has broken down the middle wall of separation,", 
                "num": 14
              }, 
              {
                "text": "having abolished in His flesh the enmity, that is, the law of commandments contained in ordinances, so as to create in Himself one new man from the two, thus making peace,", 
                "num": 15
              }, 
              {
                "text": "and that He might reconcile them both to God in one body through the cross, thereby putting to death the enmity.", 
                "num": 16
              }, 
              {
                "text": "And He came and preached peace to you who were afar off and to those who were near.", 
                "num": 17
              }, 
              {
                "text": "For through Him we both have access by one Spirit to the Father.", 
                "num": 18
              }, 
              {
                "text": "Now, therefore, you are no longer strangers and foreigners, but fellow citizens with the saints and members of the household of God,", 
                "num": 19
              }, 
              {
                "text": "having been built on the foundation of the apostles and prophets, Jesus Christ Himself being the chief cornerstone,", 
                "num": 20
              }, 
              {
                "text": "in whom the whole building, being fitted together, grows into a holy temple in the Lord,", 
                "num": 21
              }, 
              {
                "text": "in whom you also are being built together for a dwelling place of God in the Spirit.", 
                "num": 22
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "For this reason I, Paul, the prisoner of Christ Jesus for you Gentiles--", 
                "num": 1
              }, 
              {
                "text": "if indeed you have heard of the dispensation of the grace of God which was given to me for you,", 
                "num": 2
              }, 
              {
                "text": "how that by revelation He made known to me the mystery (as I have briefly written already,", 
                "num": 3
              }, 
              {
                "text": "by which, when you read, you may understand my knowledge in the mystery of Christ),", 
                "num": 4
              }, 
              {
                "text": "which in other ages was not made known to the sons of men, as it has now been revealed by the Spirit to His holy apostles and prophets:", 
                "num": 5
              }, 
              {
                "text": "that the Gentiles should be fellow heirs, of the same body, and partakers of His promise in Christ through the gospel,", 
                "num": 6
              }, 
              {
                "text": "of which I became a minister according to the gift of the grace of God given to me by the effective working of His power.", 
                "num": 7
              }, 
              {
                "text": "To me, who am less than the least of all the saints, this grace was given, that I should preach among the Gentiles the unsearchable riches of Christ,", 
                "num": 8
              }, 
              {
                "text": "and to make all see what is the fellowship of the mystery, which from the beginning of the ages has been hidden in God who created all things through Jesus Christ;", 
                "num": 9
              }, 
              {
                "text": "to the intent that now the manifold wisdom of God might be made known by the church to the principalities and powers in the heavenly places,", 
                "num": 10
              }, 
              {
                "text": "according to the eternal purpose which He accomplished in Christ Jesus our Lord,", 
                "num": 11
              }, 
              {
                "text": "in whom we have boldness and access with confidence through faith in Him.", 
                "num": 12
              }, 
              {
                "text": "Therefore I ask that you do not lose heart at my tribulations for you, which is your glory.", 
                "num": 13
              }, 
              {
                "text": "For this reason I bow my knees to the Father of our Lord Jesus Christ,", 
                "num": 14
              }, 
              {
                "text": "from whom the whole family in heaven and earth is named,", 
                "num": 15
              }, 
              {
                "text": "that He would grant you, according to the riches of His glory, to be strengthened with might through His Spirit in the inner man,", 
                "num": 16
              }, 
              {
                "text": "that Christ may dwell in your hearts through faith; that you, being rooted and grounded in love,", 
                "num": 17
              }, 
              {
                "text": "may be able to comprehend with all the saints what is the width and length and depth and height--", 
                "num": 18
              }, 
              {
                "text": "to know the love of Christ which passes knowledge; that you may be filled with all the fullness of God.", 
                "num": 19
              }, 
              {
                "text": "Now to Him who is able to do exceedingly abundantly above all that we ask or think, according to the power that works in us,", 
                "num": 20
              }, 
              {
                "text": "to Him be glory in the church by Christ Jesus to all generations, forever and ever. Amen.", 
                "num": 21
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "I, therefore, the prisoner of the Lord, beseech you to walk worthy of the calling with which you were called,", 
                "num": 1
              }, 
              {
                "text": "with all lowliness and gentleness, with longsuffering, bearing with one another in love,", 
                "num": 2
              }, 
              {
                "text": "endeavoring to keep the unity of the Spirit in the bond of peace.", 
                "num": 3
              }, 
              {
                "text": "There is one body and one Spirit, just as you were called in one hope of your calling;", 
                "num": 4
              }, 
              {
                "text": "one Lord, one faith, one baptism;", 
                "num": 5
              }, 
              {
                "text": "one God and Father of all, who is above all, and through all, and in you all.", 
                "num": 6
              }, 
              {
                "text": "But to each one of us grace was given according to the measure of Christ's gift.", 
                "num": 7
              }, 
              {
                "text": "Therefore He says: \"When He ascended on high, He led captivity captive, And gave gifts to men.\"", 
                "num": 8
              }, 
              {
                "text": "(Now this, \"He ascended\"--what does it mean but that He also first descended into the lower parts of the earth?", 
                "num": 9
              }, 
              {
                "text": "He who descended is also the One who ascended far above all the heavens, that He might fill all things.)", 
                "num": 10
              }, 
              {
                "text": "And He Himself gave some to be apostles, some prophets, some evangelists, and some pastors and teachers,", 
                "num": 11
              }, 
              {
                "text": "for the equipping of the saints for the work of ministry, for the edifying of the body of Christ,", 
                "num": 12
              }, 
              {
                "text": "till we all come to the unity of the faith and of the knowledge of the Son of God, to a perfect man, to the measure of the stature of the fullness of Christ;", 
                "num": 13
              }, 
              {
                "text": "that we should no longer be children, tossed to and fro and carried about with every wind of doctrine, by the trickery of men, in the cunning craftiness of deceitful plotting,", 
                "num": 14
              }, 
              {
                "text": "but, speaking the truth in love, may grow up in all things into Him who is the head--Christ--", 
                "num": 15
              }, 
              {
                "text": "from whom the whole body, joined and knit together by what every joint supplies, according to the effective working by which every part does its share, causes growth of the body for the edifying of itself in love.", 
                "num": 16
              }, 
              {
                "text": "This I say, therefore, and testify in the Lord, that you should no longer walk as the rest of the Gentiles walk, in the futility of their mind,", 
                "num": 17
              }, 
              {
                "text": "having their understanding darkened, being alienated from the life of God, because of the ignorance that is in them, because of the blindness of their heart;", 
                "num": 18
              }, 
              {
                "text": "who, being past feeling, have given themselves over to lewdness, to work all uncleanness with greediness.", 
                "num": 19
              }, 
              {
                "text": "But you have not so learned Christ,", 
                "num": 20
              }, 
              {
                "text": "if indeed you have heard Him and have been taught by Him, as the truth is in Jesus:", 
                "num": 21
              }, 
              {
                "text": "that you put off, concerning your former conduct, the old man which grows corrupt according to the deceitful lusts,", 
                "num": 22
              }, 
              {
                "text": "and be renewed in the spirit of your mind,", 
                "num": 23
              }, 
              {
                "text": "and that you put on the new man which was created according to God, in true righteousness and holiness.", 
                "num": 24
              }, 
              {
                "text": "Therefore, putting away lying, \"Let each one of you speak truth with his neighbor,\" for we are members of one another.", 
                "num": 25
              }, 
              {
                "text": "\"Be angry, and do not sin\": do not let the sun go down on your wrath,", 
                "num": 26
              }, 
              {
                "text": "nor give place to the devil.", 
                "num": 27
              }, 
              {
                "text": "Let him who stole steal no longer, but rather let him labor, working with his hands what is good, that he may have something to give him who has need.", 
                "num": 28
              }, 
              {
                "text": "Let no corrupt word proceed out of your mouth, but what is good for necessary edification, that it may impart grace to the hearers.", 
                "num": 29
              }, 
              {
                "text": "And do not grieve the Holy Spirit of God, by whom you were sealed for the day of redemption.", 
                "num": 30
              }, 
              {
                "text": "Let all bitterness, wrath, anger, clamor, and evil speaking be put away from you, with all malice.", 
                "num": 31
              }, 
              {
                "text": "And be kind to one another, tenderhearted, forgiving one another, even as God in Christ forgave you.", 
                "num": 32
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Therefore be imitators of God as dear children.", 
                "num": 1
              }, 
              {
                "text": "And walk in love, as Christ also has loved us and given Himself for us, an offering and a sacrifice to God for a sweet-smelling aroma.", 
                "num": 2
              }, 
              {
                "text": "But fornication and all uncleanness or covetousness, let it not even be named among you, as is fitting for saints;", 
                "num": 3
              }, 
              {
                "text": "neither filthiness, nor foolish talking, nor coarse jesting, which are not fitting, but rather giving of thanks.", 
                "num": 4
              }, 
              {
                "text": "For this you know, that no fornicator, unclean person, nor covetous man, who is an idolater, has any inheritance in the kingdom of Christ and God.", 
                "num": 5
              }, 
              {
                "text": "Let no one deceive you with empty words, for because of these things the wrath of God comes upon the sons of disobedience.", 
                "num": 6
              }, 
              {
                "text": "Therefore do not be partakers with them.", 
                "num": 7
              }, 
              {
                "text": "For you were once darkness, but now you are light in the Lord. Walk as children of light", 
                "num": 8
              }, 
              {
                "text": "(for the fruit of the Spirit is in all goodness, righteousness, and truth),", 
                "num": 9
              }, 
              {
                "text": "finding out what is acceptable to the Lord.", 
                "num": 10
              }, 
              {
                "text": "And have no fellowship with the unfruitful works of darkness, but rather expose them.", 
                "num": 11
              }, 
              {
                "text": "For it is shameful even to speak of those things which are done by them in secret.", 
                "num": 12
              }, 
              {
                "text": "But all things that are exposed are made manifest by the light, for whatever makes manifest is light.", 
                "num": 13
              }, 
              {
                "text": "Therefore He says: \"Awake, you who sleep, Arise from the dead, And Christ will give you light.\"", 
                "num": 14
              }, 
              {
                "text": "See then that you walk circumspectly, not as fools but as wise,", 
                "num": 15
              }, 
              {
                "text": "redeeming the time, because the days are evil.", 
                "num": 16
              }, 
              {
                "text": "Therefore do not be unwise, but understand what the will of the Lord is.", 
                "num": 17
              }, 
              {
                "text": "And do not be drunk with wine, in which is dissipation; but be filled with the Spirit,", 
                "num": 18
              }, 
              {
                "text": "speaking to one another in psalms and hymns and spiritual songs, singing and making melody in your heart to the Lord,", 
                "num": 19
              }, 
              {
                "text": "giving thanks always for all things to God the Father in the name of our Lord Jesus Christ,", 
                "num": 20
              }, 
              {
                "text": "submitting to one another in the fear of God.", 
                "num": 21
              }, 
              {
                "text": "Wives, submit to your own husbands, as to the Lord.", 
                "num": 22
              }, 
              {
                "text": "For the husband is head of the wife, as also Christ is head of the church; and He is the Savior of the body.", 
                "num": 23
              }, 
              {
                "text": "Therefore, just as the church is subject to Christ, so let the wives be to their own husbands in everything.", 
                "num": 24
              }, 
              {
                "text": "Husbands, love your wives, just as Christ also loved the church and gave Himself for her,", 
                "num": 25
              }, 
              {
                "text": "that He might sanctify and cleanse her with the washing of water by the word,", 
                "num": 26
              }, 
              {
                "text": "that He might present her to Himself a glorious church, not having spot or wrinkle or any such thing, but that she should be holy and without blemish.", 
                "num": 27
              }, 
              {
                "text": "So husbands ought to love their own wives as their own bodies; he who loves his wife loves himself.", 
                "num": 28
              }, 
              {
                "text": "For no one ever hated his own flesh, but nourishes and cherishes it, just as the Lord does the church.", 
                "num": 29
              }, 
              {
                "text": "For we are members of His body, of His flesh and of His bones.", 
                "num": 30
              }, 
              {
                "text": "\"For this reason a man shall leave his father and mother and be joined to his wife, and the two shall become one flesh.\"", 
                "num": 31
              }, 
              {
                "text": "This is a great mystery, but I speak concerning Christ and the church.", 
                "num": 32
              }, 
              {
                "text": "Nevertheless let each one of you in particular so love his own wife as himself, and let the wife see that she respects her husband.", 
                "num": 33
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Children, obey your parents in the Lord, for this is right.", 
                "num": 1
              }, 
              {
                "text": "\"Honor your father and mother,\" which is the first commandment with promise:", 
                "num": 2
              }, 
              {
                "text": "\"that it may be well with you and you may live long on the earth.\"", 
                "num": 3
              }, 
              {
                "text": "And you, fathers, do not provoke your children to wrath, but bring them up in the training and admonition of the Lord.", 
                "num": 4
              }, 
              {
                "text": "Bondservants, be obedient to those who are your masters according to the flesh, with fear and trembling, in sincerity of heart, as to Christ;", 
                "num": 5
              }, 
              {
                "text": "not with eyeservice, as men-pleasers, but as bondservants of Christ, doing the will of God from the heart,", 
                "num": 6
              }, 
              {
                "text": "with goodwill doing service, as to the Lord, and not to men,", 
                "num": 7
              }, 
              {
                "text": "knowing that whatever good anyone does, he will receive the same from the Lord, whether he is a slave or free.", 
                "num": 8
              }, 
              {
                "text": "And you, masters, do the same things to them, giving up threatening, knowing that your own Master also is in heaven, and there is no partiality with Him.", 
                "num": 9
              }, 
              {
                "text": "Finally, my brethren, be strong in the Lord and in the power of His might.", 
                "num": 10
              }, 
              {
                "text": "Put on the whole armor of God, that you may be able to stand against the wiles of the devil.", 
                "num": 11
              }, 
              {
                "text": "For we do not wrestle against flesh and blood, but against principalities, against powers, against the rulers of the darkness of this age, against spiritual hosts of wickedness in the heavenly places.", 
                "num": 12
              }, 
              {
                "text": "Therefore take up the whole armor of God, that you may be able to withstand in the evil day, and having done all, to stand.", 
                "num": 13
              }, 
              {
                "text": "Stand therefore, having girded your waist with truth, having put on the breastplate of righteousness,", 
                "num": 14
              }, 
              {
                "text": "and having shod your feet with the preparation of the gospel of peace;", 
                "num": 15
              }, 
              {
                "text": "above all, taking the shield of faith with which you will be able to quench all the fiery darts of the wicked one.", 
                "num": 16
              }, 
              {
                "text": "And take the helmet of salvation, and the sword of the Spirit, which is the word of God;", 
                "num": 17
              }, 
              {
                "text": "praying always with all prayer and supplication in the Spirit, being watchful to this end with all perseverance and supplication for all the saints--", 
                "num": 18
              }, 
              {
                "text": "and for me, that utterance may be given to me, that I may open my mouth boldly to make known the mystery of the gospel,", 
                "num": 19
              }, 
              {
                "text": "for which I am an ambassador in chains; that in it I may speak boldly, as I ought to speak.", 
                "num": 20
              }, 
              {
                "text": "But that you also may know my affairs and how I am doing, Tychicus, a beloved brother and faithful minister in the Lord, will make all things known to you;", 
                "num": 21
              }, 
              {
                "text": "whom I have sent to you for this very purpose, that you may know our affairs, and that he may comfort your hearts.", 
                "num": 22
              }, 
              {
                "text": "Peace to the brethren, and love with faith, from God the Father and the Lord Jesus Christ.", 
                "num": 23
              }, 
              {
                "text": "Grace be with all those who love our Lord Jesus Christ in sincerity. Amen.", 
                "num": 24
              }
            ], 
            "num": 6
          }
        ]
      }, 
      {
        "name": "Philippians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul and Timothy, bondservants of Jesus Christ, To all the saints in Christ Jesus who are in Philippi, with the bishops and deacons:", 
                "num": 1
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 2
              }, 
              {
                "text": "I thank my God upon every remembrance of you,", 
                "num": 3
              }, 
              {
                "text": "always in every prayer of mine making request for you all with joy,", 
                "num": 4
              }, 
              {
                "text": "for your fellowship in the gospel from the first day until now,", 
                "num": 5
              }, 
              {
                "text": "being confident of this very thing, that He who has begun a good work in you will complete it until the day of Jesus Christ;", 
                "num": 6
              }, 
              {
                "text": "just as it is right for me to think this of you all, because I have you in my heart, inasmuch as both in my chains and in the defense and confirmation of the gospel, you all are partakers with me of grace.", 
                "num": 7
              }, 
              {
                "text": "For God is my witness, how greatly I long for you all with the affection of Jesus Christ.", 
                "num": 8
              }, 
              {
                "text": "And this I pray, that your love may abound still more and more in knowledge and all discernment,", 
                "num": 9
              }, 
              {
                "text": "that you may approve the things that are excellent, that you may be sincere and without offense till the day of Christ,", 
                "num": 10
              }, 
              {
                "text": "being filled with the fruits of righteousness which are by Jesus Christ, to the glory and praise of God.", 
                "num": 11
              }, 
              {
                "text": "But I want you to know, brethren, that the things which happened to me have actually turned out for the furtherance of the gospel,", 
                "num": 12
              }, 
              {
                "text": "so that it has become evident to the whole palace guard, and to all the rest, that my chains are in Christ;", 
                "num": 13
              }, 
              {
                "text": "and most of the brethren in the Lord, having become confident by my chains, are much more bold to speak the word without fear.", 
                "num": 14
              }, 
              {
                "text": "Some indeed preach Christ even from envy and strife, and some also from goodwill:", 
                "num": 15
              }, 
              {
                "text": "The former preach Christ from selfish ambition, not sincerely, supposing to add affliction to my chains;", 
                "num": 16
              }, 
              {
                "text": "but the latter out of love, knowing that I am appointed for the defense of the gospel.", 
                "num": 17
              }, 
              {
                "text": "What then? Only that in every way, whether in pretense or in truth, Christ is preached; and in this I rejoice, yes, and will rejoice.", 
                "num": 18
              }, 
              {
                "text": "For I know that this will turn out for my deliverance through your prayer and the supply of the Spirit of Jesus Christ,", 
                "num": 19
              }, 
              {
                "text": "according to my earnest expectation and hope that in nothing I shall be ashamed, but with all boldness, as always, so now also Christ will be magnified in my body, whether by life or by death.", 
                "num": 20
              }, 
              {
                "text": "For to me, to live is Christ, and to die is gain.", 
                "num": 21
              }, 
              {
                "text": "But if I live on in the flesh, this will mean fruit from my labor; yet what I shall choose I cannot tell.", 
                "num": 22
              }, 
              {
                "text": "For I am hard-pressed between the two, having a desire to depart and be with Christ, which is far better.", 
                "num": 23
              }, 
              {
                "text": "Nevertheless to remain in the flesh is more needful for you.", 
                "num": 24
              }, 
              {
                "text": "And being confident of this, I know that I shall remain and continue with you all for your progress and joy of faith,", 
                "num": 25
              }, 
              {
                "text": "that your rejoicing for me may be more abundant in Jesus Christ by my coming to you again.", 
                "num": 26
              }, 
              {
                "text": "Only let your conduct be worthy of the gospel of Christ, so that whether I come and see you or am absent, I may hear of your affairs, that you stand fast in one spirit, with one mind striving together for the faith of the gospel,", 
                "num": 27
              }, 
              {
                "text": "and not in any way terrified by your adversaries, which is to them a proof of perdition, but to you of salvation, and that from God.", 
                "num": 28
              }, 
              {
                "text": "For to you it has been granted on behalf of Christ, not only to believe in Him, but also to suffer for His sake,", 
                "num": 29
              }, 
              {
                "text": "having the same conflict which you saw in me and now hear is in me.", 
                "num": 30
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Therefore if there is any consolation in Christ, if any comfort of love, if any fellowship of the Spirit, if any affection and mercy,", 
                "num": 1
              }, 
              {
                "text": "fulfill my joy by being like-minded, having the same love, being of one accord, of one mind.", 
                "num": 2
              }, 
              {
                "text": "Let nothing be done through selfish ambition or conceit, but in lowliness of mind let each esteem others better than himself.", 
                "num": 3
              }, 
              {
                "text": "Let each of you look out not only for his own interests, but also for the interests of others.", 
                "num": 4
              }, 
              {
                "text": "Let this mind be in you which was also in Christ Jesus,", 
                "num": 5
              }, 
              {
                "text": "who, being in the form of God, did not consider it robbery to be equal with God,", 
                "num": 6
              }, 
              {
                "text": "but made Himself of no reputation, taking the form of a bondservant, and coming in the likeness of men.", 
                "num": 7
              }, 
              {
                "text": "And being found in appearance as a man, He humbled Himself and became obedient to the point of death, even the death of the cross.", 
                "num": 8
              }, 
              {
                "text": "Therefore God also has highly exalted Him and given Him the name which is above every name,", 
                "num": 9
              }, 
              {
                "text": "that at the name of Jesus every knee should bow, of those in heaven, and of those on earth, and of those under the earth,", 
                "num": 10
              }, 
              {
                "text": "and that every tongue should confess that Jesus Christ is Lord, to the glory of God the Father.", 
                "num": 11
              }, 
              {
                "text": "Therefore, my beloved, as you have always obeyed, not as in my presence only, but now much more in my absence, work out your own salvation with fear and trembling;", 
                "num": 12
              }, 
              {
                "text": "for it is God who works in you both to will and to do for His good pleasure.", 
                "num": 13
              }, 
              {
                "text": "Do all things without complaining and disputing,", 
                "num": 14
              }, 
              {
                "text": "that you may become blameless and harmless, children of God without fault in the midst of a crooked and perverse generation, among whom you shine as lights in the world,", 
                "num": 15
              }, 
              {
                "text": "holding fast the word of life, so that I may rejoice in the day of Christ that I have not run in vain or labored in vain.", 
                "num": 16
              }, 
              {
                "text": "Yes, and if I am being poured out as a drink offering on the sacrifice and service of your faith, I am glad and rejoice with you all.", 
                "num": 17
              }, 
              {
                "text": "For the same reason you also be glad and rejoice with me.", 
                "num": 18
              }, 
              {
                "text": "But I trust in the Lord Jesus to send Timothy to you shortly, that I also may be encouraged when I know your state.", 
                "num": 19
              }, 
              {
                "text": "For I have no one like-minded, who will sincerely care for your state.", 
                "num": 20
              }, 
              {
                "text": "For all seek their own, not the things which are of Christ Jesus.", 
                "num": 21
              }, 
              {
                "text": "But you know his proven character, that as a son with his father he served with me in the gospel.", 
                "num": 22
              }, 
              {
                "text": "Therefore I hope to send him at once, as soon as I see how it goes with me.", 
                "num": 23
              }, 
              {
                "text": "But I trust in the Lord that I myself shall also come shortly.", 
                "num": 24
              }, 
              {
                "text": "Yet I considered it necessary to send to you Epaphroditus, my brother, fellow worker, and fellow soldier, but your messenger and the one who ministered to my need;", 
                "num": 25
              }, 
              {
                "text": "since he was longing for you all, and was distressed because you had heard that he was sick.", 
                "num": 26
              }, 
              {
                "text": "For indeed he was sick almost unto death; but God had mercy on him, and not only on him but on me also, lest I should have sorrow upon sorrow.", 
                "num": 27
              }, 
              {
                "text": "Therefore I sent him the more eagerly, that when you see him again you may rejoice, and I may be less sorrowful.", 
                "num": 28
              }, 
              {
                "text": "Receive him therefore in the Lord with all gladness, and hold such men in esteem;", 
                "num": 29
              }, 
              {
                "text": "because for the work of Christ he came close to death, not regarding his life, to supply what was lacking in your service toward me.", 
                "num": 30
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Finally, my brethren, rejoice in the Lord. For me to write the same things to you is not tedious, but for you it is safe.", 
                "num": 1
              }, 
              {
                "text": "Beware of dogs, beware of evil workers, beware of the mutilation!", 
                "num": 2
              }, 
              {
                "text": "For we are the circumcision, who worship God in the Spirit, rejoice in Christ Jesus, and have no confidence in the flesh,", 
                "num": 3
              }, 
              {
                "text": "though I also might have confidence in the flesh. If anyone else thinks he may have confidence in the flesh, I more so:", 
                "num": 4
              }, 
              {
                "text": "circumcised the eighth day, of the stock of Israel, of the tribe of Benjamin, a Hebrew of the Hebrews; concerning the law, a Pharisee;", 
                "num": 5
              }, 
              {
                "text": "concerning zeal, persecuting the church; concerning the righteousness which is in the law, blameless.", 
                "num": 6
              }, 
              {
                "text": "But what things were gain to me, these I have counted loss for Christ.", 
                "num": 7
              }, 
              {
                "text": "Yet indeed I also count all things loss for the excellence of the knowledge of Christ Jesus my Lord, for whom I have suffered the loss of all things, and count them as rubbish, that I may gain Christ", 
                "num": 8
              }, 
              {
                "text": "and be found in Him, not having my own righteousness, which is from the law, but that which is through faith in Christ, the righteousness which is from God by faith;", 
                "num": 9
              }, 
              {
                "text": "that I may know Him and the power of His resurrection, and the fellowship of His sufferings, being conformed to His death,", 
                "num": 10
              }, 
              {
                "text": "if, by any means, I may attain to the resurrection from the dead.", 
                "num": 11
              }, 
              {
                "text": "Not that I have already attained, or am already perfected; but I press on, that I may lay hold of that for which Christ Jesus has also laid hold of me.", 
                "num": 12
              }, 
              {
                "text": "Brethren, I do not count myself to have apprehended; but one thing I do, forgetting those things which are behind and reaching forward to those things which are ahead,", 
                "num": 13
              }, 
              {
                "text": "I press toward the goal for the prize of the upward call of God in Christ Jesus.", 
                "num": 14
              }, 
              {
                "text": "Therefore let us, as many as are mature, have this mind; and if in anything you think otherwise, God will reveal even this to you.", 
                "num": 15
              }, 
              {
                "text": "Nevertheless, to the degree that we have already attained, let us walk by the same rule, let us be of the same mind.", 
                "num": 16
              }, 
              {
                "text": "Brethren, join in following my example, and note those who so walk, as you have us for a pattern.", 
                "num": 17
              }, 
              {
                "text": "For many walk, of whom I have told you often, and now tell you even weeping, that they are the enemies of the cross of Christ:", 
                "num": 18
              }, 
              {
                "text": "whose end is destruction, whose god is their belly, and whose glory is in their shame--who set their mind on earthly things.", 
                "num": 19
              }, 
              {
                "text": "For our citizenship is in heaven, from which we also eagerly wait for the Savior, the Lord Jesus Christ,", 
                "num": 20
              }, 
              {
                "text": "who will transform our lowly body that it may be conformed to His glorious body, according to the working by which He is able even to subdue all things to Himself.", 
                "num": 21
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Therefore, my beloved and longed-for brethren, my joy and crown, so stand fast in the Lord, beloved.", 
                "num": 1
              }, 
              {
                "text": "I implore Euodia and I implore Syntyche to be of the same mind in the Lord.", 
                "num": 2
              }, 
              {
                "text": "And I urge you also, true companion, help these women who labored with me in the gospel, with Clement also, and the rest of my fellow workers, whose names are in the Book of Life.", 
                "num": 3
              }, 
              {
                "text": "Rejoice in the Lord always. Again I will say, rejoice!", 
                "num": 4
              }, 
              {
                "text": "Let your gentleness be known to all men. The Lord is at hand.", 
                "num": 5
              }, 
              {
                "text": "Be anxious for nothing, but in everything by prayer and supplication, with thanksgiving, let your requests be made known to God;", 
                "num": 6
              }, 
              {
                "text": "and the peace of God, which surpasses all understanding, will guard your hearts and minds through Christ Jesus.", 
                "num": 7
              }, 
              {
                "text": "Finally, brethren, whatever things are true, whatever things are noble, whatever things are just, whatever things are pure, whatever things are lovely, whatever things are of good report, if there is any virtue and if there is anything praiseworthy--meditate on these things.", 
                "num": 8
              }, 
              {
                "text": "The things which you learned and received and heard and saw in me, these do, and the God of peace will be with you.", 
                "num": 9
              }, 
              {
                "text": "But I rejoiced in the Lord greatly that now at last your care for me has flourished again; though you surely did care, but you lacked opportunity.", 
                "num": 10
              }, 
              {
                "text": "Not that I speak in regard to need, for I have learned in whatever state I am, to be content:", 
                "num": 11
              }, 
              {
                "text": "I know how to be abased, and I know how to abound. Everywhere and in all things I have learned both to be full and to be hungry, both to abound and to suffer need.", 
                "num": 12
              }, 
              {
                "text": "I can do all things through Christ who strengthens me.", 
                "num": 13
              }, 
              {
                "text": "Nevertheless you have done well that you shared in my distress.", 
                "num": 14
              }, 
              {
                "text": "Now you Philippians know also that in the beginning of the gospel, when I departed from Macedonia, no church shared with me concerning giving and receiving but you only.", 
                "num": 15
              }, 
              {
                "text": "For even in Thessalonica you sent aid once and again for my necessities.", 
                "num": 16
              }, 
              {
                "text": "Not that I seek the gift, but I seek the fruit that abounds to your account.", 
                "num": 17
              }, 
              {
                "text": "Indeed I have all and abound. I am full, having received from Epaphroditus the things sent from you, a sweet-smelling aroma, an acceptable sacrifice, well pleasing to God.", 
                "num": 18
              }, 
              {
                "text": "And my God shall supply all your need according to His riches in glory by Christ Jesus.", 
                "num": 19
              }, 
              {
                "text": "Now to our God and Father be glory forever and ever. Amen.", 
                "num": 20
              }, 
              {
                "text": "Greet every saint in Christ Jesus. The brethren who are with me greet you.", 
                "num": 21
              }, 
              {
                "text": "All the saints greet you, but especially those who are of Caesar's household.", 
                "num": 22
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you all. Amen.", 
                "num": 23
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "Colossians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle of Jesus Christ by the will of God, and Timothy our brother,", 
                "num": 1
              }, 
              {
                "text": "To the saints and faithful brethren in Christ who are in Colosse: Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 2
              }, 
              {
                "text": "We give thanks to the God and Father of our Lord Jesus Christ, praying always for you,", 
                "num": 3
              }, 
              {
                "text": "since we heard of your faith in Christ Jesus and of your love for all the saints;", 
                "num": 4
              }, 
              {
                "text": "because of the hope which is laid up for you in heaven, of which you heard before in the word of the truth of the gospel,", 
                "num": 5
              }, 
              {
                "text": "which has come to you, as it has also in all the world, and is bringing forth fruit, as it is also among you since the day you heard and knew the grace of God in truth;", 
                "num": 6
              }, 
              {
                "text": "as you also learned from Epaphras, our dear fellow servant, who is a faithful minister of Christ on your behalf,", 
                "num": 7
              }, 
              {
                "text": "who also declared to us your love in the Spirit.", 
                "num": 8
              }, 
              {
                "text": "For this reason we also, since the day we heard it, do not cease to pray for you, and to ask that you may be filled with the knowledge of His will in all wisdom and spiritual understanding;", 
                "num": 9
              }, 
              {
                "text": "that you may walk worthy of the Lord, fully pleasing Him, being fruitful in every good work and increasing in the knowledge of God;", 
                "num": 10
              }, 
              {
                "text": "strengthened with all might, according to His glorious power, for all patience and longsuffering with joy;", 
                "num": 11
              }, 
              {
                "text": "giving thanks to the Father who has qualified us to be partakers of the inheritance of the saints in the light.", 
                "num": 12
              }, 
              {
                "text": "He has delivered us from the power of darkness and conveyed us into the kingdom of the Son of His love,", 
                "num": 13
              }, 
              {
                "text": "in whom we have redemption through His blood, the forgiveness of sins.", 
                "num": 14
              }, 
              {
                "text": "He is the image of the invisible God, the firstborn over all creation.", 
                "num": 15
              }, 
              {
                "text": "For by Him all things were created that are in heaven and that are on earth, visible and invisible, whether thrones or dominions or principalities or powers. All things were created through Him and for Him.", 
                "num": 16
              }, 
              {
                "text": "And He is before all things, and in Him all things consist.", 
                "num": 17
              }, 
              {
                "text": "And He is the head of the body, the church, who is the beginning, the firstborn from the dead, that in all things He may have the preeminence.", 
                "num": 18
              }, 
              {
                "text": "For it pleased the Father that in Him all the fullness should dwell,", 
                "num": 19
              }, 
              {
                "text": "and by Him to reconcile all things to Himself, by Him, whether things on earth or things in heaven, having made peace through the blood of His cross.", 
                "num": 20
              }, 
              {
                "text": "And you, who once were alienated and enemies in your mind by wicked works, yet now He has reconciled", 
                "num": 21
              }, 
              {
                "text": "in the body of His flesh through death, to present you holy, and blameless, and above reproach in His sight--", 
                "num": 22
              }, 
              {
                "text": "if indeed you continue in the faith, grounded and steadfast, and are not moved away from the hope of the gospel which you heard, which was preached to every creature under heaven, of which I, Paul, became a minister.", 
                "num": 23
              }, 
              {
                "text": "I now rejoice in my sufferings for you, and fill up in my flesh what is lacking in the afflictions of Christ, for the sake of His body, which is the church,", 
                "num": 24
              }, 
              {
                "text": "of which I became a minister according to the stewardship from God which was given to me for you, to fulfill the word of God,", 
                "num": 25
              }, 
              {
                "text": "the mystery which has been hidden from ages and from generations, but now has been revealed to His saints.", 
                "num": 26
              }, 
              {
                "text": "To them God willed to make known what are the riches of the glory of this mystery among the Gentiles: which is Christ in you, the hope of glory.", 
                "num": 27
              }, 
              {
                "text": "Him we preach, warning every man and teaching every man in all wisdom, that we may present every man perfect in Christ Jesus.", 
                "num": 28
              }, 
              {
                "text": "To this end I also labor, striving according to His working which works in me mightily.", 
                "num": 29
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "For I want you to know what a great conflict I have for you and those in Laodicea, and for as many as have not seen my face in the flesh,", 
                "num": 1
              }, 
              {
                "text": "that their hearts may be encouraged, being knit together in love, and attaining to all riches of the full assurance of understanding, to the knowledge of the mystery of God, both of the Father and of Christ,", 
                "num": 2
              }, 
              {
                "text": "in whom are hidden all the treasures of wisdom and knowledge.", 
                "num": 3
              }, 
              {
                "text": "Now this I say lest anyone should deceive you with persuasive words.", 
                "num": 4
              }, 
              {
                "text": "For though I am absent in the flesh, yet I am with you in spirit, rejoicing to see your good order and the steadfastness of your faith in Christ.", 
                "num": 5
              }, 
              {
                "text": "As you therefore have received Christ Jesus the Lord, so walk in Him,", 
                "num": 6
              }, 
              {
                "text": "rooted and built up in Him and established in the faith, as you have been taught, abounding in it with thanksgiving.", 
                "num": 7
              }, 
              {
                "text": "Beware lest anyone cheat you through philosophy and empty deceit, according to the tradition of men, according to the basic principles of the world, and not according to Christ.", 
                "num": 8
              }, 
              {
                "text": "For in Him dwells all the fullness of the Godhead bodily;", 
                "num": 9
              }, 
              {
                "text": "and you are complete in Him, who is the head of all principality and power.", 
                "num": 10
              }, 
              {
                "text": "In Him you were also circumcised with the circumcision made without hands, by putting off the body of the sins of the flesh, by the circumcision of Christ,", 
                "num": 11
              }, 
              {
                "text": "buried with Him in baptism, in which you also were raised with Him through faith in the working of God, who raised Him from the dead.", 
                "num": 12
              }, 
              {
                "text": "And you, being dead in your trespasses and the uncircumcision of your flesh, He has made alive together with Him, having forgiven you all trespasses,", 
                "num": 13
              }, 
              {
                "text": "having wiped out the handwriting of requirements that was against us, which was contrary to us. And He has taken it out of the way, having nailed it to the cross.", 
                "num": 14
              }, 
              {
                "text": "Having disarmed principalities and powers, He made a public spectacle of them, triumphing over them in it.", 
                "num": 15
              }, 
              {
                "text": "So let no one judge you in food or in drink, or regarding a festival or a new moon or sabbaths,", 
                "num": 16
              }, 
              {
                "text": "which are a shadow of things to come, but the substance is of Christ.", 
                "num": 17
              }, 
              {
                "text": "Let no one cheat you of your reward, taking delight in false humility and worship of angels, intruding into those things which he has not seen, vainly puffed up by his fleshly mind,", 
                "num": 18
              }, 
              {
                "text": "and not holding fast to the Head, from whom all the body, nourished and knit together by joints and ligaments, grows with the increase that is from God.", 
                "num": 19
              }, 
              {
                "text": "Therefore, if you died with Christ from the basic principles of the world, why, as though living in the world, do you subject yourselves to regulations--", 
                "num": 20
              }, 
              {
                "text": "\"Do not touch, do not taste, do not handle,\"", 
                "num": 21
              }, 
              {
                "text": "which all concern things which perish with the using--according to the commandments and doctrines of men?", 
                "num": 22
              }, 
              {
                "text": "These things indeed have an appearance of wisdom in self-imposed religion, false humility, and neglect of the body, but are of no value against the indulgence of the flesh.", 
                "num": 23
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "If then you were raised with Christ, seek those things which are above, where Christ is, sitting at the right hand of God.", 
                "num": 1
              }, 
              {
                "text": "Set your mind on things above, not on things on the earth.", 
                "num": 2
              }, 
              {
                "text": "For you died, and your life is hidden with Christ in God.", 
                "num": 3
              }, 
              {
                "text": "When Christ who is our life appears, then you also will appear with Him in glory.", 
                "num": 4
              }, 
              {
                "text": "Therefore put to death your members which are on the earth: fornication, uncleanness, passion, evil desire, and covetousness, which is idolatry.", 
                "num": 5
              }, 
              {
                "text": "Because of these things the wrath of God is coming upon the sons of disobedience,", 
                "num": 6
              }, 
              {
                "text": "in which you yourselves once walked when you lived in them.", 
                "num": 7
              }, 
              {
                "text": "But now you yourselves are to put off all these: anger, wrath, malice, blasphemy, filthy language out of your mouth.", 
                "num": 8
              }, 
              {
                "text": "Do not lie to one another, since you have put off the old man with his deeds,", 
                "num": 9
              }, 
              {
                "text": "and have put on the new man who is renewed in knowledge according to the image of Him who created him,", 
                "num": 10
              }, 
              {
                "text": "where there is neither Greek nor Jew, circumcised nor uncircumcised, barbarian, Scythian, slave nor free, but Christ is all and in all.", 
                "num": 11
              }, 
              {
                "text": "Therefore, as the elect of God, holy and beloved, put on tender mercies, kindness, humility, meekness, longsuffering;", 
                "num": 12
              }, 
              {
                "text": "bearing with one another, and forgiving one another, if anyone has a complaint against another; even as Christ forgave you, so you also must do.", 
                "num": 13
              }, 
              {
                "text": "But above all these things put on love, which is the bond of perfection.", 
                "num": 14
              }, 
              {
                "text": "And let the peace of God rule in your hearts, to which also you were called in one body; and be thankful.", 
                "num": 15
              }, 
              {
                "text": "Let the word of Christ dwell in you richly in all wisdom, teaching and admonishing one another in psalms and hymns and spiritual songs, singing with grace in your hearts to the Lord.", 
                "num": 16
              }, 
              {
                "text": "And whatever you do in word or deed, do all in the name of the Lord Jesus, giving thanks to God the Father through Him.", 
                "num": 17
              }, 
              {
                "text": "Wives, submit to your own husbands, as is fitting in the Lord.", 
                "num": 18
              }, 
              {
                "text": "Husbands, love your wives and do not be bitter toward them.", 
                "num": 19
              }, 
              {
                "text": "Children, obey your parents in all things, for this is well pleasing to the Lord.", 
                "num": 20
              }, 
              {
                "text": "Fathers, do not provoke your children, lest they become discouraged.", 
                "num": 21
              }, 
              {
                "text": "Bondservants, obey in all things your masters according to the flesh, not with eyeservice, as men-pleasers, but in sincerity of heart, fearing God.", 
                "num": 22
              }, 
              {
                "text": "And whatever you do, do it heartily, as to the Lord and not to men,", 
                "num": 23
              }, 
              {
                "text": "knowing that from the Lord you will receive the reward of the inheritance; for you serve the Lord Christ.", 
                "num": 24
              }, 
              {
                "text": "But he who does wrong will be repaid for what he has done, and there is no partiality.", 
                "num": 25
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Masters, give your bondservants what is just and fair, knowing that you also have a Master in heaven.", 
                "num": 1
              }, 
              {
                "text": "Continue earnestly in prayer, being vigilant in it with thanksgiving;", 
                "num": 2
              }, 
              {
                "text": "meanwhile praying also for us, that God would open to us a door for the word, to speak the mystery of Christ, for which I am also in chains,", 
                "num": 3
              }, 
              {
                "text": "that I may make it manifest, as I ought to speak.", 
                "num": 4
              }, 
              {
                "text": "Walk in wisdom toward those who are outside, redeeming the time.", 
                "num": 5
              }, 
              {
                "text": "Let your speech always be with grace, seasoned with salt, that you may know how you ought to answer each one.", 
                "num": 6
              }, 
              {
                "text": "Tychicus, a beloved brother, faithful minister, and fellow servant in the Lord, will tell you all the news about me.", 
                "num": 7
              }, 
              {
                "text": "I am sending him to you for this very purpose, that he may know your circumstances and comfort your hearts,", 
                "num": 8
              }, 
              {
                "text": "with Onesimus, a faithful and beloved brother, who is one of you. They will make known to you all things which are happening here.", 
                "num": 9
              }, 
              {
                "text": "Aristarchus my fellow prisoner greets you, with Mark the cousin of Barnabas (about whom you received instructions: if he comes to you, welcome him),", 
                "num": 10
              }, 
              {
                "text": "and Jesus who is called Justus. These are my only fellow workers for the kingdom of God who are of the circumcision; they have proved to be a comfort to me.", 
                "num": 11
              }, 
              {
                "text": "Epaphras, who is one of you, a bondservant of Christ, greets you, always laboring fervently for you in prayers, that you may stand perfect and complete in all the will of God.", 
                "num": 12
              }, 
              {
                "text": "For I bear him witness that he has a great zeal for you, and those who are in Laodicea, and those in Hierapolis.", 
                "num": 13
              }, 
              {
                "text": "Luke the beloved physician and Demas greet you.", 
                "num": 14
              }, 
              {
                "text": "Greet the brethren who are in Laodicea, and Nymphas and the church that is in his house.", 
                "num": 15
              }, 
              {
                "text": "Now when this epistle is read among you, see that it is read also in the church of the Laodiceans, and that you likewise read the epistle from Laodicea.", 
                "num": 16
              }, 
              {
                "text": "And say to Archippus, \"Take heed to the ministry which you have received in the Lord, that you may fulfill it.\"", 
                "num": 17
              }, 
              {
                "text": "This salutation by my own hand--Paul. Remember my chains. Grace be with you. Amen.", 
                "num": 18
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "1 Thessalonians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, Silvanus, and Timothy, To the church of the Thessalonians in God the Father and the Lord Jesus Christ: Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 1
              }, 
              {
                "text": "We give thanks to God always for you all, making mention of you in our prayers,", 
                "num": 2
              }, 
              {
                "text": "remembering without ceasing your work of faith, labor of love, and patience of hope in our Lord Jesus Christ in the sight of our God and Father,", 
                "num": 3
              }, 
              {
                "text": "knowing, beloved brethren, your election by God.", 
                "num": 4
              }, 
              {
                "text": "For our gospel did not come to you in word only, but also in power, and in the Holy Spirit and in much assurance, as you know what kind of men we were among you for your sake.", 
                "num": 5
              }, 
              {
                "text": "And you became followers of us and of the Lord, having received the word in much affliction, with joy of the Holy Spirit,", 
                "num": 6
              }, 
              {
                "text": "so that you became examples to all in Macedonia and Achaia who believe.", 
                "num": 7
              }, 
              {
                "text": "For from you the word of the Lord has sounded forth, not only in Macedonia and Achaia, but also in every place. Your faith toward God has gone out, so that we do not need to say anything.", 
                "num": 8
              }, 
              {
                "text": "For they themselves declare concerning us what manner of entry we had to you, and how you turned to God from idols to serve the living and true God,", 
                "num": 9
              }, 
              {
                "text": "and to wait for His Son from heaven, whom He raised from the dead, even Jesus who delivers us from the wrath to come.", 
                "num": 10
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "For you yourselves know, brethren, that our coming to you was not in vain.", 
                "num": 1
              }, 
              {
                "text": "But even after we had suffered before and were spitefully treated at Philippi, as you know, we were bold in our God to speak to you the gospel of God in much conflict.", 
                "num": 2
              }, 
              {
                "text": "For our exhortation did not come from error or uncleanness, nor was it in deceit.", 
                "num": 3
              }, 
              {
                "text": "But as we have been approved by God to be entrusted with the gospel, even so we speak, not as pleasing men, but God who tests our hearts.", 
                "num": 4
              }, 
              {
                "text": "For neither at any time did we use flattering words, as you know, nor a cloak for covetousness--God is witness.", 
                "num": 5
              }, 
              {
                "text": "Nor did we seek glory from men, either from you or from others, when we might have made demands as apostles of Christ.", 
                "num": 6
              }, 
              {
                "text": "But we were gentle among you, just as a nursing mother cherishes her own children.", 
                "num": 7
              }, 
              {
                "text": "So, affectionately longing for you, we were well pleased to impart to you not only the gospel of God, but also our own lives, because you had become dear to us.", 
                "num": 8
              }, 
              {
                "text": "For you remember, brethren, our labor and toil; for laboring night and day, that we might not be a burden to any of you, we preached to you the gospel of God.", 
                "num": 9
              }, 
              {
                "text": "You are witnesses, and God also, how devoutly and justly and blamelessly we behaved ourselves among you who believe;", 
                "num": 10
              }, 
              {
                "text": "as you know how we exhorted, and comforted, and charged every one of you, as a father does his own children,", 
                "num": 11
              }, 
              {
                "text": "that you would walk worthy of God who calls you into His own kingdom and glory.", 
                "num": 12
              }, 
              {
                "text": "For this reason we also thank God without ceasing, because when you received the word of God which you heard from us, you welcomed it not as the word of men, but as it is in truth, the word of God, which also effectively works in you who believe.", 
                "num": 13
              }, 
              {
                "text": "For you, brethren, became imitators of the churches of God which are in Judea in Christ Jesus. For you also suffered the same things from your own countrymen, just as they did from the Judeans,", 
                "num": 14
              }, 
              {
                "text": "who killed both the Lord Jesus and their own prophets, and have persecuted us; and they do not please God and are contrary to all men,", 
                "num": 15
              }, 
              {
                "text": "forbidding us to speak to the Gentiles that they may be saved, so as always to fill up the measure of their sins; but wrath has come upon them to the uttermost.", 
                "num": 16
              }, 
              {
                "text": "But we, brethren, having been taken away from you for a short time in presence, not in heart, endeavored more eagerly to see your face with great desire.", 
                "num": 17
              }, 
              {
                "text": "Therefore we wanted to come to you--even I, Paul, time and again--but Satan hindered us.", 
                "num": 18
              }, 
              {
                "text": "For what is our hope, or joy, or crown of rejoicing? Is it not even you in the presence of our Lord Jesus Christ at His coming?", 
                "num": 19
              }, 
              {
                "text": "For you are our glory and joy.", 
                "num": 20
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Therefore, when we could no longer endure it, we thought it good to be left in Athens alone,", 
                "num": 1
              }, 
              {
                "text": "and sent Timothy, our brother and minister of God, and our fellow laborer in the gospel of Christ, to establish you and encourage you concerning your faith,", 
                "num": 2
              }, 
              {
                "text": "that no one should be shaken by these afflictions; for you yourselves know that we are appointed to this.", 
                "num": 3
              }, 
              {
                "text": "For, in fact, we told you before when we were with you that we would suffer tribulation, just as it happened, and you know.", 
                "num": 4
              }, 
              {
                "text": "For this reason, when I could no longer endure it, I sent to know your faith, lest by some means the tempter had tempted you, and our labor might be in vain.", 
                "num": 5
              }, 
              {
                "text": "But now that Timothy has come to us from you, and brought us good news of your faith and love, and that you always have good remembrance of us, greatly desiring to see us, as we also to see you--", 
                "num": 6
              }, 
              {
                "text": "therefore, brethren, in all our affliction and distress we were comforted concerning you by your faith.", 
                "num": 7
              }, 
              {
                "text": "For now we live, if you stand fast in the Lord.", 
                "num": 8
              }, 
              {
                "text": "For what thanks can we render to God for you, for all the joy with which we rejoice for your sake before our God,", 
                "num": 9
              }, 
              {
                "text": "night and day praying exceedingly that we may see your face and perfect what is lacking in your faith?", 
                "num": 10
              }, 
              {
                "text": "Now may our God and Father Himself, and our Lord Jesus Christ, direct our way to you.", 
                "num": 11
              }, 
              {
                "text": "And may the Lord make you increase and abound in love to one another and to all, just as we do to you,", 
                "num": 12
              }, 
              {
                "text": "so that He may establish your hearts blameless in holiness before our God and Father at the coming of our Lord Jesus Christ with all His saints.", 
                "num": 13
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Finally then, brethren, we urge and exhort in the Lord Jesus that you should abound more and more, just as you received from us how you ought to walk and to please God;", 
                "num": 1
              }, 
              {
                "text": "for you know what commandments we gave you through the Lord Jesus.", 
                "num": 2
              }, 
              {
                "text": "For this is the will of God, your sanctification: that you should abstain from sexual immorality;", 
                "num": 3
              }, 
              {
                "text": "that each of you should know how to possess his own vessel in sanctification and honor,", 
                "num": 4
              }, 
              {
                "text": "not in passion of lust, like the Gentiles who do not know God;", 
                "num": 5
              }, 
              {
                "text": "that no one should take advantage of and defraud his brother in this matter, because the Lord is the avenger of all such, as we also forewarned you and testified.", 
                "num": 6
              }, 
              {
                "text": "For God did not call us to uncleanness, but in holiness.", 
                "num": 7
              }, 
              {
                "text": "Therefore he who rejects this does not reject man, but God, who has also given us His Holy Spirit.", 
                "num": 8
              }, 
              {
                "text": "But concerning brotherly love you have no need that I should write to you, for you yourselves are taught by God to love one another;", 
                "num": 9
              }, 
              {
                "text": "and indeed you do so toward all the brethren who are in all Macedonia. But we urge you, brethren, that you increase more and more;", 
                "num": 10
              }, 
              {
                "text": "that you also aspire to lead a quiet life, to mind your own business, and to work with your own hands, as we commanded you,", 
                "num": 11
              }, 
              {
                "text": "that you may walk properly toward those who are outside, and that you may lack nothing.", 
                "num": 12
              }, 
              {
                "text": "But I do not want you to be ignorant, brethren, concerning those who have fallen asleep, lest you sorrow as others who have no hope.", 
                "num": 13
              }, 
              {
                "text": "For if we believe that Jesus died and rose again, even so God will bring with Him those who sleep in Jesus.", 
                "num": 14
              }, 
              {
                "text": "For this we say to you by the word of the Lord, that we who are alive and remain until the coming of the Lord will by no means precede those who are asleep.", 
                "num": 15
              }, 
              {
                "text": "For the Lord Himself will descend from heaven with a shout, with the voice of an archangel, and with the trumpet of God. And the dead in Christ will rise first.", 
                "num": 16
              }, 
              {
                "text": "Then we who are alive and remain shall be caught up together with them in the clouds to meet the Lord in the air. And thus we shall always be with the Lord.", 
                "num": 17
              }, 
              {
                "text": "Therefore comfort one another with these words.", 
                "num": 18
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "But concerning the times and the seasons, brethren, you have no need that I should write to you.", 
                "num": 1
              }, 
              {
                "text": "For you yourselves know perfectly that the day of the Lord so comes as a thief in the night.", 
                "num": 2
              }, 
              {
                "text": "For when they say, \"Peace and safety!\" then sudden destruction comes upon them, as labor pains upon a pregnant woman. And they shall not escape.", 
                "num": 3
              }, 
              {
                "text": "But you, brethren, are not in darkness, so that this Day should overtake you as a thief.", 
                "num": 4
              }, 
              {
                "text": "You are all sons of light and sons of the day. We are not of the night nor of darkness.", 
                "num": 5
              }, 
              {
                "text": "Therefore let us not sleep, as others do, but let us watch and be sober.", 
                "num": 6
              }, 
              {
                "text": "For those who sleep, sleep at night, and those who get drunk are drunk at night.", 
                "num": 7
              }, 
              {
                "text": "But let us who are of the day be sober, putting on the breastplate of faith and love, and as a helmet the hope of salvation.", 
                "num": 8
              }, 
              {
                "text": "For God did not appoint us to wrath, but to obtain salvation through our Lord Jesus Christ,", 
                "num": 9
              }, 
              {
                "text": "who died for us, that whether we wake or sleep, we should live together with Him.", 
                "num": 10
              }, 
              {
                "text": "Therefore comfort each other and edify one another, just as you also are doing.", 
                "num": 11
              }, 
              {
                "text": "And we urge you, brethren, to recognize those who labor among you, and are over you in the Lord and admonish you,", 
                "num": 12
              }, 
              {
                "text": "and to esteem them very highly in love for their work's sake. Be at peace among yourselves.", 
                "num": 13
              }, 
              {
                "text": "Now we exhort you, brethren, warn those who are unruly, comfort the fainthearted, uphold the weak, be patient with all.", 
                "num": 14
              }, 
              {
                "text": "See that no one renders evil for evil to anyone, but always pursue what is good both for yourselves and for all.", 
                "num": 15
              }, 
              {
                "text": "Rejoice always,", 
                "num": 16
              }, 
              {
                "text": "pray without ceasing,", 
                "num": 17
              }, 
              {
                "text": "in everything give thanks; for this is the will of God in Christ Jesus for you.", 
                "num": 18
              }, 
              {
                "text": "Do not quench the Spirit.", 
                "num": 19
              }, 
              {
                "text": "Do not despise prophecies.", 
                "num": 20
              }, 
              {
                "text": "Test all things; hold fast what is good.", 
                "num": 21
              }, 
              {
                "text": "Abstain from every form of evil.", 
                "num": 22
              }, 
              {
                "text": "Now may the God of peace Himself sanctify you completely; and may your whole spirit, soul, and body be preserved blameless at the coming of our Lord Jesus Christ.", 
                "num": 23
              }, 
              {
                "text": "He who calls you is faithful, who also will do it.", 
                "num": 24
              }, 
              {
                "text": "Brethren, pray for us.", 
                "num": 25
              }, 
              {
                "text": "Greet all the brethren with a holy kiss.", 
                "num": 26
              }, 
              {
                "text": "I charge you by the Lord that this epistle be read to all the holy brethren.", 
                "num": 27
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you. Amen.", 
                "num": 28
              }
            ], 
            "num": 5
          }
        ]
      }, 
      {
        "name": "2 Thessalonians", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, Silvanus, and Timothy, To the church of the Thessalonians in God our Father and the Lord Jesus Christ:", 
                "num": 1
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 2
              }, 
              {
                "text": "We are bound to thank God always for you, brethren, as it is fitting, because your faith grows exceedingly, and the love of every one of you all abounds toward each other,", 
                "num": 3
              }, 
              {
                "text": "so that we ourselves boast of you among the churches of God for your patience and faith in all your persecutions and tribulations that you endure,", 
                "num": 4
              }, 
              {
                "text": "which is manifest evidence of the righteous judgment of God, that you may be counted worthy of the kingdom of God, for which you also suffer;", 
                "num": 5
              }, 
              {
                "text": "since it is a righteous thing with God to repay with tribulation those who trouble you,", 
                "num": 6
              }, 
              {
                "text": "and to give you who are troubled rest with us when the Lord Jesus is revealed from heaven with His mighty angels,", 
                "num": 7
              }, 
              {
                "text": "in flaming fire taking vengeance on those who do not know God, and on those who do not obey the gospel of our Lord Jesus Christ.", 
                "num": 8
              }, 
              {
                "text": "These shall be punished with everlasting destruction from the presence of the Lord and from the glory of His power,", 
                "num": 9
              }, 
              {
                "text": "when He comes, in that Day, to be glorified in His saints and to be admired among all those who believe, because our testimony among you was believed.", 
                "num": 10
              }, 
              {
                "text": "Therefore we also pray always for you that our God would count you worthy of this calling, and fulfill all the good pleasure of His goodness and the work of faith with power,", 
                "num": 11
              }, 
              {
                "text": "that the name of our Lord Jesus Christ may be glorified in you, and you in Him, according to the grace of our God and the Lord Jesus Christ.", 
                "num": 12
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Now, brethren, concerning the coming of our Lord Jesus Christ and our gathering together to Him, we ask you,", 
                "num": 1
              }, 
              {
                "text": "not to be soon shaken in mind or troubled, either by spirit or by word or by letter, as if from us, as though the day of Christ had come.", 
                "num": 2
              }, 
              {
                "text": "Let no one deceive you by any means; for that Day will not come unless the falling away comes first, and the man of sin is revealed, the son of perdition,", 
                "num": 3
              }, 
              {
                "text": "who opposes and exalts himself above all that is called God or that is worshiped, so that he sits as God in the temple of God, showing himself that he is God.", 
                "num": 4
              }, 
              {
                "text": "Do you not remember that when I was still with you I told you these things?", 
                "num": 5
              }, 
              {
                "text": "And now you know what is restraining, that he may be revealed in his own time.", 
                "num": 6
              }, 
              {
                "text": "For the mystery of lawlessness is already at work; only He who now restrains will do so until He is taken out of the way.", 
                "num": 7
              }, 
              {
                "text": "And then the lawless one will be revealed, whom the Lord will consume with the breath of His mouth and destroy with the brightness of His coming.", 
                "num": 8
              }, 
              {
                "text": "The coming of the lawless one is according to the working of Satan, with all power, signs, and lying wonders,", 
                "num": 9
              }, 
              {
                "text": "and with all unrighteous deception among those who perish, because they did not receive the love of the truth, that they might be saved.", 
                "num": 10
              }, 
              {
                "text": "And for this reason God will send them strong delusion, that they should believe the lie,", 
                "num": 11
              }, 
              {
                "text": "that they all may be condemned who did not believe the truth but had pleasure in unrighteousness.", 
                "num": 12
              }, 
              {
                "text": "But we are bound to give thanks to God always for you, brethren beloved by the Lord, because God from the beginning chose you for salvation through sanctification by the Spirit and belief in the truth,", 
                "num": 13
              }, 
              {
                "text": "to which He called you by our gospel, for the obtaining of the glory of our Lord Jesus Christ.", 
                "num": 14
              }, 
              {
                "text": "Therefore, brethren, stand fast and hold the traditions which you were taught, whether by word or our epistle.", 
                "num": 15
              }, 
              {
                "text": "Now may our Lord Jesus Christ Himself, and our God and Father, who has loved us and given us everlasting consolation and good hope by grace,", 
                "num": 16
              }, 
              {
                "text": "comfort your hearts and establish you in every good word and work.", 
                "num": 17
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Finally, brethren, pray for us, that the word of the Lord may run swiftly and be glorified, just as it is with you,", 
                "num": 1
              }, 
              {
                "text": "and that we may be delivered from unreasonable and wicked men; for not all have faith.", 
                "num": 2
              }, 
              {
                "text": "But the Lord is faithful, who will establish you and guard you from the evil one.", 
                "num": 3
              }, 
              {
                "text": "And we have confidence in the Lord concerning you, both that you do and will do the things we command you.", 
                "num": 4
              }, 
              {
                "text": "Now may the Lord direct your hearts into the love of God and into the patience of Christ.", 
                "num": 5
              }, 
              {
                "text": "But we command you, brethren, in the name of our Lord Jesus Christ, that you withdraw from every brother who walks disorderly and not according to the tradition which he received from us.", 
                "num": 6
              }, 
              {
                "text": "For you yourselves know how you ought to follow us, for we were not disorderly among you;", 
                "num": 7
              }, 
              {
                "text": "nor did we eat anyone's bread free of charge, but worked with labor and toil night and day, that we might not be a burden to any of you,", 
                "num": 8
              }, 
              {
                "text": "not because we do not have authority, but to make ourselves an example of how you should follow us.", 
                "num": 9
              }, 
              {
                "text": "For even when we were with you, we commanded you this: If anyone will not work, neither shall he eat.", 
                "num": 10
              }, 
              {
                "text": "For we hear that there are some who walk among you in a disorderly manner, not working at all, but are busybodies.", 
                "num": 11
              }, 
              {
                "text": "Now those who are such we command and exhort through our Lord Jesus Christ that they work in quietness and eat their own bread.", 
                "num": 12
              }, 
              {
                "text": "But as for you, brethren, do not grow weary in doing good.", 
                "num": 13
              }, 
              {
                "text": "And if anyone does not obey our word in this epistle, note that person and do not keep company with him, that he may be ashamed.", 
                "num": 14
              }, 
              {
                "text": "Yet do not count him as an enemy, but admonish him as a brother.", 
                "num": 15
              }, 
              {
                "text": "Now may the Lord of peace Himself give you peace always in every way. The Lord be with you all.", 
                "num": 16
              }, 
              {
                "text": "The salutation of Paul with my own hand, which is a sign in every epistle; so I write.", 
                "num": 17
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you all. Amen.", 
                "num": 18
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "1 Timothy", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle of Jesus Christ, by the commandment of God our Savior and the Lord Jesus Christ, our hope,", 
                "num": 1
              }, 
              {
                "text": "To Timothy, a true son in the faith: Grace, mercy, and peace from God our Father and Jesus Christ our Lord.", 
                "num": 2
              }, 
              {
                "text": "As I urged you when I went into Macedonia--remain in Ephesus that you may charge some that they teach no other doctrine,", 
                "num": 3
              }, 
              {
                "text": "nor give heed to fables and endless genealogies, which cause disputes rather than godly edification which is in faith.", 
                "num": 4
              }, 
              {
                "text": "Now the purpose of the commandment is love from a pure heart, from a good conscience, and from sincere faith,", 
                "num": 5
              }, 
              {
                "text": "from which some, having strayed, have turned aside to idle talk,", 
                "num": 6
              }, 
              {
                "text": "desiring to be teachers of the law, understanding neither what they say nor the things which they affirm.", 
                "num": 7
              }, 
              {
                "text": "But we know that the law is good if one uses it lawfully,", 
                "num": 8
              }, 
              {
                "text": "knowing this: that the law is not made for a righteous person, but for the lawless and insubordinate, for the ungodly and for sinners, for the unholy and profane, for murderers of fathers and murderers of mothers, for manslayers,", 
                "num": 9
              }, 
              {
                "text": "for fornicators, for sodomites, for kidnappers, for liars, for perjurers, and if there is any other thing that is contrary to sound doctrine,", 
                "num": 10
              }, 
              {
                "text": "according to the glorious gospel of the blessed God which was committed to my trust.", 
                "num": 11
              }, 
              {
                "text": "And I thank Christ Jesus our Lord who has enabled me, because He counted me faithful, putting me into the ministry,", 
                "num": 12
              }, 
              {
                "text": "although I was formerly a blasphemer, a persecutor, and an insolent man; but I obtained mercy because I did it ignorantly in unbelief.", 
                "num": 13
              }, 
              {
                "text": "And the grace of our Lord was exceedingly abundant, with faith and love which are in Christ Jesus.", 
                "num": 14
              }, 
              {
                "text": "This is a faithful saying and worthy of all acceptance, that Christ Jesus came into the world to save sinners, of whom I am chief.", 
                "num": 15
              }, 
              {
                "text": "However, for this reason I obtained mercy, that in me first Jesus Christ might show all longsuffering, as a pattern to those who are going to believe on Him for everlasting life.", 
                "num": 16
              }, 
              {
                "text": "Now to the King eternal, immortal, invisible, to God who alone is wise, be honor and glory forever and ever. Amen.", 
                "num": 17
              }, 
              {
                "text": "This charge I commit to you, son Timothy, according to the prophecies previously made concerning you, that by them you may wage the good warfare,", 
                "num": 18
              }, 
              {
                "text": "having faith and a good conscience, which some having rejected, concerning the faith have suffered shipwreck,", 
                "num": 19
              }, 
              {
                "text": "of whom are Hymenaeus and Alexander, whom I delivered to Satan that they may learn not to blaspheme.", 
                "num": 20
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Therefore I exhort first of all that supplications, prayers, intercessions, and giving of thanks be made for all men,", 
                "num": 1
              }, 
              {
                "text": "for kings and all who are in authority, that we may lead a quiet and peaceable life in all godliness and reverence.", 
                "num": 2
              }, 
              {
                "text": "For this is good and acceptable in the sight of God our Savior,", 
                "num": 3
              }, 
              {
                "text": "who desires all men to be saved and to come to the knowledge of the truth.", 
                "num": 4
              }, 
              {
                "text": "For there is one God and one Mediator between God and men, the Man Christ Jesus,", 
                "num": 5
              }, 
              {
                "text": "who gave Himself a ransom for all, to be testified in due time,", 
                "num": 6
              }, 
              {
                "text": "for which I was appointed a preacher and an apostle--I am speaking the truth in Christ and not lying--a teacher of the Gentiles in faith and truth.", 
                "num": 7
              }, 
              {
                "text": "I desire therefore that the men pray everywhere, lifting up holy hands, without wrath and doubting;", 
                "num": 8
              }, 
              {
                "text": "in like manner also, that the women adorn themselves in modest apparel, with propriety and moderation, not with braided hair or gold or pearls or costly clothing,", 
                "num": 9
              }, 
              {
                "text": "but, which is proper for women professing godliness, with good works.", 
                "num": 10
              }, 
              {
                "text": "Let a woman learn in silence with all submission.", 
                "num": 11
              }, 
              {
                "text": "And I do not permit a woman to teach or to have authority over a man, but to be in silence.", 
                "num": 12
              }, 
              {
                "text": "For Adam was formed first, then Eve.", 
                "num": 13
              }, 
              {
                "text": "And Adam was not deceived, but the woman being deceived, fell into transgression.", 
                "num": 14
              }, 
              {
                "text": "Nevertheless she will be saved in childbearing if they continue in faith, love, and holiness, with self-control.", 
                "num": 15
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "This is a faithful saying: If a man desires the position of a bishop, he desires a good work.", 
                "num": 1
              }, 
              {
                "text": "A bishop then must be blameless, the husband of one wife, temperate, sober-minded, of good behavior, hospitable, able to teach;", 
                "num": 2
              }, 
              {
                "text": "not given to wine, not violent, not greedy for money, but gentle, not quarrelsome, not covetous;", 
                "num": 3
              }, 
              {
                "text": "one who rules his own house well, having his children in submission with all reverence", 
                "num": 4
              }, 
              {
                "text": "(for if a man does not know how to rule his own house, how will he take care of the church of God?);", 
                "num": 5
              }, 
              {
                "text": "not a novice, lest being puffed up with pride he fall into the same condemnation as the devil.", 
                "num": 6
              }, 
              {
                "text": "Moreover he must have a good testimony among those who are outside, lest he fall into reproach and the snare of the devil.", 
                "num": 7
              }, 
              {
                "text": "Likewise deacons must be reverent, not double-tongued, not given to much wine, not greedy for money,", 
                "num": 8
              }, 
              {
                "text": "holding the mystery of the faith with a pure conscience.", 
                "num": 9
              }, 
              {
                "text": "But let these also first be tested; then let them serve as deacons, being found blameless.", 
                "num": 10
              }, 
              {
                "text": "Likewise, their wives must be reverent, not slanderers, temperate, faithful in all things.", 
                "num": 11
              }, 
              {
                "text": "Let deacons be the husbands of one wife, ruling their children and their own houses well.", 
                "num": 12
              }, 
              {
                "text": "For those who have served well as deacons obtain for themselves a good standing and great boldness in the faith which is in Christ Jesus.", 
                "num": 13
              }, 
              {
                "text": "These things I write to you, though I hope to come to you shortly;", 
                "num": 14
              }, 
              {
                "text": "but if I am delayed, I write so that you may know how you ought to conduct yourself in the house of God, which is the church of the living God, the pillar and ground of the truth.", 
                "num": 15
              }, 
              {
                "text": "And without controversy great is the mystery of godliness: God was manifested in the flesh, Justified in the Spirit, Seen by angels, Preached among the Gentiles, Believed on in the world, Received up in glory.", 
                "num": 16
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Now the Spirit expressly says that in latter times some will depart from the faith, giving heed to deceiving spirits and doctrines of demons,", 
                "num": 1
              }, 
              {
                "text": "speaking lies in hypocrisy, having their own conscience seared with a hot iron,", 
                "num": 2
              }, 
              {
                "text": "forbidding to marry, and commanding to abstain from foods which God created to be received with thanksgiving by those who believe and know the truth.", 
                "num": 3
              }, 
              {
                "text": "For every creature of God is good, and nothing is to be refused if it is received with thanksgiving;", 
                "num": 4
              }, 
              {
                "text": "for it is sanctified by the word of God and prayer.", 
                "num": 5
              }, 
              {
                "text": "If you instruct the brethren in these things, you will be a good minister of Jesus Christ, nourished in the words of faith and of the good doctrine which you have carefully followed.", 
                "num": 6
              }, 
              {
                "text": "But reject profane and old wives' fables, and exercise yourself toward godliness.", 
                "num": 7
              }, 
              {
                "text": "For bodily exercise profits a little, but godliness is profitable for all things, having promise of the life that now is and of that which is to come.", 
                "num": 8
              }, 
              {
                "text": "This is a faithful saying and worthy of all acceptance.", 
                "num": 9
              }, 
              {
                "text": "For to this end we both labor and suffer reproach, because we trust in the living God, who is the Savior of all men, especially of those who believe.", 
                "num": 10
              }, 
              {
                "text": "These things command and teach.", 
                "num": 11
              }, 
              {
                "text": "Let no one despise your youth, but be an example to the believers in word, in conduct, in love, in spirit, in faith, in purity.", 
                "num": 12
              }, 
              {
                "text": "Till I come, give attention to reading, to exhortation, to doctrine.", 
                "num": 13
              }, 
              {
                "text": "Do not neglect the gift that is in you, which was given to you by prophecy with the laying on of the hands of the eldership.", 
                "num": 14
              }, 
              {
                "text": "Meditate on these things; give yourself entirely to them, that your progress may be evident to all.", 
                "num": 15
              }, 
              {
                "text": "Take heed to yourself and to the doctrine. Continue in them, for in doing this you will save both yourself and those who hear you.", 
                "num": 16
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Do not rebuke an older man, but exhort him as a father, younger men as brothers,", 
                "num": 1
              }, 
              {
                "text": "older women as mothers, younger women as sisters, with all purity.", 
                "num": 2
              }, 
              {
                "text": "Honor widows who are really widows.", 
                "num": 3
              }, 
              {
                "text": "But if any widow has children or grandchildren, let them first learn to show piety at home and to repay their parents; for this is good and acceptable before God.", 
                "num": 4
              }, 
              {
                "text": "Now she who is really a widow, and left alone, trusts in God and continues in supplications and prayers night and day.", 
                "num": 5
              }, 
              {
                "text": "But she who lives in pleasure is dead while she lives.", 
                "num": 6
              }, 
              {
                "text": "And these things command, that they may be blameless.", 
                "num": 7
              }, 
              {
                "text": "But if anyone does not provide for his own, and especially for those of his household, he has denied the faith and is worse than an unbeliever.", 
                "num": 8
              }, 
              {
                "text": "Do not let a widow under sixty years old be taken into the number, and not unless she has been the wife of one man,", 
                "num": 9
              }, 
              {
                "text": "well reported for good works: if she has brought up children, if she has lodged strangers, if she has washed the saints' feet, if she has relieved the afflicted, if she has diligently followed every good work.", 
                "num": 10
              }, 
              {
                "text": "But refuse the younger widows; for when they have begun to grow wanton against Christ, they desire to marry,", 
                "num": 11
              }, 
              {
                "text": "having condemnation because they have cast off their first faith.", 
                "num": 12
              }, 
              {
                "text": "And besides they learn to be idle, wandering about from house to house, and not only idle but also gossips and busybodies, saying things which they ought not.", 
                "num": 13
              }, 
              {
                "text": "Therefore I desire that the younger widows marry, bear children, manage the house, give no opportunity to the adversary to speak reproachfully.", 
                "num": 14
              }, 
              {
                "text": "For some have already turned aside after Satan.", 
                "num": 15
              }, 
              {
                "text": "If any believing man or woman has widows, let them relieve them, and do not let the church be burdened, that it may relieve those who are really widows.", 
                "num": 16
              }, 
              {
                "text": "Let the elders who rule well be counted worthy of double honor, especially those who labor in the word and doctrine.", 
                "num": 17
              }, 
              {
                "text": "For the Scripture says, \"You shall not muzzle an ox while it treads out the grain,\" and, \"The laborer is worthy of his wages.\"", 
                "num": 18
              }, 
              {
                "text": "Do not receive an accusation against an elder except from two or three witnesses.", 
                "num": 19
              }, 
              {
                "text": "Those who are sinning rebuke in the presence of all, that the rest also may fear.", 
                "num": 20
              }, 
              {
                "text": "I charge you before God and the Lord Jesus Christ and the elect angels that you observe these things without prejudice, doing nothing with partiality.", 
                "num": 21
              }, 
              {
                "text": "Do not lay hands on anyone hastily, nor share in other people's sins; keep yourself pure.", 
                "num": 22
              }, 
              {
                "text": "No longer drink only water, but use a little wine for your stomach's sake and your frequent infirmities.", 
                "num": 23
              }, 
              {
                "text": "Some men's sins are clearly evident, preceding them to judgment, but those of some men follow later.", 
                "num": 24
              }, 
              {
                "text": "Likewise, the good works of some are clearly evident, and those that are otherwise cannot be hidden.", 
                "num": 25
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Let as many bondservants as are under the yoke count their own masters worthy of all honor, so that the name of God and His doctrine may not be blasphemed.", 
                "num": 1
              }, 
              {
                "text": "And those who have believing masters, let them not despise them because they are brethren, but rather serve them because those who are benefited are believers and beloved. Teach and exhort these things.", 
                "num": 2
              }, 
              {
                "text": "If anyone teaches otherwise and does not consent to wholesome words, even the words of our Lord Jesus Christ, and to the doctrine which accords with godliness,", 
                "num": 3
              }, 
              {
                "text": "he is proud, knowing nothing, but is obsessed with disputes and arguments over words, from which come envy, strife, reviling, evil suspicions,", 
                "num": 4
              }, 
              {
                "text": "useless wranglings of men of corrupt minds and destitute of the truth, who suppose that godliness is a means of gain. From such withdraw yourself.", 
                "num": 5
              }, 
              {
                "text": "Now godliness with contentment is great gain.", 
                "num": 6
              }, 
              {
                "text": "For we brought nothing into this world, and it is certain we can carry nothing out.", 
                "num": 7
              }, 
              {
                "text": "And having food and clothing, with these we shall be content.", 
                "num": 8
              }, 
              {
                "text": "But those who desire to be rich fall into temptation and a snare, and into many foolish and harmful lusts which drown men in destruction and perdition.", 
                "num": 9
              }, 
              {
                "text": "For the love of money is a root of all kinds of evil, for which some have strayed from the faith in their greediness, and pierced themselves through with many sorrows.", 
                "num": 10
              }, 
              {
                "text": "But you, O man of God, flee these things and pursue righteousness, godliness, faith, love, patience, gentleness.", 
                "num": 11
              }, 
              {
                "text": "Fight the good fight of faith, lay hold on eternal life, to which you were also called and have confessed the good confession in the presence of many witnesses.", 
                "num": 12
              }, 
              {
                "text": "I urge you in the sight of God who gives life to all things, and before Christ Jesus who witnessed the good confession before Pontius Pilate,", 
                "num": 13
              }, 
              {
                "text": "that you keep this commandment without spot, blameless until our Lord Jesus Christ's appearing,", 
                "num": 14
              }, 
              {
                "text": "which He will manifest in His own time, He who is the blessed and only Potentate, the King of kings and Lord of lords,", 
                "num": 15
              }, 
              {
                "text": "who alone has immortality, dwelling in unapproachable light, whom no man has seen or can see, to whom be honor and everlasting power. Amen.", 
                "num": 16
              }, 
              {
                "text": "Command those who are rich in this present age not to be haughty, nor to trust in uncertain riches but in the living God, who gives us richly all things to enjoy.", 
                "num": 17
              }, 
              {
                "text": "Let them do good, that they be rich in good works, ready to give, willing to share,", 
                "num": 18
              }, 
              {
                "text": "storing up for themselves a good foundation for the time to come, that they may lay hold on eternal life.", 
                "num": 19
              }, 
              {
                "text": "O Timothy! Guard what was committed to your trust, avoiding the profane and idle babblings and contradictions of what is falsely called knowledge--", 
                "num": 20
              }, 
              {
                "text": "by professing it some have strayed concerning the faith. Grace be with you. Amen.", 
                "num": 21
              }
            ], 
            "num": 6
          }
        ]
      }, 
      {
        "name": "2 Timothy", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, an apostle of Jesus Christ by the will of God, according to the promise of life which is in Christ Jesus,", 
                "num": 1
              }, 
              {
                "text": "To Timothy, a beloved son: Grace, mercy, and peace from God the Father and Christ Jesus our Lord.", 
                "num": 2
              }, 
              {
                "text": "I thank God, whom I serve with a pure conscience, as my forefathers did, as without ceasing I remember you in my prayers night and day,", 
                "num": 3
              }, 
              {
                "text": "greatly desiring to see you, being mindful of your tears, that I may be filled with joy,", 
                "num": 4
              }, 
              {
                "text": "when I call to remembrance the genuine faith that is in you, which dwelt first in your grandmother Lois and your mother Eunice, and I am persuaded is in you also.", 
                "num": 5
              }, 
              {
                "text": "Therefore I remind you to stir up the gift of God which is in you through the laying on of my hands.", 
                "num": 6
              }, 
              {
                "text": "For God has not given us a spirit of fear, but of power and of love and of a sound mind.", 
                "num": 7
              }, 
              {
                "text": "Therefore do not be ashamed of the testimony of our Lord, nor of me His prisoner, but share with me in the sufferings for the gospel according to the power of God,", 
                "num": 8
              }, 
              {
                "text": "who has saved us and called us with a holy calling, not according to our works, but according to His own purpose and grace which was given to us in Christ Jesus before time began,", 
                "num": 9
              }, 
              {
                "text": "but has now been revealed by the appearing of our Savior Jesus Christ, who has abolished death and brought life and immortality to light through the gospel,", 
                "num": 10
              }, 
              {
                "text": "to which I was appointed a preacher, an apostle, and a teacher of the Gentiles.", 
                "num": 11
              }, 
              {
                "text": "For this reason I also suffer these things; nevertheless I am not ashamed, for I know whom I have believed and am persuaded that He is able to keep what I have committed to Him until that Day.", 
                "num": 12
              }, 
              {
                "text": "Hold fast the pattern of sound words which you have heard from me, in faith and love which are in Christ Jesus.", 
                "num": 13
              }, 
              {
                "text": "That good thing which was committed to you, keep by the Holy Spirit who dwells in us.", 
                "num": 14
              }, 
              {
                "text": "This you know, that all those in Asia have turned away from me, among whom are Phygellus and Hermogenes.", 
                "num": 15
              }, 
              {
                "text": "The Lord grant mercy to the household of Onesiphorus, for he often refreshed me, and was not ashamed of my chain;", 
                "num": 16
              }, 
              {
                "text": "but when he arrived in Rome, he sought me out very zealously and found me.", 
                "num": 17
              }, 
              {
                "text": "The Lord grant to him that he may find mercy from the Lord in that Day--and you know very well how many ways he ministered to me at Ephesus.", 
                "num": 18
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "You therefore, my son, be strong in the grace that is in Christ Jesus.", 
                "num": 1
              }, 
              {
                "text": "And the things that you have heard from me among many witnesses, commit these to faithful men who will be able to teach others also.", 
                "num": 2
              }, 
              {
                "text": "You therefore must endure hardship as a good soldier of Jesus Christ.", 
                "num": 3
              }, 
              {
                "text": "No one engaged in warfare entangles himself with the affairs of this life, that he may please him who enlisted him as a soldier.", 
                "num": 4
              }, 
              {
                "text": "And also if anyone competes in athletics, he is not crowned unless he competes according to the rules.", 
                "num": 5
              }, 
              {
                "text": "The hardworking farmer must be first to partake of the crops.", 
                "num": 6
              }, 
              {
                "text": "Consider what I say, and may the Lord give you understanding in all things.", 
                "num": 7
              }, 
              {
                "text": "Remember that Jesus Christ, of the seed of David, was raised from the dead according to my gospel,", 
                "num": 8
              }, 
              {
                "text": "for which I suffer trouble as an evildoer, even to the point of chains; but the word of God is not chained.", 
                "num": 9
              }, 
              {
                "text": "Therefore I endure all things for the sake of the elect, that they also may obtain the salvation which is in Christ Jesus with eternal glory.", 
                "num": 10
              }, 
              {
                "text": "This is a faithful saying: For if we died with Him, We shall also live with Him.", 
                "num": 11
              }, 
              {
                "text": "If we endure, We shall also reign with Him. If we deny Him, He also will deny us.", 
                "num": 12
              }, 
              {
                "text": "If we are faithless, He remains faithful; He cannot deny Himself.", 
                "num": 13
              }, 
              {
                "text": "Remind them of these things, charging them before the Lord not to strive about words to no profit, to the ruin of the hearers.", 
                "num": 14
              }, 
              {
                "text": "Be diligent to present yourself approved to God, a worker who does not need to be ashamed, rightly dividing the word of truth.", 
                "num": 15
              }, 
              {
                "text": "But shun profane and idle babblings, for they will increase to more ungodliness.", 
                "num": 16
              }, 
              {
                "text": "And their message will spread like cancer. Hymenaeus and Philetus are of this sort,", 
                "num": 17
              }, 
              {
                "text": "who have strayed concerning the truth, saying that the resurrection is already past; and they overthrow the faith of some.", 
                "num": 18
              }, 
              {
                "text": "Nevertheless the solid foundation of God stands, having this seal: \"The Lord knows those who are His,\" and, \"Let everyone who names the name of Christ depart from iniquity.\"", 
                "num": 19
              }, 
              {
                "text": "But in a great house there are not only vessels of gold and silver, but also of wood and clay, some for honor and some for dishonor.", 
                "num": 20
              }, 
              {
                "text": "Therefore if anyone cleanses himself from the latter, he will be a vessel for honor, sanctified and useful for the Master, prepared for every good work.", 
                "num": 21
              }, 
              {
                "text": "Flee also youthful lusts; but pursue righteousness, faith, love, peace with those who call on the Lord out of a pure heart.", 
                "num": 22
              }, 
              {
                "text": "But avoid foolish and ignorant disputes, knowing that they generate strife.", 
                "num": 23
              }, 
              {
                "text": "And a servant of the Lord must not quarrel but be gentle to all, able to teach, patient,", 
                "num": 24
              }, 
              {
                "text": "in humility correcting those who are in opposition, if God perhaps will grant them repentance, so that they may know the truth,", 
                "num": 25
              }, 
              {
                "text": "and that they may come to their senses and escape the snare of the devil, having been taken captive by him to do his will.", 
                "num": 26
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "But know this, that in the last days perilous times will come:", 
                "num": 1
              }, 
              {
                "text": "For men will be lovers of themselves, lovers of money, boasters, proud, blasphemers, disobedient to parents, unthankful, unholy,", 
                "num": 2
              }, 
              {
                "text": "unloving, unforgiving, slanderers, without self-control, brutal, despisers of good,", 
                "num": 3
              }, 
              {
                "text": "traitors, headstrong, haughty, lovers of pleasure rather than lovers of God,", 
                "num": 4
              }, 
              {
                "text": "having a form of godliness but denying its power. And from such people turn away!", 
                "num": 5
              }, 
              {
                "text": "For of this sort are those who creep into households and make captives of gullible women loaded down with sins, led away by various lusts,", 
                "num": 6
              }, 
              {
                "text": "always learning and never able to come to the knowledge of the truth.", 
                "num": 7
              }, 
              {
                "text": "Now as Jannes and Jambres resisted Moses, so do these also resist the truth: men of corrupt minds, disapproved concerning the faith;", 
                "num": 8
              }, 
              {
                "text": "but they will progress no further, for their folly will be manifest to all, as theirs also was.", 
                "num": 9
              }, 
              {
                "text": "But you have carefully followed my doctrine, manner of life, purpose, faith, longsuffering, love, perseverance,", 
                "num": 10
              }, 
              {
                "text": "persecutions, afflictions, which happened to me at Antioch, at Iconium, at Lystra--what persecutions I endured. And out of them all the Lord delivered me.", 
                "num": 11
              }, 
              {
                "text": "Yes, and all who desire to live godly in Christ Jesus will suffer persecution.", 
                "num": 12
              }, 
              {
                "text": "But evil men and impostors will grow worse and worse, deceiving and being deceived.", 
                "num": 13
              }, 
              {
                "text": "But you must continue in the things which you have learned and been assured of, knowing from whom you have learned them,", 
                "num": 14
              }, 
              {
                "text": "and that from childhood you have known the Holy Scriptures, which are able to make you wise for salvation through faith which is in Christ Jesus.", 
                "num": 15
              }, 
              {
                "text": "All Scripture is given by inspiration of God, and is profitable for doctrine, for reproof, for correction, for instruction in righteousness,", 
                "num": 16
              }, 
              {
                "text": "that the man of God may be complete, thoroughly equipped for every good work.", 
                "num": 17
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "I charge you therefore before God and the Lord Jesus Christ, who will judge the living and the dead at His appearing and His kingdom:", 
                "num": 1
              }, 
              {
                "text": "Preach the word! Be ready in season and out of season. Convince, rebuke, exhort, with all longsuffering and teaching.", 
                "num": 2
              }, 
              {
                "text": "For the time will come when they will not endure sound doctrine, but according to their own desires, because they have itching ears, they will heap up for themselves teachers;", 
                "num": 3
              }, 
              {
                "text": "and they will turn their ears away from the truth, and be turned aside to fables.", 
                "num": 4
              }, 
              {
                "text": "But you be watchful in all things, endure afflictions, do the work of an evangelist, fulfill your ministry.", 
                "num": 5
              }, 
              {
                "text": "For I am already being poured out as a drink offering, and the time of my departure is at hand.", 
                "num": 6
              }, 
              {
                "text": "I have fought the good fight, I have finished the race, I have kept the faith.", 
                "num": 7
              }, 
              {
                "text": "Finally, there is laid up for me the crown of righteousness, which the Lord, the righteous Judge, will give to me on that Day, and not to me only but also to all who have loved His appearing.", 
                "num": 8
              }, 
              {
                "text": "Be diligent to come to me quickly;", 
                "num": 9
              }, 
              {
                "text": "for Demas has forsaken me, having loved this present world, and has departed for Thessalonica--Crescens for Galatia, Titus for Dalmatia.", 
                "num": 10
              }, 
              {
                "text": "Only Luke is with me. Get Mark and bring him with you, for he is useful to me for ministry.", 
                "num": 11
              }, 
              {
                "text": "And Tychicus I have sent to Ephesus.", 
                "num": 12
              }, 
              {
                "text": "Bring the cloak that I left with Carpus at Troas when you come--and the books, especially the parchments.", 
                "num": 13
              }, 
              {
                "text": "Alexander the coppersmith did me much harm. May the Lord repay him according to his works.", 
                "num": 14
              }, 
              {
                "text": "You also must beware of him, for he has greatly resisted our words.", 
                "num": 15
              }, 
              {
                "text": "At my first defense no one stood with me, but all forsook me. May it not be charged against them.", 
                "num": 16
              }, 
              {
                "text": "But the Lord stood with me and strengthened me, so that the message might be preached fully through me, and that all the Gentiles might hear. Also I was delivered out of the mouth of the lion.", 
                "num": 17
              }, 
              {
                "text": "And the Lord will deliver me from every evil work and preserve me for His heavenly kingdom. To Him be glory forever and ever. Amen!", 
                "num": 18
              }, 
              {
                "text": "Greet Prisca and Aquila, and the household of Onesiphorus.", 
                "num": 19
              }, 
              {
                "text": "Erastus stayed in Corinth, but Trophimus I have left in Miletus sick.", 
                "num": 20
              }, 
              {
                "text": "Do your utmost to come before winter. Eubulus greets you, as well as Pudens, Linus, Claudia, and all the brethren.", 
                "num": 21
              }, 
              {
                "text": "The Lord Jesus Christ be with your spirit. Grace be with you. Amen.", 
                "num": 22
              }
            ], 
            "num": 4
          }
        ]
      }, 
      {
        "name": "Titus", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, a bondservant of God and an apostle of Jesus Christ, according to the faith of God's elect and the acknowledgment of the truth which accords with godliness,", 
                "num": 1
              }, 
              {
                "text": "in hope of eternal life which God, who cannot lie, promised before time began,", 
                "num": 2
              }, 
              {
                "text": "but has in due time manifested His word through preaching, which was committed to me according to the commandment of God our Savior;", 
                "num": 3
              }, 
              {
                "text": "To Titus, a true son in our common faith: Grace, mercy, and peace from God the Father and the Lord Jesus Christ our Savior.", 
                "num": 4
              }, 
              {
                "text": "For this reason I left you in Crete, that you should set in order the things that are lacking, and appoint elders in every city as I commanded you--", 
                "num": 5
              }, 
              {
                "text": "if a man is blameless, the husband of one wife, having faithful children not accused of dissipation or insubordination.", 
                "num": 6
              }, 
              {
                "text": "For a bishop must be blameless, as a steward of God, not self-willed, not quick-tempered, not given to wine, not violent, not greedy for money,", 
                "num": 7
              }, 
              {
                "text": "but hospitable, a lover of what is good, sober-minded, just, holy, self-controlled,", 
                "num": 8
              }, 
              {
                "text": "holding fast the faithful word as he has been taught, that he may be able, by sound doctrine, both to exhort and convict those who contradict.", 
                "num": 9
              }, 
              {
                "text": "For there are many insubordinate, both idle talkers and deceivers, especially those of the circumcision,", 
                "num": 10
              }, 
              {
                "text": "whose mouths must be stopped, who subvert whole households, teaching things which they ought not, for the sake of dishonest gain.", 
                "num": 11
              }, 
              {
                "text": "One of them, a prophet of their own, said, \"Cretans are always liars, evil beasts, lazy gluttons.\"", 
                "num": 12
              }, 
              {
                "text": "This testimony is true. Therefore rebuke them sharply, that they may be sound in the faith,", 
                "num": 13
              }, 
              {
                "text": "not giving heed to Jewish fables and commandments of men who turn from the truth.", 
                "num": 14
              }, 
              {
                "text": "To the pure all things are pure, but to those who are defiled and unbelieving nothing is pure; but even their mind and conscience are defiled.", 
                "num": 15
              }, 
              {
                "text": "They profess to know God, but in works they deny Him, being abominable, disobedient, and disqualified for every good work.", 
                "num": 16
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "But as for you, speak the things which are proper for sound doctrine:", 
                "num": 1
              }, 
              {
                "text": "that the older men be sober, reverent, temperate, sound in faith, in love, in patience;", 
                "num": 2
              }, 
              {
                "text": "the older women likewise, that they be reverent in behavior, not slanderers, not given to much wine, teachers of good things--", 
                "num": 3
              }, 
              {
                "text": "that they admonish the young women to love their husbands, to love their children,", 
                "num": 4
              }, 
              {
                "text": "to be discreet, chaste, homemakers, good, obedient to their own husbands, that the word of God may not be blasphemed.", 
                "num": 5
              }, 
              {
                "text": "Likewise, exhort the young men to be sober-minded,", 
                "num": 6
              }, 
              {
                "text": "in all things showing yourself to be a pattern of good works; in doctrine showing integrity, reverence, incorruptibility,", 
                "num": 7
              }, 
              {
                "text": "sound speech that cannot be condemned, that one who is an opponent may be ashamed, having nothing evil to say of you.", 
                "num": 8
              }, 
              {
                "text": "Exhort bondservants to be obedient to their own masters, to be well pleasing in all things, not answering back,", 
                "num": 9
              }, 
              {
                "text": "not pilfering, but showing all good fidelity, that they may adorn the doctrine of God our Savior in all things.", 
                "num": 10
              }, 
              {
                "text": "For the grace of God that brings salvation has appeared to all men,", 
                "num": 11
              }, 
              {
                "text": "teaching us that, denying ungodliness and worldly lusts, we should live soberly, righteously, and godly in the present age,", 
                "num": 12
              }, 
              {
                "text": "looking for the blessed hope and glorious appearing of our great God and Savior Jesus Christ,", 
                "num": 13
              }, 
              {
                "text": "who gave Himself for us, that He might redeem us from every lawless deed and purify for Himself His own special people, zealous for good works.", 
                "num": 14
              }, 
              {
                "text": "Speak these things, exhort, and rebuke with all authority. Let no one despise you.", 
                "num": 15
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Remind them to be subject to rulers and authorities, to obey, to be ready for every good work,", 
                "num": 1
              }, 
              {
                "text": "to speak evil of no one, to be peaceable, gentle, showing all humility to all men.", 
                "num": 2
              }, 
              {
                "text": "For we ourselves were also once foolish, disobedient, deceived, serving various lusts and pleasures, living in malice and envy, hateful and hating one another.", 
                "num": 3
              }, 
              {
                "text": "But when the kindness and the love of God our Savior toward man appeared,", 
                "num": 4
              }, 
              {
                "text": "not by works of righteousness which we have done, but according to His mercy He saved us, through the washing of regeneration and renewing of the Holy Spirit,", 
                "num": 5
              }, 
              {
                "text": "whom He poured out on us abundantly through Jesus Christ our Savior,", 
                "num": 6
              }, 
              {
                "text": "that having been justified by His grace we should become heirs according to the hope of eternal life.", 
                "num": 7
              }, 
              {
                "text": "This is a faithful saying, and these things I want you to affirm constantly, that those who have believed in God should be careful to maintain good works. These things are good and profitable to men.", 
                "num": 8
              }, 
              {
                "text": "But avoid foolish disputes, genealogies, contentions, and strivings about the law; for they are unprofitable and useless.", 
                "num": 9
              }, 
              {
                "text": "Reject a divisive man after the first and second admonition,", 
                "num": 10
              }, 
              {
                "text": "knowing that such a person is warped and sinning, being self-condemned.", 
                "num": 11
              }, 
              {
                "text": "When I send Artemas to you, or Tychicus, be diligent to come to me at Nicopolis, for I have decided to spend the winter there.", 
                "num": 12
              }, 
              {
                "text": "Send Zenas the lawyer and Apollos on their journey with haste, that they may lack nothing.", 
                "num": 13
              }, 
              {
                "text": "And let our people also learn to maintain good works, to meet urgent needs, that they may not be unfruitful.", 
                "num": 14
              }, 
              {
                "text": "All who are with me greet you. Greet those who love us in the faith. Grace be with you all. Amen.", 
                "num": 15
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "Philemon", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Paul, a prisoner of Christ Jesus, and Timothy our brother, To Philemon our beloved friend and fellow laborer,", 
                "num": 1
              }, 
              {
                "text": "to the beloved Apphia, Archippus our fellow soldier, and to the church in your house:", 
                "num": 2
              }, 
              {
                "text": "Grace to you and peace from God our Father and the Lord Jesus Christ.", 
                "num": 3
              }, 
              {
                "text": "I thank my God, making mention of you always in my prayers,", 
                "num": 4
              }, 
              {
                "text": "hearing of your love and faith which you have toward the Lord Jesus and toward all the saints,", 
                "num": 5
              }, 
              {
                "text": "that the sharing of your faith may become effective by the acknowledgment of every good thing which is in you in Christ Jesus.", 
                "num": 6
              }, 
              {
                "text": "For we have great joy and consolation in your love, because the hearts of the saints have been refreshed by you, brother.", 
                "num": 7
              }, 
              {
                "text": "Therefore, though I might be very bold in Christ to command you what is fitting,", 
                "num": 8
              }, 
              {
                "text": "yet for love's sake I rather appeal to you--being such a one as Paul, the aged, and now also a prisoner of Jesus Christ--", 
                "num": 9
              }, 
              {
                "text": "I appeal to you for my son Onesimus, whom I have begotten while in my chains,", 
                "num": 10
              }, 
              {
                "text": "who once was unprofitable to you, but now is profitable to you and to me.", 
                "num": 11
              }, 
              {
                "text": "I am sending him back. You therefore receive him, that is, my own heart,", 
                "num": 12
              }, 
              {
                "text": "whom I wished to keep with me, that on your behalf he might minister to me in my chains for the gospel.", 
                "num": 13
              }, 
              {
                "text": "But without your consent I wanted to do nothing, that your good deed might not be by compulsion, as it were, but voluntary.", 
                "num": 14
              }, 
              {
                "text": "For perhaps he departed for a while for this purpose, that you might receive him forever,", 
                "num": 15
              }, 
              {
                "text": "no longer as a slave but more than a slave--a beloved brother, especially to me but how much more to you, both in the flesh and in the Lord.", 
                "num": 16
              }, 
              {
                "text": "If then you count me as a partner, receive him as you would me.", 
                "num": 17
              }, 
              {
                "text": "But if he has wronged you or owes anything, put that on my account.", 
                "num": 18
              }, 
              {
                "text": "I, Paul, am writing with my own hand. I will repay--not to mention to you that you owe me even your own self besides.", 
                "num": 19
              }, 
              {
                "text": "Yes, brother, let me have joy from you in the Lord; refresh my heart in the Lord.", 
                "num": 20
              }, 
              {
                "text": "Having confidence in your obedience, I write to you, knowing that you will do even more than I say.", 
                "num": 21
              }, 
              {
                "text": "But, meanwhile, also prepare a guest room for me, for I trust that through your prayers I shall be granted to you.", 
                "num": 22
              }, 
              {
                "text": "Epaphras, my fellow prisoner in Christ Jesus, greets you,", 
                "num": 23
              }, 
              {
                "text": "as do Mark, Aristarchus, Demas, Luke, my fellow laborers.", 
                "num": 24
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with your spirit. Amen.", 
                "num": 25
              }
            ], 
            "num": 1
          }
        ]
      }, 
      {
        "name": "Hebrews", 
        "chapters": [
          {
            "verses": [
              {
                "text": "God, who at various times and in various ways spoke in time past to the fathers by the prophets,", 
                "num": 1
              }, 
              {
                "text": "has in these last days spoken to us by His Son, whom He has appointed heir of all things, through whom also He made the worlds;", 
                "num": 2
              }, 
              {
                "text": "who being the brightness of His glory and the express image of His person, and upholding all things by the word of His power, when He had by Himself purged our sins, sat down at the right hand of the Majesty on high,", 
                "num": 3
              }, 
              {
                "text": "having become so much better than the angels, as He has by inheritance obtained a more excellent name than they.", 
                "num": 4
              }, 
              {
                "text": "For to which of the angels did He ever say: \"You are My Son, Today I have begotten You\"? And again: \"I will be to Him a Father, And He shall be to Me a Son\"?", 
                "num": 5
              }, 
              {
                "text": "But when He again brings the firstborn into the world, He says: \"Let all the angels of God worship Him.\"", 
                "num": 6
              }, 
              {
                "text": "And of the angels He says: \"Who makes His angels spirits And His ministers a flame of fire.\"", 
                "num": 7
              }, 
              {
                "text": "But to the Son He says: \"Your throne, O God, is forever and ever; A scepter of righteousness is the scepter of Your kingdom.", 
                "num": 8
              }, 
              {
                "text": "You have loved righteousness and hated lawlessness; Therefore God, Your God, has anointed You With the oil of gladness more than Your companions.\"", 
                "num": 9
              }, 
              {
                "text": "And: \"You, LORD, in the beginning laid the foundation of the earth, And the heavens are the work of Your hands.", 
                "num": 10
              }, 
              {
                "text": "They will perish, but You remain; And they will all grow old like a garment;", 
                "num": 11
              }, 
              {
                "text": "Like a cloak You will fold them up, And they will be changed. But You are the same, And Your years will not fail.\"", 
                "num": 12
              }, 
              {
                "text": "But to which of the angels has He ever said: \"Sit at My right hand, Till I make Your enemies Your footstool\"?", 
                "num": 13
              }, 
              {
                "text": "Are they not all ministering spirits sent forth to minister for those who will inherit salvation?", 
                "num": 14
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Therefore we must give the more earnest heed to the things we have heard, lest we drift away.", 
                "num": 1
              }, 
              {
                "text": "For if the word spoken through angels proved steadfast, and every transgression and disobedience received a just reward,", 
                "num": 2
              }, 
              {
                "text": "how shall we escape if we neglect so great a salvation, which at the first began to be spoken by the Lord, and was confirmed to us by those who heard Him,", 
                "num": 3
              }, 
              {
                "text": "God also bearing witness both with signs and wonders, with various miracles, and gifts of the Holy Spirit, according to His own will?", 
                "num": 4
              }, 
              {
                "text": "For He has not put the world to come, of which we speak, in subjection to angels.", 
                "num": 5
              }, 
              {
                "text": "But one testified in a certain place, saying: \"What is man that You are mindful of him, Or the son of man that You take care of him?", 
                "num": 6
              }, 
              {
                "text": "You have made him a little lower than the angels; You have crowned him with glory and honor, And set him over the works of Your hands.", 
                "num": 7
              }, 
              {
                "text": "You have put all things in subjection under his feet.\" For in that He put all in subjection under him, He left nothing that is not put under him. But now we do not yet see all things put under him.", 
                "num": 8
              }, 
              {
                "text": "But we see Jesus, who was made a little lower than the angels, for the suffering of death crowned with glory and honor, that He, by the grace of God, might taste death for everyone.", 
                "num": 9
              }, 
              {
                "text": "For it was fitting for Him, for whom are all things and by whom are all things, in bringing many sons to glory, to make the captain of their salvation perfect through sufferings.", 
                "num": 10
              }, 
              {
                "text": "For both He who sanctifies and those who are being sanctified are all of one, for which reason He is not ashamed to call them brethren,", 
                "num": 11
              }, 
              {
                "text": "saying: \"I will declare Your name to My brethren; In the midst of the assembly I will sing praise to You.\"", 
                "num": 12
              }, 
              {
                "text": "And again: \"I will put My trust in Him.\" And again: \"Here am I and the children whom God has given Me.\"", 
                "num": 13
              }, 
              {
                "text": "Inasmuch then as the children have partaken of flesh and blood, He Himself likewise shared in the same, that through death He might destroy him who had the power of death, that is, the devil,", 
                "num": 14
              }, 
              {
                "text": "and release those who through fear of death were all their lifetime subject to bondage.", 
                "num": 15
              }, 
              {
                "text": "For indeed He does not give aid to angels, but He does give aid to the seed of Abraham.", 
                "num": 16
              }, 
              {
                "text": "Therefore, in all things He had to be made like His brethren, that He might be a merciful and faithful High Priest in things pertaining to God, to make propitiation for the sins of the people.", 
                "num": 17
              }, 
              {
                "text": "For in that He Himself has suffered, being tempted, He is able to aid those who are tempted.", 
                "num": 18
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Therefore, holy brethren, partakers of the heavenly calling, consider the Apostle and High Priest of our confession, Christ Jesus,", 
                "num": 1
              }, 
              {
                "text": "who was faithful to Him who appointed Him, as Moses also was faithful in all His house.", 
                "num": 2
              }, 
              {
                "text": "For this One has been counted worthy of more glory than Moses, inasmuch as He who built the house has more honor than the house.", 
                "num": 3
              }, 
              {
                "text": "For every house is built by someone, but He who built all things is God.", 
                "num": 4
              }, 
              {
                "text": "And Moses indeed was faithful in all His house as a servant, for a testimony of those things which would be spoken afterward,", 
                "num": 5
              }, 
              {
                "text": "but Christ as a Son over His own house, whose house we are if we hold fast the confidence and the rejoicing of the hope firm to the end.", 
                "num": 6
              }, 
              {
                "text": "Therefore, as the Holy Spirit says: \"Today, if you will hear His voice,", 
                "num": 7
              }, 
              {
                "text": "Do not harden your hearts as in the rebellion, In the day of trial in the wilderness,", 
                "num": 8
              }, 
              {
                "text": "Where your fathers tested Me, tried Me, And saw My works forty years.", 
                "num": 9
              }, 
              {
                "text": "Therefore I was angry with that generation, And said, \"They always go astray in their heart, And they have not known My ways.'", 
                "num": 10
              }, 
              {
                "text": "So I swore in My wrath, \"They shall not enter My rest.\"'", 
                "num": 11
              }, 
              {
                "text": "Beware, brethren, lest there be in any of you an evil heart of unbelief in departing from the living God;", 
                "num": 12
              }, 
              {
                "text": "but exhort one another daily, while it is called \"Today,\" lest any of you be hardened through the deceitfulness of sin.", 
                "num": 13
              }, 
              {
                "text": "For we have become partakers of Christ if we hold the beginning of our confidence steadfast to the end,", 
                "num": 14
              }, 
              {
                "text": "while it is said: \"Today, if you will hear His voice, Do not harden your hearts as in the rebellion.\"", 
                "num": 15
              }, 
              {
                "text": "For who, having heard, rebelled? Indeed, was it not all who came out of Egypt, led by Moses?", 
                "num": 16
              }, 
              {
                "text": "Now with whom was He angry forty years? Was it not with those who sinned, whose corpses fell in the wilderness?", 
                "num": 17
              }, 
              {
                "text": "And to whom did He swear that they would not enter His rest, but to those who did not obey?", 
                "num": 18
              }, 
              {
                "text": "So we see that they could not enter in because of unbelief.", 
                "num": 19
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Therefore, since a promise remains of entering His rest, let us fear lest any of you seem to have come short of it.", 
                "num": 1
              }, 
              {
                "text": "For indeed the gospel was preached to us as well as to them; but the word which they heard did not profit them, not being mixed with faith in those who heard it.", 
                "num": 2
              }, 
              {
                "text": "For we who have believed do enter that rest, as He has said: \"So I swore in My wrath, \"They shall not enter My rest,\"' although the works were finished from the foundation of the world.", 
                "num": 3
              }, 
              {
                "text": "For He has spoken in a certain place of the seventh day in this way: \"And God rested on the seventh day from all His works\";", 
                "num": 4
              }, 
              {
                "text": "and again in this place: \"They shall not enter My rest.\"", 
                "num": 5
              }, 
              {
                "text": "Since therefore it remains that some must enter it, and those to whom it was first preached did not enter because of disobedience,", 
                "num": 6
              }, 
              {
                "text": "again He designates a certain day, saying in David, \"Today,\" after such a long time, as it has been said: \"Today, if you will hear His voice, Do not harden your hearts.\"", 
                "num": 7
              }, 
              {
                "text": "For if Joshua had given them rest, then He would not afterward have spoken of another day.", 
                "num": 8
              }, 
              {
                "text": "There remains therefore a rest for the people of God.", 
                "num": 9
              }, 
              {
                "text": "For he who has entered His rest has himself also ceased from his works as God did from His.", 
                "num": 10
              }, 
              {
                "text": "Let us therefore be diligent to enter that rest, lest anyone fall according to the same example of disobedience.", 
                "num": 11
              }, 
              {
                "text": "For the word of God is living and powerful, and sharper than any two-edged sword, piercing even to the division of soul and spirit, and of joints and marrow, and is a discerner of the thoughts and intents of the heart.", 
                "num": 12
              }, 
              {
                "text": "And there is no creature hidden from His sight, but all things are naked and open to the eyes of Him to whom we must give account.", 
                "num": 13
              }, 
              {
                "text": "Seeing then that we have a great High Priest who has passed through the heavens, Jesus the Son of God, let us hold fast our confession.", 
                "num": 14
              }, 
              {
                "text": "For we do not have a High Priest who cannot sympathize with our weaknesses, but was in all points tempted as we are, yet without sin.", 
                "num": 15
              }, 
              {
                "text": "Let us therefore come boldly to the throne of grace, that we may obtain mercy and find grace to help in time of need.", 
                "num": 16
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "For every high priest taken from among men is appointed for men in things pertaining to God, that he may offer both gifts and sacrifices for sins.", 
                "num": 1
              }, 
              {
                "text": "He can have compassion on those who are ignorant and going astray, since he himself is also subject to weakness.", 
                "num": 2
              }, 
              {
                "text": "Because of this he is required as for the people, so also for himself, to offer sacrifices for sins.", 
                "num": 3
              }, 
              {
                "text": "And no man takes this honor to himself, but he who is called by God, just as Aaron was.", 
                "num": 4
              }, 
              {
                "text": "So also Christ did not glorify Himself to become High Priest, but it was He who said to Him: \"You are My Son, Today I have begotten You.\"", 
                "num": 5
              }, 
              {
                "text": "As He also says in another place: \"You are a priest forever According to the order of Melchizedek\";", 
                "num": 6
              }, 
              {
                "text": "who, in the days of His flesh, when He had offered up prayers and supplications, with vehement cries and tears to Him who was able to save Him from death, and was heard because of His godly fear,", 
                "num": 7
              }, 
              {
                "text": "though He was a Son, yet He learned obedience by the things which He suffered.", 
                "num": 8
              }, 
              {
                "text": "And having been perfected, He became the author of eternal salvation to all who obey Him,", 
                "num": 9
              }, 
              {
                "text": "called by God as High Priest \"according to the order of Melchizedek,\"", 
                "num": 10
              }, 
              {
                "text": "of whom we have much to say, and hard to explain, since you have become dull of hearing.", 
                "num": 11
              }, 
              {
                "text": "For though by this time you ought to be teachers, you need someone to teach you again the first principles of the oracles of God; and you have come to need milk and not solid food.", 
                "num": 12
              }, 
              {
                "text": "For everyone who partakes only of milk is unskilled in the word of righteousness, for he is a babe.", 
                "num": 13
              }, 
              {
                "text": "But solid food belongs to those who are of full age, that is, those who by reason of use have their senses exercised to discern both good and evil.", 
                "num": 14
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Therefore, leaving the discussion of the elementary principles of Christ, let us go on to perfection, not laying again the foundation of repentance from dead works and of faith toward God,", 
                "num": 1
              }, 
              {
                "text": "of the doctrine of baptisms, of laying on of hands, of resurrection of the dead, and of eternal judgment.", 
                "num": 2
              }, 
              {
                "text": "And this we will do if God permits.", 
                "num": 3
              }, 
              {
                "text": "For it is impossible for those who were once enlightened, and have tasted the heavenly gift, and have become partakers of the Holy Spirit,", 
                "num": 4
              }, 
              {
                "text": "and have tasted the good word of God and the powers of the age to come,", 
                "num": 5
              }, 
              {
                "text": "if they fall away, to renew them again to repentance, since they crucify again for themselves the Son of God, and put Him to an open shame.", 
                "num": 6
              }, 
              {
                "text": "For the earth which drinks in the rain that often comes upon it, and bears herbs useful for those by whom it is cultivated, receives blessing from God;", 
                "num": 7
              }, 
              {
                "text": "but if it bears thorns and briers, it is rejected and near to being cursed, whose end is to be burned.", 
                "num": 8
              }, 
              {
                "text": "But, beloved, we are confident of better things concerning you, yes, things that accompany salvation, though we speak in this manner.", 
                "num": 9
              }, 
              {
                "text": "For God is not unjust to forget your work and labor of love which you have shown toward His name, in that you have ministered to the saints, and do minister.", 
                "num": 10
              }, 
              {
                "text": "And we desire that each one of you show the same diligence to the full assurance of hope until the end,", 
                "num": 11
              }, 
              {
                "text": "that you do not become sluggish, but imitate those who through faith and patience inherit the promises.", 
                "num": 12
              }, 
              {
                "text": "For when God made a promise to Abraham, because He could swear by no one greater, He swore by Himself,", 
                "num": 13
              }, 
              {
                "text": "saying, \"Surely blessing I will bless you, and multiplying I will multiply you.\"", 
                "num": 14
              }, 
              {
                "text": "And so, after he had patiently endured, he obtained the promise.", 
                "num": 15
              }, 
              {
                "text": "For men indeed swear by the greater, and an oath for confirmation is for them an end of all dispute.", 
                "num": 16
              }, 
              {
                "text": "Thus God, determining to show more abundantly to the heirs of promise the immutability of His counsel, confirmed it by an oath,", 
                "num": 17
              }, 
              {
                "text": "that by two immutable things, in which it is impossible for God to lie, we might have strong consolation, who have fled for refuge to lay hold of the hope set before us.", 
                "num": 18
              }, 
              {
                "text": "This hope we have as an anchor of the soul, both sure and steadfast, and which enters the Presence behind the veil,", 
                "num": 19
              }, 
              {
                "text": "where the forerunner has entered for us, even Jesus, having become High Priest forever according to the order of Melchizedek.", 
                "num": 20
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "For this Melchizedek, king of Salem, priest of the Most High God, who met Abraham returning from the slaughter of the kings and blessed him,", 
                "num": 1
              }, 
              {
                "text": "to whom also Abraham gave a tenth part of all, first being translated \"king of righteousness,\" and then also king of Salem, meaning \"king of peace,\"", 
                "num": 2
              }, 
              {
                "text": "without father, without mother, without genealogy, having neither beginning of days nor end of life, but made like the Son of God, remains a priest continually.", 
                "num": 3
              }, 
              {
                "text": "Now consider how great this man was, to whom even the patriarch Abraham gave a tenth of the spoils.", 
                "num": 4
              }, 
              {
                "text": "And indeed those who are of the sons of Levi, who receive the priesthood, have a commandment to receive tithes from the people according to the law, that is, from their brethren, though they have come from the loins of Abraham;", 
                "num": 5
              }, 
              {
                "text": "but he whose genealogy is not derived from them received tithes from Abraham and blessed him who had the promises.", 
                "num": 6
              }, 
              {
                "text": "Now beyond all contradiction the lesser is blessed by the better.", 
                "num": 7
              }, 
              {
                "text": "Here mortal men receive tithes, but there he receives them, of whom it is witnessed that he lives.", 
                "num": 8
              }, 
              {
                "text": "Even Levi, who receives tithes, paid tithes through Abraham, so to speak,", 
                "num": 9
              }, 
              {
                "text": "for he was still in the loins of his father when Melchizedek met him.", 
                "num": 10
              }, 
              {
                "text": "Therefore, if perfection were through the Levitical priesthood (for under it the people received the law), what further need was there that another priest should rise according to the order of Melchizedek, and not be called according to the order of Aaron?", 
                "num": 11
              }, 
              {
                "text": "For the priesthood being changed, of necessity there is also a change of the law.", 
                "num": 12
              }, 
              {
                "text": "For He of whom these things are spoken belongs to another tribe, from which no man has officiated at the altar.", 
                "num": 13
              }, 
              {
                "text": "For it is evident that our Lord arose from Judah, of which tribe Moses spoke nothing concerning priesthood.", 
                "num": 14
              }, 
              {
                "text": "And it is yet far more evident if, in the likeness of Melchizedek, there arises another priest", 
                "num": 15
              }, 
              {
                "text": "who has come, not according to the law of a fleshly commandment, but according to the power of an endless life.", 
                "num": 16
              }, 
              {
                "text": "For He testifies: \"You are a priest forever According to the order of Melchizedek.\"", 
                "num": 17
              }, 
              {
                "text": "For on the one hand there is an annulling of the former commandment because of its weakness and unprofitableness,", 
                "num": 18
              }, 
              {
                "text": "for the law made nothing perfect; on the other hand, there is the bringing in of a better hope, through which we draw near to God.", 
                "num": 19
              }, 
              {
                "text": "And inasmuch as He was not made priest without an oath", 
                "num": 20
              }, 
              {
                "text": "(for they have become priests without an oath, but He with an oath by Him who said to Him: \"The LORD has sworn And will not relent, \"You are a priest forever According to the order of Melchizedek\"'),", 
                "num": 21
              }, 
              {
                "text": "by so much more Jesus has become a surety of a better covenant.", 
                "num": 22
              }, 
              {
                "text": "Also there were many priests, because they were prevented by death from continuing.", 
                "num": 23
              }, 
              {
                "text": "But He, because He continues forever, has an unchangeable priesthood.", 
                "num": 24
              }, 
              {
                "text": "Therefore He is also able to save to the uttermost those who come to God through Him, since He always lives to make intercession for them.", 
                "num": 25
              }, 
              {
                "text": "For such a High Priest was fitting for us, who is holy, harmless, undefiled, separate from sinners, and has become higher than the heavens;", 
                "num": 26
              }, 
              {
                "text": "who does not need daily, as those high priests, to offer up sacrifices, first for His own sins and then for the people's, for this He did once for all when He offered up Himself.", 
                "num": 27
              }, 
              {
                "text": "For the law appoints as high priests men who have weakness, but the word of the oath, which came after the law, appoints the Son who has been perfected forever.", 
                "num": 28
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "Now this is the main point of the things we are saying: We have such a High Priest, who is seated at the right hand of the throne of the Majesty in the heavens,", 
                "num": 1
              }, 
              {
                "text": "a Minister of the sanctuary and of the true tabernacle which the Lord erected, and not man.", 
                "num": 2
              }, 
              {
                "text": "For every high priest is appointed to offer both gifts and sacrifices. Therefore it is necessary that this One also have something to offer.", 
                "num": 3
              }, 
              {
                "text": "For if He were on earth, He would not be a priest, since there are priests who offer the gifts according to the law;", 
                "num": 4
              }, 
              {
                "text": "who serve the copy and shadow of the heavenly things, as Moses was divinely instructed when he was about to make the tabernacle. For He said, \"See that you make all things according to the pattern shown you on the mountain.\"", 
                "num": 5
              }, 
              {
                "text": "But now He has obtained a more excellent ministry, inasmuch as He is also Mediator of a better covenant, which was established on better promises.", 
                "num": 6
              }, 
              {
                "text": "For if that first covenant had been faultless, then no place would have been sought for a second.", 
                "num": 7
              }, 
              {
                "text": "Because finding fault with them, He says: \"Behold, the days are coming, says the LORD, when I will make a new covenant with the house of Israel and with the house of Judah--", 
                "num": 8
              }, 
              {
                "text": "not according to the covenant that I made with their fathers in the day when I took them by the hand to lead them out of the land of Egypt; because they did not continue in My covenant, and I disregarded them, says the LORD.", 
                "num": 9
              }, 
              {
                "text": "For this is the covenant that I will make with the house of Israel after those days, says the LORD: I will put My laws in their mind and write them on their hearts; and I will be their God, and they shall be My people.", 
                "num": 10
              }, 
              {
                "text": "None of them shall teach his neighbor, and none his brother, saying, \"Know the LORD,' for all shall know Me, from the least of them to the greatest of them.", 
                "num": 11
              }, 
              {
                "text": "For I will be merciful to their unrighteousness, and their sins and their lawless deeds I will remember no more.\"", 
                "num": 12
              }, 
              {
                "text": "In that He says, \"A new covenant,\" He has made the first obsolete. Now what is becoming obsolete and growing old is ready to vanish away.", 
                "num": 13
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then indeed, even the first covenant had ordinances of divine service and the earthly sanctuary.", 
                "num": 1
              }, 
              {
                "text": "For a tabernacle was prepared: the first part, in which was the lampstand, the table, and the showbread, which is called the sanctuary;", 
                "num": 2
              }, 
              {
                "text": "and behind the second veil, the part of the tabernacle which is called the Holiest of All,", 
                "num": 3
              }, 
              {
                "text": "which had the golden censer and the ark of the covenant overlaid on all sides with gold, in which were the golden pot that had the manna, Aaron's rod that budded, and the tablets of the covenant;", 
                "num": 4
              }, 
              {
                "text": "and above it were the cherubim of glory overshadowing the mercy seat. Of these things we cannot now speak in detail.", 
                "num": 5
              }, 
              {
                "text": "Now when these things had been thus prepared, the priests always went into the first part of the tabernacle, performing the services.", 
                "num": 6
              }, 
              {
                "text": "But into the second part the high priest went alone once a year, not without blood, which he offered for himself and for the people's sins committed in ignorance;", 
                "num": 7
              }, 
              {
                "text": "the Holy Spirit indicating this, that the way into the Holiest of All was not yet made manifest while the first tabernacle was still standing.", 
                "num": 8
              }, 
              {
                "text": "It was symbolic for the present time in which both gifts and sacrifices are offered which cannot make him who performed the service perfect in regard to the conscience--", 
                "num": 9
              }, 
              {
                "text": "concerned only with foods and drinks, various washings, and fleshly ordinances imposed until the time of reformation.", 
                "num": 10
              }, 
              {
                "text": "But Christ came as High Priest of the good things to come, with the greater and more perfect tabernacle not made with hands, that is, not of this creation.", 
                "num": 11
              }, 
              {
                "text": "Not with the blood of goats and calves, but with His own blood He entered the Most Holy Place once for all, having obtained eternal redemption.", 
                "num": 12
              }, 
              {
                "text": "For if the blood of bulls and goats and the ashes of a heifer, sprinkling the unclean, sanctifies for the purifying of the flesh,", 
                "num": 13
              }, 
              {
                "text": "how much more shall the blood of Christ, who through the eternal Spirit offered Himself without spot to God, cleanse your conscience from dead works to serve the living God?", 
                "num": 14
              }, 
              {
                "text": "And for this reason He is the Mediator of the new covenant, by means of death, for the redemption of the transgressions under the first covenant, that those who are called may receive the promise of the eternal inheritance.", 
                "num": 15
              }, 
              {
                "text": "For where there is a testament, there must also of necessity be the death of the testator.", 
                "num": 16
              }, 
              {
                "text": "For a testament is in force after men are dead, since it has no power at all while the testator lives.", 
                "num": 17
              }, 
              {
                "text": "Therefore not even the first covenant was dedicated without blood.", 
                "num": 18
              }, 
              {
                "text": "For when Moses had spoken every precept to all the people according to the law, he took the blood of calves and goats, with water, scarlet wool, and hyssop, and sprinkled both the book itself and all the people,", 
                "num": 19
              }, 
              {
                "text": "saying, \"This is the blood of the covenant which God has commanded you.\"", 
                "num": 20
              }, 
              {
                "text": "Then likewise he sprinkled with blood both the tabernacle and all the vessels of the ministry.", 
                "num": 21
              }, 
              {
                "text": "And according to the law almost all things are purified with blood, and without shedding of blood there is no remission.", 
                "num": 22
              }, 
              {
                "text": "Therefore it was necessary that the copies of the things in the heavens should be purified with these, but the heavenly things themselves with better sacrifices than these.", 
                "num": 23
              }, 
              {
                "text": "For Christ has not entered the holy places made with hands, which are copies of the true, but into heaven itself, now to appear in the presence of God for us;", 
                "num": 24
              }, 
              {
                "text": "not that He should offer Himself often, as the high priest enters the Most Holy Place every year with blood of another--", 
                "num": 25
              }, 
              {
                "text": "He then would have had to suffer often since the foundation of the world; but now, once at the end of the ages, He has appeared to put away sin by the sacrifice of Himself.", 
                "num": 26
              }, 
              {
                "text": "And as it is appointed for men to die once, but after this the judgment,", 
                "num": 27
              }, 
              {
                "text": "so Christ was offered once to bear the sins of many. To those who eagerly wait for Him He will appear a second time, apart from sin, for salvation.", 
                "num": 28
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "For the law, having a shadow of the good things to come, and not the very image of the things, can never with these same sacrifices, which they offer continually year by year, make those who approach perfect.", 
                "num": 1
              }, 
              {
                "text": "For then would they not have ceased to be offered? For the worshipers, once purified, would have had no more consciousness of sins.", 
                "num": 2
              }, 
              {
                "text": "But in those sacrifices there is a reminder of sins every year.", 
                "num": 3
              }, 
              {
                "text": "For it is not possible that the blood of bulls and goats could take away sins.", 
                "num": 4
              }, 
              {
                "text": "Therefore, when He came into the world, He said: \"Sacrifice and offering You did not desire, But a body You have prepared for Me.", 
                "num": 5
              }, 
              {
                "text": "In burnt offerings and sacrifices for sin You had no pleasure.", 
                "num": 6
              }, 
              {
                "text": "Then I said, \"Behold, I have come-- In the volume of the book it is written of Me-- To do Your will, O God.\"'", 
                "num": 7
              }, 
              {
                "text": "Previously saying, \"Sacrifice and offering, burnt offerings, and offerings for sin You did not desire, nor had pleasure in them\" (which are offered according to the law),", 
                "num": 8
              }, 
              {
                "text": "then He said, \"Behold, I have come to do Your will, O God.\" He takes away the first that He may establish the second.", 
                "num": 9
              }, 
              {
                "text": "By that will we have been sanctified through the offering of the body of Jesus Christ once for all.", 
                "num": 10
              }, 
              {
                "text": "And every priest stands ministering daily and offering repeatedly the same sacrifices, which can never take away sins.", 
                "num": 11
              }, 
              {
                "text": "But this Man, after He had offered one sacrifice for sins forever, sat down at the right hand of God,", 
                "num": 12
              }, 
              {
                "text": "from that time waiting till His enemies are made His footstool.", 
                "num": 13
              }, 
              {
                "text": "For by one offering He has perfected forever those who are being sanctified.", 
                "num": 14
              }, 
              {
                "text": "But the Holy Spirit also witnesses to us; for after He had said before,", 
                "num": 15
              }, 
              {
                "text": "\"This is the covenant that I will make with them after those days, says the LORD: I will put My laws into their hearts, and in their minds I will write them,\"", 
                "num": 16
              }, 
              {
                "text": "then He adds, \"Their sins and their lawless deeds I will remember no more.\"", 
                "num": 17
              }, 
              {
                "text": "Now where there is remission of these, there is no longer an offering for sin.", 
                "num": 18
              }, 
              {
                "text": "Therefore, brethren, having boldness to enter the Holiest by the blood of Jesus,", 
                "num": 19
              }, 
              {
                "text": "by a new and living way which He consecrated for us, through the veil, that is, His flesh,", 
                "num": 20
              }, 
              {
                "text": "and having a High Priest over the house of God,", 
                "num": 21
              }, 
              {
                "text": "let us draw near with a true heart in full assurance of faith, having our hearts sprinkled from an evil conscience and our bodies washed with pure water.", 
                "num": 22
              }, 
              {
                "text": "Let us hold fast the confession of our hope without wavering, for He who promised is faithful.", 
                "num": 23
              }, 
              {
                "text": "And let us consider one another in order to stir up love and good works,", 
                "num": 24
              }, 
              {
                "text": "not forsaking the assembling of ourselves together, as is the manner of some, but exhorting one another, and so much the more as you see the Day approaching.", 
                "num": 25
              }, 
              {
                "text": "For if we sin willfully after we have received the knowledge of the truth, there no longer remains a sacrifice for sins,", 
                "num": 26
              }, 
              {
                "text": "but a certain fearful expectation of judgment, and fiery indignation which will devour the adversaries.", 
                "num": 27
              }, 
              {
                "text": "Anyone who has rejected Moses' law dies without mercy on the testimony of two or three witnesses.", 
                "num": 28
              }, 
              {
                "text": "Of how much worse punishment, do you suppose, will he be thought worthy who has trampled the Son of God underfoot, counted the blood of the covenant by which he was sanctified a common thing, and insulted the Spirit of grace?", 
                "num": 29
              }, 
              {
                "text": "For we know Him who said, \"Vengeance is Mine, I will repay,\" says the Lord. And again, \"The LORD will judge His people.\"", 
                "num": 30
              }, 
              {
                "text": "It is a fearful thing to fall into the hands of the living God.", 
                "num": 31
              }, 
              {
                "text": "But recall the former days in which, after you were illuminated, you endured a great struggle with sufferings:", 
                "num": 32
              }, 
              {
                "text": "partly while you were made a spectacle both by reproaches and tribulations, and partly while you became companions of those who were so treated;", 
                "num": 33
              }, 
              {
                "text": "for you had compassion on me in my chains, and joyfully accepted the plundering of your goods, knowing that you have a better and an enduring possession for yourselves in heaven.", 
                "num": 34
              }, 
              {
                "text": "Therefore do not cast away your confidence, which has great reward.", 
                "num": 35
              }, 
              {
                "text": "For you have need of endurance, so that after you have done the will of God, you may receive the promise:", 
                "num": 36
              }, 
              {
                "text": "\"For yet a little while, And He who is coming will come and will not tarry.", 
                "num": 37
              }, 
              {
                "text": "Now the just shall live by faith; But if anyone draws back, My soul has no pleasure in him.\"", 
                "num": 38
              }, 
              {
                "text": "But we are not of those who draw back to perdition, but of those who believe to the saving of the soul.", 
                "num": 39
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Now faith is the substance of things hoped for, the evidence of things not seen.", 
                "num": 1
              }, 
              {
                "text": "For by it the elders obtained a good testimony.", 
                "num": 2
              }, 
              {
                "text": "By faith we understand that the worlds were framed by the word of God, so that the things which are seen were not made of things which are visible.", 
                "num": 3
              }, 
              {
                "text": "By faith Abel offered to God a more excellent sacrifice than Cain, through which he obtained witness that he was righteous, God testifying of his gifts; and through it he being dead still speaks.", 
                "num": 4
              }, 
              {
                "text": "By faith Enoch was taken away so that he did not see death, \"and was not found, because God had taken him\"; for before he was taken he had this testimony, that he pleased God.", 
                "num": 5
              }, 
              {
                "text": "But without faith it is impossible to please Him, for he who comes to God must believe that He is, and that He is a rewarder of those who diligently seek Him.", 
                "num": 6
              }, 
              {
                "text": "By faith Noah, being divinely warned of things not yet seen, moved with godly fear, prepared an ark for the saving of his household, by which he condemned the world and became heir of the righteousness which is according to faith.", 
                "num": 7
              }, 
              {
                "text": "By faith Abraham obeyed when he was called to go out to the place which he would receive as an inheritance. And he went out, not knowing where he was going.", 
                "num": 8
              }, 
              {
                "text": "By faith he dwelt in the land of promise as in a foreign country, dwelling in tents with Isaac and Jacob, the heirs with him of the same promise;", 
                "num": 9
              }, 
              {
                "text": "for he waited for the city which has foundations, whose builder and maker is God.", 
                "num": 10
              }, 
              {
                "text": "By faith Sarah herself also received strength to conceive seed, and she bore a child when she was past the age, because she judged Him faithful who had promised.", 
                "num": 11
              }, 
              {
                "text": "Therefore from one man, and him as good as dead, were born as many as the stars of the sky in multitude--innumerable as the sand which is by the seashore.", 
                "num": 12
              }, 
              {
                "text": "These all died in faith, not having received the promises, but having seen them afar off were assured of them, embraced them and confessed that they were strangers and pilgrims on the earth.", 
                "num": 13
              }, 
              {
                "text": "For those who say such things declare plainly that they seek a homeland.", 
                "num": 14
              }, 
              {
                "text": "And truly if they had called to mind that country from which they had come out, they would have had opportunity to return.", 
                "num": 15
              }, 
              {
                "text": "But now they desire a better, that is, a heavenly country. Therefore God is not ashamed to be called their God, for He has prepared a city for them.", 
                "num": 16
              }, 
              {
                "text": "By faith Abraham, when he was tested, offered up Isaac, and he who had received the promises offered up his only begotten son,", 
                "num": 17
              }, 
              {
                "text": "of whom it was said, \"In Isaac your seed shall be called,\"", 
                "num": 18
              }, 
              {
                "text": "concluding that God was able to raise him up, even from the dead, from which he also received him in a figurative sense.", 
                "num": 19
              }, 
              {
                "text": "By faith Isaac blessed Jacob and Esau concerning things to come.", 
                "num": 20
              }, 
              {
                "text": "By faith Jacob, when he was dying, blessed each of the sons of Joseph, and worshiped, leaning on the top of his staff.", 
                "num": 21
              }, 
              {
                "text": "By faith Joseph, when he was dying, made mention of the departure of the children of Israel, and gave instructions concerning his bones.", 
                "num": 22
              }, 
              {
                "text": "By faith Moses, when he was born, was hidden three months by his parents, because they saw he was a beautiful child; and they were not afraid of the king's command.", 
                "num": 23
              }, 
              {
                "text": "By faith Moses, when he became of age, refused to be called the son of Pharaoh's daughter,", 
                "num": 24
              }, 
              {
                "text": "choosing rather to suffer affliction with the people of God than to enjoy the passing pleasures of sin,", 
                "num": 25
              }, 
              {
                "text": "esteeming the reproach of Christ greater riches than the treasures in Egypt; for he looked to the reward.", 
                "num": 26
              }, 
              {
                "text": "By faith he forsook Egypt, not fearing the wrath of the king; for he endured as seeing Him who is invisible.", 
                "num": 27
              }, 
              {
                "text": "By faith he kept the Passover and the sprinkling of blood, lest he who destroyed the firstborn should touch them.", 
                "num": 28
              }, 
              {
                "text": "By faith they passed through the Red Sea as by dry land, whereas the Egyptians, attempting to do so, were drowned.", 
                "num": 29
              }, 
              {
                "text": "By faith the walls of Jericho fell down after they were encircled for seven days.", 
                "num": 30
              }, 
              {
                "text": "By faith the harlot Rahab did not perish with those who did not believe, when she had received the spies with peace.", 
                "num": 31
              }, 
              {
                "text": "And what more shall I say? For the time would fail me to tell of Gideon and Barak and Samson and Jephthah, also of David and Samuel and the prophets:", 
                "num": 32
              }, 
              {
                "text": "who through faith subdued kingdoms, worked righteousness, obtained promises, stopped the mouths of lions,", 
                "num": 33
              }, 
              {
                "text": "quenched the violence of fire, escaped the edge of the sword, out of weakness were made strong, became valiant in battle, turned to flight the armies of the aliens.", 
                "num": 34
              }, 
              {
                "text": "Women received their dead raised to life again. Others were tortured, not accepting deliverance, that they might obtain a better resurrection.", 
                "num": 35
              }, 
              {
                "text": "Still others had trial of mockings and scourgings, yes, and of chains and imprisonment.", 
                "num": 36
              }, 
              {
                "text": "They were stoned, they were sawn in two, were tempted, were slain with the sword. They wandered about in sheepskins and goatskins, being destitute, afflicted, tormented--", 
                "num": 37
              }, 
              {
                "text": "of whom the world was not worthy. They wandered in deserts and mountains, in dens and caves of the earth.", 
                "num": 38
              }, 
              {
                "text": "And all these, having obtained a good testimony through faith, did not receive the promise,", 
                "num": 39
              }, 
              {
                "text": "God having provided something better for us, that they should not be made perfect apart from us.", 
                "num": 40
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Therefore we also, since we are surrounded by so great a cloud of witnesses, let us lay aside every weight, and the sin which so easily ensnares us, and let us run with endurance the race that is set before us,", 
                "num": 1
              }, 
              {
                "text": "looking unto Jesus, the author and finisher of our faith, who for the joy that was set before Him endured the cross, despising the shame, and has sat down at the right hand of the throne of God.", 
                "num": 2
              }, 
              {
                "text": "For consider Him who endured such hostility from sinners against Himself, lest you become weary and discouraged in your souls.", 
                "num": 3
              }, 
              {
                "text": "You have not yet resisted to bloodshed, striving against sin.", 
                "num": 4
              }, 
              {
                "text": "And you have forgotten the exhortation which speaks to you as to sons: \"My son, do not despise the chastening of the LORD, Nor be discouraged when you are rebuked by Him;", 
                "num": 5
              }, 
              {
                "text": "For whom the LORD loves He chastens, And scourges every son whom He receives.\"", 
                "num": 6
              }, 
              {
                "text": "If you endure chastening, God deals with you as with sons; for what son is there whom a father does not chasten?", 
                "num": 7
              }, 
              {
                "text": "But if you are without chastening, of which all have become partakers, then you are illegitimate and not sons.", 
                "num": 8
              }, 
              {
                "text": "Furthermore, we have had human fathers who corrected us, and we paid them respect. Shall we not much more readily be in subjection to the Father of spirits and live?", 
                "num": 9
              }, 
              {
                "text": "For they indeed for a few days chastened us as seemed best to them, but He for our profit, that we may be partakers of His holiness.", 
                "num": 10
              }, 
              {
                "text": "Now no chastening seems to be joyful for the present, but painful; nevertheless, afterward it yields the peaceable fruit of righteousness to those who have been trained by it.", 
                "num": 11
              }, 
              {
                "text": "Therefore strengthen the hands which hang down, and the feeble knees,", 
                "num": 12
              }, 
              {
                "text": "and make straight paths for your feet, so that what is lame may not be dislocated, but rather be healed.", 
                "num": 13
              }, 
              {
                "text": "Pursue peace with all people, and holiness, without which no one will see the Lord:", 
                "num": 14
              }, 
              {
                "text": "looking carefully lest anyone fall short of the grace of God; lest any root of bitterness springing up cause trouble, and by this many become defiled;", 
                "num": 15
              }, 
              {
                "text": "lest there be any fornicator or profane person like Esau, who for one morsel of food sold his birthright.", 
                "num": 16
              }, 
              {
                "text": "For you know that afterward, when he wanted to inherit the blessing, he was rejected, for he found no place for repentance, though he sought it diligently with tears.", 
                "num": 17
              }, 
              {
                "text": "For you have not come to the mountain that may be touched and that burned with fire, and to blackness and darkness and tempest,", 
                "num": 18
              }, 
              {
                "text": "and the sound of a trumpet and the voice of words, so that those who heard it begged that the word should not be spoken to them anymore.", 
                "num": 19
              }, 
              {
                "text": "(For they could not endure what was commanded: \"And if so much as a beast touches the mountain, it shall be stoned or shot with an arrow.\"", 
                "num": 20
              }, 
              {
                "text": "And so terrifying was the sight that Moses said, \"I am exceedingly afraid and trembling.\")", 
                "num": 21
              }, 
              {
                "text": "But you have come to Mount Zion and to the city of the living God, the heavenly Jerusalem, to an innumerable company of angels,", 
                "num": 22
              }, 
              {
                "text": "to the general assembly and church of the firstborn who are registered in heaven, to God the Judge of all, to the spirits of just men made perfect,", 
                "num": 23
              }, 
              {
                "text": "to Jesus the Mediator of the new covenant, and to the blood of sprinkling that speaks better things than that of Abel.", 
                "num": 24
              }, 
              {
                "text": "See that you do not refuse Him who speaks. For if they did not escape who refused Him who spoke on earth, much more shall we not escape if we turn away from Him who speaks from heaven,", 
                "num": 25
              }, 
              {
                "text": "whose voice then shook the earth; but now He has promised, saying, \"Yet once more I shake not only the earth, but also heaven.\"", 
                "num": 26
              }, 
              {
                "text": "Now this, \"Yet once more,\" indicates the removal of those things that are being shaken, as of things that are made, that the things which cannot be shaken may remain.", 
                "num": 27
              }, 
              {
                "text": "Therefore, since we are receiving a kingdom which cannot be shaken, let us have grace, by which we may serve God acceptably with reverence and godly fear.", 
                "num": 28
              }, 
              {
                "text": "For our God is a consuming fire.", 
                "num": 29
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Let brotherly love continue.", 
                "num": 1
              }, 
              {
                "text": "Do not forget to entertain strangers, for by so doing some have unwittingly entertained angels.", 
                "num": 2
              }, 
              {
                "text": "Remember the prisoners as if chained with them--those who are mistreated--since you yourselves are in the body also.", 
                "num": 3
              }, 
              {
                "text": "Marriage is honorable among all, and the bed undefiled; but fornicators and adulterers God will judge.", 
                "num": 4
              }, 
              {
                "text": "Let your conduct be without covetousness; be content with such things as you have. For He Himself has said, \"I will never leave you nor forsake you.\"", 
                "num": 5
              }, 
              {
                "text": "So we may boldly say: \"The LORD is my helper; I will not fear. What can man do to me?\"", 
                "num": 6
              }, 
              {
                "text": "Remember those who rule over you, who have spoken the word of God to you, whose faith follow, considering the outcome of their conduct.", 
                "num": 7
              }, 
              {
                "text": "Jesus Christ is the same yesterday, today, and forever.", 
                "num": 8
              }, 
              {
                "text": "Do not be carried about with various and strange doctrines. For it is good that the heart be established by grace, not with foods which have not profited those who have been occupied with them.", 
                "num": 9
              }, 
              {
                "text": "We have an altar from which those who serve the tabernacle have no right to eat.", 
                "num": 10
              }, 
              {
                "text": "For the bodies of those animals, whose blood is brought into the sanctuary by the high priest for sin, are burned outside the camp.", 
                "num": 11
              }, 
              {
                "text": "Therefore Jesus also, that He might sanctify the people with His own blood, suffered outside the gate.", 
                "num": 12
              }, 
              {
                "text": "Therefore let us go forth to Him, outside the camp, bearing His reproach.", 
                "num": 13
              }, 
              {
                "text": "For here we have no continuing city, but we seek the one to come.", 
                "num": 14
              }, 
              {
                "text": "Therefore by Him let us continually offer the sacrifice of praise to God, that is, the fruit of our lips, giving thanks to His name.", 
                "num": 15
              }, 
              {
                "text": "But do not forget to do good and to share, for with such sacrifices God is well pleased.", 
                "num": 16
              }, 
              {
                "text": "Obey those who rule over you, and be submissive, for they watch out for your souls, as those who must give account. Let them do so with joy and not with grief, for that would be unprofitable for you.", 
                "num": 17
              }, 
              {
                "text": "Pray for us; for we are confident that we have a good conscience, in all things desiring to live honorably.", 
                "num": 18
              }, 
              {
                "text": "But I especially urge you to do this, that I may be restored to you the sooner.", 
                "num": 19
              }, 
              {
                "text": "Now may the God of peace who brought up our Lord Jesus from the dead, that great Shepherd of the sheep, through the blood of the everlasting covenant,", 
                "num": 20
              }, 
              {
                "text": "make you complete in every good work to do His will, working in you what is well pleasing in His sight, through Jesus Christ, to whom be glory forever and ever. Amen.", 
                "num": 21
              }, 
              {
                "text": "And I appeal to you, brethren, bear with the word of exhortation, for I have written to you in few words.", 
                "num": 22
              }, 
              {
                "text": "Know that our brother Timothy has been set free, with whom I shall see you if he comes shortly.", 
                "num": 23
              }, 
              {
                "text": "Greet all those who rule over you, and all the saints. Those from Italy greet you.", 
                "num": 24
              }, 
              {
                "text": "Grace be with you all. Amen.", 
                "num": 25
              }
            ], 
            "num": 13
          }
        ]
      }, 
      {
        "name": "James", 
        "chapters": [
          {
            "verses": [
              {
                "text": "James, a bondservant of God and of the Lord Jesus Christ, To the twelve tribes which are scattered abroad: Greetings.", 
                "num": 1
              }, 
              {
                "text": "My brethren, count it all joy when you fall into various trials,", 
                "num": 2
              }, 
              {
                "text": "knowing that the testing of your faith produces patience.", 
                "num": 3
              }, 
              {
                "text": "But let patience have its perfect work, that you may be perfect and complete, lacking nothing.", 
                "num": 4
              }, 
              {
                "text": "If any of you lacks wisdom, let him ask of God, who gives to all liberally and without reproach, and it will be given to him.", 
                "num": 5
              }, 
              {
                "text": "But let him ask in faith, with no doubting, for he who doubts is like a wave of the sea driven and tossed by the wind.", 
                "num": 6
              }, 
              {
                "text": "For let not that man suppose that he will receive anything from the Lord;", 
                "num": 7
              }, 
              {
                "text": "he is a double-minded man, unstable in all his ways.", 
                "num": 8
              }, 
              {
                "text": "Let the lowly brother glory in his exaltation,", 
                "num": 9
              }, 
              {
                "text": "but the rich in his humiliation, because as a flower of the field he will pass away.", 
                "num": 10
              }, 
              {
                "text": "For no sooner has the sun risen with a burning heat than it withers the grass; its flower falls, and its beautiful appearance perishes. So the rich man also will fade away in his pursuits.", 
                "num": 11
              }, 
              {
                "text": "Blessed is the man who endures temptation; for when he has been approved, he will receive the crown of life which the Lord has promised to those who love Him.", 
                "num": 12
              }, 
              {
                "text": "Let no one say when he is tempted, \"I am tempted by God\"; for God cannot be tempted by evil, nor does He Himself tempt anyone.", 
                "num": 13
              }, 
              {
                "text": "But each one is tempted when he is drawn away by his own desires and enticed.", 
                "num": 14
              }, 
              {
                "text": "Then, when desire has conceived, it gives birth to sin; and sin, when it is full-grown, brings forth death.", 
                "num": 15
              }, 
              {
                "text": "Do not be deceived, my beloved brethren.", 
                "num": 16
              }, 
              {
                "text": "Every good gift and every perfect gift is from above, and comes down from the Father of lights, with whom there is no variation or shadow of turning.", 
                "num": 17
              }, 
              {
                "text": "Of His own will He brought us forth by the word of truth, that we might be a kind of firstfruits of His creatures.", 
                "num": 18
              }, 
              {
                "text": "So then, my beloved brethren, let every man be swift to hear, slow to speak, slow to wrath;", 
                "num": 19
              }, 
              {
                "text": "for the wrath of man does not produce the righteousness of God.", 
                "num": 20
              }, 
              {
                "text": "Therefore lay aside all filthiness and overflow of wickedness, and receive with meekness the implanted word, which is able to save your souls.", 
                "num": 21
              }, 
              {
                "text": "But be doers of the word, and not hearers only, deceiving yourselves.", 
                "num": 22
              }, 
              {
                "text": "For if anyone is a hearer of the word and not a doer, he is like a man observing his natural face in a mirror;", 
                "num": 23
              }, 
              {
                "text": "for he observes himself, goes away, and immediately forgets what kind of man he was.", 
                "num": 24
              }, 
              {
                "text": "But he who looks into the perfect law of liberty and continues in it, and is not a forgetful hearer but a doer of the work, this one will be blessed in what he does.", 
                "num": 25
              }, 
              {
                "text": "If anyone among you thinks he is religious, and does not bridle his tongue but deceives his own heart, this one's religion is useless.", 
                "num": 26
              }, 
              {
                "text": "Pure and undefiled religion before God and the Father is this: to visit orphans and widows in their trouble, and to keep oneself unspotted from the world.", 
                "num": 27
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "My brethren, do not hold the faith of our Lord Jesus Christ, the Lord of glory, with partiality.", 
                "num": 1
              }, 
              {
                "text": "For if there should come into your assembly a man with gold rings, in fine apparel, and there should also come in a poor man in filthy clothes,", 
                "num": 2
              }, 
              {
                "text": "and you pay attention to the one wearing the fine clothes and say to him, \"You sit here in a good place,\" and say to the poor man, \"You stand there,\" or, \"Sit here at my footstool,\"", 
                "num": 3
              }, 
              {
                "text": "have you not shown partiality among yourselves, and become judges with evil thoughts?", 
                "num": 4
              }, 
              {
                "text": "Listen, my beloved brethren: Has God not chosen the poor of this world to be rich in faith and heirs of the kingdom which He promised to those who love Him?", 
                "num": 5
              }, 
              {
                "text": "But you have dishonored the poor man. Do not the rich oppress you and drag you into the courts?", 
                "num": 6
              }, 
              {
                "text": "Do they not blaspheme that noble name by which you are called?", 
                "num": 7
              }, 
              {
                "text": "If you really fulfill the royal law according to the Scripture, \"You shall love your neighbor as yourself,\" you do well;", 
                "num": 8
              }, 
              {
                "text": "but if you show partiality, you commit sin, and are convicted by the law as transgressors.", 
                "num": 9
              }, 
              {
                "text": "For whoever shall keep the whole law, and yet stumble in one point, he is guilty of all.", 
                "num": 10
              }, 
              {
                "text": "For He who said, \"Do not commit adultery,\" also said, \"Do not murder.\" Now if you do not commit adultery, but you do murder, you have become a transgressor of the law.", 
                "num": 11
              }, 
              {
                "text": "So speak and so do as those who will be judged by the law of liberty.", 
                "num": 12
              }, 
              {
                "text": "For judgment is without mercy to the one who has shown no mercy. Mercy triumphs over judgment.", 
                "num": 13
              }, 
              {
                "text": "What does it profit, my brethren, if someone says he has faith but does not have works? Can faith save him?", 
                "num": 14
              }, 
              {
                "text": "If a brother or sister is naked and destitute of daily food,", 
                "num": 15
              }, 
              {
                "text": "and one of you says to them, \"Depart in peace, be warmed and filled,\" but you do not give them the things which are needed for the body, what does it profit?", 
                "num": 16
              }, 
              {
                "text": "Thus also faith by itself, if it does not have works, is dead.", 
                "num": 17
              }, 
              {
                "text": "But someone will say, \"You have faith, and I have works.\" Show me your faith without your works, and I will show you my faith by my works.", 
                "num": 18
              }, 
              {
                "text": "You believe that there is one God. You do well. Even the demons believe--and tremble!", 
                "num": 19
              }, 
              {
                "text": "But do you want to know, O foolish man, that faith without works is dead?", 
                "num": 20
              }, 
              {
                "text": "Was not Abraham our father justified by works when he offered Isaac his son on the altar?", 
                "num": 21
              }, 
              {
                "text": "Do you see that faith was working together with his works, and by works faith was made perfect?", 
                "num": 22
              }, 
              {
                "text": "And the Scripture was fulfilled which says, \"Abraham believed God, and it was accounted to him for righteousness.\" And he was called the friend of God.", 
                "num": 23
              }, 
              {
                "text": "You see then that a man is justified by works, and not by faith only.", 
                "num": 24
              }, 
              {
                "text": "Likewise, was not Rahab the harlot also justified by works when she received the messengers and sent them out another way?", 
                "num": 25
              }, 
              {
                "text": "For as the body without the spirit is dead, so faith without works is dead also.", 
                "num": 26
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "My brethren, let not many of you become teachers, knowing that we shall receive a stricter judgment.", 
                "num": 1
              }, 
              {
                "text": "For we all stumble in many things. If anyone does not stumble in word, he is a perfect man, able also to bridle the whole body.", 
                "num": 2
              }, 
              {
                "text": "Indeed, we put bits in horses' mouths that they may obey us, and we turn their whole body.", 
                "num": 3
              }, 
              {
                "text": "Look also at ships: although they are so large and are driven by fierce winds, they are turned by a very small rudder wherever the pilot desires.", 
                "num": 4
              }, 
              {
                "text": "Even so the tongue is a little member and boasts great things. See how great a forest a little fire kindles!", 
                "num": 5
              }, 
              {
                "text": "And the tongue is a fire, a world of iniquity. The tongue is so set among our members that it defiles the whole body, and sets on fire the course of nature; and it is set on fire by hell.", 
                "num": 6
              }, 
              {
                "text": "For every kind of beast and bird, of reptile and creature of the sea, is tamed and has been tamed by mankind.", 
                "num": 7
              }, 
              {
                "text": "But no man can tame the tongue. It is an unruly evil, full of deadly poison.", 
                "num": 8
              }, 
              {
                "text": "With it we bless our God and Father, and with it we curse men, who have been made in the similitude of God.", 
                "num": 9
              }, 
              {
                "text": "Out of the same mouth proceed blessing and cursing. My brethren, these things ought not to be so.", 
                "num": 10
              }, 
              {
                "text": "Does a spring send forth fresh water and bitter from the same opening?", 
                "num": 11
              }, 
              {
                "text": "Can a fig tree, my brethren, bear olives, or a grapevine bear figs? Thus no spring yields both salt water and fresh.", 
                "num": 12
              }, 
              {
                "text": "Who is wise and understanding among you? Let him show by good conduct that his works are done in the meekness of wisdom.", 
                "num": 13
              }, 
              {
                "text": "But if you have bitter envy and self-seeking in your hearts, do not boast and lie against the truth.", 
                "num": 14
              }, 
              {
                "text": "This wisdom does not descend from above, but is earthly, sensual, demonic.", 
                "num": 15
              }, 
              {
                "text": "For where envy and self-seeking exist, confusion and every evil thing are there.", 
                "num": 16
              }, 
              {
                "text": "But the wisdom that is from above is first pure, then peaceable, gentle, willing to yield, full of mercy and good fruits, without partiality and without hypocrisy.", 
                "num": 17
              }, 
              {
                "text": "Now the fruit of righteousness is sown in peace by those who make peace.", 
                "num": 18
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Where do wars and fights come from among you? Do they not come from your desires for pleasure that war in your members?", 
                "num": 1
              }, 
              {
                "text": "You lust and do not have. You murder and covet and cannot obtain. You fight and war. Yet you do not have because you do not ask.", 
                "num": 2
              }, 
              {
                "text": "You ask and do not receive, because you ask amiss, that you may spend it on your pleasures.", 
                "num": 3
              }, 
              {
                "text": "Adulterers and adulteresses! Do you not know that friendship with the world is enmity with God? Whoever therefore wants to be a friend of the world makes himself an enemy of God.", 
                "num": 4
              }, 
              {
                "text": "Or do you think that the Scripture says in vain, \"The Spirit who dwells in us yearns jealously\"?", 
                "num": 5
              }, 
              {
                "text": "But He gives more grace. Therefore He says: \"God resists the proud, But gives grace to the humble.\"", 
                "num": 6
              }, 
              {
                "text": "Therefore submit to God. Resist the devil and he will flee from you.", 
                "num": 7
              }, 
              {
                "text": "Draw near to God and He will draw near to you. Cleanse your hands, you sinners; and purify your hearts, you double-minded.", 
                "num": 8
              }, 
              {
                "text": "Lament and mourn and weep! Let your laughter be turned to mourning and your joy to gloom.", 
                "num": 9
              }, 
              {
                "text": "Humble yourselves in the sight of the Lord, and He will lift you up.", 
                "num": 10
              }, 
              {
                "text": "Do not speak evil of one another, brethren. He who speaks evil of a brother and judges his brother, speaks evil of the law and judges the law. But if you judge the law, you are not a doer of the law but a judge.", 
                "num": 11
              }, 
              {
                "text": "There is one Lawgiver, who is able to save and to destroy. Who are you to judge another?", 
                "num": 12
              }, 
              {
                "text": "Come now, you who say, \"Today or tomorrow we will go to such and such a city, spend a year there, buy and sell, and make a profit\";", 
                "num": 13
              }, 
              {
                "text": "whereas you do not know what will happen tomorrow. For what is your life? It is even a vapor that appears for a little time and then vanishes away.", 
                "num": 14
              }, 
              {
                "text": "Instead you ought to say, \"If the Lord wills, we shall live and do this or that.\"", 
                "num": 15
              }, 
              {
                "text": "But now you boast in your arrogance. All such boasting is evil.", 
                "num": 16
              }, 
              {
                "text": "Therefore, to him who knows to do good and does not do it, to him it is sin.", 
                "num": 17
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Come now, you rich, weep and howl for your miseries that are coming upon you!", 
                "num": 1
              }, 
              {
                "text": "Your riches are corrupted, and your garments are moth-eaten.", 
                "num": 2
              }, 
              {
                "text": "Your gold and silver are corroded, and their corrosion will be a witness against you and will eat your flesh like fire. You have heaped up treasure in the last days.", 
                "num": 3
              }, 
              {
                "text": "Indeed the wages of the laborers who mowed your fields, which you kept back by fraud, cry out; and the cries of the reapers have reached the ears of the Lord of Sabaoth.", 
                "num": 4
              }, 
              {
                "text": "You have lived on the earth in pleasure and luxury; you have fattened your hearts as in a day of slaughter.", 
                "num": 5
              }, 
              {
                "text": "You have condemned, you have murdered the just; he does not resist you.", 
                "num": 6
              }, 
              {
                "text": "Therefore be patient, brethren, until the coming of the Lord. See how the farmer waits for the precious fruit of the earth, waiting patiently for it until it receives the early and latter rain.", 
                "num": 7
              }, 
              {
                "text": "You also be patient. Establish your hearts, for the coming of the Lord is at hand.", 
                "num": 8
              }, 
              {
                "text": "Do not grumble against one another, brethren, lest you be condemned. Behold, the Judge is standing at the door!", 
                "num": 9
              }, 
              {
                "text": "My brethren, take the prophets, who spoke in the name of the Lord, as an example of suffering and patience.", 
                "num": 10
              }, 
              {
                "text": "Indeed we count them blessed who endure. You have heard of the perseverance of Job and seen the end intended by the Lord--that the Lord is very compassionate and merciful.", 
                "num": 11
              }, 
              {
                "text": "But above all, my brethren, do not swear, either by heaven or by earth or with any other oath. But let your \"Yes\" be \"Yes,\" and your \"No,\" \"No,\" lest you fall into judgment.", 
                "num": 12
              }, 
              {
                "text": "Is anyone among you suffering? Let him pray. Is anyone cheerful? Let him sing psalms.", 
                "num": 13
              }, 
              {
                "text": "Is anyone among you sick? Let him call for the elders of the church, and let them pray over him, anointing him with oil in the name of the Lord.", 
                "num": 14
              }, 
              {
                "text": "And the prayer of faith will save the sick, and the Lord will raise him up. And if he has committed sins, he will be forgiven.", 
                "num": 15
              }, 
              {
                "text": "Confess your trespasses to one another, and pray for one another, that you may be healed. The effective, fervent prayer of a righteous man avails much.", 
                "num": 16
              }, 
              {
                "text": "Elijah was a man with a nature like ours, and he prayed earnestly that it would not rain; and it did not rain on the land for three years and six months.", 
                "num": 17
              }, 
              {
                "text": "And he prayed again, and the heaven gave rain, and the earth produced its fruit.", 
                "num": 18
              }, 
              {
                "text": "Brethren, if anyone among you wanders from the truth, and someone turns him back,", 
                "num": 19
              }, 
              {
                "text": "let him know that he who turns a sinner from the error of his way will save a soul from death and cover a multitude of sins.", 
                "num": 20
              }
            ], 
            "num": 5
          }
        ]
      }, 
      {
        "name": "1 Peter", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Peter, an apostle of Jesus Christ, To the pilgrims of the Dispersion in Pontus, Galatia, Cappadocia, Asia, and Bithynia,", 
                "num": 1
              }, 
              {
                "text": "elect according to the foreknowledge of God the Father, in sanctification of the Spirit, for obedience and sprinkling of the blood of Jesus Christ: Grace to you and peace be multiplied.", 
                "num": 2
              }, 
              {
                "text": "Blessed be the God and Father of our Lord Jesus Christ, who according to His abundant mercy has begotten us again to a living hope through the resurrection of Jesus Christ from the dead,", 
                "num": 3
              }, 
              {
                "text": "to an inheritance incorruptible and undefiled and that does not fade away, reserved in heaven for you,", 
                "num": 4
              }, 
              {
                "text": "who are kept by the power of God through faith for salvation ready to be revealed in the last time.", 
                "num": 5
              }, 
              {
                "text": "In this you greatly rejoice, though now for a little while, if need be, you have been grieved by various trials,", 
                "num": 6
              }, 
              {
                "text": "that the genuineness of your faith, being much more precious than gold that perishes, though it is tested by fire, may be found to praise, honor, and glory at the revelation of Jesus Christ,", 
                "num": 7
              }, 
              {
                "text": "whom having not seen you love. Though now you do not see Him, yet believing, you rejoice with joy inexpressible and full of glory,", 
                "num": 8
              }, 
              {
                "text": "receiving the end of your faith--the salvation of your souls.", 
                "num": 9
              }, 
              {
                "text": "Of this salvation the prophets have inquired and searched carefully, who prophesied of the grace that would come to you,", 
                "num": 10
              }, 
              {
                "text": "searching what, or what manner of time, the Spirit of Christ who was in them was indicating when He testified beforehand the sufferings of Christ and the glories that would follow.", 
                "num": 11
              }, 
              {
                "text": "To them it was revealed that, not to themselves, but to us they were ministering the things which now have been reported to you through those who have preached the gospel to you by the Holy Spirit sent from heaven--things which angels desire to look into.", 
                "num": 12
              }, 
              {
                "text": "Therefore gird up the loins of your mind, be sober, and rest your hope fully upon the grace that is to be brought to you at the revelation of Jesus Christ;", 
                "num": 13
              }, 
              {
                "text": "as obedient children, not conforming yourselves to the former lusts, as in your ignorance;", 
                "num": 14
              }, 
              {
                "text": "but as He who called you is holy, you also be holy in all your conduct,", 
                "num": 15
              }, 
              {
                "text": "because it is written, \"Be holy, for I am holy.\"", 
                "num": 16
              }, 
              {
                "text": "And if you call on the Father, who without partiality judges according to each one's work, conduct yourselves throughout the time of your stay here in fear;", 
                "num": 17
              }, 
              {
                "text": "knowing that you were not redeemed with corruptible things, like silver or gold, from your aimless conduct received by tradition from your fathers,", 
                "num": 18
              }, 
              {
                "text": "but with the precious blood of Christ, as of a lamb without blemish and without spot.", 
                "num": 19
              }, 
              {
                "text": "He indeed was foreordained before the foundation of the world, but was manifest in these last times for you", 
                "num": 20
              }, 
              {
                "text": "who through Him believe in God, who raised Him from the dead and gave Him glory, so that your faith and hope are in God.", 
                "num": 21
              }, 
              {
                "text": "Since you have purified your souls in obeying the truth through the Spirit in sincere love of the brethren, love one another fervently with a pure heart,", 
                "num": 22
              }, 
              {
                "text": "having been born again, not of corruptible seed but incorruptible, through the word of God which lives and abides forever,", 
                "num": 23
              }, 
              {
                "text": "because \"All flesh is as grass, And all the glory of man as the flower of the grass. The grass withers, And its flower falls away,", 
                "num": 24
              }, 
              {
                "text": "But the word of the LORD endures forever.\" Now this is the word which by the gospel was preached to you.", 
                "num": 25
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "Therefore, laying aside all malice, all deceit, hypocrisy, envy, and all evil speaking,", 
                "num": 1
              }, 
              {
                "text": "as newborn babes, desire the pure milk of the word, that you may grow thereby,", 
                "num": 2
              }, 
              {
                "text": "if indeed you have tasted that the Lord is gracious.", 
                "num": 3
              }, 
              {
                "text": "Coming to Him as to a living stone, rejected indeed by men, but chosen by God and precious,", 
                "num": 4
              }, 
              {
                "text": "you also, as living stones, are being built up a spiritual house, a holy priesthood, to offer up spiritual sacrifices acceptable to God through Jesus Christ.", 
                "num": 5
              }, 
              {
                "text": "Therefore it is also contained in the Scripture, \"Behold, I lay in Zion A chief cornerstone, elect, precious, And he who believes on Him will by no means be put to shame.\"", 
                "num": 6
              }, 
              {
                "text": "Therefore, to you who believe, He is precious; but to those who are disobedient, \"The stone which the builders rejected Has become the chief cornerstone,\"", 
                "num": 7
              }, 
              {
                "text": "and \"A stone of stumbling And a rock of offense.\" They stumble, being disobedient to the word, to which they also were appointed.", 
                "num": 8
              }, 
              {
                "text": "But you are a chosen generation, a royal priesthood, a holy nation, His own special people, that you may proclaim the praises of Him who called you out of darkness into His marvelous light;", 
                "num": 9
              }, 
              {
                "text": "who once were not a people but are now the people of God, who had not obtained mercy but now have obtained mercy.", 
                "num": 10
              }, 
              {
                "text": "Beloved, I beg you as sojourners and pilgrims, abstain from fleshly lusts which war against the soul,", 
                "num": 11
              }, 
              {
                "text": "having your conduct honorable among the Gentiles, that when they speak against you as evildoers, they may, by your good works which they observe, glorify God in the day of visitation.", 
                "num": 12
              }, 
              {
                "text": "Therefore submit yourselves to every ordinance of man for the Lord's sake, whether to the king as supreme,", 
                "num": 13
              }, 
              {
                "text": "or to governors, as to those who are sent by him for the punishment of evildoers and for the praise of those who do good.", 
                "num": 14
              }, 
              {
                "text": "For this is the will of God, that by doing good you may put to silence the ignorance of foolish men--", 
                "num": 15
              }, 
              {
                "text": "as free, yet not using liberty as a cloak for vice, but as bondservants of God.", 
                "num": 16
              }, 
              {
                "text": "Honor all people. Love the brotherhood. Fear God. Honor the king.", 
                "num": 17
              }, 
              {
                "text": "Servants, be submissive to your masters with all fear, not only to the good and gentle, but also to the harsh.", 
                "num": 18
              }, 
              {
                "text": "For this is commendable, if because of conscience toward God one endures grief, suffering wrongfully.", 
                "num": 19
              }, 
              {
                "text": "For what credit is it if, when you are beaten for your faults, you take it patiently? But when you do good and suffer, if you take it patiently, this is commendable before God.", 
                "num": 20
              }, 
              {
                "text": "For to this you were called, because Christ also suffered for us, leaving us an example, that you should follow His steps:", 
                "num": 21
              }, 
              {
                "text": "\"Who committed no sin, Nor was deceit found in His mouth\";", 
                "num": 22
              }, 
              {
                "text": "who, when He was reviled, did not revile in return; when He suffered, He did not threaten, but committed Himself to Him who judges righteously;", 
                "num": 23
              }, 
              {
                "text": "who Himself bore our sins in His own body on the tree, that we, having died to sins, might live for righteousness--by whose stripes you were healed.", 
                "num": 24
              }, 
              {
                "text": "For you were like sheep going astray, but have now returned to the Shepherd and Overseer of your souls.", 
                "num": 25
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Wives, likewise, be submissive to your own husbands, that even if some do not obey the word, they, without a word, may be won by the conduct of their wives,", 
                "num": 1
              }, 
              {
                "text": "when they observe your chaste conduct accompanied by fear.", 
                "num": 2
              }, 
              {
                "text": "Do not let your adornment be merely outward--arranging the hair, wearing gold, or putting on fine apparel--", 
                "num": 3
              }, 
              {
                "text": "rather let it be the hidden person of the heart, with the incorruptible beauty of a gentle and quiet spirit, which is very precious in the sight of God.", 
                "num": 4
              }, 
              {
                "text": "For in this manner, in former times, the holy women who trusted in God also adorned themselves, being submissive to their own husbands,", 
                "num": 5
              }, 
              {
                "text": "as Sarah obeyed Abraham, calling him lord, whose daughters you are if you do good and are not afraid with any terror.", 
                "num": 6
              }, 
              {
                "text": "Husbands, likewise, dwell with them with understanding, giving honor to the wife, as to the weaker vessel, and as being heirs together of the grace of life, that your prayers may not be hindered.", 
                "num": 7
              }, 
              {
                "text": "Finally, all of you be of one mind, having compassion for one another; love as brothers, be tenderhearted, be courteous;", 
                "num": 8
              }, 
              {
                "text": "not returning evil for evil or reviling for reviling, but on the contrary blessing, knowing that you were called to this, that you may inherit a blessing.", 
                "num": 9
              }, 
              {
                "text": "For \"He who would love life And see good days, Let him refrain his tongue from evil, And his lips from speaking deceit.", 
                "num": 10
              }, 
              {
                "text": "Let him turn away from evil and do good; Let him seek peace and pursue it.", 
                "num": 11
              }, 
              {
                "text": "For the eyes of the LORD are on the righteous, And His ears are open to their prayers; But the face of the LORD is against those who do evil.\"", 
                "num": 12
              }, 
              {
                "text": "And who is he who will harm you if you become followers of what is good?", 
                "num": 13
              }, 
              {
                "text": "But even if you should suffer for righteousness' sake, you are blessed. \"And do not be afraid of their threats, nor be troubled.\"", 
                "num": 14
              }, 
              {
                "text": "But sanctify the Lord God in your hearts, and always be ready to give a defense to everyone who asks you a reason for the hope that is in you, with meekness and fear;", 
                "num": 15
              }, 
              {
                "text": "having a good conscience, that when they defame you as evildoers, those who revile your good conduct in Christ may be ashamed.", 
                "num": 16
              }, 
              {
                "text": "For it is better, if it is the will of God, to suffer for doing good than for doing evil.", 
                "num": 17
              }, 
              {
                "text": "For Christ also suffered once for sins, the just for the unjust, that He might bring us to God, being put to death in the flesh but made alive by the Spirit,", 
                "num": 18
              }, 
              {
                "text": "by whom also He went and preached to the spirits in prison,", 
                "num": 19
              }, 
              {
                "text": "who formerly were disobedient, when once the Divine longsuffering waited in the days of Noah, while the ark was being prepared, in which a few, that is, eight souls, were saved through water.", 
                "num": 20
              }, 
              {
                "text": "There is also an antitype which now saves us--baptism (not the removal of the filth of the flesh, but the answer of a good conscience toward God), through the resurrection of Jesus Christ,", 
                "num": 21
              }, 
              {
                "text": "who has gone into heaven and is at the right hand of God, angels and authorities and powers having been made subject to Him.", 
                "num": 22
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Therefore, since Christ suffered for us in the flesh, arm yourselves also with the same mind, for he who has suffered in the flesh has ceased from sin,", 
                "num": 1
              }, 
              {
                "text": "that he no longer should live the rest of his time in the flesh for the lusts of men, but for the will of God.", 
                "num": 2
              }, 
              {
                "text": "For we have spent enough of our past lifetime in doing the will of the Gentiles--when we walked in lewdness, lusts, drunkenness, revelries, drinking parties, and abominable idolatries.", 
                "num": 3
              }, 
              {
                "text": "In regard to these, they think it strange that you do not run with them in the same flood of dissipation, speaking evil of you.", 
                "num": 4
              }, 
              {
                "text": "They will give an account to Him who is ready to judge the living and the dead.", 
                "num": 5
              }, 
              {
                "text": "For this reason the gospel was preached also to those who are dead, that they might be judged according to men in the flesh, but live according to God in the spirit.", 
                "num": 6
              }, 
              {
                "text": "But the end of all things is at hand; therefore be serious and watchful in your prayers.", 
                "num": 7
              }, 
              {
                "text": "And above all things have fervent love for one another, for \"love will cover a multitude of sins.\"", 
                "num": 8
              }, 
              {
                "text": "Be hospitable to one another without grumbling.", 
                "num": 9
              }, 
              {
                "text": "As each one has received a gift, minister it to one another, as good stewards of the manifold grace of God.", 
                "num": 10
              }, 
              {
                "text": "If anyone speaks, let him speak as the oracles of God. If anyone ministers, let him do it as with the ability which God supplies, that in all things God may be glorified through Jesus Christ, to whom belong the glory and the dominion forever and ever. Amen.", 
                "num": 11
              }, 
              {
                "text": "Beloved, do not think it strange concerning the fiery trial which is to try you, as though some strange thing happened to you;", 
                "num": 12
              }, 
              {
                "text": "but rejoice to the extent that you partake of Christ's sufferings, that when His glory is revealed, you may also be glad with exceeding joy.", 
                "num": 13
              }, 
              {
                "text": "If you are reproached for the name of Christ, blessed are you, for the Spirit of glory and of God rests upon you. On their part He is blasphemed, but on your part He is glorified.", 
                "num": 14
              }, 
              {
                "text": "But let none of you suffer as a murderer, a thief, an evildoer, or as a busybody in other people's matters.", 
                "num": 15
              }, 
              {
                "text": "Yet if anyone suffers as a Christian, let him not be ashamed, but let him glorify God in this matter.", 
                "num": 16
              }, 
              {
                "text": "For the time has come for judgment to begin at the house of God; and if it begins with us first, what will be the end of those who do not obey the gospel of God?", 
                "num": 17
              }, 
              {
                "text": "Now \"If the righteous one is scarcely saved, Where will the ungodly and the sinner appear?\"", 
                "num": 18
              }, 
              {
                "text": "Therefore let those who suffer according to the will of God commit their souls to Him in doing good, as to a faithful Creator.", 
                "num": 19
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "The elders who are among you I exhort, I who am a fellow elder and a witness of the sufferings of Christ, and also a partaker of the glory that will be revealed:", 
                "num": 1
              }, 
              {
                "text": "Shepherd the flock of God which is among you, serving as overseers, not by compulsion but willingly, not for dishonest gain but eagerly;", 
                "num": 2
              }, 
              {
                "text": "nor as being lords over those entrusted to you, but being examples to the flock;", 
                "num": 3
              }, 
              {
                "text": "and when the Chief Shepherd appears, you will receive the crown of glory that does not fade away.", 
                "num": 4
              }, 
              {
                "text": "Likewise you younger people, submit yourselves to your elders. Yes, all of you be submissive to one another, and be clothed with humility, for \"God resists the proud, But gives grace to the humble.\"", 
                "num": 5
              }, 
              {
                "text": "Therefore humble yourselves under the mighty hand of God, that He may exalt you in due time,", 
                "num": 6
              }, 
              {
                "text": "casting all your care upon Him, for He cares for you.", 
                "num": 7
              }, 
              {
                "text": "Be sober, be vigilant; because your adversary the devil walks about like a roaring lion, seeking whom he may devour.", 
                "num": 8
              }, 
              {
                "text": "Resist him, steadfast in the faith, knowing that the same sufferings are experienced by your brotherhood in the world.", 
                "num": 9
              }, 
              {
                "text": "But may the God of all grace, who called us to His eternal glory by Christ Jesus, after you have suffered a while, perfect, establish, strengthen, and settle you.", 
                "num": 10
              }, 
              {
                "text": "To Him be the glory and the dominion forever and ever. Amen.", 
                "num": 11
              }, 
              {
                "text": "By Silvanus, our faithful brother as I consider him, I have written to you briefly, exhorting and testifying that this is the true grace of God in which you stand.", 
                "num": 12
              }, 
              {
                "text": "She who is in Babylon, elect together with you, greets you; and so does Mark my son.", 
                "num": 13
              }, 
              {
                "text": "Greet one another with a kiss of love. Peace to you all who are in Christ Jesus. Amen.", 
                "num": 14
              }
            ], 
            "num": 5
          }
        ]
      }, 
      {
        "name": "2 Peter", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Simon Peter, a bondservant and apostle of Jesus Christ, To those who have obtained like precious faith with us by the righteousness of our God and Savior Jesus Christ:", 
                "num": 1
              }, 
              {
                "text": "Grace and peace be multiplied to you in the knowledge of God and of Jesus our Lord,", 
                "num": 2
              }, 
              {
                "text": "as His divine power has given to us all things that pertain to life and godliness, through the knowledge of Him who called us by glory and virtue,", 
                "num": 3
              }, 
              {
                "text": "by which have been given to us exceedingly great and precious promises, that through these you may be partakers of the divine nature, having escaped the corruption that is in the world through lust.", 
                "num": 4
              }, 
              {
                "text": "But also for this very reason, giving all diligence, add to your faith virtue, to virtue knowledge,", 
                "num": 5
              }, 
              {
                "text": "to knowledge self-control, to self-control perseverance, to perseverance godliness,", 
                "num": 6
              }, 
              {
                "text": "to godliness brotherly kindness, and to brotherly kindness love.", 
                "num": 7
              }, 
              {
                "text": "For if these things are yours and abound, you will be neither barren nor unfruitful in the knowledge of our Lord Jesus Christ.", 
                "num": 8
              }, 
              {
                "text": "For he who lacks these things is shortsighted, even to blindness, and has forgotten that he was cleansed from his old sins.", 
                "num": 9
              }, 
              {
                "text": "Therefore, brethren, be even more diligent to make your call and election sure, for if you do these things you will never stumble;", 
                "num": 10
              }, 
              {
                "text": "for so an entrance will be supplied to you abundantly into the everlasting kingdom of our Lord and Savior Jesus Christ.", 
                "num": 11
              }, 
              {
                "text": "For this reason I will not be negligent to remind you always of these things, though you know and are established in the present truth.", 
                "num": 12
              }, 
              {
                "text": "Yes, I think it is right, as long as I am in this tent, to stir you up by reminding you,", 
                "num": 13
              }, 
              {
                "text": "knowing that shortly I must put off my tent, just as our Lord Jesus Christ showed me.", 
                "num": 14
              }, 
              {
                "text": "Moreover I will be careful to ensure that you always have a reminder of these things after my decease.", 
                "num": 15
              }, 
              {
                "text": "For we did not follow cunningly devised fables when we made known to you the power and coming of our Lord Jesus Christ, but were eyewitnesses of His majesty.", 
                "num": 16
              }, 
              {
                "text": "For He received from God the Father honor and glory when such a voice came to Him from the Excellent Glory: \"This is My beloved Son, in whom I am well pleased.\"", 
                "num": 17
              }, 
              {
                "text": "And we heard this voice which came from heaven when we were with Him on the holy mountain.", 
                "num": 18
              }, 
              {
                "text": "And so we have the prophetic word confirmed, which you do well to heed as a light that shines in a dark place, until the day dawns and the morning star rises in your hearts;", 
                "num": 19
              }, 
              {
                "text": "knowing this first, that no prophecy of Scripture is of any private interpretation,", 
                "num": 20
              }, 
              {
                "text": "for prophecy never came by the will of man, but holy men of God spoke as they were moved by the Holy Spirit.", 
                "num": 21
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "But there were also false prophets among the people, even as there will be false teachers among you, who will secretly bring in destructive heresies, even denying the Lord who bought them, and bring on themselves swift destruction.", 
                "num": 1
              }, 
              {
                "text": "And many will follow their destructive ways, because of whom the way of truth will be blasphemed.", 
                "num": 2
              }, 
              {
                "text": "By covetousness they will exploit you with deceptive words; for a long time their judgment has not been idle, and their destruction does not slumber.", 
                "num": 3
              }, 
              {
                "text": "For if God did not spare the angels who sinned, but cast them down to hell and delivered them into chains of darkness, to be reserved for judgment;", 
                "num": 4
              }, 
              {
                "text": "and did not spare the ancient world, but saved Noah, one of eight people, a preacher of righteousness, bringing in the flood on the world of the ungodly;", 
                "num": 5
              }, 
              {
                "text": "and turning the cities of Sodom and Gomorrah into ashes, condemned them to destruction, making them an example to those who afterward would live ungodly;", 
                "num": 6
              }, 
              {
                "text": "and delivered righteous Lot, who was oppressed by the filthy conduct of the wicked", 
                "num": 7
              }, 
              {
                "text": "(for that righteous man, dwelling among them, tormented his righteous soul from day to day by seeing and hearing their lawless deeds)--", 
                "num": 8
              }, 
              {
                "text": "then the Lord knows how to deliver the godly out of temptations and to reserve the unjust under punishment for the day of judgment,", 
                "num": 9
              }, 
              {
                "text": "and especially those who walk according to the flesh in the lust of uncleanness and despise authority. They are presumptuous, self-willed. They are not afraid to speak evil of dignitaries,", 
                "num": 10
              }, 
              {
                "text": "whereas angels, who are greater in power and might, do not bring a reviling accusation against them before the Lord.", 
                "num": 11
              }, 
              {
                "text": "But these, like natural brute beasts made to be caught and destroyed, speak evil of the things they do not understand, and will utterly perish in their own corruption,", 
                "num": 12
              }, 
              {
                "text": "and will receive the wages of unrighteousness, as those who count it pleasure to carouse in the daytime. They are spots and blemishes, carousing in their own deceptions while they feast with you,", 
                "num": 13
              }, 
              {
                "text": "having eyes full of adultery and that cannot cease from sin, enticing unstable souls. They have a heart trained in covetous practices, and are accursed children.", 
                "num": 14
              }, 
              {
                "text": "They have forsaken the right way and gone astray, following the way of Balaam the son of Beor, who loved the wages of unrighteousness;", 
                "num": 15
              }, 
              {
                "text": "but he was rebuked for his iniquity: a dumb donkey speaking with a man's voice restrained the madness of the prophet.", 
                "num": 16
              }, 
              {
                "text": "These are wells without water, clouds carried by a tempest, for whom is reserved the blackness of darkness forever.", 
                "num": 17
              }, 
              {
                "text": "For when they speak great swelling words of emptiness, they allure through the lusts of the flesh, through lewdness, the ones who have actually escaped from those who live in error.", 
                "num": 18
              }, 
              {
                "text": "While they promise them liberty, they themselves are slaves of corruption; for by whom a person is overcome, by him also he is brought into bondage.", 
                "num": 19
              }, 
              {
                "text": "For if, after they have escaped the pollutions of the world through the knowledge of the Lord and Savior Jesus Christ, they are again entangled in them and overcome, the latter end is worse for them than the beginning.", 
                "num": 20
              }, 
              {
                "text": "For it would have been better for them not to have known the way of righteousness, than having known it, to turn from the holy commandment delivered to them.", 
                "num": 21
              }, 
              {
                "text": "But it has happened to them according to the true proverb: \"A dog returns to his own vomit,\" and, \"a sow, having washed, to her wallowing in the mire.\"", 
                "num": 22
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Beloved, I now write to you this second epistle (in both of which I stir up your pure minds by way of reminder),", 
                "num": 1
              }, 
              {
                "text": "that you may be mindful of the words which were spoken before by the holy prophets, and of the commandment of us, the apostles of the Lord and Savior,", 
                "num": 2
              }, 
              {
                "text": "knowing this first: that scoffers will come in the last days, walking according to their own lusts,", 
                "num": 3
              }, 
              {
                "text": "and saying, \"Where is the promise of His coming? For since the fathers fell asleep, all things continue as they were from the beginning of creation.\"", 
                "num": 4
              }, 
              {
                "text": "For this they willfully forget: that by the word of God the heavens were of old, and the earth standing out of water and in the water,", 
                "num": 5
              }, 
              {
                "text": "by which the world that then existed perished, being flooded with water.", 
                "num": 6
              }, 
              {
                "text": "But the heavens and the earth which are now preserved by the same word, are reserved for fire until the day of judgment and perdition of ungodly men.", 
                "num": 7
              }, 
              {
                "text": "But, beloved, do not forget this one thing, that with the Lord one day is as a thousand years, and a thousand years as one day.", 
                "num": 8
              }, 
              {
                "text": "The Lord is not slack concerning His promise, as some count slackness, but is longsuffering toward us, not willing that any should perish but that all should come to repentance.", 
                "num": 9
              }, 
              {
                "text": "But the day of the Lord will come as a thief in the night, in which the heavens will pass away with a great noise, and the elements will melt with fervent heat; both the earth and the works that are in it will be burned up.", 
                "num": 10
              }, 
              {
                "text": "Therefore, since all these things will be dissolved, what manner of persons ought you to be in holy conduct and godliness,", 
                "num": 11
              }, 
              {
                "text": "looking for and hastening the coming of the day of God, because of which the heavens will be dissolved, being on fire, and the elements will melt with fervent heat?", 
                "num": 12
              }, 
              {
                "text": "Nevertheless we, according to His promise, look for new heavens and a new earth in which righteousness dwells.", 
                "num": 13
              }, 
              {
                "text": "Therefore, beloved, looking forward to these things, be diligent to be found by Him in peace, without spot and blameless;", 
                "num": 14
              }, 
              {
                "text": "and consider that the longsuffering of our Lord is salvation--as also our beloved brother Paul, according to the wisdom given to him, has written to you,", 
                "num": 15
              }, 
              {
                "text": "as also in all his epistles, speaking in them of these things, in which are some things hard to understand, which untaught and unstable people twist to their own destruction, as they do also the rest of the Scriptures.", 
                "num": 16
              }, 
              {
                "text": "You therefore, beloved, since you know this beforehand, beware lest you also fall from your own steadfastness, being led away with the error of the wicked;", 
                "num": 17
              }, 
              {
                "text": "but grow in the grace and knowledge of our Lord and Savior Jesus Christ. To Him be the glory both now and forever. Amen.", 
                "num": 18
              }
            ], 
            "num": 3
          }
        ]
      }, 
      {
        "name": "1 John", 
        "chapters": [
          {
            "verses": [
              {
                "text": "That which was from the beginning, which we have heard, which we have seen with our eyes, which we have looked upon, and our hands have handled, concerning the Word of life--", 
                "num": 1
              }, 
              {
                "text": "the life was manifested, and we have seen, and bear witness, and declare to you that eternal life which was with the Father and was manifested to us--", 
                "num": 2
              }, 
              {
                "text": "that which we have seen and heard we declare to you, that you also may have fellowship with us; and truly our fellowship is with the Father and with His Son Jesus Christ.", 
                "num": 3
              }, 
              {
                "text": "And these things we write to you that your joy may be full.", 
                "num": 4
              }, 
              {
                "text": "This is the message which we have heard from Him and declare to you, that God is light and in Him is no darkness at all.", 
                "num": 5
              }, 
              {
                "text": "If we say that we have fellowship with Him, and walk in darkness, we lie and do not practice the truth.", 
                "num": 6
              }, 
              {
                "text": "But if we walk in the light as He is in the light, we have fellowship with one another, and the blood of Jesus Christ His Son cleanses us from all sin.", 
                "num": 7
              }, 
              {
                "text": "If we say that we have no sin, we deceive ourselves, and the truth is not in us.", 
                "num": 8
              }, 
              {
                "text": "If we confess our sins, He is faithful and just to forgive us our sins and to cleanse us from all unrighteousness.", 
                "num": 9
              }, 
              {
                "text": "If we say that we have not sinned, we make Him a liar, and His word is not in us.", 
                "num": 10
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "My little children, these things I write to you, so that you may not sin. And if anyone sins, we have an Advocate with the Father, Jesus Christ the righteous.", 
                "num": 1
              }, 
              {
                "text": "And He Himself is the propitiation for our sins, and not for ours only but also for the whole world.", 
                "num": 2
              }, 
              {
                "text": "Now by this we know that we know Him, if we keep His commandments.", 
                "num": 3
              }, 
              {
                "text": "He who says, \"I know Him,\" and does not keep His commandments, is a liar, and the truth is not in him.", 
                "num": 4
              }, 
              {
                "text": "But whoever keeps His word, truly the love of God is perfected in him. By this we know that we are in Him.", 
                "num": 5
              }, 
              {
                "text": "He who says he abides in Him ought himself also to walk just as He walked.", 
                "num": 6
              }, 
              {
                "text": "Brethren, I write no new commandment to you, but an old commandment which you have had from the beginning. The old commandment is the word which you heard from the beginning.", 
                "num": 7
              }, 
              {
                "text": "Again, a new commandment I write to you, which thing is true in Him and in you, because the darkness is passing away, and the true light is already shining.", 
                "num": 8
              }, 
              {
                "text": "He who says he is in the light, and hates his brother, is in darkness until now.", 
                "num": 9
              }, 
              {
                "text": "He who loves his brother abides in the light, and there is no cause for stumbling in him.", 
                "num": 10
              }, 
              {
                "text": "But he who hates his brother is in darkness and walks in darkness, and does not know where he is going, because the darkness has blinded his eyes.", 
                "num": 11
              }, 
              {
                "text": "I write to you, little children, Because your sins are forgiven you for His name's sake.", 
                "num": 12
              }, 
              {
                "text": "I write to you, fathers, Because you have known Him who is from the beginning. I write to you, young men, Because you have overcome the wicked one. I write to you, little children, Because you have known the Father.", 
                "num": 13
              }, 
              {
                "text": "I have written to you, fathers, Because you have known Him who is from the beginning. I have written to you, young men, Because you are strong, and the word of God abides in you, And you have overcome the wicked one.", 
                "num": 14
              }, 
              {
                "text": "Do not love the world or the things in the world. If anyone loves the world, the love of the Father is not in him.", 
                "num": 15
              }, 
              {
                "text": "For all that is in the world--the lust of the flesh, the lust of the eyes, and the pride of life--is not of the Father but is of the world.", 
                "num": 16
              }, 
              {
                "text": "And the world is passing away, and the lust of it; but he who does the will of God abides forever.", 
                "num": 17
              }, 
              {
                "text": "Little children, it is the last hour; and as you have heard that the Antichrist is coming, even now many antichrists have come, by which we know that it is the last hour.", 
                "num": 18
              }, 
              {
                "text": "They went out from us, but they were not of us; for if they had been of us, they would have continued with us; but they went out that they might be made manifest, that none of them were of us.", 
                "num": 19
              }, 
              {
                "text": "But you have an anointing from the Holy One, and you know all things.", 
                "num": 20
              }, 
              {
                "text": "I have not written to you because you do not know the truth, but because you know it, and that no lie is of the truth.", 
                "num": 21
              }, 
              {
                "text": "Who is a liar but he who denies that Jesus is the Christ? He is antichrist who denies the Father and the Son.", 
                "num": 22
              }, 
              {
                "text": "Whoever denies the Son does not have the Father either; he who acknowledges the Son has the Father also.", 
                "num": 23
              }, 
              {
                "text": "Therefore let that abide in you which you heard from the beginning. If what you heard from the beginning abides in you, you also will abide in the Son and in the Father.", 
                "num": 24
              }, 
              {
                "text": "And this is the promise that He has promised us--eternal life.", 
                "num": 25
              }, 
              {
                "text": "These things I have written to you concerning those who try to deceive you.", 
                "num": 26
              }, 
              {
                "text": "But the anointing which you have received from Him abides in you, and you do not need that anyone teach you; but as the same anointing teaches you concerning all things, and is true, and is not a lie, and just as it has taught you, you will abide in Him.", 
                "num": 27
              }, 
              {
                "text": "And now, little children, abide in Him, that when He appears, we may have confidence and not be ashamed before Him at His coming.", 
                "num": 28
              }, 
              {
                "text": "If you know that He is righteous, you know that everyone who practices righteousness is born of Him.", 
                "num": 29
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "Behold what manner of love the Father has bestowed on us, that we should be called children of God! Therefore the world does not know us, because it did not know Him.", 
                "num": 1
              }, 
              {
                "text": "Beloved, now we are children of God; and it has not yet been revealed what we shall be, but we know that when He is revealed, we shall be like Him, for we shall see Him as He is.", 
                "num": 2
              }, 
              {
                "text": "And everyone who has this hope in Him purifies himself, just as He is pure.", 
                "num": 3
              }, 
              {
                "text": "Whoever commits sin also commits lawlessness, and sin is lawlessness.", 
                "num": 4
              }, 
              {
                "text": "And you know that He was manifested to take away our sins, and in Him there is no sin.", 
                "num": 5
              }, 
              {
                "text": "Whoever abides in Him does not sin. Whoever sins has neither seen Him nor known Him.", 
                "num": 6
              }, 
              {
                "text": "Little children, let no one deceive you. He who practices righteousness is righteous, just as He is righteous.", 
                "num": 7
              }, 
              {
                "text": "He who sins is of the devil, for the devil has sinned from the beginning. For this purpose the Son of God was manifested, that He might destroy the works of the devil.", 
                "num": 8
              }, 
              {
                "text": "Whoever has been born of God does not sin, for His seed remains in him; and he cannot sin, because he has been born of God.", 
                "num": 9
              }, 
              {
                "text": "In this the children of God and the children of the devil are manifest: Whoever does not practice righteousness is not of God, nor is he who does not love his brother.", 
                "num": 10
              }, 
              {
                "text": "For this is the message that you heard from the beginning, that we should love one another,", 
                "num": 11
              }, 
              {
                "text": "not as Cain who was of the wicked one and murdered his brother. And why did he murder him? Because his works were evil and his brother's righteous.", 
                "num": 12
              }, 
              {
                "text": "Do not marvel, my brethren, if the world hates you.", 
                "num": 13
              }, 
              {
                "text": "We know that we have passed from death to life, because we love the brethren. He who does not love his brother abides in death.", 
                "num": 14
              }, 
              {
                "text": "Whoever hates his brother is a murderer, and you know that no murderer has eternal life abiding in him.", 
                "num": 15
              }, 
              {
                "text": "By this we know love, because He laid down His life for us. And we also ought to lay down our lives for the brethren.", 
                "num": 16
              }, 
              {
                "text": "But whoever has this world's goods, and sees his brother in need, and shuts up his heart from him, how does the love of God abide in him?", 
                "num": 17
              }, 
              {
                "text": "My little children, let us not love in word or in tongue, but in deed and in truth.", 
                "num": 18
              }, 
              {
                "text": "And by this we know that we are of the truth, and shall assure our hearts before Him.", 
                "num": 19
              }, 
              {
                "text": "For if our heart condemns us, God is greater than our heart, and knows all things.", 
                "num": 20
              }, 
              {
                "text": "Beloved, if our heart does not condemn us, we have confidence toward God.", 
                "num": 21
              }, 
              {
                "text": "And whatever we ask we receive from Him, because we keep His commandments and do those things that are pleasing in His sight.", 
                "num": 22
              }, 
              {
                "text": "And this is His commandment: that we should believe on the name of His Son Jesus Christ and love one another, as He gave us commandment.", 
                "num": 23
              }, 
              {
                "text": "Now he who keeps His commandments abides in Him, and He in him. And by this we know that He abides in us, by the Spirit whom He has given us.", 
                "num": 24
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "Beloved, do not believe every spirit, but test the spirits, whether they are of God; because many false prophets have gone out into the world.", 
                "num": 1
              }, 
              {
                "text": "By this you know the Spirit of God: Every spirit that confesses that Jesus Christ has come in the flesh is of God,", 
                "num": 2
              }, 
              {
                "text": "and every spirit that does not confess that Jesus Christ has come in the flesh is not of God. And this is the spirit of the Antichrist, which you have heard was coming, and is now already in the world.", 
                "num": 3
              }, 
              {
                "text": "You are of God, little children, and have overcome them, because He who is in you is greater than he who is in the world.", 
                "num": 4
              }, 
              {
                "text": "They are of the world. Therefore they speak as of the world, and the world hears them.", 
                "num": 5
              }, 
              {
                "text": "We are of God. He who knows God hears us; he who is not of God does not hear us. By this we know the spirit of truth and the spirit of error.", 
                "num": 6
              }, 
              {
                "text": "Beloved, let us love one another, for love is of God; and everyone who loves is born of God and knows God.", 
                "num": 7
              }, 
              {
                "text": "He who does not love does not know God, for God is love.", 
                "num": 8
              }, 
              {
                "text": "In this the love of God was manifested toward us, that God has sent His only begotten Son into the world, that we might live through Him.", 
                "num": 9
              }, 
              {
                "text": "In this is love, not that we loved God, but that He loved us and sent His Son to be the propitiation for our sins.", 
                "num": 10
              }, 
              {
                "text": "Beloved, if God so loved us, we also ought to love one another.", 
                "num": 11
              }, 
              {
                "text": "No one has seen God at any time. If we love one another, God abides in us, and His love has been perfected in us.", 
                "num": 12
              }, 
              {
                "text": "By this we know that we abide in Him, and He in us, because He has given us of His Spirit.", 
                "num": 13
              }, 
              {
                "text": "And we have seen and testify that the Father has sent the Son as Savior of the world.", 
                "num": 14
              }, 
              {
                "text": "Whoever confesses that Jesus is the Son of God, God abides in him, and he in God.", 
                "num": 15
              }, 
              {
                "text": "And we have known and believed the love that God has for us. God is love, and he who abides in love abides in God, and God in him.", 
                "num": 16
              }, 
              {
                "text": "Love has been perfected among us in this: that we may have boldness in the day of judgment; because as He is, so are we in this world.", 
                "num": 17
              }, 
              {
                "text": "There is no fear in love; but perfect love casts out fear, because fear involves torment. But he who fears has not been made perfect in love.", 
                "num": 18
              }, 
              {
                "text": "We love Him because He first loved us.", 
                "num": 19
              }, 
              {
                "text": "If someone says, \"I love God,\" and hates his brother, he is a liar; for he who does not love his brother whom he has seen, how can he love God whom he has not seen?", 
                "num": 20
              }, 
              {
                "text": "And this commandment we have from Him: that he who loves God must love his brother also.", 
                "num": 21
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "Whoever believes that Jesus is the Christ is born of God, and everyone who loves Him who begot also loves him who is begotten of Him.", 
                "num": 1
              }, 
              {
                "text": "By this we know that we love the children of God, when we love God and keep His commandments.", 
                "num": 2
              }, 
              {
                "text": "For this is the love of God, that we keep His commandments. And His commandments are not burdensome.", 
                "num": 3
              }, 
              {
                "text": "For whatever is born of God overcomes the world. And this is the victory that has overcome the world--our faith.", 
                "num": 4
              }, 
              {
                "text": "Who is he who overcomes the world, but he who believes that Jesus is the Son of God?", 
                "num": 5
              }, 
              {
                "text": "This is He who came by water and blood--Jesus Christ; not only by water, but by water and blood. And it is the Spirit who bears witness, because the Spirit is truth.", 
                "num": 6
              }, 
              {
                "text": "For there are three that bear witness in heaven: the Father, the Word, and the Holy Spirit; and these three are one.", 
                "num": 7
              }, 
              {
                "text": "And there are three that bear witness on earth: the Spirit, the water, and the blood; and these three agree as one.", 
                "num": 8
              }, 
              {
                "text": "If we receive the witness of men, the witness of God is greater; for this is the witness of God which He has testified of His Son.", 
                "num": 9
              }, 
              {
                "text": "He who believes in the Son of God has the witness in himself; he who does not believe God has made Him a liar, because he has not believed the testimony that God has given of His Son.", 
                "num": 10
              }, 
              {
                "text": "And this is the testimony: that God has given us eternal life, and this life is in His Son.", 
                "num": 11
              }, 
              {
                "text": "He who has the Son has life; he who does not have the Son of God does not have life.", 
                "num": 12
              }, 
              {
                "text": "These things I have written to you who believe in the name of the Son of God, that you may know that you have eternal life, and that you may continue to believe in the name of the Son of God.", 
                "num": 13
              }, 
              {
                "text": "Now this is the confidence that we have in Him, that if we ask anything according to His will, He hears us.", 
                "num": 14
              }, 
              {
                "text": "And if we know that He hears us, whatever we ask, we know that we have the petitions that we have asked of Him.", 
                "num": 15
              }, 
              {
                "text": "If anyone sees his brother sinning a sin which does not lead to death, he will ask, and He will give him life for those who commit sin not leading to death. There is sin leading to death. I do not say that he should pray about that.", 
                "num": 16
              }, 
              {
                "text": "All unrighteousness is sin, and there is sin not leading to death.", 
                "num": 17
              }, 
              {
                "text": "We know that whoever is born of God does not sin; but he who has been born of God keeps himself, and the wicked one does not touch him.", 
                "num": 18
              }, 
              {
                "text": "We know that we are of God, and the whole world lies under the sway of the wicked one.", 
                "num": 19
              }, 
              {
                "text": "And we know that the Son of God has come and has given us an understanding, that we may know Him who is true; and we are in Him who is true, in His Son Jesus Christ. This is the true God and eternal life.", 
                "num": 20
              }, 
              {
                "text": "Little children, keep yourselves from idols. Amen.", 
                "num": 21
              }
            ], 
            "num": 5
          }
        ]
      }, 
      {
        "name": "2 John", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The Elder, To the elect lady and her children, whom I love in truth, and not only I, but also all those who have known the truth,", 
                "num": 1
              }, 
              {
                "text": "because of the truth which abides in us and will be with us forever:", 
                "num": 2
              }, 
              {
                "text": "Grace, mercy, and peace will be with you from God the Father and from the Lord Jesus Christ, the Son of the Father, in truth and love.", 
                "num": 3
              }, 
              {
                "text": "I rejoiced greatly that I have found some of your children walking in truth, as we received commandment from the Father.", 
                "num": 4
              }, 
              {
                "text": "And now I plead with you, lady, not as though I wrote a new commandment to you, but that which we have had from the beginning: that we love one another.", 
                "num": 5
              }, 
              {
                "text": "This is love, that we walk according to His commandments. This is the commandment, that as you have heard from the beginning, you should walk in it.", 
                "num": 6
              }, 
              {
                "text": "For many deceivers have gone out into the world who do not confess Jesus Christ as coming in the flesh. This is a deceiver and an antichrist.", 
                "num": 7
              }, 
              {
                "text": "Look to yourselves, that we do not lose those things we worked for, but that we may receive a full reward.", 
                "num": 8
              }, 
              {
                "text": "Whoever transgresses and does not abide in the doctrine of Christ does not have God. He who abides in the doctrine of Christ has both the Father and the Son.", 
                "num": 9
              }, 
              {
                "text": "If anyone comes to you and does not bring this doctrine, do not receive him into your house nor greet him;", 
                "num": 10
              }, 
              {
                "text": "for he who greets him shares in his evil deeds.", 
                "num": 11
              }, 
              {
                "text": "Having many things to write to you, I did not wish to do so with paper and ink; but I hope to come to you and speak face to face, that our joy may be full.", 
                "num": 12
              }, 
              {
                "text": "The children of your elect sister greet you. Amen.", 
                "num": 13
              }
            ], 
            "num": 1
          }
        ]
      }, 
      {
        "name": "3 John", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The Elder, To the beloved Gaius, whom I love in truth:", 
                "num": 1
              }, 
              {
                "text": "Beloved, I pray that you may prosper in all things and be in health, just as your soul prospers.", 
                "num": 2
              }, 
              {
                "text": "For I rejoiced greatly when brethren came and testified of the truth that is in you, just as you walk in the truth.", 
                "num": 3
              }, 
              {
                "text": "I have no greater joy than to hear that my children walk in truth.", 
                "num": 4
              }, 
              {
                "text": "Beloved, you do faithfully whatever you do for the brethren and for strangers,", 
                "num": 5
              }, 
              {
                "text": "who have borne witness of your love before the church. If you send them forward on their journey in a manner worthy of God, you will do well,", 
                "num": 6
              }, 
              {
                "text": "because they went forth for His name's sake, taking nothing from the Gentiles.", 
                "num": 7
              }, 
              {
                "text": "We therefore ought to receive such, that we may become fellow workers for the truth.", 
                "num": 8
              }, 
              {
                "text": "I wrote to the church, but Diotrephes, who loves to have the preeminence among them, does not receive us.", 
                "num": 9
              }, 
              {
                "text": "Therefore, if I come, I will call to mind his deeds which he does, prating against us with malicious words. And not content with that, he himself does not receive the brethren, and forbids those who wish to, putting them out of the church.", 
                "num": 10
              }, 
              {
                "text": "Beloved, do not imitate what is evil, but what is good. He who does good is of God, but he who does evil has not seen God.", 
                "num": 11
              }, 
              {
                "text": "Demetrius has a good testimony from all, and from the truth itself. And we also bear witness, and you know that our testimony is true.", 
                "num": 12
              }, 
              {
                "text": "I had many things to write, but I do not wish to write to you with pen and ink;", 
                "num": 13
              }, 
              {
                "text": "but I hope to see you shortly, and we shall speak face to face. Peace to you. Our friends greet you. Greet the friends by name.", 
                "num": 14
              }
            ], 
            "num": 1
          }
        ]
      }, 
      {
        "name": "Jude", 
        "chapters": [
          {
            "verses": [
              {
                "text": "Jude, a bondservant of Jesus Christ, and brother of James, To those who are called, sanctified by God the Father, and preserved in Jesus Christ:", 
                "num": 1
              }, 
              {
                "text": "Mercy, peace, and love be multiplied to you.", 
                "num": 2
              }, 
              {
                "text": "Beloved, while I was very diligent to write to you concerning our common salvation, I found it necessary to write to you exhorting you to contend earnestly for the faith which was once for all delivered to the saints.", 
                "num": 3
              }, 
              {
                "text": "For certain men have crept in unnoticed, who long ago were marked out for this condemnation, ungodly men, who turn the grace of our God into lewdness and deny the only Lord God and our Lord Jesus Christ.", 
                "num": 4
              }, 
              {
                "text": "But I want to remind you, though you once knew this, that the Lord, having saved the people out of the land of Egypt, afterward destroyed those who did not believe.", 
                "num": 5
              }, 
              {
                "text": "And the angels who did not keep their proper domain, but left their own abode, He has reserved in everlasting chains under darkness for the judgment of the great day;", 
                "num": 6
              }, 
              {
                "text": "as Sodom and Gomorrah, and the cities around them in a similar manner to these, having given themselves over to sexual immorality and gone after strange flesh, are set forth as an example, suffering the vengeance of eternal fire.", 
                "num": 7
              }, 
              {
                "text": "Likewise also these dreamers defile the flesh, reject authority, and speak evil of dignitaries.", 
                "num": 8
              }, 
              {
                "text": "Yet Michael the archangel, in contending with the devil, when he disputed about the body of Moses, dared not bring against him a reviling accusation, but said, \"The Lord rebuke you!\"", 
                "num": 9
              }, 
              {
                "text": "But these speak evil of whatever they do not know; and whatever they know naturally, like brute beasts, in these things they corrupt themselves.", 
                "num": 10
              }, 
              {
                "text": "Woe to them! For they have gone in the way of Cain, have run greedily in the error of Balaam for profit, and perished in the rebellion of Korah.", 
                "num": 11
              }, 
              {
                "text": "These are spots in your love feasts, while they feast with you without fear, serving only themselves. They are clouds without water, carried about by the winds; late autumn trees without fruit, twice dead, pulled up by the roots;", 
                "num": 12
              }, 
              {
                "text": "raging waves of the sea, foaming up their own shame; wandering stars for whom is reserved the blackness of darkness forever.", 
                "num": 13
              }, 
              {
                "text": "Now Enoch, the seventh from Adam, prophesied about these men also, saying, \"Behold, the Lord comes with ten thousands of His saints,", 
                "num": 14
              }, 
              {
                "text": "to execute judgment on all, to convict all who are ungodly among them of all their ungodly deeds which they have committed in an ungodly way, and of all the harsh things which ungodly sinners have spoken against Him.\"", 
                "num": 15
              }, 
              {
                "text": "These are grumblers, complainers, walking according to their own lusts; and they mouth great swelling words, flattering people to gain advantage.", 
                "num": 16
              }, 
              {
                "text": "But you, beloved, remember the words which were spoken before by the apostles of our Lord Jesus Christ:", 
                "num": 17
              }, 
              {
                "text": "how they told you that there would be mockers in the last time who would walk according to their own ungodly lusts.", 
                "num": 18
              }, 
              {
                "text": "These are sensual persons, who cause divisions, not having the Spirit.", 
                "num": 19
              }, 
              {
                "text": "But you, beloved, building yourselves up on your most holy faith, praying in the Holy Spirit,", 
                "num": 20
              }, 
              {
                "text": "keep yourselves in the love of God, looking for the mercy of our Lord Jesus Christ unto eternal life.", 
                "num": 21
              }, 
              {
                "text": "And on some have compassion, making a distinction;", 
                "num": 22
              }, 
              {
                "text": "but others save with fear, pulling them out of the fire, hating even the garment defiled by the flesh.", 
                "num": 23
              }, 
              {
                "text": "Now to Him who is able to keep you from stumbling, And to present you faultless Before the presence of His glory with exceeding joy,", 
                "num": 24
              }, 
              {
                "text": "To God our Savior, Who alone is wise, Be glory and majesty, Dominion and power, Both now and forever. Amen.", 
                "num": 25
              }
            ], 
            "num": 1
          }
        ]
      }, 
      {
        "name": "Revelation", 
        "chapters": [
          {
            "verses": [
              {
                "text": "The Revelation of Jesus Christ, which God gave Him to show His servants--things which must shortly take place. And He sent and signified it by His angel to His servant John,", 
                "num": 1
              }, 
              {
                "text": "who bore witness to the word of God, and to the testimony of Jesus Christ, to all things that he saw.", 
                "num": 2
              }, 
              {
                "text": "Blessed is he who reads and those who hear the words of this prophecy, and keep those things which are written in it; for the time is near.", 
                "num": 3
              }, 
              {
                "text": "John, to the seven churches which are in Asia: Grace to you and peace from Him who is and who was and who is to come, and from the seven Spirits who are before His throne,", 
                "num": 4
              }, 
              {
                "text": "and from Jesus Christ, the faithful witness, the firstborn from the dead, and the ruler over the kings of the earth. To Him who loved us and washed us from our sins in His own blood,", 
                "num": 5
              }, 
              {
                "text": "and has made us kings and priests to His God and Father, to Him be glory and dominion forever and ever. Amen.", 
                "num": 6
              }, 
              {
                "text": "Behold, He is coming with clouds, and every eye will see Him, even they who pierced Him. And all the tribes of the earth will mourn because of Him. Even so, Amen.", 
                "num": 7
              }, 
              {
                "text": "\"I am the Alpha and the Omega, the Beginning and the End,\" says the Lord, \"who is and who was and who is to come, the Almighty.\"", 
                "num": 8
              }, 
              {
                "text": "I, John, both your brother and companion in the tribulation and kingdom and patience of Jesus Christ, was on the island that is called Patmos for the word of God and for the testimony of Jesus Christ.", 
                "num": 9
              }, 
              {
                "text": "I was in the Spirit on the Lord's Day, and I heard behind me a loud voice, as of a trumpet,", 
                "num": 10
              }, 
              {
                "text": "saying, \"I am the Alpha and the Omega, the First and the Last,\" and, \"What you see, write in a book and send it to the seven churches which are in Asia: to Ephesus, to Smyrna, to Pergamos, to Thyatira, to Sardis, to Philadelphia, and to Laodicea.\"", 
                "num": 11
              }, 
              {
                "text": "Then I turned to see the voice that spoke with me. And having turned I saw seven golden lampstands,", 
                "num": 12
              }, 
              {
                "text": "and in the midst of the seven lampstands One like the Son of Man, clothed with a garment down to the feet and girded about the chest with a golden band.", 
                "num": 13
              }, 
              {
                "text": "His head and hair were white like wool, as white as snow, and His eyes like a flame of fire;", 
                "num": 14
              }, 
              {
                "text": "His feet were like fine brass, as if refined in a furnace, and His voice as the sound of many waters;", 
                "num": 15
              }, 
              {
                "text": "He had in His right hand seven stars, out of His mouth went a sharp two-edged sword, and His countenance was like the sun shining in its strength.", 
                "num": 16
              }, 
              {
                "text": "And when I saw Him, I fell at His feet as dead. But He laid His right hand on me, saying to me, \"Do not be afraid; I am the First and the Last.", 
                "num": 17
              }, 
              {
                "text": "I am He who lives, and was dead, and behold, I am alive forevermore. Amen. And I have the keys of Hades and of Death.", 
                "num": 18
              }, 
              {
                "text": "Write the things which you have seen, and the things which are, and the things which will take place after this.", 
                "num": 19
              }, 
              {
                "text": "The mystery of the seven stars which you saw in My right hand, and the seven golden lampstands: The seven stars are the angels of the seven churches, and the seven lampstands which you saw are the seven churches.", 
                "num": 20
              }
            ], 
            "num": 1
          }, 
          {
            "verses": [
              {
                "text": "\"To the angel of the church of Ephesus write, \"These things says He who holds the seven stars in His right hand, who walks in the midst of the seven golden lampstands:", 
                "num": 1
              }, 
              {
                "text": "\"I know your works, your labor, your patience, and that you cannot bear those who are evil. And you have tested those who say they are apostles and are not, and have found them liars;", 
                "num": 2
              }, 
              {
                "text": "and you have persevered and have patience, and have labored for My name's sake and have not become weary.", 
                "num": 3
              }, 
              {
                "text": "Nevertheless I have this against you, that you have left your first love.", 
                "num": 4
              }, 
              {
                "text": "Remember therefore from where you have fallen; repent and do the first works, or else I will come to you quickly and remove your lampstand from its place--unless you repent.", 
                "num": 5
              }, 
              {
                "text": "But this you have, that you hate the deeds of the Nicolaitans, which I also hate.", 
                "num": 6
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches. To him who overcomes I will give to eat from the tree of life, which is in the midst of the Paradise of God.\"'", 
                "num": 7
              }, 
              {
                "text": "\"And to the angel of the church in Smyrna write, \"These things says the First and the Last, who was dead, and came to life:", 
                "num": 8
              }, 
              {
                "text": "\"I know your works, tribulation, and poverty (but you are rich); and I know the blasphemy of those who say they are Jews and are not, but are a synagogue of Satan.", 
                "num": 9
              }, 
              {
                "text": "Do not fear any of those things which you are about to suffer. Indeed, the devil is about to throw some of you into prison, that you may be tested, and you will have tribulation ten days. Be faithful until death, and I will give you the crown of life.", 
                "num": 10
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches. He who overcomes shall not be hurt by the second death.\"'", 
                "num": 11
              }, 
              {
                "text": "\"And to the angel of the church in Pergamos write, \"These things says He who has the sharp two-edged sword:", 
                "num": 12
              }, 
              {
                "text": "\"I know your works, and where you dwell, where Satan's throne is. And you hold fast to My name, and did not deny My faith even in the days in which Antipas was My faithful martyr, who was killed among you, where Satan dwells.", 
                "num": 13
              }, 
              {
                "text": "But I have a few things against you, because you have there those who hold the doctrine of Balaam, who taught Balak to put a stumbling block before the children of Israel, to eat things sacrificed to idols, and to commit sexual immorality.", 
                "num": 14
              }, 
              {
                "text": "Thus you also have those who hold the doctrine of the Nicolaitans, which thing I hate.", 
                "num": 15
              }, 
              {
                "text": "Repent, or else I will come to you quickly and will fight against them with the sword of My mouth.", 
                "num": 16
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches. To him who overcomes I will give some of the hidden manna to eat. And I will give him a white stone, and on the stone a new name written which no one knows except him who receives it.\"'", 
                "num": 17
              }, 
              {
                "text": "\"And to the angel of the church in Thyatira write, \"These things says the Son of God, who has eyes like a flame of fire, and His feet like fine brass:", 
                "num": 18
              }, 
              {
                "text": "\"I know your works, love, service, faith, and your patience; and as for your works, the last are more than the first.", 
                "num": 19
              }, 
              {
                "text": "Nevertheless I have a few things against you, because you allow that woman Jezebel, who calls herself a prophetess, to teach and seduce My servants to commit sexual immorality and eat things sacrificed to idols.", 
                "num": 20
              }, 
              {
                "text": "And I gave her time to repent of her sexual immorality, and she did not repent.", 
                "num": 21
              }, 
              {
                "text": "Indeed I will cast her into a sickbed, and those who commit adultery with her into great tribulation, unless they repent of their deeds.", 
                "num": 22
              }, 
              {
                "text": "I will kill her children with death, and all the churches shall know that I am He who searches the minds and hearts. And I will give to each one of you according to your works.", 
                "num": 23
              }, 
              {
                "text": "\"Now to you I say, and to the rest in Thyatira, as many as do not have this doctrine, who have not known the depths of Satan, as they say, I will put on you no other burden.", 
                "num": 24
              }, 
              {
                "text": "But hold fast what you have till I come.", 
                "num": 25
              }, 
              {
                "text": "And he who overcomes, and keeps My works until the end, to him I will give power over the nations--", 
                "num": 26
              }, 
              {
                "text": "\"He shall rule them with a rod of iron; They shall be dashed to pieces like the potter's vessels'-- as I also have received from My Father;", 
                "num": 27
              }, 
              {
                "text": "and I will give him the morning star.", 
                "num": 28
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches.\"'", 
                "num": 29
              }
            ], 
            "num": 2
          }, 
          {
            "verses": [
              {
                "text": "\"And to the angel of the church in Sardis write, \"These things says He who has the seven Spirits of God and the seven stars: \"I know your works, that you have a name that you are alive, but you are dead.", 
                "num": 1
              }, 
              {
                "text": "Be watchful, and strengthen the things which remain, that are ready to die, for I have not found your works perfect before God.", 
                "num": 2
              }, 
              {
                "text": "Remember therefore how you have received and heard; hold fast and repent. Therefore if you will not watch, I will come upon you as a thief, and you will not know what hour I will come upon you.", 
                "num": 3
              }, 
              {
                "text": "You have a few names even in Sardis who have not defiled their garments; and they shall walk with Me in white, for they are worthy.", 
                "num": 4
              }, 
              {
                "text": "He who overcomes shall be clothed in white garments, and I will not blot out his name from the Book of Life; but I will confess his name before My Father and before His angels.", 
                "num": 5
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches.\"'", 
                "num": 6
              }, 
              {
                "text": "\"And to the angel of the church in Philadelphia write, \"These things says He who is holy, He who is true, \"He who has the key of David, He who opens and no one shuts, and shuts and no one opens\":", 
                "num": 7
              }, 
              {
                "text": "\"I know your works. See, I have set before you an open door, and no one can shut it; for you have a little strength, have kept My word, and have not denied My name.", 
                "num": 8
              }, 
              {
                "text": "Indeed I will make those of the synagogue of Satan, who say they are Jews and are not, but lie--indeed I will make them come and worship before your feet, and to know that I have loved you.", 
                "num": 9
              }, 
              {
                "text": "Because you have kept My command to persevere, I also will keep you from the hour of trial which shall come upon the whole world, to test those who dwell on the earth.", 
                "num": 10
              }, 
              {
                "text": "Behold, I am coming quickly! Hold fast what you have, that no one may take your crown.", 
                "num": 11
              }, 
              {
                "text": "He who overcomes, I will make him a pillar in the temple of My God, and he shall go out no more. I will write on him the name of My God and the name of the city of My God, the New Jerusalem, which comes down out of heaven from My God. And I will write on him My new name.", 
                "num": 12
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches.\"'", 
                "num": 13
              }, 
              {
                "text": "\"And to the angel of the church of the Laodiceans write, \"These things says the Amen, the Faithful and True Witness, the Beginning of the creation of God:", 
                "num": 14
              }, 
              {
                "text": "\"I know your works, that you are neither cold nor hot. I could wish you were cold or hot.", 
                "num": 15
              }, 
              {
                "text": "So then, because you are lukewarm, and neither cold nor hot, I will vomit you out of My mouth.", 
                "num": 16
              }, 
              {
                "text": "Because you say, \"I am rich, have become wealthy, and have need of nothing'--and do not know that you are wretched, miserable, poor, blind, and naked--", 
                "num": 17
              }, 
              {
                "text": "I counsel you to buy from Me gold refined in the fire, that you may be rich; and white garments, that you may be clothed, that the shame of your nakedness may not be revealed; and anoint your eyes with eye salve, that you may see.", 
                "num": 18
              }, 
              {
                "text": "As many as I love, I rebuke and chasten. Therefore be zealous and repent.", 
                "num": 19
              }, 
              {
                "text": "Behold, I stand at the door and knock. If anyone hears My voice and opens the door, I will come in to him and dine with him, and he with Me.", 
                "num": 20
              }, 
              {
                "text": "To him who overcomes I will grant to sit with Me on My throne, as I also overcame and sat down with My Father on His throne.", 
                "num": 21
              }, 
              {
                "text": "\"He who has an ear, let him hear what the Spirit says to the churches.\"\"'", 
                "num": 22
              }
            ], 
            "num": 3
          }, 
          {
            "verses": [
              {
                "text": "After these things I looked, and behold, a door standing open in heaven. And the first voice which I heard was like a trumpet speaking with me, saying, \"Come up here, and I will show you things which must take place after this.\"", 
                "num": 1
              }, 
              {
                "text": "Immediately I was in the Spirit; and behold, a throne set in heaven, and One sat on the throne.", 
                "num": 2
              }, 
              {
                "text": "And He who sat there was like a jasper and a sardius stone in appearance; and there was a rainbow around the throne, in appearance like an emerald.", 
                "num": 3
              }, 
              {
                "text": "Around the throne were twenty-four thrones, and on the thrones I saw twenty-four elders sitting, clothed in white robes; and they had crowns of gold on their heads.", 
                "num": 4
              }, 
              {
                "text": "And from the throne proceeded lightnings, thunderings, and voices. Seven lamps of fire were burning before the throne, which are the seven Spirits of God.", 
                "num": 5
              }, 
              {
                "text": "Before the throne there was a sea of glass, like crystal. And in the midst of the throne, and around the throne, were four living creatures full of eyes in front and in back.", 
                "num": 6
              }, 
              {
                "text": "The first living creature was like a lion, the second living creature like a calf, the third living creature had a face like a man, and the fourth living creature was like a flying eagle.", 
                "num": 7
              }, 
              {
                "text": "The four living creatures, each having six wings, were full of eyes around and within. And they do not rest day or night, saying: \"Holy, holy, holy, Lord God Almighty, Who was and is and is to come!\"", 
                "num": 8
              }, 
              {
                "text": "Whenever the living creatures give glory and honor and thanks to Him who sits on the throne, who lives forever and ever,", 
                "num": 9
              }, 
              {
                "text": "the twenty-four elders fall down before Him who sits on the throne and worship Him who lives forever and ever, and cast their crowns before the throne, saying:", 
                "num": 10
              }, 
              {
                "text": "\"You are worthy, O Lord, To receive glory and honor and power; For You created all things, And by Your will they exist and were created.\"", 
                "num": 11
              }
            ], 
            "num": 4
          }, 
          {
            "verses": [
              {
                "text": "And I saw in the right hand of Him who sat on the throne a scroll written inside and on the back, sealed with seven seals.", 
                "num": 1
              }, 
              {
                "text": "Then I saw a strong angel proclaiming with a loud voice, \"Who is worthy to open the scroll and to loose its seals?\"", 
                "num": 2
              }, 
              {
                "text": "And no one in heaven or on the earth or under the earth was able to open the scroll, or to look at it.", 
                "num": 3
              }, 
              {
                "text": "So I wept much, because no one was found worthy to open and read the scroll, or to look at it.", 
                "num": 4
              }, 
              {
                "text": "But one of the elders said to me, \"Do not weep. Behold, the Lion of the tribe of Judah, the Root of David, has prevailed to open the scroll and to loose its seven seals.\"", 
                "num": 5
              }, 
              {
                "text": "And I looked, and behold, in the midst of the throne and of the four living creatures, and in the midst of the elders, stood a Lamb as though it had been slain, having seven horns and seven eyes, which are the seven Spirits of God sent out into all the earth.", 
                "num": 6
              }, 
              {
                "text": "Then He came and took the scroll out of the right hand of Him who sat on the throne.", 
                "num": 7
              }, 
              {
                "text": "Now when He had taken the scroll, the four living creatures and the twenty-four elders fell down before the Lamb, each having a harp, and golden bowls full of incense, which are the prayers of the saints.", 
                "num": 8
              }, 
              {
                "text": "And they sang a new song, saying: \"You are worthy to take the scroll, And to open its seals; For You were slain, And have redeemed us to God by Your blood Out of every tribe and tongue and people and nation,", 
                "num": 9
              }, 
              {
                "text": "And have made us kings and priests to our God; And we shall reign on the earth.\"", 
                "num": 10
              }, 
              {
                "text": "Then I looked, and I heard the voice of many angels around the throne, the living creatures, and the elders; and the number of them was ten thousand times ten thousand, and thousands of thousands,", 
                "num": 11
              }, 
              {
                "text": "saying with a loud voice: \"Worthy is the Lamb who was slain To receive power and riches and wisdom, And strength and honor and glory and blessing!\"", 
                "num": 12
              }, 
              {
                "text": "And every creature which is in heaven and on the earth and under the earth and such as are in the sea, and all that are in them, I heard saying: \"Blessing and honor and glory and power Be to Him who sits on the throne, And to the Lamb, forever and ever!\"", 
                "num": 13
              }, 
              {
                "text": "Then the four living creatures said, \"Amen!\" And the twenty-four elders fell down and worshiped Him who lives forever and ever.", 
                "num": 14
              }
            ], 
            "num": 5
          }, 
          {
            "verses": [
              {
                "text": "Now I saw when the Lamb opened one of the seals; and I heard one of the four living creatures saying with a voice like thunder, \"Come and see.\"", 
                "num": 1
              }, 
              {
                "text": "And I looked, and behold, a white horse. He who sat on it had a bow; and a crown was given to him, and he went out conquering and to conquer.", 
                "num": 2
              }, 
              {
                "text": "When He opened the second seal, I heard the second living creature saying, \"Come and see.\"", 
                "num": 3
              }, 
              {
                "text": "Another horse, fiery red, went out. And it was granted to the one who sat on it to take peace from the earth, and that people should kill one another; and there was given to him a great sword.", 
                "num": 4
              }, 
              {
                "text": "When He opened the third seal, I heard the third living creature say, \"Come and see.\" So I looked, and behold, a black horse, and he who sat on it had a pair of scales in his hand.", 
                "num": 5
              }, 
              {
                "text": "And I heard a voice in the midst of the four living creatures saying, \"A quart of wheat for a denarius, and three quarts of barley for a denarius; and do not harm the oil and the wine.\"", 
                "num": 6
              }, 
              {
                "text": "When He opened the fourth seal, I heard the voice of the fourth living creature saying, \"Come and see.\"", 
                "num": 7
              }, 
              {
                "text": "So I looked, and behold, a pale horse. And the name of him who sat on it was Death, and Hades followed with him. And power was given to them over a fourth of the earth, to kill with sword, with hunger, with death, and by the beasts of the earth.", 
                "num": 8
              }, 
              {
                "text": "When He opened the fifth seal, I saw under the altar the souls of those who had been slain for the word of God and for the testimony which they held.", 
                "num": 9
              }, 
              {
                "text": "And they cried with a loud voice, saying, \"How long, O Lord, holy and true, until You judge and avenge our blood on those who dwell on the earth?\"", 
                "num": 10
              }, 
              {
                "text": "Then a white robe was given to each of them; and it was said to them that they should rest a little while longer, until both the number of their fellow servants and their brethren, who would be killed as they were, was completed.", 
                "num": 11
              }, 
              {
                "text": "I looked when He opened the sixth seal, and behold, there was a great earthquake; and the sun became black as sackcloth of hair, and the moon became like blood.", 
                "num": 12
              }, 
              {
                "text": "And the stars of heaven fell to the earth, as a fig tree drops its late figs when it is shaken by a mighty wind.", 
                "num": 13
              }, 
              {
                "text": "Then the sky receded as a scroll when it is rolled up, and every mountain and island was moved out of its place.", 
                "num": 14
              }, 
              {
                "text": "And the kings of the earth, the great men, the rich men, the commanders, the mighty men, every slave and every free man, hid themselves in the caves and in the rocks of the mountains,", 
                "num": 15
              }, 
              {
                "text": "and said to the mountains and rocks, \"Fall on us and hide us from the face of Him who sits on the throne and from the wrath of the Lamb!", 
                "num": 16
              }, 
              {
                "text": "For the great day of His wrath has come, and who is able to stand?\"", 
                "num": 17
              }
            ], 
            "num": 6
          }, 
          {
            "verses": [
              {
                "text": "After these things I saw four angels standing at the four corners of the earth, holding the four winds of the earth, that the wind should not blow on the earth, on the sea, or on any tree.", 
                "num": 1
              }, 
              {
                "text": "Then I saw another angel ascending from the east, having the seal of the living God. And he cried with a loud voice to the four angels to whom it was granted to harm the earth and the sea,", 
                "num": 2
              }, 
              {
                "text": "saying, \"Do not harm the earth, the sea, or the trees till we have sealed the servants of our God on their foreheads.\"", 
                "num": 3
              }, 
              {
                "text": "And I heard the number of those who were sealed. One hundred and forty-four thousand of all the tribes of the children of Israel were sealed:", 
                "num": 4
              }, 
              {
                "text": "of the tribe of Judah twelve thousand were sealed; of the tribe of Reuben twelve thousand were sealed; of the tribe of Gad twelve thousand were sealed;", 
                "num": 5
              }, 
              {
                "text": "of the tribe of Asher twelve thousand were sealed; of the tribe of Naphtali twelve thousand were sealed; of the tribe of Manasseh twelve thousand were sealed;", 
                "num": 6
              }, 
              {
                "text": "of the tribe of Simeon twelve thousand were sealed; of the tribe of Levi twelve thousand were sealed; of the tribe of Issachar twelve thousand were sealed;", 
                "num": 7
              }, 
              {
                "text": "of the tribe of Zebulun twelve thousand were sealed; of the tribe of Joseph twelve thousand were sealed; of the tribe of Benjamin twelve thousand were sealed.", 
                "num": 8
              }, 
              {
                "text": "After these things I looked, and behold, a great multitude which no one could number, of all nations, tribes, peoples, and tongues, standing before the throne and before the Lamb, clothed with white robes, with palm branches in their hands,", 
                "num": 9
              }, 
              {
                "text": "and crying out with a loud voice, saying, \"Salvation belongs to our God who sits on the throne, and to the Lamb!\"", 
                "num": 10
              }, 
              {
                "text": "All the angels stood around the throne and the elders and the four living creatures, and fell on their faces before the throne and worshiped God,", 
                "num": 11
              }, 
              {
                "text": "saying: \"Amen! Blessing and glory and wisdom, Thanksgiving and honor and power and might, Be to our God forever and ever. Amen.\"", 
                "num": 12
              }, 
              {
                "text": "Then one of the elders answered, saying to me, \"Who are these arrayed in white robes, and where did they come from?\"", 
                "num": 13
              }, 
              {
                "text": "And I said to him, \"Sir, you know.\" So he said to me, \"These are the ones who come out of the great tribulation, and washed their robes and made them white in the blood of the Lamb.", 
                "num": 14
              }, 
              {
                "text": "Therefore they are before the throne of God, and serve Him day and night in His temple. And He who sits on the throne will dwell among them.", 
                "num": 15
              }, 
              {
                "text": "They shall neither hunger anymore nor thirst anymore; the sun shall not strike them, nor any heat;", 
                "num": 16
              }, 
              {
                "text": "for the Lamb who is in the midst of the throne will shepherd them and lead them to living fountains of waters. And God will wipe away every tear from their eyes.\"", 
                "num": 17
              }
            ], 
            "num": 7
          }, 
          {
            "verses": [
              {
                "text": "When He opened the seventh seal, there was silence in heaven for about half an hour.", 
                "num": 1
              }, 
              {
                "text": "And I saw the seven angels who stand before God, and to them were given seven trumpets.", 
                "num": 2
              }, 
              {
                "text": "Then another angel, having a golden censer, came and stood at the altar. He was given much incense, that he should offer it with the prayers of all the saints upon the golden altar which was before the throne.", 
                "num": 3
              }, 
              {
                "text": "And the smoke of the incense, with the prayers of the saints, ascended before God from the angel's hand.", 
                "num": 4
              }, 
              {
                "text": "Then the angel took the censer, filled it with fire from the altar, and threw it to the earth. And there were noises, thunderings, lightnings, and an earthquake.", 
                "num": 5
              }, 
              {
                "text": "So the seven angels who had the seven trumpets prepared themselves to sound.", 
                "num": 6
              }, 
              {
                "text": "The first angel sounded: And hail and fire followed, mingled with blood, and they were thrown to the earth. And a third of the trees were burned up, and all green grass was burned up.", 
                "num": 7
              }, 
              {
                "text": "Then the second angel sounded: And something like a great mountain burning with fire was thrown into the sea, and a third of the sea became blood.", 
                "num": 8
              }, 
              {
                "text": "And a third of the living creatures in the sea died, and a third of the ships were destroyed.", 
                "num": 9
              }, 
              {
                "text": "Then the third angel sounded: And a great star fell from heaven, burning like a torch, and it fell on a third of the rivers and on the springs of water.", 
                "num": 10
              }, 
              {
                "text": "The name of the star is Wormwood. A third of the waters became wormwood, and many men died from the water, because it was made bitter.", 
                "num": 11
              }, 
              {
                "text": "Then the fourth angel sounded: And a third of the sun was struck, a third of the moon, and a third of the stars, so that a third of them were darkened. A third of the day did not shine, and likewise the night.", 
                "num": 12
              }, 
              {
                "text": "And I looked, and I heard an angel flying through the midst of heaven, saying with a loud voice, \"Woe, woe, woe to the inhabitants of the earth, because of the remaining blasts of the trumpet of the three angels who are about to sound!\"", 
                "num": 13
              }
            ], 
            "num": 8
          }, 
          {
            "verses": [
              {
                "text": "Then the fifth angel sounded: And I saw a star fallen from heaven to the earth. To him was given the key to the bottomless pit.", 
                "num": 1
              }, 
              {
                "text": "And he opened the bottomless pit, and smoke arose out of the pit like the smoke of a great furnace. So the sun and the air were darkened because of the smoke of the pit.", 
                "num": 2
              }, 
              {
                "text": "Then out of the smoke locusts came upon the earth. And to them was given power, as the scorpions of the earth have power.", 
                "num": 3
              }, 
              {
                "text": "They were commanded not to harm the grass of the earth, or any green thing, or any tree, but only those men who do not have the seal of God on their foreheads.", 
                "num": 4
              }, 
              {
                "text": "And they were not given authority to kill them, but to torment them for five months. Their torment was like the torment of a scorpion when it strikes a man.", 
                "num": 5
              }, 
              {
                "text": "In those days men will seek death and will not find it; they will desire to die, and death will flee from them.", 
                "num": 6
              }, 
              {
                "text": "The shape of the locusts was like horses prepared for battle. On their heads were crowns of something like gold, and their faces were like the faces of men.", 
                "num": 7
              }, 
              {
                "text": "They had hair like women's hair, and their teeth were like lions' teeth.", 
                "num": 8
              }, 
              {
                "text": "And they had breastplates like breastplates of iron, and the sound of their wings was like the sound of chariots with many horses running into battle.", 
                "num": 9
              }, 
              {
                "text": "They had tails like scorpions, and there were stings in their tails. Their power was to hurt men five months.", 
                "num": 10
              }, 
              {
                "text": "And they had as king over them the angel of the bottomless pit, whose name in Hebrew is Abaddon, but in Greek he has the name Apollyon.", 
                "num": 11
              }, 
              {
                "text": "One woe is past. Behold, still two more woes are coming after these things.", 
                "num": 12
              }, 
              {
                "text": "Then the sixth angel sounded: And I heard a voice from the four horns of the golden altar which is before God,", 
                "num": 13
              }, 
              {
                "text": "saying to the sixth angel who had the trumpet, \"Release the four angels who are bound at the great river Euphrates.\"", 
                "num": 14
              }, 
              {
                "text": "So the four angels, who had been prepared for the hour and day and month and year, were released to kill a third of mankind.", 
                "num": 15
              }, 
              {
                "text": "Now the number of the army of the horsemen was two hundred million; I heard the number of them.", 
                "num": 16
              }, 
              {
                "text": "And thus I saw the horses in the vision: those who sat on them had breastplates of fiery red, hyacinth blue, and sulfur yellow; and the heads of the horses were like the heads of lions; and out of their mouths came fire, smoke, and brimstone.", 
                "num": 17
              }, 
              {
                "text": "By these three plagues a third of mankind was killed--by the fire and the smoke and the brimstone which came out of their mouths.", 
                "num": 18
              }, 
              {
                "text": "For their power is in their mouth and in their tails; for their tails are like serpents, having heads; and with them they do harm.", 
                "num": 19
              }, 
              {
                "text": "But the rest of mankind, who were not killed by these plagues, did not repent of the works of their hands, that they should not worship demons, and idols of gold, silver, brass, stone, and wood, which can neither see nor hear nor walk.", 
                "num": 20
              }, 
              {
                "text": "And they did not repent of their murders or their sorceries or their sexual immorality or their thefts.", 
                "num": 21
              }
            ], 
            "num": 9
          }, 
          {
            "verses": [
              {
                "text": "I saw still another mighty angel coming down from heaven, clothed with a cloud. And a rainbow was on his head, his face was like the sun, and his feet like pillars of fire.", 
                "num": 1
              }, 
              {
                "text": "He had a little book open in his hand. And he set his right foot on the sea and his left foot on the land,", 
                "num": 2
              }, 
              {
                "text": "and cried with a loud voice, as when a lion roars. When he cried out, seven thunders uttered their voices.", 
                "num": 3
              }, 
              {
                "text": "Now when the seven thunders uttered their voices, I was about to write; but I heard a voice from heaven saying to me, \"Seal up the things which the seven thunders uttered, and do not write them.\"", 
                "num": 4
              }, 
              {
                "text": "The angel whom I saw standing on the sea and on the land raised up his hand to heaven", 
                "num": 5
              }, 
              {
                "text": "and swore by Him who lives forever and ever, who created heaven and the things that are in it, the earth and the things that are in it, and the sea and the things that are in it, that there should be delay no longer,", 
                "num": 6
              }, 
              {
                "text": "but in the days of the sounding of the seventh angel, when he is about to sound, the mystery of God would be finished, as He declared to His servants the prophets.", 
                "num": 7
              }, 
              {
                "text": "Then the voice which I heard from heaven spoke to me again and said, \"Go, take the little book which is open in the hand of the angel who stands on the sea and on the earth.\"", 
                "num": 8
              }, 
              {
                "text": "So I went to the angel and said to him, \"Give me the little book.\" And he said to me, \"Take and eat it; and it will make your stomach bitter, but it will be as sweet as honey in your mouth.\"", 
                "num": 9
              }, 
              {
                "text": "Then I took the little book out of the angel's hand and ate it, and it was as sweet as honey in my mouth. But when I had eaten it, my stomach became bitter.", 
                "num": 10
              }, 
              {
                "text": "And he said to me, \"You must prophesy again about many peoples, nations, tongues, and kings.\"", 
                "num": 11
              }
            ], 
            "num": 10
          }, 
          {
            "verses": [
              {
                "text": "Then I was given a reed like a measuring rod. And the angel stood, saying, \"Rise and measure the temple of God, the altar, and those who worship there.", 
                "num": 1
              }, 
              {
                "text": "But leave out the court which is outside the temple, and do not measure it, for it has been given to the Gentiles. And they will tread the holy city underfoot for forty-two months.", 
                "num": 2
              }, 
              {
                "text": "And I will give power to my two witnesses, and they will prophesy one thousand two hundred and sixty days, clothed in sackcloth.\"", 
                "num": 3
              }, 
              {
                "text": "These are the two olive trees and the two lampstands standing before the God of the earth.", 
                "num": 4
              }, 
              {
                "text": "And if anyone wants to harm them, fire proceeds from their mouth and devours their enemies. And if anyone wants to harm them, he must be killed in this manner.", 
                "num": 5
              }, 
              {
                "text": "These have power to shut heaven, so that no rain falls in the days of their prophecy; and they have power over waters to turn them to blood, and to strike the earth with all plagues, as often as they desire.", 
                "num": 6
              }, 
              {
                "text": "When they finish their testimony, the beast that ascends out of the bottomless pit will make war against them, overcome them, and kill them.", 
                "num": 7
              }, 
              {
                "text": "And their dead bodies will lie in the street of the great city which spiritually is called Sodom and Egypt, where also our Lord was crucified.", 
                "num": 8
              }, 
              {
                "text": "Then those from the peoples, tribes, tongues, and nations will see their dead bodies three-and-a-half days, and not allow their dead bodies to be put into graves.", 
                "num": 9
              }, 
              {
                "text": "And those who dwell on the earth will rejoice over them, make merry, and send gifts to one another, because these two prophets tormented those who dwell on the earth.", 
                "num": 10
              }, 
              {
                "text": "Now after the three-and-a-half days the breath of life from God entered them, and they stood on their feet, and great fear fell on those who saw them.", 
                "num": 11
              }, 
              {
                "text": "And they heard a loud voice from heaven saying to them, \"Come up here.\" And they ascended to heaven in a cloud, and their enemies saw them.", 
                "num": 12
              }, 
              {
                "text": "In the same hour there was a great earthquake, and a tenth of the city fell. In the earthquake seven thousand people were killed, and the rest were afraid and gave glory to the God of heaven.", 
                "num": 13
              }, 
              {
                "text": "The second woe is past. Behold, the third woe is coming quickly.", 
                "num": 14
              }, 
              {
                "text": "Then the seventh angel sounded: And there were loud voices in heaven, saying, \"The kingdoms of this world have become the kingdoms of our Lord and of His Christ, and He shall reign forever and ever!\"", 
                "num": 15
              }, 
              {
                "text": "And the twenty-four elders who sat before God on their thrones fell on their faces and worshiped God,", 
                "num": 16
              }, 
              {
                "text": "saying: \"We give You thanks, O Lord God Almighty, The One who is and who was and who is to come, Because You have taken Your great power and reigned.", 
                "num": 17
              }, 
              {
                "text": "The nations were angry, and Your wrath has come, And the time of the dead, that they should be judged, And that You should reward Your servants the prophets and the saints, And those who fear Your name, small and great, And should destroy those who destroy the earth.\"", 
                "num": 18
              }, 
              {
                "text": "Then the temple of God was opened in heaven, and the ark of His covenant was seen in His temple. And there were lightnings, noises, thunderings, an earthquake, and great hail.", 
                "num": 19
              }
            ], 
            "num": 11
          }, 
          {
            "verses": [
              {
                "text": "Now a great sign appeared in heaven: a woman clothed with the sun, with the moon under her feet, and on her head a garland of twelve stars.", 
                "num": 1
              }, 
              {
                "text": "Then being with child, she cried out in labor and in pain to give birth.", 
                "num": 2
              }, 
              {
                "text": "And another sign appeared in heaven: behold, a great, fiery red dragon having seven heads and ten horns, and seven diadems on his heads.", 
                "num": 3
              }, 
              {
                "text": "His tail drew a third of the stars of heaven and threw them to the earth. And the dragon stood before the woman who was ready to give birth, to devour her Child as soon as it was born.", 
                "num": 4
              }, 
              {
                "text": "She bore a male Child who was to rule all nations with a rod of iron. And her Child was caught up to God and His throne.", 
                "num": 5
              }, 
              {
                "text": "Then the woman fled into the wilderness, where she has a place prepared by God, that they should feed her there one thousand two hundred and sixty days.", 
                "num": 6
              }, 
              {
                "text": "And war broke out in heaven: Michael and his angels fought with the dragon; and the dragon and his angels fought,", 
                "num": 7
              }, 
              {
                "text": "but they did not prevail, nor was a place found for them in heaven any longer.", 
                "num": 8
              }, 
              {
                "text": "So the great dragon was cast out, that serpent of old, called the Devil and Satan, who deceives the whole world; he was cast to the earth, and his angels were cast out with him.", 
                "num": 9
              }, 
              {
                "text": "Then I heard a loud voice saying in heaven, \"Now salvation, and strength, and the kingdom of our God, and the power of His Christ have come, for the accuser of our brethren, who accused them before our God day and night, has been cast down.", 
                "num": 10
              }, 
              {
                "text": "And they overcame him by the blood of the Lamb and by the word of their testimony, and they did not love their lives to the death.", 
                "num": 11
              }, 
              {
                "text": "Therefore rejoice, O heavens, and you who dwell in them! Woe to the inhabitants of the earth and the sea! For the devil has come down to you, having great wrath, because he knows that he has a short time.\"", 
                "num": 12
              }, 
              {
                "text": "Now when the dragon saw that he had been cast to the earth, he persecuted the woman who gave birth to the male Child.", 
                "num": 13
              }, 
              {
                "text": "But the woman was given two wings of a great eagle, that she might fly into the wilderness to her place, where she is nourished for a time and times and half a time, from the presence of the serpent.", 
                "num": 14
              }, 
              {
                "text": "So the serpent spewed water out of his mouth like a flood after the woman, that he might cause her to be carried away by the flood.", 
                "num": 15
              }, 
              {
                "text": "But the earth helped the woman, and the earth opened its mouth and swallowed up the flood which the dragon had spewed out of his mouth.", 
                "num": 16
              }, 
              {
                "text": "And the dragon was enraged with the woman, and he went to make war with the rest of her offspring, who keep the commandments of God and have the testimony of Jesus Christ.", 
                "num": 17
              }
            ], 
            "num": 12
          }, 
          {
            "verses": [
              {
                "text": "Then I stood on the sand of the sea. And I saw a beast rising up out of the sea, having seven heads and ten horns, and on his horns ten crowns, and on his heads a blasphemous name.", 
                "num": 1
              }, 
              {
                "text": "Now the beast which I saw was like a leopard, his feet were like the feet of a bear, and his mouth like the mouth of a lion. The dragon gave him his power, his throne, and great authority.", 
                "num": 2
              }, 
              {
                "text": "And I saw one of his heads as if it had been mortally wounded, and his deadly wound was healed. And all the world marveled and followed the beast.", 
                "num": 3
              }, 
              {
                "text": "So they worshiped the dragon who gave authority to the beast; and they worshiped the beast, saying, \"Who is like the beast? Who is able to make war with him?\"", 
                "num": 4
              }, 
              {
                "text": "And he was given a mouth speaking great things and blasphemies, and he was given authority to continue for forty-two months.", 
                "num": 5
              }, 
              {
                "text": "Then he opened his mouth in blasphemy against God, to blaspheme His name, His tabernacle, and those who dwell in heaven.", 
                "num": 6
              }, 
              {
                "text": "It was granted to him to make war with the saints and to overcome them. And authority was given him over every tribe, tongue, and nation.", 
                "num": 7
              }, 
              {
                "text": "All who dwell on the earth will worship him, whose names have not been written in the Book of Life of the Lamb slain from the foundation of the world.", 
                "num": 8
              }, 
              {
                "text": "If anyone has an ear, let him hear.", 
                "num": 9
              }, 
              {
                "text": "He who leads into captivity shall go into captivity; he who kills with the sword must be killed with the sword. Here is the patience and the faith of the saints.", 
                "num": 10
              }, 
              {
                "text": "Then I saw another beast coming up out of the earth, and he had two horns like a lamb and spoke like a dragon.", 
                "num": 11
              }, 
              {
                "text": "And he exercises all the authority of the first beast in his presence, and causes the earth and those who dwell in it to worship the first beast, whose deadly wound was healed.", 
                "num": 12
              }, 
              {
                "text": "He performs great signs, so that he even makes fire come down from heaven on the earth in the sight of men.", 
                "num": 13
              }, 
              {
                "text": "And he deceives those who dwell on the earth by those signs which he was granted to do in the sight of the beast, telling those who dwell on the earth to make an image to the beast who was wounded by the sword and lived.", 
                "num": 14
              }, 
              {
                "text": "He was granted power to give breath to the image of the beast, that the image of the beast should both speak and cause as many as would not worship the image of the beast to be killed.", 
                "num": 15
              }, 
              {
                "text": "He causes all, both small and great, rich and poor, free and slave, to receive a mark on their right hand or on their foreheads,", 
                "num": 16
              }, 
              {
                "text": "and that no one may buy or sell except one who has the mark or the name of the beast, or the number of his name.", 
                "num": 17
              }, 
              {
                "text": "Here is wisdom. Let him who has understanding calculate the number of the beast, for it is the number of a man: His number is 666.", 
                "num": 18
              }
            ], 
            "num": 13
          }, 
          {
            "verses": [
              {
                "text": "Then I looked, and behold, a Lamb standing on Mount Zion, and with Him one hundred and forty-four thousand, having His Father's name written on their foreheads.", 
                "num": 1
              }, 
              {
                "text": "And I heard a voice from heaven, like the voice of many waters, and like the voice of loud thunder. And I heard the sound of harpists playing their harps.", 
                "num": 2
              }, 
              {
                "text": "They sang as it were a new song before the throne, before the four living creatures, and the elders; and no one could learn that song except the hundred and forty-four thousand who were redeemed from the earth.", 
                "num": 3
              }, 
              {
                "text": "These are the ones who were not defiled with women, for they are virgins. These are the ones who follow the Lamb wherever He goes. These were redeemed from among men, being firstfruits to God and to the Lamb.", 
                "num": 4
              }, 
              {
                "text": "And in their mouth was found no deceit, for they are without fault before the throne of God.", 
                "num": 5
              }, 
              {
                "text": "Then I saw another angel flying in the midst of heaven, having the everlasting gospel to preach to those who dwell on the earth--to every nation, tribe, tongue, and people--", 
                "num": 6
              }, 
              {
                "text": "saying with a loud voice, \"Fear God and give glory to Him, for the hour of His judgment has come; and worship Him who made heaven and earth, the sea and springs of water.\"", 
                "num": 7
              }, 
              {
                "text": "And another angel followed, saying, \"Babylon is fallen, is fallen, that great city, because she has made all nations drink of the wine of the wrath of her fornication.\"", 
                "num": 8
              }, 
              {
                "text": "Then a third angel followed them, saying with a loud voice, \"If anyone worships the beast and his image, and receives his mark on his forehead or on his hand,", 
                "num": 9
              }, 
              {
                "text": "he himself shall also drink of the wine of the wrath of God, which is poured out full strength into the cup of His indignation. He shall be tormented with fire and brimstone in the presence of the holy angels and in the presence of the Lamb.", 
                "num": 10
              }, 
              {
                "text": "And the smoke of their torment ascends forever and ever; and they have no rest day or night, who worship the beast and his image, and whoever receives the mark of his name.\"", 
                "num": 11
              }, 
              {
                "text": "Here is the patience of the saints; here are those who keep the commandments of God and the faith of Jesus.", 
                "num": 12
              }, 
              {
                "text": "Then I heard a voice from heaven saying to me, \"Write: \"Blessed are the dead who die in the Lord from now on.\"' \"Yes,\" says the Spirit, \"that they may rest from their labors, and their works follow them.\"", 
                "num": 13
              }, 
              {
                "text": "Then I looked, and behold, a white cloud, and on the cloud sat One like the Son of Man, having on His head a golden crown, and in His hand a sharp sickle.", 
                "num": 14
              }, 
              {
                "text": "And another angel came out of the temple, crying with a loud voice to Him who sat on the cloud, \"Thrust in Your sickle and reap, for the time has come for You to reap, for the harvest of the earth is ripe.\"", 
                "num": 15
              }, 
              {
                "text": "So He who sat on the cloud thrust in His sickle on the earth, and the earth was reaped.", 
                "num": 16
              }, 
              {
                "text": "Then another angel came out of the temple which is in heaven, he also having a sharp sickle.", 
                "num": 17
              }, 
              {
                "text": "And another angel came out from the altar, who had power over fire, and he cried with a loud cry to him who had the sharp sickle, saying, \"Thrust in your sharp sickle and gather the clusters of the vine of the earth, for her grapes are fully ripe.\"", 
                "num": 18
              }, 
              {
                "text": "So the angel thrust his sickle into the earth and gathered the vine of the earth, and threw it into the great winepress of the wrath of God.", 
                "num": 19
              }, 
              {
                "text": "And the winepress was trampled outside the city, and blood came out of the winepress, up to the horses' bridles, for one thousand six hundred furlongs.", 
                "num": 20
              }
            ], 
            "num": 14
          }, 
          {
            "verses": [
              {
                "text": "Then I saw another sign in heaven, great and marvelous: seven angels having the seven last plagues, for in them the wrath of God is complete.", 
                "num": 1
              }, 
              {
                "text": "And I saw something like a sea of glass mingled with fire, and those who have the victory over the beast, over his image and over his mark and over the number of his name, standing on the sea of glass, having harps of God.", 
                "num": 2
              }, 
              {
                "text": "They sing the song of Moses, the servant of God, and the song of the Lamb, saying: \"Great and marvelous are Your works, Lord God Almighty! Just and true are Your ways, O King of the saints!", 
                "num": 3
              }, 
              {
                "text": "Who shall not fear You, O Lord, and glorify Your name? For You alone are holy. For all nations shall come and worship before You, For Your judgments have been manifested.\"", 
                "num": 4
              }, 
              {
                "text": "After these things I looked, and behold, the temple of the tabernacle of the testimony in heaven was opened.", 
                "num": 5
              }, 
              {
                "text": "And out of the temple came the seven angels having the seven plagues, clothed in pure bright linen, and having their chests girded with golden bands.", 
                "num": 6
              }, 
              {
                "text": "Then one of the four living creatures gave to the seven angels seven golden bowls full of the wrath of God who lives forever and ever.", 
                "num": 7
              }, 
              {
                "text": "The temple was filled with smoke from the glory of God and from His power, and no one was able to enter the temple till the seven plagues of the seven angels were completed.", 
                "num": 8
              }
            ], 
            "num": 15
          }, 
          {
            "verses": [
              {
                "text": "Then I heard a loud voice from the temple saying to the seven angels, \"Go and pour out the bowls of the wrath of God on the earth.\"", 
                "num": 1
              }, 
              {
                "text": "So the first went and poured out his bowl upon the earth, and a foul and loathsome sore came upon the men who had the mark of the beast and those who worshiped his image.", 
                "num": 2
              }, 
              {
                "text": "Then the second angel poured out his bowl on the sea, and it became blood as of a dead man; and every living creature in the sea died.", 
                "num": 3
              }, 
              {
                "text": "Then the third angel poured out his bowl on the rivers and springs of water, and they became blood.", 
                "num": 4
              }, 
              {
                "text": "And I heard the angel of the waters saying: \"You are righteous, O Lord, The One who is and who was and who is to be, Because You have judged these things.", 
                "num": 5
              }, 
              {
                "text": "For they have shed the blood of saints and prophets, And You have given them blood to drink. For it is their just due.\"", 
                "num": 6
              }, 
              {
                "text": "And I heard another from the altar saying, \"Even so, Lord God Almighty, true and righteous are Your judgments.\"", 
                "num": 7
              }, 
              {
                "text": "Then the fourth angel poured out his bowl on the sun, and power was given to him to scorch men with fire.", 
                "num": 8
              }, 
              {
                "text": "And men were scorched with great heat, and they blasphemed the name of God who has power over these plagues; and they did not repent and give Him glory.", 
                "num": 9
              }, 
              {
                "text": "Then the fifth angel poured out his bowl on the throne of the beast, and his kingdom became full of darkness; and they gnawed their tongues because of the pain.", 
                "num": 10
              }, 
              {
                "text": "They blasphemed the God of heaven because of their pains and their sores, and did not repent of their deeds.", 
                "num": 11
              }, 
              {
                "text": "Then the sixth angel poured out his bowl on the great river Euphrates, and its water was dried up, so that the way of the kings from the east might be prepared.", 
                "num": 12
              }, 
              {
                "text": "And I saw three unclean spirits like frogs coming out of the mouth of the dragon, out of the mouth of the beast, and out of the mouth of the false prophet.", 
                "num": 13
              }, 
              {
                "text": "For they are spirits of demons, performing signs, which go out to the kings of the earth and of the whole world, to gather them to the battle of that great day of God Almighty.", 
                "num": 14
              }, 
              {
                "text": "\"Behold, I am coming as a thief. Blessed is he who watches, and keeps his garments, lest he walk naked and they see his shame.\"", 
                "num": 15
              }, 
              {
                "text": "And they gathered them together to the place called in Hebrew, Armageddon.", 
                "num": 16
              }, 
              {
                "text": "Then the seventh angel poured out his bowl into the air, and a loud voice came out of the temple of heaven, from the throne, saying, \"It is done!\"", 
                "num": 17
              }, 
              {
                "text": "And there were noises and thunderings and lightnings; and there was a great earthquake, such a mighty and great earthquake as had not occurred since men were on the earth.", 
                "num": 18
              }, 
              {
                "text": "Now the great city was divided into three parts, and the cities of the nations fell. And great Babylon was remembered before God, to give her the cup of the wine of the fierceness of His wrath.", 
                "num": 19
              }, 
              {
                "text": "Then every island fled away, and the mountains were not found.", 
                "num": 20
              }, 
              {
                "text": "And great hail from heaven fell upon men, each hailstone about the weight of a talent. Men blasphemed God because of the plague of the hail, since that plague was exceedingly great.", 
                "num": 21
              }
            ], 
            "num": 16
          }, 
          {
            "verses": [
              {
                "text": "Then one of the seven angels who had the seven bowls came and talked with me, saying to me, \"Come, I will show you the judgment of the great harlot who sits on many waters,", 
                "num": 1
              }, 
              {
                "text": "with whom the kings of the earth committed fornication, and the inhabitants of the earth were made drunk with the wine of her fornication.\"", 
                "num": 2
              }, 
              {
                "text": "So he carried me away in the Spirit into the wilderness. And I saw a woman sitting on a scarlet beast which was full of names of blasphemy, having seven heads and ten horns.", 
                "num": 3
              }, 
              {
                "text": "The woman was arrayed in purple and scarlet, and adorned with gold and precious stones and pearls, having in her hand a golden cup full of abominations and the filthiness of her fornication.", 
                "num": 4
              }, 
              {
                "text": "And on her forehead a name was written: MYSTERY, BABYLON THE GREAT, THE MOTHER OF HARLOTS AND OF THE ABOMINATIONS OF THE EARTH.", 
                "num": 5
              }, 
              {
                "text": "I saw the woman, drunk with the blood of the saints and with the blood of the martyrs of Jesus. And when I saw her, I marveled with great amazement.", 
                "num": 6
              }, 
              {
                "text": "But the angel said to me, \"Why did you marvel? I will tell you the mystery of the woman and of the beast that carries her, which has the seven heads and the ten horns.", 
                "num": 7
              }, 
              {
                "text": "The beast that you saw was, and is not, and will ascend out of the bottomless pit and go to perdition. And those who dwell on the earth will marvel, whose names are not written in the Book of Life from the foundation of the world, when they see the beast that was, and is not, and yet is.", 
                "num": 8
              }, 
              {
                "text": "\"Here is the mind which has wisdom: The seven heads are seven mountains on which the woman sits.", 
                "num": 9
              }, 
              {
                "text": "There are also seven kings. Five have fallen, one is, and the other has not yet come. And when he comes, he must continue a short time.", 
                "num": 10
              }, 
              {
                "text": "The beast that was, and is not, is himself also the eighth, and is of the seven, and is going to perdition.", 
                "num": 11
              }, 
              {
                "text": "\"The ten horns which you saw are ten kings who have received no kingdom as yet, but they receive authority for one hour as kings with the beast.", 
                "num": 12
              }, 
              {
                "text": "These are of one mind, and they will give their power and authority to the beast.", 
                "num": 13
              }, 
              {
                "text": "These will make war with the Lamb, and the Lamb will overcome them, for He is Lord of lords and King of kings; and those who are with Him are called, chosen, and faithful.\"", 
                "num": 14
              }, 
              {
                "text": "Then he said to me, \"The waters which you saw, where the harlot sits, are peoples, multitudes, nations, and tongues.", 
                "num": 15
              }, 
              {
                "text": "And the ten horns which you saw on the beast, these will hate the harlot, make her desolate and naked, eat her flesh and burn her with fire.", 
                "num": 16
              }, 
              {
                "text": "For God has put it into their hearts to fulfill His purpose, to be of one mind, and to give their kingdom to the beast, until the words of God are fulfilled.", 
                "num": 17
              }, 
              {
                "text": "And the woman whom you saw is that great city which reigns over the kings of the earth.\"", 
                "num": 18
              }
            ], 
            "num": 17
          }, 
          {
            "verses": [
              {
                "text": "After these things I saw another angel coming down from heaven, having great authority, and the earth was illuminated with his glory.", 
                "num": 1
              }, 
              {
                "text": "And he cried mightily with a loud voice, saying, \"Babylon the great is fallen, is fallen, and has become a dwelling place of demons, a prison for every foul spirit, and a cage for every unclean and hated bird!", 
                "num": 2
              }, 
              {
                "text": "For all the nations have drunk of the wine of the wrath of her fornication, the kings of the earth have committed fornication with her, and the merchants of the earth have become rich through the abundance of her luxury.\"", 
                "num": 3
              }, 
              {
                "text": "And I heard another voice from heaven saying, \"Come out of her, my people, lest you share in her sins, and lest you receive of her plagues.", 
                "num": 4
              }, 
              {
                "text": "For her sins have reached to heaven, and God has remembered her iniquities.", 
                "num": 5
              }, 
              {
                "text": "Render to her just as she rendered to you, and repay her double according to her works; in the cup which she has mixed, mix double for her.", 
                "num": 6
              }, 
              {
                "text": "In the measure that she glorified herself and lived luxuriously, in the same measure give her torment and sorrow; for she says in her heart, \"I sit as queen, and am no widow, and will not see sorrow.'", 
                "num": 7
              }, 
              {
                "text": "Therefore her plagues will come in one day--death and mourning and famine. And she will be utterly burned with fire, for strong is the Lord God who judges her.", 
                "num": 8
              }, 
              {
                "text": "\"The kings of the earth who committed fornication and lived luxuriously with her will weep and lament for her, when they see the smoke of her burning,", 
                "num": 9
              }, 
              {
                "text": "standing at a distance for fear of her torment, saying, \"Alas, alas, that great city Babylon, that mighty city! For in one hour your judgment has come.'", 
                "num": 10
              }, 
              {
                "text": "\"And the merchants of the earth will weep and mourn over her, for no one buys their merchandise anymore:", 
                "num": 11
              }, 
              {
                "text": "merchandise of gold and silver, precious stones and pearls, fine linen and purple, silk and scarlet, every kind of citron wood, every kind of object of ivory, every kind of object of most precious wood, bronze, iron, and marble;", 
                "num": 12
              }, 
              {
                "text": "and cinnamon and incense, fragrant oil and frankincense, wine and oil, fine flour and wheat, cattle and sheep, horses and chariots, and bodies and souls of men.", 
                "num": 13
              }, 
              {
                "text": "The fruit that your soul longed for has gone from you, and all the things which are rich and splendid have gone from you, and you shall find them no more at all.", 
                "num": 14
              }, 
              {
                "text": "The merchants of these things, who became rich by her, will stand at a distance for fear of her torment, weeping and wailing,", 
                "num": 15
              }, 
              {
                "text": "and saying, \"Alas, alas, that great city that was clothed in fine linen, purple, and scarlet, and adorned with gold and precious stones and pearls!", 
                "num": 16
              }, 
              {
                "text": "For in one hour such great riches came to nothing.' Every shipmaster, all who travel by ship, sailors, and as many as trade on the sea, stood at a distance", 
                "num": 17
              }, 
              {
                "text": "and cried out when they saw the smoke of her burning, saying, \"What is like this great city?'", 
                "num": 18
              }, 
              {
                "text": "\"They threw dust on their heads and cried out, weeping and wailing, and saying, \"Alas, alas, that great city, in which all who had ships on the sea became rich by her wealth! For in one hour she is made desolate.'", 
                "num": 19
              }, 
              {
                "text": "\"Rejoice over her, O heaven, and you holy apostles and prophets, for God has avenged you on her!\"", 
                "num": 20
              }, 
              {
                "text": "Then a mighty angel took up a stone like a great millstone and threw it into the sea, saying, \"Thus with violence the great city Babylon shall be thrown down, and shall not be found anymore.", 
                "num": 21
              }, 
              {
                "text": "The sound of harpists, musicians, flutists, and trumpeters shall not be heard in you anymore. No craftsman of any craft shall be found in you anymore, and the sound of a millstone shall not be heard in you anymore.", 
                "num": 22
              }, 
              {
                "text": "The light of a lamp shall not shine in you anymore, and the voice of bridegroom and bride shall not be heard in you anymore. For your merchants were the great men of the earth, for by your sorcery all the nations were deceived.", 
                "num": 23
              }, 
              {
                "text": "And in her was found the blood of prophets and saints, and of all who were slain on the earth.\"", 
                "num": 24
              }
            ], 
            "num": 18
          }, 
          {
            "verses": [
              {
                "text": "After these things I heard a loud voice of a great multitude in heaven, saying, \"Alleluia! Salvation and glory and honor and power belong to the Lord our God!", 
                "num": 1
              }, 
              {
                "text": "For true and righteous are His judgments, because He has judged the great harlot who corrupted the earth with her fornication; and He has avenged on her the blood of His servants shed by her.\"", 
                "num": 2
              }, 
              {
                "text": "Again they said, \"Alleluia! Her smoke rises up forever and ever!\"", 
                "num": 3
              }, 
              {
                "text": "And the twenty-four elders and the four living creatures fell down and worshiped God who sat on the throne, saying, \"Amen! Alleluia!\"", 
                "num": 4
              }, 
              {
                "text": "Then a voice came from the throne, saying, \"Praise our God, all you His servants and those who fear Him, both small and great!\"", 
                "num": 5
              }, 
              {
                "text": "And I heard, as it were, the voice of a great multitude, as the sound of many waters and as the sound of mighty thunderings, saying, \"Alleluia! For the Lord God Omnipotent reigns!", 
                "num": 6
              }, 
              {
                "text": "Let us be glad and rejoice and give Him glory, for the marriage of the Lamb has come, and His wife has made herself ready.\"", 
                "num": 7
              }, 
              {
                "text": "And to her it was granted to be arrayed in fine linen, clean and bright, for the fine linen is the righteous acts of the saints.", 
                "num": 8
              }, 
              {
                "text": "Then he said to me, \"Write: \"Blessed are those who are called to the marriage supper of the Lamb!\"' And he said to me, \"These are the true sayings of God.\"", 
                "num": 9
              }, 
              {
                "text": "And I fell at his feet to worship him. But he said to me, \"See that you do not do that! I am your fellow servant, and of your brethren who have the testimony of Jesus. Worship God! For the testimony of Jesus is the spirit of prophecy.\"", 
                "num": 10
              }, 
              {
                "text": "Now I saw heaven opened, and behold, a white horse. And He who sat on him was called Faithful and True, and in righteousness He judges and makes war.", 
                "num": 11
              }, 
              {
                "text": "His eyes were like a flame of fire, and on His head were many crowns. He had a name written that no one knew except Himself.", 
                "num": 12
              }, 
              {
                "text": "He was clothed with a robe dipped in blood, and His name is called The Word of God.", 
                "num": 13
              }, 
              {
                "text": "And the armies in heaven, clothed in fine linen, white and clean, followed Him on white horses.", 
                "num": 14
              }, 
              {
                "text": "Now out of His mouth goes a sharp sword, that with it He should strike the nations. And He Himself will rule them with a rod of iron. He Himself treads the winepress of the fierceness and wrath of Almighty God.", 
                "num": 15
              }, 
              {
                "text": "And He has on His robe and on His thigh a name written: KING OF KINGS AND LORD OF LORDS.", 
                "num": 16
              }, 
              {
                "text": "Then I saw an angel standing in the sun; and he cried with a loud voice, saying to all the birds that fly in the midst of heaven, \"Come and gather together for the supper of the great God,", 
                "num": 17
              }, 
              {
                "text": "that you may eat the flesh of kings, the flesh of captains, the flesh of mighty men, the flesh of horses and of those who sit on them, and the flesh of all people, free and slave, both small and great.\"", 
                "num": 18
              }, 
              {
                "text": "And I saw the beast, the kings of the earth, and their armies, gathered together to make war against Him who sat on the horse and against His army.", 
                "num": 19
              }, 
              {
                "text": "Then the beast was captured, and with him the false prophet who worked signs in his presence, by which he deceived those who received the mark of the beast and those who worshiped his image. These two were cast alive into the lake of fire burning with brimstone.", 
                "num": 20
              }, 
              {
                "text": "And the rest were killed with the sword which proceeded from the mouth of Him who sat on the horse. And all the birds were filled with their flesh.", 
                "num": 21
              }
            ], 
            "num": 19
          }, 
          {
            "verses": [
              {
                "text": "Then I saw an angel coming down from heaven, having the key to the bottomless pit and a great chain in his hand.", 
                "num": 1
              }, 
              {
                "text": "He laid hold of the dragon, that serpent of old, who is the Devil and Satan, and bound him for a thousand years;", 
                "num": 2
              }, 
              {
                "text": "and he cast him into the bottomless pit, and shut him up, and set a seal on him, so that he should deceive the nations no more till the thousand years were finished. But after these things he must be released for a little while.", 
                "num": 3
              }, 
              {
                "text": "And I saw thrones, and they sat on them, and judgment was committed to them. Then I saw the souls of those who had been beheaded for their witness to Jesus and for the word of God, who had not worshiped the beast or his image, and had not received his mark on their foreheads or on their hands. And they lived and reigned with Christ for a thousand years.", 
                "num": 4
              }, 
              {
                "text": "But the rest of the dead did not live again until the thousand years were finished. This is the first resurrection.", 
                "num": 5
              }, 
              {
                "text": "Blessed and holy is he who has part in the first resurrection. Over such the second death has no power, but they shall be priests of God and of Christ, and shall reign with Him a thousand years.", 
                "num": 6
              }, 
              {
                "text": "Now when the thousand years have expired, Satan will be released from his prison", 
                "num": 7
              }, 
              {
                "text": "and will go out to deceive the nations which are in the four corners of the earth, Gog and Magog, to gather them together to battle, whose number is as the sand of the sea.", 
                "num": 8
              }, 
              {
                "text": "They went up on the breadth of the earth and surrounded the camp of the saints and the beloved city. And fire came down from God out of heaven and devoured them.", 
                "num": 9
              }, 
              {
                "text": "The devil, who deceived them, was cast into the lake of fire and brimstone where the beast and the false prophet are. And they will be tormented day and night forever and ever.", 
                "num": 10
              }, 
              {
                "text": "Then I saw a great white throne and Him who sat on it, from whose face the earth and the heaven fled away. And there was found no place for them.", 
                "num": 11
              }, 
              {
                "text": "And I saw the dead, small and great, standing before God, and books were opened. And another book was opened, which is the Book of Life. And the dead were judged according to their works, by the things which were written in the books.", 
                "num": 12
              }, 
              {
                "text": "The sea gave up the dead who were in it, and Death and Hades delivered up the dead who were in them. And they were judged, each one according to his works.", 
                "num": 13
              }, 
              {
                "text": "Then Death and Hades were cast into the lake of fire. This is the second death.", 
                "num": 14
              }, 
              {
                "text": "And anyone not found written in the Book of Life was cast into the lake of fire.", 
                "num": 15
              }
            ], 
            "num": 20
          }, 
          {
            "verses": [
              {
                "text": "Now I saw a new heaven and a new earth, for the first heaven and the first earth had passed away. Also there was no more sea.", 
                "num": 1
              }, 
              {
                "text": "Then I, John, saw the holy city, New Jerusalem, coming down out of heaven from God, prepared as a bride adorned for her husband.", 
                "num": 2
              }, 
              {
                "text": "And I heard a loud voice from heaven saying, \"Behold, the tabernacle of God is with men, and He will dwell with them, and they shall be His people. God Himself will be with them and be their God.", 
                "num": 3
              }, 
              {
                "text": "And God will wipe away every tear from their eyes; there shall be no more death, nor sorrow, nor crying. There shall be no more pain, for the former things have passed away.\"", 
                "num": 4
              }, 
              {
                "text": "Then He who sat on the throne said, \"Behold, I make all things new.\" And He said to me, \"Write, for these words are true and faithful.\"", 
                "num": 5
              }, 
              {
                "text": "And He said to me, \"It is done! I am the Alpha and the Omega, the Beginning and the End. I will give of the fountain of the water of life freely to him who thirsts.", 
                "num": 6
              }, 
              {
                "text": "He who overcomes shall inherit all things, and I will be his God and he shall be My son.", 
                "num": 7
              }, 
              {
                "text": "But the cowardly, unbelieving, abominable, murderers, sexually immoral, sorcerers, idolaters, and all liars shall have their part in the lake which burns with fire and brimstone, which is the second death.\"", 
                "num": 8
              }, 
              {
                "text": "Then one of the seven angels who had the seven bowls filled with the seven last plagues came to me and talked with me, saying, \"Come, I will show you the bride, the Lamb's wife.\"", 
                "num": 9
              }, 
              {
                "text": "And he carried me away in the Spirit to a great and high mountain, and showed me the great city, the holy Jerusalem, descending out of heaven from God,", 
                "num": 10
              }, 
              {
                "text": "having the glory of God. Her light was like a most precious stone, like a jasper stone, clear as crystal.", 
                "num": 11
              }, 
              {
                "text": "Also she had a great and high wall with twelve gates, and twelve angels at the gates, and names written on them, which are the names of the twelve tribes of the children of Israel:", 
                "num": 12
              }, 
              {
                "text": "three gates on the east, three gates on the north, three gates on the south, and three gates on the west.", 
                "num": 13
              }, 
              {
                "text": "Now the wall of the city had twelve foundations, and on them were the names of the twelve apostles of the Lamb.", 
                "num": 14
              }, 
              {
                "text": "And he who talked with me had a gold reed to measure the city, its gates, and its wall.", 
                "num": 15
              }, 
              {
                "text": "The city is laid out as a square; its length is as great as its breadth. And he measured the city with the reed: twelve thousand furlongs. Its length, breadth, and height are equal.", 
                "num": 16
              }, 
              {
                "text": "Then he measured its wall: one hundred and forty-four cubits, according to the measure of a man, that is, of an angel.", 
                "num": 17
              }, 
              {
                "text": "The construction of its wall was of jasper; and the city was pure gold, like clear glass.", 
                "num": 18
              }, 
              {
                "text": "The foundations of the wall of the city were adorned with all kinds of precious stones: the first foundation was jasper, the second sapphire, the third chalcedony, the fourth emerald,", 
                "num": 19
              }, 
              {
                "text": "the fifth sardonyx, the sixth sardius, the seventh chrysolite, the eighth beryl, the ninth topaz, the tenth chrysoprase, the eleventh jacinth, and the twelfth amethyst.", 
                "num": 20
              }, 
              {
                "text": "The twelve gates were twelve pearls: each individual gate was of one pearl. And the street of the city was pure gold, like transparent glass.", 
                "num": 21
              }, 
              {
                "text": "But I saw no temple in it, for the Lord God Almighty and the Lamb are its temple.", 
                "num": 22
              }, 
              {
                "text": "The city had no need of the sun or of the moon to shine in it, for the glory of God illuminated it. The Lamb is its light.", 
                "num": 23
              }, 
              {
                "text": "And the nations of those who are saved shall walk in its light, and the kings of the earth bring their glory and honor into it.", 
                "num": 24
              }, 
              {
                "text": "Its gates shall not be shut at all by day (there shall be no night there).", 
                "num": 25
              }, 
              {
                "text": "And they shall bring the glory and the honor of the nations into it.", 
                "num": 26
              }, 
              {
                "text": "But there shall by no means enter it anything that defiles, or causes an abomination or a lie, but only those who are written in the Lamb's Book of Life.", 
                "num": 27
              }
            ], 
            "num": 21
          }, 
          {
            "verses": [
              {
                "text": "And he showed me a pure river of water of life, clear as crystal, proceeding from the throne of God and of the Lamb.", 
                "num": 1
              }, 
              {
                "text": "In the middle of its street, and on either side of the river, was the tree of life, which bore twelve fruits, each tree yielding its fruit every month. The leaves of the tree were for the healing of the nations.", 
                "num": 2
              }, 
              {
                "text": "And there shall be no more curse, but the throne of God and of the Lamb shall be in it, and His servants shall serve Him.", 
                "num": 3
              }, 
              {
                "text": "They shall see His face, and His name shall be on their foreheads.", 
                "num": 4
              }, 
              {
                "text": "There shall be no night there: They need no lamp nor light of the sun, for the Lord God gives them light. And they shall reign forever and ever.", 
                "num": 5
              }, 
              {
                "text": "Then he said to me, \"These words are faithful and true.\" And the Lord God of the holy prophets sent His angel to show His servants the things which must shortly take place.", 
                "num": 6
              }, 
              {
                "text": "\"Behold, I am coming quickly! Blessed is he who keeps the words of the prophecy of this book.\"", 
                "num": 7
              }, 
              {
                "text": "Now I, John, saw and heard these things. And when I heard and saw, I fell down to worship before the feet of the angel who showed me these things.", 
                "num": 8
              }, 
              {
                "text": "Then he said to me, \"See that you do not do that. For I am your fellow servant, and of your brethren the prophets, and of those who keep the words of this book. Worship God.\"", 
                "num": 9
              }, 
              {
                "text": "And he said to me, \"Do not seal the words of the prophecy of this book, for the time is at hand.", 
                "num": 10
              }, 
              {
                "text": "He who is unjust, let him be unjust still; he who is filthy, let him be filthy still; he who is righteous, let him be righteous still; he who is holy, let him be holy still.\"", 
                "num": 11
              }, 
              {
                "text": "\"And behold, I am coming quickly, and My reward is with Me, to give to every one according to his work.", 
                "num": 12
              }, 
              {
                "text": "I am the Alpha and the Omega, the Beginning and the End, the First and the Last.\"", 
                "num": 13
              }, 
              {
                "text": "Blessed are those who do His commandments, that they may have the right to the tree of life, and may enter through the gates into the city.", 
                "num": 14
              }, 
              {
                "text": "But outside are dogs and sorcerers and sexually immoral and murderers and idolaters, and whoever loves and practices a lie.", 
                "num": 15
              }, 
              {
                "text": "\"I, Jesus, have sent My angel to testify to you these things in the churches. I am the Root and the Offspring of David, the Bright and Morning Star.\"", 
                "num": 16
              }, 
              {
                "text": "And the Spirit and the bride say, \"Come!\" And let him who hears say, \"Come!\" And let him who thirsts come. Whoever desires, let him take the water of life freely.", 
                "num": 17
              }, 
              {
                "text": "For I testify to everyone who hears the words of the prophecy of this book: If anyone adds to these things, God will add to him the plagues that are written in this book;", 
                "num": 18
              }, 
              {
                "text": "and if anyone takes away from the words of the book of this prophecy, God shall take away his part from the Book of Life, from the holy city, and from the things which are written in this book.", 
                "num": 19
              }, 
              {
                "text": "He who testifies to these things says, \"Surely I am coming quickly.\" Amen. Even so, come, Lord Jesus!", 
                "num": 20
              }, 
              {
                "text": "The grace of our Lord Jesus Christ be with you all. Amen.", 
                "num": 21
              }
            ], 
            "num": 22
          }
        ]
      }
    ]
  }